import {Drawer} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {useEventsPathnameParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {
  DRAWER_TRANSITION_DURATION,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {EventDetailContent} from './EventDetailContent'
import {EventDetailProvider} from './EventDetailProvider'

interface IEventDetailDrawerProps {
  onExited: () => void
  showOpenPreviewButton?: boolean
}

export const EventDetailDrawer: React.FC<IEventDetailDrawerProps> = ({
  onExited,
  showOpenPreviewButton
}: IEventDetailDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {eventId} = useEventsPathnameParams()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const history = useHistory()
  useEffect(() => {
    if (!isNaN(eventId)) {
      setIsDrawerOpen(true)
    }
  }, [eventId])

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])
  const handleActionButtonClick = useCallback(() => {
    history.push(routeTo.admin.cashDesk.eventAuditoriumPreview(eventId))
  }, [history, eventId])
  const drawerClasses = useDrawerWithSideNavigationStyles()
  return (
    <Drawer
      open={isDrawerOpen}
      onClose={handleClose}
      SlideProps={{
        exit: true,
        onExited
      }}
      classes={drawerClasses}
      keepMounted
      anchor="right"
      transitionDuration={DRAWER_TRANSITION_DURATION}
    >
      {!isNaN(eventId) && (
        <EventDetailProvider eventId={eventId} onClose={handleClose}>
          {({event}) => (
            <EventDetailContent
              onClose={handleClose}
              ActionButtonProps={
                showOpenPreviewButton && P([PermissionCode.ReadCart])
                  ? {
                      children: t('Open preview'),
                      onClick: handleActionButtonClick
                    }
                  : undefined
              }
              event={event}
            />
          )}
        </EventDetailProvider>
      )}
    </Drawer>
  )
}
