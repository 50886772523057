import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  LoginMutation,
  LoginMutationVariables,
  LogoutMutation,
  LogoutMutationVariables,
  PermissionCode,
  PingUserQuery,
  PingUserQueryVariables
} from '../__generated__/schema'
import {TRANSLATED_LOCALES_FRAGMENT} from '../components/pages/admin/graphql'
import {usePostCustomerDisplayMessage} from '../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../customerDisplayBroadcastChannel/types'
import {setWebSocketReconnect} from './apollo'
import {useDefaultErrorHandler} from './errors'
import {routeTo} from './routes'

export enum AuthStatus {
  LOGGED_IN = 'logged in',
  LOGGED_OUT = 'logged out',
  UNKNOWN = 'unknown'
}

export const LEAD_OPTION_FRAGMENT = gql`
  fragment LeadOptionProperties on LeadOption {
    id
    type
    inputStatus
    field
    helperText
  }
`

export const USER_PROPERTIES_FRAGMENT = gql`
  fragment UserProperties on User {
    id
    clientId
    username
    firstName
    lastName
    workEmail
    personalEmail
    phoneNumber
    mobilPhoneNumber
    title
    degree
    localeCode
    timezone
    state
    permissionCodes
    isLead
    lastLoggedIn
    client {
      id
    }
    roleIds
  }
`

export const DETAIL_CLIENT_PROPERTIES_FRAGMENT = gql`
  fragment DetailClientProperties on Client {
    id
    name
    type
    countryCode
    currency
    localeCodes
    state
    companyIdNumber
    VATRegistered
    TAXId
    VATId
    ticketDefaultVatRate
    legalAddress {
      complex
      street
      town
      postalCode
      country
    }
    mailingAddress
    invoiceAddress
    dealerId
    deactivateReason
    retailLeadOptions {
      ...LeadOptionProperties
    }
    eCommerceLeadOptions {
      ...LeadOptionProperties
    }
    retailReservationLeadOptions {
      ...LeadOptionProperties
    }
    eCommerceReservationLeadOptions {
      ...LeadOptionProperties
    }
    termsOfServiceUrl
    marketingInformationUrl
    eCommerceUrl
    templateAssignments {
      id
      template {
        id
        types
      }
    }
  }
  ${LEAD_OPTION_FRAGMENT}
`

export const ROLE_PROPERTIES_FRAGMENT = gql`
  fragment RoleProperties on Role {
    id
    names {
      ...TranslatedLocales
    }
    type
    isDeprecated
    descriptions {
      ...TranslatedLocales
    }
    permissionCodes
    client {
      name
    }
    createdAt
    updatedAt
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`

export const GET_CURRENT_USER = gql`
  query CurrentUser {
    me {
      ...UserProperties
    }
    we {
      ...DetailClientProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

export const PING_USER = gql`
  query PingUser {
    me {
      id
    }
  }
`

// TODO: how to type 'data'?
const getLoginStatus = (error: any, loading: boolean, data: any) => {
  if (error) return AuthStatus.LOGGED_OUT
  if (data) {
    return data.me && !data.me.isLead
      ? AuthStatus.LOGGED_IN
      : AuthStatus.LOGGED_OUT
  }
  return loading ? AuthStatus.UNKNOWN : AuthStatus.LOGGED_OUT
}

export const useUserInfo = () => {
  const {error, loading, data} =
    useQuery<CurrentUserQuery, CurrentUserQueryVariables>(GET_CURRENT_USER)

  return {
    loginStatus: getLoginStatus(error, loading, data),
    user: data ? data.me : null,
    effectiveClient: data ? data.we : null,
    error,
    loading
  }
}

export const usePingUser = () => {
  return useQuery<PingUserQuery, PingUserQueryVariables>(PING_USER, {
    fetchPolicy: 'network-only'
  })
}

export const useLogin = () => {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN, {
    refetchQueries: () => {
      return [{query: GET_CURRENT_USER}]
    }
  })
  return useCallback(
    async (credentials: LoginMutationVariables) => {
      try {
        await login({variables: credentials})
        setWebSocketReconnect(true)
      } catch (e) {
        setWebSocketReconnect(false)
        throw e
      }
    },
    [login]
  )
}

export const useLogout = () => {
  const history = useHistory()
  const {t} = useTranslation()
  const defaultErrorHandler = useDefaultErrorHandler()
  const [logout] = useMutation<LogoutMutation, LogoutMutationVariables>(LOGOUT)
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()
  return useCallback(async () => {
    try {
      await logout()
      postCustomerDisplayMessage({
        type: CustomerDisplayMessageType.UserLoggedOut
      })
    } catch (e) {
      defaultErrorHandler(e, t('Logout failed'))
    } finally {
      history.push(routeTo.admin.dashboard())
      localStorage.removeItem('currentCartId')
      sessionStorage.clear()
      document.location.reload()
    }
  }, [defaultErrorHandler, history, logout, postCustomerDisplayMessage, t])
}

interface IEnsurePermissions {
  children: React.ReactNode
  permissions: Array<PermissionCode>
}

export const EnsurePermissions: React.FC<IEnsurePermissions> = ({
  children,
  permissions
}: IEnsurePermissions) => {
  const {user} = useUserInfo()

  if (!user) return null
  if (permissions.some((p) => !user.permissionCodes.includes(p))) return null

  // Note: fragment is required for TS
  return <>{children}</>
}

export const useEnsurePermissions = () => {
  const {user} = useUserInfo()

  const ensurePermissions = useCallback(
    (permissions: Array<PermissionCode>) =>
      Boolean(
        user && permissions.every((p) => user.permissionCodes.includes(p))
      ),
    [user]
  )

  // To force shortcut 'P' everywhere
  return useMemo(() => ({P: ensurePermissions}), [ensurePermissions])
}
