import {configs, print, PrintData, printers, security, websocket} from 'qz-tray'
import {useCallback, useEffect, useState} from 'react'

export const connectToQz = async () => {
  try {
    await websocket.connect()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error while connecting to QZ tray: ${error}`)
  }
}

export const disconnectFromQz = async () => {
  try {
    await websocket.disconnect()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error while disconnecting from QZ tray: ${error}`)
  }
}

export const useInitializeQz = () => {
  security.setCertificatePromise((resolve: any) =>
    fetch('/qz-cert.pem', {
      cache: 'no-store',
      headers: {'Content-Type': 'text/plain'}
    }).then((data) => resolve(data.text()))
  )
  security.setSignatureAlgorithm('SHA512')
  security.setSignaturePromise((dataToSign: any) => {
    return (resolve: any, reject: any) =>
      fetch(`/signQz?requestToSign=${dataToSign}`, {
        cache: 'no-store',
        headers: {'Content-Type': 'text/plain'}
      })
        .then((data) => (data.ok ? resolve(data.text()) : reject(data.text())))
        .catch((error) => reject(error))
  })

  useEffect(() => {
    const connect = async () => await connectToQz()
    connect()
    return () => {
      const disconnect = async () => await disconnectFromQz()
      disconnect()
    }
  }, [])
}

export const useCheckQzTray = () => {
  const [isActive, setIsActive] = useState<boolean>(websocket.isActive())
  const reCheck = useCallback(() => setIsActive(websocket.isActive()), [])
  return {isActive, reCheck}
}

export const useGetPrinters = (skip?: boolean) => {
  const [availablePrinters, setAvailablePrinters] =
    useState<string[] | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any | undefined>(null)
  const getPrinters = useCallback(async () => {
    try {
      setLoading(true)
      const printersList = await printers.find()
      setAvailablePrinters(
        Array.isArray(printersList) ? printersList : [printersList]
      )
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])
  useEffect(() => {
    if (!skip) {
      getPrinters()
    }
  }, [getPrinters, skip])
  return {printers: availablePrinters, loading, error}
}

export const printToPrinter = async (printer: string, url: string) => {
  const config = configs.create(printer, {
    units: 'mm',
    orientation: 'portrait',
    colorType: 'grayscale'
  })
  const data: PrintData[] = [
    {
      type: 'pixel',
      format: 'pdf',
      flavor: 'file',
      data: url,
      options: {
        altFontRendering: true,
        ignoreTransparency: true,
        pageWidth: 75
      }
    }
  ]
  try {
    await print([config], data)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error while printing to printer ${printer}: ${error}`)
  }
}
