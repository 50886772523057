import {Box, Paper, Typography} from '@mui/material'
import React from 'react'
import {EnabledDiscountPropertiesFragment} from '../../../../__generated__/schema'

interface IDiscountListItemProps {
  discount: EnabledDiscountPropertiesFragment
  description: string
  children: React.ReactNode
  disabled?: boolean
}

export const DiscountListItem: React.FC<IDiscountListItemProps> = ({
  discount,
  description,
  children,
  disabled
}: IDiscountListItemProps) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        maxWidth: 800,
        width: '100%',
        display: 'grid',
        gridTemplateAreas: `
            "name        children"
            "description children"
          `,
        justifyContent: 'space-between',
        alignItems: 'center',
        opacity: (theme) =>
          disabled ? theme.palette.action.disabledOpacity : undefined
      }}
    >
      <Typography variant="subtitle2">{discount.name}</Typography>
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
      <Box sx={{gridArea: 'children'}}>{children}</Box>
    </Paper>
  )
}
