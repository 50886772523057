import {useQuery} from '@apollo/react-hooks'
import {makeStyles} from '@mui/styles'
import onScan, {ScanEvent} from 'onscan.js'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetAvailableProductGroupsQuery,
  GetAvailableProductGroupsQueryVariables,
  PermissionCode,
  ProductGroupState,
  ProductPropertiesFragment,
  ProductsFilterInput
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {ShopViewMode} from '../../../../../types'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {RenderOnData} from '../../../../common'
import {Blank} from '../../../../visual/Blank'
import {keyCodeMapper} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {EditProductItemQuantityDialog} from './EditProductItemQuantityDialog'
import {
  GET_AVAILABLE_PRODUCT_GROUPS,
  useDecrementProductItemQuantity,
  useIncrementProductItemQuantity,
  useIncrementProductItemQuantityWithBarcode,
  useIncrementProductItemQuantityWithPriceLookupCode
} from './graphql'
import {GridView} from './GridView'
import {InputView} from './InputView'
import {ListView} from './ListView'
import {ProductGroupChips} from './ProductGroupChips'
import {DEFAULT_SHOP_FILTER_INPUT} from './ShopSearch'
import {SubCart} from './SubCart'
import {SubHeader} from './SubHeader'
import {CodeFormulaErrorMessage} from './types'
import {
  transformPluToInput,
  useIncrementProductItemQuantityErrorHandler
} from './utils'

interface IContentProps {
  divisionId: number
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "subCart subHeader" 
      "subCart viewBox"
    `,
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '360px 1fr',
    height: '100%'
  },
  subHeader: {
    gridArea: 'subHeader'
  },
  subCart: {
    gridArea: 'subCart'
  },
  viewBox: {
    gridArea: 'viewBox',
    overflowY: 'auto'
  }
}))

export const Content: React.FC<IContentProps> = ({
  divisionId
}: IContentProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [defaultShopViewMode] = useLocalStorageState<ShopViewMode>(
    LocalStorageKey.ShopViewMode,
    ShopViewMode.ListView
  )
  const [warehouseId] = useLocalStorageState<number | null>(
    LocalStorageKey.WarehouseId,
    null
  )
  const [shopViewMode, set] = useState<ShopViewMode>(defaultShopViewMode)
  const [listViewSearchFilter, setListViewSearchFilter] =
    useState<ProductsFilterInput>(DEFAULT_SHOP_FILTER_INPUT)
  const [selectedProductGroupId, setSelectedProductGroupId] =
    useState<number | undefined>(undefined)
  const [pluCode, setPluCode] = useState<string>('')
  const [pluCodeError, setPluCodeError] =
    useState<CodeFormulaErrorMessage | null>(null)
  const handleToggleButtonGroupChange = useCallback((e, newShopViewMode) => {
    if (newShopViewMode) {
      set(newShopViewMode)
      setListViewSearchFilter(DEFAULT_SHOP_FILTER_INPUT)
    }
  }, [])
  const {
    data: productGroupsData,
    loading: productGroupsLoading,
    error: productGroupsError
  } = useQuery<
    GetAvailableProductGroupsQuery,
    GetAvailableProductGroupsQueryVariables
  >(GET_AVAILABLE_PRODUCT_GROUPS, {
    variables: {
      filter: {
        state: ProductGroupState.Active,
        divisionIds: [divisionId],
        isAvailableOnRetailChannel: true
      },
      productsFilter: {divisionId, isAvailableOnRetailChannel: true}
    }
  })
  const incrementProductItemQuantity = useIncrementProductItemQuantity()
  const decrementProductItemQuantity = useDecrementProductItemQuantity()
  const incrementProductItemQuantityWithPriceLookupCode =
    useIncrementProductItemQuantityWithPriceLookupCode()
  const incrementProductItemQuantityWithBarcode =
    useIncrementProductItemQuantityWithBarcode()
  const incrementProductItemQuantityErrorHandler =
    useIncrementProductItemQuantityErrorHandler()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {currentCartId, initializeCurrentCart, updateCurrentCart} =
    useCurrentCart()

  const handleProductItemQuantityIncrement = useCallback(
    async (productId: number, increment: number) => {
      setShowBackdrop(true)
      try {
        const {data} = await incrementProductItemQuantity({
          cartId: currentCartId,
          productId,
          increment,
          divisionId,
          warehouseId
        })
        if (data?.incrementProductItemQuantity) {
          if (!currentCartId) {
            initializeCurrentCart(data.incrementProductItemQuantity)
          } else {
            updateCurrentCart()
          }
        }
      } catch (error) {
        incrementProductItemQuantityErrorHandler({error})
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      currentCartId,
      divisionId,
      incrementProductItemQuantity,
      incrementProductItemQuantityErrorHandler,
      initializeCurrentCart,
      setShowBackdrop,
      updateCurrentCart,
      warehouseId
    ]
  )

  const handleProductItemQuantityDecrement = useCallback(
    async (productItemId: number, decrement: number) => {
      if (currentCartId) {
        setShowBackdrop(true)
        try {
          const {data} = await decrementProductItemQuantity({
            productItemId,
            cartId: currentCartId,
            decrement
          })
          if (data?.decrementProductItemQuantity) {
            updateCurrentCart()
          }
        } catch (e) {
          defaultErrorHandler(e, t('Decrementing product quantity failed'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      decrementProductItemQuantity,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )

  const handleProductItemQuantityIncrementWithPluCode =
    useCallback(async () => {
      try {
        setShowBackdrop(true)
        const {data} = await incrementProductItemQuantityWithPriceLookupCode(
          transformPluToInput({
            cartId: currentCartId,
            divisionId,
            code: pluCode,
            warehouseId
          })
        )
        if (data?.incrementProductItemQuantityWithPriceLookupCode) {
          if (!currentCartId) {
            initializeCurrentCart(
              data.incrementProductItemQuantityWithPriceLookupCode
            )
          } else {
            updateCurrentCart()
          }
        }
        setPluCode('')
      } catch (error) {
        incrementProductItemQuantityErrorHandler({
          error
        })
      } finally {
        setShowBackdrop(false)
      }
    }, [
      setShowBackdrop,
      incrementProductItemQuantityWithPriceLookupCode,
      currentCartId,
      divisionId,
      pluCode,
      warehouseId,
      initializeCurrentCart,
      updateCurrentCart,
      incrementProductItemQuantityErrorHandler
    ])
  const getProductUnitPrice = useCallback(
    (product: ProductPropertiesFragment) =>
      product.pricings.find((pricing) => pricing.division.id === divisionId)
        ?.activePricing?.retailPrice,
    [divisionId]
  )
  useEffect(() => {
    onScan.attachTo(document, {
      keyCodeMapper
    })
    return () => {
      onScan.detachFrom(document)
    }
  }, [])
  useEffect(() => {
    if (productGroupsData) {
      setSelectedProductGroupId(
        productGroupsData.productGroups.length
          ? productGroupsData.productGroups[0].id
          : undefined
      )
    }
  }, [productGroupsData])
  const handleScanSuccess = useCallback(
    async (scan: ScanEvent) => {
      if (P([PermissionCode.IncrementProductItemQuantityWithBarcode])) {
        try {
          setShowBackdrop(true)
          const {data} = await incrementProductItemQuantityWithBarcode({
            cartId: currentCartId,
            divisionId,
            increment: 1,
            barcode: scan.detail.scanCode,
            warehouseId
          })
          if (data?.incrementProductItemQuantityWithBarcode) {
            if (!currentCartId) {
              initializeCurrentCart(
                data.incrementProductItemQuantityWithBarcode
              )
            } else {
              updateCurrentCart()
            }
          }
        } catch (error) {
          incrementProductItemQuantityErrorHandler({
            error
          })
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      P,
      setShowBackdrop,
      incrementProductItemQuantityWithBarcode,
      currentCartId,
      divisionId,
      warehouseId,
      initializeCurrentCart,
      updateCurrentCart,
      incrementProductItemQuantityErrorHandler
    ]
  )
  useEffect(() => {
    window.document.addEventListener('scan', handleScanSuccess)
    return () => {
      window.document.removeEventListener('scan', handleScanSuccess)
    }
  }, [handleScanSuccess])
  return (
    <div className={classes.root}>
      <SubHeader
        className={classes.subHeader}
        shopViewMode={shopViewMode}
        onShopViewModeChange={handleToggleButtonGroupChange}
        onFilterChange={setListViewSearchFilter}
        pluCode={pluCode}
        setPluCode={setPluCode}
        pluCodeError={pluCodeError}
        setPluCodeError={setPluCodeError}
        onProductItemQuantityIncrementWithPluCode={
          handleProductItemQuantityIncrementWithPluCode
        }
        gridViewComponent={
          <RenderOnData<GetAvailableProductGroupsQuery>
            data={productGroupsData}
            loading={productGroupsLoading}
            error={productGroupsError}
            errorMessage={t<string>('Error while loading product groups')}
            dataCondition={(data) => Array.isArray(data.productGroups)}
          >
            {({productGroups}) => (
              <ProductGroupChips
                productGroups={productGroups}
                selectedProductGroup={selectedProductGroupId}
                onProductGroupChange={setSelectedProductGroupId}
              />
            )}
          </RenderOnData>
        }
      />
      <SubCart
        className={classes.subCart}
        onProductItemQuantityDecrement={handleProductItemQuantityDecrement}
      />
      <div className={classes.viewBox}>
        {shopViewMode === ShopViewMode.ListView ? (
          <ListView
            divisionId={divisionId}
            onProductItemQuantityIncrement={handleProductItemQuantityIncrement}
            onProductItemQuantityDecrement={handleProductItemQuantityDecrement}
            getProductUnitPrice={getProductUnitPrice}
            searchFilter={listViewSearchFilter}
            warehouseId={warehouseId}
          />
        ) : shopViewMode === ShopViewMode.GridView ? (
          <>
            {selectedProductGroupId ? (
              <GridView
                onProductItemQuantityIncrement={
                  handleProductItemQuantityIncrement
                }
                getProductUnitPrice={getProductUnitPrice}
                divisionId={divisionId}
                productGroupId={selectedProductGroupId}
                warehouseId={warehouseId}
              />
            ) : (
              <Blank title={t('No active product group found')} />
            )}
          </>
        ) : (
          <InputView
            pluCode={pluCode}
            setPluCode={setPluCode}
            pluCodeError={pluCodeError}
            setPluCodeError={setPluCodeError}
            onProductItemQuantityIncrementWithPluCode={
              handleProductItemQuantityIncrementWithPluCode
            }
          />
        )}
        <EditProductItemQuantityDialog
          getProductUnitPrice={getProductUnitPrice}
          onProductItemQuantityIncrement={handleProductItemQuantityIncrement}
          onProductItemQuantityDecrement={handleProductItemQuantityDecrement}
          warehouseId={warehouseId}
        />
      </div>
    </div>
  )
}
