import CancelIcon from '@mui/icons-material/Cancel'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {FieldValues} from 'react-hook-form'
import {Theme} from '../../theme'
import {FormValidationError} from '../common/FormHelpers'

import {FormFieldHelper} from '../visual/common'
import {IUncontrolledFormTextInputProps, TextField} from './FormTextInput'
import {
  cloneInputModeInInputProps,
  getValueInUncontrolledFormComponent,
  useHandleBlur,
  useHandleChange,
  useHasError
} from './utils'

const useUncontrolledFormTextInputStyles = makeStyles<Theme>(() => ({
  hidden: {
    display: 'none'
  },
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateAreas: `
      "cancelButton textField"
      ".            subTexts"
    `,
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    alignItems: 'center'
  },
  subTexts: {
    gridArea: 'subTexts'
  },
  textField: {
    gridArea: 'textField'
  },
  cancelButton: {
    gridArea: 'cancelButton',
    height: 48
  }
}))

export const UncontrolledFormTextInputWithCancelButton = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  watch,
  errors,
  setValue,
  register,
  validationOptions,
  triggerValidation,
  helperNote,
  defaultValue,
  className,
  InputProps,
  ...otherProps
}: IUncontrolledFormTextInputProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({name, defaultValue, watch})
  const hasError = useHasError<FormValues>(errors, name)
  const handleChange = useHandleChange<FormValues>({
    hasError,
    name,
    setValue
  })
  const handleBlur = useHandleBlur<FormValues>({name, triggerValidation})
  const classes = useUncontrolledFormTextInputStyles()
  const handleCancelButtonClick = useCallback(() => {
    setValue(name, undefined as unknown as any)
  }, [name, setValue])
  return (
    <div className={cn(classes.root, className)}>
      <IconButton
        className={classes.cancelButton}
        onClick={handleCancelButtonClick}
        color="primary"
        disabled={!value || otherProps.disabled}
      >
        <CancelIcon />
      </IconButton>
      <input
        name={name}
        ref={validationOptions ? register(validationOptions) : register()}
        className={classes.hidden}
        defaultValue={defaultValue as string}
      />
      <TextField
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        error={hasError}
        className={classes.textField}
        InputProps={cloneInputModeInInputProps(
          InputProps,
          otherProps.inputMode
        )}
        {...otherProps}
      />
      <div className={classes.subTexts}>
        {!hasError && (
          <FormFieldHelper required={otherProps.required} note={helperNote} />
        )}
        <FormValidationError<FormValues> errors={errors} fieldName={name} />
      </div>
    </div>
  )
}
