import {useTranslation} from 'react-i18next'

import {ApiSeatState} from '../../../../../../__generated__/schema'

export const useAllSeatStateTranslations = (): Record<
  ApiSeatState,
  {
    state: string
    desc: string
  }
> => {
  const {t} = useTranslation()

  // Note: without enumerating all it is tricky to correctly type code at some places,
  // without fallback to `any`.
  // TODO: move to some more general "common" file once used for "cash" module.
  return {
    [ApiSeatState.Available]: {
      state: t('Available'),
      desc: t('It is possible to click on seat in online and POS')
    },
    [ApiSeatState.Disabled]: {
      state: t('Disabled'),
      desc: t(
        "Seats can't be added to cart and are visible for cashiers and customers."
      )
    },
    [ApiSeatState.Hidden]: {
      state: t('Hidden'),
      desc: t(
        "Seats can't be added to cart and are visible for cashiers but hidden for customers."
      )
    },
    [ApiSeatState.SelectedByOthers]: {
      state: t('Selected by others'),
      desc: t('TODO ...')
    },
    [ApiSeatState.SelectedByMe]: {
      state: t('Selected by me'),
      desc: t('TODO ...')
    },
    [ApiSeatState.SelectedByOthers]: {
      state: t('Selected by others'),
      desc: t('TODO ...')
    },
    [ApiSeatState.AddedToMyCart]: {
      desc: 'AddedToMyCart',
      state: 'AddedToMyCart'
    },
    [ApiSeatState.AddedToOtherCart]: {
      desc: 'AddedToOtherCart',
      state: 'AddedToOtherCart'
    },
    [ApiSeatState.InMyReservation]: {
      desc: 'InMyReservation',
      state: 'InMyReservation'
    },
    [ApiSeatState.InOtherReservation]: {
      desc: 'InOtherReservation',
      state: 'InOtherReservation'
    },
    [ApiSeatState.ReservedInMyCart]: {
      desc: 'ReservedInMyCart',
      state: 'ReservedInMyCart'
    },
    [ApiSeatState.PreSold]: {desc: 'PreSold desc', state: 'PreSold'},
    [ApiSeatState.Sold]: {desc: 'Sold desc', state: 'Sold'}
  }
}

export const STATES_WITH_INFO = [
  ApiSeatState.Available,
  ApiSeatState.Disabled,
  ApiSeatState.Hidden
]
