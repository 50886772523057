import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {Button, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AdmissionRatesQuery,
  AdmissionRateState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useTranslateAdmissionRateState} from '../../../../../hooks/admissionRateState'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useTourParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../../common'
import {CreateFab, useFabClasses} from '../../../../common/Buttons'
import {ColorBox} from '../../../../common/ColorBox'
import {DataGridTable} from '../../../../common/DataGridTable'
import {admissionRateStateColors} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {WideCenteredLayout} from '../../Layout'
import {useAdmissionRates} from '../graphql'
import {CopyAdmissionRateDialog} from './CopyAdmissionRateDialog'
import {CreateAdmissionRateDrawer} from './CreateAdmissionRateDrawer'

const StateCellRenderer = ({state}: {state: AdmissionRateState}) => {
  const translateAdmissionRateState = useTranslateAdmissionRateState()
  return (
    <EntityStateChip
      label={translateAdmissionRateState(state)}
      colorConf={admissionRateStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {tourId} = useTourParams()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.tours.admissionRateDetail(tourId, id)),
    [history, id, tourId]
  )
  return P([
    PermissionCode.ReadAdmissionRate,
    PermissionCode.UpdateAdmissionRate
  ]) ? (
    <IconButton size="small" onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  ) : null
}

const CopyIconCellRenderer = ({
  onCopyIconClick
}: {
  onCopyIconClick: () => void
}) => {
  return (
    <IconButton size="small" onClick={onCopyIconClick}>
      <ContentCopyIcon />
    </IconButton>
  )
}

interface IAdmissionRatesProps {
  tourId: number
}

export const AdmissionRates: React.FC<IAdmissionRatesProps> = ({
  tourId
}: IAdmissionRatesProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useAdmissionRates({tourId})
  const {
    state: isCreateAdmissionRateDrawerOpen,
    setTrue: openAdmissionRateGroupDrawer,
    setFalse: closeAdmissionRateGroupDrawer
  } = useBooleanState(false)
  const [selectedCopyAdmissionRate, setSelectedCopyAdmissionRate] =
    useState<AdmissionRatesQuery['admissionRates']['items'][number] | null>(
      null
    )
  const hasPermissionToCreateAdmissionRate = P([
    PermissionCode.CreateAdmissionRate
  ])
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'color',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: string}>
        ) {
          return <ColorBox hexColor={params.value} />
        },
        sortable: false,
        width: 32
      },
      {
        headerName: t('Name'),
        field: 'name',
        flex: 1
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: AdmissionRateState}>
        ) {
          return <StateCellRenderer state={params.value} />
        },
        flex: 0.5
      },
      {
        headerName: t('Abbreviation'),
        field: 'abbreviation',
        flex: 0.5
      },
      {
        headerName: t('Admissions'),
        field: 'admissionTypesCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5
      },
      {
        headerName: t('Description'),
        field: 'description',
        flex: 1
      },
      {
        headerName: '',
        field: 'copyIcon',
        valueGetter: ({id}) => id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <CopyIconCellRenderer
              onCopyIconClick={() => setSelectedCopyAdmissionRate(params.row)}
            />
          )
        },
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 32
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: number}>
        ) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 32
      }
    ],
    [t]
  )
  return (
    <RenderOnData<AdmissionRatesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading admission rates')}
      ignoreLoadingIfData
      dataCondition={(data) => Array.isArray(data.admissionRates.items)}
    >
      {({admissionRates}) => (
        <>
          <WideCenteredLayout
            sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}
          >
            {admissionRates.items.length > 0 ? (
              <DataGridTable
                columns={columns}
                rows={admissionRates.items}
                initialState={{
                  pinnedColumns: {
                    left: ['color', 'name'],
                    right: ['copyIcon', 'id']
                  }
                }}
                columnVisibilityModel={{
                  id: P([PermissionCode.ReadAdmissionRate]),
                  copyIcon: P([PermissionCode.CopyAdmissionRate])
                }}
                disableColumnMenu
              />
            ) : (
              <Blank
                title={t('No admission rates found')}
                description={t(
                  'Create and manage admission rates for tour. You can create multiple admission rates for several situations like summer and winter seasons, busy time slots, weekends and weekdays and many more. Try it by yourself.'
                )}
                actions={
                  hasPermissionToCreateAdmissionRate && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={openAdmissionRateGroupDrawer}
                    >
                      {t('Create')}
                    </Button>
                  )
                }
              />
            )}
          </WideCenteredLayout>
          {hasPermissionToCreateAdmissionRate &&
            admissionRates.items.length > 0 && (
              <CreateFab
                classes={fabClasses}
                onClick={openAdmissionRateGroupDrawer}
              />
            )}
          {hasPermissionToCreateAdmissionRate && (
            <CreateAdmissionRateDrawer
              isOpen={isCreateAdmissionRateDrawerOpen}
              onClose={closeAdmissionRateGroupDrawer}
              tourId={tourId}
            />
          )}
          {selectedCopyAdmissionRate && (
            <CopyAdmissionRateDialog
              onClose={() => setSelectedCopyAdmissionRate(null)}
              admissionRate={selectedCopyAdmissionRate}
            />
          )}
        </>
      )}
    </RenderOnData>
  )
}
