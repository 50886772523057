import React from 'react'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InputRow} from '../../../common'
import {SettingsPaper, SingleSetting} from '../../../common/Settings'
import {FormSwitchInput} from '../../../form/FormSwitchInput'
import {ExpandableTimeSelectSettings} from './TimeSelect'
import {
  ITourDivisionSettingsForm,
  TimeSelectOption,
  TourFormDivisionSettingsField
} from './types'

interface ITourPurchaseFormPartProps {
  control: FormContextValues<ITourDivisionSettingsForm>['control']
  watch: FormContextValues<ITourDivisionSettingsForm>['watch']
  errors: FormContextValues<ITourDivisionSettingsForm>['errors']
  register: FormContextValues<ITourDivisionSettingsForm>['register']
  setValue: FormContextValues<ITourDivisionSettingsForm>['setValue']
  triggerValidation: FormContextValues<ITourDivisionSettingsForm>['triggerValidation']
  unregister: FormContextValues<ITourDivisionSettingsForm>['unregister']
  clearError: FormContextValues<ITourDivisionSettingsForm>['clearError']
  setError: FormContextValues<ITourDivisionSettingsForm>['setError']
  defaultValues?: Partial<ITourDivisionSettingsForm>
}

export const TourPurchaseFormPart: React.FC<ITourPurchaseFormPartProps> = ({
  control,
  watch,
  register,
  errors,
  setValue,
  triggerValidation,
  unregister,
  clearError,
  setError,
  defaultValues
}: ITourPurchaseFormPartProps) => {
  const {t} = useTranslation()
  return (
    <SettingsPaper label={t('Tour sale defaults')}>
      <SingleSetting
        label={t('Ecommerce')}
        description={t(
          'Default option for each newly created tour. Changing this setting will not affect any existing tours.'
        )}
        sx={{py: 1}}
      >
        <InputRow
          nodes={[
            <FormSwitchInput<ITourDivisionSettingsForm>
              name={TourFormDivisionSettingsField.IsECommerceSaleActive}
              key={TourFormDivisionSettingsField.IsECommerceSaleActive}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.isECommerceSaleActive || false}
            />
          ]}
        />
      </SingleSetting>
      <ExpandableTimeSelectSettings
        errors={errors}
        watch={watch}
        register={register}
        setValue={setValue}
        triggerValidation={triggerValidation}
        control={control}
        unregister={unregister}
        setError={setError}
        clearError={clearError}
        options={[
          TimeSelectOption.EventStart,
          TimeSelectOption.AfterStart,
          TimeSelectOption.BeforeStart,
          TimeSelectOption.DayBeforeStart
        ]}
        name={TourFormDivisionSettingsField.ECommerceSalesEnd}
        title={t('Deadline to create last ecommerce order')}
        sx={{py: 1}}
      />
      <SingleSetting
        label={t('Retail')}
        description={t(
          'Default option for each newly created tour. Changing this setting will not affect any existing tours.'
        )}
        sx={{py: 1}}
      >
        <InputRow
          nodes={[
            <FormSwitchInput<ITourDivisionSettingsForm>
              name={TourFormDivisionSettingsField.IsRetailSaleActive}
              key={TourFormDivisionSettingsField.IsRetailSaleActive}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.isRetailSaleActive || false}
            />
          ]}
        />
      </SingleSetting>
      <ExpandableTimeSelectSettings
        errors={errors}
        watch={watch}
        register={register}
        setValue={setValue}
        triggerValidation={triggerValidation}
        control={control}
        unregister={unregister}
        setError={setError}
        clearError={clearError}
        options={[
          TimeSelectOption.EventStart,
          TimeSelectOption.AfterStart,
          TimeSelectOption.BeforeStart,
          TimeSelectOption.DayBeforeStart,
          TimeSelectOption.DayOfEvent
        ]}
        name={TourFormDivisionSettingsField.RetailSalesEnd}
        title={t('Deadline to create last retail order')}
        sx={{py: 1}}
      />
    </SettingsPaper>
  )
}
