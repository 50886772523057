import BookmarkIcon from '@mui/icons-material/BookmarkBorderOutlined'
import CalendarIcon from '@mui/icons-material/CalendarToday'
import StorefrontIcon from '@mui/icons-material/StorefrontOutlined'
import TourOutlinedIcon from '@mui/icons-material/TourOutlined'
import {Typography, useMediaQuery} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  CardRightArrowIcon,
  NavigationCard
} from '../../../common/NavigationCard'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {PrimaryHeader} from '../Header'
import {CenteredLayout} from '../Layout'

const useQuickNavigationGridStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 0)
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    [`@media ${MediaSizes.Phablet}`]: {
      gridTemplateColumns: 'auto'
    },
    gap: theme.spacing(2)
  }
}))

interface IQuickNavigationGridProps {
  children: React.ReactNode
  title: string
}

const QuickNavigationGrid: React.FC<IQuickNavigationGridProps> = ({
  title,
  children
}: IQuickNavigationGridProps) => {
  const classes = useQuickNavigationGridStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h6">{title}</Typography>
      <div className={classes.grid}>{children}</div>
    </div>
  )
}

const useDashboardStyles = makeStyles<Theme>((theme) => ({
  title: {
    paddingTop: theme.spacing(6)
  }
}))

export const Dashboard: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {user, effectiveClient} = useUserInfo()
  const history = useHistory()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const classes = useDashboardStyles()
  const fullName = [user?.firstName, user?.lastName].filter(Boolean).join(' ')
  const maxWidth = isPhablet ? '100%' : undefined
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={
            fullName
              ? t('Welcome, {{fullName}}', {
                  fullName
                })
              : t('Welcome')
          }
        />
      }
    >
      <CenteredLayout>
        <Typography variant="h5" className={classes.title}>
          {t('Quick navigation')}
        </Typography>
        {effectiveClient ? (
          <>
            <QuickNavigationGrid title={t('Shop')}>
              <>
                {P([PermissionCode.ReadReservations]) && (
                  <NavigationCard
                    icon={<BookmarkIcon />}
                    title={t<string>('Reservations')}
                    description={t<string>(
                      'Sell, update or delete early created reservations.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.cashDesk.reservations())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ReadCart]) && (
                  <NavigationCard
                    icon={<CalendarIcon />}
                    title={t<string>('Events')}
                    description={t<string>(
                      'Sell tickets for events or create reservations for them.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.cashDesk.events())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.IncrementTourItemQuantity]) && (
                  <NavigationCard
                    icon={<TourOutlinedIcon />}
                    title={t<string>('Tours')}
                    description={t<string>(
                      'Selling tickets for tours or create reservations for them.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.cashDesk.tours())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.IncrementProductItemQuantity]) && (
                  <NavigationCard
                    icon={<StorefrontIcon />}
                    title={t<string>('Shop')}
                    description={t<string>('Sell food, products and services.')}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.cashDesk.shop())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ReadCarts]) && (
                  <NavigationCard
                    title={t<string>('Carts')}
                    description={t<string>(
                      'Find already sold, reserved, ongoing or even unsuccessful carts.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.carts.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ReadClaims]) && (
                  <NavigationCard
                    title={t<string>('Claims')}
                    description={t<string>(
                      'Manage customers claims and refund them.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.claims.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.AccessCashCounter]) && (
                  <NavigationCard
                    title={t<string>('Cash counter')}
                    description={t<string>(
                      'Count all cash and vouchers at the end of the day.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.cashCounter())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ChangeDeviceSettings]) && (
                  <NavigationCard
                    title={t<string>('Device settings')}
                    description={t<string>(
                      'Update settings only for this device for all users.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.cashDesk.settings())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
              </>
            </QuickNavigationGrid>
            <QuickNavigationGrid title={t('Manage')}>
              <>
                {P([PermissionCode.ManageVenues]) && (
                  <NavigationCard
                    title={t<string>('Venues')}
                    description={t<string>(
                      'Manage your venues, auditoriums, auditorium layouts and pricing options.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.venues.home())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ManageClientShows]) && (
                  <NavigationCard
                    title={t<string>('Shows')}
                    description={t<string>(
                      'Fill out information about shows to better inform customers and your staff.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.shows.home())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ManageEvents]) && (
                  <NavigationCard
                    title={t<string>('Events')}
                    description={t<string>(
                      'Plan new events or edit settings for the existing ones.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.events.home())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ManageTours]) && (
                  <NavigationCard
                    title={t<string>('Tours')}
                    description={t<string>(
                      'Manage admission rates and schedule time slots.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.tours.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([PermissionCode.ReadDiscounts]) && (
                  <NavigationCard
                    title={t<string>('Discounts')}
                    description={t<string>(
                      'Create new offers with discounts or discount codes and get more customers.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.discounts.home())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([
                  PermissionCode.ManageWebsites,
                  PermissionCode.ReadWebsites
                ]) && (
                  <NavigationCard
                    title={t<string>('Websites')}
                    description={t<string>(
                      'Elevate, engage, and expand your online presence with a customizable, always-accessible website that boosts visibility and growth.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.websites.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([
                  PermissionCode.ManageProducts,
                  PermissionCode.ReadProducts
                ]) && (
                  <NavigationCard
                    title={t<string>('Products')}
                    description={t<string>(
                      'Manage products and services to grow your revenues.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.products.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([
                  PermissionCode.ManageMessages,
                  PermissionCode.ReadMessages
                ]) && (
                  <NavigationCard
                    title={t<string>('Messages')}
                    description={t<string>(
                      'Send message to customers about canceled or changed events, refunds, ...'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.messages.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
                {P([
                  PermissionCode.ManageVouchers,
                  PermissionCode.ReadVouchers
                ]) && (
                  <NavigationCard
                    title={t<string>('Vouchers')}
                    description={t<string>(
                      'Create and manage gift cards, vouchers, rewards or sorry coupons.'
                    )}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() =>
                          history.push(routeTo.admin.vouchers.index())
                        }
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )}
              </>
            </QuickNavigationGrid>
          </>
        ) : (
          <QuickNavigationGrid title={t('Support')}>
            <>
              {P([PermissionCode.ReadCartsSupportUser]) && (
                <NavigationCard
                  title={t<string>('Carts for support')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.cartsSupport.index())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
              {P([
                PermissionCode.ReadPayments,
                PermissionCode.ReadPaymentsForSupport
              ]) && (
                <NavigationCard
                  title={t<string>('Payments for support')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.paymentsSupport.index())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
              {P([PermissionCode.ReadClients]) && (
                <NavigationCard
                  title={t<string>('Clients')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.clients.home())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
              {P([PermissionCode.ManageRoles]) && (
                <NavigationCard
                  title={t<string>('Roles')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.roles.index())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
              {P([PermissionCode.ManageUsers]) && (
                <NavigationCard
                  title={t<string>('Users')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.users.home())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
              {P([PermissionCode.ReadLibraryShows]) && (
                <NavigationCard
                  title={t<string>('Library')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.library.home())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
              {P([PermissionCode.ManageTemplates]) && (
                <NavigationCard
                  title={t<string>('Templates')}
                  rightAction={
                    <CardRightArrowIcon
                      onArrowClick={() =>
                        history.push(routeTo.admin.templates.index())
                      }
                    />
                  }
                  maxWidth={maxWidth}
                />
              )}
            </>
          </QuickNavigationGrid>
        )}
      </CenteredLayout>
    </PageWithHeaderTemplate>
  )
}
