import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {Button, IconButton, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {MouseEvent, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Gallery from 'react-photo-gallery'
import {useHistory} from 'react-router-dom'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import {
  ClientShowImagePropertiesFragment,
  LibraryShowImagePropertiesFragment,
  PermissionCode,
  ShowImageType
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  BaseGalleryImage,
  useBaseGalleryImageButtonStyles
} from '../../../common/BaseGalleryImage'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {
  GalleryPreview,
  useGalleryPreview
} from '../../../common/galleryPreview/GalleryPreview'
import {HeaderActionType} from '../../../common/header/types'
import {ShowSection, useTranslateShowImageType} from '../../../constants'
import {MediaPreviewHeader} from '../Header'
import {getGalleryPhotos} from './common'
import {
  useDeleteClientShowImage,
  useUpdateClientShowImageOrderPosition
} from './graphql'
import {ImageAccordion} from './ImageAccordion'

const useStyles = makeStyles<Theme>((theme) => ({
  detailsTopRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  gallery: {
    paddingTop: theme.spacing(1),
    width: '100%'
  }
}))

interface IShowImageTypeAccordionProps {
  clientShowImages: ClientShowImagePropertiesFragment[]
  libraryShowImages: LibraryShowImagePropertiesFragment[]
  type: ShowImageType
  showId: number
  refetch?: () => void
}

export const ShowImageTypeAccordion: React.FC<IShowImageTypeAccordionProps> = ({
  clientShowImages,
  libraryShowImages,
  type,
  showId,
  refetch
}: IShowImageTypeAccordionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateShowImageType = useTranslateShowImageType()
  const deleteClientShowImage = useDeleteClientShowImage()
  const updateClientShowImageOrderPosition =
    useUpdateClientShowImageOrderPosition()
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const history = useHistory()
  const [expanded, setExpanded] = useState<ShowImageType | null>(null)
  const {
    state: isDeleteDialogOpen,
    setTrue: openDeleteDialog,
    setFalse: closeDeleteDialog
  } = useBooleanState(false)
  const [clickedImageId, setClickedImageId] = useState<number | null>(null)
  const handleAccordionChange = useCallback(
    (type: ShowImageType) => (e: React.ChangeEvent<{}>, isExpanded: boolean) =>
      setExpanded(isExpanded ? type : null),
    []
  )
  const galleryPreviewPhotos = useMemo(
    () =>
      clientShowImages.map(({url: src, id}) => ({
        src,
        id
      })),
    [clientShowImages]
  )
  const galleryPreviewHookProps = useGalleryPreview(galleryPreviewPhotos)
  const getOpenPreviewHandler = useCallback(
    (id: number) => (e: MouseEvent) => {
      e.stopPropagation()
      galleryPreviewHookProps.openPreview(id)
    },
    [galleryPreviewHookProps]
  )
  const currentImage = clientShowImages.find(
    (i) => i.id === galleryPreviewHookProps.currentPhotoId
  )
  const handleDeleteButtonClick = useCallback(
    (id: number) => (e: MouseEvent) => {
      e.stopPropagation()
      setClickedImageId(id)
      openDeleteDialog()
    },
    [openDeleteDialog]
  )
  const handleDeleteImage = useCallback(
    (id: number) => async () => {
      try {
        setShowBackdrop(true)
        await deleteClientShowImage({id})
        if (refetch) {
          await refetch()
        }
        addInfoNotification(t('Image deleted'))
        closeDeleteDialog()
      } catch (error) {
        defaultErrorHandler(error, t('Error while deleting client show image'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDeleteDialog,
      defaultErrorHandler,
      deleteClientShowImage,
      refetch,
      setShowBackdrop,
      t
    ]
  )
  const buttonClasses = useBaseGalleryImageButtonStyles()
  const DragHandle = SortableHandle(() => (
    <IconButton
      className={cn(buttonClasses.button, buttonClasses.topLeft)}
      size="small"
    >
      <DragIndicatorIcon />
    </IconButton>
  ))
  const SortablePhoto = SortableElement(
    ({photo, margin}: {photo: any; margin: number}) => (
      <BaseGalleryImage
        openPreview={getOpenPreviewHandler(photo.id)}
        margin={margin}
        photo={photo}
      >
        {P([PermissionCode.DeleteClientShowImage]) && (
          <IconButton
            className={cn(buttonClasses.button, buttonClasses.topRight)}
            onClick={handleDeleteButtonClick(photo.id)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        )}
        <DragHandle />
      </BaseGalleryImage>
    )
  )
  const SortableGallery = SortableContainer(({photos}: {photos: any}) => (
    <Gallery
      margin={8}
      photos={photos}
      renderImage={({margin, photo, index}) => (
        <SortablePhoto
          margin={margin ? parseInt(margin, 10) : 8}
          photo={photo}
          index={index}
          key={index}
        />
      )}
    />
  ))
  const handleSortEnd = useCallback(
    async ({oldIndex, newIndex}) => {
      const image = clientShowImages[oldIndex]
      if (image && oldIndex !== newIndex) {
        try {
          setShowBackdrop(true)
          await updateClientShowImageOrderPosition({
            id: image.id,
            newIndexPosition: newIndex
          })
          addInfoNotification(t('Image order position updated'))
          if (refetch) {
            refetch()
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while updating client show image order position')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      clientShowImages,
      defaultErrorHandler,
      refetch,
      setShowBackdrop,
      t,
      updateClientShowImageOrderPosition
    ]
  )
  const classes = useStyles()
  return (
    <>
      <ImageAccordion
        key={type}
        isExpanded={type === expanded}
        onAccordionChange={handleAccordionChange(type)}
        summaryDescription={t('{{count}} items', {
          count: clientShowImages.length
        })}
        summaryTitle={translateShowImageType(type)}
      >
        <div className={classes.detailsTopRow}>
          <Typography variant="body2" color="textSecondary">
            {clientShowImages.length === 0 && libraryShowImages.length === 0
              ? t('No items have been found')
              : clientShowImages.length === 0 && libraryShowImages.length > 0
              ? t(
                  'No items have found. There are {{count}} available items in library',
                  {count: libraryShowImages.length}
                )
              : libraryShowImages.length > 0 &&
                t('There are {{count}} available items in library', {
                  count: libraryShowImages.length
                })}
          </Typography>
          <Button
            startIcon={<AddIcon />}
            variant="text"
            color="primary"
            onClick={() =>
              libraryShowImages.length === 0
                ? history.push(
                    routeTo.admin.shows.uploadMedia(
                      showId,
                      ShowSection.Media,
                      type
                    )
                  )
                : history.push(
                    routeTo.admin.shows.assignMediaFromLibrary(
                      showId,
                      ShowSection.Media,
                      type
                    )
                  )
            }
          >
            {t('Add')}
          </Button>
        </div>
        <div>
          <SortableGallery
            photos={getGalleryPhotos(clientShowImages)}
            onSortEnd={handleSortEnd}
            axis="xy"
            useDragHandle
          />
          <GalleryPreview
            {...galleryPreviewHookProps}
            header={
              <MediaPreviewHeader
                title={t('Preview')}
                hasArrowBackIcon
                onLeftActionClick={galleryPreviewHookProps.closePreview}
                rightActions={
                  currentImage && P([PermissionCode.DeleteClientShowImage])
                    ? [
                        {
                          type: HeaderActionType.Button,
                          onClick: handleDeleteButtonClick(currentImage.id),
                          label: t('Delete'),
                          Icon: DeleteIcon
                        }
                      ]
                    : []
                }
              />
            }
          />
        </div>
      </ImageAccordion>
      {clickedImageId && (
        <ConfirmationDialog
          title={t('Delete item?')}
          contentText={t('Are you sure you want to delete this item?')}
          confirmButtonLabel={t('Delete')}
          onConfirm={handleDeleteImage(clickedImageId)}
          onCancel={closeDeleteDialog}
          isOpen={isDeleteDialogOpen}
        />
      )}
    </>
  )
}
