import {maxBy} from 'lodash'

import {Currency} from '../../../../__generated__/schema'
import {TranslatedCurrency} from '../../../../hooks/translateCurrencies'
import {
  banknotesCzk,
  banknotesEur,
  banknotesGbp,
  banknotesHuf,
  banknotesPln,
  banknotesUsd,
  coinsCzk,
  coinsEur,
  coinsGbp,
  coinsHuf,
  coinsPln,
  coinsUsd
} from '../../../../utils/nominals'
import {
  CashCounterAction,
  CashCounterActionType,
  CashCounterPage,
  CashCounterRow,
  CashCounterRowType,
  ChangeCashCounterAction,
  DecrementCashCounterAction,
  IncrementCashCounterAction
} from './types'

export const supportedCurrencies: TranslatedCurrency[] = [
  Currency.Eur,
  Currency.Czk,
  Currency.Usd,
  Currency.Gbp,
  Currency.Huf,
  Currency.Pln
]

const defaultVoucherRows: CashCounterRow[] = []

const generateRow = (denominations: number[]): CashCounterRow[] =>
  denominations.map((denomination, index) => ({
    id: index + 1,
    denomination,
    count: 0
  }))

export const defaultCashCounterPageForEur: CashCounterPage = {
  currency: Currency.Eur,
  voucherRows: defaultVoucherRows,
  banknoteRows: generateRow(banknotesEur),
  coinRows: generateRow(coinsEur)
}

export const defaultCashCounterPageForCzk: CashCounterPage = {
  currency: Currency.Czk,
  voucherRows: defaultVoucherRows,
  banknoteRows: generateRow(banknotesCzk),
  coinRows: generateRow(coinsCzk)
}

export const defaultCashCounterPageForUsd: CashCounterPage = {
  currency: Currency.Usd,
  voucherRows: defaultVoucherRows,
  banknoteRows: generateRow(banknotesUsd),
  coinRows: generateRow(coinsUsd)
}

export const defaultCashCounterPageForGbp: CashCounterPage = {
  currency: Currency.Gbp,
  voucherRows: defaultVoucherRows,
  banknoteRows: generateRow(banknotesGbp),
  coinRows: generateRow(coinsGbp)
}

export const defaultCashCounterPageForHuf: CashCounterPage = {
  currency: Currency.Huf,
  voucherRows: defaultVoucherRows,
  banknoteRows: generateRow(banknotesHuf),
  coinRows: generateRow(coinsHuf)
}

export const defaultCashCounterPageForPln: CashCounterPage = {
  currency: Currency.Pln,
  voucherRows: defaultVoucherRows,
  banknoteRows: generateRow(banknotesPln),
  coinRows: generateRow(coinsPln)
}

export const getDefaultStateForCurrency = (currency?: Currency) => {
  switch (currency) {
    case Currency.Czk:
      return defaultCashCounterPageForCzk
    case Currency.Usd:
      return defaultCashCounterPageForUsd
    case Currency.Huf:
      return defaultCashCounterPageForHuf
    case Currency.Pln:
      return defaultCashCounterPageForPln
    case Currency.Gbp:
      return defaultCashCounterPageForGbp
    case Currency.Eur:
    default:
      return defaultCashCounterPageForEur
  }
}

const reduceRowsOfTypeByIncrementAction = (
  rows: CashCounterRow[],
  rowType: CashCounterRowType,
  payload: IncrementCashCounterAction['payload']
): CashCounterRow[] =>
  payload.row === rowType
    ? rows.map((row) =>
        row.id === payload.id
          ? {
              ...row,
              count: row.count + 1
            }
          : row
      )
    : rows

const reduceRowsOfTypeByDecrementAction = (
  rows: CashCounterRow[],
  rowType: CashCounterRowType,
  payload: DecrementCashCounterAction['payload']
): CashCounterRow[] =>
  payload.row === rowType
    ? rows.map((row) =>
        row.id === payload.id
          ? {
              ...row,
              count: row.count > 1 ? row.count - 1 : 0
            }
          : row
      )
    : rows

const reduceRowsOfTypeByChangeAction = (
  rows: CashCounterRow[],
  rowType: CashCounterRowType,
  payload: ChangeCashCounterAction['payload']
): CashCounterRow[] =>
  payload.row === rowType
    ? rows.map((row) =>
        row.id === payload.id
          ? {
              ...row,
              count:
                payload.inputValue && payload.inputValue > 0
                  ? payload.inputValue
                  : 0
            }
          : row
      )
    : rows

export const cashCounterPageReducer = (
  state = defaultCashCounterPageForEur,
  action: CashCounterAction
): CashCounterPage => {
  switch (action.type) {
    case CashCounterActionType.CurrencyChange:
      return getDefaultStateForCurrency(action.payload)
    case CashCounterActionType.Increment:
      return {
        ...state,
        banknoteRows: reduceRowsOfTypeByIncrementAction(
          state.banknoteRows,
          CashCounterRowType.BanknotesRow,
          action.payload
        ),
        coinRows: reduceRowsOfTypeByIncrementAction(
          state.coinRows,
          CashCounterRowType.CoinRow,
          action.payload
        ),
        voucherRows: reduceRowsOfTypeByIncrementAction(
          state.voucherRows,
          CashCounterRowType.VouchersRow,
          action.payload
        )
      }
    case CashCounterActionType.Decrement:
      return {
        ...state,
        banknoteRows: reduceRowsOfTypeByDecrementAction(
          state.banknoteRows,
          CashCounterRowType.BanknotesRow,
          action.payload
        ),
        coinRows: reduceRowsOfTypeByDecrementAction(
          state.coinRows,
          CashCounterRowType.CoinRow,
          action.payload
        ),
        voucherRows: reduceRowsOfTypeByDecrementAction(
          state.voucherRows,
          CashCounterRowType.VouchersRow,
          action.payload
        )
      }
    case CashCounterActionType.Change:
      return {
        ...state,
        banknoteRows: reduceRowsOfTypeByChangeAction(
          state.banknoteRows,
          CashCounterRowType.BanknotesRow,
          action.payload
        ),
        coinRows: reduceRowsOfTypeByChangeAction(
          state.coinRows,
          CashCounterRowType.CoinRow,
          action.payload
        ),
        voucherRows: reduceRowsOfTypeByChangeAction(
          state.voucherRows,
          CashCounterRowType.VouchersRow,
          action.payload
        )
      }
    case CashCounterActionType.AddVoucherRow:
      return {
        ...state,
        voucherRows: [
          ...state.voucherRows,
          {
            id: (maxBy(state.voucherRows, 'id')?.id || 0) + 1,
            denomination: 0,
            count: 0
          }
        ]
      }
    case CashCounterActionType.RemoveVoucherRow:
      return {
        ...state,
        voucherRows: state.voucherRows.filter(
          (row) => row.id !== action.payload
        )
      }
    case CashCounterActionType.ChangeVoucherDenomination:
      return {
        ...state,
        voucherRows: state.voucherRows.map((row) =>
          row.id === action.payload.id
            ? {
                ...row,
                denomination:
                  action.payload.value > 0 ? action.payload.value : 0
              }
            : row
        )
      }
    default:
      return state
  }
}
