import {
  formControlClasses,
  formLabelClasses,
  inputBaseClasses,
  inputLabelClasses,
  outlinedInputClasses,
  Theme as MuiTheme
} from '@mui/material'
import {createTheme} from '@mui/material/styles'
import {gridClasses} from '@mui/x-data-grid-pro'
//@ts-ignore
import {} from '@mui/x-data-grid-pro/themeAugmentation'

declare module '@mui/styles' {
  interface DefaultTheme extends MuiTheme {}
}

export type Theme = MuiTheme

export const PRIMARY_100_COLOR = '#E1BEE7'
export const PRIMARY_50_COLOR = '#FEF6FF'
export const NEUTRAL_900_COLOR = '#212121'
export const NEUTRAL_100_COLOR = '#F5F5F5'

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#6A1B9A',
      dark: '#38006B',
      light: '#9C4DCC',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#FFD600',
      dark: '#C7A500',
      light: '#FFFF52',
      contrastText: '#38006B'
    },
    divider: '#E0E0E0',
    background: {
      default: '#fafafa'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
        }
        body {
          font-size: 0.875rem;
          line-height: 1.43;
          letter-spacing: 0.01071em;
        }
      `
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large'
      }
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontWeight: 500
        },
        overline: {
          lineHeight: '16px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '12.5px 14px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          [`&.${outlinedInputClasses.input}`]: {
            padding: 0
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          [`& .${outlinedInputClasses.input}`]: {
            padding: '8.5px 14px'
          },
          [`& .${formLabelClasses.root}.${inputLabelClasses.animated}:not(.${inputLabelClasses.shrink})`]:
            {
              transform: 'translate(14px, 9px) scale(1)'
            }
        },
        root: {
          [`& .${formLabelClasses.root}.${inputLabelClasses.animated}:not(.${inputLabelClasses.shrink})`]:
            {
              transform: 'translate(14px, 13px) scale(1)'
            }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`& .${formControlClasses.root} .${formLabelClasses.root}.${inputLabelClasses.animated}:not(.${inputLabelClasses.shrink})`]:
            {
              transform: 'translate(14px, 9px) scale(1)'
            },
          [`& .${inputBaseClasses.root}`]: {
            paddingTop: 5,
            paddingBottom: 5
          },
          [`& .${formControlClasses.marginDense} .${outlinedInputClasses.input}`]:
            {
              paddingTop: 3.5,
              paddingBottom: 3.5
            }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.palette.divider,
          cursor: ownerState?.onRowClick ? 'pointer' : 'auto',
          [`& .${gridClasses.columnHeaderTitle}`]: {
            ...theme.typography.subtitle2,
            color: theme.palette.text.secondary
          },
          [`& .${gridClasses.columnHeaders}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`
          },
          [`& .${gridClasses.footerContainer}`]: {
            borderTop: `1px solid ${theme.palette.divider}`
          },
          [`& .${gridClasses.cell}`]: {
            borderBottom: `1px solid ${theme.palette.divider}`
          },
          [`& .${gridClasses.row}`]: {
            ...theme.typography.body2
          },
          [`& .${gridClasses.cell}:focus-within, & .${gridClasses.cell}:focus`]:
            {
              outline: 'none'
            },
          [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.columnHeader}:focus`]:
            {
              outline: 'none'
            },
          [`& .${gridClasses.booleanCell}[data-value="true"]`]: {
            color: theme.palette.primary.main
          }
        })
      }
    }
  }
})
