import {makeStyles} from '@mui/styles'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, useHistory} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState,
  DetailClientPropertiesFragment
} from '../../../../../__generated__/schema'
import {useElementDimensions} from '../../../../../hooks/dimensions'
import {useUserInfo} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {usePostDisplayBaseViewMessageOnInit} from '../../customerDisplay/postDisplayBaseViewMessageOnInit'
import {SecondaryHeader} from '../../Header'
import {useCurrentCart} from '../CurrentCartContext'
import {Core} from './Core'

import {Supplement} from './Supplement'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  grid: {
    display: 'grid',
    height: '100%',
    overflow: 'hidden',
    gridTemplateColumns: '360px 1fr'
  }
}))

interface ICartContentProps {
  cart: CartPropertiesFragment
  effectiveClient: DetailClientPropertiesFragment
}

export const CartContent: React.FC<ICartContentProps> = ({
  cart,
  effectiveClient
}: ICartContentProps) => {
  const classes = useStyles()
  const [ref, {width}] = useElementDimensions()
  return (
    <div ref={ref} className={classes.root}>
      {width < 720 ? (
        <div>Thin page, implementation in progress</div>
      ) : (
        <div className={classes.grid}>
          <Supplement cart={cart} />
          <Core cart={cart} effectiveClient={effectiveClient!} />
        </div>
      )}
    </div>
  )
}

export const Cart: React.FC = () => {
  const {t} = useTranslation()
  const {effectiveClient} = useUserInfo()
  const {currentCart, refetchCurrentCart} = useCurrentCart()
  const history = useHistory()
  useEffect(() => {
    refetchCurrentCart()
  }, [refetchCurrentCart])
  usePostDisplayBaseViewMessageOnInit()
  return currentCart && currentCart.items?.length ? (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={t('Checkout')}
          isLeftActionIconDisabled={currentCart.state === CartState.Pending}
          isDatetimeEnabled
          onLeftActionClick={() => {
            history.replace(routeTo.admin.cashDesk.index())
          }}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <CartContent cart={currentCart} effectiveClient={effectiveClient!} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  ) : (
    <Redirect to={routeTo.admin.cashDesk.index()} />
  )
}
