import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DownloadIcon from '@mui/icons-material/Download'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  IconButton,
  ListItem,
  listItemSecondaryActionClasses,
  ListItemText,
  Menu,
  MenuList,
  SxProps,
  Typography
} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {Tooltip} from '../../../common'
import {MenuItem, useMenu} from '../../../common/Menu'
import {MenuItemWithConfirmationDialog} from '../../../common/MenuItemWithConfirmationDialog'
import {getFileExtension, isFileImage, shortenFileName} from './utils'

interface IMoreMenuProps {
  url: string
  fileName: string
  anchorEl: HTMLElement | null
  closeMenu: () => void
  onDelete?: (filename: string) => Promise<void>
}

const MoreMenu: React.FC<IMoreMenuProps> = ({
  url,
  fileName,
  anchorEl,
  closeMenu,
  onDelete
}: IMoreMenuProps) => {
  const {t} = useTranslation()
  const {addInfoNotification} = useMutationAssistanceHooks()
  const handleCopyUrlClick = useCallback(async () => {
    await navigator.clipboard.writeText(url)
    addInfoNotification(t('URL has been copied to clipboard'))
    closeMenu()
  }, [addInfoNotification, closeMenu, t, url])
  const handleDownloadClick = useCallback(() => {
    window.open(url, '_blank')
    closeMenu()
  }, [closeMenu, url])
  const handleDelete = useCallback(async () => {
    if (onDelete) {
      await onDelete(fileName)
      closeMenu()
    }
  }, [closeMenu, fileName, onDelete])
  return (
    <Menu
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <MenuList dense sx={{p: 0}}>
        <MenuItem
          label={t('Download')}
          icon={<DownloadIcon fontSize="small" />}
          onClick={handleDownloadClick}
        />
        <MenuItem
          label={t('Copy URL')}
          icon={<ContentCopyIcon fontSize="small" />}
          onClick={handleCopyUrlClick}
        />
        {onDelete && (
          <MenuItemWithConfirmationDialog
            key="delete-asset"
            onConfirmButtonClick={handleDelete}
            menuItemProps={{
              label: t('Delete'),
              icon: <DeleteOutlineIcon fontSize="small" />
            }}
            dialogProps={{
              title: t('Delete asset?'),
              contentText: t(
                'Are you sure you want to delete this asset? Once deleted, it will no longer be accessible. This action cannot be undone.'
              ),
              confirmButtonLabel: t('Delete')
            }}
          />
        )}
      </MenuList>
    </Menu>
  )
}

interface IFileTileProps {
  url: string
  fileName: string
  onDelete?: (filename: string) => Promise<void>
}

const FileTile: React.FC<IFileTileProps> = ({
  url,
  fileName,
  onDelete
}: IFileTileProps) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  return (
    <>
      <Box key={url} sx={{height: 118, width: 90}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 84,
              backgroundColor: 'grey.200',
              position: 'relative'
            }}
          >
            <IconButton
              onClick={openMenu}
              size="small"
              sx={(theme) => ({
                position: 'absolute',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.4)'
                },
                top: theme.spacing(0.5),
                right: theme.spacing(0.5)
              })}
            >
              <MoreVertIcon />
            </IconButton>
            {isFileImage(fileName) ? (
              <img src={url} style={{width: '100%', height: '100%'}} />
            ) : (
              <Box
                sx={{
                  width: '100',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'default'
                }}
              >
                <Typography variant="overline">
                  {getFileExtension(fileName)}
                </Typography>
              </Box>
            )}
          </Box>
          <Tooltip title={fileName}>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{cursor: 'default'}}
            >
              {shortenFileName(fileName)}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <MoreMenu
        url={url}
        closeMenu={closeMenu}
        anchorEl={anchorEl}
        fileName={fileName}
        onDelete={onDelete}
      />
    </>
  )
}

interface IFileListItemProps {
  url: string
  fileName: string
  onDelete?: (filename: string) => Promise<void>
}

const FileListItem: React.FC<IFileListItemProps> = ({
  url,
  fileName,
  onDelete
}: IFileListItemProps) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  return (
    <>
      <ListItem
        sx={{
          minHeight: 40,
          p: 0,
          borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
          '&:last-child': {
            borderBottom: 0
          },
          gap: 2,
          [`& .${listItemSecondaryActionClasses.root}`]: {
            right: 0
          }
        }}
        secondaryAction={
          <IconButton size="small" color="primary" onClick={openMenu}>
            <MoreVertIcon />
          </IconButton>
        }
      >
        <ListItemText
          sx={{overflowWrap: 'anywhere', maxWidth: '80%'}}
          primary={fileName}
          primaryTypographyProps={{variant: 'body1'}}
        />
      </ListItem>
      <MoreMenu
        url={url}
        closeMenu={closeMenu}
        anchorEl={anchorEl}
        fileName={fileName}
        onDelete={onDelete}
      />
    </>
  )
}

interface IFilesListProps {
  files: {
    fileName: string
    url: string
  }[]
  onDelete?: (filename: string) => Promise<void>
  sx?: SxProps<Theme>
  type?: 'list' | 'grid'
}

export const FilesList: React.FC<IFilesListProps> = ({
  files,
  onDelete,
  sx,
  type = 'grid'
}: IFilesListProps) => {
  return (
    <Box
      sx={{
        px: 2,
        ...(type === 'grid'
          ? {
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)'
            }
          : {
              display: 'flex',
              gap: 1,
              flexDirection: 'column'
            }),
        ...sx
      }}
    >
      {files.map(({fileName, url}) =>
        type === 'grid' ? (
          <FileTile
            key={fileName}
            fileName={fileName}
            url={url}
            onDelete={onDelete}
          />
        ) : (
          <FileListItem
            key={fileName}
            fileName={fileName}
            url={url}
            onDelete={onDelete}
          />
        )
      )}
    </Box>
  )
}
