import {makeStyles} from '@mui/styles'
import {TFunction} from 'i18next'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../theme'
import {MarkdownEditorBlockType} from './types'

export const useTextEditorStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(', ')
  },
  toolbar: {
    alignItems: 'center',
    border: '1px solid #C4C4C4',
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: theme.spacing(0.75, 1, 0.75, 0.5),
    margin: 0,
    backgroundColor: theme.palette.background.default
  },
  editor: {
    fontSize: 16,
    overflowWrap: 'break-word',
    letterSpacing: '0.15px',
    border: '1px solid #C4C4C4',
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    padding: theme.spacing(2),
    zIndex: 0
  },
  history: {
    margin: 0
  },
  colorPickerModal: {
    position: 'relative'
  },
  colorPickerWrapper: {
    position: 'absolute',
    top: 20,
    left: -30,
    zIndex: 1
  },
  dividerVertical: {
    width: 1,
    height: 24
  },
  stickyToolbar: {
    position: 'sticky',
    top: 0,
    zIndex: 1
  }
}))

export const editorLabels = (t: TFunction) => {
  return {
    'generic.add': t('Add'),
    'generic.cancel': t('Cancel'),
    'components.controls.link.linkTitle': t('Link Title'),
    'components.controls.link.linkTarget': t('Link Target'),
    'components.controls.link.linkTargetOption': t('Open link in new window'),
    'components.controls.link.link': t('Link'),
    'components.controls.link.unlink': t('Unlink')
  }
}

export const useTranslateMarkdownEditorBlockType = () => {
  const {t} = useTranslation()
  const translated: Record<MarkdownEditorBlockType, string> = {
    [MarkdownEditorBlockType.Normal]: t('MarkdownEditorBlockType->Normal'),
    [MarkdownEditorBlockType.H1]: t('MarkdownEditorBlockType->H1'),
    [MarkdownEditorBlockType.H2]: t('MarkdownEditorBlockType->H2'),
    [MarkdownEditorBlockType.H3]: t('MarkdownEditorBlockType->H3'),
    [MarkdownEditorBlockType.H4]: t('MarkdownEditorBlockType->H4'),
    [MarkdownEditorBlockType.H5]: t('MarkdownEditorBlockType->H5'),
    [MarkdownEditorBlockType.H6]: t('MarkdownEditorBlockType->H6'),
    [MarkdownEditorBlockType.Blockquote]: t(
      'MarkdownEditorBlockType->Blockquote'
    )
  }
  return (type: MarkdownEditorBlockType) => translated[type]
}

export const isMarkdownEditorBlockType = (
  item: any
): item is MarkdownEditorBlockType =>
  Object.values(MarkdownEditorBlockType).includes(item)
