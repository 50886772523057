import {useTranslation} from 'react-i18next'

import {ShowGenreCode} from '../__generated__/schema'
import {theme} from '../theme'

export const useTranslateGenre = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowGenreCode, string> = {
    [ShowGenreCode.Family]: t<string>('ShowGenreCode->Family'),
    [ShowGenreCode.Kids]: t<string>('ShowGenreCode->Kids'),
    [ShowGenreCode.Miscellaneous]: t<string>('ShowGenreCode->Miscellaneous'),
    [ShowGenreCode.FineArt]: t<string>('ShowGenreCode->FineArt'),
    [ShowGenreCode.Painting]: t<string>('ShowGenreCode->Painting'),
    [ShowGenreCode.Drawing]: t<string>('ShowGenreCode->Drawing'),
    [ShowGenreCode.Mosaic]: t<string>('ShowGenreCode->Mosaic'),
    [ShowGenreCode.Calligraphy]: t<string>('ShowGenreCode->Calligraphy'),
    [ShowGenreCode.Photography]: t<string>('ShowGenreCode->Photography'),
    [ShowGenreCode.Architecture]: t<string>('ShowGenreCode->Architecture'),
    [ShowGenreCode.Pottery]: t<string>('ShowGenreCode->Pottery'),
    [ShowGenreCode.Sculpture]: t<string>('ShowGenreCode->Sculpture'),
    [ShowGenreCode.Illusions]: t<string>('ShowGenreCode->Illusions'),
    [ShowGenreCode.Multimedia]: t<string>('ShowGenreCode->Multimedia'),
    [ShowGenreCode.Nature]: t<string>('ShowGenreCode->Nature'),
    [ShowGenreCode.Flora]: t<string>('ShowGenreCode->Flora'),
    [ShowGenreCode.Fauna]: t<string>('ShowGenreCode->Fauna'),
    [ShowGenreCode.ContemporaryArt]: t<string>(
      'ShowGenreCode->ContemporaryArt'
    ),
    [ShowGenreCode.Science]: t<string>('ShowGenreCode->Science'),
    [ShowGenreCode.Toys]: t<string>('ShowGenreCode->Toys'),
    [ShowGenreCode.Prehistory]: t<string>('ShowGenreCode->Prehistory'),
    [ShowGenreCode.Antiquity]: t<string>('ShowGenreCode->Antiquity'),
    [ShowGenreCode.MiddleAges]: t<string>('ShowGenreCode->MiddleAges'),
    [ShowGenreCode.ModernTimes]: t<string>('ShowGenreCode->ModernTimes'),
    [ShowGenreCode.Fashion]: t<string>('ShowGenreCode->Fashion'),
    [ShowGenreCode.StandupComedy]: t<string>('ShowGenreCode->StandupComedy'),
    [ShowGenreCode.FashionShow]: t<string>('ShowGenreCode->FashionShow'),
    [ShowGenreCode.Action]: t<string>('ShowGenreCode->Action'),
    [ShowGenreCode.Animation]: t<string>('ShowGenreCode->Animation'),
    [ShowGenreCode.Adult]: t<string>('ShowGenreCode->Adult'),
    [ShowGenreCode.Adventure]: t<string>('ShowGenreCode->Adventure'),
    [ShowGenreCode.Biography]: t<string>('ShowGenreCode->Biography'),
    [ShowGenreCode.Crime]: t<string>('ShowGenreCode->Crime'),
    [ShowGenreCode.Documentary]: t<string>('ShowGenreCode->Documentary'),
    [ShowGenreCode.Fantasy]: t<string>('ShowGenreCode->Fantasy'),
    [ShowGenreCode.Filmnoir]: t<string>('ShowGenreCode->Filmnoir'),
    [ShowGenreCode.History]: t<string>('ShowGenreCode->History'),
    [ShowGenreCode.Horror]: t<string>('ShowGenreCode->Horror'),
    [ShowGenreCode.Musical]: t<string>('ShowGenreCode->Musical'),
    [ShowGenreCode.Music]: t<string>('ShowGenreCode->Music'),
    [ShowGenreCode.Mystery]: t<string>('ShowGenreCode->Mystery'),
    [ShowGenreCode.Romance]: t<string>('ShowGenreCode->Romance'),
    [ShowGenreCode.Scify]: t<string>('ShowGenreCode->Scify'),
    [ShowGenreCode.Short]: t<string>('ShowGenreCode->Short'),
    [ShowGenreCode.Sport]: t<string>('ShowGenreCode->Sport'),
    [ShowGenreCode.Talkshow]: t<string>('ShowGenreCode->Talkshow'),
    [ShowGenreCode.Thriller]: t<string>('ShowGenreCode->Thriller'),
    [ShowGenreCode.War]: t<string>('ShowGenreCode->War'),
    [ShowGenreCode.Western]: t<string>('ShowGenreCode->Western'),
    [ShowGenreCode.Disaster]: t<string>('ShowGenreCode->Disaster'),
    [ShowGenreCode.Erotic]: t<string>('ShowGenreCode->Erotic'),
    [ShowGenreCode.RoadMovie]: t<string>('ShowGenreCode->RoadMovie'),
    [ShowGenreCode.SilentFilm]: t<string>('ShowGenreCode->SilentFilm'),
    [ShowGenreCode.Psychological]: t<string>('ShowGenreCode->Psychological'),
    [ShowGenreCode.BlackHumor]: t<string>('ShowGenreCode->BlackHumor'),
    [ShowGenreCode.Parody]: t<string>('ShowGenreCode->Parody'),
    [ShowGenreCode.Politics]: t<string>('ShowGenreCode->Politics'),
    [ShowGenreCode.Dance]: t<string>('ShowGenreCode->Dance'),
    [ShowGenreCode.Comedy]: t<string>('ShowGenreCode->Comedy'),
    [ShowGenreCode.Drama]: t<string>('ShowGenreCode->Drama'),
    [ShowGenreCode.Tragedy]: t<string>('ShowGenreCode->Tragedy'),
    [ShowGenreCode.Melodrama]: t<string>('ShowGenreCode->Melodrama'),
    [ShowGenreCode.Satire]: t<string>('ShowGenreCode->Satire'),
    [ShowGenreCode.Opera]: t<string>('ShowGenreCode->Opera'),
    [ShowGenreCode.Ballet]: t<string>('ShowGenreCode->Ballet'),
    [ShowGenreCode.Cabaret]: t<string>('ShowGenreCode->Cabaret'),
    [ShowGenreCode.Farce]: t<string>('ShowGenreCode->Farce'),
    [ShowGenreCode.Operetta]: t<string>('ShowGenreCode->Operetta'),
    [ShowGenreCode.Grotesque]: t<string>('ShowGenreCode->Grotesque'),
    [ShowGenreCode.Tragicomedy]: t<string>('ShowGenreCode->Tragicomedy'),
    [ShowGenreCode.Experimental]: t<string>('ShowGenreCode->Experimental'),
    [ShowGenreCode.Modern]: t<string>('ShowGenreCode->Modern'),
    [ShowGenreCode.Variety]: t<string>('ShowGenreCode->Variety'),
    [ShowGenreCode.Pantomime]: t<string>('ShowGenreCode->Pantomime'),
    [ShowGenreCode.Folklore]: t<string>('ShowGenreCode->Folklore'),
    [ShowGenreCode.Puppetry]: t<string>('ShowGenreCode->Puppetry'),
    [ShowGenreCode.Circus]: t<string>('ShowGenreCode->Circus'),
    [ShowGenreCode.Magic]: t<string>('ShowGenreCode->Magic'),
    [ShowGenreCode.Show]: t<string>('ShowGenreCode->Show'),
    [ShowGenreCode.Gala]: t<string>('ShowGenreCode->Gala'),
    [ShowGenreCode.Ball]: t<string>('ShowGenreCode->Ball'),
    [ShowGenreCode.Prom]: t<string>('ShowGenreCode->Prom'),
    [ShowGenreCode.TravestyShow]: t<string>('ShowGenreCode->TravestyShow'),
    [ShowGenreCode.Tasting]: t<string>('ShowGenreCode->Tasting'),
    [ShowGenreCode.Striptease]: t<string>('ShowGenreCode->Striptease'),
    [ShowGenreCode.Party]: t<string>('ShowGenreCode->Party'),
    [ShowGenreCode.Ceremony]: t<string>('ShowGenreCode->Ceremony'),
    [ShowGenreCode.Carnival]: t<string>('ShowGenreCode->Carnival'),
    [ShowGenreCode.Discoteque]: t<string>('ShowGenreCode->Discoteque'),
    [ShowGenreCode.Decade_20s]: t<string>('ShowGenreCode->Decade_20s'),
    [ShowGenreCode.Decade_30s]: t<string>('ShowGenreCode->Decade_30s'),
    [ShowGenreCode.Decade_40s]: t<string>('ShowGenreCode->Decade_40s'),
    [ShowGenreCode.Decade_50s]: t<string>('ShowGenreCode->Decade_50s'),
    [ShowGenreCode.Decade_60s]: t<string>('ShowGenreCode->Decade_60s'),
    [ShowGenreCode.Decade_70s]: t<string>('ShowGenreCode->Decade_70s'),
    [ShowGenreCode.Decade_80s]: t<string>('ShowGenreCode->Decade_80s'),
    [ShowGenreCode.Decade_90s]: t<string>('ShowGenreCode->Decade_90s'),
    [ShowGenreCode.BeautyContest]: t<string>('ShowGenreCode->BeautyContest'),
    [ShowGenreCode.Blues]: t<string>('ShowGenreCode->Blues'),
    [ShowGenreCode.Country]: t<string>('ShowGenreCode->Country'),
    [ShowGenreCode.Disco]: t<string>('ShowGenreCode->Disco'),
    [ShowGenreCode.BrassBand]: t<string>('ShowGenreCode->BrassBand'),
    [ShowGenreCode.Jazz]: t<string>('ShowGenreCode->Jazz'),
    [ShowGenreCode.ElectricBoogie]: t<string>('ShowGenreCode->ElectricBoogie'),
    [ShowGenreCode.ElectronicMusic]: t<string>(
      'ShowGenreCode->ElectronicMusic'
    ),
    [ShowGenreCode.Techno]: t<string>('ShowGenreCode->Techno'),
    [ShowGenreCode.Trance]: t<string>('ShowGenreCode->Trance'),
    [ShowGenreCode.Folk]: t<string>('ShowGenreCode->Folk'),
    [ShowGenreCode.Funk]: t<string>('ShowGenreCode->Funk'),
    [ShowGenreCode.Metal]: t<string>('ShowGenreCode->Metal'),
    [ShowGenreCode.Rock]: t<string>('ShowGenreCode->Rock'),
    [ShowGenreCode.RockAndRoll]: t<string>('ShowGenreCode->RockAndRoll'),
    [ShowGenreCode.Punk]: t<string>('ShowGenreCode->Punk'),
    [ShowGenreCode.Pop]: t<string>('ShowGenreCode->Pop'),
    [ShowGenreCode.Rap]: t<string>('ShowGenreCode->Rap'),
    [ShowGenreCode.HipHop]: t<string>('ShowGenreCode->HipHop'),
    [ShowGenreCode.Soul]: t<string>('ShowGenreCode->Soul'),
    [ShowGenreCode.Swing]: t<string>('ShowGenreCode->Swing'),
    [ShowGenreCode.RhythmAndBlues]: t<string>('ShowGenreCode->RhythmAndBlues'),
    [ShowGenreCode.Reggae]: t<string>('ShowGenreCode->Reggae'),
    [ShowGenreCode.House]: t<string>('ShowGenreCode->House'),
    [ShowGenreCode.Instrumental]: t<string>('ShowGenreCode->Instrumental'),
    [ShowGenreCode.ClassicalMusic]: t<string>('ShowGenreCode->ClassicalMusic'),
    [ShowGenreCode.ChamberMusic]: t<string>('ShowGenreCode->ChamberMusic'),
    [ShowGenreCode.WorldMusic]: t<string>('ShowGenreCode->WorldMusic'),
    [ShowGenreCode.ACappella]: t<string>('ShowGenreCode->ACappella'),
    [ShowGenreCode.Alternative]: t<string>('ShowGenreCode->Alternative'),
    [ShowGenreCode.ChansonFrancaise]: t<string>(
      'ShowGenreCode->ChansonFrancaise'
    ),
    [ShowGenreCode.Medieval]: t<string>('ShowGenreCode->Medieval'),
    [ShowGenreCode.Renaissance]: t<string>('ShowGenreCode->Renaissance'),
    [ShowGenreCode.NewAge]: t<string>('ShowGenreCode->NewAge'),
    [ShowGenreCode.Beatbox]: t<string>('ShowGenreCode->Beatbox'),
    [ShowGenreCode.Sing]: t<string>('ShowGenreCode->Sing'),
    [ShowGenreCode.ChoirSinging]: t<string>('ShowGenreCode->ChoirSinging'),
    [ShowGenreCode.Religious]: t<string>('ShowGenreCode->Religious'),
    [ShowGenreCode.Hockey]: t<string>('ShowGenreCode->Hockey'),
    [ShowGenreCode.Football]: t<string>('ShowGenreCode->Football'),
    [ShowGenreCode.Volleyball]: t<string>('ShowGenreCode->Volleyball'),
    [ShowGenreCode.Basketball]: t<string>('ShowGenreCode->Basketball'),
    [ShowGenreCode.Badminton]: t<string>('ShowGenreCode->Badminton'),
    [ShowGenreCode.Biathlon]: t<string>('ShowGenreCode->Biathlon'),
    [ShowGenreCode.BodyBuilding]: t<string>('ShowGenreCode->BodyBuilding'),
    [ShowGenreCode.Boxing]: t<string>('ShowGenreCode->Boxing'),
    [ShowGenreCode.Curling]: t<string>('ShowGenreCode->Curling'),
    [ShowGenreCode.Cycling]: t<string>('ShowGenreCode->Cycling'),
    [ShowGenreCode.Esports]: t<string>('ShowGenreCode->Esports'),
    [ShowGenreCode.FieldHockey]: t<string>('ShowGenreCode->FieldHockey'),
    [ShowGenreCode.Fitness]: t<string>('ShowGenreCode->Fitness'),
    [ShowGenreCode.Floorball]: t<string>('ShowGenreCode->Floorball'),
    [ShowGenreCode.Golf]: t<string>('ShowGenreCode->Golf'),
    [ShowGenreCode.Gymnastics]: t<string>('ShowGenreCode->Gymnastics'),
    [ShowGenreCode.Handball]: t<string>('ShowGenreCode->Handball'),
    [ShowGenreCode.IceSkating]: t<string>('ShowGenreCode->IceSkating'),
    [ShowGenreCode.MartialArts]: t<string>('ShowGenreCode->MartialArts'),
    [ShowGenreCode.Racing]: t<string>('ShowGenreCode->Racing'),
    [ShowGenreCode.Motosport]: t<string>('ShowGenreCode->Motosport'),
    [ShowGenreCode.Skiing]: t<string>('ShowGenreCode->Skiing'),
    [ShowGenreCode.SkiJumping]: t<string>('ShowGenreCode->SkiJumping'),
    [ShowGenreCode.Squash]: t<string>('ShowGenreCode->Squash'),
    [ShowGenreCode.Swimming]: t<string>('ShowGenreCode->Swimming'),
    [ShowGenreCode.TableTennis]: t<string>('ShowGenreCode->TableTennis'),
    [ShowGenreCode.Tennis]: t<string>('ShowGenreCode->Tennis'),
    [ShowGenreCode.Athletics]: t<string>('ShowGenreCode->Athletics'),
    [ShowGenreCode.Waterpolo]: t<string>('ShowGenreCode->Waterpolo'),
    [ShowGenreCode.Food]: t<string>('ShowGenreCode->Food'),
    [ShowGenreCode.Streetfood]: t<string>('ShowGenreCode->Streetfood'),
    [ShowGenreCode.Gastronomy]: t<string>('ShowGenreCode->Gastronomy'),
    [ShowGenreCode.Theatre]: t<string>('ShowGenreCode->Theatre'),
    [ShowGenreCode.Film]: t<string>('ShowGenreCode->Film'),
    [ShowGenreCode.Wine]: t<string>('ShowGenreCode->Wine'),
    [ShowGenreCode.Tradeshow]: t<string>('ShowGenreCode->Tradeshow'),
    [ShowGenreCode.Fair]: t<string>('ShowGenreCode->Fair'),
    [ShowGenreCode.Drinks]: t<string>('ShowGenreCode->Drinks'),
    [ShowGenreCode.Beer]: t<string>('ShowGenreCode->Beer'),
    [ShowGenreCode.Literature]: t<string>('ShowGenreCode->Literature'),
    [ShowGenreCode.JobFair]: t<string>('ShowGenreCode->JobFair'),
    [ShowGenreCode.Conference]: t<string>('ShowGenreCode->Conference'),
    [ShowGenreCode.Business]: t<string>('ShowGenreCode->Business'),
    [ShowGenreCode.Finance]: t<string>('ShowGenreCode->Finance'),
    [ShowGenreCode.Accounting]: t<string>('ShowGenreCode->Accounting'),
    [ShowGenreCode.Communication]: t<string>('ShowGenreCode->Communication'),
    [ShowGenreCode.PersonalDevelopment]: t<string>(
      'ShowGenreCode->PersonalDevelopment'
    ),
    [ShowGenreCode.Marketing]: t<string>('ShowGenreCode->Marketing'),
    [ShowGenreCode.Design]: t<string>('ShowGenreCode->Design'),
    [ShowGenreCode.Lifestyle]: t<string>('ShowGenreCode->Lifestyle'),
    [ShowGenreCode.Yoga]: t<string>('ShowGenreCode->Yoga'),
    [ShowGenreCode.Traveling]: t<string>('ShowGenreCode->Traveling'),
    [ShowGenreCode.Wellness]: t<string>('ShowGenreCode->Wellness'),
    [ShowGenreCode.Spa]: t<string>('ShowGenreCode->Spa'),
    [ShowGenreCode.Tourism]: t<string>('ShowGenreCode->Tourism'),
    [ShowGenreCode.Relax]: t<string>('ShowGenreCode->Relax'),
    [ShowGenreCode.Rafting]: t<string>('ShowGenreCode->Rafting'),
    [ShowGenreCode.EscapeRoom]: t<string>('ShowGenreCode->EscapeRoom'),
    [ShowGenreCode.Sightseeing]: t<string>('ShowGenreCode->Sightseeing'),
    [ShowGenreCode.Tour]: t<string>('ShowGenreCode->Tour'),
    [ShowGenreCode.Flight]: t<string>('ShowGenreCode->Flight'),
    [ShowGenreCode.Cruise]: t<string>('ShowGenreCode->Cruise'),
    [ShowGenreCode.Educational]: t<string>('ShowGenreCode->Educational'),
    [ShowGenreCode.Ride]: t<string>('ShowGenreCode->Ride'),
    [ShowGenreCode.Adrenaline]: t<string>('ShowGenreCode->Adrenaline'),
    [ShowGenreCode.Paintball]: t<string>('ShowGenreCode->Paintball'),
    [ShowGenreCode.Lasertag]: t<string>('ShowGenreCode->Lasertag'),
    [ShowGenreCode.Learning]: t<string>('ShowGenreCode->Learning'),
    [ShowGenreCode.Massage]: t<string>('ShowGenreCode->Massage')
  }
  return (code: ShowGenreCode) => translatedCodes[code]
}

export const useGetGenres = (): ShowGenreCode[] => {
  return Object.values(ShowGenreCode)
}

const colors: Record<ShowGenreCode, string> = {
  [ShowGenreCode.Family]: '#F2994A',
  [ShowGenreCode.Kids]: '#F2994A',
  [ShowGenreCode.Miscellaneous]: '#6A1B9A',
  [ShowGenreCode.FineArt]: '#6A1B9A',
  [ShowGenreCode.Painting]: '#6A1B9A',
  [ShowGenreCode.Drawing]: '#6A1B9A',
  [ShowGenreCode.Mosaic]: '#6A1B9A',
  [ShowGenreCode.Calligraphy]: '#6A1B9A',
  [ShowGenreCode.Photography]: '#6A1B9A',
  [ShowGenreCode.Architecture]: '#6A1B9A',
  [ShowGenreCode.Pottery]: '#6A1B9A',
  [ShowGenreCode.Sculpture]: '#6A1B9A',
  [ShowGenreCode.Illusions]: '#6A1B9A',
  [ShowGenreCode.Multimedia]: '#6A1B9A',
  [ShowGenreCode.Nature]: '#6A1B9A',
  [ShowGenreCode.Flora]: '#6A1B9A',
  [ShowGenreCode.Fauna]: '#6A1B9A',
  [ShowGenreCode.ContemporaryArt]: '#6A1B9A',
  [ShowGenreCode.Science]: '#6A1B9A',
  [ShowGenreCode.Toys]: '#6A1B9A',
  [ShowGenreCode.Prehistory]: '#6A1B9A',
  [ShowGenreCode.Antiquity]: '#6A1B9A',
  [ShowGenreCode.MiddleAges]: '#6A1B9A',
  [ShowGenreCode.ModernTimes]: '#6A1B9A',
  [ShowGenreCode.Fashion]: '#6A1B9A',
  [ShowGenreCode.StandupComedy]: '#6A1B9A',
  [ShowGenreCode.FashionShow]: '#6A1B9A',
  [ShowGenreCode.Action]: '#172578',
  [ShowGenreCode.Animation]: '#6c21de',
  [ShowGenreCode.Adult]: '#D13725',
  [ShowGenreCode.Adventure]: '#72C285',
  [ShowGenreCode.Biography]: '#D2A56C',
  [ShowGenreCode.Crime]: '#8B2E29',
  [ShowGenreCode.Documentary]: '#2B6977',
  [ShowGenreCode.Fantasy]: '#A493EF',
  [ShowGenreCode.Filmnoir]: '#777777',
  [ShowGenreCode.History]: '#CAAD68',
  [ShowGenreCode.Horror]: '#000000',
  [ShowGenreCode.Musical]: '#9B3737',
  [ShowGenreCode.Music]: '#B400CC',
  [ShowGenreCode.Mystery]: '#AE9CB6',
  [ShowGenreCode.Romance]: '#f250d4',
  [ShowGenreCode.Scify]: '#A04DDE',
  [ShowGenreCode.Short]: '#d0c1c1',
  [ShowGenreCode.Sport]: '#44d434',
  [ShowGenreCode.Talkshow]: '#3B1206',
  [ShowGenreCode.Thriller]: '#3d823a',
  [ShowGenreCode.War]: '#BDB574',
  [ShowGenreCode.Western]: '#894129',
  [ShowGenreCode.Disaster]: '#2c5322',
  [ShowGenreCode.Erotic]: '#f97b88',
  [ShowGenreCode.RoadMovie]: '#3b0000',
  [ShowGenreCode.SilentFilm]: '#CCCCCC',
  [ShowGenreCode.Psychological]: '#854047',
  [ShowGenreCode.BlackHumor]: '#2a0303',
  [ShowGenreCode.Parody]: '#6A1B9A',
  [ShowGenreCode.Politics]: '#6A1B9A',
  [ShowGenreCode.Dance]: '#6A1B9A',
  [ShowGenreCode.Comedy]: '#EB540F',
  [ShowGenreCode.Drama]: '#8D273A',
  [ShowGenreCode.Tragedy]: '#6A1B9A',
  [ShowGenreCode.Melodrama]: '#6A1B9A',
  [ShowGenreCode.Satire]: '#6A1B9A',
  [ShowGenreCode.Opera]: '#36517C',
  [ShowGenreCode.Ballet]: '#8EC7D1',
  [ShowGenreCode.Cabaret]: '#6A1B9A',
  [ShowGenreCode.Farce]: '#6A1B9A',
  [ShowGenreCode.Operetta]: '#6A1B9A',
  [ShowGenreCode.Grotesque]: '#6A1B9A',
  [ShowGenreCode.Tragicomedy]: '#6A1B9A',
  [ShowGenreCode.Experimental]: '#6A1B9A',
  [ShowGenreCode.Modern]: '#6A1B9A',
  [ShowGenreCode.Variety]: '#6A1B9A',
  [ShowGenreCode.Pantomime]: '#6A1B9A',
  [ShowGenreCode.Folklore]: '#6A1B9A',
  [ShowGenreCode.Puppetry]: '#6A1B9A',
  [ShowGenreCode.Circus]: '#6A1B9A',
  [ShowGenreCode.Magic]: '#6A1B9A',
  [ShowGenreCode.Show]: '#6A1B9A',
  [ShowGenreCode.Gala]: '#6A1B9A',
  [ShowGenreCode.Ball]: '#6A1B9A',
  [ShowGenreCode.Prom]: '#6A1B9A',
  [ShowGenreCode.TravestyShow]: '#6A1B9A',
  [ShowGenreCode.Tasting]: '#6A1B9A',
  [ShowGenreCode.Striptease]: '#6A1B9A',
  [ShowGenreCode.Party]: '#6A1B9A',
  [ShowGenreCode.Ceremony]: '#6A1B9A',
  [ShowGenreCode.Carnival]: '#6A1B9A',
  [ShowGenreCode.Discoteque]: '#6A1B9A',
  [ShowGenreCode.Decade_20s]: '#6A1B9A',
  [ShowGenreCode.Decade_30s]: '#6A1B9A',
  [ShowGenreCode.Decade_40s]: '#6A1B9A',
  [ShowGenreCode.Decade_50s]: '#6A1B9A',
  [ShowGenreCode.Decade_60s]: '#6A1B9A',
  [ShowGenreCode.Decade_70s]: '#6A1B9A',
  [ShowGenreCode.Decade_80s]: '#6A1B9A',
  [ShowGenreCode.Decade_90s]: '#6A1B9A',
  [ShowGenreCode.BeautyContest]: '#6A1B9A',
  [ShowGenreCode.Blues]: '#6A1B9A',
  [ShowGenreCode.Country]: '#6A1B9A',
  [ShowGenreCode.Disco]: '#6A1B9A',
  [ShowGenreCode.BrassBand]: '#6A1B9A',
  [ShowGenreCode.Jazz]: '#6A1B9A',
  [ShowGenreCode.ElectricBoogie]: '#6A1B9A',
  [ShowGenreCode.ElectronicMusic]: '#6A1B9A',
  [ShowGenreCode.Techno]: '#6A1B9A',
  [ShowGenreCode.Trance]: '#6A1B9A',
  [ShowGenreCode.Folk]: '#6A1B9A',
  [ShowGenreCode.Funk]: '#6A1B9A',
  [ShowGenreCode.Metal]: '#6A1B9A',
  [ShowGenreCode.Rock]: '#6A1B9A',
  [ShowGenreCode.RockAndRoll]: '#6A1B9A',
  [ShowGenreCode.Punk]: '#6A1B9A',
  [ShowGenreCode.Pop]: '#6A1B9A',
  [ShowGenreCode.Rap]: '#6A1B9A',
  [ShowGenreCode.HipHop]: '#6A1B9A',
  [ShowGenreCode.Soul]: '#6A1B9A',
  [ShowGenreCode.Swing]: '#6A1B9A',
  [ShowGenreCode.RhythmAndBlues]: '#6A1B9A',
  [ShowGenreCode.Reggae]: '#6A1B9A',
  [ShowGenreCode.House]: '#6A1B9A',
  [ShowGenreCode.Instrumental]: '#6A1B9A',
  [ShowGenreCode.ClassicalMusic]: '#6A1B9A',
  [ShowGenreCode.ChamberMusic]: '#6A1B9A',
  [ShowGenreCode.WorldMusic]: '#6A1B9A',
  [ShowGenreCode.ACappella]: '#6A1B9A',
  [ShowGenreCode.Alternative]: '#6A1B9A',
  [ShowGenreCode.ChansonFrancaise]: '#6A1B9A',
  [ShowGenreCode.Medieval]: '#6A1B9A',
  [ShowGenreCode.Renaissance]: '#6A1B9A',
  [ShowGenreCode.NewAge]: '#6A1B9A',
  [ShowGenreCode.Beatbox]: '#6A1B9A',
  [ShowGenreCode.Sing]: '#6A1B9A',
  [ShowGenreCode.ChoirSinging]: '#6A1B9A',
  [ShowGenreCode.Religious]: '#6A1B9A',
  [ShowGenreCode.Hockey]: '#6A1B9A',
  [ShowGenreCode.Football]: '#6A1B9A',
  [ShowGenreCode.Volleyball]: '#6A1B9A',
  [ShowGenreCode.Basketball]: '#6A1B9A',
  [ShowGenreCode.Badminton]: '#6A1B9A',
  [ShowGenreCode.Biathlon]: '#6A1B9A',
  [ShowGenreCode.BodyBuilding]: '#6A1B9A',
  [ShowGenreCode.Boxing]: '#6A1B9A',
  [ShowGenreCode.Curling]: '#6A1B9A',
  [ShowGenreCode.Cycling]: '#6A1B9A',
  [ShowGenreCode.Esports]: '#6A1B9A',
  [ShowGenreCode.FieldHockey]: '#6A1B9A',
  [ShowGenreCode.Fitness]: '#6A1B9A',
  [ShowGenreCode.Floorball]: '#6A1B9A',
  [ShowGenreCode.Golf]: '#6A1B9A',
  [ShowGenreCode.Gymnastics]: '#6A1B9A',
  [ShowGenreCode.Handball]: '#6A1B9A',
  [ShowGenreCode.IceSkating]: '#6A1B9A',
  [ShowGenreCode.MartialArts]: '#6A1B9A',
  [ShowGenreCode.Racing]: '#6A1B9A',
  [ShowGenreCode.Motosport]: '#6A1B9A',
  [ShowGenreCode.Skiing]: '#6A1B9A',
  [ShowGenreCode.SkiJumping]: '#6A1B9A',
  [ShowGenreCode.Squash]: '#6A1B9A',
  [ShowGenreCode.Swimming]: '#6A1B9A',
  [ShowGenreCode.TableTennis]: '#6A1B9A',
  [ShowGenreCode.Tennis]: '#6A1B9A',
  [ShowGenreCode.Athletics]: '#6A1B9A',
  [ShowGenreCode.Waterpolo]: '#6A1B9A',
  [ShowGenreCode.Food]: '#6A1B9A',
  [ShowGenreCode.Streetfood]: '#6A1B9A',
  [ShowGenreCode.Gastronomy]: '#6A1B9A',
  [ShowGenreCode.Theatre]: '#6A1B9A',
  [ShowGenreCode.Film]: '#6A1B9A',
  [ShowGenreCode.Wine]: '#6A1B9A',
  [ShowGenreCode.Tradeshow]: '#6A1B9A',
  [ShowGenreCode.Fair]: '#6A1B9A',
  [ShowGenreCode.Drinks]: '#6A1B9A',
  [ShowGenreCode.Beer]: '#6A1B9A',
  [ShowGenreCode.Literature]: '#6A1B9A',
  [ShowGenreCode.JobFair]: '#6A1B9A',
  [ShowGenreCode.Conference]: '#6A1B9A',
  [ShowGenreCode.Business]: '#6A1B9A',
  [ShowGenreCode.Finance]: '#6A1B9A',
  [ShowGenreCode.Accounting]: '#6A1B9A',
  [ShowGenreCode.Communication]: '#6A1B9A',
  [ShowGenreCode.PersonalDevelopment]: '#6A1B9A',
  [ShowGenreCode.Marketing]: '#6A1B9A',
  [ShowGenreCode.Design]: '#6A1B9A',
  [ShowGenreCode.Lifestyle]: '#6A1B9A',
  [ShowGenreCode.Yoga]: '#6A1B9A',
  [ShowGenreCode.Traveling]: '#6A1B9A',
  [ShowGenreCode.Wellness]: '#6A1B9A',
  [ShowGenreCode.Spa]: '#6A1B9A',
  [ShowGenreCode.Tourism]: '#6A1B9A',
  [ShowGenreCode.Relax]: '#6A1B9A',
  [ShowGenreCode.Rafting]: '#6A1B9A',
  [ShowGenreCode.EscapeRoom]: '#6A1B9A',
  [ShowGenreCode.Sightseeing]: '#6A1B9A',
  [ShowGenreCode.Tour]: '#6A1B9A',
  [ShowGenreCode.Flight]: '#6A1B9A',
  [ShowGenreCode.Cruise]: '#6A1B9A',
  [ShowGenreCode.Educational]: '#6A1B9A',
  [ShowGenreCode.Ride]: '#6A1B9A',
  [ShowGenreCode.Adrenaline]: '#6A1B9A',
  [ShowGenreCode.Paintball]: '#6A1B9A',
  [ShowGenreCode.Lasertag]: '#6A1B9A',
  [ShowGenreCode.Learning]: '#6A1B9A',
  [ShowGenreCode.Massage]: '#6A1B9A'
}
export const getGenreColor = (code: ShowGenreCode) => colors[code]

export const getGenreTextColor = (code?: ShowGenreCode) =>
  code ? getGenreColor(code) : theme.palette.primary.main

export const getGenreBorderColor = (code?: ShowGenreCode) =>
  code ? getGenreColor(code) : theme.palette.primary.main
