import {Box, Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {UserPropertiesFragment} from '../../../../../__generated__/schema'

import {Theme} from '../../../../../theme'
import {BottomBackButton, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {PrimaryHeader} from '../../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {StateActionsBar} from '../StateActionsBar'

import {
  IUserFormData,
  UserForm,
  userToUserForm,
  useUserFormAnchors
} from '../UserForm'

const EDIT_PROFILE_FORM_ID = 'editProfileForm'

const useStyles = makeStyles<Theme>((theme) => ({
  userFormWrapper: {
    marginTop: theme.spacing(6)
  }
}))

interface IUserInfoProps {
  user: UserPropertiesFragment
  onSubmit: (data: IUserFormData) => Promise<void>
  hideRoles?: boolean
}

export const UserInfo: React.FC<IUserInfoProps> = ({
  user,
  onSubmit,
  hideRoles
}: IUserInfoProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const userFormAnchors = useUserFormAnchors(hideRoles)

  return (
    <PageWithHeaderAndFooterTemplate
      header={<PrimaryHeader title={t('My profile')} />}
      footer={
        <Footer>
          <BottomBackButton />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={EDIT_PROFILE_FORM_ID}
          >
            {t('Save')}
          </Button>
        </Footer>
      }
    >
      <CenteredLayout>
        <SingleSideNavigationList items={userFormAnchors} />
        <CenteredLayoutListWrapper>
          <StateActionsBar user={user} />
          <Box className={classes.userFormWrapper}>
            <UserForm
              formId={EDIT_PROFILE_FORM_ID}
              defaultValues={userToUserForm(user)}
              {...{onSubmit, hideRoles}}
            />
          </Box>
        </CenteredLayoutListWrapper>
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
