import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {Chip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../theme'
import {DaterangePicker} from '../DaterangePicker'
import {DATERANGE_IDS} from './types'

interface IOptionChipProps<T> {
  label: string
  icon?: React.ReactElement
  onClick(id: T): void
  id: T
  isActive?: boolean
  className?: string
}

export const useChipSpacingStyles = makeStyles<Theme>((theme) => ({
  chip: {
    margin: theme.spacing(0, 1, 1, 0)
  },
  chipBox: {
    marginBottom: -theme.spacing(1)
  }
}))

const useStyles = makeStyles<Theme, {hasIcon: boolean}>((theme) => ({
  chip: {
    paddingLeft: ({hasIcon}) => (hasIcon ? theme.spacing(1) : 0)
  },
  chipBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconSmall: {
    width: theme.typography.body2.fontSize
  }
}))

export const OptionChip = <T extends number | string>({
  isActive,
  icon,
  id,
  label,
  onClick,
  className
}: IOptionChipProps<T>) => {
  const classes = useStyles({hasIcon: !!icon})
  const onChipClick = useCallback(() => {
    onClick(id)
  }, [id, onClick])
  return (
    <Chip
      classes={{
        iconSmall: classes.iconSmall
      }}
      className={cn(classes.chip, className)}
      variant={isActive ? 'outlined' : 'filled'}
      color={isActive ? 'primary' : 'default'}
      icon={icon}
      onClick={onChipClick}
      label={label}
    />
  )
}

export interface IDaterangeChip {
  onSubmit(startDate: Date, endDate: Date): void
  dialogTitle: string
  dialogDescription?: string
  isActive?: boolean
  className?: string
}

export const DaterangeChip: React.FC<IDaterangeChip> = ({
  onSubmit,
  dialogTitle,
  dialogDescription,
  isActive,
  className
}: IDaterangeChip) => {
  const {t} = useTranslation()
  return (
    <DaterangePicker
      onSubmit={onSubmit}
      dialogTitle={dialogTitle}
      dialogDescription={dialogDescription}
    >
      {(openDialog) => (
        <OptionChip
          className={className}
          isActive={isActive}
          label={t('CustomDateChip')}
          icon={<CalendarTodayIcon fontSize="inherit" />}
          onClick={() => {
            openDialog()
          }}
          id={DATERANGE_IDS.CUSTOM_DATERANGE}
        />
      )}
    </DaterangePicker>
  )
}
