import {useTranslation} from 'react-i18next'
import {CashDrawerController, CashDrawerOpenLocation} from '../types'

export const useTranslateCashDrawerController = () => {
  const {t} = useTranslation()
  const translated: Record<CashDrawerController, string> = {
    [CashDrawerController.None]: t('CashDrawerController->None'),
    [CashDrawerController.VirtuosNetworkController]: t(
      'CashDrawerController->VirtuosNetworkController'
    )
  }
  return (controller: CashDrawerController) => translated[controller]
}

export const useTranslateCashDrawerOpenLocation = () => {
  const {t} = useTranslation()
  const translated: Record<CashDrawerOpenLocation, string> = {
    [CashDrawerOpenLocation.DontOpen]: t("Don't open"),
    [CashDrawerOpenLocation.BeforeCheckout]: t('On checkout page'),
    [CashDrawerOpenLocation.AfterCheckout]: t('After successful payment')
  }
  return (openLocation: CashDrawerOpenLocation) => translated[openLocation]
}
