import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GetAppIcon from '@mui/icons-material/GetApp'
import PrintIcon from '@mui/icons-material/Print'
import SendIcon from '@mui/icons-material/Send'
import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import validator from 'validator'
import {TemplatesQuery} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'

import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {useGetTicketPrintingTemplates} from '../../hooks/getTicketPrintingTemplates'
import {BRIEF_LEAD_FORM_ID} from './BriefLeadForm'
import {CoreFooter} from './CoreFooter'

const useStyles = makeStyles<Theme>((theme) => ({
  balanceGrid: {
    display: 'grid',
    textAlign: 'end',
    gridTemplateAreas: `
      ". divider"
      ". divider"
    `,
    columnGap: theme.spacing(1)
  },
  balanceDivider: {
    gridArea: 'divider'
  },
  positive: {
    color: theme.palette.success.main
  },
  negative: {
    color: theme.palette.error.main
  }
}))

interface IPaymentFooterProps {
  defaultEmail?: string
  surplus: number
  isLeadReservationEmailEnabled: boolean
  onSendByEmailButtonClick: () => Promise<void>
  onPayAndDownloadPdfButtonClick: () => Promise<void>
  onTicketPrintingTemplateButtonClick: (
    template: TemplatesQuery['templates'][number]
  ) => Promise<void>
}

export const PaymentFooter: React.FC<IPaymentFooterProps> = ({
  defaultEmail,
  surplus,
  isLeadReservationEmailEnabled,
  onSendByEmailButtonClick,
  onPayAndDownloadPdfButtonClick,
  onTicketPrintingTemplateButtonClick
}: IPaymentFooterProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const classes = useStyles()
  const translatePrice = useTranslateEffectiveClientPrice()
  const handleSendItemClick = useCallback(async () => {
    await onSendByEmailButtonClick()
    closeMenu()
  }, [closeMenu, onSendByEmailButtonClick])
  const handlePayAndDownloadPDFButtonClick = useCallback(async () => {
    await onPayAndDownloadPdfButtonClick()
    closeMenu()
  }, [closeMenu, onPayAndDownloadPdfButtonClick])
  const handleTicketPrintingTemplateButtonClick = useCallback(
    async (template: TemplatesQuery['templates'][number]) => {
      await onTicketPrintingTemplateButtonClick(template)
      closeMenu()
    },
    [closeMenu, onTicketPrintingTemplateButtonClick]
  )
  const ticketPrintingTemplates = useGetTicketPrintingTemplates()

  return (
    <CoreFooter>
      <div className={classes.balanceGrid}>
        <Typography variant="caption">
          {surplus < 0 ? t('Outstanding payment') : t('Overpaid')}
        </Typography>
        <Divider orientation="vertical" className={classes.balanceDivider} />
        <Typography
          variant="subtitle1"
          className={surplus < 0 ? classes.negative : classes.positive}
        >
          {translatePrice(Math.abs(surplus))}
        </Typography>
      </div>
      <Menu onClose={closeMenu} anchorEl={anchorEl}>
        <MenuItem
          hasDivider={false}
          isShown={false}
          label={t('Charge without other action')}
        />
        {isLeadReservationEmailEnabled && (
          <MenuItem
            label={t('Send by e-mail')}
            description={
              defaultEmail
                ? !validator.isEmail(defaultEmail)
                  ? t('Invalid email address')
                  : defaultEmail
                : t('Type email first')
            }
            icon={<SendIcon />}
            onClick={handleSendItemClick}
            isDisabled={!defaultEmail || !validator.isEmail(defaultEmail)}
          />
        )}
        <Divider />
        <MenuItem
          label={t('Pay and download PDF')}
          icon={<GetAppIcon />}
          onClick={handlePayAndDownloadPDFButtonClick}
          hasDivider={ticketPrintingTemplates.length > 0}
        />
        {ticketPrintingTemplates.reverse().map((template) => (
          <MenuItem
            key={template.id}
            label={template.name}
            icon={<PrintIcon />}
            onClick={() => handleTicketPrintingTemplateButtonClick(template)}
          />
        ))}
      </Menu>
      <CancelButton
        disabled={surplus < 0}
        startIcon={<ExpandLessIcon />}
        onClick={openMenu}
      >
        {t('More')}
      </CancelButton>
      <SaveButton
        disabled={surplus < 0}
        type="submit"
        form={BRIEF_LEAD_FORM_ID}
      >
        {t('Pay')}
      </SaveButton>
    </CoreFooter>
  )
}
