import DeleteIcon from '@mui/icons-material/Delete'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypesFilter,
  AdmissionTypeState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useTourParams} from '../../../../utils/pathname'
import {
  ButtonWithConfirmationDialog,
  DrawerTemplate,
  DrawerTemplateHeader
} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {AdmissionTypeForm} from './AdmissionTypeForm'
import {
  useAdmissionType,
  useDeleteDraftAdmissionType,
  useUpdateAdmissionType,
  useUpdateDraftAdmissionType
} from './graphql'
import {AdmissionTypeFormField, IAdmissionTypeForm} from './types'
import {
  transformUpdateAdmissionTypeDataToInput,
  transformUpdateDraftAdmissionTypeDataToInput
} from './utils'

const UPDATE_ADMISSION_TYPE_FORM_ID = 'updateAdmissionTypeForm'

interface IUpdateAdmissionTypeDrawerProps {
  onExited: () => void
  searchFilter: AdmissionTypesFilter
}

export const UpdateAdmissionTypeDrawer: React.FC<IUpdateAdmissionTypeDrawerProps> =
  ({onExited, searchFilter}: IUpdateAdmissionTypeDrawerProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {admissionTypeId} = useTourParams()
    const {data, loading, error} = useAdmissionType(admissionTypeId)
    const deleteDraftAdmissionType = useDeleteDraftAdmissionType()
    const updateDraftAdmissionType = useUpdateDraftAdmissionType()
    const updateAdmissionType = useUpdateAdmissionType()
    const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
      useMutationAssistanceHooks()
    const methods = useForm<IAdmissionTypeForm>()
    const {reset, handleSubmit} = methods
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (admissionTypeId) {
        openDrawer()
      }
    }, [admissionTypeId, openDrawer])
    useEffect(() => {
      if (data) {
        reset({
          [AdmissionTypeFormField.Name]: data.admissionType.name,
          [AdmissionTypeFormField.Abbreviation]:
            data.admissionType.abbreviation,
          [AdmissionTypeFormField.CapacityDecreaseCount]:
            data.admissionType.capacityDecreaseCount,
          [AdmissionTypeFormField.StartingQuantity]:
            data.admissionType.startingQuantity,
          [AdmissionTypeFormField.Color]: data.admissionType.color,
          [AdmissionTypeFormField.Icon]: data.admissionType.icon,
          [AdmissionTypeFormField.InternalDescription]:
            data.admissionType.internalDescription,
          [AdmissionTypeFormField.ECommerceNames]:
            data.admissionType.eCommerceNames || undefined,
          [AdmissionTypeFormField.ECommerceDescriptions]:
            data.admissionType.eCommerceDescriptions || undefined
        })
      }
    }, [data, reset])
    const handleDeleteAdmissionType = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await deleteDraftAdmissionType(admissionTypeId, searchFilter)
        addInfoNotification(t('Admission type deleted'))
        onExited()
      } catch (error) {
        defaultErrorHandler(error, t('Error while deleting admission type'))
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      admissionTypeId,
      defaultErrorHandler,
      deleteDraftAdmissionType,
      onExited,
      searchFilter,
      setShowBackdrop,
      t
    ])
    const handleAdmissionTypeUpdate = useCallback(
      async (formData: IAdmissionTypeForm) => {
        if (data) {
          if (data.admissionType.state === AdmissionTypeState.Draft) {
            try {
              setShowBackdrop(true)
              await updateDraftAdmissionType({
                id: admissionTypeId,
                input: transformUpdateDraftAdmissionTypeDataToInput(
                  formData,
                  data.admissionType.state
                )
              })
              addInfoNotification(t('Admission type updated'))
              onExited()
            } catch (error) {
              defaultErrorHandler(
                error,
                t('Error while updating admission type')
              )
            } finally {
              setShowBackdrop(false)
            }
          } else {
            try {
              setShowBackdrop(true)
              await updateAdmissionType({
                id: admissionTypeId,
                input: transformUpdateAdmissionTypeDataToInput(
                  formData,
                  data.admissionType.state
                )
              })
              addInfoNotification(t('Admission type updated'))
              onExited()
            } catch (error) {
              defaultErrorHandler(
                error,
                t('Error while updating admission type')
              )
            } finally {
              setShowBackdrop(false)
            }
          }
        }
      },
      [
        addInfoNotification,
        admissionTypeId,
        data,
        defaultErrorHandler,
        onExited,
        setShowBackdrop,
        t,
        updateAdmissionType,
        updateDraftAdmissionType
      ]
    )
    const saveAs = useCallback(
      (state: AdmissionTypeState) => async (formData: IAdmissionTypeForm) => {
        if (data) {
          if (data.admissionType.state === AdmissionTypeState.Draft) {
            try {
              setShowBackdrop(true)
              await updateDraftAdmissionType({
                id: admissionTypeId,
                input: transformUpdateDraftAdmissionTypeDataToInput(
                  formData,
                  state
                )
              })
              await addInfoNotification(t('Admission type updated'))
              onExited()
            } catch (error) {
              defaultErrorHandler(
                error,
                t('Error while updating admission type')
              )
            } finally {
              setShowBackdrop(false)
            }
          } else {
            try {
              setShowBackdrop(true)
              await updateAdmissionType({
                id: admissionTypeId,
                input: transformUpdateAdmissionTypeDataToInput(formData, state)
              })
              await addInfoNotification(t('Admission type updated'))
              onExited()
            } catch (error) {
              defaultErrorHandler(
                error,
                t('Error while updating admission type')
              )
            } finally {
              setShowBackdrop(false)
            }
          }
        }
      },
      [
        addInfoNotification,
        admissionTypeId,
        data,
        defaultErrorHandler,
        onExited,
        setShowBackdrop,
        t,
        updateAdmissionType,
        updateDraftAdmissionType
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error && t<string>('Error while loading admission type')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update admission type')}
            />
          }
          footer={
            <>
              {data?.admissionType.state === AdmissionTypeState.Draft &&
                P([PermissionCode.DeleteDraftAdmissionType]) && (
                  <ButtonWithConfirmationDialog
                    onConfirmButtonClick={handleDeleteAdmissionType}
                    buttonProps={{
                      children: t('Delete'),
                      variant: 'text',
                      startIcon: <DeleteIcon />
                    }}
                    dialogProps={{
                      title: t('Delete admission type?'),
                      contentText: t(
                        "Are you sure you want to delete this admission type? This can't be undone."
                      ),
                      confirmButtonLabel: t('Delete')
                    }}
                  />
                )}
              <SplitButton
                Options={[
                  (data?.admissionType.state === AdmissionTypeState.Draft ||
                    data?.admissionType.state ===
                      AdmissionTypeState.Inactive) && (
                    <MenuItem
                      key={1}
                      label={t('Save as active')}
                      onClick={handleSubmit(saveAs(AdmissionTypeState.Active))}
                    />
                  ),
                  data?.admissionType.state === AdmissionTypeState.Active && (
                    <MenuItem
                      key={2}
                      label={t('Save as inactive')}
                      onClick={handleSubmit(
                        saveAs(AdmissionTypeState.Inactive)
                      )}
                    />
                  )
                ].filter(Boolean)}
              >
                <SaveButton type="submit" form={UPDATE_ADMISSION_TYPE_FORM_ID}>
                  {t('Save')}
                </SaveButton>
              </SplitButton>
            </>
          }
        >
          {data && (
            <FormContext {...methods}>
              <AdmissionTypeForm
                formId={UPDATE_ADMISSION_TYPE_FORM_ID}
                onSubmit={handleAdmissionTypeUpdate}
                state={data.admissionType.state}
              />
            </FormContext>
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
