import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {CountryVatRatesQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useUserInfo} from '../../../../../utils/auth'
import {RenderOnData} from '../../../../common'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {useCountryVatRate} from '../../graphql'
import {useCreateProductPricing} from '../graphql'
import {PricingForm} from './PricingForm'
import {
  getProductPricingInput,
  IProductPricingForm,
  ProductPricingFormField
} from './types'

const CREATE_PRODUCT_PRICING_FORM_ID = 'createProductPricingForm'

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    width: 728,
    paddingTop: theme.spacing(4.5)
  }
}))

interface ICreatePricingDialogProps {
  isOpen: boolean
  onClose: () => void
  productId: number
  divisionId: number
}

export const CreatePricingDialog: React.FC<ICreatePricingDialogProps> = ({
  isOpen,
  onClose,
  productId,
  divisionId
}: ICreatePricingDialogProps) => {
  const {t} = useTranslation()
  const {effectiveClient} = useUserInfo()
  const {data, loading, error} = useCountryVatRate(effectiveClient!.countryCode)
  const createProductPricing = useCreateProductPricing()
  const {setShowBackdrop, addInfoNotification} = useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (data: IProductPricingForm) => {
      try {
        setShowBackdrop(true)
        await createProductPricing({
          id: productId,
          input: getProductPricingInput(data)
        })
        addInfoNotification(t('Product pricing created'))
        onClose()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createProductPricing,
      onClose,
      productId,
      setShowBackdrop,
      t
    ]
  )
  const classes = useStyles()
  return (
    <RenderOnData<CountryVatRatesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading country VAT rates')}
      dataCondition={(data) => Array.isArray(data.countryVatRates)}
      ignoreLoadingIfData
    >
      {({countryVatRates}) => (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md">
          <DialogTitle>{t('Create pricing')}</DialogTitle>
          <DialogContent className={classes.content} dividers>
            <PricingForm
              formId={CREATE_PRODUCT_PRICING_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={{
                [ProductPricingFormField.RetailVatRate]:
                  String(effectiveClient?.ticketDefaultVatRate) || '20',
                [ProductPricingFormField.DivisionId]: divisionId
              }}
              vatRates={countryVatRates}
            />
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={onClose} />
            <SaveButton type="submit" form={CREATE_PRODUCT_PRICING_FORM_ID}>
              {t('Create')}
            </SaveButton>
          </DialogActions>
        </Dialog>
      )}
    </RenderOnData>
  )
}
