import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import {
  Backdrop,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  UserPropertiesFragment,
  UserState
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {Theme} from '../../../../theme'
import {EnsurePermissions, useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {
  DataGridTable,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {userStateColors, useUserStateTranslations} from '../../../constants'
import {PrimaryHeader} from '../Header'
import {WideCenteredLayout} from '../Layout'
import {useGetUsers} from './graphql'
import {IUsersSearchFilter, UsersSearch} from './UsersSearch'

interface IUsersTableProps {
  users: Array<UserPropertiesFragment>
  searchFilter: IUsersSearchFilter
}

const StateRenderer = ({state}: {state: UserState}) => {
  const userStateTranslations = useUserStateTranslations()
  return (
    <EntityStateChip
      label={userStateTranslations[state]}
      colorConf={userStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.users.edit(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const getFilteredUsers = (
  users: UserPropertiesFragment[],
  filter: IUsersSearchFilter
) => {
  if (filter) {
    const filteredUsersByHasText = filter.hasText
      ? users.filter(
          (user) =>
            user.firstName
              .toLowerCase()
              .indexOf(filter.hasText!.toLowerCase()) > -1 ||
            user.lastName.toLowerCase().indexOf(filter.hasText!.toLowerCase()) >
              -1 ||
            user.username.toLowerCase().indexOf(filter.hasText!.toLowerCase()) >
              -1
        )
      : users
    return filter.state
      ? filteredUsersByHasText.filter(
          (filteredUser) => filteredUser.state === filter.state
        )
      : filteredUsersByHasText
  }
  return users
}

const UsersTable: React.FC<IUsersTableProps> = ({
  users,
  searchFilter
}: IUsersTableProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const dateTimeFormatter = useDateTimeFormatter()
  const filteredUsers = getFilteredUsers(users, searchFilter)
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Last name'),
        field: 'lastName',
        minWidth: 180
      },
      {
        headerName: t('First name'),
        field: 'firstName',
        minWidth: 180
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 120
      },
      {
        headerName: t('User name'),
        field: 'username',
        minWidth: 180
      },
      {
        headerName: t('Work email'),
        field: 'workEmail',
        minWidth: 180
      },
      {
        headerName: t('Last logged in'),
        field: 'lastLoggedIn',
        valueFormatter: dateTimeFormatter,
        minWidth: 180
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [dateTimeFormatter, t]
  )

  return (
    <DataGridTable
      columns={columns}
      rows={filteredUsers}
      disableColumnMenu
      localeText={{noRowsLabel: t('No users to show')}}
      initialState={{
        pagination: {paginationModel: {pageSize: users.length}},
        pinnedColumns: {left: ['lastName'], right: ['id']}
      }}
      columnVisibilityModel={{
        id: P([PermissionCode.ReadUser])
      }}
      hideFooter
    />
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.speedDial - 1
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  },
  staticTooltipLabel: {
    whiteSpace: 'nowrap'
  }
}))

export const UserList: React.FC = () => {
  const {error, loading, data} = useGetUsers()
  const history = useHistory()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<IUsersSearchFilter>({})
  const classes = useStyles()
  const onAddClick = useCallback(
    () => history.push(routeTo.admin.users.add()),
    [history]
  )
  const onInviteClick = useCallback(
    () => history.push(routeTo.admin.users.invite()),
    [history]
  )
  const {
    state: isSpeedDialOpen,
    setTrue: openSpeedDial,
    setFalse: closeSpeedDial
  } = useBooleanState(false)
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          search={<UsersSearch onFilterChange={setSearchFilter} />}
          title={t('Users')}
        />
      }
    >
      <RenderOnData
        error={error}
        errorMessage={t<string>('Could not load users')}
        data={data}
        loading={loading}
      >
        {(data) => (
          <WideCenteredLayout
            sx={{
              height: `calc(100% - 64px)`,
              width: '100%',
              p: 3
            }}
          >
            <UsersTable users={data.users} searchFilter={searchFilter} />
            <EnsurePermissions permissions={[PermissionCode.ManageUsers]}>
              <>
                <Backdrop
                  open={isSpeedDialOpen}
                  transitionDuration={{enter: 0, exit: 1000}}
                  className={classes.backdrop}
                />
                {P(
                  [PermissionCode.CreateUser] || P([PermissionCode.UpdateUser])
                ) && (
                  <SpeedDial
                    open={isSpeedDialOpen}
                    onClick={openSpeedDial}
                    onClose={(e, reason) => {
                      if (reason !== 'mouseLeave') {
                        closeSpeedDial()
                      }
                      e.stopPropagation()
                    }}
                    FabProps={{
                      size: 'medium'
                    }}
                    ariaLabel="addUserSpeedDial"
                    icon={<SpeedDialIcon />}
                    direction="up"
                    className={classes.speedDial}
                  >
                    {P([PermissionCode.CreateUser]) && (
                      <SpeedDialAction
                        icon={<PersonAddIcon />}
                        classes={{
                          staticTooltipLabel: classes.staticTooltipLabel
                        }}
                        tooltipTitle={t('Add user')}
                        tooltipOpen
                        onClick={onAddClick}
                      />
                    )}
                    {P([PermissionCode.InviteUsers]) && (
                      <SpeedDialAction
                        icon={<EmailOutlinedIcon />}
                        classes={{
                          staticTooltipLabel: classes.staticTooltipLabel
                        }}
                        tooltipTitle={t('Invite users by email')}
                        tooltipOpen
                        onClick={onInviteClick}
                      />
                    )}
                  </SpeedDial>
                )}
              </>
            </EnsurePermissions>
          </WideCenteredLayout>
        )}
      </RenderOnData>
    </PageWithHeaderTemplate>
  )
}
