import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionRateState,
  GetLightweightCostCentersQuery,
  GetLightweightCostCentersQueryVariables,
  GetLightweightEventCategoriesQuery,
  GetLightweightEventCategoriesQueryVariables,
  GetLightweightMarketingLabelsQuery,
  GetLightweightMarketingLabelsQueryVariables,
  LightweightAdmissionRatesQuery,
  LightweightAdmissionRatesQueryVariables,
  LightweightTourQuery,
  LightweightTourQueryVariables,
  LightweightUsersQuery,
  LightweightUsersQueryVariables,
  LightweightVenuesQuery,
  LightweightVenuesQueryVariables,
  ShowTypeCode,
  TourTimeSlotState
} from '../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {
  GET_COST_CENTERS,
  GET_EVENT_CATEGORIES,
  GET_LIGHTWEIGHT_USERS,
  GET_MARKETING_LABELS,
  LIGHTWEIGHT_ADMISSION_RATES,
  LIGHTWEIGHT_TOUR,
  LIGHTWEIGHT_VENUES
} from '../../pages/admin/graphql'
import {SaveButton} from '../Buttons'
import {DrawerTemplate, DrawerTemplateHeader} from '../DrawerUtils'
import {useCreateTourTimeSlot} from './graphql'
import {TourTimeSlotForm} from './TourTimeSlotForm'
import {ITourTimeSlotForm, TourTimeSlotFormField} from './types'
import {transformTourTimeSlotFormToInput} from './utils'

const CREATE_TOUR_TIME_SLOT_FORM_ID = 'createTourTimeSlotForm'

interface ICreateTourTimeSlotDrawerProps {
  isOpen: boolean
  onClose: () => void
  tourId: number
  showTypeCode: ShowTypeCode
  refetch: () => void
  state?: TourTimeSlotState
}

export const CreateTourTimeSlotDrawer: React.FC<ICreateTourTimeSlotDrawerProps> =
  ({
    isOpen,
    onClose,
    tourId,
    showTypeCode,
    refetch,
    state
  }: ICreateTourTimeSlotDrawerProps) => {
    const {t} = useTranslation()
    const createTourTimeSlot = useCreateTourTimeSlot()
    const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
      useMutationAssistanceHooks()
    const [skip, setSkip] = useState(!isOpen)
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
      return () => setSkip(true)
    }, [isOpen])
    const {
      data: admissionRatesData,
      loading: admissionRatesLoading,
      error: admissionRatesError
    } = useQuery<
      LightweightAdmissionRatesQuery,
      LightweightAdmissionRatesQueryVariables
    >(LIGHTWEIGHT_ADMISSION_RATES, {
      variables: {
        paginationInput: {offset: 0, limit: 300},
        filter: {states: [AdmissionRateState.Active], tourId}
      },
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: usersData,
      loading: usersLoading,
      error: usersError
    } = useQuery<LightweightUsersQuery, LightweightUsersQueryVariables>(
      GET_LIGHTWEIGHT_USERS,
      {
        fetchPolicy: 'network-only',
        skip
      }
    )
    const {
      data: costCentersData,
      loading: costCentersLoading,
      error: costCentersError
    } = useQuery<
      GetLightweightCostCentersQuery,
      GetLightweightCostCentersQueryVariables
    >(GET_COST_CENTERS, {
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: eventCategoriesData,
      loading: eventCategoriesLoading,
      error: eventCategoriesError
    } = useQuery<
      GetLightweightEventCategoriesQuery,
      GetLightweightEventCategoriesQueryVariables
    >(GET_EVENT_CATEGORIES, {
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: marketingLabelsData,
      loading: marketingLabelsLoading,
      error: marketingLabelsError
    } = useQuery<
      GetLightweightMarketingLabelsQuery,
      GetLightweightMarketingLabelsQueryVariables
    >(GET_MARKETING_LABELS, {
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: venuesData,
      loading: venuesLoading,
      error: venuesError
    } = useQuery<LightweightVenuesQuery, LightweightVenuesQueryVariables>(
      LIGHTWEIGHT_VENUES,
      {
        fetchPolicy: 'network-only',
        skip
      }
    )
    const {
      data: tourData,
      loading: tourLoading,
      error: tourError
    } = useQuery<LightweightTourQuery, LightweightTourQueryVariables>(
      LIGHTWEIGHT_TOUR,
      {
        variables: {id: tourId},
        fetchPolicy: 'network-only',
        skip
      }
    )
    const handleSubmit = useCallback(
      async (formData: ITourTimeSlotForm) => {
        try {
          setShowBackdrop(true)
          await createTourTimeSlot(
            transformTourTimeSlotFormToInput(formData, state)
          )
          refetch()
          addInfoNotification(t('Tour time slot created'))
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating tour time slot'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createTourTimeSlot,
        defaultErrorHandler,
        onClose,
        refetch,
        setShowBackdrop,
        state,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create tour time slot')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_TOUR_TIME_SLOT_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          isLoading={
            admissionRatesLoading ||
            usersLoading ||
            costCentersLoading ||
            eventCategoriesLoading ||
            marketingLabelsLoading ||
            venuesLoading ||
            tourLoading
          }
          errorMessage={
            (admissionRatesError ||
              usersError ||
              costCentersError ||
              eventCategoriesError ||
              marketingLabelsError ||
              venuesError ||
              tourError) &&
            t('Error while loading resources')
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {admissionRatesData &&
            usersData &&
            costCentersData &&
            eventCategoriesData &&
            marketingLabelsData &&
            venuesData &&
            tourData && (
              <TourTimeSlotForm
                formId={CREATE_TOUR_TIME_SLOT_FORM_ID}
                onSubmit={handleSubmit}
                admissionRates={admissionRatesData.admissionRates.items}
                users={usersData.users}
                costCenters={costCentersData.costCenters}
                showTypeCode={showTypeCode}
                eventCategories={eventCategoriesData.eventCategories}
                marketingLabels={marketingLabelsData.marketingLabels}
                venues={venuesData.venues}
                defaultValues={{
                  [TourTimeSlotFormField.IsRetailSaleActive]:
                    tourData.tour.isRetailSaleActive,
                  [TourTimeSlotFormField.IsRetailReservationActive]:
                    tourData.tour.isRetailReservationActive,
                  [TourTimeSlotFormField.IsECommerceSaleActive]:
                    tourData.tour.isECommerceSaleActive,
                  [TourTimeSlotFormField.IsECommerceReservationActive]:
                    tourData.tour.isECommerceReservationActive,
                  [TourTimeSlotFormField.VenueId]: tourData.tour.venueId
                    ? String(tourData.tour.venueId)
                    : undefined,
                  [TourTimeSlotFormField.RetailAttendeesLimit]:
                    typeof tourData.tour.retailAttendeesLimit === 'number'
                      ? String(tourData.tour.retailAttendeesLimit)
                      : undefined,
                  [TourTimeSlotFormField.ECommerceAttendeesLimit]:
                    typeof tourData.tour.eCommerceAttendeesLimit === 'number'
                      ? String(tourData.tour.eCommerceAttendeesLimit)
                      : undefined,
                  [TourTimeSlotFormField.ECommerceOrderAttendeesLimit]:
                    typeof tourData.tour.eCommerceOrderAttendeesLimit ===
                    'number'
                      ? String(tourData.tour.eCommerceOrderAttendeesLimit)
                      : undefined
                }}
              />
            )}
        </DrawerTemplate>
      </Drawer>
    )
  }
