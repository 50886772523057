import {useQuery} from '@apollo/react-hooks'
import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  BusinessPartnerCategory,
  BusinessPartnerState,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  PermissionCode
} from '../../../../__generated__/schema'
import {useGetShowCountrySelectData} from '../../../../hooks/countries'
import {useLanguageSelectData} from '../../../../hooks/languageSelectData'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useIsPositiveInteger} from '../../../../utils/formsValidations'
import {InputBlock, InputRow, TextEditor} from '../../../common'
import {TextEditorLabel} from '../../../common/textEditor/TextEditor'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {FormTextInput} from '../../../form/FormTextInput'
import {FormDateInput} from '../../../form/pickers'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {FormMultiSelect} from '../../../visual'
import {LIGHTWEIGHT_BUSINESS_PARTNERS} from '../graphql'
import {DistributionBlock, getDistributionsFieldName} from './DistributionBlock'
import {
  useAgeClassificationSelectFields,
  useGenreSelectFields,
  useShowContentDescriptorCodeFields,
  useShowTypesSelectFields
} from './getSelectFields'
import {IShowForm, ShowFormField, ShowFormLocation} from './types'
import {useShowDetailsFormAnchors} from './useShowDetailsFormAnchors'

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  selectSubtitle: {
    paddingTop: theme.spacing(2)
  }
}))

interface IShowFormProps {
  onSubmit(formData: IShowForm): void
  formId: string
  className?: string
  defaultValues: Partial<IShowForm>
  location: ShowFormLocation
}

export const ShowForm: React.FC<IShowFormProps> = ({
  onSubmit,
  formId,
  className,
  defaultValues,
  location
}: IShowFormProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const methods = useForm<IShowForm>({
    defaultValues
  })

  const {
    register,
    unregister,
    setValue,
    errors,
    setError,
    clearError,
    watch,
    handleSubmit,
    triggerValidation,
    control
  } = methods

  const isBusinessPartnerVisible =
    location === ShowFormLocation.ClientUpdate &&
    P([PermissionCode.ReadBusinessPartners])

  const {data: businessPartnersData, loading: businessPartnersLoading} =
    useQuery<
      LightweightBusinessPartnersQuery,
      LightweightBusinessPartnersQueryVariables
    >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
      variables: {
        paginationInput: {offset: 0, limit: 100},
        filter: {
          categories: [
            BusinessPartnerCategory.MovieDistributor,
            BusinessPartnerCategory.Licensing,
            BusinessPartnerCategory.Agency
          ]
        }
      },
      fetchPolicy: 'network-only',
      skip: !isBusinessPartnerVisible
    })

  const _onSubmit = useCallback(
    (data: IShowForm) => {
      let hasError = false
      Object.entries(data[ShowFormField.DISTRIBUTIONS] ?? {}).map(
        ([key, distribution]) => {
          if (
            !distribution?.formatCode &&
            !distribution?.versionCode &&
            !distribution?.soundMixCode
          ) {
            setError(getDistributionsFieldName(key), t('Empty distribution'))
            hasError = true
          }
        }
      )
      if (!hasError) {
        onSubmit(data)
      }
    },
    [onSubmit, setError, t]
  )

  const formProps = {
    register,
    unregister,
    setValue,
    watch,
    errors
  }

  const showTypeCode = watch(ShowFormField.TYPE_CODE)
  const showDetailsFormAnchors = useShowDetailsFormAnchors()
  const showTypesSelectFields = useShowTypesSelectFields()
  const ageClassificationSelectFields =
    useAgeClassificationSelectFields(showTypeCode)
  const genreSelectFields = useGenreSelectFields(showTypeCode)
  const showCountrySelectData = useGetShowCountrySelectData()
  const languagesSelectData = useLanguageSelectData()
  const {showContentDescriptorCodeFields} =
    useShowContentDescriptorCodeFields(location)
  const isPositiveInteger = useIsPositiveInteger()

  useEffect(() => {
    if (defaultValues?.businessPartnerId && businessPartnersData) {
      setValue(
        ShowFormField.BUSINESS_PARTNER_ID,
        defaultValues.businessPartnerId
      )
    }
  }, [businessPartnersData, defaultValues.businessPartnerId, setValue])

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(_onSubmit)}
      id={formId}
      className={className}
    >
      <input
        type="hidden"
        name={ShowFormField.LOCALE_CODE}
        ref={register({
          required: true
        })}
      />
      <InputBlock
        header={showDetailsFormAnchors.general.label}
        blockId={showDetailsFormAnchors.general.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormSelect<IShowForm>
              selectOptions={showTypesSelectFields}
              key={ShowFormField.TYPE_CODE}
              label={t('Type')}
              name={ShowFormField.TYPE_CODE}
              required
              fullWidth
              validationOptions={{required: true}}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormTextInput<IShowForm>
              {...formProps}
              triggerValidation={triggerValidation}
              fullWidth
              key={ShowFormField.TITLE}
              label={t('Title')}
              name={ShowFormField.TITLE}
              required
              validationOptions={{
                required: true
              }}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormTextInput<IShowForm>
              {...formProps}
              triggerValidation={triggerValidation}
              fullWidth
              key={ShowFormField.ORIGINAL_TITLE}
              label={t('Original title')}
              name={ShowFormField.ORIGINAL_TITLE}
              helperNote={t('Recommended')}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormTextInput<IShowForm>
              {...formProps}
              triggerValidation={triggerValidation}
              fullWidth
              key={ShowFormField.DURATION}
              label={t('Duration')}
              name={ShowFormField.DURATION}
              helperNote={t('Recommended. Fill in minutes.')}
              inputMode="numeric"
              validationOptions={{
                validate: {isPositiveInteger}
              }}
            />,
            <FormMultiSelect<IShowForm>
              {...formProps}
              key={ShowFormField.AGE_CLASSIFICATION_CODES}
              label={t('Age restrictions')}
              note={t('Recommended')}
              selectOptions={ageClassificationSelectFields}
              selectedKeys={watch(ShowFormField.AGE_CLASSIFICATION_CODES)}
              name={ShowFormField.AGE_CLASSIFICATION_CODES}
              isSearchable
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormDateInput<IShowForm>
              fullWidth
              {...methods}
              datePickerProps={{
                label: t('Premiere')
              }}
              key={ShowFormField.GLOBAL_RELEASE_DATE}
              name={ShowFormField.GLOBAL_RELEASE_DATE}
            />,
            <FormTextInput<IShowForm>
              {...formProps}
              triggerValidation={triggerValidation}
              fullWidth
              key={ShowFormField.PRODUCTION_YEAR}
              label={t('Production year')}
              name={ShowFormField.PRODUCTION_YEAR}
              inputMode="numeric"
              validationOptions={{
                validate: {isPositiveInteger}
              }}
            />
          ]}
        />
        <Divider className={classes.divider} />
        <Typography variant="subtitle1" className={classes.selectSubtitle}>
          {t('Genres')}
        </Typography>
        <InputRow
          nodes={[
            <FormMultiSelect<IShowForm>
              {...formProps}
              key={ShowFormField.GENRE_CODES}
              label={t('Genres')}
              selectOptions={genreSelectFields}
              selectedKeys={watch(ShowFormField.GENRE_CODES)}
              name={ShowFormField.GENRE_CODES}
              isSearchable
            />
          ]}
        />
        <Typography variant="subtitle1" className={classes.selectSubtitle}>
          {t('Countries of origin')}
        </Typography>
        <InputRow
          nodes={[
            <FormMultiSelect<IShowForm>
              {...formProps}
              key={ShowFormField.COUNTRY_CODES}
              label={t('Countries of origin')}
              selectOptions={showCountrySelectData}
              selectedKeys={watch(ShowFormField.COUNTRY_CODES)}
              name={ShowFormField.COUNTRY_CODES}
              isSearchable
            />
          ]}
        />
        <Typography variant="subtitle1" className={classes.selectSubtitle}>
          {t('Original languages')}
        </Typography>
        <InputRow
          nodes={[
            <FormMultiSelect<IShowForm>
              {...formProps}
              key={ShowFormField.LANGUAGE_CODES}
              label={t('Original languages')}
              selectOptions={languagesSelectData}
              selectedKeys={watch(ShowFormField.LANGUAGE_CODES)}
              isSearchable
              name={ShowFormField.LANGUAGE_CODES}
            />
          ]}
        />
        {Object.values(showContentDescriptorCodeFields).length > 0 && (
          <>
            <Typography variant="subtitle1" className={classes.selectSubtitle}>
              {t('Content descriptors')}
            </Typography>
            <InputRow
              nodes={[
                <FormMultiSelect<IShowForm>
                  {...formProps}
                  key={ShowFormField.CONTENT_DESCRIPTOR_CODES}
                  label={t('Content descriptors')}
                  selectOptions={showContentDescriptorCodeFields}
                  selectedKeys={
                    watch(ShowFormField.CONTENT_DESCRIPTOR_CODES) || []
                  }
                  isSearchable
                  name={ShowFormField.CONTENT_DESCRIPTOR_CODES}
                />
              ]}
            />
          </>
        )}
        {isBusinessPartnerVisible && (
          <>
            <Typography variant="subtitle1" className={classes.selectSubtitle}>
              {t('Business partner')}
            </Typography>
            <InputRow
              nodes={[
                <FormAutocomplete<IShowForm>
                  noOptionsText={t('No business partners to show')}
                  fullWidth
                  errors={errors}
                  label={t('Business partner')}
                  name={ShowFormField.BUSINESS_PARTNER_ID}
                  key={ShowFormField.BUSINESS_PARTNER_ID}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  loading={businessPartnersLoading}
                  autocompleteOptions={(
                    businessPartnersData?.businessPartners.items || []
                  )
                    .filter(
                      ({id, state}) =>
                        state === BusinessPartnerState.Active ||
                        id === defaultValues?.businessPartnerId
                    )
                    .map(({id, companyName}) => ({
                      value: id,
                      name: companyName
                    }))}
                />
              ]}
            />
          </>
        )}
      </InputBlock>
      <InputBlock
        header={showDetailsFormAnchors.content.label}
        blockId={showDetailsFormAnchors.content.id}
      >
        <Typography variant="subtitle1">{t('Tagline')}</Typography>
        <InputRow
          nodes={[
            <FormTextInput<IShowForm>
              {...formProps}
              triggerValidation={triggerValidation}
              fullWidth
              key={ShowFormField.TAGLINE}
              placeholder={t('Tagline')}
              name={ShowFormField.TAGLINE}
              helperNote={t('Recommended')}
            />
          ]}
        />
        <TextEditorLabel>{t('Description')}</TextEditorLabel>
        <InputRow
          nodes={[
            <TextEditor<IShowForm>
              key={ShowFormField.DESCRIPTION}
              name={ShowFormField.DESCRIPTION}
              inputRef={register()}
              setValue={setValue}
              defaultValue={defaultValues[ShowFormField.DESCRIPTION]}
            />
          ]}
        />
      </InputBlock>
      <DistributionBlock
        control={control}
        blockId={showDetailsFormAnchors.distribution.id}
        showTypeCode={showTypeCode}
        errors={errors}
        clearError={clearError}
        watch={watch}
      />
    </form>
  )
}

export const useShowFormStyles = makeStyles<Theme>((theme) => ({
  form: {
    '&:last-child': {
      paddingBottom: theme.spacing(3)
    }
  }
}))
