import React, {ChangeEvent, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {ISeatStateValue, ITextStateValue} from '../../shared/editor/stateTypes'
import {useIsStringWithMaxLength} from '../../utils/formsValidations'
import {useSelector} from '../redux'
import {useObjectsActions} from '../redux/objects/actions'
import {IZoneStateValue} from '../redux/objects/types'
import {useSelectionActions} from '../redux/selection/actions'
import {selectedByDoubleClickSelector} from '../redux/selection/selectors'
import {usePlaceholderAndValue} from './placeholderAndValue'
import {
  ITextFieldWithValidationsProps,
  TextFieldWithValidations
} from './TextFieldWithValidations'
import {ObjectsPanelType} from './types'

const useHelperText = (objectsPanelType: ObjectsPanelType) => {
  const {t} = useTranslation()
  switch (objectsPanelType) {
    case ObjectsPanelType.SingleSeat:
    case ObjectsPanelType.MultipleSeats:
    case ObjectsPanelType.MixWithAttendeeNavigation:
    case ObjectsPanelType.MixWithLabel:
      return t('Max 4 characters.')
    case ObjectsPanelType.SingleZone:
    case ObjectsPanelType.MultipleZones:
    case ObjectsPanelType.MixZonesAndTexts:
      return t('Max 30 characters.')
    default:
      return undefined
  }
}

interface ILabelTextFieldProps {
  objectsWithLabel: (ISeatStateValue | IZoneStateValue | ITextStateValue)[]
  objectsPanelType: ObjectsPanelType
}

const useValidationOptions = (
  objectsPanelType: ObjectsPanelType
): ITextFieldWithValidationsProps['validationOptions'] => {
  const isStringWithMaxLength4 = useIsStringWithMaxLength(4)
  const isStringWithMaxLength30 = useIsStringWithMaxLength(30)
  return {
    isRequired: [
      ObjectsPanelType.SingleText,
      ObjectsPanelType.MultipleTexts,
      ObjectsPanelType.MixWithLabel,
      ObjectsPanelType.MixZonesAndTexts
    ].includes(objectsPanelType),
    validators: [
      ObjectsPanelType.SingleSeat,
      ObjectsPanelType.MultipleSeats,
      ObjectsPanelType.MixWithAttendeeNavigation,
      ObjectsPanelType.MixWithLabel
    ].includes(objectsPanelType)
      ? {
          isStringWithMaxLength4
        }
      : [
          ObjectsPanelType.SingleZone,
          ObjectsPanelType.MultipleZones,
          ObjectsPanelType.MixZonesAndTexts
        ].includes(objectsPanelType)
      ? {isStringWithMaxLength30}
      : {}
  }
}

export const LabelTextField: React.FC<ILabelTextFieldProps> = ({
  objectsWithLabel,
  objectsPanelType
}: ILabelTextFieldProps) => {
  const {t} = useTranslation()
  const helperText = useHelperText(objectsPanelType)
  const {updateObjects} = useObjectsActions()
  const {placeholder, value} = usePlaceholderAndValue({
    selectedObjects: objectsWithLabel,
    iteratee: 'config.label'
  })
  const handleDebouncedChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateObjects(
      objectsWithLabel.map(
        <T extends ISeatStateValue | IZoneStateValue | ITextStateValue>(
          object: T
        ): T => ({
          ...object,
          config: {
            ...object.config,
            label: e.target.value
          }
        })
      )
    )
  }
  const inputRef = useRef<HTMLInputElement>(null)
  const doubleClick = useSelector(selectedByDoubleClickSelector)
  const {setDoubleClick} = useSelectionActions()
  useEffect(() => {
    if (doubleClick && inputRef && inputRef.current) {
      inputRef.current.focus()
      setDoubleClick(false)
    }
  })
  const validationOptions = useValidationOptions(objectsPanelType)
  return (
    <TextFieldWithValidations
      label={t('Label')}
      inputRef={inputRef}
      defaultValue={value}
      placeholder={placeholder}
      helperText={helperText}
      variant="outlined"
      onChange={handleDebouncedChange}
      validationOptions={validationOptions}
      fullWidth
    />
  )
}
