import {makeStyles} from '@mui/styles'
import React, {useEffect, useState} from 'react'
import {
  CartPropertiesFragment,
  CartState,
  DetailClientPropertiesFragment
} from '../../../../../__generated__/schema'
import {useOpenCashDrawer} from '../../../../../hooks/openCashDrawer'
import {Theme} from '../../../../../theme'
import {CashDrawerOpenLocation} from '../../../../../types'
import {DraftCartCore} from './DraftCartCore'
import {DraftCartCoreDataProvider} from './DraftCartCoreDataProvider'
import {FulFilledCartCore} from './FulFilledCartCore'
import {PendingCartCore} from './PendingCartCore'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  }
}))

interface ICoreProps {
  cart: CartPropertiesFragment
  effectiveClient: DetailClientPropertiesFragment
}

export const Core: React.FC<ICoreProps> = ({
  cart,
  effectiveClient
}: ICoreProps) => {
  const openCashDrawer = useOpenCashDrawer()
  const [isCashDrawerOpen, setIsCashDrawerOpen] = useState<boolean>(false)
  const classes = useStyles()
  useEffect(() => {
    if (cart.state === CartState.Draft && !isCashDrawerOpen) {
      openCashDrawer(CashDrawerOpenLocation.BeforeCheckout)
      setIsCashDrawerOpen(true)
    }
  }, [cart.state, isCashDrawerOpen, openCashDrawer])
  if ([CartState.Sold, CartState.Reserved].includes(cart.state)) {
    return <FulFilledCartCore cart={cart} className={classes.root} />
  }
  if (cart.state === CartState.Pending) {
    return <PendingCartCore cart={cart} />
  }
  if (cart.state === CartState.Draft) {
    return (
      <DraftCartCoreDataProvider>
        {(paymentMethods) => (
          <DraftCartCore
            className={classes.root}
            effectiveClient={effectiveClient}
            paymentMethods={paymentMethods}
          />
        )}
      </DraftCartCoreDataProvider>
    )
  }
  if (cart.state === CartState.Failed) {
    return null
  }
  return <div>Not implemented!</div>
}
