import {SxProps} from '@mui/material'
import React from 'react'
import {
  ApiSeatState,
  Scalars,
  SeatOptionsEventFragment
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {CurrentStatusContent} from './CurrentStatusContent'
import {EditSeatStatesContent} from './EditSeatStatesContent'
import {EditZoneSeatStatesContent} from './EditZoneSeatStatesContent'
import {AggregatedSeatsStateCounts} from './types'

interface ISidePanelProps {
  event: SeatOptionsEventFragment
  eventSeats: Scalars['JSON']
  onCancelButtonClick: () => void
  sx?: SxProps<Theme>
}

export const SidePanel: React.FC<ISidePanelProps> = ({
  sx,
  eventSeats,
  event,
  onCancelButtonClick
}: ISidePanelProps) => {
  const seatsSelectedByMe = Object.entries(
    eventSeats.seats as {
      [uuid: string]: {
        state: ApiSeatState
      }
    }
  ).filter(([, {state}]) => state === ApiSeatState.SelectedByMe)
  if (seatsSelectedByMe.length) {
    return (
      <EditSeatStatesContent
        sx={sx}
        selectedUuids={seatsSelectedByMe.map(([uuid]) => uuid)}
        eventId={event.id}
        onCancelButtonClick={onCancelButtonClick}
      />
    )
  }
  const zoneSeatsSelectedByMe = Object.entries(
    eventSeats.zones as {
      [uuid: string]: {
        states: Partial<AggregatedSeatsStateCounts>
        statesBeforeSelected: Partial<AggregatedSeatsStateCounts>
      }
    }
  ).filter(([, {states}]) => Boolean(states[ApiSeatState.SelectedByMe]))
  if (zoneSeatsSelectedByMe.length > 0) {
    return (
      <EditZoneSeatStatesContent
        sx={sx}
        selectedUuid={zoneSeatsSelectedByMe[0][0]}
        states={zoneSeatsSelectedByMe[0][1].states}
        statesBeforeSelected={zoneSeatsSelectedByMe[0][1].statesBeforeSelected}
        eventId={event.id}
        onCancelButtonClick={onCancelButtonClick}
      />
    )
  }
  return <CurrentStatusContent eventSeats={eventSeats} event={event} sx={sx} />
}
