import React, {useCallback} from 'react'

import {SearchRowWithLabel} from './SearchRowWithLabel'
import {DaterangeChip, OptionChip, useChipSpacingStyles} from './OptionChip'
import {IUseDateRangeSearch} from './daterangeSearch'
import {DATERANGE_IDS} from './types'

interface IAdvancedSearchDaterangeRowProps<T> {
  label: string
  daterangeOptions: IUseDateRangeSearch<T>['daterangeOptions']
  mapCustomDaterangeToSearchObject: IUseDateRangeSearch<T>['mapCustomDaterangeToSearchObject']
  mapDaterangeToSearchObject: IUseDateRangeSearch<T>['mapDaterangeToSearchObject']
  getIsDaterangeOptionActive: IUseDateRangeSearch<T>['getIsDaterangeOptionActive']
  stripDaterangeFromSearchObject: (searchObject: T, id: DATERANGE_IDS) => T
  advancedSearchObject: T
  setAdvancedSearchObject(currentAdvancedSearchObject: T): void
  customDaterangeDialogTitle: string
  customDaterangeDialogDescription?: string
}

export const AdvancedSearchDaterangeRow = <T extends object>({
  label,
  daterangeOptions,
  setAdvancedSearchObject,
  advancedSearchObject,
  mapDaterangeToSearchObject,
  mapCustomDaterangeToSearchObject,
  stripDaterangeFromSearchObject,
  customDaterangeDialogTitle,
  getIsDaterangeOptionActive,
  customDaterangeDialogDescription
}: IAdvancedSearchDaterangeRowProps<T>) => {
  const onChipClick = useCallback(
    (id) => {
      const isActive = getIsDaterangeOptionActive(advancedSearchObject, id)
      setAdvancedSearchObject(
        isActive
          ? stripDaterangeFromSearchObject(advancedSearchObject, id)
          : mapDaterangeToSearchObject(advancedSearchObject, id)
      )
    },
    [
      advancedSearchObject,
      getIsDaterangeOptionActive,
      mapDaterangeToSearchObject,
      setAdvancedSearchObject,
      stripDaterangeFromSearchObject
    ]
  )
  const onCustomOptionClick = useCallback(
    (startDate, endDate) => {
      setAdvancedSearchObject(
        mapCustomDaterangeToSearchObject(advancedSearchObject, {
          startDate,
          endDate
        })
      )
    },
    [
      advancedSearchObject,
      mapCustomDaterangeToSearchObject,
      setAdvancedSearchObject
    ]
  )
  const chipSpacingClasses = useChipSpacingStyles()
  return (
    <SearchRowWithLabel
      label={label}
      labelColor="textSecondary"
      alignLabel="top"
    >
      <div className={chipSpacingClasses.chipBox}>
        {daterangeOptions.map((option) => (
          <OptionChip
            className={chipSpacingClasses.chip}
            key={option.id}
            label={option.label}
            id={option.id}
            isActive={getIsDaterangeOptionActive(
              advancedSearchObject,
              option.id
            )}
            onClick={onChipClick}
          />
        ))}
        <DaterangeChip
          className={chipSpacingClasses.chip}
          isActive={getIsDaterangeOptionActive(
            advancedSearchObject,
            DATERANGE_IDS.CUSTOM_DATERANGE
          )}
          dialogTitle={customDaterangeDialogTitle}
          dialogDescription={customDaterangeDialogDescription}
          onSubmit={onCustomOptionClick}
        />
      </div>
    </SearchRowWithLabel>
  )
}
