import {arrayMove} from '@dnd-kit/sortable'
import AddIcon from '@mui/icons-material/Add'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import {Box, Button, Drawer, IconButton} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {
  BaseWebsiteFooterFieldsFragment,
  FooterItemAlignment
} from '../../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../../hooks/state'
import {useUndoRedo} from '../../../../../../hooks/undoableState'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {ItemGroupWithId} from '../../types'
import {SortableItemGroups} from './SortableItemGroups'

const transformItemGroups = (
  footer?: BaseWebsiteFooterFieldsFragment
): ItemGroupWithId[] =>
  footer?.itemGroups.map((itemGroup) => ({
    id: uuidv4(),
    ...itemGroup,
    items: itemGroup.items.map((item) => ({
      id: uuidv4(),
      ...item
    }))
  })) || []

interface IEditFooterDrawerProps {
  footer: BaseWebsiteFooterFieldsFragment
  onExited: () => void
  onSaveButtonClick: (footerId: string, itemGroups: ItemGroupWithId[]) => void
}

export const EditFooterDrawer: React.FC<IEditFooterDrawerProps> = ({
  footer,
  onExited,
  onSaveButtonClick
}: IEditFooterDrawerProps) => {
  const {t} = useTranslation()
  const {
    state: itemGroups,
    setNewState: setItemGroups,
    canUndo,
    canRedo,
    undo,
    redo
  } = useUndoRedo<ItemGroupWithId[]>(transformItemGroups(footer))
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {
    state: isCloseDialogOpen,
    setTrue: openCloseDialog,
    setFalse: closeCloseDialog
  } = useBooleanState(false)
  const handleSortEnd = useCallback(
    (oldIndex: number, newIndex: number) =>
      setItemGroups((oldItemGroups) =>
        arrayMove(oldItemGroups, oldIndex, newIndex)
      ),
    [setItemGroups]
  )
  const handleAddColumn = useCallback(
    () =>
      setItemGroups((oldItemGroups) => [
        {
          id: uuidv4(),
          alignment: FooterItemAlignment.Left,
          items: []
        },
        ...oldItemGroups
      ]),
    [setItemGroups]
  )
  const handleDrawerClose = useCallback(() => {
    if (canUndo) {
      openCloseDialog()
    } else {
      closeDrawer()
    }
  }, [canUndo, closeDrawer, openCloseDialog])
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  return (
    <>
      <Drawer
        SlideProps={{onExited}}
        anchor="bottom"
        open={isOpen}
        onClose={handleDrawerClose}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={handleDrawerClose}
              title={t('Edit footer {{id}}', {id: footer.ID})}
            />
          }
          footer={
            <SaveButton
              onClick={() => onSaveButtonClick(footer.ID, itemGroups)}
            >
              {t('Save')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              py: 2,
              px: 2,
              alignItems: 'flex-start'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddColumn}
                disabled={itemGroups.length >= 4}
              >
                {t('Add column')}
              </Button>
              <IconButton color="primary" disabled={!canUndo} onClick={undo}>
                <UndoIcon />
              </IconButton>
              <IconButton color="primary" disabled={!canRedo} onClick={redo}>
                <RedoIcon />
              </IconButton>
            </Box>
            <SortableItemGroups
              itemGroups={itemGroups}
              onSortEnd={handleSortEnd}
              setItemGroups={setItemGroups}
            />
          </Box>
        </DrawerTemplate>
      </Drawer>
      <ConfirmationDialog
        title={t('Unsaved changes')}
        contentText={t(
          'There are unsaved changes. If you would like to save changes, you can keep editing. Discarding changes is permanent action and can’t be undone.'
        )}
        confirmButtonLabel={t('Discard changes')}
        onConfirm={() => {
          closeCloseDialog()
          closeDrawer()
        }}
        cancelButtonLabel={t('Keep editing')}
        onCancel={closeCloseDialog}
        isOpen={isCloseDialogOpen}
      />
    </>
  )
}
