export enum ViewType {
  Day = 'resourceTimeGridDay',
  Week = 'resourceTimeGridWeek',
  Month = 'dayGridMonth'
}

export enum CalendarSettingsFieldName {
  FIRST_DAY_OF_WEEK = 'firstDayOfWeek',
  SHORTEN_VENUE_NAMES = 'shortenVenueNames',
  SCROLL_TIME = 'scrollTime',
  DENSITY_SETTINGS = 'densitySettings'
}

export enum DayOfWeek {
  Sun = 0,
  Mon = 1,
  Tue = 2,
  Wed = 3,
  Thu = 4,
  Fri = 5,
  Sat = 6
}

export enum ScrollTime {
  '6:00:00' = '6:00',
  '7:00:00' = '7:00',
  '8:00:00' = '8:00',
  '9:00:00' = '9:00',
  '10:00:00' = '10:00',
  '11:00:00' = '11:00',
  '12:00:00' = '12:00',
  '13:00:00' = '13:00',
  '14:00:00' = '14:00',
  '15:00:00' = '15:00'
}

export enum SlotDuration {
  Compact = '01:00:00',
  Standard = '00:30:00',
  Wide = '00:20:00',
  UltraWide = '00:15:00'
}

export interface ICalendarSettingsForm {
  [CalendarSettingsFieldName.FIRST_DAY_OF_WEEK]: DayOfWeek
  [CalendarSettingsFieldName.SHORTEN_VENUE_NAMES]: boolean
  [CalendarSettingsFieldName.SCROLL_TIME]: ScrollTime
  [CalendarSettingsFieldName.DENSITY_SETTINGS]: SlotDuration
}

export enum ClickInfoActivity {
  PUBLISH = 'publish',
  CANCEL = 'cancel',
  DELETE = 'delete'
}
