import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import {Box, Button, IconButton, Paper, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CommonSettingsScript} from '../../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../../hooks/state'
import {useTranslateCommonSettingsScriptLocation} from '../../../../../../hooks/translateCommonSettingsScriptLocation'
import {useUndoRedo} from '../../../../../../hooks/undoableState'
import {useNotifications} from '../../../../../context/notifications'
import {Blank} from '../../../../../visual/Blank'
import {AddScriptDialog} from './AddScriptDialog'
import {EditScriptDialog} from './EditScriptDialog'
import {IScriptForm, ScriptFormField} from './types'

interface IScriptRowProps extends CommonSettingsScript {
  onEditClick: () => void
  onDeleteClick: () => void
}

const ScriptRow: React.FC<IScriptRowProps> = ({
  id,
  location,
  onEditClick,
  onDeleteClick
}: IScriptRowProps) => {
  const {t} = useTranslation()
  const translateCommonSettingsScriptLocation =
    useTranslateCommonSettingsScriptLocation()
  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        py: 1.5
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 48px 48px',
          gridTemplateRows: '1fr 1fr',
          alignItems: 'center',
          gridTemplateAreas: `
                    "id         editIcon deleteIcon"
                    "location   editIcon deleteIcon"
                `
        }}
      >
        <Typography variant="subtitle2" sx={{gridArea: 'id'}}>
          {id}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{gridArea: 'location'}}
        >
          {t('Location: {{location}}', {
            location: translateCommonSettingsScriptLocation(location)
          })}
        </Typography>
        <IconButton
          color="primary"
          sx={{gridArea: 'editIcon'}}
          onClick={onEditClick}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          sx={{gridArea: 'deleteIcon'}}
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Paper>
  )
}

interface IContentProps {
  defaultScripts: CommonSettingsScript[]
  setIsDirty: (isDirty: boolean) => void
  onScriptsChange: (scripts: CommonSettingsScript[]) => void
}

export const Content: React.FC<IContentProps> = ({
  defaultScripts,
  setIsDirty,
  onScriptsChange
}: IContentProps) => {
  const {t} = useTranslation()
  const {
    state: scripts,
    setNewState: setScripts,
    canUndo,
    canRedo,
    undo,
    redo
  } = useUndoRedo<CommonSettingsScript[]>(defaultScripts)
  const {addErrorWithCustomDialogNotification} = useNotifications()
  const {
    state: isAddSriptDialogOpen,
    setTrue: openAddScriptDialog,
    setFalse: closeAddScriptDialog
  } = useBooleanState(false)
  const [scriptForEdit, setScriptForEdit] =
    useState<CommonSettingsScript | null>(null)
  const handleAddScript = useCallback(
    (formData: IScriptForm) => {
      if (scripts.some(({id}) => id === formData[ScriptFormField.Id])) {
        addErrorWithCustomDialogNotification({
          title: t('Duplicate ID'),
          contentText: t(
            'The ID you have entered already exists. Please choose a unique ID that has not been used before to proceed."'
          )
        })
      } else if (
        !formData[ScriptFormField.Content] &&
        !formData[ScriptFormField.Src]
      ) {
        addErrorWithCustomDialogNotification({
          title: t('Incomplete input'),
          contentText: t(
            "Either the 'Content' or 'Src' field must be filled out to proceed. Please ensure at least one of these fields is completed before continuing."
          )
        })
      } else {
        setScripts((prevScripts) => [
          ...prevScripts,
          {
            id: formData[ScriptFormField.Id],
            content: formData[ScriptFormField.Content] || undefined,
            src: formData[ScriptFormField.Src] || undefined,
            location: formData[ScriptFormField.Location]
          }
        ])
        closeAddScriptDialog()
      }
    },
    [
      addErrorWithCustomDialogNotification,
      closeAddScriptDialog,
      scripts,
      setScripts,
      t
    ]
  )
  const handleSaveScript = useCallback(
    (formData: IScriptForm) => {
      if (
        scripts
          .filter(({id}) => id !== scriptForEdit?.id)
          .some(({id}) => id === formData[ScriptFormField.Id])
      ) {
        addErrorWithCustomDialogNotification({
          title: t('Duplicate ID'),
          contentText: t(
            'The ID you have entered already exists. Please choose a unique ID that has not been used before to proceed."'
          )
        })
      } else if (
        !formData[ScriptFormField.Content] &&
        !formData[ScriptFormField.Src]
      ) {
        addErrorWithCustomDialogNotification({
          title: t('Incomplete input'),
          contentText: t(
            "Either the 'Content' or 'Src' field must be filled out to proceed. Please ensure at least one of these fields is completed before continuing."
          )
        })
      } else {
        setScripts((prevScripts) =>
          prevScripts.map((script) =>
            script.id === scriptForEdit?.id
              ? {
                  id: formData[ScriptFormField.Id],
                  content: formData[ScriptFormField.Content] || undefined,
                  src: formData[ScriptFormField.Src] || undefined,
                  location: formData[ScriptFormField.Location]
                }
              : script
          )
        )
        setScriptForEdit(null)
      }
    },
    [
      addErrorWithCustomDialogNotification,
      scriptForEdit?.id,
      scripts,
      setScripts,
      t
    ]
  )
  const handleDelete = useCallback(
    (scriptId: string) => () =>
      setScripts((prevScripts) =>
        prevScripts.filter(({id}) => id !== scriptId)
      ),
    [setScripts]
  )
  useEffect(() => {
    if (canUndo) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [canUndo, setIsDirty])
  useEffect(() => {
    onScriptsChange(scripts)
  }, [onScriptsChange, scripts])
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'background.paper',
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <IconButton color="primary" disabled={!canUndo} onClick={undo}>
            <UndoIcon />
          </IconButton>
          <IconButton color="primary" disabled={!canRedo} onClick={redo}>
            <RedoIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openAddScriptDialog}
        >
          {t('Add')}
        </Button>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        {scripts.length > 0 ? (
          scripts.map((script) => (
            <ScriptRow
              key={script.id}
              onEditClick={() => setScriptForEdit(script)}
              onDeleteClick={handleDelete(script.id)}
              {...script}
            />
          ))
        ) : (
          <Blank title={t('No script added yet.')} />
        )}
      </Box>
      <AddScriptDialog
        isOpen={isAddSriptDialogOpen}
        onClose={closeAddScriptDialog}
        onAdd={handleAddScript}
      />
      <EditScriptDialog
        script={scriptForEdit}
        onClose={() => setScriptForEdit(null)}
        onSave={handleSaveScript}
      />
    </Box>
  )
}
