import React, {useEffect} from 'react'
import _ from 'lodash'

import {usePingUser, useLogout} from '../../../utils/auth'

const PROLONG_SESSION_POLL = 1000 * 60 * 1 // 1m
const INACTIVITY_POLL = 1000 * 10 // 10s

let LOGOUT_TIMEOUT: number | undefined

export const useProlongSessionOnActivity = () => {
  const logout = useLogout()
  const {data, refetch, loading} = usePingUser()

  useEffect(() => {
    const cb = _.debounce(() => {
      if (!loading && (!data || !data.me)) {
        logout()
      } else {
        // We do refetch only when activity is reported, so that
        // user is not kept logged-in till infinity
        refetch()
        console.info('Session prolonged ...') // eslint-disable-line
      }
    }, PROLONG_SESSION_POLL)

    window.addEventListener('mousemove', cb)
    window.addEventListener('keypress', cb)

    return () => {
      window.removeEventListener('mousemove', cb)
      window.removeEventListener('keypress', cb)
      cb.cancel()
    }
  })
}

// TODO: this is according to docs but should not it be a lower value (1 hour?)
const INACTIVITY_INTERVAL = 1000 * 60 * 60 * 3 // three hours

export const useLogoutOnInactivity = () => {
  const logout = useLogout()

  useEffect(() => {
    const cb = _.debounce(() => {
      window.clearTimeout(LOGOUT_TIMEOUT)
      LOGOUT_TIMEOUT = window.setTimeout(logout, INACTIVITY_INTERVAL)
    }, INACTIVITY_POLL)

    window.addEventListener('mousemove', cb)
    window.addEventListener('keypress', cb)

    return () => {
      window.removeEventListener('mousemove', cb)
      window.removeEventListener('keypress', cb)
      cb.cancel()
    }
  })
}

export const ActivityListener: React.FC = () => {
  useProlongSessionOnActivity()
  useLogoutOnInactivity()

  return <div />
}
