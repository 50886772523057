import {Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  NarrowTicketItemPropertiesFragment,
  NarrowTourItemPropertiesFragment,
  TicketItemCheckStatus,
  TourItemCheckStatus
} from '../../../../../__generated__/schema'
import {
  DrawerSection,
  EntityStateChip,
  IDrawerSectionProps
} from '../../../../common'
import {DividerSeparatedInfoRow} from '../../../../common/DividerSeparatedInfoRow'
import {COLOR_CONF} from '../../../../constants'
import {isNarrowTicketItemPropertiesFragment} from '../types'

const useGetCheckStatusColorLabelAndDescription = () => {
  const {t} = useTranslation()
  return (checkStatus: TicketItemCheckStatus | TourItemCheckStatus) => {
    switch (checkStatus) {
      case TicketItemCheckStatus.CheckedIn:
      case TourItemCheckStatus.CheckedIn:
        return {
          color: COLOR_CONF.GREEN,
          label: t('Checked in'),
          description: t('Customer is already on event')
        }
      case TicketItemCheckStatus.CheckedOut:
      case TourItemCheckStatus.CheckedOut:
        return {
          color: COLOR_CONF.RED,
          label: t('Checked out'),
          description: t('Customer left event')
        }
      case TourItemCheckStatus.PartiallyChecked:
        return {
          color: COLOR_CONF.YELLOW,
          label: t('Partially checked'),
          description: t('Some attendees are still expected.')
        }
      case TicketItemCheckStatus.Unchecked:
      case TourItemCheckStatus.Unchecked:
      default:
        return {
          color: COLOR_CONF.YELLOW,
          label: t('Unchecked'),
          description: t('Ticket has not been checked yet')
        }
    }
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  },
  ticketCheckStatus: {
    display: 'flex',
    alignItems: 'baseline',
    alignContent: 'baseline',
    gap: theme.spacing(1)
  }
}))

interface IGeneralTicketInfoSectionProps {
  item: NarrowTicketItemPropertiesFragment | NarrowTourItemPropertiesFragment
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const GeneralTicketInfoSection: React.FC<IGeneralTicketInfoSectionProps> =
  ({item, drawerSectionProps}: IGeneralTicketInfoSectionProps) => {
    const {t} = useTranslation()
    const getCheckStatusColorLabelAndDescription =
      useGetCheckStatusColorLabelAndDescription()
    const {color, label, description} = getCheckStatusColorLabelAndDescription(
      isNarrowTicketItemPropertiesFragment(item)
        ? item.ticketItemCheckStatus
        : item.tourItemCheckStatus
    )
    const classes = useStyles()
    return (
      <DrawerSection {...drawerSectionProps}>
        <div className={classes.root}>
          <Typography variant="h6">{item.passCode}</Typography>
          <div className={classes.ticketCheckStatus}>
            <EntityStateChip label={label} colorConf={color} size="small" />
            <Typography variant="caption" color="textSecondary">
              {description}
            </Typography>
          </div>
          {item.passCodeCheckActivityLog &&
            item.passCodeCheckActivityLog.items.length > 0 && (
              <DividerSeparatedInfoRow
                information={[
                  {
                    caption: t('Total checks'),
                    value: item.totalPassCodeChecksCount
                  },
                  {
                    caption: t('Checked in'),
                    value: item.checkedInCount
                  },
                  {
                    caption: t('Checked out'),
                    value: item.checkedOutCount
                  }
                ]}
              />
            )}
        </div>
      </DrawerSection>
    )
  }
