import EditIcon from '@mui/icons-material/Edit'
import {Box, Button} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ProductGroupQuery
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useProductParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useProductGroup} from '../graphql'
import {UpdateProductGroupDrawer} from '../UpdateProductGroupDrawer'
import {AvailabilityDrawer} from './AvailabilityDrawer'
import {AvailabilitySection} from './AvailabilitySection'
import {GeneralSection} from './GeneralSection'
import {ProductsSection} from './ProductsSection'

const useItems = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    availability: {
      id: 'availability',
      label: t('Availability')
    },
    products: {
      id: 'products',
      label: t('Products')
    }
  }
}

export const ProductGroupDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {productGroupId} = useProductParams()
  const {data, loading, error, refetch} = useProductGroup(productGroupId)
  const [title, setTitle] = useState<string>(t('Product group'))
  const items = useItems()
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.productGroups.index()),
    [history]
  )
  const handleEditButtonClick = useCallback(() => {
    if (data) {
      history.push(routeTo.admin.productGroups.edit(data.productGroup.id))
    }
    return undefined
  }, [data, history])
  const handleExited = useCallback(
    () => history.push(routeTo.admin.productGroups.detail(productGroupId)),
    [history, productGroupId]
  )
  useEffect(() => {
    if (data) {
      setTitle(data.productGroup.name)
    }
  }, [data])
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        P([PermissionCode.UpdateProductGroup]) ? (
          <Footer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditButtonClick}
              startIcon={<EditIcon />}
            >
              {t('Edit')}
            </Button>
          </Footer>
        ) : undefined
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<ProductGroupQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading product group')}
          dataCondition={(data) => Boolean(data.productGroup)}
        >
          {({productGroup}) => (
            <CenteredLayout>
              <SingleSideNavigationList items={items} />
              <Box
                sx={{py: 3, display: 'flex', flexDirection: 'column', gap: 4}}
              >
                <GeneralSection
                  id={items.general.id}
                  productGroup={productGroup}
                />
                <AvailabilitySection
                  id={items.availability.id}
                  label={items.availability.label}
                  productGroup={productGroup}
                />
                <ProductsSection
                  id={items.products.id}
                  label={items.products.label}
                  productGroup={productGroup}
                  refetchProductGroup={refetch}
                />
              </Box>
            </CenteredLayout>
          )}
        </RenderOnData>
        {P([
          PermissionCode.ReadProductGroup,
          PermissionCode.UpdateProductGroup
        ]) && (
          <Route
            path={routeTo.admin.productGroups.edit(':productGroupId')}
            exact
          >
            <UpdateProductGroupDrawer onExited={handleExited} />
          </Route>
        )}
        {P([
          PermissionCode.UpsertProductGroupAvailability,
          PermissionCode.ReadProductGroup
        ]) && (
          <Route
            path={routeTo.admin.productGroups.availability(':productGroupId')}
            exact
          >
            <AvailabilityDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
