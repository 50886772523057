import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {Button} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    '&.MuiButtonBase-root': {
      color: 'white',
      padding: theme.spacing(1),
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
      }
    }
  }
}))

interface IPrivateCheckboxProps {
  isChecked: boolean
  onClick: () => void
}

export const PrivateCheckbox: React.FC<IPrivateCheckboxProps> = ({
  isChecked,
  onClick
}: IPrivateCheckboxProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Button
      variant="text"
      className={classes.button}
      startIcon={isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {t('Private')}
    </Button>
  )
}
