import {useCallback, useState} from 'react'

type StateWithHistory<T> = {
  past: T[]
  present: T
  future: T[]
}

type UndoRedoReturn<T> = {
  state: T
  setNewState: (newState: T | ((prevState: T) => T)) => void
  undo: () => void
  redo: () => void
  canUndo: boolean
  canRedo: boolean
  resetHistory: () => void
}

export const useUndoRedo = <T>(initialState: T): UndoRedoReturn<T> => {
  const [state, setState] = useState<StateWithHistory<T>>({
    past: [],
    present: initialState,
    future: []
  })

  const setNewState = useCallback((newState: T | ((prevState: T) => T)) => {
    setState((prevState) => {
      const updatedState =
        typeof newState === 'function'
          ? (newState as (prevState: T) => T)(prevState.present)
          : newState
      return {
        past: [...prevState.past, prevState.present],
        present: updatedState,
        future: []
      }
    })
  }, [])

  const undo = useCallback(() => {
    setState((prevState) => {
      if (prevState.past.length === 0) return prevState

      const previous = prevState.past[prevState.past.length - 1]
      const newPast = prevState.past.slice(0, prevState.past.length - 1)

      return {
        past: newPast,
        present: previous,
        future: [prevState.present, ...prevState.future]
      }
    })
  }, [])

  const redo = useCallback(() => {
    setState((prevState) => {
      if (prevState.future.length === 0) return prevState

      const next = prevState.future[0]
      const newFuture = prevState.future.slice(1)

      return {
        past: [...prevState.past, prevState.present],
        present: next,
        future: newFuture
      }
    })
  }, [])

  const resetHistory = useCallback(
    () =>
      setState({
        past: [],
        present: initialState,
        future: []
      }),
    [initialState]
  )

  return {
    state: state.present,
    setNewState,
    undo,
    redo,
    canUndo: state.past.length > 0,
    canRedo: state.future.length > 0,
    resetHistory
  }
}
