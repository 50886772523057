import {Button, Divider, Theme, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {TFunction} from 'i18next'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  AuditoriumLayoutPricingState,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useSelector} from '../../../../../../editor/redux'

import {DisplayMode} from '../../../../../../editor/redux/displayMode/reducer'
import {selectedObjectsSelector} from '../../../../../../editor/redux/objects/selectors'
import {IDimensions} from '../../../../../../hooks/dimensions'
import {useEnsurePermissions} from '../../../../../../utils/auth'

import {
  BottomActionsBar,
  BottomActionsBarButtonsGroup,
  BottomActionsBarWrapper,
  BottomBackButton,
  RenderOnData
} from '../../../../../common'
import {PageWithHeaderTemplate} from '../../../../../common/PageWithHeaderTemplate'
import {SplitButton} from '../../../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../../Header'

import {DeleteLayoutPricingButton} from '../../EditActionsDialogs'
import {
  AuditoriumEditorLayout,
  EditorSideBar,
  FullWidthEditor
} from '../../editorUtils'
import {SelectedEditorItemsStatus} from '../../FloorPlanStatus'
import {
  EditorForm,
  IEditorFormData,
  LAYOUT_PRICING_FORM_ID
} from '../EditorForm'
import {
  ITransformedPricingScreenData,
  useGetPricingScreenData
} from '../graphql'

import {TicketTypeSectionWrapper} from '../ticketTypes'
import {TicketTypeList} from '../ticketTypes/list'
import {TicketTypeSelectionList} from '../ticketTypes/ListSelection'
import {
  InvalidStatusInfo,
  useCanActivateLayoutPricing
} from './InvalidStatusInfo'
import {useOnSubmitActions} from './submitActions'
import {getUpdateOptions, StateRenderer, useInitLayouts} from './utils'

const getStatusDescription = ({
  status,
  t
}: {
  status: AuditoriumLayoutPricingState
  t: TFunction
}) => {
  switch (status) {
    case AuditoriumLayoutPricingState.Draft:
      return t(
        'To use layout pricing in Event Planner, it must be activated, first.'
      )
    case AuditoriumLayoutPricingState.Active:
      return t('Layout pricing is ready to be used for new events.')
    case AuditoriumLayoutPricingState.Archived:
      return t(
        "The layout pricing doesn't show in event planner and you are not able to use it for new events. You can change this option at any time."
      )
    default:
      return null
  }
}

interface IStateInfoProps {
  status: AuditoriumLayoutPricingState
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'inline-grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'center'
  }
}))

const StateInfo: React.FC<IStateInfoProps> = ({status}: IStateInfoProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.root}>
      <StateRenderer status={status} />
      <Typography variant="caption" color="textSecondary">
        {getStatusDescription({status, t})}
      </Typography>
    </div>
  )
}

interface IEditLayoutPricingContentProps extends IDimensions {
  data: ITransformedPricingScreenData
}

const EditLayoutPricingContent: React.FC<IEditLayoutPricingContentProps> = ({
  width,
  height,
  data
}: IEditLayoutPricingContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()

  const selectedObjects = useSelector(selectedObjectsSelector)

  const {onSubmit, onDelete, onArchive, onActivate} = useOnSubmitActions(
    data.pricingData
  )
  const {register, handleSubmit} = useForm<IEditorFormData>({
    defaultValues: {
      name: data.pricingData.name,
      maxTicketsPerOrder: `${data.pricingData.maxTicketsPerOrder}`,
      maxNumberOfOccupiedSeatsOnEcommerce:
        data.pricingData.maxNumberOfOccupiedSeatsOnEcommerce,
      maxNumberOfOccupiedSeatsOnRetail:
        data.pricingData.maxNumberOfOccupiedSeatsOnRetail
    }
  })

  const canActivatePricing = useCanActivateLayoutPricing(data)
  const updateOptions = getUpdateOptions({
    handleSubmit,
    onArchive,
    onActivate,
    status: data.pricingData.status,
    canActivatePricing,
    P
  })

  return (
    <BottomActionsBarWrapper>
      <FullWidthEditor {...{width, height}} displayMode={DisplayMode.PRICING}>
        <>
          <EditorSideBar>
            <StateInfo status={data.pricingData.status} />
            <EditorForm
              auditoriumCapacity={data.layoutData.capacity}
              onSubmit={handleSubmit(onSubmit)}
              register={register}
            />
          </EditorSideBar>
          <InvalidStatusInfo data={data} />
          <TicketTypeSectionWrapper>
            {(ticketTypes) => {
              // Note: eslint/prettier conflict when written as single return
              if (
                selectedObjects.length === 0 ||
                data.pricingData.ticketTypes.length === 0 ||
                // TODO: should we instead disable selection?
                data.pricingData.status !== AuditoriumLayoutPricingState.Draft
              ) {
                return (
                  <TicketTypeList
                    ticketTypes={ticketTypes}
                    layoutPricingState={data.pricingData.status}
                  />
                )
              }
              return <TicketTypeSelectionList ticketTypes={ticketTypes} />
            }}
          </TicketTypeSectionWrapper>
        </>
      </FullWidthEditor>
      <BottomActionsBar justify="space-between">
        <SelectedEditorItemsStatus />
        <BottomActionsBarButtonsGroup>
          {data.pricingData.status === AuditoriumLayoutPricingState.Draft &&
            P([PermissionCode.DeleteAuditoriumLayoutPricing]) && (
              <>
                <DeleteLayoutPricingButton onSubmit={onDelete} />
                <Divider orientation="vertical" />
              </>
            )}
          <BottomBackButton />
          {updateOptions.length > 0 ? (
            <SplitButton Options={updateOptions}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                form={LAYOUT_PRICING_FORM_ID}
              >
                {t('Save')}
              </Button>
            </SplitButton>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              form={LAYOUT_PRICING_FORM_ID}
            >
              {t('Save')}
            </Button>
          )}
        </BottomActionsBarButtonsGroup>
      </BottomActionsBar>
    </BottomActionsBarWrapper>
  )
}

const EditLayoutPricingWrapper: React.FC<IDimensions> = ({
  width,
  height
}: IDimensions) => {
  const {t} = useTranslation()
  const {data, loading, error} = useGetPricingScreenData()

  useInitLayouts(data.layoutData, data.pricingData)

  return (
    <RenderOnData
      loading={loading}
      error={error}
      data={data}
      dataCondition={(data) => Boolean(data.layoutData && data.pricingData)}
      errorMessage={t<string>('Could not load layout pricing.')}
    >
      {(data) => (
        <EditLayoutPricingContent
          {...{width, height, data}}
          data={data as ITransformedPricingScreenData}
        />
      )}
    </RenderOnData>
  )
}

export const EditLayoutPricing = () => {
  const {t} = useTranslation()

  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader title={t('Layout pricing editor')} hasArrowBackIcon />
      }
    >
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage venues.')}
      >
        <AuditoriumEditorLayout>
          {(dimensions) => <EditLayoutPricingWrapper {...dimensions} />}
        </AuditoriumEditorLayout>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
