import SearchIcon from '@mui/icons-material/Search'
import {Box, SvgIconProps, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    height: '100%',
    alignItems: 'center',
    justifyItems: 'center'
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: 360,
    width: 360,
    display: 'grid',
    gap: theme.spacing(3),
    alignItems: 'center',
    justifyItems: 'center',
    textAlign: 'center'
  },
  actions: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2)
  },
  icon: {
    color: theme.palette.text.secondary,
    width: 64,
    height: 64
  },
  description: {
    paddingTop: theme.spacing(2)
  },
  centerContentVertically: {
    gridTemplateRows: '1fr'
  },
  centerContentVerticallyInTopHalf: {
    gridTemplateRows: '1fr 1fr'
  }
}))

export enum BlankContentPosition {
  Center = 'center',
  TopHalfCenter = 'topHalfCenter'
}

export interface IBlankProps {
  title: string
  IconComp?: React.FC<SvgIconProps>
  description?: string
  actions?: React.ReactNode | React.ReactNodeArray
  contentPosition?: BlankContentPosition
  contentSx?: SxProps<Theme>
}

export const Blank: React.FC<IBlankProps> = ({
  title,
  description,
  IconComp,
  actions,
  contentPosition = BlankContentPosition.TopHalfCenter,
  contentSx
}: IBlankProps) => {
  const classes = useStyles()
  return (
    <div
      className={cn(classes.root, {
        [classes.centerContentVertically]:
          contentPosition === BlankContentPosition.Center,
        [classes.centerContentVerticallyInTopHalf]:
          contentPosition === BlankContentPosition.TopHalfCenter
      })}
    >
      <Box className={classes.content} sx={contentSx}>
        {IconComp && <IconComp className={classes.icon} />}
        <div>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <Typography className={classes.description}>
              {description}
            </Typography>
          )}
        </div>
        {actions && <div className={classes.actions}>{actions}</div>}
      </Box>
    </div>
  )
}

export const BlankSearch = () => {
  const {t} = useTranslation()
  return (
    <Blank
      title={t('No search results found')}
      description={t(
        'Try another search, or click the arrow in the search box to find a results by advanced search.'
      )}
      IconComp={SearchIcon}
    />
  )
}
