import {get} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {DetailLibraryShowPropertiesFragment} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../../utils/routes'
import {useGetShowTitle} from '../../../../../utils/translations'
import {ShowSection} from '../../../../constants'
import {CenteredLayout} from '../../Layout'
import {useUpsertLibraryShowCrew} from '../graphql'
import {ShowCrewForm} from '../ShowCrewForm'
import {
  IShowCrewFieldsProps,
  IShowCrewForm,
  showCrewForm,
  ShowCrewFormField
} from '../types'
import {
  mapFormValuesToShowCrewRoleInput,
  mapShowCrewRoleInputToFormValues
} from '../utils'
import {EditLibraryPageDetailTemplate} from './EditLibraryPageDetailTemplate'

interface IEditLibraryShowCrewProps {
  libraryShow: DetailLibraryShowPropertiesFragment
}

export const EditLibraryShowCrew: React.FC<IEditLibraryShowCrewProps> = ({
  libraryShow
}: IEditLibraryShowCrewProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const getShowTitle = useGetShowTitle()
  const upsertLibraryShowCrew = useUpsertLibraryShowCrew()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const methods = useForm<IShowCrewForm>()
  const [crewFields, setCrewFields] = useState<IShowCrewFieldsProps[]>([])
  const showTitle = getShowTitle({
    translations: libraryShow.translations,
    originalTitle: libraryShow.originalTitle,
    fallbackTitle: t('Library show')
  })
  useEffect(() => {
    if (libraryShow.crew.length > 0) {
      setCrewFields(mapShowCrewRoleInputToFormValues(libraryShow.crew))
    }
  }, [libraryShow.crew])
  const handleClose = useCallback(
    () => history.replace(routeTo.admin.library.home()),
    [history]
  )
  const handleSubmit = useCallback(async () => {
    const fields = methods.getValues()
    const data = crewFields.map((f) => ({
      crewRoleCode: get(fields, `${ShowCrewFormField.CrewRoleCode}[${f.id}]`),
      name: get(fields, `${ShowCrewFormField.PersonName}[${f.id}]`),
      description: get(fields, `${ShowCrewFormField.Description}[${f.id}]`)
    }))
    try {
      setShowBackdrop(true)
      await upsertLibraryShowCrew({
        id: libraryShow.id,
        data: mapFormValuesToShowCrewRoleInput(data)
      })
      addInfoNotification(t('Crew has been updated successfully'))
    } catch (error) {
      defaultErrorHandler(error, t('Error while updating show crew'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    crewFields,
    defaultErrorHandler,
    libraryShow.id,
    methods,
    setShowBackdrop,
    t,
    upsertLibraryShowCrew
  ])
  return (
    <EditLibraryPageDetailTemplate
      showName={showTitle}
      onClose={handleClose}
      formId={showCrewForm}
      currentTab={ShowSection.Crew}
      isFooterVisible
    >
      <CenteredLayout>
        <FormContext {...methods}>
          <ShowCrewForm
            onSubmit={handleSubmit}
            crewFields={crewFields}
            setCrewFields={setCrewFields}
            showType={libraryShow.typeCode}
          />
        </FormContext>
      </CenteredLayout>
    </EditLibraryPageDetailTemplate>
  )
}
