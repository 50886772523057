import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventsStatisticsFilterInput,
  GetLightweightCostCentersQuery,
  GetLightweightDivisionsQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightMarketingLabelsQuery,
  LightweightToursQuery,
  LightweightToursQueryVariables,
  LightweightVenuesQuery,
  LightweightVenuesQueryVariables,
  PermissionCode,
  TourTimeSlotsStatisticsFilterInput
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {
  LIGHTWEIGHT_TOURS,
  LIGHTWEIGHT_VENUES,
  useGetLightweightCostCenters,
  useGetLightweightDivisions,
  useGetLightweightEventCategories,
  useGetLightweightMarketingLabels
} from '../graphql'

const mapTourTimeSlotNameToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  tourTimeSlotName: string | undefined
): TourTimeSlotsStatisticsFilterInput => ({
  ...filter,
  tourTimeSlotName: tourTimeSlotName || undefined
})

const mapDivisionIdToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  divisionId: number | undefined
): TourTimeSlotsStatisticsFilterInput => ({
  ...filter,
  divisionIds: divisionId ? [divisionId] : undefined
})

const mapVenueIdToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  venueId: number | undefined
): TourTimeSlotsStatisticsFilterInput => ({
  ...filter,
  venueIds: venueId ? [venueId] : undefined
})

const mapTourIdToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  tourId: number | undefined
): TourTimeSlotsStatisticsFilterInput => ({
  ...filter,
  tourId
})

const mapCostCenterToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  costCenterId: number | undefined
): TourTimeSlotsStatisticsFilterInput => ({
  ...filter,
  costCenterId
})

const mapEventCategoryIdToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  eventCategoryId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  eventCategoryId
})

const mapMarketingLabelIdToFilter = (
  filter: TourTimeSlotsStatisticsFilterInput,
  marketingLabelId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  marketingLabelId
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getTourTimeSlotNameFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput
  ) => filter.tourTimeSlotName || undefined
  const getDivisionFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionIds && filter.divisionIds.length) {
      const filterDivisionId = filter.divisionIds[0]
      const division = divisions.find(
        (division) => division.id === filterDivisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filterDivisionId})
    }
    return undefined
  }
  const getVenueFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput,
    venues: LightweightVenuesQuery['venues']
  ) => {
    if (filter.venueIds && filter.venueIds.length) {
      const filterVenueId = filter.venueIds[0]
      const venue = venues.find((venue) => venue.id === filterVenueId)
      return venue
        ? t('Venue: {{name}}', {name: venue.name})
        : t('Venue ID: {{id}}', {id: filterVenueId})
    }
    return undefined
  }
  const getTourFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput,
    tours: LightweightToursQuery['tours']['items']
  ) => {
    if (filter.tourId) {
      const tour = tours.find((tour) => tour.id === filter.tourId)
      return tour
        ? t('Tour: {{name}}', {name: tour.name})
        : t('Tour ID: {{id}}', {id: filter.tourId})
    }
    return undefined
  }
  const getCostCenterFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput,
    costCenters: GetLightweightCostCentersQuery['costCenters']
  ) => {
    if (filter.costCenterId) {
      const costCenter = costCenters.find(
        (costCenter) => costCenter.id === filter.costCenterId
      )
      return costCenter
        ? t('Cost center: {{name}}', {name: costCenter.name})
        : t('Cost center ID: {{id}}', {id: filter.costCenterId})
    }
    return undefined
  }
  const getEventCategoryFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput,
    eventCategories: GetLightweightEventCategoriesQuery['eventCategories']
  ) => {
    if (filter.eventCategoryId) {
      const eventCategory = eventCategories.find(
        ({id}) => id === filter.eventCategoryId
      )
      return eventCategory
        ? t('Event category: {{name}}', {name: eventCategory.name})
        : t('Event category ID: {{id}}', {id: filter.eventCategoryId})
    }
    return undefined
  }
  const getMarketingLabelFromSearchObject = (
    filter: TourTimeSlotsStatisticsFilterInput,
    marketingLabels: GetLightweightMarketingLabelsQuery['marketingLabels']
  ) => {
    if (filter.marketingLabelId) {
      const marketingLabel = marketingLabels.find(
        ({id}) => id === filter.marketingLabelId
      )
      return marketingLabel
        ? t('Marketing label: {{name}}', {name: marketingLabel.name})
        : t('Marketing label ID: {{id}}', {id: filter.marketingLabelId})
    }
    return undefined
  }
  return {
    getTourTimeSlotNameFromSearchObject,
    getDivisionFromSearchObject,
    getVenueFromSearchObject,
    getTourFromSearchObject,
    getCostCenterFromSearchObject,
    getEventCategoryFromSearchObject,
    getMarketingLabelFromSearchObject
  }
}

interface ITourTimeSlotsStatisticsSearchProps {
  onFilterChange: (filter: TourTimeSlotsStatisticsFilterInput) => void
}

export const TourTimeSlotsStatisticsSearch: React.FC<ITourTimeSlotsStatisticsSearchProps> =
  ({onFilterChange}: ITourTimeSlotsStatisticsSearchProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {divisions} = useGetLightweightDivisions()
    const {data: venuesData} = useQuery<
      LightweightVenuesQuery,
      LightweightVenuesQueryVariables
    >(LIGHTWEIGHT_VENUES, {
      fetchPolicy: 'network-only'
    })
    const {data: toursData} = useQuery<
      LightweightToursQuery,
      LightweightToursQueryVariables
    >(LIGHTWEIGHT_TOURS, {
      fetchPolicy: 'network-only',
      variables: {
        paginationInput: {offset: 0, limit: 300}
      }
    })
    const {costCenters} = useGetLightweightCostCenters()
    const {eventCategories} = useGetLightweightEventCategories()
    const {marketingLabels} = useGetLightweightMarketingLabels()
    const {
      getTourTimeSlotNameFromSearchObject,
      getDivisionFromSearchObject,
      getVenueFromSearchObject,
      getTourFromSearchObject,
      getCostCenterFromSearchObject,
      getEventCategoryFromSearchObject,
      getMarketingLabelFromSearchObject
    } = useGetFieldFromSearchObject()
    const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
      getTourTimeSlotNameFromSearchObject,
      (filter) => getDivisionFromSearchObject(filter, divisions),
      (filter) => getVenueFromSearchObject(filter, venuesData?.venues || []),
      (filter) =>
        getTourFromSearchObject(filter, toursData?.tours?.items || []),
      (filter) => getCostCenterFromSearchObject(filter, costCenters),
      (filter) => getEventCategoryFromSearchObject(filter, eventCategories),
      (filter) => getMarketingLabelFromSearchObject(filter, marketingLabels)
    )
    const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
      ({id, name: label}) => ({
        id,
        label
      })
    )
    const venueSelectOptions: ISelectOption<number>[] = (
      venuesData?.venues || []
    ).map(({id, name: label}) => ({id, label}))
    const tourSelectOptions: ISelectOption<number>[] = (
      toursData?.tours.items || []
    ).map(({id, name: label}) => ({id, label}))
    const costCenterSelectOptions: ISelectOption<number>[] = costCenters.map(
      ({id, name: label}) => ({id, label})
    )
    const eventCategorySelectOptions: ISelectOption<number>[] =
      eventCategories.map(({id, name}) => ({
        id,
        label: name
      }))

    const marketingLabelSelectOptions: ISelectOption<number>[] =
      marketingLabels.map(({id, name}) => ({
        id,
        label: name
      }))
    return (
      <Search<TourTimeSlotsStatisticsFilterInput>
        storageKey="TOUR_TIME_SLOTS_STATISTICS"
        placeholder={t('Search for tour')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapTourTimeSlotNameToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={{}}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            <AdvancedSearchTextRow<TourTimeSlotsStatisticsFilterInput>
              label={t('Time slot name')}
              placeholder={t('Enter time slot name')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapTourTimeSlotNameToFilter}
              value={advancedSearchObject.tourTimeSlotName || undefined}
            />
            <AdvancedSearchSelectRow<TourTimeSlotsStatisticsFilterInput, number>
              label={t('Division')}
              value={
                advancedSearchObject.divisionIds
                  ? advancedSearchObject.divisionIds[0]
                  : undefined
              }
              options={divisionSelectOptions}
              mapSelectValueToSearchObject={mapDivisionIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            {P([PermissionCode.ReadVenues]) && (
              <AdvancedSearchSelectRow<
                TourTimeSlotsStatisticsFilterInput,
                number
              >
                label={t('Venue')}
                value={
                  advancedSearchObject.venueIds
                    ? advancedSearchObject.venueIds[0]
                    : undefined
                }
                options={venueSelectOptions}
                mapSelectValueToSearchObject={mapVenueIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadTours]) && (
              <AdvancedSearchSelectRow<
                TourTimeSlotsStatisticsFilterInput,
                number
              >
                label={t('Tour')}
                value={advancedSearchObject.tourId || undefined}
                options={tourSelectOptions}
                mapSelectValueToSearchObject={mapTourIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadCostCenters]) && (
              <AdvancedSearchSelectRow<
                TourTimeSlotsStatisticsFilterInput,
                number
              >
                label={t('Cost center')}
                value={advancedSearchObject.costCenterId || undefined}
                options={costCenterSelectOptions}
                mapSelectValueToSearchObject={mapCostCenterToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadEventCategories]) && (
              <AdvancedSearchSelectRow<
                TourTimeSlotsStatisticsFilterInput,
                number
              >
                label={t('Event category')}
                value={advancedSearchObject.eventCategoryId || undefined}
                options={eventCategorySelectOptions}
                mapSelectValueToSearchObject={mapEventCategoryIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadMarketingLabels]) && (
              <AdvancedSearchSelectRow<
                TourTimeSlotsStatisticsFilterInput,
                number
              >
                label={t('Marketing label')}
                value={advancedSearchObject.marketingLabelId || undefined}
                options={marketingLabelSelectOptions}
                mapSelectValueToSearchObject={mapMarketingLabelIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
          </AdvancedSearchBase>
        )}
      />
    )
  }
