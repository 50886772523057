import {
  Box,
  Button,
  Divider,
  List,
  ListItem as MuiListItem,
  SxProps,
  Typography
} from '@mui/material'
import {omit} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import validator from 'validator'
import {ApiSeatState} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {safeSum} from '../../../../../utils/money'
import {Footer} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {useChangeEventZoneSeatsAvailability} from '../graphql'
import {
  AggregatedSeatsStateCounts,
  IZoneSeatsCountForm,
  ZoneSeatsCountFormField
} from './types'

const getStateCounts = (
  form: IZoneSeatsCountForm
): {
  [ApiSeatState.Available]: number
  [ApiSeatState.Disabled]: number
  [ApiSeatState.Hidden]: number
} => {
  const disabled = parseInt(form[ZoneSeatsCountFormField.Disabled], 10) ?? 0
  const selected = parseInt(form[ZoneSeatsCountFormField.Selected], 10) ?? 0
  return {
    [ApiSeatState.Available]: selected - disabled,
    [ApiSeatState.Disabled]: disabled,
    [ApiSeatState.Hidden]: 0
  }
}

interface IListItemProps {
  title: string
  description: string
  children: React.ReactNode
}

const ListItem: React.FC<IListItemProps> = ({
  title,
  description,
  children
}: IListItemProps) => {
  return (
    <MuiListItem
      sx={{
        display: 'grid',
        gridTemplateAreas: `
          "input title"
          "input description"
        `,
        gridTemplateColumns: '100px 1fr',
        rowGap: 0.5,
        columnGap: 2
      }}
    >
      <Box sx={{gridArea: 'input'}}>{children}</Box>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    </MuiListItem>
  )
}

const getDefaultValueForCount = (count?: number) =>
  count ? count.toString() : '0'

const getDefaultValues = (
  states: Partial<AggregatedSeatsStateCounts>,
  statesBeforeSelected: Partial<AggregatedSeatsStateCounts>
): Partial<IZoneSeatsCountForm> => ({
  [ZoneSeatsCountFormField.Other]: getDefaultValueForCount(
    safeSum(Object.values(omit(states, ApiSeatState.SelectedByMe))) ?? 0
  ),
  [ZoneSeatsCountFormField.Disabled]: getDefaultValueForCount(
    statesBeforeSelected[ApiSeatState.Disabled]
  ),
  [ZoneSeatsCountFormField.Selected]: getDefaultValueForCount(
    states[ApiSeatState.SelectedByMe]
  )
})

export const useDisabledSeatsCountValidator = (max: number) => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isInt(value, {max})
      ? t('Can not set more seats than available!', {max})
      : undefined
}

interface IEditZoneSeatStatesContentProps {
  selectedUuid: string
  states: Partial<AggregatedSeatsStateCounts>
  statesBeforeSelected: Partial<AggregatedSeatsStateCounts>
  eventId: number
  onCancelButtonClick: () => void
  sx?: SxProps<Theme>
}

export const EditZoneSeatStatesContent: React.FC<IEditZoneSeatStatesContentProps> =
  ({
    selectedUuid,
    eventId,
    states,
    statesBeforeSelected,
    onCancelButtonClick,
    sx
  }: IEditZoneSeatStatesContentProps) => {
    const {t} = useTranslation()
    const isNonNegativeInteger = useIsNonNegativeInteger()
    const selectedZoneSeatsCount = states[ApiSeatState.SelectedByMe] ?? 0
    const disabledSeatsCountValidator = useDisabledSeatsCountValidator(
      selectedZoneSeatsCount
    )
    const {
      handleSubmit,
      register,
      setValue,
      reset,
      watch,
      errors,
      triggerValidation
    } = useForm<IZoneSeatsCountForm>({
      defaultValues: getDefaultValues(states, statesBeforeSelected)
    })
    useEffect(() => {
      reset(getDefaultValues(states, statesBeforeSelected))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset, JSON.stringify(statesBeforeSelected), JSON.stringify(states)])
    const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
    const changeEventZoneSeatsAvailability =
      useChangeEventZoneSeatsAvailability(setShowBackdrop, defaultErrorHandler)
    const _onSubmit = useCallback(
      (form: IZoneSeatsCountForm) => {
        const counts = getStateCounts(form)
        const changes = Object.entries(counts).reduce(
          (
            acc: {
              fromState: ApiSeatState
              toState: ApiSeatState
            }[],
            entry
          ) => {
            const state = entry[0] as ApiSeatState
            const count = entry[1]
            return count > 0
              ? [
                  ...acc,
                  {
                    fromState: ApiSeatState.SelectedByMe,
                    toState: state,
                    count
                  }
                ]
              : acc
          },
          []
        )
        changeEventZoneSeatsAvailability({eventId, uuid: selectedUuid}, changes)
      },
      [changeEventZoneSeatsAvailability, eventId, selectedUuid]
    )
    return (
      <Box
        onSubmit={handleSubmit(_onSubmit)}
        component="form"
        sx={{
          ...sx,
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr auto',
          height: '100%'
        }}
      >
        <Typography variant="subtitle1" sx={{pt: 3, px: 3}}>
          {t('Zone ticket states')}
        </Typography>
        <List>
          <ListItem
            title={t('Other')}
            description={t(
              'Can not be edited. Seats in occupied, reserved or purchased states.'
            )}
          >
            <UncontrolledFormTextInput<IZoneSeatsCountForm>
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
              name={ZoneSeatsCountFormField.Other}
              disabled
            />
          </ListItem>
          <Divider sx={{mx: 1}} />
          <ListItem
            title={t('Available')}
            description={t('It is possible to click on seat in online and POS')}
          >
            <UncontrolledFormTextInput<IZoneSeatsCountForm>
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
              name={ZoneSeatsCountFormField.Selected}
              disabled
            />
          </ListItem>
          <Divider sx={{mx: 1}} />
          <ListItem
            title={t('Disabled')}
            description={t(
              "Seats can't be added to cart and are visible for cashiers and customers."
            )}
          >
            <UncontrolledFormTextInput<IZoneSeatsCountForm>
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
              validationOptions={{
                validate: {
                  isNonNegativeInteger,
                  disabledSeatsCountValidator
                },
                required: true
              }}
              name={ZoneSeatsCountFormField.Disabled}
            />
          </ListItem>
        </List>

        <Footer sx={{height: 64}}>
          <Button color="primary" onClick={onCancelButtonClick}>
            {t('Cancel')}
          </Button>
          <Button type="submit" variant="contained">
            {t('Apply')}
          </Button>
        </Footer>
      </Box>
    )
  }
