import Konva from 'konva'
import _ from 'lodash'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react'
import {Group, Layer, Transformer} from 'react-konva'

import {SHAPE_ROTATION_ROUND_FACTOR} from '../config'
import {cursorMap} from '../cursorMap'
import {useSelector} from '../redux'
import {canvasSelector} from '../redux/canvas/selectors'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {isShiftActiveSelector} from '../redux/keyboardKeys/selectors'
import {useObjectsActions} from '../redux/objects/actions'
import {
  isResizeEnabledSelector,
  selectedObjectsSelector
} from '../redux/objects/selectors'
import {useSidePanelActions} from '../redux/sidePanel'
import {CanvasObjectType} from '../redux/types'
import {IRectangle} from '../types'
import {getMouseCoordsOnCanvas, toCanvasCoords} from '../utils/common'
import {findNearestMultiple, rotatePointAroundAnother} from '../utils/math'
import {CanvasObject} from './CanvasObject'

interface ISelectedObjectsProps {
  groupRef: React.RefObject<Konva.Group>
  outerRef: React.RefObject<Konva.Layer>
}

interface IBoundBox extends IRectangle {
  rotation: number // degrees
}

export const getChildrenFromRootRef = (ref: React.RefObject<Konva.Layer>) => {
  const container = (ref.current?.children && ref.current.children[0]) || null
  return container?.hasChildren()
    ? ((container as Konva.Group).children as Konva.Group[])
    : null
}

const rotateAroundCenter = (boundBox: IBoundBox, angle: number) => {
  const topLeftCoords = {x: boundBox.x, y: boundBox.y}

  const centerCoords = rotatePointAroundAnother({
    point: {
      x: topLeftCoords.x + boundBox.width / 2,
      y: topLeftCoords.y + boundBox.height / 2
    },
    angle: boundBox.rotation || 0,
    center: topLeftCoords
  })

  const newCoords = rotatePointAroundAnother({
    point: topLeftCoords,
    angle,
    center: centerCoords
  })

  return {
    ...boundBox,
    ...newCoords,
    rotation: Math.round(boundBox.rotation + angle)
  }
}

// see: https://github.com/konvajs/konva/issues/648
const boundBoxFunc = (oldBoundBox: IBoundBox, newBoundBox: IBoundBox) => {
  const closestSnap = findNearestMultiple(
    newBoundBox.rotation,
    SHAPE_ROTATION_ROUND_FACTOR
  )

  const rotationDiff = closestSnap - oldBoundBox.rotation

  if (Math.abs(rotationDiff) > 0) {
    return rotateAroundCenter(oldBoundBox, rotationDiff)
  }

  return oldBoundBox
}

const SelectedObjectsFn: React.FC<ISelectedObjectsProps> = ({
  groupRef,
  outerRef
}: ISelectedObjectsProps) => {
  const {updateObjects, updateObjectsPosition} = useObjectsActions()
  const {showObjectPanel, showDefaultPanel} = useSidePanelActions()

  const transformerRef: React.RefObject<Konva.Transformer> = useRef(null)

  const {origin, scale} = useSelector(canvasSelector)
  const displayMode = useSelector(displayModeSelector)
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)
  const selectedObjects = useSelector(selectedObjectsSelector)
  const isResizeEnabled = useSelector(isResizeEnabledSelector)
  const isShiftPressed = useSelector(isShiftActiveSelector)

  useEffect(() => {
    if (!selectedObjects.length) return

    const transformer = transformerRef.current
    if (!transformer) return

    const layer = outerRef.current
    if (!layer || !layer.children) return

    if (selectedObjects.length === 1) {
      transformer.setNode(layer.children[0])
    } else {
      const group = groupRef.current
      if (!group) return

      transformer.setNode(group)
    }

    const stage = layer.getStage()
    if (!stage) return

    // Note - for more informations see: http://disq.us/p/22xlal3
    const rotaterAnchor = transformer.findOne('.rotater')
    rotaterAnchor.off('mouseenter')
    rotaterAnchor.on('mouseenter', () => {
      stage.content.style.cursor = cursorMap[EditorMode.TRANSFORM].rotation
    })
  }, [groupRef, selectedMode, outerRef, selectedObjects.length, transformerRef])

  useLayoutEffect(() => {
    if (selectedObjects.length) {
      showObjectPanel()
    } else {
      showDefaultPanel()
    }
  }, [selectedObjects.length, showDefaultPanel, showObjectPanel])

  const onGroupDragEnd = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const coords = getMouseCoordsOnCanvas(e)
      const group = groupRef.current
      if (coords && group) {
        updateObjectsPosition(
          selectedObjects.map((selectedObject) => selectedObject.config.id),
          group.getAttr('x'),
          group.getAttr('y')
        )
        group.setAttr('x', 0)
        group.setAttr('y', 0)
      }
    },
    [groupRef, selectedObjects, updateObjectsPosition]
  )

  const onSingleObjectDragEnd = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const node = e.target

      updateObjects([
        _.merge(_.cloneDeep(selectedObjects[0]), {
          config: {
            coords: {
              x: node.x() - node.width() / 2,
              y: node.y() - node.height() / 2
            }
          }
        })
      ])
    },
    [selectedObjects, updateObjects]
  )

  const onSingleObjectTransformEnd = useCallback(() => {
    const layer = outerRef.current
    if (!layer || !layer.children) return
    const node = layer.children[0]

    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    const width = node.width() * scaleX
    const height = node.height() * scaleY
    const rotation = _.round(((node.rotation() + 180) % 360) - 180, 1)

    // reset scale back
    node.scaleX(1)
    node.scaleY(1)

    updateObjects([
      _.merge(_.cloneDeep(selectedObjects[0]), {
        config: {
          coords: {
            x: node.x() - width / 2,
            y: node.y() - height / 2
          },
          dimensions: {
            width,
            height
          },
          rotation
        }
      })
    ])
  }, [outerRef, selectedObjects, updateObjects])

  const onGroupTransformEnd = useCallback(() => {
    const group = groupRef.current
    if (!group) return

    const groupRotation = group.rotation()
    if (groupRotation === 0 && group.x() === 0 && group.y() === 0) return
    const selectedNodes: Konva.Group[] | null = getChildrenFromRootRef(outerRef)
    if (!selectedNodes) return

    const selectedNodesMap = selectedNodes.reduce<{[x: string]: Konva.Group}>(
      (map, node) => ({...map, [node.id()]: node}),
      {}
    )

    updateObjects(
      _.cloneDeep(selectedObjects).map((selectedObject) => {
        const {id} = selectedObject.config

        if (!(id in selectedNodesMap)) {
          return selectedObject
        }

        const node = selectedNodesMap[id]

        const nodeCenter = toCanvasCoords({
          coords: node.getAbsolutePosition(),
          origin,
          scale
        })

        const nodeRotation = node.rotation()

        return _.merge(selectedObject, {
          config: {
            coords: {
              x: nodeCenter.x - node.offsetX(),
              y: nodeCenter.y - node.offsetY()
            },
            rotation: nodeRotation + groupRotation
          }
        })
      })
    )

    // reset group attributes
    group.rotation(0)
    group.x(0)
    group.y(0)
  }, [groupRef, origin, outerRef, scale, selectedObjects, updateObjects])

  const reservationMode = selectedMode === EditorMode.RESERVATION

  const showTransformer = useMemo(
    () => selectedMode === EditorMode.TRANSFORM && !!selectedObjects.length,
    [selectedMode, selectedObjects.length]
  )

  useEffect(() => {
    if (!showTransformer && selectedObjects.length > 1) {
      onGroupTransformEnd()
    }
  }, [onGroupTransformEnd, selectedObjects.length, showTransformer])

  const isDraggable = useMemo(
    () =>
      displayMode !== DisplayMode.PRICING &&
      displayMode !== DisplayMode.CASH &&
      !reservationMode &&
      selectedMode !== EditorMode.PAN,
    [displayMode, reservationMode, selectedMode]
  )

  const transformerConfig = useMemo(() => {
    if (!selectedObjects.length) return {}

    const resizeEnabled =
      isResizeEnabled && modeConfigs[EditorMode.TRANSFORM].resizing
    const rotateEnabled = modeConfigs[EditorMode.TRANSFORM].rotation

    if (selectedObjects.length > 1) {
      return {
        resizeEnabled,
        rotateEnabled,
        boundBoxFunc: isShiftPressed ? boundBoxFunc : undefined
      }
    }

    const objectType = selectedObjects[0].type

    const enabledAnchors =
      objectType === CanvasObjectType.Text
        ? ['top-left', 'top-right', 'bottom-left', 'bottom-right']
        : [
            'top-left',
            'top-center',
            'top-right',
            'middle-right',
            'middle-left',
            'bottom-left',
            'bottom-center',
            'bottom-right'
          ]

    return {
      enabledAnchors,
      resizeEnabled,
      rotateEnabled,
      boundBoxFunc: isShiftPressed ? boundBoxFunc : undefined
    }
  }, [isResizeEnabled, isShiftPressed, modeConfigs, selectedObjects])

  return (
    <Layer ref={outerRef}>
      {showTransformer && selectedObjects.length === 1 ? (
        <CanvasObject
          id={selectedObjects[0].config.id}
          onTransformEnd={onSingleObjectTransformEnd}
          onDragEnd={onSingleObjectDragEnd}
          draggable={isDraggable}
        />
      ) : (
        <Group
          ref={groupRef}
          onDragEnd={showTransformer ? undefined : onGroupDragEnd}
          draggable={isDraggable}
        >
          {selectedObjects.map((selectedObject) => (
            <CanvasObject
              key={selectedObject.config.id}
              id={selectedObject.config.id}
            />
          ))}
        </Group>
      )}
      {showTransformer && (
        <Transformer ref={transformerRef} {...transformerConfig} />
      )}
    </Layer>
  )
}

export const SelectedObjects = React.memo(SelectedObjectsFn, _.isEqual)
