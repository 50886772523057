import {useQuery} from '@apollo/react-hooks'
import {compact, has} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdvancedSettingsDivisionsQuery,
  AdvancedSettingsDivisionsQueryVariables,
  CheckTicketsEventFragment,
  CheckTourTimeSlotPropertiesFragment,
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery,
  ItemByPassCodeTicketItemFieldsFragment,
  ItemByPassCodeTourItemFieldsFragment,
  SaleByItemPassCodeProductItemFieldsFragment,
  SaleByItemPassCodeQuery,
  SaleByItemPassCodeTicketItemFieldsFragment,
  SaleByItemPassCodeTourItemFieldsFragment,
  TicketItemCheckStatus,
  TourItemCheckStatus,
  TourTimeSlot
} from '../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {useAdvancedSettings} from './AdvancedSettingsContext'
import {
  ADVANCED_SETTINGS_DIVISIONS,
  useGetCheckEventData,
  useGetCheckTourData,
  useGetCheckTourTimeSlotData,
  useGetCheckVenueData
} from './graphql'
import {CheckMode, CheckTicketsPageType} from './types'

export const isCheckTicketsPageType = (
  type: string | CheckTicketsPageType
): type is CheckTicketsPageType =>
  Object.values(CheckTicketsPageType).includes(type as CheckTicketsPageType)

export const isGetCheckEventQuery = (
  item:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
): item is GetCheckEventQuery => has(item, 'event')

export const isGetCheckTourTimeSlotQuery = (
  item:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
): item is GetCheckTourTimeSlotQuery => has(item, 'tourTimeSlot')

export const isGetCheckTourQuery = (
  item:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
): item is GetCheckTourQuery => has(item, 'tour')

export const isGetCheckVenueQuery = (
  item:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
): item is GetCheckVenueQuery => has(item, 'venue')

export const getCheckTicketsQueryBasedOnPageType = (
  type: CheckTicketsPageType
) => {
  switch (type) {
    case CheckTicketsPageType.TourTimeSlots:
      return useGetCheckTourTimeSlotData
    case CheckTicketsPageType.Tours:
      return useGetCheckTourData
    case CheckTicketsPageType.Venues:
      return useGetCheckVenueData
    case CheckTicketsPageType.Events:
    default:
      return useGetCheckEventData
  }
}

export const useEnabledDivisionsTabs = () => {
  const {
    settings: {enabledDivisions: enabledDivisionsIds = []}
  } = useAdvancedSettings()
  const areDivisionTabsShown = enabledDivisionsIds.length > 1
  const {
    data: divisionsData,
    loading: divisionsLoading,
    error: divisionsError
  } = useQuery<
    AdvancedSettingsDivisionsQuery,
    AdvancedSettingsDivisionsQueryVariables
  >(ADVANCED_SETTINGS_DIVISIONS, {
    fetchPolicy: 'network-only',
    skip: !areDivisionTabsShown
  })
  const enabledDivisions = compact(
    enabledDivisionsIds.map((ed) =>
      divisionsData?.divisions.find(({id}) => id === ed)
    )
  )
  const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(
    enabledDivisionsIds ? enabledDivisionsIds[0] : null
  )
  const handleTabChange = useCallback(
    (e: React.ChangeEvent<{}>, divisionId: number) => {
      setSelectedDivisionId(divisionId)
    },
    []
  )
  return {
    enabledDivisionsIds,
    enabledDivisions,
    areDivisionTabsShown,
    selectedDivisionId,
    handleTabChange,
    divisionsLoading,
    divisionsError
  }
}

export const isCheckTicketsEventFragment = (
  item: CheckTicketsEventFragment | CheckTourTimeSlotPropertiesFragment
): item is CheckTicketsEventFragment => item.__typename === 'Event'

export const useGetTourTimeSlotDescription = () => {
  const {formatTime, formatDateNumeric} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  return useCallback(
    (tourTimeSlot: Pick<TourTimeSlot, 'startsAt' | 'names'>): string =>
      [
        formatDateNumeric(new Date(tourTimeSlot.startsAt)),
        formatTime(new Date(tourTimeSlot.startsAt)),
        getUserLocaleTranslation(tourTimeSlot.names)
      ].join(' • '),
    [formatDateNumeric, formatTime, getUserLocaleTranslation]
  )
}

export const isItemByPassCodeTicketItemFieldsFragment = (
  item:
    | ItemByPassCodeTicketItemFieldsFragment
    | ItemByPassCodeTourItemFieldsFragment
): item is ItemByPassCodeTicketItemFieldsFragment =>
  item.__typename === 'TicketItem'

export const isItemByPassCodeTourItemFieldsFragment = (
  item:
    | ItemByPassCodeTicketItemFieldsFragment
    | ItemByPassCodeTourItemFieldsFragment
): item is ItemByPassCodeTourItemFieldsFragment =>
  item.__typename === 'TourItem'

export const useTranslateCheckMode = () => {
  const {t} = useTranslation()
  const translated: Record<CheckMode, string> = {
    [CheckMode.Bidirectional]: t<string>('Bidirectional'),
    [CheckMode.Verify]: t<string>('Verify'),
    [CheckMode.CheckOut]: t<string>('Check out'),
    [CheckMode.Info]: t<string>('Info mode'),
    [CheckMode.CheckIn]: t<string>('Check in')
  }
  return (mode: CheckMode) => translated[mode]
}

export const isSaleByItemPassCodeTicketItemFieldsFragment = (
  item:
    | SaleByItemPassCodeTicketItemFieldsFragment
    | SaleByItemPassCodeTourItemFieldsFragment
    | SaleByItemPassCodeProductItemFieldsFragment
): item is SaleByItemPassCodeTicketItemFieldsFragment =>
  item.__typename === 'TicketItem'

export const isSaleByItemPassCodeTourItemFieldsFragment = (
  item:
    | SaleByItemPassCodeTicketItemFieldsFragment
    | SaleByItemPassCodeTourItemFieldsFragment
    | SaleByItemPassCodeProductItemFieldsFragment
): item is SaleByItemPassCodeTourItemFieldsFragment =>
  item.__typename === 'TourItem'

export const isSaleByItemPassCodeTicketOrTourItemFieldsFragment = (
  item:
    | SaleByItemPassCodeTicketItemFieldsFragment
    | SaleByItemPassCodeTourItemFieldsFragment
    | SaleByItemPassCodeProductItemFieldsFragment
): item is
  | SaleByItemPassCodeTicketItemFieldsFragment
  | SaleByItemPassCodeTourItemFieldsFragment =>
  item.__typename === 'TicketItem' || item.__typename === 'TourItem'

export const uncheckedTicketsFilter = (
  item:
    | SaleByItemPassCodeTicketItemFieldsFragment
    | SaleByItemPassCodeTourItemFieldsFragment
) =>
  (isSaleByItemPassCodeTourItemFieldsFragment(item) &&
    [
      TourItemCheckStatus.Unchecked,
      TourItemCheckStatus.PartiallyChecked
    ].includes(item.tourItemCheckStatus)) ||
  (isSaleByItemPassCodeTicketItemFieldsFragment(item) &&
    item.ticketItemCheckStatus === TicketItemCheckStatus.Unchecked)

export const checkedTicketsFilter = (
  item:
    | SaleByItemPassCodeTicketItemFieldsFragment
    | SaleByItemPassCodeTourItemFieldsFragment
) =>
  (isSaleByItemPassCodeTourItemFieldsFragment(item) &&
    ![
      TourItemCheckStatus.Unchecked,
      TourItemCheckStatus.PartiallyChecked
    ].includes(item.tourItemCheckStatus)) ||
  (isSaleByItemPassCodeTicketItemFieldsFragment(item) &&
    item.ticketItemCheckStatus !== TicketItemCheckStatus.Unchecked)

export const validateEntity = ({
  data,
  checkType,
  entityId
}: {
  data?: SaleByItemPassCodeQuery['saleByItemPassCode']
  checkType: CheckTicketsPageType
  entityId: number
}) => {
  switch (checkType) {
    case CheckTicketsPageType.Events:
      return data?.event?.id === entityId
    case CheckTicketsPageType.TourTimeSlots:
      return data?.tourTimeSlot?.id === entityId
    case CheckTicketsPageType.Tours:
      return data?.tourTimeSlot?.tour.id === entityId
    case CheckTicketsPageType.Venues:
    default:
      return true
  }
}
