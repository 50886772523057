import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  useIsStringWithMaxLength,
  useIsValidURL
} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {FormAutocomplete} from '../../../../../form/FormAutocomplete'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {getFileNameFromUrl} from '../../../components/utils'
import {CardRowFormField, ICardRowForm} from '../editBlockDrawer/types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(1.5)
}))

interface ICardRowFormProps {
  formId: string
  onSubmit: (data: ICardRowForm) => void
  defaultValues?: Partial<ICardRowForm>
  images: {
    fileName: string
    url: string
  }[]
}

export const CardRowForm: React.FC<ICardRowFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  images
}: ICardRowFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    control,
    watch,
    register,
    triggerValidation,
    handleSubmit
  } = useForm<ICardRowForm>({defaultValues, mode: 'onChange'})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isValidURL = useIsValidURL(false, false)
  const selectedImage = watch(CardRowFormField.CoverImageUrl)
  const buttonLabel = watch(CardRowFormField.ButtonLabel)
  const imageOptions = images.map(({url, fileName}) => ({
    value: url,
    name: fileName
  }))
  const defaultValueImageInExistingImages = imageOptions.find(
    (image) => image.value === defaultValues?.coverImageUrl
  )
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <FormAutocomplete<ICardRowForm>
        noOptionsText=""
        fullWidth
        disableClearable
        errors={errors}
        label={t('Cover image')}
        name={CardRowFormField.CoverImageUrl}
        key={CardRowFormField.CoverImageUrl}
        register={register}
        setValue={setValue}
        watch={watch}
        autocompleteOptions={
          defaultValues && !defaultValueImageInExistingImages
            ? [
                {
                  value: defaultValues.coverImageUrl,
                  name: t("{{file}} doesn't exists in assets", {
                    file: getFileNameFromUrl(defaultValues.coverImageUrl || '')
                  })
                },
                ...imageOptions
              ].filter(Boolean)
            : imageOptions
        }
        validationOptions={{required: true}}
        required
      />
      {selectedImage && (
        <img
          src={selectedImage}
          style={{width: '100%', maxHeight: 200, objectFit: 'contain'}}
        />
      )}
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ICardRowForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={CardRowFormField.Title}
            key={CardRowFormField.Title}
            label={t('Title')}
            validationOptions={{
              validate: isStringWithMaxLength255
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ICardRowForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={CardRowFormField.Description}
            key={CardRowFormField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ICardRowForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={CardRowFormField.ButtonLabel}
            key={CardRowFormField.ButtonLabel}
            label={t('Button label')}
            validationOptions={{
              validate: isStringWithMaxLength255
            }}
            fullWidth
          />
        ]}
      />
      {buttonLabel && (
        <>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ICardRowForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CardRowFormField.ButtonUrl}
                key={CardRowFormField.ButtonUrl}
                label={t('Button URL')}
                validationOptions={{
                  validate: isValidURL
                }}
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <FormSwitchWithLabels<ICardRowForm>
                name={CardRowFormField.OpenInNewTab}
                key={CardRowFormField.OpenInNewTab}
                primaryLabel={t('Open in new tab?')}
                control={control}
                watch={watch}
                defaultValue={defaultValues?.openInNewTab || false}
                secondaryLabel=""
                enabledText=""
                disabledText=""
              />
            ]}
          />
        </>
      )}
    </StyledForm>
  )
}
