import {
  ShowAgeClassificationCode,
  ShowVersionCode
} from '../../../__generated__/schema'

export enum TourTimeSlotFormField {
  AdmissionRateId = 'admissionRateId',
  StartsAt = 'startsAt',
  VersionCode = 'versionCode',
  AgeClassificationCode = 'ageClassificationCode',
  GuideId = 'guideId',
  ShowOnWebsiteAndApi = 'showOnWebsiteAndApi',
  TicketNote = 'ticketNote',
  VenueId = 'venueId',
  RetailAttendeesLimit = 'retailAttendeesLimit',
  ECommerceAttendeesLimit = 'eCommerceAttendeesLimit',
  ECommerceOrderAttendeesLimit = 'eCommerceOrderAttendeesLimit',
  CostCenterId = 'costCenterId',
  MarketingLabelId = 'marketingLabelId',
  EventCategoryId = 'eventCategoryId',
  IsRetailSaleActive = 'isRetailSaleActive',
  IsRetailReservationActive = 'isRetailReservationActive',
  IsECommerceSaleActive = 'isECommerceSaleActive',
  IsECommerceReservationActive = 'isECommerceReservationActive'
}

export interface ITourTimeSlotForm {
  [TourTimeSlotFormField.AdmissionRateId]: string
  [TourTimeSlotFormField.StartsAt]: string
  [TourTimeSlotFormField.VersionCode]?: ShowVersionCode
  [TourTimeSlotFormField.AgeClassificationCode]?: ShowAgeClassificationCode
  [TourTimeSlotFormField.GuideId]?: string
  [TourTimeSlotFormField.ShowOnWebsiteAndApi]: boolean
  [TourTimeSlotFormField.TicketNote]?: string
  [TourTimeSlotFormField.VenueId]: string
  [TourTimeSlotFormField.RetailAttendeesLimit]?: string
  [TourTimeSlotFormField.ECommerceAttendeesLimit]?: string
  [TourTimeSlotFormField.ECommerceOrderAttendeesLimit]: string
  [TourTimeSlotFormField.CostCenterId]?: string
  [TourTimeSlotFormField.MarketingLabelId]?: string
  [TourTimeSlotFormField.EventCategoryId]?: string
  [TourTimeSlotFormField.IsRetailSaleActive]: boolean
  [TourTimeSlotFormField.IsRetailReservationActive]: boolean
  [TourTimeSlotFormField.IsECommerceSaleActive]: boolean
  [TourTimeSlotFormField.IsECommerceReservationActive]: boolean
}
