import {Dayjs} from 'dayjs'

export enum TabFuture {
  Tomorrow = 'tomorrow',
  Next7Days = 'next7Days',
  Next14Days = 'next14Days',
  Next28Days = 'next28Days',
  NextWeek = 'nextWeek',
  NextMonth = 'nextMonth',
  NextQuarter = 'nextQuarter',
  NextYear = 'nextYear'
}

export enum TabNow {
  FromNow = 'fromNow',
  FromToday = 'fromToday',
  Today = 'today',
  UntilToday = 'untilToday',
  ThisWeek = 'thisWeek',
  ThisMonth = 'thisMonth',
  ThisQuarter = 'thisQuarter',
  ThisYear = 'thisYear'
}

export enum TabPast {
  Yesterday = 'yesterday',
  Last7Days = 'last7Days',
  Last14Days = 'last14Days',
  Last28Days = 'last28Days',
  LastWeek = 'lastWeek',
  LastMonth = 'lastMonth',
  LastQuarter = 'lastQuarter',
  LastYear = 'lastYear'
}

export enum TabGroup {
  Future = 'future',
  Now = 'now',
  Past = 'past',
  Custom = 'custom'
}

export type AvailableDatePickerValues = TabFuture | TabNow | TabPast

export interface IDatePickerDateRange {
  dateFrom: Dayjs | null
  dateTo: Dayjs | null
}

export interface IDataPickerData {
  group?: TabGroup
  value?: AvailableDatePickerValues
  dateRange?: IDatePickerDateRange
}
