import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DivisionGateCloses} from '../../../../../__generated__/schema'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {DivisionFormControlLabel} from './DivisionFormControlLabel'
import {
  DivisionFormSelect,
  FormControlTextLabel,
  FormControlTimeLabel
} from './DivisionFormSelect'
import {
  DivisionFormField,
  IDivisionFormData,
  IDivisionSectionProps
} from './types'

export const GateCloses: React.FC<IDivisionSectionProps> = ({
  defaultValues
}: IDivisionSectionProps) => {
  const {t} = useTranslation()
  const gateClosesOptions = Object.values(DivisionGateCloses)
  const methods = useFormContext<IDivisionFormData>()
  const {
    watch,
    triggerValidation,
    errors,
    setValue,
    register,
    control,
    unregister,
    clearError,
    setError
  } = methods
  const gateCloses = watch(DivisionFormField.GATE_CLOSES_TYPE)
  useEffect(() => {
    if (gateCloses) {
      triggerValidation(
        Object.values(DivisionGateCloses)
          .filter((option) => option !== gateCloses)
          .map((option) => `${DivisionFormField.GATE_CLOSES_VALUE}.${option}`)
      )
    }
  }, [gateCloses, triggerValidation])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <DivisionFormSelect<IDivisionFormData>
      options={gateClosesOptions}
      name={DivisionFormField.GATE_CLOSES_TYPE}
      subfieldsName={DivisionFormField.GATE_CLOSES_VALUE}
      defaultValue={
        defaultValues[DivisionFormField.GATE_CLOSES_TYPE] ||
        DivisionGateCloses.BeforeStart
      }
      validationOptions={{
        required: true
      }}
      required
      label={t('Gate closes')}
      errors={errors}
      setValue={setValue}
      register={register}
    >
      <DivisionFormControlLabel
        value={DivisionGateCloses.BeforeStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.GATE_CLOSES_VALUE}.${DivisionGateCloses.BeforeStart}`}
            validationOptions={{
              required: gateCloses === DivisionGateCloses.BeforeStart,
              validate: isNonNegativeInteger
            }}
            required={gateCloses === DivisionGateCloses.BeforeStart}
            inputMode="numeric"
            note={t('minutes before event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionGateCloses.AfterStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.GATE_CLOSES_VALUE}.${DivisionGateCloses.AfterStart}`}
            validationOptions={{
              required: gateCloses === DivisionGateCloses.AfterStart,
              validate: isNonNegativeInteger
            }}
            required={gateCloses === DivisionGateCloses.AfterStart}
            inputMode="numeric"
            note={t('minutes after event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionGateCloses.DayOfEvent}
        label={
          <FormControlTimeLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.GATE_CLOSES_VALUE}.${DivisionGateCloses.DayOfEvent}`}
            validationOptions={{
              required: gateCloses === DivisionGateCloses.DayOfEvent
            }}
            note={t('the day of event')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            unregister={unregister}
            clearError={clearError}
            setError={setError}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionGateCloses.BeforeEnd}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.GATE_CLOSES_VALUE}.${DivisionGateCloses.BeforeEnd}`}
            validationOptions={{
              required: gateCloses === DivisionGateCloses.BeforeEnd,
              validate: isNonNegativeInteger
            }}
            required={gateCloses === DivisionGateCloses.BeforeEnd}
            inputMode="numeric"
            note={t('minutes before event ends')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionGateCloses.AfterEnd}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.GATE_CLOSES_VALUE}.${DivisionGateCloses.AfterEnd}`}
            validationOptions={{
              required: gateCloses === DivisionGateCloses.AfterEnd,
              validate: isNonNegativeInteger
            }}
            required={gateCloses === DivisionGateCloses.AfterEnd}
            inputMode="numeric"
            note={t('minutes after event ends')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
    </DivisionFormSelect>
  )
}
