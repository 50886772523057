import {useCallback, useMemo} from 'react'
import {useDispatch, useStore} from 'react-redux'
import {getCompressedId} from '../../utils/compressedIdGenerator'
import {useSelectionActions} from '../selection/actions'
import {selectedObjectsIdsSelector} from '../selection/selectors'
import {Align, Distribute, Flip} from '../types'
import {ObjectsActionType} from './reducer'
import {IObjectsState, ObjectsStateValue} from './types'

export const useObjectsActions = () => {
  const dispatch = useDispatch()
  const {selectMultiple, unselectAll} = useSelectionActions()
  const store = useStore()

  const alignLeft = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.ALIGN_OBJECTS,
      payload: {ids: selectedObjectsIds, align: Align.ALIGN_LEFT}
    })
  }, [dispatch, store])

  const alignCenter = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.ALIGN_OBJECTS,
      payload: {ids: selectedObjectsIds, align: Align.ALIGN_CENTER}
    })
  }, [dispatch, store])

  const alignRight = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.ALIGN_OBJECTS,
      payload: {ids: selectedObjectsIds, align: Align.ALIGN_RIGHT}
    })
  }, [dispatch, store])

  const alignTop = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.ALIGN_OBJECTS,
      payload: {ids: selectedObjectsIds, align: Align.ALIGN_TOP}
    })
  }, [dispatch, store])

  const alignMiddle = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.ALIGN_OBJECTS,
      payload: {ids: selectedObjectsIds, align: Align.ALIGN_MIDDLE}
    })
  }, [dispatch, store])

  const alignBottom = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.ALIGN_OBJECTS,
      payload: {ids: selectedObjectsIds, align: Align.ALIGN_BOTTOM}
    })
  }, [dispatch, store])

  const distributeHorizontal = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.DISTRIBUTE_OBJECTS,
      payload: {ids: selectedObjectsIds, distribute: Distribute.HORIZONTAL}
    })
  }, [dispatch, store])

  const distributeVertical = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.DISTRIBUTE_OBJECTS,
      payload: {ids: selectedObjectsIds, distribute: Distribute.VERTICAL}
    })
  }, [dispatch, store])

  const duplicateSelectedObjects = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)
    const newIds = selectedObjectsIds.map(() => getCompressedId())
    unselectAll()

    dispatch({
      type: ObjectsActionType.DUPLICATE_OBJECTS,
      payload: {
        newIds,
        selectedIds: selectedObjectsIds
      }
    })

    selectMultiple(newIds)
  }, [dispatch, selectMultiple, store, unselectAll])

  const flipHorizontal = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.FLIP_OBJECTS,
      payload: {ids: selectedObjectsIds, flip: Flip.HORIZONTAL}
    })
  }, [dispatch, store])

  const flipVertical = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)

    dispatch({
      type: ObjectsActionType.FLIP_OBJECTS,
      payload: {ids: selectedObjectsIds, flip: Flip.VERTICAL}
    })
  }, [dispatch, store])

  const moveSelectedObjects = useCallback(
    (offsetX: number, offsetY: number) => {
      const state = store.getState()
      const selectedObjectsIds = selectedObjectsIdsSelector(state)

      dispatch({
        type: ObjectsActionType.UPDATE_OBJECTS_POSITION,
        payload: {ids: selectedObjectsIds, offsetX, offsetY}
      })
    },
    [dispatch, store]
  )

  const removeSelectedObjects = useCallback(() => {
    const state = store.getState()
    const selectedObjectsIds = selectedObjectsIdsSelector(state)
    unselectAll()

    dispatch({
      type: ObjectsActionType.REMOVE_OBJECTS,
      payload: selectedObjectsIds
    })
  }, [dispatch, store, unselectAll])

  const setObjectsState = useCallback(
    (state: IObjectsState) => {
      dispatch({
        type: ObjectsActionType.SET_OBJECTS_STATE,
        payload: state
      })
    },
    [dispatch]
  )

  const updateObjects = useCallback(
    (objects: Array<ObjectsStateValue>) => {
      dispatch({
        type: ObjectsActionType.UPDATE_OBJECTS,
        payload: objects.reduce(
          (objectsMap, object) => ({...objectsMap, [object.config.id]: object}),
          {}
        )
      })
    },
    [dispatch]
  )

  const updateObjectsPosition = useCallback(
    (ids: Array<string>, offsetX: number, offsetY: number) => {
      dispatch({
        type: ObjectsActionType.UPDATE_OBJECTS_POSITION,
        payload: {ids, offsetX, offsetY}
      })
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      alignLeft,
      alignCenter,
      alignRight,
      alignTop,
      alignMiddle,
      alignBottom,
      distributeHorizontal,
      distributeVertical,
      flipHorizontal,
      flipVertical,
      duplicateSelectedObjects,
      moveSelectedObjects,
      removeSelectedObjects,
      setObjectsState,
      updateObjects,
      updateObjectsPosition
    }),
    [
      alignBottom,
      alignCenter,
      alignLeft,
      alignMiddle,
      alignRight,
      alignTop,
      distributeHorizontal,
      distributeVertical,
      duplicateSelectedObjects,
      flipHorizontal,
      flipVertical,
      moveSelectedObjects,
      removeSelectedObjects,
      setObjectsState,
      updateObjects,
      updateObjectsPosition
    ]
  )

  return actions
}
