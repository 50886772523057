import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SettingsIcon from '@mui/icons-material/Settings'
import {useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  CheckTicketsPaginatedEventsQuery,
  EventState
} from '../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../theme'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {HeaderActionType} from '../../../common/header/types'
import {LoadingMoreProgress} from '../../../common/LoadingMoreProgress'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {ListItemWrapper} from '../cashDesk/ListItemWrapper'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {EnabledDivisionsTabs} from '../components/EnabledDivisionsTabs'
import {PrimaryHeader} from '../Header'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../Layout'
import {groupEntitiesByStartsAtDate} from '../utils'
import {EventDetailDrawer} from './EventDetailDrawer'
import {useGetCheckTicketsPaginatedEvents} from './graphql'
import {ResponsiveGroupedEntitiesItem} from './ResponsiveGroupedEntitiesItem'
import {useEnabledDivisionsTabs} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  loadingMoreProgress: {
    paddingTop: theme.spacing(2)
  }
}))

export const Events: React.FC = () => {
  const {t} = useTranslation()
  const {
    areDivisionTabsShown,
    enabledDivisions,
    selectedDivisionId,
    handleTabChange,
    divisionsLoading,
    divisionsError
  } = useEnabledDivisionsTabs()
  const {data, error, loading, fetchMore, isLoadingMore} =
    useGetCheckTicketsPaginatedEvents({
      filter: {
        state: EventState.Published,
        divisionId: selectedDivisionId
      },
      paginationInput: {limit: 50, offset: 0}
    })
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const classes = useStyles()
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.paginatedEvents.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.paginatedEvents.pagination.hasMore, fetchMore, isLoadingMore])
  const history = useHistory()
  const handleOnSettingsIconClick = useCallback(() => {
    history.push(routeTo.admin.checkTickets.advancedSettings())
  }, [history])
  const handleExited = useCallback(
    () => history.replace(routeTo.admin.checkTickets.events()),
    [history]
  )
  return (
    <>
      <PageWithHeaderTemplate
        header={
          <PrimaryHeader
            title={t('Check tickets')}
            rightActions={[
              {
                Icon: SettingsIcon,
                onClick: handleOnSettingsIconClick,
                label: t('Settings'),
                type: HeaderActionType.IconButton,
                cypressId: 'check-tickets-settings-icon'
              }
            ]}
            isDatetimeEnabled
          />
        }
      >
        <FullScreenCenteredLayout
          onScrolledNearTheEndOfTheLayout={
            data?.paginatedEvents.pagination.hasMore
              ? handleScrolledNearTheEndOfTheLayout
              : undefined
          }
          subTopbar={
            areDivisionTabsShown && (
              <EnabledDivisionsTabs
                enabledDivisions={enabledDivisions}
                selectedDivisionId={selectedDivisionId}
                onTabChange={handleTabChange}
                loading={divisionsLoading}
                error={divisionsError}
              />
            )
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <RenderOnData
              errorMessage={t<string>('Unable to load events')}
              error={error}
              loading={loading}
              data={data}
            >
              {({paginatedEvents}: CheckTicketsPaginatedEventsQuery) =>
                paginatedEvents.items.length > 0 ? (
                  <CenteredLayoutListWrapper>
                    {groupEntitiesByStartsAtDate(
                      paginatedEvents.items,
                      getUserLocaleTranslation
                    ).map((groupedEvents) => (
                      <ListItemWrapper key={groupedEvents.date}>
                        <ResponsiveGroupedEntitiesItem
                          item={groupedEvents}
                          isPhablet={isPhablet}
                        />
                      </ListItemWrapper>
                    ))}
                    {isLoadingMore && (
                      <LoadingMoreProgress
                        className={classes.loadingMoreProgress}
                      />
                    )}
                  </CenteredLayoutListWrapper>
                ) : (
                  <Blank
                    title={t('No events found')}
                    IconComp={CalendarTodayIcon}
                  />
                )
              }
            </RenderOnData>
          </ChildrenOnEffectiveClientSelected>
        </FullScreenCenteredLayout>
      </PageWithHeaderTemplate>
      <Route
        path={routeTo.admin.checkTickets.eventDetailFromList(
          ':checkType',
          ':eventId'
        )}
        exact
      >
        <EventDetailDrawer onExited={handleExited} />
      </Route>
    </>
  )
}
