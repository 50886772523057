import {useApolloClient} from '@apollo/react-hooks'
import {ApolloClient} from 'apollo-client'
import {ApolloQueryResult, OperationVariables} from 'apollo-client/core/types'
import {QueryOptions} from 'apollo-client/core/watchQueryOptions'
import Decimal from 'decimal.js'
import {chain, difference, keyBy} from 'lodash'
import {useCallback, useRef, useState} from 'react'
import {
  PaginationInput,
  PaginationPropertiesFragment
} from '../../../../../../__generated__/schema'
import {extractPaginationInput} from '../../../../../../utils/pagination'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'

const defaultPaginationInput = {
  limit: 20,
  offset: 0
}

export const isNotNull = <T extends object>(value: T | null): value is T => {
  return Boolean(value)
}

const getPaginationInputs = (
  idsLength: number,
  paginationInputLimit: number
): PaginationInput[] => {
  const requestsCount = new Decimal(idsLength)
    .div(paginationInputLimit)
    .ceil()
    .toNumber()
  return new Array(requestsCount).fill(0).map((item, index) => ({
    limit: paginationInputLimit,
    offset: index * paginationInputLimit
  }))
}

export const useIdListItems = <T = any, TVariables = OperationVariables>({
  getDataFromDb,
  getPagination,
  getIdListItemsFromCache,
  mapIdListItemsFromData,
  mapVariables
}: {
  getDataFromDb: (vars: {
    options: Omit<QueryOptions<TVariables>, 'query'>
    client: ApolloClient<object>
  }) => Promise<ApolloQueryResult<T>>
  getPagination: (data: T) => PaginationPropertiesFragment
  getIdListItemsFromCache: (
    client: ApolloClient<object>,
    ids: number[]
  ) => IIdListItem[]
  mapIdListItemsFromData: (data: T) => IIdListItem[]
  mapVariables: (args: {
    paginationInput: PaginationInput
    hasText?: string
    ids?: number[]
  }) => TVariables
}): {
  getIdListItems: (hasText?: string) => Promise<IIdListItem[]>
  getMoreIdListItems?: (hasText?: string) => Promise<IIdListItem[]>
  getIdListItemsByIds: (ids: number[]) => Promise<IIdListItem[]>
} => {
  const client = useApolloClient()

  const [hasMore, setHasMore] = useState<boolean>(false)

  const infoRef =
    useRef<
      | null
      | {
          hasText?: string
          paginationResult: PaginationPropertiesFragment
          paginationInput: PaginationInput
        }[]
    >(null)

  const getIdListItems = useCallback(
    async (hasText?: string) => {
      const result = await getDataFromDb({
        client,
        options: {
          variables: mapVariables({
            paginationInput: defaultPaginationInput,
            hasText
          })
        }
      })
      if (result.data) {
        const options: IIdListItem[] = mapIdListItemsFromData(result.data)
        const paginationResult = getPagination(result.data)
        infoRef.current = [
          {
            hasText,
            paginationResult,
            paginationInput: defaultPaginationInput
          }
        ]
        setHasMore(paginationResult.hasMore)
        return options
      }
      return []
    },
    [client, getDataFromDb, getPagination, mapIdListItemsFromData, mapVariables]
  )

  const getMoreIdListItems = useCallback(
    async (hasText?: string) => {
      const paginationInput = infoRef.current
        ? extractPaginationInput(
            infoRef.current[infoRef.current.length - 1].paginationResult
          )
        : defaultPaginationInput
      const manyResults = await Promise.all(
        (infoRef.current ?? []).map(async (ri) => {
          const result = await getDataFromDb({
            client,
            options: {
              fetchPolicy: 'cache-only',
              variables: mapVariables({
                paginationInput: ri.paginationInput,
                hasText: ri.hasText
              })
            }
          })
          if (result.data) {
            return mapIdListItemsFromData(result.data)
          }
          return [] as IIdListItem[]
        })
      )
      const result = await getDataFromDb({
        client,
        options: {
          variables: mapVariables({
            paginationInput,
            hasText
          })
        }
      })
      if (result.data) {
        const options: IIdListItem[] = mapIdListItemsFromData(result.data)
        infoRef.current = [
          ...(infoRef.current ?? []),
          {
            hasText,
            paginationResult: getPagination(result.data),
            paginationInput
          }
        ]
        setHasMore(getPagination(result.data).hasMore)
        return manyResults.flat().concat(options)
      }
      return []
    },
    [client, getDataFromDb, getPagination, mapIdListItemsFromData, mapVariables]
  )

  const getIdListItemsByIds = useCallback(
    async (ids: number[]) => {
      const idListItemsFromCache = getIdListItemsFromCache(client, ids)
      const foundIds = idListItemsFromCache.map((item) => item.id)
      const missingIds = difference(ids, foundIds)
      if (missingIds.length === 0) {
        return idListItemsFromCache
      }

      const paginationInputs: PaginationInput[] = getPaginationInputs(
        missingIds.length,
        100
      )

      const allIdListItems = await Promise.all(
        paginationInputs.map(async (paginationInput) => {
          const {data} = await getDataFromDb({
            client,
            options: {
              variables: mapVariables({
                paginationInput,
                ids: missingIds
              })
            }
          })
          if (data) {
            return mapIdListItemsFromData(data)
          }
          return []
        })
      )

      const idListItemsByIds = keyBy(
        allIdListItems.flat().concat(idListItemsFromCache),
        'id'
      )
      return chain(ids)
        .map((id) => idListItemsByIds[id])
        .compact()
        .value()
    },
    [
      client,
      getDataFromDb,
      getIdListItemsFromCache,
      mapIdListItemsFromData,
      mapVariables
    ]
  )
  return {
    getIdListItems,
    getMoreIdListItems: hasMore ? getMoreIdListItems : undefined,
    getIdListItemsByIds
  }
}
