import {ApiGrantState, ApiResource} from '../../../../__generated__/schema'

export enum ApiGrantField {
  Name = 'name',
  Description = 'description',
  GrantedResources = 'grantedResources',
  ClientIds = 'clientIds',
  MaxRequestsPerMinute = 'maxRequestsPerMinute',
  MaxRequestsPerHour = 'maxRequestsPerHour',
  State = 'state',
  TechnicalContactName = 'technicalContactName',
  TechnicalContactPhone = 'technicalContactPhone',
  TechnicalContactEmail = 'technicalContactEmail',
  TechnicalContactRole = 'technicalContactRole',
  BusinessContactName = 'businessContactName',
  BusinessContactPhone = 'businessContactPhone',
  BusinessContactEmail = 'businessContactEmail',
  BusinessContactRole = 'businessContactRole',
  ExpiresAt = 'expiresAt'
}

export interface IApiGrantForm {
  [ApiGrantField.Name]: string
  [ApiGrantField.Description]: string
  [ApiGrantField.GrantedResources]: Record<ApiResource, boolean>
  [ApiGrantField.ClientIds]: string[]
  [ApiGrantField.MaxRequestsPerMinute]: string
  [ApiGrantField.MaxRequestsPerHour]: string
  [ApiGrantField.State]: ApiGrantState
  [ApiGrantField.TechnicalContactName]: string
  [ApiGrantField.TechnicalContactPhone]?: string
  [ApiGrantField.TechnicalContactEmail]: string
  [ApiGrantField.TechnicalContactRole]?: string
  [ApiGrantField.BusinessContactName]: string
  [ApiGrantField.BusinessContactPhone]?: string
  [ApiGrantField.BusinessContactEmail]: string
  [ApiGrantField.BusinessContactRole]?: string
  [ApiGrantField.ExpiresAt]: string
}
