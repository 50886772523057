import {useTranslation} from 'react-i18next'

import {AuditoriumState} from '../__generated__/schema'

export const useTranslateAuditoriumState = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<AuditoriumState, string> = {
    [AuditoriumState.Archived]: t<string>('AuditoriumState->Archived'),
    [AuditoriumState.Active]: t<string>('AuditoriumState->Active')
  }
  return (code: AuditoriumState) => translatedCodes[code]
}
