import gql from 'graphql-tag'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {TEMPLATE_ASSIGNMENT_PROPERTIES_FRAGMENT} from '../clients/graphql'
import {NARROW_DIVISION_PROPERTIES_FRAGMENT} from '../graphql'

export const GET_DEVICE_SETTINGS_PAGE_QUERY = gql`
  ${TEMPLATE_ASSIGNMENT_PROPERTIES_FRAGMENT}
  ${NARROW_DIVISION_PROPERTIES_FRAGMENT}
  query GetDeviceSettingsPageData($clientId: Int!) {
    divisions {
      ...NarrowDivisionProperties
    }
    client(id: $clientId) {
      id
      templateAssignments {
        ...TemplateAssignmentProperties
      }
    }
  }
`

const DEVICE_SETTINGS_WAREHOUSE_FIELDS = gql`
  fragment DeviceSettingsWarehouseFields on Warehouse {
    id
    name
  }
`

export const GET_WAREHOUSES_FOR_DEVICE_SETTINGS = gql`
  ${DEVICE_SETTINGS_WAREHOUSE_FIELDS}
  ${PAGINATION_FRAGMENT}
  query GetWarehousesForDeviceSettings(
    $filter: WarehousesFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouses(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...DeviceSettingsWarehouseFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`
