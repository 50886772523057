import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {DetailClientShowPropertiesFragment} from '../../../../../__generated__/schema'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {useGetShowTitle} from '../../../../../utils/translations'
import {ShowSection} from '../../../../constants'
import {useBackdropState} from '../../../../context/backdrop'
import {useNotifications} from '../../../../context/notifications'
import {useUpsertClientShowTranslations} from '../graphql'
import {ShowCenteredLayoutWithSingleNavigation} from '../ShowCenteredLayoutWithNavigation'
import {ShowTranslationsForm, useShowFormStyles} from '../ShowTranslationsForm'
import {ITranslationsForm, TranslationFormField} from '../types'
import {useShowTranslationsFormAnchors} from '../useShowTranslationssFormAnchors'
import {useShowTranslationsFormDefaultValues} from '../utils'
import {EditClientShowPageDetailTemplate} from './EditClientShowPageDetailTemplate'

interface IEditClientShowTranslationsProps {
  clientShow: DetailClientShowPropertiesFragment
}

const EDIT_CLIENT_SHOW_TRANSLATIONS_FORM_ID = 'editClientShowTranslationsFormId'

export const EditClientShowTranslations: React.FC<IEditClientShowTranslationsProps> =
  ({clientShow}: IEditClientShowTranslationsProps) => {
    const showFormClasses = useShowFormStyles()
    const history = useHistory()
    const {t} = useTranslation()
    const navigationItems = useShowTranslationsFormAnchors()
    const handleClose = useCallback(
      () => history.replace(routeTo.admin.shows.home()),
      [history]
    )

    const upsertClientShowTranslations = useUpsertClientShowTranslations(
      clientShow.id
    )
    const defaultErrorHandler = useDefaultErrorHandler()
    const {setShowBackdrop} = useBackdropState()
    const {addInfoNotification} = useNotifications()

    const handleSubmit = useCallback(
      async (formData: ITranslationsForm) => {
        setShowBackdrop(true)
        try {
          await upsertClientShowTranslations({
            id: clientShow.id,
            data: formData[TranslationFormField.TRANSLATIONS].filter(
              (translation) =>
                translation.title ||
                translation.tagline ||
                translation.description
            )
          })
          addInfoNotification(t('Client show updated'))
        } catch (e) {
          defaultErrorHandler(
            e,
            t('Error while updating client show translations')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        defaultErrorHandler,
        clientShow.id,
        setShowBackdrop,
        t,
        upsertClientShowTranslations
      ]
    )
    const defaultValues = useShowTranslationsFormDefaultValues({
      translations: clientShow.translations
    })

    const getShowTitle = useGetShowTitle()
    const showTitle = getShowTitle({
      translations: clientShow.translations,
      originalTitle: clientShow.originalTitle,
      fallbackTitle: t('Show')
    })

    return (
      <EditClientShowPageDetailTemplate
        showName={showTitle!}
        formId={EDIT_CLIENT_SHOW_TRANSLATIONS_FORM_ID}
        onClose={handleClose}
        currentTab={ShowSection.Translations}
      >
        <ShowCenteredLayoutWithSingleNavigation items={navigationItems}>
          <ShowTranslationsForm
            formId={EDIT_CLIENT_SHOW_TRANSLATIONS_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [TranslationFormField.TRANSLATIONS]: defaultValues
            }}
            className={showFormClasses.form}
          />
        </ShowCenteredLayoutWithSingleNavigation>
      </EditClientShowPageDetailTemplate>
    )
  }
