import {
  CanvasObjectType,
  IIcon,
  ISeat,
  IZone
} from '@attendio/shared-components'
import _ from 'lodash'
import {IRectangle, IShape, IText} from '../../types'
import {
  canvasObjectBoundingRect,
  mergeBoundingRects
} from '../../utils/boundingBox'
import {ObjectId} from '../selection/reducer'
import {Align, Distribute, Flip} from '../types'
import {IconAction, IconActionType} from './icons/types'
import {SeatActionType, SeatsAction} from './seats/types'
import {ShapeAction, ShapeActionType} from './shapes/types'
import {TextAction, TextActionType} from './text/types'
import {IObjectsState, ObjectsStateValue} from './types'
import {ZoneAction, ZoneActionType} from './zones/types'

export enum ObjectsActionType {
  ALIGN_OBJECTS = 'align objects',
  DISTRIBUTE_OBJECTS = 'distribute objects',
  DUPLICATE_OBJECTS = 'duplicate objects',
  FLIP_OBJECTS = 'flip objects',
  REMOVE_OBJECTS = 'remove objects',
  SET_OBJECTS_STATE = 'set objects state',
  UPDATE_OBJECTS = 'update objects',
  UPDATE_OBJECTS_POSITION = 'update objects position'
}

interface IAlignObjects {
  type: typeof ObjectsActionType.ALIGN_OBJECTS
  payload: {
    align: Align
    ids: Array<ObjectId>
  }
}

interface IDistributeObjects {
  type: typeof ObjectsActionType.DISTRIBUTE_OBJECTS
  payload: {
    distribute: Distribute
    ids: Array<ObjectId>
  }
}

interface IDuplicateObjects {
  type: typeof ObjectsActionType.DUPLICATE_OBJECTS
  payload: {
    newIds: Array<ObjectId>
    selectedIds: Array<ObjectId>
  }
}

interface IFlipObjects {
  type: typeof ObjectsActionType.FLIP_OBJECTS
  payload: {
    flip: Flip
    ids: Array<ObjectId>
  }
}

interface IRemoveObjects {
  type: typeof ObjectsActionType.REMOVE_OBJECTS
  payload: Array<ObjectId>
}

interface ISetObjectsStateAction {
  type: typeof ObjectsActionType.SET_OBJECTS_STATE
  payload: IObjectsState
}

interface IUpdateAction {
  type: typeof ObjectsActionType.UPDATE_OBJECTS
  payload: {
    [id: string]: ObjectsStateValue
  }
}

interface IUpdatePositionAction {
  type: typeof ObjectsActionType.UPDATE_OBJECTS_POSITION
  payload: {
    ids: Array<string>
    offsetX: number
    offsetY: number
  }
}

type ObjectAction =
  | IAlignObjects
  | IconAction
  | IDistributeObjects
  | IDuplicateObjects
  | IFlipObjects
  | IRemoveObjects
  | ShapeAction
  | SeatsAction
  | TextAction
  | ISetObjectsStateAction
  | IUpdateAction
  | IUpdatePositionAction
  | ZoneAction

const initialState: IObjectsState = {}

interface IBoundingRectsMap {
  [id: string]: IRectangle
}

const verticalAligns = [Align.ALIGN_LEFT, Align.ALIGN_CENTER, Align.ALIGN_RIGHT]
const horizontalAligns = [
  Align.ALIGN_TOP,
  Align.ALIGN_MIDDLE,
  Align.ALIGN_BOTTOM
]

const computeDiff = (
  align: Align,
  commonStart: number,
  commonSize: number,
  itemStart: number,
  itemSize: number
) => {
  let diff = 0

  if (align === Align.ALIGN_LEFT || align === Align.ALIGN_TOP) {
    diff = commonStart - itemStart
  } else if (align === Align.ALIGN_CENTER || align === Align.ALIGN_MIDDLE) {
    const commonCenter = commonStart + commonSize / 2
    const itemCenter = itemStart + itemSize / 2

    diff = commonCenter - itemCenter
  } else if (align === Align.ALIGN_RIGHT || align === Align.ALIGN_BOTTOM) {
    const commonEnd = commonStart + commonSize
    const itemEnd = itemStart + itemSize

    diff = commonEnd - itemEnd
  }

  return diff
}

const distributeObjects = (
  distribute: Distribute,
  boundingRectanglesMap: IBoundingRectsMap,
  commonBoundingRectangle: IRectangle,
  ids: Array<string>,
  objectsState: IObjectsState
) => {
  const coordName = distribute === Distribute.HORIZONTAL ? 'x' : 'y'
  const dimensionName =
    distribute === Distribute.HORIZONTAL ? 'width' : 'height'

  const sizesSum = ids.reduce(
    (sum, id) => sum + boundingRectanglesMap[id][dimensionName],
    0
  )

  const gapSize =
    (commonBoundingRectangle[dimensionName] - sizesSum) / (ids.length - 1)

  const idsFromStartToEnd = ids.sort(
    (id1, id2) =>
      boundingRectanglesMap[id1][coordName] -
      boundingRectanglesMap[id2][coordName]
  )

  return idsFromStartToEnd.reduce(
    (result: {[id: string]: ObjectsStateValue}, id, index) => {
      if (index === 0 || index === idsFromStartToEnd.length) {
        return {...result, [id]: objectsState[id]}
      }

      const previousId = idsFromStartToEnd[index - 1]
      const previousStart = result[previousId].config.coords[coordName]
      const previousSize = boundingRectanglesMap[previousId][dimensionName]
      const previousEnd = previousStart + previousSize
      const newStart = previousEnd + gapSize

      const newValue = _.cloneDeep(objectsState[id])
      newValue.config.coords[coordName] = newStart

      return {
        ...result,
        [id]: newValue
      }
    },
    {}
  )
}

const flipObjects = (
  flip: Flip,
  commonBoundingRectangle: IRectangle,
  ids: Array<string>,
  objectsState: IObjectsState
) => {
  const coordName = flip === Flip.HORIZONTAL ? 'x' : 'y'
  const dimensionName = flip === Flip.HORIZONTAL ? 'width' : 'height'

  const commonCenter =
    commonBoundingRectangle[coordName] +
    commonBoundingRectangle[dimensionName] / 2

  return ids.reduce((result: {[id: string]: ObjectsStateValue}, id) => {
    const object = objectsState[id]

    const boundingRect = canvasObjectBoundingRect(object)

    // Note: There is no bounding rectangle when we do not have an implementation
    // of its computation for the given type of canvas object.
    if (!boundingRect) {
      return {...result, [id]: object}
    }

    const distanceFromCenter = commonCenter - boundingRect[coordName]

    const flippedObject = _.cloneDeep(object)
    flippedObject.config.coords[coordName] +=
      2 * distanceFromCenter - boundingRect[dimensionName]
    flippedObject.config.rotation *= -1

    return {
      ...result,
      [id]: flippedObject
    }
  }, {})
}

const computeBoundingRects = (
  ids: Array<string>,
  objectsState: IObjectsState
): IBoundingRectsMap => {
  return ids.reduce((map, id) => {
    const boundingRect = canvasObjectBoundingRect(objectsState[id])
    return boundingRect ? {...map, [id]: boundingRect} : map
  }, {})
}

export const objectsReducer = (
  state = initialState,
  action: ObjectAction
): IObjectsState => {
  switch (action.type) {
    case IconActionType.ADD_ICON:
    case ShapeActionType.ADD_SHAPE:
    case SeatActionType.ADD_SEAT:
    case TextActionType.ADD_TEXT:
    case ZoneActionType.ADD_ZONE:
      return {
        ...state,
        [action.payload.config.id]: action.payload
      }
    case SeatActionType.ADD_SEATS:
      return {
        ...state,
        ...action.payload.configs.reduce(
          (seatsMap: IObjectsState, seat: ISeat) => {
            return {
              ...seatsMap,
              [seat.id]: {type: action.payload.type, config: seat}
            }
          },
          {}
        )
      }
    case ObjectsActionType.ALIGN_OBJECTS: {
      const {align, ids} = action.payload

      const boundingRectanglesMap = computeBoundingRects(ids, state)
      const commonBoundingRectangle = mergeBoundingRects(
        Object.values(boundingRectanglesMap)
      )

      return _.mapValues<IObjectsState, ObjectsStateValue>(state, (value) => {
        const id = value.config.id

        if (!boundingRectanglesMap[id]) {
          return value
        }

        const boundingRectangle = boundingRectanglesMap[id]

        const xDiff = verticalAligns.includes(align)
          ? computeDiff(
              align,
              commonBoundingRectangle.x,
              commonBoundingRectangle.width,
              boundingRectangle.x,
              boundingRectangle.width
            )
          : 0

        const yDiff = horizontalAligns.includes(align)
          ? computeDiff(
              align,
              commonBoundingRectangle.y,
              commonBoundingRectangle.height,
              boundingRectangle.y,
              boundingRectangle.height
            )
          : 0

        if (xDiff || yDiff) {
          const newValue = _.cloneDeep(value)
          newValue.config.coords.x = newValue.config.coords.x + xDiff
          newValue.config.coords.y = newValue.config.coords.y + yDiff
          return newValue
        }

        return value
      })
    }
    case ObjectsActionType.DISTRIBUTE_OBJECTS: {
      const {distribute, ids} = action.payload

      if (ids.length < 3) {
        return state
      }

      const boundingRectanglesMap = computeBoundingRects(ids, state)
      const commonBoundingRectangle = mergeBoundingRects(
        Object.values(boundingRectanglesMap)
      )

      const updatedValues = distributeObjects(
        distribute,
        boundingRectanglesMap,
        commonBoundingRectangle,
        ids,
        state
      )

      return {...state, ...updatedValues}
    }
    case ObjectsActionType.DUPLICATE_OBJECTS: {
      const duplicatedObjects: {
        [key: string]: any
      } = {}

      action.payload.selectedIds.forEach((id, index) => {
        const newId = action.payload.newIds[index]
        const object = state[id]

        duplicatedObjects[newId] = {
          ...object,
          config: {
            ...object.config,
            id: newId,
            coords: {
              ...object.config.coords,
              y: object.config.coords.y + 50
            }
          }
        }
      })

      return {...state, ...duplicatedObjects}
    }
    case ObjectsActionType.FLIP_OBJECTS: {
      const {flip, ids} = action.payload

      const boundingRectanglesMap = computeBoundingRects(ids, state)
      const commonBoundingRectangle = mergeBoundingRects(
        Object.values(boundingRectanglesMap)
      )

      const flippedObjects = flipObjects(
        flip,
        commonBoundingRectangle,
        ids,
        state
      )

      return {...state, ...flippedObjects}
    }
    case ObjectsActionType.REMOVE_OBJECTS: {
      return _.omit(state, action.payload)
    }
    case ObjectsActionType.SET_OBJECTS_STATE: {
      return action.payload
    }
    case ObjectsActionType.UPDATE_OBJECTS: {
      const updateConfig = (value: ObjectsStateValue, id: string) => {
        const config: unknown = _.merge(
          _.cloneDeep(value.config),
          action.payload[id].config
        )
        return config
      }

      return _.mapValues<IObjectsState, ObjectsStateValue>(state, (value) => {
        const id = value.config.id

        if (!(id in action.payload)) {
          return value
        }

        if (value.type === CanvasObjectType.Icon) {
          return {...value, config: updateConfig(value, id) as IIcon}
        }

        if (value.type === CanvasObjectType.Shape) {
          return {...value, config: updateConfig(value, id) as IShape}
        }

        if (value.type === CanvasObjectType.Seat) {
          return {...value, config: updateConfig(value, id) as ISeat}
        }

        if (value.type === CanvasObjectType.Text) {
          return {...value, config: updateConfig(value, id) as IText}
        }

        if (value.type === CanvasObjectType.Zone) {
          return {...value, config: updateConfig(value, id) as IZone}
        }

        return value
      })
    }
    case ObjectsActionType.UPDATE_OBJECTS_POSITION: {
      const idsToUpdate = action.payload.ids
      return _.mapValues<IObjectsState, ObjectsStateValue>(state, (value) => {
        const id = value.config.id

        if (!idsToUpdate.includes(id)) {
          return value
        }

        const coords = value.config.coords

        const newValue = _.cloneDeep(value)
        newValue.config.coords = {
          x: coords.x + action.payload.offsetX,
          y: coords.y + action.payload.offsetY
        }
        return newValue
      })
    }
    default:
      return state
  }
}
