import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  SvgIconProps,
  SxProps,
  Typography
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {NEUTRAL_900_COLOR, Theme} from '../../theme'
import {BOTTOM_BAR_HEIGHT, TOP_BAR_HEIGHT} from '../constants'
import {Error, Loading} from '../visual'

export const SCROLLABLE_CONTENT_ID = 'drawer-content'
const DEFAULT_DRAWER_WIDTH = 500
const PADDING = 14
export const HEIGHT = 36 + PADDING * 2 + 1

const useDrawerTemplateHeaderStyles = makeStyles<Theme>((theme) => ({
  header: {
    borderBottom: `solid ${theme.palette.divider} 1px`,
    background: theme.palette.background.paper,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    gap: theme.spacing(1)
  },
  iconButton: {
    color: NEUTRAL_900_COLOR
  }
}))

interface IDrawerTemplateHeaderProps {
  className?: string
  onLeftActionClick: () => void
  LeftActionIcon?: React.FC<SvgIconProps>
  title: string
  noWrap?: boolean
}

export const DrawerTemplateHeader: React.FC<IDrawerTemplateHeaderProps> = ({
  className,
  title,
  LeftActionIcon = CloseIcon,
  onLeftActionClick,
  noWrap = false
}: IDrawerTemplateHeaderProps) => {
  const classes = useDrawerTemplateHeaderStyles()
  const handleHelpClick = useCallback(() => {
    const elements = document.getElementsByClassName('stonly-widget-trigger')
    if (elements.length) {
      elements.item(0)?.dispatchEvent(new Event('click'))
    }
  }, [])
  return (
    <div className={cn(classes.header, className)}>
      <IconButton onClick={onLeftActionClick} className={classes.iconButton}>
        <LeftActionIcon color="inherit" />
      </IconButton>
      <Typography variant="h6" noWrap={noWrap}>
        {title}
      </Typography>
      <IconButton onClick={handleHelpClick} className={classes.iconButton}>
        <HelpOutlineIcon color="inherit" />
      </IconButton>
    </div>
  )
}

const useDrawerActionsBarStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    marginBottom: HEIGHT
  },
  actionsWrapper: {
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
    padding: `${PADDING}px ${theme.spacing(3)}`,
    height: HEIGHT,
    background: theme.palette.background.paper,
    borderTop: '1px solid #E0E0E0',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  }
}))

export interface IDrawerActionBarProps {
  formId: string
  onClose: (e: SyntheticEvent) => any
  submitText?: string
  cancelText?: string
  width?: number | string
  cypressId?: string
}

export const DrawerActionsBar: React.FC<IDrawerActionBarProps> = ({
  formId,
  submitText,
  cancelText,
  onClose,
  cypressId,
  width = DEFAULT_DRAWER_WIDTH
}: IDrawerActionBarProps) => {
  const {t} = useTranslation()
  const classes = useDrawerActionsBarStyles()
  const _submitText = submitText || t('Save')
  const _cancelText = cancelText || t('Cancel')

  return (
    <Box className={classes.wrapper}>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.actionsWrapper}
        style={{width}}
      >
        <Button color="primary" onClick={onClose}>
          {_cancelText}
        </Button>
        <Button
          cypress-id={cypressId}
          variant="contained"
          color="primary"
          type="submit"
          form={formId}
        >
          {_submitText}
        </Button>
      </Grid>
    </Box>
  )
}

interface ICustomDrawerActionBarProps {
  children: React.ReactNode | Array<React.ReactNode>
  width?: number | string
}

export const CustomDrawerActionsBar: React.FC<ICustomDrawerActionBarProps> = ({
  children,
  width = DEFAULT_DRAWER_WIDTH
}: ICustomDrawerActionBarProps) => {
  const classes = useDrawerActionsBarStyles()

  return (
    <Box className={classes.wrapper}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        className={classes.actionsWrapper}
        style={{width}}
      >
        {children}
      </Grid>
    </Box>
  )
}

const useDrawerFormStyles = makeStyles<Theme, {hasActionBar: boolean}>(
  (theme) => ({
    wrapper: {
      height: '100%'
    },
    header: {
      background: theme.palette.background.paper,
      position: 'absolute',
      height: HEIGHT,
      top: 0,
      left: 0,
      right: 0,
      overflow: 'hidden'
    },
    titleBlock: {
      padding: theme.spacing(0, 3),
      height: 64
    },
    content: {
      position: 'absolute',
      top: HEIGHT,
      bottom: HEIGHT,
      left: 0,
      right: 0,
      overflow: ({hasActionBar}) => (hasActionBar ? 'auto' : 'visible'),
      padding: theme.spacing(4, 3)
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      height: HEIGHT,
      left: 0,
      right: 0,
      overflow: 'hidden'
    }
  })
)

export interface IDrawerForm {
  title: string
  ActionBar?: React.ReactNode
  children: React.ReactNode
  onClose: () => void
  width?: number | string
  wrapperClassName?: string
  bodyClassName?: string
  LeftActionIcon?: React.FC<SvgIconProps>
  noWrap?: boolean
}

/**
 * @deprecated - please use DrawerTemplate, when possible
 */
export const DrawerForm: React.FC<IDrawerForm> = ({
  title,
  ActionBar,
  children,
  onClose,
  wrapperClassName,
  bodyClassName,
  LeftActionIcon,
  noWrap = false,
  width = DEFAULT_DRAWER_WIDTH
}: IDrawerForm) => {
  const classes = useDrawerFormStyles({hasActionBar: Boolean(ActionBar)})

  return (
    <Grid
      container
      className={cn(classes.wrapper, wrapperClassName)}
      style={{width}}
    >
      <Grid item>
        <DrawerTemplateHeader
          onLeftActionClick={onClose}
          LeftActionIcon={LeftActionIcon}
          title={title}
          noWrap={noWrap}
          className={classes.header}
        />
      </Grid>
      <Grid
        item
        id={SCROLLABLE_CONTENT_ID}
        className={cn(classes.content, bodyClassName)}
      >
        {children}
      </Grid>
      {ActionBar && (
        <Grid item className={classes.footer}>
          {ActionBar}
        </Grid>
      )}
    </Grid>
  )
}

export const DRAWER_TRANSITION_DURATION = 500

interface IDrawerWithDetailProps {
  children: (props: {
    debouncedId: number
    closeDrawer: () => void
  }) => React.ReactNode
  id: number
  onClose: (id: number) => void
}

/**
 * @deprecated - check ReservationDetailDrawer for implementation of debounced drawer, hint: use `SlideProps.onExited` of drawer
 */
export const DrawerWithDebouncedId: React.FC<IDrawerWithDetailProps> = ({
  id,
  children,
  onClose
}: IDrawerWithDetailProps) => {
  const [debouncedId, setDebouncedId] = useState(id)
  useEffect(() => {
    if (id) {
      setDebouncedId(id)
    }
  }, [id])
  const closeDrawer = useCallback(() => {
    onClose(debouncedId)
  }, [debouncedId, onClose])

  return (
    <Drawer
      open={!!id}
      onClose={closeDrawer}
      anchor="right"
      transitionDuration={DRAWER_TRANSITION_DURATION}
    >
      {debouncedId && children({debouncedId, closeDrawer})}
    </Drawer>
  )
}

export interface IDrawerSectionProps {
  label?: string
  id: string
  isWithoutPaper?: boolean
  actions?: React.ReactNode
  className?: string
  children: React.ReactNode
}

export const DrawerSection: React.FC<IDrawerSectionProps> = ({
  label,
  id,
  children,
  isWithoutPaper,
  actions,
  className
}: IDrawerSectionProps) => (
  <div id={id} className={className}>
    <Box
      paddingBottom={1}
      paddingTop={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="subtitle1">{label}</Typography>
      {actions}
    </Box>
    <Box>
      {isWithoutPaper ? children : <Paper variant="outlined">{children}</Paper>}
    </Box>
  </div>
)

const useDrawerEditSectionStyles = makeStyles<Theme>((theme) => ({
  label: {
    paddingBottom: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  }
}))

interface IDrawerEditSectionProps {
  label: string
  id: string
  className?: string
  children: React.ReactNode
}

export const DrawerEditSection: React.FC<IDrawerEditSectionProps> = ({
  label,
  id,
  children,
  className
}: IDrawerEditSectionProps) => {
  const classes = useDrawerEditSectionStyles()
  return (
    <div id={id} className={className}>
      <Paper variant="outlined" classes={{root: classes.paper}}>
        <Typography variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
        <div>{children}</div>
      </Paper>
    </div>
  )
}

const useSingleCategoryGridStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto 5fr',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  }
}))

interface ISectionGridProps {
  children: React.ReactNode
}

export const SectionGrid: React.FC<ISectionGridProps> = ({
  children
}: ISectionGridProps) => {
  const classes = useSingleCategoryGridStyles()
  return <div className={classes.grid}>{children}</div>
}

interface ISectionCoupleProps {
  label: string
  children: React.ReactNode
  isLabelCentered?: boolean
}

const useSectionGridCoupleStyles = makeStyles(() => ({
  centeredLabel: {
    alignSelf: 'center'
  }
}))

export const SectionGridCouple: React.FC<ISectionCoupleProps> = ({
  label,
  children,
  isLabelCentered
}: ISectionCoupleProps) => {
  const classes = useSectionGridCoupleStyles()
  return children ? (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        className={cn({
          [classes.centeredLabel]: isLabelCentered
        })}
      >
        {label}
      </Typography>
      {['string', 'number'].includes(typeof children) ? (
        <Typography variant="body2">{children}</Typography>
      ) : (
        children
      )}
    </>
  ) : null
}

const useSectionGridSubtitle = makeStyles(() => ({
  subtitle: {
    gridColumn: '1 / span 2'
  }
}))

interface ISectionGridSubtitleProps {
  subtitle: string
}

export const SectionGridSubtitle: React.FC<ISectionGridSubtitleProps> = ({
  subtitle
}: ISectionGridSubtitleProps) => {
  const classes = useSectionGridSubtitle()
  return (
    <Typography variant="subtitle2" className={classes.subtitle}>
      {subtitle}
    </Typography>
  )
}

export interface IDrawerTemplateProps {
  children: React.ReactNode
  /**
   * @deprecated use sx prop
   */
  className?: string
  /**
   * @deprecated use childrenSx prop
   */
  childrenClassName?: string
  header: React.ReactNode
  footer?: React.ReactNode
  isFooterFullWidth?: boolean
  isLoading?: boolean
  errorMessage?: string
  sx?: SxProps
  childrenSx?: SxProps
}

/**
 * Use only for Drawers, for whole pages, use PageWithHeaderTemplate or PageWithHeaderAndFooterTemplate!
 */
export const DrawerTemplate = ({
  children,
  className,
  childrenClassName,
  header,
  footer,
  isFooterFullWidth = false,
  isLoading,
  errorMessage,
  sx,
  childrenSx
}: IDrawerTemplateProps) => (
  <Box
    className={className}
    sx={{
      display: 'grid',
      height: '100%',
      gridTemplateRows: footer
        ? `${TOP_BAR_HEIGHT}px 1fr ${BOTTOM_BAR_HEIGHT}px`
        : `${TOP_BAR_HEIGHT}px 1fr `,
      ...sx
    }}
  >
    {header}
    {isLoading ? (
      <Loading />
    ) : errorMessage ? (
      <Error message={errorMessage} error={null} />
    ) : (
      <Box
        className={childrenClassName}
        sx={{
          overflow: 'auto',
          backgroundColor: 'background.default',
          ...childrenSx
        }}
      >
        {children}
      </Box>
    )}
    {!isLoading && !errorMessage && footer && (
      <Box
        sx={(theme) => ({
          background: 'background.paper',
          borderTop: `solid ${theme.palette.divider} 1px`,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          px: 3,
          py: 0
        })}
      >
        <Box
          sx={{
            width: isFooterFullWidth ? '100%' : undefined,
            display: 'grid',
            gridAutoFlow: 'column',
            gap: 2
          }}
        >
          {footer}
        </Box>
      </Box>
    )}
  </Box>
)

export const useDrawerWithSideNavigationStyles = makeStyles<Theme>(() => ({
  paper: {
    width: '100%',
    maxWidth: 960
  }
}))
