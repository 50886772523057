import _ from 'lodash'
import dayjs from 'dayjs'

import {useEventCalendarDateProperties} from './context/eventCalendarDateProperties'
import {useEventCalendarView} from './context/eventCalendarView'
import {CalendarSettingsFieldName, ViewType} from './calendar/types'
import {useEventCalendarSettings} from './context/eventCalendarSettingsProvider'

// duration is in minutes
export const getEventEndDate = (start: Date, duration: number) => {
  const clonedStart = new Date(start.getTime())
  clonedStart.setMinutes(clonedStart.getMinutes() + duration)
  return clonedStart
}

export const SIDE_MENU_WIDTH = 380

export const getDistributionString = ({
  version,
  format,
  soundMix
}: {
  version?: string
  format?: string
  soundMix?: string
}) => _.compact([format, version, soundMix]).join(' • ')

export const useGetEventsQueryParams = () => {
  const {selectedDate} = useEventCalendarDateProperties()
  const [{[CalendarSettingsFieldName.FIRST_DAY_OF_WEEK]: firstDayOfWeek}] =
    useEventCalendarSettings()
  const {viewType} = useEventCalendarView()

  let from
  let to

  if (viewType === ViewType.Month) {
    from = dayjs(selectedDate).startOf('month')
    to = dayjs(selectedDate).add(1, 'month').startOf('month')
  } else if (viewType === ViewType.Day) {
    from = dayjs(selectedDate).startOf('day')
    to = dayjs(selectedDate).add(1, 'day').startOf('day')
  } else {
    const currentDayOfWeek = selectedDate.getDay()
    let distance = 0
    if (currentDayOfWeek > firstDayOfWeek) {
      distance = currentDayOfWeek - firstDayOfWeek
    }
    if (firstDayOfWeek > currentDayOfWeek) {
      distance = 7 - firstDayOfWeek + currentDayOfWeek
    }

    from = dayjs(selectedDate).subtract(distance, 'day').startOf('day')
    to = dayjs(selectedDate)
      .add(7 - distance, 'day')
      .startOf('day')
  }
  return {from: from.toISOString(), to: to.toISOString()}
}
