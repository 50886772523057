import {ICoords, IDimensions} from '@attendio/shared-components'
import Decimal from 'decimal.js'
import Konva from 'konva'

import {EXPORT_PIXEL_RATIO, PLAN_PADDING, SCALE_FACTOR} from '../config'
import {IRectangle} from '../types'

interface CoordsToTransform {
  coords: ICoords
  origin: ICoords
  scale: number
}

// inspired by: https://codesandbox.io/s/zn5n57r0wl
export const toCanvasCoords = ({
  coords,
  origin,
  scale
}: CoordsToTransform): ICoords => ({
  x: new Decimal(coords.x)
    .div(scale)
    .minus(new Decimal(origin.x).div(scale))
    .toNumber(),
  y: new Decimal(coords.y)
    .div(scale)
    .minus(new Decimal(origin.y).div(scale))
    .toNumber()
})

export const rectangleToCanvasCoords = (
  rectangle: IRectangle,
  origin: ICoords,
  scale: number
): IRectangle => {
  const rectangleOrigin = toCanvasCoords({
    coords: {x: rectangle.x, y: rectangle.y},
    origin,
    scale
  })

  return {
    x: rectangleOrigin.x,
    y: rectangleOrigin.y,
    width: rectangle.width / scale,
    height: rectangle.height / scale
  }
}

export const getMouseCoordsOnScreen = (
  e: Konva.KonvaEventObject<MouseEvent>
): null | ICoords => {
  const stage = e.target.getStage()
  if (!stage) return null

  return stage.getPointerPosition()
}

export const getMouseCoordsOnCanvas = (
  e: Konva.KonvaEventObject<MouseEvent>
): null | ICoords => {
  const stage = e.target.getStage()
  if (!stage) return null

  const pointerPosition = stage.getPointerPosition()
  if (!pointerPosition) return null

  const stageX = stage.attrs.x || 0
  const stageY = stage.attrs.y || 0

  const stageScale = stage.attrs.scaleX || stage.attrs.scaleY || 1

  return toCanvasCoords({
    coords: {x: pointerPosition.x, y: pointerPosition.y},
    origin: {x: stageX, y: stageY},
    scale: stageScale
  })
}

export const isPlatformMac = () => {
  return (
    navigator.platform.indexOf('Mac') > -1 ||
    navigator.platform.indexOf('iPad') > -1
  )
}

export const getFitToScreenProperties = (
  boundingRect: IRectangle,
  view: IDimensions
): {origin: ICoords; scale: number} => {
  const effectiveView = {
    width: view.width - PLAN_PADDING * 2,
    height: view.height - PLAN_PADDING * 2
  }

  const scale = Math.min(
    effectiveView.width / boundingRect.width,
    effectiveView.height / boundingRect.height
  )

  const difference = {
    width: effectiveView.width - boundingRect.width * scale,
    height: effectiveView.height - boundingRect.height * scale
  }

  const origin = {
    x: -boundingRect.x * scale + PLAN_PADDING + difference.width / 2,
    y: -boundingRect.y * scale + PLAN_PADDING + difference.height / 2
  }

  return {origin, scale}
}

/**
 * @deprecated, use getBiggerScale and getSmallerScale
 */
export const findNearestScale = (value: number, bigger: boolean) => {
  let nearestScale = 1
  const factor = nearestScale < value ? SCALE_FACTOR : 1 / SCALE_FACTOR

  while (
    Math.abs(value - nearestScale) > Math.abs(value - nearestScale * factor)
  ) {
    nearestScale = nearestScale * factor
  }

  if (bigger) {
    return nearestScale >= value ? nearestScale : nearestScale * SCALE_FACTOR
  } else {
    return nearestScale <= value ? nearestScale : nearestScale / SCALE_FACTOR
  }
}

export const getChangeCursor =
  (stage: Konva.Stage | null) => (cursor: string) => {
    if (stage) {
      stage.container().style.cursor = cursor
    }
  }

// See: https://konvajs.org/docs/data_and_serialization/High-Quality-Export.html
export const exportToPng = (stage: Konva.Stage) => {
  const dataURL = stage.toDataURL({pixelRatio: EXPORT_PIXEL_RATIO})

  const link = document.createElement('a')
  link.download = 'layout.png'
  link.href = dataURL

  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
}

export const getOriginAfterZoom = ({
  anchor,
  newScale,
  dimensions,
  origin,
  scale
}: {
  anchor?: ICoords
  newScale: number
  dimensions: IDimensions
  origin: ICoords
  scale: number
}) => {
  // if no anchor supplied then take the centre of the screen as default
  const anchorOnScreen = anchor || {
    x: new Decimal(dimensions.width).div(2).toNumber(),
    y: new Decimal(dimensions.height).div(2).toNumber()
  }

  const anchorOnCanvas = toCanvasCoords({
    coords: anchorOnScreen,
    origin,
    scale
  })

  return {
    x: -new Decimal(anchorOnCanvas.x)
      .minus(new Decimal(anchorOnScreen.x).div(newScale))
      .mul(newScale)
      .toNumber(),
    y: -new Decimal(anchorOnCanvas.y)
      .minus(new Decimal(anchorOnScreen.y).div(newScale))
      .mul(newScale)
      .toNumber()
  }
}
