import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'

import {useEnsurePermissions} from '../../../../utils/auth'
import {VENUES_PARAMS as VP} from '../../../../utils/pathname'

import {routeTo, toPlaceholderParam} from '../../../../utils/routes'
import {PageNotFound} from '../PageNotFound'

import {AuditoriumLayoutDetail} from './auditoriumLayouts/Detail'
import {AuditoriumDetail} from './auditoriums/AuditoriumDetail'
import {VenueDetail} from './VenueDetail'

export const Venues = () => {
  const {P} = useEnsurePermissions()

  // We use multiple paths for same component, so that we are able to determine when/which Drawer should appear.
  // We do so because wrapping Drawer directly into Route causes animations problems.
  return (
    <Switch>
      {/* TODO: rename ":id" to ":auditoriumId" */}
      {P([PermissionCode.ReadVenue]) && (
        <Route
          path={[routeTo.admin.venues.detail(toPlaceholderParam(VP.VENUE_ID))]}
          component={VenueDetail}
          exact
        />
      )}
      {P([PermissionCode.UpdateVenue]) && (
        <Route
          path={[routeTo.admin.venues.edit(toPlaceholderParam(VP.VENUE_ID))]} // provides "match.url" prop for Drawer - EditVenue
          component={VenueDetail}
          exact
        />
      )}
      {P([PermissionCode.ReadAuditorium]) && (
        <Route
          path={[
            routeTo.admin.venues.detailAuditorium(
              toPlaceholderParam(VP.VENUE_ID),
              toPlaceholderParam(VP.AUDITORIUM_ID)
            )
          ]}
          component={AuditoriumDetail}
          exact
        />
      )}
      {P([PermissionCode.UpdateAuditorium]) && (
        <Route
          path={[
            routeTo.admin.venues.editAuditorium(
              toPlaceholderParam(VP.VENUE_ID),
              toPlaceholderParam(VP.AUDITORIUM_ID)
            ) // provides "match.url" prop for Drawer - EditAuditorium
          ]}
          component={AuditoriumDetail}
          exact
        />
      )}
      {P([PermissionCode.CreateAuditoriumLayout]) && (
        <Route
          path={[
            routeTo.admin.venues.addAuditoriumLayout(
              toPlaceholderParam(VP.VENUE_ID),
              toPlaceholderParam(VP.AUDITORIUM_ID)
            ) // provides "match.url" prop for Drawer - AddAuditoriumLayout
          ]}
          component={AuditoriumDetail}
          exact
        />
      )}
      {P([PermissionCode.ReadAuditoriumLayout]) && (
        <Route
          path={[
            routeTo.admin.venues.detailAuditoriumLayout(
              toPlaceholderParam(VP.VENUE_ID),
              toPlaceholderParam(VP.AUDITORIUM_ID),
              toPlaceholderParam(VP.AUDITORIUM_LAYOUT_ID)
            )
          ]}
          component={AuditoriumLayoutDetail}
          exact
        />
      )}
      {P([PermissionCode.CreateAuditoriumLayoutPricing]) && (
        <Route
          path={[
            // provides "match.url" prop for Drawer - AddLayoutPricing
            routeTo.admin.venues.addLayoutPricing(
              toPlaceholderParam(VP.VENUE_ID),
              toPlaceholderParam(VP.AUDITORIUM_ID),
              toPlaceholderParam(VP.AUDITORIUM_LAYOUT_ID)
            )
          ]}
          component={AuditoriumLayoutDetail}
          exact
        />
      )}
      <Route path="*" component={PageNotFound} />
    </Switch>
  )
}
