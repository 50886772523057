import {
  alpha,
  Box,
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
  FormControlProps,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {Theme} from '../../theme'
import {FormSubText} from './FormSubText'
import {FormFieldName} from './types'
import {getFormError} from './utils'

export interface RadioGroupOption<OptionValue extends string> {
  label: FormControlLabelProps['label']
  value: OptionValue
  disabled?: boolean
}

export interface IUncontrolledFormRadioGroupProps<
  FormValues extends FieldValues = FieldValues,
  OptionValue extends string = string
> extends Pick<
    FormControlProps<typeof RadioGroup>,
    'className' | 'disabled' | 'fullWidth'
  > {
  name: FormFieldName<FormValues>
  control: FormContextValues<FormValues>['control']
  errors: FieldErrors<FormValues>
  validationOptions?: ValidationOptions
  helperText?: string
  options: RadioGroupOption<OptionValue>[]
  formControlLabelClassName?: string
  selectedFormControlLabelClassName?: string
  label?: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  formControlLabel: {
    borderWidth: 1,
    display: 'flex',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    margin: theme.spacing(0, 0, 2),
    padding: theme.spacing(1.5, 3, 1.5, 1.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    }
  },
  selectedFormControlLabel: {
    borderWidth: 1,
    display: 'flex',
    borderStyle: 'solid',
    margin: theme.spacing(0, 0, 2),
    padding: theme.spacing(1.5, 3, 1.5, 1.5),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    },
    borderColor: theme.palette.primary.main,
    position: 'relative',
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      borderRadius: 4,
      backgroundColor: alpha(theme.palette.primary.main, 0.12)
    }
  },
  formSubText: {
    paddingBottom: theme.spacing(2)
  }
}))

export const UncontrolledFormRadioGroup = <
  FormValues extends FieldValues = FieldValues,
  OptionValue extends string = string
>({
  name,
  control,
  validationOptions,
  options,
  errors,
  helperText,
  className,
  formControlLabelClassName,
  selectedFormControlLabelClassName,
  disabled,
  fullWidth,
  label
}: IUncontrolledFormRadioGroupProps<FormValues, OptionValue>) => {
  const classes = useStyles()
  const error = getFormError(errors, name)
  return (
    <FormControl
      error={Boolean(error)}
      required={Boolean(validationOptions?.required)}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {label && <Typography variant="subtitle1">{label}</Typography>}
      <FormSubText
        error={error}
        helperText={helperText}
        validationOptions={validationOptions}
        className={classes.formSubText}
      />
      <Controller
        as={({value, ...props}) => (
          <RadioGroup {...props} value={value || null}>
            {options.map((option) => (
              <FormControlLabel
                key={`key-${option.value}`}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
                className={
                  value === option.value
                    ? cn(
                        classes.selectedFormControlLabel,
                        selectedFormControlLabelClassName
                      )
                    : cn(classes.formControlLabel, formControlLabelClassName)
                }
                sx={{[`& .${formControlLabelClasses.label}`]: {width: '100%'}}}
                disabled={option.disabled}
              />
            ))}
          </RadioGroup>
        )}
        control={control}
        rules={validationOptions}
        name={name}
      />
    </FormControl>
  )
}

const useBasicRadioLabelStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    width: '100%',
    pl: 0.5,
    gridTemplateAreas: `
        "primaryText rightColumnText"
        "secondaryText rightColumnText"
      `
  },
  secondaryText: {
    gridArea: 'secondaryText'
  },
  rightColumnText: {
    gridArea: 'rightColumnText',
    alignSelf: 'center',
    justifySelf: 'flex-end'
  }
}))

interface IBasicRadioLabelProps {
  primaryText: string
  secondaryText?: string
  rightColumnText?: string
}
export const BasicRadioLabel: React.FC<IBasicRadioLabelProps> = ({
  primaryText,
  secondaryText,
  rightColumnText
}: IBasicRadioLabelProps) => {
  const classes = useBasicRadioLabelStyles()
  return (
    <Box className={classes.root}>
      <Typography variant="subtitle2">{primaryText}</Typography>
      <Typography
        variant="caption"
        className={classes.secondaryText}
        color="textSecondary"
      >
        {secondaryText}
      </Typography>
      <Typography className={classes.rightColumnText} variant="body2">
        {rightColumnText}
      </Typography>
    </Box>
  )
}
