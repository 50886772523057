import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightDivisionsQuery,
  ToursFilter,
  TourState
} from '../../../../__generated__/schema'
import {useTranslateTourState} from '../../../../hooks/tourState'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useGetLightweightDivisions} from '../graphql'

const mapHasTextToFilter = (
  filter: ToursFilter,
  hasText: string | undefined
): ToursFilter => ({...filter, hasText})

const mapTourStateToFilter = (
  filter: ToursFilter,
  states: TourState | undefined
): ToursFilter => ({...filter, states: states ? [states] : undefined})

const mapDivisionIdToFilter = (
  filter: ToursFilter,
  divisionId: number | undefined
): ToursFilter => ({
  ...filter,
  divisionId
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateTourState = useTranslateTourState()
  const getHasTextFromSearchObject = (filter: ToursFilter) =>
    filter.hasText || undefined
  const getTourStateFromSearchObject = (filter: ToursFilter) =>
    filter.states
      ? t('State: {{tourState}}', {
          tourState: translateTourState(filter.states[0])
        })
      : undefined
  const getDivisionFromSearchObject = (
    filter: ToursFilter,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionId) {
      const division = divisions.find(
        (division) => division.id === filter.divisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filter.divisionId})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getTourStateFromSearchObject,
    getDivisionFromSearchObject
  }
}

interface IToursSearchProps {
  onFilterChange: (filter: ToursFilter) => void
}

export const ToursSearch: React.FC<IToursSearchProps> = ({
  onFilterChange
}: IToursSearchProps) => {
  const {t} = useTranslation()
  const {divisions} = useGetLightweightDivisions()
  const translateTourState = useTranslateTourState()
  const {
    getHasTextFromSearchObject,
    getTourStateFromSearchObject,
    getDivisionFromSearchObject
  } = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject,
    getTourStateFromSearchObject,
    (filter) => getDivisionFromSearchObject(filter, divisions)
  )
  const tourStateSelectOptions: ISelectOption<TourState>[] = Object.values(
    TourState
  ).map((state) => ({id: state, label: translateTourState(state)}))
  const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
    (division) => ({
      id: division.id,
      label: division.name
    })
  )
  return (
    <Search<ToursFilter>
      storageKey="TOURS"
      placeholder={t('Search for tours')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow
            label={t('State')}
            value={
              advancedSearchObject.states
                ? advancedSearchObject.states[0]
                : undefined
            }
            options={tourStateSelectOptions}
            mapSelectValueToSearchObject={mapTourStateToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<ToursFilter, number>
            label={t('Division')}
            value={advancedSearchObject.divisionId || undefined}
            options={divisionSelectOptions}
            mapSelectValueToSearchObject={mapDivisionIdToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow<ToursFilter>
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
