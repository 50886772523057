import FormatClearIcon from '@mui/icons-material/FormatClear'
import FormatColorTextIcon from '@mui/icons-material/FormatColorText'
import {Box, Divider, Typography} from '@mui/material'
import {hexToRgb} from '@mui/material/styles'
import {
  ContentState,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {GithubPicker} from 'react-color'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {FieldValues, FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDebounce} from '../../../hooks/debounce'
import {FormFieldName} from '../../form/types'

import boldIcon from './assets/bold-icon.svg'
import bulletedListIcon from './assets/bulleted-list-icon.svg'
import linkIcon from './assets/link-icon.svg'
import linkOffIcon from './assets/link-off-icon.svg'
import './textEditor.css'
import {editorLabels, useTextEditorStyles} from './utils'

const COLORS_HEX = [
  '#000000', // BLACK (default)
  '#d0021b', // RED
  '#1273de', // BLUE
  '#008b02' // GREEN
]
const COLORS_RGB = [
  hexToRgb(COLORS_HEX[0]).replace(/\s+/g, ''),
  hexToRgb(COLORS_HEX[1]).replace(/\s+/g, ''),
  hexToRgb(COLORS_HEX[2]).replace(/\s+/g, ''),
  hexToRgb(COLORS_HEX[3]).replace(/\s+/g, '')
]
// styles used by editor: local changes - hex colors; saved as html - rgb colors; bold text;
const EDITOR_STYLES = COLORS_HEX.concat(COLORS_RGB)
  .map((color) => `color-${color}`)
  .concat('BOLD')

interface IColorPickerProps {
  // props are passed by ColorPickerComponent from react-draft-wysiwyg
  expanded: boolean
  onExpandEvent: () => void
  onChange: any
  currentState: any
}

const ColorPicker: React.FC<IColorPickerProps> = ({
  expanded,
  onExpandEvent,
  onChange,
  currentState
}: IColorPickerProps) => {
  const classes = useTextEditorStyles()
  const {t} = useTranslation()

  const stopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation()
  }

  const onColorChange = (color: any) => {
    onChange('color', color.hex)
  }

  const ColorModal = () => {
    return (
      <div onClick={stopPropagation} className={classes.colorPickerModal}>
        <div className={classes.colorPickerWrapper}>
          <GithubPicker
            // ts complains about color on currentState prop even when typed
            // eslint-disable-next-line react/prop-types
            color={currentState.color}
            width="112px"
            colors={COLORS_HEX}
            onChangeComplete={onColorChange}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="rdw-inline-wrapper">
      <div
        title={t('Color')}
        style={{color: currentState.color}}
        className="rdw-option-wrapper"
        onClick={onExpandEvent}
      >
        <FormatColorTextIcon />
        {expanded && <ColorModal />}
      </div>
    </div>
  )
}

interface IFormattingCleanerProps {
  onChange: (state: EditorState) => void
  editorState: EditorState
}

const FormattingCleaner: React.FC<IFormattingCleanerProps> = ({
  onChange,
  editorState
}: IFormattingCleanerProps) => {
  const classes = useTextEditorStyles()
  const {t} = useTranslation()

  const onFormattingClear = () => {
    const selection = editorState.getSelection()
    const contentWithoutStyles = EDITOR_STYLES.reduce((contentState, style) => {
      return Modifier.removeInlineStyle(contentState, selection, style)
    }, editorState.getCurrentContent())

    onChange(
      EditorState.push(editorState, contentWithoutStyles, 'change-inline-style')
    )
  }

  return (
    <>
      <Divider orientation="vertical" className={classes.dividerVertical} />
      <div className="rdw-inline-wrapper">
        <div
          title={t('Clear format')}
          className={'rdw-option-wrapper'}
          onClick={onFormattingClear}
        >
          <FormatClearIcon />
        </div>
      </div>
    </>
  )
}

interface IEditorProps<FormValues extends FieldValues = FieldValues> {
  inputRef: any
  defaultValue?: string // html
  name: FormFieldName<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
}

export const TextEditor = <FormValues extends FieldValues = FieldValues>({
  inputRef,
  defaultValue = '',
  setValue,
  name
}: IEditorProps<FormValues>) => {
  const classes = useTextEditorStyles()
  const {t} = useTranslation()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const _onChange = useCallback(
    (state: EditorState) => {
      const rawContentState = convertToRaw(state.getCurrentContent())
      const html = draftToHtml(rawContentState)
      setValue(name, html as any)
    },
    [name, setValue]
  )

  const debouncedOnChange = useDebounce(_onChange, 500)

  const onEditorStateChange = useCallback(
    (state: EditorState) => {
      debouncedOnChange(state)
      setEditorState(state)
    },
    [debouncedOnChange]
  )

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(defaultValue)
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
    )
    _onChange(newEditorState)
    setEditorState(newEditorState)
    // only dependant on defaultValue to get draftjs format from html
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  // custom onTab needed to restrict depth of lists
  const onTab = (e: any) => {
    // prevent propagation to other elements
    e.preventDefault()

    // get newState with max depth for lists = 0
    const newState = RichUtils.onTab(e, editorState, 0)

    // return value constants are expected by the library
    // 'handled' - the command is considered handled
    // 'not-handled' - the command will fall through
    if (newState) {
      setEditorState(newState)
      return 'handled'
    } else {
      return 'not-handled'
    }
  }

  return (
    <>
      <input ref={inputRef} name={name} type="hidden" />
      <Editor
        {...{editorState, onEditorStateChange, onTab}}
        stripPastedStyles
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        localization={{locale: 'multi', translations: editorLabels(t)}}
        toolbar={{
          options: ['inline', 'colorPicker', 'list', 'link'],
          colorPicker: {component: ColorPicker},
          inline: {
            options: ['bold'],
            bold: {icon: boldIcon, title: t('Bold')}
          },
          list: {
            options: ['unordered'],
            unordered: {icon: bulletedListIcon, title: t('List')}
          },
          link: {
            options: ['link', 'unlink'],
            link: {icon: linkIcon, title: t('Link')},
            unlink: {icon: linkOffIcon, title: t('Unlink')}
          }
        }}
        toolbarCustomButtons={[
          <FormattingCleaner
            key="formattingCleaner"
            onChange={onEditorStateChange}
            editorState={editorState}
          />
        ]}
      />
    </>
  )
}

interface ITextEditorLabelProps {
  children: string
}

export const TextEditorLabel: React.FC<ITextEditorLabelProps> = ({
  children
}: ITextEditorLabelProps) => {
  return (
    <Box paddingTop={2}>
      <Typography variant="subtitle1">{children}</Typography>
    </Box>
  )
}
