import dayjs from 'dayjs'
import {identity, pickBy} from 'lodash'
import {
  ApiGrantFieldsFragment,
  ApiGrantInput,
  ApiGrantState,
  ApiResource,
  UpdateApiGrantInput
} from '../../../../__generated__/schema'
import {ApiGrantField, IApiGrantForm} from './types'

export const transformFormDataToApiGrantInput = (
  formData: IApiGrantForm
): ApiGrantInput => ({
  name: formData[ApiGrantField.Name],
  description: formData[ApiGrantField.Description],
  grantedResources: Object.keys(
    pickBy(formData[ApiGrantField.GrantedResources], identity)
  ).map((resource) => resource as ApiResource),
  maxRequestsPerMinute: formData.maxRequestsPerMinute
    ? parseInt(formData[ApiGrantField.MaxRequestsPerMinute], 10)
    : undefined,
  maxRequestsPerHour: formData.maxRequestsPerHour
    ? parseInt(formData[ApiGrantField.MaxRequestsPerHour], 10)
    : undefined,
  state: ApiGrantState.Active,
  technicalContact: {
    email: formData[ApiGrantField.TechnicalContactEmail],
    phone: formData[ApiGrantField.TechnicalContactPhone] || undefined,
    name: formData[ApiGrantField.TechnicalContactName],
    role: formData[ApiGrantField.TechnicalContactRole] || undefined
  },
  businessContact: {
    email: formData[ApiGrantField.BusinessContactEmail],
    phone: formData[ApiGrantField.BusinessContactPhone] || undefined,
    name: formData[ApiGrantField.BusinessContactName],
    role: formData[ApiGrantField.BusinessContactRole] || undefined
  },
  expiresAt: dayjs(formData[ApiGrantField.ExpiresAt]).toISOString(),
  clientIds: []
})

export const transformFormDataToUpdateApiGrantInput = (
  formData: IApiGrantForm,
  state: ApiGrantState
): UpdateApiGrantInput => ({
  name: formData[ApiGrantField.Name],
  description: formData[ApiGrantField.Description],
  grantedResources: Object.keys(
    pickBy(formData[ApiGrantField.GrantedResources], identity)
  ).map((resource) => resource as ApiResource),
  maxRequestsPerMinute: formData.maxRequestsPerMinute
    ? parseInt(formData[ApiGrantField.MaxRequestsPerMinute], 10)
    : undefined,
  maxRequestsPerHour: formData.maxRequestsPerHour
    ? parseInt(formData[ApiGrantField.MaxRequestsPerHour], 10)
    : undefined,
  state,
  technicalContact: {
    email: formData[ApiGrantField.TechnicalContactEmail],
    phone: formData[ApiGrantField.TechnicalContactPhone] || undefined,
    name: formData[ApiGrantField.TechnicalContactName],
    role: formData[ApiGrantField.TechnicalContactRole] || undefined
  },
  businessContact: {
    email: formData[ApiGrantField.BusinessContactEmail],
    phone: formData[ApiGrantField.BusinessContactPhone] || undefined,
    name: formData[ApiGrantField.BusinessContactName],
    role: formData[ApiGrantField.BusinessContactRole] || undefined
  },
  expiresAt: dayjs(formData[ApiGrantField.ExpiresAt]).toISOString()
})

export const transformApiGrantDataToDefaultValues = (
  data: ApiGrantFieldsFragment
): Partial<IApiGrantForm> => ({
  [ApiGrantField.Name]: data.name,
  [ApiGrantField.Description]: data.description,
  [ApiGrantField.ExpiresAt]: dayjs(data.expiresAt).toISOString(),
  [ApiGrantField.MaxRequestsPerMinute]: data.maxRequestsPerMinute
    ? String(data.maxRequestsPerMinute)
    : undefined,
  [ApiGrantField.MaxRequestsPerHour]: data.maxRequestsPerHour
    ? String(data.maxRequestsPerHour)
    : undefined,
  [ApiGrantField.BusinessContactName]: data.businessContact.name,
  [ApiGrantField.BusinessContactRole]: data.businessContact.role || undefined,
  [ApiGrantField.BusinessContactPhone]: data.businessContact.phone || undefined,
  [ApiGrantField.BusinessContactEmail]: data.businessContact.email,
  [ApiGrantField.TechnicalContactName]: data.technicalContact.name,
  [ApiGrantField.TechnicalContactEmail]: data.technicalContact.email,
  [ApiGrantField.TechnicalContactRole]: data.technicalContact.role || undefined,
  [ApiGrantField.TechnicalContactPhone]:
    data.technicalContact.phone || undefined,
  [ApiGrantField.TechnicalContactEmail]: data.technicalContact.email,
  [ApiGrantField.GrantedResources]: Object.values(ApiResource).reduce(
    (acc, resource) => ({
      ...acc,
      [resource]: data.grantedResources.includes(resource)
    }),
    {}
  ) as Record<ApiResource, boolean>
})
