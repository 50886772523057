import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {Box, IconButton, Typography} from '@mui/material'
import {Variant} from '@mui/material/styles/createTypography'
import React from 'react'
import {TemplateProps} from 'react-draggable-list'
import {FooterItemAlignment} from '../../../../../../__generated__/schema'
import {FooterItemWithId} from '../../types'
import {TypifiedIcon} from '../TypifiedIcon'

export interface IItemCommonProps {
  getDeleteClickHandler: (id: string) => () => void
  getEditClickHandler: (id: string) => () => void
  isLastItem: (id: string) => boolean
  alignment: FooterItemAlignment
}

interface IItemProps
  extends TemplateProps<FooterItemWithId, IItemCommonProps> {}

const Item: React.FC<IItemProps> = ({
  item,
  commonProps: {
    getDeleteClickHandler,
    getEditClickHandler,
    isLastItem,
    alignment
  },
  dragHandleProps,
  itemSelected
}: IItemProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto auto',
        alignItems: 'center',
        gap: 1,
        borderBottom: (theme) =>
          !isLastItem(item.id) || itemSelected !== 0
            ? `solid 1px ${theme.palette.divider}`
            : 'none',
        backgroundColor: 'background.paper'
      }}
    >
      <IconButton {...dragHandleProps}>
        <DragHandleIcon />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          justifyContent:
            alignment === FooterItemAlignment.Right
              ? 'flex-end'
              : alignment === FooterItemAlignment.Center
              ? 'center'
              : 'flex-start'
        }}
      >
        {item.icon && <TypifiedIcon model={item.icon} />}
        <Typography
          variant={item.typographyVariant as Variant}
          component="span"
          sx={(theme) =>
            item.link
              ? {
                  textDecoration: 'underline',
                  textDecorationColor: theme.palette.primary.main
                }
              : {}
          }
        >
          {item.label}
          {item.linkTarget === '_blank' && (
            <OpenInNewIcon color="primary" fontSize="small" sx={{ml: 1}} />
          )}
        </Typography>
      </Box>
      <IconButton onClick={getDeleteClickHandler(item.id)}>
        <DeleteIcon />
      </IconButton>
      <IconButton onClick={getEditClickHandler(item.id)}>
        <EditIcon />
      </IconButton>
    </Box>
  )
}

export class DraggableItem extends React.Component<IItemProps> {
  render() {
    return <Item {...this.props} />
  }
}
