import {Divider, MenuItem} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../__generated__/schema'
import {useTranslateCheckoutPaymentMethodsViewMode} from '../../../../hooks/translateCheckoutPaymentMethodsViewMode'
import {useTranslateShopViewMode} from '../../../../hooks/translateShopViewMode'
import {useTranslateTourTimeSlotViewMode} from '../../../../hooks/translateTourTimeSlotViewMode'
import {useTranslateZonePlanView} from '../../../../hooks/translateZonePlanView'
import {
  CheckoutPaymentMethodsViewMode,
  ShopViewMode,
  TourTimeSlotViewMode,
  ZonePlanView
} from '../../../../types'
import {useEnsurePermissions} from '../../../../utils/auth'
import {InputBlockWithoutSpacings} from '../../../common'
import {RowWithSelect} from './RowWithSelect'
import {DeviceSettingsAction, DeviceSettingsActionTypes} from './types'

interface IDefaultViewBlockProps {
  blockId: string
  blockLabel: string
  selectedZonePlanView: ZonePlanView
  selectedShopViewMode: ShopViewMode
  selectedTourTimeSlotViewMode: TourTimeSlotViewMode
  selectedCheckoutPaymentMethodsViewMode: CheckoutPaymentMethodsViewMode
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const DefaultViewBlock: React.FC<IDefaultViewBlockProps> = ({
  blockId,
  blockLabel,
  selectedZonePlanView,
  selectedShopViewMode,
  selectedTourTimeSlotViewMode,
  selectedCheckoutPaymentMethodsViewMode,
  dispatch
}: IDefaultViewBlockProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateZonePlanView = useTranslateZonePlanView()
  const translateShopViewMode = useTranslateShopViewMode()
  const translateTourTimeSlotViewMode = useTranslateTourTimeSlotViewMode()
  const translateCheckoutPaymentMethodsViewMode =
    useTranslateCheckoutPaymentMethodsViewMode()
  return (
    <InputBlockWithoutSpacings blockId={blockId} header={blockLabel}>
      <RowWithSelect
        primaryLabel={t('Zone plan')}
        selectProps={{
          value: selectedZonePlanView,
          onChange: (event) => {
            dispatch({
              type: DeviceSettingsActionTypes.ChangeZonePlanView,
              payload: event.target.value as ZonePlanView
            })
          },
          children: [ZonePlanView.ListView, ZonePlanView.SeatingPlan].map(
            (view) => (
              <MenuItem key={view} value={view}>
                {translateZonePlanView(view)}
              </MenuItem>
            )
          )
        }}
      />
      <Divider />
      <RowWithSelect
        primaryLabel={t('Shop')}
        selectProps={{
          value: selectedShopViewMode,
          onChange: (event) => {
            dispatch({
              type: DeviceSettingsActionTypes.ChangeShopViewMode,
              payload: event.target.value as ShopViewMode
            })
          },
          children: [
            ShopViewMode.ListView,
            ...(P([
              PermissionCode.ReadProductGroups,
              PermissionCode.IncrementProductItemQuantity
            ])
              ? [ShopViewMode.GridView]
              : []),
            ...(P([
              PermissionCode.IncrementProductItemQuantityWithPriceLookupCode
            ])
              ? [ShopViewMode.PriceLookupCodeInput]
              : [])
          ]
            .filter(Boolean)
            .map((mode) => (
              <MenuItem key={mode} value={mode}>
                {translateShopViewMode(mode)}
              </MenuItem>
            ))
        }}
      />
      <Divider />
      <RowWithSelect
        primaryLabel={t('Tour time slot')}
        selectProps={{
          value: selectedTourTimeSlotViewMode,
          onChange: (event) => {
            dispatch({
              type: DeviceSettingsActionTypes.ChangeTourTimeSlotViewMode,
              payload: event.target.value as TourTimeSlotViewMode
            })
          },
          children: [
            TourTimeSlotViewMode.GridView,
            TourTimeSlotViewMode.ListView
          ].map((mode) => (
            <MenuItem key={mode} value={mode}>
              {translateTourTimeSlotViewMode(mode)}
            </MenuItem>
          ))
        }}
      />
      <Divider />
      <RowWithSelect
        primaryLabel={t('Checkout payment methods')}
        selectProps={{
          value: selectedCheckoutPaymentMethodsViewMode,
          onChange: (event) => {
            dispatch({
              type: DeviceSettingsActionTypes.ChangeCheckoutPaymentMethodsViewMode,
              payload: event.target.value as CheckoutPaymentMethodsViewMode
            })
          },
          children: [
            CheckoutPaymentMethodsViewMode.ExpandedFirstGroup,
            CheckoutPaymentMethodsViewMode.DoNotExpandAnyGroup
          ].map((mode) => (
            <MenuItem key={mode} value={mode}>
              {translateCheckoutPaymentMethodsViewMode(mode)}
            </MenuItem>
          ))
        }}
      />
    </InputBlockWithoutSpacings>
  )
}
