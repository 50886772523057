import React from 'react'
import {useTranslation} from 'react-i18next'
import {GoPayPaymentInstrument} from '../../../../__generated__/schema'
import {
  BasicRadioLabel,
  RadioGroupOption
} from '../../../form/UncontrolledFormRadioGroup'
import {GoPayPaymentInstrumentOrNone} from './types'

export const useTranslateGoPayPaymentInstrument = () => {
  const {t} = useTranslation()
  return (instrument: GoPayPaymentInstrumentOrNone) => {
    switch (instrument) {
      case GoPayPaymentInstrument.PaymentCard:
        return t('Payment card')
      case GoPayPaymentInstrument.BankAccount:
        return t('Quick bank transfer')
      case GoPayPaymentInstrument.Gpay:
        return t('Google pay')
      case GoPayPaymentInstrument.ApplePay:
        return t('Apple pay')
      case GoPayPaymentInstrument.Gopay:
        return t('GoPay wallet')
      case GoPayPaymentInstrument.Paypal:
        return t('PayPal')
      case GoPayPaymentInstrument.Mpayment:
        return t('M-payment')
      case GoPayPaymentInstrument.Prsms:
        return t('Premium SMS')
      case GoPayPaymentInstrument.Paysafecard:
        return t('Paysafe card')
      case GoPayPaymentInstrument.Bitcoin:
        return t('Bitcoin')
      case GoPayPaymentInstrument.ClickToPay:
        return t('Click to pay')
      case 'none':
      default:
        return t('Aggregated view')
    }
  }
}

const useGoPayPaymentInstrumentSecondaryText = () => {
  const {t} = useTranslation()
  return (instrument: GoPayPaymentInstrumentOrNone) => {
    switch (instrument) {
      case GoPayPaymentInstrument.PaymentCard:
        return t(
          'Supported card brands are Visa, Mastercard, Maestro, Visa Electron, MasterCard Electronic. For more info check gopay.com.'
        )
      case GoPayPaymentInstrument.BankAccount:
        return t(
          'List of supported banks is available at gopay.com. Customer of unsupported bank can use another payment instrument.'
        )
      case GoPayPaymentInstrument.Gpay:
        return t('Available for customers with activated service Google pay.')
      case GoPayPaymentInstrument.ApplePay:
        return t(
          'Available for customers with Safari browser and activated service Apple pay.'
        )
      case GoPayPaymentInstrument.Gopay:
        return t(
          'Available for customers with GoPay account. Allows users to make secure payments online and save card details for later.'
        )
      case GoPayPaymentInstrument.Paypal:
        return t(
          'Available for customers with PayPal account. Worldwide used digital wallet.'
        )
      case GoPayPaymentInstrument.Mpayment:
        return t(
          'Easy payments made on mobile via mobile operators. For more info check gopay.com.'
        )
      case GoPayPaymentInstrument.Prsms:
        return t(
          'Get paid by for predefined amounts with text messages (SMS). For more info check gopay.com.'
        )
      case GoPayPaymentInstrument.Paysafecard:
        return t(
          'Online payment with vouchers purchased by cash in various stores. For more info check gopay.com.'
        )
      case GoPayPaymentInstrument.Bitcoin:
        return t(
          'Payment provided by cryptocurrency Bitcoin. Transaction processing speed depends on fee volume. For lowest fees it can take several days to process payment.'
        )
      case GoPayPaymentInstrument.ClickToPay:
        return t(
          'Available for customers with activated service Click to pay and allows customer to make quick payment with payment card issued by Visa, Mastercard and other brands.'
        )
      case 'none':
      default:
        return t(
          'All payment instruments will be aggregated to only one option. Customer will be able to select preferred payment method later in GoPay interface.'
        )
    }
  }
}

export const useGoPayDefaultPaymentInstrumentOptions =
  (): RadioGroupOption<GoPayPaymentInstrumentOrNone>[] => {
    const translateGoPayPaymentInstrument = useTranslateGoPayPaymentInstrument()
    const getSecondaryText = useGoPayPaymentInstrumentSecondaryText()
    return (
      [
        'none',
        GoPayPaymentInstrument.PaymentCard,
        GoPayPaymentInstrument.BankAccount,
        GoPayPaymentInstrument.ApplePay,
        GoPayPaymentInstrument.Gpay,
        GoPayPaymentInstrument.ClickToPay,
        GoPayPaymentInstrument.Paypal,
        GoPayPaymentInstrument.Gopay,
        GoPayPaymentInstrument.Prsms,
        GoPayPaymentInstrument.Paysafecard,
        GoPayPaymentInstrument.Bitcoin,
        GoPayPaymentInstrument.Mpayment
      ] as GoPayPaymentInstrumentOrNone[]
    ).map((value) => ({
      value,
      label: (
        <BasicRadioLabel
          primaryText={translateGoPayPaymentInstrument(value)}
          secondaryText={getSecondaryText(value)}
        />
      )
    }))
  }
