import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, Divider, Paper, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  BaseWarehouseDocumentFieldsFragment,
  PermissionCode,
  WarehouseDocumentState,
  WarehouseDocumentType
} from '../../../../../__generated__/schema'
import {useFormatUserName} from '../../../../../hooks/formatUserName'
import {useTranslateWarehouseDocumentSource} from '../../../../../hooks/translateWarehouseDocumentSource'
import {
  useTranslateWarehouseDocumentState,
  useTranslateWarehouseDocumentStateDescription
} from '../../../../../hooks/translateWarehouseDocumentState'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {warehouseDocumentStateColors} from '../../../../constants'

const getTitle = (type: WarehouseDocumentType, t: TFunction) => {
  switch (type) {
    case WarehouseDocumentType.Incoming:
      return t<string>('Incoming warehouse document')
    case WarehouseDocumentType.Outgoing:
      return t<string>('Outgoing warehouse document')
    default:
      return ''
  }
}

interface IGeneralProps {
  warehouseDocument: BaseWarehouseDocumentFieldsFragment
}

export const General: React.FC<IGeneralProps> = ({
  warehouseDocument
}: IGeneralProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateWarehouseDocumentSource = useTranslateWarehouseDocumentSource()
  const translateWarehouseDocumentState = useTranslateWarehouseDocumentState()
  const translateWarehouseDocumentStateDescription =
    useTranslateWarehouseDocumentStateDescription()
  const formatUserName = useFormatUserName()
  const {formatDateTime, formatDateNumeric} = useDateTimeFormatters()
  const history = useHistory()
  const handleEditButtonClick = useCallback(
    () =>
      history.push(routeTo.admin.warehouseDocuments.edit(warehouseDocument.id)),
    [history, warehouseDocument.id]
  )
  return (
    <Paper variant="outlined">
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6">
            {[getTitle(warehouseDocument.type, t), warehouseDocument.id].join(
              ' • '
            )}
          </Typography>
          {P([
            PermissionCode.UpdateDraftWarehouseDocument,
            PermissionCode.ReadBusinessPartners
          ]) &&
            warehouseDocument.state === WarehouseDocumentState.Draft && (
              <Button
                variant="text"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditButtonClick}
              >
                {t('Edit')}
              </Button>
            )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            alignItems: 'baseline',
            gap: 1,
            gridAutoFlow: 'column',
            gridTemplateColumns: 'auto 1fr'
          }}
        >
          <EntityStateChip
            label={translateWarehouseDocumentState(warehouseDocument.state)}
            colorConf={warehouseDocumentStateColors[warehouseDocument.state]}
          />
          <Typography variant="caption" color="textSecondary">
            {translateWarehouseDocumentStateDescription(
              warehouseDocument.state
            )}
          </Typography>
        </Box>
        {warehouseDocument.note && (
          <SingleInfoColumn
            caption={t('Note')}
            value={warehouseDocument.note}
            valueTypographyVariant="body2"
          />
        )}
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Warehouse'),
              value: warehouseDocument.warehouse.name
            },
            {
              caption: t('Source'),
              value: translateWarehouseDocumentSource(warehouseDocument.source)
            },
            {
              caption: t('Division'),
              value: warehouseDocument.division.name
            },
            {
              caption: t('Created at'),
              value: formatDateTime(new Date(warehouseDocument.createdAt))
            },
            {
              caption: t('Created by'),
              value: formatUserName(warehouseDocument.createdBy)
            },
            ...(warehouseDocument.issuedAt && warehouseDocument.issuedBy
              ? [
                  {
                    caption: t('Issued at'),
                    value: formatDateTime(new Date(warehouseDocument.issuedAt))
                  },
                  {
                    caption: t('Issued by'),
                    value: formatUserName(warehouseDocument.issuedBy)
                  }
                ]
              : [
                  {
                    caption: t('Updated at'),
                    value: formatDateTime(new Date(warehouseDocument.updatedAt))
                  },
                  {
                    caption: t('Updated by'),
                    value: formatUserName(warehouseDocument.updatedBy)
                  }
                ])
          ]}
        />
      </Box>
      <Divider />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}>
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Delivery date'),
              value: formatDateNumeric(new Date(warehouseDocument.deliveryDate))
            },
            {
              caption: t('Related document ID'),
              value: warehouseDocument.thirdPartyDocumentId
            },
            {
              caption: t('Business partner'),
              value: warehouseDocument.businessPartner?.companyName
            }
          ]}
        />
      </Box>
    </Paper>
  )
}
