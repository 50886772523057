import {ApolloError} from 'apollo-client'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {VenuePropertiesFragment} from '../../../../__generated__/schema'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {useNotifications} from '../../../context/notifications'
import {useUpdateVenue} from './graphql'
import {
  IVenueFormData,
  VenueForm,
  venueFormFromVenue,
  venueFromVenueForm
} from './VenueForm'

interface IEditVenueProps {
  venue: VenuePropertiesFragment
}

export const EditVenue: React.FC<IEditVenueProps> = ({
  venue
}: IEditVenueProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const updateVenue = useUpdateVenue()

  const onSubmit = useCallback(
    async (data: IVenueFormData) => {
      await updateVenue({id: venue.id, data: venueFromVenueForm(data)})
      history.goBack()
      addInfoNotification('Venue edited')
    },
    [addInfoNotification, history, updateVenue, venue.id]
  )

  const onError = useCallback(
    (err: ApolloError) => {
      defaultErrorHandler(err, t('Error while editing venue'))
    },
    [defaultErrorHandler, t]
  )

  return (
    <>
      <VenueForm
        title={`${t('Edit venue')} ${venue.name}`}
        onSubmit={onSubmit}
        onError={onError}
        defaultValues={venueFormFromVenue(venue)}
        submitText={t('Save')}
      />
    </>
  )
}
