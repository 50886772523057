import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ProductsStatisticsFilterInput} from '../../../../__generated__/schema'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {ProductsStatisticsPage} from './ProductsStatisticsPage'
import {ProductsStatisticsSearch} from './ProductsStatisticsSearch'

export const ProductsStatistics: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] =
    useState<ProductsStatisticsFilterInput>({})
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Products statistics')}
          search={<ProductsStatisticsSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <ProductsStatisticsPage searchFilter={searchFilter} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
