import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box, IconButton} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventsStatisticsFilterInput,
  EventState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Tooltip} from '../../../common'
import {
  DataGridTable,
  useAgeClassificationAbbreviationFormatter,
  useDataGridPagination,
  useDateTimeFormatter,
  useShowFormatAbbreviationFormatter,
  useSoundMixAbbreviationFormatter,
  useTranslatedValueGetter,
  useVersionCodeAbbreviationFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {useGetFilterDateRange} from '../utils'
import {EventStateChip} from './EventStateChip'
import {useGetEventsStatistics} from './graphql'
import {IconCellRenderer} from './Miscellaneous'
import {MoreMenu} from './MoreMenu'

interface IAvailabilityPageProps {
  searchFilter: EventsStatisticsFilterInput
}

export const AvailabilityPage: React.FC<IAvailabilityPageProps> = ({
  searchFilter
}: IAvailabilityPageProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [eventStateFilter, setEventStateFilter] = useState<EventState>(
    EventState.Published
  )
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.FromToday
    }
  )
  const dataGridApiRef = useGridApiRef()
  const translatedValueGetter = useTranslatedValueGetter()
  const getFilterDateRange = useGetFilterDateRange()
  const dateTimeFormatter = useDateTimeFormatter()
  const showFormatAbbreviationFormatter = useShowFormatAbbreviationFormatter()
  const versionCodeAbbreviationFormatter = useVersionCodeAbbreviationFormatter()
  const soundMixAbbreviationFormatter = useSoundMixAbbreviationFormatter()
  const ageClassificationAbbreviationFormatter =
    useAgeClassificationAbbreviationFormatter()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useGetEventsStatistics({
    filter: {
      ...searchFilter,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'eventStartsAtFrom',
        filterNameTo: 'eventStartsAtTo'
      }),
      eventState: eventStateFilter
    },
    paginationInput
  })
  const handleEventStateChange = useCallback(
    (state: EventState) => {
      setEventStateFilter(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const hasPermissionToAccessDrawer =
    (P([PermissionCode.ReadSales]) || P([PermissionCode.ReadReservations])) &&
    P([PermissionCode.ReadAggregatedEventStatisticsByItemPrice])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Starts at'),
        field: 'startsAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Name'),
        field: 'names',
        valueGetter: translatedValueGetter,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Capacity'),
        field: 'auditoriumLayoutCapacity',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.auditoriumLayout.capacity,
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Available'),
        field: 'availableSeatsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Sold'),
        field: 'soldSeatsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Reserved'),
        field: 'reservedSeatsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('In cart'),
        field: 'inCartSeatsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Disabled'),
        field: 'disabledSeatsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Hidden'),
        field: 'hiddenSeatsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Venue'),
        field: 'venueName',
        valueGetter: (params) => params.row.venue?.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Auditorium'),
        field: 'auditoriumName',
        valueGetter: (params) => params.row.auditorium?.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Division'),
        field: 'divisionName',
        valueGetter: (params) => params.row.division.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Format'),
        field: 'formatCode',
        valueFormatter: showFormatAbbreviationFormatter,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Version'),
        field: 'versionCode',
        valueFormatter: versionCodeAbbreviationFormatter,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Sound mix'),
        field: 'soundMixCode',
        valueFormatter: soundMixAbbreviationFormatter,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Age restrictions'),
        field: 'ageClassificationCode',
        valueFormatter: ageClassificationAbbreviationFormatter,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Cost center'),
        field: 'costCenterName',
        valueGetter: (params) => params.row.costCenter?.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Event category'),
        field: 'eventCategoryName',
        valueGetter: (params) => params.row.eventCategory?.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Marketing label'),
        field: 'marketingLabelName',
        valueGetter: (params) => params.row.marketingLabel?.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.eventId,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              id={params.value}
              isRowPinned={params.rowNode.type === 'pinnedRow'}
            />
          )
        },
        sortable: false,
        disableExport: true,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      ageClassificationAbbreviationFormatter,
      dateTimeFormatter,
      showFormatAbbreviationFormatter,
      soundMixAbbreviationFormatter,
      t,
      translatedValueGetter,
      versionCodeAbbreviationFormatter
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading events statistics')}
      />
    )
  }
  return (
    <Box sx={{height: '100%'}}>
      <SubHeaderToolbar
        title={t('Availability')}
        leftActions={[
          <EventStateChip
            key="event-state-chip"
            selectedOption={eventStateFilter}
            onChange={handleEventStateChange}
          />
        ]}
        rightActions={[
          <DatePickerButton
            key="event-date-picker"
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.FromToday
            }}
            tooltip={t('Event date')}
          />,
          <Tooltip key="refetch-button" title={t('Reload')}>
            <IconButton onClick={handleRefetchButtonClick}>
              <RefreshOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>,
          <MoreMenu
            key="more-menu"
            onEventsListCsvButtonClick={() =>
              dataGridApiRef.current.exportDataAsCsv({
                fileName: 'events_statistics'
              })
            }
          />
        ]}
        rightActionsSx={{py: 0}}
      />
      <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            }
          }}
          getRowId={(row) => row.eventId}
          apiRef={dataGridApiRef}
          columns={columns}
          rows={data?.eventsStatistics.items || []}
          loading={loading}
          disableColumnMenu
          disableRowSelectionOnClick
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          initialState={{
            pinnedColumns: {left: ['startsAt'], right: ['arrow']}
          }}
          columnVisibilityModel={{
            arrow: hasPermissionToAccessDrawer
          }}
          localeText={{noRowsLabel: t('No events statistics to show')}}
          columnGroupingModel={[
            {
              groupId: t('Event'),
              children: [{field: 'names'}, {field: 'auditoriumLayoutCapacity'}]
            },
            {
              groupId: t('Summary'),
              children: [
                {field: 'availableSeatsCount'},
                {field: 'soldSeatsCount'},
                {field: 'reservedSeatsCount'},
                {field: 'inCartSeatsCount'},
                {field: 'disabledSeatsCount'},
                {field: 'hiddenSeatsCount'},
                {field: 'venueName'},
                {field: 'auditoriumName'},
                {field: 'divisionName'}
              ]
            },
            {
              groupId: t('Event details'),
              children: [
                {field: 'formatCode'},
                {field: 'versionCode'},
                {field: 'soundMixCode'},
                {field: 'ageClassificationCode'},
                {field: 'costCenterName'},
                {field: 'eventCategoryName'},
                {field: 'marketingLabelName'}
              ]
            }
          ]}
          {...getDataGridPaginationProps(data?.eventsStatistics.pagination)}
        />
      </Box>
    </Box>
  )
}
