import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  DuplicateAuditoriumLayoutSupportUserMutation,
  MutationDuplicateAuditoriumLayoutSupportUserArgs
} from '../../../../__generated__/schema'

const VENUE_SUPPORT_USER_PROPERTIES_FRAGMENT = gql`
  fragment VenueSupportUserProperties on Venue {
    id
    name
    address {
      town
    }
    auditoriums {
      id
      name
      auditoriumLayouts {
        id
        name
      }
    }
  }
`

const AUDITORIUM_LAYOUT_SUPPORT_USER_PROPERTIES_FRAGMEMT = gql`
  fragment AuditoriumLayoutSupportUserProperties on AuditoriumLayout {
    id
    name
  }
`
export const GET_VENUES_SUPPORT_USER = gql`
  query VenuesSupportUser($clientId: Int) {
    venuesSupportUser(clientId: $clientId) {
      ...VenueSupportUserProperties
    }
  }
  ${VENUE_SUPPORT_USER_PROPERTIES_FRAGMENT}
`

const DUPLICATE_AUDITORIUM_LAYOUT_SUPPORT_USER = gql`
  mutation DuplicateAuditoriumLayoutSupportUser(
    $auditoriumId: Int!
    $id: Int!
    $name: String!
  ) {
    duplicateAuditoriumLayoutSupportUser(
      auditoriumId: $auditoriumId
      id: $id
      name: $name
    ) {
      ...AuditoriumLayoutSupportUserProperties
    }
  }
  ${AUDITORIUM_LAYOUT_SUPPORT_USER_PROPERTIES_FRAGMEMT}
`

export const useDuplicateAuditoriumLayoutSupportUser = () => {
  const [duplicateAuditoriumLayoutSupportUser] = useMutation<
    DuplicateAuditoriumLayoutSupportUserMutation,
    MutationDuplicateAuditoriumLayoutSupportUserArgs
  >(DUPLICATE_AUDITORIUM_LAYOUT_SUPPORT_USER)
  return useCallback(
    (args: MutationDuplicateAuditoriumLayoutSupportUserArgs) =>
      duplicateAuditoriumLayoutSupportUser({variables: args}),
    [duplicateAuditoriumLayoutSupportUser]
  )
}
