import {
  CreateVouchersInput,
  VoucherTransactionIntentReason
} from '../../../../__generated__/schema'
import {convertValueToFloat} from '../../../../utils/conversions'
import {
  IVoucherActivationOptionsForm,
  VoucherActivationOption,
  VoucherActivationOptionsField
} from '../components/types'

export enum VouchersFormField {
  Count = 'count',
  CampaignId = 'campaignId',
  Balance = 'balance'
}

export interface IVouchersForm extends IVoucherActivationOptionsForm {
  [VouchersFormField.Count]: number
  [VouchersFormField.CampaignId]: number
  [VouchersFormField.Balance]?: number
}

export const transformDataToInput = (
  data: IVouchersForm
): CreateVouchersInput => ({
  count: data[VouchersFormField.Count],
  campaignId: data[VouchersFormField.CampaignId],
  balance: data[VouchersFormField.Balance]
    ? convertValueToFloat(data[VouchersFormField.Balance] || 0)
    : undefined,
  activationDate:
    data[VoucherActivationOptionsField.ActivationDatetime]?.toISOString(),
  expirationDate:
    data[VoucherActivationOptionsField.ExpirationDatetime]?.toISOString(),
  isActive:
    data[VoucherActivationOptionsField.Activation] ===
    VoucherActivationOption.Now
})

export enum CreditVoucherFormField {
  Amount = 'amount',
  Reason = 'reason',
  Note = 'note'
}

export interface ICreditVoucherForm {
  [CreditVoucherFormField.Amount]: string
  [CreditVoucherFormField.Reason]: VoucherTransactionIntentReason
  [CreditVoucherFormField.Note]?: string
}
