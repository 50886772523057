import {IIdListItem} from './IdListItem'
import {ListItemID, UArrayKeys} from './types'

export const getListOfIdsFromFilter = <
  T extends object,
  U extends ListItemID = number
>(
  filter: T,
  filterKey: UArrayKeys<T, U>
): U[] | undefined => {
  return filter[filterKey] as unknown as U[] | undefined
}

const unaccent = (value: string) =>
  value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const getIdListItemHasTextFilter =
  <T extends ListItemID = number>(hasText: string) =>
  (item: IIdListItem<T>): boolean =>
    unaccent(item.firstRow).includes(unaccent(hasText)) ||
    Boolean(
      item.secondRow && unaccent(item.secondRow).includes(unaccent(hasText))
    ) ||
    Boolean(
      item.thirdRow && unaccent(item.thirdRow).includes(unaccent(hasText))
    )

export const getFilteredItems = <T extends ListItemID = number>(
  items: IIdListItem<T>[],
  hasText?: string
) => (hasText ? items.filter(getIdListItemHasTextFilter(hasText)) : items)
