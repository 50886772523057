import {
  FontSize,
  FontType,
  ICoords,
  IDimensions,
  ShapeVariant
} from '@attendio/shared-components'

export {SeatShape, ZoneType} from '@attendio/shared-components'

export interface IRectangle {
  x: number
  y: number
  width: number
  height: number
}

export enum SelectionMode {
  CROSSING = 'crossing',
  FENCE = 'fence'
}

export interface ICreateShape {
  coords: ICoords
  dimensions: IDimensions
  rotation: number
}

export interface IShape extends ICreateShape {
  id: string
  shapeVariant: ShapeVariant
}

export interface ICreateText {
  coords: ICoords
  rotation: number
  label: string
  fontSize: FontSize
  fontType: FontType
}

export interface IText extends ICreateText {
  id: string
}
