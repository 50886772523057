import CloseIcon from '@mui/icons-material/Close'
import {IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogTitle: {
    display: 'grid',
    padding: theme.spacing(0.5, 1.5, 0.5, 3),
    width: '100%',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center'
  }
}))

interface IDialogTitleWithCloseButtonProps {
  children: React.ReactNode
  onCloseClick(): void
}

export const DialogTitleWithCloseButton: React.FC<IDialogTitleWithCloseButtonProps> =
  ({children, onCloseClick}: IDialogTitleWithCloseButtonProps) => {
    const classes = useStyles()
    return (
      <div className={classes.dialogTitle}>
        <Typography variant="h6">{children}</Typography>
        <IconButton onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
      </div>
    )
  }
