import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ITourTimeSlotQuantityDescriptionProps {
  capacityDecreaseCount: number
  startingQuantity: number
}

export const TourTimeSlotQuantityDescription: React.FC<ITourTimeSlotQuantityDescriptionProps> =
  ({
    capacityDecreaseCount,
    startingQuantity
  }: ITourTimeSlotQuantityDescriptionProps) => {
    const {t} = useTranslation()
    return capacityDecreaseCount !== 1 ? (
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{display: 'flex', alignItems: 'flex-start'}}
      >
        <PersonOutlinedIcon sx={{width: 18, height: 18}} />
        {capacityDecreaseCount}
      </Typography>
    ) : startingQuantity >= 2 ? (
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{display: 'flex', alignItems: 'flex-start'}}
      >
        <PersonOutlinedIcon sx={{width: 18, height: 18}} />
        {[
          capacityDecreaseCount,
          t('Min: {{min}}', {min: startingQuantity})
        ].join(' • ')}
      </Typography>
    ) : null
  }
