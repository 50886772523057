import {CanvasObjectType, ShapeVariant} from '@attendio/shared-components'
import Decimal from 'decimal.js'
import {
  IIconStateValue,
  ISeatStateValue,
  IShapeStateValue,
  ITextStateValue
} from '../../shared/editor/stateTypes'
import {IZoneStateValue, ObjectsStateValue} from '../redux/objects/types'
import {ObjectsPanelType} from './types'

const areTypesIncludedInControlArray = (
  types: CanvasObjectType[],
  controlArray: CanvasObjectType[]
) => types.every((type) => controlArray.includes(type))

export const getObjectsPanelType = (selectedObjects: ObjectsStateValue[]) => {
  if (selectedObjects.length === 1) {
    if (selectedObjects[0].type === CanvasObjectType.Seat) {
      return ObjectsPanelType.SingleSeat
    }
    if (selectedObjects[0].type === CanvasObjectType.Zone) {
      return ObjectsPanelType.SingleZone
    }
    if (selectedObjects[0].type === CanvasObjectType.Shape) {
      if (selectedObjects[0].config.shapeVariant === ShapeVariant.Rectangle) {
        return ObjectsPanelType.SingleRectangle
      }
      if (selectedObjects[0].config.shapeVariant === ShapeVariant.Ellipse) {
        return ObjectsPanelType.SingleEllipse
      }
      if (selectedObjects[0].config.shapeVariant === ShapeVariant.Line) {
        return ObjectsPanelType.SingleLine
      }
    }
    if (selectedObjects[0].type === CanvasObjectType.Icon) {
      return ObjectsPanelType.SingleIcon
    }
    if (selectedObjects[0].type === CanvasObjectType.Text) {
      return ObjectsPanelType.SingleText
    }
  } else if (selectedObjects.length > 1) {
    const types = selectedObjects.map((so) => so.type)
    if (types.every((type) => type === CanvasObjectType.Seat)) {
      return ObjectsPanelType.MultipleSeats
    }
    if (types.every((type) => type === CanvasObjectType.Zone)) {
      return ObjectsPanelType.MultipleZones
    }
    if (types.every((type) => type === CanvasObjectType.Shape)) {
      const shapeVariants = (selectedObjects as IShapeStateValue[]).map(
        (so) => so.config.shapeVariant
      )
      if (
        shapeVariants.every(
          (shapeVariant) => shapeVariant === ShapeVariant.Rectangle
        )
      ) {
        return ObjectsPanelType.MultipleRectangles
      }
      if (
        shapeVariants.every(
          (shapeVariant) => shapeVariant === ShapeVariant.Ellipse
        )
      ) {
        return ObjectsPanelType.MultipleEllipses
      }
      if (
        shapeVariants.every(
          (shapeVariant) => shapeVariant === ShapeVariant.Line
        )
      ) {
        return ObjectsPanelType.MultipleLines
      }
      return ObjectsPanelType.MultipleShapes
    }
    if (types.every((type) => type === CanvasObjectType.Icon)) {
      return ObjectsPanelType.MultipleIcons
    }
    if (types.every((type) => type === CanvasObjectType.Text)) {
      return ObjectsPanelType.MultipleTexts
    }
    if (
      areTypesIncludedInControlArray(types, [
        CanvasObjectType.Seat,
        CanvasObjectType.Zone
      ])
    ) {
      return ObjectsPanelType.MixWithAttendeeNavigation
    }
    if (
      areTypesIncludedInControlArray(types, [
        CanvasObjectType.Text,
        CanvasObjectType.Zone
      ])
    ) {
      return ObjectsPanelType.MixZonesAndTexts
    }
    if (
      areTypesIncludedInControlArray(types, [
        CanvasObjectType.Seat,
        CanvasObjectType.Text,
        CanvasObjectType.Zone
      ])
    ) {
      return ObjectsPanelType.MixWithLabel
    }
    if (
      areTypesIncludedInControlArray(types, [
        CanvasObjectType.Icon,
        CanvasObjectType.Shape,
        CanvasObjectType.Zone
      ])
    ) {
      return ObjectsPanelType.MixWithDimensions
    }
  }
  return ObjectsPanelType.MinimalMix
}

export const isSeatStateObject = (
  object: ObjectsStateValue
): object is ISeatStateValue => object.type === CanvasObjectType.Seat

export const isZoneStateValue = (
  object: ObjectsStateValue
): object is IZoneStateValue => object.type === CanvasObjectType.Zone

export const isIconStateValue = (
  object: ObjectsStateValue
): object is IIconStateValue => object.type === CanvasObjectType.Icon

export const isTextStateValue = (
  object: ObjectsStateValue
): object is ITextStateValue => object.type === CanvasObjectType.Text

export const isSeatOrZoneStateObject = (
  object: ObjectsStateValue
): object is ISeatStateValue | IZoneStateValue =>
  [CanvasObjectType.Seat, CanvasObjectType.Zone].includes(object.type)

export const isSeatZoneOrTextStateObject = (
  object: ObjectsStateValue
): object is ISeatStateValue | IZoneStateValue | ITextStateValue =>
  [
    CanvasObjectType.Seat,
    CanvasObjectType.Zone,
    CanvasObjectType.Text
  ].includes(object.type)

export const isZoneIconOrShapeStateObject = (
  object: ObjectsStateValue
): object is IZoneStateValue | IIconStateValue | IShapeStateValue =>
  [
    CanvasObjectType.Icon,
    CanvasObjectType.Zone,
    CanvasObjectType.Shape
  ].includes(object.type)

export const roundValueIfNumeric = (value: any): number | undefined =>
  isNaN(value) ? undefined : new Decimal(value).toDecimalPlaces(2).toNumber()
