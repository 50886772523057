import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Divider} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {
  AuditoriumLayoutQuery,
  AuditoriumLayoutState,
  AuditoriumLayoutType,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {DisplayMode} from '../../../../../../editor/redux/displayMode/reducer'
import {IUndoableState} from '../../../../../../editor/redux/undoable/reducer'
import {
  resetGenerator,
  restoreGenerator
} from '../../../../../../editor/utils/compressedIdGenerator'
import {IDimensions} from '../../../../../../hooks/dimensions'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {useVenuesPathnameParams} from '../../../../../../utils/pathname'
import {routeTo} from '../../../../../../utils/routes'
import {
  BottomActionsBar,
  BottomActionsBarButtonsGroup,
  BottomActionsBarWrapper,
  RenderOnData
} from '../../../../../common'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {PageWithHeaderTemplate} from '../../../../../common/PageWithHeaderTemplate'
import {SplitButton} from '../../../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../../Header'
import {
  AuditoriumEditorLayout,
  EditorSideBar,
  FullWidthEditor
} from '../../editorUtils'
import {FloorPlanStatus, SelectedEditorItemsStatus} from '../../FloorPlanStatus'
import {
  StateRenderer,
  useAuditoriumLayoutStateTextTranslations
} from '../common'
import {AUDITORIUM_LAYOUT_FORM_ID, EditorForm} from '../EditorForm'
import {useDeleteAuditoriumLayout, useGetAuditoriumLayout} from '../graphql'
import {useSubmitActions} from './submitActions'
import {getUpdateOptions, useInitForm, useSetEditorState} from './utils'

interface IEditAuditoriumLayoutContentProps extends IDimensions {
  data: AuditoriumLayoutQuery
  isSaveButtonDisabled?: boolean
}

const EditAuditoriumLayoutContent: React.FC<IEditAuditoriumLayoutContentProps> =
  ({
    width,
    height,
    data,
    isSaveButtonDisabled
  }: IEditAuditoriumLayoutContentProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {onSubmit, onArchive, onActivate} = useSubmitActions()
    const {venueId, auditoriumId} = useVenuesPathnameParams()
    const formProps = useInitForm(data)
    const history = useHistory()
    const {status} = data.auditoriumLayout
    const auditoriumLayoutStateText = useAuditoriumLayoutStateTextTranslations()
    const deleteAuditoriumLayout = useDeleteAuditoriumLayout(auditoriumId)
    const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
      useMutationAssistanceHooks()
    const {
      state: isDeleteDialogOpen,
      setTrue: openDeleteDialog,
      setFalse: closeDeleteDialog
    } = useBooleanState(false)

    let displayMode = DisplayMode.FULL
    if (data.auditoriumLayout.type === AuditoriumLayoutType.ZoneFloor) {
      displayMode = DisplayMode.FULL_WITHOUT_SEATS
    }
    if (
      status === AuditoriumLayoutState.Active ||
      status === AuditoriumLayoutState.Archived
    ) {
      displayMode = DisplayMode.RESTRICTED
    }
    const updateOptions = getUpdateOptions({
      handleSubmit: formProps.handleSubmit,
      onArchive,
      onActivate,
      status: data.auditoriumLayout.status,
      P
    })

    const handleDeleteConfirmation = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await deleteAuditoriumLayout({id: data.auditoriumLayout.id})
        history.push(
          routeTo.admin.venues.detailAuditorium(venueId, auditoriumId)
        )
        addInfoNotification(t('Auditorium layout has been deleted'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while deleting auditorium layout'))
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      auditoriumId,
      data.auditoriumLayout.id,
      defaultErrorHandler,
      deleteAuditoriumLayout,
      history,
      setShowBackdrop,
      t,
      venueId
    ])

    useEffect(() => {
      const keys = Object.keys(data.auditoriumLayout.layout).filter(
        (key) => key.length < 10
      )
      if (keys.length) {
        restoreGenerator(keys)
      } else {
        resetGenerator()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <BottomActionsBarWrapper>
          <FullWidthEditor {...{displayMode, width, height}}>
            <>
              <EditorSideBar>
                <Box display="inline-flex">
                  <StateRenderer status={data.auditoriumLayout.status} />
                </Box>
                <Box display="inline-flex" marginLeft="10px" color="gray">
                  {auditoriumLayoutStateText[data.auditoriumLayout.status]}
                </Box>
                <FormContext {...formProps}>
                  <EditorForm onSubmit={onSubmit} />
                </FormContext>
              </EditorSideBar>
              <Divider />
              <EditorSideBar>
                <FloorPlanStatus />
              </EditorSideBar>
            </>
          </FullWidthEditor>
          <BottomActionsBar justify="space-between">
            <SelectedEditorItemsStatus />
            <BottomActionsBarButtonsGroup>
              {P([PermissionCode.DeleteAuditoriumLayout]) &&
                status === AuditoriumLayoutState.Draft && (
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={openDeleteDialog}
                  >
                    {t('Delete')}
                  </Button>
                )}
              {updateOptions.length > 0 ? (
                <SplitButton Options={updateOptions}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    form={AUDITORIUM_LAYOUT_FORM_ID}
                    cypress-id="auditorium-layout-submit-button"
                    disabled={isSaveButtonDisabled}
                  >
                    {t('Save')}
                  </Button>
                </SplitButton>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  form={AUDITORIUM_LAYOUT_FORM_ID}
                  cypress-id="auditorium-layout-submit-button"
                  disabled={isSaveButtonDisabled}
                >
                  {t('Save')}
                </Button>
              )}
            </BottomActionsBarButtonsGroup>
          </BottomActionsBar>
        </BottomActionsBarWrapper>
        <ConfirmationDialog
          title={t('Delete auditorium layout?')}
          contentText={t(
            "Are you sure you want to delete auditorium layout? This can't be undone."
          )}
          confirmButtonLabel={t('Delete')}
          onConfirm={handleDeleteConfirmation}
          onCancel={closeDeleteDialog}
          isOpen={isDeleteDialogOpen}
        />
      </>
    )
  }

interface IStateProps {
  undoable: IUndoableState
}

interface IEditAuditoriumLayoutWrapperProps extends IDimensions {
  setHasHistory?: React.Dispatch<boolean>
}

const EditAuditoriumLayoutWrapper: React.FC<IEditAuditoriumLayoutWrapperProps> =
  ({width, height, setHasHistory}: IEditAuditoriumLayoutWrapperProps) => {
    const {t} = useTranslation()
    const {auditoriumLayoutId} = useVenuesPathnameParams()
    const {data, loading, error} = useGetAuditoriumLayout(auditoriumLayoutId)

    useSetEditorState(data)

    const hasHistory = useSelector(
      (state: IStateProps) => !!state.undoable.past.length
    )

    useEffect(() => {
      if (setHasHistory) {
        setHasHistory(hasHistory)
      }
    }, [hasHistory, setHasHistory])

    return (
      <RenderOnData
        {...{loading, error, data}}
        errorMessage={t<string>('Could not load auditorium layout.')}
      >
        {(data: AuditoriumLayoutQuery) => {
          return <EditAuditoriumLayoutContent {...{width, height, data}} />
        }}
      </RenderOnData>
    )
  }

export const EditAuditoriumLayout = () => {
  const {t} = useTranslation()
  const [hasHistory, setHasHistory] = useState<boolean>(false)
  const history = useHistory()
  const {
    state: isDialogOpen,
    setTrue: openDialog,
    setFalse: closeDialog
  } = useBooleanState(false)
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={t('Auditorium layout editor')}
          hasArrowBackIcon
          onLeftActionClick={hasHistory ? openDialog : () => history.goBack()}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage venues.')}
      >
        <AuditoriumEditorLayout>
          {(dimensions) => (
            <EditAuditoriumLayoutWrapper
              {...dimensions}
              setHasHistory={setHasHistory}
            />
          )}
        </AuditoriumEditorLayout>
        <ConfirmationDialog
          title={t('Unsaved changes')}
          contentText={t(
            'There are unsaved changes. If you would like to save changes, you can keep editing. Discarding changes is permanent action and can’t be undone.'
          )}
          confirmButtonLabel={t('Keep editing')}
          onConfirm={closeDialog}
          cancelButtonLabel={t('Discard changes')}
          onCancel={() => history.goBack()}
          isOpen={isDialogOpen}
        />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
