import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SettingsIcon from '@mui/icons-material/Settings'
import {useMediaQuery} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CheckTicketsTourTimeSlotsQuery,
  TourTimeSlotState
} from '../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {HeaderActionType} from '../../../common/header/types'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {ListItemWrapper} from '../cashDesk/ListItemWrapper'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {EnabledDivisionsTabs} from '../components/EnabledDivisionsTabs'
import {PrimaryHeader} from '../Header'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../Layout'
import {groupEntitiesByStartsAtDate} from '../utils'
import {useGetCheckTicketsTourTimeSlots} from './graphql'
import {ResponsiveGroupedEntitiesItem} from './ResponsiveGroupedEntitiesItem'
import {useEnabledDivisionsTabs} from './utils'

export const TourTimeSlots: React.FC = () => {
  const {t} = useTranslation()
  const {
    areDivisionTabsShown,
    enabledDivisions,
    selectedDivisionId,
    handleTabChange,
    divisionsLoading,
    divisionsError
  } = useEnabledDivisionsTabs()
  const {data, error, loading, fetchMore, isLoadingMore} =
    useGetCheckTicketsTourTimeSlots({
      filter: {
        state: TourTimeSlotState.Published,
        divisionIds: selectedDivisionId ? [selectedDivisionId] : undefined,
        startsAtFrom: dayjs().startOf('d').toISOString()
      },
      paginationInput: {limit: 50, offset: 0}
    })
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const history = useHistory()
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.tourTimeSlots.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.tourTimeSlots.pagination.hasMore, fetchMore, isLoadingMore])
  const handleOnSettingsIconClick = useCallback(() => {
    history.push(routeTo.admin.checkTickets.advancedSettings())
  }, [history])
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Check tour time slots tickets')}
          rightActions={[
            {
              Icon: SettingsIcon,
              onClick: handleOnSettingsIconClick,
              label: t('Settings'),
              type: HeaderActionType.IconButton,
              cypressId: 'check-tickets-settings-icon'
            }
          ]}
          isDatetimeEnabled
        />
      }
    >
      <FullScreenCenteredLayout
        onScrolledNearTheEndOfTheLayout={
          data?.tourTimeSlots.pagination.hasMore
            ? handleScrolledNearTheEndOfTheLayout
            : undefined
        }
        subTopbar={
          areDivisionTabsShown && (
            <EnabledDivisionsTabs
              enabledDivisions={enabledDivisions}
              selectedDivisionId={selectedDivisionId}
              onTabChange={handleTabChange}
              loading={divisionsLoading}
              error={divisionsError}
            />
          )
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData<CheckTicketsTourTimeSlotsQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading tour time slots')}
            dataCondition={(data) => Array.isArray(data.tourTimeSlots.items)}
          >
            {({tourTimeSlots}) =>
              tourTimeSlots.items.length > 0 ? (
                <CenteredLayoutListWrapper>
                  {groupEntitiesByStartsAtDate(
                    tourTimeSlots.items,
                    getUserLocaleTranslation
                  ).map((grouppedTourTimeSlots) => (
                    <ListItemWrapper key={grouppedTourTimeSlots.date}>
                      <ResponsiveGroupedEntitiesItem
                        item={grouppedTourTimeSlots}
                        isPhablet={isPhablet}
                      />
                    </ListItemWrapper>
                  ))}
                </CenteredLayoutListWrapper>
              ) : (
                <Blank
                  title={t('No tour time slots found')}
                  IconComp={CalendarTodayIcon}
                />
              )
            }
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </FullScreenCenteredLayout>
    </PageWithHeaderTemplate>
  )
}
