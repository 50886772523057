import {useLazyQuery} from '@apollo/react-hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import DomainIcon from '@mui/icons-material/Domain'
import {
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightDivisionsQuery,
  GetLightweightDivisionsQueryVariables
} from '../../../../__generated__/schema'
import {Tooltip} from '../../../common'
import {Menu, useMenu} from '../../../common/Menu'
import {GET_DIVISIONS} from '../graphql'

const LoadingSkeleton = React.forwardRef<HTMLDivElement>(
  function LoadingSkeletonWithRef(props, ref) {
    return (
      <div ref={ref}>
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
      </div>
    )
  }
)

interface IDivision {
  id: number
  name: string
}

interface IDivisionChipProps {
  onChange: (divisionId: number | null) => void
}

export const DivisionChip: React.FC<IDivisionChipProps> = ({
  onChange
}: IDivisionChipProps) => {
  const {t} = useTranslation()
  const [divisions, setDivisions] = useState<IDivision[] | null>(null)
  const [selectedDivision, setSelectedDivision] =
    useState<IDivision | null>(null)
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const [getDivisions, {loading}] = useLazyQuery<
    GetLightweightDivisionsQuery,
    GetLightweightDivisionsQueryVariables
  >(GET_DIVISIONS, {
    onCompleted: (data) =>
      setDivisions(
        data.divisions
          .sort((divisionA, divisionB) =>
            divisionA.name.localeCompare(divisionB.name)
          )
          .map(({id, name}) => ({id, name}))
      ),
    fetchPolicy: 'network-only'
  })
  const handleChipClick = useCallback(
    (e) => {
      getDivisions()
      openMenu(e)
    },
    [getDivisions, openMenu]
  )
  const handleMenuItemClick = useCallback(
    (division: IDivision) => () => {
      setSelectedDivision(division)
      onChange(division.id)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  const handleDeleteChipClick = useCallback(() => {
    setSelectedDivision(null)
    onChange(null)
  }, [onChange])
  return (
    <>
      <Tooltip title={t('Select division')}>
        <Chip
          size="small"
          variant="outlined"
          icon={<DomainIcon />}
          deleteIcon={selectedDivision ? <CancelIcon /> : <ArrowDropDownIcon />}
          label={selectedDivision?.name}
          onClick={handleChipClick}
          onDelete={selectedDivision ? handleDeleteChipClick : handleChipClick}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          divisions?.map((division) => (
            <MenuItem
              key={division.id}
              selected={division.id === selectedDivision?.id}
              onClick={handleMenuItemClick(division)}
              sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
            >
              {division.id === selectedDivision?.id && (
                <ListItemIcon
                  color="primary"
                  sx={{minWidth: 48, justifyContent: 'center'}}
                >
                  <CheckIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={division.name} />
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  )
}
