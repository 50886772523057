import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {AccessZoneForm} from './AccessZoneForm'
import {useCreateAccessZone} from './graphql'
import {IAccessZoneForm} from './types'
import {transformFormDataToAccessZoneInput} from './utils'

const CREATE_ACCESS_ZONE_FORM_ID = 'createAccessZoneForm'

interface ICreateAccessZoneDrawerProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const CreateAccessZoneDrawer: React.FC<ICreateAccessZoneDrawerProps> = ({
  isOpen,
  onClose,
  refetch
}: ICreateAccessZoneDrawerProps) => {
  const {t} = useTranslation()
  const createAccessZone = useCreateAccessZone()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (formData: IAccessZoneForm) => {
      try {
        setShowBackdrop(true)
        await createAccessZone({
          input: transformFormDataToAccessZoneInput(formData)
        })
        addInfoNotification(t('Access zone has been created'))
        refetch()
        onClose()
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating access zone'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      refetch,
      addInfoNotification,
      createAccessZone,
      defaultErrorHandler,
      onClose,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create access zone')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_ACCESS_ZONE_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <AccessZoneForm
          formId={CREATE_ACCESS_ZONE_FORM_ID}
          onSubmit={handleSubmit}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
