import InfoIcon from '@mui/icons-material/InfoOutlined'
import {IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery
} from '../../../../../__generated__/schema'
import {ReactComponent as EntranceIcon} from '../../../../../assets/entrance.svg'
import {useFormatShortGuideName} from '../../../../../hooks/formatUserName'
import {useGetDistributionLabelAbbreviation} from '../../../../../hooks/getDistributionLabel'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {useTranslateShowVersionAbbreviation} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useCheckPassCodeParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {
  isGetCheckEventQuery,
  isGetCheckTourQuery,
  isGetCheckTourTimeSlotQuery,
  isGetCheckVenueQuery
} from '../utils'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "label        infoButton"
      "auditorium   infoButton"
      "distribution checkInfo"
    `,
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0.5, 1)
  },
  label: {
    gridArea: 'label',
    paddingTop: theme.spacing(1)
  },
  auditorium: {
    gridArea: 'auditorium'
  },
  checkInfo: {
    gridArea: 'checkInfo',
    alignItems: 'center',
    display: 'flex',
    lineHeight: '100%'
  },
  infoButton: {
    gridArea: 'infoButton',
    justifySelf: 'flex-end'
  }
}))

interface IHeaderProps {
  data:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
  className?: string
}

export const Header: React.FC<IHeaderProps> = ({
  data,
  className
}: IHeaderProps) => {
  const classes = useStyles()
  const {checkType} = useCheckPassCodeParams()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const formatShortGuideName = useFormatShortGuideName(true)
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const getDistributionLabelAbbreviation = useGetDistributionLabelAbbreviation()
  const history = useHistory()
  const handleInfoIconClick = useCallback(() => {
    if (isGetCheckEventQuery(data)) {
      history.replace(
        routeTo.admin.checkTickets.eventDetailFromCheckPage(
          checkType,
          data.event.id
        )
      )
    }
    return undefined
  }, [checkType, data, history])
  return (
    <div className={cn(classes.root, className)}>
      <Typography variant="subtitle2" className={classes.label}>
        {isGetCheckEventQuery(data) &&
          [
            formatDateNumeric(new Date(data.event.startsAt)),
            formatTime(new Date(data.event.startsAt)),
            getUserLocaleTranslation(data.event.names)
          ].join(' • ')}
        {isGetCheckTourTimeSlotQuery(data) &&
          [
            formatDateNumeric(new Date(data.tourTimeSlot.startsAt)),
            formatTime(new Date(data.tourTimeSlot.startsAt)),
            getUserLocaleTranslation(data.tourTimeSlot.names)
          ].join(' • ')}
        {isGetCheckTourQuery(data) && data.tour.name}
        {isGetCheckVenueQuery(data) && data.venue.name}
      </Typography>
      <IconButton
        edge="end"
        className={classes.infoButton}
        onClick={handleInfoIconClick}
      >
        <InfoIcon />
      </IconButton>
      <Typography
        variant="overline"
        color="textSecondary"
        className={classes.auditorium}
      >
        {isGetCheckEventQuery(data) && data.event.auditorium.name}
        {isGetCheckTourTimeSlotQuery(data) && data.tourTimeSlot.venue.name}
        {isGetCheckTourQuery(data) && data.tour.division.name}
        {isGetCheckVenueQuery(data) &&
          [data.venue.address.street, data.venue.address.town]
            .filter(Boolean)
            .join(', ')}
      </Typography>
      <Typography variant="overline" color="textSecondary">
        {isGetCheckEventQuery(data) &&
          getDistributionLabelAbbreviation(data.event)}
        {isGetCheckTourTimeSlotQuery(data) &&
          [
            data.tourTimeSlot.guide &&
              formatShortGuideName(data.tourTimeSlot.guide),
            data.tourTimeSlot.versionCode &&
              translateShowVersionAbbreviation(data.tourTimeSlot.versionCode),
            data.tourTimeSlot.ageClassificationCode &&
              translateAgeClassificationAbbreviation(
                data.tourTimeSlot.ageClassificationCode
              )
          ]
            .filter(Boolean)
            .join(' • ')}
      </Typography>
      {(isGetCheckEventQuery(data) || isGetCheckTourTimeSlotQuery(data)) && (
        <Typography variant="overline" className={classes.checkInfo}>
          <EntranceIcon width="1.5em" />
          {isGetCheckEventQuery(data) &&
            `${data.event.uniqueCheckedInCount}/${data.event.soldSeatsCount}`}
          {isGetCheckTourTimeSlotQuery(data) &&
            `${
              data.tourTimeSlot.attendeesCheckedInCount -
              data.tourTimeSlot.attendeesCheckedOutCount
            }/${data.tourTimeSlot.tourItemsStateCounts.sold}`}
        </Typography>
      )}
    </div>
  )
}
