import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {VenuePropertiesFragment} from '../../../../__generated__/schema'
import {FormInput} from '../../../visual'
import {VenuesDrawerActionsBar, VenuesDrawerForm} from './common'

const VENUE_FORM_ID = 'venue form'

export interface IVenueFormData {
  name: string
  secondaryName?: string | null | undefined
  complex: string
  street: string
  town: string
  postalCode: string
  country: string
}

interface IVenueFormProps {
  title: string
  onSubmit: (data: IVenueFormData) => Promise<void>
  onError: (err: ApolloError) => any
  defaultValues?: IVenueFormData
  submitText: string
}

export const venueFromVenueForm = (data: IVenueFormData) => {
  return {
    name: data.name,
    secondaryName: data.secondaryName,
    address: {
      complex: data.complex,
      street: data.street,
      town: data.town,
      postalCode: data.postalCode,
      country: data.country
    }
  }
}

export const venueFormFromVenue = (data: VenuePropertiesFragment) => {
  return {
    name: data.name,
    secondaryName: data.secondaryName,
    ...data.address
  }
}

const useStyles = makeStyles({
  input: {
    marginBottom: 12
  }
})

export const VenueForm: React.FC<IVenueFormProps> = ({
  title,
  onSubmit,
  onError,
  defaultValues,
  submitText
}: IVenueFormProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const config = defaultValues ? {defaultValues} : {}
  const {register, handleSubmit} = useForm<IVenueFormData>(config)

  const _onSubmit = useCallback(
    async (data: IVenueFormData) => {
      try {
        await onSubmit(data)
      } catch (err) {
        onError(err)
      }
    },
    [onError, onSubmit]
  )

  return (
    <VenuesDrawerForm
      title={title}
      onClose={() => history.goBack()}
      ActionBar={
        <VenuesDrawerActionsBar
          formId={VENUE_FORM_ID}
          submitText={submitText}
          cypressId="venue-form-create-button"
        />
      }
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(_onSubmit)}
        id={VENUE_FORM_ID}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item className={classes.input}>
            <FormInput
              label={t('Venue name')}
              placeholder={t('Venue name')}
              name="name"
              type="text"
              showRequiredLabel
              inputRef={register({required: true})}
            />
          </Grid>
          <Grid item className={classes.input}>
            <FormInput
              label={t('Secondary name')}
              placeholder={t('Secondary name')}
              name="secondaryName"
              type="text"
              inputRef={register({required: false})}
            />
          </Grid>
          <Grid item>
            <Typography align="left">{t('Address information')}</Typography>
          </Grid>
          <Grid item>
            <FormInput
              label={t('Complex')}
              placeholder={t('Complex')}
              name="complex"
              type="text"
              inputRef={register({required: false})}
            />
          </Grid>
          <Grid item>
            <FormInput
              label={t('Street')}
              placeholder={t('Street')}
              name="street"
              type="text"
              inputRef={register({required: false})}
            />
          </Grid>
          <Grid item>
            <FormInput
              label={t('Town')}
              placeholder={t('Town')}
              name="town"
              type="text"
              inputRef={register({required: false})}
            />
          </Grid>
          <Grid item>
            <FormInput
              label={t('Postal code')}
              placeholder={t('Postal code')}
              name="postalCode"
              type="text"
              inputRef={register({required: false})}
            />
          </Grid>
          {/* TODO: this should be Select */}
          <Grid item>
            <FormInput
              label={t('Country')}
              placeholder={t('Country')}
              name="country"
              type="text"
              inputRef={register({required: false})}
            />
          </Grid>
        </Grid>
      </form>
    </VenuesDrawerForm>
  )
}
