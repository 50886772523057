import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {IconButton} from '@mui/material'
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientPropertiesFragment,
  ClientState,
  PermissionCode
} from '../../../../__generated__/schema'
import {EnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityState, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {clientStateColors, useClientStateTranslations} from '../../../constants'
import {PrimaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {ClientsFilter, ClientsSearch} from './ClientsSearch'
import {useGetClients} from './graphql'

interface IClientsTableProps {
  clients: Array<ClientPropertiesFragment>
  searchFilter: ClientsFilter
}

interface IStateRendererProps {
  state: ClientState
}

const StateRenderer: React.FC<IStateRendererProps> = ({
  state
}: IStateRendererProps) => {
  const clientStateTranslations = useClientStateTranslations()
  return (
    <EntityState
      label={clientStateTranslations[state]}
      color={clientStateColors[state].color}
    />
  )
}

interface IIconRendererProps {
  id: number
}

const IconRenderer: React.FC<IIconRendererProps> = ({
  id
}: IIconRendererProps) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.clients.edit(id)),
    [history, id]
  )
  return (
    <IconButton size="small" onClick={handleIconClick}>
      <KeyboardArrowRightIcon />
    </IconButton>
  )
}

const ClientsTable: React.FC<IClientsTableProps> = ({
  clients,
  searchFilter
}: IClientsTableProps) => {
  const {t} = useTranslation()
  const dateTimeFormatter = useDateTimeFormatter()
  const [dataGridFilter, setDataGridFilter] = useState<GridFilterModel>({
    items: []
  })
  useEffect(() => {
    setDataGridFilter((filter) => ({
      ...filter,
      items: [{field: 'name', operator: 'contains', value: searchFilter.name}]
    }))
  }, [searchFilter.name])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('ID'),
        field: 'id',
        flex: 0.5,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Client name'),
        field: 'name',
        flex: 1
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ClientState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        flex: 0.5
      },
      {
        headerName: t('Town'),
        field: 'legalAddress',
        valueGetter: (params) => params.row.legalAddress?.town,
        flex: 1
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        flex: 1
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        flex: 1
      },
      {
        headerName: '',
        field: 'icon',
        valueGetter: ({id}) => id,
        renderCell: function renderer(
          params: GridRenderCellParams<{value: number}>
        ) {
          return <IconRenderer id={params.value} />
        },
        sortable: false,
        disableColumnMenu: true,
        align: 'right',
        headerAlign: 'right',
        flex: 0.5
      }
    ],
    [dateTimeFormatter, t]
  )
  return (
    <DataGridTable
      columns={columns}
      rows={clients}
      initialState={{
        pagination: {paginationModel: {pageSize: 30}},
        pinnedColumns: {right: ['icon']}
      }}
      pageSizeOptions={[10, 30, 50]}
      localeText={{noRowsLabel: t('No clients to show')}}
      autoHeight
      filterModel={{items: dataGridFilter.items}}
      onFilterModelChange={(model) => setDataGridFilter(model)}
    />
  )
}

export const ClientList: React.FC = () => {
  const {error, loading, data} = useGetClients()
  const {t} = useTranslation()
  const fabClasses = useFabClasses()
  const history = useHistory()
  const [searchFilter, setSearchFilter] = useState<ClientsFilter>({})
  const handleAddClick = useCallback(
    () => history.push(routeTo.admin.clients.add()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Clients')}
          search={<ClientsSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <CenteredLayout>
        <CenteredLayoutListWrapper>
          <RenderOnData
            data={data}
            error={error}
            loading={loading}
            errorMessage={t<string>('Could not load clients')}
          >
            {(data) => (
              <>
                <ClientsTable
                  clients={data.clients}
                  searchFilter={searchFilter}
                />
                <EnsurePermissions permissions={[PermissionCode.ReadClients]}>
                  <CreateFab classes={fabClasses} onClick={handleAddClick} />
                </EnsurePermissions>
              </>
            )}
          </RenderOnData>
        </CenteredLayoutListWrapper>
      </CenteredLayout>
    </PageWithHeaderTemplate>
  )
}
