import React from 'react'
import {useTranslation} from 'react-i18next'
import {ProductsFilterInput} from '../../../../../__generated__/schema'
import {
  Search,
  useCombineStringifySearchObjectFunctions
} from '../../../../common'

export const DEFAULT_SHOP_FILTER_INPUT: ProductsFilterInput = {
  hasText: ''
}

const mapHasTextToFilter = (
  filter: ProductsFilterInput,
  hasText: string | undefined
): ProductsFilterInput => ({
  ...filter,
  hasText
})

const getHasTextFromSearchObject = (filter: ProductsFilterInput) =>
  filter.hasText || undefined

interface IShopSearchProps {
  onFilterChange: (filter: ProductsFilterInput) => void
  className?: string
}

export const ShopSearch: React.FC<IShopSearchProps> = ({
  onFilterChange,
  className
}: IShopSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<ProductsFilterInput>(
      getHasTextFromSearchObject
    )
  return (
    <div className={className}>
      <Search
        storageKey="SHOP"
        placeholder={t('Search for products')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapHasTextToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={DEFAULT_SHOP_FILTER_INPUT}
      />
    </div>
  )
}
