import _ from 'lodash'
import {useEffect, useState} from 'react'
import {useForm, ValidationOptions} from 'react-hook-form'

export type RegisterFunction = (
  validationOptions: ValidationOptions
) => (ref: Element | null) => void

type DefaultValues = {[key: string]: any} | undefined

export type SetValue<T = any> = (
  name: string,
  value: T,
  shouldValidate?: boolean
) => void

const useResetOnDataChange = (
  setValue: SetValue,
  defaultValues: DefaultValues
) => {
  const [prevValues, setPrevValues] = useState(defaultValues)

  useEffect(() => {
    if (defaultValues && !_.isEqual(prevValues, defaultValues)) {
      // Note: `reset` causes strange behavior for Controller or useFieldsArray
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key, value)
      })

      setPrevValues(defaultValues)
    }
  }, [defaultValues]) // eslint-disable-line
}

export const useFormWithReset = <I>(
  config: {defaultValues: I} | {defaultValues?: undefined}
) => {
  const {setValue, ...rest} = useForm<I>(config)
  useResetOnDataChange(setValue, config.defaultValues)
  return {setValue, ...rest}
}

export const enumToFormSelectItems = (enumValue: {[key: string]: string}) => {
  return Object.values(enumValue).reduce(
    (res, item) => ({...res, [item]: item}),
    {}
  )
}

export const nestedFormNameToArray = (name: string) => {
  return name
    .replace(/\[/g, ',')
    .replace(/\]/g, ',')
    .split(',')
    .filter((v) => !!v)
}
