import EditIcon from '@mui/icons-material/Edit'
import GetAppIcon from '@mui/icons-material/GetApp'
import {Box, IconButton} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  BusinessPartnersFilterInput,
  BusinessPartnerState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateBusinessPartnerState} from '../../../../hooks/translateBusinessPartnerState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {CreateBusinessPartnerDrawer} from '../../../common/businessPartnerDrawer/CreateBusinessPartnerDrawer'
import {IBusinessPartnerForm} from '../../../common/businessPartnerDrawer/types'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination,
  useTranslateCountryFormatter
} from '../../../common/DataGridTable'
import {MoreMenu, SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {businessPartnerStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {ReloadButton} from '../components/ReloadButton'
import {WideCenteredLayout} from '../Layout'
import {useBusinessPartners, useCreateBusinessPartner} from './graphql'
import {transformFormDataToCreateBusinessPartnerInput} from './utils'

const StateRenderer = ({state}: {state: BusinessPartnerState}) => {
  const translateBusinessPartnerState = useTranslateBusinessPartnerState()
  return (
    <EntityStateChip
      colorConf={businessPartnerStateColors[state]}
      label={translateBusinessPartnerState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.businessPartners.edit(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

interface IBusinessPartnersListProps {
  searchFilter: BusinessPartnersFilterInput
}

export const BusinessPartnersList: React.FC<IBusinessPartnersListProps> = ({
  searchFilter
}: IBusinessPartnersListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [selectedState, setSelectedState] =
    useState<BusinessPartnerState | undefined>(undefined)
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination({
    page: 0,
    pageSize: 100
  })
  const {data, loading, error, refetch} = useBusinessPartners({
    filter: {
      ...searchFilter,
      states: selectedState
        ? [selectedState]
        : [BusinessPartnerState.Active, BusinessPartnerState.Inactive]
    },
    paginationInput
  })
  const dataGridApiRef = useGridApiRef()
  const createBusinessPartner = useCreateBusinessPartner()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const translateCountryFormatter = useTranslateCountryFormatter()
  const translateBusinessPartnerState = useTranslateBusinessPartnerState()
  const {
    state: isCreateBusinessPartnerDrawerOpen,
    setTrue: openCreateBusinessPartnerDrawer,
    setFalse: closeCreateBusinessPartnerDrawer
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  const handleSubmit = useCallback(
    async (formData: IBusinessPartnerForm) => {
      try {
        setShowBackdrop(true)
        await createBusinessPartner({
          input: transformFormDataToCreateBusinessPartnerInput(formData)
        })
        closeCreateBusinessPartnerDrawer()
        await refetch()
        addInfoNotification(t('Business partner has been created'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating business partner'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createBusinessPartner,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      refetch,
      closeCreateBusinessPartnerDrawer
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Company name'),
        field: 'companyName',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: BusinessPartnerState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        valueFormatter: (params) => translateBusinessPartnerState(params.value),
        minWidth: 50,
        sortable: false
      },
      {
        headerName: t('Company ID number'),
        field: 'companyIdNumber',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('TAX ID'),
        field: 'taxId',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('VAT ID'),
        field: 'vatId',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Our ID'),
        field: 'customId',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Phone'),
        field: 'phone',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Email'),
        field: 'email',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Website'),
        field: 'website',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingAddressStreet'),
        field: 'billingAddress.street',
        valueGetter: (params) => params.row.billingAddress?.street,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingAddressTown'),
        field: 'billingAddress.town',
        valueGetter: (params) => params.row.billingAddress?.town,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingPostalCode'),
        field: 'billingAddress.postalCode',
        valueGetter: (params) => params.row.billingAddress?.postalCode,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingAddressCountry'),
        field: 'billingAddress.country',
        valueGetter: (params) => params.row.billingAddress?.country,
        valueFormatter: translateCountryFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressee'),
        field: 'deliveryAddress.addressee',
        valueGetter: (params) => params.row.deliveryAddress?.addressee,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressStreet'),
        field: 'deliveryAddress.street',
        valueGetter: (params) => params.row.deliveryAddress?.street,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressTown'),
        field: 'deliveryAddress.town',
        valueGetter: (params) => params.row.deliveryAddress?.town,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryPostalCode'),
        field: 'deliveryAddress.postalCode',
        valueGetter: (params) => params.row.deliveryAddress?.postalCode,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressCountry'),
        field: 'deliveryAddress.country',
        valueGetter: (params) => params.row.deliveryAddress?.country,
        valueFormatter: translateCountryFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Note'),
        field: 'note',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Internal note'),
        field: 'internalNote',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48,
        disableExport: true
      }
    ],
    [t, translateBusinessPartnerState, translateCountryFormatter]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading business partners')}
      />
    )
  }
  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr'
        }}
      >
        <SubHeaderToolbar
          title={t('Overview')}
          leftActions={[
            <ChipWithOptions<BusinessPartnerState>
              key="business-partner-state-chip"
              selectedItem={selectedState}
              setSelectedItem={setSelectedState}
              options={[
                {
                  label: t('Active partners'),
                  option: BusinessPartnerState.Active
                },
                {
                  label: t('Inactive partners'),
                  option: BusinessPartnerState.Inactive
                }
              ]}
              allText={t('All partners')}
            />
          ]}
          rightActions={[
            <ReloadButton
              key="reload-button"
              onReloadButtonClick={() => refetch()}
            />,
            <MoreMenu
              key="more-menu"
              items={[
                {
                  label: t('Download partners in CSV'),
                  icon: <GetAppIcon />,
                  onClick: () =>
                    dataGridApiRef.current.exportDataAsCsv({
                      fileName: t<string>('business-partners')
                    })
                }
              ]}
            />
          ]}
        />
        <WideCenteredLayout
          sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}
        >
          <DataGridTable
            sx={{
              [`& .${gridClasses.withBorderColor}`]: {
                borderColor: 'divider'
              },
              [`& .${gridClasses.columnSeparator}`]: {
                color: 'divider'
              }
            }}
            apiRef={dataGridApiRef}
            columns={columns}
            loading={loading}
            rows={data?.businessPartners.items || []}
            disableColumnMenu
            disableRowSelectionOnClick
            initialState={{
              pinnedColumns: {left: ['companyName'], right: ['id']}
            }}
            columnVisibilityModel={{
              id: P([
                PermissionCode.ReadBusinessPartner,
                PermissionCode.UpdateBusinessPartner
              ])
            }}
            experimentalFeatures={{columnGrouping: true}}
            columnHeaderHeight={32}
            columnGroupingModel={[
              {
                groupId: t('Company'),
                children: [
                  {field: 'companyIdNumber'},
                  {field: 'taxId'},
                  {field: 'vatId'},
                  {field: 'customId'}
                ]
              },
              {
                groupId: t('Contacts'),
                children: [
                  {field: 'phone'},
                  {field: 'email'},
                  {field: 'website'}
                ]
              },
              {
                groupId: t('Billing address'),
                children: [
                  {field: 'billingAddress.street'},
                  {field: 'billingAddress.town'},
                  {field: 'billingAddress.postalCode'},
                  {field: 'billingAddress.country'}
                ]
              },
              {
                groupId: t('Delivery address'),
                children: [
                  {field: 'deliveryAddress.addressee'},
                  {field: 'deliveryAddress.street'},
                  {field: 'deliveryAddress.town'},
                  {field: 'deliveryAddress.postalCode'},
                  {field: 'deliveryAddress.country'}
                ]
              },
              {
                groupId: t('Notes'),
                children: [{field: 'note'}, {field: 'interalNote'}]
              }
            ]}
            localeText={{noRowsLabel: t('No business partners to show')}}
            {...getDataGridPaginationProps(data?.businessPartners.pagination)}
          />
        </WideCenteredLayout>
      </Box>
      {P([PermissionCode.CreateBusinessPartner]) && (
        <CreateFab
          classes={fabClasses}
          onClick={openCreateBusinessPartnerDrawer}
        />
      )}
      <CreateBusinessPartnerDrawer
        isOpen={isCreateBusinessPartnerDrawerOpen}
        onClose={closeCreateBusinessPartnerDrawer}
        onSubmit={handleSubmit}
      />
    </>
  )
}
