import {LocaleCode} from '../__generated__/schema'

enum Infrastructure {
  CZ_TEST = 'test.entradio.cz',
  SK_PROD = 'app.entradio.sk',
  CZ_PROD = 'app.entradio.cz',
  SNM = 'snm.entradio.cz'
}

interface InfrastructureValues {
  promoWebsite: string
  locale: LocaleCode
}

const defaultValues: InfrastructureValues = {
  promoWebsite: 'https://www.entradio.cz',
  locale: LocaleCode.En
}

export const getValuesBasedOnInfrastructure = () => {
  const location = window.location.hostname.toLowerCase() as Infrastructure
  const values: Record<Infrastructure, InfrastructureValues> = {
    [Infrastructure.CZ_TEST]: {
      promoWebsite: 'https://www.entradio.cz',
      locale: LocaleCode.Cs
    },
    [Infrastructure.SK_PROD]: {
      promoWebsite: 'https://www.entradio.sk',
      locale: LocaleCode.Sk
    },
    [Infrastructure.CZ_PROD]: {
      promoWebsite: 'https://www.entradio.cz',
      locale: LocaleCode.Cs
    },
    [Infrastructure.SNM]: {
      promoWebsite: 'https://www.entradio.cz',
      locale: LocaleCode.Sk
    }
  }
  return Object.keys(values).includes(location)
    ? values[location]
    : defaultValues
}
