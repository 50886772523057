import {useCallback} from 'react'
import {
  LocaleCode,
  Maybe,
  Scalars,
  ShowTranslation
} from '../__generated__/schema'

import {useClientLocaleCode} from '../hooks/getLocales'

export type ShowTranslationType = Pick<
  ShowTranslation,
  'title' | 'localeCode' | 'description' | 'tagline'
>
export const getShowTranslation = ({
  translations,
  localeCode
}: {
  translations?: ShowTranslationType[]
  localeCode: LocaleCode
}): ShowTranslationType | undefined => {
  return translations?.find((t) => t.localeCode === localeCode)
}

export const useGetShowTitle = () => {
  const clientLocaleCode = useClientLocaleCode()
  return useCallback(
    ({
      translations,
      originalTitle,
      fallbackTitle
    }: {
      originalTitle?: Maybe<Scalars['String']>
      translations?: Pick<ShowTranslation, 'localeCode' | 'title'>[]
      fallbackTitle: string
    }): string =>
      getShowTranslation({
        translations,
        localeCode: clientLocaleCode
      })?.title! ||
      originalTitle! ||
      fallbackTitle,
    [clientLocaleCode]
  )
}
