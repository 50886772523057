import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  CreateECommercePaymentMethodMutation,
  CreateECommercePaymentMethodMutationVariables,
  CreatePaymentMethodGroupMutation,
  CreatePaymentMethodGroupMutationVariables,
  CreateRetailPaymentMethodMutation,
  CreateRetailPaymentMethodMutationVariables,
  DeleteECommercePaymentMethodMutation,
  DeleteECommercePaymentMethodMutationVariables,
  DeletePaymentMethodGroupMutation,
  DeletePaymentMethodGroupMutationVariables,
  DeleteRetailPaymentMethodMutation,
  DeleteRetailPaymentMethodMutationVariables,
  ECommercePaymentMethodsQuery,
  ECommercePaymentMethodsQueryVariables,
  EditPaymentMethodGroupMutation,
  EditPaymentMethodGroupMutationVariables,
  PaymentMethodGroup,
  PaymentMethodGroupsQuery,
  PaymentMethodGroupsQueryVariables,
  PaymentMethodState,
  RetailPaymentMethodQuery,
  RetailPaymentMethodQueryVariables,
  RetailPaymentMethodsQuery,
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
} from '../../../../__generated__/schema'
import {
  PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT,
  PAYMENT_METHOD_PROPERTIES_FRAGMENT,
  RETAIL_PAYMENT_METHODS
} from '../graphql'

export const GET_RETAIL_PAYMENT_METHOD = gql`
  query retailPaymentMethod($id: Int!) {
    retailPaymentMethod(id: $id) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

export const ECOMMERCE_PAYMENT_METHODS = gql`
  query eCommercePaymentMethods($state: PaymentMethodState) {
    eCommercePaymentMethods(state: $state) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

const PAYMENT_METHOD_GROUPS = gql`
  query paymentMethodGroups {
    paymentMethodGroups {
      ...PaymentMethodGroupProperties
    }
  }
  ${PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT}
`

const CREATE_PAYMENT_METHOD_GROUP = gql`
  mutation createPaymentMethodGroup($name: String!) {
    createPaymentMethodGroup(name: $name) {
      ...PaymentMethodGroupProperties
    }
  }
  ${PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT}
`
const EDIT_PAYMENT_METHOD_GROUP = gql`
  mutation editPaymentMethodGroup($id: Int!, $name: String!) {
    editPaymentMethodGroup(id: $id, name: $name) {
      ...PaymentMethodGroupProperties
    }
  }
  ${PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT}
`

const DELETE_PAYMENT_METHOD_GROUP = gql`
  mutation deletePaymentMethodGroup($id: Int!) {
    deletePaymentMethodGroup(id: $id) {
      ...PaymentMethodGroupProperties
    }
  }
  ${PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT}
`

const DELETE_RETAIL_PAYMENT_METHOD = gql`
  mutation deleteRetailPaymentMethod($id: Int!) {
    deleteRetailPaymentMethod(id: $id) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

const DELETE_ECOMMERCE_PAYMENT_METHOD = gql`
  mutation deleteECommercePaymentMethod($id: Int!) {
    deleteECommercePaymentMethod(id: $id) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

const CREATE_RETAIL_PAYMENT_METHOD = gql`
  mutation createRetailPaymentMethod(
    $input: RetailPaymentMethodInput!
    $paymentServiceProviderConfigInput: PaymentServiceProviderConfigInput
  ) {
    createRetailPaymentMethod(
      input: $input
      paymentServiceProviderConfigInput: $paymentServiceProviderConfigInput
    ) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

const CREATE_ECOMMERCE_PAYMENT_METHOD = gql`
  mutation createECommercePaymentMethod(
    $input: EcommercePaymentMethodInput!
    $paymentServiceProviderConfigInput: PaymentServiceProviderConfigInput
  ) {
    createECommercePaymentMethod(
      input: $input
      paymentServiceProviderConfigInput: $paymentServiceProviderConfigInput
    ) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod($id: Int!, $input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(id: $id, input: $input) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

export const CHECK_GOPAY_CREDENTIALS = gql`
  query checkGoPayCredentials($input: GoPayConfigInput!) {
    isGoPayConfigInputValid(input: $input)
  }
`
export const CHECK_STATNA_POKLADNICA_CREDENTIALS = gql`
  query checkStatnaPokladnicaConfigInputValid(
    $input: StatnaPokladnicaConfigInput!
  ) {
    isStatnaPokladnicaConfigInputValid(input: $input)
  }
`

export const useGetRetailPaymentMethod = (id: number) =>
  useQuery<RetailPaymentMethodQuery, RetailPaymentMethodQueryVariables>(
    GET_RETAIL_PAYMENT_METHOD,
    {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    }
  )

export const useUpdatePaymentMethod = () => {
  const [updatePaymentMethod] = useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UPDATE_PAYMENT_METHOD)
  return (variables: UpdatePaymentMethodMutationVariables) =>
    updatePaymentMethod({
      variables
    })
}

export const useEcommercePaymentMethods = () =>
  useQuery<ECommercePaymentMethodsQuery, ECommercePaymentMethodsQueryVariables>(
    ECOMMERCE_PAYMENT_METHODS,
    {
      variables: {
        state: PaymentMethodState.Active
      },
      fetchPolicy: 'network-only'
    }
  )

export const usePaymentMethodGroups = () =>
  useQuery<PaymentMethodGroupsQuery, PaymentMethodGroupsQueryVariables>(
    PAYMENT_METHOD_GROUPS
  )

export const useCreatePaymentMethodGroup = () => {
  const [createPaymentMethodGroup] = useMutation<
    CreatePaymentMethodGroupMutation,
    CreatePaymentMethodGroupMutationVariables
  >(CREATE_PAYMENT_METHOD_GROUP, {
    update(cache, {data}) {
      const createPaymentMethodGroup = data?.createPaymentMethodGroup
      const paymentMethodGroupsQuery =
        cache.readQuery<PaymentMethodGroupsQuery>({
          query: PAYMENT_METHOD_GROUPS
        })
      if (paymentMethodGroupsQuery && createPaymentMethodGroup) {
        cache.writeQuery({
          query: PAYMENT_METHOD_GROUPS,
          data: {
            paymentMethodGroups: [
              createPaymentMethodGroup,
              ...paymentMethodGroupsQuery.paymentMethodGroups
            ]
          }
        })
      }
    }
  })

  return (variables: CreatePaymentMethodGroupMutationVariables) => {
    return createPaymentMethodGroup({variables})
  }
}

export const useCreateRetailPaymentMethod = () => {
  const [createPaymentMethod] = useMutation<
    CreateRetailPaymentMethodMutation,
    CreateRetailPaymentMethodMutationVariables
  >(CREATE_RETAIL_PAYMENT_METHOD, {
    update(cache, {data}) {
      const createRetailPaymentMethod = data?.createRetailPaymentMethod
      const queryProps = {
        query: RETAIL_PAYMENT_METHODS,
        variables: {
          state: PaymentMethodState.Active
        }
      }
      const paymentMethodsQuery =
        cache.readQuery<RetailPaymentMethodsQuery>(queryProps)
      if (paymentMethodsQuery && createRetailPaymentMethod) {
        cache.writeQuery({
          ...queryProps,
          data: {
            retailPaymentMethods: [
              createRetailPaymentMethod,
              ...paymentMethodsQuery.retailPaymentMethods
            ]
          }
        })
      }
    }
  })

  return (variables: CreateRetailPaymentMethodMutationVariables) =>
    createPaymentMethod({variables})
}

export const useCreateECommercePaymentMethod = () => {
  const [createPaymentMethod] = useMutation<
    CreateECommercePaymentMethodMutation,
    CreateECommercePaymentMethodMutationVariables
  >(CREATE_ECOMMERCE_PAYMENT_METHOD, {
    update(cache, {data}) {
      const createECommercePaymentMethod = data?.createECommercePaymentMethod
      const queryProps = {
        query: ECOMMERCE_PAYMENT_METHODS,
        variables: {
          state: PaymentMethodState.Active
        }
      }
      const paymentMethodsQuery =
        cache.readQuery<ECommercePaymentMethodsQuery>(queryProps)
      if (paymentMethodsQuery && createECommercePaymentMethod) {
        cache.writeQuery({
          ...queryProps,
          data: {
            eCommercePaymentMethods: [
              createECommercePaymentMethod,
              ...paymentMethodsQuery.eCommercePaymentMethods
            ]
          }
        })
      }
    }
  })

  return (variables: CreateECommercePaymentMethodMutationVariables) =>
    createPaymentMethod({variables})
}

export const useDeletePaymentMethodGroup = () => {
  const [deletePaymentMethodGroup] = useMutation<
    DeletePaymentMethodGroupMutation,
    DeletePaymentMethodGroupMutationVariables
  >(DELETE_PAYMENT_METHOD_GROUP, {
    update(cache, {data}) {
      const deletePaymentMethodGroup = data?.deletePaymentMethodGroup
      const paymentMethodGroupsQuery =
        cache.readQuery<PaymentMethodGroupsQuery>({
          query: PAYMENT_METHOD_GROUPS
        })
      if (paymentMethodGroupsQuery && deletePaymentMethodGroup) {
        cache.writeQuery({
          query: PAYMENT_METHOD_GROUPS,
          data: {
            paymentMethodGroups:
              paymentMethodGroupsQuery.paymentMethodGroups.filter(
                (pmg) => pmg.id !== deletePaymentMethodGroup.id
              )
          }
        })
      }
    }
  })

  return (variables: DeletePaymentMethodGroupMutationVariables) => {
    return deletePaymentMethodGroup({variables})
  }
}

export const useEditPaymentMethodGroup = () => {
  const [editPaymentMethodGroup] = useMutation<
    EditPaymentMethodGroupMutation,
    EditPaymentMethodGroupMutationVariables
  >(EDIT_PAYMENT_METHOD_GROUP, {
    update(cache, {data}) {
      const editPaymentMethodGroup = data?.editPaymentMethodGroup
      const paymentMethodGroupsQuery =
        cache.readQuery<PaymentMethodGroupsQuery>({
          query: PAYMENT_METHOD_GROUPS
        })
      if (paymentMethodGroupsQuery && editPaymentMethodGroup) {
        cache.writeQuery({
          query: PAYMENT_METHOD_GROUPS,
          data: {
            paymentMethodGroups:
              paymentMethodGroupsQuery.paymentMethodGroups.reduce(
                (acc: PaymentMethodGroup[], epg) => [
                  ...acc,
                  epg.id === editPaymentMethodGroup.id
                    ? editPaymentMethodGroup
                    : epg
                ],
                []
              )
          }
        })
      }
    }
  })

  return (variables: EditPaymentMethodGroupMutationVariables) =>
    editPaymentMethodGroup({variables})
}

export const useDeleteRetailPaymentMethod = () => {
  const [deletePaymentMethod] = useMutation<
    DeleteRetailPaymentMethodMutation,
    DeleteRetailPaymentMethodMutationVariables
  >(DELETE_RETAIL_PAYMENT_METHOD, {
    update(cache, {data}) {
      const deletePaymentMethod = data?.deleteRetailPaymentMethod
      const queryProps = {
        query: RETAIL_PAYMENT_METHODS,
        variables: {
          state: PaymentMethodState.Active
        }
      }
      const paymentMethodsQuery =
        cache.readQuery<RetailPaymentMethodsQuery>(queryProps)
      if (paymentMethodsQuery && deletePaymentMethod) {
        cache.writeQuery({
          ...queryProps,
          data: {
            retailPaymentMethods:
              paymentMethodsQuery.retailPaymentMethods.filter(
                (pm) => pm.id !== deletePaymentMethod.id
              )
          }
        })
      }
    }
  })
  return (variables: DeleteRetailPaymentMethodMutationVariables) =>
    deletePaymentMethod({variables})
}

export const useDeleteECommercePaymentMethod = () => {
  const [deletePaymentMethod] = useMutation<
    DeleteECommercePaymentMethodMutation,
    DeleteECommercePaymentMethodMutationVariables
  >(DELETE_ECOMMERCE_PAYMENT_METHOD, {
    update(cache, {data}) {
      const deletePaymentMethod = data?.deleteECommercePaymentMethod
      const queryProps = {
        query: ECOMMERCE_PAYMENT_METHODS,
        variables: {
          state: PaymentMethodState.Active
        }
      }
      const paymentMethodsQuery =
        cache.readQuery<ECommercePaymentMethodsQuery>(queryProps)
      if (paymentMethodsQuery && deletePaymentMethod) {
        cache.writeQuery({
          ...queryProps,
          data: {
            eCommercePaymentMethods:
              paymentMethodsQuery.eCommercePaymentMethods.filter(
                (pm) => pm.id !== deletePaymentMethod.id
              )
          }
        })
      }
    }
  })
  return (variables: DeleteECommercePaymentMethodMutationVariables) =>
    deletePaymentMethod({variables})
}
