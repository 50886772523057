import {
  Box,
  Chip,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import {gridClasses} from '@mui/x-data-grid'
import {
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams
} from '@mui/x-data-grid-pro'
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro'
import {clsx} from 'clsx'
import {isNil} from 'lodash'
import isEqual from 'lodash/isEqual'
import React, {MutableRefObject, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {BaseInventoryCheckProductFieldsFragment} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../../hooks/translateCurrencies'
import {useTranslateUnit} from '../../../../../hooks/translateUnit'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {useIsNonNegativeDecimal} from '../../../../../utils/formsValidations'
import {
  DataGridTable,
  useDateTimeFormatter,
  useDecimalFormatter,
  useSingleClickEditing,
  useUserNameFormatter
} from '../../../../common/DataGridTable'
import {COLOR_CONF} from '../../../../constants'
import {useFormatDecimalToLocaleString} from '../../utils'
import {useUpdateInventoryCheckProduct} from '../graphql'

const getEditableCellClassName = (hasError?: boolean) =>
  hasError ? clsx('error') : clsx('editable')

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
  <MuiTooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

const StockVarianceCellRenderer: React.FC<{variance: number}> = ({
  variance
}: {
  variance: number
}) => {
  const formatDecimalToLocaleString = useFormatDecimalToLocaleString({
    fractionDigits: 6
  })
  return (
    <Chip
      label={formatDecimalToLocaleString(variance)}
      sx={
        variance < 0
          ? COLOR_CONF.RED
          : variance > 0
          ? COLOR_CONF.GREEN
          : COLOR_CONF.GRAY
      }
    />
  )
}

interface IItemsProps {
  products: BaseInventoryCheckProductFieldsFragment[]
  isEditable: boolean
  isVatRegistered: boolean
  inventoryCheckId: number
  dataGridApiRef: MutableRefObject<GridApiPro>
  rowIdError?: number
}

export const Items: React.FC<IItemsProps> = ({
  products,
  isVatRegistered,
  isEditable,
  inventoryCheckId,
  dataGridApiRef,
  rowIdError
}: IItemsProps) => {
  const {t} = useTranslation()
  const updateInventoryCheckProduct = useUpdateInventoryCheckProduct()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const decimalFormatter8 = useDecimalFormatter(8)
  const decimalFormatter6 = useDecimalFormatter(6)
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const translateUnit = useTranslateUnit()
  const dateTimeFormatter = useDateTimeFormatter()
  const userNameFormatter = useUserNameFormatter()
  const singleClickEditing = useSingleClickEditing()
  const isNonNegativeDecimal = useIsNonNegativeDecimal('1,6')
  const processRowUpdate = useCallback(
    async (updatedRow) => {
      let updatedData: BaseInventoryCheckProductFieldsFragment[] = []
      const transformedRow = {
        ...updatedRow,
        realStock:
          updatedRow.realStock === ''
            ? null
            : convertValueToFloat(updatedRow.realStock),
        note: updatedRow.note || null
      }
      const shouldUpdate = !isEqual(
        products.find(({id}) => id === transformedRow.id),
        transformedRow
      )
      if (shouldUpdate) {
        try {
          setShowBackdrop(true)
          const {data} = await updateInventoryCheckProduct({
            inventoryCheckId,
            inventoryCheckProductId: transformedRow.id,
            note: transformedRow.note,
            realStock: transformedRow.realStock
          })
          if (data) {
            updatedData =
              data.updateInventoryCheckProduct.inventoryCheckProducts
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while updating inventory check product')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
      return (
        updatedData.find(({id}) => id === transformedRow.id) || transformedRow
      )
    },
    [
      defaultErrorHandler,
      inventoryCheckId,
      products,
      setShowBackdrop,
      t,
      updateInventoryCheckProduct
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Code'),
        field: 'internalCode',
        valueGetter: (params) =>
          params.row.warehouseProduct.product.internalCode,
        minWidth: 100,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Name'),
        field: 'name',
        valueGetter: (params) => params.row.warehouseProduct.product.name,
        minWidth: 200,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Average price ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        valueGetter: (params) =>
          isVatRegistered
            ? params.row.averagePriceVatExcluded
            : params.row.averagePriceVatIncluded,
        field: 'averagePrice',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatter8,
        minWidth: 150,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Unit'),
        field: 'unit',
        valueGetter: (params) => params.row.warehouseProduct.product.unit,
        valueFormatter: (params) => translateUnit(params.value),
        minWidth: 50,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Expected'),
        field: 'expectedStock',
        valueFormatter: decimalFormatter6,
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        editable: false
      },
      {
        headerName: t('Real'),
        field: 'realStock',
        valueFormatter: decimalFormatter6,
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        cellClassName: (params) =>
          getEditableCellClassName(params.row.id === rowIdError),
        sortable: false,
        editable: isEditable,
        preProcessEditCellProps: function processor(
          params: GridPreProcessEditCellProps
        ) {
          const error = isNonNegativeDecimal(params.props.value)
          return {...params.props, errormessage: error, error: !!error}
        },
        renderEditCell: function renderer(params: GridRenderEditCellParams) {
          return (
            <StyledTooltip open={params.error} title={params.errormessage}>
              <span>
                <GridEditInputCell
                  {...params}
                  onFocus={(
                    e: React.FocusEvent<
                      HTMLInputElement | HTMLTextAreaElement,
                      Element
                    >
                  ) => !isNil(params.value) && e.target.select()}
                  sx={{'& .MuiInputBase-input': {textAlign: 'right'}}}
                />
              </span>
            </StyledTooltip>
          )
        }
      },
      {
        headerName: t('Stock variance'),
        field: 'stockVariance',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StockVarianceCellRenderer variance={params.value} />
        },
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        editable: false
      },
      {
        headerName: t('Note'),
        field: 'note',
        minWidth: 250,
        cellClassName: () => getEditableCellClassName(),
        editable: isEditable,
        sortable: false
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        editable: false,
        minWidth: 200
      },
      {
        headerName: t('Updated by'),
        field: 'updatedBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        editable: false,
        minWidth: 250
      }
    ],
    [
      dateTimeFormatter,
      decimalFormatter6,
      decimalFormatter8,
      isEditable,
      isNonNegativeDecimal,
      isVatRegistered,
      rowIdError,
      t,
      translateUnit,
      translatedEffectiveClientCurrencySign,
      userNameFormatter
    ]
  )
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Typography variant="subtitle1">{t('Items')}</Typography>
      <DataGridTable
        apiRef={dataGridApiRef}
        columns={columns}
        rows={products}
        pagination={false}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
        columnHeaderHeight={32}
        autoHeight
        localeText={{noRowsLabel: t('No items to show')}}
        initialState={{
          pinnedColumns: {
            left: ['internalCode', 'name'],
            right: ['action']
          }
        }}
        {...singleClickEditing}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{columnGrouping: true}}
        columnGroupingModel={[
          {
            groupId: 'product',
            headerName: t('Product'),
            children: [
              {field: 'internalCode'},
              {field: 'name'},
              {field: 'averagePrice'},
              {field: 'unit'}
            ]
          },
          {
            groupId: 'stock',
            headerName: t('Stock'),
            children: [
              {field: 'expectedStock'},
              {field: 'realStock'},
              {field: 'stockVariance'},
              {field: 'note'},
              {field: 'updatedAt'},
              {field: 'updatedBy'}
            ]
          }
        ]}
        sx={{
          '& .editable': {backgroundColor: COLOR_CONF.BLUE.background},
          '& .error': {backgroundColor: COLOR_CONF.RED.background},
          [`.${gridClasses.main}`]: {
            overflow: 'unset'
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            top: -16,
            backgroundColor: 'background.paper',
            zIndex: 1
          },
          [`& .${gridClasses.withBorderColor}`]: {
            borderColor: 'divider'
          },
          [`& .${gridClasses.columnSeparator}`]: {
            color: 'divider'
          }
        }}
      />
    </Box>
  )
}
