import {TourTimeSlotState} from '../../../../__generated__/schema'
import {TimeSlotStateChip} from './types'

export const getTourTimeSlotsStates = (filter: TimeSlotStateChip) => {
  switch (filter) {
    case TimeSlotStateChip.Published:
      return [TourTimeSlotState.Published]
    case TimeSlotStateChip.Canceled:
      return [TourTimeSlotState.Cancelled]
    case TimeSlotStateChip.All:
    default:
      return [TourTimeSlotState.Published, TourTimeSlotState.Cancelled]
  }
}
