import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Box, Button, Chip, ChipProps} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '../../../common'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

interface IChipWithMultiselectProps<T> {
  selectedItems: T[]
  onApplyButtonClick: (items: T[]) => void
  options: {
    option: T
    label: string
  }[]
  allText: string
  tooltip?: string
  size?: ChipProps['size']
  icon?: React.ReactElement
}

export const ChipWithMultiselect = <T extends unknown>({
  selectedItems,
  onApplyButtonClick,
  options,
  allText,
  tooltip,
  size = 'small',
  icon
}: IChipWithMultiselectProps<T>) => {
  const {t} = useTranslation()
  const [items, setItems] = useState<T[]>(selectedItems)
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleMenuItemClick = useCallback(
    (newItem: T) => () => {
      setItems((prevItems) =>
        prevItems.includes(newItem)
          ? prevItems.filter((item) => item !== newItem)
          : [...prevItems, newItem]
      )
    },
    []
  )
  const handleApplyButtonClick = useCallback(() => {
    onApplyButtonClick(items)
    closeMenu()
  }, [closeMenu, items, onApplyButtonClick])
  return (
    <>
      <Tooltip title={tooltip}>
        <Chip
          size={size}
          variant="outlined"
          icon={icon}
          deleteIcon={<ArrowDropDownIcon />}
          onClick={openMenu}
          onDelete={openMenu}
          label={
            selectedItems.length > 0
              ? selectedItems.length === options.length
                ? allText
                : options
                    .filter(({option}) => selectedItems.includes(option))
                    .map(({label}) => label)
                    .join(', ')
              : allText
          }
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box
            sx={{borderBottom: (theme) => `solid ${theme.palette.divider} 1px`}}
          >
            {options.map((o, index) => (
              <MenuItem
                key={`${index}-${o.option}`}
                label={o.label}
                icon={items.includes(o.option) ? <CheckIcon /> : undefined}
                isSelected={items.includes(o.option)}
                sx={{flexFlow: 'row-reverse'}}
                iconSx={{justifyContent: 'center'}}
                onClick={handleMenuItemClick(o.option)}
              />
            ))}
          </Box>
          <Box
            sx={{
              px: 3,
              pt: 1.5,
              pb: 0.5,
              alignSelf: 'end',
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Button onClick={closeMenu} color="primary">
              {t('Close')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyButtonClick}
            >
              {t('Apply')}
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  )
}
