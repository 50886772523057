import DragHandleIcon from '@mui/icons-material/DragHandle'
import {Box, IconButton} from '@mui/material'
import React, {useCallback} from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import {ItemGroupWithId} from '../../types'
import {ItemGroup} from './ItemGroup'
import './styles.css'

const DragHandle = SortableHandle(() => (
  <IconButton color="primary">
    <DragHandleIcon />
  </IconButton>
))

const Container = SortableContainer(
  ({children, itemsLength}: {children: any; itemsLength: number}) => {
    return (
      <Box
        sx={{
          display: 'grid',
          columnGap: 3,
          rowGap: 6,
          width: '100%',
          gridTemplateColumns: `repeat(${itemsLength}, minmax(0, 1fr))`
        }}
      >
        {children}
      </Box>
    )
  }
)

const SortableItemGroup = SortableElement(
  ({
    itemGroup,
    setItemGroups
  }: {
    itemGroup: ItemGroupWithId
    setItemGroups: React.Dispatch<React.SetStateAction<ItemGroupWithId[]>>
  }) => (
    <ItemGroup
      itemGroup={itemGroup}
      dragHandle={<DragHandle />}
      setItemGroups={setItemGroups}
    />
  )
)

interface ISortableItemGroupsProps {
  itemGroups: ItemGroupWithId[]
  onSortEnd: (oldIndex: number, newIndex: number) => void
  setItemGroups: React.Dispatch<React.SetStateAction<ItemGroupWithId[]>>
}

export const SortableItemGroups: React.FC<ISortableItemGroupsProps> = ({
  itemGroups,
  onSortEnd,
  setItemGroups
}: ISortableItemGroupsProps) => {
  const handleSortEnd = useCallback(
    ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) =>
      onSortEnd(oldIndex, newIndex),
    [onSortEnd]
  )
  return (
    <Container
      helperClass="draggable-item"
      onSortEnd={handleSortEnd}
      useDragHandle
      axis="x"
      lockAxis="x"
      itemsLength={itemGroups.length}
    >
      {itemGroups.map((itemGroup, index) => (
        <SortableItemGroup
          key={`item-group-${itemGroup.id}`}
          itemGroup={itemGroup}
          index={index}
          setItemGroups={setItemGroups}
        />
      ))}
    </Container>
  )
}
