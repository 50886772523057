import DeleteIcon from '@mui/icons-material/Delete'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {Box, Button, Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useRef, useState} from 'react'
import {ReactCropperElement} from 'react-cropper'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {DropZone} from '../../../common/DropZone'
import {ImageCropper} from '../../../common/imageCropper'

interface IImage {
  image: File
  name: string
}

const validateImages = (files: File[]) => {
  const promises = Array.from(files).map((file) => {
    return new Promise<IImage>((resolve, reject) => {
      const image = new Image()
      image.onload = () => {
        resolve({image: file, name: file.name})
      }
      image.onerror = () => reject()
      image.src = URL.createObjectURL(file)
    })
  })
  return Promise.all(promises)
}

interface ICroppedDimensions {
  width: number
  height: number
}

interface IUploadImageDrawerProps {
  isOpen: boolean
  onClose: () => void
  croppedDimensions: ICroppedDimensions
  onSubmit: (croppedImage: Blob, name: string) => void
}

export const UploadImageDrawer: React.FC<IUploadImageDrawerProps> = ({
  isOpen,
  onClose,
  croppedDimensions,
  onSubmit
}: IUploadImageDrawerProps) => {
  const {t} = useTranslation()
  const ref = useRef<ReactCropperElement | null>(null)
  const [selectedImage, setSelectedImage] =
    useState<undefined | IImage>(undefined)
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const handleDrop = useCallback(
    async (files: File[]) => {
      try {
        const droppedImages = await validateImages(files)
        if (droppedImages.length) {
          setSelectedImage(droppedImages[0])
        }
      } catch (error) {
        defaultErrorHandler(error, t('Error while loading images'))
      }
    },
    [defaultErrorHandler, t]
  )
  const handleDeleteButtonClick = useCallback(
    () => setSelectedImage(undefined),
    []
  )
  const handleClose = useCallback(() => {
    onClose()
    setSelectedImage(undefined)
  }, [onClose])
  const handleUploadButtonClick = useCallback(() => {
    ref.current?.cropper
      .getCroppedCanvas({
        width: croppedDimensions.width,
        height: croppedDimensions.height,
        fillColor: '#fff'
      })
      .toBlob(
        (blob) => {
          if (blob) {
            onSubmit(
              blob,
              selectedImage?.name.split('.').slice(0, -1).join('.') || 'image'
            )
            setSelectedImage(undefined)
          }
        },
        'image/jpeg',
        1
      )
  }, [
    croppedDimensions.height,
    croppedDimensions.width,
    onSubmit,
    selectedImage
  ])
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={handleClose}
      sx={{[`& .${drawerClasses.paper}`]: {width: '100%', height: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={handleClose}
            title={t('Upload image')}
          />
        }
        footer={
          selectedImage ? (
            <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
              <Button
                variant="text"
                color="primary"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteButtonClick}
              >
                {t('Delete')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileUploadIcon />}
                onClick={handleUploadButtonClick}
              >
                {t('Upload')}
              </Button>
            </Box>
          ) : undefined
        }
        childrenSx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {selectedImage ? (
          <ImageCropper
            cropperRef={ref}
            imageSrc={URL.createObjectURL(selectedImage.image)}
            aspectRatio={croppedDimensions.width / croppedDimensions.height}
          />
        ) : (
          <DropZone onDrop={handleDrop} />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
