import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {ApolloError} from 'apollo-client'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../__generated__/schema'
import {
  getGraphQLErrorRelatedToErrorMessage,
  isNoInternetConnectionError,
  isNotAuthenticatedError,
  isWithoutConnectivityToServer,
  isWithoutPermissionError
} from '../../utils/errors'
import {Error, Loading} from '../visual'
import {Blank, BlankContentPosition} from '../visual/Blank'
import {SaveButton} from './Buttons'

const NoInternetConnectionError: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={ReportProblemOutlinedIcon}
      title={t('No internet connection')}
      description={t(
        'Try to check your network cables, modem and routers or reconnect to your wireless network. Please, have in mind, that page reload may cause loosing data, that you have changed in this process.'
      )}
      actions={
        <SaveButton
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('Reload')}
        </SaveButton>
      }
    />
  )
}

const WithoutConnectivityToServer: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={ReportProblemOutlinedIcon}
      title={t('Something went wrong')}
      description={t(
        'The application has encountered an unknown error. Our technical staff have been automatically notified and will be looking into this with the utmost urgency.'
      )}
      actions={
        <SaveButton
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('Reload')}
        </SaveButton>
      }
    />
  )
}
const NotAuthenticatedError: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={ReportProblemOutlinedIcon}
      title={t('You are not logged in')}
      description={t(
        'You are not logged in or because of long inactivity you were automatically logged out by system. Please, try to log in again.'
      )}
      actions={
        <SaveButton
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('Log in')}
        </SaveButton>
      }
    />
  )
}

const NotAuthorizedError: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={ReportProblemOutlinedIcon}
      title={t('You are not permitted')}
      description={t(
        'Your account permissions does’t allow you to perform this action or access to such an information.'
      )}
    />
  )
}
const MissingEffectiveClientError: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={ReportProblemOutlinedIcon}
      title={t('Missing effective client')}
    />
  )
}
const UnexpectedEffectiveClientError: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={ReportProblemOutlinedIcon}
      title={t('Not expecting effective client')}
    />
  )
}

type RenderOnDataProps<TData> = (
  | {
      errorChildren: (error: ApolloError) => React.ReactElement
      errorMessage?: never
    }
  | {
      errorChildren?: never
      errorMessage: string
    }
) & {
  error?: ApolloError
  loading: boolean
  data?: TData | null
  ignoreLoadingIfData?: boolean
  children: (data: TData) => React.ReactElement | null
  dataCondition?: (data: TData) => boolean
}

export const RenderOnData = <TData extends object>({
  data,
  error,
  loading,
  children,
  dataCondition,
  errorMessage,
  ignoreLoadingIfData = false,
  errorChildren
}: RenderOnDataProps<TData>) => {
  if (error) {
    if (isNoInternetConnectionError(error)) {
      return <NoInternetConnectionError />
    }
    if (isWithoutConnectivityToServer(error)) {
      return <WithoutConnectivityToServer />
    }
    if (isNotAuthenticatedError(error)) {
      return <NotAuthenticatedError />
    }
    if (isWithoutPermissionError(error)) {
      return <NotAuthorizedError />
    }
    if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.MissingEffectiveClient
      )
    ) {
      return <MissingEffectiveClientError />
    }
    if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.UnwantedEffectiveClient
      )
    ) {
      return <UnexpectedEffectiveClientError />
    }
    if (errorChildren) {
      return errorChildren(error)
    }
    if (errorMessage) {
      return <Error error={error} message={errorMessage} />
    }
  }
  if (
    ignoreLoadingIfData
      ? !(dataCondition ? data && dataCondition(data) : data)
      : loading
  ) {
    return <Loading />
  }
  if (data && (dataCondition ? dataCondition(data) : data)) {
    return children(data)
  }
  return null
}
