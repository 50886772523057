import {Box} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ShowsStatisticsFilterInput} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PersistentDrawerMenu} from '../../../common/PersistentDrawerMenu'
import {PersistentDrawerWithContent} from '../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {Overview} from './Overview'
import {
  DEFAULT_SHOWS_STATISTICS_FILTER_INPUT,
  ShowsStatisticsSearch
} from './ShowsStatisticsSearch'

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      overview: {id: string; label: string; route: string}
    } => ({
      overview: {
        id: 'overview',
        label: t('Overview'),
        route: routeTo.admin.showsStatistics.overview()
      }
    }),
    [t]
  )
}

interface IDrawerContentProps {
  searchFilter: ShowsStatisticsFilterInput
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  searchFilter
}: IDrawerContentProps) => (
  <Switch>
    <Route path={routeTo.admin.showsStatistics.overview()}>
      <Overview searchFilter={searchFilter} />
    </Route>
  </Switch>
)

export const ShowsStatistics: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] = useState<ShowsStatisticsFilterInput>(
    DEFAULT_SHOWS_STATISTICS_FILTER_INPUT
  )
  const navigationItems = useNavigationItems()
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(true)
  return (
    <Switch>
      <Route path={routeTo.admin.showsStatistics.index()} exact>
        <Redirect to={navigationItems.overview.route} />
      </Route>
      <Route path={[routeTo.admin.showsStatistics.overview()]}>
        <PageWithHeaderTemplate
          header={
            <PrimaryHeader
              title={t('Shows statistics')}
              search={
                <ShowsStatisticsSearch onFilterChange={setSearchFilter} />
              }
            />
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <Box sx={{display: 'grid', height: '100%'}}>
              <PersistentDrawerWithContent
                content={<DrawerContent searchFilter={searchFilter} />}
                drawerContent={<PersistentDrawerMenu items={navigationItems} />}
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
              />
            </Box>
          </ChildrenOnEffectiveClientSelected>
        </PageWithHeaderTemplate>
      </Route>
    </Switch>
  )
}
