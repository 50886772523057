import {useTranslation} from 'react-i18next'
import {
  useClientLocaleCode,
  useGetClientLocales
} from '../../../../hooks/getLocales'

export const useShowDetailsFormAnchors = () => {
  const {t} = useTranslation()
  const clientLocaleCode = useClientLocaleCode()
  const clientLocaleCodes = useGetClientLocales()
  return {
    general: {
      id: 'general',
      label:
        clientLocaleCodes.length > 1
          ? t('General ({{locale}})', {
              locale: clientLocaleCode.toUpperCase()
            })
          : t('General')
    },
    content: {
      id: 'content',
      label:
        clientLocaleCodes.length > 1
          ? t('Content ({{locale}})', {
              locale: clientLocaleCode.toUpperCase()
            })
          : t('Content')
    },
    distribution: {
      id: 'distribution',
      label: t('Distribution')
    }
  }
}
