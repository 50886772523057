import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import {Chip, ListItemIcon, ListItemText, MenuItem} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TourTimeSlotState} from '../../../../../../__generated__/schema'
import {useTranslateTourTimeSlotState} from '../../../../../../hooks/translateTourTimeSlotState'
import {Menu, useMenu} from '../../../../../common/Menu'

interface IStatesChipProps {
  onChange: (state: TourTimeSlotState | null) => void
}

export const StatesChip: React.FC<IStatesChipProps> = ({
  onChange
}: IStatesChipProps) => {
  const {t} = useTranslation()
  const [selectedState, setSelectedState] =
    useState<TourTimeSlotState | null>(null)
  const translateTourTimeSlotState = useTranslateTourTimeSlotState()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleChipClick = useCallback((e) => openMenu(e), [openMenu])
  const handleMenuItemClick = useCallback(
    (state: TourTimeSlotState | null) => () => {
      setSelectedState(state)
      onChange(state)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  const handleDeleteChipClick = useCallback(() => {
    setSelectedState(null)
    onChange(null)
  }, [onChange])
  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        deleteIcon={selectedState ? <CancelIcon /> : <ArrowDropDownIcon />}
        label={
          selectedState
            ? translateTourTimeSlotState(selectedState)
            : t('All slots')
        }
        onClick={handleChipClick}
        onDelete={selectedState ? handleDeleteChipClick : handleChipClick}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        <MenuItem
          selected={!selectedState}
          onClick={handleMenuItemClick(null)}
          sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
        >
          <ListItemText primary={t('All slots')} />
        </MenuItem>
        {[
          TourTimeSlotState.Draft,
          TourTimeSlotState.Published,
          TourTimeSlotState.Cancelled
        ].map((state) => (
          <MenuItem
            key={state}
            selected={state === selectedState}
            onClick={handleMenuItemClick(state)}
            sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
          >
            {state === selectedState && (
              <ListItemIcon
                color="primary"
                sx={{minWidth: 48, justifyContent: 'center'}}
              >
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText primary={translateTourTimeSlotState(state)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
