import {
  DiscountAuthorizationMode,
  PassCodeCheckInputType
} from '../../../../__generated__/schema'

export enum CheckMode {
  CheckIn = 'checkIn',
  CheckOut = 'checkOut',
  Bidirectional = 'bidirectional',
  Verify = 'verify',
  Info = 'info'
}

export enum PauseAfterScan {
  DoNotPause = 'doNotPause',
  For250ms = 'for250ms',
  For500ms = 'for500ms',
  For750ms = 'for750ms',
  For1000ms = 'for1000ms',
  For1500ms = 'for1500ms',
  For2000ms = 'for2000ms',
  For2500ms = 'for2500ms',
  For3000ms = 'for3000ms'
}

export enum DeviceEffect {
  VibrationAndSound = 'vibrationAndSound',
  Vibration = 'vibration',
  Sound = 'sound',
  NoEffect = 'noEffect'
}

export enum CheckingMethod {
  SingleTicket = 'singleTicket',
  WholeSaleAtOnce = 'wholeSaleAtOnce'
}

export interface IAdvancedCheckingSettings {
  discountAuthorization: DiscountAuthorizationMode
  defaultInputType: PassCodeCheckInputType
  pauseAfterScan: PauseAfterScan
  cameraId?: string
  allowedDeviceEffect: DeviceEffect
  deniedDeviceEffect: DeviceEffect
  actionRequiredDeviceEffect: DeviceEffect
  enabledDivisions: number[]
  checkingMethod: CheckingMethod
}

export enum AdvancedSettingField {
  DefaultInputType = 'defaultInputType',
  DiscountAuthorization = 'discountAuthorization',
  PauseAfterScan = 'pauseAfterScan',
  CameraId = 'cameraId',
  AllowedDeviceEffect = 'allowedDeviceEffect',
  DeniedDeviceEffect = 'deniedDeviceEffect',
  ActionRequiredDeviceEffect = 'actionRequiredDeviceEffect',
  EnabledDivisions = 'enabledDivisions',
  CheckingMethod = 'checkingMethod'
}

export interface IAdvancedSettingsForm {
  [AdvancedSettingField.DiscountAuthorization]: DiscountAuthorizationMode
  [AdvancedSettingField.DefaultInputType]: PassCodeCheckInputType
  [AdvancedSettingField.PauseAfterScan]: PauseAfterScan
  [AdvancedSettingField.CameraId]: string
  [AdvancedSettingField.AllowedDeviceEffect]: DeviceEffect
  [AdvancedSettingField.DeniedDeviceEffect]: DeviceEffect
  [AdvancedSettingField.ActionRequiredDeviceEffect]: DeviceEffect
  [AdvancedSettingField.EnabledDivisions]: string
  [AdvancedSettingField.CheckingMethod]: CheckingMethod
}

export const AnCheckTicketsDrawerOpen = 'AnCheckTicketsDrawerOpen'
export const AnCheckTicketsDrawerClose = 'AnCheckTicketsDrawerClose'

export enum CheckTicketsPageType {
  Events = 'events',
  TourTimeSlots = 'tourTimeSlots',
  Tours = 'tours',
  Venues = 'venues'
}

export enum BulkCheckDrawerTab {
  Overview = 'overview',
  Unchecked = 'unchecked',
  CheckedIn = 'checkedIn'
}
