import {Button, Drawer, drawerClasses} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CreateDailyCashierReportForm} from './CreateDailyCashierReportForm'
import {
  useAvailableInputDataForDailyCashierReports,
  useCreateDailyCashierPaymentReport
} from './graphql'
import {
  CreateDailyCashierPaymentReportStep,
  DailyCashierPaymentReportFormField,
  IDailyCashierReportForm
} from './types'

const CREATE_DAILY_CASHIER_REPORT_FORM_ID = 'createDailyCashierReportForm'

interface ICreateDailyCashierReportDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateDailyCashierReportDrawer: React.FC<ICreateDailyCashierReportDrawerProps> =
  ({isOpen, onClose}: ICreateDailyCashierReportDrawerProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {user} = useUserInfo()
    const canCreateReportForAll = P([
      PermissionCode.ReadAvailableDailyCashierReportsInputDataForAll
    ])
    const [skip, setSkip] = useState(!isOpen)
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
    }, [isOpen])
    const {data, loading, error} = useAvailableInputDataForDailyCashierReports({
      fetchPolicy: 'network-only',
      variables: {cashierUserId: canCreateReportForAll ? undefined : user?.id},
      skip
    })
    const createDailyCashierPaymentReport = useCreateDailyCashierPaymentReport()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const methods = useForm<IDailyCashierReportForm>()
    const history = useHistory()
    const [step, setStep] = useState<CreateDailyCashierPaymentReportStep>(
      CreateDailyCashierPaymentReportStep.SelectCashier
    )
    const {watch, setValue} = methods
    const selectedCashierId = watch(
      DailyCashierPaymentReportFormField.CashierUserId
    )
    const selectedDate = watch(DailyCashierPaymentReportFormField.ReportDate)
    const isNextButtonDisabled = isEmpty(selectedCashierId)
    const isCreateButtonDisabled =
      isEmpty(selectedCashierId) || isEmpty(selectedDate)
    const handleSubmit = useCallback(
      async (data: IDailyCashierReportForm) => {
        try {
          setShowBackdrop(true)
          const {data: createdReportData} =
            await createDailyCashierPaymentReport({
              ...data,
              cashierUserId: parseInt(
                data[DailyCashierPaymentReportFormField.CashierUserId],
                10
              )
            })
          addInfoNotification(t('Daily cashier payment report created'))
          onClose()
          if (createdReportData) {
            history.replace(
              routeTo.admin.paymentReports.detail(
                createdReportData.createDailyCashierPaymentReport.id
              ),
              {
                from: routeTo.admin.paymentReports.dailyCashierReports()
              }
            )
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while creating daily cashier payment report')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createDailyCashierPaymentReport,
        defaultErrorHandler,
        history,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    const handleClose = useCallback(() => {
      onClose()
      setStep(CreateDailyCashierPaymentReportStep.SelectCashier)
    }, [onClose])
    useEffect(() => {
      if (data && !canCreateReportForAll && user?.id) {
        setStep(CreateDailyCashierPaymentReportStep.SelectDate)
        setValue(
          DailyCashierPaymentReportFormField.CashierUserId,
          String(user.id)
        )
      }
    }, [data, user, canCreateReportForAll, setValue])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        SlideProps={{
          onExited: () => {
            setSkip(true)
          }
        }}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 480, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error &&
            t<string>(
              'Error while loading input data for daily cashier reports'
            )
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={handleClose}
              title={t('Create daily cashier report')}
            />
          }
          footer={
            <>
              {step === CreateDailyCashierPaymentReportStep.SelectCashier &&
                canCreateReportForAll && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setStep(CreateDailyCashierPaymentReportStep.SelectDate)
                    }
                    disabled={isNextButtonDisabled}
                  >
                    {t('Next')}
                  </Button>
                )}
              {step === CreateDailyCashierPaymentReportStep.SelectDate &&
                canCreateReportForAll && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setStep(CreateDailyCashierPaymentReportStep.SelectCashier)
                      setValue(
                        DailyCashierPaymentReportFormField.ReportDate,
                        ''
                      )
                    }}
                  >
                    {t('Back')}
                  </Button>
                )}
              {step === CreateDailyCashierPaymentReportStep.SelectDate && (
                <SaveButton
                  type="submit"
                  form={CREATE_DAILY_CASHIER_REPORT_FORM_ID}
                  disabled={isCreateButtonDisabled}
                >
                  {t('Create')}
                </SaveButton>
              )}
            </>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {data && (
            <FormContext {...methods}>
              <CreateDailyCashierReportForm
                data={data.availableInputDataForDailyCashierReports}
                formId={CREATE_DAILY_CASHIER_REPORT_FORM_ID}
                onSubmit={handleSubmit}
                step={step}
              />
            </FormContext>
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
