import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  OverviewShowsStatisticsQuery,
  OverviewShowsStatisticsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const OVERVIEW_SHOWS_STATISTICS = gql`
  ${PAGINATION_FRAGMENT}
  query OverviewShowsStatistics(
    $paginationInput: PaginationInput!
    $filter: ShowsStatisticsFilterInput
  ) {
    showsStatistics(paginationInput: $paginationInput, filter: $filter) {
      items {
        showId
        showTitles {
          localeCode
          title
        }
        firstEventStartsAt
        lastEventStartsAt
        eventsCount
        profit
        profitTicketCount
        totalDiscountedSoldTicketCount
        totalFreeSoldTicketCount
      }
      totals {
        eventsCount
        profit
        profitTicketCount
        totalDiscountedSoldTicketCount
        totalFreeSoldTicketCount
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useOverviewShowsStatistics = (
  variables: OverviewShowsStatisticsQueryVariables
) =>
  useQuery<OverviewShowsStatisticsQuery, OverviewShowsStatisticsQueryVariables>(
    OVERVIEW_SHOWS_STATISTICS,
    {variables, fetchPolicy: 'network-only'}
  )
