import {Card, CardContent, Typography, useMediaQuery} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {MediaSizes} from '../../constants'

const useStyles = makeStyles<Theme, {isMobile?: boolean}>((theme) => ({
  card: {
    width: ({isMobile}) => (isMobile ? '100%' : 194)
  },
  cardContent: {
    padding: theme.spacing(1, 2),
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    }
  }
}))

interface IStatCardProps {
  title: string
  children: React.ReactNode
}

export const StatCard: React.FC<IStatCardProps> = ({
  title,
  children
}: IStatCardProps) => {
  const isMobile = useMediaQuery(MediaSizes.SmallMobile)
  const classes = useStyles({isMobile})
  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="caption" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body1">{children}</Typography>
      </CardContent>
    </Card>
  )
}
