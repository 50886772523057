import React, {
  useState,
  createContext,
  ReactNode,
  useContext,
  Dispatch,
  SetStateAction
} from 'react'
import _ from 'lodash'

import {ClickInfoActivity} from '../calendar/types'

export interface ResourceLabelClickInfo {
  x: number
  y: number
  activeStart: string
  activeEnd: string
  auditoriumId: number
  activity?: ClickInfoActivity
}

type IResourceLabelContentState = ResourceLabelClickInfo | null

export type SetResourceLabelContentState = Dispatch<
  SetStateAction<IResourceLabelContentState>
>

const Context = createContext([{}, _.noop] as [
  IResourceLabelContentState,
  SetResourceLabelContentState
])

type Props = {
  children: ReactNode
}

export const ResourceLabelContentStateProvider: React.FC<Props> = ({
  children
}: Props) => {
  const resourceHeaderContentStateTuple =
    useState<IResourceLabelContentState>(null)

  return (
    <Context.Provider value={resourceHeaderContentStateTuple}>
      {children}
    </Context.Provider>
  )
}

export const useResourceLabelContentState = () => useContext(Context)
