import {CanvasObjectType} from '@attendio/shared-components'
import GetAppIcon from '@mui/icons-material/GetApp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box, IconButton} from '@mui/material'
import {
  GridColDef,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro'
import React, {MutableRefObject, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps} from 'react-router-dom'
import {
  ApiSeatState,
  GetOccupancyDataQuery,
  Scalars
} from '../../../__generated__/schema'
import {WideCenteredLayout} from '../../pages/admin/Layout'
import {DataGridTable, useDecimalFormatter} from '../DataGridTable'
import {Menu, MenuItem, useMenu} from '../Menu'
import {RenderOnData} from '../RenderOnData'
import {SubHeaderToolbar} from '../SubHeaderToolbar'
import {Tooltip} from '../Tooltip'
import {useGetOccupancyData} from './graphql'

const useTranslateOccupancyZoneColumnHeader = () => {
  const {t} = useTranslation()
  return (apiSeatState: ApiSeatState) => {
    switch (apiSeatState) {
      case ApiSeatState.Sold:
        return t('Sold', {context: 'Occupancy zone table column header'})
      case ApiSeatState.InOtherReservation:
        return t('Reservation', {context: 'Occupancy zone table column header'})
      case ApiSeatState.Disabled:
        return t('Disabled', {context: 'Occupancy zone table column header'})
      case ApiSeatState.Hidden:
        return t('Hidden', {context: 'Occupancy zone table column header'})
      case ApiSeatState.AddedToOtherCart:
        return t('In cart', {context: 'Occupancy zone table column header'})
      case ApiSeatState.SelectedByOthers:
        return t('Selected', {context: 'Occupancy zone table column header'})
      case ApiSeatState.Available:
        return t('Available', {context: 'Occupancy zone table column header'})
      default:
        return 'Unknown header'
    }
  }
}

interface IContentProps {
  zones: Scalars['JSON']
  layout: object
  zonesDataGridApiRef: MutableRefObject<GridApiPro>
}

export const Content: React.FC<IContentProps> = ({
  zones,
  layout,
  zonesDataGridApiRef
}: IContentProps) => {
  const rows = Object.values(layout)
    .filter((ob) => ob.type === CanvasObjectType.Zone)
    .map((o) => ({
      label: o.config.label,
      id: o.config.id,
      states: zones[o.config.id].states
    }))
  const {t} = useTranslation()
  const translateOccupancyZoneColumnHeader =
    useTranslateOccupancyZoneColumnHeader()
  const decimalFormatter = useDecimalFormatter(0)
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Zone'),
        field: 'label',
        minWidth: 250,
        flex: 1
      },
      ...[
        ApiSeatState.Sold,
        ApiSeatState.InOtherReservation,
        ApiSeatState.Disabled,
        ApiSeatState.Hidden,
        ApiSeatState.AddedToOtherCart,
        ApiSeatState.SelectedByOthers,
        ApiSeatState.Available
      ].map(
        (apiSeatState): GridColDef => ({
          headerName: translateOccupancyZoneColumnHeader(apiSeatState),
          field: apiSeatState,
          align: 'right',
          headerAlign: 'right',
          valueGetter: (params) => params.row.states[apiSeatState],
          valueFormatter: (
            params: GridValueFormatterParams<number | undefined>
          ) => (params.value ? decimalFormatter(params) : 0),
          minWidth: 100,
          flex: 1
        })
      )
    ],
    [decimalFormatter, t, translateOccupancyZoneColumnHeader]
  )
  return (
    <DataGridTable
      apiRef={zonesDataGridApiRef}
      columns={columns}
      rows={rows}
      disableColumnMenu
      localeText={{noRowsLabel: t('No zones to show')}}
      initialState={{pagination: {paginationModel: {pageSize: rows.length}}}}
      hideFooter
    />
  )
}

interface IOccupancyProps
  extends RouteComponentProps<{
    eventId: string
  }> {}

export const Occupancy: React.FC<IOccupancyProps> = (
  props: IOccupancyProps
) => {
  const {t} = useTranslation()
  const eventId = parseInt(props.match.params.eventId, 10)
  const {data, loading, error, refetch} = useGetOccupancyData({eventId})
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const zonesDataGridApiRef = useGridApiRef()
  return (
    <RenderOnData<GetOccupancyDataQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Failed to fetch data for occupancy')}
    >
      {({eventSeats, event}) => (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'grid',
            gridAutoFlow: 'row',
            gridTemplateRows: 'auto 1fr'
          }}
        >
          <SubHeaderToolbar
            title={t('Occupancy')}
            rightActions={[
              <Tooltip
                key="reload"
                title={t('Reload')}
                onClick={() => {
                  refetch()
                }}
              >
                <IconButton>
                  <RefreshOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>,
              <IconButton onClick={openMenu} key="more-icon-button">
                <MoreVertIcon />
              </IconButton>
            ]}
          />
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={closeMenu}
            anchorEl={anchorEl}
            key="menu"
          >
            <MenuItem
              label={t('Download CSV')}
              icon={<GetAppIcon />}
              onClick={() => {
                zonesDataGridApiRef.current.exportDataAsCsv({
                  fileName:
                    t<string>('event-zones-occupancy-{{eventId}}', {
                      eventId
                    }) || `event-zones-occupancy-${eventId}`
                })
                closeMenu()
              }}
            />
          </Menu>
          <WideCenteredLayout
            sx={{py: 3, px: 3, height: '100%', width: '100%'}}
          >
            <Content
              zonesDataGridApiRef={zonesDataGridApiRef}
              layout={event.auditoriumLayout.layout}
              zones={eventSeats.zones}
            />
          </WideCenteredLayout>
        </Box>
      )}
    </RenderOnData>
  )
}
