import {ApolloError} from 'apollo-client'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {useNotifications} from '../../../context/notifications'
import {useCreateVenue} from './graphql'
import {IVenueFormData, VenueForm, venueFromVenueForm} from './VenueForm'

export const AddVenue: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const createVenue = useCreateVenue()

  const onSubmit = useCallback(
    async (data: IVenueFormData) => {
      await createVenue({data: venueFromVenueForm(data)})
      history.goBack()
      addInfoNotification(t('Venue created'))
    },
    [addInfoNotification, createVenue, history, t]
  )

  const onError = useCallback(
    (err: ApolloError) => {
      defaultErrorHandler(err, t('Error while creating venue'))
    },
    [defaultErrorHandler, t]
  )

  return (
    <VenueForm
      title={t('Create venue')}
      onSubmit={onSubmit}
      onError={onError}
      submitText={t('Create')}
    />
  )
}
