import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import {Theme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  CheckTicketsEventFragment,
  CheckTourTimeSlotPropertiesFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {EntranceIcon} from '../../../../editor/TopPanel/Icons'
import {useFormatShortGuideName} from '../../../../hooks/formatUserName'
import {useGetDistributionLabelAbbreviation} from '../../../../hooks/getDistributionLabel'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useTranslateAgeClassificationAbbreviation} from '../../../../hooks/translateAgeClassification'
import {useTranslateShowVersionAbbreviation} from '../../../../hooks/translateDistributions'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {EntityStateChip} from '../../../common'
import {
  CapacityIndicator,
  capacityIndicatorSxProps
} from '../../../common/CapacityIndicator'
import {COLOR_CONF} from '../../../constants'
import {isCheckTicketsEventFragment} from './utils'

const useGetGateStatusColorAndLabel = () => {
  const {t} = useTranslation()
  const {formatTime} = useDateTimeFormatters()

  return (gateOpensAt: string, gateCloseAt: string) => {
    if (dayjs().isAfter(gateCloseAt)) {
      return {color: COLOR_CONF.RED, label: t('Gates closed')}
    }
    if (dayjs().isAfter(gateOpensAt) && dayjs().isBefore(gateCloseAt)) {
      return {color: COLOR_CONF.GREEN, label: t('Gates opened')}
    }
    return {
      color: COLOR_CONF.YELLOW,
      label: t('Opens at {{gateOpensAt}}', {
        gateOpensAt: formatTime(new Date(gateOpensAt))
      })
    }
  }
}

interface IGateStateChipProps {
  eventStartsAt: string
  gateOpensAt: string
  gateCloseAt: string
}

const GateStateChip: React.FC<IGateStateChipProps> = ({
  eventStartsAt,
  gateOpensAt,
  gateCloseAt
}: IGateStateChipProps) => {
  const getGateStatusColorAndLabel = useGetGateStatusColorAndLabel()
  const {label, color} = getGateStatusColorAndLabel(gateOpensAt, gateCloseAt)
  return dayjs(eventStartsAt).isToday() ? (
    <EntityStateChip label={label} colorConf={color} size="small" isDotHidden />
  ) : null
}

const useDesktopStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: 64,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    cursor: 'pointer'
  },
  timeBox: {
    paddingRight: theme.spacing(3)
  },
  detailIconBox: {
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5)
  },
  detailIcon: {
    color: theme.palette.text.secondary,
    width: '1em',
    height: '1em',
    fontSize: '1.5rem'
  },
  eventMainBox: {
    minWidth: 0
  },
  eventMainBoxLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  auditorium: {
    color: theme.palette.text.primary,
    flexShrink: 0,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(1)
  },
  almostSoldOut: {
    color: theme.palette.warning.main,
    backgroundColor: '#FCF3D7'
  },
  soldOut: {
    color: theme.palette.error.main,
    backgroundColor: '#FEE4E2'
  },
  chipBox: {
    flexShrink: 0,
    paddingLeft: theme.spacing(1)
  },
  capacityPriceAndChipBox: {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    columnGap: theme.spacing(2)
  },
  capacityBox: {
    display: 'grid',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min-content, 1fr))'
  },
  startingPrice: {
    lineHeight: '100%'
  }
}))

const useMobileStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    padding: theme.spacing(1, 2),
    cursor: 'pointer'
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

interface IResponsiveEntityItemProps {
  entity: CheckTicketsEventFragment | CheckTourTimeSlotPropertiesFragment
  isPhablet: boolean
  onEntityClick: () => void
  onInfoIconClick?: React.MouseEventHandler
}

export const ResponsiveEntityItem: React.FC<IResponsiveEntityItemProps> = ({
  entity,
  isPhablet,
  onEntityClick,
  onInfoIconClick
}: IResponsiveEntityItemProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {formatTime} = useDateTimeFormatters()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const formatShortGuideName = useFormatShortGuideName()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const desktopClasses = useDesktopStyles()
  const mobileClasses = useMobileStyles()
  const clientLocaleCode = useClientLocaleCode()
  const eventNameInClientLocale =
    entity.names[clientLocaleCode] || Object.values(entity.names)[0]
  const getDistributionLabelAbbreviation = useGetDistributionLabelAbbreviation()
  return isPhablet ? (
    <div className={mobileClasses.root} onClick={onEntityClick}>
      <Typography variant="subtitle2">
        {[formatTime(new Date(entity.startsAt)), eventNameInClientLocale]
          .filter(Boolean)
          .join(' • ')}
      </Typography>
      <div className={mobileClasses.flexRow}>
        <Typography variant="overline" color="textSecondary">
          {isCheckTicketsEventFragment(entity)
            ? entity.auditorium.name
            : entity.venue.name}
        </Typography>
        <GateStateChip
          eventStartsAt={entity.startsAt}
          gateOpensAt={entity.gateOpensAt}
          gateCloseAt={
            isCheckTicketsEventFragment(entity)
              ? entity.gateClosedAt
              : entity.gateClosesAt
          }
        />
      </div>
      <div className={mobileClasses.flexRow}>
        <Typography variant="caption" color="textSecondary">
          {isCheckTicketsEventFragment(entity)
            ? getDistributionLabelAbbreviation({
                formatCode: entity.formatCode,
                soundMixCode: entity.soundMixCode,
                versionCode: entity.versionCode,
                ageClassificationCode: entity.ageClassificationCode
              })
            : [
                entity.guide && formatShortGuideName(entity.guide),
                entity.versionCode &&
                  translateShowVersionAbbreviation(entity.versionCode),
                entity.ageClassificationCode &&
                  translateAgeClassificationAbbreviation(
                    entity.ageClassificationCode
                  )
              ]
                .filter(Boolean)
                .join(' • ')}
        </Typography>
        <CapacityIndicator
          tooltipTitle={t('Check in')}
          countLabel={
            isCheckTicketsEventFragment(entity)
              ? `${entity.uniqueCheckedInCount}/${entity.soldSeatsCount}`
              : `${
                  entity.attendeesCheckedInCount -
                  entity.attendeesCheckedOutCount
                }/${entity.tourItemsStateCounts.sold}`
          }
          customIcon={<EntranceIcon style={{width: 18, height: 18}} />}
        />
      </div>
    </div>
  ) : (
    <div className={desktopClasses.root} onClick={onEntityClick}>
      <div className={desktopClasses.timeBox}>
        <Typography variant="subtitle2">
          {formatTime(new Date(entity.startsAt))}
        </Typography>
      </div>
      <div className={desktopClasses.eventMainBox}>
        <div className={desktopClasses.eventMainBoxLine}>
          <Typography variant="subtitle2" className={desktopClasses.title}>
            {eventNameInClientLocale}
          </Typography>
          <Typography variant="overline" className={desktopClasses.auditorium}>
            {isCheckTicketsEventFragment(entity)
              ? entity.auditorium.name
              : entity.venue.name}
          </Typography>
        </div>
        <div className={desktopClasses.eventMainBoxLine}>
          <div>
            <Typography variant="caption" color="textSecondary">
              {isCheckTicketsEventFragment(entity)
                ? getDistributionLabelAbbreviation({
                    formatCode: entity.formatCode,
                    soundMixCode: entity.soundMixCode,
                    versionCode: entity.versionCode,
                    ageClassificationCode: entity.ageClassificationCode
                  })
                : [
                    entity.guide && formatShortGuideName(entity.guide),
                    entity.versionCode &&
                      translateShowVersionAbbreviation(entity.versionCode),
                    entity.ageClassificationCode &&
                      translateAgeClassificationAbbreviation(
                        entity.ageClassificationCode
                      )
                  ]
                    .filter(Boolean)
                    .join(' • ')}
            </Typography>
          </div>
          <div className={desktopClasses.capacityPriceAndChipBox}>
            <GateStateChip
              eventStartsAt={entity.startsAt}
              gateOpensAt={entity.gateOpensAt}
              gateCloseAt={
                isCheckTicketsEventFragment(entity)
                  ? entity.gateClosedAt
                  : entity.gateClosesAt
              }
            />
            <div className={desktopClasses.capacityBox}>
              <CapacityIndicator
                tooltipTitle={t('Check in')}
                countLabel={
                  isCheckTicketsEventFragment(entity)
                    ? entity.uniqueCheckedInCount.toString()
                    : `${
                        entity.attendeesCheckedInCount -
                        entity.attendeesCheckedOutCount
                      }`
                }
                customIcon={<EntranceIcon style={{width: 18, height: 18}} />}
              />
              <CapacityIndicator
                tooltipTitle={t('Reserved')}
                colorSx={capacityIndicatorSxProps.reservedSpaces}
                countLabel={
                  isCheckTicketsEventFragment(entity)
                    ? entity.reservedSeatsCount.toString()
                    : entity.tourItemsStateCounts.reserved.toString()
                }
                iconSize={18}
              />
              <CapacityIndicator
                tooltipTitle={t('Sold')}
                colorSx={capacityIndicatorSxProps.soldSpaces}
                countLabel={
                  isCheckTicketsEventFragment(entity)
                    ? entity.soldSeatsCount.toString()
                    : entity.tourItemsStateCounts.sold.toString()
                }
                iconSize={18}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={desktopClasses.detailIconBox}>
        {P([PermissionCode.ReadEvent]) && onInfoIconClick && (
          <IconButton onClick={onInfoIconClick}>
            <InfoOutlinedIcon className={desktopClasses.detailIcon} />
          </IconButton>
        )}
      </div>
    </div>
  )
}
