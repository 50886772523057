import {TextField as MuiTextField} from '@mui/material'
import {OutlinedTextFieldProps} from '@mui/material/TextField'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {FormValidationError} from '../common/FormHelpers'
import {FormFieldHelper} from '../visual/common'
import {FormFieldName} from './types'
import {
  cloneInputModeInInputProps,
  getValueInUncontrolledFormComponent,
  useCustomReactHookFormRegistration,
  useHandleBlur,
  useHandleChange,
  useHasError
} from './utils'

export interface ITextFieldProps
  extends Omit<OutlinedTextFieldProps, 'variant' | 'helperText'> {
  maxLength?: number
  error?: OutlinedTextFieldProps['error']
  label?: OutlinedTextFieldProps['label']
  multiline?: OutlinedTextFieldProps['multiline']
  rows?: OutlinedTextFieldProps['rows']
  required?: OutlinedTextFieldProps['required']
}

export const TextField: React.FC<ITextFieldProps> = ({
  maxLength,
  ...rest
}: ITextFieldProps) => (
  <MuiTextField
    {...rest}
    inputProps={{
      maxLength
    }}
  />
)

export interface IUncontrolledFormTextInputProps<
  FormValues extends FieldValues = FieldValues
> extends Omit<ITextFieldProps, 'error' | 'inputRef' | 'type'> {
  errors: FieldErrors<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  triggerValidation: FormContextValues<FormValues>['triggerValidation']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
  helperNote?: string
  type?: 'text' | 'password'
  hideErrorMessage?: boolean
}

export interface IFormTextInputProps<
  FormValues extends FieldValues = FieldValues
> extends IUncontrolledFormTextInputProps<FormValues> {
  unregister: FormContextValues<FormValues>['unregister']
}

/**
 * @deprecated - prefer UncontrolledFormTextInput, performance is better.
 * (FormTextInput is controlled component. In general, performance of uncontrolled form inputs is better)
 */
export const FormTextInput = <FormValues extends FieldValues = FieldValues>({
  name,
  watch,
  errors,
  setValue,
  register,
  unregister,
  validationOptions,
  triggerValidation,
  helperNote,
  ...textFieldProps
}: IFormTextInputProps<FormValues>) => {
  const value = watch(name)
  const hasError = useHasError<FormValues>(errors, name)
  const handleChange = useHandleChange<FormValues>({
    hasError,
    name,
    setValue
  })
  const handleBlur = useHandleBlur<FormValues>({name, triggerValidation})
  useCustomReactHookFormRegistration<FormValues>({
    name,
    register,
    unregister,
    validationOptions
  })
  return (
    <>
      <TextField
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        error={hasError}
        {...textFieldProps}
      />
      {!hasError && (
        <FormFieldHelper required={textFieldProps.required} note={helperNote} />
      )}
      <FormValidationError<FormValues> errors={errors} fieldName={name} />
    </>
  )
}

const useUncontrolledFormTextInputStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  }
}))

export const UncontrolledFormTextInput = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  watch,
  errors,
  setValue,
  register,
  validationOptions,
  triggerValidation,
  helperNote,
  defaultValue,
  InputProps,
  hideErrorMessage,
  ...textFieldProps
}: IUncontrolledFormTextInputProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({name, defaultValue, watch})
  const hasError = useHasError<FormValues>(errors, name)
  const handleChange = useHandleChange<FormValues>({
    hasError,
    name,
    setValue
  })
  const handleBlur = useHandleBlur<FormValues>({name, triggerValidation})
  const classes = useUncontrolledFormTextInputStyles()
  return (
    <>
      <textarea
        name={name}
        ref={validationOptions ? register(validationOptions) : register()}
        className={classes.hidden}
        defaultValue={defaultValue as string}
      />
      <TextField
        onChange={handleChange}
        cypress-id={name}
        onBlur={handleBlur}
        value={value}
        error={hasError}
        InputProps={cloneInputModeInInputProps(
          InputProps,
          textFieldProps.inputMode
        )}
        {...textFieldProps}
      />
      {hasError && !hideErrorMessage ? (
        <FormValidationError<FormValues> errors={errors} fieldName={name} />
      ) : (
        <FormFieldHelper required={textFieldProps.required} note={helperNote} />
      )}
    </>
  )
}
