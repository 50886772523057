import {Box} from '@mui/material'
import {GridCellParams, gridClasses, GridColDef} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import dayjs from 'dayjs'
import {isNil} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientVatRegistered,
  PermissionCode,
  ProductsStatisticsFilterInput,
  ProductTypeState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useRequireEffectiveClient} from '../../../../hooks/requireEffectiveClient'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Tooltip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useEffectiveClientPriceFormatter,
  usePercentageFormatter,
  useUnitFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {useLocale} from '../../../context/locale'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {DivisionChip} from '../components/DivisionChip'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {useGetLightweightProductTypes} from '../graphql'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {
  useCreateProductStatisticsCsvLink,
  useProductsStatistics
} from './graphql'
import {MoreMenu} from './MoreMenu'

const getCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''

interface IProductsStatisticsPageProps {
  searchFilter: ProductsStatisticsFilterInput
}

export const ProductsStatisticsPage: React.FC<IProductsStatisticsPageProps> = ({
  searchFilter
}: IProductsStatisticsPageProps) => {
  const {t} = useTranslation()
  const effectiveClient = useRequireEffectiveClient()
  const {P} = useEnsurePermissions()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {localeCode} = useLocale()
  const [productTypeId, setProductTypeId] =
    useState<number | undefined>(undefined)
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const [divisionId, setDivisionId] = useState<number | null>(null)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.Today
    }
  )
  const {resetPaginationModel, paginationInput, getDataGridPaginationProps} =
    useDataGridPagination()
  const getFilterDateRange = useGetFilterDateRange()
  const unitFormatter = useUnitFormatter()
  const percentageFormatter = usePercentageFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter(false)
  const filter = useMemo(
    () => ({
      ...searchFilter,
      productName: searchFilter.productName || undefined,
      productTypeIds: productTypeId ? [productTypeId] : undefined,
      divisionIds: divisionId ? [divisionId] : undefined,
      paymentCreatedByIds: selectedUserId ? [selectedUserId] : undefined,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'paymentDateTimeFrom',
        filterNameTo: 'paymentDateTimeTo'
      })
    }),
    [
      divisionId,
      getFilterDateRange,
      productTypeId,
      searchFilter,
      selectedDate,
      selectedUserId
    ]
  )
  const {data, loading, error, refetch} = useProductsStatistics({
    filter,
    paginationInput
  })
  const {data: productTypesData} = useGetLightweightProductTypes(
    ProductTypeState.Active
  )
  const isClientVatPayer =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDivisionChange = useCallback(
    (divisionId: number | null) => {
      setDivisionId(divisionId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleUserChange = useCallback(
    (userId: number | null) => {
      setSelectedUserId(userId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Product name'),
        field: 'productName',
        minWidth: 300,
        sortable: false,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Internal code'),
        field: 'productInternalCode',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Type'),
        field: 'productTypeName',
        minWidth: 250,
        sortable: false
      },
      {
        headerName: t('Quantity'),
        field: 'revenueItemQuantity',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Unit'),
        field: 'unit',
        minWidth: 100,
        valueFormatter: unitFormatter,
        sortable: false
      },
      {
        headerName: t('VAT rate'),
        field: 'vatRate',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: percentageFormatter,
        sortable: false
      },
      {
        headerName: t('VAT excluded'),
        field: 'unitPriceVatExcluded',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false
      },
      {
        headerName: t('VAT'),
        field: 'unitVat',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false
      },
      {
        headerName: t('Price'),
        field: 'unitPriceVatIncluded',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false
      },
      {
        headerName: t('VAT excluded'),
        field: 'revenueVatExcluded',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false,
        cellClassName: getCellClassName
      },
      {
        headerName: t('VAT'),
        field: 'revenueVat',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Total'),
        field: 'revenueVatIncluded',
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false,
        cellClassName: getCellClassName
      }
    ],
    [effectiveClientPriceFormatter, percentageFormatter, t, unitFormatter]
  )
  const createProductStatisticsCsvLink = useCreateProductStatisticsCsvLink()
  const handleStatisticsDownload = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await createProductStatisticsCsvLink({filter, localeCode})
      if (data) {
        window.open(data.createProductsStatisticsCsvLink, '_blank')
      }
    } catch (e) {
      defaultErrorHandler(e, t('Generating csv failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    createProductStatisticsCsvLink,
    defaultErrorHandler,
    filter,
    localeCode,
    setShowBackdrop,
    t
  ])
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading products statistics')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          P([PermissionCode.ReadProductTypes]) && (
            <ChipWithOptions<number>
              key="product-type-chip"
              selectedItem={productTypeId}
              setSelectedItem={setProductTypeId}
              options={(productTypesData?.productTypes || []).map(
                ({id, name}) => ({
                  label: name,
                  option: id
                })
              )}
              allText={t('All product types')}
              size="small"
            />
          ),
          P([PermissionCode.ReadUsers]) && (
            <UserChip key="user-chip" onChange={handleUserChange} />
          ),
          <DivisionChip key="division-chip" onChange={handleDivisionChange} />
        ]}
        rightActions={[
          <DatePickerButton
            key="payment-date-picker"
            tooltip={t('Payment date')}
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.Today
            }}
            groups={[TabGroup.Now, TabGroup.Past]}
            excludedValues={[TabNow.UntilToday, TabNow.FromToday]}
            minDateFrom={
              selectedDate?.dateRange?.dateTo
                ? dayjs(selectedDate?.dateRange?.dateTo)
                    .subtract(1, 'year')
                    .startOf('d')
                : dayjs().subtract(1, 'year').startOf('d')
            }
            maxDateFrom={selectedDate?.dateRange?.dateTo || dayjs().endOf('d')}
            maxDateTo={dayjs().endOf('d')}
          />,
          <Tooltip key="reload-button" title={t('Reload')}>
            <ReloadButton onReloadButtonClick={() => refetch()} />
          </Tooltip>,
          <MoreMenu
            key="more-menu"
            onDownloadButtonClick={handleStatisticsDownload}
          />
        ]}
      />
      <WideCenteredLayout
        sx={{
          py: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            },
            '& .bold': {fontWeight: 'bold'}
          }}
          columns={columns}
          loading={loading}
          rows={data?.productsStatistics.items || []}
          localeText={{noRowsLabel: t('No products statistics to show')}}
          disableColumnFilter
          disableRowSelectionOnClick
          columnHeaderHeight={32}
          experimentalFeatures={{columnGrouping: true}}
          initialState={{
            columns: {
              columnVisibilityModel: {
                unitVat: isClientVatPayer,
                vatRate: isClientVatPayer,
                revenueVatExcluded: isClientVatPayer,
                revenueVat: isClientVatPayer,
                unitPriceVatExcluded: isClientVatPayer
              }
            },
            pinnedColumns: {
              left: ['productName'],
              right: ['revenueVatIncluded']
            }
          }}
          columnGroupingModel={[
            {
              groupId: t('Product'),
              children: [
                {field: 'productName'},
                {field: 'productInternalCode'},
                {field: 'productTypeName'},
                {field: 'revenueItemQuantity'},
                {field: 'unit'},
                {field: 'vatRate'}
              ]
            },
            {
              groupId: t('Unit price'),
              children: [
                {field: 'unitPriceVatExcluded'},
                {field: 'unitVat'},
                {field: 'unitPriceVatIncluded'}
              ]
            },
            {
              groupId: t('Revenue'),
              children: [
                {field: 'revenueVatExcluded'},
                {field: 'revenueVat'},
                {field: 'revenueVatIncluded'}
              ]
            }
          ]}
          pinnedRows={{
            bottom: [
              {
                id: 0,
                productName: t('Summary'),
                revenueVatExcluded:
                  data?.productsStatistics.totals.revenueVatExcluded,
                revenueVat: data?.productsStatistics.totals.revenueVat,
                revenueVatIncluded:
                  data?.productsStatistics.totals.revenueVatIncluded
              }
            ]
          }}
          {...getDataGridPaginationProps(data?.productsStatistics.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}
