import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateCustomerGroupDrawer} from './CreateCustomerGroupDrawer'
import {CustomerGroupsList} from './CustomerGroupsList'
import {UpdateCustomerGroupDrawer} from './UpdateCustomerGroupDrawer'

export const CustomerGroupsPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateCustomerGroupDrawerOpen,
    setTrue: openCreateCustomerGroupDrawer,
    setFalse: closeCreateCustomerGroupDrawer
  } = useBooleanState(false)
  const history = useHistory()
  const handleExited = useCallback(
    () => history.replace(routeTo.admin.customerGroups.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Customer groups')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <CustomerGroupsList
          onCreateButtonClick={openCreateCustomerGroupDrawer}
        />
        <CreateCustomerGroupDrawer
          isOpen={isCreateCustomerGroupDrawerOpen}
          onClose={closeCreateCustomerGroupDrawer}
        />
        {P([
          PermissionCode.UpdateCustomerGroup,
          PermissionCode.ReadCustomerGroup
        ]) && (
          <Route
            path={routeTo.admin.customerGroups.edit(':customerGroupId')}
            exact
          >
            <UpdateCustomerGroupDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
