import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getUrlParam, routeTo} from '../../../../utils/routes'
import {
  BottomBackButton,
  RenderOnData,
  SingleSideNavigationList
} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {useNotifications} from '../../../context/notifications'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {DIVISION_FORM_ID, DivisionForm} from './divisionForm/DivisionForm'
import {useDivisionFormAnchors} from './divisionForm/divisionFormAnchors'

import {
  getDivisionInputFromFormData,
  getFormDataFromDivision,
  IDivisionFormData
} from './divisionForm/types'
import {useGetDivision, useUpdateDivision} from './graphql'
import {StateActionsBar} from './StateActionsBar'

export const EditDivision: React.FC<RouteComponentProps> = ({
  history,
  match
}: RouteComponentProps) => {
  const updateDivision = useUpdateDivision()
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const divisionFormAnchors = useDivisionFormAnchors()
  const {P} = useEnsurePermissions()
  const canEdit = !!P([PermissionCode.UpdateDivision])

  const divisionId = parseInt(getUrlParam(match, 'id'), 10)
  const {error, loading, data} = useGetDivision(divisionId)

  const onSubmit = useCallback(
    async (formData: IDivisionFormData) => {
      if (data && data.division) {
        const division = data.division
        await updateDivision({
          id: division.id,
          data: getDivisionInputFromFormData(formData)
        })
        history.push(routeTo.admin.divisions.home())
        addInfoNotification(t('Division updated'))
      }
    },
    [addInfoNotification, data, history, t, updateDivision]
  )

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader hasArrowBackIcon title={t('Division')} />}
      footer={
        <Footer>
          <BottomBackButton />
          {canEdit && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form={DIVISION_FORM_ID}
            >
              {t('Save')}
            </Button>
          )}
        </Footer>
      }
    >
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage his divisions.')}
      >
        <RenderOnData
          data={data}
          error={error}
          loading={loading}
          errorMessage={t<string>('Could not load division')}
        >
          {(data) => (
            <CenteredLayout>
              <SingleSideNavigationList items={divisionFormAnchors} />
              <CenteredLayoutListWrapper>
                <StateActionsBar division={data.division} />
                <DivisionForm
                  defaultValues={getFormDataFromDivision(data.division)}
                  onSubmit={onSubmit}
                  submitErrorMessage={t('Error while updating division')}
                />
              </CenteredLayoutListWrapper>
            </CenteredLayout>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
