import {useLazyQuery, useMutation} from '@apollo/react-hooks'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import cn from 'classnames'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CheckPassCodeInMutation,
  CheckPassCodeInMutationVariables,
  CheckPassCodeOutMutation,
  CheckPassCodeOutMutationVariables,
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery,
  ItemByPassCodeQuery,
  ItemByPassCodeQueryVariables,
  PassCodeCheckInputType,
  PassCodeCheckPropertiesFragment,
  PassCodeCheckState,
  UpdatePassCodeCheckMutation,
  UpdatePassCodeCheckMutationVariables
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {useCheckPassCodeParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {
  HtmlQrcodePlugin,
  IHtmlQrcodePluginForwardedRef
} from '../../../../html5qrcode/HtmlQrcodePlugin'
import {useAdvancedSettings} from '../AdvancedSettingsContext'
import {getPauseAfterScanValue} from '../advancedSettingsPage/pauseAfterScanUtils'
import {useCheckMode} from '../CheckModeContext'
import {
  usePerformActionRequiredEffect,
  usePerformFailedEffect,
  usePerformSuccessEffect
} from '../deviceEffects'
import {
  AnCheckTicketsDrawerClose,
  AnCheckTicketsDrawerOpen,
  CheckingMethod,
  CheckMode,
  DeviceEffect
} from '../types'
import {
  isGetCheckEventQuery,
  isGetCheckTourQuery,
  isGetCheckTourTimeSlotQuery,
  isGetCheckVenueQuery
} from '../utils'
import {
  CHECK_PASS_CODE_IN,
  CHECK_PASS_CODE_OUT,
  ITEM_BY_PASS_CODE,
  UPDATE_PASS_CODE_CHECK
} from './graphql'
import {InfoModalController} from './InfoModalController'
import {InfoModeModal} from './InfoModeModal'
import {TypeEntry} from './TypeEntry'

const QRBOX = {width: 328, height: 218}

const useGetItemFilterBasedOnData = () =>
  useCallback(
    (
      data:
        | GetCheckEventQuery
        | GetCheckTourTimeSlotQuery
        | GetCheckTourQuery
        | GetCheckVenueQuery
    ) => {
      if (isGetCheckEventQuery(data)) {
        return {eventIds: [data.event.id]}
      }
      if (isGetCheckTourTimeSlotQuery(data)) {
        return {tourTimeSlotIds: [data.tourTimeSlot.id]}
      }
      if (isGetCheckTourQuery(data)) {
        return {tourIds: [data.tour.id]}
      }
      if (isGetCheckVenueQuery(data)) {
        return {venueIds: [data.venue.id]}
      }
      return undefined
    },
    []
  )

interface ICodeEntryProps {
  className?: string
  inputType: PassCodeCheckInputType
  entityData:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    height: '100%'
  },
  infoModal: {
    position: 'absolute',
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(1)
  }
}))

export const CodeEntry: React.FC<ICodeEntryProps> = ({
  className,
  inputType,
  entityData
}: ICodeEntryProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const getItemFilterBasedOnData = useGetItemFilterBasedOnData()
  const {checkType, entityId} = useCheckPassCodeParams()
  const {
    state: isCheckButtonDisabled,
    setFalse: enableCheckButton,
    setTrue: disableCheckButton
  } = useBooleanState(false)
  const htmlQrCodeRef = useRef<IHtmlQrcodePluginForwardedRef>(null)
  const history = useHistory()
  const [infoModeModalConfig, setInfoModeModalConfig] = useState<{
    item: ItemByPassCodeQuery['itemByPassCode'] | null
    error: any | null
  }>({item: null, error: null})
  const {
    settings: {
      discountAuthorization,
      pauseAfterScan,
      cameraId,
      allowedDeviceEffect = DeviceEffect.Sound,
      deniedDeviceEffect = DeviceEffect.Sound,
      actionRequiredDeviceEffect = DeviceEffect.Sound,
      checkingMethod = CheckingMethod.SingleTicket
    }
  } = useAdvancedSettings()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const performSuccessEffect = usePerformSuccessEffect(allowedDeviceEffect)
  const performFailedEffect = usePerformFailedEffect(deniedDeviceEffect)
  const performActionRequiredEffect = usePerformActionRequiredEffect(
    actionRequiredDeviceEffect
  )
  const performEffect = useCallback(
    (state: PassCodeCheckState) => {
      switch (state) {
        case PassCodeCheckState.Allowed:
          return performSuccessEffect()
        case PassCodeCheckState.Pending:
          return performActionRequiredEffect()
        case PassCodeCheckState.Denied:
        default:
          return performFailedEffect()
      }
    },
    [performActionRequiredEffect, performFailedEffect, performSuccessEffect]
  )
  const {checkMode: defaultCheckMode} = useCheckMode()
  const enableCheckButtonWithDebounce = useMemo(
    () => debounce(enableCheckButton, 100),
    [enableCheckButton]
  )
  const [checkPassCodeInMutation] =
    useMutation<CheckPassCodeInMutation, CheckPassCodeInMutationVariables>(
      CHECK_PASS_CODE_IN
    )
  const [checkPassCodeOutMutation] =
    useMutation<CheckPassCodeOutMutation, CheckPassCodeOutMutationVariables>(
      CHECK_PASS_CODE_OUT
    )
  const [updatePassCodeCheckMutation] = useMutation<
    UpdatePassCodeCheckMutation,
    UpdatePassCodeCheckMutationVariables
  >(UPDATE_PASS_CODE_CHECK)
  const [getItemByPassCode, {loading: itemByPassCodeLoading}] = useLazyQuery<
    ItemByPassCodeQuery,
    ItemByPassCodeQueryVariables
  >(ITEM_BY_PASS_CODE, {
    onError: (error) => {
      if (error.graphQLErrors) {
        setInfoModeModalConfig({item: null, error: error.graphQLErrors[0]})
      } else {
        defaultErrorHandler(error, t('Error while loading item by pass code'))
      }
      htmlQrCodeRef.current?.resume()
      enableCheckButtonWithDebounce()
    },
    onCompleted: (data) => {
      setInfoModeModalConfig({item: data.itemByPassCode, error: null})
      htmlQrCodeRef.current?.resume()
      enableCheckButtonWithDebounce()
    },
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    if (itemByPassCodeLoading) {
      setShowBackdrop(true)
    } else {
      setShowBackdrop(false)
    }
  }, [itemByPassCodeLoading, setShowBackdrop])
  const [infoModalConfig, setInfoModalConfig] = useState<{
    passCode: string | null
    passCodeCheck: PassCodeCheckPropertiesFragment | null
    error: any | null
    checkMode: CheckMode | null
  }>({
    passCode: null,
    passCodeCheck: null,
    error: null,
    checkMode: null
  })
  const handleError = useCallback(
    (e: ApolloError, passCode: string, defaultErrorHandlerMessage: string) => {
      if (e.graphQLErrors) {
        setInfoModalConfig({
          passCode,
          passCodeCheck: null,
          checkMode: null,
          error: e.graphQLErrors[0]
        })
      } else {
        defaultErrorHandler(e, defaultErrorHandlerMessage)
      }
    },
    [defaultErrorHandler]
  )
  const checkPassCodeIn = useCallback(
    async (passCode: string) => {
      try {
        disableCheckButton()
        setShowBackdrop(true)
        const {data} = await checkPassCodeInMutation({
          variables: {
            passCode,
            inputType,
            itemFilter: getItemFilterBasedOnData(entityData),
            discountAuthorizationMode: discountAuthorization
          }
        })
        if (data) {
          setInfoModalConfig({
            passCode,
            passCodeCheck: data.checkPassCodeIn,
            error: null,
            checkMode: CheckMode.CheckIn
          })
          if (data.checkPassCodeIn.state !== PassCodeCheckState.Pending) {
            htmlQrCodeRef.current?.resume()
          }
          performEffect(data.checkPassCodeIn.state)
        }
      } catch (e) {
        handleError(e, passCode, t('Check in failed'))
        htmlQrCodeRef.current?.resume()
        performFailedEffect()
      } finally {
        setShowBackdrop(false)
        enableCheckButtonWithDebounce()
      }
    },
    [
      checkPassCodeInMutation,
      disableCheckButton,
      discountAuthorization,
      enableCheckButtonWithDebounce,
      entityData,
      getItemFilterBasedOnData,
      handleError,
      inputType,
      performEffect,
      performFailedEffect,
      setShowBackdrop,
      t
    ]
  )
  const checkPassCodeOut = useCallback(
    async (passCode: string) => {
      try {
        disableCheckButton()
        const {data} = await checkPassCodeOutMutation({
          variables: {
            passCode,
            inputType,
            itemFilter: getItemFilterBasedOnData(entityData)
          }
        })
        if (data) {
          setInfoModalConfig({
            passCode,
            passCodeCheck: data.checkPassCodeOut,
            error: null,
            checkMode: CheckMode.CheckOut
          })
          performEffect(data.checkPassCodeOut.state)
        }
      } catch (e) {
        handleError(e, passCode, t('Check out failed'))
        performFailedEffect()
      } finally {
        htmlQrCodeRef.current?.resume()
        setShowBackdrop(false)
        enableCheckButtonWithDebounce()
      }
    },
    [
      checkPassCodeOutMutation,
      disableCheckButton,
      enableCheckButtonWithDebounce,
      entityData,
      getItemFilterBasedOnData,
      handleError,
      inputType,
      performEffect,
      performFailedEffect,
      setShowBackdrop,
      t
    ]
  )
  const handleCodeSubmit = useCallback(
    (passCode: string) => {
      const upperCasedPassCode = passCode.toUpperCase()
      htmlQrCodeRef.current?.pause()
      if (
        checkingMethod === CheckingMethod.SingleTicket ||
        defaultCheckMode === CheckMode.Info
      ) {
        if (defaultCheckMode === CheckMode.CheckIn) {
          checkPassCodeIn(upperCasedPassCode)
        } else if (defaultCheckMode === CheckMode.CheckOut) {
          checkPassCodeOut(upperCasedPassCode)
        } else if (defaultCheckMode === CheckMode.Bidirectional) {
          setInfoModalConfig({
            passCode: upperCasedPassCode,
            passCodeCheck: null,
            error: null,
            checkMode: null
          })
          performActionRequiredEffect()
        } else if (defaultCheckMode === CheckMode.Info) {
          disableCheckButton()
          getItemByPassCode({
            variables: {
              passCode: upperCasedPassCode,
              itemFilter: getItemFilterBasedOnData(entityData)
            }
          })
        } else {
          htmlQrCodeRef.current?.resume()
          // eslint-disable-next-line no-console
          console.log(
            `Unimplemented mode ${defaultCheckMode}, passCode: ${upperCasedPassCode}`
          )
        }
      } else if (checkingMethod === CheckingMethod.WholeSaleAtOnce) {
        history.push(
          routeTo.admin.checkTickets.bulkCheck(
            checkType,
            entityId,
            upperCasedPassCode
          )
        )
      } else {
        htmlQrCodeRef.current?.resume()
        // eslint-disable-next-line no-console
        console.log(
          `Unimplemented checking method ${checkingMethod}, passCode: ${upperCasedPassCode}`
        )
      }
    },
    [
      checkPassCodeIn,
      checkPassCodeOut,
      checkType,
      checkingMethod,
      defaultCheckMode,
      disableCheckButton,
      entityData,
      entityId,
      getItemByPassCode,
      getItemFilterBasedOnData,
      history,
      performActionRequiredEffect
    ]
  )
  const createUpdatePassCodeCheckHandler = useCallback(
    ({
        authorizeDiscount,
        passCode,
        id
      }: {
        authorizeDiscount: boolean
        passCode: string
        id: number
      }) =>
      async () => {
        try {
          const {data} = await updatePassCodeCheckMutation({
            variables: {
              id,
              authorizeDiscount
            }
          })
          if (data) {
            setInfoModalConfig({
              passCode,
              passCodeCheck: data.updatePassCodeCheck,
              error: null,
              checkMode: CheckMode.CheckIn
            })
            performEffect(data.updatePassCodeCheck.state)
          }
        } catch (e) {
          handleError(e, passCode, t('Error while verifying ticket'))
          performFailedEffect()
        } finally {
          setShowBackdrop(false)
          htmlQrCodeRef.current?.resume()
        }
      },
    [
      handleError,
      performEffect,
      performFailedEffect,
      setShowBackdrop,
      t,
      updatePassCodeCheckMutation
    ]
  )
  const handleDrawerOpen = useCallback(() => {
    htmlQrCodeRef.current?.pause()
  }, [])
  const handleDrawerClose = useCallback(() => {
    htmlQrCodeRef.current?.resume()
  }, [])
  useEffect(() => {
    window.addEventListener(AnCheckTicketsDrawerOpen, handleDrawerOpen)
    window.addEventListener(AnCheckTicketsDrawerClose, handleDrawerClose)
    return () => {
      window.removeEventListener(AnCheckTicketsDrawerOpen, handleDrawerOpen)
      window.removeEventListener(AnCheckTicketsDrawerClose, handleDrawerClose)
    }
  }, [handleDrawerClose, handleDrawerOpen])
  return (
    <div className={cn(className, classes.root)}>
      {inputType === PassCodeCheckInputType.Scanned ? (
        <HtmlQrcodePlugin
          ref={htmlQrCodeRef}
          qrcodeRegionId={`html5qr-code-full-region-${defaultCheckMode}`}
          qrbox={QRBOX}
          waitPeriod={getPauseAfterScanValue(pauseAfterScan)}
          onNavigateToSettingsButtonClick={() => {
            history.push(
              `${routeTo.admin.checkTickets.advancedSettings()}#deviceSettings`
            )
          }}
          onCodeScanned={handleCodeSubmit}
          defaultCameraId={cameraId}
        />
      ) : (
        <TypeEntry
          onCodeSubmit={handleCodeSubmit}
          isCheckButtonDisabled={isCheckButtonDisabled}
        />
      )}
      {defaultCheckMode === CheckMode.Info ? (
        <InfoModeModal {...infoModeModalConfig} />
      ) : (
        <InfoModalController
          className={classes.infoModal}
          checkPassCodeOut={checkPassCodeOut}
          checkPassCodeIn={checkPassCodeIn}
          createUpdatePassCodeCheckHandler={createUpdatePassCodeCheckHandler}
          {...infoModalConfig}
        />
      )}
    </div>
  )
}
