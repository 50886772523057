import {useLazyQuery} from '@apollo/react-hooks'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ApiSeatState,
  AuditoriumLayoutType,
  AuditoriumPreviewFieldsFragment,
  ErrorMessages,
  EventTicketType,
  LatestEventSeatTicketItemQuery,
  LatestEventSeatTicketItemQueryVariables,
  PermissionCode,
  RemoveTicketItemFromCartMutationVariables,
  Scalars,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {
  isCustomerDisplayEnabled,
  usePostCustomerDisplayMessage
} from '../../../../../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../../../../../customerDisplayBroadcastChannel/types'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {Theme} from '../../../../../theme'
import {ZonePlanView} from '../../../../../types'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {getFeSeatStateInEventAuditoriumPreview} from '../../../../../utils/getFeSeatState'
import {getFeZoneStateInEventAuditoriumPreview} from '../../../../../utils/getFeZoneState'
import {
  useGetSeatTooltipLabel,
  useGetZoneTooltipLabel,
  useLayoutObjectTooltip
} from '../../../../../utils/layoutObjectTooltip'
import {safeSum} from '../../../../../utils/money'
import {routeTo} from '../../../../../utils/routes'
import {
  AuditoriumLayoutPreview,
  useFeSeatStatesByUuid,
  useFeZoneStatesByUuid,
  useGetTicketTypeForUuid,
  useLayout,
  useTicketTypesByTicketTypeId
} from '../../../../common/auditoriumLayoutPreview'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {Blank} from '../../../../visual/Blank'
import {useGetBasicTicketListItemProps} from '../../components/BasicTicketListItem'
import {TicketsSubtotal} from '../../components/TicketsSubtotal'
import {isTicketItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {
  LATEST_EVENT_SEAT_TICKET_ITEM,
  useAddSeatingTicketItemToCart,
  useDecrementZoneTicketItemQuantity,
  useIncrementZoneTicketItemQuantity,
  useRemoveAllTicketItemsFromCartForEvent,
  useRemoveTicketItemFromCart,
  useUpdateSeatingTicketItemsQuantityInCart
} from '../graphql'
import {OccupiedSeatsLimitReachedErrorDialog} from '../OccupiedSeatsLimitReachedErrorDialog'
import {ReservedSeatDialog} from '../ReservedSeatDialog'
import {SoldSeatDialog} from '../SoldSeatDialog'
import {TicketListItemWithRemoveButton} from '../TicketListItem'
import {EditZoneTicketQuantityDialog} from './EditZoneTicketQuantityDialog'
import {SubCartHeader} from './SubCartHeader'
import {SubHeader} from './SubHeader'
import {ZoneListView} from './ZoneListView'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "subCart subHeader"
      "subCart auditoriumLayoutPreview"
    `,
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '360px 1fr',
    height: '100%'
  },
  subHeader: {
    gridArea: 'subHeader'
  },
  subCart: {
    gridArea: 'subCart',
    background: theme.palette.background.paper,
    borderRight: `solid ${theme.palette.divider} 1px`,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto'
  },
  auditoriumLayoutPreview: {
    gridArea: 'auditoriumLayoutPreview'
  },
  listDivider: {
    margin: theme.spacing(0, 2)
  },
  listItem: {
    padding: theme.spacing(0, 2, 0, 0.5)
  },
  zoneListView: {
    padding: theme.spacing(3, 3)
  }
}))

interface IEventAuditoriumPreviewContentProps {
  event: AuditoriumPreviewFieldsFragment
  eventSeats: Scalars['JSON']
  interruptSubscription: () => void
  resetSubscription: (cartId: null | number) => void
}

export const Content: React.FC<IEventAuditoriumPreviewContentProps> = ({
  event,
  eventSeats,
  interruptSubscription,
  resetSubscription
}: IEventAuditoriumPreviewContentProps) => {
  const classes = useStyles()
  const {P} = useEnsurePermissions()
  const addSeatingTicketItemToCart = useAddSeatingTicketItemToCart()
  const incrementZoneTicketItemQuantity = useIncrementZoneTicketItemQuantity()
  const decrementZoneTicketItemQuantity = useDecrementZoneTicketItemQuantity()
  const removeTicketItemFromCart = useRemoveTicketItemFromCart()
  const removeAllTicketItemsFromCartForEvent =
    useRemoveAllTicketItemsFromCartForEvent()
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()

  useEffect(() => {
    if (isCustomerDisplayEnabled()) {
      postCustomerDisplayMessage({
        type: CustomerDisplayMessageType.DisplayEventAuditoriumView,
        payload: {
          event,
          eventSeats
        }
      })
    }
  }, [event, eventSeats, postCustomerDisplayMessage])

  const {currentCartId, currentCart, updateCurrentCart, initializeCurrentCart} =
    useCurrentCart()
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()

  const [uuidOfOpenedZone, setUuidOfOpenedZone] = useState<string | null>(null)
  const {
    state: isOccupiedSeatsLimitReachedDialogOpen,
    setTrue: openOccupiedSeatsLimitReachedDialog,
    setFalse: closeOccupiedSeatsLimitReachedDialog
  } = useBooleanState(false)

  const closeZoneDialog = useCallback(() => {
    setUuidOfOpenedZone(null)
  }, [])

  const _removeTicketItemFromCart = useCallback(
    async (variables: RemoveTicketItemFromCartMutationVariables) => {
      try {
        setShowBackdrop(true)
        const {data} = await removeTicketItemFromCart(variables)
        if (data) {
          updateCurrentCart()
        }
      } catch (error) {
        defaultErrorHandler(error, t('Removing ticket item failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      defaultErrorHandler,
      removeTicketItemFromCart,
      updateCurrentCart,
      setShowBackdrop,
      t
    ]
  )

  const {
    state: isSeatDialogOpen,
    setTrue: openSeatDialog,
    setFalse: closeSeatDialog
  } = useBooleanState(false)

  const getOnAvailableSeatClickHandler = useCallback(
    (uuid: string) => async () => {
      try {
        setShowBackdrop(true)
        if (!currentCartId) {
          interruptSubscription()
        }
        const {data} = await addSeatingTicketItemToCart({
          eventId: event.id,
          cartId: currentCartId,
          eventSeatUUID: uuid
        })
        if (data) {
          if (!currentCartId) {
            resetSubscription(data.addSeatingTicketItemToCart.id)
            initializeCurrentCart(data.addSeatingTicketItemToCart)
          } else {
            updateCurrentCart()
          }
        }
      } catch (error) {
        if (!currentCartId) {
          resetSubscription(null)
        }
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.EventSeatNotAvailable
          )
        ) {
          defaultErrorHandler(error, t('Seat is not available'))
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          openOccupiedSeatsLimitReachedDialog()
        } else {
          defaultErrorHandler(error, t('Adding seating ticket item failed'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      currentCartId,
      addSeatingTicketItemToCart,
      event.id,
      interruptSubscription,
      resetSubscription,
      initializeCurrentCart,
      updateCurrentCart,
      defaultErrorHandler,
      t,
      openOccupiedSeatsLimitReachedDialog
    ]
  )

  const getOnSeatInMyCartClickHandler = useCallback(
    (uuid: string, eventSeatId: number) => async () => {
      if (currentCart) {
        const itemId = (currentCart.items || [])
          .filter(isTicketItemPropertiesFragment)
          .find((item) => item.eventSeat.id === eventSeatId)?.id
        if (itemId) {
          await _removeTicketItemFromCart({
            cartId: currentCart.id,
            itemId
          })
        }
      }
    },
    [_removeTicketItemFromCart, currentCart]
  )

  const removeZoneTicketsFromCart = useCallback(
    (uuid: string, decrement: number) =>
      decrementZoneTicketItemQuantity({
        eventId: event.id,
        cartId: currentCartId!,
        eventSeatUUID: uuid,
        decrement
      }),
    [decrementZoneTicketItemQuantity, event.id, currentCartId]
  )

  const createRemoveTicketItemHandler = useCallback(
    (itemId: number) => async () => {
      if (currentCartId) {
        await _removeTicketItemFromCart({
          itemId,
          cartId: currentCartId
        })
      }
    },
    [_removeTicketItemFromCart, currentCartId]
  )

  const handleRemoveAllEventItemsClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await removeAllTicketItemsFromCartForEvent({
        eventId: event.id,
        cartId: currentCartId!
      })
      if (data?.removeAllTicketItemsFromCartForEvent) {
        updateCurrentCart()
      } else {
        initializeCurrentCart(null)
      }
    } finally {
      setShowBackdrop(false)
    }
  }, [
    currentCartId,
    event.id,
    removeAllTicketItemsFromCartForEvent,
    initializeCurrentCart,
    setShowBackdrop,
    updateCurrentCart
  ])
  const getBasicTicketListItemProps = useGetBasicTicketListItemProps()
  const eventTicketItems = (currentCart?.items || [])
    .filter<TicketItemPropertiesFragment>(isTicketItemPropertiesFragment)
    .filter((item) => item.eventSeat?.event.id === event.id)
    .filter((item) => item.reservation === null)

  const ticketTypesByTicketTypeId: {[id: string]: EventTicketType} =
    useTicketTypesByTicketTypeId(event)

  const layout = useLayout(event, ticketTypesByTicketTypeId)

  const getTicketTypeForUuid = useGetTicketTypeForUuid(
    layout,
    ticketTypesByTicketTypeId
  )

  const displayZoneTicketsAddedToCartNotification = useCallback(
    ({
      insertedTicketsCount,
      expectedTicketsCount
    }: {
      insertedTicketsCount: number
      expectedTicketsCount: number
    }) => {
      if (insertedTicketsCount === 0) {
        addInfoNotification(t('No tickets added to the cart'))
      } else if (insertedTicketsCount === expectedTicketsCount) {
        addInfoNotification(
          t('{{count}} tickets added to the cart', {
            count: insertedTicketsCount
          })
        )
      } else {
        addInfoNotification(
          t('{{count}} tickets added to cart. Unable to add more.', {
            count: insertedTicketsCount
          })
        )
      }
    },
    [addInfoNotification, t]
  )

  const handleZoneTicketQuantityChange = useCallback(
    async ({
      increment,
      decrement,
      zoneUuid
    }: {
      increment?: number
      decrement?: number
      zoneUuid: string
    }) => {
      setShowBackdrop(true)
      try {
        if (decrement) {
          const {data} = await removeZoneTicketsFromCart(zoneUuid, decrement)
          if (data) {
            updateCurrentCart()
          }
        } else if (increment) {
          if (!currentCartId) {
            interruptSubscription()
          }
          const {data} = await incrementZoneTicketItemQuantity({
            eventId: event.id,
            cartId: currentCartId,
            eventSeatUUID: zoneUuid,
            increment
          })
          if (data) {
            if (
              data.incrementZoneTicketItemQuantity.__typename ===
              'IncrementZoneTicketItemQuantitySuccessResult'
            ) {
              if (!currentCartId) {
                resetSubscription(data.incrementZoneTicketItemQuantity.cart.id)
                initializeCurrentCart(data.incrementZoneTicketItemQuantity.cart)
              } else {
                updateCurrentCart()
              }
              displayZoneTicketsAddedToCartNotification({
                expectedTicketsCount: increment,
                insertedTicketsCount:
                  data.incrementZoneTicketItemQuantity
                    .newlyAddedZoneTicketItemsCount
              })
            } else {
              displayZoneTicketsAddedToCartNotification({
                expectedTicketsCount: increment,
                insertedTicketsCount: 0
              })
            }
          }
        }
        closeZoneDialog()
      } catch (error) {
        if (increment && !currentCartId) {
          resetSubscription(null)
        }
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          openOccupiedSeatsLimitReachedDialog()
        } else {
          defaultErrorHandler(error, t('Updating zone tickets quantity failed'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      closeZoneDialog,
      removeZoneTicketsFromCart,
      updateCurrentCart,
      currentCartId,
      incrementZoneTicketItemQuantity,
      event.id,
      interruptSubscription,
      displayZoneTicketsAddedToCartNotification,
      resetSubscription,
      initializeCurrentCart,
      openOccupiedSeatsLimitReachedDialog,
      defaultErrorHandler,
      t
    ]
  )

  // @ts-ignore
  const {seats, zones} = eventSeats
  const feSeatStatesByUuid = useFeSeatStatesByUuid({
    seats,
    mapApiSeatStateToFeSeatState: getFeSeatStateInEventAuditoriumPreview
  })

  const feZoneStatesByUuid = useFeZoneStatesByUuid({
    zones,
    mapZoneApiSeatStatesToFeZoneState: getFeZoneStateInEventAuditoriumPreview
  })

  const [latestEventSeatTicketItem, {data, loading}] = useLazyQuery<
    LatestEventSeatTicketItemQuery,
    LatestEventSeatTicketItemQueryVariables
  >(LATEST_EVENT_SEAT_TICKET_ITEM, {
    fetchPolicy: 'network-only',
    onCompleted: openSeatDialog,
    onError: (e) =>
      getGraphQLErrorRelatedToErrorMessage(e, ErrorMessages.TicketItemNotFound)
        ? defaultErrorHandler(e, t('Ticket item not found'))
        : defaultErrorHandler(e, t('Error while loading ticket item data'))
  })

  useEffect(() => {
    if (loading) {
      setShowBackdrop(true)
    } else {
      setShowBackdrop(false)
    }
  }, [loading, setShowBackdrop])

  const getSeatClickHandler = useCallback(
    (uuid: string) => {
      const {state, id: eventSeatId}: {state: ApiSeatState; id: number} =
        seats[uuid]
      if (state === ApiSeatState.Available) {
        return getOnAvailableSeatClickHandler(uuid)
      } else if (state === ApiSeatState.AddedToMyCart) {
        return getOnSeatInMyCartClickHandler(uuid, eventSeatId)
      } else if (
        [
          ApiSeatState.InMyReservation,
          ApiSeatState.InOtherReservation,
          ApiSeatState.ReservedInMyCart,
          ApiSeatState.Sold
        ].includes(state)
      ) {
        return () =>
          latestEventSeatTicketItem({
            variables: {
              eventSeatId
            }
          })
      }
      return undefined
    },
    [
      getOnAvailableSeatClickHandler,
      getOnSeatInMyCartClickHandler,
      latestEventSeatTicketItem,
      seats
    ]
  )

  const getZoneTooltipLabel = useGetZoneTooltipLabel()
  const getSeatTooltipLabel = useGetSeatTooltipLabel()

  const {TooltipProps, getMouseEnterHandler, getMouseLeaveHandler} =
    useLayoutObjectTooltip({
      layout: event.auditoriumLayout.layout,
      feSeatStatesByUuid,
      feZoneStatesByUuid,
      getTicketTypeForUuid,
      getZoneTooltipLabel,
      getSeatTooltipLabel
    })

  const getZoneClickHandler = useCallback(
    (uuid: string) =>
      zones[uuid].states[ApiSeatState.Available] > 0 ||
      zones[uuid].states[ApiSeatState.AddedToMyCart] > 0
        ? () => {
            setUuidOfOpenedZone(uuid)
          }
        : undefined,
    [zones]
  )

  const history = useHistory()

  const handleDiscountsButtonClick = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.eventApplyDiscounts(event.id))
  }, [event.id, history])
  const updateSeatingTicketItemsQuantityInCart =
    useUpdateSeatingTicketItemsQuantityInCart()

  const handleSeatsMultiSelect = useCallback(
    async (eventSeatUUIDs: string[]) => {
      if (eventSeatUUIDs.length > 0) {
        setShowBackdrop(true)
        if (!currentCartId) {
          interruptSubscription()
        }
        try {
          const {data} = await updateSeatingTicketItemsQuantityInCart({
            cartId: currentCartId,
            eventId: event.id,
            eventSeatUUIDs
          })
          if (data && data.updateSeatingTicketItemsQuantityInCart) {
            if (!currentCartId) {
              resetSubscription(data.updateSeatingTicketItemsQuantityInCart.id)
              initializeCurrentCart(data.updateSeatingTicketItemsQuantityInCart)
            } else {
              updateCurrentCart()
            }
          }
        } catch (e) {
          if (!currentCartId) {
            resetSubscription(null)
          }
          if (
            getGraphQLErrorRelatedToErrorMessage(
              e,
              ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
            )
          ) {
            openOccupiedSeatsLimitReachedDialog()
          } else if (
            !getGraphQLErrorRelatedToErrorMessage(
              e,
              ErrorMessages.EventSeatNotFound
            )
          ) {
            defaultErrorHandler(
              e,
              t('Update seating ticket items quantity failed')
            )
          }
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      defaultErrorHandler,
      event.id,
      initializeCurrentCart,
      interruptSubscription,
      openOccupiedSeatsLimitReachedDialog,
      resetSubscription,
      setShowBackdrop,
      t,
      updateCurrentCart,
      updateSeatingTicketItemsQuantityInCart
    ]
  )
  const [defaultZonePlanView] = useLocalStorageState<ZonePlanView>(
    LocalStorageKey.ZonePlanView,
    ZonePlanView.ListView
  )
  const [currentZonePlanView, setCurrentZonePlanView] =
    useState<ZonePlanView>(defaultZonePlanView)
  const getZoneViewChangeHandler = useCallback(
    (zonePlan: ZonePlanView) => () => {
      setCurrentZonePlanView(zonePlan)
    },
    []
  )
  return (
    <div className={classes.root}>
      <SubHeader className={classes.subHeader} event={event} />
      <div className={classes.subCart}>
        <SubCartHeader
          areActionsDisabled={eventTicketItems.length === 0}
          onRemoveAllEventItemsClick={handleRemoveAllEventItemsClick}
          onDiscountsButtonClick={handleDiscountsButtonClick}
        />
        {eventTicketItems.length > 0 ? (
          <ListOfItemsSeparatedByDividers
            DividerProps={{
              className: classes.listDivider
            }}
          >
            {eventTicketItems.map((ticketItem) => (
              <TicketListItemWithRemoveButton
                className={classes.listItem}
                onRemoveButtonClick={createRemoveTicketItemHandler(
                  ticketItem.id
                )}
                key={ticketItem.id}
                {...getBasicTicketListItemProps(ticketItem)}
                isRemoveButtonShown={P([
                  PermissionCode.RemoveTicketItemFromCart
                ])}
              />
            ))}
          </ListOfItemsSeparatedByDividers>
        ) : (
          <Blank title={t('Add first ticket for event')} />
        )}
        <TicketsSubtotal
          items={eventTicketItems}
          description={t('{{count}} ticket', {count: eventTicketItems.length})}
        />
      </div>
      {currentZonePlanView === ZonePlanView.ListView &&
      event.auditoriumLayout.type === AuditoriumLayoutType.ZoneFloor ? (
        <ZoneListView
          getZoneViewChangeHandler={getZoneViewChangeHandler}
          className={classes.zoneListView}
          zones={eventSeats.zones}
          event={event}
          getZoneClickHandler={getZoneClickHandler}
          onZoneTicketQuantityChange={handleZoneTicketQuantityChange}
        />
      ) : (
        <AuditoriumLayoutPreview
          className={classes.auditoriumLayoutPreview}
          layout={layout}
          eventSeats={eventSeats}
          feSeatStatesByUuid={feSeatStatesByUuid}
          feZoneStatesByUuid={feZoneStatesByUuid}
          getSeatClickHandler={getSeatClickHandler}
          getZoneClickHandler={getZoneClickHandler}
          getLayoutObjectMouseEnterHandler={getMouseEnterHandler}
          getLayoutObjectMouseLeaveHandler={getMouseLeaveHandler}
          getZoneViewChangeHandler={
            event.auditoriumLayout.type === AuditoriumLayoutType.ZoneFloor
              ? getZoneViewChangeHandler
              : undefined
          }
          TooltipProps={TooltipProps}
          onSeatsMultiSelect={
            P([PermissionCode.UpdateSeatingTicketItemsQuantityInCart])
              ? handleSeatsMultiSelect
              : undefined
          }
        />
      )}
      {isSeatDialogOpen && data?.latestEventSeatTicketItem.sale && (
        <SoldSeatDialog
          sale={data.latestEventSeatTicketItem.sale}
          cartId={data.latestEventSeatTicketItem.cartId}
          onClose={closeSeatDialog}
        />
      )}
      {isSeatDialogOpen && data?.latestEventSeatTicketItem.reservation && (
        <ReservedSeatDialog
          reservation={data.latestEventSeatTicketItem.reservation}
          onClose={closeSeatDialog}
        />
      )}
      {uuidOfOpenedZone && (
        <EditZoneTicketQuantityDialog
          zoneUuid={uuidOfOpenedZone}
          zoneCapacity={(layout as any)[uuidOfOpenedZone].config.capacity}
          onClose={closeZoneDialog}
          zoneLabel={(layout as any)[uuidOfOpenedZone].config.label}
          zoneDescription={[
            getTicketTypeForUuid(uuidOfOpenedZone).name,
            getTicketTypeForUuid(uuidOfOpenedZone).description
          ]
            .filter(Boolean)
            .join(' • ')}
          zonePrice={getTicketTypeForUuid(uuidOfOpenedZone).price}
          ticketsInMyCartQuantity={
            zones[uuidOfOpenedZone]?.states[ApiSeatState.AddedToMyCart] || 0
          }
          availableTicketsQuantity={
            zones[uuidOfOpenedZone]?.states[ApiSeatState.Available] || 0
          }
          reservedTicketsQuantity={safeSum([
            zones[uuidOfOpenedZone]?.states[ApiSeatState.ReservedInMyCart],
            zones[uuidOfOpenedZone]?.states[ApiSeatState.InOtherReservation],
            zones[uuidOfOpenedZone]?.states[ApiSeatState.InMyReservation]
          ])}
          soldTicketsQuantity={
            zones[uuidOfOpenedZone]?.states[ApiSeatState.Sold] || 0
          }
          onSaveClick={handleZoneTicketQuantityChange}
        />
      )}
      <OccupiedSeatsLimitReachedErrorDialog
        isOpen={isOccupiedSeatsLimitReachedDialogOpen}
        onClose={closeOccupiedSeatsLimitReachedDialog}
      />
    </div>
  )
}
