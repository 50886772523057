import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {EventCategoryForm} from './EventCategoryForm'
import {useCreateEventCategory} from './graphql'
import {
  EventCategoryFormField,
  EventCategoryState,
  IEventCategoryForm
} from './types'

const CREATE_EVENT_CATEGORY_FORM_ID = 'createEventCategoryForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  }
}))

interface ICreateEventCategoryDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateEventCategoryDrawer: React.FC<ICreateEventCategoryDrawerProps> =
  ({isOpen, onClose}: ICreateEventCategoryDrawerProps) => {
    const {t} = useTranslation()
    const createEventCategory = useCreateEventCategory()
    const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
      useMutationAssistanceHooks()
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: IEventCategoryForm) => {
        try {
          setShowBackdrop(true)
          await createEventCategory({
            data: {
              ...data,
              isActive:
                data[EventCategoryFormField.IsActive] ===
                EventCategoryState.Active
            }
          })
          addInfoNotification(t('Event category created'))
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while create event category'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createEventCategory,
        defaultErrorHandler,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create event category')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_EVENT_CATEGORY_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
        >
          <EventCategoryForm
            formId={CREATE_EVENT_CATEGORY_FORM_ID}
            onSubmit={handleSubmit}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
