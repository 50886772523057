import {Button} from '@mui/material'
import _ from 'lodash'
import queryString from 'query-string'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {User} from '../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../utils/routes'
import {Loading} from '../../visual'
import {BottomBarActionsWrapper, CenteredLayout} from '../admin/Layout'
import {
  FormField,
  IUserFormData,
  UserForm,
  userFromUserForm,
  userToUserForm
} from '../admin/users/UserForm'
import {useVerifyToken} from '../login/graphql'
import {InvalidToken} from '../login/InvalidToken'
import {useAcceptInvitation} from './graphql'

const INVITE_USER_FORM_ID = 'inviteUserForm'

export const InvitedUserForm: React.FC = () => {
  const {t} = useTranslation()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const history = useHistory()
  const location = useLocation()
  const [tokenState, setTokenState] = useState({
    invalid: false,
    user: {} as User
  })
  const acceptInvitation = useAcceptInvitation()
  const verifyToken = useVerifyToken()
  const token = queryString.parse(location.search).token as string

  useEffect(() => {
    // Note: you can not use async directly in useEffect
    const _verifyToken = async () => {
      if (token) {
        try {
          const res = await verifyToken(token)
          const user = _.get(res, ['data', 'verifyEmailToken'])
          setTokenState({invalid: user === null, user})
        } catch (err) {
          setTokenState({invalid: true, user: {} as User})
        }
      }
    }

    _verifyToken()
    // Note: we want this to run only once
  }, []) // eslint-disable-line

  const onSubmit = useCallback(
    async (data: IUserFormData) => {
      try {
        setShowBackdrop(true)
        await acceptInvitation(token, {
          ...userFromUserForm(data),
          password: data.password
        })
        history.replace(routeTo.admin.dashboard())
        addInfoNotification(t('Account was activated'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [acceptInvitation, addInfoNotification, history, setShowBackdrop, t, token]
  )

  if (!token || tokenState.invalid) {
    return (
      <InvalidToken
        description={t(
          'Token you try to use has expired, was used already or doesn’t exist at all. Please, contact your supervisor.'
        )}
      />
    )
  }

  return !_.isEmpty(tokenState.user) ? (
    <CenteredLayout
      bottomBar={
        <BottomBarActionsWrapper
          sx={{height: 57, backgroundColor: 'background.paper'}}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={INVITE_USER_FORM_ID}
          >
            {t('Submit')}
          </Button>
        </BottomBarActionsWrapper>
      }
    >
      <UserForm
        formId={INVITE_USER_FORM_ID}
        defaultValues={{
          ...userToUserForm(tokenState.user),
          password: '',
          [FormField.USERNAME]: ''
        }}
        showPasswordField
        onSubmit={onSubmit}
      />
    </CenteredLayout>
  ) : (
    <Loading />
  )
}
