import {IText} from '../../../types'
import {CanvasObjectType} from '../../types'

export enum TextActionType {
  ADD_TEXT = 'add text'
}

export interface IAddTextAction {
  type: typeof TextActionType.ADD_TEXT
  payload: {
    type: typeof CanvasObjectType.Text
    config: IText
  }
}

export type TextAction = IAddTextAction
