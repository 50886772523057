import React from 'react'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {SettingsPaper} from '../../../common/Settings'
import {ExpandableTimeSelectSettings} from './TimeSelect'
import {
  ITourDivisionSettingsForm,
  TimeSelectOption,
  TourFormDivisionSettingsField
} from './types'

interface ITourEntranceFormPartProps {
  control: FormContextValues<ITourDivisionSettingsForm>['control']
  watch: FormContextValues<ITourDivisionSettingsForm>['watch']
  errors: FormContextValues<ITourDivisionSettingsForm>['errors']
  register: FormContextValues<ITourDivisionSettingsForm>['register']
  setValue: FormContextValues<ITourDivisionSettingsForm>['setValue']
  triggerValidation: FormContextValues<ITourDivisionSettingsForm>['triggerValidation']
  unregister: FormContextValues<ITourDivisionSettingsForm>['unregister']
  clearError: FormContextValues<ITourDivisionSettingsForm>['clearError']
  setError: FormContextValues<ITourDivisionSettingsForm>['setError']
}

export const TourEntranceFormPart: React.FC<ITourEntranceFormPartProps> = ({
  control,
  watch,
  register,
  errors,
  setValue,
  triggerValidation,
  unregister,
  clearError,
  setError
}: ITourEntranceFormPartProps) => {
  const {t} = useTranslation()
  return (
    <SettingsPaper label={t('Tour entrance defaults')}>
      <ExpandableTimeSelectSettings
        errors={errors}
        watch={watch}
        register={register}
        setValue={setValue}
        triggerValidation={triggerValidation}
        control={control}
        unregister={unregister}
        setError={setError}
        clearError={clearError}
        options={[
          TimeSelectOption.BeforeStart,
          TimeSelectOption.DayBeforeStart
        ]}
        name={TourFormDivisionSettingsField.GateOpens}
        title={t('Gate opens')}
        sx={{py: 1}}
      />
      <ExpandableTimeSelectSettings
        errors={errors}
        watch={watch}
        register={register}
        setValue={setValue}
        triggerValidation={triggerValidation}
        control={control}
        unregister={unregister}
        setError={setError}
        clearError={clearError}
        options={[
          TimeSelectOption.BeforeStart,
          TimeSelectOption.AfterStart,
          TimeSelectOption.DayOfEvent,
          TimeSelectOption.BeforeEnds,
          TimeSelectOption.AfterEnds
        ]}
        name={TourFormDivisionSettingsField.GateCloses}
        title={t('Gate closes')}
        sx={{py: 1}}
      />
    </SettingsPaper>
  )
}
