import {Box} from '@mui/material'
import React from 'react'
import {Redirect} from 'react-router-dom'
import {AuthStatus, useUserInfo} from '../../../utils/auth'
import {routeTo} from '../../../utils/routes'
import {LocaleSwitcherButton} from '../../common/LocaleSwitcherButton'

import {InvitedUserForm} from './InvitedUserForm'

export const InvitedUserPage: React.FC = () => {
  const {loginStatus} = useUserInfo()

  if (loginStatus === AuthStatus.LOGGED_IN) {
    return <Redirect to={{pathname: routeTo.admin.dashboard()}} />
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Box sx={{px: 3, py: 1.5, alignSelf: 'flex-end'}}>
        <LocaleSwitcherButton />
      </Box>
      <InvitedUserForm />
    </Box>
  )
}
