import {useTranslation} from 'react-i18next'

import {
  PaymentMethodType,
  PaymentServiceProvider,
  RetailPaymentMethodType
} from '../__generated__/schema'

export const useTranslatePaymentMethodType = () => {
  const {t} = useTranslation()
  const translatedPaymentMethodTypes: Record<PaymentMethodType, string> = {
    [PaymentMethodType.Cash]: t<string>('PaymentMethodType->Cash'),
    [PaymentMethodType.Card]: t<string>('PaymentMethodType->Card'),
    [PaymentMethodType.WireTransfer]: t<string>(
      'PaymentMethodType->WireTransfer'
    ),
    [PaymentMethodType.Voucher]: t<string>('PaymentMethodType->Voucher'),
    [PaymentMethodType.PaymentGateway]: t<string>(
      'PaymentMethodType->PaymentGateway'
    )
  }
  return (type: PaymentMethodType) => translatedPaymentMethodTypes[type]
}

export const useTranslateRetailPaymentMethodType = () => {
  const {t} = useTranslation()
  const translatedRetailPaymentMethodTypes: Record<
    RetailPaymentMethodType,
    string
  > = {
    [RetailPaymentMethodType.Cash]: t<string>('RetailPaymentMethodType->Cash'),
    [RetailPaymentMethodType.Card]: t<string>('RetailPaymentMethodType->Card'),
    [RetailPaymentMethodType.WireTransfer]: t<string>(
      'RetailPaymentMethodType->WireTransfer'
    ),
    [RetailPaymentMethodType.Voucher]: t<string>(
      'RetailPaymentMethodType->Voucher'
    )
  }
  return (type: RetailPaymentMethodType) =>
    translatedRetailPaymentMethodTypes[type]
}

export const useTranslatePaymentServiceProvider = () => {
  const {t} = useTranslation()
  const translatedPaymentMethodTypes: Record<PaymentServiceProvider, string> = {
    [PaymentServiceProvider.Gopay]: t<string>('PaymentServiceProvider->Gopay'),
    [PaymentServiceProvider.Besteron]: t<string>(
      'PaymentServiceProvider->Besteron'
    ),
    [PaymentServiceProvider.StatnaPokladnica]: t<string>(
      'PaymentServiceProvider->StatnaPokladnica'
    ),
    [PaymentServiceProvider.InHouseVoucher]: t<string>(
      'PaymentServiceProvider->InHouseVoucher'
    )
  }
  return (type: PaymentServiceProvider) => translatedPaymentMethodTypes[type]
}

export const useTranslatePaymentServiceProviderSubLabel = () => {
  const {t} = useTranslation()
  const translatedPaymentMethodTypeSubLabels: Record<
    PaymentServiceProvider,
    string
  > = {
    [PaymentServiceProvider.Gopay]: t<string>(
      'PaymentServiceProviderSubLabel->Gopay'
    ),
    [PaymentServiceProvider.Besteron]: t<string>(
      'PaymentServiceProviderSubLabel->Besteron'
    ),
    [PaymentServiceProvider.StatnaPokladnica]: t<string>(
      'PaymentServiceProviderSubLabel->StatnaPokladnica'
    ),
    [PaymentServiceProvider.InHouseVoucher]: t<string>(
      'PaymentServiceProviderSubLabel->InHouseVoucher'
    )
  }
  return (type: PaymentServiceProvider) =>
    translatedPaymentMethodTypeSubLabels[type]
}

export const useTranslatePaymentMethodTypeSubLabel = () => {
  const {t} = useTranslation()
  const translatedPaymentMethodTypeSubLabels: Record<
    PaymentMethodType,
    string
  > = {
    [PaymentMethodType.Cash]: t<string>('PaymentMethodTypeSubLabel->Cash'),
    [PaymentMethodType.Card]: t<string>('PaymentMethodTypeSubLabel->Card'),
    [PaymentMethodType.WireTransfer]: t<string>(
      'PaymentMethodTypeSubLabel->WireTransfer'
    ),
    [PaymentMethodType.Voucher]: t<string>(
      'PaymentMethodTypeSubLabel->Voucher'
    ),
    [PaymentMethodType.PaymentGateway]: t<string>(
      'PaymentMethodType->PaymentGateway'
    )
  }
  return (type: PaymentMethodType) => translatedPaymentMethodTypeSubLabels[type]
}

export const useTranslateRetailPaymentMethodTypeSubLabel = () => {
  const {t} = useTranslation()
  const translatedRetailPaymentMethodTypeSubLabels: Record<
    RetailPaymentMethodType,
    string
  > = {
    [RetailPaymentMethodType.Cash]: t<string>(
      'RetailPaymentMethodTypeSubLabel->Cash'
    ),
    [RetailPaymentMethodType.Card]: t<string>(
      'RetailPaymentMethodTypeSubLabel->Card'
    ),
    [RetailPaymentMethodType.WireTransfer]: t<string>(
      'RetailPaymentMethodTypeSubLabel->WireTransfer'
    ),
    [RetailPaymentMethodType.Voucher]: t<string>(
      'RetailPaymentMethodTypeSubLabel->Voucher'
    )
  }
  return (type: RetailPaymentMethodType) =>
    translatedRetailPaymentMethodTypeSubLabels[type]
}
