import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {ListItem, ListItemText} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DetailClientPropertiesFragment,
  TemplateType
} from '../../__generated__/schema'
import {useClientFormAnchors} from '../../hooks/clientFormAnchors'
import {useTranslateTemplateType} from '../../hooks/translateTemplates'
import {Theme} from '../../theme'
import {InputBlockWithoutSpacings} from './FormHelpers'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    minHeight: 44,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}))

interface IClientTemplatesProps {
  templates: DetailClientPropertiesFragment['templateAssignments']
  onRowClick: (templateType: TemplateType) => () => void
}

export const ClientTemplates: React.FC<IClientTemplatesProps> = ({
  templates,
  onRowClick
}: IClientTemplatesProps) => {
  const {t} = useTranslation()
  const clientFormAnchors = useClientFormAnchors(true)
  const translateTemplateType = useTranslateTemplateType()
  const classes = useStyles()
  return (
    <InputBlockWithoutSpacings
      header={clientFormAnchors.templates.label}
      blockId={clientFormAnchors.templates.id}
    >
      {Object.values(TemplateType)
        .sort((a, b) =>
          translateTemplateType(a).localeCompare(translateTemplateType(b))
        )
        .map((type) => {
          const separatedTemplates = templates.filter(({template}) =>
            template.types.includes(type)
          )
          return (
            <ListItem key={type} onClick={onRowClick(type)} button divider>
              <ListItemText
                primary={translateTemplateType(type)}
                primaryTypographyProps={{variant: 'subtitle2'}}
                secondary={
                  separatedTemplates.length > 0
                    ? t('{{count}} templates', {
                        count: separatedTemplates.length
                      })
                    : undefined
                }
                secondaryTypographyProps={{variant: 'caption'}}
                classes={{
                  root: classes.root
                }}
              />
              <ChevronRightIcon />
            </ListItem>
          )
        })}
    </InputBlockWithoutSpacings>
  )
}
