import {useQuery} from '@apollo/react-hooks'
import {styled} from '@mui/system'
import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DivisionState,
  GetLightweightClientsQueryVariables,
  GetLightweightDivisionsQuery
} from '../../../../../__generated__/schema'
import {useIsStringWithMaxLength} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {FormAutocomplete} from '../../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {GET_DIVISIONS} from '../../graphql'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

export enum AuditoriumFormField {
  Name = 'name',
  Shortcut = 'shortcut',
  DefaultDivisionId = 'defaultDivisionId'
}

export interface IAuditoriumFormData {
  [AuditoriumFormField.Name]: string
  [AuditoriumFormField.Shortcut]: string
  [AuditoriumFormField.DefaultDivisionId]?: number
}

interface IAuditoriumFormProps {
  formId: string
  onSubmit: (data: IAuditoriumFormData) => Promise<void>
  defaultValues?: Partial<IAuditoriumFormData>
}

export const AuditoriumForm: React.FC<IAuditoriumFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IAuditoriumFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, triggerValidation, register, handleSubmit} =
    useForm<IAuditoriumFormData>({
      defaultValues
    })
  const {data: divisionsData, loading: divisionsLoading} = useQuery<
    GetLightweightDivisionsQuery,
    GetLightweightClientsQueryVariables
  >(GET_DIVISIONS, {
    fetchPolicy: 'network-only'
  })
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const divisionOptions = useMemo(
    () =>
      (divisionsData?.divisions || [])
        .filter(
          ({id, state}) =>
            state === DivisionState.Active ||
            id === defaultValues?.defaultDivisionId
        )
        .sort((divisionA, divisionB) =>
          divisionA.name.localeCompare(divisionB.name)
        )
        .map(({id, name}) => ({value: id, name})),
    [defaultValues?.defaultDivisionId, divisionsData?.divisions]
  )
  useEffect(() => {
    const division = divisionsData?.divisions.find(
      ({id}) => id === defaultValues?.defaultDivisionId
    )
    if (division) {
      setValue(AuditoriumFormField.DefaultDivisionId, division.id)
    }
  }, [defaultValues?.defaultDivisionId, divisionsData?.divisions, setValue])
  return (
    <StyledForm
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      id={formId}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAuditoriumFormData>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={AuditoriumFormField.Name}
            key={AuditoriumFormField.Name}
            label={t('Auditorium name')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAuditoriumFormData>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={AuditoriumFormField.Shortcut}
            key={AuditoriumFormField.Shortcut}
            label={t('Abbreviation')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IAuditoriumFormData>
            fullWidth
            noOptionsText=""
            errors={errors}
            label={t('Default division')}
            name={AuditoriumFormField.DefaultDivisionId}
            key={AuditoriumFormField.DefaultDivisionId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={divisionOptions}
            loading={divisionsLoading}
          />
        ]}
      />
    </StyledForm>
  )
}
