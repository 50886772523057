import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useUndoableActions} from '../redux/undoable/actions'
import {RedoIcon, UndoIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const HistoryPanel: React.FC = () => {
  const undoable = useSelector((state) => state.undoable)
  const {undo, redo} = useUndoableActions()
  const {t} = useTranslation()

  const canUndo = useMemo(
    () => undoable.past.length > 0,
    [undoable.past.length]
  )

  const canRedo = useMemo(
    () => undoable.future.length > 0,
    [undoable.future.length]
  )

  return (
    <Panel>
      <PanelButton
        cypressId="undo-button"
        onClick={undo}
        disabled={!canUndo}
        tooltip={t('Undo')}
      >
        <UndoIcon />
      </PanelButton>
      <PanelButton onClick={redo} disabled={!canRedo} tooltip={t('Redo')}>
        <RedoIcon />
      </PanelButton>
    </Panel>
  )
}
