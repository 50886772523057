import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {SaleFilterInput} from '../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../search'
import {AdvancedSearchBase} from '../search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../search/AdvancedSearchDaterangeRow'
import {AdvancedSearchTextRow} from '../search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../search/daterangeSearch'
import {DATERANGE_IDS, PREDICTABLE_DATERANGE_IDS} from '../search/types'

export const DEFAULT_SALES_FILTER_INPUT: SaleFilterInput = {
  hasText: undefined
}

type SalesSearchObject = SaleFilterInput & {
  _createdAtDaterangeId?: DATERANGE_IDS
}

const createdAtDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_WEEK
]

const mapHasTextToFilter = (
  filter: SaleFilterInput,
  hasText: string | undefined
): SaleFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapIdToFilter = (
  filter: SaleFilterInput,
  id: string | undefined
): SaleFilterInput => ({
  ...filter,
  id: id ? parseInt(id, 10) : undefined
})

const mapLeadNameToFilter = (
  filter: SaleFilterInput,
  leadName: string | undefined
): SaleFilterInput => ({
  ...filter,
  leadName: leadName || undefined
})

const mapLeadPhoneToFilter = (
  filter: SaleFilterInput,
  leadPhone: string | undefined
): SaleFilterInput => ({
  ...filter,
  leadPhone: leadPhone || undefined
})

const mapLeadEmailToFilter = (
  filter: SaleFilterInput,
  leadEmail: string | undefined
): SaleFilterInput => ({
  ...filter,
  leadEmail: leadEmail || undefined
})

const mapNoteToFilter = (
  filter: SaleFilterInput,
  note: string | undefined
): SaleFilterInput => ({
  ...filter,
  note: note || undefined
})

const stripCreatedAtDateFromSearchObject = (
  filter: SalesSearchObject
): SaleFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripHelperKeysFromSearchObject = (
  filter: SalesSearchObject
): SaleFilterInput => omit(filter, ['_createdAtDaterangeId'])

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getHasTextFromSearchObject = (filter: SaleFilterInput) =>
    filter.hasText || undefined
  const getIdFromSearchObject = (filter: SaleFilterInput) =>
    filter.id ? t('Sale number: {{number}}', {number: filter.id}) : undefined
  const getLeadNameFromSearchObject = (filter: SaleFilterInput) =>
    filter.leadName
      ? t('Customer name: {{name}}', {name: filter.leadName})
      : undefined
  const getLeadPhoneFromSearchObject = (filter: SaleFilterInput) =>
    filter.leadPhone
      ? t('Customer phone: {{phone}}', {phone: filter.leadPhone})
      : undefined
  const getLeadEmailFromSearchObject = (filter: SaleFilterInput) =>
    filter.leadEmail
      ? t('Customer e-mail: {{email}}', {email: filter.leadEmail})
      : undefined
  const getNoteFromSearchObject = (filter: SaleFilterInput) =>
    filter.note ? t('Order note: {{note}}', {name: filter.note}) : undefined
  return {
    getHasTextFromSearchObject,
    getIdFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadPhoneFromSearchObject,
    getLeadEmailFromSearchObject,
    getNoteFromSearchObject
  }
}

interface ISalesPageSearchProps {
  onFilterChange: (filter: SaleFilterInput) => void
}

export const SalesPageSearch: React.FC<ISalesPageSearchProps> = ({
  onFilterChange
}: ISalesPageSearchProps) => {
  const {t} = useTranslation()
  const {
    getHasTextFromSearchObject,
    getIdFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadPhoneFromSearchObject,
    getLeadEmailFromSearchObject,
    getNoteFromSearchObject
  } = useGetFieldFromSearchObject()
  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<SalesSearchObject>({
    usedDateranges: createdAtDateRanges,
    dateRangeInputPrefix: t('Payment date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<SaleFilterInput>(
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
      getHasTextFromSearchObject,
      getIdFromSearchObject,
      getLeadNameFromSearchObject,
      getLeadPhoneFromSearchObject,
      getLeadEmailFromSearchObject,
      getNoteFromSearchObject
    )
  return (
    <Search<SaleFilterInput, SalesSearchObject>
      storageKey="SALES"
      placeholder={t('Search for sale')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_SALES_FILTER_INPUT}
      stripExtendedSearchObject={stripHelperKeysFromSearchObject}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow
            label={t('Payment date')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            stripDaterangeFromSearchObject={stripCreatedAtDateFromSearchObject}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart created at'
            )}
          />
          <AdvancedSearchTextRow<SaleFilterInput>
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow<SaleFilterInput>
            label={t('Sale number')}
            placeholder={t('Enter sale number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapIdToFilter}
            advancedSearchObject={advancedSearchObject}
            value={
              advancedSearchObject.id
                ? String(advancedSearchObject.id)
                : undefined
            }
          />
          <AdvancedSearchTextRow<SaleFilterInput>
            label={t('Customer name')}
            placeholder={t('Enter customer name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapLeadNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.leadName || undefined}
          />
          <AdvancedSearchTextRow<SaleFilterInput>
            label={t('Customer phone')}
            placeholder={t('Enter customer phone')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapLeadPhoneToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.leadPhone || undefined}
          />
          <AdvancedSearchTextRow<SaleFilterInput>
            label={t('Customer e-mail')}
            placeholder={t('Enter customer e-mail')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapLeadEmailToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.leadEmail || undefined}
          />
          <AdvancedSearchTextRow<SaleFilterInput>
            label={t('Order note')}
            placeholder={t('Enter order note')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapNoteToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.note || undefined}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
