import {Button, Grid, ListItem, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientShowPropertiesFragment,
  LibraryShowPropertiesFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useGetCountryThreeLetterCode} from '../../../../hooks/countries'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useTranslateGenre} from '../../../../hooks/showGenres'
import {useTranslateShowType} from '../../../../hooks/showTypes'

import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {borderStyle} from '../../../constants'
import {ShowFillStatus} from './ShowFillStatus'
import {getShowOriginalTitle} from './utils'

const useShowsStyles = makeStyles<Theme>((theme) => ({
  showListItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    },
    borderBottom: borderStyle,
    '&:last-child': {
      borderBottom: 'none'
    },
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridTemplateRows: 'auto auto auto',
    gridTemplateAreas: `
      ". countries edit"
      ". countries edit"
      "info countries edit"
    `,
    alignItems: 'center'
  },
  editButton: {
    marginLeft: theme.spacing(3),
    gridArea: 'edit'
  },
  info: {
    gridArea: 'info'
  },
  countries: {
    gridArea: 'countries'
  }
}))

interface IShowListItemProps {
  show: LibraryShowPropertiesFragment | ClientShowPropertiesFragment
  previewShow: (id: number) => void

  getEditRoute(id: number | string): string
}

export const ShowListItem: React.FC<IShowListItemProps> = ({
  show,
  previewShow,
  getEditRoute
}: IShowListItemProps) => {
  const {
    id,
    typeCode,
    globalReleaseDate,
    originalTitle,
    translations,
    duration,
    genreCodes,
    countryCodes
  } = show

  const classes = useShowsStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const clientLocaleCode = useClientLocaleCode()
  const history = useHistory()
  const releaseDate = globalReleaseDate || null
  const {title} = translations.find(
    (showTranslation) => showTranslation.localeCode === clientLocaleCode
  ) || {title: originalTitle || t('Title not available')}
  const {formatDate} = useDateTimeFormatters()
  const translateShowType = useTranslateShowType()
  const translateGenre = useTranslateGenre()
  const translatedType = translateShowType(typeCode)
  const translatedGenres = genreCodes.map((genreCode) =>
    translateGenre(genreCode)
  )

  const openShowInEditMode = useCallback(
    (e) => {
      e.stopPropagation()
      history.push(getEditRoute(id))
    },
    [getEditRoute, history, id]
  )
  const openShowDetail = useCallback(() => {
    previewShow(id)
  }, [id, previewShow])
  const getCountryThreeLetterCode = useGetCountryThreeLetterCode()
  return (
    <ListItem
      key={id}
      className={classes.showListItem}
      onClick={openShowDetail}
    >
      <Typography variant="subtitle1" color="primary">
        {title}
      </Typography>
      <Grid container direction="column" className={classes.countries}>
        <ShowFillStatus show={show} />
        <Typography align="right" variant="body2">
          {countryCodes
            .map((countryCode) => getCountryThreeLetterCode(countryCode))
            .join(', ')}
        </Typography>
      </Grid>
      <div className={classes.editButton}>
        {(P([PermissionCode.UpdateClientShow]) ||
          P([PermissionCode.UpdateLibraryShow])) && (
          <Button color="primary" onClick={openShowInEditMode}>
            {t('Edit')}
          </Button>
        )}
      </div>
      <Typography variant="body2" color="textSecondary">
        {getShowOriginalTitle({t, originalTitle})}
      </Typography>
      <div className={classes.info}>
        <Typography variant="body2">
          {[
            translatedGenres.length > 0
              ? `${translatedType} (${translatedGenres.join(', ')})`
              : translatedType,
            releaseDate && formatDate(new Date(releaseDate)),
            duration && t('{{count}} minute', {count: duration})
          ]
            .filter(Boolean)
            .join(' • ')}
        </Typography>
      </div>
    </ListItem>
  )
}
