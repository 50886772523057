import {Box, FormControl} from '@mui/material'
import React, {useCallback} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {SetValue} from '../../../utils/forms'
import {FormValidationError} from '../../common/FormHelpers'
import {FormFieldName} from '../../form/types'
import {useCustomReactHookFormRegistration} from '../../form/utils'
import {FormFieldHelper} from '../common'
import {MultiSelect} from './MultiSelect'

interface IFormMultiSelectProps<FormValues extends FieldValues = FieldValues> {
  label?: string
  className?: string
  selectOptions: {[value: string]: React.ReactNode}
  isSearchable?: boolean
  errors: FieldErrors<FormValues>
  setValue: SetValue<string[]>
  selectedKeys: string[]
  register: FormContextValues<FormValues>['register']
  unregister: FormContextValues<FormValues>['unregister']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
  isRequired?: boolean
  showRequiredLabel?: boolean
  note?: string
  disabled?: boolean
}

export const FormMultiSelect = <FormValues extends FieldValues = FieldValues>({
  label,
  className,
  selectOptions,
  isSearchable = false,
  disabled = false,
  setValue,
  selectedKeys = [],
  errors,
  register,
  unregister,
  validationOptions,
  name,
  showRequiredLabel,
  note
}: IFormMultiSelectProps<FormValues>) => {
  useCustomReactHookFormRegistration<FormValues>({
    name,
    register,
    unregister,
    validationOptions
  })

  const onChange = useCallback(
    (value: string[]) => {
      setValue(name, value, true)
    },
    [name, setValue]
  )

  return (
    <FormControl fullWidth className={className}>
      <MultiSelect
        {...{
          selectOptions,
          label,
          onChange,
          selectedKeys,
          isSearchable,
          disabled
        }}
      />
      {errors[name] ? (
        <Box marginLeft={1}>
          <FormValidationError<FormValues> fieldName={name} errors={errors} />
        </Box>
      ) : (
        <FormFieldHelper required={showRequiredLabel} note={note} />
      )}
    </FormControl>
  )
}
