import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightUsersQuery,
  UserState
} from '../../../../../__generated__/schema'
import {ControlledFormCheckboxGroup} from '../../../../form/ControlledFormCheckboxGroup'
import {Error, Loading} from '../../../../visual'
import {useGetLightweightUsers} from '../../paymentsOverview/graphql'
import {IConditionForm, IControlledFormCheckboxGroupCommonProps} from './types'

const hasSelectedInactiveUser = (
  user: LightweightUsersQuery['users'][number],
  selectedIds?: string[]
) => {
  return (
    user.state === UserState.Disabled && selectedIds?.includes(String(user.id))
  )
}

export const ApplicableByUserId: React.FC<IControlledFormCheckboxGroupCommonProps> =
  (props: IControlledFormCheckboxGroupCommonProps) => {
    const {t} = useTranslation()
    const {users, loading, error} = useGetLightweightUsers()
    const {watch, name} = props
    const selectedIds = watch(name)
    if (error) {
      return <Error error={error} message={t('Error while loading users')} />
    }
    if (loading) {
      return <Loading />
    }
    return (
      <ControlledFormCheckboxGroup<IConditionForm>
        options={users
          .filter(
            (user) =>
              user.state === UserState.Active ||
              hasSelectedInactiveUser(user, selectedIds)
          )
          .map((user) => ({
            name: [user.lastName, user.firstName].join(' '),
            id: String(user.id),
            description:
              user.state !== UserState.Active ? t('Inactive') : undefined
          }))}
        {...props}
      />
    )
  }
