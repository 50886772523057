import {Box, BoxProps, SxProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {forwardRef, UIEvent, useCallback} from 'react'

import {Theme} from '../../../theme'
import {
  BOTTOM_BAR_HEIGHT,
  CENTERED_LAYOUT_MAX_WIDTH_ON_LARGE_DESKTOP,
  MediaSizes
} from '../../constants'

const useCenteredLayoutStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    position: 'relative'
  },
  leftSide: {
    display: 'none',
    [`@media ${MediaSizes.TabletPlus}`]: {
      display: 'block'
    },
    position: 'absolute',
    top: 0,
    left: 0
  },
  children: {
    padding: theme.spacing(0, 1),
    [`@media ${MediaSizes.TabletPlus}`]: {
      padding: theme.spacing(0, 11)
    },
    [`@media ${MediaSizes.LargeDesktop}`]: {
      padding: 0
    },
    overflow: 'auto'
  },
  childrenMaxWidthBox: {
    height: '100%',
    [`@media ${MediaSizes.LargeDesktop}`]: {
      margin: '0 auto',
      maxWidth: CENTERED_LAYOUT_MAX_WIDTH_ON_LARGE_DESKTOP
    }
  }
}))

const useFullScreenCenteredLayoutStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    position: 'relative'
  },
  leftSide: {
    display: 'none',
    [`@media ${MediaSizes.TabletPlus}`]: {
      display: 'block'
    },
    position: 'absolute',
    top: 0,
    left: 0
  },
  children: {
    padding: 0,
    [`@media ${MediaSizes.TabletPlus}`]: {
      padding: theme.spacing(0, 11)
    },
    overflow: 'auto'
  },
  childrenMaxWidthBox: {
    height: '100%',
    [`@media ${MediaSizes.LargeDesktop}`]: {
      margin: '0 auto',
      maxWidth: CENTERED_LAYOUT_MAX_WIDTH_ON_LARGE_DESKTOP
    }
  }
}))

interface ICenteredLayout {
  children: React.ReactNode
  /**
   * @deprecated
   */
  subTopbar?: React.ReactNode
  bottomBar?: React.ReactNode
  leftSide?: React.ReactNode
  onScrolledNearTheEndOfTheLayout?: () => void
}

export const CenteredLayout = forwardRef<HTMLDivElement, ICenteredLayout>(
  function CenteredLayoutComp(
    {
      children,
      subTopbar,
      bottomBar,
      leftSide,
      onScrolledNearTheEndOfTheLayout
    }: ICenteredLayout,
    ref
  ) {
    const classes = useCenteredLayoutStyles()
    const handleScroll = useCallback(
      (e: UIEvent<HTMLDivElement>) => {
        const element = e.target as HTMLDivElement
        if (e.target) {
          const {scrollTop, scrollHeight} = element
          const height = element.getBoundingClientRect().height
          const maxScrollTop = scrollHeight - height
          if (
            onScrolledNearTheEndOfTheLayout &&
            scrollTop >= maxScrollTop - 500
          ) {
            onScrolledNearTheEndOfTheLayout()
          }
        }
      },
      [onScrolledNearTheEndOfTheLayout]
    )
    return (
      <div className={classes.root}>
        <div className={classes.subTopbar}>{subTopbar}</div>
        <div
          className={classes.children}
          ref={ref}
          onScroll={onScrolledNearTheEndOfTheLayout ? handleScroll : undefined}
        >
          <div className={classes.childrenMaxWidthBox}>{children}</div>
        </div>
        <div className={classes.bottombar}>{bottomBar}</div>
        <div className={classes.leftSide}>{leftSide}</div>
      </div>
    )
  }
)

interface IFullScreenCenteredLayout {
  children: React.ReactNode
  leftSide?: React.ReactNode
  subTopbar?: React.ReactNode
  onScrolledNearTheEndOfTheLayout?: () => void
  className?: string
}

export const FullScreenCenteredLayout = forwardRef<
  HTMLDivElement,
  IFullScreenCenteredLayout
>(function FullScreenCenteredLayoutComp(
  {
    children,
    leftSide,
    onScrolledNearTheEndOfTheLayout,
    className,
    subTopbar
  }: IFullScreenCenteredLayout,
  ref
) {
  const classes = useFullScreenCenteredLayoutStyles()
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const element = e.target as HTMLDivElement
      if (e.target) {
        const {scrollTop, scrollHeight} = element
        const height = element.getBoundingClientRect().height
        const maxScrollTop = scrollHeight - height
        if (
          onScrolledNearTheEndOfTheLayout &&
          scrollTop >= maxScrollTop - 500
        ) {
          onScrolledNearTheEndOfTheLayout()
        }
      }
    },
    [onScrolledNearTheEndOfTheLayout]
  )
  return (
    <div className={cn(classes.root, className)}>
      <div>{subTopbar}</div>
      <div
        className={classes.children}
        ref={ref}
        onScroll={onScrolledNearTheEndOfTheLayout ? handleScroll : undefined}
      >
        <div className={classes.childrenMaxWidthBox}>{children}</div>
      </div>
      <div className={classes.leftSide}>{leftSide}</div>
    </div>
  )
})

interface ICenteredLayoutListWrapperProps {
  children: React.ReactNode | React.ReactNodeArray
  height?: BoxProps['height']
  className?: string
}

export const CenteredLayoutListWrapper: React.FC<ICenteredLayoutListWrapperProps> =
  ({children, height, className}: ICenteredLayoutListWrapperProps) => (
    <Box paddingTop={2} paddingBottom={2} height={height} className={className}>
      {children}
    </Box>
  )

interface IBottomBarActionsWrapperProps {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export const BottomBarActionsWrapper: React.FC<IBottomBarActionsWrapperProps> =
  ({children, sx}: IBottomBarActionsWrapperProps) => (
    <Box
      sx={{
        background: 'paper',
        borderTop: (theme) => `solid ${theme.palette.divider} 1px`,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        px: 3,
        height: BOTTOM_BAR_HEIGHT,
        ...sx
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gap: 2
        }}
      >
        {children}
      </Box>
    </Box>
  )

interface IWideCenteredLayoutProps {
  /**
   * @deprecated use sx prop
   */
  className?: string
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export const WideCenteredLayout: React.FC<IWideCenteredLayoutProps> = ({
  children,
  className,
  sx
}: IWideCenteredLayoutProps) => (
  <Box
    className={className}
    sx={{
      height: '100%',
      px: 3,
      margin: '0 auto',
      maxWidth: 1920,
      overflowY: 'auto',
      ...sx
    }}
  >
    {children}
  </Box>
)
