import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import {Button} from '@mui/material'
import {TFunction} from 'i18next'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AuditoriumPreviewFieldsFragment,
  CartPropertiesFragment,
  CheckoutOptions,
  EventState,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {CalendarAlertIcon} from '../../../../common/icons'

import {
  Blank,
  BlankContentPosition,
  IBlankProps
} from '../../../../visual/Blank'
import {isTicketItemPropertiesFragment} from '../../types'
import {useDiscardCart} from '../cart/graphql'
import {useCurrentCart} from '../CurrentCartContext'

interface IDiscardCartButtonProps {
  onClick: () => void
}
const DiscardCartButton: React.FC<IDiscardCartButtonProps> = ({
  onClick
}: IDiscardCartButtonProps) => {
  const {t} = useTranslation()
  return (
    <Button
      startIcon={<RemoveShoppingCartIcon />}
      onClick={onClick}
      variant="text"
      color="primary"
    >
      {t('Discard cart', {context: 'checkout_options_mismatch'})}
    </Button>
  )
}

interface IChildrenOnValidEventAndCartCombinationProps {
  event: AuditoriumPreviewFieldsFragment
  children: React.ReactNode
}

const getValidationResult = ({
  event,
  t,
  isReservationEnabledByEventSettings,
  isSaleEnabledByEventSettings,
  currentCart,
  onDiscardCartButtonClick
}: {
  event: AuditoriumPreviewFieldsFragment
  t: TFunction
  isReservationEnabledByEventSettings: boolean
  isSaleEnabledByEventSettings: boolean
  currentCart: CartPropertiesFragment | null
  onDiscardCartButtonClick: () => void
}): Omit<IBlankProps, 'title' | 'IconComp'> | null => {
  if (event.state === EventState.Draft) {
    return {
      description: t(
        `Event items can’t be added to cart, because event is in draft state. Change event settings and try it again.`
      )
    }
  }
  if (event.state === EventState.Canceled) {
    return {
      description: t(
        `Event items can’t be added to cart, because event is canceled. Change event settings and try it again.`
      )
    }
  }
  if (event.state === EventState.Deleted) {
    return {
      description: t(
        `Event items can’t be added to cart, because event is deleted`
      )
    }
  }
  if (
    !isReservationEnabledByEventSettings &&
    !isSaleEnabledByEventSettings &&
    !(
      currentCart &&
      (currentCart.items || [])
        .filter(isTicketItemPropertiesFragment)
        .filter(
          (item: TicketItemPropertiesFragment) =>
            item.eventSeat.event.id === event.id
        ).length > 0
    )
  ) {
    return {
      description: t(
        `Event items can’t be added to cart, because is forbidden to sell tickets or make reservations for this event. Change event settings and try it again.`
      )
    }
  }
  if (
    currentCart &&
    Array.isArray(currentCart.checkoutOptions) &&
    (currentCart.items || [])
      .filter(isTicketItemPropertiesFragment)
      .filter(
        (item: TicketItemPropertiesFragment) =>
          item.eventSeat.event.id === event.id
      ).length === 0
  ) {
    if (
      currentCart.checkoutOptions.includes(CheckoutOptions.Reservation) &&
      !currentCart.checkoutOptions.includes(CheckoutOptions.Sale) &&
      !isReservationEnabledByEventSettings
    ) {
      return {
        description: t(
          `Cart and event options mismatch. Cart can be finished only as a reservation. Event settings only allow to get paid for this event. You can discard current cart or continue to finish it.`
        ),
        actions: <DiscardCartButton onClick={onDiscardCartButtonClick} />
      }
    } else if (
      currentCart.checkoutOptions.includes(CheckoutOptions.Sale) &&
      !currentCart.checkoutOptions.includes(CheckoutOptions.Reservation) &&
      !isSaleEnabledByEventSettings
    ) {
      return {
        description: t(
          `Cart and event options mismatch. Cart can be finished only by payment. Event settings only allows to make reservation for this event. You can discard current cart or continue to finish it.`
        ),
        actions: <DiscardCartButton onClick={onDiscardCartButtonClick} />
      }
    }
  }
  return null
}

export const ChildrenOnValidEventAndCartCombination: React.FC<IChildrenOnValidEventAndCartCombinationProps> =
  ({children, event}: IChildrenOnValidEventAndCartCombinationProps) => {
    const {t} = useTranslation()
    const discardCart = useDiscardCart()
    const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
      useMutationAssistanceHooks()
    const {resetCurrentCart, currentCartId, currentCart} = useCurrentCart()
    const handleDiscardCart = useCallback(async () => {
      if (currentCartId) {
        try {
          setShowBackdrop(true)
          await discardCart(currentCartId)
          resetCurrentCart()
          addInfoNotification(t('Cart discarded'))
        } catch (e) {
          defaultErrorHandler(e, t('Discarding cart failed'))
        } finally {
          setShowBackdrop(false)
        }
      }
    }, [
      addInfoNotification,
      currentCartId,
      defaultErrorHandler,
      discardCart,
      resetCurrentCart,
      setShowBackdrop,
      t
    ])
    const isoNow = new Date().toISOString()
    const isReservationEnabledByEventSettings =
      event.salesAndReservation.pointOfSale.reservationActive &&
      event.salesAndReservation.pointOfSale.reservationEnd > isoNow &&
      event.salesAndReservation.pointOfSale.reservationStart < isoNow
    const isSaleEnabledByEventSettings =
      event.salesAndReservation.pointOfSale.saleActive &&
      event.salesAndReservation.pointOfSale.saleEnd > isoNow &&
      event.salesAndReservation.pointOfSale.saleStart < isoNow

    const validationResult = getValidationResult({
      event,
      t,
      isReservationEnabledByEventSettings,
      isSaleEnabledByEventSettings,
      currentCart,
      onDiscardCartButtonClick: handleDiscardCart
    })

    return validationResult ? (
      <Blank
        IconComp={CalendarAlertIcon}
        contentPosition={BlankContentPosition.Center}
        title={t('Can’t add event to cart')}
        {...validationResult}
      />
    ) : (
      <>{children}</>
    )
  }
