import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {ICreateText, IText} from '../../../types'
import {getCompressedId} from '../../../utils/compressedIdGenerator'
import {useEditorModeActions} from '../../editorMode/actions'
import {CanvasObjectType} from '../../types'
import {TextActionType} from './types'

export const useTextActions = () => {
  const dispatch = useDispatch()
  const {setSelectMode} = useEditorModeActions()

  const addText = useCallback(
    (text: ICreateText) => {
      const _text: IText = {
        ...text,
        id: getCompressedId()
      }
      dispatch({
        type: TextActionType.ADD_TEXT,
        payload: {type: CanvasObjectType.Text, config: _text}
      })
      setSelectMode()
    },
    [dispatch, setSelectMode]
  )

  const actions = useMemo(
    () => ({
      addText
    }),
    [addText]
  )

  return actions
}
