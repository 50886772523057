import UserIcon from '@mui/icons-material/AccountCircle'
import UnknownIcon from '@mui/icons-material/Help'
import DivisionIcon from '@mui/icons-material/Store'
import {Card, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../theme'
import {borderStyle} from '../constants'

const ClientIcon = UserIcon

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    padding: theme.spacing(2),
    boxShadow: 'none',
    border: borderStyle
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  topContent: {
    minHeight: 50
  },
  bottomContent: {
    marginLeft: theme.spacing(5)
  }
}))

type IconType = 'user' | 'client' | 'division'

interface IIcon {
  className: string
  type: IconType
}

const Icon: React.FC<IIcon> = ({className, type}: IIcon) => {
  switch (type) {
    case 'user':
      return <UserIcon color="primary" className={className} />
    case 'client':
      return <ClientIcon color="primary" className={className} />
    case 'division':
      return <DivisionIcon color="primary" className={className} />
    default:
      return <UnknownIcon color="primary" className={className} />
  }
}

interface IStatusBarLayout {
  rightSideContent: React.ReactNode
  bottomContent: React.ReactNode
  title: string
  iconType: IconType
}

export const StatusBarLayout: React.FC<IStatusBarLayout> = ({
  rightSideContent,
  bottomContent,
  title,
  iconType
}: IStatusBarLayout) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.topContent}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Icon type={iconType} className={classes.icon} />
            <Typography variant="h5" color="primary">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>{rightSideContent}</Grid>
      </Grid>
      <Grid container className={classes.bottomContent}>
        {bottomContent}
      </Grid>
    </Card>
  )
}
