import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {UserPropertiesFragment} from '../../../../../__generated__/schema'

import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useNotifications} from '../../../../context/notifications'
import {useChangePassword} from '../graphql'
import {
  NewPasswordForm,
  NewPasswordFormData,
  RESET_PASSWORD_FORM_ID
} from './common'

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    color: theme.palette.primary.main
  },
  closeIcon: {
    margin: theme.spacing(2),
    cursor: 'pointer'
  },
  dialogContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  actions: {
    padding: theme.spacing(2)
  }
}))

interface INewPasswordModal {
  user: UserPropertiesFragment
}

const useOnResetPasswordSubmit = (user: UserPropertiesFragment | null) => {
  const changePassword = useChangePassword()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {t} = useTranslation()

  return useCallback(
    async (data: NewPasswordFormData) => {
      if (user) {
        try {
          await changePassword(user.id, data.password, data.sendEmail || false)
          addInfoNotification(t('Password changed'))
        } catch (err) {
          defaultErrorHandler(err, t('Error while changing password'))
        }
      }
    },
    [addInfoNotification, defaultErrorHandler, t, changePassword, user]
  )
}

export const NewPasswordModal: React.FC<INewPasswordModal> = ({
  user
}: INewPasswordModal) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {
    state: isOpen,
    setTrue: setOpened,
    setFalse: setClosed
  } = useBooleanState(false)

  const _onSubmit = useOnResetPasswordSubmit(user)
  const onSubmit = useCallback(
    (data: NewPasswordFormData) => _onSubmit(data).then(() => setClosed()),
    [_onSubmit, setClosed]
  )

  return (
    <>
      <Button variant="outlined" onClick={setOpened} color="primary">
        {t('New password')}
      </Button>
      <Dialog open={isOpen} onClose={setClosed} fullWidth maxWidth="sm">
        <Grid container direction="row" justifyContent="space-between">
          <DialogTitle className={classes.title}>
            {t('New password')}
          </DialogTitle>
          <CloseIcon onClick={setClosed} className={classes.closeIcon} />
        </Grid>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <NewPasswordForm
            onSubmit={onSubmit}
            generatePassword
            userId={user.id}
          />
        </DialogContent>
        <Divider />
        <DialogActions className={classes.actions}>
          <Button onClick={setClosed} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            form={RESET_PASSWORD_FORM_ID}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
