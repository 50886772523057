import {Box} from '@mui/material'
import React from 'react'
import {
  CashDeskAdmissionRateFieldsFragment,
  CashDeskTourTimeSlotQuery
} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {getIcon} from '../../../common/admissionTypeIcon'
import {CashDeskCard} from '../../../common/cashDeskCard'
import {ColorBox} from '../../../common/ColorBox'
import {IconBox} from '../../../common/IconBox'
import {QuantityControls} from '../../../common/QuantityControls'
import {TourTimeSlotQuantityDescription} from '../components/TourTimeSlotQuantityDescription'

interface ITourTimeSlotGridViewProps {
  admissionRate: CashDeskAdmissionRateFieldsFragment
  onCardClick: (
    assignmentId: number,
    startingQuantity: number,
    admissionTypeName: string
  ) => (e: React.MouseEvent) => void
  onDecrementButtonClick: (
    assignmentId: number,
    startingQuantity: number
  ) => (e: React.MouseEvent) => void
  onOpenEditQuantityDialogClick: (
    assignment: CashDeskTourTimeSlotQuery['tourTimeSlot']['admissionRate']['admissionTypesAssignments'][number]
  ) => (e: React.MouseEvent) => void
  quantityGetter: (assignmentId: number) => number
}

export const TourTimeSlotGridView: React.FC<ITourTimeSlotGridViewProps> = ({
  admissionRate,
  onCardClick,
  onDecrementButtonClick,
  onOpenEditQuantityDialogClick,
  quantityGetter
}: ITourTimeSlotGridViewProps) => {
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <Box
      sx={{
        px: 3,
        pt: 3,
        pb: 11,
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        flexWrap: 'wrap'
      }}
    >
      {admissionRate.admissionTypesAssignments.map((assignment) => (
        <CashDeskCard
          key={assignment.id}
          onClick={onCardClick(
            assignment.id,
            assignment.admissionType.startingQuantity,
            assignment.admissionType.name
          )}
          title={assignment.admissionType.name}
          description={
            <TourTimeSlotQuantityDescription
              startingQuantity={assignment.admissionType.startingQuantity}
              capacityDecreaseCount={
                assignment.admissionType.capacityDecreaseCount
              }
            />
          }
          price={translateEffectiveClientPrice(assignment.retailPrice)}
          icon={
            assignment.admissionType.icon ? (
              <IconBox
                Icon={getIcon(assignment.admissionType.icon)}
                hexColor={assignment.admissionType.color}
              />
            ) : (
              <ColorBox
                hexColor={assignment.admissionType.color}
                sx={{width: 56, height: 72}}
              />
            )
          }
          actions={
            <QuantityControls
              quantity={quantityGetter(assignment.id)}
              IncrementButtonProps={{
                onClick: onCardClick(
                  assignment.id,
                  assignment.admissionType.startingQuantity,
                  assignment.admissionType.name
                )
              }}
              onClick={onOpenEditQuantityDialogClick(assignment)}
              DecrementButtonProps={{
                onClick: onDecrementButtonClick(
                  assignment.id,
                  assignment.admissionType.startingQuantity
                )
              }}
              typographySx={{
                width: 48,
                height: 40,
                justifyContent: 'center'
              }}
            />
          }
        />
      ))}
    </Box>
  )
}
