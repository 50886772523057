import {useTranslation} from 'react-i18next'
import {WarehouseDocumentType} from '../__generated__/schema'

export const useTranslateWarehouseDocumentType = () => {
  const {t} = useTranslation()
  const translated: Record<WarehouseDocumentType, string> = {
    [WarehouseDocumentType.Incoming]: t('WarehouseDocumentType->Incoming'),
    [WarehouseDocumentType.Outgoing]: t('WarehouseDocumentType->Outgoing')
  }
  return (type: WarehouseDocumentType) => translated[type]
}
