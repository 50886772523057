import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {VoucherCampaignDetail} from './voucherCampaignDetail'
import {VoucherCampaignsPage} from './VoucherCampaignsPage'

export const VoucherCampaigns: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadVoucherCampaign]) && (
        <Route
          path={routeTo.admin.voucherCampaigns.detail(':voucherCampaignId')}
          component={VoucherCampaignDetail}
        />
      )}
      <Route
        path={routeTo.admin.voucherCampaigns.index()}
        component={VoucherCampaignsPage}
        exact
      />
    </Switch>
  )
}
