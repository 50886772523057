import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import _ from 'lodash'
import {useCallback, useMemo} from 'react'

import {
  CreateTicketTypeMutation,
  CreateTicketTypeMutationVariables,
  DeleteTicketTypeMutation,
  DeleteTicketTypeMutationVariables,
  GetTicketTypesQuery,
  GetTicketTypesQueryVariables,
  TicketTypeInput,
  UpdateTicketTypeMutation,
  UpdateTicketTypeMutationVariables
} from '../../../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../../../utils/apollo'
import {GET_LAYOUT_PRICINGS} from '../graphql'

const TICKET_TYPE_PROPERTIES_FRAGMENT = gql`
  fragment TicketTypePricingProperties on TicketType {
    id
    auditoriumLayoutPricingId
    name
    description
    price
    color
    areDiscountsEnabled
  }
`

const GET_TICKET_TYPES = gql`
  query GetTicketTypes($auditoriumLayoutPricingId: Int!) {
    ticketTypes(auditoriumLayoutPricingId: $auditoriumLayoutPricingId) {
      ...TicketTypePricingProperties
    }
  }
  ${TICKET_TYPE_PROPERTIES_FRAGMENT}
`

const CREATE_TICKET_TYPE = gql`
  mutation CreateTicketType(
    $auditoriumLayoutPricingId: Int!
    $data: TicketTypeInput!
  ) {
    createTicketType(
      auditoriumLayoutPricingId: $auditoriumLayoutPricingId
      data: $data
    ) {
      ...TicketTypePricingProperties
    }
  }
  ${TICKET_TYPE_PROPERTIES_FRAGMENT}
`

const UPDATE_TICKET_TYPE = gql`
  mutation UpdateTicketType($id: Int!, $data: TicketTypeInput!) {
    updateTicketType(id: $id, data: $data) {
      ...TicketTypePricingProperties
    }
  }
  ${TICKET_TYPE_PROPERTIES_FRAGMENT}
`

const DELETE_TICKET_TYPE = gql`
  mutation DeleteTicketType($id: Int!) {
    deleteTicketType(id: $id) {
      ...TicketTypePricingProperties
    }
  }
  ${TICKET_TYPE_PROPERTIES_FRAGMENT}
`

export const useGetTicketTypes = (auditoriumLayoutPricingId: number) => {
  const {data, ...rest} = useQuery<
    GetTicketTypesQuery,
    GetTicketTypesQueryVariables
  >(GET_TICKET_TYPES, {variables: {auditoriumLayoutPricingId}})

  const sortedData = useMemo(() => {
    if (!data || !data.ticketTypes) return data
    return {
      ...data,
      ticketTypes: _.orderBy(data.ticketTypes, 'name')
    }
  }, [data])

  return {data: sortedData, ...rest}
}

export const useCreateTicketType = (
  auditoriumLayoutId: number,
  auditoriumLayoutPricingId: number
) => {
  const [createTicketType] = useMutation<
    CreateTicketTypeMutation,
    CreateTicketTypeMutationVariables
  >(CREATE_TICKET_TYPE, {
    refetchQueries: () => {
      return [
        {query: GET_TICKET_TYPES, variables: {auditoriumLayoutPricingId}},
        // We need to also re-run parent query at it has logic based on number
        // of ticket type items
        {query: GET_LAYOUT_PRICINGS, variables: {auditoriumLayoutId}}
      ]
    }
  })
  return useCallback(
    (data: TicketTypeInput) =>
      createTicketType({
        variables: {auditoriumLayoutPricingId, data}
      }),
    [auditoriumLayoutPricingId, createTicketType]
  )
}

export const useUpdateTicketType = () => {
  const [updateTicketType] =
    useMutation<UpdateTicketTypeMutation, UpdateTicketTypeMutationVariables>(
      UPDATE_TICKET_TYPE
    )
  return useCallback(
    (id: number, data: TicketTypeInput) =>
      updateTicketType({
        variables: {id, data}
      }),
    [updateTicketType]
  )
}

export const useDeleteTicketType = (
  auditoriumLayoutId: number,
  auditoriumLayoutPricingId: number
) => {
  const [deleteTicketType] = useMutation<
    DeleteTicketTypeMutation,
    DeleteTicketTypeMutationVariables
  >(DELETE_TICKET_TYPE, {
    refetchQueries: () => {
      return [
        {query: GET_TICKET_TYPES, variables: {auditoriumLayoutPricingId}},
        // We need to also re-run parent query at it has logic based on number
        // of ticket type items
        {query: GET_LAYOUT_PRICINGS, variables: {auditoriumLayoutId}}
      ]
    },
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, data.deleteTicketType)
      }
    }
  })
  return useCallback(
    (id: number) =>
      deleteTicketType({
        variables: {id}
      }),
    [deleteTicketType]
  )
}
