import {useState} from 'react'
import {CalendarApi} from '@fullcalendar/react'

import {IEventsScreenData} from '../graphql'

export const useVenueState = (
  api: CalendarApi | null,
  data: IEventsScreenData,
  defaultVenueId: number
) => {
  const [venueId, setVenueId] = useState(defaultVenueId)

  const onVenueChange = (event: any, newValue: string) => {
    setVenueId(parseInt(newValue, 10))
  }

  return {venueId, onVenueChange}
}
