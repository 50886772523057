import {Box, Chip, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {LocaleCode, ProductQuery} from '../../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../../hooks/getLocales'

const useLocaleRowStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  name: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1)
  },
  chip: {
    ...theme.typography.caption,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary
  }
}))

interface ILocaleRowProps {
  code: LocaleCode
  name?: string | null
  description?: string | null
}

const LocaleRow: React.FC<ILocaleRowProps> = ({
  code,
  name,
  description
}: ILocaleRowProps) => {
  const {t} = useTranslation()
  const classes = useLocaleRowStyles()
  return (
    <div className={classes.root}>
      <div className={classes.name}>
        <Chip label={code} className={classes.chip} />
        <Typography
          variant="subtitle2"
          color={name ? 'textPrimary' : 'textSecondary'}
        >
          {name || t('Not set')}
        </Typography>
      </div>
      {description && <Typography variant="body2">{description}</Typography>}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    paddingBottom: theme.spacing(1)
  }
}))

interface IEcommerceSectionProps {
  id: string
  product: ProductQuery['product']
}

export const EcommerceSection: React.FC<IEcommerceSectionProps> = ({
  id,
  product
}: IEcommerceSectionProps) => {
  const {t} = useTranslation()
  const clientLocales = useGetClientLocales()
  const classes = useStyles()
  return (
    <Box id={id}>
      <Typography variant="subtitle1" className={classes.title}>
        {t('Ecommerce')}
      </Typography>
      <Paper variant="outlined">
        {clientLocales.map((locale) => (
          <LocaleRow
            key={locale}
            code={locale}
            name={
              product.eCommerceNames
                ? product.eCommerceNames[locale]
                : undefined
            }
            description={
              product.eCommerceDescriptions
                ? product.eCommerceDescriptions[locale]
                : undefined
            }
          />
        ))}
      </Paper>
    </Box>
  )
}
