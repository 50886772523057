import dayjs, {Dayjs} from 'dayjs'
import {
  AddressInput,
  DetailDivisionPropertiesFragment,
  DivisionCheckingOption,
  DivisionCreateOnlineReservationEnd,
  DivisionCreatePosReservationEnd,
  DivisionGateCloses,
  DivisionGateOpens,
  DivisionInput,
  DivisionInvoiceFreq,
  DivisionOnlineReservationEnd,
  DivisionOnlineSalesEnd,
  DivisionPosReservationEnd,
  DivisionPosSalesEnd,
  DivisionType
} from '../../../../../__generated__/schema'

export enum DivisionFormField {
  NAME = 'name',
  SHORT_NAME = 'shortName',
  CODE = 'code',
  TYPE = 'type',
  ADDRESS = 'address',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  IBAN = 'IBAN',
  SWIFT = 'SWIFT',
  SPECIFIC_SYMBOL = 'specificSymbol',
  INVOICE_FREQ = 'invoiceFreq',
  ONLINE_PURCHASE_ACTIVE = 'onlinePurchaseActive',
  ONLINE_RESERVATION_ACTIVE = 'onlineReservationActive',
  POS_PURCHASE_ACTIVE = 'posPurchaseActive',
  POS_RESERVATION_ACTIVE = 'posReservationActive',
  SALES_END_WEB_TYPE = 'salesEndWebType',
  SALES_END_WEB_VALUE = 'salesEndWebValue',
  SALES_END_POS_TYPE = 'salesEndCashDeskType',
  SALES_END_POS_VALUE = 'salesEndCashDeskValue',
  RESERVATION_END_WEB_TYPE = 'reservationEndWebType',
  RESERVATION_END_WEB_VALUE = 'reservationEndWebValue',
  ONLINE_CREATE_RESERVATION_END_TYPE = 'onlineCreateReservationEndType',
  ONLINE_CREATE_RESERVATION_END_VALUE = 'onlineCreateReservationEndValue',
  RESERVATION_END_POS_TYPE = 'reservationEndCashDeskType',
  RESERVATION_END_POS_VALUE = 'reservationEndCashDeskValue',
  POS_CREATE_RESERVATION_END_TYPE = 'posCreateReservationEndType',
  POS_CREATE_RESERVATION_END_VALUE = 'posCreateReservationEndValue',
  GATE_OPENS_TYPE = 'gateOpensType',
  GATE_OPENS_VALUE = 'gateOpensValue',
  GATE_CLOSES_TYPE = 'gateClosesType',
  GATE_CLOSES_VALUE = 'gateClosesValue',
  CHECKING_OPTION = 'checkingOption',
  WEBSITE = 'website',
  SERVICE_TIME = 'serviceTime'
}

export enum DurationField {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes'
}

export interface IDuration {
  [DurationField.DAYS]: string
  [DurationField.HOURS]: string
  [DurationField.MINUTES]: string
}

export const durationToMinutes = (duration: IDuration) => {
  const {
    [DurationField.DAYS]: days,
    [DurationField.HOURS]: hours,
    [DurationField.MINUTES]: minutes
  } = duration
  return (
    parseInt(days, 10) * 60 * 24 +
    parseInt(hours, 10) * 60 +
    parseInt(minutes, 10)
  )
}

export const minutesToDuration = (lastFor: number): IDuration => {
  let base = lastFor

  const days = Math.floor(base / (60 * 24))
  base = base % (60 * 24)

  const hours = Math.floor(base / 60)
  const minutes = base % 60
  return {
    [DurationField.DAYS]: days.toString(),
    [DurationField.HOURS]: hours.toString(),
    [DurationField.MINUTES]: minutes.toString()
  }
}

const minutesToDayjs = (minutes: number): Dayjs =>
  dayjs().startOf('day').add(minutes, 'minute')

const getMinutesFromDayjs = (date: string | Dayjs) =>
  dayjs(date).diff(dayjs(date).startOf('day'), 'minute')

export interface IDivisionFormData {
  [DivisionFormField.NAME]: string
  [DivisionFormField.SHORT_NAME]?: string
  [DivisionFormField.CODE]?: string
  [DivisionFormField.TYPE]: DivisionType
  [DivisionFormField.ADDRESS]: AddressInput
  [DivisionFormField.EMAIL]?: string
  [DivisionFormField.PHONE_NUMBER]?: string
  [DivisionFormField.IBAN]: string
  [DivisionFormField.SWIFT]: string
  [DivisionFormField.SPECIFIC_SYMBOL]: number
  [DivisionFormField.INVOICE_FREQ]: DivisionInvoiceFreq
  [DivisionFormField.ONLINE_PURCHASE_ACTIVE]: boolean
  [DivisionFormField.ONLINE_RESERVATION_ACTIVE]: boolean
  [DivisionFormField.POS_PURCHASE_ACTIVE]: boolean
  [DivisionFormField.POS_RESERVATION_ACTIVE]: boolean
  [DivisionFormField.SALES_END_WEB_TYPE]: DivisionOnlineSalesEnd
  [DivisionFormField.SALES_END_WEB_VALUE]: {
    [DivisionOnlineSalesEnd.DayBeforeStart]?: Dayjs
    [DivisionOnlineSalesEnd.EventStart]?: any
    [DivisionOnlineSalesEnd.BeforeStart]?: number
    [DivisionOnlineSalesEnd.AfterStart]?: number
  }
  [DivisionFormField.SALES_END_POS_TYPE]: DivisionPosSalesEnd
  [DivisionFormField.SALES_END_POS_VALUE]: {
    [DivisionPosSalesEnd.DayBeforeStart]?: Dayjs
    [DivisionPosSalesEnd.EventStart]?: any
    [DivisionPosSalesEnd.BeforeStart]?: number
    [DivisionPosSalesEnd.AfterStart]?: number
    [DivisionPosSalesEnd.DayOfEvent]?: number
  }
  [DivisionFormField.RESERVATION_END_WEB_TYPE]: DivisionOnlineReservationEnd
  [DivisionFormField.RESERVATION_END_WEB_VALUE]: {
    [DivisionOnlineReservationEnd.DayBeforeStart]?: Dayjs
    [DivisionOnlineReservationEnd.EventStart]?: any
    [DivisionOnlineReservationEnd.BeforeStart]?: number
    [DivisionOnlineReservationEnd.AfterStart]?: number
    [DivisionOnlineReservationEnd.Duration]?: IDuration
  }
  [DivisionFormField.ONLINE_CREATE_RESERVATION_END_TYPE]: DivisionCreateOnlineReservationEnd
  [DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE]: {
    [DivisionCreateOnlineReservationEnd.AfterStart]?: number
    [DivisionCreateOnlineReservationEnd.BeforeStart]?: number
    [DivisionCreateOnlineReservationEnd.DayBeforeStart]?: Dayjs
  }
  [DivisionFormField.RESERVATION_END_POS_TYPE]: DivisionPosReservationEnd
  [DivisionFormField.RESERVATION_END_POS_VALUE]: {
    [DivisionPosReservationEnd.DayBeforeStart]?: Dayjs
    [DivisionPosReservationEnd.EventStart]?: any
    [DivisionPosReservationEnd.BeforeStart]?: number
    [DivisionPosReservationEnd.AfterStart]?: number
    [DivisionPosReservationEnd.Duration]?: IDuration
  }
  [DivisionFormField.POS_CREATE_RESERVATION_END_TYPE]: DivisionCreatePosReservationEnd
  [DivisionFormField.POS_CREATE_RESERVATION_END_VALUE]: {
    [DivisionCreatePosReservationEnd.EventStart]?: any
    [DivisionCreatePosReservationEnd.AfterStart]?: number
    [DivisionCreatePosReservationEnd.BeforeStart]?: number
    [DivisionCreatePosReservationEnd.DayBeforeStart]?: Dayjs
  }
  [DivisionFormField.GATE_OPENS_VALUE]: {
    [DivisionGateOpens.BeforeStart]?: number
    [DivisionGateOpens.DayBeforeStart]?: Dayjs
  }
  [DivisionFormField.GATE_OPENS_TYPE]: DivisionGateOpens
  [DivisionFormField.GATE_CLOSES_VALUE]: {
    [DivisionGateCloses.BeforeStart]?: number
    [DivisionGateCloses.AfterStart]?: number
    [DivisionGateCloses.BeforeEnd]?: number
    [DivisionGateCloses.AfterEnd]?: number
    [DivisionGateCloses.DayOfEvent]?: Dayjs
  }
  [DivisionFormField.GATE_CLOSES_TYPE]: DivisionGateCloses
  [DivisionFormField.CHECKING_OPTION]: DivisionCheckingOption
  [DivisionFormField.WEBSITE]?: string
  [DivisionFormField.SERVICE_TIME]?: number
}

const getInt = (n?: number | string): number => parseInt(String(n), 10) || 0

export const getDivisionInputFromFormData = (
  formData: IDivisionFormData
): DivisionInput => {
  const {
    salesEndWebValue,
    salesEndCashDeskValue,
    reservationEndWebValue,
    onlineCreateReservationEndValue,
    reservationEndCashDeskValue,
    posCreateReservationEndValue,
    gateOpensValue,
    gateClosesValue,
    ...rest
  } = formData
  const salesEndWebType: DivisionOnlineSalesEnd =
    formData[DivisionFormField.SALES_END_WEB_TYPE]
  const salesEndCashDeskType: DivisionPosSalesEnd =
    formData[DivisionFormField.SALES_END_POS_TYPE]
  const reservationEndWebType: DivisionOnlineReservationEnd =
    formData[DivisionFormField.RESERVATION_END_WEB_TYPE]
  const onlineCreateReservationEndType: DivisionCreateOnlineReservationEnd =
    formData[DivisionFormField.ONLINE_CREATE_RESERVATION_END_TYPE]
  const reservationEndCashDeskType: DivisionPosReservationEnd =
    formData[DivisionFormField.RESERVATION_END_POS_TYPE]
  const posCreateReservationEndType: DivisionCreatePosReservationEnd =
    formData[DivisionFormField.POS_CREATE_RESERVATION_END_TYPE]
  const gateOpensType: DivisionGateOpens =
    formData[DivisionFormField.GATE_OPENS_TYPE]
  const gateClosesType: DivisionGateCloses =
    formData[DivisionFormField.GATE_CLOSES_TYPE]
  return {
    ...rest,
    onlinePurchaseActive: Boolean(
      rest[DivisionFormField.ONLINE_PURCHASE_ACTIVE]
    ),
    onlineReservationActive: Boolean(
      rest[DivisionFormField.ONLINE_RESERVATION_ACTIVE]
    ),
    posPurchaseActive: Boolean(rest[DivisionFormField.POS_PURCHASE_ACTIVE]),
    posReservationActive: Boolean(
      rest[DivisionFormField.POS_RESERVATION_ACTIVE]
    ),
    specificSymbol: getInt(String(formData[DivisionFormField.SPECIFIC_SYMBOL])),
    salesEndWebValue:
      salesEndWebType === DivisionOnlineSalesEnd.DayBeforeStart
        ? getMinutesFromDayjs(salesEndWebValue[salesEndWebType]!)
        : getInt(salesEndWebValue[salesEndWebType]),
    salesEndCashDeskValue: [
      DivisionPosSalesEnd.DayBeforeStart,
      DivisionPosSalesEnd.DayOfEvent
    ].includes(salesEndCashDeskType)
      ? getMinutesFromDayjs(salesEndCashDeskValue[salesEndCashDeskType]!)
      : getInt(salesEndCashDeskValue[salesEndCashDeskType]),
    reservationEndWebValue:
      reservationEndWebType === DivisionOnlineReservationEnd.Duration
        ? durationToMinutes(reservationEndWebValue[reservationEndWebType]!)
        : reservationEndWebType === DivisionOnlineReservationEnd.DayBeforeStart
        ? getMinutesFromDayjs(reservationEndWebValue[reservationEndWebType]!)
        : getInt(reservationEndWebValue[reservationEndWebType]),
    onlineCreateReservationEndValue:
      onlineCreateReservationEndType ===
      DivisionCreateOnlineReservationEnd.DayBeforeStart
        ? getMinutesFromDayjs(
            onlineCreateReservationEndValue[onlineCreateReservationEndType]!
          )
        : getInt(
            onlineCreateReservationEndValue[onlineCreateReservationEndType]
          ),
    reservationEndCashDeskValue:
      reservationEndCashDeskType === DivisionPosReservationEnd.Duration
        ? durationToMinutes(
            reservationEndCashDeskValue[reservationEndCashDeskType]!
          )
        : reservationEndCashDeskType ===
          DivisionPosReservationEnd.DayBeforeStart
        ? getMinutesFromDayjs(
            reservationEndCashDeskValue[reservationEndCashDeskType]!
          )
        : getInt(reservationEndCashDeskValue[reservationEndCashDeskType]),
    posCreateReservationEndValue:
      posCreateReservationEndType ===
      DivisionCreatePosReservationEnd.DayBeforeStart
        ? getMinutesFromDayjs(
            posCreateReservationEndValue[posCreateReservationEndType]!
          )
        : getInt(posCreateReservationEndValue[posCreateReservationEndType]),
    gateOpensValue:
      gateOpensType === DivisionGateOpens.DayBeforeStart
        ? getMinutesFromDayjs(gateOpensValue[gateOpensType]!)
        : getInt(gateOpensValue[gateOpensType]),
    gateClosesValue:
      gateClosesType === DivisionGateCloses.DayOfEvent
        ? getMinutesFromDayjs(gateClosesValue[gateClosesType]!)
        : getInt(gateClosesValue[gateClosesType]),
    website: formData[DivisionFormField.WEBSITE] || null,
    serviceTime: formData[DivisionFormField.SERVICE_TIME] || null
  }
}

const getValueForType = (
  value: number,
  type:
    | DivisionOnlineSalesEnd
    | DivisionPosSalesEnd
    | DivisionCreateOnlineReservationEnd
    | DivisionCreatePosReservationEnd
    | DivisionGateOpens
    | DivisionGateCloses
) =>
  [
    DivisionOnlineSalesEnd.DayBeforeStart,
    DivisionPosSalesEnd.DayBeforeStart,
    DivisionCreateOnlineReservationEnd.DayBeforeStart,
    DivisionCreatePosReservationEnd.DayBeforeStart,
    DivisionGateOpens.DayBeforeStart,
    DivisionGateCloses.DayOfEvent
  ].includes(type)
    ? {[type]: minutesToDayjs(value)}
    : {[type]: value}

const getValueForTypeWithDuration = (
  value: number,
  type: DivisionOnlineReservationEnd | DivisionPosReservationEnd
) => {
  switch (type) {
    case DivisionOnlineReservationEnd.Duration:
    case DivisionPosReservationEnd.Duration:
      return {[type]: minutesToDuration(value)}
    case DivisionOnlineReservationEnd.DayBeforeStart:
    case DivisionPosReservationEnd.DayBeforeStart:
      return {[type]: minutesToDayjs(value)}
    default:
      return {[type]: value}
  }
}

export const getFormDataFromDivision = (
  division: DetailDivisionPropertiesFragment
): IDivisionFormData => {
  return {
    ...division,
    [DivisionFormField.SHORT_NAME]: division.shortName || '',
    [DivisionFormField.CODE]: division.code || '',
    [DivisionFormField.EMAIL]: division.email || '',
    [DivisionFormField.PHONE_NUMBER]: division.phoneNumber || '',
    [DivisionFormField.SALES_END_WEB_VALUE]: getValueForType(
      division.salesEndWebValue,
      division.salesEndWebType
    ),
    [DivisionFormField.SALES_END_POS_VALUE]: getValueForType(
      division.salesEndCashDeskValue,
      division.salesEndCashDeskType
    ),
    [DivisionFormField.RESERVATION_END_WEB_VALUE]: getValueForTypeWithDuration(
      division.reservationEndWebValue,
      division.reservationEndWebType
    ),
    [DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE]: getValueForType(
      division.onlineCreateReservationEndValue,
      division.onlineCreateReservationEndType
    ),
    [DivisionFormField.RESERVATION_END_POS_VALUE]: getValueForTypeWithDuration(
      division.reservationEndCashDeskValue,
      division.reservationEndCashDeskType
    ),
    [DivisionFormField.POS_CREATE_RESERVATION_END_VALUE]: getValueForType(
      division.posCreateReservationEndValue,
      division.posCreateReservationEndType
    ),
    [DivisionFormField.GATE_OPENS_VALUE]: getValueForType(
      division.gateOpensValue,
      division.gateOpensType
    ),
    [DivisionFormField.GATE_CLOSES_VALUE]: getValueForType(
      division.gateClosesValue,
      division.gateClosesType
    ),
    [DivisionFormField.WEBSITE]: division.website || undefined,
    [DivisionFormField.SERVICE_TIME]:
      typeof division.serviceTime === 'number'
        ? division.serviceTime
        : undefined
  }
}

export interface IDivisionSectionProps {
  defaultValues: Partial<IDivisionFormData>
}
