import {ShapeVariant} from '@attendio/shared-components'
import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {
  ELLIPSE_MIN_HEIGHT,
  ELLIPSE_MIN_WIDTH,
  LINE_MIN_LENGTH,
  RECTANGLE_MIN_HEIGHT,
  RECTANGLE_MIN_WIDTH
} from '../../../config'
import {ICreateShape, IShape} from '../../../types'
import {getCompressedId} from '../../../utils/compressedIdGenerator'
import {customizeDimensions} from '../../../utils/math'
import {useEditorModeActions} from '../../editorMode/actions'
import {CanvasObjectType} from '../../types'
import {ShapeActionType} from './types'

export const useShapeActions = () => {
  const dispatch = useDispatch()
  const {setSelectMode} = useEditorModeActions()

  const addShape = useCallback(
    (shape: ICreateShape, shapeVariant: ShapeVariant) => {
      let dimensions = shape.dimensions
      if (shapeVariant === ShapeVariant.Ellipse) {
        dimensions = customizeDimensions(
          shape.dimensions,
          ELLIPSE_MIN_WIDTH,
          ELLIPSE_MIN_HEIGHT
        )
      } else if (shapeVariant === ShapeVariant.Line) {
        if (shape.dimensions.height === 0) {
          dimensions = customizeDimensions(shape.dimensions, LINE_MIN_LENGTH, 0)
        }
      } else if (shapeVariant === ShapeVariant.Rectangle) {
        dimensions = customizeDimensions(
          shape.dimensions,
          RECTANGLE_MIN_WIDTH,
          RECTANGLE_MIN_HEIGHT
        )
      }

      const _shape: IShape = {
        ...shape,
        id: getCompressedId(),
        dimensions,
        shapeVariant
      }
      dispatch({
        type: ShapeActionType.ADD_SHAPE,
        payload: {type: CanvasObjectType.Shape, config: _shape}
      })
      setSelectMode()
    },
    [dispatch, setSelectMode]
  )

  const actions = useMemo(
    () => ({
      addShape
    }),
    [addShape]
  )

  return actions
}
