import {Typography} from '@mui/material'
import React, {SyntheticEvent} from 'react'
import {useTranslateEffectiveClientPrice} from '../../../../../../hooks/translateCurrencies'

import {ColoredListItem} from '../../../../../common'
import {TicketColor} from './common'

interface ITicketProps {
  count: number
  name: string
  description?: string | null | undefined
  price?: number | null | undefined
  color: TicketColor
  onClick?: (e: SyntheticEvent) => any
}

export const Ticket: React.FC<ITicketProps> = ({
  count,
  name,
  description,
  price,
  color,
  onClick
}: ITicketProps) => {
  const translatedPrice = useTranslateEffectiveClientPrice()

  return (
    <ColoredListItem
      {...{onClick, color, description}}
      title={`${count}x ${name}`}
      extra={
        <Typography variant="caption">
          {price ? translatedPrice(price) : price === 0 && translatedPrice(0)}
        </Typography>
      }
    />
  )
}
