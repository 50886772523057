import {Button} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  GetVoucherQuery,
  PermissionCode,
  VoucherState,
  VoucherTransactionIntentReason,
  VoucherTransactionIntentState,
  VoucherTransactionIntentType
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useVoucherParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {CartPreviewDrawer} from '../../components/cartPreviewDrawer'
import {ClaimDetailDrawer} from '../../components/claimDetailDrawer'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useChangeVoucherState, useGetVoucher} from '../graphql'
import {GeneralSection} from './GeneralSection'
import {TransactionsSection} from './TransactionsSection'

const useItems = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    transactions: {
      id: 'transactions',
      label: t('Transactions')
    }
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3)
    }
  }
}))

export const VoucherDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const canReadPinCode = P([PermissionCode.ReadVoucherPinCode])
  const [voucherState, setVoucherState] =
    useState<VoucherTransactionIntentState | undefined>(undefined)
  const [voucherType, setVoucherType] =
    useState<VoucherTransactionIntentType | undefined>(undefined)
  const [voucherReason, setVoucherReason] =
    useState<VoucherTransactionIntentReason | undefined>(undefined)
  const {voucherId} = useVoucherParams()
  const {data, loading, error, refetch} = useGetVoucher({
    id: voucherId,
    canReadPinCode,
    transactionIntentFilter: {
      state: voucherState,
      type: voucherType,
      reason: voucherReason
    }
  })
  const changeVoucherState = useChangeVoucherState()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const [title, setTitle] = useState<string>(t<string>('Voucher'))
  const history = useHistory()
  const items = useItems()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.vouchers.index()),
    [history]
  )
  const handleExited = useCallback(
    () => history.push(routeTo.admin.vouchers.detail(voucherId)),
    [history, voucherId]
  )
  const setAs = useCallback(
    (state: VoucherState) => async () => {
      try {
        setShowBackdrop(true)
        await changeVoucherState({id: voucherId, state})
        addInfoNotification(t('Voucher state changed'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while changing voucher state'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      changeVoucherState,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      voucherId
    ]
  )
  const classes = useStyles()
  useEffect(
    () =>
      data &&
      setTitle(t<string>('Voucher {{code}}', {code: data.voucher.code})),
    [data, t]
  )
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        P([PermissionCode.ChangeVoucherState]) ? (
          <Footer>
            {data?.voucher.state === VoucherState.Active && (
              <Button
                variant="contained"
                color="primary"
                onClick={setAs(VoucherState.Invalid)}
              >
                {t('Invalidate')}
              </Button>
            )}
            {data?.voucher.state === VoucherState.Inactive && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={setAs(VoucherState.Invalid)}
                >
                  {t('Invalidate')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={setAs(VoucherState.Active)}
                >
                  {t('Activate')}
                </Button>
              </>
            )}
            {data?.voucher.state === VoucherState.Invalid && (
              <Button
                variant="contained"
                color="primary"
                onClick={setAs(VoucherState.Active)}
              >
                {t('Activate')}
              </Button>
            )}
          </Footer>
        ) : undefined
      }
    >
      <RenderOnData<GetVoucherQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading voucher')}
        dataCondition={(data) => Boolean(data.voucher)}
        ignoreLoadingIfData
      >
        {({voucher}) => (
          <>
            <CenteredLayout>
              <SingleSideNavigationList items={items} />
              <div className={classes.sections}>
                <GeneralSection id={items.general.id} voucher={voucher} />
                <TransactionsSection
                  id={items.transactions.id}
                  voucher={voucher}
                  filters={{
                    voucherState,
                    setVoucherState,
                    voucherType,
                    setVoucherType,
                    voucherReason,
                    setVoucherReason
                  }}
                  refetch={refetch}
                />
              </div>
            </CenteredLayout>
            <Route
              path={routeTo.admin.vouchers.cartInfo(':voucherId', ':cartId')}
            >
              <CartPreviewDrawer onExited={handleExited} />
            </Route>
            <Route
              path={routeTo.admin.vouchers.claimInfo(':voucherId', ':claimId')}
            >
              <ClaimDetailDrawer onExited={handleExited} />
            </Route>
          </>
        )}
      </RenderOnData>
    </PageWithHeaderAndFooterTemplate>
  )
}
