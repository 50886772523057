import {Card, CardContent, CardMedia, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React from 'react'
import {
  ClientShowPropertiesFragment,
  LibraryShowPropertiesFragment,
  ShowImageThumbnailSize,
  ShowTranslation
} from '../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../hooks/getLocales'
import {useTranslateShowType} from '../../../../hooks/showTypes'
import {useShowDurationFormatters} from '../../../../utils/formatting'

const useGetShowTitle = () => {
  const clientLocales = useGetClientLocales()
  return (
    translations: Pick<ShowTranslation, 'localeCode' | 'title'>[],
    originalTitle?: string | null
  ) => {
    const clientLocaleTranslations = translations.filter((translation) =>
      clientLocales.some(
        (clientLocale) => translation.localeCode === clientLocale
      )
    )
    return clientLocaleTranslations.length
      ? clientLocaleTranslations[0].title
      : originalTitle || translations[0].title
  }
}

const useStyles = makeStyles<
  Theme,
  {isClickable: boolean; maxWidth: string | number}
>((theme) => ({
  root: {
    display: 'flex',
    maxWidth: ({maxWidth}) => maxWidth,
    height: 160,
    cursor: ({isClickable}) => (isClickable ? 'pointer' : 'normal'),
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  },
  cover: {
    minWidth: 110
  },
  showName: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 3,
    overflow: 'hidden'
  }
}))

interface IShowCardProps {
  show: LibraryShowPropertiesFragment | ClientShowPropertiesFragment
  onClick?: () => void
  maxWidth?: string | number
}

export const ShowCard: React.FC<IShowCardProps> = ({
  show,
  onClick,
  maxWidth = 360
}: IShowCardProps) => {
  const classes = useStyles({isClickable: Boolean(onClick), maxWidth})
  const translateShowType = useTranslateShowType()
  const {durationToMinutesWithAbbreviatedUnitFormat} =
    useShowDurationFormatters()
  const getShowTitle = useGetShowTitle()
  const smallThumbnail =
    show.images?.length &&
    show.images[0].thumbnails?.length &&
    show.images[0].thumbnails?.find(
      (thumbnail) => thumbnail.size === ShowImageThumbnailSize.Small
    )
  const showImageUrl = smallThumbnail ? smallThumbnail.url : '/noPoster.png'
  return (
    <Card onClick={onClick} className={classes.root}>
      <CardMedia className={classes.cover} image={showImageUrl} />
      <CardContent>
        <Typography className={classes.showName} variant="subtitle1">
          {getShowTitle(show.translations, show.originalTitle)}
        </Typography>
        <Typography variant="overline">
          {[
            translateShowType(show.typeCode),
            show.globalReleaseDate &&
              dayjs(show.globalReleaseDate).format('YYYY'),
            show.duration &&
              durationToMinutesWithAbbreviatedUnitFormat(show.duration)
          ]
            .filter(Boolean)
            .join(' • ')}
        </Typography>
      </CardContent>
    </Card>
  )
}
