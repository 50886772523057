import {useTranslation} from 'react-i18next'
import {LoyaltyLevelStatus} from '../__generated__/schema'

export const useTranslateLoyaltyLevelStatus = () => {
  const {t} = useTranslation()
  const translated: Record<LoyaltyLevelStatus, string> = {
    [LoyaltyLevelStatus.Active]: t('LoyaltyLevelStatus->Active'),
    [LoyaltyLevelStatus.Expired]: t('LoyaltyLevelStatus->Expired'),
    [LoyaltyLevelStatus.Unspecified]: t('LoyaltyLevelStatus->Unspecified')
  }
  return (status: LoyaltyLevelStatus) => translated[status]
}
