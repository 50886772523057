import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../hooks/effectiveClientCurrencySignInputProps'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useHandleInvalidDateTimes} from '../../../../../hooks/pickerErrors'
import {Theme} from '../../../../../theme'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {useIsTicketPriceValid} from '../../../../../utils/formsValidations'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {FormDatetimeInput} from '../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {FormChipsGroupSelect} from '../../../../visual'
import {
  IProductPricingForm,
  PricingEnabled,
  ProductPricingFormField
} from './types'

const useStyles = makeStyles<Theme, {pricingEnabled: PricingEnabled}>(
  (theme) => ({
    form: {
      display: 'grid',
      gridTemplateAreas: `
      "chips      datetime   datetime"
      "labelsGrid labelsGrid labelsGrid"
      "pricesGrid pricesGrid pricesGrid"
      `,
      gridTemplateColumns: 'auto 1fr 1fr',
      gap: theme.spacing(2)
    },
    datetime: {
      gridArea: 'datetime',
      visibility: ({pricingEnabled}) =>
        pricingEnabled === PricingEnabled.Schedule ? 'visible' : 'hidden'
    },
    labelsGrid: {
      gridArea: 'labelsGrid',
      display: 'grid',
      gridTemplateColumns: '1fr 150px 150px',
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2)
    },
    pricesGrid: {
      display: 'grid',
      gridArea: 'pricesGrid',
      gridAutoFlow: 'row',
      gridTemplateColumns: '1fr 150px 150px',
      gap: theme.spacing(4, 2),
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3)
    },
    priceAndVat: {
      alignSelf: 'flex-start',
      height: 48
    },
    chips: {
      position: 'relative',
      top: -theme.spacing(3.5)
    }
  })
)

interface IPricingFormProps {
  formId: string
  onSubmit: (data: IProductPricingForm) => void
  vatRates: number[]
  defaultValues?: Partial<IProductPricingForm>
  className?: string
}

export const PricingForm: React.FC<IPricingFormProps> = ({
  formId,
  onSubmit,
  vatRates,
  defaultValues,
  className
}: IPricingFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    setError,
    watch,
    register,
    unregister,
    triggerValidation,
    handleSubmit,
    clearError,
    control
  } = useForm<IProductPricingForm>({defaultValues})
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  const isTicketPriceValid = useIsTicketPriceValid()
  const pricingEnabled = watch(ProductPricingFormField.PricingEnabled)
  const _handleSubmit = useCallback(
    async (data: IProductPricingForm) => {
      try {
        await onSubmit(data)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidProductPricingInput
          )
        ) {
          setError(
            ProductPricingFormField.Datetime,
            t("Pricing can't start in the past.")
          )
        } else {
          defaultErrorHandler(error, t('Error while creating product pricing'))
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  const classes = useStyles({pricingEnabled})
  const handleInvalidDateTimes =
    useHandleInvalidDateTimes<IProductPricingForm>(setError)
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(handleInvalidDateTimes(_handleSubmit))}
      className={cn(classes.form, className)}
    >
      <input
        type="hidden"
        name={ProductPricingFormField.DivisionId}
        ref={register()}
      />
      <div className={classes.chips}>
        <FormChipsGroupSelect<IProductPricingForm, PricingEnabled>
          validationOptions={{
            required: true
          }}
          errors={errors}
          register={register}
          setValue={setValue}
          unregister={unregister}
          watch={watch}
          label={t('Price enabled from')}
          name={ProductPricingFormField.PricingEnabled}
          key={ProductPricingFormField.PricingEnabled}
          items={[
            {
              label: t('Now'),
              value: PricingEnabled.Now
            },
            {
              label: t('Schedule'),
              value: PricingEnabled.Schedule
            }
          ]}
        />
      </div>
      <div className={classes.datetime}>
        {pricingEnabled === PricingEnabled.Schedule && (
          <FormDatetimeInput<IProductPricingForm>
            dataTimePickerProps={{
              label: t('Enabled from'),
              disablePast: true
            }}
            validationOptions={{
              required: true
            }}
            fullWidth
            name={ProductPricingFormField.Datetime}
            unregister={unregister}
            setError={setError}
            clearError={clearError}
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
          />
        )}
      </div>
      <div className={classes.labelsGrid}>
        <Typography variant="caption" color="textSecondary">
          {t('Channel')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('Price incl. VAT')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('VAT rate')}
        </Typography>
      </div>
      <div className={classes.pricesGrid}>
        <Typography variant="subtitle2">{t('Retail and Ecommerce')}</Typography>
        <div className={classes.priceAndVat}>
          <UncontrolledFormTextInput<IProductPricingForm>
            InputProps={currencySignInputProps}
            register={register}
            setValue={setValue}
            watch={watch}
            errors={errors}
            name={ProductPricingFormField.RetailPrice}
            validationOptions={{
              required: true,
              validate: {
                isTicketPriceValid
              }
            }}
            triggerValidation={triggerValidation}
            fullWidth
          />
        </div>
        <div className={classes.priceAndVat}>
          <UncontrolledFormSelect<IProductPricingForm>
            name={ProductPricingFormField.RetailVatRate}
            validationOptions={{required: true}}
            selectOptions={vatRates.reduce(
              (acc, vatRate) => ({
                ...acc,
                [String(vatRate)]: t('{{vatRate}} %', {vatRate})
              }),
              {}
            )}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            fullWidth
          />
        </div>
      </div>
    </form>
  )
}
