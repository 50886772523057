import DragHandleIcon from '@mui/icons-material/DragHandle'
import {Button, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {TemplateProps} from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {TemplatePropertiesFragment} from '../../../../__generated__/schema'
import {useTranslateLocaleCodeName} from '../../../../hooks/translateLocales'
import {useTranslateTemplateFileType} from '../../../../hooks/translateTemplates'
import {Theme} from '../../../../theme'

export interface IAssignedTemplateItemCommonProps {
  isLastItem: (id: number) => boolean
  getRemoveButtonClickHandler: (templateId: number) => () => void
}

export interface IAssignedTemplateItemProps
  extends TemplateProps<
    TemplatePropertiesFragment,
    IAssignedTemplateItemCommonProps
  > {}

const useStyles = makeStyles<Theme, {displayDivider: boolean}>((theme) => ({
  root: {
    display: 'grid',
    padding: theme.spacing(1, 1.5),
    gap: theme.spacing(0, 1),
    gridTemplateAreas: `
      "dragHandle primaryText removeButton"
      "dragHandle secondaryText removeButton"
    `,
    gridTemplateColumns: 'auto 1fr auto',
    borderBottom: ({displayDivider}) =>
      displayDivider ? `solid 1px ${theme.palette.divider}` : 'none'
  },
  removeButton: {
    gridArea: 'removeButton',
    alignSelf: 'center'
  },
  dragHandle: {
    gridArea: 'dragHandle'
  }
}))

export const AssignedTemplateItem: React.FC<IAssignedTemplateItemProps> = ({
  item: template,
  commonProps: {isLastItem, getRemoveButtonClickHandler},
  dragHandleProps,
  itemSelected
}: IAssignedTemplateItemProps) => {
  const {t} = useTranslation()
  const classes = useStyles({
    displayDivider: !isLastItem(template.id) || itemSelected !== 0
  })
  const translateFileType = useTranslateTemplateFileType()
  const translateLocaleCodeName = useTranslateLocaleCodeName()
  return (
    <div className={classes.root}>
      <IconButton {...dragHandleProps} className={classes.dragHandle}>
        <DragHandleIcon />
      </IconButton>
      <Typography variant="subtitle2">
        {[`#${template.id}`, template.name].join('  •  ')}
      </Typography>
      <Typography color="textSecondary" variant="caption">
        {[
          t('Filetype {{templateFileType}}', {
            templateFileType: translateFileType(template.fileType)
          }),
          translateLocaleCodeName(template.localeCode)
        ].join(' • ')}
      </Typography>
      <Button
        color="primary"
        className={classes.removeButton}
        onClick={getRemoveButtonClickHandler(template.id)}
      >
        {t('Remove')}
      </Button>
    </div>
  )
}

export class DraggableAssignedTemplateItem extends React.Component<IAssignedTemplateItemProps> {
  render() {
    return <AssignedTemplateItem {...this.props} />
  }
}
