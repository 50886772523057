import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, IconButton, SvgIconProps, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../theme'
import {COLOR_CONF} from '../constants'

export enum InfoModalColor {
  Green = 'GREEN',
  Red = 'RED',
  Blue = 'BLUE',
  Purple = 'PURPLE'
}

export interface IInfoModalProps {
  title: string
  color: InfoModalColor
  Icon: React.FC<SvgIconProps>
  primaryDescription?: string
  secondaryDescription?: string
  onInfoClick?: () => void
  actions?: React.ReactNode
  className?: string
  sx?: SxProps<Theme>
}

const useStyles = makeStyles<
  Theme,
  {
    primaryColor: string
    secondaryColor: string
  }
>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'grid',
    gridTemplateColumns: '48px 1fr auto',
    border: ({primaryColor}) => `1px solid ${primaryColor}`,
    borderRadius: theme.shape.borderRadius
  },
  iconBox: {
    alignSelf: 'stretch',
    justifySelf: 'stretch',
    color: ({secondaryColor}) => secondaryColor,
    backgroundColor: ({primaryColor}) => primaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2
  },
  infoModalBody: {
    padding: theme.spacing(1)
  },
  infoButton: {
    alignSelf: 'center'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1)
  }
}))

export const InfoModal: React.FC<IInfoModalProps> = ({
  className,
  Icon,
  onInfoClick,
  color,
  primaryDescription,
  secondaryDescription,
  actions,
  title,
  sx
}: IInfoModalProps) => {
  const colorConf = COLOR_CONF[color] || COLOR_CONF.RED
  const classes = useStyles({
    primaryColor: colorConf.color,
    secondaryColor: colorConf.background
  })
  return (
    <Box className={cn(classes.root, className)} sx={sx}>
      <div className={classes.iconBox}>
        <Icon />
      </div>
      <div className={classes.infoModalBody}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        {primaryDescription && (
          <Typography variant="body2" component="div">
            {primaryDescription}
          </Typography>
        )}
        {secondaryDescription && (
          <Typography variant="body2" color="textSecondary" component="div">
            {secondaryDescription}
          </Typography>
        )}
        {actions && <div className={classes.actions}>{actions}</div>}
      </div>
      {onInfoClick && (
        <IconButton
          color="inherit"
          className={classes.infoButton}
          onClick={onInfoClick}
        >
          <InfoOutlinedIcon />
        </IconButton>
      )}
    </Box>
  )
}
