import {
  DataGridProProps,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro'
import {clsx} from 'clsx'
import {isNil} from 'lodash'
import React, {MutableRefObject, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {AdmissionTypesStatisticsQuery} from '../../../../../__generated__/schema'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../../hooks/translateCurrencies'
import {
  DataGridTable,
  DecimalCellRenderer,
  replaceDotWithCommaFormatter
} from '../../../../common/DataGridTable'

const getCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''

interface IRevenuesTableProps {
  data: AdmissionTypesStatisticsQuery | undefined
  apiRef?: MutableRefObject<GridApiPro>
  loading?: boolean
  paginationProps?: Pick<
    DataGridProProps,
    | 'rowCount'
    | 'paginationModel'
    | 'paginationMode'
    | 'onPaginationModelChange'
    | 'pageSizeOptions'
  >
  dataGridProps?: Pick<
    DataGridProProps,
    | 'disableColumnMenu'
    | 'autoHeight'
    | 'disableRowSelectionOnClick'
    | 'pagination'
    | 'hideFooter'
  >
}

export const RevenuesTable: React.FC<IRevenuesTableProps> = ({
  data,
  apiRef,
  loading,
  paginationProps,
  dataGridProps
}: IRevenuesTableProps) => {
  const {t} = useTranslation()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        sortable: false,
        minWidth: 300,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'capacityDecreaseCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Starting quantity'),
        field: 'startingQuantity',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'revenue',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'revenueTicketCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'revenueAttendeesCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'saleSum',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'saleTicketCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'saleAttendeesCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'refundSum',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'refundTicketCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'refundAttendeesCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      }
    ],
    [t, translatedEffectiveClientCurrencySign]
  )
  return (
    <DataGridTable
      sx={{
        [`& .${gridClasses.withBorderColor}`]: {
          borderColor: 'divider'
        },
        [`& .${gridClasses.columnSeparator}`]: {
          color: 'divider'
        },
        '& .bold': {fontWeight: 'bold'}
      }}
      apiRef={apiRef}
      columns={columns}
      loading={loading}
      rows={data?.admissionTypesStatistics.items || []}
      experimentalFeatures={{columnGrouping: true}}
      columnHeaderHeight={32}
      disableRowSelectionOnClick
      initialState={{
        pinnedColumns: {left: ['name']},
        pagination: paginationProps
          ? {}
          : {
              paginationModel: {
                pageSize: data ? data.admissionTypesStatistics.items.length : 5
              }
            }
      }}
      getRowId={(row) => row.admissionTypeId}
      columnGroupingModel={[
        {
          groupId: t('Admission type'),
          children: [{field: 'name'}],
          headerAlign: 'left'
        },
        {
          groupId: t('Summary'),
          children: [
            {field: 'revenue'},
            {field: 'revenueTicketCount'},
            {field: 'revenueAttendeesCount'}
          ],
          headerAlign: 'left'
        },
        {
          groupId: t('Sales'),
          children: [
            {field: 'saleSum'},
            {field: 'saleTicketCount'},
            {field: 'saleAttendeesCount'}
          ],
          headerAlign: 'left'
        },
        {
          groupId: t('Refunds'),
          children: [
            {field: 'refundSum'},
            {field: 'refundTicketCount'},
            {field: 'refundAttendeesCount'}
          ],
          headerAlign: 'left'
        }
      ]}
      pinnedRows={{
        bottom: [
          {
            admissionTypeId: 0,
            name: t('Summary'),
            revenue: data?.admissionTypesStatistics.totals.revenue,
            revenueTicketCount:
              data?.admissionTypesStatistics.totals.revenueTicketCount,
            revenueAttendeesCount:
              data?.admissionTypesStatistics.totals.revenueAttendeesCount,
            saleSum: data?.admissionTypesStatistics.totals.saleSum,
            saleTicketCount:
              data?.admissionTypesStatistics.totals.saleTicketCount,
            saleAttendeesCount:
              data?.admissionTypesStatistics.totals.saleAttendeesCount,
            refundSum: data?.admissionTypesStatistics.totals.refundSum,
            refundTicketCount:
              data?.admissionTypesStatistics.totals.refundTicketCount,
            refundAttendeesCount:
              data?.admissionTypesStatistics.totals.refundAttendeesCount
          }
        ]
      }}
      {...(paginationProps
        ? {
            ...paginationProps,
            disableColumnMenu: true
          }
        : {
            pageSizeOptions: [
              data ? data.admissionTypesStatistics.items.length : 5
            ]
          })}
      {...dataGridProps}
    />
  )
}
