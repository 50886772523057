import {ICoords, IDimensions} from '@attendio/shared-components'

export const vectorLength = (x: number, y: number) => {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
}

export const vectorToRadians = (x: number, y: number) => {
  return Math.atan2(y, x)
}

export const radiansToUnitVector = (radians: number) => {
  return {
    x: Math.cos(radians),
    y: Math.sin(radians)
  }
}

export const degreesToRadians = (degrees: number) => {
  return degrees * (Math.PI / 180)
}

export const radiansToDegrees = (angle: number) => {
  return (180 * angle) / Math.PI
}

export const findNearestMultiple = (value: number, multiple: number) => {
  return Math.round(value / multiple) * multiple
}

// forces minimum value (in absolute) but preserves sign
export const getFartherFromZero = (value: number, minValue: number) => {
  return value < 0
    ? Math.min(value, -Math.abs(minValue))
    : Math.max(value, Math.abs(minValue))
}

export const customizeDimensions = (
  originalDimensions: IDimensions,
  minWidth: number,
  minHeight: number
) => {
  return {
    width: getFartherFromZero(originalDimensions.width, minWidth),
    height: getFartherFromZero(originalDimensions.height, minHeight)
  }
}

interface IRotatePointAroundOriginProps {
  point: ICoords
  angle: number // degrees
  center: ICoords
}

// See: https://stackoverflow.com/a/12161405/5549615
export const rotatePointAroundAnother = ({
  point,
  angle,
  center
}: IRotatePointAroundOriginProps): ICoords => {
  const angleInRadians = degreesToRadians(angle)

  const sinValue = Math.sin(angleInRadians)
  const cosValue = Math.cos(angleInRadians)

  // translate the point as if the center was in the origin
  const translatedPoint = {
    x: point.x - center.x,
    y: point.y - center.y
  }

  // rotate the point around the origin by the required angle
  const rotatedPoint = {
    x: translatedPoint.x * cosValue - translatedPoint.y * sinValue,
    y: translatedPoint.x * sinValue + translatedPoint.y * cosValue
  }

  // translate the rotated point back
  return {
    x: rotatedPoint.x + center.x,
    y: rotatedPoint.y + center.y
  }
}
