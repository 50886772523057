import CancelIcon from '@mui/icons-material/Cancel'
import {Box, Chip, Typography} from '@mui/material'
import {difference, noop} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TourItemPropertiesFragment} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Tooltip} from '../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {MassCheckbox, MassCheckboxState} from '../../../../common/MassCheckbox'
import {Blank, BlankContentPosition} from '../../../../visual/Blank'
import {TicketsSubtotal} from '../../components/TicketsSubtotal'
import {Item} from './Item'

interface ITourTimeSlotContentProps {
  tourItems: TourItemPropertiesFragment[]
}

export const TourTimeSlotContent: React.FC<ITourTimeSlotContentProps> = ({
  tourItems
}: ITourTimeSlotContentProps) => {
  const {t} = useTranslation()
  const tourItemsWithAllowedDiscounts = tourItems.filter(
    (item) => item.admissionTypeAssignment.hasEnabledDiscountsOnRetail
  )
  const idsOfTourItemsWithAllowedDiscounts = tourItemsWithAllowedDiscounts.map(
    ({id}) => id
  )
  const selectableTourItemsIds = tourItemsWithAllowedDiscounts
    .filter((item) => item.appliedDiscounts.length === 0)
    .map(({id}) => id)
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([])
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const checkboxesControllerState =
    selectedItemIds.length === 0
      ? MassCheckboxState.Unselected
      : difference(selectableTourItemsIds, selectedItemIds).length === 0
      ? MassCheckboxState.Selected
      : MassCheckboxState.Indeterminate
  const createItemCheckboxClickHandler = useCallback(
    (tourItemId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedItemIds((ids) =>
        ids.includes(tourItemId)
          ? ids.filter((id) => id !== tourItemId)
          : [...ids, tourItemId]
      )
    },
    []
  )
  return (
    <Box
      sx={{display: 'grid', height: '100%', gridTemplateColumns: '360px 1fr'}}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          overflow: 'hidden'
        }}
      >
        <MassCheckbox
          state={checkboxesControllerState}
          onClick={() =>
            checkboxesControllerState === MassCheckboxState.Unselected
              ? setSelectedItemIds(selectableTourItemsIds)
              : setSelectedItemIds([])
          }
          sx={{
            pl: 2,
            minHeight: 56,
            borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
          }}
        />
        <ListOfItemsSeparatedByDividers
          DividerProps={{
            sx: {my: 0, mx: 2}
          }}
        >
          {tourItems.map((tourItem) => {
            const areDiscountsAllowed =
              idsOfTourItemsWithAllowedDiscounts.includes(tourItem.id)
            const hasAppliedDiscount = tourItem.appliedDiscounts.length > 0
            return (
              <Item
                key={tourItem.id}
                checkboxProps={{
                  checked: selectedItemIds.includes(tourItem.id),
                  disabled: hasAppliedDiscount,
                  sx: !areDiscountsAllowed ? {visibility: 'hidden'} : {}
                }}
                isDisabled={!areDiscountsAllowed}
                onClick={
                  areDiscountsAllowed && !hasAppliedDiscount
                    ? createItemCheckboxClickHandler(tourItem.id)
                    : undefined
                }
                primaryLabel={tourItem.name}
                priceLabel={translateEffectiveClientPrice(tourItem.price)}
                priceBeforeDiscountLabel={
                  tourItem.priceBeforeDiscount !== tourItem.price
                    ? translateEffectiveClientPrice(
                        tourItem.priceBeforeDiscount
                      )
                    : undefined
                }
                sx={{px: 2, py: 1}}
              >
                {!areDiscountsAllowed && (
                  <Typography variant="caption">
                    {t("Can't be discounted")}
                  </Typography>
                )}
                {hasAppliedDiscount &&
                  tourItem.appliedDiscounts.map((appliedDiscount) => (
                    <Chip
                      sx={{
                        maxWidth: 184,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      key={appliedDiscount.discount.id}
                      onDelete={noop}
                      label={
                        <Tooltip title={appliedDiscount.discount.name}>
                          <span>{appliedDiscount.discount.name}</span>
                        </Tooltip>
                      }
                      deleteIcon={
                        <Tooltip title={t<string>('Remove discount')}>
                          <CancelIcon />
                        </Tooltip>
                      }
                    />
                  ))}
              </Item>
            )
          })}
        </ListOfItemsSeparatedByDividers>
        <TicketsSubtotal
          items={tourItems}
          description={t('Discounted {{discountedCount}} of {{count}} ticket', {
            count: selectableTourItemsIds.length,
            discountedCount: tourItemsWithAllowedDiscounts.filter(
              (tourItem) => tourItem.appliedDiscounts.length
            ).length
          })}
        />
      </Box>
      <Box>
        <Blank
          title={t('No discounts found')}
          description={t(
            'There are no relevant discounts for this tour time slot.'
          )}
          contentPosition={BlankContentPosition.Center}
        />
      </Box>
    </Box>
  )
}
