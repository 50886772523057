export enum AddWarehouseProductFormField {
  ProductId = 'productId',
  Quantity = 'quantity',
  Price = 'price',
  Vat = 'vat',
  Note = 'note'
}

export interface IAddWarehouseProductForm {
  [AddWarehouseProductFormField.ProductId]: number
  [AddWarehouseProductFormField.Quantity]: number
  [AddWarehouseProductFormField.Price]: number
  [AddWarehouseProductFormField.Vat]: string
  [AddWarehouseProductFormField.Note]?: string
}
