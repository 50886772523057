import DivisionIcon from '@mui/icons-material/Store'
import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DivisionInput,
  DivisionInvoiceFreq,
  DivisionType
} from '../../../../__generated__/schema'
import {routeTo} from '../../../../utils/routes'
import {
  BottomBackButton,
  EntityCard,
  SingleSideNavigationList
} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {useNotifications} from '../../../context/notifications'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {DIVISION_FORM_ID, DivisionForm} from './divisionForm/DivisionForm'
import {useDivisionFormAnchors} from './divisionForm/divisionFormAnchors'

import {
  DivisionFormField,
  getDivisionInputFromFormData,
  IDivisionFormData
} from './divisionForm/types'
import {useCreateDivision} from './graphql'

export const AddDivision: React.FC = () => {
  const createDivision = useCreateDivision()
  const history = useHistory()
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const divisionFormAnchors = useDivisionFormAnchors()

  const onSubmit = useCallback(
    async (data: IDivisionFormData) => {
      await createDivision({
        data: getDivisionInputFromFormData(data) as DivisionInput
      })
      addInfoNotification(t('Division created'))
      history.push(routeTo.admin.divisions.home())
    },
    [addInfoNotification, createDivision, history, t]
  )

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Create division')} />}
      footer={
        <Footer>
          <BottomBackButton />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={DIVISION_FORM_ID}
          >
            {t('Create')}
          </Button>
        </Footer>
      }
    >
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage his divisions.')}
      >
        <CenteredLayout>
          <CenteredLayoutListWrapper>
            <SingleSideNavigationList items={divisionFormAnchors} />
            <EntityCard header={t('New division')} Icon={DivisionIcon} />
            <DivisionForm
              onSubmit={onSubmit}
              submitErrorMessage={t('Error while creating division')}
              defaultValues={{
                [DivisionFormField.TYPE]: DivisionType.Cinema,
                [DivisionFormField.INVOICE_FREQ]: DivisionInvoiceFreq.ByEvent,
                [DivisionFormField.ONLINE_PURCHASE_ACTIVE]: false,
                [DivisionFormField.POS_PURCHASE_ACTIVE]: false,
                [DivisionFormField.ONLINE_RESERVATION_ACTIVE]: false,
                [DivisionFormField.POS_RESERVATION_ACTIVE]: false
              }}
            />
          </CenteredLayoutListWrapper>
        </CenteredLayout>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
