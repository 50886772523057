import React from 'react'
import {TicketItemPropertiesFragment} from '../../../__generated__/schema'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../hooks/translateDistributions'
import {useDateTimeFormatters} from '../../../utils/formatting'
import {CartSummaryItem} from './CartSummaryItem'

export interface ICartSummaryTicketItemProps {
  item: TicketItemPropertiesFragment
  count: number
  total: number
  onClick?: () => void
  translatePrice: (value: number) => string
  title: string
}

export const CartSummaryTicketItem: React.FC<ICartSummaryTicketItemProps> = ({
  item,
  total,
  count,
  onClick,
  translatePrice,
  title
}: ICartSummaryTicketItemProps) => {
  const isReservation = Boolean(item.reservation?.id)
  const {formatDateNumericShort, formatTime} = useDateTimeFormatters()
  const startsAtDate = new Date(item.eventSeat.event.startsAt)
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()

  const partOfDescriptionAfterSlash = isReservation
    ? `#${item.reservation!.id}`
    : [
        item.eventSeat.event.formatCode &&
          translateShowFormatAbbreviation(item.eventSeat.event.formatCode),
        item.eventSeat.event.versionCode &&
          translateShowVersionAbbreviation(item.eventSeat.event.versionCode),
        item.eventSeat.event.soundMixCode &&
          translateShowSoundMixAbbreviation(item.eventSeat.event.soundMixCode)
      ]
        .filter(Boolean)
        .join(' • ')

  return (
    <CartSummaryItem
      count={count}
      showBookmark={isReservation}
      onClick={onClick}
      description={[
        `${formatDateNumericShort(startsAtDate)} • ${formatTime(startsAtDate)}`,
        partOfDescriptionAfterSlash
      ]
        .filter(Boolean)
        .join(' | ')}
      price={translatePrice(total)}
      title={title}
    />
  )
}
