import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  TourTimeSlotsStatisticsFilterInput
} from '../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../hooks/formatUserName'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  DataGridTable,
  useAgeClassificationFormatter,
  useDataGridPagination,
  useDateTimeFormatter,
  useGetTranslatedValueFormatter,
  useVersionCodeFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ReloadButton} from '../components/ReloadButton'
import {useGetFilterDateRange} from '../utils'
import {useTourTimeSlotsStatistics} from './graphql'
import {StateChip} from './StateChip'
import {TimeSlotStateChip} from './types'
import {getTourTimeSlotsStates} from './utils'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(
        routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsRevenues(
          id
        ),
        {
          from: routeTo.admin.tourTimeSlotsStatistics.availability()
        }
      ),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IAvailabilityProps {
  searchFilter: TourTimeSlotsStatisticsFilterInput
}

export const Availability: React.FC<IAvailabilityProps> = ({
  searchFilter
}: IAvailabilityProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [selectedTourTimeSlotState, setSelectedTourTimeSlotState] =
    useState<TimeSlotStateChip>(TimeSlotStateChip.Published)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.Today
    }
  )
  const getFilterDateRange = useGetFilterDateRange()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useTourTimeSlotsStatistics({
    filter: {
      tourTimeSlotStates: getTourTimeSlotsStates(selectedTourTimeSlotState),
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'startsAtFrom',
        filterNameTo: 'startsAtTo'
      }),
      ...searchFilter
    },
    paginationInput
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const getTranslatedValueFormatter = useGetTranslatedValueFormatter()
  const ageClassificationFormatter = useAgeClassificationFormatter()
  const formatShortGuideName = useFormatShortGuideName(true)
  const versionCodeFormatter = useVersionCodeFormatter()
  const handleStateChange = useCallback(
    (state: TimeSlotStateChip) => {
      setSelectedTourTimeSlotState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Starts at'),
        field: 'startsAt',
        sortable: false,
        minWidth: 200,
        valueFormatter: dateTimeFormatter
      },
      {
        headerName: t('Name'),
        field: 'names',
        sortable: false,
        minWidth: 350,
        valueFormatter: getTranslatedValueFormatter
      },
      {
        headerName: t('Limit'),
        field: 'retailAttendeesLimit',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Available'),
        field: 'retailAvailable',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) =>
          typeof params.row.retailAttendeesLimit === 'number'
            ? params.row.retailAttendeesLimit -
              params.row.tourItemsStateCounts.sold -
              params.row.tourItemsStateCounts.reserved -
              params.row.tourItemsStateCounts.inCart
            : ''
      },
      {
        headerName: t('Limit'),
        field: 'eCommerceAttendeesLimit',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Available'),
        field: 'eCommerceAvailable',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) =>
          typeof params.row.eCommerceAttendeesLimit === 'number'
            ? params.row.eCommerceAttendeesLimit -
              params.row.tourItemsStateCounts.sold -
              params.row.tourItemsStateCounts.reserved -
              params.row.tourItemsStateCounts.inCart
            : ''
      },
      {
        headerName: t('Sold'),
        field: 'soldCount',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.tourItemsStateCounts.sold
      },
      {
        headerName: t('Reserved'),
        field: 'reservedCount',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.tourItemsStateCounts.reserved
      },
      {
        headerName: t('In cart'),
        field: 'inCartCount',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.tourItemsStateCounts.inCart
      },
      {
        headerName: t('Venue'),
        field: 'venue',
        minWidth: 250,
        valueGetter: (params) => params.row.venue,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Division'),
        field: 'division',
        minWidth: 250,
        valueGetter: (params) => params.row.division,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Version'),
        field: 'versionCode',
        minWidth: 250,
        valueFormatter: versionCodeFormatter,
        sortable: false
      },
      {
        headerName: t('Age restriction'),
        field: 'ageClassificationCode',
        minWidth: 250,
        valueFormatter: ageClassificationFormatter,
        sortable: false
      },
      {
        headerName: t('Guide'),
        field: 'guide',
        minWidth: 200,
        valueGetter: (params) => params.row.guide,
        valueFormatter: (params) =>
          params.value ? formatShortGuideName(params.value) : '',
        sortable: false
      },
      {
        headerName: t('Cost center'),
        field: 'costCenter',
        minWidth: 250,
        valueGetter: (params) => params.row.costCenter,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Event category'),
        field: 'eventCategory',
        minWidth: 250,
        valueGetter: (params) => params.row.eventCategory,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Marketing label'),
        field: 'marketingLabel',
        minWidth: 250,
        valueGetter: (params) => params.row.marketingLabel,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Tour'),
        field: 'tourName',
        minWidth: 250,
        valueGetter: (params) => params.row.tour,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.tourTimeSlotId,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      ageClassificationFormatter,
      dateTimeFormatter,
      formatShortGuideName,
      getTranslatedValueFormatter,
      t,
      versionCodeFormatter
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading tour time slots statistics')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <SubHeaderToolbar
        title={t('Availability')}
        leftActions={[
          <StateChip
            key="state-chip"
            state={selectedTourTimeSlotState}
            onChange={handleStateChange}
          />
        ]}
        rightActions={[
          <DatePickerButton
            key="tour-time-slot-date-picker"
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.FromToday
            }}
            tooltip={t('Tour time slot date')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            }
          }}
          columns={columns}
          loading={loading}
          rows={data?.tourTimeSlotsStatistics.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          initialState={{pinnedColumns: {left: ['startsAt'], right: ['arrow']}}}
          getRowId={(row) => row.tourTimeSlotId}
          columnGroupingModel={[
            {
              groupId: t('Time slot'),
              children: [{field: 'startsAt'}]
            },
            {
              groupId: t('Retail'),
              children: [
                {field: 'retailAttendeesLimit'},
                {field: 'retailAvailable'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Ecommerce'),
              children: [
                {field: 'eCommerceAttendeesLimit'},
                {field: 'eCommerceAvailable'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Summary'),
              children: [
                {field: 'soldCount'},
                {field: 'reservedCount'},
                {field: 'inCartCount'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Time slot details'),
              children: [
                {field: 'venue'},
                {field: 'division'},
                {field: 'versionCode'},
                {field: 'ageClassificationCode'},
                {field: 'guide'},
                {field: 'costCenter'},
                {field: 'eventCategory'},
                {field: 'marketingLabel'},
                {field: 'tourName'}
              ],
              headerAlign: 'left'
            }
          ]}
          columnVisibilityModel={{
            arrow: P([PermissionCode.ReadAdmissionTypesStatistics])
          }}
          {...getDataGridPaginationProps(
            data?.tourTimeSlotsStatistics.pagination
          )}
        />
      </Box>
    </Box>
  )
}
