import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box, IconButton} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CustomersFilterInput,
  CustomerState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateCustomerState} from '../../../../hooks/translateCustomerState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, Tooltip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useDateNumericFormatter,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useTranslateCountryFormatter
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {customerStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {CustomerGroupChip} from './CustomerGroupChip'
import {CustomerStateChip} from './CustomerStateChip'
import {useGetCustomers} from './graphql'
import {MoreMenu} from './MoreMenu'

const StateRenderer = ({state}: {state: CustomerState}) => {
  const translateCustomerState = useTranslateCustomerState()
  return (
    <EntityStateChip
      colorConf={customerStateColors[state]}
      label={translateCustomerState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.customers.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface ICustomersListProps {
  searchFilter: CustomersFilterInput
}

export const CustomersList: React.FC<ICustomersListProps> = ({
  searchFilter
}: ICustomersListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const dataGridApiRef = useGridApiRef()
  const [customerState, setCustomerState] =
    useState<CustomerState | undefined>(undefined)
  const [customerGroupId, setCustomerGroupId] =
    useState<number | undefined>(undefined)
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useGetCustomers({
    filter: {
      ...searchFilter,
      states: customerState ? [customerState] : undefined,
      customerGroupIds: customerGroupId ? [customerGroupId] : undefined
    },
    paginationInput
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const dateNumericFormatter = useDateNumericFormatter()
  const translateCountryFormatter = useTranslateCountryFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Username'),
        field: 'username',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Email'),
        field: 'lead.data.email',
        valueGetter: (params) => params.row.lead?.data.email,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('Created'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: CustomerState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 50,
        sortable: false
      },
      {
        headerName: t('Loyalty ID'),
        field: 'loyaltyId',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Privacy policy'),
        field: 'privacyPolicyConsentGrantedAt',
        valueFormatter: (params) =>
          params.value ? t('Granted') : t('Not granted'),
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Benefits expiration date'),
        field: 'loyaltyLevelExpirationDate',
        valueFormatter: dateNumericFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Paid amount'),
        field: 'totalPurchaseAmount',
        valueGetter: (params) => params.row.loyaltyScore.totalPurchaseAmount,
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Sold carts'),
        field: 'soldCartsCount',
        valueGetter: (params) => params.row.loyaltyScore.soldCartsCount,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Sales count'),
        field: 'salesCount',
        valueGetter: (params) => params.row.loyaltyScore.salesCount,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Total tickets'),
        field: 'totalTicketsCount',
        valueGetter: (params) => params.row.loyaltyScore.totalTicketsCount,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Paid tickets'),
        field: 'paidTicketsCount',
        valueGetter: (params) => params.row.loyaltyScore.paidTicketsCount,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Free tickets'),
        field: 'freeTicketsCount',
        valueGetter: (params) => params.row.loyaltyScore.freeTicketsCount,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Updated at'),
        field: 'loyaltyScoreUpdatedAt',
        valueGetter: (params) => params.row.loyaltyScore.updatedAt,
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->Name'),
        field: 'lead.data.name',
        valueGetter: (params) => params.row.lead?.data.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('LeadField->Phone'),
        field: 'lead.data.phone',
        valueGetter: (params) => params.row.lead?.data.phone,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->Note'),
        field: 'lead.data.note',
        valueGetter: (params) => params.row.lead?.data.note,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->CompanyName'),
        field: 'lead.data.companyName',
        valueGetter: (params) => params.row.lead?.data.companyName,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->CompanyIdNumber'),
        field: 'lead.data.companyIdNumber',
        valueGetter: (params) => params.row.lead?.data.companyIdNumber,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->TaxId'),
        field: 'lead.data.TAXId',
        valueGetter: (params) => params.row.lead?.data.TAXid,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->VatId'),
        field: 'lead.data.VATId',
        valueGetter: (params) => params.row.lead?.data.VATid,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingAddressStreet'),
        field: 'lead.data.billingAddressStreet',
        valueGetter: (params) => params.row.lead?.data.billingAddressStreet,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingAddressTown'),
        field: 'lead.data.billingAddressTown',
        valueGetter: (params) => params.row.lead?.data.billingAddressTown,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingPostalCode'),
        field: 'lead.data.billingPostalCode',
        valueGetter: (params) => params.row.lead?.data.billingPostalCode,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->BillingAddressCountry'),
        field: 'lead.data.billingAddressCountry',
        valueGetter: (params) => params.row.lead?.data.billingAddressCountry,
        valueFormatter: translateCountryFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressee'),
        field: 'lead.data.deliveryAddressee',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressee,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressStreet'),
        field: 'lead.data.deliveryAddressStreet',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressStreet,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressTown'),
        field: 'lead.data.deliveryAddressTown',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressTown,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryPostalCode'),
        field: 'lead.data.deliveryPostalCode',
        valueGetter: (params) => params.row.lead?.data.deliveryPostalCode,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->DeliveryAddressCountry'),
        field: 'lead.data.deliveryAddressCountry',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressCountry,
        valueFormatter: translateCountryFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      dateNumericFormatter,
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      t,
      translateCountryFormatter
    ]
  )
  const handleCustomerStateChange = useCallback(
    (state: CustomerState | undefined) => {
      setCustomerState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  if (error) {
    return <Error error={error} message={t('Error while loading customers')} />
  }
  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <CustomerStateChip
            key="customer-state-chip"
            selectedState={customerState}
            setSelectedState={handleCustomerStateChange}
          />,
          P([PermissionCode.ReadCustomerGroups]) && (
            <CustomerGroupChip
              key="customer-group-chip"
              onChange={setCustomerGroupId}
            />
          )
        ].filter(Boolean)}
        rightActions={[
          <Tooltip key="refetch-button" title={t('Reload')}>
            <IconButton onClick={handleRefetchButtonClick}>
              <RefreshOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>,
          <MoreMenu
            key="more-menu"
            onCustomersListCsvButtonClick={() =>
              dataGridApiRef.current.exportDataAsCsv({
                fileName: t<string>('Customers')
              })
            }
          />
        ]}
      />
      <Box sx={{height: 'calc(100% - 112px)', width: '100%', p: 3}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            }
          }}
          apiRef={dataGridApiRef}
          columns={columns}
          loading={loading}
          rows={data?.customers.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          initialState={{pinnedColumns: {left: ['username'], right: ['id']}}}
          columnGroupingModel={[
            {
              groupId: t('Customer'),
              children: [
                {field: 'createdAt'},
                {field: 'lead.data.email'},
                {field: 'state'},
                {field: 'privacyPolicyConsentGrantedAt'},
                {field: 'loyaltyLevelExpirationDate'},
                {field: 'loyaltyId'}
              ]
            },
            {
              groupId: t('Loyalty score'),
              children: [
                {field: 'totalPurchaseAmount'},
                {field: 'soldCartsCount'},
                {field: 'salesCount'},
                {field: 'totalTicketsCount'},
                {field: 'paidTicketsCount'},
                {field: 'freeTicketsCount'},
                {field: 'loyaltyScoreUpdatedAt'}
              ]
            },
            {
              groupId: t('Contact'),
              children: [
                {field: 'lead.data.name'},
                {field: 'lead.data.phone'},
                {field: 'lead.data.note'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Company'),
              children: [
                {field: 'lead.data.companyName'},
                {field: 'lead.data.companyIdNumber'},
                {field: 'lead.data.TAXId'},
                {field: 'lead.data.VATId'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Billing address'),
              children: [
                {field: 'lead.data.billingAddressStreet'},
                {field: 'lead.data.billingAddressTown'},
                {field: 'lead.data.billingPostalCode'},
                {field: 'lead.data.billingAddressCountry'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Delivery address'),
              children: [
                {field: 'lead.data.deliveryAddressee'},
                {field: 'lead.data.deliveryAddressStreet'},
                {field: 'lead.data.deliveryAddressTown'},
                {field: 'lead.data.deliveryPostalCode'},
                {field: 'lead.data.deliveryAddressCountry'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Updated'),
              children: [{field: 'updatedAt'}],
              headerAlign: 'left'
            }
          ]}
          columnVisibilityModel={{
            id: P([PermissionCode.ReadCustomer])
          }}
          localeText={{noRowsLabel: t('No customers to show')}}
          {...getDataGridPaginationProps(data?.customers.pagination)}
        />
      </Box>
    </Box>
  )
}
