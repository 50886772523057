import DragHandleIcon from '@mui/icons-material/DragHandle'
import {Box, Button, IconButton, Typography} from '@mui/material'
import React from 'react'
import {TemplateProps} from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {NarrowDivisionPropertiesFragment} from '../../../__generated__/schema'

export interface IDivisionItemCommonProps {
  isLastItem: (id: number) => boolean
  getRemoveButtonClickHandler: (
    division: NarrowDivisionPropertiesFragment
  ) => () => void
}

export interface IDivisionItemProps
  extends TemplateProps<
    NarrowDivisionPropertiesFragment,
    IDivisionItemCommonProps
  > {}

export const DivisionItem: React.FC<IDivisionItemProps> = ({
  item: division,
  commonProps: {isLastItem, getRemoveButtonClickHandler},
  dragHandleProps,
  itemSelected
}: IDivisionItemProps) => {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        display: 'grid',
        py: 1,
        px: 1.5,
        gap: 1,
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        borderBottom: (theme) =>
          !isLastItem(division.id) || itemSelected !== 0
            ? `solid 1px ${theme.palette.divider}`
            : 'none'
      }}
    >
      <IconButton {...dragHandleProps}>
        <DragHandleIcon />
      </IconButton>
      <Typography variant="subtitle2">{division.name}</Typography>
      <Button color="primary" onClick={getRemoveButtonClickHandler(division)}>
        {t('Remove')}
      </Button>
    </Box>
  )
}

export class DraggableDivisionItem extends React.Component<IDivisionItemProps> {
  render() {
    return <DivisionItem {...this.props} />
  }
}
