import {useTranslation} from 'react-i18next'

import {ShowAgeClassificationCode} from '../__generated__/schema'
import {useUserInfo} from '../utils/auth'

export const useTranslateAgeClassification = () => {
  const {t} = useTranslation()
  const {effectiveClient} = useUserInfo()
  const translatedCodes: Record<ShowAgeClassificationCode, string> = {
    [ShowAgeClassificationCode.SkNotRestricted]: t<string>(
      'ShowAgeClassificationCode->SkNotRestricted'
    ),
    [ShowAgeClassificationCode.SkOver2]: t<string>(
      'ShowAgeClassificationCode->SkOver2'
    ),
    [ShowAgeClassificationCode.SkOver3]: t<string>(
      'ShowAgeClassificationCode->SkOver3'
    ),
    [ShowAgeClassificationCode.SkOver4]: t<string>(
      'ShowAgeClassificationCode->SkOver4'
    ),
    [ShowAgeClassificationCode.SkOver5]: t<string>(
      'ShowAgeClassificationCode->SkOver5'
    ),
    [ShowAgeClassificationCode.SkOver6]: t<string>(
      'ShowAgeClassificationCode->SkOver6'
    ),
    [ShowAgeClassificationCode.SkOver7]: t<string>(
      'ShowAgeClassificationCode->SkOver7'
    ),
    [ShowAgeClassificationCode.SkOver8]: t<string>(
      'ShowAgeClassificationCode->SkOver8'
    ),
    [ShowAgeClassificationCode.SkOver9]: t<string>(
      'ShowAgeClassificationCode->SkOver9'
    ),
    [ShowAgeClassificationCode.SkOver10]: t<string>(
      'ShowAgeClassificationCode->SkOver10'
    ),
    [ShowAgeClassificationCode.SkOver11]: t<string>(
      'ShowAgeClassificationCode->SkOver11'
    ),
    [ShowAgeClassificationCode.SkOver12]: t<string>(
      'ShowAgeClassificationCode->SkOver12'
    ),
    [ShowAgeClassificationCode.SkOver13]: t<string>(
      'ShowAgeClassificationCode->SkOver13'
    ),
    [ShowAgeClassificationCode.SkOver14]: t<string>(
      'ShowAgeClassificationCode->SkOver14'
    ),
    [ShowAgeClassificationCode.SkOver15]: t<string>(
      'ShowAgeClassificationCode->SkOver15'
    ),
    [ShowAgeClassificationCode.SkOver16]: t<string>(
      'ShowAgeClassificationCode->SkOver16'
    ),
    [ShowAgeClassificationCode.SkOver17]: t<string>(
      'ShowAgeClassificationCode->SkOver17'
    ),
    [ShowAgeClassificationCode.SkOver18]: t<string>(
      'ShowAgeClassificationCode->SkOver18'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder7]: t<string>(
      'ShowAgeClassificationCode->SkUnsuitableUnder7'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder12]: t<string>(
      'ShowAgeClassificationCode->SkUnsuitableUnder12'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder15]: t<string>(
      'ShowAgeClassificationCode->SkUnsuitableUnder15'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder18]: t<string>(
      'ShowAgeClassificationCode->SkUnsuitableUnder18'
    ),
    [ShowAgeClassificationCode.SkSuitableUnder12]: t<string>(
      'ShowAgeClassificationCode->SkSuitableUnder12'
    ),
    [ShowAgeClassificationCode.SkSuitableForAll]: t<string>(
      'ShowAgeClassificationCode->SkSuitableForAll'
    ),
    [ShowAgeClassificationCode.CzNotRestricted]: t<string>(
      'ShowAgeClassificationCode->CzNotRestricted'
    ),
    [ShowAgeClassificationCode.CzOver2]: t<string>(
      'ShowAgeClassificationCode->CzOver2'
    ),
    [ShowAgeClassificationCode.CzOver3]: t<string>(
      'ShowAgeClassificationCode->CzOver3'
    ),
    [ShowAgeClassificationCode.CzOver4]: t<string>(
      'ShowAgeClassificationCode->CzOver4'
    ),
    [ShowAgeClassificationCode.CzOver5]: t<string>(
      'ShowAgeClassificationCode->CzOver5'
    ),
    [ShowAgeClassificationCode.CzOver6]: t<string>(
      'ShowAgeClassificationCode->CzOver6'
    ),
    [ShowAgeClassificationCode.CzOver7]: t<string>(
      'ShowAgeClassificationCode->CzOver7'
    ),
    [ShowAgeClassificationCode.CzOver8]: t<string>(
      'ShowAgeClassificationCode->CzOver8'
    ),
    [ShowAgeClassificationCode.CzOver9]: t<string>(
      'ShowAgeClassificationCode->CzOver9'
    ),
    [ShowAgeClassificationCode.CzOver10]: t<string>(
      'ShowAgeClassificationCode->CzOver10'
    ),
    [ShowAgeClassificationCode.CzOver11]: t<string>(
      'ShowAgeClassificationCode->CzOver11'
    ),
    [ShowAgeClassificationCode.CzOver12]: t<string>(
      'ShowAgeClassificationCode->CzOver12'
    ),
    [ShowAgeClassificationCode.CzOver13]: t<string>(
      'ShowAgeClassificationCode->CzOver13'
    ),
    [ShowAgeClassificationCode.CzOver14]: t<string>(
      'ShowAgeClassificationCode->CzOver14'
    ),
    [ShowAgeClassificationCode.CzOver15]: t<string>(
      'ShowAgeClassificationCode->CzOver15'
    ),
    [ShowAgeClassificationCode.CzOver16]: t<string>(
      'ShowAgeClassificationCode->CzOver16'
    ),
    [ShowAgeClassificationCode.CzOver17]: t<string>(
      'ShowAgeClassificationCode->CzOver17'
    ),
    [ShowAgeClassificationCode.CzOver18]: t<string>(
      'ShowAgeClassificationCode->CzOver18'
    )
  }
  return (code: ShowAgeClassificationCode) =>
    !effectiveClient
      ? `${translatedCodes[code]} (${code.split('_')[0].toUpperCase()})`
      : translatedCodes[code]
}

export const useTranslateAgeClassificationAbbreviation = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowAgeClassificationCode, string> = {
    [ShowAgeClassificationCode.SkNotRestricted]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkNotRestricted'
    ),
    [ShowAgeClassificationCode.SkOver2]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver2'
    ),
    [ShowAgeClassificationCode.SkOver3]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver3'
    ),
    [ShowAgeClassificationCode.SkOver4]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver4'
    ),
    [ShowAgeClassificationCode.SkOver5]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver5'
    ),
    [ShowAgeClassificationCode.SkOver6]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver6'
    ),
    [ShowAgeClassificationCode.SkOver7]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver7'
    ),
    [ShowAgeClassificationCode.SkOver8]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver8'
    ),
    [ShowAgeClassificationCode.SkOver9]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver9'
    ),
    [ShowAgeClassificationCode.SkOver10]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver10'
    ),
    [ShowAgeClassificationCode.SkOver11]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver11'
    ),
    [ShowAgeClassificationCode.SkOver12]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver12'
    ),
    [ShowAgeClassificationCode.SkOver13]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver13'
    ),
    [ShowAgeClassificationCode.SkOver14]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver14'
    ),
    [ShowAgeClassificationCode.SkOver15]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver15'
    ),
    [ShowAgeClassificationCode.SkOver16]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver16'
    ),
    [ShowAgeClassificationCode.SkOver17]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver17'
    ),
    [ShowAgeClassificationCode.SkOver18]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkOver18'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder7]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkUnsuitableUnder7'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder12]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkUnsuitableUnder12'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder15]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkUnsuitableUnder15'
    ),
    [ShowAgeClassificationCode.SkUnsuitableUnder18]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkUnsuitableUnder18'
    ),
    [ShowAgeClassificationCode.SkSuitableUnder12]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkSuitableUnder12'
    ),
    [ShowAgeClassificationCode.SkSuitableForAll]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->SkSuitableForAll'
    ),
    [ShowAgeClassificationCode.CzNotRestricted]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzNotRestricted'
    ),
    [ShowAgeClassificationCode.CzOver2]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver2'
    ),
    [ShowAgeClassificationCode.CzOver3]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver3'
    ),
    [ShowAgeClassificationCode.CzOver4]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver4'
    ),
    [ShowAgeClassificationCode.CzOver5]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver5'
    ),
    [ShowAgeClassificationCode.CzOver6]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver6'
    ),
    [ShowAgeClassificationCode.CzOver7]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver7'
    ),
    [ShowAgeClassificationCode.CzOver8]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver8'
    ),
    [ShowAgeClassificationCode.CzOver9]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver9'
    ),
    [ShowAgeClassificationCode.CzOver10]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver10'
    ),
    [ShowAgeClassificationCode.CzOver11]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver11'
    ),
    [ShowAgeClassificationCode.CzOver12]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver12'
    ),
    [ShowAgeClassificationCode.CzOver13]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver13'
    ),
    [ShowAgeClassificationCode.CzOver14]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver14'
    ),
    [ShowAgeClassificationCode.CzOver15]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver15'
    ),
    [ShowAgeClassificationCode.CzOver16]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver16'
    ),
    [ShowAgeClassificationCode.CzOver17]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver17'
    ),
    [ShowAgeClassificationCode.CzOver18]: t<string>(
      'ShowAgeClassificationCodeAbbreviation->CzOver18'
    )
  }
  return (code: ShowAgeClassificationCode) => translatedCodes[code]
}
