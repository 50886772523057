import gql from 'graphql-tag'

export const PAGINATION_FRAGMENT = gql`
  fragment PaginationProperties on Pagination {
    hasMore
    offset
    limit
    totalRowsCount
  }
`

export const defaultPaginationInput = {
  limit: 10,
  offset: 0
}
