import {CircularProgress, Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

const useStyles = makeStyles<Theme>((theme) => ({
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  }
}))

export const Loading: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  )
}
