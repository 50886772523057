import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Client} from '../../../../../../__generated__/schema'

import {Theme} from '../../../../../../theme'
import {useDefaultErrorHandler} from '../../../../../../utils/errors'
import {CancelButton, SaveButton} from '../../../../../common/Buttons'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {useBackdropState} from '../../../../../context/backdrop'
import {useNotifications} from '../../../../../context/notifications'
import {
  PricingWithStatus,
  useCreateEventPricing,
  useDeleteEventPricing,
  useUpdateEventPricing
} from './graphql'
import {PricingForm} from './PricingForm'
import {
  EventTicketTypeFormField,
  getEventPricingInput,
  IPricingForm,
  PricingFormField,
  ScheduleApproach
} from './types'

const useStyles = makeStyles<Theme>(() => ({
  content: {
    width: 700
  }
}))

const NEW_EVENT_PRICING_FORM = 'newEventPricingForm'
const EDIT_EVENT_PRICING_FORM = 'editEventPricingForm'

interface ICreatePricingDialogProps {
  close: () => void
  pricing: PricingWithStatus
  eventId: number
  isOpen: boolean
  clientInfo: Pick<
    Client,
    'VATRegistered' | 'countryCode' | 'ticketDefaultVatRate'
  >
}

export const CreatePricingDialog: React.FC<ICreatePricingDialogProps> = ({
  close,
  eventId,
  pricing,
  isOpen,
  clientInfo
}: ICreatePricingDialogProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()

  const createEventPricing = useCreateEventPricing(eventId)
  const handleSubmit = useCallback(
    async (data: IPricingForm) => {
      try {
        setShowBackdrop(true)
        await createEventPricing({eventId, data: getEventPricingInput(data)})
        addInfoNotification(t('Pricing breakpoint created.'))
        close()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      createEventPricing,
      eventId,
      addInfoNotification,
      close,
      t
    ]
  )

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md">
      <DialogTitle>{t('Create new pricing')}</DialogTitle>
      <DialogContent className={classes.content} dividers>
        <PricingForm
          formId={NEW_EVENT_PRICING_FORM}
          onSubmit={handleSubmit}
          defaultValues={{
            [PricingFormField.DATETIME]: null,
            [PricingFormField.TICKET_TYPES]: (pricing?.ticketTypes || []).map(
              (tt) => ({
                ...tt,
                [EventTicketTypeFormField.VAT_RATE]:
                  clientInfo.ticketDefaultVatRate || tt.vatRate,
                price: null
              })
            )
          }}
          submitErrorMessage={t('Error while creating pricing breakpoint.')}
          clientInfo={clientInfo}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={close} />
        <SaveButton type="submit" form={NEW_EVENT_PRICING_FORM}>
          {t('Create')}
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}

interface IEditPricingDialogProps {
  close: () => void
  editPricingId: number | null
  pricing?: PricingWithStatus
  eventId: number
  clientInfo: Pick<Client, 'VATRegistered' | 'countryCode'>
}

export const EditPricingDialog: React.FC<IEditPricingDialogProps> = ({
  close,
  editPricingId,
  eventId,
  pricing,
  clientInfo
}: IEditPricingDialogProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const updateEventPricing = useUpdateEventPricing()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()

  const handleSubmit = useCallback(
    async (data: IPricingForm) => {
      if (editPricingId == null) return

      try {
        setShowBackdrop(true)
        await updateEventPricing({
          id: editPricingId,
          data: getEventPricingInput(data),
          eventId
        })
        addInfoNotification(t('Pricing breakpoint updated.'))
        close()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      updateEventPricing,
      addInfoNotification,
      editPricingId,
      eventId,
      close,
      setShowBackdrop,
      t
    ]
  )

  return (
    <Dialog open={!!pricing && !!editPricingId} onClose={close} maxWidth="md">
      <DialogTitle>{t('Edit pricing')}</DialogTitle>
      <DialogContent className={classes.content} dividers>
        <PricingForm
          formId={EDIT_EVENT_PRICING_FORM}
          onSubmit={handleSubmit}
          defaultValues={{
            [PricingFormField.DATETIME]: pricing?.startsAt
              ? dayjs(pricing.startsAt)
              : null,
            [PricingFormField.TICKET_TYPES]: pricing?.ticketTypes || [],
            [PricingFormField.SCHEDULE_APPROACH]: ScheduleApproach.Schedule
          }}
          submitErrorMessage={t('Error while updating pricing breakpoint.')}
          clientInfo={clientInfo}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={close} />
        <SaveButton type="submit" form={EDIT_EVENT_PRICING_FORM} />
      </DialogActions>
    </Dialog>
  )
}

interface IDeletePricingDialogProps {
  deletePricingId: number | null

  close(): void

  eventId: number
}

export const DeletePricingDialog: React.FC<IDeletePricingDialogProps> = ({
  deletePricingId,
  close,
  eventId
}: IDeletePricingDialogProps) => {
  const {t} = useTranslation()
  const {setShowBackdrop} = useBackdropState()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {addInfoNotification} = useNotifications()

  const deletePricing = useDeleteEventPricing(eventId)
  const handleConfirm = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deletePricing({id: deletePricingId!, eventId})
      addInfoNotification(t('Pricing breakpoint deleted.'))
      close()
    } catch (err) {
      defaultErrorHandler(err, t('Error while deleting pricing breakpoint.'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    close,
    defaultErrorHandler,
    deletePricing,
    deletePricingId,
    eventId,
    setShowBackdrop,
    t
  ])
  return (
    <ConfirmationDialog
      title={t('Delete event pricing')}
      contentText={t('Are you sure, you want to delete event pricing?')}
      isOpen={!!deletePricingId}
      onCancel={close}
      onConfirm={handleConfirm}
    />
  )
}
