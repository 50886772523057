import {ISeat} from '@attendio/shared-components'
import {FormControlLabel, List, Radio} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ApiSeatState} from '../../../../../../__generated__/schema'
import {useSelectionActions} from '../../../../../../editor/redux/selection/actions'
import {BoldRegular, ColoredListItem} from '../../../../../common'
import {DisabledFormWrapper} from '../../../../../visual'
import {EditorSideBar} from '../../../venues/editorUtils'

import {STATES_WITH_INFO, useAllSeatStateTranslations} from './common'
import {useSeatsState} from './seatsStateContext'

const useStyles = makeStyles(() => ({
  radio: {
    width: 40,
    display: 'flex',
    alignSelf: 'stretch'
  }
}))

interface ISeatsSidebarProps {
  selectedSeats: Array<ISeat>
}

export const SeatsSidebar: React.FC<ISeatsSidebarProps> = ({
  selectedSeats
}: ISeatsSidebarProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const seatStateTranslations = useAllSeatStateTranslations()
  const {updateSeatsState, setIgnoreNextDeselectAll} = useSeatsState()
  const {unselectAll} = useSelectionActions()

  // Note: the easier option would be to have no state, and just call action inside
  // useEffect immediately. Hovewer when done like this, the user sees the effect
  // of checkbox being selected and after that the screen is removed, resulting in better UX.
  // If we used no state/effect the user would not see the checkbox being selected.
  const [checked, setChecked] = useState<ApiSeatState | null>(null)
  useEffect(() => {
    if (!checked) return

    updateSeatsState(
      selectedSeats.map((s) => s.id),
      checked
    )

    // Prevent unnecessary socket call cased by deselection in the next step,
    // TODO: can we do better, using more straightforward approach?
    setIgnoreNextDeselectAll(true)

    // So that user has a chance to see selected checkbox for a while
    setTimeout(() => unselectAll())

    // Only run this hook once
    // eslint-disable-next-line
  }, [checked])

  return (
    <DisabledFormWrapper disabled={!!checked}>
      <>
        <EditorSideBar>
          <BoldRegular>{t('Seat states')}</BoldRegular>
        </EditorSideBar>
        <List component="nav">
          {STATES_WITH_INFO.map((seatState) => {
            const trObject = seatStateTranslations[seatState]
            return (
              <ColoredListItem
                key={seatState}
                title={trObject.state}
                description={trObject.desc}
                input={
                  <div className={classes.radio}>
                    <FormControlLabel
                      value={seatState}
                      control={
                        <Radio checked={checked === seatState} disableRipple />
                      }
                      label=""
                    />
                  </div>
                }
                onClick={() => setChecked(seatState)}
              />
            )
          })}
        </List>
      </>
    </DisabledFormWrapper>
  )
}
