import {Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocale} from '../../context/locale'

import {BaseHeaderVariant} from './types'

interface IDatetimeProps {
  variant: BaseHeaderVariant
}

export const Datetime: React.FC<IDatetimeProps> = ({
  variant
}: IDatetimeProps) => {
  const {localeCode} = useLocale()
  const formatTime = useCallback(
    (date: Date) => {
      const formatterOfTime = Intl.DateTimeFormat(localeCode, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })
      return formatterOfTime.format(date)
    },
    [localeCode]
  )
  const formatDate = useCallback(
    (date: Date) => {
      const formatterOfDate = Intl.DateTimeFormat(localeCode, {
        weekday: 'short',
        month: 'numeric',
        day: 'numeric'
      })
      return formatterOfDate.format(date)
    },
    [localeCode]
  )
  const [actualDate, setActualDate] = useState(new Date())
  useEffect(() => {
    const interval = setInterval(() => {
      setActualDate(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Typography
      variant="body2"
      color={
        variant === BaseHeaderVariant.Secondary ? 'textSecondary' : 'inherit'
      }
    >
      {formatDate(actualDate)} • {formatTime(actualDate)}
    </Typography>
  )
}
