import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CartDetailPropertiesFragment,
  CartState,
  IntentState,
  PaymentServiceProvider,
  TransactionType
} from '../../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {useTranslatePrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {PaymentIntentRow} from '../../../../common/PaymentIntentRow'
import {COLOR_CONF} from '../../../../constants'
import {PaymentStatusDialog} from './PaymentStatusDialog'

interface IPaymentAccordionSummaryProps {
  cart: CartDetailPropertiesFragment
}

const PaymentChip = ({cart}: IPaymentAccordionSummaryProps) => {
  const {t} = useTranslation()
  if (cart.payment?.transactionType === TransactionType.Charge) {
    return (
      <EntityStateChip
        isDotHidden
        colorConf={COLOR_CONF.GREEN}
        label={t('Paid')}
      />
    )
  }
  if (cart.payment?.transactionType === TransactionType.Refund) {
    return (
      <EntityStateChip
        isDotHidden
        colorConf={COLOR_CONF.RED}
        label={t('Refunded')}
      />
    )
  }
  if (cart.state === CartState.Pending) {
    return (
      <EntityStateChip
        isDotHidden
        colorConf={COLOR_CONF.YELLOW}
        label={t('Pending')}
      />
    )
  }
  return null
}

const useSummaryStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto'
  },
  leftWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: theme.spacing(0, 1),
    gridTemplateAreas: `
      "title   chip"
      "infoRow infoRow"
    `
  },
  infoRow: {
    gridArea: 'infoRow'
  },
  rightWrapper: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    textAlign: 'right',
    paddingRight: theme.spacing(1.5)
  },
  methodsCount: {
    lineHeight: '100%'
  }
}))

export const PaymentAccordionSummary: React.FC<IPaymentAccordionSummaryProps> =
  ({cart}: IPaymentAccordionSummaryProps) => {
    const {payment, paymentIntents} = cart
    const classes = useSummaryStyles()
    const {t} = useTranslation()
    const isCharge =
      payment && payment.transactionType === TransactionType.Charge
    const isRefund =
      payment && payment.transactionType === TransactionType.Refund
    const isChargeOrRefund = isCharge || isRefund
    const title = isChargeOrRefund
      ? payment!.id
      : t('No successful payment found')
    const countOfSuccessfulIntents = paymentIntents.filter(
      (i) => IntentState.Succeeded === i.state
    ).length
    const translatePrice = useTranslatePrice(cart.client.currency)
    const {formatDateNumeric, formatTimeWithSeconds} = useDateTimeFormatters()
    const translateSellingChannel = useTranslateSellingChannel()
    return (
      <AccordionSummary
        expandIcon={paymentIntents.length > 0 ? <ExpandMoreIcon /> : undefined}
        classes={{content: classes.content}}
      >
        <div className={classes.leftWrapper}>
          <Typography variant="subtitle2">{title}</Typography>
          <PaymentChip cart={cart} />
          {payment && (
            <Typography variant="caption" className={classes.infoRow}>
              {[
                formatDateNumeric(new Date(payment.createdAt)),
                formatTimeWithSeconds(new Date(payment.createdAt)),
                translateSellingChannel(payment.channel)
              ].join(' • ')}
            </Typography>
          )}
        </div>
        {isChargeOrRefund ? (
          <div className={classes.rightWrapper}>
            <Typography variant="body2" component="span">
              {translatePrice(payment!.amount * (isRefund ? -1 : 1))}
            </Typography>
            <Typography variant="overline" className={classes.methodsCount}>
              {t('{{count}} methods', {
                count: countOfSuccessfulIntents,
                context: 'paymentPreview'
              })}
            </Typography>
          </div>
        ) : (
          <div className={classes.rightWrapper}>
            <Typography variant="overline">
              {t('{{count}} intent', {
                count: paymentIntents.length,
                context: 'paymentPreview'
              })}
            </Typography>
          </div>
        )}
      </AccordionSummary>
    )
  }

interface IPaymentProps {
  cart: CartDetailPropertiesFragment
  refetchCart?: () => Promise<object>
}

const useStyles = makeStyles<Theme, {hasPaymentIntents: boolean}>((theme) => ({
  accordionRoot: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    pointerEvents: ({hasPaymentIntents}) =>
      hasPaymentIntents ? 'auto' : 'none'
  },
  intentsList: {
    width: '100%'
  },
  noPaymentFoundRoot: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  }
}))

export const Payment: React.FC<IPaymentProps> = ({
  cart,
  refetchCart
}: IPaymentProps) => {
  const {t} = useTranslation()
  const [checkPaymentStatusData, setCheckPaymentStatusData] =
    useState<{
      serviceProvider: PaymentServiceProvider
      thirdPartyPaymentId: string
    } | null>(null)
  const classes = useStyles({hasPaymentIntents: cart.paymentIntents.length > 0})
  if (cart.paymentIntents.length === 0 && !cart.payment) {
    return (
      <div className={classes.noPaymentFoundRoot}>
        <Typography variant="subtitle2" color="inherit">
          {t('No payment found')}
        </Typography>
      </div>
    )
  }
  return (
    <>
      <Accordion classes={{root: classes.accordionRoot}}>
        <PaymentAccordionSummary cart={cart} />
        {cart.paymentIntents.length > 0 && (
          <AccordionDetails>
            <ListOfItemsSeparatedByDividers className={classes.intentsList}>
              {cart.paymentIntents.map((intent) => (
                <PaymentIntentRow
                  intent={intent}
                  key={intent.id}
                  currency={cart.client.currency}
                  onPaymentStatusClick={setCheckPaymentStatusData}
                />
              ))}
            </ListOfItemsSeparatedByDividers>
          </AccordionDetails>
        )}
      </Accordion>
      <PaymentStatusDialog
        data={checkPaymentStatusData}
        onClose={() => setCheckPaymentStatusData(null)}
        refetchCart={refetchCart}
      />
    </>
  )
}
