import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  AdmissionTypesFilter,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {AdmissionTypesList} from './AdmissionTypesList'
import {
  AdmissionTypesSearch,
  DEFAULT_ADMISSION_TYPES_FILTER
} from './AdmissionTypesSearch'
import {CreateAdmissionTypeDrawer} from './CreateAdmissionTypeDrawer'
import {UpdateAdmissionTypeDrawer} from './UpdateAdmissionTypeDrawer'

export const AdmissionTypesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<AdmissionTypesFilter>(
    DEFAULT_ADMISSION_TYPES_FILTER
  )
  const history = useHistory()
  const {
    state: isCreateAdmissionTypeDrawerOpen,
    setTrue: openAdmissionTypeGroupDrawer,
    setFalse: closeAdmissionTypeGroupDrawer
  } = useBooleanState(false)
  const handleExited = useCallback(
    () => history.replace(routeTo.admin.admissionTypes.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Admission types')}
          search={<AdmissionTypesSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <AdmissionTypesList
          onCreateButtonClick={openAdmissionTypeGroupDrawer}
          searchFilter={searchFilter}
        />
        {P([
          PermissionCode.CreateAdmissionType,
          PermissionCode.ReadAdmissionType
        ]) && (
          <CreateAdmissionTypeDrawer
            isOpen={isCreateAdmissionTypeDrawerOpen}
            onClose={closeAdmissionTypeGroupDrawer}
            searchFilter={searchFilter}
          />
        )}
        {P([
          PermissionCode.ReadAdmissionType,
          PermissionCode.UpdateAdmissionType
        ]) && (
          <Route
            path={routeTo.admin.admissionTypes.edit(':admissionTypeId')}
            exact
          >
            <UpdateAdmissionTypeDrawer
              onExited={handleExited}
              searchFilter={searchFilter}
            />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
