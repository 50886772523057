import {Drawer} from '@mui/material'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {GetItemQuery} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {useItemParams} from '../../../../../utils/pathname'
import {
  DRAWER_TRANSITION_DURATION,
  DrawerSection,
  DrawerTemplateHeader,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {useGetItem} from '../../checkTickets/graphql'
import {isTicketItem, isTourItem} from '../../types'
import {LeadSectionGrid} from '../cartPreviewDrawer/LeadSectionGrid'
import {isNotNarrowProductItemPropertiesFragment} from '../types'
import {ActivityLogTicketInfoSection} from './ActivityLogTicketInfoSection'
import {GeneralTicketInfoSection} from './GeneralTicketInfoSection'
import {InformationTicketInfoSection} from './InformationTicketInfoSection'
import {PriceTicketInfoSection} from './PriceTicketInfoSection'

const getIsInformationAvailable = (data?: GetItemQuery) =>
  data?.item &&
  ((isTicketItem(data.item) &&
    !!(
      data.item.eventSeat.label ||
      data.item.eventSeat.row ||
      data.item.eventSeat.section ||
      data.item.eventSeat.floor
    )) ||
    isTourItem(data.item))

const useSegmentAnchors = (isInformationAvailable: boolean) => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      general: {
        id: 'general',
        label: t('General')
      },
      ...(isInformationAvailable
        ? {
            information: {
              id: 'information',
              label: t('Information')
            }
          }
        : {}),
      activityLog: {
        id: 'activityLog',
        label: t('Activity log')
      },
      price: {
        id: 'Price',
        label: t('Price')
      },
      lead: {
        id: 'lead',
        label: t('Lead')
      }
    }),
    [isInformationAvailable, t]
  )
}

interface ITicketInfoDrawerProps {
  onExited: () => void
  onEntered: () => void
}

export const TicketInfoDrawer: React.FC<ITicketInfoDrawerProps> = ({
  onExited,
  onEntered
}: ITicketInfoDrawerProps) => {
  const {t} = useTranslation()
  const {itemId} = useItemParams()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {data, loading, error} = useGetItem({
    id: itemId,
    paginationInput: {offset: 0, limit: 50}
  })
  useEffect(() => {
    if (!isNaN(itemId)) {
      openDrawer()
    }
  }, [itemId, openDrawer])
  const passCode =
    data?.item &&
    isNotNarrowProductItemPropertiesFragment(data.item) &&
    data.item.passCode
  const navigationItems = useSegmentAnchors(
    getIsInformationAvailable(data) || false
  )
  const drawerClasses = useDrawerWithSideNavigationStyles()
  return (
    <Drawer
      open={isDrawerOpen}
      onClose={closeDrawer}
      SlideProps={{
        exit: true,
        onExited,
        onEntered
      }}
      classes={drawerClasses}
      keepMounted
      anchor="right"
      transitionDuration={DRAWER_TRANSITION_DURATION}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          isLoading: loading,
          errorMessage: error && t('Loading ticket data failed'),
          header: (
            <DrawerTemplateHeader
              title={t('Ticket {{passCode}}', {passCode})}
              onLeftActionClick={closeDrawer}
            />
          )
        }}
        navigationItems={navigationItems}
      >
        {data?.item && isNotNarrowProductItemPropertiesFragment(data.item) && (
          <>
            <GeneralTicketInfoSection
              item={data.item}
              drawerSectionProps={{
                id: navigationItems.general.id
              }}
            />
            {navigationItems.information && (
              <InformationTicketInfoSection
                item={data.item}
                drawerSectionProps={{
                  id: navigationItems.information.id,
                  label: navigationItems.information.label
                }}
              />
            )}
            <ActivityLogTicketInfoSection
              activityLog={data.item.passCodeCheckActivityLog}
              drawerSectionProps={{
                id: navigationItems.activityLog.id,
                label: navigationItems.activityLog.label
              }}
            />
            <PriceTicketInfoSection
              item={data.item}
              drawerSectionProps={{
                id: navigationItems.price.id,
                label: navigationItems.price.label
              }}
            />
            <DrawerSection
              id={navigationItems.lead.id}
              label={navigationItems.lead.label}
            >
              <LeadSectionGrid leadData={data.item.sale?.cart.lead?.data} />
            </DrawerSection>
          </>
        )}
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
