import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  ChangeVoucherStateMutation,
  ChangeVoucherStateMutationVariables,
  CreateVouchersInput,
  CreateVouchersMutation,
  CreateVouchersMutationVariables,
  CreditVoucherMutation,
  CreditVoucherMutationVariables,
  GetLightweightVoucherCampaignsQuery,
  GetLightweightVoucherCampaignsQueryVariables,
  GetVoucherQuery,
  GetVoucherQueryVariables,
  VoucherCampaignsFilterInput,
  VouchersQuery,
  VouchersQueryVariables,
  VoucherTransactionIntentFilterInput
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const VOUCHER_FIELDS = gql`
  ${USER_FIELDS}
  fragment VoucherFields on Voucher {
    id
    code
    state
    campaign {
      id
      name
      rechargeLimit
    }
    value
    topUpsCount
    redemptionsCount
    balance
    activationDate
    expirationDate
    updatedAt
    updatedBy {
      ...UserFields
    }
    activatedAt
    activatedBy {
      ...UserFields
    }
    createdAt
  }
`

const GET_VOUCHERS = gql`
  query vouchers(
    $filter: VouchersFilterInput
    $paginationInput: PaginationInput!
    $canReadPinCode: Boolean!
  ) {
    vouchers(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...VoucherFields
        pinCode @include(if: $canReadPinCode)
      }
      totals {
        totalBalance
        totalValue
        totalTopUpsCount
        totalRedemptionsCount
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${VOUCHER_FIELDS}
  ${PAGINATION_FRAGMENT}
`

export const useGetVouchers = (variables: VouchersQueryVariables) =>
  useQuery<VouchersQuery, VouchersQueryVariables>(GET_VOUCHERS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CHANGE_VOUCHER_STATE = gql`
  mutation changeVoucherState($id: PositiveInt!, $state: VoucherState!) {
    changeVoucherState(id: $id, state: $state) {
      ...VoucherFields
    }
  }
  ${VOUCHER_FIELDS}
`

export const useChangeVoucherState = () => {
  const [changeVoucherState] =
    useMutation<
      ChangeVoucherStateMutation,
      ChangeVoucherStateMutationVariables
    >(CHANGE_VOUCHER_STATE)
  return useCallback(
    (variables: ChangeVoucherStateMutationVariables) =>
      changeVoucherState({variables}),
    [changeVoucherState]
  )
}

const GET_LIGHTWEIGHT_VOUCHER_CAMPAIGNS = gql`
  query getLightweightVoucherCampaigns(
    $filter: VoucherCampaignsFilterInput
    $paginationInput: PaginationInput!
  ) {
    voucherCampaigns(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        state
        name
        initialBalance
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`

export const useGetLightweightVoucherCampaigns = (
  filter?: VoucherCampaignsFilterInput
) =>
  useQuery<
    GetLightweightVoucherCampaignsQuery,
    GetLightweightVoucherCampaignsQueryVariables
  >(GET_LIGHTWEIGHT_VOUCHER_CAMPAIGNS, {
    variables: {
      filter,
      paginationInput: {offset: 0, limit: 200}
    },
    fetchPolicy: 'network-only'
  })

const CREATE_VOUCHERS = gql`
  mutation createVouchers($input: CreateVouchersInput!) {
    createVouchers(input: $input) {
      id
    }
  }
`

export const useCreateVouchers = () => {
  const [createVouchers] =
    useMutation<CreateVouchersMutation, CreateVouchersMutationVariables>(
      CREATE_VOUCHERS
    )
  return useCallback(
    (input: CreateVouchersInput) =>
      createVouchers({
        variables: {input}
      }),
    [createVouchers]
  )
}

const GET_VOUCHER = gql`
  ${VOUCHER_FIELDS}
  ${USER_FIELDS}
  query getVoucher(
    $id: PositiveInt!
    $canReadPinCode: Boolean!
    $transactionIntentFilter: VoucherTransactionIntentFilterInput
  ) {
    voucher(id: $id) {
      ...VoucherFields
      value
      pinCode @include(if: $canReadPinCode)
      expiredAt
      updatedAt
      updatedBy {
        ...UserFields
      }
      transactionIntents(filter: $transactionIntentFilter) {
        id
        amount
        state
        reason
        note
        createdAt
        createdBy {
          ...UserFields
        }
        type
        paymentIntent {
          id
          cart {
            id
          }
        }
        refundIntent {
          id
          claim {
            id
          }
        }
      }
    }
  }
`

export const useGetVoucher = ({
  id,
  canReadPinCode,
  transactionIntentFilter
}: {
  id: number
  canReadPinCode: boolean
  transactionIntentFilter?: VoucherTransactionIntentFilterInput
}) =>
  useQuery<GetVoucherQuery, GetVoucherQueryVariables>(GET_VOUCHER, {
    variables: {id, canReadPinCode, transactionIntentFilter},
    fetchPolicy: 'network-only'
  })

const CREDIT_VOUCHER = gql`
  mutation CreditVoucher($id: PositiveInt!, $input: CreditVoucherInput!) {
    creditVoucher(id: $id, input: $input) {
      id
    }
  }
`

export const useCreditVoucher = () => {
  const [creditVoucher] =
    useMutation<CreditVoucherMutation, CreditVoucherMutationVariables>(
      CREDIT_VOUCHER
    )
  return useCallback(
    (variables: CreditVoucherMutationVariables) =>
      creditVoucher({
        variables
      }),
    [creditVoucher]
  )
}
