import {Box, CircularProgress, SxProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import React, {useEffect, useRef} from 'react'

interface ILazyLoadingListProps {
  children: React.ReactNode
  isLoadingMore?: boolean
  onScrolledNearTheEndOfTheList: () => void
  sx?: SxProps<Theme>
}

export const LazyLoadingList: React.FC<ILazyLoadingListProps> = ({
  children,
  isLoadingMore,
  onScrolledNearTheEndOfTheList,
  sx
}: ILazyLoadingListProps) => {
  const observerDivRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    let observer: IntersectionObserver
    if (observerDivRef.current && onScrolledNearTheEndOfTheList) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onScrolledNearTheEndOfTheList()
          }
        },
        {threshold: 0.5}
      )

      observer.observe(observerDivRef.current)
    }
    return () => {
      observer?.disconnect()
    }
  }, [onScrolledNearTheEndOfTheList])
  return (
    <Box sx={{height: '100%', overflowY: 'auto', ...sx}}>
      <div>{children}</div>
      {isLoadingMore && (
        <Box sx={{pt: 2, display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      )}
      <div
        ref={observerDivRef}
        style={{
          height: '1px'
        }}
      />
    </Box>
  )
}
