import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  BusinessPartnerCategory,
  BusinessPartnersFilterInput,
  BusinessPartnerType
} from '../../../../__generated__/schema'
import {useTranslateBusinessPartnerCategory} from '../../../../hooks/translateBusinessPartnerCategory'
import {useTranslateBusinessPartnerType} from '../../../../hooks/translateBusinessPartnerType'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'

export const DEFAULT_BUSINESS_PARTNERS_FILTER_INPUT: BusinessPartnersFilterInput =
  {
    hasText: undefined,
    companyName: undefined,
    companyIdNumber: undefined
  }

const mapHasTextToFilter = (
  filter: BusinessPartnersFilterInput,
  hasText: string | undefined
): BusinessPartnersFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapTypeToFilter = (
  filter: BusinessPartnersFilterInput,
  type: BusinessPartnerType | undefined
): BusinessPartnersFilterInput => ({
  ...filter,
  type
})

const mapCompanyNameToFilter = (
  filter: BusinessPartnersFilterInput,
  companyName: string | undefined
): BusinessPartnersFilterInput => ({
  ...filter,
  companyName: companyName || undefined
})

const mapCompanyIdNumberToFilter = (
  filter: BusinessPartnersFilterInput,
  companyIdNumber: string | undefined
): BusinessPartnersFilterInput => ({
  ...filter,
  companyIdNumber: companyIdNumber || undefined
})

const mapCategoryToFilter = (
  filter: BusinessPartnersFilterInput,
  category: BusinessPartnerCategory | undefined
): BusinessPartnersFilterInput => ({
  ...filter,
  categories: category ? [category] : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateBusinessPartnerType = useTranslateBusinessPartnerType()
  const translateBusinessPartnerCategory = useTranslateBusinessPartnerCategory()
  const getHasTextFromSearchObject = (filter: BusinessPartnersFilterInput) =>
    filter.hasText || undefined
  const getTypeFromSearchObject = (filter: BusinessPartnersFilterInput) =>
    filter.type
      ? t('Type: {{type}}', {type: translateBusinessPartnerType(filter.type)})
      : undefined
  const getCompanyNameFromSearchObject = (
    filter: BusinessPartnersFilterInput
  ) =>
    filter.companyName
      ? t('Company name: {{name}}', {name: filter.companyName})
      : undefined
  const getCompanyIdNumberFromSearchObject = (
    filter: BusinessPartnersFilterInput
  ) =>
    filter.companyIdNumber
      ? t('Company ID number: {{name}}', {name: filter.companyIdNumber})
      : undefined
  const getCategoryFromSearchObject = (filter: BusinessPartnersFilterInput) =>
    filter.categories
      ? t('Category: {{category}}', {
          category: translateBusinessPartnerCategory(filter.categories[0])
        })
      : undefined
  return {
    getHasTextFromSearchObject,
    getTypeFromSearchObject,
    getCompanyNameFromSearchObject,
    getCompanyIdNumberFromSearchObject,
    getCategoryFromSearchObject
  }
}

interface IBusinessPartnersSearchProps {
  onFilterChange: (filter: BusinessPartnersFilterInput) => void
}

export const BusinessPartnersSearch: React.FC<IBusinessPartnersSearchProps> = ({
  onFilterChange
}: IBusinessPartnersSearchProps) => {
  const {t} = useTranslation()
  const {
    getHasTextFromSearchObject,
    getTypeFromSearchObject,
    getCompanyNameFromSearchObject,
    getCompanyIdNumberFromSearchObject,
    getCategoryFromSearchObject
  } = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<BusinessPartnersFilterInput>(
      getHasTextFromSearchObject,
      getTypeFromSearchObject,
      getCompanyNameFromSearchObject,
      getCompanyIdNumberFromSearchObject,
      getCategoryFromSearchObject
    )
  const translateBusinessPartnerType = useTranslateBusinessPartnerType()
  const translateBusinessPartnerCategory = useTranslateBusinessPartnerCategory()
  const businessPartnerTypeOptions: ISelectOption<BusinessPartnerType>[] = [
    BusinessPartnerType.Supplier,
    BusinessPartnerType.Client,
    BusinessPartnerType.Partner
  ].map((type) => ({id: type, label: translateBusinessPartnerType(type)}))
  const businessPartnerCategoryOptions: ISelectOption<BusinessPartnerCategory>[] =
    Object.values(BusinessPartnerCategory).map((category) => ({
      id: category,
      label: translateBusinessPartnerCategory(category)
    }))
  return (
    <Search<BusinessPartnersFilterInput>
      storageKey="BUSINESS_PARTNERS"
      placeholder={t('Search for business partner')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_BUSINESS_PARTNERS_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<
            BusinessPartnersFilterInput,
            BusinessPartnerType
          >
            label={t('Type')}
            value={advancedSearchObject.type || undefined}
            options={businessPartnerTypeOptions}
            mapSelectValueToSearchObject={mapTypeToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow<BusinessPartnersFilterInput>
            label={t('Has text')}
            placeholder={t('Enter name, ID number or notes')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow<BusinessPartnersFilterInput>
            label={t('Company name')}
            placeholder={t('Enter company name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapCompanyNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.companyName || undefined}
          />
          <AdvancedSearchTextRow<BusinessPartnersFilterInput>
            label={t('Company ID number')}
            placeholder={t('Enter company ID number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapCompanyIdNumberToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.companyIdNumber || undefined}
          />
          <AdvancedSearchSelectRow<
            BusinessPartnersFilterInput,
            BusinessPartnerCategory
          >
            label={t('Category')}
            value={
              advancedSearchObject.categories
                ? advancedSearchObject.categories[0]
                : undefined
            }
            options={businessPartnerCategoryOptions}
            mapSelectValueToSearchObject={mapCategoryToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
