import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useCreateWarehouseProduct} from '../graphql'
import {IWarehouseProductForm, WarehouseProductFormField} from './types'
import {WarehouseProductForm} from './WarehouseProductForm'

const CREATE_WAREHOUSE_PRODUCT_FORM_ID = 'createWarehouseProductForm'

interface ICreateWarehouseProductDrawerProps {
  warehouseId?: number
  productId: number
  onClose: () => void
}

export const CreateWarehouseProductDrawer: React.FC<ICreateWarehouseProductDrawerProps> =
  ({warehouseId, productId, onClose}: ICreateWarehouseProductDrawerProps) => {
    const {t} = useTranslation()
    const createWarehouseProduct = useCreateWarehouseProduct()
    const {setShowBackdrop, defaultErrorHandler, customErrorHandler} =
      useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (formData: IWarehouseProductForm) => {
        if (warehouseId) {
          try {
            setShowBackdrop(true)
            await createWarehouseProduct({
              input: {
                productId,
                warehouseId,
                isNegativeStockEnabled:
                  formData[WarehouseProductFormField.IsNegativeStockEnabled],
                minStockLevel: formData[WarehouseProductFormField.MinStockLevel]
                  ? convertValueToFloat(
                      formData[WarehouseProductFormField.MinStockLevel]
                    )
                  : undefined,
                optimalStockLevel: formData[
                  WarehouseProductFormField.OptimalStockLevel
                ]
                  ? convertValueToFloat(
                      formData[WarehouseProductFormField.OptimalStockLevel]
                    )
                  : undefined
              }
            })
            onClose()
          } catch (error) {
            if (
              getGraphQLErrorRelatedToErrorMessage(
                error,
                ErrorMessages.ProductAlreadyExistsInWarehouse
              )
            ) {
              customErrorHandler(error, {
                title: t('Warehouse product already created'),
                contentText: t(
                  'The product you are trying to add already exists in this warehouse. Each product can only be listed once per warehouse to prevent duplication and ensure inventory accuracy.'
                ),
                confirmButtonLabel: t('Got it'),
                onConfirm: () => window.location.reload()
              })
            } else if (
              getGraphQLErrorRelatedToErrorMessage(
                error,
                ErrorMessages.InvalidProductMode
              )
            ) {
              customErrorHandler(error, {
                title: t('Invalid product mode'),
                contentText: t(
                  'You cannot set warehouse stock details for this product because it was switched to a different mode. Please reload this page and check the product details again.'
                ),
                confirmButtonLabel: t('Got it'),
                onConfirm: () => window.location.reload()
              })
            } else {
              defaultErrorHandler(
                error,
                t('Error while creating warehouse product')
              )
            }
          } finally {
            setShowBackdrop(false)
          }
        }
      },
      [
        createWarehouseProduct,
        customErrorHandler,
        defaultErrorHandler,
        onClose,
        productId,
        setShowBackdrop,
        t,
        warehouseId
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={Boolean(warehouseId)}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create warehouse product')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_WAREHOUSE_PRODUCT_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <WarehouseProductForm
            formId={CREATE_WAREHOUSE_PRODUCT_FORM_ID}
            onSubmit={handleSubmit}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
