import gql from 'graphql-tag'
import {useCallback} from 'react'
import {useMutation} from '@apollo/react-hooks'

import {
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables,
  InvitedUser
} from '../../../__generated__/schema'
import {USER_PROPERTIES_FRAGMENT, GET_CURRENT_USER} from '../../../utils/auth'

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($token: String!, $data: InvitedUser!) {
    acceptInvitation(token: $token, data: $data) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`
export const useAcceptInvitation = () => {
  const [acceptInvitation] = useMutation<
    AcceptInvitationMutation,
    AcceptInvitationMutationVariables
  >(ACCEPT_INVITATION, {
    // TODO (quick-fix): find out why this update is needed and
    // unpacking ...UserProperties is not enough
    // TODO: investigate why this seems not to be needed for `recoverPassword`
    update(cache, {data}) {
      data &&
        cache.writeQuery({
          query: GET_CURRENT_USER,
          data: {me: data.acceptInvitation}
        })
    }
  })
  return useCallback(
    (token: string, data: InvitedUser) =>
      acceptInvitation({variables: {token, data}}),
    [acceptInvitation]
  )
}
