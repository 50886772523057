import {Box, Button, Theme, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import onScan, {ScanEvent} from 'onscan.js'
import React, {useCallback, useEffect, useRef} from 'react'
import {DeepPartial, IsAny, LiteralToPrimitive, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsStringWithMinLength} from '../../../../../utils/formsValidations'
import {BarcodeIcon} from '../../../../common/icons/BarcodeIcon'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {keyCodeMapper} from '../../types'

const useStyles = makeStyles<Theme>((theme) => ({
  barcodeIcon: {
    width: 64,
    height: 64,
    color: theme.palette.text.primary
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    gap: theme.spacing(1),
    alignItems: 'flex-start'
  },
  submitButton: {
    marginTop: 6
  }
}))

const CODE_FIELD = 'CODE_FIELD'

interface ITypeEntryForm {
  [CODE_FIELD]: string
}

interface ITypeEntryProps {
  className?: string
  onCodeSubmit: (passCode: string) => void
  isCheckButtonDisabled?: boolean
}

export const TypeEntry: React.FC<ITypeEntryProps> = ({
  className,
  onCodeSubmit,
  isCheckButtonDisabled
}: ITypeEntryProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {watch, errors, setValue, register, triggerValidation, handleSubmit} =
    useForm<ITypeEntryForm>()
  const handleFormSubmit = useCallback(
    (form: ITypeEntryForm) => {
      onCodeSubmit(form[CODE_FIELD])
    },
    [onCodeSubmit]
  )
  const isStringWithMinLength = useIsStringWithMinLength(8)
  const code = watch(CODE_FIELD)
  const setUpperCasedValue = useCallback(
    <T extends string, U extends unknown>(
      name: T,
      value: T extends keyof ITypeEntryForm
        ? IsAny<ITypeEntryForm[T]> extends true
          ? any
          : DeepPartial<ITypeEntryForm[T]>
        : LiteralToPrimitive<U>,
      shouldValidate?: boolean
    ) => {
      setValue(
        name,
        typeof value === 'string'
          ? (value.toUpperCase() as T extends keyof ITypeEntryForm
              ? IsAny<ITypeEntryForm[T]> extends true
                ? any
                : DeepPartial<ITypeEntryForm[T]>
              : LiteralToPrimitive<U>)
          : value,
        shouldValidate
      )
    },
    [setValue]
  )
  useEffect(() => {
    onScan.attachTo(document, {
      keyCodeMapper
    })
    return () => {
      onScan.detachFrom(document)
    }
  }, [])

  const submitButtonRef = useRef<null | HTMLButtonElement>(null)

  const handleScanSuccess = useCallback(
    (scan: ScanEvent) => {
      setValue(CODE_FIELD, scan.detail.scanCode, false)
      if (submitButtonRef.current) {
        submitButtonRef.current.click()
      }
    },
    [setValue]
  )

  useEffect(() => {
    window.document.addEventListener('scan', handleScanSuccess)
    return () => {
      window.document.removeEventListener('scan', handleScanSuccess)
    }
  }, [handleScanSuccess])

  return (
    <div className={cn(classes.root, className)}>
      <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          <UncontrolledFormTextInput<ITypeEntryForm>
            fullWidth
            placeholder={t('Enter code here')}
            name={CODE_FIELD}
            watch={watch}
            errors={errors}
            setValue={setUpperCasedValue}
            register={register}
            validationOptions={{
              required: true,
              validate: {
                isStringWithMinLength
              }
            }}
            triggerValidation={triggerValidation}
            autoFocus
          />
        </div>
        <Button
          color="primary"
          type="submit"
          className={classes.submitButton}
          ref={submitButtonRef}
          disabled={
            Boolean(isStringWithMinLength(code ?? 'initial')) ||
            isCheckButtonDisabled
          }
        >
          {t('Check')}
        </Button>
      </form>
      <Box pt={4} pb={2}>
        <BarcodeIcon className={classes.barcodeIcon} />
      </Box>
      <Typography variant="h6">{t('Scan code with imager')}</Typography>
    </div>
  )
}
