import {
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Theme,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  LightweightVoucherCampaignsQuery,
  PermissionCode,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useTranslateDuration} from '../../../../hooks/translateDuration'
import {PRIMARY_50_COLOR} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {Blank} from '../../../visual/Blank'
import {VoucherActivationOption} from '../components/types'
import {VoucherActivationOptions} from '../components/VoucherActivationOptions'
import {IVoucherRefundMethodForm, VoucherRefundMethodField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  radioGroup: {
    gap: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  },
  formControlLabelRoot: {
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    }
  },
  title: {
    paddingBottom: theme.spacing(1)
  },
  label: {
    width: '100%'
  },
  formControlLabelRootSelected: {
    display: 'flex',
    borderColor: theme.palette.primary.main,
    backgroundColor: PRIMARY_50_COLOR
  },
  labelBox: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5)
  }
}))

interface IVoucherTabContentProps {
  voucherCampaigns: LightweightVoucherCampaignsQuery['voucherCampaigns']['items']
  name: string
}

export const VoucherTabContent: React.FC<IVoucherTabContentProps> = ({
  voucherCampaigns,
  name
}: IVoucherTabContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateDuration = useTranslateDuration()
  const {watch, errors, setValue, register, triggerValidation, control} =
    useFormContext<IVoucherRefundMethodForm>()
  const selectedVoucherCampaign = watch(name)
  const handleVoucherCampaignsButtonClick = useCallback(
    () => history.push(routeTo.admin.voucherCampaigns.index()),
    [history]
  )
  const classes = useStyles()
  return voucherCampaigns.length ? (
    <div className={classes.root}>
      <div>
        <Typography variant="subtitle1" className={classes.title}>
          {t('Voucher settings')}
        </Typography>
        <Paper variant="outlined" className={classes.paper}>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={VoucherRefundMethodField.Balance}
                key={VoucherRefundMethodField.Balance}
                label={t('Initial balance ({{currencySign}})', {
                  currencySign: translatedEffectiveClientCurrencySign
                })}
                validationOptions={{required: true}}
                disabled
                fullWidth
              />
            ]}
          />
          <VoucherActivationOptions<IVoucherRefundMethodForm>
            availableOptions={[
              VoucherActivationOption.Now,
              VoucherActivationOption.Date
            ]}
          />
        </Paper>
      </div>
      <div>
        <Typography variant="subtitle1" className={classes.title}>
          {t('Voucher campaign')}
        </Typography>
        <Controller
          as={(props) => (
            <RadioGroup {...props} classes={{root: classes.radioGroup}}>
              {voucherCampaigns.map((vc) => (
                <FormControlLabel
                  key={String(vc.id)}
                  value={String(vc.id)}
                  label={
                    <div className={classes.labelBox}>
                      <Typography variant="subtitle2">{vc.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {[
                          `${t('Redemption limit')}: ${
                            vc.redemptionLimit || t('Not set')
                          }`,
                          `${t('Validity')}: ${
                            vc.initialValidity
                              ? translateDuration(vc.initialValidity)
                              : t('Not set')
                          }`
                        ].join(' • ')}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {`${t('Channels')}: ${[
                          vc.retailPaymentMethodId &&
                            translateSellingChannel(SellingChannel.Retail),
                          vc.ecommercePaymentMethodId &&
                            translateSellingChannel(SellingChannel.ECommerce)
                        ]
                          .filter(Boolean)
                          .join(', ')}`}
                      </Typography>
                    </div>
                  }
                  control={<Radio color="primary" />}
                  classes={{
                    root: cn(classes.formControlLabelRoot, {
                      [classes.formControlLabelRootSelected]:
                        selectedVoucherCampaign === String(vc.id)
                    }),
                    label: classes.label
                  }}
                />
              ))}
            </RadioGroup>
          )}
          control={control}
          name={name}
          rules={{required: true}}
        />
      </div>
    </div>
  ) : (
    <Blank
      title={t('Unable to refund by voucher')}
      description={t(
        'The are no suitable voucher campaigns for refund. Create voucher campaign first and try again or use another refund method.'
      )}
      actions={
        P([PermissionCode.ReadVoucherCampaigns]) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleVoucherCampaignsButtonClick}
          >
            {t('Voucher campaigns')}
          </Button>
        )
      }
    />
  )
}
