import omit from 'lodash/omit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightUsersQuery,
  PaymentReportsFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {useGetLightweightUsers} from '../paymentsOverview/graphql'

type PaymentReportsSearchObject = PaymentReportsFilterInput & {
  _createdAtDaterangeId?: DATERANGE_IDS
  _reportDaterangeId?: DATERANGE_IDS
}

const paymentReportDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.THIS_MONTH,
  DATERANGE_IDS.LAST_30_DAYS
]

const stripCreatedAtDateFromSearchObject = (
  filter: PaymentReportsSearchObject
): PaymentReportsFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripReportDateFromSearchObject = (
  filter: PaymentReportsSearchObject
): PaymentReportsFilterInput =>
  omit(filter, ['reportFrom', 'reportTo', '_reportDaterangeId'])

const stripHelperKeysFromSearchObject = (
  filter: PaymentReportsSearchObject
): PaymentReportsFilterInput =>
  omit(filter, ['_createdAtDaterangeId', '_reportDaterangeId'])

const mapHasTextToFilter = (
  filter: PaymentReportsFilterInput,
  hasText: string | undefined
): PaymentReportsFilterInput => ({
  ...filter,
  hasText
})

const mapIdToFilter = (
  filter: PaymentReportsFilterInput,
  id: string | undefined
): PaymentReportsFilterInput => ({
  ...filter,
  id: id ? parseInt(id, 10) : undefined
})

const mapCreatedByUserIdToFilter = (
  filter: PaymentReportsFilterInput,
  createdByUserId: number | undefined
) => ({
  ...filter,
  createdByUserId
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getHasTextFromSearchObject = (filter: PaymentReportsFilterInput) =>
    filter.hasText || undefined
  const getIdFromSearchObject = (filter: PaymentReportsFilterInput) =>
    filter.id ? t('Payment report ID: {{id}}', {id: filter.id}) : undefined
  const getUserFromSearchObject = (
    filter: PaymentReportsFilterInput,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.createdByUserId) {
      const user = users.find((user) => user.id === filter.createdByUserId)
      return user
        ? t('Created by: {{userName}}', {
            userName: [user.lastName, user.firstName].join(' ')
          })
        : t('Created by ID: {{userId}}', {userId: filter.createdByUserId})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getIdFromSearchObject,
    getUserFromSearchObject
  }
}

interface IPaymentReportsSearchProps {
  onFilterChange: (filter: PaymentReportsFilterInput) => void
}

export const PaymentReportsSearch: React.FC<IPaymentReportsSearchProps> = ({
  onFilterChange
}: IPaymentReportsSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {users} = useGetLightweightUsers()
  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<PaymentReportsSearchObject>({
    usedDateranges: paymentReportDateRanges,
    dateRangeInputPrefix: t('Created at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: reportDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomReportDateRangeToFilter,
    mapDaterangeToSearchObject: mapReportDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedReportDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsReportDaterangeOptionActive
  } = useDateRangeSearch<PaymentReportsSearchObject>({
    usedDateranges: paymentReportDateRanges,
    dateRangeInputPrefix: t('Reported date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.reportFrom || undefined,
      endDateISOString: o.reportTo || undefined,
      id: o._reportDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      reportFrom: input.startDate,
      reportTo: input.endDate,
      _reportDaterangeId: input.id
    })
  })
  const userSelectOptions: ISelectOption<number>[] = users.map((user) => ({
    id: user.id,
    label: [user.lastName, user.firstName].join(' ')
  }))
  const {
    getHasTextFromSearchObject,
    getIdFromSearchObject,
    getUserFromSearchObject
  } = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject,
    getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getStringifiedReportDateDateRangeFromSearchObject,
    getIdFromSearchObject,
    (filter) => getUserFromSearchObject(filter, users)
  )
  return (
    <Search<PaymentReportsFilterInput, PaymentReportsSearchObject>
      storageKey="PAYMENT_REPORTS"
      placeholder={t('Search for payment report')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      stripExtendedSearchObject={stripHelperKeysFromSearchObject}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow
            label={t('Created at')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCreatedAtDateFromSearchObject}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
          />
          <AdvancedSearchDaterangeRow
            label={t('Reported date')}
            daterangeOptions={reportDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={mapCustomReportDateRangeToFilter}
            mapDaterangeToSearchObject={mapReportDaterangeToFilter}
            getIsDaterangeOptionActive={getIsReportDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripReportDateFromSearchObject}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
          />
          <AdvancedSearchTextRow
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Payment report ID')}
            placeholder={t('Enter payment report ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapIdToFilter}
            advancedSearchObject={advancedSearchObject}
            value={
              advancedSearchObject.id
                ? String(advancedSearchObject.id)
                : undefined
            }
          />
          {P([PermissionCode.ReadUsers]) && (
            <AdvancedSearchSelectRow<PaymentReportsFilterInput, number>
              label={t('Created by')}
              value={advancedSearchObject.createdByUserId || undefined}
              options={userSelectOptions}
              mapSelectValueToSearchObject={mapCreatedByUserIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
        </AdvancedSearchBase>
      )}
    />
  )
}
