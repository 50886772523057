import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../theme'

import {AccordionDetailsItemWrapper} from './AccordionDetailsItemWrapper'

const useStyles = makeStyles<Theme>((theme) => ({
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1
  },
  outlinedInputSecondaryColor: {
    color: theme.palette.text.secondary
  },
  root: {
    maxHeight: 400,
    overflowY: 'auto',
    padding: theme.spacing(0, 2),
    borderBottom: `solid ${theme.palette.divider} 1px`
  }
}))

interface IAdvancedSearchBaseProps {
  isSubmitDisabled: boolean
  children: React.ReactNode
  onSubmit(): void
}

export const AdvancedSearchBase: React.FC<IAdvancedSearchBaseProps> = ({
  isSubmitDisabled,
  onSubmit,
  children
}: IAdvancedSearchBaseProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>{children}</div>
      <AccordionDetailsItemWrapper>
        <div className={classes.buttonsBox}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={isSubmitDisabled}
          >
            {t('Search')}
          </Button>
        </div>
      </AccordionDetailsItemWrapper>
    </>
  )
}
