import {
  CreateWarehouseInput,
  UpdateWarehouseInput,
  WarehouseState
} from '../../../../__generated__/schema'
import {IWarehouseForm, WarehouseFormField} from './types'

export const transformFormDataToCreateWarehouseInput = (
  formData: IWarehouseForm
): CreateWarehouseInput => ({
  name: formData[WarehouseFormField.Name],
  code: formData[WarehouseFormField.Code] || undefined,
  divisionId: parseInt(formData[WarehouseFormField.DivisionId], 10)
})

export const transformFormDataToUpdateWarehouseInput = (
  formData: IWarehouseForm,
  state?: WarehouseState
): UpdateWarehouseInput => ({
  name: formData[WarehouseFormField.Name],
  code: formData[WarehouseFormField.Code] || null,
  state
})
