import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {
  ButtonGroupFormField,
  ButtonWithId,
  IButtonGroupForm
} from '../editBlockDrawer/types'
import {ButtonGroupForm} from './ButtonGroupForm'

const EDIT_BUTTON_GROUP_FORM_ID = 'editButtonGroupForm'

interface IEditButtonGroupDialogProps {
  button: ButtonWithId | null
  onClose: () => void
  onSave: (data: IButtonGroupForm) => void
}

export const EditButtonGroupDialog: React.FC<IEditButtonGroupDialogProps> = ({
  button,
  onClose,
  onSave
}: IEditButtonGroupDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={Boolean(button)}
      sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit button')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <ButtonGroupForm
          formId={EDIT_BUTTON_GROUP_FORM_ID}
          defaultValues={{
            [ButtonGroupFormField.Label]: button?.label || '',
            [ButtonGroupFormField.Link]: button?.link || '',
            [ButtonGroupFormField.OpenInNewTab]: Boolean(button?.openInNewTab),
            [ButtonGroupFormField.Size]: button?.size,
            [ButtonGroupFormField.Variant]: button?.variant,
            [ButtonGroupFormField.Icon]: button?.icon,
            [ButtonGroupFormField.IconPosition]: button?.iconPosition,
            [ButtonGroupFormField.ClassName]: button?.className
          }}
          onSubmit={onSave}
        />
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={EDIT_BUTTON_GROUP_FORM_ID}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
