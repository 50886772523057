import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import {Box, Chip, chipClasses, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ShowAgeClassificationCode,
  ShowVersionCode
} from '../../../__generated__/schema'
import {useTranslateAgeClassificationAbbreviation} from '../../../hooks/translateAgeClassification'
import {useTranslateShowVersionAbbreviation} from '../../../hooks/translateDistributions'
import {PRIMARY_100_COLOR} from '../../../theme'
import {useDateTimeFormatters} from '../../../utils/formatting'

interface ICartSummaryTourItemProps {
  name: string
  price: React.ReactNode
  startsAt: Date
  attendeesCount: number
  totalCount: number
  versionCode?: ShowVersionCode | null
  ageClassificationCode?: ShowAgeClassificationCode | null
  onClick?: () => void
  reservationId?: number
}

export const CartSummaryTourItem: React.FC<ICartSummaryTourItemProps> = ({
  name,
  price,
  startsAt,
  versionCode,
  ageClassificationCode,
  attendeesCount,
  totalCount,
  onClick,
  reservationId
}: ICartSummaryTourItemProps) => {
  const {t} = useTranslation()
  const {formatDateNumericShort, formatTime} = useDateTimeFormatters()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: `
      "name         price"
      "description  price"
    `,
        gridTemplateColumns: `1fr auto`,
        gap: 0.5,
        p: 1.5,
        '&:hover': {
          backgroundColor: (theme) =>
            onClick ? theme.palette.action.hover : 'none',
          cursor: onClick ? 'pointer' : 'normal'
        }
      }}
      onClick={onClick}
    >
      <Typography
        variant="subtitle2"
        sx={{
          gridArea: 'name',
          display: 'grid',
          alignItems: 'center',
          justifyContent: 'flex-start',
          pr: 1,
          gap: 1,
          gridAutoFlow: 'column'
        }}
      >
        {reservationId && <BookmarkBorderIcon fontSize="small" />}
        {name}
      </Typography>
      <Typography variant="body2" sx={{gridArea: 'price'}}>
        {price}
      </Typography>
      <Box
        sx={{
          gridArea: 'description',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5
        }}
      >
        <Chip
          label={`${attendeesCount}x`}
          size="small"
          sx={{
            [`&.${chipClasses.root}`]: {
              background: PRIMARY_100_COLOR,
              color: 'primary.main',
              height: 'auto'
            },
            [`& .${chipClasses.labelSmall}`]: {
              px: 0.5
            }
          }}
        />
        <Typography
          variant="caption"
          sx={{
            gridArea: 'title',
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 0.5,
            gridAutoFlow: 'column'
          }}
        >
          <div>{' • '}</div>
          <ConfirmationNumberOutlinedIcon fontSize="small" />
          <span>{totalCount}</span>
          <div>{' • '}</div>
          {[formatDateNumericShort(startsAt), formatTime(startsAt)].join(' • ')}
          {(versionCode || ageClassificationCode || reservationId) &&
            ` | ${[
              reservationId && t('#{{reservationId}}', {reservationId}),
              versionCode && translateShowVersionAbbreviation(versionCode),
              ageClassificationCode &&
                translateAgeClassificationAbbreviation(ageClassificationCode)
            ]
              .filter(Boolean)
              .join(' • ')}`}
        </Typography>
      </Box>
    </Box>
  )
}
