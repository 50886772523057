import {CreateInventoryCheckInput} from '../../../../__generated__/schema'
import {IInventoryCheckForm, InventoryCheckFormField} from './types'

export const transformInventoryCheckFormToCreateInventoryCheckInput = (
  formData: IInventoryCheckForm
): CreateInventoryCheckInput => ({
  type: formData[InventoryCheckFormField.Type],
  stockLevels: formData[InventoryCheckFormField.StockLevels],
  warehouseId: formData[InventoryCheckFormField.WarehouseId],
  note: formData[InventoryCheckFormField.Note] || undefined,
  productIds:
    formData.productIds && formData.productIds.length
      ? formData[InventoryCheckFormField.ProductIds]
      : undefined,
  productGroupIds:
    formData.productGroupIds && formData.productGroupIds.length
      ? formData[InventoryCheckFormField.ProductGroupIds]
      : undefined,
  productTypeIds:
    formData.productTypeIds && formData.productTypeIds.length
      ? formData[InventoryCheckFormField.ProductTypeIds]
      : undefined
})
