import {
  Drawer,
  Paper,
  PaperProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {get} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useProductParams} from '../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'

import {SaveButton} from '../../../../common/Buttons'
import {FormSwitchInput} from '../../../../form/FormSwitchInput'
import {FormFieldName} from '../../../../form/types'
import {useGetLightweightDivisions} from '../../graphql'
import {
  useGetProductAvailability,
  useUpsertProductAvailability
} from '../graphql'
import {IProductAvailabilityForm, ProductAvailabilityFormField} from './types'

const SAVE_PRODUCT_AVAILABILITY_FORM_ID = 'saveProductAvailabilityForm'

const PaperComponent = (props: PaperProps) => <Paper {...props} elevation={0} />

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  },
  table: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      borderBottom: `solid ${theme.palette.divider} 1px`
    },
    '& .MuiTableCell-body': {
      width: '100%'
    }
  },
  form: {
    padding: theme.spacing(1, 3)
  }
}))

interface IProductAvailabilityDrawerProps {
  onExited: () => void
}

export const ProductAvailabilityDrawer: React.FC<IProductAvailabilityDrawerProps> =
  ({onExited}: IProductAvailabilityDrawerProps) => {
    const {t} = useTranslation()
    const {productId} = useProductParams()
    const {watch, control, register, handleSubmit, getValues} =
      useForm<IProductAvailabilityForm>()
    const {
      divisions,
      loading: loadingDivisions,
      error: errorDivisions
    } = useGetLightweightDivisions()
    const {
      data: availabilityData,
      loading: loadingAvailability,
      error: errorAvailability
    } = useGetProductAvailability(productId)
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
      useMutationAssistanceHooks()
    const upsertProductAvailability = useUpsertProductAvailability()
    useEffect(() => {
      if (productId) {
        openDrawer()
      }
    }, [productId, openDrawer])
    const mergedProductAvailability = divisions.map((division) => ({
      divisionId: division.id,
      divisionName: division.name,
      isAvailableOnRetailChannel:
        availabilityData?.product.productAvailability.find(
          (pa) => pa.division.id === division.id
        )?.isAvailableOnRetailChannel || false,
      isAvailableOnECommerceChannel:
        availabilityData?.product.productAvailability.find(
          (pa) => pa.division.id === division.id
        )?.isAvailableOnECommerceChannel || false
    }))
    const onSubmit = useCallback(async () => {
      const fields = getValues()
      const productAvailabilityInputs = mergedProductAvailability.map(
        (pa, index) => ({
          divisionId: get(
            fields,
            `${ProductAvailabilityFormField.DivisionId}.${index}`
          ),
          isAvailableOnRetailChannel: get(
            fields,
            `${ProductAvailabilityFormField.IsAvailableOnRetailChannel}.${index}`
          ),
          isAvailableOnECommerceChannel: get(
            fields,
            `${ProductAvailabilityFormField.IsAvailableOnECommerceChannel}.${index}`
          )
        })
      )
      try {
        setShowBackdrop(true)
        await upsertProductAvailability({
          id: productId,
          productAvailabilityInputs
        })
        addInfoNotification(t('Product availability has been updated'))
        onExited()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while upserting product availability')
        )
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      defaultErrorHandler,
      getValues,
      mergedProductAvailability,
      onExited,
      productId,
      setShowBackdrop,
      t,
      upsertProductAvailability
    ])
    const classes = useStyles()
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          isLoading={loadingDivisions || loadingAvailability}
          errorMessage={
            (errorDivisions || errorAvailability) &&
            t<string>('Error while loading data')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Product availability')}
            />
          }
          footer={
            <SaveButton type="submit" form={SAVE_PRODUCT_AVAILABILITY_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          childrenClassName={classes.drawerChildren}
        >
          <form
            id={SAVE_PRODUCT_AVAILABILITY_FORM_ID}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
          >
            <TableContainer component={PaperComponent}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('Division')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('Retail')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" color="textSecondary">
                        {t('Ecommerce')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mergedProductAvailability.map((pa, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2">
                            <input
                              type="hidden"
                              name={`${ProductAvailabilityFormField.DivisionId}.${index}`}
                              key={`${ProductAvailabilityFormField.DivisionId}.${index}`}
                              ref={register()}
                              defaultValue={pa.divisionId}
                            />
                            {pa.divisionName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <FormSwitchInput<IProductAvailabilityForm>
                            watch={watch}
                            control={control}
                            name={
                              `${ProductAvailabilityFormField.IsAvailableOnRetailChannel}.${index}` as FormFieldName<IProductAvailabilityForm>
                            }
                            key={
                              `${ProductAvailabilityFormField.IsAvailableOnRetailChannel}.${index}` as FormFieldName<IProductAvailabilityForm>
                            }
                            defaultValue={pa.isAvailableOnRetailChannel}
                            enabledText=""
                            disabledText=""
                          />
                        </TableCell>
                        <TableCell>
                          <FormSwitchInput<IProductAvailabilityForm>
                            watch={watch}
                            control={control}
                            name={
                              `${ProductAvailabilityFormField.IsAvailableOnECommerceChannel}.${index}` as FormFieldName<IProductAvailabilityForm>
                            }
                            key={
                              `${ProductAvailabilityFormField.IsAvailableOnECommerceChannel}.${index}` as FormFieldName<IProductAvailabilityForm>
                            }
                            defaultValue={pa.isAvailableOnECommerceChannel}
                            enabledText=""
                            disabledText=""
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </DrawerTemplate>
      </Drawer>
    )
  }
