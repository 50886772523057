import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {Theme} from '../../../../../theme'
import {useIsValidEmail} from '../../../../../utils/formsValidations'
import {UncontrolledFormTextInputWithCustomIcon} from '../../../../form/UncontrolledFormTextInputWithCustomIcon'
import {RoleInputs} from '../RoleInputs'

export const INVITE_USERS_FORM_ID = 'inviteUsers'

interface IInviteUsersFormProps {
  onSubmit: () => any
  activeStep: number
}

const useStyles = makeStyles<Theme, {activeStep: number}>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(3, 3, 4, 3),
    rowGap: theme.spacing(4)
  },
  inputContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2)
  },
  isUserInviteFormVisible: {
    display: ({activeStep}) => (activeStep === 0 ? 'initial' : 'none')
  },
  isSelectRolesVisible: {
    display: ({activeStep}) => (activeStep === 0 ? 'none' : 'initial')
  }
}))

export const InviteUsersForm: React.FC<IInviteUsersFormProps> = ({
  onSubmit,
  activeStep
}: IInviteUsersFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useFormContext()
  const isValidEmail = useIsValidEmail()
  const classes = useStyles({activeStep})
  const [emailIds, setEmailIds] = useState([uuidv4()]) // TODO: replace dynamic fields with useFieldArray
  const addEmailField = useCallback(() => {
    setEmailIds([...emailIds, uuidv4()])
  }, [emailIds, setEmailIds])
  const removeEmailField = useCallback(
    (id: string) => () => {
      setEmailIds(emailIds.filter((emailId) => emailId !== id))
    },
    [emailIds, setEmailIds]
  )
  const clearEmailField = useCallback(
    (id: string) => () => setValue(`emails[${id}]`, ''),
    [setValue]
  )

  return (
    <div className={classes.root}>
      <Typography variant="caption">
        {activeStep === 0
          ? t('Type email addresses of users you want to invite.')
          : t('Choose roles for all users selected in previous step.')}
      </Typography>
      <form
        className={classes.isUserInviteFormVisible}
        id={INVITE_USERS_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes.inputContent}>
          {emailIds.filter(Boolean).map((id, index) => (
            <UncontrolledFormTextInputWithCustomIcon
              key={`emails[${id}]`}
              icon={index === 0 ? <CancelIcon /> : <DeleteIcon />}
              onIconClick={
                index === 0 ? clearEmailField(id) : removeEmailField(id)
              }
              isIconDisabled={index === 0 ? !watch(`emails[${id}]`) : undefined}
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              validationOptions={{
                validate: isValidEmail,
                required: true
              }}
              name={`emails[${id}]`}
              label={`${t('User')} ${index + 1}`}
              placeholder={t('Type email here')}
              fullWidth
            />
          ))}
          <Button
            onClick={addEmailField}
            startIcon={<AddIcon />}
            color="primary"
            variant="outlined"
          >
            {t('Add user')}
          </Button>
        </div>
      </form>
      <div className={classes.isSelectRolesVisible}>
        <RoleInputs roleIds={[]} name="roleIds" />
      </div>
    </div>
  )
}
