import {Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {ApiGrantState} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useApiGrantParams} from '../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {SplitButton} from '../../../../visual'
import {ApiGrantForm} from '../ApiGrantForm'
import {useApiGrant, useUpdateApiGrant} from '../graphql'
import {IApiGrantForm} from '../types'
import {
  transformApiGrantDataToDefaultValues,
  transformFormDataToUpdateApiGrantInput
} from '../utils'

const UPDATE_API_GRANT_FORM_ID = 'updateApiGrantForm'

interface IUpdateApiGrantDrawerProps {
  onExited: () => void
}

export const UpdateApiGrantDrawer: React.FC<IUpdateApiGrantDrawerProps> = ({
  onExited
}: IUpdateApiGrantDrawerProps) => {
  const {t} = useTranslation()
  const {apiGrantId} = useApiGrantParams()
  const {data, loading, error} = useApiGrant(apiGrantId)
  const updateApiGrant = useUpdateApiGrant()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const apiGrantState = useRef<ApiGrantState | undefined>(undefined)
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const changeApiGrantState = useCallback(
    (state: ApiGrantState) => () => (apiGrantState.current = state),
    []
  )
  const handleSubmit = useCallback(
    async (formData: IApiGrantForm) => {
      if (apiGrantState.current) {
        try {
          setShowBackdrop(true)
          await updateApiGrant({
            id: apiGrantId,
            input: transformFormDataToUpdateApiGrantInput(
              formData,
              apiGrantState.current
            )
          })
          addInfoNotification(t('API grant updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating API grant'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      apiGrantId,
      closeDrawer,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateApiGrant
    ]
  )
  useEffect(() => {
    if (apiGrantId) {
      openDrawer()
    }
  }, [apiGrantId, openDrawer])
  useEffect(() => {
    if (data) {
      apiGrantState.current = data.apiGrant.state
    }
  }, [data])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update API grant')}
          />
        }
        footer={
          <SplitButton
            Options={[
              <MenuItem
                key="save-as"
                type="submit"
                form={UPDATE_API_GRANT_FORM_ID}
                component="button"
                onClick={
                  data?.apiGrant.state === ApiGrantState.Active
                    ? changeApiGrantState(ApiGrantState.Inactive)
                    : changeApiGrantState(ApiGrantState.Active)
                }
              >
                {data?.apiGrant.state === ApiGrantState.Active
                  ? t('Save as inactive')
                  : t('Save as active')}
              </MenuItem>
            ]}
          >
            <SaveButton type="submit" form={UPDATE_API_GRANT_FORM_ID}>
              {t('Save')}
            </SaveButton>
          </SplitButton>
        }
        isLoading={loading}
        errorMessage={error && t<string>('Error while loading API grant')}
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {data && (
          <ApiGrantForm
            formId={UPDATE_API_GRANT_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={transformApiGrantDataToDefaultValues(data.apiGrant)}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
