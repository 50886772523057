import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {
  LeadField,
  LeadOptionPropertiesFragment
} from '../../../../../__generated__/schema'
import {useLeadSideNavigationListItems} from '../../../../../hooks/leadSection'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  StaticSideNavigationList
} from '../../../../common'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {ILeadForm} from '../types'
import {DRAWER_LEAD_FORM_ID, DrawerLeadForm} from './DrawerLeadForm'

const mediaMinWidthToDisplaySideNavigation = `(min-width: 720px)`

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    width: '100%',
    maxWidth: 960
  },
  drawerTemplateGrid: {
    display: 'grid',
    minHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    gridTemplateColumns: '226px 1fr',
    paddingBottom: theme.spacing(3)
  },
  sideNavigationBox: {
    position: 'relative',
    display: 'none',
    [`@media ${mediaMinWidthToDisplaySideNavigation}`]: {
      display: 'block'
    }
  },
  staticSideNavigationList: {
    top: theme.spacing(3),
    position: 'sticky'
  },
  drawerLeadForm: {
    padding: theme.spacing(0, 3),
    gridColumn: '1/3',
    [`@media ${mediaMinWidthToDisplaySideNavigation}`]: {
      gridColumn: '2/3'
    }
  }
}))
interface ILeadDrawerProps {
  leadOptionsObject: Partial<Record<LeadField, LeadOptionPropertiesFragment>>
  onExited: () => void
  defaultValues: ILeadForm
  onSubmit: (form: ILeadForm) => void
}

export const LeadDrawer: React.FC<ILeadDrawerProps> = ({
  defaultValues,
  leadOptionsObject,
  onExited,
  onSubmit
}: ILeadDrawerProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const exitAndResetDrawer = useCallback(() => {
    onExited()
    openDrawer()
  }, [onExited, openDrawer])
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  const handleSubmit = useCallback(
    (form: ILeadForm) => {
      onSubmit(form)
      exitAndResetDrawer()
    },
    [exitAndResetDrawer, onSubmit]
  )
  const sideNavigationItems = useLeadSideNavigationListItems(leadOptionsObject)
  return (
    <Drawer
      open={isOpen}
      classes={{paper: classes.drawerPaper}}
      anchor="right"
      onClose={closeDrawer}
      SlideProps={{
        onExited: exitAndResetDrawer
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            title={t('Lead')}
            onLeftActionClick={closeDrawer}
          />
        }
        footer={
          <>
            <CancelButton onClick={closeDrawer} />
            <SaveButton type="submit" form={DRAWER_LEAD_FORM_ID} />
          </>
        }
      >
        <div className={classes.drawerTemplateGrid}>
          <div className={classes.sideNavigationBox}>
            <StaticSideNavigationList
              items={sideNavigationItems}
              className={classes.staticSideNavigationList}
            />
          </div>
          <DrawerLeadForm
            className={classes.drawerLeadForm}
            defaultValues={defaultValues}
            leadOptionsObject={leadOptionsObject}
            onSubmit={handleSubmit}
          />
        </div>
      </DrawerTemplate>
    </Drawer>
  )
}
