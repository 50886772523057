import React from 'react'
import {useHistory} from 'react-router-dom'
import {useGetClientLocales} from '../../../../hooks/getLocales'
import {
  AbsoluteTwoLevelSideNavigation,
  SingleSideNavigationList
} from '../../../common'
import {ShowSection, useShowSectionTranslations} from '../../../constants'

import {CenteredLayout} from '../Layout'
import {useShowContentTypeAnchors} from './UploadContent'
import {useShowDetailsFormAnchors} from './useShowDetailsFormAnchors'
import {useShowTranslationsFormAnchors} from './useShowTranslationssFormAnchors'

const useShowNavigationItems = () => {
  const showDetailsFormAnchors = useShowDetailsFormAnchors()
  const showSectionTranslations = useShowSectionTranslations()
  const showTranslationsFormAnchors = useShowTranslationsFormAnchors()
  const contentTypeAnchors = useShowContentTypeAnchors()
  const clientLocales = useGetClientLocales()
  return {
    [ShowSection.Data]: {
      label: showSectionTranslations[ShowSection.Data],
      secondLevelItems: showDetailsFormAnchors
    },
    ...(clientLocales.length > 1
      ? {
          [ShowSection.Translations]: {
            label: showSectionTranslations[ShowSection.Translations],
            secondLevelItems: showTranslationsFormAnchors
          }
        }
      : {}),
    [ShowSection.Media]: {
      label: showSectionTranslations[ShowSection.Media],
      secondLevelItems: contentTypeAnchors
    }
  }
}

interface IShowCenteredLayoutWithNavigationProps {
  id: number
  showSection: ShowSection
  children: React.ReactNode
  getSectionRoute: (id: number | string, editSection: string) => string
}

export const ShowCenteredLayoutWithNavigation: React.FC<IShowCenteredLayoutWithNavigationProps> =
  ({
    id,
    showSection,
    children,
    getSectionRoute
  }: IShowCenteredLayoutWithNavigationProps) => {
    const navigationItems = useShowNavigationItems()
    const history = useHistory()
    return (
      <>
        <AbsoluteTwoLevelSideNavigation
          items={navigationItems}
          activeLevel={showSection}
          onSectionItemClick={(sectionItem: string) =>
            history.replace(getSectionRoute(id, sectionItem))
          }
        />
        <CenteredLayout>{children}</CenteredLayout>
      </>
    )
  }

interface IShowCenteredLayoutWithSingleNavigationProps {
  children: React.ReactNode
  items: {[key: string]: {id: string; label: string}}
}

export const ShowCenteredLayoutWithSingleNavigation: React.FC<IShowCenteredLayoutWithSingleNavigationProps> =
  ({children, items}: IShowCenteredLayoutWithSingleNavigationProps) => (
    <>
      <SingleSideNavigationList items={items} top={144} />
      <CenteredLayout>{children}</CenteredLayout>
    </>
  )
