import {useQuery} from '@apollo/react-hooks'
import {Box, Button, Divider, MenuItem} from '@mui/material'
import axios from 'axios'
import {isNil} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import validator from 'validator'
import {
  NarrowRetailPaymentMethodsQuery,
  NarrowRetailPaymentMethodsQueryVariables,
  PaymentMethodState,
  PaymentMethodType
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {
  IPosTerminalSettings,
  PosTerminalApiConfigResponse
} from '../../../../types'
import {getTerminalConfig} from '../../../../utils/getTerminalConfig'
import {InputBlockWithoutSpacings, RenderOnData} from '../../../common'
import {useNotifications} from '../../../context/notifications'
import {NARROW_RETAIL_PAYMENT_METHODS} from '../graphql'
import {EditPosSettingsDrawer} from './EditPosSettingsDrawer'
import {RowWithSelect} from './RowWithSelect'
import {RowWithSwitch} from './RowWithSwitch'
import {RowWithTextField} from './RowWithTextField'
import {DeviceSettingsAction, DeviceSettingsActionTypes} from './types'

interface IPosTerminalProps {
  blockId: string
  blockLabel: string
  settings: IPosTerminalSettings
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const PosTerminal: React.FC<IPosTerminalProps> = ({
  blockId,
  blockLabel,
  settings,
  dispatch
}: IPosTerminalProps) => {
  const {t} = useTranslation()
  const {enabled, port, paymentMethodId} = settings
  const [terminalConfig, setTerminalConfig] =
    useState<PosTerminalApiConfigResponse | null>(null)
  const {data, loading, error} = useQuery<
    NarrowRetailPaymentMethodsQuery,
    NarrowRetailPaymentMethodsQueryVariables
  >(NARROW_RETAIL_PAYMENT_METHODS, {
    variables: {state: PaymentMethodState.Active}
  })
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const {addErrorWithCustomDialogNotification} = useNotifications()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const [portError, setPortError] = useState<boolean>(false)
  const isSettingsDisabled = !enabled || isNil(port) || portError
  const handleSettingsButtonClick = useCallback(async () => {
    try {
      if (port) {
        setShowBackdrop(true)
        const response = await axios.request<PosTerminalApiConfigResponse>(
          getTerminalConfig({
            url: '/config',
            port
          })
        )
        setTerminalConfig(response.data)
        openDrawer()
      }
    } catch (error) {
      addErrorWithCustomDialogNotification({
        title: t('Unable to connect with POS terminal'),
        contentText: t(
          "We're sorry, but we couldn't establish a connection with the POS terminal. Please ensure the terminal is powered on and that you've set the correct port number. If the problem persists, ensure that the communication app is running on your computer, check your computer and POS terminal network connections, and try again."
        ),
        confirmButtonLabel: t('Got it')
      })
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addErrorWithCustomDialogNotification,
    openDrawer,
    port,
    setShowBackdrop,
    t
  ])
  return (
    <RenderOnData<NarrowRetailPaymentMethodsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading retail payment methods')}
      dataCondition={(data) => Array.isArray(data.retailPaymentMethods)}
      ignoreLoadingIfData
    >
      {({retailPaymentMethods}) => (
        <>
          <InputBlockWithoutSpacings
            blockId={blockId}
            header={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                {blockLabel}
                <Button
                  variant="text"
                  color="primary"
                  disabled={isSettingsDisabled}
                  onClick={handleSettingsButtonClick}
                >
                  {t('Settings')}
                </Button>
              </Box>
            }
            headerSx={{width: '100%'}}
          >
            <RowWithSwitch
              primaryLabel={t('Connect your device to the POS terminal?')}
              secondaryLabel={t(
                'Set up automatic connection between application and POS terminal for receiving card payments. Required local windows application to establish connection.'
              )}
              switchProps={{
                checked: enabled,
                onChange: () =>
                  dispatch({
                    type: DeviceSettingsActionTypes.ChangePosTerminalSettings,
                    payload: {
                      ...settings,
                      enabled: !enabled
                    }
                  })
              }}
            />
            {enabled && (
              <>
                <Divider />
                <RowWithTextField
                  primaryLabel={t('Communication port')}
                  secondaryLabel={t(
                    'Set up communication port with local app. Default is 3030.'
                  )}
                  textFieldProps={{
                    helperText: portError
                      ? t('*An integer between 0 and 65535 is required.')
                      : t('*Required'),
                    error: portError,
                    defaultValue: port,
                    required: true,
                    onChange: (event) => {
                      if (
                        validator.isInt(event.target.value, {
                          min: 0,
                          max: 65535
                        })
                      ) {
                        if (portError) {
                          setPortError(false)
                        }
                        dispatch({
                          type: DeviceSettingsActionTypes.ChangePosTerminalSettings,
                          payload: {
                            ...settings,
                            port: parseInt(event.target.value, 10)
                          }
                        })
                      } else {
                        setPortError(true)
                      }
                    }
                  }}
                />
                <Divider />
                <RowWithSelect
                  helperText={t('*Required')}
                  primaryLabel={t('Payment method')}
                  secondaryLabel={t(
                    'Select the payment method that will be managed through the POS terminal.'
                  )}
                  inputLabel={t('Select payment method')}
                  selectProps={{
                    required: true,
                    label: t('Select payment method'),
                    value:
                      retailPaymentMethods
                        .filter(({type}) => type === PaymentMethodType.Card)
                        .find(({id}) => id === paymentMethodId)?.id || '',
                    children: retailPaymentMethods
                      .filter(({type}) => type === PaymentMethodType.Card)
                      .map(({id, name}) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      )),
                    onChange: (event) =>
                      dispatch({
                        type: DeviceSettingsActionTypes.ChangePosTerminalSettings,
                        payload: {
                          ...settings,
                          paymentMethodId: parseInt(
                            event.target.value as string,
                            10
                          )
                        }
                      })
                  }}
                />
              </>
            )}
          </InputBlockWithoutSpacings>
          {terminalConfig && !isNil(port) && (
            <EditPosSettingsDrawer
              isOpen={isDrawerOpen}
              onClose={closeDrawer}
              terminalSettings={terminalConfig}
              port={port}
            />
          )}
        </>
      )}
    </RenderOnData>
  )
}
