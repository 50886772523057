import {IDimensions} from '@attendio/shared-components'
import React, {useEffect} from 'react'
import {EditorLayout} from './Main/EditorLayout'
import {useDisplayModeActions} from './redux/displayMode/actions'
import {DisplayMode} from './redux/displayMode/reducer'

type EditorProps = IDimensions & {
  DefaultPanel?: React.ReactElement
  displayMode?: DisplayMode
}

export const Editor: React.FC<EditorProps> = ({
  displayMode = DisplayMode.FULL,
  ...rest
}: EditorProps) => {
  const {setDisplayMode} = useDisplayModeActions()

  useEffect(() => {
    setDisplayMode(displayMode)
  }, [displayMode, setDisplayMode])

  return <EditorLayout {...rest} />
}
