import {Drawer} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useCreateDiscountCode, useCreateDiscountCodes} from '../graphql'
import {
  CREATE_DISCOUNT_CODE_FORM_ID,
  DiscountCodeForm
} from './DiscountCodeForm'
import {
  DiscountCodeFormField,
  DiscountCodeNamingApproach,
  getCreateDiscountCodesVariables,
  getCreateDiscountCodeVariables,
  ICreateDiscountCodeSubmitOptions,
  IDiscountCodeForm
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(2, 3, 3, 3)
  }
}))

interface ICreateDiscountCodeDrawerProps {
  isOpen: boolean
  discountId: number

  onClose(): void
  refetch: () => Promise<object>
}

export const CreateDiscountCodeDrawer: React.FC<ICreateDiscountCodeDrawerProps> =
  ({isOpen, onClose, discountId, refetch}: ICreateDiscountCodeDrawerProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const createDiscountCode = useCreateDiscountCode(discountId)
    const {setShowBackdrop, addInfoNotification} = useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (
        form: IDiscountCodeForm,
        options: ICreateDiscountCodeSubmitOptions
      ) => {
        try {
          setShowBackdrop(true)
          await createDiscountCode(
            getCreateDiscountCodeVariables(form, options, discountId)
          )
          await refetch()
          addInfoNotification(t('Discount code created.'))
          onClose()
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createDiscountCode,
        discountId,
        onClose,
        refetch,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create discount code')}
            />
          }
          footer={
            P([PermissionCode.CreateDiscountCode]) && (
              <SaveButton type="submit" form={CREATE_DISCOUNT_CODE_FORM_ID}>
                {t('Create')}
              </SaveButton>
            )
          }
        >
          <DiscountCodeForm
            className={classes.form}
            defaultValues={{
              [DiscountCodeFormField.NAMING_APPROACH]:
                DiscountCodeNamingApproach.GENERATE_RANDOM
            }}
            onSubmit={handleSubmit}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }

export const CreateDiscountCodesDrawer: React.FC<ICreateDiscountCodeDrawerProps> =
  ({isOpen, onClose, discountId, refetch}: ICreateDiscountCodeDrawerProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const createDiscountCodes = useCreateDiscountCodes(discountId)
    const {setShowBackdrop, addInfoNotification} = useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (
        form: IDiscountCodeForm,
        options: ICreateDiscountCodeSubmitOptions
      ) => {
        try {
          setShowBackdrop(true)
          const {data} = await createDiscountCodes(
            getCreateDiscountCodesVariables(form, options, discountId)
          )
          const count = data!.createDiscountCodes.length
          addInfoNotification(
            t('{{count}} discount codes created.', {
              count
            })
          )
          await refetch()
          onClose()
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createDiscountCodes,
        discountId,
        onClose,
        refetch,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create discount codes')}
            />
          }
          footer={
            P([PermissionCode.CreateDiscountCodes]) && (
              <SaveButton type="submit" form={CREATE_DISCOUNT_CODE_FORM_ID}>
                {t('Create')}
              </SaveButton>
            )
          }
        >
          <DiscountCodeForm
            className={classes.form}
            defaultValues={{
              [DiscountCodeFormField.NAMING_APPROACH]:
                DiscountCodeNamingApproach.GENERATE_RANDOM,
              [DiscountCodeFormField.USAGE_LIMIT]: '1'
            }}
            onSubmit={handleSubmit}
            canCreateMultiple
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
