import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {BaseInventoryCheckFieldsFragment} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useUpdateInventoryCheck} from './graphql'
import {InventoryCheckForm} from './InventoryCheckForm'
import {
  IInventoryCheckForm,
  InventoryCheckFormField,
  InventoryCheckFormLocation
} from './types'

const UPDATE_INVENTORY_CHECK_FORM_ID = 'updateInventoryCheckForm'

interface IUpdateInventoryCheckDrawerProps {
  onExited: () => void
  inventoryCheck: BaseInventoryCheckFieldsFragment
}

export const UpdateInventoryCheckDrawer: React.FC<IUpdateInventoryCheckDrawerProps> =
  ({onExited, inventoryCheck}: IUpdateInventoryCheckDrawerProps) => {
    const {t} = useTranslation()
    const updateInventoryCheck = useUpdateInventoryCheck()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const handleSubmit = useCallback(
      async (formData: IInventoryCheckForm) => {
        try {
          setShowBackdrop(true)
          await updateInventoryCheck({
            id: inventoryCheck.id,
            input: {
              note: formData[InventoryCheckFormField.Note] || null,
              type: formData[InventoryCheckFormField.Type]
            }
          })
          addInfoNotification(t('Inventory check has been updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating inventory check'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        defaultErrorHandler,
        inventoryCheck.id,
        setShowBackdrop,
        t,
        updateInventoryCheck
      ]
    )
    useEffect(() => {
      openDrawer()
    }, [openDrawer])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update inventory check')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_INVENTORY_CHECK_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <InventoryCheckForm
            formId={UPDATE_INVENTORY_CHECK_FORM_ID}
            onSubmit={handleSubmit}
            warehouses={[]}
            productGroups={[]}
            productTypes={[]}
            location={InventoryCheckFormLocation.Update}
            defaultValues={{
              [InventoryCheckFormField.Note]: inventoryCheck.note,
              [InventoryCheckFormField.Type]: inventoryCheck.type
            }}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
