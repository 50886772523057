import {useCallback, useMemo} from 'react'
import {useDispatch, useStore} from 'react-redux'

import {selectedObjectsIdsSelector} from '../selection/selectors'
import {PricingActionType} from './reducer'
import {ITicketType, IPricingState} from './types'

export const usePricingActions = () => {
  const dispatch = useDispatch()
  const store = useStore()

  const assignTicketType = useCallback(
    (ticketType: ITicketType) => {
      const state = store.getState()
      const seletedObjectsIds = selectedObjectsIdsSelector(state)

      dispatch({
        type: PricingActionType.ASSIGN_TICKET_TYPE,
        payload: {objectsIds: seletedObjectsIds, ticketType}
      })
    },
    [dispatch, store]
  )

  const removeTicketType = useCallback(
    (ticketTypeId: number) => {
      dispatch({
        type: PricingActionType.REMOVE_TICKET_TYPE,
        payload: {ticketTypeId}
      })
    },
    [dispatch]
  )

  const updateTicketTypeColor = useCallback(
    (ticketType: ITicketType) => {
      dispatch({
        type: PricingActionType.UPDATE_TICKET_TYPE_COLOR,
        payload: ticketType
      })
    },
    [dispatch]
  )

  const setPricing = useCallback(
    (pricing: IPricingState) => {
      dispatch({
        type: PricingActionType.SET_PRICING,
        payload: pricing
      })
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      assignTicketType,
      removeTicketType,
      updateTicketTypeColor,
      setPricing
    }),
    [assignTicketType, removeTicketType, setPricing, updateTicketTypeColor]
  )

  return actions
}
