import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {useObjectsActions} from '../redux/objects/actions'
import {ObjectsStateValue} from '../redux/objects/types'
import {usePlaceholderAndValue} from './placeholderAndValue'
import {TextFieldWithValidations} from './TextFieldWithValidations'
import {roundValueIfNumeric} from './utils'

interface IRotationTextFieldProps {
  selectedObjects: ObjectsStateValue[]
}

export const RotationTextField: React.FC<IRotationTextFieldProps> = ({
  selectedObjects
}: IRotationTextFieldProps) => {
  const {placeholder, value} = usePlaceholderAndValue({
    selectedObjects,
    iteratee: 'config.rotation'
  })
  const {t} = useTranslation()
  const {updateObjects} = useObjectsActions()
  const changeRotation = (rotation: number) => {
    updateObjects(
      selectedObjects.map(
        <T extends ObjectsStateValue>(object: T): T => ({
          ...object,
          config: {
            ...object.config,
            rotation
          }
        })
      )
    )
  }
  const handleDebouncedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = parseFloat(e.target.value)
    if (!isNaN(parsedNumber)) {
      changeRotation(parsedNumber)
    }
  }
  return (
    <TextFieldWithValidations
      label={t('Rotation')}
      type="number"
      defaultValue={roundValueIfNumeric(value)}
      placeholder={placeholder}
      variant="outlined"
      onChange={handleDebouncedChange}
      fullWidth
      validationOptions={{
        isRequired: true
      }}
    />
  )
}
