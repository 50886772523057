import {Divider, TextField, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React, {useCallback} from 'react'
import {TranslatedCurrency} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'

import {
  CashCounterAction,
  CashCounterActionType,
  CashCounterRow,
  CashCounterRowType
} from '../types'
import {CountCell} from './CountCell'
import {HeaderRow} from './HeaderRow'
import {SummaryRow} from './SummaryRow'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    padding: theme.spacing(1.5, 3),
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  moneyTableRoot: {
    gridTemplateColumns: '1fr auto 1fr'
  },
  voucherTableRoot: {
    gridTemplateColumns: 'auto auto 1fr'
  },
  divider: {
    gridColumn: '1 / 4'
  },
  summaryRow: {
    gridColumn: '1 / 4'
  },
  countCell: {
    display: 'flex',
    justifySelf: 'center'
  },
  header: {
    height: 32,
    paddingBottom: theme.spacing(1)
  },
  denominationInput: {
    maxWidth: 128
  }
}))

interface ITableProps {
  rows: CashCounterRow[]
  currency: TranslatedCurrency
  totalCount: number
  totalPrice: number
  dispatch: React.Dispatch<CashCounterAction>
  rowType: CashCounterRowType
}

const getRowSubtotal = (row: CashCounterRow): number =>
  new Decimal(row.denomination).mul(row.count).toNumber()

export const MoneyTable: React.FC<ITableProps> = ({
  rows,
  currency,
  totalCount,
  totalPrice,
  dispatch,
  rowType
}: ITableProps) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, classes.moneyTableRoot)}>
      <HeaderRow className={classes.header} currency={currency} />
      {rows.map((row) => [
        <Typography key={`denomination-cell-${row.id}`}>
          {row.denomination}
        </Typography>,
        <CountCell
          className={classes.countCell}
          key={`count-cell-${row.id}`}
          dispatch={dispatch}
          row={row}
          rowType={rowType}
        />,
        <Typography
          align="right"
          variant="subtitle1"
          key={`row-subtotal-cell-${row.id}`}
        >
          {getRowSubtotal(row)}
        </Typography>
      ])}
      <Divider className={classes.divider} />
      <SummaryRow
        currency={currency}
        count={totalCount}
        total={totalPrice}
        className={classes.summaryRow}
      />
    </div>
  )
}

export const VouchersTable: React.FC<ITableProps> = ({
  rows,
  currency,
  totalCount,
  totalPrice,
  dispatch,
  rowType
}: ITableProps) => {
  const classes = useStyles()

  const getOnDenominationChangeHandler = useCallback(
    (id: number) => (e: any) => {
      dispatch({
        type: CashCounterActionType.ChangeVoucherDenomination,
        payload: {
          id,
          value: Math.floor(parseFloat(e.target.value) * 100) / 100
        }
      })
    },
    [dispatch]
  )
  const handleDenominationInputFocus = useCallback((e) => {
    if (e.target) {
      e.target.select()
    }
  }, [])
  return (
    <div className={cn(classes.root, classes.voucherTableRoot)}>
      <HeaderRow className={classes.header} currency={currency} />
      {rows.map((row) => [
        <TextField
          key={`denomination-cell-${row.id}`}
          variant="outlined"
          type="number"
          value={row.denomination}
          className={classes.denominationInput}
          autoFocus
          onFocus={handleDenominationInputFocus}
          onChange={getOnDenominationChangeHandler(row.id)}
        />,
        <CountCell
          className={classes.countCell}
          key={`count-cell-${row.id}`}
          dispatch={dispatch}
          row={row}
          rowType={rowType}
        />,
        <Typography
          align="right"
          variant="subtitle1"
          key={`row-subtotal-cell-${row.id}`}
        >
          {getRowSubtotal(row)}
        </Typography>
      ])}
      <Divider className={classes.divider} />
      <SummaryRow
        currency={currency}
        count={totalCount}
        total={totalPrice}
        className={classes.summaryRow}
      />
    </div>
  )
}
