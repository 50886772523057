import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightVoucherCampaignsQuery,
  VouchersFilterInput
} from '../../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {useGetLightweightVoucherCampaigns} from './graphql'

type ExtendedVouchersFilter = VouchersFilterInput & {
  _activatedAtDaterangeId?: DATERANGE_IDS
  _expiredAtDaterangeId?: DATERANGE_IDS
  _activationDateDaterangeId?: DATERANGE_IDS
  _expirationDateDaterangeId?: DATERANGE_IDS
}

const stripActivatedAtDateFromFilter = (
  filter: ExtendedVouchersFilter
): VouchersFilterInput =>
  omit(filter, ['activatedAtFrom', 'activatedAtTo', '_activatedAtDaterangeId'])

const stripExpiredAtDateFromFilter = (
  filter: ExtendedVouchersFilter
): VouchersFilterInput =>
  omit(filter, ['expiredAtFrom', 'expiredAtTo', '_expiredAtDaterangeId'])

const stripActivationDateFromFilter = (
  filter: ExtendedVouchersFilter
): VouchersFilterInput =>
  omit(filter, [
    'activationDateFrom',
    'activationDateTo',
    '_activationDateDaterangeId'
  ])

const stripExpirationDateFromFilter = (
  filter: ExtendedVouchersFilter
): VouchersFilterInput =>
  omit(filter, [
    'expirationDateFrom',
    'expirationDateTo',
    '_expirationDateDaterangeId'
  ])

const stripHelperKeysFromFilter = (
  filter: ExtendedVouchersFilter
): VouchersFilterInput =>
  omit(filter, [
    '_activatedAtDaterangeId',
    '_expiredAtDaterangeId',
    '_activationDateDaterangeId',
    '_expirationDateDaterangeId'
  ])

export const DEFAULT_VOUCHERS_FILTER_INPUT: VouchersFilterInput = {}

interface IVouchersSearchProps {
  onFilterChange: (filter: VouchersFilterInput) => void
}

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getCodeFromSearchObject = (filter: VouchersFilterInput) =>
    filter.code || undefined
  const getCampaignFromSearchObject = (
    filter: VouchersFilterInput,
    campaigns: GetLightweightVoucherCampaignsQuery['voucherCampaigns']['items']
  ) => {
    if (filter.campaignId) {
      const campaign = campaigns.find(({id}) => id === filter.campaignId)
      return campaign
        ? t('Campaign: {{campaign}}', {campaign: campaign.name})
        : t('Campaign ID: {{id}}', {id: filter.campaignId})
    }
    return undefined
  }
  return {
    getCodeFromSearchObject,
    getCampaignFromSearchObject
  }
}

const mapCodeToFilter = (
  filter: VouchersFilterInput,
  code: string | undefined
): VouchersFilterInput => ({
  ...filter,
  code
})

const mapCampaignIdToFilter = (
  filter: VouchersFilterInput,
  campaignId: number | undefined
) => ({...filter, campaignId})

const pastDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_30_DAYS
]

const futureDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.TOMORROW,
  DATERANGE_IDS.NEXT_7_DAYS,
  DATERANGE_IDS.NEXT_30_DAYS
]

export const VouchersSearch: React.FC<IVouchersSearchProps> = ({
  onFilterChange
}: IVouchersSearchProps) => {
  const {t} = useTranslation()
  const {data: voucherCampaignsData} = useGetLightweightVoucherCampaigns()
  const {getCodeFromSearchObject, getCampaignFromSearchObject} =
    useGetFieldFromSearchObject()
  const voucherCampaignOptions: ISelectOption<number>[] = (
    voucherCampaignsData?.voucherCampaigns.items || []
  ).map((vc) => ({
    id: vc.id,
    label: vc.name
  }))
  const {
    daterangeOptions: activatedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomActivatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapActivatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedActivatedAtDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsActivatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedVouchersFilter>({
    usedDateranges: pastDateRanges,
    dateRangeInputPrefix: t('Activated'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.activatedAtFrom || undefined,
      endDateISOString: o.activatedAtTo || undefined,
      id: o._activatedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      activatedAtFrom: input.startDate,
      activatedAtTo: input.endDate,
      _activatedAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: expiredAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomExpiredAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapExpiredAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedExpiredAtDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsExpiredAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedVouchersFilter>({
    usedDateranges: pastDateRanges,
    dateRangeInputPrefix: t('Expired'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.expiredAtFrom || undefined,
      endDateISOString: o.expiredAtTo || undefined,
      id: o._expiredAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      expiredAtFrom: input.startDate,
      expiredAtTo: input.endDate,
      _expiredAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: activationDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomActivationDateDateRangeToFilter,
    mapDaterangeToSearchObject: mapActivationDateDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedActivationDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsActivationDateDaterangeOptionActive
  } = useDateRangeSearch<ExtendedVouchersFilter>({
    usedDateranges: futureDateRanges,
    dateRangeInputPrefix: t('Activation date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.activationDateFrom || undefined,
      endDateISOString: o.activationDateTo || undefined,
      id: o._activationDateDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      activationDateFrom: input.startDate,
      activationDateTo: input.endDate,
      _activationDateDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: expirationDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomExpirationDateDateRangeToFilter,
    mapDaterangeToSearchObject: mapExpirationDateDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedExpirationDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsExpirationDateDaterangeOptionActive
  } = useDateRangeSearch<ExtendedVouchersFilter>({
    usedDateranges: futureDateRanges,
    dateRangeInputPrefix: t('Expiration date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.expirationDateFrom || undefined,
      endDateISOString: o.expirationDateTo || undefined,
      id: o._expirationDateDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      expirationDateFrom: input.startDate,
      expirationDateTo: input.endDate,
      _expirationDateDaterangeId: input.id
    })
  })
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<VouchersFilterInput>(
      getCodeFromSearchObject,
      getStringifiedActivatedAtDateRangeFromFilter,
      getStringifiedExpiredAtDateRangeFromFilter,
      (filter) =>
        getCampaignFromSearchObject(
          filter,
          voucherCampaignsData?.voucherCampaigns.items || []
        ),
      getStringifiedActivationDateDateRangeFromFilter,
      getStringifiedExpirationDateDateRangeFromFilter
    )
  return (
    <Search<VouchersFilterInput, ExtendedVouchersFilter>
      storageKey="VOUCHERS_LIST"
      placeholder={t('Search for voucher')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapCodeToFilter}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_VOUCHERS_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow
            label={t('Activated')}
            daterangeOptions={activatedAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomActivatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapActivatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsActivatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripActivatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          <AdvancedSearchDaterangeRow
            label={t('Expired')}
            daterangeOptions={expiredAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomExpiredAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapExpiredAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsExpiredAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripExpiredAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          <AdvancedSearchTextRow
            label={t('Code')}
            placeholder={t('Enter voucher code')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapCodeToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.code || undefined}
          />
          <AdvancedSearchSelectRow<VouchersFilterInput, number>
            label={t('Campaign')}
            value={advancedSearchObject.campaignId || undefined}
            options={voucherCampaignOptions}
            mapSelectValueToSearchObject={mapCampaignIdToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchDaterangeRow
            label={t('Activation date')}
            daterangeOptions={activationDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomActivationDateDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapActivationDateDaterangeToFilter}
            getIsDaterangeOptionActive={
              getIsActivationDateDaterangeOptionActive
            }
            stripDaterangeFromSearchObject={stripActivationDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          <AdvancedSearchDaterangeRow
            label={t('Expiration date')}
            daterangeOptions={expirationDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomExpirationDateDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapExpirationDateDaterangeToFilter}
            getIsDaterangeOptionActive={
              getIsExpirationDateDaterangeOptionActive
            }
            stripDaterangeFromSearchObject={stripExpirationDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
