import {useTranslation} from 'react-i18next'
import {EventState} from '../__generated__/schema'

export const useTranslateEventState = () => {
  const {t} = useTranslation()
  const translated: Record<EventState, string> = {
    [EventState.Published]: t('Published events'),
    [EventState.Canceled]: t('Canceled events'),
    [EventState.Draft]: t('Draft events'),
    [EventState.Deleted]: t('Deleted events')
  }
  return (state: EventState) => translated[state]
}
