import {useTranslation} from 'react-i18next'
import {TourTimeSlotViewMode} from '../types'

export const useTranslateTourTimeSlotViewMode = () => {
  const {t} = useTranslation()
  const translated: Record<TourTimeSlotViewMode, string> = {
    [TourTimeSlotViewMode.ListView]: t<string>(
      'TourTimeSlotViewMode->ListView'
    ),
    [TourTimeSlotViewMode.GridView]: t<string>('TourTimeSlotViewMode->GridView')
  }
  return (mode: TourTimeSlotViewMode) => translated[mode]
}
