import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import {Box, Button, IconButton} from '@mui/material'
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams
} from '@mui/x-data-grid-pro'
import {filter} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  PermissionCode,
  ShowVideo
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateShowVideoSource} from '../../../../hooks/translateShowVideoSource'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {AddVideoDialog} from '../../../common/addVideoDialog'
import {IAddVideoFormResult} from '../../../common/addVideoDialog/types'
import {transformVideoIdentifierToEmbedUrl} from '../../../common/addVideoDialog/utils'
import {VideoPreview} from '../../../common/addVideoDialog/VideoPreview'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {DataGridTable} from '../../../common/DataGridTable'
import {
  useAddClientShowVideo,
  useAddLibraryShowVideo,
  useUpdateClientShowVideos,
  useUpdateLibraryShowVideos
} from './graphql'
import {ImageAccordion} from './ImageAccordion'

const PlayButtonCellRenderer = ({url}: {url: string}) => {
  const {t} = useTranslation()
  const {
    state: isVideoPreviewOpen,
    setTrue: openVideoPreview,
    setFalse: closeVideoPreview
  } = useBooleanState(false)
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PlayArrowIcon />}
        onClick={openVideoPreview}
      >
        {t('Play')}
      </Button>
      <VideoPreview
        isOpen={isVideoPreviewOpen}
        onClose={closeVideoPreview}
        url={url}
        title={t('Video preview')}
      />
    </>
  )
}

const DeleteIconCellRenderer = ({onClick}: {onClick: () => void}) => (
  <IconButton color="primary" onClick={onClick}>
    <DeleteIcon />
  </IconButton>
)

interface IVideosSectionAccordionProps {
  showId: number
  videos: Pick<ShowVideo, 'source' | 'url' | 'sourceVideoId'>[]
  isLibraryVideo?: boolean
}

export const VideosSectionAccordion: React.FC<IVideosSectionAccordionProps> = ({
  showId,
  videos,
  isLibraryVideo
}: IVideosSectionAccordionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateClientShowVideos = useUpdateClientShowVideos()
  const addClientShowVideo = useAddClientShowVideo()
  const addLibraryShowVideo = useAddLibraryShowVideo()
  const updateLibraryShowVideos = useUpdateLibraryShowVideos()
  const {
    setShowBackdrop,
    customErrorHandler,
    defaultErrorHandler,
    addInfoNotification
  } = useMutationAssistanceHooks()
  const {
    state: isExpanded,
    setTrue: setExpanded,
    setFalse: setShrinked
  } = useBooleanState(false)
  const {
    state: isAddVideoDialogOpen,
    setTrue: openAddVideoDialog,
    setFalse: closeAddVideoDialog
  } = useBooleanState(false)
  const {
    state: isVideoPreviewOpen,
    setTrue: openVideoPreview,
    setFalse: closeVideoPreview
  } = useBooleanState(false)
  const [filteredVideos, setFilteredVideos] =
    useState<Pick<ShowVideo, 'source' | 'url' | 'sourceVideoId'>[] | null>(null)
  const [addedVideo, setAddedVideo] = useState<IAddVideoFormResult | null>(null)
  const translateShowVideoSource = useTranslateShowVideoSource()
  const handleAccordionChange = useCallback(
    (e, isExpanded: boolean) => (isExpanded ? setExpanded() : setShrinked()),
    [setExpanded, setShrinked]
  )
  const handleRowOrderChange = useCallback(
    (videos: Pick<ShowVideo, 'source' | 'url' | 'sourceVideoId'>[]) =>
      async (params: GridRowOrderChangeParams) => {
        const videoRowsClose = [...videos]
        const row = videoRowsClose.splice(params.oldIndex, 1)[0]
        videoRowsClose.splice(params.targetIndex, 0, row)
        try {
          setShowBackdrop(true)
          if (isLibraryVideo) {
            await updateLibraryShowVideos({
              id: showId,
              input: videoRowsClose.map(({source, sourceVideoId}) => ({
                source,
                sourceVideoId
              }))
            })
          } else {
            await updateClientShowVideos({
              id: showId,
              input: videoRowsClose.map(({source, sourceVideoId}) => ({
                source,
                sourceVideoId
              }))
            })
          }
        } catch (error) {
          customErrorHandler(error, {
            title: t('Operation has failed'),
            contentText: t(
              "We're sorry, but there was a problem updating videos. Please try again."
            ),
            confirmButtonLabel: t('Got it'),
            onConfirm: () => window.location.reload()
          })
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      customErrorHandler,
      isLibraryVideo,
      setShowBackdrop,
      showId,
      t,
      updateClientShowVideos,
      updateLibraryShowVideos
    ]
  )
  const handleDeleteVideoConfirm = useCallback(async () => {
    if (filteredVideos) {
      try {
        setShowBackdrop(true)
        if (isLibraryVideo) {
          await updateLibraryShowVideos({
            id: showId,
            input: filteredVideos.map(({source, sourceVideoId}) => ({
              source,
              sourceVideoId
            }))
          })
        } else {
          await updateClientShowVideos({
            id: showId,
            input: filteredVideos.map(({source, sourceVideoId}) => ({
              source,
              sourceVideoId
            }))
          })
        }
        setFilteredVideos(null)
      } catch (error) {
        customErrorHandler(error, {
          title: t('Operation has failed'),
          contentText: t(
            "We're sorry, but there was a problem updating videos. Please try again."
          ),
          confirmButtonLabel: t('Got it')
        })
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    customErrorHandler,
    filteredVideos,
    isLibraryVideo,
    setShowBackdrop,
    showId,
    t,
    updateClientShowVideos,
    updateLibraryShowVideos
  ])
  const handleSubmitVideo = useCallback(
    (result: IAddVideoFormResult) => {
      setAddedVideo(result)
      openVideoPreview()
    },
    [openVideoPreview]
  )
  const handleCloseVideoPreview = useCallback(() => {
    closeVideoPreview()
    closeAddVideoDialog()
    setAddedVideo(null)
  }, [closeAddVideoDialog, closeVideoPreview])
  const handleAddVideo = useCallback(
    (video: IAddVideoFormResult) => async () => {
      try {
        setShowBackdrop(true)
        if (isLibraryVideo) {
          await addLibraryShowVideo({
            id: showId,
            input: {source: video.source, sourceVideoId: video.identifier}
          })
        } else {
          await addClientShowVideo({
            id: showId,
            input: {source: video.source, sourceVideoId: video.identifier}
          })
        }
        addInfoNotification(t('Video has been added'))
        handleCloseVideoPreview()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.ShowVideoAlreadyExists
          ) ||
          error.graphQLErrors?.find(
            (e: any) =>
              e.message.split(':')[0] === ErrorMessages.ShowVideoAlreadyExists
          )
        ) {
          customErrorHandler(error, {
            title: t('Duplicate video detected'),
            contentText: t(
              'It looks like this video has already been added to the show. To keep things organized, each video can only be added once. Please check your show or consider adding a different video. If you need assistance or think this is an error, please contact our support team.'
            ),
            confirmButtonLabel: t('Got it'),
            onConfirm: () => {
              closeVideoPreview()
              closeAddVideoDialog()
            }
          })
        } else {
          defaultErrorHandler(error, t('Error while adding client show video'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addClientShowVideo,
      addInfoNotification,
      addLibraryShowVideo,
      closeAddVideoDialog,
      closeVideoPreview,
      customErrorHandler,
      defaultErrorHandler,
      handleCloseVideoPreview,
      isLibraryVideo,
      setShowBackdrop,
      showId,
      t
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        width: 40
      },
      {
        headerName: '',
        field: 'playButton',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <PlayButtonCellRenderer url={params.row.url} />
        },
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Service'),
        field: 'source',
        valueFormatter: (params) => translateShowVideoSource(params.value),
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Identifier'),
        field: 'sourceVideoId',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: '',
        field: 'deleteIcon',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <DeleteIconCellRenderer
              onClick={() =>
                setFilteredVideos(
                  filter(
                    videos,
                    ({sourceVideoId}) =>
                      sourceVideoId !== params.row.sourceVideoId
                  )
                )
              }
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [t, translateShowVideoSource, videos]
  )
  return (
    <>
      <ImageAccordion
        isExpanded={isExpanded}
        onAccordionChange={handleAccordionChange}
        summaryTitle={t('Videos')}
        summaryDescription={t('{{count}} items', {count: videos.length})}
        accordionDetailsSx={{'&&': {p: 0}}}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: 3,
            pb: 2
          }}
        >
          <Button
            startIcon={<AddIcon />}
            variant="text"
            color="primary"
            onClick={openAddVideoDialog}
          >
            {t('Add')}
          </Button>
        </Box>
        {videos.length > 0 && (
          <DataGridTable
            sx={{border: 'none', borderRadius: 0}}
            columns={columns}
            rows={videos.map((video, index) => ({id: index, ...video}))}
            pagination={false}
            slots={{rowReorderIcon: DragHandleIcon}}
            rowReordering={P([PermissionCode.UpdateClientShowVideos])}
            onRowOrderChange={handleRowOrderChange(videos)}
            initialState={{
              pinnedColumns: {
                left: ['__reorder__', 'playButton'],
                right: ['deleteIcon']
              }
            }}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            autoHeight
          />
        )}
      </ImageAccordion>
      <ConfirmationDialog
        title={t('Delete video?')}
        contentText={t(
          'Are you sure you want to delete this video? This action cannot be undone. '
        )}
        onConfirm={handleDeleteVideoConfirm}
        confirmButtonLabel={t('Delete')}
        isOpen={Boolean(filteredVideos)}
        onCancel={() => setFilteredVideos(null)}
      />
      <AddVideoDialog
        isOpen={isAddVideoDialogOpen}
        onClose={closeAddVideoDialog}
        onSubmit={handleSubmitVideo}
      />
      {addedVideo && (
        <VideoPreview
          isOpen={isVideoPreviewOpen}
          onClose={handleCloseVideoPreview}
          url={transformVideoIdentifierToEmbedUrl(addedVideo)}
          onBackButtonClick={closeVideoPreview}
          onAddButtonClick={handleAddVideo(addedVideo)}
          title={t('Add video')}
        />
      )}
    </>
  )
}
