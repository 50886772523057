import {ICoords, IDimensions} from '@attendio/shared-components'
import {SCALE_FACTOR, SCALE_MAX, SCALE_MIN} from '../../config'
import {IRectangle} from '../../types'
import {
  findNearestScale,
  getFitToScreenProperties,
  getOriginAfterZoom
} from '../../utils/common'

export type CanvasState = {
  dimensions: IDimensions
  scale: number
  origin: ICoords
}

const initialState: CanvasState = {
  dimensions: {
    height: window.innerHeight,
    width: window.innerWidth
  },
  scale: 1,
  origin: {
    x: 0,
    y: 0
  }
}

export enum CanvasActionType {
  FIT_TO_SCREEN = 'fit to screen',
  SET_DIMENSIONS = 'set dimensions',
  SET_ORIGIN = 'set origin',
  ZOOM_IN = 'zoom in',
  ZOOM_OUT = 'zoom out'
}

interface ICanvasDimensionsAction {
  type: typeof CanvasActionType.SET_DIMENSIONS
  payload: IDimensions
}

interface ICanvasScaleAction {
  type: typeof CanvasActionType.ZOOM_IN | typeof CanvasActionType.ZOOM_OUT
  payload: ICoords
}

interface ICanvasOriginAction {
  type: typeof CanvasActionType.SET_ORIGIN
  payload: ICoords
}

interface ICanvasFitToScreenAction {
  type: typeof CanvasActionType.FIT_TO_SCREEN
  payload: IRectangle
}

type CanvasAction =
  | ICanvasDimensionsAction
  | ICanvasScaleAction
  | ICanvasOriginAction
  | ICanvasFitToScreenAction

export const canvasReducer = (
  state = initialState,
  action: CanvasAction
): CanvasState => {
  switch (action.type) {
    case CanvasActionType.SET_DIMENSIONS:
      return {
        ...state,
        dimensions: action.payload
      }
    case CanvasActionType.FIT_TO_SCREEN: {
      const {scale, origin} = getFitToScreenProperties(
        action.payload,
        state.dimensions
      )

      return {
        ...state,
        origin,
        scale
      }
    }
    case CanvasActionType.ZOOM_IN: {
      if (state.scale >= SCALE_MAX) {
        return state
      }

      const newScale = findNearestScale(state.scale * SCALE_FACTOR, true)

      return {
        ...state,
        origin: getOriginAfterZoom({
          anchor: action.payload,
          newScale,
          dimensions: state.dimensions,
          origin: state.origin,
          scale: state.scale
        }),
        scale: newScale
      }
    }
    case CanvasActionType.ZOOM_OUT: {
      if (state.scale <= SCALE_MIN) {
        return state
      }

      const newScale = findNearestScale(state.scale / SCALE_FACTOR, false)

      return {
        ...state,
        origin: getOriginAfterZoom({
          anchor: action.payload,
          newScale,
          dimensions: state.dimensions,
          origin: state.origin,
          scale: state.scale
        }),
        scale: newScale
      }
    }
    case CanvasActionType.SET_ORIGIN:
      return {
        ...state,
        origin: action.payload
      }
    default:
      return state
  }
}
