import {ApolloError} from 'apollo-client'
import {Html5Qrcode} from 'html5-qrcode'
import {CameraDevice} from 'html5-qrcode/esm/core'
import {useCallback, useEffect, useState} from 'react'

interface IFetchCameras {
  isLoading: boolean
  error?: ApolloError
  data: CameraDevice[]
}
const defaultState: IFetchCameras = {
  isLoading: true,
  data: []
}

export const useFetchCameras = () => {
  const [state, setState] = useState<IFetchCameras>(defaultState)
  const fetchData = useCallback(async () => {
    try {
      setState((prevState) => ({...prevState, isLoading: true}))
      const result = await Html5Qrcode.getCameras()
      setState({
        isLoading: false,
        data: result
      })
    } catch (error) {
      setState({
        isLoading: false,
        error: new ApolloError({errorMessage: 'Not permitted'}),
        data: []
      })
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return {state, refetchCameras: fetchData}
}
