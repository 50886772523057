import {
  ECommerceTheme,
  ECommerceThemeBackgroundColor
} from '../../../../../../__generated__/schema'

export enum ButtonTarget {
  Self = '_self',
  Top = '_top',
  Blank = '_blank'
}

export enum EcommerceSettingsFormField {
  Theme = 'theme',
  ShowCartButton = 'showCartButton',
  BackgroundColor = 'backgroundColor',
  GatewayButtonTarget = 'gatewayButtonTarget',
  EventOverviewEnabled = 'eventOverviewEnabled',
  EventButtonTarget = 'eventButtonTarget',
  CartUrl = 'cartUrl'
}

export interface IEcommerceSettingsForm {
  [EcommerceSettingsFormField.Theme]?: ECommerceTheme
  [EcommerceSettingsFormField.ShowCartButton]: boolean
  [EcommerceSettingsFormField.BackgroundColor]?: ECommerceThemeBackgroundColor
  [EcommerceSettingsFormField.GatewayButtonTarget]?: ButtonTarget
  [EcommerceSettingsFormField.EventOverviewEnabled]: boolean
  [EcommerceSettingsFormField.EventButtonTarget]?: ButtonTarget
  [EcommerceSettingsFormField.CartUrl]: string
}

export const eCommerceThemeWithHex: Record<ECommerceTheme, string> = {
  [ECommerceTheme.RubyRed]: '#E6007A',
  [ECommerceTheme.CrimsonRed]: '#DD292C',
  [ECommerceTheme.OrangeRed]: '#FF4500',
  [ECommerceTheme.Coral]: '#FF7F50',
  [ECommerceTheme.DarkOrange]: '#FF8C00',
  [ECommerceTheme.BronzeBrown]: '#AB9159',
  [ECommerceTheme.LimeGreen]: '#69B92E',
  [ECommerceTheme.ForestGreen]: '#1D864B',
  [ECommerceTheme.SeaGreen]: '#26A65B',
  [ECommerceTheme.EmeraldGreen]: '#38A867',
  [ECommerceTheme.Teal]: '#1DA59E',
  [ECommerceTheme.SteelBlue]: '#218DC7',
  [ECommerceTheme.DodgerBlue]: '#1471EB',
  [ECommerceTheme.EletricIndigo]: '#4E13FF',
  [ECommerceTheme.NavyBlue]: '#000080',
  [ECommerceTheme.DeepPurple]: '#6A1B9A',
  [ECommerceTheme.Purple]: '#9D38B4',
  [ECommerceTheme.Amethyst]: '#9B51E0',
  [ECommerceTheme.DarkGray]: '#333333'
}
