import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useMemo} from 'react'

import {
  CreateEventPricingMutation,
  CreateEventPricingMutationVariables,
  DeleteEventPricingMutation,
  DeleteEventPricingMutationVariables,
  EventPricingsQuery,
  EventPricingsQueryVariables,
  EventWithPricingPropertiesFragment,
  UpdateEventPricingMutation,
  UpdateEventPricingMutationVariables
} from '../../../../../../__generated__/schema'
import {
  PricingStatus,
  setPricingsStatus
} from '../../../../../../utils/setPricingsStatus'

// TODO: it might be better if backend return whole Event object
// for `create/update` event pricing queries
const EVENT_PRICING_PROPERTIES_FRAGMENT = gql`
  fragment EventPricingProperties on EventPricing {
    id
    eventId
    startsAt
    ticketTypes {
      id
      eventPricingId
      name
      description
      promotions
      ticketTypeId
      price
      vatRate
    }
  }
`

export const EVENT_WITH_PRICING_PROPERTIES_FRAGMENT = gql`
  fragment EventWithPricingProperties on Event {
    id
    state
    startsAt
    endsAt
    state
    pricings {
      id
      eventId
      startsAt
      ticketTypes {
        id
        eventPricingId
        name
        description
        promotions
        ticketTypeId
        price
        vatRate
      }
    }
    division {
      id
      client {
        id
        VATRegistered
        countryCode
        ticketDefaultVatRate
      }
    }
  }
`

const GET_EVENT_PRICING_DATA = gql`
  query EventPricings($id: Int!) {
    event(id: $id) {
      ...EventWithPricingProperties
    }
  }
  ${EVENT_WITH_PRICING_PROPERTIES_FRAGMENT}
`

const CREATE_EVENT_PRICING = gql`
  mutation CreateEventPricing($eventId: Int!, $data: EventPricingInput!) {
    createEventPricing(eventId: $eventId, data: $data) {
      ...EventPricingProperties
    }
  }
  ${EVENT_PRICING_PROPERTIES_FRAGMENT}
`

const UPDATE_EVENT_PRICING = gql`
  mutation UpdateEventPricing(
    $id: Int!
    $data: EventPricingInput!
    $eventId: Int!
  ) {
    updateEventPricing(id: $id, data: $data, eventId: $eventId) {
      ...EventPricingProperties
    }
  }
  ${EVENT_PRICING_PROPERTIES_FRAGMENT}
`

const DELETE_EVENT_PRICING = gql`
  mutation DeleteEventPricing($id: Int!, $eventId: Int!) {
    deleteEventPricing(id: $id, eventId: $eventId)
  }
`

type Pricing = EventWithPricingPropertiesFragment['pricings'][number]

export interface PricingWithStatus extends Pricing {
  status: PricingStatus
}

export interface EventPricingData extends EventWithPricingPropertiesFragment {
  pricings: Array<PricingWithStatus>
}

export const useGetEventPricingData = (
  id: number
): {loading: boolean; error: any; data: EventPricingData | null} => {
  const {data, loading, error} = useQuery<
    EventPricingsQuery,
    EventPricingsQueryVariables
  >(GET_EVENT_PRICING_DATA, {
    variables: {id}
  })

  const _data = useMemo(
    () =>
      data
        ? {
            ...data.event,
            pricings: setPricingsStatus({pricings: data.event.pricings})
          }
        : null,
    [data]
  )

  return {
    data: _data,
    error,
    loading
  }
}

export const useCreateEventPricing = (eventId: number) => {
  const [createEventPricing] = useMutation<
    CreateEventPricingMutation,
    CreateEventPricingMutationVariables
  >(CREATE_EVENT_PRICING, {
    // TODO: it might be better if backend return whole Event object.
    // For now we refetch it in an extra query.
    refetchQueries: () => {
      return [{query: GET_EVENT_PRICING_DATA, variables: {id: eventId}}]
    }
  })
  return ({eventId, data}: CreateEventPricingMutationVariables) =>
    createEventPricing({variables: {eventId, data}})
}

export const useUpdateEventPricing = () => {
  const [updateEventPricing] =
    useMutation<
      UpdateEventPricingMutation,
      UpdateEventPricingMutationVariables
    >(UPDATE_EVENT_PRICING)
  return (variables: UpdateEventPricingMutationVariables) =>
    updateEventPricing({variables})
}

export const useDeleteEventPricing = (eventId: number) => {
  const [deleteEventPricing] = useMutation<
    DeleteEventPricingMutation,
    DeleteEventPricingMutationVariables
  >(DELETE_EVENT_PRICING, {
    refetchQueries: () => {
      return [{query: GET_EVENT_PRICING_DATA, variables: {id: eventId}}]
    }
  })
  return (variables: DeleteEventPricingMutationVariables) =>
    deleteEventPricing({variables})
}
