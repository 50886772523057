import {Box, Paper, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CartPropertiesFragment} from '../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {CartSummary} from '../../../common/cartSummary/CartSummary'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {TotalPrice} from '../components/TotalPrice'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../Layout'
import {Header} from './Header'

interface IBaseViewProps {
  cart: CartPropertiesFragment | null
}

export const BaseView: React.FC<IBaseViewProps> = ({cart}: IBaseViewProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  const clientLocaleCode = useClientLocaleCode()
  return !cart ? (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography variant="h1">{t('Welcome')}</Typography>
    </Box>
  ) : (
    <PageWithHeaderTemplate header={<Header title={t('Cart')} />}>
      <FullScreenCenteredLayout>
        <CenteredLayoutListWrapper height="100%">
          <Paper
            variant="outlined"
            sx={{
              display: 'grid',
              gridTemplateRows: '1fr auto',
              width: '100%',
              height: '100%',
              background: (theme) => theme.palette.common.white,
              overflow: 'hidden'
            }}
          >
            <CartSummary
              cart={cart}
              translatePrice={translatePrice}
              clientLocaleCode={clientLocaleCode}
            />
            <TotalPrice total={cart.price} />
          </Paper>
        </CenteredLayoutListWrapper>
      </FullScreenCenteredLayout>
    </PageWithHeaderTemplate>
  )
}
