import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {useCallback, useContext, useEffect, useMemo} from 'react'
import {useFormContext} from 'react-hook-form'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../hooks/effectiveClientCurrencySignInputProps'
import {usePiecesInputProps} from '../../../../../hooks/piecesInputProps'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {convertValueToFloat} from '../../../../../utils/conversions'

import {IntentCountController} from '../../../../common/IntentCountController'
import {
  IIntentFrameDialogForm,
  IntentFrameDialog,
  IntentFrameDialogField
} from '../../../../common/IntentFrameDialog'
import {
  getPaymentIntentFieldName,
  IPaymentIntent,
  IPaymentIntentsForm,
  PaymentIntentField
} from '../types'
import {
  IntentsStructureDispatchContext,
  IntentStructureActionType
} from './intentsStructure'
import {IIntentFrame} from './types'

const useStyles = makeStyles<Theme>(() => ({
  hiddenInput: {
    display: 'none'
  }
}))

interface IIntentCountFormControllerProps {
  intentFrame: IIntentFrame
  className?: string
}

const calculateAmountField = (
  denomination: string | number,
  count: string | number
) => {
  try {
    return new Decimal(denomination).mul(count).toString()
  } catch (e) {
    return ''
  }
}

export const IntentCountFormController: React.FC<IIntentCountFormControllerProps> =
  ({intentFrame, className}: IIntentCountFormControllerProps) => {
    const classes = useStyles()
    const intentStructureDispatch = useContext(IntentsStructureDispatchContext)
    const defaultValue: IPaymentIntent = useMemo(
      () => ({
        [PaymentIntentField.Amount]: calculateAmountField(
          intentFrame.initialDenomination,
          intentFrame.initialCount
        ),
        [PaymentIntentField.Count]: String(intentFrame.initialCount),
        [PaymentIntentField.Denomination]: String(
          intentFrame.initialDenomination
        ),
        [PaymentIntentField.PaymentMethodId]: String(
          intentFrame.paymentMethodId
        )
      }),
      [
        intentFrame.initialDenomination,
        intentFrame.initialCount,
        intentFrame.paymentMethodId
      ]
    )
    const {register, setValue, watch, triggerValidation} =
      useFormContext<IPaymentIntentsForm>()
    const field = watch(intentFrame.key) || defaultValue
    const count = field[PaymentIntentField.Count]
      ? parseInt(field[PaymentIntentField.Count]!, 10)
      : 0
    const denomination = parseFloat(field[PaymentIntentField.Denomination]!)
    const amount = parseFloat(
      field[PaymentIntentField.Amount] ? field[PaymentIntentField.Amount]! : '0'
    )

    const handleIncrementClick = useCallback(
      (e) => {
        e.stopPropagation()
        const incrementedCount = count + 1
        setValue(intentFrame.key, {
          ...field,
          [PaymentIntentField.Count]: String(incrementedCount),
          [PaymentIntentField.Amount]: calculateAmountField(
            denomination,
            incrementedCount
          )
        })
      },
      [count, denomination, field, intentFrame.key, setValue]
    )

    const removeIntentFrame = useCallback(() => {
      intentStructureDispatch({
        type: IntentStructureActionType.RemoveIntentFrame,
        payload: intentFrame.key
      })
      setValue(intentFrame.key, {})
    }, [intentFrame.key, intentStructureDispatch, setValue])

    useEffect(() => {
      // triggerValidation to force recalculation of cart balance on adding/removing intent frame
      triggerValidation()
      return () => {
        triggerValidation()
      }
    }, [triggerValidation])

    const handleDecrementClick = useCallback(
      (e) => {
        e.stopPropagation()
        if (count === 1 && !intentFrame.hasFixedDenomination) {
          removeIntentFrame()
        } else if (count > 0) {
          const decrementedCount = count - 1
          setValue(intentFrame.key, {
            ...field,
            [PaymentIntentField.Count]: String(decrementedCount),
            [PaymentIntentField.Amount]: calculateAmountField(
              denomination,
              decrementedCount
            )
          })
        }
      },
      [
        count,
        denomination,
        field,
        intentFrame.hasFixedDenomination,
        intentFrame.key,
        removeIntentFrame,
        setValue
      ]
    )
    const {
      state: isEditDialogOpened,
      setFalse: closeEditDialog,
      setTrue: openEditDialog
    } = useBooleanState(false)

    const resetIntentFrame = useCallback(() => {
      setValue(intentFrame.key, {
        ...field,
        [PaymentIntentField.Count]: String(0),
        [PaymentIntentField.Amount]: String(0)
      })
      closeEditDialog()
    }, [closeEditDialog, field, intentFrame.key, setValue])

    const handleEditFormSubmit = useCallback(
      (form: IIntentFrameDialogForm) => {
        setValue(intentFrame.key, {
          [PaymentIntentField.Count]: form[IntentFrameDialogField.Quantity],
          [PaymentIntentField.Denomination]: String(
            convertValueToFloat(form[IntentFrameDialogField.Denomination]) || 0
          ),
          [PaymentIntentField.PaymentMethodId]: String(
            intentFrame.paymentMethodId
          ),
          [PaymentIntentField.Amount]: calculateAmountField(
            convertValueToFloat(form[IntentFrameDialogField.Denomination]) || 0,
            form[IntentFrameDialogField.Quantity]
          )
        })
        closeEditDialog()
      },
      [closeEditDialog, intentFrame.key, intentFrame.paymentMethodId, setValue]
    )
    const translatePrice = useTranslateEffectiveClientPrice()
    const effectiveClientCurrencySignInputProps =
      useEffectiveClientCurrencySignInputProps()
    const piecesInputProps = usePiecesInputProps()
    return (
      <div className={className}>
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(
            intentFrame.key,
            PaymentIntentField.Amount
          )}
          defaultValue={defaultValue[PaymentIntentField.Amount] || ''}
        />
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(
            intentFrame.key,
            PaymentIntentField.Count
          )}
          defaultValue={defaultValue[PaymentIntentField.Count] || ''}
        />
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(
            intentFrame.key,
            PaymentIntentField.PaymentMethodId
          )}
          defaultValue={defaultValue[PaymentIntentField.PaymentMethodId]}
        />
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(
            intentFrame.key,
            PaymentIntentField.Denomination
          )}
          defaultValue={defaultValue[PaymentIntentField.Denomination] || ''}
        />
        <IntentCountController
          name={intentFrame.name}
          count={count}
          onIncrementButtonClick={handleIncrementClick}
          onDecrementButtonClick={handleDecrementClick}
          onBodyClick={openEditDialog}
          denominationLabel={denomination ? translatePrice(denomination) : ''}
          amountLabel={amount ? translatePrice(amount) : ''}
        />
        <IntentFrameDialog
          isOpen={isEditDialogOpened}
          onClose={closeEditDialog}
          intentFrameDialogFormId={`intent-frame-${intentFrame.key}-dialog-form`}
          defaultValues={{
            [IntentFrameDialogField.Denomination]: String(denomination),
            [IntentFrameDialogField.Quantity]: count ? String(count) : ''
          }}
          isDenominationDisabled={intentFrame.hasFixedDenomination}
          onDeleteButtonClick={
            intentFrame.hasFixedDenomination
              ? resetIntentFrame
              : removeIntentFrame
          }
          onSubmit={handleEditFormSubmit}
          title={intentFrame.name}
          translatePrice={translatePrice}
          DenominationInputProps={effectiveClientCurrencySignInputProps}
          QuantityInputProps={piecesInputProps}
          fieldWithAutoFocus={IntentFrameDialogField.Quantity}
        />
      </div>
    )
  }
