import {TemplateFileType} from '../__generated__/schema'

const downloadCompiledTicketPrintingTemplate = ({
  templateId,
  cartId,
  itemIds
}: {
  templateId: number
  cartId: number
  itemIds?: number[]
}) => {
  let url = `/compiledTemplate/${templateId}?cartId=${cartId}&filename=tickets_ID${cartId}_${Date.now()}`
  if (itemIds && itemIds.length) {
    url = `${url}&itemIds=${itemIds.join(',')}`
  }
  window.open(url, '_blank')
}

const previewCompiledTicketPrintingTemplateInPdf = ({
  templateId,
  cartId,
  itemIds
}: {
  templateId: number
  cartId: number
  itemIds?: number[]
}) => {
  let url = `/compiledTemplateInPdf/${templateId}?cartId=${cartId}`
  if (itemIds && itemIds.length) {
    url = `${url}&itemIds=${itemIds.join(',')}`
  }
  window.open(url, '_blank')
}

export const getPublicPdfTicketPrintingTemplateUrl = ({
  templateId,
  itemIds,
  uuid,
  hash
}: {
  templateId: number
  itemIds?: number[]
  uuid: string
  hash: string
}) => {
  let url = `/compiledSaleTemplateInPdf/public/${uuid}/${hash}/${templateId}`
  if (itemIds && itemIds.length) {
    url = `${url}?itemIds=${itemIds.join(',')}`
  }
  return url
}

export const performCompiledTicketPrintingTemplateAction = ({
  templateFileType,
  templateId,
  cartId,
  itemIds
}: {
  templateFileType: TemplateFileType
  templateId: number
  cartId: number
  itemIds?: number[]
}) => {
  if (templateFileType === TemplateFileType.Zpl) {
    downloadCompiledTicketPrintingTemplate({templateId, cartId, itemIds})
  }
  if (templateFileType === TemplateFileType.Pdf) {
    previewCompiledTicketPrintingTemplateInPdf({templateId, cartId, itemIds})
  }
}

const previewCompiledPaymentReportTemplateInPdf = ({
  templateId,
  paymentReportId
}: {
  templateId: number
  paymentReportId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?paymentReportId=${paymentReportId}`,
    '_blank'
  )

const downloadCompiledPaymentReportTemplate = ({
  templateId,
  paymentReportId
}: {
  templateId: number
  paymentReportId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?paymentReportId=${paymentReportId}&filename=paymentReport_ID${paymentReportId}_${Date.now()}`,
    '_blank'
  )

export const performCompiledPaymentReportAction = ({
  templateFileType,
  templateId,
  paymentReportId
}: {
  templateFileType: TemplateFileType
  templateId: number
  paymentReportId: number
}) => {
  if (templateFileType === TemplateFileType.Zpl) {
    downloadCompiledPaymentReportTemplate({templateId, paymentReportId})
  }
  if (templateFileType === TemplateFileType.Pdf) {
    previewCompiledPaymentReportTemplateInPdf({templateId, paymentReportId})
  }
}

const previewCompiledClaimTemplateInPdf = ({
  templateId,
  claimId
}: {
  templateId: number
  claimId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?claimId=${claimId}`,
    '_blank'
  )

const downloadCompiledClaimTemplate = ({
  templateId,
  claimId
}: {
  templateId: number
  claimId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?claimId=${claimId}&filename=claim_ID${claimId}_${Date.now()}`,
    '_blank'
  )

export const performCompiledClaimAction = ({
  templateFileType,
  templateId,
  claimId
}: {
  templateFileType: TemplateFileType
  templateId: number
  claimId: number
}) => {
  if (templateFileType === TemplateFileType.Zpl) {
    downloadCompiledClaimTemplate({templateId, claimId})
  }
  if (templateFileType === TemplateFileType.Pdf) {
    previewCompiledClaimTemplateInPdf({templateId, claimId})
  }
}

const previewCompiledWarehouseDocumentTemplateInPdf = ({
  templateId,
  warehouseDocumentId
}: {
  templateId: number
  warehouseDocumentId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?warehouseDocumentId=${warehouseDocumentId}`,
    '_blank'
  )

const downloadCompiledWarehouseDocumentTemplate = ({
  templateId,
  warehouseDocumentId
}: {
  templateId: number
  warehouseDocumentId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?warehouseDocumentId=${warehouseDocumentId}&filename=warehouse_document_ID${warehouseDocumentId}_${Date.now()}`,
    '_blank'
  )

export const performCompiledWarehouseDocumentAction = ({
  templateFileType,
  templateId,
  warehouseDocumentId
}: {
  templateFileType: TemplateFileType
  templateId: number
  warehouseDocumentId: number
}) => {
  if (templateFileType === TemplateFileType.Zpl) {
    downloadCompiledWarehouseDocumentTemplate({templateId, warehouseDocumentId})
  }
  if (templateFileType === TemplateFileType.Pdf) {
    previewCompiledWarehouseDocumentTemplateInPdf({
      templateId,
      warehouseDocumentId
    })
  }
}

const previewCompiledInventoryCheckTemplateInPdf = ({
  templateId,
  inventoryCheckId
}: {
  templateId: number
  inventoryCheckId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?inventoryCheckId=${inventoryCheckId}`,
    '_blank'
  )

const downloadCompiledInventoryCheckTemplate = ({
  templateId,
  inventoryCheckId
}: {
  templateId: number
  inventoryCheckId: number
}) =>
  window.open(
    `/compiledTemplateInPdf/${templateId}?inventoryCheckId=${inventoryCheckId}&filename=inventory_check_ID${inventoryCheckId}_${Date.now()}`,
    '_blank'
  )

export const performCompiledInventoryCheckAction = ({
  templateFileType,
  templateId,
  inventoryCheckId
}: {
  templateFileType: TemplateFileType
  templateId: number
  inventoryCheckId: number
}) => {
  if (templateFileType === TemplateFileType.Zpl) {
    downloadCompiledInventoryCheckTemplate({templateId, inventoryCheckId})
  }
  if (templateFileType === TemplateFileType.Pdf) {
    previewCompiledInventoryCheckTemplateInPdf({
      templateId,
      inventoryCheckId
    })
  }
}
