import {Box, Paper, Tab, Tabs, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  GetCustomerQuery,
  ReservationState
} from '../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {EventRow} from './EventRow'

enum ReservationTab {
  Ongoing = 'ongoing',
  Failed = 'failed'
}

const useStyles = makeStyles<Theme>((theme) => ({
  typography: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  tabs: {
    borderBottom: `solid ${theme.palette.divider} 1px`
  },
  paper: {
    padding: theme.spacing(0, 2)
  },
  notFound: {
    padding: theme.spacing(2.5, 0)
  },
  leftInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}))

interface IReservationsSectionProps {
  id: string
  customerId: number
  reservations: GetCustomerQuery['customer']['reservations']
}

export const ReservationsSection: React.FC<IReservationsSectionProps> = ({
  id,
  customerId,
  reservations
}: IReservationsSectionProps) => {
  const {t} = useTranslation()
  const [tab, setTab] = useState<ReservationTab>(ReservationTab.Ongoing)
  const {formatDateTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const history = useHistory()
  const filteredReservations = reservations.filter(({state}) =>
    tab === ReservationTab.Ongoing
      ? [ReservationState.Active, ReservationState.InCart].includes(state)
      : [ReservationState.Expired, ReservationState.Canceled].includes(state)
  )
  const classes = useStyles()
  const handleTabChange = useCallback(
    (e, newTab: ReservationTab) => setTab(newTab),
    []
  )
  const handleLinkButtonClick = useCallback(
    (reservationId: number) => () =>
      history.push(
        routeTo.admin.customers.reservationInfo(customerId, reservationId)
      ),
    [customerId, history]
  )
  return (
    <Box id={id}>
      <Typography variant="subtitle1" className={classes.typography}>
        {t('Reservations')}
      </Typography>
      <Paper variant="outlined" className={classes.paper}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{root: classes.tabs}}
        >
          <Tab label={t('Ongoing')} value={ReservationTab.Ongoing} />
          <Tab label={t('Failed')} value={ReservationTab.Failed} />
        </Tabs>
        {filteredReservations.map((reservation) => (
          <EventRow
            key={reservation.id}
            leftInfo={
              <div className={classes.leftInfo}>
                <Typography variant="body2">{reservation.id}</Typography>
                {'•'}
                <Typography variant="body2">
                  {tab === ReservationTab.Ongoing
                    ? t('Expiration {{date}}', {
                        date: formatDateTime(new Date(reservation.expireAt))
                      })
                    : reservation.event
                    ? [
                        formatDateTime(new Date(reservation.event!.startsAt)),
                        getUserLocaleTranslation(reservation.event!.names)
                      ].join(' • ')
                    : reservation.tourTimeSlot &&
                      [
                        formatDateTime(
                          new Date(reservation.tourTimeSlot.startsAt)
                        ),
                        reservation.tourTimeSlot.tour.name
                      ].join(' • ')}
                </Typography>
              </div>
            }
            ticketsCount={
              tab === ReservationTab.Ongoing
                ? reservation.itemsCount
                : undefined
            }
            price={
              tab === ReservationTab.Ongoing ? reservation.price : undefined
            }
            onLinkButtonClick={
              tab === ReservationTab.Ongoing
                ? handleLinkButtonClick(reservation.id)
                : undefined
            }
          />
        ))}
        {filteredReservations.length === 0 && (
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.notFound}
          >
            {tab === ReservationTab.Ongoing
              ? t('No ongoing reservations have been found')
              : t('No failed reservations have been found')}
          </Typography>
        )}
      </Paper>
    </Box>
  )
}
