import {Box, Checkbox, CheckboxProps, SxProps, Typography} from '@mui/material'
import React from 'react'

interface IItemProps {
  label: string
  price: string
  priceBeforeDiscount?: string
  checkboxProps?: CheckboxProps
  children?: React.ReactNode
  disabled?: boolean
  sx?: SxProps
  onClick?: React.MouseEventHandler
}

export const Item: React.FC<IItemProps> = ({
  label,
  price,
  priceBeforeDiscount,
  checkboxProps,
  children,
  disabled,
  sx,
  onClick
}: IItemProps) => (
  <Box
    sx={{
      display: 'grid',
      minHeight: 56,
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
      opacity: (theme) =>
        disabled ? theme.palette.action.disabledOpacity : undefined,
      cursor: disabled ? 'normal' : 'pointer',
      ...sx
    }}
    onClick={onClick}
  >
    <Checkbox
      color="primary"
      edge="start"
      disabled={disabled}
      onClick={onClick}
      {...checkboxProps}
    />
    <Box
      sx={{
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: children ? 0.5 : 0
      }}
    >
      <Typography variant="subtitle2" color="inherit">
        {label}
      </Typography>
      <div>{children}</div>
    </Box>
    <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column'}}>
      <Typography variant="body2" color="inherit" align="right">
        {price}
      </Typography>
      {priceBeforeDiscount && (
        <Typography
          variant="caption"
          color="textSecondary"
          align="right"
          sx={{textDecoration: 'line-through'}}
        >
          {priceBeforeDiscount}
        </Typography>
      )}
    </Box>
  </Box>
)
