import {useState} from 'react'

export const useForceRerender = () => {
  const [value, setValue] = useState(0)

  return {
    rerenderProp: value,
    forceRerender: () => setValue((value) => value + 1)
  }
}
