import {isEmpty, isUndefined, keyBy, omitBy, pick} from 'lodash'
import React, {useEffect, useReducer} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ShowGenreCode,
  ShowTypeCode
} from '../../../../../../__generated__/schema'
import {GeneralFilter} from '../../../../../common/generalFilter'
import {ChildrenOnVisibleSection} from '../../../../../common/generalFilter/ChildrenOnVisibleSection'
import {IdsListSecondStep} from '../../../../../common/generalFilter/IdsListSecondStep'
import {IdsListSection} from '../../../../../common/generalFilter/IdsListSection'
import {
  GeneralFilterActions,
  generalFilterReducer,
  GeneralFilterState,
  getDefaultGeneralFilterState
} from '../../../../../common/generalFilter/reducer'
import {IFilterKeyOption} from '../../../../../common/generalFilter/types'
import {isObjectEmpty} from '../../utils'
import {useCostCenterListItems} from './costCenterListItems'
import {useDivisionListItems} from './divisionListItems'
import {useEventCategoryListItems} from './eventCategoryListItems'
import {useMarketingLabelListItems} from './marketingLabelListItems'
import {useShowGenreCodes} from './showGenreCodes'
import {useShowListItems} from './showListItems'
import {useShowTypeCodes} from './showTypeCodes'
import {
  IFeaturedShowPhotosBlockContentFilter,
  IFeaturedShowPhotosBlockProps
} from './types'
import {useVenueListItems} from './venueListItems'

interface IFeaturedShowPhotosBlockContentProps {
  blockProps: IFeaturedShowPhotosBlockProps
  onBlockPropsChange: (props: IFeaturedShowPhotosBlockProps) => void
}

const useFilterKeyOptions =
  (): IFilterKeyOption<IFeaturedShowPhotosBlockContentFilter>[] => {
    const {t} = useTranslation()
    return [
      {key: 'showIds', name: t<string>('Shows')},
      {key: 'divisionIds', name: t<string>('Divisions')},
      {key: 'venueIds', name: t<string>('Venues')},
      {key: 'eventCategoryIds', name: t<string>('Event categories')},
      {key: 'marketingLabelIds', name: t<string>('Marketing labels')},
      {key: 'costCenterIds', name: t<string>('Cost centers')},
      {key: 'showTypeCodes', name: t<string>('Show types')},
      {key: 'showGenreCodes', name: t<string>('Show genres')}
    ]
  }

const mapBlockPropsToFilter = (
  blockProps: IFeaturedShowPhotosBlockProps
): IFeaturedShowPhotosBlockContentFilter =>
  omitBy(
    {
      showIds: blockProps.filter?.baseListFilter?.ids,
      divisionIds: blockProps.filter?.eventsFilter?.divisionIds,
      venueIds: blockProps.filter?.eventsFilter?.venueIds,
      eventCategoryIds: blockProps.filter?.eventsFilter?.eventCategoryIds,
      marketingLabelIds: blockProps.filter?.eventsFilter?.marketingLabelIds,
      costCenterIds: blockProps.filter?.eventsFilter?.costCenterIds,
      showTypeCodes: blockProps.filter?.typeCodes,
      showGenreCodes: blockProps.filter?.genreCodes
    },
    isUndefined
  )

const mapFilterToBlockProps = (
  filter: IFeaturedShowPhotosBlockContentFilter
): IFeaturedShowPhotosBlockProps => ({
  filter: {
    typeCodes: filter?.showTypeCodes ?? undefined,
    genreCodes: filter?.showGenreCodes ?? undefined,
    baseListFilter: isEmpty(filter?.showIds)
      ? undefined
      : {
          ids: filter.showIds
        },
    eventsFilter: isObjectEmpty(
      pick(filter, [
        'venueIds',
        'divisionIds',
        'eventCategoryIds',
        'marketingLabelIds',
        'costCenterIds'
      ])
    )
      ? undefined
      : {
          venueIds: filter?.venueIds ?? undefined,
          divisionIds: filter?.divisionIds ?? undefined,
          eventCategoryIds: filter?.eventCategoryIds ?? undefined,
          marketingLabelIds: filter?.marketingLabelIds ?? undefined,
          costCenterIds: filter?.costCenterIds ?? undefined
        }
  }
})

export const FeaturedShowPhotosBlockContent: React.FC<IFeaturedShowPhotosBlockContentProps> =
  ({blockProps, onBlockPropsChange}: IFeaturedShowPhotosBlockContentProps) => {
    const {t} = useTranslation()
    const filterKeyOptions = useFilterKeyOptions()
    const filterKeyOptionsByKey = keyBy(filterKeyOptions, 'key')
    const [generalFilter, dispatch] = useReducer<
      React.Reducer<
        GeneralFilterState<IFeaturedShowPhotosBlockContentFilter>,
        GeneralFilterActions<IFeaturedShowPhotosBlockContentFilter>
      >
    >(
      generalFilterReducer,
      getDefaultGeneralFilterState(mapBlockPropsToFilter(blockProps), [
        'showIds',
        'venueIds',
        'divisionIds',
        'eventCategoryIds',
        'marketingLabelIds',
        'costCenterIds',
        'showTypeCodes',
        'showGenreCodes'
      ])
    )
    const {getShowListItems, getMoreShowListItems, getShowListItemsByIds} =
      useShowListItems()

    const {getDivisionListItems, getDivisionListItemsByIds} =
      useDivisionListItems()

    const {getVenueListItemsByIds, getVenueListItems} = useVenueListItems()
    const {getEventCategoryListItemsByIds, getEventCategoryListItems} =
      useEventCategoryListItems()
    const {getMarketingLabelListItemsByIds, getMarketingLabelListItems} =
      useMarketingLabelListItems()
    const {getCostCenterListItemsByIds, getCostCenterListItems} =
      useCostCenterListItems()
    const {getShowTypeCodeItems, getShowTypeCodeItemsByIds} = useShowTypeCodes()
    const {getShowGenreCodeItemsByIds, getShowGenreCodeItems} =
      useShowGenreCodes()

    useEffect(() => {
      const {filter} = mapFilterToBlockProps(generalFilter.filter)
      onBlockPropsChange({
        ...omitBy(blockProps, 'filter'),
        filter: isObjectEmpty(filter) ? undefined : filter
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalFilter.filter, onBlockPropsChange])

    return (
      <>
        <GeneralFilter<IFeaturedShowPhotosBlockContentFilter>
          dispatch={dispatch}
          generalFilter={generalFilter}
          filterKeyOptions={filterKeyOptions}
          secondStepChildren={
            <>
              <IdsListSecondStep<IFeaturedShowPhotosBlockContentFilter>
                filterKey="showIds"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getShowListItems}
                getMoreOptions={getMoreShowListItems}
              />
              <IdsListSecondStep<IFeaturedShowPhotosBlockContentFilter>
                filterKey="divisionIds"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getDivisionListItems}
                isFilterTextInputHidden
              />
              <IdsListSecondStep<IFeaturedShowPhotosBlockContentFilter>
                filterKey="venueIds"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getVenueListItems}
              />
              <IdsListSecondStep<IFeaturedShowPhotosBlockContentFilter>
                filterKey="eventCategoryIds"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getEventCategoryListItems}
              />
              <IdsListSecondStep<IFeaturedShowPhotosBlockContentFilter>
                filterKey="marketingLabelIds"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getMarketingLabelListItems}
              />
              <IdsListSecondStep<IFeaturedShowPhotosBlockContentFilter>
                filterKey="costCenterIds"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getCostCenterListItems}
              />
              <IdsListSecondStep<
                IFeaturedShowPhotosBlockContentFilter,
                ShowTypeCode
              >
                filterKey="showTypeCodes"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getShowTypeCodeItems}
              />
              <IdsListSecondStep<
                IFeaturedShowPhotosBlockContentFilter,
                ShowGenreCode
              >
                filterKey="showGenreCodes"
                generalFilter={generalFilter}
                dispatch={dispatch}
                getOptions={getShowGenreCodeItems}
              />
            </>
          }
        >
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="showIds"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter>
              label={t('Shows')}
              filterKey="showIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getShowListItems}
              getMoreOptions={getMoreShowListItems}
              getOptionsByIds={getShowListItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="divisionIds"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter>
              label={filterKeyOptionsByKey.divisionIds.name}
              filterKey="divisionIds"
              isFilterTextInputHidden
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getDivisionListItems}
              getOptionsByIds={getDivisionListItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="venueIds"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter>
              label={filterKeyOptionsByKey.venueIds.name}
              filterKey="venueIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getVenueListItems}
              getOptionsByIds={getVenueListItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="eventCategoryIds"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter>
              label={filterKeyOptionsByKey.eventCategoryIds.name}
              filterKey="eventCategoryIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getEventCategoryListItems}
              getOptionsByIds={getEventCategoryListItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="marketingLabelIds"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter>
              label={filterKeyOptionsByKey.marketingLabelIds.name}
              filterKey="marketingLabelIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getMarketingLabelListItems}
              getOptionsByIds={getMarketingLabelListItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="costCenterIds"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter>
              label={filterKeyOptionsByKey.costCenterIds.name}
              filterKey="costCenterIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getCostCenterListItems}
              getOptionsByIds={getCostCenterListItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="showTypeCodes"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<IFeaturedShowPhotosBlockContentFilter, ShowTypeCode>
              label={filterKeyOptionsByKey.showTypeCodes.name}
              filterKey="showTypeCodes"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getShowTypeCodeItems}
              getOptionsByIds={getShowTypeCodeItemsByIds}
            />
          </ChildrenOnVisibleSection>
          <ChildrenOnVisibleSection<IFeaturedShowPhotosBlockContentFilter>
            filterKey="showGenreCodes"
            visibleSections={generalFilter.visibleSections}
          >
            <IdsListSection<
              IFeaturedShowPhotosBlockContentFilter,
              ShowGenreCode
            >
              label={filterKeyOptionsByKey.showGenreCodes.name}
              filterKey="showGenreCodes"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getShowGenreCodeItems}
              getOptionsByIds={getShowGenreCodeItemsByIds}
            />
          </ChildrenOnVisibleSection>
        </GeneralFilter>
      </>
    )
  }
