import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {Button, Chip, Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import dayjs, {Dayjs, QUnitType} from 'dayjs'
import {pick} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ExistingCountryCode,
  LeadField,
  Maybe,
  ReservationDetailFragment,
  SellingChannel
} from '../../../../../__generated__/schema'
import {
  getLeadBlockIsAvailable,
  LeadSection,
  useLeadOptionsObject,
  useLeadSideNavigationListItems,
  useTranslateLeadSection
} from '../../../../../hooks/leadSection'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useHandleInvalidDateTimes} from '../../../../../hooks/pickerErrors'
import {Theme} from '../../../../../theme'
import {useUserInfo} from '../../../../../utils/auth'
import {useIsValidEmail} from '../../../../../utils/formsValidations'
import {DrawerEditSection, DrawerTemplateHeader} from '../../../../common'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {FormDatetimeInput} from '../../../../form/pickers'
import {useUpdateReservation} from '../../cashDesk/graphql'
import {LeadFormCountryInput} from '../../cashDesk/LeadFormCountryInput'
import {LeadFormTextInput} from '../../cashDesk/LeadFormTextInput'
import {
  ReservationErrorDialog,
  ReservationErrorTriggers
} from '../../cashDesk/ReservationErrorDialog'
import {transformLeadFormToLeadDataInput} from '../../cashDesk/utils'

const RESERVATION_EDIT_FORM_ID = 'RESERVATION_EDIT_FORM_ID'

interface IReservationDetailContentProps {
  onClose: () => void
  reservation: ReservationDetailFragment & {
    activity:
      | NonNullable<ReservationDetailFragment['event']>
      | NonNullable<ReservationDetailFragment['tourTimeSlot']>
  }
}

enum ReservationDetailFormField {
  EXPIRE_AT = 'expireAt'
}

type IReservationDetailForm = {
  [ReservationDetailFormField.EXPIRE_AT]: Dayjs
  [LeadField.Name]: Maybe<string>
  [LeadField.Phone]: Maybe<string>
  [LeadField.Email]: Maybe<string>
  [LeadField.Note]: Maybe<string>
  [LeadField.InternalNote]: Maybe<string>
  [LeadField.CompanyName]: Maybe<string>
  [LeadField.CompanyIdNumber]: Maybe<string>
  [LeadField.VatId]: Maybe<string>
  [LeadField.TaxId]: Maybe<string>
  [LeadField.BillingAddressStreet]: Maybe<string>
  [LeadField.BillingAddressTown]: Maybe<string>
  [LeadField.BillingPostalCode]: Maybe<string>
  [LeadField.BillingAddressCountry]: Maybe<ExistingCountryCode>
  [LeadField.DeliveryAddressee]: Maybe<string>
  [LeadField.DeliveryAddressStreet]: Maybe<string>
  [LeadField.DeliveryAddressTown]: Maybe<string>
  [LeadField.DeliveryPostalCode]: Maybe<string>
  [LeadField.DeliveryAddressCountry]: Maybe<ExistingCountryCode>
  [LeadField.IsPrivacyPolicyConsentGranted]: Maybe<boolean>
}

const useStyles = makeStyles<Theme>((theme) => ({
  expiresAtChipGrid: {
    display: 'inline-grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto',
    paddingTop: theme.spacing(2)
  },
  leadOptionInput: {
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  section: {
    paddingTop: theme.spacing(2)
  },
  reservationDetailsLabel: {
    paddingTop: theme.spacing(3)
  }
}))

export const ReservationDetailEditContent: React.FC<IReservationDetailContentProps> =
  ({reservation, onClose}: IReservationDetailContentProps) => {
    const {t} = useTranslation()
    const {effectiveClient} = useUserInfo()
    const {retailReservationLeadOptions, eCommerceReservationLeadOptions} =
      effectiveClient!
    const leadOptionsObject = useLeadOptionsObject(
      reservation.sellingChannel === SellingChannel.Retail
        ? retailReservationLeadOptions
        : eCommerceReservationLeadOptions
    )
    const items = useLeadSideNavigationListItems(leadOptionsObject)
    const sideMenuItems = {
      expiration: {
        id: 'expiration',
        label: t('Expiration')
      },
      ...items
    }

    const translateLeadSection = useTranslateLeadSection()

    const {
      register,
      setValue,
      errors,
      clearError,
      getValues,
      handleSubmit,
      formState,
      triggerValidation,
      unregister,
      setError,
      control,
      watch
    } = useForm<IReservationDetailForm>({
      defaultValues: {
        [ReservationDetailFormField.EXPIRE_AT]: dayjs(reservation.expireAt),
        [LeadField.Name]: reservation.lead?.data.name,
        [LeadField.Phone]: reservation.lead?.data.phone,
        [LeadField.Email]: reservation.lead?.data.email,
        [LeadField.Note]: reservation.lead?.data.note,
        [LeadField.InternalNote]: reservation.lead?.data.internalNote,
        [LeadField.CompanyName]: reservation.lead?.data.companyName,
        [LeadField.CompanyIdNumber]: reservation.lead?.data.companyIdNumber,
        [LeadField.VatId]: reservation.lead?.data.VATId,
        [LeadField.TaxId]: reservation.lead?.data.TAXId,
        [LeadField.BillingAddressStreet]:
          reservation.lead?.data.billingAddressStreet,
        [LeadField.BillingAddressTown]:
          reservation.lead?.data.billingAddressTown,
        [LeadField.BillingPostalCode]: reservation.lead?.data.billingPostalCode,
        [LeadField.BillingAddressCountry]:
          reservation.lead?.data.billingAddressCountry,
        [LeadField.DeliveryAddressee]: reservation.lead?.data.deliveryAddressee,
        [LeadField.DeliveryAddressStreet]:
          reservation.lead?.data.deliveryAddressStreet,
        [LeadField.DeliveryAddressTown]:
          reservation.lead?.data.deliveryAddressTown,
        [LeadField.DeliveryPostalCode]:
          reservation.lead?.data.deliveryPostalCode,
        [LeadField.DeliveryAddressCountry]:
          reservation.lead?.data.deliveryAddressCountry
      }
    })

    const getChipClickHandler = useCallback(
      ({unit = 'day', amount = 1}: {unit?: QUnitType; amount?: number}) =>
        () => {
          const value = getValues(ReservationDetailFormField.EXPIRE_AT)
          setValue(
            ReservationDetailFormField.EXPIRE_AT,
            value.add(amount, unit),
            true
          )
        },
      [getValues, setValue]
    )

    const handleEndOfEventChipClick = useCallback(
      () =>
        setValue(
          ReservationDetailFormField.EXPIRE_AT,
          dayjs(reservation.activity.endsAt),
          true
        ),
      [reservation.activity, setValue]
    )

    const isValidEmail = useIsValidEmail()

    const classes = useStyles()

    const updateReservation = useUpdateReservation()

    const {setShowBackdrop} = useMutationAssistanceHooks()

    const [reservationError, setReservationError] =
      useState<{
        error: ApolloError
        trigger: ReservationErrorTriggers
      } | null>(null)

    const onSubmit = useCallback(
      async (form: IReservationDetailForm) => {
        setShowBackdrop(true)
        try {
          await updateReservation({
            expireAt: dayjs(
              form[ReservationDetailFormField.EXPIRE_AT]
            ).toISOString(),
            id: reservation.id,
            leadDataInput: transformLeadFormToLeadDataInput(
              pick(form, Object.keys(leadOptionsObject))
            )
          })
          onClose()
        } catch (e) {
          setReservationError({
            error: e,
            trigger: ReservationErrorTriggers.Update
          })
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        leadOptionsObject,
        onClose,
        reservation.id,
        setShowBackdrop,
        updateReservation
      ]
    )
    const handleInvalidDateTimes =
      useHandleInvalidDateTimes<IReservationDetailForm>(setError)
    return (
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          header: (
            <DrawerTemplateHeader
              title={t('Reservation #{{id}}', {id: reservation.id})}
              onLeftActionClick={onClose}
              LeftActionIcon={ArrowBackIcon}
            />
          ),
          footer: (
            <>
              <Button onClick={onClose} color="primary">
                {t('Back')}
              </Button>
              <Button
                autoFocus
                color="primary"
                variant="contained"
                type="submit"
                form={RESERVATION_EDIT_FORM_ID}
                disabled={formState.dirty && Object.keys(errors).length > 0}
              >
                {t('Save')}
              </Button>
            </>
          )
        }}
        navigationItems={sideMenuItems}
      >
        {reservationError &&
          reservationError.trigger === ReservationErrorTriggers.Update && (
            <ReservationErrorDialog
              error={reservationError.error}
              onConfirm={() => setReservationError(null)}
              trigger={ReservationErrorTriggers.Update}
            />
          )}
        <form
          onSubmit={handleSubmit(handleInvalidDateTimes(onSubmit))}
          id={RESERVATION_EDIT_FORM_ID}
        >
          <Typography
            variant="subtitle1"
            className={classes.reservationDetailsLabel}
          >
            {t('Reservation details')}
          </Typography>
          <DrawerEditSection
            className={classes.section}
            id={sideMenuItems.expiration.id}
            label={sideMenuItems.expiration.label}
          >
            <FormDatetimeInput<IReservationDetailForm>
              errors={errors}
              setValue={setValue}
              clearError={clearError}
              register={register}
              unregister={unregister}
              watch={watch}
              dataTimePickerProps={{
                label: t<string>('Date and time'),
                disablePast: true
              }}
              control={control}
              name={ReservationDetailFormField.EXPIRE_AT}
              setError={setError}
              validationOptions={{
                required: true
              }}
            />
            <div className={classes.expiresAtChipGrid}>
              <Chip
                size="small"
                label={t('- 1 day')}
                component="span"
                onClick={getChipClickHandler({
                  amount: -1
                })}
              />

              <Chip
                size="small"
                label={t('- 10 min')}
                component="span"
                onClick={getChipClickHandler({
                  amount: -10,
                  unit: 'minute'
                })}
              />

              <Divider orientation="vertical" />

              <Chip
                size="small"
                label={t('+ 10 min')}
                component="span"
                onClick={getChipClickHandler({
                  amount: 10,
                  unit: 'minute'
                })}
              />

              <Chip
                size="small"
                label={t('+ 1 hour')}
                component="span"
                onClick={getChipClickHandler({
                  unit: 'hour'
                })}
              />

              <Chip
                size="small"
                label={t('+ 1 day')}
                component="span"
                onClick={getChipClickHandler({})}
              />
              <Chip
                size="small"
                label={t('End of event')}
                component="span"
                onClick={handleEndOfEventChipClick}
              />
            </div>
          </DrawerEditSection>
          {getLeadBlockIsAvailable(leadOptionsObject, LeadSection.LEAD) && (
            <DrawerEditSection
              className={classes.section}
              id={LeadSection.LEAD}
              label={translateLeadSection(LeadSection.LEAD)}
            >
              <LeadFormTextInput<IReservationDetailForm>
                className={classes.leadOptionInput}
                showOptionalLeadInput
                option={leadOptionsObject[LeadField.Name]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                className={classes.leadOptionInput}
                showOptionalLeadInput
                option={leadOptionsObject[LeadField.Phone]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                className={classes.leadOptionInput}
                showOptionalLeadInput
                option={leadOptionsObject[LeadField.Email]}
                validationOptions={{
                  validate: isValidEmail
                }}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
            </DrawerEditSection>
          )}
          {getLeadBlockIsAvailable(leadOptionsObject, LeadSection.COMPANY) && (
            <DrawerEditSection
              className={classes.section}
              id={LeadSection.COMPANY}
              label={translateLeadSection(LeadSection.COMPANY)}
            >
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.CompanyName]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.CompanyIdNumber]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.TaxId]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.VatId]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
            </DrawerEditSection>
          )}
          {getLeadBlockIsAvailable(
            leadOptionsObject,
            LeadSection.BILLING_ADDRESS
          ) && (
            <DrawerEditSection
              className={classes.section}
              id={LeadSection.BILLING_ADDRESS}
              label={translateLeadSection(LeadSection.BILLING_ADDRESS)}
            >
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.BillingAddressStreet]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.BillingPostalCode]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.BillingAddressTown]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormCountryInput
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.BillingAddressCountry]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            </DrawerEditSection>
          )}
          {getLeadBlockIsAvailable(
            leadOptionsObject,
            LeadSection.DELIVERY_ADDRESS
          ) && (
            <DrawerEditSection
              className={classes.section}
              id={LeadSection.DELIVERY_ADDRESS}
              label={translateLeadSection(LeadSection.DELIVERY_ADDRESS)}
            >
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.DeliveryAddressee]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.DeliveryAddressStreet]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.DeliveryPostalCode]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormTextInput<IReservationDetailForm>
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.DeliveryAddressTown]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
              />
              <LeadFormCountryInput
                showOptionalLeadInput
                className={classes.leadOptionInput}
                option={leadOptionsObject[LeadField.DeliveryAddressCountry]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            </DrawerEditSection>
          )}
          {getLeadBlockIsAvailable(leadOptionsObject, LeadSection.NOTES) && (
            <DrawerEditSection
              className={classes.section}
              id={LeadSection.NOTES}
              label={translateLeadSection(LeadSection.NOTES)}
            >
              <LeadFormTextInput<IReservationDetailForm>
                className={classes.leadOptionInput}
                showOptionalLeadInput
                option={leadOptionsObject[LeadField.InternalNote]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
                isMultiline
              />
              <LeadFormTextInput<IReservationDetailForm>
                className={classes.leadOptionInput}
                showOptionalLeadInput
                option={leadOptionsObject[LeadField.Note]}
                register={register}
                setValue={setValue}
                watch={watch}
                errors={errors}
                triggerValidation={triggerValidation}
                isMultiline
              />
            </DrawerEditSection>
          )}
        </form>
      </DrawerTemplateWithSideNavigation>
    )
  }
