import {useLazyQuery} from '@apollo/react-hooks'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {compact, filter, includes} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AvailableDiscountPropertiesFragment,
  EventDiscountsQuery,
  EventDiscountsQueryVariables,
  PermissionCode,
  SellingChannel
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useTranslateSellingChannel} from '../../../../../../hooks/sellingChannel'
import {useBooleanState} from '../../../../../../hooks/state'
import {Theme} from '../../../../../../theme'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  StaticSideNavigationList
} from '../../../../../common'
import {
  GET_EVENT_DISCOUNTS,
  useUpdateAllowedDiscountsSellingChannelsForEvent
} from '../../graphql'

import {useCommonFormStyles, useGetBackToGeneralInfoLink} from '../common'
import {TableBox} from './TableBox'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerTemplate: {
    width: '100%'
  },
  drawerGrid: {
    display: 'grid',
    minHeight: '100%',
    gridTemplateColumns: '250px auto',
    padding: theme.spacing(0, 1, 3, 0)
  },
  tables: {
    paddingTop: theme.spacing(3)
  },
  retailDiscountTable: {
    marginBottom: theme.spacing(3)
  },
  sideMenu: {
    paddingTop: theme.spacing(3),
    position: 'sticky',
    height: 'fit-content',
    top: 0
  }
}))

interface IEventDiscountsContentProps {
  allowedDiscountsSellingChannels: SellingChannel[]
  eventId: number
}

export const EventDiscountsContent: React.FC<IEventDiscountsContentProps> = ({
  allowedDiscountsSellingChannels,
  eventId
}: IEventDiscountsContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const commonClasses = useCommonFormStyles()
  const history = useHistory()
  const backLink = useGetBackToGeneralInfoLink()
  const onClose = useCallback(() => {
    history.replace(backLink)
  }, [backLink, history])
  const translateSellingChannel = useTranslateSellingChannel()
  const updateAllowedDiscountsSellingChannelsForEvent =
    useUpdateAllowedDiscountsSellingChannelsForEvent()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const [availableDiscounts, setAvailableDiscounts] =
    useState<AvailableDiscountPropertiesFragment[] | null>()

  const [getAvailableDiscounts, {loading}] = useLazyQuery<
    EventDiscountsQuery,
    EventDiscountsQueryVariables
  >(GET_EVENT_DISCOUNTS, {
    variables: {eventId},
    fetchPolicy: 'network-only',
    onCompleted: (data) => setAvailableDiscounts(data.event.availableDiscounts),
    onError: (error) =>
      defaultErrorHandler(error, t('Could not load discounts'))
  })

  const {state: areRetailDiscountsEnabled, toggle: toggleRetailDiscounts} =
    useBooleanState(
      includes(allowedDiscountsSellingChannels, SellingChannel.Retail)
    )

  const {
    state: areECommerceDiscountsEnabled,
    toggle: toggleECommerceDiscounts
  } = useBooleanState(
    includes(allowedDiscountsSellingChannels, SellingChannel.ECommerce)
  )

  useEffect(
    () => (loading ? setShowBackdrop(true) : setShowBackdrop(false)),
    [loading, setShowBackdrop]
  )

  useEffect(() => {
    getAvailableDiscounts()
  }, [getAvailableDiscounts])

  const handleRetailDiscountsToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      toggleRetailDiscounts()
      if (e.target.checked) {
        getAvailableDiscounts()
      }
    },
    [getAvailableDiscounts, toggleRetailDiscounts]
  )

  const handleEcommerceDiscountsToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      toggleECommerceDiscounts()
      if (e.target.checked) {
        getAvailableDiscounts()
      }
    },
    [getAvailableDiscounts, toggleECommerceDiscounts]
  )

  const onSaveClick = useCallback(async () => {
    const sellingChannels = compact([
      areRetailDiscountsEnabled && SellingChannel.Retail,
      areECommerceDiscountsEnabled && SellingChannel.ECommerce
    ])
    try {
      setShowBackdrop(true)
      await updateAllowedDiscountsSellingChannelsForEvent(
        eventId,
        sellingChannels
      )
      addInfoNotification(t('Discount settings for event were updated.'))
    } catch (err) {
      defaultErrorHandler(
        err,
        t('Error while updating discount settings for event.')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    areECommerceDiscountsEnabled,
    areRetailDiscountsEnabled,
    defaultErrorHandler,
    eventId,
    setShowBackdrop,
    t,
    updateAllowedDiscountsSellingChannelsForEvent
  ])

  return (
    <DrawerTemplate
      className={classes.drawerTemplate}
      header={
        <DrawerTemplateHeader
          title={t('Discounts')}
          onLeftActionClick={onClose}
          LeftActionIcon={ArrowBackIcon}
        />
      }
      footer={
        <>
          <Button onClick={onClose} color="primary">
            {t('Back')}
          </Button>
          {P([
            PermissionCode.UpdateAllowedDiscountsSellingChannelsForEvent
          ]) && (
            <Button onClick={onSaveClick} color="primary" variant="contained">
              {t('Save')}
            </Button>
          )}
        </>
      }
    >
      <div className={cn(classes.drawerGrid, commonClasses.drawerFormWrapper)}>
        <StaticSideNavigationList
          items={{
            [SellingChannel.Retail]: {
              id: SellingChannel.Retail,
              label: translateSellingChannel(SellingChannel.Retail)
            },
            [SellingChannel.ECommerce]: {
              id: SellingChannel.ECommerce,
              label: translateSellingChannel(SellingChannel.ECommerce)
            }
          }}
          className={classes.sideMenu}
        />
        <div className={classes.tables}>
          <TableBox
            className={classes.retailDiscountTable}
            discounts={filter(availableDiscounts, (ad) =>
              includes(ad.sellingChannels, SellingChannel.Retail)
            )}
            title={translateSellingChannel(SellingChannel.Retail)}
            labelId={SellingChannel.Retail}
            isToggleOn={areRetailDiscountsEnabled}
            onToggleChange={handleRetailDiscountsToggleChange}
          />
          <TableBox
            discounts={filter(availableDiscounts, (ad) =>
              includes(ad.sellingChannels, SellingChannel.ECommerce)
            )}
            title={translateSellingChannel(SellingChannel.ECommerce)}
            labelId={SellingChannel.ECommerce}
            isToggleOn={areECommerceDiscountsEnabled}
            onToggleChange={handleEcommerceDiscountsToggleChange}
          />
        </div>
      </div>
    </DrawerTemplate>
  )
}
