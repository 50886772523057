import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LanguageIcon from '@mui/icons-material/Language'
import {Button} from '@mui/material'
import {isNil} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {LocaleCode} from '../../__generated__/schema'
import {getValuesBasedOnInfrastructure} from '../../hooks/getValuesBasedOnInfrastructure'
import {useTranslateLocaleCode} from '../../hooks/translateLocales'
import {LOCAL_STORAGE_KEYWORD, useLocale} from '../context/locale'
import {Menu, MenuItem, useMenu} from './Menu'

export const LocaleSwitcherButton: React.FC = () => {
  const {localeCode, setLocale} = useLocale()
  const translateLocaleCode = useTranslateLocaleCode()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const {locale} = getValuesBasedOnInfrastructure()
  const handleLocaleClick = useCallback(
    (locale: LocaleCode) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setLocale(locale)
      closeMenu()
    },
    [closeMenu, setLocale]
  )
  useEffect(() => {
    if (isNil(localStorage.getItem(LOCAL_STORAGE_KEYWORD))) {
      const navigatorLocale = window.navigator.language.split('-')[0]
      setLocale(
        Object.values(LocaleCode).includes(navigatorLocale as LocaleCode)
          ? navigatorLocale
          : locale
      )
    }
  }, [locale, setLocale])
  return (
    <>
      <Button
        startIcon={<LanguageIcon />}
        endIcon={<ArrowDropDownIcon />}
        variant="text"
        sx={{color: 'text.secondary'}}
        onClick={openMenu}
      >
        {translateLocaleCode(localeCode)}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={closeMenu}
        anchorEl={anchorEl}
      >
        {Object.values(LocaleCode).map((locale) => (
          <MenuItem
            key={locale}
            label={translateLocaleCode(locale)}
            onClick={handleLocaleClick(locale)}
            isSelected={localeCode === locale}
          />
        ))}
      </Menu>
    </>
  )
}
