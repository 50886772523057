import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PageWithHeaderTemplate} from '../../common/PageWithHeaderTemplate'
import {PrimaryHeader} from './Header'

import {CenteredLayout} from './Layout'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export const PageNotFound: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <PageWithHeaderTemplate header={<PrimaryHeader />}>
      <CenteredLayout>
        <div className={classes.root}>
          <Typography variant="h2">{t('Page not found!')}</Typography>
        </div>
      </CenteredLayout>
    </PageWithHeaderTemplate>
  )
}
