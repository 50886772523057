import {Button, Drawer} from '@mui/material'
import {ApolloError} from 'apollo-client'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDefaultErrorHandler} from '../../../../../../../utils/errors'
import {useVenuesPathnameParams} from '../../../../../../../utils/pathname'
import {CustomDrawerActionsBar} from '../../../../../../common'
import {TicketColor} from '../common'
import {useCreateTicketType} from '../graphql'
import {
  ITicketFormData,
  TICKET_FORM_ID,
  TicketForm,
  ticketTypeFromTicketTypeForm
} from '../TicketForm'
import {SideDrawerContent} from './common'

interface ICreateTicketTypeDrawerProps {
  open: boolean
  onClose: () => any
  unavailableColors: Array<TicketColor>
}

const useHandlers = (onClose: () => any) => {
  const {t} = useTranslation()
  const {auditoriumLayoutId, layoutPricingId} = useVenuesPathnameParams()
  const createTicketType = useCreateTicketType(
    auditoriumLayoutId,
    layoutPricingId
  )
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async (data: ITicketFormData) => {
    await createTicketType(ticketTypeFromTicketTypeForm(data))
    onClose()
  }

  const onError = (err: ApolloError) => {
    defaultErrorHandler(err, t('Error while creating ticket type'))
  }

  return {onSubmit, onError}
}

export const CreateTicketTypeDrawer: React.FC<ICreateTicketTypeDrawerProps> = ({
  open,
  onClose,
  unavailableColors
}: ICreateTicketTypeDrawerProps) => {
  const {t} = useTranslation()
  const {onSubmit, onError} = useHandlers(onClose)

  return (
    <Drawer anchor="right" {...{open, onClose}}>
      <SideDrawerContent>
        <TicketForm
          title={t('New ticket type')}
          {...{onClose, unavailableColors, onSubmit, onError}}
          ActionBar={
            <CustomDrawerActionsBar>
              <Button color="primary" onClick={onClose}>
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form={TICKET_FORM_ID}
              >
                {t('Create')}
              </Button>
            </CustomDrawerActionsBar>
          }
        />
      </SideDrawerContent>
    </Drawer>
  )
}
