import {Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {VoucherCampaignQuery} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {
  useTranslateVoucherCampaignState,
  useTranslateVoucherCampaignStateDescription
} from '../../../../../hooks/translateVoucherCampaignState'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {voucherCampaignStateColors} from '../../../../constants'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3)
  },
  state: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1)
  }
}))

interface IGeneralSectionProps {
  id: string
  voucherCampaign: VoucherCampaignQuery['voucherCampaign']
}

export const GeneralSection: React.FC<IGeneralSectionProps> = ({
  id,
  voucherCampaign
}: IGeneralSectionProps) => {
  const {t} = useTranslation()
  const translateVoucherCampaignState = useTranslateVoucherCampaignState()
  const translateVoucherCampaignStateDescription =
    useTranslateVoucherCampaignStateDescription()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const classes = useStyles()
  return (
    <Paper variant="outlined" className={classes.root} id={id}>
      <Typography variant="h6">{voucherCampaign.name}</Typography>
      <div className={classes.state}>
        <EntityStateChip
          colorConf={voucherCampaignStateColors[voucherCampaign.state]}
          label={translateVoucherCampaignState(voucherCampaign.state)}
        />
        <Typography variant="caption" color="textSecondary">
          {translateVoucherCampaignStateDescription(voucherCampaign.state)}
        </Typography>
      </div>
      {voucherCampaign.description && (
        <SingleInfoColumn
          caption={t('Description')}
          value={voucherCampaign.description}
          valueTypographyVariant="body2"
        />
      )}
      <DividerSeparatedInfoRow
        information={[
          {
            caption: t('Emit new vouchers'),
            value: voucherCampaign.canCreateNewVouchers
              ? t('Allowed')
              : t('Not allowed')
          },
          {
            caption: t('Initial balance'),
            value: voucherCampaign.initialBalance
              ? translateEffectiveClientPrice(voucherCampaign.initialBalance)
              : t('Not set')
          },
          {
            caption: t('Initial validity'),
            value: voucherCampaign.initialValidity
              ? t('{{count}} day', {
                  count: dayjs
                    .duration(voucherCampaign.initialValidity)
                    .asDays()
                })
              : t('Not set')
          },
          {
            caption: t('Redemption limit'),
            value: voucherCampaign.redemptionLimit || t('Not set')
          },
          {
            caption: t('Recharge limit'),
            value: voucherCampaign.rechargeLimit || t('Not set')
          }
        ]}
      />
    </Paper>
  )
}
