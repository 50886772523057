import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../../../../theme'
import {
  IQuantityControllerProps,
  QuantityController
} from '../../../../common/QuantityController'

interface IListItemWithQuantityControllerProps {
  primaryLabel: string
  secondaryLabel?: string
  className?: string
  quantityControllerProps: IQuantityControllerProps
  disabled?: boolean
}

const useStyles = makeStyles<Theme, {disabled?: boolean}>((theme) => ({
  root: {
    opacity: ({disabled}) =>
      disabled ? theme.palette.action.disabledOpacity : undefined,
    cursor: ({disabled}) => (disabled ? 'normal' : 'pointer'),
    pointerEvents: ({disabled}) => (disabled ? 'none' : 'auto'),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: theme.spacing(3),
    padding: theme.spacing(1, 2),
    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    },
    '&:last-of-type': {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4
    }
  },
  labelsBox: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

export const ListItemWithQuantityController: React.FC<IListItemWithQuantityControllerProps> =
  ({
    primaryLabel,
    secondaryLabel,
    className,
    quantityControllerProps,
    disabled
  }: IListItemWithQuantityControllerProps) => {
    const classes = useStyles({disabled})
    return (
      <div className={cn(classes.root, className)}>
        <div className={classes.labelsBox}>
          <Typography variant="subtitle2">{primaryLabel}</Typography>
          {secondaryLabel && (
            <Typography variant="caption" color="textSecondary">
              {secondaryLabel}
            </Typography>
          )}
        </div>
        <QuantityController {...quantityControllerProps} />
      </div>
    )
  }
