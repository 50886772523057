import noop from 'lodash/noop'
import React, {ReactNode, useCallback, useContext, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {LocaleCode} from '../../__generated__/schema'

import {
  getParsedItemFromStorage,
  useLocalStorageState
} from '../../hooks/storage'

export const DEFAULT_LOCALE = LocaleCode.En

export const Context = React.createContext({
  localeCode: DEFAULT_LOCALE,
  setLocale: noop
})

export const LOCAL_STORAGE_KEYWORD = 'locale'

//for jest tests because localStorage doesn't exist on server
export const getInitialLocale = () =>
  typeof window !== 'undefined'
    ? getParsedItemFromStorage<LocaleCode>(
        localStorage,
        LOCAL_STORAGE_KEYWORD
      ) || DEFAULT_LOCALE
    : LocaleCode.En

type Props = {
  children: ReactNode
}

export const LocaleProvider: React.FC<Props> = ({children}: Props) => {
  const [localeCode, setLocale] = useLocalStorageState<LocaleCode>(
    LOCAL_STORAGE_KEYWORD,
    DEFAULT_LOCALE
  )

  const {i18n} = useTranslation()

  const _setLocale = useCallback(
    (locale: LocaleCode) => {
      i18n.changeLanguage(locale, (err) => {
        if (err) {
          // eslint-disable-next-line
          console.error('Could not change locale', err)
          // TODO: report somehow
        } else {
          setLocale(locale)
        }
      })
    },
    [i18n, setLocale]
  )

  const context = useMemo(
    () => ({
      localeCode,
      setLocale: _setLocale
    }),
    [_setLocale, localeCode]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useLocale = () => useContext(Context)
