import {
  DataSetType,
  LocaleCode,
  TemplateFileType,
  TemplateState,
  TemplateType
} from '../../../../__generated__/schema'

export enum TemplateFormField {
  Name = 'name',
  Description = 'description',
  Types = 'types',
  FileType = 'fileType',
  LocaleCode = 'localeCode',
  DataSetType = 'dataSetType',
  State = 'state',
  AdditionalSearchParams = 'additionalSearchParams'
}

export interface ITemplateForm {
  [TemplateFormField.Name]: string
  [TemplateFormField.Description]?: string
  [TemplateFormField.AdditionalSearchParams]?: string
  [TemplateFormField.Types]: TemplateType[]
  [TemplateFormField.FileType]: TemplateFileType
  [TemplateFormField.LocaleCode]: LocaleCode
  [TemplateFormField.DataSetType]: DataSetType
}

export interface ITemplateSettingsForm
  extends Omit<ITemplateForm, 'fileType' | 'dataSetType'> {
  [TemplateFormField.State]: TemplateState
}
