import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApplicationRuleType,
  ConditionResource
} from '../../../../../__generated__/schema'
import {useTranslateConditionResource} from '../../../../../hooks/discounts'
import {Theme} from '../../../../../theme'
import {getConditionResourcesBasedOnApplicationRuleType} from '../utils'

const useStyles = makeStyles<Theme>((theme) => ({
  label: {
    paddingBottom: theme.spacing(1)
  }
}))
interface ISelectResourceStepProps {
  selectedResource: ConditionResource | null
  onChange(e: any): void
  type?: ApplicationRuleType
}

export const SelectResourceStep: React.FC<ISelectResourceStepProps> = ({
  selectedResource,
  onChange,
  type
}: ISelectResourceStepProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translateConditionResource = useTranslateConditionResource()
  return (
    <div>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {t('Select resource')}
      </Typography>
      <RadioGroup value={selectedResource} onChange={onChange}>
        {getConditionResourcesBasedOnApplicationRuleType(type).map(
          (resource) => (
            <FormControlLabel
              key={resource}
              value={resource}
              control={<Radio color="primary" />}
              sx={{
                '&:hover': {
                  backgroundColor: 'grey.300'
                },
                m: 0
              }}
              label={translateConditionResource(resource)}
            />
          )
        )}
      </RadioGroup>
    </div>
  )
}
