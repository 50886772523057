import {IDimensions} from '@attendio/shared-components'
import {Box} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useEffect} from 'react'
import {Theme} from '../../theme'
import {SIDE_PANEL_ID, SIDE_PANEL_WIDTH, TOP_PANEL_HEIGHT} from '../config'
import {useSelector} from '../redux'
import {useCanvasActions} from '../redux/canvas/actions'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {SidePanel} from '../SidePanel'
import {TopPanel} from '../TopPanel'
import {EditorCanvas} from './EditorCanvas'

type EditorLayoutProps = IDimensions & {
  DefaultPanel?: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper1: ({width, height}: IDimensions) => ({
    width,
    height
  }),
  wrapper2: ({height}: IDimensions) => ({
    height: height - TOP_PANEL_HEIGHT,
    display: 'flex'
  }),
  canvas: {
    flexGrow: 1,
    background: '#eee'
  },
  sidePanel: {
    width: SIDE_PANEL_WIDTH,
    background: theme.palette.background.paper,
    borderLeft: '1px solid #E0E0E0'
  }
}))

export const EditorLayout: React.FC<EditorLayoutProps> = ({
  width,
  height,
  DefaultPanel
}: EditorLayoutProps) => {
  const {changeDimensions} = useCanvasActions()
  const {setSelectMode} = useEditorModeActions()
  const displayMode = useSelector(displayModeSelector)
  const classes = useStyles({width, height})

  useEffect(
    () =>
      changeDimensions({
        height: height - TOP_PANEL_HEIGHT,
        width: width - SIDE_PANEL_WIDTH
      }),
    [changeDimensions, height, width]
  )

  useEffect(() => {
    if (
      displayMode === DisplayMode.PRICING ||
      displayMode === DisplayMode.CASH ||
      displayMode === DisplayMode.RESTRICTED
    ) {
      setSelectMode()
    }
  }, [displayMode, setSelectMode])

  return (
    <Box className={classes.wrapper1}>
      <TopPanel />
      <Box className={classes.wrapper2}>
        <Box className={classes.canvas} cypress-id="konvajs">
          <EditorCanvas />
        </Box>
        <Box id={SIDE_PANEL_ID} className={classes.sidePanel}>
          <SidePanel {...{DefaultPanel}} />
        </Box>
      </Box>
    </Box>
  )
}
