import RefreshIcon from '@mui/icons-material/Refresh'
import {Button, Typography, useTheme} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetCheckEventQuery,
  GetCheckTourTimeSlotQuery
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {CircularProgressWithChildren} from '../../../../common/CircularProgressWithChildren'
import {isGetCheckEventQuery} from '../utils'

const useStyles = makeStyles<Theme>(() => ({
  circularProgressChildren: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

interface IEventCircularProgressProps {
  activity: GetCheckEventQuery | GetCheckTourTimeSlotQuery
  onReload: () => void
  isInCheckInMode: boolean
}

export const CheckEventCircularProgress: React.FC<IEventCircularProgressProps> =
  ({activity, onReload, isInCheckInMode}: IEventCircularProgressProps) => {
    const {t} = useTranslation()
    const classes = useStyles()
    const theme = useTheme()
    if (isInCheckInMode) {
      const value = isGetCheckEventQuery(activity)
        ? activity.event.soldSeatsCount !== 0
          ? Math.round(
              (activity.event.uniqueCheckedInCount /
                activity.event.soldSeatsCount) *
                100
            )
          : 101
        : activity.tourTimeSlot.tourItemsStateCounts.sold !== 0
        ? Math.round(
            ((activity.tourTimeSlot.attendeesCheckedInCount -
              activity.tourTimeSlot.attendeesCheckedOutCount) /
              activity.tourTimeSlot.tourItemsStateCounts.sold) *
              100
          )
        : 101
      return (
        <CircularProgressWithChildren
          value={value}
          activeStroke={
            value > 100 ? 'transparent' : theme.palette.success.main
          }
          backgroundStroke={value > 100 ? 'transparent' : undefined}
        >
          <div className={classes.circularProgressChildren}>
            <Typography variant="overline" color="textSecondary">
              {t('Unchecked')}
            </Typography>
            <Typography variant="h2">
              {isGetCheckEventQuery(activity)
                ? activity.event.soldSeatsCount -
                  activity.event.uniqueCheckedInCount
                : activity.tourTimeSlot.tourItemsStateCounts.sold -
                  activity.tourTimeSlot.attendeesCheckedInCount +
                  activity.tourTimeSlot.attendeesCheckedOutCount}
            </Typography>
            <Button
              startIcon={<RefreshIcon />}
              color="primary"
              onClick={onReload}
            >
              {t('Reload')}
            </Button>
          </div>
        </CircularProgressWithChildren>
      )
    }
    const value = isGetCheckEventQuery(activity)
      ? activity.event.uniqueCheckedInCount !== 0
        ? Math.round(
            ((activity.event.passCodesCheckedInCount -
              activity.event.passCodesCheckedOutCount) /
              activity.event.uniqueCheckedInCount) *
              100
          )
        : 101
      : activity.tourTimeSlot.uniqueCheckedInCount !== 0
      ? Math.round(
          ((activity.tourTimeSlot.passCodesCheckedInCount -
            activity.tourTimeSlot.passCodesCheckedOutCount) /
            activity.tourTimeSlot.uniqueCheckedInCount) *
            100
        )
      : 101
    return (
      <CircularProgressWithChildren
        value={value}
        activeStroke={value > 100 ? 'transparent' : theme.palette.warning.main}
        backgroundStroke={value > 100 ? 'transparent' : undefined}
      >
        <div className={classes.circularProgressChildren}>
          <Typography variant="overline" color="textSecondary">
            {t('On event')}
          </Typography>
          <Typography variant="h2">
            {isGetCheckEventQuery(activity)
              ? activity.event.passCodesCheckedInCount -
                activity.event.passCodesCheckedOutCount
              : activity.tourTimeSlot.attendeesCheckedInCount -
                activity.tourTimeSlot.attendeesCheckedOutCount}
          </Typography>
          <Button
            startIcon={<RefreshIcon />}
            color="primary"
            onClick={onReload}
          >
            {t('Reload')}
          </Button>
        </div>
      </CircularProgressWithChildren>
    )
  }
