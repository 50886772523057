import {Dayjs} from 'dayjs'
import {
  NarrowProductItemPropertiesFragment,
  NarrowTicketItemPropertiesFragment,
  NarrowTourItemPropertiesFragment
} from '../../../../__generated__/schema'

export enum VoucherActivationOption {
  Later = 'later',
  Now = 'now',
  Date = 'date'
}

export enum VoucherActivationOptionsField {
  ActivationDatetime = 'activationDatetime',
  ExpirationDatetime = 'expirationDatetime',
  Activation = 'activation'
}

export interface IVoucherActivationOptionsForm {
  [VoucherActivationOptionsField.ActivationDatetime]?: Dayjs
  [VoucherActivationOptionsField.ExpirationDatetime]?: Dayjs
  [VoucherActivationOptionsField.Activation]?: VoucherActivationOption
}

export const isNotNarrowProductItemPropertiesFragment = (
  item:
    | NarrowTicketItemPropertiesFragment
    | NarrowTourItemPropertiesFragment
    | NarrowProductItemPropertiesFragment
): item is
  | NarrowTicketItemPropertiesFragment
  | NarrowTourItemPropertiesFragment => item.__typename !== 'ProductItem'

export const isNarrowTicketItemPropertiesFragment = (
  item:
    | NarrowTicketItemPropertiesFragment
    | NarrowTourItemPropertiesFragment
    | NarrowProductItemPropertiesFragment
): item is NarrowTicketItemPropertiesFragment =>
  item.__typename === 'TicketItem'
