import {Button} from '@mui/material'
import {
  Html5Qrcode,
  Html5QrcodeScannerState,
  Html5QrcodeSupportedFormats
} from 'html5-qrcode'
import {
  CameraDevice,
  Html5QrcodeScanType,
  QrDimensions
} from 'html5-qrcode/esm/core'
import React, {forwardRef, useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Loading} from '../visual'
import {Blank} from '../visual/Blank'
import {useFetchCameras} from './fetchCameras'
import {MissingCameraPermissionBlank} from './MissingCameraPermissionBlank'
import {NoCamerasFoundBlank} from './NoCamerasFoundBlank'

interface IHtmlQrcodePluginProps {
  qrbox: number | QrDimensions | undefined
  onCodeScanned: (code: string) => void
  qrcodeRegionId: string
  waitPeriod?: number
  verbose?: boolean
  className?: string
  defaultCameraId?: string
  onNavigateToSettingsButtonClick: () => void
}

export interface IHtmlQrcodePluginForwardedRef {
  pause: () => void
  resume: () => void
}

const getCameraId = (cameras: CameraDevice[], defaultCameraId?: string) => {
  if (cameras.length === 0) {
    return null
  }
  if (cameras.length === 1) {
    return cameras[0].id
  }
  return cameras.find((c) => c.id === defaultCameraId)?.id ?? null
}

export const HtmlQrcodePlugin = forwardRef<
  IHtmlQrcodePluginForwardedRef,
  IHtmlQrcodePluginProps
>(function HtmlQrcodePluginComp(
  {
    qrcodeRegionId,
    qrbox,
    onCodeScanned,
    waitPeriod = 750,
    verbose = false,
    onNavigateToSettingsButtonClick,
    className,
    defaultCameraId
  }: IHtmlQrcodePluginProps,
  ref
) {
  const html5Qrcode = useRef<null | Html5Qrcode>(null)
  const {t} = useTranslation()

  const {
    state: {isLoading, error, data: cameras},
    refetchCameras
  } = useFetchCameras()
  const cameraId = getCameraId(cameras, defaultCameraId)

  const resumeAfterWaitPeriod = useCallback(() => {
    setTimeout(() => {
      if (html5Qrcode.current) {
        const state = html5Qrcode.current?.getState()
        if (state === Html5QrcodeScannerState.PAUSED) {
          html5Qrcode.current?.resume()
        }
      }
    }, waitPeriod)
  }, [waitPeriod])
  const pause = useCallback(() => {
    const state = html5Qrcode.current?.getState()
    if (state === Html5QrcodeScannerState.SCANNING) {
      html5Qrcode.current?.pause(true)
    }
  }, [])

  const innerRef = useRef<IHtmlQrcodePluginForwardedRef>({
    pause,
    resume: resumeAfterWaitPeriod
  })

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(innerRef.current)
      } else {
        ref.current = innerRef.current
      }
    }
  }, [ref])

  useEffect(() => {
    if (!isLoading && !error && cameras.length > 0 && cameraId) {
      const config = {
        fps: 4,
        qrbox,
        formatsToSupport: [
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.QR_CODE
        ],
        rememberLastUsedCamera: true,
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        verbose
      }
      html5Qrcode.current = new Html5Qrcode(qrcodeRegionId)
      html5Qrcode.current
        .start(cameraId, config, onCodeScanned, () => {
          // nothing scanned
        })
        .then(() => {
          // camera started
        })
        .catch(() => {
          // camera start failed
        })
    }
    return () => {
      html5Qrcode.current
        ?.stop()
        .then(() => {
          // camera stopped
        })
        .catch(() => {
          // camera failed to stop
        })
    }
  }, [
    cameraId,
    cameras,
    cameras.length,
    error,
    isLoading,
    onCodeScanned,
    qrbox,
    qrcodeRegionId,
    verbose
  ])

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <MissingCameraPermissionBlank refetchCameras={refetchCameras} />
  }

  if (cameras.length === 0) {
    return <NoCamerasFoundBlank />
  }
  if (cameras.length > 1 && cameraId === null) {
    return (
      <Blank
        title={t('Manage camera settings')}
        description={t(
          'Set up camera first and choose which camera will be used for scanning codes. This setting needs to be done only for the first time and will be remembered on device.'
        )}
        actions={
          <Button
            onClick={onNavigateToSettingsButtonClick}
            color="primary"
            variant="contained"
          >
            {t('Camera settings')}
          </Button>
        }
      />
    )
  }
  return <div id={qrcodeRegionId} className={className} />
})
