import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {Box, Button, Grid, Typography} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DetailEventPropertiesFragment,
  EventState,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useHandleInvalidDateTimes} from '../../../../../../hooks/pickerErrors'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {
  durationObjectToMinutes,
  minutesToDurationObject
} from '../../../../../../utils/durationInput'
import {useDefaultErrorHandler} from '../../../../../../utils/errors'
import {useHistoryGoBack} from '../../../../../../utils/routes'

import {
  CustomDrawerActionsBar,
  DrawerForm,
  RelativeSideNavigation
} from '../../../../../common'
import {HEIGHT as DRAWER_HEADER_HEIGHT} from '../../../../../common/DrawerUtils'
import {useBackdropState} from '../../../../../context/backdrop'
import {useNotifications} from '../../../../../context/notifications'
import {DisabledFormWrapper} from '../../../../../visual'

import {
  LoadEventData,
  useCommonFormStyles,
  useDrawerHeaderTitle,
  useGetBackToGeneralInfoLink
} from '../common'
import {FormField, ISalesFormData, SectionPrefix} from './common'
import {useUpdateSalesEventData} from './graphql'
import {SalesFormFields} from './SalesFormFields'

const EVENT_SALES_FORM_ID = 'event sales form'

interface ISalesOptionsFormProps {
  width: number
  elementToScrollOn: Element | null
}

const useEventSalesOptionsAnchors = () => {
  const {t} = useTranslation()
  return {
    pointOfSale: {
      id: 'pointOfSale',
      label: t('Point of sale')
    },
    online: {
      id: 'online',
      label: t('Online')
    }
  }
}

const getDefaultValues = (event: DetailEventPropertiesFragment) => {
  const {
    salesAndReservation: {online, pointOfSale}
  } = event
  return {
    [SectionPrefix.POINT_OF_SALE]: {
      [FormField.SALE_ACTIVE]: pointOfSale.saleActive,
      [FormField.SALE_START]: dayjs(pointOfSale.saleStart),
      [FormField.SALE_END]: dayjs(pointOfSale.saleEnd),
      [FormField.RESERVATION_ACTIVE]: pointOfSale.reservationActive,
      [FormField.RESERVATION_START]: dayjs(pointOfSale.reservationStart),
      [FormField.RESERVATION_END]: dayjs(pointOfSale.reservationEnd),
      [FormField.RESERVATION_EXPIRATION_TYPE]:
        pointOfSale.reservationExpirationType,
      [FormField.RESERVATION_EXPIRATION_EXPIRES_AT]: dayjs(
        pointOfSale.reservationExpirationExpiresAt
      ),
      [FormField.RESERVATION_EXPIRATION_LAST_FOR]: minutesToDurationObject(
        pointOfSale.reservationExpirationLastFor
      ),
      [FormField.MAX_NUMBER_OF_OCCUPIED_SEATS]:
        event.maxNumberOfOccupiedSeatsOnRetail
    },
    [SectionPrefix.ONLINE]: {
      [FormField.SALE_ACTIVE]: online.saleActive,
      [FormField.SALE_START]: dayjs(online.saleStart),
      [FormField.SALE_END]: dayjs(online.saleEnd),
      [FormField.RESERVATION_ACTIVE]: online.reservationActive,
      [FormField.RESERVATION_START]: dayjs(online.reservationStart),
      [FormField.RESERVATION_END]: dayjs(online.reservationEnd),
      [FormField.RESERVATION_EXPIRATION_TYPE]: online.reservationExpirationType,
      [FormField.RESERVATION_EXPIRATION_EXPIRES_AT]: dayjs(
        online.reservationExpirationExpiresAt
      ),
      [FormField.RESERVATION_EXPIRATION_LAST_FOR]: minutesToDurationObject(
        online.reservationExpirationLastFor
      ),
      [FormField.MAX_NUMBER_OF_OCCUPIED_SEATS]:
        event.maxNumberOfOccupiedSeatsOnEcommerce
    }
  }
}

const useGetOnSubmit = (eventId: number, onClose: Function) => {
  const updateEvent = useUpdateSalesEventData()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const {setShowBackdrop} = useBackdropState()

  return async (data: ISalesFormData) => {
    try {
      setShowBackdrop(true)
      await updateEvent({
        id: eventId,
        data: {
          [SectionPrefix.POINT_OF_SALE]: {
            [FormField.SALE_START]: data.pointOfSale.saleStart.toISOString(),
            [FormField.SALE_END]: data.pointOfSale.saleEnd.toISOString(),
            [FormField.SALE_ACTIVE]: data.pointOfSale.saleActive,
            [FormField.RESERVATION_START]:
              data.pointOfSale.reservationStart.toISOString(),
            [FormField.RESERVATION_END]:
              data.pointOfSale.reservationEnd.toISOString(),
            [FormField.RESERVATION_ACTIVE]: data.pointOfSale.reservationActive,
            [FormField.RESERVATION_EXPIRATION_TYPE]:
              data.pointOfSale.reservationExpirationType,
            [FormField.RESERVATION_EXPIRATION_EXPIRES_AT]:
              data.pointOfSale.reservationExpirationExpiresAt.toISOString(),
            [FormField.RESERVATION_EXPIRATION_LAST_FOR]:
              durationObjectToMinutes(
                data.pointOfSale.reservationExpirationLastFor
              )
          },
          [SectionPrefix.ONLINE]: {
            [FormField.SALE_START]: data.online.saleStart.toISOString(),
            [FormField.SALE_END]: data.online.saleEnd.toISOString(),
            [FormField.SALE_ACTIVE]: data.online.saleActive,
            [FormField.RESERVATION_START]:
              data.online.reservationStart.toISOString(),
            [FormField.RESERVATION_END]:
              data.online.reservationEnd.toISOString(),
            [FormField.RESERVATION_ACTIVE]: data.online.reservationActive,
            [FormField.RESERVATION_EXPIRATION_TYPE]:
              data.online.reservationExpirationType,
            [FormField.RESERVATION_EXPIRATION_EXPIRES_AT]:
              data.online.reservationExpirationExpiresAt.toISOString(),
            [FormField.RESERVATION_EXPIRATION_LAST_FOR]:
              durationObjectToMinutes(data.online.reservationExpirationLastFor)
          },
          maxNumberOfOccupiedSeatsOnRetail:
            data.pointOfSale.maxNumberOfOccupiedSeats,
          maxNumberOfOccupiedSeatsOnEcommerce:
            data.online.maxNumberOfOccupiedSeats
        }
      })
      addInfoNotification(t('Event was updated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while saving event.'))
    } finally {
      setShowBackdrop(false)
    }

    onClose()
  }
}

interface ISalesOptionsFormContentProps extends ISalesOptionsFormProps {
  eventData: DetailEventPropertiesFragment
}

const SalesOptionsFormContent: React.FC<ISalesOptionsFormContentProps> = ({
  width,
  elementToScrollOn,
  eventData
}: ISalesOptionsFormContentProps) => {
  const classes = useCommonFormStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {goBack} = useHistoryGoBack()
  const backLink = useGetBackToGeneralInfoLink()

  const onClose = () => goBack({fallbackRoute: backLink})
  const onSubmit = useGetOnSubmit(eventData.id, onClose)

  const sideMenuItems = useEventSalesOptionsAnchors()
  const drawerHeaderTitle = useDrawerHeaderTitle()

  const formProps = useForm<ISalesFormData>({
    defaultValues: getDefaultValues(eventData)
  })
  const {setError, handleSubmit} = formProps

  const disabled =
    eventData.state !== EventState.Draft &&
    eventData.state !== EventState.Published
  const handleInvalidDateTimes =
    useHandleInvalidDateTimes<ISalesFormData>(setError)
  return (
    <DrawerForm
      title={drawerHeaderTitle}
      wrapperClassName={classes.drawerFormWrapper}
      bodyClassName={classes.drawerForm}
      width={width}
      LeftActionIcon={ArrowBackIcon}
      onClose={onClose}
      ActionBar={
        <CustomDrawerActionsBar width={width}>
          <Button color="primary" onClick={onClose}>
            {t('Back')}
          </Button>
          {P([PermissionCode.UpdateSalesEventData]) && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form={EVENT_SALES_FORM_ID}
              disabled={disabled}
            >
              {t('Save')}
            </Button>
          )}
        </CustomDrawerActionsBar>
      }
    >
      <Grid container>
        <div className={classes.stickyNavigationWrapper}>
          <RelativeSideNavigation
            items={sideMenuItems}
            scrollOffset={DRAWER_HEADER_HEIGHT}
            elementToScrollOn={elementToScrollOn}
          />
        </div>
        <div className={classes.formWrapper}>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
          >
            {t('Sale and reservation')}
          </Typography>
          <DisabledFormWrapper disabled={disabled}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleInvalidDateTimes(onSubmit))}
              id={EVENT_SALES_FORM_ID}
            >
              <FormContext {...formProps}>
                <SalesFormFields
                  id={sideMenuItems.pointOfSale.id}
                  header={sideMenuItems.pointOfSale.label}
                  sectionPrefix={SectionPrefix.POINT_OF_SALE}
                  eventCreatedAt={eventData.createdAt}
                  auditoriumCapacity={eventData.auditoriumLayout.capacity}
                />
                <Box marginTop={2} />
                <SalesFormFields
                  id={sideMenuItems.online.id}
                  header={sideMenuItems.online.label}
                  sectionPrefix={SectionPrefix.ONLINE}
                  eventCreatedAt={eventData.createdAt}
                  auditoriumCapacity={eventData.auditoriumLayout.capacity}
                />
              </FormContext>
            </form>
          </DisabledFormWrapper>
        </div>
      </Grid>
    </DrawerForm>
  )
}

export const SalesOptionsForm: React.FC<ISalesOptionsFormProps> = ({
  width,
  elementToScrollOn
}: ISalesOptionsFormProps) => {
  return (
    <LoadEventData>
      {(eventData) => (
        <SalesOptionsFormContent {...{width, elementToScrollOn, eventData}} />
      )}
    </LoadEventData>
  )
}
