import {Box, Chip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export enum ClaimStateOption {
  Draft = 'draft',
  Approved = 'approved',
  Pending = 'pending',
  Refunded = 'refunded',
  Failed = 'failed',
  All = 'all'
}

const useTranslateClaimStateOption = () => {
  const {t} = useTranslation()
  const translated: Record<ClaimStateOption, string> = {
    [ClaimStateOption.Draft]: t<string>('Draft'),
    [ClaimStateOption.Approved]: t<string>('Approved'),
    [ClaimStateOption.Pending]: t<string>('Pending'),
    [ClaimStateOption.Refunded]: t<string>('Refunded'),
    [ClaimStateOption.Failed]: t<string>('Failed'),
    [ClaimStateOption.All]: t<string>('All')
  }
  return (state: ClaimStateOption) => translated[state]
}

interface IClaimStateChipProps {
  state: ClaimStateOption
  onChange: (state: ClaimStateOption) => void
}

export const ClaimStateChip: React.FC<IClaimStateChipProps> = ({
  state,
  onChange
}: IClaimStateChipProps) => {
  const translateClaimStateOption = useTranslateClaimStateOption()
  return (
    <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
      {[
        ClaimStateOption.Draft,
        ClaimStateOption.Approved,
        ClaimStateOption.Pending,
        ClaimStateOption.Failed,
        ClaimStateOption.Refunded,
        ClaimStateOption.All
      ].map((option) => (
        <Chip
          size="small"
          key={option}
          label={translateClaimStateOption(option)}
          variant="outlined"
          color={option === state ? 'primary' : undefined}
          onClick={() => onChange(option)}
        />
      ))}
    </Box>
  )
}
