import {useTranslation} from 'react-i18next'
import {ZoneType} from '../shared/editor/objectTypes'

export const useTranslateZoneTypes = () => {
  const {t} = useTranslation()
  return (zoneType: ZoneType) => {
    switch (zoneType) {
      case ZoneType.Standing:
        return t('Standing')
      case ZoneType.Ticket:
        return t('Ticket')
      case ZoneType.FreeSitting:
      default:
        return t('Free sitting')
    }
  }
}
