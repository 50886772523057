import {FontType, SeatShape} from '@attendio/shared-components'

export const DOUBLE_CLICK_INTERVAL = 300 // ms

export const EXPORT_PIXEL_RATIO = 2

export const ROTATION = 0 // degrees

export const SHORT_MOVE_STEP = 1 // px
export const LONG_MOVE_STEP = 10 // px

export const SIDE_PANEL_ID = 'sidePanel'
export const SIDE_PANEL_WAIT_TIME_FOR_AUTOSAVE = 500 // ms
export const SIDE_PANEL_WIDTH = 400 // px
export const TOP_PANEL_HEIGHT = 48 // px
export const TOP_PANEL_ICONS_SIZE = 24 // px

export const SCALE_FACTOR = 1.2
export const SCALE_MAX = Math.pow(SCALE_FACTOR, 5)
export const SCALE_MIN = Math.pow(SCALE_FACTOR, -5)

export const ELLIPSE_MIN_WIDTH = 10
export const ELLIPSE_MIN_HEIGHT = 10
export const LINE_MIN_LENGTH = 10
export const RECTANGLE_MIN_WIDTH = 10
export const RECTANGLE_MIN_HEIGHT = 1
export const ZONE_MIN_WIDTH = 50
export const ZONE_MIN_HEIGHT = 30

export const SHAPE_ROTATION_ROUND_FACTOR = 15 // degrees

export const SEAT_SIZE = 30
export const SEAT_BORDER_RADIUS = 10
export const SEAT_LABEL = '1'
export const SEAT_SHAPE = SeatShape.Classic

export const ROW_ANGLE_PRECISION = 2
export const ROWS_GAP = 10 // px

export const ICON_SIZE = 24 // px
export const ICON_CORNER_RADIUS = 5 // px
export const ICON_CIRCLE_SIZE = ICON_SIZE * 1.5
export const ICON_MIN_SIZE = ICON_CIRCLE_SIZE * 1.2

export const TEXT_SIZE = 16 // px
export const TEXT_TYPE = FontType.Regular
export const TEXT_VALUE = 'Text'

export const ZONE_CAPACITY = 200

export const PLAN_PADDING = 30 // px

export const SELECTION_FILL_COLOR = 'rgba(140, 0, 255, 0.1)'
export const SELECTION_STROKE_COLOR = 'rgba(140, 0, 255, 1)'
export const SELECTION_STROKE_WIDTH = 2
export const SELECTION_STROKE_DASH = [7, 3]
