import ErrorIcon from '@mui/icons-material/ErrorOutline'
import {Card, Grid, Theme, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles<Theme>((theme) => ({
  errorBox: {
    display: 'inlineBlock',
    boxShadow: 'none',
    backgroundColor: '#FDECEA',
    color: '#611A15',
    padding: theme.spacing(2)
  },
  icon: {
    color: '#F44336'
  },
  title: {
    fontWeight: 500,
    fontSize: 16
  },
  message: {
    paddingLeft: theme.spacing(2)
  }
}))

interface IErrorProps {
  error: any
  message: string
}

export const Error: React.FC<IErrorProps> = ({error, message}: IErrorProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  console.error(message, error) // eslint-disable-line

  return (
    <Card className={classes.errorBox}>
      <Grid container direction="row" alignItems="flex-start">
        <Grid item>
          <ErrorIcon className={classes.icon} />
        </Grid>
        <Grid item className={classes.message}>
          <Typography className={classes.title}>{t('Error')}</Typography>
          <Typography>{message}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}
