import EditIcon from '@mui/icons-material/Edit'
import {Box, Button} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  ApiGrantQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useApiGrantParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useApiGrant} from '../graphql'
import {ClientsSection} from './ClientsSection'
import {ContactSection} from './ContactSection'
import {GeneralSection} from './GeneralSection'
import {ResourcesSection} from './ResourcesSection'
import {UpdateApiGrantDrawer} from './UpdateApiGrantDrawer'

const useItems = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    resources: {
      id: 'resources',
      label: t('Resources')
    },
    businessContact: {
      id: 'businessContact',
      label: t('Business contact')
    },
    technicalContact: {
      id: 'technicalContact',
      label: t('Technical contact')
    },
    clients: {
      id: 'clients',
      label: t('Clients')
    }
  }
}

export const ApiGrantDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {apiGrantId} = useApiGrantParams()
  const {data, loading, error} = useApiGrant(apiGrantId)
  const [title, setTitle] = useState<string>(t<string>('API grant'))
  const history = useHistory()
  const items = useItems()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.apiGrants.index()),
    [history]
  )
  const handleExited = useCallback(
    () => history.push(routeTo.admin.apiGrants.detail(apiGrantId)),
    [apiGrantId, history]
  )
  const handleEditButtonClick = useCallback(
    () => history.push(routeTo.admin.apiGrants.edit(apiGrantId)),
    [apiGrantId, history]
  )
  useEffect(() => {
    if (data) {
      setTitle(data.apiGrant.name)
    }
  }, [data])
  return (
    <>
      <PageWithHeaderAndFooterTemplate
        header={
          <SecondaryHeader
            title={title}
            hasArrowBackIcon
            onLeftActionClick={handleArrowBackClick}
          />
        }
        footer={
          P([PermissionCode.UpdateApiGrant]) && (
            <Footer>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditButtonClick}
              >
                {t('Edit')}
              </Button>
            </Footer>
          )
        }
      >
        <RenderOnData<ApiGrantQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading API grant')}
          dataCondition={(data) => Boolean(data.apiGrant)}
          ignoreLoadingIfData
        >
          {({apiGrant}) => (
            <CenteredLayout>
              <SingleSideNavigationList items={items} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  pt: 3,
                  pb: 3
                }}
              >
                <GeneralSection id={items.general.id} apiGrant={apiGrant} />
                <ResourcesSection
                  id={items.resources.id}
                  label={items.resources.label}
                  resources={apiGrant.grantedResources}
                />
                <ContactSection
                  id={items.businessContact.id}
                  label={items.businessContact.label}
                  contact={apiGrant.businessContact}
                />
                <ContactSection
                  id={items.technicalContact.id}
                  label={items.technicalContact.label}
                  contact={apiGrant.technicalContact}
                />
                <ClientsSection
                  id={items.clients.id}
                  label={items.clients.label}
                  clients={apiGrant.clientsOwningResources}
                  apiGrantId={apiGrant.id}
                />
              </Box>
            </CenteredLayout>
          )}
        </RenderOnData>
      </PageWithHeaderAndFooterTemplate>
      {P([PermissionCode.UpdateApiGrant]) && (
        <Route path={routeTo.admin.apiGrants.edit(':apiGrantId')} exact>
          <UpdateApiGrantDrawer onExited={handleExited} />
        </Route>
      )}
    </>
  )
}
