import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DivisionOnlineSalesEnd} from '../../../../../__generated__/schema'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {DivisionFormControlLabel} from './DivisionFormControlLabel'
import {
  DivisionFormSelect,
  FormControlTextLabel,
  FormControlTimeLabel
} from './DivisionFormSelect'
import {
  DivisionFormField,
  IDivisionFormData,
  IDivisionSectionProps
} from './types'

export const OnlinePurchaseEnd: React.FC<IDivisionSectionProps> = ({
  defaultValues
}: IDivisionSectionProps) => {
  const {t} = useTranslation()
  const salesEnds = Object.values(DivisionOnlineSalesEnd)
  const methods = useFormContext<IDivisionFormData>()
  const {
    watch,
    triggerValidation,
    errors,
    setValue,
    register,
    control,
    unregister,
    clearError,
    setError
  } = methods
  const salesEndWebType = watch(DivisionFormField.SALES_END_WEB_TYPE)
  useEffect(() => {
    if (salesEndWebType) {
      triggerValidation(
        Object.values(DivisionOnlineSalesEnd)
          .filter((sale) => sale !== salesEndWebType)
          .map((sale) => `${DivisionFormField.SALES_END_WEB_VALUE}.${sale}`)
      )
    }
  }, [salesEndWebType, triggerValidation])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <DivisionFormSelect<IDivisionFormData>
      options={salesEnds}
      name={DivisionFormField.SALES_END_WEB_TYPE}
      subfieldsName={DivisionFormField.SALES_END_WEB_VALUE}
      defaultValue={
        defaultValues[DivisionFormField.SALES_END_WEB_TYPE] ||
        DivisionOnlineSalesEnd.EventStart
      }
      validationOptions={{
        required: true
      }}
      required
      errors={errors}
      setValue={setValue}
      register={register}
      label={t('Online purchase ends')}
    >
      <DivisionFormControlLabel
        value={DivisionOnlineSalesEnd.EventStart}
        label={t('Same as event start time')}
      />
      <DivisionFormControlLabel
        value={DivisionOnlineSalesEnd.AfterStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.SALES_END_WEB_VALUE}.${DivisionOnlineSalesEnd.AfterStart}`}
            validationOptions={{
              required: salesEndWebType === DivisionOnlineSalesEnd.AfterStart,
              validate: isNonNegativeInteger
            }}
            required={salesEndWebType === DivisionOnlineSalesEnd.AfterStart}
            inputMode="numeric"
            note={t('minutes after event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionOnlineSalesEnd.BeforeStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.SALES_END_WEB_VALUE}.${DivisionOnlineSalesEnd.BeforeStart}`}
            validationOptions={{
              required: salesEndWebType === DivisionOnlineSalesEnd.BeforeStart,
              validate: isNonNegativeInteger
            }}
            required={salesEndWebType === DivisionOnlineSalesEnd.BeforeStart}
            inputMode="numeric"
            note={t('minutes before event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionOnlineSalesEnd.DayBeforeStart}
        label={
          <FormControlTimeLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.SALES_END_WEB_VALUE}.${DivisionOnlineSalesEnd.DayBeforeStart}`}
            validationOptions={{
              required:
                salesEndWebType === DivisionOnlineSalesEnd.DayBeforeStart
            }}
            note={t('the day before the event start')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            unregister={unregister}
            clearError={clearError}
            setError={setError}
          />
        }
      />
    </DivisionFormSelect>
  )
}
