import {
  LeadField,
  LeadInputStatus,
  LeadOptionInput
} from '../../../__generated__/schema'

export enum LeadOptionInputFormField {
  HELPER_TEXT = 'helper_text',
  INPUT_STATUS = 'input_status',
  ID = 'id'
}

export interface ILeadOptionInput {
  [LeadOptionInputFormField.ID]: string
  [LeadOptionInputFormField.INPUT_STATUS]: LeadInputStatus
  [LeadOptionInputFormField.HELPER_TEXT]: string | null
}

export interface ILeadSettingsForm {
  [LeadField.Name]: ILeadOptionInput
  [LeadField.Phone]: ILeadOptionInput
  [LeadField.Email]: ILeadOptionInput
  [LeadField.Note]: ILeadOptionInput
  [LeadField.InternalNote]: ILeadOptionInput
  [LeadField.CompanyName]: ILeadOptionInput
  [LeadField.CompanyIdNumber]: ILeadOptionInput
  [LeadField.VatId]: ILeadOptionInput
  [LeadField.TaxId]: ILeadOptionInput
  [LeadField.BillingAddressStreet]: ILeadOptionInput
  [LeadField.BillingAddressTown]: ILeadOptionInput
  [LeadField.BillingPostalCode]: ILeadOptionInput
  [LeadField.BillingAddressCountry]: ILeadOptionInput
  [LeadField.DeliveryAddressee]: ILeadOptionInput
  [LeadField.DeliveryAddressStreet]: ILeadOptionInput
  [LeadField.DeliveryAddressTown]: ILeadOptionInput
  [LeadField.DeliveryPostalCode]: ILeadOptionInput
  [LeadField.DeliveryAddressCountry]: ILeadOptionInput
}

export const transformLeadOptionsFormToInput = (
  form: ILeadSettingsForm
): LeadOptionInput[] => {
  return Object.values(form).map(
    ({
      [LeadOptionInputFormField.ID]: stringId,
      [LeadOptionInputFormField.INPUT_STATUS]: inputStatus,
      [LeadOptionInputFormField.HELPER_TEXT]: helperText
    }) => ({
      id: parseInt(stringId, 10),
      helperText,
      inputStatus
    })
  )
}
