import {useLazyQuery} from '@apollo/react-hooks'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import {Button} from '@mui/material'
import axios from 'axios'
import Decimal from 'decimal.js'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CartPropertiesFragment,
  CartState,
  Currency,
  CurrentCartQuery,
  CurrentCartQueryVariables,
  IntentState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useRequireEffectiveClient} from '../../../../../hooks/requireEffectiveClient'
import {
  getParsedItemFromStorage,
  LocalStorageKey,
  setObjectToStorage
} from '../../../../../hooks/storage'
import {
  IPosTerminalApiTerminalRepeatLastMessageRequest,
  PosTerminalApiSaleResponseState,
  PosTerminalApiTerminalSaleRequest,
  PosTerminalApiTerminalSaleResponse,
  PosTerminalCurrency
} from '../../../../../types'
import {getTerminalConfig} from '../../../../../utils/getTerminalConfig'
import {SimpleDialog} from '../../../../common/SimpleDialog'
import {Blank} from '../../../../visual/Blank'
import {useCurrentCart} from '../CurrentCartContext'
import {CURRENT_CART_QUERY} from '../graphql'
import {
  getPosTerminalAppPort,
  getPosTerminalAppSettings
} from './posTerminalAppUtils'

interface IPendingCartCoreProps {
  cart: CartPropertiesFragment
}

const getPosTerminalCurrency = (currency?: Currency) => {
  switch (currency) {
    case Currency.Czk:
      return PosTerminalCurrency.CZK
    case Currency.Usd:
      return PosTerminalCurrency.USD
    case Currency.Gbp:
      return PosTerminalCurrency.GBP
    case Currency.Rub:
      return PosTerminalCurrency.RUB
    case Currency.Eur:
    default:
      return PosTerminalCurrency.EUR
  }
}

interface IPendingCartData
  extends Pick<CartPropertiesFragment, 'id' | 'paymentIntents' | 'clientId'> {}

const extractPendingCartData = (
  cart: CartPropertiesFragment
): IPendingCartData => ({
  id: cart.id,
  paymentIntents: cart.paymentIntents,
  clientId: cart.clientId
})

const getPendingCartDataFromLocalStorage = () =>
  getParsedItemFromStorage<IPendingCartData>(
    localStorage,
    LocalStorageKey.PendingCartData
  )
const pendingCartDataIsNullOrDifferentFromCartData = (
  cart: CartPropertiesFragment
) => {
  const pendingCartDataFromCart = extractPendingCartData(cart)
  const pendingCartDataFromStorage = getPendingCartDataFromLocalStorage()
  return (
    pendingCartDataFromStorage === null ||
    JSON.stringify(pendingCartDataFromCart) !==
      JSON.stringify(pendingCartDataFromStorage)
  )
}

export const PendingCartCore: React.FC<IPendingCartCoreProps> = ({
  cart
}: IPendingCartCoreProps) => {
  const {t} = useTranslation()
  const {refetchCurrentCart, currentCartId, updateCurrentCart} =
    useCurrentCart()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const [queryCurrentCart] = useLazyQuery<
    CurrentCartQuery,
    CurrentCartQueryVariables
  >(CURRENT_CART_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      const port = getPosTerminalAppPort()
      const redirectUrl = localStorage.getItem(
        LocalStorageKey.PendingCarteCommercePaymentRedirectUrl
      )
      const pendingCartDataFromLocalStorage =
        getPendingCartDataFromLocalStorage()
      if (
        data.cart.state === CartState.Pending &&
        port &&
        redirectUrl &&
        pendingCartDataFromLocalStorage?.id === data.cart.id
      ) {
        try {
          setShowBackdrop(true)
          await axios.request<PosTerminalApiTerminalSaleResponse>(
            getTerminalConfig<IPosTerminalApiTerminalRepeatLastMessageRequest>({
              port,
              url: '/terminal/repeatLastTransaction',
              method: 'POST',
              data: {
                returnUrl: redirectUrl
              }
            })
          )
        } catch (e) {
          defaultErrorHandler(e, t('Repeat last transaction failed'))
        } finally {
          setShowBackdrop(false)
          refetchCurrentCart()
        }
      } else {
        updateCurrentCart()
      }
    },
    onError: (error) => {
      defaultErrorHandler(error, t('Error while fetching a cart'))
    }
  })
  const handleCheckStatusButtonClick = useCallback(async () => {
    if (currentCartId) {
      await queryCurrentCart({variables: {cartId: currentCartId}})
    }
  }, [currentCartId, queryCurrentCart])
  const effectiveClient = useRequireEffectiveClient()

  const [dialogData, setDialogData] =
    useState<null | {
      title: string
      content: string
    }>(null)

  const {port, paymentMethodId} = getPosTerminalAppSettings() || {}

  const handleMount = useCallback(
    async (
      eCommercePaymentRedirectUrl: string,
      pendingCartData: IPendingCartData
    ) => {
      const paymentIntent = pendingCartData.paymentIntents.find(
        (pi) =>
          pi.paymentMethodId === paymentMethodId &&
          pi.state === IntentState.Pending
      )
      if (port && paymentIntent?.amount) {
        localStorage.setItem(
          LocalStorageKey.PendingCarteCommercePaymentRedirectUrl,
          eCommercePaymentRedirectUrl
        )
        setObjectToStorage(
          localStorage,
          LocalStorageKey.PendingCartData,
          pendingCartData
        )
        const errorDialogData = {
          title: t('POS terminal payment error'),
          content: t(
            'We encountered an error processing the payment on the POS terminal. This could be due to various technical issues. Ensure the POS terminal has an internet connection and is turned on. Verify that the communication app directing commands to the POS terminal is running and has the correct configuration set. Please address the previously mentioned issues and retry the payment. If problems continue, consider using alternative payment methods or seek technical support.'
          )
        }

        try {
          const {data} =
            await axios.request<PosTerminalApiTerminalSaleResponse>(
              getTerminalConfig<PosTerminalApiTerminalSaleRequest>({
                port,
                method: 'post',
                url: '/terminal/sale',
                timeout: 120000,
                data: {
                  returnUrl: eCommercePaymentRedirectUrl,
                  data: {
                    amount: new Decimal(paymentIntent.amount)
                      .mul(100)
                      .toNumber(),
                    clientId: pendingCartData.clientId,
                    currency: getPosTerminalCurrency(effectiveClient.currency)
                  }
                }
              })
            )
          switch (data.state) {
            case PosTerminalApiSaleResponseState.succeeded:
              refetchCurrentCart()
              break
            case PosTerminalApiSaleResponseState.cancelled:
              setDialogData({
                title: t('Card payment canceled'),
                content: t(
                  'The card payment via the POS terminal was canceled by the operator or customer. Please repeat the payment process or recommend the customer to try an alternative payment method.'
                )
              })
              break
            case PosTerminalApiSaleResponseState.failed:
              setDialogData({
                title: t('Payment failed'),
                content: t(
                  'Card payment on the POS terminal was unsuccessful. Please double-check card details, such as the expiration date and PIN, and ensure the card is properly inserted or tapped. Try again. If the issue persists, recommend that the customer use a different payment method. The customer should also contact their card issuer or bank for assistance.'
                )
              })
              break
            case PosTerminalApiSaleResponseState.error:
            default:
              setDialogData(errorDialogData)
          }
        } catch (e) {
          setDialogData(errorDialogData)
        }
      }
    },
    [effectiveClient.currency, paymentMethodId, port, refetchCurrentCart, t]
  )

  useEffect(() => {
    if (
      cart.eCommercePaymentRedirectUrl &&
      pendingCartDataIsNullOrDifferentFromCartData(cart)
    ) {
      // noinspection JSIgnoredPromiseFromCall
      handleMount(
        cart.eCommercePaymentRedirectUrl,
        extractPendingCartData(cart)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Blank
        IconComp={CreditCardIcon}
        title={t('Payment in progress')}
        description={t(
          'The payment is currently being processed via the POS terminal. Please closely follow the instructions displayed on the POS terminal screen. Do not reload this screen.'
        )}
        actions={
          !pendingCartDataIsNullOrDifferentFromCartData(cart) &&
          cart.eCommercePaymentRedirectUrl ? null : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckStatusButtonClick}
            >
              {t('Check status')}
            </Button>
          )
        }
      />
      {dialogData ? (
        <SimpleDialog
          content={dialogData.content}
          title={dialogData.title}
          actions={
            <Button
              onClick={() => {
                setDialogData(null)
                refetchCurrentCart()
              }}
            >
              {t('Got it')}
            </Button>
          }
          isOpen
        />
      ) : null}
    </>
  )
}
