import GetAppIcon from '@mui/icons-material/GetApp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {IconButton} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

interface IMoreMenuProps {
  onPaymentsListCsvButtonClick: (showSalesData?: boolean) => void
  onPaymentsListXlsxButtonClick: (extended?: boolean) => void
}

export const MoreMenu: React.FC<IMoreMenuProps> = ({
  onPaymentsListCsvButtonClick,
  onPaymentsListXlsxButtonClick
}: IMoreMenuProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handlePaymentsListCsvButtonClick = useCallback(
    (showSalesData?: boolean) => () => {
      onPaymentsListCsvButtonClick(showSalesData)
      closeMenu()
    },
    [closeMenu, onPaymentsListCsvButtonClick]
  )
  const handlePaymentsListXlsxButtonClick = useCallback(
    (extended?: boolean) => () => {
      onPaymentsListXlsxButtonClick(extended)
      closeMenu()
    },
    [onPaymentsListXlsxButtonClick, closeMenu]
  )
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {P([PermissionCode.ExportStatisticsFromDatagridAsExcelFile]) && (
          <>
            <MenuItem
              label={t('Payments list XLSX')}
              icon={<GetAppIcon />}
              onClick={handlePaymentsListXlsxButtonClick(false)}
            />
            <MenuItem
              label={t('Extended payments list XLSX')}
              icon={<GetAppIcon />}
              onClick={handlePaymentsListXlsxButtonClick(true)}
            />
          </>
        )}
        <MenuItem
          label={t('Payments list CSV')}
          icon={<GetAppIcon />}
          onClick={handlePaymentsListCsvButtonClick(false)}
        />
        <MenuItem
          label={t('Extended payments list CSV')}
          icon={<GetAppIcon />}
          onClick={handlePaymentsListCsvButtonClick(true)}
        />
      </Menu>
    </>
  )
}
