import {Box, IconButton, Tooltip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ReactNode} from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: theme.palette.common.black,
    width: 32,
    height: 32,
    borderRadius: 4,
    padding: 0,
    margin: 2
  },
  selected: {
    color: theme.palette.primary.main,
    background: '#E1BEE7',
    '&:hover': {
      background: '#E1BEE7'
    }
  }
}))

type PanelButtonBaseProps = Omit<IPanelButtonProps, 'tooltip'>

const PanelButtonBase: React.FC<PanelButtonBaseProps> = ({
  disabled,
  children,
  onClick,
  cypressId,
  selected
}: PanelButtonBaseProps) => {
  const classes = useStyles()

  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      cypress-id={cypressId}
      classes={{
        root: selected ? `${classes.root} ${classes.selected}` : classes.root
      }}
    >
      {children}
    </IconButton>
  )
}

interface IPanelButtonProps {
  disabled?: boolean
  children: ReactNode
  onClick?: () => void
  selected?: boolean
  tooltip?: string
  cypressId?: string
}

export const PanelButton: React.FC<IPanelButtonProps> = ({
  tooltip,
  ...rest
}: IPanelButtonProps) =>
  tooltip ? (
    <Tooltip title={tooltip}>
      <Box>
        <PanelButtonBase {...rest} />
      </Box>
    </Tooltip>
  ) : (
    <PanelButtonBase {...rest} />
  )
