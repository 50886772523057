import React, {SyntheticEvent} from 'react'

import {SplitButtonItem} from '../visual'
import {StateChangeModal, IOpenModalButtonProps} from './StateChangeModal'

export interface ISplitButtonModalItemProps {
  onSubmit: (e: SyntheticEvent) => Promise<any>
  // Used to make sure that a modal is not openned sooner that the form is being valid
  ensureCanOpenModal?: any
  modalHeader: string
  modalMessage: string
  modalSubmitLabel: string
  buttonLabel: string
  disabled?: boolean
}

export const SplitButtonModalItem: React.FC<ISplitButtonModalItemProps> = ({
  onSubmit,
  ensureCanOpenModal,
  modalHeader,
  modalMessage,
  modalSubmitLabel,
  buttonLabel,
  disabled = false
}: ISplitButtonModalItemProps) => {
  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <SplitButtonItem
        onClick={ensureCanOpenModal ? ensureCanOpenModal(onClick) : onClick}
        disabled={disabled}
      >
        {buttonLabel}
      </SplitButtonItem>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      submitButtonText={modalSubmitLabel}
      OpenModalButton={OpenModalButton}
      header={modalHeader}
      message={modalMessage}
    />
  )
}
