import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {WebsiteState} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useWebsiteParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {SplitButton} from '../../../visual'
import {useUpdateWebsite, useWebsiteForEdit} from './graphql'
import {IWebsiteForm, WebsiteFormField} from './types'
import {transformWebsiteFormDataToUpdateWebsiteInput} from './utils'
import {WebsiteForm} from './WebsiteForm'

const UPDATE_WEBSITE_FORM_ID = 'updateWebsiteForm'

interface IUpdateWebsiteDrawerProps {
  onExited: () => void
}

export const UpdateWebsiteDrawer: React.FC<IUpdateWebsiteDrawerProps> = ({
  onExited
}: IUpdateWebsiteDrawerProps) => {
  const {t} = useTranslation()
  const {websiteId} = useWebsiteParams()
  const updateWebsite = useUpdateWebsite()
  const {setShowBackdrop, addInfoNotification} = useMutationAssistanceHooks()
  const {data, loading, error} = useWebsiteForEdit(websiteId, isNaN(websiteId))
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const nextWebsiteState = useRef<WebsiteState | undefined>(undefined)
  const updateNextWebsiteState = useCallback(
    (state) => () => (nextWebsiteState.current = state),
    []
  )
  const handleSubmit = useCallback(
    async (formData: IWebsiteForm) => {
      try {
        setShowBackdrop(true)
        await updateWebsite({
          id: websiteId,
          input: transformWebsiteFormDataToUpdateWebsiteInput(
            formData,
            nextWebsiteState.current
          )
        })
        addInfoNotification(t('Website has been updated'))
        onExited()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      onExited,
      setShowBackdrop,
      t,
      updateWebsite,
      websiteId
    ]
  )
  useEffect(() => {
    if (!isNaN(websiteId)) {
      openDrawer()
    }
  }, [openDrawer, websiteId])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t('Error while loading website')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update website')}
          />
        }
        footer={
          <>
            {data?.website.state !== WebsiteState.Deleted && (
              <Button
                color="primary"
                startIcon={<DeleteIcon />}
                type="submit"
                form={UPDATE_WEBSITE_FORM_ID}
                onClick={updateNextWebsiteState(WebsiteState.Deleted)}
              >
                {t('Delete')}
              </Button>
            )}
            <SplitButton
              Options={[
                <MenuItem
                  key="save-as"
                  type="submit"
                  form={UPDATE_WEBSITE_FORM_ID}
                  component="button"
                  onClick={
                    data?.website.state === WebsiteState.Published
                      ? updateNextWebsiteState(WebsiteState.Draft)
                      : updateNextWebsiteState(WebsiteState.Published)
                  }
                >
                  {data?.website.state === WebsiteState.Published
                    ? t('Save as draft')
                    : t('Save as published')}
                </MenuItem>
              ]}
            >
              <SaveButton type="submit" form={UPDATE_WEBSITE_FORM_ID}>
                {t('Save')}
              </SaveButton>
            </SplitButton>
          </>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {data && (
          <WebsiteForm
            formId={UPDATE_WEBSITE_FORM_ID}
            onSubmit={handleSubmit}
            clients={[
              {
                ...data.website.client
              }
            ]}
            defaultValues={{
              [WebsiteFormField.ClientId]: String(data.website.client.id),
              [WebsiteFormField.ProjectFullPath]: data.website.projectFullPath,
              [WebsiteFormField.ProductionBranch]:
                data.website.productionBranch,
              [WebsiteFormField.PreviewUrl]:
                data.website.previewUrl || undefined,
              [WebsiteFormField.Name]: data.website.name,
              [WebsiteFormField.EnabledLocaleCodes]:
                data.website.enabledLocaleCodes,
              [WebsiteFormField.PrimaryDomain]: data.website.primaryDomain
            }}
            edit
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
