import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateProductTypeMutation,
  CreateProductTypeMutationVariables,
  ProductTypeQuery,
  ProductTypeQueryVariables,
  ProductTypesQuery,
  ProductTypesQueryVariables,
  UpdateProductTypeMutation,
  UpdateProductTypeMutationVariables
} from '../../../../__generated__/schema'

const PRODUCT_TYPE_FIELDS = gql`
  fragment ProductTypeFields on ProductType {
    id
    name
    abbreviation
    state
  }
`

const PRODUCT_TYPES = gql`
  query productTypes($state: ProductTypeState) {
    productTypes(state: $state) {
      ...ProductTypeFields
    }
  }
  ${PRODUCT_TYPE_FIELDS}
`

export const useGetProductTypes = (variables?: ProductTypesQueryVariables) =>
  useQuery<ProductTypesQuery, ProductTypesQueryVariables>(PRODUCT_TYPES, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_PRODUCT_TYPE = gql`
  mutation createProductType($input: CreateProductTypeInput!) {
    createProductType(input: $input) {
      id
    }
  }
`

export const useCreateProductType = () => {
  const [createProductType] =
    useMutation<CreateProductTypeMutation, CreateProductTypeMutationVariables>(
      CREATE_PRODUCT_TYPE
    )
  return useCallback(
    (variables: CreateProductTypeMutationVariables) =>
      createProductType({variables, refetchQueries: [{query: PRODUCT_TYPES}]}),
    [createProductType]
  )
}

const PRODUCT_TYPE = gql`
  query productType($id: Int!) {
    productType(id: $id) {
      ...ProductTypeFields
    }
  }
  ${PRODUCT_TYPE_FIELDS}
`

export const useGetProductType = (variables: ProductTypeQueryVariables) =>
  useQuery<ProductTypeQuery, ProductTypeQueryVariables>(PRODUCT_TYPE, {
    variables,
    fetchPolicy: 'network-only'
  })

const UPDATE_PRODUCT_TYPE = gql`
  mutation updateProductType($id: Int!, $input: UpdateProductTypeInput!) {
    updateProductType(id: $id, input: $input) {
      ...ProductTypeFields
    }
  }
  ${PRODUCT_TYPE_FIELDS}
`

export const useUpdateProductType = () => {
  const [updateProductType] =
    useMutation<UpdateProductTypeMutation, UpdateProductTypeMutationVariables>(
      UPDATE_PRODUCT_TYPE
    )
  return useCallback(
    (variables: UpdateProductTypeMutationVariables) =>
      updateProductType({variables}),
    [updateProductType]
  )
}
