import AddIcon from '@mui/icons-material/Add'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DoneIcon from '@mui/icons-material/Done'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded'
import {Button, Chip, IconButton} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {MouseEvent, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Gallery from 'react-photo-gallery'
import {useHistory} from 'react-router-dom'
import {
  GetLibraryShowImagesQuery,
  LibraryMediaState,
  PermissionCode,
  ShowImageType
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {BaseGalleryImage} from '../../../../common/BaseGalleryImage'
import {
  GalleryPreview,
  useGalleryPreview
} from '../../../../common/galleryPreview/GalleryPreview'
import {Menu, useMenu} from '../../../../common/Menu'
import {MenuItemWithConfirmationDialog} from '../../../../common/MenuItemWithConfirmationDialog'
import {ShowSection, useTranslateShowImageType} from '../../../../constants'
import {MediaPreviewHeader} from '../../Header'
import {getGalleryPhotos} from '../common'
import {
  useActivateLibraryImage,
  useDeleteLibraryImage,
  useRemoveImageFromLibraryShow
} from '../graphql'
import {ImageAccordion} from '../ImageAccordion'

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    justifyContent: 'flex-end'
  }
}))

interface ILibraryShowImageTypeAccordionProps {
  libraryShowImages: GetLibraryShowImagesQuery['libraryShow']['images']
  type: ShowImageType
  showId: number
  refetchLibraryShowImages: () => void
}

export const LibraryShowImageTypeAccordion: React.FC<ILibraryShowImageTypeAccordionProps> =
  ({
    libraryShowImages,
    type,
    showId,
    refetchLibraryShowImages
  }: ILibraryShowImageTypeAccordionProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const [expanded, setExpanded] = useState<ShowImageType | null>(null)
    const [selectedImageId, setSelectedImageId] =
      useState<number | undefined>(undefined)
    const selectedImage = libraryShowImages.find(
      ({id}) => id === selectedImageId
    )
    const {anchorEl, openMenu, closeMenu} = useMenu()
    const removeImageFromLibraryShow = useRemoveImageFromLibraryShow()
    const deleteLibraryImage = useDeleteLibraryImage()
    const activateLibraryImage = useActivateLibraryImage()
    const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
      useMutationAssistanceHooks()
    const history = useHistory()
    const handleAccordionChange = useCallback(
      (type: ShowImageType) =>
        (e: React.ChangeEvent<{}>, isExpanded: boolean) =>
          setExpanded(isExpanded ? type : null),
      []
    )
    const galleryPreviewPhotos = libraryShowImages.map(({id, url: src}) => ({
      id,
      src
    }))
    const translateShowImageType = useTranslateShowImageType()
    const galleryPreviewHookProps = useGalleryPreview(galleryPreviewPhotos)
    const classes = useStyles()
    const handleZoomButtonClick = useCallback(
      (id: number) => (e: MouseEvent) => {
        e.stopPropagation()
        galleryPreviewHookProps.openPreview(id)
      },
      [galleryPreviewHookProps]
    )
    const handleMoreButtonClick = useCallback(
      (id: number) => (e: MouseEvent) => {
        openMenu(e)
        setSelectedImageId(id)
      },
      [openMenu]
    )
    const handleRemoveImage = useCallback(async () => {
      if (selectedImageId) {
        try {
          setShowBackdrop(true)
          await removeImageFromLibraryShow({
            imageId: selectedImageId,
            showId
          })
          refetchLibraryShowImages()
          addInfoNotification(t('Image has been removed'))
          closeMenu()
          setSelectedImageId(undefined)
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while removing image from library show')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    }, [
      addInfoNotification,
      closeMenu,
      defaultErrorHandler,
      refetchLibraryShowImages,
      removeImageFromLibraryShow,
      selectedImageId,
      setShowBackdrop,
      showId,
      t
    ])
    const handleDeleteImage = useCallback(async () => {
      if (selectedImageId) {
        try {
          setShowBackdrop(true)
          await deleteLibraryImage({
            id: selectedImageId
          })
          refetchLibraryShowImages()
          addInfoNotification(t('Image has been deleted'))
          closeMenu()
          setSelectedImageId(undefined)
        } catch (error) {
          defaultErrorHandler(error, t('Error while deleting library image'))
        } finally {
          setShowBackdrop(false)
        }
      }
    }, [
      addInfoNotification,
      closeMenu,
      defaultErrorHandler,
      deleteLibraryImage,
      refetchLibraryShowImages,
      selectedImageId,
      setShowBackdrop,
      t
    ])
    const handleActivateImage = useCallback(async () => {
      if (selectedImageId) {
        try {
          setShowBackdrop(true)
          await activateLibraryImage({id: selectedImageId})
          refetchLibraryShowImages()
          addInfoNotification(t('Image has been activated'))
          closeMenu()
        } catch (error) {
          defaultErrorHandler(error, t('Error while activating library image'))
        } finally {
          setShowBackdrop(false)
        }
      }
    }, [
      activateLibraryImage,
      addInfoNotification,
      closeMenu,
      defaultErrorHandler,
      refetchLibraryShowImages,
      selectedImageId,
      setShowBackdrop,
      t
    ])
    const renderImage = useCallback(
      ({photo, margin}) => {
        const isImageDeleted =
          libraryShowImages.find(({id}) => id === photo.id)?.state ===
          LibraryMediaState.Deleted
        return (
          <BaseGalleryImage
            key={photo.id}
            photo={photo}
            margin={margin}
            openPreview={handleZoomButtonClick(photo.id)}
          >
            {(P([PermissionCode.RemoveImageFromLibraryShow]) ||
              P([PermissionCode.DeleteLibraryImage])) && (
              <IconButton
                onClick={handleMoreButtonClick(photo.id)}
                size="small"
                sx={(theme) => ({
                  position: 'absolute',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.25)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)'
                  },
                  top: theme.spacing(0.5),
                  right: theme.spacing(0.5)
                })}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {isImageDeleted && (
              <Chip
                sx={(theme) => ({
                  position: 'absolute',
                  bottom: theme.spacing(0.5),
                  left: theme.spacing(0.5)
                })}
                label={t('Deleted')}
                color="error"
              />
            )}
          </BaseGalleryImage>
        )
      },
      [P, handleMoreButtonClick, handleZoomButtonClick, libraryShowImages, t]
    )
    return (
      <>
        <ImageAccordion
          key={type}
          isExpanded={type === expanded}
          onAccordionChange={handleAccordionChange(type)}
          summaryDescription={t('{{count}} items', {
            count: libraryShowImages.length
          })}
          summaryTitle={translateShowImageType(type)}
        >
          {P([PermissionCode.UploadLibraryImage]) && (
            <div className={classes.header}>
              <Button
                startIcon={<AddIcon />}
                variant="text"
                color="primary"
                onClick={() =>
                  history.push(
                    routeTo.admin.library.uploadMedia(
                      showId,
                      ShowSection.Media,
                      type
                    )
                  )
                }
              >
                {t('Upload from computer')}
              </Button>
            </div>
          )}
          {galleryPreviewPhotos.length > 0 && (
            <Gallery
              photos={getGalleryPhotos(libraryShowImages)}
              renderImage={renderImage}
            />
          )}
          <GalleryPreview
            {...galleryPreviewHookProps}
            header={
              <MediaPreviewHeader
                title={t('Preview')}
                hasArrowBackIcon
                onLeftActionClick={galleryPreviewHookProps.closePreview}
              />
            }
          />
        </ImageAccordion>
        <Menu
          onClose={closeMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {P([PermissionCode.RemoveImageFromLibraryShow]) && (
            <MenuItemWithConfirmationDialog
              key="remove-image"
              onConfirmButtonClick={handleRemoveImage}
              menuItemProps={{
                label: t('Remove from this show'),
                icon: <RemoveCircleRoundedIcon />
              }}
              dialogProps={{
                title: t('Remove image from this show?'),
                contentText: t(
                  'Are you sure you want to remove this image from the gallery? Once removed, it will no longer be accessible to clients. However, clients who have already obtained this image will not be affected. This action cannot be undone.'
                ),
                confirmButtonLabel: t('Remove')
              }}
            />
          )}
          {P([PermissionCode.DeleteLibraryImage]) &&
            selectedImage?.state === LibraryMediaState.Active && (
              <MenuItemWithConfirmationDialog
                key="delete-image"
                onConfirmButtonClick={handleDeleteImage}
                menuItemProps={{
                  label: t('Delete image for all shows'),
                  icon: <DeleteRoundedIcon />
                }}
                dialogProps={{
                  title: t('Delete image from library?'),
                  contentText: t(
                    'Removing this image will make it unavailable in the gallery for all clients. Additionally, clients who previously accessed this image will no longer be able to use it. Please confirm if you wish to proceed with this irreversible action.'
                  ),
                  confirmButtonLabel: t('Delete')
                }}
              />
            )}
          {P([PermissionCode.ActivateLibraryImage]) &&
            selectedImage?.state === LibraryMediaState.Deleted && (
              <MenuItemWithConfirmationDialog
                key="activate-image"
                onConfirmButtonClick={handleActivateImage}
                menuItemProps={{
                  label: t('Activate image for all shows'),
                  icon: <DoneIcon />
                }}
                dialogProps={{
                  title: t('Activate image?'),
                  contentText: t(
                    'Activating this image will make it available in the gallery for all clients. Please confirm if you wish to proceed.'
                  ),
                  confirmButtonLabel: t('Activate')
                }}
              />
            )}
        </Menu>
      </>
    )
  }
