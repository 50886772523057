import React, {ReactNode, useContext, useMemo, createContext} from 'react'

import {useLocalStorageState} from '../../../../../hooks/storage'
import {ViewType} from '../calendar/types'

interface IEventCalendarViewContext {
  viewType: ViewType
  setViewType: (v: ViewType) => void
}

const Context = createContext({} as IEventCalendarViewContext)

type Props = {
  children: ReactNode
}

export const EventCalendarViewProvider: React.FC<Props> = ({
  children
}: Props) => {
  const [viewType, setViewType] = useLocalStorageState<ViewType>(
    'events-calendar-view',
    ViewType.Week
  )

  const context = useMemo(
    () => ({
      viewType,
      setViewType
    }),
    [setViewType, viewType]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useEventCalendarView = () => useContext(Context)
