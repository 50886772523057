import {makeStyles} from '@mui/styles'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  LeadField,
  LeadOptionPropertiesFragment
} from '../../../__generated__/schema'
import {LeadSection, useTranslateLeadSection} from '../../../hooks/leadSection'
import {Theme} from '../../../theme'
import {InputBlockWithoutSpacings} from '../FormHelpers'
import {LeadOptionInput} from './LeadOptionInput'
import {ILeadSettingsForm, LeadOptionInputFormField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  inputBlockContent: {
    padding: theme.spacing(2.5, 2)
  },
  leadOptionInput: {
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

interface ILeadSettingsFormProps {
  className?: string
  formId: string
  leadOptions: LeadOptionPropertiesFragment[]
  onSubmit: (form: ILeadSettingsForm) => void
}

export const LeadSettingsForm: React.FC<ILeadSettingsFormProps> = ({
  className,
  leadOptions,
  formId,
  onSubmit
}: ILeadSettingsFormProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateLeadSection = useTranslateLeadSection()
  const {setValue, errors, register, watch, triggerValidation, handleSubmit} =
    useForm<ILeadSettingsForm>({
      defaultValues: leadOptions.reduce(
        (acc, option) => ({
          ...acc,
          [option.field]: {
            [LeadOptionInputFormField.ID]: String(option.id),
            [LeadOptionInputFormField.INPUT_STATUS]: option.inputStatus,
            [LeadOptionInputFormField.HELPER_TEXT]: option.helperText || null
          }
        }),
        {}
      )
    })
  return (
    <form
      className={className}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      id={formId}
    >
      <InputBlockWithoutSpacings
        header={translateLeadSection(LeadSection.LEAD)}
        blockId={LeadSection.LEAD}
      >
        <div className={classes.inputBlockContent}>
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.Name}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.Phone}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.Email}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.InternalNote}
            note={t('Customer will never see this')}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.Note}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.IsPrivacyPolicyConsentGranted}
          />
        </div>
      </InputBlockWithoutSpacings>
      <InputBlockWithoutSpacings
        header={translateLeadSection(LeadSection.COMPANY)}
        blockId={LeadSection.COMPANY}
      >
        <div className={classes.inputBlockContent}>
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.CompanyName}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.CompanyIdNumber}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.VatId}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.TaxId}
          />
        </div>
      </InputBlockWithoutSpacings>
      <InputBlockWithoutSpacings
        header={translateLeadSection(LeadSection.BILLING_ADDRESS)}
        blockId={LeadSection.BILLING_ADDRESS}
      >
        <div className={classes.inputBlockContent}>
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.BillingAddressStreet}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.BillingAddressTown}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.BillingPostalCode}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.BillingAddressCountry}
          />
        </div>
      </InputBlockWithoutSpacings>
      <InputBlockWithoutSpacings
        header={translateLeadSection(LeadSection.DELIVERY_ADDRESS)}
        blockId={LeadSection.DELIVERY_ADDRESS}
      >
        <div className={classes.inputBlockContent}>
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.DeliveryAddressee}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.DeliveryAddressStreet}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.DeliveryAddressTown}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.DeliveryPostalCode}
          />
          <LeadOptionInput
            className={classes.leadOptionInput}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
            triggerValidation={triggerValidation}
            leadField={LeadField.DeliveryAddressCountry}
          />
        </div>
      </InputBlockWithoutSpacings>
    </form>
  )
}
