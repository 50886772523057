import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CheckoutOptions} from '../../../../../__generated__/schema'
import {Blank, BlankContentPosition} from '../../../../visual/Blank'
import {useCurrentCart} from '../CurrentCartContext'
import {cartSatisfiesExpectedCheckoutOption} from './cartSatisfiesExpectedCheckoutOption'

interface IChildrenOnAvailableReservationTabProps {
  children: React.ReactNode
  expectedCheckoutOption: CheckoutOptions
}

export const ChildrenOnValidCartCheckoutOptions: React.FC<IChildrenOnAvailableReservationTabProps> =
  ({
    children,
    expectedCheckoutOption
  }: IChildrenOnAvailableReservationTabProps) => {
    const {t} = useTranslation()
    const {currentCart} = useCurrentCart()

    return !cartSatisfiesExpectedCheckoutOption(
      currentCart,
      expectedCheckoutOption
    ) ? (
      <Blank
        contentPosition={BlankContentPosition.Center}
        IconComp={ReportProblemOutlinedIcon}
        title={
          expectedCheckoutOption === CheckoutOptions.Reservation
            ? t('Payment allowed only')
            : t('Reservation allowed only')
        }
        description={
          expectedCheckoutOption === CheckoutOptions.Reservation
            ? t(
                'Cart can’t be reserved, due to settings of events added to cart. If you want to reserve this cart, remove all events, that can be paid only.'
              )
            : t(
                'Cart can’t be paid, due to settings of events added to cart. If you want to get paid for cart, remove all events, that can be reserved only.'
              )
        }
      />
    ) : (
      <>{children}</>
    )
  }
