import AddIcon from '@mui/icons-material/Add'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  useForm
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  BasicRoleFieldsFragment,
  LocaleCode,
  RoleType,
  TranslatedInput
} from '../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateRoleType} from '../../../../hooks/translateRoleType'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {
  EntityStateChip,
  InputBlock,
  InputBlockWithoutSpacings,
  InputRow
} from '../../../common'
import {DividerSeparatedInfoRow} from '../../../common/DividerSeparatedInfoRow'
import {COLOR_CONF} from '../../../constants'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormFieldName} from '../../../form/types'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {AddPermissionDialog} from './AddPermissionDialog'
import {FormType, useRoleFormAnchors} from './useRoleFormAnchors'

export enum RoleField {
  Type = 'type',
  Names = 'names',
  Descriptions = 'descriptions',
  PermissionCodes = 'permissionCodes'
}

export interface IRoleForm {
  [RoleField.Type]: RoleType
  [RoleField.Names]: TranslatedInput
  [RoleField.Descriptions]: TranslatedInput
  [RoleField.PermissionCodes]: string[]
}

const useOverviewStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1)
  },
  state: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'baseline',
    gap: theme.spacing(1)
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

interface IOverviewProps {
  role: BasicRoleFieldsFragment
}

const Overview: React.FC<IOverviewProps> = ({role}: IOverviewProps) => {
  const {t} = useTranslation()
  const translateRoleType = useTranslateRoleType()
  const {formatDateTime} = useDateTimeFormatters()
  const localeCode = useClientLocaleCode()
  const classes = useOverviewStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h6">{role.names[localeCode]}</Typography>
      <div className={classes.state}>
        <EntityStateChip
          label={role.isDeprecated ? t('Deprecated') : t('Enabled')}
          colorConf={role.isDeprecated ? COLOR_CONF.RED : COLOR_CONF.GREEN}
        />
        <Typography variant="caption" color="textSecondary">
          {role.isDeprecated
            ? t(
                'Is not allowed to add role to any user, but can be removed from user roles.'
              )
            : t('Role can be added to user.')}
        </Typography>
      </div>
      {role.descriptions[localeCode] && (
        <div className={classes.description}>
          <Typography variant="caption" color="textSecondary">
            {t('Description')}
          </Typography>
          <Typography variant="body2">
            {role.descriptions[localeCode]}
          </Typography>
        </div>
      )}
      <DividerSeparatedInfoRow
        information={[
          {caption: t('Type'), value: translateRoleType(role.type)},
          {
            caption: t('Users'),
            value: role.users.length
          },
          {caption: t('Permissions'), value: role.permissionCodes.length},
          {
            caption: t('Created at'),
            value: formatDateTime(new Date(role.createdAt))
          },
          {
            caption: t('Updated at'),
            value: formatDateTime(new Date(role.updatedAt))
          },
          {
            caption: t('Updated by'),
            value: role.updatedByName
          }
        ]}
      />
    </div>
  )
}

interface ITranslationFieldsProps<
  FormValues extends FieldValues = FieldValues
> {
  setValue: FormContextValues<FormValues>['setValue']
  triggerValidation: FormContextValues<FormValues>['triggerValidation']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  errors: FieldErrors<FormValues>
  locale: LocaleCode
}

const TranslationFields = <FormValues extends FieldValues = FieldValues>({
  setValue,
  locale,
  triggerValidation,
  watch,
  register,
  errors
}: ITranslationFieldsProps<FormValues>) => {
  const {t} = useTranslation()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  return (
    <>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<FormValues>
            key={locale}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            name={`${RoleField.Names}[${locale}]` as FormFieldName<FormValues>}
            label={t('Label')}
            fullWidth
            required
          />
        ]}
        spacing={0}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<FormValues>
            key={locale}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={
              `${RoleField.Descriptions}[${locale}]` as FormFieldName<FormValues>
            }
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            label={t('Description')}
            fullWidth
          />
        ]}
        spacing={0}
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    paddingBottom: theme.spacing(4)
  },
  listItem: {
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 0
    }
  },
  noPermissions: {
    padding: theme.spacing(3)
  }
}))

interface IRoleFormProps {
  formId: string
  onSubmit: (data: IRoleForm) => void
  defaultValues?: Partial<IRoleForm>
  formType: FormType
  role?: BasicRoleFieldsFragment
}

export const RoleForm: React.FC<IRoleFormProps> = ({
  formId,
  onSubmit,
  defaultValues = {},
  formType,
  role
}: IRoleFormProps) => {
  const {t} = useTranslation()
  const {
    setValue,
    errors,
    register,
    unregister,
    watch,
    triggerValidation,
    handleSubmit
  } = useForm<IRoleForm>({
    defaultValues
  })
  useEffect(() => {
    register(RoleField.PermissionCodes)
    return () => unregister(RoleField.PermissionCodes)
  }, [register, unregister])
  const {
    state: isAddPermissionDialogOpen,
    setTrue: openAddPermissionDialog,
    setFalse: closeAddPermissionDialog
  } = useBooleanState(false)
  const permissionCodes = watch(RoleField.PermissionCodes)
  const handleAddButtonClick = useCallback(
    (selectedPermissions: string[]) => {
      setValue(RoleField.PermissionCodes, selectedPermissions)
      closeAddPermissionDialog()
    },
    [closeAddPermissionDialog, setValue]
  )
  const handleRemoveIconClick = useCallback(
    (permission: string) => () => {
      setValue(
        RoleField.PermissionCodes,
        permissionCodes.filter((p) => p !== permission)
      )
    },
    [permissionCodes, setValue]
  )
  const translateRoleType = useTranslateRoleType()
  const roleFormAnchors = useRoleFormAnchors(formType)
  const classes = useStyles()
  return (
    <form
      noValidate
      autoComplete="off"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.form}
    >
      <InputBlock
        header={roleFormAnchors.firstItem.label}
        blockId={roleFormAnchors.firstItem.id}
      >
        {formType === FormType.Create ? (
          <InputRow
            nodes={[
              <UncontrolledFormSelect<IRoleForm>
                key={RoleField.Type}
                label={t('Type')}
                selectOptions={Object.values(RoleType).reduce(
                  (acc, role) => ({
                    ...acc,
                    [role]: translateRoleType(role)
                  }),
                  {}
                )}
                errors={errors}
                setValue={setValue}
                register={register}
                watch={watch}
                name={RoleField.Type}
                validationOptions={{
                  required: true
                }}
                required
                fullWidth
              />
            ]}
            spacing={0}
          />
        ) : (
          role && <Overview role={role} />
        )}
      </InputBlock>
      <InputBlock
        header={roleFormAnchors.english.label}
        blockId={roleFormAnchors.english.id}
      >
        <TranslationFields
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          register={register}
          errors={errors}
          locale={LocaleCode.En}
        />
      </InputBlock>
      <InputBlock
        header={roleFormAnchors.slovak.label}
        blockId={roleFormAnchors.slovak.id}
      >
        <TranslationFields
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          register={register}
          errors={errors}
          locale={LocaleCode.Sk}
        />
      </InputBlock>
      <InputBlock
        header={roleFormAnchors.czech.label}
        blockId={roleFormAnchors.czech.id}
      >
        <TranslationFields
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          register={register}
          errors={errors}
          locale={LocaleCode.Cs}
        />
      </InputBlock>
      <InputBlock
        header={roleFormAnchors.hungarian.label}
        blockId={roleFormAnchors.hungarian.id}
      >
        <TranslationFields
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          register={register}
          errors={errors}
          locale={LocaleCode.Hu}
        />
      </InputBlock>
      <InputBlockWithoutSpacings
        header={roleFormAnchors.permissions.label}
        blockId={roleFormAnchors.permissions.id}
        headerRightAction={
          <Button
            startIcon={<AddIcon />}
            onClick={openAddPermissionDialog}
            variant="text"
            color="primary"
          >
            {t('Add')}
          </Button>
        }
      >
        {permissionCodes.length > 0 ? (
          <List>
            {permissionCodes.map((permission) => (
              <ListItem className={classes.listItem} key={permission}>
                <ListItemIcon>
                  <IconButton onClick={handleRemoveIconClick(permission)}>
                    <RemoveCircleOutlineIcon color="primary" />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={permission}
                  primaryTypographyProps={{variant: 'body2'}}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={classes.noPermissions}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('No permissions added')}
            </Typography>
          </div>
        )}
      </InputBlockWithoutSpacings>
      <AddPermissionDialog
        isOpen={isAddPermissionDialogOpen}
        onClose={closeAddPermissionDialog}
        addedPermissions={permissionCodes}
        onAddButtonClick={handleAddButtonClick}
      />
    </form>
  )
}
