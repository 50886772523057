import ClientIcon from '@mui/icons-material/AccountCircle'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientType,
  ClientVatRegistered,
  Currency,
  ExistingCountryCode
} from '../../../../__generated__/schema'
import {useClientFormAnchors} from '../../../../hooks/clientFormAnchors'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {
  BottomBackButton,
  EntityCard,
  SingleSideNavigationList
} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {SecondaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {
  CLIENT_FORM_ID,
  ClientForm,
  clientFromClientForm,
  IClientFormData
} from './ClientForm'
import {useCreateClient} from './graphql'

export const AddClient: React.FC = () => {
  const createClient = useCreateClient()
  const {t} = useTranslation()
  const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const history = useHistory()
  const clientFormAnchors = useClientFormAnchors()

  const onSubmit = useCallback(
    async (formData: IClientFormData) => {
      try {
        setShowBackdrop(true)
        const {data} = await createClient({
          data: clientFromClientForm(formData)
        })
        if (data) {
          history.push(routeTo.admin.clients.edit(data.createClient.id))
        } else {
          history.push(routeTo.admin.clients.home())
        }
        addInfoNotification(t('Client created'))
      } catch (err) {
        defaultErrorHandler(err, t('Error while creating the client'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createClient,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t
    ]
  )

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Create client')} />}
      footer={
        <Footer>
          <BottomBackButton />
          <SaveButton type="submit" form={CLIENT_FORM_ID}>
            {t('Create')}
          </SaveButton>
        </Footer>
      }
    >
      <CenteredLayout>
        <SingleSideNavigationList items={clientFormAnchors} />
        <CenteredLayoutListWrapper>
          <EntityCard header={t('New client')} Icon={ClientIcon} />
          <ClientForm
            onSubmit={onSubmit}
            defaultValues={{
              currency: Currency.Eur,
              countryCode: ExistingCountryCode.Sk,
              type: ClientType.Distributor,
              VATRegistered: ClientVatRegistered.None
            }}
          />
        </CenteredLayoutListWrapper>
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
