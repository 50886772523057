import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AuditoriumLayoutType} from '../../../../../__generated__/schema'
import {PRIMARY_50_COLOR} from '../../../../../theme'
import {useIsStringWithMaxLength} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {FormFieldName} from '../../../../form/types'

export const CREATE_AUDITORIUM_LAYOUT_FORM_ID = 'auditorium layout form'

export enum FormField {
  NAME = 'name',
  TYPE = 'type'
}

export interface IAuditoriumLayoutForm {
  [FormField.NAME]: string
  [FormField.TYPE]: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3)
  },
  radioGroup: {
    gap: theme.spacing(1)
  },
  formControlLabelRoot: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 0.5),
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    }
  },
  formControlLabelRootSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: PRIMARY_50_COLOR
  },
  labelBox: {
    paddingLeft: theme.spacing(0.5)
  }
}))

interface IAddAuditoriumLayoutFormProps<
  FormValues extends FieldValues = FieldValues
> {
  onSubmit: (data: FormValues) => void
  setValue: FormContextValues<FormValues>['setValue']
  triggerValidation: FormContextValues<FormValues>['triggerValidation']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  handleSubmit: FormContextValues<FormValues>['handleSubmit']
}

export const AddAuditoriumLayoutForm: React.FC<IAddAuditoriumLayoutFormProps> =
  <FormValues extends FieldValues = FieldValues>({
    setValue,
    control,
    triggerValidation,
    watch,
    register,
    errors,
    onSubmit,
    handleSubmit
  }: IAddAuditoriumLayoutFormProps<FormValues>) => {
    const {t} = useTranslation()

    const isStringWithMaxLength = useIsStringWithMaxLength(255)
    const classes = useStyles()
    const selectedType = watch(FormField.TYPE)

    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        id={CREATE_AUDITORIUM_LAYOUT_FORM_ID}
      >
        <div className={classes.root}>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<FormValues>
                key={FormField.NAME}
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={FormField.NAME as FormFieldName<FormValues>}
                validationOptions={{
                  required: true,
                  validate: isStringWithMaxLength
                }}
                label={t('Name', {context: 'object'})}
                required
                fullWidth
              />
            ]}
          />
          <div>
            <Typography variant="body1">{t('Type')}</Typography>
            <Typography variant="caption" color="textSecondary">
              {t('*Required')}
            </Typography>
          </div>
          <Controller
            as={(props) => (
              <RadioGroup {...props} classes={{root: classes.radioGroup}}>
                <FormControlLabel
                  value={AuditoriumLayoutType.SeatingFloorPlan}
                  label={
                    <div className={classes.labelBox}>
                      <Typography variant="subtitle2">
                        {t('Seating plan')}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {t(
                          'Create auditorium layout with seats, zones and various objects.'
                        )}
                      </Typography>
                    </div>
                  }
                  control={<Radio color="primary" />}
                  classes={{
                    root: cn(classes.formControlLabelRoot, {
                      [classes.formControlLabelRootSelected]:
                        selectedType === AuditoriumLayoutType.SeatingFloorPlan
                    })
                  }}
                />
                <FormControlLabel
                  value={AuditoriumLayoutType.ZoneFloor}
                  label={
                    <div className={classes.labelBox}>
                      <Typography variant="subtitle2">
                        {t('Zone plan')}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {t(
                          'Create auditorium layout with zones and various objects.'
                        )}
                      </Typography>
                    </div>
                  }
                  control={<Radio color="primary" />}
                  classes={{
                    root: cn(classes.formControlLabelRoot, {
                      [classes.formControlLabelRootSelected]:
                        selectedType === AuditoriumLayoutType.ZoneFloor
                    })
                  }}
                />
              </RadioGroup>
            )}
            name={FormField.TYPE}
            control={control}
            rules={{required: true}}
          />
        </div>
      </form>
    )
  }
