import {
  FooterItem,
  FooterItemGroups,
  LocaleCode,
  TypifiedIconModel,
  TypographyVariant
} from '../../../../__generated__/schema'

export enum WebsiteFormField {
  ClientId = 'clientId',
  ProjectFullPath = 'projectFullPath',
  ProductionBranch = 'productionBranch',
  PreviewUrl = 'previewUrl',
  Name = 'name',
  EnabledLocaleCodes = 'enabledLocaleCodes',
  PrimaryDomain = 'primaryDomain'
}

export interface IWebsiteForm {
  [WebsiteFormField.ClientId]: string
  [WebsiteFormField.ProjectFullPath]: string
  [WebsiteFormField.ProductionBranch]: string
  [WebsiteFormField.PreviewUrl]?: string
  [WebsiteFormField.Name]: string
  [WebsiteFormField.EnabledLocaleCodes]: LocaleCode[]
  [WebsiteFormField.PrimaryDomain]?: string
}

export enum WebsiteEditorContent {
  Pages = 'pages',
  Blocks = 'blocks',
  Assets = 'assets'
}

export enum WebsitePageFormField {
  Title = 'title',
  Slug = 'slug',
  Description = 'description',
  LocaleCode = 'localeCode'
}

export interface IWebsitePageForm {
  [WebsitePageFormField.Title]: string
  [WebsitePageFormField.Slug]: string
  [WebsitePageFormField.Description]?: string
  [WebsitePageFormField.LocaleCode]: LocaleCode
}

export enum BlockType {
  Header = 'header',
  EventsTable = 'eventsTable',
  Label = 'label',
  FeaturedEventPosters = 'featuredEventPosters',
  FeaturedShowPosters = 'featuredShowPosters',
  FeaturedShowCovers = 'featuredShowCovers',
  ShowDetail = 'showDetail',
  Footer = 'footer',
  Markdown = 'markdown',
  RawHtml = 'rawHtml',
  FeaturedShowPhotos = 'featuredShowPhotos',
  ButtonGroup = 'buttonGroup',
  EventsList = 'eventsList',
  TimeSlotsTable = 'timeSlotsTable',
  CardsRow = 'cardsRow'
}

export enum WebsitePageMode {
  Editing = 'editing',
  Designing = 'designing'
}

export type FooterItemWithId = FooterItem & {
  id: string
}

export type ItemGroupWithId = Pick<
  FooterItemGroups,
  'alignment' | '__typename'
> & {
  id: string
  items: FooterItemWithId[]
}

export enum AddMenuItem {
  Page = 'page',
  Folder = 'folder',
  ExternalLink = 'externalLink',
  Label = 'label'
}

export enum ItemFormField {
  Label = 'label',
  Page = 'page',
  OpenInNewTab = 'openInNewTab',
  Type = 'type',
  Variant = 'variant',
  Icon = 'icon'
}

export interface IItemForm {
  [ItemFormField.Label]: string
  [ItemFormField.Page]?: string
  [ItemFormField.OpenInNewTab]?: boolean
  [ItemFormField.Type]: AddMenuItem
  [ItemFormField.Variant]?: TypographyVariant
  [ItemFormField.Icon]?: TypifiedIconModel
}

export enum SelectFooterFormField {
  Id = 'id'
}

export interface ISelectFooterForm {
  [SelectFooterFormField.Id]: string
}

export enum SelectHeaderFormField {
  Id = 'id'
}

export interface ISelectHeaderForm {
  [SelectHeaderFormField.Id]: string
}
