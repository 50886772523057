import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {FormFieldHelper} from '../../../visual/common'

const useStyles = makeStyles(() => ({
  select: {
    width: '100%'
  }
}))

interface IDummySelectProps {
  label?: string
  className?: string
  items: {[key: string]: React.ReactNode}
  defaultValue?: string
  showRequiredLabel?: boolean
  note?: string
  isDisabled: boolean
}

export const DummySelect: React.FC<IDummySelectProps> = ({
  label,
  className,
  items,
  defaultValue,
  showRequiredLabel,
  note,
  isDisabled
}: IDummySelectProps) => {
  const classes = useStyles()

  return (
    <FormControl className={cn(classes.select, className)} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select label={label} defaultValue={defaultValue} disabled={isDisabled}>
        {Object.entries(items).map(([value, node]) => (
          <MenuItem key={value} value={value}>
            {node}
          </MenuItem>
        ))}
      </Select>
      <FormFieldHelper required={showRequiredLabel} note={note} />
    </FormControl>
  )
}
