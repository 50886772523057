import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useCreateTemplate} from './graphql'
import {TemplateForm} from './TemplateForm'
import {ITemplateForm} from './types'

const CREATE_TEMPLATE_FORM_ID = 'createTemplateForm'

interface ICreateTemplateDrawerProps {
  onClose: () => void
}

export const CreateTemplateDrawer: React.FC<ICreateTemplateDrawerProps> = ({
  onClose
}: ICreateTemplateDrawerProps) => {
  const {t} = useTranslation()
  const createTemplate = useCreateTemplate()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const history = useHistory()
  const handleSubmit = useCallback(
    async (data: ITemplateForm) => {
      try {
        setShowBackdrop(true)
        const {data: templateData} = await createTemplate({input: data})
        if (templateData) {
          history.push(
            routeTo.admin.templates.edit(templateData.createTemplate.id)
          )
          addInfoNotification(t('Template was created'))
        }
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating template'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createTemplate,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t
    ]
  )
  return (
    <DrawerTemplate
      header={
        <DrawerTemplateHeader
          onLeftActionClick={onClose}
          title={t('Create template')}
        />
      }
      footer={
        <SaveButton type="submit" form={CREATE_TEMPLATE_FORM_ID}>
          {t('Create')}
        </SaveButton>
      }
    >
      <TemplateForm formId={CREATE_TEMPLATE_FORM_ID} onSubmit={handleSubmit} />
    </DrawerTemplate>
  )
}
