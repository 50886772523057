import {ShowTypeCode} from '../../../../__generated__/schema'
import {useTranslateGenre} from '../../../../hooks/showGenres'
import {useTranslateShowType} from '../../../../hooks/showTypes'
import {useTranslateAgeClassification} from '../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../../../../hooks/translateDistributions'
import {useTranslateShowContentDescriptorCode} from '../../../../hooks/translateShowContentDescriptorCode'
import {
  useFetchShowSelectFields,
  useShowContentDescriptorCodes
} from './graphql'
import {ShowFormLocation} from './types'

const useGetSelectFields = () => {
  const {data, error} = useFetchShowSelectFields()

  if (!data || error) {
    throw new Error('Shows data should be pre-loaded.')
  }
  return data.showTypes || []
}

export const useShowTypesSelectFields = () => {
  const showTypes = useGetSelectFields()
  const translateShowType = useTranslateShowType()
  return showTypes.reduce(
    (acc, {code}) => ({
      ...acc,
      [code]: translateShowType(code)
    }),
    {}
  )
}

export const useAgeClassificationSelectFields = (
  showTypeCode: ShowTypeCode
) => {
  const showTypes = useGetSelectFields()
  const translateAgeClassification = useTranslateAgeClassification()
  const ageClassificationCodes =
    showTypes.find((showType) => showType.code === showTypeCode)
      ?.ageClassificationCodes || []
  return ageClassificationCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: translateAgeClassification(code)
    }),
    {}
  )
}

export const useGenreSelectFields = (showTypeCode: ShowTypeCode) => {
  const showTypes = useGetSelectFields()
  const translateGenre = useTranslateGenre()
  const genreCodes =
    showTypes.find((showType) => showType.code === showTypeCode)?.genreCodes ||
    []
  return genreCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: translateGenre(code)
    }),
    {}
  )
}

export const useFormatSelectFields = (showTypeCode: ShowTypeCode) => {
  const showTypes = useGetSelectFields()
  const translateShowFormat = useTranslateShowFormat()
  const formatCodes =
    showTypes.find((showType) => showType.code === showTypeCode)?.formatCodes ||
    []
  return formatCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: translateShowFormat(code)
    }),
    {}
  )
}

export const useVersionSelectFields = (showTypeCode: ShowTypeCode) => {
  const showTypes = useGetSelectFields()
  const translateShowVersion = useTranslateShowVersion()
  const versionCodes =
    showTypes.find((showType) => showType.code === showTypeCode)
      ?.versionCodes || []
  return versionCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: translateShowVersion(code)
    }),
    {}
  )
}

export const useSoundMixSelectFields = (showTypeCode: ShowTypeCode) => {
  const showTypes = useGetSelectFields()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const soundMixCodes =
    showTypes.find((showType) => showType.code === showTypeCode)
      ?.soundMixCodes || []
  return soundMixCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: translateShowSoundMix(code)
    }),
    {}
  )
}

export const useShowContentDescriptorCodeFields = (
  location: ShowFormLocation
) => {
  const {data, loading, error} = useShowContentDescriptorCodes()
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  return {
    showContentDescriptorCodeFields: (
      data?.showContentDescriptorCodes || []
    ).reduce(
      (acc, descriptor) => ({
        ...acc,
        [descriptor]:
          location === ShowFormLocation.Library
            ? `${translateShowContentDescriptorCode(descriptor)} (${descriptor
                .split('_')[0]
                .toUpperCase()})`
            : translateShowContentDescriptorCode(descriptor)
      }),
      {}
    ),
    loading,
    error
  }
}
