import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs, {Dayjs} from 'dayjs'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DetailEventPropertiesFragment,
  EventQuery,
  EventState,
  ShowAgeClassificationCode,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowVersionCode,
  TranslatedInput
} from '../../../../../../../__generated__/schema'
import {useClientLocaleSelectData} from '../../../../../../../hooks/clientLocaleSelectData'
import {Theme} from '../../../../../../../theme'
import {useDefaultErrorHandler} from '../../../../../../../utils/errors'
import {useEventsPathnameParams} from '../../../../../../../utils/pathname'
import {routeTo} from '../../../../../../../utils/routes'

import {
  DrawerActionsBar,
  DrawerForm,
  InputOuterCardBlock,
  InputRow,
  RelativeSideNavigation,
  RenderOnData
} from '../../../../../../common'
import {HEIGHT as DRAWER_HEADER_HEIGHT} from '../../../../../../common/DrawerUtils'
import {useBackdropState} from '../../../../../../context/backdrop'
import {useNotifications} from '../../../../../../context/notifications'
import {FormDatetimeInput} from '../../../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../../../form/UncontrolledFormSelect'
import {
  DisabledFormWrapper,
  FormInput,
  ValidationError
} from '../../../../../../visual'
import {
  useAgeClassificationSelectFields,
  useFormatSelectFields,
  useSoundMixSelectFields,
  useVersionSelectFields
} from '../../../../shows/getSelectFields'
import {useGetEvent, useUpdateGeneralEventData} from '../../../graphql'

import {
  useCommonFormStyles,
  useDrawerHeaderTitle,
  useGetBackToGeneralInfoLink
} from '../../common'
import {AddressInfo} from './AddressInfo'
import {SelectInputRow} from './common'
import {LogInfo} from './LogInfo'

const useStyles = makeStyles<Theme>((theme) => ({
  formGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formGroupFirst: {
    marginBottom: theme.spacing(2)
  },
  additionalFieldsHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  additionalFieldsItem: {
    padding: theme.spacing(1, 3)
  },
  additionalFieldsNode: {
    padding: '0 !important'
  },
  title: {
    padding: theme.spacing(1, 0)
  }
}))

const EVENT_GENERAL_SETTINGS_FORM_ID = 'event general settings form'

interface IGeneralEventInfoFormProps {
  width: number
  elementToScrollOn: Element | null
}

const useEventGeneralOptionsAnchors = () => {
  const {t} = useTranslation()
  return {
    eventName: {
      id: 'eventName',
      label: t('Event name')
    },
    time: {
      id: 'time',
      label: t('Time')
    },
    additional: {
      id: 'additional',
      label: t('Additional')
    },
    venue: {
      id: 'venue',
      label: t('Venue')
    },
    log: {
      id: 'log',
      label: t('Log')
    }
  }
}

enum FormField {
  NAMES = 'names',
  STARTS_AT = 'startsAt',
  SERVICE_TIME = 'serviceTime',
  DURATION = 'duration',
  VERSION = 'version',
  FORMAT = 'format',
  SOUND_MIX = 'soundMix',
  AGE_CLASSIFICATION = 'ageClassification'
}

interface IGeneralEventData {
  names: TranslatedInput
  [FormField.STARTS_AT]: Dayjs
  serviceTime: string
  duration: string
  version: string
  format: string
  soundMix: string
  ageClassification: string
}

interface IGeneralEventInfoFormContentProps extends IGeneralEventInfoFormProps {
  data: DetailEventPropertiesFragment
  onSubmit: (data: IGeneralEventData) => Promise<void>
}

const GeneralEventInfoFormContent: React.FC<IGeneralEventInfoFormContentProps> =
  ({
    width,
    elementToScrollOn,
    data,
    onSubmit
  }: IGeneralEventInfoFormContentProps) => {
    const commonClasses = useCommonFormStyles()
    const classes = useStyles()
    const {t} = useTranslation()
    const history = useHistory()
    const backLink = useGetBackToGeneralInfoLink()

    const onClose = () => history.replace(backLink)
    const sideMenuItems = useEventGeneralOptionsAnchors()

    const clientLocaleSelectData = useClientLocaleSelectData()

    const {
      register,
      handleSubmit,
      errors,
      setValue,
      watch,
      unregister,
      clearError,
      control,
      setError
    } = useForm<IGeneralEventData>({
      defaultValues: {
        [FormField.NAMES]: data.names,
        [FormField.SERVICE_TIME]: `${data.serviceTime}`,
        [FormField.DURATION]: `${data.duration}`,
        [FormField.STARTS_AT]: dayjs(data.startsAt),
        [FormField.VERSION]: data.versionCode || '',
        [FormField.FORMAT]: data.formatCode || '',
        [FormField.SOUND_MIX]: data.soundMixCode || '',
        [FormField.AGE_CLASSIFICATION]: data.ageClassificationCode || ''
      }
    })
    const {typeCode} = data.show
    const formatSelectFields = useFormatSelectFields(typeCode)
    const versionSelectFields = useVersionSelectFields(typeCode)
    const soundMixSelectFields = useSoundMixSelectFields(typeCode)
    const ageClassificationSelectFields =
      useAgeClassificationSelectFields(typeCode)

    const drawerHeaderTitle = useDrawerHeaderTitle()

    return (
      <DrawerForm
        title={drawerHeaderTitle}
        wrapperClassName={commonClasses.drawerFormWrapper}
        bodyClassName={commonClasses.drawerForm}
        LeftActionIcon={ArrowBackIcon}
        width={width}
        onClose={onClose}
        noWrap
        ActionBar={
          <DrawerActionsBar
            {...{width, onClose}}
            formId={EVENT_GENERAL_SETTINGS_FORM_ID}
            submitText={t('Save')}
            cancelText={t('Back')}
          />
        }
      >
        <Grid container>
          <div className={commonClasses.stickyNavigationWrapper}>
            <RelativeSideNavigation
              items={sideMenuItems}
              scrollOffset={DRAWER_HEADER_HEIGHT}
              elementToScrollOn={elementToScrollOn}
            />
          </div>
          <div className={commonClasses.formWrapper}>
            <Typography
              className={classes.title}
              variant="h6"
              color="textPrimary"
            >
              {t('General')}
            </Typography>
            <form
              id={EVENT_GENERAL_SETTINGS_FORM_ID}
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputOuterCardBlock
                header={sideMenuItems.eventName.label}
                message={t('*Required')}
                id={sideMenuItems.eventName.id}
                className={classes.formGroupFirst}
              >
                {Object.entries(clientLocaleSelectData).map(([key, value]) => {
                  return (
                    <InputRow
                      key={key}
                      nodes={[
                        <FormInput
                          key={`${FormField.NAMES}[${key}]`}
                          name={`${FormField.NAMES}[${key}]`}
                          inputRef={register({required: true})}
                          label={value as string}
                          type="text"
                        />
                      ]}
                    />
                  )
                })}
              </InputOuterCardBlock>
              <InputOuterCardBlock
                header={sideMenuItems.time.label}
                message={t('*Required')}
                id={sideMenuItems.time.id}
                className={classes.formGroup}
              >
                <InputRow
                  nodes={[
                    <FormDatetimeInput
                      key={FormField.STARTS_AT}
                      name={FormField.STARTS_AT}
                      dataTimePickerProps={{
                        label: t('Starts at')
                      }}
                      validationOptions={{required: true}}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      register={register}
                      unregister={unregister}
                      clearError={clearError}
                      control={control}
                      setError={setError}
                    />
                  ]}
                />
                <InputRow
                  nodes={[
                    <>
                      <FormInput
                        key={FormField.DURATION}
                        name={FormField.DURATION}
                        inputRef={register({
                          required: true,
                          pattern: {
                            value: /^[1-9]{1}\d{0,5}$/,
                            message: t('Must be an integer in range 1-999999!')
                          }
                        })}
                        label={t('Duration (minutes)')}
                        inputMode="numeric"
                        hasError={!!errors[FormField.DURATION]}
                      />
                      {errors[FormField.DURATION] && (
                        <ValidationError>
                          {errors[FormField.DURATION]!.message}
                        </ValidationError>
                      )}
                    </>,
                    <>
                      <FormInput
                        key={FormField.SERVICE_TIME}
                        name={FormField.SERVICE_TIME}
                        inputRef={register({
                          required: true,
                          pattern: {
                            value: /^\d{0,6}$/,
                            message: t('Must be an integer in range 0-999999!')
                          }
                        })}
                        label={t('Service time (minutes)')}
                        inputMode="numeric"
                        hasError={!!errors[FormField.SERVICE_TIME]}
                      />
                      {errors[FormField.SERVICE_TIME] && (
                        <ValidationError>
                          {errors[FormField.SERVICE_TIME]!.message}
                        </ValidationError>
                      )}
                    </>
                  ]}
                />
              </InputOuterCardBlock>
              <DisabledFormWrapper
                disabled={
                  data.state !== EventState.Draft &&
                  data.state !== EventState.Published
                }
              >
                <InputOuterCardBlock
                  header={sideMenuItems.additional.label}
                  id={sideMenuItems.additional.id}
                  className={classes.formGroup}
                  sidesPadding={false}
                  headerClassName={classes.additionalFieldsHeader}
                >
                  <InputRow
                    spacing={0}
                    nodeClassName={classes.additionalFieldsNode}
                    nodes={[
                      <SelectInputRow
                        key={FormField.FORMAT}
                        label={t('Format')}
                        message={t('Optional')}
                        className={classes.additionalFieldsItem}
                      >
                        <UncontrolledFormSelect<IGeneralEventData>
                          fullWidth
                          name={FormField.FORMAT}
                          label={t('Format')}
                          selectOptions={formatSelectFields}
                          hasNoneSelectOption
                          errors={errors}
                          register={register}
                          setValue={setValue}
                          watch={watch}
                        />
                      </SelectInputRow>
                    ]}
                  />
                  <InputRow
                    spacing={0}
                    nodeClassName={classes.additionalFieldsNode}
                    nodes={[
                      <SelectInputRow
                        key={FormField.VERSION}
                        label={t('Version')}
                        message={t('Optional')}
                        className={classes.additionalFieldsItem}
                      >
                        <UncontrolledFormSelect<IGeneralEventData>
                          fullWidth
                          label={t('Version')}
                          name={FormField.VERSION}
                          selectOptions={versionSelectFields}
                          hasNoneSelectOption
                          errors={errors}
                          register={register}
                          setValue={setValue}
                          watch={watch}
                        />
                      </SelectInputRow>
                    ]}
                  />
                  <InputRow
                    spacing={0}
                    nodeClassName={classes.additionalFieldsNode}
                    nodes={[
                      <SelectInputRow
                        key={FormField.SOUND_MIX}
                        label={t('Sound mix')}
                        message={t('Optional')}
                        className={classes.additionalFieldsItem}
                      >
                        <UncontrolledFormSelect<IGeneralEventData>
                          fullWidth
                          label={t('Sound mix')}
                          name={FormField.SOUND_MIX}
                          selectOptions={soundMixSelectFields}
                          hasNoneSelectOption
                          errors={errors}
                          register={register}
                          setValue={setValue}
                          watch={watch}
                        />
                      </SelectInputRow>
                    ]}
                  />
                  <InputRow
                    spacing={0}
                    nodeClassName={classes.additionalFieldsNode}
                    nodes={[
                      <SelectInputRow
                        key={FormField.AGE_CLASSIFICATION}
                        label={t('Age restrictions')}
                        message={t('Recommended')}
                        className={classes.additionalFieldsItem}
                      >
                        <UncontrolledFormSelect<IGeneralEventData>
                          fullWidth
                          label={t('Age restrictions')}
                          name={FormField.AGE_CLASSIFICATION}
                          selectOptions={ageClassificationSelectFields}
                          hasNoneSelectOption
                          errors={errors}
                          register={register}
                          setValue={setValue}
                          watch={watch}
                        />
                      </SelectInputRow>
                    ]}
                  />
                </InputOuterCardBlock>
              </DisabledFormWrapper>
              <InputOuterCardBlock
                header={sideMenuItems.venue.label}
                id={sideMenuItems.venue.id}
                className={classes.formGroup}
              >
                <AddressInfo data={data} />
              </InputOuterCardBlock>
              <InputOuterCardBlock
                header={sideMenuItems.log.label}
                id={sideMenuItems.log.id}
                className={classes.formGroup}
              >
                <LogInfo data={data} />
              </InputOuterCardBlock>
            </form>
          </div>
        </Grid>
      </DrawerForm>
    )
  }

export const GeneralEventInfoForm: React.FC<IGeneralEventInfoFormProps> = ({
  width,
  elementToScrollOn
}: IGeneralEventInfoFormProps) => {
  const {t} = useTranslation()
  const {eventId} = useEventsPathnameParams()
  const {data, loading, error} = useGetEvent(eventId)
  const history = useHistory()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()
  const updateGeneralEventData = useUpdateGeneralEventData()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async (data: IGeneralEventData) => {
    const dataToSubmit = {
      names: data.names,
      startsAt: data[FormField.STARTS_AT].toISOString(),
      duration: parseInt(data.duration, 10),
      serviceTime: parseInt(data.serviceTime, 10),
      versionCode: data.version ? (data.version as ShowVersionCode) : null,
      formatCode: data.format ? (data.format as ShowFormatCode) : null,
      soundMixCode: data.soundMix ? (data.soundMix as ShowSoundMixCode) : null,
      ageClassificationCode: data.ageClassification
        ? (data.ageClassification as ShowAgeClassificationCode)
        : null
    }

    try {
      setShowBackdrop(true)
      await updateGeneralEventData({id: eventId, data: dataToSubmit})
      history.push(routeTo.admin.events.edit(eventId))
      addInfoNotification(t('Event was updated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while saving event.'))
    } finally {
      setShowBackdrop(false)
    }
  }

  return (
    <RenderOnData
      {...{data, loading, error}}
      errorMessage={t<string>('Could not load event.')}
    >
      {(data: EventQuery) => {
        return (
          <GeneralEventInfoFormContent
            data={data.event}
            {...{width, elementToScrollOn, onSubmit}}
          />
        )
      }}
    </RenderOnData>
  )
}
