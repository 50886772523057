import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {ClaimsPage} from './ClaimsPage'
import {RefundClaim} from './RefundClaim'

export const Claims: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {(P([PermissionCode.RefundClaimOnRetailChannel]) ||
        P([PermissionCode.RefundClaimOnECommerceChannel])) && (
        <Route
          component={RefundClaim}
          path={routeTo.admin.claims.refund(':claimId')}
        />
      )}
      {P([PermissionCode.ReadClaims]) && (
        <Route component={ClaimsPage} path={routeTo.admin.claims.index()} />
      )}
    </Switch>
  )
}
