import {Box, Button, Divider} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {ICustomerDisplayScreen} from '../../../../types'
import {useEnsurePermissions} from '../../../../utils/auth'
import {
  isWindowManagementAvailable,
  useFetchScreens,
  useFindScreen,
  useGetWindowManagementPermissionState,
  useListenPermissionStateChange
} from '../../../../windowManagement'
import {InputBlockWithoutSpacings} from '../../../common'
import {NotGrantedWindowManagementPermissionBlank} from './NotGrantedWindowManagementPermissionBlank'
import {RowWithChildren} from './RowWithChildren'
import {RowWithSwitch} from './RowWithSwitch'
import {SelectMonitorDrawer} from './SelectMonitorDrawer'
import {
  DeviceSettingsAction,
  DeviceSettingsActionTypes,
  DeviceSettingsState,
  ISelectMonitorForm,
  SelectMonitorFormField
} from './types'

interface ICustomerDisplaySettingsProps {
  blockId: string
  blockLabel: string
  settings: DeviceSettingsState
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const CustomerDisplaySettings: React.FC<ICustomerDisplaySettingsProps> =
  ({
    blockId,
    blockLabel,
    settings,
    dispatch
  }: ICustomerDisplaySettingsProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {customerDisplay: customerDisplaySettings} = settings
    const {enabled, windowManagementEnabled, screen} = customerDisplaySettings
    const {
      state: isSelectMonitorDrawerOpen,
      setTrue: openSelectMonitorDrawer,
      setFalse: closeSelectMonitorDrawer
    } = useBooleanState(false)
    const {
      state: {data: screensData},
      refetch: refetchScreens
    } = useFetchScreens()
    const findScreen = useFindScreen()
    const {permissionState, promptPermission, recheckPermissionState} =
      useGetWindowManagementPermissionState()
    const {addListener, removeListener} = useListenPermissionStateChange(
      recheckPermissionState
    )
    const isScreenConnected = useCallback(
      (screen: ICustomerDisplayScreen) => Boolean(findScreen(screen)),
      [findScreen]
    )
    const handleSaveMonitor = useCallback(
      (formData: ISelectMonitorForm) => {
        dispatch({
          type: DeviceSettingsActionTypes.ChangeCustomerDisplaySettings,
          payload: {
            ...customerDisplaySettings,
            screen: JSON.parse(formData[SelectMonitorFormField.Monitor])
          }
        })
        closeSelectMonitorDrawer()
      },
      [closeSelectMonitorDrawer, customerDisplaySettings, dispatch]
    )
    const handleCloseSelectMonitorDrawer = useCallback(() => {
      if (!screen) {
        dispatch({
          type: DeviceSettingsActionTypes.ChangeCustomerDisplaySettings,
          payload: {
            ...customerDisplaySettings,
            windowManagementEnabled: false
          }
        })
      }
      closeSelectMonitorDrawer()
    }, [closeSelectMonitorDrawer, customerDisplaySettings, dispatch, screen])
    useEffect(() => {
      addListener()
      return () => removeListener()
    }, [addListener, removeListener])
    useEffect(() => {
      if (windowManagementEnabled && !screen) {
        openSelectMonitorDrawer()
      }
    }, [openSelectMonitorDrawer, screen, windowManagementEnabled])
    useEffect(() => {
      if (typeof screen !== 'object') {
        dispatch({
          type: DeviceSettingsActionTypes.ChangeCustomerDisplaySettings,
          payload: {
            ...customerDisplaySettings,
            windowManagementEnabled: false,
            screen: null
          }
        })
      }
    }, [customerDisplaySettings, dispatch, screen])
    return (
      <>
        <InputBlockWithoutSpacings
          blockId={blockId}
          header={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              {blockLabel}
            </Box>
          }
          headerSx={{width: '100%'}}
        >
          <RowWithSwitch
            primaryLabel={t('Enable customer display on this device?')}
            secondaryLabel={t(
              'Set up communication between cashdesk and customer display.'
            )}
            switchProps={{
              checked: enabled,
              onChange: (event) =>
                dispatch({
                  type: DeviceSettingsActionTypes.ChangeCustomerDisplaySettings,
                  payload: {
                    ...customerDisplaySettings,
                    ...(event.target.checked
                      ? {}
                      : {
                          windowManagementEnabled: false,
                          screen: null
                        }),
                    enabled: event.target.checked
                  }
                })
            }}
          />
          {P([PermissionCode.FeatureFlagEf_448]) && enabled && (
            <>
              <Divider />
              {permissionState === 'granted' ||
              permissionState === undefined ? (
                <>
                  <RowWithSwitch
                    primaryLabel={t(
                      'Automatically open display on selected monitor?'
                    )}
                    secondaryLabel={t(
                      'This feature is only supported on Chrome, Edge, and Opera internet browsers.'
                    )}
                    disabled={!isWindowManagementAvailable()}
                    switchProps={{
                      checked: windowManagementEnabled,
                      onChange: (event) =>
                        dispatch({
                          type: DeviceSettingsActionTypes.ChangeCustomerDisplaySettings,
                          payload: {
                            ...customerDisplaySettings,
                            ...(event.target.checked ? {} : {screen: null}),
                            windowManagementEnabled: event.target.checked
                          }
                        })
                    }}
                  />
                  {windowManagementEnabled && screen && (
                    <>
                      <Divider />
                      <RowWithChildren
                        primaryLabel={t(
                          'Selected monitor for customer display'
                        )}
                        secondaryLabel={
                          isScreenConnected(screen)
                            ? screen.name
                            : [
                                screen.name,
                                t('Currently disconnected or off')
                              ].join(' • ')
                        }
                        secondaryLabelColor={
                          isScreenConnected(screen) ? undefined : 'error'
                        }
                      >
                        <Button
                          variant="text"
                          color="primary"
                          onClick={openSelectMonitorDrawer}
                        >
                          {t('Change')}
                        </Button>
                      </RowWithChildren>
                    </>
                  )}
                </>
              ) : (
                <NotGrantedWindowManagementPermissionBlank
                  permissionState={permissionState}
                  askForPermission={promptPermission}
                />
              )}
            </>
          )}
        </InputBlockWithoutSpacings>
        {isSelectMonitorDrawerOpen && (
          <SelectMonitorDrawer
            isOpen={isSelectMonitorDrawerOpen}
            onClose={handleCloseSelectMonitorDrawer}
            onSubmit={handleSaveMonitor}
            monitors={screensData?.screens || []}
            refetchMonitors={refetchScreens}
            defaultValues={{
              [SelectMonitorFormField.Monitor]: screen
                ? JSON.stringify(screen)
                : undefined
            }}
          />
        )}
      </>
    )
  }
