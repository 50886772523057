import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  DeleteButton,
  IDeleteButtonProps,
  IExtraActionOuterProps,
  SplitButtonModalItem
} from '../../../common'

type IOuterDeleteButtonProps = Pick<IDeleteButtonProps, 'onSubmit'>

export const ArchiveButtonAuditoriumLayout: React.FC<IExtraActionOuterProps> = (
  props: IExtraActionOuterProps
) => {
  const {t} = useTranslation()

  return (
    <SplitButtonModalItem
      {...props}
      modalHeader={t(
        'Do you really want to save and archive auditorium layout?'
      )}
      modalMessage={t(
        'Are you sure you want to save and archive auditorium layout?'
      )}
      modalSubmitLabel={t('Save as archived')}
      buttonLabel={t('Save as archived')}
    />
  )
}

export const ArchiveButtonLayoutPricing: React.FC<IExtraActionOuterProps> = (
  props: IExtraActionOuterProps
) => {
  const {t} = useTranslation()

  return (
    <SplitButtonModalItem
      {...props}
      modalHeader={t('Do you really want to save and archive layout pricing?')}
      modalMessage={t(
        'Are you sure you want to save and archive layout pricing?'
      )}
      modalSubmitLabel={t('Save as archived')}
      buttonLabel={t('Save as archived')}
    />
  )
}

export const ActivateButtonAuditoriumLayout: React.FC<IExtraActionOuterProps> =
  (props: IExtraActionOuterProps) => {
    const {t} = useTranslation()

    return (
      <SplitButtonModalItem
        {...props}
        modalHeader={t(
          'Do you really want to save and activate auditorium layout?'
        )}
        modalMessage={t(
          'Are you sure you want to save and activate auditorium layout?'
        )}
        modalSubmitLabel={t('Save as active')}
        buttonLabel={t('Save as active')}
      />
    )
  }

export const ActivateButtonLayoutPricing: React.FC<IExtraActionOuterProps> = (
  props: IExtraActionOuterProps
) => {
  const {t} = useTranslation()

  return (
    <SplitButtonModalItem
      {...props}
      modalHeader={t('Do you really want to save and activate layout pricing?')}
      modalMessage={t(
        'Are you sure you want to save and activate layout pricing?'
      )}
      modalSubmitLabel={t('Save as active')}
      buttonLabel={t('Save as active')}
    />
  )
}

export const DeleteLayoutPricingButton: React.FC<IOuterDeleteButtonProps> = (
  props: IOuterDeleteButtonProps
) => {
  const {t} = useTranslation()

  return (
    <DeleteButton
      {...props}
      header={t('Do you really want to delete layout pricing?')}
      message={t('Are you sure you want to delete layout pricing?')}
    />
  )
}

export const DeleteTicketTypeButton: React.FC<IOuterDeleteButtonProps> = (
  props: IOuterDeleteButtonProps
) => {
  const {t} = useTranslation()

  return (
    <DeleteButton
      {...props}
      header={t('Do you really want to delete ticket type?')}
      message={t('Are you sure you want to delete ticket type?')}
    />
  )
}
