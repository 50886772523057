import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {RefsActionType, StageRef} from './reducer'

export const useRefsActions = () => {
  const dispatch = useDispatch()

  const setStageRef = useCallback(
    (stageRef: StageRef) => {
      dispatch({
        type: RefsActionType.SET_STAGE_REF,
        payload: stageRef
      })
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      setStageRef
    }),
    [setStageRef]
  )

  return actions
}
