import {useTranslation} from 'react-i18next'
import {InventoryCheckType} from '../__generated__/schema'

export const useTranslateInventoryCheckType = () => {
  const {t} = useTranslation()
  const translated: Record<InventoryCheckType, string> = {
    [InventoryCheckType.Full]: t('InventoryCheckType->Full'),
    [InventoryCheckType.Controlling]: t('InventoryCheckType->Controlling')
  }
  return (type: InventoryCheckType) => translated[type]
}
