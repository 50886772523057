import {useTranslation} from 'react-i18next'
import {WebsitePageState} from '../__generated__/schema'

export const useTranslateWebsitePageState = () => {
  const {t} = useTranslation()
  const translated: Record<WebsitePageState, string> = {
    [WebsitePageState.Published]: t('WebsitePageState->Published'),
    [WebsitePageState.Draft]: t('WebsitePageState->Draft'),
    [WebsitePageState.Archived]: t('WebsitePageState->Archived')
  }
  return (state: WebsitePageState) => translated[state]
}
