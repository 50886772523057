import {useTranslation} from 'react-i18next'
import {InventoryCheckState} from '../__generated__/schema'

export const useTranslateInventoryCheckState = () => {
  const {t} = useTranslation()
  const translated: Record<InventoryCheckState, string> = {
    [InventoryCheckState.Draft]: t('InventoryCheckState->Draft'),
    [InventoryCheckState.Completed]: t('InventoryCheckState->Completed')
  }
  return (state: InventoryCheckState) => translated[state]
}

export const useTranslateInventoryCheckStateDescription = () => {
  const {t} = useTranslation()
  const translated: Record<InventoryCheckState, string> = {
    [InventoryCheckState.Draft]: t('InventoryCheckStateDescription->Draft'),
    [InventoryCheckState.Completed]: t(
      'InventoryCheckStateDescription->Completed'
    )
  }
  return (state: InventoryCheckState) => translated[state]
}
