import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CashDeskTourTimeSlotQuery,
  CheckoutOptions,
  PermissionCode,
  TourItemPropertiesFragment,
  TourTimeSlotState
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../../common'
import {Blank} from '../../../../../visual/Blank'
import {isTourItemPropertiesFragment} from '../../../types'
import {useDiscardCart} from '../../cart/graphql'
import {useCurrentCart} from '../../CurrentCartContext'

interface IDiscardCartButtonProps {
  onClick: () => void
}

const DiscardCartButton: React.FC<IDiscardCartButtonProps> = ({
  onClick
}: IDiscardCartButtonProps) => {
  const {t} = useTranslation()
  return (
    <ButtonWithConfirmationDialog
      onConfirmButtonClick={onClick}
      dialogProps={{
        confirmButtonLabel: t('Discard'),
        title: t('Discard cart?'),
        contentText: t(
          "Are you sure you want to discard your cart? This can't be undone."
        )
      }}
      buttonProps={{
        startIcon: <RemoveShoppingCartIcon />,
        variant: 'text',
        color: 'primary',
        children: t('Discard cart', {context: 'checkout_options_mismatch'})
      }}
    />
  )
}

interface IChildrenOnValidTourTimeSlotProps {
  tourTimeSlot: CashDeskTourTimeSlotQuery['tourTimeSlot']
  children: React.ReactNode
}

export const ChildrenOnValidTourTimeSlot: React.FC<IChildrenOnValidTourTimeSlotProps> =
  ({tourTimeSlot, children}: IChildrenOnValidTourTimeSlotProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const discardCart = useDiscardCart()
    const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
      useMutationAssistanceHooks()
    const {resetCurrentCart, currentCartId, currentCart} = useCurrentCart()
    const handleDiscardCart = useCallback(async () => {
      if (currentCartId) {
        try {
          setShowBackdrop(true)
          await discardCart(currentCartId)
          resetCurrentCart()
          addInfoNotification(t('Cart discarded'))
        } catch (e) {
          defaultErrorHandler(e, t('Discarding cart failed'))
        } finally {
          setShowBackdrop(false)
        }
      }
    }, [
      addInfoNotification,
      currentCartId,
      defaultErrorHandler,
      discardCart,
      resetCurrentCart,
      setShowBackdrop,
      t
    ])
    const isSaleEnabledByTimeSlotSettings =
      tourTimeSlot.isRetailSaleActive &&
      dayjs().isBetween(
        tourTimeSlot.retailSaleStartsAt,
        tourTimeSlot.retailSaleEndsAt
      )
    const isReservationEnabledByTimeSlotSettings =
      tourTimeSlot.isRetailReservationActive &&
      dayjs().isBetween(
        tourTimeSlot.retailReservationStartsAt,
        tourTimeSlot.retailReservationEndsAt
      )
    if (tourTimeSlot.state === TourTimeSlotState.Draft) {
      return (
        <Blank
          title={t('Time slot unavailable')}
          description={t(
            "Time slot is currently in 'Draft' state. This means it is not yet ready for bookings. Please choose another time slot, check back later or change time slot settings."
          )}
          IconComp={ReportProblemOutlinedIcon}
        />
      )
    }
    if (tourTimeSlot.state === TourTimeSlotState.Cancelled) {
      return (
        <Blank
          title={t('Time slot unavailable')}
          description={t(
            'Time slot was canceled. Please choose another time slot, check back later or change time slot settings.'
          )}
          IconComp={ReportProblemOutlinedIcon}
        />
      )
    }
    if (
      (!tourTimeSlot.isRetailSaleActive &&
        !tourTimeSlot.isRetailReservationActive) ||
      (!dayjs().isBetween(
        tourTimeSlot.retailReservationStartsAt,
        tourTimeSlot.retailReservationEndsAt
      ) &&
        !dayjs().isBetween(
          tourTimeSlot.retailSaleStartsAt,
          tourTimeSlot.retailSaleEndsAt
        ))
    ) {
      return (
        <Blank
          title={t('Time slot unavailable')}
          description={t(
            'It is forbidden to sell tickets or make reservations for this time slot. Change time slot settings and try it again.'
          )}
          IconComp={ReportProblemOutlinedIcon}
        />
      )
    }
    if (
      currentCart &&
      Array.isArray(currentCart.checkoutOptions) &&
      (currentCart.items || [])
        .filter(isTourItemPropertiesFragment)
        .filter(
          (item: TourItemPropertiesFragment) =>
            item.tourTimeSlotId === tourTimeSlot.id
        ).length === 0
    ) {
      if (
        currentCart.checkoutOptions.includes(CheckoutOptions.Reservation) &&
        !currentCart.checkoutOptions.includes(CheckoutOptions.Sale) &&
        !isReservationEnabledByTimeSlotSettings
      ) {
        return (
          <Blank
            title={t('Can’t add time slot to cart')}
            description={t(
              'Your cart and time slot options do not match. The cart requires reservation to be completed, while the time slot settings only allow payment. You can either discard the current cart or proceed to make reservation for it.'
            )}
            IconComp={ReportProblemOutlinedIcon}
            actions={
              P([PermissionCode.DiscardCart]) && (
                <DiscardCartButton onClick={handleDiscardCart} />
              )
            }
          />
        )
      } else if (
        currentCart.checkoutOptions.includes(CheckoutOptions.Sale) &&
        !currentCart.checkoutOptions.includes(CheckoutOptions.Reservation) &&
        !isSaleEnabledByTimeSlotSettings
      ) {
        return (
          <Blank
            title={t('Can’t add time slot to cart')}
            description={t(
              'Your cart and time slot options do not match. The cart requires payment to be completed, while the time slot settings only allow for reservations. You can either discard the current cart or proceed to make payment for it.'
            )}
            IconComp={ReportProblemOutlinedIcon}
            actions={
              P([PermissionCode.DiscardCart]) && (
                <DiscardCartButton onClick={handleDiscardCart} />
              )
            }
          />
        )
      }
    }
    return <>{children}</>
  }
