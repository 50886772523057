import {createSelector} from 'reselect'

import {IAppState} from '../index'

export const selectionSelector = (state: IAppState) => state.selection

export const selectedObjectsIdsSelector = createSelector(
  selectionSelector,
  (selection) => selection.ids
)

export const selectedByDoubleClickSelector = createSelector(
  selectionSelector,
  (selection) => selection.doubleClick
)
