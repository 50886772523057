import React from 'react'
import {useTranslation} from 'react-i18next'
import {FieldErrors, FormContextValues} from 'react-hook-form'

import {
  ExistingCountryCode,
  LeadField,
  LeadInputStatus,
  LeadOptionPropertiesFragment
} from '../../../../__generated__/schema'
import {ILeadForm} from './types'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {useTranslateCountry} from '../../../../hooks/countries'
import {getHelperNote} from './utils'
import {useUserInfo} from '../../../../utils/auth'
import {useTranslateLeadField} from '../../../../hooks/leadField'

interface IBriefLeadFormCountryInputProps {
  errors: FieldErrors<ILeadForm>
  setValue: FormContextValues<ILeadForm>['setValue']
  watch: FormContextValues<ILeadForm>['watch']
  register: FormContextValues<ILeadForm>['register']
  option?: LeadOptionPropertiesFragment
  showOptionalLeadInput?: boolean
  className?: string
}

export const LeadFormCountryInput = ({
  showOptionalLeadInput,
  option,
  register,
  setValue,
  watch,
  errors,
  className
}: IBriefLeadFormCountryInputProps) => {
  const {t} = useTranslation()
  const translateCountry = useTranslateCountry()
  const translateLeadField = useTranslateLeadField()
  const {effectiveClient} = useUserInfo()
  if (!option || option.inputStatus === LeadInputStatus.Unavailable) {
    return null
  }
  const name: LeadField = option.field
  if (
    !showOptionalLeadInput &&
    option.inputStatus === LeadInputStatus.Optional
  ) {
    return <input ref={register()} name={name} type="hidden" />
  }

  const effectiveClientCountryCode: ExistingCountryCode =
    effectiveClient!.countryCode

  const autocompleteOptions = Object.values(ExistingCountryCode).reduce(
    (acc: {value: string; name: string}[], value: ExistingCountryCode) =>
      value === effectiveClientCountryCode
        ? acc
        : [
            ...acc,
            {
              value: String(value),
              name: translateCountry(value)
            }
          ],
    [
      {
        value: effectiveClientCountryCode,
        name: translateCountry(effectiveClientCountryCode)
      }
    ]
  )
  return (
    <div className={className}>
      <FormAutocomplete<ILeadForm>
        register={register}
        setValue={setValue}
        errors={errors}
        name={name}
        autocompleteOptions={autocompleteOptions}
        validationOptions={{
          required: option.inputStatus === LeadInputStatus.Required
        }}
        watch={watch}
        label={translateLeadField(name)}
        noOptionsText={t('Country not found')}
        helperNote={getHelperNote(t, option)}
        disableClearable={false}
        fullWidth
      />
    </div>
  )
}
