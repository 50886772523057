import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {SaveButton} from '../../../../common/Buttons'
import {
  CapacityIndicator,
  capacityIndicatorSxProps
} from '../../../../common/CapacityIndicator'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogActionsRoot: {
    padding: theme.spacing(1, 3)
  },
  about: {
    display: 'grid',
    gridTemplateAreas: `
      "title        price"
      "quantities  quantities"
      "description  description"
    `,
    paddingTop: theme.spacing(2)
  },
  aboutDescription: {
    gridArea: 'description'
  },
  operationsBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  capacityIndicator: {
    paddingRight: theme.spacing(1)
  },
  quantities: {
    gridArea: 'quantities',
    display: 'flex'
  },
  quantityInput: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    minWidth: 216
  }
}))

interface IEditZoneTicketQuantityDialogProps {
  onClose: () => void
  zoneUuid: string
  zoneLabel: string
  zoneDescription?: string
  zonePrice: number
  ticketsInMyCartQuantity: number
  availableTicketsQuantity: number
  reservedTicketsQuantity: number
  zoneCapacity: number
  soldTicketsQuantity: number
  onSaveClick: (args: {
    zoneUuid: string
    decrement?: number
    increment?: number
  }) => void
}

export const EditZoneTicketQuantityDialog: React.FC<IEditZoneTicketQuantityDialogProps> =
  ({
    onClose,
    zoneUuid,
    zoneLabel,
    zoneDescription,
    zonePrice,
    zoneCapacity,
    ticketsInMyCartQuantity,
    onSaveClick,
    availableTicketsQuantity,
    reservedTicketsQuantity,
    soldTicketsQuantity
  }: IEditZoneTicketQuantityDialogProps) => {
    const {t} = useTranslation()
    const classes = useStyles()
    const [quantity, setQuantity] = useState<number>(ticketsInMyCartQuantity)
    const handleCancelButtonClick = useCallback(() => {
      setQuantity(0)
    }, [])
    const handleQuantityChange = useCallback(
      (e) => {
        const parsedQuantity = parseInt(e.target.value, 10)
        if (!isNaN(parsedQuantity) && parsedQuantity > -1) {
          setQuantity(
            parsedQuantity <= zoneCapacity ? parsedQuantity : zoneCapacity
          )
        } else {
          setQuantity(0)
        }
      },
      [zoneCapacity]
    )
    const handleDecrementClick = useCallback(() => {
      setQuantity((prevQuantity) =>
        prevQuantity > 0 ? prevQuantity - 1 : prevQuantity
      )
    }, [])

    const handleIncrementClick = useCallback(() => {
      setQuantity((prevQuantity) => prevQuantity + 1)
    }, [])

    const handleSaveClick = useCallback(() => {
      const change = quantity - ticketsInMyCartQuantity

      onSaveClick({
        zoneUuid,
        decrement: change < 0 ? -change : undefined,
        increment: change > 0 ? change : undefined
      })
    }, [onSaveClick, quantity, ticketsInMyCartQuantity, zoneUuid])

    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()

    return (
      <Dialog open onClose={onClose}>
        <DialogTitleWithCloseButton onCloseClick={onClose}>
          {t('Edit ticket quantity')}
        </DialogTitleWithCloseButton>
        <Divider />
        <DialogContent>
          <div className={classes.about}>
            <Typography variant="subtitle2">{zoneLabel}</Typography>
            <Typography variant="body2" align="right">
              {translateEffectiveClientPrice(zonePrice)}
            </Typography>
            <div className={classes.quantities}>
              <CapacityIndicator
                tooltipTitle={t('Available tickets in zone')}
                className={classes.capacityIndicator}
                countLabel={availableTicketsQuantity.toString()}
                customIcon={<PersonOutlineIcon fontSize="inherit" />}
              />
              <CapacityIndicator
                tooltipTitle={t('Reserved tickets in zone')}
                className={classes.capacityIndicator}
                colorSx={capacityIndicatorSxProps.reservedSpaces}
                countLabel={reservedTicketsQuantity.toString()}
              />
              <CapacityIndicator
                tooltipTitle={t('Sold tickets in zone')}
                className={classes.capacityIndicator}
                colorSx={capacityIndicatorSxProps.soldSpaces}
                countLabel={soldTicketsQuantity.toString()}
              />
            </div>
            {zoneDescription && (
              <Typography
                variant="caption"
                className={classes.aboutDescription}
                color="textSecondary"
              >
                {zoneDescription}
              </Typography>
            )}
          </div>
          <div className={classes.operationsBox}>
            <IconButton
              disabled={quantity < 1}
              color="primary"
              onClick={handleDecrementClick}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <OutlinedInputWithCancelAdornment
              inputId="quantity-input"
              label={t('Quantity')}
              inputProps={{value: quantity, onChange: handleQuantityChange}}
              onCancelClick={handleCancelButtonClick}
              className={classes.quantityInput}
            />
            <IconButton
              onClick={handleIncrementClick}
              disabled={quantity >= zoneCapacity}
              color="primary"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions
          classes={{
            root: classes.dialogActionsRoot
          }}
        >
          <SaveButton
            onClick={
              ticketsInMyCartQuantity === quantity ? onClose : handleSaveClick
            }
          />
        </DialogActions>
      </Dialog>
    )
  }
