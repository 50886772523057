import {Box, Typography} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Maybe,
  PaymentReportDataAggregatedByDivision,
  PaymentReportDataAggregatedByDivisionItem
} from '../../../__generated__/schema'
import {DataGridTable, useEffectiveClientPriceFormatter} from '../DataGridTable'
import {commonTableSxProps, getCellClassName} from './utils'

interface IDivisionsSummaryProps {
  id?: string
  label: string
  dataAggregatedByDivision?: Maybe<
    Pick<
      PaymentReportDataAggregatedByDivision,
      'totalSaleSum' | 'totalRefundSum' | 'totalRevenueSum'
    > & {
      items: Pick<
        PaymentReportDataAggregatedByDivisionItem,
        'divisionId' | 'divisionName' | 'saleSum' | 'refundSum' | 'revenueSum'
      >[]
    }
  >
}

export const DivisionsSummary: React.FC<IDivisionsSummaryProps> = ({
  id,
  label,
  dataAggregatedByDivision
}: IDivisionsSummaryProps) => {
  const {t} = useTranslation()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Division'),
        field: 'divisionName',
        flex: 1,
        sortable: false
      },
      {
        headerName: t('Sales'),
        field: 'saleSum',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        flex: 1,
        sortable: false
      },
      {
        headerName: t('Claims'),
        field: 'refundSum',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        cellClassName: getCellClassName,
        flex: 1,
        sortable: false
      },
      {
        headerName: t('Total'),
        field: 'revenueSum',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        flex: 1,
        sortable: false,
        cellClassName: getCellClassName
      }
    ],
    [effectiveClientPriceFormatter, t]
  )
  const summarizationRow = dataAggregatedByDivision?.items.length
    ? [
        {
          divisionId: 0,
          divisionName: t('Total'),
          saleSum: dataAggregatedByDivision?.totalSaleSum || 0,
          refundSum: dataAggregatedByDivision?.totalRefundSum || 0,
          revenueSum: dataAggregatedByDivision?.totalRevenueSum || 0
        }
      ]
    : []
  return (
    <Box id={id} sx={commonTableSxProps}>
      <Typography variant="subtitle1" sx={{pb: 2}}>
        {label}
      </Typography>
      <DataGridTable
        columns={columns}
        rows={[...(dataAggregatedByDivision?.items ?? []), ...summarizationRow]}
        hideFooter
        disableColumnMenu
        getRowId={(params) => params.divisionId}
        autoHeight
        disableRowSelectionOnClick
        localeText={{noRowsLabel: t('No divisions to show')}}
      />
    </Box>
  )
}
