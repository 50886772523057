import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import {SelectProps} from '@mui/material/Select/Select'
import React from 'react'

interface IRowWithSelectProps {
  primaryLabel: string
  secondaryLabel?: string
  selectProps: SelectProps
  inputLabel?: string
  helperText?: string
}

export const RowWithSelect: React.FC<IRowWithSelectProps> = ({
  primaryLabel,
  secondaryLabel,
  selectProps,
  inputLabel,
  helperText
}: IRowWithSelectProps) => (
  <Box
    sx={{
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: {sx: undefined, md: '1fr 316px'},
      columnGap: 3,
      px: 3,
      py: 1
    }}
  >
    <div>
      <Typography variant="subtitle2">{primaryLabel}</Typography>
      {secondaryLabel && (
        <Typography variant="caption" color="textSecondary">
          {secondaryLabel}
        </Typography>
      )}
    </div>
    <FormControl fullWidth>
      {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
      <Select variant="outlined" {...selectProps} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  </Box>
)
