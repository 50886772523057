import EditIcon from '@mui/icons-material/Edit'
import {Button} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  GetCustomerQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useCustomerParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {CartPreviewDrawer} from '../../components/cartPreviewDrawer'
import {ReservationDetailDrawer} from '../../components/reservationDetailDrawer'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {EditCustomerDrawer} from '../EditCustomerDrawer'
import {useGetCustomer} from '../graphql'
import {AccountSection} from './AccountSection'
import {CustomerGroupsSection} from './CustomerGroupsSection'
import {LoyaltyScoreSection} from './LoyaltyScoreSection'
import {ProfileSection} from './ProfileSection'
import {ReservationsSection} from './ReservationsSection'
import {SalesSection} from './SalesSection'

const useItems = () => {
  const {t} = useTranslation()
  return {
    account: {
      id: 'account',
      label: t('Account')
    },
    customerGroups: {
      id: 'customerGroups',
      label: t('Customer groups')
    },
    loyaltyScore: {
      id: 'loyaltyScore',
      label: t('Loyalty score')
    },
    profile: {
      id: 'profile',
      label: t('Profile')
    },
    reservations: {
      id: 'reservations',
      label: t('Active reservations')
    },
    purchaseHistory: {
      id: 'purchaseHistory',
      label: t('Purchase history')
    }
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

export const CustomerDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {customerId} = useCustomerParams()
  const {data, loading, error, refetch} = useGetCustomer(customerId)
  const [title, setTitle] = useState<string>(t('Customer'))
  const history = useHistory()
  const items = useItems()
  const classes = useStyles()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.customers.index()),
    [history]
  )
  const handleExited = useCallback(
    () => history.push(routeTo.admin.customers.detail(customerId)),
    [customerId, history]
  )
  const handleEditButtonClick = useCallback(
    () => history.push(routeTo.admin.customers.editCustomer(customerId)),
    [customerId, history]
  )
  useEffect(() => {
    if (data) {
      setTitle(t('Customer {{name}}', {name: data.customer.lead.data.name}))
    }
  }, [data, t])
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        P([PermissionCode.UpdateCustomer]) && (
          <Footer>
            <Button
              onClick={handleEditButtonClick}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              {t('Edit')}
            </Button>
          </Footer>
        )
      }
    >
      <RenderOnData<GetCustomerQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading customer')}
        dataCondition={(data) => Boolean(data.customer)}
        ignoreLoadingIfData
      >
        {({customer}) => (
          <>
            <CenteredLayout>
              <SingleSideNavigationList items={items} />
              <div className={classes.sections}>
                <AccountSection id={items.account.id} customer={customer} />
                <CustomerGroupsSection
                  id={items.customerGroups.id}
                  title={items.customerGroups.label}
                  customer={customer}
                />
                <LoyaltyScoreSection
                  id={items.loyaltyScore.id}
                  title={items.loyaltyScore.label}
                  loyaltyScore={customer.loyaltyScore}
                />
                <ProfileSection id={items.profile.id} lead={customer.lead} />
                <ReservationsSection
                  id={items.reservations.id}
                  reservations={customer.reservations}
                  customerId={customer.id}
                />
                <SalesSection
                  id={items.purchaseHistory.id}
                  sales={customer.sales}
                  customerId={customer.id}
                />
              </div>
            </CenteredLayout>
            <Route
              path={routeTo.admin.customers.cartInfo(':customerId', ':cartId')}
            >
              <CartPreviewDrawer onExited={handleExited} />
            </Route>
            <Route
              path={routeTo.admin.customers.reservationInfo(
                ':customerId',
                ':reservationId'
              )}
            >
              <ReservationDetailDrawer
                onReservationDelete={refetch}
                initialPath={routeTo.admin.customers.detail(customerId)}
                detailPath={routeTo.admin.customers.reservationInfo(
                  customerId,
                  ':reservationId'
                )}
                editPath={routeTo.admin.customers.editReservationInfo(
                  customerId,
                  ':reservationId'
                )}
                showPrimaryButton
              />
            </Route>
            <Route path={routeTo.admin.customers.editCustomer(':customerId')}>
              <EditCustomerDrawer onExited={handleExited} />
            </Route>
          </>
        )}
      </RenderOnData>
    </PageWithHeaderAndFooterTemplate>
  )
}
