import {Typography} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  LightweightClientsWithLocaleCodesQuery
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useTranslateLocaleCode} from '../../../../hooks/translateLocales'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {
  useIsStringWithMaxLength,
  useIsValidURL
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {FormAutocompleteMultiple} from '../../../form/FormAutocompleteMultiple'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {IWebsiteForm, WebsiteFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IWebsiteFormProps {
  formId: string
  onSubmit: (data: IWebsiteForm) => Promise<void>
  defaultValues?: Partial<IWebsiteForm>
  clients: LightweightClientsWithLocaleCodesQuery['clients']
  edit?: boolean
}

export const WebsiteForm: React.FC<IWebsiteFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  clients,
  edit
}: IWebsiteFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    setError,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IWebsiteForm>({defaultValues, mode: 'onChange'})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isValidURL = useIsValidURL(false, false)
  const translateLocaleCode = useTranslateLocaleCode()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const selectedClientId = watch(WebsiteFormField.ClientId)
  const client = clients.find(({id}) => id === parseInt(selectedClientId, 10))
  const _onSubmit = useCallback(
    async (formData: IWebsiteForm) => {
      try {
        await onSubmit(formData)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.RequestToGitlabFailed
          )
        ) {
          setError(
            WebsiteFormField.ProjectFullPath,
            t('Invalid project full path')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.LocaleCodesMismatch
          )
        ) {
          setError(
            WebsiteFormField.EnabledLocaleCodes,
            '',
            t('Locale codes mismatch')
          )
        } else {
          defaultErrorHandler(error, t('Error while creating website'))
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  useEffect(() => {
    if (client && !edit) {
      setValue(WebsiteFormField.EnabledLocaleCodes, [])
    }
  }, [client, edit, setValue])
  return (
    <StyledForm onSubmit={handleSubmit(_onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsiteForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsiteFormField.Name}
            key={WebsiteFormField.Name}
            label={t('Name')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IWebsiteForm>
            noOptionsText={t('No clients found')}
            fullWidth
            errors={errors}
            label={t('Client')}
            name={WebsiteFormField.ClientId}
            key={WebsiteFormField.ClientId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={clients.map(({id, name}) => ({
              value: id,
              name
            }))}
            required
            validationOptions={{required: true}}
            disableClearable={false}
            disabled={Boolean(defaultValues?.clientId) || edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocompleteMultiple<IWebsiteForm>
            errors={errors}
            control={control}
            name={WebsiteFormField.EnabledLocaleCodes}
            key={WebsiteFormField.EnabledLocaleCodes}
            label={t('Localization')}
            autocompleteOptions={
              client
                ? client.localeCodes.map((localeCode) => ({
                    value: localeCode,
                    name: translateLocaleCode(localeCode)
                  }))
                : []
            }
            fullWidth
            validationOptions={{required: true}}
            limitTags={3}
          />
        ]}
      />
      <Typography variant="overline" color="textSecondary">
        {t('Project settings')}
      </Typography>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsiteForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsiteFormField.ProjectFullPath}
            key={WebsiteFormField.ProjectFullPath}
            label={t('Project full path')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsiteForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsiteFormField.ProductionBranch}
            key={WebsiteFormField.ProductionBranch}
            label={t('Production branch')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsiteForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsiteFormField.PreviewUrl}
            key={WebsiteFormField.PreviewUrl}
            label={t('Preview url')}
            validationOptions={{
              validate: {isStringWithMaxLength255, isValidURL}
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsiteForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsiteFormField.PrimaryDomain}
            key={WebsiteFormField.PrimaryDomain}
            label={t('Primary domain')}
            validationOptions={{
              validate: {isStringWithMaxLength255, isValidURL}
            }}
            fullWidth
          />
        ]}
      />
    </StyledForm>
  )
}
