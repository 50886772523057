import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {uniqBy} from 'lodash'
import {
  LightweightTourTimeSlotsQuery,
  LightweightTourTimeSlotsQueryVariables,
  TourNameQuery,
  TourNameQueryVariables,
  TourTimeSlotsFilter
} from '../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../utils/pagination'
import {
  TOUR_ITEMS_STATE_COUNTS_FIELDS,
  USER_FIELDS
} from '../../pages/admin/graphql'
import {PAGINATION_FRAGMENT} from '../../pages/graphql'

const LIGHTWEIGHT_TOUR_TIME_SLOTS = gql`
  ${PAGINATION_FRAGMENT}
  ${USER_FIELDS}
  ${TOUR_ITEMS_STATE_COUNTS_FIELDS}
  query LightweightTourTimeSlots(
    $filter: TourTimeSlotsFilter
    $paginationInput: PaginationInput!
  ) {
    tourTimeSlots(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        startsAt
        guide {
          ...UserFields
        }
        versionCode
        ageClassificationCode
        retailAttendeesLimit
        tourItemsStateCounts {
          ...TourItemsStateCountsFields
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useLightweightTourTimeSlots = (filter: TourTimeSlotsFilter) =>
  useQueryWithPagination<
    LightweightTourTimeSlotsQuery,
    LightweightTourTimeSlotsQueryVariables
  >(
    LIGHTWEIGHT_TOUR_TIME_SLOTS,
    {
      variables: {filter, paginationInput: {offset: 0, limit: 20}},
      fetchPolicy: 'network-only'
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.tourTimeSlots.pagination),
      updateData: (prevData, fetchMoreData) => ({
        ...fetchMoreData,
        tourTimeSlots: {
          ...fetchMoreData.tourTimeSlots,
          items: uniqBy(
            [
              ...prevData.tourTimeSlots.items,
              ...fetchMoreData.tourTimeSlots.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

const TOUR_NAME = gql`
  query TourName($id: PositiveInt!) {
    tour(id: $id) {
      id
      name
    }
  }
`

export const useTourName = (id: number) =>
  useQuery<TourNameQuery, TourNameQueryVariables>(TOUR_NAME, {variables: {id}})
