import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, Drawer, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  TemplatesFilterInput,
  TemplatesQuery,
  TemplateState
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {
  useTranslateTemplateDatasetType,
  useTranslateTemplateFileType,
  useTranslateTemplateState
} from '../../../../hooks/translateTemplates'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {templateStateColors} from '../../../constants'
import {PrimaryHeader} from '../Header'
import {CreateTemplateDrawer} from './CreateTemplateDrawer'
import {useGetTemplates} from './graphql'
import {
  DEFAULT_TEMPLATES_FILTER_INPUT,
  TemplatesSearch
} from './TemplatesSearch'

const useFileTypeFormatter = () => {
  const translateTemplateFileType = useTranslateTemplateFileType()
  return (params: GridValueFormatterParams) =>
    params.value ? translateTemplateFileType(params.value).toUpperCase() : ''
}

const useDatasetTypeFormatter = () => {
  const translateTemplateDatasetType = useTranslateTemplateDatasetType()
  return (params: GridValueFormatterParams) =>
    params.value ? translateTemplateDatasetType(params.value) : ''
}

const StateRenderer = ({state}: {state: TemplateState}) => {
  const translateTemplateState = useTranslateTemplateState()
  return (
    <EntityStateChip
      label={translateTemplateState(state)}
      colorConf={templateStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.templates.edit(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface ITemplatesListProps {
  searchFilter: TemplatesFilterInput
}

const TemplatesList: React.FC<ITemplatesListProps> = ({
  searchFilter
}: ITemplatesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, error, loading} = useGetTemplates({...searchFilter})
  const dateTimeFormatter = useDateTimeFormatter()
  const fileTypeFormatter = useFileTypeFormatter()
  const datasetTypeFormatter = useDatasetTypeFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 300
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter
      },
      {
        headerName: t('Active'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: TemplateState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 100
      },
      {
        headerName: t('Filetype'),
        field: 'fileType',
        valueFormatter: fileTypeFormatter,
        minWidth: 50
      },
      {
        headerName: t('Versions'),
        field: 'versionsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Clients'),
        field: 'clientsCount',
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Locale'),
        field: 'localeCode',
        valueFormatter: (params) => params.value.toUpperCase(),
        minWidth: 50
      },
      {
        headerName: t('Dataset'),
        field: 'dataSetType',
        valueFormatter: datasetTypeFormatter,
        minWidth: 150
      },
      {
        headerName: t('Last update'),
        field: 'updatedAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 400
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [datasetTypeFormatter, dateTimeFormatter, fileTypeFormatter, t]
  )
  return (
    <RenderOnData
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading templates')}
      dataCondition={(data: TemplatesQuery) => Array.isArray(data?.templates)}
      ignoreLoadingIfData
    >
      {({templates}: TemplatesQuery) => (
        <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
          <DataGridTable
            columns={columns}
            rows={templates}
            pageSizeOptions={[10, 30, 50]}
            initialState={{
              pagination: {paginationModel: {pageSize: 30}},
              pinnedColumns: {left: ['name'], right: ['id']}
            }}
            columnVisibilityModel={{
              id: P([PermissionCode.ReadTemplate])
            }}
            disableRowSelectionOnClick
            localeText={{noRowsLabel: t('No templates to show')}}
          />
        </Box>
      )}
    </RenderOnData>
  )
}

const useTemplatesPageStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

export const TemplatesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<TemplatesFilterInput>(
    DEFAULT_TEMPLATES_FILTER_INPUT
  )
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  const classes = useTemplatesPageStyles()
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Templates')}
          search={<TemplatesSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <TemplatesList searchFilter={searchFilter} />
      {P([PermissionCode.CreateTemplate]) && (
        <CreateFab onClick={openDrawer} classes={fabClasses} />
      )}
      <Drawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <CreateTemplateDrawer onClose={closeDrawer} />
      </Drawer>
    </PageWithHeaderTemplate>
  )
}
