import {FormHelperText} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface IFormFieldHelperProps {
  required?: boolean
  note?: string
  className?: string
}

const useStyles = makeStyles({
  helpText: {
    marginLeft: 12,
    marginTop: 4,
    lineHeight: '16px'
  }
})

export const FormFieldHelper = ({
  required = false,
  note = '',
  className
}: IFormFieldHelperProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  const text = `${required ? t('*Required') : ''}${
    required && note ? `. ${note}` : note
  }`

  return text ? (
    <FormHelperText className={cn(classes.helpText, className)}>
      {text}
    </FormHelperText>
  ) : null
}
