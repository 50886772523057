import _ from 'lodash'
import {match} from 'react-router'
import {useHistory} from 'react-router-dom'
import {ShowImageType} from '../__generated__/schema'
import {CheckTicketsPageType} from '../components/pages/admin/checkTickets/types'

const v = {
  veneus: 'venues',
  auditoriums: 'auditoriums',
  layouts: 'layouts',
  pricings: 'pricings'
}

export const routeTo = {
  admin: {
    dashboard: () => '/',
    profile: () => '/profile',
    company: () => '/company',
    companyAssignedTemplates: (templateType: string) =>
      `/company/templates/${templateType}`,
    cashCounter: () => '/cashCounter',
    carts: {
      index: () => '/carts',
      detail: (cartId: number | string) => `/carts/${cartId}`
    },
    cashDesk: {
      index: () => '/cashDesk',
      events: () => '/cashDesk/events',
      settings: () => '/cashDesk/settings',
      deviceInformation: () => '/cashDesk/deviceInformation',
      eventDetail: (eventId: number | string) => `/cashDesk/events/${eventId}`,
      eventSales: (eventId: number | string) =>
        `/cashDesk/events/${eventId}/sales`,
      eventSalesCartDetail: (
        eventId: number | string,
        cartId: number | string
      ) => `/cashDesk/events/${eventId}/sales/${cartId}`,
      eventAuditoriumPreview: (eventId: number | string) =>
        `/cashDesk/events/${eventId}/auditoriumPreview`,
      eventReservations: (eventId: number | string) =>
        `/cashDesk/events/${eventId}/reservations`,
      eventReservationsWithReservationInfo: (
        eventId: number | string,
        reservationId: number | string
      ) => `/cashDesk/events/${eventId}/reservations/${reservationId}`,
      eventReservationsWithReservationInfoEdit: (
        eventId: number | string,
        reservationId: number | string
      ) => `/cashDesk/events/${eventId}/reservations/${reservationId}/edit`,
      eventAuditoriumPreviewWithEventInfo: (eventId: number | string) =>
        `/cashDesk/events/${eventId}/auditoriumPreview/eventInfo`,
      eventAuditoriumPreviewWithCartInfo: (
        eventId: number | string,
        cartId: number | string
      ) => `/cashDesk/events/${eventId}/auditoriumPreview/cartInfo/${cartId}`,
      eventApplyDiscounts: (eventId: number | string) =>
        `/cashdesk/events/${eventId}/applyDiscounts`,
      reservations: () => '/cashDesk/reservations',
      reservationDetail: (reservationId: number | string) =>
        `/cashDesk/reservations/${reservationId}`,
      reservationDetailEdit: (reservationId: number | string) =>
        `/cashDesk/reservations/${reservationId}/edit`,
      reservationPreview: (reservationId: number | string) =>
        `/cashDesk/reservations/${reservationId}/preview`,
      reservationPreviewWithReservationInfo: (reservationId: number | string) =>
        `/cashDesk/reservations/${reservationId}/preview/reservationInfo`,
      reservationPreviewWithReservationInfoEdit: (
        reservationId: number | string
      ) =>
        `/cashDesk/reservations/${reservationId}/preview/reservationInfo/edit`,
      reservationApplyDiscounts: (reservationId: number | string) =>
        `/cashdesk/reservations/${reservationId}/applyDiscounts`,
      shop: () => '/cashDesk/shop',
      shopWithOpenedQuantityDialog: (productId: number) =>
        `/cashDesk/shop?productIdForQuantityDialog=${productId}`,
      cartDetail: () => '/cashDesk/cart',
      cartDetailWithLead: () => '/cashDesk/cart/lead',
      cartDetailWithCustomers: () => '/cashDesk/cart/customers',
      tours: () => '/cashDesk/tours',
      tourTimeSlotsDrawer: (tourId: number | string) =>
        `/cashDesk/tours/${tourId}/tourTimeSlots`,
      tourTimeSlot: (tourTimeSlotId: number | string) =>
        `/cashDesk/tourTimeSlots/${tourTimeSlotId}`,
      tourTimeSlotApplyDiscounts: (tourTimeSlotId: number | string) =>
        `/cashDesk/tourTimeSlots/${tourTimeSlotId}/applyDiscounts`,
      tourTimeSlotReservations: (tourTimeSlotId: number | string) =>
        `/cashDesk/tourTimeSlots/${tourTimeSlotId}/reservations`,
      tourTimeSlotReservationsWithReservationInfo: (
        tourTimeSlotId: number | string,
        reservationId: number | string
      ) =>
        `/cashDesk/tourTimeSlots/${tourTimeSlotId}/reservations/${reservationId}`,
      tourTimeSlotReservationsWithReservationInfoEdit: (
        tourTimeSlotId: number | string,
        reservationId: number | string
      ) =>
        `/cashDesk/tourTimeSlots/${tourTimeSlotId}/reservations/${reservationId}/edit`
    },
    claims: {
      index: () => '/claims',
      detail: (claimId: number | string) => `/claims/${claimId}`,
      refund: (claimId: number | string) => `/claims/${claimId}/refund`,
      cartInfo: (cartId: number | string) => `/claims/cartInfo/${cartId}`
    },
    costCenters: {
      index: () => '/costCenters',
      edit: (costCenterId: number | string) => `/costCenters/${costCenterId}`
    },
    warehouses: {
      index: () => '/warehouses',
      edit: (warehouseId: number | string) => `/warehouses/${warehouseId}`
    },
    productTypes: {
      index: () => '/productTypes',
      edit: (productTypeId: number | string) => `/productTypes/${productTypeId}`
    },
    products: {
      index: () => '/products',
      detail: (productId: number | string) => `/products/${productId}`,
      availability: (productId: number | string) =>
        `/products/${productId}/availability`,
      edit: (productId: number | string) => `/products/${productId}/edit`,
      pricing: (productId: number | string, divisionId: number | string) =>
        `/products/${productId}/pricing/${divisionId}`,
      editWarehouseProduct: (
        productId: number | string,
        warehouseProductId: number | string
      ) => `/products/${productId}/editWarehouseProduct/${warehouseProductId}`
    },
    warehouseDocuments: {
      index: () => '/warehouseDocuments',
      detail: (warehouseDocumentId: number | string) =>
        `/warehouseDocuments/${warehouseDocumentId}`,
      edit: (warehouseDocumentId: number | string) =>
        `/warehouseDocuments/${warehouseDocumentId}/edit`
    },
    inventoryChecks: {
      index: () => '/inventoryChecks',
      detail: (inventoryCheckId: number | string) =>
        `/inventoryChecks/${inventoryCheckId}`,
      edit: (inventoryCheckId: number | string) =>
        `/inventoryChecks/${inventoryCheckId}/edit`
    },
    productGroups: {
      index: () => '/productGroups',
      detail: (productGroupId: number | string) =>
        `/productGroups/${productGroupId}`,
      edit: (productGroupId: number | string) =>
        `/productGroups/${productGroupId}/edit`,
      availability: (productGroupId: number | string) =>
        `/productGroups/${productGroupId}/availability`
    },
    messages: {
      index: () => '/messages',
      detail: (messageId: number | string) => `/messages/${messageId}`
    },
    voucherCampaigns: {
      index: () => '/voucherCampaigns',
      detail: (voucherCampaignId: number | string) =>
        `/voucherCampaigns/${voucherCampaignId}`,
      edit: (voucherCampaignId: number | string) =>
        `/voucherCampaigns/${voucherCampaignId}/edit`
    },
    vouchers: {
      index: () => '/vouchers',
      detail: (voucherId: number | string) => `/vouchers/${voucherId}`,
      cartInfo: (voucherId: number | string, cartId: number | string) =>
        `/vouchers/${voucherId}/cart/${cartId}`,
      claimInfo: (voucherId: number | string, claimId: number | string) =>
        `/vouchers/${voucherId}/claim/${claimId}`
    },
    customers: {
      index: () => '/customers',
      detail: (customerId: number | string) => `/customers/${customerId}`,
      reservationInfo: (
        customerId: number | string,
        reservationId: number | string
      ) => `/customers/${customerId}/reservation/${reservationId}`,
      editCustomer: (customerId: number | string) =>
        `/customers/${customerId}/edit`,
      editReservationInfo: (
        customerId: number | string,
        reservationId: number | string
      ) => `/customers/${customerId}/reservation/${reservationId}/edit`,
      cartInfo: (customerId: number | string, cartId: number | string) =>
        `/customers/${customerId}/cart/${cartId}`
    },
    customerGroups: {
      index: () => '/customerGroups',
      edit: (customerGroupId: number | string) =>
        `/customerGroups/${customerGroupId}`
    },
    businessPartners: {
      index: () => '/businessPartners',
      edit: (businessPartnerId: number | string) =>
        `/businessPartners/${businessPartnerId}/edit`
    },
    websites: {
      index: () => '/websites',
      detail: (websiteId: number | string) => `/websites/${websiteId}`,
      edit: (websiteId: number | string) => `/websites/${websiteId}/edit`,
      editEcommerceSettings: (websiteId: number | string) =>
        `/websites/${websiteId}/editEcommerceSettings`,
      editWebsiteMetadata: (websiteId: number | string) =>
        `/websites/${websiteId}/editWebsiteMetadata`,
      editWebsiteScripts: (websiteId: number | string) =>
        `/websites/${websiteId}/editWebsiteScripts`
    },
    admissionTypes: {
      index: () => '/admissionTypes',
      edit: (admissionTypeId: number | string) =>
        `/admissionTypes/${admissionTypeId}/edit`
    },
    accessZones: {
      index: () => '/accessZones',
      edit: (accessZoneId: number | string) =>
        `/accessZones/${accessZoneId}/edit`
    },
    tours: {
      index: () => '/tours',
      detail: (tourId: number | string) => `/tours/${tourId}`,
      timeSlots: (tourId: number | string) => `/tours/${tourId}/timeSlots`,
      editTimeSlots: (tourId: number | string) =>
        `/tours/${tourId}/timeSlots/edit`,
      general: (tourId: number | string) => `/tours/${tourId}/general`,
      admissionRates: (tourId: number | string) =>
        `/tours/${tourId}/admissionRates`,
      admissionRateDetail: (
        tourId: number | string,
        admissionRateId: number | string
      ) => `/tours/${tourId}/admissionRates/${admissionRateId}`,
      editAdmissionRate: (
        tourId: number | string,
        admissionRateId: number | string
      ) => `/tours/${tourId}/admissionRates/${admissionRateId}/edit`
    },
    cartsSupport: {
      index: () => '/cartsSupport',
      detail: (cartId: number | string) => `/cartsSupport/${cartId}`
    },
    paymentsSupport: {
      index: () => '/paymentsSupport',
      cartInfo: (cartId: number | string) => `/paymentsSupport/cart/${cartId}`,
      claimInfo: (claimId: number | string) =>
        `/paymentsSupport/claim/${claimId}`
    },
    marketingLabels: {
      index: () => '/marketingLabels',
      edit: (marketingLabelId: number | string) =>
        `/marketingLabels/${marketingLabelId}`
    },
    eventCategories: {
      index: () => '/eventCategories',
      edit: (eventCategoryId: number | string) =>
        `/eventCategories/${eventCategoryId}`
    },
    paymentReports: {
      index: () => '/paymentReports',
      dailyCashierReports: () => '/paymentReports/dailyCashierReports',
      monthlyEcommerceReports: () => '/paymentReports/monthlyEcommerceReports',
      monthlyReports: () => '/paymentReports/monthlyReports',
      paymentReportView: () => '/paymentReports/paymentReportView',
      detail: (paymentReportId: number | string) =>
        `/paymentReports/${paymentReportId}`
    },
    showsStatistics: {
      index: () => '/showsStatistics',
      overview: () => '/showsStatistics/overview'
    },
    eventsStatistics: {
      index: () => '/eventsStatistics',
      overview: () => '/eventsStatistics/overview',
      revenues: () => '/eventsStatistics/revenues',
      turnovers: () => '/eventsStatistics/turnovers',
      availability: () => '/eventsStatistics/availability',
      ticketsChecking: () => '/eventsStatistics/ticketsChecking',
      eventInfo: (eventId: number | string) => `/eventsStatistics/${eventId}`,
      eventInfoTickets: (eventId: number | string) =>
        `/eventsStatistics/${eventId}/tickets`,
      eventInfoSalesContacts: (eventId: number | string) =>
        `/eventsStatistics/${eventId}/salesContacts`,
      eventInfoReservationsContacts: (eventId: number | string) =>
        `/eventsStatistics/${eventId}/reservationsContacts`,
      eventInfoReservationsContactsCartDetail: (
        eventId: number | string,
        cartId: number | string
      ) => `/eventsStatistics/${eventId}/reservationsContacts/${cartId}`,
      eventOccupancy: (eventId: number | string) =>
        `/eventsStatistics/${eventId}/occupancy`,
      eventInfoEventSalesReport: (eventId: number | string) =>
        `/eventsStatistics/${eventId}/eventSalesReport`,
      eventInfoTicketInfo: (
        eventId: number | string,
        itemId: number | string
      ) => `/eventsStatistics/${eventId}/tickets/${itemId}`,
      eventInfoCartDetail: (
        eventId: number | string,
        cartId: number | string
      ) => `/eventsStatistics/${eventId}/salesContacts/${cartId}`
    },
    tourTimeSlotsStatistics: {
      index: () => '/tourTimeSlotsStatistics',
      revenues: () => '/tourTimeSlotsStatistics/revenues',
      turnovers: () => '/tourTimeSlotsStatistics/turnovers',
      availability: () => '/tourTimeSlotsStatistics/availability',
      admissionTypeStatistics: (tourTimeSlotId: number | string) =>
        `/tourTimeSlotsStatistics/${tourTimeSlotId}`,
      admissionTypeStatisticsRevenues: (tourTimeSlotId: number | string) =>
        `/tourTimeSlotsStatistics/${tourTimeSlotId}/revenues`,
      admissionTypeStatisticsTurnovers: (tourTimeSlotId: number | string) =>
        `/tourTimeSlotsStatistics/${tourTimeSlotId}/turnovers`
    },
    admissionTypesStatistics: {
      index: () => '/admissionTypesStatistics',
      revenues: () => '/admissionTypesStatistics/revenues',
      turnovers: () => '/admissionTypesStatistics/turnovers'
    },
    discountsStatistics: {
      index: () => '/discountsStatistics'
    },
    productsStatistics: {
      index: () => '/productsStatistics'
    },
    warehouseStocks: {
      index: () => '/warehouseStocks'
    },
    inventoryTransactions: {
      index: () => '/inventoryTransactions'
    },
    paymentsOverview: {
      index: () => '/payments',
      cartInfo: (cartId: number | string) => `/payments/cart/${cartId}`,
      claimInfo: (claimId: number | string) => `/payments/claim/${claimId}`
    },
    voucherTransactions: {
      index: () => '/voucherTransactions',
      cartDetail: (cartId: number | string) =>
        `/voucherTransactions/cart/${cartId}`,
      claimDetail: (claimId: number | string) =>
        `/voucherTransactions/claim/${claimId}`
    },
    checkTickets: {
      index: () => '/checkTickets',
      advancedSettings: () => '/checkTickets/advancedSettings',
      events: () => `/checkTickets/${CheckTicketsPageType.Events}`,
      tourTimeSlots: () =>
        `/checkTickets/${CheckTicketsPageType.TourTimeSlots}`,
      tours: () => `/checkTickets/${CheckTicketsPageType.Tours}`,
      venues: () => `/checkTickets/${CheckTicketsPageType.Venues}`,
      checkTicketCode: (
        checkType: CheckTicketsPageType | string,
        entityId: number | string
      ) => `/checkTickets/${checkType}/${entityId}/check`,
      eventDetailFromList: (
        checkType: CheckTicketsPageType | string,
        eventId: number | string
      ) => `/checkTickets/${checkType}/${eventId}`,
      eventDetailFromCheckPage: (
        checkType: CheckTicketsPageType | string,
        eventId: number | string
      ) => `/checkTickets/${checkType}/${eventId}/check/eventDetail`,
      ticketInfo: (
        checkType: CheckTicketsPageType | string,
        entityId: number | string,
        itemId: number | string
      ) => `/checkTickets/${checkType}/${entityId}/check/ticketInfo/${itemId}`,
      bulkCheck: (
        checkType: CheckTicketsPageType | string,
        entityId: number | string,
        passCode: number | string
      ) => `/checkTickets/${checkType}/${entityId}/check/bulk/${passCode}`
    },
    passCodeCheckHistory: {
      index: () => '/passCodeCheckHistory',
      ticketInfoDrawer: (itemId: number | string) =>
        `/passCodeCheckHistory/${itemId}`
    },
    users: {
      // TODO: handle nicer
      home: () => '/users',
      add: () => '/users/add',
      invite: () => '/users/invite',
      edit: (id: number | string) => `/users/${id}`
    },
    clients: {
      // TODO: handle nicer
      home: () => '/clients',
      add: () => '/clients/add',
      edit: (id: number | string) => `/clients/${id}`,
      assignedTemplates: (id: number | string, templateType: string) =>
        `/clients/${id}/templates/${templateType}`
    },
    roles: {
      index: () => '/roles',
      create: () => '/roles/create',
      edit: (roleId: number | string) => `/roles/${roleId}`
    },
    templates: {
      index: () => '/templates',
      edit: (templateId: number | string) => `/templates/${templateId}`
    },
    apiGrants: {
      index: () => '/apiGrants',
      detail: (apiGrantId: number | string) => `/apiGrants/${apiGrantId}`,
      edit: (apiGrantId: number | string) => `/apiGrants/${apiGrantId}/edit`
    },
    discounts: {
      home: () => '/discounts',
      detail: (id: number | string) => `/discounts/${id}`
    },
    divisions: {
      // TODO: handle nicer
      home: () => '/divisions',
      add: () => '/divisions/add',
      edit: (id: number | string) => `/divisions/${id}`
    },
    library: {
      home: () => '/library',
      add: () => '/library/add',
      edit: (id: number | string) => `/library/${id}`,
      editWithEditSection: (id: number | string, editSection: string) =>
        `/library/${id}/${editSection}`,
      uploadMedia: (
        id: number | string,
        editSection: string,
        addMediaSection: string | null
      ) => `/library/${id}/${editSection}/${addMediaSection}/upload`
    },
    shows: {
      home: () => '/shows',
      add: () => '/shows/add',
      edit: (id: number | string) => `/shows/${id}`,
      editWithEditSection: (id: number | string, editSection: string) =>
        `/shows/${id}/${editSection}`,
      assignMediaFromLibrary: (
        showId: number | string,
        showSection: string,
        showImageType: ShowImageType | string
      ) => `/shows/${showId}/${showSection}/${showImageType}/library`,
      uploadMedia: (
        id: number | string,
        editSection: string,
        addMediaSection: string | null
      ) => `/shows/${id}/${editSection}/${addMediaSection}/upload`,
      libraryCrew: (showId: number | string) => `/shows/${showId}/crew/library`
    },
    paymentMethods: {
      home: () => '/paymentMethods',
      retail: () => '/paymentMethods/retail',
      editRetail: (paymentMethodId: number | string) =>
        `/paymentMethods/retail/${paymentMethodId}`,
      groups: () => '/paymentMethods/groups',
      ecommerce: () => '/paymentMethods/ecommerce'
    },
    events: {
      home: () => '/events',
      edit: (id: number | string) => `/events/${id}`,
      editGeneralOptions: (id: number | string) => `/events/${id}/general`,
      editSaleOptions: (id: number | string) => `/events/${id}/saleOptions`,
      editPricingOptions: (id: number | string) =>
        `/events/${id}/pricingOptions`,
      eventDiscountDetails: (id: number | string) => `/events/${id}/discounts`,
      editSeatsOptions: (id: number | string) => `/events/${id}/seatsOptions`
    },
    seatOptions: (id: number | string) => `/eventSeatOptions/${id}`,
    customerDisplay: {
      home: () => `/customerDisplay`
    },
    // TODO: cleanup to constants
    venues: {
      home: () => `/${v.veneus}`,
      add: () => `/${v.veneus}/add`,
      detail: (id: number | string) => `/${v.veneus}/${id}`,
      edit: (id: number | string) => `/${v.veneus}/${id}/edit`,
      detailAuditorium: (venueId: number | string, id: number | string) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${id}`,
      editAuditorium: (venueId: number | string, id: number | string) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${id}/edit`,
      addAuditoriumLayout: (
        venueId: number | string,
        auditoriumId: number | string
      ) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${auditoriumId}/${v.layouts}/add`,
      detailAuditoriumLayout: (
        venueId: number | string,
        auditoriumId: number | string,
        layoutId: number | string
      ) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${auditoriumId}/${v.layouts}/${layoutId}`,
      editAuditoriumLayout: (
        venueId: number | string,
        auditoriumId: number | string,
        layoutId: number | string
      ) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${auditoriumId}/${v.layouts}/${layoutId}/edit`,
      addLayoutPricing: (
        venueId: number | string,
        auditoriumId: number | string,
        auditoriumLayoutId: number | string
      ) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${auditoriumId}/${v.layouts}/${auditoriumLayoutId}/${v.pricings}/add`,
      editLayoutPricing: (
        venueId: number | string,
        auditoriumId: number | string,
        auditoriumLayoutId: number | string,
        layoutPricingId: number | string
      ) =>
        `/${v.veneus}/${venueId}/${v.auditoriums}/${auditoriumId}/${v.layouts}/${auditoriumLayoutId}/${v.pricings}/${layoutPricingId}/edit`
    },
    support: {
      duplicateAuditoriumLayout: () => '/support/duplicateAuditoriumLayout'
    },
    notFound: () => '/notFound'
  },
  login: () => '/login',
  recoverPassword: () => '/recoverPassword',
  invitedUser: () => `/invitedUser`
}

export const getUrlParam = (_match: match, param: string) =>
  _.get(_match, `params.${param}`)

export const toPlaceholderParam = (param: string) => `:${param}`

export const useHistoryGoBack = () => {
  const history = useHistory()
  // This is where magic constant "2" comes from
  // https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app
  return {
    goBack: ({fallbackRoute}: {fallbackRoute: string}) => {
      // If for example user opens bookmarked page in new tab and does some actions
      // that calls `history.goBack` (should pop from stack), we would send him out of application,
      // which is annoying.
      // Instead, if this is the case, we rewrite current history with fallback supplied.
      history.length > 2 ? history.goBack() : history.replace(fallbackRoute)
    }
  }
}
