import TourOutlinedIcon from '@mui/icons-material/TourOutlined'
import {Box, useMediaQuery} from '@mui/material'
import dayjs from 'dayjs'
import {compact} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CashDeskToursQuery,
  TourState,
  TourTimeSlotState
} from '../../../../../__generated__/schema'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {RenderOnData} from '../../../../common'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../../common/datePicker/types'
import {DatePickerButton} from '../../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../../common/SubHeaderToolbar'
import {MediaSizes} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {EnabledDivisionsTabs} from '../../components/EnabledDivisionsTabs'
import {ReloadButton} from '../../components/ReloadButton'
import {useGetLightweightDivisions} from '../../graphql'
import {CenteredLayout} from '../../Layout'
import {useGetFilterDateRange} from '../../utils'
import {useCashDeskTours} from './graphql'
import {TourItem} from './TourItem'

export const Content: React.FC = () => {
  const {t} = useTranslation()
  const {divisions} = useGetLightweightDivisions()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const getFilterDateRange = useGetFilterDateRange()
  const [enabledDivisionsIds] = useLocalStorageState<number[]>(
    LocalStorageKey.EnabledDivisions,
    []
  )
  const enabledDivisions = compact(
    enabledDivisionsIds.map((ed) => divisions.find(({id}) => id === ed))
  )
  const areDivisionTabsShown = enabledDivisions.length > 1
  const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(
    enabledDivisionsIds ? enabledDivisionsIds[0] : null
  )
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.Today
    }
  )
  const {data, error, loading, refetch} = useCashDeskTours({
    filter: {
      states: [TourState.Active],
      divisionId: selectedDivisionId
    },
    timeSlotsFilter: {
      state: TourTimeSlotState.Published,
      isAvailableOnRetail: true,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'startsAtFrom',
        filterNameTo: 'startsAtTo'
      })
    },
    paginationInput: {offset: 0, limit: 50}
  })
  const handleTabChange = useCallback(
    (e: React.ChangeEvent<{}>, divisionId: number) => {
      setSelectedDivisionId(divisionId)
    },
    []
  )
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => setSelectedDate(date),
    []
  )
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        height: '100%'
      }}
    >
      <SubHeaderToolbar
        title={t('Tours')}
        rightActions={[
          <DatePickerButton
            key="tour-date-picker"
            tooltip={t('Tour date')}
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.Today
            }}
            groups={[TabGroup.Now, TabGroup.Future]}
            excludedValues={[TabNow.UntilToday]}
            minDateFrom={dayjs().endOf('d')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
        sx={{pl: isPhablet ? 0 : 8}}
      />
      <div>
        {areDivisionTabsShown && (
          <EnabledDivisionsTabs
            enabledDivisions={enabledDivisions}
            selectedDivisionId={selectedDivisionId}
            onTabChange={handleTabChange}
          />
        )}
      </div>
      <Box sx={{overflowY: 'auto', pb: 2}}>
        <CenteredLayout>
          <ChildrenOnEffectiveClientSelected>
            <RenderOnData<CashDeskToursQuery>
              data={data}
              loading={loading}
              error={error}
              errorMessage={t<string>('Error while loading tours')}
              ignoreLoadingIfData
              dataCondition={(data) => Array.isArray(data.tours.items)}
            >
              {({tours}) =>
                tours.items.length ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      pt: 3,
                      pb: 10
                    }}
                  >
                    {tours.items.map((tour) => (
                      <TourItem
                        key={tour.id}
                        tour={tour}
                        refetch={refetch}
                        isPhablet={isPhablet}
                      />
                    ))}
                  </Box>
                ) : (
                  <Blank
                    title={t('No tours found')}
                    IconComp={TourOutlinedIcon}
                  />
                )
              }
            </RenderOnData>
          </ChildrenOnEffectiveClientSelected>
        </CenteredLayout>
      </Box>
    </Box>
  )
}
