import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CustomerGroupForm} from './CustomerGroupForm'
import {useCreateCustomerGroup} from './graphql'
import {ICustomerGroupForm} from './types'

const CREATE_CUSTOMER_GROUP_FORM_ID = 'createCustomerGroupForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface ICreateCustomerGroupDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateCustomerGroupDrawer: React.FC<ICreateCustomerGroupDrawerProps> =
  ({isOpen, onClose}: ICreateCustomerGroupDrawerProps) => {
    const {t} = useTranslation()
    const createCustomerGroup = useCreateCustomerGroup()
    const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
      useMutationAssistanceHooks()
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: ICustomerGroupForm) => {
        try {
          setShowBackdrop(true)
          await createCustomerGroup(data)
          addInfoNotification(t('Customer group created'))
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating customer group'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createCustomerGroup,
        defaultErrorHandler,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create customer group')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_CUSTOMER_GROUP_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
        >
          <CustomerGroupForm
            formId={CREATE_CUSTOMER_GROUP_FORM_ID}
            onSubmit={handleSubmit}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
