import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AssignProductToProductGroupMutation,
  AssignProductToProductGroupMutationVariables,
  CreateProductGroupInput,
  CreateProductGroupMutation,
  CreateProductGroupMutationVariables,
  ProductGroupAvailabilityQuery,
  ProductGroupEditDataQuery,
  ProductGroupEditDataQueryVariables,
  ProductGroupQuery,
  ProductGroupQueryVariables,
  ProductGroupsFilterInput,
  ProductGroupsQuery,
  ProductGroupsQueryVariables,
  RemoveProductFromProductGroupMutation,
  RemoveProductFromProductGroupMutationVariables,
  UpdateProductGroupMutation,
  UpdateProductGroupMutationVariables,
  UpdateProductGroupsOrderMutation,
  UpdateProductGroupsOrderMutationVariables,
  UpdateProductsOrderInProductGroupMutation,
  UpdateProductsOrderInProductGroupMutationVariables,
  UpsertProductGroupAvailabilityMutation,
  UpsertProductGroupAvailabilityMutationVariables
} from '../../../../__generated__/schema'
import {USER_FIELDS} from '../graphql'

const BASE_PRODUCT_GROUP_AVAILABILITY_FIELDS = gql`
  fragment BaseProductGroupAvailabilityFields on ProductGroupAvailability {
    id
    division {
      id
      name
    }
    isAvailableOnRetailChannel
    isAvailableOnECommerceChannel
  }
`

const BASE_PRODUCT_GROUP_PRODUCT_FIELDS = gql`
  fragment BaseProductGroupProductFields on Product {
    id
    name
    state
    unit
    productType {
      id
      name
    }
    priceLookupCode
    internalCode
  }
`

const BASE_PRODUCT_GROUP_FIELDS = gql`
  fragment BaseProductGroupFields on ProductGroup {
    id
    icon
    color
    state
    name
    internalDescription
    description
  }
`

const EXTENDED_PRODUCT_GROUP_FIELDS = gql`
  ${BASE_PRODUCT_GROUP_FIELDS}
  ${BASE_PRODUCT_GROUP_AVAILABILITY_FIELDS}
  ${BASE_PRODUCT_GROUP_PRODUCT_FIELDS}
  ${USER_FIELDS}
  fragment ExtendedProductGroupFields on ProductGroup {
    ...BaseProductGroupFields
    availability {
      ...BaseProductGroupAvailabilityFields
    }
    products {
      ...BaseProductGroupProductFields
    }
    createdBy {
      ...UserFields
    }
    updatedAt
    updatedBy {
      ...UserFields
    }
  }
`

const PRODUCT_GROUPS = gql`
  ${BASE_PRODUCT_GROUP_FIELDS}
  query ProductGroups($filter: ProductGroupsFilterInput) {
    productGroups(filter: $filter) {
      ...BaseProductGroupFields
    }
  }
`

export const useProductGroups = (filter?: ProductGroupsFilterInput) =>
  useQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(PRODUCT_GROUPS, {
    variables: {filter},
    fetchPolicy: 'network-only'
  })

const CREATE_PRODUCT_GROUP = gql`
  mutation CreateProductGroup($input: CreateProductGroupInput!) {
    createProductGroup(input: $input) {
      id
    }
  }
`

export const useCreateProductGroup = () => {
  const [createProductGroup] =
    useMutation<
      CreateProductGroupMutation,
      CreateProductGroupMutationVariables
    >(CREATE_PRODUCT_GROUP)
  return useCallback(
    (input: CreateProductGroupInput) =>
      createProductGroup({variables: {input}}),
    [createProductGroup]
  )
}

const PRODUCT_GROUP = gql`
  ${EXTENDED_PRODUCT_GROUP_FIELDS}
  query ProductGroup($id: PositiveInt!) {
    productGroup(id: $id) {
      ...ExtendedProductGroupFields
    }
  }
`

export const useProductGroup = (id: number) =>
  useQuery<ProductGroupQuery, ProductGroupQueryVariables>(PRODUCT_GROUP, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const PRODUCT_GROUP_EDIT_DATA = gql`
  ${BASE_PRODUCT_GROUP_FIELDS}
  query ProductGroupEditData($id: PositiveInt!) {
    productGroup(id: $id) {
      ...BaseProductGroupFields
    }
  }
`

export const useProductGroupEditData = (id: number) =>
  useQuery<ProductGroupEditDataQuery, ProductGroupEditDataQueryVariables>(
    PRODUCT_GROUP_EDIT_DATA,
    {
      variables: {id},
      fetchPolicy: 'network-only'
    }
  )

const UPDATE_PRODUCT_GROUP = gql`
  ${EXTENDED_PRODUCT_GROUP_FIELDS}
  mutation UpdateProductGroup(
    $id: PositiveInt!
    $input: UpdateProductGroupInput!
  ) {
    updateProductGroup(id: $id, input: $input) {
      ...ExtendedProductGroupFields
    }
  }
`

export const useUpdateProductGroup = () => {
  const [updateProductGroup] =
    useMutation<
      UpdateProductGroupMutation,
      UpdateProductGroupMutationVariables
    >(UPDATE_PRODUCT_GROUP)
  return useCallback(
    (variables: UpdateProductGroupMutationVariables) =>
      updateProductGroup({variables}),
    [updateProductGroup]
  )
}

const PRODUCT_GROUP_AVAILABILITY = gql`
  ${BASE_PRODUCT_GROUP_AVAILABILITY_FIELDS}
  query ProductGroupAvailability($id: PositiveInt!) {
    productGroup(id: $id) {
      id
      availability {
        ...BaseProductGroupAvailabilityFields
      }
    }
  }
`

export const useProductGroupAvailability = (id: number) =>
  useQuery<ProductGroupAvailabilityQuery, ProductGroupEditDataQueryVariables>(
    PRODUCT_GROUP_AVAILABILITY,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

const UPSERT_PRODUCT_GROUP_AVAILABILITY = gql`
  ${BASE_PRODUCT_GROUP_AVAILABILITY_FIELDS}
  mutation UpsertProductGroupAvailability(
    $id: PositiveInt!
    $productGroupAvailabilityInputs: [ProductGroupAvailabilityInput!]!
  ) {
    upsertProductGroupAvailability(
      id: $id
      productGroupAvailabilityInputs: $productGroupAvailabilityInputs
    ) {
      id
      availability {
        ...BaseProductGroupAvailabilityFields
      }
    }
  }
`

export const useUpsertProductGroupAvailability = () => {
  const [upsertProductGroupAvailability] = useMutation<
    UpsertProductGroupAvailabilityMutation,
    UpsertProductGroupAvailabilityMutationVariables
  >(UPSERT_PRODUCT_GROUP_AVAILABILITY)
  return useCallback(
    (variables: UpsertProductGroupAvailabilityMutationVariables) =>
      upsertProductGroupAvailability({variables}),
    [upsertProductGroupAvailability]
  )
}

const ASSIGN_PRODUCT_TO_PRODUCT_GROUP = gql`
  ${BASE_PRODUCT_GROUP_PRODUCT_FIELDS}
  mutation AssignProductToProductGroup(
    $productId: PositiveInt!
    $productGroupId: PositiveInt!
  ) {
    assignProductToProductGroup(
      productId: $productId
      productGroupId: $productGroupId
    ) {
      id
      products {
        ...BaseProductGroupProductFields
      }
    }
  }
`

export const useAssignProductToProductGroup = () => {
  const [assignProductToProductGroup] = useMutation<
    AssignProductToProductGroupMutation,
    AssignProductToProductGroupMutationVariables
  >(ASSIGN_PRODUCT_TO_PRODUCT_GROUP)
  return useCallback(
    (variables: AssignProductToProductGroupMutationVariables) =>
      assignProductToProductGroup({variables}),
    [assignProductToProductGroup]
  )
}

const REMOVE_PRODUCT_FROM_PRODUCT_GROUP = gql`
  ${BASE_PRODUCT_GROUP_PRODUCT_FIELDS}
  mutation RemoveProductFromProductGroup(
    $productId: PositiveInt!
    $productGroupId: PositiveInt!
  ) {
    removeProductFromProductGroup(
      productId: $productId
      productGroupId: $productGroupId
    ) {
      id
      products {
        ...BaseProductGroupProductFields
      }
    }
  }
`

export const useRemoveProductFromProductGroup = () => {
  const [removeProductFromProductGroup] = useMutation<
    RemoveProductFromProductGroupMutation,
    RemoveProductFromProductGroupMutationVariables
  >(REMOVE_PRODUCT_FROM_PRODUCT_GROUP)
  return useCallback(
    (variables: RemoveProductFromProductGroupMutationVariables) =>
      removeProductFromProductGroup({variables}),
    [removeProductFromProductGroup]
  )
}

const UPDATE_PRODUCTS_ORDER_IN_PRODUCT_GROUP = gql`
  ${BASE_PRODUCT_GROUP_PRODUCT_FIELDS}
  mutation UpdateProductsOrderInProductGroup(
    $productGroupId: PositiveInt!
    $productIds: [PositiveInt!]!
  ) {
    updateProductsOrderInProductGroup(
      productGroupId: $productGroupId
      productIds: $productIds
    ) {
      id
      products {
        ...BaseProductGroupProductFields
      }
    }
  }
`

export const useUpdateProductsOrderInProductGroup = () => {
  const [updateProductsOrderInProductGroup] = useMutation<
    UpdateProductsOrderInProductGroupMutation,
    UpdateProductsOrderInProductGroupMutationVariables
  >(UPDATE_PRODUCTS_ORDER_IN_PRODUCT_GROUP)
  return useCallback(
    (variables: UpdateProductsOrderInProductGroupMutationVariables) =>
      updateProductsOrderInProductGroup({variables}),
    [updateProductsOrderInProductGroup]
  )
}

const UPDATE_PRODUCT_GROUPS_ORDER = gql`
  mutation UpdateProductGroupsOrder($ids: [PositiveInt!]!) {
    updateProductGroupsOrder(ids: $ids) {
      id
    }
  }
`

export const useUpdateProductGroupsOrder = () => {
  const [updateProductGroupsOrder] = useMutation<
    UpdateProductGroupsOrderMutation,
    UpdateProductGroupsOrderMutationVariables
  >(UPDATE_PRODUCT_GROUPS_ORDER)
  return useCallback(
    (variables: UpdateProductGroupsOrderMutationVariables) =>
      updateProductGroupsOrder({variables}),
    [updateProductGroupsOrder]
  )
}
