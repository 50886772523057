import {useCallback} from 'react'
import {DeviceEffect} from './types'

const successAudio = new Audio('/success.mp3')
const failedAudio = new Audio('/failed.mp3')
const actionRequiredAudio = new Audio('/actionRequired.mp3')

export const performSuccessVibrateEffect = () => {
  navigator.vibrate(200)
}
export const performFailedVibrateEffect = () => {
  navigator.vibrate([200, 80, 200])
}
export const performActionRequiredVibrateEffect = () => {
  navigator.vibrate(1000)
}

export const performSuccessSoundEffect = () => {
  successAudio.play()
}
export const performFailedSoundEffect = () => {
  failedAudio.play()
}
export const performActionRequiredSoundEffect = () => {
  actionRequiredAudio.play()
}

export const usePerformSuccessEffect = (deviceEffect: DeviceEffect) =>
  useCallback(() => {
    switch (deviceEffect) {
      case DeviceEffect.Sound:
        performSuccessSoundEffect()
        break
      case DeviceEffect.VibrationAndSound:
        performSuccessSoundEffect()
        performSuccessVibrateEffect()
        break
      case DeviceEffect.Vibration:
        performSuccessVibrateEffect()
        break
      case DeviceEffect.NoEffect:
      default:
        return
    }
  }, [deviceEffect])

export const usePerformFailedEffect = (deviceEffect: DeviceEffect) =>
  useCallback(() => {
    switch (deviceEffect) {
      case DeviceEffect.Sound:
        performFailedSoundEffect()
        break
      case DeviceEffect.VibrationAndSound:
        performFailedSoundEffect()
        performFailedVibrateEffect()
        break
      case DeviceEffect.Vibration:
        performFailedVibrateEffect()
        break
      case DeviceEffect.NoEffect:
      default:
        return
    }
  }, [deviceEffect])

export const usePerformActionRequiredEffect = (deviceEffect: DeviceEffect) =>
  useCallback(() => {
    switch (deviceEffect) {
      case DeviceEffect.Sound:
        performActionRequiredSoundEffect()
        break
      case DeviceEffect.VibrationAndSound:
        performActionRequiredSoundEffect()
        performActionRequiredVibrateEffect()
        break
      case DeviceEffect.Vibration:
        performActionRequiredVibrateEffect()
        break
      case DeviceEffect.NoEffect:
      default:
        return
    }
  }, [deviceEffect])
