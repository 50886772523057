import {
  Box,
  Button,
  Chip,
  List,
  ListItemButton,
  ListItemText
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {WebsitePageState} from '../../../../../__generated__/schema'
import {useTranslateWebsitePageState} from '../../../../../hooks/translateWebsitePageState'
import {EntityStateChip} from '../../../../common'
import {websitePageStateColors} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {normalize} from '../../utils'
import {IPagesSearchFilter, PagesSearch} from './PagesSearch'
import {useWebsitePageContext} from './WebsitePageContext'

interface IPagesProps {
  onCreatePageButtonClick?: () => void
}

export const Pages: React.FC<IPagesProps> = ({
  onCreatePageButtonClick
}: IPagesProps) => {
  const {t} = useTranslation()
  const translateWebsitePageState = useTranslateWebsitePageState()
  const {setSelectedWebsitePage, selectedWebsitePage, websitePages} =
    useWebsitePageContext()
  const [searchFilter, setSearchFilter] = useState<IPagesSearchFilter>({})
  const filteredWebsitePages = searchFilter.hasText
    ? websitePages.filter(
        ({title, slug}) =>
          normalize(title)
            .toLowerCase()
            .indexOf(normalize(searchFilter.hasText!).toLowerCase()) > -1 ||
          normalize(slug)
            .toLowerCase()
            .indexOf(normalize(searchFilter.hasText!).toLowerCase()) > -1
      )
    : websitePages
  return (
    <Box sx={{pb: 1, width: '100%'}}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'background.paper',
          py: 1,
          px: 2,
          borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
        }}
      >
        <PagesSearch onFilterChange={setSearchFilter} />
      </Box>
      {filteredWebsitePages.length > 0 ? (
        <List sx={{overflowY: 'auto', p: 0}}>
          {filteredWebsitePages.map((websitePage) => (
            <ListItemButton
              key={websitePage.ID}
              sx={{px: 2}}
              selected={selectedWebsitePage?.ID === websitePage.ID}
              onClick={() => setSelectedWebsitePage(websitePage)}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                      <Chip
                        label={websitePage.localeCode.toUpperCase()}
                        sx={{height: 24}}
                      />
                      {websitePage.title}
                    </Box>
                    {websitePage.state !== WebsitePageState.Published && (
                      <EntityStateChip
                        colorConf={websitePageStateColors[websitePage.state]}
                        label={translateWebsitePageState(websitePage.state)}
                      />
                    )}
                  </Box>
                }
                primaryTypographyProps={{variant: 'body2', component: 'div'}}
              />
            </ListItemButton>
          ))}
        </List>
      ) : (
        <Blank
          title={t('No website page created')}
          actions={
            onCreatePageButtonClick && (
              <Button
                variant="contained"
                color="primary"
                onClick={onCreatePageButtonClick}
              >
                {t('Create')}
              </Button>
            )
          }
          contentSx={{'&&': {width: '100%'}}}
        />
      )}
    </Box>
  )
}
