import {
  Fade,
  Tooltip as MuiTooltip,
  TooltipProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../theme'

/**
 * @description: The tooltip needs to apply DOM event listeners (like onFocus, onBlur, onMouseOver, onMouseLeave, ...) to its child element.
 * If the child is a custom React element, you need to make sure that it spreads its properties to the underlying DOM element.
 * This is interface, that represents those props - and satisfy typescript.
 * Check [native implementation](https://github.com/mui-org/material-ui/blob/v4.x/packages/material-ui/src/Tooltip/Tooltip.js) for more details.
 *
 * Use this interface to extend custom components props of components, that are used as tooltip children!
 */
export interface ITooltipChildrenProps {
  title?: string
  className?: string

  [otherProps: string]: any
}

const useStylesBootstrap = makeStyles<Theme>((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white
  }
}))

export const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const classes = useStylesBootstrap()
  const {title, ...rest} = props
  return (
    <MuiTooltip
      classes={classes}
      enterDelay={300}
      TransitionComponent={Fade}
      TransitionProps={{timeout: 600}}
      title={title ? <Typography variant="body2">{title}</Typography> : ''}
      {...rest}
    />
  )
}
