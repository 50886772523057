import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Button, ClickAwayListener, Paper} from '@mui/material'
import Popper from '@mui/material/Popper'
import cn from 'classnames'
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js'
import {draftjsToMd, mdToDraftjs} from 'draftjs-md-converter'
import React, {SyntheticEvent, useCallback, useState} from 'react'
import {Editor} from 'react-draft-wysiwyg'
import {useTranslation} from 'react-i18next'
import {useDebounce} from '../../../hooks/debounce'
import {MenuItem} from '../Menu'
import boldIcon from './assets/bold-icon.svg'
import bulletedListIcon from './assets/bulleted-list-icon.svg'
import italicIcon from './assets/italic-icon.svg'
import linkIcon from './assets/link-icon.svg'
import linkOffIcon from './assets/link-off-icon.svg'
import numberedListIcon from './assets/numbered-list-icon.svg'
import redoIcon from './assets/redo-icon.svg'
import underline from './assets/underline-icon.svg'
import undoIcon from './assets/undo-icon.svg'
import {MarkdownEditorBlockType} from './types'
import {
  editorLabels,
  isMarkdownEditorBlockType,
  useTextEditorStyles,
  useTranslateMarkdownEditorBlockType
} from './utils'

const BlockTypeDropdown = (props: any) => {
  const {t} = useTranslation()
  const translateMarkdownEditorBlockType = useTranslateMarkdownEditorBlockType()
  const {currentState, onChange, doExpand, doCollapse, expanded} = props
  const selectedValue = currentState.blockType
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const getBlockItemClickHandler = useCallback(
    (blockType: MarkdownEditorBlockType) => (e: SyntheticEvent) => {
      e.stopPropagation()
      onChange(blockType)
      doCollapse()
      setAnchorEl(null)
    },
    [doCollapse, onChange]
  )
  const handleClickAway = useCallback(
    (e) => {
      e.stopPropagation()
      setAnchorEl(null)
      doCollapse()
    },
    [doCollapse]
  )
  return (
    <>
      <Button
        variant="text"
        endIcon={<ArrowDropDownIcon />}
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl((prevState) => (prevState ? null : e.currentTarget))
          doExpand()
        }}
      >
        {isMarkdownEditorBlockType(selectedValue)
          ? translateMarkdownEditorBlockType(selectedValue)
          : t('Mixed')}
      </Button>
      <Popper
        open={Boolean(anchorEl && expanded)}
        anchorEl={anchorEl}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 1
        })}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper elevation={8}>
            {Object.values(MarkdownEditorBlockType).map((blockType) => (
              <MenuItem
                key={blockType}
                label={translateMarkdownEditorBlockType(blockType)}
                onClick={getBlockItemClickHandler(blockType)}
                isSelected={blockType === selectedValue}
              />
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

interface IMarkdownEditorProps {
  defaultValue: string
  onChange: (value: string) => void
  onChangeDebunceMs?: number
}

export const MarkdownEditor: React.FC<IMarkdownEditorProps> = ({
  defaultValue,
  onChange,
  onChangeDebunceMs = 500
}: IMarkdownEditorProps) => {
  const {t} = useTranslation()
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(convertFromRaw(mdToDraftjs(defaultValue)))
  )
  const debuncedOnChange = useDebounce(onChange, onChangeDebunceMs)
  const handleEditorStateChange = useCallback(
    (state: EditorState) => {
      setEditorState(state)
      debuncedOnChange(draftjsToMd(convertToRaw(state.getCurrentContent())))
    },
    [debuncedOnChange]
  )
  const classes = useTextEditorStyles()
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleEditorStateChange}
      toolbar={{
        options: ['history', 'inline', 'list', 'blockType', 'link'],
        history: {
          className: classes.history,
          undo: {
            icon: undoIcon,
            title: t('Undo')
          },
          redo: {
            icon: redoIcon,
            title: t('Redo')
          }
        },
        blockType: {
          component: BlockTypeDropdown
        },
        inline: {
          options: ['bold', 'italic', 'underline'],
          bold: {icon: boldIcon, title: t('Bold')},
          italic: {icon: italicIcon, title: t('Italic')},
          underline: {icon: underline, title: t('Underline')}
        },
        list: {
          options: ['unordered', 'ordered'],
          unordered: {icon: bulletedListIcon, title: t('Unordered list')},
          ordered: {icon: numberedListIcon, title: t('Ordered list')}
        },
        link: {
          options: ['link', 'unlink'],
          link: {icon: linkIcon, title: t('Link')},
          unlink: {icon: linkOffIcon, title: t('Unlink')}
        }
      }}
      stripPastedStyles
      wrapperClassName={classes.wrapper}
      editorClassName={classes.editor}
      toolbarClassName={cn(classes.toolbar, classes.stickyToolbar)}
      localization={{locale: 'multi', translations: editorLabels(t)}}
    />
  )
}
