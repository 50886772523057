import {useTranslation} from 'react-i18next'
import {MessageType} from '../__generated__/schema'

export const useTranslateMessageType = () => {
  const {t} = useTranslation()
  const translatedTypes: Record<MessageType, string> = {
    [MessageType.Email]: t<string>('MessageType->Email'),
    [MessageType.Sms]: t<string>('MessageType->Sms')
  }
  return (type: MessageType) => translatedTypes[type]
}
