import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PaymentReportsFilterInput,
  PaymentReportType,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ReloadButton} from '../components/ReloadButton'
import {WideCenteredLayout} from '../Layout'
import {CreateMonthlyECommerceReportDrawer} from './CreateMonthlyECommerceReportDrawer'
import {usePaymentReports} from './graphql'
import {useColDefForMonthlyReports} from './utils'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(routeTo.admin.paymentReports.detail(id), {
        from: routeTo.admin.paymentReports.monthlyEcommerceReports()
      }),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IMonthlyEcommerceReportsProps {
  searchFilter: PaymentReportsFilterInput
}

export const MonthlyEcommerceReports: React.FC<IMonthlyEcommerceReportsProps> =
  ({searchFilter}: IMonthlyEcommerceReportsProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {
      state: isDrawerOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const {paginationInput, getDataGridPaginationProps} =
      useDataGridPagination()
    const {data, loading, error, refetch} = usePaymentReports({
      filter: {
        type: PaymentReportType.MonthlyECommerceReport,
        ...searchFilter
      },
      paginationInput
    })
    const fabClasses = useFabClasses()
    const hasPermissionToAccessDetail = P([
      PermissionCode.ReadPaymentReport,
      PermissionCode.ReadMonthlyEcommerceReport
    ])
    const columns = useColDefForMonthlyReports([
      {
        headerName: '',
        field: 'icon',
        valueGetter: ({id}) => id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48,
        hideable: hasPermissionToAccessDetail
      }
    ])
    if (error) {
      return <Error error={error} message={t('Error while payment reports')} />
    }
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr'
        }}
      >
        <SubHeaderToolbar
          title={t('Monthly ecommerce reports')}
          rightActions={[
            <ReloadButton
              key="reload-button"
              onReloadButtonClick={() => refetch()}
            />
          ]}
        />
        <WideCenteredLayout
          sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}
        >
          <DataGridTable
            columns={columns}
            loading={loading}
            rows={data?.paymentReports.items || []}
            localeText={{
              noRowsLabel: t('No monthly ecommerce reports to show')
            }}
            disableColumnFilter
            initialState={{
              pinnedColumns: {left: ['id', 'reportFrom'], right: ['icon']}
            }}
            columnVisibilityModel={{
              icon: hasPermissionToAccessDetail
            }}
            {...getDataGridPaginationProps(data?.paymentReports.pagination)}
          />
        </WideCenteredLayout>
        {P([
          PermissionCode.CreateMonthlyECommercePaymentReport,
          PermissionCode.ReadAvailableInputDataForMonthlyECommerceReports
        ]) && (
          <>
            <CreateMonthlyECommerceReportDrawer
              isOpen={isDrawerOpen}
              onClose={closeDrawer}
            />
            <CreateFab classes={fabClasses} onClick={openDrawer} />
          </>
        )}
      </Box>
    )
  }
