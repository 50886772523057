import {CanvasObjectType} from '@attendio/shared-components'
import _ from 'lodash'
import {IObjectsState, ITicketType} from './editor/stateTypes'

// Note: frontend also uses `color`, but backend does not, therefore this explicit naming
export interface BackendPricingJSON {
  [key: string]: {id: number}
}

export const getUnlabeledLayoutPricingObjectsCounts = (
  auditoriumLayout: IObjectsState,
  pricing: BackendPricingJSON,
  ticketTypes: Array<Pick<ITicketType, 'id'>>
) => {
  const ticketTypesMap = _.keyBy(ticketTypes, 'id')

  return Object.values(auditoriumLayout).reduce(
    (res, layoutObject) => {
      // We care only about seats and zones
      if (
        layoutObject.type !== CanvasObjectType.Seat &&
        layoutObject.type !== CanvasObjectType.Zone
      ) {
        return res
      }

      const objectId = layoutObject.config.id

      // Mark as missing if object id is not present in pricing layout
      // or its assigned ticket type does not exist
      if (!pricing[objectId] || !ticketTypesMap[pricing[objectId].id]) {
        return {...res, [layoutObject.type]: res[layoutObject.type] + 1}
      }

      return res
    },
    {
      [CanvasObjectType.Seat]: 0,
      [CanvasObjectType.Zone]: 0
    }
  )
}

export const getUnassignedTicketTypesCount = (
  pricing: BackendPricingJSON,
  ticketTypes: Array<Pick<ITicketType, 'id'>>
) => {
  const usedTicketTypes: {[key: number]: boolean} = Object.values(
    pricing
  ).reduce((res, {id: ticketTypeId}) => ({...res, [ticketTypeId]: true}), {})

  const unassignedTicketTypesCount =
    ticketTypes.length - Object.keys(usedTicketTypes).length
  return unassignedTicketTypesCount
}

export const canActivateLayoutPricing = (
  auditoriumLayout: IObjectsState,
  pricing: BackendPricingJSON,
  ticketTypes: Array<Pick<ITicketType, 'id'>>
) => {
  const unlabelledCounts = getUnlabeledLayoutPricingObjectsCounts(
    auditoriumLayout,
    pricing,
    ticketTypes
  )
  const hasNoUnlabelledObjects = Object.values(unlabelledCounts).every(
    (v) => v === 0
  )
  const hasAllTicketTypesAssigned =
    getUnassignedTicketTypesCount(pricing, ticketTypes) === 0

  return hasNoUnlabelledObjects && hasAllTicketTypesAssigned
}
