import React from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps} from 'react-router-dom'
import {RenderOnData} from '../../../common'
import {DiscountDetail} from './DiscountDetail'

import {useGetDiscount} from './graphql'

interface IDiscountProps
  extends RouteComponentProps<{
    discountId: string
  }> {}

export const Discount: React.FC<IDiscountProps> = ({match}: IDiscountProps) => {
  const {data, loading, error} = useGetDiscount(
    parseInt(match.params.discountId, 10)
  )
  const {t} = useTranslation()
  return (
    <RenderOnData
      loading={loading}
      error={error}
      dataCondition={(data) => Boolean(data?.discount)}
      errorMessage={t<string>('Could not load discount detail.')}
      data={data}
    >
      {({discount}) => <DiscountDetail discount={discount} />}
    </RenderOnData>
  )
}
