import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AddItemsToClaimMutation,
  AddItemsToClaimMutationVariables,
  ApproveClaimMutation,
  ApproveClaimMutationVariables,
  ClaimQuery,
  ClaimQueryVariables,
  ClaimsQuery,
  ClaimsQueryVariables,
  CreateClaimMutation,
  CreateClaimMutationVariables,
  DeleteClaimMutation,
  DeleteClaimMutationVariables,
  RefundClaimByInHouseVoucherMutation,
  RefundClaimByInHouseVoucherMutationVariables,
  RefundClaimOnEcommerceChannelMutation,
  RefundClaimOnEcommerceChannelMutationVariables,
  RefundClaimOnRetailChannelMutation,
  RefundClaimOnRetailChannelMutationVariables,
  RemoveItemsFromClaimMutation,
  RemoveItemsFromClaimMutationVariables,
  UpdateClaimReasonMutation,
  UpdateClaimReasonMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {NARROW_SALE_PROPERTIES_FRAGMENT} from '../cashDesk/graphql'
import {EVENT_PROPERTIES_FRAGMENT} from '../events/graphql'
import {
  LEAD_PROPERTIES_FRAGMENT,
  PRODUCT_ITEM_PROPERTIES_FRAGMENT,
  TICKET_ITEM_PROPERTIES_FRAGMENT,
  TRANSLATED_LOCALES_FRAGMENT,
  USER_FIELDS
} from '../graphql'

const CLAIM_PROPERTIES_FRAGMENT = gql`
  fragment ClaimProperties on Claim {
    id
    reason
    itemType
    state
    price
    itemsCount
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    createdAt
    createdByName
    updatedAt
    updatedByName
    approvedAt
    approvedByName
    refundedAt
    refundedByName
    event {
      ...EventProperties
    }
    sale {
      itemType
      ...NarrowSaleProperties
    }
    lead {
      ...LeadProperties
    }
    tourTimeSlot {
      id
      startsAt
      ageClassificationCode
      versionCode
      guide {
        ...UserFields
      }
      tour {
        id
        name
      }
    }
    payment {
      id
      amount
      currency
      channel
      createdAt
    }
    refundIntents {
      id
      state
      payment {
        id
        amount
        currency
        channel
        createdAt
      }
      paymentMethod {
        id
        name
        serviceProvider
        type
      }
      amount
      createdAt
      thirdPartyPaymentId
    }
    division {
      id
      client {
        id
        currency
      }
    }
  }
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${EVENT_PROPERTIES_FRAGMENT}
  ${NARROW_SALE_PROPERTIES_FRAGMENT}
  ${LEAD_PROPERTIES_FRAGMENT}
  ${USER_FIELDS}
`

const NARROW_CLAIM_PROPERTIES_FRAGMENT = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment NarrowClaimProperties on Claim {
    id
    reason
    state
    price
    itemsCount
    createdAt
    createdByName
    updatedAt
    updatedByName
    approvedAt
    approvedByName
    refundedAt
    refundedByName
    itemType
    tourTimeSlot {
      id
      startsAt
      tour {
        id
        name
      }
    }
    event {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
    }
  }
`

const GET_CLAIMS = gql`
  ${NARROW_CLAIM_PROPERTIES_FRAGMENT}
  ${PAGINATION_FRAGMENT}
  query claims($filter: ClaimsFilterInput, $paginationInput: PaginationInput!) {
    claims(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowClaimProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useClaims = (variables: ClaimsQueryVariables) =>
  useQuery<ClaimsQuery, ClaimsQueryVariables>(GET_CLAIMS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_CLAIM_MUTATION = gql`
  mutation createClaim($reason: String!, $itemIds: [Int!]!) {
    createClaim(reason: $reason, itemIds: $itemIds) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useCreateClaim = () => {
  const [createClaim] = useMutation<
    CreateClaimMutation,
    CreateClaimMutationVariables
  >(CREATE_CLAIM_MUTATION)
  return (variables: CreateClaimMutationVariables) =>
    createClaim({
      variables
    })
}

const GET_CLAIM = gql`
  query claim($id: Int!) {
    claim(id: $id) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useClaim = (id: number) =>
  useQuery<ClaimQuery, ClaimQueryVariables>(GET_CLAIM, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

const REMOVE_ITEMS_FROM_CLAIM_MUTATION = gql`
  mutation removeItemsFromClaim($claimId: Int!, $itemIds: [Int!]!) {
    removeItemsFromClaim(claimId: $claimId, itemIds: $itemIds) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useRemoveItemsFromClaim = () => {
  const [removeItemsFromClaim] = useMutation<
    RemoveItemsFromClaimMutation,
    RemoveItemsFromClaimMutationVariables
  >(REMOVE_ITEMS_FROM_CLAIM_MUTATION)
  return (variables: RemoveItemsFromClaimMutationVariables) =>
    removeItemsFromClaim({
      variables
    })
}

const ADD_ITEMS_TO_CLAIM_MUTATION = gql`
  mutation addItemsToClaim($claimId: Int!, $itemIds: [Int!]!) {
    addItemsToClaim(claimId: $claimId, itemIds: $itemIds) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useAddItemsToClaim = () => {
  const [addItemsToClaim] = useMutation<
    AddItemsToClaimMutation,
    AddItemsToClaimMutationVariables
  >(ADD_ITEMS_TO_CLAIM_MUTATION)
  return (variables: AddItemsToClaimMutationVariables) =>
    addItemsToClaim({
      variables
    })
}

const APPROVE_CLAIM_MUTATION = gql`
  mutation approveClaim($id: Int!) {
    approveClaim(id: $id) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useApproveClaim = () => {
  const [approveClaim] = useMutation<
    ApproveClaimMutation,
    ApproveClaimMutationVariables
  >(APPROVE_CLAIM_MUTATION)
  return (variables: ApproveClaimMutationVariables) =>
    approveClaim({
      variables
    })
}

const DELETE_CLAIM_MUTATION = gql`
  mutation deleteClaim($id: Int!) {
    deleteClaim(id: $id) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useDeleteClaim = () => {
  const [deleteClaim] = useMutation<
    DeleteClaimMutation,
    DeleteClaimMutationVariables
  >(DELETE_CLAIM_MUTATION)
  return (variables: DeleteClaimMutationVariables) =>
    deleteClaim({
      variables
    })
}

const UPDATE_CLAIM_REASON = gql`
  mutation updateClaimReason($id: Int!, $reason: String!) {
    updateClaimReason(id: $id, reason: $reason) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useUpdateClaimReason = () => {
  const [updateClaimReason] =
    useMutation<UpdateClaimReasonMutation, UpdateClaimReasonMutationVariables>(
      UPDATE_CLAIM_REASON
    )
  return (variables: UpdateClaimReasonMutationVariables) =>
    updateClaimReason({
      variables
    })
}

export const GET_SALE = gql`
  query sale($id: Int!) {
    sale(id: $id) {
      ...NarrowSaleProperties
    }
  }
  ${NARROW_SALE_PROPERTIES_FRAGMENT}
`

const REFUND_CLAIM_ON_RETAIL_CHANNEL = gql`
  mutation refundClaimOnRetailChannel(
    $id: Int!
    $refundIntentInputs: [RefundIntentInput!]!
  ) {
    refundClaimOnRetailChannel(
      id: $id
      refundIntentInputs: $refundIntentInputs
    ) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useRefundClaimOnRetailChannel = () => {
  const [refundClaimOnRetailChannel] = useMutation<
    RefundClaimOnRetailChannelMutation,
    RefundClaimOnRetailChannelMutationVariables
  >(REFUND_CLAIM_ON_RETAIL_CHANNEL)
  return (variables: RefundClaimOnRetailChannelMutationVariables) =>
    refundClaimOnRetailChannel({
      variables
    })
}

const REFUND_CLAIM_ON_ECOMMERCE_CHANNEL = gql`
  mutation refundClaimOnEcommerceChannel(
    $id: Int!
    $refundIntentInputs: [RefundIntentInput!]!
  ) {
    refundClaimOnECommerceChannel(
      id: $id
      refundIntentInputs: $refundIntentInputs
    ) {
      ...ClaimProperties
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useRefundClaimOnECommerceChannel = () => {
  const [refundClaimOnECommerceChannel] = useMutation<
    RefundClaimOnEcommerceChannelMutation,
    RefundClaimOnEcommerceChannelMutationVariables
  >(REFUND_CLAIM_ON_ECOMMERCE_CHANNEL)
  return (variables: RefundClaimOnEcommerceChannelMutationVariables) =>
    refundClaimOnECommerceChannel({
      variables
    })
}

export const LIGHTWEIGHT_VOUCHER_CAMPAIGNS = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightVoucherCampaigns(
    $filter: VoucherCampaignsFilterInput
    $paginationInput: PaginationInput!
  ) {
    voucherCampaigns(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        redemptionLimit
        initialValidity
        retailPaymentMethodId
        ecommercePaymentMethodId
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

const REFUND_CLAIM_BY_IN_HOUSE_VOUCHER = gql`
  mutation refundClaimByInHouseVoucher(
    $id: PositiveInt!
    $input: VoucherInput!
  ) {
    refundClaimByInHouseVoucher(id: $id, input: $input) {
      claim {
        ...ClaimProperties
      }
      voucher {
        id
        code
      }
    }
  }
  ${CLAIM_PROPERTIES_FRAGMENT}
`

export const useRefundClaimByInHouseVoucher = () => {
  const [refundClaimByInHouseVoucher] = useMutation<
    RefundClaimByInHouseVoucherMutation,
    RefundClaimByInHouseVoucherMutationVariables
  >(REFUND_CLAIM_BY_IN_HOUSE_VOUCHER)
  return useCallback(
    (variables: RefundClaimByInHouseVoucherMutationVariables) =>
      refundClaimByInHouseVoucher({variables}),
    [refundClaimByInHouseVoucher]
  )
}
