import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import {Box, IconButton, Typography} from '@mui/material'
import {gridClasses} from '@mui/x-data-grid'
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowOrderChangeParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  BaseProductGroupFieldsFragment,
  ErrorMessages,
  PermissionCode,
  ProductGroupsQuery,
  ProductGroupState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateProductGroupState} from '../../../../hooks/translateProductGroupState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {ColorBox} from '../../../common/ColorBox'
import {DataGridTable} from '../../../common/DataGridTable'
import {IconBox} from '../../../common/IconBox'
import {getProductGroupIcon} from '../../../common/productGroupIcon'
import {productGroupStateColors} from '../../../constants'
import {WideCenteredLayout} from '../Layout'
import {CreateProductGroupDrawer} from './CreateProductGroupDrawer'
import {useProductGroups, useUpdateProductGroupsOrder} from './graphql'

const NameRenderer = ({
  group
}: {
  group: ProductGroupsQuery['productGroups'][number]
}) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
    {group.icon ? (
      <IconBox
        Icon={getProductGroupIcon(group.icon)}
        hexColor={group.color}
        hideBackground
        sx={{width: 24, height: 24}}
        iconSx={{width: 24, height: 24}}
      />
    ) : (
      <ColorBox hexColor={group.color} />
    )}
    <Typography>{group.name}</Typography>
  </Box>
)

const StateRenderer = ({state}: {state: ProductGroupState}) => {
  const translateProductGroupState = useTranslateProductGroupState()
  return (
    <EntityStateChip
      label={translateProductGroupState(state)}
      colorConf={productGroupStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.productGroups.detail(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

export const ProductGroupsList: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error, refetch} = useProductGroups()
  const updateProductGroupsOrder = useUpdateProductGroupsOrder()
  const {
    addInfoNotification,
    setShowBackdrop,
    defaultErrorHandler,
    customErrorHandler
  } = useMutationAssistanceHooks()
  const {
    state: isCreateProductGroupDrawerOpen,
    setTrue: openCreateProductGroupDrawer,
    setFalse: closeCreateProductGroupDrawer
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  const handleRowOrderChange = useCallback(
    (productGroups: BaseProductGroupFieldsFragment[]) =>
      async (params: GridRowOrderChangeParams) => {
        const productGroupRowsClone = [...productGroups]
        const row = productGroupRowsClone.splice(params.oldIndex, 1)[0]
        productGroupRowsClone.splice(params.targetIndex, 0, row)
        try {
          setShowBackdrop(true)
          await updateProductGroupsOrder({
            ids: productGroupRowsClone.map(({id}) => id)
          })
          addInfoNotification(t('Order has been updated'))
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.InvalidProductGroupIds
            )
          ) {
            customErrorHandler(error, {
              title: t('Something went wrong'),
              contentText: t(
                'There was an issue with rearranging the product groups. Please try again.'
              ),
              confirmButtonLabel: t('Got it')
            })
          } else {
            defaultErrorHandler(
              error,
              t('Error while updating product groups order')
            )
          }
        } finally {
          await refetch()
          setShowBackdrop(false)
        }
      },
    [
      addInfoNotification,
      customErrorHandler,
      defaultErrorHandler,
      refetch,
      setShowBackdrop,
      t,
      updateProductGroupsOrder
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        width: 40
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ProductGroupState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Name'),
        field: 'name',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <NameRenderer group={params.row} />
        },
        minWidth: 350,
        sortable: false
      },
      {
        headerName: t('Internal description'),
        field: 'internalDescription',
        minWidth: 350,
        sortable: false
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 350,
        sortable: false
      },
      {
        headerName: '',
        field: 'icon',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.row.id} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [t]
  )
  return (
    <RenderOnData<ProductGroupsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading product groups')}
      dataCondition={(data) => Array.isArray(data.productGroups)}
    >
      {({productGroups}) => (
        <>
          <WideCenteredLayout
            sx={{
              width: '100%',
              px: 3,
              pb: 11
            }}
          >
            <DataGridTable
              sx={{
                mt: 3,
                [`.${gridClasses.main}`]: {
                  overflow: 'unset'
                },
                [`.${gridClasses.columnHeaders}`]: {
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'background.paper',
                  zIndex: 1
                }
              }}
              columns={columns}
              rows={productGroups}
              pagination={false}
              disableColumnMenu
              disableRowSelectionOnClick
              localeText={{noRowsLabel: t('No product groups to show')}}
              initialState={{
                pinnedColumns: {left: ['__reorder__', 'name'], right: ['icon']}
              }}
              columnVisibilityModel={{
                icon: P([PermissionCode.ReadProductGroup])
              }}
              rowReordering={P([PermissionCode.UpdateProductGroupsOrder])}
              onRowOrderChange={handleRowOrderChange(productGroups)}
              slots={{rowReorderIcon: DragHandleIcon}}
              hideFooter
              autoHeight
            />
          </WideCenteredLayout>
          {P([PermissionCode.CreateProductGroup]) && (
            <CreateFab
              classes={fabClasses}
              onClick={openCreateProductGroupDrawer}
            />
          )}
          <CreateProductGroupDrawer
            isOpen={isCreateProductGroupDrawerOpen}
            onClose={closeCreateProductGroupDrawer}
          />
        </>
      )}
    </RenderOnData>
  )
}
