import {Divider} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useMemo} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useDivisionCheckingOptionSelectData} from '../../../../../hooks/divisionCheckingOption'
import {useDivisionInvoiceFreqSelectData} from '../../../../../hooks/divisionInvoiceFreq'
import {useDivisionTypeSelectData} from '../../../../../hooks/divisionTypes'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {
  useIsNonNegativeInteger,
  useIsValidIBAN,
  useIsValidSWIFT,
  useIsValidURL
} from '../../../../../utils/formsValidations'
import {
  InputBlock,
  InputBlockWithoutSpacings,
  InputRow
} from '../../../../common'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {useDivisionFormAnchors} from './divisionFormAnchors'
import {FormSwitch} from './FormSwitch'
import {GateCloses} from './GateCloses'
import {GateOpens} from './GateOpens'
import {OnlineCreateReservationEnd} from './OnlineCreateReservationEnd'
import {OnlinePurchaseEnd} from './OnlinePurchaseEnd'
import {OnlineReservationEnd} from './OnlineReservationEnd'
import {PosCreateReservationEnd} from './PosCreateReservationEnd'
import {PosPurchaseEnd} from './PosPurchaseEnd'
import {PosReservationEnd} from './PosReservationEnd'

import {DivisionFormField, IDivisionFormData} from './types'

export const DIVISION_FORM_ID = 'divisionFormId'

export const useStyles = makeStyles<Theme>((theme) => ({
  spacings: {
    padding: theme.spacing(3)
  },
  checkingOptionsBox: {
    maxWidth: 330 + theme.spacing(6)
  }
}))

interface IDivisionFormProps {
  onSubmit: (data: IDivisionFormData) => Promise<void>
  submitErrorMessage: string
  defaultValues: Partial<IDivisionFormData> &
    Pick<
      IDivisionFormData,
      | DivisionFormField.ONLINE_PURCHASE_ACTIVE
      | DivisionFormField.POS_PURCHASE_ACTIVE
      | DivisionFormField.ONLINE_RESERVATION_ACTIVE
      | DivisionFormField.POS_RESERVATION_ACTIVE
    >
}

export const DivisionForm: React.FC<IDivisionFormProps> = ({
  defaultValues,
  submitErrorMessage,
  onSubmit
}: IDivisionFormProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const divisionFormAnchors = useDivisionFormAnchors()
  const divisionTypeSelectData = useDivisionTypeSelectData()
  const divisionCheckingOptionsSelectData =
    useDivisionCheckingOptionSelectData()
  const divisionInvoiceFreqSelectData = useDivisionInvoiceFreqSelectData()
  const {P} = useEnsurePermissions()

  const hasNotPermissionToEditInvoiceInfo = useMemo(
    () => !P([PermissionCode.EditDivisionInvoiceInfo]),
    [P]
  )

  const methods = useForm<IDivisionFormData>({
    defaultValues
  })
  const {
    register,
    setValue,
    errors,
    watch,
    handleSubmit,
    triggerValidation,
    control
  } = methods
  const formProps = {
    register,
    setValue,
    watch,
    errors
  }
  const uncontrolledFormTextInputProps = {
    ...formProps,
    triggerValidation
  }

  const isValidIBAN = useIsValidIBAN()
  const isValidSWIFT = useIsValidSWIFT()
  const isNonNegativeInteger = useIsNonNegativeInteger()
  const isValidURL = useIsValidURL(false, false)

  const {defaultErrorHandler} = useMutationAssistanceHooks()

  const _handleSubmit = useCallback(
    async (data: IDivisionFormData) => {
      try {
        await onSubmit(data)
      } catch (err) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            err,
            ErrorMessages.InvalidReservationEndCashdeskDuration
          )
        ) {
          defaultErrorHandler(
            err,
            `${submitErrorMessage} ${t(
              "Retail reservation expiration duration can't be zero."
            )}`,
            t('Update duration and submit again.')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            err,
            ErrorMessages.InvalidReservationEndWebDuration
          )
        ) {
          defaultErrorHandler(
            err,
            `${submitErrorMessage} ${t(
              "Ecommerce reservation expiration duration can't be zero."
            )}`,
            t('Update duration and submit again.')
          )
        } else {
          defaultErrorHandler(err, submitErrorMessage)
        }
      }
    },
    [defaultErrorHandler, onSubmit, submitErrorMessage, t]
  )

  return (
    <FormContext {...methods}>
      <form
        noValidate
        autoComplete="off"
        id={DIVISION_FORM_ID}
        onSubmit={handleSubmit(_handleSubmit)}
      >
        <InputBlock
          header={t('General information')}
          blockId={divisionFormAnchors.general.id}
        >
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('Name')}
                name={DivisionFormField.NAME}
                key={DivisionFormField.NAME}
                validationOptions={{
                  required: true
                }}
                required
                fullWidth
              />,
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                name={DivisionFormField.SHORT_NAME}
                key={DivisionFormField.SHORT_NAME}
                label={t('Short name')}
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('Code')}
                name={DivisionFormField.CODE}
                key={DivisionFormField.CODE}
                fullWidth
              />,
              <UncontrolledFormSelect<IDivisionFormData>
                {...formProps}
                name={DivisionFormField.TYPE}
                key={DivisionFormField.TYPE}
                label={t('Type')}
                selectOptions={divisionTypeSelectData}
                required
                fullWidth
                validationOptions={{required: true}}
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('Service time')}
                name={DivisionFormField.SERVICE_TIME}
                key={DivisionFormField.SERVICE_TIME}
                fullWidth
                validationOptions={{validate: isNonNegativeInteger}}
              />
            ]}
            xs={6}
          />
        </InputBlock>

        <InputBlock
          header={t('Contact information')}
          blockId={divisionFormAnchors.contact.id}
        >
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                key={`${DivisionFormField.ADDRESS}[complex]`}
                label={t('Complex')}
                name={`${DivisionFormField.ADDRESS}[complex]`}
                fullWidth
              />,
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                key={`${DivisionFormField.ADDRESS}[street]`}
                label={t('Street')}
                name={`${DivisionFormField.ADDRESS}[street]`}
                fullWidth
                required
                validationOptions={{required: true}}
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                key={`${DivisionFormField.ADDRESS}[town]`}
                label={t('Town')}
                name={`${DivisionFormField.ADDRESS}[town]`}
                fullWidth
                required
                validationOptions={{required: true}}
              />,
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                key={`${DivisionFormField.ADDRESS}[postalCode]`}
                label={t('Postal code')}
                name={`${DivisionFormField.ADDRESS}[postalCode]`}
                fullWidth
                required
                validationOptions={{required: true}}
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                key={`${DivisionFormField.ADDRESS}[country]`}
                label={t('Country')}
                name={`${DivisionFormField.ADDRESS}[country]`}
                fullWidth
                required
                validationOptions={{required: true}}
              />,
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                key={DivisionFormField.WEBSITE}
                label={t('Website')}
                name={DivisionFormField.WEBSITE}
                validationOptions={{validate: isValidURL}}
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('Email')}
                name={DivisionFormField.EMAIL}
                key={DivisionFormField.EMAIL}
                fullWidth
              />,
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('Phone number')}
                name={DivisionFormField.PHONE_NUMBER}
                key={DivisionFormField.PHONE_NUMBER}
                fullWidth
              />
            ]}
          />
        </InputBlock>

        <InputBlock
          header={t('Invoice information')}
          blockId={divisionFormAnchors.invoice.id}
        >
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('IBAN')}
                name={DivisionFormField.IBAN}
                key={DivisionFormField.IBAN}
                fullWidth
                required
                validationOptions={{
                  required: true,
                  validate: isValidIBAN
                }}
                disabled={hasNotPermissionToEditInvoiceInfo}
              />,
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('SWIFT')}
                name={DivisionFormField.SWIFT}
                key={DivisionFormField.SWIFT}
                fullWidth
                validationOptions={{
                  validate: isValidSWIFT
                }}
                disabled={hasNotPermissionToEditInvoiceInfo}
              />
            ]}
          />

          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IDivisionFormData>
                {...uncontrolledFormTextInputProps}
                label={t('Specific symbol')}
                name={DivisionFormField.SPECIFIC_SYMBOL}
                key={DivisionFormField.SPECIFIC_SYMBOL}
                inputMode="numeric"
                fullWidth
                required
                validationOptions={{
                  required: true,
                  validate: isNonNegativeInteger
                }}
                disabled={hasNotPermissionToEditInvoiceInfo}
              />,
              <UncontrolledFormSelect<IDivisionFormData>
                {...formProps}
                label={t('Invoice frequency')}
                name={DivisionFormField.INVOICE_FREQ}
                key={DivisionFormField.INVOICE_FREQ}
                selectOptions={divisionInvoiceFreqSelectData}
                fullWidth
                required
                validationOptions={{required: true}}
                disabled={hasNotPermissionToEditInvoiceInfo}
              />
            ]}
          />
        </InputBlock>

        <InputBlockWithoutSpacings
          header={t('Event purchase defaults')}
          blockId={divisionFormAnchors.sales.id}
        >
          <FormSwitch<IDivisionFormData>
            className={classes.spacings}
            mainLabel={t('Online purchase')}
            secondaryLabel={t(
              'Default option for each newly created event. Changing this setting will not affect any existing events.'
            )}
            name={DivisionFormField.ONLINE_PURCHASE_ACTIVE}
            defaultValue={
              defaultValues[DivisionFormField.ONLINE_PURCHASE_ACTIVE]
            }
            control={control}
            watch={watch}
          />
          <Divider />
          <OnlinePurchaseEnd defaultValues={defaultValues} />
          <Divider />
          <FormSwitch<IDivisionFormData>
            className={classes.spacings}
            mainLabel={t('POS purchase')}
            secondaryLabel={t(
              'Default option for each newly created event. Changing this setting will not affect any existing events.'
            )}
            name={DivisionFormField.POS_PURCHASE_ACTIVE}
            defaultValue={defaultValues[DivisionFormField.POS_PURCHASE_ACTIVE]}
            control={control}
            watch={watch}
          />
          <Divider />
          <PosPurchaseEnd defaultValues={defaultValues} />
        </InputBlockWithoutSpacings>

        <InputBlockWithoutSpacings
          header={t('Event reservation defaults')}
          blockId={divisionFormAnchors.reservation.id}
        >
          <FormSwitch<IDivisionFormData>
            className={classes.spacings}
            mainLabel={t('Online reservation')}
            secondaryLabel={t(
              'Default option for each newly created event. Changing this setting will not affect any existing events.'
            )}
            name={DivisionFormField.ONLINE_RESERVATION_ACTIVE}
            defaultValue={
              defaultValues[DivisionFormField.ONLINE_RESERVATION_ACTIVE]
            }
            control={control}
            watch={watch}
          />
          <Divider />
          <OnlineReservationEnd defaultValues={defaultValues} />
          <Divider />
          <OnlineCreateReservationEnd defaultValues={defaultValues} />
          <Divider />
          <FormSwitch<IDivisionFormData>
            className={classes.spacings}
            mainLabel={t('POS reservation')}
            secondaryLabel={t(
              'Default option for each newly created event. Changing this setting will not affect any existing events.'
            )}
            name={DivisionFormField.POS_RESERVATION_ACTIVE}
            defaultValue={
              defaultValues[DivisionFormField.POS_RESERVATION_ACTIVE]
            }
            control={control}
            watch={watch}
          />
          <Divider />
          <PosReservationEnd defaultValues={defaultValues} />
          <Divider />
          <PosCreateReservationEnd defaultValues={defaultValues} />
        </InputBlockWithoutSpacings>

        <InputBlockWithoutSpacings
          header={t('Event entrance defaults')}
          blockId={divisionFormAnchors.entrance.id}
        >
          <GateOpens defaultValues={defaultValues} />
          <Divider />
          <GateCloses defaultValues={defaultValues} />
          <Divider />
          <div className={cn(classes.checkingOptionsBox, classes.spacings)}>
            <UncontrolledFormSelect<IDivisionFormData>
              {...formProps}
              name={DivisionFormField.CHECKING_OPTION}
              key={DivisionFormField.CHECKING_OPTION}
              label={t('Checking option')}
              selectOptions={divisionCheckingOptionsSelectData}
              required
              fullWidth
              validationOptions={{required: true}}
            />
          </div>
        </InputBlockWithoutSpacings>
      </form>
    </FormContext>
  )
}
