import {useTranslation} from 'react-i18next'

import {WeekDay} from '../__generated__/schema'

export const useTranslateWeekDay = () => {
  const {t} = useTranslation()
  const translatedWeekdays: Record<WeekDay, string> = {
    [WeekDay.Sun]: t<string>('WeekDay->Sun'),
    [WeekDay.Mon]: t<string>('WeekDay->Mon'),
    [WeekDay.Tue]: t<string>('WeekDay->Tue'),
    [WeekDay.Wed]: t<string>('WeekDay->Wed'),
    [WeekDay.Thu]: t<string>('WeekDay->Thu'),
    [WeekDay.Fri]: t<string>('WeekDay->Fri'),
    [WeekDay.Sat]: t<string>('WeekDay->Sat')
  }
  return (code: WeekDay) => translatedWeekdays[code]
}
