import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {AddIdItemsCheckGroup} from './AddIdItemsCheckGroup'
import {IIdListItem} from './IdListItem'
import {
  GeneralFilterActions,
  GeneralFilterActionTypes,
  GeneralFilterState
} from './reducer'
import {ListItemID, UArrayKeys} from './types'
import {getListOfIdsFromFilter} from './utils'

interface IIdsListSecondStepProps<T extends object, ID extends ListItemID> {
  getOptions: (hasText?: string) => Promise<IIdListItem<ID>[]>
  getMoreOptions?: (hasText?: string) => Promise<IIdListItem<ID>[]>
  generalFilter: GeneralFilterState<T>
  dispatch: React.Dispatch<GeneralFilterActions<T>>
  filterKey: UArrayKeys<T, ID>
  isFilterTextInputHidden?: boolean
}

export const IdsListSecondStep = <
  T extends object,
  ID extends ListItemID = number
>({
  getOptions,
  getMoreOptions,
  generalFilter: {addSectionDialog},
  filterKey,
  dispatch,
  isFilterTextInputHidden
}: IIdsListSecondStepProps<T, ID>) => {
  const {t} = useTranslation()
  if (addSectionDialog.key !== filterKey) {
    return null
  }
  return (
    <>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{pt: 2}}
        component="div"
      >
        {t('Select items')}
      </Typography>
      <AddIdItemsCheckGroup<ID>
        isFilterTextInputHidden={isFilterTextInputHidden}
        getOptions={getOptions}
        getMoreOptions={getMoreOptions}
        selectedIds={[]}
        checkedIds={
          getListOfIdsFromFilter<T, ID>(
            addSectionDialog.dialogFilter,
            filterKey
          ) ?? []
        }
        onCheck={(value: ID) => {
          dispatch({
            type: GeneralFilterActionTypes.AddIdToDialogFilterList,
            payload: {
              key: filterKey,
              value
            }
          })
        }}
        onUnCheck={(value: ID) => {
          dispatch({
            type: GeneralFilterActionTypes.RemoveIdFromListOfIds,
            payload: {
              key: filterKey,
              value
            }
          })
        }}
      />
    </>
  )
}
