import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box, Button, IconButton} from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  VouchersFilterInput,
  VouchersQuery,
  VoucherState
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {useTranslateVoucherState} from '../../../../hooks/translateVoucherState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData, Tooltip} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useDecimalFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {voucherStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {CreateVouchersDrawer} from './CreateVouchersDrawer'
import {useGetVouchers} from './graphql'
import {MoreMenu} from './MoreMenu'
import {VoucherStateChip} from './VoucherStateChip'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.vouchers.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const StateRenderer = ({state}: {state: VoucherState}) => {
  const translateVoucherState = useTranslateVoucherState()
  return (
    <EntityStateChip
      colorConf={voucherStateColors[state]}
      label={translateVoucherState(state)}
    />
  )
}

interface IVouchersListProps {
  searchFilter: VouchersFilterInput
}

export const VouchersList: React.FC<IVouchersListProps> = ({
  searchFilter
}: IVouchersListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const dataGridApiRef = useGridApiRef()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination({
      page: 0,
      pageSize: 100
    })
  const [voucherState, setVoucherState] = useState<VoucherState | undefined>()
  const {data, loading, error, refetch} = useGetVouchers({
    canReadPinCode: P([PermissionCode.ReadVoucherPinCode]),
    filter: {
      ...searchFilter,
      state: voucherState ? [voucherState] : undefined
    },
    paginationInput
  })
  const {
    state: isCreateVouchersDrawerOpen,
    setTrue: openCreateVouchersDrawer,
    setFalse: closeCreateVouchersDrawer
  } = useBooleanState(false)
  const translateVoucherState = useTranslateVoucherState()
  const dateTimeFormatter = useDateTimeFormatter()
  const userNameFormatter = useUserNameFormatter()
  const decimalFormatter2FractionDigits = useDecimalFormatter()
  const decimalFormatterNoFractionDigits = useDecimalFormatter(0)
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const handleVouchersListCsvButtonClick = useCallback(
    () =>
      dataGridApiRef.current.exportDataAsCsv({
        fileName: 'vouchers'
      }),
    [dataGridApiRef]
  )
  const handleVoucherStateChange = useCallback(
    (state: VoucherState | undefined) => {
      setVoucherState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Code'),
        field: 'code',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: VoucherState}>
        ) {
          return params.rowNode.type === 'pinnedRow' ? null : (
            <StateRenderer state={params.value} />
          )
        },
        valueFormatter: (params) => translateVoucherState(params.value),
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Campaign'),
        field: 'campaignName',
        valueGetter: (params) => params.row.campaign?.name,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Balance'),
        field: 'balance',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <>{translateEffectiveClientPrice(params.value)}</>
        },
        valueFormatter: decimalFormatter2FractionDigits,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Value'),
        field: 'value',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <>{translateEffectiveClientPrice(params.value)}</>
        },
        valueFormatter: decimalFormatter2FractionDigits,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('PIN'),
        field: 'pinCode',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Top-ups'),
        field: 'topUpsCount',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatterNoFractionDigits,
        minWidth: 120,
        sortable: false
      },
      {
        headerName: t('Redemptions'),
        field: 'redemptionsCount',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatterNoFractionDigits,
        minWidth: 120,
        sortable: false
      },
      {
        headerName: t('Activation date'),
        field: 'activationDate',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Expiration date'),
        field: 'expirationDate',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Activated at'),
        field: 'activatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Activated by'),
        field: 'activatedBy',
        valueFormatter: userNameFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Created'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48,
        disableExport: true
      }
    ],
    [
      dateTimeFormatter,
      decimalFormatter2FractionDigits,
      decimalFormatterNoFractionDigits,
      t,
      translateEffectiveClientPrice,
      translateVoucherState,
      userNameFormatter
    ]
  )
  return (
    <RenderOnData<VouchersQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading vouchers')}
      dataCondition={(data) => Array.isArray(data.vouchers.items)}
      ignoreLoadingIfData
    >
      {({vouchers}) => (
        <>
          <Box
            sx={{
              height: '100%'
            }}
          >
            <SubHeaderToolbar
              title={t('Overview')}
              leftActions={[
                <VoucherStateChip
                  key="voucher-state-chip"
                  selectedState={voucherState}
                  setSelectedState={handleVoucherStateChange}
                />
              ]}
              rightActions={[
                <Tooltip key="refetch-button" title={t('Reload')}>
                  <IconButton onClick={handleRefetchButtonClick}>
                    <RefreshOutlinedIcon color="primary" />
                  </IconButton>
                </Tooltip>,
                <MoreMenu
                  key="more-menu"
                  onVouchersListCsvButtonClick={
                    handleVouchersListCsvButtonClick
                  }
                />
              ]}
            />
            <Box sx={{height: 'calc(100% - 112px)', width: '100%', p: 3}}>
              {vouchers.items.length > 0 ? (
                <DataGridTable
                  sx={{'& .bold': {fontWeight: 'bold'}}}
                  apiRef={dataGridApiRef}
                  columns={columns}
                  loading={loading}
                  rows={vouchers.items}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  initialState={{
                    pinnedColumns: {left: ['code'], right: ['id']}
                  }}
                  columnVisibilityModel={{
                    pin: P([PermissionCode.ReadVoucherPinCode]),
                    id: P([PermissionCode.ReadVoucher])
                  }}
                  pinnedRows={{
                    bottom: [
                      {
                        id: 0,
                        code: t('Total'),
                        balance: data?.vouchers.totals.totalBalance,
                        value: data?.vouchers.totals.totalValue,
                        topUpsCount: data?.vouchers.totals.totalTopUpsCount,
                        redemptionsCount:
                          data?.vouchers.totals.totalRedemptionsCount
                      }
                    ]
                  }}
                  getRowClassName={(params) =>
                    params.id === 0 ? clsx('bold') : ''
                  }
                  {...getDataGridPaginationProps(vouchers.pagination)}
                />
              ) : (
                <Blank
                  title={t('No vouchers found')}
                  description={t(
                    'Create and manage gift cards, vouchers, rewards, sorry coupons, affiliate codes or whatever comes to your mind.'
                  )}
                  actions={
                    P([PermissionCode.CreateVouchers]) && (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={openCreateVouchersDrawer}
                      >
                        {t('Create')}
                      </Button>
                    )
                  }
                />
              )}
            </Box>
          </Box>
          {P([PermissionCode.CreateVouchers]) && vouchers.items.length > 0 && (
            <CreateFab
              classes={fabClasses}
              onClick={openCreateVouchersDrawer}
            />
          )}
          <CreateVouchersDrawer
            isOpen={isCreateVouchersDrawerOpen}
            onClose={closeCreateVouchersDrawer}
            refetch={refetch}
          />
        </>
      )}
    </RenderOnData>
  )
}
