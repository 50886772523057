import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {SalesQuery, SalesQueryVariables} from '../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../pages/graphql'

const SALES = gql`
  ${PAGINATION_FRAGMENT}
  query sales($filter: SaleFilterInput, $paginationInput: PaginationInput) {
    sales(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        createdAt
        sellingChannel
        price
        lead {
          id
          data {
            name
            phone
            email
          }
        }
        cart {
          id
          uuid
          hash
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useSales = (variables?: SalesQueryVariables, skip?: boolean) =>
  useQuery<SalesQuery, SalesQueryVariables>(SALES, {
    variables,
    fetchPolicy: 'network-only',
    skip
  })
