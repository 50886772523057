import {
  TourTimeSlotState,
  TranslatedInput
} from '../../../../__generated__/schema'

export enum TimeSelectOption {
  EventStart = 'eventStart',
  AfterStart = 'afterStart',
  BeforeStart = 'beforeStart',
  DayBeforeStart = 'dayBeforeStart',
  Duration = 'duration',
  DayOfEvent = 'dayOfEvent',
  BeforeEnds = 'beforeEnd',
  AfterEnds = 'afterEnd'
}

export enum TimeSelectValue {
  Minutes = 'minutes',
  Time = 'time',
  DaysDropdown = 'daysDropdown',
  HoursDropdown = 'hoursDropdown',
  MinutesDropdown = 'minutesDropdown'
}

export interface ITimeSelectValue {
  [TimeSelectValue.Minutes]?: string
  [TimeSelectValue.Time]?: string
  [TimeSelectValue.DaysDropdown]?: string
  [TimeSelectValue.HoursDropdown]?: string
  [TimeSelectValue.MinutesDropdown]?: string
}

export enum TimeSelectFormField {
  Option = 'option',
  Value = 'value'
}

export interface ITimeSelect {
  [TimeSelectFormField.Option]: TimeSelectOption
  [TimeSelectFormField.Value]: ITimeSelectValue
}

export enum TourFormDivisionSettingsField {
  IsECommerceSaleActive = 'isECommerceSaleActive',
  IsRetailSaleActive = 'isRetailSaleActive',
  ECommerceSalesEnd = 'eCommerceSalesEnd',
  RetailSalesEnd = 'retailSalesEnd',
  IsECommerceReservationActive = 'isECommerceReservationActive',
  ECommerceReservationExpiration = 'eCommerceReservationExpiration',
  ECommerceReservationEnd = 'eCommerceReservationEnd',
  IsRetailReservationActive = 'isRetailReservationActive',
  RetailReservationExpiration = 'retailReservationExpiration',
  RetailReservationEnd = 'retailReservationEnd',
  GateOpens = 'gateOpens',
  GateCloses = 'gateCloses'
}

export interface ITourDivisionSettingsForm {
  [TourFormDivisionSettingsField.IsECommerceSaleActive]: boolean
  [TourFormDivisionSettingsField.IsRetailSaleActive]: boolean
  [TourFormDivisionSettingsField.ECommerceSalesEnd]: ITimeSelect
  [TourFormDivisionSettingsField.RetailSalesEnd]: ITimeSelect
  [TourFormDivisionSettingsField.IsECommerceReservationActive]: boolean
  [TourFormDivisionSettingsField.ECommerceReservationExpiration]: ITimeSelect
  [TourFormDivisionSettingsField.ECommerceReservationEnd]: ITimeSelect
  [TourFormDivisionSettingsField.IsRetailReservationActive]: boolean
  [TourFormDivisionSettingsField.RetailReservationExpiration]: ITimeSelect
  [TourFormDivisionSettingsField.RetailReservationEnd]: ITimeSelect
  [TourFormDivisionSettingsField.GateOpens]: ITimeSelect
  [TourFormDivisionSettingsField.GateCloses]: ITimeSelect
}

export enum TourFormField {
  Name = 'name',
  InternalNote = 'internalNote',
  ShowId = 'showId',
  DivisionId = 'divisionId',
  Duration = 'duration'
}

export interface ITourForm extends ITourDivisionSettingsForm {
  [TourFormField.Name]: string
  [TourFormField.InternalNote]: string | null
  [TourFormField.ShowId]: string
  [TourFormField.DivisionId]: string
  [TourFormField.Duration]: string
}

export enum AdmissionRateFormField {
  Name = 'name',
  Abbreviation = 'abbreviation',
  Color = 'color',
  Description = 'description'
}

export interface IAdmissionRateForm {
  [AdmissionRateFormField.Name]: string
  [AdmissionRateFormField.Abbreviation]: string
  [AdmissionRateFormField.Color]: string
  [AdmissionRateFormField.Description]: string | undefined
}

export enum CopyTourTimeSlotsFormField {
  SourceDate = 'sourceDate',
  DestinationDateFrom = 'destinationDateFrom',
  DestinationDateTo = 'destinationDateTo',
  DestinationDate = 'destinationDate',
  AllowedWeekDays = 'allowedWeekDays',
  State = 'state'
}

export interface ICopyTourTimeSlotsForm {
  [CopyTourTimeSlotsFormField.SourceDate]: string
  [CopyTourTimeSlotsFormField.DestinationDateFrom]?: string
  [CopyTourTimeSlotsFormField.DestinationDateTo]?: string
  [CopyTourTimeSlotsFormField.DestinationDate]?: string
  [CopyTourTimeSlotsFormField.AllowedWeekDays]: string
  [CopyTourTimeSlotsFormField.State]: TourTimeSlotState
}

export enum UpdateTourTimeSlotsStaticOption {
  DoNotChange = 'doNotChange',
  ClearValues = 'clearValues',
  NewValue = 'newValue'
}

export enum UpdateTourTimeSlotsBooleanOption {
  DoNotChange = 'doNotChange',
  Enable = 'enable',
  Disable = 'disable'
}

export enum UpdateTourTimeSlotsFormField {
  AdmissionRateId = 'admissionRateId',
  State = 'state',
  TicketNoteOption = 'ticketNoteOption',
  TicketNote = 'ticketNote',
  RetailSaleOption = 'retailSaleOption',
  RetailReservationOption = 'retailReservationOption',
  RetailAttendeesLimitOption = 'retailAttendeesLimitOption',
  RetailAttendeesLimit = 'retailAttendeesLimit',
  ECommerceSaleOption = 'eCommerceSaleOption',
  ECommerceReservationOption = 'eCommerceReservationOption',
  ShowOnWebsiteAndApi = 'showOnWebsiteAndApi',
  ECommerceAttendeesLimitOption = 'eCommerceAttendeesLimitOption',
  ECommerceAttendeesLimit = 'eCommerceAttendeesLimit',
  ECommerceOrderAttendeesLimitOption = 'eCommerceOrderAttendeesLimitOption',
  ECommerceOrderAttendeesLimit = 'eCommerceOrderAttendeesLimit',
  VersionCode = 'versionCode',
  GuideId = 'guideId',
  CostCenterId = 'costCenterId',
  MarketingLabelId = 'marketingLabelId',
  EventCategoryId = 'eventCategoryId',
  AgeClassificationCode = 'ageClassificationCode',
  VenueId = 'venueId',
  NamesOption = 'namesOption',
  Names = 'names'
}

export interface IUpdateTourTimeSlotsForm {
  [UpdateTourTimeSlotsFormField.AdmissionRateId]: string
  [UpdateTourTimeSlotsFormField.State]: string
  [UpdateTourTimeSlotsFormField.TicketNoteOption]: UpdateTourTimeSlotsStaticOption
  [UpdateTourTimeSlotsFormField.TicketNote]?: string
  [UpdateTourTimeSlotsFormField.RetailSaleOption]: UpdateTourTimeSlotsBooleanOption
  [UpdateTourTimeSlotsFormField.RetailReservationOption]: UpdateTourTimeSlotsBooleanOption
  [UpdateTourTimeSlotsFormField.RetailAttendeesLimitOption]: UpdateTourTimeSlotsStaticOption
  [UpdateTourTimeSlotsFormField.RetailAttendeesLimit]?: string
  [UpdateTourTimeSlotsFormField.ECommerceSaleOption]: UpdateTourTimeSlotsBooleanOption
  [UpdateTourTimeSlotsFormField.ECommerceReservationOption]: UpdateTourTimeSlotsBooleanOption
  [UpdateTourTimeSlotsFormField.ShowOnWebsiteAndApi]: UpdateTourTimeSlotsBooleanOption
  [UpdateTourTimeSlotsFormField.ECommerceAttendeesLimitOption]: UpdateTourTimeSlotsStaticOption
  [UpdateTourTimeSlotsFormField.ECommerceAttendeesLimit]?: string
  [UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimitOption]: UpdateTourTimeSlotsStaticOption
  [UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimit]?: string
  [UpdateTourTimeSlotsFormField.VersionCode]: string
  [UpdateTourTimeSlotsFormField.GuideId]: string
  [UpdateTourTimeSlotsFormField.CostCenterId]: string
  [UpdateTourTimeSlotsFormField.MarketingLabelId]: string
  [UpdateTourTimeSlotsFormField.EventCategoryId]: string
  [UpdateTourTimeSlotsFormField.AgeClassificationCode]: string
  [UpdateTourTimeSlotsFormField.VenueId]: string
  [UpdateTourTimeSlotsFormField.NamesOption]: {
    [key: string]: UpdateTourTimeSlotsStaticOption
  }
  [UpdateTourTimeSlotsFormField.Names]?: TranslatedInput
}

export enum CopyAdmissionRateDestinationOption {
  ThisTour = 'thisTour',
  AnotherTour = 'anotherTour'
}

export enum CopyAdmissionRateFormField {
  AdmissionRateId = 'admissionRateId',
  AdmissionRateName = 'admissionRateName',
  TourId = 'tourId',
  Destination = 'destination',
  AnotherTourId = 'anotherTourId'
}

export interface ICopyAdmissionRateForm {
  [CopyAdmissionRateFormField.AdmissionRateId]: string
  [CopyAdmissionRateFormField.AdmissionRateName]: string
  [CopyAdmissionRateFormField.TourId]: string
  [CopyAdmissionRateFormField.Destination]: CopyAdmissionRateDestinationOption
  [CopyAdmissionRateFormField.AnotherTourId]?: string
}
