import {createSelector} from 'reselect'

import {IAppState} from '../index'
import {EditorMode} from './reducer'

export const editorModeSelector = (state: IAppState) => state.editorMode

export const selectionModeSelector = createSelector(
  editorModeSelector,
  (editorMode) => editorMode.modeConfigs[EditorMode.SELECT].mode
)
