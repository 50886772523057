import {useTranslation} from 'react-i18next'
import {ProductMode} from '../__generated__/schema'

export const useTranslateProductMode = () => {
  const {t} = useTranslation()
  const translated: Record<ProductMode, string> = {
    [ProductMode.Product]: t('ProductMode->Product'),
    [ProductMode.WarehouseProduct]: t('ProductMode->WarehouseProduct'),
    [ProductMode.WarehouseRecipe]: t('ProductMode->WarehouseRecipe')
  }
  return (mode: ProductMode) => translated[mode]
}

export const useTranslateProductModeDescription = () => {
  const {t} = useTranslation()
  const translated: Record<ProductMode, string> = {
    [ProductMode.Product]: t('ProductModeDescription->Product'),
    [ProductMode.WarehouseProduct]: t(
      'ProductModeDescription->WarehouseProduct'
    ),
    [ProductMode.WarehouseRecipe]: t('ProductModeDescription->WarehouseRecipe')
  }
  return (mode: ProductMode) => translated[mode]
}
