import {Box} from '@mui/material'
import React from 'react'
import {
  CashDeskAdmissionRateFieldsFragment,
  CashDeskTourTimeSlotQuery
} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {getIcon} from '../../../common/admissionTypeIcon'
import {CashDeskListItem} from '../../../common/cashDeskListItem'
import {ColorBox} from '../../../common/ColorBox'
import {IconBox} from '../../../common/IconBox'
import {TourTimeSlotQuantityDescription} from '../components/TourTimeSlotQuantityDescription'
import {CenteredLayout} from '../Layout'

interface ITourTimeSlotListViewProps {
  admissionRate: CashDeskAdmissionRateFieldsFragment
  onIncrementButtonClick: (
    assignmentId: number,
    startingQuantity: number,
    admissionTypeName: string
  ) => (e: React.MouseEvent) => void
  onDecrementButtonClick: (
    assignmentId: number,
    startingQuantity: number
  ) => (e: React.MouseEvent) => void
  onOpenEditQuantityDialogClick: (
    assignment: CashDeskTourTimeSlotQuery['tourTimeSlot']['admissionRate']['admissionTypesAssignments'][number]
  ) => (e: React.MouseEvent) => void
  quantityGetter: (assignmentId: number) => number
}

export const TourTimeSlotListView: React.FC<ITourTimeSlotListViewProps> = ({
  admissionRate,
  onIncrementButtonClick,
  onDecrementButtonClick,
  onOpenEditQuantityDialogClick,
  quantityGetter
}: ITourTimeSlotListViewProps) => {
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <CenteredLayout>
      <Box
        sx={{
          pt: 3,
          pb: 11,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        {admissionRate.admissionTypesAssignments.map((assignment) => (
          <CashDeskListItem
            key={assignment.id}
            title={assignment.admissionType.name}
            description={
              (assignment.admissionType.capacityDecreaseCount !== 1 ||
                assignment.admissionType.startingQuantity >= 2) && (
                <TourTimeSlotQuantityDescription
                  startingQuantity={assignment.admissionType.startingQuantity}
                  capacityDecreaseCount={
                    assignment.admissionType.capacityDecreaseCount
                  }
                />
              )
            }
            note={assignment.admissionType.internalDescription}
            price={translateEffectiveClientPrice(assignment.retailPrice)}
            icon={
              assignment.admissionType.icon ? (
                <IconBox
                  Icon={getIcon(assignment.admissionType.icon)}
                  hexColor={assignment.admissionType.color}
                  sx={{width: 64, height: 'auto'}}
                />
              ) : (
                <ColorBox
                  hexColor={assignment.admissionType.color}
                  sx={{width: 64, height: 'auto'}}
                />
              )
            }
            QuantityControlsProps={{
              quantity: quantityGetter(assignment.id),
              IncrementButtonProps: {
                onClick: onIncrementButtonClick(
                  assignment.id,
                  assignment.admissionType.startingQuantity,
                  assignment.admissionType.name
                )
              },
              onClick: onOpenEditQuantityDialogClick(assignment),
              DecrementButtonProps: {
                onClick: onDecrementButtonClick(
                  assignment.id,
                  assignment.admissionType.startingQuantity
                )
              },
              typographySx: {
                width: 48,
                height: 40,
                justifyContent: 'center'
              }
            }}
          />
        ))}
      </Box>
    </CenteredLayout>
  )
}
