import AddIcon from '@mui/icons-material/Add'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {orderBy} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps} from 'react-router-dom'
import {
  MixedShowImagesQuery,
  PermissionCode,
  ShowImageType
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {RenderOnData} from '../../../../common/RenderOnData'
import {ShowSection, useTranslateShowImageType} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useGetMixedShowImages} from '../graphql'
import {getLibraryIdForMixedShowImage} from '../utils'
import {AssignMediaGallery} from './AssignMediaGallery'

const usePageHeaders = (showImageType: ShowImageType) => {
  const {t} = useTranslation()
  switch (showImageType) {
    case ShowImageType.Poster:
      return t('Poster library')
    case ShowImageType.Banner:
      return t('Banner library')
    case ShowImageType.Photo:
      return t('Photo library')
    case ShowImageType.Cover:
      return t('Cover library')
    case ShowImageType.Backdrop:
    default:
      return t('Backdrop library')
  }
}

interface IAssignMediaFromLibraryProps
  extends RouteComponentProps<{
    showId: string
    showImageType: ShowImageType
  }> {}

const useStyles = makeStyles<Theme>((theme) => ({
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 0)
  }
}))

export const AssignMediaFromLibrary: React.FC<IAssignMediaFromLibraryProps> = ({
  match,
  history
}: IAssignMediaFromLibraryProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const showId = parseInt(match.params.showId, 10)
  const showImageType = match.params.showImageType
  const translateShowImageType = useTranslateShowImageType()
  const {data, loading, error, refetch} = useGetMixedShowImages(showId, {
    type: [match.params.showImageType]
  })
  const {P} = useEnsurePermissions()
  const handleUploadFromComputerButtonClick = useCallback(() => {
    history.push(
      routeTo.admin.shows.uploadMedia(showId, ShowSection.Media, showImageType)
    )
  }, [history, showId, showImageType])
  const showImageTypeTranslation = usePageHeaders(match.params.showImageType)
  return (
    <PageWithHeaderTemplate
      header={<SecondaryHeader title={showImageTypeTranslation} />}
    >
      <CenteredLayout>
        <RenderOnData<MixedShowImagesQuery>
          loading={loading}
          data={data}
          error={error}
          errorMessage={t<string>('Error while loading show images')}
        >
          {({mixedShowImages}) =>
            mixedShowImages?.length ? (
              <div>
                <div className={classes.subHeader}>
                  <Typography variant="subtitle1">
                    {t('Select from library')}
                  </Typography>
                  {P([PermissionCode.UploadClientShowImage]) && (
                    <Button
                      startIcon={<AddIcon />}
                      color="primary"
                      onClick={handleUploadFromComputerButtonClick}
                    >
                      {t('Upload from computer')}
                    </Button>
                  )}
                </div>
                <AssignMediaGallery
                  mixedShowImages={orderBy(
                    mixedShowImages,
                    getLibraryIdForMixedShowImage
                  )}
                  showId={showId}
                  refetch={refetch}
                />
              </div>
            ) : (
              <Blank
                title={t('No {{showImageType}} found', {
                  showImageType: translateShowImageType(
                    match.params.showImageType
                  )
                })}
                description={t(
                  'Upload the very first media and make a better promo for your event'
                )}
                IconComp={SentimentDissatisfiedIcon}
                actions={
                  P([PermissionCode.UploadClientShowImage]) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUploadFromComputerButtonClick}
                    >
                      {t('Upload from computer')}
                    </Button>
                  )
                }
              />
            )
          }
        </RenderOnData>
      </CenteredLayout>
    </PageWithHeaderTemplate>
  )
}
