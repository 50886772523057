import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {TourItemPropertiesFragment} from '../../../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../../../hooks/translateCurrencies'
import {safeSum} from '../../../../../../../utils/money'

interface ISubtotalProps {
  tourItems: TourItemPropertiesFragment[]
  description: string
}

export const Subtotal: React.FC<ISubtotalProps> = ({
  tourItems,
  description
}: ISubtotalProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return (
    <Box
      sx={{
        height: 64,
        display: 'grid',
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        py: 0,
        px: 2,
        gridTemplateAreas: `
      "subtotalLabel tickets"
      "subtotalLabel subtotal"
    `,
        gridTemplateColumns: '1fr auto'
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{gridArea: 'subtotalLabel', alignSelf: 'center'}}
      >
        {t('Subtotal')}
      </Typography>
      <Typography align="right" variant="caption" sx={{alignSelf: 'flex-end'}}>
        {description}
      </Typography>
      <Typography
        align="right"
        variant="subtitle1"
        sx={{alignSelf: 'flex-start'}}
      >
        {translatePrice(safeSum(tourItems.map(({price}) => price)))}
      </Typography>
    </Box>
  )
}
