import GridViewIcon from '@mui/icons-material/GridView'
import ListIcon from '@mui/icons-material/List'
import {Fab, SxProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import React from 'react'
import {TourTimeSlotViewMode} from '../../../../types'

interface ITourTimeSlotViewModeSwitchButtonProps {
  sx?: SxProps<Theme>
  currentMode: TourTimeSlotViewMode
  onClick: () => void
}

export const TourTimeSlotViewModeSwitchButton: React.FC<ITourTimeSlotViewModeSwitchButtonProps> =
  ({currentMode, onClick, sx}: ITourTimeSlotViewModeSwitchButtonProps) => {
    return (
      <Fab
        size="small"
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          backgroundColor: 'background.paper',
          borderRadius: 0.5,
          zIndex: 0,
          ...sx
        }}
        onClick={onClick}
      >
        {currentMode === TourTimeSlotViewMode.ListView ? (
          <GridViewIcon />
        ) : (
          <ListIcon />
        )}
      </Fab>
    )
  }
