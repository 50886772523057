import {Chip, chipClasses, useTheme} from '@mui/material'
import {SvgIconProps} from '@mui/material/SvgIcon'
import React from 'react'
import {Theme} from '../../theme'
import {isValidHexColor} from '../../utils/formsValidations'

interface ICashDeskProductGroupChipProps {
  label: string
  Icon?: React.FC<SvgIconProps>
  hexColor: string
  isActive?: boolean
  onClick?: () => void
}

export const CashDeskProductGroupChip: React.FC<ICashDeskProductGroupChipProps> =
  ({
    label,
    Icon,
    hexColor,
    isActive,
    onClick
  }: ICashDeskProductGroupChipProps) => {
    const theme = useTheme<Theme>()
    const iconColor = isValidHexColor(hexColor)
      ? hexColor
      : theme.palette.primary.main
    return (
      <Chip
        sx={{
          [`& .${chipClasses.label}`]: {
            ...theme.typography.subtitle1,
            fontWeight: 'bold',
            color: isActive ? theme.palette.background.paper : iconColor
          },
          [`& .${chipClasses.icon}`]: {
            color: isActive ? theme.palette.background.paper : iconColor
          },
          backgroundColor: isActive
            ? iconColor
            : theme.palette.background.paper,
          border: isActive ? undefined : `2px solid ${iconColor}`,
          height: 36,
          px: 1,
          py: 0.75,
          borderRadius: 4.5,
          cursor: onClick ? 'pointer' : 'normal'
        }}
        clickable={false}
        onClick={onClick}
        label={label}
        icon={Icon ? <Icon /> : undefined}
      />
    )
  }
