import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DataSetType,
  LocaleCode,
  TemplateFileType,
  TemplateType
} from '../../../../__generated__/schema'
import {useTranslateLocaleCode} from '../../../../hooks/translateLocales'
import {
  useTranslateTemplateDatasetType,
  useTranslateTemplateFileType,
  useTranslateTemplateType
} from '../../../../hooks/translateTemplates'
import {Theme} from '../../../../theme'
import {
  useIsFormMultiSelectRequired,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {FormMultiSelect} from '../../../visual'
import {ITemplateForm, TemplateFormField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  title: {
    padding: theme.spacing(2, 0)
  }
}))

interface ITemplateFormProps {
  formId: string
  onSubmit: (data: ITemplateForm) => void
  defaultValues?: Partial<ITemplateForm>
  className?: string
}

export const TemplateForm: React.FC<ITemplateFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  className
}: ITemplateFormProps) => {
  const {t} = useTranslation()
  const {
    register,
    unregister,
    triggerValidation,
    setValue,
    errors,
    watch,
    handleSubmit
  } = useForm<ITemplateForm>({defaultValues})
  const classes = useStyles()
  const isStringWithMaxLength30 = useIsStringWithMaxLength(30)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isStringWithMaxLength256 = useIsStringWithMaxLength(256)
  const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
  const translateTemplateType = useTranslateTemplateType()
  const translateTemplateFileType = useTranslateTemplateFileType()
  const translateLocaleCode = useTranslateLocaleCode()
  const translateTemplateDatasetType = useTranslateTemplateDatasetType()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ITemplateForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            key={TemplateFormField.Name}
            name={TemplateFormField.Name}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength30
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ITemplateForm>
            multiline
            rows={4}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            key={TemplateFormField.Description}
            name={TemplateFormField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
          />
        ]}
      />
      <Typography variant="subtitle1" className={classes.title}>
        {t('Settings')}
      </Typography>
      <InputRow
        nodes={[
          <FormMultiSelect
            selectOptions={Object.values(TemplateType).reduce(
              (acc, type) => ({
                ...acc,
                [type]: translateTemplateType(type)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            selectedKeys={watch(TemplateFormField.Types)}
            register={register}
            unregister={unregister}
            name={TemplateFormField.Types}
            label={t('Type')}
            key={TemplateFormField.Types}
            validationOptions={{
              validate: {
                isFormMultiSelectRequired
              }
            }}
            showRequiredLabel
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<ITemplateForm>
            selectOptions={Object.values(TemplateFileType).reduce(
              (acc, fileType) => ({
                ...acc,
                [fileType]: translateTemplateFileType(fileType)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            name={TemplateFormField.FileType}
            label={t('Filetype')}
            key={TemplateFormField.FileType}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<ITemplateForm>
            selectOptions={Object.values(LocaleCode).reduce(
              (acc, localeCode) => ({
                ...acc,
                [localeCode]: translateLocaleCode(localeCode)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            name={TemplateFormField.LocaleCode}
            label={t('Locale')}
            key={TemplateFormField.LocaleCode}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ITemplateForm>
            multiline
            rows={3}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            key={TemplateFormField.AdditionalSearchParams}
            name={TemplateFormField.AdditionalSearchParams}
            label={t('Additional arguments')}
            helperNote={t(
              'Those arguments are used for filling puppeteer pdf options.'
            )}
            validationOptions={{
              validate: isStringWithMaxLength256
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<ITemplateForm>
            selectOptions={Object.values(DataSetType).reduce(
              (acc, dataSetType) => ({
                ...acc,
                [dataSetType]: translateTemplateDatasetType(dataSetType)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            name={TemplateFormField.DataSetType}
            label={t('Data request')}
            key={TemplateFormField.DataSetType}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
    </form>
  )
}
