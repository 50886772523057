import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionState} from '../../../../windowManagement/types'
import {Blank, BlankContentPosition} from '../../../visual/Blank'

interface INotGrantedWindowManagementPermissionBlankProps {
  permissionState: PermissionState
  askForPermission: () => void
}

export const NotGrantedWindowManagementPermissionBlank: React.FC<INotGrantedWindowManagementPermissionBlankProps> =
  ({
    permissionState,
    askForPermission
  }: INotGrantedWindowManagementPermissionBlankProps) => {
    const {t} = useTranslation()
    return (
      <Blank
        title={t('Missing permission')}
        description={t(
          'Browser permission for window management is missing. Please enable this in your browser settings, or contact IT support if the issue persists.'
        )}
        actions={
          permissionState === 'prompt' && (
            <Button
              variant="contained"
              color="primary"
              onClick={askForPermission}
            >
              {t('Retry')}
            </Button>
          )
        }
        IconComp={ReportProblemOutlinedIcon}
        contentPosition={BlankContentPosition.Center}
      />
    )
  }
