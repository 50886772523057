import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  listItemTextClasses,
  Typography,
  useMediaQuery
} from '@mui/material'
import {styled} from '@mui/system'
import dayjs from 'dayjs'
import queryString from 'query-string'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions, useUserInfo} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {PaymentReportDrawer} from '../../../../common/paymentReportDrawer'
import {MediaSizes} from '../../../../constants'
import {CashRegisterOperationDrawer} from './CashRegisterOperationDrawer'
import {CashRegisterOperation} from './types'

const StyledListItem = styled(ListItem)(({theme}) => ({
  padding: theme.spacing(1.5, 3),
  borderBottom: `solid ${theme.palette.divider} 1px`,
  '&:last-child': {
    borderBottom: 'none'
  },
  [`.${listItemTextClasses.root}`]: {
    maxWidth: '80%'
  }
}))

export const CashRegisterOperations: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {user} = useUserInfo()
  const history = useHistory()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const [selectedCashRegisterOperation, setSelectedCashRegisterOperation] =
    useState<CashRegisterOperation | null>(null)
  const handlePaymentReportViewClick = useCallback(
    () =>
      history.push({
        pathname: routeTo.admin.cashDesk.deviceInformation(),
        search: queryString.stringify({
          dateFrom: dayjs().startOf('d').toISOString(),
          dateTo: dayjs().endOf('d').toISOString(),
          userIds: user ? [user.id] : undefined
        })
      }),
    [history, user]
  )
  return (
    <>
      <Box>
        <Typography variant="subtitle1" sx={{pb: 1.75}}>
          {t('Cash register operations')}
        </Typography>
        <List
          sx={{
            backgroundColor: 'background.paper',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: 1
          }}
        >
          {P([
            PermissionCode.CreateCashDeskDeposit,
            PermissionCode.ReadRetailPaymentMethods
          ]) && (
            <StyledListItem
              secondaryAction={
                isPhablet ? (
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setSelectedCashRegisterOperation(
                        CashRegisterOperation.Deposit
                      )
                    }
                  >
                    <ChevronRightIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() =>
                      setSelectedCashRegisterOperation(
                        CashRegisterOperation.Deposit
                      )
                    }
                  >
                    {t('Deposit')}
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t('Deposit')}
                primaryTypographyProps={{variant: 'subtitle2'}}
                secondary={t(
                  'Add funds to your cash register to guarantee you have enough cash for giving change.'
                )}
                secondaryTypographyProps={{
                  variant: 'caption',
                  color: 'textSecondary'
                }}
              />
            </StyledListItem>
          )}
          {P([
            PermissionCode.CreateCashDeskWithdrawal,
            PermissionCode.ReadRetailPaymentMethods
          ]) && (
            <StyledListItem
              secondaryAction={
                isPhablet ? (
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setSelectedCashRegisterOperation(
                        CashRegisterOperation.Withdrawal
                      )
                    }
                  >
                    <ChevronRightIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() =>
                      setSelectedCashRegisterOperation(
                        CashRegisterOperation.Withdrawal
                      )
                    }
                  >
                    {t('Withdrawal')}
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t('Withdrawal')}
                primaryTypographyProps={{variant: 'subtitle2'}}
                secondary={t(
                  'Withdraw funds from the cash register at the end of your shift or when another cashier takes over.'
                )}
                secondaryTypographyProps={{
                  variant: 'caption',
                  color: 'textSecondary'
                }}
              />
            </StyledListItem>
          )}
          {P([PermissionCode.ReadPaymentReportView]) && (
            <StyledListItem
              secondaryAction={
                isPhablet ? (
                  <IconButton
                    color="primary"
                    onClick={handlePaymentReportViewClick}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handlePaymentReportViewClick}
                  >
                    {t('View')}
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t('Payment report view')}
                primaryTypographyProps={{variant: 'subtitle2'}}
                secondary={t(
                  "Comprehensive overview of the day's financial transactions, including total sales, cash received, credit card payments and refunds. Display detailed summaries of each transaction type, ensuring accurate end-of-day reconciliation."
                )}
                secondaryTypographyProps={{
                  variant: 'caption',
                  color: 'textSecondary'
                }}
              />
            </StyledListItem>
          )}
        </List>
      </Box>
      <CashRegisterOperationDrawer
        isOpen={!!selectedCashRegisterOperation}
        onClose={() => setSelectedCashRegisterOperation(null)}
        operation={selectedCashRegisterOperation!}
      />
      {P([PermissionCode.ReadPaymentReportView]) && (
        <PaymentReportDrawer
          onExited={() =>
            history.replace(routeTo.admin.cashDesk.deviceInformation())
          }
        />
      )}
    </>
  )
}
