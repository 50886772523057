import {Box, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {LightweightTourTimeSlotsQuery} from '../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../hooks/formatUserName'
import {useTranslateAgeClassificationAbbreviation} from '../../../hooks/translateAgeClassification'
import {useTranslateShowVersionAbbreviation} from '../../../hooks/translateDistributions'
import {useDateTimeFormatters} from '../../../utils/formatting'
import {routeTo} from '../../../utils/routes'
import {TimeSlotCapacityIndicators} from '../../pages/admin/cashDesk/tours/TimeSlotCapacityIndicators'
import {ListOfItemsSeparatedByDividers} from '../ListOfItemsSeparatedByDividers'

interface ITimeSlotItemProps {
  timeSlot: LightweightTourTimeSlotsQuery['tourTimeSlots']['items'][number]
  onClick: () => void
}

const TimeSlotItem: React.FC<ITimeSlotItemProps> = ({
  timeSlot,
  onClick
}: ITimeSlotItemProps) => {
  const {formatTime} = useDateTimeFormatters()
  const formatShortGuideName = useFormatShortGuideName()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 1.5,
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer'
        }
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle2">
          {formatTime(new Date(timeSlot.startsAt))}
        </Typography>
        <TimeSlotCapacityIndicators
          retailAttendeesLimit={timeSlot.retailAttendeesLimit}
          stateCounts={timeSlot.tourItemsStateCounts}
          hideCapacity
        />
      </Box>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{alignSelf: 'end'}}
      >
        {[
          timeSlot.guide && formatShortGuideName(timeSlot.guide),
          timeSlot.versionCode &&
            translateShowVersionAbbreviation(timeSlot.versionCode),
          timeSlot.ageClassificationCode &&
            translateAgeClassificationAbbreviation(
              timeSlot.ageClassificationCode
            )
        ]
          .filter(Boolean)
          .join(' • ')}
      </Typography>
    </Box>
  )
}

interface ITimeSlotsListProps {
  date: string
  timeSlots: LightweightTourTimeSlotsQuery['tourTimeSlots']['items']
}

export const TimeSlotsList: React.FC<ITimeSlotsListProps> = ({
  date,
  timeSlots
}: ITimeSlotsListProps) => {
  const history = useHistory()
  const {formatDateNumeric, formatLongWeekDay} = useDateTimeFormatters()
  const handleClick = useCallback(
    (tourTimeSlotId: number) => () =>
      history.push(routeTo.admin.cashDesk.tourTimeSlot(tourTimeSlotId)),
    [history]
  )
  return (
    <Box>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'background.paper',
          py: 0.5
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          {[
            formatDateNumeric(new Date(date)),
            formatLongWeekDay(new Date(date))
          ].join(' • ')}
        </Typography>
      </Box>
      <ListOfItemsSeparatedByDividers sx={{overflow: 'auto'}} isFullWidth>
        {timeSlots.map((timeSlot) => (
          <TimeSlotItem
            key={timeSlot.id}
            timeSlot={timeSlot}
            onClick={handleClick(timeSlot.id)}
          />
        ))}
      </ListOfItemsSeparatedByDividers>
    </Box>
  )
}
