import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import SearchIcon from '@mui/icons-material/Search'
import {Typography, useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {compact, flow, isEmpty, sortBy, values} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  NarrowCashDeskReservationPropertiesFragment,
  PermissionCode,
  ReservationsFilterInput,
  ReservationsQuery
} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {
  EVENTS_PARAMS,
  RESERVATION_PARAMS,
  useEventsPathnameParams,
  useTourParams
} from '../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {LoadingMoreProgress} from '../../../common/LoadingMoreProgress'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {ReservationDetailDrawer} from '../components/reservationDetailDrawer'
import {SecondaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {useGetReservations} from './graphql'
import {ListItemWrapper} from './ListItemWrapper'
import {ReservationListItem} from './reservations/ReservationListItem'
import {
  DEFAULT_RESERVATIONS_FILTER_INPUT,
  ReservationSearchLocation,
  ReservationsSearch
} from './ReservationsSearch'

const useStyles = makeStyles<Theme>((theme) => ({
  searchResultsText: {
    paddingBottom: theme.spacing(1)
  },
  loadingMoreProgress: {
    paddingTop: theme.spacing(2)
  }
}))

const isEmptyObject = flow(values, compact, isEmpty)

export const ActivityReservations: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const [searchFilter, setSearchFilter] = useState<ReservationsFilterInput>(
    DEFAULT_RESERVATIONS_FILTER_INPUT
  )
  const {eventId} = useEventsPathnameParams()
  const {tourTimeSlotId} = useTourParams()
  const {
    data,
    error,
    loading,
    isLoadingMore,
    fetchMore,
    refetch: refetchReservations
  } = useGetReservations(
    {
      ...searchFilter,
      eventId: isNaN(eventId) ? undefined : eventId,
      tourTimeSlotId: isNaN(tourTimeSlotId) ? undefined : tourTimeSlotId
    },
    30
  )
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.reservations.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.reservations.pagination.hasMore, fetchMore, isLoadingMore])
  const history = useHistory()
  const classes = useStyles()
  const getOnInfoClickHandler = useCallback(
    (reservation: NarrowCashDeskReservationPropertiesFragment) =>
      (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!isNaN(eventId) && reservation.event) {
          history.replace(
            routeTo.admin.cashDesk.eventReservationsWithReservationInfo(
              reservation.event.id,
              reservation.id
            )
          )
        }
        if (!isNaN(tourTimeSlotId) && reservation.tourTimeSlot) {
          history.replace(
            routeTo.admin.cashDesk.tourTimeSlotReservationsWithReservationInfo(
              reservation.tourTimeSlot.id,
              reservation.id
            )
          )
        }
      },
    [eventId, history, tourTimeSlotId]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={t('Reservations')}
          search={
            <ReservationsSearch
              location={ReservationSearchLocation.ActivityReservations}
              onFilterChange={setSearchFilter}
            />
          }
          hasArrowBackIcon
          isDatetimeEnabled
        />
      }
    >
      <CenteredLayout
        onScrolledNearTheEndOfTheLayout={
          data?.reservations.pagination.hasMore
            ? handleScrolledNearTheEndOfTheLayout
            : undefined
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData
            data={data}
            error={error}
            loading={loading}
            errorMessage={t<string>('Failed to load reservations')}
            dataCondition={(data?: ReservationsQuery) =>
              Array.isArray(data?.reservations.items)
            }
          >
            {({reservations}: ReservationsQuery) =>
              reservations.items.length ? (
                <CenteredLayoutListWrapper>
                  {!isEmptyObject(searchFilter) && (
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      className={classes.searchResultsText}
                    >
                      {t('Search results')}
                    </Typography>
                  )}
                  {sortBy(reservations.items, 'expireAt').map((reservation) => (
                    <ListItemWrapper key={reservation.id}>
                      <ReservationListItem
                        reservation={reservation}
                        onInfoClick={getOnInfoClickHandler(reservation)}
                        isPhablet={isPhablet}
                      />
                    </ListItemWrapper>
                  ))}
                  {isLoadingMore && (
                    <LoadingMoreProgress
                      className={classes.loadingMoreProgress}
                    />
                  )}
                </CenteredLayoutListWrapper>
              ) : (
                <Blank
                  title={
                    isEmptyObject(searchFilter)
                      ? t('No reservations found for event')
                      : t('No search results found')
                  }
                  IconComp={
                    isEmptyObject(searchFilter)
                      ? BookmarkBorderIcon
                      : SearchIcon
                  }
                  description={
                    isEmptyObject(searchFilter)
                      ? undefined
                      : t(
                          'Try another search, or click the arrow in the search box to find a results by advanced search.'
                        )
                  }
                />
              )
            }
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </CenteredLayout>
      {P([PermissionCode.ReadReservation]) && (
        <Route
          path={
            isNaN(tourTimeSlotId)
              ? routeTo.admin.cashDesk.eventReservationsWithReservationInfo(
                  toPlaceholderParam(EVENTS_PARAMS.EVENT_ID),
                  toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
                )
              : routeTo.admin.cashDesk.tourTimeSlotReservationsWithReservationInfo(
                  ':tourTimeSlotId',
                  ':reservationId'
                )
          }
        >
          <ReservationDetailDrawer
            initialPath={
              isNaN(tourTimeSlotId)
                ? routeTo.admin.cashDesk.eventReservations(
                    toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
                  )
                : routeTo.admin.cashDesk.tourTimeSlotReservations(
                    ':tourTimeSlotId'
                  )
            }
            detailPath={
              isNaN(tourTimeSlotId)
                ? routeTo.admin.cashDesk.eventReservationsWithReservationInfo(
                    toPlaceholderParam(EVENTS_PARAMS.EVENT_ID),
                    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
                  )
                : routeTo.admin.cashDesk.tourTimeSlotReservationsWithReservationInfo(
                    ':tourTimeSlotId',
                    ':reservationId'
                  )
            }
            editPath={
              isNaN(tourTimeSlotId)
                ? routeTo.admin.cashDesk.eventReservationsWithReservationInfoEdit(
                    toPlaceholderParam(EVENTS_PARAMS.EVENT_ID),
                    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
                  )
                : routeTo.admin.cashDesk.tourTimeSlotReservationsWithReservationInfoEdit(
                    ':tourTimeSlotId',
                    ':reservationId'
                  )
            }
            onReservationDelete={refetchReservations}
            showPrimaryButton
          />
        </Route>
      )}
    </PageWithHeaderTemplate>
  )
}
