import {useTranslation} from 'react-i18next'
import {TourTimeSlotState} from '../__generated__/schema'

export const useTranslateTourTimeSlotState = () => {
  const {t} = useTranslation()
  const translated: Record<TourTimeSlotState, string> = {
    [TourTimeSlotState.Draft]: t<string>('TourTimeSlotState->Draft'),
    [TourTimeSlotState.Published]: t<string>('TourTimeSlotState->Published'),
    [TourTimeSlotState.Cancelled]: t<string>('TourTimeSlotState->Cancelled')
  }
  return (state: TourTimeSlotState) => translated[state]
}
