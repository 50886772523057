import {styled} from '@mui/system'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {CommonSettingsScriptLocation} from '../../../../../../__generated__/schema'
import {useTranslateCommonSettingsScriptLocation} from '../../../../../../hooks/translateCommonSettingsScriptLocation'
import {
  useIsStringWithMaxLength,
  useIsValidURL
} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {slugify} from '../../../utils'
import {IScriptForm, ScriptFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(1)
}))

interface IScriptFormProps {
  formId: string
  onSubmit: (data: IScriptForm) => void
  defaultValues?: Partial<IScriptForm>
}

export const ScriptForm: React.FC<IScriptFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IScriptFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IScriptForm>({defaultValues, mode: 'onChange'})
  const translateCommonSettingsScriptLocation =
    useTranslateCommonSettingsScriptLocation()
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isValidURL = useIsValidURL(true)
  const handleIdBlur = useCallback(() => {
    setValue(ScriptFormField.Id, slugify(watch(ScriptFormField.Id ?? 'id')))
  }, [setValue, watch])
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IScriptForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ScriptFormField.Id}
            key={ScriptFormField.Id}
            label={t('ID')}
            onBlur={handleIdBlur}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IScriptForm>
            selectOptions={Object.values(CommonSettingsScriptLocation).reduce(
              (acc, location) => ({
                ...acc,
                [location]: translateCommonSettingsScriptLocation(location)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={ScriptFormField.Location}
            key={ScriptFormField.Location}
            label={t('Location')}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IScriptForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ScriptFormField.Content}
            key={ScriptFormField.Content}
            label={t('Inline script')}
            fullWidth
            multiline
            rows={5}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IScriptForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ScriptFormField.Src}
            key={ScriptFormField.Src}
            validationOptions={{validate: isValidURL}}
            label={t('External file')}
            helperNote={t(
              'A path string specifying the URL of an external script. This can be either an absolute external URL or an internal path. The external file property is required unless an inline script is used.'
            )}
            fullWidth
          />
        ]}
      />
    </StyledForm>
  )
}
