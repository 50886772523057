import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {safeSum} from '../../../../utils/money'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: 64,
    display: 'grid',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 2),
    gridTemplateAreas: `
      "subtotalLabel tickets"
      "subtotalLabel subtotal"
    `,
    gridTemplateColumns: '1fr auto'
  },
  subtotalLabel: {
    gridArea: 'subtotalLabel',
    alignSelf: 'center'
  },
  count: {
    alignSelf: 'flex-end'
  },
  price: {
    alignSelf: 'flex-start'
  }
}))

interface ITicketsSubtotalProps {
  items: TicketItemPropertiesFragment[] | TourItemPropertiesFragment[]
  description: string
}

export const TicketsSubtotal: React.FC<ITicketsSubtotalProps> = ({
  items,
  description
}: ITicketsSubtotalProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.subtotalLabel}>
        {t('Subtotal')}
      </Typography>
      <Typography align="right" variant="caption" className={classes.count}>
        {description}
      </Typography>
      <Typography align="right" variant="subtitle1" className={classes.price}>
        {translatePrice(
          safeSum(
            items.map(
              (
                item: TicketItemPropertiesFragment | TourItemPropertiesFragment
              ) => item.price
            )
          )
        )}
      </Typography>
    </div>
  )
}
