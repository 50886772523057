import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

export enum PanelType {
  DEFAULT = 'default',
  OBJECT = 'object'
}

export interface ISidePanelState {
  activePanelType: PanelType
}

const initialState: ISidePanelState = {
  activePanelType: PanelType.DEFAULT
}

// Note: this can not be declared inside object (map), because then type inference
// for reducer stops working ... (for some unknow reason)
const SET_PANEL = 'SET_PANEL'

interface ISetPanelAction {
  type: typeof SET_PANEL
  payload: PanelType
}

// Add actions as ISidePanelAction = ISomeAction1 | ISomeAction2 | ...
type SidePanelAction = ISetPanelAction

export const sidePanelReducer = (
  state = initialState,
  action: SidePanelAction
): ISidePanelState => {
  switch (action.type) {
    case SET_PANEL:
      return {...state, activePanelType: action.payload}
    default:
      return state
  }
}

export const useSidePanelActions = () => {
  const dispatch = useDispatch()

  const showDefaultPanel = useCallback(() => {
    dispatch({type: SET_PANEL, payload: PanelType.DEFAULT})
  }, [dispatch])

  const showObjectPanel = useCallback(() => {
    dispatch({type: SET_PANEL, payload: PanelType.OBJECT})
  }, [dispatch])

  const actions = useMemo(
    () => ({
      showDefaultPanel,
      showObjectPanel
    }),
    [showDefaultPanel, showObjectPanel]
  )

  return actions
}
