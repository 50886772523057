import {SvgIconProps} from '@mui/material'
import React from 'react'

import {User} from '../../../__generated__/schema'

export enum BaseHeaderVariant {
  Primary = 'primary',
  Secondary = 'secondary'
}

export enum HeaderActionType {
  Button = 'button',
  IconButton = 'iconButton'
}

export enum IconPosition {
  Start = 'start',
  End = 'end'
}

export type ButtonHeaderAction = {
  type: HeaderActionType.Button
  label: string
  onClick?: React.MouseEventHandler<HTMLElement>
  Icon?: React.FC<SvgIconProps>
  isDisabled?: boolean
  isSelected?: boolean
  hasDivider?: boolean
  cypressId?: string
  iconPosition?: IconPosition
}

export type IconButtonHeaderAction = {
  type: HeaderActionType.IconButton
  label: string
  onClick?: React.MouseEventHandler<HTMLElement>
  Icon: React.FC<SvgIconProps>
  isDisabled?: boolean
  isSelected?: boolean
  hasDivider?: boolean
  cypressId?: string
  iconPosition?: IconPosition
}

export type HeaderAction = ButtonHeaderAction | IconButtonHeaderAction

export type HeaderUserProps = Pick<
  User,
  'id' | 'username' | 'firstName' | 'lastName' | 'workEmail' | 'personalEmail'
>
