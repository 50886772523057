import dayjs from 'dayjs'
import {get} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {useIsNonNegativeInteger} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {ICollapseSectionProps} from '../../../common/CollapseSection'
import {ExpandableSettings} from '../../../common/Settings'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormTimeInput} from '../../../form/pickers'
import {FormFieldName} from '../../../form/types'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  ITimeSelectValue,
  ITourDivisionSettingsForm,
  TimeSelectFormField,
  TimeSelectOption,
  TimeSelectValue
} from './types'
import {getDurationOption, useTranslateTimeSelectOption} from './utils'

const useGetSelectedValuesDescription = () => {
  const {t} = useTranslation()
  const translateTimeSelectOption = useTranslateTimeSelectOption()
  const {formatTime} = useDateTimeFormatters()
  return useCallback(
    (option?: TimeSelectOption, time?: ITimeSelectValue) => {
      if (option) {
        switch (option) {
          case TimeSelectOption.EventStart:
            return translateTimeSelectOption(option)
          case TimeSelectOption.AfterStart:
          case TimeSelectOption.BeforeStart:
          case TimeSelectOption.BeforeEnds:
          case TimeSelectOption.AfterEnds:
            return [time?.minutes, translateTimeSelectOption(option)]
              .filter(Boolean)
              .join(' ')
          case TimeSelectOption.DayBeforeStart:
          case TimeSelectOption.DayOfEvent:
            return [
              time?.time &&
                dayjs(time.time).isValid() &&
                formatTime(new Date(time.time)),
              translateTimeSelectOption(option)
            ]
              .filter(Boolean)
              .join(' ')
          case TimeSelectOption.Duration:
            return [
              translateTimeSelectOption(option),
              [
                time?.daysDropdown &&
                  t('{{count}} day', {count: parseInt(time.daysDropdown, 10)}),
                time?.hoursDropdown &&
                  t('{{count}} hour', {
                    count: parseInt(time.hoursDropdown, 10)
                  }),
                time?.minutesDropdown &&
                  t('{{count}} minute', {
                    count: parseInt(time.minutesDropdown, 10)
                  })
              ]
                .filter(Boolean)
                .join(', ')
            ]
              .filter(Boolean)
              .join(': ')
          default:
            return undefined
        }
      }
      return undefined
    },
    [formatTime, t, translateTimeSelectOption]
  )
}

interface ITimeSelectProps {
  errors: FormContextValues<ITourDivisionSettingsForm>['errors']
  watch: FormContextValues<ITourDivisionSettingsForm>['watch']
  register: FormContextValues<ITourDivisionSettingsForm>['register']
  setValue: FormContextValues<ITourDivisionSettingsForm>['setValue']
  triggerValidation: FormContextValues<ITourDivisionSettingsForm>['triggerValidation']
  control: FormContextValues<ITourDivisionSettingsForm>['control']
  unregister: FormContextValues<ITourDivisionSettingsForm>['unregister']
  setError: FormContextValues<ITourDivisionSettingsForm>['setError']
  clearError: FormContextValues<ITourDivisionSettingsForm>['clearError']
  options: TimeSelectOption[]
  name: FormFieldName<ITourDivisionSettingsForm>
}

export const TimeSelect: React.FC<ITimeSelectProps> = ({
  errors,
  watch,
  register,
  setValue,
  triggerValidation,
  control,
  unregister,
  setError,
  clearError,
  options,
  name
}: ITimeSelectProps) => {
  const {t} = useTranslation()
  const translateTimeSelectOption = useTranslateTimeSelectOption()
  const isNonNegativeInteger = useIsNonNegativeInteger()
  const selectedOption = watch(`${name}[${TimeSelectFormField.Option}]`)
  return (
    <>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<ITourDivisionSettingsForm>
            errors={errors}
            watch={watch}
            register={register}
            setValue={setValue}
            label={t('Select option')}
            name={
              `${name}[${TimeSelectFormField.Option}]` as FormFieldName<ITourDivisionSettingsForm>
            }
            key={name}
            validationOptions={{
              required: true
            }}
            selectOptions={Object.values(options).reduce(
              (acc, option) => ({
                ...acc,
                [option]: translateTimeSelectOption(option)
              }),
              {}
            )}
            fullWidth
          />
        ]}
      />
      {selectedOption &&
        [
          TimeSelectOption.AfterStart,
          TimeSelectOption.BeforeStart,
          TimeSelectOption.BeforeEnds,
          TimeSelectOption.AfterEnds
        ].includes(selectedOption as TimeSelectOption) && (
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ITourDivisionSettingsForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={
                  `${name}[${TimeSelectFormField.Value}][${TimeSelectValue.Minutes}]` as FormFieldName<ITourDivisionSettingsForm>
                }
                key={`${name}[${TimeSelectFormField.Value}][${TimeSelectValue.Minutes}]`}
                validationOptions={{
                  required: true,
                  validate: isNonNegativeInteger
                }}
                fullWidth
              />
            ]}
          />
        )}
      {selectedOption &&
        [TimeSelectOption.DayBeforeStart, TimeSelectOption.DayOfEvent].includes(
          selectedOption as TimeSelectOption
        ) && (
          <FormTimeInput<ITourDivisionSettingsForm>
            timePickerProps={{
              label: t<string>('Select time')
            }}
            control={control}
            register={register}
            unregister={unregister}
            watch={watch}
            errors={errors}
            clearError={clearError}
            setValue={setValue}
            name={
              `${name}[${TimeSelectFormField.Value}][${TimeSelectValue.Time}]` as FormFieldName<ITourDivisionSettingsForm>
            }
            key={`${name}[${TimeSelectFormField.Value}][${TimeSelectValue.Time}]`}
            setError={setError}
            validationOptions={{
              required: true
            }}
            fullWidth
          />
        )}
      {selectedOption && selectedOption === TimeSelectOption.Duration && (
        <InputRow
          nodes={[
            <UncontrolledFormSelect<ITourDivisionSettingsForm>
              errors={errors}
              watch={watch}
              register={register}
              setValue={setValue}
              label={t('Days')}
              name={
                `${name}[${TimeSelectFormField.Value}][${TimeSelectValue.DaysDropdown}]` as FormFieldName<ITourDivisionSettingsForm>
              }
              key={`${name}[${TimeSelectFormField.Value}][${TimeSelectValue.DaysDropdown}]`}
              selectOptions={getDurationOption(7)}
              fullWidth
            />,
            <UncontrolledFormSelect
              errors={errors}
              watch={watch}
              register={register}
              setValue={setValue}
              label={t('Hours')}
              name={
                `${name}[${TimeSelectFormField.Value}][${TimeSelectValue.HoursDropdown}]` as FormFieldName<ITourDivisionSettingsForm>
              }
              key={`${name}[${TimeSelectFormField.Value}][${TimeSelectValue.HoursDropdown}]`}
              selectOptions={getDurationOption(24)}
              fullWidth
            />,
            <UncontrolledFormSelect<ITourDivisionSettingsForm>
              errors={errors}
              watch={watch}
              register={register}
              setValue={setValue}
              label={t('Minutes')}
              name={
                `${name}[${TimeSelectFormField.Value}][${TimeSelectValue.MinutesDropdown}]` as FormFieldName<ITourDivisionSettingsForm>
              }
              key={`${name}[${TimeSelectFormField.Value}][${TimeSelectValue.MinutesDropdown}]`}
              selectOptions={getDurationOption(60)}
              fullWidth
            />
          ]}
        />
      )}
    </>
  )
}

interface IExpandableTimeSelectSettingsProps
  extends ITimeSelectProps,
    Omit<ICollapseSectionProps, 'children'> {}

export const ExpandableTimeSelectSettings: React.FC<IExpandableTimeSelectSettingsProps> =
  ({
    errors,
    watch,
    register,
    setError,
    triggerValidation,
    control,
    unregister,
    setValue,
    clearError,
    options,
    name,
    ...expandableSettingProps
  }: IExpandableTimeSelectSettingsProps) => {
    const {t} = useTranslation()
    const selectedOption = watch(`${name}[${TimeSelectFormField.Option}]`)
    const getSelectedValuesDescription = useGetSelectedValuesDescription()
    const hasError = !!get(errors, name)
    const selectedTime = watch(`${name}[${TimeSelectFormField.Value}]`)
    useEffect(() => {
      if (selectedOption) {
        triggerValidation([
          `${name}[${TimeSelectFormField.Option}]` as FormFieldName<ITourDivisionSettingsForm>,
          `${name}[${TimeSelectFormField.Value}]` as FormFieldName<ITourDivisionSettingsForm>
        ])
      }
    }, [triggerValidation, name, selectedOption])
    return (
      <ExpandableSettings
        helperText={
          hasError
            ? t('Fix errors in this setting')
            : getSelectedValuesDescription(
                selectedOption as TimeSelectOption,
                selectedTime as ITimeSelectValue
              )
        }
        hasError={hasError}
        {...expandableSettingProps}
      >
        <TimeSelect
          errors={errors}
          watch={watch}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          control={control}
          unregister={unregister}
          setError={setError}
          clearError={clearError}
          options={options}
          name={name}
        />
      </ExpandableSettings>
    )
  }
