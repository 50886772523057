import {Divider} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery,
  PassCodeCheckInputType
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {CodeEntry} from './CodeEntry'
import {Header} from './Header'

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    padding: theme.spacing(0, 2, 1),
    backgroundColor: theme.palette.background.paper
  },
  cameraBox: {
    background: theme.palette.background.paper,
    flex: 1
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}))

interface IMobileContentProps {
  data:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
  inputType: PassCodeCheckInputType
}

export const PhabletContent: React.FC<IMobileContentProps> = ({
  data,
  inputType
}: IMobileContentProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Header data={data} className={classes.header} />
      <Divider />
      <CodeEntry
        className={classes.cameraBox}
        inputType={inputType}
        entityData={data}
      />
    </div>
  )
}
