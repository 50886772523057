import React from 'react'
import {IntentStructureAccordion} from '../../../../common/IntentStructureAccordion'
import {IntentFrames} from './IntentFrames'
import {IntentStructureAccordionSecondaryChildren} from './IntentStructureAccordionSecondaryChildren'
import {IGroupIntentStructure} from './types'

interface IGroupIntentStructureProps {
  structure: IGroupIntentStructure
  isExpanded: boolean
  onStructureExpand: () => void
}

export const GroupIntentStructure: React.FC<IGroupIntentStructureProps> = ({
  structure,
  isExpanded,
  onStructureExpand
}: IGroupIntentStructureProps) => (
  <IntentStructureAccordion
    isExpanded={isExpanded}
    onSummaryClick={onStructureExpand}
    name={structure.group.name}
    secondaryChildren={
      <IntentStructureAccordionSecondaryChildren
        intentFrames={structure.intentFrames}
        isExpanded={isExpanded}
      />
    }
  >
    <IntentFrames structure={structure} />
  </IntentStructureAccordion>
)
