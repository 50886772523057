import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {TicketItemPropertiesFragment} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'

const useBaseStyles = makeStyles<Theme>((theme) => ({
  labels: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  price: {
    alignSelf: 'center',
    textAlign: 'right'
  },
  priceBeforeDiscount: {
    textDecoration: 'line-through'
  }
}))

export interface IBasicTicketListItemProps {
  primaryLabel: string
  secondaryLabel?: string
  priceLabel: string
  priceBeforeDiscountLabel?: string
}

export const BasicTicketListItem: React.FC<IBasicTicketListItemProps> = ({
  primaryLabel,
  secondaryLabel,
  priceLabel,
  priceBeforeDiscountLabel
}: IBasicTicketListItemProps) => {
  const classes = useBaseStyles()
  return (
    <>
      <div className={classes.labels}>
        <Typography variant="subtitle2">{primaryLabel}</Typography>
        {secondaryLabel && (
          <Typography variant="caption" color="textSecondary">
            {secondaryLabel}
          </Typography>
        )}
      </div>
      <div className={classes.price}>
        <Typography variant="body2">{priceLabel}</Typography>
        {priceBeforeDiscountLabel && (
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.priceBeforeDiscount}
          >
            {priceBeforeDiscountLabel}
          </Typography>
        )}
      </div>
    </>
  )
}

export const getTicketListItemPrimaryLabel = (
  eventSeat: TicketItemPropertiesFragment['eventSeat']
) =>
  [eventSeat.label, eventSeat.row, eventSeat.section, eventSeat.floor]
    .filter(Boolean)
    .join(' • ')

export const useGetBasicTicketListItemProps = () => {
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return useCallback(
    ({
      eventSeat,
      price,
      appliedDiscounts,
      priceBeforeDiscount
    }: TicketItemPropertiesFragment) => ({
      primaryLabel: getTicketListItemPrimaryLabel(eventSeat),
      secondaryLabel: appliedDiscounts
        .map((appliedDiscount) => appliedDiscount.discount.name)
        .join(' • '),
      priceLabel: translateEffectiveClientPrice(price),
      priceBeforeDiscountLabel:
        price !== priceBeforeDiscount
          ? translateEffectiveClientPrice(priceBeforeDiscount)
          : undefined
    }),
    [translateEffectiveClientPrice]
  )
}
