import {useRef, useEffect} from 'react'
import {debounce, DebouncedFunc, DebounceSettings} from 'lodash'

export const useDebounce = <T extends (...args: any[]) => any>(
  effect: T,
  delay?: number,
  options?: DebounceSettings
): DebouncedFunc<T> => {
  const debouncedOnChangeRef = useRef<DebouncedFunc<T>>(
    debounce(effect, delay, options)
  )

  useEffect(() => {
    debouncedOnChangeRef.current = debounce(effect, delay, options)
    return () => {
      if (debouncedOnChangeRef.current) {
        debouncedOnChangeRef.current.cancel()
      }
    }
  }, [delay, effect, options])

  return debouncedOnChangeRef.current
}
