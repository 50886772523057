import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {NarrowDivisionPropertiesFragment} from '../../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../DialogTitleWithCloseButton'

interface IAddDivisionDialogProps {
  isOpen: boolean
  onClose: () => void
  divisions: NarrowDivisionPropertiesFragment[]
  onAddButtonClick: (divisions: NarrowDivisionPropertiesFragment[]) => void
}

export const AddDivisionDialog: React.FC<IAddDivisionDialogProps> = ({
  isOpen,
  onAddButtonClick,
  divisions,
  onClose
}: IAddDivisionDialogProps) => {
  const {t} = useTranslation()
  const [selectedDivisions, setSelectedDivisions] = useState<
    NarrowDivisionPropertiesFragment[]
  >([])
  const getCheckBoxClickHandler = useCallback(
    (division: NarrowDivisionPropertiesFragment) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedDivisions((divisions) =>
        divisions.includes(division)
          ? divisions.filter(({id}) => id !== division.id)
          : [...divisions, division]
      )
    },
    []
  )
  const handleAddButtonClick = useCallback(() => {
    onAddButtonClick(selectedDivisions)
    setSelectedDivisions([])
    onClose()
  }, [onAddButtonClick, onClose, selectedDivisions])
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add division')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{py: 1, px: 2}} dividers>
        <Typography variant="caption" color="textSecondary">
          {t('Select division enabled on device')}
        </Typography>
        {divisions.map((division) => (
          <ListItem
            key={division.id}
            divider
            dense
            disableGutters
            sx={{
              '&:last-child': {
                borderBottom: 0
              }
            }}
          >
            <ListItemIcon>
              <Checkbox
                color="primary"
                edge="start"
                onClick={getCheckBoxClickHandler(division)}
              />
            </ListItemIcon>
            <ListItemText
              primary={division.name}
              primaryTypographyProps={{variant: 'subtitle2'}}
            />
          </ListItem>
        ))}
      </DialogContent>
      <DialogActions sx={{py: 2, px: 3}}>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedDivisions.length === 0}
          onClick={handleAddButtonClick}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
