import {useTranslation} from 'react-i18next'
import {WarehouseState} from '../__generated__/schema'

export const useTranslateWarehouseState = () => {
  const {t} = useTranslation()
  const translated: Record<WarehouseState, string> = {
    [WarehouseState.Active]: t<string>('WarehouseState->Active'),
    [WarehouseState.Inactive]: t<string>('WarehouseState->Inactive'),
    [WarehouseState.Deleted]: t<string>('WarehouseState->Deleted')
  }
  return (state: WarehouseState) => translated[state]
}
