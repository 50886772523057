import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  useIsDecimalWithMinMaxBoundary,
  useIsPercentValue
} from '../../../../../../utils/formsValidations'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {StackableCardDivider, StackableCardWrapper} from '../../../../../visual'
import {
  EventBasePropertyCard,
  EventFormFieldHeader,
  FormField,
  IMainEventPropertiesFormData
} from './common'

export const Fees: React.FC = () => {
  const {t} = useTranslation()
  const {register, setValue, errors, watch, triggerValidation} =
    useFormContext<IMainEventPropertiesFormData>()
  const isPercentValue = useIsPercentValue()
  const isDecimalWithMinMaxBoundary = useIsDecimalWithMinMaxBoundary(
    0.0,
    1000000.0
  )
  return (
    <StackableCardWrapper>
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader
            required={false}
            label={t('Percentage fee per ticket')}
          />
        }
        right={
          <UncontrolledFormTextInput<IMainEventPropertiesFormData>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={FormField.FEE_PERCENTAGE_OF_SOLD_TICKETS}
            key={FormField.FEE_PERCENTAGE_OF_SOLD_TICKETS}
            placeholder={t('Enter value')}
            validationOptions={{
              validate: isPercentValue
            }}
            fullWidth
          />
        }
      />
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader
            required={false}
            label={t('Fixed fee per ticket')}
          />
        }
        right={
          <UncontrolledFormTextInput<IMainEventPropertiesFormData>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={FormField.FEE_FIXED_AMOUNT_PER_SOLD_TICKET}
            key={FormField.FEE_FIXED_AMOUNT_PER_SOLD_TICKET}
            placeholder={t('Enter value')}
            validationOptions={{
              validate: isDecimalWithMinMaxBoundary
            }}
            fullWidth
          />
        }
      />
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader required={false} label={t('Fee for event')} />
        }
        right={
          <UncontrolledFormTextInput<IMainEventPropertiesFormData>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={FormField.FEE_FIXED_AMOUNT_PER_EVENT}
            key={FormField.FEE_FIXED_AMOUNT_PER_EVENT}
            placeholder={t('Enter value')}
            validationOptions={{
              validate: isDecimalWithMinMaxBoundary
            }}
            fullWidth
          />
        }
      />
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader
            required={false}
            label={t('Minimal guarantee')}
          />
        }
        right={
          <UncontrolledFormTextInput<IMainEventPropertiesFormData>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={FormField.FEE_MINIMUM_GUARANTEE}
            key={FormField.FEE_MINIMUM_GUARANTEE}
            placeholder={t('Enter value')}
            validationOptions={{
              validate: isDecimalWithMinMaxBoundary
            }}
            fullWidth
          />
        }
      />
    </StackableCardWrapper>
  )
}
