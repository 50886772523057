import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useUserInfo} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {useUpdateMe} from '../graphql'
import {IUserFormData, userFromUserForm} from '../UserForm'

import {UserInfo} from './UserInfo'

export const Profile: React.FC = () => {
  const {user} = useUserInfo()
  const history = useHistory()
  const updateMe = useUpdateMe()
  const {t} = useTranslation()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const onSubmit = useCallback(
    async (formData: IUserFormData) => {
      try {
        setShowBackdrop(true)
        await updateMe(userFromUserForm(formData))
        history.push(routeTo.admin.dashboard())
        addInfoNotification(t('User updated'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [addInfoNotification, history, setShowBackdrop, t, updateMe]
  )
  if (!user) return null
  return <UserInfo {...{user, onSubmit}} hideRoles />
}
