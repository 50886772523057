import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Divider} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  ErrorMessages,
  PermissionCode,
  WarehouseDocumentQuery,
  WarehouseDocumentState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {useWarehouseParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {ButtonWithConfirmationDialog, RenderOnData} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {WarehouseDocumentPrintingButtonGroup} from '../../components/WarehouseDocumentPrintingButtonGroup'
import {SecondaryHeader} from '../../Header'
import {WideCenteredLayout} from '../../Layout'
import {
  useDeleteDraftWarehouseDocument,
  useIssueWarehouseDocument,
  useWarehouseDocument
} from '../graphql'
import {General} from './General'
import {Items} from './Items'
import {UpdateWarehouseDocumentDrawer} from './UpdateWarehouseDocumentDrawer'

export const WarehouseDocumentDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {warehouseDocumentId} = useWarehouseParams()
  const {
    addInfoNotification,
    setShowBackdrop,
    defaultErrorHandler,
    customErrorHandler
  } = useMutationAssistanceHooks()
  const deleteDraftWarehouseDocument = useDeleteDraftWarehouseDocument()
  const issueWarehouseDocument = useIssueWarehouseDocument()
  const {data, loading, error, refetch} = useWarehouseDocument(
    warehouseDocumentId,
    isNaN(warehouseDocumentId)
  )
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.warehouseDocuments.index()),
    [history]
  )
  const handleExited = useCallback(
    () =>
      history.push(
        routeTo.admin.warehouseDocuments.detail(warehouseDocumentId)
      ),
    [history, warehouseDocumentId]
  )
  const handleDeleteButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteDraftWarehouseDocument({id: warehouseDocumentId})
      addInfoNotification(t('Warehouse document has been deleted'))
      history.replace(routeTo.admin.warehouseDocuments.index())
    } catch (error) {
      defaultErrorHandler(
        error,
        t('Error while deleting draft warehouse document')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteDraftWarehouseDocument,
    history,
    setShowBackdrop,
    t,
    warehouseDocumentId
  ])
  const handleIssueButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await issueWarehouseDocument({id: warehouseDocumentId})
      addInfoNotification(t('Warehouse document has been issued'))
    } catch (error) {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.WarehouseProductOutOfStock
        )
      ) {
        customErrorHandler(error, {
          title: t("Warehouse document can't be issued"),
          contentText: t(
            'Issuing this document may result in negative stock levels for some products. Please verify and adjust the quantities of the relevant items accordingly.'
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.CanNotIssueEmptyWarehouseDocument
        )
      ) {
        customErrorHandler(error, {
          title: t("Warehouse document can't be issued"),
          contentText: t(
            'The document cannot be issued because it contains no items. Please add at least one item to the document before proceeding.'
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidProductMode
        )
      ) {
        customErrorHandler(error, {
          title: t('Invalid product mode'),
          contentText: t(
            "You are attempting to perform an action that is not allowed with the selected item(s). Items must be in mode 'Inventory item' to be added to warehouse document. Please verify the product mode of your items and make the necessary adjustments. If you need further assistance, contact your system administrator or refer to the help documentation."
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } else {
        defaultErrorHandler(
          error,
          t('Error while deleting draft warehouse document')
        )
      }
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    customErrorHandler,
    defaultErrorHandler,
    issueWarehouseDocument,
    setShowBackdrop,
    t,
    warehouseDocumentId
  ])
  const isIssueOrDeleteWarehouseDocumentButtonVisible =
    (P([PermissionCode.DeleteDraftWarehouseDocument]) ||
      P([PermissionCode.IssueWarehouseDocument])) &&
    data?.warehouseDocument.state === WarehouseDocumentState.Draft
  const isFooterVisible =
    isIssueOrDeleteWarehouseDocumentButtonVisible ||
    P([PermissionCode.ReadTemplates])
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={t('Warehouse document #{{id}}', {id: warehouseDocumentId})}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        isFooterVisible && (
          <Footer>
            {P([PermissionCode.ReadTemplates]) && data && (
              <>
                <WarehouseDocumentPrintingButtonGroup
                  warehouseDocumentId={data.warehouseDocument.id}
                  type={data.warehouseDocument.type}
                  variant="outlined"
                />
                {isIssueOrDeleteWarehouseDocumentButtonVisible && (
                  <Divider orientation="vertical" sx={{height: 32}} />
                )}
              </>
            )}
            {data?.warehouseDocument.state === WarehouseDocumentState.Draft && (
              <>
                {P([PermissionCode.DeleteDraftWarehouseDocument]) && (
                  <ButtonWithConfirmationDialog
                    onConfirmButtonClick={handleDeleteButtonClick}
                    dialogProps={{
                      confirmButtonLabel: t('Delete'),
                      title: t('Delete warehouse document?'),
                      contentText: t(
                        'Deleting this document is permanent and cannot be reversed. Are you sure you want to proceed?'
                      )
                    }}
                    buttonProps={{
                      startIcon: <DeleteIcon />,
                      children: t('Delete'),
                      color: 'primary'
                    }}
                  />
                )}
                {P([PermissionCode.IssueWarehouseDocument]) && (
                  <ButtonWithConfirmationDialog
                    onConfirmButtonClick={handleIssueButtonClick}
                    dialogProps={{
                      confirmButtonLabel: t('Issue'),
                      title: t('Issue warehouse document?'),
                      contentText: t(
                        'You are about to issue this warehouse document. Please review all details carefully before proceeding. Once issued, the document cannot be edited or altered in any way. Are you sure you want to proceed?'
                      )
                    }}
                    buttonProps={{
                      children: t('Issue'),
                      color: 'primary',
                      variant: 'contained',
                      disabled: data?.warehouseDocument.items.length === 0
                    }}
                  />
                )}
              </>
            )}
          </Footer>
        )
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<WarehouseDocumentQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading warehouse document')}
          dataCondition={(data) => Boolean(data.warehouseDocument)}
        >
          {({warehouseDocument}) => (
            <>
              <WideCenteredLayout>
                <Box
                  sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 5}}
                >
                  <General warehouseDocument={warehouseDocument} />
                  <Items
                    warehouseDocument={warehouseDocument}
                    refetch={refetch}
                  />
                </Box>
              </WideCenteredLayout>
              {P([
                PermissionCode.UpdateDraftWarehouseDocument,
                PermissionCode.ReadBusinessPartners
              ]) && (
                <Route
                  path={routeTo.admin.warehouseDocuments.edit(
                    ':warehouseDocumentId'
                  )}
                  exact
                >
                  <UpdateWarehouseDocumentDrawer
                    warehouseDocument={warehouseDocument}
                    onExited={handleExited}
                  />
                </Route>
              )}
            </>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
