import _ from 'lodash'

import {ITicketType, IPricingState} from './types'

const initialState: IPricingState = {}

export enum PricingActionType {
  ASSIGN_TICKET_TYPE = 'assign ticket type',
  REMOVE_TICKET_TYPE = 'remove ticket type',
  UPDATE_TICKET_TYPE_COLOR = 'update ticket type color',
  SET_PRICING = 'set pricing'
}

interface IAssignTicketTypeAction {
  type: typeof PricingActionType.ASSIGN_TICKET_TYPE
  payload: {
    objectsIds: Array<string>
    ticketType: ITicketType
  }
}

interface IRemoveTicketTypeAction {
  type: typeof PricingActionType.REMOVE_TICKET_TYPE
  payload: {
    ticketTypeId: number
  }
}

interface IUpdateTicketTypeColor {
  type: typeof PricingActionType.UPDATE_TICKET_TYPE_COLOR
  payload: ITicketType
}

interface ISetPricing {
  type: typeof PricingActionType.SET_PRICING
  payload: IPricingState
}

type PricingAction =
  | IAssignTicketTypeAction
  | IRemoveTicketTypeAction
  | IUpdateTicketTypeColor
  | ISetPricing

export const pricingReducer = (
  state = initialState,
  action: PricingAction
): IPricingState => {
  switch (action.type) {
    case PricingActionType.ASSIGN_TICKET_TYPE: {
      const {objectsIds, ticketType} = action.payload

      const newAssignments = objectsIds.reduce(
        (assignments, objectId) => ({...assignments, [objectId]: ticketType}),
        {}
      )

      return {...state, ...newAssignments}
    }
    case PricingActionType.REMOVE_TICKET_TYPE: {
      const {ticketTypeId} = action.payload

      return _.pickBy(
        state,
        (ticketType: ITicketType) => ticketType.id !== ticketTypeId
      )
    }
    case PricingActionType.UPDATE_TICKET_TYPE_COLOR: {
      const {id: ticketTypeId} = action.payload

      return _.mapValues(state, (ticketType: ITicketType) => {
        return ticketType.id === ticketTypeId ? action.payload : ticketType
      })
    }
    case PricingActionType.SET_PRICING: {
      return action.payload
    }
    default:
      return state
  }
}
