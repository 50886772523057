import {Button, Paper, Tab, Tabs} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'

import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {BOTTOM_BAR_HEIGHT, ShowSection} from '../../../../constants'
import {SecondaryHeader} from '../../Header'

const useStyles = makeStyles<Theme, {isFooterVisible: boolean}>((theme) => ({
  paper: {
    border: 0,
    borderBottom: `solid ${theme.palette.divider} 1px`
  },
  pageTemplate: {
    gridTemplateRows: ({isFooterVisible}) =>
      isFooterVisible ? `auto 1fr ${BOTTOM_BAR_HEIGHT}px` : 'auto 1fr'
  }
}))

interface IEditLibraryPageDetailTemplateProps {
  children: React.ReactNode

  onClose(): void

  formId?: string
  showName: string
  currentTab: ShowSection
  isFooterVisible?: boolean
}

export const EditLibraryPageDetailTemplate: React.FC<IEditLibraryPageDetailTemplateProps> =
  ({
    children,
    onClose,
    formId,
    showName,
    currentTab,
    isFooterVisible = false
  }: IEditLibraryPageDetailTemplateProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {showId} = useParams<{showId: string}>()
    const history = useHistory()
    const handleTabChange = useCallback(
      (e, newTab) => {
        if (newTab !== currentTab) {
          history.replace(
            routeTo.admin.library.editWithEditSection(showId, newTab)
          )
        }
      },
      [currentTab, history, showId]
    )
    const classes = useStyles({isFooterVisible})
    return (
      <PageWithHeaderAndFooterTemplate
        className={classes.pageTemplate}
        header={
          <div>
            <SecondaryHeader
              title={showName}
              hasArrowBackIcon
              onLeftActionClick={onClose}
            />
            <Paper variant="outlined" square className={classes.paper}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={t('General')} value={ShowSection.Data} />
                <Tab
                  label={t('Translations')}
                  value={ShowSection.Translations}
                />
                <Tab label={t('Media')} value={ShowSection.Media} />
                {P([PermissionCode.UpsertLibraryShowCrew]) && (
                  <Tab label={t('Crew')} value={ShowSection.Crew} />
                )}
              </Tabs>
            </Paper>
          </div>
        }
        footer={
          isFooterVisible && (
            <Footer>
              <Button onClick={onClose} color="primary">
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                form={formId}
              >
                {t('Save')}
              </Button>
            </Footer>
          )
        }
      >
        {children}
      </PageWithHeaderAndFooterTemplate>
    )
  }
