import {useDateTimeFormatters} from '../../../../../../utils/formatting'

export const useEventPricingOptionsAnchors = (
  preprocessedData: Array<{startsAt: string; id: number}>
) => {
  const {formatDateTime} = useDateTimeFormatters()
  return preprocessedData.reduce(
    (res, item) => ({
      ...res,
      [item.startsAt]: {
        id: `${item.id}`,
        label: formatDateTime(new Date(item.startsAt))
      }
    }),
    {}
  )
}
