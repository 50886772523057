import {MenuItem, SelectProps, TextField, TextFieldProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {FormValidationError} from '../common/FormHelpers'
import {FormFieldHelper} from '../visual/common'
import {FormFieldName} from './types'
import {getValueInUncontrolledFormComponent, useHasError} from './utils'

export interface IUncontrolledFormSelectProps<
  FormValues extends FieldValues = FieldValues
> extends Omit<SelectProps, 'onChange' | 'margin'> {
  label?: string
  className?: string
  selectOptions: {[value: string]: React.ReactNode}
  errors: FieldErrors<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  register: FormContextValues<FormValues>['register']
  watch: FormContextValues<FormValues>['watch']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
  helperNote?: string
  hasNoneSelectOption?: boolean
  hideErrorMessage?: boolean
  margin?: TextFieldProps['margin']
}

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  }
}))

export const UncontrolledFormSelect = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  helperNote,
  label,
  className,
  errors,
  selectOptions,
  hasNoneSelectOption,
  register,
  setValue,
  watch,
  validationOptions,
  defaultValue,
  hideErrorMessage,
  margin,
  ...selectProps
}: IUncontrolledFormSelectProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({name, defaultValue, watch})
  const hasError = useHasError<FormValues>(errors, name)

  const {t} = useTranslation()

  const classes = useStyles()

  const handleChange = useCallback(
    (e) => {
      setValue(name, e.target.value)
    },
    [name, setValue]
  )

  return (
    <div className={className}>
      <select
        ref={validationOptions ? register(validationOptions) : register()}
        name={name}
        className={classes.hidden}
        defaultValue={defaultValue as string}
      >
        {hasNoneSelectOption && <option value="">{t('None')}</option>}
        {Object.entries(selectOptions).map(([optionValue, node]) => (
          <option key={optionValue} value={optionValue}>
            {node}
          </option>
        ))}
      </select>
      <TextField
        select
        error={hasError}
        label={label}
        fullWidth={selectProps.fullWidth}
        SelectProps={selectProps}
        onChange={handleChange}
        value={value}
        margin={margin}
      >
        {hasNoneSelectOption && <MenuItem value="">{t('None')}</MenuItem>}
        {Object.entries(selectOptions).map(([optionValue, node]) => (
          <MenuItem key={optionValue} value={optionValue}>
            {node}
          </MenuItem>
        ))}
      </TextField>
      {hasError && !hideErrorMessage ? (
        <FormValidationError<FormValues> errors={errors} fieldName={name} />
      ) : (
        <FormFieldHelper required={selectProps.required} note={helperNote} />
      )}
    </div>
  )
}
