import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartsSupportUserFilterInput,
  CartState,
  Currency,
  PermissionCode,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateCartState} from '../../../../hooks/cartState'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useTranslatePrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  CartStateCellRenderer,
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ChipWithMultiselect} from '../components/ChipWithMultiselect'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {ReloadButton} from '../components/ReloadButton'
import {WideCenteredLayout} from '../Layout'
import {useCartsSupportUser} from './graphql'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.cartsSupport.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const useTranslatePriceValueFormatter = () => {
  const translatePrice = useTranslatePrice(Currency.Eur)
  return (params: GridValueFormatterParams, currency?: Currency) =>
    currency && translatePrice(params.value, currency)
}

const useStyles = makeStyles<Theme>((theme) => ({
  stateCell: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'auto 1fr'
  },
  tableWrapper: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(3)
  }
}))

interface ICartsSupportListProps {
  searchFilter: CartsSupportUserFilterInput
}

export const CartsSupportList: React.FC<ICartsSupportListProps> = ({
  searchFilter
}: ICartsSupportListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()

  const {resetPaginationModel, paginationInput, getDataGridPaginationProps} =
    useDataGridPagination()

  const [selectedCartStates, setSelectedCartStates] = useState<CartState[]>([])
  const [sellingChannel, setSellingChannel] = useState<
    SellingChannel | undefined
  >(SellingChannel.ECommerce)
  const {data, loading, error, refetch} = useCartsSupportUser({
    filter: {
      ...searchFilter,
      sellingChannel,
      cartStates: selectedCartStates.length > 0 ? selectedCartStates : undefined
    },
    paginationInput
  })
  const translateSellingChannel = useTranslateSellingChannel()
  const translatePriceValueFormatter = useTranslatePriceValueFormatter()
  const dateTimeFormatter = useDateTimeFormatter()
  const translateCartState = useTranslateCartState()
  const classes = useStyles()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Created at'),
        field: 'createdAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Total'),
        field: 'price',
        type: 'number',
        valueFormatter: (params) =>
          translatePriceValueFormatter(
            params,
            data?.cartsSupportUser.items.find((item) => item.id === params.id)
              ?.client.currency
          ),
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: CartStateCellRenderer,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Customer'),
        field: 'lead.data.name',
        minWidth: 250,
        valueGetter: (params) => params.row.lead?.data?.name,
        sortable: false
      },
      {
        headerName: t('Email'),
        field: 'lead.data.email',
        minWidth: 250,
        valueGetter: (params) => params.row.lead?.data?.email,
        sortable: false
      },
      {
        headerName: t('Client'),
        field: 'client',
        minWidth: 250,
        valueGetter: (params) => params.row.client.name,
        sortable: false
      },
      {
        headerName: t('Cart'),
        field: 'id',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Last update'),
        field: 'updatedAt',
        minWidth: 250,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        width: 64
      }
    ],
    [
      data?.cartsSupportUser.items,
      dateTimeFormatter,
      t,
      translatePriceValueFormatter
    ]
  )
  const handleCartStateChange = useCallback(
    (states: CartState[]) => {
      setSelectedCartStates(states)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  if (error) {
    return <Error error={error} message={t('Error while loading carts')} />
  }
  return (
    <div className={classes.root}>
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ChipWithOptions<SellingChannel>
            key="selling-channel-chip"
            selectedItem={sellingChannel}
            setSelectedItem={setSellingChannel}
            options={[SellingChannel.ECommerce, SellingChannel.Retail].map(
              (sc) => ({
                label: translateSellingChannel(sc),
                option: sc
              })
            )}
            allText={t('All channels')}
            size="small"
          />,
          <ChipWithMultiselect<CartState>
            key="cart-state-chip"
            selectedItems={selectedCartStates}
            onApplyButtonClick={handleCartStateChange}
            options={[
              CartState.Draft,
              CartState.Pending,
              CartState.Sold,
              CartState.Reserved,
              CartState.Expired,
              CartState.Failed,
              CartState.Discarded
            ].map((state) => ({
              option: state,
              label: translateCartState(state)
            }))}
            allText={t('All carts')}
          />
        ]}
        rightActions={[
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout className={classes.tableWrapper}>
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.cartsSupportUser.items || []}
          localeText={{noRowsLabel: t('No carts to show')}}
          disableColumnFilter
          initialState={{
            pinnedColumns: {
              left: ['createdAt'],
              right: ['arrow']
            }
          }}
          columnVisibilityModel={{
            id: P([PermissionCode.ReadCartSupportUser])
          }}
          disableRowSelectionOnClick
          {...getDataGridPaginationProps(data?.cartsSupportUser.pagination)}
        />
      </WideCenteredLayout>
    </div>
  )
}
