import {Button} from '@mui/material'
import {omit} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {SingleSideNavigationList} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {SecondaryHeader} from '../Header'
import {CenteredLayout} from '../Layout'
import {useCreateRole} from './graphql'
import {IRoleForm, RoleField, RoleForm} from './RoleForm'
import {FormType, useRoleFormAnchors} from './useRoleFormAnchors'

const CREATE_ROLE_FORM_ID = 'createRole'

export const CreateRole: React.FC = () => {
  const {t} = useTranslation()
  const createRole = useCreateRole()
  const roleFormAnchors = useRoleFormAnchors(FormType.Create)
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const history = useHistory()
  const handleOnSubmit = useCallback(
    async (formData: IRoleForm) => {
      try {
        setShowBackdrop(true)
        await createRole(omit(formData, RoleField.Type), formData.type)
        addInfoNotification(t('Role created successfully'))
        history.push(routeTo.admin.roles.index())
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating role'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createRole,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t
    ]
  )
  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Create role')} />}
      footer={
        <Footer>
          <Button
            variant="contained"
            color="primary"
            form={CREATE_ROLE_FORM_ID}
            type="submit"
          >
            {t('Create')}
          </Button>
        </Footer>
      }
    >
      <CenteredLayout>
        <SingleSideNavigationList items={roleFormAnchors} />
        <RoleForm
          formId={CREATE_ROLE_FORM_ID}
          formType={FormType.Create}
          onSubmit={handleOnSubmit}
          defaultValues={{
            [RoleField.PermissionCodes]: []
          }}
        />
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
