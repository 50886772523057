import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {useUserInfo} from '../../../utils/auth'
import {Blank} from '../../visual/Blank'
import {PageNotFound} from './PageNotFound'

const BlankPage: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      title={t('Not expecting effective client')}
      IconComp={ReportProblemOutlinedIcon}
    />
  )
}

interface IChildrenOnNoEffectiveClientSelectedProps {
  children: ReactNode
  showBlank?: boolean
}

export const ChildrenOnNoEffectiveClientSelected: React.FC<IChildrenOnNoEffectiveClientSelectedProps> =
  ({children, showBlank}: IChildrenOnNoEffectiveClientSelectedProps) => {
    const {effectiveClient} = useUserInfo()
    return !effectiveClient ? (
      <>{children}</>
    ) : showBlank ? (
      <BlankPage />
    ) : (
      <PageNotFound />
    )
  }
