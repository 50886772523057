import {Box} from '@mui/material'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {
  CashDeskEventFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useRequireEffectiveClient} from '../../../../hooks/requireEffectiveClient'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {parseDateObjectFromDate} from '../cashDesk/utils'
import {GroupedEventsType, PinnedEventPick} from '../types'
import {ICashdeskEventItemProps} from './CashdeskEventItem'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 64,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'flex-start'
  },
  dateBox: {
    display: 'flex',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    alignItems: 'center'
  },
  stickyDateBox: {
    position: 'sticky',
    top: 0,
    flexShrink: 0
  },
  monthDay: {
    width: theme.spacing(5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0
  },
  dateNamesBox: {
    display: 'flex',
    height: theme.spacing(6),
    width: theme.spacing(5),
    paddingLeft: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    letterSpacing: 1.5,
    textTransform: 'uppercase'
  },
  eventsBox: {
    minWidth: 0,
    '&>div': {
      borderBottom: `solid ${theme.palette.divider} 1px`
    },
    '&>div:last-child': {
      borderBottom: 0
    }
  }
}))

interface IGroupedEventsItemProps {
  item: GroupedEventsType<CashDeskEventFragment | PinnedEventPick>
  EventItemComp: React.FC<ICashdeskEventItemProps>
  onEventDetailClick?: (eventId: number) => void
  onEventClick?: (eventId: number) => void
  isPhablet: boolean
}

export const GroupedEventsItem: React.FC<IGroupedEventsItemProps> = ({
  item: {date, events},
  EventItemComp,
  onEventDetailClick,
  onEventClick,
  isPhablet
}: IGroupedEventsItemProps) => {
  const classes = useStyles()
  const {
    formatWeekDay,
    formatMonth,
    formatMonthDay,
    formatDateShort,
    formatLongWeekDay
  } = useDateTimeFormatters()
  const dayDate = parseDateObjectFromDate(date)
  const clientLocaleCode = useClientLocaleCode()
  const {P} = useEnsurePermissions()
  const effectiveClient = useRequireEffectiveClient()
  return isPhablet ? (
    <Paper
      sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
      variant="outlined"
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          position: 'sticky',
          top: 0,
          flexShrink: 0,
          borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
          py: 1.5,
          px: 2
        }}
      >
        <Typography
          variant="overline"
          color="textSecondary"
        >{`${formatDateShort(dayDate)} • ${formatLongWeekDay(
          dayDate
        )}`}</Typography>
      </Box>
      <Box
        sx={{
          '&>div': {
            borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
          },
          '&>div:last-child': {
            borderBottom: 0
          }
        }}
      >
        {events.map((event) => (
          <EventItemComp
            currency={effectiveClient.currency}
            onEventDetailClick={
              P([PermissionCode.ReadEvent]) ? onEventDetailClick : undefined
            }
            key={event.id}
            event={event}
            onEventClick={onEventClick}
            clientLocaleCode={clientLocaleCode}
          />
        ))}
      </Box>
    </Paper>
  ) : (
    <Paper className={classes.root} variant="outlined">
      <div className={classes.stickyDateBox}>
        <div className={classes.dateBox}>
          <Typography variant="h6" className={classes.monthDay}>
            {formatMonthDay(dayDate)}
          </Typography>
          <div className={classes.dateNamesBox}>
            <Typography variant="caption">{formatMonth(dayDate)}</Typography>
            <Typography variant="caption" color="textSecondary">
              {formatWeekDay(dayDate)}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.eventsBox}>
        {events.map((event) => (
          <EventItemComp
            currency={effectiveClient.currency}
            onEventDetailClick={
              P([PermissionCode.ReadEvent]) ? onEventDetailClick : undefined
            }
            key={event.id}
            event={event}
            onEventClick={onEventClick}
            clientLocaleCode={clientLocaleCode}
          />
        ))}
      </div>
    </Paper>
  )
}
