import PersonIcon from '@mui/icons-material/Person'
import {Box, SxProps} from '@mui/material'
import Typography from '@mui/material/Typography'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Tooltip} from './index'

export const capacityIndicatorSxProps = {
  freeSpaces: {
    color: 'success.main'
  },
  reservedSpaces: {
    color: 'info.main'
  },
  soldSpaces: {
    color: 'error.main'
  }
}

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '100%'
  },
  adjustedLineHeight: {
    lineHeight: '100%'
  }
}))

interface ICapacityIndicatorProps {
  colorSx?: SxProps
  tooltipTitle: string
  sx?: SxProps
  /**
   * @deprecated use sx prop
   */
  className?: string
  countLabel: string
  customIcon?: React.ReactNode
  iconSize?: number
}

export const CapacityIndicator: React.FC<ICapacityIndicatorProps> = ({
  colorSx,
  className,
  sx,
  countLabel,
  tooltipTitle,
  customIcon,
  iconSize
}: ICapacityIndicatorProps) => {
  const classes = useStyles()
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Box className={cn(classes.box, className)} sx={sx}>
        <Typography variant="body2" className={classes.adjustedLineHeight}>
          {customIcon || (
            <PersonIcon
              style={{fontSize: iconSize || 'inherit'}}
              sx={colorSx}
            />
          )}
          &nbsp;
        </Typography>
        <Typography
          variant="overline"
          className={classes.adjustedLineHeight}
          sx={colorSx}
        >
          {countLabel}
        </Typography>
      </Box>
    </Tooltip>
  )
}
