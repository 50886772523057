import {Grid, Switch, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'

import {Theme} from '../../../theme'

interface IStylesProps {
  checked: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  outline: ({checked}: IStylesProps) => ({
    border: `1px solid ${checked ? theme.palette.primary.main : '#E0E0E0'}`,
    borderRadius: 34,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }),
  switch: {
    marginLeft: -theme.spacing(1)
  }
}))

interface IFormSwitchProps {
  checked?: boolean
  control: any // TODO: Type better
  disabled?: boolean
  label: string
  name: string
  outlined?: boolean
}

/**
 * @deprecated - please use FormSwitchInput instead of this one
 */
export const FormSwitch: React.FC<IFormSwitchProps> = ({
  checked = false,
  control, // TODO: Get rid of this to unify with API of other custom inputs.
  disabled = false,
  label,
  name,
  outlined = false
}: IFormSwitchProps) => {
  const [value, setValue] = useState<boolean>(checked)
  const classes = useStyles({checked: value})

  return (
    <Grid
      container
      alignItems="center"
      className={outlined ? classes.outline : undefined}
    >
      <Controller
        as={<Switch color="primary" />}
        className={classes.switch}
        valueName="checked"
        defaultValue={checked}
        onChange={([, newValue]) => {
          setValue(newValue)
          return newValue
        }}
        {...{control, disabled, name}}
      />
      <Typography variant="body2">{label}</Typography>
    </Grid>
  )
}
