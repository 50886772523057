import {useQuery} from '@apollo/react-hooks'
import StorefrontIcon from '@mui/icons-material/StorefrontOutlined'
import {Box} from '@mui/material'
import {isNumber} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetAvailableProductGroupProductsQuery,
  GetAvailableProductGroupProductsQueryVariables,
  ProductMode,
  ProductPropertiesFragment,
  ProductState
} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {RenderOnData} from '../../../../common'
import {ProductTile} from '../../../../common/ProductTile'
import {Blank} from '../../../../visual/Blank'
import {isProductItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {GET_AVAILABLE_PRODUCT_GROUP_PRODUCTS} from './graphql'

interface IGridViewProps {
  onProductItemQuantityIncrement: (productId: number, increment: number) => void
  getProductUnitPrice: (
    product: ProductPropertiesFragment
  ) => number | undefined
  divisionId: number
  productGroupId: number
  warehouseId: number | null
}

export const GridView: React.FC<IGridViewProps> = ({
  onProductItemQuantityIncrement,
  getProductUnitPrice,
  divisionId,
  productGroupId,
  warehouseId
}: IGridViewProps) => {
  const {t} = useTranslation()
  const {data, loading, error} = useQuery<
    GetAvailableProductGroupProductsQuery,
    GetAvailableProductGroupProductsQueryVariables
  >(GET_AVAILABLE_PRODUCT_GROUP_PRODUCTS, {
    variables: {
      id: productGroupId,
      productsFilter: {
        divisionId,
        isAvailableOnRetailChannel: true,
        state: ProductState.Active
      },
      warehouseIds: warehouseId ? [warehouseId] : undefined
    },
    fetchPolicy: 'network-only'
  })
  const {currentCart} = useCurrentCart()
  const currentCartProductItems = (currentCart?.items || []).filter(
    isProductItemPropertiesFragment
  )
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const getIncrementButtonClickHandler = useCallback(
    (productId: number) => () => {
      onProductItemQuantityIncrement(productId, 1)
    },
    [onProductItemQuantityIncrement]
  )
  return (
    <RenderOnData<GetAvailableProductGroupProductsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading product group')}
      dataCondition={(data) => Boolean(data.productGroup)}
    >
      {({productGroup}) =>
        productGroup.products?.length ? (
          <Box
            sx={{
              px: 3,
              pt: 3,
              pb: 11,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexWrap: 'wrap'
            }}
          >
            {productGroup.products.map((product) => {
              const unitPrice = getProductUnitPrice(product)
              const productItem = currentCartProductItems.find(
                (i) => i.productId === product.id
              )
              const isProductOutOfStock =
                product.mode === ProductMode.WarehouseProduct &&
                product.warehouseProducts.length > 0 &&
                !product.warehouseProducts[0].isNegativeStockEnabled &&
                (product.warehouseProducts[0].stock <= 0 ||
                  (productItem &&
                    productItem.quantity >= product.warehouseProducts[0].stock))
              const isProductDisabled =
                product.mode === ProductMode.WarehouseProduct &&
                (!warehouseId ||
                  product.warehouseProducts.length === 0 ||
                  isProductOutOfStock)
              return (
                <ProductTile
                  key={product.id}
                  disabled={isProductDisabled}
                  imageUrl={product.coverImageURL || undefined}
                  name={product.name}
                  formattedPrice={
                    isProductOutOfStock
                      ? t('Out of stock')
                      : isNumber(unitPrice)
                      ? translateEffectiveClientPrice(unitPrice)
                      : t('Undefined price')
                  }
                  badgeContent={productItem?.quantity ?? 0}
                  onClick={getIncrementButtonClickHandler(product.id)}
                />
              )
            })}
          </Box>
        ) : (
          <Blank title={t('No products found')} IconComp={StorefrontIcon} />
        )
      }
    </RenderOnData>
  )
}
