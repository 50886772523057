import ListArrow from '@mui/icons-material/KeyboardArrowRight'
import {
  Grid,
  lighten,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {SyntheticEvent} from 'react'
import {Theme} from '../../theme'
import {borderStyle} from '../constants'

const useStyles = makeStyles<Theme, {disableBorder: boolean}>((theme) => ({
  listWrapper: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(1),
    paddingLeft: 0,
    display: 'flex',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    borderBottom: ({disableBorder}) => (!disableBorder ? borderStyle : 'none'),
    marginLeft: theme.spacing(1.5)
  },
  innerWrapper: {
    flex: 1,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  colorIndicator: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 10,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    display: 'flex',
    alignSelf: 'stretch'
  },
  arrow: {
    marginLeft: theme.spacing(2)
  },
  extra: {
    marginTop: 19 // pixel perfect adhoc match
  },
  height100: {
    height: '100%'
  }
}))

interface IColoredListItemProps {
  title: string
  description?: React.ReactNode | string | null
  extra?: string | null | React.ReactNode
  input?: React.ReactNode | null
  color?: string
  highlighted?: boolean
  showArrow?: boolean
  disableBorder?: boolean
  onClick?: (e: SyntheticEvent) => any
}

export const ColoredListItem: React.FC<IColoredListItemProps> = ({
  title,
  description,
  extra,
  input,
  color,
  highlighted,
  showArrow,
  disableBorder = false,
  onClick
}: IColoredListItemProps) => {
  const classes = useStyles({disableBorder})

  return (
    <ListItem
      // TS complains, about "boolean is not assignable to type true", which is quite ridiculous
      // @ts-ignore
      button={!!onClick}
      className={classes.listWrapper}
      onClick={onClick}
      style={highlighted && color ? {background: lighten(color, 0.9)} : {}}
    >
      {color && (
        <div className={classes.colorIndicator} style={{background: color}} />
      )}
      <div className={classes.wrapper}>
        {input}
        <div className={classes.innerWrapper}>
          <ListItemText primary={title} secondary={description} />
          <ListItemSecondaryAction className={classes.height100}>
            <Grid container className={classes.height100}>
              {extra && (
                <Grid item>
                  <Grid container className={classes.height100}>
                    <div className={classes.extra}>{extra}</div>
                  </Grid>
                </Grid>
              )}
              {showArrow && (
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    className={classes.height100}
                  >
                    <ListArrow className={classes.arrow} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ListItemSecondaryAction>
        </div>
      </div>
    </ListItem>
  )
}
