import i18n from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import {initReactI18next} from 'react-i18next'
import {Currency, LocaleCode} from '../__generated__/schema'

import {DEFAULT_LOCALE, getInitialLocale} from '../components/context/locale'
import cs from '../i18n/cs'

import en from '../i18n/en'
import hu from '../i18n/hu'
import sk from '../i18n/sk'

const resources: {[key in LocaleCode]: any} = {
  en,
  sk,
  cs,
  hu
}

interface IFormatPrice {
  currency: Currency
  value: number
}

const formatPrice = (
  {currency, value}: IFormatPrice,
  lng: string | undefined
) =>
  new Intl.NumberFormat(lng, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency
  }).format(value)

const formatCurrencySign = (currency: Currency, lng: string | undefined) =>
  new Intl.NumberFormat(lng, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency
  })
    .formatToParts(0)
    .filter((part) => part.type === 'currency')[0].value

export const initI18next = () =>
  i18n
    .use(intervalPlural)
    .use(initReactI18next)
    .init({
      resources,
      compatibilityJSON: 'v3',
      lng: getInitialLocale(),
      fallbackLng: DEFAULT_LOCALE,
      defaultNS: 'common',
      fallbackNS: 'common',
      nsSeparator: '|',
      keySeparator: false,
      interpolation: {
        format: (value, format, lng) => {
          if (format === 'formatPrice') {
            return formatPrice(value, lng)
          }
          if (format === 'formatCurrencySign') {
            return formatCurrencySign(value, lng)
          }
          return value
        }
      }
    })
