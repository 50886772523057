import {useTranslation} from 'react-i18next'
import {ClaimState} from '../__generated__/schema'

export const useTranslateClaimState = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ClaimState, string> = {
    [ClaimState.Draft]: t<string>('ClaimState->Draft'),
    [ClaimState.Approved]: t<string>('ClaimState->Approved'),
    [ClaimState.Deleted]: t<string>('ClaimState->Deleted'),
    [ClaimState.Pending]: t<string>('ClaimState->Pending'),
    [ClaimState.Refunded]: t<string>('ClaimState->Refunded'),
    [ClaimState.Failed]: t<string>('ClaimState->Failed')
  }
  return (code: ClaimState) => translatedCodes[code]
}

export const useTranslateClaimStateDescription = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ClaimState, string> = {
    [ClaimState.Draft]: t<string>('Claim has been created.'),
    [ClaimState.Approved]: t<string>(
      'Claim is approved and is not allowed to update items or reason.'
    ),
    [ClaimState.Deleted]: t<string>('Claim has been deleted.'),
    [ClaimState.Pending]: t<string>(
      'Waiting for the result of refunding process.'
    ),
    [ClaimState.Refunded]: t<string>('Claim has been successfully refunded.'),
    [ClaimState.Failed]: t<string>(
      'Refund has failed, try to refund the claim again.'
    )
  }
  return (code: ClaimState) => translatedCodes[code]
}
