import GetAppIcon from '@mui/icons-material/GetApp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {IconButton} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

interface IMoreMenuProps {
  onDownloadButtonClick: () => void
}

export const MoreMenu: React.FC<IMoreMenuProps> = ({
  onDownloadButtonClick
}: IMoreMenuProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handlePaymentsListCsvButtonClick = useCallback(() => {
    onDownloadButtonClick()
    closeMenu()
  }, [closeMenu, onDownloadButtonClick])
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          label={t('Products statistics CSV')}
          icon={<GetAppIcon />}
          onClick={handlePaymentsListCsvButtonClick}
        />
      </Menu>
    </>
  )
}
