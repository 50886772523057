import {useGetClientLocales} from '../../../../hooks/getLocales'
import {useTranslateLocaleCode} from '../../../../hooks/translateLocales'

export const useShowTranslationsFormAnchors = () => {
  const clientLocaleCodes = useGetClientLocales()
  const translateLocaleCode = useTranslateLocaleCode()
  return clientLocaleCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: {
        id: code,
        label: translateLocaleCode(code)
      }
    }),
    {}
  )
}
