import {Drawer, drawerClasses, useTheme} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useCreateProductGroup} from './graphql'
import {ProductGroupForm} from './ProductGroupForm'
import {IProductGroupForm, ProductGroupFormField} from './types'
import {transformFormDataToCreateProductGroupInput} from './utils'

const CREATE_PRODUCT_GROUP_FORM_ID = 'createProductGroupForm'

interface ICreateProductGroupDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateProductGroupDrawer: React.FC<ICreateProductGroupDrawerProps> =
  ({isOpen, onClose}: ICreateProductGroupDrawerProps) => {
    const {t} = useTranslation()
    const theme = useTheme<Theme>()
    const history = useHistory()
    const createProductGroup = useCreateProductGroup()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (formData: IProductGroupForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await createProductGroup(
            transformFormDataToCreateProductGroupInput(formData)
          )
          addInfoNotification(t('Product group created'))
          if (data) {
            history.push(
              routeTo.admin.productGroups.detail(data.createProductGroup.id)
            )
          }
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating product group'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createProductGroup,
        defaultErrorHandler,
        history,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create product group')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_PRODUCT_GROUP_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <ProductGroupForm
            formId={CREATE_PRODUCT_GROUP_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [ProductGroupFormField.Color]: theme.palette.primary.main
            }}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
