import {useTranslation} from 'react-i18next'

export const useCashCounterAnchors = () => {
  const {t} = useTranslation()
  return {
    currency: {
      id: 'currency',
      label: t('Currency')
    },
    banknotes: {
      id: 'banknotes',
      label: t('Banknotes')
    },
    coins: {
      id: 'coins',
      label: t('Coins')
    },
    vouchers: {
      id: 'vouchers',
      label: t('Vouchers')
    }
  }
}
