import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {useMediaQuery} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery,
  PassCodeCheckInputType,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useCheckPassCodeParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {HeaderActionType} from '../../../../common/header/types'
import {LoginIcon} from '../../../../common/icons/LoginIcon'
import {LogoutIcon} from '../../../../common/icons/LogoutIcon'
import {QrcodeIcon} from '../../../../common/icons/QrcodeIcon'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../../constants'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {TicketInfoDrawer} from '../../components/ticketInfoDrawer/TicketInfoDrawer'
import {SecondaryHeader} from '../../Header'
import {FullScreenCenteredLayout} from '../../Layout'
import {PageNotFound} from '../../PageNotFound'
import {useAdvancedSettings} from '../AdvancedSettingsContext'
import {useCheckMode} from '../CheckModeContext'
import {EventDetailDrawer} from '../EventDetailDrawer'
import {
  AnCheckTicketsDrawerClose,
  AnCheckTicketsDrawerOpen,
  CheckMode,
  CheckTicketsPageType
} from '../types'
import {
  getCheckTicketsQueryBasedOnPageType,
  isCheckTicketsPageType,
  useTranslateCheckMode
} from '../utils'
import {BulkCheckDrawer} from './bulkCheckDrawer'
import {ChildrenOnValidData} from './ChildrenOnValidData'
import {DesktopContent} from './DesktopContent'
import {Error} from './Error'
import {PhabletContent} from './PhabletContent'

export const CheckPassCodePage: React.FC = () => {
  const {entityId, checkType} = useCheckPassCodeParams()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const dataQuery = getCheckTicketsQueryBasedOnPageType(
    checkType as CheckTicketsPageType
  )
  const {data, loading, error, refetch} = dataQuery(entityId)
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const handleReload = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await refetch()
    } finally {
      setShowBackdrop(false)
    }
  }, [refetch, setShowBackdrop])
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const {checkMode, setCheckMode} = useCheckMode()
  const translateCheckMode = useTranslateCheckMode()
  const getCheckModeActionHandler = useCallback(
    (mode: CheckMode, callback?: () => void) => () => {
      setCheckMode(mode)
      if (callback) {
        callback()
      }
    },
    [setCheckMode]
  )
  const history = useHistory()
  const handleAdvancedSettingsClick = useCallback(() => {
    history.push(routeTo.admin.checkTickets.advancedSettings())
  }, [history])
  const handleExited = useCallback(() => {
    history.replace(
      routeTo.admin.checkTickets.checkTicketCode(checkType, entityId)
    )
    window.dispatchEvent(new Event(AnCheckTicketsDrawerClose))
  }, [checkType, entityId, history])
  const handleEntered = useCallback(() => {
    window.dispatchEvent(new Event(AnCheckTicketsDrawerOpen))
  }, [])
  const {
    settings: {defaultInputType}
  } = useAdvancedSettings()
  const [inputType, setInputType] =
    useState<PassCodeCheckInputType>(defaultInputType)
  const switchInputType = useCallback(() => {
    setInputType((currentInputType) =>
      currentInputType === PassCodeCheckInputType.Scanned
        ? PassCodeCheckInputType.Typed
        : PassCodeCheckInputType.Scanned
    )
  }, [])
  const handleBackIconClick = useCallback(() => {
    switch (checkType as CheckTicketsPageType) {
      case CheckTicketsPageType.Events:
        history.push(routeTo.admin.checkTickets.events())
        break
      case CheckTicketsPageType.Tours:
        history.push(routeTo.admin.checkTickets.tours())
        break
      case CheckTicketsPageType.TourTimeSlots:
        history.push(routeTo.admin.checkTickets.tourTimeSlots())
        break
      case CheckTicketsPageType.Venues:
      default:
        history.push(routeTo.admin.checkTickets.index())
        break
    }
  }, [checkType, history])
  if (!isCheckTicketsPageType(checkType)) {
    return <PageNotFound />
  }
  return (
    <>
      <PageWithHeaderTemplate
        header={
          <SecondaryHeader
            hasArrowBackIcon
            onLeftActionClick={handleBackIconClick}
            title={translateCheckMode(checkMode)}
            isDatetimeEnabled
            rightActions={
              isPhablet
                ? [
                    {
                      Icon: LoginIcon,
                      onClick: getCheckModeActionHandler(CheckMode.CheckIn),
                      label: t('Check in mode'),
                      type: HeaderActionType.IconButton,
                      isSelected: checkMode === CheckMode.CheckIn
                    },
                    {
                      Icon: LogoutIcon,
                      onClick: getCheckModeActionHandler(CheckMode.CheckOut),
                      label: t('Check out mode'),
                      type: HeaderActionType.IconButton,
                      isSelected: checkMode === CheckMode.CheckOut
                    },
                    {
                      Icon: SwapHorizIcon,
                      onClick: getCheckModeActionHandler(
                        CheckMode.Bidirectional
                      ),
                      label: t('Bidirectional mode'),
                      type: HeaderActionType.IconButton,
                      isSelected: checkMode === CheckMode.Bidirectional
                    },
                    {
                      Icon: VisibilityIcon,
                      onClick: getCheckModeActionHandler(CheckMode.Info),
                      label: t('Info mode'),
                      type: HeaderActionType.IconButton,
                      isSelected: checkMode === CheckMode.Info
                    },
                    {
                      Icon: SettingsIcon,
                      onClick: handleAdvancedSettingsClick,
                      label: t('Advanced settings'),
                      type: HeaderActionType.IconButton
                    }
                  ]
                : [
                    {
                      Icon:
                        inputType === PassCodeCheckInputType.Typed
                          ? QrcodeIcon
                          : SearchIcon,
                      onClick: switchInputType,
                      label: t('Switch code input'),
                      type: HeaderActionType.IconButton
                    },
                    {
                      Icon: SettingsIcon,
                      onClick: openMenu,
                      label: t('Settings'),
                      type: HeaderActionType.IconButton
                    }
                  ]
            }
          />
        }
      >
        <FullScreenCenteredLayout>
          <ChildrenOnEffectiveClientSelected>
            <RenderOnData<
              | GetCheckEventQuery
              | GetCheckTourTimeSlotQuery
              | GetCheckTourQuery
              | GetCheckVenueQuery
            >
              data={data}
              error={error}
              loading={loading}
              errorChildren={(error) => <Error error={error} />}
            >
              {(data) => (
                <ChildrenOnValidData data={data}>
                  {isPhablet ? (
                    <PhabletContent data={data} inputType={inputType} />
                  ) : (
                    <DesktopContent
                      data={data}
                      onReload={handleReload}
                      inputType={inputType}
                    />
                  )}
                </ChildrenOnValidData>
              )}
            </RenderOnData>
          </ChildrenOnEffectiveClientSelected>
        </FullScreenCenteredLayout>
        <Menu
          onClose={closeMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem
            onClick={getCheckModeActionHandler(CheckMode.CheckIn, closeMenu)}
            label={t('Check in mode')}
            icon={<LoginIcon />}
            isSelected={checkMode === CheckMode.CheckIn}
          />
          <MenuItem
            onClick={getCheckModeActionHandler(CheckMode.CheckOut, closeMenu)}
            label={t('Check out mode')}
            icon={<LogoutIcon />}
            isSelected={checkMode === CheckMode.CheckOut}
          />
          <MenuItem
            onClick={getCheckModeActionHandler(
              CheckMode.Bidirectional,
              closeMenu
            )}
            label={t('Bidirectional mode')}
            icon={<SwapHorizIcon />}
            isSelected={checkMode === CheckMode.Bidirectional}
          />
          <MenuItem
            onClick={getCheckModeActionHandler(CheckMode.Info, closeMenu)}
            label={t('Info mode')}
            icon={<VisibilityIcon />}
            isSelected={checkMode === CheckMode.Info}
          />
          <MenuItem
            onClick={handleAdvancedSettingsClick}
            label={t('Advanced settings')}
            icon={<SettingsIcon />}
          />
        </Menu>
      </PageWithHeaderTemplate>
      <Route
        path={routeTo.admin.checkTickets.eventDetailFromCheckPage(
          ':checkType',
          ':eventId'
        )}
        exact
      >
        <EventDetailDrawer onExited={handleExited} onEntered={handleEntered} />
      </Route>
      {P([PermissionCode.ReadItem]) && (
        <Route
          path={routeTo.admin.checkTickets.ticketInfo(
            ':checkType',
            ':entityId',
            ':itemId'
          )}
          exact
        >
          <TicketInfoDrawer onExited={handleExited} onEntered={handleEntered} />
        </Route>
      )}
      {P([PermissionCode.ReadSaleByItemPassCode]) && (
        <Route
          path={routeTo.admin.checkTickets.bulkCheck(
            ':checkType',
            ':entityId',
            ':passCode'
          )}
          exact
        >
          <BulkCheckDrawer
            onExited={handleExited}
            onEntered={handleEntered}
            inputType={inputType}
            refetch={handleReload}
          />
        </Route>
      )}
    </>
  )
}
