import {ShapeVariant} from '@attendio/shared-components'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {AuditoriumIcon, DrawTool} from '../types'
import {
  BarIcon,
  CloakroomIcon,
  CoffeeIcon,
  DrinksIcon,
  EllipseIcon,
  EmergencyExitIcon,
  EntranceIcon,
  ExitIcon,
  FoodCourtIcon,
  LineIcon,
  RectangleIcon,
  ScreenIcon,
  StageIcon,
  TextIcon,
  ToiletsIcon
} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'
import {PanelSplitButton} from './PanelSplitButton'

const ShapeSplitButton: React.FC = () => {
  const {setDrawMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)

  const onRectangleClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.SHAPE,
      variant: ShapeVariant.Rectangle
    })
  }, [setDrawMode])

  const onEllipseClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.SHAPE,
      variant: ShapeVariant.Ellipse
    })
  }, [setDrawMode])

  const onLineClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.SHAPE,
      variant: ShapeVariant.Line
    })
  }, [setDrawMode])

  const options = useMemo(
    () => [
      {
        icon: <RectangleIcon />,
        onClick: onRectangleClick,
        label: t('Rectangle'),
        cypressId: 'rectangle-icon'
      },
      {
        icon: <EllipseIcon />,
        onClick: onEllipseClick,
        label: t('Ellipse'),
        cypressId: 'ellipse-icon'
      },
      {
        icon: <LineIcon />,
        onClick: onLineClick,
        label: t('Line'),
        cypressId: 'line-icon'
      }
    ],
    [onEllipseClick, onLineClick, onRectangleClick, t]
  )

  return (
    <PanelSplitButton
      options={options}
      selected={
        selectedMode === EditorMode.DRAW &&
        modeConfigs[EditorMode.DRAW].type === DrawTool.SHAPE
      }
      toggleButtonCypressId="shapes-toggle-arrow"
    />
  )
}

const IconSplitButton: React.FC = () => {
  const {setDrawMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)

  const getClickHandler = useCallback(
    (icon: AuditoriumIcon) => () => {
      setDrawMode({type: DrawTool.ICON, icon})
    },
    [setDrawMode]
  )

  const options = useMemo(
    () => [
      {
        icon: <ToiletsIcon />,
        onClick: getClickHandler(AuditoriumIcon.Toilets),
        label: t('Toilets'),
        cypressId: 'toilets-icon'
      },
      {
        icon: <StageIcon />,
        onClick: getClickHandler(AuditoriumIcon.Stage),
        label: t('Stage'),
        cypressId: 'stage-icon'
      },
      {
        icon: <ScreenIcon />,
        onClick: getClickHandler(AuditoriumIcon.Screen),
        label: t('Screen'),
        cypressId: 'screen-icon'
      },
      {
        icon: <EntranceIcon />,
        onClick: getClickHandler(AuditoriumIcon.Entrance),
        label: t('Entrance'),
        cypressId: 'entrance-icon'
      },
      {
        icon: <ExitIcon />,
        onClick: getClickHandler(AuditoriumIcon.Exit),
        label: t('Exit'),
        cypressId: 'exit-icon'
      },
      {
        icon: <EmergencyExitIcon />,
        onClick: getClickHandler(AuditoriumIcon.EmergencyExit),
        label: t('Emergency exit'),
        cypressId: 'emergency-exit-icon'
      },
      {
        icon: <CloakroomIcon />,
        onClick: getClickHandler(AuditoriumIcon.Cloakroom),
        label: t('Cloakroom'),
        cypressId: 'cloakroom-icon'
      },
      {
        icon: <DrinksIcon />,
        onClick: getClickHandler(AuditoriumIcon.Drinks),
        label: t('Drinks'),
        cypressId: 'drinks-icon'
      },
      {
        icon: <BarIcon />,
        onClick: getClickHandler(AuditoriumIcon.Bar),
        label: t('Bar'),
        cypressId: 'bar-icon'
      },
      {
        icon: <CoffeeIcon />,
        onClick: getClickHandler(AuditoriumIcon.Coffee),
        label: t('Coffee'),
        cypressId: 'coffee-icon'
      },
      {
        icon: <FoodCourtIcon />,
        onClick: getClickHandler(AuditoriumIcon.FoodCourt),
        label: t('Food court'),
        cypressId: 'food-court-icon'
      }
    ],
    [getClickHandler, t]
  )

  return (
    <PanelSplitButton
      options={options}
      selected={
        selectedMode === EditorMode.DRAW &&
        modeConfigs[EditorMode.DRAW].type === DrawTool.ICON
      }
      toggleButtonCypressId="icons-toggle-arrow"
    />
  )
}

export const DrawPanel: React.FC = () => {
  const {setDrawMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)

  const onTextClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.TEXT
    })
  }, [setDrawMode])

  return (
    <Panel>
      <PanelButton
        onClick={onTextClick}
        tooltip={t('Text')}
        selected={
          selectedMode === EditorMode.DRAW &&
          modeConfigs[EditorMode.DRAW].type === DrawTool.TEXT
        }
        cypressId="text-button"
      >
        <TextIcon />
      </PanelButton>
      <ShapeSplitButton />
      <IconSplitButton />
    </Panel>
  )
}
