import {Box} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  noUnderline: {
    textDecoration: 'none'
  },
  link: {
    color: 'grey',
    fontSize: 20,
    fontWeight: 500,
    '&:hover': {
      color: 'inherit'
    }
  },
  divider: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'inline',
    '&:hover': {
      color: 'grey'
    }
  }
}))

interface BLinkProps {
  to: string
  component?: React.ComponentClass | React.FunctionComponent
  children?: React.ReactNode
  hideDivider?: boolean
}

export const BLink: React.FC<BLinkProps> = ({
  hideDivider = false,
  ...restProps
}: BLinkProps) => {
  const classes = useStyles()
  return (
    <>
      {!hideDivider && (
        <Box className={cn(classes.link, classes.divider)}>/</Box>
      )}
      <Link className={cn(classes.link, classes.noUnderline)} {...restProps} />
    </>
  )
}
