import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import {Chip, ClickAwayListener, Grow, Paper, SxProps} from '@mui/material'
import Popper from '@mui/material/Popper'
import dayjs from 'dayjs'
import React, {useCallback, useState} from 'react'
import {Theme} from '../../../../theme'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {Tooltip} from '../../../common'
import {DatePicker} from '../../../common/datePicker/DatePicker'
import {IDataPickerData, TabGroup} from '../../../common/datePicker/types'
import {useTransformDatePickerValueToDate} from '../../../common/datePicker/utils'

const useGetChipLabel = (label: string) => {
  const transformDatePickerValueToDate = useTransformDatePickerValueToDate()
  const {formatDateNumeric} = useDateTimeFormatters()
  return (date?: IDataPickerData) => {
    const transformedDateRange = transformDatePickerValueToDate(date?.value)
    if (date?.group === TabGroup.Custom) {
      return `${label} ${[
        date?.dateRange?.dateFrom &&
          formatDateNumeric(date.dateRange.dateFrom.toDate()),
        date?.dateRange?.dateTo &&
          formatDateNumeric(date.dateRange.dateTo.toDate())
      ]
        .filter(Boolean)
        .join(' - ')}`
    } else {
      return `${label} ${[
        transformedDateRange?.dateFrom &&
          formatDateNumeric(transformedDateRange.dateFrom.toDate()),
        transformedDateRange?.dateTo &&
          formatDateNumeric(transformedDateRange.dateTo.toDate())
      ]
        .filter(Boolean)
        .join(' - ')}`
    }
  }
}

interface IDatePickerChipProps {
  selectedDate?: IDataPickerData
  setSelectedDate: (date?: IDataPickerData) => void
  popperSx?: SxProps<Theme>
  tooltip?: string
  icon?: React.ReactElement
  chipLabel: string
}

export const DatePickerChip: React.FC<IDatePickerChipProps> = ({
  selectedDate,
  setSelectedDate,
  popperSx,
  tooltip,
  icon,
  chipLabel
}: IDatePickerChipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const getChipLabel = useGetChipLabel(chipLabel)
  const handleChipClick = useCallback(
    (e) => {
      e.stopPropagation()
      setAnchorEl(anchorEl ? null : e.currentTarget)
    },
    [anchorEl]
  )
  const handleClickAway = useCallback(() => setAnchorEl(null), [])
  const handleApplyButtonClick = useCallback(
    (date: IDataPickerData) => {
      setSelectedDate(date)
      setAnchorEl(null)
    },
    [setSelectedDate]
  )
  const handleDeleteChipClick = useCallback(
    () => setSelectedDate(undefined),
    [setSelectedDate]
  )
  const isDateSelected =
    selectedDate && Object.values(selectedDate).some((v) => v !== undefined)
  return (
    <>
      <Tooltip title={tooltip}>
        <Chip
          size="small"
          variant="outlined"
          icon={icon}
          deleteIcon={isDateSelected ? <CancelIcon /> : <ArrowDropDownIcon />}
          onDelete={isDateSelected ? handleDeleteChipClick : handleChipClick}
          onClick={handleChipClick}
          label={isDateSelected ? getChipLabel(selectedDate) : undefined}
        />
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={popperSx}
      >
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left top'
              }}
            >
              <Paper elevation={8}>
                <DatePicker
                  onApplyButtonClick={handleApplyButtonClick}
                  onCloseButtonClick={handleClickAway}
                  selectedValues={selectedDate}
                  groups={[TabGroup.Now, TabGroup.Past, TabGroup.Future]}
                  minDateFrom={
                    selectedDate?.dateRange?.dateTo
                      ? dayjs(selectedDate?.dateRange?.dateTo)
                          .subtract(1, 'year')
                          .startOf('d')
                      : dayjs().subtract(1, 'year').startOf('d')
                  }
                  maxDateFrom={
                    selectedDate?.dateRange?.dateTo || dayjs().endOf('d')
                  }
                  maxDateTo={dayjs().endOf('d')}
                />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
