import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  TourTimeSlotsStatisticsQuery,
  TourTimeSlotsStatisticsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {
  TOUR_ITEMS_STATE_COUNTS_FIELDS,
  TRANSLATED_LOCALES_FRAGMENT,
  USER_FIELDS
} from '../graphql'

const TOUR_TIME_SLOTS_STATISTICS = gql`
  ${PAGINATION_FRAGMENT}
  ${USER_FIELDS}
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${TOUR_ITEMS_STATE_COUNTS_FIELDS}
  query TourTimeSlotsStatistics(
    $paginationInput: PaginationInput!
    $filter: TourTimeSlotsStatisticsFilterInput
  ) {
    tourTimeSlotsStatistics(
      paginationInput: $paginationInput
      filter: $filter
    ) {
      items {
        startsAt
        names {
          ...TranslatedLocales
        }
        tour {
          id
          name
        }
        retailAttendeesLimit
        eCommerceAttendeesLimit
        tourItemsStateCounts {
          ...TourItemsStateCountsFields
        }
        revenue
        revenueTicketCount
        revenueAttendeesCount
        saleSumOnRetailChannel
        saleTicketCountOnRetailChannel
        saleAttendeesCountOnRetailChannel
        saleSumOnECommerceChannel
        saleTicketCountOnECommerceChannel
        saleAttendeesCountOnECommerceChannel
        refundSumOnRetailChannel
        refundTicketCountOnRetailChannel
        refundAttendeesCountOnRetailChannel
        refundSumOnECommerceChannel
        refundTicketCountOnECommerceChannel
        refundAttendeesCountOnECommerceChannel
        venue {
          id
          name
        }
        division {
          id
          name
        }
        versionCode
        ageClassificationCode
        guide {
          ...UserFields
        }
        costCenter {
          id
          name
        }
        eventCategory {
          id
          name
        }
        marketingLabel {
          id
          name
        }
        tourTimeSlotId
      }
      totals {
        revenue
        revenueTicketCount
        revenueAttendeesCount
        saleSumOnRetailChannel
        saleTicketCountOnRetailChannel
        saleAttendeesCountOnRetailChannel
        saleSumOnECommerceChannel
        saleTicketCountOnECommerceChannel
        saleAttendeesCountOnECommerceChannel
        refundSumOnRetailChannel
        refundTicketCountOnRetailChannel
        refundAttendeesCountOnRetailChannel
        refundSumOnECommerceChannel
        refundTicketCountOnECommerceChannel
        refundAttendeesCountOnECommerceChannel
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useTourTimeSlotsStatistics = (
  variables: TourTimeSlotsStatisticsQueryVariables
) =>
  useQuery<TourTimeSlotsStatisticsQuery, TourTimeSlotsStatisticsQueryVariables>(
    TOUR_TIME_SLOTS_STATISTICS,
    {variables, fetchPolicy: 'network-only'}
  )

export const TOUR_TIME_SLOT_NARROW_DETAIL = gql`
  query TourTimeSlotNarrowDetail($id: PositiveInt!) {
    tourTimeSlot(id: $id) {
      id
      startsAt
      tour {
        id
        name
      }
    }
  }
`
