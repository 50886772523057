import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateProductsStatisticsCsvLinkMutation,
  CreateProductsStatisticsCsvLinkMutationVariables,
  ProductsStatisticsQuery,
  ProductsStatisticsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const PRODUCTS_STATISTICS = gql`
  ${PAGINATION_FRAGMENT}
  query ProductsStatistics(
    $paginationInput: PaginationInput!
    $filter: ProductsStatisticsFilterInput
  ) {
    productsStatistics(paginationInput: $paginationInput, filter: $filter) {
      items {
        id
        productName
        productInternalCode
        productTypeName
        revenueItemQuantity
        unit
        vatRate
        unitPriceVatExcluded
        unitVat
        unitPriceVatIncluded
        revenueVatExcluded
        revenueVat
        revenueVatIncluded
      }
      totals {
        revenueVat
        revenueVatExcluded
        revenueVatIncluded
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useProductsStatistics = (
  variables: ProductsStatisticsQueryVariables
) =>
  useQuery<ProductsStatisticsQuery, ProductsStatisticsQueryVariables>(
    PRODUCTS_STATISTICS,
    {variables, fetchPolicy: 'network-only'}
  )

const CREATE_PRODUCT_STATISTICS_LINK_MUTATION = gql`
  mutation CreateProductsStatisticsCsvLink(
    $filter: ProductsStatisticsFilterInput
    $localeCode: LocaleCode
  ) {
    createProductsStatisticsCsvLink(filter: $filter, localeCode: $localeCode)
  }
`

export const useCreateProductStatisticsCsvLink = () => {
  const [createProductStatisticsCsvLink] = useMutation<
    CreateProductsStatisticsCsvLinkMutation,
    CreateProductsStatisticsCsvLinkMutationVariables
  >(CREATE_PRODUCT_STATISTICS_LINK_MUTATION)
  return useCallback(
    (variables: CreateProductsStatisticsCsvLinkMutationVariables) =>
      createProductStatisticsCsvLink({variables}),
    [createProductStatisticsCsvLink]
  )
}
