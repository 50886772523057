import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  AuditoriumQuery,
  AuditoriumQueryVariables,
  AuditoriumsFilterInput,
  AuditoriumsQuery,
  AuditoriumsQueryVariables,
  CreateAuditoriumMutation,
  DeleteAuditoriumMutation,
  MutationCreateAuditoriumArgs,
  MutationDeleteAuditoriumArgs,
  MutationUpdateAuditoriumArgs,
  UpdateAuditoriumMutation,
  UpdateAuditoriumsOrderMutation,
  UpdateAuditoriumsOrderMutationVariables
} from '../../../../../__generated__/schema'
import {GET_VENUES} from '../graphql'

const AUDITORIUM_PROPERTIES_FRAGMENT = gql`
  fragment AuditoriumProperties on Auditorium {
    id
    name
    shortcut
    state
    defaultDivision {
      id
      name
    }
    auditoriumLayouts {
      id
    }
    venue {
      name
    }
  }
`

const CREATE_AUDITORIUM = gql`
  mutation CreateAuditorium($data: AuditoriumInput!) {
    createAuditorium(data: $data) {
      ...AuditoriumProperties
    }
  }
  ${AUDITORIUM_PROPERTIES_FRAGMENT}
`

export const GET_AUDITORIUMS = gql`
  query Auditoriums($filter: AuditoriumsFilterInput) {
    auditoriums(filter: $filter) {
      ...AuditoriumProperties
    }
  }
  ${AUDITORIUM_PROPERTIES_FRAGMENT}
`

const GET_AUDITORIUM = gql`
  query Auditorium($id: Int!) {
    auditorium(id: $id) {
      ...AuditoriumProperties
    }
  }
  ${AUDITORIUM_PROPERTIES_FRAGMENT}
`

const UPDATE_AUDITORIUM = gql`
  mutation UpdateAuditorium($id: Int!, $data: AuditoriumInput!) {
    updateAuditorium(id: $id, data: $data) {
      ...AuditoriumProperties
    }
  }
  ${AUDITORIUM_PROPERTIES_FRAGMENT}
`

const DELETE_AUDITORIUM = gql`
  mutation DeleteAuditorium($id: Int!) {
    deleteAuditorium(id: $id) {
      ...AuditoriumProperties
    }
  }
  ${AUDITORIUM_PROPERTIES_FRAGMENT}
`

export const useCreateAuditorium = () => {
  const [createAuditorium] =
    useMutation<CreateAuditoriumMutation, MutationCreateAuditoriumArgs>(
      CREATE_AUDITORIUM
    )
  return useCallback(
    ({data}: MutationCreateAuditoriumArgs) =>
      createAuditorium({
        variables: {data},
        refetchQueries: [
          {
            query: GET_VENUES
          }
        ]
      }),
    [createAuditorium]
  )
}

export const useUpdateAuditorium = () => {
  const [updateAuditorium] =
    useMutation<UpdateAuditoriumMutation, MutationUpdateAuditoriumArgs>(
      UPDATE_AUDITORIUM
    )
  return useCallback(
    ({id, data}: MutationUpdateAuditoriumArgs) =>
      updateAuditorium({variables: {id, data}}),
    [updateAuditorium]
  )
}

export const useDeleteAuditorium = () => {
  const [deleteAuditorium] =
    useMutation<DeleteAuditoriumMutation, MutationDeleteAuditoriumArgs>(
      DELETE_AUDITORIUM
    )
  return useCallback(
    ({id}: MutationDeleteAuditoriumArgs) =>
      deleteAuditorium({
        variables: {id},
        refetchQueries: [
          {
            query: GET_VENUES
          }
        ]
      }),
    [deleteAuditorium]
  )
}

export const useGetAuditoriums = (filter?: AuditoriumsFilterInput) => {
  return useQuery<AuditoriumsQuery, AuditoriumsQueryVariables>(
    GET_AUDITORIUMS,
    {
      variables: {filter},
      fetchPolicy: 'network-only'
    }
  )
}

export const useGetAuditorium = (id: number) => {
  return useQuery<AuditoriumQuery, AuditoriumQueryVariables>(GET_AUDITORIUM, {
    variables: {id},
    fetchPolicy: 'network-only'
  })
}

const UPDATE_AUDITORIUMS_ORDER = gql`
  mutation UpdateAuditoriumsOrder(
    $venueId: PositiveInt!
    $auditoriumIds: [PositiveInt!]!
  ) {
    updateAuditoriumsOrder(venueId: $venueId, auditoriumIds: $auditoriumIds) {
      id
    }
  }
`

export const useUpdateAuditoriumsOrder = () => {
  const [updateAuditoriumsOrder] = useMutation<
    UpdateAuditoriumsOrderMutation,
    UpdateAuditoriumsOrderMutationVariables
  >(UPDATE_AUDITORIUMS_ORDER)
  return useCallback(
    (variables: UpdateAuditoriumsOrderMutationVariables) =>
      updateAuditoriumsOrder({variables}),
    [updateAuditoriumsOrder]
  )
}
