import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import {styled} from '@mui/system'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsNonNegativeInteger} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {
  CardsRowBlockSettingFormField,
  CardsRowSize,
  ICardsRowBlockForm
} from '../editBlockDrawer/types'

const UPDATE_SETTINGS_FORM_ID = 'updateSettingsForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(1.5)
}))

interface ISettingsDialogProps {
  isOpen: boolean
  onClose: () => void
  defaultValues: ICardsRowBlockForm
  onSubmit: (data: ICardsRowBlockForm) => void
}

export const SettingsDialog: React.FC<ISettingsDialogProps> = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit
}: ISettingsDialogProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    control,
    watch,
    register,
    triggerValidation,
    handleSubmit,
    reset
  } = useForm<ICardsRowBlockForm>({defaultValues, mode: 'onChange'})
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit settings')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          id={UPDATE_SETTINGS_FORM_ID}
        >
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ICardsRowBlockForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CardsRowBlockSettingFormField.ClassName}
                key={CardsRowBlockSettingFormField.ClassName}
                label={t('Class name')}
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ICardsRowBlockForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CardsRowBlockSettingFormField.SpaceBetweenSlides}
                key={CardsRowBlockSettingFormField.SpaceBetweenSlides}
                label={t('Space between slides')}
                fullWidth
                validationOptions={{validate: isNonNegativeInteger}}
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormSelect<ICardsRowBlockForm>
                errors={errors}
                watch={watch}
                register={register}
                label={t('Size')}
                setValue={setValue}
                name={CardsRowBlockSettingFormField.Size}
                key={CardsRowBlockSettingFormField.Size}
                fullWidth
                hasNoneSelectOption
                selectOptions={{
                  [CardsRowSize.Small]: t('Small'),
                  [CardsRowSize.Medium]: t('Medium'),
                  [CardsRowSize.Large]: t('Large')
                }}
              />
            ]}
          />
          <InputRow
            nodes={[
              <FormSwitchWithLabels<ICardsRowBlockForm>
                name={CardsRowBlockSettingFormField.Autoplay}
                key={CardsRowBlockSettingFormField.Autoplay}
                primaryLabel={t('Autoplay')}
                control={control}
                watch={watch}
                defaultValue={
                  defaultValues?.[CardsRowBlockSettingFormField.Autoplay] ||
                  false
                }
                secondaryLabel=""
                enabledText=""
                disabledText=""
              />
            ]}
          />
        </StyledForm>
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={UPDATE_SETTINGS_FORM_ID}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
