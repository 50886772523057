import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {useCallback, useContext} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {
  PaymentMethodState,
  VoucherCampaignState,
  VoucherPropertiesFragment,
  VoucherState
} from '../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../hooks/effectiveClientCurrencySignInputProps'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {
  useIsNumberWithMaxBoundary,
  useIsPositiveNumber
} from '../../../../../utils/formsValidations'
import {SaveButton} from '../../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {COLOR_CONF} from '../../../../constants'
import {UncontrolledFormTextInputWithCancelButton} from '../../../../form/UncontrolledFormTextInputWithCancelButton'
import {
  IntentsStructureDispatchContext,
  IntentStructureActionType
} from './intentsStructure'

interface IInfoProps {
  label: string
  text: string
  className?: string
}

export const Info: React.FC<IInfoProps> = ({
  className,
  label,
  text
}: IInfoProps) => {
  return (
    <div className={className}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="subtitle1">{text}</Typography>
    </div>
  )
}

interface IVoucherBalanceDialogProps {
  voucher: VoucherPropertiesFragment
  onClose: () => void
  onCheckFormReset: () => void
  maxCredit: number
}

const useStyles = makeStyles<Theme>((theme) => ({
  dialogActionsRoot: {
    padding: theme.spacing(1, 3)
  },
  dialogContent: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateAreas: `
      "activation balance"
      "expiration ."
      "validityRow validityRow"
    `
  },
  balance: {
    gridTemplateAreas: 'balance',
    justifySelf: 'flex-end'
  },
  invalidRow: {
    gridArea: 'validityRow',
    display: 'flex',
    alignItems: 'baseline'
  },
  validRow: {
    gridArea: 'validityRow'
  },
  invalidChip: {
    color: COLOR_CONF.RED.color,
    backgroundColor: COLOR_CONF.RED.background
  }
}))

enum RedeemField {
  Credit = 'Credit'
}

interface IRedeemForm {
  [RedeemField.Credit]: number
}

const REDEEM_FORM_ID = 'REDEEM_FORM_ID'

export const VoucherBalanceDialog: React.FC<IVoucherBalanceDialogProps> = ({
  voucher,
  onClose,
  onCheckFormReset,
  maxCredit
}: IVoucherBalanceDialogProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {formatDateNumeric} = useDateTimeFormatters()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const isValid =
    voucher.state === VoucherState.Active &&
    voucher.balance > 0 &&
    voucher.campaign?.state === VoucherCampaignState.Active &&
    voucher.campaign?.retailPaymentMethod?.state === PaymentMethodState.Active
  const {register, errors, setValue, triggerValidation, watch, handleSubmit} =
    useForm<IRedeemForm>({
      defaultValues: {
        [RedeemField.Credit]: maxCredit
      }
    })
  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  const isPositiveNumber = useIsPositiveNumber()
  const isNumberWithMaxBoundary = useIsNumberWithMaxBoundary(maxCredit)
  const intentStructureDispatch = useContext(IntentsStructureDispatchContext)
  const _handleSubmit = useCallback(
    (form: IRedeemForm) => {
      if (voucher.campaign.retailPaymentMethod) {
        intentStructureDispatch({
          type: IntentStructureActionType.InsertInHouseVoucherFrame,
          payload: {
            campaign: voucher.campaign,
            voucherFrame: {
              voucherId: voucher.id,
              credit: new Decimal(
                convertValueToFloat(form[RedeemField.Credit]) ?? 0
              ).toNumber(),
              code: voucher.code,
              key: uuidv4(),
              paymentMethodId: voucher.campaign.retailPaymentMethod.id
            }
          }
        })
      }
      onCheckFormReset()
      onClose()
    },
    [
      intentStructureDispatch,
      onCheckFormReset,
      onClose,
      voucher.campaign,
      voucher.code,
      voucher.id
    ]
  )
  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {voucher.campaign.name} {voucher.code}
      </DialogTitleWithCloseButton>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Info
          label={t('Activation')}
          text={
            voucher.activatedAt
              ? formatDateNumeric(new Date(voucher.activatedAt))
              : t('Not activated yet')
          }
        />
        <Info
          className={classes.balance}
          label={t('Balance')}
          text={translateEffectiveClientPrice(voucher.balance)}
        />
        <Info
          label={t('Valid until')}
          text={
            voucher.expirationDate
              ? formatDateNumeric(new Date(voucher.expirationDate))
              : t('Undefined')
          }
        />
        {isValid ? (
          <form
            className={classes.validRow}
            id={REDEEM_FORM_ID}
            onSubmit={handleSubmit(_handleSubmit)}
          >
            <UncontrolledFormTextInputWithCancelButton<IRedeemForm>
              margin="dense"
              fullWidth
              className={classes.uncontrolledInput}
              label={t('Redeem credit')}
              name={RedeemField.Credit}
              register={register}
              errors={errors}
              InputProps={currencySignInputProps}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              required
              validationOptions={{
                required: true,
                validate: {
                  isPositiveNumber,
                  isNumberWithMaxBoundary
                }
              }}
            />
          </form>
        ) : (
          <div className={classes.invalidRow}>
            <Typography color="textSecondary" component="div">
              <Chip label={t('Invalid')} className={classes.invalidChip} />
              &nbsp;
              {t('Unable to redeem.')}
            </Typography>
          </div>
        )}
      </DialogContent>
      {isValid && (
        <>
          <Divider />
          <DialogActions
            classes={{
              root: classes.dialogActionsRoot
            }}
          >
            <SaveButton type="submit" form={REDEEM_FORM_ID}>
              {t('Redeem')}
            </SaveButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
