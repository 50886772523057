import {useTranslation} from 'react-i18next'

import {LeadInputStatus} from '../__generated__/schema'

export const useTranslateLeadInputStatus = () => {
  const {t} = useTranslation()
  const translated: Record<LeadInputStatus, string> = {
    [LeadInputStatus.Unavailable]: t<string>('LeadInputStatus->Unavailable'),
    [LeadInputStatus.Optional]: t<string>('LeadInputStatus->Optional'),
    [LeadInputStatus.Required]: t<string>('LeadInputStatus->Required'),
    [LeadInputStatus.Recommended]: t<string>('LeadInputStatus->Recommended')
  }
  return (code: LeadInputStatus) => translated[code]
}
