import {
  CartsFilterInput,
  CartsSupportUserFilterInput
} from '../../../../../__generated__/schema'
import {DATERANGE_IDS} from '../../../../common/search/types'

export type CartsFilter = CartsFilterInput | CartsSupportUserFilterInput

export type ExtendedCartsFilter = CartsFilter & {
  _createdAtDaterangeId?: DATERANGE_IDS
  _updatedAtDaterangeId?: DATERANGE_IDS
  _startsAtDaterangeId?: DATERANGE_IDS
}

export enum CartSearchLocation {
  Carts = 'cartsPage',
  CartsSupport = 'cartsSupport'
}

export const DEFAULT_CARTS_FILTER = {
  hasText: ''
}
