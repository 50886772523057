import {ApolloError} from 'apollo-client'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ErrorMessages,
  IncrementProductItemQuantityWithPriceLookupCodeMutationVariables,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {CodeFormulaErrorMessage} from './types'
import {validateCodeFormula} from './validateCodeFormula'

export const useTranslateCodeFormulaErrorMessages = () => {
  const {t} = useTranslation()
  const translated: Record<CodeFormulaErrorMessage, string> = {
    [CodeFormulaErrorMessage.InvalidInput]: t<string>(
      'Invalid input. Expected PLU code or quantity*PLU code'
    ),
    [CodeFormulaErrorMessage.ZeroQuantity]: t<string>(
      'Quantity must be greater than 0'
    ),
    [CodeFormulaErrorMessage.MissingPlu]: t<string>('Type PLU code'),
    [CodeFormulaErrorMessage.MaxPluLength]: t<string>(
      'Max length of PLU code exceeded'
    ),
    [CodeFormulaErrorMessage.PluCodeCannotBeZero]: t<string>(
      'PLU code can not be 0'
    )
  }
  return (message: CodeFormulaErrorMessage) => translated[message]
}

export const validatePluCode = (
  code: string,
  setPluCodeError: (error: CodeFormulaErrorMessage | null) => void
) => {
  const validationResult = validateCodeFormula(code)
  if (!validationResult.isValid && validationResult.message && code) {
    setPluCodeError(validationResult.message)
  } else {
    setPluCodeError(null)
  }
}
const extractPluCodeWithIncrement = (code: string) => {
  if (code.includes('*')) {
    const split = code.split('*')
    return {
      increment: parseInt(split[0], 10),
      priceLookupCode: parseInt(split[1], 10)
    }
  } else if (code.includes('x')) {
    const split = code.split('x')
    return {
      increment: parseInt(split[0], 10),
      priceLookupCode: parseInt(split[1], 10)
    }
  } else if (code.includes('X')) {
    const split = code.split('X')
    return {
      increment: parseInt(split[0], 10),
      priceLookupCode: parseInt(split[1], 10)
    }
  } else {
    return {increment: 1, priceLookupCode: parseInt(code, 10)}
  }
}

export const transformPluToInput = ({
  cartId,
  divisionId,
  code,
  warehouseId
}: {
  cartId?: number | null
  divisionId: number
  code: string
  warehouseId?: number | null
}): IncrementProductItemQuantityWithPriceLookupCodeMutationVariables => ({
  cartId,
  divisionId,
  warehouseId,
  ...extractPluCodeWithIncrement(code)
})

export const useIncrementProductItemQuantityErrorHandler = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {addInfoNotification, defaultErrorHandler, customErrorHandler} =
    useMutationAssistanceHooks()
  const history = useHistory()
  return useCallback(
    ({error}: {error: ApolloError}) => {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.ProductNotFound
        )
      ) {
        addInfoNotification(t('Product not found'))
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.DivisionNotFound
        )
      ) {
        addInfoNotification(t('Division not found'))
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.ProductHasNoActivePricing
        )
      ) {
        addInfoNotification(t('Product has no active pricing'))
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.ProductIsNotAvailable
        )
      ) {
        addInfoNotification(t('Product is not available'))
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.MissingWarehouseId
        )
      ) {
        customErrorHandler(error, {
          title: t('Missing warehouse in device settings'),
          contentText: t(
            'No warehouse has been configured in the device settings. This setting is required to ensure accurate processing and inventory management. Please set a warehouse in the device settings or contact your administrator for assistance.'
          ),
          cancelButtonLabel: P([PermissionCode.ChangeDeviceSettings])
            ? t('Settings')
            : undefined,
          onCancel: P([PermissionCode.ChangeDeviceSettings])
            ? () => history.push(routeTo.admin.cashDesk.settings())
            : undefined,
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.WarehouseProductOutOfStock
        )
      ) {
        addInfoNotification(t('Product is out of stock'))
      } else {
        defaultErrorHandler(error, t('Error while incrementing product'))
      }
    },
    [
      addInfoNotification,
      t,
      customErrorHandler,
      P,
      history,
      defaultErrorHandler
    ]
  )
}
