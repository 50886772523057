import {Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetCheckTicketsEventDrawerDataQuery} from '../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {
  DrawerSection,
  EntityStateChip,
  IDrawerSectionProps
} from '../../../common'
import {DividerSeparatedInfoRow} from '../../../common/DividerSeparatedInfoRow'
import {COLOR_CONF} from '../../../constants'

const useGetGateStatusColorLabelAndDescription = () => {
  const {t} = useTranslation()
  const {formatTime, formatDate} = useDateTimeFormatters()

  return (gateOpensAt: string, gateCloseAt: string) => {
    if (dayjs().isAfter(gateCloseAt)) {
      return {
        color: COLOR_CONF.RED,
        label: t('Gates closed'),
        description: t(
          'Checking tickets for event was allowed to {{date}} at {{time}}',
          {
            date: formatDate(new Date(gateCloseAt)),
            time: formatTime(new Date(gateCloseAt))
          }
        )
      }
    }
    if (dayjs().isAfter(gateOpensAt) && dayjs().isBefore(gateCloseAt)) {
      return {
        color: COLOR_CONF.GREEN,
        label: t('Gates opened'),
        description: t(
          'Checking tickets for event is allowed to {{date}} at {{time}}',
          {
            date: formatDate(new Date(gateCloseAt)),
            time: formatTime(new Date(gateCloseAt))
          }
        )
      }
    }
    return {
      color: COLOR_CONF.YELLOW,
      label: t('Gates closed'),
      description: t(
        'Checking tickets for event will be allowed from {{date}} at {{time}}',
        {
          date: formatDate(new Date(gateOpensAt)),
          time: formatTime(new Date(gateOpensAt))
        }
      )
    }
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  },
  checkingStatus: {
    display: 'flex',
    alignItems: 'baseline',
    alignContent: 'baseline',
    gap: theme.spacing(1)
  }
}))

interface IGeneralDrawerSectionProps {
  event: GetCheckTicketsEventDrawerDataQuery['event']
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const GeneralDrawerSection: React.FC<IGeneralDrawerSectionProps> = ({
  event,
  drawerSectionProps
}: IGeneralDrawerSectionProps) => {
  const {t} = useTranslation()
  const {formatTime, formatDateTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const getGateStatusColorLabelAndDescription =
    useGetGateStatusColorLabelAndDescription()
  const {color, label, description} = getGateStatusColorLabelAndDescription(
    event.gateOpensAt,
    event.gateClosedAt
  )
  const classes = useStyles()
  return (
    <DrawerSection {...drawerSectionProps}>
      <div className={classes.root}>
        <Typography variant="h6">
          {getUserLocaleTranslation(event.names)}
        </Typography>
        <div className={classes.checkingStatus}>
          <EntityStateChip
            label={label}
            colorConf={color}
            size="small"
            isDotHidden
          />
          <Typography variant="caption" color="textSecondary">
            {description}
          </Typography>
        </div>
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Gates opened'),
              value: dayjs(event.gateOpensAt).isSame(event.gateClosedAt, 'day')
                ? `${formatDateTime(
                    new Date(event.gateOpensAt)
                  )} - ${formatTime(new Date(event.gateClosedAt))}`
                : `${formatDateTime(
                    new Date(event.gateOpensAt)
                  )} - ${formatDateTime(new Date(event.gateClosedAt))}`
            },
            {
              caption: t('Event ends at'),
              value: `${formatDateTime(new Date(event.endsAt))}`
            }
          ]}
        />
      </div>
    </DrawerSection>
  )
}
