import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  PaymentMethodGroupPropertiesFragment,
  PaymentMethodGroupsQuery,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {DataGridTable} from '../../../common/DataGridTable'
import {Blank} from '../../../visual/Blank'
import {CreatePaymentMethodGroupDrawer} from './CreatePaymentMethodGroupDrawer'
import {EditPaymentMethodGroupDrawer} from './EditPaymentMethodGroupDrawer'

import {useDeletePaymentMethodGroup, usePaymentMethodGroups} from './graphql'

const EditIconCellRenderer = ({
  id,
  name,
  setGroupToEdit
}: {
  id: number
  name: string
  setGroupToEdit: (data: PaymentMethodGroupPropertiesFragment) => void
}) => (
  <IconButton
    sx={{width: 48, height: 48}}
    onClick={() => setGroupToEdit({id, name})}
  >
    <EditIcon />
  </IconButton>
)

const DeleteIconCellRenderer = ({id}: {id: number}) => {
  const {t} = useTranslation()
  const {
    state: isOpen,
    setTrue: setOpened,
    setFalse: setClosed
  } = useBooleanState(false)
  const deletePaymentMethodGroup = useDeletePaymentMethodGroup()
  const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const handleDeleteClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deletePaymentMethodGroup({id})
      addInfoNotification(t('Payment method group deleted successfully'))
      setClosed()
    } catch (error) {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.PaymentMethodGroupInUseCantBeDeleted
        )
      ) {
        defaultErrorHandler(
          error,
          t('Payment method group is still in use'),
          t(
            "You can't delete payment method group, that is actively used by paymenet methods. If you really want to delete this group, delete related payment methods first."
          )
        )
      } else {
        defaultErrorHandler(error, t('Deleting payment method group failed'))
      }
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deletePaymentMethodGroup,
    id,
    setClosed,
    setShowBackdrop,
    t
  ])
  return (
    <>
      <IconButton sx={{width: 48, height: 48}} onClick={setOpened}>
        <DeleteIcon />
      </IconButton>
      <ConfirmationDialog
        title={t('Delete payment method group')}
        contentText={
          <>
            {t(
              "Deleting this payment method will not affect created transactions, but new usage won't be allowed."
            )}
            <br />
            <br />
            {t(
              "Are you sure you want to delete this payment method? This can't be undone."
            )}
          </>
        }
        confirmButtonLabel={t('Delete')}
        isOpen={isOpen}
        onCancel={setClosed}
        onConfirm={handleDeleteClick}
      />
    </>
  )
}

export const Groups: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = usePaymentMethodGroups()
  const hasCreatePaymentMethodGroupPermission = P([
    PermissionCode.CreatePaymentMethodGroup
  ])
  const {
    setTrue: openCreateDrawer,
    setFalse: closeCreateDrawer,
    state: isCreateDrawerOpened
  } = useBooleanState(false)
  const [groupToEdit, setGroupToEdit] =
    useState<PaymentMethodGroupPropertiesFragment | null>(null)
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 300
      },
      {
        headerName: '',
        field: 'editIcon',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <EditIconCellRenderer
              id={params.value}
              name={params.row.name}
              setGroupToEdit={setGroupToEdit}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      },
      {
        headerName: '',
        field: 'deleteIcon',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DeleteIconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [t]
  )
  return (
    <RenderOnData<PaymentMethodGroupsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Could not load payment method groups')}
      dataCondition={(data) => Array.isArray(data.paymentMethodGroups)}
      ignoreLoadingIfData
    >
      {({paymentMethodGroups}) => (
        <>
          {paymentMethodGroups.length > 0 ? (
            <>
              <DataGridTable
                columns={columns}
                rows={paymentMethodGroups}
                hideFooter
                pagination={false}
                disableRowSelectionOnClick
                columnVisibilityModel={{
                  editIcon: P([PermissionCode.EditPaymentMethodGroup]),
                  deleteIcon: P([PermissionCode.DeletePaymentMethodGroup])
                }}
                initialState={{
                  pinnedColumns: {
                    left: ['name'],
                    right: ['editIcon', 'deleteIcon']
                  }
                }}
              />
              {hasCreatePaymentMethodGroupPermission && (
                <CreateFab onClick={openCreateDrawer} classes={fabClasses} />
              )}
            </>
          ) : (
            <Blank
              title={t('No payment method groups to show')}
              description={
                hasCreatePaymentMethodGroupPermission
                  ? t(
                      'Group your payment methods at your cash desk for your cashiers'
                    )
                  : undefined
              }
              actions={
                hasCreatePaymentMethodGroupPermission ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={openCreateDrawer}
                  >
                    {t('Create')}
                  </Button>
                ) : undefined
              }
            />
          )}
          <CreatePaymentMethodGroupDrawer
            closeDrawer={closeCreateDrawer}
            isOpen={isCreateDrawerOpened}
          />
          <EditPaymentMethodGroupDrawer
            closeDrawer={() => setGroupToEdit(null)}
            groupToEdit={groupToEdit}
          />
        </>
      )}
    </RenderOnData>
  )
}
