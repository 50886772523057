import {
  PaymentMethodGroupPropertiesFragment,
  PaymentMethodType,
  ProductItemPropertiesFragment,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment,
  VoucherPropertiesFragment
} from '../../../../../__generated__/schema'

export type CartSummaryItem = {
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
  count: number
  total: number
  attendeesCount?: number
}

export enum IntentFrameComponentType {
  IntentTextFieldWithChipsFrame = 'intentTextFieldWithChipsFrame',
  IntentCountFrame = 'intentCountFrame',
  AddIntentFrame = 'addIntentFrame',
  InHouseVoucherIntentFrame = 'inHouseVoucherIntentFrame',
  None = 'none'
}

export interface IIntentFrame {
  paymentMethodId: number
  key: string
  initialDenomination: number
  initialCount: number
  name: string
  hasFixedDenomination: boolean
  type: PaymentMethodType
  componentType: IntentFrameComponentType
}

export interface IAddIntentFrame extends IIntentFrame {
  componentType: IntentFrameComponentType.AddIntentFrame
}

export interface ITypeIntentStructure {
  key: string
  type: PaymentMethodType
  intentFrames: IIntentFrame[]
}

export interface IGroupIntentStructure {
  key: string
  group: PaymentMethodGroupPropertiesFragment
  intentFrames: IIntentFrame[]
}

export interface IInHouseVoucherRowStructure {
  key: string
}

export interface IInHouseVoucherFrame {
  code: string
  credit: number
  voucherId: number
  key: string
  paymentMethodId: number
}
export interface IInHouseVoucherIntentStructure {
  key: string
  campaign: VoucherPropertiesFragment['campaign']
  voucherFrames: IInHouseVoucherFrame[]
}

export type IntentsStructureReducer = {
  typeIntentStructures: ITypeIntentStructure[]
  groupIntentStructures: IGroupIntentStructure[]
  inHouseVoucherIntentStructure: IInHouseVoucherIntentStructure[]
  inHouseVoucherRowStructure: IInHouseVoucherRowStructure | null
  expandedStructureKey: string | null
}
