import AddIcon from '@mui/icons-material/Add'
import {Button, Drawer, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {filter} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  GetClientTemplatesQuery,
  PermissionCode
} from '../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../hooks/state'
import {useTranslateLocaleCode} from '../../hooks/translateLocales'
import {
  useTranslateTemplateFileType,
  useTranslateTemplateType
} from '../../hooks/translateTemplates'
import {useEnsurePermissions, useUserInfo} from '../../utils/auth'
import {useDateTimeFormatters} from '../../utils/formatting'
import {useTemplateParams} from '../../utils/pathname'
import {
  useGetClientTemplates,
  useRemoveTemplateFromClient
} from '../pages/admin/clients/graphql'
import {isTemplateType} from '../pages/admin/types'
import {Blank, BlankContentPosition} from '../visual/Blank'
import {DrawerTemplate, DrawerTemplateHeader} from './DrawerUtils'
import {ItemRow} from './ItemRow'
import {ListOfItemsSeparatedByDividers} from './ListOfItemsSeparatedByDividers'
import {RenderOnData} from './RenderOnData'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  list: {
    border: `1px solid ${theme.palette.divider}`
  }
}))

interface IClientTemplatesDrawerProps {
  onExited: () => void
  onAssignTemplateButtonClick: () => void
}

export const ClientTemplatesDrawer: React.FC<IClientTemplatesDrawerProps> = ({
  onExited,
  onAssignTemplateButtonClick
}: IClientTemplatesDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {templateType} = useTemplateParams()
  const {id} = useParams<{id: string}>()
  const {effectiveClient} = useUserInfo()
  const {formatDate} = useDateTimeFormatters()
  const clientId = id ? parseInt(id, 10) : effectiveClient?.id
  const {data, loading, error} = useGetClientTemplates({
    skip: !clientId,
    variables: {id: clientId!},
    fetchPolicy: 'network-only'
  })
  const removeTemplateFromClient = useRemoveTemplateFromClient()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const translateTemplateType = useTranslateTemplateType()
  const translateTemplateFileType = useTranslateTemplateFileType()
  const translateLocaleCode = useTranslateLocaleCode()
  const classes = useStyles()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    if (isTemplateType(templateType) && clientId) {
      openDrawer()
    }
  }, [clientId, openDrawer, templateType])
  const onRemoveButtonClick = useCallback(
    (templateId: number) => async () => {
      if (clientId) {
        try {
          setShowBackdrop(true)
          await removeTemplateFromClient({templateId, clientId})
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while removing template from client')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      clientId,
      defaultErrorHandler,
      removeTemplateFromClient,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      SlideProps={{onExited}}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Assigned templates')}
          />
        }
      >
        <RenderOnData
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading client')}
          dataCondition={(data: GetClientTemplatesQuery) =>
            Boolean(data?.client)
          }
        >
          {({client}) => {
            const filteredTemplateType = filter(
              client.templateAssignments,
              ({template}) => template.types.includes(templateType)
            )
            return filteredTemplateType.length > 0 ? (
              <div className={classes.root}>
                <div className={classes.infoRow}>
                  <Typography variant="caption" color="textSecondary">
                    {t('Templates assigned for type: {{templateType}}', {
                      templateType: translateTemplateType(templateType)
                    })}
                  </Typography>
                  {P([PermissionCode.AssignTemplateToClient]) && (
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={onAssignTemplateButtonClick}
                    >
                      {t('Add template')}
                    </Button>
                  )}
                </div>
                <ListOfItemsSeparatedByDividers
                  className={classes.list}
                  isFullWidth
                >
                  {filteredTemplateType.map((templateAssignment) => (
                    <ItemRow
                      key={templateAssignment.template.id}
                      firstRow={
                        <Typography variant="body1">
                          {[
                            t('#{{templateId}}', {
                              templateId: templateAssignment.template.id
                            }),
                            templateAssignment.template.name
                          ].join(' • ')}
                        </Typography>
                      }
                      secondRow={
                        <Typography variant="caption" color="textSecondary">
                          {[
                            translateTemplateFileType(
                              templateAssignment.template.fileType
                            ),
                            t('Locale {{locale}}', {
                              locale: translateLocaleCode(
                                templateAssignment.template.localeCode
                              ).toLowerCase()
                            })
                          ].join(' • ')}
                        </Typography>
                      }
                      thirdRow={
                        P([PermissionCode.AssignTemplateToClient]) ? (
                          <Typography variant="caption" color="textSecondary">
                            {[
                              t('Assigned {{date}}', {
                                date: formatDate(
                                  new Date(templateAssignment.createdAt)
                                )
                              }),
                              templateAssignment.createdByName
                            ].join(' • ')}
                          </Typography>
                        ) : undefined
                      }
                      fourthRow={
                        P([PermissionCode.AssignTemplateToClient]) ? (
                          <Typography variant="caption" color="textSecondary">
                            {templateAssignment.template.description}
                          </Typography>
                        ) : undefined
                      }
                      action={
                        P([PermissionCode.RemoveTemplateFromClient]) ? (
                          <Button
                            onClick={onRemoveButtonClick(
                              templateAssignment.template.id
                            )}
                            variant="text"
                            color="primary"
                          >
                            {t('Remove')}
                          </Button>
                        ) : undefined
                      }
                    />
                  ))}
                </ListOfItemsSeparatedByDividers>
              </div>
            ) : (
              <Blank
                title={t('No templates have been found')}
                actions={
                  P([PermissionCode.AssignTemplateToClient]) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAssignTemplateButtonClick}
                    >
                      {t('Add template')}
                    </Button>
                  )
                }
                contentPosition={BlankContentPosition.Center}
              />
            )
          }}
        </RenderOnData>
      </DrawerTemplate>
    </Drawer>
  )
}
