import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {IconButton, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'

const useStyles = makeStyles<Theme, {hasIcon: boolean}>((theme) => ({
  root: {
    padding: ({hasIcon}) =>
      hasIcon ? theme.spacing(1, 0) : theme.spacing(2.5, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 0
    }
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  iconButton: {
    marginLeft: theme.spacing(1)
  }
}))

interface IEventRowProps {
  leftInfo: React.ReactNode
  ticketsCount?: number
  price?: number
  onLinkButtonClick?: () => void
}

export const EventRow: React.FC<IEventRowProps> = ({
  leftInfo,
  ticketsCount,
  price,
  onLinkButtonClick
}: IEventRowProps) => {
  const {t} = useTranslation()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const classes = useStyles({hasIcon: Boolean(onLinkButtonClick)})
  return (
    <div className={classes.root}>
      {leftInfo}
      <div className={classes.right}>
        {ticketsCount && (
          <Typography variant="body2" color="textSecondary">
            {t('{{count}} tickets', {count: ticketsCount})}
          </Typography>
        )}
        {ticketsCount && typeof price === 'number' && (
          <Typography variant="body2" color="textSecondary">
            {'•'}
          </Typography>
        )}
        {typeof price === 'number' && (
          <Typography variant="body2" color="textSecondary">
            {translateEffectiveClientPrice(price)}
          </Typography>
        )}
        {onLinkButtonClick && (
          <IconButton
            color="primary"
            onClick={onLinkButtonClick}
            className={classes.iconButton}
          >
            <OpenInNewIcon />
          </IconButton>
        )}
      </div>
    </div>
  )
}
