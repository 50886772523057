import {styled} from '@mui/system'
import {get} from 'lodash'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages, LocaleCode} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateLocaleCode} from '../../../../../hooks/translateLocales'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {
  useIsStringWithMaxLength,
  useIsValidSlug
} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {slugify} from '../../utils'
import {IWebsitePageForm, WebsitePageFormField} from '../types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IWebsitePageFormProps {
  formId: string
  onSubmit: (data: IWebsitePageForm) => Promise<void>
  defaultValues?: Partial<IWebsitePageForm>
  primaryDomain?: string
  enabledLocaleCodes: LocaleCode[]
  isLocaleCodeDisabled?: boolean
}

export const WebsitePageForm: React.FC<IWebsitePageFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  primaryDomain,
  enabledLocaleCodes,
  isLocaleCodeDisabled
}: IWebsitePageFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    setError,
    watch,
    register,
    triggerValidation,
    handleSubmit
  } = useForm<IWebsitePageForm>({defaultValues, reValidateMode: 'onBlur'})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isValidSlug = useIsValidSlug()
  const translateLocaleCode = useTranslateLocaleCode()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const _onSubmit = useCallback(
    async (formData: IWebsitePageForm) => {
      try {
        await onSubmit(formData)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.SlugIsAlreadyUsed
          )
        ) {
          setError(WebsitePageFormField.Slug, t('Slug is already used'))
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.UnsupportedCharacterInSlug
          )
        ) {
          setError(
            WebsitePageFormField.Slug,
            t('Unsupported character in slug')
          )
        } else {
          defaultErrorHandler(error, t('Error while creating website page'))
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  const handleTitleChange = useCallback(
    (e) => {
      setValue(
        WebsitePageFormField.Title,
        e.target.value,
        !!get(errors, WebsitePageFormField.Title)
      )
      setValue(WebsitePageFormField.Slug, slugify(e.target.value ?? ''))
    },
    [errors, setValue]
  )
  return (
    <StyledForm onSubmit={handleSubmit(_onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IWebsitePageForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Locale code')}
            setValue={setValue}
            name={WebsitePageFormField.LocaleCode}
            key={WebsitePageFormField.LocaleCode}
            fullWidth
            required
            validationOptions={{required: true}}
            selectOptions={enabledLocaleCodes.reduce(
              (acc, localeCode) => ({
                ...acc,
                [localeCode]: translateLocaleCode(localeCode)
              }),
              {}
            )}
            disabled={isLocaleCodeDisabled}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsitePageForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            onChange={handleTitleChange}
            triggerValidation={triggerValidation}
            name={WebsitePageFormField.Title}
            key={WebsitePageFormField.Title}
            label={t('Title')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsitePageForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsitePageFormField.Slug}
            key={WebsitePageFormField.Slug}
            label={t('Slug')}
            validationOptions={{
              validate: {
                isStringWithMaxLength255,
                isValidSlug
              },
              required: true
            }}
            fullWidth
            required
            helperNote={
              primaryDomain
                ? `${primaryDomain}/${watch(WebsitePageFormField.Slug)}`
                : undefined
            }
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWebsitePageForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WebsitePageFormField.Description}
            key={WebsitePageFormField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
            helperNote={t(
              'Recommended for helping visitors understand what the page is about.'
            )}
          />
        ]}
      />
    </StyledForm>
  )
}
