import {ApiSeatState} from '../../../../../__generated__/schema'

export type AggregatedSeatsStateCounts = Record<ApiSeatState, number>

export enum ZoneSeatsCountFormField {
  Other = 'other',
  Selected = 'selected',
  Disabled = 'disabled'
}
export interface IZoneSeatsCountForm {
  [ZoneSeatsCountFormField.Other]: string
  [ZoneSeatsCountFormField.Selected]: string
  [ZoneSeatsCountFormField.Disabled]: string
}
