import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AuditoriumLayoutQuery,
  AuditoriumLayoutState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useVenuesPathnameParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {
  IActionButton,
  IOpenModalButtonProps,
  StateChangeModal
} from '../../../../common/StateChangeModal'
import {useNotifications} from '../../../../context/notifications'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {EntityDetailCardLayout} from '../common'
import {LayoutPricings} from '../layoutPricings/List'
import {VenuesBreadcrumbs} from '../VenuesBreadcrumbs'
import {StateRenderer, useAuditoriumLayoutTypeToString} from './common'

import {useDeleteAuditoriumLayout, useGetAuditoriumLayout} from './graphql'

interface IDeleteAuditoriumLayoutButtonProps extends IActionButton {
  venueId: number
  auditoriumId: number
  disabled: boolean
}

const DeleteAuditoriumLayoutButton: React.FC<IDeleteAuditoriumLayoutButtonProps> =
  ({
    id,
    venueId,
    auditoriumId,
    disabled
  }: IDeleteAuditoriumLayoutButtonProps) => {
    const {t} = useTranslation()
    const history = useHistory()
    const deleteAuditoriumLayout = useDeleteAuditoriumLayout(auditoriumId)
    const {addInfoNotification} = useNotifications()
    const defaultErrorHandler = useDefaultErrorHandler()

    const onSubmit = async () => {
      try {
        await deleteAuditoriumLayout({id})
        addInfoNotification(t('Auditorium layout was deleted.'))
        history.replace(
          routeTo.admin.venues.detailAuditorium(venueId, auditoriumId)
        )
      } catch (err) {
        defaultErrorHandler(
          err,
          t('Error while deleting the auditorium layout.')
        )
      }
    }

    const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
      onClick
    }: IOpenModalButtonProps) => {
      return (
        <Button
          cypress-id="delete-layout-button"
          color="primary"
          onClick={onClick}
          disabled={disabled}
          startIcon={<DeleteIcon />}
        >
          {t('Delete')}
        </Button>
      )
    }

    return (
      <StateChangeModal
        onSubmit={onSubmit}
        header={t('Do you really want to delete the auditorium layout?')}
        message={t('Are you sure you want to delete the auditorium layout?')}
        submitButtonText={t('Delete')}
        OpenModalButton={OpenModalButton}
        confirmationButtonCypressId="delete-layout-confirmation-button"
      />
    )
  }

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(1)
  }
}))

const getStateDescription = ({
  state,
  t
}: {
  state: AuditoriumLayoutState
  t: TFunction
}) => {
  if (state === AuditoriumLayoutState.Draft) {
    return t(
      "The auditorium layout is not ready yet and can't be used in event planner. Activate it and create layout pricing for this auditorium layout to start use it."
    )
  } else if (state === AuditoriumLayoutState.Archived) {
    return t(
      "The auditorium layout doesn't show in event planner and you are not able to use it for new events. You can change this option at any time."
    )
  }
  return null
}

interface IStateInfoProps {
  state: AuditoriumLayoutState
}

const StateInfo: React.FC<IStateInfoProps> = ({state}: IStateInfoProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return state !== AuditoriumLayoutState.Active ? (
    <div className={classes.root}>
      <StateRenderer status={state} />
      <Typography variant="caption" color="textSecondary">
        {getStateDescription({state, t})}
      </Typography>
    </div>
  ) : null
}

export const AuditoriumLayoutDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {venueId, auditoriumId, auditoriumLayoutId} = useVenuesPathnameParams()
  const {data, loading, error} = useGetAuditoriumLayout(auditoriumLayoutId)
  const auditoriumLayoutTypeTranslations = useAuditoriumLayoutTypeToString()

  return (
    <PageWithHeaderTemplate header={<PrimaryHeader title={t('Venues')} />}>
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData
          {...{loading, error, data}}
          errorMessage={t<string>('Could not load auditorium')}
        >
          {(data: AuditoriumLayoutQuery) => {
            const {auditoriumLayout} = data

            return (
              <CenteredLayout>
                <CenteredLayoutListWrapper>
                  <VenuesBreadcrumbs
                    links={[
                      {
                        label: t('Venues'),
                        onClick: () =>
                          history.replace(routeTo.admin.venues.home())
                      },
                      {
                        label: auditoriumLayout.auditorium.venue.name,
                        onClick: () =>
                          history.replace(routeTo.admin.venues.detail(venueId))
                      },
                      {
                        label: auditoriumLayout.auditorium.name,
                        onClick: () =>
                          history.replace(
                            routeTo.admin.venues.detailAuditorium(
                              venueId,
                              auditoriumId
                            )
                          )
                      },
                      {
                        label: auditoriumLayout.name
                      }
                    ]}
                  />
                  <EntityDetailCardLayout
                    header={auditoriumLayout.name}
                    Buttons={[
                      P([PermissionCode.DeleteAuditoriumLayout]) && (
                        <DeleteAuditoriumLayoutButton
                          id={auditoriumLayoutId}
                          {...{venueId, auditoriumId}}
                          key={1}
                          disabled={
                            auditoriumLayout.status !==
                            AuditoriumLayoutState.Draft
                          }
                        />
                      ),
                      P([PermissionCode.UpdateAuditoriumLayout]) && (
                        <Button
                          color="primary"
                          key={2}
                          onClick={() =>
                            history.push(
                              routeTo.admin.venues.editAuditoriumLayout(
                                venueId,
                                auditoriumId,
                                auditoriumLayoutId
                              )
                            )
                          }
                          startIcon={<EditIcon />}
                          cypress-id="layout-edit-button"
                        >
                          {t('Edit')}
                        </Button>
                      )
                    ].filter(Boolean)}
                    supplementalInformation={[
                      {
                        caption: t('Capacity'),
                        value: auditoriumLayout.capacity
                      },
                      {
                        caption: t('Type'),
                        value: auditoriumLayoutTypeTranslations(
                          auditoriumLayout.type
                        )
                      }
                    ]}
                  >
                    <StateInfo state={data.auditoriumLayout.status} />
                  </EntityDetailCardLayout>
                  {P([PermissionCode.ReadAuditoriumLayoutPricings]) && (
                    <LayoutPricings
                      {...{venueId, auditoriumId, auditoriumLayoutId}}
                    />
                  )}
                </CenteredLayoutListWrapper>
              </CenteredLayout>
            )
          }}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
