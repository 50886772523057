import gql from 'graphql-tag'
import {useMutation} from '@apollo/react-hooks'
import {useCallback} from 'react'
import {DocumentNode} from 'graphql'

import {
  DeleteEventMutation,
  PublishEventMutation,
  CancelEventMutation,
  UpdateExtendedEventDataMutation,
  UpdateExtendedEventDataMutationVariables,
  DeleteEventMutationVariables,
  PublishEventMutationVariables,
  CancelEventMutationVariables,
  EventsQueryVariables
} from '../../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../../utils/apollo'

import {
  EVENT_PROPERTIES_FRAGMENT,
  DETAIL_EVENT_PROPERTIES_FRAGMENT,
  GET_EVENTS
} from '../graphql'
import {useGetEventsQueryParams} from '../common'

const DELETE_EVENT = gql`
  mutation DeleteEvent($id: Int!) {
    deleteEvent(id: $id)
  }
`

const PUBLISH_EVENT = gql`
  mutation PublishEvent($id: Int!) {
    publishEvent(id: $id) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`

const CANCEL_EVENT = gql`
  mutation CancelEvent($id: Int!) {
    cancelEvent(id: $id) {
      ...EventProperties
    }
  }
  ${EVENT_PROPERTIES_FRAGMENT}
`

const UPDATE_EXTENDED_EVENT_DATA = gql`
  mutation UpdateExtendedEventData($id: Int!, $data: ExtendedEventDataInput!) {
    updateExtendedEventData(id: $id, data: $data) {
      ...DetailEventProperties
    }
  }
  ${DETAIL_EVENT_PROPERTIES_FRAGMENT}
`

export const useUpdateExtendedEventData = () => {
  const [updateEvent] = useMutation<
    UpdateExtendedEventDataMutation,
    UpdateExtendedEventDataMutationVariables
  >(UPDATE_EXTENDED_EVENT_DATA)
  return useCallback(
    ({id, data}: UpdateExtendedEventDataMutationVariables) =>
      updateEvent({variables: {id, data}}),
    [updateEvent]
  )
}

export const useDeleteEvent = () => {
  const {from, to} = useGetEventsQueryParams()

  const [deleteEvent] = useMutation<
    DeleteEventMutation,
    DeleteEventMutationVariables
  >(DELETE_EVENT, {
    refetchQueries: () => {
      const getEventsQueryOptions: {
        query: DocumentNode
        variables: EventsQueryVariables
      } = {
        query: GET_EVENTS,
        variables: {filter: {from, to}}
      }
      return [getEventsQueryOptions]
    },
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, {
          __typename: 'Event',
          id: data.deleteEvent
        })
      }
    }
  })
  return useCallback(
    (id: number) => deleteEvent({variables: {id}}),
    [deleteEvent]
  )
}

export const usePublishEvent = () => {
  const [publishEvent] =
    useMutation<PublishEventMutation, PublishEventMutationVariables>(
      PUBLISH_EVENT
    )
  return useCallback(
    (id: number) => publishEvent({variables: {id}}),
    [publishEvent]
  )
}

export const useCancelEvent = () => {
  const [cancelEvent] =
    useMutation<CancelEventMutation, CancelEventMutationVariables>(CANCEL_EVENT)
  return useCallback(
    (id: number) => cancelEvent({variables: {id}}),
    [cancelEvent]
  )
}
