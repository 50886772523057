import {Box} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ECommerceTheme,
  ECommerceThemeBackgroundColor
} from '../../../../../../__generated__/schema'
import {useTranslateECommerceThemeBackgroundColor} from '../../../../../../hooks/translateECommerceThemeBackgroundColor'
import {useIsValidURL} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {ColorBox} from '../../../../../common/ColorBox'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {
  ButtonTarget,
  EcommerceSettingsFormField,
  eCommerceThemeWithHex,
  IEcommerceSettingsForm
} from './types'
import {useTranslateButtonTarget} from './utils'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1)
}))

interface IECommerceSettingsFormProps {
  formId: string
  onSubmit: (data: IEcommerceSettingsForm) => Promise<void>
  defaultValues?: Partial<IEcommerceSettingsForm>
}

export const ECommerceSettingsForm: React.FC<IECommerceSettingsFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IECommerceSettingsFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IEcommerceSettingsForm>({defaultValues, mode: 'onChange'})
  const translateButtonTarget = useTranslateButtonTarget()
  const translateECommerceThemeBackgroundColor =
    useTranslateECommerceThemeBackgroundColor()
  const isValidURL = useIsValidURL(true)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IEcommerceSettingsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Theme')}
            setValue={setValue}
            name={EcommerceSettingsFormField.Theme}
            key={EcommerceSettingsFormField.Theme}
            fullWidth
            hasNoneSelectOption
            selectOptions={Object.values(ECommerceTheme).reduce(
              (acc, theme) => ({
                ...acc,
                [theme]: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5
                    }}
                  >
                    <ColorBox hexColor={eCommerceThemeWithHex[theme]} />
                    {theme}
                  </Box>
                )
              }),
              {}
            )}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IEcommerceSettingsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Background color')}
            setValue={setValue}
            name={EcommerceSettingsFormField.BackgroundColor}
            key={EcommerceSettingsFormField.BackgroundColor}
            fullWidth
            hasNoneSelectOption
            selectOptions={Object.values(ECommerceThemeBackgroundColor).reduce(
              (acc, color) => ({
                ...acc,
                [color]: translateECommerceThemeBackgroundColor(color)
              }),
              {}
            )}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IEcommerceSettingsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Gateway button target')}
            setValue={setValue}
            name={EcommerceSettingsFormField.GatewayButtonTarget}
            key={EcommerceSettingsFormField.GatewayButtonTarget}
            fullWidth
            hasNoneSelectOption
            selectOptions={Object.values(ButtonTarget).reduce(
              (acc, target) => ({
                ...acc,
                [target]: translateButtonTarget(target)
              }),
              {}
            )}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IEcommerceSettingsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Event button target')}
            setValue={setValue}
            name={EcommerceSettingsFormField.EventButtonTarget}
            key={EcommerceSettingsFormField.EventButtonTarget}
            fullWidth
            hasNoneSelectOption
            selectOptions={Object.values(ButtonTarget).reduce(
              (acc, target) => ({
                ...acc,
                [target]: translateButtonTarget(target)
              }),
              {}
            )}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IEcommerceSettingsForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={EcommerceSettingsFormField.CartUrl}
            key={EcommerceSettingsFormField.CartUrl}
            label={t('Cart URL')}
            validationOptions={{
              validate: isValidURL,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IEcommerceSettingsForm>
            name={EcommerceSettingsFormField.ShowCartButton}
            key={EcommerceSettingsFormField.ShowCartButton}
            primaryLabel={t('Show cart button')}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.showCartButton || false}
            secondaryLabel=""
            enabledText=""
            disabledText=""
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IEcommerceSettingsForm>
            name={EcommerceSettingsFormField.EventOverviewEnabled}
            key={EcommerceSettingsFormField.EventOverviewEnabled}
            primaryLabel={t('Event overview enabled')}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.eventOverviewEnabled || false}
            secondaryLabel=""
            enabledText=""
            disabledText=""
          />
        ]}
      />
    </StyledForm>
  )
}
