import {Box, Button} from '@mui/material'
import React from 'react'
import {IButtonProps} from '../editBlockDrawer/types'
import {TypifiedIcon} from '../TypifiedIcon'

export const ButtonPreview: React.FC<IButtonProps> = ({
  label,
  size,
  variant,
  color,
  className,
  iconPosition,
  icon
}: IButtonProps) => (
  <Button size={size} variant={variant} color={color} className={className}>
    <Box
      sx={{
        display: 'flex',
        gap: iconPosition === 'start' ? 0.5 : 0,
        alignItems: iconPosition === 'start' ? 'center' : 'flex-start',
        flexDirection:
          iconPosition === 'top'
            ? 'column'
            : iconPosition === 'end'
            ? 'column-reverse'
            : 'row'
      }}
    >
      {icon && (
        <TypifiedIcon
          iconProps={{
            sx: {fontSize: 18}
          }}
          model={icon}
        />
      )}
      {label}
    </Box>
  </Button>
)
