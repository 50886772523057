import {Button, Tab, Tabs, TextField} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {DatePicker as MuiDatePicker} from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDatePickerError} from '../../../hooks/pickerErrors'
import {LocalizationProvider} from '../../form/pickers'
import {ValidationError} from '../../visual'
import {TabContent} from './TabContent'
import {TabPanel} from './TabPanel'
import {useTranslateDatePickerGroupValues} from './translations'
import {AvailableDatePickerValues, IDataPickerData, TabGroup} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 360,
    minHeight: 512,
    display: 'grid',
    gridTemplateRows: '1fr 64px',
    borderRadius: 4
  },
  footer: {
    padding: theme.spacing(1.5, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(3),
    borderTop: `solid ${theme.palette.divider} 1px`
  },
  tabsWrapper: {
    display: 'flex'
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    paddingTop: theme.spacing(1),
    textAlign: 'left',
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 161
  },
  customTab: {
    padding: theme.spacing(3, 1.5),
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spacing(2)
  },
  keyboardDatePicker: {
    paddingRight: 0
  }
}))

interface IDatePickerProps {
  onCloseButtonClick?: () => void
  onApplyButtonClick: (data: IDataPickerData) => void
  defaultValues?: IDataPickerData
  selectedValues?: IDataPickerData
  groups?: Exclude<TabGroup, TabGroup.Custom>[]
  excludedValues?: AvailableDatePickerValues[]
  minDateTo?: Dayjs
  maxDateTo?: Dayjs
  minDateFrom?: Dayjs
  maxDateFrom?: Dayjs
}

export const DatePicker: React.FC<IDatePickerProps> = ({
  onCloseButtonClick,
  onApplyButtonClick,
  defaultValues,
  selectedValues,
  groups = [],
  excludedValues,
  minDateFrom,
  maxDateFrom,
  minDateTo,
  maxDateTo
}: IDatePickerProps) => {
  const {t} = useTranslation()
  const translateDatePickerGroupValues = useTranslateDatePickerGroupValues()
  const [tab, setTab] = useState<TabGroup>(
    selectedValues?.group ||
      defaultValues?.group ||
      (groups?.length > 0 ? Object.values(groups)[0] : TabGroup.Custom)
  )
  const [selectedItem, setSelectedItem] = useState<
    AvailableDatePickerValues | undefined
  >(selectedValues?.value || defaultValues?.value)
  const [startDate, setStartDate] = useState<Dayjs | null>(
    selectedValues?.dateRange?.dateFrom ||
      defaultValues?.dateRange?.dateFrom ||
      null
  )
  const [endDate, setEndDate] = useState<Dayjs | null>(
    selectedValues?.dateRange?.dateTo ||
      defaultValues?.dateRange?.dateTo ||
      null
  )
  const {
    reason: startReason,
    errorMessage: startErrorMessage,
    onError: onStartDateError
  } = useDatePickerError()
  const {
    reason: endReason,
    errorMessage: endErrorMessage,
    onError: onEndDateError
  } = useDatePickerError()
  const classes = useStyles()
  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, value: TabGroup) => {
      setTab(value)
      setSelectedItem(undefined)
    },
    []
  )
  const handleApplyButtonClick = useCallback(() => {
    onApplyButtonClick({
      group: tab,
      value: tab === TabGroup.Custom ? undefined : selectedItem,
      dateRange:
        tab === TabGroup.Custom && (startDate || endDate)
          ? {dateFrom: startDate, dateTo: endDate}
          : undefined
    })
    if (onCloseButtonClick) {
      onCloseButtonClick()
    }
  }, [
    endDate,
    onApplyButtonClick,
    onCloseButtonClick,
    selectedItem,
    startDate,
    tab
  ])
  const handleResetButtonClick = useCallback(() => {
    onApplyButtonClick({
      group: defaultValues?.group,
      value: defaultValues?.value,
      dateRange: defaultValues?.dateRange
    })
    setTab(defaultValues?.group || TabGroup.Custom)
    setSelectedItem(defaultValues?.value)
    setStartDate(null)
    setEndDate(null)
    if (onCloseButtonClick) {
      onCloseButtonClick()
    }
  }, [
    defaultValues?.dateRange,
    defaultValues?.group,
    defaultValues?.value,
    onApplyButtonClick,
    onCloseButtonClick
  ])
  return (
    <div className={classes.root}>
      <div className={classes.tabsWrapper}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
          value={tab}
          onChange={handleTabChange}
          className={classes.tabs}
          sx={{'& .MuiTabs-flexContainer': {alignItems: 'flex-start'}}}
        >
          {groups.map((group) => (
            <Tab
              key={group}
              label={translateDatePickerGroupValues(group)}
              value={group}
              sx={{alignItems: 'flex-start', width: '100%'}}
            />
          ))}
          <Tab
            label={translateDatePickerGroupValues(TabGroup.Custom)}
            value={TabGroup.Custom}
            sx={{alignItems: 'flex-start', width: '100%'}}
          />
        </Tabs>
        {groups.map((group) => (
          <TabPanel key={group} isVisible={tab === group}>
            <TabContent
              selectedValue={selectedItem}
              setItem={setSelectedItem}
              setTab={setTab}
              group={group}
              excludedValues={excludedValues}
            />
          </TabPanel>
        ))}
        <LocalizationProvider>
          <TabPanel
            className={classes.customTab}
            isVisible={tab === TabGroup.Custom}
          >
            <MuiDatePicker<Dayjs>
              label={t('From')}
              renderInput={(props) => (
                <div>
                  <TextField {...props} helperText={null} />
                  <div>
                    <ValidationError>{startErrorMessage}</ValidationError>
                  </div>
                </div>
              )}
              onError={onStartDateError}
              value={startDate}
              maxDate={endDate || maxDateFrom}
              minDate={
                minDateFrom ||
                (endDate ? endDate.subtract(1, 'year').endOf('d') : undefined)
              }
              onChange={setStartDate}
            />
            <MuiDatePicker<Dayjs>
              label={t('To')}
              renderInput={(props) => (
                <div>
                  <TextField {...props} helperText={null} />
                  <div>
                    <ValidationError>{endErrorMessage}</ValidationError>
                  </div>
                </div>
              )}
              onError={onEndDateError}
              value={endDate}
              minDate={startDate || minDateTo}
              maxDate={
                maxDateTo ||
                (startDate ? startDate.add(1, 'year').endOf('d') : undefined)
              }
              onChange={setEndDate}
            />
          </TabPanel>
        </LocalizationProvider>
      </div>
      <div className={classes.footer}>
        {onCloseButtonClick && (
          <Button variant="text" color="primary" onClick={onCloseButtonClick}>
            {t('Close')}
          </Button>
        )}
        <Button
          variant="text"
          color="primary"
          onClick={handleResetButtonClick}
          disabled={
            defaultValues?.dateRange?.dateFrom === startDate &&
            defaultValues?.dateRange?.dateTo === endDate &&
            selectedItem === defaultValues?.value
          }
        >
          {t('Reset')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyButtonClick}
          disabled={
            tab === TabGroup.Custom
              ? Boolean(startReason || endReason) ||
                (!startDate && !endDate) ||
                (selectedValues?.dateRange?.dateFrom === startDate &&
                  selectedValues?.dateRange?.dateTo === endDate)
              : (!tab && !selectedItem) ||
                selectedValues?.value === selectedItem ||
                !selectedItem
          }
        >
          {t('Apply')}
        </Button>
      </div>
    </div>
  )
}
