import {
  Checkbox,
  CheckboxProps,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import React from 'react'

interface IClientRowListItemProps {
  clientName: string
  checkBoxProps?: CheckboxProps
}

export const ClientRowListItem: React.FC<IClientRowListItemProps> = ({
  clientName,
  checkBoxProps
}: IClientRowListItemProps) => (
  <ListItem
    sx={{
      minHeight: 40,
      p: 0,
      borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
      '&:last-child': {
        borderBottom: 0
      }
    }}
  >
    <ListItemIcon>
      <Checkbox {...checkBoxProps} color="primary" />
    </ListItemIcon>
    <ListItemText
      sx={{overflowWrap: 'anywhere'}}
      primary={clientName}
      primaryTypographyProps={{variant: 'body1'}}
    />
  </ListItem>
)
