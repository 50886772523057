import dayjs from 'dayjs'
import {TFunction} from 'i18next'
import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useDateTimeFormatters} from '../../../utils/formatting'
import {DATERANGE_IDS, PREDICTABLE_DATERANGE_IDS} from './types'

const mapDateRangeToString = (
  fromDatetime: string,
  toDatetime: string
): string =>
  fromDatetime === toDatetime
    ? fromDatetime
    : [fromDatetime, toDatetime].join('-')

interface IUseDateRangeSearchProps<T> {
  dateRangeInputPrefix: string
  usedDateranges: PREDICTABLE_DATERANGE_IDS[]
  getDateRangeFromSearchObject: (searchObject: T) => {
    startDateISOString: string | undefined
    endDateISOString: string | undefined
    id: DATERANGE_IDS | undefined
  }
  mapDaterangeValuesToSearchObject: (
    searchObject: T,
    input: {
      startDate: string
      endDate: string
      id: DATERANGE_IDS
    }
  ) => T
}

export interface IDaterangeOption {
  id: DATERANGE_IDS
  label: string
  startDate: Date
  endDate: Date
}

export interface IUseDateRangeSearch<T> {
  daterangeOptions: IDaterangeOption[]
  mapCustomDaterangeToSearchObject: (
    searchObject: T,
    input: {startDate: Date; endDate: Date}
  ) => T
  mapDaterangeToSearchObject: (
    searchObject: T,
    daterangeId: PREDICTABLE_DATERANGE_IDS
  ) => T
  getStringifiedDaterangeFromSearchObject: (
    searchObject: T
  ) => string | undefined
  getIsDaterangeOptionActive: (searchObject: T, id: DATERANGE_IDS) => boolean
}

const getThisWeekendDaterangeData = (t: TFunction) => ({
  id: DATERANGE_IDS.THIS_WEEKEND,
  label: t('This weekend'),
  startDate: dayjs().endOf('week').subtract(1, 'day').toDate(),
  endDate: dayjs().endOf('week').toDate()
})

const getThisWeekDaterangeData = (t: TFunction) => ({
  id: DATERANGE_IDS.THIS_WEEK,
  label: t('This week'),
  startDate: dayjs().startOf('week').toDate(),
  endDate: dayjs().endOf('week').toDate()
})

export const useDateRangeSearch = <T extends object>({
  usedDateranges,
  mapDaterangeValuesToSearchObject,
  getDateRangeFromSearchObject,
  dateRangeInputPrefix
}: IUseDateRangeSearchProps<T>): IUseDateRangeSearch<T> => {
  const {t} = useTranslation()
  const allDateRanges = useMemo<
    Record<PREDICTABLE_DATERANGE_IDS, IDaterangeOption>
  >(() => {
    const thisWeekend = getThisWeekendDaterangeData(t)
    const thisWeek = getThisWeekDaterangeData(t)
    return {
      [DATERANGE_IDS.TODAY]: {
        id: DATERANGE_IDS.TODAY,
        label: t('Today'),
        startDate: dayjs().startOf('day').toDate(),
        endDate: dayjs().endOf('day').toDate()
      },
      [DATERANGE_IDS.TOMORROW]: {
        id: DATERANGE_IDS.TOMORROW,
        label: t('Tomorrow'),
        startDate: dayjs().startOf('day').add(1, 'day').toDate(),
        endDate: dayjs().endOf('day').add(1, 'day').toDate()
      },
      [DATERANGE_IDS.YESTERDAY]: {
        id: DATERANGE_IDS.YESTERDAY,
        label: t('Yesterday'),
        startDate: dayjs().startOf('day').add(-1, 'day').toDate(),
        endDate: dayjs().endOf('day').add(-1, 'day').toDate()
      },
      [DATERANGE_IDS.THIS_WEEK]: thisWeek,
      [DATERANGE_IDS.NEXT_7_DAYS]: {
        id: DATERANGE_IDS.NEXT_7_DAYS,
        label: t('Next 7 days'),
        startDate: dayjs().startOf('day').toDate(),
        endDate: dayjs().startOf('day').add(7, 'day').toDate()
      },
      [DATERANGE_IDS.NEXT_30_DAYS]: {
        id: DATERANGE_IDS.NEXT_30_DAYS,
        label: t('Next 30 days'),
        startDate: dayjs().startOf('day').toDate(),
        endDate: dayjs().startOf('day').add(30, 'day').toDate()
      },
      [DATERANGE_IDS.THIS_WEEKEND]: thisWeekend,
      [DATERANGE_IDS.NEXT_WEEKEND]: {
        id: DATERANGE_IDS.NEXT_WEEKEND,
        label: t('Next weekend'),
        startDate: dayjs(thisWeekend.startDate).add(7, 'day').toDate(),
        endDate: dayjs(thisWeekend.endDate).add(7, 'day').toDate()
      },
      [DATERANGE_IDS.NEXT_WEEK]: {
        id: DATERANGE_IDS.NEXT_WEEK,
        label: t('Next week'),
        startDate: dayjs(thisWeek.startDate).add(7, 'day').toDate(),
        endDate: dayjs(thisWeek.endDate).add(7, 'day').toDate()
      },
      [DATERANGE_IDS.LAST_WEEK]: {
        id: DATERANGE_IDS.LAST_WEEK,
        label: t('Last week'),
        startDate: dayjs(thisWeek.startDate).add(-7, 'day').toDate(),
        endDate: dayjs(thisWeek.endDate).add(-7, 'day').toDate()
      },
      [DATERANGE_IDS.LAST_7_DAYS]: {
        id: DATERANGE_IDS.LAST_7_DAYS,
        label: t('Last 7 days'),
        startDate: dayjs().startOf('day').add(-6, 'day').toDate(),
        endDate: dayjs().endOf('day').toDate()
      },
      [DATERANGE_IDS.LAST_30_DAYS]: {
        id: DATERANGE_IDS.LAST_30_DAYS,
        label: t('Last 30 days'),
        startDate: dayjs().startOf('day').add(-29, 'day').toDate(),
        endDate: dayjs().endOf('day').toDate()
      },
      [DATERANGE_IDS.THIS_MONTH]: {
        id: DATERANGE_IDS.THIS_MONTH,
        label: t('This month'),
        startDate: dayjs().startOf('month').toDate(),
        endDate: dayjs().endOf('month').toDate()
      },
      [DATERANGE_IDS.NEXT_MONTH]: {
        id: DATERANGE_IDS.NEXT_MONTH,
        label: t('Next month'),
        startDate: dayjs().startOf('month').add(1, 'month').toDate(),
        endDate: dayjs().endOf('month').add(1, 'month').toDate()
      },
      [DATERANGE_IDS.TWO_DAYS_AGO]: {
        id: DATERANGE_IDS.TWO_DAYS_AGO,
        label: t('2 days ago'),
        startDate: dayjs().startOf('day').subtract(2, 'day').toDate(),
        endDate: dayjs().endOf('day').subtract(2, 'day').toDate()
      },
      [DATERANGE_IDS.THIS_QUARTER]: {
        id: DATERANGE_IDS.THIS_QUARTER,
        label: t('This quarter'),
        startDate: dayjs().startOf('quarter').toDate(),
        endDate: dayjs().endOf('quarter').toDate()
      },
      [DATERANGE_IDS.LAST_QUARTER]: {
        id: DATERANGE_IDS.LAST_QUARTER,
        label: t('Last quarter'),
        startDate: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
        endDate: dayjs().subtract(1, 'quarter').endOf('quarter').toDate()
      },
      [DATERANGE_IDS.LAST_MONTH]: {
        id: DATERANGE_IDS.LAST_MONTH,
        label: t('Last month'),
        startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toDate()
      }
    }
  }, [t])
  const daterangeOptions = useMemo(() => {
    return usedDateranges.map((id) => allDateRanges[id])
  }, [allDateRanges, usedDateranges])
  const mapCustomDaterangeToSearchObject = useCallback(
    (searchObject: T, input: {startDate: Date; endDate: Date}): T =>
      mapDaterangeValuesToSearchObject(searchObject, {
        startDate: input.startDate.toISOString(),
        endDate: input.endDate.toISOString(),
        id: DATERANGE_IDS.CUSTOM_DATERANGE
      }),
    [mapDaterangeValuesToSearchObject]
  )

  const {formatDateNumeric} = useDateTimeFormatters()

  const getStringifiedDaterangeFromSearchObject = useCallback(
    (searchObject: T): string | undefined => {
      const {startDateISOString, endDateISOString} =
        getDateRangeFromSearchObject(searchObject)
      return (
        startDateISOString &&
        endDateISOString &&
        `${dateRangeInputPrefix}: ${mapDateRangeToString(
          formatDateNumeric(new Date(startDateISOString)),
          formatDateNumeric(new Date(endDateISOString))
        )}`
      )
    },
    [dateRangeInputPrefix, formatDateNumeric, getDateRangeFromSearchObject]
  )

  const mapDaterangeToSearchObject = useCallback(
    (searchObject, id: PREDICTABLE_DATERANGE_IDS) => {
      const startDate = allDateRanges[id].startDate.toISOString()
      const endDate = allDateRanges[id].endDate.toISOString()
      return mapDaterangeValuesToSearchObject(searchObject, {
        id,
        startDate,
        endDate
      })
    },
    [allDateRanges, mapDaterangeValuesToSearchObject]
  )
  const getIsDaterangeOptionActive = useCallback(
    (searchObject: T, id): boolean =>
      getDateRangeFromSearchObject(searchObject).id === id,
    [getDateRangeFromSearchObject]
  )
  return {
    daterangeOptions,
    mapCustomDaterangeToSearchObject,
    mapDaterangeToSearchObject,
    getStringifiedDaterangeFromSearchObject,
    getIsDaterangeOptionActive
  }
}
