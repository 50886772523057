import {EditorMode} from './redux/editorMode/reducer'
import rotateCursor from './assets/rotate-cursor.png'

export const cursorMap = {
  [EditorMode.DRAW]: {
    default: 'crosshair'
  },
  [EditorMode.PAN]: {
    default: 'grab',
    mouseDown: 'grabbing'
  },
  [EditorMode.RESERVATION]: {
    default: 'default'
  },
  [EditorMode.SELECT]: {
    default: 'default',
    overSelectedObject: 'move'
  },
  [EditorMode.TRANSFORM]: {
    default: 'default',
    rotation: `url(${rotateCursor}) 16 18, auto`
  }
}
