import {ShowVideoSource} from '../../../__generated__/schema'

export enum AddVideoFormField {
  Url = 'url',
  Provider = 'provider'
}

export interface IAddVideoForm {
  [AddVideoFormField.Url]: string
  [AddVideoFormField.Provider]?: ShowVideoSource
}

export interface IAddVideoFormResult {
  identifier: string
  source: ShowVideoSource
}
