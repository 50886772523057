import AddIcon from '@mui/icons-material/Add'
import {Button, ButtonProps, Fab, FabProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Theme} from '../../theme'

export const CancelButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}: ButtonProps) => {
  const {t} = useTranslation()
  return (
    <Button color="primary" {...rest}>
      {children || t('Cancel')}
    </Button>
  )
}

export const SaveButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}: ButtonProps) => {
  const {t} = useTranslation()
  return (
    <Button color="primary" variant="contained" {...rest}>
      {children || t('Save')}
    </Button>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  createButtonRoot: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  },
  createButtonLabel: {
    display: 'grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'column'
  }
}))

export const useFabClasses = () => {
  const classes = useStyles()
  return {
    root: classes.createButtonRoot,
    label: classes.createButtonLabel
  }
}

interface ICreateFabProps extends Omit<FabProps, 'children'> {
  children?: FabProps['children']
}

export const CreateFab = ({children, ...props}: ICreateFabProps) => {
  const {t} = useTranslation()
  return (
    <Fab variant="extended" color="primary" {...props}>
      {children || (
        <>
          <AddIcon />
          {t('Create')}
        </>
      )}
    </Fab>
  )
}
