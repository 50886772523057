import {Dialog} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Carousel from 'nuka-carousel'
import React, {useCallback, useState} from 'react'
import {Theme} from '../../../theme'
import {CenterLeftControls, CenterRightControls} from './PreviewControls'

export interface IGalleryPreviewPhoto {
  id: number
  src: string
}

interface IGalleryPreviewHookProps {
  openPreview: (photoId: number) => void
  closePreview: () => void
  currentPhotoId: number | null
  /**
   * currentPhotoIndex is -1, if not available
   */
  currentPhotoIndex: number
  photos: IGalleryPreviewPhoto[]
  onCurrentPhotoIndexChange: (slideIndex: number) => void
}

export const useGalleryPreview = (
  photos: IGalleryPreviewPhoto[]
): IGalleryPreviewHookProps => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(-1)
  const openPreview = useCallback(
    (photoId: number) => {
      setCurrentPhotoIndex(photos.findIndex((p) => p.id === photoId))
    },
    [photos]
  )
  const closePreview = useCallback(() => {
    setCurrentPhotoIndex(-1)
  }, [])
  const onCurrentPhotoIndexChange = useCallback((slideIndex: number) => {
    setCurrentPhotoIndex(slideIndex)
  }, [])
  return {
    currentPhotoId:
      currentPhotoIndex > -1 && currentPhotoIndex < photos.length
        ? photos[currentPhotoIndex].id
        : null,
    openPreview,
    closePreview,
    photos,
    onCurrentPhotoIndexChange,
    currentPhotoIndex
  }
}
interface IGalleryPreviewProps extends IGalleryPreviewHookProps {
  header?: React.ReactNode
}

const useStyles = makeStyles<Theme>(() => ({
  headerRoot: {
    width: '100vw'
  },
  image: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  imageWrapper: {
    height: '100vh',
    width: '100vw',
    background: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const GalleryPreview: React.FC<IGalleryPreviewProps> = ({
  currentPhotoId,
  photos,
  currentPhotoIndex,
  header,
  onCurrentPhotoIndexChange
}: IGalleryPreviewProps) => {
  const classes = useStyles()
  const renderCenterLeftControls = useCallback(
    (props) => <CenterLeftControls {...props} />,
    []
  )
  const renderCenterRightControls = useCallback(
    (props) => <CenterRightControls {...props} />,
    []
  )
  return (
    <Dialog open={Boolean(currentPhotoId)} fullScreen>
      <Carousel
        transitionMode="fade"
        dragging={false}
        renderBottomCenterControls={null}
        slideIndex={currentPhotoIndex}
        renderTopCenterControls={
          header
            ? () => <div className={classes.headerRoot}>{header}</div>
            : null
        }
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        afterSlide={onCurrentPhotoIndexChange}
      >
        {photos.map((photo) => (
          <div key={photo.id} className={classes.imageWrapper}>
            <img src={photo.src} alt={photo.src} className={classes.image} />
          </div>
        ))}
      </Carousel>
    </Dialog>
  )
}
