import {FeZoneState} from '@attendio/shared-components'
import {ApiSeatState} from '../__generated__/schema'

export const getFeZoneStateInEventSeatOptions = (
  zoneStatesWithCounts?: {
    [keys in ApiSeatState]: number
  }
): FeZoneState => {
  if (zoneStatesWithCounts) {
    const {
      available = 0,
      selectedByMe = 0,
      disabled = 0,
      hidden = 0
    } = zoneStatesWithCounts

    if (selectedByMe > 0) {
      return FeZoneState.Selected
    }
    if (available + disabled + hidden > 0) {
      return FeZoneState.Available
    }
    return FeZoneState.UnAvailable
  }
  return FeZoneState.Available
}

export const getFeZoneStateInEventAuditoriumPreview = (
  zoneStatesWithCounts?: {
    [keys in ApiSeatState]: number
  }
): FeZoneState => {
  if (zoneStatesWithCounts) {
    const {
      [ApiSeatState.Available]: available = 0,
      [ApiSeatState.AddedToMyCart]: addedToMyCart = 0
    } = zoneStatesWithCounts
    if (addedToMyCart > 0) {
      return FeZoneState.Selected
    }
    if (available && available > 0) {
      return FeZoneState.Available
    }
    return FeZoneState.UnAvailable
  }
  return FeZoneState.UnAvailable
}

export const getFeZoneStateInReservationAuditoriumPreview = (
  zoneStatesWithCounts?: {
    [keys in ApiSeatState]: number
  }
): FeZoneState => {
  if (zoneStatesWithCounts) {
    const {
      [ApiSeatState.Available]: available = 0,
      [ApiSeatState.ReservedInMyCart]: reservedInMyCart = 0
    } = zoneStatesWithCounts
    if (available && available > 0) {
      if (reservedInMyCart > 0) {
        return FeZoneState.Selected
      }
      return FeZoneState.Available
    }
    return FeZoneState.UnAvailable
  }
  return FeZoneState.UnAvailable
}
