import {useBackdropState} from '../components/context/backdrop'
import {useNotifications} from '../components/context/notifications'
import {useCustomErrorHandler, useDefaultErrorHandler} from '../utils/errors'

export const useMutationAssistanceHooks = () => {
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()
  const customErrorHandler = useCustomErrorHandler()
  return {
    defaultErrorHandler,
    setShowBackdrop,
    addInfoNotification,
    customErrorHandler
  }
}
