import {useTranslation} from 'react-i18next'
import {PaymentGatewayEnvironment} from '../../../../__generated__/schema'

export const useTranslatePaymentGatewayEnvironment = () => {
  const {t} = useTranslation()
  return (environment: PaymentGatewayEnvironment) => {
    switch (environment) {
      case PaymentGatewayEnvironment.Sandbox:
        return t('Sandbox')
      case PaymentGatewayEnvironment.Production:
        return t('Production')
      default:
        return ''
    }
  }
}
