import GetAppIcon from '@mui/icons-material/GetApp'
import PrintIcon from '@mui/icons-material/Print'
import {Box, Button} from '@mui/material'
import {gridClasses} from '@mui/x-data-grid'
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import {isNil, subtract} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AggregatedEventStatisticsByItemPriceQuery,
  DiscountType
} from '../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../hooks/formatDiscountValue'
import {
  useTranslatedEffectiveClientCurrencySign,
  useTranslateEffectiveClientPrice
} from '../../../hooks/translateCurrencies'
import {safeSum} from '../../../utils/money'
import {useEventsPathnameParams} from '../../../utils/pathname'
import {DataGridTable, useDecimalFormatter} from '../DataGridTable'
import {PagePDFHeader} from '../PagePDFHeader'
import {RenderOnData} from '../RenderOnData'
import {SubHeaderToolbar} from '../SubHeaderToolbar'
import {useGetAggregatedEventStatisticsByItemPrice} from './graphql'
import {StatCard} from './StatCard'

const getCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''

const useDiscountValueFormatter = () => {
  const formatDiscountValue = useFormatDiscountValue(true)
  return (params: GridValueFormatterParams, discountType?: DiscountType) =>
    params.value && discountType
      ? formatDiscountValue({value: params.value, type: discountType})
      : ''
}

interface IEventSalesReportDrawerContentProps {
  headerTitle: string
}

export const EventSalesReportDrawerContent: React.FC<IEventSalesReportDrawerContentProps> =
  ({headerTitle}: IEventSalesReportDrawerContentProps) => {
    const {t} = useTranslation()
    const {eventId} = useEventsPathnameParams()
    const {data, error, loading} = useGetAggregatedEventStatisticsByItemPrice({
      eventId
    })
    const dataGridApiRef = useGridApiRef()
    const translatedEffectiveClientCurrencySign =
      useTranslatedEffectiveClientCurrencySign()
    const discountValueFormatter = useDiscountValueFormatter()
    const decimalFormatter2FractionDigits = useDecimalFormatter()
    const decimalFormatterNoFractionDigits = useDecimalFormatter(0)
    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
    const columns: GridColDef[] = useMemo(
      () => [
        {
          headerName: t('Type'),
          field: 'ticketTypeName',
          cellClassName: getCellClassName
        },
        {
          headerName: t('Price ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'itemPriceBeforeDiscount',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Name'),
          field: 'discountName',
          cellClassName: getCellClassName
        },
        {
          headerName: t('Value'),
          field: 'discountValue',
          valueFormatter: (params) =>
            params.id &&
            discountValueFormatter(
              params,
              params.api.getRow(params.id).discountType
            ),
          cellClassName: getCellClassName
        },
        {
          headerName: t('Final price ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'itemPrice',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Quantity'),
          field: 'saleTicketCountOnRetailChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatterNoFractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Amount ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'saleSumOnRetailChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Quantity'),
          field: 'saleTicketCountOnEcommerceChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatterNoFractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Amount ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'saleSumOnEcommerceChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Quantity'),
          field: 'refundTicketCountOnRetailChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatterNoFractionDigits,
          minWidth: 100,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Amount ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'refundSumOnRetailChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Quantity'),
          field: 'refundTicketCountOnEcommerceChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatterNoFractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Amount ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'refundSumOnEcommerceChannel',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Quantity'),
          field: 'profitTicketCount',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatterNoFractionDigits,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Amount ({{currencySign}})', {
            currencySign: translatedEffectiveClientCurrencySign
          }),
          field: 'profit',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter2FractionDigits,
          cellClassName: getCellClassName
        }
      ],
      [
        decimalFormatter2FractionDigits,
        decimalFormatterNoFractionDigits,
        discountValueFormatter,
        t,
        translatedEffectiveClientCurrencySign
      ]
    )
    return (
      <RenderOnData<AggregatedEventStatisticsByItemPriceQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>(
          'Error while loading aggregated event statistics'
        )}
        dataCondition={(data) =>
          Boolean(data.aggregatedEventStatisticsByItemPrice)
        }
        ignoreLoadingIfData
      >
        {({aggregatedEventStatisticsByItemPrice: stats}) => (
          <Box
            sx={{
              height: '100%'
            }}
          >
            <SubHeaderToolbar
              title={t('Event sales report')}
              rightActions={[
                <Button
                  key="print"
                  variant="text"
                  color="primary"
                  startIcon={<PrintIcon />}
                  onClick={() =>
                    dataGridApiRef.current.exportDataAsPrint({
                      fileName: t('event_sales_report_{{eventId}}', {eventId}),
                      hideFooter: true
                    })
                  }
                >
                  {t('Print')}
                </Button>,
                <Button
                  key="download"
                  onClick={() =>
                    dataGridApiRef.current.exportDataAsCsv({
                      fileName: t('event_sales_report_{{eventId}}', {eventId})
                    })
                  }
                  variant="text"
                  color="primary"
                  startIcon={<GetAppIcon />}
                >
                  {t('Download')}
                </Button>
              ]}
            />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                alignItems: 'stretch',
                px: 3,
                pt: 3
              }}
            >
              <StatCard title={t('Total')}>
                {[
                  t('{{count}} tickets', {
                    count: stats.profitTicketCount
                  }),
                  translateEffectiveClientPrice(stats.profit)
                ].join(' • ')}
              </StatCard>
              <StatCard title={t('Total sales')}>
                {[
                  t('{{count}} tickets', {
                    count: safeSum([
                      stats.saleTicketCountOnRetailChannel,
                      stats.saleTicketCountOnEcommerceChannel
                    ])
                  }),
                  translateEffectiveClientPrice(
                    safeSum([
                      stats.saleSumOnRetailChannel,
                      stats.saleSumOnEcommerceChannel
                    ])
                  )
                ].join(' • ')}
              </StatCard>
              <StatCard title={t('Total refunds')}>
                {[
                  t('{{count}} tickets', {
                    count: safeSum([
                      stats.refundTicketCountOnRetailChannel,
                      stats.refundTicketCountOnEcommerceChannel
                    ])
                  }),
                  translateEffectiveClientPrice(
                    safeSum([
                      stats.refundSumOnRetailChannel,
                      stats.refundSumOnEcommerceChannel
                    ])
                  )
                ].join(' • ')}
              </StatCard>
              <StatCard title={t('Total retail')}>
                {[
                  t('{{count}} tickets', {
                    count: subtract(
                      stats.saleTicketCountOnRetailChannel,
                      stats.refundTicketCountOnRetailChannel
                    )
                  }),
                  translateEffectiveClientPrice(
                    subtract(
                      stats.saleSumOnRetailChannel,
                      stats.refundSumOnRetailChannel
                    )
                  )
                ].join(' • ')}
              </StatCard>
              <StatCard title={t('Total ecommerce')}>
                {[
                  t('{{count}} tickets', {
                    count: subtract(
                      stats.saleTicketCountOnEcommerceChannel,
                      stats.refundTicketCountOnEcommerceChannel
                    )
                  }),
                  translateEffectiveClientPrice(
                    subtract(
                      stats.saleSumOnEcommerceChannel,
                      stats.refundSumOnEcommerceChannel
                    )
                  )
                ].join(' • ')}
              </StatCard>
            </Box>
            <Box sx={{height: 'calc(100% - 152px)', width: '100%', p: 3}}>
              <DataGridTable
                sx={{
                  '@media print': {
                    '@page': {
                      size: 'landscape'
                    },
                    [`& .${gridClasses.main}`]: {
                      width: 'fit-content',
                      fontSize: '10px',
                      height: 'fit-content',
                      overflow: 'visible'
                    }
                  },
                  [`& .${gridClasses.withBorderColor}`]: {
                    borderColor: 'divider'
                  },
                  [`& .${gridClasses.columnSeparator}`]: {
                    color: 'divider'
                  },
                  '& .bold': {fontWeight: 'bold'}
                }}
                slots={{toolbar: PagePDFHeader}}
                slotProps={{
                  toolbar: {
                    title: t('Event sales report'),
                    subTitle: headerTitle,
                    informations: [
                      {
                        title: t('Venue'),
                        value: [
                          stats.venue.name,
                          stats.venue.address.complex,
                          stats.venue.address.street,
                          stats.venue.address.postalCode,
                          stats.venue.address.town,
                          stats.venue.address.country
                        ]
                          .filter(Boolean)
                          .join(' • ')
                      },
                      {
                        title: t('Division'),
                        value: [
                          stats.division.name,
                          stats.division.address.complex,
                          stats.division.address.street,
                          stats.division.address.postalCode,
                          stats.division.address.town,
                          stats.division.address.country
                        ]
                          .filter(Boolean)
                          .join(' • ')
                      },
                      {
                        title: t('Company'),
                        value: [
                          stats.division.client.name,
                          stats.division.client.legalAddress.complex,
                          stats.division.client.legalAddress.street,
                          stats.division.client.legalAddress.postalCode,
                          stats.division.client.legalAddress.town,
                          stats.division.client.legalAddress.country
                        ]
                          .filter(Boolean)
                          .join(' • ')
                      }
                    ]
                  }
                }}
                apiRef={dataGridApiRef}
                columns={columns}
                rows={stats.aggregatedStatistics}
                pageSizeOptions={[10, 30, 50]}
                initialState={{
                  pagination: {paginationModel: {pageSize: 30}},
                  pinnedColumns: {left: ['ticketTypeName']}
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                columnHeaderHeight={32}
                experimentalFeatures={{columnGrouping: true}}
                columnGroupingModel={[
                  {
                    groupId: t('Ticket'),
                    children: [
                      {
                        field: 'itemPriceBeforeDiscount'
                      },
                      {
                        groupId: t('Discount'),
                        children: [
                          {
                            field: 'discountName'
                          },
                          {
                            field: 'discountValue'
                          }
                        ]
                      },
                      {
                        field: 'itemPrice'
                      }
                    ]
                  },
                  {
                    groupId: t('Sales'),
                    children: [
                      {
                        groupId: t('Retail'),
                        children: [
                          {
                            field: 'saleTicketCountOnRetailChannel'
                          },
                          {field: 'saleSumOnRetailChannel'}
                        ]
                      },
                      {
                        groupId: t('Ecommerce'),
                        children: [
                          {
                            field: 'saleTicketCountOnEcommerceChannel'
                          },
                          {field: 'saleSumOnEcommerceChannel'}
                        ]
                      }
                    ]
                  },
                  {
                    groupId: t('Refunds'),
                    children: [
                      {
                        groupId: t('Retail'),
                        children: [
                          {
                            field: 'refundTicketCountOnRetailChannel'
                          },
                          {field: 'refundSumOnRetailChannel'}
                        ]
                      },
                      {
                        groupId: t('Ecommerce'),
                        children: [
                          {
                            field: 'refundTicketCountOnEcommerceChannel'
                          },
                          {field: 'refundSumOnEcommerceChannel'}
                        ]
                      }
                    ]
                  },
                  {
                    groupId: t('Summary'),
                    children: [
                      {
                        field: 'profitTicketCount'
                      },
                      {field: 'profit'}
                    ]
                  }
                ]}
                pinnedRows={{
                  bottom: [
                    {
                      id: '0',
                      ticketTypeName: t('Summary'),
                      discountName: '',
                      discountValue: '',
                      saleTicketCountOnRetailChannel:
                        stats.saleTicketCountOnRetailChannel,
                      saleSumOnRetailChannel: stats.saleSumOnRetailChannel,
                      saleTicketCountOnEcommerceChannel:
                        stats.saleTicketCountOnEcommerceChannel,
                      saleSumOnEcommerceChannel:
                        stats.saleSumOnEcommerceChannel,
                      refundTicketCountOnRetailChannel:
                        stats.refundTicketCountOnRetailChannel,
                      refundSumOnRetailChannel: stats.refundSumOnRetailChannel,
                      refundTicketCountOnEcommerceChannel:
                        stats.refundTicketCountOnEcommerceChannel,
                      refundSumOnEcommerceChannel:
                        stats.refundSumOnEcommerceChannel,
                      profitTicketCount: stats.profitTicketCount,
                      profit: stats.profit
                    }
                  ]
                }}
                localeText={{noRowsLabel: t('No payments to show')}}
                disableAutosize={false}
                autosizeOnMount
                autosizeOptions={{
                  includeHeaders: true,
                  includeOutliers: true,
                  expand: true
                }}
              />
            </Box>
          </Box>
        )}
      </RenderOnData>
    )
  }
