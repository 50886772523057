import {Box} from '@mui/material'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  TourTimeSlotsStatisticsFilterInput
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PersistentDrawerMenu} from '../../../common/PersistentDrawerMenu'
import {PersistentDrawerWithContent} from '../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {AdmissionTypesStatistics} from './admissionTypesStatistics'
import {Availability} from './Availability'
import {Revenues} from './Revenues'
import {TourTimeSlotsStatisticsSearch} from './TourTimeSlotsStatisticsSearch'
import {Turnovers} from './Turnovers'

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      revenues: {id: string; label: string; route: string}
      turnovers: {id: string; label: string; route: string}
      availability: {id: string; label: string; route: string}
    } => ({
      revenues: {
        id: 'revenues',
        label: t('Revenues'),
        route: routeTo.admin.tourTimeSlotsStatistics.revenues()
      },
      turnovers: {
        id: 'turnovers',
        label: t('Turnovers'),
        route: routeTo.admin.tourTimeSlotsStatistics.turnovers()
      },
      availability: {
        id: 'availability',
        label: t('Availability'),
        route: routeTo.admin.tourTimeSlotsStatistics.availability()
      }
    }),
    [t]
  )
}

interface IDrawerContentProps {
  searchFilter: TourTimeSlotsStatisticsFilterInput
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  searchFilter
}: IDrawerContentProps) => (
  <Switch>
    <Route path={routeTo.admin.tourTimeSlotsStatistics.revenues()}>
      <Revenues searchFilter={searchFilter} />
    </Route>
    <Route path={routeTo.admin.tourTimeSlotsStatistics.turnovers()}>
      <Turnovers searchFilter={searchFilter} />
    </Route>
    <Route path={routeTo.admin.tourTimeSlotsStatistics.availability()}>
      <Availability searchFilter={searchFilter} />
    </Route>
  </Switch>
)

export const TourTimeSlotsStatistics: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const navigationItems = useNavigationItems()
  const [searchFilter, setSearchFilter] =
    useState<TourTimeSlotsStatisticsFilterInput>({})
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(true)
  const handleExited = useCallback(
    () => history.push(routeTo.admin.tourTimeSlotsStatistics.index()),
    [history]
  )
  return (
    <Switch>
      <Route path={routeTo.admin.tourTimeSlotsStatistics.index()} exact>
        <Redirect to={routeTo.admin.tourTimeSlotsStatistics.revenues()} />
      </Route>
      <Route
        path={[
          routeTo.admin.tourTimeSlotsStatistics.revenues(),
          routeTo.admin.tourTimeSlotsStatistics.turnovers(),
          routeTo.admin.tourTimeSlotsStatistics.availability()
        ]}
      >
        <PageWithHeaderTemplate
          header={
            <PrimaryHeader
              title={t('Time slots statistics')}
              search={
                <TourTimeSlotsStatisticsSearch
                  onFilterChange={setSearchFilter}
                />
              }
            />
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <Box sx={{display: 'grid', height: '100%'}}>
              <PersistentDrawerWithContent
                content={<DrawerContent searchFilter={searchFilter} />}
                drawerContent={<PersistentDrawerMenu items={navigationItems} />}
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
              />
            </Box>
          </ChildrenOnEffectiveClientSelected>
        </PageWithHeaderTemplate>
      </Route>
      {P([PermissionCode.ReadAdmissionTypesStatistics]) && (
        <Route
          path={routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatistics(
            ':tourTimeSlotId'
          )}
        >
          <AdmissionTypesStatistics onExited={handleExited} />
        </Route>
      )}
    </Switch>
  )
}
