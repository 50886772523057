import React from 'react'
import {GeneralFilterState} from './reducer'

interface IChildrenOnVisibleSectionProps<T extends object> {
  visibleSections: GeneralFilterState<T>['visibleSections']
  filterKey: keyof T
  children: React.ReactNode
}

export const ChildrenOnVisibleSection = <T extends object>({
  visibleSections,
  filterKey,
  children
}: IChildrenOnVisibleSectionProps<T>) =>
  visibleSections.includes(filterKey) ? <>{children}</> : null
