import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {IconButton, IconButtonProps, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {isNil} from 'lodash'
import React from 'react'
import {Theme} from '../../theme'

export interface IQuantityControllerProps {
  quantity: number
  minQuantity?: number
  maxQuantity?: number
  onClick?: () => void
  DecrementButtonProps: IconButtonProps
  IncrementButtonProps: IconButtonProps
  className?: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'inline-grid',
    gridAutoFlow: 'column'
  },
  paper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    minWidth: 66,
    cursor: 'pointer'
  }
}))

export const QuantityController: React.FC<IQuantityControllerProps> = ({
  quantity,
  onClick,
  DecrementButtonProps,
  IncrementButtonProps,
  minQuantity = 0,
  maxQuantity,
  className
}: IQuantityControllerProps) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.root, className)}>
      <IconButton
        disabled={quantity <= minQuantity}
        color="primary"
        {...DecrementButtonProps}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
      <Paper variant="outlined" className={classes.paper} onClick={onClick}>
        <Typography variant="body1">{quantity}</Typography>
      </Paper>
      <IconButton
        disabled={!isNil(maxQuantity) ? quantity >= maxQuantity : undefined}
        color="primary"
        {...IncrementButtonProps}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  )
}
