import CloseIcon from '@mui/icons-material/Close'
import DialpadIcon from '@mui/icons-material/Dialpad'
import ViewListIcon from '@mui/icons-material/ViewList'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import {
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {ChangeEvent, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  ProductsFilterInput
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {ShopViewMode} from '../../../../../types'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {VerticalDivider} from '../../../../common/VerticalDivider'
import {ShopSearch} from './ShopSearch'
import {CodeFormulaErrorMessage} from './types'
import {validatePluCode} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderBottom: `solid ${theme.palette.divider} 1px`,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(2),
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1, 3)
  },
  hidden: {
    visibility: 'hidden'
  },
  search: {
    width: '100%'
  },
  toggleButton: {
    '&.Mui-selected': {
      color: theme.palette.primary.main
    }
  },
  toggleButtonGroup: {
    padding: theme.spacing(1, 0)
  },
  pluInput: {
    width: '100%'
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    gap: theme.spacing(2)
  }
}))

interface ISubHeaderProps {
  shopViewMode: ShopViewMode
  onShopViewModeChange: (e: object, newMode: ShopViewMode) => void
  onFilterChange: (filter: ProductsFilterInput) => void
  className?: string
  pluCode: string
  setPluCode: (code: string) => void
  pluCodeError: CodeFormulaErrorMessage | null
  setPluCodeError: (error: CodeFormulaErrorMessage | null) => void
  onProductItemQuantityIncrementWithPluCode: () => void
  gridViewComponent: React.ReactNode
}

export const SubHeader: React.FC<ISubHeaderProps> = ({
  shopViewMode,
  onShopViewModeChange,
  onFilterChange,
  className,
  pluCode,
  setPluCode,
  pluCodeError,
  setPluCodeError,
  onProductItemQuantityIncrementWithPluCode,
  gridViewComponent
}: ISubHeaderProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const handlePluCodeChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      validatePluCode(e.target.value, setPluCodeError)
      setPluCode(e.target.value)
    },
    [setPluCode, setPluCodeError]
  )
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !pluCodeError && pluCode) {
        e.preventDefault()
        onProductItemQuantityIncrementWithPluCode()
      }
    },
    [onProductItemQuantityIncrementWithPluCode, pluCode, pluCodeError]
  )
  const handleClearIconClick = useCallback(() => setPluCode(''), [setPluCode])
  return (
    <div className={cn(classes.root, className)}>
      {shopViewMode === ShopViewMode.ListView && (
        <ShopSearch
          className={classes.search}
          onFilterChange={onFilterChange}
        />
      )}
      {shopViewMode === ShopViewMode.PriceLookupCodeInput && (
        <TextField
          placeholder={t('Type PLU or quantity*PLU')}
          value={pluCode}
          onChange={handlePluCodeChange}
          variant="outlined"
          fullWidth
          className={classes.pluInput}
          onKeyDown={handleKeyDown}
          error={!!pluCodeError}
          InputProps={{
            endAdornment: pluCode ? (
              <IconButton onClick={handleClearIconClick}>
                <CloseIcon />
              </IconButton>
            ) : undefined
          }}
        />
      )}
      {shopViewMode === ShopViewMode.GridView && gridViewComponent}
      <div className={classes.rightSide}>
        <VerticalDivider />
        <ToggleButtonGroup
          size="small"
          value={shopViewMode}
          exclusive
          onChange={onShopViewModeChange}
          className={classes.toggleButtonGroup}
        >
          {P([
            PermissionCode.IncrementProductItemQuantityWithPriceLookupCode
          ]) && (
            <ToggleButton
              className={classes.toggleButton}
              value={ShopViewMode.PriceLookupCodeInput}
            >
              <DialpadIcon />
            </ToggleButton>
          )}
          {P([
            PermissionCode.ReadProductGroups,
            PermissionCode.IncrementProductItemQuantity
          ]) && (
            <ToggleButton
              className={classes.toggleButton}
              value={ShopViewMode.GridView}
            >
              <ViewModuleIcon />
            </ToggleButton>
          )}
          <ToggleButton
            className={classes.toggleButton}
            value={ShopViewMode.ListView}
          >
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  )
}
