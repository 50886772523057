import {Box, Divider, List, SxProps, Typography} from '@mui/material'
import omit from 'lodash/omit'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {ApiSeatState, Scalars} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {safeSum} from '../../../../../utils/money'
import {InfoListItem} from '../InfoListItem'
import {AggregatedSeatsStateCounts} from './types'

const zeroSeatStateCounts: AggregatedSeatsStateCounts = {
  [ApiSeatState.Available]: 0,
  [ApiSeatState.Hidden]: 0,
  [ApiSeatState.SelectedByMe]: 0,
  [ApiSeatState.SelectedByOthers]: 0,
  [ApiSeatState.AddedToMyCart]: 0,
  [ApiSeatState.AddedToOtherCart]: 0,
  [ApiSeatState.InMyReservation]: 0,
  [ApiSeatState.InOtherReservation]: 0,
  [ApiSeatState.ReservedInMyCart]: 0,
  [ApiSeatState.Disabled]: 0,
  [ApiSeatState.PreSold]: 0,
  [ApiSeatState.Sold]: 0
}

const mergeAggregatedSeatsStateCounts = (
  countsA: AggregatedSeatsStateCounts,
  countsB: Partial<AggregatedSeatsStateCounts>
) =>
  Object.values(ApiSeatState).reduce(
    (acc: AggregatedSeatsStateCounts, key: ApiSeatState) => ({
      ...acc,
      [key]: countsA[key] + (countsB[key] ?? 0)
    }),
    zeroSeatStateCounts
  )

const getAggregatedSeatsStateCounts = ({
  seats,
  zones
}: {
  seats: {
    [uuid: string]: {
      state: ApiSeatState
    }
  }
  zones: {
    [uuid: string]: {
      states: Partial<AggregatedSeatsStateCounts>
    }
  }
}): AggregatedSeatsStateCounts => {
  const aggregatedSeats = Object.values(seats).reduce(
    (acc, {state}) => ({
      ...acc,
      [state]: acc[state] + 1
    }),
    zeroSeatStateCounts
  )
  const aggregatedZones = Object.values(zones).reduce(
    (acc, {states}) => mergeAggregatedSeatsStateCounts(acc, states),
    zeroSeatStateCounts
  )
  return mergeAggregatedSeatsStateCounts(aggregatedSeats, aggregatedZones)
}

const getCountOfOtherStates = (countsByState: AggregatedSeatsStateCounts) => {
  return safeSum(
    Object.values(
      omit(countsByState, [
        ApiSeatState.Available,
        ApiSeatState.Disabled,
        ApiSeatState.Hidden
      ])
    )
  )
}

interface ISeatStatesProps {
  eventSeats: Scalars['JSON']
  sx: SxProps<Theme>
}

export const SeatStates: React.FC<ISeatStatesProps> = ({
  sx,
  eventSeats
}: ISeatStatesProps) => {
  const {t} = useTranslation()
  const countsByState = useMemo(
    () => getAggregatedSeatsStateCounts(eventSeats),
    [eventSeats]
  )
  const countOfOtherStates = getCountOfOtherStates(countsByState)
  return (
    <Box sx={sx}>
      <Typography variant="subtitle1" sx={{pb: 1}}>
        {t('Seat states')}
      </Typography>
      <List>
        <InfoListItem
          title={t('Available')}
          description={t('It is possible to click on seat in online and POS')}
          count={countsByState[ApiSeatState.Available]}
          sx={{py: 1}}
        />
        <Divider />
        <InfoListItem
          title={t('Disabled')}
          description={t(
            "Seats can't be added to cart and are visible for cashiers and customers."
          )}
          count={countsByState[ApiSeatState.Disabled]}
          sx={{py: 1}}
        />
        <Divider />
        <InfoListItem
          title={t('Hidden')}
          description={t(
            "Seats can't be added to cart and are visible for cashiers but hidden for customers."
          )}
          count={countsByState[ApiSeatState.Hidden]}
          sx={{py: 1}}
        />
        <Divider />
        <InfoListItem
          title={t('Other')}
          description={t(
            'Can not be edited. Seats in occupied, reserved or purchased states.'
          )}
          count={countOfOtherStates}
          sx={{py: 1}}
        />
      </List>
    </Box>
  )
}
