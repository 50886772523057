import {isNull, omitBy, pick, uniqueId, without} from 'lodash'
import {
  HeaderItemsGroupFieldsFragment,
  HeaderLinkItemFieldFragment,
  UpdateWebsiteFooterInput
} from '../../../../../../__generated__/schema'
import {TreeItems} from '../../../../../common/sortableTree/types'
import {ItemGroupWithId} from '../../types'

const isHeaderLinkItemFieldFragment = (
  item: HeaderLinkItemFieldFragment | HeaderItemsGroupFieldsFragment
): item is HeaderLinkItemFieldFragment => item.__typename === 'HeaderLinkItem'

export const transformWebsiteHeaderItemToTreeItems = (
  items: (HeaderLinkItemFieldFragment | HeaderItemsGroupFieldsFragment)[]
): TreeItems =>
  items.map((item: any) =>
    isHeaderLinkItemFieldFragment(item)
      ? {
          id: uniqueId(),
          label: item.label,
          href: item.href,
          target: item.target,
          description: item.href,
          children: []
        }
      : {
          id: uniqueId(),
          label: item.label,
          children: transformWebsiteHeaderItemToTreeItems(item.items)
        }
  )

export const extractHeaderDataFromTreeItems = (items: TreeItems): any[] =>
  without(
    items.map((item) =>
      item.children.length
        ? {
            ...pick(item, ['label']),
            items: extractHeaderDataFromTreeItems(item.children)
          }
        : omitBy(pick(item, ['label', 'href', 'target']), isNull)
    ),
    null
  )

export const transformItemGroupWithIdsToUpdateWebsiteFooterInput = (
  itemGroups: ItemGroupWithId[]
): UpdateWebsiteFooterInput => ({
  itemGroups: itemGroups.map((itemGroup) => ({
    alignment: itemGroup.alignment,
    items: itemGroup.items.map((item) => ({
      label: item.label,
      typographyVariant: item.typographyVariant || undefined,
      link: item.link || undefined,
      linkTarget: item.linkTarget || undefined,
      icon: item.icon || undefined
    }))
  }))
})
