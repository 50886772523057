import React from 'react'
import {useTranslation} from 'react-i18next'

import {InputRow} from '../common'
import {FormInput} from '../visual'

interface IFormAddressFieldsProps {
  register: Function
  namePrefix: string
}

export const FormAddressFields: React.FC<IFormAddressFieldsProps> = ({
  register,
  namePrefix
}: IFormAddressFieldsProps) => {
  const {t} = useTranslation()

  return (
    <>
      <InputRow
        nodes={[
          <FormInput
            key="complex"
            label={t('Complex')}
            placeholder={t('Complex')}
            type="text"
            name={`${namePrefix}[complex]`}
            inputRef={register({required: false})}
          />,
          <FormInput
            key="street"
            label={t('Street')}
            placeholder={t('Street')}
            type="text"
            name={`${namePrefix}[street]`}
            inputRef={register({required: true})}
            showRequiredLabel
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormInput
            key="town"
            label={t('Town')}
            placeholder={t('Town')}
            type="text"
            name={`${namePrefix}[town]`}
            inputRef={register({required: true})}
            showRequiredLabel
          />,
          <FormInput
            key="postalCode"
            label={t('Postal code')}
            placeholder={t('Postal code')}
            type="text"
            name={`${namePrefix}[postalCode]`}
            inputRef={register({required: true})}
            showRequiredLabel
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormInput
            key="country"
            label={t('Country')}
            placeholder={t('Country')}
            type="text"
            name={`${namePrefix}[country]`}
            inputRef={register({required: true})}
            showRequiredLabel
          />
        ]}
        xs={6}
      />
    </>
  )
}
