import {useState, useCallback} from 'react'

export const useBooleanState = (defaultState: boolean) => {
  const [state, setState] = useState(defaultState)

  const toggle = useCallback(() => setState((state) => !state), [setState])
  const setTrue = useCallback(() => setState(true), [setState])
  const setFalse = useCallback(() => setState(false), [setState])

  return {state, toggle, setTrue, setFalse}
}

export const useKeyBooleanState = (defaultState: {[key: string]: boolean}) => {
  const [state, setState] = useState(defaultState)

  const toggle = useCallback(
    (key: string) => {
      setState({
        ...state,
        [key]: !state[key]
      })
    },
    [state]
  )

  const setTrue = useCallback(
    (key: string) => {
      setState({
        ...state,
        [key]: true
      })
    },
    [state]
  )

  const setFalse = useCallback(
    (key: string) => {
      setState({
        ...state,
        [key]: false
      })
    },
    [state]
  )

  return {state, toggle, setTrue, setFalse}
}
