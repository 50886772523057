import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  OutlinedInputProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../theme'
import {convertValueToFloat} from '../../utils/conversions'
import {
  useIsNotZero,
  useIsPositiveInteger,
  useIsValidMoneyAmount
} from '../../utils/formsValidations'
import {UncontrolledFormTextInputWithCancelButton} from '../form/UncontrolledFormTextInputWithCancelButton'
import {SaveButton} from './Buttons'
import {DialogTitleWithCloseButton} from './DialogTitleWithCloseButton'

const useStyles = makeStyles<Theme>((theme) => ({
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  totalLabel: {
    fontWeight: 'bold'
  },
  uncontrolledInput: {
    marginLeft: -theme.spacing(2)
  },
  dialogContent: {
    display: 'grid',
    gap: theme.spacing(3),
    padding: theme.spacing(3)
  },
  form: {
    position: 'absolute'
  }
}))

export const calculateTotal = (
  denomination: string | number,
  count: string | number
): number => {
  try {
    return new Decimal(denomination).mul(count).toNumber()
  } catch (e) {
    return 0
  }
}

export enum IntentFrameDialogField {
  Denomination = 'denomination',
  Quantity = 'quantity'
}

export interface IIntentFrameDialogForm {
  [IntentFrameDialogField.Quantity]: string
  [IntentFrameDialogField.Denomination]: string
}

interface IIntentFrameDialogProps {
  title: string
  onSubmit: (form: IIntentFrameDialogForm) => void
  defaultValues?: Partial<IIntentFrameDialogForm>
  DenominationInputProps?: Pick<
    OutlinedInputProps,
    'startAdornment' | 'endAdornment'
  >
  QuantityInputProps?: Pick<
    OutlinedInputProps,
    'startAdornment' | 'endAdornment'
  >
  isDenominationDisabled?: boolean
  intentFrameDialogFormId: string
  fieldWithAutoFocus?: IntentFrameDialogField
  translatePrice: (value: number) => string
  onDeleteButtonClick?: () => void
  onClose: () => void
  isOpen: boolean
}

export const IntentFrameDialog: React.FC<IIntentFrameDialogProps> = ({
  title,
  intentFrameDialogFormId,
  translatePrice,
  onSubmit,
  defaultValues,
  DenominationInputProps,
  QuantityInputProps,
  fieldWithAutoFocus,
  onDeleteButtonClick,
  isDenominationDisabled,
  onClose,
  isOpen
}: IIntentFrameDialogProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {
    errors,
    triggerValidation,
    watch,
    setValue,
    register,
    handleSubmit,
    reset
  } = useForm<IIntentFrameDialogForm>({defaultValues})
  useEffect(() => {
    if (isOpen) {
      reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset])
  const isValidMoneyAmount = useIsValidMoneyAmount()
  const isNotZero = useIsNotZero()
  const isPositiveInteger = useIsPositiveInteger()
  const values = watch()
  const total = calculateTotal(
    convertValueToFloat(values[IntentFrameDialogField.Denomination]) || 0,
    values[IntentFrameDialogField.Quantity]
  )

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={intentFrameDialogFormId}
      className={classes.form}
    >
      <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitleWithCloseButton onCloseClick={onClose}>
          {title}
        </DialogTitleWithCloseButton>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <UncontrolledFormTextInputWithCancelButton<IIntentFrameDialogForm>
            autoFocus={
              fieldWithAutoFocus === IntentFrameDialogField.Denomination
            }
            margin="dense"
            fullWidth
            className={classes.uncontrolledInput}
            label={t('Denomination', {context: 'intent_frame_dialog'})}
            name={IntentFrameDialogField.Denomination}
            errors={errors}
            triggerValidation={triggerValidation}
            watch={watch}
            setValue={setValue}
            register={register}
            validationOptions={{
              validate: {isValidMoneyAmount, isNotZero},
              required: true
            }}
            disabled={isDenominationDisabled}
            inputMode="decimal"
            InputProps={DenominationInputProps}
          />
          <UncontrolledFormTextInputWithCancelButton<IIntentFrameDialogForm>
            autoFocus={fieldWithAutoFocus === IntentFrameDialogField.Quantity}
            margin="dense"
            fullWidth
            className={classes.uncontrolledInput}
            label={t('Quantity')}
            name={IntentFrameDialogField.Quantity}
            errors={errors}
            triggerValidation={triggerValidation}
            watch={watch}
            setValue={setValue}
            register={register}
            validationOptions={{
              validate: isPositiveInteger,
              required: true
            }}
            inputMode="decimal"
            InputProps={QuantityInputProps}
            helperNote={t('Minimum is 1')}
          />
          <span className={classes.totalRow}>
            <Typography variant="subtitle1" className={classes.totalLabel}>
              {t('Total: ')}
            </Typography>
            <Typography variant="body1">{translatePrice(total)}</Typography>
          </span>
        </DialogContent>
        <Divider />
        <DialogActions>
          {onDeleteButtonClick && (
            <Button color="primary" onClick={onDeleteButtonClick}>
              {t('Delete')}
            </Button>
          )}
          <SaveButton type="submit" form={intentFrameDialogFormId} />
        </DialogActions>
      </Dialog>
    </form>
  )
}
