import {useTranslation} from 'react-i18next'
import {VoucherState} from '../__generated__/schema'

export const useTranslateVoucherState = () => {
  const {t} = useTranslation()
  const translatedStates: Record<VoucherState, string> = {
    [VoucherState.Active]: t<string>('VoucherState->Active'),
    [VoucherState.Inactive]: t<string>('VoucherState->Inactive'),
    [VoucherState.Invalid]: t<string>('VoucherState->Invalid')
  }
  return (state: VoucherState) => translatedStates[state]
}

export const useTranslateVoucherStateDescription = () => {
  const {t} = useTranslation()
  const translatedStates: Record<VoucherState, string> = {
    [VoucherState.Active]: t<string>('Voucher can be redeemed.'),
    [VoucherState.Inactive]: t<string>("Voucher can't be redeemed yet."),
    [VoucherState.Invalid]: t<string>("Voucher can't be redeemed anymore.")
  }
  return (state: VoucherState) => translatedStates[state]
}
