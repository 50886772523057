import {useQuery} from '@apollo/react-hooks'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {compact} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightWarehousesQuery,
  LightweightWarehousesQueryVariables,
  NarrowRetailPaymentMethodsQuery,
  NarrowRetailPaymentMethodsQueryVariables,
  PaymentMethodState,
  PaymentMethodType,
  PermissionCode,
  TemplateType
} from '../../../../../__generated__/schema'
import {useDeviceServices} from '../../../../../hooks/deviceServices'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {useTranslateBoolean} from '../../../../../hooks/translateBoolean'
import {
  useTranslateCashDrawerController,
  useTranslateCashDrawerOpenLocation
} from '../../../../../hooks/translateCashDrawer'
import {useTranslateCheckoutPaymentMethodsViewMode} from '../../../../../hooks/translateCheckoutPaymentMethodsViewMode'
import {useTranslateShopViewMode} from '../../../../../hooks/translateShopViewMode'
import {useTranslateTourTimeSlotViewMode} from '../../../../../hooks/translateTourTimeSlotViewMode'
import {useTranslateZonePlanView} from '../../../../../hooks/translateZonePlanView'
import {Theme} from '../../../../../theme'
import {
  CashDrawerController,
  CashDrawerOpenLocation,
  CheckoutPaymentMethodsViewMode,
  ICashDrawerSettings,
  IDirectTicketPrintSettings,
  IPosTerminalSettings,
  RegistrationOfSales,
  ShopViewMode,
  TourTimeSlotViewMode,
  ZonePlanView
} from '../../../../../types'
import {useEnsurePermissions, useUserInfo} from '../../../../../utils/auth'
import {SubTopbarControls} from '../../../../common'
import {useGetClientTemplates} from '../../clients/graphql'
import {
  LIGHTWEIGHT_WAREHOUSES,
  NARROW_RETAIL_PAYMENT_METHODS,
  useGetLightweightDivisions
} from '../../graphql'

import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {CashRegisterOperations} from './CashRegisterOperations'
import {CustomerDisplaySection} from './CustomerDisplaySection'
import {ItemsList} from './ItemsList'
import {ServicesList} from './ServicesList'

const useSectionStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.75)
  }
}))

interface ISectionProps {
  title: string
  children: React.ReactNode
}

const Section: React.FC<ISectionProps> = ({title, children}: ISectionProps) => {
  const classes = useSectionStyles()
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">{title}</Typography>
      {children}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    paddingTop: theme.spacing(3.75),
    paddingBottom: theme.spacing(3.75),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5.75)
  }
}))

export const DeviceInformation: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {divisions} = useGetLightweightDivisions()
  const {effectiveClient} = useUserInfo()
  const {data} = useGetClientTemplates({
    skip: !effectiveClient?.id,
    variables: {id: effectiveClient!.id},
    fetchPolicy: 'network-only'
  })
  const {data: retailPaymentMethodsData, error: retailPaymentMethodsError} =
    useQuery<
      NarrowRetailPaymentMethodsQuery,
      NarrowRetailPaymentMethodsQueryVariables
    >(NARROW_RETAIL_PAYMENT_METHODS, {
      variables: {state: PaymentMethodState.Active}
    })
  const {data: warehousesData} = useQuery<
    LightweightWarehousesQuery,
    LightweightWarehousesQueryVariables
  >(LIGHTWEIGHT_WAREHOUSES, {
    variables: {
      paginationInput: {limit: 100, offset: 0}
    },
    fetchPolicy: 'network-only',
    skip: !P([PermissionCode.ReadWarehouses])
  })
  const [deviceServices] = useDeviceServices()
  const [registrationOfSales] = useLocalStorageState<RegistrationOfSales>(
    LocalStorageKey.RegistrationOfSales,
    RegistrationOfSales.None
  )
  const [divisionId] = useLocalStorageState<number | null>(
    LocalStorageKey.CashDeskShopDivisionId,
    null
  )
  const [warehouseId] = useLocalStorageState<number | null>(
    LocalStorageKey.WarehouseId,
    null
  )
  const [zonePlanView] = useLocalStorageState<ZonePlanView>(
    LocalStorageKey.ZonePlanView,
    ZonePlanView.ListView
  )
  const [shopViewMode] = useLocalStorageState<ShopViewMode>(
    LocalStorageKey.ShopViewMode,
    ShopViewMode.ListView
  )
  const [tourTimeSlotViewMode] = useLocalStorageState<TourTimeSlotViewMode>(
    LocalStorageKey.TourTimeSlotViewMode,
    TourTimeSlotViewMode.GridView
  )
  const [checkoutPaymentMethodsViewMode] =
    useLocalStorageState<CheckoutPaymentMethodsViewMode>(
      LocalStorageKey.CheckoutPaymentMethodsViewMode,
      CheckoutPaymentMethodsViewMode.ExpandedFirstGroup
    )
  const [cashDrawer] = useLocalStorageState<ICashDrawerSettings>(
    LocalStorageKey.CashDrawer,
    {
      controller: CashDrawerController.None,
      openLocation: CashDrawerOpenLocation.DontOpen,
      ipAddress: null
    }
  )
  const [posTerminal] = useLocalStorageState<IPosTerminalSettings>(
    LocalStorageKey.PosTerminal,
    {
      enabled: false,
      port: null,
      paymentMethodId: null
    }
  )
  const [enabledDivisions] = useLocalStorageState<number[]>(
    LocalStorageKey.EnabledDivisions,
    []
  )
  const [deviceTemplateIds] = useLocalStorageState<{
    [templateType: string]: number[]
  }>(LocalStorageKey.TemplateIdsAssignedToDeviceByType, {})
  const [directTicketPrint] = useLocalStorageState<IDirectTicketPrintSettings>(
    LocalStorageKey.DirectTicketPrintSettings,
    {
      enabled: false,
      printer: null
    }
  )
  const translateBoolean = useTranslateBoolean()
  const translateZonePlanView = useTranslateZonePlanView()
  const translateShopViewMode = useTranslateShopViewMode()
  const translateTourTimeSlotViewMode = useTranslateTourTimeSlotViewMode()
  const translateCheckoutPaymentMethodsViewMode =
    useTranslateCheckoutPaymentMethodsViewMode()
  const translateCashDrawerController = useTranslateCashDrawerController()
  const translateCashDrawerOpenLocation = useTranslateCashDrawerOpenLocation()
  const ticketPrintingTemplates = deviceTemplateIds[TemplateType.TicketPrinting]
    ? compact(
        deviceTemplateIds[TemplateType.TicketPrinting].map(
          (deviceTemplateId) =>
            data?.client.templateAssignments.find(
              ({template}) => template.id === deviceTemplateId
            )?.template
        )
      )
    : []
  const receiptPrintingTemplates = deviceTemplateIds[TemplateType.Receipt]
    ? compact(
        deviceTemplateIds[TemplateType.Receipt].map(
          (deviceTemplateId) =>
            data?.client.templateAssignments.find(
              ({template}) => template.id === deviceTemplateId
            )?.template
        )
      )
    : []
  const selectedRetailPaymentMethod = (
    retailPaymentMethodsData?.retailPaymentMethods || []
  )
    .filter(({type}) => type === PaymentMethodType.Card)
    .find(({id}) => id === posTerminal.paymentMethodId)
  const classes = useStyles()
  return (
    <CenteredLayout
      subTopbar={
        <SubTopbarControls
          leftChildren={
            <Typography variant="subtitle1">
              {t('Device information')}
            </Typography>
          }
        />
      }
    >
      <CenteredLayoutListWrapper className={classes.list}>
        {P([PermissionCode.CustomerDisplay]) && <CustomerDisplaySection />}
        {(P([PermissionCode.CreateCashDeskDeposit]) ||
          P([PermissionCode.CreateCashDeskWithdrawal]) ||
          P([PermissionCode.ReadPaymentReportView])) && (
          <CashRegisterOperations />
        )}
        <ServicesList
          title={t('Enabled services')}
          services={deviceServices.filter((setting) => setting.isAvailable)}
        />
        <ServicesList
          title={t('Disabled services')}
          services={deviceServices.filter((setting) => !setting.isAvailable)}
        />
        <Section title={t('Shop settings')}>
          <ItemsList
            disablePadding
            items={[
              {
                primaryText: t('Default division for products'),
                secondaryText: divisionId
                  ? divisions.find(({id}) => id === divisionId)?.name
                  : t('None')
              },
              {
                primaryText: t('Default warehouse for products'),
                secondaryText: warehouseId
                  ? (warehousesData?.warehouses.items || []).find(
                      ({id}) => id === warehouseId
                    )?.name
                  : t('None')
              },
              {
                primaryText: t('Registration of sales'),
                secondaryText:
                  registrationOfSales === RegistrationOfSales.PortosEkasa
                    ? t('Portos ekasa')
                    : t('None')
              }
            ]}
          />
        </Section>
        <Section title={t('Default views')}>
          <ItemsList
            disablePadding
            items={[
              {
                primaryText: t('Event zone plan'),
                secondaryText: translateZonePlanView(zonePlanView)
              },
              {
                primaryText: t('Shop'),
                secondaryText: translateShopViewMode(shopViewMode)
              },
              {
                primaryText: t('Tour time slot'),
                secondaryText:
                  translateTourTimeSlotViewMode(tourTimeSlotViewMode)
              },
              {
                primaryText: t('Checkout payment methods'),
                secondaryText: translateCheckoutPaymentMethodsViewMode(
                  checkoutPaymentMethodsViewMode
                )
              }
            ]}
          />
        </Section>
        <Section title={t('Direct ticket print')}>
          <ItemsList
            disablePadding
            items={[
              {
                primaryText: t('Direct ticket print enabled'),
                secondaryText: translateBoolean(directTicketPrint.enabled)
              },
              ...(directTicketPrint.printer
                ? [
                    {
                      primaryText: t('Printer'),
                      secondaryText: directTicketPrint.printer
                    }
                  ]
                : [])
            ].filter(Boolean)}
          />
        </Section>
        <Section title={t('Cash drawer')}>
          <ItemsList
            disablePadding
            items={[
              {
                primaryText: t('Open cash drawer by app'),
                secondaryText: translateCashDrawerOpenLocation(
                  cashDrawer.openLocation
                )
              },
              {
                primaryText: t('Connected cash drawer controller'),
                secondaryText: translateCashDrawerController(
                  cashDrawer.controller
                )
              },
              ...(cashDrawer.ipAddress
                ? [
                    {
                      primaryText: t('Controller IP address'),
                      secondaryText: cashDrawer.ipAddress
                    }
                  ]
                : [])
            ].filter(Boolean)}
          />
        </Section>
        <Section title={t('POS terminal')}>
          <ItemsList
            disablePadding
            items={[
              {
                primaryText: posTerminal.enabled
                  ? t('POS terminal is connected to device')
                  : t('POS terminal is not connected to device'),
                secondaryText: posTerminal.enabled
                  ? t(
                      'All card payments will be processed through this connection.'
                    )
                  : t(
                      'Set up automatic connection between application and POS terminal for receiving card payments.'
                    )
              },
              ...(posTerminal.enabled
                ? [
                    {
                      primaryText: t('Port'),
                      secondaryText: posTerminal.port
                    }
                  ]
                : []),
              ...(posTerminal.enabled
                ? [
                    {
                      primaryText: t('Payment method'),
                      secondaryText: retailPaymentMethodsError
                        ? t('Error while loading retail payment methods')
                        : selectedRetailPaymentMethod
                        ? selectedRetailPaymentMethod.name
                        : t(
                            'The payment method you’ve selected is no longer available. Please choose an alternative method or reach out to your supervisor if you need assistance.'
                          )
                    }
                  ]
                : [])
            ].filter(Boolean)}
          />
        </Section>
        <Section title={t('Enabled divisions')}>
          <ItemsList
            disablePadding
            items={
              enabledDivisions.length
                ? compact(
                    enabledDivisions.map((ed) =>
                      divisions.find(({id}) => id === ed)
                    )
                  ).map(({id, name}) => ({
                    primaryText: t('#{{id}} • {{name}}', {id, name})
                  }))
                : [
                    {
                      primaryText: t('All divisions are enabled'),
                      secondaryText: t(
                        'No restrictions were set for this device.'
                      )
                    }
                  ]
            }
          />
        </Section>
        <Section title={t('Ticket printing')}>
          <ItemsList
            disablePadding
            items={
              ticketPrintingTemplates.length
                ? ticketPrintingTemplates.map((template) => ({
                    primaryText: t('#{{id}} • {{name}}', {
                      id: template.id,
                      name: template.name
                    })
                  }))
                : [
                    {
                      primaryText: t('Templates assigned to device'),
                      secondaryText: t(
                        'Device is using default templates assigned to company.'
                      )
                    }
                  ]
            }
          />
        </Section>
        <Section title={t('Receipt printing')}>
          <ItemsList
            disablePadding
            items={
              receiptPrintingTemplates.length
                ? receiptPrintingTemplates.map((template) => ({
                    primaryText: t('#{{id}} • {{name}}', {
                      id: template.id,
                      name: template.name
                    })
                  }))
                : [
                    {
                      primaryText: t('Templates assigned to device'),
                      secondaryText: t(
                        'Device is using default templates assigned to company.'
                      )
                    }
                  ]
            }
          />
        </Section>
      </CenteredLayoutListWrapper>
    </CenteredLayout>
  )
}
