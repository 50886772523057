import {Box, Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {DetailEventPropertiesFragment} from '../../../../../../../__generated__/schema'

import {useDateTimeFormatters} from '../../../../../../../utils/formatting'

import {HighlightedInfoLabel, InfoLabel} from './common'

interface ILogInfoProps {
  data: DetailEventPropertiesFragment
}

export const LogInfo: React.FC<ILogInfoProps> = ({data}: ILogInfoProps) => {
  const {t} = useTranslation()
  const {formatDateTime} = useDateTimeFormatters()

  return (
    <Box>
      <Typography variant="caption">{t('Unable to change values')}</Typography>
      <Box marginTop={1} />
      <Grid container>
        <HighlightedInfoLabel>{t('Created:')}</HighlightedInfoLabel>
        <Box paddingRight={1} />
        <InfoLabel>
          {formatDateTime(new Date(data.createdAt))} {t('by User')}{' '}
          {data.createdBy}
        </InfoLabel>
      </Grid>
      <Grid container>
        <HighlightedInfoLabel>{t('Last update:')}</HighlightedInfoLabel>
        <Box paddingRight={1} />
        <InfoLabel>
          {formatDateTime(new Date(data.updatedAt))} {t('by User')}{' '}
          {data.updatedBy}
        </InfoLabel>
      </Grid>
      <Grid container>
        <HighlightedInfoLabel>
          {t('Moved after publishing:')}
        </HighlightedInfoLabel>
        <Box paddingLeft={1} />
        <InfoLabel>{data.moved ? t('True') : t('False')}</InfoLabel>
      </Grid>
    </Box>
  )
}
