import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {Box, Drawer, drawerClasses, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import {isNil} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DiscountPropertiesFragment,
  DiscountsFilterInput,
  DiscountState,
  PermissionCode,
  SellingChannel
} from '../../../../__generated__/schema'
import {
  useTranslateDiscountApplication,
  useTranslateDiscountState
} from '../../../../hooks/discounts'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {
  BooleanIconCellRenderer,
  DataGridTable,
  useDataGridPagination,
  useDiscountValueFormatter
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {discountStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {ReloadButton} from '../components/ReloadButton'
import {WideCenteredLayout} from '../Layout'
import {CopyDiscountDrawer} from './CopyDiscountDrawer'
import {useDiscounts} from './graphql'

const StateRenderer = ({state}: {state: DiscountState}) => {
  const translateDiscountState = useTranslateDiscountState()
  return (
    <EntityStateChip
      colorConf={discountStateColors[state]}
      label={translateDiscountState(state)}
    />
  )
}

const ActionsRenderer = ({
  id,
  onCopyButtonClick
}: {
  id: number
  onCopyButtonClick: () => void
}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleDetailClick = useCallback(
    () => history.push(routeTo.admin.discounts.detail(id)),
    [history, id]
  )
  return (
    <Box>
      {P([PermissionCode.CopyDiscount]) && (
        <IconButton onClick={onCopyButtonClick}>
          <ContentCopyIcon />
        </IconButton>
      )}
      {P([PermissionCode.ReadDiscount]) && (
        <IconButton onClick={handleDetailClick}>
          <ChevronRightIcon />
        </IconButton>
      )}
    </Box>
  )
}

interface IDiscountsList {
  searchFilter: DiscountsFilterInput
}

export const DiscountsList: React.FC<IDiscountsList> = ({
  searchFilter
}: IDiscountsList) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [selectedState, setSelectedState] =
    useState<DiscountState | undefined>(undefined)
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, error, loading, refetch} = useDiscounts({
    filter: {
      ...searchFilter,
      states: selectedState ? [selectedState] : undefined
    },
    paginationInput
  })
  const [discountToCopy, setDiscountToCopy] =
    useState<undefined | DiscountPropertiesFragment>(undefined)
  const openCopyDrawer = useCallback(
    (discount: DiscountPropertiesFragment) => () => {
      setDiscountToCopy(discount)
    },
    []
  )
  const closeCopyDrawer = useCallback(() => {
    setDiscountToCopy(undefined)
  }, [])
  const discountValueFormatter = useDiscountValueFormatter()
  const translateDiscountApplication = useTranslateDiscountApplication()
  const handleStateChange = useCallback(
    (state?: DiscountState) => {
      setSelectedState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Value'),
        field: 'value',
        valueFormatter: (params) =>
          params.id &&
          discountValueFormatter(params, params.api.getRow(params.id).type),
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Application'),
        field: 'application',
        sortable: false,
        valueFormatter: (params) => translateDiscountApplication(params.value),
        minWidth: 100
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: DiscountState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Rules'),
        field: 'applicationRulesCount',
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        minWidth: 50
      },
      {
        headerName: t('Retail'),
        field: 'retail',
        valueGetter: (params) =>
          params.row.sellingChannels.includes(SellingChannel.Retail),
        renderCell: function renderer(
          params: GridRenderCellParams<{value: boolean}>
        ) {
          return <BooleanIconCellRenderer value={params.value} />
        },
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Ecommerce'),
        field: 'eCommerce',
        valueGetter: (params) =>
          params.row.sellingChannels.includes(SellingChannel.ECommerce),
        renderCell: function renderer(
          params: GridRenderCellParams<{value: boolean}>
        ) {
          return <BooleanIconCellRenderer value={params.value} />
        },
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Max usage limit per order'),
        field: 'maxUsageLimitPerOrder',
        sortable: false,
        valueFormatter: (params) => (isNil(params.value) ? '' : params.value),
        align: 'right',
        headerAlign: 'right',
        minWidth: 200
      },
      {
        headerName: t('Internal description'),
        field: 'internalDescription',
        sortable: false,
        minWidth: 300
      },
      {
        headerName: '',
        field: 'actions',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <ActionsRenderer
              id={params.value}
              onCopyButtonClick={openCopyDrawer(params.row)}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true
      }
    ],
    [discountValueFormatter, openCopyDrawer, t, translateDiscountApplication]
  )
  if (error) {
    return <Error error={error} message={t('Could not load discounts')} />
  }
  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr'
        }}
      >
        <SubHeaderToolbar
          title={t('Overview')}
          leftActions={[
            <ChipWithOptions<DiscountState>
              key="discount-state-chip"
              size="small"
              selectedItem={selectedState}
              setSelectedItem={handleStateChange}
              options={[
                {
                  label: t('Active discounts'),
                  option: DiscountState.Active
                },
                {
                  label: t('Inactive discounts'),
                  option: DiscountState.Inactive
                }
              ]}
              allText={t('All discounts')}
            />
          ]}
          rightActions={[
            <ReloadButton
              key="reload-button"
              onReloadButtonClick={() => refetch()}
            />
          ]}
        />

        <WideCenteredLayout
          sx={{height: `calc(100% - 64px)`, width: '100%', p: 3}}
        >
          <DataGridTable
            columns={columns}
            loading={loading}
            rows={data?.discounts.items || []}
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick
            initialState={{
              pinnedColumns: {left: ['name'], right: ['actions']}
            }}
            columnVisibilityModel={{
              actions:
                P([PermissionCode.ReadDiscount]) ||
                P([PermissionCode.CopyDiscount])
            }}
            localeText={{noRowsLabel: t('No discounts found')}}
            {...getDataGridPaginationProps(data?.discounts.pagination)}
          />
        </WideCenteredLayout>
      </Box>
      <Drawer
        onClose={closeCopyDrawer}
        open={Boolean(discountToCopy)}
        anchor="right"
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <CopyDiscountDrawer
          closeDrawer={closeCopyDrawer}
          originalDiscount={discountToCopy}
        />
      </Drawer>
    </>
  )
}
