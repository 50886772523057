import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ConditionOperator,
  ConditionPropertiesFragment
} from '../../../../../__generated__/schema'
import {
  useTranslateConditionOperator,
  useTranslateConditionResource
} from '../../../../../hooks/discounts'
import {Theme} from '../../../../../theme'
import {ConditionValueChip} from './ConditionValueChip'

const useStyles = makeStyles<Theme>((theme) => ({
  chipWrapper: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    '&:last-child': {
      paddingRight: 0
    }
  }
}))

interface IConditionViewProps {
  condition: ConditionPropertiesFragment
  isFirst: boolean
  chipsClassName?: string
  labelClassName?: string
  onDeleteClick?: ({
    condition,
    valueToDelete
  }: {
    condition: ConditionPropertiesFragment
    valueToDelete: any
  }) => () => void
}

export const ConditionView: React.FC<IConditionViewProps> = ({
  condition,
  isFirst,
  chipsClassName,
  labelClassName,
  onDeleteClick
}: IConditionViewProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translateConditionResource = useTranslateConditionResource()
  const translateConditionOperator = useTranslateConditionOperator()
  return (
    <>
      <div className={labelClassName}>
        <Typography variant="body2" color="textSecondary" component="span">
          {isFirst ? t('If') : t('and')}
        </Typography>
        &nbsp;
        <Typography variant="subtitle2" component="span">
          {translateConditionResource(condition.resource)}
        </Typography>
        &nbsp;
        <Typography
          variant="body2"
          component="span"
          color={
            [
              ConditionOperator.IsNotOneOf,
              ConditionOperator.IsNotEqual
            ].includes(condition.conditionData.operator)
              ? 'error'
              : 'textSecondary'
          }
        >
          {translateConditionOperator(condition.conditionData.operator)}
        </Typography>
      </div>
      <div className={chipsClassName}>
        {Array.isArray(condition.conditionData.value) ? (
          condition.conditionData.value.map((value, index) => (
            <div className={classes.chipWrapper} key={index}>
              <ConditionValueChip
                value={value}
                resource={condition.resource}
                onDeleteClick={
                  onDeleteClick
                    ? onDeleteClick({condition, valueToDelete: value})
                    : undefined
                }
              />
            </div>
          ))
        ) : (
          <div className={classes.chipWrapper}>
            <ConditionValueChip
              value={condition.conditionData.value}
              resource={condition.resource}
              onDeleteClick={
                onDeleteClick
                  ? onDeleteClick({
                      condition,
                      valueToDelete: condition.conditionData
                    })
                  : undefined
              }
            />
          </div>
        )}
      </div>
    </>
  )
}
