import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  Switch,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {RolePropertiesFragment} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {EntityStateChip} from '../../../common'
import {COLOR_CONF} from '../../../constants'

import {useLocale} from '../../../context/locale'
import {Loading} from '../../../visual'
import {useGetAvailableRoles} from './graphql'

type RolePropertiesFragmentWithSelected = RolePropertiesFragment & {
  selected?: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    width: '100%'
  },
  rolesHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    fontWeight: 500
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(1, 3, 1, 3),
    minHeight: 72
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  },
  roleNameWithChip: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  roleDescription: {
    maxWidth: '70%'
  }
}))

const useRoles = (roleIds: Array<number>) => {
  const {data, loading} = useGetAvailableRoles()
  const [roles, setRoles] = useState<
    Record<string, RolePropertiesFragmentWithSelected>
  >({})

  useEffect(() => {
    if (data && data.availableRoles) {
      setRoles(
        data.availableRoles.reduce(
          (roles, role) => ({
            ...roles,
            [role.id]: {
              id: role.id,
              names: role.names,
              descriptions: role.descriptions,
              selected: roleIds.includes(role.id),
              isDeprecated: role.isDeprecated
            }
          }),
          {}
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) // dependant on roleIds only first time (as default value)

  return {
    loading,
    roles,
    setRoles
  }
}

const hasActiveDeprecatedRole = (
  role: RolePropertiesFragment,
  activeRoleIds: Array<number>
) => role.isDeprecated && activeRoleIds.includes(role.id)

interface IRoleInputs {
  roleIds: Array<number>
  showHeader?: boolean
  name: string
}

export const RoleInputs: React.FC<IRoleInputs> = ({
  showHeader,
  roleIds,
  name
}: IRoleInputs) => {
  const {register, unregister, setValue} = useFormContext()

  const classes = useStyles()
  const {t} = useTranslation()
  const {localeCode} = useLocale()
  const {loading, roles, setRoles} = useRoles(roleIds)
  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [name, register, unregister])
  const getOnSwitchChangeHandler = useCallback(
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRoles: Record<string, RolePropertiesFragmentWithSelected> = {
        ...roles,
        [id]: {...roles[id], selected: event.target.checked}
      }
      setRoles(newRoles)
      const roleIds = Object.keys(newRoles)
        .filter((roleId) => newRoles[roleId].selected)
        .map((roleId) => parseInt(roleId, 10))
      setValue(name, roleIds)
    },
    [name, roles, setRoles, setValue]
  )

  const sortedRoles = Object.values(roles)
    .filter(
      (role) => !role.isDeprecated || hasActiveDeprecatedRole(role, roleIds)
    )
    .sort((a, b) => a.names[localeCode]!.localeCompare(b.names[localeCode]!))

  return loading ? (
    <Loading />
  ) : (
    <Box className={classes.wrapper}>
      {showHeader && (
        <Typography className={classes.rolesHeader}>{t('Roles')}</Typography>
      )}
      <List>
        {sortedRoles.map((role, index, array) => (
          <ListItem
            key={role.id}
            className={classes.listItem}
            divider={array.length - 1 !== index}
          >
            <div className={classes.roleNameWithChip}>
              <Typography variant="subtitle2">
                {role.names[localeCode]}
              </Typography>
              {role.isDeprecated && (
                <EntityStateChip
                  label={t('Deprecated')}
                  colorConf={COLOR_CONF.RED}
                  isDotHidden
                />
              )}
            </div>
            <div className={classes.roleDescription}>
              <Typography variant="caption">
                {role.descriptions[localeCode]}
              </Typography>
            </div>
            <ListItemSecondaryAction className={classes.switch}>
              <>
                <Typography variant="body2">
                  {role.selected ? t('Enabled') : t('Disabled')}
                </Typography>
                <Switch
                  color="primary"
                  checked={role.selected}
                  onChange={getOnSwitchChangeHandler(role.id)}
                  value={role.id}
                />
              </>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
