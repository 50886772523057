import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined'
import {ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  NarrowReservationPropertiesFragment,
  PermissionCode,
  SellingChannel
} from '../../../../__generated__/schema'
import {ReactComponent as SandTimer} from '../../../../assets/sandTimer.svg'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {routeTo} from '../../../../utils/routes'
import {Tooltip} from '../../../common'
import {
  BaseSeatDialog,
  ListItemRow,
  ListWrapper,
  TopSummary
} from './BaseSeatDialog'
import {
  useDaysCountBetweenTodayAndExpirationDate,
  useExpirationToNowLabel,
  useGetExpirationToNowColor
} from './utils'

const useExpirationReservationStyles = makeStyles<Theme>((theme) => ({
  root: {display: 'flex', gap: theme.spacing(1), alignItems: 'center'},
  text: {
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  }
}))

interface IExpirationReservationProps {
  expireAt: string
  tooltip: string
  hasDivider?: boolean
}

const ExpirationReservation: React.FC<IExpirationReservationProps> = ({
  expireAt,
  tooltip,
  hasDivider
}: IExpirationReservationProps) => {
  const classes = useExpirationReservationStyles()
  const daysCountBetweenTodayAndExpirationDate =
    useDaysCountBetweenTodayAndExpirationDate(expireAt)
  const expirationToNowLabel = useExpirationToNowLabel({
    expireAt,
    daysCountBetweenTodayAndExpirationDate
  })
  const getExpirationToNowColor = useGetExpirationToNowColor({
    isWithBackground: false
  })
  const {formatDateTime, formatTime} = useDateTimeFormatters()
  const expireAtDate = new Date(expireAt)
  return (
    <ListItem disableGutters divider={hasDivider}>
      <ListItemIcon>
        <Tooltip title={tooltip}>
          <SandTimer />
        </Tooltip>
      </ListItemIcon>
      <ListItemText
        disableTypography
        primaryTypographyProps={{
          className: getExpirationToNowColor(
            daysCountBetweenTodayAndExpirationDate
          )
        }}
        primary={
          <div className={classes.root}>
            <Typography
              className={cn(
                getExpirationToNowColor(daysCountBetweenTodayAndExpirationDate),
                classes.text
              )}
              variant="body1"
            >
              {expirationToNowLabel}
            </Typography>
            {'•'}
            <Typography variant="body1">
              {daysCountBetweenTodayAndExpirationDate < 7
                ? formatTime(expireAtDate)
                : formatDateTime(expireAtDate)}
            </Typography>
          </div>
        }
      />
    </ListItem>
  )
}

interface IReservedSeatDialogProps {
  reservation: NarrowReservationPropertiesFragment
  onClose: () => void
}

export const ReservedSeatDialog: React.FC<IReservedSeatDialogProps> = ({
  reservation,
  onClose
}: IReservedSeatDialogProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const hasPermissionsToAccessRAP = P([
    PermissionCode.ReadReservation,
    PermissionCode.ReadEventSeats
  ])
  const history = useHistory()
  const {formatDateTime} = useDateTimeFormatters()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const handleOnConfirmationButtonClick = useCallback(() => {
    history.push(routeTo.admin.cashDesk.reservationPreview(reservation.id))
  }, [reservation.id, history])
  return (
    <BaseSeatDialog
      isOpen
      title={t('Reservation #{{reservationNumber}}', {
        reservationNumber: reservation.id
      })}
      confirmationButtonLabel={
        hasPermissionsToAccessRAP ? t('Open preview') : undefined
      }
      onConfirmationButtonClick={
        hasPermissionsToAccessRAP ? handleOnConfirmationButtonClick : undefined
      }
      onClose={onClose}
    >
      <ListWrapper>
        {reservation.items && (
          <TopSummary
            Icon={BookmarkBorderIcon}
            texts={[
              t('{{count}} ticket', {count: reservation.items.length}),
              translateEffectiveClientPrice(reservation.price),
              translateSellingChannel(reservation.sellingChannel)
            ]}
            tooltip={t('Reservation details')}
          />
        )}
        <ListItemRow
          Icon={TodayOutlinedIcon}
          texts={[
            formatDateTime(new Date(reservation.createdAt)),
            reservation.sellingChannel === SellingChannel.Retail
              ? reservation.createdByName
              : ''
          ].filter(Boolean)}
          tooltip={t('Created at')}
        />
        <ExpirationReservation
          expireAt={reservation.expireAt}
          tooltip={t('Expires at')}
          hasDivider={
            !!reservation.lead?.data.name ||
            !!reservation.lead?.data.phone ||
            !!reservation.lead?.data.email ||
            !!reservation.lead?.data.note ||
            !!reservation.lead?.data.internalNote
          }
        />
        {reservation.lead?.data.name && (
          <ListItemRow
            Icon={PersonOutlinedIcon}
            texts={[reservation.lead.data.name]}
            tooltip={t('Customer name')}
          />
        )}
        {reservation.lead?.data.phone && (
          <ListItemRow
            Icon={PhoneOutlinedIcon}
            texts={[reservation.lead.data.phone]}
            tooltip={t('Customer phone')}
          />
        )}
        {reservation.lead?.data.email && (
          <ListItemRow
            Icon={EmailOutlinedIcon}
            texts={[reservation.lead.data.email]}
            tooltip={t('Customer e-mail')}
          />
        )}
        {reservation.lead?.data.note && (
          <ListItemRow
            Icon={NotesOutlinedIcon}
            texts={[reservation.lead.data.note]}
            tooltip={t('Notes')}
          />
        )}
        {reservation.lead?.data.internalNote && (
          <ListItemRow
            Icon={!reservation.lead?.data.note ? NotesOutlinedIcon : undefined}
            texts={[reservation.lead.data.internalNote]}
            tooltip={!reservation.lead?.data.note ? t('Notes') : undefined}
          />
        )}
      </ListWrapper>
    </BaseSeatDialog>
  )
}
