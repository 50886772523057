import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Chip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {Tooltip} from '../../../common'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

const useStyles = makeStyles(() => ({
  menuItem: {
    flexFlow: 'row-reverse'
  },
  menuIcon: {
    justifyContent: 'center'
  }
}))

interface IOption<T> {
  option: T
  label: string
}

interface IChipWithOptionsProps<T> {
  selectedItem: T | undefined
  setSelectedItem: (item: T | undefined) => void
  options: IOption<T>[]
  allText: string
  size?: 'small' | 'medium'
  icon?: React.ReactElement
  tooltip?: string
}

export const ChipWithOptions = <T extends unknown>({
  selectedItem,
  setSelectedItem,
  options,
  allText,
  size,
  icon,
  tooltip
}: IChipWithOptionsProps<T>) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleMenuItemClick = useCallback(
    (newItem: T | undefined) => () => {
      setSelectedItem(newItem)
      closeMenu()
    },
    [closeMenu, setSelectedItem]
  )
  const classes = useStyles()
  return (
    <>
      <Tooltip title={tooltip}>
        <Chip
          size={size}
          variant="outlined"
          icon={icon}
          deleteIcon={<ArrowDropDownIcon />}
          onClick={openMenu}
          onDelete={openMenu}
          label={
            options.find((o) => o.option === selectedItem)?.label || allText
          }
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          label={allText}
          icon={!selectedItem ? <CheckIcon /> : undefined}
          isSelected={!selectedItem}
          className={classes.menuItem}
          iconClassName={classes.menuIcon}
          onClick={handleMenuItemClick(undefined)}
        />
        {options.map((o, index) => (
          <MenuItem
            key={`${index}-${o.option}`}
            label={o.label}
            icon={selectedItem === o.option ? <CheckIcon /> : undefined}
            isSelected={selectedItem === o.option}
            className={classes.menuItem}
            iconClassName={classes.menuIcon}
            onClick={handleMenuItemClick(o.option)}
          />
        ))}
      </Menu>
    </>
  )
}
