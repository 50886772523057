import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../../utils/errors'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {ICopyTourTimeSlotsForm} from '../../types'
import {formatCopyTourTimeSlotsFormDataToInput} from '../../utils'
import {CopySlotsForm} from './CopySlotsForm'
import {useCopyTourTimeSlots} from './graphql'

const COPY_TOUR_TIME_SLOTS_FORM_ID = 'copyTourTimeSlotsForm'

interface ICopySlotsDrawerProps {
  isOpen: boolean
  onClose: () => void
  tourId: number
  refetch: () => void
}

export const CopySlotsDrawer: React.FC<ICopySlotsDrawerProps> = ({
  isOpen,
  onClose,
  tourId,
  refetch
}: ICopySlotsDrawerProps) => {
  const {t} = useTranslation()
  const copyTourTimeSlots = useCopyTourTimeSlots()
  const {
    defaultErrorHandler,
    addInfoNotification,
    customErrorHandler,
    setShowBackdrop
  } = useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (formData: ICopyTourTimeSlotsForm) => {
      try {
        setShowBackdrop(true)
        await copyTourTimeSlots(
          formatCopyTourTimeSlotsFormDataToInput(formData, tourId)
        )
        addInfoNotification(t('Tour time slots copied'))
        refetch()
        onClose()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.AllowedWeekdaysAreEmpty
          )
        ) {
          customErrorHandler(error, {
            title: t('Missing weekday'),
            contentText: t(
              'Unable to copy tour time slots because no weekdays were selected. Please select at least one weekday and try again.'
            ),
            confirmButtonLabel: t('Got it')
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DestinationDateToIsBeforeDestinationDateFrom
          )
        ) {
          customErrorHandler(error, {
            title: t('Invalid date range'),
            contentText: t(
              "The destination date to date can't be earlier than the destination date. Please adjust the date range and try again."
            ),
            confirmButtonLabel: t('Got it')
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DestinationDateFromIsBeforeSourceDate
          )
        ) {
          customErrorHandler(error, {
            title: t('Invalid date entry'),
            contentText: t(
              "The selected destination date from can't be earlier than source date. Please choose an another date and try again."
            ),
            confirmButtonLabel: t('Got it')
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.TourTimeSlotNotFound
          )
        ) {
          customErrorHandler(error, {
            title: t('0 tour time slots found in source date'),
            contentText: t(
              'The selected source date has no time slots. Please choose an another date and try again.'
            ),
            confirmButtonLabel: t('Got it')
          })
        } else {
          defaultErrorHandler(error, t('Error while copying tour time slots'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      copyTourTimeSlots,
      customErrorHandler,
      defaultErrorHandler,
      onClose,
      refetch,
      setShowBackdrop,
      t,
      tourId
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Copy tour time slots')}
          />
        }
        footer={
          <SaveButton type="submit" form={COPY_TOUR_TIME_SLOTS_FORM_ID}>
            {t('Copy')}
          </SaveButton>
        }
      >
        <CopySlotsForm
          formId={COPY_TOUR_TIME_SLOTS_FORM_ID}
          onSubmit={handleSubmit}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
