import React, {SyntheticEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {RegisterFunction} from '../../../../../utils/forms'
import {
  useIsIntegerWithMaxBoundary,
  useIsNonNegativeInteger
} from '../../../../../utils/formsValidations'

import {FormInput} from '../../../../visual'
import {useEditorFormStyles} from '../editorUtils'

export const LAYOUT_PRICING_FORM_ID = 'layout pricing editor form'

export interface IEditorFormData {
  name: string
  maxTicketsPerOrder: string
  maxNumberOfOccupiedSeatsOnEcommerce: number
  maxNumberOfOccupiedSeatsOnRetail: number
}

interface IEditorFormProps {
  onSubmit: (e: SyntheticEvent) => Promise<void>
  register: RegisterFunction
  auditoriumCapacity: number
}

export const EditorForm: React.FC<IEditorFormProps> = ({
  onSubmit,
  register,
  auditoriumCapacity
}: IEditorFormProps) => {
  const {t} = useTranslation()
  const classes = useEditorFormStyles()
  const isIntegerWithMaxBoundary =
    useIsIntegerWithMaxBoundary(auditoriumCapacity)
  const isNonNegativeInteger = useIsNonNegativeInteger()

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.wrapper}
      onSubmit={onSubmit}
      id={LAYOUT_PRICING_FORM_ID}
    >
      <div className={classes.formItem}>
        <FormInput
          label={t('Name', {context: 'object'})}
          placeholder={t('Name', {context: 'object'})}
          name="name"
          type="text"
          showRequiredLabel
          inputRef={register({required: true})}
        />
      </div>
      <div className={classes.formItem}>
        <FormInput
          label={t('Maximum online purchased tickets per order')}
          placeholder={t('Maximum tickets per order')}
          name="maxTicketsPerOrder"
          type="number"
          inputRef={register({required: true})}
          showRequiredLabel
        />
      </div>
      <div className={classes.formItem}>
        <FormInput
          label={t('Max occupied seats on ecommerce channel')}
          name="maxNumberOfOccupiedSeatsOnEcommerce"
          type="number"
          inputRef={register({
            required: true,
            validate: {isNonNegativeInteger, isIntegerWithMaxBoundary}
          })}
          showRequiredLabel
        />
        <div className={classes.formItem}>
          <FormInput
            label={t('Max occupied seats on retail channel')}
            name="maxNumberOfOccupiedSeatsOnRetail"
            type="number"
            inputRef={register({
              required: true,
              validate: {isNonNegativeInteger, isIntegerWithMaxBoundary}
            })}
            showRequiredLabel
          />
        </div>
      </div>
    </form>
  )
}
