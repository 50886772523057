import {WarehouseProductsFilterInput} from '../../../../__generated__/schema'
import {StockLevelOption} from './types'

export const transformStockLevelToFilter = (
  stockLevel?: StockLevelOption
): Partial<WarehouseProductsFilterInput> => {
  switch (stockLevel) {
    case StockLevelOption.Positive:
      return {isStockLevelOutOfStock: false}
    case StockLevelOption.BelowOptimal:
      return {isStockLevelBelowOptimalStockLevel: true}
    case StockLevelOption.BelowMinimal:
      return {isStockLevelBelowMinStockLevel: true}
    case StockLevelOption.OutOfStock:
      return {isStockLevelOutOfStock: true}
    case StockLevelOption.Negative:
      return {isStockLevelNegative: true}
    default:
      return {}
  }
}
