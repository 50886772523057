import EditIcon from '@mui/icons-material/Edit'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClaimPropertiesFragment,
  ClaimState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {
  useTranslateClaimState,
  useTranslateClaimStateDescription
} from '../../../../../hooks/claimState'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {claimStateColors} from '../../../../constants'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {useUpdateClaimReason} from '../../claims/graphql'
import {ClaimPrintingGroupButton} from '../ClaimPrintingGroupButton'
import {CreateMessageDrawer} from '../messageDrawer/CreateMessageDrawer'
import {MessageFormField} from '../messageDrawer/types'
import {ClaimDialog} from './ClaimDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'row'
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2)
  },
  stateRow: {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr'
  }
}))

const useClaimInformation = (claim: ClaimPropertiesFragment) => {
  const {t} = useTranslation()
  const {formatDateTime} = useDateTimeFormatters()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const formatDateTimeFromString = useCallback(
    (value?: string | null) => value && formatDateTime(new Date(value)),
    [formatDateTime]
  )
  const common = [
    {caption: t('Value'), value: translateEffectiveClientPrice(claim.price)},
    {
      caption: t('Created'),
      value: claim.createdByName,
      subValue: formatDateTimeFromString(claim.createdAt)
    }
  ]
  const approved = {
    caption: t('Approved'),
    value: claim.approvedByName,
    subValue: formatDateTimeFromString(claim.approvedAt)
  }

  const lastUpdate = {
    caption: t('Last update'),
    value: claim.updatedByName,
    subValue: formatDateTimeFromString(claim.updatedAt)
  }

  switch (claim.state) {
    case ClaimState.Draft:
    case ClaimState.Deleted:
      return [...common, lastUpdate]
    case ClaimState.Approved:
    case ClaimState.Pending:
    case ClaimState.Refunded:
      return [...common, approved, lastUpdate]
    default:
      return []
  }
}

interface IGeneralProps {
  claim: ClaimPropertiesFragment
}

export const General: React.FC<IGeneralProps> = ({claim}: IGeneralProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateClaimReason = useUpdateClaimReason()
  const translateClaimState = useTranslateClaimState()
  const translateClaimStateDescription = useTranslateClaimStateDescription()
  const information = useClaimInformation(claim)
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const {
    state: isEditClaimDialogOpen,
    setTrue: openEditClaimDialog,
    setFalse: closeEditClaimDialog
  } = useBooleanState(false)
  const {
    state: isCreateMessageDrawerOpen,
    setTrue: openCreateMessageDrawer,
    setFalse: closeCreateMessageDrawer
  } = useBooleanState(false)
  const [reason, setReason] = useState<string>(claim.reason)
  const handleReasonInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setReason(e.target.value)
    },
    []
  )
  const handleOnSaveButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await updateClaimReason({id: claim.id, reason})
    } catch (e) {
      defaultErrorHandler(e, t('Error while updating claim reason'))
    } finally {
      setShowBackdrop(false)
      closeEditClaimDialog()
    }
  }, [
    claim.id,
    closeEditClaimDialog,
    defaultErrorHandler,
    reason,
    setShowBackdrop,
    t,
    updateClaimReason
  ])
  const classes = useStyles()
  return (
    <>
      {isEditClaimDialogOpen && (
        <ClaimDialog
          title={t('Update claim')}
          text={reason}
          onChange={handleReasonInputChange}
          confirmationButtonLabel={t('Save')}
          onConfirm={handleOnSaveButtonClick}
          onCancel={closeEditClaimDialog}
          isConfirmationButtonDisabled={reason === claim.reason}
        />
      )}
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h6">{claim.id}</Typography>
          <div className={classes.actionButtons}>
            {claim.state === ClaimState.Draft &&
              P([PermissionCode.UpdateClaimReason]) && (
                <Button
                  onClick={openEditClaimDialog}
                  startIcon={<EditIcon />}
                  color="primary"
                  variant="text"
                >
                  {t('Edit')}
                </Button>
              )}
            {P([PermissionCode.SendMessage]) && claim.lead?.data.email && (
              <Button
                color="primary"
                variant="text"
                onClick={openCreateMessageDrawer}
              >
                {t('Send message')}
              </Button>
            )}
            {P([PermissionCode.ReadTemplates]) && (
              <ChildrenOnEffectiveClientSelected>
                <ClaimPrintingGroupButton claimId={claim.id} />
              </ChildrenOnEffectiveClientSelected>
            )}
          </div>
        </div>
        <div className={classes.stateRow}>
          <EntityStateChip
            colorConf={claimStateColors[claim.state]}
            label={translateClaimState(claim.state)}
          />
          <Typography variant="caption">
            {translateClaimStateDescription(claim.state)}
          </Typography>
        </div>
        <SingleInfoColumn caption={t('Reason')} value={claim.reason} />
        <DividerSeparatedInfoRow information={information} />
      </div>
      {claim.lead && claim.lead.data.email && (
        <CreateMessageDrawer
          isOpen={isCreateMessageDrawerOpen}
          onClose={closeCreateMessageDrawer}
          leads={[{id: claim.lead.id, contact: claim.lead.data.email}]}
          defaultValues={{
            [MessageFormField.LeadIds]: [claim.lead.id]
          }}
        />
      )}
    </>
  )
}
