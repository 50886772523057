import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Scalars} from '../__generated__/schema'

export const useModifyZoneLabel = () => {
  const {t} = useTranslation()
  return useCallback(
    ({capacity, label}: {capacity: number; label: string}) =>
      t('Capacity {{capacity}}\n{{label}}', {
        capacity,
        label
      }),
    [t]
  )
}

export const useGetZoneLabelModifier = (
  eventSeats: Scalars['JSONObject'] = {zones: {}}
) => {
  const {t} = useTranslation()
  return useCallback(
    (uuid: string) =>
      ({label}: {capacity: number; label: string}) =>
        t('Available {{availableZoneCapacity}}\n{{label}}', {
          // @ts-ignore
          availableZoneCapacity: eventSeats.zones[uuid].states.available || 0,
          label
        }),
    [eventSeats, t]
  )
}
