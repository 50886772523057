import {Box, Paper, Tab, Tabs, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  GetCustomerQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {EventRow} from './EventRow'

enum SaleTab {
  Upcoming = 'upcoming',
  Past = 'past'
}

const useStyles = makeStyles<Theme>((theme) => ({
  typography: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  tabs: {
    borderBottom: `solid ${theme.palette.divider} 1px`
  },
  paper: {
    padding: theme.spacing(0, 2)
  },
  notFound: {
    padding: theme.spacing(2.5, 0)
  },
  leftInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}))

interface ISalesSectionProps {
  id: string
  sales: GetCustomerQuery['customer']['sales']
  customerId: number
}

export const SalesSection: React.FC<ISalesSectionProps> = ({
  id,
  sales,
  customerId
}: ISalesSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [tab, setTab] = useState<SaleTab>(SaleTab.Upcoming)
  const filteredSales = sales.filter((sale) =>
    tab === SaleTab.Upcoming
      ? dayjs(sale.event?.startsAt || sale.tourTimeSlot?.startsAt).isAfter(
          dayjs()
        )
      : dayjs(sale.event?.startsAt || sale.tourTimeSlot?.startsAt).isBefore(
          dayjs()
        )
  )
  const {formatDateTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const history = useHistory()
  const classes = useStyles()
  const handleTabChange = useCallback(
    (e, newTab: SaleTab) => setTab(newTab),
    []
  )
  const handleLinkButtonClick = useCallback(
    (cartId: number) => () =>
      history.push(routeTo.admin.customers.cartInfo(customerId, cartId)),
    [customerId, history]
  )
  return (
    <Box id={id}>
      <Typography variant="subtitle1" className={classes.typography}>
        {t('Purchase history')}
      </Typography>
      <Paper variant="outlined" className={classes.paper}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{root: classes.tabs}}
        >
          <Tab label={t('Upcoming')} value={SaleTab.Upcoming} />
          <Tab label={t('Past')} value={SaleTab.Past} />
        </Tabs>
        {filteredSales.map((sale) =>
          sale.event ? (
            <EventRow
              key={sale.id}
              leftInfo={
                <div className={classes.leftInfo}>
                  <Typography variant="body2">
                    {formatDateTime(new Date(sale.event.startsAt))}
                  </Typography>
                  {'•'}
                  <Typography variant="body2">
                    {getUserLocaleTranslation(sale.event.names)}
                  </Typography>
                </div>
              }
              ticketsCount={sale.items?.length || 0}
              price={sale.price}
              onLinkButtonClick={
                P([PermissionCode.ReadCart])
                  ? handleLinkButtonClick(sale.cart.id)
                  : undefined
              }
            />
          ) : (
            sale.tourTimeSlot && (
              <EventRow
                key={sale.id}
                leftInfo={
                  <div className={classes.leftInfo}>
                    <Typography variant="body2">
                      {formatDateTime(new Date(sale.tourTimeSlot.startsAt))}
                    </Typography>
                    {'•'}
                    <Typography variant="body2">
                      {sale.tourTimeSlot.tour.name}
                    </Typography>
                  </div>
                }
                ticketsCount={sale.items?.length || 0}
                price={sale.price}
                onLinkButtonClick={
                  P([PermissionCode.ReadCart])
                    ? handleLinkButtonClick(sale.cart.id)
                    : undefined
                }
              />
            )
          )
        )}
        {filteredSales.length === 0 && (
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.notFound}
          >
            {tab === SaleTab.Upcoming
              ? t('No upcoming purchases have been found')
              : t('No past purchases have been found')}
          </Typography>
        )}
      </Paper>
    </Box>
  )
}
