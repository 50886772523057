import {Box} from '@mui/material'
import {useGridApiRef} from '@mui/x-data-grid-pro'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {AdmissionTypesStatisticsQuery} from '../../../../../__generated__/schema'
import {RenderOnData} from '../../../../common'
import {RevenuesTable} from '../../components/admissionsStatisticsTables/RevenuesTable'
import {useAdmissionTypesStatistics} from '../../graphql'
import {Toolbar} from './Toolbar'

interface IRevenuesProps {
  tourTimeSlotId: number
}

export const Revenues: React.FC<IRevenuesProps> = ({
  tourTimeSlotId
}: IRevenuesProps) => {
  const {t} = useTranslation()
  const dataGridApiRef = useGridApiRef()
  const {data, loading, error, refetch} = useAdmissionTypesStatistics({
    filter: {
      tourTimeSlotId
    },
    paginationInput: {
      offset: 0,
      limit: 500
    }
  })
  return (
    <Box sx={{height: '100%'}}>
      <Toolbar
        title={t('Revenues')}
        onReloadButtonClick={() => refetch()}
        onDownloadButtonClick={() =>
          dataGridApiRef.current.exportDataAsCsv({
            fileName: t<string>(
              'revenues-admission-types-statistics-{{tourTimeSlotId}}',
              {tourTimeSlotId}
            )
          })
        }
      />
      <RenderOnData<AdmissionTypesStatisticsQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>(
          'Error while loading admission types statistics'
        )}
      >
        {(data) => (
          <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
            <RevenuesTable apiRef={dataGridApiRef} data={data} />
          </Box>
        )}
      </RenderOnData>
    </Box>
  )
}
