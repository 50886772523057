import {Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetCustomerQuery} from '../../../../../__generated__/schema'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {DividerSeparatedInfoRow} from '../../../../common/DividerSeparatedInfoRow'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3)
  }
}))

interface IAccountSectionProps {
  id: string
  customer: GetCustomerQuery['customer']
}

export const AccountSection: React.FC<IAccountSectionProps> = ({
  id,
  customer
}: IAccountSectionProps) => {
  const {t} = useTranslation()
  const {formatDateTime, formatDateNumeric} = useDateTimeFormatters()
  const classes = useStyles()
  return (
    <Paper variant="outlined" id={id} className={classes.root}>
      <Typography variant="h6">{customer.loyaltyId}</Typography>
      <DividerSeparatedInfoRow
        information={[
          {
            caption: t('Username'),
            value: customer.username
          },
          {
            caption: t('Privacy policy consent'),
            value: customer.privacyPolicyConsentGrantedAt
              ? t('Granted')
              : t('Not granted')
          },
          {
            caption: t('Benefits expiration date'),
            value: customer.loyaltyLevelExpirationDate
              ? formatDateNumeric(new Date(customer.loyaltyLevelExpirationDate))
              : undefined
          },
          {
            caption: t('Account created at'),
            value: formatDateTime(new Date(customer.createdAt))
          }
        ]}
      />
    </Paper>
  )
}
