import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  BusinessPartnerState,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  LightweightWarehousesQuery,
  LightweightWarehousesQueryVariables,
  WarehouseState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {LIGHTWEIGHT_BUSINESS_PARTNERS, LIGHTWEIGHT_WAREHOUSES} from '../graphql'
import {useCreateWarehouseDocument} from './graphql'
import {IWarehouseDocumentForm, WarehouseDocumentFormType} from './types'
import {transformWarehouseFormDataToCreateWarehouseDocumentInput} from './utils'
import {WarehouseDocumentForm} from './WarehouseDocumentForm'

const CREATE_WAREHOUSE_DOCUMENT_FORM_ID = 'createWarehouseDocumentForm'

interface ICreateWarehouseDocumentDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateWarehouseDocumentDrawer: React.FC<ICreateWarehouseDocumentDrawerProps> =
  ({isOpen, onClose}: ICreateWarehouseDocumentDrawerProps) => {
    const {t} = useTranslation()
    const [skip, setSkip] = useState(!isOpen)
    const createWarehouseDocument = useCreateWarehouseDocument()
    const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
      useMutationAssistanceHooks()
    const history = useHistory()
    const {
      data: warehousesData,
      loading: warehousesLoading,
      error: warehousesError
    } = useQuery<
      LightweightWarehousesQuery,
      LightweightWarehousesQueryVariables
    >(LIGHTWEIGHT_WAREHOUSES, {
      variables: {filter: {states: [WarehouseState.Active]}},
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: businessPartnersData,
      loading: businessPartnersLoading,
      error: businessPartnersError
    } = useQuery<
      LightweightBusinessPartnersQuery,
      LightweightBusinessPartnersQueryVariables
    >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
      variables: {
        filter: {states: [BusinessPartnerState.Active]},
        paginationInput: {offset: 0, limit: 300}
      },
      fetchPolicy: 'network-only',
      skip
    })
    const handleSubmit = useCallback(
      async (formData: IWarehouseDocumentForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await createWarehouseDocument({
            input:
              transformWarehouseFormDataToCreateWarehouseDocumentInput(formData)
          })
          addInfoNotification(t('Warehouse document has been created'))
          if (data) {
            history.push(
              routeTo.admin.warehouseDocuments.detail(
                data.createWarehouseDocument.id
              )
            )
          }
          onClose()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while creating warehouse document')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createWarehouseDocument,
        defaultErrorHandler,
        history,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
      return () => setSkip(true)
    }, [isOpen])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create warehouse document')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_WAREHOUSE_DOCUMENT_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
          isLoading={warehousesLoading || businessPartnersLoading}
          errorMessage={
            (warehousesError && t('Error while loading warehouses')) ||
            (businessPartnersError &&
              t('Error while loading business partners'))
          }
        >
          {warehousesData && businessPartnersData && (
            <WarehouseDocumentForm
              formId={CREATE_WAREHOUSE_DOCUMENT_FORM_ID}
              onSubmit={handleSubmit}
              warehouses={warehousesData.warehouses.items}
              businessPartners={businessPartnersData.businessPartners.items}
              formType={WarehouseDocumentFormType.Create}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
