import {Box, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'

interface IInfoListItemProps {
  title: string
  description: string
  count: number
  sx?: SxProps<Theme>
}

export const InfoListItem: React.FC<IInfoListItemProps> = ({
  title,
  description,
  count,
  sx
}: IInfoListItemProps) => {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: `
          "title        count"
          "description  description"
        `,
        ...sx
      }}
    >
      <Typography variant="body1">{title}</Typography>
      <Typography
        variant="caption"
        fontWeight="bold"
        component="div"
        align="right"
      >
        {t('{{count}} seats', {
          count
        })}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{gridArea: 'description'}}
      >
        {description}
      </Typography>
    </Box>
  )
}
