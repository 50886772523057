import {EventContentArg} from '@fullcalendar/common'
import {EventReceiveArg, EventResizeDoneArg} from '@fullcalendar/interaction'
import {DateSpanApi, EventApi, EventDropArg} from '@fullcalendar/react'
import {makeStyles} from '@mui/styles'
import _ from 'lodash'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'

import {
  EventState,
  ShowAgeClassificationCode,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowVersionCode
} from '../../../../../__generated__/schema'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useNotifications} from '../../../../context/notifications'
import {getEventEndDate} from '../common'
import {useAddEventProperties} from '../context/addEventProperties'
import {useMoveEvent, useResizeEvent} from '../graphql'
import {ViewType} from './types'

const getResource = (event: EventApi) => {
  const resources = event.getResources().map(({id}) => id)
  if (resources.length !== 1) throw new Error('Invalid resources')
  return resources[0]
}

const useStyles = makeStyles(() => ({
  eventPreventResize: {
    '& .fc-event-resizer-end': {
      pointerEvents: 'none'
    }
  }
}))

export const useGetEventClassNames = () => {
  const classes = useStyles()

  return useCallback(
    (args: EventContentArg) =>
      args.event.extendedProps.state !== EventState.Draft
        ? [classes.eventPreventResize]
        : [],
    [classes.eventPreventResize]
  )
}

export const useEventReceive = () => {
  const [, setAddEventProperties] = useAddEventProperties()
  return useCallback(
    ({event, draggedEl, revert}: EventReceiveArg) => {
      // draggedElement with "data" attribute represents an intention for creation of event. All other cases are reverted
      if (draggedEl.hasAttribute('data')) {
        const {
          title,
          extendedProps: {
            showId,
            versionCode,
            soundMixCode,
            formatCode,
            duration,
            ageClassificationCode
          }
        } = event
        const start = event.start!

        // Note: we can not set id of event directly, possible only for pre-defined events that we drag into calendar
        // but that is not good for us, as at the time of dragging we do not know the id yet.
        // `d.event.setProp('id', value)` is not working
        // `d.event.id = value` causes error
        const newEventId = uuidv4()
        event.setExtendedProp('id', newEventId)

        const addEventData = {
          auditoriumId: parseInt(getResource(event), 10),
          showId,
          versionCode: versionCode as ShowVersionCode | null,
          soundMixCode: soundMixCode as ShowSoundMixCode | null,
          formatCode: formatCode as ShowFormatCode | null,
          ageClassificationCode:
            ageClassificationCode as ShowAgeClassificationCode | null,
          title,
          start: start.toISOString(),
          calendarEventId: newEventId,
          duration
        }

        // add end date
        if (event.start) {
          event.setEnd(getEventEndDate(event.start, addEventData.duration))
        }

        setAddEventProperties(addEventData)
      } else {
        revert()
      }
    },
    [setAddEventProperties]
  )
}

// Fired when already created event is moved to other time/day
export const useEventDrop = () => {
  const {t} = useTranslation()
  const moveEvent = useMoveEvent()
  const {addErrorNotification} = useNotifications()
  const defaultErrorHandlers = useDefaultErrorHandler()
  return useCallback(
    async ({event, revert}: EventDropArg) => {
      // We allow existing event date change via calendar just in "draft" state,
      // otherwise user is forced to do this via form in event settings
      if (event.extendedProps.state !== EventState.Draft) {
        revert()
        return
      }
      if (event.start) {
        try {
          await moveEvent({
            id: parseInt(event.id, 10),
            startsAt: event.start.toISOString()
          })
        } catch (err) {
          defaultErrorHandlers(err, t('Could not move event.'))
        }
      } else {
        addErrorNotification(t('Could not move event.'))
      }
    },
    [addErrorNotification, defaultErrorHandlers, moveEvent, t]
  )
}

export const useEventResize = () => {
  const {t} = useTranslation()
  const resizeEvent = useResizeEvent()
  const defaultErrorHandlers = useDefaultErrorHandler()
  return useCallback(
    async (info: EventResizeDoneArg) => {
      const {event} = info
      if (!event || !event.end || !event.start) {
        return
      }

      // We allow resize via calendar just in "draft" state, otherwise
      // user is forced to do this via form in event settings
      if (event.extendedProps.state !== EventState.Draft) {
        info.revert()
        return
      }

      const durationWithServiceTime =
        (event.end.getTime() - event.start.getTime()) / (1000 * 60)

      const serviceTime = event.extendedProps.serviceTime
      const newDuration = durationWithServiceTime - serviceTime

      // TODO: decide how to handle this case
      if (newDuration <= 0) {
        info.revert()

        // eslint-disable-next-line no-alert
        alert(
          t(
            'Can not make event time interval smaller than the event service time. ({{count}} minute)',
            {
              count: serviceTime
            }
          )
        )
        return
      }

      try {
        await resizeEvent(parseInt(event.id, 10), newDuration)
      } catch (err) {
        defaultErrorHandlers(err, t('Could not resize event.'))
      }
    },
    [defaultErrorHandlers, resizeEvent, t]
  )
}

// Whether to allow drag and drop
export const useAllowEventDragAndDrop = (
  viewType: ViewType,
  isLoadingMore?: boolean
) =>
  useCallback(
    (span: DateSpanApi, movingEvent: EventApi | null) =>
      span && movingEvent && viewType !== ViewType.Month && !isLoadingMore
        ? getResource(movingEvent) === _.get(span, 'resource.id')
        : false,
    [isLoadingMore, viewType]
  )
