import {ApolloError} from 'apollo-client'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {
  EnabledDiscountsForEventQuery,
  ErrorMessages,
  SellingChannel
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {useNotifications} from '../../../../context/notifications'
import {isTicketItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {DiscountCodeErrorDialog} from '../DiscountCodeErrorDialog'
import {
  useAddDiscountToCartTicketItems,
  useDecrementDiscountQuantityFromCartTicketItems,
  useEnabledDiscountsForEvent,
  useIncrementDiscountQuantityForCartTicketItems,
  useRemoveDiscountFromItem
} from '../graphql'
import {useDiscountErrorHandler} from '../utils'
import {ApplyDiscountsPageTemplate} from './ApplyDiscountsPageTemplate'
import {ChildrenOnValidEventData} from './ChildrenOnValidEventData'
import {Content} from './Content'

export const ApplyDiscountsForEvent = () => {
  const params = useParams<{eventId: string}>()
  const {t} = useTranslation()
  const eventId = parseInt(params.eventId, 10)
  const history = useHistory()
  const handleBackArrowClick = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.eventAuditoriumPreview(eventId))
  }, [eventId, history])
  const {data, loading, error} = useEnabledDiscountsForEvent({
    id: eventId,
    discountsFilter: {channel: SellingChannel.Retail}
  })
  const {currentCartId, currentCart, updateCurrentCart} = useCurrentCart()
  const addDiscountToCartTicketItems = useAddDiscountToCartTicketItems()
  const incrementDiscountQuantityForCartTicketItems =
    useIncrementDiscountQuantityForCartTicketItems()
  const decrementDiscountQuantityFromCartTicketItems =
    useDecrementDiscountQuantityFromCartTicketItems()
  const {addInfoNotification} = useNotifications()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const discountErrorHandler = useDiscountErrorHandler()
  const handleAddButtonClick = useCallback(
    async ({
      discountId,
      selectedItemIds,
      discountCodeId,
      customerLoyaltyId,
      onSuccessCallback
    }: {
      discountId: number
      selectedItemIds: number[]
      discountCodeId?: number
      customerLoyaltyId?: string
      onSuccessCallback?: () => void
    }) => {
      try {
        setShowBackdrop(true)
        const {data} = await addDiscountToCartTicketItems({
          cartId: currentCartId!,
          discountId,
          itemIds: selectedItemIds,
          discountCodeId,
          customerLoyaltyId
        })
        if (data) {
          updateCurrentCart()
        }
        onSuccessCallback && onSuccessCallback()
      } catch (error) {
        discountErrorHandler(error, t('Adding discount to ticket items failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      addDiscountToCartTicketItems,
      currentCartId,
      updateCurrentCart,
      discountErrorHandler,
      t
    ]
  )
  const [exceededUsageLimitError, setExceededUsageLimitError] =
    useState<ApolloError | null>(null)
  const incrementDiscountQuantity = useCallback(
    async ({
      discountId,
      discountCodeId,
      increment,
      notificationOnSuccess,
      customerLoyaltyId,
      onSuccessCallback
    }: {
      discountId: number
      discountCodeId?: number
      increment?: number
      notificationOnSuccess?: string
      customerLoyaltyId?: string
      onSuccessCallback?: () => void
    }) => {
      try {
        setShowBackdrop(true)
        const {data} = await incrementDiscountQuantityForCartTicketItems({
          cartId: currentCartId!,
          eventId,
          discountId,
          increment,
          discountCodeId,
          customerLoyaltyId
        })
        if (data) {
          updateCurrentCart()
          notificationOnSuccess && addInfoNotification(notificationOnSuccess)
        }
        onSuccessCallback && onSuccessCallback()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DiscountCodeHasExceededUsageLimit
          )
        ) {
          setExceededUsageLimitError(error)
        } else {
          discountErrorHandler(
            error,
            t('Adding discount to ticket items failed')
          )
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      incrementDiscountQuantityForCartTicketItems,
      currentCartId,
      eventId,
      updateCurrentCart,
      addInfoNotification,
      discountErrorHandler,
      t
    ]
  )
  const decrementDiscountQuantity = useCallback(
    async ({
      discountId,
      discountCodeId,
      decrement,
      notificationOnSuccess
    }: {
      discountId: number
      discountCodeId?: number
      decrement?: number
      notificationOnSuccess?: string
    }) => {
      setShowBackdrop(true)
      try {
        const {data} = await decrementDiscountQuantityFromCartTicketItems({
          cartId: currentCartId!,
          eventId,
          discountCodeId,
          discountId,
          decrement
        })
        if (data) {
          updateCurrentCart()
          notificationOnSuccess && addInfoNotification(notificationOnSuccess)
        }
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Removing discount from ticket items failed')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      currentCartId,
      decrementDiscountQuantityFromCartTicketItems,
      defaultErrorHandler,
      eventId,
      updateCurrentCart,
      setShowBackdrop,
      t
    ]
  )
  const removeDiscountFromItem = useRemoveDiscountFromItem()
  const getChipDeleteButtonClickHandler = useCallback(
    (itemId: number, discountId: number) => async () => {
      try {
        setShowBackdrop(true)
        const {data} = await removeDiscountFromItem({
          itemId,
          discountId,
          cartId: currentCartId!
        })
        if (data) {
          updateCurrentCart()
        }
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Removing discount from ticket item failed')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      currentCartId,
      defaultErrorHandler,
      removeDiscountFromItem,
      updateCurrentCart,
      setShowBackdrop,
      t
    ]
  )
  return (
    <ApplyDiscountsPageTemplate onBackArrowClick={handleBackArrowClick}>
      <RenderOnData<EnabledDiscountsForEventQuery>
        data={data}
        loading={loading}
        error={error}
        dataCondition={(data) => Boolean(data && data.event && currentCart)}
        ignoreLoadingIfData
        errorMessage={t<string>('Could not load event data')}
      >
        {({event}) => {
          const ticketItems = (currentCart?.items || [])
            .filter(isTicketItemPropertiesFragment)
            .filter((item) => item.eventSeat.event.id === event.id)
            .filter((item) => item.reservation === null)
          return (
            <ChildrenOnValidEventData ticketItems={ticketItems} event={event}>
              <>
                {exceededUsageLimitError && (
                  <DiscountCodeErrorDialog
                    error={exceededUsageLimitError}
                    onConfirm={() => setExceededUsageLimitError(null)}
                  />
                )}
                <Content
                  ticketItems={ticketItems}
                  discounts={event.enabledDiscounts}
                  eventId={event.id}
                  onAddButtonClick={handleAddButtonClick}
                  incrementDiscountQuantity={incrementDiscountQuantity}
                  decrementDiscountQuantity={decrementDiscountQuantity}
                  getChipDeleteButtonClickHandler={
                    getChipDeleteButtonClickHandler
                  }
                />
              </>
            </ChildrenOnValidEventData>
          )
        }}
      </RenderOnData>
    </ApplyDiscountsPageTemplate>
  )
}
