import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  IconButton
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import {makeStyles} from '@mui/styles'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {
  AuditoriumLayoutPropertiesFragment,
  AuditoriumLayoutsQuery,
  AuditoriumLayoutState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {
  DRAWER_TRANSITION_DURATION,
  DrawerTemplate,
  DrawerTemplateHeader,
  EntityStateChip,
  NoEntity,
  RenderOnData,
  Tooltip,
  TopControls,
  TopControlsCreateButton
} from '../../../../common'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {DataGridTable} from '../../../../common/DataGridTable'
import {
  auditoriumLayoutStateColors,
  useAuditoriumLayoutStateTranslations
} from '../../../../constants'
import {useNotifications} from '../../../../context/notifications'
import {FormTextInput} from '../../../../form/FormTextInput'
import {
  AddAuditoriumLayoutForm,
  CREATE_AUDITORIUM_LAYOUT_FORM_ID,
  FormField,
  IAuditoriumLayoutForm
} from './AddForm'
import {useAuditoriumLayoutTypeToString} from './common'
import {
  useCopyAuditoriumLayout,
  useCreateAuditoriumLayout,
  useGetAuditoriumLayouts
} from './graphql'

interface ILayoutTableProps {
  venueId: number
  auditoriumId: number
  auditoriumLayouts: Array<AuditoriumLayoutPropertiesFragment>
}

const StateRenderer = ({state}: {state: AuditoriumLayoutState}) => {
  const auditoriumLayoutStateTranslations =
    useAuditoriumLayoutStateTranslations()
  return (
    <EntityStateChip
      label={auditoriumLayoutStateTranslations[state]}
      colorConf={auditoriumLayoutStateColors[state]}
      isDotHidden
    />
  )
}

const CopyCellRenderer = ({
  id,
  name,
  openCopyDialog
}: {
  id: number
  name: string
  openCopyDialog: (layoutId: number, layoutName: string) => void
}) => {
  const {t} = useTranslation()
  return (
    <Tooltip title={t('Duplicate')}>
      <IconButton onClick={() => openCopyDialog(id, name)}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  )
}

const ArrowCellRenderer = ({
  id,
  venueId,
  auditoriumId
}: {
  id: number
  venueId: number
  auditoriumId: number
}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(
        routeTo.admin.venues.detailAuditoriumLayout(venueId, auditoriumId, id)
      ),
    [auditoriumId, history, id, venueId]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

enum FieldName {
  LAYOUT_NAME = 'layoutName'
}

interface ICopyLayoutFormData {
  [FieldName.LAYOUT_NAME]: string
}

interface ICopyDialogProps {
  isOpen: boolean
  layoutId: number
  layoutName: null | string
  auditoriumId: number
  venueId: number
  closeDialog: () => void
}

const CopyDialog: React.FC<ICopyDialogProps> = ({
  isOpen,
  layoutId,
  layoutName,
  auditoriumId,
  venueId,
  closeDialog
}: ICopyDialogProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {
    register,
    unregister,
    setValue,
    watch,
    errors,
    handleSubmit,
    triggerValidation
  } = useForm<ICopyLayoutFormData>({
    defaultValues: {
      [FieldName.LAYOUT_NAME]: t('Copy of {{layoutName}}', {layoutName})
    }
  })

  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const copyAuditoriumLayout = useCopyAuditoriumLayout(auditoriumId)
  const submit = async (data: ICopyLayoutFormData) => {
    try {
      const result = await copyAuditoriumLayout({
        auditoriumId,
        id: layoutId,
        name: data[FieldName.LAYOUT_NAME]
      })
      addInfoNotification(t('Auditorium layout duplicated.'))
      closeDialog()
      if (result && result.data && result.data.copyAuditoriumLayout) {
        history.push(
          routeTo.admin.venues.detailAuditoriumLayout(
            venueId,
            auditoriumId,
            result.data.copyAuditoriumLayout.id
          )
        )
      }
    } catch (err) {
      defaultErrorHandler(err, t('Error while copying auditorium layout.'))
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="duplicate-dialog-title"
    >
      <DialogTitle id="duplicate-dialog-title">
        {t('Duplicate auditorium layout?')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'Are you sure you want to duplicate auditorium layout {{name}}? Default state of the duplicate will be Draft. Layout pricings will not be duplicated.  Edit name of the duplicate below.',
            {
              name: layoutName
            }
          )}
        </DialogContentText>
        <form autoComplete="off">
          <FormTextInput<ICopyLayoutFormData>
            label={t('Name')}
            name={FieldName.LAYOUT_NAME}
            fullWidth
            register={register}
            unregister={unregister}
            setValue={setValue}
            watch={watch}
            errors={errors}
            autoFocus
            triggerValidation={triggerValidation}
            validationOptions={{
              required: true
            }}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t('Cancel')}
        </Button>
        <Button onClick={handleSubmit(submit)} color="primary">
          {t('Duplicate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const LayoutsTable: React.FC<ILayoutTableProps> = ({
  venueId,
  auditoriumId,
  auditoriumLayouts
}: ILayoutTableProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const auditoriumLayoutTypeTranslations = useAuditoriumLayoutTypeToString()
  const [copyDialogState, setCopyDialogState] = useState<{
    isOpen: boolean
    layoutId: number
    layoutName: string
  }>({
    isOpen: false,
    layoutId: auditoriumLayouts[0].id,
    layoutName: auditoriumLayouts[0].name
  })
  const handleOpenCopyDialog = useCallback(
    (layoutId: number, layoutName: string) =>
      setCopyDialogState({
        isOpen: true,
        layoutId,
        layoutName
      }),
    []
  )
  const handleCloseCopyDialog = useCallback(
    () =>
      setCopyDialogState({
        isOpen: false,
        layoutId: auditoriumLayouts[0].id,
        layoutName: auditoriumLayouts[0].name
      }),
    [auditoriumLayouts]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name', {context: 'object'}),
        field: 'name',
        flex: 1
      },
      {
        headerName: t('Capacity'),
        field: 'capacity',
        flex: 0.5
      },
      {
        headerName: t('Type'),
        field: 'type',
        valueFormatter: (params) =>
          auditoriumLayoutTypeTranslations(params.value),
        flex: 0.5
      },
      {
        headerName: t('State'),
        field: 'status',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: AuditoriumLayoutState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        flex: 0.5
      },
      {
        headerName: '',
        field: 'copy',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <CopyCellRenderer
              id={params.value}
              name={params.row.name}
              openCopyDialog={handleOpenCopyDialog}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <ArrowCellRenderer
              id={params.value}
              venueId={venueId}
              auditoriumId={auditoriumId}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      auditoriumId,
      auditoriumLayoutTypeTranslations,
      handleOpenCopyDialog,
      t,
      venueId
    ]
  )

  return (
    <>
      <DataGridTable
        columns={columns}
        rows={auditoriumLayouts}
        pagination={false}
        disableColumnFilter
        autoHeight
        hideFooter
        disableRowSelectionOnClick
        columnVisibilityModel={{
          copy: P([PermissionCode.CopyAuditoriumLayout]),
          arrow: P([PermissionCode.ReadAuditoriumLayout])
        }}
        initialState={{
          pinnedColumns: {right: ['copy', 'arrow']}
        }}
      />
      {copyDialogState.isOpen && (
        <CopyDialog
          {...copyDialogState}
          auditoriumId={auditoriumId}
          venueId={venueId}
          closeDialog={handleCloseCopyDialog}
        />
      )}
    </>
  )
}

interface IControlsProps {
  CreateButton: React.ReactNode
}

const Controls: React.FC<IControlsProps> = ({CreateButton}: IControlsProps) => {
  const {t} = useTranslation()

  return (
    <Box paddingBottom={4}>
      <TopControls label={t('Auditorium layout')} rightChild={CreateButton} />
    </Box>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  controls: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1)
  },
  drawerPaper: {
    width: '100%',
    maxWidth: 500
  }
}))

interface IAuditoriumLayoutsProps {
  venueId: number
  auditoriumId: number
}

export const AuditoriumLayouts: React.FC<IAuditoriumLayoutsProps> = ({
  venueId,
  auditoriumId
}: IAuditoriumLayoutsProps) => {
  const history = useHistory()
  const match = useRouteMatch()
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useGetAuditoriumLayouts(auditoriumId)

  const isAddEntityDrawerOpen =
    match.url ===
    routeTo.admin.venues.addAuditoriumLayout(venueId, auditoriumId)

  const onAddClick = useCallback(
    () =>
      history.push(
        routeTo.admin.venues.addAuditoriumLayout(venueId, auditoriumId)
      ),
    [auditoriumId, history, venueId]
  )

  const CreateButton = P([PermissionCode.CreateAuditoriumLayout]) ? (
    <TopControlsCreateButton
      key={1}
      label={t('Add new layout')}
      onClick={onAddClick}
      cypressId="layout-list-add-button"
    />
  ) : null

  const {
    errors,
    triggerValidation,
    watch,
    register,
    handleSubmit,
    setValue,
    control
  } = useForm<IAuditoriumLayoutForm>()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()

  const createAuditoriumLayout = useCreateAuditoriumLayout(
    venueId,
    auditoriumId
  )

  const handleClose = useCallback(() => history.goBack(), [history])
  const isCreateButtonDisabled =
    isEmpty(watch(FormField.NAME)?.trim()) || isEmpty(watch(FormField.TYPE))

  const handleAddAuditoriumLayoutSubmit = useCallback(
    async (data) => {
      try {
        setShowBackdrop(true)
        const auditoriumLayout = await createAuditoriumLayout({
          auditoriumId,
          type: data.type,
          data: {name: data[FormField.NAME].trim(), layout: {}}
        })
        if (
          auditoriumLayout &&
          auditoriumLayout.data &&
          auditoriumLayout.data.createAuditoriumLayout
        ) {
          history.replace(
            routeTo.admin.venues.editAuditoriumLayout(
              venueId,
              auditoriumId,
              auditoriumLayout.data.createAuditoriumLayout.id
            )
          )
        }
      } catch (e) {
        defaultErrorHandler(e, t('Error while creating auditorium layout'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      auditoriumId,
      createAuditoriumLayout,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t,
      venueId
    ]
  )

  return (
    <RenderOnData
      {...{loading, error, data}}
      errorMessage={t<string>('Could not load auditorium layouts')}
    >
      {(data: AuditoriumLayoutsQuery) => {
        const {auditoriumLayouts} = data

        return (
          <>
            <Drawer
              anchor="right"
              open={isAddEntityDrawerOpen}
              onClose={handleClose}
              classes={{paper: classes.drawerPaper}}
              keepMounted
              transitionDuration={DRAWER_TRANSITION_DURATION}
              SlideProps={{exit: true}}
            >
              <DrawerTemplate
                header={
                  <DrawerTemplateHeader
                    onLeftActionClick={handleClose}
                    title={t('New auditorium layout')}
                  />
                }
                footer={
                  <>
                    <CancelButton onClick={handleClose} />
                    <SaveButton
                      type="submit"
                      form={CREATE_AUDITORIUM_LAYOUT_FORM_ID}
                      disabled={isCreateButtonDisabled}
                    >
                      {t('Create')}
                    </SaveButton>
                  </>
                }
              >
                <AddAuditoriumLayoutForm
                  onSubmit={handleAddAuditoriumLayoutSubmit}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  triggerValidation={triggerValidation}
                  register={register}
                  handleSubmit={handleSubmit}
                />
              </DrawerTemplate>
            </Drawer>
            {auditoriumLayouts.length ? (
              <Box className={classes.controls}>
                <Controls CreateButton={CreateButton} />
                <LayoutsTable
                  {...{venueId, auditoriumId}}
                  auditoriumLayouts={auditoriumLayouts}
                />
              </Box>
            ) : (
              <NoEntity
                label={t('There are no layouts created yet.')}
                CreateButton={CreateButton}
              />
            )}
          </>
        )
      }}
    </RenderOnData>
  )
}
