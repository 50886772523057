import VisibilityIcon from '@mui/icons-material/Visibility'
import {SxProps} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ErrorMessages,
  ItemByPassCodeQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useGetEventSeatLocation} from '../../../../../hooks/getEventSeatLocation'
import {useTranslateTicketItemCheckStatus} from '../../../../../hooks/translateTicketItemCheckStatus'
import {useTranslateTourItemCheckStatus} from '../../../../../hooks/translateTourItemCheckStatus'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useCheckPassCodeParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {StopIcon} from '../../../../common/icons/StopIcon'
import {InfoModal, InfoModalColor} from '../../../../common/InfoModal'
import {
  isItemByPassCodeTicketItemFieldsFragment,
  isItemByPassCodeTourItemFieldsFragment
} from '../utils'

interface IInfo {
  status: string
  description: string
  secondaryDescription: string
}

const commonSxProps: SxProps<Theme> = (theme) => ({
  position: 'absolute',
  left: theme.spacing(1),
  right: theme.spacing(1),
  bottom: theme.spacing(1)
})

const useGetInfo = () => {
  const translateTicketItemCheckStatus = useTranslateTicketItemCheckStatus()
  const translateTourItemCheckStatus = useTranslateTourItemCheckStatus()
  const getEventSeatLocation = useGetEventSeatLocation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  return (item: ItemByPassCodeQuery['itemByPassCode']): IInfo | null => {
    if (isItemByPassCodeTicketItemFieldsFragment(item)) {
      const startsAt = new Date(item.eventSeat.event.startsAt)
      return {
        status: translateTicketItemCheckStatus(item.ticketItemCheckStatus),
        description: getEventSeatLocation(item.eventSeat),
        secondaryDescription: [
          formatDateNumeric(startsAt),
          formatTime(startsAt),
          getUserLocaleTranslation(item.eventSeat.event.names)
        ].join(' • ')
      }
    }
    if (isItemByPassCodeTourItemFieldsFragment(item)) {
      const startsAt = new Date(item.tourTimeSlot.startsAt)
      return {
        status: translateTourItemCheckStatus(item.tourItemCheckStatus),
        description: item.admissionType.name,
        secondaryDescription: [
          formatDateNumeric(startsAt),
          formatTime(startsAt),
          getUserLocaleTranslation(item.tourTimeSlot.names)
        ].join(' • ')
      }
    }
    return null
  }
}

interface IInfoModeModalProps {
  item: ItemByPassCodeQuery['itemByPassCode'] | null
  error: any | null
}

export const InfoModeModal: React.FC<IInfoModeModalProps> = ({
  item,
  error
}: IInfoModeModalProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {checkType, entityId} = useCheckPassCodeParams()
  const getInfo = useGetInfo()
  const history = useHistory()
  const handlInfoClick = useCallback(
    (itemId: number) => () => {
      history.push(
        routeTo.admin.checkTickets.ticketInfo(checkType, entityId, itemId)
      )
    },
    [checkType, entityId, history]
  )
  const commonErrorProps = {
    color: InfoModalColor.Red,
    Icon: StopIcon,
    sx: commonSxProps
  }
  if (error) {
    switch (error.message) {
      case ErrorMessages.ItemNotFoundDueToFilterEventIds:
        return <InfoModal title={t('Different event')} {...commonErrorProps} />
      case ErrorMessages.ItemNotFoundDueToFilterTourTimeSlotIds:
      case ErrorMessages.ItemNotFoundDueToFilterTourIds:
        return (
          <InfoModal
            title={t('Code not found for this tour or time slot')}
            {...commonErrorProps}
          />
        )
      case ErrorMessages.PassCodeNotFound:
      case ErrorMessages.ItemNotFound:
        return (
          <InfoModal title={t('Code was not found')} {...commonErrorProps} />
        )
      case ErrorMessages.EventNotPublished:
        return (
          <InfoModal
            title={t(
              "Event settings doesn't allow to check tickets. Please contact your supervisor."
            )}
            {...commonErrorProps}
          />
        )
      case ErrorMessages.RequestMadeUnauthorizedUser:
        return (
          <InfoModal
            title={ErrorMessages.RequestMadeUnauthorizedUser}
            {...commonErrorProps}
          />
        )
      case ErrorMessages.InvalidPassCodeCheckState:
        return (
          <InfoModal
            title={ErrorMessages.InvalidPassCodeCheckState}
            {...commonErrorProps}
          />
        )
      default:
        return null
    }
  }
  if (item) {
    const info = getInfo(item)
    return info ? (
      <InfoModal
        title={info.status}
        primaryDescription={info.description}
        secondaryDescription={info.secondaryDescription}
        color={InfoModalColor.Purple}
        Icon={VisibilityIcon}
        onInfoClick={
          P([PermissionCode.ReadItem]) ? handlInfoClick(item.id) : undefined
        }
        sx={commonSxProps}
      />
    ) : null
  }
  return null
}
