import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PaymentsFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnNoEffectiveClientSelected} from '../ChildrenOnNoEffectiveClientSelected'
import {CartSupportDetailDrawer} from '../components/cartPreviewDrawer/CartSupportDetailDrawer'
import {
  DEFAULT_PAYMENTS_OVERVIEW_FILTER_INPUT,
  PaymentsSearch
} from '../components/PaymentsSearch'
import {PrimaryHeader} from '../Header'
import {PaymentsSupportList} from './PaymentsSupportList'

export const PaymentsSupportPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<PaymentsFilterInput>(
    DEFAULT_PAYMENTS_OVERVIEW_FILTER_INPUT
  )
  const history = useHistory()
  const handleExited = useCallback(() => {
    history.replace(routeTo.admin.paymentsSupport.index())
  }, [history])
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Payments for support')}
          search={
            <PaymentsSearch
              location="paymentsSupport"
              onFilterChange={setSearchFilter}
            />
          }
        />
      }
    >
      <ChildrenOnNoEffectiveClientSelected showBlank>
        <PaymentsSupportList searchFilter={searchFilter} />
        {P([PermissionCode.ReadCart]) && (
          <Route path={routeTo.admin.paymentsSupport.cartInfo(':cartId')} exact>
            <CartSupportDetailDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnNoEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
