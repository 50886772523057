import {Grid} from '@mui/material'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {FormInput} from '../../../../visual'
import {VenuesDrawerActionsBar, VenuesDrawerForm} from '../common'

const LAYOUT_PRICING_FORM_ID = 'layout pricing form'

export interface ILayoutPricingFormData {
  name: string
}

interface IAddLayoutPricingFormProps {
  title: string
  onSubmit: (data: ILayoutPricingFormData) => void
}

export const AddLayoutPricingForm: React.FC<IAddLayoutPricingFormProps> = ({
  title,
  onSubmit
}: IAddLayoutPricingFormProps) => {
  const {t} = useTranslation()
  const {register, handleSubmit} = useForm<ILayoutPricingFormData>()
  const history = useHistory()

  return (
    <VenuesDrawerForm
      title={title}
      onClose={() => history.goBack()}
      ActionBar={
        <VenuesDrawerActionsBar
          submitText={t('Create')}
          formId={LAYOUT_PRICING_FORM_ID}
        />
      }
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        id={LAYOUT_PRICING_FORM_ID}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <FormInput
              label={t('Name', {context: 'object'})}
              placeholder={t('Name', {context: 'object'})}
              name="name"
              type="text"
              showRequiredLabel
              inputRef={register({required: true})}
            />
          </Grid>
        </Grid>
      </form>
    </VenuesDrawerForm>
  )
}
