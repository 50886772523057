import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {FieldValues} from 'react-hook-form'
import {
  FormSwitchInput,
  IFormSwitchInputProps
} from '../../../../form/FormSwitchInput'

import {BasePropertyStackableCardHeader} from '../../../../visual'

const useStyles = makeStyles(() => ({
  formSwitchInput: {
    justifyContent: 'flex-end',
    height: '100%'
  }
}))

interface IFormSwitchProps<FormValues extends FieldValues = FieldValues>
  extends IFormSwitchInputProps<FormValues> {
  mainLabel: string
  secondaryLabel: string
}

export const FormSwitch = <FormValues extends FieldValues = FieldValues>({
  mainLabel,
  secondaryLabel,
  className,
  ...rest
}: IFormSwitchProps<FormValues>) => {
  const classes = useStyles()
  return (
    <Grid container className={className}>
      <Grid item xs={7}>
        <BasePropertyStackableCardHeader {...{mainLabel, secondaryLabel}} />
      </Grid>
      <Grid item xs={5}>
        <FormSwitchInput<FormValues>
          {...rest}
          className={classes.formSwitchInput}
        />
      </Grid>
    </Grid>
  )
}
