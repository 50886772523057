import {LocaleCode} from '../__generated__/schema'
import {useUserInfo} from '../utils/auth'

export const useGetAllAvailableLocales = (): LocaleCode[] => {
  // you should put all locales, user can use to TRANSLATED_LOCALES_FRAGMENT
  return [LocaleCode.En, LocaleCode.Sk, LocaleCode.Cs, LocaleCode.Hu]
}

export const useGetClientLocales = (): LocaleCode[] => {
  const {effectiveClient} = useUserInfo()
  const allAvailableLocales = useGetAllAvailableLocales()
  return effectiveClient?.localeCodes || allAvailableLocales
}

export const useClientLocaleCode = () => {
  const {effectiveClient} = useUserInfo()

  return effectiveClient && effectiveClient.localeCodes.length > 0
    ? effectiveClient.localeCodes[0]
    : LocaleCode.En
}
