import {Button, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  UserPropertiesFragment,
  UserState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common/EntityState'
import {
  IActionButton,
  IOpenModalButtonProps,
  StateChangeModal
} from '../../../common/StateChangeModal'
import {StatusBarLayout} from '../../../common/StatusBarLayout'
import {userStateColors, useUserStateTranslations} from '../../../constants'
import {useNotifications} from '../../../context/notifications'

import {
  useAnonymizeUser,
  useDisableUser,
  useEnableUser,
  useResendInvitation,
  useRevokeInvitation
} from './graphql'
import {NewPasswordModal} from './resetPassword/NewPasswordModal'

interface IStateActionsBar {
  user: UserPropertiesFragment
}

const useShowStateActions = (id: number) => {
  const {user: currentUser} = useUserInfo()
  return currentUser && currentUser.id !== id
}

interface IButtonProps {
  onClick: () => any
  children: string
}

// TODO: consider sharing for all state action bars
const OutlinedButton = (props: IButtonProps) => {
  return <Button variant="outlined" color="primary" {...props} />
}

// TODO: consider sharing for all state action bars
const NonOutlinedButton = (props: IButtonProps) => {
  return <Button color="primary" {...props} />
}

const AnonymizeUserButton: React.FC<IActionButton> = ({id}: IActionButton) => {
  const {t} = useTranslation()
  const anonymizeUser = useAnonymizeUser()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async () => {
    try {
      await anonymizeUser(id)
      addInfoNotification(t('User was anonymized.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while anonymizing the user.'))
    }
  }

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <NonOutlinedButton onClick={onClick}>{t('Anonymize')}</NonOutlinedButton>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to anonymize the user?')}
      message={t(
        "Are you sure you want to anonymize the user? This action is irreversible and clears all of the user's personal information"
      )}
      submitButtonText={t('Anonymize')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const DisableUserButton: React.FC<IActionButton> = ({id}: IActionButton) => {
  const {t} = useTranslation()
  const disableUser = useDisableUser()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async () => {
    try {
      await disableUser(id)
      addInfoNotification(t('User was suspended.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while suspending the user.'))
    }
  }

  const OpenModalButton = ({onClick}: IOpenModalButtonProps) => {
    return (
      <NonOutlinedButton onClick={onClick}>
        {t('Suspend user')}
      </NonOutlinedButton>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to suspend the user?')}
      message={t(
        "Are you sure you want to cancel the user's account and ban him?"
      )}
      submitButtonText={t('Suspend')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const EnableUserButton: React.FC<IActionButton> = ({id}: IActionButton) => {
  const {t} = useTranslation()
  const enableUser = useEnableUser()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async () => {
    try {
      await enableUser(id)
      addInfoNotification(t('User was reactivated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while reactivating the user.'))
    }
  }

  const OpenModalButton = ({onClick}: IOpenModalButtonProps) => {
    return (
      <OutlinedButton onClick={onClick}>
        {t('Reactivate account')}
      </OutlinedButton>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to reactivate the user?')}
      message={t('Are you sure you want to reactivate the user?')}
      submitButtonText={t('Reactivate')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const RevokeUserButton: React.FC<IActionButton> = ({id}: IActionButton) => {
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const revokeInvitation = useRevokeInvitation(id)
  const history = useHistory()
  const onSubmit = async () => {
    try {
      await revokeInvitation()
      history.replace(routeTo.admin.users.home())
      addInfoNotification(t('Invitation was revoked.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while revoking the user.'))
    }
  }

  const OpenModalButton = ({onClick}: IOpenModalButtonProps) => {
    return (
      <NonOutlinedButton onClick={onClick}>{t('Revoke')}</NonOutlinedButton>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to revoke invitation?')}
      message={t('Are you sure you want to revoke invitation?')}
      submitButtonText={t('Revoke')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const ResendInvitationButton: React.FC<IActionButton> = ({
  id
}: IActionButton) => {
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const resendInvitation = useResendInvitation()

  const onSubmit = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await resendInvitation(id)
      addInfoNotification(t('Invitation has been sent'))
    } catch (error) {
      defaultErrorHandler(error, t('Sending invitation has failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    id,
    resendInvitation,
    setShowBackdrop,
    t
  ])

  const OpenModalButton = ({onClick}: IOpenModalButtonProps) => {
    return (
      <OutlinedButton onClick={onClick}>
        {t('Resend invitation')}
      </OutlinedButton>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to resend invitation?')}
      message={t('Are you sure you want to resend invitation?')}
      submitButtonText={t('Resend')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const useBottomContentStyles = makeStyles<Theme>((theme) => ({
  signInRow: {
    marginTop: theme.spacing(1)
  }
}))

const BottomContent: React.FC<
  Pick<UserPropertiesFragment, 'state' | 'lastLoggedIn'>
> = ({
  state,
  lastLoggedIn
}: Pick<UserPropertiesFragment, 'state' | 'lastLoggedIn'>) => {
  const {t} = useTranslation()
  const classes = useBottomContentStyles()
  const userStateTranslations = useUserStateTranslations()
  const {formatDateTime} = useDateTimeFormatters()

  const config = {
    [UserState.Active]: {
      chipLabel: userStateTranslations.active,
      desc: t('User is able to sign in.'),
      style: userStateColors.active
    },
    [UserState.Invited]: {
      chipLabel: userStateTranslations.invited,
      desc: t('User got email invitation which was not confirmed yet.'),
      style: userStateColors.invited
    },
    [UserState.Disabled]: {
      chipLabel: userStateTranslations.disabled,
      desc: t('User is unable to sign in.'),
      style: userStateColors.disabled
    },
    [UserState.Anonymized]: {
      chipLabel: userStateTranslations.anonymized,
      desc: t('User is unable to sign in.'),
      style: userStateColors.anonymized
    }
  }[state]

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <EntityStateChip label={config.chipLabel} colorConf={config.style} />
        </Grid>
        <Grid item>
          <Typography variant="caption">{config.desc}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.signInRow}>
        <Grid item>
          <Typography variant="caption">{t('Last sign in:')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            {lastLoggedIn ? formatDateTime(new Date(lastLoggedIn)) : t('Never')}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export const StateActionsBar: React.FC<IStateActionsBar> = ({
  user
}: IStateActionsBar) => {
  const {P} = useEnsurePermissions()
  const showStateActions = useShowStateActions(user.id)
  const {user: userInfo} = useUserInfo()

  let rightSideContent = null

  if (user.state === UserState.Active) {
    rightSideContent = (P([PermissionCode.DisableUser]) ||
      P([PermissionCode.ChangePassword]) ||
      user.id === userInfo?.id) && (
      <Grid container direction="row" alignItems="center" spacing={2}>
        {showStateActions && P([PermissionCode.DisableUser]) && (
          <Grid item>
            <DisableUserButton id={user.id} />
          </Grid>
        )}
        {(P([PermissionCode.ChangePassword]) || user.id === userInfo?.id) && (
          <Grid item>
            <NewPasswordModal user={user} />
          </Grid>
        )}
      </Grid>
    )
  }

  if (user.state === UserState.Disabled && showStateActions) {
    rightSideContent = (P([PermissionCode.AnonymizeUser]) ||
      P([PermissionCode.EnableUser])) && (
      <Grid container direction="row" spacing={2}>
        {P([PermissionCode.AnonymizeUser]) && (
          <Grid item>
            <AnonymizeUserButton id={user.id} />
          </Grid>
        )}
        {P([PermissionCode.EnableUser]) && (
          <Grid item>
            <EnableUserButton id={user.id} />
          </Grid>
        )}
      </Grid>
    )
  }

  if (user.state === UserState.Invited && showStateActions) {
    rightSideContent = (P([PermissionCode.ResendInvitation]) ||
      P([PermissionCode.RevokeInvitation])) && (
      <Grid container direction="row" spacing={2}>
        {P([PermissionCode.RevokeInvitation]) && (
          <Grid item>
            <RevokeUserButton id={user.id} />
          </Grid>
        )}
        {P([PermissionCode.ResendInvitation]) && (
          <Grid item>
            <ResendInvitationButton id={user.id} />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <StatusBarLayout
      rightSideContent={rightSideContent}
      bottomContent={
        <BottomContent state={user.state} lastLoggedIn={user.lastLoggedIn} />
      }
      title={user.username}
      iconType="user"
    />
  )
}
