import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, Typography} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiGrantQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {DataGridTable} from '../../../../common/DataGridTable'
import {useAssignClientsToApiGrant} from '../graphql'
import {ManageClientsDrawer} from './ManageClientsDrawer'

interface IClientsSectionProps {
  id: string
  label: string
  clients: ApiGrantQuery['apiGrant']['clientsOwningResources']
  apiGrantId: number
}

export const ClientsSection: React.FC<IClientsSectionProps> = ({
  id,
  label,
  clients,
  apiGrantId
}: IClientsSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const assignClientsToApiGrant = useAssignClientsToApiGrant()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {
    state: isManageClientsDrawerOpen,
    setTrue: openManageClientsDrawer,
    setFalse: closeManageClientsDrawer
  } = useBooleanState(false)
  const handleSaveButtonClick = useCallback(
    async (selectedClientIds: number[]) => {
      try {
        setShowBackdrop(true)
        await assignClientsToApiGrant({
          id: apiGrantId,
          clientIds: selectedClientIds
        })
        addInfoNotification(t('Clients have been updated'))
        closeManageClientsDrawer()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while assigning clients to API grant')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      apiGrantId,
      assignClientsToApiGrant,
      closeManageClientsDrawer,
      defaultErrorHandler,
      setShowBackdrop,
      t
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('ID'),
        field: 'id',
        minWidth: 100
      },
      {
        headerName: t('Name'),
        field: 'name',
        flex: 1
      },
      {
        headerName: t('Town'),
        field: 'town',
        valueGetter: (params) => params.row.legalAddress.town,
        flex: 1
      }
    ],
    [t]
  )
  return (
    <>
      <Box id={id}>
        <Box
          sx={{
            pb: 1,
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle1">{label}</Typography>
          {P([
            PermissionCode.AssignClientsToApiGrant,
            PermissionCode.ReadClients
          ]) && (
            <Button
              variant="text"
              color="primary"
              startIcon={<EditIcon />}
              onClick={openManageClientsDrawer}
            >
              {t('Manage')}
            </Button>
          )}
        </Box>
        <DataGridTable
          columns={columns}
          rows={clients}
          autoHeight
          disableColumnMenu
          disableRowSelectionOnClick
          pagination={false}
          hideFooter
          initialState={{
            pinnedColumns: {left: ['id']}
          }}
          localeText={{noRowsLabel: t('No clients to show')}}
        />
      </Box>
      <ManageClientsDrawer
        isOpen={isManageClientsDrawerOpen}
        onClose={closeManageClientsDrawer}
        addedClientIds={clients.map(({id}) => id)}
        onSaveButtonClick={handleSaveButtonClick}
      />
    </>
  )
}
