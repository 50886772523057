import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import {Typography} from '@mui/material'
import {TypographyProps} from '@mui/material/Typography/Typography'
import React from 'react'
import {LocaleCode, WarehouseProduct} from '../../../../__generated__/schema'
import {useLocale} from '../../../context/locale'

const getStock = ({
  stock,
  minStock,
  optimalStock,
  localeCode
}: {
  stock: number
  minStock: number
  optimalStock: number
  localeCode: LocaleCode
}): {color: TypographyProps['color']; stock: string} => {
  if (stock <= 0) {
    return {
      color: 'error.main',
      stock: stock.toLocaleString(localeCode, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      })
    }
  } else if (stock <= minStock) {
    return {
      color: 'warning.main',
      stock: stock.toLocaleString(localeCode, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      })
    }
  } else if (stock <= optimalStock) {
    return {
      color: 'info.main',
      stock: stock.toLocaleString(localeCode, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      })
    }
  } else {
    return {
      color: 'initial',
      stock: stock.toLocaleString(localeCode, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      })
    }
  }
}

export const StockCellRenderer = ({
  warehouseProduct
}: {
  warehouseProduct?: Pick<
    WarehouseProduct,
    'stock' | 'minStockLevel' | 'optimalStockLevel'
  >
}) => {
  const {localeCode} = useLocale()
  const {color, stock} = getStock({
    stock: warehouseProduct?.stock ?? 0,
    minStock: warehouseProduct?.minStockLevel ?? 0,
    optimalStock: warehouseProduct?.optimalStockLevel ?? 0,
    localeCode
  })
  return warehouseProduct ? (
    <Typography variant="body2" color={color}>
      {stock}
    </Typography>
  ) : null
}

export const NegativeStockCellRenderer = ({
  isNegativeStockEnabled
}: {
  isNegativeStockEnabled?: boolean
}) =>
  typeof isNegativeStockEnabled !== 'undefined' ? (
    isNegativeStockEnabled ? (
      <CheckCircleIcon sx={{color: 'error.main'}} />
    ) : (
      <CloseIcon />
    )
  ) : null
