import dayjs from 'dayjs'
import {
  AddItemToWarehouseDocumentInput,
  BaseWarehouseDocumentFieldsFragment,
  CreateWarehouseDocumentInput,
  UpdateDraftWarehouseDocumentInput,
  UpdateDraftWarehouseDocumentItem,
  WarehouseDocumentSource,
  WarehouseDocumentType
} from '../../../../__generated__/schema'
import {convertValueToFloat} from '../../../../utils/conversions'
import {
  AddWarehouseProductFormField,
  IAddWarehouseProductForm
} from './detail/types'
import {
  DocumentTypeOption,
  IWarehouseDocumentForm,
  WarehouseDocumentFormField
} from './types'

const transformDocumentTypeOptionToWarehouseDocumentTypeAndSource = (
  type: DocumentTypeOption
) => {
  switch (type) {
    case DocumentTypeOption.Outgoing:
      return {
        type: WarehouseDocumentType.Outgoing,
        source: WarehouseDocumentSource.Stock
      }
    case DocumentTypeOption.StockWriteOff:
      return {
        type: WarehouseDocumentType.Outgoing,
        source: WarehouseDocumentSource.StockWriteOff
      }
    case DocumentTypeOption.Incoming:
    default:
      return {
        type: WarehouseDocumentType.Incoming,
        source: WarehouseDocumentSource.Stock
      }
  }
}

export const transformWarehouseFormDataToCreateWarehouseDocumentInput = (
  formData: IWarehouseDocumentForm
): CreateWarehouseDocumentInput => ({
  warehouseId: formData[WarehouseDocumentFormField.WarehouseId],
  ...transformDocumentTypeOptionToWarehouseDocumentTypeAndSource(
    formData[WarehouseDocumentFormField.Type]
  ),
  thirdPartyDocumentId:
    formData[WarehouseDocumentFormField.ThirdPartyDocumentId] || undefined,
  note: formData[WarehouseDocumentFormField.Note] || undefined,
  deliveryDate: dayjs(formData[WarehouseDocumentFormField.DeliveryDate]).format(
    'YYYY-MM-DD'
  ),
  businessPartnerId:
    formData[WarehouseDocumentFormField.BusinessPartnerId] || undefined
})

export const transformWarehouseFormDataToUpdateDraftWarehouseDocumentInput = (
  formData: IWarehouseDocumentForm
): UpdateDraftWarehouseDocumentInput => ({
  deliveryDate: formData[WarehouseDocumentFormField.DeliveryDate]
    ? dayjs(formData[WarehouseDocumentFormField.DeliveryDate]).format(
        'YYYY-MM-DD'
      )
    : null,
  note: formData[WarehouseDocumentFormField.Note] || null,
  thirdPartyDocumentId:
    formData[WarehouseDocumentFormField.ThirdPartyDocumentId] || null,
  businessPartnerId:
    formData[WarehouseDocumentFormField.BusinessPartnerId] || null
})

export const transformAddWarehouseProductFormToAddItemToWarehouseDocumentInput =
  (
    formData: IAddWarehouseProductForm,
    warehouseDocumentId: number
  ): AddItemToWarehouseDocumentInput => ({
    warehouseProductId: formData[AddWarehouseProductFormField.ProductId],
    warehouseDocumentId,
    note: formData[AddWarehouseProductFormField.Note] || undefined,
    vatRate: parseInt(formData[AddWarehouseProductFormField.Vat], 10),
    quantity:
      convertValueToFloat(formData[AddWarehouseProductFormField.Quantity]) || 0,
    unitPriceVatExcluded:
      convertValueToFloat(formData[AddWarehouseProductFormField.Price]) || 0
  })

export const transformRowUpdateToUpdateDraftWarehouseDocumentItems = (
  rows: Pick<
    BaseWarehouseDocumentFieldsFragment['items'][number],
    'id' | 'note' | 'vatRate' | 'quantity' | 'unitPriceVatExcluded'
  >[]
): UpdateDraftWarehouseDocumentItem[] =>
  rows.map((row) => ({
    id: row.id,
    input: {
      note: row.note,
      vatRate: row.vatRate,
      quantity: row.quantity,
      unitPriceVatExcluded: row.unitPriceVatExcluded
    }
  }))
