import {useLazyQuery} from '@apollo/react-hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import {Chip, ListItemIcon, ListItemText, MenuItem} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightWarehousesQuery,
  LightweightWarehousesQueryVariables
} from '../../../../__generated__/schema'
import {Tooltip} from '../../../common'
import {LoadingSkeleton} from '../../../common/LoadingSkeleton'
import {Menu, useMenu} from '../../../common/Menu'
import {LIGHTWEIGHT_WAREHOUSES} from '../graphql'

interface IWarehousesChipProps {
  onChange: (warehouseId: number | undefined) => void
}

export const WarehousesChip: React.FC<IWarehousesChipProps> = ({
  onChange
}: IWarehousesChipProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const [selectedWarehouse, setSelectedWarehouse] =
    useState<{id: number; name: string} | undefined>(undefined)
  const [warehouses, setWarehouses] = useState<{id: number; name: string}[]>([])
  const [getWarehousers, {loading}] = useLazyQuery<
    LightweightWarehousesQuery,
    LightweightWarehousesQueryVariables
  >(LIGHTWEIGHT_WAREHOUSES, {
    onCompleted: (data) =>
      setWarehouses(
        data.warehouses.items
          .sort((warehouseA, warehouseB) =>
            warehouseA.name.localeCompare(warehouseB.name)
          )
          .map(({id, name}) => ({id, name}))
      ),
    fetchPolicy: 'network-only'
  })
  const handleChipClick = useCallback(
    (e) => {
      getWarehousers()
      openMenu(e)
    },
    [getWarehousers, openMenu]
  )
  const handleMenuItemClick = useCallback(
    (warehouse) => () => {
      setSelectedWarehouse(warehouse)
      onChange(warehouse.id)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  const handleDeleteChipClick = useCallback(() => {
    setSelectedWarehouse(undefined)
    onChange(undefined)
  }, [onChange])
  return (
    <>
      <Tooltip title={t('Select warehouse')}>
        <Chip
          size="small"
          variant="outlined"
          icon={<WarehouseOutlinedIcon />}
          deleteIcon={
            selectedWarehouse ? <CancelIcon /> : <ArrowDropDownIcon />
          }
          label={selectedWarehouse?.name}
          onClick={handleChipClick}
          onDelete={selectedWarehouse ? handleDeleteChipClick : handleChipClick}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          warehouses?.map((warehouse) => (
            <MenuItem
              key={warehouse.id}
              selected={warehouse.id === selectedWarehouse?.id}
              onClick={handleMenuItemClick(warehouse)}
              sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
            >
              {warehouse.id === selectedWarehouse?.id && (
                <ListItemIcon
                  color="primary"
                  sx={{minWidth: 48, justifyContent: 'center'}}
                >
                  <CheckIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={warehouse.name} />
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  )
}
