import {UniqueIdentifier} from '@dnd-kit/core'
import {ButtonProps, TypographyVariant} from '@mui/material'
import {Variant} from '@mui/material/styles/createTypography'
import {
  ShowGenreCode,
  ShowTypeCode,
  TypifiedIconModel
} from '../../../../../../__generated__/schema'

export interface IMarkdownBlockProps {
  content: string
}

export interface ILabelBlockProps {
  text: string
  variant?: Variant
  className?: string
}

export enum LabelBlockFormField {
  ClassName = 'className',
  Variant = 'variant',
  Text = 'text'
}

export interface ILabelBlockForm {
  [LabelBlockFormField.ClassName]?: string
  [LabelBlockFormField.Variant]?: TypographyVariant
  [LabelBlockFormField.Text]: string
}

export interface IFeaturedShowPhotosBlockContentFilter {
  showIds?: number[]
  divisionIds?: number[]
  venueIds?: number[]
  marketingLabelIds?: number[]
  eventCategoryIds?: number[]
  costCenterIds?: number[]
  showTypeCodes?: ShowTypeCode[]
  showGenreCodes?: ShowGenreCode[]
}

export interface IWebsiteShowsEventFilter {
  divisionIds?: number[]
  venueIds?: number[]
  eventCategoryIds?: number[]
  costCenterIds?: number[]
  marketingLabelIds?: number[]
}
export interface IFeaturedShowsFilter {
  baseListFilter?: IWebsiteBaseListFilter
  genreCodes?: ShowGenreCode[]
  typeCodes?: ShowTypeCode[]
  eventsFilter?: IWebsiteShowsEventFilter
}

export interface IFeaturedShowPhotosBlockProps {
  className?: string
  filter?: IFeaturedShowsFilter
}

export interface IWebsiteBaseListFilter {
  clientIds?: number[]
  fromCreatedAt?: Date
  fromUpdatedAt?: Date
  ids?: number[]
  toCreatedAt?: Date
  toUpdatedAt?: Date
}

export interface IWebsiteEventsFilter {
  baseListFilter?: IWebsiteBaseListFilter
  divisionIds?: number[]
  venueIds?: number[]
  eventCategoryIds?: number[]
  costCenterIds?: number[]
  marketingLabelIds?: number[]
  showGenreCodes?: ShowGenreCode[]
  showTypeCodes?: ShowTypeCode[]
}

export interface IFeaturedEventPostersBlockContentFilter {
  eventIds?: number[]
  divisionIds?: number[]
  venueIds?: number[]
  marketingLabelIds?: number[]
  eventCategoryIds?: number[]
  costCenterIds?: number[]
  showTypeCodes?: ShowTypeCode[]
  showGenreCodes?: ShowGenreCode[]
}

export interface IFeaturedEventPosterBlockProps {
  className?: string
  eventsFilter?: IWebsiteEventsFilter
}

export interface IEventsListBlockContentFilter {
  eventIds?: number[]
  divisionIds?: number[]
  venueIds?: number[]
  marketingLabelIds?: number[]
  eventCategoryIds?: number[]
  costCenterIds?: number[]
  showTypeCodes?: ShowTypeCode[]
  showGenreCodes?: ShowGenreCode[]
}

export interface IEventsListBlockProps {
  className?: string
  filter?: IWebsiteEventsFilter
}

interface ICardProps {
  coverImageUrl: string
  title?: string
  description?: string
  buttonLabel?: string
  buttonUrl?: string
  openInNewTab?: boolean
}

export type CardWithId = ICardProps & {
  id: UniqueIdentifier
}

export enum CardsRowSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export interface ICardsRowBlockProps {
  className?: string
  autoplay?: boolean
  spaceBetweenSlides?: number
  size?: CardsRowSize
  cards: ICardProps[]
}

export enum MuiButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum MuiButtonVariant {
  Text = 'text',
  Outlined = 'outlined',
  Contained = 'contained'
}

export enum ButtonIconPosition {
  Start = 'start',
  End = 'end',
  Top = 'top'
}

export interface IButtonProps {
  label: string
  link: string
  openInNewTab?: boolean
  size?: MuiButtonSize
  variant?: MuiButtonVariant
  color?: ButtonProps['color']
  icon?: TypifiedIconModel
  iconPosition?: ButtonIconPosition
  className?: string
}

export type ButtonWithId = IButtonProps & {
  id: UniqueIdentifier
}

export interface IButtonGroupBlockProps {
  buttons: IButtonProps[]
  className?: string
}

export type EditBlockProps =
  | IMarkdownBlockProps
  | IFeaturedShowPhotosBlockProps
  | ILabelBlockProps
  | IFeaturedEventPosterBlockProps
  | ICardsRowBlockProps
  | IButtonGroupBlockProps

export enum CardRowFormField {
  CoverImageUrl = 'coverImageUrl',
  Title = 'title',
  Description = 'description',
  ButtonLabel = 'buttonLabel',
  ButtonUrl = 'buttonUrl',
  OpenInNewTab = 'openInNewTab'
}

export interface ICardRowForm {
  [CardRowFormField.CoverImageUrl]: string
  [CardRowFormField.Title]?: string
  [CardRowFormField.Description]?: string
  [CardRowFormField.ButtonLabel]?: string
  [CardRowFormField.ButtonUrl]?: string
  [CardRowFormField.OpenInNewTab]: boolean
}

export enum CardsRowBlockSettingFormField {
  ClassName = 'className',
  Autoplay = 'autoplay',
  SpaceBetweenSlides = 'spaceBetweenSlides',
  Size = 'size'
}

export interface ICardsRowBlockForm {
  [CardsRowBlockSettingFormField.ClassName]?: string
  [CardsRowBlockSettingFormField.Autoplay]?: boolean
  [CardsRowBlockSettingFormField.SpaceBetweenSlides]?: string
  [CardsRowBlockSettingFormField.Size]?: CardsRowSize
}

export enum ButtonGroupFormField {
  Label = 'label',
  Link = 'link',
  OpenInNewTab = 'openInNewTab',
  Size = 'size',
  Variant = 'variant',
  Icon = 'icon',
  IconPosition = 'iconPosition',
  ClassName = 'className'
}

export interface IButtonGroupForm {
  [ButtonGroupFormField.Label]: string
  [ButtonGroupFormField.Link]: string
  [ButtonGroupFormField.OpenInNewTab]: boolean
  [ButtonGroupFormField.Size]?: MuiButtonSize
  [ButtonGroupFormField.Variant]?: MuiButtonVariant
  [ButtonGroupFormField.Icon]?: TypifiedIconModel
  [ButtonGroupFormField.IconPosition]?: ButtonIconPosition
  [ButtonGroupFormField.ClassName]?: string
}

export enum ButtonGroupBlockSettingsFormField {
  ClassName = 'className'
}

export interface IButtonGroupBlockSettingsForm {
  [ButtonGroupBlockSettingsFormField.ClassName]?: string
}
