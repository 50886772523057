import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {WarehouseState} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useWarehouseParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {SplitButton} from '../../../visual'
import {useUpdateWarehouse, useWarehouse} from './graphql'
import {IWarehouseForm, WarehouseFormField} from './types'
import {transformFormDataToUpdateWarehouseInput} from './utils'
import {WarehouseForm} from './WarehouseForm'

const UPDATE_WAREHOUSE_FORM_ID = 'updateWarehouseForm'

interface IUpdateWarehouseDrawerProps {
  onExited: () => void
}

export const UpdateWarehouseDrawer: React.FC<IUpdateWarehouseDrawerProps> = ({
  onExited
}: IUpdateWarehouseDrawerProps) => {
  const {t} = useTranslation()
  const {warehouseId} = useWarehouseParams()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const nextWarehouseState = useRef<WarehouseState | undefined>(undefined)
  const {data, loading, error} = useWarehouse(warehouseId)
  const updateWarehouse = useUpdateWarehouse()
  const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
    useMutationAssistanceHooks()
  useEffect(() => {
    if (!isNaN(warehouseId)) {
      openDrawer()
    }
  }, [openDrawer, warehouseId])
  const handleSubmit = useCallback(
    async (formData: IWarehouseForm) => {
      try {
        setShowBackdrop(true)
        await updateWarehouse({
          id: warehouseId,
          input: transformFormDataToUpdateWarehouseInput(
            formData,
            nextWarehouseState.current
          )
        })
        addInfoNotification(t('Warehouse updated'))
        closeDrawer()
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating warehouse'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDrawer,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateWarehouse,
      warehouseId
    ]
  )
  const updateNextWarehouseState = useCallback(
    (state) => () => (nextWarehouseState.current = state),
    []
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t('Error while loading warehouse')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update warehouse')}
          />
        }
        footer={
          <>
            {data?.warehouse.state !== WarehouseState.Deleted && (
              <Button
                color="primary"
                startIcon={<DeleteIcon />}
                type="submit"
                form={UPDATE_WAREHOUSE_FORM_ID}
                onClick={updateNextWarehouseState(WarehouseState.Deleted)}
              >
                {t('Delete')}
              </Button>
            )}
            <SplitButton
              Options={[
                <MenuItem
                  key="save-as"
                  type="submit"
                  form={UPDATE_WAREHOUSE_FORM_ID}
                  component="button"
                  onClick={
                    data?.warehouse.state === WarehouseState.Active
                      ? updateNextWarehouseState(WarehouseState.Inactive)
                      : updateNextWarehouseState(WarehouseState.Active)
                  }
                >
                  {data?.warehouse.state === WarehouseState.Active
                    ? t('Save as inactive')
                    : t('Save as active')}
                </MenuItem>
              ]}
            >
              <SaveButton type="submit" form={UPDATE_WAREHOUSE_FORM_ID}>
                {t('Save')}
              </SaveButton>
            </SplitButton>
          </>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {data && (
          <WarehouseForm
            formId={UPDATE_WAREHOUSE_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [WarehouseFormField.Name]: data.warehouse.name,
              [WarehouseFormField.Code]: data.warehouse.code,
              [WarehouseFormField.DivisionId]: String(
                data.warehouse.division.id
              )
            }}
            divisions={[data.warehouse.division]}
            isDivisionInputDisabled
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
