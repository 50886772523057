import {Checkbox, CheckboxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClaimState,
  Currency,
  ProductItemPropertiesFragment,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useGetEventSeatLocation} from '../../../../../hooks/getEventSeatLocation'
import {useTranslatePrice} from '../../../../../hooks/translateCurrencies'
import {useTranslateUnit} from '../../../../../hooks/translateUnit'
import {Theme} from '../../../../../theme'
import {EntityStateChip} from '../../../../common'
import {claimStateColors} from '../../../../constants'
import {
  isProductItemPropertiesFragment,
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'

const getClaimState = (state: ClaimState, t: TFunction) => {
  switch (state) {
    case ClaimState.Refunded:
      return t('Refunded')
    case ClaimState.Draft:
    case ClaimState.Approved:
    case ClaimState.Pending:
      return t('Claimed')
    case ClaimState.Deleted:
      return t('Deleted')
    case ClaimState.Failed:
      return t('Refund failed')
    default:
      return t('Unknown')
  }
}

export enum CheckboxState {
  Visible = 'visible',
  Hidden = 'hidden',
  None = 'none'
}

interface IItemProps {
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
  checkboxState?: CheckboxState
  checkboxProps?: CheckboxProps
  isCheckboxDisabled?: boolean
  onCheckboxClick?: React.MouseEventHandler
  claimState?: ClaimState
  disabled?: boolean
  currency: Currency
}

const useStyles = makeStyles<Theme, {checkboxState: CheckboxState}>(
  (theme) => ({
    root: {
      display: 'grid',
      gap: theme.spacing(0, 1),
      gridTemplateAreas: ({checkboxState}) =>
        checkboxState === CheckboxState.None
          ? `
      "itemId       priceBox"
      "description  priceBox"
      "name         priceBox"
    `
          : `
      "checkbox itemId       priceBox"
      "checkbox description  priceBox"
      "checkbox name         priceBox"
    `,
      gridTemplateColumns: ({checkboxState}) =>
        checkboxState === CheckboxState.None ? 'auto 1fr' : 'auto 1fr auto',
      alignItems: 'center',
      padding: ({checkboxState}) =>
        checkboxState === CheckboxState.Hidden
          ? theme.spacing(1, 0, 1, 4)
          : theme.spacing(1, 0),
      '&:last-child': {
        paddingBottom: theme.spacing(0)
      },
      '&:first-child': {
        paddingTop: theme.spacing(0)
      }
    },
    checkbox: {
      gridArea: 'checkbox'
    },
    priceBox: {
      gridArea: 'priceBox',
      textAlign: 'right'
    },
    priceBeforeDiscount: {
      textDecorationLine: 'line-through'
    },
    itemId: {
      gridArea: 'itemId'
    },
    itemIdStateChip: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'baseline'
    },
    disabled: {
      color: theme.palette.text.disabled
    }
  })
)

export const Item: React.FC<IItemProps> = ({
  item,
  isCheckboxDisabled,
  checkboxState = CheckboxState.None,
  onCheckboxClick,
  checkboxProps,
  claimState,
  disabled,
  currency
}: IItemProps) => {
  const {t} = useTranslation()
  const classes = useStyles({checkboxState})
  const translatePrice = useTranslatePrice(currency)
  const getEventSeatLocation = useGetEventSeatLocation()
  const translateUnit = useTranslateUnit()
  return (
    <div className={cn(classes.root, disabled && classes.disabled)}>
      {checkboxState !== CheckboxState.None && (
        <div className={classes.checkbox}>
          {checkboxState === CheckboxState.Visible && (
            <Checkbox
              color="primary"
              edge="start"
              disabled={isCheckboxDisabled}
              onClick={onCheckboxClick}
              {...checkboxProps}
            />
          )}
        </div>
      )}
      <div className={classes.itemId}>
        <div className={classes.itemIdStateChip}>
          <Typography variant="subtitle2">
            {isTicketItemPropertiesFragment(item) ||
            isTourItemPropertiesFragment(item)
              ? item.passCode
              : item.name}
          </Typography>
          {claimState && (
            <EntityStateChip
              colorConf={claimStateColors[claimState]}
              label={getClaimState(claimState, t)}
            />
          )}
        </div>
      </div>
      <Typography
        variant="caption"
        color={!disabled ? 'textSecondary' : 'inherit'}
      >
        {isTicketItemPropertiesFragment(item)
          ? getEventSeatLocation(item.eventSeat)
          : isProductItemPropertiesFragment(item)
          ? [
              `${item.quantity} ${translateUnit(item.unit)}`,
              translatePrice(item.unitPrice)
            ].join(' • ')
          : t('Type: {{type}}', {type: item.name})}
      </Typography>
      <div className={classes.priceBox}>
        <Typography variant="body2">{translatePrice(item.price)}</Typography>
        {(isTicketItemPropertiesFragment(item) ||
          isTourItemPropertiesFragment(item)) &&
          item.priceBeforeDiscount !== item.price && (
            <Typography
              variant="caption"
              className={classes.priceBeforeDiscount}
              color={!disabled ? 'textSecondary' : 'inherit'}
            >
              {translatePrice(item.priceBeforeDiscount)}
            </Typography>
          )}
      </div>
      {isTicketItemPropertiesFragment(item) && (
        <Typography
          variant="caption"
          color={!disabled ? 'textSecondary' : 'inherit'}
        >
          {[
            item.eventPricingToTicketType.name,
            ...item.appliedDiscounts.map(
              (appliedDiscount) => appliedDiscount.discount.name
            )
          ].join(' • ')}
        </Typography>
      )}
      {isTourItemPropertiesFragment(item) && (
        <Typography
          variant="caption"
          color={!disabled ? 'textSecondary' : 'inherit'}
        >
          {[
            t('{{count}} attendees', {count: item.attendeesCount}),
            item.appliedDiscounts[0]?.discount.name
          ]
            .filter(Boolean)
            .join(' • ')}
        </Typography>
      )}
    </div>
  )
}
