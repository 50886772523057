import {ApolloClient} from 'apollo-client'
import {ApolloQueryResult} from 'apollo-client/core/types'
import {QueryOptions} from 'apollo-client/core/watchQueryOptions'
import {useCallback} from 'react'
import {
  NarrowCostCenterFragment,
  NarrowCostCentersQuery,
  NarrowCostCentersQueryVariables,
  Pagination,
  PaginationInput
} from '../../../../../../__generated__/schema'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {GET_NARROW_COST_CENTERS, NARROW_COST_CENTER_FRAGMENT} from './graphql'
import {isNotNull, useIdListItems} from './idListItems'

const useMapNarrowCostCenterToIdListItem = () =>
  useCallback(
    ({id, name}: NarrowCostCenterFragment): IIdListItem => ({
      firstRow: name,
      id
    }),
    []
  )

const getDataFromDb = ({
  client,
  options
}: {
  options: Omit<QueryOptions<NarrowCostCentersQueryVariables>, 'query'>
  client: ApolloClient<object>
}): Promise<ApolloQueryResult<NarrowCostCentersQuery>> =>
  client.query<NarrowCostCentersQuery, NarrowCostCentersQueryVariables>({
    query: GET_NARROW_COST_CENTERS,
    ...options
  })

const getPagination = (data: NarrowCostCentersQuery): Pagination => ({
  offset: 0,
  hasMore: false,
  totalRowsCount: data.costCenters.length,
  limit: data.costCenters.length || 1
})

const useGetIdListItemsFromCache = (
  mapTItemToIdListItem: (tItem: NarrowCostCenterFragment) => IIdListItem
) =>
  useCallback(
    (client: ApolloClient<object>, ids: number[]) =>
      ids
        .map((id) =>
          client.readFragment<NarrowCostCenterFragment>({
            id: `CostCenter:${id}`,
            fragment: NARROW_COST_CENTER_FRAGMENT,
            fragmentName: 'NarrowCostCenter'
          })
        )
        .filter(isNotNull)
        .map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const useMapIdListItemsFromData = (
  mapTItemToIdListItem: (tItem: NarrowCostCenterFragment) => IIdListItem
) =>
  useCallback(
    (data: NarrowCostCentersQuery): IIdListItem[] =>
      data.costCenters.map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const mapVariables = ({
  hasText,
  ids
}: {
  paginationInput: PaginationInput
  hasText?: string
  ids?: number[]
}): NarrowCostCentersQueryVariables => ({
  filter: {
    hasText,
    costCentersIds: ids
  }
})

export const useCostCenterListItems = () => {
  const mapTItemToIdListItem = useMapNarrowCostCenterToIdListItem()
  const getIdListItemsFromCache =
    useGetIdListItemsFromCache(mapTItemToIdListItem)
  const mapIdListItemsFromData = useMapIdListItemsFromData(mapTItemToIdListItem)
  const {
    getIdListItems: getCostCenterListItems,
    getIdListItemsByIds: getCostCenterListItemsByIds
  } = useIdListItems<NarrowCostCentersQuery, NarrowCostCentersQueryVariables>({
    getDataFromDb,
    getPagination,
    getIdListItemsFromCache,
    mapIdListItemsFromData,
    mapVariables
  })
  return {
    getCostCenterListItems,
    getCostCenterListItemsByIds
  }
}
