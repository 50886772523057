import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {MouseEvent, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import Gallery from 'react-photo-gallery'
import {
  ClientShowImagePropertiesFragment,
  LibraryShowImagePropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {PRIMARY_100_COLOR, Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  BaseGalleryImage,
  IGalleryImageProps,
  useBaseGalleryImageButtonStyles
} from '../../../../common/BaseGalleryImage'
import {
  GalleryPreview,
  useGalleryPreview
} from '../../../../common/galleryPreview/GalleryPreview'
import {HeaderActionType} from '../../../../common/header/types'
import {MediaPreviewHeader} from '../../Header'
import {
  isClientShowImagePropertiesFragment,
  isLibraryShowImagePropertiesFragment
} from '../../types'
import {getGalleryPhotos} from '../common'
import {
  useAssignLibraryImagesToClientShow,
  useDeleteClientShowImage
} from '../graphql'

interface ILibraryImageProps extends IGalleryImageProps {
  onCheckButtonClick?: (e: MouseEvent) => void
}
export const LibraryImage: React.FC<ILibraryImageProps> = ({
  photo,
  openPreview,
  onCheckButtonClick,
  margin
}: ILibraryImageProps) => {
  const buttonClasses = useBaseGalleryImageButtonStyles()
  return (
    <BaseGalleryImage openPreview={openPreview} photo={photo} margin={margin}>
      {onCheckButtonClick ? (
        <IconButton
          size="small"
          onClick={onCheckButtonClick}
          className={cn(buttonClasses.button, buttonClasses.topLeft)}
        >
          <CheckCircleOutlineIcon />
        </IconButton>
      ) : null}
    </BaseGalleryImage>
  )
}

const BORDER_SIZE = 20
const useClientImageStyles = makeStyles<Theme, {width: number; height: number}>(
  () => ({
    image: {
      height: ({height}) => height - BORDER_SIZE * 2,
      width: ({width}) => width - BORDER_SIZE * 2,
      border: `${BORDER_SIZE}px solid ${PRIMARY_100_COLOR}`,
      boxSizing: 'content-box'
    }
  })
)
interface IClientImageProps extends IGalleryImageProps {
  onRemoveButtonClick?: (e: MouseEvent) => void
}
export const ClientImage: React.FC<IClientImageProps> = ({
  photo,
  openPreview,
  onRemoveButtonClick,
  margin
}: IClientImageProps) => {
  const buttonClasses = useBaseGalleryImageButtonStyles()
  const classes = useClientImageStyles({
    width: photo.width,
    height: photo.height
  })
  return (
    <BaseGalleryImage
      openPreview={openPreview}
      photo={photo}
      margin={margin}
      imageClassName={classes.image}
    >
      {onRemoveButtonClick ? (
        <IconButton
          onClick={onRemoveButtonClick}
          size="small"
          className={cn(buttonClasses.button, buttonClasses.topLeft)}
        >
          <CancelIcon />
        </IconButton>
      ) : null}
    </BaseGalleryImage>
  )
}

interface IAssignMediaGalleryProps {
  showId: number
  mixedShowImages: Array<
    | ({__typename?: 'ClientShowImage'} & ClientShowImagePropertiesFragment)
    | ({__typename?: 'LibraryShowImage'} & LibraryShowImagePropertiesFragment)
  >
  refetch: () => Promise<object>
}

export const AssignMediaGallery: React.FC<IAssignMediaGalleryProps> = ({
  showId,
  mixedShowImages,
  refetch
}: IAssignMediaGalleryProps) => {
  const {t} = useTranslation()
  const deleteClientShowImage = useDeleteClientShowImage()
  const assignLibraryImagesToClientShow = useAssignLibraryImagesToClientShow()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const galleryPreviewPhotos = useMemo(
    () =>
      mixedShowImages.map((i) => ({
        src: i.url,
        id: i.id
      })),
    [mixedShowImages]
  )
  const galleryPreviewHookProps = useGalleryPreview(galleryPreviewPhotos)
  const currentImage = mixedShowImages.find(
    (i) => i.id === galleryPreviewHookProps.currentPhotoId
  )

  const getOpenPreviewHandler = useCallback(
    (id: number) => (e: MouseEvent) => {
      e.stopPropagation()
      galleryPreviewHookProps.openPreview(id)
    },
    [galleryPreviewHookProps]
  )
  const {P} = useEnsurePermissions()
  const getCheckButtonClickHandler = useCallback(
    (id: number) => async (e: MouseEvent) => {
      e.stopPropagation()
      setShowBackdrop(true)
      try {
        await assignLibraryImagesToClientShow({libraryImageIds: [id], showId})
        await refetch()
      } catch (e) {
        defaultErrorHandler(e, t('Assigning show failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      assignLibraryImagesToClientShow,
      defaultErrorHandler,
      refetch,
      setShowBackdrop,
      showId,
      t
    ]
  )
  const getRemoveButtonClickHandler = useCallback(
    (id: number) => async (e: MouseEvent) => {
      e.stopPropagation()
      setShowBackdrop(true)
      try {
        await deleteClientShowImage({id})
        await refetch()
      } catch (e) {
        defaultErrorHandler(e, t('Removing image failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [defaultErrorHandler, deleteClientShowImage, refetch, setShowBackdrop, t]
  )
  const renderImage = useCallback(
    ({photo, margin}) => {
      if (photo.__typename === 'ClientShowImage') {
        return (
          <ClientImage
            key={`ClientImage-${photo.id}`}
            photo={photo}
            openPreview={getOpenPreviewHandler(photo.id)}
            onRemoveButtonClick={
              P([PermissionCode.DeleteClientShowImage])
                ? getRemoveButtonClickHandler(photo.id)
                : undefined
            }
            margin={margin}
          />
        )
      }
      if (photo.__typename === 'LibraryShowImage') {
        return (
          <LibraryImage
            key={`LibraryImage-${photo.id}`}
            photo={photo}
            onCheckButtonClick={
              P([PermissionCode.AssignLibraryImagesToClientShow])
                ? getCheckButtonClickHandler(photo.id)
                : undefined
            }
            openPreview={getOpenPreviewHandler(photo.id)}
            margin={margin}
          />
        )
      }
      return null
    },
    [
      P,
      getCheckButtonClickHandler,
      getOpenPreviewHandler,
      getRemoveButtonClickHandler
    ]
  )
  return (
    <>
      <Gallery
        margin={8}
        photos={getGalleryPhotos(mixedShowImages)}
        renderImage={renderImage}
      />
      <GalleryPreview
        {...galleryPreviewHookProps}
        header={
          <MediaPreviewHeader
            title={t('Preview')}
            hasArrowBackIcon
            onLeftActionClick={galleryPreviewHookProps.closePreview}
            rightActions={
              currentImage &&
              isLibraryShowImagePropertiesFragment(currentImage) &&
              P([PermissionCode.AssignLibraryImagesToClientShow])
                ? [
                    {
                      type: HeaderActionType.Button,
                      onClick: getCheckButtonClickHandler(currentImage.id),
                      label: t('Add'),
                      Icon: CheckCircleOutlineIcon
                    }
                  ]
                : currentImage &&
                  P([PermissionCode.DeleteClientShowImage]) &&
                  isClientShowImagePropertiesFragment(currentImage)
                ? [
                    {
                      type: HeaderActionType.Button,
                      onClick: getRemoveButtonClickHandler(currentImage.id),
                      label: t('Remove'),
                      Icon: CancelIcon
                    }
                  ]
                : []
            }
          />
        }
      />
    </>
  )
}
