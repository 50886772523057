import HelpIcon from '@mui/icons-material/HelpOutline'
import PersonIcon from '@mui/icons-material/Person'
import {Divider, MenuProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ReactComponent as LogoutIcon} from '../../../assets/logout.svg'
import {Theme} from '../../../theme'
import {Menu, MenuItem} from '../Menu'
import {HeaderAction, HeaderUserProps} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  userBox: {
    padding: theme.spacing(1, 3)
  },
  userName: {
    lineHeight: 1.2
  }
}))

interface IHeaderMenuProps {
  onClose: () => void
  anchorEl: MenuProps['anchorEl']
  user: HeaderUserProps
  actions: HeaderAction[]
  isUserSectionVisible: boolean
  isHelpActionVisible: boolean
  onMyProfileClick: () => void
  onLogoutClick: () => void
  onHelpClick: () => void
}

export const HeaderMenu: React.FC<IHeaderMenuProps> = ({
  anchorEl,
  onClose,
  user,
  isUserSectionVisible,
  isHelpActionVisible,
  actions,
  onHelpClick,
  onLogoutClick,
  onMyProfileClick
}: IHeaderMenuProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <Menu
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {[
        isUserSectionVisible && [
          <div className={classes.userBox} key="user-box">
            <Typography variant="h6" className={classes.userName}>
              {user.firstName || user.lastName
                ? [user.firstName, user.lastName].join(' ')
                : user.username}
            </Typography>
            {(user.workEmail || user.personalEmail) && (
              <Typography variant="body2" color="textSecondary">
                {user.workEmail || user.personalEmail}
              </Typography>
            )}
          </div>,
          <MenuItem
            key="my-profile"
            onClick={onMyProfileClick}
            icon={<PersonIcon />}
            label={t('My profile')}
            cypressId="navbar-right-my-profile-button"
          />,
          <MenuItem
            key="logout"
            onClick={onLogoutClick}
            icon={<LogoutIcon width="1em" fontSize="1.5rem" />}
            label={t('Logout')}
            cypressId="navbar-right-logout-button"
          />
        ],
        Boolean(actions.length) &&
          actions.map(
            ({
              label,
              Icon,
              onClick,
              isDisabled,
              isSelected,
              hasDivider
            }: HeaderAction) => (
              <MenuItem
                key={label}
                label={label}
                icon={Icon && <Icon />}
                onClick={onClick}
                isSelected={isSelected}
                isDisabled={isDisabled}
                hasDivider={hasDivider}
                cypressId="navbar-right-logout-button"
              />
            )
          ),
        isHelpActionVisible && (
          <MenuItem
            label={t('Help')}
            icon={<HelpIcon />}
            key="help"
            onClick={onHelpClick}
            cypressId="navbar-right-my-profile-button"
          />
        )
      ]
        .filter(Boolean)
        .reduce<React.ReactNodeArray>(
          (acc, element, index) => [
            ...acc,
            index > 0 && <Divider key={`divider-${index}`} />,
            element
          ],
          []
        )
        .flat()}
    </Menu>
  )
}
