import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import {IconButton} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {TemplateProps} from 'react-draggable-list'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ShowCrewRoleCode} from '../../../../__generated__/schema'
import {useTranslateShowCrewRole} from '../../../../hooks/translateCrewRole'
import {InputRow} from '../../../common'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormFieldName} from '../../../form/types'
import {IShowCrewFieldsProps, IShowCrewForm, ShowCrewFormField} from './types'

export interface IShowCrewInputRowCommonProps {
  errors: FormContextValues<IShowCrewForm>['errors']
  watch: FormContextValues<IShowCrewForm>['watch']
  setValue: FormContextValues<IShowCrewForm>['setValue']
  register: FormContextValues<IShowCrewForm>['register']
  triggerValidation: FormContextValues<IShowCrewForm>['triggerValidation']
  getDeleteButtonClickHandler: (id: string) => () => void
  crewRoleCodes: ShowCrewRoleCode[]
}

interface IShowCrewInputRowProps
  extends TemplateProps<IShowCrewFieldsProps, IShowCrewInputRowCommonProps> {}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns:
      '48px minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 48px',
    padding: theme.spacing(0, 2),
    gap: theme.spacing(2),
    background: theme.palette.background.paper,
    borderBottom: `solid ${theme.palette.divider} 1px`,
    alignItems: 'center'
  },
  iconButton: {
    height: 48
  }
}))

const ShowCrewInputRow: React.FC<IShowCrewInputRowProps> = ({
  item,
  commonProps: {
    errors,
    watch,
    setValue,
    triggerValidation,
    register,
    getDeleteButtonClickHandler,
    crewRoleCodes
  },
  dragHandleProps
}: IShowCrewInputRowProps) => {
  const {t} = useTranslation()
  const translateShowCrewRole = useTranslateShowCrewRole()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <IconButton className={classes.iconButton} {...dragHandleProps}>
        <DragHandleIcon />
      </IconButton>
      <InputRow
        nodes={[
          <FormAutocomplete
            label=""
            placeholder={t('Select role')}
            validationOptions={{required: true}}
            autocompleteOptions={crewRoleCodes.map((code) => ({
              value: code,
              name: translateShowCrewRole(code)
            }))}
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            name={
              `${ShowCrewFormField.CrewRoleCode}[${item.id}]` as FormFieldName<IShowCrewForm>
            }
            key={`${ShowCrewFormField.CrewRoleCode}[${item.id}]`}
            noOptionsText={t('No crew roles found')}
            fullWidth
            defaultValue={item.crewRoleCode}
            hideErrorMessage
            disableClearable
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IShowCrewForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={
              `${ShowCrewFormField.PersonName}[${item.id}]` as FormFieldName<IShowCrewForm>
            }
            key={`${ShowCrewFormField.PersonName}[${item.id}]`}
            defaultValue={item.name}
            placeholder={t('Name required')}
            validationOptions={{required: true}}
            fullWidth
            hideErrorMessage
            size="small"
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IShowCrewForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={
              `${ShowCrewFormField.Description}[${item.id}]` as FormFieldName<IShowCrewForm>
            }
            key={`${ShowCrewFormField.Description}[${item.id}]`}
            defaultValue={item.description}
            fullWidth
            hideErrorMessage
            size="small"
          />
        ]}
      />
      <IconButton
        className={classes.iconButton}
        onClick={getDeleteButtonClickHandler(item.id)}
      >
        <DeleteIcon color="primary" />
      </IconButton>
    </div>
  )
}

export class DraggableShowCrewInputRow extends React.Component<IShowCrewInputRowProps> {
  render() {
    return <ShowCrewInputRow {...this.props} />
  }
}
