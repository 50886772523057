import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {Theme} from '../../../theme'
import {AccordionDetailsItemWrapper} from './AccordionDetailsItemWrapper'
import {IUseDateRangeSearch} from './daterangeSearch'
import {DaterangeChip, OptionChip, useChipSpacingStyles} from './OptionChip'
import {SearchRowWithLabel} from './SearchRowWithLabel'
import {PREDICTABLE_DATERANGE_IDS} from './types'

interface IQuickSearchDaterangeRowProps<T> {
  searchObject: T
  label: string
  daterangeOptions: IUseDateRangeSearch<T>['daterangeOptions']
  mapDaterangeToSearchObject: IUseDateRangeSearch<T>['mapDaterangeToSearchObject']
  mapCustomDaterangeToSearchObject: IUseDateRangeSearch<T>['mapCustomDaterangeToSearchObject']
  customDaterangeDialogTitle: string
  customDaterangeDialogDescription?: string
  setSearchObject: (searchObject: T) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  iconSmall: {
    width: theme.typography.body2.fontSize
  },
  rowWrapper: {
    padding: theme.spacing(0, 1),
    flexGrow: 1
  }
}))

export const QuickSearchDaterangeRow = <T extends object>({
  label,
  daterangeOptions,
  mapDaterangeToSearchObject,
  mapCustomDaterangeToSearchObject,
  searchObject,
  setSearchObject,
  customDaterangeDialogTitle,
  customDaterangeDialogDescription
}: IQuickSearchDaterangeRowProps<T>) => {
  const classes = useStyles()
  const chipSpacingClasses = useChipSpacingStyles()
  const onOptionClick = useCallback(
    (id: PREDICTABLE_DATERANGE_IDS) => {
      setSearchObject(mapDaterangeToSearchObject(searchObject, id))
    },
    [mapDaterangeToSearchObject, searchObject, setSearchObject]
  )
  const onCustomOptionClick = useCallback(
    (startDate, endDate) => {
      setSearchObject(
        mapCustomDaterangeToSearchObject(searchObject, {startDate, endDate})
      )
    },
    [mapCustomDaterangeToSearchObject, searchObject, setSearchObject]
  )
  return (
    <AccordionDetailsItemWrapper>
      <div className={classes.rowWrapper}>
        <SearchRowWithLabel
          label={label}
          labelColor="textPrimary"
          alignLabel="top"
        >
          <div className={chipSpacingClasses.chipBox}>
            {daterangeOptions.map((option) => (
              <OptionChip
                className={chipSpacingClasses.chip}
                id={option.id}
                key={option.id}
                label={option.label}
                onClick={onOptionClick}
              />
            ))}
            <DaterangeChip
              className={chipSpacingClasses.chip}
              dialogTitle={customDaterangeDialogTitle}
              dialogDescription={customDaterangeDialogDescription}
              onSubmit={onCustomOptionClick}
            />
          </div>
        </SearchRowWithLabel>
      </div>
    </AccordionDetailsItemWrapper>
  )
}
