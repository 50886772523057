import {makeStyles} from '@mui/styles'
import {omit} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiSeatState,
  CashDeskEventDetailPropertiesFragment,
  ErrorMessages,
  EventTicketType,
  ReservationDetailFragment,
  ReservationState,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {getFeSeatStateInReservationAuditoriumPreview} from '../../../../../utils/getFeSeatState'
import {getFeZoneStateInReservationAuditoriumPreview} from '../../../../../utils/getFeZoneState'
import {
  useGetSeatTooltipLabel,
  useGetZoneTooltipLabel,
  useLayoutObjectTooltip
} from '../../../../../utils/layoutObjectTooltip'
import {safeSum} from '../../../../../utils/money'
import {
  AuditoriumLayoutPreview,
  useFeSeatStatesByUuid,
  useFeZoneStatesByUuid,
  useGetTicketTypeForUuid,
  useLayout,
  useTicketTypesByTicketTypeId
} from '../../../../common/auditoriumLayoutPreview'
import {BackdropWithInfo} from '../../../../visual/Backdrop'
import {isTicketItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {EditZoneTicketQuantityDialog} from '../eventAuditoriumPreview/EditZoneTicketQuantityDialog'
import {
  useAddSeatingTicketItemToReservation,
  useAddZoneTicketItemToReservation,
  useRemoveTicketItemsFromReservation,
  useRemoveZoneTicketItemsFromReservation
} from '../graphql'
import {OccupiedSeatsLimitReachedErrorDialog} from '../OccupiedSeatsLimitReachedErrorDialog'
import {ReservationHasNoItemsDialog} from './ReservationHasNoItemsDialog'
import {SubHeader} from './SubHeader'
import {SubReservation} from './SubReservation'

interface IContentProps {
  reservation: ReservationDetailFragment & {
    event: CashDeskEventDetailPropertiesFragment
  }
  refetch: () => void
  eventSeats: JSON
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "subReservation subHeader"
      "subReservation preview"
    `,
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '360px 1fr',
    height: '100%'
  },
  subHeader: {
    gridArea: 'subHeader'
  },
  subReservation: {
    gridArea: 'subReservation',
    background: theme.palette.background.paper,
    borderRight: `solid ${theme.palette.divider} 1px`
  },
  preview: {
    gridArea: 'preview',
    position: 'relative'
  },
  auditoriumLayoutPreview: {
    width: '100%',
    height: '100%'
  }
}))

export const Content: React.FC<IContentProps> = ({
  reservation,
  eventSeats,
  refetch
}: IContentProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {currentCart} = useCurrentCart()
  const isReservationInCurrentCart =
    reservation.state === ReservationState.InCart &&
    !!currentCart?.items
      ?.filter<TicketItemPropertiesFragment>(isTicketItemPropertiesFragment)
      .find((item) => item.reservation?.id === reservation.id)
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()

  const removeTicketItemsFromReservation = useRemoveTicketItemsFromReservation()
  const addSeatingTicketItemToReservation =
    useAddSeatingTicketItemToReservation()
  const addZoneTicketItemToReservation = useAddZoneTicketItemToReservation()
  const removeZoneTicketItemsFromReservation =
    useRemoveZoneTicketItemsFromReservation()
  const {
    state: isOccupiedSeatsLimitReachedDialogOpen,
    setTrue: openOccupiedSeatsLimitReachedDialog,
    setFalse: closeOccupiedSeatsLimitReachedDialog
  } = useBooleanState(false)

  // @ts-ignore
  const {seats, zones} = eventSeats

  const feSeatStatesByUuid = useFeSeatStatesByUuid({
    seats,
    mapApiSeatStateToFeSeatState: getFeSeatStateInReservationAuditoriumPreview
  })

  const feZoneStatesByUuid = useFeZoneStatesByUuid({
    zones,
    mapZoneApiSeatStatesToFeZoneState:
      getFeZoneStateInReservationAuditoriumPreview
  })

  const getOnAvailableSeatClickHandler = useCallback(
    (uuid: string) => async () => {
      try {
        setShowBackdrop(true)
        await addSeatingTicketItemToReservation(reservation.id, uuid)
      } catch (err) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            err,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          openOccupiedSeatsLimitReachedDialog()
        } else {
          defaultErrorHandler(
            err,
            t('Error while adding seating ticket item to reservation.')
          )
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addSeatingTicketItemToReservation,
      defaultErrorHandler,
      openOccupiedSeatsLimitReachedDialog,
      reservation.id,
      setShowBackdrop,
      t
    ]
  )

  const getOnSeatInMyReservationClickHandler = useCallback(
    (uuid: string, eventSeatId: number) => async () => {
      const itemId = (reservation.items || [])
        .filter(isTicketItemPropertiesFragment)
        .find((item) => item.eventSeat.id === eventSeatId)?.id
      if (itemId) {
        try {
          setShowBackdrop(true)
          await removeTicketItemsFromReservation(reservation.id, [itemId])
        } catch (err) {
          defaultErrorHandler(
            err,
            t('Error while removing ticket item from reservation.')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      defaultErrorHandler,
      removeTicketItemsFromReservation,
      reservation.id,
      reservation.items,
      setShowBackdrop,
      t
    ]
  )

  const getSeatClickHandler = useCallback(
    (uuid: string) => {
      const {state, id: eventSeatId}: {state: ApiSeatState; id: number} =
        seats[uuid]
      if (state === ApiSeatState.Available) {
        return getOnAvailableSeatClickHandler(uuid)
      } else if (state === ApiSeatState.InMyReservation) {
        return getOnSeatInMyReservationClickHandler(uuid, eventSeatId)
      }
      return undefined
    },
    [
      getOnAvailableSeatClickHandler,
      getOnSeatInMyReservationClickHandler,
      seats
    ]
  )

  const [uuidOfOpenedZone, setUuidOfOpenedZone] = useState<string | null>(null)

  const closeZoneDialog = useCallback(() => {
    setUuidOfOpenedZone(null)
  }, [])

  const handleZoneTicketQuantityChange = useCallback(
    async ({
      increment,
      decrement,
      zoneUuid
    }: {
      increment?: number
      decrement?: number
      zoneUuid: string
    }) => {
      setShowBackdrop(true)
      try {
        if (decrement) {
          await removeZoneTicketItemsFromReservation(
            reservation.id,
            zoneUuid,
            decrement
          )
        } else if (increment) {
          await addZoneTicketItemToReservation(
            reservation.id,
            zoneUuid,
            increment
          )
        }
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.MaxNumberOfOccupiedSeatsHasBeenReached
          )
        ) {
          openOccupiedSeatsLimitReachedDialog()
        } else {
          defaultErrorHandler(error, t('Updating zone tickets quantity failed'))
        }
      } finally {
        setShowBackdrop(false)
        closeZoneDialog()
      }
    },
    [
      addZoneTicketItemToReservation,
      closeZoneDialog,
      defaultErrorHandler,
      openOccupiedSeatsLimitReachedDialog,
      removeZoneTicketItemsFromReservation,
      reservation.id,
      setShowBackdrop,
      t
    ]
  )

  const getZoneClickHandler = useCallback(
    (uuid: string) => {
      const zoneStatesWithCounts = zones[uuid].states ?? {}
      return zoneStatesWithCounts[ApiSeatState.Available] > 0 ||
        zoneStatesWithCounts[ApiSeatState.InMyReservation] > 0
        ? () => {
            setUuidOfOpenedZone(uuid)
          }
        : undefined
    },
    [zones]
  )
  const ticketTypesByTicketTypeId: {[id: string]: EventTicketType} =
    useTicketTypesByTicketTypeId(reservation.event)

  const layout = useLayout(reservation.event, ticketTypesByTicketTypeId)

  const getTicketTypeForUuid = useGetTicketTypeForUuid(
    layout,
    ticketTypesByTicketTypeId
  )
  const getZoneTooltipLabel = useGetZoneTooltipLabel()
  const getSeatTooltipLabel = useGetSeatTooltipLabel()

  const {TooltipProps, getMouseEnterHandler, getMouseLeaveHandler} =
    useLayoutObjectTooltip({
      layout: reservation.event.auditoriumLayout.layout,
      feSeatStatesByUuid,
      feZoneStatesByUuid,
      getTicketTypeForUuid,
      getZoneTooltipLabel,
      getSeatTooltipLabel
    })
  return (
    <div className={classes.root}>
      <SubHeader
        className={classes.subHeader}
        reservation={omit(reservation, 'event')}
        event={reservation.event}
      />
      <SubReservation
        className={classes.subReservation}
        reservation={reservation}
        isReservationInCurrentCart={isReservationInCurrentCart}
        refetch={refetch}
      />
      <div className={classes.preview}>
        {reservation.items?.length === 0 && <ReservationHasNoItemsDialog />}
        <AuditoriumLayoutPreview
          className={classes.auditoriumLayoutPreview}
          eventSeats={eventSeats}
          layout={layout}
          feSeatStatesByUuid={feSeatStatesByUuid}
          feZoneStatesByUuid={feZoneStatesByUuid}
          getSeatClickHandler={getSeatClickHandler}
          getZoneClickHandler={getZoneClickHandler}
          getLayoutObjectMouseEnterHandler={getMouseEnterHandler}
          getLayoutObjectMouseLeaveHandler={getMouseLeaveHandler}
          TooltipProps={TooltipProps}
        />
        {uuidOfOpenedZone && (
          <EditZoneTicketQuantityDialog
            zoneUuid={uuidOfOpenedZone}
            zoneCapacity={(layout as any)[uuidOfOpenedZone].config.capacity}
            onClose={closeZoneDialog}
            zoneLabel={(layout as any)[uuidOfOpenedZone].config.label}
            zoneDescription={[
              getTicketTypeForUuid(uuidOfOpenedZone).name,
              getTicketTypeForUuid(uuidOfOpenedZone).description
            ]
              .filter(Boolean)
              .join(' • ')}
            zonePrice={getTicketTypeForUuid(uuidOfOpenedZone).price}
            ticketsInMyCartQuantity={
              zones[uuidOfOpenedZone]?.states[ApiSeatState.InMyReservation] || 0
            }
            availableTicketsQuantity={
              zones[uuidOfOpenedZone]?.states[ApiSeatState.Available] || 0
            }
            reservedTicketsQuantity={safeSum([
              zones[uuidOfOpenedZone]?.states[ApiSeatState.ReservedInMyCart],
              zones[uuidOfOpenedZone]?.states[ApiSeatState.InOtherReservation],
              zones[uuidOfOpenedZone]?.states[ApiSeatState.InMyReservation]
            ])}
            soldTicketsQuantity={
              zones[uuidOfOpenedZone]?.states[ApiSeatState.Sold] || 0
            }
            onSaveClick={handleZoneTicketQuantityChange}
          />
        )}
        <BackdropWithInfo
          isVisible={isReservationInCurrentCart}
          title={t('Reservation is in cart')}
          description={t(
            'Unable to edit reservation added to cart. If you want to change anything in reservation, remove it from cart first.'
          )}
        />
      </div>
      <OccupiedSeatsLimitReachedErrorDialog
        isOpen={isOccupiedSeatsLimitReachedDialogOpen}
        onClose={closeOccupiedSeatsLimitReachedDialog}
      />
    </div>
  )
}
