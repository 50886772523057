import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useCostCenterParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CostCenterForm} from './CostCenterForm'
import {useGetCostCenter, useUpdateCostCenter} from './graphql'
import {CostCenterFormField, CostCenterState, ICostCenterForm} from './types'

const UPDATE_COST_CENTER_FORM_ID = 'updateCostCenterForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface IUpdateCostCenterDrawerProps {
  onExited: () => void
}

export const UpdateCostCenterDrawer: React.FC<IUpdateCostCenterDrawerProps> = ({
  onExited
}: IUpdateCostCenterDrawerProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {costCenterId} = useCostCenterParams()
  const {data, error, loading} = useGetCostCenter(costCenterId)
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const updateCostCenter = useUpdateCostCenter()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    if (costCenterId) {
      openDrawer()
    }
  }, [costCenterId, openDrawer])
  const handleSubmit = useCallback(
    async (data: ICostCenterForm) => {
      try {
        setShowBackdrop(true)
        await updateCostCenter({
          id: costCenterId,
          data: {
            ...data,
            isActive:
              data[CostCenterFormField.IsActive] === CostCenterState.Active
          }
        })
        addInfoNotification(t('Cost center has been updated'))
        onExited()
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating cost center'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      costCenterId,
      defaultErrorHandler,
      onExited,
      setShowBackdrop,
      t,
      updateCostCenter
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t<string>('Error while loading cost center')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update cost center')}
          />
        }
        footer={
          <SaveButton type="submit" form={UPDATE_COST_CENTER_FORM_ID}>
            {t('Save')}
          </SaveButton>
        }
      >
        {data && (
          <CostCenterForm
            formId={UPDATE_COST_CENTER_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [CostCenterFormField.Name]: data.costCenter.name,
              [CostCenterFormField.Label]: data.costCenter.label || undefined,
              [CostCenterFormField.IsActive]: data.costCenter.isActive
                ? CostCenterState.Active
                : CostCenterState.Inactive
            }}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
