import {makeStyles} from '@mui/styles'
import React from 'react'
import {useFieldArray, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useTranslateLocaleCode} from '../../../../hooks/translateLocales'
import {Theme} from '../../../../theme'

import {InputBlock, InputRow, TextEditor} from '../../../common'
import {TextEditorLabel} from '../../../common/textEditor/TextEditor'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {
  ITranslationProps,
  ITranslationsForm,
  TranslationFormField
} from './types'

const getTranslationsFieldName = (
  index: number,
  translationFormField: TranslationFormField
) => `${TranslationFormField.TRANSLATIONS}[${index}].${translationFormField}`

interface IShowTranslationsFormProps {
  onSubmit(formData: ITranslationsForm): void
  formId: string
  className?: string
  defaultValues: Partial<ITranslationsForm>
}

export const ShowTranslationsForm: React.FC<IShowTranslationsFormProps> = ({
  defaultValues,
  onSubmit,
  formId,
  className
}: IShowTranslationsFormProps) => {
  const translateLocaleCode = useTranslateLocaleCode()
  const {
    register,
    setValue,
    errors,
    watch,
    handleSubmit,
    triggerValidation,
    control
  } = useForm<ITranslationsForm>({
    defaultValues
  })
  const formProps = {
    register,
    setValue,
    watch,
    errors,
    triggerValidation
  }
  const {fields} = useFieldArray<ITranslationProps>({
    control,
    name: TranslationFormField.TRANSLATIONS
  })
  const {t} = useTranslation()
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={className}>
      {fields.map(({localeCode, description}, index) => {
        return (
          <InputBlock
            key={localeCode}
            header={translateLocaleCode(localeCode!)}
            blockId={localeCode}
          >
            <input
              name={getTranslationsFieldName(
                index,
                TranslationFormField.LOCALE_CODE
              )}
              type="hidden"
              ref={register()}
            />
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<ITranslationsForm>
                  {...formProps}
                  fullWidth
                  key={TranslationFormField.TITLE}
                  label={t('Title')}
                  name={getTranslationsFieldName(
                    index,
                    TranslationFormField.TITLE
                  )}
                  required
                  validationOptions={{
                    required: true
                  }}
                />
              ]}
            />
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<ITranslationsForm>
                  {...formProps}
                  fullWidth
                  key={TranslationFormField.TAGLINE}
                  label={t('Tagline')}
                  name={getTranslationsFieldName(
                    index,
                    TranslationFormField.TAGLINE
                  )}
                />
              ]}
            />
            <TextEditorLabel>{t('Description')}</TextEditorLabel>
            <InputRow
              nodes={[
                <TextEditor<ITranslationsForm>
                  key={TranslationFormField.DESCRIPTION}
                  name={getTranslationsFieldName(
                    index,
                    TranslationFormField.DESCRIPTION
                  )}
                  inputRef={register()}
                  setValue={setValue}
                  defaultValue={description}
                />
              ]}
            />
          </InputBlock>
        )
      })}
    </form>
  )
}

export const useShowFormStyles = makeStyles<Theme>((theme) => ({
  form: {
    '&:last-child': {
      paddingBottom: theme.spacing(3)
    }
  }
}))
