import GetAppIcon from '@mui/icons-material/GetApp'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {Box, Button} from '@mui/material'
import {GridToolbarContainer, useGridApiContext} from '@mui/x-data-grid-pro'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useDebounce} from '../../../../../hooks/debounce'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../common'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {useInvalidateDiscountCodes} from '../graphql'

interface IDataGridToolbarProps {
  selectedItemIds: number[]
  setSelectedItemIds: (ids: number[]) => void
  onDiscountCodeSearchChange: (code?: string) => void
}

export const DataGridToolbar: React.FC<IDataGridToolbarProps> = ({
  selectedItemIds,
  setSelectedItemIds,
  onDiscountCodeSearchChange
}: IDataGridToolbarProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const apiRef = useGridApiContext()
  const [search, setSearch] = useState<string>('')
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const invalidateDiscountCodes = useInvalidateDiscountCodes()
  const handleInvalidateSelectedDiscountCodes = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await invalidateDiscountCodes(selectedItemIds)
      setSelectedItemIds([])
      addInfoNotification(t('Discount codes invalidated successfully'))
    } catch (e) {
      defaultErrorHandler(e, t('Error while invalidating discount codes'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    invalidateDiscountCodes,
    selectedItemIds,
    setSelectedItemIds,
    setShowBackdrop,
    t
  ])
  const debouncedDiscountCodeSearchChange = useDebounce(
    (v) => onDiscountCodeSearchChange(v || undefined),
    400
  )
  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value.length !== 1) {
        debouncedDiscountCodeSearchChange(e.target.value)
      }
      setSearch(e.target.value)
    },
    [debouncedDiscountCodeSearchChange]
  )
  const handleCancelClick = useCallback(() => {
    setSearch('')
    onDiscountCodeSearchChange(undefined)
  }, [onDiscountCodeSearchChange])
  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 3,
        backgroundColor: 'background.paper',
        minHeight: 64
      }}
    >
      <OutlinedInputWithCancelAdornment
        onCancelClick={handleCancelClick}
        inputProps={{
          value: search,
          onChange: handleInputChange,
          placeholder: t('Enter discount code')
        }}
      />
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        {P([PermissionCode.InvalidateDiscountCodes]) &&
          selectedItemIds.length > 0 && (
            <ButtonWithConfirmationDialog
              onConfirmButtonClick={handleInvalidateSelectedDiscountCodes}
              dialogProps={{
                title: t('Invalidate discount codes?'),
                contentText: t(
                  "Are you sure you want to invalidate discount codes? This can't be undone."
                ),
                confirmButtonLabel: t('Invalidate')
              }}
              buttonProps={{
                children: t('Invalidate'),
                startIcon: <HighlightOffIcon />
              }}
            />
          )}
        {selectedItemIds.length > 0 && (
          <Button
            variant="text"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={() =>
              apiRef.current.exportDataAsCsv({
                fileName: 'discount-codes',
                getRowsToExport: () => selectedItemIds
              })
            }
          >
            {t('Download')}
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  )
}
