import {useTranslation} from 'react-i18next'
import {ScreenOrientationType} from './types'

export const useTranslateScreenOrientationType = () => {
  const {t} = useTranslation()
  const translated: Record<ScreenOrientationType, string> = {
    [ScreenOrientationType.PortraitPrimary]: t(
      'ScreenOrientationType->PortraitPrimary'
    ),
    [ScreenOrientationType.PortraitSecondary]: t(
      'ScreenOrientationType->PortraitSecondary'
    ),
    [ScreenOrientationType.LandscapePrimary]: t(
      'ScreenOrientationType->LandscapePrimary'
    ),
    [ScreenOrientationType.LandscapeSecondary]: t(
      'ScreenOrientationType->LandscapeSecondary'
    )
  }
  return (type: ScreenOrientationType) => translated[type]
}
