import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EditTemplate} from './EditTemplate'
import {TemplatesPage} from './TemplatesPage'

export const Templates: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadTemplate]) && (
        <Route
          component={EditTemplate}
          path={routeTo.admin.templates.edit(':templateId')}
        />
      )}
      <Route component={TemplatesPage} path={routeTo.admin.templates.index()} />
    </Switch>
  )
}
