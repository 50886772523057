import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {Button, IconButton, InputAdornment, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {isEmpty} from 'lodash'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  EcommercePaymentMethodInput,
  PaymentGatewayEnvironment,
  PaymentServiceProvider,
  PaymentServiceProviderConfigInput
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {Theme} from '../../../../theme'
import {useUserInfo} from '../../../../utils/auth'
import {useIsPositiveInteger} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../form/UncontrolledFormRadioGroup'
import {CheckGoPayCredentials} from './CheckGoPayCredentials'
import {CheckStatnaPokladnicaCredentials} from './CheckStatnaPokladnicaCredentials'
import {CommonEcommercePaymentMethodFormParts} from './CommonEcommercePaymentMethodFormParts'
import {useGoPayDefaultPaymentInstrumentOptions} from './defaultGopayPaymentInstroment'
import {useTranslatePaymentGatewayEnvironment} from './translatePaymentGatewayEnvironment'
import {
  BesteronField,
  BesteronPaymentMethod,
  GoPayField,
  GoPayPaymentInstrumentOrNone,
  IBesteronForm,
  IGoPayForm,
  IPaymentMethodForm,
  isGoPayInstrument,
  IStatnaPokladnicaForm,
  PaymentMethodField,
  StatnaPokladnicaField,
  transformEcommercePaymentMethodFormIntoInput
} from './types'
import {getBesteronPaymentInstruments} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  credentials: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1.5),
    gap: theme.spacing(1)
  },
  checkCredentialsButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  typography: {
    paddingBottom: theme.spacing(1.5)
  }
}))

interface IEcommercePaymentMethodFormProps {
  formId: string
  onSubmit: (
    input: EcommercePaymentMethodInput,
    paymentServiceProviderConfigInput: PaymentServiceProviderConfigInput
  ) => void
  paymentServiceProvider: PaymentServiceProvider
  defaultValues: Partial<IPaymentMethodForm> &
    Pick<
      IPaymentMethodForm,
      PaymentMethodField.CURRENCY | PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS
    >
  className?: string
}

const GoPayForm: React.FC<IEcommercePaymentMethodFormProps> = ({
  formId,
  paymentServiceProvider,
  onSubmit,
  defaultValues,
  className
}: IEcommercePaymentMethodFormProps) => {
  const {t} = useTranslation()
  const {
    register,
    setValue,
    errors,
    watch,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IGoPayForm>({
    defaultValues
  })
  const {state: isPasswordVisible, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const goId = watch(GoPayField.GoId)
  const clientId = watch(GoPayField.ClientId)
  const clientSecret = watch(GoPayField.ClientSecret)
  const environment = watch(GoPayField.Environment)
  const isGoPayConfigEmpty =
    isEmpty(goId) ||
    isEmpty(clientId) ||
    isEmpty(clientSecret) ||
    isEmpty(environment)
  const classes = useStyles()
  const goPayDefaultPaymentInstrumentOptions =
    useGoPayDefaultPaymentInstrumentOptions()
  const translatePaymentGatewayEnvironment =
    useTranslatePaymentGatewayEnvironment()
  const _handleSubmit = useCallback(
    (form: IGoPayForm) => {
      const formDefaultPaymentInstrument =
        form[GoPayField.DefaultPaymentInstrument]
      onSubmit(
        transformEcommercePaymentMethodFormIntoInput(
          form,
          paymentServiceProvider
        ),
        {
          type: PaymentServiceProvider.Gopay,
          goPayConfigInput: {
            goId: parseInt(form[GoPayField.GoId], 10),
            clientId: form[GoPayField.ClientId],
            clientSecret: form[GoPayField.ClientSecret],
            environment: form[GoPayField.Environment],
            defaultPaymentInstrument: isGoPayInstrument(
              formDefaultPaymentInstrument
            )
              ? formDefaultPaymentInstrument
              : null
          }
        }
      )
    },
    [onSubmit, paymentServiceProvider]
  )
  return (
    <CommonEcommercePaymentMethodFormParts<IGoPayForm>
      onSubmit={handleSubmit(_handleSubmit)}
      control={control}
      defaultValues={defaultValues}
      errors={errors}
      formId={formId}
      className={className}
      register={register}
      setValue={setValue}
      triggerValidation={triggerValidation}
      watch={watch}
    >
      <div className={classes.credentials}>
        <div className={classes.typography}>
          <Typography variant="subtitle1">{t('GoPay credentials')}</Typography>
          <Typography variant="caption" color="textSecondary">
            {t('GoPay credentials description')}
          </Typography>
        </div>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IGoPayForm>
              register={register}
              name={GoPayField.GoId}
              key={GoPayField.GoId}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Go ID')}
              validationOptions={{
                required: true
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IGoPayForm>
              register={register}
              name={GoPayField.ClientId}
              key={GoPayField.ClientId}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Client ID')}
              validationOptions={{
                required: true
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IGoPayForm>
              register={register}
              name={GoPayField.ClientSecret}
              key={GoPayField.ClientSecret}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Client secret')}
              validationOptions={{
                required: true
              }}
              type={isPasswordVisible ? 'text' : 'password'}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {isPasswordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormRadioGroup<IGoPayForm, PaymentGatewayEnvironment>
              label={t('Environment')}
              name={GoPayField.Environment}
              key={GoPayField.Environment}
              errors={errors}
              validationOptions={{
                required: true
              }}
              fullWidth
              control={control}
              options={[
                {
                  value: PaymentGatewayEnvironment.Production,
                  label: (
                    <BasicRadioLabel
                      primaryText={translatePaymentGatewayEnvironment(
                        PaymentGatewayEnvironment.Production
                      )}
                    />
                  )
                },
                {
                  value: PaymentGatewayEnvironment.Sandbox,
                  label: (
                    <BasicRadioLabel
                      primaryText={translatePaymentGatewayEnvironment(
                        PaymentGatewayEnvironment.Sandbox
                      )}
                      secondaryText={t(
                        'Only for testing purposes. All payments made on this environment are dummy and you will be not credited funds for those payments.'
                      )}
                    />
                  )
                }
              ]}
            />
          ]}
        />
        <div className={classes.checkCredentialsButton}>
          <CheckGoPayCredentials
            goId={parseInt(goId, 10)}
            clientSecret={clientSecret}
            clientId={clientId}
            environment={environment}
            isButtonDisabled={isGoPayConfigEmpty}
          />
        </div>
        <InputRow
          nodes={[
            <UncontrolledFormRadioGroup<
              IGoPayForm,
              GoPayPaymentInstrumentOrNone
            >
              label={t('Default payment instrument')}
              name={GoPayField.DefaultPaymentInstrument}
              key={GoPayField.DefaultPaymentInstrument}
              errors={errors}
              helperText={t(
                'Customer will be able to select or change preferred payment instrument in GoPay interface.'
              )}
              validationOptions={{
                required: true
              }}
              fullWidth
              control={control}
              options={goPayDefaultPaymentInstrumentOptions}
            />
          ]}
        />
      </div>
    </CommonEcommercePaymentMethodFormParts>
  )
}

const StatnaPokladnicaForm: React.FC<IEcommercePaymentMethodFormProps> = ({
  formId,
  paymentServiceProvider,
  onSubmit,
  defaultValues,
  className
}: IEcommercePaymentMethodFormProps) => {
  const {t} = useTranslation()
  const {
    register,
    setValue,
    errors,
    watch,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IStatnaPokladnicaForm>({
    defaultValues
  })
  const merchantId = watch(StatnaPokladnicaField.MerchantId)
  const privateKey = watch(StatnaPokladnicaField.PrivateKey)
  const environment = watch(StatnaPokladnicaField.Environment)
  const classes = useStyles()
  const translateGoPayEnvironment = useTranslatePaymentGatewayEnvironment()
  const _handleSubmit = useCallback(
    (form: IStatnaPokladnicaForm) => {
      onSubmit(
        transformEcommercePaymentMethodFormIntoInput(
          form,
          paymentServiceProvider
        ),
        {
          type: PaymentServiceProvider.StatnaPokladnica,
          statnaPokladnicaConfigInput: {
            merchantId: form[StatnaPokladnicaField.MerchantId],
            privateKey: form[StatnaPokladnicaField.PrivateKey],
            environment: form[StatnaPokladnicaField.Environment]
          }
        }
      )
    },
    [onSubmit, paymentServiceProvider]
  )
  return (
    <CommonEcommercePaymentMethodFormParts<IStatnaPokladnicaForm>
      onSubmit={handleSubmit(_handleSubmit)}
      control={control}
      defaultValues={defaultValues}
      errors={errors}
      formId={formId}
      className={className}
      register={register}
      setValue={setValue}
      triggerValidation={triggerValidation}
      watch={watch}
    >
      <div className={classes.credentials}>
        <div className={classes.typography}>
          <Typography variant="subtitle1">
            {t('Slovak State Treasury credentials')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t(
              'You have to officially apply Slovak State Treasury for payment gateway credentials and sign a contract between your company and provider.'
            )}
          </Typography>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IStatnaPokladnicaForm>
                register={register}
                name={StatnaPokladnicaField.MerchantId}
                key={StatnaPokladnicaField.MerchantId}
                errors={errors}
                setValue={setValue}
                triggerValidation={triggerValidation}
                watch={watch}
                label={t('MID')}
                validationOptions={{
                  required: true
                }}
                required
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IStatnaPokladnicaForm>
                register={register}
                name={StatnaPokladnicaField.PrivateKey}
                key={StatnaPokladnicaField.PrivateKey}
                errors={errors}
                setValue={setValue}
                triggerValidation={triggerValidation}
                watch={watch}
                label={t('Private Key')}
                validationOptions={{
                  required: true,
                  pattern: {
                    value:
                      /^-----BEGIN RSA PRIVATE KEY-----\n(?:[a-zA-Z0-9/+=]{64}\n)+[a-zA-Z0-9/+=]{1,64}\n-----END RSA PRIVATE KEY-----$/,
                    message: t('Must be a valid RSA private key')
                  }
                }}
                required
                fullWidth
                multiline
                rows={6}
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormRadioGroup<
                IStatnaPokladnicaForm,
                PaymentGatewayEnvironment
              >
                label={t('Environment')}
                name={StatnaPokladnicaField.Environment}
                key={StatnaPokladnicaField.Environment}
                errors={errors}
                validationOptions={{
                  required: true
                }}
                fullWidth
                control={control}
                options={[
                  {
                    value: PaymentGatewayEnvironment.Production,
                    label: (
                      <BasicRadioLabel
                        primaryText={translateGoPayEnvironment(
                          PaymentGatewayEnvironment.Production
                        )}
                      />
                    )
                  },
                  {
                    value: PaymentGatewayEnvironment.Sandbox,
                    label: (
                      <BasicRadioLabel
                        primaryText={translateGoPayEnvironment(
                          PaymentGatewayEnvironment.Sandbox
                        )}
                        secondaryText={t(
                          'Only for testing purposes. All payments made on this environment are dummy and you will be not credited funds for those payments.'
                        )}
                      />
                    )
                  }
                ]}
              />
            ]}
          />
          <div className={classes.checkCredentialsButton}>
            <CheckStatnaPokladnicaCredentials
              privateKey={privateKey}
              merchantId={merchantId}
              environment={environment}
              isButtonDisabled={
                isEmpty(merchantId) ||
                isEmpty(privateKey) ||
                isEmpty(environment)
              }
            />
          </div>
        </div>
      </div>
    </CommonEcommercePaymentMethodFormParts>
  )
}
const InHouseVoucherForm: React.FC<IEcommercePaymentMethodFormProps> = ({
  formId,
  paymentServiceProvider,
  onSubmit,
  defaultValues,
  className
}: IEcommercePaymentMethodFormProps) => {
  const {
    register,
    setValue,
    errors,
    watch,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IPaymentMethodForm>({
    defaultValues
  })
  const _handleSubmit = useCallback(
    (form: IPaymentMethodForm) => {
      onSubmit(
        transformEcommercePaymentMethodFormIntoInput(
          form,
          paymentServiceProvider
        ),
        {
          type: PaymentServiceProvider.InHouseVoucher
        }
      )
    },
    [onSubmit, paymentServiceProvider]
  )
  return (
    <CommonEcommercePaymentMethodFormParts<IPaymentMethodForm>
      onSubmit={handleSubmit(_handleSubmit)}
      control={control}
      defaultValues={defaultValues}
      errors={errors}
      formId={formId}
      className={className}
      register={register}
      setValue={setValue}
      triggerValidation={triggerValidation}
      watch={watch}
    />
  )
}

const BesteronForm: React.FC<IEcommercePaymentMethodFormProps> = ({
  formId,
  paymentServiceProvider,
  onSubmit,
  defaultValues,
  className
}: IEcommercePaymentMethodFormProps) => {
  const {t} = useTranslation()
  const {
    register,
    setValue,
    errors,
    watch,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IBesteronForm>({
    defaultValues
  })
  const {effectiveClient} = useUserInfo()
  const isPositiveInteger = useIsPositiveInteger()
  const {state: isPasswordVisible, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const classes = useStyles()
  const translatePaymentGatewayEnvironment =
    useTranslatePaymentGatewayEnvironment()
  const _handleSubmit = useCallback(
    (form: IBesteronForm) => {
      if (effectiveClient) {
        onSubmit(
          transformEcommercePaymentMethodFormIntoInput(
            form,
            paymentServiceProvider
          ),
          {
            type: PaymentServiceProvider.Besteron,
            besteronConfigInput: {
              besteronId: form[BesteronField.BesteronId],
              paymentKey: form[BesteronField.PaymentKey],
              apiKey: form[BesteronField.ApiKey],
              onBehalfOf: form[BesteronField.OnBehalfOf] || undefined,
              environment: form[BesteronField.Enviroment],
              paymentInstruments: getBesteronPaymentInstruments(
                form[BesteronField.PaymentInstruments],
                effectiveClient.countryCode
              )
            }
          }
        )
      }
    },
    [effectiveClient, onSubmit, paymentServiceProvider]
  )
  return (
    <CommonEcommercePaymentMethodFormParts<IBesteronForm>
      onSubmit={handleSubmit(_handleSubmit)}
      control={control}
      defaultValues={defaultValues}
      errors={errors}
      formId={formId}
      className={className}
      register={register}
      setValue={setValue}
      triggerValidation={triggerValidation}
      watch={watch}
    >
      <div className={classes.credentials}>
        <div className={classes.typography}>
          <Typography variant="subtitle1">
            {t('Besteron credentials')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('Besteron credentials description')}
          </Typography>
        </div>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBesteronForm>
              register={register}
              name={BesteronField.BesteronId}
              key={BesteronField.BesteronId}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Besteron ID')}
              validationOptions={{
                required: true
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBesteronForm>
              register={register}
              name={BesteronField.PaymentKey}
              key={BesteronField.PaymentKey}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Payment key')}
              validationOptions={{
                required: true
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBesteronForm>
              register={register}
              name={BesteronField.ApiKey}
              key={BesteronField.ApiKey}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('API key')}
              validationOptions={{
                required: true
              }}
              type={isPasswordVisible ? 'text' : 'password'}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {isPasswordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBesteronForm>
              register={register}
              name={BesteronField.OnBehalfOf}
              key={BesteronField.OnBehalfOf}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('On behalf of')}
              validationOptions={{
                validate: isPositiveInteger
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormRadioGroup<
              IBesteronForm,
              PaymentGatewayEnvironment
            >
              label={t('Environment')}
              name={BesteronField.Enviroment}
              key={BesteronField.Enviroment}
              errors={errors}
              validationOptions={{
                required: true
              }}
              fullWidth
              control={control}
              options={[
                {
                  value: PaymentGatewayEnvironment.Production,
                  label: (
                    <BasicRadioLabel
                      primaryText={translatePaymentGatewayEnvironment(
                        PaymentGatewayEnvironment.Production
                      )}
                    />
                  )
                },
                {
                  value: PaymentGatewayEnvironment.Sandbox,
                  label: (
                    <BasicRadioLabel
                      primaryText={translatePaymentGatewayEnvironment(
                        PaymentGatewayEnvironment.Sandbox
                      )}
                      secondaryText={t(
                        'Only for testing purposes. All payments made on this environment are dummy and you will be not credited funds for those payments.'
                      )}
                    />
                  )
                }
              ]}
            />
          ]}
        />
        <div className={classes.checkCredentialsButton}>
          <Button variant="outlined" color="primary" disabled>
            {t('Check credentials')}
          </Button>
        </div>
        <InputRow
          nodes={[
            <UncontrolledFormRadioGroup<IBesteronForm, BesteronPaymentMethod>
              label={t('Default payment instrument')}
              name={BesteronField.PaymentInstruments}
              key={BesteronField.PaymentInstruments}
              errors={errors}
              helperText={t(
                'Customer will be able to select or change preferred payment instrument in Besteron interface.'
              )}
              validationOptions={{
                required: true
              }}
              fullWidth
              control={control}
              options={[
                {
                  value: BesteronPaymentMethod.AggregatedView,
                  label: <BasicRadioLabel primaryText={t('Aggregated view')} />
                },
                {
                  value: BesteronPaymentMethod.PaymentCard,
                  label: <BasicRadioLabel primaryText={t('Payment card')} />
                },
                {
                  value: BesteronPaymentMethod.QuickBankTransfer,
                  label: (
                    <BasicRadioLabel primaryText={t('Quick bank transfer')} />
                  )
                }
              ]}
            />
          ]}
        />
      </div>
    </CommonEcommercePaymentMethodFormParts>
  )
}

export const EcommercePaymentMethodForm: React.FC<IEcommercePaymentMethodFormProps> =
  (props: IEcommercePaymentMethodFormProps) => {
    switch (props.paymentServiceProvider) {
      case PaymentServiceProvider.Gopay:
        return <GoPayForm {...props} />
      case PaymentServiceProvider.StatnaPokladnica:
        return <StatnaPokladnicaForm {...props} />
      case PaymentServiceProvider.Besteron:
        return <BesteronForm {...props} />
      default:
        return <InHouseVoucherForm {...props} />
    }
  }
