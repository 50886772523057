import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {LeadData} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {SectionGrid} from '../../../../common'
import {LeadSectionGridContent} from '../LeadSectionGridContent'

const useStyles = makeStyles<Theme>((theme) => ({
  noData: {
    color: theme.palette.text.disabled
  }
}))

interface ILeadSectionGridProps {
  leadData?: LeadData
}

export const LeadSectionGrid: React.FC<ILeadSectionGridProps> = ({
  leadData
}: ILeadSectionGridProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <SectionGrid>
      {leadData &&
      Object.values(omit(leadData, '__typename')).filter(Boolean).length ? (
        <LeadSectionGridContent leadData={leadData} />
      ) : (
        <Typography variant="subtitle2" className={classes.noData}>
          {t('No additional information found')}
        </Typography>
      )}
    </SectionGrid>
  )
}
