import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import {Box, Button, IconButton, Typography} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  AuditoriumPropertiesFragment,
  AuditoriumsQuery,
  AuditoriumState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useTranslateAuditoriumState} from '../../../../../hooks/auditoriumState'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../../common'
import {DataGridTable} from '../../../../common/DataGridTable'
import {auditoriumStateColors} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {CreateAuditoriumDrawer} from './CreateAuditoriumDrawer'
import {useGetAuditoriums, useUpdateAuditoriumsOrder} from './graphql'

const StateRenderer = ({state}: {state: AuditoriumState}) => {
  const translateAuditoriumState = useTranslateAuditoriumState()
  return (
    <EntityStateChip
      label={translateAuditoriumState(state)}
      colorConf={auditoriumStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id, venueId}: {id: number; venueId: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.venues.detailAuditorium(venueId, id)),
    [history, id, venueId]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IAuditoriumsProps {
  venueId: number
  auditoriums: Array<AuditoriumPropertiesFragment>
  refetch: () => Promise<object>
}

const Auditoriums: React.FC<IAuditoriumsProps> = ({
  venueId,
  auditoriums,
  refetch
}: IAuditoriumsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateAuditoriumsOrder = useUpdateAuditoriumsOrder()
  const {addInfoNotification, setShowBackdrop, customErrorHandler} =
    useMutationAssistanceHooks()
  const handleRowOrderChange = useCallback(
    async (params) => {
      const rowsClone = [...auditoriums]
      const row = rowsClone.splice(params.oldIndex, 1)[0]
      rowsClone.splice(params.targetIndex, 0, row)
      try {
        setShowBackdrop(true)
        await updateAuditoriumsOrder({
          venueId,
          auditoriumIds: rowsClone.map(({id}) => id)
        })
        await refetch()
        addInfoNotification(t('Auditoriums have been reordered'))
      } catch (error) {
        customErrorHandler(error, {
          title: t('Operation has failed'),
          contentText: t(
            "We're sorry, but there was a problem updating auditoriums order. Please try again."
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      auditoriums,
      customErrorHandler,
      refetch,
      setShowBackdrop,
      t,
      updateAuditoriumsOrder,
      venueId
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        flex: 1
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: AuditoriumState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        flex: 1
      },
      {
        headerName: '',
        field: 'layoutsCount',
        valueGetter: (params) => params.row.auditoriumLayouts.length,
        valueFormatter: (params) =>
          params.value
            ? t('x layout', {
                count: params.value
              })
            : t('empty'),
        align: 'right',
        headerAlign: 'right',
        minWidth: 120
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} venueId={venueId} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t, venueId]
  )

  return (
    <DataGridTable
      columns={columns}
      rows={auditoriums}
      pagination={false}
      autoHeight
      hideFooter
      disableRowSelectionOnClick
      columnVisibilityModel={{
        id: P([PermissionCode.ReadAuditorium])
      }}
      initialState={{
        pinnedColumns: {left: ['__reorder__'], right: ['layoutsCount', 'id']}
      }}
      rowReordering={P([PermissionCode.UpdateAuditoriumsOrder])}
      slots={{rowReorderIcon: DragHandleIcon}}
      onRowOrderChange={handleRowOrderChange}
    />
  )
}

interface IAuditoriumListProps {
  venueId: number
}

export const AuditoriumList: React.FC<IAuditoriumListProps> = ({
  venueId
}: IAuditoriumListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateAuditoriumDrawerOpen,
    setTrue: openCreateAuditoriumDrawer,
    setFalse: closeOpenAuditoriumDrawer
  } = useBooleanState(false)
  const {data, error, loading, refetch} = useGetAuditoriums({
    venueIds: [venueId]
  })
  return (
    <RenderOnData<AuditoriumsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Could not load auditoriums')}
    >
      {({auditoriums}) => (
        <>
          {auditoriums.length ? (
            <Box
              sx={{
                pt: 8,
                pb: 3,
                display: ' flex',
                flexDirection: 'column',
                gap: 4
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="h6">{t('Auditoriums')}</Typography>
                {P([PermissionCode.CreateAuditorium]) && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openCreateAuditoriumDrawer}
                    startIcon={<AddIcon />}
                  >
                    {t('Create auditorium')}
                  </Button>
                )}
              </Box>
              <Box>
                <Auditoriums
                  venueId={venueId}
                  auditoriums={auditoriums}
                  refetch={refetch}
                />
              </Box>
            </Box>
          ) : (
            <Blank
              title={t('There are no auditoriums created yet.')}
              actions={
                P([PermissionCode.CreateAuditorium]) && (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={openCreateAuditoriumDrawer}
                  >
                    {t('Create auditorium')}
                  </Button>
                )
              }
              contentSx={{minWidth: '100%'}}
            />
          )}
          <CreateAuditoriumDrawer
            isOpen={isCreateAuditoriumDrawerOpen}
            onClose={closeOpenAuditoriumDrawer}
            venueId={venueId}
            refetch={refetch}
          />
        </>
      )}
    </RenderOnData>
  )
}
