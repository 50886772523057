import {Box, Divider, SxProps, Typography} from '@mui/material'
import React from 'react'
import {
  Scalars,
  SeatOptionsEventFragment
} from '../../../../../__generated__/schema'
import {useGetClientLocaleTranslation} from '../../../../../hooks/getClientLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EventAddress, StateRenderer} from '../../events/edit/common'
import {FloorPlanStatus} from './FloorPlanStatus'
import {SeatStates} from './SeatStates'

interface ICurrentStatusContentProps {
  event: SeatOptionsEventFragment
  eventSeats: Scalars['JSON']
  sx?: SxProps<Theme>
}

export const CurrentStatusContent: React.FC<ICurrentStatusContentProps> = ({
  event,
  eventSeats,
  sx
}: ICurrentStatusContentProps) => {
  const getClientLocaleTranslation = useGetClientLocaleTranslation()
  const {formatDateTime} = useDateTimeFormatters()

  return (
    <Box sx={{...sx, overflowY: 'auto'}}>
      <Box sx={{px: 3, py: 2}}>
        <Typography variant="subtitle1">
          {getClientLocaleTranslation(event.names)}
        </Typography>
        <StateRenderer state={event.state} />
        <Typography variant="caption">
          {formatDateTime(new Date(event.startsAt))}
        </Typography>
        <Box sx={{pt: 2}}>
          <EventAddress venue={event.venue} auditorium={event.auditorium} />
        </Box>
      </Box>
      <Divider />
      <FloorPlanStatus
        layout={event.auditoriumLayout.layout}
        sx={{px: 3, py: 2}}
      />
      <Divider />
      <SeatStates sx={{px: 3, py: 2}} eventSeats={eventSeats} />
    </Box>
  )
}
