import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ProductTypeState} from '../../../../__generated__/schema'
import {useTranslateProductTypeState} from '../../../../hooks/productTypeState'
import {Theme} from '../../../../theme'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  IProductTypeForm,
  ProductTypeFormField,
  ProductTypeFormLocation
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface IProductTypeFormProps {
  formId: string
  location: ProductTypeFormLocation
  onSubmit: (data: IProductTypeForm) => void
  defaultValues?: Partial<IProductTypeForm>
  className?: string
}

export const ProductTypeForm: React.FC<IProductTypeFormProps> = ({
  formId,
  location,
  onSubmit,
  defaultValues,
  className
}: IProductTypeFormProps) => {
  const {t} = useTranslation()
  const {handleSubmit, errors, setValue, watch, register, triggerValidation} =
    useForm<IProductTypeForm>({defaultValues})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength5 = useIsStringWithMaxLength(5)
  const translateProductTypeState = useTranslateProductTypeState()
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductTypeFormField.Name}
            key={ProductTypeFormField.Name}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength255
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductTypeFormField.Abbreviation}
            key={ProductTypeFormField.Abbreviation}
            label={t('Abbreviation')}
            helperNote={t('Max length is 5 characters.')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength5
            }}
            required
            fullWidth
          />
        ]}
      />
      {location === ProductTypeFormLocation.Edit && (
        <InputRow
          nodes={[
            <UncontrolledFormSelect
              selectOptions={Object.values(ProductTypeState).reduce(
                (acc, state) => ({
                  ...acc,
                  [state]: translateProductTypeState(state)
                }),
                {}
              )}
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              name={ProductTypeFormField.State}
              key={ProductTypeFormField.State}
              label={t('State')}
              validationOptions={{
                required: true
              }}
              required
              fullWidth
            />
          ]}
        />
      )}
    </form>
  )
}
