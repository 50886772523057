import {Accordion, AccordionDetails, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ClaimPropertiesFragment} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {PaymentIntentRow} from '../../../../common/PaymentIntentRow'
import {PaymentAccordionSummary} from './PaymentAccordionSummary'

const useStyles = makeStyles<Theme, {hasRefundIntents: boolean}>((theme) => ({
  accordionRoot: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    pointerEvents: ({hasRefundIntents}) => (hasRefundIntents ? 'auto' : 'none')
  },
  noPaymentFoundRoot: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  },
  intentsList: {
    width: '100%'
  }
}))

interface IPaymentSectionProps {
  claim: ClaimPropertiesFragment
}

export const PaymentSection: React.FC<IPaymentSectionProps> = ({
  claim
}: IPaymentSectionProps) => {
  const {t} = useTranslation()
  const classes = useStyles({hasRefundIntents: claim.refundIntents.length > 0})
  if (claim.refundIntents.length === 0 && !claim.payment) {
    return (
      <div className={classes.noPaymentFoundRoot}>
        <Typography variant="subtitle2" color="inherit">
          {t('No payment found')}
        </Typography>
      </div>
    )
  }
  return (
    <Accordion classes={{root: classes.accordionRoot}}>
      <PaymentAccordionSummary claim={claim} />
      {claim.refundIntents.length > 0 && (
        <AccordionDetails>
          <ListOfItemsSeparatedByDividers className={classes.intentsList}>
            {claim.refundIntents.map((refundIntent) => (
              <PaymentIntentRow
                intent={refundIntent}
                hasAmountMinus
                key={refundIntent.id}
                currency={
                  refundIntent.payment?.currency ||
                  claim.division.client.currency
                }
              />
            ))}
          </ListOfItemsSeparatedByDividers>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
