import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClaimPropertiesFragment,
  ClaimState
} from '../../../../__generated__/schema'
import {Blank} from '../../../visual/Blank'

interface IChildrenOnValidClaimProps {
  claim: ClaimPropertiesFragment
  children: React.ReactNode
}

export const ChildrenOnValidClaim: React.FC<IChildrenOnValidClaimProps> = ({
  claim,
  children
}: IChildrenOnValidClaimProps) => {
  const {t} = useTranslation()
  if (![ClaimState.Approved, ClaimState.Failed].includes(claim.state)) {
    return (
      <Blank
        title={t('Unable to refund claim')}
        description={t(
          'You are not allowed to refund claim, that is not in state approved. Check state for the claim and try to refund it again.'
        )}
        IconComp={ReportProblemOutlinedIcon}
      />
    )
  }
  return <>{children}</>
}
