import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RedoIcon from '@mui/icons-material/Redo'
import SettingsIcon from '@mui/icons-material/Settings'
import UndoIcon from '@mui/icons-material/Undo'
import {Box, Button, IconButton, Paper} from '@mui/material'
import {uniqueId} from 'lodash'
import omit from 'lodash/omit'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useBooleanState} from '../../../../../../hooks/state'
import {useUndoRedo} from '../../../../../../hooks/undoableState'
import {Blank} from '../../../../../visual/Blank'
import {
  ButtonGroupBlockSettingsFormField,
  ButtonGroupFormField,
  ButtonWithId,
  IButtonGroupBlockProps,
  IButtonGroupBlockSettingsForm,
  IButtonGroupForm,
  IButtonProps
} from '../editBlockDrawer/types'
import {AddButtonGroupDialog} from './AddButtonGroupDialog'
import {ButtonPreview} from './ButtonPreview'
import {EditButtonGroupDialog} from './EditButtonGroupDialog'
import {SettingsDialog} from './SettingsDialog'

interface IButtonRowProps extends ButtonWithId {
  onEditClick: () => void
  onDeleteClick: () => void
  isDeleteButtonDisabled?: boolean
}

const ButtonRow: React.FC<IButtonRowProps> = ({
  id,
  onEditClick,
  onDeleteClick,
  isDeleteButtonDisabled,
  ...buttonProps
}: IButtonRowProps) => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id})
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  return (
    <Paper
      ref={setNodeRef}
      style={style}
      variant="outlined"
      sx={{
        py: 1,
        px: 1
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '48px 1fr 48px 48px',
          alignItems: 'center'
        }}
      >
        <IconButton {...attributes} {...listeners}>
          <DragHandleIcon />
        </IconButton>
        <Box
          sx={{width: '100%', display: 'flex', alignItems: 'center', gap: 1}}
        >
          <ButtonPreview {...buttonProps} />
          {buttonProps.openInNewTab && (
            <OpenInNewIcon color="primary" fontSize="small" />
          )}
        </Box>
        <IconButton color="primary" onClick={onEditClick}>
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={onDeleteClick}
          disabled={isDeleteButtonDisabled}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Paper>
  )
}

interface IButtonGroupBlockContentProps {
  blockProps: IButtonGroupBlockProps
  onBlockPropsChange: (props: IButtonGroupBlockProps) => void
}

export const ButtonGroupBlockContent: React.FC<IButtonGroupBlockContentProps> =
  ({blockProps, onBlockPropsChange}: IButtonGroupBlockContentProps) => {
    const {t} = useTranslation()
    const {
      state: buttons,
      setNewState: setButtons,
      canUndo,
      canRedo,
      undo,
      redo
    } = useUndoRedo<ButtonWithId[]>(
      blockProps.buttons.map((button) => ({
        id: uniqueId(),
        ...button
      }))
    )
    const [blockSettings, setBlockSettings] = useState<
      Omit<IButtonGroupBlockProps, 'buttons'>
    >(omit(blockProps, ['buttons']))
    const [buttonForEdit, setButtonForEdit] =
      useState<ButtonWithId | null>(null)
    const {
      state: isCreateDialogOpen,
      setTrue: openCreateDialog,
      setFalse: closeCreateDialog
    } = useBooleanState(false)
    const {
      state: isSettingsDialogOpen,
      setTrue: openSettingsDialog,
      setFalse: closeSettingsDialog
    } = useBooleanState(false)
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates
      }),
      useSensor(TouchSensor)
    )
    const handleDragEnd = useCallback(
      (event: DragEndEvent) => {
        const {active, over} = event
        if (active.id !== over?.id) {
          setButtons((prevButtons) => {
            const oldIndex = prevButtons.findIndex(({id}) => id === active.id)
            const newIndex = prevButtons.findIndex(({id}) => id === over?.id)
            return arrayMove(prevButtons, oldIndex, newIndex)
          })
        }
      },
      [setButtons]
    )
    const handleAddButtonGroup = useCallback(
      (data: IButtonGroupForm) => {
        setButtons((prevButtons) => [
          ...prevButtons,
          {
            id: uniqueId(),
            ...data,
            size: data[ButtonGroupFormField.Size] || undefined,
            variant: data[ButtonGroupFormField.Variant] || undefined,
            icon: data[ButtonGroupFormField.Icon] || undefined,
            iconPosition: data[ButtonGroupFormField.IconPosition] || undefined,
            className: data[ButtonGroupFormField.ClassName] || undefined
          }
        ])
        closeCreateDialog()
      },
      [closeCreateDialog, setButtons]
    )
    const handleSaveButton = useCallback(
      (data: IButtonProps) => {
        setButtons((prevButtons) =>
          prevButtons.map((button) =>
            button.id === buttonForEdit?.id
              ? {
                  ...button,
                  ...data,
                  size: data[ButtonGroupFormField.Size] || undefined,
                  variant: data[ButtonGroupFormField.Variant] || undefined,
                  icon: data[ButtonGroupFormField.Icon] || undefined,
                  iconPosition:
                    data[ButtonGroupFormField.IconPosition] || undefined,
                  className: data[ButtonGroupFormField.ClassName] || undefined
                }
              : button
          )
        )
        setButtonForEdit(null)
      },
      [buttonForEdit?.id, setButtons]
    )
    const handleDeleteButtonClick = useCallback(
      (button: ButtonWithId) => () =>
        setButtons((prevButtons) =>
          prevButtons.filter(({id}) => id !== button.id)
        ),
      [setButtons]
    )
    const handleSettingsChange = useCallback(
      (settings: IButtonGroupBlockSettingsForm) => {
        setBlockSettings({
          className:
            settings[ButtonGroupBlockSettingsFormField.ClassName] || undefined
        })
        closeSettingsDialog()
      },
      [closeSettingsDialog]
    )
    useEffect(() => {
      onBlockPropsChange({
        ...blockSettings,
        buttons: buttons.map((button) => omit(button, 'id')) || []
      })
    }, [buttons, onBlockPropsChange, blockSettings])
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'background.paper',
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <IconButton color="primary" disabled={!canUndo} onClick={undo}>
              <UndoIcon />
            </IconButton>
            <IconButton color="primary" disabled={!canRedo} onClick={redo}>
              <RedoIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <Button
              variant="text"
              color="primary"
              startIcon={<SettingsIcon />}
              onClick={openSettingsDialog}
            >
              {t('Settings')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openCreateDialog}
            >
              {t('Add')}
            </Button>
          </Box>
        </Box>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={buttons}
            strategy={verticalListSortingStrategy}
          >
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
              {buttons.length > 0 ? (
                buttons.map((button) => (
                  <ButtonRow
                    key={button.id}
                    onEditClick={() => setButtonForEdit(button)}
                    onDeleteClick={handleDeleteButtonClick(button)}
                    isDeleteButtonDisabled={buttons.length === 1}
                    {...button}
                  />
                ))
              ) : (
                <Blank title={t('No button created yet.')} />
              )}
            </Box>
          </SortableContext>
        </DndContext>
        <AddButtonGroupDialog
          isOpen={isCreateDialogOpen}
          onClose={closeCreateDialog}
          onAdd={handleAddButtonGroup}
        />
        <EditButtonGroupDialog
          button={buttonForEdit}
          onClose={() => setButtonForEdit(null)}
          onSave={handleSaveButton}
        />
        <SettingsDialog
          isOpen={isSettingsDialogOpen}
          onClose={closeSettingsDialog}
          defaultValues={{
            [ButtonGroupBlockSettingsFormField.ClassName]:
              blockSettings.className
          }}
          onSubmit={handleSettingsChange}
        />
      </Box>
    )
  }
