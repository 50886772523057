import {Box, Chip, Paper, Typography} from '@mui/material'
import React from 'react'
import {ApiResource} from '../../../../../__generated__/schema'
import {useTranslateApiResource} from '../../../../../hooks/translateApiResource'

interface IResourcesSectionProps {
  id: string
  label: string
  resources: ApiResource[]
}

export const ResourcesSection: React.FC<IResourcesSectionProps> = ({
  id,
  label,
  resources
}: IResourcesSectionProps) => {
  const translateApiResource = useTranslateApiResource()
  return (
    <Box id={id}>
      <Typography variant="subtitle1" sx={{pb: 2}}>
        {label}
      </Typography>
      <Paper variant="outlined">
        <Box sx={{p: 2, display: 'flex', alignItems: 'center', gap: 1}}>
          {resources.map((resource) => (
            <Chip key={resource} label={translateApiResource(resource)} />
          ))}
        </Box>
      </Paper>
    </Box>
  )
}
