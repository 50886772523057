import React from 'react'
import {useTranslation} from 'react-i18next'
import {EventSeatsQuery} from '../../../../../__generated__/schema'
import {useEventSeats} from '../../../../../hooks/eventSeats'
import {RenderOnData} from '../../../../common'
import {useCurrentCart} from '../CurrentCartContext'

interface IRenderOnEventSeatsProps {
  eventId: number
  reservationId: number
  children: (data: EventSeatsQuery) => React.ReactElement
}

export const RenderOnEventSeats: React.FC<IRenderOnEventSeatsProps> = ({
  eventId,
  reservationId,
  children
}: IRenderOnEventSeatsProps) => {
  const {t} = useTranslation()
  const {currentCartId} = useCurrentCart()
  const {loading, data, error} = useEventSeats({
    eventId,
    myCartId: currentCartId,
    myReservationIds: [reservationId]
  })
  return (
    <RenderOnData
      data={data}
      loading={loading}
      error={error}
      ignoreLoadingIfData
      dataCondition={(data) => Boolean(data?.eventSeats)}
      errorMessage={t<string>(
        "Failed to load event seats for reservation's event"
      )}
    >
      {(data: EventSeatsQuery) => children(data)}
    </RenderOnData>
  )
}
