import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, Button, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  MessagesFilterInput,
  MessagesQuery,
  MessageState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateMessageState} from '../../../../hooks/translateMessageState'
import {useTranslateMessageType} from '../../../../hooks/translateMessageType'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {messageStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {useGetMessages} from './graphql'

const StateRenderer = ({state}: {state: MessageState}) => {
  const translateMessageState = useTranslateMessageState()
  return (
    <EntityStateChip
      colorConf={messageStateColors[state]}
      label={translateMessageState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.messages.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IMessagesListProps {
  onCreateButtonClick: () => void
  searchFilter: MessagesFilterInput
}

export const MessagesList: React.FC<IMessagesListProps> = ({
  onCreateButtonClick,
  searchFilter
}: IMessagesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error} = useGetMessages({
    paginationInput,
    filter: searchFilter
  })
  const translateMessageType = useTranslateMessageType()
  const dateTimeFormatter = useDateTimeFormatter()
  const userNameFormatter = useUserNameFormatter()
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Internal note'),
        field: 'internalNote',
        sortable: false,
        minWidth: 300
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: MessageState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Recipients'),
        field: 'recipientsCount',
        valueGetter: (params) => params.row.leads.length,
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Type'),
        field: 'type',
        valueFormatter: (params) => translateMessageType(params.value),
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Sender name'),
        field: 'senderName',
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Reply to'),
        field: 'replyTo',
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [dateTimeFormatter, t, translateMessageType, userNameFormatter]
  )
  return (
    <RenderOnData<MessagesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading messages')}
      dataCondition={(data) => Array.isArray(data.messages.items)}
      ignoreLoadingIfData
    >
      {({messages}) => (
        <>
          <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
            {messages.items.length > 0 ? (
              <DataGridTable
                columns={columns}
                loading={loading}
                rows={messages.items}
                disableColumnMenu
                disableRowSelectionOnClick
                initialState={{
                  pinnedColumns: {left: ['internalNote'], right: ['id']}
                }}
                columnVisibilityModel={{
                  id: P([PermissionCode.ReadMessage])
                }}
                {...getDataGridPaginationProps(messages.pagination)}
              />
            ) : (
              <Blank
                title={t('No messages found')}
                description={t(
                  'Send message to customers about canceled or changed events, refunds, ...'
                )}
                actions={
                  P([PermissionCode.SendMessage]) && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={onCreateButtonClick}
                    >
                      {t('Create')}
                    </Button>
                  )
                }
              />
            )}
          </Box>
          {P([PermissionCode.SendMessage]) && messages.items.length > 0 && (
            <CreateFab classes={fabClasses} onClick={onCreateButtonClick} />
          )}
        </>
      )}
    </RenderOnData>
  )
}
