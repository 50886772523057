import {Box, Button} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CashDeskAdmissionRateFieldsFragment,
  CashDeskTourTimeSlotDetailPropertiesFragment,
  PermissionCode,
  ReservationDetailFragment,
  ReservationState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {TourTimeSlotViewMode} from '../../../../../types'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {BackdropWithInfo} from '../../../../visual'
import {Blank} from '../../../../visual/Blank'
import {TourTimeSlotViewModeSwitchButton} from '../../components/TourTimeSlotViewModeSwitchButton'
import {isTourItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {EditTourTicketQuantityDialog} from '../EditTourTicketQuantityDialog'
import {
  useDecrementTourItemQuantityInReservation,
  useIncrementTourItemQuantityInReservation
} from '../graphql'
import {TourTimeSlotGridView} from '../TourTimeSlotGridView'
import {TourTimeSlotListView} from '../TourTimeSlotListView'
import {useGetTourItemQuantity, useIncrementErrorHandler} from '../utils'
import {ReservationHasNoItemsDialog} from './ReservationHasNoItemsDialog'
import {SubHeader} from './SubHeader'
import {SubReservation} from './SubReservation'

interface ITourTimeSlotContentProps {
  reservation: ReservationDetailFragment
  tourTimeSlot: CashDeskTourTimeSlotDetailPropertiesFragment
  refetch: () => void
}

export const TourTimeSlotContent: React.FC<ITourTimeSlotContentProps> = ({
  reservation,
  tourTimeSlot,
  refetch
}: ITourTimeSlotContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {currentCart} = useCurrentCart()
  const incrementTourItemQuantityInReservation =
    useIncrementTourItemQuantityInReservation()
  const decrementTourItemQuantityInReservation =
    useDecrementTourItemQuantityInReservation()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const incrementErrorHandler = useIncrementErrorHandler()
  const {getIncrementTourItemQuantity, getDecrementTourItemQuantity} =
    useGetTourItemQuantity(
      (reservation.items || []).filter(isTourItemPropertiesFragment)
    )
  const [defaultTourTimeSlotViewMode] =
    useLocalStorageState<TourTimeSlotViewMode>(
      LocalStorageKey.TourTimeSlotViewMode,
      TourTimeSlotViewMode.GridView
    )
  const [tourTimeSlotViewMode, setTourTimeSlotViewMode] =
    useState<TourTimeSlotViewMode>(defaultTourTimeSlotViewMode)
  const [
    selectedAdmissionTypesAssignment,
    setSelectedAdmissionTypesAssignment
  ] =
    useState<
      | CashDeskAdmissionRateFieldsFragment['admissionTypesAssignments'][number]
      | null
    >(null)
  const isReservationInCurrentCart =
    reservation.state === ReservationState.InCart &&
    !!currentCart?.items
      ?.filter(isTourItemPropertiesFragment)
      .find((item) => item.reservation?.id === reservation.id)
  const handleViewModeChange = useCallback(
    () =>
      setTourTimeSlotViewMode(
        tourTimeSlotViewMode === TourTimeSlotViewMode.GridView
          ? TourTimeSlotViewMode.ListView
          : TourTimeSlotViewMode.GridView
      ),
    [tourTimeSlotViewMode]
  )
  const handleIncrementTourItem = useCallback(
    (
        admissionTypeAssignmentId: number,
        startingQuantity: number,
        admissionTypeName: string
      ) =>
      async (e: React.MouseEvent) => {
        e.stopPropagation()
        try {
          setShowBackdrop(true)
          await incrementTourItemQuantityInReservation({
            reservationId: reservation.id,
            admissionTypeAssignmentId,
            increment: getIncrementTourItemQuantity(
              startingQuantity,
              admissionTypeAssignmentId,
              tourTimeSlot.id
            )
          })
        } catch (error) {
          incrementErrorHandler(error, admissionTypeName)
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      getIncrementTourItemQuantity,
      incrementErrorHandler,
      incrementTourItemQuantityInReservation,
      reservation.id,
      setShowBackdrop,
      tourTimeSlot.id
    ]
  )
  const handleDecrementTourItem = useCallback(
    (admissionTypeAssignmentId: number, startingQuantity: number) =>
      async (e: React.MouseEvent) => {
        e.stopPropagation()
        try {
          setShowBackdrop(true)
          await decrementTourItemQuantityInReservation({
            reservationId: reservation.id,
            admissionTypeAssignmentId,
            decrement: getDecrementTourItemQuantity(
              startingQuantity,
              admissionTypeAssignmentId,
              tourTimeSlot.id
            )
          })
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while decrementing tour item quantity in reservation')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
    [
      decrementTourItemQuantityInReservation,
      defaultErrorHandler,
      getDecrementTourItemQuantity,
      reservation.id,
      setShowBackdrop,
      t,
      tourTimeSlot.id
    ]
  )
  const handleQuantityChange = useCallback(
    async ({
      admissionTypeAssignmentId,
      increment,
      decrement,
      admissionTypeName
    }: {
      admissionTypeAssignmentId: number
      increment?: number
      decrement?: number
      admissionTypeName: string
    }) => {
      if (increment) {
        try {
          setShowBackdrop(true)
          await incrementTourItemQuantityInReservation({
            reservationId: reservation.id,
            admissionTypeAssignmentId,
            increment
          })
        } catch (error) {
          incrementErrorHandler(error, admissionTypeName)
        } finally {
          setShowBackdrop(false)
        }
      } else if (decrement) {
        try {
          setShowBackdrop(true)
          await decrementTourItemQuantityInReservation({
            reservationId: reservation.id,
            admissionTypeAssignmentId,
            decrement
          })
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while decrementing tour item quantity in reservation')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
      setSelectedAdmissionTypesAssignment(null)
    },
    [
      decrementTourItemQuantityInReservation,
      defaultErrorHandler,
      incrementErrorHandler,
      incrementTourItemQuantityInReservation,
      reservation.id,
      setShowBackdrop,
      t
    ]
  )
  const getQuantity = useCallback(
    (assignmentId: number) =>
      (reservation.items || [])
        .filter(isTourItemPropertiesFragment)
        .filter((i) => i.tourTimeSlotId === tourTimeSlot.id)
        .filter((i) => i.reservation?.id === reservation.id)
        .filter((i) => i.admissionTypeAssignmentId === assignmentId).length,
    [reservation.id, reservation.items, tourTimeSlot.id]
  )
  const handleOpenEditQuantityDialog = useCallback(
    (
        assignment: CashDeskAdmissionRateFieldsFragment['admissionTypesAssignments'][number]
      ) =>
      (e: React.MouseEvent) => {
        e.stopPropagation()
        setSelectedAdmissionTypesAssignment(assignment)
      },
    []
  )
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: `
      "subReservation subHeader"
      "subReservation preview"
    `,
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '360px 1fr',
        height: '100%'
      }}
    >
      <SubHeader
        reservation={reservation}
        tourTimeSlot={tourTimeSlot}
        sx={{gridArea: 'subHeader'}}
      />
      <SubReservation
        reservation={reservation}
        isReservationInCurrentCart={isReservationInCurrentCart}
        refetch={refetch}
        sx={{
          gridArea: 'subReservation',
          backgroundColor: 'background.paper',
          borderRight: (theme) => `solid ${theme.palette.divider} 1px`
        }}
      />
      <Box
        sx={{
          gridArea: 'preview',
          position: 'relative',
          overflowY: isReservationInCurrentCart ? 'hidden' : 'auto'
        }}
      >
        {tourTimeSlot.admissionRate.admissionTypesAssignments.length === 0 ? (
          <Blank
            title={t('No admissions have been found')}
            description={t(
              'There are no admissions available for retail right now. Please adjust admission rate settings or contact your supervisor.'
            )}
            actions={
              P([PermissionCode.ManageAdmissionRates]) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(
                      routeTo.admin.tours.admissionRateDetail(
                        tourTimeSlot.tour.id,
                        tourTimeSlot.admissionRate.id
                      )
                    )
                  }
                >
                  {t('Admission rate')}
                </Button>
              )
            }
          />
        ) : (
          <>
            {tourTimeSlotViewMode === TourTimeSlotViewMode.GridView ? (
              <TourTimeSlotGridView
                admissionRate={tourTimeSlot.admissionRate}
                quantityGetter={getQuantity}
                onCardClick={handleIncrementTourItem}
                onDecrementButtonClick={handleDecrementTourItem}
                onOpenEditQuantityDialogClick={handleOpenEditQuantityDialog}
              />
            ) : (
              <TourTimeSlotListView
                admissionRate={tourTimeSlot.admissionRate}
                quantityGetter={getQuantity}
                onIncrementButtonClick={handleIncrementTourItem}
                onDecrementButtonClick={handleDecrementTourItem}
                onOpenEditQuantityDialogClick={handleOpenEditQuantityDialog}
              />
            )}
          </>
        )}
        {selectedAdmissionTypesAssignment && (
          <EditTourTicketQuantityDialog
            admissionTypesAssignment={selectedAdmissionTypesAssignment}
            onClose={() => setSelectedAdmissionTypesAssignment(null)}
            itemsInCart={
              (reservation.items || [])
                .filter(isTourItemPropertiesFragment)
                .filter((i) => i.tourTimeSlotId === tourTimeSlot.id)
                .filter((i) => i.reservation?.id === reservation.id)
                .filter(
                  (i) =>
                    i.admissionTypeAssignmentId ===
                    selectedAdmissionTypesAssignment.id
                ).length
            }
            onSaveClick={handleQuantityChange}
          />
        )}
        <TourTimeSlotViewModeSwitchButton
          currentMode={tourTimeSlotViewMode}
          onClick={handleViewModeChange}
        />
        <BackdropWithInfo
          isVisible={isReservationInCurrentCart}
          title={t('Reservation is in cart')}
          description={t(
            'Unable to edit reservation added to cart. If you want to change anything in reservation, remove it from cart first.'
          )}
        />
        {reservation.items?.length === 0 && <ReservationHasNoItemsDialog />}
      </Box>
    </Box>
  )
}
