import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import {Typography, useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {compact} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  ReservationsFilterInput,
  ReservationsQuery
} from '../../../../../__generated__/schema'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {Theme} from '../../../../../theme'
import {RESERVATION_PARAMS} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {RenderOnData, SubTopbarControls} from '../../../../common'
import {LoadingMoreProgress} from '../../../../common/LoadingMoreProgress'
import {MediaSizes} from '../../../../constants'
import {Blank, BlankSearch} from '../../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {EnabledDivisionsTabs} from '../../components/EnabledDivisionsTabs'
import {ReservationDetailDrawer} from '../../components/reservationDetailDrawer'
import {usePostDisplayBaseViewMessageOnInit} from '../../customerDisplay/postDisplayBaseViewMessageOnInit'
import {useGetLightweightDivisions} from '../../graphql'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../../Layout'
import {useGetReservations} from '../graphql'
import {ListItemWrapper} from '../ListItemWrapper'
import {
  DEFAULT_RESERVATIONS_FILTER_INPUT,
  ReservationSearchLocation,
  ReservationsSearch
} from '../ReservationsSearch'
import {ReservationListItem} from './ReservationListItem'

const reservationDrawerPaths = {
  initialPath: routeTo.admin.cashDesk.reservations(),
  detailPath: routeTo.admin.cashDesk.reservationDetail(
    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
  ),
  editPath: routeTo.admin.cashDesk.reservationDetailEdit(
    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  loadingMoreProgress: {
    paddingTop: theme.spacing(2)
  }
}))

export const Reservations: React.FC = () => {
  const classes = useStyles()
  usePostDisplayBaseViewMessageOnInit()
  const {t} = useTranslation()
  const {divisions} = useGetLightweightDivisions()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const [filter, setFilter] = useState<ReservationsFilterInput>(
    DEFAULT_RESERVATIONS_FILTER_INPUT
  )
  const [enabledDivisionsIds] = useLocalStorageState<number[]>(
    LocalStorageKey.EnabledDivisions,
    []
  )
  const enabledDivisions = compact(
    enabledDivisionsIds.map((ed) => divisions.find(({id}) => id === ed))
  )
  const areDivisionTabsShown = enabledDivisions.length > 1
  const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(
    enabledDivisionsIds ? enabledDivisionsIds[0] : null
  )
  const displayAsSearchResults = useMemo(
    () =>
      JSON.stringify(DEFAULT_RESERVATIONS_FILTER_INPUT) !==
      JSON.stringify(filter),
    [filter]
  )
  const history = useHistory()
  const {
    data,
    error,
    loading,
    fetchMore,
    isLoadingMore,
    refetch: refetchReservations
  } = useGetReservations({
    ...filter,
    divisionId: selectedDivisionId || filter.divisionId
  })

  const getOnInfoClickHandler = useCallback(
    (reservationId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      history.push(routeTo.admin.cashDesk.reservationDetail(reservationId))
    },
    [history]
  )

  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.reservations.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.reservations.pagination.hasMore, fetchMore, isLoadingMore])
  const handleTabChange = useCallback(
    (e: React.ChangeEvent<{}>, divisionId: number) => {
      setSelectedDivisionId(divisionId)
    },
    []
  )
  return (
    <>
      <FullScreenCenteredLayout
        subTopbar={
          <>
            <SubTopbarControls
              leftChildren={
                <ReservationsSearch
                  location={ReservationSearchLocation.ReservationsList}
                  onFilterChange={setFilter}
                />
              }
            />
            {areDivisionTabsShown && (
              <EnabledDivisionsTabs
                enabledDivisions={enabledDivisions}
                selectedDivisionId={selectedDivisionId}
                onTabChange={handleTabChange}
              />
            )}
          </>
        }
        onScrolledNearTheEndOfTheLayout={
          data?.reservations.pagination.hasMore
            ? handleScrolledNearTheEndOfTheLayout
            : undefined
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData
            data={data}
            error={error}
            errorMessage={t<string>('Failed to load reservations')}
            loading={loading}
            dataCondition={(data?: ReservationsQuery) =>
              Array.isArray(data?.reservations.items)
            }
          >
            {({reservations}: ReservationsQuery) =>
              reservations.items.length ? (
                <CenteredLayoutListWrapper>
                  {displayAsSearchResults && (
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('Search results')}
                    </Typography>
                  )}
                  {reservations.items.map((reservation) => (
                    <ListItemWrapper key={reservation.id}>
                      <ReservationListItem
                        reservation={reservation}
                        onInfoClick={getOnInfoClickHandler(reservation.id)}
                        isPhablet={isPhablet}
                      />
                    </ListItemWrapper>
                  ))}
                  {isLoadingMore && (
                    <LoadingMoreProgress
                      className={classes.loadingMoreProgress}
                    />
                  )}
                </CenteredLayoutListWrapper>
              ) : displayAsSearchResults ? (
                <BlankSearch />
              ) : (
                <Blank
                  title={t('No reservations found')}
                  IconComp={BookmarkBorderIcon}
                />
              )
            }
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </FullScreenCenteredLayout>
      <Route path={Object.values(reservationDrawerPaths)} exact>
        <ReservationDetailDrawer
          {...reservationDrawerPaths}
          onReservationDelete={refetchReservations}
          showPrimaryButton
        />
      </Route>
    </>
  )
}
