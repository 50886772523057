import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AddDiscountToTourItemsMutation,
  AddDiscountToTourItemsMutationVariables,
  CashDeskToursQuery,
  CashDeskToursQueryVariables,
  CashDeskTourTimeSlotQuery,
  CashDeskTourTimeSlotQueryVariables,
  DecrementTourItemQuantityMutation,
  DecrementTourItemQuantityMutationVariables,
  EnabledDiscountsForTourTimeSlotQuery,
  EnabledDiscountsForTourTimeSlotQueryVariables,
  IncrementTourItemQuantityMutation,
  IncrementTourItemQuantityMutationVariables,
  LightweightTourTimeSlotQuery,
  LightweightTourTimeSlotQueryVariables,
  RemoveAllTourItemsFromCartForTimeSlotMutation,
  RemoveAllTourItemsFromCartForTimeSlotMutationVariables,
  RemoveTourItemFromCartMutation,
  RemoveTourItemFromCartMutationVariables
} from '../../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../../graphql'
import {
  CART_PROPERTIES_FRAGMENT,
  ENABLED_DISCOUNT_PROPERTIES_FRAGMENT,
  TOUR_ITEMS_STATE_COUNTS_FIELDS,
  USER_FIELDS
} from '../../graphql'
import {CASH_DESK_ADMISSION_RATE_FIELDS} from '../graphql'

const CASH_DESK_TOURS = gql`
  ${PAGINATION_FRAGMENT}
  ${USER_FIELDS}
  ${TOUR_ITEMS_STATE_COUNTS_FIELDS}
  query CashDeskTours(
    $filter: ToursFilter
    $timeSlotsFilter: TimeSlotsFilter
    $paginationInput: PaginationInput!
  ) {
    tours(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        internalNote
        division {
          id
          name
        }
        show {
          id
          typeCode
        }
        timeSlots(
          filter: $timeSlotsFilter
          paginationInput: {offset: 0, limit: 3}
        ) {
          items {
            id
            startsAt
            guide {
              ...UserFields
            }
            versionCode
            ageClassificationCode
            retailAttendeesLimit
            tourItemsStateCounts {
              ...TourItemsStateCountsFields
            }
          }
          pagination {
            ...PaginationProperties
          }
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useCashDeskTours = (variables: CashDeskToursQueryVariables) =>
  useQuery<CashDeskToursQuery, CashDeskToursQueryVariables>(CASH_DESK_TOURS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CASH_DESK_TOUR_TIME_SLOT = gql`
  ${USER_FIELDS}
  ${TOUR_ITEMS_STATE_COUNTS_FIELDS}
  ${CASH_DESK_ADMISSION_RATE_FIELDS}
  query CashDeskTourTimeSlot($id: PositiveInt!) {
    tourTimeSlot(id: $id) {
      id
      startsAt
      state
      isRetailSaleActive
      isRetailReservationActive
      retailReservationStartsAt
      retailReservationEndsAt
      retailSaleStartsAt
      retailSaleEndsAt
      tour {
        id
        name
      }
      versionCode
      guide {
        ...UserFields
      }
      tourItemsStateCounts {
        ...TourItemsStateCountsFields
      }
      ageClassificationCode
      retailAttendeesLimit
      admissionRate {
        ...CashDeskAdmissionRateFields
      }
    }
  }
`

export const useCashDeskTourTimeSlot = (id: number) =>
  useQuery<CashDeskTourTimeSlotQuery, CashDeskTourTimeSlotQueryVariables>(
    CASH_DESK_TOUR_TIME_SLOT,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

const INCREMENT_TOUR_ITEM_QUANTITY = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation IncrementTourItemQuantity(
    $cartId: PositiveInt
    $tourTimeSlotId: PositiveInt!
    $admissionTypeAssignmentId: PositiveInt!
    $increment: PositiveInt!
  ) {
    incrementTourItemQuantity(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
      admissionTypeAssignmentId: $admissionTypeAssignmentId
      increment: $increment
    ) {
      ...CartProperties
    }
  }
`

export const useIncrementTourItemQuantity = () => {
  const [incrementTourItemQuantity] = useMutation<
    IncrementTourItemQuantityMutation,
    IncrementTourItemQuantityMutationVariables
  >(INCREMENT_TOUR_ITEM_QUANTITY)
  return useCallback(
    (variables: IncrementTourItemQuantityMutationVariables) =>
      incrementTourItemQuantity({variables}),
    [incrementTourItemQuantity]
  )
}

const DECREMENT_TOUR_ITEM_QUANTITY = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation DecrementTourItemQuantity(
    $cartId: PositiveInt!
    $tourTimeSlotId: PositiveInt!
    $admissionTypeAssignmentId: PositiveInt!
    $decrement: PositiveInt!
  ) {
    decrementTourItemQuantity(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
      admissionTypeAssignmentId: $admissionTypeAssignmentId
      decrement: $decrement
    ) {
      ...CartProperties
    }
  }
`

export const useDecrementTourItemQuantity = () => {
  const [decrementTourItemQuantity] = useMutation<
    DecrementTourItemQuantityMutation,
    DecrementTourItemQuantityMutationVariables
  >(DECREMENT_TOUR_ITEM_QUANTITY)
  return useCallback(
    (variables: DecrementTourItemQuantityMutationVariables) =>
      decrementTourItemQuantity({variables}),
    [decrementTourItemQuantity]
  )
}

const REMOVE_TOUR_ITEM_FROM_CART = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation RemoveTourItemFromCart(
    $itemId: PositiveInt!
    $cartId: PositiveInt!
  ) {
    removeTourItemFromCart(itemId: $itemId, cartId: $cartId) {
      ...CartProperties
    }
  }
`

export const useRemoveTourItemFromCart = () => {
  const [removeTourItemFromCart] = useMutation<
    RemoveTourItemFromCartMutation,
    RemoveTourItemFromCartMutationVariables
  >(REMOVE_TOUR_ITEM_FROM_CART)
  return useCallback(
    (variables: RemoveTourItemFromCartMutationVariables) =>
      removeTourItemFromCart({variables}),
    [removeTourItemFromCart]
  )
}

const REMOVE_ALL_TOUR_ITEMS_FROM_CART_FOR_TIME_SLOT = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation RemoveAllTourItemsFromCartForTimeSlot(
    $cartId: PositiveInt!
    $tourTimeSlotId: PositiveInt!
  ) {
    removeAllTourItemsFromCartForTimeSlot(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
    ) {
      ...CartProperties
    }
  }
`

export const useRemoveAllTourItemsFromCartForTimeSlot = () => {
  const [removeAllTourItemsFromCartForTimeSlot] = useMutation<
    RemoveAllTourItemsFromCartForTimeSlotMutation,
    RemoveAllTourItemsFromCartForTimeSlotMutationVariables
  >(REMOVE_ALL_TOUR_ITEMS_FROM_CART_FOR_TIME_SLOT)
  return useCallback(
    (variables: RemoveAllTourItemsFromCartForTimeSlotMutationVariables) =>
      removeAllTourItemsFromCartForTimeSlot({variables}),
    [removeAllTourItemsFromCartForTimeSlot]
  )
}

const LIGHTWEIGHT_TOUR_TIME_SLOT = gql`
  query LightweightTourTimeSlot($id: PositiveInt!) {
    tourTimeSlot(id: $id) {
      id
      startsAt
      tour {
        id
        name
      }
    }
  }
`

export const useLightweightTourTimeSlot = (id: number) =>
  useQuery<LightweightTourTimeSlotQuery, LightweightTourTimeSlotQueryVariables>(
    LIGHTWEIGHT_TOUR_TIME_SLOT,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

const ENABLED_DISCOUNTS_FOR_TOUR_TIME_SLOT = gql`
  ${ENABLED_DISCOUNT_PROPERTIES_FRAGMENT}
  query EnabledDiscountsForTourTimeSlot(
    $id: PositiveInt!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    tourTimeSlot(id: $id) {
      id
      enabledDiscounts(discountsFilter: $discountsFilter) {
        ...EnabledDiscountProperties
      }
    }
  }
`

export const useEnabledDiscountsForTourTimeSlot = (
  variables: EnabledDiscountsForTourTimeSlotQueryVariables
) =>
  useQuery<
    EnabledDiscountsForTourTimeSlotQuery,
    EnabledDiscountsForTourTimeSlotQueryVariables
  >(ENABLED_DISCOUNTS_FOR_TOUR_TIME_SLOT, {
    variables,
    fetchPolicy: 'cache-and-network'
  })

const ADD_DISCOUNT_TO_TOUR_ITEMS = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation AddDiscountToTourItems(
    $cartId: PositiveInt!
    $itemIds: [PositiveInt!]!
    $discountId: PositiveInt!
    $discountCodeId: PositiveInt
  ) {
    addDiscountToTourItems(
      cartId: $cartId
      itemIds: $itemIds
      discountId: $discountId
      discountCodeId: $discountCodeId
    ) {
      ...CartProperties
    }
  }
`

export const useAddDiscountToTourItems = () => {
  const [addDiscountToTourItems] = useMutation<
    AddDiscountToTourItemsMutation,
    AddDiscountToTourItemsMutationVariables
  >(ADD_DISCOUNT_TO_TOUR_ITEMS)
  return useCallback(
    (variables: AddDiscountToTourItemsMutationVariables) =>
      addDiscountToTourItems({variables}),
    [addDiscountToTourItems]
  )
}
