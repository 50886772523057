import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CostCenterForm} from './CostCenterForm'
import {useCreateCostCenter} from './graphql'
import {CostCenterFormField, CostCenterState, ICostCenterForm} from './types'

const CREATE_COST_CENTER_FORM_ID = 'createCostCenterForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface ICreateCostCenterDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateCostCenterDrawer: React.FC<ICreateCostCenterDrawerProps> = ({
  isOpen,
  onClose
}: ICreateCostCenterDrawerProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const createCostCenter = useCreateCostCenter()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (data: ICostCenterForm) => {
      try {
        setShowBackdrop(true)
        await createCostCenter({
          data: {
            ...data,
            isActive:
              data[CostCenterFormField.IsActive] === CostCenterState.Active
          }
        })
        addInfoNotification(t('Cost center has been created'))
        onClose()
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating cost center'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createCostCenter,
      defaultErrorHandler,
      onClose,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create cost center')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_COST_CENTER_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
      >
        <CostCenterForm
          formId={CREATE_COST_CENTER_FORM_ID}
          onSubmit={handleSubmit}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
