import {Box, Divider, Paper, Typography} from '@mui/material'
import React from 'react'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {
  useDaysCountBetweenTodayAndExpirationDate,
  useGetExpirationToNowColor
} from '../utils'

interface IMobileReservationListItemProps {
  expireAt: string
  reservationId: string
  translatedPrice: string
  startsAt?: string
  name: string
  leadName?: string | null
  leadEmail?: string | null
  onClick?: () => void
}

export const MobileReservationListItem: React.FC<IMobileReservationListItemProps> =
  ({
    expireAt,
    reservationId,
    translatedPrice,
    startsAt,
    name,
    leadName,
    leadEmail,
    onClick
  }: IMobileReservationListItemProps) => {
    const {formatDateNumericShort, formatTime, formatDateNumeric} =
      useDateTimeFormatters()
    const daysCountBetweenTodayAndExpirationDate =
      useDaysCountBetweenTodayAndExpirationDate(expireAt)
    const getExpirationToNowColor = useGetExpirationToNowColor({
      isWithBackground: false
    })
    return (
      <Paper
        variant="outlined"
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'flex-start',
          gap: 1,
          cursor: onClick ? 'pointer' : 'auto'
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            minWidth: 100
          }}
        >
          <Box>
            <Typography
              variant="caption"
              className={getExpirationToNowColor(
                daysCountBetweenTodayAndExpirationDate
              )}
            >
              {formatDateNumericShort(new Date(expireAt))}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {[' • ', formatTime(new Date(expireAt))].join('')}
            </Typography>
          </Box>
          <Typography variant="caption">{reservationId}</Typography>
          <Typography variant="caption" color="textSecondary">
            {translatedPrice}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
          }}
        >
          {(leadName || leadEmail) && (
            <Typography
              variant="caption"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical'
              }}
            >
              {[leadName, leadEmail].filter(Boolean).join(', ')}
            </Typography>
          )}
          {startsAt && (
            <Typography variant="caption" color="textSecondary">
              {[
                formatDateNumeric(new Date(startsAt)),
                formatTime(new Date(startsAt))
              ].join(' • ')}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {name}
          </Typography>
        </Box>
      </Paper>
    )
  }
