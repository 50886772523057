import {Paper} from '@mui/material'
import {Theme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {
  CheckTicketsEventFragment,
  CheckTourTimeSlotPropertiesFragment
} from '../../../../__generated__/schema'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {routeTo} from '../../../../utils/routes'
import {parseDateObjectFromDate} from '../cashDesk/utils'
import {GroupedEntitiesType} from '../types'
import {ResponsiveEntityItem} from './ResponsiveEntityItem'
import {CheckTicketsPageType} from './types'
import {isCheckTicketsEventFragment} from './utils'

const useDesktopStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 64,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'flex-start'
  },
  dateBox: {
    display: 'flex',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    alignItems: 'center'
  },
  stickyDateBox: {
    position: 'sticky',
    top: 0,
    flexShrink: 0
  },
  monthDay: {
    width: theme.spacing(5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0
  },
  dateNamesBox: {
    display: 'flex',
    height: theme.spacing(6),
    width: theme.spacing(5),
    paddingLeft: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    letterSpacing: 1.5,
    textTransform: 'uppercase'
  },
  eventsBox: {
    minWidth: 0,
    '&>div': {
      borderBottom: `solid ${theme.palette.divider} 1px`
    },
    '&>div:last-child': {
      borderBottom: 0
    }
  }
}))

const useMobileStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  stickyDateBox: {
    background: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    flexShrink: 0,
    borderBottom: `solid ${theme.palette.divider} 1px`,
    padding: theme.spacing(1.5, 2)
  }
}))

interface IResponsiveGroupedEntitiesItemProps {
  item: GroupedEntitiesType<
    CheckTicketsEventFragment | CheckTourTimeSlotPropertiesFragment
  >
  isPhablet: boolean
}

export const ResponsiveGroupedEntitiesItem: React.FC<IResponsiveGroupedEntitiesItemProps> =
  ({item, isPhablet}: IResponsiveGroupedEntitiesItemProps) => {
    const desktopClasses = useDesktopStyles()
    const mobileClasses = useMobileStyles()
    const {
      formatDateNumericShort,
      formatLongWeekDay,
      formatWeekDay,
      formatMonth,
      formatMonthDay
    } = useDateTimeFormatters()
    const dayDate = parseDateObjectFromDate(item.date)
    const history = useHistory()
    const getEntityClickHandler = useCallback(
      (type: CheckTicketsPageType, entityId: number) => () => {
        history.push(routeTo.admin.checkTickets.checkTicketCode(type, entityId))
      },
      [history]
    )
    const handleInfoIconClick = useCallback(
      (eventId: number) => (e: React.MouseEvent) => {
        e.stopPropagation()
        history.replace(
          routeTo.admin.checkTickets.eventDetailFromList(
            CheckTicketsPageType.Events,
            eventId
          )
        )
      },
      [history]
    )
    return isPhablet ? (
      <Paper className={mobileClasses.root} variant="outlined">
        <div className={mobileClasses.stickyDateBox}>
          <Typography variant="overline" color="textSecondary">
            {`${formatDateNumericShort(dayDate)} • ${formatLongWeekDay(
              dayDate
            )}`}
          </Typography>
        </div>
        {item.entities.map((entity) => (
          <ResponsiveEntityItem
            key={entity.id}
            entity={entity}
            isPhablet={isPhablet}
            onEntityClick={getEntityClickHandler(
              isCheckTicketsEventFragment(entity)
                ? CheckTicketsPageType.Events
                : CheckTicketsPageType.TourTimeSlots,
              entity.id
            )}
          />
        ))}
      </Paper>
    ) : (
      <Paper className={desktopClasses.root} variant="outlined">
        <div className={desktopClasses.stickyDateBox}>
          <div className={desktopClasses.dateBox}>
            <Typography variant="h6" className={desktopClasses.monthDay}>
              {formatMonthDay(dayDate)}
            </Typography>
            <div className={desktopClasses.dateNamesBox}>
              <Typography variant="caption">{formatMonth(dayDate)}</Typography>
              <Typography variant="caption" color="textSecondary">
                {formatWeekDay(dayDate)}
              </Typography>
            </div>
          </div>
        </div>
        <div className={desktopClasses.eventsBox}>
          {item.entities.map((entity) => (
            <ResponsiveEntityItem
              key={entity.id}
              entity={entity}
              isPhablet={isPhablet}
              onEntityClick={getEntityClickHandler(
                isCheckTicketsEventFragment(entity)
                  ? CheckTicketsPageType.Events
                  : CheckTicketsPageType.TourTimeSlots,
                entity.id
              )}
              onInfoIconClick={
                isCheckTicketsEventFragment(entity)
                  ? handleInfoIconClick(entity.id)
                  : undefined
              }
            />
          ))}
        </div>
      </Paper>
    )
  }
