import {
  CanvasObjectType,
  FeSeatState,
  FeZoneState,
  ILayoutPreviewProps,
  LayoutPreview
} from '@attendio/shared-components'
import {Box, SxProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useMemo} from 'react'
import {Helmet} from 'react-helmet-async'
import {
  ApiSeatState,
  CashDeskEventDetailPropertiesFragment,
  EventTicketType,
  Scalars
} from '../../../__generated__/schema'
import {ModifierKey} from '../../../editor/redux/keyboardKeys/reducer'
import {useElementDimensions} from '../../../hooks/dimensions'
import {useModifierKeys} from '../../../hooks/modifierKey'
import {useGetZoneLabelModifier} from '../../../hooks/zoneLabel'
import {Theme} from '../../../theme'
import {ZonePlanView} from '../../../types'
import {VerticalZoomPanel} from './VerticalZoomPanel'

export const useFeSeatStatesByUuid = ({
  seats,
  mapApiSeatStateToFeSeatState
}: {
  seats: {state: ApiSeatState}[]
  mapApiSeatStateToFeSeatState: (apiSeatState?: ApiSeatState) => FeSeatState
}): {[uuid: string]: FeSeatState} =>
  useMemo<{[uuid: string]: FeSeatState}>(() => {
    const acc: {[uuid: string]: FeSeatState} = {}
    const entries = Object.entries<{state: ApiSeatState}>(seats)
    for (const [uuid, {state}] of entries) {
      acc[uuid] = mapApiSeatStateToFeSeatState(state)
    }
    return acc
  }, [mapApiSeatStateToFeSeatState, seats])

export const useFeZoneStatesByUuid = ({
  zones,
  mapZoneApiSeatStatesToFeZoneState
}: {
  zones: {
    states: {
      [keys in ApiSeatState]: number
    }
  }[]
  mapZoneApiSeatStatesToFeZoneState: (
    zoneStatesWithCounts?: {
      [keys in ApiSeatState]: number
    }
  ) => FeZoneState
}): {[uuid: string]: FeZoneState} =>
  useMemo<{[uuid: string]: FeZoneState}>(
    () =>
      Object.entries<{
        states: {
          [keys in ApiSeatState]: number
        }
      }>(zones).reduce<{[uuid: string]: FeZoneState}>(
        (acc, [uuid, {states}]) => ({
          ...acc,
          [uuid]: mapZoneApiSeatStatesToFeZoneState(states)
        }),
        {}
      ),
    [mapZoneApiSeatStatesToFeZoneState, zones]
  )

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative'
  },
  verticalZoomPanel: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5)
  }
}))

interface IAuditoriumPreviewProps
  extends Omit<ILayoutPreviewProps, 'dimensions'> {
  className?: string
  sx?: SxProps<Theme>
  eventSeats: JSON
  onSeatsMultiSelect?: (uuids: string[]) => void
  getZoneViewChangeHandler?: (zonePlan: ZonePlanView) => () => void
  isZoomPanelHidden?: boolean
}

export const useGetTicketTypeForUuid = (
  layout: CashDeskEventDetailPropertiesFragment['auditoriumLayout']['layout'],
  ticketTypesByTicketTypeId: {[id: string]: EventTicketType}
) =>
  useCallback(
    (uuid: string) =>
      ticketTypesByTicketTypeId[String((layout as any)[uuid].ticketTypeId)] ??
      {},
    [layout, ticketTypesByTicketTypeId]
  )

export const useTicketTypesByTicketTypeId = (event: {
  activePricing: {
    ticketTypes: Pick<
      EventTicketType,
      'id' | 'name' | 'description' | 'price' | 'color' | 'ticketTypeId'
    >[]
  }
}) =>
  useMemo(
    () =>
      event.activePricing.ticketTypes.reduce(
        (acc: {[id: string]: EventTicketType}, ticketType) => ({
          ...acc,
          [ticketType.ticketTypeId]: ticketType
        }),
        {}
      ),
    [event.activePricing.ticketTypes]
  )
export const useLayout = (
  event: {
    auditoriumLayout: {
      layout: Scalars['JSONObject']
    }
    auditoriumLayoutPricing: {
      pricing: Scalars['JSONObject']
    }
  },
  ticketTypesByTicketTypeId: {[id: string]: EventTicketType}
) =>
  useMemo(() => {
    const entries = Object.entries(event.auditoriumLayout.layout)
    const acc: {[key: string]: any} = {}
    for (const [key, o] of entries) {
      if (![CanvasObjectType.Zone, CanvasObjectType.Seat].includes(o.type)) {
        acc[key] = o
      } else {
        // @ts-ignore
        const ticketTypeId = event.auditoriumLayoutPricing.pricing[key].id
        acc[key] = {
          ...o,
          color: ticketTypesByTicketTypeId[String(ticketTypeId)].color,
          ticketTypeId
        }
      }
    }
    return acc
  }, [
    event.auditoriumLayout.layout,
    event.auditoriumLayoutPricing.pricing,
    ticketTypesByTicketTypeId
  ])

export const AuditoriumLayoutPreview: React.FC<IAuditoriumPreviewProps> = ({
  className,
  layout,
  eventSeats,
  feSeatStatesByUuid,
  feZoneStatesByUuid,
  getSeatClickHandler,
  getZoneClickHandler,
  getLayoutObjectMouseEnterHandler,
  getLayoutObjectMouseLeaveHandler,
  TooltipProps,
  onSeatsMultiSelect,
  getZoneViewChangeHandler,
  sx,
  isZoomPanelHidden
}: IAuditoriumPreviewProps) => {
  const classes = useStyles()
  const [ref, dimensions] = useElementDimensions()
  const isControlPressed = useModifierKeys([ModifierKey.Control])
  const getZoneLabelModifier = useGetZoneLabelModifier(eventSeats)
  const renderZoomPanel = useCallback(
    ({zoomIn, zoomOut, fitToScreen, isZoomOutDisabled, isZoomInDisabled}) => (
      <VerticalZoomPanel
        className={classes.verticalZoomPanel}
        onFitToScreenClick={fitToScreen}
        onZoomInClick={zoomIn}
        onZoomOutClick={zoomOut}
        isZoomOutDisabled={isZoomOutDisabled}
        isZoomInDisabled={isZoomInDisabled}
        getZoneViewChangeHandler={getZoneViewChangeHandler}
      />
    ),
    [classes.verticalZoomPanel, getZoneViewChangeHandler]
  )
  return (
    <Box ref={ref} className={cn(classes.root, className)} sx={sx}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
        />
      </Helmet>
      <LayoutPreview
        isControlPressed={isControlPressed}
        dimensions={{
          width: Math.floor(dimensions.width),
          height: Math.floor(dimensions.height)
        }}
        layout={layout}
        feSeatStatesByUuid={feSeatStatesByUuid}
        feZoneStatesByUuid={feZoneStatesByUuid}
        getSeatClickHandler={getSeatClickHandler}
        getZoneClickHandler={getZoneClickHandler}
        getLayoutObjectMouseEnterHandler={getLayoutObjectMouseEnterHandler}
        getLayoutObjectMouseLeaveHandler={getLayoutObjectMouseLeaveHandler}
        TooltipProps={TooltipProps}
        getZoneLabelModifier={getZoneLabelModifier}
        onSeatsMultiSelect={onSeatsMultiSelect}
        renderZoomPanel={isZoomPanelHidden ? undefined : renderZoomPanel}
      />
    </Box>
  )
}
