import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  DiscountsStatisticsQuery,
  DiscountsStatisticsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const DISCOUNTS_STATISTICS = gql`
  ${PAGINATION_FRAGMENT}
  query DiscountsStatistics(
    $paginationInput: PaginationInput!
    $filter: DiscountsStatisticsFilterInput
  ) {
    discountsStatistics(paginationInput: $paginationInput, filter: $filter) {
      items {
        id
        name
        value
        type
        application
        discountApplicationCountTotal
        discountedPriceTotal
      }
      totals {
        discountApplicationCountTotal
        discountedPriceTotal
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useDiscountsStatistics = (
  variables: DiscountsStatisticsQueryVariables
) =>
  useQuery<DiscountsStatisticsQuery, DiscountsStatisticsQueryVariables>(
    DISCOUNTS_STATISTICS,
    {variables, fetchPolicy: 'network-only'}
  )
