import {useTranslation} from 'react-i18next'

export const useClientFormAnchors = (isWithLeadSettings?: boolean) => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General information')
    },
    legal: {
      id: 'legal',
      label: t('Legal information')
    },
    contact: {
      id: 'contact',
      label: t('Contact information')
    },
    ...(isWithLeadSettings
      ? {
          leadSettings: {
            id: 'leadSettings',
            label: t('Lead settings')
          }
        }
      : {}),
    templates: {
      id: 'templates',
      label: t('Templates')
    }
  }
}
