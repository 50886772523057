import {useTranslation} from 'react-i18next'
import {ProductGroupState} from '../__generated__/schema'

export const useTranslateProductGroupState = () => {
  const {t} = useTranslation()
  const translated: Record<ProductGroupState, string> = {
    [ProductGroupState.Active]: t<string>('ProductGroupState->Active'),
    [ProductGroupState.Inactive]: t<string>('ProductGroupState->Inactive')
  }
  return (state: ProductGroupState) => translated[state]
}

export const useTranslateProductGroupStateDescription = () => {
  const {t} = useTranslation()
  const translated: Record<ProductGroupState, string> = {
    [ProductGroupState.Active]: t<string>(
      'ProductGroupStateDescription->Active'
    ),
    [ProductGroupState.Inactive]: t<string>(
      'ProductGroupStateDescription->Inactive'
    )
  }
  return (state: ProductGroupState) => translated[state]
}
