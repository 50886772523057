import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useGetClientLocales} from '../../../../hooks/getLocales'

import {Theme} from '../../../../theme'
import {ShowTranslationType} from '../../../../utils/translations'

const useTranslationsStyles = makeStyles<Theme>((theme) => ({
  localeChip: {
    backgroundColor: '#E0E0E0',
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase'
  },
  accordionSummaryContent: {
    display: 'grid',
    gap: theme.spacing(2),
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr'
  },
  accordionDetailsRoot: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr'
  },
  description: {
    color: theme.palette.text.secondary
  }
}))

interface ITranslationsProps {
  translations: ShowTranslationType[]
}

export const ShowDetailTranslations: React.FC<ITranslationsProps> = ({
  translations
}: ITranslationsProps) => {
  const classes = useTranslationsStyles()
  const {t} = useTranslation()
  const clientLocales = useGetClientLocales()

  const orderedTranslations: ShowTranslationType[] = clientLocales.reduce<
    ShowTranslationType[]
  >((acc, localeCode) => {
    const translation = translations.find((t) => t.localeCode === localeCode)
    return translation ? [...acc, translation] : acc
  }, [])

  const [expandedLocale, setExpandedLocale] = useState<string | null>(
    clientLocales[0]
  )

  if (translations.length === 0) {
    return <Typography>{t('Translations not available.')}</Typography>
  }
  return (
    <>
      {orderedTranslations.map((translation) => (
        <Accordion
          variant="outlined"
          key={translation.localeCode}
          expanded={translation.localeCode === expandedLocale}
          onChange={(event, isExpanded) => {
            setExpandedLocale(isExpanded ? translation.localeCode : null)
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              content: classes.accordionSummaryContent
            }}
          >
            <Typography variant="body2" className={classes.localeChip}>
              {translation.localeCode}
            </Typography>
            <Typography variant="subtitle2">{translation.title}</Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.accordionDetailsRoot
            }}
          >
            <Typography variant="body2">{translation.tagline}</Typography>
            {/* Note: XSS check is handled on backend */}
            {translation.description && (
              <div
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: translation.description!
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
