import {useLazyQuery} from '@apollo/react-hooks'
import {Tab, Tabs} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClaimPropertiesFragment,
  ECommercePaymentMethodsQuery,
  ECommercePaymentMethodsQueryVariables,
  ErrorMessages,
  LightweightVoucherCampaignsQuery,
  LightweightVoucherCampaignsQueryVariables,
  PaymentMethodPropertiesFragment,
  PaymentMethodState,
  PermissionCode,
  RetailPaymentMethodsQuery,
  RetailPaymentMethodsQueryVariables,
  SellingChannel,
  VoucherCampaignState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useHandleInvalidDateTimes} from '../../../../hooks/pickerErrors'
import {PRIMARY_50_COLOR} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'
import {VoucherActivationOptionsField} from '../components/types'
import {RETAIL_PAYMENT_METHODS} from '../graphql'
import {ECOMMERCE_PAYMENT_METHODS} from '../paymentMethods/graphql'
import {calculateCeil} from '../utils'
import {AvailableRefundMethods} from './AvailableRefundMethods'
import {
  LIGHTWEIGHT_VOUCHER_CAMPAIGNS,
  useRefundClaimByInHouseVoucher,
  useRefundClaimOnECommerceChannel,
  useRefundClaimOnRetailChannel
} from './graphql'
import {RefundClaimFooter} from './RefundClaimFooter'
import {
  ClaimTab,
  ECOMMERCE_REFUND_FORM_ID,
  EcommerceRefundMethodField,
  IEcommerceRefundMethodForm,
  IRetailRefundMethodForm,
  IVoucherRefundMethodForm,
  RETAIL_REFUND_FORM_ID,
  RetailRefundMethodField,
  VOUCHER_REFUND_FORM_ID,
  VoucherRefundMethodField
} from './types'
import {
  getAmountAndCount,
  getDefaultTab,
  getFormId,
  getPaymentMethodValue,
  getSurplus
} from './utils'
import {VoucherTabContent} from './VoucherTabContent'

const useStyles = makeStyles<Theme>((theme) => ({
  tabs: {
    height: 48,
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  content: {
    overflow: 'hidden'
  },
  scrollableBox: {
    height: '100%',
    overflow: 'auto'
  },
  contentColumn: {
    maxWidth: 'min(100%, 480px)',
    minWidth: 360,
    margin: 'auto',
    padding: theme.spacing(2)
  },
  frames: {
    paddingTop: theme.spacing(2)
  },
  radioGroup: {
    gap: theme.spacing(1)
  },
  formControlLabelRoot: {
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    }
  },
  formControlLabelRootSelected: {
    display: 'flex',
    borderColor: theme.palette.primary.main,
    backgroundColor: PRIMARY_50_COLOR
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0.5)
  },
  label: {
    width: '100%'
  }
}))

interface IRefundClaimCoreProps {
  className?: string
  claim: ClaimPropertiesFragment
}

export const RefundClaimCore: React.FC<IRefundClaimCoreProps> = ({
  className,
  claim
}: IRefundClaimCoreProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const refundClaimOnRetailChannel = useRefundClaimOnRetailChannel()
  const refundClaimOnECommerceChannel = useRefundClaimOnECommerceChannel()
  const refundClaimByInHouseVoucher = useRefundClaimByInHouseVoucher()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const [retailPaymentMethods, setRetailPaymentMethods] =
    useState<PaymentMethodPropertiesFragment[] | null>(null)
  const [eCommercePaymentMethods, setECommercePaymentMethods] =
    useState<PaymentMethodPropertiesFragment[] | null>(null)
  const [voucherCampaigns, setVoucherCampaigns] =
    useState<
      LightweightVoucherCampaignsQuery['voucherCampaigns']['items'] | null
    >(null)
  const history = useHistory()
  const classes = useStyles()
  const [
    getECommercePaymentMethods,
    {loading: isEcommercePaymentMethodsLoading}
  ] = useLazyQuery<
    ECommercePaymentMethodsQuery,
    ECommercePaymentMethodsQueryVariables
  >(ECOMMERCE_PAYMENT_METHODS, {
    variables: {state: PaymentMethodState.Active},
    onCompleted: ({eCommercePaymentMethods}) =>
      setECommercePaymentMethods(eCommercePaymentMethods),
    onError: (error) =>
      defaultErrorHandler(error, t('Error while loading payment method')),
    fetchPolicy: 'network-only'
  })
  const [getRetailPaymentMethods, {loading: isRetailPaymentMethodsLoading}] =
    useLazyQuery<RetailPaymentMethodsQuery, RetailPaymentMethodsQueryVariables>(
      RETAIL_PAYMENT_METHODS,
      {
        variables: {state: PaymentMethodState.Active},
        onCompleted: ({retailPaymentMethods}) =>
          setRetailPaymentMethods(retailPaymentMethods),
        onError: (error) =>
          defaultErrorHandler(error, t('Error while loading payment method')),
        fetchPolicy: 'network-only'
      }
    )
  const [getVoucherCampaigns, {loading: isVoucherCampaignsLoading}] =
    useLazyQuery<
      LightweightVoucherCampaignsQuery,
      LightweightVoucherCampaignsQueryVariables
    >(LIGHTWEIGHT_VOUCHER_CAMPAIGNS, {
      variables: {
        filter: {
          state: [VoucherCampaignState.Active],
          canCreateNewVouchers: true,
          hasInitialBalance: false,
          voucherCampaignRetailPaymentMethodFilter: {
            isAvailableForRefunds: true,
            hasDenomination: false
          }
        },
        paginationInput: {limit: 50, offset: 0}
      },
      onCompleted: ({voucherCampaigns}) =>
        setVoucherCampaigns(voucherCampaigns.items),
      onError: (error) =>
        defaultErrorHandler(error, t('Error while loading voucher campaigns')),
      fetchPolicy: 'network-only'
    })
  const {
    control: controlRetail,
    watch: watchRetail,
    register: registerRetail,
    setValue: setValueRetail,
    handleSubmit: handleSubmitRetail
  } = useForm<IRetailRefundMethodForm>()
  const {
    control: controlEcommerce,
    watch: watchEcommerce,
    register: registerEcommerce,
    setValue: setValueEcommerce,
    handleSubmit: handleSubmitEcommerce
  } = useForm<IEcommerceRefundMethodForm>()
  const refundFormProps = useForm<IVoucherRefundMethodForm>({
    defaultValues: {[VoucherRefundMethodField.Balance]: claim.price}
  })
  const {
    setError: setRefundFormError,
    watch: watchRefundForm,
    handleSubmit: handleRefundFormSubmit
  } = refundFormProps
  const hasRefundOnRetailPermission = P([
    PermissionCode.RefundClaimOnRetailChannel
  ])
  const hasRefundOnECommercePermission =
    P([PermissionCode.RefundClaimOnECommerceChannel]) &&
    claim.sale.sellingChannel === SellingChannel.ECommerce
  const hasRefundByVoucherPermission =
    hasRefundOnRetailPermission &&
    P([
      PermissionCode.RefundClaimByInHouseVoucher,
      PermissionCode.ReadVoucherCampaigns
    ])
  const [tab, setTab] = useState<ClaimTab>(
    getDefaultTab({
      hasRefundOnRetailPermission,
      hasRefundOnECommercePermission,
      hasRefundByVoucherPermission
    })
  )
  useEffect(() => {
    if (tab === ClaimTab.Retail) {
      getRetailPaymentMethods()
    } else if (tab === ClaimTab.Ecommerce) {
      getECommercePaymentMethods()
    } else {
      getVoucherCampaigns()
    }
  }, [
    getECommercePaymentMethods,
    getRetailPaymentMethods,
    getVoucherCampaigns,
    tab
  ])
  useEffect(
    () =>
      isRetailPaymentMethodsLoading ||
      isEcommercePaymentMethodsLoading ||
      isVoucherCampaignsLoading
        ? setShowBackdrop(true)
        : setShowBackdrop(false),
    [
      isEcommercePaymentMethodsLoading,
      isRetailPaymentMethodsLoading,
      isVoucherCampaignsLoading,
      setShowBackdrop
    ]
  )
  const handleTabChange = useCallback((e, newTab) => {
    setTab(newTab)
  }, [])

  const retailPMTotalRefundValue = watchRetail(RetailRefundMethodField.Value)
  const selectedRetailRefundMethod = watchRetail(RetailRefundMethodField.Type)
  const selectedVoucherActivationOption = watchRefundForm(
    VoucherActivationOptionsField.Activation
  )
  const selectedVoucherCampaign = watchRefundForm(
    VoucherRefundMethodField.CampaignId
  )
  const voucherTotalRefundValue = watchRefundForm(
    VoucherRefundMethodField.Balance
  )
  useEffect(() => {
    if (selectedRetailRefundMethod && retailPaymentMethods) {
      const paymentMethod = retailPaymentMethods.find(
        (pm) => String(pm.id) === selectedRetailRefundMethod
      )
      if (paymentMethod) {
        if (paymentMethod.hasDenomination) {
          setValueRetail(
            RetailRefundMethodField.Value,
            calculateCeil(claim.price, paymentMethod.value) *
              paymentMethod.value
          )
        } else {
          setValueRetail(
            RetailRefundMethodField.Value,
            claim.price * paymentMethod.value
          )
        }
      }
    }
  }, [
    claim.price,
    retailPaymentMethods,
    selectedRetailRefundMethod,
    setValueRetail
  ])

  const ecommercePMTotalRefundValue = watchEcommerce(
    EcommerceRefundMethodField.Value
  )
  const selectedEcommerceRefundMethod = watchEcommerce(
    EcommerceRefundMethodField.Type
  )
  useEffect(() => {
    if (selectedEcommerceRefundMethod && eCommercePaymentMethods) {
      const paymentMethod = eCommercePaymentMethods.find(
        (pm) => String(pm.id) === selectedEcommerceRefundMethod
      )
      if (paymentMethod) {
        if (paymentMethod.hasDenomination) {
          setValueEcommerce(
            EcommerceRefundMethodField.Value,
            calculateCeil(claim.price, paymentMethod.value) *
              paymentMethod.value
          )
        } else {
          setValueEcommerce(
            EcommerceRefundMethodField.Value,
            claim.price * paymentMethod.value
          )
        }
      }
    }
  }, [
    claim.price,
    eCommercePaymentMethods,
    retailPaymentMethods,
    selectedEcommerceRefundMethod,
    selectedRetailRefundMethod,
    setValueEcommerce,
    setValueRetail
  ])

  const onRetailRefundSubmit = useCallback(
    async (data: IRetailRefundMethodForm) => {
      try {
        setShowBackdrop(true)
        await refundClaimOnRetailChannel({
          id: claim.id,
          refundIntentInputs: [
            {
              paymentMethodId: parseInt(data[RetailRefundMethodField.Type], 10),
              amount: data[RetailRefundMethodField.Value],
              ...getAmountAndCount(
                data[RetailRefundMethodField.Value],
                retailPaymentMethods?.find(
                  (pm) =>
                    pm.id === parseInt(data[RetailRefundMethodField.Type], 10)
                )
              )
            }
          ]
        })
        history.replace(routeTo.admin.claims.detail(claim.id))
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while refunding claim on retail channel')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      claim.id,
      defaultErrorHandler,
      history,
      refundClaimOnRetailChannel,
      retailPaymentMethods,
      setShowBackdrop,
      t
    ]
  )

  const onEcommerceRefundSubmit = useCallback(
    async (data: IEcommerceRefundMethodForm) => {
      try {
        setShowBackdrop(true)
        await refundClaimOnECommerceChannel({
          id: claim.id,
          refundIntentInputs: [
            {
              paymentMethodId: parseInt(
                data[EcommerceRefundMethodField.Type],
                10
              ),
              amount: data[EcommerceRefundMethodField.Value],
              ...getAmountAndCount(
                data[EcommerceRefundMethodField.Value],
                eCommercePaymentMethods?.find(
                  (pm) =>
                    pm.id ===
                    parseInt(data[EcommerceRefundMethodField.Type], 10)
                )
              )
            }
          ]
        })
        history.replace(routeTo.admin.claims.detail(claim.id))
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while refunding claim on ecommerce channel')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      claim.id,
      defaultErrorHandler,
      eCommercePaymentMethods,
      history,
      refundClaimOnECommerceChannel,
      setShowBackdrop,
      t
    ]
  )
  const onVoucherRefundSubmit = useCallback(
    async (data: IVoucherRefundMethodForm) => {
      const voucherCampaign = voucherCampaigns?.find(
        (vc) => vc.id === Number(selectedVoucherCampaign)
      )
      if (voucherCampaign && voucherCampaign.retailPaymentMethodId) {
        try {
          setShowBackdrop(true)
          const {data: voucherData} = await refundClaimByInHouseVoucher({
            id: claim.id,
            input: {
              campaignId: voucherCampaign.id,
              activationDate:
                data[
                  VoucherActivationOptionsField.ActivationDatetime
                ]?.toISOString(),
              expirationDate:
                data[
                  VoucherActivationOptionsField.ExpirationDatetime
                ]?.toISOString()
            }
          })
          history.replace({
            pathname: routeTo.admin.claims.detail(claim.id),
            search: voucherData
              ? `?voucherCode=${voucherData.refundClaimByInHouseVoucher.voucher.code}`
              : ''
          })
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.ActivationDateIsInThePast
            )
          ) {
            setRefundFormError(
              VoucherActivationOptionsField.ActivationDatetime,
              t('Can’t be in past.')
            )
          } else if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.ExpirationDateIsInThePast
            )
          ) {
            setRefundFormError(
              VoucherActivationOptionsField.ExpirationDatetime,
              t('Can’t be in past.')
            )
          } else if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.ActivationDateIsAfterExpirationDate
            )
          ) {
            setRefundFormError(
              VoucherActivationOptionsField.ExpirationDatetime,
              t('Can’t be before activation date.')
            )
          } else {
            defaultErrorHandler(
              error,
              t('Error while refunding claim by in house voucher')
            )
          }
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      claim.id,
      defaultErrorHandler,
      history,
      refundClaimByInHouseVoucher,
      selectedVoucherCampaign,
      setRefundFormError,
      setShowBackdrop,
      t,
      voucherCampaigns
    ]
  )
  const handleInvalidDateTimes =
    useHandleInvalidDateTimes<IVoucherRefundMethodForm>(setRefundFormError)
  return (
    <div className={className}>
      <div className={classes.tabs}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {hasRefundOnRetailPermission && (
            <Tab label={t('Retail')} value={ClaimTab.Retail} />
          )}
          {hasRefundOnECommercePermission &&
            claim.sale.sellingChannel === SellingChannel.ECommerce && (
              <Tab label={t('Ecommerce')} value={ClaimTab.Ecommerce} />
            )}
          {hasRefundByVoucherPermission && (
            <Tab label={t('Voucher')} value={ClaimTab.Voucher} />
          )}
        </Tabs>
      </div>
      <div className={classes.content}>
        <div className={classes.scrollableBox}>
          {tab === ClaimTab.Retail && retailPaymentMethods && (
            <div className={classes.contentColumn}>
              <div className={classes.frames}>
                <form
                  onSubmit={handleSubmitRetail(onRetailRefundSubmit)}
                  id={RETAIL_REFUND_FORM_ID}
                />
                <input
                  type="hidden"
                  name={RetailRefundMethodField.Value}
                  ref={registerRetail}
                />
                <AvailableRefundMethods
                  paymentMethods={retailPaymentMethods}
                  claimPrice={claim.price}
                  pmTotalRefundValue={retailPMTotalRefundValue}
                  name={RetailRefundMethodField.Type}
                  watch={watchRetail}
                  control={controlRetail}
                />
              </div>
            </div>
          )}
          {tab === ClaimTab.Ecommerce && eCommercePaymentMethods && (
            <div className={classes.contentColumn}>
              <div className={classes.frames}>
                <form
                  onSubmit={handleSubmitEcommerce(onEcommerceRefundSubmit)}
                  id={ECOMMERCE_REFUND_FORM_ID}
                />
                <input
                  type="hidden"
                  name={EcommerceRefundMethodField.Value}
                  ref={registerEcommerce}
                />
                <AvailableRefundMethods
                  paymentMethods={eCommercePaymentMethods}
                  claimPrice={claim.price}
                  name={EcommerceRefundMethodField.Type}
                  pmTotalRefundValue={ecommercePMTotalRefundValue}
                  watch={watchEcommerce}
                  control={controlEcommerce}
                />
              </div>
            </div>
          )}
          {tab === ClaimTab.Voucher && voucherCampaigns && (
            <div className={classes.contentColumn}>
              <div className={classes.frames}>
                <form
                  onSubmit={handleRefundFormSubmit(
                    handleInvalidDateTimes(onVoucherRefundSubmit)
                  )}
                  id={VOUCHER_REFUND_FORM_ID}
                >
                  <FormContext {...refundFormProps}>
                    <VoucherTabContent
                      voucherCampaigns={voucherCampaigns}
                      name={VoucherRefundMethodField.CampaignId}
                    />
                  </FormContext>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <RefundClaimFooter
        surplus={getSurplus(
          claim.price,
          getPaymentMethodValue({
            tab,
            retailTotal: retailPMTotalRefundValue || 0,
            ecommerceTotal: ecommercePMTotalRefundValue || 0,
            voucherTotal: voucherTotalRefundValue || 0,
            isSelectedVoucherActivationAndCampaign: !!(
              selectedVoucherActivationOption && selectedVoucherCampaign
            )
          })
        )}
        formId={getFormId(tab)}
      />
    </div>
  )
}
