import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  NarrowTicketItemPropertiesFragment,
  NarrowTourItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../../../hooks/formatDiscountValue'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple
} from '../../../../common'
import {isTicketItem, isTourItem} from '../../types'

interface IPriceTicketInfoSectionProps {
  item: NarrowTicketItemPropertiesFragment | NarrowTourItemPropertiesFragment
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const PriceTicketInfoSection: React.FC<IPriceTicketInfoSectionProps> = ({
  item,
  drawerSectionProps
}: IPriceTicketInfoSectionProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  const formatDiscountValue = useFormatDiscountValue()
  return (
    <DrawerSection {...drawerSectionProps}>
      <SectionGrid>
        <SectionGridCouple label={t('Ticket type')}>
          {isTicketItem(item)
            ? `${translatePrice(item.eventPricingToTicketType.price)} • ${
                item.eventPricingToTicketType.name
              }`
            : isTourItem(item) &&
              `${translatePrice(item.priceBeforeDiscount)} • ${
                item.admissionType.name
              }`}
        </SectionGridCouple>
        {item.appliedDiscounts.length > 0 && (
          <SectionGridCouple label={t('Discount')}>
            {`${formatDiscountValue({
              value: item.appliedDiscounts[0].discount.value,
              type: item.appliedDiscounts[0].discount.type
            })} • ${item.appliedDiscounts[0].discount.name}`}
          </SectionGridCouple>
        )}
        <SectionGridCouple label={t('Final price')}>
          {translatePrice(item.price)}
        </SectionGridCouple>
      </SectionGrid>
    </DrawerSection>
  )
}
