import React from 'react'
import {useTranslation} from 'react-i18next'
import {WarehousesFilterInput} from '../../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'

const mapNameToFilter = (
  filter: WarehousesFilterInput,
  name?: string
): WarehousesFilterInput => ({
  ...filter,
  name: name || null
})

const getNameFromSearchObject = (filter: WarehousesFilterInput) =>
  filter.name || undefined

interface IWarehousesSearchProps {
  onFilterChange: (filter: WarehousesFilterInput) => void
}

export const WarehousesSearch: React.FC<IWarehousesSearchProps> = ({
  onFilterChange
}: IWarehousesSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<WarehousesFilterInput>(
      getNameFromSearchObject
    )
  return (
    <Search<WarehousesFilterInput>
      storageKey="WAREHOUSES"
      placeholder={t('Search for warehouse')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapNameToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
    />
  )
}
