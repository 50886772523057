import Konva from 'konva'
import _ from 'lodash'
import React from 'react'
import {Layer} from 'react-konva'

import {useSelector} from '../redux'
import {unselectedObjectsIdsSelector} from '../redux/objects/selectors'
import {CanvasObject} from './CanvasObject'

interface IUnselectedObjectsProps {
  outerRef: React.RefObject<Konva.Layer>
}

const UnselectedObjectsFn: React.FC<IUnselectedObjectsProps> = ({
  outerRef
}: IUnselectedObjectsProps) => {
  const unselectedObjectsIds = useSelector(unselectedObjectsIdsSelector)

  return (
    <Layer ref={outerRef}>
      {unselectedObjectsIds.map((id) => (
        <CanvasObject key={id} id={id} />
      ))}
    </Layer>
  )
}

export const UnselectedObjects = React.memo(UnselectedObjectsFn, _.isEqual)
