import AccessibleIcon from '@mui/icons-material/Accessible'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import AppleIcon from '@mui/icons-material/Apple'
import ArticleIcon from '@mui/icons-material/Article'
import BlindIcon from '@mui/icons-material/Blind'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import CoffeeIcon from '@mui/icons-material/Coffee'
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CookieIcon from '@mui/icons-material/Cookie'
import CopyrightIcon from '@mui/icons-material/Copyright'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined'
import EmailIcon from '@mui/icons-material/Email'
import EventIcon from '@mui/icons-material/Event'
import FacebookIcon from '@mui/icons-material/Facebook'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import GoogleIcon from '@mui/icons-material/Google'
import HelpIcon from '@mui/icons-material/Help'
import InfoIcon from '@mui/icons-material/Info'
import InstagramIcon from '@mui/icons-material/Instagram'
import LanguageIcon from '@mui/icons-material/Language'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import MapIcon from '@mui/icons-material/Map'
import MovieFilterOutlinedIcon from '@mui/icons-material/MovieFilterOutlined'
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined'
import PaymentIcon from '@mui/icons-material/Payment'
import PetsIcon from '@mui/icons-material/Pets'
import PhoneIcon from '@mui/icons-material/Phone'
import PinterestIcon from '@mui/icons-material/Pinterest'
import PlaceIcon from '@mui/icons-material/Place'
import PublicIcon from '@mui/icons-material/Public'
import RedditIcon from '@mui/icons-material/Reddit'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import SecurityIcon from '@mui/icons-material/Security'
import SellIcon from '@mui/icons-material/Sell'
import ShareIcon from '@mui/icons-material/Share'
import SignLanguageIcon from '@mui/icons-material/SignLanguage'
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms'
import SportsBarIcon from '@mui/icons-material/SportsBar'
import StoreIcon from '@mui/icons-material/Store'
import SupportIcon from '@mui/icons-material/Support'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import TheaterComedyOutlinedIcon from '@mui/icons-material/TheaterComedyOutlined'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import YouTubeIcon from '@mui/icons-material/YouTube'
import {SvgIconProps} from '@mui/material/SvgIcon'
import React from 'react'
import {TypifiedIconModel} from '../../../../../__generated__/schema'

interface ITypifiedIconProps {
  model: TypifiedIconModel
  iconProps?: SvgIconProps
}

export const TypifiedIcon: React.FC<ITypifiedIconProps> = ({
  model,
  iconProps
}: ITypifiedIconProps) => {
  switch (model) {
    case TypifiedIconModel.Facebook:
      return <FacebookIcon {...iconProps} />
    case TypifiedIconModel.Instagram:
      return <InstagramIcon {...iconProps} />
    case TypifiedIconModel.WhatsApp:
      return <WhatsAppIcon {...iconProps} />
    case TypifiedIconModel.LinkedIn:
      return <LinkedInIcon {...iconProps} />
    case TypifiedIconModel.YouTube:
      return <YouTubeIcon {...iconProps} />
    case TypifiedIconModel.Pinterest:
      return <PinterestIcon {...iconProps} />
    case TypifiedIconModel.Reddit:
      return <RedditIcon {...iconProps} />
    case TypifiedIconModel.Google:
      return <GoogleIcon {...iconProps} />
    case TypifiedIconModel.Apple:
      return <AppleIcon {...iconProps} />
    case TypifiedIconModel.Share:
      return <ShareIcon {...iconProps} />
    case TypifiedIconModel.Map:
      return <MapIcon {...iconProps} />
    case TypifiedIconModel.MapPointer:
      return <PlaceIcon {...iconProps} />
    case TypifiedIconModel.Info:
      return <InfoIcon {...iconProps} />
    case TypifiedIconModel.Clock:
      return <AccessTimeIcon {...iconProps} />
    case TypifiedIconModel.PersonOnWheelchair:
      return <AccessibleIcon {...iconProps} />
    case TypifiedIconModel.ChildFriendly:
      return <ChildFriendlyIcon {...iconProps} />
    case TypifiedIconModel.Pets:
      return <PetsIcon {...iconProps} />
    case TypifiedIconModel.Language:
      return <LanguageIcon {...iconProps} />
    case TypifiedIconModel.World:
      return <PublicIcon {...iconProps} />
    case TypifiedIconModel.SignLanguage:
      return <SignLanguageIcon {...iconProps} />
    case TypifiedIconModel.DirectionsCar:
      return <DirectionsCarIcon {...iconProps} />
    case TypifiedIconModel.DirectionsBike:
      return <DirectionsBikeIcon {...iconProps} />
    case TypifiedIconModel.Ticket:
      return <ConfirmationNumberIcon {...iconProps} />
    case TypifiedIconModel.SmokingRooms:
      return <SmokingRoomsIcon {...iconProps} />
    case TypifiedIconModel.RssFeed:
      return <RssFeedIcon {...iconProps} />
    case TypifiedIconModel.Blind:
      return <BlindIcon {...iconProps} />
    case TypifiedIconModel.PriceTag:
      return <SellIcon {...iconProps} />
    case TypifiedIconModel.Copyright:
      return <CopyrightIcon {...iconProps} />
    case TypifiedIconModel.Event:
      return <EventIcon {...iconProps} />
    case TypifiedIconModel.At:
      return <AlternateEmailIcon {...iconProps} />
    case TypifiedIconModel.Email:
      return <EmailIcon {...iconProps} />
    case TypifiedIconModel.Phone:
      return <PhoneIcon {...iconProps} />
    case TypifiedIconModel.PersonWithHeadset:
      return <SupportAgentIcon {...iconProps} />
    case TypifiedIconModel.Lifeline:
      return <SupportIcon {...iconProps} />
    case TypifiedIconModel.Help:
      return <HelpIcon {...iconProps} />
    case TypifiedIconModel.Shield:
      return <SecurityIcon {...iconProps} />
    case TypifiedIconModel.OpenInNewTab:
      return <LaunchIcon {...iconProps} />
    case TypifiedIconModel.Article:
      return <ArticleIcon {...iconProps} />
    case TypifiedIconModel.FileDownload:
      return <FileDownloadIcon {...iconProps} />
    case TypifiedIconModel.Cookie:
      return <CookieIcon {...iconProps} />
    case TypifiedIconModel.Heart:
      return <FavoriteIcon {...iconProps} />
    case TypifiedIconModel.PaymentCard:
      return <PaymentIcon {...iconProps} />
    case TypifiedIconModel.CardGiftcard:
      return <CardGiftcardIcon {...iconProps} />
    case TypifiedIconModel.Wallet:
      return <AccountBalanceWalletIcon {...iconProps} />
    case TypifiedIconModel.GreekBuilding:
      return <AccountBalanceIcon {...iconProps} />
    case TypifiedIconModel.ShoppingBag:
      return <LocalMallIcon {...iconProps} />
    case TypifiedIconModel.Storefront:
      return <StoreIcon {...iconProps} />
    case TypifiedIconModel.LocalParking:
      return <LocalParkingIcon {...iconProps} />
    case TypifiedIconModel.SportsBar:
      return <SportsBarIcon {...iconProps} />
    case TypifiedIconModel.Coffee:
      return <CoffeeIcon {...iconProps} />
    case TypifiedIconModel.Calendar:
      return <CalendarMonthOutlinedIcon {...iconProps} />
    case TypifiedIconModel.TheaterComedy:
      return <TheaterComedyOutlinedIcon {...iconProps} />
    case TypifiedIconModel.MusicNote:
      return <MusicNoteOutlinedIcon {...iconProps} />
    case TypifiedIconModel.Movie:
      return <MovieFilterOutlinedIcon {...iconProps} />
    case TypifiedIconModel.FamilyRestroom:
      return <FamilyRestroomOutlinedIcon {...iconProps} />
    case TypifiedIconModel.ColorLens:
      return <ColorLensOutlinedIcon {...iconProps} />
    case TypifiedIconModel.Diversity:
      return <Diversity1OutlinedIcon {...iconProps} />
    case TypifiedIconModel.X:
    default:
      return <div>{model}</div>
  }
}
