import {Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {AccessZoneState} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useTourParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {SplitButton} from '../../../visual'
import {AccessZoneForm} from './AccessZoneForm'
import {useAccessZone, useUpdateAccessZone} from './graphql'
import {IAccessZoneForm} from './types'
import {
  transformFormDataToUpdateAccessZoneInput,
  transformUpdateAccessZoneQueryDataToFormData
} from './utils'

const UPDATE_ACCESS_ZONE_FORM_ID = 'updateAccessZoneForm'

interface IUpdateAccessZoneDrawerProps {
  onExited: () => void
}

export const UpdateAccessZoneDrawer: React.FC<IUpdateAccessZoneDrawerProps> = ({
  onExited
}: IUpdateAccessZoneDrawerProps) => {
  const {t} = useTranslation()
  const {accessZoneId} = useTourParams()
  const {data, loading, error} = useAccessZone(
    accessZoneId,
    isNaN(accessZoneId)
  )
  const updateAccessZone = useUpdateAccessZone()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const nextAccessZoneState = useRef<AccessZoneState | undefined>(undefined)
  const updateNextAccessZoneState = useCallback(
    (state) => () => (nextAccessZoneState.current = state),
    []
  )
  const handleSubmit = useCallback(
    async (formData: IAccessZoneForm) => {
      try {
        setShowBackdrop(true)
        await updateAccessZone({
          id: accessZoneId,
          input: transformFormDataToUpdateAccessZoneInput(
            formData,
            nextAccessZoneState.current
          )
        })
        addInfoNotification(t('Access zone has been updated'))
        closeDrawer()
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating access zone'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      accessZoneId,
      addInfoNotification,
      closeDrawer,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateAccessZone
    ]
  )
  useEffect(() => {
    if (!isNaN(accessZoneId)) {
      openDrawer()
    }
  }, [accessZoneId, openDrawer])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t<string>('Error while loading access zone')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update access zone')}
          />
        }
        footer={
          <SplitButton
            Options={[
              <MenuItem
                key="save-as"
                type="submit"
                form={UPDATE_ACCESS_ZONE_FORM_ID}
                component="button"
                onClick={
                  data?.accessZone.state === AccessZoneState.Active
                    ? updateNextAccessZoneState(AccessZoneState.Inactive)
                    : updateNextAccessZoneState(AccessZoneState.Active)
                }
              >
                {data?.accessZone.state === AccessZoneState.Active
                  ? t('Save as inactive')
                  : t('Save as active')}
              </MenuItem>
            ]}
          >
            <SaveButton type="submit" form={UPDATE_ACCESS_ZONE_FORM_ID}>
              {t('Save')}
            </SaveButton>
          </SplitButton>
        }
      >
        {data && (
          <AccessZoneForm
            formId={UPDATE_ACCESS_ZONE_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={transformUpdateAccessZoneQueryDataToFormData(
              data.accessZone
            )}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
