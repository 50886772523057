import {FontSize, FontType} from '@attendio/shared-components'
import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ITextStateValue} from '../../shared/editor/stateTypes'
import {
  NEUTRAL_100_COLOR,
  NEUTRAL_900_COLOR,
  PRIMARY_100_COLOR,
  Theme
} from '../../theme'
import {useObjectsActions} from '../redux/objects/actions'
import {usePlaceholderAndValue} from './placeholderAndValue'

const useStyles = makeStyles((theme: Theme) => ({
  groupRoot: {
    paddingTop: theme.spacing(1)
  },
  groupGrouped: {
    border: 'none'
  },
  buttonRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: NEUTRAL_100_COLOR,
    color: NEUTRAL_900_COLOR,
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: PRIMARY_100_COLOR
    },
    '&.Mui-selected': {
      backgroundColor: PRIMARY_100_COLOR,
      color: theme.palette.primary.main
    }
  }
}))

interface IFontFieldsToggleButtonGroupsProps {
  texts: ITextStateValue[]
}

export const FontFieldsToggleButtonGroups: React.FC<IFontFieldsToggleButtonGroupsProps> =
  ({texts}: IFontFieldsToggleButtonGroupsProps) => {
    const {t} = useTranslation()
    const {updateObjects} = useObjectsActions()
    const {value: commonFontSize} = usePlaceholderAndValue({
      selectedObjects: texts,
      iteratee: 'config.fontSize'
    })
    const {value: commonFontType} = usePlaceholderAndValue({
      selectedObjects: texts,
      iteratee: 'config.fontType'
    })
    const classes = useStyles()
    const getFontFieldChangeHandler =
      (field: 'fontSize' | 'fontType') =>
      (e: React.MouseEvent<HTMLElement>, value: any) => {
        if (value) {
          updateObjects(
            texts.map(
              <T extends ITextStateValue>(object: T): T => ({
                ...object,
                config: {
                  ...object.config,
                  [field]: value
                }
              })
            )
          )
        }
      }
    return (
      <>
        <div>
          <Typography variant="body2">{t('Font size')}</Typography>
          <ToggleButtonGroup
            exclusive
            onChange={getFontFieldChangeHandler('fontSize')}
            value={commonFontSize}
            classes={{grouped: classes.groupGrouped, root: classes.groupRoot}}
            size="small"
          >
            {([12, 16, 20, 24] as FontSize[]).map((item) => (
              <ToggleButton
                key={item}
                value={item}
                classes={{root: classes.buttonRoot}}
              >
                {item}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        <div>
          <Typography variant="body2">{t('Font type')}</Typography>
          <ToggleButtonGroup
            exclusive
            onChange={getFontFieldChangeHandler('fontType')}
            value={commonFontType}
            classes={{grouped: classes.groupGrouped, root: classes.groupRoot}}
            size="small"
          >
            <ToggleButton
              value={FontType.Regular}
              classes={{root: classes.buttonRoot}}
            >
              {t('Regular')}
            </ToggleButton>
            <ToggleButton
              value={FontType.Bold}
              classes={{root: classes.buttonRoot}}
            >
              {t('Bold')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </>
    )
  }
