import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateProductTypeDrawer} from './CreateProductTypeDrawer'
import {ProductTypesList} from './ProductTypesList'
import {UpdateProductTypeDrawer} from './UpdateProductTypeDrawer'

export const ProductTypesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {
    state: isCreateProductTypeDrawerOpen,
    setTrue: openCreateProductTypeDrawer,
    setFalse: closeCreateProductTypeDrawer
  } = useBooleanState(false)
  const handleExited = useCallback(
    () => history.push(routeTo.admin.productTypes.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Product types')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <ProductTypesList
          onCreateProductTypeButtonClick={openCreateProductTypeDrawer}
        />
        <CreateProductTypeDrawer
          isOpen={isCreateProductTypeDrawerOpen}
          onClose={closeCreateProductTypeDrawer}
        />
        {P([
          PermissionCode.UpdateProductType,
          PermissionCode.ReadProductType
        ]) && (
          <Route path={routeTo.admin.productTypes.edit(':productTypeId')} exact>
            <UpdateProductTypeDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
