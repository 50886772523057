import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import {IconButton, Paper, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {TemplateProps} from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {Scalars} from '../../../../../__generated__/schema'
import {Tooltip} from '../../../../common'
import {BlockType, WebsitePageMode} from '../types'
import {isBlockType, useTranslateBlockType} from '../utils'
import {useWebsitePageContext} from './WebsitePageContext'

export interface IBlockItemCommonProps {
  getDeleteClickHandler: (id: string) => () => void
  editableBlocks: BlockType[]
  onEditBlockButtonClick: (block: Scalars['JSON']) => void
}

interface IBlockItemProps
  extends TemplateProps<Scalars['JSON'], IBlockItemCommonProps> {}

export const BlockItem: React.FC<IBlockItemProps> = ({
  item: block,
  commonProps: {getDeleteClickHandler, editableBlocks, onEditBlockButtonClick},
  dragHandleProps
}: IBlockItemProps) => {
  const {t} = useTranslation()
  const translateBlockType = useTranslateBlockType()
  const {selectedWebsitePageMode} = useWebsitePageContext()
  const getEditButtonClickHandler = useCallback(
    (block: Scalars['JSON']) => () => onEditBlockButtonClick(block),
    [onEditBlockButtonClick]
  )
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: 'background.paper',
        p: 2,
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'auto 1fr 48px 48px',
        alignItems: 'center',
        minHeight: 80
      }}
    >
      {selectedWebsitePageMode === WebsitePageMode.Designing ? (
        <Tooltip title={t('Drag to reorder')}>
          <IconButton {...dragHandleProps}>
            <DragHandleIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )}
      <Typography variant="subtitle1">
        {isBlockType(block.type)
          ? translateBlockType(block.type)
          : t('This block {{blockType}} is not implemented yet', {
              blockType: block.type
            })}
      </Typography>
      {selectedWebsitePageMode === WebsitePageMode.Designing ? (
        <Tooltip title={t('Delete')}>
          <IconButton color="primary" onClick={getDeleteClickHandler(block.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )}
      {editableBlocks.includes(block.type) ? (
        <Tooltip title={t('Edit')}>
          <IconButton
            color="primary"
            onClick={getEditButtonClickHandler(block)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )}
    </Paper>
  )
}

export class DraggableBlockItem extends React.Component<IBlockItemProps> {
  render() {
    return <BlockItem {...this.props} />
  }
}
