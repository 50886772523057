import {useGetClientLocales} from './getLocales'
import {useTranslateLocaleCode} from './translateLocales'

export const useClientLocaleSelectData = () => {
  const clientLocales = useGetClientLocales()
  const translateLocaleCode = useTranslateLocaleCode()

  return clientLocales.reduce((res, localeCode) => {
    return {...res, [localeCode]: translateLocaleCode(localeCode)}
  }, {})
}
