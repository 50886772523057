import {FormControlLabel, Radio, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {SyntheticEvent} from 'react'
import {useTranslateEffectiveClientPrice} from '../../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../../theme'

import {ColoredListItem} from '../../../../../common'
import {TicketColor} from './common'

const useStyles = makeStyles<Theme>(() => ({
  radio: {
    width: 40,
    display: 'flex',
    alignSelf: 'stretch'
  }
}))

interface ITicketProps {
  name: string
  description?: string | null | undefined
  price?: number | null | undefined
  color: TicketColor
  onClick: (e: SyntheticEvent) => any
  checked: boolean
  id: number
  highlighted: boolean
}

export const TicketSelection: React.FC<ITicketProps> = ({
  name,
  description,
  price,
  color,
  onClick,
  checked,
  id,
  highlighted
}: ITicketProps) => {
  const classes = useStyles()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()

  return (
    <ColoredListItem
      title={name}
      extra={
        <Typography variant="caption">
          {price
            ? translateEffectiveClientPrice(price)
            : price === 0 && translateEffectiveClientPrice(0)}
        </Typography>
      }
      input={
        <div className={classes.radio}>
          <FormControlLabel
            value={id}
            control={<Radio checked={checked} disableRipple />}
            label=""
          />
        </div>
      }
      disableBorder
      {...{description, onClick, color, highlighted}}
    />
  )
}
