import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  AdmissionRateQuery,
  AdmissionRateState,
  ErrorMessages,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../../utils/errors'
import {useTourParams} from '../../../../../../utils/pathname'
import {routeTo} from '../../../../../../utils/routes'
import {ButtonWithConfirmationDialog, RenderOnData} from '../../../../../common'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {MenuItem} from '../../../../../common/Menu'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../../common/PageWithHeaderAndFooterTemplate'
import {SplitButton} from '../../../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../../Header'
import {WideCenteredLayout} from '../../../Layout'
import {
  useAdmissionRate,
  useDeleteAdmissionRate,
  useUpdateAdmissionRate,
  useUpdateAdmissionRateAssignments,
  useUpdateDraftAdmissionRateAssignments
} from '../../graphql'
import {Admissions} from './Admissions'
import {General} from './General'
import {UpdateAdmissionRateDrawer} from './UpdateAdmissionRateDrawer'
import {
  transformAdmissionTypeAssignmentsToAssignmentInputs,
  transformDraftAdmissionTypeAssignmentsToAssignmentInputs
} from './utils'

export const AdmissionRateDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {admissionRateId, tourId} = useTourParams()
  const {data, loading, error, refetch} = useAdmissionRate(admissionRateId)
  const deleteAdmissionRate = useDeleteAdmissionRate()
  const updateDraftAdmissionRateAssignments =
    useUpdateDraftAdmissionRateAssignments()
  const updateAdmissionRateAssignments = useUpdateAdmissionRateAssignments()
  const updateAdmissionRate = useUpdateAdmissionRate()
  const {
    addInfoNotification,
    defaultErrorHandler,
    customErrorHandler,
    setShowBackdrop
  } = useMutationAssistanceHooks()
  const [title, setTitle] = useState<string>(t('Admission rate'))
  const [admissionRateAssignments, setAdmissionRateAssignments] = useState<
    AdmissionRateQuery['admissionRate']['admissionTypesAssignments']
  >(data?.admissionRate.admissionTypesAssignments || [])
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.tours.admissionRates(tourId)),
    [history, tourId]
  )
  const handleExited = useCallback(
    () =>
      history.push(
        routeTo.admin.tours.admissionRateDetail(tourId, admissionRateId)
      ),
    [admissionRateId, history, tourId]
  )
  useEffect(() => {
    if (data) {
      setTitle(data.admissionRate.name)
    }
  }, [data])
  const handleDeleteConfirm = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteAdmissionRate(admissionRateId)
      addInfoNotification(t('Admission rate deleted'))
      history.replace(routeTo.admin.tours.admissionRates(tourId))
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting admission rate'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    admissionRateId,
    defaultErrorHandler,
    deleteAdmissionRate,
    history,
    setShowBackdrop,
    t,
    tourId
  ])
  const saveAs = useCallback(
    async (state: AdmissionRateState) => {
      if (data) {
        try {
          setShowBackdrop(true)
          if (data.admissionRate.state === AdmissionRateState.Draft) {
            await updateDraftAdmissionRateAssignments({
              admissionRateId,
              assignmentInputs:
                transformDraftAdmissionTypeAssignmentsToAssignmentInputs(
                  admissionRateAssignments
                )
            })
          } else {
            await updateAdmissionRateAssignments({
              admissionRateId,
              assignmentInputs:
                transformAdmissionTypeAssignmentsToAssignmentInputs(
                  admissionRateAssignments
                )
            })
          }
          await updateAdmissionRate({
            id: data.admissionRate.id,
            input: {
              name: data.admissionRate.name,
              abbreviation: data.admissionRate.abbreviation,
              color: data.admissionRate.color,
              description: data.admissionRate.description || null,
              state
            }
          })
          addInfoNotification(t('Admissions updated'))
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.AdmissionRateHasNoTypeAssignments
            )
          ) {
            customErrorHandler(error, {
              title: t('Unable to activate admission rate'),
              contentText: t(
                'The admission rate currently has no associated admissions. Please add some admissions first, and you can activate it later.'
              ),
              confirmButtonLabel: t('Got it')
            })
          } else {
            defaultErrorHandler(
              error,
              t('Error while updating draft admission rate')
            )
          }
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      admissionRateAssignments,
      admissionRateId,
      customErrorHandler,
      data,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateAdmissionRate,
      updateAdmissionRateAssignments,
      updateDraftAdmissionRateAssignments
    ]
  )
  const handleSaveButtonClick = useCallback(async () => {
    if (data) {
      try {
        setShowBackdrop(true)
        if (data.admissionRate.state === AdmissionRateState.Draft) {
          await updateDraftAdmissionRateAssignments({
            admissionRateId,
            assignmentInputs:
              transformDraftAdmissionTypeAssignmentsToAssignmentInputs(
                admissionRateAssignments
              )
          })
        } else {
          await updateAdmissionRateAssignments({
            admissionRateId,
            assignmentInputs:
              transformAdmissionTypeAssignmentsToAssignmentInputs(
                admissionRateAssignments
              )
          })
        }
        addInfoNotification(t('Admissions updated'))
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while updating draft admission rate')
        )
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    addInfoNotification,
    admissionRateAssignments,
    admissionRateId,
    data,
    defaultErrorHandler,
    setShowBackdrop,
    t,
    updateAdmissionRateAssignments,
    updateDraftAdmissionRateAssignments
  ])
  const [openedDialog, setOpenedDialog] =
    useState<AdmissionRateState | null>(null)
  const retailWithoutPrice = admissionRateAssignments.filter(
    ({retailPrice}) => retailPrice === 0 || String(retailPrice) === '0.00'
  ).length
  const eCommerceWithoutPrice = admissionRateAssignments.filter(
    ({eCommercePrice}) =>
      eCommercePrice === 0 || String(eCommercePrice) === '0.00'
  ).length
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        <Footer>
          {data?.admissionRate.state === AdmissionRateState.Draft &&
            P([PermissionCode.DeleteAdmissionRate]) && (
              <ButtonWithConfirmationDialog
                onConfirmButtonClick={handleDeleteConfirm}
                buttonProps={{
                  children: t('Delete'),
                  startIcon: <DeleteIcon />
                }}
                dialogProps={{
                  title: t('Delete admission rate?'),
                  contentText: t(
                    "Are you sure you want to delete this admission rate? This can't be undone."
                  ),
                  confirmButtonLabel: t('Delete')
                }}
              />
            )}
          {P([PermissionCode.UpdateAdmissionRate]) &&
            (P([PermissionCode.UpdateDraftAdmissionRateAssignments]) ||
              P([PermissionCode.UpdateAdmissionRate])) && (
              <SplitButton
                Options={[
                  (data?.admissionRate.state === AdmissionRateState.Draft ||
                    data?.admissionRate.state ===
                      AdmissionRateState.Inactive) && (
                    <MenuItem
                      key={1}
                      label={t('Save as active')}
                      onClick={() => setOpenedDialog(AdmissionRateState.Active)}
                    />
                  ),
                  data?.admissionRate.state === AdmissionRateState.Active && (
                    <MenuItem
                      key={1}
                      label={t('Save as inactive')}
                      onClick={() =>
                        setOpenedDialog(AdmissionRateState.Inactive)
                      }
                    />
                  )
                ]}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="FORM"
                  onClick={handleSaveButtonClick}
                  disabled={
                    !P([
                      PermissionCode.UpdateAdmissionRateAssignments,
                      PermissionCode.UpdateDraftAdmissionRateAssignments
                    ])
                  }
                >
                  {t('Save')}
                </Button>
              </SplitButton>
            )}
        </Footer>
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<AdmissionRateQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading admission rate')}
          ignoreLoadingIfData
          dataCondition={(data) => Boolean(data.admissionRate)}
        >
          {({admissionRate}) => (
            <>
              <WideCenteredLayout>
                <Box
                  sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 5}}
                >
                  <General admissionRate={admissionRate} />
                  <Admissions
                    admissionRate={admissionRate}
                    setAdmissionRateAssignments={setAdmissionRateAssignments}
                    admissionRateAssignments={admissionRateAssignments}
                    refetch={refetch}
                  />
                </Box>
              </WideCenteredLayout>
              {P([PermissionCode.UpdateAdmissionRate]) && (
                <Route
                  path={routeTo.admin.tours.editAdmissionRate(
                    ':tourId',
                    ':admissionRateId'
                  )}
                >
                  <UpdateAdmissionRateDrawer
                    onExited={handleExited}
                    admissionRate={admissionRate}
                  />
                </Route>
              )}
              {openedDialog && (
                <ConfirmationDialog
                  title={
                    openedDialog === AdmissionRateState.Active
                      ? t('Activate admission rate?')
                      : t('Inactivate admission rate?')
                  }
                  contentText={
                    openedDialog === AdmissionRateState.Active
                      ? [
                          retailWithoutPrice &&
                            admissionRate.state === AdmissionRateState.Draft &&
                            t(
                              'There are {{count}} rates with zero retail price.',
                              {
                                count: retailWithoutPrice
                              }
                            ),
                          eCommerceWithoutPrice &&
                            admissionRate.state === AdmissionRateState.Draft &&
                            t(
                              'There are {{count}} rates with zero ecommerce price.',
                              {count: eCommerceWithoutPrice}
                            ),
                          t(
                            'Please note, once you activate this admission rate, you will not be able to edit prices or VAT rates. Active admission rate can be used in new time slots. Are you sure you want to proceed?'
                          )
                        ]
                          .filter(Boolean)
                          .reduce(
                            (prev: React.ReactNode, current, index) => [
                              prev,
                              prev !== null && <br key={index} />,
                              current
                            ],
                            null
                          )
                      : t(
                          'Please note, upon deactivating this admission rate, it will not be available for use in new tour time slots. However, all existing time slots with this rate will continue to operate as usual. Are you sure you want to proceed?'
                        )
                  }
                  onConfirm={() => {
                    saveAs(openedDialog)
                    setOpenedDialog(null)
                  }}
                  confirmButtonLabel={
                    openedDialog === AdmissionRateState.Active
                      ? t('Activate')
                      : t('Inactivate')
                  }
                  cancelButtonLabel={t('Cancel')}
                  onCancel={() => setOpenedDialog(null)}
                  isOpen
                />
              )}
            </>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
