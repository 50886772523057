import {Reducer, useCallback, useEffect, useReducer, useState} from 'react'

export enum LocalStorageKey {
  TemplateIdsAssignedToDeviceByType = 'templateIdsAssignedToDeviceByType',
  ZonePlanView = 'zonePlanView',
  ShopViewMode = 'shopViewMode',
  TourTimeSlotViewMode = 'tourTimeSlotViewMode',
  CheckoutPaymentMethodsViewMode = 'checkoutPaymentMethodsViewMode',
  RegistrationOfSales = 'registrationOfSales',
  CashDeskShopDivisionId = 'cashDeskShopDivisionId',
  EnabledDivisions = 'enabledDivisions',
  CashDrawer = 'cashDrawer',
  PosTerminal = 'posTerminal',
  PendingCarteCommercePaymentRedirectUrl = 'pendingCarteCommercePaymentRedirectUrl',
  PendingCartData = 'pendingCartData',
  WarehouseId = 'warehouseId',
  CustomerDisplaySettings = 'customerDisplaySettings',
  DirectTicketPrintSettings = 'directTicketPrintSettings'
}

export enum SessionStorageKey {
  PinnedEvents = 'pinnedEvents',
  CustomerDisplayPageState = 'customerDisplayPageState'
}

const getItemFromStorage = (storage: Storage, key: string) =>
  storage.getItem(key)

export const getParsedItemFromStorage = <T>(
  storage: Storage,
  key: string
): T | null => {
  const savedItem = getItemFromStorage(storage, key)
  if (savedItem) {
    try {
      return JSON.parse(savedItem)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Invalid item in storage')
      return null
    }
  }
  return null
}

export const setObjectToStorage = <T>(
  storage: Storage,
  key: string,
  object: T | null
) => {
  const stringifiedObject = JSON.stringify(object)
  storage.setItem(key, stringifiedObject)
}

const useStorageState = <T>(
  storage: Storage,
  key: string,
  initialState: T
): [T, (newState: T) => void] => {
  const savedParsedItem = getParsedItemFromStorage<T>(storage, key)
  const [state, setState] = useState<T>(savedParsedItem || initialState)

  const setNewState = useCallback(
    (newState: T) => {
      const stringifiedNewState = JSON.stringify(newState)
      const stringifiedStorageState = getItemFromStorage(storage, key)
      if (stringifiedNewState !== stringifiedStorageState) {
        storage.setItem(key, stringifiedNewState)
        setState(newState)
      }
    },
    [key, storage]
  )

  return [state, setNewState]
}

export const useLocalStorageState = <T>(
  key: string,
  initialState: T
): [T, (newState: T) => void] =>
  useStorageState<T>(localStorage, key, initialState)

export const useSessionStorageState = <T>(
  key: string,
  initialState: T
): [T, (newState: T) => void] =>
  useStorageState<T>(sessionStorage, key, initialState)

const getInitialStateForStorageReducer = <TState>(
  initialState: TState,
  key: string,
  storage: Storage
) => {
  const savedParsedItem = getParsedItemFromStorage<TState>(storage, key)
  if (savedParsedItem === null) {
    return initialState
  }
  return savedParsedItem
}

export const useSessionStorageReducer = <TState, TAction>(
  reducer: Reducer<TState, TAction>,
  initialState: TState,
  key: string
) => {
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(
    reducer,
    getInitialStateForStorageReducer(initialState, key, sessionStorage)
  )
  useEffect(() => {
    const stringifiedNewState = JSON.stringify(state)
    const stringifiedStorageState = getItemFromStorage(sessionStorage, key)
    if (stringifiedNewState !== stringifiedStorageState) {
      sessionStorage.setItem(key, stringifiedNewState)
    }
  }, [key, state])
  return {state, dispatch}
}
