import {Box, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {VoucherCampaignQuery} from '../../../../../__generated__/schema'

const usePaymentMethodRowStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  name: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1)
  },
  chip: {
    ...theme.typography.caption,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary
  }
}))

interface IPaymentMethodRowProps {
  title: string
  name: string
}

const PaymentMethodRow: React.FC<IPaymentMethodRowProps> = ({
  title,
  name
}: IPaymentMethodRowProps) => {
  const classes = usePaymentMethodRowStyles()
  return (
    <div className={classes.root}>
      <div className={classes.name}>
        <Typography variant="subtitle2">{title}</Typography>
      </div>
      <Typography variant="body2" color="textSecondary">
        {name}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    paddingBottom: theme.spacing(1)
  }
}))

interface IPaymentMethodsSectionProps {
  id: string
  voucherCampaign: VoucherCampaignQuery['voucherCampaign']
}

export const PaymentMethodsSection: React.FC<IPaymentMethodsSectionProps> = ({
  id,
  voucherCampaign
}: IPaymentMethodsSectionProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Box id={id}>
      <Typography variant="subtitle1" className={classes.title}>
        {t('Payment methods')}
      </Typography>
      <Paper variant="outlined">
        <PaymentMethodRow
          title={t('Retail payment method')}
          name={
            voucherCampaign.retailPaymentMethod?.name ||
            t('Not set yet. Vouchers can’t be redeemed on this channel.')
          }
        />
        <PaymentMethodRow
          title={t('Ecommerce payment method')}
          name={
            voucherCampaign.ecommercePaymentMethod?.name ||
            t('Not set yet. Vouchers can’t be redeemed on this channel.')
          }
        />
      </Paper>
    </Box>
  )
}
