import React, {useCallback} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'

import {CartPreviewDrawer} from '../components/cartPreviewDrawer'
import {CartsPage} from './CartsPage'

export const Carts: React.FC = () => {
  const history = useHistory()
  const {P} = useEnsurePermissions()
  const handleCartPreviewExited = useCallback(() => {
    history.replace(routeTo.admin.carts.index())
  }, [history])
  return (
    <>
      <CartsPage />
      {P([PermissionCode.ReadCart]) && (
        <Route path={routeTo.admin.carts.detail(':cartId')}>
          <CartPreviewDrawer onExited={handleCartPreviewExited} />
        </Route>
      )}
    </>
  )
}
