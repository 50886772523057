import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps} from 'react-router-dom'
import {PermissionCode, UserQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {getUrlParam, routeTo} from '../../../../../utils/routes'
import {
  BottomBackButton,
  RenderOnData,
  SingleSideNavigationList
} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {TOP_BAR_HEIGHT} from '../../../../constants'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useGetAvailableRoles, useGetUser, useUpdateUser} from '../graphql'
import {StateActionsBar} from '../StateActionsBar'
import {
  IUserFormData,
  UserForm,
  userFromUserForm,
  userToUserForm,
  useUserFormAnchors
} from '../UserForm'

const EDIT_USER_FORM_ID = 'editUserForm'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    padding: theme.spacing(3, 0),
    gap: theme.spacing(6)
  }
}))

const useGetDeprecatedRoleIds = () => {
  const {data} = useGetAvailableRoles()
  return data?.availableRoles
    ? data.availableRoles
        .filter((role) => role.isDeprecated)
        .map((role) => role.id)
    : undefined
}

export const EditUser: React.FC<RouteComponentProps> = ({
  match,
  history
}: RouteComponentProps) => {
  const userId = parseInt(getUrlParam(match, 'id'), 10)
  const {error, loading, data} = useGetUser(userId)
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const {
    state: isErrorDialogOpen,
    setTrue: openErrorDialog,
    setFalse: closeErrorDialog
  } = useBooleanState(false)
  const deprecatedRoleIds = useGetDeprecatedRoleIds()
  const updateUser = useUpdateUser()
  const onSubmit = useCallback(
    async (formData: IUserFormData) => {
      if (
        formData.roleIds?.some((roleId) => deprecatedRoleIds?.includes(roleId))
      ) {
        openErrorDialog()
      } else {
        try {
          setShowBackdrop(true)
          await updateUser(
            userId,
            userFromUserForm(formData),
            P([PermissionCode.ReadAvailableRoles])
              ? formData.roleIds
              : data?.user.roleIds
          )
          history.push(routeTo.admin.users.home())
          addInfoNotification(t('User updated'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      P,
      addInfoNotification,
      data?.user.roleIds,
      deprecatedRoleIds,
      history,
      openErrorDialog,
      setShowBackdrop,
      t,
      updateUser,
      userId
    ]
  )

  const classes = useStyles()
  const userFormAnchors = useUserFormAnchors()

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('User')} hasArrowBackIcon />}
      footer={
        <Footer>
          <BottomBackButton />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={EDIT_USER_FORM_ID}
          >
            {t('Save')}
          </Button>
        </Footer>
      }
    >
      <RenderOnData
        data={data}
        loading={loading}
        error={error}
        dataCondition={(data?: UserQuery) => Boolean(data?.user)}
        errorMessage={t<string>('Could not load user')}
      >
        {({user}) => (
          <CenteredLayout>
            <SingleSideNavigationList
              items={userFormAnchors}
              top={2 * TOP_BAR_HEIGHT + 30}
            />
            <div className={classes.grid}>
              <StateActionsBar user={user} />
              <UserForm
                formId={EDIT_USER_FORM_ID}
                defaultValues={userToUserForm(user)}
                onSubmit={onSubmit}
              />
            </div>
            <ConfirmationDialog
              title={t('Unable to update user')}
              onConfirm={closeErrorDialog}
              contentText={t(
                'You try to update user’s settings with deprecated role. Please, disable all deprecated roles for this user and try to save user settings again.'
              )}
              isOpen={isErrorDialogOpen}
              confirmButtonLabel={t('Got it')}
            />
          </CenteredLayout>
        )}
      </RenderOnData>
    </PageWithHeaderAndFooterTemplate>
  )
}
