import {useTranslation} from 'react-i18next'

export const useDivisionFormAnchors = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    contact: {
      id: 'contact',
      label: t('Contact')
    },
    invoice: {
      id: 'invoice',
      label: t('Invoice')
    },
    sales: {
      id: 'sales',
      label: t('Purchase defaults')
    },
    reservation: {
      id: 'reservation',
      label: t('Reservation defaults')
    },
    entrance: {
      id: 'entrance',
      label: t('Entrance defaults')
    }
  }
}
