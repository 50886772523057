import React, {useCallback} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {useBooleanState} from '../../../../hooks/state'
import {useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {ClientTemplatesDrawer} from '../../../common/ClientTemplatesDrawer'
import {AssignTemplateDialog} from '../components/assignTemplateDialog'
import {NoEffectiveClientSelected} from '../NoEffectiveClientSelected'
import {EditCompany} from './EditCompany'

export const Company: React.FC = () => {
  const {effectiveClient} = useUserInfo()
  const history = useHistory()
  const handleDrawerExited = useCallback(
    () => history.push(routeTo.admin.company()),
    [history]
  )
  const {
    state: isAssignTemplateDialogOpen,
    setTrue: openAssignTemplateDialog,
    setFalse: closeAssignTemplateDialog
  } = useBooleanState(false)
  return effectiveClient ? (
    <>
      <EditCompany company={effectiveClient} />
      <Route path={routeTo.admin.companyAssignedTemplates(':templateType')}>
        <>
          <ClientTemplatesDrawer
            onExited={handleDrawerExited}
            onAssignTemplateButtonClick={openAssignTemplateDialog}
          />
          <AssignTemplateDialog
            clientId={effectiveClient.id}
            isOpen={isAssignTemplateDialogOpen}
            onClose={closeAssignTemplateDialog}
          />
        </>
      </Route>
    </>
  ) : (
    <NoEffectiveClientSelected />
  )
}
