import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {ActionCreators as UndoActionCreators} from 'redux-undo'

import {useSelectionActions} from '../selection/actions'

export const useUndoableActions = () => {
  const dispatch = useDispatch()
  const {unselectAll} = useSelectionActions()

  const clearHistory = useCallback(() => {
    dispatch(UndoActionCreators.clearHistory())
  }, [dispatch])

  const undo = useCallback(() => {
    unselectAll()
    dispatch(UndoActionCreators.undo())
  }, [dispatch, unselectAll])

  const redo = useCallback(() => {
    unselectAll()
    dispatch(UndoActionCreators.redo())
  }, [dispatch, unselectAll])

  const actions = useMemo(
    () => ({
      clearHistory,
      undo,
      redo
    }),
    [clearHistory, redo, undo]
  )

  return actions
}
