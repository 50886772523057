import {useTranslation} from 'react-i18next'
import {ApiGrantState} from '../__generated__/schema'

export const useTranslateApiGrantState = () => {
  const {t} = useTranslation()
  const translated: Record<ApiGrantState, string> = {
    [ApiGrantState.Active]: t<string>('ApiGrantState->Active'),
    [ApiGrantState.Inactive]: t<string>('ApiGrantState->Inactive'),
    [ApiGrantState.Expired]: t<string>('ApiGrantState->Expired')
  }
  return (state: ApiGrantState) => translated[state]
}

export const useTranslateApiGrantStateDescription = () => {
  const {t} = useTranslation()
  const translated: Record<ApiGrantState, string> = {
    [ApiGrantState.Active]: t<string>(
      'API grant is fully operational and ready for use.'
    ),
    [ApiGrantState.Inactive]: t<string>(
      'API grant is currently disabled and cannot be used.'
    ),
    [ApiGrantState.Expired]: t<string>(
      'API grant has reached the end of its validity period and is no longer functional.'
    )
  }
  return (state: ApiGrantState) => translated[state]
}
