import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState
} from '../../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../../hooks/getLocales'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {routeTo} from '../../../../../utils/routes'
import {CartSummary} from '../../../../common/cartSummary/CartSummary'
import {TotalPrice} from '../../components/TotalPrice'
import {DiscardCartButton} from '../DiscardCartButton'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.common.white,
    overflow: 'hidden'
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2)
  }
}))

interface ISupplementProps {
  cart: CartPropertiesFragment
}

export const Supplement: React.FC<ISupplementProps> = ({
  cart
}: ISupplementProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  const history = useHistory()
  const isDisabled = cart.state === CartState.Pending

  const getTicketItemClickHandler = useCallback(
    (eventId: number) => () => {
      history.push(routeTo.admin.cashDesk.eventAuditoriumPreview(eventId))
    },
    [history]
  )

  const getReservedTicketItemClickHandler = useCallback(
    (reservationId: number) => () => {
      history.push(routeTo.admin.cashDesk.reservationPreview(reservationId))
    },
    [history]
  )

  const clientLocaleCode = useClientLocaleCode()

  const getProductItemClickHandler = useCallback(
    (productId: number) => () => {
      history.replace(
        routeTo.admin.cashDesk.shopWithOpenedQuantityDialog(productId)
      )
    },
    [history]
  )

  const getReservedTourItemClickHandler = useCallback(
    (reservationId: number) => () => {
      history.push(routeTo.admin.cashDesk.reservationPreview(reservationId))
    },
    [history]
  )
  const getTourItemClickHandler = useCallback(
    (tourTimeSlotId: number) => () => {
      history.push(routeTo.admin.cashDesk.tourTimeSlot(tourTimeSlotId))
    },
    [history]
  )

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle1">{t('Cart')}</Typography>
        <DiscardCartButton cart={cart} />
      </div>
      <CartSummary
        sx={{
          overflow: 'auto',
          opacity: (theme) =>
            isDisabled ? theme.palette.action.disabledOpacity : undefined,
          cursor: isDisabled ? 'pointer' : 'normal',
          pointerEvents: isDisabled ? 'none' : 'auto'
        }}
        cart={cart}
        translatePrice={translatePrice}
        getReservedTicketItemClickHandler={getReservedTicketItemClickHandler}
        getTicketItemClickHandler={getTicketItemClickHandler}
        getProductItemClickHandler={getProductItemClickHandler}
        getReservedTourItemClickHandler={getReservedTourItemClickHandler}
        getTourItemClickHandler={getTourItemClickHandler}
        clientLocaleCode={clientLocaleCode}
      />
      <TotalPrice total={cart.price} />
    </div>
  )
}
