import AddIcon from '@mui/icons-material/Add'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Box, Button, IconButton, Typography} from '@mui/material'
import Paper from '@mui/material/Paper'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  ProductGroupFieldsFragment,
  ProductGroupState,
  ProductQuery
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateProductGroupState} from '../../../../../hooks/translateProductGroupState'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {EntityStateChip, Tooltip} from '../../../../common'
import {ColorBox} from '../../../../common/ColorBox'
import {DataGridTable} from '../../../../common/DataGridTable'
import {IconBox} from '../../../../common/IconBox'
import {getProductGroupIcon} from '../../../../common/productGroupIcon'
import {productGroupStateColors} from '../../../../constants'
import {useRemoveProductGroupFormProduct} from '../graphql'
import {AssignProductGroupDrawer} from './AssignProductGroupDrawer'

const NameRenderer = ({group}: {group: ProductGroupFieldsFragment}) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
    {group.icon ? (
      <IconBox
        Icon={getProductGroupIcon(group.icon)}
        hexColor={group.color}
        hideBackground
        sx={{width: 24, height: 24}}
        iconSx={{width: 24, height: 24}}
      />
    ) : (
      <ColorBox hexColor={group.color} />
    )}
    <Typography>{group.name}</Typography>
  </Box>
)

const StateRenderer = ({state}: {state: ProductGroupState}) => {
  const translateProductGroupState = useTranslateProductGroupState()
  return (
    <EntityStateChip
      label={translateProductGroupState(state)}
      colorConf={productGroupStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({
  productId,
  productGroupId
}: {
  productId: number
  productGroupId: number
}) => {
  const {t} = useTranslation()
  const removeProductGroupFormProduct = useRemoveProductGroupFormProduct()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const handleIconClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await removeProductGroupFormProduct({productId, productGroupId})
    } catch (error) {
      defaultErrorHandler(
        error,
        t('Error while removing product group from product')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    defaultErrorHandler,
    productGroupId,
    productId,
    removeProductGroupFormProduct,
    setShowBackdrop,
    t
  ])
  return (
    <Tooltip title={t('Remove')}>
      <IconButton onClick={handleIconClick} color="primary">
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  )
}

interface IProductGroupsSectionProps {
  id: string
  label: string
  product: ProductQuery['product']
  refetchProduct: () => void
}

export const ProductGroupsSection: React.FC<IProductGroupsSectionProps> = ({
  id,
  label,
  product,
  refetchProduct
}: IProductGroupsSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(false)
  const {id: productId} = product
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <NameRenderer group={params.row} />
        },
        minWidth: 350,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ProductGroupState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Internal description'),
        field: 'internalDescription',
        minWidth: 350,
        sortable: false
      },
      {
        headerName: '',
        field: 'icon',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              productId={productId}
              productGroupId={params.row.id}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [productId, t]
  )
  return (
    <Box id={id}>
      <Box
        sx={{
          pb: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
        {P([
          PermissionCode.AssignProductGroupToProduct,
          PermissionCode.ReadProductGroups
        ]) && (
          <Button
            onClick={openDrawer}
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
          >
            {t('Add')}
          </Button>
        )}
      </Box>
      <Paper variant="outlined" sx={{pt: 1.5}}>
        <Box sx={{px: 3, pb: 2}}>
          <Typography variant="caption" color="textSecondary">
            {t(
              'This product is included in the product groups listed below. Product groups help categorize your offerings more effectively for customers and cashiers, streamlining the shopping process and enhancing the overall experience.'
            )}
          </Typography>
        </Box>
        {product.productGroups && product.productGroups.length > 0 && (
          <DataGridTable
            sx={{border: 'none', borderRadius: 0}}
            columns={columns}
            rows={product.productGroups}
            initialState={{
              pinnedColumns: {right: ['icon']}
            }}
            columnVisibilityModel={{
              icon: P([PermissionCode.RemoveProductGroupFromProduct])
            }}
            pagination={false}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            autoHeight
          />
        )}
      </Paper>
      <AssignProductGroupDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        assignedProductGroupIds={(product.productGroups || []).map(
          ({id}) => id
        )}
        productId={productId}
        refetchProduct={refetchProduct}
      />
    </Box>
  )
}
