import {ItemType, Maybe, Sale, TicketItem} from '../__generated__/schema'
import {isNotProductItem} from '../components/pages/admin/types'

export const useDownloadSalesPdf = () => {
  return async (
    sales: (Pick<Sale, 'id' | 'itemType'> & {
      items?: Maybe<
        Array<
          | ({__typename?: 'TicketItem'} & Pick<TicketItem, 'id'>)
          | {__typename?: 'ProductItem'}
          | {__typename?: 'TourItem'}
        >
      >
    })[]
  ) =>
    await Promise.all(
      sales.map(async (sale) => {
        if (sale.itemType !== ItemType.Product) {
          const fileName = `tickets_ID${sale.id}_${Date.now()}`
          const response = await fetch(
            `/pdf/saleTickets?saleId=${sale.id}&itemIds=${sale.items
              ?.filter(isNotProductItem)
              .map((item) => item.id)
              .join(',')}&pdfFilename=${fileName}`
          )
          const blob = await response.blob()
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        }
      })
    )
}
