import AddIcon from '@mui/icons-material/Add'
import {Button, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {uniqueId} from 'lodash'
import React, {useCallback} from 'react'
import DraggableList from 'react-draggable-list'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ShowTypeCode, ShowTypesQuery} from '../../../../__generated__/schema'
import {RenderOnData} from '../../../common'
import {useShowTypes} from '../graphql'
import {
  DraggableShowCrewInputRow,
  IShowCrewInputRowCommonProps
} from './DraggableShowCrewInputRow'
import {IShowCrewFieldsProps, IShowCrewForm, showCrewForm} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    gap: theme.spacing(3),
    width: '100%',
    height: '100% '
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  },
  table: {
    '& .MuiTableCell-body': {
      padding: theme.spacing(0, 0, 0, 2),
      '&:last-child': {
        paddingRight: theme.spacing(2)
      }
    }
  },
  noItemsFound: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paperHeader: {
    display: 'grid',
    gridTemplateColumns:
      '48px minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 48px',
    padding: theme.spacing(1.5, 2),
    gap: theme.spacing(2),
    borderBottom: `solid ${theme.palette.divider} 1px`
  },
  paperRow: {
    display: 'grid',
    gridTemplateColumns: '48px 1fr 1fr 1fr 48px',
    padding: theme.spacing(0, 2),
    gap: theme.spacing(2)
  },
  paper: {
    minHeight: 580,
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column'
  }
}))

interface IShowCrewFormProps {
  onSubmit: (data: IShowCrewForm) => void
  crewFields: IShowCrewFieldsProps[]
  setCrewFields: React.Dispatch<React.SetStateAction<IShowCrewFieldsProps[]>>
  headerAction?: React.ReactNode
  showType: ShowTypeCode
}

export const ShowCrewForm: React.FC<IShowCrewFormProps> = ({
  onSubmit,
  crewFields,
  setCrewFields,
  headerAction,
  showType
}: IShowCrewFormProps) => {
  const {t} = useTranslation()
  const {handleSubmit, errors, setValue, register, triggerValidation, watch} =
    useFormContext<IShowCrewForm>()
  const {data, loading, error} = useShowTypes()
  const classes = useStyles()
  const handleAddButtonClick = useCallback(
    () =>
      setCrewFields((fields) => [
        ...fields,
        {
          id: uniqueId(),
          description: '',
          name: '',
          crewRoleCode: ''
        }
      ]),
    [setCrewFields]
  )
  const handleDeleteButtonClick = useCallback(
    (id: string) => () =>
      setCrewFields((fields) => fields.filter((f) => f.id !== id)),
    [setCrewFields]
  )
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {headerAction}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddButtonClick}
        >
          {t('Add')}
        </Button>
      </div>
      <Paper variant="outlined" elevation={0} className={classes.paper}>
        <div className={classes.paperHeader}>
          <div />
          <Typography variant="subtitle2" color="textSecondary">
            {t('Role')}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t('Name')}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t('Description')}
          </Typography>
          <div />
        </div>
        <RenderOnData<ShowTypesQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading show types')}
        >
          {({showTypes}) => (
            <form id={showCrewForm} onSubmit={handleSubmit(onSubmit)}>
              <DraggableList<
                IShowCrewFieldsProps,
                IShowCrewInputRowCommonProps,
                DraggableShowCrewInputRow
              >
                list={crewFields}
                itemKey="id"
                padding={0}
                constrainDrag
                onMoveEnd={(newList: readonly IShowCrewFieldsProps[]) =>
                  setCrewFields([...newList])
                }
                template={DraggableShowCrewInputRow}
                commonProps={{
                  errors,
                  setValue,
                  watch,
                  register,
                  triggerValidation,
                  getDeleteButtonClickHandler: handleDeleteButtonClick,
                  crewRoleCodes:
                    showTypes.find(({code}) => code === showType)
                      ?.crewRoleCodes || []
                }}
              />
            </form>
          )}
        </RenderOnData>
        {crewFields.length === 0 && (
          <div className={classes.noItemsFound}>
            <Typography variant="body2" color="textSecondary">
              {t('No items found')}
            </Typography>
          </div>
        )}
      </Paper>
    </div>
  )
}
