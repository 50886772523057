import {Box, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {
  useIsStringWithMaxLength,
  useIsValidEmail
} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {FormAutocompleteMultiple} from '../../../../form/FormAutocompleteMultiple'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {IMessageForm, MessageFormField, useNavigationItems} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  sectionTitle: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  paper: {
    padding: theme.spacing(2, 3)
  },
  message: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(3),
    flexDirection: 'column'
  }
}))

interface IMessageFormProps {
  formId: string
  onSubmit: (data: IMessageForm) => void
  defaultValues?: Partial<IMessageForm>
  leads: {id: number; contact: string}[]
  className?: string
}

export const MessageForm: React.FC<IMessageFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  leads,
  className
}: IMessageFormProps) => {
  const {t} = useTranslation()
  const {
    control,
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    handleSubmit
  } = useForm<IMessageForm>({defaultValues})
  const navigationItems = useNavigationItems()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isValidEmail = useIsValidEmail()
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <Paper
        id={navigationItems.general.id}
        variant="outlined"
        className={classes.paper}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IMessageForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={MessageFormField.SenderName}
              key={MessageFormField.SenderName}
              label={t('Sender name')}
              helperNote={t(
                'Will be displayed in recipient’s inbox as name of message sender.'
              )}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IMessageForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={MessageFormField.ReplyTo}
              key={MessageFormField.ReplyTo}
              label={t('Reply to')}
              helperNote={t(
                'If you are expecting reply, type your or team email address and recipient can contact you there.'
              )}
              validationOptions={{
                validate: isValidEmail
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IMessageForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={MessageFormField.InternalNote}
              key={MessageFormField.InternalNote}
              label={t('Internal note')}
              helperNote={t(
                'Recipient will never see this. Note will help you to keep your messages organized.'
              )}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
              required
              fullWidth
            />
          ]}
        />
      </Paper>
      <div id={navigationItems.message.id} className={classes.message}>
        <Box>
          <Typography variant="subtitle1">{t('Message')}</Typography>
        </Box>
        <Paper variant="outlined" className={classes.paper}>
          <InputRow
            nodes={[
              <FormAutocompleteMultiple<IMessageForm>
                errors={errors}
                control={control}
                name={MessageFormField.LeadIds}
                key={MessageFormField.LeadIds}
                label={t('Recipients')}
                autocompleteOptions={leads.map(
                  ({id: value, contact: name}) => ({value, name})
                )}
                fullWidth
                validationOptions={{required: true}}
                limitTags={2}
              />
            ]}
          />
          <input
            type="hidden"
            name={MessageFormField.LeadIds}
            ref={register()}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IMessageForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={MessageFormField.Subject}
                key={MessageFormField.Subject}
                label={t('Subject')}
                helperNote={t(
                  'This help recipient understand, what is your message about.'
                )}
                validationOptions={{
                  required: true,
                  validate: isStringWithMaxLength
                }}
                required
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IMessageForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={MessageFormField.Body}
                key={MessageFormField.Body}
                label={t('Message body')}
                validationOptions={{
                  required: true
                }}
                required
                fullWidth
                multiline
                rows={10}
              />
            ]}
          />
        </Paper>
      </div>
    </form>
  )
}
