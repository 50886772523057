import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import {alpha, Box, IconButton, Typography} from '@mui/material'
import {clsx} from 'clsx'
import React, {forwardRef} from 'react'
import {ITreeItemProps} from './types'

export const TreeItem = forwardRef<HTMLDivElement, ITreeItemProps>(
  function TreeItemComp(
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      onEdit,
      style,
      value,
      description,
      wrapperRef,
      ...props
    }: ITreeItemProps,
    ref
  ) {
    return (
      <Box
        component="li"
        sx={(theme) => ({
          pl: `${indentationWidth * depth}px`,
          listStyle: 'none',
          boxSizing: 'border-box',
          marginBottom: '-1px',
          ...(disableSelection
            ? {userSelect: 'none', WebkitUserSelect: 'none'}
            : {}),
          pointerEvents: disableInteraction ? 'none' : 'auto',
          '&.clone': {
            display: 'inline-block',
            pointerEvents: 'none',
            padding: 0,
            pl: 2,
            pt: 1,
            '.treeItem': {
              pr: 3,
              boxShadow: '0px 15px 15px 0 rgba(34, 33, 81, 0.1)'
            }
          },
          '&.ghost': {
            '&.indicator': {
              opacity: 1,
              position: 'relative',
              zIndex: 1,
              marginBottom: '-1px',
              '.treeItem': {
                position: 'relative',
                padding: 0,
                height: '8px',
                borderColor: 'primary.main',
                backgroundColor: alpha(theme.palette.primary.main, 0.12),
                '&:before': {
                  position: 'absolute',
                  left: -8,
                  top: -4,
                  content: "''",
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  border: `solid 1px ${theme.palette.primary.main}`,
                  backgroundColor: 'background.default'
                },
                '> *': {
                  opacity: 0,
                  height: 0
                }
              }
            },
            '&:not(.indicator)': {
              opacity: 0.5
            },
            '.treeItem > *': {
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }
          },
          '.treeItem': {
            alignItems: 'center',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto auto',
            p: 1.5,
            borderRadius: 1,
            backgroundColor: 'background.default',
            border: `solid 1px ${theme.palette.divider}`,
            boxSizing: 'border-box'
          }
        })}
        className={clsx({
          clone,
          ghost,
          indicator
        })}
        ref={wrapperRef}
        {...props}
      >
        <div className={clsx('treeItem')} ref={ref} style={style}>
          <IconButton {...handleProps}>
            <DragHandleIcon />
          </IconButton>
          <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="subtitle1">{value}</Typography>
              <Typography variant="caption" color="textSecondary">
                {description}
              </Typography>
            </Box>
            {onCollapse && (
              <IconButton onClick={onCollapse} size="small">
                {collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </IconButton>
            )}
          </Box>
          {!clone && onEdit && (
            <IconButton color="primary" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          )}
          {!clone && onRemove && (
            <IconButton onClick={onRemove} color="primary">
              <DeleteIcon />
            </IconButton>
          )}
          {clone && childCount && childCount > 1 ? (
            <Box
              component="span"
              sx={{
                position: 'absolute',
                top: -10,
                right: -10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                color: 'background.default',
                fontWeight: 'bold'
              }}
            >
              {childCount}
            </Box>
          ) : null}
        </div>
      </Box>
    )
  }
)
