import UserIcon from '@mui/icons-material/AccountCircle'
import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {LocaleCode, Timezone, Title} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {routeTo} from '../../../../utils/routes'
import {
  BottomBackButton,
  EntityCard,
  SingleSideNavigationList
} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {TOP_BAR_HEIGHT} from '../../../constants'
import {SecondaryHeader} from '../Header'
import {CenteredLayout} from '../Layout'
import {useCreateUser} from './graphql'
import {
  FormField,
  IUserFormData,
  UserForm,
  userFromUserForm,
  useUserFormAnchors
} from './UserForm'

const CREATE_USER_FORM_ID = 'createUserForm'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    padding: theme.spacing(3, 0)
  }
}))

export const AddUser: React.FC = () => {
  const createUser = useCreateUser()
  const history = useHistory()
  const {t} = useTranslation()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const userFormAnchors = useUserFormAnchors()
  const classes = useStyles()
  const onSubmit = useCallback(
    async (data: IUserFormData) => {
      try {
        setShowBackdrop(true)
        await createUser(
          userFromUserForm(data),
          data.password,
          data.roleIds || [],
          {
            // TODO: supply data once we have them
            eventManagedDivisionIds: [],
            eventManagedVenueIds: []
          }
        )
        history.push(routeTo.admin.users.home())
        addInfoNotification(t('User created'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [addInfoNotification, createUser, history, setShowBackdrop, t]
  )

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Add user')} />}
      footer={
        <Footer>
          <BottomBackButton />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form={CREATE_USER_FORM_ID}
          >
            {t('Add')}
          </Button>
        </Footer>
      }
    >
      <CenteredLayout>
        <SingleSideNavigationList
          items={userFormAnchors}
          top={2 * TOP_BAR_HEIGHT + 30}
        />
        <div className={classes.form}>
          <EntityCard header={t('New user')} Icon={UserIcon} />
          <UserForm
            formId={CREATE_USER_FORM_ID}
            showPasswordField
            onSubmit={onSubmit}
            defaultValues={{
              [FormField.TITLE]: Title.Mr,
              [FormField.FIRST_NAME]: '',
              [FormField.LAST_NAME]: '',
              [FormField.USERNAME]: '',
              [FormField.PASSWORD]: '',
              [FormField.FIRST_NAME]: '',
              [FormField.LAST_NAME]: '',
              [FormField.WORK_EMAIL]: null,
              [FormField.PERSONAL_EMAIL]: null,
              [FormField.PHONE_NUMBER]: null,
              [FormField.MOBIL_PHONE_NUMBER]: null,
              [FormField.DEGREE]: null,
              [FormField.LOCALE_CODE]: LocaleCode.En,
              [FormField.TIMEZONE]: Timezone.Cet,
              [FormField.ROLE_IDS]: []
            }}
          />
        </div>
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
