import {List} from '@mui/material'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TicketTypePricingPropertiesFragment} from '../../../../../../__generated__/schema'
import {useSelector} from '../../../../../../editor/redux'
import {usePricingActions} from '../../../../../../editor/redux/pricing/actions'
import {pricingSelector} from '../../../../../../editor/redux/pricing/selectors'
import {IPricingState} from '../../../../../../editor/redux/pricing/types'
import {ObjectId} from '../../../../../../editor/redux/selection/reducer'
import {selectedObjectsIdsSelector} from '../../../../../../editor/redux/selection/selectors'
import {ChildrenOnEffectiveClientSelected} from '../../../ChildrenOnEffectiveClientSelected'
import {TicketColor, TicketTypeMenuHeader} from './common'
import {useTicketTypeDrawersState} from './drawerContext'

import {TicketSelection} from './TicketSelection'

const useSelectedTicketId = (
  selectedObjectsIds: Array<ObjectId>,
  pricing: IPricingState
) => {
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null)

  useEffect(() => {
    if (!selectedObjectsIds.length || !Object.keys(pricing).length) {
      setSelectedTicketId(null)
      return
    }

    const selectedTicketIdsMap = selectedObjectsIds.reduce(
      (result, objectId) => {
        const ticketId = _.get(pricing[objectId], 'id')
        return ticketId !== null ? {...result, [ticketId]: true} : result
      },
      {}
    )

    const commonTicketTypeId =
      Object.keys(selectedTicketIdsMap).length === 1
        ? parseInt(Object.keys(selectedTicketIdsMap)[0], 10)
        : null

    setSelectedTicketId(commonTicketTypeId)
  }, [
    pricing,
    selectedObjectsIds,
    selectedObjectsIds.length,
    setSelectedTicketId
  ])

  return selectedTicketId
}

interface ITicketTypeSelectionListProps {
  ticketTypes: Array<TicketTypePricingPropertiesFragment>
}

export const TicketTypeSelectionList: React.FC<ITicketTypeSelectionListProps> =
  ({ticketTypes}: ITicketTypeSelectionListProps) => {
    const {t} = useTranslation()

    const pricing = useSelector(pricingSelector)
    const selectedObjectsIds = useSelector(selectedObjectsIdsSelector)

    const {assignTicketType} = usePricingActions()

    const selectedTicketId = useSelectedTicketId(selectedObjectsIds, pricing)

    const {openCreateTicketTypeDrawer} = useTicketTypeDrawersState()

    return (
      <ChildrenOnEffectiveClientSelected>
        <TicketTypeMenuHeader
          label={t('Choose ticket type for selection')}
          onCreateNewTicket={openCreateTicketTypeDrawer}
          hideCreate={false} // We only show this component in draft state
        />
        <List component="nav">
          {ticketTypes.map(({id, color, ...rest}) => (
            <TicketSelection
              key={id}
              {...rest}
              color={color as TicketColor}
              onClick={() => assignTicketType({id, color})}
              checked={id === selectedTicketId}
              highlighted={id === selectedTicketId}
              id={id}
            />
          ))}
        </List>
      </ChildrenOnEffectiveClientSelected>
    )
  }
