import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightUsersQuery,
  PermissionCode,
  WebsitesFilter,
  WebsiteState
} from '../../../../__generated__/schema'
import {useFormatUserName} from '../../../../hooks/formatUserName'
import {useTranslateWebsiteState} from '../../../../hooks/translateWebsiteState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {CartsFilter} from '../components/cartsSearch/types'
import {useGetLightweightClients, useLightweightUsers} from '../graphql'

export const DEFAULT_WEBSITES_FILTER = {
  hasText: undefined
}

type ExtendedWebsitesFilter = WebsitesFilter & {
  _createdAtDaterangeId?: DATERANGE_IDS
  _updatedAtDaterangeId?: DATERANGE_IDS
}

const stripCreatedAtDateFromFilter = (
  filter: ExtendedWebsitesFilter
): CartsFilter =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripUpdatedAtDateFromFilter = (
  filter: ExtendedWebsitesFilter
): CartsFilter =>
  omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAtDaterangeId'])

const stripHelperKeysFromFilter = (
  filter: ExtendedWebsitesFilter
): WebsitesFilter =>
  omit(filter, ['_createdAtDaterangeId', '_updatedAtDaterangeId'])

const mapHasTextToFilter = (
  filter: WebsitesFilter,
  hasText: string | undefined
): WebsitesFilter => ({
  ...filter,
  hasText: hasText || undefined
})

const mapCreatedByUserIdToFilter = (
  filter: WebsitesFilter,
  createdByIds?: number
): WebsitesFilter => ({
  ...filter,
  createdByIds: createdByIds ? [createdByIds] : undefined
})

const mapUpdatedByUserIdToFilter = (
  filter: WebsitesFilter,
  updatedByIds?: number
): WebsitesFilter => ({
  ...filter,
  updatedByIds: updatedByIds ? [updatedByIds] : undefined
})

const mapStateToFilter = (
  filter: WebsitesFilter,
  states?: WebsiteState
): WebsitesFilter => ({
  ...filter,
  states: states ? [states] : undefined
})

const mapClientIdToFilter = (
  filter: WebsitesFilter,
  clientId?: number
): WebsitesFilter => ({...filter, clientId})

const createdAtDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.THIS_WEEK,
  DATERANGE_IDS.THIS_MONTH,
  DATERANGE_IDS.THIS_QUARTER,
  DATERANGE_IDS.LAST_30_DAYS,
  DATERANGE_IDS.LAST_MONTH
]

const updatedAtDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.THIS_WEEK,
  DATERANGE_IDS.LAST_WEEK,
  DATERANGE_IDS.LAST_MONTH
]

interface IWebsitesSearchProps {
  onFilterChange: (filter: WebsitesFilter) => void
}

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateWebsiteState = useTranslateWebsiteState()
  const formatUserName = useFormatUserName()
  const getHasTextFromSearchObject = (filter: WebsitesFilter) =>
    filter.hasText || undefined
  const getStateFromSearchObject = (filter: WebsitesFilter) =>
    filter.states
      ? t('State: {{state}}', {state: translateWebsiteState(filter.states[0])})
      : undefined
  const getCreatedByFromSearchObject = (
    filter: WebsitesFilter,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.createdByIds && filter.createdByIds[0]) {
      const user = users.find((user) => user.id === filter.createdByIds![0])
      return user
        ? t('Created by: {{userName}}', {
            userName: formatUserName(user)
          })
        : t('Created by ID: {{userId}}', {userId: filter.createdByIds[0]})
    }
    return undefined
  }
  const getUpdatedByFromSearchObject = (
    filter: WebsitesFilter,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.updatedByIds && filter.updatedByIds[0]) {
      const user = users.find((user) => user.id === filter.updatedByIds![0])
      return user
        ? t('Updated by: {{userName}}', {
            userName: formatUserName(user)
          })
        : t('Updated by ID: {{userId}}', {userId: filter.updatedByIds[0]})
    }
    return undefined
  }
  const getClientFromSearchObject = (
    filter: WebsitesFilter,
    clients: {id: number; name: string}[]
  ) => {
    if (filter.clientId) {
      const client = clients.find(({id}) => id === filter.clientId)
      return client
        ? t('Client: {{name}}', {name: client.name})
        : t('Client ID: {{id}}', {id: filter.clientId})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getStateFromSearchObject,
    getCreatedByFromSearchObject,
    getUpdatedByFromSearchObject,
    getClientFromSearchObject
  }
}

export const WebsitesSearch: React.FC<IWebsitesSearchProps> = ({
  onFilterChange
}: IWebsitesSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateWebsiteState = useTranslateWebsiteState()
  const formatUserName = useFormatUserName(true)
  const {users} = useLightweightUsers()
  const {clients} = useGetLightweightClients()
  const {
    getHasTextFromSearchObject,
    getStateFromSearchObject,
    getCreatedByFromSearchObject,
    getUpdatedByFromSearchObject,
    getClientFromSearchObject
  } = useGetFieldFromSearchObject()
  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedWebsitesFilter>({
    usedDateranges: createdAtDateRanges,
    dateRangeInputPrefix: t('Created at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: updatedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedUpdatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedWebsitesFilter>({
    usedDateranges: updatedAtDateRanges,
    dateRangeInputPrefix: t('Updated at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.updatedAtFrom || undefined,
      endDateISOString: o.updatedAtTo || undefined,
      id: o._updatedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      updatedAtFrom: input.startDate,
      updatedAtTo: input.endDate,
      _updatedAtDaterangeId: input.id
    })
  })
  const stateOptions: ISelectOption<WebsiteState>[] = [
    WebsiteState.Draft,
    WebsiteState.Published,
    WebsiteState.Deleted
  ].map((state) => ({id: state, label: translateWebsiteState(state)}))
  const userSelectOptions: ISelectOption<number>[] = users.map((user) => ({
    id: user.id,
    label: formatUserName(user)
  }))
  const clientOptions: ISelectOption<number>[] = clients.map(({id, name}) => ({
    id,
    label: name
  }))
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject,
    getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getStringifiedUpdatedAtDateDateRangeFromSearchObject,
    getStateFromSearchObject,
    (filter) => getCreatedByFromSearchObject(filter, users),
    (filter) => getUpdatedByFromSearchObject(filter, users),
    (filter) => getClientFromSearchObject(filter, clients)
  )
  return (
    <Search<WebsitesFilter, ExtendedWebsitesFilter>
      storageKey="WEBSITES"
      placeholder={t('Search for website')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_WEBSITES_FILTER}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow<WebsitesFilter>
            label={t('Created at')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart created at'
            )}
          />
          <AdvancedSearchDaterangeRow<WebsitesFilter>
            label={t('Updated at')}
            daterangeOptions={updatedAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomUpdatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripUpdatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart updated at'
            )}
          />
          <AdvancedSearchSelectRow<WebsitesFilter, WebsiteState>
            value={
              advancedSearchObject.states
                ? advancedSearchObject.states[0]
                : undefined
            }
            label={t('State')}
            options={stateOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapStateToFilter}
          />
          <AdvancedSearchTextRow<WebsitesFilter>
            label={t('Has words')}
            placeholder={t('Enter name or domain')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          {P([PermissionCode.ReadUsers]) && (
            <>
              <AdvancedSearchSelectRow<WebsitesFilter, number>
                label={t('Created by')}
                value={
                  advancedSearchObject.createdByIds
                    ? advancedSearchObject.createdByIds[0]
                    : undefined
                }
                options={userSelectOptions}
                mapSelectValueToSearchObject={mapCreatedByUserIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
              <AdvancedSearchSelectRow<WebsitesFilter, number>
                label={t('Updated by')}
                value={
                  advancedSearchObject.updatedByIds
                    ? advancedSearchObject.updatedByIds[0]
                    : undefined
                }
                options={userSelectOptions}
                mapSelectValueToSearchObject={mapUpdatedByUserIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            </>
          )}
          {P([PermissionCode.ReadClients]) && (
            <AdvancedSearchSelectRow<WebsitesFilter, number>
              label={t('Client')}
              value={advancedSearchObject.clientId || undefined}
              options={clientOptions}
              mapSelectValueToSearchObject={mapClientIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
        </AdvancedSearchBase>
      )}
    />
  )
}
