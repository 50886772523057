import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ProductsFilterInput} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateProductDrawer} from './CreateProductDrawer'
import {ProductsList} from './ProductsList'
import {DEFAULT_PRODUCTS_FILTER_INPUT, ProductsSearch} from './ProductsSearch'

export const ProductsPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] = useState<ProductsFilterInput>(
    DEFAULT_PRODUCTS_FILTER_INPUT
  )
  const {
    state: isCreateProductDrawerOpen,
    setTrue: openCreateProductDrawer,
    setFalse: closeCreateProductDrawer
  } = useBooleanState(false)
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Products')}
          search={<ProductsSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <ProductsList
          searchFilter={searchFilter}
          onCreateProductButtonClick={openCreateProductDrawer}
        />
        <CreateProductDrawer
          isOpen={isCreateProductDrawerOpen}
          onClose={closeCreateProductDrawer}
        />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
