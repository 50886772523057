import React from 'react'
import {RouteProps} from 'react-router'
import {Redirect, Route} from 'react-router-dom'

import {useUserInfo, AuthStatus} from '../../utils/auth'
import {routeTo} from '../../utils/routes'

export const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const {loginStatus} = useUserInfo()

  if (!Component) {
    throw new Error('No component passed to PrivateRoute')
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loginStatus === AuthStatus.LOGGED_IN ? (
          <Component {...props} />
        ) : loginStatus === AuthStatus.LOGGED_OUT ? (
          <Redirect to={{pathname: routeTo.login(), state: props.match.url}} />
        ) : (
          <p>Loading ...</p>
        )
      }
    />
  )
}
