import {useTranslation} from 'react-i18next'
import {
  VoucherTransactionIntentReason,
  VoucherTransactionIntentState,
  VoucherTransactionIntentType
} from '../__generated__/schema'

export const useTranslateVoucherIntentState = () => {
  const {t} = useTranslation()
  const translatedIntents: Record<VoucherTransactionIntentState, string> = {
    [VoucherTransactionIntentState.Pending]: t<string>(
      'VoucherTransactionIntentState->Pending'
    ),
    [VoucherTransactionIntentState.Failed]: t<string>(
      'VoucherTransactionIntentState->Failed'
    ),
    [VoucherTransactionIntentState.Expired]: t<string>(
      'VoucherTransactionIntentState->Expired'
    ),
    [VoucherTransactionIntentState.Canceled]: t<string>(
      'VoucherTransactionIntentState->Canceled'
    ),
    [VoucherTransactionIntentState.Succeeded]: t<string>(
      'VoucherTransactionIntentState->Succeeded'
    )
  }
  return (state: VoucherTransactionIntentState) => translatedIntents[state]
}

export const useTranslateVoucherIntentType = () => {
  const {t} = useTranslation()
  const translatedTypes: Record<VoucherTransactionIntentType, string> = {
    [VoucherTransactionIntentType.Credit]: t<string>(
      'VoucherTransactionIntentType->Credit'
    ),
    [VoucherTransactionIntentType.Debit]: t<string>(
      'VoucherTransactionIntentType->Debit'
    )
  }
  return (type: VoucherTransactionIntentType) => translatedTypes[type]
}

export const useTranslateVoucherIntentReason = () => {
  const {t} = useTranslation()
  const translatedReasons: Record<VoucherTransactionIntentReason, string> = {
    [VoucherTransactionIntentReason.Charge]: t<string>(
      'VoucherTransactionIntentReason->Charge'
    ),
    [VoucherTransactionIntentReason.Refund]: t<string>(
      'VoucherTransactionIntentReason->Refund'
    ),
    [VoucherTransactionIntentReason.TopUp]: t<string>(
      'VoucherTransactionIntentReason->TopUp'
    ),
    [VoucherTransactionIntentReason.Reward]: t<string>(
      'VoucherTransactionIntentReason->Reward'
    ),
    [VoucherTransactionIntentReason.Activation]: t<string>(
      'VoucherTransactionIntentReason->Activation'
    ),
    [VoucherTransactionIntentReason.Expiration]: t<string>(
      'VoucherTransactionIntentReason->Expiration'
    ),
    [VoucherTransactionIntentReason.Other]: t<string>(
      'VoucherTransactionIntentReason->Other'
    ),
    [VoucherTransactionIntentReason.Import]: t<string>(
      'VoucherTransactionIntentReason->Import'
    ),
    [VoucherTransactionIntentReason.Export]: t<string>(
      'VoucherTransactionIntentReason->Export'
    )
  }
  return (reason: VoucherTransactionIntentReason) => translatedReasons[reason]
}
