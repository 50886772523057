import {Box, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ApiResource} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useTranslateApiResource} from '../../../../hooks/translateApiResource'
import {
  useIsPositiveInteger,
  useIsStringWithMaxLength,
  useIsValidEmail,
  useIsValidPhone
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormSwitchWithLabels} from '../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormDateInput} from '../../../form/pickers'
import {FormFieldName} from '../../../form/types'
import {ApiGrantField, IApiGrantForm} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IApiGrantFormProps {
  formId: string
  onSubmit: (data: IApiGrantForm) => Promise<void>
  defaultValues?: Partial<IApiGrantForm>
}

export const ApiGrantForm: React.FC<IApiGrantFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IApiGrantFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    unregister,
    setError,
    clearError,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IApiGrantForm>({defaultValues, mode: 'onChange'})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isPositiveInteger = useIsPositiveInteger()
  const isValidPhone = useIsValidPhone()
  const isValidEmail = useIsValidEmail()
  const translateApiResource = useTranslateApiResource()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const [hasGrantedResourcesError, setHasGrantedResourcesError] =
    useState<boolean>(false)
  const selectedGrantedResources = watch(ApiGrantField.GrantedResources)
  const isGrantedResourcesFalse =
    selectedGrantedResources &&
    Object.values(selectedGrantedResources).every((v) => !v)
  const _onSubmit = useCallback(
    async (formData: IApiGrantForm) => {
      if (!isGrantedResourcesFalse) {
        try {
          await onSubmit(formData)
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating API grant'))
        }
      } else {
        setHasGrantedResourcesError(true)
      }
    },
    [defaultErrorHandler, isGrantedResourcesFalse, onSubmit, t]
  )
  useEffect(() => {
    if (!isGrantedResourcesFalse && hasGrantedResourcesError) {
      setHasGrantedResourcesError(false)
    }
  }, [hasGrantedResourcesError, isGrantedResourcesFalse])
  return (
    <StyledForm onSubmit={handleSubmit(_onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IApiGrantForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ApiGrantField.Name}
            key={ApiGrantField.Name}
            label={t('Name')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IApiGrantForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ApiGrantField.Description}
            key={ApiGrantField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength1000,
              required: true
            }}
            fullWidth
            required
            multiline
            rows={3}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormDateInput<IApiGrantForm>
            datePickerProps={{
              label: t<string>('Expiration date'),
              disablePast: true
            }}
            control={control}
            register={register}
            unregister={unregister}
            watch={watch}
            errors={errors}
            clearError={clearError}
            setValue={setValue}
            key={ApiGrantField.ExpiresAt}
            name={ApiGrantField.ExpiresAt}
            setError={setError}
            validationOptions={{required: true}}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IApiGrantForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ApiGrantField.MaxRequestsPerMinute}
            key={ApiGrantField.MaxRequestsPerMinute}
            label={t('Max requests per minute')}
            validationOptions={{
              validate: isPositiveInteger
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IApiGrantForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ApiGrantField.MaxRequestsPerHour}
            key={ApiGrantField.MaxRequestsPerHour}
            label={t('Max requests per hour')}
            validationOptions={{
              validate: isPositiveInteger
            }}
            fullWidth
          />
        ]}
      />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1.5}}>
        <Typography variant="subtitle1">{t('Business contact')}</Typography>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.BusinessContactName}
              key={ApiGrantField.BusinessContactName}
              label={t('Name')}
              validationOptions={{
                validate: isStringWithMaxLength255,
                required: true
              }}
              fullWidth
              required
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.BusinessContactRole}
              key={ApiGrantField.BusinessContactRole}
              label={t('Role')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.BusinessContactPhone}
              key={ApiGrantField.BusinessContactPhone}
              label={t('Phone')}
              validationOptions={{
                validate: isValidPhone
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.BusinessContactEmail}
              key={ApiGrantField.BusinessContactEmail}
              label={t('Email')}
              validationOptions={{
                validate: isValidEmail,
                required: true
              }}
              fullWidth
              required
            />
          ]}
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1.5}}>
        <Typography variant="subtitle1">{t('Technical contact')}</Typography>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.TechnicalContactName}
              key={ApiGrantField.TechnicalContactName}
              label={t('Name')}
              validationOptions={{
                validate: isStringWithMaxLength255,
                required: true
              }}
              fullWidth
              required
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.TechnicalContactRole}
              key={ApiGrantField.TechnicalContactRole}
              label={t('Role')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.TechnicalContactPhone}
              key={ApiGrantField.TechnicalContactPhone}
              label={t('Phone')}
              validationOptions={{
                validate: isValidPhone
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IApiGrantForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ApiGrantField.TechnicalContactEmail}
              key={ApiGrantField.TechnicalContactEmail}
              label={t('Email')}
              validationOptions={{
                validate: isValidEmail,
                required: true
              }}
              fullWidth
              required
            />
          ]}
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1.5}}>
        <Box>
          <Typography variant="subtitle1">{t('Granted resources')}</Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{color: hasGrantedResourcesError ? 'error.main' : 'inherit'}}
          >
            {hasGrantedResourcesError
              ? t('Select at least one resource')
              : t('*Required')}
          </Typography>
          {Object.values(ApiResource).map((resource, index) => (
            <InputRow
              key={`${ApiGrantField.GrantedResources}[${resource}]-${index}`}
              nodes={[
                <FormSwitchWithLabels<IApiGrantForm>
                  name={
                    `${ApiGrantField.GrantedResources}[${resource}]` as FormFieldName<IApiGrantForm>
                  }
                  key={`${ApiGrantField.GrantedResources}[${resource}]`}
                  primaryLabel={translateApiResource(resource)}
                  control={control}
                  watch={watch}
                  defaultValue={
                    defaultValues?.grantedResources
                      ? defaultValues?.grantedResources[resource]
                      : false
                  }
                  secondaryLabel=""
                  enabledText=""
                  disabledText=""
                />
              ]}
            />
          ))}
        </Box>
      </Box>
    </StyledForm>
  )
}
