import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {Chip, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AuditoriumPreviewFieldsFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {
  CapacityIndicator,
  capacityIndicatorSxProps
} from '../../../../common/CapacityIndicator'
import {Tooltip} from '../../../../common/Tooltip'
import {VerticalDivider} from '../../../../common/VerticalDivider'

const useStyles = makeStyles<Theme>((theme) => ({
  subHeader: {
    background: theme.palette.background.paper,
    borderBottom: `solid ${theme.palette.divider} 1px`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  leftSubHeaderBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 3)
  },
  rightSubHeaderBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    gap: theme.spacing(1)
  },
  startingPrice: {
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1)
  }
}))

interface ISubHeaderProps {
  event: AuditoriumPreviewFieldsFragment
  className?: string
}

export const SubHeader: React.FC<ISubHeaderProps> = ({
  event,
  className
}: ISubHeaderProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translatePrice = useTranslateEffectiveClientPrice()
  const history = useHistory()
  const handleInfoClick = useCallback(() => {
    history.push(
      routeTo.admin.cashDesk.eventAuditoriumPreviewWithEventInfo(event.id)
    )
  }, [event.id, history])
  return (
    <div className={cn(classes.subHeader, className)}>
      <div className={classes.leftSubHeaderBox}>
        {[
          event.formatCode && translateShowFormatAbbreviation(event.formatCode),
          event.soundMixCode &&
            translateShowSoundMixAbbreviation(event.soundMixCode),
          event.versionCode &&
            translateShowVersionAbbreviation(event.versionCode),
          event.ageClassificationCode &&
            translateAgeClassificationAbbreviation(event.ageClassificationCode),
          event.auditorium.name
        ]
          .filter(Boolean)
          .map((text, index, array) => [
            <Typography key={index} variant="body2">
              {text}
            </Typography>,
            index !== array.length - 1 && (
              <VerticalDivider height="1.2em" key={`divider-${index}`} />
            )
          ])}
        {event.marketingLabel && (
          <>
            <VerticalDivider height="1.2em" />
            <Chip size="small" label={event.marketingLabel.name} />
          </>
        )}
      </div>
      <div className={classes.rightSubHeaderBox}>
        <CapacityIndicator
          tooltipTitle={t('Available')}
          countLabel={event.availableSeatsCount.toString()}
          customIcon={<PersonOutlineIcon fontSize="inherit" />}
        />
        <CapacityIndicator
          tooltipTitle={t('Reserved')}
          colorSx={capacityIndicatorSxProps.reservedSpaces}
          countLabel={event.reservedSeatsCount.toString()}
        />
        <CapacityIndicator
          tooltipTitle={t('Sold')}
          colorSx={capacityIndicatorSxProps.soldSpaces}
          countLabel={event.soldSeatsCount.toString()}
        />
        <VerticalDivider height="1.2em" />
        <CapacityIndicator
          tooltipTitle={t('Capacity')}
          countLabel={event.auditoriumLayout.capacity.toString()}
          customIcon={<PeopleOutlineIcon fontSize="inherit" />}
        />

        <Typography variant="overline" className={classes.startingPrice}>
          {t('From {{startingPrice}}', {
            startingPrice: translatePrice(event.activePricing.minPrice)
          })}
        </Typography>
        {event.reservedSeatsCount > 0 && P([PermissionCode.ReadReservations]) && (
          <Tooltip title={t<string>('Show event reservations')}>
            <IconButton
              onClick={() =>
                history.push(routeTo.admin.cashDesk.eventReservations(event.id))
              }
            >
              <BookmarkIcon />
            </IconButton>
          </Tooltip>
        )}
        {event.soldSeatsCount > 0 && P([PermissionCode.ReadSales]) && (
          <Tooltip title={t<string>('Show event sales')}>
            <IconButton
              onClick={() =>
                history.push(routeTo.admin.cashDesk.eventSales(event.id))
              }
            >
              <AccountBalanceWalletIcon />
            </IconButton>
          </Tooltip>
        )}
        {P([PermissionCode.ReadEvent]) && (
          <Tooltip title={t<string>('Event auditorium preview info')}>
            <IconButton onClick={handleInfoClick}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
