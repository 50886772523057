import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {ModifierKeysActions, ModifierKey} from './reducer'

export const useModifierKeysActions = () => {
  const dispatch = useDispatch()

  const addModifierKey = useCallback(
    (key: ModifierKey) => {
      dispatch({type: ModifierKeysActions.ADD_MODIFIER_KEY, payload: key})
    },
    [dispatch]
  )

  const removeModifierKey = useCallback(
    (key: ModifierKey) => {
      dispatch({type: ModifierKeysActions.REMOVE_MODIFIER_KEY, payload: key})
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      addModifierKey,
      removeModifierKey
    }),
    [addModifierKey, removeModifierKey]
  )

  return actions
}
