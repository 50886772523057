import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {sortBy} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {NarrowSalePropertiesFragment} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {DialogTitleWithCloseButton} from '../../../common/DialogTitleWithCloseButton'
import {ListOfItemsSeparatedByDividers} from '../../../common/ListOfItemsSeparatedByDividers'
import {OutlinedInputWithCancelAdornment} from '../../../common/OutlinedInputWithCancelAdornment'
import {CheckboxState, Item} from '../components/cartPreviewDrawer/Item'
import {isTicketItemPropertiesFragment} from '../types'
import {useAddItemsToClaim} from './graphql'

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    height: '92vh'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: 0
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  topBar: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    gap: theme.spacing(0.5)
  },
  list: {
    padding: theme.spacing(0, 2, 1, 2)
  },
  search: {
    padding: 0
  },
  noItems: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'center',
    alignItems: 'center'
  }
}))

interface IAddItemsDialogProps {
  sale: NarrowSalePropertiesFragment
  claimId: number

  onClose(): void
}

export const AddItemsDialog: React.FC<IAddItemsDialogProps> = ({
  sale,
  claimId,
  onClose
}: IAddItemsDialogProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const items = sortBy(sale.items || [], 'id')
  const addItemsToClaim = useAddItemsToClaim()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([])
  const [search, setSearch] = useState<string>('')
  const selectableItemsIds = items
    .filter((item) => !item.claim)
    .map(({id}) => id)
  const filteredItems = items.filter((item) =>
    isTicketItemPropertiesFragment(item)
      ? item.passCode && item.passCode.indexOf(search.toUpperCase()) > -1
      : item.name.toLowerCase().includes(search.toLowerCase())
  )
  const createItemCheckboxClickHandler = useCallback(
    (itemId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedItemIds((ids) =>
        ids.includes(itemId)
          ? ids.filter((id) => id !== itemId)
          : [...ids, itemId]
      )
    },
    []
  )

  const handleOnAddButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await addItemsToClaim({claimId, itemIds: selectedItemIds})
    } catch (e) {
      defaultErrorHandler(e, t('Error while adding items to claim'))
    } finally {
      setShowBackdrop(false)
      onClose()
    }
  }, [
    addItemsToClaim,
    claimId,
    defaultErrorHandler,
    onClose,
    selectedItemIds,
    setShowBackdrop,
    t
  ])

  return (
    <Dialog
      classes={{paper: classes.paper}}
      scroll="paper"
      maxWidth="xs"
      fullWidth
      open
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add items to claim')}
      </DialogTitleWithCloseButton>
      <DialogContent className={classes.dialogContent} dividers>
        <div className={classes.topBar}>
          <OutlinedInputWithCancelAdornment
            onCancelClick={() => setSearch('')}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
              placeholder: t('Search for ticket number or product name')
            }}
            className={classes.search}
          />
          <Typography variant="overline" color="textSecondary">
            {t('Select items from sale #{{saleNumber}}', {saleNumber: sale.id})}
          </Typography>
        </div>
        {filteredItems.length > 0 ? (
          <ListOfItemsSeparatedByDividers className={classes.list} isFullWidth>
            {filteredItems.map((item) => (
              <Item
                key={item.id}
                item={item}
                onCheckboxClick={
                  !item.claim
                    ? createItemCheckboxClickHandler(item.id)
                    : undefined
                }
                checkboxProps={{
                  checked:
                    !!selectedItemIds.find(
                      (selectedItem) => selectedItem === item.id
                    ) || false
                }}
                checkboxState={
                  item.claim ? CheckboxState.Hidden : CheckboxState.Visible
                }
                disabled={!!item.claim}
                claimState={item.claim?.state}
                currency={sale.client.currency}
              />
            ))}
          </ListOfItemsSeparatedByDividers>
        ) : (
          <Typography variant="body2" className={classes.noItems}>
            {t('No items to show')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Typography variant="subtitle1" color="textPrimary">
          {t('Selected: {{selected}} of {{available}}', {
            selected: selectedItemIds.length,
            available: selectableItemsIds.length
          })}
        </Typography>
        <Button
          onClick={handleOnAddButtonClick}
          color="primary"
          variant="contained"
          disabled={selectedItemIds.length === 0}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
