import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {AdmissionTypesFilter} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {AdmissionTypeForm} from './AdmissionTypeForm'
import {useCreateAdmissionType} from './graphql'
import {AdmissionTypeFormField, IAdmissionTypeForm} from './types'
import {transformCreateAdmissionTypeDataToInput} from './utils'

const CREATE_ADMISSION_TYPE_FORM_ID = 'createAdmissionTypeForm'

interface ICreateAdmissionTypeDrawerProps {
  isOpen: boolean
  onClose: () => void
  searchFilter: AdmissionTypesFilter
}

export const CreateAdmissionTypeDrawer: React.FC<ICreateAdmissionTypeDrawerProps> =
  ({isOpen, onClose, searchFilter}: ICreateAdmissionTypeDrawerProps) => {
    const {t} = useTranslation()
    const createAdmissionType = useCreateAdmissionType()
    const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const methods = useForm<IAdmissionTypeForm>({
      defaultValues: {
        [AdmissionTypeFormField.CapacityDecreaseCount]: 1,
        [AdmissionTypeFormField.StartingQuantity]: 1,
        [AdmissionTypeFormField.Color]: '#999999',
        [AdmissionTypeFormField.Icon]: null
      }
    })
    const handleSubmit = useCallback(
      async (data: IAdmissionTypeForm) => {
        try {
          setShowBackdrop(true)
          await createAdmissionType(
            transformCreateAdmissionTypeDataToInput(data),
            searchFilter
          )
          addInfoNotification(t('Admission type created'))
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating admission type'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createAdmissionType,
        defaultErrorHandler,
        onClose,
        searchFilter,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create admission type')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_ADMISSION_TYPE_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <FormContext {...methods}>
            <AdmissionTypeForm
              onSubmit={handleSubmit}
              formId={CREATE_ADMISSION_TYPE_FORM_ID}
            />
          </FormContext>
        </DrawerTemplate>
      </Drawer>
    )
  }
