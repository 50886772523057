import {Dialog, DialogActions, DialogContent} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SaveButton} from '../Buttons'
import {DialogTitleWithCloseButton} from '../DialogTitleWithCloseButton'
import {AddIdItemsCheckGroup} from './AddIdItemsCheckGroup'
import {IIdListItem} from './IdListItem'
import {ListItemID} from './types'

interface IAddItemsDialogProps<ID extends ListItemID> {
  isOpen: boolean
  onClose: () => void
  selectedIds: ID[]
  saveIdsToFilter: (ids: ID[]) => void
  getOptions: (hasText?: string) => Promise<IIdListItem<ID>[]>
  getMoreOptions?: (hasText?: string) => Promise<IIdListItem<ID>[]>
  isFilterTextInputHidden?: boolean
}

export const AddItemsDialog = <ID extends ListItemID = number>({
  isOpen,
  onClose,
  selectedIds,
  saveIdsToFilter,
  getOptions,
  getMoreOptions,
  isFilterTextInputHidden
}: IAddItemsDialogProps<ID>) => {
  const {t} = useTranslation()
  const [checkedIds, setCheckedIds] = useState<ID[]>([])
  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Select items')} X
      </DialogTitleWithCloseButton>
      <DialogContent dividers sx={isFilterTextInputHidden ? {} : {pt: 0}}>
        <AddIdItemsCheckGroup<ID>
          getOptions={getOptions}
          getMoreOptions={getMoreOptions}
          selectedIds={selectedIds}
          checkedIds={checkedIds}
          isFilterTextInputHidden={isFilterTextInputHidden}
          onCheck={(id: ID) => {
            setCheckedIds((prevSelectedIds) => [...prevSelectedIds, id])
          }}
          onUnCheck={(id: ID) => {
            setCheckedIds((prevSelectedIds) =>
              prevSelectedIds.filter((i) => i !== id)
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <SaveButton
          onClick={() => {
            saveIdsToFilter(checkedIds)
            onClose()
            setCheckedIds([])
          }}
          disabled={checkedIds.length === 0}
        >
          {t('Done')}
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}
