export enum MarketingLabelState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum MarketingLabelFormField {
  Name = 'name',
  Label = 'label',
  IsActive = 'isActive'
}

export interface IMarketingLabelForm {
  [MarketingLabelFormField.Name]: string
  [MarketingLabelFormField.Label]?: string
  [MarketingLabelFormField.IsActive]: MarketingLabelState
}
