import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {AddItemsDialog} from './AddItemsDialog'
import {IIdListItem} from './IdListItem'
import {ListSection} from './ListSection'
import {
  GeneralFilterActions,
  GeneralFilterActionTypes,
  GeneralFilterState
} from './reducer'
import {ListItemID, UArrayKeys} from './types'
import {getListOfIdsFromFilter} from './utils'

interface IIdsListSectionProps<T extends object, ID extends ListItemID> {
  getOptions: (hasText?: string) => Promise<IIdListItem<ID>[]>
  getMoreOptions?: (hasText?: string) => Promise<IIdListItem<ID>[]>
  getOptionsByIds: (itemIds: ID[]) => Promise<IIdListItem<ID>[]>
  generalFilter: GeneralFilterState<T>
  dispatch: React.Dispatch<GeneralFilterActions<T>>
  filterKey: UArrayKeys<T, ID>
  label: string
  isFilterTextInputHidden?: boolean
}

export const IdsListSection = <
  T extends object,
  ID extends ListItemID = number
>({
  filterKey,
  generalFilter,
  dispatch,
  label,
  getOptions,
  getMoreOptions,
  getOptionsByIds,
  isFilterTextInputHidden
}: IIdsListSectionProps<T, ID>) => {
  const {t} = useTranslation()
  const {filter} = generalFilter
  const idsFromFilter = getListOfIdsFromFilter(filter, filterKey) ?? []
  const [optionsByIds, setOptionsByIds] = useState<IIdListItem<ID>[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const {customErrorHandler} = useMutationAssistanceHooks()
  useEffect(() => {
    let isActive = true
    setIsLoading(true)
    getOptionsByIds(idsFromFilter)
      .then((data: IIdListItem<ID>[]) => {
        if (isActive) {
          setOptionsByIds(data)
        }
      })
      .catch((e) => {
        if (isActive) {
          customErrorHandler(e, {
            title: t('Loading more data failed'),
            contentText: '',
            onConfirm: () => {
              window.location.reload()
            },
            confirmButtonLabel: t('Reload')
          })
        }
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false)
        }
      })
    return () => {
      isActive = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOptionsByIds, JSON.stringify(idsFromFilter), customErrorHandler, t])
  const handleDeleteOption = useCallback(
    (id: ID) => {
      dispatch({
        type: GeneralFilterActionTypes.RemoveIdFromListOfIds,
        payload: {
          key: filterKey,
          value: id
        }
      })
    },
    [filterKey, dispatch]
  )
  const [isAddIdItemsDialogOpen, setIsAddIdItemsDialogOpen] = useState(false)
  return (
    <>
      <ListSection<ID>
        sx={{px: 2, py: 1}}
        isLoading={isLoading}
        label={label}
        listItemIDs={idsFromFilter}
        onAddButtonClick={() => {
          setIsAddIdItemsDialogOpen(true)
        }}
        onDeleteButtonClick={() => {
          dispatch({
            type: GeneralFilterActionTypes.RemoveKey,
            payload: filterKey
          })
        }}
        onDeleteOptionClick={handleDeleteOption}
        optionsByIds={optionsByIds}
      />
      <AddItemsDialog<ID>
        isOpen={isAddIdItemsDialogOpen}
        onClose={() => {
          setIsAddIdItemsDialogOpen(false)
        }}
        getOptions={getOptions}
        getMoreOptions={getMoreOptions}
        isFilterTextInputHidden={isFilterTextInputHidden}
        saveIdsToFilter={(checkedIds: ID[]) => {
          dispatch({
            type: GeneralFilterActionTypes.AddKeyWithListIds,
            payload: {
              key: filterKey,
              values: checkedIds
            }
          })
        }}
        selectedIds={
          getListOfIdsFromFilter(generalFilter.filter, filterKey) ?? []
        }
      />
    </>
  )
}
