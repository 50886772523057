import {QueryHookOptions, useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AdmissionRateInput,
  AdmissionRateQuery,
  AdmissionRateQueryVariables,
  AdmissionRatesFilter,
  AdmissionRatesQuery,
  AdmissionRatesQueryVariables,
  AssignAccessZoneToAdmissionRateTypeMutation,
  AssignAccessZoneToAdmissionRateTypeMutationVariables,
  CopyAdmissionRateMutation,
  CopyAdmissionRateMutationVariables,
  CreateAdmissionRateMutation,
  CreateAdmissionRateMutationVariables,
  CreateTourMutation,
  CreateTourMutationVariables,
  DeleteAdmissionRateMutation,
  DeleteAdmissionRateMutationVariables,
  LightweightAdmissionTypesQuery,
  LightweightAdmissionTypesQueryVariables,
  RemoveAccessZoneFromAdmissionRateTypeMutation,
  RemoveAccessZoneFromAdmissionRateTypeMutationVariables,
  TourInput,
  TourQuery,
  TourQueryVariables,
  ToursQuery,
  ToursQueryVariables,
  TourTimeSlotsQuery,
  TourTimeSlotsQueryVariables,
  UpdateAdmissionRateAssignmentsMutation,
  UpdateAdmissionRateAssignmentsMutationVariables,
  UpdateAdmissionRateMutation,
  UpdateAdmissionRateMutationVariables,
  UpdateDraftAdmissionRateAssignmentsMutation,
  UpdateDraftAdmissionRateAssignmentsMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const ADMISSION_RATE_FIELDS = gql`
  ${USER_FIELDS}
  fragment AdmissionRateFields on AdmissionRate {
    id
    name
    abbreviation
    state
    description
    color
    createdAt
    createdBy {
      ...UserFields
    }
    updatedAt
    updatedBy {
      ...UserFields
    }
  }
`

const NARROW_ADMISSION_TYPE_FIELDS = gql`
  fragment NarrowAdmissionTypeFields on AdmissionType {
    id
    color
    icon
    name
    startingQuantity
    capacityDecreaseCount
  }
`

const TOURS = gql`
  ${PAGINATION_FRAGMENT}
  query Tours($filter: ToursFilter, $paginationInput: PaginationInput!) {
    tours(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        division {
          id
          name
        }
        state
        internalNote
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useTours = (variables: ToursQueryVariables) =>
  useQuery<ToursQuery, ToursQueryVariables>(TOURS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_TOUR = gql`
  mutation CreateTour($input: TourInput!) {
    createTour(input: $input) {
      id
    }
  }
`

export const useCreateTour = () => {
  const [createTour] =
    useMutation<CreateTourMutation, CreateTourMutationVariables>(CREATE_TOUR)
  return useCallback(
    (input: TourInput) => createTour({variables: {input}}),
    [createTour]
  )
}

const TOUR = gql`
  query Tour($id: PositiveInt!) {
    tour(id: $id) {
      id
      name
      divisionId
      venueId
      show {
        id
        typeCode
      }
    }
  }
`

export const useGetTour = (id: number) =>
  useQuery<TourQuery, TourQueryVariables>(TOUR, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const ADMISSION_RATES = gql`
  ${PAGINATION_FRAGMENT}
  query AdmissionRates(
    $filter: AdmissionRatesFilter
    $paginationInput: PaginationInput!
  ) {
    admissionRates(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        color
        name
        state
        abbreviation
        admissionTypesCount
        description
        tour {
          id
          name
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useAdmissionRates = (filter?: AdmissionRatesFilter) =>
  useQuery<AdmissionRatesQuery, AdmissionRatesQueryVariables>(ADMISSION_RATES, {
    variables: {filter, paginationInput: {offset: 0, limit: 200}},
    fetchPolicy: 'network-only'
  })

const CREATE_ADMISSION_RATE = gql`
  mutation CreateAdmissionRate($input: AdmissionRateInput!) {
    createAdmissionRate(input: $input) {
      id
    }
  }
`

export const useCreateAdmissionRate = () => {
  const [createAdmissionRate] = useMutation<
    CreateAdmissionRateMutation,
    CreateAdmissionRateMutationVariables
  >(CREATE_ADMISSION_RATE)
  return useCallback(
    (input: AdmissionRateInput) => createAdmissionRate({variables: {input}}),
    [createAdmissionRate]
  )
}

const ADMISSION_RATE = gql`
  ${ADMISSION_RATE_FIELDS}
  ${NARROW_ADMISSION_TYPE_FIELDS}
  query AdmissionRate($id: PositiveInt!) {
    admissionRate(id: $id) {
      ...AdmissionRateFields
      admissionTypesAssignments {
        id
        admissionTypeId
        retailPrice
        retailVatRate
        eCommercePrice
        eCommerceVatRate
        enabledOnRetail
        enabledOnECommerce
        hasEnabledDiscountsOnRetail
        hasEnabledDiscountsOnECommerce
        limitPerTimeSlot
        triggerCheckNotification
        admissionType {
          ...NarrowAdmissionTypeFields
        }
        accessZones {
          id
        }
      }
    }
  }
`

export const useAdmissionRate = (id: number) =>
  useQuery<AdmissionRateQuery, AdmissionRateQueryVariables>(ADMISSION_RATE, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const UPDATE_ADMISSION_RATE = gql`
  ${ADMISSION_RATE_FIELDS}
  mutation UpdateAdmissionRate(
    $id: PositiveInt!
    $input: UpdateAdmissionRateInput!
  ) {
    updateAdmissionRate(id: $id, input: $input) {
      ...AdmissionRateFields
    }
  }
`

export const useUpdateAdmissionRate = () => {
  const [updateAdmissionRate] = useMutation<
    UpdateAdmissionRateMutation,
    UpdateAdmissionRateMutationVariables
  >(UPDATE_ADMISSION_RATE)
  return useCallback(
    (variables: UpdateAdmissionRateMutationVariables) =>
      updateAdmissionRate({variables}),
    [updateAdmissionRate]
  )
}

const DELETE_ADMISSION_RATE = gql`
  mutation DeleteAdmissionRate($id: PositiveInt!) {
    deleteAdmissionRate(id: $id)
  }
`

export const useDeleteAdmissionRate = () => {
  const [deleteAdmissionRate] = useMutation<
    DeleteAdmissionRateMutation,
    DeleteAdmissionRateMutationVariables
  >(DELETE_ADMISSION_RATE)
  return useCallback(
    (id: number) => deleteAdmissionRate({variables: {id}}),
    [deleteAdmissionRate]
  )
}

const LIGHTWEIGHT_ADMISSION_TYPES = gql`
  ${PAGINATION_FRAGMENT}
  ${NARROW_ADMISSION_TYPE_FIELDS}
  query LightweightAdmissionTypes(
    $filter: AdmissionTypesFilter
    $paginationInput: PaginationInput!
  ) {
    admissionTypes(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowAdmissionTypeFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useLightweightAdmissionTypes = (
  options: QueryHookOptions<
    LightweightAdmissionTypesQuery,
    LightweightAdmissionTypesQueryVariables
  >
) =>
  useQuery<
    LightweightAdmissionTypesQuery,
    LightweightAdmissionTypesQueryVariables
  >(LIGHTWEIGHT_ADMISSION_TYPES, options)

const UPDATE_DRAFT_ADMISSION_RATE_ASSIGNMENTS = gql`
  ${ADMISSION_RATE_FIELDS}
  ${NARROW_ADMISSION_TYPE_FIELDS}
  mutation UpdateDraftAdmissionRateAssignments(
    $admissionRateId: PositiveInt!
    $assignmentInputs: [DraftAdmissionRateAssignmentsInput!]!
  ) {
    updateDraftAdmissionRateAssignments(
      admissionRateId: $admissionRateId
      assignmentInputs: $assignmentInputs
    ) {
      ...AdmissionRateFields
      admissionTypesAssignments {
        id
        admissionTypeId
        retailPrice
        retailVatRate
        eCommercePrice
        eCommerceVatRate
        enabledOnRetail
        enabledOnECommerce
        hasEnabledDiscountsOnRetail
        hasEnabledDiscountsOnECommerce
        limitPerTimeSlot
        triggerCheckNotification
        admissionType {
          ...NarrowAdmissionTypeFields
        }
      }
    }
  }
`

export const useUpdateDraftAdmissionRateAssignments = () => {
  const [updateDraftAdmissionRateAssignments] = useMutation<
    UpdateDraftAdmissionRateAssignmentsMutation,
    UpdateDraftAdmissionRateAssignmentsMutationVariables
  >(UPDATE_DRAFT_ADMISSION_RATE_ASSIGNMENTS)
  return useCallback(
    (variables: UpdateDraftAdmissionRateAssignmentsMutationVariables) =>
      updateDraftAdmissionRateAssignments({variables}),
    [updateDraftAdmissionRateAssignments]
  )
}

const UPDATE_ADMISSION_RATE_ASSIGNMENTS = gql`
  ${ADMISSION_RATE_FIELDS}
  ${NARROW_ADMISSION_TYPE_FIELDS}
  mutation UpdateAdmissionRateAssignments(
    $admissionRateId: PositiveInt!
    $assignmentInputs: [AdmissionRateAssignmentsInput!]!
  ) {
    updateAdmissionRateAssignments(
      admissionRateId: $admissionRateId
      assignmentInputs: $assignmentInputs
    ) {
      ...AdmissionRateFields
      admissionTypesAssignments {
        id
        admissionTypeId
        retailPrice
        retailVatRate
        eCommercePrice
        eCommerceVatRate
        enabledOnRetail
        enabledOnECommerce
        hasEnabledDiscountsOnRetail
        hasEnabledDiscountsOnECommerce
        limitPerTimeSlot
        triggerCheckNotification
        admissionType {
          ...NarrowAdmissionTypeFields
        }
      }
    }
  }
`

export const useUpdateAdmissionRateAssignments = () => {
  const [updateAdmissionRateAssignments] = useMutation<
    UpdateAdmissionRateAssignmentsMutation,
    UpdateAdmissionRateAssignmentsMutationVariables
  >(UPDATE_ADMISSION_RATE_ASSIGNMENTS)
  return useCallback(
    (variables: UpdateAdmissionRateAssignmentsMutationVariables) =>
      updateAdmissionRateAssignments({variables}),
    [updateAdmissionRateAssignments]
  )
}

const TOUR_TIME_SLOTS = gql`
  ${PAGINATION_FRAGMENT}
  ${USER_FIELDS}
  query TourTimeSlots(
    $filter: TourTimeSlotsFilter
    $paginationInput: PaginationInput!
  ) {
    tourTimeSlots(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        startsAt
        retailSaleStartsAt
        retailSaleEndsAt
        retailReservationStartsAt
        retailReservationEndsAt
        eCommerceSaleStartsAt
        eCommerceSaleEndsAt
        eCommerceReservationStartsAt
        eCommerceReservationEndsAt
        duration
        state
        retailAttendeesLimit
        eCommerceAttendeesLimit
        eCommerceOrderAttendeesLimit
        admissionRate {
          id
          name
          color
        }
        versionCode
        guide {
          ...UserFields
        }
        isRetailSaleActive
        isRetailReservationActive
        isECommerceSaleActive
        isECommerceReservationActive
        showOnWebsiteAndApi
        ageClassificationCode
        venue {
          id
          name
        }
        costCenter {
          id
          name
        }
        marketingLabel {
          id
          name
        }
        eventCategory {
          id
          name
        }
        ticketNote
        updatedAt
        updatedBy {
          ...UserFields
        }
        createdAt
        createdBy {
          ...UserFields
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useTourTimeSlots = (variables: TourTimeSlotsQueryVariables) =>
  useQuery<TourTimeSlotsQuery, TourTimeSlotsQueryVariables>(TOUR_TIME_SLOTS, {
    variables,
    fetchPolicy: 'network-only'
  })

const COPY_ADMISSION_RATE = gql`
  mutation CopyAdmissionRate($input: CopyAdmissionRateInput!) {
    copyAdmissionRate(input: $input) {
      id
      tourId
    }
  }
`

export const useCopyAdmissionRate = () => {
  const [copyAdmissionRate] =
    useMutation<CopyAdmissionRateMutation, CopyAdmissionRateMutationVariables>(
      COPY_ADMISSION_RATE
    )
  return useCallback(
    (variables: CopyAdmissionRateMutationVariables) =>
      copyAdmissionRate({variables}),
    [copyAdmissionRate]
  )
}

export const DIVISIONS_WITH_SETTINGS = gql`
  query DivisionsWithSettings {
    divisions {
      id
      name
      state
      onlinePurchaseActive
      onlineReservationActive
      posPurchaseActive
      posReservationActive
      gateOpensType
      gateOpensValue
      gateClosesType
      gateClosesValue
      salesEndWebValue
      salesEndWebType
      reservationEndCashDeskType
      reservationEndCashDeskValue
      reservationEndWebType
      reservationEndWebValue
      onlineCreateReservationEndValue
      onlineCreateReservationEndType
      posCreateReservationEndValue
      posCreateReservationEndType
      salesEndCashDeskType
      salesEndCashDeskValue
    }
  }
`

export const LIGHTWEIGHT_ACCESS_ZONES = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightAccessZones(
    $filter: AccessZonesFilter
    $paginationInput: PaginationInput!
  ) {
    accessZones(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        description
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

const ASSIGN_ACCESS_ZONE_TO_ADMISSION_RATE_TYPE = gql`
  mutation AssignAccessZoneToAdmissionRateType(
    $admissionTypeToRateId: PositiveInt!
    $accessZoneIds: [PositiveInt!]!
  ) {
    assignAccessZoneToAdmissionRateType(
      admissionTypeToRateId: $admissionTypeToRateId
      accessZoneIds: $accessZoneIds
    ) {
      id
    }
  }
`

export const useAssignAccessZoneToAdmissionRateType = () => {
  const [assignAccessZoneToAdmissionRateType] = useMutation<
    AssignAccessZoneToAdmissionRateTypeMutation,
    AssignAccessZoneToAdmissionRateTypeMutationVariables
  >(ASSIGN_ACCESS_ZONE_TO_ADMISSION_RATE_TYPE)
  return useCallback(
    (variables: AssignAccessZoneToAdmissionRateTypeMutationVariables) =>
      assignAccessZoneToAdmissionRateType({variables}),
    [assignAccessZoneToAdmissionRateType]
  )
}

const REMOVE_ACCESS_ZONE_FROM_ADMISSION_RATE_TYPE = gql`
  mutation RemoveAccessZoneFromAdmissionRateType(
    $admissionTypeToRateId: PositiveInt!
    $accessZoneIds: [PositiveInt!]!
  ) {
    removeAccessZoneFromAdmissionRateType(
      admissionTypeToRateId: $admissionTypeToRateId
      accessZoneIds: $accessZoneIds
    ) {
      id
    }
  }
`

export const useRemoveAccessZoneFromAdmissionRateType = () => {
  const [removeAccessZoneFromAdmissionRateType] = useMutation<
    RemoveAccessZoneFromAdmissionRateTypeMutation,
    RemoveAccessZoneFromAdmissionRateTypeMutationVariables
  >(REMOVE_ACCESS_ZONE_FROM_ADMISSION_RATE_TYPE)
  return useCallback(
    (variables: RemoveAccessZoneFromAdmissionRateTypeMutationVariables) =>
      removeAccessZoneFromAdmissionRateType({variables}),
    [removeAccessZoneFromAdmissionRateType]
  )
}
