import dayjs from 'dayjs'
import {TFunction} from 'i18next'
import {flatten, groupBy, isNull, uniqueId} from 'lodash'
import omit from 'lodash/omit'
import {v4 as uuid} from 'uuid'

import {
  ClientShowImagePropertiesFragment,
  ClientShowInput,
  DetailClientShowPropertiesFragment,
  DetailLibraryShowPropertiesFragment,
  LibraryShowImagePropertiesFragment,
  LibraryShowInput,
  LocaleCode,
  ShowCrewRoleCode,
  ShowImageType,
  ShowTranslationInput,
  ShowTypeCode,
  UpdateClientShowInput
} from '../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../hooks/getLocales'
import {getShowTranslation} from '../../../../utils/translations'
import {isClientShowImagePropertiesFragment} from '../types'

import {
  DistributionFormField,
  IDistributionFormProps,
  IShowCrewFieldsProps,
  IShowForm,
  ITranslationProps,
  ShowCrewFormField,
  ShowFormField,
  TranslationFormField
} from './types'

export type GalleryImage2 = {
  id: number
  showId: number
  imageType: ShowImageType
  imageName: string
  isPrivate?: boolean
  src: string
  width: number
  height: number
  image?: File
}

export type GalleryImage = {
  imageId?: number
  showId: number
  code: ShowImageType
  alt: string
  isPrivate: boolean
  src: string
  width: number
  height: number
}

export const DROPZONE_WIDTH = 50
export const GALLERY_MARGIN = 4
export const GALLERY_TARGET_ROW_HEIGHT = 320

export const getImageInfo = (
  files: Array<File>,
  showId: number,
  imageType: ShowImageType
): Promise<GalleryImage2[]> => {
  const promises = Array.from(files).map((file: File) => {
    return new Promise<GalleryImage2>((resolve, reject) => {
      const image = new Image()
      image.onload = () => {
        const {naturalWidth, naturalHeight, src} = image
        resolve({
          id: parseInt(uniqueId(), 10),
          showId,
          imageType,
          src,
          isPrivate: false,
          imageName: file.name,
          width: naturalWidth,
          height: naturalHeight,
          image: file
        })
      }
      image.onerror = () => reject(new Error('Error while loading images'))
      image.src = URL.createObjectURL(file)
    })
  })

  return Promise.all(promises)
}

export const isDetailClientShowPropertiesFragment = (
  show: DetailClientShowPropertiesFragment | DetailLibraryShowPropertiesFragment
): show is DetailClientShowPropertiesFragment =>
  show.__typename === 'ClientShow'

export const getShowOriginalTitle = ({
  originalTitle,
  t
}: {
  originalTitle?: string | null
  t: TFunction
}) => {
  return originalTitle
    ? t('{{originalTitle}} (Original title)', {
        originalTitle,
        interpolation: {escapeValue: false}
      })
    : null
}

export const transformIShowFormToShowInput = (
  showForm: IShowForm
): LibraryShowInput | ClientShowInput => {
  const data = omit<
    IShowForm,
    | typeof ShowFormField.LOCALE_CODE
    | typeof ShowFormField.TAGLINE
    | typeof ShowFormField.TITLE
    | typeof ShowFormField.DESCRIPTION
  >(showForm, [
    ShowFormField.LOCALE_CODE,
    ShowFormField.TAGLINE,
    ShowFormField.TITLE,
    ShowFormField.DESCRIPTION
  ])
  return {
    ...data,
    globalReleaseDate: data.globalReleaseDate
      ? dayjs(data.globalReleaseDate).format('YYYY-MM-DD')
      : null,
    duration: data.duration ? parseInt(data.duration, 10) : null,
    productionYear: data.productionYear
      ? parseInt(data.productionYear, 10)
      : null,
    distributions:
      Object.values(data.distributions ?? {}).map((distribution) => ({
        formatCode: distribution?.formatCode || undefined,
        versionCode: distribution?.versionCode || undefined,
        soundMixCode: distribution?.soundMixCode || undefined
      })) || []
  }
}

export const transformIShowFormToUpdateClientShowInput = (
  showForm: IShowForm
): UpdateClientShowInput => {
  const data = omit<
    IShowForm,
    | typeof ShowFormField.LOCALE_CODE
    | typeof ShowFormField.TAGLINE
    | typeof ShowFormField.TITLE
    | typeof ShowFormField.DESCRIPTION
  >(showForm, [
    ShowFormField.LOCALE_CODE,
    ShowFormField.TAGLINE,
    ShowFormField.TITLE,
    ShowFormField.DESCRIPTION
  ])
  return {
    ...data,
    globalReleaseDate: data.globalReleaseDate
      ? dayjs(data.globalReleaseDate).format('YYYY-MM-DD')
      : null,
    duration: data.duration ? parseInt(data.duration, 10) : null,
    productionYear: data.productionYear
      ? parseInt(data.productionYear, 10)
      : null,
    distributions:
      Object.values(data.distributions ?? {}).map((distribution) => ({
        formatCode: distribution?.formatCode || undefined,
        versionCode: distribution?.versionCode || undefined,
        soundMixCode: distribution?.soundMixCode || undefined
      })) || [],
    businessPartnerId: data.businessPartnerId || null
  }
}

export const transformIShowFormToShowTranslationInput = (
  showForm: IShowForm
): ShowTranslationInput => {
  const {localeCode, title, description, tagline} = showForm
  return {
    localeCode,
    title,
    description,
    tagline
  }
}

function getDistributionsObject(
  show:
    | DetailLibraryShowPropertiesFragment
    | DetailClientShowPropertiesFragment
    | null
): {[key: string]: IDistributionFormProps} {
  const emptyObject: {[key: string]: IDistributionFormProps} = {}
  return (
    show?.distributions.reduce(
      (acc, distribution) => ({
        ...acc,
        [uuid()]: {
          [DistributionFormField.FORMAT]: distribution.formatCode || '',
          [DistributionFormField.VERSION]: distribution.versionCode || '',
          [DistributionFormField.SOUND_MIX]: distribution.soundMixCode || ''
        }
      }),
      emptyObject
    ) || emptyObject
  )
}

export const getShowFormDefaultValues = ({
  show,
  translation,
  localeCode,
  defaultTitle
}: {
  localeCode: LocaleCode
  show:
    | DetailLibraryShowPropertiesFragment
    | DetailClientShowPropertiesFragment
    | null
  translation?: DetailLibraryShowPropertiesFragment['translations'][number]
  defaultTitle?: string
}): IShowForm =>
  show === null
    ? {
        [ShowFormField.DURATION]: '',
        [ShowFormField.GLOBAL_RELEASE_DATE]: '',
        [ShowFormField.PRODUCTION_YEAR]: '',
        [ShowFormField.TITLE]: defaultTitle || '',
        [ShowFormField.TAGLINE]: '',
        [ShowFormField.DESCRIPTION]: '',
        [ShowFormField.TYPE_CODE]: ShowTypeCode.Movie,
        [ShowFormField.ORIGINAL_TITLE]: '',
        [ShowFormField.AGE_CLASSIFICATION_CODES]: [],
        [ShowFormField.GENRE_CODES]: [],
        [ShowFormField.COUNTRY_CODES]: [],
        [ShowFormField.LANGUAGE_CODES]: [],
        [ShowFormField.DISTRIBUTIONS]: [],
        [ShowFormField.LOCALE_CODE]: localeCode,
        [ShowFormField.DISTRIBUTIONS]: {}
      }
    : {
        [ShowFormField.TITLE]: translation?.title || '',
        [ShowFormField.TAGLINE]: translation?.tagline || '',
        [ShowFormField.DESCRIPTION]: translation?.description || '',
        [ShowFormField.LOCALE_CODE]: localeCode,
        [ShowFormField.TYPE_CODE]: show.typeCode,
        [ShowFormField.ORIGINAL_TITLE]: show.originalTitle || '',
        [ShowFormField.DURATION]: isNull(show.duration)
          ? ''
          : String(show.duration),
        [ShowFormField.GLOBAL_RELEASE_DATE]: show.globalReleaseDate,
        [ShowFormField.PRODUCTION_YEAR]: isNull(show.productionYear)
          ? ''
          : String(show.productionYear),
        [ShowFormField.AGE_CLASSIFICATION_CODES]: show.ageClassificationCodes,
        [ShowFormField.GENRE_CODES]: show.genreCodes,
        [ShowFormField.COUNTRY_CODES]: show.countryCodes,
        [ShowFormField.LANGUAGE_CODES]: show.languageCodes,
        [ShowFormField.DISTRIBUTIONS]: getDistributionsObject(show),
        [ShowFormField.CONTENT_DESCRIPTOR_CODES]:
          show.contentDescriptorCodes || undefined,
        [ShowFormField.BUSINESS_PARTNER_ID]:
          show.__typename === 'ClientShow'
            ? show.businessPartnerId || undefined
            : undefined
      }

export const useShowTranslationsFormDefaultValues = ({
  translations
}: {
  translations: DetailLibraryShowPropertiesFragment['translations']
}) => {
  const clientLocales = useGetClientLocales()
  return clientLocales.reduce<ITranslationProps[]>((acc, localeCode) => {
    const translation = getShowTranslation({
      translations,
      localeCode
    })
    return [
      ...acc,
      {
        [TranslationFormField.LOCALE_CODE]: localeCode,
        [TranslationFormField.TITLE]: translation?.title || '',
        [TranslationFormField.TAGLINE]: translation?.tagline || '',
        [TranslationFormField.DESCRIPTION]: translation?.description || ''
      }
    ]
  }, [])
}

export const getLibraryIdForMixedShowImage = (
  image:
    | ({__typename?: 'ClientShowImage'} & ClientShowImagePropertiesFragment)
    | ({__typename?: 'LibraryShowImage'} & LibraryShowImagePropertiesFragment)
) =>
  isClientShowImagePropertiesFragment(image) ? image.libraryImageId : image.id

export const mapFormValuesToShowCrewRoleInput = (
  data: Omit<IShowCrewFieldsProps, 'id'>[]
) =>
  Object.entries(groupBy(data, ShowCrewFormField.CrewRoleCode)).map(
    ([roleCode, value]) => ({
      crewRoleCode: roleCode as ShowCrewRoleCode,
      persons: value.map((person) => ({
        name: person.name,
        description: person.description
      }))
    })
  )

export const mapShowCrewRoleInputToFormValues = (
  data: DetailClientShowPropertiesFragment['crew']
) => {
  const flattenedCrew = flatten(
    data.map((crew) =>
      crew.persons.map((person) => ({
        name: person.name,
        description: person.description || '',
        code: crew.crewRoleCode
      }))
    )
  )
  return flattenedCrew.map(
    (person): IShowCrewFieldsProps => ({
      id: uniqueId(),
      name: person.name,
      description: person.description,
      crewRoleCode: person.code
    })
  )
}
