import {Drawer} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {
  EVENTS_PARAMS,
  RESERVATION_PARAMS,
  useEventsPathnameParams,
  useReservationPathnameParams,
  useTourParams
} from '../../../../../utils/pathname'
import {toPlaceholderParam} from '../../../../../utils/routes'
import {
  DRAWER_TRANSITION_DURATION,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {ReservationDetailContent} from './ReservationDetailContent'
import {ReservationDetailDataProvider} from './ReservationDetailDataProvider'
import {ReservationDetailEditContent} from './ReservationDetailEditContent'

const getLinkFromPath = (
  path: string,
  reservationId: number,
  eventId?: number,
  tourTimeSlotId?: number
) =>
  eventId || tourTimeSlotId
    ? eventId
      ? path
          .replace(
            toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID),
            String(reservationId)
          )
          .replace(toPlaceholderParam(EVENTS_PARAMS.EVENT_ID), String(eventId))
      : path
          .replace(
            toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID),
            String(reservationId)
          )
          .replace(':tourTimeSlotId', String(tourTimeSlotId))
    : path.replace(
        toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID),
        String(reservationId)
      )

interface IReservationDetailDrawerProps {
  onReservationDelete: () => void
  initialPath: string
  detailPath: string
  editPath: string
  showPrimaryButton?: boolean
}

export const ReservationDetailDrawer: React.FC<IReservationDetailDrawerProps> =
  ({
    onReservationDelete,
    initialPath,
    detailPath,
    editPath,
    showPrimaryButton
  }: IReservationDetailDrawerProps) => {
    const {reservationId} = useReservationPathnameParams()
    const {eventId} = useEventsPathnameParams()
    const {tourTimeSlotId} = useTourParams()
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
    const location = useLocation()
    const {P} = useEnsurePermissions()
    useEffect(() => {
      if (
        (matchPath(location.pathname, {
          path: detailPath,
          exact: true
        }) &&
          P([PermissionCode.ReadReservation])) ||
        (matchPath(location.pathname, {
          path: editPath,
          exact: true
        }) &&
          P([PermissionCode.UpdateReservation]))
      ) {
        setIsDrawerOpen(true)
      }
    }, [detailPath, editPath, location.pathname, reservationId, P])
    const history = useHistory()
    const handleClose = useCallback(() => {
      setIsDrawerOpen(false)
    }, [])
    const handleExited = useCallback(() => {
      history.replace(
        getLinkFromPath(initialPath, reservationId, eventId, tourTimeSlotId)
      )
    }, [eventId, history, initialPath, reservationId, tourTimeSlotId])
    const onEditClose = useCallback(() => {
      history.replace(
        getLinkFromPath(detailPath, reservationId, eventId, tourTimeSlotId)
      )
    }, [detailPath, eventId, history, reservationId, tourTimeSlotId])
    const handleEditButtonClick = useCallback(() => {
      history.replace(
        getLinkFromPath(editPath, reservationId, eventId, tourTimeSlotId)
      )
    }, [editPath, eventId, history, reservationId, tourTimeSlotId])
    const drawerClasses = useDrawerWithSideNavigationStyles()
    return (
      <Drawer
        open={isDrawerOpen}
        onClose={handleClose}
        SlideProps={{
          exit: true,
          onExited: handleExited
        }}
        classes={drawerClasses}
        keepMounted
        anchor="right"
        transitionDuration={DRAWER_TRANSITION_DURATION}
      >
        {!isNaN(reservationId) && (
          <ChildrenOnEffectiveClientSelected>
            <ReservationDetailDataProvider
              id={reservationId}
              onClose={handleClose}
            >
              {(reservation) => (
                <Switch>
                  {P([PermissionCode.ReadReservation]) && (
                    <Route path={detailPath} exact>
                      <ReservationDetailContent
                        onEditButtonClick={handleEditButtonClick}
                        onReservationDelete={onReservationDelete}
                        reservation={reservation}
                        onClose={handleClose}
                        showPrimaryButton={showPrimaryButton}
                      />
                    </Route>
                  )}
                  {P([PermissionCode.UpdateReservation]) && (
                    <Route path={editPath} exact>
                      <ReservationDetailEditContent
                        reservation={reservation}
                        onClose={onEditClose}
                      />
                    </Route>
                  )}
                </Switch>
              )}
            </ReservationDetailDataProvider>
          </ChildrenOnEffectiveClientSelected>
        )}
      </Drawer>
    )
  }
