import {useTranslation} from 'react-i18next'
import {TransactionType} from '../__generated__/schema'

export const useTranslateTransactionType = () => {
  const {t} = useTranslation()
  const translatedTransactionType: Record<TransactionType, string> = {
    [TransactionType.Refund]: t('TransactionType->Refund'),
    [TransactionType.Charge]: t('TransactionType->Charge'),
    [TransactionType.Deposit]: t('TransactionType->Deposit'),
    [TransactionType.Payout]: t('TransactionType->Payout'),
    [TransactionType.Withdrawal]: t('TransactionType->Withdrawal')
  }
  return (transactionType: TransactionType) =>
    translatedTransactionType[transactionType]
}
