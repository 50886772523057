import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {IconButton, InputAdornment} from '@mui/material'
import {styled} from '@mui/system'
import dayjs from 'dayjs'
import React, {useCallback, useMemo, useRef} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  ExistingCountryCode,
  LeadField
} from '../../../../../__generated__/schema'
import {useTranslateCountry} from '../../../../../hooks/countries'
import {useTranslateLeadField} from '../../../../../hooks/leadField'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {
  useIsStringWithMaxLength,
  useIsStringWithMinLength,
  useIsValidEmail,
  useIsValidPassword,
  useIsValidPhone
} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {SettingsPaper} from '../../../../common/Settings'
import {FormAutocomplete} from '../../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {FormDateInput} from '../../../../form/pickers'
import {UncontrolledFormCheckbox} from '../../../../form/UncontrolledFormCheckbox'
import {CustomerFormField, CustomerFormLocation, ICustomerForm} from './types'

interface IPasswordInputAdornmentProps {
  onClick: () => void
  isPasswordVisibilityOn: boolean
}

export const PasswordInputAdornment: React.FC<IPasswordInputAdornmentProps> = ({
  onClick,
  isPasswordVisibilityOn
}: IPasswordInputAdornmentProps) => (
  <InputAdornment position="end">
    <IconButton onClick={onClick}>
      {isPasswordVisibilityOn ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </IconButton>
  </InputAdornment>
)

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(3)
}))

interface ICustomerFormProps {
  formId: string
  onSubmit: (data: ICustomerForm) => Promise<void>
  defaultValues?: Partial<ICustomerForm>
  location: CustomerFormLocation
}

export const CustomerForm: React.FC<ICustomerFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  location
}: ICustomerFormProps) => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const {
    errors,
    setValue,
    watch,
    register,
    getValues,
    triggerValidation,
    control,
    setError,
    unregister,
    clearError,
    handleSubmit
  } = useForm<ICustomerForm>({defaultValues, mode: 'onChange'})
  const {state: isPasswordVisibilityOn, toggle: togglePasswordVisibility} =
    useBooleanState(false)
  const {
    state: isConfirmPasswordVisibilityOn,
    toggle: toggleConfirmPasswordVisibility
  } = useBooleanState(false)
  const usernameDivRef = useRef<HTMLDivElement | null>(null)
  const loyaltyIdDivRef = useRef<HTMLDivElement | null>(null)
  const loyaltyLevelExpirationDateDivRef = useRef<HTMLDivElement | null>(null)
  const translateLeadField = useTranslateLeadField()
  const translateCountry = useTranslateCountry()
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength32 = useIsStringWithMaxLength(32)
  const isStringWithMinLength = useIsStringWithMinLength(6)
  const isValidPassword = useIsValidPassword()
  const isValidPhone = useIsValidPhone()
  const isValidEmail = useIsValidEmail()
  const countryOptions = useMemo(
    () =>
      Object.values(ExistingCountryCode).map((code: ExistingCountryCode) => ({
        value: code,
        name: translateCountry(code)
      })),
    [translateCountry]
  )
  const _handleSubmit = useCallback(
    async (formData: ICustomerForm) => {
      try {
        await onSubmit(formData)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.LoyaltyIdAlreadyTaken
          )
        ) {
          setError(
            CustomerFormField.LoyaltyId,
            t('Loyalty ID is already taken.')
          )
          loyaltyIdDivRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CustomerAlreadyExists
          )
        ) {
          setError(
            CustomerFormField.Username,
            t('This username is taken. Try another one or sign in to account.')
          )
          usernameDivRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.ExpirationDateIsInThePast
          )
        ) {
          setError(
            CustomerFormField.LoyaltyLevelExpirationDate,
            t('Can’t be in past.')
          )
          loyaltyIdDivRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        } else {
          defaultErrorHandler(
            error,
            location === CustomerFormLocation.Create
              ? t('Error while creating customer')
              : t('Error while updating customer')
          )
        }
      }
    },
    [defaultErrorHandler, location, onSubmit, setError, t]
  )
  return (
    <StyledForm onSubmit={handleSubmit(_handleSubmit)} id={formId}>
      <SettingsPaper label={t('Account')} childrenSx={{p: 3}}>
        <InputRow
          nodes={[
            <div ref={usernameDivRef} key={CustomerFormField.Username}>
              <UncontrolledFormTextInput<ICustomerForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CustomerFormField.Username}
                label={t('Username')}
                margin="dense"
                validationOptions={{
                  validate: isStringWithMinLength,
                  required: true
                }}
                fullWidth
                required
              />
            </div>
          ]}
        />
        <InputRow
          nodes={[
            <div ref={loyaltyIdDivRef} key={CustomerFormField.LoyaltyId}>
              <UncontrolledFormTextInput<ICustomerForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CustomerFormField.LoyaltyId}
                label={t('Loyalty ID')}
                margin="dense"
                validationOptions={{
                  validate: isStringWithMaxLength32
                }}
                helperNote={t(
                  'Enter the code on the card here, or leave it empty to generate an unique ID'
                )}
                fullWidth
                disabled={location === CustomerFormLocation.Edit}
              />
            </div>
          ]}
        />
        {location === CustomerFormLocation.Create && (
          <>
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<ICustomerForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={CustomerFormField.Password}
                  key={CustomerFormField.Password}
                  label={t('Password')}
                  margin="dense"
                  validationOptions={{
                    validate: isValidPassword,
                    required: true
                  }}
                  type={isPasswordVisibilityOn ? 'text' : 'password'}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <PasswordInputAdornment
                        onClick={togglePasswordVisibility}
                        isPasswordVisibilityOn={isPasswordVisibilityOn}
                      />
                    )
                  }}
                  fullWidth
                  required
                />
              ]}
            />
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<ICustomerForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={CustomerFormField.ConfirmPassword}
                  key={CustomerFormField.ConfirmPassword}
                  label={t('Confirm password')}
                  margin="dense"
                  autoComplete="new-password"
                  validationOptions={{
                    required: true,
                    validate: (confirmPassword) => {
                      const password = getValues(CustomerFormField.Password)
                      return (
                        password === confirmPassword ||
                        t('Passwords did not match.')
                      )
                    }
                  }}
                  type={isConfirmPasswordVisibilityOn ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <PasswordInputAdornment
                        onClick={toggleConfirmPasswordVisibility}
                        isPasswordVisibilityOn={isConfirmPasswordVisibilityOn}
                      />
                    )
                  }}
                  fullWidth
                />
              ]}
            />
          </>
        )}
        <InputRow
          nodes={[
            <UncontrolledFormCheckbox<ICustomerForm>
              errors={errors}
              name={CustomerFormField.IsPrivacyPolicyConsentGranted}
              key={CustomerFormField.IsPrivacyPolicyConsentGranted}
              label={t(
                'Customer agrees with privacy policy for marketing purposes.'
              )}
              control={control}
            />
          ]}
        />
        <InputRow
          nodes={[
            <div
              ref={loyaltyLevelExpirationDateDivRef}
              key={CustomerFormField.LoyaltyLevelExpirationDate}
            >
              <FormDateInput<ICustomerForm>
                datePickerProps={{
                  label: t<string>('Benefits expiration date'),
                  minDate:
                    location === CustomerFormLocation.Create ||
                    (!watch(CustomerFormField.LoyaltyLevelExpirationDate) &&
                      !defaultValues?.loyaltyLevelExpirationDate)
                      ? dayjs().add(1, 'day')
                      : undefined
                }}
                helperText={t(
                  'Optional. Last date a customer can use benefits such as discounts, free tickets, and points collection for purchases. If this date is in the past, the customer is no longer eligible to apply these benefits to their account.'
                )}
                control={control}
                register={register}
                unregister={unregister}
                watch={watch}
                errors={errors}
                clearError={clearError}
                setValue={setValue}
                key={CustomerFormField.LoyaltyLevelExpirationDate}
                name={CustomerFormField.LoyaltyLevelExpirationDate}
                setError={setError}
                fullWidth
              />
            </div>
          ]}
        />
      </SettingsPaper>
      <SettingsPaper label={t('Contact information')} childrenSx={{p: 3}}>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[name]`}
              key={`${CustomerFormField.LeadData}[name]`}
              label={translateLeadField(LeadField.Name)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              helperNote={t('Recommended')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[phone]`}
              key={`${CustomerFormField.LeadData}[phone]`}
              label={translateLeadField(LeadField.Phone)}
              margin="dense"
              validationOptions={{
                validate: isValidPhone
              }}
              helperNote={t('Recommended')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[email]`}
              key={`${CustomerFormField.LeadData}[email]`}
              label={translateLeadField(LeadField.Email)}
              margin="dense"
              validationOptions={{
                validate: isValidEmail,
                required: true
              }}
              fullWidth
              required
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[note]`}
              key={`${CustomerFormField.LeadData}[note]`}
              label={translateLeadField(LeadField.Note)}
              margin="dense"
              multiline
              rows={4}
              validationOptions={{validate: isStringWithMaxLength1000}}
              helperNote={t('Customer can see this note.')}
              fullWidth
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper label={t('Company')} childrenSx={{p: 3}}>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[companyName]`}
              key={`${CustomerFormField.LeadData}[companyName]`}
              label={translateLeadField(LeadField.CompanyName)}
              validationOptions={{validate: isStringWithMaxLength255}}
              margin="dense"
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[companyIdNumber]`}
              key={`${CustomerFormField.LeadData}[companyIdNumber]`}
              label={translateLeadField(LeadField.CompanyIdNumber)}
              validationOptions={{validate: isStringWithMaxLength255}}
              margin="dense"
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[TAXId]`}
              key={`${CustomerFormField.LeadData}[TAXId]`}
              label={translateLeadField(LeadField.TaxId)}
              validationOptions={{validate: isStringWithMaxLength255}}
              margin="dense"
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[VATId]`}
              key={`${CustomerFormField.LeadData}[VATId]`}
              label={translateLeadField(LeadField.VatId)}
              validationOptions={{validate: isStringWithMaxLength255}}
              margin="dense"
              fullWidth
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper label={t('Billing address')} childrenSx={{p: 3}}>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[billingAddressStreet]`}
              key={`${CustomerFormField.LeadData}[billingAddressStreet]`}
              label={translateLeadField(LeadField.BillingAddressStreet)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[billingAddressTown]`}
              key={`${CustomerFormField.LeadData}[billingAddressTown]`}
              label={translateLeadField(LeadField.BillingAddressTown)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[billingPostalCode]`}
              key={`${CustomerFormField.LeadData}[billingPostalCode]`}
              label={translateLeadField(LeadField.BillingPostalCode)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<ICustomerForm>
              fullWidth
              noOptionsText=""
              errors={errors}
              label={translateLeadField(LeadField.BillingAddressCountry)}
              name={`${CustomerFormField.LeadData}[billingAddressCountry]`}
              key={`${CustomerFormField.LeadData}[billingAddressCountry]`}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={countryOptions}
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper label={t('Delivery address')} childrenSx={{p: 3}}>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[deliveryAddressee]`}
              key={`${CustomerFormField.LeadData}[deliveryAddressee]`}
              label={translateLeadField(LeadField.DeliveryAddressee)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[deliveryAddressStreet]`}
              key={`${CustomerFormField.LeadData}[deliveryAddressStreet]`}
              label={translateLeadField(LeadField.DeliveryAddressStreet)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[deliveryAddressTown]`}
              key={`${CustomerFormField.LeadData}[deliveryAddressTown]`}
              label={translateLeadField(LeadField.DeliveryAddressTown)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICustomerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${CustomerFormField.LeadData}[deliveryPostalCode]`}
              key={`${CustomerFormField.LeadData}[deliveryPostalCode]`}
              label={translateLeadField(LeadField.DeliveryPostalCode)}
              margin="dense"
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<ICustomerForm>
              fullWidth
              noOptionsText=""
              errors={errors}
              label={translateLeadField(LeadField.DeliveryAddressCountry)}
              name={`${CustomerFormField.LeadData}[deliveryAddressCountry]`}
              key={`${CustomerFormField.LeadData}[deliveryAddressCountry]`}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={countryOptions}
            />
          ]}
        />
      </SettingsPaper>
    </StyledForm>
  )
}
