import React from 'react'
import {useTranslation} from 'react-i18next'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'

export type ClientsFilter = {
  name?: string
}

const getClientNameFromSearchObject = (filter: ClientsFilter) =>
  filter.name || undefined

const mapClientNameToFiler = (
  filter: ClientsFilter,
  name: string | undefined
): ClientsFilter => ({...filter, name})

interface IClientsSearchProps {
  onFilterChange: (filter: ClientsFilter) => void
}

export const ClientsSearch: React.FC<IClientsSearchProps> = ({
  onFilterChange
}: IClientsSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getClientNameFromSearchObject
  )
  return (
    <Search<ClientsFilter>
      storageKey="CLIENTS"
      placeholder={t('Search for client')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapClientNameToFiler}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
    />
  )
}
