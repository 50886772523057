import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField
} from '@mui/material'
import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
  TimePicker,
  TimePickerProps
} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {
  LocalizationProvider as MuiLocalizationProvider,
  LocalizationProviderProps
} from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, {Dayjs} from 'dayjs'
import 'dayjs/locale/cs'
import 'dayjs/locale/hu'
import 'dayjs/locale/sk'
import React, {useCallback} from 'react'
import {
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {
  INVALID_DATETIME_FORMAT_MISSING_TIME,
  useTranslateDateTimeValidationError,
  useTranslateDateValidationError,
  useTranslateTimeValidationError
} from '../../hooks/pickerErrors'
// noinspection ES6PreferShortImport
import {FormValidationError} from '../common/FormHelpers'
import {useLocale} from '../context/locale'
import {FormFieldHelper} from '../visual/common'
import {isValidDateWithMissingTimePart} from './pickerUtils'
import {FormFieldName} from './types'
import {
  getValueInUncontrolledFormComponent,
  useCustomReactHookFormRegistration,
  useHasError
} from './utils'

interface ILocalizationProviderProps
  extends Omit<LocalizationProviderProps, 'dateAdapter' | 'locale'> {
  dateAdapter?: LocalizationProviderProps['dateAdapter']
  locale?: LocalizationProviderProps['locale']
}

export const LocalizationProvider: React.FC<ILocalizationProviderProps> = ({
  dateAdapter,
  locale,
  ...props
}: ILocalizationProviderProps) => {
  const {localeCode} = useLocale()
  return (
    <MuiLocalizationProvider
      dateAdapter={dateAdapter || AdapterDayjs}
      adapterLocale={locale || localeCode}
      {...props}
    />
  )
}

interface ICommonProps<FormValues extends FieldValues = FieldValues> {
  control: FormContextValues<FormValues>['control']
  register: FormContextValues<FormValues>['register']
  unregister: FormContextValues<FormValues>['unregister']
  watch: FormContextValues<FormValues>['watch']
  errors: FormContextValues<FormValues>['errors']
  clearError: FormContextValues<FormValues>['clearError']
  setValue: FormContextValues<FormValues>['setValue']
  validationOptions?: ValidationOptions
  helperText?: string
  name: FormFieldName<FormValues>
  setError: FormContextValues<FormValues>['setError']
  fullWidth?: boolean
}
interface IFormDatetimeInputProps<FormValues extends FieldValues = FieldValues>
  extends ICommonProps<FormValues> {
  dataTimePickerProps: Omit<
    DateTimePickerProps<Dayjs, Dayjs>,
    'value' | 'onChange' | 'renderInput'
  >
}

export const FormDatetimeInput = <
  FormValues extends FieldValues = FieldValues
>({
  dataTimePickerProps,
  control,
  register,
  unregister,
  setError,
  clearError,
  errors,
  validationOptions,
  setValue,
  watch,
  name,
  fullWidth,
  helperText
}: IFormDatetimeInputProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({
    name,
    defaultValue: control?.defaultValuesRef?.current[name] ?? null,
    watch
  }) as Dayjs | null
  const hasError = useHasError<FormValues>(errors, name)

  const handleChange = useCallback(
    (newValue) => {
      setValue(name, newValue, newValue === null)
    },
    [name, setValue]
  )

  useCustomReactHookFormRegistration({
    register,
    unregister,
    validationOptions,
    name
  })
  const translateDateTimeValidationError = useTranslateDateTimeValidationError()
  return (
    <LocalizationProvider>
      <DateTimePicker<Dayjs>
        {...dataTimePickerProps}
        renderInput={(
          props:
            | FilledTextFieldProps
            | OutlinedTextFieldProps
            | StandardTextFieldProps
        ) => (
          <TextField
            {...props}
            fullWidth={fullWidth}
            helperText={null}
            error={hasError}
            onBlur={async () => {
              if (control.mode.isOnBlur) {
                await control.triggerValidation(name)
              }
              if (value) {
                if (!value.isValid?.()) {
                  if (
                    isValidDateWithMissingTimePart(props?.inputProps?.value)
                  ) {
                    setError(
                      name,
                      translateDateTimeValidationError(
                        INVALID_DATETIME_FORMAT_MISSING_TIME
                      )
                    )
                  } else {
                    setError(
                      name,
                      translateDateTimeValidationError('invalidDate')
                    )
                  }
                }
              }
            }}
            required={Boolean(validationOptions?.required)}
          />
        )}
        value={value}
        onError={(reason) => {
          if (reason === null) {
            clearError()
          } else {
            if (value) {
              setError(name, translateDateTimeValidationError(reason))
            }
          }
        }}
        onChange={handleChange}
      />
      <div>
        {hasError ? (
          <FormValidationError<FormValues> errors={errors} fieldName={name} />
        ) : (
          <FormFieldHelper
            required={Boolean(validationOptions?.required)}
            note={helperText}
          />
        )}
      </div>
    </LocalizationProvider>
  )
}

export interface IFormTimeInputProps<
  FormValues extends FieldValues = FieldValues
> extends ICommonProps<FormValues> {
  timePickerProps: Omit<
    TimePickerProps<Dayjs, Dayjs>,
    'value' | 'onChange' | 'renderInput'
  >
}

export const FormTimeInput = <FormValues extends FieldValues = FieldValues>({
  timePickerProps,
  control,
  register,
  unregister,
  setError,
  clearError,
  errors,
  validationOptions,
  setValue,
  watch,
  name,
  fullWidth,
  helperText
}: IFormTimeInputProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({
    name,
    defaultValue: control?.defaultValuesRef?.current[name] ?? null,
    watch
  }) as Dayjs | null
  const hasError = useHasError<FormValues>(errors, name)

  const handleChange = useCallback(
    (newValue) => {
      setValue(name, newValue, newValue === null)
    },
    [name, setValue]
  )

  const renderInput = useCallback(
    (props) => (
      <TextField
        {...props}
        fullWidth={fullWidth}
        helperText={null}
        error={hasError}
        required={Boolean(validationOptions?.required)}
      />
    ),
    [fullWidth, hasError, validationOptions?.required]
  )

  useCustomReactHookFormRegistration({
    register,
    unregister,
    validationOptions,
    name
  })
  const translateTimeValidationError = useTranslateTimeValidationError()
  return (
    <LocalizationProvider>
      <TimePicker<Dayjs>
        {...timePickerProps}
        renderInput={renderInput}
        value={value}
        onError={(reason) => {
          if (reason === null) {
            clearError()
          } else {
            if (value) {
              setError(name, translateTimeValidationError(reason))
            }
          }
        }}
        onChange={handleChange}
      />
      <div>
        {hasError ? (
          <FormValidationError<FormValues> errors={errors} fieldName={name} />
        ) : (
          <FormFieldHelper
            required={Boolean(validationOptions?.required)}
            note={helperText}
          />
        )}
      </div>
    </LocalizationProvider>
  )
}

interface IFormDateInputProps<FormValues extends FieldValues = FieldValues>
  extends ICommonProps<FormValues> {
  datePickerProps: Omit<
    DatePickerProps<Dayjs, Dayjs>,
    'value' | 'onChange' | 'renderInput'
  >
}

const isValidDateText = (dateText: string): boolean =>
  dayjs(dateText.trim(), 'DD.MM.YYYY', true).isValid() ||
  dayjs(dateText.trim(), 'MM/DD/YYYY', true).isValid()

export const FormDateInput = <FormValues extends FieldValues = FieldValues>({
  datePickerProps,
  control,
  register,
  unregister,
  setError,
  clearError,
  errors,
  validationOptions,
  setValue,
  watch,
  name,
  fullWidth,
  helperText
}: IFormDateInputProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({
    name,
    defaultValue: control?.defaultValuesRef?.current[name] ?? null,
    watch
  }) as Dayjs | null
  const hasError = useHasError<FormValues>(errors, name)

  const handleChange = useCallback(
    (newValue) => {
      setValue(name, newValue, newValue === null)
    },
    [name, setValue]
  )

  const translateDateValidationError = useTranslateDateValidationError()

  const renderInput = useCallback(
    (
      props:
        | FilledTextFieldProps
        | OutlinedTextFieldProps
        | StandardTextFieldProps
    ) => (
      <TextField
        {...props}
        fullWidth={fullWidth}
        helperText={null}
        error={hasError}
        onBlur={() => {
          const dateText = props.inputProps?.value
          if (dateText && !isValidDateText(dateText)) {
            setError(name, translateDateValidationError('invalidDate'))
          }
        }}
        required={Boolean(validationOptions?.required)}
      />
    ),
    [
      fullWidth,
      hasError,
      name,
      setError,
      translateDateValidationError,
      validationOptions?.required
    ]
  )

  useCustomReactHookFormRegistration({
    register,
    unregister,
    validationOptions,
    name
  })
  return (
    <LocalizationProvider>
      <DatePicker<Dayjs>
        {...datePickerProps}
        renderInput={renderInput}
        value={value}
        onError={(reason) => {
          if (reason === null) {
            clearError()
          } else {
            if (value) {
              setError(name, translateDateValidationError(reason))
            }
          }
        }}
        onChange={handleChange}
      />
      <div>
        {hasError ? (
          <FormValidationError<FormValues> errors={errors} fieldName={name} />
        ) : (
          <FormFieldHelper
            required={Boolean(validationOptions?.required)}
            note={helperText}
          />
        )}
      </div>
    </LocalizationProvider>
  )
}
