import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Box, Button, Divider} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {TypifiedIconModel} from '../../../../../../__generated__/schema'
import {
  useIsStringWithMaxLength,
  useIsValidURL
} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {Menu, MenuItem, useMenu} from '../../../../../common/Menu'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {
  useTranslateButtonIconPosition,
  useTranslateMuiButtonSize,
  useTranslateMuiButtonVariant
} from '../../utils'
import {
  ButtonGroupFormField,
  ButtonIconPosition,
  IButtonGroupForm,
  MuiButtonSize,
  MuiButtonVariant
} from '../editBlockDrawer/types'
import {TypifiedIcon} from '../TypifiedIcon'
import {useWebsitePageContext} from '../WebsitePageContext'

const transformSlug = (slug: string) => (slug === '' ? '/' : `/${slug}`)

const useIsSlugInPages = () => {
  const {websitePages, selectedWebsitePage} = useWebsitePageContext()
  const isValidURL = useIsValidURL()
  const slugs = websitePages
    .filter((page) => page.localeCode === selectedWebsitePage?.localeCode)
    .map(({slug}) => transformSlug(slug))
  return (value: any) =>
    value && slugs.includes(value) ? undefined : isValidURL(value)
}

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(1)
}))

interface IButtonGroupFormProps {
  formId: string
  onSubmit: (data: IButtonGroupForm) => void
  defaultValues?: Partial<IButtonGroupForm>
}

export const ButtonGroupForm: React.FC<IButtonGroupFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IButtonGroupFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    control,
    register,
    triggerValidation,
    handleSubmit
  } = useForm<IButtonGroupForm>({defaultValues, mode: 'onChange'})
  const {websitePages, selectedWebsitePage} = useWebsitePageContext()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isSlugInPages = useIsSlugInPages()
  const translateMuiButtonSize = useTranslateMuiButtonSize()
  const translateMuiButtonVariant = useTranslateMuiButtonVariant()
  const translateButtonIconPosition = useTranslateButtonIconPosition()
  const handleSetLink = useCallback(
    (slug: string) => () => {
      setValue(ButtonGroupFormField.Link, transformSlug(slug))
      closeMenu()
    },
    [setValue, closeMenu]
  )
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IButtonGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ButtonGroupFormField.Label}
            key={ButtonGroupFormField.Label}
            label={t('Label')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
      <Box sx={{py: 1}}>
        <Box>
          <UncontrolledFormTextInput<IButtonGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ButtonGroupFormField.Link}
            key={ButtonGroupFormField.Link}
            label={t('Link')}
            validationOptions={{
              required: true,
              validate: isSlugInPages
            }}
            required
            fullWidth
          />
        </Box>
        <Divider>
          <Button
            variant="text"
            color="primary"
            endIcon={<ExpandMoreIcon />}
            onClick={openMenu}
          >
            {t('or select page')}
          </Button>
        </Divider>
      </Box>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IButtonGroupForm>
            selectOptions={Object.values(MuiButtonSize).reduce(
              (acc, size) => ({
                ...acc,
                [size]: translateMuiButtonSize(size)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={ButtonGroupFormField.Size}
            key={ButtonGroupFormField.Size}
            label={t('Size')}
            fullWidth
            hasNoneSelectOption
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IButtonGroupForm>
            selectOptions={Object.values(MuiButtonVariant).reduce(
              (acc, variant) => ({
                ...acc,
                [variant]: translateMuiButtonVariant(variant)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={ButtonGroupFormField.Variant}
            key={ButtonGroupFormField.Variant}
            label={t('Variant')}
            fullWidth
            hasNoneSelectOption
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IButtonGroupForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Icon')}
            setValue={setValue}
            name={ButtonGroupFormField.Icon}
            key={ButtonGroupFormField.Icon}
            fullWidth
            hasNoneSelectOption
            selectOptions={Object.values(TypifiedIconModel).reduce(
              (acc, icon) => ({
                ...acc,
                [icon]: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5
                    }}
                  >
                    <TypifiedIcon model={icon} />
                    {icon}
                  </Box>
                )
              }),
              {}
            )}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IButtonGroupForm>
            selectOptions={Object.values(ButtonIconPosition).reduce(
              (acc, position) => ({
                ...acc,
                [position]: translateButtonIconPosition(position)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={ButtonGroupFormField.IconPosition}
            key={ButtonGroupFormField.IconPosition}
            label={t('Icon position')}
            fullWidth
            hasNoneSelectOption
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IButtonGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ButtonGroupFormField.ClassName}
            key={ButtonGroupFormField.ClassName}
            label={t('Class name')}
            validationOptions={{
              validate: isStringWithMaxLength255
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IButtonGroupForm>
            name={ButtonGroupFormField.OpenInNewTab}
            key={ButtonGroupFormField.OpenInNewTab}
            primaryLabel={t('Open in new tab?')}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.openInNewTab || false}
            secondaryLabel=""
            enabledText=""
            disabledText=""
          />
        ]}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {websitePages
          .filter((page) => page.localeCode === selectedWebsitePage?.localeCode)
          .map((page) => (
            <MenuItem
              key={page.ID}
              label={page.title}
              onClick={handleSetLink(page.slug)}
              isSelected={
                watch(ButtonGroupFormField.Link) === transformSlug(page.slug)
              }
            />
          ))}
      </Menu>
    </StyledForm>
  )
}
