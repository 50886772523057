import {useCallback, useEffect} from 'react'

const BROADCAST_CHANNEL_ID = 'BROADCAST_CHANNEL_ID'
const CLIENT_SWITCHED = 'CLIENT_SWITCHED'

let broadcastChannel: BroadcastChannel

export const useSubscribeToBroadcastChannel = () => {
  useEffect(() => {
    if (!broadcastChannel && typeof BroadcastChannel === 'function') {
      broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_ID)
      if (broadcastChannel) {
        broadcastChannel.onmessage = (event) => {
          if (event.data === CLIENT_SWITCHED) {
            window.location.reload()
          }
        }
      }
    }
    return () => {
      if (broadcastChannel) {
        broadcastChannel.close()
      }
    }
  }, [])
}

export const useBroadcastClientSwitched = () =>
  useCallback(() => {
    if (broadcastChannel) {
      broadcastChannel.postMessage(CLIENT_SWITCHED)
    } else {
      // eslint-disable-next-line no-console
      console.error('Post to BroadcastChannel failed. Not subscribed')
    }
  }, [])
