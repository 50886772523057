import {combineReducers} from 'redux'
import undoable from 'redux-undo'

import {objectsReducer} from '../objects/reducer'
import {IObjectsState} from '../objects/types'

interface _IUndoableState {
  objects: IObjectsState
}

export interface IUndoableState {
  past: Array<_IUndoableState>
  present: _IUndoableState
  future: Array<_IUndoableState>
}

const _undoableReducer = combineReducers({
  objects: objectsReducer
})

export const undoableReducer = undoable(_undoableReducer)
