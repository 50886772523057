import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {
  getGraphQLErrorRelatedToErrorMessage,
  useDefaultErrorHandler
} from '../../../../../utils/errors'
import {
  useIsIntegerWithMaxBoundary,
  useIsPositiveInteger,
  useIsValidDiscountCodeName
} from '../../../../../utils/formsValidations'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {FormDateInput} from '../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {CheckboxWithLabel, useCheckboxWithLabel} from './CheckboxWithLabel'

import {
  DiscountCodeFormField,
  DiscountCodeNamingApproach,
  ICreateDiscountCodeSubmitOptions,
  IDiscountCodeForm
} from './types'

export const discountCodeTimeOptions = [
  '0:00',
  '1:00',
  '2:00',
  '3:00',
  '4:00',
  '5:00',
  '6:00',
  '7:00',
  '8:00',
  '9:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
].reduce(
  (acc, label, index) => ({
    ...acc,
    [index]: label
  }),
  {}
)

export const CREATE_DISCOUNT_CODE_FORM_ID = 'CREATE_DISCOUNT_CODE_FORM_ID'

const useStyles = makeStyles<Theme>((theme) => ({
  dateRow: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(3)
  },
  nameField: {
    marginTop: theme.spacing(2)
  },
  countField: {
    marginTop: theme.spacing(2)
  },
  usageLimitsLabel: {
    padding: theme.spacing(2, 0)
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  },
  descriptionFieldWrapper: {
    paddingTop: theme.spacing(2)
  }
}))

interface IDiscountCodeFormProps {
  defaultValues?: Partial<IDiscountCodeForm>
  onSubmit: (
    data: IDiscountCodeForm,
    options: ICreateDiscountCodeSubmitOptions
  ) => Promise<void>
  className?: string
  canCreateMultiple?: boolean
}

export const DiscountCodeForm: React.FC<IDiscountCodeFormProps> = ({
  defaultValues,
  onSubmit,
  className,
  canCreateMultiple
}: IDiscountCodeFormProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {
    register,
    unregister,
    setValue,
    errors,
    watch,
    handleSubmit,
    setError,
    triggerValidation,
    control,
    clearError
  } = useForm<IDiscountCodeForm>({
    defaultValues
  })

  const isValidDiscountCodeName = useIsValidDiscountCodeName()

  const {[DiscountCodeFormField.NAMING_APPROACH]: namingApproach} = watch()

  const [hasUsageLimit, onHasUsageLimitChange] =
    useCheckboxWithLabel(canCreateMultiple)
  const [hasUsageLimitPerOrder, onHasUsageLimitPerOrderChange] =
    useCheckboxWithLabel(false)
  const [hasActivationDate, onHasActivationDateChange] = useCheckboxWithLabel()
  const [hasExpirationDate, onHasExpirationDateChange] = useCheckboxWithLabel()
  const isPositiveInteger = useIsPositiveInteger()
  const isIntegerWithMaxBoundary = useIsIntegerWithMaxBoundary(500)
  const defaultErrorHandler = useDefaultErrorHandler()

  const _onSubmit = useCallback(
    async (data: IDiscountCodeForm) => {
      try {
        await onSubmit(data, {
          hasUsageLimit,
          hasActivationDate,
          hasExpirationDate,
          hasUsageLimitPerOrder
        })
      } catch (e) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.DiscountCodeIsNotUnique
          )
        ) {
          setError(
            DiscountCodeFormField.NAME,
            t('Discount code name is not unique')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.ExpirationDateIsInThePast
          )
        ) {
          setError(
            DiscountCodeFormField.EXPIRATION_DATE,
            t('Expiration date is in the past')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.ActivationDateIsInThePast
          )
        ) {
          setError(
            DiscountCodeFormField.ACTIVATION_DATE,
            t('Activation date is in the past')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            e,
            ErrorMessages.ActivationDateIsAfterExpirationDate
          )
        ) {
          defaultErrorHandler(
            e,
            t("Activation date can't be after expiration date")
          )
        } else {
          defaultErrorHandler(e, t('Error while creating a discount code'))
        }
      }
    },
    [
      onSubmit,
      hasUsageLimit,
      hasActivationDate,
      hasExpirationDate,
      hasUsageLimitPerOrder,
      setError,
      t,
      defaultErrorHandler
    ]
  )

  return (
    <form
      className={className}
      noValidate
      autoComplete="off"
      id={CREATE_DISCOUNT_CODE_FORM_ID}
      onSubmit={handleSubmit(_onSubmit)}
    >
      {canCreateMultiple ? (
        <UncontrolledFormTextInput<IDiscountCodeForm>
          className={classes.countField}
          register={register}
          name={DiscountCodeFormField.COUNT}
          key={DiscountCodeFormField.COUNT}
          errors={errors}
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          label={t('Amount of codes')}
          validationOptions={{
            required: true,
            validate: {isPositiveInteger, isIntegerWithMaxBoundary}
          }}
          required
          fullWidth
          inputMode="numeric"
        />
      ) : (
        <Controller
          as={
            <RadioGroup>
              <FormControlLabel
                value={DiscountCodeNamingApproach.GENERATE_RANDOM}
                control={<Radio color="primary" />}
                label={t('Generate random unique code')}
              />
              <FormControlLabel
                value={DiscountCodeNamingApproach.DEFINED_BY_USER}
                control={<Radio color="primary" />}
                label={t('Custom name for code')}
              />
            </RadioGroup>
          }
          name={DiscountCodeFormField.NAMING_APPROACH}
          control={control}
        />
      )}

      {!canCreateMultiple &&
        namingApproach === DiscountCodeNamingApproach.DEFINED_BY_USER && (
          <UncontrolledFormTextInput<IDiscountCodeForm>
            className={classes.nameField}
            register={register}
            name={DiscountCodeFormField.NAME}
            key={DiscountCodeFormField.NAME}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Code')}
            validationOptions={{
              required: true,
              validate: isValidDiscountCodeName
            }}
            required
            fullWidth
            helperNote={t(
              'Only capital letters and numbers allowed. Must be unique across all inactive and active codes in all discounts.'
            )}
          />
        )}
      <Typography variant="subtitle1" className={classes.usageLimitsLabel}>
        {t('Usage limits')}
      </Typography>
      <CheckboxWithLabel
        checked={hasUsageLimit}
        onChange={onHasUsageLimitChange}
        label={t('Limit total number of usage')}
      />
      {hasUsageLimit && (
        <div className={classes.paddingBottom}>
          <UncontrolledFormTextInput<IDiscountCodeForm>
            register={register}
            name={DiscountCodeFormField.USAGE_LIMIT}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Number of usage per code')}
            fullWidth
            validationOptions={{
              required: true,
              validate: isPositiveInteger
            }}
            required
            inputMode="numeric"
          />
        </div>
      )}
      <CheckboxWithLabel
        checked={hasUsageLimitPerOrder}
        onChange={onHasUsageLimitPerOrderChange}
        label={t('Limit total number of usage per order')}
      />
      {hasUsageLimitPerOrder && (
        <div className={classes.paddingBottom}>
          <UncontrolledFormTextInput<IDiscountCodeForm>
            register={register}
            name={DiscountCodeFormField.USAGE_PER_LIMIT_ORDER}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Number of usage per order')}
            fullWidth
            validationOptions={{
              required: true,
              validate: isPositiveInteger
            }}
            required
            inputMode="numeric"
          />
        </div>
      )}
      <CheckboxWithLabel
        checked={hasActivationDate}
        onChange={onHasActivationDateChange}
        label={t('Add an activation date')}
      />
      {hasActivationDate && (
        <div className={cn(classes.dateRow, classes.paddingBottom)}>
          <div>
            <FormDateInput<IDiscountCodeForm>
              validationOptions={{
                required: true
              }}
              datePickerProps={{
                label: t('Activation date'),
                disablePast: true
              }}
              fullWidth
              name={DiscountCodeFormField.ACTIVATION_DATE}
              errors={errors}
              register={register}
              setValue={setValue}
              unregister={unregister}
              watch={watch}
              control={control}
              clearError={clearError}
              setError={setError}
            />
          </div>
          <div>
            <UncontrolledFormSelect<IDiscountCodeForm>
              register={register}
              errors={errors}
              setValue={setValue}
              label={t('Activation time')}
              watch={watch}
              required
              validationOptions={{
                required: true
              }}
              name={DiscountCodeFormField.ACTIVATION_TIME}
              selectOptions={discountCodeTimeOptions}
              fullWidth
            />
          </div>
        </div>
      )}
      <CheckboxWithLabel
        checked={hasExpirationDate}
        onChange={onHasExpirationDateChange}
        label={t('Add an expiration date')}
      />
      {hasExpirationDate && (
        <div className={classes.dateRow}>
          <div>
            <FormDateInput<IDiscountCodeForm>
              validationOptions={{
                required: true
              }}
              datePickerProps={{
                label: t('Expiration date'),
                disablePast: true
              }}
              fullWidth
              name={DiscountCodeFormField.EXPIRATION_DATE}
              errors={errors}
              register={register}
              setValue={setValue}
              unregister={unregister}
              watch={watch}
              control={control}
              clearError={clearError}
              setError={setError}
            />
          </div>
          <div>
            <UncontrolledFormSelect<IDiscountCodeForm>
              register={register}
              errors={errors}
              setValue={setValue}
              label={t('Expiration time')}
              watch={watch}
              required
              validationOptions={{
                required: true
              }}
              name={DiscountCodeFormField.EXPIRATION_TIME}
              selectOptions={discountCodeTimeOptions}
              fullWidth
            />
          </div>
        </div>
      )}
      <div className={classes.descriptionFieldWrapper}>
        <UncontrolledFormTextInput<IDiscountCodeForm>
          register={register}
          name={DiscountCodeFormField.DESCRIPTION}
          errors={errors}
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          label={t('Description')}
          fullWidth
          multiline
          rows={3}
          helperNote={t(
            'Optional. Customer can see this in specific situations.'
          )}
        />
      </div>
    </form>
  )
}
