import CheckIcon from '@mui/icons-material/Check'
import {Box, Chip, Grid, lighten, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {Theme} from '../../../theme'
import {SetValue} from '../../../utils/forms'
import {FormValidationError} from '../../common/FormHelpers'
import {FormFieldName} from '../../form/types'
import {useCustomReactHookFormRegistration} from '../../form/utils'

const useStyles = makeStyles<Theme>((theme) => ({
  activeChip: {
    background: `${lighten(theme.palette.primary.light, 0.6)} !important`,
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark
  },
  inactiveChip: {
    border: `1px solid ${theme.palette.background.paper}`
  },
  tick: {
    marginRight: theme.spacing(1)
  }
}))

type Value = any

interface IFormChipsGroupSelectProps<
  FormValues extends FieldValues = FieldValues,
  T extends number | string | boolean = number
> {
  items: {
    value: T
    label: string
  }[]
  errors: FieldErrors<FormValues>
  setValue: SetValue<T>
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  unregister: FormContextValues<FormValues>['unregister']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
  label: string
  isRequired?: boolean
}

export const FormChipsGroupSelect = <
  FormValues extends FieldValues = FieldValues,
  T extends number | string | boolean = number
>({
  items,
  setValue,
  watch,
  errors,
  register,
  unregister,
  validationOptions,
  name,
  isRequired,
  label
}: IFormChipsGroupSelectProps<FormValues, T>) => {
  const classes = useStyles()
  useCustomReactHookFormRegistration<FormValues>({
    name,
    register,
    unregister,
    validationOptions
  })
  const value = watch(name)
  return (
    <>
      <Box paddingTop={1} paddingBottom={1}>
        <Typography variant="caption" color="textSecondary">{`${label}${
          isRequired ? '*' : ''
        }`}</Typography>
      </Box>
      <Grid container spacing={1}>
        {items.map(({value: itemValue, label}, index) => (
          <Grid item key={index}>
            <Chip
              label={label}
              onClick={() => {
                setValue(name, itemValue, true)
              }}
              color={value === itemValue ? 'primary' : 'default'}
              classes={
                value === itemValue
                  ? {colorPrimary: classes.activeChip}
                  : {root: classes.inactiveChip}
              }
            />
          </Grid>
        ))}
      </Grid>
      {errors[name] && (
        <Box marginLeft={1}>
          <FormValidationError<FormValues> fieldName={name} errors={errors} />
        </Box>
      )}
    </>
  )
}

const useHandlersMulti = (
  defaultValues: Array<Value>,
  onChange: (values: Array<Value>) => any
) => {
  const [values, setValues] = useState<Array<Value>>(defaultValues)

  const _onChange = (clickedValue: Value) => {
    const newValues = values.includes(clickedValue)
      ? values.filter((value) => value !== clickedValue)
      : [...values, clickedValue]

    onChange(newValues)
    setValues(newValues)
  }

  useEffect(() => {
    if (_.isEqual(_.sortBy(defaultValues), _.sortBy(values))) {
      onChange(defaultValues)
      setValues(defaultValues)
    }
    // only want to re-run when default value changes
  }, [defaultValues]) // eslint-disable-line react-hooks/exhaustive-deps

  return {values, _onChange}
}

interface IFormMultiChipsGroupProps {
  onChange: (values: Array<Value>) => any
  defaultValues: Array<Value>
  items: Array<{label: string; value: Value}>
  inputRef: any
}

export const FormMultiChipsGroup: React.FC<IFormMultiChipsGroupProps> = ({
  items,
  onChange,
  defaultValues
}: IFormMultiChipsGroupProps) => {
  const classes = useStyles()

  const {values, _onChange} = useHandlersMulti(defaultValues, onChange)

  return (
    <Grid container spacing={1}>
      {items.map(({value: itemValue, label}) => {
        const isActive = values.includes(itemValue)

        return (
          <Grid item key={itemValue}>
            <Chip
              label={
                isActive ? (
                  <Box display="flex">
                    <CheckIcon fontSize="small" className={classes.tick} />
                    {label}
                  </Box>
                ) : (
                  label
                )
              }
              onClick={() => _onChange(itemValue)}
              color={isActive ? 'primary' : 'default'}
              classes={
                isActive
                  ? {colorPrimary: classes.activeChip}
                  : {root: classes.inactiveChip}
              }
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
