import {useTranslation} from 'react-i18next'

import {DivisionInvoiceFreq} from '../__generated__/schema'

const useTranslateDivisionInvoiceFreq = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<DivisionInvoiceFreq, string> = {
    [DivisionInvoiceFreq.ByEvent]: t<string>('DivisionInvoiceFreq->ByEvent'),
    [DivisionInvoiceFreq.Daily]: t<string>('DivisionInvoiceFreq->Daily'),
    [DivisionInvoiceFreq.Weekly]: t<string>('DivisionInvoiceFreq->Weekly'),
    [DivisionInvoiceFreq.Monthly]: t<string>('DivisionInvoiceFreq->Monthly')
  }
  return (code: DivisionInvoiceFreq) => translatedCodes[code]
}

export const useDivisionInvoiceFreqSelectData = () => {
  const divisionInvoiceFrequencies = Object.values(DivisionInvoiceFreq)
  const translateDivisionInvoiceFreq = useTranslateDivisionInvoiceFreq()
  return divisionInvoiceFrequencies.reduce(
    (acc, divisionInvoiceFrequency) => ({
      ...acc,
      [divisionInvoiceFrequency]: translateDivisionInvoiceFreq(
        divisionInvoiceFrequency
      )
    }),
    {}
  ) as Record<DivisionInvoiceFreq, string>
}
