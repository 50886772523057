import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {uniqBy} from 'lodash'
import {useCallback} from 'react'
import {
  AddDiscountToCartItemsMutation,
  AddDiscountToCartItemsMutationVariables,
  AddDiscountToReservationTicketItemsMutation,
  AddDiscountToReservationTicketItemsMutationVariables,
  AddReservationToCartMutation,
  AddReservationToCartMutationVariables,
  AddSeatingTicketItemToCartMutation,
  AddSeatingTicketItemToCartMutationVariables,
  AddSeatingTicketItemToReservationMutation,
  AddSeatingTicketItemToReservationMutationVariables,
  AddZoneTicketItemToReservationMutation,
  AddZoneTicketItemToReservationMutationVariables,
  AuditoriumPreviewDataQuery,
  AuditoriumPreviewDataQueryVariables,
  CancelReservationMutation,
  CancelReservationMutationVariables,
  CreateCashDeskCustomerMutation,
  CreateCashDeskCustomerMutationVariables,
  DecrementDiscountQuantityFromCartTicketItemsMutation,
  DecrementDiscountQuantityFromCartTicketItemsMutationVariables,
  DecrementDiscountQuantityFromReservationTicketItemsMutation,
  DecrementDiscountQuantityFromReservationTicketItemsMutationVariables,
  DecrementTourItemQuantityInReservationMutation,
  DecrementTourItemQuantityInReservationMutationVariables,
  DecrementZoneTicketItemQuantityMutation,
  DecrementZoneTicketItemQuantityMutationVariables,
  EnabledDiscountsFilterInput,
  EnabledDiscountsForEventQuery,
  EnabledDiscountsForEventQueryVariables,
  EventDetailDrawerDataQuery,
  EventDetailDrawerDataQueryVariables,
  EventsFilterInput,
  EventState,
  GetFilterEventVenuesQuery,
  GetFilterEventVenuesQueryVariables,
  IncrementDiscountQuantityForCartTicketItemsMutation,
  IncrementDiscountQuantityForCartTicketItemsMutationVariables,
  IncrementDiscountQuantityForReservationTicketItemsMutation,
  IncrementDiscountQuantityForReservationTicketItemsMutationVariables,
  IncrementTourItemQuantityInReservationMutation,
  IncrementTourItemQuantityInReservationMutationVariables,
  IncrementZoneTicketItemQuantityMutation,
  IncrementZoneTicketItemQuantityMutationVariables,
  PaginatedEventsQuery,
  PaginatedEventsQueryVariables,
  RemoveAllTicketItemsFromCartForEventMutation,
  RemoveAllTicketItemsFromCartForEventMutationVariables,
  RemoveDiscountFromItemMutation,
  RemoveDiscountFromItemMutationVariables,
  RemoveDiscountFromReservationTicketItemMutation,
  RemoveDiscountFromReservationTicketItemMutationVariables,
  RemoveReservationFromCartMutation,
  RemoveReservationFromCartMutationVariables,
  RemoveTicketItemFromCartMutation,
  RemoveTicketItemFromCartMutationVariables,
  RemoveTicketItemsFromReservationMutation,
  RemoveTicketItemsFromReservationMutationVariables,
  RemoveTourItemFromReservationMutation,
  RemoveTourItemFromReservationMutationVariables,
  RemoveZoneTicketItemsFromReservationMutation,
  RemoveZoneTicketItemsFromReservationMutationVariables,
  ReservationDetailQuery,
  ReservationDetailQueryVariables,
  ReservationOnApplyDiscountsPageQuery,
  ReservationOnApplyDiscountsPageQueryVariables,
  ReservationsFilterInput,
  ReservationsQuery,
  ReservationsQueryVariables,
  SplitReservationMutation,
  SplitReservationMutationVariables,
  UpdateReservationMutation,
  UpdateReservationMutationVariables,
  UpdateSeatingTicketItemsQuantityInCartMutation,
  UpdateSeatingTicketItemsQuantityInCartMutationVariables
} from '../../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../utils/pagination'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {DISCOUNT_PROPERTIES_FRAGMENT} from '../discounts/graphql'

import {
  CART_PROPERTIES_FRAGMENT,
  ENABLED_DISCOUNT_PROPERTIES_FRAGMENT,
  ERROR_PROPERTIES_FRAGMENT,
  LEAD_PROPERTIES_FRAGMENT,
  PRODUCT_ITEM_PROPERTIES_FRAGMENT,
  TICKET_ITEM_PROPERTIES_FRAGMENT,
  TOUR_ITEM_PROPERTIES_FRAGMENT,
  TRANSLATED_LOCALES_FRAGMENT,
  USER_FIELDS
} from '../graphql'

export const CASH_DESK_EVENT_FRAGMENT = gql`
  fragment CashDeskEvent on Event {
    id
    marketingLabel {
      id
      name
    }
    reservedSeatsCount
    soldSeatsCount
    availableSeatsCount
    auditoriumLayout {
      capacity
      id
    }
    names {
      ...TranslatedLocales
    }
    startsAt
    #activePricing {
    #  id
    #  minPrice
    #}
    state
    formatCode
    auditorium {
      id
      name
    }
    soundMixCode
    versionCode
    ageClassificationCode
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const CASH_DESK_RESERVATION_FRAGMENT = gql`
  fragment CashDeskReservation on Reservation {
    id
    event {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
    }
    price
    lead {
      id
      data {
        name
        phone
        email
      }
    }
    sellingChannel
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    expireAt
    state
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TOUR_ITEM_PROPERTIES_FRAGMENT}
`

const NARROW_CASH_DESK_RESERVATION_FRAGMENT = gql`
  fragment NarrowCashDeskReservationProperties on Reservation {
    id
    event {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
    }
    tourTimeSlot {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
    }
    price
    lead {
      id
      data {
        name
        phone
        email
      }
    }
    sellingChannel
    itemsCount
    expireAt
    state
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const GET_PAGINATED_EVENTS = gql`
  query paginatedEvents(
    $filter: EventsFilterInput!
    $paginationInput: PaginationInput!
  ) {
    paginatedEvents(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...CashDeskEvent
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${CASH_DESK_EVENT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const GET_PAGINATED_RESERVATIONS = gql`
  query Reservations(
    $filter: ReservationsFilterInput!
    $paginationInput: PaginationInput!
  ) {
    reservations(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowCashDeskReservationProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${NARROW_CASH_DESK_RESERVATION_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const useGetPaginatedEvents = (filterInput: EventsFilterInput) =>
  useQueryWithPagination<PaginatedEventsQuery, PaginatedEventsQueryVariables>(
    GET_PAGINATED_EVENTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          ...filterInput,
          state: EventState.Published,
          isAvailableAtPointOfSale: true
        },
        paginationInput: {
          limit: 25,
          offset: 0
        }
      }
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.paginatedEvents.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        paginatedEvents: {
          ...fetchMoreResult.paginatedEvents,
          items: uniqBy(
            [
              ...prevData.paginatedEvents.items,
              ...fetchMoreResult.paginatedEvents?.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

export const useGetReservations = (
  filterInput: ReservationsFilterInput,
  paginationLimit: number = 25
) =>
  useQueryWithPagination<ReservationsQuery, ReservationsQueryVariables>(
    GET_PAGINATED_RESERVATIONS,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: filterInput,
        paginationInput: {
          limit: paginationLimit,
          offset: 0
        }
      }
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.reservations.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        reservations: {
          ...fetchMoreResult.reservations,
          items: uniqBy(
            [
              ...prevData.reservations.items,
              ...fetchMoreResult.reservations?.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

const DETAIL_CASH_DESK_EVENT_PROPERTIES_FRAGMENT = gql`
  fragment CashDeskEventDetailProperties on Event {
    id
    division {
      id
      name
    }
    names {
      ...TranslatedLocales
    }
    startsAt
    endsAt
    duration
    formatCode
    soundMixCode
    versionCode
    ageClassificationCode
    reservedSeatsCount
    soldSeatsCount
    availableSeatsCount
    state
    auditorium {
      id
      name
    }
    auditoriumLayout {
      capacity
      id
      layout
      type
    }
    auditoriumLayoutPricing {
      id
      pricing
    }
    venue {
      id
      name
      address {
        complex
        street
        town
        postalCode
        country
      }
    }
    show {
      productionYear
      genreCodes
      globalReleaseDate
      translations {
        localeCode
        title
        description
      }
      crew {
        crewRoleCode
        persons {
          name
          description
        }
      }
      contentDescriptorCodes
    }
    activePricing {
      id
      minPrice
      ticketTypes {
        id
        name
        description
        price
        color
        ticketTypeId
      }
    }
    salesAndReservation {
      pointOfSale {
        saleStart
        saleEnd
        saleActive
        reservationStart
        reservationEnd
        reservationActive
      }
    }
    allowedDiscountsSellingChannels
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const CASH_DESK_ADMISSION_RATE_FIELDS = gql`
  fragment CashDeskAdmissionRateFields on AdmissionRate {
    id
    admissionTypesAssignments(state: active, channel: retail) {
      id
      retailPrice
      admissionType {
        id
        name
        color
        icon
        capacityDecreaseCount
        startingQuantity
        internalDescription
      }
    }
  }
`

const CASH_DESK_TOUR_TIME_SLOT_DETAIL_FIELDS = gql`
  ${USER_FIELDS}
  ${CASH_DESK_ADMISSION_RATE_FIELDS}
  fragment CashDeskTourTimeSlotDetailProperties on TourTimeSlot {
    id
    versionCode
    startsAt
    endsAt
    duration
    guide {
      ...UserFields
    }
    admissionRate {
      ...CashDeskAdmissionRateFields
    }
    ageClassificationCode
    venue {
      id
      name
      address {
        complex
        street
        town
        postalCode
        country
      }
    }
    tour {
      id
      name
      division {
        id
        name
      }
    }
  }
`

const EVENT_DETAIL_DRAWER_DATA = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  query EventDetailDrawerData(
    $id: Int!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    event(id: $id) {
      id
      names {
        ...TranslatedLocales
      }
      show {
        id
        genreCodes
        productionYear
        globalReleaseDate
        contentDescriptorCodes
        translations {
          title
          description
          localeCode
        }
        crew {
          crewRoleCode
          persons {
            name
            description
          }
        }
      }
      startsAt
      endsAt
      duration
      formatCode
      soundMixCode
      versionCode
      ageClassificationCode
      auditorium {
        id
        name
      }
      venue {
        id
        name
        address {
          complex
          street
          town
          postalCode
          country
        }
      }
      division {
        id
        name
      }
      activePricing {
        id
        ticketTypes {
          id
          color
          description
          price
          name
        }
      }
      enabledDiscounts(discountsFilter: $discountsFilter) {
        id
        name
        value
        type
        application
      }
    }
  }
`

export const useEventDetailDrawerData = (
  variables: EventDetailDrawerDataQueryVariables
) =>
  useQuery<EventDetailDrawerDataQuery, EventDetailDrawerDataQueryVariables>(
    EVENT_DETAIL_DRAWER_DATA,
    {variables}
  )

const ENABLED_DISCOUNTS_FOR_EVENT = gql`
  ${ENABLED_DISCOUNT_PROPERTIES_FRAGMENT}
  query EnabledDiscountsForEvent(
    $id: Int!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    event(id: $id) {
      id
      allowedDiscountsSellingChannels
      enabledDiscounts(discountsFilter: $discountsFilter) {
        ...EnabledDiscountProperties
      }
    }
  }
`

export const useEnabledDiscountsForEvent = (
  variables: EnabledDiscountsForEventQueryVariables
) =>
  useQuery<
    EnabledDiscountsForEventQuery,
    EnabledDiscountsForEventQueryVariables
  >(ENABLED_DISCOUNTS_FOR_EVENT, {variables, fetchPolicy: 'cache-and-network'})

const AUDITORIUM_PREVIEW_FIELDS = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment AuditoriumPreviewFields on Event {
    id
    names {
      ...TranslatedLocales
    }
    startsAt
    endsAt
    duration
    formatCode
    soundMixCode
    versionCode
    ageClassificationCode
    reservedSeatsCount
    soldSeatsCount
    availableSeatsCount
    state
    auditorium {
      id
      name
    }
    salesAndReservation {
      pointOfSale {
        reservationActive
        reservationEnd
        reservationStart
        saleActive
        saleEnd
        saleStart
      }
    }
    auditoriumLayout {
      id
      layout
      type
      capacity
    }
    activePricing {
      id
      minPrice
      ticketTypes {
        id
        name
        description
        price
        color
        ticketTypeId
      }
    }
    auditoriumLayoutPricing {
      id
      pricing
    }
    marketingLabel {
      id
      name
    }
  }
`

const AUDITORIUM_PREVIEW_DATA = gql`
  ${AUDITORIUM_PREVIEW_FIELDS}
  query AuditoriumPreviewData($id: Int!) {
    event(id: $id) {
      ...AuditoriumPreviewFields
    }
  }
`

export const useAuditoriumPreviewData = (id: number) =>
  useQuery<AuditoriumPreviewDataQuery, AuditoriumPreviewDataQueryVariables>(
    AUDITORIUM_PREVIEW_DATA,
    {variables: {id}, fetchPolicy: 'cache-and-network'}
  )

const GET_VENUES = gql`
  query getFilterEventVenues {
    venues {
      id
      name
    }
  }
`

export const useGetFilterEventVenues = () => {
  const {data, error, loading} =
    useQuery<GetFilterEventVenuesQuery, GetFilterEventVenuesQueryVariables>(
      GET_VENUES
    )
  return {
    venues:
      data?.venues.sort((venueA, venueB) =>
        venueA.name.localeCompare(venueB.name)
      ) || [],
    error,
    loading
  }
}

const ADD_SEATING_TICKET_ITEM_TO_CART_MUTATION = gql`
  mutation addSeatingTicketItemToCart(
    $cartId: Int
    $eventId: Int!
    $eventSeatUUID: String!
  ) {
    addSeatingTicketItemToCart(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUID: $eventSeatUUID
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

const REMOVE_TICKET_ITEM_FROM_CART_MUTATION = gql`
  mutation removeTicketItemFromCart($itemId: Int!, $cartId: Int!) {
    removeTicketItemFromCart(itemId: $itemId, cartId: $cartId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

const REMOVE_ALL_TICKET_ITEMS_FROM_CART_FOR_EVENT_MUTATION = gql`
  mutation removeAllTicketItemsFromCartForEvent($cartId: Int!, $eventId: Int!) {
    removeAllTicketItemsFromCartForEvent(cartId: $cartId, eventId: $eventId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useAddSeatingTicketItemToCart = () => {
  const [addSeatingTicketItemToCart] = useMutation<
    AddSeatingTicketItemToCartMutation,
    AddSeatingTicketItemToCartMutationVariables
  >(ADD_SEATING_TICKET_ITEM_TO_CART_MUTATION)
  return useCallback(
    (variables: AddSeatingTicketItemToCartMutationVariables) =>
      addSeatingTicketItemToCart({variables}),
    [addSeatingTicketItemToCart]
  )
}

export const useRemoveTicketItemFromCart = () => {
  const [removeTicketItemFromCart] = useMutation<
    RemoveTicketItemFromCartMutation,
    RemoveTicketItemFromCartMutationVariables
  >(REMOVE_TICKET_ITEM_FROM_CART_MUTATION)
  return useCallback(
    (variables: RemoveTicketItemFromCartMutationVariables) =>
      removeTicketItemFromCart({variables}),
    [removeTicketItemFromCart]
  )
}

export const useRemoveAllTicketItemsFromCartForEvent = () => {
  const [removeAllTicketItemsFromCartForEvent] = useMutation<
    RemoveAllTicketItemsFromCartForEventMutation,
    RemoveAllTicketItemsFromCartForEventMutationVariables
  >(REMOVE_ALL_TICKET_ITEMS_FROM_CART_FOR_EVENT_MUTATION)
  return (variables: RemoveAllTicketItemsFromCartForEventMutationVariables) =>
    removeAllTicketItemsFromCartForEvent({variables})
}

const INCREMENT_ZONE_TICKET_ITEM_QUANTITY_SUCCESS_RESULT_FRAGMENT = gql`
  fragment IncrementZoneTicketItemQuantitySuccessResultProperties on IncrementZoneTicketItemQuantitySuccessResult {
    cart {
      ...CartProperties
    }
    newlyAddedZoneTicketItemsCount
  }
  ${CART_PROPERTIES_FRAGMENT}
`

const INCREMENT_ZONE_TICKET_ITEM_QUANTITY_MUTATION = gql`
  mutation incrementZoneTicketItemQuantity(
    $cartId: Int
    $eventId: Int!
    $eventSeatUUID: String!
    $increment: NonNegativeInt!
  ) {
    incrementZoneTicketItemQuantity(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUID: $eventSeatUUID
      increment: $increment
    ) {
      ...IncrementZoneTicketItemQuantitySuccessResultProperties
      ...ErrorResultProperties
    }
  }
  ${INCREMENT_ZONE_TICKET_ITEM_QUANTITY_SUCCESS_RESULT_FRAGMENT}
  ${ERROR_PROPERTIES_FRAGMENT}
`

export const useIncrementZoneTicketItemQuantity = () => {
  const [incrementZoneTicketItemQuantity] = useMutation<
    IncrementZoneTicketItemQuantityMutation,
    IncrementZoneTicketItemQuantityMutationVariables
  >(INCREMENT_ZONE_TICKET_ITEM_QUANTITY_MUTATION)
  return (variables: IncrementZoneTicketItemQuantityMutationVariables) =>
    incrementZoneTicketItemQuantity({variables})
}

const DECREMENT_ZONE_TICKET_ITEM_QUANTITY_MUTATION = gql`
  mutation decrementZoneTicketItemQuantity(
    $cartId: Int!
    $eventId: Int!
    $eventSeatUUID: String!
    $decrement: NonNegativeInt!
  ) {
    decrementZoneTicketItemQuantity(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUID: $eventSeatUUID
      decrement: $decrement
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useDecrementZoneTicketItemQuantity = () => {
  const [decrementZoneTicketItemQuantity] = useMutation<
    DecrementZoneTicketItemQuantityMutation,
    DecrementZoneTicketItemQuantityMutationVariables
  >(DECREMENT_ZONE_TICKET_ITEM_QUANTITY_MUTATION)
  return (variables: DecrementZoneTicketItemQuantityMutationVariables) =>
    decrementZoneTicketItemQuantity({variables})
}

export const RESERVATION_DETAIL_FRAGMENT = gql`
  fragment ReservationDetail on Reservation {
    id
    event {
      ...CashDeskEventDetailProperties
    }
    tourTimeSlot {
      ...CashDeskTourTimeSlotDetailProperties
    }
    price
    lead {
      ...LeadProperties
    }
    sellingChannel
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    expireAt
    state
    createdAt
    createdByName
    updatedAt
    updatedByName
    cart {
      uuid
      hash
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TOUR_ITEM_PROPERTIES_FRAGMENT}
  ${DETAIL_CASH_DESK_EVENT_PROPERTIES_FRAGMENT}
  ${CASH_DESK_TOUR_TIME_SLOT_DETAIL_FIELDS}
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const RESERVATION_DETAIL = gql`
  query reservationDetail($id: Int!) {
    reservation(id: $id) {
      ...ReservationDetail
    }
  }
  ${RESERVATION_DETAIL_FRAGMENT}
`

export const useReservation = (id: number) =>
  useQuery<ReservationDetailQuery, ReservationDetailQueryVariables>(
    RESERVATION_DETAIL,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

export const RESERVATION_ON_APPLY_DISCOUNTS_PAGE = gql`
  query reservationOnApplyDiscountsPage(
    $id: Int!
    $discountsFilter: EnabledDiscountsFilterInput
  ) {
    reservation(id: $id) {
      id
      event {
        ...CashDeskEventDetailProperties
        enabledDiscounts(discountsFilter: $discountsFilter) {
          ...EnabledDiscountProperties
        }
      }
      tourTimeSlot {
        ...CashDeskTourTimeSlotDetailProperties
      }
      price
      sellingChannel
      items {
        ...TicketItemProperties
        ...ProductItemProperties
        ...TourItemProperties
      }
      expireAt
      state
      updatedAt
      updatedByName
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TOUR_ITEM_PROPERTIES_FRAGMENT}
  ${DETAIL_CASH_DESK_EVENT_PROPERTIES_FRAGMENT}
  ${ENABLED_DISCOUNT_PROPERTIES_FRAGMENT}
  ${CASH_DESK_TOUR_TIME_SLOT_DETAIL_FIELDS}
`

export const useApplyDiscountForReservation = (
  id: number,
  discountsFilter?: EnabledDiscountsFilterInput
) =>
  useQuery<
    ReservationOnApplyDiscountsPageQuery,
    ReservationOnApplyDiscountsPageQueryVariables
  >(RESERVATION_ON_APPLY_DISCOUNTS_PAGE, {
    variables: {
      discountsFilter,
      id
    }
  })

const UPDATE_RESERVATION_MUTATION = gql`
  mutation updateReservation(
    $id: Int!
    $expireAt: DateTime!
    $leadDataInput: LeadDataInput!
  ) {
    updateReservation(
      id: $id
      expireAt: $expireAt
      leadDataInput: $leadDataInput
    ) {
      id
      expireAt
      lead {
        ...LeadProperties
      }
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const useUpdateReservation = () => {
  const [updateReservation] = useMutation<
    UpdateReservationMutation,
    UpdateReservationMutationVariables
  >(UPDATE_RESERVATION_MUTATION)
  return (variables: UpdateReservationMutationVariables) =>
    updateReservation({variables})
}
const CANCEL_RESERVATION_MUTATION = gql`
  mutation cancelReservation($reservationId: Int!) {
    cancelReservation(reservationId: $reservationId) {
      ...ReservationDetail
    }
  }
  ${RESERVATION_DETAIL_FRAGMENT}
`

export const useCancelReservation = () => {
  const [cancelReservation] = useMutation<
    CancelReservationMutation,
    CancelReservationMutationVariables
  >(CANCEL_RESERVATION_MUTATION)
  return (variables: CancelReservationMutationVariables) =>
    cancelReservation({
      variables
    })
}

const ADD_RESERVATION_TO_CART_MUTATION = gql`
  mutation addReservationToCart($reservationId: Int!, $cartId: Int) {
    addReservationToCart(reservationId: $reservationId, cartId: $cartId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useAddReservationToCart = () => {
  const [addReservationToCart] = useMutation<
    AddReservationToCartMutation,
    AddReservationToCartMutationVariables
  >(ADD_RESERVATION_TO_CART_MUTATION)
  return (variables: AddReservationToCartMutationVariables) =>
    addReservationToCart({
      variables,
      refetchQueries: [
        {
          query: RESERVATION_DETAIL,
          variables: {
            id: variables.reservationId
          }
        }
      ]
    })
}

const REMOVE_RESERVATION_FROM_CART_MUTATION = gql`
  mutation removeReservationFromCart($cartId: Int!, $reservationId: Int!) {
    removeReservationFromCart(cartId: $cartId, reservationId: $reservationId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useRemoveReservationFromCart = () => {
  const [removeReservationFromCart] = useMutation<
    RemoveReservationFromCartMutation,
    RemoveReservationFromCartMutationVariables
  >(REMOVE_RESERVATION_FROM_CART_MUTATION)
  return (variables: RemoveReservationFromCartMutationVariables) =>
    removeReservationFromCart({
      variables,
      refetchQueries: [
        {
          query: RESERVATION_DETAIL,
          variables: {
            id: variables.reservationId
          }
        }
      ],
      awaitRefetchQueries: true
    })
}

const SPLIT_RESERVATION_MUTATION = gql`
  mutation splitReservation(
    $reservationId: Int!
    $cartId: Int
    $itemIds: [Int!]!
  ) {
    splitReservation(
      reservationId: $reservationId
      cartId: $cartId
      itemIds: $itemIds
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useSplitReservation = () => {
  const [splitReservation] = useMutation<
    SplitReservationMutation,
    SplitReservationMutationVariables
  >(SPLIT_RESERVATION_MUTATION)
  return (variables: SplitReservationMutationVariables) =>
    splitReservation({
      variables,
      refetchQueries: [
        {
          query: RESERVATION_DETAIL,
          variables: {
            id: variables.reservationId
          }
        }
      ]
    })
}

const REMOVE_TICKET_ITEMS_FROM_RESERVATION_MUTATION = gql`
  mutation removeTicketItemsFromReservation(
    $reservationId: Int!
    $itemIds: [Int!]!
  ) {
    removeTicketItemsFromReservation(
      reservationId: $reservationId
      itemIds: $itemIds
    ) {
      ...CashDeskReservation
    }
  }
  ${CASH_DESK_RESERVATION_FRAGMENT}
`

export const useRemoveTicketItemsFromReservation = () => {
  const [removeTicketItemsFromReservation] = useMutation<
    RemoveTicketItemsFromReservationMutation,
    RemoveTicketItemsFromReservationMutationVariables
  >(REMOVE_TICKET_ITEMS_FROM_RESERVATION_MUTATION)
  return useCallback(
    (reservationId: number, itemIds: number[]) =>
      removeTicketItemsFromReservation({
        variables: {
          reservationId,
          itemIds
        }
      }),
    [removeTicketItemsFromReservation]
  )
}

const REMOVE_TOUR_ITEM_FROM_RESERVATION = gql`
  mutation RemoveTourItemFromReservation(
    $reservationId: PositiveInt!
    $itemId: PositiveInt!
  ) {
    removeTourItemFromReservation(
      reservationId: $reservationId
      itemId: $itemId
    ) {
      ...CashDeskReservation
    }
  }
  ${CASH_DESK_RESERVATION_FRAGMENT}
`

export const useRemoveTourItemFromReservation = () => {
  const [removeTourItemFromReservation] = useMutation<
    RemoveTourItemFromReservationMutation,
    RemoveTourItemFromReservationMutationVariables
  >(REMOVE_TOUR_ITEM_FROM_RESERVATION)
  return useCallback(
    (variables: RemoveTourItemFromReservationMutationVariables) =>
      removeTourItemFromReservation({variables}),
    [removeTourItemFromReservation]
  )
}

const ADD_SEATING_TICKET_ITEM_TO_RESERVATION_MUTATION = gql`
  mutation addSeatingTicketItemToReservation(
    $id: Int!
    $eventSeatUUID: String!
  ) {
    addSeatingTicketItemToReservation(id: $id, eventSeatUUID: $eventSeatUUID) {
      ...CashDeskReservation
    }
  }
  ${CASH_DESK_RESERVATION_FRAGMENT}
`

export const useAddSeatingTicketItemToReservation = () => {
  const [addSeatingTicketItemToReservation] = useMutation<
    AddSeatingTicketItemToReservationMutation,
    AddSeatingTicketItemToReservationMutationVariables
  >(ADD_SEATING_TICKET_ITEM_TO_RESERVATION_MUTATION)
  return useCallback(
    (id: number, eventSeatUUID: string) =>
      addSeatingTicketItemToReservation({
        variables: {
          id,
          eventSeatUUID
        }
      }),
    [addSeatingTicketItemToReservation]
  )
}

const ADD_ZONE_TICKET_ITEM_TO_RESERVATION_MUTATION = gql`
  mutation addZoneTicketItemToReservation(
    $id: Int!
    $eventSeatUUID: String!
    $count: NonNegativeInt!
  ) {
    addZoneTicketItemToReservation(
      id: $id
      eventSeatUUID: $eventSeatUUID
      count: $count
    ) {
      ... on AddZoneTicketItemToReservationSuccessResult {
        reservation {
          ...CashDeskReservation
        }
      }
    }
  }
  ${CASH_DESK_RESERVATION_FRAGMENT}
`

export const useAddZoneTicketItemToReservation = () => {
  const [addZoneTicketItemToReservation] = useMutation<
    AddZoneTicketItemToReservationMutation,
    AddZoneTicketItemToReservationMutationVariables
  >(ADD_ZONE_TICKET_ITEM_TO_RESERVATION_MUTATION)
  return useCallback(
    (id: number, eventSeatUUID: string, count: number) =>
      addZoneTicketItemToReservation({
        variables: {
          id,
          eventSeatUUID,
          count
        }
      }),
    [addZoneTicketItemToReservation]
  )
}

const REMOVE_ZONE_TICKET_ITEMS_FROM_RESERVATION_MUTATION = gql`
  mutation removeZoneTicketItemsFromReservation(
    $reservationId: Int!
    $eventSeatUUID: String!
    $count: NonNegativeInt!
  ) {
    removeZoneTicketItemsFromReservation(
      reservationId: $reservationId
      eventSeatUUID: $eventSeatUUID
      count: $count
    ) {
      ... on Reservation {
        ...CashDeskReservation
      }
    }
  }
  ${CASH_DESK_RESERVATION_FRAGMENT}
`

export const useRemoveZoneTicketItemsFromReservation = () => {
  const [removeZoneTicketItemsFromReservation] = useMutation<
    RemoveZoneTicketItemsFromReservationMutation,
    RemoveZoneTicketItemsFromReservationMutationVariables
  >(REMOVE_ZONE_TICKET_ITEMS_FROM_RESERVATION_MUTATION)
  return useCallback(
    (reservationId: number, eventSeatUUID: string, count: number) =>
      removeZoneTicketItemsFromReservation({
        variables: {
          reservationId,
          eventSeatUUID,
          count
        }
      }),
    [removeZoneTicketItemsFromReservation]
  )
}

const ADD_DISCOUNT_TO_CART_TICKET_ITEMS = gql`
  mutation addDiscountToCartItems(
    $cartId: Int!
    $itemIds: [Int!]!
    $discountId: Int!
    $discountCodeId: Int
    $customerLoyaltyId: NonEmptyString
  ) {
    addDiscountToCartTicketItems(
      cartId: $cartId
      itemIds: $itemIds
      discountId: $discountId
      discountCodeId: $discountCodeId
      customerLoyaltyId: $customerLoyaltyId
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useAddDiscountToCartTicketItems = () => {
  const [addDiscountToCartTicketItems] = useMutation<
    AddDiscountToCartItemsMutation,
    AddDiscountToCartItemsMutationVariables
  >(ADD_DISCOUNT_TO_CART_TICKET_ITEMS)
  return useCallback(
    (variables: AddDiscountToCartItemsMutationVariables) =>
      addDiscountToCartTicketItems({variables}),
    [addDiscountToCartTicketItems]
  )
}

const INCREMENT_DISCOUNT_QUANTITY_FOR_CART_TICKET_ITEMS = gql`
  mutation incrementDiscountQuantityForCartTicketItems(
    $cartId: Int!
    $eventId: Int!
    $discountId: Int!
    $discountCodeId: Int
    $increment: NonNegativeInt
    $customerLoyaltyId: NonEmptyString
  ) {
    incrementDiscountQuantityForCartTicketItems(
      cartId: $cartId
      eventId: $eventId
      discountId: $discountId
      discountCodeId: $discountCodeId
      increment: $increment
      customerLoyaltyId: $customerLoyaltyId
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useIncrementDiscountQuantityForCartTicketItems = () => {
  const [incrementDiscountQuantityForCartTicketItems] = useMutation<
    IncrementDiscountQuantityForCartTicketItemsMutation,
    IncrementDiscountQuantityForCartTicketItemsMutationVariables
  >(INCREMENT_DISCOUNT_QUANTITY_FOR_CART_TICKET_ITEMS)
  return useCallback(
    (variables: IncrementDiscountQuantityForCartTicketItemsMutationVariables) =>
      incrementDiscountQuantityForCartTicketItems({variables}),
    [incrementDiscountQuantityForCartTicketItems]
  )
}

const DECREMENT_DISCOUNT_QUANTITY_FROM_CART_TICKET_ITEMS = gql`
  mutation decrementDiscountQuantityFromCartTicketItems(
    $cartId: Int!
    $eventId: Int!
    $discountId: Int!
    $discountCodeId: Int
    $decrement: NonNegativeInt
  ) {
    decrementDiscountQuantityFromCartTicketItems(
      cartId: $cartId
      eventId: $eventId
      discountId: $discountId
      discountCodeId: $discountCodeId
      decrement: $decrement
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useDecrementDiscountQuantityFromCartTicketItems = () => {
  const [decrementDiscountQuantityFromCartTicketItems] = useMutation<
    DecrementDiscountQuantityFromCartTicketItemsMutation,
    DecrementDiscountQuantityFromCartTicketItemsMutationVariables
  >(DECREMENT_DISCOUNT_QUANTITY_FROM_CART_TICKET_ITEMS)
  return useCallback(
    (
      variables: DecrementDiscountQuantityFromCartTicketItemsMutationVariables
    ) => decrementDiscountQuantityFromCartTicketItems({variables}),
    [decrementDiscountQuantityFromCartTicketItems]
  )
}

const REMOVE_DISCOUNT_FROM_ITEM = gql`
  mutation removeDiscountFromItem(
    $itemId: Int!
    $cartId: Int!
    $discountId: Int!
  ) {
    removeDiscountFromItem(
      itemId: $itemId
      cartId: $cartId
      discountId: $discountId
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useRemoveDiscountFromItem = () => {
  const [removeDiscountFromItem] = useMutation<
    RemoveDiscountFromItemMutation,
    RemoveDiscountFromItemMutationVariables
  >(REMOVE_DISCOUNT_FROM_ITEM)
  return useCallback(
    (variables: RemoveDiscountFromItemMutationVariables) =>
      removeDiscountFromItem({variables}),
    [removeDiscountFromItem]
  )
}

export const NARROW_SALE_PROPERTIES_FRAGMENT = gql`
  fragment NarrowSaleProperties on Sale {
    id
    sellingChannel
    price
    lead {
      ...LeadProperties
    }
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    cart {
      id
    }
    createdByName
    createdAt
    client {
      id
      currency
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TOUR_ITEM_PROPERTIES_FRAGMENT}
`

const NARROW_RESERVATION_PROPERTIES_FRAGMENT = gql`
  fragment NarrowReservationProperties on Reservation {
    id
    sellingChannel
    price
    createdAt
    createdByName
    expireAt
    lead {
      ...LeadProperties
    }
    items {
      ...TicketItemProperties
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
`

export const LATEST_EVENT_SEAT_TICKET_ITEM = gql`
  query latestEventSeatTicketItem($eventSeatId: Int!) {
    latestEventSeatTicketItem(eventSeatId: $eventSeatId) {
      cartId
      reservation {
        ...NarrowReservationProperties
      }
      sale {
        ...NarrowSaleProperties
      }
      price
    }
  }
  ${NARROW_RESERVATION_PROPERTIES_FRAGMENT}
  ${NARROW_SALE_PROPERTIES_FRAGMENT}
`

const BROAD_DISCOUNT_CODE_PROPERTIES_FRAGMENT = gql`
  fragment BroadDiscountCodeProperties on DiscountCode {
    id
    name
    state
    usageLimit
    usageCount
    usageLimitPerOrder
    activationDate
    expirationDate
    createdByName
    createdAt
    updatedByName
    updatedAt
    discount {
      ...DiscountProperties
    }
  }
  ${DISCOUNT_PROPERTIES_FRAGMENT}
`

export const CHECK_DISCOUNT_CODE_FOR_CART = gql`
  query checkDiscountCodeForCart(
    $cartId: Int!
    $eventId: Int!
    $codeName: String!
  ) {
    checkDiscountCodeForCart(
      cartId: $cartId
      eventId: $eventId
      codeName: $codeName
    ) {
      ...BroadDiscountCodeProperties
    }
  }
  ${BROAD_DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`

export const CHECK_DISCOUNT_CODE_FOR_RESERVATION = gql`
  query checkDiscountCodeForReservation(
    $reservationId: Int!
    $codeName: String!
  ) {
    checkDiscountCodeForReservation(
      reservationId: $reservationId
      codeName: $codeName
    ) {
      ...BroadDiscountCodeProperties
    }
  }
  ${BROAD_DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`

const ADD_DISCOUNT_TO_RESERVATION_TICKET_ITEMS = gql`
  mutation addDiscountToReservationTicketItems(
    $reservationId: Int!
    $itemIds: [Int!]!
    $discountId: Int!
    $discountCodeId: Int
    $customerLoyaltyId: NonEmptyString
  ) {
    addDiscountToReservationTicketItems(
      reservationId: $reservationId
      itemIds: $itemIds
      discountId: $discountId
      discountCodeId: $discountCodeId
      customerLoyaltyId: $customerLoyaltyId
    ) {
      ...ReservationDetail
    }
  }
  ${RESERVATION_DETAIL_FRAGMENT}
`

export const useAddDiscountToReservationTicketItems = () => {
  const [addDiscountToReservationTicketItems] = useMutation<
    AddDiscountToReservationTicketItemsMutation,
    AddDiscountToReservationTicketItemsMutationVariables
  >(ADD_DISCOUNT_TO_RESERVATION_TICKET_ITEMS)
  return useCallback(
    (variables: AddDiscountToReservationTicketItemsMutationVariables) =>
      addDiscountToReservationTicketItems({variables}),
    [addDiscountToReservationTicketItems]
  )
}

const REMOVE_DISCOUNT_FROM_RESERVATION_TICKET_ITEM = gql`
  mutation removeDiscountFromReservationTicketItem(
    $discountId: Int!
    $reservationId: Int!
    $itemId: Int!
  ) {
    removeDiscountFromReservationTicketItem(
      discountId: $discountId
      reservationId: $reservationId
      itemId: $itemId
    ) {
      ...ReservationDetail
    }
  }
  ${RESERVATION_DETAIL_FRAGMENT}
`

export const useRemoveDiscountFromReservationTicketItem = () => {
  const [removeDiscountFromReservationTicketItem] = useMutation<
    RemoveDiscountFromReservationTicketItemMutation,
    RemoveDiscountFromReservationTicketItemMutationVariables
  >(REMOVE_DISCOUNT_FROM_RESERVATION_TICKET_ITEM)
  return useCallback(
    (variables: RemoveDiscountFromReservationTicketItemMutationVariables) =>
      removeDiscountFromReservationTicketItem({variables}),
    [removeDiscountFromReservationTicketItem]
  )
}

const INCREMENT_DISCOUNT_QUANTITY_FOR_RESERVATION_TICKET_ITEMS = gql`
  mutation incrementDiscountQuantityForReservationTicketItems(
    $reservationId: Int!
    $discountId: Int!
    $discountCodeId: Int
    $increment: NonNegativeInt
    $customerLoyaltyId: NonEmptyString
  ) {
    incrementDiscountQuantityForReservationTicketItems(
      reservationId: $reservationId
      discountId: $discountId
      discountCodeId: $discountCodeId
      increment: $increment
      customerLoyaltyId: $customerLoyaltyId
    ) {
      ...ReservationDetail
    }
  }
  ${RESERVATION_DETAIL_FRAGMENT}
`

export const useIncrementDiscountQuantityForReservationTicketItems = () => {
  const [incrementDiscountQuantityForReservationTicketItems] = useMutation<
    IncrementDiscountQuantityForReservationTicketItemsMutation,
    IncrementDiscountQuantityForReservationTicketItemsMutationVariables
  >(INCREMENT_DISCOUNT_QUANTITY_FOR_RESERVATION_TICKET_ITEMS)
  return useCallback(
    (
      variables: IncrementDiscountQuantityForReservationTicketItemsMutationVariables
    ) => incrementDiscountQuantityForReservationTicketItems({variables}),
    [incrementDiscountQuantityForReservationTicketItems]
  )
}

export const DISCOUNT_CODE = gql`
  query discountCode($id: Int!) {
    discountCode(id: $id) {
      ...BroadDiscountCodeProperties
    }
  }
  ${BROAD_DISCOUNT_CODE_PROPERTIES_FRAGMENT}
`

const DECREMENT_DISCOUNT_QUANTITY_FROM_RESERVATION_TICKET_ITEMS = gql`
  mutation decrementDiscountQuantityFromReservationTicketItems(
    $reservationId: Int!
    $discountId: Int!
    $discountCodeId: Int
    $decrement: NonNegativeInt
  ) {
    decrementDiscountQuantityFromReservationTicketItems(
      reservationId: $reservationId
      discountId: $discountId
      discountCodeId: $discountCodeId
      decrement: $decrement
    ) {
      ...ReservationDetail
    }
  }
  ${RESERVATION_DETAIL_FRAGMENT}
`

export const useDecrementDiscountQuantityFromReservationTicketItems = () => {
  const [decrementDiscountQuantityFromReservationTicketItems] = useMutation<
    DecrementDiscountQuantityFromReservationTicketItemsMutation,
    DecrementDiscountQuantityFromReservationTicketItemsMutationVariables
  >(DECREMENT_DISCOUNT_QUANTITY_FROM_RESERVATION_TICKET_ITEMS)
  return useCallback(
    (
      variables: DecrementDiscountQuantityFromReservationTicketItemsMutationVariables
    ) => decrementDiscountQuantityFromReservationTicketItems({variables}),
    [decrementDiscountQuantityFromReservationTicketItems]
  )
}

export const CURRENT_CART_QUERY = gql`
  query currentCart($cartId: Int!) {
    cart(cartId: $cartId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

const UPDATE_SEATING_TICKET_ITEMS_QUANTITY_IN_CART_MUTATION = gql`
  mutation UpdateSeatingTicketItemsQuantityInCart(
    $cartId: Int
    $eventId: Int!
    $eventSeatUUIDs: [String!]!
  ) {
    updateSeatingTicketItemsQuantityInCart(
      cartId: $cartId
      eventId: $eventId
      eventSeatUUIDs: $eventSeatUUIDs
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useUpdateSeatingTicketItemsQuantityInCart = () => {
  const [updateSeatingTicketItemsQuantityInCart] = useMutation<
    UpdateSeatingTicketItemsQuantityInCartMutation,
    UpdateSeatingTicketItemsQuantityInCartMutationVariables
  >(UPDATE_SEATING_TICKET_ITEMS_QUANTITY_IN_CART_MUTATION)
  return useCallback(
    (variables: UpdateSeatingTicketItemsQuantityInCartMutationVariables) =>
      updateSeatingTicketItemsQuantityInCart({variables}),
    [updateSeatingTicketItemsQuantityInCart]
  )
}

export const GET_LEADS = gql`
  query leads($filter: LeadsFilterInput!) {
    leads(filter: $filter) {
      id
      data {
        name
        email
      }
    }
  }
`

const INCREMENT_TOUR_ITEM_QUANTITY_IN_RESERVATION = gql`
  ${RESERVATION_DETAIL_FRAGMENT}
  mutation IncrementTourItemQuantityInReservation(
    $reservationId: PositiveInt!
    $admissionTypeAssignmentId: PositiveInt!
    $increment: PositiveInt!
  ) {
    incrementTourItemQuantityInReservation(
      reservationId: $reservationId
      admissionTypeAssignmentId: $admissionTypeAssignmentId
      increment: $increment
    ) {
      ...ReservationDetail
    }
  }
`

export const useIncrementTourItemQuantityInReservation = () => {
  const [incrementTourItemQuantityInReservation] = useMutation<
    IncrementTourItemQuantityInReservationMutation,
    IncrementTourItemQuantityInReservationMutationVariables
  >(INCREMENT_TOUR_ITEM_QUANTITY_IN_RESERVATION)
  return useCallback(
    (variables: IncrementTourItemQuantityInReservationMutationVariables) =>
      incrementTourItemQuantityInReservation({variables}),
    [incrementTourItemQuantityInReservation]
  )
}

const DECREMENT_TOUR_ITEM_QUANTITY_IN_RESERVATION = gql`
  ${RESERVATION_DETAIL_FRAGMENT}
  mutation DecrementTourItemQuantityInReservation(
    $reservationId: PositiveInt!
    $admissionTypeAssignmentId: PositiveInt!
    $decrement: PositiveInt!
  ) {
    decrementTourItemQuantityInReservation(
      reservationId: $reservationId
      admissionTypeAssignmentId: $admissionTypeAssignmentId
      decrement: $decrement
    ) {
      ...ReservationDetail
    }
  }
`

export const useDecrementTourItemQuantityInReservation = () => {
  const [decrementTourItemQuantityInReservation] = useMutation<
    DecrementTourItemQuantityInReservationMutation,
    DecrementTourItemQuantityInReservationMutationVariables
  >(DECREMENT_TOUR_ITEM_QUANTITY_IN_RESERVATION)
  return useCallback(
    (variables: DecrementTourItemQuantityInReservationMutationVariables) =>
      decrementTourItemQuantityInReservation({variables}),
    [decrementTourItemQuantityInReservation]
  )
}

export const CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS = gql`
  ${BROAD_DISCOUNT_CODE_PROPERTIES_FRAGMENT}
  query CheckDiscountCodeForTourItems(
    $cartId: PositiveInt!
    $tourTimeSlotId: PositiveInt!
    $discountCodeName: NonEmptyString!
  ) {
    checkDiscountCodeForTourItems(
      cartId: $cartId
      tourTimeSlotId: $tourTimeSlotId
      discountCodeName: $discountCodeName
    ) {
      ...BroadDiscountCodeProperties
    }
  }
`

const CASH_DESK_CUSTOMER_FIELDS = gql`
  fragment CashDeskCustomerFields on Customer {
    id
    loyaltyId
    loyaltyLevelExpirationDate
    lead {
      id
      data {
        name
        companyName
        phone
        email
        note
        internalNote
      }
    }
    loyaltyScore {
      totalPurchaseAmount
      soldCartsCount
      totalTicketsCount
    }
  }
`

export const CASH_DESK_CUSTOMERS = gql`
  ${CASH_DESK_CUSTOMER_FIELDS}
  ${PAGINATION_FRAGMENT}
  query CashDeskCustomers(
    $filter: CustomersFilterInput
    $paginationInput: PaginationInput!
  ) {
    customers(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...CashDeskCustomerFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

const CREATE_CASHDESK_CUSTOMER = gql`
  ${CASH_DESK_CUSTOMER_FIELDS}
  mutation CreateCashDeskCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ...CashDeskCustomerFields
    }
  }
`

export const useCreateCashDeskCustomer = () => {
  const [createCashDeskCustomer] = useMutation<
    CreateCashDeskCustomerMutation,
    CreateCashDeskCustomerMutationVariables
  >(CREATE_CASHDESK_CUSTOMER)
  return useCallback(
    (variables: CreateCashDeskCustomerMutationVariables) =>
      createCashDeskCustomer({variables}),
    [createCashDeskCustomer]
  )
}

export const CUSTOMERS_FOR_DISCOUNT = gql`
  ${PAGINATION_FRAGMENT}
  query CustomersForDiscount(
    $filter: CustomersFilterInput
    $paginationInput: PaginationInput!
  ) {
    customers(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        loyaltyId
        username
        loyaltyLevelExpirationDate
        lead {
          id
          data {
            name
          }
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`
