import {ProductTypeState} from '../../../../__generated__/schema'

export enum ProductTypeFormField {
  Name = 'name',
  Abbreviation = 'abbreviation',
  State = 'state'
}

export interface IProductTypeForm {
  [ProductTypeFormField.Name]: string
  [ProductTypeFormField.Abbreviation]: string
  [ProductTypeFormField.State]: ProductTypeState
}

export enum ProductTypeFormLocation {
  Create = 'create',
  Edit = 'edit'
}
