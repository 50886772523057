import Button from '@mui/material/Button'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ReservationDetailFragment,
  ReservationDetailQuery
} from '../../../../../__generated__/schema'

import {Theme} from '../../../../../theme'
import {RenderOnData} from '../../../../common'
import {useReservation} from '../../cashDesk/graphql'

interface IReservationDetailDataProviderProps {
  id: number
  children: (
    reservation: ReservationDetailFragment & {
      activity:
        | NonNullable<ReservationDetailFragment['event']>
        | NonNullable<ReservationDetailFragment['tourTimeSlot']>
    }
  ) => React.ReactElement
  onClose: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  errorButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
}))

export const ReservationDetailDataProvider: React.FC<IReservationDetailDataProviderProps> =
  ({id, children, onClose}: IReservationDetailDataProviderProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {data, error, loading} = useReservation(id)
    return (
      <div className={classes.root}>
        <RenderOnData
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Could not load reservation detail data')}
          dataCondition={(data) => Boolean(data.reservation)}
        >
          {(data: ReservationDetailQuery) =>
            data.reservation.event
              ? children({
                  ...data.reservation,
                  activity: data.reservation.event
                })
              : data.reservation.tourTimeSlot
              ? children({
                  ...data.reservation,
                  activity: data.reservation.tourTimeSlot
                })
              : null
          }
        </RenderOnData>
        {error && (
          <div className={classes.errorButtonBox}>
            <Button autoFocus onClick={onClose} color="primary">
              {t('Close')}
            </Button>
          </div>
        )}
      </div>
    )
  }
