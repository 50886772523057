import {useTranslation} from 'react-i18next'
import {CommonSettingsScriptLocation} from '../__generated__/schema'

export const useTranslateCommonSettingsScriptLocation = () => {
  const {t} = useTranslation()
  const translated: Record<CommonSettingsScriptLocation, string> = {
    [CommonSettingsScriptLocation.Body]: t(
      'CommonSettingsScriptLocation->Body'
    ),
    [CommonSettingsScriptLocation.Head]: t('CommonSettingsScriptLocation->Head')
  }
  return (location: CommonSettingsScriptLocation) => translated[location]
}
