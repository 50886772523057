import {Dialog} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Carousel from 'nuka-carousel'
import React from 'react'
import {GalleryImage2} from '../utils'
import {CenterLeftControls} from './CenterLeftControls'
import {CenterRightControls} from './CenterRightControls'
import {CenterTopControls} from './CenterTopControls'

const useStyles = makeStyles(() => ({
  root: {
    background: 'black',
    position: 'relative',
    height: '100%',
    '& .slider': {
      outline: 'none',
      '& .slider-control-centerleft, .slider-control-centerright': {
        padding: 0,
        transform: 'none !important',
        top: '10% !important'
      },
      '& .slider-frame .slider-list .slider-slide': {
        height: '100% !important',
        outline: 'none',
        '& > img': {
          width: 'auto',
          outline: 'none'
        }
      }
    }
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  controlArrow: {
    width: 48,
    height: 48,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: 'white',
    '&.Mui-disabled': {
      color: 'grey'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  }
}))

interface IImagePreviewProps {
  isOpen: boolean
  onClose: () => void
  images: GalleryImage2[]
  startIndex: number
  currentImage: number
  onBackButtonClick: () => void
  onDeleteButtonClick?: (id: number) => void
  onPrivateButtonClick?: (id: number) => void
  setCurrentImage: (id: number) => void
}

export const ImagePreview: React.FC<IImagePreviewProps> = ({
  isOpen,
  onClose,
  images,
  startIndex,
  currentImage,
  onBackButtonClick,
  onDeleteButtonClick,
  onPrivateButtonClick,
  setCurrentImage
}: IImagePreviewProps) => {
  const classes = useStyles()
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <div className={classes.root}>
        <Carousel
          transitionMode="fade"
          slideIndex={startIndex}
          dragging={false}
          renderBottomCenterControls={null}
          renderTopCenterControls={() => (
            <CenterTopControls
              images={images}
              startIndex={startIndex}
              currentImage={currentImage}
              onPrivateButtonClick={onPrivateButtonClick}
              onDeleteButtonClick={onDeleteButtonClick}
              onBackButtonClick={onBackButtonClick}
            />
          )}
          renderCenterLeftControls={({previousSlide, currentSlide}) => (
            <CenterLeftControls
              previousSlide={previousSlide}
              currentSlide={currentSlide}
              className={classes.controlArrow}
            />
          )}
          renderCenterRightControls={({nextSlide, currentSlide}) => (
            <CenterRightControls
              nextSlide={nextSlide}
              currentSlide={currentSlide}
              imagesLength={images.length}
              className={classes.controlArrow}
            />
          )}
          afterSlide={(slideIndex) => setCurrentImage(images[slideIndex].id)}
        >
          {images.map((image) => (
            <img
              key={image.src}
              src={image.src}
              alt={image.imageName}
              className={classes.image}
            />
          ))}
        </Carousel>
      </div>
    </Dialog>
  )
}
