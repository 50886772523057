import {Box, Button, Grid, GridProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Theme} from '../../theme'

export interface IBottomActionsBarProps {
  children: React.ReactNode
  justify?: GridProps['justifyContent']
}

const PADDING = 14
export const BOTTOM_ACTION_BAR_HEIGHT = 36 + PADDING * 2

const useStyles = makeStyles<Theme>((theme) => ({
  bottomBarWrapper: {
    position: 'fixed',
    zIndex: 1,
    bottom: 0,
    left: 0,
    width: '100%',
    padding: `${PADDING}px ${theme.spacing(3)}`,
    height: BOTTOM_ACTION_BAR_HEIGHT,
    background: theme.palette.background.paper,
    borderTop: '1px solid #E0E0E0'
  },
  buttonsWrapper: {
    '& > *': {
      marginLeft: theme.spacing(2)
    }
  },
  wrapper: {
    height: '100%',
    paddingBottom: BOTTOM_ACTION_BAR_HEIGHT,
    width: '100%',
    overflow: 'hidden'
  }
}))

/**
 * @deprecated - please use BottomBarActionsWrapper and place it into CenteredLayout's bottomBar property
 */
export const BottomActionsBar: React.FC<IBottomActionsBarProps> = ({
  children,
  justify
}: IBottomActionsBarProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent={justify || 'flex-end'}
      alignItems="center"
      className={cn(classes.bottomBarWrapper, classes.buttonsWrapper)}
    >
      {children}
    </Grid>
  )
}

// Should be used only within "BottomActionsBar"
export const BottomActionsBarButtonsGroup: React.FC<IBottomActionsBarProps> = ({
  children,
  justify
}: IBottomActionsBarProps) => {
  const classes = useStyles()

  return (
    <Grid item>
      <Grid
        container
        justifyContent={justify || 'flex-end'}
        className={classes.buttonsWrapper}
      >
        {children}
      </Grid>
    </Grid>
  )
}

interface IBottomActionsBarWrapperProps {
  children: React.ReactNode
}

/**
 * @deprecated - please use CenteredLayout's bottomBar property
 */
export const BottomActionsBarWrapper: React.FC<IBottomActionsBarWrapperProps> =
  ({children}: IBottomActionsBarWrapperProps) => {
    const classes = useStyles()

    return <Box className={classes.wrapper}>{children}</Box>
  }

interface IBottomBackButtonProps {
  label?: string
}

export const BottomBackButton: React.FC<IBottomBackButtonProps> = ({
  label
}: IBottomBackButtonProps) => {
  const {t} = useTranslation()
  const history = useHistory()

  const onClick = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Button color="primary" onClick={onClick}>
      {label || t('Cancel')}
    </Button>
  )
}
