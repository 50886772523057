import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {LocaleCode} from '../__generated__/schema'

export const useGetECommerceSettingsFieldsLabel = () => {
  const {t} = useTranslation()
  return useCallback(
    (localeCode: LocaleCode) => {
      switch (localeCode) {
        case LocaleCode.Sk:
          return {
            name: t('Slovak name'),
            description: t('Slovak description')
          }
        case LocaleCode.Cs:
          return {
            name: t('Czech name'),
            description: t('Czech description')
          }
        case LocaleCode.En:
          return {
            name: t('English name'),
            description: t('English description')
          }
        case LocaleCode.Hu:
          return {
            name: t('Hungary name'),
            description: t('Hungary description')
          }
        default:
          return {
            name: '',
            description: ''
          }
      }
    },
    [t]
  )
}
