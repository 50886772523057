import {Box, Button} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CashDeskAdmissionRateFieldsFragment,
  CashDeskTourTimeSlotQuery,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {
  isCustomerDisplayEnabled,
  usePostCustomerDisplayMessage
} from '../../../../../../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../../../../../../customerDisplayBroadcastChannel/types'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../../hooks/storage'
import {TourTimeSlotViewMode} from '../../../../../../types'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {routeTo} from '../../../../../../utils/routes'
import {Blank} from '../../../../../visual/Blank'
import {TourTimeSlotViewModeSwitchButton} from '../../../components/TourTimeSlotViewModeSwitchButton'
import {isTourItemPropertiesFragment} from '../../../types'
import {useCurrentCart} from '../../CurrentCartContext'
import {EditTourTicketQuantityDialog} from '../../EditTourTicketQuantityDialog'
import {TourTimeSlotGridView} from '../../TourTimeSlotGridView'
import {TourTimeSlotListView} from '../../TourTimeSlotListView'
import {useGetTourItemQuantity} from '../../utils'

interface IContentProps {
  tourTimeSlot: CashDeskTourTimeSlotQuery['tourTimeSlot']
  onIncrementButtonClick: (
    admissionTypeAssignmentId: number,
    increment: number,
    admissionTypeName: string
  ) => void
  onDecrementButtonClick: (
    admissionTypeAssignmentId: number,
    decrement: number
  ) => void
}

export const Content: React.FC<IContentProps> = ({
  tourTimeSlot,
  onIncrementButtonClick,
  onDecrementButtonClick
}: IContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {currentCart} = useCurrentCart()
  const {getIncrementTourItemQuantity, getDecrementTourItemQuantity} =
    useGetTourItemQuantity(
      (currentCart?.items || []).filter(isTourItemPropertiesFragment)
    )
  const [
    selectedAdmissionTypesAssignment,
    setSelectedAdmissionTypesAssignment
  ] =
    useState<
      | CashDeskAdmissionRateFieldsFragment['admissionTypesAssignments'][number]
      | null
    >(null)
  const [defaultTourTimeSlotViewMode] =
    useLocalStorageState<TourTimeSlotViewMode>(
      LocalStorageKey.TourTimeSlotViewMode,
      TourTimeSlotViewMode.GridView
    )
  const [tourTimeSlotViewMode, setTourTimeSlotViewMode] =
    useState<TourTimeSlotViewMode>(defaultTourTimeSlotViewMode)
  const getCardClickHandler = useCallback(
    (
        assignmentId: number,
        startingQuantity: number,
        admissionTypeName: string
      ) =>
      (e: React.MouseEvent) => {
        e.stopPropagation()
        onIncrementButtonClick(
          assignmentId,
          getIncrementTourItemQuantity(
            startingQuantity,
            assignmentId,
            tourTimeSlot.id
          ),
          admissionTypeName
        )
      },
    [getIncrementTourItemQuantity, onIncrementButtonClick, tourTimeSlot.id]
  )
  const getDecrementButtonClickHandler = useCallback(
    (assignmentId: number, startingQuantity: number) =>
      (e: React.MouseEvent) => {
        e.stopPropagation()
        onDecrementButtonClick(
          assignmentId,
          getDecrementTourItemQuantity(
            startingQuantity,
            assignmentId,
            tourTimeSlot.id
          )
        )
      },
    [getDecrementTourItemQuantity, onDecrementButtonClick, tourTimeSlot.id]
  )
  const handleOpenEditQuantityDialog = useCallback(
    (
        assignment: CashDeskAdmissionRateFieldsFragment['admissionTypesAssignments'][number]
      ) =>
      (e: React.MouseEvent) => {
        e.stopPropagation()
        setSelectedAdmissionTypesAssignment(assignment)
      },
    []
  )
  const handleQuantityChange = useCallback(
    ({
      admissionTypeAssignmentId,
      increment,
      decrement,
      admissionTypeName
    }: {
      admissionTypeAssignmentId: number
      increment?: number
      decrement?: number
      admissionTypeName: string
    }) => {
      if (increment) {
        onIncrementButtonClick(
          admissionTypeAssignmentId,
          increment,
          admissionTypeName
        )
      } else if (decrement) {
        onDecrementButtonClick(admissionTypeAssignmentId, decrement)
      }
      setSelectedAdmissionTypesAssignment(null)
    },
    [onDecrementButtonClick, onIncrementButtonClick]
  )
  const handleViewModeChange = useCallback(
    () =>
      setTourTimeSlotViewMode(
        tourTimeSlotViewMode === TourTimeSlotViewMode.GridView
          ? TourTimeSlotViewMode.ListView
          : TourTimeSlotViewMode.GridView
      ),
    [tourTimeSlotViewMode]
  )
  const getQuantity = useCallback(
    (assignmentId: number) =>
      (currentCart?.items || [])
        .filter(isTourItemPropertiesFragment)
        .filter((i) => i.tourTimeSlotId === tourTimeSlot.id)
        .filter((i) => i.reservation === null)
        .filter((i) => i.admissionTypeAssignmentId === assignmentId).length,
    [currentCart?.items, tourTimeSlot.id]
  )
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()

  useEffect(() => {
    if (
      isCustomerDisplayEnabled() &&
      tourTimeSlot.admissionRate.admissionTypesAssignments.length
    ) {
      postCustomerDisplayMessage({
        type: CustomerDisplayMessageType.DisplayTourTimeSlotView,
        payload: tourTimeSlot
      })
    }
  }, [tourTimeSlot, postCustomerDisplayMessage])

  if (tourTimeSlot.admissionRate.admissionTypesAssignments.length === 0) {
    return (
      <Blank
        title={t('No admissions have been found')}
        description={t(
          'There are no admissions available for retail right now. Please adjust admission rate settings or contact your supervisor.'
        )}
        actions={
          P([PermissionCode.ManageAdmissionRates]) && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(
                  routeTo.admin.tours.admissionRateDetail(
                    tourTimeSlot.tour.id,
                    tourTimeSlot.admissionRate.id
                  )
                )
              }
            >
              {t('Admission rate')}
            </Button>
          )
        }
      />
    )
  }
  return (
    <Box sx={{position: 'relative'}}>
      {tourTimeSlotViewMode === TourTimeSlotViewMode.GridView ? (
        <TourTimeSlotGridView
          admissionRate={tourTimeSlot.admissionRate}
          quantityGetter={getQuantity}
          onCardClick={getCardClickHandler}
          onDecrementButtonClick={getDecrementButtonClickHandler}
          onOpenEditQuantityDialogClick={handleOpenEditQuantityDialog}
        />
      ) : (
        <TourTimeSlotListView
          admissionRate={tourTimeSlot.admissionRate}
          quantityGetter={getQuantity}
          onIncrementButtonClick={getCardClickHandler}
          onDecrementButtonClick={getDecrementButtonClickHandler}
          onOpenEditQuantityDialogClick={handleOpenEditQuantityDialog}
        />
      )}
      <TourTimeSlotViewModeSwitchButton
        currentMode={tourTimeSlotViewMode}
        onClick={handleViewModeChange}
      />
      {selectedAdmissionTypesAssignment && (
        <EditTourTicketQuantityDialog
          admissionTypesAssignment={selectedAdmissionTypesAssignment}
          onClose={() => setSelectedAdmissionTypesAssignment(null)}
          itemsInCart={
            (currentCart?.items || [])
              .filter(isTourItemPropertiesFragment)
              .filter((i) => i.tourTimeSlotId === tourTimeSlot.id)
              .filter((i) => i.reservation === null)
              .filter(
                (i) =>
                  i.admissionTypeAssignmentId ===
                  selectedAdmissionTypesAssignment.id
              ).length
          }
          onSaveClick={handleQuantityChange}
        />
      )}
    </Box>
  )
}
