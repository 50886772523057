import {Box, SxProps, Typography, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {AuditoriumPreviewFieldsFragment} from '../../../../__generated__/schema'
import {ReactComponent as TicketIcon} from '../../../../assets/ticket.svg'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {JoinedChildren} from '../../../common/JoinedChildren'

interface ITicketPricesRowProps {
  event: AuditoriumPreviewFieldsFragment
  sx: SxProps<Theme>
}

export const TicketPricesRow: React.FC<ITicketPricesRowProps> = ({
  event,
  sx
}: ITicketPricesRowProps) => {
  const {t} = useTranslation()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'background.paper',
        display: 'flex',
        overflowY: 'auto',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        ...sx
      }}
    >
      <Typography variant="body2">{t('Ticket prices')}</Typography>
      <JoinedChildren sx={{flexWrap: 'noWrap', flexShrink: 0, gap: 3}}>
        {event.activePricing.ticketTypes.map((ticketType) => (
          <Box
            key={ticketType.id}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              height: 18,
              gap: 2
            }}
            component="span"
          >
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              <TicketIcon
                fill={ticketType.color || theme.palette.primary.main}
              />
              <Typography
                variant="subtitle2"
                sx={{lineHeight: '100%'}}
                color="textPrimary"
              >
                {ticketType.name}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{lineHeight: '100%'}}
              color="textSecondary"
            >
              {translateEffectiveClientPrice(ticketType.price)}
            </Typography>
          </Box>
        ))}
      </JoinedChildren>
    </Box>
  )
}
