import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {VoucherDetail} from './voucherDetail'
import {VouchersPage} from './VouchersPage'

export const Vouchers: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadVoucher]) && (
        <Route
          path={routeTo.admin.vouchers.detail(':voucherId')}
          component={VoucherDetail}
        />
      )}
      <Route
        path={routeTo.admin.vouchers.index()}
        component={VouchersPage}
        exact
      />
    </Switch>
  )
}
