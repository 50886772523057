import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'
import {
  GetClientCountryCodesQuery,
  GetClientCountryCodesQueryVariables
} from '../__generated__/schema'
import {useTranslateCountry} from './countries'

const GET_AVAILABLE_COUNTRY_CODES = gql`
  query getClientCountryCodes {
    clientCountryCodes
  }
`

export const useGetClientCountryCodes = () => {
  const {data, error} = useQuery<
    GetClientCountryCodesQuery,
    GetClientCountryCodesQueryVariables
  >(GET_AVAILABLE_COUNTRY_CODES)
  if (error) {
    throw new Error('Loading country codes available for client failed!')
  }
  return data?.clientCountryCodes || []
}

export const useGetClientCountrySelectData = () => {
  const clientCountryCodes = useGetClientCountryCodes()
  const translateCountry = useTranslateCountry()
  return clientCountryCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: translateCountry(code)
    }),
    {}
  )
}
