import {useTranslation} from 'react-i18next'
import {ProductState} from '../__generated__/schema'

export const useTranslateProductState = () => {
  const {t} = useTranslation()
  const translatedProductStates: Record<ProductState, string> = {
    [ProductState.Active]: t<string>('ProductState->Active'),
    [ProductState.Deleted]: t<string>('ProductState->Deleted'),
    [ProductState.Inactive]: t<string>('ProductState->Inactive'),
    [ProductState.Disabled]: t<string>('ProductState->Disabled')
  }
  return (state: ProductState) => translatedProductStates[state]
}

export const useTranslateProductStateDescription = () => {
  const {t} = useTranslation()
  const translatedDescriptions: Record<ProductState, string> = {
    [ProductState.Active]: t<string>('ProductStateDescription->Active'),
    [ProductState.Deleted]: t<string>('ProductStateDescription->Deleted'),
    [ProductState.Inactive]: t<string>('ProductStateDescription->Inactive'),
    [ProductState.Disabled]: t<string>('ProductStateDescription->Disabled')
  }
  return (state: ProductState) => translatedDescriptions[state]
}
