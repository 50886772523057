import {Button, Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  DivisionPropertiesFragment,
  DivisionState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common/EntityState'
import {
  IActionButton,
  IOpenModalButtonProps,
  StateChangeModal
} from '../../../common/StateChangeModal'
import {StatusBarLayout} from '../../../common/StatusBarLayout'
import {
  divisionStateColors,
  useDivisionStateTranslations
} from '../../../constants'
import {useNotifications} from '../../../context/notifications'
import {
  useActivateDivision,
  useDeactivateDivision,
  useDeleteDivision
} from './graphql'

interface IStateActionsBar {
  division: DivisionPropertiesFragment
}

const ActivateDivisionButton: React.FC<IActionButton> = ({
  id
}: IActionButton) => {
  const {t} = useTranslation()
  const activateDivision = useActivateDivision()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async () => {
    try {
      await activateDivision(id)
      addInfoNotification(t('Division was activated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while activating the division.'))
    }
  }

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button variant="outlined" color="primary" onClick={onClick}>
        {t('Activate division')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to activate the division?')}
      message={t('Are you sure you want to activate the division?')}
      submitButtonText={t('Activate')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const DeactivateDivisionButton: React.FC<IActionButton> = ({
  id
}: IActionButton) => {
  const {t} = useTranslation()
  const deactivateDivision = useDeactivateDivision()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async () => {
    try {
      await deactivateDivision(id)
      addInfoNotification(t('Division was deactivated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while deactivating the division.'))
    }
  }

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button variant="outlined" color="primary" onClick={onClick}>
        {t('Deactivate division')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to deactivate the division?')}
      message={t('Are you sure you want to deactivate the division?')}
      submitButtonText={t('Dectivate')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const DeleteDivisionButton: React.FC<IActionButton> = ({id}: IActionButton) => {
  const {t} = useTranslation()
  const deleteDivision = useDeleteDivision(id)
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const history = useHistory()

  const onSubmit = async () => {
    try {
      await deleteDivision()
      history.replace(routeTo.admin.divisions.home())
      addInfoNotification(t('Division was deleted.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while deleting the division.'))
    }
  }

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button color="primary" onClick={onClick}>
        {t('Delete division')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to delete the division?')}
      message={t('Are you sure you want to delete the division?')}
      submitButtonText={t('Delete')}
      OpenModalButton={OpenModalButton}
    />
  )
}

const BottomContent: React.FC<Pick<DivisionPropertiesFragment, 'state'>> = ({
  state
}: Pick<DivisionPropertiesFragment, 'state'>) => {
  const {t} = useTranslation()
  const divisionStateTranslations = useDivisionStateTranslations()

  const config = {
    [DivisionState.Draft]: {
      chipLabel: divisionStateTranslations.draft,
      desc: t('Division is not activated.'),
      style: divisionStateColors.draft
    },
    [DivisionState.Active]: {
      chipLabel: divisionStateTranslations.active,
      desc: t('Division is ready for use.'),
      style: divisionStateColors.active
    },
    [DivisionState.Inactive]: {
      chipLabel: divisionStateTranslations.inactive,
      desc: t('Division is inactive.'),
      style: divisionStateColors.inactive
    }
  }[state]

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <EntityStateChip label={config.chipLabel} colorConf={config.style} />
      </Grid>
      <Grid item>
        <Typography variant="caption">{config.desc}</Typography>
      </Grid>
    </Grid>
  )
}

export const StateActionsBar: React.FC<IStateActionsBar> = ({
  division
}: IStateActionsBar) => {
  let rightSideContent = null
  const bottomContent = <BottomContent state={division.state} />
  const {P} = useEnsurePermissions()
  const canDeleteDivision = P([PermissionCode.DeleteDivision])
  const canActivateDivision = P([PermissionCode.ActivateDivision])
  const canDeactivateDivision = P([PermissionCode.DeactivateDivision])

  if (division.state !== DivisionState.Active) {
    rightSideContent = (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          {division.state === DivisionState.Draft && canDeleteDivision && (
            <DeleteDivisionButton id={division.id} />
          )}
        </Grid>
        <Grid item>
          {canActivateDivision && <ActivateDivisionButton id={division.id} />}
        </Grid>
      </Grid>
    )
  } else {
    rightSideContent = (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          {canDeactivateDivision && (
            <DeactivateDivisionButton id={division.id} />
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <StatusBarLayout
      rightSideContent={rightSideContent}
      bottomContent={bottomContent}
      title={division.name}
      iconType="division"
    />
  )
}
