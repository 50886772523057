import {useMutation, useQuery} from '@apollo/react-hooks'
import {QueryHookOptions} from '@apollo/react-hooks/lib/types'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AvailableInputDataForDailyCashierReportsQuery,
  AvailableInputDataForDailyCashierReportsQueryVariables,
  AvailableInputDataForMonthlyECommerceReportsQuery,
  AvailableInputDataForMonthlyECommerceReportsQueryVariables,
  AvailableInputDataForMonthlyReportsQuery,
  AvailableInputDataForMonthlyReportsQueryVariables,
  CreateDailyCashierPaymentReportInput,
  CreateDailyCashierPaymentReportMutation,
  CreateDailyCashierPaymentReportMutationVariables,
  CreateMonthlyECommercePaymentReportInput,
  CreateMonthlyECommercePaymentReportMutation,
  CreateMonthlyECommercePaymentReportMutationVariables,
  CreateMonthlyPaymentReportInput,
  CreateMonthlyPaymentReportMutation,
  CreateMonthlyPaymentReportMutationVariables,
  PaymentReportQuery,
  PaymentReportQueryVariables,
  PaymentReportsQuery,
  PaymentReportsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const PAYMENT_REPORTS = gql`
  ${USER_FIELDS}
  ${PAGINATION_FRAGMENT}
  query PaymentReports(
    $filter: PaymentReportsFilterInput
    $paginationInput: PaginationInput!
  ) {
    paymentReports(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        reportFrom
        paymentsAmount
        paymentsCount
        revenueVatIncluded
        cashier {
          ...UserFields
        }
        createdAt
        createdBy {
          ...UserFields
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const usePaymentReports = (variables: PaymentReportsQueryVariables) =>
  useQuery<PaymentReportsQuery, PaymentReportsQueryVariables>(PAYMENT_REPORTS, {
    variables,
    fetchPolicy: 'network-only'
  })

const PAYMENT_REPORT = gql`
  ${USER_FIELDS}
  query PaymentReport($id: PositiveInt!) {
    paymentReport(id: $id) {
      id
      reportFrom
      reportTo
      note
      type
      paymentsAmount
      revenueVat
      revenueVatIncluded
      revenueVatExcluded
      paymentsSurplus
      paymentsCount
      cashier {
        ...UserFields
      }
      createdBy {
        ...UserFields
      }
      createdAt
      dataAggregatedByPaymentMethodAndTransactionType {
        paymentMethodId
        paymentMethodName
        transactionTypes {
          transactionType
          intentsAmount
          intentsCount
        }
        intentsAmount
        intentsCount
      }
      dataAggregatedByPaymentMethod {
        paymentMethodId
        intentsAmount
        intentsCount
      }
      dataAggregatedByTransactionType {
        transactionType
        paymentsCount
        paymentsAmount
      }
      dataAggregatedByVatRate {
        vatRate
        revenueVatExcluded
        revenueVat
        revenueVatIncluded
      }
      dataAggregatedByDivision {
        items {
          divisionId
          divisionName
          saleSum
          refundSum
          revenueSum
        }
        totalSaleSum
        totalRefundSum
        totalRevenueSum
      }
      externalFileUrl
    }
  }
`

export const usePaymentReport = (id: number) =>
  useQuery<PaymentReportQuery, PaymentReportQueryVariables>(PAYMENT_REPORT, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const AVAILABLE_INPUT_DATA_FOR_DAILY_CASHIER_REPORTS = gql`
  ${USER_FIELDS}
  query AvailableInputDataForDailyCashierReports($cashierUserId: PositiveInt) {
    availableInputDataForDailyCashierReports(cashierUserId: $cashierUserId) {
      user {
        ...UserFields
      }
      paymentDatesWithoutReport
    }
  }
`

export const useAvailableInputDataForDailyCashierReports = (
  options?: QueryHookOptions<
    AvailableInputDataForDailyCashierReportsQuery,
    AvailableInputDataForDailyCashierReportsQueryVariables
  >
) =>
  useQuery<
    AvailableInputDataForDailyCashierReportsQuery,
    AvailableInputDataForDailyCashierReportsQueryVariables
  >(AVAILABLE_INPUT_DATA_FOR_DAILY_CASHIER_REPORTS, options)

const CREATE_DAILY_CASHIER_PAYMENT_REPORT = gql`
  mutation CreateDailyCashierPaymentReport(
    $input: CreateDailyCashierPaymentReportInput!
  ) {
    createDailyCashierPaymentReport(input: $input) {
      id
    }
  }
`

export const useCreateDailyCashierPaymentReport = () => {
  const [createDailyCashierPaymentReport] = useMutation<
    CreateDailyCashierPaymentReportMutation,
    CreateDailyCashierPaymentReportMutationVariables
  >(CREATE_DAILY_CASHIER_PAYMENT_REPORT)
  return useCallback(
    (input: CreateDailyCashierPaymentReportInput) =>
      createDailyCashierPaymentReport({variables: {input}}),
    [createDailyCashierPaymentReport]
  )
}

const AVAILABLE_INPUT_DATA_FOR_MONTHLY_ECOMMERCE_REPORTS = gql`
  query AvailableInputDataForMonthlyECommerceReports {
    availableInputDataForMonthlyECommerceReports {
      year
      month
    }
  }
`

export const useAvailableInputDataForMonthlyECommerceReports = (
  options?: QueryHookOptions<
    AvailableInputDataForMonthlyECommerceReportsQuery,
    AvailableInputDataForMonthlyECommerceReportsQueryVariables
  >
) => useQuery(AVAILABLE_INPUT_DATA_FOR_MONTHLY_ECOMMERCE_REPORTS, options)

const CREATE_MONTHLY_ECOMMERCE_PAYMENT_REPORT = gql`
  mutation CreateMonthlyECommercePaymentReport(
    $input: CreateMonthlyECommercePaymentReportInput!
  ) {
    createMonthlyECommercePaymentReport(input: $input) {
      id
    }
  }
`

export const useCreateMonthlyECommercePaymentReport = () => {
  const [createMonthlyECommercePaymentReport] = useMutation<
    CreateMonthlyECommercePaymentReportMutation,
    CreateMonthlyECommercePaymentReportMutationVariables
  >(CREATE_MONTHLY_ECOMMERCE_PAYMENT_REPORT)
  return useCallback(
    (input: CreateMonthlyECommercePaymentReportInput) =>
      createMonthlyECommercePaymentReport({variables: {input}}),
    [createMonthlyECommercePaymentReport]
  )
}

const AVAILABLE_INPUT_DATA_FOR_MONTHLY_REPORTS = gql`
  query AvailableInputDataForMonthlyReports {
    availableInputDataForMonthlyReports {
      year
      month
    }
  }
`

export const useAvailableInputDataForMonthlyReports = (
  options?: QueryHookOptions<
    AvailableInputDataForMonthlyReportsQuery,
    AvailableInputDataForMonthlyReportsQueryVariables
  >
) => useQuery(AVAILABLE_INPUT_DATA_FOR_MONTHLY_REPORTS, options)

const CREATE_MONTHLY_PAYMENT_REPORT = gql`
  mutation CreateMonthlyPaymentReport(
    $input: CreateMonthlyPaymentReportInput!
  ) {
    createMonthlyPaymentReport(input: $input) {
      id
    }
  }
`

export const useCreateMonthlyPaymentReport = () => {
  const [createMonthlyPaymentReport] = useMutation<
    CreateMonthlyPaymentReportMutation,
    CreateMonthlyPaymentReportMutationVariables
  >(CREATE_MONTHLY_PAYMENT_REPORT)
  return useCallback(
    (input: CreateMonthlyPaymentReportInput) =>
      createMonthlyPaymentReport({variables: {input}}),
    [createMonthlyPaymentReport]
  )
}
