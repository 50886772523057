import {Box, Typography} from '@mui/material'
import React from 'react'

interface ICashDeskCardProps {
  icon?: React.ReactNode
  title: React.ReactNode
  subTitle?: React.ReactNode
  description?: React.ReactNode
  price: string
  actions: React.ReactNode
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
}

export const CashDeskCard: React.FC<ICashDeskCardProps> = ({
  icon,
  title,
  subTitle,
  description,
  price,
  actions,
  disabled,
  onClick
}: ICashDeskCardProps) => (
  <Box
    sx={{
      width: 256,
      height: 120,
      backgroundColor: 'background.paper',
      borderRadius: 1,
      border: (theme) => `solid 1px ${theme.palette.divider}`,
      p: 0.5,
      opacity: (theme) =>
        disabled ? theme.palette.action.disabledOpacity : undefined,
      cursor: onClick ? 'pointer' : 'normal',
      pointerEvents: disabled ? 'none' : 'auto'
    }}
    onClick={onClick}
  >
    <Box sx={{display: 'flex', gap: 1, flex: 1}}>
      {icon}
      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
        <Typography variant="subtitle2">{title}</Typography>
        {subTitle && (
          <Typography variant="caption" color="textSecondary">
            {subTitle}
          </Typography>
        )}
        {description && (
          <Typography variant="caption" color="textSecondary">
            {description}
          </Typography>
        )}
      </Box>
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="body2">{price}</Typography>
      {actions}
    </Box>
  </Box>
)
