import {useTranslation} from 'react-i18next'
import {MessageState} from '../__generated__/schema'
import {useDateTimeFormatters} from '../utils/formatting'

export const useTranslateMessageState = () => {
  const {t} = useTranslation()
  const translatedState: Record<MessageState, string> = {
    [MessageState.Sent]: t<string>('MessageState->Sent'),
    [MessageState.Draft]: t<string>('MessageState->Draft'),
    [MessageState.Failed]: t<string>('MessageState->Failed'),
    [MessageState.Scheduled]: t<string>('MessageState->Scheduled'),
    [MessageState.Pending]: t<string>('MessageState->Pending')
  }
  return (state: MessageState) => translatedState[state]
}

export const useTranslateMessageStateDescription = (sendAt?: string | null) => {
  const {t} = useTranslation()
  const {formatDate, formatTime} = useDateTimeFormatters()
  const translatedState: Record<MessageState, string> = {
    [MessageState.Sent]: t<string>('Message was sent to recipients.'),
    [MessageState.Scheduled]: sendAt
      ? t<string>('Message will be sent on {{date}} at {{time}}.', {
          date: formatDate(new Date(sendAt)),
          time: formatTime(new Date(sendAt))
        })
      : t<string>('Message will be sent.'),
    [MessageState.Pending]: t<string>(
      'Request to send message is processed right now. Check for result later.'
    ),
    [MessageState.Failed]: t<string>(
      'Sending message has failed for reason: One or more contacts have unexpected format.'
    ),
    [MessageState.Draft]: t<string>(
      'Message was not send yet and can be modified.'
    )
  }
  return (state: MessageState) => translatedState[state]
}
