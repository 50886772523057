import {
  Ellipse,
  FeSeatState,
  FeZoneState,
  Icon,
  IIcon,
  ISeat,
  IZone,
  Line,
  Rectangle,
  SeatByState,
  ShapeVariant,
  Text,
  ZoneByState
} from '@attendio/shared-components'
import Konva from 'konva'
import React, {useCallback} from 'react'
import {useStore} from 'react-redux'
import {useModifyZoneLabel} from '../../hooks/zoneLabel'
import {getFeSeatStateInEventSeatOption} from '../../utils/getFeSeatState'
import {getFeZoneStateInEventSeatOptions} from '../../utils/getFeZoneState'

import {useSelector} from '../redux'
import {canvasSelector} from '../redux/canvas/selectors'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {
  presentObjectsSelector,
  selectedObjectsSelector
} from '../redux/objects/selectors'
import {pricingSelector} from '../redux/pricing/selectors'
import {useSelectionActions} from '../redux/selection/actions'
import {selectedObjectsIdsSelector} from '../redux/selection/selectors'
import {CanvasObjectType} from '../redux/types'
import {IShape, IText} from '../types'

interface ICanvasObjectProps {
  id: string

  [prop: string]: any
}

const CanvasObjectFn: React.FC<ICanvasObjectProps> = ({
  id,
  ...otherProps
}: ICanvasObjectProps) => {
  const store = useStore()
  const {selectedMode} = useSelector(editorModeSelector)
  const displayMode = useSelector(displayModeSelector)

  const {handleSingleObjectSelection, selectObject} = useSelectionActions()

  const selected = useSelector((state) =>
    selectedObjectsIdsSelector(state).includes(id)
  )

  const {config: objectConfig, type: objectType} = useSelector(
    (state) => presentObjectsSelector(state)[id]
  )

  const assignedTicketType = useSelector((state) => pricingSelector(state)[id])
  const {scale} = useSelector(canvasSelector)

  const handleSeatInSelectModeClick = useCallback(() => {
    handleSingleObjectSelection(id)
  }, [handleSingleObjectSelection, id])

  const handleSeatInCashModeClick = useCallback(() => {
    const selectedObjects = selectedObjectsSelector(store.getState())
    if (
      // In CASH mode we only allow to select multiple seats or single zone
      // If some zone was already selected we simple deselect everything
      selectedObjects.some(({type}) => type !== CanvasObjectType.Seat)
    ) {
      selectObject(id)
    } else {
      handleSingleObjectSelection(id, false)
    }
  }, [handleSingleObjectSelection, id, selectObject, store])

  const onClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (
        selectedMode !== EditorMode.SELECT &&
        (selectedMode !== EditorMode.RESERVATION ||
          objectType !== CanvasObjectType.Seat)
      )
        return

      e.evt.stopPropagation()

      if (displayMode === DisplayMode.CASH) {
        if (
          objectType !== CanvasObjectType.Seat &&
          objectType !== CanvasObjectType.Zone
        )
          return

        const selectedObjects = selectedObjectsSelector(store.getState())
        if (
          // In CASH mode we only allow to select multiple seats or single zone
          // If some zone was already selected we simple deselect everything
          selectedObjects.some(({type}) => type !== CanvasObjectType.Seat) ||
          objectType !== CanvasObjectType.Seat
        ) {
          selectObject(id)
        } else {
          handleSingleObjectSelection(id, false)
        }
      } else if (
        displayMode !== DisplayMode.PRICING ||
        objectType === CanvasObjectType.Seat ||
        objectType === CanvasObjectType.Zone
      ) {
        handleSingleObjectSelection(id, selectedMode === EditorMode.RESERVATION)
      }
    },
    [
      displayMode,
      handleSingleObjectSelection,
      id,
      objectType,
      selectObject,
      selectedMode,
      store
    ]
  )

  const handleZoneClickInCashMode = useCallback(() => {
    selectObject(id)
  }, [id, selectObject])

  const handleZoneInSelectModeClick = useCallback(() => {
    handleSingleObjectSelection(id)
  }, [handleSingleObjectSelection, id])

  const modifyZoneLabel = useModifyZoneLabel()
  if (objectType === CanvasObjectType.Icon) {
    const iconConfig = objectConfig as IIcon
    const iconProps = {...iconConfig, selected, onClick}

    return <Icon {...iconProps} {...otherProps} />
  }

  if (objectType === CanvasObjectType.Seat) {
    const seatConfig = objectConfig as ISeat
    if (
      [DisplayMode.RESTRICTED, DisplayMode.FULL, DisplayMode.PRICING].includes(
        displayMode
      )
    ) {
      return (
        <SeatByState
          onClick={
            selectedMode === EditorMode.SELECT
              ? handleSeatInSelectModeClick
              : undefined
          }
          state={
            selected
              ? FeSeatState.SelectedInEditor
              : assignedTicketType
              ? FeSeatState.Available
              : FeSeatState.Plain
          }
          seatConfig={seatConfig}
          color={assignedTicketType?.color}
          // other props are required in transform mode
          seatProps={otherProps}
        />
      )
    } else if (displayMode === DisplayMode.CASH) {
      return (
        <SeatByState
          onClick={
            selectedMode === EditorMode.SELECT
              ? handleSeatInCashModeClick
              : undefined
          }
          state={getFeSeatStateInEventSeatOption(
            assignedTicketType?.apiSeatState
          )}
          seatConfig={seatConfig}
          color={assignedTicketType?.color}
        />
      )
    }
    throw new Error('Use new SeatByState component')
  }

  if (objectType === CanvasObjectType.Shape) {
    const shapeConfig = objectConfig as IShape
    const shapeProps = {...shapeConfig, selected, onClick}

    if (shapeConfig.shapeVariant === ShapeVariant.Ellipse) {
      return <Ellipse {...shapeProps} {...otherProps} />
    }

    if (shapeConfig.shapeVariant === ShapeVariant.Line) {
      return <Line {...shapeProps} scale={scale} {...otherProps} />
    }

    if (shapeConfig.shapeVariant === ShapeVariant.Rectangle) {
      return <Rectangle {...shapeProps} {...otherProps} />
    }
  }

  if (objectType === CanvasObjectType.Text) {
    const textConfig = objectConfig as IText
    const textProps = {...textConfig, selected, onClick}

    return <Text {...textProps} {...otherProps} />
  }

  if (objectType === CanvasObjectType.Zone) {
    const zoneConfig = objectConfig as IZone
    if (
      [
        DisplayMode.RESTRICTED,
        DisplayMode.FULL,
        DisplayMode.FULL_WITHOUT_SEATS,
        DisplayMode.PRICING
      ].includes(displayMode)
    ) {
      return (
        <ZoneByState
          modifyLabel={modifyZoneLabel}
          onClick={
            selectedMode === EditorMode.SELECT
              ? handleZoneInSelectModeClick
              : undefined
          }
          state={
            selected
              ? FeZoneState.SelectedInEditor
              : assignedTicketType
              ? FeZoneState.Available
              : FeZoneState.Plain
          }
          color={assignedTicketType?.color}
          zoneConfig={zoneConfig}
          // other props are required in transform mode
          otherProps={otherProps}
        />
      )
    } else if (DisplayMode.CASH === displayMode) {
      return (
        <ZoneByState
          modifyLabel={modifyZoneLabel}
          onClick={
            selectedMode === EditorMode.SELECT
              ? handleZoneClickInCashMode
              : undefined
          }
          state={getFeZoneStateInEventSeatOptions(
            assignedTicketType?.zoneStatesWithCounts
          )}
          zoneConfig={zoneConfig}
          color={assignedTicketType?.color}
        />
      )
    }
    throw new Error('Use new ZoneByState component')
  }

  return null
}

export const CanvasObject = React.memo(CanvasObjectFn)
