import {Button, ButtonGroupProps} from '@mui/material'
import {noop} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TemplatesQuery} from '../../../../__generated__/schema'
import {performCompiledInventoryCheckAction} from '../../../../utils/compiledTemplates'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useGetInventoryCheckTemplates} from '../hooks/getInventoryCheckTemplates'

interface IInventoryCheckPrintingButtonGroupProps {
  inventoryCheckId: number
  variant?: ButtonGroupProps['variant']
  disabled?: boolean
  icon?: React.ReactNode
}

export const InventoryCheckPrintingButtonGroup: React.FC<IInventoryCheckPrintingButtonGroupProps> =
  ({
    inventoryCheckId,
    variant = 'contained',
    disabled,
    icon
  }: IInventoryCheckPrintingButtonGroupProps) => {
    const {t} = useTranslation()
    const inventoryCheckTemplates = useGetInventoryCheckTemplates()
    const getTemplateButtonClickHandler = useCallback(
      (template: TemplatesQuery['templates'][number]) => () =>
        performCompiledInventoryCheckAction({
          templateFileType: template.fileType,
          templateId: template.id,
          inventoryCheckId
        }),
      [inventoryCheckId]
    )
    return (
      <SplitButton
        variant={variant}
        disabled={disabled}
        disableElevation
        Options={
          inventoryCheckTemplates.length
            ? inventoryCheckTemplates.map((template) => (
                <MenuItem
                  key={template.id}
                  label={template.name}
                  icon={icon}
                  onClick={getTemplateButtonClickHandler(template)}
                />
              ))
            : [
                <MenuItem
                  key="no-templates"
                  label={t('No templates have been found')}
                  isDisabled
                />
              ]
        }
      >
        <Button
          variant={variant}
          color="primary"
          onClick={
            inventoryCheckTemplates[0]
              ? getTemplateButtonClickHandler(inventoryCheckTemplates[0])
              : noop
          }
          disabled={!inventoryCheckTemplates.length}
        >
          {t('Print')}
        </Button>
      </SplitButton>
    )
  }
