import React from 'react'
import {useTranslation} from 'react-i18next'

import {SCALE_MAX, SCALE_MIN} from '../config'
import {useSelector} from '../redux'
import {useCanvasActions} from '../redux/canvas/actions'
import {canvasScaleSelector} from '../redux/canvas/selectors'
import {FullscreenIcon, ZoomInIcon, ZoomOutIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const ZoomPanel: React.FC = () => {
  const scale = useSelector(canvasScaleSelector)
  const {fitToScreen, zoomIn, zoomOut} = useCanvasActions()
  const {t} = useTranslation()

  return (
    <Panel>
      <PanelButton
        onClick={() => zoomOut()}
        disabled={scale <= SCALE_MIN}
        tooltip={t('Zoom out')}
        cypressId="zoom-out-button"
      >
        <ZoomOutIcon />
      </PanelButton>
      <PanelButton
        cypressId="fit-to-screen-button"
        onClick={() => fitToScreen()}
        tooltip={t('Fit to screen')}
      >
        <FullscreenIcon />
      </PanelButton>
      <PanelButton
        onClick={() => zoomIn()}
        disabled={scale >= SCALE_MAX}
        tooltip={t('Zoom in')}
        cypressId="zoom-in-button"
      >
        <ZoomInIcon />
      </PanelButton>
    </Panel>
  )
}
