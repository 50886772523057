import {Box, Drawer, drawerClasses, List, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {useLightweightCustomerGroups} from '../../graphql'
import {CustomerGroupRowListItem} from './CustomerGroupRowListItem'

interface IAssignCustomerGroupDrawerProps {
  isOpen: boolean
  onClose: () => void
  assignedCustomerGroupIds: number[]
  onSaveButtonClick: (customerGroupIds: number[]) => void
}

export const AssignCustomerGroupDrawer: React.FC<IAssignCustomerGroupDrawerProps> =
  ({
    isOpen,
    onClose,
    assignedCustomerGroupIds,
    onSaveButtonClick
  }: IAssignCustomerGroupDrawerProps) => {
    const {t} = useTranslation()
    const [skip, setSkip] = useState(!isOpen)
    const [search, setSearch] = useState<string>('')
    const [selectedCustomerGroupIds, setSelectedCustomerGroupIds] = useState<
      number[]
    >([])
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
        setSelectedCustomerGroupIds(assignedCustomerGroupIds)
      }
      return () => {
        setSkip(true)
        setSelectedCustomerGroupIds([])
        setSearch('')
      }
    }, [assignedCustomerGroupIds, isOpen])
    const {data, loading, error} = useLightweightCustomerGroups(skip)
    const filteredCustomerGroupIds = (data?.customerGroups || []).filter(
      ({name}) => name.toUpperCase().indexOf(search.toUpperCase()) > -1
    )
    const handleInputChange = useCallback((e) => setSearch(e.target.value), [])
    const handleCancelClick = useCallback(() => setSearch(''), [])
    const createCustomerGroupCheckboxClickHandler = useCallback(
      (groupId: number) => (e: React.MouseEvent) => {
        e.stopPropagation()
        setSelectedCustomerGroupIds((ids) =>
          ids.find((id) => id === groupId)
            ? ids.filter((id) => id !== groupId)
            : [...ids, groupId]
        )
      },
      []
    )
    const handleSaveButtonClick = useCallback(() => {
      onSaveButtonClick(selectedCustomerGroupIds)
    }, [onSaveButtonClick, selectedCustomerGroupIds])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          errorMessage={error && t('Error while loading customer groups')}
          isLoading={loading}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t("Customer's groups")}
            />
          }
          footer={<SaveButton onClick={handleSaveButtonClick} />}
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'common.white',
              py: 2,
              px: 3,
              zIndex: 1,
              borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
            }}
          >
            <Typography variant="subtitle1">
              {t('Add or remove customer groups for this customer')}
            </Typography>
            <OutlinedInputWithCancelAdornment
              onCancelClick={handleCancelClick}
              inputProps={{
                value: search,
                onChange: handleInputChange,
                placeholder: t('Search for customer group'),
                fullWidth: true
              }}
            />
          </Box>
          {filteredCustomerGroupIds.length > 0 ? (
            <List sx={{px: 2, py: 1, overflowY: 'auto'}}>
              {filteredCustomerGroupIds.map((group) => (
                <CustomerGroupRowListItem
                  key={group.id}
                  name={group.name}
                  description={group.description}
                  checkBoxProps={{
                    onClick: createCustomerGroupCheckboxClickHandler(group.id),
                    checked:
                      !!selectedCustomerGroupIds.find(
                        (id) => id === group.id
                      ) || false
                  }}
                />
              ))}
            </List>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2
              }}
            >
              <Typography variant="body2">
                {t('No customer group found')}
              </Typography>
            </Box>
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
