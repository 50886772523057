import AddIcon from '@mui/icons-material/Add'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Box, Button, IconButton, Typography} from '@mui/material'
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  ProductGroupQuery,
  ProductState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateProductState} from '../../../../../hooks/translateProductState'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {EntityStateChip, Tooltip} from '../../../../common'
import {DataGridTable, useUnitFormatter} from '../../../../common/DataGridTable'
import {productStateColors} from '../../../../constants'
import {
  useRemoveProductFromProductGroup,
  useUpdateProductsOrderInProductGroup
} from '../graphql'
import {AssignProductDrawer} from './AssignProductDrawer'

const StateRenderer = ({state}: {state: ProductState}) => {
  const translateProductState = useTranslateProductState()
  return (
    <EntityStateChip
      label={translateProductState(state)}
      colorConf={productStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({
  productId,
  productGroupId
}: {
  productId: number
  productGroupId: number
}) => {
  const {t} = useTranslation()
  const removeProductFromProductGroup = useRemoveProductFromProductGroup()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const handleIconClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await removeProductFromProductGroup({productId, productGroupId})
      addInfoNotification(t('Product removed'))
    } catch (error) {
      defaultErrorHandler(
        error,
        t('Error while removeing product from product group')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    productGroupId,
    productId,
    removeProductFromProductGroup,
    setShowBackdrop,
    t
  ])
  return (
    <Tooltip title={t('Remove')}>
      <IconButton onClick={handleIconClick} color="primary">
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  )
}

interface IProductsSectionProps {
  id: string
  label: string
  productGroup: ProductGroupQuery['productGroup']
  refetchProductGroup: () => void
}

export const ProductsSection: React.FC<IProductsSectionProps> = ({
  id,
  label,
  productGroup,
  refetchProductGroup
}: IProductsSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateProductsOrderInProductGroup =
    useUpdateProductsOrderInProductGroup()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const unitFormatter = useUnitFormatter()
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(false)
  const {id: productGroupId} = productGroup
  const handleRowOrderChange = useCallback(
    async (params) => {
      const productRowsClone = productGroup.products
        ? [...productGroup.products]
        : []
      const row = productRowsClone.splice(params.oldIndex, 1)[0]
      productRowsClone.splice(params.targetIndex, 0, row)
      try {
        setShowBackdrop(true)
        await updateProductsOrderInProductGroup({
          productGroupId,
          productIds: productRowsClone.map(({id}) => id)
        })
        addInfoNotification(t('Products have been reordered'))
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while updating products order in product group')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      productGroup.products,
      productGroupId,
      setShowBackdrop,
      t,
      updateProductsOrderInProductGroup
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        width: 40
      },
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 250,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ProductState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Measurement unit'),
        field: 'unit',
        minWidth: 150,
        valueFormatter: unitFormatter,
        sortable: false
      },
      {
        headerName: t('Product type'),
        field: 'productType',
        minWidth: 150,
        valueGetter: (params) => params.value.name,
        sortable: false
      },
      {
        headerName: t('PLU'),
        field: 'priceLookupCode',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Product number'),
        field: 'internalCode',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: '',
        field: 'icon',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              productGroupId={productGroupId}
              productId={params.row.id}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [productGroupId, t, unitFormatter]
  )
  return (
    <Box id={id}>
      <Box
        sx={{
          pb: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
        {P([
          PermissionCode.AssignProductToProductGroup,
          PermissionCode.ReadProducts
        ]) && (
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openDrawer}
          >
            {t('Add')}
          </Button>
        )}
      </Box>
      <Box sx={{height: '100%', width: '100%'}}>
        <DataGridTable
          columns={columns}
          rows={productGroup.products ?? []}
          autoHeight
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          pagination={false}
          localeText={{noRowsLabel: t('No products were assigned')}}
          initialState={{
            pinnedColumns: {left: ['__reorder__', 'name'], right: ['icon']}
          }}
          columnVisibilityModel={{
            icon: P([PermissionCode.RemoveProductFromProductGroup])
          }}
          rowReordering={P([PermissionCode.UpdateProductsOrderInProductGroup])}
          onRowOrderChange={handleRowOrderChange}
          slots={{rowReorderIcon: DragHandleIcon}}
        />
      </Box>
      <AssignProductDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        assignedProductIds={(productGroup.products || []).map(({id}) => id)}
        productGroupId={productGroupId}
        refetchProductGroup={refetchProductGroup}
      />
    </Box>
  )
}
