import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CenteredLayout} from './Layout'

export interface INoEffectiveClientSelectedProps {
  text?: string
}

/**
 * Check ChildrenOnEffectiveClientSelected component, in many cases, you will find it more handy
 */
export const NoEffectiveClientSelected: React.FC<INoEffectiveClientSelectedProps> =
  ({text}: INoEffectiveClientSelectedProps) => {
    const {t} = useTranslation()
    return (
      <CenteredLayout>
        <Box padding={3}>
          <Typography>
            {text || t('No effective client selected ...')}
          </Typography>
        </Box>
      </CenteredLayout>
    )
  }
