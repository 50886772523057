import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'

import {IPaymentMethodGroupForm, PaymentMethodGroupField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface IPaymentMethodGroupFormProps {
  formId: string
  defaultValues?: Partial<IPaymentMethodGroupForm>
  onSubmit: (form: IPaymentMethodGroupForm) => void
  className?: string
}

export const PaymentMethodGroupForm: React.FC<IPaymentMethodGroupFormProps> = ({
  formId,
  defaultValues,
  onSubmit,
  className
}: IPaymentMethodGroupFormProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {register, setValue, errors, watch, triggerValidation, handleSubmit} =
    useForm<IPaymentMethodGroupForm>({
      defaultValues
    })
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(className, classes.form)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IPaymentMethodGroupForm>
            register={register}
            name={PaymentMethodGroupField.NAME}
            key={PaymentMethodGroupField.NAME}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
    </form>
  )
}
