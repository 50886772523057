import {Chip, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {QuantityController} from '../../../../common/QuantityController'

interface IZoneListItemProps {
  primaryLabel: string
  secondaryLabel: string
  isSoldOut: boolean
  isAddButtonDisabled: boolean
  ticketsInCartCount: number
  onQuantityControllerClick?: () => void
  onIncrementButtonClick: () => void
  onDecrementButtonClick: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    padding: theme.spacing(1, 2),
    gridTemplateAreas: `
      "primaryLabel rightBox"
      "secondaryLabel rightBox"
    `,
    gridTemplateColumns: '1fr auto'
  },
  chip: {
    gridArea: 'rightBox',
    color: theme.palette.grey.A700,
    alignSelf: 'center'
  },
  secondaryLabel: {
    gridArea: 'secondaryLabel'
  }
}))

export const ZoneListItem: React.FC<IZoneListItemProps> = ({
  primaryLabel,
  secondaryLabel,
  isSoldOut,
  isAddButtonDisabled,
  ticketsInCartCount,
  onQuantityControllerClick,
  onIncrementButtonClick,
  onDecrementButtonClick
}: IZoneListItemProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <Paper className={classes.root} variant="outlined">
      <Typography variant="subtitle2">{primaryLabel}</Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.secondaryLabel}
      >
        {secondaryLabel}
      </Typography>
      {isSoldOut ? (
        <Chip className={classes.chip} size="medium" label={t('Sold out')} />
      ) : (
        <QuantityController
          className={classes.chip}
          onClick={onQuantityControllerClick}
          quantity={ticketsInCartCount}
          DecrementButtonProps={{
            onClick: onDecrementButtonClick
          }}
          IncrementButtonProps={{
            onClick: onIncrementButtonClick,
            disabled: isAddButtonDisabled
          }}
        />
      )}
    </Paper>
  )
}
