import {Typography} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  useIsStringWithMaxLength,
  useIsValidURL
} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {OpenGraphPreview} from '../../../../../common/OpenGraphPreview'
import {SearchResultPreview} from '../../../../../common/SearchResultPreview'
import {FormAutocomplete} from '../../../../../form/FormAutocomplete'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {getFileNameFromUrl} from '../../../components/utils'
import {IMetadataForm, MetadataFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1)
}))

interface IMetadataFormProps {
  formId: string
  onSubmit: (data: IMetadataForm) => void
  defaultValues?: Partial<IMetadataForm>
  images: {
    fileName: string
    url: string
  }[]
}

export const MetadataForm: React.FC<IMetadataFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  images
}: IMetadataFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IMetadataForm>({defaultValues, mode: 'onChange'})
  const isValidURL = useIsValidURL(true)
  const isStringWithMaxLength60 = useIsStringWithMaxLength(60)
  const isStringWithMaxLength160 = useIsStringWithMaxLength(160)
  const watchedTitle = watch(MetadataFormField.Title)
  const watchedMetadataBase = watch(MetadataFormField.MetadataBase)
  const watchedDescription = watch(MetadataFormField.Description)
  const watchedOpenGraphTitle = watch(MetadataFormField.OpenGraphTitle)
  const watchedOpenGraphImageUrl = watch(MetadataFormField.OpenGraphImageUrl)
  const watchedOpenGraphDescrpition = watch(
    MetadataFormField.OpenGraphDescrpition
  )
  const imageOptions = images.map(({url, fileName}) => ({
    value: url,
    name: fileName
  }))
  const defaultValueImageInExistingImages = imageOptions.find(
    (image) => image.value === defaultValues?.openGraphImageUrl
  )
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <Typography variant="subtitle1">{t('SEO settings')}</Typography>
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IMetadataForm>
            name={MetadataFormField.Index}
            key={MetadataFormField.Index}
            primaryLabel={t('Index website by search engines')}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.index || false}
            secondaryLabel=""
            enabledText=""
            disabledText=""
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMetadataForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={MetadataFormField.MetadataBase}
            key={MetadataFormField.MetadataBase}
            label={t('Website URL')}
            validationOptions={{
              validate: isValidURL,
              required: true
            }}
            fullWidth
            required
            helperNote={t('URL address displayed when shared.')}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMetadataForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={MetadataFormField.Title}
            key={MetadataFormField.Title}
            label={t('Search engine title')}
            validationOptions={{
              validate: isStringWithMaxLength60
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMetadataForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={MetadataFormField.Description}
            key={MetadataFormField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength160
            }}
            fullWidth
            multiline
            rows={5}
            helperNote={t(
              'Recommended for helping visitors understand what the page is about.'
            )}
          />
        ]}
      />
      <Typography variant="body2">
        {t(
          "Specify the title and description for this page. You can preview the page's appearance in search engine results below."
        )}
      </Typography>
      {watchedTitle && watchedMetadataBase && (
        <>
          <Typography variant="caption">
            {t(
              "This preview adheres to the typical character limits found on Google's desktop search result pages. However, search engines often experiment with these limits and may choose to display different content."
            )}
          </Typography>
          <Typography variant="overline">
            {t('Search results preview')}
          </Typography>
          <SearchResultPreview
            title={watchedTitle}
            url={watchedMetadataBase}
            description={watchedDescription || undefined}
          />
        </>
      )}
      <Typography sx={{pt: 2}} variant="subtitle1">
        {t('Social media settings')}
      </Typography>
      <Typography variant="body2">
        {t(
          'Specify the title and description for sharing this page on social media platforms like Facebook, X (Twitter), LinkedIn, etc.'
        )}
      </Typography>
      {watchedMetadataBase && watchedOpenGraphTitle && (
        <OpenGraphPreview
          title={watchedOpenGraphTitle}
          url={watchedMetadataBase}
          imageUrl={watchedOpenGraphImageUrl || undefined}
          description={watchedOpenGraphDescrpition || undefined}
        />
      )}
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMetadataForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={MetadataFormField.OpenGraphTitle}
            key={MetadataFormField.OpenGraphTitle}
            label={t('Open Graph title')}
            validationOptions={{
              validate: isStringWithMaxLength60
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMetadataForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={MetadataFormField.OpenGraphDescrpition}
            key={MetadataFormField.OpenGraphDescrpition}
            label={t('Open Graph description')}
            validationOptions={{
              validate: isStringWithMaxLength160
            }}
            fullWidth
            multiline
            rows={5}
            helperNote={t(
              'Recommended for helping visitors understand what the page is about.'
            )}
          />
        ]}
      />
      <FormAutocomplete<IMetadataForm>
        noOptionsText=""
        fullWidth
        errors={errors}
        label={t('Open Graph image')}
        name={MetadataFormField.OpenGraphImageUrl}
        key={MetadataFormField.OpenGraphImageUrl}
        register={register}
        setValue={setValue}
        watch={watch}
        autocompleteOptions={
          defaultValues?.openGraphImageUrl && !defaultValueImageInExistingImages
            ? [
                {
                  value: defaultValues.openGraphImageUrl,
                  name: t("{{file}} doesn't exists in assets", {
                    file: getFileNameFromUrl(
                      defaultValues.openGraphImageUrl || ''
                    )
                  })
                },
                ...imageOptions
              ].filter(Boolean)
            : imageOptions
        }
        helperNote={t(
          'Make sure your images are at least 1200px by 630px and have a 1.91:1 aspect ratio.'
        )}
      />
    </StyledForm>
  )
}
