import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '2px 4px',
    width: 'fit-content'
  },
  rectangle: {
    // Nothing special for this class
  },
  round: {
    borderRadius: 4
  },
  circle: {
    borderRadius: 15,
    minWidth: 26
  }
}))

interface ICustomChipProps {
  label: string | number
  shape: 'rectangle' | 'round' | 'circle'
  className?: string
}

export const CustomChip: React.FC<ICustomChipProps> = ({
  label,
  shape,
  className
}: ICustomChipProps) => {
  const classes = useStyles()

  return (
    <Typography
      variant="body2"
      align="center"
      className={cn(classes.chip, classes[shape], className)}
    >
      {label}
    </Typography>
  )
}
