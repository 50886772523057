import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'
import React from 'react'

export const BarcodeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 65 64" {...props}>
    <g clipPath="url(#barcode-icon-path)">
      <path
        d="M11.167 16H16.5v32h-5.333V16Zm8 0h2.666v32h-2.666V16Zm5.333 0h8v32h-8V16Zm10.667 0h2.666v32h-2.666V16Zm8 0H48.5v32h-5.333V16Zm8 0h2.666v32h-2.666V16ZM5.833 10.667v10.666H.5V10.667a5.333 5.333 0 0 1 5.333-5.334H16.5v5.334H5.833Zm53.334-5.334a5.333 5.333 0 0 1 5.333 5.334v10.666h-5.333V10.667H48.5V5.333h10.667ZM5.833 42.667v10.666H16.5v5.334H5.833A5.333 5.333 0 0 1 .5 53.333V42.667h5.333Zm53.334 10.666V42.667H64.5v10.666a5.333 5.333 0 0 1-5.333 5.334H48.5v-5.334h10.667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="barcode-icon-path">
        <path fill="#fff" transform="translate(.5)" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)
