import {Box, Typography} from '@mui/material'
import React from 'react'
import {useForm} from 'react-hook-form'
import {
  ConditionOperator,
  ConditionResource
} from '../../../../../__generated__/schema'
import {
  useTranslateConditionOperator,
  useTranslateConditionResource
} from '../../../../../hooks/discounts'
import {
  IUncontrolledFormSelectProps,
  UncontrolledFormSelect
} from '../../../../form/UncontrolledFormSelect'
import {ConditionResourceFormInput} from './ConditionResourceFormInput'
import {ConditionFormField, IConditionForm} from './types'

export const getAvailableOperatorsByResource = (
  resource: ConditionResource
) => {
  switch (resource) {
    case ConditionResource.StartTime:
    case ConditionResource.StartDate:
    case ConditionResource.DiscountApplicationDate:
    case ConditionResource.DiscountApplicationTime:
      return [
        ConditionOperator.IsEqual,
        ConditionOperator.IsNotEqual,
        ConditionOperator.IsGreaterOrEqual,
        ConditionOperator.IsLowerOrEqual
      ]
    case ConditionResource.ShowTypeCode:
    case ConditionResource.FormatCode:
    case ConditionResource.VersionCode:
    case ConditionResource.SoundMixCode:
    case ConditionResource.ShowAgeClassificationCode:
    case ConditionResource.VenueId:
    case ConditionResource.ShowId:
    case ConditionResource.EventId:
    case ConditionResource.StartWeekDay:
    case ConditionResource.ApplicableByUserId:
    case ConditionResource.MarketingLabelId:
    case ConditionResource.EventCategoryId:
    case ConditionResource.CostCenterId:
    case ConditionResource.AuditoriumId:
    case ConditionResource.TourId:
    case ConditionResource.AdmissionTypeId:
      return [ConditionOperator.IsOneOf, ConditionOperator.IsNotOneOf]
    default:
      throw new Error('Not implemented')
  }
}

interface ISelectValueAndOperatorStepProps {
  defaultValues: Pick<
    Partial<IConditionForm>,
    ConditionFormField.Operator | ConditionFormField.Value
  > &
    Pick<IConditionForm, ConditionFormField.Resource>
  formId: string
  onSubmit(form: IConditionForm): void
}

export const SelectValueAndOperatorStep: React.FC<ISelectValueAndOperatorStepProps> =
  ({formId, defaultValues, onSubmit}: ISelectValueAndOperatorStepProps) => {
    const resource = defaultValues[ConditionFormField.Resource]
    const translateConditionResource = useTranslateConditionResource()
    const translateConditionOperator = useTranslateConditionOperator()

    const {
      register,
      unregister,
      setValue,
      triggerValidation,
      errors,
      watch,
      handleSubmit,
      setError,
      clearError,
      control
    } = useForm<IConditionForm>({defaultValues})

    return (
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          name={ConditionFormField.Resource}
          ref={register()}
        />
        <Box paddingBottom={1}>
          <Typography variant="body1">
            {translateConditionResource(resource)}
          </Typography>
        </Box>
        <Box paddingBottom={2}>
          <UncontrolledFormSelect<IConditionForm>
            fullWidth
            validationOptions={{
              required: true
            }}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            label=""
            name={ConditionFormField.Operator}
            selectOptions={getAvailableOperatorsByResource(resource).reduce(
              (
                acc: IUncontrolledFormSelectProps['selectOptions'],
                operator
              ) => ({
                ...acc,
                [operator]: translateConditionOperator(operator)
              }),
              {}
            )}
          />
        </Box>
        <ConditionResourceFormInput
          resource={resource}
          conditionFormInputProps={{
            register,
            unregister,
            setValue,
            triggerValidation,
            errors,
            watch,
            setError,
            clearError,
            control,
            defaultValue: defaultValues[ConditionFormField.Value]
          }}
        />
      </form>
    )
  }
