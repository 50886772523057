import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useReservationPathnameParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {useCancelReservation} from '../graphql'

export const ReservationHasNoItemsDialog: React.FC = () => {
  const {reservationId} = useReservationPathnameParams()
  const {t} = useTranslation()
  const history = useHistory()
  const cancelReservation = useCancelReservation()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const {state: isOpen, setFalse: closeDialog} = useBooleanState(true)

  const handleDeleteConfirmation = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await cancelReservation({reservationId})
      addInfoNotification(t('Reservation deleted'))
      closeDialog()
      history.replace(routeTo.admin.cashDesk.reservations())
    } catch (err) {
      defaultErrorHandler(err, t('Deletion of reservation failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    cancelReservation,
    closeDialog,
    defaultErrorHandler,
    history,
    reservationId,
    setShowBackdrop,
    t
  ])
  return (
    <ConfirmationDialog
      title={t('Keep reservation?')}
      contentText={t(
        'There are no items in this reservation. Should this reservation remain without items or do you want to delete it? Delete is permanent and irreversible action.'
      )}
      onConfirm={closeDialog}
      onCancel={handleDeleteConfirmation}
      isOpen={isOpen}
      confirmButtonLabel={t('Keep it')}
      cancelButtonLabel={t('Delete reservation')}
    />
  )
}
