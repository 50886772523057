import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  WarehousesFilterInput
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {UpdateWarehouseDrawer} from './UpdateWarehouseDrawer'
import {WarehousesList} from './WarehousesList'
import {WarehousesSearch} from './WarehousesSearch'

export const WarehousesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<WarehousesFilterInput>({})
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.warehouses.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Warehouses')}
          search={<WarehousesSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <WarehousesList searchFilter={searchFilter} />
        {P([PermissionCode.UpdateWarehouse, PermissionCode.ReadWarehouse]) && (
          <Route path={routeTo.admin.warehouses.edit(':warehouseId')} exact>
            <UpdateWarehouseDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
