import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  CartQuery,
  CartQueryVariables,
  CartSupportUserQuery,
  CartSupportUserQueryVariables
} from '../../../../../__generated__/schema'
import {
  LEAD_PROPERTIES_FRAGMENT,
  PRODUCT_ITEM_PROPERTIES_FRAGMENT,
  TICKET_ITEM_PROPERTIES_FRAGMENT,
  TOUR_ITEM_PROPERTIES_FRAGMENT,
  TRANSLATED_LOCALES_FRAGMENT,
  USER_FIELDS
} from '../../graphql'

export const CART_DETAIL_RESERVATION_PROPERTIES_FRAGMENT = gql`
  fragment CartDetailReservationProperties on Reservation {
    id
    state
    expireAt
    updatedAt
    relatedSale {
      id
      cart {
        id
      }
    }
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    event {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
      formatCode
      soundMixCode
      versionCode
    }
    tourTimeSlot {
      id
      startsAt
      versionCode
      ageClassificationCode
      guide {
        ...UserFields
      }
      tour {
        id
        name
      }
    }
    division {
      id
      name
      address {
        town
      }
    }
    venue {
      id
      name
      address {
        town
      }
    }
  }
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${USER_FIELDS}
`

export const CART_DETAIL_SALE_PROPERTIES_FRAGMENT = gql`
  fragment CartDetailSaleProperties on Sale {
    id
    event {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
      formatCode
      soundMixCode
      versionCode
    }
    price
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    cart {
      id
      uuid
      hash
    }
    itemType
    division {
      id
      name
      address {
        town
      }
    }
    venue {
      id
      name
      address {
        town
      }
    }
  }
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const CART_DETAIL_PROPERTIES_FRAGMENT = gql`
  fragment CartDetailProperties on Cart {
    id
    state
    price
    sellingChannel
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    lead {
      ...LeadProperties
    }
    expiresAt
    createdByName
    createdAt
    updatedAt
    payment {
      id
      createdAt
      transactionType
      amount
      channel
    }
    paymentIntents {
      id
      state
      amount
      createdAt
      thirdPartyPaymentId
      paymentMethod {
        id
        name
        type
        serviceProvider
      }
    }
    reservations {
      ...CartDetailReservationProperties
    }
    sales {
      ...CartDetailSaleProperties
    }
    uuid
    hash
    client {
      id
      currency
      name
      legalAddress {
        town
      }
    }
  }
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TOUR_ITEM_PROPERTIES_FRAGMENT}
  ${LEAD_PROPERTIES_FRAGMENT}
  ${CART_DETAIL_RESERVATION_PROPERTIES_FRAGMENT}
  ${CART_DETAIL_SALE_PROPERTIES_FRAGMENT}
`

const CART_QUERY = gql`
  query cart($cartId: Int!) {
    cart(cartId: $cartId) {
      ...CartDetailProperties
    }
  }
  ${CART_DETAIL_PROPERTIES_FRAGMENT}
`

export const useCart = (cartId: number) =>
  useQuery<CartQuery, CartQueryVariables>(CART_QUERY, {
    fetchPolicy: 'network-only',
    variables: {cartId}
  })

const CART_SUPPORT_USER = gql`
  ${CART_DETAIL_PROPERTIES_FRAGMENT}
  query CartSupportUser($id: PositiveInt!) {
    cartSupportUser(id: $id) {
      ...CartDetailProperties
    }
  }
`

export const useCartSupportUser = (id: number) =>
  useQuery<CartSupportUserQuery, CartSupportUserQueryVariables>(
    CART_SUPPORT_USER,
    {variables: {id}, fetchPolicy: 'network-only'}
  )
