import {ICreateZone, IZone} from '@attendio/shared-components'
import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {ZONE_MIN_HEIGHT, ZONE_MIN_WIDTH} from '../../../config'
import {getCompressedId} from '../../../utils/compressedIdGenerator'
import {customizeDimensions} from '../../../utils/math'
import {useEditorModeActions} from '../../editorMode/actions'
import {CanvasObjectType} from '../../types'
import {ZoneActionType} from './types'

export const useZoneActions = () => {
  const dispatch = useDispatch()
  const {setSelectMode} = useEditorModeActions()

  const addZone = useCallback(
    (zone: ICreateZone) => {
      const _zone: IZone = {
        ...zone,
        id: getCompressedId(),
        dimensions: customizeDimensions(
          zone.dimensions,
          ZONE_MIN_WIDTH,
          ZONE_MIN_HEIGHT
        )
      }
      dispatch({
        type: ZoneActionType.ADD_ZONE,
        payload: {type: CanvasObjectType.Zone, config: _zone}
      })
      setSelectMode()
    },
    [dispatch, setSelectMode]
  )

  const actions = useMemo(
    () => ({
      addZone
    }),
    [addZone]
  )

  return actions
}
