import EditIcon from '@mui/icons-material/Edit'
import {IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AccessZonesFilter,
  AccessZoneState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateAccessZoneState} from '../../../../hooks/translateAccessZoneState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {accessZoneStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {WideCenteredLayout} from '../Layout'
import {CreateAccessZoneDrawer} from './CreateAccessZoneDrawer'
import {useAccessZones} from './graphql'

const StateRenderer = ({state}: {state: AccessZoneState}) => {
  const translateAccessZoneState = useTranslateAccessZoneState()
  return (
    <EntityStateChip
      colorConf={accessZoneStateColors[state]}
      label={translateAccessZoneState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.accessZones.edit(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

interface IAccessZonesListProps {
  searchFilter: AccessZonesFilter
}

export const AccessZonesList: React.FC<IAccessZonesListProps> = ({
  searchFilter
}: IAccessZonesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateAccessZoneDrawerOpen,
    setTrue: openCreateAccessZoneDrawer,
    setFalse: closeCreateAccessZoneDrawer
  } = useBooleanState(false)
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error, refetch} = useAccessZones({
    paginationInput,
    filter: searchFilter
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const userNameFormatter = useUserNameFormatter()
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: AccessZoneState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 50,
        sortable: false
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 350,
        sortable: false
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        minWidth: 200
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Updated by'),
        field: 'updatedBy',
        valueFormatter: userNameFormatter,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [dateTimeFormatter, t, userNameFormatter]
  )
  if (error) {
    return (
      <Error error={error} message={t('Error while loading access zones')} />
    )
  }
  return (
    <>
      <WideCenteredLayout
        sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.accessZones.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={{noRowsLabel: t('No access zones to show')}}
          initialState={{
            pagination: {paginationModel: {pageSize: 30}},
            pinnedColumns: {left: ['name'], right: ['id']}
          }}
          columnVisibilityModel={{
            id: P([
              PermissionCode.UpdateAccessZone,
              PermissionCode.ReadAccessZone
            ])
          }}
          {...getDataGridPaginationProps(data?.accessZones.pagination)}
        />
      </WideCenteredLayout>
      {P([PermissionCode.UpdateAccessZone, PermissionCode.ReadAccessZone]) && (
        <CreateFab classes={fabClasses} onClick={openCreateAccessZoneDrawer} />
      )}
      <CreateAccessZoneDrawer
        isOpen={isCreateAccessZoneDrawerOpen}
        onClose={closeCreateAccessZoneDrawer}
        refetch={refetch}
      />
    </>
  )
}
