import {MenuItem, TextField} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {useTranslateZoneTypes} from '../../hooks/translateZoneType'
import {ZoneType} from '../../shared/editor/objectTypes'
import {Theme} from '../../theme'
import {useObjectsActions} from '../redux/objects/actions'
import {IZoneStateValue} from '../redux/objects/types'
import {usePlaceholderAndValue} from './placeholderAndValue'

const useStyles = makeStyles<Theme, {placeholder?: string}>(() => ({
  selectRoot: ({placeholder}) => ({
    '& span::after': {
      content: `"${placeholder}"`,
      opacity: 0.42
    }
  })
}))

interface IZoneTypeSelectProps {
  selectedZones: IZoneStateValue[]
}

export const ZoneTypeSelect: React.FC<IZoneTypeSelectProps> = ({
  selectedZones
}: IZoneTypeSelectProps) => {
  const {t} = useTranslation()
  const translateZoneTypes = useTranslateZoneTypes()
  const {placeholder, value} = usePlaceholderAndValue({
    selectedObjects: selectedZones,
    iteratee: 'config.zoneType'
  })
  const {updateObjects} = useObjectsActions()
  const classes = useStyles({placeholder})
  return (
    <TextField
      select
      label={t('Type')}
      variant="outlined"
      fullWidth
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        updateObjects(
          selectedZones.map(
            <T extends IZoneStateValue>(object: T): T => ({
              ...object,
              config: {
                ...object.config,
                zoneType: e.target.value
              }
            })
          )
        )
      }}
      value={value || ''}
      SelectProps={{
        classes: {
          select: classes.selectRoot
        }
      }}
    >
      {[ZoneType.FreeSitting, ZoneType.Standing, ZoneType.Ticket].map(
        (type) => (
          <MenuItem key={type} value={type}>
            {translateZoneTypes(type)}
          </MenuItem>
        )
      )}
    </TextField>
  )
}
