import {Drawer, drawerClasses} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DiscountsFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'

import {CreateDiscountDrawer} from './CreateDiscountDrawer'
import {DiscountsList} from './DiscountsList'
import {
  DEFAULT_DISCOUNTS_FILTER_INPUT,
  DiscountsSearch
} from './DiscountsSearch'

export const Discounts: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<DiscountsFilterInput>(
    DEFAULT_DISCOUNTS_FILTER_INPUT
  )
  const {
    setTrue: openCreateDrawer,
    setFalse: closeCreateDrawer,
    state: isCreateDrawerOpened
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Discounts')}
          search={<DiscountsSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage his discounts.')}
      >
        <DiscountsList searchFilter={searchFilter} />
        {P([PermissionCode.CreateDiscount]) && (
          <CreateFab onClick={openCreateDrawer} classes={fabClasses} />
        )}
        <Drawer
          onClose={closeCreateDrawer}
          open={isCreateDrawerOpened}
          anchor="right"
          sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
        >
          <CreateDiscountDrawer closeDrawer={closeCreateDrawer} />
        </Drawer>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
