import {makeStyles} from '@mui/styles'
import React from 'react'
import {useForm} from 'react-hook-form'
import {
  LeadField,
  LeadOptionPropertiesFragment
} from '../../../../../__generated__/schema'
import {
  getLeadBlockIsAvailable,
  LeadSection,
  useTranslateLeadSection
} from '../../../../../hooks/leadSection'
import {Theme} from '../../../../../theme'
import {useIsValidEmail} from '../../../../../utils/formsValidations'
import {InputBlockWithoutSpacings} from '../../../../common'
import {LeadFormCheckbox} from '../LeadFormCheckbox'
import {LeadFormCountryInput} from '../LeadFormCountryInput'
import {LeadFormTextInput} from '../LeadFormTextInput'
import {ILeadForm} from '../types'

export const DRAWER_LEAD_FORM_ID = 'drawer_lead_form_id'

const useStyles = makeStyles<Theme>((theme) => ({
  inputBlockContent: {
    padding: theme.spacing(2.5, 2)
  },
  leadOptionInput: {
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

interface IDrawerLeadFormProps {
  leadOptionsObject: Partial<Record<LeadField, LeadOptionPropertiesFragment>>
  onSubmit: (form: ILeadForm) => void
  className?: string
  defaultValues: ILeadForm
}

export const DrawerLeadForm: React.FC<IDrawerLeadFormProps> = ({
  leadOptionsObject,
  onSubmit,
  className,
  defaultValues
}: IDrawerLeadFormProps) => {
  const classes = useStyles()
  const {
    setValue,
    errors,
    register,
    watch,
    triggerValidation,
    handleSubmit,
    control
  } = useForm<ILeadForm>({defaultValues})
  const isValidEmail = useIsValidEmail()
  const translateLeadSection = useTranslateLeadSection()

  return (
    <form
      id={DRAWER_LEAD_FORM_ID}
      noValidate
      autoComplete="off"
      className={className}
      onSubmit={handleSubmit(onSubmit)}
    >
      {getLeadBlockIsAvailable(leadOptionsObject, LeadSection.LEAD) && (
        <InputBlockWithoutSpacings
          header={translateLeadSection(LeadSection.LEAD)}
          blockId={LeadSection.LEAD}
        >
          <div className={classes.inputBlockContent}>
            <LeadFormTextInput
              margin="dense"
              className={classes.leadOptionInput}
              showOptionalLeadInput
              option={leadOptionsObject[LeadField.Name]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              className={classes.leadOptionInput}
              showOptionalLeadInput
              option={leadOptionsObject[LeadField.Phone]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              className={classes.leadOptionInput}
              showOptionalLeadInput
              option={leadOptionsObject[LeadField.Email]}
              validationOptions={{
                validate: isValidEmail
              }}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormCheckbox
              className={classes.leadOptionInput}
              showOptionalLeadInput
              option={
                leadOptionsObject[LeadField.IsPrivacyPolicyConsentGranted]
              }
              control={control}
              errors={errors}
            />
          </div>
        </InputBlockWithoutSpacings>
      )}
      {getLeadBlockIsAvailable(leadOptionsObject, LeadSection.COMPANY) && (
        <InputBlockWithoutSpacings
          header={translateLeadSection(LeadSection.COMPANY)}
          blockId={LeadSection.COMPANY}
        >
          <div className={classes.inputBlockContent}>
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.CompanyName]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.CompanyIdNumber]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.TaxId]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.VatId]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
          </div>
        </InputBlockWithoutSpacings>
      )}
      {getLeadBlockIsAvailable(
        leadOptionsObject,
        LeadSection.BILLING_ADDRESS
      ) && (
        <InputBlockWithoutSpacings
          header={translateLeadSection(LeadSection.BILLING_ADDRESS)}
          blockId={LeadSection.BILLING_ADDRESS}
        >
          <div className={classes.inputBlockContent}>
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.BillingAddressStreet]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />

            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.BillingPostalCode]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.BillingAddressTown]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormCountryInput
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.BillingAddressCountry]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          </div>
        </InputBlockWithoutSpacings>
      )}
      {getLeadBlockIsAvailable(
        leadOptionsObject,
        LeadSection.DELIVERY_ADDRESS
      ) && (
        <InputBlockWithoutSpacings
          header={translateLeadSection(LeadSection.DELIVERY_ADDRESS)}
          blockId={LeadSection.DELIVERY_ADDRESS}
        >
          <div className={classes.inputBlockContent}>
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.DeliveryAddressee]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />

            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.DeliveryAddressStreet]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.DeliveryPostalCode]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormTextInput
              margin="dense"
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.DeliveryAddressTown]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
            />
            <LeadFormCountryInput
              showOptionalLeadInput
              className={classes.leadOptionInput}
              option={leadOptionsObject[LeadField.DeliveryAddressCountry]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          </div>
        </InputBlockWithoutSpacings>
      )}
      {getLeadBlockIsAvailable(leadOptionsObject, LeadSection.NOTES) && (
        <InputBlockWithoutSpacings
          header={translateLeadSection(LeadSection.NOTES)}
          blockId={LeadSection.NOTES}
        >
          <div className={classes.inputBlockContent}>
            <LeadFormTextInput
              margin="dense"
              className={classes.leadOptionInput}
              showOptionalLeadInput
              option={leadOptionsObject[LeadField.InternalNote]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
              isMultiline
            />
            <LeadFormTextInput
              margin="dense"
              className={classes.leadOptionInput}
              showOptionalLeadInput
              option={leadOptionsObject[LeadField.Note]}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
              isMultiline
            />
          </div>
        </InputBlockWithoutSpacings>
      )}
    </form>
  )
}
