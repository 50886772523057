import HelpIcon from '@mui/icons-material/HelpOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {Avatar, IconButton, SvgIconProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {ReactNode, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useElementDimensions} from '../../../hooks/dimensions'
import {PRIMARY_50_COLOR, Theme} from '../../../theme'
import {useMenu} from '../Menu'
import {Tooltip} from '../Tooltip'
import {VerticalDivider} from '../VerticalDivider'
import {Datetime} from './Datetime'
import {HeaderMenu} from './HeaderMenu'
import {RightActionInHeader} from './RightActionInHeader'
import {BaseHeaderVariant, HeaderAction, HeaderUserProps} from './types'

const useStyles = makeStyles<
  Theme,
  {
    variant: BaseHeaderVariant
    isDatetimeVisible: boolean
    isSearchDefined: boolean
  }
>((theme) => ({
  root: {
    height: ({variant}) => (variant === BaseHeaderVariant.Primary ? 64 : 63),
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: ({isDatetimeVisible}) =>
      isDatetimeVisible ? '48px 228px 1fr auto auto' : '48px 228px 1fr auto',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    background: ({variant}) =>
      variant === BaseHeaderVariant.Primary
        ? theme.palette.primary.dark
        : theme.palette.background.paper,
    color: ({variant}) =>
      variant === BaseHeaderVariant.Primary
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary,
    borderBottom: ({variant}) =>
      variant === BaseHeaderVariant.Primary
        ? 'none'
        : `1px solid ${theme.palette.divider}`
  },
  title: {
    paddingLeft: theme.spacing(1.5),
    gridColumn: ({isSearchDefined}) => (isSearchDefined ? 'auto' : '2 / 4')
  },
  searchBox: {
    maxWidth: 720,
    marginRight: theme.spacing(3)
  },
  datetimeBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
  actionsBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  avatar: {
    background: PRIMARY_50_COLOR,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    width: 32,
    height: 32,
    fontSize: 14,
    lineHeight: 16
  },
  buttonRoot: {
    '&:hover': {
      backgroundColor: ({variant}) =>
        variant === BaseHeaderVariant.Primary
          ? 'rgba(255, 255, 255, 0.12)'
          : 'invalid'
    }
  }
}))

export interface IBaseHeaderProps {
  title?: string
  variant: BaseHeaderVariant
  LeftActionIcon: React.FC<SvgIconProps>
  onLeftActionClick: () => void
  isLeftActionIconDisabled?: boolean
  search?: ReactNode
  rightActions: HeaderAction[]
  isHelpHidden?: boolean
  isAvatarHidden?: boolean
  user: HeaderUserProps
  isDatetimeEnabled?: boolean
  onHelpClick: () => void
  onMyProfileClick: () => void
  onLogoutClick: () => void
  className?: string
}

export const isDividerVisible = ({
  isHelpHidden,
  isAvatarHidden,
  isDatetimeVisible,
  hasRightActions
}: {
  isHelpHidden: boolean | undefined
  isAvatarHidden: boolean | undefined
  isDatetimeVisible: boolean
  hasRightActions: boolean
}) =>
  (!isHelpHidden || !isAvatarHidden) && (isDatetimeVisible || hasRightActions)

const getAvatarInitials = (user: HeaderUserProps) =>
  user.firstName || user.lastName
    ? [user.firstName, user.lastName]
        .filter(Boolean)
        .map((name) => name[0].toUpperCase())
        .join('')
    : user.username[0].toUpperCase()

export const BaseHeader: React.FC<IBaseHeaderProps> = ({
  title,
  variant,
  LeftActionIcon,
  onLeftActionClick,
  search,
  rightActions,
  isHelpHidden,
  isAvatarHidden,
  user,
  isDatetimeEnabled,
  onHelpClick,
  onMyProfileClick,
  onLogoutClick,
  className,
  isLeftActionIconDisabled
}: IBaseHeaderProps) => {
  const {t} = useTranslation()
  const [ref, {width}] = useElementDimensions()
  const [actionsBoxRef, {width: actionsBoxWidth}] = useElementDimensions()
  const [dateTimeBoxRef, {width: dateTimeBoxWidth}] = useElementDimensions()
  const isDatetimeVisible = Boolean(isDatetimeEnabled) && width > 1024
  const widthForSearch =
    width - 300 - actionsBoxWidth - (isDatetimeVisible ? dateTimeBoxWidth : 0)
  const isWideEnoughToContainSearch = widthForSearch >= 304
  const classes = useStyles({
    variant,
    isDatetimeVisible,
    isSearchDefined: Boolean(search)
  })
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const doesContentForRightSideExists =
    !isAvatarHidden || !isHelpHidden || rightActions.length > 0
  const handleHelpClickInMenu = useCallback(() => {
    onHelpClick()
    closeMenu()
  }, [closeMenu, onHelpClick])
  const handleMyProfileClickInMenu = useCallback(() => {
    onMyProfileClick()
    closeMenu()
  }, [closeMenu, onMyProfileClick])
  const handleLogoutClickInMenu = useCallback(() => {
    onLogoutClick()
    closeMenu()
  }, [closeMenu, onLogoutClick])
  return (
    <div className={cn(classes.root, className)} ref={ref}>
      <IconButton
        classes={{root: classes.buttonRoot}}
        onClick={onLeftActionClick}
        disabled={isLeftActionIconDisabled}
        color="inherit"
      >
        <LeftActionIcon color="inherit" />
      </IconButton>

      <Typography className={classes.title} variant="h6" noWrap>
        {title}
      </Typography>
      <div className={classes.searchBox}>
        {isWideEnoughToContainSearch && search ? search : null}
      </div>
      {isDatetimeVisible && (
        <div className={classes.datetimeBox} ref={dateTimeBoxRef}>
          <Datetime variant={variant} />
        </div>
      )}
      {doesContentForRightSideExists &&
        (isWideEnoughToContainSearch ? (
          <div className={classes.actionsBox} ref={actionsBoxRef}>
            {rightActions.map((rightAction) => (
              <RightActionInHeader
                {...rightAction}
                key={rightAction.label}
                buttonRootClassname={classes.buttonRoot}
              />
            ))}
            {isDividerVisible({
              isHelpHidden,
              isAvatarHidden,
              isDatetimeVisible,
              hasRightActions: rightActions.length > 0
            }) && <VerticalDivider />}
            {!isHelpHidden && (
              <Tooltip title={t<string>('Help')}>
                <IconButton
                  classes={{root: classes.buttonRoot}}
                  color="inherit"
                  onClick={onHelpClick}
                >
                  <HelpIcon color="inherit" />
                </IconButton>
              </Tooltip>
            )}
            {!isAvatarHidden && (
              <IconButton
                classes={{root: classes.buttonRoot}}
                onClick={openMenu}
                cypress-id="navbar-right-avatar-button"
              >
                <Avatar className={classes.avatar}>
                  {getAvatarInitials(user)}
                </Avatar>
              </IconButton>
            )}
          </div>
        ) : (
          <>
            {isDatetimeVisible && <VerticalDivider />}
            <IconButton
              classes={{root: classes.buttonRoot}}
              onClick={openMenu}
              color="inherit"
            >
              <MoreVertIcon color="inherit" />
            </IconButton>
          </>
        ))}
      <HeaderMenu
        onClose={closeMenu}
        anchorEl={anchorEl}
        user={user}
        isUserSectionVisible={!isAvatarHidden}
        isHelpActionVisible={
          isWideEnoughToContainSearch ? false : !isHelpHidden
        }
        onHelpClick={handleHelpClickInMenu}
        onMyProfileClick={handleMyProfileClickInMenu}
        onLogoutClick={handleLogoutClickInMenu}
        actions={isWideEnoughToContainSearch ? [] : rightActions}
      />
    </div>
  )
}
