import {
  TourFieldsFragment,
  TourState,
  UpdateTourInput
} from '../../../../../../__generated__/schema'
import {
  ITourDivisionSettingsForm,
  TimeSelectFormField,
  TourFormDivisionSettingsField
} from '../../types'
import {
  getTimeSelectValueForECommerceSalesEnd,
  transformDivisionSettingToTimeSelectOption,
  transformTourDivisionSettingsToInput
} from '../../utils'

export enum TourField {
  Name = 'name',
  InternalNote = 'internalNote',
  Duration = 'duration',
  RetailAttendeesLimit = 'retailAttendeesLimit',
  ECommerceAttendeesLimit = 'eCommerceAttendeesLimit',
  ECommerceOrderAttendeesLimit = 'eCommerceOrderAttendeesLimit',
  VenueId = 'venueId'
}

export interface IUpdateTourForm extends ITourDivisionSettingsForm {
  [TourField.Name]: string
  [TourField.InternalNote]: string
  [TourField.Duration]: string
  [TourField.RetailAttendeesLimit]: string
  [TourField.ECommerceAttendeesLimit]: string
  [TourField.ECommerceOrderAttendeesLimit]: string
  [TourField.VenueId]: string | null
}

const getNumberFromFieldInput = (formField: string | null) =>
  formField ? parseInt(formField, 10) : null

export const transformUpdateTourFormIntoInput = (
  form: IUpdateTourForm,
  state: TourState
): UpdateTourInput => ({
  name: form[TourField.Name],
  duration: parseInt(form[TourField.Duration], 10),
  state,
  internalNote: form[TourField.InternalNote] || null,
  venueId: getNumberFromFieldInput(form[TourField.VenueId]),
  retailAttendeesLimit: getNumberFromFieldInput(
    form[TourField.RetailAttendeesLimit]
  ),
  eCommerceAttendeesLimit: getNumberFromFieldInput(
    form[TourField.ECommerceAttendeesLimit]
  ),
  eCommerceOrderAttendeesLimit: getNumberFromFieldInput(
    form[TourField.ECommerceOrderAttendeesLimit]
  ),
  ...transformTourDivisionSettingsToInput(form)
})

export const transformDivisionSettingsToUpdateForm = (
  tour: TourFieldsFragment
): Partial<IUpdateTourForm> => {
  const eCommerceSalesEndOption = transformDivisionSettingToTimeSelectOption(
    tour.salesEndWebType
  )
  const retailSalesEndOption = transformDivisionSettingToTimeSelectOption(
    tour.salesEndCashDeskType
  )
  const eCommerceReservationExpirationOption =
    transformDivisionSettingToTimeSelectOption(tour.reservationEndWebType)
  const eCommerceReservationEndOption =
    transformDivisionSettingToTimeSelectOption(
      tour.eCommerceCreateReservationEndType
    )
  const retailReservationExpirationOption =
    transformDivisionSettingToTimeSelectOption(tour.reservationEndCashDeskType)
  const retailReservationEndOption = transformDivisionSettingToTimeSelectOption(
    tour.retailCreateReservationEndType
  )
  const gateOpensOption = transformDivisionSettingToTimeSelectOption(
    tour.gateOpensType
  )
  const gateClosesOption = transformDivisionSettingToTimeSelectOption(
    tour.gateClosesType
  )
  return {
    [TourFormDivisionSettingsField.IsECommerceSaleActive]:
      tour.isECommerceSaleActive,
    [TourFormDivisionSettingsField.ECommerceSalesEnd]: {
      [TimeSelectFormField.Option]: eCommerceSalesEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        eCommerceSalesEndOption,
        tour.salesEndWebValue
      )
    },
    [TourFormDivisionSettingsField.IsRetailSaleActive]: tour.isRetailSaleActive,
    [TourFormDivisionSettingsField.RetailSalesEnd]: {
      [TimeSelectFormField.Option]: retailSalesEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        retailSalesEndOption,
        tour.salesEndCashDeskValue
      )
    },
    [TourFormDivisionSettingsField.IsECommerceReservationActive]:
      tour.isECommerceReservationActive,
    [TourFormDivisionSettingsField.ECommerceReservationExpiration]: {
      [TimeSelectFormField.Option]: eCommerceReservationExpirationOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        eCommerceReservationExpirationOption,
        tour.reservationEndWebValue
      )
    },
    [TourFormDivisionSettingsField.ECommerceReservationEnd]: {
      [TimeSelectFormField.Option]: eCommerceReservationEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        eCommerceReservationEndOption,
        tour.eCommerceCreateReservationEndValue
      )
    },
    [TourFormDivisionSettingsField.IsRetailReservationActive]:
      tour.isRetailReservationActive,
    [TourFormDivisionSettingsField.RetailReservationExpiration]: {
      [TimeSelectFormField.Option]: retailReservationExpirationOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        retailReservationExpirationOption,
        tour.reservationEndCashDeskValue
      )
    },
    [TourFormDivisionSettingsField.RetailReservationEnd]: {
      [TimeSelectFormField.Option]: retailReservationEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        retailReservationEndOption,
        tour.retailCreateReservationEndValue
      )
    },
    [TourFormDivisionSettingsField.GateOpens]: {
      [TimeSelectFormField.Option]: gateOpensOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        gateOpensOption,
        tour.gateOpensValue
      )
    },
    [TourFormDivisionSettingsField.GateCloses]: {
      [TimeSelectFormField.Option]: gateClosesOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        gateClosesOption,
        tour.gateClosesValue
      )
    }
  }
}
