import DeleteIcon from '@mui/icons-material/Delete'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {IconButton, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode, ProductQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateProductMode} from '../../../../../hooks/translateProductMode'
import {
  useTranslateProductState,
  useTranslateProductStateDescription
} from '../../../../../hooks/translateProductState'
import {useTranslateUnit} from '../../../../../hooks/translateUnit'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {EntityStateChip} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {productStateColors} from '../../../../constants'
import {UploadImageDrawer} from '../../components/UploadImageDrawer'
import {
  useDeleteProductCoverImage,
  useUploadProductCoverImage
} from '../graphql'

const useGeneralSectionStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'grid',
    gridTemplateAreas: `
      "image              productDetails"
      "moreProductDetails moreProductDetails"
    `,
    gridTemplateColumns: '200px 1fr',
    gap: theme.spacing(3)
  },
  image: {
    gridArea: 'image',
    position: 'relative'
  },
  productDetails: {
    gridArea: 'productDetails',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  moreProductDetails: {
    gridArea: 'moreProductDetails',
    paddingTop: theme.spacing(3),
    borderTop: `solid ${theme.palette.divider} 1px`
  },
  editButton: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)'
    }
  },
  topRight: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5)
  },
  state: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1)
  },
  infoRow: {
    gap: theme.spacing(3)
  }
}))

interface IGeneralSectionProps {
  id: string
  product: ProductQuery['product']
}

export const GeneralSection: React.FC<IGeneralSectionProps> = ({
  id,
  product
}: IGeneralSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const uploadProductCoverImage = useUploadProductCoverImage()
  const deleteProductCoverImage = useDeleteProductCoverImage()
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const {
    state: isUploadImageDrawerOpen,
    setTrue: openUploadImageDrawer,
    setFalse: closeUploadImageDrawer
  } = useBooleanState(false)
  const {
    state: isDeleteDialogOpen,
    setTrue: openDeleteDialog,
    setFalse: closeDeleteDialog
  } = useBooleanState(false)
  const classes = useGeneralSectionStyles()
  const translateProductState = useTranslateProductState()
  const translateProductStateDescription = useTranslateProductStateDescription()
  const translateUnit = useTranslateUnit()
  const translateProductMode = useTranslateProductMode()
  const handleImageUpload = useCallback(
    async (croppedImage: Blob, name: string) => {
      try {
        setShowBackdrop(true)
        await uploadProductCoverImage({
          id: product.id,
          file: new File([croppedImage], `${name}-cropped.jpg`, {
            lastModified: new Date().getTime(),
            type: 'image/jpg'
          })
        })
        addInfoNotification(t('Image has been updated'))
        closeUploadImageDrawer()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while uplading product cover image')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeUploadImageDrawer,
      defaultErrorHandler,
      product.id,
      setShowBackdrop,
      t,
      uploadProductCoverImage
    ]
  )
  const handleDeleteConfirm = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteProductCoverImage({id: product.id})
      closeDeleteDialog()
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting product cover image'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    closeDeleteDialog,
    defaultErrorHandler,
    deleteProductCoverImage,
    product.id,
    setShowBackdrop,
    t
  ])
  return (
    <>
      <Paper variant="outlined" className={classes.root} id={id}>
        <div className={classes.image}>
          <img
            src={product.coverImageURL || '/noProductImage.png'}
            alt={product.name}
            style={{width: '100%', height: '100%'}}
          />
          {P([PermissionCode.UploadProductCoverImage]) &&
            !product.coverImageURL && (
              <IconButton
                size="small"
                className={cn(classes.editButton, classes.topRight)}
                onClick={openUploadImageDrawer}
              >
                <FileUploadIcon />
              </IconButton>
            )}
          {P([PermissionCode.DeleteProductCoverImage]) &&
            product.coverImageURL && (
              <IconButton
                size="small"
                className={cn(classes.editButton, classes.topRight)}
                onClick={openDeleteDialog}
              >
                <DeleteIcon />
              </IconButton>
            )}
        </div>
        <div className={classes.productDetails}>
          <Typography variant="h6">{product.name}</Typography>
          <div className={classes.state}>
            <EntityStateChip
              colorConf={productStateColors[product.state]}
              label={translateProductState(product.state)}
            />
            <Typography variant="caption" color="textSecondary">
              {translateProductStateDescription(product.state)}
            </Typography>
          </div>
          {product.internalDescription && (
            <SingleInfoColumn
              caption={t('Internal description')}
              value={product.internalDescription}
              valueTypographyVariant="body2"
            />
          )}
          {product.receiptName && (
            <SingleInfoColumn
              caption={t('Receipt name')}
              value={product.receiptName}
              valueTypographyVariant="body2"
            />
          )}
        </div>
        <div className={classes.moreProductDetails}>
          <DividerSeparatedInfoRow
            className={classes.infoRow}
            information={[
              {
                caption: t('Product mode'),
                value: translateProductMode(product.mode)
              },
              {
                caption: t('Product type'),
                value: product.productType.name
              },
              {
                caption: t('Unit of measure'),
                value: translateUnit(product.unit)
              },
              {
                caption: t('Internal code'),
                value: product.internalCode
              },
              {
                caption: t('PLU'),
                value: product.priceLookupCode
              }
            ]}
          />
        </div>
      </Paper>
      <UploadImageDrawer
        isOpen={isUploadImageDrawerOpen}
        onClose={closeUploadImageDrawer}
        croppedDimensions={{width: 450, height: 300}}
        onSubmit={handleImageUpload}
      />
      <ConfirmationDialog
        title={t('Delete cover image?')}
        contentText={t(
          'This action will permanently remove the current cover image from your product. Please note that once deleted, this image cannot be recovered.'
        )}
        confirmButtonLabel={t('Delete')}
        isOpen={isDeleteDialogOpen}
        onCancel={closeDeleteDialog}
        onConfirm={handleDeleteConfirm}
      />
    </>
  )
}
