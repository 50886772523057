import {Typography} from '@mui/material'
import {TypographyProps} from '@mui/material/Typography/Typography'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useElementDimensions} from '../../../hooks/dimensions'

import {Theme} from '../../../theme'

type AlignLabelType = 'top' | 'center'

const CHIP_INNER_VERTICAL_PADDING = 5

const useRowWrapperStyles = makeStyles<
  Theme,
  {alignLabel: AlignLabelType; hasColumns: boolean}
>((theme) => ({
  grid: {
    display: 'grid',
    gap: ({hasColumns}) => (hasColumns ? theme.spacing(2) : 0),
    gridTemplateColumns: ({hasColumns}) => (hasColumns ? '124px 1fr' : '1fr'),
    alignItems: ({alignLabel}) => alignLabel,
    flexGrow: 1
  },
  children: {
    paddingTop: theme.spacing(2),
    '$grid:last-child &': {
      paddingBottom: theme.spacing(2)
    }
  },
  label: {
    paddingTop: ({alignLabel}) =>
      alignLabel === 'top'
        ? 16 + CHIP_INNER_VERTICAL_PADDING
        : theme.spacing(2),
    '$grid:last-child &': {
      paddingBottom: ({alignLabel}) =>
        alignLabel === 'top' ? 0 : theme.spacing(2)
    }
  }
}))

interface ISearchRowWithLabelProps {
  label: string
  labelColor?: TypographyProps['color']
  children: React.ReactNode
  alignLabel?: AlignLabelType
}

export const SearchRowWithLabel: React.FC<ISearchRowWithLabelProps> = ({
  label,
  labelColor,
  children,
  alignLabel = 'center'
}: ISearchRowWithLabelProps) => {
  const [ref, {width}] = useElementDimensions()
  const classes = useRowWrapperStyles({
    alignLabel,
    hasColumns: width > 500
  })
  return (
    <div className={classes.grid} ref={ref}>
      <Typography variant="body2" color={labelColor} className={classes.label}>
        {label}
      </Typography>
      <div className={classes.children}>{children}</div>
    </div>
  )
}
