import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Division,
  Tour,
  TourTimeSlot,
  User,
  Venue
} from '../../../__generated__/schema'
import {useTranslateAgeClassification} from '../../../hooks/translateAgeClassification'
import {useTranslateShowVersion} from '../../../hooks/translateDistributions'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../utils/formatting'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple
} from '../DrawerUtils'

type TourTimeSlotPick = Pick<
  TourTimeSlot,
  'startsAt' | 'duration' | 'versionCode' | 'ageClassificationCode'
> & {
  venue: Pick<Venue, 'name' | 'address'>
} & {
  tour: Pick<Tour, 'name'> & {
    division: Pick<Division, 'name'>
  }
} & {
  guide?: Pick<User, 'firstName' | 'lastName'> | null
}

interface ITourTimeSlotDrawerSectionProps<T extends TourTimeSlotPick> {
  tourTimeSlot: T
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const TourTimeSlotDrawerSection = <T extends TourTimeSlotPick>({
  tourTimeSlot,
  drawerSectionProps
}: ITourTimeSlotDrawerSectionProps<T>) => {
  const {t} = useTranslation()
  const {formatTime, formatDate} = useDateTimeFormatters()
  const {durationToMinutesFormat, durationToHoursMinutesLongFormat} =
    useShowDurationFormatters()
  const translateShowVersion = useTranslateShowVersion()
  const translateAgeClassification = useTranslateAgeClassification()
  const startsAtDate = new Date(tourTimeSlot.startsAt)
  return (
    <DrawerSection {...drawerSectionProps}>
      <SectionGrid>
        <SectionGridCouple label={t('Title')}>
          {tourTimeSlot.tour.name}
        </SectionGridCouple>
        <SectionGridCouple label={t('Date')}>
          {formatDate(startsAtDate)}
        </SectionGridCouple>
        <SectionGridCouple label={t('Time')}>
          {formatTime(startsAtDate)}
        </SectionGridCouple>
        <SectionGridCouple label={t('Duration')}>
          {`${durationToHoursMinutesLongFormat(
            tourTimeSlot.duration
          )}, (${durationToMinutesFormat(tourTimeSlot.duration)})`}
        </SectionGridCouple>
        {tourTimeSlot.guide && (
          <SectionGridCouple label={t('Guide')}>
            {`${tourTimeSlot.guide.firstName} ${tourTimeSlot.guide.lastName}`}
          </SectionGridCouple>
        )}
        {tourTimeSlot.versionCode && (
          <SectionGridCouple label={t('Version')}>
            {translateShowVersion(tourTimeSlot.versionCode)}
          </SectionGridCouple>
        )}
        {tourTimeSlot.ageClassificationCode && (
          <SectionGridCouple label={t('Age restrictions')}>
            {translateAgeClassification(tourTimeSlot.ageClassificationCode)}
          </SectionGridCouple>
        )}
        <SectionGridCouple label={t('Venue')}>
          {[
            tourTimeSlot.venue.name,
            tourTimeSlot.venue.address.complex,
            tourTimeSlot.venue.address.street,
            tourTimeSlot.venue.address.postalCode,
            tourTimeSlot.venue.address.town
          ]
            .filter(Boolean)
            .join(', ')}
        </SectionGridCouple>
        <SectionGridCouple label={t('Division')}>
          {tourTimeSlot.tour.division.name}
        </SectionGridCouple>
      </SectionGrid>
    </DrawerSection>
  )
}
