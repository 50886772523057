import {useTranslation} from 'react-i18next'
import {RoleType} from '../__generated__/schema'

export const useTranslateRoleType = () => {
  const {t} = useTranslation()
  const translatedRoleTypes: Record<RoleType, string> = {
    [RoleType.Admin]: t<string>('RoleType->Admin'),
    [RoleType.Client]: t<string>('RoleType->Client'),
    [RoleType.Public]: t<string>('RoleType->Public')
  }

  return (roleType: RoleType) => translatedRoleTypes[roleType]
}
