import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {ApiGrantDetail} from './apiGrantDetail'
import {ApiGrantsPage} from './ApiGrantsPage'

export const ApiGrants: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadApiGrant]) && (
        <Route
          path={routeTo.admin.apiGrants.detail(':apiGrantId')}
          component={ApiGrantDetail}
        />
      )}
      <Route
        path={routeTo.admin.apiGrants.index()}
        component={ApiGrantsPage}
        exact
      />
    </Switch>
  )
}
