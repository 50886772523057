import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CheckPassCodesMutation,
  CheckPassCodesMutationVariables,
  SaleByItemPassCodeQuery,
  SaleByItemPassCodeQueryVariables
} from '../../../../../__generated__/schema'
import {TRANSLATED_LOCALES_FRAGMENT} from '../../graphql'

export const PASS_CODE_CHECK_FRAGMENT = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment PassCodeCheckProperties on PassCodeCheck {
    id
    state
    additionalInformation
    tourItem {
      id
      admissionType {
        id
        name
      }
      tourTimeSlot {
        id
        names {
          ...TranslatedLocales
        }
        gateOpensAt
        gateClosesAt
        startsAt
        passCodesCheckedInCount
        passCodesCheckedOutCount
        uniqueCheckedInCount
        tourItemsStateCounts {
          reserved
          sold
        }
      }
    }
    ticketItem {
      id
      appliedDiscounts {
        discount {
          id
          internalDescription
          name
        }
      }
      eventSeat {
        id
        row
        label
        floor
        section
        event {
          id
          names {
            ...TranslatedLocales
          }
          passCodesCheckedInCount
          passCodesCheckedOutCount
          reservedSeatsCount
          soldSeatsCount
          uniqueCheckedInCount
          gateOpensAt
          gateClosedAt
        }
      }
    }
  }
`

export const CHECK_PASS_CODE_IN = gql`
  mutation checkPassCodeIn(
    $passCode: String!
    $inputType: PassCodeCheckInputType!
    $discountAuthorizationMode: DiscountAuthorizationMode!
    $itemFilter: ItemFilter
  ) {
    checkPassCodeIn(
      passCode: $passCode
      inputType: $inputType
      discountAuthorizationMode: $discountAuthorizationMode
      itemFilter: $itemFilter
    ) {
      ...PassCodeCheckProperties
    }
  }
  ${PASS_CODE_CHECK_FRAGMENT}
`
export const CHECK_PASS_CODE_OUT = gql`
  mutation checkPassCodeOut(
    $passCode: String!
    $inputType: PassCodeCheckInputType!
    $itemFilter: ItemFilter
  ) {
    checkPassCodeOut(
      passCode: $passCode
      inputType: $inputType
      itemFilter: $itemFilter
    ) {
      ...PassCodeCheckProperties
    }
  }
  ${PASS_CODE_CHECK_FRAGMENT}
`
export const UPDATE_PASS_CODE_CHECK = gql`
  mutation updatePassCodeCheck(
    $id: PositiveInt!
    $authorizeDiscount: Boolean!
  ) {
    updatePassCodeCheck(id: $id, authorizeDiscount: $authorizeDiscount) {
      ...PassCodeCheckProperties
    }
  }
  ${PASS_CODE_CHECK_FRAGMENT}
`

const ITEM_BY_PASS_CODE_TICKET_ITEM_FIELDS = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment ItemByPassCodeTicketItemFields on TicketItem {
    id
    eventSeat {
      id
      label
      row
      floor
      section
      event {
        id
        names {
          ...TranslatedLocales
        }
        startsAt
      }
    }
    ticketItemCheckStatus: checkStatus
  }
`

const ITEM_BY_PASS_CODE_TOUR_ITEM_FIELDS = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment ItemByPassCodeTourItemFields on TourItem {
    id
    admissionType {
      id
      name
    }
    tourTimeSlot {
      id
      names {
        ...TranslatedLocales
      }
      startsAt
    }
    tourItemCheckStatus: checkStatus
  }
`

export const ITEM_BY_PASS_CODE = gql`
  ${ITEM_BY_PASS_CODE_TICKET_ITEM_FIELDS}
  ${ITEM_BY_PASS_CODE_TOUR_ITEM_FIELDS}
  query itemByPassCode($passCode: NonEmptyString!, $itemFilter: ItemFilter) {
    itemByPassCode(passCode: $passCode, itemFilter: $itemFilter) {
      ...ItemByPassCodeTicketItemFields
      ...ItemByPassCodeTourItemFields
    }
  }
`

const SALE_BY_ITEM_PASS_CODE_TICKET_ITEM_FIELDS = gql`
  fragment SaleByItemPassCodeTicketItemFields on TicketItem {
    id
    passCode
    claim {
      id
    }
    ticketItemCheckStatus: checkStatus
    eventPricingToTicketType {
      id
      ticketTypeId
      name
    }
    appliedDiscounts {
      discount {
        id
        name
      }
    }
  }
`

const SALE_BY_ITEM_PASS_CODE_TOUR_ITEM_FIELDS = gql`
  fragment SaleByItemPassCodeTourItemFields on TourItem {
    id
    passCode
    attendeesCount
    claim {
      id
    }
    admissionType {
      id
      name
    }
    appliedDiscounts {
      discount {
        id
        name
      }
    }
    tourItemCheckStatus: checkStatus
  }
`

const SALE_BY_ITEM_PASS_CODE_PRODUCT_ITEM_FIELDS = gql`
  fragment SaleByItemPassCodeProductItemFields on ProductItem {
    id
  }
`

const SALE_BY_ITEM_PASS_CODE = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${SALE_BY_ITEM_PASS_CODE_TICKET_ITEM_FIELDS}
  ${SALE_BY_ITEM_PASS_CODE_TOUR_ITEM_FIELDS}
  ${SALE_BY_ITEM_PASS_CODE_PRODUCT_ITEM_FIELDS}
  query SaleByItemPassCode($passCode: NonEmptyString!) {
    saleByItemPassCode(passCode: $passCode) {
      id
      itemType
      tourTimeSlot {
        id
        tour {
          id
          name
        }
        startsAt
        endsAt
        duration
        venue {
          id
          name
        }
      }
      event {
        id
        names {
          ...TranslatedLocales
        }
        startsAt
        endsAt
        duration
        auditorium {
          id
          name
        }
        venue {
          id
          name
        }
      }
      items {
        ...SaleByItemPassCodeTicketItemFields
        ...SaleByItemPassCodeTourItemFields
        ...SaleByItemPassCodeProductItemFields
      }
    }
  }
`

export const useSaleByItemPassCode = (passCode: string, skip?: boolean) =>
  useQuery<SaleByItemPassCodeQuery, SaleByItemPassCodeQueryVariables>(
    SALE_BY_ITEM_PASS_CODE,
    {variables: {passCode}, skip, fetchPolicy: 'network-only'}
  )

export const CHECK_PASS_CODES = gql`
  mutation CheckPassCodes(
    $itemFilter: ItemFilter
    $passCodes: [NonEmptyString!]!
    $direction: PassCodeCheckDirection!
    $inputType: PassCodeCheckInputType!
    $discountAuthorizationMode: DiscountAuthorizationMode!
  ) {
    checkPassCodes(
      itemFilter: $itemFilter
      passCodes: $passCodes
      direction: $direction
      inputType: $inputType
      discountAuthorizationMode: $discountAuthorizationMode
    ) {
      id
      passCode
      bulkCheckUUID
      eventId
      tourTimeSlotId
      state
    }
  }
`

export const useCheckPassCodes = () => {
  const [checkPassCodes] =
    useMutation<CheckPassCodesMutation, CheckPassCodesMutationVariables>(
      CHECK_PASS_CODES
    )
  return useCallback(
    (variables: CheckPassCodesMutationVariables) => checkPassCodes({variables}),
    [checkPassCodes]
  )
}
