import SendIcon from '@mui/icons-material/SendOutlined'
import {Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {difference, groupBy, sortBy} from 'lodash'
import uniqBy from 'lodash/uniqBy'
import React, {ChangeEvent, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartDetailPropertiesFragment,
  CartDetailSalePropertiesFragment,
  CartState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {safeSum} from '../../../../../utils/money'
import {routeTo} from '../../../../../utils/routes'
import {
  EmailDialog,
  EmailDialogField,
  IEmailDialogForm
} from '../../../../common/EmailDialog'
import {MassCheckboxState} from '../../../../common/MassCheckbox'
import {useCreateClaim} from '../../claims/graphql'
import {useSendSaleConfirmationEmail} from '../../graphql'
import {
  isProductItemPropertiesFragment,
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'
import {ClaimDialog} from '../claimDetailDrawer/ClaimDialog'
import {CheckboxState} from './Item'
import {ItemsAccordion} from './ItemsAccordion'
import {CartDetailDrawerType} from './types'

interface IItemsSectionProps {
  cart: CartDetailPropertiesFragment
  type: CartDetailDrawerType
}

const useStyles = makeStyles<Theme>((theme) => ({
  noItemsFoundRoot: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  }
}))

export const ItemsSection: React.FC<IItemsSectionProps> = ({
  cart,
  type
}: IItemsSectionProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const sendSaleConfirmationEmail = useSendSaleConfirmationEmail()
  const history = useHistory()
  const createClaim = useCreateClaim()

  const productItems = (cart.items || []).filter(
    isProductItemPropertiesFragment
  )
  const productItemsByDivisionId = groupBy(productItems, 'division.id')
  const uniqDivisions = uniqBy(
    productItems.map((productItem) => productItem.division),
    'id'
  )
  const ticketItems = (cart.items || []).filter(isTicketItemPropertiesFragment)
  const ticketItemsByEventId = groupBy(ticketItems, 'eventSeat.event.id')
  const uniqEvents = uniqBy(
    ticketItems.map((ticketItem) => ticketItem.eventSeat.event),
    'id'
  )
  const tourItems = (cart.items || []).filter(isTourItemPropertiesFragment)
  const tourItemsByTimeSlotId = groupBy(tourItems, 'tourTimeSlot.id')
  const uniqTourTimeSlots = uniqBy(
    tourItems.map((tourItem) => tourItem.tourTimeSlot),
    'id'
  )
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(null)
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([])
  const [selectableItemsIds, setSelectableItemsIds] = useState<number[]>([])
  const [saleId, setSaleId] = useState<number | null>(null)
  const {
    state: isCreateClaimDialogOpen,
    setTrue: openCreateClaimDialog,
    setFalse: closeCreateClaimDialog
  } = useBooleanState(false)
  const {
    state: isSendEmailDialogOpen,
    setTrue: openSendEmailDialog,
    setFalse: closeSendEmailDialog
  } = useBooleanState(false)

  const checkboxesControllerState =
    selectedItemIds.length === 0
      ? MassCheckboxState.Unselected
      : difference(selectableItemsIds, selectedItemIds).length === 0
      ? MassCheckboxState.Selected
      : MassCheckboxState.Indeterminate

  const getSaleChangeHandler = useCallback(
    (sale: CartDetailSalePropertiesFragment) => () => {
      setOpenedAccordion((prev) =>
        prev === String(sale.id) ? null : String(sale.id)
      )
      setSelectableItemsIds(
        sale
          .items!.filter((ti) => ti.sale?.id === sale.id && !ti.claim)
          .map(({id}) => id)
      )
      setSelectedItemIds([])
      setSaleId(sale.id)
    },
    []
  )

  const getItemChangeHandler = useCallback(
    (id: string) => () => {
      setOpenedAccordion((prev) => (prev === id ? null : id))
    },
    []
  )

  const createItemCheckboxClickHandler = useCallback(
    (itemId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedItemIds((ids) =>
        ids.includes(itemId)
          ? ids.filter((id) => id !== itemId)
          : [...ids, itemId]
      )
    },
    []
  )

  const [reason, setReason] = useState<string>('')
  const handleReasonInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setReason(e.target.value)
    },
    []
  )

  const handleOnCreateButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await createClaim({reason, itemIds: selectedItemIds})
      if (data?.createClaim) {
        history.replace(routeTo.admin.claims.detail(data.createClaim.id))
        addInfoNotification(t('Claim was created'))
      }
    } catch (e) {
      defaultErrorHandler(e, 'Error while creating claim')
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    createClaim,
    defaultErrorHandler,
    history,
    reason,
    selectedItemIds,
    setShowBackdrop,
    t
  ])

  const handleOnCancelButtonClick = useCallback(() => {
    setReason('')
    setSelectedItemIds([])
    closeCreateClaimDialog()
  }, [closeCreateClaimDialog])

  const handleSendSaleConfirmationSubmit = useCallback(
    async (form: IEmailDialogForm) => {
      if (cart.hash && cart.uuid && saleId) {
        try {
          setShowBackdrop(true)
          await sendSaleConfirmationEmail({
            cartUUID: cart.uuid,
            cartHash: cart.hash,
            saleInputs: [{id: saleId, itemIds: selectedItemIds}],
            deliverTo: [form[EmailDialogField.Email]]
          })
          setSelectedItemIds([])
          addInfoNotification(
            t('Message was sent to {{email}}', {
              email: form[EmailDialogField.Email]
            })
          )
        } catch (e) {
          defaultErrorHandler(e, 'Error while sending sale confirmation email')
        } finally {
          setShowBackdrop(false)
          closeSendEmailDialog()
        }
      }
    },
    [
      addInfoNotification,
      cart.hash,
      cart.uuid,
      closeSendEmailDialog,
      defaultErrorHandler,
      saleId,
      selectedItemIds,
      sendSaleConfirmationEmail,
      setShowBackdrop,
      t
    ]
  )

  return cart.items?.length ? (
    <div>
      {isCreateClaimDialogOpen && (
        <ClaimDialog
          title={t('Create claim')}
          confirmationButtonLabel={t('Create')}
          text={reason}
          onChange={handleReasonInputChange}
          onConfirm={handleOnCreateButtonClick}
          onCancel={handleOnCancelButtonClick}
        />
      )}
      <EmailDialog
        isOpen={isSendEmailDialogOpen}
        title={t('Send sale confirmation')}
        description={t(
          'Confirmation e-mail will contain only selected tickets.'
        )}
        submitButtonLabel={t('Send')}
        submitButtonStartIcon={<SendIcon />}
        inputPlaceholder={t('Type email here')}
        inputLabel={t('Email address')}
        onSubmit={handleSendSaleConfirmationSubmit}
        onCancel={closeSendEmailDialog}
        defaultValues={{
          [EmailDialogField.Email]: cart.lead?.data.email || undefined
        }}
      />
      {cart.state === CartState.Sold ? (
        sortBy(cart.sales, 'event.startsAt').map(
          (sale: CartDetailSalePropertiesFragment) => {
            const tourItem = (sale.items || []).find(
              (item) =>
                isTourItemPropertiesFragment(item) && item.sale?.id === sale.id
            )
            return (
              sale.items?.length && (
                <ItemsAccordion
                  key={sale.id}
                  isExpanded={String(sale.id) === openedAccordion}
                  onChange={getSaleChangeHandler(sale)}
                  event={sale.event}
                  price={sale.price}
                  itemsCount={sale.items.length}
                  cartState={cart.state}
                  cartId={cart.id}
                  uuid={cart.uuid}
                  hash={cart.hash}
                  items={sale.items}
                  checkboxesControllerState={checkboxesControllerState}
                  onMassCheckboxClick={() =>
                    checkboxesControllerState === MassCheckboxState.Unselected
                      ? setSelectedItemIds(selectableItemsIds)
                      : setSelectedItemIds([])
                  }
                  onCheckboxClick={createItemCheckboxClickHandler}
                  isMassCheckboxDisabled={selectableItemsIds.length === 0}
                  selectedItemIds={selectedItemIds}
                  onClaimButtonClick={openCreateClaimDialog}
                  isClaimButtonDisabled={selectedItemIds.length === 0}
                  onSendEmailButtonClick={openSendEmailDialog}
                  isSendEmailButtonDisabled={selectedItemIds.length === 0}
                  isDownloadButtonDisabled={selectedItemIds.length === 0}
                  downloadButtonUrl={
                    saleId && selectedItemIds.length
                      ? `/pdf/saleTickets?saleId=${saleId}&itemIds=${selectedItemIds}&pdfFilename=tickets_ID${saleId}_${Date.now()}`
                      : undefined
                  }
                  currency={cart.client.currency}
                  type={type}
                  division={sale.division}
                  venue={sale.venue}
                  tourTimeSlot={
                    tourItem && isTourItemPropertiesFragment(tourItem)
                      ? tourItem.tourTimeSlot
                      : undefined
                  }
                />
              )
            )
          }
        )
      ) : (
        <>
          {uniqEvents.map((event) => {
            const price = safeSum(
              Object.values(ticketItemsByEventId[event.id]).map(
                (ticket) => ticket.price
              )
            )
            return (
              <ItemsAccordion
                key={event.id}
                isExpanded={`ticket-item-${event.id}` === openedAccordion}
                onChange={getItemChangeHandler(`ticket-item-${event.id}`)}
                event={event}
                price={price}
                itemsCount={ticketItemsByEventId[event.id].length}
                cartState={cart.state}
                cartId={cart.id}
                items={ticketItemsByEventId[event.id]}
                checkboxState={CheckboxState.None}
                currency={cart.client.currency}
                type={type}
                division={event.division}
                venue={event.venue}
              />
            )
          })}
          {uniqDivisions.map((division) => {
            const price = safeSum(
              Object.values(productItemsByDivisionId[division.id]).map(
                (product) => product.price
              )
            )
            return (
              <ItemsAccordion
                key={division.id}
                isExpanded={`product-item-${division.id}` === openedAccordion}
                onChange={getItemChangeHandler(`product-item-${division.id}`)}
                price={price}
                itemsCount={productItemsByDivisionId[division.id].length}
                cartState={cart.state}
                cartId={cart.id}
                items={productItemsByDivisionId[division.id]}
                checkboxState={CheckboxState.None}
                currency={cart.client.currency}
                type={type}
              />
            )
          })}
          {uniqTourTimeSlots.map((tourTimeSlot) => {
            const price = safeSum(
              Object.values(tourItemsByTimeSlotId[tourTimeSlot.id]).map(
                (slot) => slot.price
              )
            )
            return (
              <ItemsAccordion
                key={tourTimeSlot.id}
                isExpanded={`tour-item-${tourTimeSlot.id}` === openedAccordion}
                onChange={getItemChangeHandler(`tour-item-${tourTimeSlot.id}`)}
                price={price}
                itemsCount={tourItemsByTimeSlotId[tourTimeSlot.id].length}
                cartState={cart.state}
                cartId={cart.id}
                items={tourItemsByTimeSlotId[tourTimeSlot.id]}
                currency={cart.client.currency}
                type={type}
                checkboxState={CheckboxState.None}
                tourTimeSlot={tourTimeSlot}
                division={tourTimeSlot.tour.division}
              />
            )
          })}
        </>
      )}
    </div>
  ) : (
    <Paper variant="outlined" className={classes.noItemsFoundRoot}>
      <Typography variant="subtitle2" color="inherit">
        {t('No items found')}
      </Typography>
    </Paper>
  )
}
