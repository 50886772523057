import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {Box, Chip, Divider, SxProps} from '@mui/material'
import {TourItemsStateCountsFieldsFragment} from 'frontend/src/__generated__/schema'
import {CapacityIndicator} from 'frontend/src/components/common/CapacityIndicator'
import React from 'react'
import {useTranslation} from 'react-i18next'

const getAvailableCount = (
  stateCounts: TourItemsStateCountsFieldsFragment,
  retailAttendeesLimit?: number | null
) =>
  typeof retailAttendeesLimit === 'number'
    ? retailAttendeesLimit -
      (stateCounts.reserved + stateCounts.sold + stateCounts.inCart)
    : undefined

interface ITimeSlotCapacityIndicatorsProps {
  retailAttendeesLimit?: number | null
  stateCounts: TourItemsStateCountsFieldsFragment
  hideCapacity?: boolean
  hideSoldOut?: boolean
  sx?: SxProps
}

export const TimeSlotCapacityIndicators: React.FC<ITimeSlotCapacityIndicatorsProps> =
  ({
    retailAttendeesLimit,
    stateCounts,
    hideCapacity,
    hideSoldOut,
    sx
  }: ITimeSlotCapacityIndicatorsProps) => {
    const {t} = useTranslation()
    const {sold, reserved} = stateCounts
    const availableCount = getAvailableCount(stateCounts, retailAttendeesLimit)
    const isSoldOut =
      typeof availableCount === 'number' ? availableCount <= 0 : false
    return isSoldOut && !hideSoldOut ? (
      <Chip
        sx={{
          color: 'error.main',
          backgroundColor: '#FEE4E2'
        }}
        label={t('Sold out')}
        size="small"
      />
    ) : (
      <Box sx={{display: 'flex', gap: 0.5, ...sx}}>
        {typeof retailAttendeesLimit === 'number' && (
          <>
            {typeof availableCount === 'number' && availableCount > 0 ? (
              <CapacityIndicator
                tooltipTitle={t('Available')}
                countLabel={String(availableCount)}
                customIcon={<PersonOutlineIcon sx={{width: 18, height: 18}} />}
              />
            ) : (
              <Chip
                sx={{
                  color: 'error.main',
                  backgroundColor: '#FEE4E2'
                }}
                label={t('Sold out')}
                size="small"
              />
            )}
          </>
        )}
        {reserved !== 0 && (
          <CapacityIndicator
            tooltipTitle={t('Reserved')}
            colorSx={{color: 'info.main'}}
            countLabel={String(reserved)}
            iconSize={18}
          />
        )}
        <CapacityIndicator
          tooltipTitle={t('Sold')}
          colorSx={{color: 'error.main'}}
          countLabel={String(sold)}
          iconSize={18}
        />
        {typeof retailAttendeesLimit === 'number' && !hideCapacity && (
          <>
            <Divider orientation="vertical" sx={{height: 18}} />
            <CapacityIndicator
              tooltipTitle={t('Capacity')}
              countLabel={String(retailAttendeesLimit)}
              customIcon={<GroupOutlinedIcon sx={{width: 18, height: 18}} />}
            />
          </>
        )}
      </Box>
    )
  }
