import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ClientFilesQuery} from '../../../../../../__generated__/schema'
import {RenderOnData} from '../../../../../common'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {ChildrenOnEffectiveClientSelected} from '../../../ChildrenOnEffectiveClientSelected'
import {isFileImage} from '../../../components/utils'
import {useClientFiles} from '../../graphql'
import {
  CardRowFormField,
  CardWithId,
  ICardRowForm
} from '../editBlockDrawer/types'
import {CardRowForm} from './CardRowForm'

const EDIT_CARD_ROW_FORM_ID = 'editCardRowForm'

interface IEditCardRowDialogProps {
  card: CardWithId | null
  onClose: () => void
  onSave: (data: ICardRowForm) => void
}

export const EditCardRowDialog: React.FC<IEditCardRowDialogProps> = ({
  card,
  onClose,
  onSave
}: IEditCardRowDialogProps) => {
  const {t} = useTranslation()
  const [skip, setSkip] = useState<boolean>(!card)
  const {data, loading, error} = useClientFiles(skip)
  useEffect(() => {
    if (card) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [card])
  return (
    <Dialog
      open={Boolean(card)}
      sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit card row')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData<ClientFilesQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading client files')}
          >
            {({clientFiles}) => (
              <CardRowForm
                images={clientFiles.filter(({fileName}) =>
                  isFileImage(fileName)
                )}
                formId={EDIT_CARD_ROW_FORM_ID}
                defaultValues={{
                  [CardRowFormField.CoverImageUrl]: card?.coverImageUrl || '',
                  [CardRowFormField.Title]: card?.title,
                  [CardRowFormField.Description]: card?.description,
                  [CardRowFormField.ButtonLabel]: card?.buttonLabel,
                  [CardRowFormField.ButtonUrl]: card?.buttonUrl,
                  [CardRowFormField.OpenInNewTab]: Boolean(card?.openInNewTab)
                }}
                onSubmit={onSave}
              />
            )}
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={EDIT_CARD_ROW_FORM_ID}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
