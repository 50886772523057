import {Box, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ShowsFilterInput} from '../../../../../__generated__/schema'

import {Theme} from '../../../../../theme'
import {LazyLoadingList} from '../../../../common/LazyLoadingList'
import {
  DEFAULT_SHOWS_FILTER_INPUT,
  ShowsSearch,
  ShowsSearchLocation
} from '../../../../common/ShowsSearch'
import {borderStyle} from '../../../../constants'
import {Error} from '../../../../visual'
import {useGetClientShows} from '../../graphql'
import {useGetEventsScreenData} from '../graphql'
import {ShowCard} from './ShowCard'

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    borderLeft: borderStyle,
    height: '100%',
    background: theme.palette.background.paper,
    display: 'grid',
    gridTemplateRows: 'auto 1fr'
  },
  showCardWrapper: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25)
  },
  noShows: {
    marginLeft: theme.spacing(3)
  }
}))

export const CalendarShowsMenu: React.FC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [filter, setFilter] = useState<ShowsFilterInput>(
    DEFAULT_SHOWS_FILTER_INPUT
  )
  const [expandedCardId, setExpandedCardId] = useState<number | null>(null)

  const {
    data: showsData,
    fetchMore,
    error: showsError,
    loading,
    isLoadingMore
  } = useGetClientShows(filter)
  const {
    data: {sortedVenues, events}
  } = useGetEventsScreenData()

  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!loading && showsData && showsData?.clientShows.pagination.hasMore) {
      fetchMore()
    }
  }, [fetchMore, loading, showsData])

  if (showsError) {
    return <Error error={showsError} message={t('Could not load shows.')} />
  }

  // Note: we must have venues/events loaded before this component is being rendered
  if (!events || !sortedVenues || sortedVenues.length === 0) return null

  if (!showsData) return null

  const {items} = showsData.clientShows

  const onShowCollapse = () => setExpandedCardId(null)

  return (
    <div className={classes.wrapper}>
      <Box padding={1}>
        <ShowsSearch
          onFilterChange={setFilter}
          location={ShowsSearchLocation.Shows}
        />
      </Box>
      <LazyLoadingList
        sx={{p: 1}}
        isLoadingMore={isLoadingMore}
        onScrolledNearTheEndOfTheList={handleScrolledNearTheEndOfTheLayout}
      >
        {items.length > 0 ? (
          items.map((show) => {
            return (
              <div key={show.id} className={classes.showCardWrapper}>
                <ShowCard
                  show={show}
                  expanded={expandedCardId === show.id}
                  onExpand={() => setExpandedCardId(show.id)}
                  onCollapse={onShowCollapse}
                />
              </div>
            )
          })
        ) : (
          <Typography className={classes.noShows}>
            {t('No shows matching the search query.')}
          </Typography>
        )}
      </LazyLoadingList>
    </div>
  )
}
