import {Drawer, drawerClasses, MenuItem, useTheme} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {ProductGroupState} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {Theme} from '../../../../theme'
import {useProductParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {SplitButton} from '../../../visual'
import {useProductGroupEditData, useUpdateProductGroup} from './graphql'
import {ProductGroupForm} from './ProductGroupForm'
import {IProductGroupForm, ProductGroupFormField} from './types'
import {transformFormDataToUpdateProductGroupInput} from './utils'

const UPDATE_PRODUCT_GROUP_FORM_ID = 'updateProductGroupForm'

interface IUpdateProductGroupDrawerProps {
  onExited: () => void
}

export const UpdateProductGroupDrawer: React.FC<IUpdateProductGroupDrawerProps> =
  ({onExited}: IUpdateProductGroupDrawerProps) => {
    const {t} = useTranslation()
    const theme = useTheme<Theme>()
    const {productGroupId} = useProductParams()
    const nextProductGroupState =
      useRef<ProductGroupState | undefined>(undefined)
    const {data, loading, error} = useProductGroupEditData(productGroupId)
    const updateProductGroup = useUpdateProductGroup()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (!isNaN(productGroupId)) {
        openDrawer()
      }
    }, [productGroupId, openDrawer])
    const handleSubmit = useCallback(
      async (formData: IProductGroupForm) => {
        try {
          setShowBackdrop(true)
          await updateProductGroup({
            id: productGroupId,
            input: transformFormDataToUpdateProductGroupInput(
              formData,
              nextProductGroupState.current
            )
          })
          addInfoNotification(t('Product group has been updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating product group'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        defaultErrorHandler,
        productGroupId,
        setShowBackdrop,
        t,
        updateProductGroup
      ]
    )
    const updateNextProductGroupState = useCallback(
      (state: ProductGroupState) => () =>
        (nextProductGroupState.current = state),
      []
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={error && t<string>('Error while loading product group')}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update product group')}
            />
          }
          footer={
            <SplitButton
              Options={[
                <MenuItem
                  key="save-as"
                  type="submit"
                  form={UPDATE_PRODUCT_GROUP_FORM_ID}
                  component="button"
                  onClick={
                    data?.productGroup.state === ProductGroupState.Active
                      ? updateNextProductGroupState(ProductGroupState.Inactive)
                      : updateNextProductGroupState(ProductGroupState.Active)
                  }
                >
                  {data?.productGroup.state === ProductGroupState.Active
                    ? t('Save as inactive')
                    : t('Save as active')}
                </MenuItem>
              ]}
            >
              <SaveButton type="submit" form={UPDATE_PRODUCT_GROUP_FORM_ID}>
                {t('Save')}
              </SaveButton>
            </SplitButton>
          }
        >
          {data && (
            <ProductGroupForm
              formId={UPDATE_PRODUCT_GROUP_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={{
                [ProductGroupFormField.Name]: data.productGroup.name,
                [ProductGroupFormField.Color]:
                  data.productGroup.color || theme.palette.primary.main,
                [ProductGroupFormField.Icon]: data.productGroup.icon,
                [ProductGroupFormField.InternalDescription]:
                  data.productGroup.internalDescription
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
