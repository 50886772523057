import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {TFunction} from 'i18next'
import React, {useEffect} from 'react'
import {
  FieldErrors,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {LeadField, LeadInputStatus} from '../../../__generated__/schema'
import {useTranslateLeadField} from '../../../hooks/leadField'
import {useTranslateLeadInputStatus} from '../../../hooks/leadInputStatus'
import {Theme} from '../../../theme'
import {UncontrolledFormTextInput} from '../../form/FormTextInput'
import {FormFieldName} from '../../form/types'
import {UncontrolledFormSelect} from '../../form/UncontrolledFormSelect'
import {ILeadSettingsForm, LeadOptionInputFormField} from './types'

const getHelperNote = (inputStatus: unknown, t: TFunction) => {
  switch (inputStatus) {
    case LeadInputStatus.Optional:
      return t(
        'Text is visible in checkout below input field. Default is none.'
      )
    case LeadInputStatus.Recommended:
      return t(
        'Text is visible in checkout below input field. Default is “Recommended”.'
      )
    case LeadInputStatus.Required:
      return t(
        'Text is visible in checkout below input field. Default is “Required”.'
      )
    case LeadInputStatus.Unavailable:
    default:
      return ''
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  sectionContent: {
    wordBreak: 'break-all'
  },
  leadOptionInput: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `
      "label label"
      "note note"
      "inputStatus helperText"
    `
  },
  inputStatus: {
    gridArea: 'inputStatus',
    minWidth: 170
  },
  note: {
    gridArea: 'note',
    paddingBottom: theme.spacing(2)
  },
  label: {
    gridArea: 'label',
    paddingBottom: theme.spacing(2)
  },
  helperText: {
    gridArea: 'helperText',
    paddingLeft: theme.spacing(2)
  },
  displayNone: {
    display: 'none'
  }
}))

interface ILeadOptionInputProps {
  errors: FieldErrors<ILeadSettingsForm>
  setValue: FormContextValues<ILeadSettingsForm>['setValue']
  triggerValidation: FormContextValues<ILeadSettingsForm>['triggerValidation']
  register: FormContextValues<ILeadSettingsForm>['register']
  watch: FormContextValues<ILeadSettingsForm>['watch']
  validationOptions?: ValidationOptions
  note?: string
  className?: string
  leadField: LeadField
}

export const LeadOptionInput: React.FC<ILeadOptionInputProps> = ({
  setValue,
  errors,
  register,
  watch,
  triggerValidation,
  leadField,
  note,
  className
}: ILeadOptionInputProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateLeadField = useTranslateLeadField()
  const translateLeadInputStatus = useTranslateLeadInputStatus()
  const inputStatusFieldName = `${leadField}.${LeadOptionInputFormField.INPUT_STATUS}`
  const helperTextFieldName = `${leadField}.${LeadOptionInputFormField.HELPER_TEXT}`
  const inputStatus = watch(inputStatusFieldName)
  useEffect(() => {
    if (inputStatus === LeadInputStatus.Unavailable) {
      setValue(helperTextFieldName, null)
    }
  }, [helperTextFieldName, inputStatus, setValue])
  return (
    <div className={cn(classes.leadOptionInput, className)}>
      <Typography variant="subtitle2" className={classes.label}>
        {translateLeadField(leadField)}
      </Typography>
      {note && (
        <Typography variant="caption" className={classes.note}>
          {note}
        </Typography>
      )}
      <UncontrolledFormSelect<ILeadSettingsForm>
        className={classes.inputStatus}
        name={inputStatusFieldName as FormFieldName<ILeadSettingsForm>}
        register={register}
        setValue={setValue}
        errors={errors}
        watch={watch}
        selectOptions={[
          LeadInputStatus.Unavailable,
          LeadInputStatus.Optional,
          LeadInputStatus.Required,
          LeadInputStatus.Recommended
        ].reduce(
          (acc, inputStatus) => ({
            ...acc,
            [inputStatus]: translateLeadInputStatus(inputStatus)
          }),
          {}
        )}
        label={t('Option')}
      />
      <div
        className={cn(classes.helperText, {
          [classes.displayNone]: inputStatus === LeadInputStatus.Unavailable
        })}
      >
        <UncontrolledFormTextInput<ILeadSettingsForm>
          register={register}
          setValue={setValue}
          watch={watch}
          errors={errors}
          triggerValidation={triggerValidation}
          name={helperTextFieldName as FormFieldName<ILeadSettingsForm>}
          label={t('Custom helper text')}
          fullWidth
          helperNote={getHelperNote(inputStatus, t)}
        />
      </div>
      <input
        type="hidden"
        ref={register()}
        name={`${leadField}.${LeadOptionInputFormField.ID}`}
      />
    </div>
  )
}
