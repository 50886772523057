import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {ListItem, ListItemText, ListItemTextProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LeadInputStatus,
  LeadOptionPropertiesFragment
} from '../../../__generated__/schema'

import {Theme} from '../../../theme'

interface ILeadSettingsItemProps {
  primary: ListItemTextProps['primary']
  leadOptions: LeadOptionPropertiesFragment[]
  onClick: ListItemTextProps['onClick']
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    minHeight: 44,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}))

export const LeadSettingsItem: React.FC<ILeadSettingsItemProps> = ({
  primary,
  leadOptions,
  onClick
}: ILeadSettingsItemProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const requiredLength = leadOptions.filter(
    (option) => option.inputStatus === LeadInputStatus.Required
  ).length
  const requiredString = requiredLength
    ? t('{{count}} required', {
        count: requiredLength
      })
    : null
  const recommendedLength = leadOptions.filter(
    (option) => option.inputStatus === LeadInputStatus.Recommended
  ).length
  const recommendedString = recommendedLength
    ? t('{{count}} recommended', {
        count: recommendedLength
      })
    : null
  const secondary =
    [requiredString, recommendedString].filter(Boolean).join(', ') || null
  return (
    <ListItem button divider onClick={onClick}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        classes={{
          root: classes.root
        }}
      />
      <ChevronRightIcon />
    </ListItem>
  )
}
