import {useTranslation} from 'react-i18next'
import {AccessZoneState} from '../__generated__/schema'

export const useTranslateAccessZoneState = () => {
  const {t} = useTranslation()
  const translated: Record<AccessZoneState, string> = {
    [AccessZoneState.Active]: t('AccessZoneState->Active'),
    [AccessZoneState.Inactive]: t('AccessZoneState->Inactive')
  }
  return (state: AccessZoneState) => translated[state]
}
