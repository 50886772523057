import {CommonSettingsScriptLocation} from '../../../../../../__generated__/schema'

export enum ScriptFormField {
  Id = 'id',
  Content = 'content',
  Location = 'location',
  Src = 'src'
}

export interface IScriptForm {
  [ScriptFormField.Id]: string
  [ScriptFormField.Content]?: string
  [ScriptFormField.Src]?: string
  [ScriptFormField.Location]: CommonSettingsScriptLocation
}
