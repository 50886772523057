import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Divider, SxProps, Typography} from '@mui/material'
import Paper from '@mui/material/Paper'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../theme'
import {Loading} from '../../visual'
import {IdListItem, IIdListItem} from './IdListItem'
import {ListItemID} from './types'

interface IListSectionProps<T extends ListItemID> {
  label: string
  sx?: SxProps<Theme>
  onAddButtonClick: () => void
  onDeleteButtonClick: () => void
  onDeleteOptionClick: (id: T) => void
  isLoading: boolean
  listItemIDs: T[]
  optionsByIds: IIdListItem<T>[]
}

const isListItem = <T extends ListItemID>(
  i?: IIdListItem<T>
): i is IIdListItem<T> => Boolean(i)

export const ListSection = <T extends ListItemID>({
  label,
  sx,
  onAddButtonClick,
  onDeleteButtonClick,
  onDeleteOptionClick,
  isLoading,
  listItemIDs,
  optionsByIds
}: IListSectionProps<T>) => {
  const {t} = useTranslation()
  return (
    <Paper sx={sx} variant="outlined">
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="subtitle2" sx={{flex: 1}}>
          {label}
        </Typography>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={onAddButtonClick}
        >
          {t('Add')}
        </Button>
        <Button
          variant="text"
          sx={{ml: 1}}
          startIcon={<DeleteIcon />}
          onClick={onDeleteButtonClick}
        >
          {t('Delete')}
        </Button>
      </Box>
      <Box>
        {isLoading ? (
          <Loading />
        ) : (
          listItemIDs
            .map((id) => optionsByIds.find((o) => o.id === id))
            .filter(isListItem)
            .map((o, index, array) => (
              <div key={o.id}>
                <IdListItem {...o} onDelete={onDeleteOptionClick} />
                {index !== array.length - 1 && <Divider />}
              </div>
            ))
        )}
      </Box>
    </Paper>
  )
}
