import {Card, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {
  ClientVatRegistered,
  DivisionPropertiesFragment
} from '../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../hooks/effectiveClientCurrencySignInputProps'
import {Theme} from '../../../../../theme'
import {useIsTicketPriceValid} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {FormSwitchInput} from '../../../../form/FormSwitchInput'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {FormTimeInput} from '../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {FormChipsGroupSelect} from '../../../../visual'
import {getAuditoriumLayoutPricings, getChipItems} from './dataUtilts'
import {
  CreateEventAuditoriumLayout,
  CreateEventFormField,
  ICreateEventForm,
  TicketTypeFormField
} from './types'

export const CREATE_EVENT_FORM_ID = 'createEventFormId'

const getTicketTypeFieldName = (
  index: number,
  ticketTypeFormField: TicketTypeFormField
) => `${CreateEventFormField.TICKET_TYPES}.${index}.${ticketTypeFormField}`

const useStyles = makeStyles<Theme, {isVatRegistered: boolean}>((theme) => ({
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  divisionCard: {
    padding: theme.spacing(0.5, 3, 2, 3),
    marginBottom: theme.spacing(3)
  },
  auditoriumCard: {
    padding: theme.spacing(0.5, 3, 2, 3)
  },
  labelsGrid: {
    gridArea: 'labelsGrid',
    display: 'grid',
    gridTemplateColumns: ({isVatRegistered}) =>
      isVatRegistered ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  promotionColumnLabel: {
    justifySelf: 'flex-end',
    paddingRight: theme.spacing(1.5)
  },
  ticketTypesGrid: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: ({isVatRegistered}) =>
      isVatRegistered ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr',
    gap: theme.spacing(4, 2),
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  priceColumn: {
    justifySelf: 'center',
    alignSelf: 'flex-start',
    height: 48
  },
  promotionColumn: {
    justifySelf: 'flex-end'
  }
}))

interface ICreateEventFormProps {
  className?: string
  defaultValues: Partial<ICreateEventForm>
  divisions: DivisionPropertiesFragment[]
  auditoriumLayouts: CreateEventAuditoriumLayout[]
  onSubmit: (form: ICreateEventForm) => void
  ticketDefaultVatRate: number
  clientVatRegistered: ClientVatRegistered
  countryVatRates: number[]
}

export const CreateEventForm: React.FC<ICreateEventFormProps> = ({
  className,
  defaultValues,
  divisions,
  auditoriumLayouts,
  onSubmit,
  ticketDefaultVatRate,
  clientVatRegistered,
  countryVatRates
}: ICreateEventFormProps) => {
  const isVatRegistered = clientVatRegistered !== ClientVatRegistered.None
  const classes = useStyles({isVatRegistered})
  const {
    register,
    setValue,
    errors,
    watch,
    triggerValidation,
    unregister,
    handleSubmit,
    control,
    clearError,
    setError
  } = useForm<ICreateEventForm>({
    defaultValues
  })
  const {t} = useTranslation()
  const divisionItems = getChipItems(divisions)
  const auditoriumLayoutItems = getChipItems(auditoriumLayouts)
  const auditoriumLayoutId = watch(CreateEventFormField.AUDITORIUM_LAYOUT_ID)
  const selectedDivisionId = watch(CreateEventFormField.DIVISION_ID)
  useEffect(() => {
    if (selectedDivisionId) {
      const serviceTime =
        divisions.find(({id}) => id === selectedDivisionId)?.serviceTime ?? 0
      setValue(CreateEventFormField.SERVICE_TIME, serviceTime)
    }
  }, [divisions, selectedDivisionId, setValue])
  useEffect(() => {
    if (!defaultValues[CreateEventFormField.AUDITORIUM_LAYOUT_ID]) {
      setValue(CreateEventFormField.AUDITORIUM_LAYOUT_PRICING_ID, undefined)
    }
  }, [auditoriumLayoutId, defaultValues, setValue])
  useEffect(() => {
    if (defaultValues?.divisionId) {
      setValue(CreateEventFormField.DIVISION_ID, defaultValues.divisionId)
    } else {
      if (divisions.length === 1) {
        setValue(CreateEventFormField.DIVISION_ID, divisions[0].id)
      }
    }
  }, [defaultValues.divisionId, divisions, setValue])
  const auditoriumLayoutPricingId = watch(
    CreateEventFormField.AUDITORIUM_LAYOUT_PRICING_ID
  )
  const auditoriumLayoutPricings = getAuditoriumLayoutPricings({
    auditoriumLayouts,
    auditoriumLayoutId
  })
  const auditoriumLayoutPricingItems = getChipItems(
    auditoriumLayoutPricings || []
  )

  const auditoriumTicketTypes = auditoriumLayoutPricings?.find(
    (alP) => alP.id === auditoriumLayoutPricingId
  )?.ticketTypes

  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  const isTicketPriceValid = useIsTicketPriceValid()
  return (
    <form
      className={className}
      id={CREATE_EVENT_FORM_ID}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Card className={classes.card} variant="outlined">
        <input
          type="hidden"
          ref={register()}
          name={CreateEventFormField.SERVICE_TIME}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICreateEventForm>
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              triggerValidation={triggerValidation}
              name={CreateEventFormField.DROPPED_STARTS_AT}
              key={CreateEventFormField.DROPPED_STARTS_AT}
              label={t('Event start date')}
              disabled
              fullWidth
            />,
            <FormTimeInput<ICreateEventForm>
              validationOptions={{required: true}}
              timePickerProps={{
                label: t('Event start time')
              }}
              setValue={setValue}
              errors={errors}
              register={register}
              unregister={unregister}
              watch={watch}
              name={CreateEventFormField.STARTS_AT}
              key={CreateEventFormField.STARTS_AT}
              fullWidth
              clearError={clearError}
              control={control}
              setError={setError}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICreateEventForm>
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
              validationOptions={{required: true}}
              required
              triggerValidation={triggerValidation}
              name={CreateEventFormField.NAME}
              key={CreateEventFormField.NAME}
              label={t('Event name')}
              fullWidth
            />
          ]}
        />
      </Card>
      <Card className={classes.divisionCard} variant="outlined">
        <InputRow
          nodes={[
            <FormChipsGroupSelect<ICreateEventForm>
              items={divisionItems}
              errors={errors}
              register={register}
              unregister={unregister}
              isRequired
              label={t('Division')}
              validationOptions={{
                required: t<string>('Division is required!')
              }}
              setValue={setValue}
              name={CreateEventFormField.DIVISION_ID}
              key={CreateEventFormField.DIVISION_ID}
              watch={watch}
            />
          ]}
        />
      </Card>

      <Card className={classes.auditoriumCard} variant="outlined">
        <InputRow
          nodes={[
            <FormChipsGroupSelect<ICreateEventForm>
              items={auditoriumLayoutItems}
              errors={errors}
              register={register}
              unregister={unregister}
              isRequired
              label={t('Auditorium layout')}
              validationOptions={{
                required: t<string>('Auditorium layout is required!')
              }}
              setValue={setValue}
              name={CreateEventFormField.AUDITORIUM_LAYOUT_ID}
              key={CreateEventFormField.AUDITORIUM_LAYOUT_ID}
              watch={watch}
            />
          ]}
        />

        {auditoriumLayoutPricingItems.length > 0 && (
          <>
            <InputRow
              nodes={[
                <FormChipsGroupSelect<ICreateEventForm>
                  items={auditoriumLayoutPricingItems}
                  errors={errors}
                  register={register}
                  unregister={unregister}
                  isRequired
                  label={t('Layout pricing')}
                  validationOptions={{
                    required: t<string>('Layout pricing is required!')
                  }}
                  setValue={setValue}
                  name={CreateEventFormField.AUDITORIUM_LAYOUT_PRICING_ID}
                  key={CreateEventFormField.AUDITORIUM_LAYOUT_PRICING_ID}
                  watch={watch}
                />
              ]}
            />
            {auditoriumTicketTypes && (
              <div>
                <div className={classes.labelsGrid}>
                  <Typography variant="caption" color="textSecondary">
                    {t('Ticket type')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t('Price')}
                  </Typography>
                  {isVatRegistered && (
                    <Typography variant="caption" color="textSecondary">
                      {t('VAT rate')}
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    className={classes.promotionColumnLabel}
                  >
                    {t('Discounts')}
                  </Typography>
                </div>
                <div className={classes.ticketTypesGrid}>
                  {auditoriumTicketTypes.map((ticketType) =>
                    [
                      <input
                        type="hidden"
                        ref={register()}
                        name={getTicketTypeFieldName(
                          ticketType.id,
                          TicketTypeFormField.TICKET_TYPE_ID
                        )}
                        key={getTicketTypeFieldName(
                          ticketType.id,
                          TicketTypeFormField.TICKET_TYPE_ID
                        )}
                        defaultValue={ticketType.id}
                      />,
                      <div key={`name-${ticketType.id}`}>
                        <Typography variant="body2">
                          {ticketType.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {ticketType.description}
                        </Typography>
                      </div>,
                      <div
                        className={classes.priceColumn}
                        key={getTicketTypeFieldName(
                          ticketType.id,
                          TicketTypeFormField.PRICE
                        )}
                      >
                        <UncontrolledFormTextInput<ICreateEventForm>
                          InputProps={currencySignInputProps}
                          register={register}
                          setValue={setValue}
                          watch={watch}
                          errors={errors}
                          validationOptions={{
                            required: true,
                            validate: isTicketPriceValid
                          }}
                          triggerValidation={triggerValidation}
                          name={getTicketTypeFieldName(
                            ticketType.id,
                            TicketTypeFormField.PRICE
                          )}
                          defaultValue={ticketType.price}
                        />
                      </div>,
                      ...(isVatRegistered
                        ? [
                            <UncontrolledFormSelect<ICreateEventForm>
                              defaultValue={ticketDefaultVatRate}
                              key={getTicketTypeFieldName(
                                ticketType.id,
                                TicketTypeFormField.VAT_RATE
                              )}
                              name={getTicketTypeFieldName(
                                ticketType.id,
                                TicketTypeFormField.VAT_RATE
                              )}
                              selectOptions={countryVatRates.reduce(
                                (acc, vatRate) => ({
                                  ...acc,
                                  [vatRate]: t('{{vatRate}} %', {vatRate})
                                }),
                                {}
                              )}
                              setValue={setValue}
                              errors={errors}
                              register={register}
                              watch={watch}
                              validationOptions={{
                                required: true
                              }}
                              fullWidth
                            />
                          ]
                        : []),
                      <FormSwitchInput<ICreateEventForm>
                        watch={watch}
                        control={control}
                        name={getTicketTypeFieldName(
                          ticketType.id,
                          TicketTypeFormField.PROMOTIONS
                        )}
                        key={getTicketTypeFieldName(
                          ticketType.id,
                          TicketTypeFormField.PROMOTIONS
                        )}
                        className={classes.promotionColumn}
                        defaultValue={ticketType.areDiscountsEnabled}
                      />
                    ].filter(Boolean)
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </Card>
    </form>
  )
}
