import {identity, pickBy} from 'lodash'
import {PaymentInput} from '../../../../../__generated__/schema'
import {replaceCommaWithDot} from '../../tours/tourDetail/admissionRateDetail/utils'
import {ICashRegisterOperationForm} from './types'

export const transformFormDataToPaymentInputs = (
  formData: ICashRegisterOperationForm
): PaymentInput[] =>
  Object.entries(pickBy(formData.amount, identity)).map(
    ([paymentMethodId, amount]) => ({
      amount: parseFloat(replaceCommaWithDot(amount)),
      paymentMethodId: parseFloat(paymentMethodId)
    })
  )
