import {Box, Typography} from '@mui/material'
import React from 'react'
import {IQuantityControlsProps, QuantityControls} from '../QuantityControls'

interface ICashDeskListItemProps {
  icon?: React.ReactElement
  title: string
  price: string
  description?: React.ReactNode
  note?: string
  QuantityControlsProps?: IQuantityControlsProps
}

export const CashDeskListItem: React.FC<ICashDeskListItemProps> = ({
  icon,
  title,
  price,
  description,
  note,
  QuantityControlsProps
}: ICashDeskListItemProps) => (
  <Box
    sx={{
      minWidth: 328,
      minHeight: 64,
      backgroundColor: 'background.paper',
      borderRadius: 1,
      border: (theme) => `solid 1px ${theme.palette.divider}`
    }}
  >
    <Box sx={{display: 'flex', gap: 1, flex: 1, alignItems: 'stretch', pr: 1}}>
      {icon}
      <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, py: 1.25}}>
        <Typography variant="subtitle2">{title}</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 1,
            flexWrap: 'wrap'
          }}
        >
          <Typography variant="caption">{price}</Typography>
          {description && (
            <>
              <Typography variant="caption" color="textSecondary">
                {' • '}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {description}
              </Typography>
            </>
          )}
          {note && (
            <>
              <Typography variant="caption" color="textSecondary">
                {' • '}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {note}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {QuantityControlsProps && (
        <QuantityControls
          sx={{alignSelf: 'center'}}
          {...QuantityControlsProps}
        />
      )}
    </Box>
  </Box>
)
