import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../theme'

interface ICircleProps {
  value: number
  backgroundStroke?: string
  activeStroke?: string
}
const Circle: React.FC<ICircleProps> = ({
  value,
  backgroundStroke = '#EEEEEE',
  activeStroke = 'currentColor'
}: ICircleProps) => (
  <svg
    viewBox="22 22 44 44"
    style={{
      transform: 'rotate(-90deg)'
    }}
  >
    <circle
      cx="44"
      cy="44"
      r="21.5"
      fill="none"
      strokeWidth="1"
      stroke={backgroundStroke}
      style={{strokeDasharray: 135.088, strokeDashoffset: 0}}
    />
    {Boolean(value) && (
      <circle
        cx="44"
        cy="44"
        r="21.5"
        fill="none"
        strokeWidth="1"
        stroke={activeStroke}
        style={{
          strokeDasharray: 135.088,
          strokeDashoffset: ((100 - value) * 135.088) / 100
        }}
      />
    )}
  </svg>
)

const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: 'relative'
  },
  children: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface ICircularProgressWithChildrenProps {
  className?: string
  children?: React.ReactElement
  value: number
  backgroundStroke?: string
  activeStroke?: string
}

export const CircularProgressWithChildren: React.FC<ICircularProgressWithChildrenProps> =
  ({
    className,
    value,
    children,
    backgroundStroke,
    activeStroke
  }: ICircularProgressWithChildrenProps) => {
    const classes = useStyles()
    return (
      <div className={cn(classes.root, className)}>
        <Circle
          value={value}
          backgroundStroke={backgroundStroke}
          activeStroke={activeStroke}
        />
        {children && <div className={classes.children}>{children}</div>}
      </div>
    )
  }
