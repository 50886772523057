import AcUnitIcon from '@mui/icons-material/AcUnit'
import AttractionsIcon from '@mui/icons-material/Attractions'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import BakeryDiningIcon from '@mui/icons-material/BakeryDining'
import BlenderIcon from '@mui/icons-material/Blender'
import BrushIcon from '@mui/icons-material/Brush'
import CakeIcon from '@mui/icons-material/Cake'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import CasinoIcon from '@mui/icons-material/Casino'
import CheckroomIcon from '@mui/icons-material/Checkroom'
import CoffeeIcon from '@mui/icons-material/Coffee'
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CookieIcon from '@mui/icons-material/Cookie'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat'
import DiamondIcon from '@mui/icons-material/Diamond'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import EggIcon from '@mui/icons-material/Egg'
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage'
import EmojiNatureIcon from '@mui/icons-material/EmojiNature'
import ExtensionIcon from '@mui/icons-material/Extension'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import IcecreamIcon from '@mui/icons-material/Icecream'
import LiquorIcon from '@mui/icons-material/Liquor'
import LocalBarIcon from '@mui/icons-material/LocalBar'
import LocalCafeIcon from '@mui/icons-material/LocalCafe'
import LocalDiningIcon from '@mui/icons-material/LocalDining'
import LocalDrinkIcon from '@mui/icons-material/LocalDrink'
import LocalFloristIcon from '@mui/icons-material/LocalFlorist'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import LocalParkingIcon from '@mui/icons-material/LocalParking'
import LocalPizzaIcon from '@mui/icons-material/LocalPizza'
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice'
import LunchDiningIcon from '@mui/icons-material/LunchDining'
import MapIcon from '@mui/icons-material/Map'
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill'
import PaletteIcon from '@mui/icons-material/Palette'
import PedalBikeIcon from '@mui/icons-material/PedalBike'
import PrintIcon from '@mui/icons-material/Print'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import ScannerIcon from '@mui/icons-material/Scanner'
import SellIcon from '@mui/icons-material/Sell'
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen'
import SportsBarIcon from '@mui/icons-material/SportsBar'
import TollIcon from '@mui/icons-material/Toll'
import WaterDropIcon from '@mui/icons-material/WaterDrop'
import WineBarIcon from '@mui/icons-material/WineBar'
import {Box, SvgIconProps} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export enum ProductGroupIcon {
  Restaurant = 'restaurant',
  LocalDining = 'localDining',
  Cake = 'cake',
  Cookie = 'cookie',
  Icecream = 'icecream',
  Blender = 'blender',
  Egg = 'egg',
  BakeryDining = 'bakeryDining',
  LocalPizza = 'localPizza',
  OutdoorGrill = 'outdoorGrill',
  SoupKitchen = 'soupKitchen',
  LunchDining = 'lunchDining',
  Fastfood = 'fastfood',
  DeliveryDining = 'deliveryDining',
  DirectionsCar = 'directionsCar',
  PedalBike = 'pedalBike',
  Print = 'print',
  Scanner = 'scanner',
  CameraAlt = 'cameraAlt',
  AutoStories = 'autoStories',
  Map = 'map',
  Toll = 'toll',
  LocalPostOffice = 'localPostOffice',
  Brush = 'brush',
  Palette = 'palette',
  Diamond = 'diamond',
  Extension = 'extension',
  Casino = 'casino',
  Attractions = 'attractions',
  Checkroom = 'checkroom',
  LocalMall = 'localMall',
  EmojiNature = 'emojiNature',
  LocalFlorist = 'localFlorist',
  ConfirmationNumber = 'ConfirmationNumber',
  CardGiftcard = 'CardGiftcard',
  Sell = 'Sell',
  Coffee = 'Coffee',
  EmojiFoodBeverage = 'EmojiFoodBeverage',
  LocalCafe = 'LocalCafe',
  CoffeeMaker = 'CoffeeMaker',
  AcUnit = 'AcUnit',
  DeviceThermostat = 'DeviceThermostat',
  LocalDrink = 'LocalDrink',
  WaterDrop = 'WaterDrop',
  LocalBar = 'LocalBar',
  Liquor = 'Liquor',
  WineBar = 'WineBar',
  SportsBar = 'SportsBar',
  LocalParking = 'LocalParking'
}

export const useTranslateProductGroupIcon = () => {
  const {t} = useTranslation()
  return (icon: ProductGroupIcon) => {
    switch (icon) {
      case ProductGroupIcon.Restaurant:
        return t<string>('ProductGroupIcon->Restaurant')
      case ProductGroupIcon.LocalDining:
        return t<string>('ProductGroupIcon->LocalDining')
      case ProductGroupIcon.Cake:
        return t<string>('ProductGroupIcon->Cake')
      case ProductGroupIcon.Cookie:
        return t<string>('ProductGroupIcon->Cookie')
      case ProductGroupIcon.Icecream:
        return t<string>('ProductGroupIcon->Icecream')
      case ProductGroupIcon.Blender:
        return t<string>('ProductGroupIcon->Blender')
      case ProductGroupIcon.Egg:
        return t<string>('ProductGroupIcon->Egg')
      case ProductGroupIcon.BakeryDining:
        return t<string>('ProductGroupIcon->BakeryDining')
      case ProductGroupIcon.LocalPizza:
        return t<string>('ProductGroupIcon->LocalPizza')
      case ProductGroupIcon.OutdoorGrill:
        return t<string>('ProductGroupIcon->OutdoorGrill')
      case ProductGroupIcon.SoupKitchen:
        return t<string>('ProductGroupIcon->SoupKitchen')
      case ProductGroupIcon.LunchDining:
        return t<string>('ProductGroupIcon->LunchDining')
      case ProductGroupIcon.Fastfood:
        return t<string>('ProductGroupIcon->Fastfood')
      case ProductGroupIcon.DeliveryDining:
        return t<string>('ProductGroupIcon->DeliveryDining')
      case ProductGroupIcon.DirectionsCar:
        return t<string>('ProductGroupIcon->DirectionsCar')
      case ProductGroupIcon.PedalBike:
        return t<string>('ProductGroupIcon->PedalBike')
      case ProductGroupIcon.Print:
        return t<string>('ProductGroupIcon->Print')
      case ProductGroupIcon.Scanner:
        return t<string>('ProductGroupIcon->Scanner')
      case ProductGroupIcon.CameraAlt:
        return t<string>('ProductGroupIcon->CameraAlt')
      case ProductGroupIcon.AutoStories:
        return t<string>('ProductGroupIcon->AutoStories')
      case ProductGroupIcon.Map:
        return t<string>('ProductGroupIcon->Map')
      case ProductGroupIcon.Toll:
        return t<string>('ProductGroupIcon->Toll')
      case ProductGroupIcon.LocalPostOffice:
        return t<string>('ProductGroupIcon->LocalPostOffice')
      case ProductGroupIcon.Brush:
        return t<string>('ProductGroupIcon->Brush')
      case ProductGroupIcon.Palette:
        return t<string>('ProductGroupIcon->Palette')
      case ProductGroupIcon.Diamond:
        return t<string>('ProductGroupIcon->Diamond')
      case ProductGroupIcon.Extension:
        return t<string>('ProductGroupIcon->Extension')
      case ProductGroupIcon.Casino:
        return t<string>('ProductGroupIcon->Casino')
      case ProductGroupIcon.Attractions:
        return t<string>('ProductGroupIcon->Attractions')
      case ProductGroupIcon.Checkroom:
        return t<string>('ProductGroupIcon->Checkroom')
      case ProductGroupIcon.LocalMall:
        return t<string>('ProductGroupIcon->LocalMall')
      case ProductGroupIcon.EmojiNature:
        return t<string>('ProductGroupIcon->EmojiNature')
      case ProductGroupIcon.LocalFlorist:
        return t<string>('ProductGroupIcon->LocalFlorist')
      case ProductGroupIcon.ConfirmationNumber:
        return t<string>('ProductGroupIcon->ConfirmationNumber')
      case ProductGroupIcon.CardGiftcard:
        return t<string>('ProductGroupIcon->CardGiftcard')
      case ProductGroupIcon.Sell:
        return t<string>('ProductGroupIcon->Sell')
      case ProductGroupIcon.Coffee:
        return t<string>('ProductGroupIcon->Coffee')
      case ProductGroupIcon.EmojiFoodBeverage:
        return t<string>('ProductGroupIcon->EmojiFoodBeverage')
      case ProductGroupIcon.LocalCafe:
        return t<string>('ProductGroupIcon->LocalCafe')
      case ProductGroupIcon.CoffeeMaker:
        return t<string>('ProductGroupIcon->CoffeeMaker')
      case ProductGroupIcon.AcUnit:
        return t<string>('ProductGroupIcon->AcUnit')
      case ProductGroupIcon.DeviceThermostat:
        return t<string>('ProductGroupIcon->DeviceThermostat')
      case ProductGroupIcon.LocalDrink:
        return t<string>('ProductGroupIcon->LocalDrink')
      case ProductGroupIcon.WaterDrop:
        return t<string>('ProductGroupIcon->WaterDrop')
      case ProductGroupIcon.LocalBar:
        return t<string>('ProductGroupIcon->LocalBar')
      case ProductGroupIcon.Liquor:
        return t<string>('ProductGroupIcon->Liquor')
      case ProductGroupIcon.WineBar:
        return t<string>('ProductGroupIcon->WineBar')
      case ProductGroupIcon.SportsBar:
        return t<string>('ProductGroupIcon->SportsBar')
      case ProductGroupIcon.LocalParking:
        return t<string>('ProductGroupIcon->LocalParking')
      default:
        return `Unknown icon ${icon}`
    }
  }
}

export const getProductGroupIcon = (icon: ProductGroupIcon) => {
  switch (icon) {
    case ProductGroupIcon.Restaurant:
      return RestaurantIcon
    case ProductGroupIcon.LocalDining:
      return LocalDiningIcon
    case ProductGroupIcon.Cake:
      return CakeIcon
    case ProductGroupIcon.Cookie:
      return CookieIcon
    case ProductGroupIcon.Icecream:
      return IcecreamIcon
    case ProductGroupIcon.Blender:
      return BlenderIcon
    case ProductGroupIcon.Egg:
      return EggIcon
    case ProductGroupIcon.BakeryDining:
      return BakeryDiningIcon
    case ProductGroupIcon.LocalPizza:
      return LocalPizzaIcon
    case ProductGroupIcon.OutdoorGrill:
      return OutdoorGrillIcon
    case ProductGroupIcon.SoupKitchen:
      return SoupKitchenIcon
    case ProductGroupIcon.LunchDining:
      return LunchDiningIcon
    case ProductGroupIcon.Fastfood:
      return FastfoodIcon
    case ProductGroupIcon.DeliveryDining:
      return DeliveryDiningIcon
    case ProductGroupIcon.DirectionsCar:
      return DirectionsCarIcon
    case ProductGroupIcon.PedalBike:
      return PedalBikeIcon
    case ProductGroupIcon.Print:
      return PrintIcon
    case ProductGroupIcon.Scanner:
      return ScannerIcon
    case ProductGroupIcon.CameraAlt:
      return CameraAltIcon
    case ProductGroupIcon.AutoStories:
      return AutoStoriesIcon
    case ProductGroupIcon.Map:
      return MapIcon
    case ProductGroupIcon.Toll:
      return TollIcon
    case ProductGroupIcon.LocalPostOffice:
      return LocalPostOfficeIcon
    case ProductGroupIcon.Brush:
      return BrushIcon
    case ProductGroupIcon.Palette:
      return PaletteIcon
    case ProductGroupIcon.Diamond:
      return DiamondIcon
    case ProductGroupIcon.Extension:
      return ExtensionIcon
    case ProductGroupIcon.Casino:
      return CasinoIcon
    case ProductGroupIcon.Attractions:
      return AttractionsIcon
    case ProductGroupIcon.Checkroom:
      return CheckroomIcon
    case ProductGroupIcon.LocalMall:
      return LocalMallIcon
    case ProductGroupIcon.EmojiNature:
      return EmojiNatureIcon
    case ProductGroupIcon.LocalFlorist:
      return LocalFloristIcon
    case ProductGroupIcon.ConfirmationNumber:
      return ConfirmationNumberIcon
    case ProductGroupIcon.CardGiftcard:
      return CardGiftcardIcon
    case ProductGroupIcon.Sell:
      return SellIcon
    case ProductGroupIcon.Coffee:
      return CoffeeIcon
    case ProductGroupIcon.EmojiFoodBeverage:
      return EmojiFoodBeverageIcon
    case ProductGroupIcon.LocalCafe:
      return LocalCafeIcon
    case ProductGroupIcon.CoffeeMaker:
      return CoffeeMakerIcon
    case ProductGroupIcon.AcUnit:
      return AcUnitIcon
    case ProductGroupIcon.DeviceThermostat:
      return DeviceThermostatIcon
    case ProductGroupIcon.LocalDrink:
      return LocalDrinkIcon
    case ProductGroupIcon.WaterDrop:
      return WaterDropIcon
    case ProductGroupIcon.LocalBar:
      return LocalBarIcon
    case ProductGroupIcon.Liquor:
      return LiquorIcon
    case ProductGroupIcon.WineBar:
      return WineBarIcon
    case ProductGroupIcon.SportsBar:
      return SportsBarIcon
    case ProductGroupIcon.LocalParking:
      return LocalParkingIcon
    default:
      return QuestionMarkIcon
  }
}

interface IProductGroupSvgIconProps extends SvgIconProps {
  icon: ProductGroupIcon
}

export const ProductGroupSvgIcon: React.FC<IProductGroupSvgIconProps> = ({
  icon,
  ...props
}: IProductGroupSvgIconProps) => {
  const IconComp = getProductGroupIcon(icon)
  return <IconComp {...props} />
}

export const useProductGroupIconSelectOptions = () => {
  const translateProductGroupIcon = useTranslateProductGroupIcon()
  return Object.values(ProductGroupIcon).reduce(
    (acc, icon) => ({
      ...acc,
      [icon]: (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1.5}}>
          <ProductGroupSvgIcon icon={icon} />
          {translateProductGroupIcon(icon)}
        </Box>
      )
    }),
    {}
  )
}
