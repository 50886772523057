import AddIcon from '@mui/icons-material/Add'
import {Box, Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  ApplicationRulePropertiesFragment,
  ApplicationRuleType,
  ConditionPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {InputBlockWithoutSpacings} from '../../../../common'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {useDeleteApplicationRule} from '../graphql'
import {ApplicationRule} from './ApplicationRule'
import {ApplicationRuleDrawer, IRuleDrawerState} from './ApplicationRuleDrawer'

const useStyles = makeStyles<Theme>((theme) => ({
  applicationRule: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    borderBottom: `1px ${theme.palette.divider} solid`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  heading: {
    padding: theme.spacing(2, 3)
  },
  headerClassName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

interface IApplicationRulesBlockProps {
  applicationRules: ApplicationRulePropertiesFragment[]
  label: string
  blockId: string
  discountId: number
}

const initialRuleDrawerState: IRuleDrawerState = {
  isOpen: false
}

export const ApplicationRulesBlock: React.FC<IApplicationRulesBlockProps> = ({
  label,
  blockId,
  applicationRules,
  discountId
}: IApplicationRulesBlockProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    anchorEl: createRuleButtonAnchorEl,
    openMenu: openCreateRuleMenu,
    closeMenu: closeCreateRuleMenu
  } = useMenu()
  const classes = useStyles()
  const [ruleDrawerState, setRuleDrawerState] = useState<IRuleDrawerState>(
    initialRuleDrawerState
  )
  const closeRuleDrawer = useCallback(() => {
    setRuleDrawerState({isOpen: false, type: undefined})
  }, [])
  const openApplicationRuleDetail = useCallback(
    (applicationRule: ApplicationRulePropertiesFragment) => {
      setRuleDrawerState({
        isOpen: true,
        applicationRule,
        type: applicationRule.type
      })
    },
    []
  )

  const updateCondition = useCallback(
    (updatedCondition: ConditionPropertiesFragment) => {
      setRuleDrawerState((state) => ({
        ...state,
        applicationRule: state.applicationRule
          ? {
              ...state.applicationRule,
              conditions: state.applicationRule!.conditions.reduce(
                (acc: ConditionPropertiesFragment[], condition) => [
                  ...acc,
                  condition.id === updatedCondition.id
                    ? updatedCondition
                    : condition
                ],
                []
              )
            }
          : undefined
      }))
    },
    []
  )

  const deleteApplicationRule = useDeleteApplicationRule(discountId)
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()

  const handleDeleteApplicationRuleConfirm = useCallback(
    async (id: number) => {
      setShowBackdrop(true)
      try {
        await deleteApplicationRule(id)
        addInfoNotification(t('Rule deleted.'))
      } catch (e) {
        defaultErrorHandler(e, t('Deletion of rule failed.'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      deleteApplicationRule,
      setShowBackdrop,
      t
    ]
  )
  const handleCreateRuleForEventButtonClick = useCallback(() => {
    setRuleDrawerState({isOpen: true, type: ApplicationRuleType.Event})
    closeCreateRuleMenu()
  }, [closeCreateRuleMenu])
  const handleCreateRuleForTourButtonClick = useCallback(() => {
    setRuleDrawerState({isOpen: true, type: ApplicationRuleType.Tour})
    closeCreateRuleMenu()
  }, [closeCreateRuleMenu])
  return (
    <>
      <InputBlockWithoutSpacings
        headerClassName={classes.headerClassName}
        header={<span>{label}</span>}
        headerRightAction={
          <>
            {P([PermissionCode.CreateApplicationRule]) &&
              !P([PermissionCode.FeatureFlagEntradio_3306]) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setRuleDrawerState({
                      isOpen: true,
                      type: ApplicationRuleType.Event
                    })
                  }
                >
                  <AddIcon fontSize="small" /> &nbsp; {t('Create rule')}
                </Button>
              )}
            {P([
              PermissionCode.CreateApplicationRule,
              PermissionCode.FeatureFlagEntradio_3306
            ]) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={openCreateRuleMenu}
              >
                {t('Create rule')}
              </Button>
            )}
          </>
        }
        blockId={blockId}
      >
        <div className={classes.heading}>
          <Typography variant="subtitle2">
            {t('Filter discount availability with custom rules')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t(
              'Create rules with multiple conditions for discount application. If item meets the conditions then discount is applicable.'
            )}
          </Typography>
        </div>
        {applicationRules.length > 0 ? (
          applicationRules.map((applicationRule, index) => (
            <ApplicationRule
              applicationRule={applicationRule}
              key={applicationRule.id}
              index={index + 1}
              className={classes.applicationRule}
              onEditButtonClick={() => {
                setRuleDrawerState({
                  isOpen: true,
                  applicationRule,
                  type: applicationRule.type
                })
              }}
              onDeleteApplicationRuleConfirm={
                handleDeleteApplicationRuleConfirm
              }
            />
          ))
        ) : (
          <Box paddingX={3} paddingY={3}>
            <Typography variant="body2">
              {t(
                'No rules created. Discount is available for all events in specified divisions'
              )}
            </Typography>
          </Box>
        )}
        <ApplicationRuleDrawer
          closeDrawer={closeRuleDrawer}
          openApplicationRuleDetail={openApplicationRuleDetail}
          onConditionUpdate={updateCondition}
          {...ruleDrawerState}
        />
      </InputBlockWithoutSpacings>
      <Menu
        anchorEl={createRuleButtonAnchorEl}
        onClose={closeCreateRuleMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          label={t('For event')}
          onClick={handleCreateRuleForEventButtonClick}
        />
        <MenuItem
          label={t('For tour')}
          onClick={handleCreateRuleForTourButtonClick}
        />
      </Menu>
    </>
  )
}
