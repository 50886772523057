import {makeStyles} from '@mui/styles'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {CustomerGroupFormField, ICustomerGroupForm} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface ICustomerGroupFormProps {
  formId: string
  onSubmit: (data: ICustomerGroupForm) => void
  defaultValues?: Partial<ICustomerGroupForm>
}

export const CustomerGroupForm: React.FC<ICustomerGroupFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: ICustomerGroupFormProps) => {
  const {t} = useTranslation()
  const {handleSubmit, errors, setValue, watch, register, triggerValidation} =
    useForm<ICustomerGroupForm>({defaultValues})
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.form}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ICustomerGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Name')}
            name={CustomerGroupFormField.Name}
            key={CustomerGroupFormField.Name}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ICustomerGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Description')}
            name={CustomerGroupFormField.Description}
            key={CustomerGroupFormField.Description}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            fullWidth
          />
        ]}
      />
    </form>
  )
}
