import {useTranslation} from 'react-i18next'
import {
  ClientShowImage,
  LibraryShowImage,
  Maybe,
  ShowImageThumbnail
} from '../../../../__generated__/schema'
import BackdropIcon from '../../../../assets/shows/backdrop.svg'
import BannerIcon from '../../../../assets/shows/banner.svg'
import CoverIcon from '../../../../assets/shows/cover.svg'
import CrewIcon from '../../../../assets/shows/crew.svg'
import PhotoIcon from '../../../../assets/shows/photo.svg'

import PosterIcon from '../../../../assets/shows/poster.svg'
import VideoIcon from '../../../../assets/shows/video.svg'

export const useGetMediaIcons = () => {
  const {t} = useTranslation()

  return {
    poster: {
      icon: PosterIcon,
      alt: t('Poster Icon')
    },
    photo: {
      icon: PhotoIcon,
      alt: t('Photo Icon')
    },
    video: {
      icon: VideoIcon,
      alt: t('Video Icon')
    },
    cover: {
      icon: CoverIcon,
      alt: t('Cover Icon')
    },
    crew: {
      icon: CrewIcon,
      alt: t('Crew Icon')
    },
    banner: {
      icon: BannerIcon,
      alt: t('Banner Icon')
    },
    backdrop: {
      icon: BackdropIcon,
      alt: t('Backdrop Icon')
    }
  }
}

type Images = Array<
  (
    | Pick<ClientShowImage, 'id' | 'height' | 'width' | 'url' | '__typename'>
    | Pick<LibraryShowImage, 'id' | 'height' | 'width' | 'url' | '__typename'>
  ) & {
    thumbnails?: Maybe<
      Array<Pick<ShowImageThumbnail, 'id' | 'url' | 'width' | 'height'>>
    >
  }
>

export const getGalleryPhotos = (images: Images) =>
  images.map(({thumbnails, url: src, width, height, id, __typename}) => ({
    ...(thumbnails && thumbnails?.length > 0
      ? {
          src: thumbnails[0].url,
          width: thumbnails[0].width || 10,
          height: thumbnails[0].height || 10
        }
      : {
          src,
          width: width || 10,
          height: height || 10
        }),
    key: id.toString(),
    alt: id.toString(),
    id,
    __typename
  }))
