import {makeStyles} from '@mui/styles'
import Konva from 'konva'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {Stage} from 'react-konva'
import {Provider, useStore} from 'react-redux'

import {Theme} from '../../theme'
import {RectangleSelection} from '../components/RectangleSelection'
import {SelectedObjects} from '../components/SelectedObjects'
import {TemporaryIcon} from '../components/TemporaryIcon'
import {TemporarySeats} from '../components/TemporarySeats'
import {TemporaryShape} from '../components/TemporaryShape'
import {TemporaryZone} from '../components/TemporaryZone'
import {UnselectedObjects} from '../components/UnselectedObjects'
import {ROTATION, SEAT_LABEL, TEXT_SIZE, TEXT_TYPE, TEXT_VALUE} from '../config'
import {cursorMap} from '../cursorMap'
import {useKeyboardListener} from '../hooks/keyboard'
import {useMouseListener} from '../hooks/mouse'
import {useSelector} from '../redux'
import {useCanvasActions} from '../redux/canvas/actions'
import {canvasSelector} from '../redux/canvas/selectors'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {isCtrlActiveSelector} from '../redux/keyboardKeys/selectors'
import {useSeatsActions} from '../redux/objects/seats/actions'
import {useTextActions} from '../redux/objects/text/actions'
import {useRefsActions} from '../redux/refs/actions'
import {DrawTool} from '../types'
import {
  getChangeCursor,
  getMouseCoordsOnCanvas,
  getMouseCoordsOnScreen
} from '../utils/common'

const useStyles = makeStyles<Theme>(() => ({
  stage: {
    outline: 'none'
  }
}))

export const EditorCanvas: React.FC = () => {
  const classes = useStyles()
  const [stageRef, setStageRef] = useState<Konva.Stage | null>(null)
  const [fittedOnInit, setFittedOnInit] = useState<boolean>(false)
  const {setStageRef: setStageRefToStore} = useRefsActions()
  const {changeOrigin, fitToScreen, zoomIn, zoomOut} = useCanvasActions()

  useEffect(() => {
    setStageRefToStore(stageRef)
  }, [setStageRefToStore, stageRef])

  useLayoutEffect(() => {
    if (stageRef && !fittedOnInit) {
      setFittedOnInit(true)
      fitToScreen()
    }
  }, [fitToScreen, fittedOnInit, stageRef])

  useKeyboardListener()
  useMouseListener()

  const konvaStore = useStore()
  const {
    dimensions: canvasDimensions,
    origin: canvasOrigin,
    scale: canvasScale
  } = useSelector(canvasSelector)
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)
  const ctrlActive = useSelector(isCtrlActiveSelector)
  const {setSelectMode} = useEditorModeActions()

  const {addSeat} = useSeatsActions()
  const {addText} = useTextActions()

  const onClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (selectedMode === EditorMode.DRAW) {
        const drawConfig = modeConfigs[EditorMode.DRAW]
        const coords = getMouseCoordsOnCanvas(e)

        if (coords) {
          if (drawConfig.type === DrawTool.SEAT) {
            addSeat({
              coords,
              label: SEAT_LABEL,
              rotation: ROTATION,
              seatShape: drawConfig.shape,
              row: '',
              section: '',
              floor: ''
            })
          } else if (drawConfig.type === DrawTool.TEXT) {
            addText({
              coords,
              rotation: ROTATION,
              label: TEXT_VALUE,
              fontSize: TEXT_SIZE,
              fontType: TEXT_TYPE
            })
          }
        }
      }
      if (selectedMode === EditorMode.TRANSFORM) {
        setSelectMode()
      }
    },
    [addSeat, addText, modeConfigs, selectedMode, setSelectMode]
  )

  const onDragEnd = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const stage = e.target.getStage()
      if (!stage) return

      const stageX = stage.attrs.x || 0
      const stageY = stage.attrs.y || 0

      changeOrigin({x: stageX, y: stageY})
    },
    [changeOrigin]
  )

  const stageStyles = useMemo(
    () => ({
      cursor: cursorMap[selectedMode].default
    }),
    [selectedMode]
  )

  const unselectedLayerRef: React.RefObject<Konva.Layer> = useRef(null)
  const selectedLayerRef: React.RefObject<Konva.Layer> = useRef(null)
  const selectedGroupRef: React.RefObject<Konva.Group> = useRef(null)

  const showTemporaryIcon = useMemo(
    () =>
      selectedMode === EditorMode.DRAW &&
      modeConfigs[EditorMode.DRAW].type === DrawTool.ICON,
    [modeConfigs, selectedMode]
  )

  const showTemporarySeats = useMemo(
    () =>
      selectedMode === EditorMode.DRAW &&
      (modeConfigs[EditorMode.DRAW].type === DrawTool.SEAT_ROW ||
        modeConfigs[EditorMode.DRAW].type === DrawTool.SEAT_ROWS),
    [modeConfigs, selectedMode]
  )

  const showTemporaryShape = useMemo(
    () =>
      selectedMode === EditorMode.DRAW &&
      modeConfigs[EditorMode.DRAW].type === DrawTool.SHAPE,
    [modeConfigs, selectedMode]
  )

  const showTemporaryZone = useMemo(
    () =>
      selectedMode === EditorMode.DRAW &&
      modeConfigs[EditorMode.DRAW].type === DrawTool.ZONE,
    [modeConfigs, selectedMode]
  )

  const onWheel = useCallback(
    (e: Konva.KonvaEventObject<WheelEvent>) => {
      if (!ctrlActive) return

      e.evt.preventDefault()
      const coords = getMouseCoordsOnScreen(e)

      if (coords) {
        const delta = e.evt.deltaY

        if (delta > 0) {
          zoomOut(coords)
        } else if (delta < 0) {
          zoomIn(coords)
        }
      }
    },
    [ctrlActive, zoomIn, zoomOut]
  )

  const changeCursor = getChangeCursor(stageRef ? stageRef.getStage() : null)

  const onMouseDown = useCallback(() => {
    if (selectedMode === EditorMode.PAN) {
      changeCursor(cursorMap[EditorMode.PAN].mouseDown)
    }
  }, [changeCursor, selectedMode])

  const onMouseUp = useCallback(() => {
    if (selectedMode === EditorMode.PAN) {
      changeCursor(cursorMap[EditorMode.PAN].default)
    }
  }, [changeCursor, selectedMode])

  return (
    <Stage
      ref={(ref) => {
        setStageRef(ref)
      }}
      className={classes.stage}
      draggable={selectedMode === EditorMode.PAN}
      scaleX={canvasScale}
      scaleY={canvasScale}
      style={stageStyles}
      x={canvasOrigin.x}
      y={canvasOrigin.y}
      {...{onClick, onDragEnd, onMouseDown, onMouseUp, onWheel}}
      {...canvasDimensions}
    >
      {/* Without this components inside Konva does not see redux store
          TODO: investigate if having two Providers is safe or can bring nasty errors
          TODO: seems to introduce "Can't perform a React state update on an unmounted component" error,
          however it does not break the app
          See: https://github.com/konvajs/react-konva/issues/311
        */}
      <Provider store={konvaStore}>
        {/* TODO use props forward */}
        <UnselectedObjects outerRef={unselectedLayerRef} />
        {/* TODO use props forward */}
        <SelectedObjects
          outerRef={selectedLayerRef}
          groupRef={selectedGroupRef}
        />
        {selectedMode === EditorMode.SELECT && (
          <RectangleSelection
            {...{unselectedLayerRef, selectedGroupRef, selectedLayerRef}}
          />
        )}
        {/* TODO: The following temporary layers are quite similar, maybe it is possible to refactor them to one more general component. */}
        {showTemporaryIcon && <TemporaryIcon />}
        {showTemporarySeats && <TemporarySeats />}
        {showTemporaryShape && <TemporaryShape />}
        {showTemporaryZone && <TemporaryZone />}
      </Provider>
    </Stage>
  )
}
