import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'

import {
  AuditoriumLayoutQuery,
  AuditoriumLayoutState,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useObjectsActions} from '../../../../../../editor/redux/objects/actions'
import {IObjectsState} from '../../../../../../editor/redux/objects/types'
import {useUndoableActions} from '../../../../../../editor/redux/undoable/actions'
import {
  ActivateButtonAuditoriumLayout,
  ArchiveButtonAuditoriumLayout
} from '../../EditActionsDialogs'
import {IEditorFormData} from '../EditorForm'

interface IUpdateOptions {
  handleSubmit: any
  onArchive: (formData: IEditorFormData) => Promise<any>
  onActivate: (formData: IEditorFormData) => Promise<any>
  status: AuditoriumLayoutState
  P: (permissions: Array<PermissionCode>) => Boolean
}

export const getUpdateOptions = ({
  handleSubmit,
  onArchive,
  onActivate,
  status,
  P
}: IUpdateOptions) => {
  if (status === AuditoriumLayoutState.Active) {
    return P([PermissionCode.ArchiveAuditoriumLayout])
      ? [
          <ArchiveButtonAuditoriumLayout
            key={1}
            onSubmit={handleSubmit(onArchive)}
            ensureCanOpenModal={handleSubmit}
          />
        ]
      : []
  }
  return P([PermissionCode.ActivateAuditoriumLayout])
    ? [
        <ActivateButtonAuditoriumLayout
          key={1}
          onSubmit={handleSubmit(onActivate)}
          ensureCanOpenModal={handleSubmit}
        />
      ]
    : []
}

export const useInitForm = (data: AuditoriumLayoutQuery) => {
  const config = {
    defaultValues: data.auditoriumLayout
  }
  return useForm<IEditorFormData>(config)
}

export const useSetEditorState = (data: AuditoriumLayoutQuery | undefined) => {
  const {setObjectsState} = useObjectsActions()
  const {clearHistory} = useUndoableActions()

  useEffect(() => {
    if (!data) return
    setObjectsState(data.auditoriumLayout.layout as IObjectsState)
    clearHistory()
  }, [clearHistory, data, setObjectsState])
}
