import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsStringWithMaxLength} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {UncontrolledFormColorInput} from '../../../../form/UncontrolledFormColorInput'
import {AdmissionRateFormField, IAdmissionRateForm} from '../types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3)
}))

interface IAdmissionRateFormProps {
  formId: string
  onSubmit: (data: IAdmissionRateForm) => void
  defaultValues?: Partial<IAdmissionRateForm>
}

export const AdmissionRateForm: React.FC<IAdmissionRateFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IAdmissionRateFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IAdmissionRateForm>({defaultValues})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength30 = useIsStringWithMaxLength(30)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionRateForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Name')}
            name={AdmissionRateFormField.Name}
            key={AdmissionRateFormField.Name}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength255
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionRateForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Abbreviation')}
            name={AdmissionRateFormField.Abbreviation}
            key={AdmissionRateFormField.Abbreviation}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength30
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormColorInput<IAdmissionRateForm>
            errors={errors}
            triggerValidation={triggerValidation}
            watch={watch}
            register={register}
            label={t('Color')}
            setValue={setValue}
            name={AdmissionRateFormField.Color}
            key={AdmissionRateFormField.Color}
            colorPickerType="compact"
            validationOptions={{
              required: true
            }}
            helperNote={t('Will be displayed for cashiers.')}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionRateForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Internal description')}
            name={AdmissionRateFormField.Description}
            key={AdmissionRateFormField.Description}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
    </StyledForm>
  )
}
