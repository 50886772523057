import {Box, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {LeadPropertiesFragment} from '../../../../../__generated__/schema'
import {SectionGrid} from '../../../../common'
import {LeadSectionGridContent} from '../../components/LeadSectionGridContent'

const useStyles = makeStyles<Theme>((theme) => ({
  typography: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}))

interface IProfileSectionProps {
  id: string
  lead: LeadPropertiesFragment
}

export const ProfileSection: React.FC<IProfileSectionProps> = ({
  id,
  lead
}: IProfileSectionProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Box id={id}>
      <Typography variant="subtitle1" className={classes.typography}>
        {t('Profile')}
      </Typography>
      <Paper variant="outlined">
        <SectionGrid>
          <LeadSectionGridContent
            leadData={lead.data}
            title={t('Contact information')}
            isPrivacyPolicyHidden
          />
        </SectionGrid>
      </Paper>
    </Box>
  )
}
