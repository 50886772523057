import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DownloadIcon from '@mui/icons-material/Download'
import {Box, Button, IconButton, Paper, Typography} from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  GetVoucherQuery,
  PermissionCode,
  VoucherTransactionIntentReason,
  VoucherTransactionIntentState,
  VoucherTransactionIntentType
} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {
  useTranslateVoucherIntentReason,
  useTranslateVoucherIntentState,
  useTranslateVoucherIntentType
} from '../../../../../hooks/translateVoucherTransactionIntent'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip} from '../../../../common'
import {
  DataGridTable,
  useDateTimeFormatter,
  useUserNameFormatter,
  VoucherTransactionAmountCellRenderer
} from '../../../../common/DataGridTable'
import {voucherTransactionIntentStateColors} from '../../../../constants'
import {useLocale} from '../../../../context/locale'
import {ChipWithOptions} from '../../components/ChipWithOptions'
import {CreditVoucherDrawer} from './CreditVoucherDrawer'

const StateCellRenderer = ({state}: {state: VoucherTransactionIntentState}) => {
  const translateVoucherState = useTranslateVoucherIntentState()
  return (
    <EntityStateChip
      colorConf={voucherTransactionIntentStateColors[state]}
      label={translateVoucherState(state)}
    />
  )
}

const IconCellRenderer = ({
  voucherId,
  transactionIntent
}: {
  voucherId: number
  transactionIntent: GetVoucherQuery['voucher']['transactionIntents'][number]
}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleIconClick = useCallback(() => {
    if (
      transactionIntent.paymentIntent?.cart.id &&
      P([PermissionCode.ReadCart])
    ) {
      history.push(
        routeTo.admin.vouchers.cartInfo(
          voucherId,
          transactionIntent.paymentIntent.cart.id
        )
      )
    }
    if (
      transactionIntent.refundIntent?.claim?.id &&
      P([PermissionCode.ReadCart])
    ) {
      history.push(
        routeTo.admin.vouchers.claimInfo(
          voucherId,
          transactionIntent.refundIntent.claim.id
        )
      )
    }
    return undefined
  }, [
    P,
    history,
    transactionIntent.paymentIntent?.cart.id,
    transactionIntent.refundIntent?.claim?.id,
    voucherId
  ])
  return (P([PermissionCode.ReadCart]) && transactionIntent.paymentIntent) ||
    (P([PermissionCode.ReadClaim]) && transactionIntent.refundIntent) ? (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  ) : null
}

interface IFilters {
  voucherState: VoucherTransactionIntentState | undefined
  setVoucherState: (state: VoucherTransactionIntentState | undefined) => void
  voucherType: VoucherTransactionIntentType | undefined
  setVoucherType: (type: VoucherTransactionIntentType | undefined) => void
  voucherReason: VoucherTransactionIntentReason | undefined
  setVoucherReason: (reason: VoucherTransactionIntentReason | undefined) => void
}

interface ITransactionsSectionProps {
  id: string
  voucher: GetVoucherQuery['voucher']
  filters: IFilters
  refetch: () => void
}

export const TransactionsSection: React.FC<ITransactionsSectionProps> = ({
  id,
  voucher,
  filters,
  refetch
}: ITransactionsSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {id: voucherId} = voucher
  const dataGridApiRef = useGridApiRef()
  const {
    state: isCreditVoucherDrawerOpen,
    setTrue: openCreditVoucherDrawer,
    setFalse: closeCreditVoucherDrawer
  } = useBooleanState(false)
  const {localeCode} = useLocale()
  const dateTimeFormatter = useDateTimeFormatter()
  const userNameFormatter = useUserNameFormatter(false, true)
  const translateVoucherIntentState = useTranslateVoucherIntentState()
  const translateVoucherIntentType = useTranslateVoucherIntentType()
  const translateVoucherIntentReason = useTranslateVoucherIntentReason()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Date'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Amount'),
        field: 'amount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <VoucherTransactionAmountCellRenderer
              amount={params.value}
              type={params.row.type}
            />
          )
        },
        valueFormatter: (params) =>
          params.id &&
          (
            parseFloat(params.value) *
            (params.api.getRow(params.id).type ===
            VoucherTransactionIntentType.Debit
              ? -1
              : 1)
          ).toLocaleString(localeCode, {
            minimumFractionDigits: 2
          }),
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StateCellRenderer state={params.value} />
        },
        valueFormatter: (params) => translateVoucherIntentState(params.value),
        minWidth: 150
      },
      {
        headerName: t('Reason'),
        field: 'reason',
        valueFormatter: (params) =>
          params ? translateVoucherIntentReason(params.value) : '',
        minWidth: 150
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        minWidth: 200
      },
      {
        headerName: t('Note'),
        field: 'note',
        minWidth: 300
      },
      {
        headerName: '',
        field: 'arrow',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              voucherId={voucherId}
              transactionIntent={params.row}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48,
        disableExport: true
      }
    ],
    [
      dateTimeFormatter,
      localeCode,
      t,
      translateVoucherIntentReason,
      translateVoucherIntentState,
      userNameFormatter,
      voucherId
    ]
  )
  return (
    <>
      <Box id={id}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            pb: 1
          }}
        >
          <Typography variant="subtitle1">{t('Transactions')}</Typography>
          <Box sx={{display: 'flex', gap: 1}}>
            <Button
              startIcon={<DownloadIcon />}
              color="primary"
              variant="text"
              onClick={() =>
                dataGridApiRef.current.exportDataAsCsv({
                  fileName: voucher.code
                })
              }
              disabled={voucher.transactionIntents.length === 0}
            >
              {t('Download')}
            </Button>
            {P([PermissionCode.CreditVoucher]) &&
              voucher.topUpsCount <
                (voucher.campaign.rechargeLimit || Infinity) && (
                <Button
                  startIcon={<AddIcon />}
                  color="primary"
                  variant="text"
                  onClick={openCreditVoucherDrawer}
                >
                  {t('Add')}
                </Button>
              )}
          </Box>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            display: 'flex',
            gap: 2,
            alignItems: 'center'
          }}
          square
        >
          <ChipWithOptions<VoucherTransactionIntentState>
            selectedItem={filters.voucherState}
            setSelectedItem={filters.setVoucherState}
            options={Object.values(VoucherTransactionIntentState).map(
              (state) => ({
                label: translateVoucherIntentState(state),
                option: state
              })
            )}
            allText={t('All states')}
          />
          <ChipWithOptions<VoucherTransactionIntentType>
            selectedItem={filters.voucherType}
            setSelectedItem={filters.setVoucherType}
            options={Object.values(VoucherTransactionIntentType).map(
              (type) => ({
                label: translateVoucherIntentType(type),
                option: type
              })
            )}
            allText={t('All types')}
          />
          <ChipWithOptions<VoucherTransactionIntentReason>
            selectedItem={filters.voucherReason}
            setSelectedItem={filters.setVoucherReason}
            options={Object.values(VoucherTransactionIntentReason).map(
              (reason) => ({
                label: translateVoucherIntentReason(reason),
                option: reason
              })
            )}
            allText={t('All reasons')}
          />
        </Paper>
        <DataGridTable
          apiRef={dataGridApiRef}
          sx={{borderRadius: 0, borderTop: 'none'}}
          columns={columns}
          rows={voucher.transactionIntents}
          pagination={false}
          hideFooter
          autoHeight
          disableRowSelectionOnClick
          initialState={{
            pinnedColumns: {left: ['createdAt'], right: ['arrow']}
          }}
          columnVisibilityModel={{
            arrow: P([PermissionCode.ReadCart]) || P([PermissionCode.ReadClaim])
          }}
          localeText={{noRowsLabel: t('No transactions to show')}}
        />
      </Box>
      <CreditVoucherDrawer
        isOpen={isCreditVoucherDrawerOpen}
        onClose={closeCreditVoucherDrawer}
        voucherId={voucher.id}
        refetch={refetch}
      />
    </>
  )
}
