import {useQuery} from '@apollo/react-hooks'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {Box, IconButton, Typography} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import {isNil} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  LightweightWarehousesQuery,
  LightweightWarehousesQueryVariables,
  PermissionCode,
  ProductQuery,
  WarehouseState
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useProductParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {DataGridTable} from '../../../../common/DataGridTable'
import {
  NegativeStockCellRenderer,
  StockCellRenderer
} from '../../components/CommonCellRenderers'
import {LIGHTWEIGHT_WAREHOUSES} from '../../graphql'
import {CreateWarehouseProductDrawer} from './CreateWarehouseProductDrawer'

const ActionCellRenderer = ({
  warehouseId,
  warehouseProducts,
  onAddClick
}: {
  warehouseId: number
  warehouseProducts: ProductQuery['product']['warehouseProducts']
  onAddClick: (warehouseId: number) => void
}) => {
  const {P} = useEnsurePermissions()
  const {productId} = useProductParams()
  const history = useHistory()
  const product = warehouseProducts.find(
    ({warehouse}) => warehouse.id === warehouseId
  )
  const handleEditClick = useCallback(
    (warehouseProductId: number) => () => {
      history.push(
        routeTo.admin.products.editWarehouseProduct(
          productId,
          warehouseProductId
        )
      )
    },
    [history, productId]
  )
  return product ? (
    <>
      {P([
        PermissionCode.UpdateWarehouseProduct,
        PermissionCode.ReadWarehouseProduct
      ]) && (
        <IconButton color="primary" onClick={handleEditClick(product.id)}>
          <EditIcon />
        </IconButton>
      )}
    </>
  ) : (
    <>
      {P([PermissionCode.CreateWarehouseProduct]) && (
        <IconButton color="primary" onClick={() => onAddClick(warehouseId)}>
          <AddIcon />
        </IconButton>
      )}
    </>
  )
}

interface IWarehousesSectionProps {
  id: string
  title: string
  product: ProductQuery['product']
}

export const WarehousesSection: React.FC<IWarehousesSectionProps> = ({
  id,
  title,
  product
}: IWarehousesSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useQuery<
    LightweightWarehousesQuery,
    LightweightWarehousesQueryVariables
  >(LIGHTWEIGHT_WAREHOUSES, {
    variables: {
      filter: {states: [WarehouseState.Active]},
      paginationInput: {limit: 100, offset: 0}
    },
    fetchPolicy: 'network-only'
  })
  const [selectedWarehouseId, setSelectedWarehouseId] =
    useState<number | undefined>(undefined)
  const {warehouseProducts} = product
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Warehouse'),
        field: 'name',
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Stock'),
        field: 'stock',
        valueGetter: ({id}) =>
          warehouseProducts.find(({warehouse}) => warehouse.id === id),
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StockCellRenderer warehouseProduct={params.value} />
        },
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Can be negative'),
        field: 'isNegativeStockEnabled',
        valueGetter: ({id}) =>
          warehouseProducts.find(({warehouse}) => warehouse.id === id)
            ?.isNegativeStockEnabled,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <NegativeStockCellRenderer isNegativeStockEnabled={params.value} />
          )
        },
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Min stock'),
        field: 'minStockLevel',
        valueGetter: ({id}) => {
          const minStockLevel = warehouseProducts.find(
            ({warehouse}) => warehouse.id === id
          )?.minStockLevel
          return !isNil(minStockLevel)
            ? minStockLevel.toFixed(6)
            : t('Not set yet')
        },
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Optimal stock level'),
        field: 'optimalStockLevel',
        valueGetter: ({id}) => {
          const optimalStockLevel = warehouseProducts.find(
            ({warehouse}) => warehouse.id === id
          )?.optimalStockLevel
          return !isNil(optimalStockLevel) ? optimalStockLevel.toFixed(6) : ''
        },
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Division'),
        field: 'divisionName',
        valueGetter: ({id}) =>
          warehouseProducts.find(({warehouse}) => warehouse.id === id)?.division
            .name,
        sortable: false,
        minWidth: 150
      },
      {
        headerName: '',
        field: 'action',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <ActionCellRenderer
              warehouseId={params.row.id}
              warehouseProducts={warehouseProducts}
              onAddClick={setSelectedWarehouseId}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [t, warehouseProducts]
  )
  return (
    <Box id={id}>
      <Typography variant="subtitle1" sx={{pb: 1}}>
        {title}
      </Typography>
      <RenderOnData<LightweightWarehousesQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading warehouses')}
        dataCondition={(data) => Array.isArray(data.warehouses.items)}
      >
        {({warehouses}) => (
          <DataGridTable
            sx={{
              [`& .${gridClasses.withBorderColor}`]: {
                borderColor: 'divider'
              },
              [`& .${gridClasses.columnSeparator}`]: {
                color: 'divider'
              }
            }}
            initialState={{
              pinnedColumns: {left: ['name'], right: ['action']}
            }}
            columnVisibilityModel={{
              action:
                P([
                  PermissionCode.UpdateWarehouseProduct,
                  PermissionCode.ReadWarehouseProduct
                ]) || P([PermissionCode.CreateWarehouseProduct])
            }}
            columns={columns}
            rows={warehouses.items}
            autoHeight
            pagination={false}
            disableColumnMenu
            disableRowSelectionOnClick
            columnHeaderHeight={32}
            hideFooter
            localeText={{noRowsLabel: t('No active warehouses to show')}}
          />
        )}
      </RenderOnData>
      <CreateWarehouseProductDrawer
        warehouseId={selectedWarehouseId}
        productId={product.id}
        onClose={() => setSelectedWarehouseId(undefined)}
      />
    </Box>
  )
}
