import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {Blank} from '../../../../visual/Blank'

interface INoDiscountableItemsBlankProps {
  eventId: number
}

export const NoDiscountableItemsBlank: React.FC<INoDiscountableItemsBlankProps> =
  ({eventId}: INoDiscountableItemsBlankProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const history = useHistory()
    const handleSettingsButtonClick = useCallback(() => {
      history.replace(routeTo.admin.events.editPricingOptions(eventId))
    }, [eventId, history])
    return (
      <Blank
        title={t('No items to be discounted')}
        description={t(
          'It is forbidden to add discounts for this items. It can be changed in event pricing settings.'
        )}
        actions={
          P([PermissionCode.UpdateAllowedDiscountsSellingChannelsForEvent]) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSettingsButtonClick}
            >
              {t('Settings')}
            </Button>
          )
        }
      />
    )
  }
