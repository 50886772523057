import AddIcon from '@mui/icons-material/Add'
import {Button, Drawer, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  ApplicationRulePropertiesFragment,
  ApplicationRuleType,
  Condition,
  ConditionPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  ButtonWithConfirmationDialog,
  DrawerTemplate,
  DrawerTemplateHeader
} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {Blank} from '../../../../visual/Blank'
import {useDeleteCondition, useUpdateCondition} from '../graphql'
import {ConditionView} from './ConditionView'
import {CreateConditionDialog} from './CreateConditionDialog'
import {EditConditionDialog} from './EditConditionDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    padding: theme.spacing(3),
    gridTemplateColumns: '1fr auto',
    display: 'grid',
    gridTemplateAreas: `
      "caption addConditionButton"
      "conditions conditions"
    `,
    gap: theme.spacing(2)
  },
  caption: {
    gridArea: 'caption'
  },
  addConditionButton: {
    gridArea: 'addConditionButton'
  },
  conditions: {
    gridArea: 'conditions'
  },
  drawerPaper: {
    maxWidth: 728,
    width: '100%'
  },
  conditionPaper: {
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridTemplateAreas: `
      "conditionViewLabel deleteButton editButton"
      "conditionViewChips conditionViewChips conditionViewChips"
    `,
    gap: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  },
  chipsClassName: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gridArea: 'conditionViewChips'
  },
  labelClassName: {
    gridArea: 'conditionViewLabel',
    alignSelf: 'center'
  }
}))

export interface IRuleDrawerState {
  isOpen: boolean
  applicationRule?: ApplicationRulePropertiesFragment
  type?: ApplicationRuleType
}

interface IApplicationRuleDrawerProps extends IRuleDrawerState {
  closeDrawer: () => void
  onConditionUpdate: (condition: ConditionPropertiesFragment) => void
  openApplicationRuleDetail: (
    applicationRule: ApplicationRulePropertiesFragment
  ) => void
}

export const ApplicationRuleDrawer: React.FC<IApplicationRuleDrawerProps> = ({
  closeDrawer,
  applicationRule,
  openApplicationRuleDetail,
  onConditionUpdate,
  isOpen,
  type
}: IApplicationRuleDrawerProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const params = useParams<{discountId: string}>()
  const discountId = parseInt(params.discountId, 10)
  const {P} = useEnsurePermissions()
  const updateCondition = useUpdateCondition(discountId)
  const {
    state: isCreateConditionDialogOpen,
    setTrue: handleAddButtonClick,
    setFalse: closeConditionDialog
  } = useBooleanState(false)
  const [conditionIdToBeDeleted, setConditionIdToBeDeleted] =
    useState<number | null>(null)
  const [editCondition, setEditCondition] = useState<Condition | null>(null)
  const closeEditConditionDialog = useCallback(() => {
    setEditCondition(null)
  }, [])
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const deleteCondition = useDeleteCondition(discountId)
  const handleDeleteConditionConfirmation = useCallback(
    async (conditionId: number) => {
      setShowBackdrop(true)
      if (applicationRule) {
        try {
          await deleteCondition(conditionId)
          addInfoNotification(t('Condition deleted.'))
          const remainingConditions = applicationRule?.conditions.filter(
            ({id}) => id !== conditionId
          )
          if (remainingConditions.length) {
            openApplicationRuleDetail({
              ...applicationRule,
              conditions: remainingConditions
            })
          } else {
            closeDrawer()
          }
        } catch (e) {
          defaultErrorHandler(e, t('Error while deleting a condition'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      applicationRule,
      closeDrawer,
      defaultErrorHandler,
      deleteCondition,
      openApplicationRuleDetail,
      setShowBackdrop,
      t
    ]
  )
  const handleDeleteIconClick = useCallback(
    ({
        condition,
        valueToDelete
      }: {
        condition: ConditionPropertiesFragment
        valueToDelete: any
      }) =>
      async () => {
        const remainingValues = condition.conditionData.value.filter(
          (value: any) => value !== valueToDelete
        )
        if (remainingValues.length >= 1) {
          try {
            setShowBackdrop(true)
            const updatedCondition = await updateCondition({
              id: condition.id,
              operator: condition.conditionData.operator,
              value: remainingValues
            })
            addInfoNotification(t('Condition updated'))
            onConditionUpdate(updatedCondition)
          } catch (error) {
            defaultErrorHandler(error, t('Updating of condition failed.'))
          } finally {
            setShowBackdrop(false)
          }
        } else {
          setConditionIdToBeDeleted(condition.id)
        }
      },
    [
      addInfoNotification,
      defaultErrorHandler,
      onConditionUpdate,
      setShowBackdrop,
      t,
      updateCondition
    ]
  )
  return (
    <Drawer
      open={isOpen}
      anchor="right"
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            title={applicationRule ? t('Edit rule') : t('Create rule')}
            onLeftActionClick={closeDrawer}
          />
        }
      >
        {applicationRule?.conditions.length ? (
          <div className={classes.grid}>
            <Typography variant="caption" color="textSecondary">
              {t(
                'Create one or multiple conditions for rule. All the conditions below must be met for the rule to apply.'
              )}
            </Typography>
            {P([PermissionCode.CreateCondition]) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddButtonClick}
              >
                {t('Add condition')}
              </Button>
            )}
            <div className={classes.conditions}>
              {applicationRule?.conditions.map((condition, index) => (
                <Paper
                  variant="outlined"
                  key={condition.id}
                  classes={{
                    root: classes.conditionPaper
                  }}
                >
                  <ConditionView
                    condition={condition}
                    isFirst={index === 0}
                    chipsClassName={classes.chipsClassName}
                    labelClassName={classes.labelClassName}
                    onDeleteClick={
                      P([
                        PermissionCode.UpdateCondition,
                        PermissionCode.DeleteCondition
                      ])
                        ? handleDeleteIconClick
                        : undefined
                    }
                  />
                  {P([PermissionCode.DeleteCondition]) && (
                    <ButtonWithConfirmationDialog
                      buttonProps={{
                        color: 'primary',
                        variant: 'text',
                        children: t('Delete')
                      }}
                      onConfirmButtonClick={() => {
                        handleDeleteConditionConfirmation(condition.id)
                      }}
                      dialogProps={{
                        title: t('Delete condition'),
                        contentText: t(
                          "Are you sure you want to delete condition? This can't be undone."
                        ),
                        confirmButtonLabel: t('Delete')
                      }}
                    />
                  )}
                  {P([PermissionCode.UpdateCondition]) && (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        setEditCondition(condition)
                      }}
                    >
                      {t('Edit')}
                    </Button>
                  )}
                </Paper>
              ))}
            </div>
          </div>
        ) : (
          <Blank
            title={
              applicationRule
                ? t('Create conditions')
                : t('Create rule with conditions')
            }
            description={
              applicationRule
                ? t(
                    'Create one or multiple conditions for rule. All the conditions below must be met for the rule to apply.'
                  )
                : t(
                    'Create a rule with one or multiple conditions. All the conditions below must be met for the rule to apply.'
                  )
            }
            actions={
              P([PermissionCode.CreateCondition]) && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddButtonClick}
                >
                  {t('Add condition')}
                </Button>
              )
            }
          />
        )}
      </DrawerTemplate>
      <CreateConditionDialog
        isOpen={isCreateConditionDialogOpen}
        closeDialog={closeConditionDialog}
        openApplicationRuleDetail={openApplicationRuleDetail}
        existingApplicationRule={applicationRule}
        type={type}
      />
      <EditConditionDialog
        closeDialog={closeEditConditionDialog}
        condition={editCondition}
        onConditionUpdate={onConditionUpdate}
        onConditionDelete={handleDeleteConditionConfirmation}
      />
      {applicationRule && (
        <ConfirmationDialog
          title={t('Delete condition')}
          contentText={t(
            "Are you sure you want to delete condition? This can't be undone."
          )}
          confirmButtonLabel={t('Delete')}
          onConfirm={async () => {
            if (conditionIdToBeDeleted) {
              await handleDeleteConditionConfirmation(conditionIdToBeDeleted)
              setConditionIdToBeDeleted(null)
            }
          }}
          onCancel={() => setConditionIdToBeDeleted(null)}
          isOpen={!!conditionIdToBeDeleted}
        />
      )}
    </Drawer>
  )
}
