import {Box, Typography} from '@mui/material'
import React from 'react'
import {Datetime} from '../../../common/header/Datetime'
import {BaseHeaderVariant} from '../../../common/header/types'

interface IHeaderProps {
  title: string
}

export const Header: React.FC<IHeaderProps> = ({title}: IHeaderProps) => {
  return (
    <Box
      sx={{
        height: 64,
        backgroundColor: (theme) => theme.palette.common.white,
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
        display: 'grid',
        px: 3,
        alignItems: 'center',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr auto'
      }}
    >
      <Typography variant="h6" noWrap>
        {title}
      </Typography>
      <Datetime variant={BaseHeaderVariant.Secondary} />
    </Box>
  )
}
