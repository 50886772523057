import EditIcon from '@mui/icons-material/Edit'
import {Box, Drawer, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useRouteMatch} from 'react-router-dom'

import {
  AuditoriumLayoutPricingPropertiesFragment,
  AuditoriumLayoutPricingState,
  AuditoriumLayoutState,
  GetLayoutPricingsQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {
  EntityStateChip,
  NoEntity,
  RenderOnData,
  TopControls,
  TopControlsCreateButton
} from '../../../../common'
import {DataGridTable} from '../../../../common/DataGridTable'
import {
  layoutPricingStateColors,
  useLayoutPricingStateTranslations
} from '../../../../constants'
import {AddLayoutPricingForm, ILayoutPricingFormData} from './AddForm'
import {useCreateLayoutPricing, useGetLayoutPricings} from './graphql'

const MAX_TICKETS_PER_ORDER_DEFAULT = 10

export const StateRenderer = ({
  state
}: {
  state: AuditoriumLayoutPricingState
}) => {
  const layoutPricingStateTranslations = useLayoutPricingStateTranslations()
  return (
    <EntityStateChip
      label={layoutPricingStateTranslations[state]}
      colorConf={layoutPricingStateColors[state]}
      isDotHidden
    />
  )
}

const EditCellRenderer = ({
  id,
  venueId,
  auditoriumId,
  auditoriumLayoutId
}: {
  id: number
  venueId: number
  auditoriumId: number
  auditoriumLayoutId: number
}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(
        routeTo.admin.venues.editLayoutPricing(
          venueId,
          auditoriumId,
          auditoriumLayoutId,
          id
        )
      ),
    [auditoriumId, auditoriumLayoutId, history, id, venueId]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

interface ILayoutPricingsTableProps {
  venueId: number
  auditoriumId: number
  auditoriumLayoutId: number
  layoutPricings: Array<AuditoriumLayoutPricingPropertiesFragment>
}

const LayoutsTable: React.FC<ILayoutPricingsTableProps> = ({
  venueId,
  auditoriumId,
  auditoriumLayoutId,
  layoutPricings
}: ILayoutPricingsTableProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name', {context: 'object'}),
        field: 'name',
        flex: 1
      },
      {
        headerName: t('Ticket types'),
        field: 'ticketTypes',
        valueFormatter: (params) => params.value.length,
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('State'),
        field: 'status',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: AuditoriumLayoutPricingState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 100
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <EditCellRenderer
              id={params.value}
              venueId={venueId}
              auditoriumId={auditoriumId}
              auditoriumLayoutId={auditoriumLayoutId}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [auditoriumId, auditoriumLayoutId, t, venueId]
  )
  return (
    <DataGridTable
      columns={columns}
      rows={layoutPricings}
      pagination={false}
      autoHeight
      hideFooter
      disableRowSelectionOnClick
      columnVisibilityModel={{
        id: P([PermissionCode.UpdateAuditoriumLayoutPricing])
      }}
      initialState={{
        pinnedColumns: {right: ['id']}
      }}
    />
  )
}

interface IControlsProps {
  CreateButton: React.ReactNode
}

const Controls: React.FC<IControlsProps> = ({CreateButton}: IControlsProps) => {
  const {t} = useTranslation()

  return (
    <Box paddingBottom={4}>
      <TopControls label={t('Layout pricing')} rightChild={CreateButton} />
    </Box>
  )
}

interface ILayoutPricingProps {
  venueId: number
  auditoriumId: number
  auditoriumLayoutId: number
}

const useStyles = makeStyles<Theme>((theme) => ({
  controls: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1)
  }
}))

export const LayoutPricings: React.FC<ILayoutPricingProps> = ({
  venueId,
  auditoriumId,
  auditoriumLayoutId
}: ILayoutPricingProps) => {
  const match = useRouteMatch()
  const history = useHistory()
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useGetLayoutPricings(auditoriumLayoutId)
  const createAuditoriumLayout = useCreateLayoutPricing(auditoriumLayoutId)
  const defaultErrorHandler = useDefaultErrorHandler()

  const isAddEntityDrawerOpen =
    match.url ===
    routeTo.admin.venues.addLayoutPricing(
      venueId,
      auditoriumId,
      auditoriumLayoutId
    )

  const onAddClick = useCallback(
    () =>
      history.push(
        routeTo.admin.venues.addLayoutPricing(
          venueId,
          auditoriumId,
          auditoriumLayoutId
        )
      ),
    [auditoriumId, auditoriumLayoutId, history, venueId]
  )

  const CreateButton = (
    <TopControlsCreateButton
      label={t('Add new layout pricing')}
      onClick={onAddClick}
    />
  )

  return (
    <RenderOnData
      {...{loading, error, data}}
      errorMessage={t<string>('Could not load layout pricings')}
    >
      {(data: GetLayoutPricingsQuery) => {
        const {auditoriumLayoutPricings, auditoriumLayout} = data
        return (
          <>
            <Drawer
              anchor="right"
              open={isAddEntityDrawerOpen}
              onClose={history.goBack}
            >
              <AddLayoutPricingForm
                onSubmit={async (data: ILayoutPricingFormData) => {
                  try {
                    const res = await createAuditoriumLayout(
                      auditoriumLayoutId,
                      data.name,
                      MAX_TICKETS_PER_ORDER_DEFAULT,
                      auditoriumLayout.capacity,
                      auditoriumLayout.capacity
                    )
                    if (!res.data) {
                      throw new Error('Could not get new layout pricing data.')
                    }
                    history.replace({
                      pathname: routeTo.admin.venues.editLayoutPricing(
                        venueId,
                        auditoriumId,
                        auditoriumLayoutId,
                        res.data.createAuditoriumLayoutPricing.id
                      )
                    })
                  } catch (err) {
                    defaultErrorHandler(
                      err,
                      t('Error while creating layout pricing')
                    )
                  }
                }}
                title={t('New layout pricing')}
              />
            </Drawer>
            {auditoriumLayoutPricings.length > 0 && (
              <Box className={classes.controls}>
                <Controls
                  CreateButton={
                    P([PermissionCode.CreateAuditoriumLayoutPricing])
                      ? CreateButton
                      : null
                  }
                />

                <LayoutsTable
                  {...{venueId, auditoriumId, auditoriumLayoutId}}
                  layoutPricings={auditoriumLayoutPricings}
                />
              </Box>
            )}
            {auditoriumLayoutPricings.length === 0 &&
              auditoriumLayout.status !== AuditoriumLayoutState.Draft && (
                <NoEntity
                  label={t('There are no layout pricings created yet.')}
                  CreateButton={
                    P([PermissionCode.CreateAuditoriumLayoutPricing])
                      ? CreateButton
                      : null
                  }
                />
              )}
          </>
        )
      }}
    </RenderOnData>
  )
}
