import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {WebsiteQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useCreateWebsitePage} from '../graphql'
import {IWebsitePageForm, WebsitePageFormField} from '../types'
import {getWebsitePageInputForCopyPage} from '../utils'
import {useWebsitePageContext} from './WebsitePageContext'
import {WebsitePageForm} from './WebsitePageForm'

const COPY_PAGE_FORM_ID = 'copyPageForm'

interface ICopyWebsitePageDrawerProps {
  isOpen: boolean
  onClose: () => void
  website: WebsiteQuery['website']
}

export const CopyWebsitePageDrawer: React.FC<ICopyWebsitePageDrawerProps> = ({
  isOpen,
  onClose,
  website
}: ICopyWebsitePageDrawerProps) => {
  const {t} = useTranslation()
  const {selectedWebsitePage, refetchWebsitePages, setSelectedWebsitePage} =
    useWebsitePageContext()
  const createWebsitePage = useCreateWebsitePage()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (formData: IWebsitePageForm) => {
      if (selectedWebsitePage) {
        try {
          setShowBackdrop(true)
          const {data} = await createWebsitePage({
            websiteId: website.id,
            localeCode: formData[WebsitePageFormField.LocaleCode],
            input: getWebsitePageInputForCopyPage(formData, selectedWebsitePage)
          })
          refetchWebsitePages()
          if (data) {
            setSelectedWebsitePage(data.createWebsitePage)
          }
          addInfoNotification(t('Website page has been created'))
          onClose()
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      createWebsitePage,
      onClose,
      refetchWebsitePages,
      selectedWebsitePage,
      setSelectedWebsitePage,
      setShowBackdrop,
      t,
      website.id
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Copy page')}
          />
        }
        footer={
          <SaveButton type="submit" form={COPY_PAGE_FORM_ID}>
            {t('Copy')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {selectedWebsitePage && (
          <WebsitePageForm
            formId={COPY_PAGE_FORM_ID}
            onSubmit={handleSubmit}
            primaryDomain={website.primaryDomain}
            enabledLocaleCodes={website.enabledLocaleCodes}
            defaultValues={{
              [WebsitePageFormField.LocaleCode]: selectedWebsitePage.localeCode,
              [WebsitePageFormField.Title]: t('Copy of {{pageTitle}}', {
                pageTitle: selectedWebsitePage.title
              }),
              [WebsitePageFormField.Slug]: t('copy-of-{{pageSlug}}', {
                pageSlug: selectedWebsitePage.slug
              }),
              [WebsitePageFormField.Description]:
                selectedWebsitePage.description
            }}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
