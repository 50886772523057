import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {useWebsiteParams} from '../../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {
  useUpdateWebsiteCommonSettings,
  useWebsiteCommonSettings
} from '../../graphql'
import {ECommerceSettingsForm} from './ECommerceSettingsForm'
import {IEcommerceSettingsForm} from './types'
import {
  transformBaseWebsiteCommonSettingsFieldsToEcommerceSettingsForm,
  transformECommerceSettingsFormToUpdateWebsiteCommonSettingsInput
} from './utils'

const UPDATE_ECOMMERCE_SETTINGS_FORM_ID = 'updateEcommerceSettingsForm'

interface IUpdateEcommerceSettingsDrawerProps {
  onExited: () => void
}

export const UpdateEcommerceSettingsDrawer: React.FC<IUpdateEcommerceSettingsDrawerProps> =
  ({onExited}: IUpdateEcommerceSettingsDrawerProps) => {
    const {t} = useTranslation()
    const {websiteId} = useWebsiteParams()
    const updateWebsiteCommonSettings = useUpdateWebsiteCommonSettings()
    const {data, loading, error} = useWebsiteCommonSettings(
      websiteId,
      isNaN(websiteId)
    )
    const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const handleSubmit = useCallback(
      async (formData: IEcommerceSettingsForm) => {
        try {
          setShowBackdrop(true)
          await updateWebsiteCommonSettings({
            websiteId,
            input:
              transformECommerceSettingsFormToUpdateWebsiteCommonSettingsInput(
                formData
              )
          })
          addInfoNotification(t('Ecommerce settings has been updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while updating website common settings')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        defaultErrorHandler,
        setShowBackdrop,
        t,
        updateWebsiteCommonSettings,
        websiteId
      ]
    )
    useEffect(() => {
      if (!isNaN(websiteId)) {
        openDrawer()
      }
    }, [openDrawer, websiteId])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error && t('Error while loading website common settings')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update ecommerce settings')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_ECOMMERCE_SETTINGS_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {data && (
            <ECommerceSettingsForm
              formId={UPDATE_ECOMMERCE_SETTINGS_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={transformBaseWebsiteCommonSettingsFieldsToEcommerceSettingsForm(
                data.websiteCommonSettings
              )}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
