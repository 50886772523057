import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {TemplatePropertiesFragment} from '../../../../../__generated__/schema'
import {useTranslateLocaleCodeName} from '../../../../../hooks/translateLocales'
import {useTranslateTemplateFileType} from '../../../../../hooks/translateTemplates'
import {Theme} from '../../../../../theme'

interface ITemplateItemProps {
  template: TemplatePropertiesFragment
  onAssignButtonClick: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    padding: theme.spacing(2, 0),
    gap: theme.spacing(0, 1),
    gridTemplateAreas: `
      "primaryText addButton"
      "secondaryText addButton"
    `,
    gridTemplateColumns: '1fr auto',
    '&:last-child': {
      paddingBottom: 0
    },
    '&:first-child': {
      paddingTop: 0
    }
  },
  addButton: {
    gridArea: 'addButton',
    alignSelf: 'center'
  }
}))

export const TemplateItem: React.FC<ITemplateItemProps> = ({
  template,
  onAssignButtonClick
}: ITemplateItemProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translateFileType = useTranslateTemplateFileType()
  const translateLocaleCodeName = useTranslateLocaleCodeName()
  return (
    <div className={classes.root}>
      <Typography>
        {[`#${template.id}`, template.name].join('  •  ')}
      </Typography>
      <Typography color="textSecondary" variant="caption">
        {[
          t('Filetype {{templateFileType}}', {
            templateFileType: translateFileType(template.fileType)
          }),
          translateLocaleCodeName(template.localeCode)
        ].join(' • ')}
      </Typography>
      <Button
        color="primary"
        className={classes.addButton}
        onClick={onAssignButtonClick}
      >
        {t('Add')}
      </Button>
    </div>
  )
}
