import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {Box, Collapse, IconButton, SxProps, Typography} from '@mui/material'
import {Variant} from '@mui/material/styles/createTypography'
import React from 'react'
import {useBooleanState} from '../../hooks/state'
import {Theme} from '../../theme'

export interface ICollapseSectionProps {
  title: string
  children: React.ReactNode
  expanded?: boolean
  sx?: SxProps<Theme>
  typographyVariant?: Variant
  helperText?: string
  hasError?: boolean
}

export const CollapseSection: React.FC<ICollapseSectionProps> = ({
  title,
  children,
  expanded,
  sx,
  typographyVariant = 'subtitle2',
  helperText,
  hasError
}: ICollapseSectionProps) => {
  const {state: isSectionExpanded, toggle: toggleSection} = useBooleanState(
    Boolean(expanded)
  )
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
        onClick={toggleSection}
      >
        <Box>
          <Typography component="div" variant={typographyVariant}>
            {title}
          </Typography>
          {helperText && !isSectionExpanded && (
            <Typography
              component="div"
              variant="caption"
              color={hasError ? 'error' : 'textSecondary'}
            >
              {helperText}
            </Typography>
          )}
        </Box>
        <IconButton>
          {isSectionExpanded ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </Box>
      <Collapse in={isSectionExpanded}>{children}</Collapse>
    </Box>
  )
}
