import {useTranslation} from 'react-i18next'
import {TourItemCheckStatus} from '../__generated__/schema'

export const useTranslateTourItemCheckStatus = () => {
  const {t} = useTranslation()
  const translated: Record<TourItemCheckStatus, string> = {
    [TourItemCheckStatus.CheckedIn]: t('TourItemCheckStatus->CheckedIn'),
    [TourItemCheckStatus.CheckedOut]: t('TourItemCheckStatus->CheckedOut'),
    [TourItemCheckStatus.Unchecked]: t('TourItemCheckStatus->Unchecked'),
    [TourItemCheckStatus.PartiallyChecked]: t(
      'TourItemCheckStatus->PartiallyChecked'
    )
  }
  return (status: TourItemCheckStatus) => translated[status]
}
