import EditIcon from '@mui/icons-material/Edit'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  MarketingLabelsQuery,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {DataGridTable} from '../../../common/DataGridTable'
import {COLOR_CONF} from '../../../constants'
import {useGetMarketingLabels} from './graphql'

const StateRenderer = ({isActive}: {isActive: boolean}) => {
  const {t} = useTranslation()
  return (
    <EntityStateChip
      colorConf={isActive ? COLOR_CONF.GREEN : COLOR_CONF.RED}
      label={isActive ? t('Active') : t('Inactive')}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.marketingLabels.edit(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick} color="primary">
      <EditIcon />
    </IconButton>
  )
}

export const MarketingLabelsList: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useGetMarketingLabels()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 200
      },
      {
        headerName: t('Label'),
        field: 'label',
        minWidth: 150
      },
      {
        headerName: t('State'),
        field: 'isActive',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StateRenderer isActive={params.value} />
        },
        minWidth: 150
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t]
  )
  return (
    <RenderOnData<MarketingLabelsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading marketing labels')}
      dataCondition={(data) => Array.isArray(data.marketingLabels)}
      ignoreLoadingIfData
    >
      {({marketingLabels}) => (
        <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
          <DataGridTable
            columns={columns}
            rows={marketingLabels}
            pageSizeOptions={[10, 30, 50]}
            initialState={{
              pagination: {paginationModel: {pageSize: 30}},
              pinnedColumns: {left: ['name'], right: ['id']}
            }}
            columnVisibilityModel={{
              id: P([PermissionCode.UpdateMarketingLabel])
            }}
            localeText={{noRowsLabel: t('No marketing labels to show')}}
            disableRowSelectionOnClick
          />
        </Box>
      )}
    </RenderOnData>
  )
}
