import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {IconButton} from '@mui/material'
import {GridCellParams} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import {isNil} from 'lodash'
import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {routeTo} from '../../../../utils/routes'

export const IconCellRenderer = ({
  id,
  isRowPinned
}: {
  id: number
  isRowPinned: boolean
}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.eventsStatistics.eventInfo(id)),
    [history, id]
  )
  return !isRowPinned ? (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  ) : null
}

export const getCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''
