import React from 'react'
import {ProductItemPropertiesFragment} from '../../../__generated__/schema'
import {CartSummaryItem} from './CartSummaryItem'

interface ICartSummaryProductItemProps {
  cartItem: ProductItemPropertiesFragment
  onClick?: () => void
  translatePrice: (value: number) => string
}

export const CartSummaryProductItem: React.FC<ICartSummaryProductItemProps> = ({
  cartItem,
  onClick,
  translatePrice
}: ICartSummaryProductItemProps) => {
  return (
    <CartSummaryItem
      onClick={onClick}
      count={cartItem.quantity}
      description={translatePrice(cartItem.unitPrice)}
      price={translatePrice(cartItem.price)}
      title={cartItem.name}
    />
  )
}
