import {useCallback} from 'react'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ShowVideoSource} from '../../../__generated__/schema'
import {VideoSourceError} from '../../../utils/getVideoSource'
import {useNotifications} from '../../context/notifications'
import {AddVideoFormField, IAddVideoForm, IAddVideoFormResult} from './types'

export const useHandleValidationError = () => {
  const {t} = useTranslation()
  const {addErrorWithCustomDialogNotification} = useNotifications()
  return useCallback(
    ({
      error,
      setError
    }: {
      error: any
      setError: FormContextValues<IAddVideoForm>['setError']
    }) => {
      switch (error.message) {
        case VideoSourceError.IncorrectVideoIdentifier:
          setError(AddVideoFormField.Url, t('Incorrect video identifier'))
          break
        case VideoSourceError.MissingVideoIdentifier:
          setError(AddVideoFormField.Url, t('Missing video identifier'))
          break
        case VideoSourceError.UnsupportedSource:
          setError(AddVideoFormField.Url, t('Unsupported source'))
          break
        default:
          addErrorWithCustomDialogNotification({
            title: t('Error while parsing URL'),
            contentText: t('Please check URL and try again')
          })
      }
    },
    [addErrorWithCustomDialogNotification, t]
  )
}

export const transformVideoIdentifierToEmbedUrl = (
  data: Pick<IAddVideoFormResult, 'source' | 'identifier'>
) =>
  data.source === ShowVideoSource.Youtube
    ? `https://www.youtube.com/embed/${data.identifier}`
    : `https://player.vimeo.com/video/${data.identifier}`
