import {Breadcrumbs, Link, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'

import {useVenuesPathnameParams} from '../../../../utils/pathname'
import {routeTo} from '../../../../utils/routes'
import {BLink} from '../../../common/BLink'
import {useGetAuditoriumLayout} from './auditoriumLayouts/graphql'
import {useGetAuditorium} from './auditoriums/graphql'
import {useGetVenue} from './graphql'

const useVenueBreadcrumbsStyles = makeStyles<Theme>((theme) => ({
  breadcrumbs: {
    padding: theme.spacing(1, 0, 2)
  },
  link: {
    cursor: 'pointer'
  }
}))

interface ILink {
  label: string
  onClick?: () => void
}

interface IVenuesBreadcrumbsProps {
  links: ILink[]
}

export const VenuesBreadcrumbs: React.FC<IVenuesBreadcrumbsProps> = ({
  links
}: IVenuesBreadcrumbsProps) => {
  const classes = useVenueBreadcrumbsStyles()
  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      {links.filter(Boolean).map((link, index, array) =>
        index !== array.length - 1 ? (
          <Link
            key={`link-${index}`}
            className={classes.link}
            color="inherit"
            onClick={link.onClick}
          >
            {link.label}
          </Link>
        ) : (
          <Typography key={`typography-${index}`} color="textPrimary">
            {link.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  )
}

const HomeLink: React.FC = () => {
  const {t} = useTranslation()
  return (
    <BLink hideDivider to={routeTo.admin.venues.home()}>
      {t('Venues')}
    </BLink>
  )
}

export const VenueDetailBreadcrumb: React.FC = () => {
  const {venueId} = useVenuesPathnameParams()
  const {data} = useGetVenue(venueId)

  if (data && data.venue) {
    return (
      <>
        <HomeLink />
        <BLink to={routeTo.admin.venues.detail(venueId)}>
          {data.venue.name}
        </BLink>
      </>
    )
  }
  return null
}

export const AuditoriumDetailBreadcrumb: React.FC = () => {
  const {venueId, auditoriumId} = useVenuesPathnameParams()
  const {data: venueData} = useGetVenue(venueId)
  const {data: auditoriumData} = useGetAuditorium(auditoriumId)

  if (
    venueData &&
    venueData.venue &&
    auditoriumData &&
    auditoriumData.auditorium
  ) {
    return (
      <>
        <HomeLink />
        <BLink to={routeTo.admin.venues.detail(venueId)}>
          {venueData.venue.name}
        </BLink>
        <BLink
          to={routeTo.admin.venues.detailAuditorium(venueId, auditoriumId)}
        >
          {auditoriumData.auditorium.name}
        </BLink>
      </>
    )
  }
  return null
}

export const AuditoriumLayoutDetailBreadcrumb: React.FC = () => {
  const {venueId, auditoriumId, auditoriumLayoutId} = useVenuesPathnameParams()
  const {data: venueData} = useGetVenue(venueId)
  const {data: auditoriumData} = useGetAuditorium(auditoriumId)
  const {data: auditoriumLayoutData} =
    useGetAuditoriumLayout(auditoriumLayoutId)

  if (
    venueData &&
    venueData.venue &&
    auditoriumData &&
    auditoriumData.auditorium &&
    auditoriumLayoutData &&
    auditoriumLayoutData.auditoriumLayout
  ) {
    return (
      <>
        <HomeLink />
        <BLink to={routeTo.admin.venues.detail(venueId)}>
          {venueData.venue.name}
        </BLink>
        <BLink
          to={routeTo.admin.venues.detailAuditorium(venueId, auditoriumId)}
        >
          {auditoriumData.auditorium.name}
        </BLink>
        <BLink
          to={routeTo.admin.venues.detailAuditoriumLayout(
            venueId,
            auditoriumId,
            auditoriumLayoutId
          )}
        >
          {auditoriumLayoutData.auditoriumLayout.name}
        </BLink>
      </>
    )
  }
  return null
}
