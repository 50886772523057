import InfoIcon from '@mui/icons-material/Info'
import {Divider, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from '../../../../../../editor/redux'

import {IObjectsState} from '../../../../../../editor/redux/objects/types'
import {pricingSelector} from '../../../../../../editor/redux/pricing/selectors'

import {
  canActivateLayoutPricing,
  getUnassignedTicketTypesCount,
  getUnlabeledLayoutPricingObjectsCounts
} from '../../../../../../shared/layoutPricingActivation'
import {Theme} from '../../../../../../theme'

import {EditorSideBar} from '../../editorUtils'
import {ITransformedPricingScreenData} from '../graphql'

interface IInvalidStatusInfoProps {
  data: ITransformedPricingScreenData
}

interface IInfoProps {
  label: string
  count: number
  iconClassName: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  unusedTicketTypeError: {
    color: '#F3AB28'
  },
  unlabelledError: {
    color: theme.palette.error.main
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  activationInfo: {
    marginTop: theme.spacing(1)
  }
}))

const Info: React.FC<IInfoProps> = ({
  label,
  count,
  iconClassName
}: IInfoProps) => {
  const classes = useStyles()
  return (
    <Grid container alignItems="center">
      <InfoIcon fontSize="small" className={iconClassName} />
      <Typography className={classes.label}>
        {count} {label}
      </Typography>
    </Grid>
  )
}

export const useCanActivateLayoutPricing = (
  data: ITransformedPricingScreenData
) => {
  // Note: we do not validate pricing from server, but current working version
  const currentPricing = useSelector(pricingSelector)

  return canActivateLayoutPricing(
    data.layoutData.layout as IObjectsState,
    currentPricing,
    data.pricingData.ticketTypes
  )
}

export const InvalidStatusInfo: React.FC<IInvalidStatusInfoProps> = ({
  data
}: IInvalidStatusInfoProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  // Note: we do not validate pricing from server, but current working version
  const currentPricing = useSelector(pricingSelector)

  const unlabelledInfo = getUnlabeledLayoutPricingObjectsCounts(
    data.layoutData.layout as IObjectsState,
    currentPricing,
    data.pricingData.ticketTypes
  )

  const unassignedTicketTypesCount = getUnassignedTicketTypesCount(
    currentPricing,
    data.pricingData.ticketTypes
  )

  if (
    unassignedTicketTypesCount === 0 &&
    Object.values(unlabelledInfo).every((v) => v === 0)
  ) {
    return null
  }

  return (
    <>
      <Divider />
      <EditorSideBar>
        {unlabelledInfo.seat > 0 && (
          <Info
            count={unlabelledInfo.seat}
            label={t('seats without price', {
              postProcess: 'interval',
              count: unlabelledInfo.seat
            })}
            iconClassName={classes.unlabelledError}
          />
        )}
        {unlabelledInfo.zone > 0 && (
          <Info
            count={unlabelledInfo.zone}
            label={t('zones without price', {
              count: unlabelledInfo.zone
            })}
            iconClassName={classes.unlabelledError}
          />
        )}
        {unassignedTicketTypesCount > 0 && (
          <Info
            count={unassignedTicketTypesCount}
            label={t('unused ticket types', {
              postProcess: 'interval',
              count: unassignedTicketTypesCount
            })}
            iconClassName={classes.unusedTicketTypeError}
          />
        )}
        <Typography
          variant="caption"
          component="div"
          className={classes.activationInfo}
        >
          {t('Those issues must be resolved before pricing activation.')}
        </Typography>
      </EditorSideBar>
      <Divider />
    </>
  )
}
