import dayjs from 'dayjs'
import {orderBy} from 'lodash'

export enum PricingStatus {
  Past = 'past',
  Future = 'future',
  Current = 'current'
}

type PricingsPick = {
  startsAt: string
}

interface ISetPricingsStatusProps<T extends PricingsPick> {
  pricings: T[]
}

export const setPricingsStatus = <T extends PricingsPick>({
  pricings
}: ISetPricingsStatusProps<T>) => {
  const now = dayjs()
  let foundCurrent = false

  return orderBy(pricings, (p) => p.startsAt, 'desc')
    .map((pricing) => {
      let pricingStatus: PricingStatus

      if (dayjs(pricing.startsAt).isAfter(now)) {
        pricingStatus = PricingStatus.Future
      } else {
        if (foundCurrent) {
          pricingStatus = PricingStatus.Past
        } else {
          foundCurrent = true
          pricingStatus = PricingStatus.Current
        }
      }

      return {...pricing, status: pricingStatus}
    })
    .reverse()
}
