import {
  Backdrop as MuiBackdrop,
  CircularProgress,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  backdrop: {
    // `theme.zIndex.drawer + 1` does not seem to work correctly inside Drawers,
    // (the Drawer still has higher zIndex), thus we use value that is high enough,
    // as Backdrop should always be on top when active
    zIndex: 99999,
    color: theme.palette.background.default
  }
}))

interface IBackdropProps {
  open: boolean
}

export const Backdrop: React.FC<IBackdropProps> = ({open}: IBackdropProps) => {
  const classes = useStyles()
  return (
    <MuiBackdrop className={classes.backdrop} open={open} timeout={0}>
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  )
}

interface IBackdropWithInfoProps {
  isVisible: boolean
  title: string
  description: string
}

const useBackdropWithInfoStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.primary.contrastText,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'column',
    gap: theme.spacing(2),
    zIndex: 'initial'
  },
  backdropText: {
    maxWidth: 328
  }
}))

export const BackdropWithInfo: React.FC<IBackdropWithInfoProps> = ({
  title,
  isVisible,
  description
}: IBackdropWithInfoProps) => {
  const classes = useBackdropWithInfoStyles()
  const handleBackdropClick = useCallback((e) => {
    e.stopPropagation()
  }, [])
  return (
    <MuiBackdrop
      open={isVisible}
      className={classes.root}
      transitionDuration={{enter: 0, exit: 1000}}
      onClick={handleBackdropClick}
    >
      <Typography variant="h6" className={classes.backdropText} align="center">
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classes.backdropText}
        align="center"
      >
        {description}
      </Typography>
    </MuiBackdrop>
  )
}
