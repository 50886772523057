import {useLazyQuery} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  AddressInput,
  ClientType,
  ClientVatRegistered,
  CountryVatRatesQuery,
  CountryVatRatesQueryVariables,
  Currency,
  DetailClientPropertiesFragment,
  ExistingCountryCode,
  LocaleCode
} from '../../../../__generated__/schema'
import {useGetClientCountrySelectData} from '../../../../hooks/clientCountryCodes'
import {useClientFormAnchors} from '../../../../hooks/clientFormAnchors'
import {useGetServerIntlSelectData} from '../../../../hooks/getServerIntlSelectData'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {enumToFormSelectItems} from '../../../../utils/forms'
import {
  useIsFormMultiSelectRequired,
  useIsValidEmailList,
  useIsValidURL
} from '../../../../utils/formsValidations'
import {InputBlock, InputRow} from '../../../common'
import {FormAddressFields} from '../../../common/FormAddressFields'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {FormInput, FormMultiSelect} from '../../../visual'
import {COUNTRY_VAT_RATE} from '../graphql'
import {useGetSuperadmins} from './graphql'
import {ClientFormField} from './types'

export interface IClientFormData {
  name: string
  type: string
  countryCode: string
  currency: string
  localeCodes: Array<string>
  companyIdNumber: string
  VATRegistered: string
  TAXId?: string | null
  VATId?: string | null
  VATRate?: string | null
  legalAddress: AddressInput
  [ClientFormField.MAILING_ADDRESS]?: string | null
  [ClientFormField.INVOICE_ADDRESS]?: string | null
  dealerId: string
  [ClientFormField.TERMS_OF_SERVICE_URL]?: string | null
  [ClientFormField.MARKETING_INFORMATION_URL]?: string | null
  [ClientFormField.TICKET_DEFAULT_VAT_RATE]?: string | null
  [ClientFormField.E_COMMERCE_URL]?: string | null
}

export const clientToClientForm = (client: DetailClientPropertiesFragment) => {
  return {
    ...client,
    dealerId: client.dealerId ? `${client.dealerId}` : '',
    [ClientFormField.TICKET_DEFAULT_VAT_RATE]: String(
      client.ticketDefaultVatRate
    )
  }
}

export const clientFromClientForm = (client: IClientFormData) => {
  return {
    ...client,
    type: client.type as ClientType,
    countryCode: client.countryCode as ExistingCountryCode,
    currency: client.currency as Currency,
    VATRegistered: client.VATRegistered as ClientVatRegistered,
    localeCodes: client.localeCodes as Array<LocaleCode>,
    dealerId: parseInt(client.dealerId, 10),
    ticketDefaultVatRate: parseInt(
      client[ClientFormField.TICKET_DEFAULT_VAT_RATE] || '0',
      10
    ),
    eCommerceUrl: client[ClientFormField.E_COMMERCE_URL] || null
  }
}

export const CLIENT_FORM_ID = 'client form'

interface IClientFormProps {
  onSubmit: (data: IClientFormData) => void
  defaultValues?: Partial<IClientFormData>
}

export const ClientForm: React.FC<IClientFormProps> = ({
  onSubmit,
  defaultValues
}: IClientFormProps) => {
  const config = defaultValues ? {defaultValues} : {}
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    watch,
    unregister,
    triggerValidation
  } = useForm<IClientFormData>(config)
  const {t} = useTranslation()
  const clientFormAnchors = useClientFormAnchors()
  const {allLocaleSelectData} = useGetServerIntlSelectData()

  // TODO: use FormField as in other forms
  const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
  const countrySelectData = useGetClientCountrySelectData()
  const isValidEmailList = useIsValidEmailList()
  const isValidURL = useIsValidURL()
  const isValidHttpsUrl = useIsValidURL(true)
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const [countryVatRates, setCountryVatRates] = useState<number[]>([])
  const [getCountryVatRates, {loading: isCountryVatRatesLoading}] =
    useLazyQuery<CountryVatRatesQuery, CountryVatRatesQueryVariables>(
      COUNTRY_VAT_RATE,
      {
        onCompleted: (data) => {
          setCountryVatRates(data.countryVatRates)
          if (defaultValues?.ticketDefaultVatRate) {
            if (
              !data.countryVatRates.includes(
                parseInt(defaultValues.ticketDefaultVatRate, 10)
              )
            ) {
              setValue(ClientFormField.TICKET_DEFAULT_VAT_RATE, '0')
            }
          }
        },
        onError: (error) =>
          defaultErrorHandler(error, t('Error while loading country VAT rates'))
      }
    )
  const selectedCountryCode = watch('countryCode')
  const vatRegistered = watch('VATRegistered')
  const isVatRegistered = vatRegistered !== ClientVatRegistered.None
  useEffect(
    () =>
      isCountryVatRatesLoading ? setShowBackdrop(true) : setShowBackdrop(false),
    [isCountryVatRatesLoading, setShowBackdrop]
  )
  useEffect(() => {
    if (selectedCountryCode) {
      getCountryVatRates({
        variables: {countryCode: selectedCountryCode as ExistingCountryCode}
      })
    }
  }, [getCountryVatRates, selectedCountryCode])
  const {data} = useGetSuperadmins()
  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      id={CLIENT_FORM_ID}
    >
      <InputBlock
        header={clientFormAnchors.general.label}
        id={clientFormAnchors.general.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormSelect<IClientFormData>
              errors={errors}
              key="dealerId"
              name="dealerId"
              fullWidth
              selectOptions={(data?.role.users || []).reduce(
                (res, user) => ({
                  ...res,
                  [`${user.id}`]: [user.username]
                }),
                {}
              )}
              label={t('Dealer ID')}
              validationOptions={{
                required: true
              }}
              required
              register={register}
              watch={watch}
              setValue={setValue}
            />
          ]}
          xs={6}
        />
        <InputRow
          nodes={[
            <FormInput
              key="name"
              label={t('Name', {context: 'object'})}
              placeholder={t('Name', {context: 'object'})}
              type="text"
              name="name"
              inputRef={register({required: true})}
              showRequiredLabel
            />,
            <UncontrolledFormSelect<IClientFormData>
              key="type"
              label={t('Client type')}
              name="type"
              validationOptions={{
                required: true
              }}
              required
              selectOptions={{
                [ClientType.Distributor]: t('Distributor'),
                [ClientType.Operator]: t('Operator')
              }}
              fullWidth
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormSelect<IClientFormData>
              key="country"
              label={t('Country')}
              name="countryCode"
              validationOptions={{
                required: true
              }}
              required
              selectOptions={countrySelectData}
              fullWidth
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
            />,
            <UncontrolledFormSelect<IClientFormData>
              key="currency"
              label={t('Currency')}
              name="currency"
              validationOptions={{
                required: true
              }}
              required
              selectOptions={enumToFormSelectItems(Currency)}
              fullWidth
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormMultiSelect<IClientFormData>
              name="localeCodes"
              register={register}
              selectedKeys={watch('localeCodes')}
              unregister={unregister}
              validationOptions={{
                validate: {
                  isFormMultiSelectRequired
                }
              }}
              errors={errors}
              setValue={setValue}
              key="locales"
              label={t('Locales')}
              selectOptions={allLocaleSelectData}
              showRequiredLabel
            />
          ]}
        />
      </InputBlock>
      <InputBlock
        header={clientFormAnchors.legal.label}
        id={clientFormAnchors.legal.id}
      >
        <InputRow
          nodes={[
            <FormInput
              key="companyIdNumber"
              label={t('Company ID number')}
              placeholder={t('Company ID number')}
              type="text"
              name="companyIdNumber"
              inputRef={register({required: true})}
              showRequiredLabel
            />,
            <UncontrolledFormSelect
              key="VATRegistered"
              label={t('VAT Registered')}
              name="VATRegistered"
              required
              validationOptions={{
                required: true
              }}
              selectOptions={{
                [ClientVatRegistered.Full]: t('Full'),
                [ClientVatRegistered.None]: t('Non VAT payer'),
                [ClientVatRegistered.Part]: t('Part')
              }}
              setValue={setValue}
              register={register}
              watch={watch}
              errors={errors}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormInput
              key="TaxId"
              label={t('TAX ID')}
              placeholder={t('TAX ID')}
              type="text"
              name="TAXId"
              inputRef={register({required: false})}
            />
          ]}
          xs={6}
        />
        <InputRow
          nodes={[
            <FormInput
              key="VatId"
              label={t('VAT ID')}
              placeholder={t('VAT ID')}
              type="text"
              name="VATId"
              inputRef={register({required: false})}
            />,
            ...(isVatRegistered && countryVatRates.length > 0
              ? [
                  <UncontrolledFormSelect<IClientFormData>
                    label={t('Default VAT rate for tickets')}
                    placeholder={t('Default VAT rate for tickets')}
                    key={ClientFormField.TICKET_DEFAULT_VAT_RATE}
                    name={ClientFormField.TICKET_DEFAULT_VAT_RATE}
                    selectOptions={countryVatRates.reduce(
                      (acc, vatRate) => ({
                        ...acc,
                        [String(vatRate)]: t('{{vatRate}} %', {vatRate})
                      }),
                      {}
                    )}
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    watch={watch}
                    validationOptions={{required: true}}
                    required
                    fullWidth
                  />
                ]
              : [])
          ].filter(Boolean)}
          xs={isVatRegistered ? undefined : 6}
        />
        <FormAddressFields register={register} namePrefix="legalAddress" />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IClientFormData>
              key={ClientFormField.TERMS_OF_SERVICE_URL}
              label={t('Terms of service')}
              placeholder={t('Type link here')}
              helperNote={t(
                'Recommended. Customer will be able to visit this link.'
              )}
              type="text"
              name={ClientFormField.TERMS_OF_SERVICE_URL}
              validationOptions={{validate: isValidURL}}
              register={register}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IClientFormData>
              key={ClientFormField.MARKETING_INFORMATION_URL}
              label={t('Marketing and GDPR information for customers')}
              placeholder={t('Type link here')}
              helperNote={t(
                'Recommended. Customer will be able to visit this link.'
              )}
              type="text"
              name={ClientFormField.MARKETING_INFORMATION_URL}
              validationOptions={{validate: isValidURL}}
              register={register}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IClientFormData>
              key={ClientFormField.E_COMMERCE_URL}
              label={t('Ecommerce URL')}
              placeholder={t('Type link here')}
              type="text"
              name={ClientFormField.E_COMMERCE_URL}
              validationOptions={{validate: isValidHttpsUrl}}
              register={register}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />
          ]}
        />
      </InputBlock>
      <InputBlock
        header={clientFormAnchors.contact.label}
        id={clientFormAnchors.contact.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IClientFormData>
              key={ClientFormField.MAILING_ADDRESS}
              label={t('Mailing address')}
              name={ClientFormField.MAILING_ADDRESS}
              register={register}
              validationOptions={{validate: isValidEmailList}}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
              helperNote={t('Please use "," or ";" to separate emails')}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IClientFormData>
              key={ClientFormField.INVOICE_ADDRESS}
              label={t('Invoice address')}
              name={ClientFormField.INVOICE_ADDRESS}
              register={register}
              validationOptions={{validate: isValidEmailList}}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
              helperNote={t('Please use "," or ";" to separate emails')}
            />
          ]}
        />
      </InputBlock>
    </form>
  )
}
