import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Checkbox,
  CheckboxProps,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {Theme} from '../../../../theme'
import {
  BasicTicketListItem,
  IBasicTicketListItemProps
} from '../components/BasicTicketListItem'

interface ITicketListItemWithRemoveButtonProps
  extends IBasicTicketListItemProps {
  className?: string
  onRemoveButtonClick: () => void
  isRemoveButtonDisabled?: boolean
  isRemoveButtonShown?: boolean
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'grid',
    minHeight: 56,
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center'
  }
}))

export const TicketListItemWithRemoveButton: React.FC<ITicketListItemWithRemoveButtonProps> =
  ({
    className,
    onRemoveButtonClick,
    isRemoveButtonDisabled,
    isRemoveButtonShown = true,
    ...rest
  }: ITicketListItemWithRemoveButtonProps) => {
    const classes = useStyles()
    return (
      <div className={cn(classes.root, className)}>
        {isRemoveButtonShown && (
          <IconButton
            onClick={onRemoveButtonClick}
            disabled={isRemoveButtonDisabled}
            color="primary"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
        <BasicTicketListItem {...rest} />
      </div>
    )
  }

interface ITicketListItemWithCheckboxProps extends IBasicTicketListItemProps {
  checkBoxProps?: CheckboxProps
}

export const TicketListItemWithCheckbox: React.FC<ITicketListItemWithCheckboxProps> =
  ({
    checkBoxProps,
    primaryLabel,
    secondaryLabel,
    priceLabel,
    priceBeforeDiscountLabel
  }: ITicketListItemWithCheckboxProps) => (
    <ListItem
      sx={{
        minHeight: 40,
        p: 0,
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
        '&:last-child': {
          borderBottom: 0
        }
      }}
      secondaryAction={
        <Box sx={{alignSelf: 'center', textAlign: 'right'}}>
          <Typography variant="body2">{priceLabel}</Typography>
          {priceBeforeDiscountLabel && (
            <Typography
              sx={{textDecoration: 'line-through'}}
              variant="caption"
              color="textSecondary"
            >
              {priceBeforeDiscountLabel}
            </Typography>
          )}
        </Box>
      }
    >
      <ListItemIcon>
        <Checkbox {...checkBoxProps} color="primary" />
      </ListItemIcon>
      <ListItemText
        sx={{overflowWrap: 'anywhere'}}
        primary={primaryLabel}
        primaryTypographyProps={{variant: 'subtitle2'}}
        secondary={secondaryLabel}
        secondaryTypographyProps={{
          variant: 'caption',
          color: 'textSecondary'
        }}
      />
    </ListItem>
  )
