import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DivisionsWithSettingsQuery,
  DivisionsWithSettingsQueryVariables
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useGetLightweightClientShows} from '../graphql'
import {DIVISIONS_WITH_SETTINGS, useCreateTour} from './graphql'
import {TourForm} from './TourForm'
import {ITourForm, TourFormField} from './types'
import {transformTourFormToInput} from './utils'

const CREATE_TOUR_FORM_ID = 'createTourForm'

interface ICreateTourDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateTourDrawer: React.FC<ICreateTourDrawerProps> = ({
  isOpen,
  onClose
}: ICreateTourDrawerProps) => {
  const {t} = useTranslation()
  const [skip, setSkip] = useState(!isOpen)
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  const createTour = useCreateTour()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {clientShows} = useGetLightweightClientShows(
    {
      paginationInput: {offset: 0, limit: 500}
    },
    skip
  )
  const {
    data: divisionsData,
    loading: divisionsLoading,
    error: divisionsError
  } = useQuery<DivisionsWithSettingsQuery, DivisionsWithSettingsQueryVariables>(
    DIVISIONS_WITH_SETTINGS,
    {skip}
  )
  const history = useHistory()
  const handleSubmit = useCallback(
    async (formData: ITourForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await createTour(transformTourFormToInput(formData))
        addInfoNotification(t('Tour created'))
        if (data) {
          history.replace(routeTo.admin.tours.detail(data.createTour.id))
        }
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating tour'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createTour,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create tour')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_TOUR_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <RenderOnData<DivisionsWithSettingsQuery>
          data={divisionsData}
          loading={divisionsLoading}
          error={divisionsError}
          errorMessage={t<string>('Could not load divisions')}
          dataCondition={(data) => Array.isArray(data?.divisions)}
        >
          {({divisions}) => (
            <TourForm
              formId={CREATE_TOUR_FORM_ID}
              onSubmit={handleSubmit}
              clientShows={clientShows}
              divisions={divisions}
              defaultValues={{
                [TourFormField.Duration]: '60'
              }}
            />
          )}
        </RenderOnData>
      </DrawerTemplate>
    </Drawer>
  )
}
