import {useCallback} from 'react'
import {Translated} from '../__generated__/schema'
import {useClientLocaleCode} from './getLocales'

export const useGetClientLocaleTranslation = () => {
  const clientLocaleCode = useClientLocaleCode()
  return useCallback(
    (translated: Translated) =>
      translated[clientLocaleCode] ||
      Object.values(translated).filter(Boolean)[0] ||
      null,
    [clientLocaleCode]
  )
}
