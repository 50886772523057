import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@mui/material'
import React, {useState} from 'react'
import {LocaleCode, ShowTranslation} from '../../../__generated__/schema'
import {useTranslateLocaleCode} from '../../../hooks/translateLocales'
import {useUserInfo} from '../../../utils/auth'
import {DrawerSection, IDrawerSectionProps} from '../DrawerUtils'

type EventPick = {
  show: {
    translations: Pick<
      ShowTranslation,
      'localeCode' | 'title' | 'description'
    >[]
  }
}

interface IOverviewDrawerSectionProps<T extends EventPick> {
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
  event: T
}

export const OverviewDrawerSection = <T extends EventPick>({
  drawerSectionProps,
  event
}: IOverviewDrawerSectionProps<T>) => {
  const {user} = useUserInfo()
  const [expanded, setExpanded] = useState<LocaleCode | null>(user!.localeCode)
  const translateLocaleCode = useTranslateLocaleCode()

  return (
    <DrawerSection {...drawerSectionProps}>
      {event.show.translations.map((translation) => (
        <Accordion
          key={translation.localeCode}
          variant="outlined"
          expanded={expanded === translation.localeCode}
          onChange={(event, isExpanded) => {
            setExpanded(isExpanded ? translation.localeCode : null)
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2">
              {translateLocaleCode(translation.localeCode)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Typography variant="body2">{translation.title}</Typography>
              </Grid>
              <Grid item>
                {/* Note: XSS check is handled on backend */}
                {translation.description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: translation.description!
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </DrawerSection>
  )
}
