import {Paper, Typography} from '@mui/material'
import React from 'react'

const endChars = '...'
const titleMaxLength = 60
const descriptionMaxLength = 160

interface IOpenGraphPreviewProps {
  imageUrl?: string
  title: string
  description?: string
  url: string
}

export const OpenGraphPreview: React.FC<IOpenGraphPreviewProps> = ({
  imageUrl,
  title,
  description,
  url
}: IOpenGraphPreviewProps) => (
  <Paper
    variant="outlined"
    sx={{px: 2, py: 1.5, display: 'flex', flexDirection: 'column', gap: 0.5}}
  >
    {imageUrl && (
      <img
        src={imageUrl}
        style={{width: '100%', height: 214, objectFit: 'cover'}}
      />
    )}
    <Typography variant="subtitle2">
      {title.length > titleMaxLength
        ? title.substr(0, 60).concat(endChars)
        : title}
    </Typography>
    {description && (
      <Typography variant="caption" color="textSecondary">
        {description.length > descriptionMaxLength
          ? description.substr(0, 160).concat(endChars)
          : description}
      </Typography>
    )}
    <Typography variant="caption" color="textSecondary">
      {url}
    </Typography>
  </Paper>
)
