import * as Sentry from '@sentry/browser'

import {config} from '../config'

export type SentryEventId = string

// Temporary workaroud so everyone does not need to setup Sentry accout
// or till we do not have common account
const sentryEnabled = !!config.sentryDSN

export const initSentry = () =>
  sentryEnabled &&
  Sentry.init({
    dsn: config.sentryDSN,
    release: `${config.frontendProjectName}-${config.frontendProjectVersion}`,
    environment: process.env.NODE_ENV
  })

export const reportErrorToSentry = (
  error: any,
  errorInfo: any,
  onLogError?: (eventId: SentryEventId) => any
) => {
  sentryEnabled &&
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      onLogError && onLogError(eventId)
    })
}

export const getSentry = () => Sentry
