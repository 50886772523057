import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {
  useIsIntegerWithMaxBoundary,
  useIsPositiveInteger
} from '../../utils/formsValidations'
import {useObjectsActions} from '../redux/objects/actions'
import {IZoneStateValue} from '../redux/objects/types'
import {usePlaceholderAndValue} from './placeholderAndValue'
import {TextFieldWithValidations} from './TextFieldWithValidations'

interface ICapacityTextFieldProps {
  selectedZones: IZoneStateValue[]
}

export const CapacityTextField: React.FC<ICapacityTextFieldProps> = ({
  selectedZones
}: ICapacityTextFieldProps) => {
  const {t} = useTranslation()
  const {updateObjects} = useObjectsActions()
  const isPositiveInteger = useIsPositiveInteger()
  const isIntegerWithMaxBoundary = useIsIntegerWithMaxBoundary(20000)
  const handleDebouncedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedNumber = parseFloat(e.target.value)
    if (!isNaN(parsedNumber)) {
      updateObjects(
        selectedZones.map((object) => ({
          ...object,
          config: {
            ...object.config,
            capacity: parsedNumber
          }
        }))
      )
    }
  }
  const {placeholder, value} = usePlaceholderAndValue({
    selectedObjects: selectedZones,
    iteratee: 'config.capacity'
  })
  return (
    <TextFieldWithValidations
      label={t('Capacity')}
      type="number"
      defaultValue={value}
      placeholder={placeholder}
      helperText={t('Maximum 20 000.')}
      variant="outlined"
      onChange={handleDebouncedChange}
      inputProps={{
        min: 1,
        max: 20000
      }}
      fullWidth
      validationOptions={{
        isRequired: true,
        validators: {
          isPositiveInteger,
          isIntegerWithMaxBoundary
        }
      }}
    />
  )
}
