import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {FieldErrors, FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {DiscountType} from '../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../hooks/effectiveClientCurrencySignInputProps'
import {Theme} from '../../../../theme'
import {
  useIsNonNegativeNumber,
  useIsPercentValue
} from '../../../../utils/formsValidations'
import {FormValidationError} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {useHasError} from '../../../form/utils'

import {DiscountFormField, IDiscountForm} from './types'

const useStyles = makeStyles<Theme>(() => ({
  hidden: {
    display: 'none'
  },
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 205
  }
}))

interface ITypeRadioGroupProps {
  defaultValues?: Partial<IDiscountForm>
  register: FormContextValues<IDiscountForm>['register']
  setValue: FormContextValues<IDiscountForm>['setValue']
  watch: FormContextValues<IDiscountForm>['watch']
  triggerValidation: FormContextValues<IDiscountForm>['triggerValidation']
  errors: FieldErrors<IDiscountForm>
}

export const TypeRadioGroup: React.FC<ITypeRadioGroupProps> = ({
  register,
  setValue,
  defaultValues,
  watch,
  triggerValidation,
  errors
}: ITypeRadioGroupProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const onRadioGroupChange = useCallback(
    (_event, value) => {
      setValue(DiscountFormField.TYPE, value, true)
    },
    [setValue]
  )
  const typeDefaultValue = defaultValues
    ? defaultValues[DiscountFormField.TYPE]
    : undefined
  const isNonNegativeNumber = useIsNonNegativeNumber()
  const isPercentValue = useIsPercentValue()

  const type = watch(DiscountFormField.TYPE)
  const hasError = useHasError<IDiscountForm>(errors, DiscountFormField.TYPE)
  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  return (
    <FormControl error={hasError}>
      <select
        name={DiscountFormField.TYPE}
        defaultValue={typeDefaultValue}
        ref={register({required: true})}
        className={classes.hidden}
      >
        <option />
        <option value={DiscountType.Percentage}>
          {DiscountType.Percentage}
        </option>
        <option value={DiscountType.FixedAmount}>
          {DiscountType.FixedAmount}
        </option>
      </select>
      <FormLabel>{t('Type')}</FormLabel>
      <FormValidationError<IDiscountForm>
        errors={errors}
        fieldName={DiscountFormField.TYPE}
      />
      <RadioGroup onChange={onRadioGroupChange} defaultValue={typeDefaultValue}>
        <FormControlLabel
          control={<Radio color="primary" />}
          label={
            <Typography variant="body1">{t('Percentage discount')}</Typography>
          }
          value={DiscountType.Percentage}
        />
        <div
          className={
            type !== DiscountType.Percentage ? classes.hidden : classes.valueBox
          }
        >
          <UncontrolledFormTextInput<IDiscountForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            validationOptions={{
              required: type === DiscountType.Percentage,
              validate: isPercentValue
            }}
            required={type === DiscountType.Percentage}
            name={DiscountFormField.PERCENTAGE_TYPE_VALUE}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
        </div>
        <FormControlLabel
          control={<Radio color="primary" />}
          label={
            <Typography variant="body1">
              {t('Fixed amount discount')}
            </Typography>
          }
          value={DiscountType.FixedAmount}
        />
        <div
          className={
            type !== DiscountType.FixedAmount
              ? classes.hidden
              : classes.valueBox
          }
        >
          <UncontrolledFormTextInput<IDiscountForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            InputProps={currencySignInputProps}
            validationOptions={{
              required: type === DiscountType.FixedAmount,
              validate: isNonNegativeNumber
            }}
            required={type === DiscountType.FixedAmount}
            name={DiscountFormField.FIXED_TYPE_VALUE}
          />
        </div>
      </RadioGroup>
    </FormControl>
  )
}
