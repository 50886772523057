import {Box, Button, Card, Grid, Step, StepLabel, Stepper} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useMemo, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {BottomBackButton} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {useNotifications} from '../../../../context/notifications'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'

import {useInviteUsers} from './graphql'
import {INVITE_USERS_FORM_ID, InviteUsersForm} from './InviteUsersForm'

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    width: '400px',
    backgroundColor: `${theme.palette.background.default}!important`
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  contentWrapper: {
    width: '100%',
    paddingBottom: theme.spacing(3)
  }
}))

const isFirstStep = (activeStep: number) => {
  return activeStep === 0
}

const isLastStep = (activeStep: number, steps: Array<string>) => {
  return activeStep === steps.length - 1
}

export const InviteUsers: React.FC = () => {
  const {addInfoNotification} = useNotifications()
  const {t} = useTranslation()
  const history = useHistory()
  const inviteUsers = useInviteUsers()
  const defaultErrorHandler = useDefaultErrorHandler()

  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const formProps = useForm()
  const {getValues} = formProps

  const steps = useMemo(() => [t('Choose users'), t('Select roles')], [t])

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [])

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [setActiveStep])

  const onInvite = useCallback(async () => {
    const data = getValues()
    const {roleIds, ...emails} = data
    try {
      await inviteUsers(Object.values(emails), roleIds || [])
      history.push(routeTo.admin.users.home())
      addInfoNotification(t('Users were invited'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while inviting users'))
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    getValues,
    history,
    inviteUsers,
    t
  ])

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Invite users')} />}
      footer={
        <Footer>
          {isFirstStep(activeStep) && <BottomBackButton />}
          {!isLastStep(activeStep, steps) && (
            <Button
              variant="contained"
              color="primary"
              form={INVITE_USERS_FORM_ID}
              type="submit"
              className={classes.button}
            >
              {t('Next')}
            </Button>
          )}
          {!isFirstStep(activeStep) && (
            <Button onClick={handleBack} className={classes.button}>
              {t('Back')}
            </Button>
          )}
          {isLastStep(activeStep, steps) && (
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={onInvite}
            >
              {t('Invite')}
            </Button>
          )}
        </Footer>
      }
    >
      <CenteredLayout>
        <FormContext {...formProps}>
          <Grid container direction="column" alignItems="center">
            <Grid container direction="row" justifyContent="center">
              <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            <Box className={classes.contentWrapper}>
              <Card>
                <InviteUsersForm
                  onSubmit={handleNext}
                  activeStep={activeStep}
                />
              </Card>
            </Box>
          </Grid>
        </FormContext>
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
