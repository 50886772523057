import {Box, Divider, Typography} from '@mui/material'
import {isNil} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {GetDiscountQuery} from '../../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {InputBlockWithoutSpacings} from '../../../../common'

interface IRowProps {
  label: string
  subLabel: string
}

const Row = ({label, subLabel}: IRowProps) => (
  <Box paddingY={1} paddingX={3}>
    <Typography variant="subtitle2">{label}</Typography>
    <Typography variant="caption" color="textSecondary">
      {subLabel}
    </Typography>
  </Box>
)

interface IRestrictionsBlockProps {
  label: string
  blockId: string
  discount: GetDiscountQuery['discount']
}

export const RestrictionsBlock: React.FC<IRestrictionsBlockProps> = ({
  label,
  blockId,
  discount
}: IRestrictionsBlockProps) => {
  const {t} = useTranslation()
  const translateSellingChannel = useTranslateSellingChannel()
  return (
    <InputBlockWithoutSpacings header={label} blockId={blockId}>
      <Row
        label={t('Allowed divisions')}
        subLabel={discount.divisions.map(({name}) => name).join(', ')}
      />
      <Divider />
      <Row
        label={t('Allowed selling channels')}
        subLabel={discount.sellingChannels
          .map((channel) => translateSellingChannel(channel))
          .join(', ')}
      />
      {!isNil(discount.maxUsageLimitPerOrder) && (
        <>
          <Divider />
          <Row
            label={t('Usage limit per order')}
            subLabel={t(
              'The maximum number of discounted items per order is {{limit}}.',
              {limit: discount.maxUsageLimitPerOrder}
            )}
          />
        </>
      )}
    </InputBlockWithoutSpacings>
  )
}
