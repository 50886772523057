import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventSeatType,
  NarrowTicketItemPropertiesFragment,
  NarrowTourItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple
} from '../../../../common'
import {isTicketItem, isTourItem} from '../../types'

interface IInformationTicketInfoSectionProps {
  item: NarrowTicketItemPropertiesFragment | NarrowTourItemPropertiesFragment
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const InformationTicketInfoSection: React.FC<IInformationTicketInfoSectionProps> =
  ({item, drawerSectionProps}: IInformationTicketInfoSectionProps) => {
    const {t} = useTranslation()
    const {formatDateNumeric, formatTime} = useDateTimeFormatters()
    const getUserLocaleTranslation = useGetUserLocaleTranslation()
    return (
      <DrawerSection {...drawerSectionProps}>
        <Typography variant="subtitle2" sx={{px: 2, pt: 2}}>
          {t('Ticket')}
        </Typography>
        {isTicketItem(item) && (
          <SectionGrid>
            {item.eventSeat.label && (
              <SectionGridCouple label={t<string>('Seat')}>
                {item.eventSeat.label}
              </SectionGridCouple>
            )}
            {item.eventSeat.row && (
              <SectionGridCouple label={t<string>('Row')}>
                {item.eventSeat.row}
              </SectionGridCouple>
            )}
            {item.eventSeat.section && (
              <SectionGridCouple label={t<string>('Section')}>
                {item.eventSeat.section}
              </SectionGridCouple>
            )}
            {item.eventSeat.floor && (
              <SectionGridCouple label={t<string>('Floor')}>
                {item.eventSeat.floor}
              </SectionGridCouple>
            )}
            <SectionGridCouple label={t<string>('Type')}>
              {item.eventSeat.type === EventSeatType.Seat
                ? t('Reserved seating')
                : t('Free sitting or standing')}
            </SectionGridCouple>
          </SectionGrid>
        )}
        {isTourItem(item) && (
          <SectionGrid>
            <SectionGridCouple label={t('Name')}>
              {item.admissionType.name}
            </SectionGridCouple>
            <SectionGridCouple label={t('Attendees')}>
              {item.attendeesCount}
            </SectionGridCouple>
            {item.admissionType.internalDescription && (
              <SectionGridCouple label={t('Internal description')}>
                {item.admissionType.internalDescription}
              </SectionGridCouple>
            )}
          </SectionGrid>
        )}
        <Typography variant="subtitle2" sx={{px: 2}}>
          {isTourItem(item) ? t('Time slot') : t('Event')}
        </Typography>
        <SectionGrid>
          <SectionGridCouple label={t('Starts at')}>
            {isTourItem(item)
              ? [
                  formatDateNumeric(new Date(item.tourTimeSlot.startsAt)),
                  formatTime(new Date(item.tourTimeSlot.startsAt))
                ].join(' • ')
              : isTicketItem(item) &&
                [
                  formatDateNumeric(new Date(item.eventSeat.event.startsAt)),
                  formatTime(new Date(item.eventSeat.event.startsAt))
                ].join(' • ')}
          </SectionGridCouple>
          <SectionGridCouple label={t('Title')}>
            {isTourItem(item)
              ? getUserLocaleTranslation(item.tourTimeSlot.names)
              : isTicketItem(item) &&
                getUserLocaleTranslation(item.eventSeat.event.names)}
          </SectionGridCouple>
          <SectionGridCouple label={t('Venue')}>
            {isTourItem(item)
              ? [
                  item.tourTimeSlot.venue.address.town,
                  item.tourTimeSlot.venue.name
                ]
                  .filter(Boolean)
                  .join(' • ')
              : isTicketItem(item) &&
                [
                  item.eventSeat.event.venue.address.town,
                  item.eventSeat.event.venue.name
                ]
                  .filter(Boolean)
                  .join(' • ')}
            {isTicketItem(item) && (
              <SectionGridCouple label={t('Auditorium')}>
                {item.eventSeat.event.auditorium.name}
              </SectionGridCouple>
            )}
          </SectionGridCouple>
        </SectionGrid>
      </DrawerSection>
    )
  }
