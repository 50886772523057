import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  externalLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

interface IExternalLinkProps {
  children: React.ReactNode
  className?: string
  to: string
}

export const ExternalLink: React.FC<IExternalLinkProps> = ({
  children,
  className,
  to
}: IExternalLinkProps) => {
  const classes = useStyles()

  return (
    <a
      className={cn(classes.externalLink, className)}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
