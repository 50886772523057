import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateEventCategoryDrawer} from './CreateEventCategoryDrawer'
import {EventCategoriesList} from './EventCategoriesList'
import {UpdateEventCategoryDrawer} from './UpdateEventCategoryDrawer'

export const EventCategoriesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateEventCategoryDrawerOpen,
    setTrue: openCreateEventCategoryDrawer,
    setFalse: closeCreatEventCategoryDrawer
  } = useBooleanState(false)
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.eventCategories.index()),
    [history]
  )
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Event categories')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <EventCategoriesList />
        <CreateEventCategoryDrawer
          isOpen={isCreateEventCategoryDrawerOpen}
          onClose={closeCreatEventCategoryDrawer}
        />
        {P([PermissionCode.CreateEventCategory]) && (
          <CreateFab
            classes={fabClasses}
            onClick={openCreateEventCategoryDrawer}
          />
        )}
        {P([PermissionCode.UpdateEventCategory]) && (
          <Route
            path={routeTo.admin.eventCategories.edit(':eventCategoryId')}
            exact
          >
            <UpdateEventCategoryDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
