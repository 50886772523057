import {Box} from '@mui/material'
import React from 'react'
import {GetAvailableProductGroupsQuery} from '../../../../../__generated__/schema'
import {CashDeskProductGroupChip} from '../../../../common/CashDeskProductGroupChip'
import {getProductGroupIcon} from '../../../../common/productGroupIcon'

interface IProductGroupChipsProps {
  productGroups: GetAvailableProductGroupsQuery['productGroups']
  selectedProductGroup: number | undefined
  onProductGroupChange: (groupId: number | undefined) => void
}

export const ProductGroupChips: React.FC<IProductGroupChipsProps> = ({
  productGroups,
  selectedProductGroup,
  onProductGroupChange
}: IProductGroupChipsProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      overflowX: 'auto',
      overflowY: 'hidden'
    }}
  >
    {productGroups.map((productGroup) => (
      <CashDeskProductGroupChip
        key={productGroup.id}
        label={productGroup.name}
        hexColor={productGroup.color}
        Icon={
          productGroup.icon ? getProductGroupIcon(productGroup.icon) : undefined
        }
        isActive={productGroup.id === selectedProductGroup}
        onClick={() => onProductGroupChange(productGroup.id)}
      />
    ))}
  </Box>
)
