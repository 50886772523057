import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CommonSettingsScript} from '../../../../../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {ScriptForm} from './ScriptForm'
import {IScriptForm, ScriptFormField} from './types'

const EDIT_SCRIPT_FORM_ID = 'editScriptForm'

interface IEditScriptDialogProps {
  script: CommonSettingsScript | null
  onClose: () => void
  onSave: (data: IScriptForm) => void
}

export const EditScriptDialog: React.FC<IEditScriptDialogProps> = ({
  script,
  onClose,
  onSave
}: IEditScriptDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={Boolean(script)}
      sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit script')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        {script && (
          <ScriptForm
            formId={EDIT_SCRIPT_FORM_ID}
            defaultValues={{
              [ScriptFormField.Id]: script.id,
              [ScriptFormField.Content]: script.content,
              [ScriptFormField.Src]: script.src,
              [ScriptFormField.Location]: script.location
            }}
            onSubmit={onSave}
          />
        )}
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={EDIT_SCRIPT_FORM_ID}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
