import {
  DataGridProProps,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro'
import {clsx} from 'clsx'
import {isNil} from 'lodash'
import React, {MutableRefObject, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {AdmissionTypesStatisticsQuery} from '../../../../../__generated__/schema'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../../hooks/translateCurrencies'
import {
  DataGridTable,
  DecimalCellRenderer,
  replaceDotWithCommaFormatter
} from '../../../../common/DataGridTable'

const getCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''

interface ITurnoversTableProps {
  data: AdmissionTypesStatisticsQuery | undefined
  apiRef?: MutableRefObject<GridApiPro>
  loading?: boolean
  paginationProps?: Pick<
    DataGridProProps,
    | 'rowCount'
    | 'paginationModel'
    | 'paginationMode'
    | 'onPaginationModelChange'
    | 'pageSizeOptions'
  >
  dataGridProps?: Pick<
    DataGridProProps,
    | 'disableColumnMenu'
    | 'autoHeight'
    | 'disableRowSelectionOnClick'
    | 'pagination'
    | 'hideFooter'
  >
}

export const TurnoversTable: React.FC<ITurnoversTableProps> = ({
  data,
  apiRef,
  loading,
  paginationProps,
  dataGridProps
}: ITurnoversTableProps) => {
  const {t} = useTranslation()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        sortable: false,
        minWidth: 300,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'capacityDecreaseCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Starting quantity'),
        field: 'startingQuantity',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'revenue',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'revenueTicketCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'revenueAttendeesCount',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'saleSumOnRetailChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'saleTicketCountOnRetailChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'saleAttendeesCountOnRetailChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'saleSumOnECommerceChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'saleTicketCountOnECommerceChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'saleAttendeesCountOnECommerceChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'refundSumOnRetailChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'refundTicketCountOnRetailChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'refundAttendeesCountOnRetailChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'refundSumOnECommerceChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Tickets'),
        field: 'refundTicketCountOnECommerceChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Attendees'),
        field: 'refundAttendeesCountOnECommerceChannel',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      }
    ],
    [t, translatedEffectiveClientCurrencySign]
  )
  return (
    <DataGridTable
      sx={{
        [`& .${gridClasses.withBorderColor}`]: {
          borderColor: 'divider'
        },
        [`& .${gridClasses.columnSeparator}`]: {
          color: 'divider'
        },
        '& .bold': {fontWeight: 'bold'}
      }}
      apiRef={apiRef}
      columns={columns}
      loading={loading}
      rows={data?.admissionTypesStatistics.items || []}
      experimentalFeatures={{columnGrouping: true}}
      columnHeaderHeight={32}
      disableRowSelectionOnClick
      getRowId={(row) => row.admissionTypeId}
      initialState={{
        pinnedColumns: {left: ['name']},
        pagination: paginationProps
          ? {}
          : {
              paginationModel: {
                pageSize: data ? data.admissionTypesStatistics.items.length : 5
              }
            }
      }}
      columnGroupingModel={[
        {
          groupId: t('Admission type'),
          children: [{field: 'name'}],
          headerAlign: 'left'
        },
        {
          groupId: t('Summary'),
          children: [
            {field: 'revenue'},
            {field: 'revenueTicketCount'},
            {field: 'revenueAttendeesCount'}
          ],
          headerAlign: 'left'
        },
        {
          groupId: t('Sales'),
          children: [
            {
              groupId: t('Retail'),
              children: [
                {field: 'saleSumOnRetailChannel'},
                {field: 'saleTicketCountOnRetailChannel'},
                {field: 'saleAttendeesCountOnRetailChannel'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Ecommerce'),
              children: [
                {field: 'saleSumOnECommerceChannel'},
                {field: 'saleTicketCountOnECommerceChannel'},
                {field: 'saleAttendeesCountOnECommerceChannel'}
              ],
              headerAlign: 'left'
            }
          ],
          headerAlign: 'left'
        },
        {
          groupId: t('Refunds'),
          children: [
            {
              groupId: t('Retail'),
              children: [
                {field: 'refundSumOnRetailChannel'},
                {field: 'refundTicketCountOnRetailChannel'},
                {field: 'refundAttendeesCountOnRetailChannel'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Ecommerce'),
              children: [
                {field: 'refundSumOnECommerceChannel'},
                {field: 'refundTicketCountOnECommerceChannel'},
                {field: 'refundAttendeesCountOnECommerceChannel'}
              ],
              headerAlign: 'left'
            }
          ],
          headerAlign: 'left'
        }
      ]}
      pinnedRows={{
        bottom: [
          {
            admissionTypeId: 0,
            name: t('Summary'),
            revenue: data?.admissionTypesStatistics.totals.revenue,
            revenueTicketCount:
              data?.admissionTypesStatistics.totals.revenueTicketCount,
            revenueAttendeesCount:
              data?.admissionTypesStatistics.totals.revenueAttendeesCount,
            saleSumOnRetailChannel:
              data?.admissionTypesStatistics.totals.saleSumOnRetailChannel,
            saleTicketCountOnRetailChannel:
              data?.admissionTypesStatistics.totals
                .saleTicketCountOnRetailChannel,
            saleAttendeesCountOnRetailChannel:
              data?.admissionTypesStatistics.totals
                .saleAttendeesCountOnRetailChannel,
            saleSumOnECommerceChannel:
              data?.admissionTypesStatistics.totals.saleSumOnECommerceChannel,
            saleTicketCountOnECommerceChannel:
              data?.admissionTypesStatistics.totals
                .saleTicketCountOnECommerceChannel,
            saleAttendeesCountOnECommerceChannel:
              data?.admissionTypesStatistics.totals
                .saleAttendeesCountOnECommerceChannel,
            refundSumOnRetailChannel:
              data?.admissionTypesStatistics.totals.refundSumOnRetailChannel,
            refundTicketCountOnRetailChannel:
              data?.admissionTypesStatistics.totals
                .refundTicketCountOnRetailChannel,
            refundAttendeesCountOnRetailChannel:
              data?.admissionTypesStatistics.totals
                .refundAttendeesCountOnRetailChannel,
            refundSumOnECommerceChannel:
              data?.admissionTypesStatistics.totals.refundSumOnECommerceChannel,
            refundTicketCountOnECommerceChannel:
              data?.admissionTypesStatistics.totals
                .refundTicketCountOnECommerceChannel,
            refundAttendeesCountOnECommerceChannel:
              data?.admissionTypesStatistics.totals
                .refundAttendeesCountOnECommerceChannel
          }
        ]
      }}
      {...(paginationProps
        ? {
            ...paginationProps,
            disableColumnMenu: true
          }
        : {
            pageSizeOptions: [
              data ? data.admissionTypesStatistics.items.length : 5
            ]
          })}
      {...dataGridProps}
    />
  )
}
