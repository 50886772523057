import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Chip, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {PRIMARY_100_COLOR, Theme} from '../../../../../theme'

interface IProductListItemWithRemoveButtonProps {
  className?: string
  onRemoveButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  title: string
  price: React.ReactNode
  quantity: number
  description: string
  onClick: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "removeButtonBox title        price"
      "removeButtonBox description  price"
    `,
    gridTemplateColumns: `auto 1fr auto`,
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    '&:hover': {
      background: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  title: {
    gridArea: 'title'
  },
  removeButtonBox: {
    gridArea: 'removeButtonBox'
  },
  price: {
    gridArea: 'price'
  },
  description: {
    gridArea: 'description'
  },
  chip: {
    paddingRight: theme.spacing(0.5)
  },
  chipRoot: {
    background: PRIMARY_100_COLOR,
    color: theme.palette.primary.main,
    height: 'auto'
  },
  chipLabelSmall: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  }
}))

export const ProductListItemWithRemoveButton: React.FC<IProductListItemWithRemoveButtonProps> =
  ({
    className,
    onRemoveButtonClick,
    title,
    quantity,
    price,
    description,
    onClick
  }: IProductListItemWithRemoveButtonProps) => {
    const classes = useStyles()
    return (
      <div className={cn(classes.root, className)} onClick={onClick}>
        <div className={classes.removeButtonBox}>
          <IconButton
            onClick={onRemoveButtonClick}
            color="primary"
            edge="start"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </div>
        <Typography variant="subtitle2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.price}>
          {price}
        </Typography>
        <div className={classes.description}>
          <span className={classes.chip}>
            <Chip
              color="primary"
              label={`${quantity}x`}
              size="small"
              classes={{
                root: classes.chipRoot,
                labelSmall: classes.chipLabelSmall
              }}
            />
          </span>
          <Typography variant="caption">{description}</Typography>
        </div>
      </div>
    )
  }
