import {Drawer, drawerClasses} from '@mui/material'
import {omitBy} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CommonSettingsScript} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {useWebsiteParams} from '../../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {
  useUpdateWebsiteCommonSettings,
  useWebsiteCommonSettings
} from '../../graphql'
import {Content} from './Content'

interface IUpdateWebsiteScriptDrawerProps {
  onExited: () => void
}

export const UpdateWebsiteScriptDrawer: React.FC<IUpdateWebsiteScriptDrawerProps> =
  ({onExited}: IUpdateWebsiteScriptDrawerProps) => {
    const {t} = useTranslation()
    const {websiteId} = useWebsiteParams()
    const updateWebsiteCommonSettings = useUpdateWebsiteCommonSettings()
    const {data, loading, error} = useWebsiteCommonSettings(
      websiteId,
      isNaN(websiteId)
    )
    const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
      useMutationAssistanceHooks()
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [scripts, setScripts] = useState<CommonSettingsScript[]>([])
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const {
      state: isCloseDialogOpen,
      setTrue: openCloseDialog,
      setFalse: closeCloseDialog
    } = useBooleanState(false)
    const handleDrawerClose = useCallback(() => {
      if (isDirty) {
        openCloseDialog()
      } else {
        closeDrawer()
      }
    }, [closeDrawer, isDirty, openCloseDialog])
    const handleScriptsChange = useCallback(
      (scripts) => setScripts(scripts),
      [setScripts]
    )
    const handleSaveChanges = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await updateWebsiteCommonSettings({
          websiteId,
          input: {
            scripts: scripts.map((script) => omitBy(script, ['__typename']))
          }
        })
        addInfoNotification(t('Scripts has been updated'))
        closeDrawer()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while updating website common settings')
        )
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      closeDrawer,
      defaultErrorHandler,
      scripts,
      setShowBackdrop,
      t,
      updateWebsiteCommonSettings,
      websiteId
    ])
    useEffect(() => {
      if (!isNaN(websiteId)) {
        openDrawer()
      }
    }, [openDrawer, websiteId])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleDrawerClose}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error && t('Error while loading website common settings')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={handleDrawerClose}
              title={t('Manage website scripts')}
            />
          }
          footer={
            <SaveButton onClick={handleSaveChanges}>{t('Save')}</SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {data && (
            <Content
              defaultScripts={(data.websiteCommonSettings.scripts || []).map(
                (script) => ({
                  id: script.id,
                  content: script.content || undefined,
                  location: script.location,
                  src: script.src || undefined
                })
              )}
              setIsDirty={setIsDirty}
              onScriptsChange={handleScriptsChange}
            />
          )}
        </DrawerTemplate>
        <ConfirmationDialog
          title={t('Unsaved changes')}
          contentText={t(
            'There are unsaved changes. If you would like to save changes, you can keep editing. Discarding changes is permanent action and can’t be undone.'
          )}
          confirmButtonLabel={t('Discard changes')}
          onConfirm={() => {
            closeCloseDialog()
            closeDrawer()
          }}
          cancelButtonLabel={t('Keep editing')}
          onCancel={closeCloseDialog}
          isOpen={isCloseDialogOpen}
        />
      </Drawer>
    )
  }
