import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, Typography} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  GetLightweightDivisionsQuery,
  PermissionCode,
  ProductGroupQuery
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip} from '../../../../common'
import {DataGridTable} from '../../../../common/DataGridTable'
import {COLOR_CONF} from '../../../../constants'
import {useGetLightweightDivisions} from '../../graphql'

const getTableData = (
  productGroup: ProductGroupQuery['productGroup'],
  divisions: GetLightweightDivisionsQuery['divisions']
) =>
  divisions.map((division) => {
    const productGroupAvailability = productGroup.availability.find(
      (pa) => pa.division.id === division.id
    )
    return {
      divisionId: division.id,
      divisionName: division.name,
      productGroupId: productGroup.id,
      isAvailableOnRetailChannel:
        productGroupAvailability?.isAvailableOnRetailChannel || false,
      isAvailableOnECommerceChannel:
        productGroupAvailability?.isAvailableOnECommerceChannel || false
    }
  })

const AvailabilityRenderer = ({isAvailable}: {isAvailable: boolean}) => {
  const {t} = useTranslation()
  return (
    <EntityStateChip
      colorConf={isAvailable ? COLOR_CONF.GREEN : COLOR_CONF.RED}
      label={isAvailable ? t('Enabled') : t('Disabled')}
    />
  )
}

interface IAvailabilitySectionProps {
  id: string
  label: string
  productGroup: ProductGroupQuery['productGroup']
}

export const AvailabilitySection: React.FC<IAvailabilitySectionProps> = ({
  id,
  label,
  productGroup
}: IAvailabilitySectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {divisions} = useGetLightweightDivisions()
  const history = useHistory()
  const handleEditButtonClick = useCallback(
    () =>
      history.push(routeTo.admin.productGroups.availability(productGroup.id)),
    [history, productGroup.id]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Division'),
        field: 'divisionName',
        minWidth: 350,
        sortable: false
      },
      {
        headerName: t('Retail'),
        field: 'isAvailableOnRetailChannel',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: boolean}>
        ) {
          return <AvailabilityRenderer isAvailable={params.value} />
        },
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Ecommerce'),
        field: 'isAvailableOnECommerceChannel',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: boolean}>
        ) {
          return <AvailabilityRenderer isAvailable={params.value} />
        },
        minWidth: 200,
        sortable: false
      }
    ],
    [t]
  )
  return (
    <Box id={id}>
      <Box
        sx={{
          pb: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">{label}</Typography>
        {P([PermissionCode.UpsertProductGroupAvailability]) && (
          <Button
            variant="text"
            color="primary"
            startIcon={<EditIcon />}
            onClick={handleEditButtonClick}
          >
            {t('Edit')}
          </Button>
        )}
      </Box>
      <Box sx={{height: '100%', width: '100%'}}>
        <DataGridTable
          columns={columns}
          rows={getTableData(productGroup, divisions)}
          getRowId={(row) => row.divisionId}
          autoHeight
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          pagination={false}
        />
      </Box>
    </Box>
  )
}
