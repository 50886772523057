import {useTranslation} from 'react-i18next'
import {PassCodeCheckState} from '../__generated__/schema'

export const useTranslatePassCodeCheckState = () => {
  const {t} = useTranslation()
  const translated: Record<PassCodeCheckState, string> = {
    [PassCodeCheckState.Allowed]: t<string>('PassCodeCheckState->Allowed'),
    [PassCodeCheckState.Denied]: t<string>('PassCodeCheckState->Denied'),
    [PassCodeCheckState.Pending]: t<string>('PassCodeCheckState->Pending')
  }
  return (state: PassCodeCheckState) => translated[state]
}
