import {useMutation} from '@apollo/react-hooks'
import {Box} from '@mui/material'
import {groupBy} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiSeatState,
  ChangeEventSeatsAvailabilityMutation,
  ChangeEventSeatsAvailabilityMutationVariables,
  EventTicketType,
  MutationChangeEventZoneSeatsAvailabilityArgs,
  Scalars,
  SeatOptionsEventFragment
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {getFeSeatStateInEventSeatOption} from '../../../../utils/getFeSeatState'
import {getFeZoneStateInEventSeatOptions} from '../../../../utils/getFeZoneState'
import {
  useGetSeatTooltipLabelOnSeatingOptionsPage,
  useGetZoneTooltipLabelOnSeatingOptionsPage,
  useLayoutObjectTooltip
} from '../../../../utils/layoutObjectTooltip'
import {
  AuditoriumLayoutPreview,
  useFeSeatStatesByUuid,
  useFeZoneStatesByUuid,
  useGetTicketTypeForUuid,
  useLayout,
  useTicketTypesByTicketTypeId
} from '../../../common/auditoriumLayoutPreview'
import {
  CHANGE_EVENT_SEATS_AVAILABILITY,
  useChangeEventZoneSeatsAvailability
} from './graphql'
import {SidePanel} from './sidePanel'
import {AggregatedSeatsStateCounts} from './sidePanel/types'

interface IContentProps {
  event: SeatOptionsEventFragment
  eventSeats: Scalars['JSON']
  interruptSubscription: () => void
  resetSubscription: (cartId: null | number) => void
}

export const Content: React.FC<IContentProps> = ({
  event,
  eventSeats
}: IContentProps) => {
  const {seats, zones} = eventSeats
  const {t} = useTranslation()
  const feSeatStatesByUuid = useFeSeatStatesByUuid({
    seats,
    mapApiSeatStateToFeSeatState: getFeSeatStateInEventSeatOption
  })

  const feZoneStatesByUuid = useFeZoneStatesByUuid({
    zones,
    mapZoneApiSeatStatesToFeZoneState: getFeZoneStateInEventSeatOptions
  })

  const ticketTypesByTicketTypeId: {[id: string]: EventTicketType} =
    useTicketTypesByTicketTypeId(event)

  const layout = useLayout(event, ticketTypesByTicketTypeId)

  const getTicketTypeForUuid = useGetTicketTypeForUuid(
    layout,
    ticketTypesByTicketTypeId
  )
  const getZoneTooltipLabel = useGetZoneTooltipLabelOnSeatingOptionsPage()
  const getSeatTooltipLabel = useGetSeatTooltipLabelOnSeatingOptionsPage()

  const {TooltipProps, getMouseEnterHandler, getMouseLeaveHandler} =
    useLayoutObjectTooltip({
      layout: event.auditoriumLayout.layout,
      feSeatStatesByUuid,
      feZoneStatesByUuid,
      getTicketTypeForUuid,
      getZoneTooltipLabel,
      getSeatTooltipLabel
    })
  const [changeEventSeatsAvailability] = useMutation<
    ChangeEventSeatsAvailabilityMutation,
    ChangeEventSeatsAvailabilityMutationVariables
  >(CHANGE_EVENT_SEATS_AVAILABILITY)
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const changeEventZoneSeatsAvailability = useChangeEventZoneSeatsAvailability(
    setShowBackdrop,
    defaultErrorHandler
  )

  const deselectAllZones = useCallback(
    (exceptZoneWithUuid?: string) => {
      const selectedZones = Object.entries(
        zones as {
          [uuid: string]: {
            states: Partial<AggregatedSeatsStateCounts>
            statesBeforeSelected: Partial<AggregatedSeatsStateCounts>
          }
        }
      )
        .filter(([uuid]) => uuid !== exceptZoneWithUuid)
        .filter(([, {states}]) => {
          const count = states[ApiSeatState.SelectedByMe]
          return count && count > 0
        })
      for (const zone of selectedZones) {
        const [uuid, {statesBeforeSelected}] = zone
        const changes = [
          ApiSeatState.Available,
          ApiSeatState.Disabled,
          ApiSeatState.Hidden
        ].reduce(
          (
            acc: Pick<
              MutationChangeEventZoneSeatsAvailabilityArgs,
              'count' | 'toState' | 'fromState'
            >[],
            state
          ) =>
            statesBeforeSelected[state]
              ? [
                  ...acc,
                  {
                    fromState: ApiSeatState.SelectedByMe,
                    toState: state,
                    count: statesBeforeSelected[state]
                  }
                ]
              : acc,
          []
        )
        changeEventZoneSeatsAvailability({eventId: event.id, uuid}, changes)
      }
    },
    [changeEventZoneSeatsAvailability, event.id, zones]
  )

  const deselectAllSeats = useCallback(() => {
    const seatsSelectedByMe = Object.entries(
      seats as {
        [uuid: string]: {
          state: ApiSeatState
          stateBeforeSelected: ApiSeatState
        }
      }
    ).reduce(
      (
        acc: {
          state: ApiSeatState
          stateBeforeSelected: ApiSeatState
          uuid: string
        }[],
        [uuid, data]
      ) =>
        data.state === ApiSeatState.SelectedByMe
          ? [
              ...acc,
              {
                ...data,
                uuid
              }
            ]
          : acc,
      []
    )

    if (seatsSelectedByMe.length) {
      setShowBackdrop(true)
      const selectedSeatsGroupedByStateBeforeSelected = groupBy(
        seatsSelectedByMe,
        'stateBeforeSelected'
      )
      Promise.all(
        Object.entries(selectedSeatsGroupedByStateBeforeSelected).map(
          ([stateBeforeSelected, items]) =>
            changeEventSeatsAvailability({
              variables: {
                eventId: event.id,
                uuids: items.map(({uuid}) => uuid),
                state:
                  (stateBeforeSelected as ApiSeatState) ??
                  ApiSeatState.Available
              }
            })
        )
      )
        .catch((e) => {
          defaultErrorHandler(e, t('Changing event seat availability failed'))
        })
        .finally(() => {
          setShowBackdrop(false)
        })
    }
  }, [
    changeEventSeatsAvailability,
    defaultErrorHandler,
    event.id,
    seats,
    setShowBackdrop,
    t
  ])

  const getSeatClickHandler = useCallback(
    (uuid: string) => {
      const seat = seats[uuid]
      if (
        [
          ApiSeatState.Available,
          ApiSeatState.Disabled,
          ApiSeatState.Hidden,
          ApiSeatState.SelectedByMe
        ].includes(seat.state)
      ) {
        return () => {
          setShowBackdrop(true)
          deselectAllZones()
          changeEventSeatsAvailability({
            variables: {
              eventId: event.id,
              uuids: [uuid],
              state:
                seat.state === ApiSeatState.SelectedByMe
                  ? seat.stateBeforeSelected ?? ApiSeatState.Available
                  : ApiSeatState.SelectedByMe
            }
          })
            .catch((e) => {
              defaultErrorHandler(
                e,
                t('Changing event seat availability failed')
              )
            })
            .finally(() => {
              setShowBackdrop(false)
            })
        }
      }
      return undefined
    },
    [
      changeEventSeatsAvailability,
      defaultErrorHandler,
      deselectAllZones,
      event.id,
      seats,
      setShowBackdrop,
      t
    ]
  )
  const handleSeatsMultiSelect = useCallback(
    (uuids: string[]) => {
      if (uuids.length === 0) {
        return
      }
      const selectedSeats = uuids.map((uuid) => ({
        ...seats[uuid],
        uuid
      }))
      const multiSelectedSeats = selectedSeats.filter(({state}) =>
        [
          ApiSeatState.Available,
          ApiSeatState.Disabled,
          ApiSeatState.Hidden
        ].includes(state)
      )
      deselectAllZones()
      if (multiSelectedSeats.length) {
        setShowBackdrop(true)
        changeEventSeatsAvailability({
          variables: {
            eventId: event.id,
            uuids: multiSelectedSeats.map(({uuid}) => uuid),
            state: ApiSeatState.SelectedByMe
          }
        })
          .catch((e) => {
            defaultErrorHandler(e, t('Changing event seat availability failed'))
          })
          .finally(() => {
            setShowBackdrop(false)
          })
      }
      const seatsSelectedByMe: {
        state: ApiSeatState
        uuid: string
        stateBeforeSelected?: ApiSeatState
      }[] = selectedSeats.filter(
        ({state}) => state === ApiSeatState.SelectedByMe
      )
      if (seatsSelectedByMe.length) {
        const selectedSeatsGroupedByStateBeforeSelected = groupBy(
          seatsSelectedByMe,
          'stateBeforeSelected'
        )
        Promise.all(
          Object.entries(selectedSeatsGroupedByStateBeforeSelected).map(
            ([stateBeforeSelected, items]) =>
              changeEventSeatsAvailability({
                variables: {
                  eventId: event.id,
                  uuids: items.map(({uuid}) => uuid),
                  state:
                    (stateBeforeSelected as ApiSeatState) ??
                    ApiSeatState.Available
                }
              })
          )
        )
          .catch((e) => {
            defaultErrorHandler(e, t('Changing event seat availability failed'))
          })
          .finally(() => {
            setShowBackdrop(false)
          })
      }
    },
    [
      changeEventSeatsAvailability,
      defaultErrorHandler,
      deselectAllZones,
      event.id,
      seats,
      setShowBackdrop,
      t
    ]
  )

  const getZoneClickHandler = useCallback(
    (uuid: string) => {
      const zoneStates = zones[uuid].states
      const zoneStatesBeforeSelected = zones[uuid]
        .statesBeforeSelected as Partial<AggregatedSeatsStateCounts>
      if (
        zoneStates &&
        (zoneStates[ApiSeatState.Available] > 0 ||
          zoneStates[ApiSeatState.Disabled] > 0 ||
          zoneStates[ApiSeatState.Hidden] > 0)
      ) {
        return () => {
          const changes = [
            ApiSeatState.Available,
            ApiSeatState.Disabled,
            ApiSeatState.Hidden
          ].reduce(
            (
              acc: {
                fromState: ApiSeatState
                toState: ApiSeatState
              }[],
              state
            ) =>
              zoneStates[state] > 0
                ? [
                    ...acc,
                    {
                      fromState: state,
                      toState: ApiSeatState.SelectedByMe
                    }
                  ]
                : acc,
            []
          )
          deselectAllSeats()
          deselectAllZones(uuid)
          changeEventZoneSeatsAvailability({eventId: event.id, uuid}, changes)
        }
      }
      if (zoneStates && zoneStates[ApiSeatState.SelectedByMe] > 0) {
        return () => {
          const changes = [
            ApiSeatState.Available,
            ApiSeatState.Disabled,
            ApiSeatState.Hidden
          ].reduce(
            (
              acc: Pick<
                MutationChangeEventZoneSeatsAvailabilityArgs,
                'count' | 'toState' | 'fromState'
              >[],
              state
            ) =>
              zoneStatesBeforeSelected[state]
                ? [
                    ...acc,
                    {
                      fromState: ApiSeatState.SelectedByMe,
                      toState: state,
                      count: zoneStatesBeforeSelected[state]
                    }
                  ]
                : acc,
            []
          )
          deselectAllSeats()
          changeEventZoneSeatsAvailability({eventId: event.id, uuid}, changes)
        }
      }
      return undefined
    },
    [
      changeEventZoneSeatsAvailability,
      deselectAllSeats,
      deselectAllZones,
      event.id,
      zones
    ]
  )
  const handleCancelButtonClick = useCallback(() => {
    deselectAllZones()
    deselectAllSeats()
  }, [deselectAllSeats, deselectAllZones])
  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 360px'
      }}
    >
      <AuditoriumLayoutPreview
        layout={layout}
        eventSeats={eventSeats}
        feSeatStatesByUuid={feSeatStatesByUuid}
        feZoneStatesByUuid={feZoneStatesByUuid}
        getSeatClickHandler={getSeatClickHandler}
        getZoneClickHandler={getZoneClickHandler}
        getLayoutObjectMouseEnterHandler={getMouseEnterHandler}
        getLayoutObjectMouseLeaveHandler={getMouseLeaveHandler}
        TooltipProps={TooltipProps}
        onSeatsMultiSelect={handleSeatsMultiSelect}
      />
      <SidePanel
        event={event}
        eventSeats={eventSeats}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`
        }}
        onCancelButtonClick={handleCancelButtonClick}
      />
    </Box>
  )
}
