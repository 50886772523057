import {useTranslation} from 'react-i18next'
import {EventSeat} from '../__generated__/schema'

export const useGetEventSeatLocation = () => {
  const {t} = useTranslation()
  return (
    eventSeat: Pick<EventSeat, 'label' | 'floor' | 'section' | 'row'>
  ): string =>
    [
      eventSeat.floor && t('Floor: {{floor}}', {floor: eventSeat.floor}),
      eventSeat.section &&
        t('Section: {{section}}', {section: eventSeat.section}),
      eventSeat.row && t('Row: {{row}}', {row: eventSeat.row}),
      eventSeat.label && t('Seat: {{label}}', {label: eventSeat.label})
    ]
      .filter(Boolean)
      .join(' • ')
}
