import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {Box, IconButton} from '@mui/material'
import {gridClasses} from '@mui/x-data-grid'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  WarehouseDocumentItemsFilterInput,
  WarehouseDocumentSource,
  WarehouseDocumentState,
  WarehouseDocumentType
} from '../../../../__generated__/schema'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useTranslateUnit} from '../../../../hooks/translateUnit'
import {useTranslateWarehouseDocumentSource} from '../../../../hooks/translateWarehouseDocumentSource'
import {useTranslateWarehouseDocumentType} from '../../../../hooks/translateWarehouseDocumentType'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useDecimalFormatter,
  usePercentageFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {OptionsChipWithDelete} from '../../../common/OptionsChipWithDelete'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {DivisionChip} from '../components/DivisionChip'
import {ReloadButton} from '../components/ReloadButton'
import {WarehousesChip} from '../components/WarehousesChip'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {useWarehouseDocumentItems} from './graphql'

const IconCellRenderer = ({id}: {id: number}) => {
  const handleIconClick = useCallback(
    () => window.open(routeTo.admin.warehouseDocuments.detail(id), '_blank'),
    [id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IInventoryTransactionsListProps {
  searchFilter: WarehouseDocumentItemsFilterInput
}

export const InventoryTransactionsList: React.FC<IInventoryTransactionsListProps> =
  ({searchFilter}: IInventoryTransactionsListProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const [source, setSource] =
      useState<WarehouseDocumentSource | undefined>(undefined)
    const [type, setType] =
      useState<WarehouseDocumentType | undefined>(undefined)
    const [divisionId, setDivisionId] = useState<number | null>(null)
    const [warehouseId, setWarehouseId] =
      useState<number | undefined>(undefined)
    const [selectedDate, setSelectedDate] =
      useState<IDataPickerData | undefined>(undefined)
    const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
      useDataGridPagination()
    const getFilterDateRange = useGetFilterDateRange(true)
    const {data, loading, error, refetch} = useWarehouseDocumentItems({
      paginationInput,
      filter: {
        ...searchFilter,
        ...getFilterDateRange({
          date: selectedDate,
          filterNameFrom: 'warehouseDocumentDeliveryDateFrom',
          filterNameTo: 'warehouseDocumentDeliveryDateTo'
        }),
        warehouseDocumentWarehouseIds: warehouseId ? [warehouseId] : undefined,
        warehouseDocumentDivisionIds: divisionId ? [divisionId] : undefined,
        warehouseDocumentTypes: type ? [type] : undefined,
        warehouseDocumentSources: source ? [source] : undefined,
        warehouseDocumentStates: [WarehouseDocumentState.Issued]
      }
    })
    const translateWarehouseDocumentType = useTranslateWarehouseDocumentType()
    const translateWarehouseDocumentSource =
      useTranslateWarehouseDocumentSource()
    const translateUnit = useTranslateUnit()
    const percentageFormatter = usePercentageFormatter(0)
    const dateTimeFormatter = useDateTimeFormatter()
    const decimalFormatter6 = useDecimalFormatter(6)
    const decimalFormatter = useDecimalFormatter()
    const userNameFormatter = useUserNameFormatter()
    const translatedEffectiveClientCurrencySign =
      useTranslatedEffectiveClientCurrencySign()
    const handleSourceChange = useCallback(
      (source?: WarehouseDocumentSource) => {
        setSource(source)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleTypeChange = useCallback(
      (type?: WarehouseDocumentType) => {
        setType(type)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleDivisionChange = useCallback(
      (divisionId: number | null) => {
        setDivisionId(divisionId)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleWarehouseChange = useCallback(
      (warehouseId?: number) => {
        setWarehouseId(warehouseId)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleDateSelect = useCallback(
      (date?: IDataPickerData) => {
        setSelectedDate(date)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const columns: GridColDef[] = useMemo(
      () => [
        {
          headerName: t('Issue date'),
          field: 'issueDate',
          valueGetter: (params) => params.row.warehouseDocument.issuedAt,
          valueFormatter: dateTimeFormatter,
          minWidth: 200,
          sortable: false
        },
        {
          headerName: t('ID'),
          field: 'warehouseDocumentId',
          valueGetter: (params) => params.row.warehouseDocument.id,
          minWidth: 100,
          sortable: false
        },
        {
          headerName: t('Type'),
          field: 'type',
          minWidth: 150,
          valueGetter: (params) => params.row.warehouseDocument.type,
          valueFormatter: (params) =>
            translateWarehouseDocumentType(params.value),
          sortable: false
        },
        {
          headerName: t('Source'),
          field: 'source',
          minWidth: 150,
          valueGetter: (params) => params.row.warehouseDocument.source,
          valueFormatter: (params) =>
            translateWarehouseDocumentSource(params.value),
          sortable: false
        },
        {
          headerName: t('Code'),
          field: 'code',
          minWidth: 200,
          valueGetter: (params) =>
            params.row.warehouseProduct.product.internalCode,
          sortable: false
        },
        {
          headerName: t('Name'),
          field: 'name',
          minWidth: 200,
          valueGetter: (params) => params.row.warehouseProduct.product.name,
          sortable: false
        },
        {
          headerName: t('Quantity'),
          field: 'quantity',
          valueFormatter: decimalFormatter6,
          align: 'right',
          headerAlign: 'right',
          minWidth: 100,
          sortable: false
        },
        {
          headerName: t('Unit'),
          field: 'unit',
          minWidth: 100,
          valueGetter: (params) => params.row.warehouseProduct.product.unit,
          valueFormatter: (params) => translateUnit(params.value),
          sortable: false
        },
        {
          headerName: t('VAT exc.'),
          field: 'unitPriceVatExcluded',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter,
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('VAT'),
          field: 'vatRate',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: percentageFormatter,
          minWidth: 100,
          sortable: false
        },
        {
          headerName: t('VAT inc.'),
          field: 'unitPriceVatIncluded',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter,
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('VAT exc.'),
          field: 'priceVatExcluded',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter,
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('VAT'),
          field: 'vat',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: percentageFormatter,
          minWidth: 100,
          sortable: false
        },
        {
          headerName: t('VAT inc.'),
          field: 'priceVatIncluded',
          align: 'right',
          headerAlign: 'right',
          valueFormatter: decimalFormatter,
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('Warehouse'),
          field: 'warehouse',
          valueGetter: (params) => params.row.warehouseDocument.warehouse.name,
          minWidth: 200,
          sortable: false
        },
        {
          headerName: t('Division'),
          field: 'division',
          valueGetter: (params) => params.row.warehouseDocument.division.name,
          minWidth: 200,
          sortable: false
        },
        {
          headerName: t('Issued by'),
          field: 'issuedBy',
          valueGetter: (params) => params.row.warehouseDocument.issuedBy,
          valueFormatter: userNameFormatter,
          minWidth: 200,
          sortable: false
        },
        {
          headerName: t('Note'),
          field: 'note',
          minWidth: 300,
          sortable: false
        },
        {
          headerName: '',
          field: 'detail',
          valueGetter: (params) => params.row.warehouseDocument.id,
          renderCell: function renderer(params: GridRenderCellParams) {
            return <IconCellRenderer id={params.value} />
          },
          sortable: false,
          align: 'center',
          headerAlign: 'center',
          width: 48
        }
      ],
      [
        dateTimeFormatter,
        decimalFormatter,
        decimalFormatter6,
        percentageFormatter,
        t,
        translateUnit,
        translateWarehouseDocumentSource,
        translateWarehouseDocumentType,
        userNameFormatter
      ]
    )
    if (error) {
      return (
        <Error
          error={error}
          message={t('Error while loading warehouse document items')}
        />
      )
    }
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr'
        }}
      >
        <SubHeaderToolbar
          title={t('Overview')}
          leftActions={[
            <ChipWithOptions<WarehouseDocumentSource>
              key="source-chip"
              selectedItem={source}
              setSelectedItem={handleSourceChange}
              options={[
                WarehouseDocumentSource.Shop,
                WarehouseDocumentSource.Stock,
                WarehouseDocumentSource.Transfer,
                WarehouseDocumentSource.StockWriteOff,
                WarehouseDocumentSource.InventoryCheck
              ].map((source) => ({
                label: translateWarehouseDocumentSource(source),
                option: source
              }))}
              allText={t('All sources')}
              size="small"
            />,
            <OptionsChipWithDelete<WarehouseDocumentType>
              key="type-chip"
              tooltip={t('Select document type')}
              icon={<DescriptionOutlinedIcon />}
              options={[
                {
                  id: WarehouseDocumentType.Incoming,
                  label: t('Incoming documents')
                },
                {
                  id: WarehouseDocumentType.Outgoing,
                  label: t('Outgoing documents')
                }
              ]}
              selectedOption={type}
              onChange={handleTypeChange}
            />,
            <DivisionChip
              key="division-chip"
              onChange={handleDivisionChange}
            />,
            <WarehousesChip
              key="warehouse-chip"
              onChange={handleWarehouseChange}
            />
          ]}
          rightActions={[
            <DatePickerButton
              key="delivery-date-picker"
              tooltip={t('Delivery date')}
              label={t('Delivery date')}
              onDateSelect={handleDateSelect}
              selectedValues={selectedDate}
              groups={[TabGroup.Now, TabGroup.Past]}
              excludedValues={[TabNow.UntilToday, TabNow.FromToday]}
              minDateFrom={
                selectedDate?.dateRange?.dateTo
                  ? dayjs(selectedDate?.dateRange?.dateTo)
                      .subtract(1, 'year')
                      .startOf('d')
                  : dayjs().subtract(1, 'year').startOf('d')
              }
              maxDateFrom={
                selectedDate?.dateRange?.dateTo || dayjs().endOf('d')
              }
              maxDateTo={dayjs().endOf('d')}
            />,
            <ReloadButton
              key="reload-button"
              onReloadButtonClick={() => refetch()}
            />
          ]}
        />
        <WideCenteredLayout
          sx={{
            height: '100%',
            width: '100%',
            p: 3
          }}
        >
          <DataGridTable
            sx={{
              [`& .${gridClasses.withBorderColor}`]: {
                borderColor: 'divider'
              },
              [`& .${gridClasses.columnSeparator}`]: {
                color: 'divider'
              }
            }}
            columns={columns}
            loading={loading}
            rows={data?.warehouseDocumentItems.items || []}
            initialState={{
              pinnedColumns: {
                left: ['issueDate', 'warehouseDocumentId'],
                right: ['detail']
              }
            }}
            columnVisibilityModel={{
              detail: P([PermissionCode.ReadWarehouseDocument])
            }}
            disableColumnMenu
            disableRowSelectionOnClick
            localeText={{noRowsLabel: t('No warehouse document items found')}}
            experimentalFeatures={{columnGrouping: true}}
            columnGroupingModel={[
              {
                groupId: 'warehouseDocument',
                headerName: t('Warehouse document'),
                children: [
                  {field: 'issueDate'},
                  {field: 'warehouseDocumentId'},
                  {field: 'type'},
                  {field: 'source'}
                ]
              },
              {
                groupId: 'product',
                headerName: t('Product'),
                children: [
                  {field: 'code'},
                  {field: 'name'},
                  {field: 'quantity'},
                  {field: 'unit'}
                ]
              },
              {
                groupId: 'unitPrice',
                headerName: t('Unit price ({{currencySign}})', {
                  currencySign: translatedEffectiveClientCurrencySign
                }),
                children: [
                  {field: 'unitPriceVatExcluded'},
                  {field: 'vatRate'},
                  {field: 'unitPriceVatIncluded'}
                ]
              },
              {
                groupId: 'totalPrice',
                headerName: t('Total price ({{currencySign}})', {
                  currencySign: translatedEffectiveClientCurrencySign
                }),
                children: [
                  {field: 'priceVatExcluded'},
                  {field: 'vat'},
                  {field: 'priceVatIncluded'}
                ]
              }
            ]}
            columnHeaderHeight={32}
            {...getDataGridPaginationProps(
              data?.warehouseDocumentItems.pagination
            )}
          />
        </WideCenteredLayout>
      </Box>
    )
  }
