import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import BrushIcon from '@mui/icons-material/Brush'
import EditIcon from '@mui/icons-material/Edit'
import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {WebsiteEditorContent, WebsitePageMode} from '../types'
import {
  useTranslateWebsitePageMode,
  useTranslateWebsitePageModeDescription
} from '../utils'
import {useWebsitePageContext} from './WebsitePageContext'

export const WebsitePageModeSwitcherButton: React.FC = () => {
  const {P} = useEnsurePermissions()
  const {
    selectedWebsitePageMode,
    setSelectedWebsitePageMode,
    setSelectedWebsiteEditorContent
  } = useWebsitePageContext()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const translateWebsitePageMode = useTranslateWebsitePageMode()
  const translateWebsitePageModeDescription =
    useTranslateWebsitePageModeDescription()
  const handleModeClick = useCallback(
    (mode: WebsitePageMode) => () => {
      setSelectedWebsitePageMode(mode)
      if (mode === WebsitePageMode.Designing) {
        setSelectedWebsiteEditorContent(WebsiteEditorContent.Blocks)
      }
      closeMenu()
    },
    [closeMenu, setSelectedWebsiteEditorContent, setSelectedWebsitePageMode]
  )
  return (
    <>
      <Button
        startIcon={
          selectedWebsitePageMode === WebsitePageMode.Editing ? (
            <EditIcon />
          ) : (
            <BrushIcon />
          )
        }
        endIcon={<ArrowDropDownIcon />}
        variant="text"
        sx={{color: 'primary.main'}}
        onClick={openMenu}
      >
        {translateWebsitePageMode(selectedWebsitePageMode)}
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={closeMenu}
        anchorEl={anchorEl}
      >
        <MenuItem
          key={WebsitePageMode.Editing}
          label={translateWebsitePageMode(WebsitePageMode.Editing)}
          description={translateWebsitePageModeDescription(
            WebsitePageMode.Editing
          )}
          onClick={handleModeClick(WebsitePageMode.Editing)}
          isSelected={selectedWebsitePageMode === WebsitePageMode.Editing}
          icon={<EditIcon />}
        />
        {P([PermissionCode.UpdateWebsiteDesign]) && (
          <MenuItem
            key={WebsitePageMode.Designing}
            label={translateWebsitePageMode(WebsitePageMode.Designing)}
            description={translateWebsitePageModeDescription(
              WebsitePageMode.Designing
            )}
            onClick={handleModeClick(WebsitePageMode.Designing)}
            isSelected={selectedWebsitePageMode === WebsitePageMode.Designing}
            icon={<BrushIcon />}
          />
        )}
      </Menu>
    </>
  )
}
