import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {Card, CardContent, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../theme'

interface ICardRightArrowIconProps {
  onArrowClick?: () => void
}

export const CardRightArrowIcon: React.FC<ICardRightArrowIconProps> = ({
  onArrowClick
}: ICardRightArrowIconProps) => {
  return (
    <IconButton onClick={onArrowClick}>
      <ArrowRightIcon color="primary" />
    </IconButton>
  )
}

const useStyles = makeStyles<Theme, {maxWidth: string | number}>((theme) => ({
  root: {
    maxWidth: ({maxWidth}) => maxWidth
  },
  content: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 48
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}))

type NavigationCardProps = (
  | {
      title: string
      description?: string
    }
  | {
      title?: string
      description: string
    }
) & {
  rightAction?: React.ReactNode
  icon?: React.ReactNode
  maxWidth?: string | number
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
  title,
  description,
  icon,
  rightAction,
  maxWidth = 360
}: NavigationCardProps) => {
  const classes = useStyles({maxWidth})
  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent className={classes.content}>
        {(title || icon) && (
          <div className={classes.header}>
            <div className={classes.left}>
              {icon}
              <Typography variant="subtitle1">{title}</Typography>
            </div>
            {rightAction}
          </div>
        )}
        {description && (
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}
