import {useCallback} from 'react'
import {LocaleCode, Translated} from '../__generated__/schema'
import {useLocale} from '../components/context/locale'

export const getFirstExistingTranslation = (field: Translated) =>
  Object.values(field).filter(Boolean)[0]

export const useGetUserLocaleTranslation = () => {
  const {localeCode} = useLocale()

  return useCallback(
    (field: Translated) =>
      field[localeCode] ||
      field[LocaleCode.En] ||
      getFirstExistingTranslation(field) ||
      '',
    [localeCode]
  )
}
