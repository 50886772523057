import dayjs from 'dayjs'
import {identity, pickBy} from 'lodash'
import {useTranslation} from 'react-i18next'
import {
  AdmissionRateInput,
  AdmissionRateState,
  CopyTourTimeSlotsInput,
  DivisionCreateOnlineReservationEnd,
  DivisionCreatePosReservationEnd,
  DivisionGateCloses,
  DivisionGateOpens,
  DivisionOnlineReservationEnd,
  DivisionOnlineSalesEnd,
  DivisionPosReservationEnd,
  DivisionPosSalesEnd,
  DivisionsWithSettingsQuery,
  ShowAgeClassificationCode,
  ShowVersionCode,
  TourInput,
  TourTimeSlotState,
  TranslatedInput,
  UpdateAdmissionRateInput,
  UpdateTourTimeSlotsInput,
  WeekDay
} from '../../../../__generated__/schema'
import {
  AdmissionRateFormField,
  CopyTourTimeSlotsFormField,
  IAdmissionRateForm,
  ICopyTourTimeSlotsForm,
  ITimeSelectValue,
  ITourDivisionSettingsForm,
  ITourForm,
  IUpdateTourTimeSlotsForm,
  TimeSelectFormField,
  TimeSelectOption,
  TimeSelectValue,
  TourFormDivisionSettingsField,
  TourFormField,
  UpdateTourTimeSlotsBooleanOption,
  UpdateTourTimeSlotsFormField,
  UpdateTourTimeSlotsStaticOption
} from './types'

const dateToMinutes = (date: string) =>
  dayjs(date).diff(dayjs(date).startOf('d'), 'm')

const getTimeValue = (option: TimeSelectOption, value: ITimeSelectValue) => {
  switch (option) {
    case TimeSelectOption.AfterStart:
    case TimeSelectOption.BeforeStart:
    case TimeSelectOption.BeforeEnds:
    case TimeSelectOption.AfterEnds:
      return value?.minutes ? parseInt(value.minutes, 10) : 0
    case TimeSelectOption.DayBeforeStart:
    case TimeSelectOption.DayOfEvent:
      return value?.time ? dateToMinutes(value.time) : 0
    case TimeSelectOption.Duration:
      return (
        parseInt(value?.daysDropdown || '0', 10) * 60 * 24 +
        parseInt(value?.hoursDropdown || '0', 10) * 60 +
        parseInt(value?.minutesDropdown || '0', 10)
      )
    case TimeSelectOption.EventStart:
    default:
      return 0
  }
}

export const transformTourDivisionSettingsToInput = (
  settings: ITourDivisionSettingsForm
) => ({
  isECommerceReservationActive:
    settings[TourFormDivisionSettingsField.IsECommerceReservationActive],
  salesEndWebType: settings[TourFormDivisionSettingsField.ECommerceSalesEnd][
    TimeSelectFormField.Option
  ] as unknown as DivisionOnlineSalesEnd,
  salesEndWebValue: getTimeValue(
    settings[TourFormDivisionSettingsField.ECommerceSalesEnd][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.ECommerceSalesEnd][
      TimeSelectFormField.Value
    ]
  ),
  isRetailSaleActive:
    settings[TourFormDivisionSettingsField.IsRetailSaleActive],
  salesEndCashDeskType: settings[TourFormDivisionSettingsField.RetailSalesEnd][
    TimeSelectFormField.Option
  ] as unknown as DivisionPosSalesEnd,
  salesEndCashDeskValue: getTimeValue(
    settings[TourFormDivisionSettingsField.RetailSalesEnd][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.RetailSalesEnd][
      TimeSelectFormField.Value
    ]
  ),
  isECommerceSaleActive:
    settings[TourFormDivisionSettingsField.IsECommerceSaleActive],
  reservationEndWebType: settings[
    TourFormDivisionSettingsField.ECommerceReservationExpiration
  ][TimeSelectFormField.Option] as unknown as DivisionOnlineReservationEnd,
  reservationEndWebValue: getTimeValue(
    settings[TourFormDivisionSettingsField.ECommerceReservationExpiration][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.ECommerceReservationExpiration][
      TimeSelectFormField.Value
    ]
  ),
  eCommerceCreateReservationEndType: settings[
    TourFormDivisionSettingsField.ECommerceReservationEnd
  ][
    TimeSelectFormField.Option
  ] as unknown as DivisionCreateOnlineReservationEnd,
  eCommerceCreateReservationEndValue: getTimeValue(
    settings[TourFormDivisionSettingsField.ECommerceReservationEnd][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.ECommerceReservationEnd][
      TimeSelectFormField.Value
    ]
  ),
  isRetailReservationActive:
    settings[TourFormDivisionSettingsField.IsRetailReservationActive],
  reservationEndCashDeskType: settings[
    TourFormDivisionSettingsField.RetailReservationExpiration
  ][TimeSelectFormField.Option] as unknown as DivisionPosReservationEnd,
  reservationEndCashDeskValue: getTimeValue(
    settings[TourFormDivisionSettingsField.RetailReservationExpiration][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.RetailReservationExpiration][
      TimeSelectFormField.Value
    ]
  ),
  retailCreateReservationEndType: settings[
    TourFormDivisionSettingsField.RetailReservationEnd
  ][TimeSelectFormField.Option] as unknown as DivisionCreatePosReservationEnd,
  retailCreateReservationEndValue: getTimeValue(
    settings[TourFormDivisionSettingsField.RetailReservationEnd][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.RetailReservationEnd][
      TimeSelectFormField.Value
    ]
  ),
  gateOpensType: settings[TourFormDivisionSettingsField.GateOpens][
    TimeSelectFormField.Option
  ] as unknown as DivisionGateOpens,
  gateOpensValue: getTimeValue(
    settings[TourFormDivisionSettingsField.GateOpens][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.GateOpens][TimeSelectFormField.Value]
  ),
  gateClosesType: settings[TourFormDivisionSettingsField.GateCloses][
    TimeSelectFormField.Option
  ] as unknown as DivisionGateCloses,
  gateClosesValue: getTimeValue(
    settings[TourFormDivisionSettingsField.GateCloses][
      TimeSelectFormField.Option
    ],
    settings[TourFormDivisionSettingsField.GateCloses][
      TimeSelectFormField.Value
    ]
  )
})

export const transformTourFormToInput = (formData: ITourForm): TourInput => ({
  name: formData[TourFormField.Name],
  internalNote: formData[TourFormField.InternalNote] || undefined,
  showId: parseInt(formData[TourFormField.ShowId], 10),
  divisionId: parseInt(formData[TourFormField.DivisionId], 10),
  duration: parseInt(formData[TourFormField.Duration], 10),
  ...transformTourDivisionSettingsToInput(formData)
})

export const transformAdmissionRateFormToInput = (
  formData: IAdmissionRateForm,
  tourId: number
): AdmissionRateInput => ({
  tourId,
  name: formData[AdmissionRateFormField.Name],
  abbreviation: formData[AdmissionRateFormField.Abbreviation],
  color: formData[AdmissionRateFormField.Color],
  description: formData[AdmissionRateFormField.Description] || undefined
})

export const transformAdmissionRateUpdateFormToInput = (
  formData: IAdmissionRateForm,
  state: AdmissionRateState
): UpdateAdmissionRateInput => ({
  name: formData[AdmissionRateFormField.Name],
  abbreviation: formData[AdmissionRateFormField.Abbreviation],
  color: formData[AdmissionRateFormField.Color],
  description: formData[AdmissionRateFormField.Description] || null,
  state
})

const getWeekDay = (dayNumber: number) => {
  switch (dayNumber) {
    case 0:
    default:
      return [WeekDay.Sun]
    case 1:
      return [WeekDay.Mon]
    case 2:
      return [WeekDay.Tue]
    case 3:
      return [WeekDay.Wed]
    case 4:
      return [WeekDay.Thu]
    case 5:
      return [WeekDay.Fri]
    case 6:
      return [WeekDay.Sat]
  }
}

export const formatCopyTourTimeSlotsFormDataToInput = (
  formData: ICopyTourTimeSlotsForm,
  tourId: number
): CopyTourTimeSlotsInput => ({
  tourId,
  sourceDate: dayjs(formData[CopyTourTimeSlotsFormField.SourceDate]).format(
    'YYYY-MM-DD'
  ),
  destinationDateFrom: formData.destinationDateFrom
    ? dayjs(formData[CopyTourTimeSlotsFormField.DestinationDateFrom]).format(
        'YYYY-MM-DD'
      )
    : dayjs(formData[CopyTourTimeSlotsFormField.DestinationDate]).format(
        'YYYY-MM-DD'
      ),
  destinationDateTo: formData.destinationDateTo
    ? dayjs(formData[CopyTourTimeSlotsFormField.DestinationDateTo]).format(
        'YYYY-MM-DD'
      )
    : dayjs(formData[CopyTourTimeSlotsFormField.DestinationDate]).format(
        'YYYY-MM-DD'
      ),
  allowedWeekDays: formData.allowedWeekDays
    ? formData[CopyTourTimeSlotsFormField.AllowedWeekDays]
        .split(',')
        .map((day) => day as WeekDay)
    : getWeekDay(
        parseInt(
          dayjs(formData[CopyTourTimeSlotsFormField.DestinationDate])
            .get('day')
            .toString(),
          10
        )
      ),
  state: formData[CopyTourTimeSlotsFormField.State]
})

const getValueForStaticOption = (
  option: UpdateTourTimeSlotsStaticOption,
  value?: string
) =>
  option === UpdateTourTimeSlotsStaticOption.NewValue && value
    ? value
    : option === UpdateTourTimeSlotsStaticOption.ClearValues
    ? null
    : undefined

const getValueForBooleanOption = (option: UpdateTourTimeSlotsBooleanOption) =>
  option === UpdateTourTimeSlotsBooleanOption.Enable
    ? true
    : option === UpdateTourTimeSlotsBooleanOption.Disable
    ? false
    : undefined

const transformStringToNumber = (value?: string | null) =>
  typeof value === 'string'
    ? parseInt(value, 10)
    : typeof value === 'undefined'
    ? undefined
    : null

const getNamesTranslatedFields = (fields?: TranslatedInput) => {
  const filteredFields = pickBy(fields, identity)
  return Object.keys(filteredFields).length > 0 ? filteredFields : null
}

export const transformUpdateTourTimeSlotsFormDataToInput = (
  data: IUpdateTourTimeSlotsForm
): UpdateTourTimeSlotsInput => ({
  admissionRateId:
    data.admissionRateId === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : parseInt(data[UpdateTourTimeSlotsFormField.AdmissionRateId], 10),
  state:
    data.state === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : (data[UpdateTourTimeSlotsFormField.State] as TourTimeSlotState),
  ticketNote: getValueForStaticOption(
    data[UpdateTourTimeSlotsFormField.TicketNoteOption],
    data[UpdateTourTimeSlotsFormField.TicketNote]
  ),
  isRetailSaleActive: getValueForBooleanOption(
    data[UpdateTourTimeSlotsFormField.RetailSaleOption]
  ),
  isRetailReservationActive: getValueForBooleanOption(
    data[UpdateTourTimeSlotsFormField.RetailReservationOption]
  ),
  retailAttendeesLimit: transformStringToNumber(
    getValueForStaticOption(
      data[UpdateTourTimeSlotsFormField.RetailAttendeesLimitOption],
      data[UpdateTourTimeSlotsFormField.RetailAttendeesLimit]
    )
  ),
  isECommerceSaleActive: getValueForBooleanOption(
    data[UpdateTourTimeSlotsFormField.ECommerceSaleOption]
  ),
  isECommerceReservationActive: getValueForBooleanOption(
    data[UpdateTourTimeSlotsFormField.ECommerceReservationOption]
  ),
  showOnWebsiteAndApi: getValueForBooleanOption(
    data[UpdateTourTimeSlotsFormField.ShowOnWebsiteAndApi]
  ),
  eCommerceAttendeesLimit: transformStringToNumber(
    getValueForStaticOption(
      data[UpdateTourTimeSlotsFormField.ECommerceAttendeesLimitOption],
      data[UpdateTourTimeSlotsFormField.ECommerceAttendeesLimit]
    )
  ),
  eCommerceOrderAttendeesLimit: transformStringToNumber(
    getValueForStaticOption(
      data[UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimitOption],
      data[UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimit]
    )
  ),
  versionCode:
    data.versionCode === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : data.versionCode === UpdateTourTimeSlotsStaticOption.ClearValues
      ? null
      : (data[UpdateTourTimeSlotsFormField.VersionCode] as ShowVersionCode),
  guideId:
    data.guideId === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : data.guideId === UpdateTourTimeSlotsStaticOption.ClearValues
      ? null
      : parseInt(data[UpdateTourTimeSlotsFormField.GuideId], 10),
  costCenterId:
    data.costCenterId === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : data.costCenterId === UpdateTourTimeSlotsStaticOption.ClearValues
      ? null
      : parseInt(data[UpdateTourTimeSlotsFormField.CostCenterId], 10),
  marketingLabelId:
    data.marketingLabelId === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : data.marketingLabelId === UpdateTourTimeSlotsStaticOption.ClearValues
      ? null
      : parseInt(data[UpdateTourTimeSlotsFormField.MarketingLabelId], 10),
  eventCategoryId:
    data.eventCategoryId === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : data.eventCategoryId === UpdateTourTimeSlotsStaticOption.ClearValues
      ? null
      : parseInt(data[UpdateTourTimeSlotsFormField.EventCategoryId], 10),
  ageClassificationCode:
    data.ageClassificationCode === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : data.ageClassificationCode ===
        UpdateTourTimeSlotsStaticOption.ClearValues
      ? null
      : (data[
          UpdateTourTimeSlotsFormField.AgeClassificationCode
        ] as ShowAgeClassificationCode),
  venueId:
    data.venueId === UpdateTourTimeSlotsStaticOption.DoNotChange
      ? undefined
      : parseInt(data[UpdateTourTimeSlotsFormField.VenueId], 10),
  names: Object.values(data.namesOption).some(
    (option) => option !== UpdateTourTimeSlotsStaticOption.DoNotChange
  )
    ? getNamesTranslatedFields(data[UpdateTourTimeSlotsFormField.Names])
    : undefined
})

export const getDurationOption = (n: number) => {
  return [...Array.from(Array(n).keys())].reduce(
    (acc, key) => ({
      ...acc,
      [key]: String(key)
    }),
    {}
  )
}

const minutesToDuration = (minutes: number) => ({
  days: Math.floor(minutes / (24 * 60)),
  hours: Math.floor((minutes % (24 * 60)) / 60),
  minutes: minutes % 60
})

export const useTranslateTimeSelectOption = () => {
  const {t} = useTranslation()
  const translated: Record<TimeSelectOption, string> = {
    [TimeSelectOption.EventStart]: t('Same as tour start time'),
    [TimeSelectOption.AfterStart]: t('Minutes after time slot starts'),
    [TimeSelectOption.BeforeStart]: t('Minutes before time slot starts'),
    [TimeSelectOption.DayBeforeStart]: t('The day before time slot starts'),
    [TimeSelectOption.Duration]: t('Duration'),
    [TimeSelectOption.DayOfEvent]: t('Day of event'),
    [TimeSelectOption.BeforeEnds]: t('Minutes before event ends'),
    [TimeSelectOption.AfterEnds]: t('Minutes after event ends')
  }
  return (key: TimeSelectOption) => translated[key]
}

export const transformDivisionSettingToTimeSelectOption = (
  value:
    | DivisionOnlineSalesEnd
    | DivisionPosSalesEnd
    | DivisionOnlineReservationEnd
    | DivisionCreateOnlineReservationEnd
    | DivisionPosReservationEnd
    | DivisionCreatePosReservationEnd
    | DivisionGateOpens
    | DivisionGateCloses
): TimeSelectOption => {
  switch (value) {
    case DivisionOnlineSalesEnd.BeforeStart:
    case DivisionPosSalesEnd.BeforeStart:
    case DivisionOnlineReservationEnd.BeforeStart:
    case DivisionCreateOnlineReservationEnd.BeforeStart:
    case DivisionPosReservationEnd.BeforeStart:
    case DivisionCreatePosReservationEnd.BeforeStart:
    case DivisionGateOpens.BeforeStart:
    case DivisionGateCloses.BeforeStart:
      return TimeSelectOption.BeforeStart
    case DivisionOnlineSalesEnd.AfterStart:
    case DivisionPosSalesEnd.AfterStart:
    case DivisionOnlineReservationEnd.AfterStart:
    case DivisionCreateOnlineReservationEnd.AfterStart:
    case DivisionPosReservationEnd.AfterStart:
    case DivisionCreatePosReservationEnd.AfterStart:
    case DivisionGateCloses.AfterStart:
      return TimeSelectOption.AfterStart
    case DivisionOnlineSalesEnd.DayBeforeStart:
    case DivisionPosSalesEnd.DayBeforeStart:
    case DivisionOnlineReservationEnd.DayBeforeStart:
    case DivisionCreateOnlineReservationEnd.DayBeforeStart:
    case DivisionPosReservationEnd.DayBeforeStart:
    case DivisionCreatePosReservationEnd.DayBeforeStart:
    case DivisionGateOpens.DayBeforeStart:
      return TimeSelectOption.DayBeforeStart
    case DivisionPosSalesEnd.DayOfEvent:
    case DivisionGateCloses.DayOfEvent:
      return TimeSelectOption.DayOfEvent
    case DivisionOnlineReservationEnd.Duration:
    case DivisionPosReservationEnd.Duration:
      return TimeSelectOption.Duration
    case DivisionGateCloses.AfterEnd:
      return TimeSelectOption.AfterEnds
    case DivisionGateCloses.BeforeEnd:
      return TimeSelectOption.BeforeEnds
    case DivisionOnlineSalesEnd.EventStart:
    case DivisionPosSalesEnd.EventStart:
    case DivisionOnlineReservationEnd.EventStart:
    case DivisionPosReservationEnd.EventStart:
    case DivisionCreatePosReservationEnd.EventStart:
    default:
      return TimeSelectOption.EventStart
  }
}

export const getTimeSelectValueForECommerceSalesEnd = (
  option: TimeSelectOption,
  value: number
) => {
  const {days, hours, minutes} = minutesToDuration(value)
  switch (option) {
    case TimeSelectOption.BeforeStart:
    case TimeSelectOption.AfterStart:
    case TimeSelectOption.BeforeEnds:
    case TimeSelectOption.AfterEnds:
      return {[TimeSelectValue.Minutes]: String(value)}
    case TimeSelectOption.DayBeforeStart:
    case TimeSelectOption.DayOfEvent:
      return {
        [TimeSelectValue.Time]: dayjs()
          .startOf('d')
          .add(value, 'm')
          .toISOString()
      }
    case TimeSelectOption.Duration:
      return {
        [TimeSelectValue.DaysDropdown]: String(days),
        [TimeSelectValue.HoursDropdown]: String(hours),
        [TimeSelectValue.MinutesDropdown]: String(minutes)
      }
    case TimeSelectOption.EventStart:
    default:
      return {}
  }
}

export const transformDivisionSettingsToForm = (
  division: DivisionsWithSettingsQuery['divisions'][number]
): Partial<ITourForm> => {
  const eCommerceSalesEndOption = transformDivisionSettingToTimeSelectOption(
    division.salesEndWebType
  )
  const retailSalesEndOption = transformDivisionSettingToTimeSelectOption(
    division.salesEndCashDeskType
  )
  const eCommerceReservationExpirationOption =
    transformDivisionSettingToTimeSelectOption(division.reservationEndWebType)
  const eCommerceReservationEndOption =
    transformDivisionSettingToTimeSelectOption(
      division.onlineCreateReservationEndType
    )
  const retailReservationExpirationOption =
    transformDivisionSettingToTimeSelectOption(
      division.reservationEndCashDeskType
    )
  const retailReservationEndOption = transformDivisionSettingToTimeSelectOption(
    division.posCreateReservationEndType
  )
  const gateOpensOption = transformDivisionSettingToTimeSelectOption(
    division.gateOpensType
  )
  const gateClosesOption = transformDivisionSettingToTimeSelectOption(
    division.gateClosesType
  )
  return {
    [TourFormDivisionSettingsField.IsECommerceSaleActive]:
      division.onlinePurchaseActive,
    [TourFormDivisionSettingsField.ECommerceSalesEnd]: {
      [TimeSelectFormField.Option]: eCommerceSalesEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        eCommerceSalesEndOption,
        division.salesEndWebValue
      )
    },
    [TourFormDivisionSettingsField.IsRetailSaleActive]:
      division.posPurchaseActive,
    [TourFormDivisionSettingsField.RetailSalesEnd]: {
      [TimeSelectFormField.Option]: retailSalesEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        retailSalesEndOption,
        division.salesEndCashDeskValue
      )
    },
    [TourFormDivisionSettingsField.IsECommerceReservationActive]:
      division.onlineReservationActive,
    [TourFormDivisionSettingsField.ECommerceReservationExpiration]: {
      [TimeSelectFormField.Option]: eCommerceReservationExpirationOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        eCommerceReservationExpirationOption,
        division.reservationEndWebValue
      )
    },
    [TourFormDivisionSettingsField.ECommerceReservationEnd]: {
      [TimeSelectFormField.Option]: eCommerceReservationEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        eCommerceReservationEndOption,
        division.onlineCreateReservationEndValue
      )
    },
    [TourFormDivisionSettingsField.IsRetailReservationActive]:
      division.posReservationActive,
    [TourFormDivisionSettingsField.RetailReservationExpiration]: {
      [TimeSelectFormField.Option]: retailReservationExpirationOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        retailReservationExpirationOption,
        division.reservationEndCashDeskValue
      )
    },
    [TourFormDivisionSettingsField.RetailReservationEnd]: {
      [TimeSelectFormField.Option]: retailReservationEndOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        retailReservationEndOption,
        division.posCreateReservationEndValue
      )
    },
    [TourFormDivisionSettingsField.GateOpens]: {
      [TimeSelectFormField.Option]: gateOpensOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        gateOpensOption,
        division.gateOpensValue
      )
    },
    [TourFormDivisionSettingsField.GateCloses]: {
      [TimeSelectFormField.Option]: gateClosesOption,
      [TimeSelectFormField.Value]: getTimeSelectValueForECommerceSalesEnd(
        gateClosesOption,
        division.gateClosesValue
      )
    }
  }
}
