import {Box, Chip, FormHelperText, Switch, Typography} from '@mui/material'
import {styled} from '@mui/system'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  TourTimeSlotState,
  WeekDay
} from '../../../../../../__generated__/schema'
import {useTranslateTourTimeSlotState} from '../../../../../../hooks/translateTourTimeSlotState'
import {FormValidationError, InputRow} from '../../../../../common'
import {FormDateInput} from '../../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {useHasError} from '../../../../../form/utils'
import {CopyTourTimeSlotsFormField, ICopyTourTimeSlotsForm} from '../../types'

const useGetShortWeekDays = () => {
  const {t} = useTranslation()
  const shortWeekDays: Record<WeekDay, string> = {
    [WeekDay.Mon]: t<string>('Mon'),
    [WeekDay.Tue]: t<string>('Tue'),
    [WeekDay.Wed]: t<string>('Wed'),
    [WeekDay.Thu]: t<string>('Thu'),
    [WeekDay.Fri]: t<string>('Fri'),
    [WeekDay.Sat]: t<string>('Sat'),
    [WeekDay.Sun]: t<string>('Sun')
  }
  return shortWeekDays
}

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface ICopySlotsFormProps {
  formId: string
  onSubmit: (data: ICopyTourTimeSlotsForm) => void
  defaultValues?: Partial<ICopyTourTimeSlotsForm>
}

export const CopySlotsForm: React.FC<ICopySlotsFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: ICopySlotsFormProps) => {
  const {t} = useTranslation()
  const shortWeekDays = useGetShortWeekDays()
  const translateTourTimeSlotState = useTranslateTourTimeSlotState()
  const [selectedDays, setSelectedDays] = useState<WeekDay[]>([])
  const {
    errors,
    setValue,
    control,
    setError,
    watch,
    clearError,
    register,
    unregister,
    handleSubmit
  } = useForm<ICopyTourTimeSlotsForm>({defaultValues})
  const [isSectionExpanded, setIsSectionExpanded] = useState<boolean>(false)
  const hasError = useHasError<ICopyTourTimeSlotsForm>(
    errors,
    CopyTourTimeSlotsFormField.AllowedWeekDays
  )
  const handleSwitchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSectionExpanded(e.target.checked)
    },
    []
  )
  const handleChipClick = useCallback(
    (day: WeekDay) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedDays((selectedDays) =>
        selectedDays.includes(day)
          ? selectedDays.filter((selectedDay) => selectedDay !== day)
          : [...selectedDays, day]
      )
    },
    []
  )
  const watchAllowedWeekDays = watch(CopyTourTimeSlotsFormField.AllowedWeekDays)
  const watchDestinationDateFrom = watch(
    CopyTourTimeSlotsFormField.DestinationDateFrom
  )
  useEffect(
    () =>
      setValue(CopyTourTimeSlotsFormField.AllowedWeekDays, selectedDays.join()),
    [selectedDays, setValue]
  )
  useEffect(() => {
    if (!watchAllowedWeekDays) {
      setError(CopyTourTimeSlotsFormField.AllowedWeekDays, 'required')
    } else {
      clearError(CopyTourTimeSlotsFormField.AllowedWeekDays)
    }
  }, [clearError, setError, watchAllowedWeekDays])
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <FormDateInput<ICopyTourTimeSlotsForm>
            datePickerProps={{
              label: t<string>('Copy tour time slots from date')
            }}
            control={control}
            register={register}
            unregister={unregister}
            watch={watch}
            errors={errors}
            clearError={clearError}
            setValue={setValue}
            key={CopyTourTimeSlotsFormField.SourceDate}
            name={CopyTourTimeSlotsFormField.SourceDate}
            setError={setError}
            validationOptions={{
              required: true
            }}
            fullWidth
          />
        ]}
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          <Typography variant="body1">
            {t('Make copies for more than one day?')}
          </Typography>
          <Switch
            color="primary"
            checked={isSectionExpanded}
            onChange={handleSwitchChange}
          />
        </Box>
        {isSectionExpanded && (
          <>
            <input
              type="hidden"
              ref={register({required: true})}
              name={CopyTourTimeSlotsFormField.AllowedWeekDays}
            />
            <InputRow
              nodes={[
                <FormDateInput<ICopyTourTimeSlotsForm>
                  datePickerProps={{
                    label: t<string>('Destination date from'),
                    disablePast: true
                  }}
                  control={control}
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  errors={errors}
                  clearError={clearError}
                  setValue={setValue}
                  key={CopyTourTimeSlotsFormField.DestinationDateFrom}
                  name={CopyTourTimeSlotsFormField.DestinationDateFrom}
                  setError={setError}
                  validationOptions={{
                    required: true
                  }}
                  fullWidth
                />
              ]}
            />
            <InputRow
              nodes={[
                <FormDateInput<ICopyTourTimeSlotsForm>
                  datePickerProps={{
                    label: t<string>('Destination date to'),
                    minDate: watchDestinationDateFrom
                      ? dayjs(watchDestinationDateFrom)
                      : undefined
                  }}
                  control={control}
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  errors={errors}
                  clearError={clearError}
                  setValue={setValue}
                  key={CopyTourTimeSlotsFormField.DestinationDateTo}
                  name={CopyTourTimeSlotsFormField.DestinationDateTo}
                  setError={setError}
                  validationOptions={{
                    required: true
                  }}
                  fullWidth
                />
              ]}
            />
            <Box sx={{pt: 2}}>
              <Typography variant="subtitle2">
                {t('Repeat in the days')}
              </Typography>
              {hasError ? (
                <FormValidationError<ICopyTourTimeSlotsForm>
                  errors={errors}
                  fieldName={CopyTourTimeSlotsFormField.AllowedWeekDays}
                />
              ) : (
                <FormHelperText>{t('*Required')}</FormHelperText>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 1,
                  pt: 1
                }}
              >
                {Object.entries(shortWeekDays).map(([key, value]) => (
                  <Chip
                    key={key}
                    label={value}
                    onClick={handleChipClick(key as WeekDay)}
                    variant={
                      selectedDays.includes(key as WeekDay)
                        ? 'filled'
                        : 'outlined'
                    }
                    color={
                      selectedDays.includes(key as WeekDay)
                        ? 'primary'
                        : undefined
                    }
                  />
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
      {!isSectionExpanded && (
        <InputRow
          nodes={[
            <FormDateInput<ICopyTourTimeSlotsForm>
              datePickerProps={{
                label: t<string>('Destination date'),
                disablePast: true
              }}
              control={control}
              register={register}
              unregister={unregister}
              watch={watch}
              errors={errors}
              clearError={clearError}
              setValue={setValue}
              key={CopyTourTimeSlotsFormField.DestinationDate}
              name={CopyTourTimeSlotsFormField.DestinationDate}
              setError={setError}
              validationOptions={{
                required: true
              }}
              fullWidth
            />
          ]}
        />
      )}
      <InputRow
        nodes={[
          <UncontrolledFormSelect<ICopyTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Default state')}
            setValue={setValue}
            name={CopyTourTimeSlotsFormField.State}
            key={CopyTourTimeSlotsFormField.State}
            fullWidth
            selectOptions={[
              TourTimeSlotState.Draft,
              TourTimeSlotState.Published
            ].reduce(
              (acc, state) => ({
                ...acc,
                [state]: translateTourTimeSlotState(state)
              }),
              {}
            )}
          />
        ]}
      />
    </StyledForm>
  )
}
