import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useObjectsActions} from '../redux/objects/actions'
import {selectedObjectsIdsSelector} from '../redux/selection/selectors'
import {
  AlignBottomIcon,
  AlignCenterIcon,
  AlignLeftIcon,
  AlignMiddleIcon,
  AlignRightIcon,
  AlignTopIcon,
  DistributeHorizontalIcon,
  DistributeVerticalIcon
} from './Icons'
import {Panel} from './Panel'
import {PanelSplitButton} from './PanelSplitButton'

export const AlignPanel: React.FC = () => {
  const {t} = useTranslation()
  const {
    alignLeft,
    alignCenter,
    alignRight,
    alignTop,
    alignMiddle,
    alignBottom,
    distributeHorizontal,
    distributeVertical
  } = useObjectsActions()
  const selectedObjectsIds = useSelector(selectedObjectsIdsSelector)

  const disabled = selectedObjectsIds.length < 2

  // Note: null is used for divider
  const options = useMemo(
    () => [
      {
        icon: <AlignLeftIcon />,
        disabled,
        label: t('Align left'),
        onClick: alignLeft,
        cypressId: 'align-left-button'
      },
      {
        icon: <AlignCenterIcon />,
        disabled,
        label: t('Align center'),
        onClick: alignCenter,
        cypressId: 'align-center-button'
      },
      {
        icon: <AlignRightIcon />,
        disabled,
        label: t('Align right'),
        onClick: alignRight,
        cypressId: 'align-right-button'
      },
      null,
      {
        icon: <AlignTopIcon />,
        disabled,
        label: t('Align top'),
        onClick: alignTop,
        cypressId: 'align-top-button'
      },
      {
        icon: <AlignMiddleIcon />,
        disabled,
        label: t('Align middle'),
        onClick: alignMiddle,
        cypressId: 'align-middle-button'
      },
      {
        icon: <AlignBottomIcon />,
        disabled,
        label: t('Align bottom'),
        onClick: alignBottom,
        cypressId: 'align-bottom-button'
      },
      null,
      {
        icon: <DistributeHorizontalIcon />,
        disabled,
        label: t('Distribute horizontal'),
        onClick: distributeHorizontal,
        cypressId: 'distribute-horizontal-button'
      },
      {
        icon: <DistributeVerticalIcon />,
        disabled,
        label: t('Distribute vertical'),
        onClick: distributeVertical,
        cypressId: 'distribute-vertical-button'
      }
    ],
    [
      alignBottom,
      alignCenter,
      alignLeft,
      alignMiddle,
      alignRight,
      alignTop,
      disabled,
      distributeHorizontal,
      distributeVertical,
      t
    ]
  )

  return (
    <Panel>
      <PanelSplitButton
        toggleButtonCypressId="align-toggle-arrow"
        {...{options, disabled}}
      />
    </Panel>
  )
}
