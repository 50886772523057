import {
  CheckoutMutationVariables,
  ExistingCountryCode,
  LeadField,
  Maybe,
  PaymentIntentInput
} from '../../../../__generated__/schema'
import {
  getParsedItemFromStorage,
  LocalStorageKey
} from '../../../../hooks/storage'
import {IPosTerminalSettings} from '../../../../types'
import {convertValueToFloat} from '../../../../utils/conversions'

export interface ILeadForm {
  [LeadField.Name]: Maybe<string>
  [LeadField.Phone]: Maybe<string>
  [LeadField.Email]: Maybe<string>
  [LeadField.Note]: Maybe<string>
  [LeadField.InternalNote]: Maybe<string>
  [LeadField.CompanyName]: Maybe<string>
  [LeadField.CompanyIdNumber]: Maybe<string>
  [LeadField.VatId]: Maybe<string>
  [LeadField.TaxId]: Maybe<string>
  [LeadField.BillingAddressStreet]: Maybe<string>
  [LeadField.BillingAddressTown]: Maybe<string>
  [LeadField.BillingPostalCode]: Maybe<string>
  [LeadField.BillingAddressCountry]: Maybe<ExistingCountryCode>
  [LeadField.DeliveryAddressee]: Maybe<string>
  [LeadField.DeliveryAddressStreet]: Maybe<string>
  [LeadField.DeliveryAddressTown]: Maybe<string>
  [LeadField.DeliveryPostalCode]: Maybe<string>
  [LeadField.DeliveryAddressCountry]: Maybe<ExistingCountryCode>
  [LeadField.IsPrivacyPolicyConsentGranted]: Maybe<boolean>
}

export enum PaymentIntentField {
  VoucherId = 'voucherId',
  PaymentMethodId = 'paymentMethodId',
  Denomination = 'denomination',
  Amount = 'amount',
  Count = 'count'
}

export interface IPaymentIntent {
  [PaymentIntentField.PaymentMethodId]: string
  [PaymentIntentField.Denomination]?: Maybe<string>
  [PaymentIntentField.VoucherId]?: Maybe<string>
  [PaymentIntentField.Amount]?: Maybe<string>
  [PaymentIntentField.Count]?: Maybe<string>
}

export const getPaymentIntentFieldName = (
  key: string,
  paymentIntentFieldName: PaymentIntentField
) => `${key}.${paymentIntentFieldName}`

export interface IPaymentIntentsForm {
  [key: string]: IPaymentIntent
}

const transformIPaymentIntentIntoPaymentIntentInput = (
  paymentIntent: IPaymentIntent
): PaymentIntentInput => {
  const posTerminalSettings = getParsedItemFromStorage<IPosTerminalSettings>(
    localStorage,
    LocalStorageKey.PosTerminal
  )
  const posTerminalPaymentMethodId = posTerminalSettings?.enabled
    ? posTerminalSettings.paymentMethodId
    : undefined
  const paymentMethodId = parseInt(paymentIntent.paymentMethodId, 10)
  return {
    paymentMethodId,
    amount: convertValueToFloat(paymentIntent.amount!)!,
    denomination: paymentIntent.denomination
      ? convertValueToFloat(paymentIntent.denomination)
      : undefined,
    isManagedByThirdPartyLocalDevice: posTerminalPaymentMethodId
      ? posTerminalPaymentMethodId === paymentMethodId
      : false,
    count: paymentIntent.count ? parseInt(paymentIntent.count, 10) : undefined,
    voucherId: paymentIntent.voucherId
      ? parseInt(paymentIntent.voucherId, 10)
      : undefined
  }
}

export const getPaymentIntentsInputs = (
  form: IPaymentIntentsForm,
  cartPrice: number
): CheckoutMutationVariables['paymentIntentInputs'] =>
  cartPrice
    ? Object.values<IPaymentIntent>(form)
        .filter(
          (paymentIntent) =>
            Boolean(paymentIntent.amount) &&
            Boolean(convertValueToFloat(paymentIntent.amount!))
        )
        .map(transformIPaymentIntentIntoPaymentIntentInput)
    : []

export enum SelectCustomerFormField {
  LoyaltyId = 'loyaltyId'
}

export interface ISelectCustomerForm {
  [SelectCustomerFormField.LoyaltyId]: string
}

export interface ISelectCustomerDialogState {
  isOpen: boolean
  discountId: number | null
  type: 'add' | 'increment' | null
}

export const defaultSelectCustomerDialogState: ISelectCustomerDialogState = {
  isOpen: false,
  discountId: null,
  type: null
}
