import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../__generated__/schema'
import {useBooleanState} from '../../../hooks/state'
import {STONLY_WIDGET_TRIGGER} from '../../../stonly'
import {Theme} from '../../../theme'
import {useEnsurePermissions, useLogout, useUserInfo} from '../../../utils/auth'
import {routeTo} from '../../../utils/routes'
import {AccountSwitchWithBadgeIcon} from '../../common/AccountSwitchWithBadgeIcon'

import {BaseHeader, IBaseHeaderProps} from '../../common/header/BaseHeader'
import {
  BaseHeaderVariant,
  HeaderAction,
  HeaderActionType
} from '../../common/header/types'
import {SelectClientDialog} from './SelectClientDialog'
import {SideMenu} from './sideMenu'

const useStyles = makeStyles<Theme>(() => ({
  invisibleEl: {
    position: 'absolute'
  }
}))

interface IHeaderProps
  extends Pick<
    IBaseHeaderProps,
    | 'LeftActionIcon'
    | 'title'
    | 'variant'
    | 'search'
    | 'isHelpHidden'
    | 'isAvatarHidden'
    | 'isDatetimeEnabled'
    | 'className'
    | 'isLeftActionIconDisabled'
  > {
  rightActions?: HeaderAction[]
  onLeftActionClick?: () => void
}

const Header: React.FC<IHeaderProps> = ({
  onLeftActionClick,
  rightActions = [],
  ...props
}: IHeaderProps) => {
  const {user} = useUserInfo()
  const logout = useLogout()
  const history = useHistory()
  const handleHelpClick = useCallback(() => {
    const elements = document.getElementsByClassName('stonly-widget-trigger')
    if (elements.length) {
      elements.item(0)?.dispatchEvent(new Event('click'))
    }
  }, [])
  const handleLogoutClick = useCallback(async () => {
    await logout()
  }, [logout])
  const handleMyProfileClicked = useCallback(() => {
    history.push(routeTo.admin.profile())
  }, [history])

  const defaultOnLeftActionClick = useCallback(() => {
    history.goBack()
  }, [history])

  const {t} = useTranslation()
  const {
    state: isSelectClientDialogOpen,
    setTrue: openSelectClientDialog,
    setFalse: closeSelectClientDialog
  } = useBooleanState(false)
  const {P} = useEnsurePermissions()
  const classes = useStyles()

  if (!user) {
    return null
  }

  const hasRightsToSwitchClient = P([
    PermissionCode.SwitchClient,
    PermissionCode.ReadClients
  ])
  return (
    <>
      <BaseHeader
        onLeftActionClick={onLeftActionClick || defaultOnLeftActionClick}
        rightActions={
          hasRightsToSwitchClient
            ? [
                ...rightActions,
                {
                  Icon: AccountSwitchWithBadgeIcon,
                  onClick: openSelectClientDialog,
                  label: t('Switch client'),
                  type: HeaderActionType.IconButton,
                  cypressId: 'switch-client-button'
                }
              ]
            : rightActions
        }
        user={user}
        onHelpClick={handleHelpClick}
        onLogoutClick={handleLogoutClick}
        onMyProfileClick={handleMyProfileClicked}
        {...props}
      />
      {hasRightsToSwitchClient && (
        <SelectClientDialog
          isOpen={isSelectClientDialogOpen}
          onClose={closeSelectClientDialog}
        />
      )}
      <div className={cn(STONLY_WIDGET_TRIGGER, classes.invisibleEl)} />
    </>
  )
}

interface IPrimaryHeaderProps
  extends Pick<
    IHeaderProps,
    'title' | 'rightActions' | 'search' | 'isDatetimeEnabled'
  > {}

export const PrimaryHeader: React.FC<IPrimaryHeaderProps> = ({
  rightActions = [],
  ...props
}: IPrimaryHeaderProps) => {
  const {
    state: isOpen,
    setFalse: handleClose,
    setTrue: handleOpen
  } = useBooleanState(false)
  return (
    <>
      <Header
        LeftActionIcon={MenuIcon}
        onLeftActionClick={handleOpen}
        variant={BaseHeaderVariant.Primary}
        rightActions={rightActions}
        {...props}
      />
      <SideMenu onClose={handleClose} isOpen={isOpen} />
    </>
  )
}

interface IHeaderCommonProps
  extends Pick<
    IHeaderProps,
    | 'title'
    | 'rightActions'
    | 'search'
    | 'isDatetimeEnabled'
    | 'onLeftActionClick'
    | 'isLeftActionIconDisabled'
  > {
  hasArrowBackIcon?: boolean
}

export const SecondaryHeader: React.FC<IHeaderCommonProps> = ({
  rightActions = [],
  hasArrowBackIcon,
  ...props
}: IHeaderCommonProps) => {
  return (
    <Header
      LeftActionIcon={hasArrowBackIcon ? ArrowBackIcon : CloseIcon}
      variant={BaseHeaderVariant.Secondary}
      rightActions={rightActions}
      {...props}
    />
  )
}

export const useMediaPreviewHeaderStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: 64,
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 150%)',
    color: theme.palette.primary.contrastText,
    borderBottom: 'none'
  }
}))

export const MediaPreviewHeader: React.FC<IHeaderCommonProps> = ({
  rightActions = [],
  hasArrowBackIcon,
  ...props
}: IHeaderCommonProps) => {
  const classes = useMediaPreviewHeaderStyles()
  return (
    <Header
      LeftActionIcon={hasArrowBackIcon ? ArrowBackIcon : CloseIcon}
      variant={BaseHeaderVariant.Primary}
      rightActions={rightActions}
      className={classes.root}
      {...props}
    />
  )
}
