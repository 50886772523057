import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory, useRouteMatch} from 'react-router-dom'
import {TemplateType} from '../../../../__generated__/schema'
import {useClientFormAnchors} from '../../../../hooks/clientFormAnchors'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'

import {getUrlParam, routeTo} from '../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {ClientTemplates} from '../../../common/ClientTemplates'
import {ClientTemplatesDrawer} from '../../../common/ClientTemplatesDrawer'
import {LeadSettings} from '../../../common/leadSettings'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {AssignTemplateDialog} from '../components/assignTemplateDialog'
import {SecondaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {
  CLIENT_FORM_ID,
  ClientForm,
  clientFromClientForm,
  clientToClientForm,
  IClientFormData
} from './ClientForm'
import {useGetClient, useUpdateClient} from './graphql'
import {StateActionsBar} from './StateActionsBar'

export const EditClient: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()
  const clientId = parseInt(getUrlParam(match, 'id'), 10)
  const {error, loading, data} = useGetClient(clientId)
  const {
    state: isAssignTemplateDialogOpen,
    setTrue: openAssignTemplateDialog,
    setFalse: closeAssignTemplateDialog
  } = useBooleanState(false)

  const updateClient = useUpdateClient()
  const clientFormAnchors = useClientFormAnchors(true)
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const rowClickHandler = useCallback(
    (templateType: TemplateType) => () =>
      history.push(
        routeTo.admin.clients.assignedTemplates(clientId, templateType)
      ),
    [clientId, history]
  )

  const handleDrawerExited = useCallback(
    () => history.push(routeTo.admin.clients.edit(clientId)),
    [clientId, history]
  )

  const onSubmit = useCallback(
    async (formData: IClientFormData) => {
      try {
        setShowBackdrop(true)
        await updateClient(clientId, clientFromClientForm(formData))
        history.push(routeTo.admin.clients.home())
        addInfoNotification(t('Client updated'))
      } catch (err) {
        defaultErrorHandler(err, t('Error while updating the client'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      clientId,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t,
      updateClient
    ]
  )
  const handleClose = useCallback(
    () => history.push(routeTo.admin.clients.home()),
    [history]
  )

  return (
    <>
      <PageWithHeaderAndFooterTemplate
        header={
          <SecondaryHeader
            title={t('Client')}
            hasArrowBackIcon
            onLeftActionClick={handleClose}
          />
        }
        footer={
          <Footer>
            <SaveButton type="submit" form={CLIENT_FORM_ID} />
          </Footer>
        }
      >
        <RenderOnData
          loading={loading}
          error={error}
          data={data}
          dataCondition={(data) => Boolean(data?.client)}
          errorMessage={t<string>('Could not load client')}
        >
          {({client}) => (
            <CenteredLayout>
              <SingleSideNavigationList items={clientFormAnchors} />
              <CenteredLayoutListWrapper>
                <StateActionsBar client={client} />
                <ClientForm
                  defaultValues={clientToClientForm(client)}
                  onSubmit={onSubmit}
                />
                <LeadSettings client={client} />
                <ClientTemplates
                  templates={client.templateAssignments}
                  onRowClick={rowClickHandler}
                />
              </CenteredLayoutListWrapper>
            </CenteredLayout>
          )}
        </RenderOnData>
      </PageWithHeaderAndFooterTemplate>
      <Route
        path={routeTo.admin.clients.assignedTemplates(':id', ':templateType')}
      >
        <>
          <ClientTemplatesDrawer
            onExited={handleDrawerExited}
            onAssignTemplateButtonClick={openAssignTemplateDialog}
          />
          <AssignTemplateDialog
            clientId={clientId}
            isOpen={isAssignTemplateDialogOpen}
            onClose={closeAssignTemplateDialog}
          />
        </>
      </Route>
    </>
  )
}
