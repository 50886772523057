import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useCreateVouchers} from './graphql'
import {IVouchersForm, transformDataToInput} from './types'
import {VouchersForm} from './VouchersForm'

const CREATE_VOUCHERS_FORM_ID = 'createVouchersForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface ICreateVouchersDrawerProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const CreateVouchersDrawer: React.FC<ICreateVouchersDrawerProps> = ({
  isOpen,
  onClose,
  refetch
}: ICreateVouchersDrawerProps) => {
  const {t} = useTranslation()
  const createVouchers = useCreateVouchers()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const classes = useStyles()
  const handleSubmit = useCallback(
    async (data: IVouchersForm) => {
      try {
        setShowBackdrop(true)
        await createVouchers(transformDataToInput(data))
        addInfoNotification(t('Vouchers created'))
        refetch()
        onClose()
      } finally {
        setShowBackdrop(false)
      }
    },
    [addInfoNotification, createVouchers, onClose, refetch, setShowBackdrop, t]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      classes={{paper: classes.drawerPaper}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create vouchers')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_VOUCHERS_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenClassName={classes.drawerChildren}
      >
        <VouchersForm
          formId={CREATE_VOUCHERS_FORM_ID}
          onSubmit={handleSubmit}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
