import {Box, Paper, Typography} from '@mui/material'
import {isNil} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Maybe, TourFieldsFragment} from '../../../../../../__generated__/schema'
import {
  useTranslateTourState,
  useTranslateTourStateDescription
} from '../../../../../../hooks/tourState'
import {useShowDurationFormatters} from '../../../../../../utils/formatting'
import {EntityStateChip} from '../../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../../common/DividerSeparatedInfoRow'
import {tourStateColors} from '../../../../../constants'

interface IGeneralSectionProps {
  tour: TourFieldsFragment
}

export const GeneralSection: React.FC<IGeneralSectionProps> = ({
  tour
}: IGeneralSectionProps) => {
  const {t} = useTranslation()
  const translateTourState = useTranslateTourState()
  const translateTourStateDescription = useTranslateTourStateDescription()
  const translateAttendeesCount = useCallback(
    (count?: Maybe<number>) =>
      isNil(count)
        ? '-'
        : t<string>('{{count}} attendees', {
            count
          }),
    [t]
  )
  const {durationToMinutesFormat} = useShowDurationFormatters()

  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <div>
        <Typography variant="h6">{tour.name}</Typography>
      </div>
      <Box
        sx={{
          display: 'grid',
          alignItems: 'baseline',
          gap: 1,
          py: 2,
          gridAutoFlow: 'column',
          gridTemplateColumns: 'auto 1fr'
        }}
      >
        <EntityStateChip
          label={translateTourState(tour.state)}
          colorConf={tourStateColors[tour.state]}
        />
        <Typography variant="caption" color="textSecondary">
          {translateTourStateDescription(tour.state)}
        </Typography>
      </Box>
      {tour.internalNote && (
        <SingleInfoColumn
          caption={t('Internal note')}
          value={tour.internalNote}
          valueTypographyVariant="body2"
        />
      )}
      <DividerSeparatedInfoRow
        information={[
          {
            caption: t('Division'),
            value: tour.division.name
          },
          {
            caption: t('Duration'),
            value: durationToMinutesFormat(tour.duration)
          },
          {
            caption: t('Retail limit'),
            value: translateAttendeesCount(tour.retailAttendeesLimit)
          },
          {
            caption: t('Ecommerce limit'),
            value: translateAttendeesCount(tour.eCommerceAttendeesLimit)
          },
          {
            caption: t('Ecommerce order limit'),
            value: translateAttendeesCount(tour.eCommerceOrderAttendeesLimit)
          }
        ]}
      />
    </Paper>
  )
}
