import {
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Blank, BlankContentPosition} from '../../visual/Blank'
import {DialogTitleWithCloseButton} from '../DialogTitleWithCloseButton'

interface IVideoPreviewProps {
  isOpen: boolean
  onClose: () => void
  url: string
  title: string
  onBackButtonClick?: () => void
  onAddButtonClick?: () => void
}

export const VideoPreview: React.FC<IVideoPreviewProps> = ({
  isOpen,
  onClose,
  url,
  title,
  onBackButtonClick,
  onAddButtonClick
}: IVideoPreviewProps) => {
  const {t} = useTranslation()
  return (
    <Dialog fullScreen open={isOpen}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {title}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 0}} dividers>
        {
          // @ts-ignore
          navigator.connection?.rtt === 0 ? (
            <Blank
              title={t('No internet connection')}
              description={t(
                'Please try to reload the page and try again. If the error persists, please contact our support.'
              )}
              contentPosition={BlankContentPosition.Center}
            />
          ) : (
            <CardMedia
              component="iframe"
              src={`${url}?autoplay=1`}
              sx={{
                aspectRatio: '16/9',
                width: '100%',
                height: '100%',
                border: 0
              }}
              allow="autoplay"
            />
          )
        }
      </DialogContent>
      {(onBackButtonClick || onAddButtonClick) && (
        <DialogActions sx={{px: 3, display: 'flex', gap: 2}}>
          {onBackButtonClick && (
            <Button variant="text" color="primary" onClick={onBackButtonClick}>
              {t('Back')}
            </Button>
          )}
          {onAddButtonClick && (
            <Button
              variant="contained"
              color="primary"
              onClick={onAddButtonClick}
            >
              {t('Add')}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
