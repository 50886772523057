import {SeatShape, ZoneType} from '@attendio/shared-components'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {DrawTool} from '../types'
import {
  SeatIcon,
  SeatRowIcon,
  SeatRowsIcon,
  SittingIcon,
  StandingIcon,
  TicketIcon
} from './Icons'
import {Panel} from './Panel'
import {PanelSplitButton} from './PanelSplitButton'

const SeatSplitButton: React.FC = () => {
  const {setDrawMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)

  const onSeatClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.SEAT,
      shape: SeatShape.Classic
    })
  }, [setDrawMode])

  const onSeatRowClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.SEAT_ROW
    })
  }, [setDrawMode])

  const onSeatRowsClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.SEAT_ROWS
    })
  }, [setDrawMode])

  const options = useMemo(
    () => [
      {
        icon: <SeatIcon />,
        onClick: onSeatClick,
        label: t('Seat'),
        cypressId: 'seat-button'
      },
      {
        icon: <SeatRowIcon />,
        onClick: onSeatRowClick,
        label: t('Rows'),
        cypressId: 'seat-row-button'
      },
      {
        icon: <SeatRowsIcon />,
        onClick: onSeatRowsClick,
        label: t('Multiple rows'),
        cypressId: 'multiple-rows-button'
      }
    ],
    [onSeatClick, onSeatRowClick, onSeatRowsClick, t]
  )

  return (
    <PanelSplitButton
      options={options}
      toggleButtonCypressId="seats-toggle-arrow"
      selected={
        selectedMode === EditorMode.DRAW &&
        (modeConfigs[EditorMode.DRAW].type === DrawTool.SEAT ||
          modeConfigs[EditorMode.DRAW].type === DrawTool.SEAT_ROW ||
          modeConfigs[EditorMode.DRAW].type === DrawTool.SEAT_ROWS)
      }
    />
  )
}

const ZoneSplitButton: React.FC = () => {
  const {setDrawMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)

  const onFreeSittingZoneClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.ZONE,
      zoneType: ZoneType.FreeSitting
    })
  }, [setDrawMode])

  const onStandingZoneClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.ZONE,
      zoneType: ZoneType.Standing
    })
  }, [setDrawMode])

  const onTicketZoneClick = useCallback(() => {
    setDrawMode({
      type: DrawTool.ZONE,
      zoneType: ZoneType.Ticket
    })
  }, [setDrawMode])

  const options = useMemo(
    () => [
      {
        icon: <SittingIcon />,
        onClick: onFreeSittingZoneClick,
        label: t('Free sitting zone'),
        cypressId: 'free-sitting-zone-icon'
      },
      {
        icon: <StandingIcon />,
        onClick: onStandingZoneClick,
        label: t('Standing zone'),
        cypressId: 'standing-zone-icon'
      },
      {
        icon: <TicketIcon />,
        onClick: onTicketZoneClick,
        label: t('Ticket'),
        cypressId: 'ticket-zone-icon'
      }
    ],
    [onFreeSittingZoneClick, onStandingZoneClick, onTicketZoneClick, t]
  )

  return (
    <PanelSplitButton
      options={options}
      defaultSelectedIndex={2}
      selected={
        selectedMode === EditorMode.DRAW &&
        modeConfigs[EditorMode.DRAW].type === DrawTool.ZONE
      }
      toggleButtonCypressId="zones-toggle-arrow"
    />
  )
}

interface IPlacePanelProps {
  seatsDisabled?: boolean
}

export const PlacePanel: React.FC<IPlacePanelProps> = ({
  seatsDisabled = false
}: IPlacePanelProps) => {
  return (
    <Panel>
      {!seatsDisabled && <SeatSplitButton />}
      <ZoneSplitButton />
    </Panel>
  )
}
