import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {IconButton} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {CarouselSlideRenderControlProps} from 'nuka-carousel'
import React from 'react'

const useStyles = makeStyles<Theme, {isDisabled: boolean}>((theme) => ({
  root: {
    width: '30vw',
    height: '90vh',
    cursor: ({isDisabled}) => (isDisabled ? 'auto' : 'pointer'),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: theme.spacing(3)
  }
}))

interface ICenterRightControlsProps
  extends Pick<CarouselSlideRenderControlProps, 'nextSlide' | 'currentSlide'> {
  imagesLength: number
  className?: string
}

export const CenterRightControls: React.FC<ICenterRightControlsProps> = ({
  nextSlide: onNextButtonClick,
  currentSlide,
  imagesLength,
  className
}: ICenterRightControlsProps) => {
  const classes = useStyles({isDisabled: currentSlide === imagesLength - 1})
  return currentSlide !== imagesLength - 1 ? (
    <div className={classes.root} onClick={onNextButtonClick}>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className={className}
        disabled={currentSlide === imagesLength - 1}
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  ) : null
}
