import {Badge, SvgIconProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {ReactComponent as AccountSwitchOutlineIcon} from '../../assets/accountSwitchOutline.svg'
import {Theme} from '../../theme'
import {useUserInfo} from '../../utils/auth'
import {COLOR_CONF} from '../constants'

const useStyles = makeStyles<Theme>(() => ({
  badge: {
    backgroundColor: COLOR_CONF.GREEN.color
  }
}))

export const AccountSwitchWithBadgeIcon: React.FC<SvgIconProps> = (
  svgIconProps: SvgIconProps
) => {
  const classes = useStyles()
  const {effectiveClient} = useUserInfo()
  return (
    <Badge
      classes={{
        badge: classes.badge
      }}
      badgeContent=""
      variant="dot"
      invisible={!effectiveClient}
    >
      <AccountSwitchOutlineIcon {...svgIconProps} />
    </Badge>
  )
}
