import {Box, Paper, SxProps, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import React from 'react'
import {CollapseSection, ICollapseSectionProps} from './CollapseSection'

interface ISettingsPaperProps {
  label: string
  id?: string
  children: React.ReactNode
  childrenSx?: SxProps<Theme>
}

export const SettingsPaper: React.FC<ISettingsPaperProps> = ({
  label,
  id,
  children,
  childrenSx
}: ISettingsPaperProps) => (
  <Box id={id} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
    <Typography fontWeight={500}>{label}</Typography>
    <Paper sx={childrenSx} variant="outlined">
      {children}
    </Paper>
  </Box>
)

interface ISingleSettingProps {
  label: string
  description?: string
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export const SingleSetting: React.FC<ISingleSettingProps> = ({
  label,
  description,
  children,
  sx
}: ISingleSettingProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 3,
      p: 3,
      borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
      '&:last-child': {
        borderBottom: 0
      },
      ...sx
    }}
  >
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      {description && (
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      )}
    </Box>
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>{children}</Box>
  </Box>
)

interface IExpandableSettingsProps extends ICollapseSectionProps {}

export const ExpandableSettings: React.FC<IExpandableSettingsProps> = ({
  sx,
  children,
  ...props
}: IExpandableSettingsProps) => (
  <CollapseSection
    sx={{
      borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
      '&:last-child': {
        borderBottom: 0
      },
      p: 3,
      ...sx
    }}
    {...props}
  >
    {children}
  </CollapseSection>
)
