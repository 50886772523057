import {FormControl, MenuItem, Select} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {ViewType} from './types'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 150,
    marginRight: 10
  },
  select: {
    paddingTop: `9px !important`,
    paddingBottom: `9px !important`
  }
}))

interface IViewOptionsProps {
  value: ViewType
  changeView(viewType: ViewType): void
}

export const ViewOptions: React.FC<IViewOptionsProps> = ({
  value,
  changeView
}: IViewOptionsProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const onChange = useCallback(
    (e) => {
      changeView(e.target.value)
    },
    [changeView]
  )

  return (
    <FormControl className={classes.wrapper} variant="outlined">
      <Select
        value={value}
        onChange={onChange}
        classes={{select: classes.select}}
      >
        <MenuItem value={ViewType.Day}>{t('Day')}</MenuItem>
        <MenuItem value={ViewType.Week}>{t('Week')}</MenuItem>
        <MenuItem value={ViewType.Month}>{t('Month')}</MenuItem>
      </Select>
    </FormControl>
  )
}
