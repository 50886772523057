import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useCustomerParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CustomerForm} from '../components/customerDrawer/CustomerForm'
import {
  CustomerFormLocation,
  ICustomerForm
} from '../components/customerDrawer/types'
import {
  transformNarrowCustomerDataToFormData,
  trasnformFormDataToUpdateCustomerInput
} from '../components/customerDrawer/utils'
import {useNarrowCustomer, useUpdateCustomer} from './graphql'

const UPDATE_CUSTOMER_FORM_ID = 'updateCustomerFormId'

interface IEditCustomerDrawerProps {
  onExited: () => void
}

export const EditCustomerDrawer: React.FC<IEditCustomerDrawerProps> = ({
  onExited
}: IEditCustomerDrawerProps) => {
  const {t} = useTranslation()
  const {customerId} = useCustomerParams()
  const {data, loading, error} = useNarrowCustomer(customerId)
  const updateCustomer = useUpdateCustomer()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const handleSubmit = useCallback(
    async (formData: ICustomerForm) => {
      try {
        setShowBackdrop(true)
        await updateCustomer({
          id: customerId,
          input: trasnformFormDataToUpdateCustomerInput(
            formData,
            data?.customer.loyaltyLevelExpirationDate
          )
        })
        addInfoNotification(t('Customer has been updated'))
        closeDrawer()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDrawer,
      customerId,
      data?.customer.loyaltyLevelExpirationDate,
      setShowBackdrop,
      t,
      updateCustomer
    ]
  )
  useEffect(() => {
    if (!isNaN(customerId)) {
      openDrawer()
    }
  }, [customerId, openDrawer])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Edit customer')}
          />
        }
        footer={
          <SaveButton type="submit" form={UPDATE_CUSTOMER_FORM_ID}>
            {t('Update')}
          </SaveButton>
        }
        isLoading={loading}
        errorMessage={error && t('Error while loading customer')}
      >
        {data && (
          <CustomerForm
            formId={UPDATE_CUSTOMER_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={transformNarrowCustomerDataToFormData(data.customer)}
            location={CustomerFormLocation.Edit}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
