import {AuditoriumIcon} from '@attendio/shared-components'
import {useTranslation} from 'react-i18next'

export const useTranslateAuditoriumIcon = () => {
  const {t} = useTranslation()
  return (icon: AuditoriumIcon) => {
    switch (icon) {
      case AuditoriumIcon.Toilets:
        return t('Toilets')
      case AuditoriumIcon.Stage:
        return t('Stage')
      case AuditoriumIcon.Screen:
        return t('Screen')
      case AuditoriumIcon.Entrance:
        return t('Entrance')
      case AuditoriumIcon.Exit:
        return t('Exit')
      case AuditoriumIcon.EmergencyExit:
        return t('Emergency exit')
      case AuditoriumIcon.Cloakroom:
        return t('Cloak room')
      case AuditoriumIcon.Drinks:
        return t('Drinks')
      case AuditoriumIcon.Bar:
        return t('Bar')
      case AuditoriumIcon.Coffee:
        return t('Coffee')
      case AuditoriumIcon.FoodCourt:
      default:
        return t('Food court')
    }
  }
}
