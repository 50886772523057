import {TextFieldProps} from '@mui/material'
import React from 'react'
import {
  FieldErrors,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {
  LeadInputStatus,
  LeadOptionPropertiesFragment
} from '../../../../__generated__/schema'
import {useTranslateLeadField} from '../../../../hooks/leadField'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormFieldName} from '../../../form/types'
import {ILeadForm} from './types'
import {getHelperNote} from './utils'

interface IBriefLeadFormTextInputProps<
  FormValues extends ILeadForm = ILeadForm
> {
  errors: FieldErrors<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  triggerValidation: FormContextValues<FormValues>['triggerValidation']
  validationOptions?: ValidationOptions
  option?: LeadOptionPropertiesFragment
  isMultiline?: boolean
  showOptionalLeadInput?: boolean
  className?: string
  margin?: TextFieldProps['margin']
}

export const LeadFormTextInput = <FormValues extends ILeadForm = ILeadForm>({
  option,
  register,
  setValue,
  watch,
  errors,
  triggerValidation,
  validationOptions,
  isMultiline,
  showOptionalLeadInput,
  margin,
  className
}: IBriefLeadFormTextInputProps<FormValues>) => {
  const {t} = useTranslation()
  const translateLeadField = useTranslateLeadField()
  if (!option || option.inputStatus === LeadInputStatus.Unavailable) {
    return null
  }
  const name = option.field as FormFieldName<FormValues>
  if (
    !showOptionalLeadInput &&
    option.inputStatus === LeadInputStatus.Optional
  ) {
    return <input ref={register()} name={name} type="hidden" />
  }
  return (
    <div className={className}>
      <UncontrolledFormTextInput<FormValues>
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
        name={name}
        label={translateLeadField(option.field)}
        margin={margin}
        {...(isMultiline && {
          multiline: true,
          rows: 4
        })}
        fullWidth
        validationOptions={{
          ...validationOptions,
          required: option.inputStatus === LeadInputStatus.Required
        }}
        helperNote={getHelperNote(t, option)}
      />
    </div>
  )
}
