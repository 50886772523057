import {styled} from '@mui/system'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {CreateBusinessPartnerDrawer} from '../../../common/businessPartnerDrawer/CreateBusinessPartnerDrawer'
import {
  BusinessPartnerFormField,
  IBusinessPartnerForm
} from '../../../common/businessPartnerDrawer/types'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormDateInput} from '../../../form/pickers'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../form/UncontrolledFormRadioGroup'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {useCreateBusinessPartner} from '../businessPartners/graphql'
import {transformFormDataToCreateBusinessPartnerInput} from '../businessPartners/utils'
import {
  DocumentTypeOption,
  IWarehouseDocumentForm,
  WarehouseDocumentFormField,
  WarehouseDocumentFormType
} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IWarehouseDocumentFormProps {
  formId: string
  onSubmit: (data: IWarehouseDocumentForm) => Promise<void>
  warehouses: {id: number; name: string}[]
  businessPartners: {id: number; companyName: string}[]
  defaultValues?: Partial<IWarehouseDocumentForm>
  formType: WarehouseDocumentFormType
}

export const WarehouseDocumentForm: React.FC<IWarehouseDocumentFormProps> = ({
  formId,
  onSubmit,
  warehouses,
  businessPartners,
  defaultValues,
  formType
}: IWarehouseDocumentFormProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [newBusinessPartnerCompanyName, setNewBusinessPartnerCompanyName] =
    useState<string | null>(null)
  const [businessPartnersList, setBusinessPartnersList] =
    useState<{id: number; companyName: string}[]>(businessPartners)
  const {
    errors,
    control,
    setValue,
    watch,
    clearError,
    register,
    unregister,
    setError,
    triggerValidation,
    getValues,
    reset,
    handleSubmit
  } = useForm<IWarehouseDocumentForm>({
    defaultValues
  })
  const createBusinessPartner = useCreateBusinessPartner()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const handleCreateBusinessPartner = useCallback(
    (companyName) => setNewBusinessPartnerCompanyName(companyName),
    []
  )
  const handleCreateBusinessPartnerSubmit = useCallback(
    async (formData: IBusinessPartnerForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await createBusinessPartner({
          input: transformFormDataToCreateBusinessPartnerInput(formData)
        })
        if (data) {
          setBusinessPartnersList((prevState) => [
            ...prevState,
            {
              id: data.createBusinessPartner.id,
              companyName: data.createBusinessPartner.companyName
            }
          ])
          reset({
            ...getValues(),
            [WarehouseDocumentFormField.BusinessPartnerId]:
              data.createBusinessPartner.id
          })
          addInfoNotification(t('Business partner has been created'))
        }
        setNewBusinessPartnerCompanyName(null)
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating business partner'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createBusinessPartner,
      defaultErrorHandler,
      getValues,
      reset,
      setShowBackdrop,
      t
    ]
  )
  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
        {formType === WarehouseDocumentFormType.Create && (
          <InputRow
            nodes={[
              <UncontrolledFormRadioGroup<IWarehouseDocumentForm>
                label={t('Document type')}
                name={WarehouseDocumentFormField.Type}
                key={WarehouseDocumentFormField.Type}
                errors={errors}
                validationOptions={{
                  required: true
                }}
                fullWidth
                control={control}
                options={[
                  {
                    value: DocumentTypeOption.Incoming,
                    label: <BasicRadioLabel primaryText={t('Incoming')} />
                  },
                  {
                    value: DocumentTypeOption.Outgoing,
                    label: <BasicRadioLabel primaryText={t('Outgoing')} />
                  },
                  {
                    value: DocumentTypeOption.StockWriteOff,
                    label: (
                      <BasicRadioLabel primaryText={t('Stock write off')} />
                    )
                  }
                ]}
              />
            ]}
          />
        )}
        <InputRow
          nodes={[
            <UncontrolledFormSelect<IWarehouseDocumentForm>
              selectOptions={warehouses.reduce(
                (acc, warehouse) => ({
                  ...acc,
                  [warehouse.id]: warehouse.name
                }),
                {}
              )}
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              name={WarehouseDocumentFormField.WarehouseId}
              key={WarehouseDocumentFormField.WarehouseId}
              label={t('Warehouse')}
              validationOptions={{required: true}}
              fullWidth
              required
              disabled={formType === WarehouseDocumentFormType.Update}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormDateInput
              datePickerProps={{
                label: t<string>('Delivery date'),
                disableFuture: true
              }}
              control={control}
              register={register}
              unregister={unregister}
              watch={watch}
              errors={errors}
              clearError={clearError}
              setValue={setValue}
              name={WarehouseDocumentFormField.DeliveryDate}
              key={WarehouseDocumentFormField.DeliveryDate}
              setError={setError}
              helperText={t('Expected today or date from past')}
              fullWidth
              validationOptions={{
                required: true
              }}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<IWarehouseDocumentForm>
              autocompleteOptions={businessPartnersList.map(
                ({id, companyName}) => ({
                  value: id,
                  name: companyName
                })
              )}
              errors={errors}
              register={register}
              watch={watch}
              setValue={setValue}
              label={t('Business partner')}
              name={WarehouseDocumentFormField.BusinessPartnerId}
              key={WarehouseDocumentFormField.BusinessPartnerId}
              fullWidth
              onCreate={
                P([PermissionCode.CreateBusinessPartner])
                  ? handleCreateBusinessPartner
                  : undefined
              }
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IWarehouseDocumentForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={WarehouseDocumentFormField.ThirdPartyDocumentId}
              key={WarehouseDocumentFormField.ThirdPartyDocumentId}
              label={t('Related document ID')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IWarehouseDocumentForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={WarehouseDocumentFormField.Note}
              key={WarehouseDocumentFormField.Note}
              label={t('Note')}
              validationOptions={{
                validate: isStringWithMaxLength1000
              }}
              fullWidth
              multiline
              rows={3}
            />
          ]}
        />
      </StyledForm>
      <CreateBusinessPartnerDrawer
        isOpen={Boolean(newBusinessPartnerCompanyName)}
        onClose={() => setNewBusinessPartnerCompanyName(null)}
        onSubmit={handleCreateBusinessPartnerSubmit}
        defaultValues={{
          [BusinessPartnerFormField.CompanyName]:
            newBusinessPartnerCompanyName || ''
        }}
      />
    </>
  )
}
