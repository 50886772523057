import React, {useRef, useState, useEffect, useCallback} from 'react'

import {useDebounce} from './debounce'

export interface IDimensions {
  width: number
  height: number
}

export const useElementDimensions = <
  T extends HTMLElement = HTMLDivElement
>(): [React.MutableRefObject<T | null>, IDimensions] => {
  const ref = useRef<null | T>(null)
  const [dimensions, setDimensions] = useState({width: 0, height: 0})

  const onResize = useCallback(() => {
    const current = ref && ref.current
    if (current) {
      const domRect = current.getBoundingClientRect()
      const newDimensions: IDimensions = {
        width: domRect.width,
        height: domRect.height
      }
      setDimensions(newDimensions)
    }
  }, [])

  const debouncedOnResize = useDebounce(onResize, 400)

  useEffect(() => {
    const current = ref && ref.current
    if (current) {
      onResize()
      window.addEventListener('resize', debouncedOnResize)
    }
    return () => {
      if (current) {
        window.removeEventListener('resize', debouncedOnResize)
        debouncedOnResize.cancel()
      }
    }
  }, [debouncedOnResize, onResize])

  return [ref, dimensions]
}
