import {Card, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {Address} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {
  DrawerActionsBar,
  DrawerForm,
  IDrawerActionBarProps,
  IDrawerForm
} from '../../../common'
import {DividerSeparatedInfoRow} from '../../../common/DividerSeparatedInfoRow'
import {borderStyle} from '../../../constants'

export type VenuesDrawerActionsBarProps = Omit<IDrawerActionBarProps, 'onClose'>

export const VenuesDrawerActionsBar: React.FC<VenuesDrawerActionsBarProps> = (
  props: VenuesDrawerActionsBarProps
) => {
  const history = useHistory()

  return <DrawerActionsBar onClose={history.goBack} {...props} />
}

const useEntityDetailStyles = makeStyles<Theme>((theme) => ({
  title: {
    padding: theme.spacing(2),
    fontWeight: 500
  },
  card: {
    padding: theme.spacing(3),
    boxShadow: 'none',
    border: borderStyle
  },
  padding: {
    padding: theme.spacing(1)
  },
  supplementalInformationWrapper: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    paddingLeft: theme.spacing(1)
  },
  children: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    paddingBottom: theme.spacing(1)
  }
}))

interface ISupplementalInformation {
  caption: string
  value?: string | number | null | undefined
}

interface IEntityDetailCardLayoutProps {
  header: string
  Buttons: Array<React.ReactNode>
  children?: React.ReactNode
  supplementalInformation?: Array<ISupplementalInformation>
}

export const EntityDetailCardLayout: React.FC<IEntityDetailCardLayoutProps> = ({
  header,
  Buttons,
  children,
  supplementalInformation
}: IEntityDetailCardLayoutProps) => {
  const classes = useEntityDetailStyles()
  return (
    <Card className={classes.card}>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.padding}
        >
          <Grid item>
            <Typography variant="h6" color="initial">
              {header}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {Buttons.map((Button, index) => (
                <Grid item key={index}>
                  {Button}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.children}>{children}</div>
        {supplementalInformation && (
          <DividerSeparatedInfoRow information={supplementalInformation} />
        )}
      </Grid>
    </Card>
  )
}

export const VenuesDrawerForm: React.FC<IDrawerForm> = ({
  onClose,
  children,
  ...rest
}: IDrawerForm) => {
  const history = useHistory()

  return (
    <DrawerForm onClose={onClose || history.goBack} {...rest}>
      {children}
    </DrawerForm>
  )
}

export const getVenueAddressString = (address: Address) => {
  const entities = [
    address.complex,
    address.street,
    address.postalCode,
    address.town,
    address.country
  ]
  return entities.filter((v) => v).join(', ')
}
