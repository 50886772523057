import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'

const useStyles = makeStyles<Theme, {inset: boolean}>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: ({inset}) =>
      inset ? theme.spacing(2, 3, 2, 9) : theme.spacing(2, 3),
    justifyContent: 'space-between'
  },
  iconWithRows: {
    display: 'flex',
    gap: theme.spacing(0, 3),
    alignItems: 'center'
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5)
  }
}))

interface IItemRowProps {
  icon?: React.ReactNode
  firstRow: React.ReactNode
  secondRow?: React.ReactNode
  thirdRow?: React.ReactNode
  fourthRow?: React.ReactNode
  action?: React.ReactNode
  inset?: boolean
}

export const ItemRow: React.FC<IItemRowProps> = ({
  icon,
  firstRow,
  secondRow,
  thirdRow,
  fourthRow,
  action,
  inset = false
}: IItemRowProps) => {
  const classes = useStyles({inset})
  return (
    <div className={classes.root}>
      <div className={classes.iconWithRows}>
        {icon}
        <div className={classes.rows}>
          <div>{firstRow}</div>
          <div>{secondRow}</div>
          <div>{thirdRow}</div>
          <div>{fourthRow}</div>
        </div>
      </div>
      <div>{action}</div>
    </div>
  )
}
