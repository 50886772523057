import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {Box, Drawer, drawerClasses, Tab, Tabs, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  ItemFilter,
  PassCodeCheckDirection,
  PassCodeCheckInputType,
  SaleByItemPassCodeQuery
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../../utils/errors'
import {safeSum} from '../../../../../../utils/money'
import {useCheckPassCodeParams} from '../../../../../../utils/pathname'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {Blank} from '../../../../../visual/Blank'
import {useAdvancedSettings} from '../../AdvancedSettingsContext'
import {useCheckMode} from '../../CheckModeContext'
import {BulkCheckDrawerTab, CheckMode, CheckTicketsPageType} from '../../types'
import {
  checkedTicketsFilter,
  isCheckTicketsPageType,
  isSaleByItemPassCodeTicketItemFieldsFragment,
  isSaleByItemPassCodeTicketOrTourItemFieldsFragment,
  uncheckedTicketsFilter,
  useTranslateCheckMode,
  validateEntity
} from '../../utils'
import {useCheckPassCodes, useSaleByItemPassCode} from '../graphql'
import {Overview} from './Overview'

const getItemFilter = (
  checkType: any,
  entityId: number
): ItemFilter | undefined => {
  if (isCheckTicketsPageType(checkType)) {
    switch (checkType) {
      case CheckTicketsPageType.Events:
        return {eventIds: [entityId]}
      case CheckTicketsPageType.TourTimeSlots:
        return {tourTimeSlotIds: [entityId]}
      case CheckTicketsPageType.Tours:
        return {tourIds: [entityId]}
      case CheckTicketsPageType.Venues:
        return {venueIds: [entityId]}
      default:
        return undefined
    }
  }
  return undefined
}

interface IBulkCheckDrawerProps {
  onExited: () => void
  onEntered: () => void
  inputType: PassCodeCheckInputType
  refetch: () => void
}

export const BulkCheckDrawer: React.FC<IBulkCheckDrawerProps> = ({
  onExited,
  onEntered,
  inputType,
  refetch
}: IBulkCheckDrawerProps) => {
  const {t} = useTranslation()
  const {checkType, entityId, passCode} = useCheckPassCodeParams()
  const {checkMode} = useCheckMode()
  const checkPassCodes = useCheckPassCodes()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const translateCheckMode = useTranslateCheckMode()
  const {
    settings: {discountAuthorization}
  } = useAdvancedSettings()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {data, loading, error} = useSaleByItemPassCode(passCode, !isDrawerOpen)
  const [tab, setTab] = useState<BulkCheckDrawerTab>(
    BulkCheckDrawerTab.Overview
  )
  const handleTabChange = useCallback((_, newTab) => setTab(newTab), [])
  const filteredTickets = (data?.saleByItemPassCode.items || [])
    .filter(isSaleByItemPassCodeTicketOrTourItemFieldsFragment)
    .filter((item) => !item.claim)
  const filteredTicketsBasedOnCheckMode = filteredTickets.filter(
    checkMode === CheckMode.CheckIn
      ? uncheckedTicketsFilter
      : checkedTicketsFilter
  )
  const attendeesCount = safeSum(
    filteredTicketsBasedOnCheckMode.map((item) =>
      isSaleByItemPassCodeTicketItemFieldsFragment(item)
        ? 1
        : item.attendeesCount
    )
  )
  const handleCheckPassCodes = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data: checkPassCodesData} = await checkPassCodes({
        itemFilter: getItemFilter(checkType, entityId),
        passCodes: filteredTicketsBasedOnCheckMode.map(
          ({passCode}) => passCode
        ),
        direction:
          checkMode === CheckMode.CheckIn
            ? PassCodeCheckDirection.In
            : PassCodeCheckDirection.Out,
        inputType,
        discountAuthorizationMode: discountAuthorization
      })
      // eslint-disable-next-line no-console
      console.log(checkPassCodesData)
      refetch()
      closeDrawer()
    } catch (error) {
      defaultErrorHandler(error, t('Error while checking pass codes'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    refetch,
    checkMode,
    checkPassCodes,
    checkType,
    closeDrawer,
    defaultErrorHandler,
    discountAuthorization,
    entityId,
    filteredTicketsBasedOnCheckMode,
    inputType,
    setShowBackdrop,
    t
  ])
  const isEntityValid = validateEntity({
    data: data?.saleByItemPassCode,
    checkType: checkType as CheckTicketsPageType,
    entityId
  })
  useEffect(() => {
    if (isCheckTicketsPageType(checkType) && !isNaN(entityId) && passCode) {
      openDrawer()
    }
  }, [checkType, entityId, openDrawer, passCode])
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
      SlideProps={{
        exit: true,
        onExited,
        onEntered
      }}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 360, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={translateCheckMode(checkMode)}
          />
        }
        footer={
          error || !isEntityValid ? undefined : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="body2">
                {t('{{count}} attendees', {count: attendeesCount})}
              </Typography>
              {[CheckMode.CheckIn, CheckMode.CheckOut].includes(checkMode) && (
                <SaveButton
                  disabled={attendeesCount === 0}
                  onClick={handleCheckPassCodes}
                >
                  {translateCheckMode(checkMode)}
                </SaveButton>
              )}
            </Box>
          )
        }
        isFooterFullWidth
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <RenderOnData<SaleByItemPassCodeQuery>
          data={data}
          loading={loading}
          error={error}
          errorChildren={(error) =>
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.SaleNotFound
            ) ? (
              <Blank
                title={t('Invalid pass code')}
                IconComp={ReportProblemOutlinedIcon}
              />
            ) : (
              <Blank
                title={t('Error while loading sale by item pass code')}
                IconComp={ReportProblemOutlinedIcon}
              />
            )
          }
          dataCondition={(data) => Array.isArray(data.saleByItemPassCode.items)}
        >
          {({saleByItemPassCode}) => (
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{
                  height: 48,
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`
                }}
              >
                <Tab
                  label={t('Overview')}
                  value={BulkCheckDrawerTab.Overview}
                />
              </Tabs>
              {tab === BulkCheckDrawerTab.Overview && (
                <Overview
                  sale={saleByItemPassCode}
                  filteredTickets={filteredTickets}
                  isEntityValid={isEntityValid}
                  checkType={checkType as CheckTicketsPageType}
                />
              )}
            </Box>
          )}
        </RenderOnData>
      </DrawerTemplate>
    </Drawer>
  )
}
