import {useTranslation} from 'react-i18next'

import {
  ShowFormatCode,
  ShowSoundMixCode,
  ShowVersionCode
} from '../__generated__/schema'

export const useTranslateShowFormat = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowFormatCode, string> = {
    [ShowFormatCode.Analog_16mm]: t<string>('ShowFormatCode->Analog_16mm'),
    [ShowFormatCode.Analog_35mm]: t<string>('ShowFormatCode->Analog_35mm'),
    [ShowFormatCode.Analog_70mm]: t<string>('ShowFormatCode->Analog_70mm'),
    [ShowFormatCode.Digital_2d]: t<string>('ShowFormatCode->Digital_2d'),
    [ShowFormatCode.Digital_2d_4d]: t<string>('ShowFormatCode->Digital_2d_4d'),
    [ShowFormatCode.Digital_2d_4k]: t<string>('ShowFormatCode->Digital_2d_4k'),
    [ShowFormatCode.Digital_3d]: t<string>('ShowFormatCode->Digital_3d'),
    [ShowFormatCode.Digital_3d_4d]: t<string>('ShowFormatCode->Digital_3d_4d'),
    [ShowFormatCode.DigitalBd]: t<string>('ShowFormatCode->DigitalBd'),
    [ShowFormatCode.DigitalDvd]: t<string>('ShowFormatCode->DigitalDvd'),
    [ShowFormatCode.Digital_2dHfr]: t<string>('ShowFormatCode->Digital_2dHfr'),
    [ShowFormatCode.Digital_3dHfr]: t<string>('ShowFormatCode->Digital_3dHfr'),
    [ShowFormatCode.LiveStream]: t<string>('ShowFormatCode->LiveStream'),
    [ShowFormatCode.Record]: t<string>('ShowFormatCode->Record'),
    [ShowFormatCode.Live]: t<string>('ShowFormatCode->Live')
  }
  return (code: ShowFormatCode) => translatedCodes[code]
}

export const useTranslateShowFormatAbbreviation = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowFormatCode, string> = {
    [ShowFormatCode.Analog_16mm]: t<string>(
      'ShowFormatCodeAbbreviation->Analog_16mm'
    ),
    [ShowFormatCode.Analog_35mm]: t<string>(
      'ShowFormatCodeAbbreviation->Analog_35mm'
    ),
    [ShowFormatCode.Analog_70mm]: t<string>(
      'ShowFormatCodeAbbreviation->Analog_70mm'
    ),
    [ShowFormatCode.Digital_2d]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_2d'
    ),
    [ShowFormatCode.Digital_2d_4d]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_2d_4d'
    ),
    [ShowFormatCode.Digital_2d_4k]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_2d_4k'
    ),
    [ShowFormatCode.Digital_3d]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_3d'
    ),
    [ShowFormatCode.Digital_3d_4d]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_3d_4d'
    ),
    [ShowFormatCode.DigitalBd]: t<string>(
      'ShowFormatCodeAbbreviation->DigitalBd'
    ),
    [ShowFormatCode.DigitalDvd]: t<string>(
      'ShowFormatCodeAbbreviation->DigitalDvd'
    ),
    [ShowFormatCode.Digital_2dHfr]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_2dHfr'
    ),
    [ShowFormatCode.Digital_3dHfr]: t<string>(
      'ShowFormatCodeAbbreviation->Digital_3dHfr'
    ),
    [ShowFormatCode.LiveStream]: t<string>(
      'ShowFormatCodeAbbreviation->LiveStream'
    ),
    [ShowFormatCode.Record]: t<string>('ShowFormatCodeAbbreviation->Record'),
    [ShowFormatCode.Live]: t<string>('ShowFormatCodeAbbreviation->Live')
  }
  return (code: ShowFormatCode) => translatedCodes[code]
}

export const useTranslateShowSoundMix = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowSoundMixCode, string> = {
    [ShowSoundMixCode.Stereo20]: t<string>('ShowSoundMixCode->Stereo20'),
    [ShowSoundMixCode.Stereo21]: t<string>('ShowSoundMixCode->Stereo21'),
    [ShowSoundMixCode.Surround51]: t<string>('ShowSoundMixCode->Surround51'),
    [ShowSoundMixCode.Surround71]: t<string>('ShowSoundMixCode->Surround71'),
    [ShowSoundMixCode.DolbyAtmos]: t<string>('ShowSoundMixCode->DolbyAtmos')
  }
  return (code: ShowSoundMixCode) => translatedCodes[code]
}

export const useTranslateShowSoundMixAbbreviation = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowSoundMixCode, string> = {
    [ShowSoundMixCode.Stereo20]: t<string>(
      'ShowSoundMixCodeAbbreviation->Stereo20'
    ),
    [ShowSoundMixCode.Stereo21]: t<string>(
      'ShowSoundMixCodeAbbreviation->Stereo21'
    ),
    [ShowSoundMixCode.Surround51]: t<string>(
      'ShowSoundMixCodeAbbreviation->Surround51'
    ),
    [ShowSoundMixCode.Surround71]: t<string>(
      'ShowSoundMixCodeAbbreviation->Surround71'
    ),
    [ShowSoundMixCode.DolbyAtmos]: t<string>(
      'ShowSoundMixCodeAbbreviation->DolbyAtmos'
    )
  }
  return (code: ShowSoundMixCode) => translatedCodes[code]
}

export const useTranslateShowVersion = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowVersionCode, string> = {
    [ShowVersionCode.Original]: t<string>('ShowVersionCode->Original'),
    [ShowVersionCode.DubbingSlk]: t<string>('ShowVersionCode->DubbingSlk'),
    [ShowVersionCode.DubbingCes]: t<string>('ShowVersionCode->DubbingCes'),
    [ShowVersionCode.DubbingEng]: t<string>('ShowVersionCode->DubbingEng'),
    [ShowVersionCode.DubbingHun]: t<string>('ShowVersionCode->DubbingHun'),
    [ShowVersionCode.DubbingDeu]: t<string>('ShowVersionCode->DubbingDeu'),
    [ShowVersionCode.DubbingPol]: t<string>('ShowVersionCode->DubbingPol'),
    [ShowVersionCode.SubtitlesSlk]: t<string>('ShowVersionCode->SubtitlesSlk'),
    [ShowVersionCode.SubtitlesCes]: t<string>('ShowVersionCode->SubtitlesCes'),
    [ShowVersionCode.SubtitlesEng]: t<string>('ShowVersionCode->SubtitlesEng'),
    [ShowVersionCode.SubtitlesHun]: t<string>('ShowVersionCode->SubtitlesHun'),
    [ShowVersionCode.SubtitlesDeu]: t<string>('ShowVersionCode->SubtitlesDeu'),
    [ShowVersionCode.SubtitlesPol]: t<string>('ShowVersionCode->SubtitlesPol'),
    [ShowVersionCode.SubtitlesCesEng]: t<string>(
      'ShowVersionCode->SubtitlesCesEng'
    ),
    [ShowVersionCode.SubtitlesCesDeu]: t<string>(
      'ShowVersionCode->SubtitlesCesDeu'
    ),
    [ShowVersionCode.SubtitlesSlkEng]: t<string>(
      'ShowVersionCode->SubtitlesSlkEng'
    ),
    [ShowVersionCode.SubtitlesSlkDeu]: t<string>(
      'ShowVersionCode->SubtitlesSlkDeu'
    ),
    [ShowVersionCode.SubtitlesSlkHun]: t<string>(
      'ShowVersionCode->SubtitlesSlkHun'
    ),
    [ShowVersionCode.Ukr]: t<string>('ShowVersionCode->Ukr'),
    [ShowVersionCode.Rue]: t<string>('ShowVersionCode->Rue'),
    [ShowVersionCode.Slk]: t<string>('ShowVersionCode->Slk'),
    [ShowVersionCode.Ces]: t<string>('ShowVersionCode->Ces'),
    [ShowVersionCode.Eng]: t<string>('ShowVersionCode->Eng'),
    [ShowVersionCode.Rus]: t<string>('ShowVersionCode->Rus'),
    [ShowVersionCode.Deu]: t<string>('ShowVersionCode->Deu'),
    [ShowVersionCode.Hun]: t<string>('ShowVersionCode->Hun'),
    [ShowVersionCode.Pol]: t<string>('ShowVersionCode->Pol')
  }
  return (code: ShowVersionCode) => translatedCodes[code]
}

export const useTranslateShowVersionAbbreviation = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ShowVersionCode, string> = {
    [ShowVersionCode.Original]: t<string>(
      'ShowVersionCodeAbbreviation->Original'
    ),
    [ShowVersionCode.DubbingSlk]: t<string>(
      'ShowVersionCodeAbbreviation->DubbingSlk'
    ),
    [ShowVersionCode.DubbingCes]: t<string>(
      'ShowVersionCodeAbbreviation->DubbingCes'
    ),
    [ShowVersionCode.DubbingEng]: t<string>(
      'ShowVersionCodeAbbreviation->DubbingEng'
    ),
    [ShowVersionCode.DubbingHun]: t<string>(
      'ShowVersionCodeAbbreviation->DubbingHun'
    ),
    [ShowVersionCode.DubbingDeu]: t<string>(
      'ShowVersionCodeAbbreviation->DubbingDeu'
    ),
    [ShowVersionCode.DubbingPol]: t<string>(
      'ShowVersionCodeAbbreviation->DubbingPol'
    ),
    [ShowVersionCode.SubtitlesSlk]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesSlk'
    ),
    [ShowVersionCode.SubtitlesCes]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesCes'
    ),
    [ShowVersionCode.SubtitlesEng]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesEng'
    ),
    [ShowVersionCode.SubtitlesHun]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesHun'
    ),
    [ShowVersionCode.SubtitlesDeu]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesDeu'
    ),
    [ShowVersionCode.SubtitlesPol]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesPol'
    ),
    [ShowVersionCode.SubtitlesCesEng]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesCesEng'
    ),
    [ShowVersionCode.SubtitlesCesDeu]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesCesDeu'
    ),
    [ShowVersionCode.SubtitlesSlkEng]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesSlkEng'
    ),
    [ShowVersionCode.SubtitlesSlkDeu]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesSlkDeu'
    ),
    [ShowVersionCode.SubtitlesSlkHun]: t<string>(
      'ShowVersionCodeAbbreviation->SubtitlesSlkHun'
    ),
    [ShowVersionCode.Ukr]: t<string>('ShowVersionCodeAbbreviation->Ukr'),
    [ShowVersionCode.Rue]: t<string>('ShowVersionCodeAbbreviation->Rue'),
    [ShowVersionCode.Slk]: t<string>('ShowVersionCodeAbbreviation->Slk'),
    [ShowVersionCode.Ces]: t<string>('ShowVersionCodeAbbreviation->Ces'),
    [ShowVersionCode.Eng]: t<string>('ShowVersionCodeAbbreviation->Eng'),
    [ShowVersionCode.Rus]: t<string>('ShowVersionCodeAbbreviation->Rus'),
    [ShowVersionCode.Deu]: t<string>('ShowVersionCodeAbbreviation->Deu'),
    [ShowVersionCode.Hun]: t<string>('ShowVersionCodeAbbreviation->Hun'),
    [ShowVersionCode.Pol]: t<string>('ShowVersionCodeAbbreviation->Pol')
  }
  return (code: ShowVersionCode) => translatedCodes[code]
}
