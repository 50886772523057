import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  PaymentMethodGroup,
  RetailPaymentMethodType
} from '../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../hooks/effectiveClientCurrencySignInputProps'
import {Theme} from '../../../../theme'
import {
  useIsNonNegativeNumber,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormSwitchWithLabels} from '../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {
  IUncontrolledFormSelectProps,
  UncontrolledFormSelect
} from '../../../form/UncontrolledFormSelect'
import {usePaymentMethodGroups} from './graphql'

import {IPaymentMethodForm, PaymentMethodField} from './types'

export enum PaymentMethodFormLocation {
  CreatePayment = 'createPayment',
  EditPayment = 'editPayment'
}

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface IPaymentMethodFormProps {
  formId: string
  className?: string
  defaultValues: Partial<IPaymentMethodForm> &
    Pick<
      IPaymentMethodForm,
      | PaymentMethodField.TYPE
      | PaymentMethodField.CURRENCY
      | PaymentMethodField.HAS_DENOMINATION
      | PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT
      | PaymentMethodField.REDUCES_BILL_TOTAL
      | PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS
      | PaymentMethodField.IS_IN_HOUSE_VOUCHER
    >
  onSubmit: (form: IPaymentMethodForm) => void
  location: PaymentMethodFormLocation
}

export const PaymentMethodForm: React.FC<IPaymentMethodFormProps> = ({
  formId,
  defaultValues,
  onSubmit,
  className,
  location
}: IPaymentMethodFormProps) => {
  const {t} = useTranslation()
  const {
    register,
    setValue,
    errors,
    watch,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IPaymentMethodForm>({
    defaultValues
  })
  const classes = useStyles()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isNonNegativeNumber = useIsNonNegativeNumber()
  const {data: paymentMethodGroupsData} = usePaymentMethodGroups()
  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  const paymentMethodType = watch(PaymentMethodField.TYPE)
  const hasDenomination = watch(PaymentMethodField.HAS_DENOMINATION)
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(className, classes.form)}
    >
      <input name={PaymentMethodField.TYPE} type="hidden" ref={register()} />
      <input
        name={PaymentMethodField.CURRENCY}
        type="hidden"
        ref={register()}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IPaymentMethodForm>
            register={register}
            name={PaymentMethodField.NAME}
            key={PaymentMethodField.NAME}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
            disabled={location === PaymentMethodFormLocation.EditPayment}
          />
        ]}
      />
      {paymentMethodType === RetailPaymentMethodType.Voucher && (
        <InputRow
          nodes={[
            <FormSwitchWithLabels<IPaymentMethodForm>
              key={PaymentMethodField.HAS_DENOMINATION}
              primaryLabel={t('Set nominal value?')}
              secondaryLabel={t('Allows to accept specified denomination.')}
              name={PaymentMethodField.HAS_DENOMINATION}
              control={control}
              watch={watch}
              enabledText={t('Yes')}
              disabledText={t('No')}
              defaultValue={defaultValues[PaymentMethodField.HAS_DENOMINATION]}
              disabled={location === PaymentMethodFormLocation.EditPayment}
            />
          ]}
        />
      )}
      {hasDenomination && (
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IPaymentMethodForm>
              InputProps={currencySignInputProps}
              register={register}
              name={PaymentMethodField.VALUE}
              key={PaymentMethodField.VALUE}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Value')}
              validationOptions={{
                required: true,
                validate: {
                  isNonNegativeNumber
                }
              }}
              required
              fullWidth
              disabled={location === PaymentMethodFormLocation.EditPayment}
            />
          ]}
        />
      )}
      {paymentMethodType === RetailPaymentMethodType.Voucher && (
        <InputRow
          nodes={[
            <FormSwitchWithLabels<IPaymentMethodForm>
              key={PaymentMethodField.IS_IN_HOUSE_VOUCHER}
              primaryLabel={t('Vouchers emitted by Entradio?')}
              secondaryLabel={''}
              name={PaymentMethodField.IS_IN_HOUSE_VOUCHER}
              control={control}
              watch={watch}
              enabledText={t('Yes')}
              disabledText={t('No')}
              defaultValue={
                defaultValues[PaymentMethodField.IS_IN_HOUSE_VOUCHER]
              }
              disabled={location === PaymentMethodFormLocation.EditPayment}
            />
          ]}
        />
      )}
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IPaymentMethodForm>
            key={PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT}
            primaryLabel={t('Enable cashback?')}
            secondaryLabel={t(
              'Allows cashback, if the payment exceeds the value of the purchase'
            )}
            name={PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT}
            control={control}
            watch={watch}
            enabledText={t('Yes')}
            disabledText={t('No')}
            defaultValue={
              defaultValues[PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT]
            }
            disabled={location === PaymentMethodFormLocation.EditPayment}
          />
        ]}
      />

      <InputRow
        nodes={[
          <FormSwitchWithLabels<IPaymentMethodForm>
            key={PaymentMethodField.REDUCES_BILL_TOTAL}
            primaryLabel={t('Reduces bill total?')}
            secondaryLabel={t(
              'Total amount of the bill will be reduced by the value of the payment method. The setting is used to avoid double taxation if necessary. For more information about double taxation, please contact your accountant.'
            )}
            name={PaymentMethodField.REDUCES_BILL_TOTAL}
            control={control}
            watch={watch}
            enabledText={t('Yes')}
            disabledText={t('No')}
            defaultValue={defaultValues[PaymentMethodField.REDUCES_BILL_TOTAL]}
            disabled={location === PaymentMethodFormLocation.EditPayment}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IPaymentMethodForm>
            key={PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS}
            primaryLabel={t('Available for refunds?')}
            secondaryLabel={t(
              'Allows payment method to be used also for returning funds to the customer.'
            )}
            name={PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS}
            control={control}
            watch={watch}
            enabledText={t('Yes')}
            disabledText={t('No')}
            defaultValue={
              defaultValues[PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]
            }
          />
        ]}
      />

      <InputRow
        nodes={[
          <UncontrolledFormSelect<IPaymentMethodForm>
            register={register}
            name={PaymentMethodField.GROUP_ID}
            key={PaymentMethodField.GROUP_ID}
            errors={errors}
            setValue={setValue}
            watch={watch}
            label={t('Group')}
            fullWidth
            selectOptions={(
              paymentMethodGroupsData?.paymentMethodGroups || []
            ).reduce(
              (
                acc: IUncontrolledFormSelectProps['selectOptions'],
                pmg: PaymentMethodGroup
              ) => ({
                ...acc,
                [pmg.id]: pmg.name
              }),
              {}
            )}
            hasNoneSelectOption
          />
        ]}
      />
    </form>
  )
}
