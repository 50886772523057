import {useTranslation} from 'react-i18next'
import {VoucherCampaignState} from '../__generated__/schema'

export const useTranslateVoucherCampaignState = () => {
  const {t} = useTranslation()
  const translatedStates: Record<VoucherCampaignState, string> = {
    [VoucherCampaignState.Active]: t<string>('VoucherCampaignState->Active'),
    [VoucherCampaignState.Inactive]: t<string>(
      'VoucherCampaignState->Inactive'
    ),
    [VoucherCampaignState.Invalid]: t<string>('VoucherCampaignState->Invalid')
  }
  return (state: VoucherCampaignState) => translatedStates[state]
}

export const useTranslateVoucherCampaignStateDescription = () => {
  const {t} = useTranslation()
  const translatedStates: Record<VoucherCampaignState, string> = {
    [VoucherCampaignState.Active]: t<string>(
      'Active vouchers in campaign can be redeemed.'
    ),
    [VoucherCampaignState.Inactive]: t<string>(
      'Campaign was not activated yet and it is not allowed to redeem vouchers.'
    ),
    [VoucherCampaignState.Invalid]: t<string>(
      'It is not allowed to redeem any voucher from this campaign, even if voucher is active.'
    )
  }
  return (state: VoucherCampaignState) => translatedStates[state]
}
