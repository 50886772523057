import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  StaticSideNavigationList
} from '..'

import {LeadOptionPropertiesFragment} from '../../../__generated__/schema'
import {LeadSection, useTranslateLeadSection} from '../../../hooks/leadSection'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../theme'
import {CancelButton, SaveButton} from '../Buttons'
import {useUpdateLeadOptions} from './graphql'
import {LeadSettingsForm} from './LeadSettingsForm'
import {ILeadSettingsForm, transformLeadOptionsFormToInput} from './types'

const LEAD_SETTINGS_FORM_ID = 'lead_settings_form_id'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 960,
    width: '100%'
  },
  drawerGrid: {
    display: 'grid',
    minHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    gridTemplateColumns: '250px 1fr',
    padding: theme.spacing(0, 3, 3, 0)
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden'
  },
  sideNavigationBox: {
    position: 'relative'
  },
  staticSideNavigationList: {
    top: theme.spacing(3),
    position: 'sticky'
  }
}))

interface ILeadSettingsDrawerProps {
  closeDrawer(): void
  onExited(): void
  title: string
  isOpen: boolean
  leadOptions: LeadOptionPropertiesFragment[] | null
}

export const LeadSettingsDrawer: React.FC<ILeadSettingsDrawerProps> = ({
  closeDrawer,
  leadOptions,
  title,
  onExited,
  isOpen
}: ILeadSettingsDrawerProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateLeadSection = useTranslateLeadSection()
  const updateLeadOptions = useUpdateLeadOptions()
  const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (form: ILeadSettingsForm) => {
      try {
        setShowBackdrop(true)
        await updateLeadOptions({
          options: transformLeadOptionsFormToInput(form)
        })
        closeDrawer()
        addInfoNotification(t('Lead options updated successfully'))
      } catch (e) {
        defaultErrorHandler(e, t('Error while trying to update lead options'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDrawer,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateLeadOptions
    ]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      classes={{
        paper: classes.drawerPaper
      }}
      SlideProps={{
        onExited
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader title={title} onLeftActionClick={closeDrawer} />
        }
        footer={
          <>
            <CancelButton onClick={closeDrawer} />
            <SaveButton type="submit" form={LEAD_SETTINGS_FORM_ID} />
          </>
        }
      >
        <div className={classes.drawerGrid}>
          <div className={classes.sideNavigationBox}>
            <StaticSideNavigationList
              items={[
                LeadSection.LEAD,
                LeadSection.COMPANY,
                LeadSection.BILLING_ADDRESS,
                LeadSection.DELIVERY_ADDRESS
              ].reduce(
                (acc, leadSection) => ({
                  ...acc,
                  [leadSection]: {
                    id: leadSection,
                    label: translateLeadSection(leadSection)
                  }
                }),
                {}
              )}
              className={classes.staticSideNavigationList}
            />
          </div>
          {leadOptions && (
            <LeadSettingsForm
              className={classes.form}
              leadOptions={leadOptions}
              formId={LEAD_SETTINGS_FORM_ID}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </DrawerTemplate>
    </Drawer>
  )
}
