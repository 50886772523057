import {useTranslation} from 'react-i18next'

import {SellingChannel} from '../__generated__/schema'

export const useTranslateSellingChannel = () => {
  const {t} = useTranslation()
  const sellingChannel: Record<SellingChannel, string> = {
    [SellingChannel.Retail]: t<string>('SellingChannel->Retail'),
    [SellingChannel.ECommerce]: t<string>('SellingChannel->ECommerce')
  }
  return (channel: SellingChannel) => sellingChannel[channel]
}
