import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {Button, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AdmissionTypesFilter,
  AdmissionTypesQuery,
  AdmissionTypeState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateAdmissionTypeState} from '../../../../hooks/admissionTypeState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {AdmissionTypeIcon, getIcon} from '../../../common/admissionTypeIcon'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {ColorBox} from '../../../common/ColorBox'
import {DataGridTable} from '../../../common/DataGridTable'
import {IconBox} from '../../../common/IconBox'
import {admissionTypeStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {WideCenteredLayout} from '../Layout'
import {useAdmissionTypes} from './graphql'

const IconCellRenderer = ({
  icon,
  color
}: {
  icon?: AdmissionTypeIcon
  color: string
}) => (
  <>
    {icon ? (
      <IconBox
        Icon={getIcon(icon)}
        hexColor={color}
        hideBackground
        sx={{width: 24, height: 24}}
        iconSx={{width: 24, height: 24}}
      />
    ) : (
      <ColorBox hexColor={color} />
    )}
  </>
)

const StateCellRenderer = ({value}: {value?: AdmissionTypeState}) => {
  const translateAdmissionTypeState = useTranslateAdmissionTypeState()
  return (
    <>
      {value && (
        <EntityStateChip
          label={translateAdmissionTypeState(value)}
          colorConf={admissionTypeStateColors[value]}
          isDotHidden
        />
      )}
    </>
  )
}

const EditCellRenderer = ({value}: {value?: number}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleButtonClick = useCallback(
    () => value && history.push(routeTo.admin.admissionTypes.edit(value)),
    [history, value]
  )
  return (
    <>
      {value &&
        P([
          PermissionCode.ReadAdmissionType,
          PermissionCode.UpdateAdmissionType,
          PermissionCode.UpdateDraftAdmissionType
        ]) && (
          <IconButton color="primary" size="small" onClick={handleButtonClick}>
            <EditIcon />
          </IconButton>
        )}
    </>
  )
}

interface IAdmissionTypesListProps {
  onCreateButtonClick: () => void
  searchFilter: AdmissionTypesFilter
}

export const AdmissionTypesList: React.FC<IAdmissionTypesListProps> = ({
  onCreateButtonClick,
  searchFilter
}: IAdmissionTypesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useAdmissionTypes({...searchFilter})
  const hasPermissionToCreateAdmissionType = P([
    PermissionCode.CreateAdmissionType,
    PermissionCode.ReadAdmissionType
  ])
  const fabClasses = useFabClasses()
  const column: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Icon'),
        field: 'icon',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer icon={params.value} color={params.row.color} />
          )
        },
        width: 64
      },
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 250,
        flex: 1
      },
      {
        headerName: t('Abbreviation'),
        field: 'abbreviation',
        minWidth: 150,
        flex: 0.5
      },
      {
        headerName: t('Attendees'),
        field: 'capacityDecreaseCount',
        type: 'number',
        minWidth: 150,
        flex: 0.5
      },
      {
        headerName: t('Minimal quantity'),
        field: 'startingQuantity',
        type: 'number',
        minWidth: 150,
        flex: 0.5
      },
      {
        headerName: t('State'),
        field: 'state',
        minWidth: 150,
        renderCell: StateCellRenderer,
        flex: 0.5
      },
      {
        headerName: t('Internal description'),
        field: 'internalDescription',
        minWidth: 300,
        flex: 1
      },
      {
        headerName: '',
        field: 'id',
        renderCell: EditCellRenderer,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 32,
        hideable: false
      }
    ],
    [t]
  )
  return (
    <RenderOnData<AdmissionTypesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading admission types')}
      ignoreLoadingIfData
      dataCondition={(data) => Array.isArray(data.admissionTypes.items)}
    >
      {({admissionTypes}) => (
        <>
          <WideCenteredLayout
            sx={{
              height: `calc(100% - 64px)`,
              width: '100%',
              p: 3
            }}
          >
            {admissionTypes.items.length > 0 ? (
              <DataGridTable
                columns={column}
                rows={admissionTypes.items}
                disableColumnMenu
                pageSizeOptions={[10, 50, 100]}
                initialState={{
                  pinnedColumns: {left: ['icon', 'name'], right: ['id']},
                  pagination: {paginationModel: {pageSize: 10}},
                  columns: {
                    columnVisibilityModel: {
                      id: P([
                        PermissionCode.ReadAdmissionType,
                        PermissionCode.UpdateAdmissionType,
                        PermissionCode.UpdateDraftAdmissionType
                      ])
                    }
                  }
                }}
              />
            ) : (
              <Blank
                title={t('No admission types found')}
                actions={
                  hasPermissionToCreateAdmissionType && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={onCreateButtonClick}
                    >
                      {t('Create')}
                    </Button>
                  )
                }
              />
            )}
          </WideCenteredLayout>
          {hasPermissionToCreateAdmissionType &&
            admissionTypes.items.length > 0 && (
              <CreateFab classes={fabClasses} onClick={onCreateButtonClick} />
            )}
        </>
      )}
    </RenderOnData>
  )
}
