import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AccessZonesFilter,
  AccessZoneState,
  WebsitesFilter
} from '../../../../__generated__/schema'
import {useTranslateAccessZoneState} from '../../../../hooks/translateAccessZoneState'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'

export const DEFAULT_ACCESS_ZONES_FILTER_INPUT: AccessZonesFilter = {
  hasText: undefined
}

const mapHasTextToFilter = (
  filter: AccessZonesFilter,
  hasText: string | undefined
): AccessZonesFilter => ({
  ...filter,
  hasText: hasText || undefined
})

const mapStateToFilter = (
  filter: WebsitesFilter,
  state?: AccessZoneState
): AccessZonesFilter => ({
  ...filter,
  state
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateAccessZoneState = useTranslateAccessZoneState()
  const getHasTextFromSearchObject = (filter: AccessZonesFilter) =>
    filter.hasText || undefined
  const getStateFromSearchObject = (filter: AccessZonesFilter) =>
    filter.state
      ? t('State: {{state}}', {state: translateAccessZoneState(filter.state)})
      : undefined
  return {getHasTextFromSearchObject, getStateFromSearchObject}
}

interface IAccessZonesSearchProps {
  onFilterChange: (filter: AccessZonesFilter) => void
}

export const AccessZonesSearch: React.FC<IAccessZonesSearchProps> = ({
  onFilterChange
}: IAccessZonesSearchProps) => {
  const {t} = useTranslation()
  const translateAccessZoneState = useTranslateAccessZoneState()
  const {getHasTextFromSearchObject, getStateFromSearchObject} =
    useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject,
    getStateFromSearchObject
  )
  const stateOptions: ISelectOption<AccessZoneState>[] = [
    AccessZoneState.Active,
    AccessZoneState.Inactive
  ].map((state) => ({id: state, label: translateAccessZoneState(state)}))
  return (
    <Search<AccessZonesFilter>
      storageKey="ACCESS_ZONES"
      placeholder={t('Search for access zone')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_ACCESS_ZONES_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<AccessZonesFilter, AccessZoneState>
            value={advancedSearchObject.state || undefined}
            label={t('State')}
            options={stateOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapStateToFilter}
          />
          <AdvancedSearchTextRow<WebsitesFilter>
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
