import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Auditorium,
  ClientShow,
  Division,
  Event,
  Venue
} from '../../../__generated__/schema'
import {useTranslateGenre} from '../../../hooks/showGenres'
import {useTranslateAgeClassification} from '../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../../../hooks/translateDistributions'
import {useTranslateShowContentDescriptorCode} from '../../../hooks/translateShowContentDescriptorCode'
import {useGetUserLocaleTranslation} from '../../../hooks/useGetUserLocaleTranslation'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../utils/formatting'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple
} from '../DrawerUtils'

const getVenueAddressString = (venue: Pick<Venue, 'name' | 'address'>) =>
  [
    venue.name,
    venue.address.complex,
    venue.address.street,
    venue.address.postalCode,
    venue.address.town
  ]
    .filter(Boolean)
    .join(', ')

type EventPick = Pick<
  Event,
  | 'names'
  | 'startsAt'
  | 'endsAt'
  | 'duration'
  | 'formatCode'
  | 'soundMixCode'
  | 'versionCode'
  | 'ageClassificationCode'
> & {
  show: Pick<
    ClientShow,
    | 'genreCodes'
    | 'globalReleaseDate'
    | 'productionYear'
    | 'contentDescriptorCodes'
  >
} & {
  auditorium: Pick<Auditorium, 'name'>
} & {
  division: Pick<Division, 'name'>
} & {
  venue: Pick<Venue, 'name' | 'address'>
}

interface IEventDrawerSectionProps<T extends EventPick> {
  event: T
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const EventDrawerSection = <T extends EventPick>({
  event,
  drawerSectionProps
}: IEventDrawerSectionProps<T>) => {
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const translateShowFormat = useTranslateShowFormat()
  const translateShowVersion = useTranslateShowVersion()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const translateAgeClassification = useTranslateAgeClassification()
  const translateGenre = useTranslateGenre()
  const {formatTime, formatDate} = useDateTimeFormatters()
  const {durationToMinutesFormat, durationToHoursMinutesLongFormat} =
    useShowDurationFormatters()
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  const {t} = useTranslation()
  return (
    <DrawerSection {...drawerSectionProps}>
      <SectionGrid>
        <SectionGridCouple label={t('Title')}>
          {getUserLocaleTranslation(event.names)}
        </SectionGridCouple>
        {Boolean(event.show.genreCodes.length) && (
          <SectionGridCouple label={t('Genre')}>
            {event.show.genreCodes
              .map((code) => translateGenre(code))
              .join(', ')}
          </SectionGridCouple>
        )}
        <SectionGridCouple label={t('Date')}>
          {formatDate(new Date(event.startsAt))}
        </SectionGridCouple>
        <SectionGridCouple label={t('Time')}>
          {`${formatTime(new Date(event.startsAt))} (${t('Ends at {{time}}', {
            time: formatTime(new Date(event.endsAt))
          })})`}
        </SectionGridCouple>
        <SectionGridCouple label={t('Duration')}>
          {`${durationToHoursMinutesLongFormat(
            event.duration
          )}, (${durationToMinutesFormat(event.duration)})`}
        </SectionGridCouple>
        {event.formatCode && (
          <SectionGridCouple label={t('Format')}>
            {translateShowFormat(event.formatCode)}
          </SectionGridCouple>
        )}
        {event.soundMixCode && (
          <SectionGridCouple label={t('Sound')}>
            {translateShowSoundMix(event.soundMixCode)}
          </SectionGridCouple>
        )}
        {event.versionCode && (
          <SectionGridCouple label={t('Version')}>
            {translateShowVersion(event.versionCode)}
          </SectionGridCouple>
        )}
        {event.ageClassificationCode && (
          <SectionGridCouple label={t('Age restrictions')}>
            {translateAgeClassification(event.ageClassificationCode)}
          </SectionGridCouple>
        )}
        {event.show.contentDescriptorCodes &&
          event.show.contentDescriptorCodes.length > 0 && (
            <SectionGridCouple label={t('Content descriptors')}>
              {event.show.contentDescriptorCodes
                .map((code) => translateShowContentDescriptorCode(code))
                .join(', ')}
            </SectionGridCouple>
          )}
        <SectionGridCouple label={t('Auditorium')}>
          {event.auditorium.name}
        </SectionGridCouple>
        <SectionGridCouple label={t('Venue')}>
          {getVenueAddressString(event.venue)}
        </SectionGridCouple>
        <SectionGridCouple label={t('Division')}>
          {event.division.name}
        </SectionGridCouple>
        {event.show.globalReleaseDate && (
          <SectionGridCouple label={t('Premiere')}>
            {formatDate(new Date(event.show.globalReleaseDate))}
          </SectionGridCouple>
        )}
        <SectionGridCouple label={t('Production year')}>
          {event.show.productionYear}
        </SectionGridCouple>
      </SectionGrid>
    </DrawerSection>
  )
}
