import {useTranslation} from 'react-i18next'

import {DiscountCodeState} from '../__generated__/schema'

export const useTranslateDiscountCodeState = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<DiscountCodeState, string> = {
    [DiscountCodeState.Inactive]: t<string>('DiscountCodeState->Inactive'),
    [DiscountCodeState.Active]: t<string>('DiscountCodeState->Active'),
    [DiscountCodeState.Invalid]: t<string>('DiscountCodeState->Invalid')
  }
  return (code: DiscountCodeState) => translatedCodes[code]
}
