import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {AccessZoneFormField, IAccessZoneForm} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IAccessZoneFormProps {
  formId: string
  onSubmit: (data: IAccessZoneForm) => Promise<void>
  defaultValues?: Partial<IAccessZoneForm>
}

export const AccessZoneForm: React.FC<IAccessZoneFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IAccessZoneFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IAccessZoneForm>({defaultValues, mode: 'onChange'})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAccessZoneForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={AccessZoneFormField.Name}
            key={AccessZoneFormField.Name}
            label={t('Name')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAccessZoneForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={AccessZoneFormField.Description}
            key={AccessZoneFormField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
    </StyledForm>
  )
}
