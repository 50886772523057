import GetAppIcon from '@mui/icons-material/GetApp'
import LaunchIcon from '@mui/icons-material/Launch'
import {Box, Button, IconButton} from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import {flatten, orderBy} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {GetSalesItemsQuery, PermissionCode} from '../../../__generated__/schema'
import {useTranslateClaimState} from '../../../hooks/claimState'
import {useTranslateTicketItemCheckStatus} from '../../../hooks/translateTicketItemCheckStatus'
import {useEnsurePermissions} from '../../../utils/auth'
import {useEventsPathnameParams} from '../../../utils/pathname'
import {routeTo} from '../../../utils/routes'
import {isTicketItem} from '../../pages/admin/types'
import {Blank, BlankContentPosition} from '../../visual/Blank'
import {
  DataGridTable,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  usePercentageFormatter,
  useSellingChannelFormatter
} from '../DataGridTable'
import {RenderOnData} from '../RenderOnData'
import {SubHeaderToolbar} from '../SubHeaderToolbar'
import {useGetSalesItems} from './graphql'

const useTranslateDiscountsValues = () => {
  const {t} = useTranslation()
  return (discounts: any) =>
    discounts
      ? discounts.length > 1
        ? t('{{count}} discounts', {count: discounts.length})
        : discounts[0]?.discount.name
        ? discounts[0].discount.name
        : ''
      : ''
}

const useDiscountsValuesFormatter = () => {
  const translateDiscountsValues = useTranslateDiscountsValues()
  return (params: GridValueFormatterParams) =>
    translateDiscountsValues(params.value)
}

export const useClaimStateFormatter = () => {
  const translateClaimState = useTranslateClaimState()
  return (params: GridValueFormatterParams) =>
    params.value ? translateClaimState(params.value) : ''
}

export const useTicketItemCheckStatusFormatter = () => {
  const translateTicketItemCheckStatus = useTranslateTicketItemCheckStatus()
  return (params: GridValueFormatterParams) =>
    params.value ? translateTicketItemCheckStatus(params.value) : ''
}

const IconCellRenderer = ({id}: {id: number}) => {
  const {eventId} = useEventsPathnameParams()
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(
        routeTo.admin.eventsStatistics.eventInfoTicketInfo(eventId, id)
      ),
    [eventId, history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <LaunchIcon />
    </IconButton>
  )
}

const transformRowData = (sales: GetSalesItemsQuery['sales']['items']) =>
  flatten(
    orderBy(sales, 'createdAt', 'desc').map((sale) =>
      sale.items?.filter(isTicketItem).map((item) => ({
        createdAt: sale.createdAt,
        passCode: item.passCode,
        price: item.price,
        channel: sale.sellingChannel,
        claimStatus: item.claim?.state,
        ticketType: item.eventPricingToTicketType.name,
        discounts: item.appliedDiscounts,
        vatExcluded: item.priceVatExcluded,
        vat: item.vat,
        vatRate: item.vatRate,
        label: item.eventSeat.label,
        row: item.eventSeat.row,
        section: item.eventSeat.section,
        floor: item.eventSeat.floor,
        checkStatus: item.checkStatus,
        totalChecks: item.totalPassCodeChecksCount,
        checkIns: item.checkedInCount,
        checkOuts: item.checkedOutCount,
        id: item.id
      }))
    )
  )

export const TicketsDrawerContent: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {eventId} = useEventsPathnameParams()
  const {data, loading, error} = useGetSalesItems({
    filter: {eventId},
    paginationInput: {offset: 0, limit: 1000}
  })
  const dataGridApiRef = useGridApiRef()
  const dateTimeFormatter = useDateTimeFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const claimStateFormatter = useClaimStateFormatter()
  const percentageFormatter = usePercentageFormatter(0)
  const sellingChannelFormatter = useSellingChannelFormatter()
  const ticketItemCheckStatusFormatter = useTicketItemCheckStatusFormatter()
  const discountsValuesFormatter = useDiscountsValuesFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Passcode'),
        field: 'passCode',
        minWidth: 150
      },
      {
        headerName: t('Price'),
        field: 'price',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 100
      },
      {
        headerName: t('Channel'),
        field: 'channel',
        valueFormatter: sellingChannelFormatter,
        minWidth: 150
      },
      {
        headerName: t('Ticket type'),
        field: 'ticketType',
        minWidth: 200
      },
      {
        headerName: t('Claim status'),
        field: 'claimStatus',
        valueFormatter: claimStateFormatter,
        minWidth: 200
      },
      {
        headerName: t('Discounts'),
        field: 'discounts',
        valueFormatter: discountsValuesFormatter,
        minWidth: 150
      },
      {
        headerName: t('VAT excluded'),
        field: 'vatExcluded',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 150
      },
      {
        headerName: t('VAT'),
        field: 'vat',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 150
      },
      {
        headerName: t('VAT rate'),
        field: 'vatRate',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: percentageFormatter,
        minWidth: 100
      },
      {
        headerName: t('Label'),
        field: 'label',
        minWidth: 100
      },
      {
        headerName: t('Row'),
        field: 'row',
        minWidth: 100
      },
      {
        headerName: t('Section'),
        field: 'section',
        minWidth: 100
      },
      {
        headerName: t('Floor'),
        field: 'floor',
        minWidth: 100
      },
      {
        headerName: t('Check status'),
        field: 'checkStatus',
        valueFormatter: ticketItemCheckStatusFormatter,
        minWidth: 150
      },
      {
        headerName: t('Total checks'),
        field: 'totalChecks',
        minWidth: 100
      },
      {
        headerName: t('Check-ins'),
        field: 'checkIns',
        minWidth: 100
      },
      {
        headerName: t('Check-outs'),
        field: 'checkOuts',
        minWidth: 100
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [
      claimStateFormatter,
      dateTimeFormatter,
      discountsValuesFormatter,
      effectiveClientPriceFormatter,
      percentageFormatter,
      sellingChannelFormatter,
      t,
      ticketItemCheckStatusFormatter
    ]
  )
  return (
    <RenderOnData<GetSalesItemsQuery>
      data={data}
      loading={loading}
      error={error}
      errorChildren={() => (
        <Blank
          contentPosition={BlankContentPosition.Center}
          title={t<string>('No payments to show')}
        />
      )}
      dataCondition={(data) => Array.isArray(data.sales.items)}
      ignoreLoadingIfData
    >
      {({sales}) => (
        <Box sx={{height: '100%'}}>
          <SubHeaderToolbar
            title={t('Tickets')}
            rightActions={[
              <Button
                key="download"
                variant="text"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() =>
                  dataGridApiRef.current.exportDataAsCsv({
                    fileName: `tickets_${dayjs(
                      sales.items[0].event?.startsAt
                    ).format('YYYY_MM_DD_hhmm')}_event_${eventId}`
                  })
                }
              >
                {t('Download')}
              </Button>
            ]}
          />
          <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
            <DataGridTable
              columns={columns}
              rows={transformRowData(sales.items)}
              apiRef={dataGridApiRef}
              pageSizeOptions={[10, 30, 50]}
              initialState={{
                pagination: {paginationModel: {pageSize: 30}},
                pinnedColumns: {
                  left: ['createdAt', 'passCode'],
                  right: ['id']
                }
              }}
              columnVisibilityModel={{
                id: P([PermissionCode.ReadItem])
              }}
              disableRowSelectionOnClick
              disableColumnFilter
              localeText={{noRowsLabel: t('No tickets to show')}}
            />
          </Box>
        </Box>
      )}
    </RenderOnData>
  )
}
