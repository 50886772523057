import {ButtonBase, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5)
  },
  label: {
    padding: theme.spacing(0.75, 1),
    textDecoration: 'underline'
  }
}))

interface IInputDropzoneButton {
  children: React.ReactNode
  onClick: () => void
  underlined?: boolean
}

export const InputDropzoneButton: React.FC<IInputDropzoneButton> = ({
  children,
  onClick
}: IInputDropzoneButton) => {
  const classes = useStyles()
  return (
    <ButtonBase className={classes.root} onClick={onClick}>
      <span className={classes.label}>
        <Typography>{children}</Typography>
      </span>
    </ButtonBase>
  )
}
