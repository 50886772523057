import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  PaymentsSupportQuery,
  PaymentsSupportQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const PAYMENTS_SUPPORT = gql`
  ${PAGINATION_FRAGMENT}
  query PaymentsSupport(
    $filter: PaymentsFilterInput
    $paginationInput: PaginationInput!
  ) {
    payments(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        createdAt
        channel
        amount
        surplus
        paymentMethodTypeAmounts {
          cash
          card
          wireTransfer
          voucher
          paymentGateway
        }
        transactionType
        cartId
        claimId
        clientId
        note
      }
      summary(filter: $filter) {
        amount
        surplus
        paymentMethodTypeAmounts {
          cash
          card
          wireTransfer
          voucher
          paymentGateway
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const usePaymentsForSupport = (
  variables: PaymentsSupportQueryVariables
) =>
  useQuery<PaymentsSupportQuery, PaymentsSupportQueryVariables>(
    PAYMENTS_SUPPORT,
    {
      variables,
      fetchPolicy: 'network-only'
    }
  )
