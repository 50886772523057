import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DetailEventPropertiesFragment,
  EventQuery,
  EventState
} from '../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useEventsPathnameParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {BoldCaption, EntityStateChip, RenderOnData} from '../../../../common'

import {HEIGHT as DRAWER_HEADER_HEIGHT} from '../../../../common/DrawerUtils'
import {
  eventStateColors,
  useEventStateTranslations
} from '../../../../constants'
import {useGetEvent} from '../graphql'

export const useCommonFormStyles = makeStyles<Theme>((theme) => ({
  section: {
    height: 300,
    background: theme.palette.background.paper
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  stickyNavigationWrapper: {
    position: 'sticky',
    top: DRAWER_HEADER_HEIGHT,
    height: 'fit-content',
    minWidth: 250
  },
  formWrapper: {
    flex: 1,
    padding: theme.spacing(1)
  },
  drawerFormWrapper: {
    background: '#eeeeee'
  },
  drawerForm: {
    padding: 0,
    margin: 0
  }
}))

export const useGetBackToGeneralInfoLink = () => {
  const {eventId} = useEventsPathnameParams()
  return routeTo.admin.events.edit(eventId)
}

export const useDrawerHeaderTitle = () => {
  const {eventId} = useEventsPathnameParams()
  const {data} = useGetEvent(eventId)
  const {formatDateTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {t} = useTranslation()

  if (data) {
    const title = getUserLocaleTranslation(data.event.names)
    const formattedDate = formatDateTime(new Date(data.event.startsAt))
    return `${formattedDate} | ${title}`
  }
  return t('Loading...')
}

interface IEventDrawerProps {
  children: (data: DetailEventPropertiesFragment) => React.ReactElement
}

export const LoadEventData: React.FC<IEventDrawerProps> = ({
  children
}: IEventDrawerProps) => {
  const {t} = useTranslation()
  const {eventId} = useEventsPathnameParams()
  const {data, loading, error} = useGetEvent(eventId, {
    fetchPolicy: 'network-only'
  })

  return (
    <RenderOnData
      {...{data, loading, error}}
      errorMessage={t<string>('Could not load event data.')}
    >
      {(data: EventQuery) => {
        return children(data.event)
      }}
    </RenderOnData>
  )
}

export const useIsAfterEventCreation = (eventCreatedAt: string) => {
  const {t} = useTranslation()
  const {formatDateTime} = useDateTimeFormatters()

  return (d: string) => {
    return (
      !dayjs(d).isBefore(eventCreatedAt) ||
      t<string>('eventDateMustBeGreaterThan', {
        date: formatDateTime(new Date(eventCreatedAt))
      })
    )
  }
}

const useEventStateRendererStyles = makeStyles<Theme>((theme) => ({
  chipWrapper: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  chipHelpText: {
    paddingLeft: theme.spacing(1)
  }
}))

export const StateRenderer = ({state}: {state: EventState}) => {
  const classes = useEventStateRendererStyles()
  const {t} = useTranslation()
  const eventStateTranslations = useEventStateTranslations()

  const helpText = {
    [EventState.Draft]: t('Event is only visible in events planner.'),
    [EventState.Published]: '',
    [EventState.Canceled]: '',
    [EventState.Deleted]: ''
  }[state]

  return (
    <Grid container className={classes.chipWrapper} alignItems="center">
      <EntityStateChip
        label={eventStateTranslations[state]}
        colorConf={eventStateColors[state]}
      />
      {!!helpText && (
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.chipHelpText}
        >
          {helpText}
        </Typography>
      )}
    </Grid>
  )
}

interface IEventAddressProps {
  venue: DetailEventPropertiesFragment['venue']
  auditorium: DetailEventPropertiesFragment['auditorium']
}

export const EventAddress: React.FC<IEventAddressProps> = ({
  venue,
  auditorium
}: IEventAddressProps) => {
  const {t} = useTranslation()

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container>
          <BoldCaption>{t('Auditorium:')}</BoldCaption>
          <Typography variant="caption">{auditorium.name}</Typography>
        </Grid>
      </Grid>
      <BoldCaption>{venue.name}</BoldCaption>
      <Typography variant="caption">{venue.address.complex}</Typography>
      <Typography variant="caption">{venue.address.street}</Typography>
      <Typography variant="caption">
        {venue.address.postalCode} {venue.address.town}
      </Typography>
      <Typography variant="caption">{venue.address.country}</Typography>
    </Grid>
  )
}
