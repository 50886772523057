import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientVatRegistered,
  InventoryChecksFilterInput,
  InventoryCheckState,
  InventoryCheckType,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateInventoryCheckState} from '../../../../hooks/translateInventoryCheckState'
import {useTranslateInventoryCheckType} from '../../../../hooks/translateInventoryCheckType'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {inventoryCheckStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {DivisionChip} from '../components/DivisionChip'
import {ReloadButton} from '../components/ReloadButton'
import {WarehousesChip} from '../components/WarehousesChip'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {useInventoryChecks} from './graphql'

const StateRenderer = ({state}: {state: InventoryCheckState}) => {
  const translateInventoryCheckState = useTranslateInventoryCheckState()
  return (
    <EntityStateChip
      colorConf={inventoryCheckStateColors[state]}
      label={translateInventoryCheckState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.inventoryChecks.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IInventoryChecksListProps {
  searchFilter: InventoryChecksFilterInput
}

export const InventoryChecksList: React.FC<IInventoryChecksListProps> = ({
  searchFilter
}: IInventoryChecksListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {effectiveClient} = useUserInfo()
  const [selectedDate, setSelectedDate] =
    useState<IDataPickerData | undefined>(undefined)
  const [state, setState] = useState<InventoryCheckState | undefined>(undefined)
  const [type, setType] = useState<InventoryCheckType | undefined>(undefined)
  const [divisionId, setDivisionId] = useState<number | null>(null)
  const [warehouseId, setWarehouseId] = useState<number | undefined>(undefined)
  const getFilterDateRange = useGetFilterDateRange()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useInventoryChecks({
    paginationInput,
    filter: {
      ...searchFilter,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'inventoryCheckedTillFrom',
        filterNameTo: 'inventoryCheckedTillTo'
      }),
      states: state ? [state] : undefined,
      types: type ? [type] : undefined,
      divisionIds: divisionId ? [divisionId] : undefined,
      warehouseIds: warehouseId ? [warehouseId] : undefined
    }
  })
  const translateInventoryCheckType = useTranslateInventoryCheckType()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const dateTimeFormatter = useDateTimeFormatter()
  const userNameFormatter = useUserNameFormatter()
  const translateInventoryCheckState = useTranslateInventoryCheckState()
  const isVatRegistered =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleStateChange = useCallback(
    (state?: InventoryCheckState) => {
      setState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleTypeChange = useCallback(
    (type?: InventoryCheckType) => {
      setType(type)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDivisionChange = useCallback(
    (divisionId: number | null) => {
      setDivisionId(divisionId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleWarehouseChange = useCallback(
    (warehouseId?: number) => {
      setWarehouseId(warehouseId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('ID'),
        field: 'id',
        minWidth: 100,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('Type'),
        field: 'type',
        valueFormatter: (params) => translateInventoryCheckType(params.value),
        minWidth: 150,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: InventoryCheckState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Stock on hand as of'),
        field: 'inventoryCheckedTill',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Inventory variance'),
        field: 'inventoryVariance',
        valueGetter: (params) =>
          isVatRegistered
            ? params.row.inventoryVarianceVatExcluded
            : params.row.inventoryVarianceVatIncluded,
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 150,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('Warehouse'),
        field: 'warehouse',
        valueGetter: (params) => params.row.warehouse.name,
        disableColumnMenu: true,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Division'),
        field: 'division',
        valueGetter: (params) => params.row.division.name,
        disableColumnMenu: true,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Note'),
        field: 'note',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Completed at'),
        field: 'completedAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Completed by'),
        field: 'completedBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: '',
        field: 'detail',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      isVatRegistered,
      t,
      translateInventoryCheckType,
      userNameFormatter
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading inventory checks')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ChipWithOptions<InventoryCheckState>
            key="state-chip"
            selectedItem={state}
            setSelectedItem={handleStateChange}
            options={[
              InventoryCheckState.Draft,
              InventoryCheckState.Completed
            ].map((state) => ({
              label: translateInventoryCheckState(state),
              option: state
            }))}
            allText={t('All states')}
            size="small"
          />,
          <ChipWithOptions<InventoryCheckType>
            key="type-chip"
            selectedItem={type}
            setSelectedItem={handleTypeChange}
            options={[
              InventoryCheckType.Full,
              InventoryCheckType.Controlling
            ].map((type) => ({
              label: translateInventoryCheckType(type),
              option: type
            }))}
            allText={t('All types')}
            size="small"
          />,
          <DivisionChip key="division-chip" onChange={handleDivisionChange} />,
          <WarehousesChip
            key="warehouse-chip"
            onChange={handleWarehouseChange}
          />
        ]}
        rightActions={[
          <DatePickerButton
            key="inventory-checked-till-date-picker"
            tooltip={t('Stock on hands as of')}
            label={t('Stock on hands as of')}
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            groups={[TabGroup.Now, TabGroup.Past]}
            excludedValues={[TabNow.UntilToday, TabNow.FromToday]}
            minDateFrom={
              selectedDate?.dateRange?.dateTo
                ? dayjs(selectedDate?.dateRange?.dateTo)
                    .subtract(1, 'year')
                    .startOf('d')
                : dayjs().subtract(1, 'year').startOf('d')
            }
            maxDateFrom={selectedDate?.dateRange?.dateTo || dayjs().endOf('d')}
            maxDateTo={dayjs().endOf('d')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout
        sx={{
          height: `calc(100% - 64px)`,
          width: '100%',
          p: 3
        }}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.inventoryChecks.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={{noRowsLabel: t('No inventory checks found')}}
          initialState={{
            pinnedColumns: {left: ['id'], right: ['detail']}
          }}
          columnVisibilityModel={{
            detail: P([PermissionCode.ReadInventoryCheck])
          }}
          {...getDataGridPaginationProps(data?.inventoryChecks.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}
