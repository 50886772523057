import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  BusinessPartnerQuery,
  BusinessPartnerQueryVariables,
  BusinessPartnersQuery,
  BusinessPartnersQueryVariables,
  CreateBusinessPartnerMutation,
  CreateBusinessPartnerMutationVariables,
  UpdateBusinessPartnerMutation,
  UpdateBusinessPartnerMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const BUSINESS_PARTNER_FIELDS = gql`
  fragment BusinessPartnerFields on BusinessPartner {
    id
    companyName
    state
    companyIdNumber
    taxId
    vatId
    VATRegistered
    types
    categories
    customId
    phone
    email
    website
    billingAddress {
      street
      town
      postalCode
      country
    }
    deliveryAddress {
      addressee
      street
      town
      postalCode
      country
    }
    note
    internalNote
  }
`

const BUSINESS_PARTNERS = gql`
  ${BUSINESS_PARTNER_FIELDS}
  ${PAGINATION_FRAGMENT}
  query BusinessPartners(
    $filter: BusinessPartnersFilterInput
    $paginationInput: PaginationInput
  ) {
    businessPartners(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...BusinessPartnerFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useBusinessPartners = (
  variables?: BusinessPartnersQueryVariables
) =>
  useQuery<BusinessPartnersQuery, BusinessPartnersQueryVariables>(
    BUSINESS_PARTNERS,
    {variables, fetchPolicy: 'network-only'}
  )

const CREATE_BUSINESS_PARTNER = gql`
  mutation CreateBusinessPartner($input: CreateBusinessPartnerInput!) {
    createBusinessPartner(input: $input) {
      id
      companyName
    }
  }
`

export const useCreateBusinessPartner = () => {
  const [createBusinessPartner] = useMutation<
    CreateBusinessPartnerMutation,
    CreateBusinessPartnerMutationVariables
  >(CREATE_BUSINESS_PARTNER)
  return useCallback(
    (variables: CreateBusinessPartnerMutationVariables) =>
      createBusinessPartner({
        variables
      }),
    [createBusinessPartner]
  )
}

const BUSINESS_PARTNER = gql`
  ${BUSINESS_PARTNER_FIELDS}
  query BusinessPartner($id: PositiveInt!) {
    businessPartner(id: $id) {
      ...BusinessPartnerFields
    }
  }
`

export const useBusinessPartner = (id: number) =>
  useQuery<BusinessPartnerQuery, BusinessPartnerQueryVariables>(
    BUSINESS_PARTNER,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

const UPDATE_BUSINESS_PARTNER = gql`
  ${BUSINESS_PARTNER_FIELDS}
  mutation UpdateBusinessPartner(
    $id: PositiveInt!
    $input: UpdateBusinessPartnerInput!
  ) {
    updateBusinessPartner(id: $id, input: $input) {
      ...BusinessPartnerFields
    }
  }
`

export const useUpdateBusinessPartner = () => {
  const [updateBusinessPartner] = useMutation<
    UpdateBusinessPartnerMutation,
    UpdateBusinessPartnerMutationVariables
  >(UPDATE_BUSINESS_PARTNER)
  return useCallback(
    (variables: UpdateBusinessPartnerMutationVariables) =>
      updateBusinessPartner({
        variables
      }),
    [updateBusinessPartner]
  )
}
