import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<
  Theme,
  {
    height: string | number
  }
>((theme) => ({
  divider: {
    width: 1,
    height: ({height}) => height,
    backgroundColor: theme.palette.divider
  }
}))

interface IVerticalDividerProps {
  height?: string | number
}

export const VerticalDivider: React.FC<IVerticalDividerProps> = ({
  height = 32
}: IVerticalDividerProps) => {
  const classes = useStyles({height})
  return <div className={classes.divider} />
}
