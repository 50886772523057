import {Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetVoucherQuery,
  VoucherState
} from '../../../../../__generated__/schema'
import {useFormatUserName} from '../../../../../hooks/formatUserName'
import {
  useTranslateVoucherState,
  useTranslateVoucherStateDescription
} from '../../../../../hooks/translateVoucherState'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {voucherStateColors} from '../../../../constants'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3)
  },
  state: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.spacing(1)
  }
}))

interface IGeneralSectionProps {
  id: string
  voucher: GetVoucherQuery['voucher']
}

export const GeneralSection: React.FC<IGeneralSectionProps> = ({
  id,
  voucher
}: IGeneralSectionProps) => {
  const {t} = useTranslation()
  const translateVoucherState = useTranslateVoucherState()
  const translateVoucherStateDescription = useTranslateVoucherStateDescription()
  const {formatDateTime} = useDateTimeFormatters()
  const formatUserName = useFormatUserName()
  const inactiveVoucherInfo = [
    {
      caption: t('Activation date'),
      value: voucher.activationDate
        ? formatDateTime(new Date(voucher.activationDate))
        : t('Not set')
    },
    {
      caption: t('Expiration date'),
      value: voucher.expirationDate
        ? formatDateTime(new Date(voucher.expirationDate))
        : t('Not set')
    }
  ]
  const activeVoucherInfo = [
    {
      caption: t('Activated at'),
      value: voucher.activatedAt
        ? formatDateTime(new Date(voucher.activatedAt))
        : undefined
    },
    {
      caption: t('Expiration date'),
      value: voucher.expirationDate
        ? formatDateTime(new Date(voucher.expirationDate))
        : undefined
    }
  ]
  const invalidVoucherInfo = [
    {
      caption: t('Activated at'),
      value: voucher.activatedAt
        ? formatDateTime(new Date(voucher.activatedAt))
        : undefined
    },
    {
      caption: t('Expired at'),
      value: voucher.expiredAt
        ? formatDateTime(new Date(voucher.expiredAt))
        : undefined
    }
  ]
  const classes = useStyles()
  return (
    <Paper variant="outlined" id={id} className={classes.root}>
      <Typography variant="h6">{voucher.code}</Typography>
      <div className={classes.state}>
        <EntityStateChip
          label={translateVoucherState(voucher.state)}
          colorConf={voucherStateColors[voucher.state]}
        />
        <Typography variant="caption" color="textSecondary">
          {translateVoucherStateDescription(voucher.state)}
        </Typography>
      </div>
      <SingleInfoColumn
        caption={t('Campaign')}
        value={voucher.campaign.name}
        valueTypographyVariant="body2"
      />
      <DividerSeparatedInfoRow
        information={[
          {caption: t('Balance'), value: voucher.balance},
          {caption: t('Value'), value: voucher.value},
          {caption: t('PIN'), value: voucher.pinCode},
          {
            caption: t('Created at'),
            value: formatDateTime(new Date(voucher.createdAt))
          },
          ...(voucher.state === VoucherState.Inactive
            ? inactiveVoucherInfo
            : voucher.state === VoucherState.Active
            ? activeVoucherInfo
            : invalidVoucherInfo),
          {
            caption: t('Updated'),
            value: [
              formatDateTime(new Date(voucher.updatedAt)),
              formatUserName(voucher.updatedBy)
            ].join(' • ')
          }
        ]}
      />
    </Paper>
  )
}
