import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Typography
} from '@mui/material'
import dayjs from 'dayjs'
import React, {SyntheticEvent, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {CashDeskCustomerFieldsFragment} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {DividerSeparatedInfoRow} from '../../../../common/DividerSeparatedInfoRow'

interface IBaseCustomerCardProps {
  customer: CashDeskCustomerFieldsFragment
  onCardClick?: (e: SyntheticEvent) => void
  icon: React.ReactNode
  children?: React.ReactNode
}

export const BaseCustomerCard: React.FC<IBaseCustomerCardProps> = ({
  customer,
  onCardClick,
  icon,
  children
}: IBaseCustomerCardProps) => {
  const {t} = useTranslation()
  const {formatDateNumeric} = useDateTimeFormatters()
  const {name, companyName, phone, email} = customer.lead.data
  const hasBenefitsExpired = customer.loyaltyLevelExpirationDate
    ? dayjs().isAfter(dayjs(customer.loyaltyLevelExpirationDate))
    : false
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{
        py: 1.5,
        px: 2,
        cursor: onCardClick ? 'pointer' : 'normal',
        opacity: (theme) =>
          hasBenefitsExpired ? theme.palette.action.disabledOpacity : undefined,
        pointerEvents: hasBenefitsExpired ? 'none' : 'auto'
      }}
      onClick={onCardClick}
    >
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle2">{customer.loyaltyId}</Typography>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
            {hasBenefitsExpired && (
              <Chip
                size="small"
                label={[
                  t('Expired'),
                  formatDateNumeric(
                    new Date(customer.loyaltyLevelExpirationDate)
                  )
                ].join(' ')}
              />
            )}
            {icon}
          </Box>
        </Box>
        {(name || companyName) && (
          <Typography variant="body2">
            {[name, companyName].filter(Boolean).join(', ')}
          </Typography>
        )}
        {(phone || email) && (
          <Typography variant="body2" color="textSecondary">
            {[phone, email].filter(Boolean).join(' • ')}
          </Typography>
        )}
        {children}
      </Box>
    </Paper>
  )
}

interface ICustomerCardProps
  extends Pick<IBaseCustomerCardProps, 'customer' | 'onCardClick'> {}

export const CustomerCard: React.FC<ICustomerCardProps> = ({
  customer,
  onCardClick
}: ICustomerCardProps) => {
  const {t} = useTranslation()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const {internalNote, note} = customer.lead.data
  const {state: isCardExpanded, toggle: toggleCard} = useBooleanState(false)
  const handleArrowClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleCard()
    },
    [toggleCard]
  )
  return (
    <BaseCustomerCard
      customer={customer}
      onCardClick={onCardClick}
      icon={
        <IconButton
          sx={{pointerEvents: 'auto'}}
          onClick={handleArrowClick}
          size="small"
        >
          {isCardExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      }
    >
      <Collapse in={isCardExpanded}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5, pt: 0.5}}>
          <Divider />
          {(internalNote || note) && (
            <Typography variant="caption" color="textSecondary">
              {[internalNote, note].filter(Boolean).join(' ')}
            </Typography>
          )}
          <DividerSeparatedInfoRow
            information={[
              {
                caption: t('Paid amount'),
                value: translateEffectiveClientPrice(
                  customer.loyaltyScore.totalPurchaseAmount
                )
              },
              {
                caption: t('Sold carts'),
                value: `${customer.loyaltyScore.soldCartsCount}x`
              },
              {
                caption: t('Total tickets'),
                value: `${customer.loyaltyScore.totalTicketsCount}x`
              }
            ]}
          />
        </Box>
      </Collapse>
    </BaseCustomerCard>
  )
}
