import React from 'react'
import {DetailLibraryShowPropertiesFragment} from '../../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../../hooks/getLocales'
import {getShowTranslation} from '../../../../../utils/translations'
import {ShowForm, useShowFormStyles} from '../ShowForm'
import {IShowForm, ShowFormLocation} from '../types'
import {useShowDetailsFormAnchors} from '../useShowDetailsFormAnchors'
import {getShowFormDefaultValues} from '../utils'

import {ShowSingleSideNavigationList} from './ShowSingleSideNavigationList'

export const DETAILS_STEP_FORM_ID = 'details_step_form_id'

interface IDetailsStepProps {
  libraryShow: DetailLibraryShowPropertiesFragment | null
  onSubmit(formData: IShowForm): void
  defaultTitle?: string
}

export const DetailsStep: React.FC<IDetailsStepProps> = ({
  libraryShow,
  onSubmit,
  defaultTitle
}: IDetailsStepProps) => {
  const showFormClasses = useShowFormStyles()
  const showDetailsFormAnchors = useShowDetailsFormAnchors()
  const localeCode = useClientLocaleCode()
  const defaultValues: IShowForm = getShowFormDefaultValues({
    localeCode,
    translation: getShowTranslation({
      translations: libraryShow?.translations,
      localeCode
    }),
    show: libraryShow,
    defaultTitle
  })
  return (
    <>
      <ShowSingleSideNavigationList items={showDetailsFormAnchors} />
      <ShowForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        formId={DETAILS_STEP_FORM_ID}
        className={showFormClasses.form}
        location={ShowFormLocation.ClientCreate}
      />
    </>
  )
}
