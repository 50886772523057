import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material'
import React, {SyntheticEvent, useRef} from 'react'
import {useBooleanState} from '../../hooks/state'

interface ISplitButtonProps
  extends Pick<ButtonGroupProps, 'disabled' | 'disableElevation' | 'variant'> {
  Options: Array<React.ReactNode>
  children: React.ReactNode
  disablePortal?: boolean
}

export const SplitButton: React.FC<ISplitButtonProps> = ({
  Options,
  children,
  disablePortal = true,
  ...buttonGroupProps
}: ISplitButtonProps) => {
  const {state: open, setFalse: setClose, toggle} = useBooleanState(false)
  const anchorRef = useRef(null)

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        {...buttonGroupProps}
        ref={anchorRef}
      >
        {children}
        <Button color="primary" size="small" onClick={toggle}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="top-start"
        disablePortal={disablePortal}
        sx={(theme) => ({zIndex: theme.zIndex.drawer - 1})}
      >
        {({TransitionProps}) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={setClose}>
                <MenuList>{Options}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

interface ISplitButtonItemProps {
  children: React.ReactNode
  onClick: (e: SyntheticEvent) => any
  disabled?: boolean
}

export const SplitButtonItem: React.FC<ISplitButtonItemProps> = ({
  children,
  onClick,
  disabled
}: ISplitButtonItemProps) => {
  return <MenuItem {...{disabled, onClick}}>{children}</MenuItem>
}
