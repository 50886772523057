import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {Collapse, Drawer, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {matchPath, useHistory, useLocation} from 'react-router-dom'
import {ReactComponent as Logo} from '../../../../assets/logo.svg'
import {ReactComponent as LogoutIcon} from '../../../../assets/logout.svg'
import {STONLY_WIDGET_TRIGGER} from '../../../../stonly'
import {Theme} from '../../../../theme'
import {useLogout} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {TOP_BAR_HEIGHT} from '../../../constants'
import {CategoryItem} from './CategoryItem'
import {FooterItem} from './FooterItem'
import {IMenuCategory, useMenuCategories} from './menuCategories'
import {SideMenuItem} from './SideMenuItem'

export const NO_CATEGORY_SELECTED = 'NO_CATEGORY_SELECTED'

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 320,
    background: theme.palette.background.paper,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%'
  },
  body: {
    overflowY: 'auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: TOP_BAR_HEIGHT,
    borderBottom: `solid ${theme.palette.divider} 1px`,
    padding: theme.spacing(0, 1.5)
  },
  logo: {
    marginLeft: theme.spacing(0.5)
  },
  footer: {
    borderTop: `solid ${theme.palette.divider} 1px`
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

const getActiveCategoryId = (categories: IMenuCategory[], pathname: string) =>
  categories.find((category) =>
    category.items.some(
      (item) =>
        item.to &&
        !!matchPath(pathname, {
          path: item.to
        })
    )
  )?.id || NO_CATEGORY_SELECTED

interface ISideMenuProps {
  isOpen: boolean
  onClose: () => void
}

export const SideMenu: React.FC<ISideMenuProps> = ({
  isOpen,
  onClose
}: ISideMenuProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const categories = useMenuCategories()
  const location = useLocation()
  const history = useHistory()
  const handleLogoClick = useCallback(() => {
    history.push(routeTo.admin.dashboard())
    onClose()
  }, [history, onClose])
  const [openedCategory, setOpenedCategory] = useState(
    getActiveCategoryId(categories, location.pathname)
  )
  useEffect(() => {
    if (!isOpen) {
      setOpenedCategory(getActiveCategoryId(categories, location.pathname))
    }
  }, [categories, isOpen, location.pathname])
  const logout = useLogout()
  return (
    <Drawer open={isOpen} onClose={onClose} classes={{paper: classes.paper}}>
      <div className={classes.header}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <IconButton
          onClick={handleLogoClick}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          className={classes.iconButton}
        >
          <Logo className={classes.logo} />
        </IconButton>
      </div>
      <div className={classes.body}>
        {categories.map((category) => {
          const isOpened = category.id === openedCategory
          return (
            <div key={category.id}>
              <CategoryItem
                category={category}
                isOpened={isOpened}
                onCategoryItemClick={() => {
                  if (isOpened) {
                    setOpenedCategory(NO_CATEGORY_SELECTED)
                  } else {
                    setOpenedCategory(category.id)
                  }
                }}
              />
              <Collapse in={isOpened} timeout="auto" unmountOnExit>
                {(category.items || []).map((item, itemIndex) => (
                  <SideMenuItem
                    key={itemIndex}
                    label={item.label}
                    onClick={() => {
                      history.push(item.to)
                      onClose()
                    }}
                    isActive={
                      !!item.to &&
                      !!matchPath(location.pathname, {
                        path: item.to
                      })
                    }
                  />
                ))}
              </Collapse>
            </div>
          )
        })}
      </div>
      <div className={classes.footer}>
        <FooterItem
          label={t('Help')}
          className={STONLY_WIDGET_TRIGGER}
          icon={<HelpOutlineIcon />}
        />
        <FooterItem
          label={t('Log out')}
          icon={<LogoutIcon fontSize="1.5rem" width="1em" />}
          onClick={logout}
        />
      </div>
    </Drawer>
  )
}
