import assert from 'assert'
import packageJson from '../package.json'

const graphqlServerUrl = process.env.REACT_APP_GRAPHQL_SERVER_URL
assert(graphqlServerUrl, 'Grahpql server url must be set')
const graphqlServerSubscriptionsPath =
  process.env.REACT_APP_GRAPHQL_SERVER_SUBSCRIPTIONS_PATH
assert(
  graphqlServerSubscriptionsPath,
  'Graphql server subscriptions url must be set'
)

const sentryDSN = process.env.REACT_APP_SENTRY_DSN

const isProduction = process.env.NODE_ENV === 'production'

if (isProduction) {
  assert(sentryDSN, 'Sentry DSN needs to be set')
}

export const config = {
  sentryDSN,
  isProduction,
  graphqlServerUrl,
  graphqlServerSubscriptionsPath,
  frontendProjectVersion: packageJson.version,
  frontendProjectName: packageJson.name
}
