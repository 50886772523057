import AddIcon from '@mui/icons-material/Add'
import {Button, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../theme'
import {
  CENTERED_LAYOUT_MAX_WIDTH_ON_LARGE_DESKTOP,
  MediaSizes
} from '../constants'

const useStyles = makeStyles<Theme>((theme) => ({
  topControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  addIcon: {
    marginRight: theme.spacing(1)
  }
}))

interface ICreateEntityButtonProps {
  onClick: () => void
  label: string
  isDisabled?: boolean
  cypressId?: string
}

export const TopControlsCreateButton: React.FC<ICreateEntityButtonProps> = ({
  onClick,
  label,
  isDisabled,
  cypressId
}: ICreateEntityButtonProps) => {
  const classes = useStyles()
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={isDisabled}
      cypress-id={cypressId}
    >
      <AddIcon fontSize="small" className={classes.addIcon} /> {label}
    </Button>
  )
}

interface ITopControlsProps {
  label: string
  rightChild?: React.ReactNode
}

export const TopControls: React.FC<ITopControlsProps> = ({
  label,
  rightChild
}: ITopControlsProps) => {
  const classes = useStyles()

  return (
    <div className={classes.topControls}>
      <Typography variant="h6">{label}</Typography>
      {rightChild}
    </div>
  )
}

const useSubTopbarControlsStyles = makeStyles<
  Theme,
  {
    isWithRightChildren: boolean
  }
>((theme) => ({
  paper: {
    display: 'grid'
  },
  grid: {
    display: 'grid',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    [`@media ${MediaSizes.TabletPlus}`]: {
      paddingRight: theme.spacing(11),
      paddingLeft: theme.spacing(11)
    },
    [`@media ${MediaSizes.LargeDesktop}`]: {
      maxWidth: CENTERED_LAYOUT_MAX_WIDTH_ON_LARGE_DESKTOP,
      width: '100%',
      justifySelf: 'center',
      paddingRight: 0,
      paddingLeft: 0
    },
    gridTemplateColumns: ({isWithRightChildren}) =>
      isWithRightChildren ? '1fr auto' : '1fr',
    gap: theme.spacing(3),
    minHeight: theme.spacing(8)
  }
}))

interface ISubTopbarControlsProps {
  rightChildren?: React.ReactNode
  leftChildren: React.ReactNode
}

export const SubTopbarControls: React.FC<ISubTopbarControlsProps> = ({
  rightChildren,
  leftChildren
}: ISubTopbarControlsProps) => {
  const classes = useSubTopbarControlsStyles({
    isWithRightChildren: !!rightChildren
  })
  return (
    <Paper
      variant="outlined"
      square
      classes={{
        root: classes.paper
      }}
    >
      <div className={classes.grid}>
        <div>{leftChildren}</div>
        {rightChildren && <div>{rightChildren}</div>}
      </div>
    </Paper>
  )
}

const useSubTopbarControlsChildrenWrapper = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2)
  }
}))

interface ISubTopbarControlsChildrenWrapperProps {
  children: React.ReactNode
}

export const SubTopbarControlsChildrenWrapper: React.FC<ISubTopbarControlsChildrenWrapperProps> =
  ({children}: ISubTopbarControlsChildrenWrapperProps) => {
    const classes = useSubTopbarControlsChildrenWrapper()
    return <div className={classes.grid}>{children}</div>
  }
