import {useQuery} from '@apollo/react-hooks'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import Joi from 'joi'
import {isNumber} from 'lodash'
import queryString from 'query-string'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {
  GetProductQuery,
  GetProductQueryVariables,
  ProductItemPropertiesFragment,
  ProductMode,
  ProductPropertiesFragment
} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {isProductItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {GET_PRODUCT} from './graphql'

interface IEditProductItemQuantityDialogProps {
  getProductUnitPrice: (
    product: ProductPropertiesFragment
  ) => number | undefined
  onProductItemQuantityIncrement: (productId: number, increment: number) => void
  onProductItemQuantityDecrement: (
    productItemId: number,
    decrement: number
  ) => void
  warehouseId: number | null
}

interface IQuantityDialogProps
  extends Pick<
    IEditProductItemQuantityDialogProps,
    'onProductItemQuantityIncrement' | 'onProductItemQuantityDecrement'
  > {
  productItem?: ProductItemPropertiesFragment
  product: ProductPropertiesFragment
  unitPrice: number
}

const useStyles = makeStyles<Theme>((theme) => ({
  productDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0)
  },
  operationsBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'center',
    columnGap: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  dialogActionsRoot: {
    padding: theme.spacing(1.5, 3),
    display: 'flex',
    justifyContent: 'space-between'
  },
  bold: {
    fontWeight: 500
  }
}))

const QuantityDialog: React.FC<IQuantityDialogProps> = ({
  productItem,
  product,
  unitPrice,
  onProductItemQuantityIncrement,
  onProductItemQuantityDecrement
}: IQuantityDialogProps) => {
  const productItemQuantity = productItem?.quantity ?? 0
  const {t} = useTranslation()
  const classes = useStyles()
  const [quantity, setQuantity] = useState<number>(productItemQuantity)
  const handleCancelButtonClick = useCallback(() => {
    setQuantity(0)
  }, [])
  const handleDecrementClick = useCallback(() => {
    setQuantity((prevQuantity) =>
      prevQuantity > 0 ? prevQuantity - 1 : prevQuantity
    )
  }, [])

  const handleIncrementClick = useCallback(() => {
    setQuantity((prevQuantity) => prevQuantity + 1)
  }, [])
  const handleQuantityChange = useCallback(
    (e) => {
      const parsedQuantity = parseInt(e.target.value, 10)
      const maxQuantity =
        product.mode === ProductMode.WarehouseProduct &&
        product.warehouseProducts.length > 0 &&
        !product.warehouseProducts[0].isNegativeStockEnabled &&
        parsedQuantity > product.warehouseProducts[0].stock
          ? product.warehouseProducts[0].stock
          : parsedQuantity
      setQuantity(
        !isNaN(parsedQuantity) && parsedQuantity > -1 ? maxQuantity : 0
      )
    },
    [product.warehouseProducts, product.mode]
  )
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const history = useHistory()
  const handleClose = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.shop())
  }, [history])

  const handleSaveClick = useCallback(() => {
    if (quantity > productItemQuantity) {
      onProductItemQuantityIncrement(product.id, quantity - productItemQuantity)
    } else if (quantity < productItemQuantity && productItem) {
      onProductItemQuantityDecrement(
        productItem.id,
        productItemQuantity - quantity
      )
    }
    handleClose()
  }, [
    handleClose,
    onProductItemQuantityDecrement,
    onProductItemQuantityIncrement,
    product.id,
    productItem,
    productItemQuantity,
    quantity
  ])
  const isIncrementDisabled =
    product.mode === ProductMode.WarehouseProduct &&
    product.warehouseProducts.length > 0 &&
    !product.warehouseProducts[0].isNegativeStockEnabled &&
    quantity >= product.warehouseProducts[0].stock
  return (
    <Dialog open onClose={handleClose}>
      <DialogTitleWithCloseButton onCloseClick={handleClose}>
        {t('Edit product quantity')}
      </DialogTitleWithCloseButton>
      <Divider />
      <DialogContent>
        <div className={classes.productDescription}>
          <Typography variant="subtitle2">{product.name}</Typography>
          <Typography>{translateEffectiveClientPrice(unitPrice)}</Typography>
        </div>
        <div className={classes.operationsBox}>
          <IconButton
            disabled={quantity < 1}
            color="primary"
            onClick={handleDecrementClick}
            edge="start"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          <OutlinedInputWithCancelAdornment
            inputId="quantity-input"
            label={t('Quantity')}
            inputProps={{value: quantity, onChange: handleQuantityChange}}
            onCancelClick={handleCancelButtonClick}
          />
          <IconButton
            onClick={handleIncrementClick}
            disabled={isIncrementDisabled}
            color="primary"
            edge="end"
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions
        classes={{
          root: classes.dialogActionsRoot
        }}
      >
        <Typography>
          <span className={classes.bold}>{t('Total: ')}&nbsp;</span>
          {translateEffectiveClientPrice(
            new Decimal(quantity).mul(unitPrice).toNumber()
          )}
        </Typography>
        <SaveButton onClick={handleSaveClick} />
      </DialogActions>
    </Dialog>
  )
}

const shopSearchSchema = Joi.object<{productIdForQuantityDialog?: number}>({
  productIdForQuantityDialog: Joi.number().positive().integer().optional()
})

export const EditProductItemQuantityDialog: React.FC<IEditProductItemQuantityDialogProps> =
  ({
    getProductUnitPrice,
    onProductItemQuantityIncrement,
    onProductItemQuantityDecrement,
    warehouseId
  }: IEditProductItemQuantityDialogProps) => {
    const {t} = useTranslation()
    const location = useLocation()
    const validationResult = shopSearchSchema.validate(
      queryString.parse(location.search)
    )
    const {productIdForQuantityDialog} = validationResult.value || {}
    const {data, loading, error} = useQuery<
      GetProductQuery,
      GetProductQueryVariables
    >(GET_PRODUCT, {
      skip: !productIdForQuantityDialog,
      variables: {
        id: productIdForQuantityDialog!,
        warehouseIds: warehouseId ? [warehouseId] : null
      }
    })
    const {currentCart} = useCurrentCart()
    return (
      <RenderOnData<GetProductQuery>
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading product')}
        data={data}
      >
        {({product}) => {
          const unitPrice = getProductUnitPrice(product)
          return isNumber(unitPrice) ? (
            <QuantityDialog
              onProductItemQuantityIncrement={onProductItemQuantityIncrement}
              onProductItemQuantityDecrement={onProductItemQuantityDecrement}
              product={product}
              productItem={(currentCart?.items || [])
                .filter(isProductItemPropertiesFragment)
                .find((i) => i.productId === productIdForQuantityDialog)}
              unitPrice={unitPrice}
            />
          ) : null
        }}
      </RenderOnData>
    )
  }
