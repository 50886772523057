import {IconButton, PropTypes} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {FieldValues} from 'react-hook-form'
import {Theme} from '../../theme'
import {FormValidationError} from '../common'

import {FormFieldHelper} from '../visual/common'
import {IUncontrolledFormTextInputProps, TextField} from './FormTextInput'
import {
  cloneInputModeInInputProps,
  getValueInUncontrolledFormComponent,
  useHandleBlur,
  useHandleChange,
  useHasError
} from './utils'

const useUncontrolledFormTextInputWithCustomIconStyles = makeStyles<Theme>(
  (theme) => ({
    hidden: {
      display: 'none'
    },
    root: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateAreas: `
      "cancelButton textField"
      ".            subTexts"
    `,
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto 1fr',
      alignItems: 'center',
      columnGap: theme.spacing(2),
      width: '100%'
    },
    subTexts: {
      gridArea: 'subTexts'
    },
    textField: {
      gridArea: 'textField'
    },
    iconButton: {
      gridArea: 'cancelButton',
      height: 48
    }
  })
)

interface IUncontrolledFormTextInputWithCustomIconProps<T>
  extends IUncontrolledFormTextInputProps<T> {
  icon: React.ReactNode
  iconColor?: PropTypes.Color
  iconClassName?: string
  isIconDisabled?: boolean
  onIconClick?: () => void
}

export const UncontrolledFormTextInputWithCustomIcon = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  watch,
  errors,
  setValue,
  register,
  validationOptions,
  triggerValidation,
  helperNote,
  defaultValue,
  className,
  InputProps,
  icon,
  iconClassName,
  iconColor,
  isIconDisabled,
  onIconClick,
  ...otherProps
}: IUncontrolledFormTextInputWithCustomIconProps<FormValues>) => {
  const value = getValueInUncontrolledFormComponent({name, defaultValue, watch})
  const hasError = useHasError<FormValues>(errors, name)
  const handleChange = useHandleChange<FormValues>({
    hasError,
    name,
    setValue
  })
  const handleBlur = useHandleBlur<FormValues>({name, triggerValidation})
  const classes = useUncontrolledFormTextInputWithCustomIconStyles()
  return (
    <div className={cn(classes.root, className)}>
      <IconButton
        className={cn(classes.iconButton, iconClassName)}
        onClick={onIconClick}
        color={iconColor || 'inherit'}
        disabled={isIconDisabled}
      >
        {icon}
      </IconButton>
      <input
        name={name}
        ref={validationOptions ? register(validationOptions) : register()}
        className={classes.hidden}
        defaultValue={defaultValue as string}
      />
      <TextField
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        error={hasError}
        className={classes.textField}
        InputProps={cloneInputModeInInputProps(
          InputProps,
          otherProps.inputMode
        )}
        {...otherProps}
      />
      <div className={classes.subTexts}>
        {!hasError && (
          <FormFieldHelper required={otherProps.required} note={helperNote} />
        )}
        <FormValidationError<FormValues> errors={errors} fieldName={name} />
      </div>
    </div>
  )
}
