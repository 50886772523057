import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import {orderBy} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DataExportStatisticsType,
  PaymentsFilterInput,
  PermissionCode,
  SellingChannel
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {Tooltip} from '../../../common'
import {
  DataGridTable,
  getIsNegativeNumberOrPinnedRow,
  useDataGridPagination,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useSellingChannelFormatter,
  useTransactionTypeFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {useLocale} from '../../../context/locale'
import {Error} from '../../../visual'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {
  useCreatePaymentsCsvLink,
  useExportStatisticsAsExcel,
  useGetPayments
} from './graphql'
import {MoreMenu} from './MoreMenu'
import {downloadXlsx, transformGridColDefToDataGridTableData} from './utils'

const IconCellRenderer = ({
  row: {cartId, claimId}
}: {
  row: {cartId?: number | null; claimId?: number | null}
}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const isCartWithPermission = cartId && P([PermissionCode.ReadCart])
  const isClaimWithPermission = claimId && P([PermissionCode.ReadClaim])
  const handleIconClick = useCallback(() => {
    if (cartId) {
      history.push(routeTo.admin.paymentsOverview.cartInfo(cartId))
    }
    if (claimId) {
      history.push(routeTo.admin.paymentsOverview.claimInfo(claimId))
    }
  }, [cartId, claimId, history])
  return isCartWithPermission || isClaimWithPermission ? (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  ) : null
}

interface IPaymentsOverviewPageProps {
  searchFilter: PaymentsFilterInput
}

export const PaymentsOverviewPage: React.FC<IPaymentsOverviewPageProps> = ({
  searchFilter
}: IPaymentsOverviewPageProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {localeCode} = useLocale()
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.Today
    }
  )
  const getFilterDateRange = useGetFilterDateRange()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const filter = useMemo(
    () => ({
      ...searchFilter,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'createdAtFrom',
        filterNameTo: 'createdAtTo'
      })
    }),
    [getFilterDateRange, searchFilter, selectedDate]
  )
  const {data, error, loading, refetch} = useGetPayments({
    filter,
    paginationInput
  })
  const createPaymentsCsvLink = useCreatePaymentsCsvLink()
  const exportStatisticsAsExcel = useExportStatisticsAsExcel()
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const handleDownloadCsvButtonClick = useCallback(
    async (showSalesData?: boolean) => {
      try {
        setShowBackdrop(true)
        const {data} = await createPaymentsCsvLink({
          variables: {filter, localeCode, showSalesData}
        })
        if (data) {
          window.open(data.createPaymentsCsvLink, '_blank')
        }
      } catch (e) {
        defaultErrorHandler(e, t('Generating csv failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      createPaymentsCsvLink,
      defaultErrorHandler,
      filter,
      localeCode,
      setShowBackdrop,
      t
    ]
  )
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const dateTimeFormatter = useDateTimeFormatter()
  const sellingChannelFormatter = useSellingChannelFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const effectiveClientPriceFormatterWithoutZero =
    useEffectiveClientPriceFormatter(false)
  const transactionTypeFormatter = useTransactionTypeFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Created at'),
        field: 'createdAt',
        sortable: false,
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        type: 'dateTime'
      },
      {
        headerName: t('Channel'),
        field: 'channel',
        sortable: false,
        minWidth: 150,
        valueFormatter: sellingChannelFormatter
      },
      {
        headerName: t('Type'),
        field: 'transactionType',
        sortable: false,
        minWidth: 150,
        valueFormatter: transactionTypeFormatter
      },
      {
        headerName: t('Amount'),
        field: 'amount',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('Surplus'),
        field: 'surplus',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatterWithoutZero,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('Cash'),
        field: 'paymentMethodTypeAmounts.cash',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatterWithoutZero,
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? params.row.paymentMethodTypeAmounts?.cash
            : params.value,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('Card'),
        field: 'paymentMethodTypeAmounts.card',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatterWithoutZero,
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? params.row.paymentMethodTypeAmounts?.card
            : params.value,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('Wire transfer'),
        field: 'paymentMethodTypeAmounts.wireTransfer',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatterWithoutZero,
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? params.row.paymentMethodTypeAmounts?.wireTransfer
            : params.value,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('Voucher'),
        field: 'paymentMethodTypeAmounts.voucher',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatterWithoutZero,
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? params.row.paymentMethodTypeAmounts?.voucher
            : params.value,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('Payment gateway'),
        field: 'paymentMethodTypeAmounts.paymentGateway',
        sortable: false,
        minWidth: 150,
        valueFormatter: effectiveClientPriceFormatterWithoutZero,
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? params.row.paymentMethodTypeAmounts?.paymentGateway
            : params.value,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getIsNegativeNumberOrPinnedRow,
        type: 'number'
      },
      {
        headerName: t('ID'),
        field: 'id',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 50,
        valueGetter: (params) => (params.row.id === 0 ? '' : params.row.id),
        type: 'number'
      },
      {
        headerName: t('Cashier'),
        field: 'createdByName',
        sortable: false,
        valueFormatter: (params) =>
          data?.payments.items.find((item) => item.id === params.id)
            ?.channel === SellingChannel.Retail
            ? params.value
            : '',
        minWidth: 200
      },
      {
        headerName: t('Note'),
        field: 'note',
        sortable: false,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer row={params.row} />
        },
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        width: 64
      }
    ],
    [
      transactionTypeFormatter,
      t,
      dateTimeFormatter,
      sellingChannelFormatter,
      effectiveClientPriceFormatter,
      effectiveClientPriceFormatterWithoutZero,
      data?.payments.items
    ]
  )
  const handleDownloadXslxButtonClick = useCallback(
    async (extended = false) => {
      try {
        setShowBackdrop(true)
        const {data} = await exportStatisticsAsExcel({
          dataGridTableData: {
            columns: transformGridColDefToDataGridTableData(columns, extended),
            ...(extended ? {mergeByColumn: 'id'} : {})
          },
          filter: {
            paymentStatisticsFilter: filter
          },
          statisticsType: extended
            ? DataExportStatisticsType.PaymentsExtended
            : DataExportStatisticsType.Payments
        })
        if (data) {
          downloadXlsx(data.exportStatisticsFromDatagridAsExcelFile)
        }
      } catch (e) {
        defaultErrorHandler(e, t('Generating XLSX failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      columns,
      defaultErrorHandler,
      exportStatisticsAsExcel,
      filter,
      setShowBackdrop,
      t
    ]
  )
  if (error) {
    return <Error error={error} message={t('Error while loading payments')} />
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        rightActions={[
          <DatePickerButton
            key="payment-date-picker"
            tooltip={t('Payment date')}
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.Today
            }}
            groups={[TabGroup.Now, TabGroup.Past]}
            excludedValues={[TabNow.UntilToday, TabNow.FromToday]}
            minDateFrom={
              selectedDate?.dateRange?.dateTo
                ? dayjs(selectedDate?.dateRange?.dateTo)
                    .subtract(1, 'year')
                    .startOf('d')
                : dayjs().subtract(1, 'year').startOf('d')
            }
            maxDateFrom={selectedDate?.dateRange?.dateTo || dayjs().endOf('d')}
            maxDateTo={dayjs().endOf('d')}
          />,
          <Tooltip key="refetch-button" title={t('Reload')}>
            <IconButton onClick={handleRefetchButtonClick}>
              <RefreshOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>,
          <MoreMenu
            key="more-menu"
            onPaymentsListCsvButtonClick={handleDownloadCsvButtonClick}
            onPaymentsListXlsxButtonClick={handleDownloadXslxButtonClick}
          />
        ]}
        rightActionsSx={{paddingTop: 0, paddingBottom: 0}}
      />
      <WideCenteredLayout
        sx={{
          minHeight: 512,
          width: '100%',
          padding: 3,
          '& .amount.negative': {color: 'error.main'},
          '& .bold': {fontWeight: 'bold'}
        }}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={orderBy(data?.payments.items || [], 'createdAt', 'desc')}
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{
            pinnedColumns: {left: ['createdAt'], right: ['arrow']}
          }}
          columnVisibilityModel={{
            arrow: P([PermissionCode.ReadClaim]) || P([PermissionCode.ReadCart])
          }}
          pinnedRows={{
            bottom: [
              {
                id: 0,
                amount: data?.payments.summary.amount,
                surplus: data?.payments.summary.surplus,
                'paymentMethodTypeAmounts.cash':
                  data?.payments.summary.paymentMethodTypeAmounts.cash,
                'paymentMethodTypeAmounts.card':
                  data?.payments.summary.paymentMethodTypeAmounts.card,
                'paymentMethodTypeAmounts.wireTransfer':
                  data?.payments.summary.paymentMethodTypeAmounts.wireTransfer,
                'paymentMethodTypeAmounts.voucher':
                  data?.payments.summary.paymentMethodTypeAmounts.voucher,
                'paymentMethodTypeAmounts.paymentGateway':
                  data?.payments.summary.paymentMethodTypeAmounts.paymentGateway
              }
            ]
          }}
          {...getDataGridPaginationProps(data?.payments.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}
