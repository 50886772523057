import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useCreateProductBarcode} from '../graphql'
import {BarcodeForm} from './BarcodeForm'
import {IProductBarcodeForm, ProductBarcodeFormField} from './types'

const CREATE_BARCODE_FORM_ID = 'createBarcodeForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface ICreateBarcodeDrawerProps {
  isOpen: boolean
  onClose: () => void
  productId: number
}

export const CreateBarcodeDrawer: React.FC<ICreateBarcodeDrawerProps> = ({
  isOpen,
  onClose,
  productId
}: ICreateBarcodeDrawerProps) => {
  const {t} = useTranslation()
  const createProductBarcode = useCreateProductBarcode()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const methods = useForm<IProductBarcodeForm>()
  const {setError} = methods
  const classes = useStyles()
  const handleSubmit = useCallback(
    async (data: IProductBarcodeForm) => {
      try {
        setShowBackdrop(true)
        await createProductBarcode({input: {...data, productId}})
        addInfoNotification(t('Barcode created'))
        onClose()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DuplicateProductBarcode
          )
        ) {
          setError(
            ProductBarcodeFormField.Code,
            t('Code is already used in another product.')
          )
        } else {
          defaultErrorHandler(error, t('Error while creating product barcode'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createProductBarcode,
      defaultErrorHandler,
      onClose,
      productId,
      setError,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      classes={{paper: classes.drawerPaper}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create barcode')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_BARCODE_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenClassName={classes.drawerChildren}
      >
        <FormContext {...methods}>
          <BarcodeForm
            formId={CREATE_BARCODE_FORM_ID}
            onSubmit={handleSubmit}
          />
        </FormContext>
      </DrawerTemplate>
    </Drawer>
  )
}
