import {get, uniqBy} from 'lodash'
import {useTranslation} from 'react-i18next'
import {ObjectsStateValue} from '../redux/objects/types'

export const usePlaceholderAndValue = ({
  selectedObjects,
  iteratee
}: {
  selectedObjects: ObjectsStateValue[]
  iteratee: string
}) => {
  const {t} = useTranslation()
  const isMixed =
    selectedObjects.length > 1 && uniqBy(selectedObjects, iteratee).length > 1
  return {
    placeholder: isMixed ? t<string>('Mixed values') : undefined,
    value:
      !isMixed && selectedObjects.length > 0
        ? get(selectedObjects[0], iteratee)
        : undefined
  }
}
