import {makeStyles} from '@mui/styles'
import _ from 'lodash'
import React, {useMemo} from 'react'
import {SIDE_PANEL_WIDTH} from '../../../../../../../editor/config'

import {useSelector} from '../../../../../../../editor/redux'
import {zonesSelector} from '../../../../../../../editor/redux/objects/selectors'
import {IZoneStateValue} from '../../../../../../../editor/redux/objects/types'
import {pricingSelector} from '../../../../../../../editor/redux/pricing/selectors'

import {Theme} from '../../../../../../../theme'

const useDrawerStyles = makeStyles<Theme>(() => ({
  drawerContent: {
    minWidth: SIDE_PANEL_WIDTH
  }
}))

export const SideDrawerContent = ({children}: {children: React.ReactNode}) => {
  const classes = useDrawerStyles()
  return <div className={classes.drawerContent}>{children}</div>
}

type TicketTypeCounts = {[key: number]: number}

export const useGetTicketTypeCounts = () => {
  const pricing = useSelector(pricingSelector)
  const zones = useSelector(zonesSelector)

  return useMemo(() => {
    const zonesMap = _.keyBy(zones, 'config.id')

    return Object.keys(pricing).reduce((res, id) => {
      const ticketType = pricing[id]
      const zone = id in zonesMap ? (zonesMap[id] as IZoneStateValue) : null
      const capacity = zone ? zone.config.capacity : 1
      return {
        ...res,
        [ticketType.id]:
          ticketType.id in res ? res[ticketType.id] + capacity : capacity
      }
    }, {} as TicketTypeCounts)
  }, [pricing, zones])
}
