import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CancelButton} from './Buttons'

export interface IConfirmationDialogProps {
  title: string
  contentText: string | React.ReactNode
  confirmButtonLabel?: string
  cancelButtonLabel?: string

  onConfirm(): void

  onCancel?(): void

  isOpen: boolean
  dialogProps?: Omit<DialogProps, 'open'>
  confirmButtonProps?: Omit<ButtonProps, 'onClick'>
}

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  title,
  contentText,
  confirmButtonLabel,
  cancelButtonLabel,
  isOpen,
  onConfirm,
  onCancel,
  dialogProps,
  confirmButtonProps
}: IConfirmationDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog open={isOpen} {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <CancelButton onClick={onCancel}>{cancelButtonLabel}</CancelButton>
        )}
        <Button color="primary" onClick={onConfirm} {...confirmButtonProps}>
          {confirmButtonLabel || t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
