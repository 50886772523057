import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useLocale} from '../components/context/locale'
import {CurrencySignPosition} from '../types'

export const useDateTimeFormatters = () => {
  const {localeCode} = useLocale()

  const formatDateNumeric = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const formatDate = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  /**
   * example: 11/19
   */
  const formatDateNumericShort = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        month: 'numeric',
        day: 'numeric'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const formatDateShort = useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {day: 'numeric', month: 'long'}).format(
        date
      ),
    [localeCode]
  )

  const formatTime = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        hour: '2-digit',
        minute: '2-digit'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const formatTimeWithSeconds = useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(date),
    [localeCode]
  )

  const formatTimeWithMilliseconds = useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3
      }).format(date),
    [localeCode]
  )

  const formatDateTime = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const formatDateTimeWithSeconds = useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(date),
    [localeCode]
  )

  const formatDateTimeWithMilliseconds = useCallback(
    (date: Date) =>
      Intl.DateTimeFormat(localeCode, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3
      }).format(date),
    [localeCode]
  )

  const formatWeekDay = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        weekday: 'short'
      })
      return formatter.format(date)
    },
    [localeCode]
  )
  const formatLongWeekDay = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        weekday: 'long'
      })
      return formatter.format(date)
    },
    [localeCode]
  )
  const formatMonthDay = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        day: 'numeric'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const formatMonth = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        month: 'short'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const formatMonthYear = useCallback(
    (date: Date) => {
      const formatter = Intl.DateTimeFormat(localeCode, {
        month: 'numeric',
        year: 'numeric'
      })
      return formatter.format(date)
    },
    [localeCode]
  )

  const daysToNow = useCallback(
    (daysCount: number) => {
      const formatter = new Intl.RelativeTimeFormat(localeCode, {
        style: 'narrow',
        numeric: 'auto'
      })
      return formatter.format(daysCount, 'day')
    },
    [localeCode]
  )

  return useMemo(
    () => ({
      formatDateNumericShort,
      formatDateNumeric,
      formatTime,
      formatTimeWithSeconds,
      formatDate,
      formatDateTime,
      formatDateTimeWithSeconds,
      formatLongWeekDay,
      formatWeekDay,
      formatMonth,
      formatMonthDay,
      daysToNow,
      formatTimeWithMilliseconds,
      formatDateTimeWithMilliseconds,
      formatMonthYear,
      formatDateShort
    }),
    [
      daysToNow,
      formatDate,
      formatDateNumeric,
      formatDateNumericShort,
      formatDateTime,
      formatDateTimeWithSeconds,
      formatLongWeekDay,
      formatMonth,
      formatMonthDay,
      formatTime,
      formatTimeWithSeconds,
      formatWeekDay,
      formatTimeWithMilliseconds,
      formatDateTimeWithMilliseconds,
      formatMonthYear,
      formatDateShort
    ]
  )
}

const durationToHoursAndMinutesStrings = (
  duration: number
): {hours: string; minutes: string} => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60

  return {
    hours: `${hours}`,
    minutes: minutes > 9 ? `${minutes}` : `0${minutes}`
  }
}

export const useShowDurationFormatters = () => {
  const {t} = useTranslation()

  const durationToMinutesFormat = useCallback(
    (duration: number) =>
      t('{{count}} minute', {
        count: duration
      }),
    [t]
  )

  const durationToHoursMinutesLongFormat = useCallback(
    (duration: number) => {
      const {hours, minutes} = durationToHoursAndMinutesStrings(duration)

      return `${hours}:${minutes} ${t('hours', {
        postProcess: 'interval',
        count: duration,
        context: 'show_duration'
      })}`
    },
    [t]
  )

  const durationToHoursMinutesShortFormat = useCallback((duration: number) => {
    const {hours, minutes} = durationToHoursAndMinutesStrings(duration)
    return `${hours}:${minutes}`
  }, [])

  const durationToMinutesWithAbbreviatedUnitFormat = useCallback(
    (duration: number) => t('{{duration}} m', {duration}),
    [t]
  )

  return {
    durationToMinutesFormat,
    durationToHoursMinutesShortFormat,
    durationToHoursMinutesLongFormat,
    durationToMinutesWithAbbreviatedUnitFormat
  }
}

export const formatShortenedPrice = (
  value: number,
  currencySign: string,
  currencySignPosition: CurrencySignPosition
) => {
  const fixedValue = Number.isInteger(value)
    ? value.toFixed(0)
    : value.toFixed(2)
  return currencySignPosition === CurrencySignPosition.START
    ? `${currencySign}${fixedValue}`
    : `${fixedValue} ${currencySign}`
}
