import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {IconButton, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {TFunction} from 'i18next'
import {sortBy} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartDetailPropertiesFragment,
  CartDetailReservationPropertiesFragment,
  PermissionCode,
  ReservationState
} from '../../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../../hooks/formatUserName'
import {useTranslateReservationState} from '../../../../../hooks/reservationState'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip} from '../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {COLOR_CONF} from '../../../../constants'
import {CartDetailDrawerType} from './types'

interface IReservationStateChipProps {
  state: ReservationState
}

const getColorConf = (state: ReservationState) => {
  switch (state) {
    case ReservationState.Active:
      return COLOR_CONF.BLUE
    case ReservationState.InCart:
      return COLOR_CONF.YELLOW
    case ReservationState.Fulfilled:
      return COLOR_CONF.GREEN
    case ReservationState.Expired:
    case ReservationState.Canceled:
    default:
      return COLOR_CONF.GRAY
  }
}

export const ReservationStateChip: React.FC<IReservationStateChipProps> = ({
  state
}: IReservationStateChipProps) => {
  const translateReservationState = useTranslateReservationState()
  return (
    <EntityStateChip
      isDotHidden
      colorConf={getColorConf(state)}
      label={translateReservationState(state)}
    />
  )
}

interface IReservationsSectionProps {
  cart: CartDetailPropertiesFragment
  type: CartDetailDrawerType
}

const getSecondaryReservationDescription = ({
  reservation,
  t,
  formatDateNumeric,
  formatTime
}: {
  reservation: CartDetailReservationPropertiesFragment
  t: TFunction
  formatDateNumeric: (date: Date) => string
  formatTime: (date: Date) => string
}): string => {
  const updatedAt = new Date(reservation.updatedAt)
  const expireAt = new Date(reservation.expireAt)
  switch (reservation.state) {
    case ReservationState.Canceled:
      return t('{{id}} • Deleted {{date}}, {{time}}', {
        id: reservation.id,
        date: formatDateNumeric(updatedAt),
        time: formatTime(updatedAt),
        interpolation: {escapeValue: false}
      })
    case ReservationState.Fulfilled:
      return t('{{id}} • Fulfilled {{date}}, {{time}}', {
        id: reservation.id,
        date: formatDateNumeric(updatedAt),
        time: formatTime(updatedAt),
        interpolation: {escapeValue: false}
      })
    case ReservationState.Expired:
      return t('{{id}} • Expired {{date}}, {{time}}', {
        id: reservation.id,
        date: formatDateNumeric(updatedAt),
        time: formatTime(updatedAt),
        interpolation: {escapeValue: false}
      })
    case ReservationState.Active:
    case ReservationState.InCart:
    default:
      return t('{{id}} • Expires {{date}}, {{time}}', {
        id: reservation.id,
        date: formatDateNumeric(expireAt),
        time: formatTime(expireAt),
        interpolation: {escapeValue: false}
      })
  }
}

const isTourTimeSlot = (
  item:
    | NonNullable<CartDetailReservationPropertiesFragment['event']>
    | NonNullable<CartDetailReservationPropertiesFragment['tourTimeSlot']>
): item is NonNullable<
  CartDetailReservationPropertiesFragment['tourTimeSlot']
> => item.__typename === 'TourTimeSlot'

interface IReservationItemProps {
  reservation: CartDetailReservationPropertiesFragment & {
    activity:
      | NonNullable<CartDetailReservationPropertiesFragment['event']>
      | NonNullable<CartDetailReservationPropertiesFragment['tourTimeSlot']>
  }
  type: CartDetailDrawerType
}

const useReservationItemStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(0, 2)
  },
  item: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateAreas: `
      "title                tickets iconButton"
      "primaryDescription   tickets iconButton"
      "secondaryDescription tickets iconButton"
    `,
    padding: theme.spacing(1, 0)
  },
  tickets: {
    gridArea: 'tickets',
    paddingRight: theme.spacing(0.5)
  },
  iconButton: {
    gridArea: 'iconButton'
  },
  titleCell: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}))

export const ReservationItem: React.FC<IReservationItemProps> = ({
  reservation,
  type
}: IReservationItemProps) => {
  const classes = useReservationItemStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const translateShowFormat = useTranslateShowFormatAbbreviation()
  const translateShowSoundMix = useTranslateShowSoundMixAbbreviation()
  const translateShowVersion = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const formatShortGuideName = useFormatShortGuideName()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const history = useHistory()
  const handleButtonClick = useCallback(() => {
    if (
      reservation.state === ReservationState.Fulfilled &&
      reservation.relatedSale
    ) {
      history.replace(
        routeTo.admin.carts.detail(reservation.relatedSale.cart.id)
      )
    } else if (reservation.state === ReservationState.Active) {
      history.push(routeTo.admin.cashDesk.reservationPreview(reservation.id))
    }
  }, [history, reservation.id, reservation.relatedSale, reservation.state])
  return (
    <div key={reservation.id} className={classes.item}>
      <div className={classes.titleCell}>
        <Typography variant="subtitle2" component="span">
          {isTourTimeSlot(reservation.activity)
            ? reservation.activity.tour.name
            : getUserLocaleTranslation(reservation.activity.names)}
        </Typography>
        <ReservationStateChip state={reservation.state} />
      </div>
      <Typography variant="caption" color="textSecondary">
        {isTourTimeSlot(reservation.activity)
          ? [
              formatDateNumeric(new Date(reservation.activity.startsAt)),
              formatTime(new Date(reservation.activity.startsAt)),
              reservation.activity.versionCode &&
                translateShowVersion(reservation.activity.versionCode),
              reservation.activity.ageClassificationCode &&
                translateAgeClassificationAbbreviation(
                  reservation.activity.ageClassificationCode
                ),
              reservation.activity.guide &&
                formatShortGuideName(reservation.activity.guide),
              [
                `${t('Division')}: ${reservation.division.name}, ${
                  reservation.division.address.town
                }`,
                reservation.venue &&
                  `${t('Venue')}: ${reservation.venue.name}, ${
                    reservation.venue.address.town
                  }`
              ]
                .filter(Boolean)
                .join(' • ')
            ]
              .filter(Boolean)
              .join(' • ')
          : [
              formatDateNumeric(new Date(reservation.activity.startsAt)),
              formatTime(new Date(reservation.activity.startsAt)),
              reservation.activity.formatCode &&
                translateShowFormat(reservation.activity.formatCode),
              reservation.activity.soundMixCode &&
                translateShowSoundMix(reservation.activity.soundMixCode),
              reservation.activity.versionCode &&
                translateShowVersion(reservation.activity.versionCode),
              [
                `${t('Division')}: ${reservation.division.name}, ${
                  reservation.division.address.town
                }`,
                reservation.venue &&
                  `${t('Venue')}: ${reservation.venue.name}, ${
                    reservation.venue.address.town
                  }`
              ]
                .filter(Boolean)
                .join(' • ')
            ]
              .filter(Boolean)
              .join(' • ')}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {getSecondaryReservationDescription({
          reservation,
          t,
          formatDateNumeric,
          formatTime
        })}
      </Typography>
      {reservation.state === ReservationState.Active &&
        Array.isArray(reservation.items) && (
          <Typography className={classes.tickets} variant="overline">
            {t('{{count}} ticket', {
              count: reservation.items.length
            })}
          </Typography>
        )}
      {type === CartDetailDrawerType.Cart &&
        [ReservationState.Fulfilled, ReservationState.Active].includes(
          reservation.state!
        ) &&
        P([PermissionCode.UpdateReservation]) && (
          <IconButton
            className={classes.iconButton}
            onClick={handleButtonClick}
          >
            <OpenInNewIcon color="primary" />
          </IconButton>
        )}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  noPaymentFoundRoot: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  },
  root: {
    padding: theme.spacing(0, 2)
  }
}))

export const ReservationsSection: React.FC<IReservationsSectionProps> = ({
  cart,
  type
}: IReservationsSectionProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  if ((cart.reservations || []).length === 0) {
    return (
      <Paper variant="outlined" className={classes.noPaymentFoundRoot}>
        <Typography variant="subtitle2" color="inherit">
          {t('No reservations found')}
        </Typography>
      </Paper>
    )
  }
  return (
    <Paper className={classes.root} variant="outlined">
      <ListOfItemsSeparatedByDividers>
        {(
          sortBy(
            cart.reservations || [],
            (reservation) =>
              reservation.event?.startsAt || reservation.tourTimeSlot?.startsAt
          ) || []
        ).map((reservation) =>
          reservation.event ? (
            <ReservationItem
              key={reservation.id}
              reservation={{
                ...reservation,
                activity: reservation.event
              }}
              type={type}
            />
          ) : (
            reservation.tourTimeSlot && (
              <ReservationItem
                key={reservation.id}
                reservation={{
                  ...reservation,
                  activity: reservation.tourTimeSlot
                }}
                type={type}
              />
            )
          )
        )}
      </ListOfItemsSeparatedByDividers>
    </Paper>
  )
}
