/**
 * Transforms hex color into hex with alpha. Eg '#FF0000' with opacity 0.5 to '#FF000080'
 * @param hex - use format: "#FF0000"
 * @param opacity - use number between 0 and 1
 */
export const addOpacityToHex = (hex: string, opacity: number) => {
  const alphaInHex = Math.round(opacity * 255).toString(16)
  const alphaTwoLetters =
    alphaInHex.length === 1 ? `0${alphaInHex}` : alphaInHex
  return (hex + alphaTwoLetters).toUpperCase()
}

export const convertHexToRGBA = (hexColor: string, opacity: number = 1) => {
  let hex = hexColor.replace('#', '')

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100
  }

  return `rgba(${r},${g},${b},${opacity})`
}
