import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../../../theme'
import {useCartDetailParams} from '../../../../../utils/pathname'
import {DrawerSection} from '../../../../common'
import {CartDetailDrawerTemplate} from './CartDetailDrawerTemplate'
import {General} from './General'
import {useCartSupportUser} from './graphql'
import {ItemsSection} from './ItemsSection'
import {LeadSectionGrid} from './LeadSectionGrid'
import {Payment} from './Payment'
import {ReservationsSection} from './ReservationsSection'
import {CartDetailDrawerType} from './types'
import {useNavigationItems} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  section: {
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

interface ICartSupportDetailDrawerProps {
  onExited: () => void
}

export const CartSupportDetailDrawer: React.FC<ICartSupportDetailDrawerProps> =
  ({onExited}: ICartSupportDetailDrawerProps) => {
    const {cartId} = useCartDetailParams()
    const {data, loading, error, refetch} = useCartSupportUser(cartId)
    const navigationItems = useNavigationItems(data?.cartSupportUser.state)
    const classes = useStyles()
    return (
      <>
        {data && (
          <CartDetailDrawerTemplate
            cart={data.cartSupportUser}
            onExited={onExited}
            loading={loading}
            error={error}
            navigationItems={navigationItems}
          >
            <DrawerSection
              className={classes.section}
              id={navigationItems.general.id}
            >
              <General
                cart={data.cartSupportUser}
                type={CartDetailDrawerType.CartSupport}
              />
            </DrawerSection>
            <DrawerSection
              className={classes.section}
              id={navigationItems.payment.id}
              label={navigationItems.payment.label}
            >
              <Payment cart={data.cartSupportUser} refetchCart={refetch} />
            </DrawerSection>
            {navigationItems.reservations && (
              <DrawerSection
                className={classes.section}
                id={navigationItems.reservations.id}
                label={navigationItems.reservations.label}
                isWithoutPaper
              >
                <ReservationsSection
                  cart={data.cartSupportUser}
                  type={CartDetailDrawerType.CartSupport}
                />
              </DrawerSection>
            )}
            {navigationItems.items && (
              <DrawerSection
                className={classes.section}
                id={navigationItems.items.id}
                label={navigationItems.items.label}
                isWithoutPaper
              >
                <ItemsSection
                  cart={data.cartSupportUser}
                  type={CartDetailDrawerType.CartSupport}
                />
              </DrawerSection>
            )}
            <DrawerSection
              className={classes.section}
              id={navigationItems.lead.id}
              label={navigationItems.lead.label}
            >
              <LeadSectionGrid leadData={data.cartSupportUser.lead?.data} />
            </DrawerSection>
          </CartDetailDrawerTemplate>
        )}
      </>
    )
  }
