import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CopyTourTimeSlotsInput,
  CopyTourTimeSlotsMutation,
  CopyTourTimeSlotsMutationVariables
} from '../../../../../../__generated__/schema'

export const UPDATE_TOUR_TIME_SLOTS_MUTATION = gql`
  mutation UpdateTourTimeSlots(
    $ids: [PositiveInt!]!
    $input: UpdateTourTimeSlotsInput!
  ) {
    updateTourTimeSlots(ids: $ids, input: $input) {
      id
      state
    }
  }
`

export const DELETE_TOUR_TIME_SLOTS_MUTATION = gql`
  mutation DeleteTourTimeSlots($ids: [PositiveInt!]!) {
    deleteTourTimeSlots(ids: $ids)
  }
`

const COPY_TOUR_TIME_SLOTS = gql`
  mutation CopyTourTimeSlots($input: CopyTourTimeSlotsInput!) {
    copyTourTimeSlots(input: $input) {
      id
    }
  }
`

export const useCopyTourTimeSlots = () => {
  const [copyTourTimeSlots] =
    useMutation<CopyTourTimeSlotsMutation, CopyTourTimeSlotsMutationVariables>(
      COPY_TOUR_TIME_SLOTS
    )
  return useCallback(
    (input: CopyTourTimeSlotsInput) => copyTourTimeSlots({variables: {input}}),
    [copyTourTimeSlots]
  )
}
