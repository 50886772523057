import {useMutation} from '@apollo/react-hooks'
import {ApolloError} from 'apollo-client'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiSeatState,
  ChangeEventZoneSeatsAvailabilityMutation,
  ChangeEventZoneSeatsAvailabilityMutationVariables,
  MutationChangeEventZoneSeatsAvailabilityArgs
} from '../../../../__generated__/schema'
import {TRANSLATED_LOCALES_FRAGMENT} from '../graphql'

const SEAT_OPTIONS_EVENT = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment SeatOptionsEvent on Event {
    id
    names {
      ...TranslatedLocales
    }
    startsAt
    state
    auditorium {
      id
      name
    }
    activePricing {
      id
      minPrice
      ticketTypes {
        id
        name
        description
        price
        color
        ticketTypeId
      }
    }
    auditoriumLayout {
      id
      layout
    }
    auditoriumLayoutPricing {
      id
      pricing
    }
    venue {
      id
      name
      address {
        complex
        street
        town
        postalCode
        country
      }
    }
  }
`

export const GET_SEAT_OPTIONS_EVENT = gql`
  ${SEAT_OPTIONS_EVENT}
  query GetSeatOptionsEvent($eventId: Int!) {
    event(id: $eventId) {
      ...SeatOptionsEvent
    }
  }
`

const RAW_EVENT_SEAT_FRAGMENT = gql`
  fragment RawEventSeatFields on RawEventSeat {
    id
    uuid
    state
  }
`

export const CHANGE_EVENT_SEATS_AVAILABILITY = gql`
  ${RAW_EVENT_SEAT_FRAGMENT}
  mutation changeEventSeatsAvailability(
    $eventId: PositiveInt!
    $uuids: [NonEmptyString!]!
    $state: ApiSeatState
  ) {
    changeEventSeatsAvailability(
      eventId: $eventId
      uuids: $uuids
      state: $state
    ) {
      ...RawEventSeatFields
    }
  }
`

export const CHANGE_ZONE_EVENT_SEATS_AVAILABILITY = gql`
  ${RAW_EVENT_SEAT_FRAGMENT}
  mutation ChangeEventZoneSeatsAvailability(
    $eventId: PositiveInt!
    $uuid: NonEmptyString!
    $count: PositiveInt
    $fromState: ApiSeatState!
    $toState: ApiSeatState!
    $countB: PositiveInt
    $fromStateB: ApiSeatState!
    $toStateB: ApiSeatState!
    $includeB: Boolean!
    $countC: PositiveInt
    $fromStateC: ApiSeatState!
    $toStateC: ApiSeatState!
    $includeC: Boolean!
  ) {
    changeEventZoneSeatsAvailability(
      eventId: $eventId
      uuid: $uuid
      count: $count
      fromState: $fromState
      toState: $toState
    ) {
      ...RawEventSeatFields
    }

    changeEventZoneSeatsAvailabilityB: changeEventZoneSeatsAvailability(
      eventId: $eventId
      uuid: $uuid
      count: $countB
      fromState: $fromStateB
      toState: $toStateB
    ) @include(if: $includeB) {
      ...RawEventSeatFields
    }

    changeEventZoneSeatsAvailabilityC: changeEventZoneSeatsAvailability(
      eventId: $eventId
      uuid: $uuid
      count: $countC
      fromState: $fromStateC
      toState: $toStateC
    ) @include(if: $includeC) {
      ...RawEventSeatFields
    }
  }
`

export const useChangeEventZoneSeatsAvailability = (
  setShowBackdrop: (isLoading: boolean) => void,
  defaultErrorHandler: (
    error: ApolloError,
    message: string,
    subText?: string | undefined
  ) => void
) => {
  const [changeEventZoneSeatsAvailability] = useMutation<
    ChangeEventZoneSeatsAvailabilityMutation,
    ChangeEventZoneSeatsAvailabilityMutationVariables
  >(CHANGE_ZONE_EVENT_SEATS_AVAILABILITY)
  const {t} = useTranslation()
  return useCallback(
    (
      {
        eventId,
        uuid
      }: Pick<MutationChangeEventZoneSeatsAvailabilityArgs, 'eventId' | 'uuid'>,
      changes: Pick<
        MutationChangeEventZoneSeatsAvailabilityArgs,
        'count' | 'toState' | 'fromState'
      >[]
    ) => {
      if (![1, 2, 3].includes(changes.length)) {
        throw new Error('Invalid variables!')
      }
      setShowBackdrop(true)
      return changeEventZoneSeatsAvailability({
        variables: {
          eventId,
          uuid,
          ...changes[0],
          ...(changes[1]
            ? {
                includeB: true,
                toStateB: changes[1].toState,
                countB: changes[1].count,
                fromStateB: changes[1].fromState
              }
            : {
                includeB: false,
                toStateB: ApiSeatState.Available,
                fromStateB: ApiSeatState.Disabled
              }),
          ...(changes[2]
            ? {
                includeC: true,
                toStateC: changes[2].toState,
                countC: changes[2].count,
                fromStateC: changes[2].fromState
              }
            : {
                includeC: false,
                toStateC: ApiSeatState.Available,
                fromStateC: ApiSeatState.Disabled
              })
        }
      })
        .catch((e) => {
          defaultErrorHandler(e, t('Changing zone seats availability failed'))
        })
        .finally(() => {
          setShowBackdrop(false)
        })
    },
    [changeEventZoneSeatsAvailability, defaultErrorHandler, setShowBackdrop, t]
  )
}
