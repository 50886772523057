import {useCallback, useMemo} from 'react'
import {ShowTypeCode} from '../../../../../../__generated__/schema'
import {useTranslateShowType} from '../../../../../../hooks/showTypes'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {getFilteredItems} from '../../../../../common/generalFilter/utils'

export const useShowTypeCodes = () => {
  const translateShowType = useTranslateShowType()
  const allShowTypeCodeItems: IIdListItem<ShowTypeCode>[] = useMemo(
    () =>
      Object.values(ShowTypeCode).map((c) => ({
        id: c,
        firstRow: translateShowType(c)
      })),
    [translateShowType]
  )
  const getShowTypeCodeItems = useCallback(
    (hasText?: string) => {
      return Promise.resolve(getFilteredItems(allShowTypeCodeItems, hasText))
    },
    [allShowTypeCodeItems]
  )
  const getShowTypeCodeItemsByIds = useCallback(
    (codes: ShowTypeCode[]) => {
      return Promise.resolve(
        allShowTypeCodeItems.filter((c) => codes.includes(c.id))
      )
    },
    [allShowTypeCodeItems]
  )
  return {
    getShowTypeCodeItems,
    getShowTypeCodeItemsByIds
  }
}
