import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button, Drawer} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {PermissionCode, VenueQuery} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {useVenuesPathnameParams} from '../../../../utils/pathname'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {
  IActionButton,
  IOpenModalButtonProps,
  StateChangeModal
} from '../../../common/StateChangeModal'
import {useNotifications} from '../../../context/notifications'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {AuditoriumList} from './auditoriums/AuditoriumList'
import {EntityDetailCardLayout, getVenueAddressString} from './common'
import {EditVenue} from './EditVenue'

import {useDeleteVenue, useGetVenue} from './graphql'
import {VenuesBreadcrumbs} from './VenuesBreadcrumbs'

interface IDeleteVenueButtonProps extends IActionButton {
  disabled: boolean
}

const DeleteVenueButton: React.FC<IDeleteVenueButtonProps> = ({
  id,
  disabled
}: IDeleteVenueButtonProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const deleteVenue = useDeleteVenue()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = useCallback(async () => {
    try {
      await deleteVenue({id})
      addInfoNotification(t('Venue was deleted.'))
      history.replace(routeTo.admin.venues.home())
    } catch (err) {
      defaultErrorHandler(err, t('Error while deleting the venue.'))
    }
  }, [addInfoNotification, defaultErrorHandler, deleteVenue, history, id, t])

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button
        cypress-id="delete-venue-button"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        startIcon={<DeleteIcon />}
      >
        {t('Delete')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to delete the venue?')}
      message={t('Are you sure you want to delete the venue?')}
      submitButtonText={t('Delete')}
      OpenModalButton={OpenModalButton}
      confirmationButtonCypressId="delete-venue-confirmation-button"
    />
  )
}

export const VenueDetail: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()
  const {P} = useEnsurePermissions()
  const {venueId} = useVenuesPathnameParams()
  const venueEditDrawerOpen = match.url === routeTo.admin.venues.edit(venueId)
  const {data, loading, error} = useGetVenue(venueId)

  return (
    <PageWithHeaderTemplate header={<PrimaryHeader title={t('Venues')} />}>
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData
          loading={loading}
          error={error}
          data={data}
          errorMessage={t<string>('Could not load venue')}
          dataCondition={(data: VenueQuery) => Boolean(data?.venue)}
        >
          {({venue}: VenueQuery) => (
            <CenteredLayout>
              <CenteredLayoutListWrapper>
                <VenuesBreadcrumbs
                  links={[
                    {
                      label: t('Venues'),
                      onClick: () =>
                        history.replace(routeTo.admin.venues.home())
                    },
                    {
                      label: venue.name
                    }
                  ]}
                />
                <EntityDetailCardLayout
                  header={venue.name}
                  supplementalInformation={[
                    {
                      caption: t('Secondary name'),
                      value: venue.secondaryName
                    },
                    {
                      caption: t('Address'),
                      value: getVenueAddressString(venue.address)
                    }
                  ]}
                  Buttons={[
                    P([PermissionCode.DeleteVenue]) && (
                      <DeleteVenueButton
                        id={venue.id}
                        disabled={!!venue.auditoriums.length}
                        key={1}
                      />
                    ),
                    P([PermissionCode.UpdateVenue]) && (
                      <Button
                        color="primary"
                        onClick={() =>
                          history.push(routeTo.admin.venues.edit(venueId))
                        }
                        key={2}
                        startIcon={<EditIcon />}
                      >
                        {t('Edit')}
                      </Button>
                    )
                  ].filter(Boolean)}
                />
                {P([PermissionCode.ReadAuditoriums]) && (
                  <AuditoriumList venueId={venue.id} />
                )}
              </CenteredLayoutListWrapper>
            </CenteredLayout>
          )}
        </RenderOnData>

        {data?.venue && (
          <Drawer
            anchor="right"
            open={venueEditDrawerOpen}
            onClose={history.goBack}
          >
            <EditVenue venue={data.venue} />
          </Drawer>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
