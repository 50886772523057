import {Box, SxProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../theme'

import {BOTTOM_BAR_HEIGHT, TOP_BAR_HEIGHT} from '../constants'

const useFooterStyles = makeStyles<Theme>((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderTop: `solid ${theme.palette.divider} 1px`,
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0, 3)
  }
}))

interface IFooterProps {
  children: React.ReactNode
  className?: string
  sx?: SxProps<Theme>
}

export const Footer: React.FC<IFooterProps> = ({
  children,
  className,
  sx
}: IFooterProps) => {
  const classes = useFooterStyles()
  return (
    <Box className={cn(classes.root, className)} sx={sx}>
      {children}
    </Box>
  )
}

interface IPageWithHeaderAndFooterTemplateProps {
  children: React.ReactNode
  header: React.ReactNode
  footer?: React.ReactNode
  className?: string
}

const useStyles = makeStyles<Theme, {hasFooter: boolean}>(() => ({
  grid: {
    display: 'grid',
    gridTemplateRows: ({hasFooter}) =>
      hasFooter ? `${TOP_BAR_HEIGHT}px 1fr ${BOTTOM_BAR_HEIGHT}px` : 'auto 1fr',
    height: '100vh'
  },
  children: {
    overflow: 'auto'
  }
}))

export const PageWithHeaderAndFooterTemplate: React.FC<IPageWithHeaderAndFooterTemplateProps> =
  ({
    children,
    header,
    footer,
    className
  }: IPageWithHeaderAndFooterTemplateProps) => {
    const classes = useStyles({hasFooter: Boolean(footer)})
    return (
      <div className={cn(classes.grid, className)}>
        {header}
        <div className={classes.children}>{children}</div>
        {footer}
      </div>
    )
  }
