import SettingsIcon from '@mui/icons-material/Settings'
import {IconButton} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'

interface IWebsiteSettingsButtonProps {
  websiteId: number
}

export const WebsiteSettingsButton: React.FC<IWebsiteSettingsButtonProps> = ({
  websiteId
}: IWebsiteSettingsButtonProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const history = useHistory()
  const handleGeneralSettingsClick = useCallback(() => {
    history.push(routeTo.admin.websites.edit(websiteId))
    closeMenu()
  }, [closeMenu, history, websiteId])
  const handleEcommerceSettingsClick = useCallback(() => {
    history.push(routeTo.admin.websites.editEcommerceSettings(websiteId))
    closeMenu()
  }, [closeMenu, history, websiteId])
  const handleMetadataClick = useCallback(() => {
    history.push(routeTo.admin.websites.editWebsiteMetadata(websiteId))
    closeMenu()
  }, [closeMenu, history, websiteId])
  const handleScriptsClick = useCallback(() => {
    history.push(routeTo.admin.websites.editWebsiteScripts(websiteId))
    closeMenu()
  }, [closeMenu, history, websiteId])
  const canUpdateWebsite = P([PermissionCode.UpdateWebsite])
  const canUpdateEcommerceSettings = P([
    PermissionCode.UpdateWebsiteCommonSettings,
    PermissionCode.UpdateWebsiteEcommerceSettings
  ])
  const canUpdateWebsiteMetadata = P([
    PermissionCode.UpdateWebsiteCommonSettings,
    PermissionCode.UpdateWebsiteMetadata
  ])
  const canUpdateWebsiteScripts = P([
    PermissionCode.UpdateWebsiteCommonSettings,
    PermissionCode.UpdateWebsiteScripts
  ])
  return canUpdateWebsite ||
    canUpdateEcommerceSettings ||
    canUpdateWebsiteMetadata ||
    canUpdateWebsiteScripts ? (
    <>
      <IconButton onClick={openMenu}>
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={closeMenu}
        anchorEl={anchorEl}
      >
        {canUpdateWebsite && (
          <MenuItem
            label={t('General settings')}
            onClick={handleGeneralSettingsClick}
          />
        )}
        {canUpdateEcommerceSettings && (
          <MenuItem
            label={t('Ecommerce settings')}
            onClick={handleEcommerceSettingsClick}
          />
        )}
        {canUpdateWebsiteMetadata && (
          <MenuItem label={t('Metadata')} onClick={handleMetadataClick} />
        )}
        {canUpdateWebsiteScripts && (
          <MenuItem label={t('Scripts')} onClick={handleScriptsClick} />
        )}
      </Menu>
    </>
  ) : null
}
