import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  PaymentReportViewFilterInput,
  PaymentReportViewQuery,
  PaymentReportViewQueryVariables
} from '../../../__generated__/schema'
import {USER_FIELDS} from '../../pages/admin/graphql'

const PAYMENT_REPORT_VIEW = gql`
  ${USER_FIELDS}
  query PaymentReportView($filter: PaymentReportViewFilterInput!) {
    paymentReportView(filter: $filter) {
      id
      revenueVat
      revenueVatIncluded
      revenueVatExcluded
      paymentsAmount
      paymentsSurplus
      paymentsCount
      createdAt
      reportFrom
      reportTo
      createdBy {
        ...UserFields
      }
      dataAggregatedByPaymentMethodAndTransactionType {
        paymentMethodId
        paymentMethodName
        intentsAmount
        intentsCount
        transactionTypes {
          transactionType
          intentsAmount
          intentsCount
        }
      }
      dataAggregatedByTransactionType {
        transactionType
        paymentsCount
        paymentsAmount
      }
      dataAggregatedByVatRate {
        vatRate
        revenueVatExcluded
        revenueVat
        revenueVatIncluded
      }
      dataAggregatedByDivision {
        totalSaleSum
        totalRefundSum
        totalRevenueSum
        items {
          divisionId
          divisionName
          saleSum
          refundSum
          revenueSum
        }
      }
    }
  }
`

export const usePaymentReportView = (
  filter: PaymentReportViewFilterInput,
  skip?: boolean
) =>
  useQuery<PaymentReportViewQuery, PaymentReportViewQueryVariables>(
    PAYMENT_REPORT_VIEW,
    {variables: {filter}, fetchPolicy: 'network-only', skip}
  )
