import {omit} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypeState,
  ConditionResource,
  ShowAgeClassificationCodesQuery,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowTypeCode,
  ShowVersionCode,
  TourState,
  WeekDay
} from '../../../../../__generated__/schema'
import {useTranslateShowType} from '../../../../../hooks/showTypes'
import {useTranslateAgeClassification} from '../../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMix,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersion,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useTranslateWeekDay} from '../../../../../hooks/weekDay'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../../../utils/formatting'
import {useIsFormMultiSelectRequired} from '../../../../../utils/formsValidations'
import {useGetShowTitle} from '../../../../../utils/translations'
import {RenderOnData} from '../../../../common'
import {ControlledFormCheckboxGroup} from '../../../../form/ControlledFormCheckboxGroup'
import {FormDateInput, FormTimeInput} from '../../../../form/pickers'
import {
  useGetLightweightCostCenters,
  useGetLightweightEventCategories,
  useGetLightweightMarketingLabels,
  useLightweightAuditoriums,
  useLightweightVenues,
  useShowAgeClassificationCodes
} from '../../graphql'
import {
  useAdmissionTypesForConditionResource,
  useLightweightClientShows,
  useLightweightEvents,
  useToursForConditionResource
} from '../graphql'
import {ApplicableByUserId} from './ApplicableByUserId'
import {IdConditionResourceFormInput} from './IdConditionResourceFormInput'
import {
  ConditionFormField,
  IConditionForm,
  IConditionFormInputProps,
  IControlledFormCheckboxGroupCommonProps
} from './types'

const identity = (value: string) => value

const ShowIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {data, error, loading} = useLightweightClientShows(
    searchText || undefined
  )
  const getTitle = useGetShowTitle()

  const options =
    data?.clientShows.items.map(({id, translations, originalTitle}) => ({
      name: getTitle({
        translations,
        originalTitle,
        fallbackTitle: t('Title not available')
      }),
      id
    })) || []

  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      error={error}
      loading={loading}
      options={options}
      textFieldLabel={t('Search for show')}
      errorMessage={t<string>('Failed to load shows')}
      blankMessage={t('No shows found')}
      setSearchText={setSearchText}
    />
  )
}
const MarketingLabelIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {marketingLabels, loading, error} = useGetLightweightMarketingLabels({
    hasText: searchText || undefined,
    isActive: true
  })
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for marketing label')}
      errorMessage={t<string>('Error while loading marketing labels')}
      blankMessage={t('No marketing label found')}
      options={marketingLabels.map(({id, name}) => ({name, id}))}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
    />
  )
}

const EventCategoryIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {eventCategories, loading, error} = useGetLightweightEventCategories({
    hasText: searchText || undefined,
    isActive: true
  })
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for event category')}
      errorMessage={t<string>('Error while loading event categories')}
      blankMessage={t('No event category found')}
      options={eventCategories.map(({id, name}) => ({name, id}))}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
    />
  )
}

const CostCenterIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {costCenters, loading, error} = useGetLightweightCostCenters({
    hasText: searchText || undefined,
    isActive: true
  })
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for cost center')}
      errorMessage={t<string>('Error while loading cost centers')}
      blankMessage={t('No cost center found')}
      options={costCenters.map(({id, name}) => ({name, id}))}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
    />
  )
}

const VenueIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {venues, loading, error} = useLightweightVenues({
    hasText: searchText || undefined
  })
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for venue')}
      errorMessage={t<string>('Could not load venues')}
      blankMessage={t('No venue found')}
      options={venues.map(({id, name, address}) => ({
        name,
        id,
        description: address.town
      }))}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
      sx={{gap: 1}}
    />
  )
}

const AuditoriumIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {auditoriums, loading, error} = useLightweightAuditoriums({
    name: searchText || undefined
  })
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for auditorium')}
      errorMessage={t<string>('Could not load auditoriums')}
      blankMessage={t('No auditorium found')}
      options={auditoriums.map(({id, name, venue}) => ({
        name,
        id,
        description: [venue.name, venue.address.town].join(', ')
      }))}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
      sx={{gap: 1}}
    />
  )
}

const TourIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {data, loading, error} = useToursForConditionResource(
    {
      filter: {
        states: [TourState.Active],
        hasText: searchText
      },
      paginationInput: {limit: 20, offset: 0}
    },
    'network-only'
  )
  const {durationToMinutesFormat} = useShowDurationFormatters()
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for tour')}
      errorMessage={t<string>('Error while loading tours')}
      blankMessage={t('No tour found')}
      options={(data?.tours.items || []).map(
        ({id, name, duration, division}) => ({
          name,
          id,
          description: [division.name, durationToMinutesFormat(duration)].join(
            ' • '
          )
        })
      )}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
      sx={{gap: 1, pt: 1}}
    />
  )
}

const AdmissionTypeIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {data, loading, error} = useAdmissionTypesForConditionResource(
    {
      filter: {
        states: [AdmissionTypeState.Active],
        hasText: searchText
      },
      paginationInput: {limit: 20, offset: 0}
    },
    'network-only'
  )
  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      textFieldLabel={t('Search for admission type')}
      errorMessage={t<string>('Error while loading admission types')}
      blankMessage={t('No admission type found')}
      options={(data?.admissionTypes.items || []).map(
        ({id, name, capacityDecreaseCount, startingQuantity}) => ({
          name,
          id,
          description: [
            t('Attendees: {{attendeesCount}}', {
              attendeesCount: capacityDecreaseCount
            }),
            startingQuantity > 1 &&
              t('Min: {{minCount}}', {minCount: startingQuantity})
          ]
            .filter(Boolean)
            .join(' • ')
        })
      )}
      error={error}
      loading={loading}
      setSearchText={setSearchText}
      sx={{gap: 1, pt: 1}}
    />
  )
}

const EventIdConditionResourceFormInput = (
  conditionFormInputProps: IConditionFormInputProps
) => {
  const {t} = useTranslation()
  const [searchText, setSearchText] = useState('')
  const {data, error, loading} = useLightweightEvents(searchText || undefined)
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()

  const options =
    data?.events.map((item) => ({
      name: getUserLocaleTranslation(item.names),
      description: [
        item.startsAt && formatDateNumeric(new Date(item.startsAt)),
        item.startsAt && formatTime(new Date(item.startsAt)),
        item.formatCode && translateShowFormatAbbreviation(item.formatCode),
        item.soundMixCode &&
          translateShowSoundMixAbbreviation(item.soundMixCode),
        item.versionCode && translateShowVersionAbbreviation(item.versionCode)
      ]
        .filter(Boolean)
        .join(' • '),
      id: item.id
    })) || []

  return (
    <IdConditionResourceFormInput
      conditionFormInputProps={conditionFormInputProps}
      error={error}
      loading={loading}
      options={options}
      textFieldLabel={t('Search for event')}
      errorMessage={t<string>('Failed to load events')}
      blankMessage={t('No events found')}
      setSearchText={setSearchText}
      sx={{gap: 1}}
    />
  )
}

const ShowAgeClassificationCodeConditionResourceFormInput = (
  props: IControlledFormCheckboxGroupCommonProps
) => {
  const {t} = useTranslation()
  const {data, loading, error} = useShowAgeClassificationCodes()
  const translateAgeClassification = useTranslateAgeClassification()
  return (
    <RenderOnData<ShowAgeClassificationCodesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>(
        'Error while loading show age classification codes'
      )}
      dataCondition={(data) => Boolean(data.showAgeClassificationCodes)}
    >
      {({showAgeClassificationCodes}) => (
        <ControlledFormCheckboxGroup<IConditionForm>
          options={showAgeClassificationCodes.map((code) => ({
            name: translateAgeClassification(code),
            id: code
          }))}
          {...props}
        />
      )}
    </RenderOnData>
  )
}

interface IConditionResourceFormInputProps {
  conditionFormInputProps: IConditionFormInputProps
  resource: ConditionResource
}

export const ConditionResourceFormInput: React.FC<IConditionResourceFormInputProps> =
  ({resource, conditionFormInputProps}: IConditionResourceFormInputProps) => {
    const {t} = useTranslation()
    const translateShowType = useTranslateShowType()
    const translateShowFormatCode = useTranslateShowFormat()
    const translateShowSoundMix = useTranslateShowSoundMix()
    const translateShowVersion = useTranslateShowVersion()
    const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
    const translateWeekDay = useTranslateWeekDay()

    const controlledFormCheckboxGroupCommonProps = {
      ...omit(conditionFormInputProps, 'triggerValidation'),
      required: true,
      name: ConditionFormField.Value,
      validationOptions: {
        validate: isFormMultiSelectRequired
      },
      parseCheckboxValue: identity,
      helperNote: t('Select at least one option'),
      checkboxSx: {
        '&:hover': {
          backgroundColor: 'grey.300'
        },
        m: 0
      },
      fullWidth: true
    }

    switch (resource) {
      case ConditionResource.ShowTypeCode:
        return (
          <ControlledFormCheckboxGroup<IConditionForm>
            options={Object.values(ShowTypeCode).map((showType) => ({
              name: translateShowType(showType),
              id: showType
            }))}
            {...controlledFormCheckboxGroupCommonProps}
          />
        )
      case ConditionResource.FormatCode:
        return (
          <ControlledFormCheckboxGroup<IConditionForm>
            options={Object.values(ShowFormatCode).map((showFormatCode) => ({
              name: translateShowFormatCode(showFormatCode),
              id: showFormatCode
            }))}
            {...controlledFormCheckboxGroupCommonProps}
          />
        )
      case ConditionResource.VersionCode:
        return (
          <ControlledFormCheckboxGroup<IConditionForm>
            options={Object.values(ShowVersionCode).map((showVersionCode) => ({
              name: translateShowVersion(showVersionCode),
              id: showVersionCode
            }))}
            {...controlledFormCheckboxGroupCommonProps}
          />
        )
      case ConditionResource.ShowAgeClassificationCode:
        return (
          <ShowAgeClassificationCodeConditionResourceFormInput
            {...controlledFormCheckboxGroupCommonProps}
          />
        )
      case ConditionResource.SoundMixCode:
        return (
          <ControlledFormCheckboxGroup<IConditionForm>
            options={Object.values(ShowSoundMixCode).map((soundMixCode) => ({
              name: translateShowSoundMix(soundMixCode),
              id: soundMixCode
            }))}
            {...controlledFormCheckboxGroupCommonProps}
          />
        )
      case ConditionResource.StartWeekDay:
        return (
          <ControlledFormCheckboxGroup<IConditionForm>
            options={Object.values(WeekDay).map((weekDay) => ({
              name: translateWeekDay(weekDay),
              id: weekDay
            }))}
            {...controlledFormCheckboxGroupCommonProps}
          />
        )
      case ConditionResource.StartDate:
        return (
          <FormDateInput<IConditionForm>
            name={ConditionFormField.Value}
            datePickerProps={{
              label: t('Event start date')
            }}
            validationOptions={{
              required: true
            }}
            {...omit(conditionFormInputProps, 'defaultValue')}
            fullWidth
          />
        )
      case ConditionResource.StartTime:
        return (
          <FormTimeInput<IConditionForm>
            name={ConditionFormField.Value}
            timePickerProps={{
              label: t('Event start time')
            }}
            validationOptions={{
              required: true
            }}
            {...omit(conditionFormInputProps, 'defaultValue')}
            fullWidth
          />
        )
      case ConditionResource.ShowId:
        return <ShowIdConditionResourceFormInput {...conditionFormInputProps} />
      case ConditionResource.EventId:
        return (
          <EventIdConditionResourceFormInput {...conditionFormInputProps} />
        )
      case ConditionResource.ApplicableByUserId:
        return (
          <ApplicableByUserId {...controlledFormCheckboxGroupCommonProps} />
        )
      case ConditionResource.DiscountApplicationDate:
        return (
          <FormDateInput<IConditionForm>
            name={ConditionFormField.Value}
            datePickerProps={{
              label: t('Date')
            }}
            validationOptions={{
              required: true
            }}
            {...omit(conditionFormInputProps, 'defaultValue')}
            fullWidth
          />
        )
      case ConditionResource.DiscountApplicationTime:
        return (
          <FormTimeInput<IConditionForm>
            name={ConditionFormField.Value}
            timePickerProps={{
              label: t('Time')
            }}
            validationOptions={{
              required: true
            }}
            {...omit(conditionFormInputProps, 'defaultValue')}
            fullWidth
          />
        )
      case ConditionResource.MarketingLabelId:
        return (
          <MarketingLabelIdConditionResourceFormInput
            {...conditionFormInputProps}
          />
        )
      case ConditionResource.EventCategoryId:
        return (
          <EventCategoryIdConditionResourceFormInput
            {...conditionFormInputProps}
          />
        )
      case ConditionResource.CostCenterId:
        return (
          <CostCenterIdConditionResourceFormInput
            {...conditionFormInputProps}
          />
        )
      case ConditionResource.VenueId:
        return (
          <VenueIdConditionResourceFormInput {...conditionFormInputProps} />
        )
      case ConditionResource.AuditoriumId:
        return (
          <AuditoriumIdConditionResourceFormInput
            {...conditionFormInputProps}
          />
        )
      case ConditionResource.TourId:
        return <TourIdConditionResourceFormInput {...conditionFormInputProps} />
      case ConditionResource.AdmissionTypeId:
        return (
          <AdmissionTypeIdConditionResourceFormInput
            {...conditionFormInputProps}
          />
        )
      default:
        return <div>Not implemented</div>
    }
  }
