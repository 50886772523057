import React from 'react'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InputRow} from '../../../common'
import {SettingsPaper, SingleSetting} from '../../../common/Settings'
import {FormSwitchInput} from '../../../form/FormSwitchInput'
import {ExpandableTimeSelectSettings} from './TimeSelect'
import {
  ITourDivisionSettingsForm,
  TimeSelectOption,
  TourFormDivisionSettingsField
} from './types'

interface ITourReservationFormPartProps {
  control: FormContextValues<ITourDivisionSettingsForm>['control']
  watch: FormContextValues<ITourDivisionSettingsForm>['watch']
  errors: FormContextValues<ITourDivisionSettingsForm>['errors']
  register: FormContextValues<ITourDivisionSettingsForm>['register']
  setValue: FormContextValues<ITourDivisionSettingsForm>['setValue']
  triggerValidation: FormContextValues<ITourDivisionSettingsForm>['triggerValidation']
  unregister: FormContextValues<ITourDivisionSettingsForm>['unregister']
  clearError: FormContextValues<ITourDivisionSettingsForm>['clearError']
  setError: FormContextValues<ITourDivisionSettingsForm>['setError']
  defaultValues?: Partial<ITourDivisionSettingsForm>
}

export const TourReservationFormPart: React.FC<ITourReservationFormPartProps> =
  ({
    control,
    watch,
    register,
    errors,
    setValue,
    triggerValidation,
    unregister,
    clearError,
    setError,
    defaultValues
  }: ITourReservationFormPartProps) => {
    const {t} = useTranslation()
    return (
      <SettingsPaper label={t('Tour reservation defaults')}>
        <SingleSetting
          label={t('Ecommerce reservation')}
          description={t(
            'Default option for each newly created time slot. Changing this setting will not affect any existing time slots.'
          )}
          sx={{py: 1}}
        >
          <InputRow
            nodes={[
              <FormSwitchInput<ITourDivisionSettingsForm>
                name={
                  TourFormDivisionSettingsField.IsECommerceReservationActive
                }
                key={TourFormDivisionSettingsField.IsECommerceReservationActive}
                control={control}
                watch={watch}
                defaultValue={
                  defaultValues?.isECommerceReservationActive || false
                }
              />
            ]}
          />
        </SingleSetting>
        <ExpandableTimeSelectSettings
          errors={errors}
          watch={watch}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          control={control}
          unregister={unregister}
          setError={setError}
          clearError={clearError}
          options={[
            TimeSelectOption.EventStart,
            TimeSelectOption.AfterStart,
            TimeSelectOption.BeforeStart,
            TimeSelectOption.DayBeforeStart,
            TimeSelectOption.Duration
          ]}
          name={TourFormDivisionSettingsField.ECommerceReservationExpiration}
          title={t('Ecommerce reservation expiration')}
          sx={{py: 1}}
        />
        <ExpandableTimeSelectSettings
          errors={errors}
          watch={watch}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          control={control}
          unregister={unregister}
          setError={setError}
          clearError={clearError}
          options={[
            TimeSelectOption.AfterStart,
            TimeSelectOption.BeforeStart,
            TimeSelectOption.DayBeforeStart
          ]}
          name={TourFormDivisionSettingsField.ECommerceReservationEnd}
          title={t('Deadline to create last ecommerce reservation')}
          sx={{py: 1}}
        />
        <SingleSetting
          label={t('Retail reservation')}
          description={t(
            'Default option for each newly created time slot. Changing this setting will not affect any existing time slots.'
          )}
          sx={{py: 1}}
        >
          <InputRow
            nodes={[
              <FormSwitchInput<ITourDivisionSettingsForm>
                name={TourFormDivisionSettingsField.IsRetailReservationActive}
                key={TourFormDivisionSettingsField.IsRetailReservationActive}
                control={control}
                watch={watch}
                defaultValue={defaultValues?.isRetailReservationActive || false}
              />
            ]}
          />
        </SingleSetting>
        <ExpandableTimeSelectSettings
          errors={errors}
          watch={watch}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          control={control}
          unregister={unregister}
          setError={setError}
          clearError={clearError}
          options={[
            TimeSelectOption.EventStart,
            TimeSelectOption.AfterStart,
            TimeSelectOption.BeforeStart,
            TimeSelectOption.DayBeforeStart,
            TimeSelectOption.Duration
          ]}
          name={TourFormDivisionSettingsField.RetailReservationExpiration}
          title={t('Retail reservation expiration')}
          sx={{py: 1}}
        />
        <ExpandableTimeSelectSettings
          errors={errors}
          watch={watch}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          control={control}
          unregister={unregister}
          setError={setError}
          clearError={clearError}
          options={[
            TimeSelectOption.EventStart,
            TimeSelectOption.AfterStart,
            TimeSelectOption.BeforeStart,
            TimeSelectOption.DayBeforeStart
          ]}
          name={TourFormDivisionSettingsField.RetailReservationEnd}
          title={t('Deadline to create last retail reservation')}
          sx={{py: 1}}
        />
      </SettingsPaper>
    )
  }
