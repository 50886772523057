import {Box, Typography} from '@mui/material'
import React from 'react'

export const BoldCaption = ({
  children
}: {
  children: string | React.ReactNode
}) => {
  return (
    <Typography component="div" variant="caption">
      <Box fontWeight="fontWeightBold">{children}</Box>
    </Typography>
  )
}

export const BoldRegular = ({
  children
}: {
  children: string | React.ReactNode
}) => {
  return (
    <Typography component="div">
      <Box fontWeight="fontWeightBold">{children}</Box>
    </Typography>
  )
}
