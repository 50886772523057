import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  EnabledDiscountsForTourTimeSlotQuery,
  SellingChannel
} from '../../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../../hooks/mutationAssistanceHooks'
import {useTourParams} from '../../../../../../../utils/pathname'
import {routeTo} from '../../../../../../../utils/routes'
import {RenderOnData} from '../../../../../../common'
import {isTourItemPropertiesFragment} from '../../../../types'
import {ApplyDiscountsPageTemplate} from '../../../applyDiscounts/ApplyDiscountsPageTemplate'
import {useCurrentCart} from '../../../CurrentCartContext'
import {useRemoveDiscountFromItem} from '../../../graphql'
import {useDiscountErrorHandler} from '../../../utils'
import {
  useAddDiscountToTourItems,
  useEnabledDiscountsForTourTimeSlot
} from '../../graphql'
import {ChildrenOnValidData} from './ChildrenOnValidData'
import {Content} from './Content'

export const ApplyTourDiscounts: React.FC = () => {
  const {t} = useTranslation()
  const {tourTimeSlotId} = useTourParams()
  const addDiscountToTourItems = useAddDiscountToTourItems()
  const removeDiscountFromItem = useRemoveDiscountFromItem()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const discountErrorHandler = useDiscountErrorHandler()
  const {data, loading, error} = useEnabledDiscountsForTourTimeSlot({
    id: tourTimeSlotId,
    discountsFilter: {channel: SellingChannel.Retail}
  })
  const {currentCart, currentCartId, updateCurrentCart} = useCurrentCart()
  const history = useHistory()
  const handleBackArrowClick = useCallback(
    () => history.push(routeTo.admin.cashDesk.tourTimeSlot(tourTimeSlotId)),
    [history, tourTimeSlotId]
  )
  const handleAddButtonClick = useCallback(
    async (itemIds: number[], discountId: number, discountCodeId?: number) => {
      if (currentCartId) {
        try {
          setShowBackdrop(true)
          const {data} = await addDiscountToTourItems({
            cartId: currentCartId,
            discountId,
            itemIds,
            discountCodeId
          })
          if (data) {
            updateCurrentCart()
          }
        } catch (error) {
          discountErrorHandler(error, t('Adding discount to tour items failed'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addDiscountToTourItems,
      currentCartId,
      discountErrorHandler,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
  const getChipDeleteButtonClickHandler = useCallback(
    (itemId: number, discountId: number) => async () => {
      if (currentCartId) {
        try {
          setShowBackdrop(true)
          const {data} = await removeDiscountFromItem({
            itemId,
            discountId,
            cartId: currentCartId
          })
          if (data) {
            updateCurrentCart()
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Removing discount from tour item failed')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      defaultErrorHandler,
      removeDiscountFromItem,
      updateCurrentCart,
      setShowBackdrop,
      t
    ]
  )
  return (
    <ApplyDiscountsPageTemplate onBackArrowClick={handleBackArrowClick}>
      <RenderOnData<EnabledDiscountsForTourTimeSlotQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading tour time slot')}
        dataCondition={(data) => Boolean(data.tourTimeSlot)}
      >
        {({tourTimeSlot}) => {
          const tourItems = (currentCart?.items || [])
            .filter(isTourItemPropertiesFragment)
            .filter((item) => item.tourTimeSlotId === tourTimeSlot.id)
          return (
            <ChildrenOnValidData
              tourTimeSlot={tourTimeSlot}
              tourItems={tourItems}
            >
              <Content
                tourItems={tourItems}
                discounts={tourTimeSlot.enabledDiscounts}
                onAddButtonClick={handleAddButtonClick}
                getChipDeleteButtonClickHandler={
                  getChipDeleteButtonClickHandler
                }
                tourTimeSlotId={tourTimeSlot.id}
              />
            </ChildrenOnValidData>
          )
        }}
      </RenderOnData>
    </ApplyDiscountsPageTemplate>
  )
}
