import AddIcon from '@mui/icons-material/Add'
import {Paper} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientShowsQuery,
  PermissionCode,
  ShowsFilterInput
} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'

import {routeTo} from '../../../../utils/routes'
import {DrawerWithDebouncedId, RenderOnData} from '../../../common'
import {CreateFab, SaveButton, useFabClasses} from '../../../common/Buttons'
import {LazyLoadingList} from '../../../common/LazyLoadingList'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {
  DEFAULT_SHOWS_FILTER_INPUT,
  ShowsSearch,
  ShowsSearchLocation
} from '../../../common/ShowsSearch'
import {Blank} from '../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {useGetClientShows} from '../graphql'
import {PrimaryHeader} from '../Header'
import {CenteredLayout} from '../Layout'
import {ClientShowDetailProvider} from './ClientShowDetailProvider'
import {ShowDetailContent} from './ShowDetailContent'
import {ShowListItem} from './ShowListItem'

const useStyles = makeStyles<Theme>((theme) => ({
  showMore: {
    paddingTop: theme.spacing(1)
  },
  showMoreButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  downArrow: {
    marginRight: theme.spacing(1)
  },
  detailRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }
}))

export const ClientShowList: React.FC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const [filter, setFilter] = useState<ShowsFilterInput>(
    DEFAULT_SHOWS_FILTER_INPUT
  )
  const {data, fetchMore, loading, isLoadingMore, error} =
    useGetClientShows(filter)
  const [previewedShowId, setPreviewedShowId] = useState(0)

  const resetPreviewedShowId = useCallback(() => {
    setPreviewedShowId(0)
  }, [])

  const fabClasses = useFabClasses()

  const handleAddClicked = useCallback(() => {
    history.push(routeTo.admin.shows.add())
  }, [history])

  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.clientShows.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.clientShows.pagination.hasMore, fetchMore, isLoadingMore])
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Shows')}
          search={
            <ShowsSearch
              onFilterChange={setFilter}
              location={ShowsSearchLocation.Shows}
            />
          }
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<ClientShowsQuery>
          data={data}
          loading={loading}
          error={error}
          dataCondition={(data) => Array.isArray(data?.clientShows.items)}
          errorMessage={t<string>('Could not load shows.')}
        >
          {({clientShows}) => {
            const {items} = clientShows
            return items.length > 0 ? (
              <LazyLoadingList
                isLoadingMore={isLoadingMore}
                onScrolledNearTheEndOfTheList={
                  handleScrolledNearTheEndOfTheLayout
                }
                sx={{py: 2}}
              >
                <CenteredLayout>
                  <Paper variant="outlined">
                    {items.map((show) => (
                      <ShowListItem
                        getEditRoute={routeTo.admin.shows.edit}
                        key={show.id}
                        show={show}
                        previewShow={setPreviewedShowId}
                      />
                    ))}
                  </Paper>
                  {P([PermissionCode.CreateClientShow]) && (
                    <CreateFab
                      classes={fabClasses}
                      onClick={handleAddClicked}
                    />
                  )}
                </CenteredLayout>
              </LazyLoadingList>
            ) : (
              <Blank
                title={t('No shows to show.')}
                actions={
                  P([PermissionCode.CreateClientShow]) && (
                    <SaveButton
                      startIcon={<AddIcon />}
                      onClick={handleAddClicked}
                    >
                      {t('Create')}
                    </SaveButton>
                  )
                }
              />
            )
          }}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
      <DrawerWithDebouncedId
        id={previewedShowId}
        onClose={resetPreviewedShowId}
      >
        {({debouncedId, closeDrawer}) => (
          <div className={classes.detailRoot}>
            <ClientShowDetailProvider
              showId={debouncedId}
              onClose={closeDrawer}
            >
              {(show) => (
                <ShowDetailContent
                  onClose={closeDrawer}
                  show={show}
                  onPrimaryButtonClick={(id) => {
                    history.push(routeTo.admin.shows.edit(id))
                  }}
                />
              )}
            </ClientShowDetailProvider>
          </div>
        )}
      </DrawerWithDebouncedId>
    </PageWithHeaderTemplate>
  )
}
