import {
  FeZoneState,
  ICoords,
  IDimensions,
  ZoneByState,
  ZoneType
} from '@attendio/shared-components'
import Konva from 'konva'
import _ from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useModifyZoneLabel} from '../../hooks/zoneLabel'

import {ROTATION, ZONE_CAPACITY} from '../config'
import {useSelector} from '../redux'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {useZoneActions} from '../redux/objects/zones/actions'
import {DrawTool} from '../types'
import {getMouseCoordsOnCanvas} from '../utils/common'
import {EventLayer} from './EventLayer'

const defaultDimensions = {width: 0, height: 0}

const TemporaryZoneFn: React.FC = () => {
  const {t} = useTranslation()
  const [startCoords, setStartCoords] = useState<ICoords | null>(null)
  const [endCoords, setEndCoords] = useState<ICoords | null>(null)
  const {modeConfigs} = useSelector(editorModeSelector)
  const {addZone} = useZoneActions()

  const defaultLabelMap = useMemo(
    () => ({
      [ZoneType.FreeSitting]: t('Free sitting zone'),
      [ZoneType.Standing]: t('Standing zone'),
      [ZoneType.Ticket]: t('Ticket')
    }),
    [t]
  )

  const zoneType: ZoneType = useMemo(() => {
    const drawConfig = modeConfigs[EditorMode.DRAW]
    return drawConfig.type === DrawTool.ZONE
      ? drawConfig.zoneType
      : ZoneType.Ticket
  }, [modeConfigs])

  const dimensions: IDimensions = useMemo(() => {
    if (!startCoords || !endCoords) {
      return defaultDimensions
    }

    const newDimensions = {
      width: endCoords.x - startCoords.x,
      height: endCoords.y - startCoords.y
    }

    if (_.isEqual(newDimensions, defaultDimensions)) {
      return defaultDimensions
    }

    return newDimensions
  }, [endCoords, startCoords])

  const onMouseDown = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const newCoords = getMouseCoordsOnCanvas(e)
    setStartCoords(newCoords)
    setEndCoords(newCoords)
  }, [])

  const onMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (!startCoords) {
        return
      }
      setEndCoords(getMouseCoordsOnCanvas(e))
    },
    [startCoords]
  )

  const onMouseUp = useCallback(() => {
    if (startCoords && endCoords) {
      addZone({
        zoneType,
        label: defaultLabelMap[zoneType],
        capacity: ZONE_CAPACITY,
        coords: startCoords,
        dimensions,
        rotation: ROTATION,
        row: '',
        section: '',
        floor: ''
      })
    }

    setStartCoords(null)
    setEndCoords(null)
  }, [addZone, defaultLabelMap, dimensions, endCoords, startCoords, zoneType])
  const modifyZoneLabel = useModifyZoneLabel()

  return (
    <EventLayer {...{onMouseUp, onMouseMove, onMouseDown}}>
      {startCoords && endCoords && (
        <ZoneByState
          modifyLabel={modifyZoneLabel}
          state={FeZoneState.Plain}
          zoneConfig={{
            id: '',
            capacity: ZONE_CAPACITY,
            coords: startCoords,
            dimensions,
            zoneType,
            label: defaultLabelMap[zoneType],
            rotation: ROTATION,
            row: '',
            section: '',
            floor: ''
          }}
        />
      )}
    </EventLayer>
  )
}

export const TemporaryZone = React.memo(TemporaryZoneFn)
