import {useTranslation} from 'react-i18next'

import {
  LeadField,
  LeadInputStatus,
  LeadOptionPropertiesFragment
} from '../__generated__/schema'

export enum LeadSection {
  LEAD = 'lead',
  COMPANY = 'company',
  BILLING_ADDRESS = 'billing_address',
  DELIVERY_ADDRESS = 'delivery_address',
  NOTES = 'notes'
}

export const useTranslateLeadSection = () => {
  const {t} = useTranslation()

  const translatedLeadSections: Record<LeadSection, string> = {
    [LeadSection.LEAD]: t('LeadSection->LEAD'),
    [LeadSection.COMPANY]: t('LeadSection->COMPANY'),
    [LeadSection.BILLING_ADDRESS]: t('LeadSection->BILLING_ADDRESS'),
    [LeadSection.DELIVERY_ADDRESS]: t('LeadSection->DELIVERY_ADDRESS'),
    [LeadSection.NOTES]: t('LeadSection->NOTES')
  }
  return (leadSection: LeadSection) => translatedLeadSections[leadSection]
}

export const useLeadOptionsObject = (
  leadOptions: LeadOptionPropertiesFragment[]
): Partial<Record<LeadField, LeadOptionPropertiesFragment>> =>
  leadOptions.reduce<Partial<Record<LeadField, LeadOptionPropertiesFragment>>>(
    (acc, option) =>
      option.inputStatus !== LeadInputStatus.Unavailable
        ? {
            ...acc,
            [option.field]: option
          }
        : acc,
    {}
  )

const getFieldsForSection = (section: LeadSection): LeadField[] => {
  switch (section) {
    case LeadSection.COMPANY:
      return [
        LeadField.CompanyName,
        LeadField.CompanyIdNumber,
        LeadField.TaxId,
        LeadField.VatId
      ]
    case LeadSection.BILLING_ADDRESS:
      return [
        LeadField.BillingAddressStreet,
        LeadField.BillingPostalCode,
        LeadField.BillingAddressTown,
        LeadField.BillingAddressCountry
      ]
    case LeadSection.LEAD:
      return [LeadField.Name, LeadField.Phone, LeadField.Email]
    case LeadSection.NOTES:
      return [LeadField.InternalNote, LeadField.Note]
    case LeadSection.DELIVERY_ADDRESS:
      return [
        LeadField.DeliveryAddressee,
        LeadField.DeliveryAddressStreet,
        LeadField.DeliveryPostalCode,
        LeadField.DeliveryAddressTown,
        LeadField.DeliveryAddressCountry
      ]
    default:
      return []
  }
}

export const getLeadBlockIsAvailable = (
  leadOptionsObject: Partial<Record<LeadField, LeadOptionPropertiesFragment>>,
  leadSection: LeadSection
): Boolean =>
  getFieldsForSection(leadSection).some(
    (leadField) =>
      leadOptionsObject[leadField] &&
      leadOptionsObject[leadField]!.inputStatus !== LeadInputStatus.Unavailable
  )

export const useLeadSideNavigationListItems = (
  leadOptionsObject: Partial<Record<LeadField, LeadOptionPropertiesFragment>>
): {
  [key: string]: {id: string; label: string}
} => {
  const translateLeadSection = useTranslateLeadSection()
  return [
    LeadSection.LEAD,
    LeadSection.COMPANY,
    LeadSection.BILLING_ADDRESS,
    LeadSection.DELIVERY_ADDRESS,
    LeadSection.NOTES
  ]
    .filter((leadSection) =>
      getLeadBlockIsAvailable(leadOptionsObject, leadSection)
    )
    .reduce(
      (acc, leadSection: LeadSection) => ({
        ...acc,
        [leadSection]: {
          id: leadSection,
          label: translateLeadSection(leadSection)
        }
      }),
      {}
    )
}
