import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Chip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {CustomerState} from '../../../../__generated__/schema'
import {useTranslateCustomerStateInFilterChip} from '../../../../hooks/translateCustomerState'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

const useStyles = makeStyles(() => ({
  menuItem: {
    flexFlow: 'row-reverse'
  },
  menuIcon: {
    justifyContent: 'center'
  }
}))

interface ICustomerStateChipProps {
  selectedState: CustomerState | undefined
  setSelectedState: (state: CustomerState | undefined) => void
}

export const CustomerStateChip: React.FC<ICustomerStateChipProps> = ({
  selectedState,
  setSelectedState
}: ICustomerStateChipProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const translateCustomerStateInFilterChip =
    useTranslateCustomerStateInFilterChip()
  const handleMenuItemClick = useCallback(
    (newState: CustomerState | undefined) => () => {
      setSelectedState(newState)
      closeMenu()
    },
    [closeMenu, setSelectedState]
  )
  const classes = useStyles()
  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={openMenu}
        onClick={openMenu}
        label={
          selectedState
            ? translateCustomerStateInFilterChip(selectedState)
            : t('All customer states')
        }
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          label={t('All customer states')}
          icon={!selectedState ? <CheckIcon /> : undefined}
          isSelected={!selectedState}
          className={classes.menuItem}
          iconClassName={classes.menuIcon}
          onClick={handleMenuItemClick(undefined)}
        />
        {[
          CustomerState.Invited,
          CustomerState.Active,
          CustomerState.Disabled,
          CustomerState.Anonymized
        ].map((state) => (
          <MenuItem
            key={state}
            label={translateCustomerStateInFilterChip(state)}
            icon={selectedState === state ? <CheckIcon /> : undefined}
            isSelected={selectedState === state}
            className={classes.menuItem}
            iconClassName={classes.menuIcon}
            onClick={handleMenuItemClick(state)}
          />
        ))}
      </Menu>
    </>
  )
}
