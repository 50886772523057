import {useGetAllAvailableLocales, useGetClientLocales} from './getLocales'
import {useTranslateLocaleCode} from './translateLocales'

interface IServerIntlSelects {
  localeSelectData: {[key: string]: string}
  allLocaleSelectData: {[key: string]: string}
}

export const useGetServerIntlSelectData = (): IServerIntlSelects => {
  const clientLocales = useGetClientLocales()
  const allAvailableLocales = useGetAllAvailableLocales()
  const translateLocaleCode = useTranslateLocaleCode()

  const localeSelectData = clientLocales.reduce((res, localeCode) => {
    return {...res, [localeCode]: translateLocaleCode(localeCode)}
  }, {})

  const allLocaleSelectData = allAvailableLocales.reduce((res, localeCode) => {
    return {...res, [localeCode]: translateLocaleCode(localeCode)}
  }, {})

  return {
    localeSelectData,
    allLocaleSelectData
  }
}
