import {useTranslation} from 'react-i18next'
import {BusinessPartnerCategory} from '../__generated__/schema'

export const useTranslateBusinessPartnerCategory = () => {
  const {t} = useTranslation()
  const translated: Record<BusinessPartnerCategory, string> = {
    [BusinessPartnerCategory.Retailer]: t<string>(
      'BusinessPartnerCategory->Retailer'
    ),
    [BusinessPartnerCategory.Wholesaler]: t<string>(
      'BusinessPartnerCategory->Wholesaler'
    ),
    [BusinessPartnerCategory.Distributor]: t<string>(
      'BusinessPartnerCategory->Distributor'
    ),
    [BusinessPartnerCategory.Affiliate]: t<string>(
      'BusinessPartnerCategory->Affiliate'
    ),
    [BusinessPartnerCategory.Licensing]: t<string>(
      'BusinessPartnerCategory->Licensing'
    ),
    [BusinessPartnerCategory.Outsourcing]: t<string>(
      'BusinessPartnerCategory->Outsourcing'
    ),
    [BusinessPartnerCategory.Franchise]: t<string>(
      'BusinessPartnerCategory->Franchise'
    ),
    [BusinessPartnerCategory.Shareholder]: t<string>(
      'BusinessPartnerCategory->Shareholder'
    ),
    [BusinessPartnerCategory.Government]: t<string>(
      'BusinessPartnerCategory->Government'
    ),
    [BusinessPartnerCategory.Municipal]: t<string>(
      'BusinessPartnerCategory->Municipal'
    ),
    [BusinessPartnerCategory.Local]: t<string>(
      'BusinessPartnerCategory->Local'
    ),
    [BusinessPartnerCategory.Regional]: t<string>(
      'BusinessPartnerCategory->Regional'
    ),
    [BusinessPartnerCategory.NonProfit]: t<string>(
      'BusinessPartnerCategory->NonProfit'
    ),
    [BusinessPartnerCategory.Community]: t<string>(
      'BusinessPartnerCategory->Community'
    ),
    [BusinessPartnerCategory.Consultant]: t<string>(
      'BusinessPartnerCategory->Consultant'
    ),
    [BusinessPartnerCategory.Advisor]: t<string>(
      'BusinessPartnerCategory->Advisor'
    ),
    [BusinessPartnerCategory.Regulatory]: t<string>(
      'BusinessPartnerCategory->Regulatory'
    ),
    [BusinessPartnerCategory.MovieDistributor]: t<string>(
      'BusinessPartnerCategory->MovieDistributor'
    ),
    [BusinessPartnerCategory.Agency]: t<string>(
      'BusinessPartnerCategory->Agency'
    )
  }
  return (category: BusinessPartnerCategory) => translated[category]
}
