import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useCreateProductType} from './graphql'
import {ProductTypeForm} from './ProductTypeForm'
import {IProductTypeForm, ProductTypeFormLocation} from './types'

const CREATE_PRODUCT_TYPE_FORM_ID = 'createProductTypeForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface IProductTypeFormProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateProductTypeDrawer: React.FC<IProductTypeFormProps> = ({
  isOpen,
  onClose
}: IProductTypeFormProps) => {
  const {t} = useTranslation()
  const createProductType = useCreateProductType()
  const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
    useMutationAssistanceHooks()
  const classes = useStyles()
  const handleSubmit = useCallback(
    async (data: IProductTypeForm) => {
      try {
        setShowBackdrop(true)
        await createProductType({input: data})
        addInfoNotification(t('Product type created'))
        onClose()
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating product type'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createProductType,
      defaultErrorHandler,
      onClose,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      classes={{paper: classes.drawerPaper}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create product type')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_PRODUCT_TYPE_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
      >
        <ProductTypeForm
          formId={CREATE_PRODUCT_TYPE_FORM_ID}
          location={ProductTypeFormLocation.Create}
          onSubmit={handleSubmit}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
