import {identity, pickBy} from 'lodash'
import {
  AdmissionTypeInput,
  AdmissionTypeState,
  ECommerceTranslatedInput,
  UpdateAdmissionTypeInput,
  UpdateDraftAdmissionTypeInput
} from '../../../../__generated__/schema'
import {AdmissionTypeFormField, IAdmissionTypeForm} from './types'

const getEcommerceTranslatedFields = (fields?: ECommerceTranslatedInput) => {
  const filteredFields = pickBy(fields, identity)
  return Object.keys(filteredFields).length > 0 ? filteredFields : null
}

export const transformCreateAdmissionTypeDataToInput = (
  data: IAdmissionTypeForm
): AdmissionTypeInput => ({
  ...data,
  internalDescription:
    data[AdmissionTypeFormField.InternalDescription] || undefined,
  icon: data[AdmissionTypeFormField.Icon] || undefined,
  eCommerceNames: getEcommerceTranslatedFields(
    data[AdmissionTypeFormField.ECommerceNames]
  ),
  eCommerceDescriptions: getEcommerceTranslatedFields(
    data[AdmissionTypeFormField.ECommerceDescriptions]
  )
})

export const transformUpdateDraftAdmissionTypeDataToInput = (
  data: IAdmissionTypeForm,
  state: AdmissionTypeState
): UpdateDraftAdmissionTypeInput => ({
  ...data,
  internalDescription: data[AdmissionTypeFormField.InternalDescription] || null,
  icon: data[AdmissionTypeFormField.Icon] || null,
  eCommerceNames: getEcommerceTranslatedFields(
    data[AdmissionTypeFormField.ECommerceNames]
  ),
  eCommerceDescriptions: getEcommerceTranslatedFields(
    data[AdmissionTypeFormField.ECommerceDescriptions]
  ),
  state
})

export const transformUpdateAdmissionTypeDataToInput = (
  data: IAdmissionTypeForm,
  state: AdmissionTypeState
): UpdateAdmissionTypeInput => ({
  name: data[AdmissionTypeFormField.Name],
  abbreviation: data[AdmissionTypeFormField.Abbreviation],
  color: data[AdmissionTypeFormField.Color],
  internalDescription: data[AdmissionTypeFormField.InternalDescription] || null,
  icon: data[AdmissionTypeFormField.Icon] || null,
  eCommerceNames: getEcommerceTranslatedFields(
    data[AdmissionTypeFormField.ECommerceNames]
  ),
  eCommerceDescriptions: getEcommerceTranslatedFields(
    data[AdmissionTypeFormField.ECommerceDescriptions]
  ),
  state
})
