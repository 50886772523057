import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  TranslatedCurrency,
  useTranslateCurrencySign
} from '../../../../../hooks/translateCurrencies'

interface IHeaderRowProps {
  className?: string
  currency: TranslatedCurrency
}

export const HeaderRow: React.FC<IHeaderRowProps> = ({
  className,
  currency
}: IHeaderRowProps) => {
  const {t} = useTranslation()
  const translateCurrencySign = useTranslateCurrencySign()
  return (
    <>
      <Typography className={className} color="textSecondary">
        {t('Denomination')}
      </Typography>
      <Typography className={className} color="textSecondary" align="center">
        {t('Count')}
      </Typography>
      <Typography className={className} color="textSecondary" align="right">
        {translateCurrencySign(currency)}
      </Typography>
    </>
  )
}
