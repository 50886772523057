import {Box, Drawer, drawerClasses, Typography} from '@mui/material'
import {uniqBy} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightProductsQuery,
  LightweightProductsQueryVariables,
  ProductsFilterInput,
  ProductState
} from '../../../../../__generated__/schema'
import {useDebounce} from '../../../../../hooks/debounce'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../../utils/pagination'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../../common'
import {LazyLoadingList} from '../../../../common/LazyLoadingList'
import {ListItemWithAddButton} from '../../../../common/ListItemWithAddButton'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {LIGHTWEIGHT_PRODUCTS} from '../../graphql'
import {useAssignProductToProductGroup} from '../graphql'

interface IAssignProductDrawerProps {
  isOpen: boolean
  onClose: () => void
  assignedProductIds: number[]
  productGroupId: number
  refetchProductGroup: () => void
}

export const AssignProductDrawer: React.FC<IAssignProductDrawerProps> = ({
  isOpen,
  onClose,
  assignedProductIds,
  productGroupId,
  refetchProductGroup
}: IAssignProductDrawerProps) => {
  const {t} = useTranslation()
  const [skip, setSkip] = useState(!isOpen)
  const [search, setSearch] = useState<string>('')
  const [searchFilter, setSearchFilter] = useState<ProductsFilterInput>({})
  const assignProductToProductGroup = useAssignProductToProductGroup()
  const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
    useMutationAssistanceHooks()
  const {data, loading, error, isLoadingMore, fetchMore, refetch} =
    useQueryWithPagination<
      LightweightProductsQuery,
      LightweightProductsQueryVariables
    >(
      LIGHTWEIGHT_PRODUCTS,
      {
        variables: {
          filter: {state: ProductState.Active, ...searchFilter},
          paginationInput: {offset: 0, limit: 30}
        },
        fetchPolicy: 'network-only',
        skip
      },
      {
        mapPaginationInput: (data) =>
          extractPaginationInput(data.products.pagination),
        updateData: (prevData, fetchMoreData) => ({
          ...fetchMoreData,
          products: {
            ...fetchMoreData.products,
            items: uniqBy(
              [...prevData.products.items, ...fetchMoreData.products.items],
              ({id}) => id
            )
          }
        })
      }
    )
  const debouncedSetSearchFilter = useDebounce(setSearchFilter, 400)
  const handleAddButtonClick = useCallback(
    (productId: number) => async () => {
      try {
        setShowBackdrop(true)
        await assignProductToProductGroup({productGroupId, productId})
        addInfoNotification('Product successfully added')
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while assigning product to product group')
        )
        refetchProductGroup()
        refetch()
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      assignProductToProductGroup,
      defaultErrorHandler,
      productGroupId,
      refetch,
      refetchProductGroup,
      setShowBackdrop,
      t
    ]
  )
  const handleInputChange = useCallback(
    (e) => {
      setSearch(e.target.value)
      if (e.target.value.length !== 1) {
        debouncedSetSearchFilter((prevFilter) => ({
          ...prevFilter,
          hasText: e.target.value || undefined
        }))
      }
    },
    [debouncedSetSearchFilter]
  )
  const handleCancelClick = useCallback(() => {
    setSearch('')
    debouncedSetSearchFilter((prevFilter) => ({
      ...prevFilter,
      hasText: undefined
    }))
  }, [debouncedSetSearchFilter])
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.products.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.products.pagination.hasMore, fetchMore, isLoadingMore])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        errorMessage={error && t('Error while loading products')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Products')}
          />
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <LazyLoadingList
          isLoadingMore={isLoadingMore}
          onScrolledNearTheEndOfTheList={handleScrolledNearTheEndOfTheLayout}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'common.white',
              py: 2,
              px: 3,
              zIndex: 1,
              borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
            }}
          >
            <OutlinedInputWithCancelAdornment
              onCancelClick={handleCancelClick}
              inputProps={{
                value: search,
                onChange: handleInputChange,
                placeholder: t('Search for product'),
                fullWidth: true
              }}
            />
          </Box>
          <RenderOnData<LightweightProductsQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading products')}
            dataCondition={(data) => Array.isArray(data.products.items)}
          >
            {({products}) => (
              <Box
                sx={{
                  px: 3,
                  pb: 2,
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'column'
                }}
              >
                {products.items.length > 0 ? (
                  <>
                    {products.items.map((product) => (
                      <ListItemWithAddButton
                        key={product.id}
                        onAddButtonClick={handleAddButtonClick(product.id)}
                        primaryText={product.name}
                        secondaryText={[
                          product.priceLookupCode &&
                            t('PLU: {{code}}', {code: product.priceLookupCode}),
                          product.internalCode &&
                            t('Code: {{code}}', {code: product.internalCode})
                        ]
                          .filter(Boolean)
                          .join(' • ')}
                        disabled={
                          !!assignedProductIds.find((id) => id === product.id)
                        }
                      />
                    ))}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      pt: 2
                    }}
                  >
                    <Typography variant="body2">
                      {t('No products found')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </RenderOnData>
        </LazyLoadingList>
      </DrawerTemplate>
    </Drawer>
  )
}
