import CheckIcon from '@mui/icons-material/Check'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ClearIcon from '@mui/icons-material/Clear'
import DangerousIcon from '@mui/icons-material/Dangerous'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import {Box, Chip, chipClasses, IconButton, SvgIconProps} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import {noop} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  DiscountAuthorizationMode,
  PassCodeCheckAdditionalInformation,
  PassCodeChecksFilter,
  PassCodeCheckState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslatePassCodeCheckAdditionalInformation} from '../../../../hooks/translatePassCodeCheckAdditionalInformation'
import {useTranslatePassCodeCheckDirection} from '../../../../hooks/translatePassCodeCheckDirection'
import {useTranslatePassCodeCheckState} from '../../../../hooks/translatePassCodeCheckState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useDateTimeNumericWithMilliSecondsFormatter,
  useGetTranslatedValueFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {COLOR_CONF, passCodeCheckStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {TicketInfoDrawer} from '../components/ticketInfoDrawer/TicketInfoDrawer'
import {PrimaryHeader} from '../Header'
import {usePassCodeChecks} from './graphql'
import {PassCodeCheckHistorySearch} from './PassCodeCheckHistorySearch'

const getAdditionalInformationIconAndColor = (
  additionalInformation: PassCodeCheckAdditionalInformation
) => {
  const info: Record<
    PassCodeCheckAdditionalInformation,
    {style: {color: string; background: string}; Icon: React.FC<SvgIconProps>}
  > = {
    [PassCodeCheckAdditionalInformation.CheckedIn]: {
      style: COLOR_CONF.GREEN,
      Icon: LoginIcon
    },
    [PassCodeCheckAdditionalInformation.CheckedOut]: {
      style: COLOR_CONF.RED,
      Icon: LogoutIcon
    },
    [PassCodeCheckAdditionalInformation.TicketClaimed]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.AlreadyCheckedIn]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.AlreadyCheckedOut]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.GateIsNotOpen]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.AuthorizedDiscount]: {
      style: COLOR_CONF.BLUE,
      Icon: CheckIcon
    },
    [PassCodeCheckAdditionalInformation.UnauthorizedDiscount]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.DiscountAuthorizationPending]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterVenueIds]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterTourIds]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterTourTimeSlotIds]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterEventIds]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterArguments]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.EventNotPublished]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    },
    [PassCodeCheckAdditionalInformation.TourTimeSlotNotPublished]: {
      style: COLOR_CONF.GRAY,
      Icon: DangerousIcon
    }
  }
  return info[additionalInformation]
}

const StateRenderer = ({state}: {state: PassCodeCheckState}) => {
  const translatePassCodeCheckState = useTranslatePassCodeCheckState()
  return (
    <EntityStateChip
      label={translatePassCodeCheckState(state)}
      colorConf={passCodeCheckStateColors[state]}
    />
  )
}

const AdditionalInformationRenderer = ({
  additionalInformation
}: {
  additionalInformation: PassCodeCheckAdditionalInformation
}) => {
  const translatePassCodeCheckAdditionalInformation =
    useTranslatePassCodeCheckAdditionalInformation()
  const {style, Icon} = getAdditionalInformationIconAndColor(
    additionalInformation
  )
  return (
    <Chip
      label={translatePassCodeCheckAdditionalInformation(additionalInformation)}
      icon={
        <Icon
          sx={{
            width: 18,
            height: 18,
            [`&.${chipClasses.icon}`]: {color: style.color}
          }}
        />
      }
      sx={{
        height: 24,
        width: 'min-content',
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
    />
  )
}

const DiscountCheckRenderer = ({
  authorizationMode
}: {
  authorizationMode: DiscountAuthorizationMode
}) => {
  if (
    [
      DiscountAuthorizationMode.RetailOnly,
      DiscountAuthorizationMode.ECommerceOnly,
      DiscountAuthorizationMode.AuthorizeAll
    ].includes(authorizationMode)
  ) {
    return <CheckCircleIcon sx={{color: COLOR_CONF.GREEN.color}} />
  }
  return <ClearIcon sx={{color: COLOR_CONF.GRAY.color}} />
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.passCodeCheckHistory.ticketInfoDrawer(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IPassCodeCheckHistoryListProps {
  searchFilter: PassCodeChecksFilter
}

const PassCodeCheckHistoryList: React.FC<IPassCodeCheckHistoryListProps> = ({
  searchFilter
}: IPassCodeCheckHistoryListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error} = usePassCodeChecks({
    paginationInput,
    filter: searchFilter
  })
  const dateTimeNumericWithMilliSecondsFormatter =
    useDateTimeNumericWithMilliSecondsFormatter()
  const translatePassCodeCheckDirection = useTranslatePassCodeCheckDirection()
  const dateTimeFormatter = useDateTimeFormatter()
  const getTranslatedValueFormatter = useGetTranslatedValueFormatter()
  const userNameFormatter = useUserNameFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Timestamp'),
        field: 'createdAt',
        valueFormatter: dateTimeNumericWithMilliSecondsFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Ticket'),
        field: 'passCode',
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: PassCodeCheckState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Additional information'),
        field: 'additionalInformation',
        renderCell: function renderer(
          params: GridRenderCellParams<{
            value: PassCodeCheckAdditionalInformation
          }>
        ) {
          return (
            <AdditionalInformationRenderer
              additionalInformation={params.value}
            />
          )
        },
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Direction'),
        field: 'direction',
        valueFormatter: (params) =>
          translatePassCodeCheckDirection(params.value),
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Discount check'),
        field: 'discountAuthorizationMode',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: DiscountAuthorizationMode}>
        ) {
          return <DiscountCheckRenderer authorizationMode={params.value} />
        },
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        minWidth: 250,
        valueFormatter: userNameFormatter,
        sortable: false
      },
      {
        headerName: t('Starts at'),
        field: 'startsAt',
        valueGetter: (params) =>
          params.row.event
            ? params.row.event.startsAt
            : params.row.tourTimeSlot.startsAt,
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Title'),
        field: 'name',
        valueGetter: (params) =>
          params.row.event
            ? params.row.event.names
            : params.row.tourTimeSlot.names,
        valueFormatter: getTranslatedValueFormatter,
        sortable: false,
        minWidth: 300
      },
      {
        headerName: '',
        field: 'itemId',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48,
        disableExport: true,
        disableColumnMenu: true
      }
    ],
    [
      t,
      dateTimeNumericWithMilliSecondsFormatter,
      userNameFormatter,
      dateTimeFormatter,
      getTranslatedValueFormatter,
      translatePassCodeCheckDirection
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading pass code checks')}
      />
    )
  }
  return (
    <Box sx={{height: '100%', width: '100%', p: 3}}>
      <DataGridTable
        columns={columns}
        loading={loading}
        rows={data?.passCodeChecks.items || []}
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: ['createdAt'],
            right: ['itemId']
          }
        }}
        columnVisibilityModel={{
          id: P([PermissionCode.ReadItem])
        }}
        disableColumnFilter
        localeText={{
          noRowsLabel: t('No pass code checks to show')
        }}
        {...getDataGridPaginationProps(data?.passCodeChecks.pagination)}
      />
    </Box>
  )
}

export const PassCodeCheckHistoryPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<PassCodeChecksFilter>({})
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.passCodeCheckHistory.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Tickets check history')}
          search={
            <PassCodeCheckHistorySearch onFilterChange={setSearchFilter} />
          }
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <PassCodeCheckHistoryList searchFilter={searchFilter} />
        {P([PermissionCode.ReadItem]) && (
          <Route
            path={routeTo.admin.passCodeCheckHistory.ticketInfoDrawer(
              ':itemId'
            )}
            exact
          >
            <TicketInfoDrawer onExited={handleExited} onEntered={noop} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
