import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  InventoryChecksFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateInventoryCheckDrawer} from './CreateInventoryCheckDrawer'
import {InventoryChecksList} from './InventoryChecksList'
import {
  DEFAULT_INVENTORY_CHECKS_FILTER_INPUT,
  InventoryChecksSearch
} from './InventoryChecksSearch'

export const InventoryChecksPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<InventoryChecksFilterInput>(
    DEFAULT_INVENTORY_CHECKS_FILTER_INPUT
  )
  const {
    state: isCreateInventoryCheckDrawerOpen,
    setTrue: openCreateInventoryCheckDrawer,
    setFalse: closeCreateInventoryCheckDrawer
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Inventory checks')}
          search={<InventoryChecksSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <InventoryChecksList searchFilter={searchFilter} />
        {P([
          PermissionCode.CreateInventoryCheck,
          PermissionCode.ReadWarehouses,
          PermissionCode.ReadProducts,
          PermissionCode.ReadProductGroups,
          PermissionCode.ReadProductTypes
        ]) && (
          <CreateFab
            classes={fabClasses}
            onClick={openCreateInventoryCheckDrawer}
          />
        )}
        <CreateInventoryCheckDrawer
          isOpen={isCreateInventoryCheckDrawerOpen}
          onClose={closeCreateInventoryCheckDrawer}
        />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
