import {createSelector} from 'reselect'

import {IAppState} from '../index'

export const refsSelector = (state: IAppState) => state.refs

export const stageRefSelector = createSelector(
  refsSelector,
  (refs) => refs.stageRef
)
