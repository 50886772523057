import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {get} from 'lodash'
import React from 'react'
import {
  Controller,
  FieldError,
  FieldValues,
  useFormContext
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {InputRow} from '../../../common'
import {FormSubText} from '../../../form/FormSubText'
import {FormDatetimeInput} from '../../../form/pickers'
import {FormFieldName} from '../../../form/types'
import {
  CheckboxWithLabel,
  useCheckboxWithLabel
} from '../discounts/DiscountDetail/CheckboxWithLabel'
import {VoucherActivationOption, VoucherActivationOptionsField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  sectionTitle: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  }
}))

const useTranslateVoucherActivationOption = () => {
  const {t} = useTranslation()
  return (option: VoucherActivationOption) => {
    switch (option) {
      case VoucherActivationOption.Later:
        return t('Activate later')
      case VoucherActivationOption.Now:
        return t('Activate now')
      case VoucherActivationOption.Date:
        return t('Add an activation date')
      default:
        return ''
    }
  }
}

interface IVoucherActivationOptionsProps {
  availableOptions: VoucherActivationOption[]
}

export const VoucherActivationOptions = <
  FormValues extends FieldValues = FieldValues
>({
  availableOptions
}: IVoucherActivationOptionsProps) => {
  const {
    errors,
    watch,
    register,
    setValue,
    unregister,
    clearError,
    control,
    setError
  } = useFormContext<FormValues>()
  const {t} = useTranslation()
  const [hasExpirationDate, onHasExpirationDateChange] = useCheckboxWithLabel()
  const error = get(errors, VoucherActivationOptionsField.Activation) as
    | FieldError
    | undefined
  const classes = useStyles()
  const translateVoucherActivationOption = useTranslateVoucherActivationOption()
  const formValues = watch()
  return (
    <>
      <InputRow
        nodes={[
          <FormControl
            key={VoucherActivationOptionsField.Activation}
            error={Boolean(error)}
            required
            fullWidth
          >
            <Typography variant="subtitle1">
              {t('Activate vouchers?')}
            </Typography>
            <FormSubText error={error} validationOptions={{required: true}} />
            <Controller
              as={({value, ...props}) => (
                <RadioGroup {...props} value={value || null}>
                  {availableOptions.map((o) => (
                    <FormControlLabel
                      key={o}
                      value={o}
                      control={<Radio />}
                      label={translateVoucherActivationOption(o)}
                    />
                  ))}
                </RadioGroup>
              )}
              name={VoucherActivationOptionsField.Activation}
              control={control}
              rules={{required: true}}
            />
          </FormControl>
        ]}
      />
      {formValues[VoucherActivationOptionsField.Activation] ===
        VoucherActivationOption.Date && (
        <div className={classes.paddingBottom}>
          <FormDatetimeInput<FormValues>
            validationOptions={{
              required: true
            }}
            fullWidth
            name={
              VoucherActivationOptionsField.ActivationDatetime as FormFieldName<FormValues>
            }
            errors={errors}
            register={register}
            setValue={setValue}
            unregister={unregister}
            watch={watch}
            dataTimePickerProps={{
              label: t('Activation date'),
              disablePast: true,
              maxDateTime:
                formValues[VoucherActivationOptionsField.ExpirationDatetime]
            }}
            clearError={clearError}
            control={control}
            setError={setError}
          />
        </div>
      )}
      <CheckboxWithLabel
        checked={hasExpirationDate}
        onChange={onHasExpirationDateChange}
        label={t('Add an expiration date')}
      />
      {hasExpirationDate && (
        <FormDatetimeInput<FormValues>
          validationOptions={{
            required: true
          }}
          fullWidth
          name={
            VoucherActivationOptionsField.ExpirationDatetime as FormFieldName<FormValues>
          }
          errors={errors}
          register={register}
          setValue={setValue}
          unregister={unregister}
          watch={watch}
          dataTimePickerProps={{
            label: t('Expiration date'),
            disablePast: true,
            minDateTime:
              formValues[VoucherActivationOptionsField.ActivationDatetime]
          }}
          clearError={clearError}
          control={control}
          setError={setError}
        />
      )}
    </>
  )
}
