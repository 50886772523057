import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateTourTimeSlotMutation,
  CreateTourTimeSlotMutationVariables,
  TourTimeSlotInput
} from '../../../__generated__/schema'

const CREATE_TOUR_TIME_SLOT = gql`
  mutation CreateTourTimeSlot($input: TourTimeSlotInput!) {
    createTourTimeSlot(input: $input) {
      id
    }
  }
`

export const useCreateTourTimeSlot = () => {
  const [createTourTimeSlot] = useMutation<
    CreateTourTimeSlotMutation,
    CreateTourTimeSlotMutationVariables
  >(CREATE_TOUR_TIME_SLOT)
  return useCallback(
    (input: TourTimeSlotInput) =>
      createTourTimeSlot({
        variables: {input}
      }),
    [createTourTimeSlot]
  )
}
