import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientVatRegistered,
  EventState,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../../hooks/translateCurrencies'

import {Theme} from '../../../../../../theme'
import {useEnsurePermissions, useUserInfo} from '../../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../../utils/formatting'
import {PricingStatus} from '../../../../../../utils/setPricingsStatus'
import {EntityStateChip} from '../../../../../common'
import {eventPricingStatusColors} from '../../../../../constants'

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    padding: theme.spacing(3)
  },
  ticketRow: {
    marginTop: theme.spacing(2)
  },
  buttons: {
    marginRight: theme.spacing(-2)
  }
}))

const useGetChipLabel = (startsAt: string, status: PricingStatus) => {
  const {t} = useTranslation()

  if (status === PricingStatus.Current) return t('On sale')
  if (status === PricingStatus.Past) return t('Ended')

  return t('start-in-n-days', {count: dayjs(startsAt).diff(dayjs(), 'day')})
}

interface IPricingExpandableCardProps {
  summary: React.ReactElement
  details: React.ReactElement
}

const ExpandablePricingCard: React.FC<IPricingExpandableCardProps> = ({
  summary,
  details
}: IPricingExpandableCardProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">{details}</Box>
      </AccordionDetails>
    </Accordion>
  )
}

interface IPricingCardSummaryProps {
  startsAt: string
  onEditButtonClick: () => void
  onDeleteButtonClick: () => any
  status: PricingStatus
  areButtonsVisible?: boolean
  areButtonsEnabled?: boolean
}

const PricingCardSummary = ({
  startsAt,
  onDeleteButtonClick,
  onEditButtonClick,
  status,
  areButtonsVisible,
  areButtonsEnabled
}: IPricingCardSummaryProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {formatDateTime} = useDateTimeFormatters()

  const chipLabel = useGetChipLabel(startsAt, status)

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container alignItems="center">
          <Typography component="div" variant="caption">
            <Box fontWeight="fontWeightBold">
              {t('Price start in')} {formatDateTime(new Date(startsAt))}
            </Box>
          </Typography>
          <Box marginLeft={1}>
            <EntityStateChip
              label={chipLabel}
              colorConf={eventPricingStatusColors[status]}
            />
          </Box>
        </Grid>
      </Grid>
      {areButtonsVisible && (
        <Grid item className={classes.buttons}>
          {P([PermissionCode.DeleteEventPricing]) && (
            <Button
              disabled={!areButtonsEnabled}
              onClick={onDeleteButtonClick}
              color="primary"
            >
              {t('Delete')}
            </Button>
          )}
          {P([PermissionCode.UpdateEventPricing]) && (
            <Button
              disabled={!areButtonsEnabled}
              onClick={onEditButtonClick}
              color="primary"
            >
              {t('Edit')}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  )
}

interface IPricingCardDetailProps {
  ticketTypes: Array<{
    name: string
    promotions: boolean
    price: number
    id: number
    vatRate: number
  }>
  clientVatRegistered: ClientVatRegistered
}

export const PricingCardDetail: React.FC<IPricingCardDetailProps> = ({
  ticketTypes,
  clientVatRegistered
}: IPricingCardDetailProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <>
      {ticketTypes.map(({name, promotions, price, id, vatRate}) => (
        <Grid
          className={classes.ticketRow}
          container
          key={id}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography component="div" variant="caption">
              <Box fontWeight="fontWeightBold">{name}</Box>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {[
                clientVatRegistered !== ClientVatRegistered.None &&
                  t('VAT rate {{vatRate}}%', {
                    vatRate: vatRate.toFixed(2).replace('.', ',')
                  }),
                promotions ? t('Promotions are available') : t('Promotions off')
              ]
                .filter(Boolean)
                .join(' • ')}
            </Typography>
          </Grid>
          <Typography variant="caption" color="textSecondary">
            {translateEffectiveClientPrice(price)}
          </Typography>
        </Grid>
      ))}
    </>
  )
}

interface IPricingCardProps {
  ticketTypes: Array<{
    name: string
    promotions: boolean
    price: number
    id: number
    vatRate: number
  }>
  startsAt: string
  status: PricingStatus
  openEditDialog: (pricingId: number) => any
  openDeleteDialog: (pricingId: number) => void
  id: number
  eventState: EventState
  isLastAvailablePricing: boolean
  clientVatRegistered: ClientVatRegistered
}

export const PricingCard: React.FC<IPricingCardProps> = ({
  ticketTypes,
  startsAt,
  status,
  openEditDialog,
  openDeleteDialog,
  eventState,
  isLastAvailablePricing,
  id,
  clientVatRegistered
}: IPricingCardProps) => {
  const classes = useStyles()
  const {effectiveClient} = useUserInfo()

  const handleEditButtonClick = useCallback(() => {
    openEditDialog(id)
  }, [openEditDialog, id])

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteDialog(id)
  }, [id, openDeleteDialog])

  if (!effectiveClient) return null

  if (eventState === EventState.Draft || status !== PricingStatus.Past) {
    return (
      <Card className={classes.card}>
        <PricingCardSummary
          areButtonsVisible
          areButtonsEnabled={
            (eventState === EventState.Draft && !isLastAvailablePricing) ||
            status === PricingStatus.Future
          }
          onDeleteButtonClick={handleDeleteButtonClick}
          onEditButtonClick={handleEditButtonClick}
          startsAt={startsAt}
          status={status}
        />
        <PricingCardDetail
          ticketTypes={ticketTypes}
          clientVatRegistered={clientVatRegistered}
        />
      </Card>
    )
  }

  return (
    <ExpandablePricingCard
      summary={
        <PricingCardSummary
          onEditButtonClick={handleEditButtonClick}
          onDeleteButtonClick={handleDeleteButtonClick}
          startsAt={startsAt}
          status={status}
          areButtonsEnabled
        />
      }
      details={
        <PricingCardDetail
          ticketTypes={ticketTypes}
          clientVatRegistered={clientVatRegistered}
        />
      }
    />
  )
}
