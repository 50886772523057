import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import _ from 'lodash'
import {useCallback} from 'react'

import {
  ActivateLibraryImageMutation,
  ActivateLibraryImageMutationVariables,
  AddClientShowVideoMutation,
  AddClientShowVideoMutationVariables,
  AddLibraryShowVideoMutation,
  AddLibraryShowVideoMutationVariables,
  AssignLibraryImagesToClientShowMutation,
  AssignLibraryImagesToClientShowMutationVariables,
  ClientShowQuery,
  ClientShowQueryVariables,
  CreateClientShowMutation,
  CreateClientShowMutationVariables,
  CreateLibraryShowMutation,
  CreateLibraryShowMutationVariables,
  DeleteClientShowImageMutation,
  DeleteClientShowImageMutationVariables,
  DeleteClientShowMutation,
  DeleteClientShowMutationVariables,
  DeleteLibraryImageMutation,
  DeleteLibraryImageMutationVariables,
  GetLibraryShowCrewQuery,
  GetLibraryShowCrewQueryVariables,
  GetLibraryShowImagesQuery,
  GetLibraryShowImagesQueryVariables,
  LibraryShowQuery,
  LibraryShowQueryVariables,
  LibraryShowsQuery,
  LibraryShowsQueryVariables,
  MixedShowImagesFilterInput,
  MixedShowImagesQuery,
  MixedShowImagesQueryVariables,
  MixedShowsQuery,
  MixedShowsQueryVariables,
  RemoveImageFromLibraryShowMutation,
  RemoveImageFromLibraryShowMutationVariables,
  ShowContentDescriptorCodesQuery,
  ShowContentDescriptorCodesQueryVariables,
  ShowSelectFieldsQuery,
  ShowSelectFieldsQueryVariables,
  ShowsFilterInput,
  UpdateClientShowImageOrderPositionMutation,
  UpdateClientShowImageOrderPositionMutationVariables,
  UpdateClientShowMutation,
  UpdateClientShowMutationVariables,
  UpdateClientShowVideosMutation,
  UpdateClientShowVideosMutationVariables,
  UpdateLibraryShowMutation,
  UpdateLibraryShowMutationVariables,
  UpdateLibraryShowVideosMutation,
  UpdateLibraryShowVideosMutationVariables,
  UploadClientShowImageMutation,
  UploadClientShowImageMutationVariables,
  UploadLibraryImageMutation,
  UploadLibraryImageMutationVariables,
  UpsertClientShowCrewMutation,
  UpsertClientShowCrewMutationVariables,
  UpsertClientShowTranslationsMutation,
  UpsertClientShowTranslationsMutationVariables,
  UpsertLibraryShowCrewMutation,
  UpsertLibraryShowCrewMutationVariables,
  UpsertLibraryShowTranslationsMutation,
  UpsertLibraryShowTranslationsMutationVariables
} from '../../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../utils/pagination'
import {defaultPaginationInput, PAGINATION_FRAGMENT} from '../../graphql'
import {CLIENT_SHOW_PROPERTIES_FRAGMENT} from '../graphql'

const LIBRARY_SHOW_PROPERTIES_FRAGMENT = gql`
  fragment LibraryShowProperties on LibraryShow {
    id
    originalTitle
    translations {
      localeCode
      title
      description
      tagline
    }
    countryCodes
    genreCodes
    typeCode
    ageClassificationCodes
    crew {
      crewRoleCode
    }
    duration
    globalReleaseDate
    images {
      id
      url
      thumbnails {
        id
        key
        size
        width
        height
        url
      }
    }
  }
`

const DETAIL_LIBRARY_SHOW_PROPERTIES_FRAGMENT = gql`
  fragment DetailLibraryShowProperties on LibraryShow {
    id
    originalTitle
    productionYear
    globalReleaseDate
    duration
    typeCode
    ageClassificationCodes
    videos {
      sourceVideoId
      source
      url
    }
    translations {
      localeCode
      title
      description
      tagline
    }
    contentDescriptorCodes
    genreCodes
    distributions {
      formatCode
      soundMixCode
      versionCode
    }
    languageCodes
    countryCodes
    crew {
      crewRoleCode
      persons {
        name
        description
      }
    }
    images {
      id
      type
      width
      height
      url
      type
      thumbnails(size: [medium]) {
        id
        width
        height
        url
      }
    }
  }
`

const DETAIL_CLIENT_SHOW_PROPERTIES_FRAGMENT = gql`
  fragment DetailClientShowProperties on ClientShow {
    id
    showsDbId
    originalTitle
    productionYear
    globalReleaseDate
    duration
    typeCode
    ageClassificationCodes
    businessPartnerId
    businessPartner {
      id
      companyName
    }
    translations {
      localeCode
      title
      description
      tagline
    }
    genreCodes
    distributions {
      formatCode
      soundMixCode
      versionCode
    }
    languageCodes
    countryCodes
    crew {
      crewRoleCode
      persons {
        name
        description
      }
    }
    primaryImage(type: poster) {
      id
      url
      thumbnails(size: [small]) {
        id
        url
      }
    }
    images {
      id
      type
      width
      height
      url
      type
      thumbnails(size: [medium]) {
        id
        width
        height
        url
      }
    }
    videos {
      source
      url
      sourceVideoId
    }
    contentDescriptorCodes
  }
`

const GET_LIBRARY_SHOWS = gql`
  query LibraryShows(
    $filter: ShowsFilterInput
    $paginationInput: PaginationInput!
  ) {
    libraryShows(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...LibraryShowProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${LIBRARY_SHOW_PROPERTIES_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

const GET_MIXED_SHOWS = gql`
  query MixedShows(
    $filter: ShowsFilterInput!
    $paginationInput: PaginationInput!
  ) {
    mixedShows(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...LibraryShowProperties
        ...ClientShowProperties
      }
      pagination {
        hasMore
        offset
        limit
        totalRowsCount
      }
    }
  }
  ${LIBRARY_SHOW_PROPERTIES_FRAGMENT}
  ${CLIENT_SHOW_PROPERTIES_FRAGMENT}
`

const GET_CLIENT_SHOW = gql`
  query ClientShow($id: Int!) {
    clientShow(id: $id) {
      ...DetailClientShowProperties
    }
  }
  ${DETAIL_CLIENT_SHOW_PROPERTIES_FRAGMENT}
`

const GET_LIBRARY_SHOW = gql`
  query LibraryShow($id: Int!) {
    libraryShow(id: $id) {
      ...DetailLibraryShowProperties
    }
  }
  ${DETAIL_LIBRARY_SHOW_PROPERTIES_FRAGMENT}
`

const CREATE_LIBRARY_SHOW = gql`
  mutation CreateLibraryShow(
    $translation: ShowTranslationInput!
    $data: LibraryShowInput!
  ) {
    createLibraryShow(data: $data, translation: $translation) {
      ...DetailLibraryShowProperties
    }
  }
  ${DETAIL_LIBRARY_SHOW_PROPERTIES_FRAGMENT}
`
const CREATE_CLIENT_SHOW = gql`
  mutation CreateClientShow(
    $translation: ShowTranslationInput!
    $data: ClientShowInput!
    $showsDbId: Int!
  ) {
    createClientShow(
      data: $data
      translation: $translation
      showsDbId: $showsDbId
    ) {
      ...DetailClientShowProperties
    }
  }
  ${DETAIL_CLIENT_SHOW_PROPERTIES_FRAGMENT}
`

const UPDATE_LIBRARY_SHOW = gql`
  mutation UpdateLibraryShow(
    $id: Int!
    $translation: ShowTranslationInput!
    $data: LibraryShowInput!
  ) {
    updateLibraryShow(id: $id, data: $data, translation: $translation) {
      ...DetailLibraryShowProperties
    }
  }
  ${DETAIL_LIBRARY_SHOW_PROPERTIES_FRAGMENT}
`

const UPDATE_CLIENT_SHOW = gql`
  mutation UpdateClientShow(
    $id: Int!
    $translation: ShowTranslationInput!
    $data: UpdateClientShowInput!
  ) {
    updateClientShow(id: $id, data: $data, translation: $translation) {
      ...DetailClientShowProperties
    }
  }
  ${DETAIL_CLIENT_SHOW_PROPERTIES_FRAGMENT}
`

const UPSERT_LIBRARY_SHOW_TRANSLATIONS = gql`
  mutation UpsertLibraryShowTranslations(
    $id: Int!
    $data: [ShowTranslationInput!]!
  ) {
    upsertLibraryShowTranslations(id: $id, data: $data) {
      localeCode
    }
  }
`
const UPSERT_CLIENT_SHOW_TRANSLATIONS = gql`
  mutation UpsertClientShowTranslations(
    $id: Int!
    $data: [ShowTranslationInput!]!
  ) {
    upsertClientShowTranslations(id: $id, data: $data) {
      localeCode
      title
      description
      tagline
    }
  }
`

const GET_SHOW_SELECT_FIELDS = gql`
  query ShowSelectFields {
    showTypes {
      code
      ageClassificationCodes
      genreCodes
      formatCodes
      soundMixCodes
      versionCodes
    }
  }
`

const CLIENT_SHOW_IMAGE_PROPERTIES_FRAGMENT = gql`
  fragment ClientShowImageProperties on ClientShowImage {
    id
    type
    width
    height
    showId
    libraryImageId
    url
    thumbnails(size: [large]) {
      id
      width
      height
      url
    }
    order
  }
`

const LIBRARY_SHOW_IMAGE_PROPERTIES_FRAGMENT = gql`
  fragment LibraryShowImageProperties on LibraryShowImage {
    id
    type
    width
    height
    url
    thumbnails(size: [large]) {
      id
      url
      width
      height
    }
  }
`

const GET_MIXED_SHOW_IMAGES = gql`
  query MixedShowImages(
    $clientShowId: Int!
    $filter: MixedShowImagesFilterInput!
  ) {
    mixedShowImages(clientShowId: $clientShowId, filter: $filter) {
      ...ClientShowImageProperties
      ...LibraryShowImageProperties
    }
  }
  ${CLIENT_SHOW_IMAGE_PROPERTIES_FRAGMENT}
  ${LIBRARY_SHOW_IMAGE_PROPERTIES_FRAGMENT}
`

export const useGetMixedShowImages = (
  clientShowId: number,
  filter: MixedShowImagesFilterInput
) =>
  useQuery<MixedShowImagesQuery, MixedShowImagesQueryVariables>(
    GET_MIXED_SHOW_IMAGES,
    {
      variables: {clientShowId, filter},
      fetchPolicy: 'network-only'
    }
  )

const DELETE_CLIENT_SHOW_IMAGE = gql`
  mutation DeleteClientShowImage($id: Int!) {
    deleteClientShowImage(id: $id) {
      id
    }
  }
`

export const useDeleteClientShowImage = () => {
  const [deleteClientShowImage] = useMutation<
    DeleteClientShowImageMutation,
    DeleteClientShowImageMutationVariables
  >(DELETE_CLIENT_SHOW_IMAGE)
  return (variables: DeleteClientShowImageMutationVariables) =>
    deleteClientShowImage({
      variables
    })
}

const ASSIGN_LIBRARY_IMAGES_TO_CLIENT_SHOW = gql`
  mutation AssignLibraryImagesToClientShow(
    $libraryImageIds: [Int!]!
    $showId: Int!
  ) {
    assignLibraryImagesToClientShow(
      libraryImageIds: $libraryImageIds
      showId: $showId
    ) {
      ...ClientShowImageProperties
    }
  }
  ${CLIENT_SHOW_IMAGE_PROPERTIES_FRAGMENT}
`

export const useAssignLibraryImagesToClientShow = () => {
  const [assignLibraryImagesToClientShow] = useMutation<
    AssignLibraryImagesToClientShowMutation,
    AssignLibraryImagesToClientShowMutationVariables
  >(ASSIGN_LIBRARY_IMAGES_TO_CLIENT_SHOW)
  return (variables: AssignLibraryImagesToClientShowMutationVariables) =>
    assignLibraryImagesToClientShow({
      variables
    })
}

const UPLOAD_CLIENT_SHOW_IMAGE = gql`
  mutation UploadClientShowImage(
    $isPrivate: Boolean!
    $data: ClientShowImageUploadInput!
    $file: Upload!
  ) {
    uploadClientShowImage(isPrivate: $isPrivate, data: $data, file: $file) {
      ...ClientShowImageProperties
    }
  }
  ${CLIENT_SHOW_IMAGE_PROPERTIES_FRAGMENT}
`

export const useUploadClientShowImage = () => {
  const [uploadClientShowImage] = useMutation<
    UploadClientShowImageMutation,
    UploadClientShowImageMutationVariables
  >(UPLOAD_CLIENT_SHOW_IMAGE)
  return (variables: UploadClientShowImageMutationVariables) =>
    uploadClientShowImage({
      variables
    })
}

export const useGetLibraryShows = (filterInput: ShowsFilterInput) =>
  useQueryWithPagination<LibraryShowsQuery, LibraryShowsQueryVariables>(
    GET_LIBRARY_SHOWS,
    {
      variables: {filter: filterInput, paginationInput: defaultPaginationInput},
      fetchPolicy: 'network-only'
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.libraryShows.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        libraryShows: {
          ...fetchMoreResult.libraryShows,
          items: _.uniqBy(
            [
              ...prevData.libraryShows.items,
              ...fetchMoreResult.libraryShows.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

export const useGetMixedShows = (searchString: string, limit: number) =>
  useQueryWithPagination<MixedShowsQuery, MixedShowsQueryVariables>(
    GET_MIXED_SHOWS,
    {
      variables: {
        filter: {hasText: searchString},
        paginationInput: {limit}
      },
      skip: !searchString,
      fetchPolicy: 'network-only'
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.mixedShows.pagination),
      updateData: (prevData, fetchMoreData) => ({
        ...fetchMoreData,
        mixedShows: {
          ...fetchMoreData.mixedShows,
          items: [
            ...prevData.mixedShows.items,
            ...fetchMoreData.mixedShows.items
          ]
        }
      })
    }
  )

// Mocked part of getShow data that's not supported on BE
const imgUrl50x72 = 'https://via.placeholder.com/50x72'
export const showMockedProperties = {
  imgUrl: 'https://via.placeholder.com/100x140',
  media: {
    posters: [
      {id: 1, imgUrl: imgUrl50x72, alt: 'Star Wars 1'},
      {id: 2, imgUrl: imgUrl50x72, alt: 'Star Wars 2'},
      {id: 3, imgUrl: imgUrl50x72, alt: 'Star Wars 3'},
      {id: 4, imgUrl: imgUrl50x72, alt: 'Star Wars 4'},
      {id: 5, imgUrl: imgUrl50x72, alt: 'Star Wars 5'},
      {id: 6, imgUrl: imgUrl50x72, alt: 'Star Wars 6'},
      {id: 7, imgUrl: imgUrl50x72, alt: 'Star Wars 7'},
      {id: 8, imgUrl: imgUrl50x72, alt: 'Star Wars 8'},
      {id: 9, imgUrl: imgUrl50x72, alt: 'Star Wars 9'},
      {id: 10, imgUrl: imgUrl50x72, alt: 'Star Wars 10'}
    ],
    photos: [
      {id: 1, imgUrl: imgUrl50x72, alt: 'Star Wars 1'},
      {id: 2, imgUrl: imgUrl50x72, alt: 'Star Wars 2'},
      {id: 3, imgUrl: imgUrl50x72, alt: 'Star Wars 3'},
      {id: 4, imgUrl: imgUrl50x72, alt: 'Star Wars 4'}
    ],
    covers: [
      {id: 1, imgUrl: imgUrl50x72, alt: 'Star Wars 1'},
      {id: 2, imgUrl: imgUrl50x72, alt: 'Star Wars 2'},
      {id: 3, imgUrl: imgUrl50x72, alt: 'Star Wars 3'},
      {id: 4, imgUrl: imgUrl50x72, alt: 'Star Wars 4'}
    ]
  }
}

export const useGetClientShow = (id: number) =>
  useQuery<ClientShowQuery, ClientShowQueryVariables>(GET_CLIENT_SHOW, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

export const useGetLibraryShow = (id: number) =>
  useQuery<LibraryShowQuery, LibraryShowQueryVariables>(GET_LIBRARY_SHOW, {
    variables: {id}
  })

export const useCreateLibraryShow = () => {
  const [createLibraryShow] =
    useMutation<CreateLibraryShowMutation, CreateLibraryShowMutationVariables>(
      CREATE_LIBRARY_SHOW
    )
  return (variables: CreateLibraryShowMutationVariables) =>
    createLibraryShow({
      variables
    })
}

export const useCreateClientShow = () => {
  const [createClientShow] =
    useMutation<CreateClientShowMutation, CreateLibraryShowMutationVariables>(
      CREATE_CLIENT_SHOW
    )
  return (variables: CreateClientShowMutationVariables) =>
    createClientShow({variables})
}

export const useUpdateLibraryShow = () => {
  const [updateLibraryShow] =
    useMutation<UpdateLibraryShowMutation, UpdateLibraryShowMutationVariables>(
      UPDATE_LIBRARY_SHOW
    )
  return (variables: UpdateLibraryShowMutationVariables) =>
    updateLibraryShow({
      variables
    })
}

export const useUpdateClientShow = () => {
  const [updateClientShow] =
    useMutation<UpdateClientShowMutation, UpdateClientShowMutationVariables>(
      UPDATE_CLIENT_SHOW
    )
  return (variables: UpdateClientShowMutationVariables) =>
    updateClientShow({
      variables
    })
}

export const useUpsertLibraryShowTranslations = (showId: number) => {
  const [upsertLibraryShowTranslations] = useMutation<
    UpsertLibraryShowTranslationsMutation,
    UpsertLibraryShowTranslationsMutationVariables
  >(UPSERT_LIBRARY_SHOW_TRANSLATIONS, {
    refetchQueries: () => {
      return [{query: GET_LIBRARY_SHOW, variables: {id: showId}}]
    }
  })
  return (variables: UpsertLibraryShowTranslationsMutationVariables) =>
    upsertLibraryShowTranslations({
      variables
    })
}

export const useUpsertClientShowTranslations = (showId: number) => {
  const [upsertClientShowTranslations] = useMutation<
    UpsertClientShowTranslationsMutation,
    UpsertClientShowTranslationsMutationVariables
  >(UPSERT_CLIENT_SHOW_TRANSLATIONS, {
    refetchQueries: () => {
      return [{query: GET_CLIENT_SHOW, variables: {id: showId}}]
    }
  })
  return (variables: UpsertClientShowTranslationsMutationVariables) =>
    upsertClientShowTranslations({
      variables
    })
}

// Note: this is called before rendering the whole app load data into cache
export const useFetchShowSelectFields = () =>
  useQuery<ShowSelectFieldsQuery, ShowSelectFieldsQueryVariables>(
    GET_SHOW_SELECT_FIELDS
  )

const UPSERT_CLIENT_SHOW_CREW = gql`
  mutation upsertClientShowCrew($id: Int!, $data: [ShowCrewByRoleInput!]!) {
    upsertClientShowCrew(id: $id, data: $data) {
      crewRoleCode
      persons {
        name
        description
      }
    }
  }
`

export const useUpsertClientShowCrew = () => {
  const [upsertClientShowCrew] = useMutation<
    UpsertClientShowCrewMutation,
    UpsertClientShowCrewMutationVariables
  >(UPSERT_CLIENT_SHOW_CREW)
  return (variables: UpsertClientShowCrewMutationVariables) =>
    upsertClientShowCrew({
      variables,
      refetchQueries: [
        {
          query: GET_CLIENT_SHOW,
          variables: {
            id: variables.id
          }
        }
      ]
    })
}

const UPSERT_LIBRARY_SHOW_CREW = gql`
  mutation upsertLibraryShowCrew($id: Int!, $data: [ShowCrewByRoleInput!]!) {
    upsertLibraryShowCrew(id: $id, data: $data) {
      crewRoleCode
      persons {
        name
        description
      }
    }
  }
`

export const useUpsertLibraryShowCrew = () => {
  const [upsertLibraryShowCrew] = useMutation<
    UpsertLibraryShowCrewMutation,
    UpsertLibraryShowCrewMutationVariables
  >(UPSERT_LIBRARY_SHOW_CREW)
  return useCallback(
    (variables: UpsertLibraryShowCrewMutationVariables) =>
      upsertLibraryShowCrew({
        variables,
        refetchQueries: [
          {
            query: GET_CLIENT_SHOW,
            variables: {
              id: variables.id
            }
          }
        ]
      }),
    [upsertLibraryShowCrew]
  )
}

const GET_LIBRARY_SHOW_IMAGES = gql`
  query getLibraryShowImages($id: Int!) {
    libraryShow(id: $id) {
      id
      images {
        id
        state
        type
        url
        width
        height
        thumbnails(size: [large]) {
          id
          width
          height
          url
        }
      }
    }
  }
`

export const useGetLibraryShowImages = (
  variables: GetLibraryShowImagesQueryVariables
) =>
  useQuery<GetLibraryShowImagesQuery, GetLibraryShowImagesQueryVariables>(
    GET_LIBRARY_SHOW_IMAGES,
    {
      variables,
      fetchPolicy: 'network-only'
    }
  )

const UPLOAD_LIBRARY_IMAGE = gql`
  mutation uploadLibraryImage(
    $showId: Int!
    $data: LibraryImageUploadInput!
    $file: Upload!
  ) {
    uploadLibraryImage(showId: $showId, data: $data, file: $file) {
      id
    }
  }
`

export const useUploadLibraryImage = () => {
  const [uploadLibraryImage] =
    useMutation<
      UploadLibraryImageMutation,
      UploadLibraryImageMutationVariables
    >(UPLOAD_LIBRARY_IMAGE)
  return useCallback(
    (variables: UploadLibraryImageMutationVariables) =>
      uploadLibraryImage({variables}),
    [uploadLibraryImage]
  )
}

const GET_LIBRARY_SHOW_CREW = gql`
  query getLibraryShowCrew($id: Int!) {
    libraryShow(id: $id) {
      id
      crew {
        crewRoleCode
        persons {
          name
          description
        }
      }
    }
  }
`

export const useGetLibraryShowCrew = (id: number) =>
  useQuery<GetLibraryShowCrewQuery, GetLibraryShowCrewQueryVariables>(
    GET_LIBRARY_SHOW_CREW,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

export const UPDATE_CLIENT_SHOW_IMAGE_ORDER_POSITION = gql`
  mutation updateClientShowImageOrderPosition(
    $id: PositiveInt!
    $newIndexPosition: Int!
  ) {
    updateClientShowImageOrderPosition(
      id: $id
      newIndexPosition: $newIndexPosition
    ) {
      id
    }
  }
`

export const useUpdateClientShowImageOrderPosition = () => {
  const [updateClientShowImageOrderPosition] = useMutation<
    UpdateClientShowImageOrderPositionMutation,
    UpdateClientShowImageOrderPositionMutationVariables
  >(UPDATE_CLIENT_SHOW_IMAGE_ORDER_POSITION)
  return useCallback(
    (variables: UpdateClientShowImageOrderPositionMutationVariables) =>
      updateClientShowImageOrderPosition({variables}),
    [updateClientShowImageOrderPosition]
  )
}

const REMOVE_IMAGE_FROM_LIBRARY_SHOW = gql`
  mutation RemoveImageFromLibraryShow(
    $showId: PositiveInt!
    $imageId: PositiveInt!
  ) {
    removeImageFromLibraryShow(showId: $showId, imageId: $imageId) {
      id
    }
  }
`

export const useRemoveImageFromLibraryShow = () => {
  const [removeImageFromLibraryShow] = useMutation<
    RemoveImageFromLibraryShowMutation,
    RemoveImageFromLibraryShowMutationVariables
  >(REMOVE_IMAGE_FROM_LIBRARY_SHOW)
  return useCallback(
    (variables: RemoveImageFromLibraryShowMutationVariables) =>
      removeImageFromLibraryShow({
        variables
      }),
    [removeImageFromLibraryShow]
  )
}

const DELETE_LIBRARY_IMAGE = gql`
  mutation DeleteLibraryImage($id: PositiveInt!) {
    deleteLibraryImage(id: $id) {
      id
    }
  }
`

export const useDeleteLibraryImage = () => {
  const [deleteLibraryImage] =
    useMutation<
      DeleteLibraryImageMutation,
      DeleteLibraryImageMutationVariables
    >(DELETE_LIBRARY_IMAGE)
  return useCallback(
    (variables: DeleteLibraryImageMutationVariables) =>
      deleteLibraryImage({variables}),
    [deleteLibraryImage]
  )
}

const ACTIVATE_LIBRARY_IMAGE = gql`
  mutation ActivateLibraryImage($id: PositiveInt!) {
    activateLibraryImage(id: $id) {
      id
    }
  }
`

export const useActivateLibraryImage = () => {
  const [activateLibraryImage] = useMutation<
    ActivateLibraryImageMutation,
    ActivateLibraryImageMutationVariables
  >(ACTIVATE_LIBRARY_IMAGE)
  return useCallback(
    (variables: ActivateLibraryImageMutationVariables) =>
      activateLibraryImage({variables}),
    [activateLibraryImage]
  )
}

const UPDATE_CLIENT_SHOW_VIDEOS = gql`
  ${DETAIL_CLIENT_SHOW_PROPERTIES_FRAGMENT}
  mutation UpdateClientShowVideos(
    $id: PositiveInt!
    $input: [UpdateShowVideoInput!]!
  ) {
    updateClientShowVideos(id: $id, input: $input) {
      ...DetailClientShowProperties
    }
  }
`

export const useUpdateClientShowVideos = () => {
  const [updateClientShowVideos] = useMutation<
    UpdateClientShowVideosMutation,
    UpdateClientShowVideosMutationVariables
  >(UPDATE_CLIENT_SHOW_VIDEOS)
  return useCallback(
    (variables: UpdateClientShowVideosMutationVariables) =>
      updateClientShowVideos({variables}),
    [updateClientShowVideos]
  )
}

const ADD_CLIENT_SHOW_VIDEO = gql`
  ${DETAIL_CLIENT_SHOW_PROPERTIES_FRAGMENT}
  mutation AddClientShowVideo($id: PositiveInt!, $input: AddShowVideoInput!) {
    addClientShowVideo(id: $id, input: $input) {
      ...DetailClientShowProperties
    }
  }
`

export const useAddClientShowVideo = () => {
  const [addClientShowVideo] = useMutation<
    AddClientShowVideoMutation,
    AddClientShowVideoMutationVariables
  >(ADD_CLIENT_SHOW_VIDEO)
  return useCallback(
    (variables: AddClientShowVideoMutationVariables) =>
      addClientShowVideo({variables}),
    [addClientShowVideo]
  )
}

const SHOW_CONTENT_DESCRIPTOR_CODES = gql`
  query ShowContentDescriptorCodes {
    showContentDescriptorCodes
  }
`

export const useShowContentDescriptorCodes = (skip?: boolean) =>
  useQuery<
    ShowContentDescriptorCodesQuery,
    ShowContentDescriptorCodesQueryVariables
  >(SHOW_CONTENT_DESCRIPTOR_CODES, {skip})

const ADD_LIBRARY_SHOW_VIDEO = gql`
  ${DETAIL_LIBRARY_SHOW_PROPERTIES_FRAGMENT}
  mutation AddLibraryShowVideo($id: PositiveInt!, $input: AddShowVideoInput!) {
    addLibraryShowVideo(id: $id, input: $input) {
      ...DetailLibraryShowProperties
    }
  }
`

export const useAddLibraryShowVideo = () => {
  const [addLibraryShowVideo] = useMutation<
    AddLibraryShowVideoMutation,
    AddLibraryShowVideoMutationVariables
  >(ADD_LIBRARY_SHOW_VIDEO)
  return useCallback(
    (variables: AddLibraryShowVideoMutationVariables) =>
      addLibraryShowVideo({variables}),
    [addLibraryShowVideo]
  )
}

const UPDATE_LIBRARY_SHOW_VIDEOS = gql`
  ${DETAIL_LIBRARY_SHOW_PROPERTIES_FRAGMENT}
  mutation UpdateLibraryShowVideos(
    $id: PositiveInt!
    $input: [UpdateShowVideoInput!]!
  ) {
    updateLibraryShowVideos(id: $id, input: $input) {
      ...DetailLibraryShowProperties
    }
  }
`

export const useUpdateLibraryShowVideos = () => {
  const [updateLibraryShowVideos] = useMutation<
    UpdateLibraryShowVideosMutation,
    UpdateLibraryShowVideosMutationVariables
  >(UPDATE_LIBRARY_SHOW_VIDEOS)
  return useCallback(
    (variables: UpdateLibraryShowVideosMutationVariables) =>
      updateLibraryShowVideos({variables}),
    [updateLibraryShowVideos]
  )
}

const DELETE_CLIENT_SHOW = gql`
  mutation DeleteClientShow($id: PositiveInt!) {
    deleteClientShow(id: $id)
  }
`

export const useDeleteClientShow = () => {
  const [deleteClientShow] =
    useMutation<DeleteClientShowMutation, DeleteClientShowMutationVariables>(
      DELETE_CLIENT_SHOW
    )
  return useCallback(
    (id: number) => deleteClientShow({variables: {id}}),
    [deleteClientShow]
  )
}
