import {
  CanvasObjectType,
  FeSeatState,
  FeZoneState,
  ITooltipProps,
  SEAT_SIZE
} from '@attendio/shared-components'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AuditoriumLayout, EventTicketType} from '../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../hooks/translateCurrencies'

export const useGetSeatTooltipLabelOnSeatingOptionsPage = () => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return useCallback(
    ({
      state,
      ticketTypePrice,
      ticketTypeName
    }: {
      state: FeSeatState
      ticketTypePrice: number
      ticketTypeName: string
    }): string => {
      switch (state) {
        case FeSeatState.Occupied:
          return t('Occupied • In someones cart')
        case FeSeatState.Disabled:
          return t('Disabled • Not for sale')
        case FeSeatState.Hidden:
          return t('Hidden • Not for sale')
        case FeSeatState.Selected:
          return t('Selected')
        case FeSeatState.Sold:
          return t('Sold')
        case FeSeatState.Reserved:
          return t('Reserved')
        case FeSeatState.UnAvailable:
          return t('Unavailable')
        case FeSeatState.Available:
          return [ticketTypeName, translatePrice(ticketTypePrice)]
            .filter(Boolean)
            .join(' • ')
        default:
          return t('Unknown')
      }
    },
    [t, translatePrice]
  )
}

export const useGetSeatTooltipLabel = () => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return useCallback(
    ({
      state,
      ticketTypePrice,
      ticketTypeName
    }: {
      state: FeSeatState
      ticketTypePrice: number
      ticketTypeName: string
    }): string => {
      switch (state) {
        case FeSeatState.Occupied:
          return t('Occupied • In someones cart')
        case FeSeatState.Disabled:
          return t('Disabled • Not for sale')
        case FeSeatState.Hidden:
          return t('Hidden • Not for sale')
        case FeSeatState.Selected:
          return t('In my cart')
        case FeSeatState.Sold:
          return t('Sold')
        case FeSeatState.Reserved:
          return t('Reserved')
        case FeSeatState.UnAvailable:
          return t('Unavailable')
        case FeSeatState.Available:
          return [ticketTypeName, translatePrice(ticketTypePrice)]
            .filter(Boolean)
            .join(' • ')
        default:
          return t('Unknown')
      }
    },
    [t, translatePrice]
  )
}

export const useGetZoneTooltipLabel = () => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return useCallback(
    ({
      state,
      ticketTypePrice,
      ticketTypeName
    }: {
      state: FeZoneState
      ticketTypePrice: number
      ticketTypeName: string
    }): string => {
      switch (state) {
        case FeZoneState.Available:
          return [ticketTypeName, translatePrice(ticketTypePrice)]
            .filter(Boolean)
            .join(' • ')
        case FeZoneState.Selected:
          return t<string>('In my cart')
        case FeZoneState.UnAvailable:
          return t<string>('No available tickets')
        default:
          return t<string>('Unavailable')
      }
    },
    [t, translatePrice]
  )
}

export const useGetZoneTooltipLabelOnSeatingOptionsPage = () => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return useCallback(
    ({
      state,
      ticketTypePrice,
      ticketTypeName
    }: {
      state: FeZoneState
      ticketTypePrice: number
      ticketTypeName: string
    }): string => {
      switch (state) {
        case FeZoneState.Available:
          return [ticketTypeName, translatePrice(ticketTypePrice)]
            .filter(Boolean)
            .join(' • ')
        case FeZoneState.Selected:
          return t<string>('Selected')
        case FeZoneState.UnAvailable:
          return t<string>('No available tickets')
        default:
          return t<string>('Unavailable')
      }
    },
    [t, translatePrice]
  )
}

interface ILayoutObjectTooltipArg {
  layout: AuditoriumLayout['layout']
  feSeatStatesByUuid: {[uuid: string]: FeSeatState}
  feZoneStatesByUuid: {[uuid: string]: FeZoneState}
  getTicketTypeForUuid: (uuid: string) => EventTicketType
  getZoneTooltipLabel: ({
    state,
    ticketTypePrice,
    ticketTypeName
  }: {
    state: FeZoneState
    ticketTypePrice: number
    ticketTypeName: string
  }) => string
  getSeatTooltipLabel: ({
    state,
    ticketTypePrice,
    ticketTypeName
  }: {
    state: FeSeatState
    ticketTypePrice: number
    ticketTypeName: string
  }) => string
}

interface IGetLayoutObjectMouseEnterHandlerArg extends ILayoutObjectTooltipArg {
  setTooltipProps: React.Dispatch<React.SetStateAction<ITooltipProps | null>>
}

const useGetLayoutObjectMouseEnterHandler = ({
  layout,
  feSeatStatesByUuid,
  feZoneStatesByUuid,
  setTooltipProps,
  getTicketTypeForUuid,
  getSeatTooltipLabel,
  getZoneTooltipLabel
}: IGetLayoutObjectMouseEnterHandlerArg) => {
  return useCallback(
    (uuid: string, type: CanvasObjectType) => {
      // @ts-ignore
      const layoutObject = layout[uuid]
      if (layoutObject && type === CanvasObjectType.Zone) {
        return () => {
          const ticketType = getTicketTypeForUuid(uuid)
          setTooltipProps({
            label: getZoneTooltipLabel({
              state: feZoneStatesByUuid[uuid],
              ticketTypeName: ticketType.name,
              ticketTypePrice: ticketType.price
            }),
            elementCoords: layoutObject.config.coords,
            elementDimensions: layoutObject.config.dimensions
          })
        }
      } else if (layoutObject && type === CanvasObjectType.Seat) {
        return () => {
          const ticketType = getTicketTypeForUuid(uuid)
          setTooltipProps({
            label: getSeatTooltipLabel({
              state: feSeatStatesByUuid[uuid],
              ticketTypeName: ticketType.name,
              ticketTypePrice: ticketType.price
            }),
            elementCoords: layoutObject.config.coords,
            elementDimensions: {width: SEAT_SIZE, height: SEAT_SIZE}
          })
        }
      }
      return undefined
    },
    [
      layout,
      getTicketTypeForUuid,
      setTooltipProps,
      getZoneTooltipLabel,
      feZoneStatesByUuid,
      getSeatTooltipLabel,
      feSeatStatesByUuid
    ]
  )
}

const useGetLayoutObjectMouseLeaveHandler = (
  setTooltipProps: React.Dispatch<React.SetStateAction<ITooltipProps | null>>
) =>
  useCallback(
    (uuid: string, type: CanvasObjectType) =>
      [CanvasObjectType.Seat, CanvasObjectType.Zone].includes(type)
        ? () => {
            setTooltipProps(null)
          }
        : undefined,
    [setTooltipProps]
  )

export const useLayoutObjectTooltip = ({
  layout,
  feSeatStatesByUuid,
  feZoneStatesByUuid,
  getTicketTypeForUuid,
  getSeatTooltipLabel,
  getZoneTooltipLabel
}: ILayoutObjectTooltipArg) => {
  const [TooltipProps, setTooltipProps] = useState<ITooltipProps | null>(null)

  const getMouseEnterHandler = useGetLayoutObjectMouseEnterHandler({
    layout,
    feSeatStatesByUuid,
    feZoneStatesByUuid,
    setTooltipProps,
    getTicketTypeForUuid,
    getSeatTooltipLabel,
    getZoneTooltipLabel
  })

  const getMouseLeaveHandler =
    useGetLayoutObjectMouseLeaveHandler(setTooltipProps)
  return {
    TooltipProps,
    getMouseEnterHandler,
    getMouseLeaveHandler
  }
}
