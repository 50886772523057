import React from 'react'
import {useTranslation} from 'react-i18next'
import {ReservationState} from '../../../../../__generated__/schema'
import {Blank} from '../../../../visual/Blank'

interface IChildrenOnValidDataProps {
  children: React.ReactElement
  reservationState: ReservationState
  isReservationInCurrentCart: boolean
}

export const ChildrenOnValidData: React.FC<IChildrenOnValidDataProps> = ({
  children,
  reservationState,
  isReservationInCurrentCart
}: IChildrenOnValidDataProps) => {
  const {t} = useTranslation()
  switch (reservationState) {
    case ReservationState.Fulfilled:
      return <Blank title={t('Reservation already paid')} />
    case ReservationState.Expired:
      return <Blank title={t('Reservation expired')} />
    case ReservationState.Canceled:
      return <Blank title={t('Reservation deleted')} />
    case ReservationState.InCart:
      return !isReservationInCurrentCart ? (
        <Blank
          title={t('Unable to edit reservation')}
          description={t('Reservation is being processed.')}
        />
      ) : (
        children
      )
    default:
      return children
  }
}
