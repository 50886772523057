import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionSummary,
  AccordionSummaryProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../../../theme'

interface IItemAccordionSummaryProps {
  title: string
  primaryDescription: string
  secondaryDescription?: string
  priceLabel: string
  itemsCountLabel: string
  AccordionSummaryProps?: Partial<AccordionSummaryProps>
}

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'grid',
    gap: theme.spacing(0, 1),
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto'
  },
  leftGrid: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center'
  },
  rightGrid: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    textAlign: 'right',
    paddingRight: theme.spacing(1.5)
  }
}))

export const ItemAccordionSummary: React.FC<IItemAccordionSummaryProps> = ({
  title,
  primaryDescription,
  secondaryDescription,
  priceLabel,
  itemsCountLabel,
  AccordionSummaryProps
}: IItemAccordionSummaryProps) => {
  const classes = useStyles()
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      classes={{
        content: classes.content
      }}
      {...AccordionSummaryProps}
    >
      <div className={classes.leftGrid}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="caption" color="textSecondary">
          {primaryDescription}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {secondaryDescription}
        </Typography>
      </div>
      <div className={classes.rightGrid}>
        <Typography variant="body2">{priceLabel}</Typography>
        <Typography variant="overline" color="textSecondary">
          {itemsCountLabel}
        </Typography>
      </div>
    </AccordionSummary>
  )
}
