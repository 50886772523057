import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {Button} from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  EventState,
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery,
  PermissionCode,
  TourTimeSlotState
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {CalendarAlertIcon} from '../../../../common/icons'
import {Blank} from '../../../../visual/Blank'
import {isGetCheckEventQuery, isGetCheckTourTimeSlotQuery} from '../utils'

interface IChildrenOnValidDataProps {
  data:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
  children: React.ReactElement
}

export const ChildrenOnValidData: React.FC<IChildrenOnValidDataProps> = ({
  children,
  data
}: IChildrenOnValidDataProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  if (isGetCheckEventQuery(data)) {
    const {event} = data
    if (event.state === EventState.Canceled) {
      return (
        <Blank
          title={t('Event isn’t ready')}
          IconComp={CalendarAlertIcon}
          description={t(
            'Unable to check event tickets, because event was canceled. Change event settings and try it again.'
          )}
          actions={
            P([PermissionCode.PublishEvent]) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.replace(routeTo.admin.events.edit(event.id))
                }
              >
                {t('Settings')}
              </Button>
            )
          }
        />
      )
    }
    if (event.state === EventState.Draft) {
      return <Blank title={t('Event not found')} IconComp={CalendarAlertIcon} />
    }
    const now = dayjs()
    if (now.isBefore(event.gateOpensAt) || now.isAfter(event.gateClosedAt)) {
      return (
        <Blank
          title={t('Checking not allowed')}
          IconComp={ReportProblemOutlinedIcon}
          description={t(
            'Unable to check event tickets, because of event settings. Change event settings and try it again.'
          )}
          actions={
            P([PermissionCode.UpdateExtendedEventData]) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.replace(routeTo.admin.events.edit(event.id))
                }
              >
                {t('Settings')}
              </Button>
            )
          }
        />
      )
    }
  }
  if (isGetCheckTourTimeSlotQuery(data)) {
    const {tourTimeSlot} = data
    if (tourTimeSlot.state === TourTimeSlotState.Cancelled) {
      return (
        <Blank
          title={t('Tour time slot isn’t ready')}
          IconComp={CalendarAlertIcon}
          description={t(
            'Unable to check tour time slot tickets, because tour time slot was canceled. Change tour time slot settings and try it again.'
          )}
          actions={
            P([PermissionCode.ReadTourTimeSlots]) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.replace(
                    routeTo.admin.tours.timeSlots(tourTimeSlot.tourId)
                  )
                }
              >
                {t('Settings')}
              </Button>
            )
          }
        />
      )
    }
    if (tourTimeSlot.state === TourTimeSlotState.Draft) {
      return (
        <Blank
          title={t('Tour time slot not found')}
          IconComp={CalendarAlertIcon}
        />
      )
    }
    if (
      !dayjs().isBetween(tourTimeSlot.gateOpensAt, tourTimeSlot.gateClosesAt)
    ) {
      return (
        <Blank
          title={t('Checking not allowed')}
          IconComp={ReportProblemOutlinedIcon}
          description={t(
            'Unable to check tour time slot tickets, because of tour tme slot settings. Change tour time slot settings and try it again.'
          )}
          actions={
            P([PermissionCode.ReadTourTimeSlots]) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.replace(
                    routeTo.admin.tours.timeSlots(tourTimeSlot.tourId)
                  )
                }
              >
                {t('Settings')}
              </Button>
            )
          }
        />
      )
    }
  }
  return children
}
