import {Drawer, drawerClasses} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {CustomerForm} from './CustomerForm'
import {CustomerFormLocation, ICustomerForm} from './types'

const CREATE_CUSTOMER_FORM_ID = 'createCustomerFormId'

interface ICreateCustomerDrawerProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (formData: ICustomerForm) => Promise<void>
}

export const CreateCustomerDrawer: React.FC<ICreateCustomerDrawerProps> = ({
  isOpen,
  onClose,
  onSubmit
}: ICreateCustomerDrawerProps) => {
  const {t} = useTranslation()
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create customer')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_CUSTOMER_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
      >
        <CustomerForm
          formId={CREATE_CUSTOMER_FORM_ID}
          onSubmit={onSubmit}
          location={CustomerFormLocation.Create}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
