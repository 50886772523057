import React, {MouseEvent, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Gallery from 'react-photo-gallery'
import {
  DetailClientShowPropertiesFragment,
  DetailLibraryShowPropertiesFragment,
  ShowImageType
} from '../../../../__generated__/schema'
import {BaseGalleryImage} from '../../../common/BaseGalleryImage'
import {
  GalleryPreview,
  useGalleryPreview
} from '../../../common/galleryPreview/GalleryPreview'
import {useTranslateShowImageType} from '../../../constants'
import {MediaPreviewHeader} from '../Header'
import {getGalleryPhotos} from './common'
import {IImageAccordionProps, ImageAccordion} from './ImageAccordion'

type Image =
  | DetailLibraryShowPropertiesFragment['images'][number]
  | DetailClientShowPropertiesFragment['images'][number]

interface IShowDetailImageAccordionProps
  extends Omit<IImageAccordionProps, 'children'> {
  images: Image[]
}

export const ShowDetailImageAccordion: React.FC<IShowDetailImageAccordionProps> =
  ({images, ...imageAccordionProps}: IShowDetailImageAccordionProps) => {
    const {t} = useTranslation()
    const galleryPreviewHookProps = useGalleryPreview(
      images.map((i) => ({id: i.id, src: i.url}))
    )
    const getOpenPreviewHandler = useCallback(
      (id: number) => (e: MouseEvent) => {
        e.stopPropagation()
        galleryPreviewHookProps.openPreview(id)
      },
      [galleryPreviewHookProps]
    )
    const renderImage = useCallback(
      ({photo, margin}) => (
        <BaseGalleryImage
          key={photo.id}
          photo={photo}
          openPreview={getOpenPreviewHandler(photo.id)}
          margin={margin}
        />
      ),
      [getOpenPreviewHandler]
    )
    return (
      <ImageAccordion {...imageAccordionProps}>
        <Gallery
          margin={8}
          targetRowHeight={180}
          renderImage={renderImage}
          photos={getGalleryPhotos(images)}
        />
        <GalleryPreview
          {...galleryPreviewHookProps}
          header={
            <MediaPreviewHeader
              title={t('Preview')}
              hasArrowBackIcon
              onLeftActionClick={galleryPreviewHookProps.closePreview}
            />
          }
        />
      </ImageAccordion>
    )
  }

interface IShowDetailImageAccordionsProps {
  images: Image[]
  className?: string
}

export const ShowDetailImageAccordions: React.FC<IShowDetailImageAccordionsProps> =
  ({images, className}: IShowDetailImageAccordionsProps) => {
    const translateShowImageType = useTranslateShowImageType()
    const {t} = useTranslation()
    const [expandedImageType, setExpandedImageType] =
      useState<ShowImageType | null>(null)
    const getAccordionChangeHandler = useCallback(
      (type: ShowImageType) =>
        (e: React.ChangeEvent<{}>, isExpanded: boolean) =>
          setExpandedImageType(isExpanded ? type : null),
      []
    )
    return (
      <div className={className}>
        {Object.values(ShowImageType).map((type) => {
          const filteredImages = images.filter((i) => i.type === type)
          return (
            <ShowDetailImageAccordion
              images={images.filter((i) => i.type === type)}
              key={type}
              isExpanded={expandedImageType === type}
              onAccordionChange={getAccordionChangeHandler(type)}
              summaryDescription={t('{{count}} items', {
                count: filteredImages.length
              })}
              summaryTitle={translateShowImageType(type)}
              isDisabled={filteredImages.length === 0}
            />
          )
        })}
      </div>
    )
  }
