import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../__generated__/schema'
import {useEnsurePermissions} from '../utils/auth'
import {routeTo} from '../utils/routes'

import {useLocalStorageState} from './storage'

export interface DeviceServices {
  isAvailable: boolean
  link: string
}

export const useTranslateDeviceServiceTitle = () => {
  const {t} = useTranslation()
  return (link: string) => {
    switch (link) {
      case routeTo.admin.cashDesk.events():
        return t('Events')
      case routeTo.admin.cashDesk.tours():
        return t('Tours')
      case routeTo.admin.cashDesk.reservations():
        return t('Reservations')
      case routeTo.admin.cashDesk.shop():
      default:
        return t('Shop')
    }
  }
}

export const useTranslateDeviceServiceDescription = () => {
  const {t} = useTranslation()
  return (link: string) => {
    switch (link) {
      case routeTo.admin.cashDesk.events():
        return t('Selling tickets for events by cashier.')
      case routeTo.admin.cashDesk.tours():
        return t('Selling tickets for tours by cashier.')
      case routeTo.admin.cashDesk.reservations():
        return t('Managing and selling reservations by cashier.')
      case routeTo.admin.cashDesk.shop():
      default:
        return t('Selling goods and services by cashier.')
    }
  }
}

export const useDeviceServices = () => {
  const {P} = useEnsurePermissions()
  return useLocalStorageState<DeviceServices[]>('deviceServices', [
    {
      isAvailable: P([PermissionCode.Checkout]) || P([PermissionCode.Reserve]),
      link: routeTo.admin.cashDesk.events()
    },
    {
      isAvailable: true,
      link: routeTo.admin.cashDesk.tours()
    },
    {
      isAvailable: P([PermissionCode.ReadReservations]),
      link: routeTo.admin.cashDesk.reservations()
    },
    {
      isAvailable: true,
      link: routeTo.admin.cashDesk.shop()
    }
  ])
}
