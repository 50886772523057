import {useLazyQuery} from '@apollo/react-hooks'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LeadEventOrigin,
  LeadsQuery,
  LeadsQueryVariables,
  SellingChannel
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {CreateMessageDrawer} from '../../components/messageDrawer/CreateMessageDrawer'
import {MessageFormField} from '../../components/messageDrawer/types'
import {GET_LEADS} from '../graphql'

enum ToggleButtonChannel {
  All = 'all',
  Retail = 'retail',
  Ecommerce = 'ecommerce'
}

enum ToggleButtonOrderType {
  All = 'all',
  Sales = 'sales',
  Reservations = 'reservations'
}

const useTranslateToggleButtons = () => {
  const {t} = useTranslation()
  const translatedChannels: Record<ToggleButtonChannel, string> = {
    [ToggleButtonChannel.All]: t<string>('MessageDialogChannel->All'),
    [ToggleButtonChannel.Retail]: t<string>('MessageDialogChannel->Retail'),
    [ToggleButtonChannel.Ecommerce]: t<string>(
      'MessageDialogChannel->Ecommerce'
    )
  }
  const translatedOrderTypes: Record<ToggleButtonOrderType, string> = {
    [ToggleButtonOrderType.All]: t<string>('MessageDialogOrderType->All'),
    [ToggleButtonOrderType.Sales]: t<string>('MessageDialogOrderType->Sales'),
    [ToggleButtonOrderType.Reservations]: t<string>(
      'MessageDialogOrderType->Reservations'
    )
  }
  const translateChannel = (channel: ToggleButtonChannel) =>
    translatedChannels[channel]
  const translateOrderType = (orderType: ToggleButtonOrderType) =>
    translatedOrderTypes[orderType]
  return {translateChannel, translateOrderType}
}

const getFilterSellingChannel = (option: ToggleButtonChannel) => {
  switch (option) {
    case ToggleButtonChannel.Retail:
      return SellingChannel.Retail
    case ToggleButtonChannel.Ecommerce:
      return SellingChannel.ECommerce
    case ToggleButtonChannel.All:
    default:
      return undefined
  }
}

const getFilterLeadEventOrigin = (option: ToggleButtonOrderType) => {
  switch (option) {
    case ToggleButtonOrderType.Sales:
      return LeadEventOrigin.Sale
    case ToggleButtonOrderType.Reservations:
      return LeadEventOrigin.Reservation
    case ToggleButtonOrderType.All:
    default:
      return undefined
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 480
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  toggleGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  toggleButton: {
    '&.Mui-selected': {
      color: theme.palette.text.primary
    }
  },
  dialogActions: {
    padding: theme.spacing(1.5, 3)
  }
}))

interface ICreateMessageDialogProps {
  eventId: number
  isOpen: boolean
  onClose: () => void
}

export const CreateMessageDialog: React.FC<ICreateMessageDialogProps> = ({
  eventId,
  isOpen,
  onClose
}: ICreateMessageDialogProps) => {
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {translateChannel, translateOrderType} = useTranslateToggleButtons()
  const [leads, setLeads] = useState<LeadsQuery['leads']>([])
  const [channel, setChannel] = useState<ToggleButtonChannel>(
    ToggleButtonChannel.All
  )
  const [orderType, setOrderType] = useState<ToggleButtonOrderType>(
    ToggleButtonOrderType.All
  )
  const [skip, setSkip] = useState(!isOpen)
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  const [getLeads, {loading: isLeadsLoading}] = useLazyQuery<
    LeadsQuery,
    LeadsQueryVariables
  >(GET_LEADS, {
    variables: {
      filter: {
        eventFilter: {
          eventIds: [eventId],
          sellingChannel: getFilterSellingChannel(channel),
          leadEventOrigin: getFilterLeadEventOrigin(orderType)
        }
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      setLeads(data.leads.filter((lead) => lead.data.email)),
    onError: (error) =>
      defaultErrorHandler(error, t('Error while loading leads'))
  })
  const {
    state: isCreateMessageDrawerOpen,
    setTrue: openCreateMessageDrawer,
    setFalse: closeCreateMessageDrawer
  } = useBooleanState(false)
  const handleChannelChange = useCallback(
    (e, newChannel: ToggleButtonChannel) =>
      newChannel !== null && setChannel(newChannel),
    []
  )
  const handleOrderTypeChange = useCallback(
    (e, newOrderType: ToggleButtonOrderType) =>
      newOrderType !== null && setOrderType(newOrderType),
    []
  )
  const handleCreateButtonClick = useCallback(() => {
    openCreateMessageDrawer()
    onClose()
  }, [onClose, openCreateMessageDrawer])
  useEffect(() => {
    if (!skip) {
      getLeads()
    }
  }, [getLeads, skip])
  useEffect(
    () => (isLeadsLoading ? setShowBackdrop(true) : setShowBackdrop(false)),
    [isLeadsLoading, setShowBackdrop]
  )
  const classes = useStyles()
  return (
    <>
      <Dialog
        open={isOpen}
        scroll="paper"
        classes={{paper: classes.paper}}
        fullWidth
      >
        <DialogTitleWithCloseButton onCloseClick={onClose}>
          {t('Create new message')}
        </DialogTitleWithCloseButton>
        <DialogContent className={classes.dialogContent} dividers>
          <Typography variant="body1" color="textSecondary">
            {t(
              'Filter message recipients from event. Select option “all”, in both filters, if you would like to send message to all customers.'
            )}
          </Typography>
          <div className={classes.toggleGroup}>
            <Typography variant="body2" color="textSecondary">
              {t('Filter contacts by channel')}
            </Typography>
            <ToggleButtonGroup
              value={channel}
              exclusive
              onChange={handleChannelChange}
              size="small"
            >
              {Object.values(ToggleButtonChannel).map((ch) => (
                <ToggleButton
                  key={ch}
                  value={ch}
                  className={classes.toggleButton}
                >
                  {translateChannel(ch)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <div className={classes.toggleGroup}>
            <Typography variant="body2" color="textSecondary">
              {t('Filter contacts by order type')}
            </Typography>
            <ToggleButtonGroup
              value={orderType}
              exclusive
              onChange={handleOrderTypeChange}
              size="small"
            >
              {Object.values(ToggleButtonOrderType).map((ot) => (
                <ToggleButton
                  key={ot}
                  value={ot}
                  className={classes.toggleButton}
                >
                  {translateOrderType(ot)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <Typography variant="body1" color="textSecondary">
            {t('Message will be sent to {{count}} recipients.', {
              count: leads.length
            })}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            variant="contained"
            disabled={leads.length === 0}
            onClick={handleCreateButtonClick}
          >
            {t('Create')}
          </Button>
        </DialogActions>
      </Dialog>
      <CreateMessageDrawer
        isOpen={isCreateMessageDrawerOpen}
        onClose={closeCreateMessageDrawer}
        leads={leads.map((lead) => ({id: lead.id, contact: lead.data.email!}))}
        defaultValues={{
          [MessageFormField.LeadIds]: leads.map((lead) => lead.id)
        }}
      />
    </>
  )
}
