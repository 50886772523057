import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  AccessZonesFilter,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {AccessZonesList} from './AccessZonesList'
import {
  AccessZonesSearch,
  DEFAULT_ACCESS_ZONES_FILTER_INPUT
} from './AccessZonesSearch'
import {UpdateAccessZoneDrawer} from './UpdateAccessZoneDrawer'

export const AccessZonesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<AccessZonesFilter>(
    DEFAULT_ACCESS_ZONES_FILTER_INPUT
  )
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.accessZones.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Access zones')}
          search={<AccessZonesSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <AccessZonesList searchFilter={searchFilter} />
        {P([
          PermissionCode.UpdateAccessZone,
          PermissionCode.ReadAccessZone
        ]) && (
          <Route path={routeTo.admin.accessZones.edit(':accessZoneId')} exact>
            <UpdateAccessZoneDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
