import {SeatShape, ShapeVariant, ZoneType} from '@attendio/shared-components'
import {AuditoriumIcon, DrawTool, SelectionMode} from '../../types'

export enum EditorMode {
  DRAW = 'draw',
  PAN = 'pan',
  RESERVATION = 'reservation',
  SELECT = 'select',
  TRANSFORM = 'transform'
}

interface IIconConfig {
  type: typeof DrawTool.ICON
  icon: AuditoriumIcon
}

interface ISeatConfig {
  type: typeof DrawTool.SEAT
  shape: SeatShape
}

interface ISeatRowConfig {
  type: typeof DrawTool.SEAT_ROW
}

interface ISeatRowsConfig {
  type: typeof DrawTool.SEAT_ROWS
}

interface IShapeConfig {
  type: typeof DrawTool.SHAPE
  variant: ShapeVariant
}

interface ITextConfig {
  type: typeof DrawTool.TEXT
}

interface IZoneConfig {
  type: typeof DrawTool.ZONE
  zoneType: ZoneType
}

export type DrawConfig =
  | IIconConfig
  | ISeatConfig
  | ISeatRowConfig
  | ISeatRowsConfig
  | IShapeConfig
  | ITextConfig
  | IZoneConfig

export type PanConfig = {}

export type ReservationConfig = {
  occupied: Array<string>
}

export type SelectConfig = {
  mode: SelectionMode
}

export type TransformConfig = {
  resizing: boolean
  rotation: boolean
}

export interface IEditorModeState {
  selectedMode: EditorMode
  modeConfigs: {
    [EditorMode.DRAW]: DrawConfig
    [EditorMode.PAN]: PanConfig
    [EditorMode.RESERVATION]: ReservationConfig
    [EditorMode.SELECT]: SelectConfig
    [EditorMode.TRANSFORM]: TransformConfig
  }
}

const initialState: IEditorModeState = {
  selectedMode: EditorMode.SELECT,
  modeConfigs: {
    [EditorMode.DRAW]: {
      type: DrawTool.SEAT,
      shape: SeatShape.Classic
    },
    [EditorMode.PAN]: {},
    [EditorMode.RESERVATION]: {
      occupied: []
    },
    [EditorMode.SELECT]: {
      mode: SelectionMode.CROSSING
    },
    [EditorMode.TRANSFORM]: {
      resizing: false,
      rotation: false
    }
  }
}

export const SET_MODE = 'SET_MODE'

interface ISetModeAction {
  type: typeof SET_MODE
  payload: {
    mode: EditorMode
    config: DrawConfig | PanConfig | ReservationConfig | SelectConfig
  }
}

type EditorModeAction = ISetModeAction

export const editorModeReducer = (
  state = initialState,
  action: EditorModeAction
): IEditorModeState => {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        selectedMode: action.payload.mode,
        modeConfigs: {
          ...state.modeConfigs,
          [action.payload.mode]: action.payload.config
        }
      }
    default:
      return state
  }
}
