import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ProductState,
  WarehouseProductsFilterInput
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useUnitFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {OptionsChipWithDelete} from '../../../common/OptionsChipWithDelete'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {useLocale} from '../../../context/locale'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {
  NegativeStockCellRenderer,
  StockCellRenderer
} from '../components/CommonCellRenderers'
import {DivisionChip} from '../components/DivisionChip'
import {ReloadButton} from '../components/ReloadButton'
import {WarehousesChip} from '../components/WarehousesChip'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {useWarehouseStocksProducts} from './graphql'
import {StockLevelOption} from './types'
import {transformStockLevelToFilter} from './utils'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.products.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IWarehouseStocksListProps {
  searchFilter: WarehouseProductsFilterInput
}

export const WarehouseStocksList: React.FC<IWarehouseStocksListProps> = ({
  searchFilter
}: IWarehouseStocksListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {localeCode} = useLocale()
  const getFilterDateRange = useGetFilterDateRange()
  const [productState, setProductState] =
    useState<ProductState | undefined>(undefined)
  const [divisionId, setDivisionId] = useState<number | null>(null)
  const [warehouseId, setWarehouseId] = useState<number | undefined>(undefined)
  const [stockLevel, setStockLevel] =
    useState<StockLevelOption | undefined>(undefined)
  const [selectedDate, setSelectedDate] =
    useState<IDataPickerData | undefined>(undefined)
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useWarehouseStocksProducts({
    paginationInput,
    filter: {
      ...searchFilter,
      productStates: productState ? [productState] : undefined,
      divisionIds: divisionId ? [divisionId] : undefined,
      warehouseIds: warehouseId ? [warehouseId] : undefined,
      ...transformStockLevelToFilter(stockLevel),
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'updatedAtFrom',
        filterNameTo: 'updatedAtTo'
      })
    }
  })
  const unitFormatter = useUnitFormatter()
  const dateTimeFormatter = useDateTimeFormatter()
  const handleProductStateChange = useCallback(
    (newState: ProductState | undefined) => {
      setProductState(newState)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDivisionIdChange = useCallback(
    (divisionId: number | null) => {
      setDivisionId(divisionId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleWarehouseIdChange = useCallback(
    (warehouseId: number | undefined) => {
      setWarehouseId(warehouseId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleStockLevelChange = useCallback(
    (stockLevel: StockLevelOption | undefined) => {
      setStockLevel(stockLevel)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Code'),
        field: 'internalCode',
        valueGetter: (params) => params.row.product.internalCode,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Name'),
        field: 'name',
        valueGetter: (params) => params.row.product.name,
        minWidth: 250,
        sortable: false
      },
      {
        headerName: t('Stock'),
        field: 'stock',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StockCellRenderer warehouseProduct={params.row} />
        },
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Unit'),
        field: 'unit',
        valueGetter: (params) => params.row.product.unit,
        valueFormatter: unitFormatter,
        minWidth: 50,
        sortable: false
      },
      {
        headerName: t('Can be negative'),
        field: 'isNegativeStockEnabled',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <NegativeStockCellRenderer isNegativeStockEnabled={params.value} />
          )
        },
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Min stock'),
        field: 'minStockLevel',
        valueFormatter: ({value}) =>
          typeof value === 'number'
            ? value.toLocaleString(localeCode, {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
              })
            : '',
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Optimal stock'),
        field: 'optimalStockLevel',
        valueFormatter: ({value}) =>
          typeof value === 'number'
            ? value.toLocaleString(localeCode, {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6
              })
            : '',
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Warehouse'),
        field: 'warehouse',
        valueGetter: (params) => params.row.warehouse.name,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Division'),
        field: 'division',
        valueGetter: (params) => params.row.division.name,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'detail',
        valueGetter: (params) => params.row.product.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [dateTimeFormatter, localeCode, t, unitFormatter]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading warehouse products')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ChipWithOptions<ProductState>
            key="state-chip"
            selectedItem={productState}
            setSelectedItem={handleProductStateChange}
            options={[
              {
                option: ProductState.Active,
                label: t('Active products')
              },
              {
                option: ProductState.Inactive,
                label: t('Inactive products')
              },
              {
                option: ProductState.Disabled,
                label: t('Disabled products')
              }
            ]}
            allText={t('All products')}
            size="small"
          />,
          <DivisionChip
            key="division-chip"
            onChange={handleDivisionIdChange}
          />,
          <WarehousesChip
            key="warehouse-chip"
            onChange={handleWarehouseIdChange}
          />,
          <OptionsChipWithDelete<StockLevelOption>
            key="type-chip"
            tooltip={t('Select stock level')}
            icon={<Inventory2OutlinedIcon />}
            options={[
              {
                id: StockLevelOption.Positive,
                label: t('Positive stock level')
              },
              {
                id: StockLevelOption.BelowOptimal,
                label: t('Below optimal stock level')
              },
              {
                id: StockLevelOption.BelowMinimal,
                label: t('Below minimal stock level')
              },
              {
                id: StockLevelOption.OutOfStock,
                label: t('Out of stock')
              },
              {
                id: StockLevelOption.Negative,
                label: t('Negative stock level')
              }
            ]}
            selectedOption={stockLevel}
            onChange={handleStockLevelChange}
          />
        ]}
        rightActions={[
          <DatePickerButton
            key="last-update-date-picker"
            tooltip={t('Select last update')}
            label={t('Last update')}
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            groups={[TabGroup.Now, TabGroup.Past]}
            excludedValues={[TabNow.FromNow, TabNow.FromToday]}
            minDateFrom={
              selectedDate?.dateRange?.dateTo
                ? dayjs(selectedDate?.dateRange?.dateTo)
                    .subtract(1, 'year')
                    .startOf('d')
                : dayjs().subtract(1, 'year').startOf('d')
            }
            maxDateFrom={selectedDate?.dateRange?.dateTo || dayjs().endOf('d')}
            maxDateTo={dayjs().endOf('d')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout
        sx={{
          height: '100%',
          width: '100%',
          p: 3
        }}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.warehouseProducts.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={{noRowsLabel: t('No warehouse products to show')}}
          initialState={{
            pinnedColumns: {left: ['internalCode', 'name'], right: ['detail']}
          }}
          columnVisibilityModel={{
            detail: P([PermissionCode.ReadProduct])
          }}
          {...getDataGridPaginationProps(data?.warehouseProducts.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}
