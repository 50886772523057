import {useLazyQuery} from '@apollo/react-hooks'
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  GetVoucherByCodeQuery,
  GetVoucherByCodeQueryVariables,
  VoucherPropertiesFragment
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {SaveButton} from '../../../../common/Buttons'
import {IntentStructureAccordion} from '../../../../common/IntentStructureAccordion'
import {UncontrolledFormTextInputWithCancelButton} from '../../../../form/UncontrolledFormTextInputWithCancelButton'
import {GET_VOUCHER_BY_CODE} from './graphql'
import {IInHouseVoucherRowStructure} from './types'
import {VoucherBalanceDialog} from './VoucherBalanceDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  uncontrolledInput: {
    marginLeft: -theme.spacing(2)
  },
  submitButtonBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  }
}))

interface IInHouseVoucherRowStructureProps {
  structure: IInHouseVoucherRowStructure
  isExpanded: boolean
  onStructureExpand: () => void
  cartPrice: number
}

enum CheckField {
  Code = 'code'
}

type ICheckForm = {
  [CheckField.Code]: string
}
const CHECK_FORM_ID = 'CHECK_FORM_ID'

export const InHouseVoucherRowStructure: React.FC<IInHouseVoucherRowStructureProps> =
  ({
    onStructureExpand,
    isExpanded,
    cartPrice
  }: IInHouseVoucherRowStructureProps) => {
    const {t} = useTranslation()
    const classes = useStyles()
    const {
      register,
      setValue,
      errors,
      watch,
      triggerValidation,
      handleSubmit,
      setError,
      reset
    } = useForm<ICheckForm>()
    const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
    const [voucher, setVoucher] =
      useState<VoucherPropertiesFragment | null>(null)
    const handleClose = useCallback(() => {
      setVoucher(null)
    }, [])
    const [getVoucherByCode] = useLazyQuery<
      GetVoucherByCodeQuery,
      GetVoucherByCodeQueryVariables
    >(GET_VOUCHER_BY_CODE, {
      fetchPolicy: 'network-only',
      onCompleted: ({voucherByCodeOnRetailChannel}) => {
        setVoucher(voucherByCodeOnRetailChannel)
      },
      onError: (error: ApolloError) => {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.VoucherNotFound
          )
        ) {
          setError(CheckField.Code, t("Code doesn't exist, or is misspelled."))
        } else {
          defaultErrorHandler(
            error,
            t('Error occurred during checking the voucher')
          )
        }
      }
    })
    const handleCheck = useCallback(
      async (form: ICheckForm) => {
        try {
          setShowBackdrop(true)
          await getVoucherByCode({
            variables: {
              code: form[CheckField.Code]
            }
          })
        } finally {
          setShowBackdrop(false)
        }
      },
      [getVoucherByCode, setShowBackdrop]
    )
    return (
      <IntentStructureAccordion
        isExpanded={isExpanded}
        onSummaryClick={onStructureExpand}
        name={t('Check voucher or gift cart')}
        Icon={CardGiftcardOutlinedIcon}
      >
        <form onSubmit={handleSubmit(handleCheck)} id={CHECK_FORM_ID}>
          <UncontrolledFormTextInputWithCancelButton<ICheckForm>
            margin="dense"
            fullWidth
            className={classes.uncontrolledInput}
            label={t('Voucher code')}
            name={CheckField.Code}
            register={register}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            required
            validationOptions={{
              required: true
            }}
          />
          <div className={classes.submitButtonBox}>
            <SaveButton type="submit" form={CHECK_FORM_ID}>
              {t('Check')}
            </SaveButton>
          </div>
        </form>
        {voucher && (
          <VoucherBalanceDialog
            voucher={voucher}
            onClose={handleClose}
            onCheckFormReset={reset}
            maxCredit={
              cartPrice < voucher.balance ? cartPrice : voucher.balance
            }
          />
        )}
      </IntentStructureAccordion>
    )
  }
