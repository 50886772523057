import {ShowVideoSource} from '../__generated__/schema'

export enum VideoSource {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  NotImplemented = 'notImplemented'
}

export enum VideoSourceError {
  MissingVideoIdentifier = 'missingVideoIdentifier',
  IncorrectVideoIdentifier = 'incorrectVideoIdentifier',
  UnsupportedSource = 'unsupportedSource'
}

export const youtubeIdLength = 11
export const vimeoIdLength = 9
const youtubeRegEx =
  /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
const vimeoRegEx =
  /^.*(?:vimeo\.com\/|player\.vimeo\.com\/video\/|vimeo\.com\/m\/)(\d+)/

const getYoutubeId = (url: string) => {
  const match = url.match(youtubeRegEx)
  if (match) {
    if (match[2] === null) {
      throw new Error(VideoSourceError.MissingVideoIdentifier)
    }
    if (match[2].length === youtubeIdLength) {
      return match[2]
    }
    throw new Error(VideoSourceError.IncorrectVideoIdentifier)
  }
  if (url.includes('youtube') || url.includes('youtu.be')) {
    throw new Error(VideoSourceError.MissingVideoIdentifier)
  }
  throw new Error(VideoSourceError.UnsupportedSource)
}

const getVimeoId = (url: string) => {
  const match = url.match(vimeoRegEx)
  if (match) {
    if (match[1] === null) {
      throw new Error(VideoSourceError.MissingVideoIdentifier)
    }
    if (match[1].length === vimeoIdLength) {
      return match[1]
    }
    throw new Error(VideoSourceError.IncorrectVideoIdentifier)
  }
  if (url.includes('vimeo')) {
    throw new Error(VideoSourceError.MissingVideoIdentifier)
  }
  throw new Error(VideoSourceError.UnsupportedSource)
}

const getVideoProvider = (url: string) =>
  url.includes('youtube') || url.includes('youtu.be')
    ? ShowVideoSource.Youtube
    : url.includes('vimeo')
    ? ShowVideoSource.Vimeo
    : undefined

export const getVideoSource = (url: string) => {
  const videoProvider = getVideoProvider(url)
  switch (videoProvider) {
    case ShowVideoSource.Youtube:
      try {
        const youtubeId = getYoutubeId(url)
        return {source: ShowVideoSource.Youtube, identifier: youtubeId}
      } catch (error) {
        throw error
      }
    case ShowVideoSource.Vimeo:
      try {
        const vimeoId = getVimeoId(url)
        return {source: ShowVideoSource.Vimeo, identifier: vimeoId}
      } catch (error) {
        throw error
      }
    default:
      throw new Error(VideoSourceError.UnsupportedSource)
  }
}
