import {Box, IconButton, SvgIconProps} from '@mui/material'
import React from 'react'
import {PRIMARY_100_COLOR} from '../../theme'
import {Tooltip} from './Tooltip'

interface INavigationRailItemProps {
  selected?: boolean
  onIconClick: () => void
  Icon: React.FC<SvgIconProps>
  tooltip?: string
}

const NavigationRailItem: React.FC<INavigationRailItemProps> = ({
  selected,
  Icon,
  onIconClick,
  tooltip
}: INavigationRailItemProps) => (
  <Tooltip title={tooltip}>
    <IconButton
      onClick={onIconClick}
      color={selected ? 'primary' : undefined}
      sx={{
        backgroundColor: selected ? PRIMARY_100_COLOR : 'transparent',
        '&:hover': {
          backgroundColor: selected ? PRIMARY_100_COLOR : 'grey.300'
        }
      }}
    >
      <Icon />
    </IconButton>
  </Tooltip>
)

interface IIconNavigationRailProps {
  items: {
    id: string
    tooltip?: string
    Icon: React.FC<SvgIconProps>
    onClick: () => void
    selected?: boolean
  }[]
  bottomIcon?: React.ReactNode
}

export const IconNavigationRail: React.FC<IIconNavigationRailProps> = ({
  items,
  bottomIcon
}: IIconNavigationRailProps) => (
  <Box
    sx={{
      bottom: 0,
      height: 'auto',
      width: 72,
      alignItems: 'center',
      borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
      backgroundColor: 'background.paper'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 1,
        gap: 1,
        height: '100%',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1
        }}
      >
        {items.map(({id, Icon, onClick, selected, tooltip}) => (
          <NavigationRailItem
            key={id}
            onIconClick={onClick}
            Icon={Icon}
            selected={selected}
            tooltip={tooltip}
          />
        ))}
      </Box>
      {bottomIcon}
    </Box>
  </Box>
)
