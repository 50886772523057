import {Chip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

interface IEntityStateProps {
  label: string
  color: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  entityStateGrid: {
    display: 'inline-grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridAutoRows: '1fr',
    gap: theme.spacing(1)
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 8
  },
  chip: {
    height: 24,
    width: 'min-content',
    display: 'flex',
    alignItems: 'center'
  },
  chipLabel: {
    lineHeight: '100%'
  },
  chipDot: {
    width: 8,
    height: 8,
    borderRadius: 8
  }
}))

export const EntityState = ({label, color}: IEntityStateProps) => {
  const classes = useStyles()

  return (
    <div className={classes.entityStateGrid}>
      <div className={classes.dot} style={{background: color}} />
      <div>{label}</div>
    </div>
  )
}

interface IEntityStateChipProps {
  label: string
  isDotHidden?: boolean
  colorConf: {
    color: string
    background: string
  }
  size?: 'small' | 'medium'
}

export const EntityStateChip = ({
  label,
  colorConf,
  isDotHidden,
  size = 'medium'
}: IEntityStateChipProps) => {
  const classes = useStyles()

  return (
    <Chip
      className={classes.chip}
      icon={
        !isDotHidden ? (
          <div
            className={classes.chipDot}
            style={{background: colorConf.color}}
          />
        ) : undefined
      }
      label={label}
      style={colorConf}
      classes={{
        label: classes.chipLabel
      }}
      size={size}
    />
  )
}
