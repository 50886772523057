import DeleteIcon from '@mui/icons-material/Delete'
import {Button, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useRoleParams} from '../../../../utils/pathname'
import {routeTo} from '../../../../utils/routes'
import {
  ButtonWithConfirmationDialog,
  RenderOnData,
  SingleSideNavigationList
} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {SplitButton} from '../../../visual'
import {SecondaryHeader} from '../Header'
import {CenteredLayout} from '../Layout'
import {useDeleteRole, useGetRole, useUpdateRole} from './graphql'
import {IRoleForm, RoleField, RoleForm} from './RoleForm'
import {FormType, useRoleFormAnchors} from './useRoleFormAnchors'

const UPDATE_ROLE_FORM_ID = 'updateRole'

export const EditRole: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {roleId} = useRoleParams()
  const {data, error, loading} = useGetRole(roleId)
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const updateRole = useUpdateRole()
  const deleteRole = useDeleteRole()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const history = useHistory()
  const [title, setTitle] = useState<string>(t<string>('Edit role'))
  const roleFormAnchors = useRoleFormAnchors(FormType.Edit)
  useEffect(() => {
    if (data) {
      setTitle(getUserLocaleTranslation(data.role.names))
    }
  }, [data, data?.role.names, getUserLocaleTranslation])
  const handleDeleteButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteRole(roleId)
      history.push(routeTo.admin.roles.index())
      addInfoNotification(t('Role deleted'))
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting role'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteRole,
    history,
    roleId,
    setShowBackdrop,
    t
  ])
  const handleSetAsEnabledButtonClick = useCallback(async () => {
    if (data) {
      try {
        setShowBackdrop(true)
        await updateRole(
          roleId,
          {
            names: data.role.names,
            descriptions: data.role.descriptions,
            permissionCodes: data.role.permissionCodes,
            clientId: data.role.clientId
          },
          false
        )
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating role'))
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [data, defaultErrorHandler, roleId, setShowBackdrop, t, updateRole])
  const handleDeprecatedButtonClick = useCallback(async () => {
    if (data) {
      try {
        setShowBackdrop(true)
        await updateRole(
          roleId,
          {
            names: data.role.names,
            descriptions: data.role.descriptions,
            permissionCodes: data.role.permissionCodes,
            clientId: data.role.clientId
          },
          true
        )
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating role'))
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [data, defaultErrorHandler, roleId, setShowBackdrop, t, updateRole])
  const handleOnSubmit = useCallback(
    async (formData: IRoleForm) => {
      try {
        setShowBackdrop(true)
        await updateRole(roleId, formData)
        history.push(routeTo.admin.roles.index())
        addInfoNotification(t('Role updated'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating role'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      history,
      roleId,
      setShowBackdrop,
      t,
      updateRole
    ]
  )
  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={title} />}
      footer={
        <Footer>
          {P([PermissionCode.DeleteRole]) && (
            <ButtonWithConfirmationDialog
              onConfirmButtonClick={handleDeleteButtonClick}
              dialogProps={{
                confirmButtonLabel: t('Delete'),
                title: t('Delete role?'),
                contentText: t(
                  'Are you sure you want to delete this role? This can’t be undone.'
                )
              }}
              buttonProps={{
                startIcon: <DeleteIcon />,
                disabled: !data?.role.isDeprecated,
                children: t('Delete'),
                color: 'primary'
              }}
            />
          )}
          <SplitButton
            Options={[
              data?.role.isDeprecated ? (
                <MenuItem
                  key="set-as-enabled"
                  component="button"
                  onClick={handleSetAsEnabledButtonClick}
                >
                  {t('Set as enabled')}
                </MenuItem>
              ) : (
                <MenuItem
                  key="set-as-deprecated"
                  component="button"
                  onClick={handleDeprecatedButtonClick}
                >
                  {t('Set as deprecated')}
                </MenuItem>
              )
            ]}
          >
            <Button
              variant="contained"
              color="primary"
              form={UPDATE_ROLE_FORM_ID}
              type="submit"
            >
              {t('Save')}
            </Button>
          </SplitButton>
        </Footer>
      }
    >
      <RenderOnData
        loading={loading}
        error={error}
        data={data}
        dataCondition={(data) => Boolean(data?.role)}
        errorMessage={t<string>('Could not load role')}
      >
        {({role}) => (
          <CenteredLayout>
            <SingleSideNavigationList items={roleFormAnchors} />
            <RoleForm
              formId={UPDATE_ROLE_FORM_ID}
              formType={FormType.Edit}
              onSubmit={handleOnSubmit}
              defaultValues={{
                [RoleField.Type]: role.type,
                [RoleField.Names]: role.names,
                [RoleField.Descriptions]: role.descriptions,
                [RoleField.PermissionCodes]: role.permissionCodes
              }}
              role={role}
            />
          </CenteredLayout>
        )}
      </RenderOnData>
    </PageWithHeaderAndFooterTemplate>
  )
}
