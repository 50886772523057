import React from 'react'

import {ShowDistribution} from '../../../../__generated__/schema'
import {CustomChip} from '../../../common/CustomChip'
import {
  DividedElements,
  getUniqueAndTrulyItems
} from '../../../common/DividedElements'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../hooks/translateDistributions'

interface IShowDistributionsProps {
  distributions: Array<ShowDistribution>
  className?: string
}

export const ShowDistributions: React.FC<IShowDistributionsProps> = ({
  distributions,
  className
}: IShowDistributionsProps) => {
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  return (
    <DividedElements
      className={className}
      arrayOfElementArrays={[
        getUniqueAndTrulyItems(distributions, 'formatCode').map(
          (distribution, index) => (
            <CustomChip
              key={`format-${index}`}
              label={`${translateShowFormatAbbreviation(
                distribution.formatCode!
              )}`}
              shape="rectangle"
            />
          )
        ),
        getUniqueAndTrulyItems(distributions, 'version').map(
          (distribution, index) => (
            <CustomChip
              key={`version-${index}`}
              label={translateShowVersionAbbreviation(
                distribution.versionCode!
              )}
              shape="round"
            />
          )
        ),
        getUniqueAndTrulyItems(distributions, 'soundMix').map(
          (distribution, index) => (
            <CustomChip
              key={`soundMix-${index}`}
              label={translateShowSoundMixAbbreviation(
                distribution.soundMixCode!
              )}
              shape="round"
            />
          )
        )
      ]}
    />
  )
}
