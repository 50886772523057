import gql from 'graphql-tag'
import {PAGINATION_FRAGMENT} from '../../../../graphql'
import {TRANSLATED_LOCALES_FRAGMENT} from '../../../graphql'

export const NARROW_SHOW_FRAGMENT = gql`
  fragment NarrowShowFragment on ClientShow {
    id
    originalTitle
    translations {
      localeCode
      title
    }
  }
`

export const GET_NARROW_SHOWS = gql`
  query NarrowShows(
    $filter: ShowsFilterInput
    $paginationInput: PaginationInput!
  ) {
    clientShows(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowShowFragment
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${NARROW_SHOW_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const NARROW_EVENT_FRAGMENT = gql`
  fragment NarrowEvent on Event {
    id
    names {
      ...TranslatedLocales
    }
    startsAt
    formatCode
    soundMixCode
    versionCode
    venue {
      id
      name
    }
    auditorium {
      id
      name
    }
    marketingLabel {
      id
      name
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const GET_NARROW_EVENTS = gql`
  query NarrowEvents(
    $filter: EventsFilterInput!
    $paginationInput: PaginationInput!
  ) {
    paginatedEvents(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowEvent
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${NARROW_EVENT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const NARROW_DIVISION_ITEM_FRAGMENT = gql`
  fragment NarrowDivisionItem on Division {
    id
    name
  }
`

export const GET_NARROW_DIVISION_ITEMS = gql`
  query NarrowDivisionItems($filter: DivisionFilter!) {
    divisions(filter: $filter) {
      ...NarrowDivisionItem
    }
  }
  ${NARROW_DIVISION_ITEM_FRAGMENT}
`

export const NARROW_VENUE_FRAGMENT = gql`
  fragment NarrowVenue on Venue {
    id
    name
    secondaryName
  }
`

export const GET_NARROW_VENUES = gql`
  query NarrowVenues($filter: VenuesFilterInput!) {
    venues(filter: $filter) {
      ...NarrowVenue
    }
  }
  ${NARROW_VENUE_FRAGMENT}
`

export const NARROW_EVENT_CATEGORY_FRAGMENT = gql`
  fragment NarrowEventCategory on EventCategory {
    id
    name
  }
`

export const GET_NARROW_EVENT_CATEGORIES = gql`
  query NarrowEventCategories($filter: EventCategoriesFilterInput) {
    eventCategories(filter: $filter) {
      ...NarrowEventCategory
    }
  }
  ${NARROW_EVENT_CATEGORY_FRAGMENT}
`

export const NARROW_MARKETING_LABEL_FRAGMENT = gql`
  fragment NarrowMarketingLabel on MarketingLabel {
    id
    name
  }
`

export const GET_NARROW_MARKETING_LABELS = gql`
  query NarrowMarketingLabels($filter: MarketingLabelsFilterInput) {
    marketingLabels(filter: $filter) {
      ...NarrowMarketingLabel
    }
  }
  ${NARROW_MARKETING_LABEL_FRAGMENT}
`

export const NARROW_COST_CENTER_FRAGMENT = gql`
  fragment NarrowCostCenter on CostCenter {
    id
    name
  }
`

export const GET_NARROW_COST_CENTERS = gql`
  query NarrowCostCenters($filter: CostCentersFilterInput) {
    costCenters(filter: $filter) {
      ...NarrowCostCenter
    }
  }
  ${NARROW_COST_CENTER_FRAGMENT}
`
