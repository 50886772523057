import DesktopAccessDisabledOutlinedIcon from '@mui/icons-material/DesktopAccessDisabledOutlined'
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {Box, Button, Paper, Typography} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  useOpenCustomerDisplay,
  usePingCustomerDisplayPages,
  useSubscribeToCustomerDisplayChannel
} from '../../../../../customerDisplayBroadcastChannel'
import {
  CustomerDisplayMessage,
  CustomerDisplayMessageType
} from '../../../../../customerDisplayBroadcastChannel/types'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {
  ICustomerDisplayScreen,
  ICustomerDisplaySettings
} from '../../../../../types'
import {
  useFindScreen,
  useGetWindowManagementPermissionState,
  useListenPermissionStateChange
} from '../../../../../windowManagement'
import {COLOR_CONF} from '../../../../constants'

const useGetTextAndIcon = (settings: ICustomerDisplaySettings) => {
  const {t} = useTranslation()
  const {enabled, windowManagementEnabled, screen} = settings
  const openCustomerDisplay = useOpenCustomerDisplay()
  const findScreen = useFindScreen()
  const {permissionState, recheckPermissionState} =
    useGetWindowManagementPermissionState()
  const {addListener, removeListener} = useListenPermissionStateChange(
    recheckPermissionState
  )
  const {pingPages, detectedPages} = usePingCustomerDisplayPages()
  useEffect(() => {
    pingPages()
  }, [pingPages])

  const pingPagesRef = useRef(0)

  const pingPagesOnTimeout = useCallback(
    (timeout: number) => {
      if (pingPagesRef.current) {
        window.clearTimeout(pingPagesRef.current)
      }
      pingPagesRef.current = window.setTimeout(() => {
        pingPages()
      }, timeout)
    },
    [pingPages]
  )

  const handleOpenCustomerDisplay = useCallback(() => {
    const externalScreen = screen && findScreen(screen)
    openCustomerDisplay(externalScreen || undefined)
  }, [findScreen, openCustomerDisplay, screen])
  const isScreenConnected = useCallback(
    (screen: ICustomerDisplayScreen) => Boolean(findScreen(screen)),
    [findScreen]
  )
  const shouldOpenCustomerDisplay = screen && permissionState === 'granted'
  useEffect(() => {
    addListener()
    return () => removeListener()
  }, [addListener, removeListener])

  const handleCustomerDisplayPageClosedOrOpened = useCallback(
    function (
      this: BroadcastChannel,
      {data: message}: MessageEvent<CustomerDisplayMessage>
    ) {
      if (
        [
          CustomerDisplayMessageType.CustomerDisplayPageClosed,
          CustomerDisplayMessageType.CustomerDisplayPageOpened
        ].includes(message.type)
      ) {
        pingPagesOnTimeout(300)
      }
    },
    [pingPagesOnTimeout]
  )

  useEffect(() => {
    return () => {
      if (pingPagesRef.current) {
        window.clearTimeout(pingPagesRef.current)
      }
    }
  }, [])
  useSubscribeToCustomerDisplayChannel(handleCustomerDisplayPageClosedOrOpened)
  useEffect(() => {
    if (shouldOpenCustomerDisplay && detectedPages.length === 0 && screen) {
      const externalScreen = findScreen(screen)
      if (externalScreen) {
        openCustomerDisplay(externalScreen)
      }
    }
  }, [
    detectedPages.length,
    findScreen,
    openCustomerDisplay,
    permissionState,
    screen,
    shouldOpenCustomerDisplay
  ])
  if (
    enabled &&
    windowManagementEnabled &&
    screen &&
    permissionState !== 'granted'
  ) {
    return {
      icon: <ReportProblemOutlinedIcon sx={{color: COLOR_CONF.RED.color}} />,
      outline: `1px solid ${COLOR_CONF.RED.color}`,
      backgroundColor: COLOR_CONF.RED.background,
      title: t(
        'Browser permissions are missing or disabled to auto-open customer display'
      ),
      description: t(
        'We are unable to open the customer display automatically. Please ensure that window management is enabled in your browser settings for this site. If the issue persists, consider restarting your browser or seeking assistance from your IT support team. Meanwhile, you can manually open the customer display.'
      ),
      action: (
        <Button
          variant="text"
          color="primary"
          onClick={handleOpenCustomerDisplay}
        >
          {t('Open')}
        </Button>
      )
    }
  }
  if (
    enabled &&
    windowManagementEnabled &&
    screen &&
    permissionState === 'granted' &&
    !isScreenConnected(screen)
  ) {
    return {
      icon: (
        <DesktopAccessDisabledOutlinedIcon
          sx={{color: COLOR_CONF.YELLOW.color}}
        />
      ),
      outline: `1px solid ${COLOR_CONF.YELLOW.color}`,
      backgroundColor: COLOR_CONF.YELLOW.background,
      title: t(
        'Default monitor {{name}} for customer display is disconnected or off',
        {name: screen.name}
      ),
      description: t(
        'Ensure that all cables connecting the monitor are secure and that the monitor is powered on. If everything appears to be in order, consult your IT support team for assistance. In the meantime, you can manually open the customer display.'
      ),
      action: (
        <Button
          variant="text"
          color="primary"
          onClick={() => openCustomerDisplay()}
        >
          {t('Open')}
        </Button>
      )
    }
  }
  if (enabled && detectedPages.length > 0) {
    return {
      icon: <DesktopWindowsOutlinedIcon sx={{color: COLOR_CONF.GREEN.color}} />,
      outline: `1px solid ${COLOR_CONF.GREEN.color}`,
      backgroundColor: COLOR_CONF.GREEN.background,
      title: t('Customer display'),
      description: t('The customer display is active and operating correctly.'),
      action: undefined
    }
  }
  if (enabled && detectedPages.length === 0) {
    return {
      icon: (
        <DesktopWindowsOutlinedIcon sx={{color: COLOR_CONF.YELLOW.color}} />
      ),
      outline: `1px solid ${COLOR_CONF.YELLOW.color}`,
      backgroundColor: COLOR_CONF.YELLOW.background,
      title: t('Customer display'),
      description: t(
        'Please open the customer display and position it on a monitor facing the customer. Ensure that the display window is maximized for optimal visibility.'
      ),
      action: (
        <Button
          variant="text"
          color="primary"
          onClick={handleOpenCustomerDisplay}
        >
          {t('Open')}
        </Button>
      )
    }
  }
  return {
    icon: <DesktopWindowsOutlinedIcon sx={{color: COLOR_CONF.GRAY.color}} />,
    outline: `1px solid ${COLOR_CONF.GRAY.color}`,
    backgroundColor: 'inherit',
    title: t('Customer display'),
    description: t(
      'Display cart details in real-time to customers and enhance the overall shopping experience. This setting enables immediate updates of item details, prices, and totals on an external display that is visible to customers. A second display facing the customer is expected. Set it up in device settings or contact your IT support team for assistance.'
    ),
    action: undefined
  }
}

export const CustomerDisplaySection: React.FC = () => {
  const [customerDisplay] = useLocalStorageState<ICustomerDisplaySettings>(
    LocalStorageKey.CustomerDisplaySettings,
    {
      enabled: false,
      windowManagementEnabled: false,
      screen: null
    }
  )
  const {icon, title, description, action, ...style} =
    useGetTextAndIcon(customerDisplay)
  return (
    <Paper
      variant="outlined"
      sx={{
        px: 3,
        py: 1,
        display: 'grid',
        gridTemplateColumns: action ? 'auto 1fr auto' : 'auto 1fr',
        gap: 3,
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: 48,
          height: 48,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...style
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Box>
      {action}
    </Paper>
  )
}
