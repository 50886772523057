import {Box, Collapse, Switch, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContextValues, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DivisionState,
  DivisionsWithSettingsQuery,
  GetLightweightClientShowsQuery
} from '../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {
  useIsPositiveInteger,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {TourEntranceFormPart} from './TourEntranceFormPart'
import {TourPurchaseFormPart} from './TourPurchaseFormPart'
import {TourReservationFormPart} from './TourReservationFormPart'
import {ITourDivisionSettingsForm, ITourForm, TourFormField} from './types'
import {transformDivisionSettingsToForm} from './utils'

const useGetShowTitle = () => {
  const {t} = useTranslation()
  const clientLocaleCode = useClientLocaleCode()
  return useCallback(
    (show?: GetLightweightClientShowsQuery['clientShows']['items'][number]) =>
      show?.translations.find(
        (translation) => translation.localeCode === clientLocaleCode
      )?.title ||
      show?.originalTitle ||
      show?.translations[0]?.title ||
      t<string>('Title not available'),
    [clientLocaleCode, t]
  )
}

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface ITourFormProps {
  formId: string
  onSubmit: (data: ITourForm) => void
  clientShows: GetLightweightClientShowsQuery['clientShows']['items']
  divisions: DivisionsWithSettingsQuery['divisions']
  defaultValues?: Partial<ITourForm>
}

export const TourForm: React.FC<ITourFormProps> = ({
  formId,
  onSubmit,
  clientShows,
  divisions,
  defaultValues
}: ITourFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    control,
    triggerValidation,
    unregister,
    clearError,
    getValues,
    setError,
    reset,
    handleSubmit
  } = useForm<ITourForm>({defaultValues, reValidateMode: 'onBlur'})
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const [isSectionExpanded, setIsSectionExpanded] = useState<boolean>(false)
  const getShowTitle = useGetShowTitle()
  const handleSwitchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSectionExpanded(e.target.checked)
      if (!e.target.checked) {
        setValue(TourFormField.Name, '')
      }
    },
    [setValue]
  )
  const showIdValue = watch(TourFormField.ShowId)
  useEffect(() => {
    const show = clientShows.find((show) => String(show.id) === showIdValue)
    if (show) {
      setValue(TourFormField.Name, getShowTitle(show), false)
      if (show.duration) {
        setValue(TourFormField.Duration, String(show.duration), false)
      }
    }
  }, [clientShows, getShowTitle, setValue, showIdValue])
  const isPositiveInteger = useIsPositiveInteger()
  const selectedDivisionId = watch(TourFormField.DivisionId)
  useEffect(() => {
    if (selectedDivisionId) {
      const division = divisions.find(
        ({id}) => id === parseInt(selectedDivisionId, 10)
      )
      if (division) {
        reset({
          ...getValues(),
          ...transformDivisionSettingsToForm(division)
        })
      }
    }
  }, [divisions, selectedDivisionId, getValues, reset])
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <FormAutocomplete<ITourForm>
            noOptionsText={t('No shows found')}
            disableClearable
            fullWidth
            required
            errors={errors}
            label={t('Show')}
            name={TourFormField.ShowId}
            key={TourFormField.ShowId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={clientShows.map((show) => ({
              value: String(show.id),
              name: getShowTitle(show)
            }))}
            validationOptions={{required: true}}
          />
        ]}
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          <Typography variant="body1">
            {t('Custom internal name for tour?')}
          </Typography>
          <Switch color="primary" onChange={handleSwitchChange} />
        </Box>
        <Collapse in={isSectionExpanded}>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ITourForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                label={t('Name')}
                name={TourFormField.Name}
                key={TourFormField.Name}
                fullWidth
                required={isSectionExpanded}
                validationOptions={{
                  required: isSectionExpanded,
                  validate: isStringWithMaxLength255
                }}
              />
            ]}
          />
        </Collapse>
      </Box>
      <InputRow
        nodes={[
          <FormAutocomplete<ITourForm>
            noOptionsText={t('No divisions found')}
            disableClearable
            fullWidth
            required
            errors={errors}
            label={t('Division')}
            name={TourFormField.DivisionId}
            key={TourFormField.DivisionId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={divisions
              .filter(({state}) => state === DivisionState.Active)
              .sort((divisionA, divisionB) =>
                divisionA.name.localeCompare(divisionB.name)
              )
              .map(({id, name}) => ({
                value: String(id),
                name
              }))}
            validationOptions={{required: true}}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ITourForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={TourFormField.InternalNote}
            key={TourFormField.InternalNote}
            label={t('Internal note')}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ITourForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Duration')}
            name={TourFormField.Duration}
            key={TourFormField.Duration}
            fullWidth
            required
            inputMode="decimal"
            validationOptions={{
              required: true,
              validate: isPositiveInteger
            }}
          />
        ]}
      />
      <Box
        sx={{
          display: selectedDivisionId ? 'flex' : 'none',
          flexDirection: 'column',
          gap: 1.5
        }}
      >
        <TourPurchaseFormPart
          control={control}
          watch={watch}
          errors={errors}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          unregister={unregister}
          clearError={clearError}
          setError={
            setError as unknown as FormContextValues<ITourDivisionSettingsForm>['setError']
          }
          defaultValues={defaultValues}
        />
        <TourReservationFormPart
          control={control}
          watch={watch}
          errors={errors}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          unregister={unregister}
          clearError={clearError}
          setError={
            setError as unknown as FormContextValues<ITourDivisionSettingsForm>['setError']
          }
          defaultValues={defaultValues}
        />
        <TourEntranceFormPart
          control={control}
          watch={watch}
          errors={errors}
          register={register}
          setValue={setValue}
          triggerValidation={triggerValidation}
          unregister={unregister}
          clearError={clearError}
          setError={
            setError as unknown as FormContextValues<ITourDivisionSettingsForm>['setError']
          }
        />
      </Box>
    </StyledForm>
  )
}
