import {useCallback, useEffect} from 'react'

import {LONG_MOVE_STEP, SHORT_MOVE_STEP, SIDE_PANEL_ID} from '../config'
import {useSelector} from '../redux'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {useModifierKeysActions} from '../redux/keyboardKeys/actions'
import {CommonKey, ModifierKey} from '../redux/keyboardKeys/reducer'
import {
  isCtrlActiveSelector,
  isShiftActiveSelector
} from '../redux/keyboardKeys/selectors'
import {useObjectsActions} from '../redux/objects/actions'
import {isSomeObjectSelectedSelector} from '../redux/objects/selectors'
import {useSelectionActions} from '../redux/selection/actions'
import {useUndoableActions} from '../redux/undoable/actions'

export const escapeHandler = (
  selectedMode: EditorMode,
  isSomeObjectSelected: boolean,
  unselectAll: Function,
  setSelectMode: Function
) => {
  if (selectedMode === EditorMode.SELECT && isSomeObjectSelected) {
    unselectAll()
  } else if (selectedMode !== EditorMode.SELECT) {
    setSelectMode()
  }
  return
}

const isInSidePanel = (element: HTMLElement | null): boolean => {
  if (!element) return false

  if (element.id === SIDE_PANEL_ID) return true

  return isInSidePanel(element.parentElement)
}

export const useKeyboardListener = () => {
  const displayMode = useSelector(displayModeSelector)
  const {selectedMode} = useSelector(editorModeSelector)
  const isSomeObjectSelected = useSelector(isSomeObjectSelectedSelector)
  const isCtrlPressed = useSelector(isCtrlActiveSelector)
  const isShiftPressed = useSelector(isShiftActiveSelector)

  const {setPanMode, setSelectMode} = useEditorModeActions()
  const {addModifierKey, removeModifierKey} = useModifierKeysActions()
  const {unselectAll} = useSelectionActions()
  const {undo, redo} = useUndoableActions()
  const {moveSelectedObjects, removeSelectedObjects} = useObjectsActions()

  const onEscapeDown = useCallback(
    () =>
      escapeHandler(
        selectedMode,
        isSomeObjectSelected,
        unselectAll,
        setSelectMode
      ),
    [isSomeObjectSelected, selectedMode, setSelectMode, unselectAll]
  )

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const target = e.target ? (e.target as HTMLElement) : null

      if (isInSidePanel(target) && e.key !== CommonKey.Escape) {
        return
      }

      if (
        (displayMode === DisplayMode.PRICING ||
          displayMode === DisplayMode.CASH) &&
        e.key !== CommonKey[' '] &&
        e.key !== ModifierKey.Control &&
        e.key !== ModifierKey.Meta
      ) {
        return
      }

      if (e.key in ModifierKey) {
        addModifierKey(e.key as ModifierKey)
      } else if (e.key in CommonKey) {
        switch (e.key) {
          case CommonKey.ArrowUp: {
            moveSelectedObjects(
              0,
              isShiftPressed ? -LONG_MOVE_STEP : -SHORT_MOVE_STEP
            )
            return
          }
          case CommonKey.ArrowDown: {
            moveSelectedObjects(
              0,
              isShiftPressed ? LONG_MOVE_STEP : SHORT_MOVE_STEP
            )
            return
          }
          case CommonKey.ArrowLeft: {
            moveSelectedObjects(
              isShiftPressed ? -LONG_MOVE_STEP : -SHORT_MOVE_STEP,
              0
            )
            return
          }
          case CommonKey.ArrowRight: {
            moveSelectedObjects(
              isShiftPressed ? LONG_MOVE_STEP : SHORT_MOVE_STEP,
              0
            )
            return
          }
          case CommonKey.Backspace:
          case CommonKey.Delete: {
            if (
              displayMode === DisplayMode.FULL ||
              displayMode === DisplayMode.FULL_WITHOUT_SEATS
            ) {
              removeSelectedObjects()
            }
            return
          }
          case CommonKey.Escape: {
            onEscapeDown()
            return
          }
          case CommonKey.y:
          case CommonKey.Y: {
            if (isCtrlPressed) {
              redo()
            }
            return
          }
          case CommonKey.z:
          case CommonKey.Z: {
            if (isCtrlPressed) {
              if (isShiftPressed) {
                redo()
              } else {
                undo()
              }
            }
            return
          }
          case CommonKey[' ']: {
            if (selectedMode !== EditorMode.PAN) {
              setPanMode({})
            }
            return
          }
          default:
            return
        }
      }
    },
    [
      addModifierKey,
      displayMode,
      isCtrlPressed,
      isShiftPressed,
      moveSelectedObjects,
      onEscapeDown,
      redo,
      removeSelectedObjects,
      selectedMode,
      setPanMode,
      undo
    ]
  )

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      const target = e.target ? (e.target as HTMLElement) : null

      if (isInSidePanel(target)) {
        return
      }

      if (e.key in ModifierKey) {
        removeModifierKey(e.key as ModifierKey)
      } else if (e.key in CommonKey) {
        if (e.key === CommonKey[' '] && selectedMode !== EditorMode.SELECT) {
          setSelectMode()
        }
      }
    },
    [removeModifierKey, selectedMode, setSelectMode]
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [onKeyDown, onKeyUp])
}
