import {useTranslation} from 'react-i18next'

import {CartState} from '../__generated__/schema'

export const useTranslateCartState = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<CartState, string> = {
    [CartState.Draft]: t<string>('CartState->Draft'),
    [CartState.Sold]: t<string>('CartState->Sold'),
    [CartState.Discarded]: t<string>('CartState->Discarded'),
    [CartState.Expired]: t<string>('CartState->Expired'),
    [CartState.Failed]: t<string>('CartState->Failed'),
    [CartState.Reserved]: t<string>('CartState->Reserved'),
    [CartState.Pending]: t<string>('CartState->Pending')
  }
  return (code: CartState) => translatedCodes[code]
}

export const useTranslateCartStateDescription = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<CartState, string> = {
    [CartState.Draft]: t('Cart is being processed.'),
    [CartState.Sold]: t('Cart has been successfully ended by payment.'),
    [CartState.Discarded]: t('Cart has been discarded by user.'),
    [CartState.Expired]: t('Cart has not been finished in the specified time.'),
    [CartState.Failed]: t(
      'Cart has been ended by unsuccessful payment attempt.'
    ),
    [CartState.Reserved]: t('Cart has been successfully reserved.'),
    [CartState.Pending]: t('Waiting for the result of payment process.')
  }
  return (code: CartState) => translatedCodes[code]
}
