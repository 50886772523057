import DeleteIcon from '@mui/icons-material/Delete'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {Button, Chip, Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import {sortBy} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ReservationDetailFragment,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useGetEventSeatLocation} from '../../../../../hooks/getEventSeatLocation'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {
  ButtonWithConfirmationDialog,
  DrawerSection,
  DrawerTemplateHeader,
  SectionGrid,
  SectionGridCouple
} from '../../../../common'
import {DividerSeparatedInfoRow} from '../../../../common/DividerSeparatedInfoRow'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {EventDrawerSection} from '../../../../common/eventDetailDrawer/EventDrawerSection'
import {TourTimeSlotDrawerSection} from '../../../../common/eventDetailDrawer/TourTimeSlotDrawerSection'
import {useCancelReservation} from '../../cashDesk/graphql'
import {
  ReservationErrorDialog,
  ReservationErrorTriggers
} from '../../cashDesk/ReservationErrorDialog'
import {
  useDaysCountBetweenTodayAndExpirationDate,
  useExpirationToNowLabel,
  useGetExpirationToNowColor
} from '../../cashDesk/utils'
import {
  isCashDeskTourTimeSlotDetailPropertiesFragment,
  isTourItemPropertiesFragment,
  isTourOrTicketItemPropertiesFragment
} from '../../types'
import {LeadSectionGridContent} from '../LeadSectionGridContent'
import {CreateMessageDrawer} from '../messageDrawer/CreateMessageDrawer'
import {MessageFormField} from '../messageDrawer/types'

const useSegmentAnchors = (isTourTimeSlot: boolean) => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      general: {
        id: 'general',
        label: t('General')
      },
      reservationDetails: {
        id: 'reservationDetails',
        label: t('Reservation details')
      },
      tickets: {
        id: 'tickets',
        label: t('Tickets')
      },
      ...(isTourTimeSlot
        ? {
            tourTimeSlot: {
              id: 'tourTimeSlot',
              label: t('Tour time slot')
            }
          }
        : {
            event: {
              id: 'event',
              label: t('Event')
            }
          }),
      history: {
        id: 'history',
        label: t('History')
      }
    }),
    [isTourTimeSlot, t]
  )
}

interface IDetailsProps {
  reservation: ReservationDetailFragment & {
    activity:
      | NonNullable<ReservationDetailFragment['event']>
      | NonNullable<ReservationDetailFragment['tourTimeSlot']>
  }
}

const useDetailsStyles = makeStyles<Theme>((theme) => ({
  chipLabelSmall: {
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  },
  chipIconSmall: {
    width: '10px',
    height: '10px',
    marginLeft: theme.spacing(1)
  },
  expirationText: {
    paddingRight: theme.spacing(2)
  }
}))

interface ITicketProps {
  ticket: TicketItemPropertiesFragment | TourItemPropertiesFragment
}

const useTicketStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    margin: theme.spacing(0, 2),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  price: {
    gridRow: '1 / span 2',
    gridColumnStart: 2,
    alignSelf: 'center',
    textAlign: 'end'
  },
  priceBeforeDiscount: {
    textDecoration: 'line-through'
  }
}))

const Ticket: React.FC<ITicketProps> = ({ticket}: ITicketProps) => {
  const {t} = useTranslation()
  const classes = useTicketStyles()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const getEventSeatLocation = useGetEventSeatLocation()
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">
        {isTourItemPropertiesFragment(ticket)
          ? ticket.name
          : getEventSeatLocation(ticket.eventSeat)}
      </Typography>
      <div className={classes.price}>
        <Typography variant="subtitle2" align="right">
          {translateEffectiveClientPrice(ticket.price)}
        </Typography>
        {ticket.priceBeforeDiscount !== ticket.price && (
          <Typography
            variant="caption"
            align="right"
            color="textSecondary"
            className={classes.priceBeforeDiscount}
          >
            {translateEffectiveClientPrice(ticket.priceBeforeDiscount)}
          </Typography>
        )}
      </div>
      <Typography variant="caption" color="textSecondary">
        {isTourItemPropertiesFragment(ticket)
          ? [
              t('{{count}} attendees', {count: ticket.attendeesCount}),
              ...ticket.appliedDiscounts.map(
                (appliedDiscount) => appliedDiscount.discount.name
              )
            ].join(' • ')
          : [
              ticket.eventPricingToTicketType.name,
              ...ticket.appliedDiscounts.map(
                (appliedDiscount) => appliedDiscount.discount.name
              )
            ].join(' • ')}
      </Typography>
    </div>
  )
}

const Details: React.FC<IDetailsProps> = ({reservation}: IDetailsProps) => {
  const {formatTime, formatDateNumeric} = useDateTimeFormatters()
  const expireDate = new Date(reservation.expireAt)
  const classes = useDetailsStyles()
  const {t} = useTranslation()
  const getExpirationToNowColor = useGetExpirationToNowColor({
    isWithBackground: true
  })
  const daysCountBetweenTodayAndExpirationDate =
    useDaysCountBetweenTodayAndExpirationDate(reservation.expireAt)
  const chipLabel = useExpirationToNowLabel({
    expireAt: reservation.expireAt,
    daysCountBetweenTodayAndExpirationDate
  })
  return (
    <SectionGrid>
      <SectionGridCouple label={t('Expiration')} isLabelCentered>
        <span>
          <Typography
            variant="body2"
            component="span"
            className={classes.expirationText}
          >
            {`${formatDateNumeric(expireDate)} • ${formatTime(expireDate)}`}
          </Typography>
          <Chip
            component="span"
            size="small"
            color="primary"
            icon={<FiberManualRecordIcon fontSize="small" />}
            classes={{
              colorPrimary: getExpirationToNowColor(
                daysCountBetweenTodayAndExpirationDate
              ),
              iconSmall: classes.chipIconSmall,
              labelSmall: classes.chipLabelSmall
            }}
            label={chipLabel}
          />
        </span>
      </SectionGridCouple>
      <LeadSectionGridContent leadData={reservation.lead?.data} />
    </SectionGrid>
  )
}

const useGeneralSectionStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    padding: theme.spacing(2)
  },
  eventInfoWrapper: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    justifyContent: 'start'
  },
  reservationInfoWrapper: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start'
  }
}))

const General: React.FC<IDetailsProps> = ({reservation}: IDetailsProps) => {
  const {formatTime, formatDateNumeric} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {t} = useTranslation()
  const classes = useGeneralSectionStyles()
  const startsAtDate = new Date(reservation.activity.startsAt)
  const reservationCreatedAt = new Date(reservation.createdAt)
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const translateSellingChannel = useTranslateSellingChannel()
  return (
    <div className={classes.root}>
      <div className={classes.eventInfoWrapper}>
        <Typography variant="caption" color="textSecondary">
          {isCashDeskTourTimeSlotDetailPropertiesFragment(reservation.activity)
            ? t('Tour time slot')
            : t('Event')}
        </Typography>
        <Typography variant="subtitle2">
          {[
            formatDateNumeric(startsAtDate),
            formatTime(startsAtDate),
            isCashDeskTourTimeSlotDetailPropertiesFragment(reservation.activity)
              ? reservation.activity.tour.name
              : getUserLocaleTranslation(reservation.activity.names)
          ].join(' • ')}
        </Typography>
      </div>
      <DividerSeparatedInfoRow
        information={[
          {
            caption: t('Total price'),
            value: translateEffectiveClientPrice(reservation.price)
          },
          {
            caption: t('Items'),
            value: reservation.items?.length || 0
          },
          {
            caption: t('Channel'),
            value: translateSellingChannel(reservation.sellingChannel)
          },
          {
            caption: t('Created at'),
            value: `${formatDateNumeric(reservationCreatedAt)}, ${formatTime(
              reservationCreatedAt
            )}`
          }
        ]}
      />
    </div>
  )
}

const History: React.FC<IDetailsProps> = ({reservation}: IDetailsProps) => {
  const {t} = useTranslation()
  const {formatTime, formatDateNumeric} = useDateTimeFormatters()
  const createdAtDate = new Date(reservation.createdAt)
  const updatedAtDate =
    reservation.updatedAt &&
    reservation.updatedAt !== reservation.createdAt &&
    new Date(reservation.updatedAt)
  return (
    <SectionGrid>
      <SectionGridCouple label={t('Created')}>
        {`${formatDateNumeric(createdAtDate)}, ${formatTime(createdAtDate)}, ${
          reservation.createdByName
        }`}
      </SectionGridCouple>
      {updatedAtDate && (
        <SectionGridCouple label={t('Last update')}>
          {`${formatDateNumeric(updatedAtDate)}, ${formatTime(
            updatedAtDate
          )}, ${reservation.updatedByName}`}
        </SectionGridCouple>
      )}
    </SectionGrid>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  section: {
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2)
  }
}))

interface IReservationDetailContentProps {
  onClose: () => void
  onEditButtonClick: () => void
  reservation: ReservationDetailFragment & {
    activity:
      | NonNullable<ReservationDetailFragment['event']>
      | NonNullable<ReservationDetailFragment['tourTimeSlot']>
  }
  onReservationDelete: () => void
  showPrimaryButton?: boolean
}

export const ReservationDetailContent: React.FC<IReservationDetailContentProps> =
  ({
    reservation,
    onClose,
    onReservationDelete,
    onEditButtonClick,
    showPrimaryButton
  }: IReservationDetailContentProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const classes = useStyles()

    const [reservationError, setReservationError] =
      useState<{
        error: ApolloError
        trigger: ReservationErrorTriggers
      } | null>(null)
    const {
      state: isCreateMessageDrawerOpen,
      setTrue: openCreateMessageDrawer,
      setFalse: closeCreateMessageDrawer
    } = useBooleanState(false)

    const cancelReservation = useCancelReservation()
    const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
    const handleDeleteConfirmation = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await cancelReservation({reservationId: reservation.id})
        addInfoNotification(t('Reservation deleted'))
        onReservationDelete()
        onClose()
      } catch (e) {
        setReservationError({
          error: e,
          trigger: ReservationErrorTriggers.Delete
        })
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      cancelReservation,
      onClose,
      onReservationDelete,
      reservation.id,
      setShowBackdrop,
      t
    ])

    const history = useHistory()

    const onOpenPreview = useCallback(() => {
      history.push(routeTo.admin.cashDesk.reservationPreview(reservation.id))
      onClose()
    }, [history, onClose, reservation.id])

    const sideMenuItems: Record<string, {id: string; label: string}> =
      useSegmentAnchors(
        isCashDeskTourTimeSlotDetailPropertiesFragment(reservation.activity)
      )

    return (
      <>
        {reservationError &&
          reservationError.trigger === ReservationErrorTriggers.Delete && (
            <ReservationErrorDialog
              error={reservationError.error}
              onConfirm={() => setReservationError(null)}
              trigger={ReservationErrorTriggers.Delete}
            />
          )}
        <DrawerTemplateWithSideNavigation
          DrawerTemplateProps={{
            header: (
              <DrawerTemplateHeader
                title={t('Reservation #{{id}}', {id: reservation.id})}
                onLeftActionClick={onClose}
              />
            ),
            footer: (
              <>
                {P([PermissionCode.CancelReservation]) && (
                  <>
                    <ButtonWithConfirmationDialog
                      buttonProps={{
                        color: 'primary',
                        startIcon: <DeleteIcon />,
                        children: t('Delete')
                      }}
                      onConfirmButtonClick={handleDeleteConfirmation}
                      dialogProps={{
                        title: t('Delete reservation?'),
                        contentText: t(
                          'Do you really want to delete this reservation? This action is permanent and irreversible.'
                        ),
                        confirmButtonLabel: t('Delete')
                      }}
                    />
                    <Divider orientation="vertical" />
                  </>
                )}
                <Button onClick={onClose} color="primary">
                  {t('Close')}
                </Button>
                {showPrimaryButton &&
                  P([
                    PermissionCode.ReadReservation,
                    PermissionCode.ReadEventSeats
                  ]) && (
                    <Button
                      autoFocus
                      onClick={onOpenPreview}
                      color="primary"
                      variant="contained"
                    >
                      {t('Open preview')}
                    </Button>
                  )}
              </>
            )
          }}
          navigationItems={sideMenuItems}
        >
          <DrawerSection
            className={classes.section}
            id={sideMenuItems.general.id}
          >
            <General reservation={reservation} />
          </DrawerSection>
          <DrawerSection
            className={classes.section}
            id={sideMenuItems.reservationDetails.id}
            label={sideMenuItems.reservationDetails.label}
            actions={
              <div className={classes.actionButtons}>
                {P([PermissionCode.SendMessage]) &&
                  reservation.lead?.data.email && (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={openCreateMessageDrawer}
                    >
                      {t('Send message')}
                    </Button>
                  )}
                {P([PermissionCode.UpdateReservation]) && (
                  <Button color="primary" onClick={onEditButtonClick}>
                    {t('Edit')}
                  </Button>
                )}
              </div>
            }
          >
            <Details reservation={reservation} />
          </DrawerSection>
          <DrawerSection
            className={classes.section}
            id={sideMenuItems.tickets.id}
            label={sideMenuItems.tickets.label}
          >
            {sortBy(
              reservation.items || ([] as TicketItemPropertiesFragment[]),
              'id'
            )
              .filter(isTourOrTicketItemPropertiesFragment)
              .map(
                (
                  ticket:
                    | TicketItemPropertiesFragment
                    | TourItemPropertiesFragment
                ) => (
                  <Ticket ticket={ticket} key={ticket.id} />
                )
              )}
          </DrawerSection>
          {isCashDeskTourTimeSlotDetailPropertiesFragment(reservation.activity)
            ? sideMenuItems.tourTimeSlot && (
                <TourTimeSlotDrawerSection
                  tourTimeSlot={reservation.activity}
                  drawerSectionProps={{
                    id: sideMenuItems.tourTimeSlot.id,
                    label: sideMenuItems.tourTimeSlot.label,
                    className: classes.section
                  }}
                />
              )
            : sideMenuItems.event && (
                <EventDrawerSection
                  event={reservation.activity}
                  drawerSectionProps={{
                    id: sideMenuItems.event.id,
                    label: sideMenuItems.event.label,
                    className: classes.section
                  }}
                />
              )}
          <DrawerSection
            className={classes.section}
            id={sideMenuItems.history.id}
            label={sideMenuItems.history.label}
          >
            <History reservation={reservation} />
          </DrawerSection>
        </DrawerTemplateWithSideNavigation>
        {reservation.lead && reservation.lead.data.email && (
          <CreateMessageDrawer
            isOpen={isCreateMessageDrawerOpen}
            onClose={closeCreateMessageDrawer}
            leads={[
              {id: reservation.lead.id, contact: reservation.lead.data.email}
            ]}
            defaultValues={{
              [MessageFormField.LeadIds]: [reservation.lead.id]
            }}
          />
        )}
      </>
    )
  }
