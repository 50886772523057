import {Box} from '@mui/material'
import {compact} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ShowTypeCode,
  TourQuery
} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useTourParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {PersistentDrawerMenu} from '../../../../common/PersistentDrawerMenu'
import {PersistentDrawerWithContent} from '../../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../Header'
import {useGetTour} from '../graphql'
import {AdmissionRateDetail} from './admissionRateDetail'
import {AdmissionRates} from './AdmissionRates'
import {General} from './general'
import {TimeSlots} from './timeSlots'

const useNavigationItems = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {tourId} = useTourParams()
  return useMemo(
    (): {
      general?: {id: string; label: string; route: string}
      timeSlots?: {id: string; label: string; route: string}
      admissionRates?: {id: string; label: string; route: string}
    } => ({
      ...(P([PermissionCode.ReadTour])
        ? {
            general: {
              id: 'general',
              label: t('General'),
              route: routeTo.admin.tours.general(tourId)
            }
          }
        : {}),
      ...(P([PermissionCode.ManageTimeSlots, PermissionCode.ReadTourTimeSlots])
        ? {
            timeSlots: {
              id: 'timeSlots',
              label: t('Time slots'),
              route: routeTo.admin.tours.timeSlots(tourId)
            }
          }
        : {}),
      ...(P([
        PermissionCode.ManageAdmissionRates,
        PermissionCode.ReadAdmissionRates
      ])
        ? {
            admissionRates: {
              id: 'admissionRates',
              label: t('Admission rates'),
              route: routeTo.admin.tours.admissionRates(tourId)
            }
          }
        : {})
    }),
    [P, t, tourId]
  )
}

const useGetFirstAvailableRoute = () => {
  const navigationItems = useNavigationItems()
  return () =>
    compact([
      navigationItems.general?.route,
      navigationItems.timeSlots?.route,
      navigationItems.admissionRates?.route,
      routeTo.admin.tours.index()
    ])[0]
}

interface IDrawerContentProps {
  tourId: number
  showTypeCode: ShowTypeCode
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  tourId,
  showTypeCode
}: IDrawerContentProps) => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadTour]) && (
        <Route path={routeTo.admin.tours.general(':tourId')}>
          <General tourId={tourId} />
        </Route>
      )}
      {P([
        PermissionCode.ManageTimeSlots,
        PermissionCode.ReadTourTimeSlots
      ]) && (
        <Route path={routeTo.admin.tours.timeSlots(':tourId')}>
          <TimeSlots tourId={tourId} showTypeCode={showTypeCode} />
        </Route>
      )}
      {P([
        PermissionCode.ManageAdmissionRates,
        PermissionCode.ReadAdmissionRates
      ]) && (
        <Route path={routeTo.admin.tours.admissionRates(':tourId')}>
          <AdmissionRates tourId={tourId} />
        </Route>
      )}
    </Switch>
  )
}

export const TourDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {tourId} = useTourParams()
  const {data, loading, error} = useGetTour(tourId)
  const navigationItems = useNavigationItems()
  const getFirstAvailableRoute = useGetFirstAvailableRoute()
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(true)
  const [title, setTitle] = useState<string>(t('Tour'))
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.tours.index()),
    [history]
  )
  useEffect(() => {
    if (data) {
      setTitle(data.tour.name)
    }
  }, [data])
  return (
    <Switch>
      <Route path={routeTo.admin.tours.detail(':tourId')} exact>
        <Redirect to={getFirstAvailableRoute()} />
      </Route>
      {P([PermissionCode.ReadAdmissionRate]) && (
        <Route
          path={routeTo.admin.tours.admissionRateDetail(
            ':tourId',
            ':admissionRateId'
          )}
          component={AdmissionRateDetail}
        />
      )}
      <PageWithHeaderTemplate
        header={
          <SecondaryHeader
            title={title}
            hasArrowBackIcon
            onLeftActionClick={handleArrowBackClick}
          />
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData<TourQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading tour')}
            ignoreLoadingIfData
            dataCondition={(data) => Boolean(data.tour)}
          >
            {({tour}) => (
              <Box sx={{display: 'grid', height: '100%'}}>
                <PersistentDrawerWithContent
                  content={
                    <DrawerContent
                      tourId={tour.id}
                      showTypeCode={tour.show.typeCode}
                    />
                  }
                  drawerContent={
                    <PersistentDrawerMenu items={navigationItems} />
                  }
                  isOpen={isDrawerOpen}
                  onClose={closeDrawer}
                  onOpen={openDrawer}
                />
              </Box>
            )}
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </PageWithHeaderTemplate>
    </Switch>
  )
}
