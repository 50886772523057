import {chain, noop, reverse} from 'lodash'
import {UserFieldsFragment} from '../__generated__/schema'

export const useFormatUserName =
  (swap?: boolean, hideLead?: boolean) => (user: UserFieldsFragment) => {
    if (user.username === 'attendio') {
      return 'Entradio'
    }
    if (hideLead && user.username === 'lead') {
      return ''
    }
    return chain([user.firstName, user.lastName])
      .tap(swap ? reverse : noop)
      .join(' ')
      .value()
  }

export const useFormatShortGuideName =
  (swap?: boolean) => (guide: UserFieldsFragment) =>
    swap
      ? `${guide.lastName} ${guide.firstName.charAt(0)}.`
      : `${guide.firstName.charAt(0)}. ${guide.lastName}`
