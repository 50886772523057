import Decimal from 'decimal.js'
import {isFinite} from 'lodash'
import {convertValueToFloat} from './conversions'

const isFinitePredicate = (value: any): value is number => isFinite(value)

export const safeSum = (
  prices: (number | string | null | undefined)[]
): number =>
  Decimal.sum(
    0,
    ...prices.map(convertValueToFloat).filter<number>(isFinitePredicate)
  ).toNumber()
