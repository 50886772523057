import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateWarehouseInput,
  CreateWarehouseMutation,
  CreateWarehouseMutationVariables,
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables,
  WarehouseQuery,
  WarehouseQueryVariables,
  WarehousesQuery,
  WarehousesQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const NARROW_WAREHOUSE_FIELDS = gql`
  fragment NarrowWarehouseFields on Warehouse {
    id
    name
    code
    division {
      id
      name
    }
    state
  }
`

const WAREHOUSES = gql`
  ${NARROW_WAREHOUSE_FIELDS}
  ${PAGINATION_FRAGMENT}
  query Warehouses(
    $filter: WarehousesFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouses(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowWarehouseFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useWarehouses = (variables?: WarehousesQueryVariables) =>
  useQuery<WarehousesQuery, WarehousesQueryVariables>(WAREHOUSES, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_WAREHOUSE = gql`
  ${NARROW_WAREHOUSE_FIELDS}
  mutation CreateWarehouse($input: CreateWarehouseInput!) {
    createWarehouse(input: $input) {
      ...NarrowWarehouseFields
    }
  }
`

export const useCreateWarehouse = () => {
  const [createWarehouse] =
    useMutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>(
      CREATE_WAREHOUSE
    )
  return useCallback(
    (input: CreateWarehouseInput) =>
      createWarehouse({
        variables: {input}
      }),
    [createWarehouse]
  )
}

const WAREHOUSE = gql`
  ${NARROW_WAREHOUSE_FIELDS}
  query Warehouse($id: PositiveInt!) {
    warehouse(id: $id) {
      ...NarrowWarehouseFields
    }
  }
`

export const useWarehouse = (id: number) =>
  useQuery<WarehouseQuery, WarehouseQueryVariables>(WAREHOUSE, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const UPDATE_WAREHOUSE = gql`
  ${NARROW_WAREHOUSE_FIELDS}
  mutation UpdateWarehouse($id: PositiveInt!, $input: UpdateWarehouseInput!) {
    updateWarehouse(id: $id, input: $input) {
      ...NarrowWarehouseFields
    }
  }
`

export const useUpdateWarehouse = () => {
  const [updateWarehouse] =
    useMutation<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>(
      UPDATE_WAREHOUSE
    )
  return useCallback(
    (variables: UpdateWarehouseMutationVariables) =>
      updateWarehouse({variables}),
    [updateWarehouse]
  )
}
