import dayjs from 'dayjs'
import {useCallback, useMemo} from 'react'
import {
  AvailableDatePickerValues,
  IDatePickerDateRange,
  TabFuture,
  TabNow,
  TabPast
} from './types'

export const useTransformDatePickerValueToDate = () => {
  const now = useMemo(() => dayjs(), [])
  return useCallback(
    (value?: AvailableDatePickerValues): IDatePickerDateRange | undefined => {
      if (value) {
        switch (value) {
          case TabFuture.Tomorrow:
            return {
              dateFrom: dayjs().add(1, 'day').startOf('d'),
              dateTo: dayjs().add(1, 'day').endOf('d')
            }
          case TabFuture.Next7Days:
            return {
              dateFrom: dayjs().add(1, 'day').startOf('d'),
              dateTo: dayjs().add(7, 'day').endOf('d')
            }
          case TabFuture.Next14Days:
            return {
              dateFrom: dayjs().add(1, 'day').startOf('d'),
              dateTo: dayjs().add(14, 'day').endOf('d')
            }
          case TabFuture.Next28Days:
            return {
              dateFrom: dayjs().add(1, 'day').startOf('d'),
              dateTo: dayjs().add(28, 'day').endOf('d')
            }
          case TabFuture.NextWeek:
            return {
              dateFrom: dayjs().add(1, 'week').startOf('week'),
              dateTo: dayjs().add(1, 'week').endOf('week')
            }
          case TabFuture.NextMonth:
            return {
              dateFrom: dayjs().add(1, 'month').startOf('month'),
              dateTo: dayjs().add(1, 'month').endOf('month')
            }
          case TabFuture.NextQuarter:
            return {
              dateFrom: dayjs().add(1, 'quarter').startOf('quarter'),
              dateTo: dayjs().add(1, 'quarter').endOf('quarter')
            }
          case TabFuture.NextYear:
            return {
              dateFrom: dayjs().add(1, 'year').startOf('year'),
              dateTo: dayjs().add(1, 'year').endOf('year')
            }
          case TabNow.FromNow:
            return {dateFrom: now, dateTo: null}
          case TabNow.FromToday:
            return {dateFrom: dayjs().startOf('d'), dateTo: null}
          case TabNow.UntilToday:
            return {
              dateTo: dayjs().endOf('day'),
              dateFrom: null
            }
          case TabNow.ThisWeek:
            return {
              dateFrom: dayjs().startOf('week'),
              dateTo: dayjs().endOf('week')
            }
          case TabNow.ThisMonth:
            return {
              dateFrom: dayjs().startOf('month'),
              dateTo: dayjs().endOf('month')
            }
          case TabNow.ThisQuarter:
            return {
              dateFrom: dayjs().startOf('quarter'),
              dateTo: dayjs().endOf('quarter')
            }
          case TabNow.ThisYear:
            return {
              dateFrom: dayjs().startOf('year'),
              dateTo: dayjs().endOf('year')
            }
          case TabPast.Yesterday:
            return {
              dateFrom: dayjs().subtract(1, 'day').startOf('day'),
              dateTo: dayjs().subtract(1, 'day').endOf('day')
            }
          case TabPast.Last7Days:
            return {
              dateFrom: dayjs().subtract(7, 'day').startOf('day'),
              dateTo: dayjs().subtract(1, 'day').endOf('day')
            }
          case TabPast.Last14Days:
            return {
              dateFrom: dayjs().subtract(14, 'day').startOf('day'),
              dateTo: dayjs().subtract(1, 'day').endOf('day')
            }
          case TabPast.Last28Days:
            return {
              dateFrom: dayjs().subtract(28, 'day').startOf('day'),
              dateTo: dayjs().subtract(1, 'day').endOf('day')
            }
          case TabPast.LastWeek:
            return {
              dateFrom: dayjs().subtract(1, 'week').startOf('week'),
              dateTo: dayjs().subtract(1, 'week').endOf('week')
            }
          case TabPast.LastMonth:
            return {
              dateFrom: dayjs().subtract(1, 'month').startOf('month'),
              dateTo: dayjs().subtract(1, 'month').endOf('month')
            }
          case TabPast.LastQuarter:
            return {
              dateFrom: dayjs().subtract(1, 'quarter').startOf('quarter'),
              dateTo: dayjs().subtract(1, 'quarter').endOf('quarter')
            }
          case TabPast.LastYear:
            return {
              dateFrom: dayjs().subtract(1, 'year').startOf('year'),
              dateTo: dayjs().subtract(1, 'year').endOf('year')
            }
          case TabNow.Today:
          default:
            return {
              dateFrom: dayjs().startOf('day'),
              dateTo: dayjs().endOf('day')
            }
        }
      }
      return undefined
    },
    [now]
  )
}

export const isPastValue = (value: any): value is TabPast =>
  Object.values(TabPast).includes(value)
export const isNowValue = (value: any): value is TabNow =>
  Object.values(TabNow).includes(value)
