import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {WarehouseDocumentDetail} from './detail'
import {WarehouseDocumentsPage} from './WarehouseDocumentsPage'

export const WarehouseDocuments: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadWarehouseDocument]) && (
        <Route
          path={routeTo.admin.warehouseDocuments.detail(':warehouseDocumentId')}
          component={WarehouseDocumentDetail}
        />
      )}
      <Route
        path={routeTo.admin.warehouseDocuments.index()}
        component={WarehouseDocumentsPage}
        exact
      />
    </Switch>
  )
}
