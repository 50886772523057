import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import {LocaleCode} from './__generated__/schema'
import {getInitialLocale} from './components/context/locale'

const getWeekStart = (locale: LocaleCode) => {
  switch (locale) {
    case LocaleCode.En:
      return 0
    case LocaleCode.Hu:
    case LocaleCode.Cs:
    case LocaleCode.Sk:
    default:
      return 1
  }
}

export const initializeDayjs = () => {
  const locale = getInitialLocale()
  dayjs.extend(updateLocale)
  dayjs.extend(quarterOfYear)
  dayjs.extend(isToday)
  dayjs.extend(isYesterday)
  dayjs.extend(duration)
  dayjs.extend(utc)
  dayjs.extend(isBetween)
  dayjs.locale(locale)
  dayjs.updateLocale(locale, {weekStart: getWeekStart(locale)})
}
