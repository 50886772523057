import AddIcon from '@mui/icons-material/Add'
import {Box, Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  WebsitesFilter,
  WebsitesQuery,
  WebsiteState
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {LazyLoadingList} from '../../../common/LazyLoadingList'
import {MediaSizes} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {CenteredLayout} from '../Layout'
import {CreateWebsiteDrawer} from './CreateWebsiteDrawer'
import {useWebsites} from './graphql'
import {WebsiteCard} from './WebsiteCard'

interface IWebsitesList {
  searchFilter: WebsitesFilter
}

export const WebsitesList: React.FC<IWebsitesList> = ({
  searchFilter
}: IWebsitesList) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateWebsiteDrawerOpen,
    setTrue: openCreateWebsiteDrawer,
    setFalse: closeCreateWebsiteDrawer
  } = useBooleanState(false)
  const hasSupportUserPermission = P([PermissionCode.WebsiteInfoForSupportUser])
  const {data, loading, error, fetchMore, isLoadingMore} = useWebsites({
    paginationInput: {offset: 0, limit: 15},
    filter: {
      ...searchFilter,
      ...(!hasSupportUserPermission && {
        states: [WebsiteState.Published, WebsiteState.Draft]
      })
    }
  })
  const history = useHistory()
  const handleDetailClick = useCallback(
    (id: number) => () => history.push(routeTo.admin.websites.detail(id)),
    [history]
  )
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data && data?.websites.pagination.hasMore) {
      fetchMore()
    }
  }, [data, fetchMore, isLoadingMore])
  const canCreateWebsite = P([PermissionCode.CreateWebsite])
  const fabClasses = useFabClasses()
  return (
    <RenderOnData<WebsitesQuery>
      data={data}
      loading={data === undefined && loading}
      error={error}
      errorMessage={t<string>('Error while loading websites')}
    >
      {({websites}) => (
        <>
          {websites.items.length > 0 ? (
            <LazyLoadingList
              isLoadingMore={isLoadingMore}
              onScrolledNearTheEndOfTheList={
                handleScrolledNearTheEndOfTheLayout
              }
              sx={{pt: 3, pb: 11}}
            >
              <CenteredLayout>
                <Box
                  sx={{
                    gap: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, 304px)',
                    justifyContent: 'center',
                    [`@media ${MediaSizes.Phablet}`]: {
                      gridTemplateColumns: '1fr'
                    }
                  }}
                >
                  {websites.items.map((website) => (
                    <WebsiteCard
                      key={website.id}
                      website={website}
                      onDetailClick={
                        P([PermissionCode.ReadWebsite])
                          ? handleDetailClick
                          : undefined
                      }
                      hasSupportUserPermission={hasSupportUserPermission}
                    />
                  ))}
                </Box>
              </CenteredLayout>
            </LazyLoadingList>
          ) : (
            <Blank
              title={t('Discover the power of online presence!')}
              description={t(
                'Launch your website to increase visibility and credibility, connecting with audiences online. Utilize customizable designs, SEO tools, and analytics to engage visitors and track your success. Ensure your brand is always available, making information, services, or products accessible around the clock.'
              )}
              actions={
                canCreateWebsite && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={openCreateWebsiteDrawer}
                  >
                    {t('Create')}
                  </Button>
                )
              }
            />
          )}
          {canCreateWebsite && websites.items.length > 0 && (
            <CreateFab classes={fabClasses} onClick={openCreateWebsiteDrawer} />
          )}
          <CreateWebsiteDrawer
            isOpen={isCreateWebsiteDrawerOpen}
            onClose={closeCreateWebsiteDrawer}
          />
        </>
      )}
    </RenderOnData>
  )
}
