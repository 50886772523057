import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateCashDeskDepositMutation,
  CreateCashDeskDepositMutationVariables,
  CreateCashDeskWithdrawalMutation,
  CreateCashDeskWithdrawalMutationVariables
} from '../../../../../__generated__/schema'

const CREATE_CASH_DESK_DEPOSIT = gql`
  mutation CreateCashDeskDeposit(
    $note: NonEmptyString
    $paymentInputs: [PaymentInput!]!
  ) {
    createCashDeskDeposit(note: $note, paymentInputs: $paymentInputs) {
      id
    }
  }
`

export const useCreateCashDeskDeposit = () => {
  const [createCashDeskDeposit] = useMutation<
    CreateCashDeskDepositMutation,
    CreateCashDeskDepositMutationVariables
  >(CREATE_CASH_DESK_DEPOSIT)
  return useCallback(
    (variables: CreateCashDeskDepositMutationVariables) =>
      createCashDeskDeposit({variables}),
    [createCashDeskDeposit]
  )
}

const CREATE_CASH_DESK_WITHDRAWAL = gql`
  mutation CreateCashDeskWithdrawal(
    $note: NonEmptyString
    $paymentInputs: [PaymentInput!]!
  ) {
    createCashDeskWithdrawal(note: $note, paymentInputs: $paymentInputs) {
      id
    }
  }
`

export const useCreateCashDeskWithdrawal = () => {
  const [createCashDeskWithdrawal] = useMutation<
    CreateCashDeskWithdrawalMutation,
    CreateCashDeskWithdrawalMutationVariables
  >(CREATE_CASH_DESK_WITHDRAWAL)
  return useCallback(
    (variables: CreateCashDeskWithdrawalMutationVariables) =>
      createCashDeskWithdrawal({variables}),
    [createCashDeskWithdrawal]
  )
}
