import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {AdvancedSettingsContextProvider} from './AdvancedSettingsContext'
import {AdvancedSettingsPage} from './advancedSettingsPage'
import {CheckModeContextProvider} from './CheckModeContext'
import {CheckPassCodePage} from './checkPassCodePage'
import {Events} from './Events'
import {Switcher} from './Switcher'
import {Tours} from './Tours'
import {TourTimeSlots} from './TourTimeSlots'
import {Venues} from './Venues'

export const CheckTickets: React.FC = () => {
  const {P} = useEnsurePermissions()
  const hasBasePermission =
    P([PermissionCode.CheckPassCodeIn]) ||
    P([PermissionCode.CheckPassCodeOut]) ||
    P([PermissionCode.ReadItemByPassCode])
  return (
    <AdvancedSettingsContextProvider>
      <CheckModeContextProvider>
        <Switch>
          <Route
            component={AdvancedSettingsPage}
            path={routeTo.admin.checkTickets.advancedSettings()}
          />
          <Route
            component={CheckPassCodePage}
            path={routeTo.admin.checkTickets.checkTicketCode(
              ':checkType',
              ':entityId'
            )}
          />
          {hasBasePermission && P([PermissionCode.ReadPaginatedEvents]) && (
            <Route
              component={Events}
              path={routeTo.admin.checkTickets.events()}
            />
          )}
          {hasBasePermission && P([PermissionCode.ReadTourTimeSlots]) && (
            <Route
              component={TourTimeSlots}
              path={routeTo.admin.checkTickets.tourTimeSlots()}
            />
          )}
          {hasBasePermission && P([PermissionCode.ReadTours]) && (
            <Route
              component={Tours}
              path={routeTo.admin.checkTickets.tours()}
            />
          )}
          {hasBasePermission && P([PermissionCode.ReadVenues]) && (
            <Route
              component={Venues}
              path={routeTo.admin.checkTickets.venues()}
            />
          )}
          <Route
            component={Switcher}
            path={routeTo.admin.checkTickets.index()}
          />
        </Switch>
      </CheckModeContextProvider>
    </AdvancedSettingsContextProvider>
  )
}
