import {Button, ButtonProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventDetailDrawerDataQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {ReactComponent as PinIcon} from '../../../../../assets/pin.svg'
import {ReactComponent as PinOffIcon} from '../../../../../assets/pinOff.svg'
import {useBooleanState} from '../../../../../hooks/state'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {DrawerTemplateHeader} from '../../../../common'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {CrewDrawerSection} from '../../../../common/eventDetailDrawer/CrewDrawerSection'
import {EventDrawerSection} from '../../../../common/eventDetailDrawer/EventDrawerSection'
import {OverviewDrawerSection} from '../../../../common/eventDetailDrawer/OverviewDrawerSection'
import {PricesAndDiscountsDrawerSection} from '../../../../common/eventDetailDrawer/PricesAndDiscountsDrawerSection'
import {useNotifications} from '../../../../context/notifications'
import {usePinnedEvents} from '../PinnedEventsContext'
import {CreateMessageDialog} from './CreateMessageDialog'

const useSegmentAnchors = ({isCrewAvailable}: {isCrewAvailable: boolean}) => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      event: {
        id: 'event',
        label: t('Event')
      },
      pricesAndDiscounts: {
        id: 'pricesAndDiscounts',
        label: t('Prices & discounts')
      },
      overview: {
        id: 'overview',
        label: t('Overview')
      },
      ...(isCrewAvailable
        ? {
            crew: {
              id: 'crew',
              label: t('Crew')
            }
          }
        : {})
    }),
    [isCrewAvailable, t]
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
  },
  pinIcon: {
    width: '1em',
    height: '1em',
    fontSize: '1.5rem'
  }
}))

interface IEventDetailContentProps extends EventDetailDrawerDataQuery {
  onClose(): void

  ActionButtonProps?: ButtonProps
}

export const EventDetailContent: React.FC<IEventDetailContentProps> = ({
  onClose,
  event,
  ActionButtonProps
}: IEventDetailContentProps) => {
  const classes = useStyles()
  const sideMenuItems: Record<string, {id: string; label: string}> =
    useSegmentAnchors({isCrewAvailable: event.show.crew.length > 0})
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {pinEvent, unpinEvent, isEventPinned} = usePinnedEvents()
  const {addInfoNotification} = useNotifications()
  const {
    state: isCreateMessageDialogOpen,
    setTrue: openCreateMessageDialog,
    setFalse: closeCreateMessageDialog
  } = useBooleanState(false)

  const pinCurrentEvent = useCallback(() => {
    pinEvent(event)
    addInfoNotification(t('Event pinned to the top'))
  }, [addInfoNotification, event, pinEvent, t])

  const unpinCurrentEvent = useCallback(() => {
    unpinEvent(event.id)
    addInfoNotification(t('Event unpinned'))
  }, [addInfoNotification, event.id, t, unpinEvent])
  const hasPermissionToAccessCreateMessageDialog = P([
    PermissionCode.SendMessage,
    PermissionCode.ReadLeads
  ])
  return (
    <>
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          header: (
            <DrawerTemplateHeader
              title={
                getUserLocaleTranslation(event.names) || t('Loading title...')
              }
              onLeftActionClick={onClose}
            />
          ),
          footer: (
            <>
              <Button autoFocus onClick={onClose} color="primary">
                {t('Close')}
              </Button>
              {ActionButtonProps && (
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  {...ActionButtonProps}
                />
              )}
            </>
          )
        }}
        navigationItems={sideMenuItems}
      >
        <EventDrawerSection
          event={event}
          drawerSectionProps={{
            id: sideMenuItems.event.id,
            label: sideMenuItems.event.label,
            actions: (
              <div className={classes.buttonsBox}>
                {hasPermissionToAccessCreateMessageDialog && (
                  <Button color="primary" onClick={openCreateMessageDialog}>
                    {t('Send message')}
                  </Button>
                )}
                {isEventPinned(event.id) ? (
                  <Button
                    color="primary"
                    startIcon={<PinOffIcon className={classes.pinIcon} />}
                    onClick={unpinCurrentEvent}
                  >
                    {t('Unpin')}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    startIcon={<PinIcon className={classes.pinIcon} />}
                    onClick={pinCurrentEvent}
                  >
                    {t('Pin')}
                  </Button>
                )}
              </div>
            )
          }}
        />
        <PricesAndDiscountsDrawerSection
          event={event}
          drawerSectionProps={{
            id: sideMenuItems.pricesAndDiscounts.id,
            label: sideMenuItems.pricesAndDiscounts.label
          }}
        />
        <OverviewDrawerSection
          event={event}
          drawerSectionProps={{
            id: sideMenuItems.overview.id,
            label: sideMenuItems.overview.label,
            isWithoutPaper: true
          }}
        />
        {sideMenuItems.crew && (
          <CrewDrawerSection
            event={event}
            drawerSectionProps={{
              id: sideMenuItems.crew.id,
              label: sideMenuItems.crew.label
            }}
          />
        )}
      </DrawerTemplateWithSideNavigation>
      {hasPermissionToAccessCreateMessageDialog && (
        <CreateMessageDialog
          eventId={event.id}
          isOpen={isCreateMessageDialogOpen}
          onClose={closeCreateMessageDialog}
        />
      )}
    </>
  )
}
