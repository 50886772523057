import {CircularProgress} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

interface ILoadingMoreProgressProps {
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const LoadingMoreProgress: React.FC<ILoadingMoreProgressProps> = ({
  className
}: ILoadingMoreProgressProps) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.root, className)}>
      <CircularProgress />
    </div>
  )
}
