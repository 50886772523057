import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  TextFieldProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {CancelButton} from '../../../../common/Buttons'

const useOutlinedInputStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  bottomInfo: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

interface IOutlinedInputWithCharsCountProps {
  label: string
  inputId: string
  requiredText: string
  availableCharsLabel: string
  inputProps?: TextFieldProps
}

const OutlinedInputWithCharsCount: React.FC<IOutlinedInputWithCharsCountProps> =
  ({
    label,
    inputProps = {},
    inputId,
    requiredText,
    availableCharsLabel
  }: IOutlinedInputWithCharsCountProps) => {
    const classes = useOutlinedInputStyles()
    return (
      <FormControl className={classes.root} fullWidth>
        <TextField
          variant="outlined"
          id={inputId}
          label={label}
          {...inputProps}
        />
        <div className={classes.bottomInfo}>
          <Typography variant="caption" color="textSecondary">
            {requiredText}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {availableCharsLabel}
          </Typography>
        </div>
      </FormControl>
    )
  }

interface IClaimDialogProps {
  title: string
  text: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  confirmationButtonLabel: string
  onConfirm: () => void
  onCancel: () => void
  isConfirmationButtonDisabled?: boolean
}

const useClaimDialogStyles = makeStyles<Theme>((theme) => ({
  dialogActions: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

export const ClaimDialog: React.FC<IClaimDialogProps> = ({
  title,
  confirmationButtonLabel,
  text,
  onChange,
  onConfirm,
  onCancel,
  isConfirmationButtonDisabled
}: IClaimDialogProps) => {
  const {t} = useTranslation()
  const classes = useClaimDialogStyles()
  return (
    <Dialog fullWidth open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{pt: 1}}>
          <OutlinedInputWithCharsCount
            inputId="claim-reason"
            label={t('Reason')}
            requiredText={t('*Required')}
            availableCharsLabel={`${text.length}/255`}
            inputProps={{
              value: text,
              placeholder: t('Type reason here'),
              onChange,
              required: true,
              focused: true,
              fullWidth: true,
              inputProps: {maxLength: 255}
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CancelButton onClick={onCancel}>{t('Cancel')}</CancelButton>
        <Button
          color="primary"
          onClick={onConfirm}
          disabled={!text || isConfirmationButtonDisabled}
        >
          {confirmationButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
