import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../../theme'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: 199
  }
}))

interface ITabPanelProps {
  children: React.ReactNode
  isVisible: boolean
  className?: string
}

export const TabPanel: React.FC<ITabPanelProps> = ({
  children,
  isVisible,
  className
}: ITabPanelProps) => {
  const classes = useStyles()
  return isVisible ? (
    <div className={cn(classes.root, className)}>{children}</div>
  ) : null
}
