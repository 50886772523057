import {Box, Typography} from '@mui/material'
import Paper from '@mui/material/Paper'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ProductGroupQuery} from '../../../../../__generated__/schema'
import {useFormatUserName} from '../../../../../hooks/formatUserName'
import {
  useTranslateProductGroupState,
  useTranslateProductGroupStateDescription
} from '../../../../../hooks/translateProductGroupState'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {ColorBox} from '../../../../common/ColorBox'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {
  ProductGroupIcon,
  ProductGroupSvgIcon
} from '../../../../common/productGroupIcon'
import {productGroupStateColors} from '../../../../constants'

interface IGeneralSectionProps {
  id: string
  productGroup: ProductGroupQuery['productGroup']
}

export const GeneralSection: React.FC<IGeneralSectionProps> = ({
  id,
  productGroup
}: IGeneralSectionProps) => {
  const {t} = useTranslation()
  const translateProductGroupState = useTranslateProductGroupState()
  const translateProductGroupStateDescription =
    useTranslateProductGroupStateDescription()
  const formatUserName = useFormatUserName()
  const {formatDateTime} = useDateTimeFormatters()
  return (
    <Paper
      variant="outlined"
      sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}
      id={id}
    >
      <Typography variant="h6">{productGroup.name}</Typography>
      <Box sx={{display: 'flex', alignItems: 'baseline', gap: 1}}>
        <EntityStateChip
          colorConf={productGroupStateColors[productGroup.state]}
          label={translateProductGroupState(productGroup.state)}
        />
        <Typography variant="caption" color="textSecondary">
          {translateProductGroupStateDescription(productGroup.state)}
        </Typography>
      </Box>
      {productGroup.internalDescription && (
        <SingleInfoColumn
          caption={t('Internal description')}
          value={productGroup.internalDescription}
          valueTypographyVariant="body2"
        />
      )}
      <DividerSeparatedInfoRow
        information={[
          {
            caption: t('Icon'),
            value: productGroup.icon && (
              <ProductGroupSvgIcon
                icon={productGroup.icon as ProductGroupIcon}
              />
            )
          },
          {
            caption: t('Color'),
            value: (
              <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center'}}>
                <ColorBox hexColor={productGroup.color} />
                <Typography variant="subtitle2">
                  {productGroup.color.substring(1).toUpperCase()}
                </Typography>
              </Box>
            )
          },
          {
            caption: t('Created by'),
            value: formatUserName(productGroup.createdBy)
          },
          {
            caption: t('Updated at'),
            value: formatDateTime(new Date(productGroup.updatedAt))
          },
          {
            caption: t('Updated by'),
            value: formatUserName(productGroup.updatedBy)
          }
        ]}
      />
    </Paper>
  )
}
