import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {ScriptForm} from './ScriptForm'
import {IScriptForm} from './types'

const ADD_SCRIPT_FORM_ID = 'addScriptForm'

interface IAddScriptDialogProps {
  isOpen: boolean
  onClose: () => void
  onAdd: (data: IScriptForm) => void
}

export const AddScriptDialog: React.FC<IAddScriptDialogProps> = ({
  isOpen,
  onClose,
  onAdd
}: IAddScriptDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add script')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <ScriptForm formId={ADD_SCRIPT_FORM_ID} onSubmit={onAdd} />
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={ADD_SCRIPT_FORM_ID}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
