import {useCallback} from 'react'
import {useLocalStorageState} from '../../../hooks/storage'

const MAX_ITEMS_COUNT = 5

interface IUseSearchHistory {
  items: string[]
  addToHistory(item: string): void
}

type ItemType = [string, number]

export const useSearchHistory = (key: string): IUseSearchHistory => {
  const [historyArray, setHistoryArray] = useLocalStorageState<ItemType[]>(
    key,
    []
  )
  const addToHistory = useCallback(
    (value: string) => {
      const historyObjectWithNewEntry = {
        ...Object.fromEntries<number>(historyArray),
        [value]: Date.now()
      }

      const newHistoryArray = Object.entries(historyObjectWithNewEntry)
        .sort((a, b) => {
          return b[1] - a[1]
        })
        .slice(0, MAX_ITEMS_COUNT)

      setHistoryArray(newHistoryArray)
    },
    [historyArray, setHistoryArray]
  )
  return {
    addToHistory,
    items: historyArray.map(([label]) => label)
  }
}
