import React, {useCallback} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {MessageDetailDrawer} from '../components/messageDrawer/MessageDetailDrawer'
import {MessagesPage} from './MessagesPage'

export const Messages: React.FC = () => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.messages.index()),
    [history]
  )
  return (
    <>
      <MessagesPage />
      {P([PermissionCode.ReadMessage]) && (
        <Route path={routeTo.admin.messages.detail(':messageId')}>
          <MessageDetailDrawer onExited={handleExited} />
        </Route>
      )}
    </>
  )
}
