import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PaymentsFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {CartPreviewDrawer} from '../components/cartPreviewDrawer'
import {ClaimDetailDrawer} from '../components/claimDetailDrawer'
import {
  DEFAULT_PAYMENTS_OVERVIEW_FILTER_INPUT,
  PaymentsSearch
} from '../components/PaymentsSearch'
import {PrimaryHeader} from '../Header'
import {PaymentsOverviewPage} from './PaymentsOverviewPage'

export const PaymentsOverview: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<PaymentsFilterInput>(
    DEFAULT_PAYMENTS_OVERVIEW_FILTER_INPUT
  )
  const history = useHistory()
  const handleExited = useCallback(() => {
    history.replace(routeTo.admin.paymentsOverview.index())
  }, [history])
  return (
    <>
      <PageWithHeaderTemplate
        header={
          <PrimaryHeader
            title={t('Payments overview')}
            search={
              <PaymentsSearch
                location="payments"
                onFilterChange={setSearchFilter}
              />
            }
          />
        }
      >
        <ChildrenOnEffectiveClientSelected
          text={t('Please select some client, to see payments overview.')}
        >
          <PaymentsOverviewPage searchFilter={searchFilter} />
        </ChildrenOnEffectiveClientSelected>
      </PageWithHeaderTemplate>
      {P([PermissionCode.ReadCart]) && (
        <Route path={routeTo.admin.paymentsOverview.cartInfo(':cartId')} exact>
          <CartPreviewDrawer onExited={handleExited} />
        </Route>
      )}
      {P([PermissionCode.ReadClaim]) && (
        <Route
          path={routeTo.admin.paymentsOverview.claimInfo(':claimId')}
          exact
        >
          <ClaimDetailDrawer onExited={handleExited} />
        </Route>
      )}
    </>
  )
}
