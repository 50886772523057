import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  CreateVenueMutation,
  DeleteVenueMutation,
  MutationCreateVenueArgs,
  MutationDeleteVenueArgs,
  MutationUpdateVenueArgs,
  UpdateVenueMutation,
  UpdateVenuesOrderMutation,
  UpdateVenuesOrderMutationVariables,
  VenueQuery,
  VenueQueryVariables,
  VenuesQuery,
  VenuesQueryVariables
} from '../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../utils/apollo'

const VENUE_PROPERTIES_FRAGMENT = gql`
  fragment VenueProperties on Venue {
    id
    name
    secondaryName
    address {
      complex
      street
      town
      postalCode
      country
    }
    auditoriums {
      id
    }
  }
`

export const GET_VENUES = gql`
  query Venues {
    venues {
      ...VenueProperties
    }
  }
  ${VENUE_PROPERTIES_FRAGMENT}
`

const GET_VENUE = gql`
  query Venue($id: Int!) {
    venue(id: $id) {
      ...VenueProperties
    }
  }
  ${VENUE_PROPERTIES_FRAGMENT}
`

const CREATE_VENUE = gql`
  mutation CreateVenue($data: VenueInput!) {
    createVenue(data: $data) {
      ...VenueProperties
    }
  }
  ${VENUE_PROPERTIES_FRAGMENT}
`

const UPDATE_VENUE = gql`
  mutation UpdateVenue($id: Int!, $data: VenueInput!) {
    updateVenue(id: $id, data: $data) {
      ...VenueProperties
    }
  }
  ${VENUE_PROPERTIES_FRAGMENT}
`

const DELETE_VENUE = gql`
  mutation DeleteVenue($id: Int!) {
    deleteVenue(id: $id) {
      ...VenueProperties
    }
  }
  ${VENUE_PROPERTIES_FRAGMENT}
`

export const useUpdateVenue = () => {
  const [updateVenue] =
    useMutation<UpdateVenueMutation, MutationUpdateVenueArgs>(UPDATE_VENUE)
  return useCallback(
    ({id, data}: MutationUpdateVenueArgs) =>
      updateVenue({variables: {id, data}}),
    [updateVenue]
  )
}

export const useDeleteVenue = () => {
  const [deleteVenue] = useMutation<
    DeleteVenueMutation,
    MutationDeleteVenueArgs
  >(DELETE_VENUE, {
    refetchQueries: () => {
      return [{query: GET_VENUES}]
    },
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, data.deleteVenue)
      }
    }
  })
  return useCallback(
    ({id}: MutationDeleteVenueArgs) => deleteVenue({variables: {id}}),
    [deleteVenue]
  )
}

export const useCreateVenue = () => {
  const [createVenue] = useMutation<
    CreateVenueMutation,
    MutationCreateVenueArgs
  >(CREATE_VENUE, {
    refetchQueries: () => {
      return [{query: GET_VENUES}]
    }
  })
  return useCallback(
    ({data}: MutationCreateVenueArgs) => createVenue({variables: {data}}),
    [createVenue]
  )
}

export const useGetVenues = () => {
  return useQuery<VenuesQuery, VenuesQueryVariables>(GET_VENUES)
}

export const useGetVenue = (id: number) => {
  return useQuery<VenueQuery, VenueQueryVariables>(GET_VENUE, {
    variables: {id}
  })
}

const UPDATE_VENUES_ORDER = gql`
  mutation UpdateVenuesOrder($ids: [PositiveInt!]!) {
    updateVenuesOrder(ids: $ids) {
      id
    }
  }
`

export const useUpdateVenuesOrder = () => {
  const [updateVenuesOrder] =
    useMutation<UpdateVenuesOrderMutation, UpdateVenuesOrderMutationVariables>(
      UPDATE_VENUES_ORDER
    )
  return useCallback(
    (ids: number[]) => updateVenuesOrder({variables: {ids}}),
    [updateVenuesOrder]
  )
}
