import React, {useState, useContext} from 'react'

import {useBooleanState} from '../../../../../../hooks/state'

type DrawersStateContext = {
  selectedTicketId: number | null
  openSelectedTicketTypeDrawer: (ticketId: number) => any
  closeSelectedTicketTypeDrawer: () => any
  isOpenCreateTicketTypeDrawer: boolean
  openCreateTicketTypeDrawer: () => any
  closeCreateTicketTypeDrawer: () => any
}

const DrawerStateContext = React.createContext({} as DrawersStateContext)

type Props = {
  children: React.ReactNode
}

export const TicketTypeDrawersStateProvider: React.FC<Props> = ({
  children
}: Props) => {
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null)
  const {
    state: isOpenCreateTicketTypeDrawer,
    setTrue: openCreateTicketTypeDrawer,
    setFalse: closeCreateTicketTypeDrawer
  } = useBooleanState(false)

  const openSelectedTicketTypeDrawer = (ticketId: number) => {
    setSelectedTicketId(ticketId)
  }

  const closeSelectedTicketTypeDrawer = () => {
    setSelectedTicketId(null)
  }

  const value = {
    selectedTicketId,
    openSelectedTicketTypeDrawer,
    closeSelectedTicketTypeDrawer,
    isOpenCreateTicketTypeDrawer,
    openCreateTicketTypeDrawer,
    closeCreateTicketTypeDrawer
  }

  return (
    <DrawerStateContext.Provider value={value}>
      {children}
    </DrawerStateContext.Provider>
  )
}

export const useTicketTypeDrawersState = () => useContext(DrawerStateContext)
