import {IZone} from '@attendio/shared-components'
import {CanvasObjectType} from '../../types'

export enum ZoneActionType {
  ADD_ZONE = 'add zone'
}

export interface IAddZoneAction {
  type: typeof ZoneActionType.ADD_ZONE
  payload: {
    type: typeof CanvasObjectType.Zone
    config: IZone
  }
}

export type ZoneAction = IAddZoneAction
