import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  SendMessageInput,
  SendMessageMutation,
  SendMessageMutationVariables
} from '../../../../../__generated__/schema'

const SEND_MESSAGE = gql`
  mutation sendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      id
    }
  }
`

export const useSendMessage = () => {
  const [sendMessage] =
    useMutation<SendMessageMutation, SendMessageMutationVariables>(SEND_MESSAGE)
  return useCallback(
    (input: SendMessageInput) => sendMessage({variables: {input}}),
    [sendMessage]
  )
}
