import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  InventoryChecksFilterInput,
  LightweightUsersQuery,
  PermissionCode
} from '../../../../__generated__/schema'
import {useFormatUserName} from '../../../../hooks/formatUserName'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {useLightweightUsers} from '../graphql'

export const DEFAULT_INVENTORY_CHECKS_FILTER_INPUT: InventoryChecksFilterInput =
  {
    hasText: undefined
  }

type ExtendedInventoryChecksFilterInput = InventoryChecksFilterInput & {
  _completedAtDaterangeId?: DATERANGE_IDS
  _createdAtDaterangeId?: DATERANGE_IDS
  _updatedAtDaterangeId?: DATERANGE_IDS
}

const stripCompletedAtDateFromFilter = (
  filter: ExtendedInventoryChecksFilterInput
): ExtendedInventoryChecksFilterInput =>
  omit(filter, ['completedAtFrom', 'completedAtTo', '_completedAtDaterangeId'])

const stripCreatedAtDateFromFilter = (
  filter: ExtendedInventoryChecksFilterInput
): ExtendedInventoryChecksFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripUpdatedAtDateFromFilter = (
  filter: ExtendedInventoryChecksFilterInput
): ExtendedInventoryChecksFilterInput =>
  omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAtDaterangeId'])

const stripHelperKeysFromFilter = (
  filter: ExtendedInventoryChecksFilterInput
): ExtendedInventoryChecksFilterInput =>
  omit(filter, [
    '_completedAtDaterangeId',
    '_createdAtDaterangeId',
    '_updatedAtDaterangeId'
  ])

const dateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.THIS_MONTH,
  DATERANGE_IDS.LAST_MONTH
]

const mapHasTextToFilter = (
  filter: InventoryChecksFilterInput,
  hasText?: string
): InventoryChecksFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapNoteToFilter = (
  filter: InventoryChecksFilterInput,
  note?: string
): InventoryChecksFilterInput => ({
  ...filter,
  note: note || undefined
})

const mapCompletedByUserIdToFilter = (
  filter: InventoryChecksFilterInput,
  completedById?: number
): InventoryChecksFilterInput => ({
  ...filter,
  completedByIds: completedById ? [completedById] : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const formatUserName = useFormatUserName(true)
  const getHasTextFromSearchObject = (filter: InventoryChecksFilterInput) =>
    filter.hasText || undefined
  const getNoteFromSearchObject = (filter: InventoryChecksFilterInput) =>
    filter.note ? t('Note: {{note}}', {note: filter.note}) : undefined
  const getCompletedByFromSearchObject = (
    filter: InventoryChecksFilterInput,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.completedByIds && filter.completedByIds.length) {
      const completedById = filter.completedByIds[0]
      const user = users.find((user) => user.id === completedById)
      return user
        ? t('Completed by: {{userName}}', {
            userName: formatUserName(user)
          })
        : t('Completed by ID: {{userId}}', {userId: completedById})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getNoteFromSearchObject,
    getCompletedByFromSearchObject
  }
}

interface IInventoryChecksSearchProps {
  onFilterChange: (filter: InventoryChecksFilterInput) => void
}

export const InventoryChecksSearch: React.FC<IInventoryChecksSearchProps> = ({
  onFilterChange
}: IInventoryChecksSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {users} = useLightweightUsers()
  const {
    getHasTextFromSearchObject,
    getNoteFromSearchObject,
    getCompletedByFromSearchObject
  } = useGetFieldFromSearchObject()
  const {
    daterangeOptions: completedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCompletedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCompletedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCompletedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCompletedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedInventoryChecksFilterInput>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Completed at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.completedAtFrom || undefined,
      endDateISOString: o.completedAtTo || undefined,
      id: o._completedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      completedAtTo: input.startDate,
      completedAtFrom: input.endDate,
      _completedAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedInventoryChecksFilterInput>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Created at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: updatedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedUpdatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedInventoryChecksFilterInput>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Updated at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.updatedAtFrom || undefined,
      endDateISOString: o.updatedAtTo || undefined,
      id: o._updatedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      updatedAtFrom: input.startDate,
      updatedAtTo: input.endDate,
      _updatedAtDaterangeId: input.id
    })
  })
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<InventoryChecksFilterInput>(
      getHasTextFromSearchObject,
      getStringifiedCompletedAtDateDateRangeFromSearchObject,
      getNoteFromSearchObject,
      (filter) => getCompletedByFromSearchObject(filter, users),
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
      getStringifiedUpdatedAtDateDateRangeFromSearchObject
    )
  const formatUserName = useFormatUserName(true)
  const userSelectOptions: ISelectOption<number>[] = users.map((user) => ({
    id: user.id,
    label: formatUserName(user)
  }))
  return (
    <Search<InventoryChecksFilterInput, ExtendedInventoryChecksFilterInput>
      storageKey="INVENTORY_CHECKS"
      placeholder={t('Search for inventory check')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_INVENTORY_CHECKS_FILTER_INPUT}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow<InventoryChecksFilterInput>
            label={t('Completed at')}
            daterangeOptions={completedAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCompletedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapCompletedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCompletedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCompletedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for inventory check completed at'
            )}
          />
          <AdvancedSearchTextRow<InventoryChecksFilterInput>
            label={t('Has text')}
            placeholder={t('Enter inventory check id or note')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow<InventoryChecksFilterInput>
            label={t('Note')}
            placeholder={t('Enter note')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapNoteToFilter}
            value={advancedSearchObject.note || undefined}
          />
          {P([PermissionCode.ReadUsers]) && (
            <AdvancedSearchSelectRow<InventoryChecksFilterInput, number>
              label={t('Completed by')}
              value={
                advancedSearchObject.completedByIds
                  ? advancedSearchObject.completedByIds[0]
                  : undefined
              }
              options={userSelectOptions}
              mapSelectValueToSearchObject={mapCompletedByUserIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          <AdvancedSearchDaterangeRow<InventoryChecksFilterInput>
            label={t('Created at')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for inventory check created at'
            )}
          />
          <AdvancedSearchDaterangeRow<InventoryChecksFilterInput>
            label={t('Updated at')}
            daterangeOptions={updatedAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomUpdatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripUpdatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for inventory check updated at'
            )}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
