import {Drawer, RadioGroup} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EcommercePaymentMethodInput,
  ErrorMessages,
  PaymentServiceProvider,
  PaymentServiceProviderConfigInput
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {
  useTranslatePaymentServiceProvider,
  useTranslatePaymentServiceProviderSubLabel
} from '../../../../hooks/translatePaymentMethodType'
import {Theme} from '../../../../theme'
import {useUserInfo} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'

import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {CancelButton, SaveButton} from '../../../common/Buttons'
import {RadioGroupItem} from '../../../common/RadioGroupItem'
import {useShowFormStyles} from '../shows/ShowForm'
import {EcommercePaymentMethodForm} from './EcommercePaymentMethodForm'
import {useCreateECommercePaymentMethod} from './graphql'
import {PaymentMethodField} from './types'

const CREATE_ECOMMERCE_PAYMENT_METHOD_FORM_ID =
  'createEcommercePaymentMethodFormId'

enum DrawerState {
  SELECT_PSP,
  FILL_CREATE_ECOMMERCE_PAYMENT_METHOD_FORM
}

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  radioGroup: {
    padding: theme.spacing(3)
  }
}))

interface ICreateEcommercePaymentMethodDrawerProps {
  isOpen: boolean
  closeDrawer: () => void
}

export const CreateEcommercePaymentMethodDrawer: React.FC<ICreateEcommercePaymentMethodDrawerProps> =
  ({isOpen, closeDrawer}: ICreateEcommercePaymentMethodDrawerProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const [drawerState, setDrawerState] = useState<DrawerState>(
      DrawerState.SELECT_PSP
    )
    const createECommercePaymentMethod = useCreateECommercePaymentMethod()
    const {
      defaultErrorHandler,
      setShowBackdrop,
      addInfoNotification,
      customErrorHandler
    } = useMutationAssistanceHooks()
    const [selectedPaymentServiceProvider, setSelectedPaymentServiceProvider] =
      useState<PaymentServiceProvider | null>(null)
    const onRadioGroupChange = useCallback((_event, value) => {
      setSelectedPaymentServiceProvider(value)
    }, [])

    const handleExited = useCallback(() => {
      setDrawerState(DrawerState.SELECT_PSP)
      setSelectedPaymentServiceProvider(null)
    }, [])

    const handleSubmit = useCallback(
      async (
        input: EcommercePaymentMethodInput,
        paymentServiceProviderConfigInput: PaymentServiceProviderConfigInput
      ) => {
        try {
          setShowBackdrop(true)
          await createECommercePaymentMethod({
            input,
            paymentServiceProviderConfigInput
          })
          addInfoNotification(t('Payment method created'))
          closeDrawer()
        } catch (e) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              e,
              ErrorMessages.InvalidStatnaPokladnicaCredentials
            ) ||
            getGraphQLErrorRelatedToErrorMessage(
              e,
              ErrorMessages.InvalidGopayCredentials
            )
          ) {
            customErrorHandler(e, {
              contentText: t(
                'Unable to create new payment method. Invalid credentials.'
              ),
              title: t('Create payment method has failed.'),
              confirmButtonLabel: t('Got it')
            })
          } else {
            defaultErrorHandler(e, t('Error while creating payment method'))
          }
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        createECommercePaymentMethod,
        customErrorHandler,
        defaultErrorHandler,
        setShowBackdrop,
        t
      ]
    )

    const translatePaymentServiceProvider = useTranslatePaymentServiceProvider()
    const translatePaymentServiceProviderSubLabel =
      useTranslatePaymentServiceProviderSubLabel()

    const options = Object.values(PaymentServiceProvider).map((type) => ({
      label: translatePaymentServiceProvider(type),
      subLabel: translatePaymentServiceProviderSubLabel(type),
      value: type
    }))
    const showFormClasses = useShowFormStyles()
    const {effectiveClient} = useUserInfo()
    if (!effectiveClient) {
      return null
    }
    return (
      <Drawer
        onClose={closeDrawer}
        open={isOpen}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
        SlideProps={{
          onExited: handleExited
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Create e-commerce payment method')}
            />
          }
          footer={
            drawerState === DrawerState.SELECT_PSP ? (
              <>
                <CancelButton onClick={closeDrawer} />
                <SaveButton
                  form={CREATE_ECOMMERCE_PAYMENT_METHOD_FORM_ID}
                  disabled={!selectedPaymentServiceProvider}
                  onClick={() => {
                    setDrawerState(
                      DrawerState.FILL_CREATE_ECOMMERCE_PAYMENT_METHOD_FORM
                    )
                  }}
                >
                  {t('Next')}
                </SaveButton>
              </>
            ) : (
              <>
                <CancelButton
                  onClick={() => {
                    setDrawerState(DrawerState.SELECT_PSP)
                  }}
                >
                  {t('Back')}
                </CancelButton>
                <SaveButton
                  type="submit"
                  form={CREATE_ECOMMERCE_PAYMENT_METHOD_FORM_ID}
                >
                  {t('Create')}
                </SaveButton>
              </>
            )
          }
        >
          {drawerState === DrawerState.SELECT_PSP ? (
            <RadioGroup
              onChange={onRadioGroupChange}
              classes={{root: classes.radioGroup}}
              value={selectedPaymentServiceProvider}
            >
              {options.map((option) => (
                <RadioGroupItem
                  key={option.value}
                  {...option}
                  selectedValue={selectedPaymentServiceProvider}
                />
              ))}
            </RadioGroup>
          ) : (
            <EcommercePaymentMethodForm
              formId={CREATE_ECOMMERCE_PAYMENT_METHOD_FORM_ID}
              onSubmit={handleSubmit}
              paymentServiceProvider={selectedPaymentServiceProvider!}
              defaultValues={{
                [PaymentMethodField.CURRENCY]: effectiveClient.currency,
                [PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]: false
              }}
              className={showFormClasses.form}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
