import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {ApolloError} from 'apollo-client'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {CalendarAlertIcon} from '../../../../common/icons'
import {Blank} from '../../../../visual/Blank'

interface IErrorProps {
  error: ApolloError
}

export const Error: React.FC<IErrorProps> = ({error}: IErrorProps) => {
  const {t} = useTranslation()
  if (
    getGraphQLErrorRelatedToErrorMessage(error, ErrorMessages.EventNotFound)
  ) {
    return <Blank title={t('Event not found')} IconComp={CalendarAlertIcon} />
  } else if (
    getGraphQLErrorRelatedToErrorMessage(
      error,
      ErrorMessages.TourTimeSlotNotFound
    )
  ) {
    return (
      <Blank
        title={t('Tour time slot not found')}
        IconComp={CalendarAlertIcon}
      />
    )
  } else if (
    getGraphQLErrorRelatedToErrorMessage(error, ErrorMessages.TourNotFound)
  ) {
    return (
      <Blank title={t('Tour not found')} IconComp={ReportProblemOutlinedIcon} />
    )
  } else if (
    getGraphQLErrorRelatedToErrorMessage(error, ErrorMessages.VenueNotFound)
  ) {
    return (
      <Blank
        title={t('Venue not found')}
        IconComp={ReportProblemOutlinedIcon}
      />
    )
  } else {
    return (
      <Blank
        title={t('Loading entity detail failed')}
        IconComp={ReportProblemOutlinedIcon}
      />
    )
  }
}
