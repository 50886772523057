import GetAppIcon from '@mui/icons-material/GetApp'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {Accordion, AccordionDetails, Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Address,
  CartState,
  Currency,
  Division,
  Event,
  PermissionCode,
  ProductItemPropertiesFragment,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment,
  Venue
} from '../../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../../hooks/formatUserName'
import {useTranslatePrice} from '../../../../../hooks/translateCurrencies'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {MassCheckbox, MassCheckboxState} from '../../../../common/MassCheckbox'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {
  isProductItemPropertiesFragment,
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'
import {ReceiptPrintingButtonGroup} from '../ReceiptPrintingButtonGroup'
import {TicketPrintingGroupButton} from '../TicketPrintingGroupButton'
import {CheckboxState, Item} from './Item'
import {ItemAccordionSummary} from './ItemAccordionSummary'
import {CartDetailDrawerType} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  accordionDetailsRoot: {
    paddingTop: 0
  },
  noPaymentFoundRoot: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  },
  topActions: {
    display: 'flex',
    padding: theme.spacing(0, 2, 2, 2),
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  topRightActions: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  list: {
    overflowY: 'visible'
  }
}))

interface IItemsAccordionProps {
  isExpanded: boolean
  onChange: (e?: React.ChangeEvent<{}>) => void
  event?: Pick<
    Event,
    'names' | 'startsAt' | 'formatCode' | 'soundMixCode' | 'versionCode'
  > | null
  price: number
  itemsCount: number
  cartState: CartState
  cartId: number
  items: Array<
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
  >
  checkboxesControllerState?: MassCheckboxState
  onMassCheckboxClick?: () => void
  onCheckboxClick?: (itemId: number) => (e: React.MouseEvent) => void
  isMassCheckboxDisabled?: boolean
  checkboxState?: CheckboxState
  selectedItemIds?: number[]
  onClaimButtonClick?: () => void
  isClaimButtonDisabled?: boolean
  onSendEmailButtonClick?: () => void
  isSendEmailButtonDisabled?: boolean
  downloadButtonUrl?: string
  isDownloadButtonDisabled?: boolean
  currency: Currency
  type: CartDetailDrawerType
  division?: Pick<Division, 'name'> & {address: Pick<Address, 'town'>}
  venue?: (Pick<Venue, 'name'> & {address: Pick<Address, 'town'>}) | null
  tourTimeSlot?: TourItemPropertiesFragment['tourTimeSlot'] | null
  uuid?: string | null
  hash?: string | null
}

export const ItemsAccordion: React.FC<IItemsAccordionProps> = ({
  isExpanded,
  onChange,
  event,
  price,
  itemsCount,
  cartState,
  cartId,
  items,
  checkboxesControllerState,
  onMassCheckboxClick,
  onCheckboxClick,
  isMassCheckboxDisabled,
  checkboxState,
  selectedItemIds,
  onClaimButtonClick,
  isClaimButtonDisabled,
  onSendEmailButtonClick,
  isSendEmailButtonDisabled,
  downloadButtonUrl,
  isDownloadButtonDisabled,
  currency,
  type,
  division,
  venue,
  tourTimeSlot,
  uuid,
  hash
}: IItemsAccordionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const translateShowFormat = useTranslateShowFormatAbbreviation()
  const translateShowSoundMix = useTranslateShowSoundMixAbbreviation()
  const translateShowVersion = useTranslateShowVersionAbbreviation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const translatePrice = useTranslatePrice(currency)
  const formatShortGuideName = useFormatShortGuideName()
  const classes = useStyles()
  const ticketItems = (items || []).filter(isTicketItemPropertiesFragment)
  const productItems = (items || []).filter(isProductItemPropertiesFragment)
  const tourItems = (items || []).filter(isTourItemPropertiesFragment)
  const isTicketItem = ticketItems.length > 0
  const isTourItem = tourItems.length > 0
  return (
    <Accordion variant="outlined" expanded={isExpanded} onChange={onChange}>
      <ItemAccordionSummary
        title={
          isTicketItem && event
            ? getUserLocaleTranslation(event.names)
            : isTourItem && tourTimeSlot
            ? tourTimeSlot.tour.name
            : t('Products')
        }
        primaryDescription={
          isTicketItem && event
            ? [
                formatDateNumeric(new Date(event.startsAt)),
                formatTime(new Date(event.startsAt)),
                event.formatCode && translateShowFormat(event.formatCode),
                event.soundMixCode && translateShowSoundMix(event.soundMixCode),
                event.versionCode && translateShowVersion(event.versionCode)
              ]
                .filter(Boolean)
                .join(' • ')
            : isTourItem && tourTimeSlot
            ? [
                formatDateNumeric(new Date(tourTimeSlot.startsAt)),
                formatTime(new Date(tourTimeSlot.startsAt)),
                tourTimeSlot.versionCode &&
                  translateShowVersion(tourTimeSlot.versionCode),
                tourTimeSlot.guide && formatShortGuideName(tourTimeSlot.guide)
              ]
                .filter(Boolean)
                .join(' • ')
            : productItems[0]?.division.name
        }
        secondaryDescription={[
          division &&
            `${t('Division')}: ${division.name}, ${division.address.town}`,
          venue && `${t('Venue')}: ${venue.name}, ${venue.address.town}`
        ]
          .filter(Boolean)
          .join(' • ')}
        priceLabel={translatePrice(price)}
        itemsCountLabel={
          isTicketItem || isTourItem
            ? t('{{count}} ticket', {
                count: itemsCount
              })
            : t('{{count}} product', {
                count: itemsCount
              })
        }
      />
      {cartState === CartState.Sold && (
        <div className={classes.topActions}>
          {checkboxesControllerState && (
            <MassCheckbox
              state={checkboxesControllerState}
              onClick={onMassCheckboxClick}
              disabled={isMassCheckboxDisabled}
            />
          )}
          <div className={classes.topRightActions}>
            {type === CartDetailDrawerType.Cart &&
              P([PermissionCode.CreateClaim]) && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={onClaimButtonClick}
                  disabled={isClaimButtonDisabled}
                >
                  {t('Claim items')}
                </Button>
              )}
            {(isTicketItem || isTourItem) && (
              <>
                <Button
                  color="primary"
                  variant="text"
                  disabled={isDownloadButtonDisabled}
                  href={downloadButtonUrl}
                  startIcon={<GetAppIcon />}
                >
                  {t('Download')}
                </Button>
                {P([PermissionCode.SendSaleConfirmationEmail]) && (
                  <Button
                    color="primary"
                    variant="text"
                    disabled={isSendEmailButtonDisabled}
                    onClick={onSendEmailButtonClick}
                    startIcon={<SendOutlinedIcon />}
                  >
                    {t('Send')}
                  </Button>
                )}
              </>
            )}
            {type === CartDetailDrawerType.Cart &&
              P([PermissionCode.ReadTemplates]) && (
                <ChildrenOnEffectiveClientSelected>
                  {isTourItem || isTicketItem ? (
                    <TicketPrintingGroupButton
                      selectedItemIds={selectedItemIds}
                      cartId={cartId}
                      isDisabled={!selectedItemIds?.length}
                      uuid={uuid}
                      hash={hash}
                    />
                  ) : (
                    <ReceiptPrintingButtonGroup
                      selectedItemIds={selectedItemIds}
                      cartId={cartId}
                      disabled={!selectedItemIds?.length}
                    />
                  )}
                </ChildrenOnEffectiveClientSelected>
              )}
          </div>
        </div>
      )}
      <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
        <ListOfItemsSeparatedByDividers className={classes.list} isFullWidth>
          {items.map((item) => (
            <Item
              key={item.id}
              item={item}
              checkboxProps={{
                checked:
                  !!selectedItemIds?.find(
                    (selectedItem) => selectedItem === item.id
                  ) || false
              }}
              onCheckboxClick={
                onCheckboxClick
                  ? !item.claim
                    ? onCheckboxClick(item.id)
                    : undefined
                  : undefined
              }
              checkboxState={
                checkboxState || item.claim
                  ? CheckboxState.Hidden
                  : CheckboxState.Visible
              }
              disabled={!!item.claim}
              claimState={item.claim?.state}
              currency={currency}
            />
          ))}
        </ListOfItemsSeparatedByDividers>
      </AccordionDetails>
    </Accordion>
  )
}
