import {uniq} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, RouteComponentProps} from 'react-router-dom'
import {
  AuditoriumPreviewDataQuery,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useEventSeats} from '../../../../../hooks/eventSeats'
import {useGetClientLocaleTranslation} from '../../../../../hooks/getClientLocaleTranslation'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {
  EVENTS_PARAMS,
  useEventsPathnameParams
} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {CartPreviewDrawer} from '../../components/cartPreviewDrawer'
import {SecondaryHeader} from '../../Header'
import {isTicketItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {EventDetailDrawer} from '../eventDetailDrawer'
import {useAuditoriumPreviewData} from '../graphql'
import {ChildrenOnValidEventAndCartCombination} from './ChildrenOnValidEventAndCartCombination'
import {Content} from './Content'

export const EventAuditoriumPreview: React.FC<RouteComponentProps> = ({
  history
}: RouteComponentProps) => {
  const {t} = useTranslation()
  const {eventId} = useEventsPathnameParams()
  const {data, loading, error} = useAuditoriumPreviewData(eventId)
  const [title, setTitle] = useState<string>(
    t<string>('Event with id {{eventId}}', {
      eventId
    })
  )
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const getClientLocaleTranslation = useGetClientLocaleTranslation()
  const {currentCartId, currentCart} = useCurrentCart()
  useEffect(() => {
    if (data) {
      const startsAt = new Date(data.event.startsAt)
      setTitle(
        [
          formatDateNumeric(startsAt),
          formatTime(startsAt),
          getClientLocaleTranslation(data.event.names)
        ]
          .filter(Boolean)
          .join(' • ')
      )
    }
  }, [data, formatDateNumeric, formatTime, getClientLocaleTranslation])

  const handleCartPreviewExited = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.eventAuditoriumPreview(eventId))
  }, [eventId, history])
  const handlEventDetailDrawerExited = useCallback(
    () => history.goBack(),
    [history]
  )
  const {
    data: eventSeatsData,
    loading: isEventSeatsLoading,
    interruptSubscription,
    resetSubscription
  } = useEventSeats({
    eventId,
    myCartId: currentCartId,
    myReservationIds: uniq(
      (currentCart?.items || [])
        .filter<TicketItemPropertiesFragment>(isTicketItemPropertiesFragment)
        .filter((item) => item.reservation?.id)
        .map<number>((item) => item.reservation!.id)
    )
  })
  const handleLeftActionClick = useCallback(
    () => history.push(routeTo.admin.cashDesk.events()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleLeftActionClick}
          isDatetimeEnabled
        />
      }
    >
      <RenderOnData<AuditoriumPreviewDataQuery>
        data={data}
        loading={loading && isEventSeatsLoading}
        error={error}
        dataCondition={(data) =>
          Boolean(data && data.event && eventSeatsData?.eventSeats)
        }
        errorMessage={t<string>('Could not load event data')}
      >
        {({event}) => (
          <ChildrenOnValidEventAndCartCombination event={event}>
            <Content
              event={event}
              eventSeats={eventSeatsData!.eventSeats}
              interruptSubscription={interruptSubscription}
              resetSubscription={resetSubscription}
            />
            <Route
              path={[
                routeTo.admin.cashDesk.eventAuditoriumPreviewWithCartInfo(
                  toPlaceholderParam(EVENTS_PARAMS.EVENT_ID),
                  ':cartId'
                )
              ]}
            >
              <CartPreviewDrawer onExited={handleCartPreviewExited} />
            </Route>
            <Route
              path={routeTo.admin.cashDesk.eventAuditoriumPreviewWithEventInfo(
                toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
              )}
              exact
            >
              <EventDetailDrawer onExited={handlEventDetailDrawerExited} />
            </Route>
          </ChildrenOnValidEventAndCartCombination>
        )}
      </RenderOnData>
    </PageWithHeaderTemplate>
  )
}
