import {useQuery} from '@apollo/react-hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import {Drawer, drawerClasses, IconButton} from '@mui/material'
import {styled} from '@mui/system'
import {every, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  NarrowRetailPaymentMethodsQuery,
  NarrowRetailPaymentMethodsQueryVariables,
  PaymentMethodState,
  PaymentMethodType
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {
  useIsPositiveDecimal,
  useIsStringWithMaxLength
} from '../../../../../utils/formsValidations'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  InputRow
} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {Blank} from '../../../../visual/Blank'
import {NARROW_RETAIL_PAYMENT_METHODS} from '../../graphql'
import {useCreateCashDeskDeposit, useCreateCashDeskWithdrawal} from './graphql'
import {
  CashRegisterOperation,
  CashRegisterOperationFormField,
  ICashRegisterOperationForm
} from './types'
import {transformFormDataToPaymentInputs} from './utils'

const CREATE_CASH_REGISTER_OPERATION_FORM_ID = 'createCashRegisterOperationForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface ICashRegisterOperationDrawerProps {
  isOpen: boolean
  onClose: () => void
  operation: CashRegisterOperation
}

export const CashRegisterOperationDrawer: React.FC<ICashRegisterOperationDrawerProps> =
  ({isOpen, onClose, operation}: ICashRegisterOperationDrawerProps) => {
    const {t} = useTranslation()
    const [skip, setSkip] = useState(!isOpen)
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
      return () => setSkip(true)
    }, [isOpen])
    const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
      useForm<ICashRegisterOperationForm>()
    const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
      useMutationAssistanceHooks()
    const createCashDeskDeposit = useCreateCashDeskDeposit()
    const createCashDeskWithdrawal = useCreateCashDeskWithdrawal()
    const isPositiveDecimal = useIsPositiveDecimal('1,2')
    const stringWithMaxLength = useIsStringWithMaxLength(1000)
    const {
      data: retailPaymentMethodsData,
      loading: retailPaymentMethodsLoading,
      error: retailPaymentMethodsError
    } = useQuery<
      NarrowRetailPaymentMethodsQuery,
      NarrowRetailPaymentMethodsQueryVariables
    >(NARROW_RETAIL_PAYMENT_METHODS, {
      variables: {state: PaymentMethodState.Active},
      skip
    })
    const isSubmitDisabled = every(
      watch(CashRegisterOperationFormField.Amount),
      isEmpty
    )
    const onSubmit = useCallback(
      async (formData: ICashRegisterOperationForm) => {
        const note = formData[CashRegisterOperationFormField.Note] || undefined
        const paymentInputs = transformFormDataToPaymentInputs(formData)
        try {
          setShowBackdrop(true)
          if (operation === CashRegisterOperation.Deposit) {
            await createCashDeskDeposit({note, paymentInputs})
            addInfoNotification(t('Deposit successful'))
          } else {
            await createCashDeskWithdrawal({note, paymentInputs})
            addInfoNotification(t('Withdrawal successful'))
          }
          onClose()
        } catch (error) {
          defaultErrorHandler(
            error,
            operation === CashRegisterOperation.Deposit
              ? t('Error while creating cash desk deposit')
              : t('Error while creating cash desk withdrawal')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createCashDeskDeposit,
        createCashDeskWithdrawal,
        defaultErrorHandler,
        onClose,
        operation,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 360, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={retailPaymentMethodsLoading}
          errorMessage={
            retailPaymentMethodsError &&
            t<string>('Error while loading retail payment methods')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={
                operation === CashRegisterOperation.Deposit
                  ? t('Deposit')
                  : t('Withdrawal')
              }
            />
          }
          footer={
            <SaveButton
              type="submit"
              form={CREATE_CASH_REGISTER_OPERATION_FORM_ID}
              disabled={isSubmitDisabled}
            >
              {operation === CashRegisterOperation.Deposit
                ? t('Deposit funds')
                : t('Withdrawal funds')}
            </SaveButton>
          }
        >
          {retailPaymentMethodsData && (
            <>
              {retailPaymentMethodsData.retailPaymentMethods.length > 0 ? (
                <StyledForm
                  id={CREATE_CASH_REGISTER_OPERATION_FORM_ID}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {retailPaymentMethodsData.retailPaymentMethods
                    .filter(({type}) => type === PaymentMethodType.Cash)
                    .map(({id, name}) => (
                      <InputRow
                        key={id}
                        nodes={[
                          <UncontrolledFormTextInput<ICashRegisterOperationForm>
                            errors={errors}
                            setValue={setValue}
                            watch={watch}
                            register={register}
                            triggerValidation={triggerValidation}
                            name={`${CashRegisterOperationFormField.Amount}[${id}]`}
                            key={`${CashRegisterOperationFormField.Amount}[${id}]`}
                            label={name}
                            fullWidth
                            validationOptions={{validate: isPositiveDecimal}}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setValue(
                                      `${CashRegisterOperationFormField.Amount}[${id}]`,
                                      ''
                                    )
                                  }
                                >
                                  <CancelIcon />
                                </IconButton>
                              )
                            }}
                          />
                        ]}
                      />
                    ))}
                  <InputRow
                    nodes={[
                      <UncontrolledFormTextInput<ICashRegisterOperationForm>
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                        register={register}
                        triggerValidation={triggerValidation}
                        name={CashRegisterOperationFormField.Note}
                        key={CashRegisterOperationFormField.Note}
                        label={t('Note')}
                        fullWidth
                        multiline
                        rows={4}
                        validationOptions={{validate: stringWithMaxLength}}
                      />
                    ]}
                  />
                </StyledForm>
              ) : (
                <Blank title={t('No payment methods found')} />
              )}
            </>
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
