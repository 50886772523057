import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {IconButton} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {CarouselSlideRenderControlProps} from 'nuka-carousel'
import React from 'react'

const useStyles = makeStyles<Theme, {isDisabled: boolean}>((theme) => ({
  root: {
    width: '30vw',
    height: '90vh',
    cursor: ({isDisabled}) => (isDisabled ? 'auto' : 'pointer'),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3)
  }
}))

interface ICenterLeftControlsProps
  extends Pick<
    CarouselSlideRenderControlProps,
    'previousSlide' | 'currentSlide'
  > {
  className?: string
}

export const CenterLeftControls: React.FC<ICenterLeftControlsProps> = ({
  previousSlide: onPrevButtonClick,
  currentSlide,
  className
}: ICenterLeftControlsProps) => {
  const classes = useStyles({isDisabled: currentSlide === 0})
  return currentSlide !== 0 ? (
    <div className={classes.root} onClick={onPrevButtonClick}>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className={className}
        onClick={onPrevButtonClick}
        disabled={currentSlide === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
    </div>
  ) : null
}
