import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEventCategoryParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {EventCategoryForm} from './EventCategoryForm'
import {useGetEventCategory, useUpdateEventCategory} from './graphql'
import {
  EventCategoryFormField,
  EventCategoryState,
  IEventCategoryForm
} from './types'

const UPDATE_EVENT_CATEGORY_FORM_ID = 'updateEventCategoryForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  }
}))

interface IUpdateEventCategoryDrawerProps {
  onExited: () => void
}

export const UpdateEventCategoryDrawer: React.FC<IUpdateEventCategoryDrawerProps> =
  ({onExited}: IUpdateEventCategoryDrawerProps) => {
    const {t} = useTranslation()
    const {eventCategoryId} = useEventCategoryParams()
    const {data, loading, error} = useGetEventCategory(eventCategoryId)
    const updateEventCategory = useUpdateEventCategory()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (eventCategoryId) {
        openDrawer()
      }
    }, [eventCategoryId, openDrawer])
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: IEventCategoryForm) => {
        try {
          setShowBackdrop(true)
          await updateEventCategory({
            id: eventCategoryId,
            data: {
              ...data,
              isActive:
                data[EventCategoryFormField.IsActive] ===
                EventCategoryState.Active
            }
          })
          addInfoNotification(t('Event category updated'))
          onExited()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating event category'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        defaultErrorHandler,
        eventCategoryId,
        onExited,
        setShowBackdrop,
        t,
        updateEventCategory
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error && t<string>('Error while loading event category')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update event category')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_EVENT_CATEGORY_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
        >
          {data && (
            <EventCategoryForm
              formId={UPDATE_EVENT_CATEGORY_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={{
                [EventCategoryFormField.Name]: data.eventCategory.name,
                [EventCategoryFormField.Label]:
                  data.eventCategory.label || undefined,
                [EventCategoryFormField.IsActive]: data.eventCategory.isActive
                  ? EventCategoryState.Active
                  : EventCategoryState.Inactive
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
