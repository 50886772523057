import {useLazyQuery} from '@apollo/react-hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import {Chip} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightCustomerGroupsQuery,
  LightweightCustomerGroupsQueryVariables
} from '../../../../__generated__/schema'
import {Tooltip} from '../../../common'
import {LoadingSkeleton} from '../../../common/LoadingSkeleton'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'
import {LIGHTWEIGHT_CUSTOMER_GROUPS} from '../graphql'

interface ICustomerGroup {
  id: number
  name: string
}

interface ICustomerGroupChipProps {
  onChange: (customerGroupId?: number) => void
}

export const CustomerGroupChip: React.FC<ICustomerGroupChipProps> = ({
  onChange
}: ICustomerGroupChipProps) => {
  const {t} = useTranslation()
  const [customerGroups, setCustomerGroups] = useState<ICustomerGroup[]>([])
  const [selectedCustomerGroup, setSelectedCustomerGroup] =
    useState<ICustomerGroup | undefined>(undefined)
  const [getCustomerGroups, {loading}] = useLazyQuery<
    LightweightCustomerGroupsQuery,
    LightweightCustomerGroupsQueryVariables
  >(LIGHTWEIGHT_CUSTOMER_GROUPS, {
    onCompleted: (data) =>
      setCustomerGroups(
        data.customerGroups
          .sort((groupA, groupB) => groupA.name.localeCompare(groupB.name))
          .map(({id, name}) => ({id, name}))
      )
  })
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleDeleteChipClick = useCallback(() => {
    setSelectedCustomerGroup(undefined)
    onChange(undefined)
  }, [onChange])
  const handleChipClick = useCallback(
    (event) => {
      getCustomerGroups()
      openMenu(event)
    },
    [getCustomerGroups, openMenu]
  )
  const handleMenuItemClick = useCallback(
    (customerGroup: ICustomerGroup) => () => {
      setSelectedCustomerGroup(customerGroup)
      onChange(customerGroup.id)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  return (
    <>
      <Tooltip title={t('Select customer group')}>
        <Chip
          size="small"
          onClick={handleChipClick}
          variant="outlined"
          icon={<GroupsOutlinedIcon />}
          deleteIcon={
            selectedCustomerGroup ? <CancelIcon /> : <ArrowDropDownIcon />
          }
          onDelete={
            selectedCustomerGroup ? handleDeleteChipClick : handleChipClick
          }
          label={selectedCustomerGroup ? selectedCustomerGroup.name : undefined}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          customerGroups?.map((customerGroup) => (
            <MenuItem
              key={customerGroup.id}
              label={customerGroup.name}
              icon={
                customerGroup.id === selectedCustomerGroup?.id ? (
                  <CheckIcon />
                ) : undefined
              }
              isSelected={customerGroup.id === selectedCustomerGroup?.id}
              sx={{flexFlow: 'row-reverse'}}
              iconSx={{justifyContent: 'center'}}
              onClick={handleMenuItemClick(customerGroup)}
            />
          ))
        )}
      </Menu>
    </>
  )
}
