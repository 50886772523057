import {CartSearchLocation} from './types'

export const getStorageKey = (location: CartSearchLocation) => {
  switch (location) {
    case CartSearchLocation.Carts:
    default:
      return 'CARTS_LIST'
    case CartSearchLocation.CartsSupport:
      return 'SUPPORT_CARTS_LIST'
  }
}
