import {Button, ButtonGroupProps} from '@mui/material'
import {noop} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TemplatesQuery} from '../../../../__generated__/schema'
import {performCompiledClaimAction} from '../../../../utils/compiledTemplates'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useGetClaimPrintingTemplates} from '../hooks/getClaimPrintingTemplates'

interface IClaimPrintingGroupButtonProps {
  claimId: number
  variant?: ButtonGroupProps['variant']
  disabled?: boolean
  icon?: React.ReactNode
}

export const ClaimPrintingGroupButton: React.FC<IClaimPrintingGroupButtonProps> =
  ({
    claimId,
    variant = 'contained',
    disabled,
    icon
  }: IClaimPrintingGroupButtonProps) => {
    const {t} = useTranslation()
    const templates = useGetClaimPrintingTemplates()
    const getTemplateButtonClickHandler = useCallback(
      (template: TemplatesQuery['templates'][number]) => () => {
        performCompiledClaimAction({
          templateFileType: template.fileType,
          templateId: template.id,
          claimId
        })
      },
      [claimId]
    )
    return (
      <SplitButton
        variant={variant}
        disabled={disabled}
        disableElevation
        Options={
          templates.length
            ? templates.map((template) => (
                <MenuItem
                  key={template.id}
                  label={template.name}
                  icon={icon}
                  onClick={getTemplateButtonClickHandler(template)}
                />
              ))
            : [
                <MenuItem
                  key="no-templates"
                  label={t('No templates have been found')}
                  isDisabled
                />
              ]
        }
      >
        <Button
          variant={variant}
          color="primary"
          onClick={
            templates[0] ? getTemplateButtonClickHandler(templates[0]) : noop
          }
        >
          {t('Print')}
        </Button>
      </SplitButton>
    )
  }
