import DeleteIcon from '@mui/icons-material/Delete'
import {Divider} from '@mui/material'
import {useGridApiRef} from '@mui/x-data-grid-pro'
import {isNull} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  ClientVatRegistered,
  ErrorMessages,
  InventoryCheckQuery,
  InventoryCheckState,
  InventoryCheckType,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions, useUserInfo} from '../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {useWarehouseParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {ButtonWithConfirmationDialog, RenderOnData} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {InventoryCheckPrintingButtonGroup} from '../../components/InventoryCheckPrintingButtonGroup'
import {SecondaryHeader} from '../../Header'
import {WideCenteredLayout} from '../../Layout'
import {
  useCompleteInventoryCheck,
  useDeleteInventoryCheck,
  useInventoryCheck
} from '../graphql'
import {UpdateInventoryCheckDrawer} from '../UpdateInventoryCheckDrawer'
import {General} from './General'
import {Items} from './Items'

export const InventoryCheckDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const dataGridApiRef = useGridApiRef()
  const {effectiveClient} = useUserInfo()
  const {inventoryCheckId} = useWarehouseParams()
  const {data, loading, error} = useInventoryCheck(
    inventoryCheckId,
    isNaN(inventoryCheckId)
  )
  const deleteInventoryCheck = useDeleteInventoryCheck()
  const completeInventoryCheck = useCompleteInventoryCheck()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const [rowIdError, setRowIdError] = useState<number | undefined>(undefined)
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.inventoryChecks.index()),
    [history]
  )
  const handleExited = useCallback(
    () => history.push(routeTo.admin.inventoryChecks.detail(inventoryCheckId)),
    [history, inventoryCheckId]
  )
  const isVatRegistered =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None
  const isDeleteButtonVisible =
    P([PermissionCode.DeleteInventoryCheck]) &&
    (data?.inventoryCheck.state === InventoryCheckState.Draft ||
      data?.inventoryCheck.type === InventoryCheckType.Controlling)
  const isCompleteButtonVisible =
    P([PermissionCode.CompleteInventoryCheck]) &&
    data?.inventoryCheck.state === InventoryCheckState.Draft
  const isFooterVisible =
    isDeleteButtonVisible ||
    isCompleteButtonVisible ||
    P([PermissionCode.ReadTemplates])
  const handleDeleteButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteInventoryCheck(inventoryCheckId)
      addInfoNotification(t('Inventory check has been deleted'))
      history.replace(routeTo.admin.inventoryChecks.index())
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting inventory check'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteInventoryCheck,
    history,
    inventoryCheckId,
    setShowBackdrop,
    t
  ])
  const handleCompleteButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await completeInventoryCheck(inventoryCheckId)
      if (rowIdError) {
        setRowIdError(undefined)
      }
    } catch (error) {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.MissingRealStock
        )
      ) {
        const firstRowWithNullRealStock = (
          data?.inventoryCheck.inventoryCheckProducts || []
        ).find(({realStock}) => isNull(realStock))
        if (firstRowWithNullRealStock) {
          const rowElement = dataGridApiRef.current.getRowElement(
            firstRowWithNullRealStock.id
          )
          rowElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          })
          setRowIdError(firstRowWithNullRealStock.id)
          dataGridApiRef.current.setCellFocus(
            firstRowWithNullRealStock.id,
            'realStock'
          )
        }
      } else {
        defaultErrorHandler(error, t('Error while completing inventory check'))
      }
    } finally {
      setShowBackdrop(false)
    }
  }, [
    completeInventoryCheck,
    data?.inventoryCheck.inventoryCheckProducts,
    dataGridApiRef,
    defaultErrorHandler,
    inventoryCheckId,
    rowIdError,
    setShowBackdrop,
    t
  ])
  useEffect(() => {
    if (data) {
      setRowIdError(undefined)
    }
  }, [data])
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={t('Inventory check #{{id}}', {id: inventoryCheckId})}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        isFooterVisible && (
          <Footer>
            {P([PermissionCode.ReadTemplates]) && data && (
              <>
                <InventoryCheckPrintingButtonGroup
                  inventoryCheckId={data.inventoryCheck.id}
                />
                {(isDeleteButtonVisible || isCompleteButtonVisible) && (
                  <Divider orientation="vertical" sx={{height: 32}} />
                )}
              </>
            )}
            {isDeleteButtonVisible && (
              <ButtonWithConfirmationDialog
                onConfirmButtonClick={handleDeleteButtonClick}
                dialogProps={{
                  confirmButtonLabel: t('Delete'),
                  title: t('Delete inventory check?'),
                  contentText: t(
                    'Deleting this inventory check is permanent and cannot be reversed. Are you sure you want to proceed?'
                  )
                }}
                buttonProps={{
                  startIcon: <DeleteIcon />,
                  children: t('Delete'),
                  color: 'primary'
                }}
              />
            )}
            {isCompleteButtonVisible && (
              <ButtonWithConfirmationDialog
                onConfirmButtonClick={handleCompleteButtonClick}
                dialogProps={{
                  confirmButtonLabel: t('Complete'),
                  title: t('Complete inventory check?'),
                  contentText: t(
                    'Completing this inventory check is permanent and cannot be reversed. Are you sure you want to proceed?'
                  )
                }}
                buttonProps={{
                  children: t('Complete'),
                  color: 'primary',
                  variant: 'contained'
                }}
              />
            )}
          </Footer>
        )
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<InventoryCheckQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading inventory check')}
          dataCondition={(data) => Boolean(data.inventoryCheck)}
        >
          {({inventoryCheck}) => (
            <>
              <WideCenteredLayout
                sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 5}}
              >
                <General
                  inventoryCheck={inventoryCheck}
                  isVatRegistered={isVatRegistered}
                />
                <Items
                  products={inventoryCheck.inventoryCheckProducts}
                  isVatRegistered={isVatRegistered}
                  isEditable={
                    inventoryCheck.state === InventoryCheckState.Draft &&
                    P([PermissionCode.UpdateInventoryCheckProduct])
                  }
                  inventoryCheckId={inventoryCheck.id}
                  dataGridApiRef={dataGridApiRef}
                  rowIdError={rowIdError}
                />
              </WideCenteredLayout>
              {P([PermissionCode.UpdateInventoryCheck]) && (
                <Route
                  path={routeTo.admin.inventoryChecks.edit(':inventoryCheckId')}
                  exact
                >
                  <UpdateInventoryCheckDrawer
                    inventoryCheck={inventoryCheck}
                    onExited={handleExited}
                  />
                </Route>
              )}
            </>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
