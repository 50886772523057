import {useMutation, useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import Joi from 'joi'
import isUndefined from 'lodash/isUndefined'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {
  AdmissionRateState,
  GetLightweightCostCentersQuery,
  GetLightweightCostCentersQueryVariables,
  GetLightweightEventCategoriesQuery,
  GetLightweightEventCategoriesQueryVariables,
  GetLightweightMarketingLabelsQuery,
  GetLightweightMarketingLabelsQueryVariables,
  GetTourShowTypeCodeQuery,
  GetTourShowTypeCodeQueryVariables,
  LightweightAdmissionRatesQuery,
  LightweightAdmissionRatesQueryVariables,
  LightweightUsersQuery,
  LightweightUsersQueryVariables,
  LightweightVenuesQuery,
  LightweightVenuesQueryVariables,
  UpdateTourTimeSlotsMutation,
  UpdateTourTimeSlotsMutationVariables
} from '../../../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../../../hooks/getLocales'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {useTourParams} from '../../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {
  GET_COST_CENTERS,
  GET_EVENT_CATEGORIES,
  GET_LIGHTWEIGHT_USERS,
  GET_MARKETING_LABELS,
  GET_TOUR_SHOW_TYPE_CODE,
  LIGHTWEIGHT_ADMISSION_RATES,
  LIGHTWEIGHT_VENUES
} from '../../../graphql'
import {
  IUpdateTourTimeSlotsForm,
  UpdateTourTimeSlotsBooleanOption,
  UpdateTourTimeSlotsFormField,
  UpdateTourTimeSlotsStaticOption
} from '../../types'
import {transformUpdateTourTimeSlotsFormDataToInput} from '../../utils'
import {UPDATE_TOUR_TIME_SLOTS_MUTATION} from './graphql'
import {UpdateSlotsForm} from './UpdateSlotsForm'

const UPDATE_TOUR_TIME_SLOTS_FORM_ID = 'updateTourTimeSlotsForm'

const updateTimeSlotsSchema = Joi.object<{itemIds: number[]}>({
  itemIds: Joi.array()
    .required()
    .min(1)
    .items(Joi.number().integer().positive().required())
})

const validateSchema = (
  itemIds: string
): Joi.ValidationResult<{itemIds: number[]}> =>
  updateTimeSlotsSchema.validate({itemIds: itemIds.split(',')})

interface IUpdateSlotsDrawerProps {
  onExited: () => void
  refetch: () => void
}

export const UpdateSlotsDrawer: React.FC<IUpdateSlotsDrawerProps> = ({
  onExited,
  refetch
}: IUpdateSlotsDrawerProps) => {
  const {t} = useTranslation()
  const {tourId} = useTourParams()
  const [updateTourTimeSlots] = useMutation<
    UpdateTourTimeSlotsMutation,
    UpdateTourTimeSlotsMutationVariables
  >(UPDATE_TOUR_TIME_SLOTS_MUTATION)
  const {
    data: admissionRatesData,
    loading: admissionRatesLoading,
    error: admissionRatesError
  } = useQuery<
    LightweightAdmissionRatesQuery,
    LightweightAdmissionRatesQueryVariables
  >(LIGHTWEIGHT_ADMISSION_RATES, {
    variables: {
      paginationInput: {offset: 0, limit: 300},
      filter: {states: [AdmissionRateState.Active], tourId}
    },
    fetchPolicy: 'network-only'
  })
  const {
    data: tourShowTypeCodeData,
    loading: tourShowTypeCodeLoading,
    error: tourShowTypeCodeError
  } = useQuery<GetTourShowTypeCodeQuery, GetTourShowTypeCodeQueryVariables>(
    GET_TOUR_SHOW_TYPE_CODE,
    {variables: {id: tourId}, fetchPolicy: 'network-only'}
  )
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError
  } = useQuery<LightweightUsersQuery, LightweightUsersQueryVariables>(
    GET_LIGHTWEIGHT_USERS,
    {
      fetchPolicy: 'network-only'
    }
  )
  const {
    data: costCentersData,
    loading: costCentersLoading,
    error: costCentersError
  } = useQuery<
    GetLightweightCostCentersQuery,
    GetLightweightCostCentersQueryVariables
  >(GET_COST_CENTERS, {
    fetchPolicy: 'network-only'
  })
  const {
    data: marketingLabelsData,
    loading: marketingLabelsLoading,
    error: marketingLabelsError
  } = useQuery<
    GetLightweightMarketingLabelsQuery,
    GetLightweightMarketingLabelsQueryVariables
  >(GET_MARKETING_LABELS, {
    fetchPolicy: 'network-only'
  })
  const {
    data: eventCategoriesData,
    loading: eventCategoriesLoading,
    error: eventCategoriesError
  } = useQuery<
    GetLightweightEventCategoriesQuery,
    GetLightweightEventCategoriesQueryVariables
  >(GET_EVENT_CATEGORIES, {
    fetchPolicy: 'network-only'
  })
  const {
    data: venuesData,
    loading: venuesLoading,
    error: venuesError
  } = useQuery<LightweightVenuesQuery, LightweightVenuesQueryVariables>(
    LIGHTWEIGHT_VENUES,
    {
      fetchPolicy: 'network-only'
    }
  )
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const location = useLocation()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const clientLocales = useGetClientLocales()
  const params = new URLSearchParams(location.search)
  const validationResult = validateSchema(params.get('itemIds') || '')
  const {itemIds} = validationResult.value || {}
  useEffect(() => {
    if (tourId && !validationResult.error) {
      openDrawer()
    }
  }, [tourId, openDrawer, validationResult.error])
  const handleSubmit = useCallback(
    async (formData: IUpdateTourTimeSlotsForm) => {
      const input = transformUpdateTourTimeSlotsFormDataToInput(formData)
      const hasChange =
        Object.values(input).filter((v) => !isUndefined(v)).length > 0
      if (itemIds && hasChange) {
        try {
          setShowBackdrop(true)
          await updateTourTimeSlots({
            variables: {
              ids: itemIds,
              input
            }
          })
          addInfoNotification(t('Tour time slots updated'))
          refetch()
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating tour time slots'))
        } finally {
          setShowBackdrop(false)
        }
      } else {
        closeDrawer()
      }
    },
    [
      addInfoNotification,
      closeDrawer,
      defaultErrorHandler,
      itemIds,
      refetch,
      setShowBackdrop,
      t,
      updateTourTimeSlots
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        isLoading={
          admissionRatesLoading ||
          usersLoading ||
          costCentersLoading ||
          marketingLabelsLoading ||
          eventCategoriesLoading ||
          venuesLoading ||
          tourShowTypeCodeLoading
        }
        errorMessage={
          (admissionRatesError ||
            usersError ||
            costCentersError ||
            marketingLabelsError ||
            eventCategoriesError ||
            venuesError ||
            tourShowTypeCodeError) &&
          t<string>('Error while loading resources')
        }
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update tour time slots')}
          />
        }
        footer={
          <SaveButton type="submit" form={UPDATE_TOUR_TIME_SLOTS_FORM_ID}>
            {t('Save')}
          </SaveButton>
        }
      >
        {itemIds &&
          admissionRatesData &&
          usersData &&
          costCentersData &&
          marketingLabelsData &&
          eventCategoriesData &&
          venuesData &&
          tourShowTypeCodeData && (
            <UpdateSlotsForm
              formId={UPDATE_TOUR_TIME_SLOTS_FORM_ID}
              onSubmit={handleSubmit}
              admissionRates={admissionRatesData.admissionRates.items}
              users={usersData.users}
              costCenters={costCentersData.costCenters}
              marketingLabels={marketingLabelsData.marketingLabels}
              eventCategories={eventCategoriesData.eventCategories}
              venues={venuesData.venues}
              showTypeCode={tourShowTypeCodeData.tour.show.typeCode}
              clientLocales={clientLocales}
              defaultValues={{
                [UpdateTourTimeSlotsFormField.AdmissionRateId]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.State]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.TicketNoteOption]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.RetailSaleOption]:
                  UpdateTourTimeSlotsBooleanOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.RetailReservationOption]:
                  UpdateTourTimeSlotsBooleanOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.RetailAttendeesLimitOption]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.ECommerceSaleOption]:
                  UpdateTourTimeSlotsBooleanOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.ECommerceReservationOption]:
                  UpdateTourTimeSlotsBooleanOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.ShowOnWebsiteAndApi]:
                  UpdateTourTimeSlotsBooleanOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.ECommerceAttendeesLimitOption]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimitOption]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.VersionCode]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.GuideId]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.CostCenterId]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.MarketingLabelId]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.EventCategoryId]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.AgeClassificationCode]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.VenueId]:
                  UpdateTourTimeSlotsStaticOption.DoNotChange,
                [UpdateTourTimeSlotsFormField.NamesOption]:
                  clientLocales.reduce(
                    (acc, locale) => ({
                      ...acc,
                      [locale]: UpdateTourTimeSlotsStaticOption.DoNotChange
                    }),
                    {}
                  )
              }}
            />
          )}
      </DrawerTemplate>
    </Drawer>
  )
}
