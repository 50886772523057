import {styled} from '@mui/system'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {AdmissionTypeState} from '../../../../__generated__/schema'
import {useGetECommerceSettingsFieldsLabel} from '../../../../hooks/eCommerceSettingsFieldsLabel'
import {useGetClientLocales} from '../../../../hooks/getLocales'
import {
  useIsNonNegativeInteger,
  useIsPositiveInteger,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {useAdmissionTypeIconSelectOptions} from '../../../common/admissionTypeIcon'
import {CollapseSection} from '../../../common/CollapseSection'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormColorInput} from '../../../form/UncontrolledFormColorInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {AdmissionTypeFormField, IAdmissionTypeForm} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3)
}))

interface IAdmissionTypeFormProps {
  formId: string
  onSubmit: (data: IAdmissionTypeForm) => void
  state?: AdmissionTypeState
}

export const AdmissionTypeForm: React.FC<IAdmissionTypeFormProps> = ({
  formId,
  onSubmit,
  state
}: IAdmissionTypeFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useFormContext<IAdmissionTypeForm>()
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength30 = useIsStringWithMaxLength(30)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isNonNegativeInteger = useIsNonNegativeInteger()
  const isPositiveInteger = useIsPositiveInteger()
  const clientLocales = useGetClientLocales()
  const getECommerceSettingsFieldsLabel = useGetECommerceSettingsFieldsLabel()
  const iconSelectOptions = useAdmissionTypeIconSelectOptions()
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionTypeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Name')}
            name={AdmissionTypeFormField.Name}
            key={AdmissionTypeFormField.Name}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength255
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionTypeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Abbreviation')}
            name={AdmissionTypeFormField.Abbreviation}
            key={AdmissionTypeFormField.Abbreviation}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength30
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionTypeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Attendees per admission type')}
            name={AdmissionTypeFormField.CapacityDecreaseCount}
            key={AdmissionTypeFormField.CapacityDecreaseCount}
            validationOptions={{
              required: true,
              validate: isNonNegativeInteger
            }}
            required
            fullWidth
            disabled={
              state === AdmissionTypeState.Active ||
              state === AdmissionTypeState.Inactive
            }
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionTypeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Minimal quantity per order')}
            name={AdmissionTypeFormField.StartingQuantity}
            key={AdmissionTypeFormField.StartingQuantity}
            validationOptions={{
              required: true,
              validate: isPositiveInteger
            }}
            helperNote={t(
              'It can’t be added to cart less tickets than specified number.'
            )}
            required
            fullWidth
            disabled={
              state === AdmissionTypeState.Active ||
              state === AdmissionTypeState.Inactive
            }
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormColorInput<IAdmissionTypeForm>
            errors={errors}
            triggerValidation={triggerValidation}
            watch={watch}
            register={register}
            label={t('Color')}
            setValue={setValue}
            name={AdmissionTypeFormField.Color}
            key={AdmissionTypeFormField.Color}
            colorPickerType="compact"
            validationOptions={{
              required: true
            }}
            helperNote={t('Will be displayed for cashiers.')}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IAdmissionTypeForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Icon')}
            setValue={setValue}
            name={AdmissionTypeFormField.Icon}
            key={AdmissionTypeFormField.Icon}
            fullWidth
            hasNoneSelectOption
            selectOptions={iconSelectOptions}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IAdmissionTypeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Internal description')}
            name={AdmissionTypeFormField.InternalDescription}
            key={AdmissionTypeFormField.InternalDescription}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
      <CollapseSection title={t('Ecommerce settings')} expanded>
        {clientLocales.map((locale) => (
          <React.Fragment key={locale}>
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<IAdmissionTypeForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={`${AdmissionTypeFormField.ECommerceNames}[${locale}]`}
                  key={`${AdmissionTypeFormField.ECommerceNames}[${locale}]`}
                  label={getECommerceSettingsFieldsLabel(locale).name}
                  validationOptions={{
                    validate: isStringWithMaxLength255
                  }}
                  fullWidth
                />
              ]}
            />
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<IAdmissionTypeForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={`${AdmissionTypeFormField.ECommerceDescriptions}[${locale}]`}
                  key={`${AdmissionTypeFormField.ECommerceDescriptions}[${locale}]`}
                  label={getECommerceSettingsFieldsLabel(locale).description}
                  validationOptions={{
                    validate: isStringWithMaxLength1000
                  }}
                  fullWidth
                  multiline
                  rows={3}
                />
              ]}
            />
          </React.Fragment>
        ))}
      </CollapseSection>
    </StyledForm>
  )
}
