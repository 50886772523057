import {
  CashDrawerController,
  CashDrawerOpenLocation,
  ICashDrawerSettings
} from '../types'
import {LocalStorageKey, useLocalStorageState} from './storage'

export const newAbortSignal = (timeout: number) => {
  const abortController = new AbortController()
  setTimeout(() => abortController.abort(), timeout || 0)
  return abortController.signal
}

export const useOpenCashDrawer = () => {
  const [cashDrawer] = useLocalStorageState<ICashDrawerSettings>(
    LocalStorageKey.CashDrawer,
    {
      controller: CashDrawerController.None,
      openLocation: CashDrawerOpenLocation.DontOpen,
      ipAddress: null
    }
  )
  const {controller, openLocation, ipAddress} = cashDrawer
  return (location: CashDrawerOpenLocation) => {
    if (
      controller === CashDrawerController.VirtuosNetworkController &&
      openLocation === location &&
      ipAddress
    ) {
      fetch(`https://${ipAddress}`, {signal: newAbortSignal(100)}).catch(
        (e) => e
      )
    }
  }
}
