import gql from 'graphql-tag'
import {uniqBy} from 'lodash'
import {
  WarehouseProductsFilterInput,
  WarehouseProductsQuery,
  WarehouseProductsQueryVariables
} from '../../../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../../utils/pagination'
import {PAGINATION_FRAGMENT} from '../../../graphql'

export const BASE_WAREHOUSE_PRODUCT_FIELDS = gql`
  fragment BaseWarehouseProductFields on WarehouseProduct {
    id
    stock
    product {
      id
      name
      unit
      internalCode
      pricings {
        activePricing {
          id
          retailPrice
        }
      }
    }
  }
`

const WAREHOUSE_PRODUCTS = gql`
  ${BASE_WAREHOUSE_PRODUCT_FIELDS}
  ${PAGINATION_FRAGMENT}
  query WarehouseProducts(
    $filter: WarehouseProductsFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouseProducts(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...BaseWarehouseProductFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useWarehouseProducts = (
  filter?: WarehouseProductsFilterInput,
  skip?: boolean
) =>
  useQueryWithPagination<
    WarehouseProductsQuery,
    WarehouseProductsQueryVariables
  >(
    WAREHOUSE_PRODUCTS,
    {
      variables: {filter, paginationInput: {offset: 0, limit: 20}},
      fetchPolicy: 'network-only',
      skip
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.warehouseProducts.pagination),
      updateData: (prevData, fetchMoreData) => ({
        ...fetchMoreData,
        warehouseProducts: {
          ...fetchMoreData.warehouseProducts,
          items: uniqBy(
            [
              ...prevData.warehouseProducts.items,
              ...fetchMoreData.warehouseProducts.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )
