import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {DetailLibraryShowPropertiesFragment} from '../../../../../__generated__/schema'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useGetShowTitle} from '../../../../../utils/translations'

import {ShowSection} from '../../../../constants'
import {useBackdropState} from '../../../../context/backdrop'
import {useNotifications} from '../../../../context/notifications'
import {useUpsertLibraryShowTranslations} from '../graphql'
import {ShowCenteredLayoutWithSingleNavigation} from '../ShowCenteredLayoutWithNavigation'
import {ShowTranslationsForm, useShowFormStyles} from '../ShowTranslationsForm'
import {ITranslationsForm, TranslationFormField} from '../types'
import {useShowTranslationsFormAnchors} from '../useShowTranslationssFormAnchors'
import {useShowTranslationsFormDefaultValues} from '../utils'
import {EditLibraryPageDetailTemplate} from './EditLibraryPageDetailTemplate'

const EDIT_LIBRARY_TRANSLATIONS_FORM_ID = 'editLibraryTranslationsFormId'

interface IEditLibraryShowTranslationsProps {
  libraryShow: DetailLibraryShowPropertiesFragment
}

export const EditLibraryShowTranslations: React.FC<IEditLibraryShowTranslationsProps> =
  ({libraryShow}: IEditLibraryShowTranslationsProps) => {
    const {translations} = libraryShow
    const showFormClasses = useShowFormStyles()
    const history = useHistory()
    const {t} = useTranslation()
    const handleClose = useCallback(() => {
      history.goBack()
    }, [history])

    const defaultValues = useShowTranslationsFormDefaultValues({
      translations
    })

    const upsertLibraryShowTranslations = useUpsertLibraryShowTranslations(
      libraryShow.id
    )
    const defaultErrorHandler = useDefaultErrorHandler()
    const {setShowBackdrop} = useBackdropState()
    const {addInfoNotification} = useNotifications()

    const handleSubmit = useCallback(
      async (formData: ITranslationsForm) => {
        setShowBackdrop(true)
        try {
          await upsertLibraryShowTranslations({
            id: libraryShow.id,
            data: formData[TranslationFormField.TRANSLATIONS].filter(
              (translation) =>
                translation.title ||
                translation.tagline ||
                translation.description
            )
          })
          addInfoNotification(t('Library show updated'))
        } catch (e) {
          defaultErrorHandler(
            e,
            t('Error while updating library show translations')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        defaultErrorHandler,
        libraryShow.id,
        setShowBackdrop,
        t,
        upsertLibraryShowTranslations
      ]
    )

    const getShowTitle = useGetShowTitle()
    const showTitle = getShowTitle({
      translations: libraryShow.translations,
      originalTitle: libraryShow.originalTitle,
      fallbackTitle: t('Library show')
    })
    const showTranslationsFormAnchors = useShowTranslationsFormAnchors()
    return (
      <EditLibraryPageDetailTemplate
        showName={showTitle}
        onClose={handleClose}
        formId={EDIT_LIBRARY_TRANSLATIONS_FORM_ID}
        currentTab={ShowSection.Translations}
        isFooterVisible
      >
        <ShowCenteredLayoutWithSingleNavigation
          items={showTranslationsFormAnchors}
        >
          <ShowTranslationsForm
            formId={EDIT_LIBRARY_TRANSLATIONS_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [TranslationFormField.TRANSLATIONS]: defaultValues
            }}
            className={showFormClasses.form}
          />
        </ShowCenteredLayoutWithSingleNavigation>
      </EditLibraryPageDetailTemplate>
    )
  }
