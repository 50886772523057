import {Box, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {CashDeskProductGroupChip} from '../../../common/CashDeskProductGroupChip'
import {
  getProductGroupIcon,
  ProductGroupIcon,
  useProductGroupIconSelectOptions
} from '../../../common/productGroupIcon'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormColorInput} from '../../../form/UncontrolledFormColorInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {AdmissionTypeFormField} from '../admissionTypes/types'
import {IProductGroupForm, ProductGroupFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IProductGroupFormProps {
  formId: string
  onSubmit: (data: IProductGroupForm) => Promise<void>
  defaultValues?: Partial<IProductGroupForm>
}

export const ProductGroupForm: React.FC<IProductGroupFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IProductGroupFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IProductGroupForm>({defaultValues})
  const iconSelectOptions = useProductGroupIconSelectOptions()
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const color = watch(ProductGroupFormField.Color)
  const icon = watch(ProductGroupFormField.Icon)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IProductGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductGroupFormField.Name}
            key={ProductGroupFormField.Name}
            label={t('Name')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormColorInput<IProductGroupForm>
            errors={errors}
            triggerValidation={triggerValidation}
            watch={watch}
            register={register}
            label={t('Color')}
            setValue={setValue}
            name={ProductGroupFormField.Color}
            key={ProductGroupFormField.Color}
            colorPickerType="compact"
            validationOptions={{
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IProductGroupForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Icon')}
            setValue={setValue}
            name={ProductGroupFormField.Icon}
            key={ProductGroupFormField.Icon}
            fullWidth
            hasNoneSelectOption
            selectOptions={iconSelectOptions}
          />
        ]}
      />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
        <Typography variant="subtitle2" color="textSecondary">
          {t('Preview in cash desk')}
        </Typography>
        <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
          <CashDeskProductGroupChip
            label={t('Active')}
            hexColor={color}
            Icon={
              icon ? getProductGroupIcon(icon as ProductGroupIcon) : undefined
            }
            isActive
          />
          <CashDeskProductGroupChip
            label={t('Inactive')}
            hexColor={color}
            Icon={
              icon ? getProductGroupIcon(icon as ProductGroupIcon) : undefined
            }
          />
        </Box>
      </Box>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IProductGroupForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Internal description')}
            name={ProductGroupFormField.InternalDescription}
            key={AdmissionTypeFormField.InternalDescription}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
    </StyledForm>
  )
}
