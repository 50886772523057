import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateMarketingLabelMutation,
  CreateMarketingLabelMutationVariables,
  MarketingLabelQuery,
  MarketingLabelQueryVariables,
  MarketingLabelsQuery,
  MarketingLabelsQueryVariables,
  UpdateMarketingLabelMutation,
  UpdateMarketingLabelMutationVariables
} from '../../../../__generated__/schema'

export const MARKETING_LABEL_FIELDS = gql`
  fragment MarketingLabelFields on MarketingLabel {
    id
    name
    label
    isActive
  }
`

const GET_MARKETING_LABELS = gql`
  query marketingLabels {
    marketingLabels {
      ...MarketingLabelFields
    }
  }
  ${MARKETING_LABEL_FIELDS}
`

export const useGetMarketingLabels = () =>
  useQuery<MarketingLabelsQuery, MarketingLabelsQueryVariables>(
    GET_MARKETING_LABELS,
    {fetchPolicy: 'network-only'}
  )

const CREATE_MARKETING_LABEL = gql`
  mutation createMarketingLabel($data: MarketingLabelInput!) {
    createMarketingLabel(data: $data) {
      id
    }
  }
`

export const useCreateMarketingLabel = () => {
  const [createMarketingLabel] = useMutation<
    CreateMarketingLabelMutation,
    CreateMarketingLabelMutationVariables
  >(CREATE_MARKETING_LABEL)
  return useCallback(
    (variables: CreateMarketingLabelMutationVariables) =>
      createMarketingLabel({
        variables,
        refetchQueries: [{query: GET_MARKETING_LABELS}]
      }),
    [createMarketingLabel]
  )
}

const GET_MARKETING_LABEL = gql`
  query marketingLabel($id: Int!) {
    marketingLabel(id: $id) {
      ...MarketingLabelFields
    }
  }
  ${MARKETING_LABEL_FIELDS}
`

export const useGetMarketingLabel = (id: number) =>
  useQuery<MarketingLabelQuery, MarketingLabelQueryVariables>(
    GET_MARKETING_LABEL,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

const UPDATE_MARKETING_LABEL = gql`
  mutation updateMarketingLabel($id: Int!, $data: MarketingLabelInput!) {
    updateMarketingLabel(id: $id, data: $data) {
      ...MarketingLabelFields
    }
  }
  ${MARKETING_LABEL_FIELDS}
`

export const useUpdateMarketingLabel = () => {
  const [updateMarketingLabel] = useMutation<
    UpdateMarketingLabelMutation,
    UpdateMarketingLabelMutationVariables
  >(UPDATE_MARKETING_LABEL)
  return useCallback(
    (variables: UpdateMarketingLabelMutationVariables) =>
      updateMarketingLabel({
        variables
      }),
    [updateMarketingLabel]
  )
}
