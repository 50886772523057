import {Box, Button, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ClientDeactivateReason,
  ClientState,
  DetailClientPropertiesFragment
} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {enumToFormSelectItems} from '../../../../utils/forms'
import {EntityStateChip} from '../../../common/EntityState'
import {
  IActionButton,
  IOpenModalButtonProps,
  StateChangeModal
} from '../../../common/StateChangeModal'
import {StatusBarLayout} from '../../../common/StatusBarLayout'
import {clientStateColors, useClientStateTranslations} from '../../../constants'
import {useNotifications} from '../../../context/notifications'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {useActivateClient, useDeactivateClient} from './graphql'

interface IStateActionsBar {
  client: DetailClientPropertiesFragment
}

interface IDeactivateClientFormData {
  reason: ClientDeactivateReason
}

const useDeactivateStyles = makeStyles<Theme>((theme) => ({
  reasonSelect: {
    marginTop: theme.spacing(2),
    width: 280
  }
}))

const DeactivateClientButton: React.FC<IActionButton> = ({
  id
}: IActionButton) => {
  const {t} = useTranslation()
  const deactivateClient = useDeactivateClient()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {register, handleSubmit, setValue, watch, errors} =
    useForm<IDeactivateClientFormData>()
  const classes = useDeactivateStyles()

  const onSubmit = async (data: IDeactivateClientFormData) => {
    try {
      await deactivateClient(id, data.reason || ClientDeactivateReason.Reason)
      addInfoNotification(t('Client was deactivated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while deactivating the client.'))
    }
  }

  return (
    <StateChangeModal
      onSubmit={handleSubmit(onSubmit)}
      header={t('Do you really want to deactivate the client?')}
      message={t('What is the reason for deactivation?')}
      content={
        <form autoComplete="off" style={{width: '100%'}}>
          <UncontrolledFormSelect<IDeactivateClientFormData>
            label={t('Reason')}
            className={classes.reasonSelect}
            register={register}
            fullWidth
            name="reason"
            defaultValue={ClientDeactivateReason.Reason}
            selectOptions={enumToFormSelectItems(ClientDeactivateReason)}
            setValue={setValue}
            watch={watch}
            errors={errors}
          />
        </form>
      }
      submitButtonText={t('Dectivate')}
      OpenModalButton={({onClick}) => (
        <Button variant="outlined" color="primary" onClick={onClick}>
          {t('Deactivate client')}
        </Button>
      )}
    />
  )
}

const ActivateClientButton: React.FC<IActionButton> = ({id}: IActionButton) => {
  const {t} = useTranslation()
  const activateClient = useActivateClient()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = async () => {
    try {
      await activateClient(id)
      addInfoNotification(t('Client was activated.'))
    } catch (err) {
      defaultErrorHandler(err, t('Error while activating the client.'))
    }
  }

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button variant="outlined" color="primary" onClick={onClick}>
        {t('Activate client')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to deactivate the client?')}
      message={t('Are you sure you want to activate the client?')}
      submitButtonText={t('Activate')}
      OpenModalButton={OpenModalButton}
    />
  )
}

interface IBottomContentProps {
  client: DetailClientPropertiesFragment
}

const BottomContent: React.FC<IBottomContentProps> = ({
  client
}: IBottomContentProps) => {
  const {state, deactivateReason} = client
  const {t} = useTranslation()
  const clientStateTranslations = useClientStateTranslations()

  const reasonText = deactivateReason
    ? {
        [ClientDeactivateReason.Other]: t('Other'),
        [ClientDeactivateReason.Reason]: t('TODO: reason')
      }[deactivateReason]
    : null

  const config = {
    [ClientState.Active]: {
      chipLabel: clientStateTranslations.active,
      desc: t('Client is active.'),
      style: clientStateColors.active
    },
    [ClientState.Inactive]: {
      chipLabel: clientStateTranslations.inactive,
      desc: (
        <>
          <Box>{t('Client is inactive.')}</Box>
          <Box fontWeight="fontWeightBold" component="span">
            {t('Reason:')}
          </Box>
          &nbsp;
          {reasonText}
        </>
      ),
      style: clientStateColors.inactive
    },
    [ClientState.Potential]: {
      chipLabel: clientStateTranslations.potential,
      desc: t('Client is potential.'),
      style: clientStateColors.potential
    }
  }[state]

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <EntityStateChip label={config.chipLabel} colorConf={config.style} />
        </Grid>
        <Grid item>
          <Typography variant="caption" component="span">
            {config.desc}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export const StateActionsBar: React.FC<IStateActionsBar> = ({
  client
}: IStateActionsBar) => (
  <StatusBarLayout
    rightSideContent={
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          {client.state === ClientState.Active ? (
            <DeactivateClientButton id={client.id} />
          ) : (
            <ActivateClientButton id={client.id} />
          )}
        </Grid>
      </Grid>
    }
    bottomContent={<BottomContent client={client} />}
    title={client.name}
    iconType="client"
  />
)
