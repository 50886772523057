import {useTranslation} from 'react-i18next'

import {LeadOptionType} from '../../../__generated__/schema'

export const useTranslateDivisionType = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<LeadOptionType, string> = {
    [LeadOptionType.Retail]: t<string>('LeadOptionType->Retail'),
    [LeadOptionType.RetailReservation]: t<string>(
      'LeadOptionType->RetailReservation'
    ),
    [LeadOptionType.ECommerce]: t<string>('LeadOptionType->ECommerce'),
    [LeadOptionType.ECommerceReservation]: t<string>(
      'LeadOptionType->ECommerceReservation'
    )
  }
  return (code: LeadOptionType) => translatedCodes[code]
}
