import ListIcon from '@mui/icons-material/List'
import {ToggleButton, ToggleButtonGroup, ToggleButtonProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  FullscreenIcon,
  ZoomInIcon,
  ZoomOutIcon
} from '../../../editor/TopPanel/Icons'
import {useTranslateZonePlanView} from '../../../hooks/translateZonePlanView'
import {Theme} from '../../../theme'
import {ZonePlanView} from '../../../types'
import {Tooltip} from '../index'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'row',
    gap: theme.spacing(1),
    filter:
      'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));'
  },
  buttonRoot: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    color: theme.palette.text.secondary
  }
}))

interface IZoomButtonProps extends ToggleButtonProps {
  tooltipTitle: string
}

export const ZoomButton: React.FC<IZoomButtonProps> = ({
  tooltipTitle,
  ...props
}: IZoomButtonProps) => {
  const classes = useStyles()
  return (
    <Tooltip title={tooltipTitle}>
      <span>
        <ToggleButton
          classes={{
            root: classes.buttonRoot
          }}
          {...props}
        />
      </span>
    </Tooltip>
  )
}

interface IVerticalZoomPanelProps {
  onZoomInClick: () => void
  onZoomOutClick: () => void
  onFitToScreenClick: () => void
  className?: string
  isZoomOutDisabled: boolean
  isZoomInDisabled: boolean
  getZoneViewChangeHandler?: (zonePlan: ZonePlanView) => () => void
}

export const VerticalZoomPanel: React.FC<IVerticalZoomPanelProps> = ({
  onZoomInClick,
  onZoomOutClick,
  onFitToScreenClick,
  className,
  isZoomOutDisabled,
  isZoomInDisabled,
  getZoneViewChangeHandler
}: IVerticalZoomPanelProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translateZonePlanView = useTranslateZonePlanView()
  return (
    <ToggleButtonGroup
      size="small"
      exclusive
      orientation="vertical"
      className={cn(classes.root, className)}
    >
      {getZoneViewChangeHandler && (
        <ZoomButton
          tooltipTitle={translateZonePlanView(ZonePlanView.ListView)}
          onClick={getZoneViewChangeHandler(ZonePlanView.ListView)}
          value={0}
        >
          <ListIcon />
        </ZoomButton>
      )}
      <ZoomButton
        tooltipTitle={t<string>('Zoom in')}
        onClick={onZoomInClick}
        disabled={isZoomInDisabled}
        value={1}
      >
        <ZoomInIcon />
      </ZoomButton>
      <ZoomButton
        tooltipTitle={t<string>('Fit to screen')}
        onClick={onFitToScreenClick}
        value={2}
      >
        <FullscreenIcon />
      </ZoomButton>
      <ZoomButton
        tooltipTitle={t<string>('Zoom out')}
        onClick={onZoomOutClick}
        disabled={isZoomOutDisabled}
        value={3}
      >
        <ZoomOutIcon />
      </ZoomButton>
    </ToggleButtonGroup>
  )
}
