import {
  BesteronPaymentInstrument,
  ExistingCountryCode
} from '../../../../__generated__/schema'
import {BesteronPaymentMethod} from './types'

const getAvailableBanksByCountryCode = (countryCode: ExistingCountryCode) => {
  switch (countryCode) {
    case ExistingCountryCode.Sk:
      return [
        BesteronPaymentInstrument.Gibaskbx,
        BesteronPaymentInstrument.Pobnskba,
        BesteronPaymentInstrument.Subaskbx,
        BesteronPaymentInstrument.Tatrskbx,
        BesteronPaymentInstrument.Uncrskbx
      ]
    case ExistingCountryCode.Cz:
      return [
        BesteronPaymentInstrument.Agbaczpp,
        BesteronPaymentInstrument.Bacxczpp,
        BesteronPaymentInstrument.Brexczpp,
        BesteronPaymentInstrument.Fiobczpp,
        BesteronPaymentInstrument.Gibaczpx,
        BesteronPaymentInstrument.Kombczpp,
        BesteronPaymentInstrument.Rzbcczpp
      ]
    default:
      return []
  }
}

export const getBesteronPaymentInstruments = (
  paymentMethod: BesteronPaymentMethod,
  countryCode: ExistingCountryCode
): BesteronPaymentInstrument[] => {
  const availableBanksByCountryCode =
    getAvailableBanksByCountryCode(countryCode)
  switch (paymentMethod) {
    case BesteronPaymentMethod.AggregatedView:
      return [
        ...availableBanksByCountryCode,
        BesteronPaymentInstrument.Card,
        BesteronPaymentInstrument.Applepay,
        BesteronPaymentInstrument.Googlepay
      ].filter(Boolean)
    case BesteronPaymentMethod.PaymentCard:
      return [
        BesteronPaymentInstrument.Card,
        BesteronPaymentInstrument.Applepay,
        BesteronPaymentInstrument.Googlepay
      ]
    case BesteronPaymentMethod.QuickBankTransfer:
    default:
      return availableBanksByCountryCode
  }
}
