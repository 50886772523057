import React from 'react'
import {useTranslation} from 'react-i18next'
import {UserState} from '../../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useUserStateTranslations} from '../../../constants'

export interface IUsersSearchFilter {
  hasText?: string
  state?: UserState
}

const mapHasTextToFiler = (
  filter: IUsersSearchFilter,
  hasText: string | undefined
): IUsersSearchFilter => ({...filter, hasText})

const mapUserStateToFilter = (
  filter: IUsersSearchFilter,
  state: UserState | undefined
): IUsersSearchFilter => ({...filter, state})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const userStateTranslations = useUserStateTranslations()
  const getHasTextFormSearchObject = (filter: IUsersSearchFilter) =>
    filter.hasText || undefined
  const getUserStateFromSearchObject = (filter: IUsersSearchFilter) =>
    filter.state
      ? t('State: {{userState}}', {
          userState: userStateTranslations[filter.state]
        })
      : undefined
  return {getHasTextFormSearchObject, getUserStateFromSearchObject}
}

interface IUsersSearchProps {
  onFilterChange: (filter: IUsersSearchFilter) => void
}

export const UsersSearch: React.FC<IUsersSearchProps> = ({
  onFilterChange
}: IUsersSearchProps) => {
  const {t} = useTranslation()
  const userStateTranslations = useUserStateTranslations()
  const {getHasTextFormSearchObject, getUserStateFromSearchObject} =
    useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFormSearchObject,
    getUserStateFromSearchObject
  )
  const userStateSelectOptions: ISelectOption<UserState>[] = Object.values(
    UserState
  ).map((state) => ({
    id: state,
    label: userStateTranslations[state]
  }))
  return (
    <Search<IUsersSearchFilter>
      storageKey="USERS"
      placeholder={t('Search for user')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFiler}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchTextRow<IUsersSearchFilter>
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFiler}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchSelectRow<IUsersSearchFilter, UserState>
            label={t('State')}
            value={advancedSearchObject.state || undefined}
            options={userStateSelectOptions}
            mapSelectValueToSearchObject={mapUserStateToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
