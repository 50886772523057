import {Grid} from '@mui/material'
import React, {Children, ReactNode} from 'react'

interface IPanelProps {
  children: ReactNode
}

export const Panel: React.FC<IPanelProps> = ({children}: IPanelProps) => {
  return (
    <Grid container alignItems="center">
      {Children.map(children, (child) => (
        <Grid item>{child}</Grid>
      ))}
    </Grid>
  )
}
