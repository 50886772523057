import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useNoEntityStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    marginTop: theme.spacing(5)
  },
  banner: {
    marginBottom: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(2)
  }
}))

interface INoEntityProps {
  Banner?: React.ReactNode
  CreateButton: React.ReactNode
  label: string
}

/**
 * @deprecated - please use Blank component
 */
export const NoEntity: React.FC<INoEntityProps> = ({
  Banner,
  CreateButton,
  label
}: INoEntityProps) => {
  const classes = useNoEntityStyles()
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.wrapper}
    >
      {Banner && (
        <Grid item className={classes.banner}>
          {Banner}
        </Grid>
      )}
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      {CreateButton}
    </Grid>
  )
}
