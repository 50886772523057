import {
  Button,
  ListItem,
  ListItemIcon,
  listItemSecondaryActionClasses,
  ListItemText
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface IListItemWithAddButtonProps {
  disabled?: boolean
  onAddButtonClick: () => void
  icon?: React.ReactNode
  primaryText: string
  secondaryText?: string
}

export const ListItemWithAddButton: React.FC<IListItemWithAddButtonProps> = ({
  disabled,
  onAddButtonClick,
  icon,
  primaryText,
  secondaryText
}: IListItemWithAddButtonProps) => {
  const {t} = useTranslation()
  return (
    <ListItem
      sx={{
        minHeight: 40,
        p: 0,
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
        '&:last-child': {
          borderBottom: 0
        },
        gap: 2,
        opacity: (theme) =>
          disabled ? theme.palette.action.disabledOpacity : undefined,
        cursor: disabled ? 'pointer' : 'normal',
        pointerEvents: disabled ? 'none' : 'auto',
        [`& .${listItemSecondaryActionClasses.root}`]: {
          right: 0
        }
      }}
      secondaryAction={
        <Button variant="text" color="primary" onClick={onAddButtonClick}>
          {t('Add')}
        </Button>
      }
    >
      {icon && <ListItemIcon sx={{minWidth: 'unset'}}>{icon}</ListItemIcon>}
      <ListItemText
        sx={{overflowWrap: 'anywhere', maxWidth: '80%'}}
        primary={primaryText}
        primaryTypographyProps={{variant: 'body1'}}
        secondary={secondaryText}
        secondaryTypographyProps={{variant: 'caption', color: 'textSecondary'}}
      />
    </ListItem>
  )
}
