import {styled} from '@mui/system'
import React, {useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  BusinessPartnerCategory,
  BusinessPartnerType,
  ClientVatRegistered,
  ExistingCountryCode,
  LeadField
} from '../../../__generated__/schema'
import {useTranslateCountry} from '../../../hooks/countries'
import {useTranslateLeadField} from '../../../hooks/leadField'
import {useTranslateBusinessPartnerCategory} from '../../../hooks/translateBusinessPartnerCategory'
import {useTranslateBusinessPartnerType} from '../../../hooks/translateBusinessPartnerType'
import {
  useIsStringWithMaxLength,
  useIsValidEmail,
  useIsValidPhone,
  useIsValidURL
} from '../../../utils/formsValidations'
import {FormAutocomplete} from '../../form/FormAutocomplete'
import {FormAutocompleteMultiple} from '../../form/FormAutocompleteMultiple'
import {UncontrolledFormTextInput} from '../../form/FormTextInput'
import {InputRow} from '../FormHelpers'
import {SettingsPaper} from '../Settings'
import {BusinessPartnerFormField, IBusinessPartnerForm} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(3)
}))

export interface IBusinessPartnerFormProps {
  formId: string
  onSubmit: (data: IBusinessPartnerForm) => Promise<void>
  defaultValues?: Partial<IBusinessPartnerForm>
  navigationItems: {
    company: {id: string; label: string}
    billingAddress: {id: string; label: string}
    contact: {id: string; label: string}
    deliveryAddress: {id: string; label: string}
    notes: {id: string; label: string}
  }
}

export const BusinessPartnerForm: React.FC<IBusinessPartnerFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  navigationItems
}: IBusinessPartnerFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IBusinessPartnerForm>({defaultValues, mode: 'onChange'})
  const translateBusinessPartnerType = useTranslateBusinessPartnerType()
  const translateBusinessPartnerCategory = useTranslateBusinessPartnerCategory()
  const translateLeadField = useTranslateLeadField()
  const translateCountry = useTranslateCountry()
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isValidPhone = useIsValidPhone()
  const isValidEmail = useIsValidEmail()
  const isValidURL = useIsValidURL(false, false)
  const countryOptions = useMemo(
    () =>
      Object.values(ExistingCountryCode).map((code: ExistingCountryCode) => ({
        value: code,
        name: translateCountry(code)
      })),
    [translateCountry]
  )
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <SettingsPaper
        id={navigationItems.company.id}
        label={navigationItems.company.label}
        childrenSx={{p: 3}}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.CompanyName}
              key={BusinessPartnerFormField.CompanyName}
              label={t('Company name')}
              validationOptions={{
                validate: isStringWithMaxLength255,
                required: true
              }}
              fullWidth
              required
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.CompanyIdNumber}
              key={BusinessPartnerFormField.CompanyIdNumber}
              label={t('Company ID')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              helperNote={t('Recommended')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.TaxId}
              key={BusinessPartnerFormField.TaxId}
              label={t('TAX ID')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.VatId}
              key={BusinessPartnerFormField.VatId}
              label={t('VAT ID')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<IBusinessPartnerForm>
              noOptionsText=""
              fullWidth
              errors={errors}
              label={t('VAT payer')}
              name={BusinessPartnerFormField.VATRegistered}
              key={BusinessPartnerFormField.VATRegistered}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={[
                {value: ClientVatRegistered.Full, name: t('Full')},
                {value: ClientVatRegistered.None, name: t('Non VAT payer')},
                {value: ClientVatRegistered.Part, name: t('Part')}
              ]}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocompleteMultiple<IBusinessPartnerForm>
              errors={errors}
              control={control}
              name={BusinessPartnerFormField.Types}
              key={BusinessPartnerFormField.Types}
              label={t('Types')}
              autocompleteOptions={Object.values(BusinessPartnerType).map(
                (type) => ({
                  value: type,
                  name: translateBusinessPartnerType(type)
                })
              )}
              fullWidth
              validationOptions={{required: true}}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocompleteMultiple<IBusinessPartnerForm>
              errors={errors}
              control={control}
              name={BusinessPartnerFormField.Categories}
              key={BusinessPartnerFormField.Categories}
              label={t('Categories')}
              autocompleteOptions={Object.values(BusinessPartnerCategory).map(
                (category) => ({
                  value: category,
                  name: translateBusinessPartnerCategory(category)
                })
              )}
              fullWidth
              helperText={t(
                'Recommended. Used for filtering data on various places.'
              )}
              limitTags={3}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.CustomId}
              key={BusinessPartnerFormField.VatId}
              label={t('Our ID')}
              validationOptions={{
                validate: isStringWithMaxLength255
              }}
              helperNote={t('Out custom identification code')}
              fullWidth
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper
        id={navigationItems.billingAddress.id}
        label={navigationItems.billingAddress.label}
        childrenSx={{p: 3}}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.BillingAddress}[street]`}
              key={`${BusinessPartnerFormField.BillingAddress}[street]`}
              label={translateLeadField(LeadField.BillingAddressStreet)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.BillingAddress}[town]`}
              key={`${BusinessPartnerFormField.BillingAddress}[town]`}
              label={translateLeadField(LeadField.BillingAddressTown)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.BillingAddress}[postalCode]`}
              key={`${BusinessPartnerFormField.BillingAddress}[postalCode]`}
              label={translateLeadField(LeadField.BillingPostalCode)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<IBusinessPartnerForm>
              fullWidth
              noOptionsText=""
              errors={errors}
              label={translateLeadField(LeadField.BillingAddressCountry)}
              name={`${BusinessPartnerFormField.BillingAddress}[country]`}
              key={`${BusinessPartnerFormField.BillingAddress}[country]`}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={countryOptions}
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper
        id={navigationItems.contact.id}
        label={navigationItems.contact.label}
        childrenSx={{p: 3}}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.Phone}
              key={BusinessPartnerFormField.Phone}
              label={t('Phone')}
              validationOptions={{
                validate: isValidPhone
              }}
              helperNote={t('Recommended')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.Email}
              key={BusinessPartnerFormField.Email}
              label={t('Email')}
              validationOptions={{
                validate: isValidEmail
              }}
              helperNote={t('Recommended')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.Website}
              key={BusinessPartnerFormField.Website}
              label={t('Website')}
              validationOptions={{
                validate: isValidURL
              }}
              fullWidth
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper
        id={navigationItems.deliveryAddress.id}
        label={navigationItems.deliveryAddress.label}
        childrenSx={{p: 3}}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.DeliveryAddress}[addressee]`}
              key={`${BusinessPartnerFormField.DeliveryAddress}[addressee]`}
              label={translateLeadField(LeadField.DeliveryAddressee)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.DeliveryAddress}[street]`}
              key={`${BusinessPartnerFormField.DeliveryAddress}[street]`}
              label={translateLeadField(LeadField.DeliveryAddressStreet)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.DeliveryAddress}[town]`}
              key={`${BusinessPartnerFormField.DeliveryAddress}[town]`}
              label={translateLeadField(LeadField.DeliveryAddressTown)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={`${BusinessPartnerFormField.DeliveryAddress}[postalCode]`}
              key={`${BusinessPartnerFormField.DeliveryAddress}[postalCode]`}
              label={translateLeadField(LeadField.DeliveryPostalCode)}
              validationOptions={{validate: isStringWithMaxLength255}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<IBusinessPartnerForm>
              fullWidth
              noOptionsText=""
              errors={errors}
              label={translateLeadField(LeadField.DeliveryAddressCountry)}
              name={`${BusinessPartnerFormField.DeliveryAddress}[country]`}
              key={`${BusinessPartnerFormField.DeliveryAddress}[country]`}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={countryOptions}
            />
          ]}
        />
      </SettingsPaper>
      <SettingsPaper
        id={navigationItems.notes.id}
        label={navigationItems.notes.label}
        childrenSx={{p: 3}}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.Note}
              key={BusinessPartnerFormField.Note}
              label={t('Note')}
              validationOptions={{
                validate: isStringWithMaxLength1000
              }}
              multiline
              rows={3}
              helperNote={t('Can be printed on various reports')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IBusinessPartnerForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={BusinessPartnerFormField.InternalNote}
              key={BusinessPartnerFormField.InternalNote}
              label={t('Internal note')}
              validationOptions={{
                validate: isStringWithMaxLength1000
              }}
              multiline
              rows={3}
              fullWidth
            />
          ]}
        />
      </SettingsPaper>
    </StyledForm>
  )
}
