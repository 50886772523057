import {Box, Typography} from '@mui/material'
import {GridPagination} from '@mui/x-data-grid-pro'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface IDataGridPaginationProps {
  selectedItemIds: number[]
}

export const DataGridPagination: React.FC<IDataGridPaginationProps> = ({
  selectedItemIds
}: IDataGridPaginationProps) => {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        px: 2,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        {selectedItemIds.length > 0 && (
          <Typography variant="subtitle2" color="textPrimary">
            {t('{{count}} selected', {count: selectedItemIds.length})}
          </Typography>
        )}
      </Box>
      <Box>
        <GridPagination />
      </Box>
    </Box>
  )
}
