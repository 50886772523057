import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CartDetailPropertiesFragment,
  CartState,
  PermissionCode,
  SellingChannel
} from '../../../../../__generated__/schema'
import {
  useTranslateCartState,
  useTranslateCartStateDescription
} from '../../../../../hooks/cartState'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslatePrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {cartStateColors} from '../../../../constants'
import {CreateMessageDrawer} from '../messageDrawer/CreateMessageDrawer'
import {MessageFormField} from '../messageDrawer/types'
import {CartDetailDrawerType} from './types'

interface IGeneralProps {
  cart: CartDetailPropertiesFragment
  type: CartDetailDrawerType
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'row'
  },
  stateRow: {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr'
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2)
  }
}))

const useInformation = (cart: CartDetailPropertiesFragment) => {
  const {t} = useTranslation()
  const {formatDateTimeWithSeconds} = useDateTimeFormatters()
  const translateSellingChannel = useTranslateSellingChannel()
  const translatePrice = useTranslatePrice(cart.client.currency)
  const formatDateTimeFromString = useCallback(
    (value?: string | null) =>
      value && formatDateTimeWithSeconds(new Date(value)),
    [formatDateTimeWithSeconds]
  )
  const common = [
    {
      caption: t('Channel'),
      value: translateSellingChannel(cart.sellingChannel)
    },
    {
      caption: t('Value'),
      value: translatePrice(cart.price)
    },
    {
      caption: t('Created at'),
      value: formatDateTimeFromString(cart.createdAt)
    }
  ]
  const lastUpdate = {
    caption: t('Last update'),
    value: formatDateTimeFromString(cart.updatedAt)
  }

  switch (cart.state) {
    case CartState.Sold:
      return [
        ...common,
        {
          caption: t('Paid'),
          value: formatDateTimeFromString(cart.payment?.createdAt)
        }
      ]
    case CartState.Draft:
    case CartState.Pending:
      return [
        ...common,
        lastUpdate,
        {
          caption: t('Expires at'),
          value: formatDateTimeFromString(cart.expiresAt)
        }
      ]
    case CartState.Reserved:
    case CartState.Discarded:
    case CartState.Failed:
      return [...common, lastUpdate]
    case CartState.Expired:
      return [
        ...common,
        {
          ...lastUpdate,
          caption: t('Expired at')
        }
      ]
    default:
      return []
  }
}

export const General: React.FC<IGeneralProps> = ({
  cart,
  type
}: IGeneralProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateMessageDrawerOpen,
    setTrue: openCreateMessageDrawer,
    setFalse: closeCreateMessageDrawer
  } = useBooleanState(false)
  const classes = useStyles()
  const translateCartState = useTranslateCartState()
  const translateCartStateDescription = useTranslateCartStateDescription()
  const information = useInformation(cart)
  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h6">{cart.id}</Typography>
          <div className={classes.actionButtons}>
            {type === CartDetailDrawerType.Cart &&
              P([PermissionCode.SendMessage]) &&
              cart.lead?.data.email && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={openCreateMessageDrawer}
                >
                  {t('Send message')}
                </Button>
              )}
          </div>
        </div>
        <div className={classes.stateRow}>
          <EntityStateChip
            colorConf={cartStateColors[cart.state]}
            label={translateCartState(cart.state)}
          />
          <Typography variant="caption">
            {translateCartStateDescription(cart.state)}
          </Typography>
        </div>
        {cart.sellingChannel === SellingChannel.ECommerce &&
        cart.lead?.data.name ? (
          <SingleInfoColumn
            caption={t('Customer')}
            value={cart.lead?.data.name}
          />
        ) : undefined}
        {type === CartDetailDrawerType.CartSupport && (
          <SingleInfoColumn
            caption={t('Client')}
            value={[cart.client.name, cart.client.legalAddress?.town]
              .filter(Boolean)
              .join(', ')}
          />
        )}
        {type === CartDetailDrawerType.Cart &&
        cart.sellingChannel === SellingChannel.Retail &&
        cart.createdByName ? (
          <SingleInfoColumn caption={t('Cashier')} value={cart.createdByName} />
        ) : undefined}
        <DividerSeparatedInfoRow information={information} />
      </div>
      {cart.lead && cart.lead.data.email && (
        <CreateMessageDrawer
          isOpen={isCreateMessageDrawerOpen}
          onClose={closeCreateMessageDrawer}
          leads={[{id: cart.lead.id, contact: cart.lead.data.email}]}
          defaultValues={{
            [MessageFormField.LeadIds]: [cart.lead.id]
          }}
        />
      )}
    </>
  )
}
