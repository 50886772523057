import {get} from 'lodash'
import {
  CreateProductGroupInput,
  ProductGroupAvailabilityInput,
  ProductGroupState,
  UpdateProductGroupInput
} from '../../../../__generated__/schema'
import {
  IMergedProductGrupAvailability,
  IProductGroupAvailabilityForm,
  IProductGroupForm,
  ProductGroupAvailabilityFormField,
  ProductGroupFormField
} from './types'

export const transformFormDataToCreateProductGroupInput = (
  data: IProductGroupForm
): CreateProductGroupInput => ({
  name: data[ProductGroupFormField.Name],
  color: data[ProductGroupFormField.Color],
  icon: data[ProductGroupFormField.Icon] || undefined,
  description: data[ProductGroupFormField.Description] || undefined,
  internalDescription:
    data[ProductGroupFormField.InternalDescription] || undefined,
  coverImageUrl: data[ProductGroupFormField.CoverImageUrl] || undefined
})

export const transformFormDataToUpdateProductGroupInput = (
  data: IProductGroupForm,
  state?: ProductGroupState
): UpdateProductGroupInput => ({
  name: data[ProductGroupFormField.Name],
  color: data[ProductGroupFormField.Color],
  icon: data[ProductGroupFormField.Icon] || null,
  description: data[ProductGroupFormField.Description] || null,
  internalDescription: data[ProductGroupFormField.InternalDescription] || null,
  state
})

export const transformProductGroupAvailabilityFormDataToProductGroupAvailabilityInputs =
  (
    data: IProductGroupAvailabilityForm,
    mergedProductGroupAvailability: IMergedProductGrupAvailability[]
  ): ProductGroupAvailabilityInput[] =>
    mergedProductGroupAvailability.map(({divisionId}) => ({
      divisionId: get(
        data,
        `${ProductGroupAvailabilityFormField.DivisionId}.${divisionId}`
      ),
      isAvailableOnRetailChannel: get(
        data,
        `${ProductGroupAvailabilityFormField.IsAvailableOnRetailChannel}.${divisionId}`
      ),
      isAvailableOnECommerceChannel: get(
        data,
        `${ProductGroupAvailabilityFormField.IsAvailableOnECommerceChannel}.${divisionId}`
      )
    }))
