import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {v4 as uuid} from 'uuid'
import {ShowTypeCode} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {FormValidationError, InputBlock} from '../../../common'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  useFormatSelectFields,
  useSoundMixSelectFields,
  useVersionSelectFields
} from './getSelectFields'
import {DistributionFormField, IShowForm, ShowFormField} from './types'

export const getDistributionsFieldName = (key: string) =>
  `${ShowFormField.DISTRIBUTIONS}[${key}]`

const getDistributionFieldName = (
  key: string,
  distributionFormField: DistributionFormField
) => `${getDistributionsFieldName(key)}.${distributionFormField}`

const useStyles = makeStyles<Theme>((theme) => ({
  addButton: {
    marginTop: theme.spacing(2)
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) auto',
    gridTemplateAreas: `
      "format version soundMix deleteButton"
      "error error error error"
    `,
    gap: theme.spacing(0, 3),
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5)
  }
}))

interface IDistributionBlockProps {
  control: FormContextValues<IShowForm>['control']
  errors: FormContextValues<IShowForm>['errors']
  watch: FormContextValues<IShowForm>['watch']
  clearError: FormContextValues<IShowForm>['clearError']
  blockId?: string
  showTypeCode: ShowTypeCode
}

export const DistributionBlock = ({
  blockId,
  errors,
  clearError,
  control,
  watch,
  showTypeCode
}: IDistributionBlockProps) => {
  const distributions = watch(ShowFormField.DISTRIBUTIONS)
  const {unregister, register, setValue} = control
  const appendDistributionField = useCallback(() => {
    const name = getDistributionsFieldName(uuid())
    register(name)
    setValue(name, {
      [DistributionFormField.FORMAT]: '',
      [DistributionFormField.VERSION]: '',
      [DistributionFormField.SOUND_MIX]: ''
    })
  }, [register, setValue])
  const getDeleteButtonClickHandler = useCallback(
    (key: string) => () => {
      unregister(getDistributionFieldName(key, DistributionFormField.FORMAT))
      unregister(getDistributionFieldName(key, DistributionFormField.VERSION))
      unregister(getDistributionFieldName(key, DistributionFormField.SOUND_MIX))
      setValue(getDistributionsFieldName(key), null)
    },
    [setValue, unregister]
  )
  const {t} = useTranslation()
  const versionSelectFields = useVersionSelectFields(showTypeCode)
  const formatSelectFields = useFormatSelectFields(showTypeCode)
  const soundMixSelectFields = useSoundMixSelectFields(showTypeCode)
  const classes = useStyles()
  useEffect(() => {
    for (const e of Object.values(errors.distributions || {})) {
      if (e?.ref?.value) {
        if (Object.values(e.ref.value).some(Boolean)) {
          clearError(e.ref.name)
        }
      }
    }
  }, [clearError, distributions, errors.distributions])
  return (
    <InputBlock header={t('Distribution')} blockId={blockId}>
      {Object.entries(distributions).map(([key, distribution]) =>
        distribution ? (
          <div key={key} className={classes.row}>
            <UncontrolledFormSelect<IShowForm>
              label={t('Format')}
              name={getDistributionFieldName(key, DistributionFormField.FORMAT)}
              hasNoneSelectOption
              fullWidth
              selectOptions={formatSelectFields}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <UncontrolledFormSelect<IShowForm>
              label={t('Version')}
              name={getDistributionFieldName(
                key,
                DistributionFormField.VERSION
              )}
              hasNoneSelectOption
              fullWidth
              selectOptions={versionSelectFields}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <UncontrolledFormSelect<IShowForm>
              label={t('Sound mix')}
              name={getDistributionFieldName(
                key,
                DistributionFormField.SOUND_MIX
              )}
              hasNoneSelectOption
              fullWidth
              selectOptions={soundMixSelectFields}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <IconButton key="delete" onClick={getDeleteButtonClickHandler(key)}>
              <DeleteIcon />
            </IconButton>
            <FormValidationError<IShowForm>
              errors={errors}
              fieldName={getDistributionsFieldName(key)}
            />
          </div>
        ) : null
      )}
      <Button
        variant="outlined"
        color="primary"
        onClick={appendDistributionField}
        className={
          Object.values(distributions).length === 0
            ? undefined
            : classes.addButton
        }
        startIcon={<AddIcon />}
      >
        {t('Add distribution')}
      </Button>
    </InputBlock>
  )
}
