import React, {useCallback, useEffect} from 'react'
import {
  isCustomerDisplayEnabled,
  usePostCustomerDisplayMessage,
  useSubscribeToCustomerDisplayChannel
} from '../../../../customerDisplayBroadcastChannel'
import {
  CustomerDisplayMessage,
  CustomerDisplayMessageType
} from '../../../../customerDisplayBroadcastChannel/types'
import {
  SessionStorageKey,
  useSessionStorageReducer
} from '../../../../hooks/storage'
import {BaseView} from './BaseView'
import {CustomerDisplayDisabledView} from './CustomerDisplayDisabledView'
import {EventAuditoriumView} from './EventAuditoriumView'
import {EventsView} from './EventsView'
import {
  customerDisplayPageReducer,
  CustomerDisplayView,
  getDefaultCustomerDisplayPageState,
  ICustomerDisplayPageState
} from './reducer'
import {TourTimeSlotView} from './TourTimeSlotView'

export const CustomerDisplay: React.FC = () => {
  const {state, dispatch} = useSessionStorageReducer<
    ICustomerDisplayPageState,
    CustomerDisplayMessage
  >(
    customerDisplayPageReducer,
    getDefaultCustomerDisplayPageState(),
    SessionStorageKey.CustomerDisplayPageState
  )
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()

  useEffect(() => {
    postCustomerDisplayMessage({
      type: CustomerDisplayMessageType.CustomerDisplayPageOpened
    })
    const cleanup = () => {
      postCustomerDisplayMessage({
        type: CustomerDisplayMessageType.CustomerDisplayPageClosed
      })
    }

    window.addEventListener('beforeunload', cleanup)

    return () => {
      window.removeEventListener('beforeunload', cleanup)
    }
  }, [postCustomerDisplayMessage])

  const handleEvent = useCallback(
    function (
      this: BroadcastChannel,
      {data: message}: MessageEvent<CustomerDisplayMessage>
    ) {
      if (
        message.type === CustomerDisplayMessageType.PingAllCustomerDisplayPages
      ) {
        postCustomerDisplayMessage({
          type: CustomerDisplayMessageType.ReplyToPing,
          payload: state.pageUuid
        })
      }
      dispatch(message)
      if (message.type === CustomerDisplayMessageType.UserLoggedOut) {
        window.location.reload()
      }
    },
    [dispatch, postCustomerDisplayMessage, state]
  )
  useSubscribeToCustomerDisplayChannel(handleEvent)
  if (!isCustomerDisplayEnabled()) {
    return <CustomerDisplayDisabledView />
  }
  if (
    state.currentView === CustomerDisplayView.EventAuditoriumView &&
    state.event &&
    state.eventSeats
  ) {
    return (
      <EventAuditoriumView
        event={state.event}
        eventSeats={state.eventSeats}
        cart={state.cart}
      />
    )
  }
  if (
    state.currentView === CustomerDisplayView.CashdeskEventsView &&
    Array.isArray(state.cashdeskEvents)
  ) {
    return <EventsView events={state.cashdeskEvents} />
  }
  if (
    state.currentView === CustomerDisplayView.TourTimeSlotView &&
    state.tourTimeSlot
  ) {
    return (
      <TourTimeSlotView tourTimeSlot={state.tourTimeSlot} cart={state.cart} />
    )
  }
  return <BaseView cart={state.cart} />
}
