import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useCreateMarketingLabel} from './graphql'
import {MarketingLabelForm} from './MarketingLabelForm'
import {
  IMarketingLabelForm,
  MarketingLabelFormField,
  MarketingLabelState
} from './types'

const CREATE_MARKETING_LABEL_FORM_ID = 'createMarketingLabelForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface ICreateMarketingLabelDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateMarketingLabelDrawer: React.FC<ICreateMarketingLabelDrawerProps> =
  ({isOpen, onClose}: ICreateMarketingLabelDrawerProps) => {
    const {t} = useTranslation()
    const createMarketingLabel = useCreateMarketingLabel()
    const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
      useMutationAssistanceHooks()
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: IMarketingLabelForm) => {
        try {
          setShowBackdrop(true)
          await createMarketingLabel({
            data: {
              ...data,
              isActive:
                data[MarketingLabelFormField.IsActive] ===
                MarketingLabelState.Active
            }
          })
          addInfoNotification(t('Marketing label created'))
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating marketing label'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createMarketingLabel,
        defaultErrorHandler,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create marketing label')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_MARKETING_LABEL_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
        >
          <MarketingLabelForm
            formId={CREATE_MARKETING_LABEL_FORM_ID}
            onSubmit={handleSubmit}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
