import {Button, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DetailClientShowPropertiesFragment,
  DetailLibraryShowPropertiesFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useGetCountryThreeLetterCode} from '../../../../hooks/countries'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useTranslateGenre} from '../../../../hooks/showGenres'
import {useTranslateShowType} from '../../../../hooks/showTypes'
import {useTranslateAgeClassificationAbbreviation} from '../../../../hooks/translateAgeClassification'
import {useTranslateShowCrewRole} from '../../../../hooks/translateCrewRole'
import {useTranslateLanguageCode} from '../../../../hooks/translateLanguages'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../../utils/formatting'
import {getShowTranslation} from '../../../../utils/translations'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {CustomChip} from '../../../common/CustomChip'
import {DescriptorsRow} from '../../../common/DescriptorsRow'
import {DividedElements} from '../../../common/DividedElements'
import {SHOW_IMAGE_HEIGHT, SHOW_IMAGE_WIDTH} from '../../../constants'
import {ShowDetailImageAccordions} from './ShowDetailImageAccordions'
import {ShowDetailTranslations} from './ShowDetailTranslations'
import {ShowDistributions} from './ShowDistributions'
import {
  getShowOriginalTitle,
  isDetailClientShowPropertiesFragment
} from './utils'

interface IInfoCoupleProps {
  label: string
  children: string
}

const InfoCouple = ({label, children}: IInfoCoupleProps) => (
  <Grid container direction="column" spacing={1}>
    <Grid item>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="body2" color="textSecondary">
        {children}
      </Typography>
    </Grid>
  </Grid>
)

const useStyles = makeStyles<Theme, {hasThumbnail: boolean}>((theme) => ({
  wrapper: {
    width: 680
  },
  sectionWrapper: {
    padding: theme.spacing(3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  dividerVerticalH2: {
    height: theme.spacing(2)
  },
  showImage: {
    width: SHOW_IMAGE_WIDTH,
    height: SHOW_IMAGE_HEIGHT,
    borderRadius: 5,
    gridArea: 'showImage'
  },
  moreInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: theme.spacing(1, 3)
  },
  crewRole: {
    display: 'inline-block',
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  },
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: ({hasThumbnail}) =>
      hasThumbnail ? 'auto 1fr' : 'none',
    gridTemplateRows: 'auto auto auto 1fr',
    gap: theme.spacing(0, 3),
    gridTemplateAreas: ({hasThumbnail}) =>
      hasThumbnail
        ? `
      "showImage ."
      "showImage ."
      "showImage ."
      "showImage ."
    `
        : 'none'
  },
  infoGridOtherData: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    paddingTop: theme.spacing(1),
    gap: theme.spacing(1),
    alignItems: 'center',
    justifySelf: 'flex-start'
  }
}))

const getClientShowPosterThumbnail = (
  clientShow: DetailClientShowPropertiesFragment
) => {
  if (clientShow.primaryImage?.thumbnails?.length) {
    return clientShow.primaryImage.thumbnails[0].url
  }
  if (clientShow.primaryImage?.url) {
    return clientShow.primaryImage.url
  }
  return undefined
}

interface IShowDetailContentProps {
  show: DetailLibraryShowPropertiesFragment | DetailClientShowPropertiesFragment
  title?: string
  onPrimaryButtonClick: (id: number) => void
  primaryButtonLabel?: string

  onClose(): void
}

export const ShowDetailContent: React.FC<IShowDetailContentProps> = ({
  show,
  title,
  onClose,
  onPrimaryButtonClick,
  primaryButtonLabel
}: IShowDetailContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const clientLocaleCode = useClientLocaleCode()
  const translateShowCrewRole = useTranslateShowCrewRole()
  const {formatDate} = useDateTimeFormatters()
  const {durationToMinutesFormat, durationToHoursMinutesLongFormat} =
    useShowDurationFormatters()

  const {
    originalTitle,
    typeCode,
    ageClassificationCodes,
    genreCodes,
    globalReleaseDate,
    duration,
    distributions,
    languageCodes,
    countryCodes,
    translations,
    id,
    crew
  } = show
  const translation = getShowTranslation({
    localeCode: clientLocaleCode,
    translations
  })
  const translateLanguageCode = useTranslateLanguageCode()

  const getCountryThreeLetterCode = useGetCountryThreeLetterCode()
  const translateShowType = useTranslateShowType()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateGenre = useTranslateGenre()

  const posterThumbnail = isDetailClientShowPropertiesFragment(show)
    ? getClientShowPosterThumbnail(show)
    : undefined

  const classes = useStyles({hasThumbnail: Boolean(posterThumbnail)})

  return (
    <DrawerTemplate
      header={
        <DrawerTemplateHeader
          title={title || t('Show details')}
          onLeftActionClick={onClose}
        />
      }
      className={classes.wrapper}
      footer={
        <>
          <Button onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          {P([PermissionCode.UpdateClientShow]) && (
            <Button
              onClick={() => {
                onPrimaryButtonClick(id)
              }}
              color="primary"
              variant="contained"
            >
              {primaryButtonLabel || t('Edit')}
            </Button>
          )}
        </>
      }
    >
      <div className={cn(classes.infoGrid, classes.sectionWrapper)}>
        {posterThumbnail && (
          <img
            src={posterThumbnail}
            alt={translation?.title || t('Show image')}
            className={classes.showImage}
          />
        )}
        <Typography variant="h6" display="inline">
          {translation?.title || t('Title not available')}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {getShowOriginalTitle({t, originalTitle})}
        </Typography>
        <DividedElements
          className={classes.infoGridOtherData}
          arrayOfElementArrays={[
            [
              ...ageClassificationCodes.map((code) => (
                <CustomChip
                  key={code}
                  label={translateAgeClassificationAbbreviation(code)}
                  shape="circle"
                />
              )),
              <Typography variant="body2" key="genres">
                {`${translateShowType(typeCode)} ${
                  genreCodes.length > 0
                    ? `(${genreCodes
                        .map((code) => translateGenre(code))
                        .join(', ')})`
                    : ''
                }`}
              </Typography>
            ],
            globalReleaseDate
              ? [
                  <Typography variant="body2" key="globalReleaseDay">
                    {formatDate(new Date(globalReleaseDate))}
                  </Typography>
                ]
              : [],
            [
              duration ? (
                <Typography variant="body2" key="duration">
                  {durationToMinutesFormat(duration)}
                </Typography>
              ) : null
            ]
          ]}
        />
        {isDetailClientShowPropertiesFragment(show) &&
          show.contentDescriptorCodes &&
          show.contentDescriptorCodes.length > 0 && (
            <DescriptorsRow
              descriptorCodes={show.contentDescriptorCodes}
              prefix={t('Descriptors')}
              sx={{pt: 1}}
              typographyVariant="body2"
            />
          )}
        {isDetailClientShowPropertiesFragment(show) && show.businessPartner && (
          <Typography variant="body2">
            {t('Business partner: {{companyName}}', {
              companyName: show.businessPartner.companyName
            })}
          </Typography>
        )}
      </div>
      <ShowDistributions
        distributions={distributions}
        className={classes.sectionWrapper}
      />
      <div className={classes.sectionWrapper}>
        <ShowDetailTranslations translations={translations} />
      </div>
      {crew.length > 0 && (
        <div className={classes.sectionWrapper}>
          {crew.map(({crewRoleCode, persons}, index) => (
            <span key={index}>
              <Typography
                variant="body2"
                display="inline"
                className={classes.crewRole}
              >
                {translateShowCrewRole(crewRoleCode)}:&nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                display="inline"
              >
                {persons
                  .map(
                    (person) =>
                      `${person.name}${
                        person.description && ` (${person.description})`
                      }`
                  )
                  .join(', ')}
                &nbsp;
              </Typography>
            </span>
          ))}
        </div>
      )}
      <div className={cn(classes.moreInfo, classes.sectionWrapper)}>
        <InfoCouple label={t('Premiere')}>
          {globalReleaseDate && formatDate(new Date(globalReleaseDate))}
        </InfoCouple>
        <InfoCouple label={t('Origin languages')}>
          {languageCodes.map((l) => translateLanguageCode(l)).join(', ')}
        </InfoCouple>
        {duration && (
          <InfoCouple label={t('Duration')}>
            {durationToHoursMinutesLongFormat(duration)}
          </InfoCouple>
        )}
        <InfoCouple label={t('Countries of origin')}>
          {countryCodes
            .map((countryCode) => getCountryThreeLetterCode(countryCode))
            .join(', ')}
        </InfoCouple>
      </div>
      {show.images.length ? (
        <ShowDetailImageAccordions
          images={show.images}
          className={classes.sectionWrapper}
        />
      ) : null}
    </DrawerTemplate>
  )
}
