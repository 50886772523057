import {Chip as MuiChip, ChipProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ConditionResource,
  ShowAgeClassificationCode,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowTypeCode,
  ShowVersionCode,
  WeekDay
} from '../../../../../__generated__/schema'
import {useTranslateShowType} from '../../../../../hooks/showTypes'
import {useTranslateAgeClassification} from '../../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useTranslateWeekDay} from '../../../../../hooks/weekDay'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useGetShowTitle} from '../../../../../utils/translations'
import {
  useGetLightweightCostCenters,
  useGetLightweightEventCategories,
  useGetLightweightMarketingLabels,
  useLightweightAuditoriums,
  useLightweightVenues
} from '../../graphql'
import {useGetLightweightUsers} from '../../paymentsOverview/graphql'
import {
  useAdmissionTypesForConditionResource,
  useLightweightClientShow,
  useLightweightEvent,
  useToursForConditionResource
} from '../graphql'

const useChipStyles = makeStyles(() => ({
  chip: {
    height: '100%'
  }
}))

const Chip = (props: ChipProps) => {
  const classes = useChipStyles()
  return <MuiChip size="small" className={classes.chip} {...props} />
}

const ShowConditionChip = ({
  showId,
  onDelete
}: {
  showId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {data, loading, error} = useLightweightClientShow(showId)
  const {t} = useTranslation()
  const getTitle = useGetShowTitle()
  if (loading) {
    return <Chip label={t(`Loading...`)} />
  }
  if (error) {
    return (
      <Chip label={t('Show id: {{showId}}', {showId})} onDelete={onDelete} />
    )
  }
  return (
    <Chip
      label={getTitle({
        translations: data!.clientShow.translations,
        originalTitle: data!.clientShow.originalTitle,
        fallbackTitle: t('Title not available')
      })}
      onDelete={onDelete}
    />
  )
}

const EventIdConditionChip = ({
  eventId,
  onDelete
}: {
  eventId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {data, loading, error} = useLightweightEvent(eventId)
  const {t} = useTranslation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  if (loading) {
    return <Chip label={t(`Loading...`)} />
  }
  if (error) {
    return (
      <Chip label={t('Event id: {{eventId}}', {eventId})} onDelete={onDelete} />
    )
  }
  if (data) {
    const startsAtDate = new Date(data.event.startsAt)
    return (
      <Chip
        label={[
          getUserLocaleTranslation(data.event.names),
          formatDateNumeric(startsAtDate),
          formatTime(startsAtDate)
        ].join(' • ')}
        onDelete={onDelete}
      />
    )
  }
  return null
}

const UserIdConditionChip = ({
  userId,
  onDelete
}: {
  userId: string
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {users, loading, error} = useGetLightweightUsers()
  const user = users.find(({id}) => id === parseInt(userId, 10))
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip label={t('User ID: {{userId}}', {userId})} onDelete={onDelete} />
    )
  }
  if (user) {
    return (
      <Chip
        label={[user.lastName, user.firstName].join(' ')}
        onDelete={onDelete}
      />
    )
  }
  return null
}

const MarketingLabelIdConditionChip = ({
  marketingLabelId,
  onDelete
}: {
  marketingLabelId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {marketingLabels, loading, error} = useGetLightweightMarketingLabels()
  const marketingLabel = marketingLabels.find(({id}) => id === marketingLabelId)
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Marketing label ID: {{marketingLabelId}}', {
          marketingLabelId
        })}
        onDelete={onDelete}
      />
    )
  }
  if (marketingLabel) {
    return <Chip label={marketingLabel.name} onDelete={onDelete} />
  }
  return null
}

const EventCategoryIdConditionChip = ({
  eventCategoryId,
  onDelete
}: {
  eventCategoryId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {eventCategories, loading, error} = useGetLightweightEventCategories()
  const eventCategory = eventCategories.find(({id}) => id === eventCategoryId)
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Event category ID: {{id}}', {
          eventCategoryId
        })}
        onDelete={onDelete}
      />
    )
  }
  if (eventCategory) {
    return <Chip label={eventCategory.name} onDelete={onDelete} />
  }
  return null
}

const CostCenterIdConditionChip = ({
  costCenterId,
  onDelete
}: {
  costCenterId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {costCenters, loading, error} = useGetLightweightCostCenters()
  const costCenter = costCenters.find(({id}) => id === costCenterId)
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Cost center ID: {{id}}', {
          costCenterId
        })}
        onDelete={onDelete}
      />
    )
  }
  if (costCenter) {
    return <Chip label={costCenter.name} onDelete={onDelete} />
  }
  return null
}

const VenueIdConditionChip = ({
  venueId,
  onDelete
}: {
  venueId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {venues, loading, error} = useLightweightVenues()
  const venue = venues.find(({id}) => id === venueId)
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Venue ID: {{id}}', {
          venueId
        })}
        onDelete={onDelete}
      />
    )
  }
  if (venue) {
    return (
      <Chip
        label={[venue.name, venue.address.town].join(', ')}
        onDelete={onDelete}
      />
    )
  }
  return null
}

const AuditoriumIdConditionChip = ({
  auditoriumId,
  onDelete
}: {
  auditoriumId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {auditoriums, loading, error} = useLightweightAuditoriums()
  const auditorium = auditoriums.find(({id}) => id === auditoriumId)
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Auditorium ID: {{id}}', {
          auditoriumId
        })}
        onDelete={onDelete}
      />
    )
  }
  if (auditorium) {
    return (
      <Chip
        label={[
          auditorium.name,
          auditorium.venue.name,
          auditorium.venue.address.town
        ].join(', ')}
        onDelete={onDelete}
      />
    )
  }
  return null
}

const TourIdConditionChip = ({
  tourId,
  onDelete
}: {
  tourId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {data, loading, error} = useToursForConditionResource({
    paginationInput: {limit: 300, offset: 0}
  })
  const tour = (data?.tours.items || []).find(({id}) => id === tourId)
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Tour ID: {{id}}', {
          id: tourId
        })}
        onDelete={onDelete}
      />
    )
  }
  if (tour) {
    return (
      <Chip
        label={[tour.name, tour.division.name].join(' • ')}
        onDelete={onDelete}
      />
    )
  }
  return null
}

const AdmissionTypeIdConditionChip = ({
  admissionTypeId,
  onDelete
}: {
  admissionTypeId: number
  onDelete?: ChipProps['onDelete']
}) => {
  const {t} = useTranslation()
  const {data, loading, error} = useAdmissionTypesForConditionResource({
    paginationInput: {limit: 300, offset: 0}
  })
  const admissionType = (data?.admissionTypes.items || []).find(
    ({id}) => id === admissionTypeId
  )
  if (loading) {
    return <Chip label={t('Loading...')} />
  }
  if (error) {
    return (
      <Chip
        label={t('Admission type ID: {{id}}', {
          id: admissionType
        })}
        onDelete={onDelete}
      />
    )
  }
  if (admissionType) {
    return (
      <Chip
        label={[
          admissionType.name,
          admissionType.startingQuantity > 1 &&
            t('Min: {{minCount}}', {minCount: admissionType.startingQuantity})
        ]
          .filter(Boolean)
          .join(' • ')}
        onDelete={onDelete}
      />
    )
  }
  return null
}

interface IConditionValueChipProps {
  value: any
  resource: ConditionResource
  onDeleteClick?: () => void
}

export const ConditionValueChip: React.FC<IConditionValueChipProps> = ({
  value,
  resource,
  onDeleteClick
}: IConditionValueChipProps) => {
  const translateAgeClassification = useTranslateAgeClassification()
  const translateShowFormat = useTranslateShowFormat()
  const translateShowType = useTranslateShowType()
  const translateShowVersion = useTranslateShowVersion()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const translateWeekDay = useTranslateWeekDay()
  const {formatDateNumeric} = useDateTimeFormatters()
  switch (resource) {
    case ConditionResource.ShowTypeCode:
      return (
        <Chip
          label={translateShowType(value as ShowTypeCode)}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.FormatCode:
      return (
        <Chip
          label={translateShowFormat(value as ShowFormatCode)}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.VersionCode:
      return (
        <Chip
          label={translateShowVersion(value as ShowVersionCode)}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.SoundMixCode:
      return (
        <Chip
          label={translateShowSoundMix(value as ShowSoundMixCode)}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.ShowAgeClassificationCode:
      return (
        <Chip
          label={translateAgeClassification(value as ShowAgeClassificationCode)}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.StartWeekDay:
      return (
        <Chip
          label={translateWeekDay(value as WeekDay)}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.StartTime:
    case ConditionResource.DiscountApplicationTime:
      return <Chip label={value} onDelete={onDeleteClick} />
    case ConditionResource.StartDate:
    case ConditionResource.DiscountApplicationDate:
      return (
        <Chip
          label={formatDateNumeric(new Date(value))}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.ShowId:
      return (
        <ShowConditionChip showId={value as number} onDelete={onDeleteClick} />
      )
    case ConditionResource.EventId:
      return (
        <EventIdConditionChip
          eventId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.ApplicableByUserId:
      return (
        <UserIdConditionChip
          userId={value as string}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.MarketingLabelId:
      return (
        <MarketingLabelIdConditionChip
          marketingLabelId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.EventCategoryId:
      return (
        <EventCategoryIdConditionChip
          eventCategoryId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.CostCenterId:
      return (
        <CostCenterIdConditionChip
          costCenterId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.VenueId:
      return (
        <VenueIdConditionChip
          venueId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.AuditoriumId:
      return (
        <AuditoriumIdConditionChip
          auditoriumId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.TourId:
      return (
        <TourIdConditionChip
          tourId={value as number}
          onDelete={onDeleteClick}
        />
      )
    case ConditionResource.AdmissionTypeId:
      return (
        <AdmissionTypeIdConditionChip
          admissionTypeId={value as number}
          onDelete={onDeleteClick}
        />
      )
    default:
      return (
        <Typography variant="subtitle2" component="span" color="error">
          Not implemented resource: {resource}
        </Typography>
      )
  }
}
