import React, {useCallback, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {InputBlockWithoutSpacings} from '../FormHelpers'
import {useClientFormAnchors} from '../../../hooks/clientFormAnchors'
import {
  DetailClientPropertiesFragment,
  LeadOptionPropertiesFragment,
  LeadOptionType
} from '../../../__generated__/schema'
import {LeadSettingsItem} from './LeadSettingsItem'
import {LeadSettingsDrawer} from './LeadSettingsDrawer'
import {useTranslateDivisionType} from './leadOptionsType'
import {useBooleanState} from '../../../hooks/state'

const getLeadOptions = (
  client: DetailClientPropertiesFragment,
  type: LeadOptionType
): LeadOptionPropertiesFragment[] => {
  switch (type) {
    case LeadOptionType.Retail:
      return client.retailLeadOptions
    case LeadOptionType.RetailReservation:
      return client.retailReservationLeadOptions
    case LeadOptionType.ECommerce:
      return client.eCommerceLeadOptions
    case LeadOptionType.ECommerceReservation:
      return client.eCommerceReservationLeadOptions
    default:
      return []
  }
}

interface ILeadSettingsProps {
  client: DetailClientPropertiesFragment
}

export const LeadSettings: React.FC<ILeadSettingsProps> = ({
  client
}: ILeadSettingsProps) => {
  const clientFormAnchors = useClientFormAnchors(true)
  const translateDivisionType = useTranslateDivisionType()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse
  } = useBooleanState(false)
  const location = useLocation()
  const history = useHistory()
  const closeDrawer = useCallback(() => {
    history.replace(location.pathname, {...location, hash: ''})
    setFalse()
  }, [history, location, setFalse])
  const [leadOptionsType, setLeadOptionsType] =
    useState<LeadOptionType | null>(null)
  const handleOpenDrawer = useCallback(
    (leadOptionType: LeadOptionType) => {
      setLeadOptionsType(leadOptionType)
      openDrawer()
    },
    [openDrawer]
  )
  const handleExited = useCallback(() => {
    setLeadOptionsType(null)
  }, [])
  return (
    <>
      <InputBlockWithoutSpacings
        header={clientFormAnchors.leadSettings!.label}
        blockId={clientFormAnchors.leadSettings!.id}
      >
        {[
          LeadOptionType.Retail,
          LeadOptionType.RetailReservation,
          LeadOptionType.ECommerce,
          LeadOptionType.ECommerceReservation
        ].map((type) => (
          <LeadSettingsItem
            key={type}
            primary={translateDivisionType(type)}
            leadOptions={getLeadOptions(client, type)}
            onClick={() => {
              handleOpenDrawer(type)
            }}
          />
        ))}
      </InputBlockWithoutSpacings>
      <LeadSettingsDrawer
        isOpen={isDrawerOpen}
        title={leadOptionsType ? translateDivisionType(leadOptionsType) : ''}
        leadOptions={
          leadOptionsType ? getLeadOptions(client, leadOptionsType) : null
        }
        closeDrawer={closeDrawer}
        onExited={handleExited}
      />
    </>
  )
}
