import {isPlatformMac} from '../../utils/common'
import {IAppState} from '../index'
import {ModifierKey} from './reducer'

export const isModifierKeyActiveSelector = (
  state: IAppState,
  key: ModifierKey
) => state.modifierKeys[key]

export const isCtrlActiveSelector = (state: IAppState) =>
  isPlatformMac()
    ? isModifierKeyActiveSelector(state, ModifierKey.Meta)
    : isModifierKeyActiveSelector(state, ModifierKey.Control)

export const isShiftActiveSelector = (state: IAppState) =>
  isModifierKeyActiveSelector(state, ModifierKey.Shift)
