import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientShowPropertiesFragment,
  LibraryShowPropertiesFragment
} from '../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../hooks/getLocales'

import {Theme} from '../../../../theme'
import {ITooltipChildrenProps, Tooltip} from '../../../common'

const TOTAL_NUMBER_OF_FIELDS = 10

const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    height: 4,
    width: 7
  },
  leftBlock: {
    borderTopLeftRadius: 1,
    borderBottomLeftRadius: 1
  },
  rightBlock: {
    borderTopRightRadius: 1,
    borderBottomRightRadius: 1
  },
  filled: {
    backgroundColor: theme.palette.primary.main
  },
  empty: {
    backgroundColor: '#E0E0E0'
  },
  divider: {
    width: 2,
    backgroundColor: 'inherit'
  },
  barsRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  }
}))

interface IBarsProps {
  totalBars: number
  filledBars: number
}

// Only works well with even number of totalBars bars,
// as 1 number counts as half the bar
const Bars = React.forwardRef<HTMLDivElement, IBarsProps>(
  function BarsComponent(
    {
      totalBars,
      filledBars,
      ...tooltipChildrenProps
    }: IBarsProps & ITooltipChildrenProps,
    ref
  ) {
    const classes = useStyles()

    return (
      <div
        {...tooltipChildrenProps}
        className={cn(classes.barsRoot, tooltipChildrenProps.className)}
        ref={ref}
      >
        {_.range(0, totalBars).map((i: number) => {
          const isOddIndex = i % 2 === 1
          return (
            <Grid item key={i}>
              <Grid item container direction="row">
                <div
                  className={cn(
                    classes.block,
                    isOddIndex ? classes.rightBlock : classes.leftBlock,
                    i < filledBars ? classes.filled : classes.empty
                  )}
                />
                {i < totalBars && isOddIndex && (
                  <div className={classes.divider} />
                )}
              </Grid>
            </Grid>
          )
        })}
      </div>
    )
  }
)

interface IShowFillStatusProps {
  show: LibraryShowPropertiesFragment | ClientShowPropertiesFragment
}

export const ShowFillStatus: React.FC<IShowFillStatusProps> = ({
  show
}: IShowFillStatusProps) => {
  const {t} = useTranslation()
  const clientLocaleCode = useClientLocaleCode()
  const translations = show.translations.find(
    (t) => t.localeCode === clientLocaleCode
  )
  const filledFieldsCount = show
    ? _.compact([
        translations && translations.tagline,
        translations && translations.description,
        !_.isEmpty(show.ageClassificationCodes),
        !_.isEmpty(show.genreCodes),
        !_.isEmpty(show.crew),
        show.globalReleaseDate,
        show.duration
        // TODO possibly change when backend supports media
        // photos
        // posters
        // videos
      ]).length
    : 0
  const filledPercentage = Math.round(
    (filledFieldsCount * 100) / TOTAL_NUMBER_OF_FIELDS
  )
  return (
    <Tooltip
      title={t<string>('Content filled {{filledPercentage}} %', {
        filledPercentage
      })}
    >
      <Bars totalBars={TOTAL_NUMBER_OF_FIELDS} filledBars={filledFieldsCount} />
    </Tooltip>
  )
}
