import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CustomersFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {CreateCustomerDrawer} from '../components/customerDrawer/CreateCustomerDrawer'
import {ICustomerForm} from '../components/customerDrawer/types'
import {transformFormDataToCreateCustomerInput} from '../components/customerDrawer/utils'
import {
  CustomersSearch,
  DEFAULT_CUSTOMERS_FILTER_INPUT
} from '../components/CustomersSearch'
import {PrimaryHeader} from '../Header'
import {CustomersList} from './CustomersList'
import {useCreateCustomer} from './graphql'

export const CustomersPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const createCustomer = useCreateCustomer()
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const history = useHistory()
  const {
    state: isCreateCustomerDrawerOpen,
    setTrue: openCreateCustomerDrawer,
    setFalse: closeCreateCustomerDrawer
  } = useBooleanState(false)
  const [searchFilter, setSearchFilter] = useState<CustomersFilterInput>(
    DEFAULT_CUSTOMERS_FILTER_INPUT
  )
  const fabClasses = useFabClasses()
  const handleSubmit = useCallback(
    async (formData: ICustomerForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await createCustomer({
          input: transformFormDataToCreateCustomerInput(formData)
        })
        addInfoNotification(t('Customer has been created'))
        if (data) {
          history.push(routeTo.admin.customers.detail(data.createCustomer.id))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [addInfoNotification, createCustomer, history, setShowBackdrop, t]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Customers')}
          search={<CustomersSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <CustomersList searchFilter={searchFilter} />
        {P([PermissionCode.CreateCustomer]) && (
          <CreateFab classes={fabClasses} onClick={openCreateCustomerDrawer} />
        )}
        <CreateCustomerDrawer
          isOpen={isCreateCustomerDrawerOpen}
          onClose={closeCreateCustomerDrawer}
          onSubmit={handleSubmit}
        />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
