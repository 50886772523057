import {Currency} from '../__generated__/schema'

export const banknotesEur = [500, 200, 100, 50, 20, 10, 5]
export const coinsEur = [2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01]

export const banknotesCzk = [5000, 2000, 1000, 500, 200, 100]
export const coinsCzk = [50, 20, 10, 5, 2, 1]

export const banknotesUsd = [100, 50, 20, 10, 5]
export const coinsUsd = [0.5, 0.25, 0.1, 0.05, 0.01]

export const banknotesGbp = [50, 20, 10, 5]
export const coinsGbp = [2, 1, 0.5, 0.25, 0.2, 0.1]

export const banknotesHuf = [20000, 10000, 5000, 2000, 1000]
export const coinsHuf = [200, 100, 50, 20, 10, 5]

export const banknotesPln = [500, 200, 100, 50, 20, 10]
export const coinsPln = [5, 2, 1, 0.5, 0.2, 0.1, 0.05, 0.02, 0.01]

const getNominalValuesForCurrency = (currency?: Currency) => {
  switch (currency) {
    case Currency.Czk:
      return [...banknotesCzk, ...coinsCzk]
    case Currency.Usd:
      return [...banknotesUsd, ...coinsUsd]
    case Currency.Huf:
      return [...banknotesHuf, ...coinsHuf]
    case Currency.Pln:
      return [...banknotesPln, ...coinsPln]
    case Currency.Gbp:
      return [...banknotesGbp, ...coinsGbp]
    case Currency.Eur:
      return [...banknotesEur, ...coinsEur]
    default:
      return []
  }
}

interface IGetClosestNominalValuesProps {
  currency?: Currency
  value: number
}

export const getClosestNominalValues = ({
  currency,
  value
}: IGetClosestNominalValuesProps) => {
  return value > 0
    ? getNominalValuesForCurrency(currency)
        .filter((nominal) => nominal > value)
        .reverse()
        .slice(0, 3)
    : []
}
