import {
  ProductMode,
  TranslatedInput,
  Unit
} from '../../../../__generated__/schema'

export enum ProductFormLocation {
  CreateProduct = 'createProduct',
  EditProduct = 'editProduct'
}

export enum ProductFormField {
  Name = 'name',
  ECommerceNames = 'eCommerceNames',
  ECommerceDescriptions = 'eCommerceDescriptions',
  ReceiptName = 'receiptName',
  InternalDescription = 'internalDescription',
  InternalCode = 'internalCode',
  PriceLookupCode = 'priceLookupCode',
  Unit = 'unit',
  ProductTypeId = 'productTypeId',
  Mode = 'mode'
}

export interface IProductForm {
  [ProductFormField.Name]: string
  [ProductFormField.ECommerceNames]?: TranslatedInput
  [ProductFormField.ECommerceDescriptions]?: TranslatedInput
  [ProductFormField.ReceiptName]?: string
  [ProductFormField.InternalDescription]?: string
  [ProductFormField.InternalCode]?: string
  [ProductFormField.PriceLookupCode]?: number
  [ProductFormField.Unit]: Unit
  [ProductFormField.ProductTypeId]: string
  [ProductFormField.Mode]: ProductMode
}
