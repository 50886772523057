import {Button, Drawer, drawerClasses} from '@mui/material'
import axios from 'axios'
import React, {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState
} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {
  PosTerminalApiConfigResponse,
  PosTerminalApiConfigSchemaResponse,
  PosTerminalApiSaleResponseState,
  PosTerminalApiTerminalSaleRequest,
  PosTerminalApiTerminalSaleResponse
} from '../../../../types'
import {getTerminalConfig} from '../../../../utils/getTerminalConfig'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {SimpleDialog} from '../../../common/SimpleDialog'
import {useNotifications} from '../../../context/notifications'
import {PosSettingsForm} from './PosSettingsForm'
import {IPosSettingsForm, PosSettingsField} from './types'

const EDIT_POS_SETTINGS_FORM_ID = 'editPosSettingsForm'

const OPEN_PROGRESS_DIALOG = 'OPEN_PROGRESS_DIALOG'
const OPEN_SUCCESS_DIALOG = 'OPEN_SUCCESS_DIALOG'
const OPEN_FAILURE_DIALOG = 'OPEN_FAILURE_DIALOG'
const CLOSE_ALL_DIALOGS = 'CLOSE_ALL_DIALOGS'

type ReducerAction =
  | {type: typeof OPEN_PROGRESS_DIALOG}
  | {type: typeof OPEN_SUCCESS_DIALOG}
  | {type: typeof OPEN_FAILURE_DIALOG}
  | {type: typeof CLOSE_ALL_DIALOGS}

interface IReducerState {
  isProgressDialogOpen: boolean
  isSuccessDialogOpen: boolean
  isFailureDialogOpen: boolean
}
const reducer: Reducer<IReducerState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case OPEN_PROGRESS_DIALOG:
      return {
        isProgressDialogOpen: true,
        isSuccessDialogOpen: false,
        isFailureDialogOpen: false
      }
    case OPEN_SUCCESS_DIALOG:
      return {
        isProgressDialogOpen: false,
        isSuccessDialogOpen: true,
        isFailureDialogOpen: false
      }
    case OPEN_FAILURE_DIALOG:
      return {
        isProgressDialogOpen: false,
        isSuccessDialogOpen: false,
        isFailureDialogOpen: true
      }
    case CLOSE_ALL_DIALOGS:
      return {
        isProgressDialogOpen: false,
        isSuccessDialogOpen: false,
        isFailureDialogOpen: false
      }
    default:
      return state
  }
}

interface IEditPosSettingsDrawerProps {
  isOpen: boolean
  onClose: () => void
  terminalSettings: PosTerminalApiConfigResponse
  port: number
}

export const EditPosSettingsDrawer: React.FC<IEditPosSettingsDrawerProps> = ({
  isOpen,
  onClose,
  terminalSettings,
  port
}: IEditPosSettingsDrawerProps) => {
  const {t} = useTranslation()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const {addErrorWithCustomDialogNotification} = useNotifications()
  const [configSchema, setConfigSchema] =
    useState<PosTerminalApiConfigSchemaResponse | null>(null)

  const [
    {isProgressDialogOpen, isSuccessDialogOpen, isFailureDialogOpen},
    dispatch
  ] = useReducer<Reducer<IReducerState, ReducerAction>>(reducer, {
    isProgressDialogOpen: false,
    isSuccessDialogOpen: false,
    isFailureDialogOpen: false
  })
  const handleSubmit = useCallback(
    async (formData: IPosSettingsForm) => {
      try {
        setShowBackdrop(true)
        await axios.request(
          getTerminalConfig({
            port,
            url: '/config',
            method: 'PUT',
            data: {
              terminal: {
                message_protocol: formData[PosSettingsField.ProtocolType],
                host: formData[PosSettingsField.IpAddress],
                port: parseInt(formData[PosSettingsField.Port], 10),
                id: formData[PosSettingsField.TerminalId],
                initial_timeout: formData[PosSettingsField.Timeout]
              }
            }
          })
        )
      } catch (error) {
        addErrorWithCustomDialogNotification({
          title: t('Failed to save POS terminal settings'),
          contentText: t(
            'Please ensure the POS terminal is powered on and connected to the network. Check if communication app is running and allowed on your computer. Some settings may need adjustment. Please review them and try again.'
          ),
          confirmButtonLabel: t('Got it')
        })
      } finally {
        setShowBackdrop(false)
      }
      try {
        dispatch({type: OPEN_PROGRESS_DIALOG})
        const {data} = await axios.request<PosTerminalApiTerminalSaleResponse>(
          getTerminalConfig<PosTerminalApiTerminalSaleRequest>({
            port,
            url: '/terminal/sale',
            method: 'POST',
            timeout: 120000,
            data: {
              data: {
                amount: 100
              }
            }
          })
        )
        if (
          (
            [
              PosTerminalApiSaleResponseState.cancelled,
              PosTerminalApiSaleResponseState.succeeded
            ] as (PosTerminalApiSaleResponseState | undefined)[]
          ).includes(data.state)
        ) {
          dispatch({type: OPEN_SUCCESS_DIALOG})
        } else {
          dispatch({type: OPEN_FAILURE_DIALOG})
        }
      } catch (error) {
        dispatch({type: OPEN_FAILURE_DIALOG})
      }
    },
    [addErrorWithCustomDialogNotification, port, setShowBackdrop, t]
  )
  const fetchConfigSchema = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const response = await axios.request<PosTerminalApiConfigSchemaResponse>(
        getTerminalConfig({
          port,
          url: '/config/schema'
        })
      )
      setConfigSchema(response.data)
    } catch (error) {
      addErrorWithCustomDialogNotification({
        title: t('Error while loading POS terminal config schema'),
        contentText: '',
        confirmButtonLabel: t('Got it')
      })
    } finally {
      setShowBackdrop(false)
    }
  }, [addErrorWithCustomDialogNotification, port, setShowBackdrop, t])
  useEffect(() => {
    if (isOpen) {
      fetchConfigSchema()
    }
    return () => setConfigSchema(null)
  }, [isOpen, fetchConfigSchema])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 480, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Edit POS settings')}
          />
        }
        footer={
          <SaveButton type="submit" form={EDIT_POS_SETTINGS_FORM_ID}>
            {t('Save')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {configSchema && (
          <PosSettingsForm
            formId={EDIT_POS_SETTINGS_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [PosSettingsField.ProtocolType]:
                terminalSettings.terminal.message_protocol,
              [PosSettingsField.IpAddress]: terminalSettings.terminal.host,
              [PosSettingsField.Port]: String(terminalSettings.terminal.port),
              [PosSettingsField.Timeout]: String(
                terminalSettings.terminal.initial_timeout
              ),
              [PosSettingsField.TerminalId]: terminalSettings.terminal.id
            }}
            terminalSettings={configSchema}
          />
        )}
      </DrawerTemplate>
      <SimpleDialog
        title={t('Payment test in progress')}
        content={t(
          `We've just sent a dummy payment request of €0,01 to the POS terminal. This is only a test, so please do not use any payment card with the terminal. The payment testing should conclude within 120 seconds. When payment request is displayed on POS terminal, press RED/Cancel/Stop button on terminal.`
        )}
        isOpen={isProgressDialogOpen}
      />
      <SimpleDialog
        title={t('POS terminal test unsuccessful')}
        content={t(
          `We attempted a dummy payment check on the POS terminal but encountered some issues. Please ensure the POS terminal is powered on and connected to the network. Check if communication app is running and allowed on your computer. Some settings may need adjustment. Please review them and try again.`
        )}
        isOpen={isFailureDialogOpen}
        actions={
          <Button
            onClick={() => {
              dispatch({type: CLOSE_ALL_DIALOGS})
            }}
          >
            {t('Got it')}
          </Button>
        }
      />
      <SimpleDialog
        title={t('POS terminal test successful')}
        content={t(
          `All settings have been verified and are correct. Your terminal is now ready for actual transactions.`
        )}
        isOpen={isSuccessDialogOpen}
        actions={
          <Button
            onClick={() => {
              dispatch({type: CLOSE_ALL_DIALOGS})
              onClose()
            }}
          >
            {t('Got it')}
          </Button>
        }
      />
    </Drawer>
  )
}
