import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DivisionPosReservationEnd} from '../../../../../__generated__/schema'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {DivisionFormControlLabel} from './DivisionFormControlLabel'
import {
  DivisionFormSelect,
  FormControlDurationLabel,
  FormControlTextLabel,
  FormControlTimeLabel
} from './DivisionFormSelect'
import {
  DivisionFormField,
  IDivisionFormData,
  IDivisionSectionProps
} from './types'

export const PosReservationEnd: React.FC<IDivisionSectionProps> = ({
  defaultValues
}: IDivisionSectionProps) => {
  const {t} = useTranslation()
  const reservationsEnds = Object.values(DivisionPosReservationEnd)
  const methods = useFormContext<IDivisionFormData>()
  const {
    watch,
    triggerValidation,
    errors,
    setValue,
    register,
    control,
    unregister,
    clearError,
    setError
  } = methods
  const reservationEndCashDeskType = watch(
    DivisionFormField.RESERVATION_END_POS_TYPE
  )
  useEffect(() => {
    if (reservationEndCashDeskType) {
      triggerValidation(
        Object.values(DivisionPosReservationEnd)
          .filter((reservation) => reservation !== reservationEndCashDeskType)
          .map(
            (reservation) =>
              `${DivisionFormField.RESERVATION_END_POS_VALUE}.${reservation}`
          )
      )
    }
  }, [reservationEndCashDeskType, triggerValidation])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <DivisionFormSelect<IDivisionFormData>
      options={reservationsEnds}
      name={DivisionFormField.RESERVATION_END_POS_TYPE}
      subfieldsName={DivisionFormField.RESERVATION_END_POS_VALUE}
      defaultValue={
        defaultValues[DivisionFormField.RESERVATION_END_POS_TYPE] ||
        DivisionPosReservationEnd.EventStart
      }
      validationOptions={{
        required: true
      }}
      required
      label={t('POS reservation expiration')}
      errors={errors}
      setValue={setValue}
      register={register}
    >
      <DivisionFormControlLabel
        value={DivisionPosReservationEnd.EventStart}
        label={t('Same as event start time')}
      />
      <DivisionFormControlLabel
        value={DivisionPosReservationEnd.AfterStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.RESERVATION_END_POS_VALUE}.${DivisionPosReservationEnd.AfterStart}`}
            validationOptions={{
              required:
                reservationEndCashDeskType ===
                DivisionPosReservationEnd.AfterStart,
              validate: isNonNegativeInteger
            }}
            required={
              reservationEndCashDeskType ===
              DivisionPosReservationEnd.AfterStart
            }
            inputMode="numeric"
            note={t('minutes after event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionPosReservationEnd.BeforeStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.RESERVATION_END_POS_VALUE}.${DivisionPosReservationEnd.BeforeStart}`}
            validationOptions={{
              required:
                reservationEndCashDeskType ===
                DivisionPosReservationEnd.BeforeStart,
              validate: isNonNegativeInteger
            }}
            required={
              reservationEndCashDeskType ===
              DivisionPosReservationEnd.BeforeStart
            }
            inputMode="numeric"
            note={t('minutes before event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionPosReservationEnd.DayBeforeStart}
        label={
          <FormControlTimeLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.RESERVATION_END_POS_VALUE}.${DivisionPosReservationEnd.DayBeforeStart}`}
            validationOptions={{
              required:
                reservationEndCashDeskType ===
                DivisionPosReservationEnd.DayBeforeStart
            }}
            note={t('the day before the event start')}
            watch={watch}
            errors={errors}
            setValue={setValue}
            register={register}
            control={control}
            unregister={unregister}
            clearError={clearError}
            setError={setError}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionPosReservationEnd.Duration}
        label={
          <FormControlDurationLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.RESERVATION_END_POS_VALUE}.${DivisionPosReservationEnd.Duration}`}
            validationOptions={{
              required:
                reservationEndCashDeskType ===
                DivisionPosReservationEnd.Duration
            }}
            required={
              reservationEndCashDeskType === DivisionPosReservationEnd.Duration
            }
            note={t('Duration')}
            watch={watch}
            errors={errors}
            setValue={setValue}
            register={register}
          />
        }
      />
    </DivisionFormSelect>
  )
}
