import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {AddMenuItem, IItemForm} from '../types'
import {ItemForm} from './ItemForm'

const ADD_ITEM_FORM_ID = 'addItemForm'

interface IAddItemDialogProps {
  type?: AddMenuItem
  onClose: () => void
  onSubmit: (formData: IItemForm) => void
  showVariant?: boolean
  showIcon?: boolean
}

export const AddItemDialog: React.FC<IAddItemDialogProps> = ({
  type,
  onClose,
  onSubmit,
  showVariant,
  showIcon
}: IAddItemDialogProps) => {
  const {t} = useTranslation()
  const handleSubmit = useCallback(
    (formData: IItemForm) => {
      onSubmit(formData)
      onClose()
    },
    [onClose, onSubmit]
  )
  return (
    <Dialog
      open={Boolean(type)}
      sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add item')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        {type && (
          <ItemForm
            formId={ADD_ITEM_FORM_ID}
            onSubmit={handleSubmit}
            type={type}
            showVariant={showVariant}
            showIcon={showIcon}
          />
        )}
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={ADD_ITEM_FORM_ID}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
