import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AddItemToWarehouseDocumentMutation,
  AddItemToWarehouseDocumentMutationVariables,
  CreateWarehouseDocumentMutation,
  CreateWarehouseDocumentMutationVariables,
  DeleteDraftWarehouseDocumentMutation,
  DeleteDraftWarehouseDocumentMutationVariables,
  IssueWarehouseDocumentMutation,
  IssueWarehouseDocumentMutationVariables,
  RemoveItemFromWarehouseDocumentMutation,
  RemoveItemFromWarehouseDocumentMutationVariables,
  UpdateDraftWarehouseDocumentMutation,
  UpdateDraftWarehouseDocumentMutationVariables,
  WarehouseDocumentQuery,
  WarehouseDocumentQueryVariables,
  WarehouseDocumentsQuery,
  WarehouseDocumentsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const WAREHOUSE_DOCUMENTS = gql`
  ${PAGINATION_FRAGMENT}
  ${USER_FIELDS}
  query WarehouseDocuments(
    $filter: WarehouseDocumentsFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouseDocuments(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        deliveryDate
        type
        source
        state
        note
        sumPriceVatExcluded
        sumPriceVatIncluded
        warehouse {
          id
          name
        }
        businessPartner {
          id
          companyName
        }
        thirdPartyDocumentId
        updatedBy {
          ...UserFields
        }
        updatedAt
        createdBy {
          ...UserFields
        }
        createdAt
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useWarehouseDocuments = (
  variables?: WarehouseDocumentsQueryVariables
) =>
  useQuery<WarehouseDocumentsQuery, WarehouseDocumentsQueryVariables>(
    WAREHOUSE_DOCUMENTS,
    {variables, fetchPolicy: 'network-only'}
  )

const CREATE_WAREHOUSE_DOCUMENT = gql`
  mutation CreateWarehouseDocument($input: CreateWarehouseDocumentInput!) {
    createWarehouseDocument(input: $input) {
      id
    }
  }
`
export const useCreateWarehouseDocument = () => {
  const [createWarehouseDocument] = useMutation<
    CreateWarehouseDocumentMutation,
    CreateWarehouseDocumentMutationVariables
  >(CREATE_WAREHOUSE_DOCUMENT)
  return useCallback(
    (variables: CreateWarehouseDocumentMutationVariables) =>
      createWarehouseDocument({variables}),
    [createWarehouseDocument]
  )
}

const BASE_WAREHOUSE_DOCUMENT_FIELDS = gql`
  ${USER_FIELDS}
  fragment BaseWarehouseDocumentFields on WarehouseDocument {
    id
    type
    source
    state
    note
    deliveryDate
    thirdPartyDocumentId
    sumPriceVatExcluded
    sumVat
    sumPriceVatIncluded
    items {
      id
      quantity
      vatRate
      unitPriceVatExcluded
      unitPriceVatIncluded
      priceVatExcluded
      priceVatIncluded
      vat
      note
      warehouseProduct {
        id
        product {
          id
          name
          unit
          internalCode
        }
      }
    }
    businessPartner {
      id
      companyName
    }
    warehouse {
      id
      name
    }
    division {
      id
      name
    }
    createdAt
    createdBy {
      ...UserFields
    }
    updatedAt
    updatedBy {
      ...UserFields
    }
    issuedAt
    issuedBy {
      ...UserFields
    }
  }
`

const WAREHOUSE_DOCUMENT = gql`
  ${BASE_WAREHOUSE_DOCUMENT_FIELDS}
  query WarehouseDocument($id: PositiveInt!) {
    warehouseDocument(id: $id) {
      ...BaseWarehouseDocumentFields
    }
  }
`

export const useWarehouseDocument = (id: number, skip?: boolean) =>
  useQuery<WarehouseDocumentQuery, WarehouseDocumentQueryVariables>(
    WAREHOUSE_DOCUMENT,
    {variables: {id}, fetchPolicy: 'network-only', skip}
  )

const UPDATE_DRAFT_WAREHOUSE_DOCUMENT = gql`
  ${BASE_WAREHOUSE_DOCUMENT_FIELDS}
  mutation UpdateDraftWarehouseDocument(
    $id: PositiveInt!
    $input: UpdateDraftWarehouseDocumentInput!
  ) {
    updateDraftWarehouseDocument(id: $id, input: $input) {
      ...BaseWarehouseDocumentFields
    }
  }
`

export const useUpdateDraftWarehouseDocument = () => {
  const [updateDraftWarehouseDocument] = useMutation<
    UpdateDraftWarehouseDocumentMutation,
    UpdateDraftWarehouseDocumentMutationVariables
  >(UPDATE_DRAFT_WAREHOUSE_DOCUMENT)
  return useCallback(
    (variables: UpdateDraftWarehouseDocumentMutationVariables) =>
      updateDraftWarehouseDocument({variables}),
    [updateDraftWarehouseDocument]
  )
}

const ADD_ITEM_TO_WAREHOUSE_DOCUMENT = gql`
  ${BASE_WAREHOUSE_DOCUMENT_FIELDS}
  mutation AddItemToWarehouseDocument(
    $input: AddItemToWarehouseDocumentInput!
  ) {
    addItemToWarehouseDocument(input: $input) {
      ...BaseWarehouseDocumentFields
    }
  }
`

export const useAddItemToWarehouseDocument = () => {
  const [addItemToWarehouseDocument] = useMutation<
    AddItemToWarehouseDocumentMutation,
    AddItemToWarehouseDocumentMutationVariables
  >(ADD_ITEM_TO_WAREHOUSE_DOCUMENT)
  return useCallback(
    (variables: AddItemToWarehouseDocumentMutationVariables) =>
      addItemToWarehouseDocument({variables}),
    [addItemToWarehouseDocument]
  )
}

const REMOVE_ITEM_FROM_WAREHOUSE_DOCUMENT = gql`
  ${BASE_WAREHOUSE_DOCUMENT_FIELDS}
  mutation RemoveItemFromWarehouseDocument(
    $itemId: PositiveInt!
    $documentId: PositiveInt!
  ) {
    removeItemFromWarehouseDocument(itemId: $itemId, documentId: $documentId) {
      ...BaseWarehouseDocumentFields
    }
  }
`

export const useRemoveItemFromWarehouseDocument = () => {
  const [removeItemFromWarehouseDocument] = useMutation<
    RemoveItemFromWarehouseDocumentMutation,
    RemoveItemFromWarehouseDocumentMutationVariables
  >(REMOVE_ITEM_FROM_WAREHOUSE_DOCUMENT)
  return useCallback(
    (variables: RemoveItemFromWarehouseDocumentMutationVariables) =>
      removeItemFromWarehouseDocument({variables}),
    [removeItemFromWarehouseDocument]
  )
}

const DELETE_DRAFT_WAREHOUSE_DOCUMENT = gql`
  mutation DeleteDraftWarehouseDocument($id: PositiveInt!) {
    deleteDraftWarehouseDocument(id: $id)
  }
`

export const useDeleteDraftWarehouseDocument = () => {
  const [deleteDraftWarehouseDocument] = useMutation<
    DeleteDraftWarehouseDocumentMutation,
    DeleteDraftWarehouseDocumentMutationVariables
  >(DELETE_DRAFT_WAREHOUSE_DOCUMENT)
  return useCallback(
    (variables: DeleteDraftWarehouseDocumentMutationVariables) =>
      deleteDraftWarehouseDocument({variables}),
    [deleteDraftWarehouseDocument]
  )
}

const ISSUE_WAREHOUSE_DOCUMENT = gql`
  ${BASE_WAREHOUSE_DOCUMENT_FIELDS}
  mutation IssueWarehouseDocument($id: PositiveInt!) {
    issueWarehouseDocument(id: $id) {
      ...BaseWarehouseDocumentFields
    }
  }
`

export const useIssueWarehouseDocument = () => {
  const [issueWarehouseDocument] = useMutation<
    IssueWarehouseDocumentMutation,
    IssueWarehouseDocumentMutationVariables
  >(ISSUE_WAREHOUSE_DOCUMENT)
  return useCallback(
    (variables: IssueWarehouseDocumentMutationVariables) =>
      issueWarehouseDocument({variables}),
    [issueWarehouseDocument]
  )
}
