import {useTranslation} from 'react-i18next'
import {AdmissionRateState} from '../__generated__/schema'

export const useTranslateAdmissionRateState = () => {
  const {t} = useTranslation()
  const translated: Record<AdmissionRateState, string> = {
    [AdmissionRateState.Active]: t<string>('Active'),
    [AdmissionRateState.Draft]: t<string>('Draft'),
    [AdmissionRateState.Inactive]: t<string>('Inactive')
  }
  return (state: AdmissionRateState) => translated[state]
}

export const useTranslateAdmissionRateStateDescription = () => {
  const {t} = useTranslation()
  const translated: Record<AdmissionRateState, string> = {
    [AdmissionRateState.Active]: t<string>(
      'Admission rate is available to be used in tours.'
    ),
    [AdmissionRateState.Draft]: t<string>(
      'Admission rate is not available to be used in tours. You can add or remove new admissions.'
    ),
    [AdmissionRateState.Inactive]: t<string>(
      'Admission rate is not available to be used in tours.'
    )
  }
  return (state: AdmissionRateState) => translated[state]
}
