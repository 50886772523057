import {
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  MenuProps as MuiMenuProps,
  SxProps
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useState} from 'react'

import {Theme} from '../../theme'

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = useCallback((event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])
  const closeMenu = useCallback((event?) => {
    if (event?.stopPropagation) {
      event.stopPropagation()
    }
    setAnchorEl(null)
  }, [])
  return {
    anchorEl,
    openMenu,
    closeMenu
  }
}

const useStyles = makeStyles(() => ({
  list: {
    minWidth: 280
  }
}))

type IMenuProps = Omit<MuiMenuProps, 'open' | 'getContentAnchorEl'>

export const Menu: React.FC<IMenuProps> = (props: IMenuProps) => {
  const classes = useStyles()
  return (
    <MuiMenu
      classes={{list: classes.list}}
      keepMounted
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={Boolean(props.anchorEl)}
      {...props}
    />
  )
}

const useMenuItemStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(1.5, 3)
  },
  listItemIconRoot: {
    minWidth: 48
  }
}))

export interface IMenuItemProps {
  label: string
  description?: string
  icon?: React.ReactNode
  onClick?: MenuItemProps['onClick']
  isDisabled?: boolean
  isSelected?: boolean
  /**
   * @deprecated use sx prop
   */
  className?: string
  /**
   * @deprecated use iconSx prop
   */
  iconClassName?: string
  cypressId?: string
  isShown?: boolean
  hasDivider?: boolean
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
}

export const MenuItem = React.forwardRef<HTMLLIElement, IMenuItemProps>(
  function MenuItemWithRef(
    {
      label,
      icon,
      onClick,
      description,
      isDisabled,
      isSelected,
      className,
      iconClassName,
      cypressId,
      hasDivider,
      isShown = true,
      sx,
      iconSx
    }: IMenuItemProps,
    ref
  ) {
    const classes = useMenuItemStyles()
    return isShown ? (
      <MuiMenuItem
        classes={{root: classes.root}}
        sx={sx}
        ref={ref}
        onClick={onClick}
        disabled={isDisabled}
        selected={isSelected}
        className={className}
        cypress-id={cypressId}
        divider={hasDivider}
      >
        {icon && (
          <ListItemIcon
            classes={{root: cn(classes.listItemIconRoot, iconClassName)}}
            sx={iconSx}
            color="primary"
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primary={label} secondary={description} />
      </MuiMenuItem>
    ) : null
  }
)
