import {styled} from '@mui/system'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {useIsPositiveDecimal} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {FormSwitchWithLabels} from '../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {IWarehouseProductForm, WarehouseProductFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IWarehouseProductFormProps {
  formId: string
  onSubmit: (data: IWarehouseProductForm) => Promise<void>
  defaultValues?: Partial<IWarehouseProductForm>
}

export const WarehouseProductForm: React.FC<IWarehouseProductFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IWarehouseProductFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    setError,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IWarehouseProductForm>({
    defaultValues
  })
  const isPositiveDecimal = useIsPositiveDecimal('1,6')
  const _onSubmit = useCallback(
    async (formData: IWarehouseProductForm) => {
      const minStockLevel = convertValueToFloat(formData.minStockLevel)
      const optimalStockLevel = convertValueToFloat(formData.optimalStockLevel)
      if (
        minStockLevel &&
        optimalStockLevel &&
        minStockLevel >= optimalStockLevel
      ) {
        setError(
          WarehouseProductFormField.MinStockLevel,
          t('Expected lower than optimal stock')
        )
      } else {
        await onSubmit(formData)
      }
    },
    [onSubmit, setError, t]
  )
  return (
    <StyledForm onSubmit={handleSubmit(_onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWarehouseProductForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WarehouseProductFormField.MinStockLevel}
            key={WarehouseProductFormField.MinStockLevel}
            label={t('Minimal stock')}
            validationOptions={{
              validate: isPositiveDecimal
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWarehouseProductForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WarehouseProductFormField.OptimalStockLevel}
            key={WarehouseProductFormField.OptimalStockLevel}
            label={t('Optimal stock')}
            validationOptions={{
              validate: isPositiveDecimal
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormSwitchWithLabels<IWarehouseProductForm>
            name={WarehouseProductFormField.IsNegativeStockEnabled}
            key={WarehouseProductFormField.IsNegativeStockEnabled}
            primaryLabel={t('Allow negative stock levels')}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.isNegativeStockEnabled || false}
            secondaryLabel={t(
              'Enable this setting if you want to allow inventory counts to drop below zero.This can be useful for managing backorders or continuing sales even when items are temporarily out of stock. Please note that enabling this may require careful monitoring and management to avoid potential stockout issues.'
            )}
            enabledText=""
            disabledText=""
          />
        ]}
      />
    </StyledForm>
  )
}
