import InfoIcon from '@mui/icons-material/InfoOutlined'
import {Box, IconButton, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CashDeskEventDetailPropertiesFragment,
  CashDeskTourTimeSlotDetailPropertiesFragment,
  ReservationDetailFragment
} from '../../../../../__generated__/schema'
import {ReactComponent as SandTimer} from '../../../../../assets/sandTimer.svg'
import {useFormatShortGuideName} from '../../../../../hooks/formatUserName'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {Tooltip} from '../../../../common'
import {
  useDaysCountBetweenTodayAndExpirationDate,
  useExpirationToNowLabel,
  useGetExpirationToNowColor
} from '../utils'

interface IExpirationProps {
  expireAt: string
}

const useExpirationStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center'
  },
  text: {
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  }
}))

export const Expiration: React.FC<IExpirationProps> = ({
  expireAt
}: IExpirationProps) => {
  const classes = useExpirationStyles()
  const daysCountBetweenTodayAndExpirationDate =
    useDaysCountBetweenTodayAndExpirationDate(expireAt)
  const expirationToNowLabel = useExpirationToNowLabel({
    expireAt,
    daysCountBetweenTodayAndExpirationDate
  })
  const getExpirationToNowColor = useGetExpirationToNowColor({
    isWithBackground: false
  })
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const expireAtDate = new Date(expireAt)
  return (
    <div
      className={cn(
        classes.root,
        getExpirationToNowColor(daysCountBetweenTodayAndExpirationDate)
      )}
    >
      <SandTimer color="inherit" />
      <Typography color="inherit" className={classes.text} variant="caption">
        {`${expirationToNowLabel} • ${
          daysCountBetweenTodayAndExpirationDate < 7
            ? formatTime(expireAtDate)
            : formatDateNumeric(expireAtDate)
        }`}
      </Typography>
    </div>
  )
}

type SubHeaderProps = (
  | {
      event: CashDeskEventDetailPropertiesFragment
      tourTimeSlot?: never
    }
  | {
      event?: never
      tourTimeSlot: CashDeskTourTimeSlotDetailPropertiesFragment
    }
) & {
  reservation: ReservationDetailFragment
  sx?: SxProps<Theme>
  /**
   * @deprecated use sx prop
   */
  className?: string
}

const useStyles = makeStyles<Theme>((theme) => ({
  subHeader: {
    background: theme.palette.background.paper,
    borderBottom: `solid ${theme.palette.divider} 1px`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  leftSubHeaderBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 3)
  },
  rightSubHeaderBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 1.5),
    gap: theme.spacing(1)
  }
}))

export const SubHeader: React.FC<SubHeaderProps> = ({
  reservation,
  event,
  tourTimeSlot,
  className,
  sx
}: SubHeaderProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const handleInfoClick = useCallback(() => {
    history.replace(
      routeTo.admin.cashDesk.reservationPreviewWithReservationInfo(
        reservation.id
      )
    )
  }, [history, reservation.id])
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const formatShortGuideName = useFormatShortGuideName()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  return (
    <Box className={cn(classes.subHeader, className)} sx={sx}>
      <div className={classes.leftSubHeaderBox}>
        <Typography variant="subtitle2">
          {event
            ? [
                formatDateNumeric(new Date(event.startsAt)),
                formatTime(new Date(event.startsAt)),
                getUserLocaleTranslation(event.names)
              ]
                .filter(Boolean)
                .join(' • ')
            : tourTimeSlot &&
              [
                formatDateNumeric(new Date(tourTimeSlot.startsAt)),
                formatTime(new Date(tourTimeSlot.startsAt)),
                tourTimeSlot.tour.name
              ]
                .filter(Boolean)
                .join(' • ')}
        </Typography>
        <Typography variant="caption">
          {event
            ? [
                event.formatCode &&
                  translateShowFormatAbbreviation(event.formatCode),
                event.soundMixCode &&
                  translateShowSoundMixAbbreviation(event.soundMixCode),
                event.versionCode &&
                  translateShowVersionAbbreviation(event.versionCode),
                event.ageClassificationCode &&
                  translateAgeClassificationAbbreviation(
                    event.ageClassificationCode
                  )
              ]
                .filter(Boolean)
                .join(' • ')
            : tourTimeSlot &&
              [
                tourTimeSlot.versionCode &&
                  translateShowVersionAbbreviation(tourTimeSlot.versionCode),
                tourTimeSlot.guide && formatShortGuideName(tourTimeSlot.guide),
                tourTimeSlot.ageClassificationCode &&
                  translateAgeClassificationAbbreviation(
                    tourTimeSlot.ageClassificationCode
                  ),
                tourTimeSlot.venue.name
              ]
                .filter(Boolean)
                .join(' • ')}
        </Typography>
      </div>
      <div className={classes.rightSubHeaderBox}>
        <Expiration expireAt={reservation.expireAt} />
        <Tooltip title={t<string>('Reservation auditorium preview info')}>
          <IconButton onClick={handleInfoClick}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  )
}
