import {ApolloClient} from 'apollo-client'
import {ApolloQueryResult} from 'apollo-client/core/types'
import {QueryOptions} from 'apollo-client/core/watchQueryOptions'
import {useCallback} from 'react'
import {
  NarrowDivisionItemFragment,
  NarrowDivisionItemsQuery,
  NarrowDivisionItemsQueryVariables,
  Pagination,
  PaginationInput
} from '../../../../../../__generated__/schema'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {
  GET_NARROW_DIVISION_ITEMS,
  NARROW_DIVISION_ITEM_FRAGMENT
} from './graphql'
import {isNotNull, useIdListItems} from './idListItems'

const useMapNarrowDivisionToIdListItem = () =>
  useCallback(
    ({id, name}: NarrowDivisionItemFragment): IIdListItem => ({
      firstRow: name,
      id
    }),
    []
  )

const getDataFromDb = ({
  client,
  options
}: {
  options: Omit<QueryOptions<NarrowDivisionItemsQueryVariables>, 'query'>
  client: ApolloClient<object>
}): Promise<ApolloQueryResult<NarrowDivisionItemsQuery>> =>
  client.query<NarrowDivisionItemsQuery, NarrowDivisionItemsQueryVariables>({
    query: GET_NARROW_DIVISION_ITEMS,
    ...options
  })

const getPagination = (data: NarrowDivisionItemsQuery): Pagination => ({
  offset: 0,
  hasMore: false,
  totalRowsCount: data.divisions.length,
  limit: data.divisions.length || 1
})

const useGetIdListItemsFromCache = (
  mapTItemToIdListItem: (tItem: NarrowDivisionItemFragment) => IIdListItem
) =>
  useCallback(
    (client: ApolloClient<object>, ids: number[]) =>
      ids
        .map((id) => {
          return client.readFragment<NarrowDivisionItemFragment>({
            id: `Division:${id}`,
            fragment: NARROW_DIVISION_ITEM_FRAGMENT,
            fragmentName: 'NarrowDivisionItem'
          })
        })
        .filter(isNotNull)
        .map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const useMapIdListItemsFromData = (
  mapTItemToIdListItem: (tItem: NarrowDivisionItemFragment) => IIdListItem
) =>
  useCallback(
    (data: NarrowDivisionItemsQuery): IIdListItem[] =>
      data.divisions.map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const mapVariables = ({
  ids
}: {
  paginationInput: PaginationInput
  hasText?: string
  ids?: number[]
}): NarrowDivisionItemsQueryVariables => ({
  filter: {
    divisionIds: ids
  }
})

export const useDivisionListItems = () => {
  const mapTItemToIdListItem = useMapNarrowDivisionToIdListItem()
  const getIdListItemsFromCache =
    useGetIdListItemsFromCache(mapTItemToIdListItem)
  const mapIdListItemsFromData = useMapIdListItemsFromData(mapTItemToIdListItem)
  const {
    getIdListItems: getDivisionListItems,
    getIdListItemsByIds: getDivisionListItemsByIds
  } = useIdListItems<
    NarrowDivisionItemsQuery,
    NarrowDivisionItemsQueryVariables
  >({
    getDataFromDb,
    getPagination,
    getIdListItemsFromCache,
    mapIdListItemsFromData,
    mapVariables
  })
  return {
    getDivisionListItems,
    getDivisionListItemsByIds
  }
}
