import {useQuery} from '@apollo/react-hooks'
import {Box, Drawer, drawerClasses, List, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightProductGroupsQuery,
  LightweightProductGroupsQueryVariables
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateProductGroupState} from '../../../../../hooks/translateProductGroupState'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {ColorBox} from '../../../../common/ColorBox'
import {IconBox} from '../../../../common/IconBox'
import {ListItemWithAddButton} from '../../../../common/ListItemWithAddButton'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {getProductGroupIcon} from '../../../../common/productGroupIcon'
import {LIGHTWEIGHT_PRODUCT_GROUPS} from '../../graphql'
import {useAssignProductGroupToProduct} from '../graphql'

interface IAssignProductGroupDrawerProps {
  isOpen: boolean
  onClose: () => void
  assignedProductGroupIds: number[]
  productId: number
  refetchProduct: () => void
}

export const AssignProductGroupDrawer: React.FC<IAssignProductGroupDrawerProps> =
  ({
    isOpen,
    onClose,
    assignedProductGroupIds,
    productId,
    refetchProduct
  }: IAssignProductGroupDrawerProps) => {
    const {t} = useTranslation()
    const [skip, setSkip] = useState(!isOpen)
    const [search, setSearch] = useState<string>('')
    const {data, loading, error, refetch} = useQuery<
      LightweightProductGroupsQuery,
      LightweightProductGroupsQueryVariables
    >(LIGHTWEIGHT_PRODUCT_GROUPS, {fetchPolicy: 'network-only', skip})
    const assignProductGroupToProduct = useAssignProductGroupToProduct()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const translateProductGroupState = useTranslateProductGroupState()
    const filteredProductGroups = (data?.productGroups || []).filter(
      ({name}) => name.toLowerCase().indexOf(search.toLowerCase()) > -1
    )
    const handleAddButtonClick = useCallback(
      (productGroupId: number) => async () => {
        try {
          setShowBackdrop(true)
          await assignProductGroupToProduct({productId, productGroupId})
          addInfoNotification(t('Product added to group'))
        } catch (error) {
          refetchProduct()
          refetch()
          defaultErrorHandler(
            error,
            t('Error while assigning product group to product')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        assignProductGroupToProduct,
        defaultErrorHandler,
        productId,
        refetch,
        refetchProduct,
        setShowBackdrop,
        t
      ]
    )
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
      return () => setSkip(true)
    }, [isOpen])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={error && t('Error while loading product groups')}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Product groups')}
            />
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {data && (
            <Box
              sx={{
                px: 3,
                pt: 3,
                display: 'flex',
                gap: 1,
                flexDirection: 'column'
              }}
            >
              <OutlinedInputWithCancelAdornment
                onCancelClick={() => setSearch('')}
                inputProps={{
                  value: search,
                  onChange: (e) => setSearch(e.target.value),
                  placeholder: t('Search for product group')
                }}
              />
              {filteredProductGroups.length > 0 ? (
                <List sx={{overflowY: 'auto'}}>
                  {filteredProductGroups.map((productGroup) => (
                    <ListItemWithAddButton
                      key={productGroup.id}
                      icon={
                        productGroup.icon ? (
                          <IconBox
                            Icon={getProductGroupIcon(productGroup.icon)}
                            hexColor={productGroup.color}
                            hideBackground
                            sx={{width: 24, height: 24}}
                            iconSx={{width: 24, height: 24}}
                          />
                        ) : (
                          <ColorBox hexColor={productGroup.color} />
                        )
                      }
                      primaryText={productGroup.name}
                      secondaryText={[
                        t('State: {{state}}', {
                          state: translateProductGroupState(productGroup.state)
                        }),
                        productGroup.internalDescription
                      ]
                        .filter(Boolean)
                        .join(' • ')}
                      disabled={
                        !!assignedProductGroupIds.find(
                          (id) => id === productGroup.id
                        )
                      }
                      onAddButtonClick={handleAddButtonClick(productGroup.id)}
                    />
                  ))}
                </List>
              ) : (
                <Typography variant="body2">
                  {t('No product groups found')}
                </Typography>
              )}
            </Box>
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
