import {
  ApplicationRuleType,
  ConditionResource
} from '../../../../__generated__/schema'

const commonTourAndEventConditionResources: ConditionResource[] = [
  ConditionResource.VersionCode,
  ConditionResource.ShowAgeClassificationCode,
  ConditionResource.StartWeekDay,
  ConditionResource.StartTime,
  ConditionResource.StartDate,
  ConditionResource.ApplicableByUserId,
  ConditionResource.DiscountApplicationDate,
  ConditionResource.DiscountApplicationTime,
  ConditionResource.VenueId
]

export const getConditionResourcesBasedOnApplicationRuleType = (
  type?: ApplicationRuleType
): ConditionResource[] => {
  switch (type) {
    case ApplicationRuleType.Event:
      return [
        ConditionResource.ShowTypeCode,
        ConditionResource.FormatCode,
        ConditionResource.SoundMixCode,
        ConditionResource.ShowId,
        ConditionResource.EventId,
        ConditionResource.MarketingLabelId,
        ConditionResource.EventCategoryId,
        ConditionResource.CostCenterId,
        ConditionResource.AuditoriumId,
        ...commonTourAndEventConditionResources
      ]
    case ApplicationRuleType.Tour:
      return [
        ConditionResource.TourId,
        ConditionResource.AdmissionTypeId,
        ...commonTourAndEventConditionResources
      ]
    case ApplicationRuleType.Cart:
    case ApplicationRuleType.Product:
    default:
      return []
  }
}
