import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {DETAIL_CLIENT_PROPERTIES_FRAGMENT} from '../../../../utils/auth'
import {
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
} from '../../../../__generated__/schema'

const UPDATE_COMPANY_MUTATION = gql`
  mutation updateCompany($input: CompanyInput!) {
    updateCompany(input: $input) {
      ...DetailClientProperties
    }
  }
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

export const useUpdateCompany = () => {
  const [updateCompany] = useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UPDATE_COMPANY_MUTATION)
  return (variables: UpdateCompanyMutationVariables) =>
    updateCompany({variables})
}
