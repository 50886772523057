import {TranslatedCurrency} from '../../../../hooks/translateCurrencies'

export type CashCounterRow = {
  denomination: number
  count: number
  id: number
}

export enum CashCounterRowType {
  CoinRow = 'coinRow',
  BanknotesRow = 'banknotesRow',
  VouchersRow = 'vouchersRow'
}

export type CashCounterPage = {
  currency: TranslatedCurrency
  coinRows: CashCounterRow[]
  banknoteRows: CashCounterRow[]
  voucherRows: CashCounterRow[]
}

export enum CashCounterActionType {
  CurrencyChange = 'currencyChange',
  Increment = 'increment',
  Decrement = 'decrement',
  Change = 'change',
  ChangeVoucherDenomination = 'changeVoucherDenomination',
  AddVoucherRow = 'addVoucherRow',
  RemoveVoucherRow = 'removeVoucherRow'
}

export type CurrencyChangeCashCounterAction = {
  type: CashCounterActionType.CurrencyChange
  payload: TranslatedCurrency
}
export type IncrementCashCounterAction = {
  type: CashCounterActionType.Increment
  payload: {
    id: number
    row: CashCounterRowType
  }
}

export type DecrementCashCounterAction = {
  type: CashCounterActionType.Decrement
  payload: {
    id: number
    row: CashCounterRowType
  }
}

export type ChangeCashCounterAction = {
  type: CashCounterActionType.Change
  payload: {
    id: number
    row: CashCounterRowType
    inputValue: number
  }
}
export type ChangeVoucherDenominationCashCounterAction = {
  type: CashCounterActionType.ChangeVoucherDenomination
  payload: {
    id: number
    value: number
  }
}
export type AddVoucherRowCashCounterAction = {
  type: CashCounterActionType.AddVoucherRow
}
export type RemoveVoucherRowCashCounterAction = {
  type: CashCounterActionType.RemoveVoucherRow
  payload: number
}

export type CashCounterAction =
  | IncrementCashCounterAction
  | DecrementCashCounterAction
  | ChangeCashCounterAction
  | CurrencyChangeCashCounterAction
  | AddVoucherRowCashCounterAction
  | RemoveVoucherRowCashCounterAction
  | ChangeVoucherDenominationCashCounterAction
