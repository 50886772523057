import RefreshIcon from '@mui/icons-material/Refresh'
import {Button, Drawer} from '@mui/material'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useCheckPassCodeParams} from '../../../../utils/pathname'
import {
  DRAWER_TRANSITION_DURATION,
  DrawerTemplateHeader,
  useDrawerWithSideNavigationStyles
} from '../../../common'

import {DrawerTemplateWithSideNavigation} from '../../../common/DrawerTemplateWithSideNavigation'
import {CrewDrawerSection} from '../../../common/eventDetailDrawer/CrewDrawerSection'
import {EventDrawerSection} from '../../../common/eventDetailDrawer/EventDrawerSection'
import {OverviewDrawerSection} from '../../../common/eventDetailDrawer/OverviewDrawerSection'
import {PricesAndDiscountsDrawerSection} from '../../../common/eventDetailDrawer/PricesAndDiscountsDrawerSection'
import {GeneralDrawerSection} from './GeneralDrawerSection'
import {useGetCheckTicketEventDrawerData} from './graphql'
import {StatisticsDrawerSection} from './StatisticsDrawerSection'
import {isCheckTicketsPageType} from './utils'

const useSegmentAnchors = (isCrewAvailable: boolean) => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      general: {
        id: 'general',
        label: t('General')
      },
      statistics: {
        id: 'statistics',
        label: t('Statistics')
      },
      event: {
        id: 'event',
        label: t('Event')
      },
      pricesAndDiscounts: {
        id: 'pricesAndDiscounts',
        label: t('Prices and discounts')
      },
      overview: {
        id: 'overview',
        label: t('Overview')
      },
      ...(isCrewAvailable
        ? {
            crew: {
              id: 'crew',
              label: t('Crew')
            }
          }
        : {})
    }),
    [isCrewAvailable, t]
  )
}

interface IEventDetailDrawerProps {
  onExited: () => void
  onEntered?: () => void
}

export const EventDetailDrawer: React.FC<IEventDetailDrawerProps> = ({
  onExited,
  onEntered
}: IEventDetailDrawerProps) => {
  const {t} = useTranslation()
  const {eventId, checkType} = useCheckPassCodeParams()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    if (!isNaN(eventId) && isCheckTicketsPageType(checkType)) {
      openDrawer()
    }
  }, [checkType, eventId, openDrawer])
  const {data, loading, error, refetch} =
    useGetCheckTicketEventDrawerData(eventId)
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const handleReload = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await refetch()
    } finally {
      setShowBackdrop(false)
    }
  }, [refetch, setShowBackdrop])
  const drawerClasses = useDrawerWithSideNavigationStyles()
  const navigationItems = useSegmentAnchors(
    data?.event ? data.event.show.crew.length > 0 : false
  )
  return (
    <Drawer
      open={isDrawerOpen}
      onClose={closeDrawer}
      SlideProps={{
        exit: true,
        onExited,
        onEntered
      }}
      classes={drawerClasses}
      keepMounted
      anchor="right"
      transitionDuration={DRAWER_TRANSITION_DURATION}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          isLoading: loading,
          errorMessage: error && t('Loading event data failed'),
          header: (
            <DrawerTemplateHeader
              title={t('More information')}
              onLeftActionClick={closeDrawer}
            />
          )
        }}
        navigationItems={navigationItems}
      >
        {data?.event && (
          <>
            <GeneralDrawerSection
              event={data.event}
              drawerSectionProps={{
                id: navigationItems.general.id
              }}
            />
            <StatisticsDrawerSection
              event={data.event}
              drawerSectionProps={{
                id: navigationItems.statistics.id,
                label: navigationItems.statistics.label,
                actions: (
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<RefreshIcon />}
                    onClick={handleReload}
                  >
                    {t('Reload')}
                  </Button>
                )
              }}
            />
            <EventDrawerSection
              event={data.event}
              drawerSectionProps={{
                id: navigationItems.event.id,
                label: navigationItems.event.label
              }}
            />
            <PricesAndDiscountsDrawerSection
              event={data.event}
              drawerSectionProps={{
                id: navigationItems.pricesAndDiscounts.id,
                label: navigationItems.pricesAndDiscounts.label
              }}
            />
            <OverviewDrawerSection
              event={data.event}
              drawerSectionProps={{
                id: navigationItems.overview.id,
                label: navigationItems.overview.label,
                isWithoutPaper: true
              }}
            />
            {navigationItems.crew && (
              <CrewDrawerSection
                event={data.event}
                drawerSectionProps={{
                  id: navigationItems.crew.id,
                  label: navigationItems.crew.label
                }}
              />
            )}
          </>
        )}
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
