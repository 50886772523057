import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreatePaymentsCsvLinkMutation,
  CreatePaymentsCsvLinkMutationVariables,
  ExportStatisticsFromDatagridAsExcelFileMutation,
  ExportStatisticsFromDatagridAsExcelFileMutationVariables,
  GetPaymentsQuery,
  GetPaymentsQueryVariables,
  LightweightUsersQuery,
  LightweightUsersQueryVariables,
  UserState
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {GET_LIGHTWEIGHT_USERS} from '../graphql'

const GET_PAYMENTS = gql`
  ${PAGINATION_FRAGMENT}
  query getPayments(
    $filter: PaymentsFilterInput
    $paginationInput: PaginationInput!
  ) {
    payments(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        createdAt
        channel
        amount
        surplus
        paymentMethodTypeAmounts {
          cash
          card
          wireTransfer
          voucher
          paymentGateway
        }
        transactionType
        cartId
        claimId
        createdByName
        note
      }
      summary(filter: $filter) {
        amount
        surplus
        paymentMethodTypeAmounts {
          cash
          card
          wireTransfer
          voucher
          paymentGateway
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useGetPayments = (variables: GetPaymentsQueryVariables) =>
  useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GET_PAYMENTS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_PAYMENTS_CSV_LINK_MUTATION = gql`
  mutation createPaymentsCsvLink(
    $filter: PaymentsFilterInput
    $localeCode: LocaleCode
    $showSalesData: Boolean
  ) {
    createPaymentsCsvLink(
      filter: $filter
      localeCode: $localeCode
      showSalesData: $showSalesData
    )
  }
`
export const useCreatePaymentsCsvLink = () => {
  const [createPaymentsCsvLink] = useMutation<
    CreatePaymentsCsvLinkMutation,
    CreatePaymentsCsvLinkMutationVariables
  >(CREATE_PAYMENTS_CSV_LINK_MUTATION)
  return createPaymentsCsvLink
}

export const useGetLightweightUsers = (skip?: boolean) => {
  const {data, error, loading} = useQuery<
    LightweightUsersQuery,
    LightweightUsersQueryVariables
  >(GET_LIGHTWEIGHT_USERS, {skip})
  return {
    users:
      data?.users
        .filter((user) => user.state !== UserState.Invited)
        .sort(
          (nameA, nameB) =>
            nameA.lastName.localeCompare(nameB.lastName) ||
            nameA.firstName.localeCompare(nameB.firstName)
        ) || [],
    error,
    loading
  }
}

const EXPORT_STATISTICS_FROM_DATAGRID_AS_EXCEL_FILE = gql`
  mutation ExportStatisticsFromDatagridAsExcelFile(
    $dataGridTableData: DataGridTableData!
    $statisticsType: DataExportStatisticsType!
    $filter: DataExportStatisticsFilterInput
    $localeCode: LocaleCode
    $additionalHeaderInformation: [NonEmptyString!]
  ) {
    exportStatisticsFromDatagridAsExcelFile(
      dataGridTableData: $dataGridTableData
      statisticsType: $statisticsType
      filter: $filter
      localeCode: $localeCode
      additionalHeaderInformation: $additionalHeaderInformation
    )
  }
`

export const useExportStatisticsAsExcel = () => {
  const [exportStatistics] = useMutation<
    ExportStatisticsFromDatagridAsExcelFileMutation,
    ExportStatisticsFromDatagridAsExcelFileMutationVariables
  >(EXPORT_STATISTICS_FROM_DATAGRID_AS_EXCEL_FILE)
  return useCallback(
    (variables: ExportStatisticsFromDatagridAsExcelFileMutationVariables) =>
      exportStatistics({variables}),
    [exportStatistics]
  )
}
