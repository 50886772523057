import {useQuery} from '@apollo/react-hooks'
import {Box, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  InventoryCheckStockLevel,
  InventoryCheckType,
  LightweightWarehouseProductsQuery,
  LightweightWarehouseProductsQueryVariables
} from '../../../../__generated__/schema'
import {useTranslateInventoryCheckType} from '../../../../hooks/translateInventoryCheckType'
import {
  useIsFormMultiSelectRequired,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {ControlledFormCheckboxGroup} from '../../../form/ControlledFormCheckboxGroup'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {FormAutocompleteMultiple} from '../../../form/FormAutocompleteMultiple'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../form/UncontrolledFormRadioGroup'
import {LIGHTWEIGHT_WAREHOUSE_PRODUCTS} from '../graphql'
import {
  IInventoryCheckForm,
  InventoryCheckFormField,
  InventoryCheckFormLocation
} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IInventoryCheckFormProps {
  formId: string
  onSubmit: (data: IInventoryCheckForm) => Promise<void>
  defaultValues?: Partial<IInventoryCheckForm>
  warehouses: {id: number; name: string}[]
  productGroups: {id: number; name: string}[]
  productTypes: {id: number; name: string}[]
  location: InventoryCheckFormLocation
}

export const InventoryCheckForm: React.FC<IInventoryCheckFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  warehouses,
  productTypes,
  productGroups,
  location
}: IInventoryCheckFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
    triggerValidation,
    getValues,
    reset,
    handleSubmit
  } = useForm<IInventoryCheckForm>({
    defaultValues
  })
  const translateInventoryCheckType = useTranslateInventoryCheckType()
  const selectedWarehouseId = watch(InventoryCheckFormField.WarehouseId)
  const {
    data: warehouseProductsData,
    loading: warehouseProductsLoading,
    error: warehouseProductsError
  } = useQuery<
    LightweightWarehouseProductsQuery,
    LightweightWarehouseProductsQueryVariables
  >(LIGHTWEIGHT_WAREHOUSE_PRODUCTS, {
    variables: {
      paginationInput: {offset: 0, limit: 300},
      filter: {
        warehouseIds: selectedWarehouseId ? [selectedWarehouseId] : undefined
      }
    },
    fetchPolicy: 'network-only',
    skip:
      !selectedWarehouseId || location === InventoryCheckFormLocation.Update,
    onCompleted: (data) => {
      if (data) {
        reset({
          ...getValues(),
          [InventoryCheckFormField.ProductIds]: []
        })
      }
    }
  })
  const isStringWithMaxLength = useIsStringWithMaxLength(1000)
  const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      {location === InventoryCheckFormLocation.Create && (
        <InputRow
          nodes={[
            <FormAutocomplete<IInventoryCheckForm>
              autocompleteOptions={warehouses.map(({id: value, name}) => ({
                value,
                name
              }))}
              errors={errors}
              register={register}
              watch={watch}
              setValue={setValue}
              label={t('Warehouse')}
              name={InventoryCheckFormField.WarehouseId}
              key={InventoryCheckFormField.WarehouseId}
              fullWidth
              required
              validationOptions={{required: true}}
            />
          ]}
        />
      )}
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IInventoryCheckForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={InventoryCheckFormField.Note}
            key={InventoryCheckFormField.Note}
            label={t('Note')}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            fullWidth
            multiline
            rows={3}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormRadioGroup<IInventoryCheckForm, InventoryCheckType>
            fullWidth
            name={InventoryCheckFormField.Type}
            key={InventoryCheckFormField.Type}
            control={control}
            label={t('Select inventory check type')}
            helperText={t(
              'After a Full inventory check, any differences between actual and expected inventory will be adjusted with incoming and outgoing warehouse documents. A Controlling inventory check does not include reconciling these discrepancies.'
            )}
            errors={errors}
            options={[
              InventoryCheckType.Full,
              InventoryCheckType.Controlling
            ].map((type) => ({
              value: type,
              label: (
                <BasicRadioLabel
                  primaryText={translateInventoryCheckType(type)}
                />
              )
            }))}
            validationOptions={{
              required: true
            }}
          />
        ]}
      />
      {location === InventoryCheckFormLocation.Create && (
        <>
          <InputRow
            nodes={[
              <ControlledFormCheckboxGroup<
                IInventoryCheckForm,
                InventoryCheckStockLevel
              >
                label={t('Include products with selected stock level')}
                errors={errors}
                name={InventoryCheckFormField.StockLevels}
                key={InventoryCheckFormField.StockLevels}
                options={[
                  {
                    id: InventoryCheckStockLevel.Positive,
                    name: t('Positive stock level')
                  },
                  {
                    id: InventoryCheckStockLevel.Zero,
                    name: t('Zero stock level')
                  },
                  {
                    id: InventoryCheckStockLevel.Negative,
                    name: t('Negative stock')
                  }
                ]}
                register={register}
                watch={watch}
                setValue={setValue}
                unregister={unregister}
                validationOptions={{validate: isFormMultiSelectRequired}}
                required
                parseCheckboxValue={(v) => v as InventoryCheckStockLevel}
              />
            ]}
          />
          <Box>
            <Typography variant="subtitle2">
              {t(
                'Select products, product group and/or product types, that you want to include to inventory check.'
              )}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t(
                'Leave this fields empty to create an inventory check for all products in the selected warehouse.'
              )}
            </Typography>
          </Box>
          <InputRow
            nodes={[
              <FormAutocompleteMultiple<IInventoryCheckForm>
                errors={errors}
                control={control}
                name={InventoryCheckFormField.ProductIds}
                key={InventoryCheckFormField.ProductIds}
                label={t('Products')}
                autocompleteOptions={(
                  warehouseProductsData?.warehouseProducts.items || []
                ).map(({id: value, product}) => ({
                  value,
                  name: product.name
                }))}
                fullWidth
                loading={warehouseProductsLoading}
                noOptionsText={
                  warehouseProductsError
                    ? t('Error while loading warehouse products')
                    : t('No warehouse products found')
                }
              />
            ]}
          />
          <InputRow
            nodes={[
              <FormAutocompleteMultiple<IInventoryCheckForm>
                errors={errors}
                control={control}
                name={InventoryCheckFormField.ProductGroupIds}
                key={InventoryCheckFormField.ProductGroupIds}
                label={t('Product groups')}
                autocompleteOptions={productGroups.map(({id: value, name}) => ({
                  value,
                  name
                }))}
                fullWidth
              />
            ]}
          />
          <InputRow
            nodes={[
              <FormAutocompleteMultiple<IInventoryCheckForm>
                errors={errors}
                control={control}
                name={InventoryCheckFormField.ProductTypeIds}
                key={InventoryCheckFormField.ProductTypeIds}
                label={t('Product types')}
                autocompleteOptions={productTypes.map(({id: value, name}) => ({
                  value,
                  name
                }))}
                fullWidth
              />
            ]}
          />
        </>
      )}
    </StyledForm>
  )
}
