import {Skeleton} from '@mui/material'
import React, {forwardRef} from 'react'

interface ILoadingSkeletonProps {
  count?: number
  height?: number | string
}

export const LoadingSkeleton = forwardRef<
  HTMLDivElement,
  ILoadingSkeletonProps
>(function LoadingSkeletonWithRef(
  {count = 5, height = 48}: ILoadingSkeletonProps,
  ref
) {
  return (
    <div ref={ref}>
      {Array.from({length: count}).map((_, index) => (
        <Skeleton key={index} height={height} />
      ))}
    </div>
  )
})
