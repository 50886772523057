import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

const useStyles = makeStyles(() => ({
  wrapper: {
    opacity: 0.7,
    cursor: 'not-allowed'
  },
  innerWrapper: {
    pointerEvents: 'none'
  }
}))

interface IDisabledFormWrapperProps {
  children: React.ReactElement
  disabled: boolean
  className?: string
}

export const DisabledFormWrapper = ({
  children,
  disabled,
  className
}: IDisabledFormWrapperProps) => {
  const classes = useStyles()

  return (
    <div className={cn(disabled && classes.wrapper, className)}>
      <div className={cn(disabled && classes.innerWrapper)}>{children}</div>
    </div>
  )
}
