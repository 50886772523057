import CancelIcon from '@mui/icons-material/Cancel'
import {IconButton, Paper, Typography, useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useState} from 'react'
import Draggable from 'react-draggable'
import {useTranslation} from 'react-i18next'
import {ReactComponent as CollapsedArrows} from '../../../assets/collapsedArrows.svg'
import {ReactComponent as ExpandedArrows} from '../../../assets/expandedArrows.svg'

import {Theme} from '../../../theme'
import {useUserInfo} from '../../../utils/auth'
import {addOpacityToHex} from '../../../utils/colors'
import {useDefaultErrorHandler} from '../../../utils/errors'
import {MediaSizes} from '../../constants'
import {useBackdropState} from '../../context/backdrop'
import {useSwitchClient} from './clients/graphql'

const useStyles = makeStyles<
  Theme,
  {isExpanded: boolean; isSmallMobile: boolean}
>((theme) => ({
  paperRoot: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip + 1,
    height: ({isExpanded}) => (isExpanded ? 64 : 32),
    top: ({isExpanded}) => (isExpanded ? 0 : 16),
    transitionProperty: 'height, top',
    transitionDuration: '1s',
    transitionTimingFunction: 'ease-out',
    transition: 'height 225ms ease-in-out',
    left: ({isSmallMobile}) => (isSmallMobile ? 'auto' : 'calc(50% - 230px)'),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    width: 344,
    background: addOpacityToHex('#000000', 0.8),
    color: theme.palette.common.white,
    alignItems: 'center',
    alignContent: 'center'
  },
  arrows: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1)
  },
  textBox: {
    overflow: 'hidden'
  },
  iconButton: {
    color: theme.palette.common.white
  }
}))

const DRAG_HANDLE_CLASS = 'dragHandle'

export const EffectiveClientIndicator: React.FC = () => {
  const {t} = useTranslation()
  const isSmallMobile = useMediaQuery(MediaSizes.SmallMobile)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const classes = useStyles({isExpanded, isSmallMobile})
  const {effectiveClient} = useUserInfo()
  const switchClient = useSwitchClient()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const handleStopButtonClick = useCallback(async () => {
    setShowBackdrop(true)
    try {
      await switchClient()
    } catch (e) {
      defaultErrorHandler(e, t('Stopping using the client failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [defaultErrorHandler, setShowBackdrop, switchClient, t])
  if (!effectiveClient) {
    return null
  }
  return (
    <Draggable
      defaultPosition={{x: 0, y: 0}}
      handle={`.${DRAG_HANDLE_CLASS}`}
      bounds="#root"
    >
      <Paper
        classes={{
          root: classes.paperRoot
        }}
      >
        <IconButton
          onClick={() => {
            setIsExpanded((isPrevExpanded) => !isPrevExpanded)
          }}
          className={classes.arrows}
        >
          {isExpanded ? <CollapsedArrows /> : <ExpandedArrows />}
        </IconButton>
        <div className={cn(classes.textBox, DRAG_HANDLE_CLASS)}>
          <Typography variant="body1" noWrap>
            {effectiveClient.name}
          </Typography>
          {isExpanded && (
            <Typography variant="caption" noWrap>
              {t('ID {{id}} {{pipe}} {{city}}', {
                id: effectiveClient.id,
                pipe: '|',
                city: effectiveClient.legalAddress.town
              })}
            </Typography>
          )}
        </div>
        <IconButton
          disabled={!effectiveClient}
          onClick={handleStopButtonClick}
          className={classes.iconButton}
          cypress-id="client-simulation-stop-button"
        >
          <CancelIcon />
        </IconButton>
      </Paper>
    </Draggable>
  )
}
