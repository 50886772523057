import {
  Box,
  Button,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  SxProps
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useRef} from 'react'

import {useBooleanState} from '../../hooks/state'
import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  popper: {
    padding: theme.spacing(0.5, 0)
  }
}))

interface IDropdownButtonProps {
  options: (Omit<MenuItemProps, 'onClick'> & {onClick?: () => void})[]
  buttonProps: Omit<ButtonProps, 'onClick' | 'ref'>
  /**
   * @deprecated use sx prop
   */
  className?: string
  sx?: SxProps<Theme>
}

export const DropdownButton: React.FC<IDropdownButtonProps> = ({
  buttonProps,
  options,
  className,
  sx
}: IDropdownButtonProps) => {
  const {state: open, setFalse: setClose, toggle} = useBooleanState(false)
  const anchorRef = useRef(null)
  const classes = useStyles()
  return (
    <Box className={className} sx={sx}>
      <Button
        variant="contained"
        color="primary"
        {...buttonProps}
        onClick={toggle}
        ref={anchorRef}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className={classes.popper}
      >
        {({TransitionProps}) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener disableReactTree onClickAway={setClose}>
                <MenuList>
                  {options.map(({onClick, ...option}, index) => (
                    <MenuItem
                      key={index}
                      {...option}
                      onClick={() => {
                        if (onClick) {
                          onClick()
                        }
                        setClose()
                      }}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
