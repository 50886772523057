import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../../../theme'
import {useCartDetailParams} from '../../../../../utils/pathname'
import {DrawerSection} from '../../../../common'
import {CartDetailDrawerTemplate} from './CartDetailDrawerTemplate'
import {General} from './General'
import {useCart} from './graphql'
import {ItemsSection} from './ItemsSection'
import {LeadSectionGrid} from './LeadSectionGrid'
import {Payment} from './Payment'
import {ReservationsSection} from './ReservationsSection'
import {CartDetailDrawerType} from './types'
import {useNavigationItems} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  section: {
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

interface ICartPreviewDrawerProps {
  onExited: () => void
}

export const CartPreviewDrawer: React.FC<ICartPreviewDrawerProps> = ({
  onExited
}: ICartPreviewDrawerProps) => {
  const {cartId} = useCartDetailParams()
  const classes = useStyles()
  const {data, loading, error, refetch} = useCart(cartId)
  const navigationItems = useNavigationItems(data?.cart.state)
  return (
    <>
      {data && (
        <CartDetailDrawerTemplate
          cart={data.cart}
          onExited={onExited}
          loading={loading}
          error={error}
          navigationItems={navigationItems}
        >
          <DrawerSection
            className={classes.section}
            id={navigationItems.general.id}
          >
            <General cart={data.cart} type={CartDetailDrawerType.Cart} />
          </DrawerSection>
          <DrawerSection
            className={classes.section}
            id={navigationItems.payment.id}
            label={navigationItems.payment.label}
          >
            <Payment cart={data.cart} refetchCart={refetch} />
          </DrawerSection>
          {navigationItems.reservations && (
            <DrawerSection
              className={classes.section}
              id={navigationItems.reservations.id}
              label={navigationItems.reservations.label}
              isWithoutPaper
            >
              <ReservationsSection
                cart={data.cart}
                type={CartDetailDrawerType.Cart}
              />
            </DrawerSection>
          )}
          {navigationItems.items && (
            <DrawerSection
              className={classes.section}
              id={navigationItems.items.id}
              label={navigationItems.items.label}
              isWithoutPaper
            >
              <ItemsSection cart={data.cart} type={CartDetailDrawerType.Cart} />
            </DrawerSection>
          )}
          <DrawerSection
            className={classes.section}
            id={navigationItems.lead.id}
            label={navigationItems.lead.label}
          >
            <LeadSectionGrid leadData={data.cart.lead?.data} />
          </DrawerSection>
        </CartDetailDrawerTemplate>
      )}
    </>
  )
}
