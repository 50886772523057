import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  ApiGrantInput,
  ApiGrantQuery,
  ApiGrantQueryVariables,
  ApiGrantsQuery,
  ApiGrantsQueryVariables,
  AssignClientsToApiGrantMutation,
  AssignClientsToApiGrantMutationVariables,
  CreateApiGrantMutation,
  CreateApiGrantMutationVariables,
  UpdateApiGrantMutation,
  UpdateApiGrantMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const API_GRANTS = gql`
  ${PAGINATION_FRAGMENT}
  query ApiGrants(
    $filter: ApiGrantsFilterInput
    $paginationInput: PaginationInput!
  ) {
    apiGrants(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        state
        expiresAt
        maxRequestsPerMinute
        maxRequestsPerHour
        updatedAt
        createdAt
        description
        clientsOwningResources {
          id
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useApiGrants = (variables: ApiGrantsQueryVariables) =>
  useQuery<ApiGrantsQuery, ApiGrantsQueryVariables>(API_GRANTS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_API_GRANT = gql`
  mutation createApiGrant($input: ApiGrantInput!) {
    createApiGrant(input: $input) {
      apiGrant {
        id
      }
      bearerToken
    }
  }
`

export const useCreateApiGrant = () => {
  const [createApiGrant] =
    useMutation<CreateApiGrantMutation, CreateApiGrantMutationVariables>(
      CREATE_API_GRANT
    )
  return useCallback(
    (input: ApiGrantInput) =>
      createApiGrant({
        variables: {input},
        refetchQueries: [
          {
            query: API_GRANTS,
            variables: {
              paginationInput: {offset: 0, limit: 300}
            }
          }
        ]
      }),
    [createApiGrant]
  )
}

const API_GRANT_FIELDS = gql`
  ${USER_FIELDS}
  fragment ApiGrantFields on ApiGrant {
    id
    name
    state
    expiresAt
    maxRequestsPerMinute
    maxRequestsPerHour
    updatedAt
    updatedBy {
      ...UserFields
    }
    createdAt
    createdBy {
      ...UserFields
    }
    description
    clientsOwningResources {
      id
      name
      legalAddress {
        town
      }
    }
    grantedResources
    businessContact {
      name
      role
      email
      phone
    }
    technicalContact {
      name
      role
      email
      phone
    }
  }
`

const API_GRANT = gql`
  ${API_GRANT_FIELDS}
  query apiGrant($id: PositiveInt!) {
    apiGrant(id: $id) {
      ...ApiGrantFields
    }
  }
`

export const useApiGrant = (id: number) =>
  useQuery<ApiGrantQuery, ApiGrantQueryVariables>(API_GRANT, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

export const LIGHTWEIGHT_CLIENTS_WITH_TOWN = gql`
  query lightweightClientsWithTown {
    clients {
      id
      name
      state
      legalAddress {
        town
      }
    }
  }
`

const UPDATE_API_GRANT = gql`
  ${API_GRANT_FIELDS}
  mutation updateApiGrant($id: PositiveInt!, $input: UpdateApiGrantInput!) {
    updateApiGrant(id: $id, input: $input) {
      ...ApiGrantFields
    }
  }
`

export const useUpdateApiGrant = () => {
  const [updateApiGrant] =
    useMutation<UpdateApiGrantMutation, UpdateApiGrantMutationVariables>(
      UPDATE_API_GRANT
    )
  return useCallback(
    (variables: UpdateApiGrantMutationVariables) => updateApiGrant({variables}),
    [updateApiGrant]
  )
}

const ASSIGN_CLIENTS_TO_API_GRANT = gql`
  ${API_GRANT_FIELDS}
  mutation assignClientsToApiGrant(
    $id: PositiveInt!
    $clientIds: [PositiveInt!]!
  ) {
    assignClientsToApiGrant(id: $id, clientIds: $clientIds) {
      ...ApiGrantFields
    }
  }
`

export const useAssignClientsToApiGrant = () => {
  const [assignClientsToApiGrant] = useMutation<
    AssignClientsToApiGrantMutation,
    AssignClientsToApiGrantMutationVariables
  >(ASSIGN_CLIENTS_TO_API_GRANT)
  return useCallback(
    (variables: AssignClientsToApiGrantMutationVariables) =>
      assignClientsToApiGrant({variables}),
    [assignClientsToApiGrant]
  )
}
