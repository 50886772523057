import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  MessageQuery,
  MessageQueryVariables,
  MessagesQuery,
  MessagesQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {LEAD_PROPERTIES_FRAGMENT, USER_FIELDS} from '../graphql'

const GET_MESSAGES = gql`
  ${USER_FIELDS}
  query messages(
    $filter: MessagesFilterInput
    $paginationInput: PaginationInput!
  ) {
    messages(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        internalNote
        state
        leads {
          id
        }
        type
        senderName
        replyTo
        createdAt
        createdBy {
          ...UserFields
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${PAGINATION_FRAGMENT}
`

export const useGetMessages = (variables: MessagesQueryVariables) =>
  useQuery<MessagesQuery, MessagesQueryVariables>(GET_MESSAGES, {
    variables,
    fetchPolicy: 'network-only'
  })

const GET_MESSAGE = gql`
  query message($id: PositiveInt!) {
    message(id: $id) {
      id
      internalNote
      subject
      body
      state
      sendAt
      leads {
        ...LeadProperties
      }
      type
      senderName
      replyTo
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const useGetMessage = (id: number) =>
  useQuery<MessageQuery, MessageQueryVariables>(GET_MESSAGE, {
    variables: {id},
    fetchPolicy: 'network-only'
  })
