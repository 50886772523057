// Note: create-react-app can not import TS from other workspaces without
// being ejected. As this would require a lot of changes,
// we put shared utils between backend/frontend into this folder, till we
// decide whether we extract them away.

// Note: mock
export enum TicketColor {
  BRIGHT_RED = '#a30000',
  BROWN = '#9a6324',
  CRIMSON = '#e6194b',
  ORANGE = '#f58231',
  CORN = '#e0a100',
  GOLD = '#edd221',
  LIME = '#aad933',
  OLIVE = '#b1b100',
  GREEN = '#3cb44b',
  TURQUOISE = '#469990',
  LIGHT_BLUE = '#42d4f4',
  AZURE = '#009af1',
  ROYAL_BLUE = '#4363d8',
  PURPLE = '#911eb4',
  RED_VIOLET = '#b41e86',
  MAGENTA = '#e32ed9'
}
