import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientState,
  LightweightClientsWithLocaleCodesQuery
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {
  LIGHTWEIGHT_CLIENTS_WITH_LOCALE_CODES,
  useCreateWebsite
} from './graphql'
import {IWebsiteForm, WebsiteFormField} from './types'
import {transformWebsiteFormDataToWebsiteInput} from './utils'
import {WebsiteForm} from './WebsiteForm'

const CREATE_WEBSITE_FORM_ID = 'createWebsiteForm'

interface ICreateWebsiteDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateWebsiteDrawer: React.FC<ICreateWebsiteDrawerProps> = ({
  isOpen,
  onClose
}: ICreateWebsiteDrawerProps) => {
  const {t} = useTranslation()
  const {effectiveClient} = useUserInfo()
  const [skip, setSkip] = useState(!isOpen)
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError
  } = useQuery<LightweightClientsWithLocaleCodesQuery>(
    LIGHTWEIGHT_CLIENTS_WITH_LOCALE_CODES,
    {skip}
  )
  const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
  const createWebsite = useCreateWebsite()
  const history = useHistory()
  const handleSubmit = useCallback(
    async (formData: IWebsiteForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await createWebsite({
          input: transformWebsiteFormDataToWebsiteInput(formData)
        })
        addInfoNotification(t('Website has been created'))
        if (data) {
          history.push(routeTo.admin.websites.detail(data.createWebsite.id))
        }
        onClose()
      } finally {
        setShowBackdrop(false)
      }
    },
    [addInfoNotification, createWebsite, history, onClose, setShowBackdrop, t]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create website')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_WEBSITE_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        isLoading={clientsLoading}
        errorMessage={clientsError && t<string>('Error while loading clients')}
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {clientsData && (
          <WebsiteForm
            formId={CREATE_WEBSITE_FORM_ID}
            onSubmit={handleSubmit}
            clients={clientsData.clients
              .filter(({state}) => state === ClientState.Active)
              .sort((clientA, clientB) =>
                clientA.name.localeCompare(clientB.name)
              )}
            defaultValues={{
              [WebsiteFormField.ClientId]: effectiveClient
                ? String(effectiveClient.id)
                : undefined
            }}
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
