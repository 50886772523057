import {Box, IconButton, TextField, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {CopyIcon} from '../../../../editor/TopPanel/Icons'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PrimaryHeader} from '../Header'
import {ApiGrantsList} from './ApiGrantsList'
import {CreateApiGrantDrawer} from './CreateApiGrantDrawer'

export const ApiGrantsPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {
    state: isCreateApiGrantDrawerOpen,
    setTrue: openCreateApiGrantDrawer,
    setFalse: closeCreateApiGrantDrawer
  } = useBooleanState(false)
  const [createdApiKey, setCreateApiKey] =
    useState<{id: number; key: string} | null>(null)
  const hasPermissionsToCreateApiGrant = P([
    PermissionCode.CreateApiGrant,
    PermissionCode.ReadClients
  ])
  const handleCopyIconClick = useCallback(
    async () => await navigator.clipboard.writeText(createdApiKey?.key || ''),
    [createdApiKey]
  )
  const handleConfirm = useCallback(() => {
    if (createdApiKey) {
      history.push(routeTo.admin.apiGrants.detail(createdApiKey.id))
      setCreateApiKey(null)
    }
  }, [createdApiKey, history])
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate header={<PrimaryHeader title={t('API grants')} />}>
      <ApiGrantsList />
      {hasPermissionsToCreateApiGrant && (
        <CreateFab classes={fabClasses} onClick={openCreateApiGrantDrawer} />
      )}
      {hasPermissionsToCreateApiGrant && (
        <CreateApiGrantDrawer
          isOpen={isCreateApiGrantDrawerOpen}
          onClose={closeCreateApiGrantDrawer}
          setCreatedApiKey={setCreateApiKey}
        />
      )}
      <ConfirmationDialog
        title={t('Your new API key')}
        contentText={
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
            <Typography component="span" variant="body1" color="textSecondary">
              {t(
                'Keep your key safe. Save and store this new key to a secure place, such as a password manager or secret store. You won’t be able to see it again.'
              )}
            </Typography>
            <TextField
              defaultValue={createdApiKey?.key}
              onFocus={(e) => e.target.select()}
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={handleCopyIconClick}>
                    <CopyIcon />
                  </IconButton>
                )
              }}
            />
          </Box>
        }
        onConfirm={handleConfirm}
        confirmButtonLabel={t('Done')}
        isOpen={!!createdApiKey}
      />
    </PageWithHeaderTemplate>
  )
}
