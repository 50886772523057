export enum ScreenOrientationType {
  PortraitPrimary = 'portrait-primary',
  PortraitSecondary = 'portrait-econdary',
  LandscapePrimary = 'landscape-primary',
  LandscapeSecondary = 'landscape-secondary'
}

type ScreenOrientation = {
  angle: number
  type: ScreenOrientationType
  onChange: any
}

export type ScreenDetailed = {
  availHeight: number
  availLeft: number
  availTop: number
  availWidth: number
  colorDepth: number
  devicePixelRatio: number
  height: number
  isExtended: boolean
  isInternal: boolean
  isPrimary: boolean
  label: string
  left: number
  onchange: any
  orientation: ScreenOrientation
  pixelDepth: number
  top: number
  width: number
}

export interface IScreenDetails {
  currentScreen: ScreenDetailed
  screens: ScreenDetailed[]
  oncurrentscreenchange: any
  onscreenschange: any
}

export type PermissionState = 'denied' | 'granted' | 'prompt' | undefined
