import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useUserInfo} from '../../../utils/auth'
import {useDefaultErrorHandler} from '../../../utils/errors'
import {CancelButton, SaveButton} from '../../common/Buttons'
import {useBackdropState} from '../../context/backdrop'
import {FormAutocomplete} from '../../form/FormAutocomplete'
import {useGetClients, useSwitchClient} from './clients/graphql'

interface ISelectClientDialogProps {
  isOpen: boolean
  onClose: () => void
}

const SELECT_CLIENT_FORM_ID = 'selectClientFormId'

enum SelectClientDialogFormField {
  ID = 'id'
}

interface ISelectClientDialogForm {
  [SelectClientDialogFormField.ID]: number
}

export const SelectClientDialog: React.FC<ISelectClientDialogProps> = ({
  isOpen,
  onClose
}: ISelectClientDialogProps) => {
  const {t} = useTranslation()
  const {data} = useGetClients()
  const {effectiveClient} = useUserInfo()
  const switchClient = useSwitchClient()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const {errors, register, setValue, watch, handleSubmit} =
    useForm<ISelectClientDialogForm>({
      defaultValues: {
        [SelectClientDialogFormField.ID]: effectiveClient?.id
      }
    })
  const _handleSubmit = useCallback(
    async (data: ISelectClientDialogForm) => {
      try {
        setShowBackdrop(true)
        await switchClient(parseInt(String(data.id), 10))
        sessionStorage.clear()
      } catch (error) {
        defaultErrorHandler(error, t('Error while selecting client'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [defaultErrorHandler, setShowBackdrop, switchClient, t]
  )
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>{t('Switch client')}</DialogTitle>
      <DialogContent>
        <Box sx={{pt: 1}}>
          <form
            onSubmit={handleSubmit(_handleSubmit)}
            id={SELECT_CLIENT_FORM_ID}
          >
            <FormAutocomplete<ISelectClientDialogForm>
              noOptionsText={t('Client not found')}
              disableClearable
              fullWidth
              required
              errors={errors}
              label={t('Client')}
              name={SelectClientDialogFormField.ID}
              cypressId="switch-client-select-input"
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={
                data?.clients.map(({id, name}) => ({
                  value: id,
                  name: [id, name].join(' - ')
                })) || []
              }
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          startIcon={<PlayArrowIcon />}
          type="submit"
          form={SELECT_CLIENT_FORM_ID}
          cypress-id="switch-client-start-button"
        >
          {t('Start')}
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}
