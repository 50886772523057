import {isNil} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DetailEventPropertiesFragment,
  EventState,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {convertValueToFloat} from '../../../../../../utils/conversions'
import {useDefaultErrorHandler} from '../../../../../../utils/errors'
import {routeTo} from '../../../../../../utils/routes'
import {SplitButtonModalItem} from '../../../../../common'
import {useNotifications} from '../../../../../context/notifications'
import {
  useCancelEvent,
  usePublishEvent,
  useUpdateExtendedEventData
} from '../graphql'
import {
  FormField,
  IMainEventPropertiesFormData,
  NO_BUSINESS_PARTNER_SELECTED,
  NO_COST_CENTER_SELECTED,
  NO_EVENT_CATEGORY_SELECTED,
  NO_MARKETING_LABEL_SELECTED
} from './common'

export const useSubmitActions = (event: DetailEventPropertiesFragment) => {
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const history = useHistory()
  const publishEvent = usePublishEvent()
  const cancelEvent = useCancelEvent()
  const defaultErrorHandler = useDefaultErrorHandler()
  const updateEvent = useUpdateExtendedEventData()

  const _onSubmit = async (formData: IMainEventPropertiesFormData) => {
    const formCostCenterId = formData[FormField.COST_CENTER_ID]
    const formMarketingLabelId = formData[FormField.MARKETING_LABEL_ID]
    const formEventCategoryId = formData[FormField.EVENT_CATEGORY_ID]
    const formBusinessPartnerId = formData[FormField.BUSINESS_PARTNER_ID]
    const dataToSubmit = {
      organizerNote: formData.organizerNote,
      showOnWebsiteAndApi: formData.showOnWebsiteAndApi,
      ticketNote: formData.ticketNote,
      divisionId: parseInt(formData.divisionId, 10),
      costCenterId:
        formCostCenterId && formCostCenterId !== NO_COST_CENTER_SELECTED
          ? parseInt(formCostCenterId, 10)
          : null,
      gateOpensAt: formData.gateOpensAt.toISOString(),
      gateClosedAt: formData.gateClosedAt.toISOString(),
      checkingOption: formData.checkingOption,
      marketingLabelId:
        formMarketingLabelId &&
        formMarketingLabelId !== NO_MARKETING_LABEL_SELECTED
          ? parseInt(formMarketingLabelId, 10)
          : null,
      eventCategoryId:
        formEventCategoryId &&
        formEventCategoryId !== NO_EVENT_CATEGORY_SELECTED
          ? parseInt(formEventCategoryId, 10)
          : null,
      businessPartnerId:
        formBusinessPartnerId &&
        formBusinessPartnerId !== NO_BUSINESS_PARTNER_SELECTED
          ? parseInt(formBusinessPartnerId, 10)
          : null,
      feePercentageOfSoldTickets: !isNil(
        formData[FormField.FEE_PERCENTAGE_OF_SOLD_TICKETS]
      )
        ? convertValueToFloat(
            formData[FormField.FEE_PERCENTAGE_OF_SOLD_TICKETS]
          )
        : null,
      feeFixedAmountPerSoldTicket: !isNil(
        formData[FormField.FEE_FIXED_AMOUNT_PER_SOLD_TICKET]
      )
        ? convertValueToFloat(
            formData[FormField.FEE_FIXED_AMOUNT_PER_SOLD_TICKET]
          )
        : null,
      feeFixedAmountPerEvent: !isNil(
        formData[FormField.FEE_FIXED_AMOUNT_PER_EVENT]
      )
        ? convertValueToFloat(formData[FormField.FEE_FIXED_AMOUNT_PER_EVENT])
        : null,
      feeMinimumGuarantee: !isNil(formData[FormField.FEE_MINIMUM_GUARANTEE])
        ? convertValueToFloat(formData[FormField.FEE_MINIMUM_GUARANTEE])
        : null,
      thirdPartyPurchaseURL:
        formData[FormField.THIRD_PARTY_PURCHASE_URL] || null
    }
    await updateEvent({
      id: event.id,
      data: dataToSubmit
    })
    history.push(routeTo.admin.events.home())
  }

  const onSuccess = () => {
    addInfoNotification(t('Event was saved.'))
  }

  const errorMsg = t('Error while saving event.')

  const onPublish = async (formData: IMainEventPropertiesFormData) => {
    try {
      await _onSubmit(formData)
      await publishEvent(event.id)
      onSuccess()
    } catch (err) {
      defaultErrorHandler(err, errorMsg)
    }
  }

  const onCancel = async (formData: IMainEventPropertiesFormData) => {
    try {
      await _onSubmit(formData)
      await cancelEvent(event.id)
      onSuccess()
    } catch (err) {
      defaultErrorHandler(err, errorMsg)
    }
  }

  const onSubmit = async (formData: IMainEventPropertiesFormData) => {
    try {
      await _onSubmit(formData)
      onSuccess()
    } catch (err) {
      defaultErrorHandler(err, errorMsg)
    }
  }

  return {onSubmit, onPublish, onCancel}
}

interface IUpdateOptions {
  handleSubmit: any
  onPublish: (formData: IMainEventPropertiesFormData) => Promise<any>
  onCancel: (formData: IMainEventPropertiesFormData) => Promise<any>
  state: EventState | null
}

export const useGetUpdateOptions = ({
  handleSubmit,
  onPublish,
  onCancel,
  state
}: IUpdateOptions) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  if (!state) return []
  if (state === EventState.Published && P([PermissionCode.CancelEvent])) {
    return [
      <SplitButtonModalItem
        key={1}
        onSubmit={handleSubmit(onCancel)}
        ensureCanOpenModal={handleSubmit}
        modalHeader={t('Cancel event')}
        modalMessage={t('Are you sure you want to cancel the event?')}
        modalSubmitLabel={t('Cancel Event')}
        buttonLabel={t('Save and Cancel Event')}
      />
    ]
  }
  if (state !== EventState.Published && P([PermissionCode.PublishEvent])) {
    return [
      <SplitButtonModalItem
        key={1}
        onSubmit={handleSubmit(onPublish)}
        ensureCanOpenModal={handleSubmit}
        modalHeader={t('Publish event')}
        modalMessage={t('Are you sure you want to publish the event?')}
        modalSubmitLabel={t('Publish')}
        buttonLabel={t('Save and publish')}
      />
    ]
  }
  return []
}
