import {useTranslation} from 'react-i18next'
import {AdmissionTypeState} from '../__generated__/schema'

export const useTranslateAdmissionTypeState = () => {
  const {t} = useTranslation()
  const translated: Record<AdmissionTypeState, string> = {
    [AdmissionTypeState.Active]: t('AdmissionTypeState->Active'),
    [AdmissionTypeState.Inactive]: t('AdmissionTypeState->Inactive'),
    [AdmissionTypeState.Draft]: t('AdmissionTypeState->Draft')
  }
  return (state: AdmissionTypeState) => translated[state]
}
