import {useLocation, useParams, useRouteMatch} from 'react-router-dom'
import {TemplateType} from '../__generated__/schema'

import {ShowContentType} from '../components/constants'
import {getUrlParam} from './routes'

export const VENUES_PARAMS = {
  VENUE_ID: 'venueId',
  AUDITORIUM_ID: 'auditoriumId',
  AUDITORIUM_LAYOUT_ID: 'auditoriumLayoutId',
  LAYOUT_PRICING_ID: 'layoutPricingId'
}

export const SHOWS_PARAMS = {
  SHOW_ID: 'showId',
  SECTION: 'editSection',
  ACTIVE_CONTENT_TYPE: 'activeContentType',
  SHOW_IMAGE_TYPE: 'showImageType'
}

export const EVENTS_PARAMS = {
  EVENT_ID: 'eventId'
}

export const DISCOUNTS_PARAMS = {
  DISCOUNT_ID: 'discountId'
}

export const RESERVATION_PARAMS = {
  RESERVATION_ID: 'reservationId'
}

export const useVenuesPathnameParams = () => {
  const match = useRouteMatch()

  const venueId = parseInt(getUrlParam(match, VENUES_PARAMS.VENUE_ID), 10)
  const auditoriumId = parseInt(
    getUrlParam(match, VENUES_PARAMS.AUDITORIUM_ID),
    10
  )
  const auditoriumLayoutId = parseInt(
    getUrlParam(match, VENUES_PARAMS.AUDITORIUM_LAYOUT_ID),
    10
  )
  const layoutPricingId = parseInt(
    getUrlParam(match, VENUES_PARAMS.LAYOUT_PRICING_ID),
    10
  )

  return {venueId, auditoriumId, auditoriumLayoutId, layoutPricingId}
}

export const useShowsPathnameParams = () => {
  const match = useRouteMatch()
  const location = useLocation()

  const showId = parseInt(getUrlParam(match, SHOWS_PARAMS.SHOW_ID), 10)
  const editSection = getUrlParam(match, SHOWS_PARAMS.SECTION)
  let activeContentType =
    (location.hash && location.hash.substring(1)) ||
    getUrlParam(match, SHOWS_PARAMS.ACTIVE_CONTENT_TYPE)
  if (
    !Object.values(ShowContentType).includes(
      activeContentType as ShowContentType
    )
  )
    activeContentType = ShowContentType.Posters // default content type

  return {showId, editSection, activeContentType}
}

export const useEventsPathnameParams = () => {
  const match = useRouteMatch()

  const eventId = parseInt(getUrlParam(match, EVENTS_PARAMS.EVENT_ID), 10)

  return {eventId}
}

export const useReservationPathnameParams = () => {
  const match = useRouteMatch()

  const reservationId = parseInt(
    getUrlParam(match, RESERVATION_PARAMS.RESERVATION_ID),
    10
  )

  return {reservationId}
}
export const useCartDetailParams = () => {
  const {cartId} = useParams<{cartId: string}>()
  return {cartId: parseInt(cartId, 10)}
}

export const useClaimDetailParams = () => {
  const {claimId} = useParams<{claimId: string}>()
  return {claimId: parseInt(claimId, 10)}
}

export const usePaymentMethodParams = () => {
  const {paymentMethodId} = useParams<{paymentMethodId: string}>()
  const {groupId} = useParams<{groupId: string}>()
  return {
    paymentMethodId: parseInt(paymentMethodId, 10),
    groupId: parseInt(groupId, 10)
  }
}

export const useRoleParams = () => {
  const {roleId} = useParams<{roleId: string}>()
  return {roleId: parseInt(roleId, 10)}
}

export const useItemParams = () => {
  const {itemId} = useParams<{itemId: string}>()
  return {itemId: parseInt(itemId, 10)}
}

export const useShowParams = () => {
  const {showId} = useParams<{showId: string}>()
  const {editSection} = useParams<{editSection: string}>()
  const {activeContentType} = useParams<{activeContentType: string}>()
  return {showId: parseInt(showId, 10), editSection, activeContentType}
}

export const useTemplateParams = () => {
  const {templateId} = useParams<{templateId: string}>()
  const {templateType} = useParams<{templateType: string}>()
  return {
    templateId: parseInt(templateId, 10),
    templateType: templateType as TemplateType
  }
}

export const useCostCenterParams = () => {
  const {costCenterId} = useParams<{costCenterId: string}>()
  return {
    costCenterId: parseInt(costCenterId, 10)
  }
}

export const useProductTypeParams = () => {
  const {productTypeId} = useParams<{productTypeId: string}>()
  return {
    productTypeId: parseInt(productTypeId, 10)
  }
}

export const useProductParams = () => {
  const {productId} = useParams<{productId: string}>()
  const {productGroupId} = useParams<{productGroupId: string}>()
  const {warehouseProductId} = useParams<{warehouseProductId: string}>()
  return {
    productId: parseInt(productId, 10),
    productGroupId: parseInt(productGroupId, 10),
    warehouseProductId: parseInt(warehouseProductId, 10)
  }
}

export const useMarketingLabelParams = () => {
  const {marketingLabelId} = useParams<{marketingLabelId: string}>()
  return {
    marketingLabelId: parseInt(marketingLabelId, 10)
  }
}

export const useEventCategoryParams = () => {
  const {eventCategoryId} = useParams<{eventCategoryId: string}>()
  return {
    eventCategoryId: parseInt(eventCategoryId, 10)
  }
}

export const useDivisionParams = () => {
  const {divisionId} = useParams<{divisionId: string}>()
  return {
    divisionId: parseInt(divisionId, 10)
  }
}

export const useMessageParams = () => {
  const {messageId} = useParams<{messageId: string}>()
  return {
    messageId: parseInt(messageId, 10)
  }
}

export const useVoucherParams = () => {
  const {voucherCampaignId} = useParams<{voucherCampaignId: string}>()
  const {voucherId} = useParams<{voucherId: string}>()
  return {
    voucherCampaignId: parseInt(voucherCampaignId, 10),
    voucherId: parseInt(voucherId, 10)
  }
}

export const useCustomerParams = () => {
  const {customerGroupId} = useParams<{customerGroupId: string}>()
  const {customerId} = useParams<{customerId: string}>()
  return {
    customerGroupId: parseInt(customerGroupId, 10),
    customerId: parseInt(customerId, 10)
  }
}

export const useTourParams = () => {
  const {admissionTypeId} = useParams<{admissionTypeId: string}>()
  const {tourId} = useParams<{tourId: string}>()
  const {admissionRateId} = useParams<{admissionRateId: string}>()
  const {tourTimeSlotId} = useParams<{tourTimeSlotId: string}>()
  const {accessZoneId} = useParams<{accessZoneId: string}>()
  return {
    admissionTypeId: parseInt(admissionTypeId, 10),
    tourId: parseInt(tourId, 10),
    admissionRateId: parseInt(admissionRateId, 10),
    tourTimeSlotId: parseInt(tourTimeSlotId, 10),
    accessZoneId: parseInt(accessZoneId, 10)
  }
}

export const usePaymentReportParams = () => {
  const {paymentReportId} = useParams<{paymentReportId: string}>()
  return {paymentReportId: parseInt(paymentReportId, 10)}
}

export const useApiGrantParams = () => {
  const {apiGrantId} = useParams<{apiGrantId: string}>()
  return {apiGrantId: parseInt(apiGrantId, 10)}
}

export const useCheckPassCodeParams = () => {
  const {entityId, eventId, checkType, passCode} =
    useParams<{
      entityId: string
      eventId: string
      checkType: string
      passCode: string
    }>()
  return {
    entityId: parseInt(entityId, 10),
    eventId: parseInt(eventId, 10),
    checkType,
    passCode
  }
}

export const useWarehouseParams = () => {
  const {warehouseId, warehouseDocumentId, inventoryCheckId} =
    useParams<{
      warehouseId: string
      warehouseDocumentId: string
      inventoryCheckId: string
    }>()
  return {
    warehouseId: parseInt(warehouseId, 10),
    warehouseDocumentId: parseInt(warehouseDocumentId, 10),
    inventoryCheckId: parseInt(inventoryCheckId, 10)
  }
}

export const useBusinessPartnerParams = () => {
  const {businessPartnerId} = useParams<{businessPartnerId: string}>()
  return {businessPartnerId: parseInt(businessPartnerId, 10)}
}

export const useWebsiteParams = () => {
  const {websiteId} = useParams<{websiteId: string}>()
  return {websiteId: parseInt(websiteId, 10)}
}
