import RefreshIcon from '@mui/icons-material/Refresh'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import {Box, Button, Typography} from '@mui/material'
import i18next from 'i18next'
import React, {ReactNode} from 'react'
import {reportErrorToSentry, SentryEventId} from '../../utils/sentry'
import {routeTo} from '../../utils/routes'

type BoundaryState = {
  hasError: boolean
  eventId?: SentryEventId
}

type BoundaryProps = {
  children: ReactNode
}

export class DefaultErrorBoundary extends React.Component<
  BoundaryProps,
  BoundaryState
> {
  constructor(props: BoundaryProps) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return {hasError: true}
  }

  componentDidCatch(error: any, errorInfo: any) {
    reportErrorToSentry(error, errorInfo, (eventId) =>
      this.setState((state) => ({...state, eventId}))
    )
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'grid',
            height: '95vh',
            alignItems: 'center',
            justifyItems: 'center',
            gridTemplateRows: '1fr'
          }}
        >
          <Box>
            <Box
              sx={{
                padding: '16px',
                maxWidth: 360,
                width: 360,
                display: 'grid',
                gap: '24px',
                alignItems: 'center',
                justifyItems: 'center',
                textAlign: 'center'
              }}
            >
              <SentimentDissatisfiedIcon
                sx={{width: 64, height: 64, color: 'text.secondary'}}
              />
              <div>
                <Typography variant="h6">
                  {i18next.t('Something went wrong!')}
                </Typography>
                <Typography sx={{paddingTop: '16px'}} color="textSecondary">
                  {i18next.t(
                    'Please try to reload the page or return to the homepage. If the error persists, contact support.'
                  )}
                </Typography>
              </div>
              <Box
                sx={{
                  display: 'inline-grid',
                  gridAutoFlow: 'column',
                  gap: '16px'
                }}
              >
                <Button
                  variant="text"
                  onClick={() =>
                    (window.location.href = routeTo.admin.dashboard())
                  }
                >
                  {i18next.t('Homepage')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon />}
                  onClick={() => location.reload()}
                >
                  {i18next.t('Reload')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
    return this.props.children
  }
}
