import {Button, Divider, Drawer} from '@mui/material'
import {ApolloError} from 'apollo-client'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AuditoriumLayoutPricingState,
  PermissionCode,
  TicketTypePricingPropertiesFragment
} from '../../../../../../../__generated__/schema'
import {usePricingActions} from '../../../../../../../editor/redux/pricing/actions'
import {useEnsurePermissions} from '../../../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../../../utils/errors'
import {useVenuesPathnameParams} from '../../../../../../../utils/pathname'
import {CustomDrawerActionsBar} from '../../../../../../common'
import {DeleteTicketTypeButton} from '../../../EditActionsDialogs'
import {TicketColor} from '../common'
import {useDeleteTicketType, useUpdateTicketType} from '../graphql'
import {
  ITicketFormData,
  TICKET_FORM_ID,
  TicketForm,
  ticketTypeFromTicketTypeForm
} from '../TicketForm'
import {SideDrawerContent} from './common'

interface IEditTicketTypeDrawerProps {
  open: boolean
  onClose: () => any
  ticketType: TicketTypePricingPropertiesFragment | undefined
  unavailableColors: Array<TicketColor>
  layoutPricingState: AuditoriumLayoutPricingState
}

const useHandlers = (
  ticketType: TicketTypePricingPropertiesFragment | undefined,
  onClose: () => any
) => {
  const {t} = useTranslation()
  const {auditoriumLayoutId, layoutPricingId} = useVenuesPathnameParams()

  const updateTicketType = useUpdateTicketType()
  const deleteTicketType = useDeleteTicketType(
    auditoriumLayoutId,
    layoutPricingId
  )
  const defaultErrorHandler = useDefaultErrorHandler()

  const {removeTicketType, updateTicketTypeColor} = usePricingActions()

  const onSubmit = async (data: ITicketFormData) => {
    if (!ticketType) return

    const updatedTicketType = ticketTypeFromTicketTypeForm(data)
    await updateTicketType(ticketType.id, updatedTicketType)
    if (ticketType.color !== updatedTicketType.color) {
      updateTicketTypeColor({
        id: ticketType.id,
        color: updatedTicketType.color
      })
    }
    onClose()
  }

  // TODO: confirmation modal
  const onDelete = async () => {
    // TODO: error handling
    if (!ticketType) return
    await deleteTicketType(ticketType.id)
    removeTicketType(ticketType.id)
    onClose()
  }

  const onError = (err: ApolloError) => {
    defaultErrorHandler(err, t('Error while updating ticket type'))
  }

  return {onDelete, onError, onSubmit}
}

export const EditTicketTypeDrawer: React.FC<IEditTicketTypeDrawerProps> = ({
  onClose,
  open,
  ticketType,
  unavailableColors,
  layoutPricingState
}: IEditTicketTypeDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {onSubmit, onError, onDelete} = useHandlers(ticketType, onClose)

  return (
    <Drawer anchor="right" {...{open, onClose}}>
      <SideDrawerContent>
        {ticketType && (
          <TicketForm
            title={t('Edit ticket type')}
            defaultValues={{
              ...ticketType,
              color: ticketType.color as TicketColor,
              price:
                ticketType.price != null
                  ? `${ticketType.price}`
                  : ticketType.price
            }}
            {...{onClose, unavailableColors, onSubmit, onError}}
            ActionBar={
              <CustomDrawerActionsBar>
                {layoutPricingState === AuditoriumLayoutPricingState.Draft &&
                  P([PermissionCode.DeleteTicketType]) && (
                    <>
                      <DeleteTicketTypeButton onSubmit={onDelete} />
                      <Divider orientation="vertical" />
                    </>
                  )}
                <Button color="primary" onClick={onClose}>
                  {t('Cancel')}
                </Button>
                {P([PermissionCode.UpdateTicketType]) && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    form={TICKET_FORM_ID}
                  >
                    {t('Save')}
                  </Button>
                )}
              </CustomDrawerActionsBar>
            }
          />
        )}
      </SideDrawerContent>
    </Drawer>
  )
}
