import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'

import {
  EventWithSeatsQuery,
  EventWithSeatsQueryVariables
} from '../../../../../../__generated__/schema'
import {TRANSLATED_LOCALES_FRAGMENT} from '../../../graphql'

export const EVENT_WITH_SEATS_PROPERTIES_FRAGMENT = gql`
  fragment EventWithSeatsProperties on Event {
    id
    startsAt
    state
    names {
      ...TranslatedLocales
    }
    venue {
      id
      name
      address {
        street
        complex
        town
        postalCode
        country
      }
    }
    auditorium {
      id
      name
    }
    auditoriumLayout {
      id
      layout
    }
    auditoriumLayoutPricing {
      id
      pricing
      ticketTypes {
        id
        color
      }
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const GET_EVENT_SEATS_DATA = gql`
  query eventWithSeats($id: Int!) {
    event(id: $id) {
      ...EventWithSeatsProperties
    }
  }
  ${EVENT_WITH_SEATS_PROPERTIES_FRAGMENT}
`

export const useGetEventSeatsData = (id: number) => {
  return useQuery<EventWithSeatsQuery, EventWithSeatsQueryVariables>(
    GET_EVENT_SEATS_DATA,
    {
      variables: {id}
    }
  )
}

// TODO: event seats loading using sockets
