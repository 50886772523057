import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {Controller, FieldValues, FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PaymentMethodPropertiesFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {useTranslatePaymentMethodType} from '../../../../hooks/translatePaymentMethodType'
import {PRIMARY_50_COLOR} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {Blank} from '../../../visual/Blank'
import {calculateCeil} from '../utils'

const useGetRetailPaymentMethodDescription = (claimPrice: number) => {
  const translatePaymentMethodType = useTranslatePaymentMethodType()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (paymentMethod: PaymentMethodPropertiesFragment) => {
    if (paymentMethod.hasDenomination) {
      return `${translatePaymentMethodType(
        paymentMethod.type
      )} • ${calculateCeil(
        claimPrice,
        paymentMethod.value
      )}x ${translateEffectiveClientPrice(paymentMethod.value)}`
    }
    return translatePaymentMethodType(paymentMethod.type)
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  radioGroup: {
    gap: theme.spacing(1)
  },
  formControlLabelRoot: {
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    }
  },
  formControlLabelRootSelected: {
    display: 'flex',
    borderColor: theme.palette.primary.main,
    backgroundColor: PRIMARY_50_COLOR
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(0.5)
  },
  label: {
    width: '100%'
  },
  title: {
    paddingBottom: theme.spacing(1)
  }
}))

interface IAvailableRefundMethodsProps<
  FormValues extends FieldValues = FieldValues
> {
  paymentMethods?: PaymentMethodPropertiesFragment[]
  claimPrice: number
  pmTotalRefundValue: number
  name: string
  watch: FormContextValues<FormValues>['watch']
  control: FormContextValues<FormValues>['control']
}

export const AvailableRefundMethods = <
  FormValues extends FieldValues = FieldValues
>({
  paymentMethods,
  claimPrice,
  pmTotalRefundValue,
  name,
  watch,
  control
}: IAvailableRefundMethodsProps<FormValues>) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const history = useHistory()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const getRetailPaymentMethodDescription =
    useGetRetailPaymentMethodDescription(claimPrice)
  const selectedType = watch(name)
  const handlePaymentMethodsButtonClick = useCallback(
    () => history.push(routeTo.admin.paymentMethods.home()),
    [history]
  )
  const paymentMethodsAvailableForRefund = paymentMethods?.filter(
    (pm) => pm.isAvailableForRefunds
  )
  return paymentMethodsAvailableForRefund?.length ? (
    <>
      <Typography variant="subtitle1" className={classes.title}>
        {t('Refund method')}
      </Typography>
      <Controller
        as={(props) => (
          <RadioGroup {...props} classes={{root: classes.radioGroup}}>
            {paymentMethodsAvailableForRefund.map((pm) => (
              <FormControlLabel
                key={String(pm.id)}
                value={String(pm.id)}
                label={
                  <div className={classes.labelBox}>
                    <div>
                      <Typography variant="subtitle2">{pm.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {getRetailPaymentMethodDescription(pm)}
                      </Typography>
                    </div>
                    {selectedType === String(pm.id) && (
                      <Typography variant="body2">
                        {translateEffectiveClientPrice(pmTotalRefundValue)}
                      </Typography>
                    )}
                  </div>
                }
                control={<Radio color="primary" />}
                classes={{
                  root: cn(classes.formControlLabelRoot, {
                    [classes.formControlLabelRootSelected]:
                      selectedType === String(pm.id)
                  }),
                  label: classes.label
                }}
              />
            ))}
          </RadioGroup>
        )}
        control={control}
        name={name}
      />
    </>
  ) : (
    <Blank
      title={t('Payment methods not found')}
      description={t(
        'Refund can’t be provided, because no payment methods allowed for refund were found. It can be changed in your payment methods settings.'
      )}
      actions={
        P([PermissionCode.ManagePaymentMethods]) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePaymentMethodsButtonClick}
          >
            {t('Payment methods')}
          </Button>
        )
      }
    />
  )
}
