import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {useProductParams} from '../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useUpdateWarehouseProduct, useWarehouseProduct} from '../graphql'
import {IWarehouseProductForm, WarehouseProductFormField} from './types'
import {WarehouseProductForm} from './WarehouseProductForm'

const UPDATE_WAREHOUSE_PRODUCT_FORM_ID = 'updateWarehouseProductForm'

interface IUpdateWarehouseProductDrawerProps {
  onExited: () => void
}

export const UpdateWarehouseProductDrawer: React.FC<IUpdateWarehouseProductDrawerProps> =
  ({onExited}: IUpdateWarehouseProductDrawerProps) => {
    const {t} = useTranslation()
    const {productId, warehouseProductId} = useProductParams()
    const updateWarehouseProduct = useUpdateWarehouseProduct()
    const {
      addInfoNotification,
      defaultErrorHandler,
      setShowBackdrop,
      customErrorHandler
    } = useMutationAssistanceHooks()
    const {data, loading, error} = useWarehouseProduct(
      warehouseProductId,
      isNaN(warehouseProductId)
    )
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const handleSubmit = useCallback(
      async (formData: IWarehouseProductForm) => {
        try {
          setShowBackdrop(true)
          await updateWarehouseProduct(
            {
              id: warehouseProductId,
              input: {
                isNegativeStockEnabled:
                  formData[WarehouseProductFormField.IsNegativeStockEnabled],
                minStockLevel: formData[WarehouseProductFormField.MinStockLevel]
                  ? convertValueToFloat(
                      formData[WarehouseProductFormField.MinStockLevel]
                    )
                  : null,
                optimalStockLevel: formData[
                  WarehouseProductFormField.OptimalStockLevel
                ]
                  ? convertValueToFloat(
                      formData[WarehouseProductFormField.OptimalStockLevel]
                    )
                  : null
              }
            },
            productId
          )
          addInfoNotification(t('Warehouse product has been updated'))
          closeDrawer()
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.WarehouseProductStockIsNegative
            )
          ) {
            customErrorHandler(error, {
              title: t('Product has negative stock yet'),
              contentText: t(
                'Negative stock levels are currently enabled, but disabling this option is not possible because product’s stock level is below zero. Please adjust the stock levels to zero or positive values before disabling negative stock levels.'
              ),
              confirmButtonLabel: t('Got it'),
              onConfirm: () => window.location.reload()
            })
          } else {
            defaultErrorHandler(
              error,
              t('Error while updating warehouse product')
            )
          }
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        customErrorHandler,
        defaultErrorHandler,
        productId,
        setShowBackdrop,
        t,
        updateWarehouseProduct,
        warehouseProductId
      ]
    )
    useEffect(() => {
      if (!isNaN(productId) && !isNaN(warehouseProductId)) {
        openDrawer()
      }
    }, [openDrawer, productId, warehouseProductId])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update warehouse product')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_WAREHOUSE_PRODUCT_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          isLoading={loading}
          errorMessage={
            error && t<string>('Error while loading warehouse product')
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {data && (
            <WarehouseProductForm
              defaultValues={{
                [WarehouseProductFormField.IsNegativeStockEnabled]:
                  data.warehouseProduct.isNegativeStockEnabled,
                [WarehouseProductFormField.MinStockLevel]:
                  data.warehouseProduct.minStockLevel || undefined,
                [WarehouseProductFormField.OptimalStockLevel]:
                  data.warehouseProduct.optimalStockLevel || undefined
              }}
              formId={UPDATE_WAREHOUSE_PRODUCT_FORM_ID}
              onSubmit={handleSubmit}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
