import {CanvasObjectType, ISeat, IZone} from '@attendio/shared-components'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ApiSeatState,
  EventWithSeatsPropertiesFragment,
  EventWithSeatsQuery
} from '../../../../../../__generated__/schema'
import {useSelector} from '../../../../../../editor/redux'
import {DisplayMode} from '../../../../../../editor/redux/displayMode/reducer'
import {selectedObjectsSelector} from '../../../../../../editor/redux/objects/selectors'
import {Theme} from '../../../../../../theme'
import {useEventsPathnameParams} from '../../../../../../utils/pathname'

import {DrawerForm, RenderOnData} from '../../../../../common'
import {HEIGHT} from '../../../../../common/DrawerUtils'
import {Loading} from '../../../../../visual'

import {
  AuditoriumEditorLayout,
  FullWidthEditor
} from '../../../venues/editorUtils'

import {
  useCommonFormStyles,
  useDrawerHeaderTitle,
  useGetBackToGeneralInfoLink
} from '../common'
import {
  useInitEditor,
  useOnSelectionChange,
  useUpdateEditorSeatsStates
} from './editorUtils'
import {useGetEventSeatsData} from './graphql'
import {InfoSidebar} from './InfoSidebar'
import {SeatsSidebar} from './SeatsSidebar'
import {SeatsStateProvider, useSeatsState} from './seatsStateContext'
import {ZoneSidebar} from './ZoneSidebar'

const useStyles = makeStyles<Theme>((theme) => ({
  editorWrapper: {
    background: theme.palette.background.default
  }
}))

interface IEventSeatsDataLoaderProps {
  height: number
}

interface IEventSeatsContentProps extends IEventSeatsDataLoaderProps {
  data: EventWithSeatsPropertiesFragment
}

const EventSeatsContent: React.FC<IEventSeatsContentProps> = ({
  height,
  data
}: IEventSeatsContentProps) => {
  const commonClasses = useCommonFormStyles()
  const classes = useStyles()
  const history = useHistory()
  const backLink = useGetBackToGeneralInfoLink()

  const onClose = () => history.replace(backLink)
  const drawerHeaderTitle = useDrawerHeaderTitle()

  const selectedObjects = useSelector(selectedObjectsSelector)
  const {seatsState} = useSeatsState()

  useUpdateEditorSeatsStates(data)
  useOnSelectionChange()

  const showZoneForm =
    selectedObjects.length > 0 &&
    selectedObjects.some((o) => o.type !== CanvasObjectType.Seat)

  // If seats are selected in editor, but are not selectable from backend point
  // of view, we consider nothing to be selected.
  // Note: we can not apply same approach to zone, as we want zone to be selected
  // even if there is nothing to select, otherwise UI looks non-reactive.
  const hasSeatsSelectedByMe = !!(
    seatsState &&
    Object.values(seatsState.seats).some(
      (s) => s.state === ApiSeatState.SelectedByMe
    )
  )

  return (
    <DrawerForm
      title={drawerHeaderTitle}
      wrapperClassName={commonClasses.drawerFormWrapper}
      bodyClassName={cn(commonClasses.drawerForm, classes.editorWrapper)}
      width={window.innerWidth}
      onClose={onClose}
    >
      {seatsState ? (
        <FullWidthEditor
          // Not using `height` from `AuditoriumEditorLayout` as when first rendered
          // it is rendered within drawer that does not have full width and thus
          // the dimensions are wrong.
          // TODO: make `AuditoriumEditorLayout` reflect change in dimensions.
          width={window.innerWidth}
          // Magic 1px
          height={height - HEIGHT - 1}
          displayMode={DisplayMode.CASH}
          fullHeight
        >
          <>
            {selectedObjects.length > 0 ? (
              <>
                {showZoneForm && (
                  <ZoneSidebar
                    selectedZone={
                      selectedObjects.map((o) => o.config as IZone)[0]
                    }
                  />
                )}
                {hasSeatsSelectedByMe && (
                  <SeatsSidebar
                    selectedSeats={selectedObjects.map(
                      (o) => o.config as ISeat
                    )}
                  />
                )}
                {!showZoneForm && !hasSeatsSelectedByMe && (
                  <InfoSidebar data={data} />
                )}
              </>
            ) : (
              <InfoSidebar data={data} />
            )}
          </>
        </FullWidthEditor>
      ) : (
        <Loading />
      )}
    </DrawerForm>
  )
}

const EventSeatsDataLoader: React.FC<IEventSeatsDataLoaderProps> = ({
  height
}: IEventSeatsDataLoaderProps) => {
  const {t} = useTranslation()
  const {eventId} = useEventsPathnameParams()

  const {error, loading, data} = useGetEventSeatsData(eventId)

  useInitEditor(data ? data.event : null)

  return (
    <RenderOnData
      {...{data, loading, error}}
      errorMessage={t<string>('Could not load event seats data.')}
    >
      {(data: EventWithSeatsQuery) => {
        return <EventSeatsContent height={height} data={data.event} />
      }}
    </RenderOnData>
  )
}

export const EventSeats = () => {
  const {eventId} = useEventsPathnameParams()
  return (
    <SeatsStateProvider eventId={eventId}>
      <AuditoriumEditorLayout>
        {(dimensions) => <EventSeatsDataLoader height={dimensions.height} />}
      </AuditoriumEditorLayout>
    </SeatsStateProvider>
  )
}
