import {useTranslation} from 'react-i18next'
import validator from 'validator'
import {
  AdmissionRateAssignmentsInput,
  AdmissionRateQuery,
  DraftAdmissionRateAssignmentsInput,
  NarrowAdmissionTypeFieldsFragment
} from '../../../../../../__generated__/schema'

export const transformAdmissionTypesToAssignmentInputs = (
  admissionTypes: NarrowAdmissionTypeFieldsFragment[],
  admissionTypeAssignments: AdmissionRateQuery['admissionRate']['admissionTypesAssignments'],
  defaultVatRate?: number | null
): DraftAdmissionRateAssignmentsInput[] =>
  admissionTypes.map((admissionType) => {
    const assignment = admissionTypeAssignments.find(
      ({admissionTypeId}) => admissionTypeId === admissionType.id
    )
    return assignment
      ? {
          id: assignment.id,
          admissionTypeId: assignment.admissionTypeId,
          retailPrice: assignment.retailPrice,
          retailVatRate: assignment.retailVatRate,
          enabledOnRetail: assignment.enabledOnRetail,
          hasEnabledDiscountsOnRetail: assignment.hasEnabledDiscountsOnRetail,
          eCommercePrice: assignment.eCommercePrice,
          eCommerceVatRate: assignment.retailVatRate,
          enabledOnECommerce: assignment.enabledOnECommerce,
          hasEnabledDiscountsOnECommerce:
            assignment.hasEnabledDiscountsOnECommerce,
          triggerCheckNotification: assignment.triggerCheckNotification,
          limitPerTimeSlot: assignment.limitPerTimeSlot
        }
      : {
          admissionTypeId: admissionType.id,
          retailPrice: 0,
          retailVatRate: defaultVatRate || 0,
          enabledOnRetail: false,
          hasEnabledDiscountsOnRetail: false,
          eCommercePrice: 0,
          eCommerceVatRate: defaultVatRate || 0,
          enabledOnECommerce: false,
          hasEnabledDiscountsOnECommerce: false,
          triggerCheckNotification: false
        }
  })

export const transformDraftAdmissionTypeAssignmentsToAssignmentInputs = (
  data: AdmissionRateQuery['admissionRate']['admissionTypesAssignments']
): DraftAdmissionRateAssignmentsInput[] =>
  data.map((row) => ({
    id: row.id,
    admissionTypeId: row.admissionTypeId,
    retailPrice: row.retailPrice,
    retailVatRate: row.retailVatRate,
    enabledOnRetail: row.enabledOnRetail,
    hasEnabledDiscountsOnRetail: row.hasEnabledDiscountsOnRetail,
    eCommercePrice: row.eCommercePrice,
    eCommerceVatRate: row.retailVatRate,
    enabledOnECommerce: row.enabledOnECommerce,
    hasEnabledDiscountsOnECommerce: row.hasEnabledDiscountsOnECommerce,
    triggerCheckNotification: row.triggerCheckNotification,
    limitPerTimeSlot: row.limitPerTimeSlot
  }))

export const transformAdmissionTypeAssignmentsToAssignmentInputs = (
  data: AdmissionRateQuery['admissionRate']['admissionTypesAssignments']
): AdmissionRateAssignmentsInput[] =>
  data.map((row) => ({
    id: row.id,
    enabledOnRetail: row.enabledOnRetail,
    hasEnabledDiscountsOnRetail: row.hasEnabledDiscountsOnRetail,
    enabledOnECommerce: row.enabledOnECommerce,
    hasEnabledDiscountsOnECommerce: row.hasEnabledDiscountsOnECommerce,
    limitPerTimeSlot: row.limitPerTimeSlot,
    triggerCheckNotification: row.triggerCheckNotification
  }))

export const replaceCommaWithDot = (value: string) => value.replace(',', '.')

export const useIsNonNegativeNumber = () => {
  const {t} = useTranslation()
  return (value: string) =>
    !value || !validator.isFloat(replaceCommaWithDot(value), {min: 0})
      ? t('Requires non-negative number')
      : null
}

export const useIsPositiveInteger = () => {
  const {t} = useTranslation()
  return (value: string) =>
    !value || !validator.isInt(value, {min: 1})
      ? t('Requires positive integer')
      : null
}
