import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  AggregatedEventStatisticsByItemPriceQuery,
  AggregatedEventStatisticsByItemPriceQueryVariables,
  GetLightweightAggregatedEventStatisticsByItemPriceQuery,
  GetLightweightAggregatedEventStatisticsByItemPriceQueryVariables,
  GetOccupancyDataQuery,
  GetOccupancyDataQueryVariables,
  GetReservationsContactsQuery,
  GetReservationsContactsQueryVariables,
  GetSalesContactsQuery,
  GetSalesContactsQueryVariables,
  GetSalesItemsQuery,
  GetSalesItemsQueryVariables
} from '../../../__generated__/schema'
import {
  LEAD_PROPERTIES_FRAGMENT,
  TRANSLATED_LOCALES_FRAGMENT
} from '../../pages/admin/graphql'
import {PAGINATION_FRAGMENT} from '../../pages/graphql'

export const GET_EVENT_DETAILS = gql`
  query getLightweightAggregatedEventStatisticsByItemPrice(
    $eventId: PositiveInt!
  ) {
    aggregatedEventStatisticsByItemPrice(eventId: $eventId) {
      names {
        ...TranslatedLocales
      }
      startsAt
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

export const useGetLightweightEventStatistics = (eventId: number) =>
  useQuery<
    GetLightweightAggregatedEventStatisticsByItemPriceQuery,
    GetLightweightAggregatedEventStatisticsByItemPriceQueryVariables
  >(GET_EVENT_DETAILS, {
    variables: {eventId},
    fetchPolicy: 'network-only'
  })

const GET_SALES_ITEMS = gql`
  ${PAGINATION_FRAGMENT}
  query getSalesItems(
    $filter: SaleFilterInput
    $paginationInput: PaginationInput
  ) {
    sales(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        createdAt
        sellingChannel
        event {
          id
          startsAt
        }
        items {
          ... on TicketItem {
            id
            passCode
            price
            claim {
              id
              state
            }
            eventPricingToTicketType {
              id
              name
            }
            appliedDiscounts {
              discount {
                id
                name
              }
            }
            priceVatExcluded
            vat
            vatRate
            eventSeat {
              id
              label
              row
              section
              floor
            }
            checkStatus
            totalPassCodeChecksCount
            checkedInCount
            checkedOutCount
          }
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useGetSalesItems = (variables?: GetSalesItemsQueryVariables) =>
  useQuery<GetSalesItemsQuery, GetSalesItemsQueryVariables>(GET_SALES_ITEMS, {
    variables,
    fetchPolicy: 'network-only'
  })

const GET_SALES_CONTACTS = gql`
  query getSalesContacts(
    $filter: SaleFilterInput
    $paginationInput: PaginationInput
  ) {
    sales(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        createdAt
        sellingChannel
        price
        event {
          id
          startsAt
        }
        cart {
          id
        }
        lead {
          ...LeadProperties
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const useGetSalesContacts = (
  variables?: GetSalesContactsQueryVariables
) =>
  useQuery<GetSalesContactsQuery, GetSalesContactsQueryVariables>(
    GET_SALES_CONTACTS,
    {
      variables,
      fetchPolicy: 'network-only'
    }
  )

const GET_RESERVATIONS_CONTACTS = gql`
  query getReservationsContacts(
    $filter: ReservationsFilterInput
    $paginationInput: PaginationInput!
    $eventId: Int!
  ) {
    event(id: $eventId) {
      id
      startsAt
    }
    reservations(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        cart {
          id
        }
        createdAt
        sellingChannel
        price
        lead {
          ...LeadProperties
        }
      }
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const useGetReservationsContacts = (
  args: GetReservationsContactsQueryVariables
) =>
  useQuery<GetReservationsContactsQuery, GetReservationsContactsQueryVariables>(
    GET_RESERVATIONS_CONTACTS,
    {
      variables: {...args},
      fetchPolicy: 'network-only'
    }
  )

const GET_AGGREGATED_EVENT_STATISTICS_BY_ITEM_PRICE = gql`
  query aggregatedEventStatisticsByItemPrice($eventId: PositiveInt!) {
    aggregatedEventStatisticsByItemPrice(eventId: $eventId) {
      eventId
      saleTicketCountOnRetailChannel
      saleSumOnRetailChannel
      saleTicketCountOnEcommerceChannel
      saleSumOnEcommerceChannel
      refundTicketCountOnRetailChannel
      refundSumOnRetailChannel
      refundTicketCountOnEcommerceChannel
      refundSumOnEcommerceChannel
      profitTicketCount
      profit
      venue {
        id
        name
        address {
          complex
          street
          postalCode
          town
          country
        }
      }
      division {
        id
        client {
          id
          name
          companyIdNumber
          TAXId
          VATId
          legalAddress {
            complex
            street
            postalCode
            town
            country
          }
        }
        name
        address {
          complex
          street
          postalCode
          town
          country
        }
      }
      aggregatedStatistics {
        id
        ticketTypeName
        itemPriceBeforeDiscount
        discountName
        discountValue
        discountType
        itemPrice
        saleTicketCountOnRetailChannel
        saleSumOnRetailChannel
        saleTicketCountOnEcommerceChannel
        saleSumOnEcommerceChannel
        refundTicketCountOnRetailChannel
        refundSumOnRetailChannel
        refundTicketCountOnEcommerceChannel
        refundSumOnEcommerceChannel
        profitTicketCount
        profit
      }
    }
  }
`

export const useGetAggregatedEventStatisticsByItemPrice = (
  variables: AggregatedEventStatisticsByItemPriceQueryVariables
) =>
  useQuery<
    AggregatedEventStatisticsByItemPriceQuery,
    AggregatedEventStatisticsByItemPriceQueryVariables
  >(GET_AGGREGATED_EVENT_STATISTICS_BY_ITEM_PRICE, {
    variables,
    fetchPolicy: 'network-only'
  })

const GET_OCCUPANCY_DATA = gql`
  query GetOccupancyData($eventId: Int!) {
    eventSeats(eventId: $eventId)
    event(id: $eventId) {
      id
      auditoriumLayout {
        id
        layout
      }
    }
  }
`

export const useGetOccupancyData = (
  variables: GetOccupancyDataQueryVariables
) =>
  useQuery<GetOccupancyDataQuery, GetOccupancyDataQueryVariables>(
    GET_OCCUPANCY_DATA,
    {variables, fetchPolicy: 'network-only'}
  )
