import {styled} from '@mui/system'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  AvailableInputDataForMonthlyECommerceReportsQuery,
  AvailableInputDataForMonthlyReportsQuery
} from '../../../../__generated__/schema'
import {useTranslateMonth} from '../../../../hooks/translateMonth'
import {InputRow} from '../../../common'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../form/UncontrolledFormRadioGroup'
import {Blank} from '../../../visual/Blank'
import {IMonthlyPaymentReportForm, MonthlyPaymentReportField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  padding: theme.spacing(2, 3)
}))

interface ICreateMonthlyReportFormProps {
  formId: string
  onSubmit: (data: IMonthlyPaymentReportForm) => void
  data:
    | AvailableInputDataForMonthlyECommerceReportsQuery['availableInputDataForMonthlyECommerceReports']
    | AvailableInputDataForMonthlyReportsQuery['availableInputDataForMonthlyReports']
}

export const CreateMonthlyReportForm: React.FC<ICreateMonthlyReportFormProps> =
  ({formId, onSubmit, data}: ICreateMonthlyReportFormProps) => {
    const {t} = useTranslation()
    const {errors, control, handleSubmit} =
      useFormContext<IMonthlyPaymentReportForm>()
    const translateMonth = useTranslateMonth()
    return (
      <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
        {data.length ? (
          <InputRow
            nodes={[
              <UncontrolledFormRadioGroup<IMonthlyPaymentReportForm>
                label={t('Select month')}
                name={MonthlyPaymentReportField.YearMonth}
                key={MonthlyPaymentReportField.YearMonth}
                errors={errors}
                validationOptions={{
                  required: true
                }}
                fullWidth
                control={control}
                options={data.map(({year, month}) => ({
                  value: [year, month].join('-'),
                  label: (
                    <BasicRadioLabel
                      primaryText={[year, translateMonth(month)].join(' ')}
                    />
                  )
                }))}
              />
            ]}
          />
        ) : (
          <Blank
            title={t('All payments have been processed.')}
            description={t(
              'No payments were found that should be included in the monthly ecommerce report.'
            )}
          />
        )}
      </StyledForm>
    )
  }
