import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useBooleanState} from '../../hooks/state'

export interface IActionButton {
  id: number
}

export interface IOpenModalButtonProps {
  onClick: () => void
}

interface IStateChangeModalProps {
  OpenModalButton: React.FC<IOpenModalButtonProps>
  onSubmit: (data?: any) => Promise<void>
  header: React.ReactNode
  message: React.ReactNode
  content?: React.ReactNode
  submitButtonText: string
  confirmationButtonCypressId?: string
}

export const StateChangeModal: React.FC<IStateChangeModalProps> = ({
  OpenModalButton,
  onSubmit,
  header,
  message,
  content,
  submitButtonText,
  confirmationButtonCypressId
}: IStateChangeModalProps) => {
  const {t} = useTranslation()

  const {
    state: isOpen,
    setTrue: setOpened,
    setFalse: setClosed
  } = useBooleanState(false)

  const _onSubmit = () => {
    setClosed()
    onSubmit()
  }

  return (
    <>
      <OpenModalButton onClick={setOpened} />
      <Dialog open={isOpen} onClose={setClosed}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>{message}</Grid>
            {content && <Grid item>{content}</Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={setClosed} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            cypress-id={confirmationButtonCypressId}
            onClick={_onSubmit}
            color="primary"
          >
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
