import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateEventCategoryMutation,
  CreateEventCategoryMutationVariables,
  EventCategoriesQuery,
  EventCategoriesQueryVariables,
  EventCategoryQuery,
  EventCategoryQueryVariables,
  UpdateEventCategoryMutation,
  UpdateEventCategoryMutationVariables
} from '../../../../__generated__/schema'

const EVENT_CATEGORY_FIELDS = gql`
  fragment EventCategoryFields on EventCategory {
    id
    name
    label
    isActive
  }
`

const GET_EVENT_CATEGORIES = gql`
  query eventCategories {
    eventCategories {
      ...EventCategoryFields
    }
  }
  ${EVENT_CATEGORY_FIELDS}
`

export const useGetEventCategories = () =>
  useQuery<EventCategoriesQuery, EventCategoriesQueryVariables>(
    GET_EVENT_CATEGORIES,
    {fetchPolicy: 'network-only'}
  )

const CREATE_EVENT_CATEGORY = gql`
  mutation createEventCategory($data: EventCategoryInput!) {
    createEventCategory(data: $data) {
      id
    }
  }
`

export const useCreateEventCategory = () => {
  const [createEventCategory] = useMutation<
    CreateEventCategoryMutation,
    CreateEventCategoryMutationVariables
  >(CREATE_EVENT_CATEGORY)
  return useCallback(
    (variables: CreateEventCategoryMutationVariables) =>
      createEventCategory({
        variables,
        refetchQueries: [{query: GET_EVENT_CATEGORIES}]
      }),
    [createEventCategory]
  )
}

const GET_EVENT_CATEGORY = gql`
  query eventCategory($id: Int!) {
    eventCategory(id: $id) {
      ...EventCategoryFields
    }
  }
  ${EVENT_CATEGORY_FIELDS}
`

export const useGetEventCategory = (id: number) =>
  useQuery<EventCategoryQuery, EventCategoryQueryVariables>(
    GET_EVENT_CATEGORY,
    {variables: {id}, fetchPolicy: 'network-only'}
  )

const UPDATE_EVENT_CATEGORY = gql`
  mutation updateEventCategory($id: Int!, $data: EventCategoryInput!) {
    updateEventCategory(id: $id, data: $data) {
      ...EventCategoryFields
    }
  }
  ${EVENT_CATEGORY_FIELDS}
`

export const useUpdateEventCategory = () => {
  const [updateEventCategory] = useMutation<
    UpdateEventCategoryMutation,
    UpdateEventCategoryMutationVariables
  >(UPDATE_EVENT_CATEGORY)
  return useCallback(
    (variables: UpdateEventCategoryMutationVariables) =>
      updateEventCategory({
        variables
      }),
    [updateEventCategory]
  )
}
