import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {Collapse, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useEffect, useMemo} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ProductFieldsFragment,
  ProductMode,
  ProductTypeState,
  Unit
} from '../../../../__generated__/schema'
import {useGetECommerceSettingsFieldsLabel} from '../../../../hooks/eCommerceSettingsFieldsLabel'
import {useGetClientLocales} from '../../../../hooks/getLocales'
import {useBooleanState} from '../../../../hooks/state'
import {
  useTranslateProductMode,
  useTranslateProductModeDescription
} from '../../../../hooks/translateProductMode'
import {useTranslateUnit} from '../../../../hooks/translateUnit'
import {Theme} from '../../../../theme'
import {
  useIsPositiveInteger,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../form/UncontrolledFormRadioGroup'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {useGetLightweightProductTypes} from '../graphql'
import {IProductForm, ProductFormField, ProductFormLocation} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  sectionTitle: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  }
}))

interface IProductFormProps {
  location: ProductFormLocation
  formId: string
  onSubmit: (data: IProductForm) => void
  className?: string
  product?: ProductFieldsFragment
  isAdditionalSettingsExpanded?: boolean
  isEcommerceSettingsExpanded?: boolean
}

export const ProductForm: React.FC<IProductFormProps> = ({
  location,
  formId,
  onSubmit,
  className,
  product,
  isAdditionalSettingsExpanded,
  isEcommerceSettingsExpanded
}: IProductFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useFormContext<IProductForm>()
  const {data} = useGetLightweightProductTypes()
  const {state: isAdditionalSettingsOpen, toggle: toggleAdditionalSettings} =
    useBooleanState(isAdditionalSettingsExpanded || false)
  const {state: isEcommerceSettingsOpen, toggle: toggleEcommerceSettings} =
    useBooleanState(isEcommerceSettingsExpanded || false)
  const clientLocales = useGetClientLocales()
  const translateUnit = useTranslateUnit()
  const translateProductMode = useTranslateProductMode()
  const translateProductModeDescription = useTranslateProductModeDescription()
  const productTypesAutocompleteOptions = useMemo(
    () =>
      (data?.productTypes || [])
        .filter(
          ({state, id}) =>
            state === ProductTypeState.Active || id === product?.productType.id
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({id, name}) => ({
          value: String(id),
          name
        })),
    [data?.productTypes, product?.productType.id]
  )
  useEffect(() => {
    if (data && product) {
      setValue(ProductFormField.ProductTypeId, String(product.productType.id))
    }
  }, [data, product, setValue])
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isPositiveInteger = useIsPositiveInteger()
  const getEcommerceSettingsFieldsLabel = useGetECommerceSettingsFieldsLabel()
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IProductForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductFormField.Name}
            key={ProductFormField.Name}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IProductForm>
            selectOptions={Object.values(Unit).reduce(
              (acc, unit) => ({
                ...acc,
                [unit]: translateUnit(unit)
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={ProductFormField.Unit}
            key={ProductFormField.Unit}
            label={t('Unit type')}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
            hasNoneSelectOption
            disabled={location === ProductFormLocation.EditProduct}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IProductForm>
            autocompleteOptions={productTypesAutocompleteOptions}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={ProductFormField.ProductTypeId}
            key={ProductFormField.ProductTypeId}
            label={t('Product type')}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
            noOptionsText={t('No product types found')}
            disableClearable={false}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormRadioGroup<IProductForm, ProductMode>
            fullWidth
            label={t('Product mode')}
            validationOptions={{required: true}}
            name={ProductFormField.Mode}
            key={ProductFormField.Mode}
            control={control}
            errors={errors}
            options={[
              ProductMode.Product,
              ProductMode.WarehouseProduct,
              ProductMode.WarehouseRecipe
            ].map((mode) => ({
              value: mode,
              label: (
                <BasicRadioLabel
                  primaryText={translateProductMode(mode)}
                  secondaryText={translateProductModeDescription(mode)}
                />
              ),
              disabled: mode === ProductMode.WarehouseRecipe
            }))}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IProductForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductFormField.InternalDescription}
            key={ProductFormField.InternalDescription}
            label={t('Internal description')}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            fullWidth
            rows={4}
            multiline
          />
        ]}
      />
      <div className={classes.sectionTitle} onClick={toggleAdditionalSettings}>
        <Typography variant="subtitle2">{t('Additional settings')}</Typography>
        <IconButton>
          {isAdditionalSettingsOpen ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </div>
      <Collapse in={isAdditionalSettingsOpen}>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IProductForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ProductFormField.ReceiptName}
              key={ProductFormField.ReceiptName}
              label={t('Receipt name')}
              helperNote={t(
                'If you leave this field empty, name will be printed on receipts.'
              )}
              validationOptions={{
                validate: isStringWithMaxLength
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IProductForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ProductFormField.InternalCode}
              key={ProductFormField.InternalCode}
              label={t('Internal code')}
              helperNote={t(
                'Ideal for suppliers product number, ordering code or internal stock code.'
              )}
              validationOptions={{
                validate: isStringWithMaxLength
              }}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IProductForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ProductFormField.PriceLookupCode}
              key={ProductFormField.PriceLookupCode}
              label={t('PLU')}
              validationOptions={{
                validate: isPositiveInteger
              }}
              fullWidth
              inputMode="numeric"
            />
          ]}
        />
      </Collapse>
      <div className={classes.sectionTitle} onClick={toggleEcommerceSettings}>
        <Typography variant="subtitle2">{t('Ecommerce settings')}</Typography>
        <IconButton>
          {isEcommerceSettingsOpen ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </div>
      <Collapse in={isEcommerceSettingsOpen}>
        {clientLocales.map((locale) => (
          <React.Fragment key={locale}>
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<IProductForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={`${ProductFormField.ECommerceNames}[${locale}]`}
                  key={`${ProductFormField.ECommerceNames}[${locale}]`}
                  label={getEcommerceSettingsFieldsLabel(locale).name}
                  validationOptions={{
                    validate: isStringWithMaxLength
                  }}
                  fullWidth
                />
              ]}
            />
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<IProductForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={`${ProductFormField.ECommerceDescriptions}[${locale}]`}
                  key={`${ProductFormField.ECommerceDescriptions}[${locale}]`}
                  label={getEcommerceSettingsFieldsLabel(locale).description}
                  validationOptions={{
                    validate: isStringWithMaxLength
                  }}
                  fullWidth
                />
              ]}
            />
          </React.Fragment>
        ))}
      </Collapse>
    </form>
  )
}
