import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Drawer, drawerClasses, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import {TFunction} from 'i18next'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  useDivisionParams,
  useProductParams
} from '../../../../../utils/pathname'
import {
  PricingStatus,
  setPricingsStatus
} from '../../../../../utils/setPricingsStatus'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  EntityStateChip
} from '../../../../common'
import {
  DataGridTable,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  usePercentageFormatter,
  useUserNameFormatter
} from '../../../../common/DataGridTable'
import {COLOR_CONF} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {useDeleteProductPricing, useGetProductPricings} from '../graphql'
import {CreatePricingDialog} from './CreatePricingDialog'

const getPricingStateLabelAndColor = (
  status: PricingStatus,
  startsAt: string,
  t: TFunction
) => {
  const difference = dayjs(startsAt).diff(dayjs(), 'd')
  switch (status) {
    case PricingStatus.Current:
      return {color: COLOR_CONF.GREEN, label: t('On sale')}
    case PricingStatus.Future:
      return {
        color: COLOR_CONF.YELLOW,
        label: t('start-in-n-days', {count: difference})
      }
    case PricingStatus.Past:
    default:
      return {color: COLOR_CONF.RED, label: t('Ended')}
  }
}

const IconCellRenderer = ({
  id,
  status,
  refetch
}: {
  id: number
  status: PricingStatus
  refetch: () => void
}) => {
  const {t} = useTranslation()
  const deleteProductPricing = useDeleteProductPricing()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const handleDeleteButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteProductPricing(id)
      refetch()
      addInfoNotification(t('Price was deleted'))
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting product pricing'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteProductPricing,
    id,
    refetch,
    setShowBackdrop,
    t
  ])
  return (
    <>
      {status === PricingStatus.Future && (
        <IconButton onClick={handleDeleteButtonClick} color="primary">
          <DeleteIcon />
        </IconButton>
      )}
    </>
  )
}

const StatusRenderer = ({
  status,
  startsAt
}: {
  status: PricingStatus
  startsAt: string
}) => {
  const {t} = useTranslation()
  const {label, color} = getPricingStateLabelAndColor(status, startsAt, t)
  return <EntityStateChip colorConf={color} label={label} />
}

interface IPricingDrawerProps {
  onExited: () => void
}

export const PricingDrawer: React.FC<IPricingDrawerProps> = ({
  onExited
}: IPricingDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {productId} = useProductParams()
  const {divisionId} = useDivisionParams()
  const {data, loading, error, refetch} = useGetProductPricings(productId)
  const {
    state: isCreatePricingDialogOpen,
    setTrue: openCreatePricingDialog,
    setFalse: closeCreatePricingDialog
  } = useBooleanState(false)
  const [title, setTitle] = useState<string>(t('Pricing'))
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const dateTimeFormatter = useDateTimeFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const percentageFormatter = usePercentageFormatter(0)
  const userNameFormatter = useUserNameFormatter()
  useEffect(() => {
    if (productId && divisionId) {
      openDrawer()
    }
  }, [productId, openDrawer, divisionId])
  const divisionPricings = data?.product.pricings.find(
    (p) => p.division.id === divisionId
  )
  useEffect(() => {
    if (divisionPricings) {
      setTitle([t('Pricing'), divisionPricings.division.name].join(' • '))
    }
  }, [divisionPricings, t])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Valid from'),
        field: 'startsAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Status'),
        field: 'status',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <StatusRenderer
              status={params.row.status}
              startsAt={params.row.startsAt}
            />
          )
        },
        minWidth: 150
      },
      {
        headerName: t('Retail'),
        field: 'retailPrice',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 100
      },
      {
        headerName: t('Retail VAT rate'),
        field: 'retailVatRate',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: percentageFormatter,
        minWidth: 170
      },
      {
        headerName: t('Ecommerce'),
        field: 'eCommercePrice',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 100
      },
      {
        headerName: t('Ecommerce VAT rate'),
        field: 'eCommerceVatRate',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: percentageFormatter,
        minWidth: 170
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              id={params.value}
              status={params.row.status}
              refetch={refetch}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      percentageFormatter,
      refetch,
      t,
      userNameFormatter
    ]
  )
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 960, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={error && t<string>('Error while loading pricing')}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={title}
            />
          }
        >
          {divisionPricings ? (
            <Box
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                alignItems: 'flex-end'
              }}
            >
              {P([PermissionCode.CreateProductPricing]) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openCreatePricingDialog}
                >
                  {t('Create price')}
                </Button>
              )}
              <Box sx={{height: '100%', width: '100%'}}>
                <DataGridTable
                  columns={columns}
                  rows={setPricingsStatus({
                    pricings: divisionPricings.allPricings
                  })}
                  disableColumnMenu
                  autoHeight
                  disableRowSelectionOnClick
                  pagination={false}
                  hideFooter
                  initialState={{
                    pinnedColumns: {left: ['startsAt'], right: ['id']}
                  }}
                  columnVisibilityModel={{
                    id: P([PermissionCode.DeleteProductPricing])
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Blank
              title={t('Price not set yet')}
              description={t(
                'Set price for product, otherwise this product can’t be sold.'
              )}
              actions={
                P([PermissionCode.CreateProductPricing]) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openCreatePricingDialog}
                  >
                    {t('Create price')}
                  </Button>
                )
              }
            />
          )}
        </DrawerTemplate>
      </Drawer>
      <CreatePricingDialog
        isOpen={isCreatePricingDialogOpen}
        onClose={closeCreatePricingDialog}
        productId={productId}
        divisionId={divisionId}
      />
    </>
  )
}
