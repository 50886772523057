import React from 'react'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {WebsiteEditorContent} from '../types'
import {Assets} from './Assets'
import {Blocks} from './Blocks'
import {Pages} from './Pages'
import {useWebsitePageContext} from './WebsitePageContext'

interface IDrawerContentProps {
  onCreatePageButtonClick?: () => void
}

export const DrawerContent: React.FC<IDrawerContentProps> = ({
  onCreatePageButtonClick
}: IDrawerContentProps) => {
  const {P} = useEnsurePermissions()
  const {selectedWebsiteEditorContent} = useWebsitePageContext()
  return (
    <>
      {selectedWebsiteEditorContent === WebsiteEditorContent.Pages && (
        <Pages onCreatePageButtonClick={onCreatePageButtonClick} />
      )}
      {selectedWebsiteEditorContent === WebsiteEditorContent.Blocks && (
        <Blocks />
      )}
      {selectedWebsiteEditorContent === WebsiteEditorContent.Assets &&
        P([PermissionCode.ReadClientFiles]) && <Assets />}
    </>
  )
}
