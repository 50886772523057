import {omit, pick, uniq} from 'lodash'
import {UArrayKeys} from './types'
import {getListOfIdsFromFilter} from './utils'

export enum CreateSectionDialogStep {
  First = 'first',
  Second = 'second'
}

export type GeneralFilterState<T extends object> = {
  filter: T
  visibleSections: (keyof T)[]
  addSectionDialog: {
    isOpen: boolean
    key: keyof T | null
    step: CreateSectionDialogStep
    dialogFilter: T
  }
}

export enum GeneralFilterActionTypes {
  RemoveKey = 'removeKey',
  RemoveIdFromListOfIds = 'removeIdFromListOfIds',
  AddKeyWithListIds = 'addKeyWithListIds',
  OpenCreateSectionDialog = 'openCreateSectionDialog',
  CloseCreateSectionDialog = 'closeCreateSectionDialog',
  SelectSectionDialogKey = 'selectSectionDialogKey',
  SetCreateSectionDialogStep = 'setCreateSectionDialogStep',
  AddIdToDialogFilterList = 'addIdToDialogFilterList',
  RemoveIdFromDialogFilterList = 'removeIdFromDialogFilterList',
  MoveDataFromAddDialogToFilter = 'moveDataFromAddDialogToFilter'
}

type RemoveKeyAction<T extends object> = {
  type: GeneralFilterActionTypes.RemoveKey
  payload: keyof T
}

type RemoveIdFromListOfIdsAction<T extends object> = {
  type: GeneralFilterActionTypes.RemoveIdFromListOfIds
  payload: {
    key: keyof T
    value: any
  }
}
type AddKeyWithListIdsAction<T extends object> = {
  type: GeneralFilterActionTypes.AddKeyWithListIds
  payload: {
    key: keyof T
    values: any[]
  }
}
type OpenCreateSectionDialogAction = {
  type: GeneralFilterActionTypes.OpenCreateSectionDialog
}
type CloseCreateSectionDialogAction = {
  type: GeneralFilterActionTypes.CloseCreateSectionDialog
}
type SelectSectionDialogKeyAction<T extends object> = {
  type: GeneralFilterActionTypes.SelectSectionDialogKey
  payload: keyof T
}
type SetCreateSectionDialogStepAction = {
  type: GeneralFilterActionTypes.SetCreateSectionDialogStep
  payload: CreateSectionDialogStep
}

type AddIdToDialogFilterListAction<T extends object> = {
  type: GeneralFilterActionTypes.AddIdToDialogFilterList
  payload: {
    key: keyof T
    value: any
  }
}
type RemoveIdFromDialogFilterListAction<T extends object> = {
  type: GeneralFilterActionTypes.RemoveIdFromDialogFilterList
  payload: {
    key: keyof T
    value: any
  }
}
type MoveDataFromAddDialogToFilterAction<T extends object> = {
  type: GeneralFilterActionTypes.MoveDataFromAddDialogToFilter
  payload: keyof T
}

export type GeneralFilterActions<T extends object> =
  | RemoveKeyAction<T>
  | RemoveIdFromListOfIdsAction<T>
  | AddKeyWithListIdsAction<T>
  | OpenCreateSectionDialogAction
  | CloseCreateSectionDialogAction
  | SelectSectionDialogKeyAction<T>
  | SetCreateSectionDialogStepAction
  | AddIdToDialogFilterListAction<T>
  | RemoveIdFromDialogFilterListAction<T>
  | MoveDataFromAddDialogToFilterAction<T>

const reduceRemoveIdFromDialogFilterListAction = <T extends object>(
  state: GeneralFilterState<T>,
  action: RemoveIdFromDialogFilterListAction<T>
) => {
  const currentIdsList: number[] =
    getListOfIdsFromFilter(
      state.addSectionDialog.dialogFilter,
      action.payload.key as unknown as UArrayKeys<T>
    ) ?? []
  const nextIdsList = currentIdsList.filter((id) => id !== action.payload.value)
  return {
    ...state,
    addSectionDialog: {
      ...state.addSectionDialog,
      dialogFilter: nextIdsList.length
        ? {
            [action.payload.key]: nextIdsList
          }
        : {}
    }
  }
}

export const getDefaultGeneralFilterState = <T extends object>(
  blockPropsFilter: T,
  keepSections: (keyof T)[]
): GeneralFilterState<T> => {
  const filter = pick(blockPropsFilter, keepSections)
  return {
    filter,
    visibleSections: Object.entries(filter)
      .filter(([, value]) =>
        Array.isArray(value) ? value.length > 0 : Boolean(value)
      )
      .map(([key]) => key as unknown as keyof T),
    addSectionDialog: {
      isOpen: false,
      key: null,
      step: CreateSectionDialogStep.First,
      dialogFilter: {} as T
    }
  }
}

export const generalFilterReducer = <T extends object>(
  state: GeneralFilterState<T>,
  action: GeneralFilterActions<T>
) => {
  switch (action.type) {
    case GeneralFilterActionTypes.RemoveKey:
      return {
        ...state,
        filter: omit(state.filter, [action.payload]),
        visibleSections: state.visibleSections.filter(
          (s) => s !== action.payload
        )
      }
    case GeneralFilterActionTypes.RemoveIdFromListOfIds: {
      const filteredKeys = getListOfIdsFromFilter<T>(
        state.filter,
        action.payload.key as unknown as UArrayKeys<T>
      )?.filter((id) => id !== action.payload.value)
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]:
            filteredKeys?.length === 0 ? undefined : filteredKeys
        },
        visibleSections:
          filteredKeys?.length === 0
            ? state.visibleSections.filter((s) => s !== action.payload.key)
            : state.visibleSections
      }
    }
    case GeneralFilterActionTypes.AddKeyWithListIds:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: uniq([
            ...action.payload.values,
            ...(getListOfIdsFromFilter<T>(
              state.filter,
              action.payload.key as unknown as UArrayKeys<T>
            ) ?? [])
          ])
        },
        visibleSections: state.visibleSections.includes(action.payload.key)
          ? state.visibleSections
          : [action.payload.key, ...state.visibleSections]
      }
    case GeneralFilterActionTypes.OpenCreateSectionDialog:
      return {
        ...state,
        addSectionDialog: {
          ...state.addSectionDialog,
          isOpen: true
        }
      }
    case GeneralFilterActionTypes.CloseCreateSectionDialog:
      return {
        ...state,
        addSectionDialog: {
          ...state.addSectionDialog,
          step: CreateSectionDialogStep.First,
          dialogFilter: {},
          isOpen: false,
          key: null
        }
      }
    case GeneralFilterActionTypes.SelectSectionDialogKey:
      return {
        ...state,
        addSectionDialog: {
          ...state.addSectionDialog,
          isOpen: true,
          key: state.visibleSections.includes(action.payload)
            ? state.addSectionDialog.key
            : action.payload
        }
      }
    case GeneralFilterActionTypes.SetCreateSectionDialogStep:
      return {
        ...state,
        addSectionDialog: {
          ...state.addSectionDialog,
          step: action.payload
        }
      }
    case GeneralFilterActionTypes.AddIdToDialogFilterList:
      return {
        ...state,
        addSectionDialog: {
          ...state.addSectionDialog,
          dialogFilter: {
            [action.payload.key]: uniq([
              ...(getListOfIdsFromFilter(
                state.addSectionDialog.dialogFilter,
                action.payload.key as unknown as UArrayKeys<T>
              ) ?? []),
              action.payload.value
            ])
          }
        }
      }
    case GeneralFilterActionTypes.RemoveIdFromDialogFilterList:
      return reduceRemoveIdFromDialogFilterListAction<T>(state, action)
    case GeneralFilterActionTypes.MoveDataFromAddDialogToFilter:
      return {
        ...state,
        addSectionDialog: {
          key: null,
          isOpen: false,
          step: CreateSectionDialogStep.First,
          dialogFilter: {}
        },
        filter: {
          ...(state.addSectionDialog.dialogFilter[action.payload]
            ? {
                ...state.filter,
                [action.payload]:
                  state.addSectionDialog.dialogFilter[action.payload]
              }
            : state.filter)
        },
        visibleSections: state.addSectionDialog.dialogFilter[action.payload]
          ? [action.payload, ...state.visibleSections]
          : state.visibleSections
      }
    default:
      return state
  }
}
