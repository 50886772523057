import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'

import {
  GetShowAgeClassificationsQuery,
  GetShowAgeClassificationsQueryVariables,
  ShowAgeClassificationCode
} from '../__generated__/schema'

const GET_SHOW_AGE_CLASSIFICATIONS = gql`
  query getShowAgeClassifications {
    showAgeClassificationCodes
  }
`

const useGetShowAgeClassifications = () =>
  useQuery<
    GetShowAgeClassificationsQuery,
    GetShowAgeClassificationsQueryVariables
  >(GET_SHOW_AGE_CLASSIFICATIONS)

export const useGetAgeClassifications = (): ShowAgeClassificationCode[] => {
  const {data, error} = useGetShowAgeClassifications()

  if (error) {
    throw new Error('Age classifications download failed!')
  }
  return data?.showAgeClassificationCodes || []
}
