import {useTranslation} from 'react-i18next'
import {TourState} from '../__generated__/schema'

export const useTranslateTourState = () => {
  const {t} = useTranslation()
  const translated: Record<TourState, string> = {
    [TourState.Active]: t<string>('Active'),
    [TourState.Draft]: t<string>('Draft'),
    [TourState.Inactive]: t<string>('Inactive')
  }
  return (state: TourState) => translated[state]
}

export const useTranslateTourStateDescription = () => {
  const {t} = useTranslation()
  const translated: Record<TourState, string> = {
    [TourState.Active]: t<string>(
      'The tour is displayed in the cash desk and is available for customers on the ecommerce.'
    ),
    [TourState.Draft]: t<string>(
      'The tour is not appearing in the cash desk, and it is also unavailable for customers on the ecommerce.'
    ),
    [TourState.Inactive]: t<string>(
      'The tour is not appearing in the cash desk, and it is also unavailable for customers on the ecommerce.'
    )
  }
  return (state: TourState) => translated[state]
}
