import {
  Drawer,
  drawerClasses,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useProductParams} from '../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {FormSwitchInput} from '../../../../form/FormSwitchInput'
import {FormFieldName} from '../../../../form/types'
import {useGetLightweightDivisions} from '../../graphql'
import {
  useProductGroupAvailability,
  useUpsertProductGroupAvailability
} from '../graphql'
import {
  IMergedProductGrupAvailability,
  IProductGroupAvailabilityForm,
  ProductGroupAvailabilityFormField
} from '../types'
import {transformProductGroupAvailabilityFormDataToProductGroupAvailabilityInputs} from '../utils'

const SAVE_PRODUCT_GRUP_AVAILABILITY_FORM_ID =
  'saveProductGroupAvailabilityForm'

const StyledForm = styled('form')(({theme}) => ({
  padding: theme.spacing(1, 3)
}))

interface IAvailabilityDrawerProps {
  onExited: () => void
}

export const AvailabilityDrawer: React.FC<IAvailabilityDrawerProps> = ({
  onExited
}: IAvailabilityDrawerProps) => {
  const {t} = useTranslation()
  const {productGroupId} = useProductParams()
  const {watch, control, register, handleSubmit} =
    useForm<IProductGroupAvailabilityForm>()
  const {data, loading, error} = useProductGroupAvailability(productGroupId)
  const upsertProductGroupAvailability = useUpsertProductGroupAvailability()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {
    divisions,
    loading: loadingDivisions,
    error: errorDivisions
  } = useGetLightweightDivisions()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const mergedProductGroupAvailability = divisions.map(
    (division): IMergedProductGrupAvailability => {
      const productGroupAvailability = data?.productGroup.availability.find(
        (pga) => pga.division.id === division.id
      )
      return {
        divisionId: division.id,
        divisionName: division.name,
        isAvailableOnRetailChannel:
          productGroupAvailability?.isAvailableOnRetailChannel || false,
        isAvailableOnECommerceChannel:
          productGroupAvailability?.isAvailableOnECommerceChannel || false
      }
    }
  )
  const onSubmit = useCallback(
    async (formData: IProductGroupAvailabilityForm) => {
      try {
        setShowBackdrop(true)
        await upsertProductGroupAvailability({
          id: productGroupId,
          productGroupAvailabilityInputs:
            transformProductGroupAvailabilityFormDataToProductGroupAvailabilityInputs(
              formData,
              mergedProductGroupAvailability
            )
        })
        addInfoNotification(t('Product group availability has been updated'))
        onExited()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while upserting product group availability')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      mergedProductGroupAvailability,
      onExited,
      productGroupId,
      setShowBackdrop,
      t,
      upsertProductGroupAvailability
    ]
  )
  useEffect(() => {
    if (!isNaN(productGroupId)) {
      openDrawer()
    }
  }, [openDrawer, productGroupId])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        isLoading={loading || loadingDivisions}
        errorMessage={
          (error && t<string>('Error while loading product group')) ||
          (errorDivisions && t<string>('Could not load divisions'))
        }
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Product group availability')}
          />
        }
        footer={
          <SaveButton
            type="submit"
            form={SAVE_PRODUCT_GRUP_AVAILABILITY_FORM_ID}
          >
            {t('Save')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <StyledForm
          id={SAVE_PRODUCT_GRUP_AVAILABILITY_FORM_ID}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TableContainer>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  px: 0,
                  py: 2,
                  borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
                },
                [`& .${tableCellClasses.body}`]: {
                  width: '100%'
                }
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('Division')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('Retail')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {t('Ecommerce')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mergedProductGroupAvailability.map(
                  ({
                    divisionId,
                    divisionName,
                    isAvailableOnRetailChannel,
                    isAvailableOnECommerceChannel
                  }) => (
                    <React.Fragment key={divisionId}>
                      <TableRow>
                        <TableCell>
                          <input
                            type="hidden"
                            name={`${ProductGroupAvailabilityFormField.DivisionId}.${divisionId}`}
                            key={`${ProductGroupAvailabilityFormField.DivisionId}.${divisionId}`}
                            ref={register()}
                            defaultValue={divisionId}
                          />
                          <Typography variant="body2">
                            {divisionName}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <FormSwitchInput<IProductGroupAvailabilityForm>
                            watch={watch}
                            control={control}
                            name={
                              `${ProductGroupAvailabilityFormField.IsAvailableOnRetailChannel}.${divisionId}` as FormFieldName<IProductGroupAvailabilityForm>
                            }
                            key={
                              `${ProductGroupAvailabilityFormField.IsAvailableOnRetailChannel}.${divisionId}` as FormFieldName<IProductGroupAvailabilityForm>
                            }
                            defaultValue={isAvailableOnRetailChannel}
                            enabledText=""
                            disabledText=""
                          />
                        </TableCell>
                        <TableCell>
                          <FormSwitchInput<IProductGroupAvailabilityForm>
                            watch={watch}
                            control={control}
                            name={
                              `${ProductGroupAvailabilityFormField.IsAvailableOnECommerceChannel}.${divisionId}` as FormFieldName<IProductGroupAvailabilityForm>
                            }
                            key={
                              `${ProductGroupAvailabilityFormField.IsAvailableOnECommerceChannel}.${divisionId}` as FormFieldName<IProductGroupAvailabilityForm>
                            }
                            defaultValue={isAvailableOnECommerceChannel}
                            enabledText=""
                            disabledText=""
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledForm>
      </DrawerTemplate>
    </Drawer>
  )
}
