import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  DetailLibraryShowPropertiesFragment,
  GetLibraryShowImagesQuery
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {SHOWS_PARAMS as SP} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {useGetShowTitle} from '../../../../../utils/translations'
import {RenderOnData} from '../../../../common'
import {ShowSection} from '../../../../constants'
import {CenteredLayout} from '../../Layout'
import {useGetLibraryShowImages, useUploadLibraryImage} from '../graphql'
import {LibraryShowUploadContent} from '../UploadContent'
import {UploadMedia} from '../UploadMedia'
import {GalleryImage2} from '../utils'
import {EditLibraryPageDetailTemplate} from './EditLibraryPageDetailTemplate'

interface IEditLibraryShowMediaProps {
  libraryShow: DetailLibraryShowPropertiesFragment
}

export const EditLibraryShowMedia: React.FC<IEditLibraryShowMediaProps> = ({
  libraryShow
}: IEditLibraryShowMediaProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {data, loading, error, refetch} = useGetLibraryShowImages({
    id: libraryShow.id
  })
  const {videos} = libraryShow
  const uploadLibraryImage = useUploadLibraryImage()
  const {setShowBackdrop, addInfoNotification} = useMutationAssistanceHooks()
  const handleUpload = useCallback(
    async (images: GalleryImage2[]) => {
      setShowBackdrop(true)
      const promises = await Promise.allSettled(
        images.map((image) =>
          uploadLibraryImage({
            showId: libraryShow.id,
            data: {type: image.imageType},
            file: image.image
          })
        )
      )
      addInfoNotification(
        t('{{uploadedCount}} of {{count}} images uploaded', {
          uploadedCount: promises.filter(
            (promise) => promise.status === 'fulfilled'
          ).length,
          count: images.length
        })
      )
      await refetch()
      setShowBackdrop(false)
      history.push(
        routeTo.admin.library.editWithEditSection(
          libraryShow.id,
          ShowSection.Media
        )
      )
    },
    [
      addInfoNotification,
      history,
      libraryShow.id,
      refetch,
      setShowBackdrop,
      t,
      uploadLibraryImage
    ]
  )
  const handleClose = useCallback(
    () => history.replace(routeTo.admin.library.home()),
    [history]
  )
  const getShowTitle = useGetShowTitle()
  const showTitle = getShowTitle({
    translations: libraryShow.translations,
    originalTitle: libraryShow.originalTitle,
    fallbackTitle: t('Library show')
  })
  const handleExited = useCallback(
    () =>
      history.replace(
        routeTo.admin.library.editWithEditSection(
          libraryShow.id,
          ShowSection.Media
        )
      ),
    [history, libraryShow.id]
  )
  return (
    <RenderOnData<GetLibraryShowImagesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading show images')}
      dataCondition={(data) => Boolean(data.libraryShow)}
    >
      {({libraryShow}) => (
        <>
          <EditLibraryPageDetailTemplate
            showName={showTitle}
            onClose={handleClose}
            currentTab={ShowSection.Media}
          >
            <CenteredLayout>
              <LibraryShowUploadContent
                libraryShowImages={libraryShow.images}
                showId={libraryShow.id}
                refetchLibraryShowImages={refetch}
                videos={videos}
              />
            </CenteredLayout>
          </EditLibraryPageDetailTemplate>
          <Route
            path={routeTo.admin.library.uploadMedia(
              toPlaceholderParam(SP.SHOW_ID),
              toPlaceholderParam(SP.SECTION),
              toPlaceholderParam(SP.ACTIVE_CONTENT_TYPE)
            )}
            exact
          >
            <UploadMedia
              onExited={handleExited}
              onUpload={handleUpload}
              title={showTitle}
            />
          </Route>
        </>
      )}
    </RenderOnData>
  )
}
