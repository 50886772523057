import {useQuery} from '@apollo/react-hooks'
import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  LightweightUsersQuery,
  PermissionCode,
  WarehouseDocumentsFilterInput,
  WarehouseDocumentSource
} from '../../../../__generated__/schema'
import {useFormatUserName} from '../../../../hooks/formatUserName'
import {useTranslateWarehouseDocumentSource} from '../../../../hooks/translateWarehouseDocumentSource'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {LIGHTWEIGHT_BUSINESS_PARTNERS, useLightweightUsers} from '../graphql'

export const DEFAULT_WAREHOUSE_DOCUMENTS_FILTER_INPUT: WarehouseDocumentsFilterInput =
  {
    hasText: undefined
  }

type ExtendedWarehouseDocumentsFilterInput = WarehouseDocumentsFilterInput & {
  _updatedAtDaterangeId?: DATERANGE_IDS
  _createdAtDaterangeId?: DATERANGE_IDS
  _issuedAtDaterangeId?: DATERANGE_IDS
}

const stripCreatedAtDateFromFilter = (
  filter: ExtendedWarehouseDocumentsFilterInput
): ExtendedWarehouseDocumentsFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripUpdatedAtDateFromFilter = (
  filter: ExtendedWarehouseDocumentsFilterInput
): ExtendedWarehouseDocumentsFilterInput =>
  omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAtDaterangeId'])

const stripIssuedAtDateFromFilter = (
  filter: ExtendedWarehouseDocumentsFilterInput
): ExtendedWarehouseDocumentsFilterInput =>
  omit(filter, ['issuedAtFrom', 'issuedAtTo', '_issuedAtDaterangeId'])

const stripHelperKeysFromFilter = (
  filter: ExtendedWarehouseDocumentsFilterInput
): ExtendedWarehouseDocumentsFilterInput =>
  omit(filter, [
    '_createdAtDaterangeId',
    '_updatedAtDaterangeId',
    '_issuedAtDaterangeId'
  ])

const dateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.THIS_MONTH,
  DATERANGE_IDS.LAST_MONTH
]

const mapHasTextToFilter = (
  filter: WarehouseDocumentsFilterInput,
  hasText?: string
): WarehouseDocumentsFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapSourceToFilter = (
  filter: WarehouseDocumentsFilterInput,
  source?: WarehouseDocumentSource
): WarehouseDocumentsFilterInput => ({
  ...filter,
  sources: source ? [source] : undefined
})

const mapBusinessPartnerIdToFilter = (
  filter: WarehouseDocumentsFilterInput,
  businessPartnerId?: number
): WarehouseDocumentsFilterInput => ({
  ...filter,
  businessPartnerId
})

const mapThirdPartyDocumentIdToFilter = (
  filter: WarehouseDocumentsFilterInput,
  thirdPartyDocumentId?: string
): WarehouseDocumentsFilterInput => ({
  ...filter,
  thirdPartyDocumentId: thirdPartyDocumentId || undefined
})

const mapNoteToFilter = (
  filter: WarehouseDocumentsFilterInput,
  note?: string
): WarehouseDocumentsFilterInput => ({
  ...filter,
  note: note || undefined
})

const mapUpdatedByUserIdToFilter = (
  filter: WarehouseDocumentsFilterInput,
  updatedById?: number
): WarehouseDocumentsFilterInput => ({
  ...filter,
  updatedByIds: updatedById ? [updatedById] : undefined
})

const mapCreatedByUserIdToFilter = (
  filter: WarehouseDocumentsFilterInput,
  createdById?: number
): WarehouseDocumentsFilterInput => ({
  ...filter,
  createdByIds: createdById ? [createdById] : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateWarehouseDocumentSource = useTranslateWarehouseDocumentSource()
  const formatUserName = useFormatUserName(true)
  const getHasTextFromSearchObject = (filter: WarehouseDocumentsFilterInput) =>
    filter.hasText || undefined
  const getSourceFromSearchObject = (filter: WarehouseDocumentsFilterInput) => {
    if (filter.sources && filter.sources.length) {
      return t('Source: {{source}}', {
        source: translateWarehouseDocumentSource(filter.sources[0])
      })
    }
    return undefined
  }
  const getBusinessPartnerFromSearchObject = (
    filter: WarehouseDocumentsFilterInput,
    businessPartners: {id: number; companyName: string}[]
  ) => {
    if (filter.businessPartnerId) {
      const businessPartner = businessPartners.find(
        ({id}) => id === filter.businessPartnerId
      )
      return businessPartner
        ? t('Business partner: {{companyName}}', {
            companyName: businessPartner.companyName
          })
        : t('Business partner ID: {{id}}', {id: filter.businessPartnerId})
    }
    return undefined
  }
  const getThirdPartyDocumentIdFromSearchObject = (
    filter: WarehouseDocumentsFilterInput
  ) =>
    filter.thirdPartyDocumentId
      ? t('Related document ID: {{id}}', {id: filter.thirdPartyDocumentId})
      : undefined
  const getNoteFromSearchObject = (filter: WarehouseDocumentsFilterInput) =>
    filter.note ? t('Note: {{note}}', {note: filter.note}) : undefined
  const getUpdatedByFromSearchObject = (
    filter: WarehouseDocumentsFilterInput,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.updatedByIds && filter.updatedByIds.length) {
      const updatedById = filter.updatedByIds[0]
      const user = users.find((user) => user.id === updatedById)
      return user
        ? t('Updated by: {{userName}}', {
            userName: formatUserName(user)
          })
        : t('Updated by ID: {{userId}}', {userId: updatedById})
    }
    return undefined
  }
  const getCreatedByFromSearchObject = (
    filter: WarehouseDocumentsFilterInput,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.createdByIds && filter.createdByIds.length) {
      const createdById = filter.createdByIds[0]
      const user = users.find((user) => user.id === createdById)
      return user
        ? t('Created by: {{userName}}', {
            userName: formatUserName(user)
          })
        : t('Created by ID: {{userId}}', {userId: createdById})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getSourceFromSearchObject,
    getBusinessPartnerFromSearchObject,
    getThirdPartyDocumentIdFromSearchObject,
    getNoteFromSearchObject,
    getUpdatedByFromSearchObject,
    getCreatedByFromSearchObject
  }
}

interface IWarehouseDocumentsSearchProps {
  onFilterChange: (filter: WarehouseDocumentsFilterInput) => void
}

export const WarehouseDocumentsSearch: React.FC<IWarehouseDocumentsSearchProps> =
  ({onFilterChange}: IWarehouseDocumentsSearchProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {data: businessPartnersData} = useQuery<
      LightweightBusinessPartnersQuery,
      LightweightBusinessPartnersQueryVariables
    >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
      variables: {paginationInput: {offset: 0, limit: 100}},
      fetchPolicy: 'network-only',
      skip: !P([PermissionCode.ReadBusinessPartners])
    })
    const {users} = useLightweightUsers()
    const {
      daterangeOptions: updatedAtDateDaterangeOptions,
      mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDateRangeToFilter,
      mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
      getStringifiedDaterangeFromSearchObject:
        getStringifiedUpdatedAtDateDateRangeFromSearchObject,
      getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
    } = useDateRangeSearch<ExtendedWarehouseDocumentsFilterInput>({
      usedDateranges: dateRanges,
      dateRangeInputPrefix: t('Updated at'),
      getDateRangeFromSearchObject: (o) => ({
        startDateISOString: o.updatedAtFrom || undefined,
        endDateISOString: o.updatedAtTo || undefined,
        id: o._updatedAtDaterangeId
      }),
      mapDaterangeValuesToSearchObject: (o, input) => ({
        ...o,
        updatedAtFrom: input.startDate,
        updatedAtTo: input.endDate,
        _updatedAtDaterangeId: input.id
      })
    })
    const {
      daterangeOptions: createdAtDateDaterangeOptions,
      mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
      mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
      getStringifiedDaterangeFromSearchObject:
        getStringifiedCreatedAtDateDateRangeFromSearchObject,
      getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
    } = useDateRangeSearch<ExtendedWarehouseDocumentsFilterInput>({
      usedDateranges: dateRanges,
      dateRangeInputPrefix: t('Created at'),
      getDateRangeFromSearchObject: (o) => ({
        startDateISOString: o.createdAtFrom || undefined,
        endDateISOString: o.createdAtTo || undefined,
        id: o._createdAtDaterangeId
      }),
      mapDaterangeValuesToSearchObject: (o, input) => ({
        ...o,
        createdAtFrom: input.startDate,
        createdAtTo: input.endDate,
        _createdAtDaterangeId: input.id
      })
    })
    const {
      daterangeOptions: issuedAtDateDaterangeOptions,
      mapCustomDaterangeToSearchObject: mapCustomIssuedAtDateRangeToFilter,
      mapDaterangeToSearchObject: mapIssuedAtDaterangeToFilter,
      getStringifiedDaterangeFromSearchObject:
        getStringifiedIssuedAtDateDateRangeFromSearchObject,
      getIsDaterangeOptionActive: getIsIssuedAtDaterangeOptionActive
    } = useDateRangeSearch<ExtendedWarehouseDocumentsFilterInput>({
      usedDateranges: dateRanges,
      dateRangeInputPrefix: t('Issued at'),
      getDateRangeFromSearchObject: (o) => ({
        startDateISOString: o.issuedAtFrom || undefined,
        endDateISOString: o.issuedAtTo || undefined,
        id: o._issuedAtDaterangeId
      }),
      mapDaterangeValuesToSearchObject: (o, input) => ({
        ...o,
        issuedAtFrom: input.startDate,
        issuedAtTo: input.endDate,
        _issuedAtDaterangeId: input.id
      })
    })
    const {
      getHasTextFromSearchObject,
      getSourceFromSearchObject,
      getBusinessPartnerFromSearchObject,
      getThirdPartyDocumentIdFromSearchObject,
      getNoteFromSearchObject,
      getUpdatedByFromSearchObject,
      getCreatedByFromSearchObject
    } = useGetFieldFromSearchObject()
    const mapSearchObjectToInputText =
      useCombineStringifySearchObjectFunctions<WarehouseDocumentsFilterInput>(
        getHasTextFromSearchObject,
        getSourceFromSearchObject,
        (filter) =>
          getBusinessPartnerFromSearchObject(
            filter,
            businessPartnersData?.businessPartners.items || []
          ),
        getThirdPartyDocumentIdFromSearchObject,
        getNoteFromSearchObject,
        getStringifiedUpdatedAtDateDateRangeFromSearchObject,
        getStringifiedCreatedAtDateDateRangeFromSearchObject,
        (filter) => getUpdatedByFromSearchObject(filter, users),
        (filter) => getCreatedByFromSearchObject(filter, users),
        getStringifiedIssuedAtDateDateRangeFromSearchObject
      )
    const translateWarehouseDocumentSource =
      useTranslateWarehouseDocumentSource()
    const formatUserName = useFormatUserName(true)
    const businessPartnerSelectOptions: ISelectOption<number>[] = (
      businessPartnersData?.businessPartners.items || []
    ).map(({id, companyName}) => ({id, label: companyName}))
    const userSelectOptions: ISelectOption<number>[] = users.map((user) => ({
      id: user.id,
      label: formatUserName(user)
    }))
    return (
      <Search
        storageKey="WAREHOUSE_DOCUMENTS"
        placeholder={t('Search for warehouse document')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapHasTextToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={DEFAULT_WAREHOUSE_DOCUMENTS_FILTER_INPUT}
        stripExtendedSearchObject={stripHelperKeysFromFilter}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            <AdvancedSearchSelectRow<
              WarehouseDocumentsFilterInput,
              WarehouseDocumentSource
            >
              label={t('Source')}
              value={
                advancedSearchObject.sources
                  ? advancedSearchObject.sources[0]
                  : undefined
              }
              options={[
                WarehouseDocumentSource.Shop,
                WarehouseDocumentSource.Stock,
                WarehouseDocumentSource.InventoryCheck,
                WarehouseDocumentSource.Transfer,
                WarehouseDocumentSource.StockWriteOff
              ].map((source) => ({
                id: source,
                label: translateWarehouseDocumentSource(source)
              }))}
              mapSelectValueToSearchObject={mapSourceToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchDaterangeRow<WarehouseDocumentsFilterInput>
              label={t('Issued at')}
              daterangeOptions={issuedAtDateDaterangeOptions}
              mapCustomDaterangeToSearchObject={
                mapCustomIssuedAtDateRangeToFilter
              }
              mapDaterangeToSearchObject={mapIssuedAtDaterangeToFilter}
              getIsDaterangeOptionActive={getIsIssuedAtDaterangeOptionActive}
              stripDaterangeFromSearchObject={stripIssuedAtDateFromFilter}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range for issued at'
              )}
            />
            <AdvancedSearchTextRow<WarehouseDocumentsFilterInput>
              label={t('Contains')}
              placeholder={t(
                'Enter IDs, note or business partner related to document'
              )}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapHasTextToFilter}
              value={advancedSearchObject.hasText || undefined}
            />
            {P([PermissionCode.ReadBusinessPartners]) && (
              <AdvancedSearchSelectRow<WarehouseDocumentsFilterInput, number>
                label={t('Business partner')}
                value={advancedSearchObject.businessPartnerId || undefined}
                options={businessPartnerSelectOptions}
                mapSelectValueToSearchObject={mapBusinessPartnerIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            <AdvancedSearchTextRow<WarehouseDocumentsFilterInput>
              label={t('Related document ID')}
              placeholder={t('Enter warehouse document related ID')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapThirdPartyDocumentIdToFilter}
              value={advancedSearchObject.thirdPartyDocumentId || undefined}
            />
            <AdvancedSearchTextRow<WarehouseDocumentsFilterInput>
              label={t('Note')}
              placeholder={t('Enter warehouse document note')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapNoteToFilter}
              value={advancedSearchObject.note || undefined}
            />
            <AdvancedSearchDaterangeRow<WarehouseDocumentsFilterInput>
              label={t('Updated at')}
              daterangeOptions={updatedAtDateDaterangeOptions}
              mapCustomDaterangeToSearchObject={
                mapCustomUpdatedAtDateRangeToFilter
              }
              mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
              getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
              stripDaterangeFromSearchObject={stripUpdatedAtDateFromFilter}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range for warehouse document updated at'
              )}
            />
            {P([PermissionCode.ReadUsers]) && (
              <AdvancedSearchSelectRow<WarehouseDocumentsFilterInput, number>
                label={t('Updated by')}
                value={
                  advancedSearchObject.updatedByIds
                    ? advancedSearchObject.updatedByIds[0]
                    : undefined
                }
                options={userSelectOptions}
                mapSelectValueToSearchObject={mapUpdatedByUserIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            <AdvancedSearchDaterangeRow<WarehouseDocumentsFilterInput>
              label={t('Created at')}
              daterangeOptions={createdAtDateDaterangeOptions}
              mapCustomDaterangeToSearchObject={
                mapCustomCreatedAtDateRangeToFilter
              }
              mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
              getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
              stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range for cart created at'
              )}
            />
            {P([PermissionCode.ReadUsers]) && (
              <AdvancedSearchSelectRow<WarehouseDocumentsFilterInput, number>
                label={t('Created by')}
                value={
                  advancedSearchObject.createdByIds
                    ? advancedSearchObject.createdByIds[0]
                    : undefined
                }
                options={userSelectOptions}
                mapSelectValueToSearchObject={mapCreatedByUserIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
          </AdvancedSearchBase>
        )}
      />
    )
  }
