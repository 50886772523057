import gql from 'graphql-tag'

const TOUR_FIELDS = gql`
  fragment TourFields on Tour {
    id
    state
    internalNote
    name
    division {
      id
      name
    }
    duration
    retailAttendeesLimit
    eCommerceAttendeesLimit
    eCommerceOrderAttendeesLimit
    venueId
    isECommerceSaleActive
    isECommerceReservationActive
    isRetailSaleActive
    isRetailReservationActive
    gateOpensType
    gateOpensValue
    gateClosesType
    gateClosesValue
    salesEndCashDeskValue
    salesEndCashDeskType
    salesEndWebValue
    salesEndWebType
    reservationEndCashDeskValue
    reservationEndCashDeskType
    reservationEndWebValue
    reservationEndWebType
    eCommerceCreateReservationEndValue
    eCommerceCreateReservationEndType
    retailCreateReservationEndValue
    retailCreateReservationEndType
  }
`

export const GET_TOUR_GENERAL_PAGE = gql`
  ${TOUR_FIELDS}
  query TourGeneralPage($id: PositiveInt!) {
    tour(id: $id) {
      ...TourFields
    }
    venues {
      id
      name
      address {
        town
      }
    }
  }
`
export const DELETE_TOUR_MUTATION = gql`
  mutation DeleteTour($id: PositiveInt!) {
    deleteTour(id: $id)
  }
`

export const UPDATE_TOUR_MUTATION = gql`
  ${TOUR_FIELDS}
  mutation UpdateTour($id: PositiveInt!, $input: UpdateTourInput!) {
    updateTour(id: $id, input: $input) {
      ...TourFields
    }
  }
`
