import RefreshIcon from '@mui/icons-material/Refresh'
import {Chip, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Currency,
  IntentState,
  PaymentIntent,
  PaymentMethod,
  PaymentMethodType,
  PaymentServiceProvider
} from '../../__generated__/schema'
import {useTranslatePrice} from '../../hooks/translateCurrencies'
import {useTranslateIntentState} from '../../hooks/translateIntentState'
import {
  useTranslatePaymentMethodType,
  useTranslatePaymentServiceProvider
} from '../../hooks/translatePaymentMethodType'
import {useDateTimeFormatters} from '../../utils/formatting'
import {COLOR_CONF} from '../constants'
import {EntityStateChip} from './EntityState'

interface IPaymentIntentChipProps {
  state: IntentState
}

const getPaymentIntentChipColorConf = (state: IntentState) => {
  switch (state) {
    case IntentState.Succeeded:
      return COLOR_CONF.GREEN
    case IntentState.Pending:
      return COLOR_CONF.YELLOW
    case IntentState.Cancelled:
    case IntentState.Expired:
    case IntentState.Failed:
    default:
      return COLOR_CONF.GRAY
  }
}

export const PaymentIntentChip: React.FC<IPaymentIntentChipProps> = ({
  state
}: IPaymentIntentChipProps) => {
  const translateIntentState = useTranslateIntentState()
  return (
    <EntityStateChip
      isDotHidden
      colorConf={getPaymentIntentChipColorConf(state)}
      label={translateIntentState(state)}
    />
  )
}

type IntentPick = Pick<
  PaymentIntent,
  'createdAt' | 'state' | 'amount' | 'thirdPartyPaymentId'
> & {
  paymentMethod: Pick<PaymentMethod, 'name' | 'type' | 'serviceProvider'>
}

interface IPaymentIntentRowProps<T extends IntentPick> {
  intent: T
  hasAmountMinus?: boolean
  currency: Currency
  onPaymentStatusClick?: ({
    serviceProvider,
    thirdPartyPaymentId
  }: {
    serviceProvider: PaymentServiceProvider
    thirdPartyPaymentId: string
  }) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "title chip amount"
      "info info amount"
      "thirdPartyPaymentInfo thirdPartyPaymentInfo thirdPartyPaymentInfo"
    `,
    gap: theme.spacing(0, 1),
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  amount: {
    gridArea: 'amount'
  },
  info: {
    gridArea: 'info'
  },
  thirdPartyPaymentInfo: {
    gridArea: 'thirdPartyPaymentInfo'
  }
}))

export const PaymentIntentRow = <T extends IntentPick>({
  intent,
  hasAmountMinus,
  currency,
  onPaymentStatusClick
}: IPaymentIntentRowProps<T>) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translatePrice = useTranslatePrice(currency)
  const translatePaymentServiceProvider = useTranslatePaymentServiceProvider()
  const {formatDateNumeric, formatTimeWithSeconds} = useDateTimeFormatters()
  const createdAt = new Date(intent.createdAt)
  const translatePaymentMethodType = useTranslatePaymentMethodType()
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">{intent.paymentMethod.name}</Typography>
      <PaymentIntentChip state={intent.state} />
      <Typography
        component="div"
        variant="body2"
        className={classes.amount}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'flex-end'
        }}
      >
        {translatePrice(hasAmountMinus ? -intent.amount : intent.amount)}
        {onPaymentStatusClick &&
          intent.state === IntentState.Pending &&
          intent.paymentMethod.type === PaymentMethodType.PaymentGateway &&
          intent.paymentMethod.serviceProvider &&
          intent.paymentMethod.serviceProvider !==
            PaymentServiceProvider.StatnaPokladnica &&
          intent.thirdPartyPaymentId && (
            <Chip
              icon={<RefreshIcon />}
              variant="outlined"
              size="small"
              label={t('Payment status')}
              onClick={() =>
                onPaymentStatusClick({
                  serviceProvider: intent.paymentMethod.serviceProvider!,
                  thirdPartyPaymentId: intent.thirdPartyPaymentId!
                })
              }
            />
          )}
      </Typography>
      <div className={classes.info}>
        <Typography variant="caption" color="textSecondary">
          {[
            formatDateNumeric(createdAt),
            formatTimeWithSeconds(createdAt)
          ].join(' • ')}
        </Typography>
        <Typography variant="overline" color="textSecondary">
          {` • ${translatePaymentMethodType(intent.paymentMethod.type)}`}
        </Typography>
      </div>
      <div className={classes.thirdPartyPaymentInfo}>
        {intent.thirdPartyPaymentId && (
          <Typography variant="caption" color="textSecondary">
            {intent.paymentMethod.serviceProvider
              ? t('{{serviceProvider}} payment ID {{thirdPartyPaymentId}}', {
                  serviceProvider: translatePaymentServiceProvider(
                    intent.paymentMethod.serviceProvider
                  ),
                  thirdPartyPaymentId: intent.thirdPartyPaymentId
                })
              : t('Payment ID {{thirdPartyPaymentId}}', {
                  thirdPartyPaymentId: intent.thirdPartyPaymentId
                })}
          </Typography>
        )}
      </div>
    </div>
  )
}
