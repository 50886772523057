import gql from 'graphql-tag'
import {useCallback} from 'react'
import {useMutation} from '@apollo/react-hooks'

import {
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  RecoverPasswordMutation,
  RecoverPasswordMutationVariables,
  VerifyTokenMutation,
  VerifyTokenMutationVariables
} from '../../../__generated__/schema'
import {USER_PROPERTIES_FRAGMENT} from '../../../utils/auth'

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username)
  }
`

const RECOVER_PASSWORD = gql`
  mutation RecoverPassword($token: String!, $password: String!) {
    recoverPassword(token: $token, password: $password)
  }
`

const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyEmailToken(token: $token) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

export const useForgotPassword = () => {
  const [forgotPassword] =
    useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
      FORGOT_PASSWORD
    )
  return useCallback(
    (username: string) => forgotPassword({variables: {username}}),
    [forgotPassword]
  )
}

export const useRecoverPassword = () => {
  const [recoverPassword] =
    useMutation<RecoverPasswordMutation, RecoverPasswordMutationVariables>(
      RECOVER_PASSWORD
    )
  return useCallback(
    (token: string, password: string) =>
      recoverPassword({variables: {token, password}}),
    [recoverPassword]
  )
}

export const useVerifyToken = () => {
  const [verifyToken] =
    useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VERIFY_TOKEN)
  return useCallback(
    (token: string) => verifyToken({variables: {token}}),
    [verifyToken]
  )
}
