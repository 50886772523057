import {ShapeVariant} from '@attendio/shared-components'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CanvasObjectType,
  IIconStateValue,
  IShapeStateValue,
  IZoneStateValue
} from '../../shared/editor/stateTypes'
import {Theme} from '../../theme'
import {useIsNumberWithMinAbsoluteBoundary} from '../../utils/formsValidations'
import {useObjectsActions} from '../redux/objects/actions'
import {usePlaceholderAndValue} from './placeholderAndValue'
import {
  ITextFieldWithValidationsProps,
  TextFieldWithValidations
} from './TextFieldWithValidations'
import {roundValueIfNumeric} from './utils'

type ObjectWithDimension = IZoneStateValue | IIconStateValue | IShapeStateValue

const getWidthBoundaryForObject = (object: ObjectWithDimension): number => {
  if (object.type === CanvasObjectType.Zone) {
    return 50
  }
  if (object.type === CanvasObjectType.Icon) {
    return 43
  }
  if (object.type === CanvasObjectType.Shape) {
    if (
      [ShapeVariant.Rectangle, ShapeVariant.Ellipse].includes(
        object.config.shapeVariant
      )
    ) {
      return 10
    }
    return 0
  }
  return NaN
}

const getMaxBoundaryForObjects = (
  objects: ObjectWithDimension[],
  getBoundaryForObject: (object: ObjectWithDimension) => number
): number => {
  const boundaries: number[] = objects
    .map((o) => getBoundaryForObject(o))
    .filter((n) => !isNaN(n))
  return boundaries.length ? Math.max(...boundaries) : 0
}

const useWidthValidationOptions = (
  objects: ObjectWithDimension[]
): ITextFieldWithValidationsProps['validationOptions'] => {
  const absoluteBoundary = getMaxBoundaryForObjects(
    objects,
    getWidthBoundaryForObject
  )
  const isNumberWithMinAbsoluteBoundary =
    useIsNumberWithMinAbsoluteBoundary(absoluteBoundary)
  return absoluteBoundary !== 0
    ? {
        isRequired: true,
        validators: {
          isNumberWithMinAbsoluteBoundary
        }
      }
    : {
        isRequired: true
      }
}

const getHeightBoundaryForObject = (object: ObjectWithDimension): number => {
  if (object.type === CanvasObjectType.Zone) {
    return 30
  }
  if (object.type === CanvasObjectType.Icon) {
    return 43
  }
  if (object.type === CanvasObjectType.Shape) {
    if (object.config.shapeVariant === ShapeVariant.Rectangle) {
      return 1
    }
    if (object.config.shapeVariant === ShapeVariant.Ellipse) {
      return 10
    }
    return 0
  }
  return NaN
}

const useHeightValidationOptions = (
  objects: ObjectWithDimension[]
): ITextFieldWithValidationsProps['validationOptions'] => {
  const absoluteBoundary = getMaxBoundaryForObjects(
    objects,
    getHeightBoundaryForObject
  )
  const isNumberWithMinAbsoluteBoundary =
    useIsNumberWithMinAbsoluteBoundary(absoluteBoundary)
  return absoluteBoundary !== 0
    ? {
        isRequired: true,
        validators: {
          isNumberWithMinAbsoluteBoundary
        }
      }
    : {
        isRequired: true
      }
}

interface IDimensionTextFieldsProps {
  selectedObjects: ObjectWithDimension[]
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2)
  }
}))

export const DimensionTextFields: React.FC<IDimensionTextFieldsProps> = ({
  selectedObjects
}: IDimensionTextFieldsProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {updateObjects} = useObjectsActions()
  const getDimensionChangeHandler =
    (field: 'height' | 'width') => (e: ChangeEvent<HTMLInputElement>) => {
      const parsedNumber = parseFloat(e.target.value)
      if (!isNaN(parsedNumber)) {
        updateObjects(
          selectedObjects.map(
            <T extends ObjectWithDimension>(object: T): T => ({
              ...object,
              config: {
                ...object.config,
                dimensions: {
                  ...object.config.dimensions,
                  [field]: parsedNumber
                }
              }
            })
          )
        )
      }
    }
  const {placeholder: widthPlaceholder, value: width} = usePlaceholderAndValue({
    selectedObjects,
    iteratee: 'config.dimensions.width'
  })
  const {placeholder: heightPlaceholder, value: height} =
    usePlaceholderAndValue({
      selectedObjects,
      iteratee: 'config.dimensions.height'
    })
  const widthValidationOptions = useWidthValidationOptions(selectedObjects)
  const heightValidationOptions = useHeightValidationOptions(selectedObjects)
  return (
    <div className={classes.root}>
      <TextFieldWithValidations
        label={t('Width')}
        type="number"
        defaultValue={roundValueIfNumeric(width)}
        placeholder={widthPlaceholder}
        variant="outlined"
        onChange={getDimensionChangeHandler('width')}
        fullWidth
        validationOptions={widthValidationOptions}
      />
      <TextFieldWithValidations
        label={t('Height')}
        type="number"
        defaultValue={roundValueIfNumeric(height)}
        placeholder={heightPlaceholder}
        variant="outlined"
        onChange={getDimensionChangeHandler('height')}
        fullWidth
        validationOptions={heightValidationOptions}
      />
    </div>
  )
}
