import AddIcon from '@mui/icons-material/Add'
import {Box, Button, List, ListItem, ListItemText} from '@mui/material'
import {TFunction} from 'i18next'
import {uniqueId} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {Scalars, TypographyVariant} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {
  BlockType,
  ISelectFooterForm,
  ISelectHeaderForm,
  SelectFooterFormField,
  SelectHeaderFormField,
  WebsitePageMode
} from '../types'
import {useTranslateBlockType} from '../utils'
import {MuiButtonSize, MuiButtonVariant} from './editBlockDrawer/types'
import {SelectFooterDialog} from './SelectFooterDialog'
import {SelectHeaderDialog} from './SelectHeaderDialog'
import {useWebsitePageContext} from './WebsitePageContext'

const getInitBlockProps = (block: BlockType, t: TFunction) => {
  switch (block) {
    case BlockType.CardsRow:
      return {cards: []}
    case BlockType.ButtonGroup:
      return {
        buttons: [
          {
            id: uniqueId(),
            label: t('Button'),
            link: '/',
            size: MuiButtonSize.Large,
            variant: MuiButtonVariant.Contained
          }
        ]
      }
    case BlockType.Label:
      return {variant: TypographyVariant.H6, text: t('Label')}
    default:
      return undefined
  }
}

export const Blocks: React.FC = () => {
  const {t} = useTranslation()
  const {setBlocksWithId, selectedWebsitePageMode} = useWebsitePageContext()
  const {
    state: isSelectFooterDialogOpen,
    setTrue: openSelectFooterDialog,
    setFalse: closeSelectFooterDialog
  } = useBooleanState(false)
  const {
    state: isSelectHeaderDialogOpen,
    setTrue: openSelectHeaderDialog,
    setFalse: closeSelectHeaderDialog
  } = useBooleanState(false)
  const translateBlockType = useTranslateBlockType()
  const handleAddBlock = useCallback(
    (block: BlockType) => () => {
      if (block === BlockType.Footer) {
        openSelectFooterDialog()
      } else if (block === BlockType.Header) {
        openSelectHeaderDialog()
      } else {
        setBlocksWithId((prevBlocks: Scalars['JSON']) => [
          ...prevBlocks,
          {id: uuidv4(), type: block, props: getInitBlockProps(block, t)}
        ])
      }
    },
    [openSelectFooterDialog, openSelectHeaderDialog, setBlocksWithId, t]
  )
  const handleFooterSelect = useCallback(
    (formData: ISelectFooterForm) => {
      setBlocksWithId((prevBlocks: Scalars['JSON']) => [
        ...prevBlocks,
        {
          id: uuidv4(),
          type: BlockType.Footer,
          props: {
            footerId: formData[SelectFooterFormField.Id],
            className: 'px-2 pt-2 phablet:px-3 pb-3'
          }
        }
      ])
      closeSelectFooterDialog()
    },
    [closeSelectFooterDialog, setBlocksWithId]
  )
  const handleHeaderSelect = useCallback(
    (formData: ISelectHeaderForm) => {
      setBlocksWithId((prevBlocks: Scalars['JSON']) => [
        ...prevBlocks,
        {
          id: uuidv4(),
          type: BlockType.Header,
          props: {
            headerId: formData[SelectHeaderFormField.Id]
          }
        }
      ])
      closeSelectHeaderDialog()
    },
    [closeSelectHeaderDialog, setBlocksWithId]
  )
  return (
    <>
      <Box sx={{py: 1, width: '100%', height: '100%'}}>
        <List sx={{overflowY: 'auto', p: 0}}>
          {Object.values(BlockType).map((block) => (
            <ListItem
              key={block}
              sx={{
                px: 2,
                borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
                ':last-child': {
                  borderBottom: 'none'
                }
              }}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {translateBlockType(block)}
                    {selectedWebsitePageMode === WebsitePageMode.Designing && (
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddBlock(block)}
                      >
                        {t('Add')}
                      </Button>
                    )}
                  </Box>
                }
                primaryTypographyProps={{variant: 'body2', component: 'div'}}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <SelectFooterDialog
        isOpen={isSelectFooterDialogOpen}
        onClose={closeSelectFooterDialog}
        onSubmit={handleFooterSelect}
      />
      <SelectHeaderDialog
        isOpen={isSelectHeaderDialogOpen}
        onClose={closeSelectHeaderDialog}
        onSubmit={handleHeaderSelect}
      />
    </>
  )
}
