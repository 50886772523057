import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'

import {RenderOnData} from '../../../../common'
import {Blank} from '../../../../visual/Blank'
import {IEventsScreenData, useGetEventsScreenData} from '../graphql'
import {EventsCalendarContent} from './CalendarContent'

interface IEventsCalendarProps {
  setHasDivisions?: (hasDivisions: boolean) => void
}

export const EventsCalendar: React.FC<IEventsCalendarProps> = ({
  setHasDivisions
}: IEventsCalendarProps) => {
  const {data, loading, error} = useGetEventsScreenData()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()

  const onEditVenuesButtonClick = useCallback(
    () => history.push(routeTo.admin.venues.home()),
    [history]
  )

  if (setHasDivisions && data.sortedVenues) {
    setHasDivisions(data.sortedVenues.length > 0)
  }

  return (
    <RenderOnData
      loading={loading}
      error={error}
      data={data}
      ignoreLoadingIfData
      dataCondition={(data) => {
        return (
          data.events != null &&
          data.venues != null &&
          data.sortedVenues != null
        )
      }}
      errorMessage={t<string>('Could not load events data')}
    >
      {(data) => {
        if ((data as unknown as IEventsScreenData).sortedVenues.length === 0) {
          return (
            <Blank
              title={t('Missing active layout pricing')}
              description={t(
                'Unable to create new events, because you are missing venue, auditorium, auditorium layout or layout pricing. You can change this in venues settings.'
              )}
              actions={
                P([PermissionCode.ManageVenues]) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onEditVenuesButtonClick}
                  >
                    {t('Edit venues')}
                  </Button>
                ) : undefined
              }
            />
          )
        }

        return (
          <EventsCalendarContent
            data={data as unknown as IEventsScreenData}
            isLoadingMore={loading}
          />
        )
      }}
    </RenderOnData>
  )
}
