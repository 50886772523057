import {Typography, useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {CashDeskEventFragment} from '../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {routeTo} from '../../../../../utils/routes'
import {MediaSizes} from '../../../../constants'
import {
  CashdeskEventItem,
  PinnedEventItem
} from '../../components/CashdeskEventItem'
import {GroupedEventsItem} from '../../components/GroupedEventsItem'
import {
  MobileCashdeskEventItem,
  MobileCashdeskPinnedEventItem
} from '../../components/MobileCashdeskEventItem'
import {groupEventsByStartAtDate} from '../../utils'
import {ListItemWrapper} from '../ListItemWrapper'
import {usePinnedEvents} from '../PinnedEventsContext'

const useStyles = makeStyles<Theme, {isPhablet: boolean}>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'row',
    paddingBottom: ({isPhablet}) => (isPhablet ? theme.spacing(19.5) : 0)
  }
}))

interface IEventsContentProps {
  events: CashDeskEventFragment[]
  displayAsSearchResults: boolean
}

export const EventsContent: React.FC<IEventsContentProps> = ({
  events,
  displayAsSearchResults
}: IEventsContentProps) => {
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {t} = useTranslation()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const classes = useStyles({isPhablet})
  const {pinnedEvents} = usePinnedEvents()
  const history = useHistory()
  const handleEventDetailClick = useCallback(
    (eventId: number) => {
      history.push(routeTo.admin.cashDesk.eventDetail(eventId))
    },
    [history]
  )
  const handleEventClick = useCallback(
    (eventId: number) => {
      history.push(routeTo.admin.cashDesk.eventAuditoriumPreview(eventId))
    },
    [history]
  )

  const {unpinEvent} = usePinnedEvents()
  return (
    <div className={classes.root}>
      {!displayAsSearchResults && Boolean(pinnedEvents.length) && (
        <>
          <Typography variant="subtitle2" color="textSecondary">
            {t('Pinned events')}
          </Typography>
          <div>
            {groupEventsByStartAtDate(
              pinnedEvents,
              getUserLocaleTranslation
            ).map((groupedEvents) => (
              <ListItemWrapper key={groupedEvents.date}>
                <GroupedEventsItem
                  onEventClick={handleEventClick}
                  onEventDetailClick={unpinEvent}
                  item={groupedEvents}
                  EventItemComp={
                    isPhablet ? MobileCashdeskPinnedEventItem : PinnedEventItem
                  }
                  isPhablet={isPhablet}
                />
              </ListItemWrapper>
            ))}
          </div>
          <Typography variant="subtitle2" color="textSecondary">
            {t('All events')}
          </Typography>
        </>
      )}
      {displayAsSearchResults && (
        <Typography variant="subtitle2" color="textSecondary">
          {t('Search results')}
        </Typography>
      )}
      {groupEventsByStartAtDate(events, getUserLocaleTranslation).map(
        (groupedEvents) => (
          <ListItemWrapper key={groupedEvents.date}>
            <GroupedEventsItem
              onEventClick={handleEventClick}
              item={groupedEvents}
              EventItemComp={
                isPhablet ? MobileCashdeskEventItem : CashdeskEventItem
              }
              onEventDetailClick={handleEventDetailClick}
              isPhablet={isPhablet}
            />
          </ListItemWrapper>
        )
      )}
    </div>
  )
}
