import SettingsIcon from '@mui/icons-material/Settings'
import {Box, Typography, useMediaQuery} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {HeaderActionType} from '../../../common/header/types'
import {
  CardRightArrowIcon,
  NavigationCard
} from '../../../common/NavigationCard'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {PrimaryHeader} from '../Header'
import {FullScreenCenteredLayout} from '../Layout'

export const useNavigationItems = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const hasBasePermission =
    P([PermissionCode.CheckPassCodeIn]) ||
    P([PermissionCode.CheckPassCodeOut]) ||
    P([PermissionCode.ReadItemByPassCode])
  return useMemo(
    (): {
      events?: {id: string; label: string; route: string}
      tourTimeSlots?: {id: string; label: string; route: string}
      tours?: {id: string; label: string; route: string}
      venues?: {id: string; label: string; route: string}
    } => ({
      ...(hasBasePermission && P([PermissionCode.ReadPaginatedEvents])
        ? {
            events: {
              id: 'events',
              label: t('Events'),
              route: routeTo.admin.checkTickets.events()
            }
          }
        : {}),
      ...(hasBasePermission && P([PermissionCode.ReadTourTimeSlots])
        ? {
            tourTimeSlots: {
              id: 'tourTimeSlots',
              label: t('Tour time slots'),
              route: routeTo.admin.checkTickets.tourTimeSlots()
            }
          }
        : {}),
      ...(hasBasePermission && P([PermissionCode.ReadTours])
        ? {
            tours: {
              id: 'tours',
              label: t('Tours'),
              route: routeTo.admin.checkTickets.tours()
            }
          }
        : {})
    }),
    [P, hasBasePermission, t]
  )
}

export const Switcher: React.FC = () => {
  const {t} = useTranslation()
  const navigationItems = useNavigationItems()
  const history = useHistory()
  const handleOnSettingsIconClick = useCallback(() => {
    history.push(routeTo.admin.checkTickets.advancedSettings())
  }, [history])
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const maxWidth = isPhablet ? '100%' : undefined
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Check tickets')}
          rightActions={[
            {
              Icon: SettingsIcon,
              onClick: handleOnSettingsIconClick,
              label: t('Settings'),
              type: HeaderActionType.IconButton,
              cypressId: 'check-tickets-settings-icon'
            }
          ]}
          isDatetimeEnabled
        />
      }
    >
      <FullScreenCenteredLayout>
        <Box
          sx={{
            py: 2,
            px: {sm: 1, md: 0},
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}
        >
          <Typography variant="h6">{t('Choose option')}</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                sm: 'auto',
                md: 'auto auto auto'
              },
              gap: 2
            }}
          >
            {Object.values(navigationItems).map(
              (item) =>
                item && (
                  <NavigationCard
                    key={item.id}
                    title={item.label}
                    rightAction={
                      <CardRightArrowIcon
                        onArrowClick={() => history.push(item.route)}
                      />
                    }
                    maxWidth={maxWidth}
                  />
                )
            )}
          </Box>
        </Box>
      </FullScreenCenteredLayout>
    </PageWithHeaderTemplate>
  )
}
