import {useTranslation} from 'react-i18next'
import {Unit} from '../__generated__/schema'

export const useTranslateUnit = () => {
  const {t} = useTranslation()
  const translatedUnits: Record<Unit, string> = {
    [Unit.Kilogram]: t<string>('Unit->Kilogram'),
    [Unit.Gram]: t<string>('Unit->Gram'),
    [Unit.Milligram]: t<string>('Unit->Milligram'),
    [Unit.Liter]: t<string>('Unit->Liter'),
    [Unit.Milliliter]: t<string>('Unit->Milliliter'),
    [Unit.Centiliter]: t<string>('Unit->Centiliter'),
    [Unit.Piece]: t<string>('Unit->Piece')
  }
  return (unit: Unit) => translatedUnits[unit]
}
