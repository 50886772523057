import {
  Box,
  Checkbox,
  CheckboxProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypeIcon,
  getIcon
} from '../../../../../common/admissionTypeIcon'
import {ColorBox} from '../../../../../common/ColorBox'
import {IconBox} from '../../../../../common/IconBox'

interface IAdmissionRowListItemProps {
  name: string
  icon?: AdmissionTypeIcon
  color: string
  capacity: number
  minimalQuantity: number
  isCheckBoxHidden?: boolean
  checkBoxProps?: CheckboxProps
}

interface IPrimaryProps
  extends Pick<IAdmissionRowListItemProps, 'name' | 'icon' | 'color'> {}

const Primary: React.FC<IPrimaryProps> = ({
  name,
  icon,
  color
}: IPrimaryProps) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
    {icon ? (
      <IconBox
        Icon={getIcon(icon)}
        hexColor={color}
        hideBackground
        sx={{width: 24, height: 24}}
        iconSx={{width: 24, height: 24}}
      />
    ) : (
      <ColorBox hexColor={color} />
    )}
    <Typography variant="body1">{name}</Typography>
  </Box>
)

interface ISecondaryProps
  extends Pick<IAdmissionRowListItemProps, 'capacity' | 'minimalQuantity'> {}

const Secondary: React.FC<ISecondaryProps> = ({
  capacity,
  minimalQuantity
}: ISecondaryProps) => {
  const {t} = useTranslation()
  return (
    <Typography variant="caption" color="textSecondary">
      {[
        t('Capacity: {{capacity}}', {capacity}),
        t('Minimal quantity per order: {{minimalQuantity}}', {minimalQuantity})
      ].join(' • ')}
    </Typography>
  )
}

export const AdmissionRowListItem: React.FC<IAdmissionRowListItemProps> = ({
  name,
  icon,
  color,
  isCheckBoxHidden,
  checkBoxProps,
  capacity,
  minimalQuantity
}: IAdmissionRowListItemProps) => (
  <ListItem
    sx={(theme) => ({
      minHeight: 40,
      p: 0,
      borderBottom: `solid ${theme.palette.divider} 1px`,
      '&:last-child': {
        borderBottom: 0
      },
      opacity: isCheckBoxHidden
        ? theme.palette.action.disabledOpacity
        : undefined,
      pointerEvents: isCheckBoxHidden ? 'none' : 'auto'
    })}
  >
    {!isCheckBoxHidden && (
      <ListItemIcon>
        <Checkbox {...checkBoxProps} color="primary" />
      </ListItemIcon>
    )}
    <ListItemText
      sx={{overflowWrap: 'anywhere'}}
      inset={isCheckBoxHidden}
      primary={<Primary name={name} icon={icon} color={color} />}
      secondary={
        <Secondary capacity={capacity} minimalQuantity={minimalQuantity} />
      }
    />
  </ListItem>
)
