import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  ActivateDivisionMutation,
  ActivateDivisionMutationVariables,
  CreateDivisionMutation,
  DeactivateDivisionMutation,
  DeactivateDivisionMutationVariables,
  DeleteDivisionMutation,
  DeleteDivisionMutationVariables,
  DetailDivisionPropertiesFragment,
  DivisionPropertiesFragment,
  DivisionQuery,
  DivisionQueryVariables,
  DivisionsQuery,
  DivisionsQueryVariables,
  MutationCreateDivisionArgs,
  MutationUpdateDivisionArgs,
  UpdateDivisionMutation
} from '../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../utils/apollo'

export const DIVISION_PROPERTIES_FRAGMENT = gql`
  fragment DivisionProperties on Division {
    id
    clientId
    name
    code
    shortName
    address {
      town
    }
    state
    type
    serviceTime
  }
`

// TODO: decide whether we want two separate fragments
// or only one that consist of all fields
export const DETAIL_DIVISION_PROPERTIES_FRAGMENT = gql`
  fragment DetailDivisionProperties on Division {
    id
    clientId
    name
    shortName
    code
    state
    type
    address {
      complex
      street
      town
      postalCode
      country
    }
    phoneNumber
    email
    IBAN
    SWIFT
    invoiceFreq
    specificSymbol
    onlinePurchaseActive
    onlineReservationActive
    posPurchaseActive
    posReservationActive
    salesEndCashDeskValue
    salesEndCashDeskType
    salesEndWebValue
    salesEndWebType
    gateOpensValue
    gateOpensType
    gateClosesValue
    gateClosesType
    checkingOption
    reservationEndCashDeskValue
    reservationEndCashDeskType
    reservationEndWebValue
    reservationEndWebType
    onlineCreateReservationEndValue
    onlineCreateReservationEndType
    posCreateReservationEndValue
    posCreateReservationEndType
    website
    serviceTime
  }
`

const GET_DIVISIONS = gql`
  query Divisions {
    divisions {
      ...DivisionProperties
    }
  }
  ${DIVISION_PROPERTIES_FRAGMENT}
`

const GET_DIVISION = gql`
  query Division($id: Int!) {
    division(id: $id) {
      ...DetailDivisionProperties
    }
  }
  ${DETAIL_DIVISION_PROPERTIES_FRAGMENT}
`

const CREATE_DIVISION = gql`
  mutation CreateDivision($data: DivisionInput!) {
    createDivision(data: $data) {
      ...DetailDivisionProperties
    }
  }
  ${DETAIL_DIVISION_PROPERTIES_FRAGMENT}
`

const UPDATE_DIVISION = gql`
  mutation UpdateDivision($id: Int!, $data: DivisionInput!) {
    updateDivision(id: $id, data: $data) {
      ...DetailDivisionProperties
    }
  }
  ${DETAIL_DIVISION_PROPERTIES_FRAGMENT}
`

const ACTIVATE_DIVISION = gql`
  mutation ActivateDivision($id: Int!) {
    activateDivision(id: $id) {
      id
      state
    }
  }
`

const DEACTIVATE_DIVISION = gql`
  mutation DeactivateDivision($id: Int!) {
    deactivateDivision(id: $id) {
      id
      state
    }
  }
`

const DELETE_DIVISION = gql`
  mutation DeleteDivision($id: Int!) {
    deleteDivision(id: $id) {
      id
    }
  }
`

export const useGetDivisions = () => {
  return useQuery<DivisionsQuery, DivisionsQueryVariables>(GET_DIVISIONS)
}

export const useGetDivision = (id: number) => {
  return useQuery<DivisionQuery, DivisionQueryVariables>(GET_DIVISION, {
    variables: {id}
  })
}

interface DivisionsCacheResult {
  divisions: Array<
    DivisionPropertiesFragment | DetailDivisionPropertiesFragment
  >
}

export const useCreateDivision = () => {
  const [createDivision] = useMutation<
    CreateDivisionMutation,
    MutationCreateDivisionArgs
  >(CREATE_DIVISION, {
    update(cache, {data}) {
      const cachedData = cache.readQuery<DivisionsCacheResult>({
        query: GET_DIVISIONS
      })
      const divisions = cachedData ? cachedData.divisions : []
      data &&
        cache.writeQuery({
          query: GET_DIVISIONS,
          data: {
            // Note: this may cause inconsistencies based on sorting order,
            // we may later consider refetching queries instead
            divisions: [data.createDivision, ...divisions]
          }
        })
    }
  })
  return useCallback(
    (divisionData: MutationCreateDivisionArgs) =>
      createDivision({variables: divisionData}),
    [createDivision]
  )
}

export const useUpdateDivision = () => {
  const [updateDivision] =
    useMutation<UpdateDivisionMutation, MutationUpdateDivisionArgs>(
      UPDATE_DIVISION
    )
  return useCallback(
    (divisionData: MutationUpdateDivisionArgs) =>
      updateDivision({variables: divisionData}),
    [updateDivision]
  )
}

export const useActivateDivision = () => {
  const [activateDivision] =
    useMutation<ActivateDivisionMutation, ActivateDivisionMutationVariables>(
      ACTIVATE_DIVISION
    )
  return useCallback(
    (id: number) => activateDivision({variables: {id}}),
    [activateDivision]
  )
}

export const useDeactivateDivision = () => {
  const [deactivateDivision] =
    useMutation<
      DeactivateDivisionMutation,
      DeactivateDivisionMutationVariables
    >(DEACTIVATE_DIVISION)
  return useCallback(
    (id: number) => deactivateDivision({variables: {id}}),
    [deactivateDivision]
  )
}

export const useDeleteDivision = (id: number) => {
  const [deleteDivision] = useMutation<
    DeleteDivisionMutation,
    DeleteDivisionMutationVariables
  >(DELETE_DIVISION, {
    refetchQueries: () => {
      return [{query: GET_DIVISIONS}]
    },
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, data.deleteDivision)
      }
    }
  })
  return useCallback(
    () => deleteDivision({variables: {id}}),
    [deleteDivision, id]
  )
}
