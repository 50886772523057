import React from 'react'

import {
  ISingleSideNavigationListProps,
  SingleSideNavigationList
} from '../../../../common'
import {TOP_BAR_HEIGHT} from '../../../../constants'

interface IShowSideNavigationListProps {
  items: ISingleSideNavigationListProps['items']
}

export const ShowSingleSideNavigationList: React.FC<IShowSideNavigationListProps> =
  ({items}: IShowSideNavigationListProps) => (
    <SingleSideNavigationList items={items} top={30 + TOP_BAR_HEIGHT * 2} />
  )
