import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Chip, IconButton, IconButtonProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NEUTRAL_100_COLOR, PRIMARY_100_COLOR, Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  name: {
    fontWeight: 'bold'
  },
  clickableBody: {
    cursor: 'pointer'
  },
  info: {
    display: 'flex',
    alignItems: 'center'
  },
  body: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1
  },
  chip: {
    height: 20,
    lineHeight: '100%',
    padding: 0
  },
  activeChip: {
    backgroundColor: PRIMARY_100_COLOR,
    color: theme.palette.primary.main
  },
  inactiveChip: {
    backgroundColor: NEUTRAL_100_COLOR,
    color: theme.palette.text.disabled
  },
  denominationCaption: {
    height: 18
  }
}))

interface IIntentCountControllerProps {
  onDecrementButtonClick?: IconButtonProps['onClick']
  onIncrementButtonClick?: IconButtonProps['onClick']
  onBodyClick?: () => void
  count: number
  denominationLabel?: string
  amountLabel?: string
  name: string
}

export const IntentCountController: React.FC<IIntentCountControllerProps> = ({
  count = 0,
  onDecrementButtonClick,
  onIncrementButtonClick,
  onBodyClick,
  denominationLabel,
  amountLabel,
  name
}: IIntentCountControllerProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.root}>
      <IconButton
        disabled={count < 1}
        color="primary"
        onClick={onDecrementButtonClick}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
      <div
        className={cn(classes.body, {
          [classes.clickableBody]: Boolean(onBodyClick)
        })}
        onClick={onBodyClick}
      >
        <div>
          <Typography variant="subtitle2" className={classes.name}>
            {name}
          </Typography>
          <div className={classes.info}>
            <Chip
              size="small"
              label={t('{{xTimes}}x', {xTimes: count})}
              classes={{
                root: cn(
                  classes.chip,
                  count === 0 ? classes.inactiveChip : classes.activeChip
                )
              }}
            />
            <Typography
              variant="caption"
              className={classes.denominationCaption}
            >
              &nbsp;
              {denominationLabel}
            </Typography>
          </div>
        </div>
        {amountLabel && <Typography variant="body2">{amountLabel}</Typography>}
      </div>
      <IconButton color="primary" onClick={onIncrementButtonClick}>
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  )
}
