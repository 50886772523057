import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  SearchLightweightVoucherCampaingsQuery,
  SearchLightweightVoucherCampaingsQueryVariables,
  SellingChannel,
  VoucherCampaignState,
  VoucherTransactionIntentReason,
  VoucherTransactionIntentsFilterInput,
  VoucherTransactionIntentType
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {
  useTranslateVoucherIntentReason,
  useTranslateVoucherIntentType
} from '../../../../hooks/translateVoucherTransactionIntent'
import {useEnsurePermissions} from '../../../../utils/auth'
import {
  RenderOnData,
  Search,
  useCombineStringifySearchObjectFunctions
} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchSelectRow} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {SEARCH_LIGHTWEIGHT_VOUCHER_CAMPAINGS} from './graphql'

const mapVoucherCodeToFilter = (
  filter: VoucherTransactionIntentsFilterInput,
  voucherCode: string | undefined
): VoucherTransactionIntentsFilterInput => ({
  ...filter,
  voucherCode: voucherCode || undefined
})

const mapTypeToFilter = (
  filter: VoucherTransactionIntentsFilterInput,
  type: VoucherTransactionIntentType | undefined
): VoucherTransactionIntentsFilterInput => ({
  ...filter,
  type
})

const mapReasonToFilter = (
  filter: VoucherTransactionIntentsFilterInput,
  reason: VoucherTransactionIntentReason | undefined
): VoucherTransactionIntentsFilterInput => ({
  ...filter,
  reasons: reason ? [reason] : undefined
})

const mapCampaignIdToFilter = (
  filter: VoucherTransactionIntentsFilterInput,
  campaignId: number | undefined
): VoucherTransactionIntentsFilterInput => ({...filter, campaignId})

const mapSellingChannelToFilter = (
  filter: VoucherTransactionIntentsFilterInput,
  channel: SellingChannel | undefined
): VoucherTransactionIntentsFilterInput => ({...filter, channel})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateVoucherIntentType = useTranslateVoucherIntentType()
  const translateVoucherIntentReason = useTranslateVoucherIntentReason()
  const translateSellingChannel = useTranslateSellingChannel()
  const getVoucherCodeFromSearchObject = (
    filter: VoucherTransactionIntentsFilterInput
  ) => filter.voucherCode || undefined
  const getTypeFromSearchObject = (
    filter: VoucherTransactionIntentsFilterInput
  ) =>
    filter.type
      ? t('Type: {{type}}', {type: translateVoucherIntentType(filter.type)})
      : undefined
  const getReasonFromSearchObject = (
    filter: VoucherTransactionIntentsFilterInput
  ) =>
    filter.reasons
      ? t('Reason: {{reason}}', {
          reason: translateVoucherIntentReason(filter.reasons[0])
        })
      : undefined
  const getCampaignFromSearchObject = (
    filter: VoucherTransactionIntentsFilterInput,
    campaigns: SearchLightweightVoucherCampaingsQuery['voucherCampaigns']['items']
  ) => {
    if (filter.campaignId) {
      const campaign = campaigns.find(({id}) => id === filter.campaignId)
      return campaign
        ? t('Campaign: {{campaign}}', {campaign: campaign.name})
        : t('Campaign ID: {{id}}', {id: filter.campaignId})
    }
    return undefined
  }
  const getSellingChannelFromSearchObject = (
    filter: VoucherTransactionIntentsFilterInput
  ) =>
    filter.channel
      ? t('Selling channel: {{channel}}', {
          channel: translateSellingChannel(filter.channel)
        })
      : undefined
  return {
    getVoucherCodeFromSearchObject,
    getTypeFromSearchObject,
    getReasonFromSearchObject,
    getCampaignFromSearchObject,
    getSellingChannelFromSearchObject
  }
}

interface IVoucherTransactionsSearchProps {
  onFilterChange: (filter: VoucherTransactionIntentsFilterInput) => void
}

export const VoucherTransactionsSearch: React.FC<IVoucherTransactionsSearchProps> =
  ({onFilterChange}: IVoucherTransactionsSearchProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {
      data: voucherCampaignsData,
      loading: voucherCampaignsLoading,
      error: voucherCampaignsError
    } = useQuery<
      SearchLightweightVoucherCampaingsQuery,
      SearchLightweightVoucherCampaingsQueryVariables
    >(SEARCH_LIGHTWEIGHT_VOUCHER_CAMPAINGS, {
      variables: {
        filter: {
          state: [VoucherCampaignState.Active, VoucherCampaignState.Invalid]
        },
        paginationInput: {limit: 200, offset: 0}
      },
      skip: !P([PermissionCode.ReadVoucherCampaigns])
    })
    const {
      getVoucherCodeFromSearchObject,
      getTypeFromSearchObject,
      getReasonFromSearchObject,
      getCampaignFromSearchObject,
      getSellingChannelFromSearchObject
    } = useGetFieldFromSearchObject()
    const translateVoucherIntentType = useTranslateVoucherIntentType()
    const translateVoucherIntentReason = useTranslateVoucherIntentReason()
    const translateSellingChannel = useTranslateSellingChannel()
    const mapSearchObjectToInputText =
      useCombineStringifySearchObjectFunctions<VoucherTransactionIntentsFilterInput>(
        getVoucherCodeFromSearchObject,
        getTypeFromSearchObject,
        getSellingChannelFromSearchObject,
        getReasonFromSearchObject,
        (filter) =>
          getCampaignFromSearchObject(
            filter,
            voucherCampaignsData?.voucherCampaigns.items || []
          )
      )
    return (
      <Search<VoucherTransactionIntentsFilterInput>
        storageKey="VOUCHER_TRANSACTIONS"
        placeholder={t('Search for transaction')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapVoucherCodeToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={{}}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            <AdvancedSearchSelectRow<
              VoucherTransactionIntentsFilterInput,
              VoucherTransactionIntentType
            >
              label={t('Type')}
              value={advancedSearchObject.type || undefined}
              options={[
                VoucherTransactionIntentType.Credit,
                VoucherTransactionIntentType.Debit
              ].map((type) => ({
                id: type,
                label: translateVoucherIntentType(type)
              }))}
              mapSelectValueToSearchObject={mapTypeToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchSelectRow<
              VoucherTransactionIntentsFilterInput,
              SellingChannel
            >
              label={t('Channel')}
              value={advancedSearchObject.channel || undefined}
              options={[SellingChannel.Retail, SellingChannel.ECommerce].map(
                (channel) => ({
                  id: channel,
                  label: translateSellingChannel(channel)
                })
              )}
              mapSelectValueToSearchObject={mapSellingChannelToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchSelectRow<
              VoucherTransactionIntentsFilterInput,
              VoucherTransactionIntentReason
            >
              label={t('Reason')}
              value={
                advancedSearchObject.reasons
                  ? advancedSearchObject.reasons[0]
                  : undefined
              }
              options={[
                VoucherTransactionIntentReason.Charge,
                VoucherTransactionIntentReason.Refund,
                VoucherTransactionIntentReason.TopUp,
                VoucherTransactionIntentReason.Reward,
                VoucherTransactionIntentReason.Import,
                VoucherTransactionIntentReason.Export,
                VoucherTransactionIntentReason.Other
              ].map((reason) => ({
                id: reason,
                label: translateVoucherIntentReason(reason)
              }))}
              mapSelectValueToSearchObject={mapReasonToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchTextRow<VoucherTransactionIntentsFilterInput>
              label={t('Code')}
              placeholder={t('Enter voucher code')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              mapTextToSearchObject={mapVoucherCodeToFilter}
              advancedSearchObject={advancedSearchObject}
              value={advancedSearchObject.voucherCode ?? ''}
            />
            {P([PermissionCode.ReadVoucherCampaigns]) && (
              <RenderOnData<SearchLightweightVoucherCampaingsQuery>
                data={voucherCampaignsData}
                loading={voucherCampaignsLoading}
                error={voucherCampaignsError}
                errorMessage={t<string>('Error while loading voucher campaign')}
                dataCondition={(data) =>
                  Array.isArray(data.voucherCampaigns.items)
                }
              >
                {({voucherCampaigns}) => (
                  <AdvancedSearchSelectRow<
                    VoucherTransactionIntentsFilterInput,
                    number
                  >
                    label={t('Campaign')}
                    value={advancedSearchObject.campaignId || undefined}
                    options={voucherCampaigns.items.map(
                      ({id, name: label}) => ({id, label})
                    )}
                    mapSelectValueToSearchObject={mapCampaignIdToFilter}
                    setSearchObject={setAdvancedSearchObject}
                    searchObject={advancedSearchObject}
                  />
                )}
              </RenderOnData>
            )}
          </AdvancedSearchBase>
        )}
      />
    )
  }
