import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentsFilterInput,
  ProductsStatisticsFilterInput,
  ProductState,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useTranslateProductState} from '../../../../hooks/translateProductState'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'

const mapProductNameToFilter = (
  filter: ProductsStatisticsFilterInput,
  productName: string | undefined
): ProductsStatisticsFilterInput => ({
  ...filter,
  productName
})

const mapProductStateToFilter = (
  filter: ProductsStatisticsFilterInput,
  productStates: ProductState | undefined
): ProductsStatisticsFilterInput => ({
  ...filter,
  productStates: productStates ? [productStates] : undefined
})

const mapSellingChannelToFilter = (
  filter: ProductsStatisticsFilterInput,
  sellingChannel: SellingChannel | undefined
): ProductsStatisticsFilterInput => ({
  ...filter,
  sellingChannel
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateProductState = useTranslateProductState()
  const translateSellingChannel = useTranslateSellingChannel()
  const getProductStateFromSearchObject = (
    filter: ProductsStatisticsFilterInput
  ) =>
    filter.productStates
      ? t('State: {{productState}}', {
          productState: translateProductState(filter.productStates[0])
        })
      : undefined
  const getSellingChannelFromSearchObject = (
    filter: ProductsStatisticsFilterInput
  ) =>
    filter.sellingChannel
      ? t('Selling channel: {{sellingChannel}}', {
          sellingChannel: translateSellingChannel(filter.sellingChannel)
        })
      : undefined
  const getProductNameFromSearchObject = (
    filter: ProductsStatisticsFilterInput
  ) => filter.productName || undefined
  return {
    getProductStateFromSearchObject,
    getSellingChannelFromSearchObject,
    getProductNameFromSearchObject
  }
}

interface IProductsStatisticsSearchProps {
  onFilterChange: (filter: ProductsStatisticsFilterInput) => void
}

export const ProductsStatisticsSearch: React.FC<IProductsStatisticsSearchProps> =
  ({onFilterChange}: IProductsStatisticsSearchProps) => {
    const {t} = useTranslation()
    const {
      getProductStateFromSearchObject,
      getSellingChannelFromSearchObject,
      getProductNameFromSearchObject
    } = useGetFieldFromSearchObject()
    const translateProductState = useTranslateProductState()
    const translateSellingChannel = useTranslateSellingChannel()
    const productStateSelectOptions: ISelectOption<ProductState>[] = [
      ProductState.Active,
      ProductState.Inactive,
      ProductState.Disabled,
      ProductState.Deleted
    ].map((state) => ({id: state, label: translateProductState(state)}))
    const sellingChannelSelectOptions: ISelectOption<SellingChannel>[] = [
      SellingChannel.Retail,
      SellingChannel.ECommerce
    ].map((channel) => ({
      id: channel,
      label: translateSellingChannel(channel)
    }))
    const mapSearchObjectToInputText =
      useCombineStringifySearchObjectFunctions<ProductsStatisticsFilterInput>(
        getProductStateFromSearchObject,
        getSellingChannelFromSearchObject,
        getProductNameFromSearchObject
      )
    return (
      <Search
        storageKey="PRODUCTS_STATISTICS"
        placeholder={t('Search for product')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapProductNameToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={{}}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            <AdvancedSearchSelectRow<PaymentsFilterInput, ProductState>
              label={t('State')}
              value={
                advancedSearchObject.productStates
                  ? advancedSearchObject.productStates[0]
                  : undefined
              }
              options={productStateSelectOptions}
              mapSelectValueToSearchObject={mapProductStateToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchSelectRow<PaymentsFilterInput, SellingChannel>
              label={t('Channel')}
              value={advancedSearchObject.sellingChannel || undefined}
              options={sellingChannelSelectOptions}
              mapSelectValueToSearchObject={mapSellingChannelToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchTextRow<PaymentsFilterInput>
              label={t('Product')}
              placeholder={t('Enter product name')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapProductNameToFilter}
              value={advancedSearchObject.productName || undefined}
            />
          </AdvancedSearchBase>
        )}
      />
    )
  }
