import {Dialog, DialogContent, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  TemplatePropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../../common/DialogTitleWithCloseButton'
import {TemplateItem} from '../components/assignTemplateDialog/TemplateItem'

interface IAssignTemplateDialogProps {
  templateType: TemplateType | null
  templates: TemplatePropertiesFragment[]
  onClose: () => void
  getOnAssignButtonClickHandler: (
    template: TemplatePropertiesFragment
  ) => () => void
}

export const AssignTemplateDialog: React.FC<IAssignTemplateDialogProps> = ({
  templateType,
  templates,
  onClose,
  getOnAssignButtonClickHandler
}: IAssignTemplateDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      open={Boolean(templateType)}
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add template')}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        {templates.length ? (
          templates.map((template) => (
            <TemplateItem
              key={template.id}
              template={template}
              onAssignButtonClick={getOnAssignButtonClickHandler(template)}
            />
          ))
        ) : (
          <Typography>{t('All templates assigned to device')}</Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}
