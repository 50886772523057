import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {useWebsiteParams} from '../../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {isFileImage} from '../../../components/utils'
import {
  useClientFiles,
  useUpdateWebsiteCommonSettings,
  useWebsiteCommonSettings
} from '../../graphql'
import {MetadataForm} from './MetadataForm'
import {IMetadataForm} from './types'
import {
  transformBaseWebsiteCommonSettingsFieldsToMetadataForm,
  transformMetadataFormToUpdateWebsiteCommonSettingsInput
} from './utils'

const UPDATE_WEBSITE_METADATA_FORM_ID = 'updateWebsiteMetadataForm'

interface IUpdateWebsiteMetadataDrawerProps {
  onExited: () => void
}

export const UpdateWebsiteMetadataDrawer: React.FC<IUpdateWebsiteMetadataDrawerProps> =
  ({onExited}: IUpdateWebsiteMetadataDrawerProps) => {
    const {t} = useTranslation()
    const {websiteId} = useWebsiteParams()
    const updateWebsiteCommonSettings = useUpdateWebsiteCommonSettings()
    const {
      data: websiteData,
      loading: websiteLoading,
      error: websiteError
    } = useWebsiteCommonSettings(websiteId, isNaN(websiteId))
    const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
      useMutationAssistanceHooks()
    const {
      data: clientFilesData,
      loading: clientFilesLoading,
      error: clientFilesError
    } = useClientFiles()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const handleSubmit = useCallback(
      async (formData: IMetadataForm) => {
        try {
          setShowBackdrop(true)
          await updateWebsiteCommonSettings({
            websiteId,
            input:
              transformMetadataFormToUpdateWebsiteCommonSettingsInput(formData)
          })
          addInfoNotification(t('Metadata has been updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while updating website common settings')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        defaultErrorHandler,
        setShowBackdrop,
        t,
        updateWebsiteCommonSettings,
        websiteId
      ]
    )
    useEffect(() => {
      if (!isNaN(websiteId)) {
        openDrawer()
      }
    }, [openDrawer, websiteId])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={clientFilesLoading || websiteLoading}
          errorMessage={
            clientFilesError
              ? t('Error while loading client files')
              : websiteError && t('Error while loading website common settings')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update website metadata')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_WEBSITE_METADATA_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {clientFilesData && websiteData && (
            <MetadataForm
              formId={UPDATE_WEBSITE_METADATA_FORM_ID}
              onSubmit={handleSubmit}
              images={clientFilesData.clientFiles.filter(({fileName}) =>
                isFileImage(fileName)
              )}
              defaultValues={transformBaseWebsiteCommonSettingsFieldsToMetadataForm(
                websiteData.websiteCommonSettings
              )}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
