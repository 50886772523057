import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {AuditoriumState} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'

import {
  AuditoriumForm,
  AuditoriumFormField,
  IAuditoriumFormData
} from './AuditoriumForm'
import {useCreateAuditorium} from './graphql'

const CREATE_AUDITORIUM_FORM_ID = 'createAuditoriumForm'

interface ICreateAuditoriumDrawerProps {
  isOpen: boolean
  onClose: () => void
  venueId: number
  refetch: () => void
}

export const CreateAuditoriumDrawer: React.FC<ICreateAuditoriumDrawerProps> = ({
  isOpen,
  onClose,
  venueId,
  refetch
}: ICreateAuditoriumDrawerProps) => {
  const {t} = useTranslation()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const createAuditorium = useCreateAuditorium()
  const onSubmit = useCallback(
    async (data: IAuditoriumFormData) => {
      try {
        setShowBackdrop(true)
        await createAuditorium({
          data: {
            venueId,
            name: data[AuditoriumFormField.Name],
            shortcut: data[AuditoriumFormField.Shortcut],
            state: AuditoriumState.Active,
            defaultDivisionId:
              data[AuditoriumFormField.DefaultDivisionId] || undefined
          }
        })
        addInfoNotification('Auditorium created')
        refetch()
        onClose()
      } catch (e) {
        defaultErrorHandler(e, t('Error while creating auditorium'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createAuditorium,
      defaultErrorHandler,
      onClose,
      refetch,
      setShowBackdrop,
      t,
      venueId
    ]
  )

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create auditorium')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_AUDITORIUM_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <AuditoriumForm
          onSubmit={onSubmit}
          formId={CREATE_AUDITORIUM_FORM_ID}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
