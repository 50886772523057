import {ListItem, ListItemText} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {PRIMARY_100_COLOR, Theme} from '../../../../theme'

const useStyles = makeStyles<Theme, {isActive: boolean}>((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(8),
    color: ({isActive}) =>
      isActive ? theme.palette.primary.main : theme.palette.text.primary,
    background: ({isActive}) => (isActive ? PRIMARY_100_COLOR : 'inherit'),
    '&:hover': {
      background: ({isActive}) =>
        isActive ? PRIMARY_100_COLOR : theme.palette.grey[100]
    }
  },
  listItemRoot: {
    height: 54
  }
}))

interface ISideMenuItemProps {
  isActive: boolean
  label: string
  onClick(): void
}

export const SideMenuItem: React.FC<ISideMenuItemProps> = ({
  isActive,
  label,
  onClick
}: ISideMenuItemProps) => {
  const classes = useStyles({isActive})
  return (
    <ListItem
      className={classes.listItem}
      button
      classes={{
        root: classes.listItemRoot
      }}
      onClick={onClick}
    >
      <ListItemText>{label}</ListItemText>
    </ListItem>
  )
}
