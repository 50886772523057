import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ConfirmationDialog,
  IConfirmationDialogProps
} from '../../../common/ConfirmationDialog'

interface IDeletePaymentMethodConfirmationDialogProps
  extends Pick<IConfirmationDialogProps, 'isOpen' | 'onCancel' | 'onConfirm'> {}

export const DeletePaymentMethodConfirmationDialog: React.FC<IDeletePaymentMethodConfirmationDialogProps> =
  ({
    isOpen,
    onCancel,
    onConfirm
  }: IDeletePaymentMethodConfirmationDialogProps) => {
    const {t} = useTranslation()
    return (
      <ConfirmationDialog
        title={t('Delete payment method')}
        contentText={
          <>
            {t(
              "Deleting this payment method will not affect created transactions, but new usage won't be allowed."
            )}
            <br />
            <br />
            {t(
              "Are you sure you want to delete payment method? This can't be undone."
            )}
          </>
        }
        confirmButtonLabel={t('Delete')}
        isOpen={isOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )
  }
