import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {WebsiteQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useCreateWebsitePage} from '../graphql'
import {IWebsitePageForm, WebsitePageFormField} from '../types'
import {transformWebsitePageDataToWebsitePageInput} from '../utils'
import {useWebsitePageContext} from './WebsitePageContext'
import {WebsitePageForm} from './WebsitePageForm'

const CREATE_PAGE_FORM_ID = 'createPageForm'

interface ICreateWebsitePageDrawerProps {
  isOpen: boolean
  onClose: () => void
  website: WebsiteQuery['website']
}

export const CreateWebsitePageDrawer: React.FC<ICreateWebsitePageDrawerProps> =
  ({isOpen, onClose, website}: ICreateWebsitePageDrawerProps) => {
    const {t} = useTranslation()
    const {refetchWebsitePages, setSelectedWebsitePage} =
      useWebsitePageContext()
    const createWebsitePage = useCreateWebsitePage()
    const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (formData: IWebsitePageForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await createWebsitePage({
            websiteId: website.id,
            localeCode: formData[WebsitePageFormField.LocaleCode],
            input: transformWebsitePageDataToWebsitePageInput(formData)
          })
          refetchWebsitePages()
          if (data) {
            setSelectedWebsitePage(data.createWebsitePage)
          }
          addInfoNotification(t('Website page has been created'))
          onClose()
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        refetchWebsitePages,
        addInfoNotification,
        setSelectedWebsitePage,
        createWebsitePage,
        onClose,
        setShowBackdrop,
        t,
        website.id
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create page')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_PAGE_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <WebsitePageForm
            formId={CREATE_PAGE_FORM_ID}
            onSubmit={handleSubmit}
            primaryDomain={website.primaryDomain}
            enabledLocaleCodes={website.enabledLocaleCodes}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
