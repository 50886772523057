import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import dayjs from 'dayjs'
import {isNil} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  VoucherTransactionIntentsFilterInput,
  VoucherTransactionIntentsQuery,
  VoucherTransactionIntentState
} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {
  useTranslateVoucherIntentReason,
  useTranslateVoucherIntentState
} from '../../../../hooks/translateVoucherTransactionIntent'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useUserNameFormatter,
  VoucherTransactionAmountCellRenderer
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {voucherTransactionIntentStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {useGetFilterDateRange} from '../utils'
import {useVoucherTransactionIntents} from './graphql'
import {
  StateChip,
  StateChipOption,
  transformStateChipOptionToStatesFilter
} from './StateChip'

const getBoldCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''

const StateRenderer = ({state}: {state: VoucherTransactionIntentState}) => {
  const translateVoucherIntentState = useTranslateVoucherIntentState()
  return (
    <EntityStateChip
      colorConf={voucherTransactionIntentStateColors[state]}
      label={translateVoucherIntentState(state)}
    />
  )
}

const IconCellRenderer = ({
  row
}: {
  row: VoucherTransactionIntentsQuery['voucherTransactionIntents']['items'][number]
}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleIconClick = useCallback(() => {
    if (row.refundIntent?.claim?.id && P([PermissionCode.ReadClaim])) {
      history.push(
        routeTo.admin.voucherTransactions.claimDetail(row.refundIntent.claim.id)
      )
    }
    if (row.paymentIntent?.cart?.id && P([PermissionCode.ReadCart])) {
      history.push(
        routeTo.admin.voucherTransactions.cartDetail(row.paymentIntent.cart.id)
      )
    }
    return undefined
  }, [P, history, row.paymentIntent?.cart.id, row.refundIntent?.claim?.id])
  return (P([PermissionCode.ReadCart]) && row.paymentIntent) ||
    (P([PermissionCode.ReadClaim]) && row.refundIntent) ? (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  ) : null
}

interface IVoucherTransactionsListProps {
  searchFilter: VoucherTransactionIntentsFilterInput
}

export const VoucherTransactionsList: React.FC<IVoucherTransactionsListProps> =
  ({searchFilter}: IVoucherTransactionsListProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const [voucherState, setVoucherState] = useState<StateChipOption>(
      StateChipOption.AllTransactions
    )
    const [selectedDate, setSelectedDate] = useState<
      IDataPickerData | undefined
    >({
      group: TabGroup.Now,
      value: TabNow.UntilToday
    })
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
    const getFilterDateRange = useGetFilterDateRange()
    const {paginationInput, getDataGridPaginationProps} =
      useDataGridPagination()
    const {data, loading, error, refetch} = useVoucherTransactionIntents({
      paginationInput,
      filter: {
        ...searchFilter,
        states: transformStateChipOptionToStatesFilter(voucherState),
        createdByUserIds: selectedUserId ? [selectedUserId] : undefined,
        ...getFilterDateRange({
          date: selectedDate,
          filterNameFrom: 'createdAtFrom',
          filterNameTo: 'createdAtTo'
        })
      }
    })
    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
    const dateTimeFormatter = useDateTimeFormatter()
    const translateVoucherIntentReason = useTranslateVoucherIntentReason()
    const userNameFormatter = useUserNameFormatter(false, true)
    const handleDateSelect = useCallback(
      (date?: IDataPickerData) => setSelectedDate(date),
      []
    )
    const handleReloadButtonClick = useCallback(() => refetch(), [refetch])
    const columns: GridColDef[] = useMemo(
      () => [
        {
          headerName: t('Created at'),
          field: 'createdAt',
          valueFormatter: (params) =>
            dayjs(params.value).isValid()
              ? dateTimeFormatter(params)
              : params.value,
          minWidth: 300,
          sortable: false,
          cellClassName: getBoldCellClassName
        },
        {
          headerName: t('Voucher'),
          field: 'voucher',
          valueGetter: (params) => params.value?.code,
          minWidth: 200,
          sortable: false
        },
        {
          headerName: t('Amount'),
          field: 'amount',
          align: 'right',
          headerAlign: 'right',
          minWidth: 150,
          renderCell: function renderer(params: GridRenderCellParams) {
            return params.rowNode.type === 'pinnedRow' ? (
              translateEffectiveClientPrice(params.value)
            ) : (
              <VoucherTransactionAmountCellRenderer
                amount={params.value}
                type={params.row.type}
              />
            )
          },
          sortable: false,
          cellClassName: getBoldCellClassName
        },
        {
          headerName: t('State'),
          field: 'state',
          renderCell: function renderer(
            params: GridRenderCellParams<{value: VoucherTransactionIntentState}>
          ) {
            return params.rowNode.type === 'pinnedRow' ? null : (
              <StateRenderer state={params.value} />
            )
          },
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('Reason'),
          field: 'reason',
          minWidth: 200,
          valueFormatter: (params) =>
            translateVoucherIntentReason(params.value),
          sortable: false
        },
        {
          headerName: t('Cashier'),
          field: 'createdBy',
          minWidth: 300,
          valueFormatter: userNameFormatter,
          sortable: false
        },
        {
          headerName: t('Note'),
          field: 'note',
          minWidth: 300,
          sortable: false
        },
        {
          headerName: '',
          field: 'icon',
          renderCell: function renderer(params: GridRenderCellParams) {
            return <IconCellRenderer row={params.row} />
          },
          sortable: false,
          align: 'center',
          headerAlign: 'center',
          disableColumnMenu: true,
          width: 48
        }
      ],
      [
        dateTimeFormatter,
        t,
        translateEffectiveClientPrice,
        translateVoucherIntentReason,
        userNameFormatter
      ]
    )
    if (error) {
      return (
        <Error
          error={error}
          message={t('Error while loading voucher transaction intents')}
        />
      )
    }
    return (
      <Box
        sx={{
          height: '100%'
        }}
      >
        <SubHeaderToolbar
          title={t('Overview')}
          leftActions={[
            <StateChip
              key="voucher-state-chip"
              state={voucherState}
              setState={setVoucherState}
            />,
            P([PermissionCode.ReadUsers]) ? (
              <UserChip key="user-chip" onChange={setSelectedUserId} />
            ) : (
              []
            )
          ].filter(Boolean)}
          rightActions={[
            <DatePickerButton
              key="date-picker"
              onDateSelect={handleDateSelect}
              selectedValues={selectedDate}
              defaultValues={{
                group: TabGroup.Now,
                value: TabNow.UntilToday
              }}
              groups={[TabGroup.Now, TabGroup.Past]}
              excludedValues={[TabNow.FromToday, TabNow.FromNow]}
              minDateFrom={
                selectedDate?.dateRange?.dateTo
                  ? dayjs(selectedDate?.dateRange?.dateTo)
                      .subtract(1, 'year')
                      .startOf('d')
                  : dayjs().subtract(1, 'year').startOf('d')
              }
              maxDateFrom={
                selectedDate?.dateRange?.dateTo || dayjs().endOf('d')
              }
              maxDateTo={dayjs().endOf('d')}
              tooltip={t('Transaction date')}
            />,
            <ReloadButton
              key="reload"
              onReloadButtonClick={handleReloadButtonClick}
            />
          ]}
        />
        <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
          <DataGridTable
            sx={{'& .bold': {fontWeight: 'bold'}}}
            columns={columns}
            loading={loading}
            rows={data?.voucherTransactionIntents.items || []}
            disableColumnMenu
            disableRowSelectionOnClick
            initialState={{
              pinnedColumns: {left: ['createdAt'], right: ['icon']}
            }}
            columnVisibilityModel={{
              icon:
                P([PermissionCode.ReadCart]) || P([PermissionCode.ReadClaim])
            }}
            localeText={{noRowsLabel: t('No voucher transactions to show')}}
            pinnedRows={{
              bottom: [
                {
                  id: '0',
                  createdAt: t('Total'),
                  amount: data?.voucherTransactionIntents.totals.amount
                }
              ]
            }}
            {...getDataGridPaginationProps(
              data?.voucherTransactionIntents.pagination
            )}
          />
        </Box>
      </Box>
    )
  }
