import PersonIcon from '@mui/icons-material/Person'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import {Box, IconButton, Paper, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../__generated__/schema'

import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {CancelButton} from '../../../../common/Buttons'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(1, 2)
  },
  titleRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: theme.spacing(1.5)
  }
}))

interface ILeadDetailLayoutProps {
  children: React.ReactNode
  onMoreButtonClick?: () => void
  onPersonButtonClick?: () => void
  sx?: SxProps<Theme>
}

export const LeadDetailLayout: React.FC<ILeadDetailLayoutProps> = ({
  children,
  onMoreButtonClick,
  onPersonButtonClick,
  sx
}: ILeadDetailLayoutProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  return (
    <Paper className={classes.root} sx={sx} variant="outlined">
      <div className={classes.titleRow}>
        <PersonIcon />
        <Typography variant="subtitle1">{t('Lead')}</Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          {onPersonButtonClick && P([PermissionCode.ReadCustomers]) && (
            <IconButton color="primary" onClick={onPersonButtonClick}>
              <PersonSearchIcon />
            </IconButton>
          )}
          {onMoreButtonClick && (
            <CancelButton onClick={onMoreButtonClick}>{t('More')}</CancelButton>
          )}
        </Box>
      </div>
      {children}
    </Paper>
  )
}
