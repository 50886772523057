import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import TodayIcon from '@mui/icons-material/Today'
import {
  Card,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {EventState, PermissionCode} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useBackdropState} from '../../../../context/backdrop'
import {
  CopyInfo,
  DayHeaderClickInfo,
  SetDayHeaderContentState
} from '../context/dayHeaderContentState'
import {
  ResourceLabelClickInfo,
  SetResourceLabelContentState
} from '../context/resourceLabelContentState'
import {IEvent, useCopyEvents} from '../graphql'
import {DropdownsConfirmationDialogs} from './DropdownsConfirmationDialogs'
import {ClickInfoActivity} from './types'

const getEventsOnDateFilter = (date: string) => (event: IEvent) =>
  dayjs(date).get('date') === dayjs(event.start).get('date')

const getEventsInActivePeriodFilter =
  (clickInfo: ResourceLabelClickInfo) => (event: IEvent) => {
    const eventStartTimestamp = dayjs(event.start).toDate().getTime()
    const activeStartTimestamp = new Date(clickInfo.activeStart).getTime()
    const activeEndTimestamp = new Date(clickInfo.activeEnd).getTime()
    return (
      activeStartTimestamp < eventStartTimestamp &&
      activeEndTimestamp > eventStartTimestamp
    )
  }

const getEventId = (event: IEvent) => event.id

const useStyles = makeStyles<Theme, {top: number; left: number}>(() => ({
  popover: {
    position: 'absolute',
    minWidth: 280,
    zIndex: 2,
    top: ({top}) => top,
    left: ({left}) => left
  },
  unScrollablePopoverBackground: {
    position: 'fixed',
    zIndex: 2,
    width: '100%',
    height: '100%',
    top: 0,
    overflow: 'hidden'
  }
}))

interface IDayHeaderDropdownProps {
  eventsInAuditorium: IEvent[]
  clickInfo: DayHeaderClickInfo
  copyInfo: CopyInfo | null
  setDayHeaderContentState: SetDayHeaderContentState

  onDayPreviewClick(date: Date): void
}

const useFilterEventIds = (eventsInAuditorium: IEvent[]) => ({
  draftEventIds: eventsInAuditorium
    .filter(({state}) => state === EventState.Draft)
    .map(getEventId),
  draftAndCanceledEventIds: eventsInAuditorium
    .filter(
      ({state}) => [EventState.Draft, EventState.Canceled].indexOf(state) > -1
    )
    .map(getEventId),
  publishedEventIds: eventsInAuditorium
    .filter(({state}) => state === EventState.Published)
    .map(getEventId)
})

export const DayHeaderDropdown: React.FC<IDayHeaderDropdownProps> = ({
  eventsInAuditorium,
  clickInfo,
  copyInfo,
  setDayHeaderContentState,
  onDayPreviewClick
}: IDayHeaderDropdownProps) => {
  const {x, y} = clickInfo
  const classes = useStyles({top: y + 15, left: x})
  const {t} = useTranslation()
  const {formatDate} = useDateTimeFormatters()
  const {setShowBackdrop} = useBackdropState()
  const copyEvents = useCopyEvents()
  const defaultErrorHandler = useDefaultErrorHandler()

  const events = eventsInAuditorium.filter(
    getEventsOnDateFilter(clickInfo.date)
  )

  const {draftEventIds, draftAndCanceledEventIds, publishedEventIds} =
    useFilterEventIds(events)

  const closeDialogAndPopup = useCallback(() => {
    setDayHeaderContentState((previousState) => ({
      ...previousState,
      clickInfo: null
    }))
  }, [setDayHeaderContentState])

  const handleCopyButtonClick = useCallback(() => {
    const {auditoriumId, date} = clickInfo
    const eventsIdsToCopy = events.map(getEventId)

    setDayHeaderContentState({
      clickInfo: null,
      copyInfo: {
        auditoriumId,
        date,
        ids: eventsIdsToCopy
      }
    })
  }, [clickInfo, events, setDayHeaderContentState])

  const getActivityClickHandler = useCallback(
    (activity: ClickInfoActivity) => () => {
      setDayHeaderContentState((dayHeaderContentState) => ({
        ...dayHeaderContentState,
        clickInfo: {
          ...clickInfo,
          activity
        }
      }))
    },
    [clickInfo, setDayHeaderContentState]
  )

  const handlePasteButtonClick = useCallback(async () => {
    if (copyInfo == null) {
      return
    }

    setShowBackdrop(true)
    const {auditoriumId: copyAuditoriumId, date: copyDate, ids} = copyInfo
    const {date: pasteDate} = clickInfo

    const daysDiff = dayjs(pasteDate).diff(copyDate, 'day')

    try {
      await copyEvents(copyAuditoriumId, ids, daysDiff)
    } catch (e) {
      defaultErrorHandler(e, t('Error while copying events.'))
    } finally {
      setShowBackdrop(false)
      closeDialogAndPopup()
    }
  }, [
    clickInfo,
    closeDialogAndPopup,
    copyEvents,
    copyInfo,
    defaultErrorHandler,
    setShowBackdrop,
    t
  ])

  const handleDayPreviewClick = useCallback(() => {
    onDayPreviewClick(dayjs(clickInfo.date).toDate())
    closeDialogAndPopup()
  }, [clickInfo.date, closeDialogAndPopup, onDayPreviewClick])
  const {P} = useEnsurePermissions()
  return (
    <>
      <div className={classes.unScrollablePopoverBackground} />
      {!clickInfo.activity && (
        <ClickAwayListener disableReactTree onClickAway={closeDialogAndPopup}>
          <Card className={classes.popover}>
            <List component="nav">
              <ListItem button onClick={handleDayPreviewClick}>
                <ListItemIcon>
                  <TodayIcon />
                </ListItemIcon>
                <ListItemText primary={t('Show in day view')} />
              </ListItem>
            </List>
            <Divider />
            {P([PermissionCode.CopyEvents]) && (
              <List component="nav">
                <ListItem
                  button
                  disabled={
                    !copyInfo ||
                    copyInfo.auditoriumId !== clickInfo.auditoriumId
                  }
                  onClick={handlePasteButtonClick}
                >
                  <ListItemIcon />
                  <ListItemText
                    primary={t('Paste from {{date}}', {
                      date: copyInfo ? formatDate(new Date(copyInfo.date)) : ''
                    })}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={handleCopyButtonClick}
                  disabled={events.length === 0}
                >
                  <ListItemIcon>
                    <FileCopyOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Copy to clipboard')} />
                </ListItem>
              </List>
            )}

            <Divider />
            <List component="nav">
              {P([PermissionCode.PublishEvents]) && (
                <ListItem
                  button
                  disabled={draftAndCanceledEventIds.length === 0}
                  onClick={getActivityClickHandler(ClickInfoActivity.PUBLISH)}
                >
                  <ListItemIcon />
                  <ListItemText primary={t('Publish events')} />
                </ListItem>
              )}
              {P([PermissionCode.CancelEvents]) && (
                <ListItem
                  button
                  disabled={publishedEventIds.length === 0}
                  onClick={getActivityClickHandler(ClickInfoActivity.CANCEL)}
                >
                  <ListItemIcon />
                  <ListItemText primary={t('Cancel published events')} />
                </ListItem>
              )}
              {P([PermissionCode.DeleteEvents]) && (
                <ListItem
                  button
                  onClick={getActivityClickHandler(ClickInfoActivity.DELETE)}
                  disabled={draftEventIds.length === 0}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Delete draft events')} />
                </ListItem>
              )}
            </List>
          </Card>
        </ClickAwayListener>
      )}
      <DropdownsConfirmationDialogs
        closeDialogAndPopup={closeDialogAndPopup}
        draftEventIds={draftEventIds}
        draftAndCanceledEventIds={draftAndCanceledEventIds}
        publishedEventIds={publishedEventIds}
        activity={clickInfo.activity}
      />
    </>
  )
}

interface IResourceLabelDropdownProps {
  eventsInAuditorium: IEvent[]
  clickInfo: ResourceLabelClickInfo
  setResourceLabelContentState: SetResourceLabelContentState
}

export const ResourceLabelDropdown: React.FC<IResourceLabelDropdownProps> = ({
  eventsInAuditorium,
  clickInfo,
  setResourceLabelContentState
}: IResourceLabelDropdownProps) => {
  const {x, y} = clickInfo
  const classes = useStyles({top: y + 15, left: x})
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const events = eventsInAuditorium.filter(
    getEventsInActivePeriodFilter(clickInfo)
  )

  const {draftEventIds, draftAndCanceledEventIds, publishedEventIds} =
    useFilterEventIds(events)

  const closeDialogAndPopup = useCallback(() => {
    setResourceLabelContentState(null)
  }, [setResourceLabelContentState])

  const getActivityClickHandler = useCallback(
    (activity: ClickInfoActivity) => () => {
      setResourceLabelContentState({
        ...clickInfo,
        activity
      })
    },
    [clickInfo, setResourceLabelContentState]
  )

  return (
    <>
      <div className={classes.unScrollablePopoverBackground} />
      {!clickInfo.activity && (
        <ClickAwayListener onClickAway={closeDialogAndPopup}>
          <Card className={classes.popover} style={{top: y + 15, left: x}}>
            <List component="nav">
              {P([PermissionCode.PublishEvents]) && (
                <ListItem
                  button
                  disabled={draftAndCanceledEventIds.length === 0}
                  onClick={getActivityClickHandler(ClickInfoActivity.PUBLISH)}
                >
                  <ListItemIcon />
                  <ListItemText primary={t('Publish events')} />
                </ListItem>
              )}
              {P([PermissionCode.CancelEvents]) && (
                <ListItem
                  button
                  disabled={publishedEventIds.length === 0}
                  onClick={getActivityClickHandler(ClickInfoActivity.CANCEL)}
                >
                  <ListItemIcon />
                  <ListItemText primary={t('Cancel published events')} />
                </ListItem>
              )}
              {P([PermissionCode.DeleteEvents]) && (
                <ListItem
                  button
                  onClick={getActivityClickHandler(ClickInfoActivity.DELETE)}
                  disabled={draftEventIds.length === 0}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Delete draft events')} />
                </ListItem>
              )}
            </List>
          </Card>
        </ClickAwayListener>
      )}
      <DropdownsConfirmationDialogs
        closeDialogAndPopup={closeDialogAndPopup}
        draftEventIds={draftEventIds}
        draftAndCanceledEventIds={draftAndCanceledEventIds}
        publishedEventIds={publishedEventIds}
        activity={clickInfo.activity}
      />
    </>
  )
}
