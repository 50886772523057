import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {IButtonGroupForm} from '../editBlockDrawer/types'
import {ButtonGroupForm} from './ButtonGroupForm'

const ADD_BUTTON_FORM_ID = 'addButtonForm'

interface IAddButtonGroupDialogProps {
  isOpen: boolean
  onClose: () => void
  onAdd: (data: IButtonGroupForm) => void
}

export const AddButtonGroupDialog: React.FC<IAddButtonGroupDialogProps> = ({
  isOpen,
  onClose,
  onAdd
}: IAddButtonGroupDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add button')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <ButtonGroupForm formId={ADD_BUTTON_FORM_ID} onSubmit={onAdd} />
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={ADD_BUTTON_FORM_ID}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
