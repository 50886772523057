import {Box, Typography} from '@mui/material'
import {styled} from '@mui/system'
import omit from 'lodash/omit'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightCostCentersQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightMarketingLabelsQuery,
  LightweightUsersQuery,
  LightweightVenuesQuery,
  LocaleCode,
  NarrowAdmissionRateFieldsFragment,
  ShowTypeCode,
  ShowVersionCode,
  TourTimeSlotState,
  UserState
} from '../../../../../../__generated__/schema'
import {useTranslateShowVersion} from '../../../../../../hooks/translateDistributions'
import {useTranslateLocaleCode} from '../../../../../../hooks/translateLocales'
import {useTranslateTourTimeSlotState} from '../../../../../../hooks/translateTourTimeSlotState'
import {
  useIsNonNegativeInteger,
  useIsStringWithMaxLength
} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {CollapseSection} from '../../../../../common/CollapseSection'
import {ColorBox} from '../../../../../common/ColorBox'
import {FormAutocomplete} from '../../../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {FormFieldName} from '../../../../../form/types'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {useAgeClassificationSelectFields} from '../../../shows/getSelectFields'
import {
  IUpdateTourTimeSlotsForm,
  UpdateTourTimeSlotsBooleanOption,
  UpdateTourTimeSlotsFormField,
  UpdateTourTimeSlotsStaticOption
} from '../../types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

const useTranslateUpdateTourTimeSlotsStaticOptions = () => {
  const {t} = useTranslation()
  const translated: Record<UpdateTourTimeSlotsStaticOption, string> = {
    [UpdateTourTimeSlotsStaticOption.DoNotChange]: t<string>('Do not change'),
    [UpdateTourTimeSlotsStaticOption.ClearValues]: t<string>('Clear values'),
    [UpdateTourTimeSlotsStaticOption.NewValue]: t<string>('New value')
  }
  return (option: UpdateTourTimeSlotsStaticOption) => translated[option]
}

const useTranslateUpdateTourTimeSlotsBooleanOptions = () => {
  const {t} = useTranslation()
  const translated: Record<UpdateTourTimeSlotsBooleanOption, string> = {
    [UpdateTourTimeSlotsBooleanOption.DoNotChange]: t<string>('Do not change'),
    [UpdateTourTimeSlotsBooleanOption.Enable]: t<string>('Enable'),
    [UpdateTourTimeSlotsBooleanOption.Disable]: t<string>('Disable')
  }
  return (option: UpdateTourTimeSlotsBooleanOption) => translated[option]
}

const useGetSelectStaticOptions = () => {
  const translateUpdateTourTimeSlotsStaticOptions =
    useTranslateUpdateTourTimeSlotsStaticOptions()
  return Object.values(UpdateTourTimeSlotsStaticOption).reduce(
    (acc, option) => ({
      ...acc,
      [option]: translateUpdateTourTimeSlotsStaticOptions(option)
    }),
    {}
  )
}

const useGetSelectBooleanOptions = () => {
  const translateUpdateTourTimeSlotsBooleanOptions =
    useTranslateUpdateTourTimeSlotsBooleanOptions()
  return Object.values(UpdateTourTimeSlotsBooleanOption).reduce(
    (acc, option) => ({
      ...acc,
      [option]: translateUpdateTourTimeSlotsBooleanOptions(option)
    }),
    {}
  )
}

interface IUpdateSlotsFormProps {
  formId: string
  onSubmit: (data: IUpdateTourTimeSlotsForm) => void
  defaultValues?: Partial<IUpdateTourTimeSlotsForm>
  admissionRates: NarrowAdmissionRateFieldsFragment[]
  users: LightweightUsersQuery['users']
  costCenters: GetLightweightCostCentersQuery['costCenters']
  marketingLabels: GetLightweightMarketingLabelsQuery['marketingLabels']
  eventCategories: GetLightweightEventCategoriesQuery['eventCategories']
  venues: LightweightVenuesQuery['venues']
  showTypeCode: ShowTypeCode
  clientLocales: LocaleCode[]
}

export const UpdateSlotsForm: React.FC<IUpdateSlotsFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  admissionRates,
  users,
  costCenters,
  marketingLabels,
  eventCategories,
  venues,
  showTypeCode,
  clientLocales
}: IUpdateSlotsFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IUpdateTourTimeSlotsForm>({defaultValues})
  const translateTourTimeSlotState = useTranslateTourTimeSlotState()
  const selectStaticOptions = useGetSelectStaticOptions()
  const selectBooleanOptions = useGetSelectBooleanOptions()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isNonNegativeInteger = useIsNonNegativeInteger()
  const translateShowVersion = useTranslateShowVersion()
  const translateUpdateTourTimeSlotsStaticOptions =
    useTranslateUpdateTourTimeSlotsStaticOptions()
  const translateLocaleCode = useTranslateLocaleCode()
  const ageClassificationSelectFields =
    useAgeClassificationSelectFields(showTypeCode)
  const ticketNoteOption = watch(UpdateTourTimeSlotsFormField.TicketNoteOption)
  const retailAttendeesLimitOption = watch(
    UpdateTourTimeSlotsFormField.RetailAttendeesLimitOption
  )
  const eCommerceAttendeesLimitOption = watch(
    UpdateTourTimeSlotsFormField.ECommerceAttendeesLimitOption
  )
  const eCommerceOrderAttendeesLimitOption = watch(
    UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimitOption
  )
  const namesOption = watch(UpdateTourTimeSlotsFormField.NamesOption)
  return (
    <StyledForm id={formId} onSubmit={handleSubmit(onSubmit)}>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Admission rate')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.AdmissionRateId}
            key={UpdateTourTimeSlotsFormField.AdmissionRateId}
            fullWidth
            selectOptions={{
              [UpdateTourTimeSlotsStaticOption.DoNotChange]: t('Do not change'),
              ...admissionRates.reduce(
                (acc, rate) => ({
                  ...acc,
                  [rate.id]: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      {rate.name}
                      <ColorBox hexColor={rate.color} />
                    </Box>
                  )
                }),
                {}
              )
            }}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('State')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.State}
            key={UpdateTourTimeSlotsFormField.State}
            fullWidth
            selectOptions={{
              [UpdateTourTimeSlotsStaticOption.DoNotChange]: t('Do not change'),
              ...[
                TourTimeSlotState.Published,
                TourTimeSlotState.Cancelled
              ].reduce(
                (acc, state) => ({
                  ...acc,
                  [state]: translateTourTimeSlotState(state)
                }),
                {}
              )
            }}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Ticket note')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.TicketNoteOption}
            key={UpdateTourTimeSlotsFormField.TicketNoteOption}
            fullWidth
            selectOptions={selectStaticOptions}
          />
        ]}
      />
      {ticketNoteOption === UpdateTourTimeSlotsStaticOption.NewValue && (
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUpdateTourTimeSlotsForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={UpdateTourTimeSlotsFormField.TicketNote}
              key={UpdateTourTimeSlotsFormField.TicketNote}
              fullWidth
              required
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
            />
          ]}
        />
      )}
      <Typography variant="subtitle1">{t('Retail settings')}</Typography>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Retail sales')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.RetailSaleOption}
            key={UpdateTourTimeSlotsFormField.RetailSaleOption}
            fullWidth
            selectOptions={selectBooleanOptions}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Retail reservations')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.RetailReservationOption}
            key={UpdateTourTimeSlotsFormField.RetailReservationOption}
            fullWidth
            selectOptions={selectBooleanOptions}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Retail attendees limit')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.RetailAttendeesLimitOption}
            key={UpdateTourTimeSlotsFormField.RetailAttendeesLimitOption}
            fullWidth
            selectOptions={selectStaticOptions}
          />
        ]}
      />
      {retailAttendeesLimitOption ===
        UpdateTourTimeSlotsStaticOption.NewValue && (
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUpdateTourTimeSlotsForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={UpdateTourTimeSlotsFormField.RetailAttendeesLimit}
              key={UpdateTourTimeSlotsFormField.RetailAttendeesLimit}
              fullWidth
              required
              validationOptions={{
                required: true,
                validate: isNonNegativeInteger
              }}
            />
          ]}
        />
      )}
      <Typography variant="subtitle1">{t('Ecommerce settings')}</Typography>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Ecommerce sales')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.ECommerceSaleOption}
            key={UpdateTourTimeSlotsFormField.ECommerceSaleOption}
            fullWidth
            selectOptions={selectBooleanOptions}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Ecommerce reservations')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.ECommerceReservationOption}
            key={UpdateTourTimeSlotsFormField.ECommerceReservationOption}
            fullWidth
            selectOptions={selectBooleanOptions}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Show on website')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.ShowOnWebsiteAndApi}
            key={UpdateTourTimeSlotsFormField.ShowOnWebsiteAndApi}
            fullWidth
            selectOptions={selectBooleanOptions}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Ecommerce attendees limit')}
            setValue={setValue}
            name={UpdateTourTimeSlotsFormField.ECommerceAttendeesLimitOption}
            key={UpdateTourTimeSlotsFormField.ECommerceAttendeesLimitOption}
            fullWidth
            selectOptions={selectStaticOptions}
          />
        ]}
      />
      {eCommerceAttendeesLimitOption ===
        UpdateTourTimeSlotsStaticOption.NewValue && (
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUpdateTourTimeSlotsForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={UpdateTourTimeSlotsFormField.ECommerceAttendeesLimit}
              key={UpdateTourTimeSlotsFormField.ECommerceAttendeesLimit}
              fullWidth
              required
              validationOptions={{
                required: true,
                validate: isNonNegativeInteger
              }}
            />
          ]}
        />
      )}
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Ecommerce order limit')}
            setValue={setValue}
            name={
              UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimitOption
            }
            key={UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimit}
            fullWidth
            selectOptions={omit(
              selectStaticOptions,
              UpdateTourTimeSlotsStaticOption.ClearValues
            )}
          />
        ]}
      />
      {eCommerceOrderAttendeesLimitOption ===
        UpdateTourTimeSlotsStaticOption.NewValue && (
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUpdateTourTimeSlotsForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimit}
              key={UpdateTourTimeSlotsFormField.ECommerceOrderAttendeesLimit}
              fullWidth
              required
              validationOptions={{
                required: true,
                validate: isNonNegativeInteger
              }}
            />
          ]}
        />
      )}
      <Typography variant="subtitle1">{t('Additional settings')}</Typography>
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No version found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Version')}
            name={UpdateTourTimeSlotsFormField.VersionCode}
            key={UpdateTourTimeSlotsFormField.VersionCode}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.DoNotChange
                )
              },
              {
                value: UpdateTourTimeSlotsStaticOption.ClearValues,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.ClearValues
                )
              },
              ...Object.values(ShowVersionCode).map((code) => ({
                value: code,
                name: translateShowVersion(code)
              }))
            ]}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No guide found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Guide')}
            name={UpdateTourTimeSlotsFormField.GuideId}
            key={UpdateTourTimeSlotsFormField.GuideId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.DoNotChange
                )
              },
              {
                value: UpdateTourTimeSlotsStaticOption.ClearValues,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.ClearValues
                )
              },
              ...users
                .filter(({state}) => state === UserState.Active)
                .sort(
                  (nameA, nameB) =>
                    nameA.lastName.localeCompare(nameB.lastName) ||
                    nameA.firstName.localeCompare(nameB.firstName)
                )
                .map(({id, firstName, lastName}) => ({
                  value: String(id),
                  name: [lastName, firstName].join(' ')
                }))
            ]}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No cost center found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Cost center')}
            name={UpdateTourTimeSlotsFormField.CostCenterId}
            key={UpdateTourTimeSlotsFormField.CostCenterId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.DoNotChange
                )
              },
              {
                value: UpdateTourTimeSlotsStaticOption.ClearValues,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.ClearValues
                )
              },
              ...costCenters
                .filter(({isActive}) => isActive)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))
            ]}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No marketing label found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Marketing label')}
            name={UpdateTourTimeSlotsFormField.MarketingLabelId}
            key={UpdateTourTimeSlotsFormField.MarketingLabelId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.DoNotChange
                )
              },
              {
                value: UpdateTourTimeSlotsStaticOption.ClearValues,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.ClearValues
                )
              },
              ...marketingLabels
                .filter(({isActive}) => isActive)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))
            ]}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No event category found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Event category')}
            name={UpdateTourTimeSlotsFormField.EventCategoryId}
            key={UpdateTourTimeSlotsFormField.EventCategoryId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.DoNotChange
                )
              },
              {
                value: UpdateTourTimeSlotsStaticOption.ClearValues,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.ClearValues
                )
              },
              ...eventCategories
                .filter(({isActive}) => isActive)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))
            ]}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No age restriction found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Age restrictions')}
            name={UpdateTourTimeSlotsFormField.AgeClassificationCode}
            key={UpdateTourTimeSlotsFormField.AgeClassificationCode}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.DoNotChange
                )
              },
              {
                value: UpdateTourTimeSlotsStaticOption.ClearValues,
                name: translateUpdateTourTimeSlotsStaticOptions(
                  UpdateTourTimeSlotsStaticOption.ClearValues
                )
              },
              ...Object.entries(ageClassificationSelectFields).map(
                ([value, name]) => ({value, name: name as string})
              )
            ]}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<IUpdateTourTimeSlotsForm>
            noOptionsText={t('No venue found')}
            disableClearable
            fullWidth
            errors={errors}
            label={t('Venue')}
            name={UpdateTourTimeSlotsFormField.VenueId}
            key={UpdateTourTimeSlotsFormField.VenueId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={[
              {
                value: UpdateTourTimeSlotsStaticOption.DoNotChange,
                name: t('Do not change')
              },
              ...venues
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))
            ]}
          />
        ]}
      />
      <CollapseSection title={t('Names')} typographyVariant="subtitle1">
        {clientLocales.map((locale) => (
          <React.Fragment key={locale}>
            <InputRow
              nodes={[
                <UncontrolledFormSelect<IUpdateTourTimeSlotsForm>
                  errors={errors}
                  watch={watch}
                  register={register}
                  label={translateLocaleCode(locale)}
                  setValue={setValue}
                  name={
                    `${UpdateTourTimeSlotsFormField.NamesOption}[${locale}]` as FormFieldName<IUpdateTourTimeSlotsForm>
                  }
                  key={`${UpdateTourTimeSlotsFormField.NamesOption}[${locale}]`}
                  fullWidth
                  selectOptions={omit(
                    selectStaticOptions,
                    UpdateTourTimeSlotsStaticOption.ClearValues
                  )}
                />
              ]}
            />
            {namesOption[locale] ===
              UpdateTourTimeSlotsStaticOption.NewValue && (
              <InputRow
                nodes={[
                  <UncontrolledFormTextInput<IUpdateTourTimeSlotsForm>
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    triggerValidation={triggerValidation}
                    name={
                      `${UpdateTourTimeSlotsFormField.Names}[${locale}]` as FormFieldName<IUpdateTourTimeSlotsForm>
                    }
                    key={`${UpdateTourTimeSlotsFormField.Names}[${locale}]`}
                    fullWidth
                    required
                    validationOptions={{
                      required: true,
                      validate: isStringWithMaxLength
                    }}
                  />
                ]}
              />
            )}
          </React.Fragment>
        ))}
      </CollapseSection>
    </StyledForm>
  )
}
