import {Box} from '@mui/material'
import 'cropperjs/dist/cropper.css'
import React from 'react'
import {Cropper, ReactCropperElement, ReactCropperProps} from 'react-cropper'
import './styles.css'
import {Toolbar} from './Toolbar'

interface IImageCropperProps
  extends Pick<ReactCropperProps, 'aspectRatio' | 'viewMode'> {
  cropperRef: React.MutableRefObject<ReactCropperElement | null>
  imageSrc: string
}

export const ImageCropper: React.FC<IImageCropperProps> = ({
  imageSrc,
  cropperRef,
  ...props
}: IImageCropperProps) => {
  return (
    <Box sx={{width: '100%', height: '100%', position: 'relative'}}>
      <Cropper
        ref={cropperRef}
        src={imageSrc}
        style={{width: '100%', height: '100%'}}
        dragMode="move"
        responsive
        checkOrientation={false}
        viewMode={1}
        toggleDragModeOnDblclick={false}
        {...props}
      />
      <Toolbar cropperRef={cropperRef} />
    </Box>
  )
}
