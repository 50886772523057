import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypesStatisticsFilterInput,
  BusinessPartnerCategory,
  BusinessPartnerState,
  GetLightweightCostCentersQuery,
  GetLightweightDivisionsQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightMarketingLabelsQuery,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  LightweightVenuesQuery,
  LightweightVenuesQueryVariables,
  PermissionCode,
  ShowsStatisticsFilterInput
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useLocale} from '../../../context/locale'
import {
  LIGHTWEIGHT_BUSINESS_PARTNERS,
  LIGHTWEIGHT_VENUES,
  useGetLightweightClientShows,
  useGetLightweightCostCenters,
  useGetLightweightDivisions,
  useGetLightweightEventCategories,
  useGetLightweightMarketingLabels
} from '../graphql'

export const DEFAULT_SHOWS_STATISTICS_FILTER_INPUT: ShowsStatisticsFilterInput =
  {
    hasText: undefined
  }

const mapHasTextToFilter = (
  filter: ShowsStatisticsFilterInput,
  hasText?: string
): ShowsStatisticsFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapDivisionIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  divisionId: number | undefined
): ShowsStatisticsFilterInput => ({
  ...filter,
  divisionIds: divisionId ? [divisionId] : undefined
})

const mapVenueIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  venueId: number | undefined
): ShowsStatisticsFilterInput => ({
  ...filter,
  venueIds: venueId ? [venueId] : undefined
})

const mapCostCenterIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  costCenterId: number | undefined
): ShowsStatisticsFilterInput => ({
  ...filter,
  costCenterIds: costCenterId ? [costCenterId] : undefined
})

const mapShowIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  showId: number | undefined
): ShowsStatisticsFilterInput => ({
  ...filter,
  showIds: showId ? [showId] : undefined
})

const mapEventCategoryIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  eventCategoryId: number | undefined
): ShowsStatisticsFilterInput => ({
  ...filter,
  eventCategoryIds: eventCategoryId ? [eventCategoryId] : undefined
})

const mapMarketingLabelIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  marketingLabelId: number | undefined
): ShowsStatisticsFilterInput => ({
  ...filter,
  marketingLabelIds: marketingLabelId ? [marketingLabelId] : undefined
})

const mapBusinessPartnerIdToFilter = (
  filter: ShowsStatisticsFilterInput,
  businessPartnerId?: number
): ShowsStatisticsFilterInput => ({
  ...filter,
  businessPartnerIds: businessPartnerId ? [businessPartnerId] : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getHasTextFromSearchObject = (filter: ShowsStatisticsFilterInput) =>
    filter.hasText || undefined
  const getDivisionFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionIds && filter.divisionIds.length) {
      const filterDivisionId = filter.divisionIds[0]
      const division = divisions.find(
        (division) => division.id === filterDivisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filterDivisionId})
    }
    return undefined
  }
  const getVenueFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    venues: LightweightVenuesQuery['venues']
  ) => {
    if (filter.venueIds && filter.venueIds.length) {
      const filterVenueId = filter.venueIds[0]
      const venue = venues.find((venue) => venue.id === filterVenueId)
      return venue
        ? t('Venue: {{name}}', {name: venue.name})
        : t('Venue ID: {{id}}', {id: filterVenueId})
    }
    return undefined
  }
  const getCostCenterFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    costCenters: GetLightweightCostCentersQuery['costCenters']
  ) => {
    if (filter.costCenterIds && filter.costCenterIds.length) {
      const filterCostCenterId = filter.costCenterIds[0]
      const costCenter = costCenters.find(
        (costCenter) => costCenter.id === filterCostCenterId
      )
      return costCenter
        ? t('Cost center: {{name}}', {name: costCenter.name})
        : t('Cost center ID: {{id}}', {id: filterCostCenterId})
    }
    return undefined
  }
  const getShowFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    shows: {id: number; title: string}[]
  ) => {
    if (filter.showIds && filter.showIds.length) {
      const filterShowId = filter.showIds[0]
      const clientShow = shows.find((show) => show.id === filterShowId)
      return clientShow
        ? t('Show: {{name}}', {name: clientShow.title})
        : t('Show ID: {{id}}', {id: filterShowId})
    }
    return undefined
  }
  const getEventCategoryFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    eventCategories: GetLightweightEventCategoriesQuery['eventCategories']
  ) => {
    if (filter.eventCategoryIds && filter.eventCategoryIds.length) {
      const filterEventCategoryId = filter.eventCategoryIds[0]
      const eventCategory = eventCategories.find(
        ({id}) => id === filterEventCategoryId
      )
      return eventCategory
        ? t('Event category: {{name}}', {name: eventCategory.name})
        : t('Event category ID: {{id}}', {id: filterEventCategoryId})
    }
    return undefined
  }
  const getMarketingLabelFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    marketingLabels: GetLightweightMarketingLabelsQuery['marketingLabels']
  ) => {
    if (filter.marketingLabelIds && filter.marketingLabelIds.length) {
      const filterMarketingLabelId = filter.marketingLabelIds[0]
      const marketingLabel = marketingLabels.find(
        ({id}) => id === filterMarketingLabelId
      )
      return marketingLabel
        ? t('Marketing label: {{name}}', {name: marketingLabel.name})
        : t('Marketing label ID: {{id}}', {id: filterMarketingLabelId})
    }
    return undefined
  }
  const getBusinessPartnerIdFromSearchObject = (
    filter: ShowsStatisticsFilterInput,
    businessPartners: {id: number; companyName: string}[]
  ) => {
    if (filter.businessPartnerIds && filter.businessPartnerIds.length) {
      const businessPartnerId = filter.businessPartnerIds[0]
      const businessPartner = businessPartners.find(
        ({id}) => id === businessPartnerId
      )
      return businessPartner
        ? t('Business partner: {{companyName}}', {
            companyName: businessPartner.companyName
          })
        : t('Business partner ID: {{id}}', {id: businessPartnerId})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getDivisionFromSearchObject,
    getVenueFromSearchObject,
    getCostCenterFromSearchObject,
    getShowFromSearchObject,
    getEventCategoryFromSearchObject,
    getMarketingLabelFromSearchObject,
    getBusinessPartnerIdFromSearchObject
  }
}

interface IShowsStatisticsSearchProps {
  onFilterChange: (filter: ShowsStatisticsFilterInput) => void
}

export const ShowsStatisticsSearch: React.FC<IShowsStatisticsSearchProps> = ({
  onFilterChange
}: IShowsStatisticsSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {localeCode} = useLocale()
  const {
    getHasTextFromSearchObject,
    getDivisionFromSearchObject,
    getVenueFromSearchObject,
    getCostCenterFromSearchObject,
    getShowFromSearchObject,
    getEventCategoryFromSearchObject,
    getMarketingLabelFromSearchObject,
    getBusinessPartnerIdFromSearchObject
  } = useGetFieldFromSearchObject()
  const {divisions} = useGetLightweightDivisions()
  const {data: venuesData} = useQuery<
    LightweightVenuesQuery,
    LightweightVenuesQueryVariables
  >(LIGHTWEIGHT_VENUES, {
    fetchPolicy: 'network-only'
  })
  const {costCenters} = useGetLightweightCostCenters()
  const {clientShows} = useGetLightweightClientShows({
    paginationInput: {offset: 0, limit: 200}
  })
  const {eventCategories} = useGetLightweightEventCategories()
  const {marketingLabels} = useGetLightweightMarketingLabels()
  const {data: businessPartnersData} = useQuery<
    LightweightBusinessPartnersQuery,
    LightweightBusinessPartnersQueryVariables
  >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
    variables: {
      paginationInput: {offset: 0, limit: 100},
      filter: {
        categories: [
          BusinessPartnerCategory.Licensing,
          BusinessPartnerCategory.MovieDistributor,
          BusinessPartnerCategory.Agency
        ],
        states: [BusinessPartnerState.Active, BusinessPartnerState.Inactive]
      }
    },
    fetchPolicy: 'network-only',
    skip: !P([PermissionCode.ReadBusinessPartners])
  })
  const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
    ({id, name: label}) => ({
      id,
      label
    })
  )
  const venueSelectOptions: ISelectOption<number>[] = (
    venuesData?.venues || []
  ).map(({id, name: label}) => ({id, label}))
  const costCenterSelectOptions: ISelectOption<number>[] = costCenters.map(
    ({id, name: label}) => ({id, label})
  )
  const translatedShowsTitle = clientShows.map((show) => ({
    id: show.id,
    title:
      show.translations.find(
        (translation) => translation.localeCode === localeCode
      )?.title ||
      show?.originalTitle ||
      show.translations[0]?.title ||
      t<string>('Title not available')
  }))
  const showsSelectOptions: ISelectOption<number>[] = translatedShowsTitle.map(
    (show) => ({
      id: show.id,
      label: show.title
    })
  )
  const eventCategorySelectOptions: ISelectOption<number>[] =
    eventCategories.map(({id, name}) => ({
      id,
      label: name
    }))
  const marketingLabelSelectOptions: ISelectOption<number>[] =
    marketingLabels.map(({id, name}) => ({
      id,
      label: name
    }))
  const businessPartnerSelectOptions: ISelectOption<number>[] = (
    businessPartnersData?.businessPartners.items || []
  ).map(({id, companyName}) => ({id, label: companyName}))
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<ShowsStatisticsFilterInput>(
      getHasTextFromSearchObject,
      (filter) => getDivisionFromSearchObject(filter, divisions),
      (filter) => getVenueFromSearchObject(filter, venuesData?.venues || []),
      (filter) => getCostCenterFromSearchObject(filter, costCenters),
      (filter) => getShowFromSearchObject(filter, translatedShowsTitle),
      (filter) => getEventCategoryFromSearchObject(filter, eventCategories),
      (filter) => getMarketingLabelFromSearchObject(filter, marketingLabels),
      (filter) =>
        getBusinessPartnerIdFromSearchObject(
          filter,
          businessPartnersData?.businessPartners.items || []
        )
    )
  return (
    <Search<ShowsStatisticsFilterInput>
      storageKey="SHOWS_STATISTICS"
      placeholder={t('Search for show')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_SHOWS_STATISTICS_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchTextRow<ShowsStatisticsFilterInput>
            label={t('Has text')}
            placeholder={t('Enter show title')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchSelectRow<ShowsStatisticsFilterInput, number>
            label={t('Division')}
            value={
              advancedSearchObject.divisionIds
                ? advancedSearchObject.divisionIds[0]
                : undefined
            }
            options={divisionSelectOptions}
            mapSelectValueToSearchObject={mapDivisionIdToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          {P([PermissionCode.ReadVenues]) && (
            <AdvancedSearchSelectRow<ShowsStatisticsFilterInput, number>
              label={t('Venue')}
              value={
                advancedSearchObject.venueIds
                  ? advancedSearchObject.venueIds[0]
                  : undefined
              }
              options={venueSelectOptions}
              mapSelectValueToSearchObject={mapVenueIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadCostCenters]) && (
            <AdvancedSearchSelectRow<
              AdmissionTypesStatisticsFilterInput,
              number
            >
              label={t('Cost center')}
              value={
                advancedSearchObject.costCenterIds
                  ? advancedSearchObject.costCenterIds[0]
                  : undefined
              }
              options={costCenterSelectOptions}
              mapSelectValueToSearchObject={mapCostCenterIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadClientShows]) && (
            <AdvancedSearchSelectRow<
              AdmissionTypesStatisticsFilterInput,
              number
            >
              label={t('Show')}
              value={
                advancedSearchObject.showIds
                  ? advancedSearchObject.showIds[0]
                  : undefined
              }
              options={showsSelectOptions}
              mapSelectValueToSearchObject={mapShowIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadEventCategories]) && (
            <AdvancedSearchSelectRow<
              AdmissionTypesStatisticsFilterInput,
              number
            >
              label={t('Event category')}
              value={
                advancedSearchObject.eventCategoryIds
                  ? advancedSearchObject.eventCategoryIds[0]
                  : undefined
              }
              options={eventCategorySelectOptions}
              mapSelectValueToSearchObject={mapEventCategoryIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadMarketingLabels]) && (
            <AdvancedSearchSelectRow<
              AdmissionTypesStatisticsFilterInput,
              number
            >
              label={t('Marketing label')}
              value={
                advancedSearchObject.marketingLabelIds
                  ? advancedSearchObject.marketingLabelIds[0]
                  : undefined
              }
              options={marketingLabelSelectOptions}
              mapSelectValueToSearchObject={mapMarketingLabelIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadBusinessPartners]) && (
            <AdvancedSearchSelectRow<
              AdmissionTypesStatisticsFilterInput,
              number
            >
              label={t('Business partner')}
              value={
                advancedSearchObject.businessPartnerIds
                  ? advancedSearchObject.businessPartnerIds[0]
                  : undefined
              }
              options={businessPartnerSelectOptions}
              mapSelectValueToSearchObject={mapBusinessPartnerIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
        </AdvancedSearchBase>
      )}
    />
  )
}
