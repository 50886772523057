import {DiscountPropertiesFragment, DiscountType} from '../__generated__/schema'
import {useTranslateEffectiveClientPrice} from './translateCurrencies'

export const useFormatDiscountValue = (startsWithMinus: boolean = false) => {
  const translatePrice = useTranslateEffectiveClientPrice()
  return ({
    value,
    type
  }: Pick<DiscountPropertiesFragment, 'value' | 'type'>) => {
    switch (type) {
      case DiscountType.FixedAmount:
        return startsWithMinus
          ? `- ${translatePrice(value)}`
          : translatePrice(value)
      case DiscountType.Percentage:
        return startsWithMinus
          ? `- ${value.toFixed(2)}%`
          : `${value.toFixed(2)}%`
      default:
        return String(value)
    }
  }
}
