import {ICreateIcon, IIcon} from '@attendio/shared-components'
import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {ICON_MIN_SIZE} from '../../../config'
import {getCompressedId} from '../../../utils/compressedIdGenerator'
import {customizeDimensions} from '../../../utils/math'
import {useEditorModeActions} from '../../editorMode/actions'
import {CanvasObjectType} from '../../types'
import {IconActionType} from './types'

export const useIconActions = () => {
  const dispatch = useDispatch()
  const {setSelectMode} = useEditorModeActions()

  const addIcon = useCallback(
    (icon: ICreateIcon) => {
      const _icon: IIcon = {
        ...icon,
        id: getCompressedId(),
        dimensions: customizeDimensions(
          icon.dimensions,
          ICON_MIN_SIZE,
          ICON_MIN_SIZE
        )
      }
      dispatch({
        type: IconActionType.ADD_ICON,
        payload: {type: CanvasObjectType.Icon, config: _icon}
      })
      setSelectMode()
    },
    [dispatch, setSelectMode]
  )

  const actions = useMemo(
    () => ({
      addIcon
    }),
    [addIcon]
  )

  return actions
}
