import {isEmpty, omitBy} from 'lodash'
import {CreateBusinessPartnerInput} from '../../../../__generated__/schema'
import {
  BusinessPartnerFormField,
  IBusinessPartnerForm
} from '../../../common/businessPartnerDrawer/types'

export const transformFormDataToCreateBusinessPartnerInput = (
  formData: IBusinessPartnerForm
): CreateBusinessPartnerInput => ({
  companyName: formData[BusinessPartnerFormField.CompanyName],
  types: formData[BusinessPartnerFormField.Types],
  categories: formData[BusinessPartnerFormField.Categories],
  companyIdNumber:
    formData[BusinessPartnerFormField.CompanyIdNumber] || undefined,
  taxId: formData[BusinessPartnerFormField.TaxId] || undefined,
  vatId: formData[BusinessPartnerFormField.VatId] || undefined,
  VATRegistered: formData[BusinessPartnerFormField.VATRegistered] || undefined,
  customId: formData[BusinessPartnerFormField.CustomId] || undefined,
  phone: formData[BusinessPartnerFormField.Phone] || undefined,
  email: formData[BusinessPartnerFormField.Email] || undefined,
  website: formData[BusinessPartnerFormField.Website] || undefined,
  note: formData[BusinessPartnerFormField.Note] || undefined,
  internalNote: formData[BusinessPartnerFormField.InternalNote] || undefined,
  billingAddress: omitBy(
    formData[BusinessPartnerFormField.BillingAddress],
    isEmpty
  ),
  deliveryAddress: omitBy(
    formData[BusinessPartnerFormField.DeliveryAddress],
    isEmpty
  )
})
