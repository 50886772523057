import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode, RolesQuery} from '../../../../__generated__/schema'
import {useTranslateRoleType} from '../../../../hooks/translateRoleType'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {COLOR_CONF} from '../../../constants'
import {useLocale} from '../../../context/locale'
import {PrimaryHeader} from '../Header'
import {WideCenteredLayout} from '../Layout'
import {useGetRoles} from './graphql'
import {IRolesSearchFilter, RolesSearch} from './RolesSearch'

const StateRenderer = ({isDeprecated}: {isDeprecated: boolean}) => {
  const {t} = useTranslation()
  return (
    <EntityStateChip
      label={isDeprecated ? t('Deprecated') : t('Enabled')}
      colorConf={isDeprecated ? COLOR_CONF.RED : COLOR_CONF.GREEN}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.roles.edit(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const getFilteredRoles = (
  roles: RolesQuery['roles'],
  filter: IRolesSearchFilter
) =>
  filter && filter.hasText
    ? roles.filter((role) =>
        Object.values(role.names).some(
          (roleName) =>
            roleName &&
            roleName.toLowerCase().indexOf(filter.hasText!.toLowerCase()) > -1
        )
      )
    : roles

interface IRolesListProps {
  filter: IRolesSearchFilter
}

const RolesList: React.FC<IRolesListProps> = ({filter}: IRolesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useGetRoles()
  const {localeCode} = useLocale()
  const dateTimeFormatter = useDateTimeFormatter()
  const translateRoleType = useTranslateRoleType()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'names',
        valueGetter: (params) => params.value[localeCode],
        minWidth: 180
      },
      {
        headerName: t('Type'),
        field: 'type',
        minWidth: 120,
        valueFormatter: (params) => translateRoleType(params.value)
      },
      {
        headerName: t('State'),
        field: 'isDeprecated',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StateRenderer isDeprecated={params.value} />
        },
        minWidth: 120
      },
      {
        headerName: t('Description'),
        field: 'descriptions',
        valueGetter: (params) => params.value[localeCode],
        minWidth: 200
      },
      {
        headerName: t('Permissions'),
        field: 'permissionCodes',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.value.length,
        minWidth: 150
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 180
      },
      {
        headerName: t('Updated by'),
        field: 'updatedByName',
        minWidth: 180
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 180
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [dateTimeFormatter, localeCode, t, translateRoleType]
  )
  return (
    <RenderOnData
      errorMessage={t<string>('Loading roles failed')}
      error={error}
      loading={loading}
      data={data}
      ignoreLoadingIfData
      dataCondition={(data: RolesQuery) => Array.isArray(data?.roles)}
    >
      {({roles}: RolesQuery) => (
        <WideCenteredLayout
          sx={{
            height: `calc(100% - 64px)`,
            width: '100%',
            p: 3
          }}
        >
          <DataGridTable
            columns={columns}
            rows={getFilteredRoles(roles, filter)}
            disableColumnMenu
            localeText={{noRowsLabel: t('No roles found')}}
            initialState={{
              pagination: {paginationModel: {pageSize: roles.length}},
              pinnedColumns: {left: ['names'], right: ['id']}
            }}
            columnVisibilityModel={{
              id: P([PermissionCode.ReadRole, PermissionCode.UpdateRole])
            }}
            hideFooter
          />
        </WideCenteredLayout>
      )}
    </RenderOnData>
  )
}

export const RolesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<IRolesSearchFilter>({})
  const history = useHistory()
  const fabClasses = useFabClasses()
  const handleOnCreateButtonClick = useCallback(
    () => history.push(routeTo.admin.roles.create()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          search={<RolesSearch onFilterChange={setSearchFilter} />}
          title={t('Roles')}
        />
      }
    >
      <RolesList filter={searchFilter} />
      {P([PermissionCode.CreateRole]) && (
        <CreateFab onClick={handleOnCreateButtonClick} classes={fabClasses} />
      )}
    </PageWithHeaderTemplate>
  )
}
