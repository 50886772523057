import {Box} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {
  PaymentReportsFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PaymentReportDrawer} from '../../../common/paymentReportDrawer'
import {PersistentDrawerMenu} from '../../../common/PersistentDrawerMenu'
import {PersistentDrawerWithContent} from '../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {DailyCashierReports} from './DailyCashierReports'
import {MonthlyEcommerceReports} from './MonthlyEcommerceReports'
import {MonthlyReports} from './MonthlyReports'
import {PaymentReportDetail} from './paymentReportDetail'
import {PaymentReportsSearch} from './PaymentReportsSearch'
import {PaymentReportView} from './PaymentReportView'

const useNavigationItems = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  return useMemo(
    (): {
      dailyCashierReports?: {id: string; label: string; route: string}
      monthlyEcommerceReports?: {id: string; label: string; route: string}
      monthlyReports?: {id: string; label: string; route: string}
      paymentReportView?: {id: string; label: string; route: string}
    } => ({
      ...(P([
        PermissionCode.ReadPaymentReports,
        PermissionCode.ReadDailyCashierReport
      ])
        ? {
            dailyCashierReports: {
              id: 'dailyCashierReports',
              label: t('Daily cashier reports'),
              route: routeTo.admin.paymentReports.dailyCashierReports()
            }
          }
        : {}),
      ...(P([
        PermissionCode.ReadPaymentReports,
        PermissionCode.ReadMonthlyEcommerceReports
      ])
        ? {
            monthlyEcommerceReports: {
              id: 'monthlyEcommerceReports',
              label: t('Monthly ecommerce reports'),
              route: routeTo.admin.paymentReports.monthlyEcommerceReports()
            }
          }
        : {}),
      ...(P([
        PermissionCode.ReadPaymentReports,
        PermissionCode.ReadMonthlyReports
      ])
        ? {
            monthlyReports: {
              id: 'monthlyReports',
              label: t('Monthly reports'),
              route: routeTo.admin.paymentReports.monthlyReports()
            }
          }
        : {}),
      ...(P([
        PermissionCode.ReadPaymentReports,
        PermissionCode.ReadPaymentReportView,
        PermissionCode.ReadPaymentReportViewPage
      ])
        ? {
            paymentReportView: {
              id: 'paymentReportView',
              label: t('Payment report view'),
              route: routeTo.admin.paymentReports.paymentReportView()
            }
          }
        : {})
    }),
    [P, t]
  )
}

const useGetFirstAvailableRoute = () => {
  const navigationItems = useNavigationItems()
  return () => {
    if (navigationItems.dailyCashierReports) {
      return navigationItems.dailyCashierReports.route
    } else if (navigationItems.monthlyEcommerceReports) {
      return navigationItems.monthlyEcommerceReports.route
    } else if (navigationItems.monthlyReports) {
      return navigationItems.monthlyReports.route
    } else if (navigationItems.paymentReportView) {
      return navigationItems.paymentReportView.route
    } else {
      return '/'
    }
  }
}

interface IDrawerContentProps {
  searchFilter: PaymentReportsFilterInput
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  searchFilter
}: IDrawerContentProps) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  return (
    <Switch>
      {P([
        PermissionCode.ReadDailyCashierReports,
        PermissionCode.ReadPaymentReports
      ]) && (
        <Route path={routeTo.admin.paymentReports.dailyCashierReports()}>
          <DailyCashierReports searchFilter={searchFilter} />
        </Route>
      )}
      {P([
        PermissionCode.ReadMonthlyEcommerceReports,
        PermissionCode.ReadPaymentReports
      ]) && (
        <Route path={routeTo.admin.paymentReports.monthlyEcommerceReports()}>
          <MonthlyEcommerceReports searchFilter={searchFilter} />
        </Route>
      )}
      {P([
        PermissionCode.ReadMonthlyReports,
        PermissionCode.ReadPaymentReports
      ]) && (
        <Route path={routeTo.admin.paymentReports.monthlyReports()}>
          <MonthlyReports searchFilter={searchFilter} />
        </Route>
      )}
      {P([
        PermissionCode.ReadPaymentReports,
        PermissionCode.ReadPaymentReportView,
        PermissionCode.ReadPaymentReportViewPage
      ]) && (
        <Route path={routeTo.admin.paymentReports.paymentReportView()}>
          <PaymentReportView />
          <PaymentReportDrawer
            onExited={() =>
              history.replace(routeTo.admin.paymentReports.paymentReportView())
            }
          />
        </Route>
      )}
    </Switch>
  )
}

export const PaymentReports: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory<{from?: string}>()
  const [searchFilter, setSearchFilter] = useState<PaymentReportsFilterInput>(
    {}
  )
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(true)
  const navigationItems = useNavigationItems()
  const getFirstAvailableRoute = useGetFirstAvailableRoute()
  const handleExited = useCallback(() => {
    if (history.location?.state?.from) {
      history.push(history.location.state.from)
    } else {
      history.push(routeTo.admin.paymentReports.index())
    }
  }, [history])
  const isOnPaymentReportViewRoute =
    history.location.pathname === navigationItems.paymentReportView?.route
  useEffect(() => {
    if (isOnPaymentReportViewRoute) {
      setSearchFilter({})
    }
  }, [isOnPaymentReportViewRoute])
  return (
    <Switch>
      <Route path={routeTo.admin.paymentReports.index()} exact>
        <Redirect to={getFirstAvailableRoute()} />
      </Route>
      <Route
        path={[
          routeTo.admin.paymentReports.dailyCashierReports(),
          routeTo.admin.paymentReports.monthlyEcommerceReports(),
          routeTo.admin.paymentReports.monthlyReports(),
          routeTo.admin.paymentReports.paymentReportView()
        ]}
      >
        <PageWithHeaderTemplate
          header={
            <PrimaryHeader
              title={t('Payment reports')}
              search={
                !isOnPaymentReportViewRoute && (
                  <PaymentReportsSearch onFilterChange={setSearchFilter} />
                )
              }
            />
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <Box sx={{display: 'grid', height: '100%'}}>
              <PersistentDrawerWithContent
                content={<DrawerContent searchFilter={searchFilter} />}
                drawerContent={<PersistentDrawerMenu items={navigationItems} />}
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
              />
            </Box>
          </ChildrenOnEffectiveClientSelected>
        </PageWithHeaderTemplate>
      </Route>
      {P([PermissionCode.ReadPaymentReport]) && (
        <Route
          path={routeTo.admin.paymentReports.detail(':paymentReportId')}
          exact
        >
          <PaymentReportDetail onExited={handleExited} />
        </Route>
      )}
    </Switch>
  )
}
