import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  ChangeVoucherCampaignStateMutation,
  ChangeVoucherCampaignStateMutationVariables,
  CreateVoucherCampaignInput,
  CreateVoucherCampaignMutation,
  CreateVoucherCampaignMutationVariables,
  LightweightECommercePaymentMethodsQuery,
  LightweightECommercePaymentMethodsQueryVariables,
  LightweightRetailPaymentMethodsQuery,
  LightweightRetailPaymentMethodsQueryVariables,
  PaymentMethodState,
  VoucherCampaignQuery,
  VoucherCampaignQueryVariables,
  VoucherCampaignsQuery,
  VoucherCampaignsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const VOUCHER_CAMPAIGN_FIELDS = gql`
  fragment VoucherCampaignFields on VoucherCampaign {
    id
    name
    state
    canCreateNewVouchers
    initialBalance
    initialValidity
    redemptionLimit
    rechargeLimit
    retailPaymentMethod {
      id
      name
    }
    ecommercePaymentMethod {
      id
      name
    }
  }
`

const GET_VOUCHER_CAMPAIGNS = gql`
  query voucherCampaigns(
    $filter: VoucherCampaignsFilterInput
    $paginationInput: PaginationInput!
  ) {
    voucherCampaigns(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...VoucherCampaignFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${VOUCHER_CAMPAIGN_FIELDS}
  ${PAGINATION_FRAGMENT}
`

export const useGetVoucherCampaigns = (
  variables: VoucherCampaignsQueryVariables
) =>
  useQuery<VoucherCampaignsQuery, VoucherCampaignsQueryVariables>(
    GET_VOUCHER_CAMPAIGNS,
    {
      variables,
      fetchPolicy: 'network-only'
    }
  )

const GET_LIGHTWEIGHT_RETAIL_PAYMENT_METHODS = gql`
  query lightweightRetailPaymentMethods($state: PaymentMethodState) {
    retailPaymentMethods(state: $state) {
      id
      name
      type
      serviceProvider
    }
  }
`

const GET_LIGHTWEIGHT_ECOMMERCE_PAYMENT_METHODS = gql`
  query lightweightECommercePaymentMethods($state: PaymentMethodState) {
    eCommercePaymentMethods(state: $state) {
      id
      name
      type
      serviceProvider
    }
  }
`

export const useGetLightweightRetailPaymentMethods = () =>
  useQuery<
    LightweightRetailPaymentMethodsQuery,
    LightweightRetailPaymentMethodsQueryVariables
  >(GET_LIGHTWEIGHT_RETAIL_PAYMENT_METHODS, {
    variables: {state: PaymentMethodState.Active},
    fetchPolicy: 'network-only'
  })

export const useGetLightweightECommercePaymentMethods = () =>
  useQuery<
    LightweightECommercePaymentMethodsQuery,
    LightweightECommercePaymentMethodsQueryVariables
  >(GET_LIGHTWEIGHT_ECOMMERCE_PAYMENT_METHODS, {
    variables: {state: PaymentMethodState.Active},
    fetchPolicy: 'network-only'
  })

const CREATE_VOUCHER_CAMPAIGN = gql`
  mutation createVoucherCampaign($input: CreateVoucherCampaignInput!) {
    createVoucherCampaign(input: $input) {
      id
    }
  }
`

export const useCreateVoucherCampaign = () => {
  const [createVoucherCampaign] = useMutation<
    CreateVoucherCampaignMutation,
    CreateVoucherCampaignMutationVariables
  >(CREATE_VOUCHER_CAMPAIGN)
  return useCallback(
    (input: CreateVoucherCampaignInput) =>
      createVoucherCampaign({
        variables: {input}
      }),
    [createVoucherCampaign]
  )
}

const GET_VOUCHER_CAMPAIGN = gql`
  query voucherCampaign($id: PositiveInt!) {
    voucherCampaign(id: $id) {
      ...VoucherCampaignFields
      description
    }
  }
  ${VOUCHER_CAMPAIGN_FIELDS}
`

export const useGetVoucherCampaign = (id: number) =>
  useQuery<VoucherCampaignQuery, VoucherCampaignQueryVariables>(
    GET_VOUCHER_CAMPAIGN,
    {
      variables: {id},
      fetchPolicy: 'network-only'
    }
  )

const CHANGE_VOUCHER_CAMPAIGN_STATE = gql`
  mutation changeVoucherCampaignState(
    $id: PositiveInt!
    $state: VoucherCampaignState!
  ) {
    changeVoucherCampaignState(id: $id, state: $state) {
      ...VoucherCampaignFields
    }
  }
  ${VOUCHER_CAMPAIGN_FIELDS}
`

export const useChangeVoucherCampaignState = () => {
  const [changeVoucherCampaignState] = useMutation<
    ChangeVoucherCampaignStateMutation,
    ChangeVoucherCampaignStateMutationVariables
  >(CHANGE_VOUCHER_CAMPAIGN_STATE)
  return useCallback(
    (variables: ChangeVoucherCampaignStateMutationVariables) =>
      changeVoucherCampaignState({
        variables
      }),
    [changeVoucherCampaignState]
  )
}
