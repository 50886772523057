import {
  BusinessPartnerBillingAddressInput,
  BusinessPartnerCategory,
  BusinessPartnerDeliveryAddressInput,
  BusinessPartnerType,
  ClientVatRegistered
} from '../../../__generated__/schema'

export enum BusinessPartnerFormField {
  CompanyName = 'companyName',
  Types = 'types',
  Categories = 'categories',
  CompanyIdNumber = 'companyIdNumber',
  TaxId = 'taxId',
  VatId = 'vatId',
  VATRegistered = 'VATRegistered',
  CustomId = 'customId',
  Phone = 'phone',
  Email = 'email',
  Website = 'website',
  Note = 'note',
  InternalNote = 'internalNote',
  BillingAddress = 'billingAddress',
  DeliveryAddress = 'deliveryAddress'
}

export interface IBusinessPartnerForm {
  [BusinessPartnerFormField.CompanyName]: string
  [BusinessPartnerFormField.Types]: BusinessPartnerType[]
  [BusinessPartnerFormField.Categories]?: BusinessPartnerCategory[]
  [BusinessPartnerFormField.CompanyIdNumber]?: string
  [BusinessPartnerFormField.TaxId]?: string
  [BusinessPartnerFormField.VatId]?: string
  [BusinessPartnerFormField.VATRegistered]?: ClientVatRegistered
  [BusinessPartnerFormField.CustomId]?: string
  [BusinessPartnerFormField.Phone]?: string
  [BusinessPartnerFormField.Email]?: string
  [BusinessPartnerFormField.Website]?: string
  [BusinessPartnerFormField.Note]?: string
  [BusinessPartnerFormField.InternalNote]?: string
  [BusinessPartnerFormField.BillingAddress]: BusinessPartnerBillingAddressInput | null
  [BusinessPartnerFormField.DeliveryAddress]: BusinessPartnerDeliveryAddressInput | null
}
