import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'

import {routeTo} from '../../../../utils/routes'
import {ChildrenOnNoEffectiveClientSelected} from '../ChildrenOnNoEffectiveClientSelected'
import {AddClient} from './AddClient'
import {ClientList} from './ClientList'
import {EditClient} from './EditClient'

export const Clients = () => {
  const {P} = useEnsurePermissions()

  return (
    <ChildrenOnNoEffectiveClientSelected>
      <Switch>
        {P([PermissionCode.ReadClients]) && (
          <Route
            path={routeTo.admin.clients.home()}
            component={ClientList}
            exact
          />
        )}
        {P([PermissionCode.CreateClient]) && (
          <Route
            path={routeTo.admin.clients.add()}
            component={AddClient}
            exact
          />
        )}
        {P([PermissionCode.UpdateClient]) && (
          <Route
            path={routeTo.admin.clients.edit(':id')}
            component={EditClient}
          />
        )}
      </Switch>
    </ChildrenOnNoEffectiveClientSelected>
  )
}
