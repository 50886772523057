import {
  BaseWebsiteCommonSettingsFieldsFragment,
  UpdateWebsiteCommonSettingsInput
} from '../../../../../../__generated__/schema'
import {IMetadataForm, MetadataFormField} from './types'

export const transformBaseWebsiteCommonSettingsFieldsToMetadataForm = (
  data: BaseWebsiteCommonSettingsFieldsFragment
): IMetadataForm => ({
  [MetadataFormField.Index]: Boolean(
    data.metadata.robots.index && data.metadata.robots.follow
  ),
  [MetadataFormField.MetadataBase]: data.metadata.metadataBase,
  [MetadataFormField.Title]: data.metadata.title,
  [MetadataFormField.Description]: data.metadata.description,
  [MetadataFormField.OpenGraphTitle]: data.metadata.openGraph.title,
  [MetadataFormField.OpenGraphDescrpition]: data.metadata.openGraph.description,
  [MetadataFormField.OpenGraphImageUrl]:
    data.metadata.openGraph.images || undefined
})

export const transformMetadataFormToUpdateWebsiteCommonSettingsInput = (
  data: IMetadataForm
): UpdateWebsiteCommonSettingsInput => ({
  metadata: {
    metadataBase: data[MetadataFormField.MetadataBase],
    title: data[MetadataFormField.Title] || '',
    description: data[MetadataFormField.Description] || '',
    openGraph: {
      title: data[MetadataFormField.OpenGraphTitle] || '',
      description: data[MetadataFormField.Description] || '',
      images: data[MetadataFormField.OpenGraphImageUrl] || null
    },
    robots: data[MetadataFormField.Index]
      ? {follow: true, index: true}
      : {follow: false, index: false}
  }
})
