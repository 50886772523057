import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useUserInfo} from '../../utils/auth'
import {useDateTimeFormatters} from '../../utils/formatting'

interface IInformation {
  title: string
  value: string
}

interface IPagePDFHeaderProps {
  title: string
  subTitle: string
  informations?: IInformation[]
}

export const PagePDFHeader: React.FC<IPagePDFHeaderProps> = ({
  title,
  subTitle,
  informations
}: IPagePDFHeaderProps) => {
  const {t} = useTranslation()
  const {user} = useUserInfo()
  const {formatDateTime} = useDateTimeFormatters()
  const name = [user?.lastName, user?.firstName].join(' ')
  return (
    <Box
      sx={{
        '@media print': {
          display: 'flex',
          flexDirection: 'column'
        },
        display: 'none',
        py: 1.5,
        px: 2.5
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="caption" color="textSecondary">
          {[t('Print: {{name}}', {name}), formatDateTime(new Date())].join(
            ' • '
          )}
        </Typography>
      </Box>
      <Typography variant="subtitle2">{subTitle}</Typography>
      {informations?.map(({title, value}, index) => (
        <Typography key={`information-${index}`} variant="caption">
          {title}: {value}
        </Typography>
      ))}
    </Box>
  )
}
