import {useLazyQuery} from '@apollo/react-hooks'
import {Button} from '@mui/material'
import {ApolloError} from 'apollo-client'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CheckGoPayCredentialsQuery,
  CheckGoPayCredentialsQueryVariables,
  ErrorMessages,
  PaymentGatewayEnvironment
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {CHECK_GOPAY_CREDENTIALS} from './graphql'

interface ICheckGoPayCredentialsProps {
  goId: number
  clientSecret: string
  clientId: string
  environment: PaymentGatewayEnvironment
  isButtonDisabled?: boolean
}

export const CheckGoPayCredentials: React.FC<ICheckGoPayCredentialsProps> = ({
  goId,
  clientSecret,
  clientId,
  environment,
  isButtonDisabled
}: ICheckGoPayCredentialsProps) => {
  const {t} = useTranslation()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const {
    state: isDialogOpen,
    setTrue: openDialog,
    setFalse: closeDialog
  } = useBooleanState(false)
  const [checkCredentialsMessage, setCheckCredentialsMessage] =
    useState<string | null>(null)
  const handleOnCheckCredentialsCompleted = useCallback(
    (data: CheckGoPayCredentialsQuery) => {
      if (data.isGoPayConfigInputValid) {
        setCheckCredentialsMessage(
          t<string>('Successful. Credentials are ready to use.')
        )
      } else {
        setCheckCredentialsMessage(null)
        closeDialog()
      }
    },
    [closeDialog, t]
  )
  const handleOnCheckCredentialsError = useCallback(
    (error: ApolloError) => {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidGopayCredentials
        )
      ) {
        setCheckCredentialsMessage(t<string>('Credentials are not valid.'))
      } else {
        closeDialog()
        setCheckCredentialsMessage(null)
        defaultErrorHandler(error, t('Error while checking GoPay credentials'))
      }
    },
    [closeDialog, defaultErrorHandler, t]
  )
  const [checkGoPayCredentials, {loading}] = useLazyQuery<
    CheckGoPayCredentialsQuery,
    CheckGoPayCredentialsQueryVariables
  >(CHECK_GOPAY_CREDENTIALS, {
    fetchPolicy: 'network-only',
    onCompleted: handleOnCheckCredentialsCompleted,
    onError: handleOnCheckCredentialsError
  })
  const handleOnCheckCredentialsButtonClick = useCallback(() => {
    checkGoPayCredentials({
      variables: {
        input: {
          goId,
          clientSecret,
          clientId,
          environment
        }
      }
    })
    openDialog()
  }, [
    checkGoPayCredentials,
    clientId,
    clientSecret,
    goId,
    environment,
    openDialog
  ])
  useEffect(() => {
    if (loading) {
      setCheckCredentialsMessage(t<string>('Connecting to GoPay ...'))
    }
  }, [loading, t])
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOnCheckCredentialsButtonClick}
        disabled={isButtonDisabled}
      >
        {t('Check credentials')}
      </Button>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        title={t('Checking credentials')}
        contentText={checkCredentialsMessage}
        onConfirm={closeDialog}
        confirmButtonLabel={t('Close')}
        dialogProps={{maxWidth: 'sm', fullWidth: true}}
      />
    </>
  )
}
