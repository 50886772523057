import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {WarehouseProductsFilterInput} from '../../../../__generated__/schema'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {WarehouseStocksList} from './WarehouseStocksList'
import {
  DEFAULT_WAREHOUSE_PRODUCTS_FILTER_INPUT,
  WarehouseStocksSearch
} from './WarehouseStocksSearch'

export const WarehouseStocksPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] =
    useState<WarehouseProductsFilterInput>(
      DEFAULT_WAREHOUSE_PRODUCTS_FILTER_INPUT
    )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Warehouse stocks')}
          search={<WarehouseStocksSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <WarehouseStocksList searchFilter={searchFilter} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
