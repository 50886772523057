import React, {createContext, ReactNode, useContext} from 'react'

import {useLocalStorageState} from '../../../../../hooks/storage'
import {
  CalendarSettingsFieldName,
  DayOfWeek,
  ICalendarSettingsForm,
  ScrollTime,
  SlotDuration
} from '../calendar/types'

const Context = createContext(
  [] as unknown as [
    ICalendarSettingsForm,
    (
      newState: ICalendarSettingsForm
    ) => void | ((state: ICalendarSettingsForm) => ICalendarSettingsForm)
  ]
)

type Props = {
  children: ReactNode
}

export const EventCalendarSettingsProvider: React.FC<Props> = ({
  children
}: Props) => {
  const calendarSettingsTuple = useLocalStorageState<ICalendarSettingsForm>(
    'events-calendar-settings',
    {
      [CalendarSettingsFieldName.FIRST_DAY_OF_WEEK]: DayOfWeek.Thu,
      [CalendarSettingsFieldName.SHORTEN_VENUE_NAMES]: false,
      [CalendarSettingsFieldName.SCROLL_TIME]: ScrollTime['9:00:00'],
      [CalendarSettingsFieldName.DENSITY_SETTINGS]: SlotDuration.Standard
    }
  )

  return (
    <Context.Provider value={calendarSettingsTuple}>
      {children}
    </Context.Provider>
  )
}

export const useEventCalendarSettings = () => useContext(Context)
