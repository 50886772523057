import {makeStyles} from '@mui/styles'
import {TFunction} from 'i18next'
import React from 'react'
import {
  FieldErrors,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  LeadInputStatus,
  LeadOptionPropertiesFragment
} from '../../../../__generated__/schema'
import {useTranslateLeadField} from '../../../../hooks/leadField'
import {Theme} from '../../../../theme'
import {FormFieldName} from '../../../form/types'
import {UncontrolledFormCheckbox} from '../../../form/UncontrolledFormCheckbox'
import {ILeadForm} from './types'

interface IBriefLeadFormCheckboxProps<
  FormValues extends ILeadForm = ILeadForm
> {
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  validationOptions?: ValidationOptions
  option?: LeadOptionPropertiesFragment
  showOptionalLeadInput?: boolean
  className?: string
}

const getHelperNote = (
  t: TFunction,
  leadOption?: LeadOptionPropertiesFragment
): string | undefined =>
  leadOption?.helperText
    ? leadOption?.inputStatus === LeadInputStatus.Required
      ? `*${leadOption.helperText}`
      : leadOption.helperText
    : (leadOption?.inputStatus === LeadInputStatus.Recommended &&
        t('*Recommended')) ||
      undefined

const useStyles = makeStyles<Theme>(() => ({
  hiddenInput: {
    display: 'none'
  }
}))
export const LeadFormCheckbox = <FormValues extends ILeadForm = ILeadForm>({
  option,
  control,
  errors,
  validationOptions,
  showOptionalLeadInput,
  className
}: IBriefLeadFormCheckboxProps<FormValues>) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translateLeadField = useTranslateLeadField()
  if (!option || option.inputStatus === LeadInputStatus.Unavailable) {
    return null
  }
  const name = option.field as FormFieldName<FormValues>
  if (
    !showOptionalLeadInput &&
    option.inputStatus === LeadInputStatus.Optional
  ) {
    return (
      <input
        type="checkbox"
        ref={control.register()}
        name={name}
        className={classes.hiddenInput}
      />
    )
  }
  return (
    <div className={className}>
      <UncontrolledFormCheckbox<FormValues>
        errors={errors}
        name={name}
        label={translateLeadField(option.field)}
        validationOptions={{
          ...validationOptions,
          required: option.inputStatus === LeadInputStatus.Required
        }}
        helperText={getHelperNote(t, option)}
        control={control}
      />
    </div>
  )
}
