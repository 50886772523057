import _ from 'lodash'
import React, {useEffect, useMemo} from 'react'

import {
  AuditoriumLayoutPricingPropertiesFragment,
  AuditoriumLayoutPricingState,
  AuditoriumLayoutPropertiesFragment,
  PermissionCode
} from '../../../../../../__generated__/schema'

import {useObjectsActions} from '../../../../../../editor/redux/objects/actions'
import {IObjectsState} from '../../../../../../editor/redux/objects/types'
import {usePricingActions} from '../../../../../../editor/redux/pricing/actions'
import {IPricingState} from '../../../../../../editor/redux/pricing/types'

import {EntityStateChip} from '../../../../../common'
import {
  layoutPricingStateColors,
  useLayoutPricingStateTranslations
} from '../../../../../constants'

import {
  ActivateButtonLayoutPricing,
  ArchiveButtonLayoutPricing
} from '../../EditActionsDialogs'
import {IEditorFormData} from '../EditorForm'

interface IUpdateOptions {
  handleSubmit: any
  onArchive: (formData: IEditorFormData) => Promise<any>
  onActivate: (formData: IEditorFormData) => Promise<any>
  status: AuditoriumLayoutPricingState
  canActivatePricing: boolean
  P: (permissions: Array<PermissionCode>) => Boolean
}

export const StateRenderer = ({
  status
}: {
  status: AuditoriumLayoutPricingState
}) => {
  const layoutPricingStateTranslations = useLayoutPricingStateTranslations()

  return (
    <EntityStateChip
      label={layoutPricingStateTranslations[status]}
      colorConf={layoutPricingStateColors[status]}
    />
  )
}

export const getUpdateOptions = ({
  handleSubmit,
  onArchive,
  onActivate,
  status,
  canActivatePricing,
  P
}: IUpdateOptions) => {
  if (status === AuditoriumLayoutPricingState.Active) {
    return P([PermissionCode.ArchiveAuditoriumLayoutPricing])
      ? [
          <ArchiveButtonLayoutPricing
            key={1}
            onSubmit={handleSubmit(onArchive)}
            ensureCanOpenModal={handleSubmit}
          />
        ]
      : []
  }
  return P([PermissionCode.ActivateAuditoriumLayoutPricing])
    ? [
        <ActivateButtonLayoutPricing
          key={1}
          onSubmit={handleSubmit(onActivate)}
          ensureCanOpenModal={handleSubmit}
          disabled={!canActivatePricing}
        />
      ]
    : []
}

export const backendLayoutPricingToEditorLayoutPricing = ({
  pricing,
  ticketTypes
}: Pick<
  AuditoriumLayoutPricingPropertiesFragment,
  'pricing' | 'ticketTypes'
>) => {
  const ticketTypesById = _.keyBy(ticketTypes, 'id')

  const pricingLayoutWithoutDeletedKeys = _.pickBy(
    pricing,
    ({id: ticketTypeId}: {id: string}) => {
      return ticketTypesById[ticketTypeId] != null
    }
  )

  const pricingLayoutWithColors = _.mapValues(
    pricingLayoutWithoutDeletedKeys,
    ({id: ticketTypeId}: {id: string}) => {
      return {id: ticketTypeId, color: ticketTypesById[ticketTypeId].color}
    }
  ) as unknown

  return pricingLayoutWithColors as IPricingState
}

export const editorLayoutPricingToBackendLayoutPricing = (
  pricing: IPricingState
) => {
  return _.mapValues(pricing, (v) => ({id: v.id}))
}

export const useInitLayouts = (
  layoutData: AuditoriumLayoutPropertiesFragment | null,
  pricingData: AuditoriumLayoutPricingPropertiesFragment | null
) => {
  const {setPricing} = usePricingActions()
  const {setObjectsState} = useObjectsActions()

  const pricingLayout = useMemo(
    () => pricingData && pricingData.pricing,
    [pricingData]
  )
  const auditoriumLayout = useMemo(
    () => layoutData && layoutData.layout,
    [layoutData]
  )
  // Will be always defined when `pricingLayout` is defined
  const ticketTypes = pricingData && pricingData.ticketTypes

  useEffect(() => {
    if (pricingLayout && auditoriumLayout && ticketTypes) {
      const editorLayoutPricing = backendLayoutPricingToEditorLayoutPricing({
        pricing: pricingLayout,
        ticketTypes
      })

      setPricing(editorLayoutPricing)
      setObjectsState(auditoriumLayout as IObjectsState)
    }
    // We do not want to depend on `ticketTypes/pricingData` as we do not want to re-init
    // layout when user create/delete/update ticket type.
    }, [pricingLayout, auditoriumLayout, setPricing, setObjectsState]) // eslint-disable-line
}
