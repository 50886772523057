import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Box, IconButton, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {Tooltip} from '../../../../../common'

interface ITourListItemProps {
  name: string
  price: React.ReactNode
  appliedDiscounts?: string
  onRemoveButtonClick?: () => Promise<void>
  priceBeforeDiscountLabel?: string
}

export const TourListItem: React.FC<ITourListItemProps> = ({
  name,
  price,
  appliedDiscounts,
  onRemoveButtonClick,
  priceBeforeDiscountLabel
}: ITourListItemProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  return (
    <Box
      sx={{
        display: 'grid',
        minHeight: 56,
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        py: 0,
        px: 2,
        '&:hover': {
          backgroundColor: 'action.hover',
          cursor: 'pointer'
        }
      }}
    >
      {P([PermissionCode.RemoveTourItemFromCart]) && onRemoveButtonClick && (
        <Box>
          <Tooltip title={t('Remove')}>
            <IconButton
              onClick={onRemoveButtonClick}
              color="primary"
              edge="start"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Typography variant="subtitle2" sx={{gridArea: 'title'}}>
          {name}
        </Typography>
        {appliedDiscounts && (
          <Typography variant="caption" color="textSecondary">
            {appliedDiscounts}
          </Typography>
        )}
      </Box>
      <Box sx={{alignSelf: 'center', textAlign: 'right'}}>
        <Typography variant="body2">{price}</Typography>
        {priceBeforeDiscountLabel && (
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{textDecoration: 'line-through'}}
          >
            {priceBeforeDiscountLabel}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
