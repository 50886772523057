import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, matchPath, useLocation} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {
  useDeviceServices,
  useTranslateDeviceServiceTitle
} from '../../../../hooks/deviceServices'
import {PRIMARY_100_COLOR, Theme} from '../../../../theme'
import {EnsurePermissions} from '../../../../utils/auth'

import {routeTo} from '../../../../utils/routes'
import {DeviceServiceIcon} from '../../../common/DeviceServiceIcon'

const NAVIGATION_RAIL_EXPANDED_WIDTH = 280
const NAVIGATION_RAIL_COLLAPSED_WIDTH = 72

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    '&:hover $listItemRootCollapsed': {
      display: 'none'
    },
    '&:not(:hover) $listItemRootExpanded': {
      display: 'none'
    },
    width: NAVIGATION_RAIL_COLLAPSED_WIDTH,
    '&:hover': {
      width: NAVIGATION_RAIL_EXPANDED_WIDTH
    },
    flexShrink: 0
  },
  drawerPaper: {
    top: 64,
    bottom: 0,
    height: 'auto',
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.2, 1)',
    width: NAVIGATION_RAIL_COLLAPSED_WIDTH,
    '&:hover': {
      width: NAVIGATION_RAIL_EXPANDED_WIDTH
    }
  },
  drawerContainer: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  listItemRootExpanded: {
    padding: theme.spacing(1, 3),
    '&$selected': {
      backgroundColor: PRIMARY_100_COLOR,
      color: theme.palette.primary.main
    },
    '&$selected:hover': {
      backgroundColor: PRIMARY_100_COLOR
    }
  },
  listItemRootCollapsed: {
    padding: theme.spacing(0, 1.5),
    '&$selected': {
      backgroundColor: theme.palette.primary.contrastText
    }
  },
  selected: {}
}))

const useItemStyles = makeStyles<Theme, {isSelected: boolean}>((theme) => ({
  selectedIcon: ({isSelected = true}) =>
    isSelected
      ? {
          color: theme.palette.primary.main
        }
      : {},
  avatar: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    color: ({isSelected = true}) =>
      isSelected ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.54)',
    backgroundColor: ({isSelected = true}) =>
      isSelected ? PRIMARY_100_COLOR : 'transparent'
  }
}))

interface INavigationRailItemProps {
  link: string
  title: string
  isSelected: boolean
  classes: Record<string, string>
}

const NavigationRailItem: React.FC<INavigationRailItemProps> = ({
  link,
  title,
  isSelected,
  classes
}: INavigationRailItemProps) => {
  const itemClasses = useItemStyles({isSelected})
  return (
    <>
      <ListItem
        button
        component={Link}
        to={link}
        selected={isSelected}
        classes={{
          root: classes.listItemRootExpanded,
          selected: classes.selected
        }}
      >
        <ListItemIcon>
          <DeviceServiceIcon className={itemClasses.selectedIcon} link={link} />
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            noWrap: true
          }}
        />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={link}
        selected={isSelected}
        classes={{
          root: classes.listItemRootCollapsed,
          selected: classes.selected
        }}
      >
        <ListItemAvatar>
          <Avatar className={itemClasses.avatar}>
            <DeviceServiceIcon
              className={itemClasses.selectedIcon}
              link={link}
            />
          </Avatar>
        </ListItemAvatar>
      </ListItem>
    </>
  )
}

export const NavigationRail: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const [deviceServices] = useDeviceServices()

  const settingsLink = routeTo.admin.cashDesk.settings()
  const deviceInformationLink = routeTo.admin.cashDesk.deviceInformation()
  const location = useLocation()
  const translateDeviceServiceTitle = useTranslateDeviceServiceTitle()
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
        root: classes.root
      }}
    >
      <List className={classes.drawerContainer}>
        {deviceServices
          .filter(({isAvailable}) => isAvailable)
          .map(({link}) => (
            <NavigationRailItem
              key={link}
              link={link}
              isSelected={Boolean(
                matchPath(location.pathname, {
                  path: link
                })
              )}
              title={translateDeviceServiceTitle(link)}
              classes={classes}
            />
          ))}
      </List>
      <List>
        <NavigationRailItem
          key={deviceInformationLink}
          link={deviceInformationLink}
          isSelected={Boolean(
            matchPath(location.pathname, {
              path: deviceInformationLink
            })
          )}
          title={t('Device information')}
          classes={classes}
        />
        <EnsurePermissions permissions={[PermissionCode.ChangeDeviceSettings]}>
          <NavigationRailItem
            key={settingsLink}
            link={settingsLink}
            isSelected={Boolean(
              matchPath(location.pathname, {
                path: settingsLink
              })
            )}
            title={t('Device settings')}
            classes={classes}
          />
        </EnsurePermissions>
      </List>
    </Drawer>
  )
}
