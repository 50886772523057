import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {
  LeadField,
  LeadOptionPropertiesFragment
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useIsValidEmail} from '../../../../../utils/formsValidations'
import {LeadFormCheckbox} from '../LeadFormCheckbox'
import {LeadFormCountryInput} from '../LeadFormCountryInput'
import {LeadFormTextInput} from '../LeadFormTextInput'
import {ILeadForm} from '../types'

export const BRIEF_LEAD_FORM_ID = 'brief_lead_form_id'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gap: theme.spacing(2)
  }
}))

interface IBriefLeadFormProps {
  leadOptionsObject: Partial<Record<LeadField, LeadOptionPropertiesFragment>>
  onSubmit: (leadForm: ILeadForm) => void
  className?: string
}

export const BriefLeadForm: React.FC<IBriefLeadFormProps> = ({
  leadOptionsObject,
  onSubmit,
  className
}: IBriefLeadFormProps) => {
  const classes = useStyles()
  const isValidEmail = useIsValidEmail()
  const {
    setValue,
    errors,
    register,
    control,
    watch,
    triggerValidation,
    handleSubmit
  } = useFormContext<ILeadForm>()
  return (
    <form
      id={BRIEF_LEAD_FORM_ID}
      noValidate
      autoComplete="off"
      className={cn(classes.form, className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.Name]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.Phone]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.Email]}
        margin="dense"
        validationOptions={{
          validate: isValidEmail
        }}
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.InternalNote]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
        isMultiline
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.Note]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
        isMultiline
      />
      <LeadFormCheckbox
        option={leadOptionsObject[LeadField.IsPrivacyPolicyConsentGranted]}
        control={control}
        errors={errors}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.CompanyName]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.CompanyIdNumber]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.TaxId]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.VatId]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.BillingAddressStreet]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.BillingPostalCode]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.BillingAddressTown]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormCountryInput
        option={leadOptionsObject[LeadField.BillingAddressCountry]}
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.DeliveryAddressee]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.DeliveryAddressStreet]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.DeliveryPostalCode]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormTextInput
        option={leadOptionsObject[LeadField.DeliveryAddressTown]}
        margin="dense"
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
        triggerValidation={triggerValidation}
      />
      <LeadFormCountryInput
        option={leadOptionsObject[LeadField.DeliveryAddressCountry]}
        register={register}
        setValue={setValue}
        watch={watch}
        errors={errors}
      />
    </form>
  )
}
