import EditIcon from '@mui/icons-material/Edit'
import {IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  WarehousesFilterInput,
  WarehouseState
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateWarehouseState} from '../../../../hooks/translateWarehouseState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination
} from '../../../common/DataGridTable'
import {warehouseStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {WideCenteredLayout} from '../Layout'
import {CreateWarehouseDrawer} from './CreateWarehouseDrawer'
import {useWarehouses} from './graphql'

const StateRenderer = ({state}: {state: WarehouseState}) => {
  const translateWarehouseState = useTranslateWarehouseState()
  return (
    <EntityStateChip
      colorConf={warehouseStateColors[state]}
      label={translateWarehouseState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.warehouses.edit(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

interface IWarehousesListProps {
  searchFilter: WarehousesFilterInput
}

export const WarehousesList: React.FC<IWarehousesListProps> = ({
  searchFilter
}: IWarehousesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error, refetch} = useWarehouses({
    paginationInput,
    filter: {
      ...searchFilter,
      states: [WarehouseState.Active, WarehouseState.Inactive]
    }
  })
  const {
    state: isCreateWarehouseDrawerOpen,
    setTrue: openCreateWarehouseDrawer,
    setFalse: closeCreateWarehouseDrawer
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        sortable: false
      },
      {
        headerName: t('Code'),
        field: 'code',
        sortable: false
      },
      {
        headerName: t('Division'),
        field: 'division',
        valueGetter: ({row}) => row.division.name,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: WarehouseState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t]
  )
  if (error) {
    return <Error error={error} message={t('Error while loading warehouses')} />
  }
  return (
    <>
      <WideCenteredLayout
        sx={{
          height: `calc(100% - 64px)`,
          width: '100%',
          p: 3
        }}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.warehouses.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{pinnedColumns: {left: ['name'], right: ['id']}}}
          columnVisibilityModel={{
            id: P([
              PermissionCode.ReadWarehouse,
              PermissionCode.UpdateWarehouse
            ])
          }}
          localeText={{noRowsLabel: t('No warehouses found')}}
          disableAutosize={false}
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            expand: true,
            columns: ['name', 'code', 'division', 'state']
          }}
          {...getDataGridPaginationProps(data?.warehouses.pagination)}
        />
      </WideCenteredLayout>
      {P([PermissionCode.CreateWarehouse]) && (
        <CreateFab classes={fabClasses} onClick={openCreateWarehouseDrawer} />
      )}
      <CreateWarehouseDrawer
        isOpen={isCreateWarehouseDrawerOpen}
        onClose={closeCreateWarehouseDrawer}
        refetch={refetch}
      />
    </>
  )
}
