import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Chip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {VoucherState} from '../../../../__generated__/schema'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

const useTranslateVoucherState = () => {
  const {t} = useTranslation()
  return {
    [VoucherState.Inactive]: t('Inactive vouchers'),
    [VoucherState.Active]: t('Active vouchers'),
    [VoucherState.Invalid]: t('Invalid vouchers')
  }
}

const useStyles = makeStyles(() => ({
  menuItem: {
    flexFlow: 'row-reverse'
  },
  menuIcon: {
    justifyContent: 'center'
  }
}))

interface IVoucherStateChipProps {
  selectedState: VoucherState | undefined
  setSelectedState: (state: VoucherState | undefined) => void
}

export const VoucherStateChip: React.FC<IVoucherStateChipProps> = ({
  selectedState,
  setSelectedState
}: IVoucherStateChipProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const translateVoucherState = useTranslateVoucherState()
  const handleMenuItemClick = useCallback(
    (newState: VoucherState | undefined) => () => {
      setSelectedState(newState)
      closeMenu()
    },
    [closeMenu, setSelectedState]
  )
  const classes = useStyles()
  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={openMenu}
        onClick={openMenu}
        label={
          selectedState
            ? translateVoucherState[selectedState]
            : t('All vouchers')
        }
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          label={t('All vouchers')}
          icon={!selectedState ? <CheckIcon /> : undefined}
          isSelected={!selectedState}
          className={classes.menuItem}
          iconClassName={classes.menuIcon}
          onClick={handleMenuItemClick(undefined)}
        />
        {[VoucherState.Inactive, VoucherState.Active, VoucherState.Invalid].map(
          (option) => (
            <MenuItem
              key={option}
              label={translateVoucherState[option]}
              icon={selectedState === option ? <CheckIcon /> : undefined}
              isSelected={selectedState === option}
              className={classes.menuItem}
              iconClassName={classes.menuIcon}
              onClick={handleMenuItemClick(option)}
            />
          )
        )}
      </Menu>
    </>
  )
}
