import {useQuery} from '@apollo/react-hooks'
import dayjs from 'dayjs'
import omit from 'lodash/omit'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  BusinessPartnerCategory,
  BusinessPartnerState,
  CountryCode,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  PermissionCode,
  ShowAgeClassificationCode,
  ShowGenreCode,
  ShowsFilterInput,
  ShowTypeCode
} from '../../__generated__/schema'
import {useGetShowCountries, useTranslateCountry} from '../../hooks/countries'
import {useGetAgeClassifications} from '../../hooks/getAgeClassifications'
import {useGetGenres, useTranslateGenre} from '../../hooks/showGenres'
import {useGetShowTypeCodes, useTranslateShowType} from '../../hooks/showTypes'
import {useTranslateAgeClassification} from '../../hooks/translateAgeClassification'
import {useEnsurePermissions} from '../../utils/auth'
import {LIGHTWEIGHT_BUSINESS_PARTNERS} from '../pages/admin/graphql'
import {Search, useCombineStringifySearchObjectFunctions} from './search'
import {AdvancedSearchBase} from './search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from './search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption,
  useSearchSelect
} from './search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from './search/AdvancedSearchTextRow'
import {useDateRangeSearch} from './search/daterangeSearch'
import {DATERANGE_IDS, PREDICTABLE_DATERANGE_IDS} from './search/types'

type ExtendedShowsFilter = ShowsFilterInput & {
  _globalReleaseDaterangeId?: DATERANGE_IDS
  _createdAtDaterangeId?: DATERANGE_IDS
  _updatedAtDaterangeId?: DATERANGE_IDS
}

export const DEFAULT_SHOWS_FILTER_INPUT: ShowsFilterInput = {
  hasText: ''
}

const getHasTextFromFilter = (filter: ShowsFilterInput) =>
  filter.hasText || undefined

const getTypeCodeFromFilter = (filter: ShowsFilterInput) =>
  filter.typeCode || undefined

const getCountryCodeFromFilter = (filter: ShowsFilterInput) =>
  filter.countryCode || undefined

const getGenreCodeFromFilter = (filter: ShowsFilterInput) =>
  filter.genreCode || undefined

const getAgeClassificationCodeFromFilter = (filter: ShowsFilterInput) =>
  filter.ageClassificationCode || undefined

const getBusinessPartnerFromFilter = (filter: ShowsFilterInput) =>
  filter.businessPartnerIds ? filter.businessPartnerIds[0] : undefined

const mapTypeCodeToFilter = (
  filter: ShowsFilterInput,
  typeCode: ShowTypeCode | undefined
): ShowsFilterInput => ({
  ...filter,
  typeCode
})

const mapCountryCodeToFilter = (
  filter: ShowsFilterInput,
  countryCode: CountryCode | undefined
): ShowsFilterInput => ({
  ...filter,
  countryCode
})

const mapGenreCodeToFilter = (
  filter: ShowsFilterInput,
  genreCode: ShowGenreCode | undefined
): ShowsFilterInput => ({
  ...filter,
  genreCode
})
const mapAgeClassificationCodeToFilter = (
  filter: ShowsFilterInput,
  ageClassificationCode: ShowAgeClassificationCode | undefined
): ShowsFilterInput => ({
  ...filter,
  ageClassificationCode
})

const mapHasTextToFilter = (
  filter: ShowsFilterInput,
  hasText: string
): ShowsFilterInput => ({
  ...filter,
  hasText
})

const mapBusinessPartnerIdToFilter = (
  filter: ShowsFilterInput,
  businessPartnerId?: number
): ShowsFilterInput => ({
  ...filter,
  businessPartnerIds: businessPartnerId ? [businessPartnerId] : undefined
})

export enum ShowsSearchLocation {
  Shows = 'shows',
  Library = 'library'
}

interface IShowsSearchProps {
  onFilterChange: (filter: ShowsFilterInput) => void
  location: ShowsSearchLocation
}

const stripGlobalReleaseDateFromFilter = (
  filter: ExtendedShowsFilter
): ShowsFilterInput => {
  return omit(filter, [
    'fromGlobalReleaseDate',
    'toGlobalReleaseDate',
    '_globalReleaseDaterangeId'
  ])
}

const stripCreatedAtDateFromFilter = (
  filter: ExtendedShowsFilter
): ShowsFilterInput => {
  return omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])
}

const stripUpdatedAtDateFromFilter = (
  filter: ExtendedShowsFilter
): ShowsFilterInput => {
  return omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAtDaterangeId'])
}

const stripHelperKeysFromFilter = (
  filter: ExtendedShowsFilter
): ShowsFilterInput => {
  return omit(filter, [
    '_globalReleaseDaterangeId',
    '_createdAtDaterangeId',
    '_updatedAtDaterangeId'
  ])
}

const globalReleaseDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.LAST_WEEK,
  DATERANGE_IDS.THIS_WEEK,
  DATERANGE_IDS.NEXT_WEEK,
  DATERANGE_IDS.THIS_MONTH,
  DATERANGE_IDS.NEXT_MONTH
]

const createdUpdatedDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_30_DAYS,
  DATERANGE_IDS.LAST_MONTH
]

const formatDate = (date: string) =>
  date ? dayjs(date).format('YYYY-MM-DD') : date

export const ShowsSearch: React.FC<IShowsSearchProps> = ({
  onFilterChange,
  location
}: IShowsSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    daterangeOptions: globalReleaseDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomGlobalReleaseDaterangeToFilter,
    mapDaterangeToSearchObject: mapGlobalReleaseDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedGlobalReleaseDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsGlobalReleaseDaterangeOptionActive
  } = useDateRangeSearch<ExtendedShowsFilter>({
    usedDateranges: globalReleaseDateDateRanges,
    dateRangeInputPrefix: t('Global release date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.fromGlobalReleaseDate,
      endDateISOString: o.toGlobalReleaseDate,
      id: o._globalReleaseDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      fromGlobalReleaseDate: formatDate(input.startDate),
      toGlobalReleaseDate: formatDate(input.endDate),
      _globalReleaseDaterangeId: input.id
    })
  })

  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDaterangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedShowsFilter>({
    usedDateranges: createdUpdatedDateDateRanges,
    dateRangeInputPrefix: t('Created at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom
        ? dayjs(o.createdAtFrom).toISOString()
        : undefined,
      endDateISOString: o.createdAtTo
        ? dayjs(o.createdAtTo).toISOString()
        : undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })

  const {
    daterangeOptions: updatedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDaterangeToFilter,
    mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedUpdatedAtDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedShowsFilter>({
    usedDateranges: createdUpdatedDateDateRanges,
    dateRangeInputPrefix: t('Updated at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.updatedAtFrom
        ? dayjs(o.updatedAtFrom).toISOString()
        : undefined,
      endDateISOString: o.updatedAtTo
        ? dayjs(o.updatedAtTo).toISOString()
        : undefined,
      id: o._updatedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      updatedAtFrom: input.startDate,
      updatedAtTo: input.endDate,
      _updatedAtDaterangeId: input.id
    })
  })

  const translateShowType = useTranslateShowType()
  const showTypes = useGetShowTypeCodes()

  const showTypeSelectOptions: ISelectOption<ShowTypeCode>[] = showTypes.map(
    (showTypeCode) => ({
      id: showTypeCode,
      label: translateShowType(showTypeCode)
    })
  )

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedTypeCodeFromSearchObject
  } = useSearchSelect<ShowsFilterInput, string>({
    selectInputPrefix: t('Type'),
    selectOptions: showTypeSelectOptions,
    getSelectValueFromSearchObject: getTypeCodeFromFilter
  })

  const translateCountry = useTranslateCountry()

  const showCountryCodes = useGetShowCountries()

  const countrySelectOptions: ISelectOption<CountryCode>[] =
    showCountryCodes.map((countryCode) => ({
      id: countryCode,
      label: translateCountry(countryCode)
    }))

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedCountryCodeFromSearchObject
  } = useSearchSelect<ShowsFilterInput, string>({
    selectInputPrefix: t('Country'),
    selectOptions: countrySelectOptions,
    getSelectValueFromSearchObject: getCountryCodeFromFilter
  })

  const translateGenre = useTranslateGenre()

  const showGenreCodes = useGetGenres()
  const showGenreSelectOptions: ISelectOption<ShowGenreCode>[] =
    showGenreCodes.map((genreCode) => ({
      id: genreCode,
      label: translateGenre(genreCode)
    }))

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedGenreCodeFromSearchObject
  } = useSearchSelect<ShowsFilterInput, string>({
    selectInputPrefix: t('Genre'),
    selectOptions: showGenreSelectOptions,
    getSelectValueFromSearchObject: getGenreCodeFromFilter
  })

  const showAgeClassificationCodes = useGetAgeClassifications()
  const translateAgeClassification = useTranslateAgeClassification()

  const showAgeClassificationOptions: ISelectOption<ShowAgeClassificationCode>[] =
    useMemo(
      () =>
        showAgeClassificationCodes.map((code) => ({
          id: code,
          label: translateAgeClassification(code)
        })),
      [translateAgeClassification, showAgeClassificationCodes]
    )
  const {data: businessPartnersData} = useQuery<
    LightweightBusinessPartnersQuery,
    LightweightBusinessPartnersQueryVariables
  >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
    variables: {
      paginationInput: {offset: 0, limit: 100},
      filter: {
        categories: [
          BusinessPartnerCategory.Licensing,
          BusinessPartnerCategory.MovieDistributor,
          BusinessPartnerCategory.Agency
        ],
        states: [BusinessPartnerState.Active, BusinessPartnerState.Inactive]
      }
    },
    fetchPolicy: 'network-only',
    skip:
      !P([PermissionCode.ReadBusinessPartners]) ||
      location === ShowsSearchLocation.Library
  })
  const businessPartnerSelectOptions: ISelectOption<number>[] = (
    businessPartnersData?.businessPartners.items || []
  ).map(({id, companyName}) => ({id, label: companyName}))

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedAgeClassificationCodeFromSearchObject
  } = useSearchSelect<ShowsFilterInput, string>({
    selectInputPrefix: t('Age classification'),
    selectOptions: showAgeClassificationOptions,
    getSelectValueFromSearchObject: getAgeClassificationCodeFromFilter
  })

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedBusinessPartnerFromSearchObject
  } = useSearchSelect<ShowsFilterInput, number>({
    selectInputPrefix: t('Business partner'),
    selectOptions: businessPartnerSelectOptions,
    getSelectValueFromSearchObject: getBusinessPartnerFromFilter
  })

  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<ShowsFilterInput>(
      getHasTextFromFilter,
      getStringifiedTypeCodeFromSearchObject,
      getStringifiedCountryCodeFromSearchObject,
      getStringifiedGenreCodeFromSearchObject,
      getStringifiedAgeClassificationCodeFromSearchObject,
      getStringifiedGlobalReleaseDateDateRangeFromFilter,
      getStringifiedCreatedAtDateDateRangeFromFilter,
      getStringifiedUpdatedAtDateDateRangeFromFilter,
      getStringifiedBusinessPartnerFromSearchObject
    )
  return (
    <Search<ShowsFilterInput, ExtendedShowsFilter>
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      storageKey="SHOWS_LIST"
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      placeholder={t('Search show title...')}
      defaultSearchObject={DEFAULT_SHOWS_FILTER_INPUT}
      mapInputTextToSearchObject={mapHasTextToFilter}
      onChange={onFilterChange}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchTextRow
            label={t('Has words')}
            placeholder={t('Search show words...')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchDaterangeRow
            label={t('Global release date')}
            daterangeOptions={globalReleaseDateDaterangeOptions}
            mapDaterangeToSearchObject={mapGlobalReleaseDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomGlobalReleaseDaterangeToFilter
            }
            stripDaterangeFromSearchObject={stripGlobalReleaseDateFromFilter}
            getIsDaterangeOptionActive={getIsGlobalReleaseDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of global release date'
            )}
          />
          <AdvancedSearchSelectRow
            label={t('Show type')}
            value={getTypeCodeFromFilter(advancedSearchObject)}
            options={showTypeSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapTypeCodeToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Country')}
            value={getCountryCodeFromFilter(advancedSearchObject)}
            options={countrySelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapCountryCodeToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Genre')}
            value={getGenreCodeFromFilter(advancedSearchObject)}
            options={showGenreSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapGenreCodeToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Age restrictions')}
            value={getAgeClassificationCodeFromFilter(advancedSearchObject)}
            options={showAgeClassificationOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapAgeClassificationCodeToFilter}
          />
          {P([PermissionCode.ReadBusinessPartners]) &&
            location === ShowsSearchLocation.Shows && (
              <AdvancedSearchSelectRow<ShowsFilterInput, number>
                label={t('Business partner')}
                value={
                  advancedSearchObject.businessPartnerIds
                    ? advancedSearchObject.businessPartnerIds[0]
                    : undefined
                }
                options={businessPartnerSelectOptions}
                mapSelectValueToSearchObject={mapBusinessPartnerIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
          <AdvancedSearchDaterangeRow
            label={t('Created at')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDaterangeToFilter
            }
            stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of create date'
            )}
          />
          <AdvancedSearchDaterangeRow
            label={t('Updated at')}
            daterangeOptions={updatedAtDateDaterangeOptions}
            mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomUpdatedAtDaterangeToFilter
            }
            stripDaterangeFromSearchObject={stripUpdatedAtDateFromFilter}
            getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of update date'
            )}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
