import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useMemo} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DetailEventPropertiesFragment,
  EventState
} from '../../../../../../__generated__/schema'
import {FormAutocomplete} from '../../../../../form/FormAutocomplete'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {StackableCardDivider, StackableCardWrapper} from '../../../../../visual'
import {useGetDivisions} from '../../../divisions/graphql'
import {useGetEvent} from '../../graphql'

import {
  EventBasePropertyCard,
  EventFormFieldHeader,
  FormField,
  IMainEventPropertiesFormData,
  NO_COST_CENTER_SELECTED
} from './common'

const useGetSelectItems = () => {
  const {data} = useGetDivisions()

  if (data) {
    const divisionItems = data.divisions.reduce(
      (res, d) => ({
        ...res,
        [d.id]: d.name
      }),
      {}
    )
    return {
      divisionItems
    }
  }
  return {
    divisionItems: {}
  }
}

interface IFinancialFieldsProps {
  event: DetailEventPropertiesFragment
}

const useStyles = makeStyles<Theme>(() => ({
  divisionField: {
    flex: 1
  }
}))

export const FinancialFields: React.FC<IFinancialFieldsProps> = ({
  event
}: IFinancialFieldsProps) => {
  const {t} = useTranslation()
  const {register, setValue, errors, watch} =
    useFormContext<IMainEventPropertiesFormData>()
  const {divisionItems} = useGetSelectItems()
  const {data} = useGetEvent(event.id, {
    fetchPolicy: 'cache-only'
  })

  const costCenterAutocompleteOptions = useMemo(
    () => [
      {
        value: NO_COST_CENTER_SELECTED,
        name: t('None')
      },
      ...(data?.costCenters || [])
        .filter(
          (costCenter) =>
            costCenter.isActive || costCenter.id === event.costCenterId
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((costCenter) => ({
          value: String(costCenter.id),
          name: costCenter.name
        }))
    ],
    [event.costCenterId, data?.costCenters, t]
  )
  const classes = useStyles()
  return (
    <StackableCardWrapper>
      <EventBasePropertyCard
        left={<EventFormFieldHeader required label={t('Division')} />}
        right={
          <UncontrolledFormSelect
            validationOptions={{
              required: true
            }}
            fullWidth
            className={classes.divisionField}
            register={register}
            name={FormField.DIVISION_ID}
            selectOptions={divisionItems}
            setValue={setValue}
            disabled={event.state !== EventState.Draft}
            watch={watch}
            errors={errors}
          />
        }
      />
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader required={false} label={t('Cost center')} />
        }
        right={
          <FormAutocomplete<IMainEventPropertiesFormData>
            label=""
            register={register}
            setValue={setValue}
            errors={errors}
            name={FormField.COST_CENTER_ID}
            autocompleteOptions={costCenterAutocompleteOptions}
            noOptionsText={t('No cost centers found')}
            disableClearable={false}
            watch={watch}
            fullWidth
          />
        }
      />
    </StackableCardWrapper>
  )
}
