import {ApolloClient} from 'apollo-client'
import {ApolloQueryResult} from 'apollo-client/core/types'
import {QueryOptions} from 'apollo-client/core/watchQueryOptions'
import {useCallback} from 'react'
import {
  NarrowVenueFragment,
  NarrowVenuesQuery,
  NarrowVenuesQueryVariables,
  PaginationInput
} from '../../../../../../__generated__/schema'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {GET_NARROW_VENUES, NARROW_VENUE_FRAGMENT} from './graphql'
import {isNotNull, useIdListItems} from './idListItems'

const useMapNarrowVenueToIdListItem = () =>
  useCallback(
    ({id, name, secondaryName}: NarrowVenueFragment): IIdListItem => ({
      firstRow: name,
      secondRow: secondaryName ?? undefined,
      id
    }),
    []
  )

const getDataFromDb = ({
  client,
  options
}: {
  options: Omit<QueryOptions<NarrowVenuesQueryVariables>, 'query'>
  client: ApolloClient<object>
}): Promise<ApolloQueryResult<NarrowVenuesQuery>> =>
  client.query<NarrowVenuesQuery, NarrowVenuesQueryVariables>({
    query: GET_NARROW_VENUES,
    ...options
  })

const getPagination = (data: NarrowVenuesQuery) => ({
  offset: 0,
  hasMore: false,
  totalRowsCount: data.venues.length,
  limit: data.venues.length || 1
})

const useGetIdListItemsFromCache = (
  mapTItemToIdListItem: (tItem: NarrowVenueFragment) => IIdListItem
) =>
  useCallback(
    (client: ApolloClient<object>, ids: number[]) =>
      ids
        .map((id) => {
          return client.readFragment<NarrowVenueFragment>({
            id: `Venue:${id}`,
            fragment: NARROW_VENUE_FRAGMENT,
            fragmentName: 'NarrowVenue'
          })
        })
        .filter(isNotNull)
        .map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const useMapIdListItemsFromData = (
  mapTItemToIdListItem: (tItem: NarrowVenueFragment) => IIdListItem
) =>
  useCallback(
    (data: NarrowVenuesQuery): IIdListItem[] =>
      data.venues.map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const mapVariables = ({
  hasText,
  ids
}: {
  paginationInput: PaginationInput
  hasText?: string
  ids?: number[]
}): NarrowVenuesQueryVariables => ({
  filter: {
    hasText,
    venueIds: ids
  }
})

export const useVenueListItems = () => {
  const mapTItemToIdListItem = useMapNarrowVenueToIdListItem()
  const getIdListItemsFromCache =
    useGetIdListItemsFromCache(mapTItemToIdListItem)
  const mapIdListItemsFromData = useMapIdListItemsFromData(mapTItemToIdListItem)
  const {
    getIdListItems: getVenueListItems,
    getIdListItemsByIds: getVenueListItemsByIds
  } = useIdListItems<NarrowVenuesQuery, NarrowVenuesQueryVariables>({
    getDataFromDb,
    getPagination,
    getIdListItemsFromCache,
    mapIdListItemsFromData,
    mapVariables
  })
  return {
    getVenueListItems,
    getVenueListItemsByIds
  }
}
