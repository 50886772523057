import {Box, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, RouteProps} from 'react-router-dom'
import {useSubscribeToCustomerDisplayChannel} from '../../../customerDisplayBroadcastChannel'
import {
  CustomerDisplayMessage,
  CustomerDisplayMessageType
} from '../../../customerDisplayBroadcastChannel/types'
import {AuthStatus, useUserInfo} from '../../../utils/auth'
import {Loading} from '../../visual'

export const CustomerDisplayRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const {loginStatus, effectiveClient} = useUserInfo()

  const {t} = useTranslation()
  const handleEvent = useCallback(function (
    this: BroadcastChannel,
    {data: message}: MessageEvent<CustomerDisplayMessage>
  ) {
    if (message.type === CustomerDisplayMessageType.UserLoggedIn) {
      window.location.reload()
    }
  },
  [])
  useSubscribeToCustomerDisplayChannel(handleEvent)
  if (!Component) {
    throw new Error('No component passed to CustomerDisplayRoute')
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loginStatus === AuthStatus.UNKNOWN ? (
          <Loading />
        ) : loginStatus === AuthStatus.LOGGED_IN && effectiveClient ? (
          <Component {...props} />
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography variant="h1" align="center">
              {t('The cash desk is closed')}
            </Typography>
          </Box>
        )
      }
    />
  )
}
