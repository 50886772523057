import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightProductTypesQuery,
  ProductsFilterInput
} from '../../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useGetLightweightProductTypes} from '../graphql'

export const DEFAULT_PRODUCTS_FILTER_INPUT: ProductsFilterInput = {}

const mapHasTextToFilter = (
  filter: ProductsFilterInput,
  hasText: string | undefined
): ProductsFilterInput => ({
  ...filter,
  hasText
})

const mapProductTypeToFilter = (
  filter: ProductsFilterInput,
  productTypeId: number | undefined
): ProductsFilterInput => ({
  ...filter,
  productTypeId
})

const mapInternalCodeToFilter = (
  filter: ProductsFilterInput,
  internalCode: string | undefined
): ProductsFilterInput => ({
  ...filter,
  internalCode
})

const mapPriceLookupCodeToFilter = (
  filter: ProductsFilterInput,
  priceLookupCode: string | undefined
): ProductsFilterInput => ({
  ...filter,
  priceLookupCode: priceLookupCode ? parseInt(priceLookupCode, 10) : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getHasTextFromSearchObject = (filter: ProductsFilterInput) =>
    filter.hasText || undefined
  const getProductTypeFromSearchObject = (
    filter: ProductsFilterInput,
    productTypes?: GetLightweightProductTypesQuery['productTypes']
  ) => {
    if (filter.productTypeId) {
      const productType = productTypes?.find(
        (pt) => pt.id === filter.productTypeId
      )
      return productType
        ? t('Product type: {{productType}}', {productType: productType.name})
        : t('Product type ID: {{productTypeId}}', {
            productTypeId: filter.productTypeId
          })
    }
    return undefined
  }
  const getInternalCodeFromSearchObject = (filter: ProductsFilterInput) =>
    filter.internalCode
      ? t('Internal code: {{code}}', {code: filter.internalCode})
      : undefined
  const getPriceLookupCodeFromSearchObject = (filter: ProductsFilterInput) =>
    filter.priceLookupCode
      ? t('PLU: {{code}}', {code: filter.priceLookupCode})
      : undefined
  return {
    getHasTextFromSearchObject,
    getProductTypeFromSearchObject,
    getInternalCodeFromSearchObject,
    getPriceLookupCodeFromSearchObject
  }
}

interface IProductsSearchProps {
  onFilterChange: (filter: ProductsFilterInput) => void
}

export const ProductsSearch: React.FC<IProductsSearchProps> = ({
  onFilterChange
}: IProductsSearchProps) => {
  const {t} = useTranslation()
  const {data} = useGetLightweightProductTypes()
  const productTypeSelectOptions: ISelectOption<number>[] = (
    data?.productTypes || []
  ).map((pt) => ({
    id: pt.id,
    label: pt.name
  }))
  const {
    getHasTextFromSearchObject,
    getProductTypeFromSearchObject,
    getInternalCodeFromSearchObject,
    getPriceLookupCodeFromSearchObject
  } = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<ProductsFilterInput>(
      getHasTextFromSearchObject,
      (filter) => getProductTypeFromSearchObject(filter, data?.productTypes),
      getInternalCodeFromSearchObject,
      getPriceLookupCodeFromSearchObject
    )
  return (
    <Search
      storageKey="PRODUCTS"
      placeholder={t('Search for products')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_PRODUCTS_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow
            label={t('Product type')}
            value={advancedSearchObject.productTypeId || undefined}
            options={productTypeSelectOptions}
            mapSelectValueToSearchObject={mapProductTypeToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow
            label={t('Has text')}
            placeholder={t(
              'Enter product name, receipt name or ecommerce names'
            )}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Internal code')}
            placeholder={t('Enter internal code')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapInternalCodeToFilter}
            value={advancedSearchObject.internalCode || undefined}
          />
          <AdvancedSearchTextRow<ProductsFilterInput>
            label={t('PLU')}
            placeholder={t('Enter PLU code')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapPriceLookupCodeToFilter}
            value={
              advancedSearchObject.priceLookupCode
                ? String(advancedSearchObject.priceLookupCode)
                : undefined
            }
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
