import AddIcon from '@mui/icons-material/Add'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {Reducer, useCallback, useReducer} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency} from '../../../../__generated__/schema'
import {useTranslatePrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {
  InputBlockWithoutSpacings,
  SingleSideNavigationList
} from '../../../common'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PrimaryHeader} from '../Header'

import {
  BottomBarActionsWrapper,
  CenteredLayout,
  CenteredLayoutListWrapper
} from '../Layout'
import {useCashCounterAnchors} from './cashCounterAnchors'
import {CurrencyBlock} from './CurrencyBlock'
import {cashCounterPageReducer, getDefaultStateForCurrency} from './reducer'
import {MoneyTable, VouchersTable} from './tables'
import {
  CashCounterAction,
  CashCounterActionType,
  CashCounterPage,
  CashCounterRow,
  CashCounterRowType
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  total: {
    fontWeight: 'bold'
  },
  voucherBlockHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1)
  },
  inputBlock: {
    maxWidth: 480,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const getTableTotalPrice = (coinRows: CashCounterRow[]) =>
  coinRows.reduce(
    (acc, row) =>
      new Decimal(row.denomination).mul(row.count).add(acc).toNumber(),
    0
  )

const getTableTotalCount = (coinRows: CashCounterRow[]) =>
  coinRows.reduce<number>((acc, row) => row.count + acc, 0)

interface ICashCounterProps {
  effectiveClientCurrency?: Currency
}

export const CashCounter: React.FC<ICashCounterProps> = ({
  effectiveClientCurrency
}: ICashCounterProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const anchors = useCashCounterAnchors()

  const [page, dispatch] = useReducer<
    Reducer<CashCounterPage, CashCounterAction>
  >(cashCounterPageReducer, getDefaultStateForCurrency(effectiveClientCurrency))

  const handleAddVoucherClick = useCallback(() => {
    dispatch({
      type: CashCounterActionType.AddVoucherRow
    })
  }, [])

  const banknotesTotalCount = getTableTotalCount(page.banknoteRows)
  const coinsTotalCount = getTableTotalCount(page.coinRows)
  const vouchersTotalCount = getTableTotalCount(page.voucherRows)
  const banknotesTotalPrice = getTableTotalPrice(page.banknoteRows)
  const coinsTotalPrice = getTableTotalPrice(page.coinRows)
  const vouchersTotalPrice = getTableTotalPrice(page.voucherRows)
  const translatePrice = useTranslatePrice(page.currency)
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Cash counter')} />}
    >
      <CenteredLayout
        bottomBar={
          <BottomBarActionsWrapper
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <Typography variant="body2">
              <span className={classes.total}>{t('Total: ')}</span>
              {`${t('pc', {
                count:
                  banknotesTotalCount + coinsTotalCount + vouchersTotalCount
              })}  •  ${translatePrice(
                new Decimal(banknotesTotalPrice)
                  .add(coinsTotalPrice)
                  .add(vouchersTotalPrice)
                  .toNumber()
              )}`}
            </Typography>
          </BottomBarActionsWrapper>
        }
      >
        <SingleSideNavigationList items={anchors} />
        <CenteredLayoutListWrapper>
          <div className={classes.inputBlock}>
            <CurrencyBlock
              label={anchors.currency.label}
              blockId={anchors.currency.id}
              currency={page.currency}
              dispatch={dispatch}
            />
          </div>
          <InputBlockWithoutSpacings
            className={classes.inputBlock}
            header={anchors.banknotes.label}
            blockId={anchors.banknotes.id}
          >
            <MoneyTable
              rows={page.banknoteRows}
              currency={page.currency}
              totalCount={banknotesTotalCount}
              totalPrice={banknotesTotalPrice}
              dispatch={dispatch}
              rowType={CashCounterRowType.BanknotesRow}
            />
          </InputBlockWithoutSpacings>
          <InputBlockWithoutSpacings
            className={classes.inputBlock}
            header={anchors.coins.label}
            blockId={anchors.coins.id}
          >
            <MoneyTable
              rows={page.coinRows}
              currency={page.currency}
              totalCount={coinsTotalCount}
              totalPrice={coinsTotalPrice}
              dispatch={dispatch}
              rowType={CashCounterRowType.CoinRow}
            />
          </InputBlockWithoutSpacings>
          <InputBlockWithoutSpacings
            headerClassName={classes.voucherBlockHeader}
            className={classes.inputBlock}
            header={anchors.vouchers.label}
            headerRightAction={
              <Button
                onClick={handleAddVoucherClick}
                startIcon={<AddIcon />}
                color="primary"
                variant="outlined"
              >
                {t('Add voucher')}
              </Button>
            }
            blockId={anchors.vouchers.id}
          >
            <VouchersTable
              dispatch={dispatch}
              rows={page.voucherRows}
              currency={page.currency}
              rowType={CashCounterRowType.VouchersRow}
              totalCount={vouchersTotalCount}
              totalPrice={vouchersTotalPrice}
            />
          </InputBlockWithoutSpacings>
        </CenteredLayoutListWrapper>
      </CenteredLayout>
    </PageWithHeaderTemplate>
  )
}
