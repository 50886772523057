import {Button, IconButton} from '@mui/material'
import React from 'react'
import {Tooltip} from '../Tooltip'

import {HeaderAction, HeaderActionType, IconPosition} from './types'

type IRightActionInHeaderProps = HeaderAction & {
  buttonRootClassname?: string
}

export const RightActionInHeader: React.FC<IRightActionInHeaderProps> = ({
  type,
  label,
  Icon,
  onClick,
  isDisabled,
  buttonRootClassname,
  cypressId,
  iconPosition = IconPosition.Start
}: IRightActionInHeaderProps) => {
  return type === HeaderActionType.IconButton && Icon ? (
    <Tooltip title={label}>
      <IconButton
        color="inherit"
        onClick={onClick}
        classes={{root: buttonRootClassname}}
        disabled={isDisabled}
        cypress-id={cypressId}
      >
        <Icon color="inherit" />
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      color="inherit"
      classes={{root: buttonRootClassname}}
      startIcon={
        Icon && iconPosition === IconPosition.Start && <Icon color="inherit" />
      }
      endIcon={
        Icon && iconPosition === IconPosition.End && <Icon color="inherit" />
      }
      onClick={onClick}
      disabled={isDisabled}
      cypress-id={cypressId}
    >
      {label}
    </Button>
  )
}
