import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PaymentServiceProvider} from '../../../../../__generated__/schema'
import {CancelButton} from '../../../../common/Buttons'

const getNotifyUrl = ({
  serviceProvider,
  thirdPartyPaymentId
}: {
  serviceProvider: PaymentServiceProvider
  thirdPartyPaymentId: string
}) => {
  const origin = window.location.origin
  switch (serviceProvider) {
    case PaymentServiceProvider.Gopay:
      return `${origin}/gopay/notify?id=${thirdPartyPaymentId}`
    case PaymentServiceProvider.Besteron:
      return `${origin}/besteron/payment/notify?transactionId=${thirdPartyPaymentId}`
    default:
      return origin
  }
}

const HiddenIframe = styled('iframe')`
  display: none;
`

interface IPaymentStatusDialogProps {
  data: {
    serviceProvider: PaymentServiceProvider
    thirdPartyPaymentId: string
  } | null
  onClose: () => void
  refetchCart?: () => Promise<object>
}

export const PaymentStatusDialog: React.FC<IPaymentStatusDialogProps> = ({
  data,
  onClose,
  refetchCart
}: IPaymentStatusDialogProps) => {
  const {t} = useTranslation()
  const [message, setMessage] = useState<string | null>(null)
  const handleIframeLoad = useCallback(() => {
    setMessage(t<string>('Payment status has been checked.'))
  }, [t])
  const handleIframeError = useCallback(() => {
    setMessage(t<string>('There was an error while checking payment status.'))
  }, [t])
  const handleClose = useCallback(async () => {
    if (refetchCart) {
      await refetchCart()
    }
    setMessage(null)
    onClose()
  }, [onClose, refetchCart])
  return data ? (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>{t('Checking payment status')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message || t('Waiting for response...')}
        </DialogContentText>
        <HiddenIframe
          src={getNotifyUrl(data)}
          onLoad={handleIframeLoad}
          onError={handleIframeError}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose}>{t('Got it')}</CancelButton>
      </DialogActions>
    </Dialog>
  ) : null
}
