import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Division} from '../../../../__generated__/schema'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {IWarehouseForm, WarehouseFormField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IWarehouseFormProps {
  formId: string
  onSubmit: (data: IWarehouseForm) => Promise<void>
  divisions: Pick<Division, 'id' | 'name'>[]
  defaultValues?: Partial<IWarehouseForm>
  isDivisionInputDisabled?: boolean
}

export const WarehouseForm: React.FC<IWarehouseFormProps> = ({
  formId,
  onSubmit,
  divisions,
  defaultValues,
  isDivisionInputDisabled
}: IWarehouseFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IWarehouseForm>({defaultValues, mode: 'onChange'})
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWarehouseForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WarehouseFormField.Name}
            key={WarehouseFormField.Name}
            label={t('Name')}
            validationOptions={{
              validate: isStringWithMaxLength,
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IWarehouseForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={WarehouseFormField.Code}
            key={WarehouseFormField.Code}
            label={t('Code')}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IWarehouseForm>
            selectOptions={divisions.reduce(
              (acc, {id, name}) => ({
                ...acc,
                [String(id)]: name
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={WarehouseFormField.DivisionId}
            key={WarehouseFormField.DivisionId}
            label={t('Division')}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
            hasNoneSelectOption
            disabled={isDivisionInputDisabled}
          />
        ]}
      />
    </StyledForm>
  )
}
