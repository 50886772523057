import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import {Divider, Grid, Theme} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {SyntheticEvent, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import Select, {
  components,
  IndicatorProps,
  OptionTypeBase,
  StylesConfig,
  ValueType
} from 'react-select'
import {NoticeProps} from 'react-select/src/components/Menu'
import {MultiValueRemoveProps} from 'react-select/src/components/MultiValue'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'

const useStyles = makeStyles<Theme>((theme) => ({
  multiValueRemove: {
    marginRight: -theme.spacing(0.5),
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.26)',
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.4)'
    }
  },
  clearIndicatorWrapper: {
    padding: theme.spacing(1),
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  clearIndicator: {
    color: 'rgb(117, 117, 117)',
    fontSize: 16
  },
  indicatorSeparator: {
    margin: theme.spacing(0, 1),
    height: '75%'
  },
  dropDownIndicator: {
    color: 'rgb(117, 117, 117)',
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

// Needed to use MUI dropdown icon
const DropdownIndicator: React.FC = () => {
  const classes = useStyles()
  return <ArrowDropDownIcon className={classes.dropDownIndicator} />
}

// Needed to use MUI close icon
const ClearIndicator: React.FC<IndicatorProps<any>> = (
  props: IndicatorProps<any>
) => {
  const classes = useStyles()
  const {innerProps} = props
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.clearIndicatorWrapper}
      {...innerProps}
    >
      <CloseIcon className={classes.clearIndicator} />
    </Grid>
  )
}

// Needed for translated message
const NoOptionsMessage: React.FC<NoticeProps<any>> = (
  props: NoticeProps<any>
) => {
  const {t} = useTranslation()
  return (
    <components.NoOptionsMessage {...props}>
      {t('No options')}
    </components.NoOptionsMessage>
  )
}

// Needed to use MUI cancel icon
const MultiValueRemove: React.FC<MultiValueRemoveProps<any>> = (
  props: MultiValueRemoveProps<any>
) => {
  const classes = useStyles()
  return (
    <components.MultiValueRemove {...props}>
      <CancelIcon className={classes.multiValueRemove} />
    </components.MultiValueRemove>
  )
}

// Default separator doesn't show with changes to custom dropdown/clear
const IndicatorSeparator: React.FC = () => {
  const classes = useStyles()
  return (
    <Divider orientation="vertical" className={classes.indicatorSeparator} />
  )
}

// Custom styling for react-select to look like MUI
const styles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.08)' : '',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`,
    border: 'none',
    zIndex: 2
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: 16,
    minHeight: 48,
    border: state.isFocused
      ? '1px solid #6a1a9a'
      : '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgb(32, 32, 32)'
    },
    boxShadow: state.isFocused ? '0 0 0 1pt #6a1a9a' : 'none'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing'
    }
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: 16,
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    padding: 4,
    borderRadius: 16
  })
}

type Option = {label: string | React.ReactNode; value: string}
type Options = Array<Option>

export interface IMultiSelectProps {
  selectOptions: {[value: string]: React.ReactNode}
  onChange: (value: Array<string>) => void
  selectedKeys: string[]
  isSearchable: boolean
  label?: string
  disabled: boolean
}

const selectOptionsToOptions = (
  selectOptions: IMultiSelectProps['selectOptions']
): Options =>
  Object.entries(selectOptions).map(([value, label]) => ({
    value,
    label
  }))

const moveElementPosition = (
  array: Array<Option>,
  from: number,
  to: number
) => {
  const newArray = [...array]
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    newArray.splice(from, 1)[0]
  )
  return newArray
}

const SortableMultiValue = SortableElement((props: any) => {
  // this prevents the menu from being opened/closed when the user clicks on a value
  const onMouseDown = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const innerProps = {onMouseDown}
  return <components.MultiValue {...props} innerProps={innerProps} />
})

const SortableMultiSelect = SortableContainer(Select)

export const MultiSelect: React.FC<IMultiSelectProps> = ({
  selectOptions,
  onChange,
  selectedKeys,
  isSearchable,
  label,
  disabled
}: IMultiSelectProps) => {
  const {t} = useTranslation()

  const value = Array.isArray(selectedKeys)
    ? selectOptionsToOptions(
        selectedKeys.reduce(
          (defaultItems, value) => ({
            ...defaultItems,
            [value]: selectOptions[value]
          }),
          {}
        )
      )
    : selectOptionsToOptions({[selectedKeys]: selectOptions[selectedKeys]})

  const _onChange = useCallback(
    (o: ValueType<OptionTypeBase>) => {
      const newValue = Array.isArray(o) ? o.map(({value}) => value) : []
      onChange(newValue)
    },
    [onChange]
  )

  const onSortEnd = useCallback(
    ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
      const newOptions = moveElementPosition(value, oldIndex, newIndex)
      onChange(newOptions.map((option) => option.value))
    },
    [onChange, value]
  )

  return (
    <SortableMultiSelect
      // react-sortable-hoc props:
      axis="xy"
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({node}) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      maxMenuHeight={150}
      placeholder={label || t('Select options')}
      options={selectOptionsToOptions(selectOptions)}
      onChange={_onChange}
      components={{
        MultiValue: SortableMultiValue,
        NoOptionsMessage,
        MultiValueRemove,
        ClearIndicator,
        IndicatorSeparator,
        DropdownIndicator
      }}
      closeMenuOnSelect={false}
      {...{
        disabled,
        isDisabled: disabled,
        isSearchable,
        onSortEnd,
        styles,
        value
      }}
    />
  )
}
