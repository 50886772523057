import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode, ToursFilter} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateTourDrawer} from './CreateTourDrawer'
import {ToursList} from './ToursList'
import {ToursSearch} from './ToursSearch'

export const ToursPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateTourDrawerOpen,
    setTrue: openCreateTourDrawer,
    setFalse: closeCreateTourDrawer
  } = useBooleanState(false)
  const [searchFilter, setSearchFilter] = useState<ToursFilter>({})

  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Tours')}
          search={<ToursSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <ToursList
          onCreateButtonClick={openCreateTourDrawer}
          searchFilter={searchFilter}
        />
        {P([PermissionCode.CreateTour, PermissionCode.ReadClientShows]) && (
          <CreateTourDrawer
            isOpen={isCreateTourDrawerOpen}
            onClose={closeCreateTourDrawer}
          />
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
