import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {Box, Grid, ListItem, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {Dayjs} from 'dayjs'
import React, {SyntheticEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {CheckingOption} from '../../../../../../__generated__/schema'
import {Theme} from '../../../../../../theme'

import {
  BasePropertyStackableCard,
  BasePropertyStackableCardHeader,
  ITEM_HEIGHT as COMMON_ITEM_HEIGHT,
  MainLabel as CommonMainLabel,
  SecondaryLabel as CommonSecondaryLabel,
  StackableCard
} from '../../../../../visual'

export const ITEM_HEIGHT = COMMON_ITEM_HEIGHT

const useStyles = makeStyles<Theme>(() => ({
  listItem: {
    padding: 0
  },
  itemHeight: {
    minHeight: ITEM_HEIGHT,
    display: 'flex',
    alignItems: 'center'
  }
}))

export enum FormField {
  DIVISION_ID = 'divisionId',
  COST_CENTER_ID = 'costCenterId',
  VAT = 'vat',
  SHOW_ON_WEBSITE_API = 'showOnWebsiteAndApi',
  MARKETING_GROUP = 'marketingGroup',
  EVENT_CATEGORY = 'eventCategory',
  GATE_OPENS_AT = 'gateOpensAt',
  GATE_CLOSES_AT = 'gateClosedAt',
  CHECKING_OPTION = 'checkingOption',
  ORGANIZER = 'organizerNote',
  TICKET_NOTE = 'ticketNote',
  EVENT_CATEGORY_ID = 'eventCategoryId',
  MARKETING_LABEL_ID = 'marketingLabelId',
  BUSINESS_PARTNER_ID = 'businessPartnerId',
  FEE_PERCENTAGE_OF_SOLD_TICKETS = 'feePercentageOfSoldTickets',
  FEE_FIXED_AMOUNT_PER_SOLD_TICKET = 'feeFixedAmountPerSoldTicket',
  FEE_FIXED_AMOUNT_PER_EVENT = 'feeFixedAmountPerEvent',
  FEE_MINIMUM_GUARANTEE = 'feeMinimumGuarantee',
  THIRD_PARTY_PURCHASE_URL = 'thirdPartyPurchaseURL'
}

export interface IMainEventPropertiesFormData {
  [FormField.DIVISION_ID]: string
  [FormField.COST_CENTER_ID]: string
  [FormField.SHOW_ON_WEBSITE_API]: boolean
  [FormField.GATE_OPENS_AT]: Dayjs
  [FormField.GATE_CLOSES_AT]: Dayjs
  [FormField.CHECKING_OPTION]: CheckingOption
  [FormField.ORGANIZER]: string
  [FormField.TICKET_NOTE]: string
  [FormField.EVENT_CATEGORY_ID]: string
  [FormField.MARKETING_LABEL_ID]: string
  [FormField.BUSINESS_PARTNER_ID]?: string
  [FormField.FEE_PERCENTAGE_OF_SOLD_TICKETS]?: string
  [FormField.FEE_FIXED_AMOUNT_PER_SOLD_TICKET]?: string
  [FormField.FEE_FIXED_AMOUNT_PER_EVENT]?: string
  [FormField.FEE_MINIMUM_GUARANTEE]?: string
  [FormField.THIRD_PARTY_PURCHASE_URL]?: string
}

export const NO_COST_CENTER_SELECTED = 'NO_COST_CENTER_SELECTED'
export const NO_EVENT_CATEGORY_SELECTED = 'NO_EVENT_CATEGORY_SELECTED'
export const NO_MARKETING_LABEL_SELECTED = 'NO_MARKETING_LABEL_SELECTED'
export const NO_BUSINESS_PARTNER_SELECTED = 'NO_BUSINESS_PARTNER_SELECTED'

export const MainLabel = CommonMainLabel

export const SecondaryLabel = CommonSecondaryLabel

export const EventBasePropertyHeader = BasePropertyStackableCardHeader

export const EventBasePropertyCard = BasePropertyStackableCard

interface INavigatableListItemProps {
  mainLabel: string
  secondaryLabel?: string
  onClick?: (event: SyntheticEvent) => void
}

export const NavigatableListItem: React.FC<INavigatableListItemProps> = ({
  mainLabel,
  secondaryLabel,
  onClick
}: INavigatableListItemProps) => {
  const classes = useStyles()
  return (
    <ListItem
      button
      onClick={onClick}
      className={cn(classes.itemHeight, classes.listItem)}
    >
      <StackableCard>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.listItemContainerWrapper}
        >
          <Grid item>
            <Grid container direction="column" justifyContent="center">
              <Typography variant="caption" component="div">
                <Box fontWeight="fontWeightBold">{mainLabel}</Box>
              </Typography>
              {secondaryLabel && (
                <Typography variant="caption" color="textSecondary">
                  {secondaryLabel}
                </Typography>
              )}
            </Grid>
          </Grid>
          <ArrowRightIcon />
        </Grid>
      </StackableCard>
    </ListItem>
  )
}

interface IEventFormFieldHeaderProps {
  required: boolean
  label: string
}

export const EventFormFieldHeader: React.FC<IEventFormFieldHeaderProps> = ({
  required,
  label
}: IEventFormFieldHeaderProps) => {
  const {t} = useTranslation()
  return (
    <EventBasePropertyHeader
      mainLabel={label}
      secondaryLabel={required ? t('*Required') : t('Optional')}
    />
  )
}
