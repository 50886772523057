import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AssignProductGroupToProductMutation,
  AssignProductGroupToProductMutationVariables,
  CreateProductBarcodeMutation,
  CreateProductBarcodeMutationVariables,
  CreateProductMutation,
  CreateProductMutationVariables,
  CreateProductPricingMutation,
  CreateProductPricingMutationVariables,
  CreateWarehouseProductMutation,
  CreateWarehouseProductMutationVariables,
  DeleteProductBarcodeMutation,
  DeleteProductBarcodeMutationVariables,
  DeleteProductCoverImageMutation,
  DeleteProductCoverImageMutationVariables,
  DeleteProductPricingMutation,
  DeleteProductPricingMutationVariables,
  GetProductAvailabilityQuery,
  GetProductAvailabilityQueryVariables,
  GetProductPricingsQuery,
  GetProductPricingsQueryVariables,
  ProductQuery,
  ProductQueryVariables,
  ProductsQuery,
  ProductsQueryVariables,
  RemoveProductGroupFormProductMutation,
  RemoveProductGroupFormProductMutationVariables,
  UpdateProductMutation,
  UpdateProductMutationVariables,
  UpdateWarehouseProductMutation,
  UpdateWarehouseProductMutationVariables,
  UploadProductCoverImageMutation,
  UploadProductCoverImageMutationVariables,
  UpsertProductAvailabilityMutation,
  UpsertProductAvailabilityMutationVariables,
  WarehouseProductQuery,
  WarehouseProductQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {TRANSLATED_LOCALES_FRAGMENT, USER_FIELDS} from '../graphql'

const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    id
    name
    state
    unit
    mode
    receiptName
    coverImageURL
    internalDescription
    productType {
      id
      name
    }
    priceLookupCode
    internalCode
    productAvailability {
      id
      division {
        id
        name
      }
      isAvailableOnRetailChannel
      isAvailableOnECommerceChannel
    }
    eCommerceNames {
      ...TranslatedLocales
    }
    eCommerceDescriptions {
      ...TranslatedLocales
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const GET_PRODUCTS = gql`
  ${PAGINATION_FRAGMENT}
  query products(
    $filter: ProductsFilterInput
    $paginationInput: PaginationInput!
  ) {
    products(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        state
        unit
        mode
        productType {
          id
          name
        }
        priceLookupCode
        internalCode
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useGetProducts = (variables: ProductsQueryVariables) =>
  useQuery<ProductsQuery, ProductsQueryVariables>(GET_PRODUCTS, {
    variables,
    fetchPolicy: 'network-only'
  })

const PRODUCT_GROUP_FIELDS = gql`
  fragment ProductGroupFields on ProductGroup {
    id
    name
    state
    internalDescription
    color
    icon
  }
`

const GET_PRODUCT = gql`
  ${USER_FIELDS}
  ${PRODUCT_GROUP_FIELDS}
  query product($id: PositiveInt!) {
    product(id: $id) {
      ...ProductFields
      productBarcodes {
        id
        code
        description
        createdAt
        createdBy {
          ...UserFields
        }
      }
      productGroups {
        ...ProductGroupFields
      }
      warehouseProducts {
        id
        stock
        isNegativeStockEnabled
        minStockLevel
        optimalStockLevel
        division {
          id
          name
        }
        warehouse {
          id
        }
      }
      pricings {
        division {
          id
          name
        }
        activePricing {
          id
          retailPrice
          retailVatRate
          eCommercePrice
          eCommerceVatRate
          startsAt
        }
        futurePricing {
          id
          retailPrice
          retailVatRate
          eCommercePrice
          eCommerceVatRate
          startsAt
        }
      }
    }
  }
  ${PRODUCT_FIELDS}
`

export const useGetProduct = (id: number) =>
  useQuery<ProductQuery, ProductQueryVariables>(GET_PRODUCT, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`

export const useCreateProduct = () => {
  const [createProduct] =
    useMutation<CreateProductMutation, CreateProductMutationVariables>(
      CREATE_PRODUCT
    )
  return useCallback(
    (variables: CreateProductMutationVariables) => createProduct({variables}),
    [createProduct]
  )
}

const GET_PRODUCT_AVAILABILITY = gql`
  query getProductAvailability($id: PositiveInt!) {
    product(id: $id) {
      id
      productAvailability {
        id
        division {
          id
          name
        }
        isAvailableOnRetailChannel
        isAvailableOnECommerceChannel
      }
    }
  }
`

export const useGetProductAvailability = (id: number) =>
  useQuery<GetProductAvailabilityQuery, GetProductAvailabilityQueryVariables>(
    GET_PRODUCT_AVAILABILITY,
    {
      variables: {id},
      fetchPolicy: 'network-only'
    }
  )

const UPSERT_PRODUCT_AVAILABILITY = gql`
  mutation upsertProductAvailability(
    $id: PositiveInt!
    $productAvailabilityInputs: [ProductAvailabilityInput!]!
  ) {
    upsertProductAvailability(
      id: $id
      productAvailabilityInputs: $productAvailabilityInputs
    ) {
      id
      productAvailability {
        id
        division {
          id
          name
        }
        isAvailableOnRetailChannel
        isAvailableOnECommerceChannel
      }
    }
  }
`

export const useUpsertProductAvailability = () => {
  const [upsertProductAvailability] = useMutation<
    UpsertProductAvailabilityMutation,
    UpsertProductAvailabilityMutationVariables
  >(UPSERT_PRODUCT_AVAILABILITY)
  return useCallback(
    (variables: UpsertProductAvailabilityMutationVariables) =>
      upsertProductAvailability({variables}),
    [upsertProductAvailability]
  )
}

const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: PositiveInt!, $input: UpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      ...ProductFields
    }
  }
  ${PRODUCT_FIELDS}
`

export const useUpdateProduct = () => {
  const [updateProduct] =
    useMutation<UpdateProductMutation, UpdateProductMutationVariables>(
      UPDATE_PRODUCT
    )
  return useCallback(
    (variables: UpdateProductMutationVariables) => updateProduct({variables}),
    [updateProduct]
  )
}

const GET_PRODUCT_PRICINGS = gql`
  ${USER_FIELDS}
  query getProductPricings($id: PositiveInt!) {
    product(id: $id) {
      id
      pricings {
        division {
          id
          name
        }
        allPricings {
          id
          startsAt
          retailPrice
          retailVatRate
          eCommercePrice
          eCommerceVatRate
          createdBy {
            ...UserFields
          }
          createdAt
        }
      }
    }
  }
`

export const useGetProductPricings = (id: number) =>
  useQuery<GetProductPricingsQuery, GetProductPricingsQueryVariables>(
    GET_PRODUCT_PRICINGS,
    {
      variables: {id},
      fetchPolicy: 'network-only'
    }
  )

const CREATE_PRODUCT_BARCODE = gql`
  mutation createProductBarcode($input: CreateProductBarcodeInput!) {
    createProductBarcode(input: $input) {
      id
      code
      description
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`

export const useCreateProductBarcode = () => {
  const [createProductBarcode] = useMutation<
    CreateProductBarcodeMutation,
    CreateProductBarcodeMutationVariables
  >(CREATE_PRODUCT_BARCODE)
  return useCallback(
    (variables: CreateProductBarcodeMutationVariables) =>
      createProductBarcode({
        variables,
        refetchQueries: [
          {
            query: GET_PRODUCT,
            variables: {
              id: variables.input.productId
            }
          }
        ]
      }),
    [createProductBarcode]
  )
}

const DELETE_PRODUCT_BARCODE = gql`
  mutation deleteProductBarcode($id: PositiveInt!) {
    deleteProductBarcode(id: $id) {
      id
    }
  }
`

export const useDeleteProductBarcode = () => {
  const [deleteProductBarcode] = useMutation<
    DeleteProductBarcodeMutation,
    DeleteProductBarcodeMutationVariables
  >(DELETE_PRODUCT_BARCODE)
  return useCallback(
    (variables: DeleteProductBarcodeMutationVariables) =>
      deleteProductBarcode({
        variables
      }),
    [deleteProductBarcode]
  )
}

const CREATE_PRODUCT_PRICING = gql`
  mutation createProductPricing(
    $id: PositiveInt!
    $input: ProductPricingInput!
  ) {
    createProductPricing(id: $id, input: $input) {
      id
    }
  }
`

export const useCreateProductPricing = () => {
  const [createProductPricing] = useMutation<
    CreateProductPricingMutation,
    CreateProductPricingMutationVariables
  >(CREATE_PRODUCT_PRICING)
  return useCallback(
    (variables: CreateProductPricingMutationVariables) =>
      createProductPricing({
        variables,
        refetchQueries: [
          {
            query: GET_PRODUCT_PRICINGS,
            variables: {id: variables.id}
          }
        ]
      }),
    [createProductPricing]
  )
}

const DELETE_PRODUCT_PRICING = gql`
  mutation deleteProductPricing($id: PositiveInt!) {
    deleteProductPricing(id: $id) {
      id
    }
  }
`

export const useDeleteProductPricing = () => {
  const [deleteProductPricing] = useMutation<
    DeleteProductPricingMutation,
    DeleteProductPricingMutationVariables
  >(DELETE_PRODUCT_PRICING)
  return useCallback(
    (id: number) =>
      deleteProductPricing({
        variables: {id}
      }),
    [deleteProductPricing]
  )
}

const ASSIGN_PRODUCT_GROUP_TO_PRODUCT = gql`
  ${PRODUCT_GROUP_FIELDS}
  mutation AssignProductGroupToProduct(
    $productId: PositiveInt!
    $productGroupId: PositiveInt!
  ) {
    assignProductGroupToProduct(
      productId: $productId
      productGroupId: $productGroupId
    ) {
      id
      productGroups {
        ...ProductGroupFields
      }
    }
  }
`

export const useAssignProductGroupToProduct = () => {
  const [assignProductGroupToProduct] = useMutation<
    AssignProductGroupToProductMutation,
    AssignProductGroupToProductMutationVariables
  >(ASSIGN_PRODUCT_GROUP_TO_PRODUCT)
  return useCallback(
    (variables: AssignProductGroupToProductMutationVariables) =>
      assignProductGroupToProduct({variables}),
    [assignProductGroupToProduct]
  )
}

const REMOVE_PRODUCT_GROUP_FROM_PRODUCT = gql`
  ${PRODUCT_GROUP_FIELDS}
  mutation RemoveProductGroupFormProduct(
    $productId: PositiveInt!
    $productGroupId: PositiveInt!
  ) {
    removeProductGroupFromProduct(
      productId: $productId
      productGroupId: $productGroupId
    ) {
      id
      productGroups {
        ...ProductGroupFields
      }
    }
  }
`

export const useRemoveProductGroupFormProduct = () => {
  const [removeProductGroupFormProduct] = useMutation<
    RemoveProductGroupFormProductMutation,
    RemoveProductGroupFormProductMutationVariables
  >(REMOVE_PRODUCT_GROUP_FROM_PRODUCT)
  return useCallback(
    (variables: RemoveProductGroupFormProductMutationVariables) =>
      removeProductGroupFormProduct({variables}),
    [removeProductGroupFormProduct]
  )
}

const UPLOAD_PRODUCT_COVER_IMAGE = gql`
  ${PRODUCT_FIELDS}
  mutation UploadProductCoverImage($id: PositiveInt!, $file: Upload!) {
    uploadProductCoverImage(id: $id, image: $file) {
      ...ProductFields
    }
  }
`

export const useUploadProductCoverImage = () => {
  const [uploadProductCoverImage] = useMutation<
    UploadProductCoverImageMutation,
    UploadProductCoverImageMutationVariables
  >(UPLOAD_PRODUCT_COVER_IMAGE)
  return useCallback(
    (variables: UploadProductCoverImageMutationVariables) =>
      uploadProductCoverImage({variables}),
    [uploadProductCoverImage]
  )
}

const DELETE_PRODUCT_COVER_IMAGE = gql`
  ${PRODUCT_FIELDS}
  mutation DeleteProductCoverImage($id: PositiveInt!) {
    deleteProductCoverImage(id: $id) {
      ...ProductFields
    }
  }
`

export const useDeleteProductCoverImage = () => {
  const [deleteProductCoverImage] = useMutation<
    DeleteProductCoverImageMutation,
    DeleteProductCoverImageMutationVariables
  >(DELETE_PRODUCT_COVER_IMAGE)
  return useCallback(
    (variables: DeleteProductCoverImageMutationVariables) =>
      deleteProductCoverImage({variables}),
    [deleteProductCoverImage]
  )
}

const CREATE_WAREHOUSE_PRODUCT = gql`
  mutation CreateWarehouseProduct($input: CreateWarehouseProductInput!) {
    createWarehouseProduct(input: $input) {
      id
    }
  }
`

export const useCreateWarehouseProduct = () => {
  const [createWarehouseProduct] = useMutation<
    CreateWarehouseProductMutation,
    CreateWarehouseProductMutationVariables
  >(CREATE_WAREHOUSE_PRODUCT)
  return useCallback(
    (variables: CreateWarehouseProductMutationVariables) =>
      createWarehouseProduct({
        variables,
        refetchQueries: [
          {
            query: GET_PRODUCT,
            variables: {id: variables.input.productId}
          }
        ]
      }),
    [createWarehouseProduct]
  )
}

const WAREHOUSE_PRODUCT = gql`
  query WarehouseProduct($id: PositiveInt!) {
    warehouseProduct(id: $id) {
      id
      minStockLevel
      optimalStockLevel
      isNegativeStockEnabled
    }
  }
`

export const useWarehouseProduct = (id: number, skip?: boolean) =>
  useQuery<WarehouseProductQuery, WarehouseProductQueryVariables>(
    WAREHOUSE_PRODUCT,
    {variables: {id}, fetchPolicy: 'network-only', skip}
  )

const UPDATE_WAREHOUSE_PRODUCT = gql`
  mutation UpdateWarehouseProduct(
    $id: PositiveInt!
    $input: UpdateWarehouseProductInput!
  ) {
    updateWarehouseProduct(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateWarehouseProduct = () => {
  const [updateWarehouseProduct] = useMutation<
    UpdateWarehouseProductMutation,
    UpdateWarehouseProductMutationVariables
  >(UPDATE_WAREHOUSE_PRODUCT)
  return useCallback(
    (variables: UpdateWarehouseProductMutationVariables, productId?: number) =>
      updateWarehouseProduct({
        variables,
        ...(productId
          ? {
              refetchQueries: [
                {
                  query: GET_PRODUCT,
                  variables: {id: productId}
                }
              ]
            }
          : {})
      }),
    [updateWarehouseProduct]
  )
}
