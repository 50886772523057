import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {routeTo} from '../../../../utils/routes'
import {useDiscardCart, usePostponeCartExpiration} from './cart/graphql'
import {useCurrentCart} from './CurrentCartContext'

const PERIOD_BETWEEN_OPENING_CART_EXPIRES_SOON_DIALOG_AND_CART_EXPIRATION_IN_SECONDS = 90

interface ICartExpiresSoonDialogProps {
  expiresAt: string
  cartId: number
}

export const CartExpiresSoonDialog: React.FC<ICartExpiresSoonDialogProps> = ({
  expiresAt,
  cartId
}: ICartExpiresSoonDialogProps) => {
  const {resetCurrentCart, updateCurrentCart} = useCurrentCart()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const opensAt = useMemo(
    () =>
      dayjs(expiresAt)
        .add(
          -PERIOD_BETWEEN_OPENING_CART_EXPIRES_SOON_DIALOG_AND_CART_EXPIRATION_IN_SECONDS,
          's'
        )
        .toISOString(),
    [expiresAt]
  )
  const {
    state: isOpen,
    setTrue: openDialog,
    setFalse: closeDialog
  } = useBooleanState(dayjs().isAfter(opensAt))

  const [remainingSeconds, setRemainingSeconds] = useState<number>(
    isOpen
      ? -dayjs().diff(expiresAt, 's')
      : PERIOD_BETWEEN_OPENING_CART_EXPIRES_SOON_DIALOG_AND_CART_EXPIRATION_IN_SECONDS
  )

  useEffect(() => {
    let timeout: number
    if (!isOpen) {
      const timeToOpenDialog = new Date(opensAt).getTime() - Date.now()
      timeout = window.setTimeout(openDialog, timeToOpenDialog)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isOpen, openDialog, opensAt])

  useEffect(() => {
    let interval: number
    if (isOpen) {
      interval = window.setInterval(() => {
        setRemainingSeconds(-dayjs().diff(expiresAt, 's') + 1)
      }, 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [expiresAt, isOpen])

  const discardCart = useDiscardCart()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const history = useHistory()
  const {formatTime} = useDateTimeFormatters()
  const handleDiscardButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await discardCart(cartId)
      resetCurrentCart()
      history.replace(routeTo.admin.cashDesk.index())
      addInfoNotification(t('Cart discarded'))
    } catch (e) {
      defaultErrorHandler(e, t('Discarding cart failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    cartId,
    defaultErrorHandler,
    discardCart,
    history,
    resetCurrentCart,
    setShowBackdrop,
    t
  ])
  const postponeCartExpiration = usePostponeCartExpiration()
  const handlePostponeButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await postponeCartExpiration(cartId)
      if (data) {
        updateCurrentCart()
        closeDialog()
        setRemainingSeconds(
          PERIOD_BETWEEN_OPENING_CART_EXPIRES_SOON_DIALOG_AND_CART_EXPIRATION_IN_SECONDS
        )
        addInfoNotification(
          t('Cart expiration postponed to {{time}}', {
            time: formatTime(new Date(data.postponeCartExpiration.expiresAt))
          })
        )
      }
    } catch (e) {
      defaultErrorHandler(e, t('Postponing cart expiration failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    cartId,
    closeDialog,
    defaultErrorHandler,
    formatTime,
    postponeCartExpiration,
    setShowBackdrop,
    t,
    updateCurrentCart
  ])

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle>{t('Cart expires soon')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Your cart expires in {{count}} second.', {
            count: remainingSeconds
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {P([PermissionCode.DiscardCart]) && (
          <Button color="primary" onClick={handleDiscardButtonClick}>
            {t('Discard cart')}
          </Button>
        )}
        {P([PermissionCode.PostponeCartExpiration]) && (
          <Button color="primary" onClick={handlePostponeButtonClick}>
            {t('Postpone expiration')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface ICartExpiredDialogProps {
  currentCart: CartPropertiesFragment | null
}

export const CartExpiredDialog: React.FC<ICartExpiredDialogProps> = ({
  currentCart
}: ICartExpiredDialogProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {
    state: isOpen,
    setFalse: closeDialog,
    setTrue: openDialog
  } = useBooleanState(
    Boolean(
      currentCart &&
        [CartState.Draft, CartState.Expired].includes(currentCart?.state) &&
        !dayjs(currentCart?.expiresAt).isAfter(new Date())
    )
  )
  const {resetCurrentCart} = useCurrentCart()

  const handleCartExpired = useCallback(() => {
    openDialog()
    resetCurrentCart()
    history.replace(routeTo.admin.cashDesk.index())
  }, [history, openDialog, resetCurrentCart])

  useEffect(() => {
    if (
      currentCart &&
      [CartState.Draft, CartState.Expired].includes(currentCart.state) &&
      !dayjs(currentCart?.expiresAt).isAfter(new Date())
    ) {
      handleCartExpired()
    }
  }, [currentCart, handleCartExpired])

  useEffect(() => {
    let timeout: number
    if (
      currentCart?.state === CartState.Draft &&
      dayjs(currentCart?.expiresAt).isAfter(new Date())
    ) {
      const availableTime =
        new Date(currentCart?.expiresAt).getTime() - Date.now()
      timeout = window.setTimeout(() => {
        handleCartExpired()
      }, availableTime)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [currentCart?.expiresAt, currentCart?.state, handleCartExpired])
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle>{t('Cart expired')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'Your cart has not been completed within the specified time limit and expired.'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          {t('Got it')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface ICartExpirationControllerProps {
  children: React.ReactNode
}

export const CartExpirationController: React.FC<ICartExpirationControllerProps> =
  ({children}: ICartExpirationControllerProps) => {
    const {currentCart} = useCurrentCart()

    return (
      <>
        {children}
        {currentCart && currentCart.state === CartState.Draft && (
          <CartExpiresSoonDialog
            expiresAt={currentCart.expiresAt}
            cartId={currentCart.id}
          />
        )}
        <CartExpiredDialog currentCart={currentCart} />
      </>
    )
  }
