import {useLazyQuery} from '@apollo/react-hooks'
import AddIcon from '@mui/icons-material/Add'
import {Button, Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Joi from 'joi'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {
  ClaimState,
  PermissionCode,
  SaleQuery,
  SaleQueryVariables
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useClaimDetailParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {
  DrawerSection,
  DrawerTemplateHeader,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {AddItemsDialog} from '../../claims/AddItemsDialog'
import {
  GET_SALE,
  useApproveClaim,
  useClaim,
  useDeleteClaim
} from '../../claims/graphql'
import {LeadSectionGrid} from '../cartPreviewDrawer/LeadSectionGrid'
import {Footer} from './Footer'
import {General} from './General'
import {useGetVoucherDetail} from './graphql'
import {ItemsSection} from './ItemsSection'
import {PaymentSection} from './PaymentSection'
import {VoucherDialog} from './VoucherDialog'

const refundVoucherSchema = Joi.object<{voucherCode: string}>({
  voucherCode: Joi.string().optional()
}).options({stripUnknown: true})

const validateSchema = (
  voucherCode: string
): Joi.ValidationResult<{voucherCode: string}> =>
  refundVoucherSchema.validate({voucherCode})

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      general: {id: string; label: string}
      payment: {id: string; label: string}
      items: {id: string; label: string}
      lead: {id: string; label: string}
    } => ({
      general: {
        id: 'general',
        label: t('General')
      },
      payment: {
        id: 'payment',
        label: t('Payment')
      },
      items: {
        id: 'items',
        label: t('Items')
      },
      lead: {
        id: 'lead',
        label: t('Lead')
      }
    }),
    [t]
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  section: {
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

interface IClaimDetailDrawerProps {
  onExited: () => void
}

export const ClaimDetailDrawer: React.FC<IClaimDetailDrawerProps> = ({
  onExited
}: IClaimDetailDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {claimId} = useClaimDetailParams()
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const navigationItems = useNavigationItems()
  const drawerClasses = useDrawerWithSideNavigationStyles()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const validationResult = validateSchema(params.get('voucherCode') || '')
  const {voucherCode} = validationResult.value || {}
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {
    state: isAddItemsDialogOpen,
    setTrue: openAddItemsDialog,
    setFalse: closeAddItemsDialog
  } = useBooleanState(false)
  const {
    state: isApproveDialogOpen,
    setTrue: openApproveDialog,
    setFalse: closeApproveDialog
  } = useBooleanState(false)
  const {
    state: isDeleteDialogOpen,
    setTrue: openDeleteDialog,
    setFalse: closeDeleteDialog
  } = useBooleanState(false)
  const {
    state: isVoucherDialogOpen,
    setTrue: openVoucherDialog,
    setFalse: closeVoucherDialog
  } = useBooleanState(false)
  const {data: voucherData, error: voucherError} = useGetVoucherDetail(
    {
      canReadPinCode: P([PermissionCode.ReadVoucherPinCode]),
      code: voucherCode
    },
    !voucherCode || !!validationResult.error
  )
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  useEffect(() => {
    if (
      voucherCode &&
      !validationResult.error &&
      voucherData &&
      !voucherError
    ) {
      openVoucherDialog()
    }
  }, [
    openVoucherDialog,
    validationResult.error,
    voucherCode,
    voucherData,
    voucherError
  ])
  const {data, loading, error} = useClaim(claimId)
  const [getSale, {data: saleData, loading: isLoadingGetSale}] = useLazyQuery<
    SaleQuery,
    SaleQueryVariables
  >(GET_SALE, {
    fetchPolicy: 'network-only',
    onCompleted: () => openAddItemsDialog(),
    onError: (e) =>
      defaultErrorHandler(e, t('Error while loading list of items'))
  })
  const approveClaim = useApproveClaim()
  const deleteClaim = useDeleteClaim()

  const handleOnAddButtonClick = useCallback(() => {
    if (data?.claim.sale) {
      getSale({variables: {id: data.claim.sale.id}})
    }
  }, [data?.claim.sale, getSale])

  const handleRefundButtonClick = useCallback(() => {
    history.replace(routeTo.admin.claims.refund(claimId))
  }, [claimId, history])

  useEffect(() => {
    if (isLoadingGetSale) {
      setShowBackdrop(true)
    } else {
      setShowBackdrop(false)
    }
  }, [isLoadingGetSale, setShowBackdrop])

  const handleOnApproveConfirmationButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await approveClaim({id: claimId})
    } catch (e) {
      defaultErrorHandler(e, t('Error while approving claim'))
    } finally {
      setShowBackdrop(false)
      closeApproveDialog()
    }
  }, [
    approveClaim,
    claimId,
    closeApproveDialog,
    defaultErrorHandler,
    setShowBackdrop,
    t
  ])

  const handleOnDeleteConfirmationButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteClaim({id: claimId})
    } catch (e) {
      defaultErrorHandler(e, t('Error while deleting claim'))
    } finally {
      setShowBackdrop(false)
      closeDeleteDialog()
      closeDrawer()
      addInfoNotification(t('Claim deleted'))
    }
  }, [
    addInfoNotification,
    claimId,
    closeDeleteDialog,
    closeDrawer,
    defaultErrorHandler,
    deleteClaim,
    setShowBackdrop,
    t
  ])

  return (
    <>
      {isAddItemsDialogOpen && saleData?.sale && (
        <AddItemsDialog
          sale={saleData.sale}
          claimId={claimId}
          onClose={closeAddItemsDialog}
        />
      )}
      {isApproveDialogOpen && (
        <ConfirmationDialog
          title={t('Approve claim?')}
          contentText={t(
            'Are you sure you want to approve this claim? You will not be able to revoke this approval in future.'
          )}
          confirmButtonLabel={t('Approve')}
          onConfirm={handleOnApproveConfirmationButtonClick}
          onCancel={closeApproveDialog}
          isOpen={isApproveDialogOpen}
        />
      )}
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          title={t('Delete claim?')}
          contentText={t(
            "Are you sure you want to delete this claim? This can't be undone."
          )}
          confirmButtonLabel={t('Delete')}
          onConfirm={handleOnDeleteConfirmationButtonClick}
          onCancel={closeDeleteDialog}
          isOpen={isDeleteDialogOpen}
        />
      )}
      <VoucherDialog
        isOpen={isVoucherDialogOpen}
        onClose={closeVoucherDialog}
        voucher={voucherData?.voucherByCodeOnRetailChannel}
        lead={data?.claim.lead}
      />
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        anchor="right"
        SlideProps={{
          onExited
        }}
        classes={drawerClasses}
      >
        <DrawerTemplateWithSideNavigation
          DrawerTemplateProps={{
            isLoading: loading,
            errorMessage: error && t('Loading claim failed'),
            header: (
              <DrawerTemplateHeader
                title={t('Claim {{claimId}}', {claimId})}
                onLeftActionClick={closeDrawer}
              />
            ),
            footer: data &&
              data.claim.items &&
              [
                ClaimState.Draft,
                ClaimState.Approved,
                ClaimState.Pending,
                ClaimState.Failed
              ].includes(data.claim.state) && (
                <Footer
                  claimState={data.claim.state}
                  claimItemsLength={data.claim.items.length}
                  onDeleteButtonClick={openDeleteDialog}
                  onApproveButtonClick={openApproveDialog}
                  onRefundButtonClick={handleRefundButtonClick}
                />
              )
          }}
          navigationItems={navigationItems}
        >
          {data?.claim && (
            <DrawerSection
              id={navigationItems.general.id}
              className={classes.section}
            >
              <General claim={data.claim} />
            </DrawerSection>
          )}
          {data?.claim && (
            <DrawerSection
              id={navigationItems.payment.id}
              label={navigationItems.payment.label}
            >
              <PaymentSection claim={data.claim} />
            </DrawerSection>
          )}
          {data?.claim.items && (
            <DrawerSection
              id={navigationItems.items.id}
              label={navigationItems.items.label}
              actions={
                data.claim.state === ClaimState.Draft &&
                P([PermissionCode.AddItemsToClaim]) && (
                  <Button
                    onClick={handleOnAddButtonClick}
                    startIcon={<AddIcon />}
                    color="primary"
                  >
                    {t('Add')}
                  </Button>
                )
              }
              className={classes.section}
            >
              <ItemsSection claim={data.claim} />
            </DrawerSection>
          )}
          {data?.claim.lead && (
            <DrawerSection
              id={navigationItems.lead.id}
              label={navigationItems.lead.label}
            >
              <LeadSectionGrid leadData={data.claim.lead.data} />
            </DrawerSection>
          )}
        </DrawerTemplateWithSideNavigation>
      </Drawer>
    </>
  )
}
