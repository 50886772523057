import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ObjectsPanelType} from './types'

export const useGetObjectsPanelTitle = () => {
  const {t} = useTranslation()
  return useCallback(
    (type: ObjectsPanelType) => {
      switch (type) {
        case ObjectsPanelType.SingleSeat:
          return t('Single seat settings')
        case ObjectsPanelType.MultipleSeats:
          return t('Multiple seats settings')
        case ObjectsPanelType.SingleZone:
          return t('Single zone settings')
        case ObjectsPanelType.MultipleZones:
          return t('Multiple zones settings')
        case ObjectsPanelType.SingleIcon:
          return t('Single icon settings')
        case ObjectsPanelType.MultipleIcons:
          return t('Multiple icons settings')
        case ObjectsPanelType.SingleRectangle:
        case ObjectsPanelType.SingleLine:
        case ObjectsPanelType.SingleEllipse:
          return t('Single shape settings')
        case ObjectsPanelType.MultipleRectangles:
        case ObjectsPanelType.MultipleLines:
        case ObjectsPanelType.MultipleEllipses:
        case ObjectsPanelType.MultipleShapes:
          return t('Multiple shapes settings')
        case ObjectsPanelType.SingleText:
          return t('Single text settings')
        case ObjectsPanelType.MultipleTexts:
          return t('Multiple texts settings')
        case ObjectsPanelType.MixWithAttendeeNavigation:
        case ObjectsPanelType.MinimalMix:
        case ObjectsPanelType.MixWithLabel:
        case ObjectsPanelType.MixZonesAndTexts:
        case ObjectsPanelType.MixWithDimensions:
        default:
          return t('Various objects settings')
      }
    },
    [t]
  )
}
