import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  GetCustomerQuery,
  GetCustomerQueryVariables,
  GetCustomersQuery,
  GetCustomersQueryVariables,
  NarrowCustomerQuery,
  NarrowCustomerQueryVariables,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {LEAD_PROPERTIES_FRAGMENT, TRANSLATED_LOCALES_FRAGMENT} from '../graphql'

const GET_CUSTOMERS = gql`
  query getCustomers(
    $filter: CustomersFilterInput
    $paginationInput: PaginationInput!
  ) {
    customers(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        username
        state
        privacyPolicyConsentGrantedAt
        loyaltyLevelExpirationDate
        loyaltyId
        lead {
          ...LeadProperties
        }
        createdAt
        updatedAt
        loyaltyScore {
          totalPurchaseAmount
          soldCartsCount
          salesCount
          totalTicketsCount
          paidTicketsCount
          freeTicketsCount
          updatedAt
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const useGetCustomers = (variables: GetCustomersQueryVariables) =>
  useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GET_CUSTOMERS, {
    variables,
    fetchPolicy: 'network-only'
  })

const CUSTOMER_FIELDS = gql`
  ${LEAD_PROPERTIES_FRAGMENT}
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment CustomerFields on Customer {
    id
    username
    loyaltyId
    privacyPolicyConsentGrantedAt
    loyaltyLevelExpirationDate
    createdAt
    lead {
      ...LeadProperties
    }
    reservations(states: [active, inCart, expired, canceled]) {
      id
      expireAt
      itemsCount
      price
      state
      tourTimeSlot {
        id
        startsAt
        tour {
          id
          name
        }
      }
      event {
        id
        startsAt
        names {
          ...TranslatedLocales
        }
      }
    }
    sales {
      id
      event {
        id
        startsAt
        names {
          ...TranslatedLocales
        }
      }
      tourTimeSlot {
        id
        startsAt
        tour {
          id
          name
        }
      }
      items {
        ... on TicketItem {
          id
        }
        ... on TourItem {
          id
        }
      }
      price
      cart {
        id
      }
    }
    loyaltyScore {
      totalPurchaseAmount
      soldCartsCount
      reservedCartsCount
      salesCount
      reservationsCount
      totalTicketsCount
      paidTicketsCount
      freeTicketsCount
      updatedAt
    }
    customerGroups {
      id
      name
    }
  }
`

const GET_CUSTOMER = gql`
  ${CUSTOMER_FIELDS}
  query getCustomer($id: PositiveInt!) {
    customer(id: $id) {
      ...CustomerFields
    }
  }
`

export const useGetCustomer = (id: number) =>
  useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GET_CUSTOMER, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
    }
  }
`

export const useCreateCustomer = () => {
  const [createCustomer] =
    useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(
      CREATE_CUSTOMER
    )
  return useCallback(
    (variables: CreateCustomerMutationVariables) => createCustomer({variables}),
    [createCustomer]
  )
}

const NARROW_CUSTOMER = gql`
  ${LEAD_PROPERTIES_FRAGMENT}
  query NarrowCustomer($id: PositiveInt!) {
    customer(id: $id) {
      id
      username
      loyaltyId
      privacyPolicyConsentGrantedAt
      loyaltyLevelExpirationDate
      lead {
        ...LeadProperties
      }
    }
  }
`

export const useNarrowCustomer = (id: number) =>
  useQuery<NarrowCustomerQuery, NarrowCustomerQueryVariables>(NARROW_CUSTOMER, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const UPDATE_CUSTOMER = gql`
  ${CUSTOMER_FIELDS}
  mutation UpdateCustomer($id: PositiveInt!, $input: UpdateCustomerInput!) {
    updateCustomer(id: $id, input: $input) {
      ...CustomerFields
    }
  }
`

export const useUpdateCustomer = () => {
  const [updateCustomer] =
    useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
      UPDATE_CUSTOMER
    )
  return useCallback(
    (variables: UpdateCustomerMutationVariables) => updateCustomer({variables}),
    [updateCustomer]
  )
}
