import {ApolloError} from 'apollo-client'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'

export const useCheckoutErrorHandler = () => {
  const {t} = useTranslation()
  const {defaultErrorHandler, customErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  return (error: ApolloError) => {
    if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.TooManyPaymentMethodsUsed
      )
    ) {
      customErrorHandler(error, {
        title: t('Too many payment methods'),
        contentText: t(
          'One or more payment methods exceed the cart’s price. You have tried to use too many payment methods. Check applied payment methods in checkout and remove the unnecessary.'
        ),
        confirmButtonLabel: t('Got it')
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.WarehouseProductOutOfStock
      )
    ) {
      addInfoNotification(t('Product is out of stock'))
    } else {
      defaultErrorHandler(error, t('Checkout failed'))
    }
  }
}
