import {IconShape} from '@attendio/shared-components'
import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {IIconStateValue} from '../../shared/editor/stateTypes'
import {
  NEUTRAL_100_COLOR,
  NEUTRAL_900_COLOR,
  PRIMARY_100_COLOR,
  Theme
} from '../../theme'
import {useObjectsActions} from '../redux/objects/actions'
import {usePlaceholderAndValue} from './placeholderAndValue'

const useStyles = makeStyles((theme: Theme) => ({
  groupRoot: {
    paddingTop: theme.spacing(1)
  },
  groupGrouped: {
    border: 'none'
  },
  buttonRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: NEUTRAL_100_COLOR,
    color: NEUTRAL_900_COLOR,
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: PRIMARY_100_COLOR
    },
    '&.Mui-selected': {
      backgroundColor: PRIMARY_100_COLOR,
      color: theme.palette.primary.main
    }
  }
}))

interface IIconShapeToggleButtonGroupProps {
  icons: IIconStateValue[]
}

export const IconShapeToggleButtonGroup: React.FC<IIconShapeToggleButtonGroupProps> =
  ({icons}: IIconShapeToggleButtonGroupProps) => {
    const {t} = useTranslation()
    const {updateObjects} = useObjectsActions()
    const {value} = usePlaceholderAndValue({
      selectedObjects: icons,
      iteratee: 'config.iconShape'
    })
    const classes = useStyles()
    return (
      <div>
        <Typography variant="body2">{t('Shape')}</Typography>
        <ToggleButtonGroup
          exclusive
          onChange={(e, iconShape?: IconShape) => {
            if (iconShape) {
              updateObjects(
                icons.map(
                  <T extends IIconStateValue>(object: T): T => ({
                    ...object,
                    config: {
                      ...object.config,
                      iconShape
                    }
                  })
                )
              )
            }
          }}
          value={value}
          classes={{grouped: classes.groupGrouped, root: classes.groupRoot}}
        >
          {[
            {
              value: IconShape.Ellipse,
              label: t('Ellipse'),
              cypressId: 'button-shape-ellipse'
            },
            {
              value: IconShape.Rectangle,
              label: t('Rectangle'),
              cypressId: 'button-shape-rectangle'
            }
          ].map((item) => (
            <ToggleButton
              key={item.value}
              value={item.value}
              classes={{root: classes.buttonRoot}}
              cypress-id={item.cypressId}
              size="small"
            >
              {item.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    )
  }
