import {Box, Switch, SwitchProps, Typography} from '@mui/material'
import {TypographyProps} from '@mui/material/Typography/Typography'
import React from 'react'

interface IRowWithSwitchProps {
  primaryLabel: string
  secondaryLabel?: string
  switchProps: SwitchProps
  disabled?: boolean
  secondaryLabelColor?: TypographyProps['color']
}

export const RowWithSwitch: React.FC<IRowWithSwitchProps> = ({
  primaryLabel,
  secondaryLabel,
  switchProps,
  disabled,
  secondaryLabelColor
}: IRowWithSwitchProps) => (
  <Box
    sx={{
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '1fr auto',
      columnGap: 3,
      py: 1,
      px: 3,
      opacity: (theme) =>
        disabled ? theme.palette.action.disabledOpacity : undefined,
      cursor: disabled ? 'pointer' : 'normal',
      pointerEvents: disabled ? 'none' : 'auto'
    }}
  >
    <Box>
      <Typography variant="subtitle2">{primaryLabel}</Typography>
      {secondaryLabel && (
        <Typography
          variant="caption"
          color={secondaryLabelColor || 'textSecondary'}
        >
          {secondaryLabel}
        </Typography>
      )}
    </Box>
    <Switch color="primary" {...switchProps} />
  </Box>
)
