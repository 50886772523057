import {
  FeSeatState,
  ICoords,
  ISeat,
  SeatByState
} from '@attendio/shared-components'
import Konva from 'konva'
import _ from 'lodash'

import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {
  ROTATION,
  ROW_ANGLE_PRECISION,
  ROWS_GAP,
  SEAT_LABEL,
  SEAT_SHAPE,
  SEAT_SIZE,
  SHAPE_ROTATION_ROUND_FACTOR
} from '../config'
import {useSelector} from '../redux'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {isShiftActiveSelector} from '../redux/keyboardKeys/selectors'
import {useSeatsActions} from '../redux/objects/seats/actions'
import {DrawTool} from '../types'
import {getMouseCoordsOnCanvas} from '../utils/common'
import {
  degreesToRadians,
  findNearestMultiple,
  radiansToDegrees,
  radiansToUnitVector,
  vectorLength,
  vectorToRadians
} from '../utils/math'
import {EventLayer} from './EventLayer'

interface SeatRowProps {
  startCoords: ICoords
  endCoords: ICoords
  constrainAngle?: boolean
  flip?: boolean
}

const angleRoundFactor = degreesToRadians(SHAPE_ROTATION_ROUND_FACTOR)

const getVectorAngle = (vector: {x: number; y: number}, round: boolean) => {
  let vectorAngle = vectorToRadians(vector.x, vector.y)

  if (round) {
    vectorAngle = findNearestMultiple(vectorAngle, angleRoundFactor)
  }

  return vectorAngle
}

const composeSeatId = (coords: ICoords) => {
  return `${Math.round(coords.x)}-${Math.round(coords.y)}`
}

const getSeatRow = ({
  startCoords,
  endCoords,
  constrainAngle = false,
  flip = false
}: SeatRowProps): Array<ISeat> => {
  const vector = {
    x: endCoords.x - startCoords.x,
    y: endCoords.y - startCoords.y
  }

  const vectorSize = vectorLength(vector.x, vector.y)

  if (vectorSize === 0) {
    const coords = {x: startCoords.x, y: startCoords.y}

    return [
      {
        id: composeSeatId(coords),
        coords,
        label: SEAT_LABEL,
        rotation: ROTATION,
        seatShape: SEAT_SHAPE,
        row: '',
        section: '',
        floor: ''
      }
    ]
  }

  const vectorAngle = getVectorAngle(vector, constrainAngle)

  const unitVector = radiansToUnitVector(vectorAngle)

  const seatVector = {
    x: unitVector.x * SEAT_SIZE,
    y: unitVector.y * SEAT_SIZE
  }
  const seatCount = Math.ceil(vectorSize / SEAT_SIZE)

  let seatRotation = vectorAngle

  if (flip && (vectorAngle > Math.PI / 2 || vectorAngle < -Math.PI / 2)) {
    seatRotation += Math.PI
  }

  const rotationInDegrees = _.round(
    radiansToDegrees(seatRotation),
    ROW_ANGLE_PRECISION
  )

  return [...Array(seatCount)].map((_, i) => {
    const coords = {
      x: startCoords.x + i * seatVector.x,
      y: startCoords.y + i * seatVector.y
    }

    return {
      id: composeSeatId(coords),
      coords,
      label: String(i + 1),
      rotation: rotationInDegrees,
      seatShape: SEAT_SHAPE,
      row: '',
      section: '',
      floor: ''
    }
  })
}

const getSeatRows = (startCoords: ICoords, endCoords: ICoords) => {
  const rowCount = Math.ceil(
    (Math.abs(startCoords.y - endCoords.y) + SEAT_SIZE / 2) /
      (SEAT_SIZE + ROWS_GAP)
  )

  const rowOffset =
    startCoords.y > endCoords.y ? -SEAT_SIZE - ROWS_GAP : SEAT_SIZE + ROWS_GAP

  const seatRows = [...Array(rowCount)].map((_, i) =>
    getSeatRow({
      startCoords: {x: startCoords.x, y: startCoords.y + i * rowOffset},
      endCoords: {x: endCoords.x, y: startCoords.y + i * rowOffset},
      flip: true
    })
  )

  return seatRows
}

const TemporarySeatsFn: React.FC = () => {
  const [startCoords, setStartCoords] = useState<ICoords | null>(null)
  const [endCoords, setEndCoords] = useState<ICoords | null>(null)
  const [seats, setSeats] = useState<Array<ISeat>>([])

  const {modeConfigs} = useSelector(editorModeSelector)

  const isShiftPressed = useSelector(isShiftActiveSelector)

  const {addSeats} = useSeatsActions()

  const drawToolType = useMemo(
    () => modeConfigs[EditorMode.DRAW].type,
    [modeConfigs]
  )

  useEffect(() => {
    if (!startCoords || !endCoords) {
      if (seats.length) {
        setSeats([])
      }
    } else if (drawToolType === DrawTool.SEAT_ROW) {
      const newSeats = getSeatRow({
        startCoords,
        endCoords,
        constrainAngle: isShiftPressed
      })

      if (!_.isEqual(seats, newSeats)) {
        setSeats(newSeats)
      }
    } else if (drawToolType === DrawTool.SEAT_ROWS) {
      const newSeats = getSeatRows(startCoords, endCoords).flat()

      if (!_.isEqual(seats, newSeats)) {
        setSeats(newSeats)
      }
    }
  }, [
    drawToolType,
    endCoords,
    isShiftPressed,
    seats,
    seats.length,
    startCoords
  ])

  const onMouseDown = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const coords = getMouseCoordsOnCanvas(e)
    setStartCoords(coords)
    setEndCoords(coords)
  }, [])

  const onMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (startCoords) {
        _.debounce(
          () => {
            const coords = getMouseCoordsOnCanvas(e)
            if (!_.isEqual(endCoords, coords)) {
              setEndCoords(coords)
            }
          },
          100,
          {
            leading: true,
            trailing: false
          }
        )()
      }
    },
    [endCoords, startCoords]
  )

  const onMouseUp = useCallback(() => {
    setStartCoords(null)
    setEndCoords(null)
    addSeats(seats)
  }, [addSeats, seats])

  return (
    <EventLayer {...{onMouseUp, onMouseMove, onMouseDown}}>
      {seats.map((seat) => (
        <SeatByState
          key={seat.id}
          state={FeSeatState.Plain}
          seatConfig={seat}
        />
      ))}
    </EventLayer>
  )
}

export const TemporarySeats = React.memo(TemporarySeatsFn)
