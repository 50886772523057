import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: 64,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 3),
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`
  }
}))

interface ICoreFooterProps {
  children: React.ReactNode
}

export const CoreFooter: React.FC<ICoreFooterProps> = ({
  children
}: ICoreFooterProps) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}
