import {useQuery} from '@apollo/react-hooks'
import {Box, Drawer, drawerClasses, List, Typography} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientState,
  LightweightClientsWithTownQuery,
  LightweightClientsWithTownQueryVariables
} from '../../../../../__generated__/schema'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {LIGHTWEIGHT_CLIENTS_WITH_TOWN} from '../graphql'
import {ClientRowListItem} from './ClientRowListItem'

interface IManageClientsDrawerProps {
  isOpen: boolean
  onClose: () => void
  addedClientIds: number[]
  onSaveButtonClick: (selectedClients: number[]) => void
}

export const ManageClientsDrawer: React.FC<IManageClientsDrawerProps> = ({
  isOpen,
  onClose,
  addedClientIds,
  onSaveButtonClick
}: IManageClientsDrawerProps) => {
  const {t} = useTranslation()
  const [skip, setSkip] = useState(!isOpen)
  const [search, setSearch] = useState<string>('')
  const [selectedClientIds, setSelectedClientIds] =
    useState<number[]>(addedClientIds)
  const {data, loading, error} = useQuery<
    LightweightClientsWithTownQuery,
    LightweightClientsWithTownQueryVariables
  >(LIGHTWEIGHT_CLIENTS_WITH_TOWN, {fetchPolicy: 'network-only', skip})
  const filteredClients = (data?.clients || [])
    .sort((clientA, clientB) => clientA.name.localeCompare(clientB.name))
    .filter(({state}) => state === ClientState.Active)
    .filter(({name}) => name.toLowerCase().indexOf(search.toLowerCase()) > -1)
  const createClientCheckboxClickHandler = useCallback(
    (clientId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedClientIds((clientIds) =>
        clientIds.includes(clientId)
          ? clientIds.filter((id) => id !== clientId)
          : [...clientIds, clientId]
      )
    },
    []
  )
  const handleSaveButtonClick = useCallback(() => {
    onSaveButtonClick(selectedClientIds)
    onClose()
  }, [onClose, onSaveButtonClick, selectedClientIds])
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Manage clients')}
          />
        }
        errorMessage={error && t<string>('Error while loading clients')}
        isLoading={loading}
        footer={
          <SaveButton onClick={handleSaveButtonClick}>{t('Save')}</SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {data && (
          <Box
            sx={{
              px: 3,
              pt: 3,
              display: 'flex',
              gap: 1,
              flexDirection: 'column'
            }}
          >
            <Typography variant="subtitle1">{t('Select clients')}</Typography>
            <OutlinedInputWithCancelAdornment
              onCancelClick={() => setSearch('')}
              inputProps={{
                value: search,
                onChange: (e) => setSearch(e.target.value),
                placeholder: t('Search for client')
              }}
            />
            {filteredClients.length > 0 ? (
              <List sx={{overflowY: 'auto'}}>
                {filteredClients.map((client) => (
                  <ClientRowListItem
                    key={client.id}
                    clientName={`${client.id} - ${client.name}`}
                    checkBoxProps={{
                      onClick: createClientCheckboxClickHandler(client.id),
                      checked: !!selectedClientIds.find(
                        (selectedClientId) => selectedClientId === client.id
                      )
                    }}
                  />
                ))}
              </List>
            ) : (
              <Typography variant="body2">{t('No clients found')}</Typography>
            )}
          </Box>
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
