import {UniqueIdentifier} from '@dnd-kit/core'
import AddIcon from '@mui/icons-material/Add'
import {Box, Button, Drawer, drawerClasses} from '@mui/material'
import {isNil, uniqueId} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useBooleanState} from '../../../../../../hooks/state'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {Menu, MenuItem, useMenu} from '../../../../../common/Menu'
import {SortableTree} from '../../../../../common/sortableTree'
import {TreeItems} from '../../../../../common/sortableTree/types'
import {buildTree, flattenTree} from '../../../../../common/sortableTree/utils'
import {useNotifications} from '../../../../../context/notifications'
import {AddMenuItem, IItemForm, ItemFormField} from '../../types'
import {getAddMenuItemType} from '../../utils'
import {AddItemDialog} from '../AddItemDialog'
import {EditItemDialog} from '../EditItemDialog'
import {useWebsitePageContext} from '../WebsitePageContext'
import {transformWebsiteHeaderItemToTreeItems} from './utils'

const checkForEmptyFolders = (items: TreeItems): boolean =>
  items.some((item) => {
    if (item.children.length === 0 && isNil(item.href)) {
      return true
    }
    if (item.children.length > 0) {
      return checkForEmptyFolders(item.children)
    }
    return false
  })

interface IEditHeaderDrawerProps {
  headerId: string | null
  onClose: () => void
  onSaveButtonClick: (newItems: TreeItems) => void
}

export const EditHeaderDrawer: React.FC<IEditHeaderDrawerProps> = ({
  headerId,
  onClose,
  onSaveButtonClick
}: IEditHeaderDrawerProps) => {
  const {t} = useTranslation()
  const {websiteHeaders} = useWebsitePageContext()
  const {addErrorWithCustomDialogNotification} = useNotifications()
  const header = websiteHeaders.find(({ID}) => ID === headerId)
  const [items, setItems] = useState<TreeItems>([])
  const [hasEmptyFolder, setHasEmptyFolder] = useState<boolean>(false)
  const [selectedAddItem, setSelectedAddItem] =
    useState<AddMenuItem | undefined>(undefined)
  const [selectedItemIdToEdit, setSelectedItemIdToEdit] =
    useState<UniqueIdentifier | null>(null)
  const itemToEdit = flattenTree(items).find(
    ({id}) => id === selectedItemIdToEdit
  )
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const handleMenuItemClick = useCallback(
    (item: AddMenuItem) => () => {
      setSelectedAddItem(item)
      closeMenu()
    },
    [closeMenu]
  )
  const handleAddItem = useCallback((formData: IItemForm) => {
    const href = formData[ItemFormField.Page]
      ? formData[ItemFormField.Type] === AddMenuItem.Page
        ? formData[ItemFormField.Page] === 'homepage'
          ? '/'
          : `/${formData[ItemFormField.Page]}`
        : formData[ItemFormField.Page]
      : null
    setItems((prevState) => [
      {
        id: uniqueId(),
        label: formData[ItemFormField.Label],
        href,
        description: href ?? undefined,
        target: formData[ItemFormField.OpenInNewTab] ? '_blank' : null,
        children: []
      },
      ...prevState
    ])
    if (formData[ItemFormField.Type] === AddMenuItem.Folder) {
      setHasEmptyFolder(true)
    }
  }, [])
  const handleEditItem = useCallback(
    (formData: IItemForm) => {
      const href = formData[ItemFormField.Page]
        ? formData[ItemFormField.Type] === AddMenuItem.Page
          ? formData[ItemFormField.Page] === 'homepage'
            ? '/'
            : `/${formData[ItemFormField.Page]}`
          : formData[ItemFormField.Page]
        : null

      setItems((prevState) =>
        buildTree(
          flattenTree(prevState).map((item) =>
            item.id === selectedItemIdToEdit
              ? {
                  ...item,
                  label: formData[ItemFormField.Label],
                  href,
                  description: href ?? undefined,
                  target: formData[ItemFormField.OpenInNewTab] ? '_blank' : null
                }
              : item
          )
        )
      )
      checkForEmptyFolders(items)
    },
    [items, selectedItemIdToEdit]
  )
  const handleItemsChange = useCallback((items: TreeItems) => {
    if (checkForEmptyFolders(items)) {
      setHasEmptyFolder(true)
    } else {
      setHasEmptyFolder(false)
    }
    setItems(items)
  }, [])
  const handleSaveButtonClick = useCallback(() => {
    if (hasEmptyFolder) {
      addErrorWithCustomDialogNotification({
        title: t('Some issues found in header settings'),
        contentText: t(
          'We encountered an issue with your website header. Please review and update your settings to ensure smooth navigation for your users. Check all items in the header and fix the marked issues.'
        ),
        confirmButtonLabel: t('Got it')
      })
    } else {
      onSaveButtonClick(items)
    }
  }, [
    addErrorWithCustomDialogNotification,
    hasEmptyFolder,
    items,
    onSaveButtonClick,
    t
  ])
  useEffect(() => {
    if (header) {
      openDrawer()
      setItems(transformWebsiteHeaderItemToTreeItems(header.items))
    } else {
      closeDrawer()
      setItems(transformWebsiteHeaderItemToTreeItems([]))
      setHasEmptyFolder(false)
    }
  }, [header, openDrawer, closeDrawer])
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Edit header')}
            />
          }
          footer={
            <SaveButton onClick={handleSaveButtonClick}>{t('Save')}</SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <Box>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'background.paper',
                py: 1,
                px: 2,
                borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="text"
                color="primary"
                startIcon={<AddIcon />}
                onClick={openMenu}
              >
                {t('Add')}
              </Button>
            </Box>
            <Box sx={{py: 2, px: 3}}>
              <SortableTree
                treeItems={items}
                removable
                indicator
                collapsible
                onEdit={(id) => setSelectedItemIdToEdit(id)}
                onChange={handleItemsChange}
              />
            </Box>
          </Box>
        </DrawerTemplate>
      </Drawer>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          label={t('Page')}
          sx={{flexFlow: 'row-reverse'}}
          iconSx={{justifyContent: 'center'}}
          onClick={handleMenuItemClick(AddMenuItem.Page)}
        />
        <MenuItem
          label={t('Folder')}
          sx={{flexFlow: 'row-reverse'}}
          iconSx={{justifyContent: 'center'}}
          onClick={handleMenuItemClick(AddMenuItem.Folder)}
        />
        <MenuItem
          label={t('External link')}
          sx={{flexFlow: 'row-reverse'}}
          iconSx={{justifyContent: 'center'}}
          onClick={handleMenuItemClick(AddMenuItem.ExternalLink)}
        />
      </Menu>
      <AddItemDialog
        type={selectedAddItem}
        onSubmit={handleAddItem}
        onClose={() => setSelectedAddItem(undefined)}
      />
      {itemToEdit && (
        <EditItemDialog
          isOpen={Boolean(selectedItemIdToEdit)}
          type={getAddMenuItemType(
            isNil(itemToEdit.href) ? undefined : (itemToEdit.href as string)
          )}
          onSubmit={handleEditItem}
          onClose={() => setSelectedItemIdToEdit(null)}
          defaultValues={{
            [ItemFormField.Label]: itemToEdit.label,
            [ItemFormField.Page]: isNil(itemToEdit.href)
              ? undefined
              : (itemToEdit.href as string) === '/'
              ? 'homepage'
              : typeof itemToEdit.href === 'string' &&
                itemToEdit.href.startsWith('/')
              ? itemToEdit.href.substring(1)
              : (itemToEdit.href as string),
            [ItemFormField.OpenInNewTab]: itemToEdit.target === '_blank'
          }}
        />
      )}
    </>
  )
}
