import {isEmailList} from 'is-email-list'
import {isNil} from 'lodash'
import {useTranslation} from 'react-i18next'
import validator from 'validator'

import {convertValueToFloat} from './conversions'

const TEST_STRING_CONTAINS_NUMBERS_AND_CAPITAL_LETTERS_ONLY = /^[0-9A-Z]+$/

export const isValidDiscountCodeName = (name: string) =>
  name.length > 255
    ? false
    : TEST_STRING_CONTAINS_NUMBERS_AND_CAPITAL_LETTERS_ONLY.test(name)

export const isValidPrice = (value: string) =>
  /(^[1-9]+[0-9]*([.,][0-9]{0,2})?$)|(^0([.,][0-9]{0,2})?$)/.test(value)

export const isValidHexColor = (value?: string) =>
  value && /^#[0-9A-F]{6}$/i.test(value)

/**
 * @deprecated - please use `useIsTicketPriceValid`
 */
export const isValidTicketPrice = (value: string) =>
  value === '' || isValidPrice(value)

/**
 * deprecated, use useIsNonNegativeInteger
 */
export const isNonNegativeInteger = (value: string) => {
  return `${parseInt(value, 10)}` === value && parseInt(value, 10) >= 0
}

const replaceCommaWithDot = (value: string) => value.replace(',', '.')

export const useIsTicketPriceValid = () => {
  const {t} = useTranslation()
  return (value: string) =>
    isValidTicketPrice(value) ? undefined : t('Invalid price!')
}

export const useIsValidMoneyAmount = (message?: string) => {
  const {t} = useTranslation()
  return (value: string) =>
    value === '' || isValidPrice(value)
      ? undefined
      : message || t('Enter a valid amount of money.')
}

export const useIsNotZero = (message?: string) => {
  const {t} = useTranslation()
  return (value: string) =>
    value === '' || convertValueToFloat(value) !== 0
      ? undefined
      : message || t('Requires non-zero value')
}

export const useIsStringWithMaxLength = (max: number) => {
  const {t} = useTranslation()
  return (value: any) =>
    value && (typeof value !== 'string' || value.length > max)
      ? t('Max allowed length is {{max}}', {max})
      : undefined
}

export const useIsStringWithMinLength = (min: number) => {
  const {t} = useTranslation()
  return (value: any) =>
    value && (typeof value !== 'string' || value.length < min)
      ? t('Min allowed length is {{min}}', {min})
      : undefined
}

export const useIsNonNegativeNumber = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isFloat(replaceCommaWithDot(value), {min: 0})
      ? t('Requires non-negative number')
      : undefined
}

export const useIsNonNegativeDecimal = (decimalDigits: string = '2') => {
  const {t} = useTranslation()
  return (value: string) =>
    value &&
    (!validator.isFloat(replaceCommaWithDot(value), {min: 0}) ||
      !validator.isDecimal(replaceCommaWithDot(value), {
        decimal_digits: decimalDigits
      }))
      ? t('Requires non-negative number')
      : undefined
}

export const useIsPositiveDecimal = (decimalDigits: string = '2') => {
  const {t} = useTranslation()
  return (value: string) =>
    value &&
    (!validator.isFloat(replaceCommaWithDot(value), {gt: 0}) ||
      !validator.isDecimal(replaceCommaWithDot(value), {
        decimal_digits: decimalDigits
      }))
      ? t('Requires positive number')
      : undefined
}

export const useIsPercentValue = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isFloat(replaceCommaWithDot(value), {min: 0, max: 100})
      ? t('Requires a number between 0 and 100')
      : undefined
}

export const useIsNonNegativeInteger = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isInt(value, {min: 0})
      ? t('Requires non-negative integer')
      : undefined
}

export const useIsPositiveNumber = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isFloat(replaceCommaWithDot(value), {gt: 0})
      ? t('Requires positive number')
      : undefined
}
export const useIsPositiveInteger = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isInt(value, {min: 1})
      ? t('Requires positive integer')
      : undefined
}
export const useIsRequired = () => {
  const {t} = useTranslation()
  return (value: string) =>
    isNil(value) || value === '' ? t('This field is required!') : undefined
}
export const useIsIntegerWithMaxBoundary = (max: number) => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isInt(value, {max})
      ? t('Value exceeded max allowed {{max}}', {max})
      : undefined
}

export const useIsNumberWithMaxBoundary = (max: number) => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isFloat(replaceCommaWithDot(value), {max})
      ? t('Value exceeded max allowed {{max}}', {max})
      : undefined
}

export const useIsIntegerWithMinMaxBoundary = (min: number, max: number) => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isInt(value, {min, max})
      ? t('Required integer is interval from {{min}} to {{max}}', {min, max})
      : undefined
}

export const useIsDecimalWithMinMaxBoundary = (
  min: number,
  max: number,
  decimalDigits: string = '1,2'
) => {
  const {t} = useTranslation()
  return (value: string) =>
    value &&
    (!validator.isFloat(replaceCommaWithDot(value), {min, max}) ||
      !validator.isDecimal(replaceCommaWithDot(value), {
        decimal_digits: decimalDigits
      }))
      ? t('Required decimal is interval from {{min}} to {{max}}', {min, max})
      : undefined
}

export const useIsValidEmail = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isEmail(value) ? t('Invalid email address') : undefined
}

export const useIsValidEmailList = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !isEmailList(value) ? t('Invalid emails list') : undefined
}

export const useIsValidIBAN = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isIBAN(value) ? t('Invalid IBAN format') : undefined
}

export const useIsValidSWIFT = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isBIC(value) ? t('Invalid SWIFT format') : undefined
}

export const useIsFormMultiSelectRequired = () => {
  const {t} = useTranslation()
  return (value: any) =>
    Array.isArray(value) && value.length > 0
      ? undefined
      : t('This field is required!')
}

export const useIsValidDiscountCodeName = () => {
  const {t} = useTranslation()
  return (value: any) =>
    isValidDiscountCodeName(value)
      ? undefined
      : t('Only capital letters and numbers allowed.')
}

export const useIsValidURL = (
  httpsOnly?: boolean,
  requireProtocol: boolean = true
) => {
  const {t} = useTranslation()
  return (value: string) =>
    value &&
    !validator.isURL(value, {
      protocols: httpsOnly ? ['https'] : ['http', 'https'],
      require_protocol: requireProtocol
    })
      ? httpsOnly
        ? t('Invalid URL format. Note, that URL should start with https://')
        : requireProtocol
        ? t(
            'Invalid URL format. Note, that URL should start with http:// or https://'
          )
        : t('Invalid URL format')
      : undefined
}

export const useIsValidURLWithMailtoAndTel = (httpsOnly?: boolean) => {
  const {t} = useTranslation()
  const mailtoPattern = /^mailto:[^\s@]+@[^\s@]+\.[^\s@]+$/i
  const telPattern = /^tel:\+?[0-9\s-]+$/i
  return (value: string) => {
    if (value) {
      if (mailtoPattern.test(value) || telPattern.test(value)) {
        return true
      }
      return !validator.isURL(value, {
        protocols: httpsOnly ? ['https'] : ['http', 'https']
      })
        ? httpsOnly
          ? t(
              'Invalid URL format. Note, that URL should start with https://, tel: or mailto:'
            )
          : t(
              'Invalid URL format. Note, that URL should start with http://, https://, tel: or mailto:'
            )
        : undefined
    }
    return undefined
  }
}

export const useIsNumberWithMinAbsoluteBoundary = (min: number) => {
  const {t} = useTranslation()
  return (value: string) => {
    const parsed = parseFloat(replaceCommaWithDot(value))
    return value && !validator.isFloat(Math.abs(parsed).toString(10), {min})
      ? t('Invalid -{{min}} - +{{min}}', {min})
      : undefined
  }
}

export const useIsValidHexColor = () => {
  const {t} = useTranslation()
  return (value: string) =>
    isValidHexColor(value)
      ? undefined
      : t('Type correct color format or select color with picker.')
}

export const useIsValidPhone = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && !validator.isMobilePhone(value)
      ? t('Invalid phone number')
      : undefined
}

export const useIsValidIpAddress = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value &&
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value
    )
      ? undefined
      : t('Invalid IP address')
}

export const useIsValidPassword = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && value.length < 8 ? t('Required at least 8 characters.') : undefined
}

export const useIsValidSlug = () => {
  const {t} = useTranslation()
  return (value: string) =>
    value && /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value)
      ? undefined
      : t('Invalid slug')
}
