import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import uniqBy from 'lodash/uniqBy'
import {useCallback} from 'react'
import {
  DecrementProductItemQuantityMutation,
  DecrementProductItemQuantityMutationVariables,
  GetAvailableProductsQuery,
  GetAvailableProductsQueryVariables,
  IncrementProductItemQuantityMutation,
  IncrementProductItemQuantityMutationVariables,
  IncrementProductItemQuantityWithBarcodeMutation,
  IncrementProductItemQuantityWithBarcodeMutationVariables,
  IncrementProductItemQuantityWithPriceLookupCodeMutation,
  IncrementProductItemQuantityWithPriceLookupCodeMutationVariables,
  ProductsFilterInput,
  ProductState,
  RemoveAllProductItemsFromCartMutation,
  RemoveAllProductItemsFromCartMutationVariables,
  SellingChannel
} from '../../../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../../utils/pagination'
import {PAGINATION_FRAGMENT} from '../../../graphql'
import {CART_PROPERTIES_FRAGMENT} from '../../graphql'

const PRODUCT_FRAGMENT = gql`
  fragment ProductProperties on Product {
    id
    name
    coverImageURL
    mode
    warehouseProducts(warehouseIds: $warehouseIds) {
      id
      stock
      isNegativeStockEnabled
    }
    pricings {
      division {
        id
      }
      activePricing {
        id
        retailPrice
      }
    }
  }
`

const GET_AVAILABLE_PRODUCTS = gql`
  ${PAGINATION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  query GetAvailableProducts(
    $filter: ProductsFilterInput
    $paginationInput: PaginationInput!
    $warehouseIds: [PositiveInt!]
  ) {
    products(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...ProductProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useGetAvailableProducts = ({
  divisionId,
  filter,
  skip,
  warehouseIds
}: {
  divisionId: number
  filter?: ProductsFilterInput
  skip?: boolean
  warehouseIds?: number[]
}) =>
  useQueryWithPagination<
    GetAvailableProductsQuery,
    GetAvailableProductsQueryVariables
  >(
    GET_AVAILABLE_PRODUCTS,
    {
      variables: {
        filter: {
          state: ProductState.Active,
          availability: {
            divisionId,
            sellingChannel: SellingChannel.Retail
          },
          ...filter
        },
        warehouseIds,
        paginationInput: {
          offset: 0,
          limit: 30
        }
      },
      fetchPolicy: 'network-only',
      skip
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.products.pagination),
      updateData: (prevData, fetchMoreData) => ({
        ...fetchMoreData,
        products: {
          ...fetchMoreData.products,
          items: uniqBy(
            [...prevData.products.items, ...fetchMoreData.products.items],
            ({id}) => id
          )
        }
      })
    }
  )

export const GET_AVAILABLE_PRODUCT_GROUPS = gql`
  query GetAvailableProductGroups(
    $filter: ProductGroupsFilterInput
    $productsFilter: ProductGroupProductsFilterInput
  ) {
    productGroups(filter: $filter, productsFilter: $productsFilter) {
      id
      name
      color
      icon
    }
  }
`

export const GET_AVAILABLE_PRODUCT_GROUP_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query GetAvailableProductGroupProducts(
    $id: PositiveInt!
    $productsFilter: ProductGroupProductsFilterInput
    $warehouseIds: [PositiveInt!]
  ) {
    productGroup(id: $id, productsFilter: $productsFilter) {
      id
      products(productsFilter: $productsFilter) {
        ...ProductProperties
      }
    }
  }
`

const INCREMENT_PRODUCT_ITEM_QUANTITY_MUTATION = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation incrementProductItemQuantity(
    $cartId: PositiveInt
    $productId: PositiveInt!
    $divisionId: PositiveInt!
    $increment: PositiveInt!
    $warehouseId: PositiveInt
  ) {
    incrementProductItemQuantity(
      cartId: $cartId
      productId: $productId
      divisionId: $divisionId
      increment: $increment
      warehouseId: $warehouseId
    ) {
      ...CartProperties
    }
  }
`

export const useIncrementProductItemQuantity = () => {
  const [incrementProductItemQuantity] = useMutation<
    IncrementProductItemQuantityMutation,
    IncrementProductItemQuantityMutationVariables
  >(INCREMENT_PRODUCT_ITEM_QUANTITY_MUTATION)
  return useCallback(
    (variables: IncrementProductItemQuantityMutationVariables) =>
      incrementProductItemQuantity({variables}),
    [incrementProductItemQuantity]
  )
}
export const DECREMENT_PRODUCT_ITEM_QUANTITY_MUTATION = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation decrementProductItemQuantity(
    $cartId: PositiveInt!
    $productItemId: PositiveInt!
    $decrement: PositiveInt!
  ) {
    decrementProductItemQuantity(
      cartId: $cartId
      productItemId: $productItemId
      decrement: $decrement
    ) {
      ...CartProperties
    }
  }
`

export const useDecrementProductItemQuantity = () => {
  const [decrementProductItemQuantity] = useMutation<
    DecrementProductItemQuantityMutation,
    DecrementProductItemQuantityMutationVariables
  >(DECREMENT_PRODUCT_ITEM_QUANTITY_MUTATION)
  return useCallback(
    (variables: DecrementProductItemQuantityMutationVariables) =>
      decrementProductItemQuantity({variables}),
    [decrementProductItemQuantity]
  )
}

export const GET_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  query GetProduct($id: PositiveInt!, $warehouseIds: [PositiveInt!]) {
    product(id: $id) {
      ...ProductProperties
    }
  }
`

const INCREMENT_PRODUCT_ITEM_QUANTITY_WITH_PRICE_LOOKUP_CODE = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation incrementProductItemQuantityWithPriceLookupCode(
    $cartId: PositiveInt
    $priceLookupCode: PositiveInt!
    $divisionId: PositiveInt!
    $increment: PositiveInt!
    $warehouseId: PositiveInt
  ) {
    incrementProductItemQuantityWithPriceLookupCode(
      cartId: $cartId
      priceLookupCode: $priceLookupCode
      divisionId: $divisionId
      increment: $increment
      warehouseId: $warehouseId
    ) {
      ...CartProperties
    }
  }
`

export const useIncrementProductItemQuantityWithPriceLookupCode = () => {
  const [incrementProductItemQuantityWithPriceLookupCode] = useMutation<
    IncrementProductItemQuantityWithPriceLookupCodeMutation,
    IncrementProductItemQuantityWithPriceLookupCodeMutationVariables
  >(INCREMENT_PRODUCT_ITEM_QUANTITY_WITH_PRICE_LOOKUP_CODE)
  return useCallback(
    (
      variables: IncrementProductItemQuantityWithPriceLookupCodeMutationVariables
    ) => incrementProductItemQuantityWithPriceLookupCode({variables}),
    [incrementProductItemQuantityWithPriceLookupCode]
  )
}

const INCREMENT_PRODUCT_ITEM_QUANTITY_WITH_BARCODE = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation incrementProductItemQuantityWithBarcode(
    $cartId: PositiveInt
    $barcode: NonEmptyString!
    $divisionId: PositiveInt!
    $increment: PositiveInt!
    $warehouseId: PositiveInt
  ) {
    incrementProductItemQuantityWithBarcode(
      cartId: $cartId
      barcode: $barcode
      divisionId: $divisionId
      increment: $increment
      warehouseId: $warehouseId
    ) {
      ...CartProperties
    }
  }
`

export const useIncrementProductItemQuantityWithBarcode = () => {
  const [incrementProductItemQuantityWithBarcode] = useMutation<
    IncrementProductItemQuantityWithBarcodeMutation,
    IncrementProductItemQuantityWithBarcodeMutationVariables
  >(INCREMENT_PRODUCT_ITEM_QUANTITY_WITH_BARCODE)
  return useCallback(
    (variables: IncrementProductItemQuantityWithBarcodeMutationVariables) =>
      incrementProductItemQuantityWithBarcode({variables}),
    [incrementProductItemQuantityWithBarcode]
  )
}

const REMOVE_ALL_PRODUCT_ITEMS_FROM_CART = gql`
  ${CART_PROPERTIES_FRAGMENT}
  mutation RemoveAllProductItemsFromCart($cartId: Int!) {
    removeAllProductItemsFromCart(cartId: $cartId) {
      ...CartProperties
    }
  }
`

export const useRemoveAllProductItemsFromCart = () => {
  const [removeAllProductItemsFromCart] = useMutation<
    RemoveAllProductItemsFromCartMutation,
    RemoveAllProductItemsFromCartMutationVariables
  >(REMOVE_ALL_PRODUCT_ITEMS_FROM_CART)
  return useCallback(
    (variables: RemoveAllProductItemsFromCartMutationVariables) =>
      removeAllProductItemsFromCart({variables}),
    [removeAllProductItemsFromCart]
  )
}
