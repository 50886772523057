import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {PanIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const PanPanel: React.FC = () => {
  const {setPanMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode} = useSelector(editorModeSelector)

  const onPanClick = useCallback(() => {
    setPanMode({})
  }, [setPanMode])

  return (
    <Panel>
      <PanelButton
        onClick={onPanClick}
        tooltip={t('Pan')}
        selected={selectedMode === EditorMode.PAN}
        cypressId="pan-button"
      >
        <PanIcon />
      </PanelButton>
    </Panel>
  )
}
