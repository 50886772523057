import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Divider, Paper, Tab, Tabs} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {
  ErrorMessages,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../../hooks/getLocales'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {ButtonWithConfirmationDialog} from '../../../../common'

import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {BOTTOM_BAR_HEIGHT, ShowSection} from '../../../../constants'
import {SecondaryHeader} from '../../Header'
import {useDeleteClientShow} from '../graphql'

const useStyles = makeStyles<Theme, {hideFooter: boolean}>(() => ({
  paper: {
    border: 0
  },
  pageTemplate: {
    gridTemplateRows: ({hideFooter}) =>
      hideFooter ? 'auto 1fr' : `auto 1fr ${BOTTOM_BAR_HEIGHT}px`
  }
}))

interface IEditClientShowPageDetailTemplateProps {
  children: React.ReactNode

  onClose(): void

  showName: string
  formId?: string
  currentTab: ShowSection
  hideFooter?: boolean
}

export const EditClientShowPageDetailTemplate: React.FC<IEditClientShowPageDetailTemplateProps> =
  ({
    children,
    onClose,
    formId,
    showName,
    currentTab,
    hideFooter = false
  }: IEditClientShowPageDetailTemplateProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {showId} = useParams<{showId: string}>()
    const deleteClientShow = useDeleteClientShow()
    const {addInfoNotification, customErrorHandler, setShowBackdrop} =
      useMutationAssistanceHooks()
    const history = useHistory()
    const classes = useStyles({hideFooter})
    const clientLocales = useGetClientLocales()
    const handleTabChange = useCallback(
      (e, newTab) => {
        if (newTab !== currentTab) {
          history.replace(
            routeTo.admin.shows.editWithEditSection(showId, newTab)
          )
        }
      },
      [currentTab, history, showId]
    )
    const handleDeleteShow = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await deleteClientShow(parseInt(showId, 10))
        addInfoNotification(t('Show has been deleted'))
        history.replace(routeTo.admin.shows.home())
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.ShowHasEvents
          )
        ) {
          customErrorHandler(error, {
            title: t('Show cannot be deleted'),
            contentText: t(
              'The show cannot be deleted because it has associated events. Please delete all related events before attempting to delete the show.'
            ),
            confirmButtonLabel: t('Got it'),
            onConfirm: () => window.location.reload()
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.ShowHasTours
          )
        ) {
          customErrorHandler(error, {
            title: t('Show cannot be deleted'),
            contentText: t(
              'The show cannot be deleted because it has associated tours. Please delete all related tours before attempting to delete the show.'
            ),
            confirmButtonLabel: t('Got it'),
            onConfirm: () => window.location.reload()
          })
        } else {
          customErrorHandler(error, {
            title: t('Show cannot be deleted'),
            contentText: error.graphQLErrors[0].message,
            confirmButtonLabel: t('Got it'),
            onConfirm: () => window.location.reload()
          })
        }
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      customErrorHandler,
      deleteClientShow,
      history,
      setShowBackdrop,
      showId,
      t
    ])
    return (
      <PageWithHeaderAndFooterTemplate
        className={classes.pageTemplate}
        header={
          <div>
            <SecondaryHeader
              title={showName}
              onLeftActionClick={onClose}
              hasArrowBackIcon
            />
            <Paper variant="outlined" square className={classes.paper}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={t('General')} value={ShowSection.Data} />
                {clientLocales.length > 1 && (
                  <Tab
                    label={t('Translations')}
                    value={ShowSection.Translations}
                  />
                )}
                <Tab label={t('Media')} value={ShowSection.Media} />
                {P([PermissionCode.UpsertClientShowCrew]) && (
                  <Tab label={t('Crew')} value={ShowSection.Crew} />
                )}
              </Tabs>
            </Paper>
            <Divider />
          </div>
        }
        footer={
          hideFooter ? undefined : (
            <Footer>
              {P([PermissionCode.DeleteClientShow]) && (
                <ButtonWithConfirmationDialog
                  onConfirmButtonClick={handleDeleteShow}
                  dialogProps={{
                    confirmButtonLabel: t('Delete'),
                    title: t('Delete show?'),
                    contentText: t(
                      'Are you sure you want to delete this show? This can’t be undone.'
                    )
                  }}
                  buttonProps={{
                    startIcon: <DeleteIcon />,
                    children: t('Delete'),
                    color: 'primary'
                  }}
                />
              )}
              <Button
                type="submit"
                color="primary"
                variant="contained"
                form={formId}
              >
                {t('Save')}
              </Button>
            </Footer>
          )
        }
      >
        {children}
      </PageWithHeaderAndFooterTemplate>
    )
  }
