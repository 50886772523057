import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Box, IconButton, SxProps, Typography} from '@mui/material'
import React from 'react'
import {Theme} from '../../../../../theme'

interface ITourListItemWithRemoveButtonProps {
  label: string
  secondaryLabel?: string
  price: string
  priceBeforeDiscountLabel?: string
  onRemoveButtonClick: () => void
  sx?: SxProps<Theme>
  isRemoveButtonHidden: boolean
  isRemoveButtonDisabled?: boolean
}

export const TourListItemWithRemoveButton: React.FC<ITourListItemWithRemoveButtonProps> =
  ({
    label,
    secondaryLabel,
    price,
    priceBeforeDiscountLabel,
    onRemoveButtonClick,
    sx,
    isRemoveButtonHidden,
    isRemoveButtonDisabled
  }: ITourListItemWithRemoveButtonProps) => {
    return (
      <Box
        sx={{
          display: 'grid',
          minHeight: 56,
          gridTemplateColumns: 'auto 1fr auto',
          alignItems: 'center',
          ...sx
        }}
      >
        {!isRemoveButtonHidden && (
          <IconButton
            disabled={isRemoveButtonDisabled}
            onClick={onRemoveButtonClick}
            color="primary"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
        <Box
          sx={{
            padding: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
          {secondaryLabel && (
            <Typography variant="caption" color="textSecondary">
              {secondaryLabel}
            </Typography>
          )}
        </Box>
        <Box sx={{alignSelf: 'center', textAlign: 'right'}}>
          <Typography variant="body2">{price}</Typography>
          {priceBeforeDiscountLabel && (
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{textDecoration: 'line-through'}}
            >
              {priceBeforeDiscountLabel}
            </Typography>
          )}
        </Box>
      </Box>
    )
  }
