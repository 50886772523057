import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import {Chip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {PRIMARY_100_COLOR, Theme} from '../../../theme'

const useStyles = makeStyles<Theme, {isClickable: boolean}>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "title        price"
      "description  price"
    `,
    gridTemplateColumns: `1fr auto`,
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    '&:hover': {
      background: ({isClickable}) =>
        isClickable ? theme.palette.action.hover : 'none',
      cursor: ({isClickable}) => (isClickable ? 'pointer' : 'normal')
    }
  },
  title: {
    gridArea: 'title',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(1),
    gap: theme.spacing(0.5),
    gridAutoFlow: 'column'
  },
  price: {
    gridArea: 'price'
  },
  description: {
    gridArea: 'description'
  },
  chip: {
    paddingRight: theme.spacing(0.5)
  },
  chipRoot: {
    background: PRIMARY_100_COLOR,
    color: theme.palette.primary.main,
    height: 'auto'
  },
  chipLabelSmall: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  }
}))

interface ICartSummaryItemProps {
  count: number
  description: string
  title: string
  showBookmark?: boolean
  price: React.ReactNode
  onClick?: () => void
}

export const CartSummaryItem: React.FC<ICartSummaryItemProps> = ({
  count,
  description,
  title,
  showBookmark,
  price,
  onClick
}: ICartSummaryItemProps) => {
  const classes = useStyles({isClickable: Boolean(onClick)})
  return (
    <div className={classes.root} onClick={onClick}>
      <Typography variant="subtitle2" className={classes.title}>
        {showBookmark && <BookmarkBorderIcon fontSize="small" />}
        {title}
      </Typography>
      <Typography variant="body2" className={classes.price}>
        {price}
      </Typography>
      <div className={classes.description}>
        <span className={classes.chip}>
          <Chip
            color="primary"
            label={`${count}x`}
            size="small"
            classes={{
              root: classes.chipRoot,
              labelSmall: classes.chipLabelSmall
            }}
          />
        </span>
        <Typography variant="caption">{description}</Typography>
      </div>
    </div>
  )
}
