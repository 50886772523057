import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  AdmissionTypesStatisticsFilterInput,
  EventsFilterInput,
  PermissionCode,
  ShowFormatCode,
  ShowGenreCode,
  ShowSoundMixCode,
  ShowTypeCode,
  ShowVersionCode
} from '../../../../../__generated__/schema'
import {useGetGenres, useTranslateGenre} from '../../../../../hooks/showGenres'
import {
  useGetShowTypeCodes,
  useTranslateShowType
} from '../../../../../hooks/showTypes'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../../../../../hooks/translateDistributions'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  Search,
  useCombineStringifySearchObjectFunctions
} from '../../../../common'
import {AdvancedSearchBase} from '../../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption,
  useSearchSelect
} from '../../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../../common/search/daterangeSearch'
import {QuickSearchDaterangeRow} from '../../../../common/search/QuickSearchDaterangeRow'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../../common/search/types'
import {
  useGetLightweightDivisions,
  useGetLightweightMarketingLabels
} from '../../graphql'
import {useGetFilterEventVenues} from '../graphql'

type ExtendedEventsFilter = EventsFilterInput & {
  _eventStartDaterangeId?: DATERANGE_IDS
}

const stripEventStartDateFromFilter = (
  filter: ExtendedEventsFilter
): EventsFilterInput => {
  return omit(filter, ['from', 'to', '_eventStartDaterangeId'])
}

const stripHelperKeysFromFilter = (
  filter: ExtendedEventsFilter
): EventsFilterInput => {
  return omit(filter, ['_eventStartDaterangeId'])
}

export const DEFAULT_EVENTS_FILTER_INPUT: EventsFilterInput = {
  hasText: ''
}

const getHasTextFromFilter = (filter: EventsFilterInput) =>
  filter.hasText || undefined

const getVenueIdFromFilter = (filter: EventsFilterInput) =>
  filter.venueId || undefined

const getDivisionIdFromFilter = (filter: EventsFilterInput) =>
  filter.divisionId || undefined

const getTypeCodeFromFilter = (filter: EventsFilterInput) =>
  filter.typeCode || undefined

const getGenreCodeFromFilter = (filter: EventsFilterInput) =>
  filter.genreCode || undefined

const getFormatCodeFromFilter = (filter: EventsFilterInput) =>
  filter.formatCode || undefined

const getVersionCodeFromFilter = (filter: EventsFilterInput) =>
  filter.versionCode || undefined

const getSoundMixCodeFromFilter = (filter: EventsFilterInput) =>
  filter.soundMixCode || undefined

const getMarketingLabelIdFromFilter = (filter: EventsFilterInput) =>
  filter.marketingLabelId || undefined

const mapHasTextToFilter = (
  filter: EventsFilterInput,
  hasText: string
): EventsFilterInput => ({
  ...filter,
  hasText
})

const mapVenueIdToFilter = (
  filter: EventsFilterInput,
  venueId: number | undefined
): EventsFilterInput => ({
  ...filter,
  venueId
})

const mapDivisionIdToFilter = (
  filter: EventsFilterInput,
  divisionId: number | undefined
): EventsFilterInput => ({
  ...filter,
  divisionId
})

const mapTypeCodeToFilter = (
  filter: EventsFilterInput,
  typeCode: ShowTypeCode | undefined
): EventsFilterInput => ({
  ...filter,
  typeCode
})

const mapGenreCodeToFilter = (
  filter: EventsFilterInput,
  genreCode: ShowGenreCode | undefined
): EventsFilterInput => ({
  ...filter,
  genreCode
})

const mapFormatCodeToFilter = (
  filter: EventsFilterInput,
  formatCode: ShowFormatCode | undefined
): EventsFilterInput => ({
  ...filter,
  formatCode
})

const mapVersionCodeToFilter = (
  filter: EventsFilterInput,
  versionCode: ShowVersionCode | undefined
): EventsFilterInput => ({
  ...filter,
  versionCode
})

const mapSoundMixVersionCodeToFilter = (
  filter: EventsFilterInput,
  soundMixCode: ShowSoundMixCode | undefined
): EventsFilterInput => ({
  ...filter,
  soundMixCode
})

const mapMarketingLabelIdToFilter = (
  filter: EventsFilterInput,
  marketingLabelId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  marketingLabelId
})

const eventStartDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.TOMORROW,
  DATERANGE_IDS.THIS_WEEKEND,
  DATERANGE_IDS.NEXT_WEEK,
  DATERANGE_IDS.NEXT_WEEKEND,
  DATERANGE_IDS.NEXT_MONTH
]

interface IEventsSearchProps {
  onFilterChange: (filter: EventsFilterInput) => void
}

export const EventsSearch: React.FC<IEventsSearchProps> = ({
  onFilterChange
}: IEventsSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [enabledDivisions] = useLocalStorageState<number[]>(
    LocalStorageKey.EnabledDivisions,
    []
  )

  const {venues} = useGetFilterEventVenues()

  const venueSelectOptions: ISelectOption<number>[] = venues.map((venue) => ({
    id: venue.id,
    label: venue.name
  }))

  const {
    getStringifiedSelectValueFromSearchObject: getVenueIdFromSearchObject
  } = useSearchSelect<EventsFilterInput, number>({
    selectInputPrefix: t('Venue'),
    selectOptions: venueSelectOptions,
    getSelectValueFromSearchObject: getVenueIdFromFilter
  })

  const {divisions} = useGetLightweightDivisions()

  const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
    (division) => ({
      id: division.id,
      label: division.name
    })
  )

  const {marketingLabels} = useGetLightweightMarketingLabels()
  const marketingLabelSelectOptions: ISelectOption<number>[] =
    marketingLabels.map(({id, name}) => ({
      id,
      label: name
    }))

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedMarketingLabelFromSearchObject
  } = useSearchSelect<EventsFilterInput, number>({
    selectInputPrefix: t('Marketing label'),
    selectOptions: marketingLabelSelectOptions,
    getSelectValueFromSearchObject: getMarketingLabelIdFromFilter
  })

  const {
    getStringifiedSelectValueFromSearchObject: getDivisionIdFromSearchObject
  } = useSearchSelect<EventsFilterInput, number>({
    selectInputPrefix: t('Division'),
    selectOptions: divisionSelectOptions,
    getSelectValueFromSearchObject: getDivisionIdFromFilter
  })

  const translateShowType = useTranslateShowType()
  const showTypes = useGetShowTypeCodes()

  const showTypeSelectOptions: ISelectOption<ShowTypeCode>[] = showTypes.map(
    (showTypeCode) => ({
      id: showTypeCode,
      label: translateShowType(showTypeCode)
    })
  )

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedTypeCodeFromSearchObject
  } = useSearchSelect<EventsFilterInput, string>({
    selectInputPrefix: t('Type'),
    selectOptions: showTypeSelectOptions,
    getSelectValueFromSearchObject: getTypeCodeFromFilter
  })

  const translateGenre = useTranslateGenre()

  const showGenreCodes = useGetGenres()
  const showGenreSelectOptions: ISelectOption<ShowGenreCode>[] =
    showGenreCodes.map((genreCode) => ({
      id: genreCode,
      label: translateGenre(genreCode)
    }))

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedGenreCodeFromSearchObject
  } = useSearchSelect<EventsFilterInput, string>({
    selectInputPrefix: t('Genre'),
    selectOptions: showGenreSelectOptions,
    getSelectValueFromSearchObject: getGenreCodeFromFilter
  })

  const translateShowFormat = useTranslateShowFormat()
  const showFormatSelectOptions = Object.values(ShowFormatCode).map(
    (formatCode) => ({
      id: formatCode,
      label: translateShowFormat(formatCode)
    })
  )

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedFormatCodeFromSearchObject
  } = useSearchSelect<EventsFilterInput, string>({
    selectInputPrefix: t('Format'),
    selectOptions: showFormatSelectOptions,
    getSelectValueFromSearchObject: getFormatCodeFromFilter
  })

  const translateShowVersion = useTranslateShowVersion()
  const showVersionSelectOptions = Object.values(ShowVersionCode).map(
    (versionCode) => ({
      id: versionCode,
      label: translateShowVersion(versionCode)
    })
  )

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedVersionCodeFromSearchObject
  } = useSearchSelect<EventsFilterInput, string>({
    selectInputPrefix: t('Version'),
    selectOptions: showVersionSelectOptions,
    getSelectValueFromSearchObject: getVersionCodeFromFilter
  })

  const translateSoundMixCode = useTranslateShowSoundMix()
  const showSoundMixSelectOptions = Object.values(ShowSoundMixCode).map(
    (soundMixCode) => ({
      id: soundMixCode,
      label: translateSoundMixCode(soundMixCode)
    })
  )

  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedSoundMixCodeFromSearchObject
  } = useSearchSelect<EventsFilterInput, string>({
    selectInputPrefix: t('Sound mix'),
    selectOptions: showSoundMixSelectOptions,
    getSelectValueFromSearchObject: getSoundMixCodeFromFilter
  })

  const {
    daterangeOptions: eventStartDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomEventStartDateRangeToFilter,
    mapDaterangeToSearchObject: mapEventStartDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedEventStartDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsEventStartDaterangeOptionActive
  } = useDateRangeSearch<ExtendedEventsFilter>({
    usedDateranges: eventStartDateDateRanges,
    dateRangeInputPrefix: t('Event start'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.from || undefined,
      endDateISOString: o.to || undefined,
      id: o._eventStartDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      from: input.startDate,
      to: input.endDate,
      _eventStartDaterangeId: input.id
    })
  })

  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<EventsFilterInput>(
      getHasTextFromFilter,
      getVenueIdFromSearchObject,
      getDivisionIdFromSearchObject,
      getStringifiedTypeCodeFromSearchObject,
      getStringifiedGenreCodeFromSearchObject,
      getStringifiedFormatCodeFromSearchObject,
      getStringifiedVersionCodeFromSearchObject,
      getStringifiedSoundMixCodeFromSearchObject,
      getStringifiedEventStartDateDateRangeFromFilter,
      getStringifiedMarketingLabelFromSearchObject
    )

  return (
    <Search<EventsFilterInput, ExtendedEventsFilter>
      storageKey="EVENTS_LIST"
      placeholder={t('Search for events')}
      defaultSearchObject={DEFAULT_EVENTS_FILTER_INPUT}
      mapInputTextToSearchObject={mapHasTextToFilter}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      onChange={onFilterChange}
      renderQuickOptions={(setSearchObject) => (
        <QuickSearchDaterangeRow<EventsFilterInput>
          label={t('Event start')}
          daterangeOptions={eventStartDateDaterangeOptions}
          mapDaterangeToSearchObject={mapEventStartDaterangeToFilter}
          mapCustomDaterangeToSearchObject={
            mapCustomEventStartDateRangeToFilter
          }
          searchObject={DEFAULT_EVENTS_FILTER_INPUT}
          setSearchObject={setSearchObject}
          customDaterangeDialogTitle={t('Select date range')}
          customDaterangeDialogDescription={t(
            'Select date range of event start'
          )}
        />
      )}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow
            label={t('Event start')}
            daterangeOptions={eventStartDateDaterangeOptions}
            mapDaterangeToSearchObject={mapEventStartDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomEventStartDateRangeToFilter
            }
            stripDaterangeFromSearchObject={stripEventStartDateFromFilter}
            getIsDaterangeOptionActive={getIsEventStartDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          {enabledDivisions.length === 0 && (
            <AdvancedSearchSelectRow<EventsFilterInput, number>
              label={t('Division')}
              value={getDivisionIdFromFilter(advancedSearchObject)}
              options={divisionSelectOptions}
              searchObject={advancedSearchObject}
              setSearchObject={setAdvancedSearchObject}
              mapSelectValueToSearchObject={mapDivisionIdToFilter}
            />
          )}
          <AdvancedSearchTextRow
            label={t('Event title')}
            placeholder={t('Enter event name...')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchSelectRow<EventsFilterInput, number>
            label={t('Venue')}
            value={getVenueIdFromFilter(advancedSearchObject)}
            options={venueSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapVenueIdToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Type of show')}
            value={getTypeCodeFromFilter(advancedSearchObject)}
            options={showTypeSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapTypeCodeToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Genre')}
            value={getGenreCodeFromFilter(advancedSearchObject)}
            options={showGenreSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapGenreCodeToFilter}
          />
          {P([PermissionCode.ReadMarketingLabels]) && (
            <AdvancedSearchSelectRow<EventsFilterInput, number>
              label={t('Marketing label')}
              value={getMarketingLabelIdFromFilter(advancedSearchObject)}
              options={marketingLabelSelectOptions}
              searchObject={advancedSearchObject}
              setSearchObject={setAdvancedSearchObject}
              mapSelectValueToSearchObject={mapMarketingLabelIdToFilter}
            />
          )}
          <AdvancedSearchSelectRow
            label={t('Format')}
            value={getFormatCodeFromFilter(advancedSearchObject)}
            options={showFormatSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapFormatCodeToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Version')}
            value={getVersionCodeFromFilter(advancedSearchObject)}
            options={showVersionSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapVersionCodeToFilter}
          />
          <AdvancedSearchSelectRow
            label={t('Sound mix')}
            value={getSoundMixCodeFromFilter(advancedSearchObject)}
            options={showSoundMixSelectOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapSoundMixVersionCodeToFilter}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
