import {Box, Card, Divider, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

// TODO: based on the future usage decide whether it is worth to have such abstraction

interface IStackableCardProps {
  children: React.ReactNode
}

export const ITEM_HEIGHT = 64

const useStyles = makeStyles<Theme>((theme) => ({
  itemWrapper: {
    padding: theme.spacing(1, 2),
    width: '100%'
  },
  itemHeight: {
    minHeight: ITEM_HEIGHT,
    display: 'flex',
    alignItems: 'center'
  }
}))

export const StackableCard: React.FC<IStackableCardProps> = ({
  children
}: IStackableCardProps) => {
  const classes = useStyles()
  return <div className={classes.itemWrapper}>{children}</div>
}

export const StackableCardWrapper = Card

export const StackableCardDivider = Divider

export const MainLabel = ({label}: {label: string}) => (
  <Typography component="div" variant="caption">
    <Box fontWeight="fontWeightBold">{label}</Box>
  </Typography>
)

export const SecondaryLabel = ({label}: {label: string}) => (
  <Typography color="textSecondary" variant="caption" component="div">
    {label}
  </Typography>
)

interface IBasePropertyStackableCardHeaderProps {
  mainLabel: string
  secondaryLabel: string
}

export const BasePropertyStackableCardHeader: React.FC<IBasePropertyStackableCardHeaderProps> =
  ({mainLabel, secondaryLabel}: IBasePropertyStackableCardHeaderProps) => {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{height: '100%'}}
      >
        <MainLabel label={mainLabel} />
        <SecondaryLabel label={secondaryLabel} />
      </Grid>
    )
  }

interface IBasePropertyStackableCardProps {
  left: React.ReactElement
  right: React.ReactElement
}

export const BasePropertyStackableCard: React.FC<IBasePropertyStackableCardProps> =
  ({left, right}: IBasePropertyStackableCardProps) => {
    const classes = useStyles()

    return (
      <Box className={classes.itemHeight}>
        <StackableCard>
          <Grid container>
            <Grid item xs={7}>
              {left}
            </Grid>
            <Grid item xs={5}>
              <Grid
                container
                sx={{height: '100%'}}
                justifyContent="flex-end"
                alignItems="center"
              >
                {right}
              </Grid>
            </Grid>
          </Grid>
        </StackableCard>
      </Box>
    )
  }
