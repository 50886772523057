import React, {useCallback} from 'react'
import {useBooleanState} from '../../hooks/state'
import {
  ConfirmationDialog,
  IConfirmationDialogProps
} from './ConfirmationDialog'
import {IMenuItemProps, MenuItem} from './Menu'

interface IMenuItemWithConfirmationDialogProps {
  onConfirmButtonClick: () => void
  menuItemProps: IMenuItemProps
  dialogProps: Pick<
    IConfirmationDialogProps,
    'title' | 'contentText' | 'confirmButtonLabel'
  >
}

export const MenuItemWithConfirmationDialog: React.FC<IMenuItemWithConfirmationDialogProps> =
  ({
    onConfirmButtonClick,
    menuItemProps,
    dialogProps
  }: IMenuItemWithConfirmationDialogProps) => {
    const {
      state: isOpen,
      setTrue: setOpened,
      setFalse: setClosed
    } = useBooleanState(false)
    const handleConfirmButtonClick = useCallback(() => {
      setClosed()
      onConfirmButtonClick()
    }, [onConfirmButtonClick, setClosed])
    return (
      <>
        <MenuItem {...menuItemProps} onClick={setOpened} />
        <ConfirmationDialog
          {...dialogProps}
          isOpen={isOpen}
          onCancel={setClosed}
          onConfirm={handleConfirmButtonClick}
        />
      </>
    )
  }
