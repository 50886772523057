import {Paper, Typography} from '@mui/material'
import React from 'react'

const endChars = '...'
const titleMaxLength = 60
const descriptionMaxLength = 160

interface ISearchResultPreviewProps {
  title: string
  url: string
  description?: string
}

export const SearchResultPreview: React.FC<ISearchResultPreviewProps> = ({
  title,
  url,
  description
}: ISearchResultPreviewProps) => (
  <Paper
    variant="outlined"
    sx={{px: 2, py: 1.5, display: 'flex', flexDirection: 'column', gap: 0.5}}
  >
    <Typography variant="subtitle2" color="info.main">
      {title.length > titleMaxLength
        ? title.substr(0, 60).concat(endChars)
        : title}
    </Typography>
    <Typography variant="caption" color="success.light">
      {url}
    </Typography>
    {description && (
      <Typography variant="caption" color="textSecondary">
        {description.length > descriptionMaxLength
          ? description.substr(0, 160).concat(endChars)
          : description}
      </Typography>
    )}
  </Paper>
)
