import {
  DateMarker,
  DayCellContentArg,
  DayHeaderContentArg,
  EventClickArg,
  ViewContentArg
} from '@fullcalendar/common'
import FullCalendar, {CalendarApi, CalendarOptions} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {ResourceLabelContentArg} from '@fullcalendar/resource-common'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import scrollgridPlugin from '@fullcalendar/scrollgrid'
import {Backdrop, CircularProgress, Tab, Tabs} from '@mui/material'
import {ClassNameMap, makeStyles, useTheme} from '@mui/styles'
import dayjs from 'dayjs'
import * as i18next from 'i18next'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {LocaleCode} from '../../../../../__generated__/schema'
import {useElementDimensions} from '../../../../../hooks/dimensions'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {capitalizeFirstLetter} from '../../../../../utils/capitalizeFirstLetter'
import {routeTo} from '../../../../../utils/routes'
import {useLocale} from '../../../../context/locale'
import {useDayHeaderContentState} from '../context/dayHeaderContentState'
import {useEventCalendarSettings} from '../context/eventCalendarSettingsProvider'
import {useEventCalendarView} from '../context/eventCalendarView'
import {useResourceLabelContentState} from '../context/resourceLabelContentState'
import {CreateEventDrawer} from '../create'
import {IEvent, IEventsScreenData} from '../graphql'
import {useDateState} from '../stateUtils/dateState'
import {useVenueState} from '../stateUtils/venueState'
import {CalendarHeader} from './CalendarHeader'
import {CalendarSettingsDialog} from './CalendarSettingsDialog'
import {
  DateHeaderContent,
  FUTURE_BG_COLOR,
  PAST_BG_COLOR
} from './DayHeaderContent'
import {DayHeaderDropdown, ResourceLabelDropdown} from './Dropdowns'
import {EventContent} from './EventContent'
import {
  useAllowEventDragAndDrop,
  useEventDrop,
  useEventReceive,
  useEventResize,
  useGetEventClassNames
} from './eventHandlers'
import {ResourceLabelContent} from './ResourceLabelContent'
import {CalendarSettingsFieldName, SlotDuration, ViewType} from './types'

const getPrevTooltipTitle = (t: i18next.TFunction, type: ViewType): string => {
  switch (type) {
    case ViewType.Day:
      return t('Previous day')
    case ViewType.Month:
      return t('Previous month')
    case ViewType.Week:
    default:
      return t('Previous week')
  }
}

const getNextTooltipTitle = (t: i18next.TFunction, type: ViewType): string => {
  switch (type) {
    case ViewType.Day:
      return t('Next day')
    case ViewType.Month:
      return t('Next month')
    case ViewType.Week:
    default:
      return t('Next week')
  }
}

const TIME_FORMAT: CalendarOptions['slotLabelFormat'] = {
  hour: '2-digit',
  minute: '2-digit',
  meridiem: false,
  hour12: false
}

const useViewsConfig = (localeCode: LocaleCode): CalendarOptions['views'] => ({
  [ViewType.Week]: {
    type: ViewType.Week,
    duration: {weeks: 1},
    titleFormat: {
      year: 'numeric',
      month: 'short',
      weekday: undefined
    }
  },
  [ViewType.Month]: {
    showNonCurrentDates: false,
    titleFormat: {
      year: 'numeric',
      month: localeCode === LocaleCode.Sk ? 'short' : 'long',
      weekday: undefined
    }
  },
  [ViewType.Day]: {
    titleFormat: {
      day: 'numeric',
      month: 'numeric',
      weekday: 'long'
    }
  }
})

const PLUGINS = [
  dayGridPlugin,
  interactionPlugin,
  resourceTimeGridPlugin,
  scrollgridPlugin
]

interface IEventsCalendarContentProps {
  data: IEventsScreenData
  isLoadingMore?: boolean
}

export const borderOnEndOfWeek = '2px solid black'

const useStyles = makeStyles<Theme, {hasVenuesTab: boolean}>((theme) => ({
  calendarContentRoot: {
    display: 'grid',
    paddingLeft: theme.spacing(2),
    gridAutoRows: 'auto 1fr',
    height: '100%'
  },
  calendarHeader: {
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: ({hasVenuesTab}) => (hasVenuesTab ? 0 : theme.spacing(2)),
    overflow: 'hidden'
  },
  fullCalendarBox: {
    // this overflow is important to process shrinking during resize properly
    overflow: 'auto',
    position: 'relative'
  },
  nowIndicator: {
    '.fc &.fc-timegrid-now-indicator-arrow': {
      borderColor: theme.palette.primary.main,
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent'
    },
    '.fc &.fc-timegrid-now-indicator-line': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px 0 0'
    }
  },
  past: {
    background: PAST_BG_COLOR
  },
  feature: {
    background: FUTURE_BG_COLOR
  },
  lineOnEndOfWeek: {
    '&:nth-child(7n)': {
      borderRight: borderOnEndOfWeek
    }
  },
  viewWeek: {
    '& .fc-timegrid-axis-frame': {
      height: `93px !important`
    },
    '& .fc-event-main': {
      padding: 0
    },
    '& .fc-timegrid-event.fc-event': {
      borderWidth: 0
    },
    '& .fc-timegrid-col.fc-day-today': {
      backgroundColor: theme.palette.common.white
    }
  },
  viewDay: {
    '& .fc-timegrid-col.fc-day-today': {
      backgroundColor: 'inherit'
    },
    '& .fc-timegrid-axis-frame': {
      height: `40px !important`
    }
  },
  resourceLabel: {
    ...theme.typography.subtitle1,
    background: theme.palette.background.paper,
    height: theme.spacing(5),
    color: theme.palette.primary.main,
    '.fc &': {
      verticalAlign: 'middle'
    }
  },
  resourceLabelWithBorder: {
    '.fc &': {
      borderRight: borderOnEndOfWeek
    }
  },
  tabLabel: {
    display: 'block',
    width: '100%'
  },
  tab: {
    maxWidth: 'fit-content'
  },
  backdrop: {
    zIndex: 1,
    position: 'absolute'
  }
}))

const getIsPast = (date: DateMarker) =>
  dayjs(date).startOf('day').isBefore(dayjs().startOf('day'))

const classNamesGetters = (classes: ClassNameMap<string>) => ({
  getDayCellClassNames: (args: DayCellContentArg) =>
    getIsPast(args.date)
      ? [classes.past, classes.lineOnEndOfWeek]
      : [classes.feature, classes.lineOnEndOfWeek],
  getDayHeaderClassNames: (args: DayHeaderContentArg) =>
    getIsPast(args.date)
      ? [classes.past, classes.lineOnEndOfWeek]
      : [classes.feature, classes.lineOnEndOfWeek],
  getViewClassNames: (args: ViewContentArg) =>
    args.view.type === ViewType.Week ? [classes.viewWeek] : [classes.viewDay],
  getResourceLabelClassNames: (args: ResourceLabelContentArg) =>
    args.view.type === ViewType.Week
      ? [classes.resourceLabel, classes.resourceLabelWithBorder]
      : args.view.type === ViewType.Day
      ? [classes.resourceLabel]
      : []
})

const getEventsInAuditoriumFilter = (auditoriumId: number) => (event: IEvent) =>
  auditoriumId.toString() === event.resourceId

export const EventsCalendarContent: React.FC<IEventsCalendarContentProps> = ({
  data,
  isLoadingMore
}: IEventsCalendarContentProps) => {
  const theme: Theme = useTheme()
  const {t} = useTranslation()
  const history = useHistory()

  const [api, setApi] = useState<CalendarApi | null>(null)
  const calendarComponentRef = useRef<FullCalendar | null>(null)
  useEffect(() => {
    if (calendarComponentRef && calendarComponentRef.current) {
      setApi(calendarComponentRef.current.getApi())
    }
  }, [calendarComponentRef])

  const {venueId, onVenueChange} = useVenueState(
    api,
    data,
    data.sortedVenues[0].id
  )
  const [
    {
      [CalendarSettingsFieldName.FIRST_DAY_OF_WEEK]: firstDayOfWeek,
      [CalendarSettingsFieldName.SHORTEN_VENUE_NAMES]: shortenVenueNames,
      [CalendarSettingsFieldName.SCROLL_TIME]: scrollTime,
      [CalendarSettingsFieldName.DENSITY_SETTINGS]: slotDuration
    }
  ] = useEventCalendarSettings()
  useEffect(() => {
    api?.scrollToTime(scrollTime)
  }, [api, scrollTime])
  const {selectedDate, changeDate, onNext, onPrev, onToday} = useDateState(api)
  const {viewType, setViewType} = useEventCalendarView()

  const changeView = useCallback(
    (viewType: ViewType) => {
      setViewType(viewType)
      api?.changeView(viewType, selectedDate)
    },
    [api, selectedDate, setViewType]
  )

  const openDayPreview = useCallback(
    (day: Date) => {
      changeView(ViewType.Day)
      changeDate(day)
    },
    [changeDate, changeView]
  )

  const getEventClassNames = useGetEventClassNames()
  const eventReceive = useEventReceive()
  const eventDrop = useEventDrop()
  const eventResize = useEventResize()
  const eventAllow = useAllowEventDragAndDrop(viewType, isLoadingMore)

  const eventClick = useCallback(
    ({event}: EventClickArg) => {
      history.push(routeTo.admin.events.edit(event.extendedProps.id))
    },
    [history]
  )

  const hasVenuesTab = data.sortedVenues.length > 1
  const classes = useStyles({hasVenuesTab})

  const {localeCode} = useLocale()
  const {
    getDayCellClassNames,
    getDayHeaderClassNames,
    getViewClassNames,
    getResourceLabelClassNames
  } = classNamesGetters(classes)
  const [calendarBoxRef, {height}] = useElementDimensions<HTMLDivElement>()
  const viewsConfig = useViewsConfig(localeCode)

  const {
    state: isSettingsDialogOpened,
    setFalse: closeSettingsDialog,
    setTrue: openSettingsDialog
  } = useBooleanState(false)

  const [dayHeaderContentState, setDayHeaderContentState] =
    useDayHeaderContentState()

  const [resourceLabelContentState, setResourceLabelContentState] =
    useResourceLabelContentState()
  return (
    <>
      <div className={classes.calendarContentRoot}>
        <div className={classes.calendarHeader}>
          <CalendarHeader
            title={api ? capitalizeFirstLetter(api.view.title) : ''}
            openSettingsDialog={openSettingsDialog}
            {...{
              onToday,
              onPrev,
              onNext,
              selectedDate,
              changeDate,
              viewType,
              changeView
            }}
            prevTooltipTitle={getPrevTooltipTitle(t, viewType)}
            nextTooltipTitle={getNextTooltipTitle(t, viewType)}
          />
          {hasVenuesTab && (
            <Tabs
              value={venueId}
              onChange={onVenueChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {data.sortedVenues.map((venue) => (
                <Tab
                  classes={{
                    root: classes.tab
                  }}
                  label={
                    shortenVenueNames
                      ? venue.secondaryName || venue.name
                      : venue.name
                  }
                  value={venue.id}
                  key={venue.id}
                />
              ))}
            </Tabs>
          )}
        </div>
        <div ref={calendarBoxRef} className={classes.fullCalendarBox}>
          <FullCalendar
            schedulerLicenseKey={
              process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY
            }
            locale={localeCode}
            viewClassNames={getViewClassNames}
            nowIndicator
            nowIndicatorClassNames={classes.nowIndicator}
            dayHeaderClassNames={getDayHeaderClassNames}
            height={height}
            eventColor={theme.palette.background.paper}
            eventTextColor={theme.palette.text.primary}
            allDaySlot={false}
            headerToolbar={false}
            scrollTime={scrollTime}
            slotLabelFormat={TIME_FORMAT}
            eventTimeFormat={TIME_FORMAT}
            initialView={viewType}
            plugins={PLUGINS}
            resources={data.venues[venueId].auditoriums.map((auditorium) => ({
              ...auditorium,
              id: auditorium.id.toString()
            }))}
            resourceOrder="order"
            resourceLabelClassNames={getResourceLabelClassNames}
            resourceLabelContent={ResourceLabelContent}
            views={viewsConfig}
            ref={calendarComponentRef}
            events={data.events[venueId].map(({id, duration, ...event}) => ({
              ...event,
              id: id.toString(),
              myDuration: duration
            }))}
            firstDay={firstDayOfWeek}
            editable={viewType !== ViewType.Month} // whether allow to drag already created events in calendar
            eventDrop={eventDrop}
            eventReceive={eventReceive}
            eventAllow={eventAllow}
            eventClick={eventClick}
            eventResize={eventResize}
            snapDuration="00:05:00"
            slotDuration={slotDuration || SlotDuration.Standard}
            eventContent={EventContent}
            eventClassNames={getEventClassNames}
            displayEventEnd={false}
            dayMinWidth={viewType === ViewType.Month ? 257 : 82}
            dayHeaderContent={DateHeaderContent}
            dayCellClassNames={getDayCellClassNames}
            navLinks={viewType === ViewType.Month}
            navLinkDayClick={
              viewType === ViewType.Month
                ? (date) => openDayPreview(date)
                : undefined
            }
          />
          <Backdrop className={classes.backdrop} open={!!isLoadingMore}>
            <CircularProgress color="primary" />
          </Backdrop>
        </div>
      </div>
      {api && <CreateEventDrawer api={api} venueId={venueId} />}
      {viewType === ViewType.Week && !!dayHeaderContentState.clickInfo && (
        <DayHeaderDropdown
          onDayPreviewClick={openDayPreview}
          clickInfo={dayHeaderContentState.clickInfo}
          copyInfo={dayHeaderContentState.copyInfo}
          setDayHeaderContentState={setDayHeaderContentState}
          eventsInAuditorium={data.events[venueId].filter(
            getEventsInAuditoriumFilter(
              dayHeaderContentState.clickInfo.auditoriumId
            )
          )}
        />
      )}
      {[ViewType.Week, ViewType.Day].includes(viewType) &&
        resourceLabelContentState && (
          <ResourceLabelDropdown
            clickInfo={resourceLabelContentState}
            setResourceLabelContentState={setResourceLabelContentState}
            eventsInAuditorium={data.events[venueId].filter(
              getEventsInAuditoriumFilter(
                resourceLabelContentState.auditoriumId
              )
            )}
          />
        )}
      {isSettingsDialogOpened && (
        <CalendarSettingsDialog onClose={closeSettingsDialog} />
      )}
    </>
  )
}
