import {useTranslation} from 'react-i18next'
import {BusinessPartnerType} from '../__generated__/schema'

export const useTranslateBusinessPartnerType = () => {
  const {t} = useTranslation()
  const translated: Record<BusinessPartnerType, string> = {
    [BusinessPartnerType.Supplier]: t<string>('BusinessPartnerType->Supplier'),
    [BusinessPartnerType.Client]: t<string>('BusinessPartnerType->Client'),
    [BusinessPartnerType.Partner]: t<string>('BusinessPartnerType->Partner')
  }
  return (type: BusinessPartnerType) => translated[type]
}
