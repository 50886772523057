import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {Button} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DetailLibraryShowPropertiesFragment,
  MixedShowsQuery
} from '../../../../../__generated__/schema'
import {RenderOnData} from '../../../../common'
import {Blank} from '../../../../visual/Blank'
import {useGetMixedShows} from '../graphql'
import {MixedShowsGrid} from './MixedShowsGrid'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3),
    paddingBottom: theme.spacing(2.5)
  }
}))

interface IDatabaseStepProps {
  onUseLibraryShowClicked: (show: DetailLibraryShowPropertiesFragment) => void
  searchText: string
}

export const DatabaseStep: React.FC<IDatabaseStepProps> = ({
  onUseLibraryShowClicked,
  searchText
}: IDatabaseStepProps) => {
  const {t} = useTranslation()
  const {data, loading, error, isLoadingMore, fetchMore} = useGetMixedShows(
    searchText,
    12
  )
  const classes = useStyles()
  return searchText ? (
    <RenderOnData
      loading={loading}
      error={error}
      data={data}
      errorMessage={t<string>('Error while loading shows')}
      dataCondition={(data: MixedShowsQuery) =>
        Array.isArray(data?.mixedShows.items)
      }
    >
      {({mixedShows}) => (
        <div className={classes.root}>
          {searchText && (
            <MixedShowsGrid
              shows={mixedShows.items}
              onUseLibraryShowClicked={onUseLibraryShowClicked}
            />
          )}
          {!isLoadingMore && mixedShows.pagination.hasMore && (
            <Button
              color="primary"
              variant="contained"
              onClick={fetchMore}
              startIcon={<ArrowDownwardIcon />}
            >
              {t('Show more')}
            </Button>
          )}
        </div>
      )}
    </RenderOnData>
  ) : (
    <Blank
      title={t('Search for show name first')}
      description={t(
        'Save your time with our library of shows. Library contains full information about shows with photos, posters, videos and much more.'
      )}
    />
  )
}
