import {Box, Paper, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentReport,
  PaymentReportType,
  UserFieldsFragment
} from '../../../__generated__/schema'
import {useFormatUserName} from '../../../hooks/formatUserName'
import {useTranslateEffectiveClientPrice} from '../../../hooks/translateCurrencies'
import {useDateTimeFormatters} from '../../../utils/formatting'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../DividerSeparatedInfoRow'

interface IGeneralProps {
  id?: string
  paymentReport: Pick<
    PaymentReport,
    | 'type'
    | 'note'
    | 'paymentsAmount'
    | 'revenueVatIncluded'
    | 'paymentsSurplus'
    | 'paymentsCount'
    | 'createdAt'
    | 'reportFrom'
    | 'reportTo'
  > & {cashier?: UserFieldsFragment | null; createdBy: UserFieldsFragment}
}

export const General: React.FC<IGeneralProps> = ({
  id,
  paymentReport
}: IGeneralProps) => {
  const {t} = useTranslation()
  const formatUserName = useFormatUserName()
  const {formatDateTime, formatDateNumeric} = useDateTimeFormatters()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const reportFromDate = new Date(paymentReport.reportFrom)
  const reportToDate = new Date(paymentReport.reportTo)
  return (
    <Paper id={id} variant="outlined" sx={{p: 3}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <Typography variant="h6">
          {paymentReport.type === PaymentReportType.DailyCashierReport
            ? [
                formatDateNumeric(reportFromDate),
                paymentReport.cashier && formatUserName(paymentReport.cashier)
              ]
                .filter(Boolean)
                .join(' • ')
            : [
                formatDateNumeric(reportFromDate),
                formatDateNumeric(reportToDate)
              ].join(' - ')}
        </Typography>
        {paymentReport.note && (
          <SingleInfoColumn caption={t('Note')} value={paymentReport.note} />
        )}
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Amount'),
              value: translateEffectiveClientPrice(paymentReport.paymentsAmount)
            },
            {
              caption: t('Revenue'),
              value: translateEffectiveClientPrice(
                paymentReport.revenueVatIncluded
              )
            },
            {
              caption: t('Surplus'),
              value: translateEffectiveClientPrice(
                paymentReport.paymentsSurplus
              )
            },
            {caption: t('Payments'), value: paymentReport.paymentsCount},
            {
              caption: t('Created by'),
              value: formatUserName(paymentReport.createdBy)
            },
            {
              caption: t('Created at'),
              value: formatDateTime(new Date(paymentReport.createdAt))
            }
          ]}
        />
      </Box>
    </Paper>
  )
}
