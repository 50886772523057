import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  CartsSupportUserQuery,
  CartsSupportUserQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const CARTS_SUPPORT_USER = gql`
  ${PAGINATION_FRAGMENT}
  query CartsSupportUser(
    $filter: CartsSupportUserFilterInput
    $paginationInput: PaginationInput!
  ) {
    cartsSupportUser(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        createdAt
        price
        state
        lead {
          id
          data {
            name
            email
          }
        }
        client {
          id
          name
          currency
        }
        updatedAt
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useCartsSupportUser = (
  variables: CartsSupportUserQueryVariables
) =>
  useQuery<CartsSupportUserQuery, CartsSupportUserQueryVariables>(
    CARTS_SUPPORT_USER,
    {
      variables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  )
