import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Chip, IconButton, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  NarrowCashDeskReservationPropertiesFragment,
  PermissionCode,
  ReservationState
} from '../../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'

import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {COLOR_CONF} from '../../../../constants'
import {useDaysCountBetweenTodayAndExpirationDate} from '../utils'
import {ExpirationCell, ExpirationToNowCell} from './ExpirationCell'
import {MobileReservationListItem} from './MobileReservationListItem'
import {getReservationIdLabel} from './utils'

interface IReservationListItemProps {
  reservation: NarrowCashDeskReservationPropertiesFragment
  onInfoClick?: React.MouseEventHandler
  isPhablet: boolean
}

const useStyles = makeStyles<Theme, {hasPermissionsToAccessRAP: boolean}>(
  (theme) => ({
    root: {
      display: 'grid',
      minHeight: theme.spacing(8),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1.5),
      gridTemplateColumns: '90px 114px 1fr auto auto',
      alignItems: 'center',
      cursor: ({hasPermissionsToAccessRAP}) =>
        hasPermissionsToAccessRAP ? 'pointer' : 'auto'
    },
    infoButtonCell: {
      gridColumn: 5,
      gridRow: '1 / 3',
      alignItems: 'center',
      alignSelf: 'center',
      marginLeft: theme.spacing(1)
    },
    infoIcon: {
      color: theme.palette.text.secondary
    },
    fourthColumnPadding: {
      paddingLeft: theme.spacing(1)
    },
    inCartChip: {
      ...theme.typography.caption,
      color: COLOR_CONF.YELLOW.color,
      backgroundColor: COLOR_CONF.YELLOW.background,
      height: 20,
      lineHeight: '100%'
    }
  })
)

export const ReservationListItem: React.FC<IReservationListItemProps> = ({
  reservation,
  onInfoClick,
  isPhablet
}: IReservationListItemProps) => {
  const {P} = useEnsurePermissions()
  const hasPermissionsToAccessRAP = P([
    PermissionCode.ReadReservation,
    PermissionCode.ReadEventSeats
  ])
  const classes = useStyles({hasPermissionsToAccessRAP})
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const daysCountBetweenTodayAndExpirationDate =
    useDaysCountBetweenTodayAndExpirationDate(reservation.expireAt)
  const history = useHistory()
  const onItemClicked = useCallback(() => {
    if (hasPermissionsToAccessRAP) {
      history.push(routeTo.admin.cashDesk.reservationPreview(reservation.id))
    }
  }, [hasPermissionsToAccessRAP, history, reservation.id])
  const translateSellingChannel = useTranslateSellingChannel()
  const lead = reservation.lead
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {t} = useTranslation()
  const {startsAt, names = {}} =
    reservation.event ?? reservation.tourTimeSlot ?? {}
  return isPhablet ? (
    <MobileReservationListItem
      expireAt={reservation.expireAt}
      reservationId={getReservationIdLabel(reservation.id)}
      translatedPrice={translateEffectiveClientPrice(reservation.price)}
      startsAt={startsAt}
      name={getUserLocaleTranslation(names)}
      leadEmail={lead?.data.email}
      leadName={lead?.data.name}
      onClick={hasPermissionsToAccessRAP ? onItemClicked : undefined}
    />
  ) : (
    <Paper className={classes.root} variant="outlined" onClick={onItemClicked}>
      <ExpirationToNowCell
        expireAt={reservation.expireAt}
        daysCountBetweenTodayAndExpirationDate={
          daysCountBetweenTodayAndExpirationDate
        }
      />
      <Typography variant="subtitle2" color="textPrimary">
        {getReservationIdLabel(reservation.id)}
      </Typography>
      <Typography variant="caption" color="textPrimary" noWrap>
        {lead
          ? [lead.data.name, lead.data.phone, lead.data.email]
              .filter(Boolean)
              .join(', ')
          : ''}
      </Typography>
      <Typography
        variant="caption"
        color="textPrimary"
        align="right"
        className={classes.fourthColumnPadding}
      >
        {translateEffectiveClientPrice(reservation.price)}
      </Typography>
      <ExpirationCell
        expireAt={reservation.expireAt}
        daysCountBetweenTodayAndExpirationDate={
          daysCountBetweenTodayAndExpirationDate
        }
      />
      <Typography variant="caption" color="textSecondary">
        {reservation.state === ReservationState.InCart ? (
          <Chip
            label={t('In cart')}
            className={classes.inCartChip}
            size="small"
          />
        ) : (
          translateSellingChannel(reservation.sellingChannel)
        )}
      </Typography>
      <Typography variant="caption" color="textSecondary" noWrap>
        {startsAt
          ? [
              formatDateNumeric(new Date(startsAt)),
              formatTime(new Date(startsAt)),
              getUserLocaleTranslation(names)
            ].join(' • ')
          : ''}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        align="right"
        className={classes.fourthColumnPadding}
      >
        {reservation.itemsCount}
      </Typography>
      {P([PermissionCode.ReadReservation]) ? (
        <IconButton className={classes.infoButtonCell} onClick={onInfoClick}>
          <InfoOutlinedIcon className={classes.infoIcon} />
        </IconButton>
      ) : (
        <div className={classes.infoButtonCell} />
      )}
    </Paper>
  )
}
