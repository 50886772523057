import {Button, ButtonGroupProps} from '@mui/material'
import {noop} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  TemplateFileType,
  TemplatesQuery
} from '../../../../__generated__/schema'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {printToPrinter} from '../../../../qz'
import {IDirectTicketPrintSettings} from '../../../../types'
import {
  getPublicPdfTicketPrintingTemplateUrl,
  performCompiledTicketPrintingTemplateAction
} from '../../../../utils/compiledTemplates'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useGetTicketPrintingTemplates} from '../hooks/getTicketPrintingTemplates'

interface ITicketPrintingGroupButtonProps {
  selectedItemIds?: number[]
  isDisabled?: boolean
  cartId: number
  variant?: ButtonGroupProps['variant']
  icon?: React.ReactNode
  label?: React.ReactNode
  disablePortal?: boolean
  uuid?: string | null
  hash?: string | null
}

export const TicketPrintingGroupButton: React.FC<ITicketPrintingGroupButtonProps> =
  ({
    selectedItemIds = [],
    isDisabled = false,
    cartId,
    variant = 'contained',
    icon,
    label,
    disablePortal = true,
    uuid,
    hash
  }: ITicketPrintingGroupButtonProps) => {
    const templates = useGetTicketPrintingTemplates()
    const {t} = useTranslation()
    const [directTicketPrint] =
      useLocalStorageState<IDirectTicketPrintSettings>(
        LocalStorageKey.DirectTicketPrintSettings,
        {
          enabled: false,
          printer: null
        }
      )
    const getTemplateButtonClickHandler = useCallback(
      (template: TemplatesQuery['templates'][number]) => () => {
        if (
          uuid &&
          hash &&
          directTicketPrint.enabled &&
          directTicketPrint.printer &&
          template.fileType === TemplateFileType.Pdf
        ) {
          const publicUrl = getPublicPdfTicketPrintingTemplateUrl({
            templateId: template.id,
            uuid,
            hash,
            itemIds: selectedItemIds
          })
          printToPrinter(directTicketPrint.printer, publicUrl)
        } else {
          performCompiledTicketPrintingTemplateAction({
            templateFileType: template.fileType,
            templateId: template.id,
            cartId,
            itemIds: selectedItemIds
          })
        }
      },
      [
        cartId,
        directTicketPrint.enabled,
        directTicketPrint.printer,
        hash,
        selectedItemIds,
        uuid
      ]
    )
    return (
      <SplitButton
        variant={variant}
        disabled={isDisabled}
        disableElevation
        disablePortal={disablePortal}
        Options={
          templates.length
            ? templates.map((template) => (
                <MenuItem
                  key={template.id}
                  label={template.name}
                  icon={icon}
                  onClick={getTemplateButtonClickHandler(template)}
                />
              ))
            : [
                <MenuItem
                  key="no-templates"
                  label={t('No templates have been found')}
                  isDisabled
                />
              ]
        }
      >
        <Button
          variant={variant}
          color="primary"
          onClick={
            templates[0] ? getTemplateButtonClickHandler(templates[0]) : noop
          }
        >
          {label || t('Print')}
        </Button>
      </SplitButton>
    )
  }
