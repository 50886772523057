import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetDeviceSettingsPageDataQuery,
  GetDeviceSettingsPageDataQueryVariables
} from '../../../../__generated__/schema'
import {useUserInfo} from '../../../../utils/auth'
import {RenderOnData} from '../../../common'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../Header'
import {DeviceSettings} from './DeviceSettings'
import {GET_DEVICE_SETTINGS_PAGE_QUERY} from './graphql'

export const DeviceSettingsPage: React.FC = () => {
  const {effectiveClient} = useUserInfo()
  const {loading, error, data} = useQuery<
    GetDeviceSettingsPageDataQuery,
    GetDeviceSettingsPageDataQueryVariables
  >(GET_DEVICE_SETTINGS_PAGE_QUERY, {
    variables: {clientId: effectiveClient?.id || -1},
    skip: !effectiveClient
  })
  const {t} = useTranslation()
  return (
    <PageWithHeaderTemplate
      header={<SecondaryHeader title={t('Device settings')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<GetDeviceSettingsPageDataQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading client')}
        >
          {({client, divisions}) => (
            <DeviceSettings
              clientTemplateAssignments={client.templateAssignments}
              divisions={divisions}
            />
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
