import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  AssignTemplateDialogQuery,
  AssignTemplateDialogQueryVariables,
  AssignTemplateToClientMutation,
  AssignTemplateToClientMutationVariables
} from '../../../../../__generated__/schema'
import {
  GET_CLIENT_TEMPLATES,
  TEMPLATE_PROPERTIES_FRAGMENT
} from '../../clients/graphql'

const TEMPLATES_QUERY = gql`
  query assignTemplateDialog($filter: TemplatesFilterInput, $clientId: Int!) {
    templates(filter: $filter) {
      ...TemplateProperties
    }
    client(id: $clientId) {
      id
      templateAssignments {
        id
        template {
          id
        }
      }
    }
  }
  ${TEMPLATE_PROPERTIES_FRAGMENT}
`

export const useAssignTemplateDialogQuery = (
  variables: AssignTemplateDialogQueryVariables,
  skip: boolean
) =>
  useQuery<AssignTemplateDialogQuery, AssignTemplateDialogQueryVariables>(
    TEMPLATES_QUERY,
    {variables, skip}
  )

const ASSIGN_TEMPLATE_TO_CLIENT_MUTATION = gql`
  mutation assignTemplateToClient(
    $templateId: PositiveInt!
    $clientId: PositiveInt!
  ) {
    assignTemplateToClient(templateId: $templateId, clientId: $clientId) {
      id
      templateAssignments {
        id
        template {
          id
        }
      }
    }
  }
`

export const useAssignTemplateToClient = () => {
  const [assignTemplateToClient] = useMutation<
    AssignTemplateToClientMutation,
    AssignTemplateToClientMutationVariables
  >(ASSIGN_TEMPLATE_TO_CLIENT_MUTATION, {
    refetchQueries: ({data}) => [
      {
        query: GET_CLIENT_TEMPLATES,
        variables: {id: data.assignTemplateToClient.id}
      }
    ]
  })
  return (variables: AssignTemplateToClientMutationVariables) =>
    assignTemplateToClient({variables})
}
