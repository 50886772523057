import {Box} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridCsvGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventsStatisticsFilterInput,
  EventState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../utils/auth'
import {
  DataGridTable,
  useAgeClassificationAbbreviationFormatter,
  useDataGridPagination,
  useDateTimeFormatter,
  useDecimalFormatter,
  useShowFormatAbbreviationFormatter,
  useSoundMixAbbreviationFormatter,
  useTranslatedValueGetter,
  useVersionCodeAbbreviationFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ReloadButton} from '../components/ReloadButton'
import {useGetFilterDateRange} from '../utils'
import {EventStateChip} from './EventStateChip'
import {useGetEventsStatistics} from './graphql'
import {IconCellRenderer} from './Miscellaneous'
import {MoreMenu} from './MoreMenu'

interface ITicketsCheckingPageProps {
  searchFilter: EventsStatisticsFilterInput
}

export const TicketsCheckingPage: React.FC<ITicketsCheckingPageProps> = ({
  searchFilter
}: ITicketsCheckingPageProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [eventStateFilter, setEventStateFilter] = useState<EventState>(
    EventState.Published
  )
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.FromToday
    }
  )
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const dataGridApiRef = useGridApiRef()
  const getFilterDateRange = useGetFilterDateRange()
  const dateTimeFormatter = useDateTimeFormatter()
  const translatedValueGetter = useTranslatedValueGetter()
  const decimalFormatter = useDecimalFormatter()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const showFormatAbbreviationFormatter = useShowFormatAbbreviationFormatter()
  const versionCodeAbbreviationFormatter = useVersionCodeAbbreviationFormatter()
  const soundMixAbbreviationFormatter = useSoundMixAbbreviationFormatter()
  const ageClassificationAbbreviationFormatter =
    useAgeClassificationAbbreviationFormatter()
  const {data, loading, error, refetch} = useGetEventsStatistics({
    filter: {
      ...searchFilter,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'eventStartsAtFrom',
        filterNameTo: 'eventStartsAtTo'
      }),
      eventState: eventStateFilter
    },
    paginationInput
  })
  const handleEventStateChange = useCallback(
    (state: EventState) => {
      setEventStateFilter(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const hasPermissionToAccessDrawer =
    (P([PermissionCode.ReadSales]) || P([PermissionCode.ReadReservations])) &&
    P([PermissionCode.ReadAggregatedEventStatisticsByItemPrice])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Starts at'),
        field: 'startsAt',
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Name'),
        field: 'names',
        valueGetter: translatedValueGetter,
        sortable: false
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'profit',
        valueFormatter: decimalFormatter,
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Sold tickets'),
        field: 'profitTicketCount',
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Checked'),
        field: 'uniqueCheckedInCount',
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Unchecked'),
        field: 'uncheckedCount',
        align: 'right',
        headerAlign: 'right',
        sortable: false,
        valueGetter: (params) =>
          params.row.profitTicketCount - params.row.uniqueCheckedInCount
      },
      {
        headerName: t('Checked in'),
        field: 'passCodesCheckedInCount',
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Checked out'),
        field: 'passCodesCheckedOutCount',
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Venue'),
        field: 'venueName',
        valueGetter: (params) => params.row.venue?.name
      },
      {
        headerName: t('Auditorium'),
        field: 'auditoriumName',
        valueGetter: (params) => params.row.auditorium?.name
      },
      {
        headerName: t('Division'),
        field: 'divisionName',
        valueGetter: (params) => params.row.division?.name
      },
      {
        headerName: t('Format'),
        field: 'formatCode',
        valueFormatter: showFormatAbbreviationFormatter
      },
      {
        headerName: t('Version'),
        field: 'versionCode',
        valueFormatter: versionCodeAbbreviationFormatter
      },
      {
        headerName: t('Sound mix'),
        field: 'soundMixCode',
        valueFormatter: soundMixAbbreviationFormatter
      },
      {
        headerName: t('Age restrictions'),
        field: 'ageClassificationCode',
        valueFormatter: ageClassificationAbbreviationFormatter
      },
      {
        headerName: t('Cost center'),
        field: 'costCenterName',
        valueGetter: (params) => params.row.costCenter?.name
      },
      {
        headerName: t('Event category'),
        field: 'eventCategoryName',
        valueGetter: (params) => params.row.eventCategory?.name
      },
      {
        headerName: t('Marketing label'),
        field: 'marketingLabelName',
        valueGetter: (params) => params.row.marketingLabel?.name
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.eventId,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              id={params.value}
              isRowPinned={params.rowNode.type === 'pinnedRow'}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [
      ageClassificationAbbreviationFormatter,
      dateTimeFormatter,
      decimalFormatter,
      showFormatAbbreviationFormatter,
      soundMixAbbreviationFormatter,
      t,
      translatedEffectiveClientCurrencySign,
      translatedValueGetter,
      versionCodeAbbreviationFormatter
    ]
  )
  const handleCsvButtonClick = useCallback(() => {
    dataGridApiRef.current.exportDataAsCsv({
      fileName: 'eventsStatistics',
      getRowsToExport: (params: GridCsvGetRowsToExportParams) =>
        gridFilteredSortedRowIdsSelector(params.apiRef).filter(
          (rowId) => rowId !== 0
        )
    })
  }, [dataGridApiRef])
  useEffect(() => {
    if (data && !loading) {
      dataGridApiRef.current.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
        expand: true
      })
    }
  }, [data, dataGridApiRef, loading])
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading events statistics')}
      />
    )
  }
  return (
    <Box sx={{height: '100%'}}>
      <SubHeaderToolbar
        title={t('Tickets checking')}
        leftActions={[
          <EventStateChip
            key="event-state-chip"
            selectedOption={eventStateFilter}
            onChange={handleEventStateChange}
          />
        ]}
        rightActions={[
          <DatePickerButton
            key="event-date-picker"
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.FromToday
            }}
            tooltip={t('Event date')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={handleRefetchButtonClick}
          />,
          <MoreMenu
            key="more-menu"
            onEventsListCsvButtonClick={handleCsvButtonClick}
          />
        ]}
      />
      <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            }
          }}
          getRowId={(row) => row.eventId}
          apiRef={dataGridApiRef}
          columns={columns}
          rows={data?.eventsStatistics.items || []}
          loading={loading}
          disableColumnMenu
          disableRowSelectionOnClick
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          initialState={{
            pinnedColumns: {left: ['startsAt'], right: ['arrow']}
          }}
          columnVisibilityModel={{
            arrow: hasPermissionToAccessDrawer
          }}
          localeText={{noRowsLabel: t('No events statistics to show')}}
          columnGroupingModel={[
            {
              groupId: t('Event'),
              children: [{field: 'names'}]
            },
            {
              groupId: t('Tickets'),
              children: [
                {field: 'uniqueCheckedInCount'},
                {field: 'uncheckedCount'}
              ]
            },
            {
              groupId: t('Checks count'),
              children: [
                {field: 'passCodesCheckedInCount'},
                {field: 'passCodesCheckedOutCount'}
              ]
            },
            {
              groupId: t('Event details'),
              children: [
                {field: 'formatCode'},
                {field: 'versionCode'},
                {field: 'soundMixCode'},
                {field: 'ageClassificationCode'},
                {field: 'costCenterName'},
                {field: 'eventCategoryName'},
                {field: 'marketingLabelName'}
              ]
            }
          ]}
          disableAutosize={false}
          autosizeOnMount
          autosizeOptions={{
            includeHeaders: true,
            includeOutliers: true,
            expand: true
          }}
          {...getDataGridPaginationProps(data?.eventsStatistics.pagination)}
        />
      </Box>
    </Box>
  )
}
