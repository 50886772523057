import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateRole} from './CreateRole'
import {EditRole} from './EditRole'
import {RolesPage} from './RolesPage'

export const Roles: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ManageRoles]) && (
        <Route path={routeTo.admin.roles.index()} exact>
          <RolesPage />
        </Route>
      )}
      {P([PermissionCode.CreateRole]) && (
        <Route path={routeTo.admin.roles.create()} exact>
          <CreateRole />
        </Route>
      )}
      {P([PermissionCode.UpdateRole]) && (
        <Route path={routeTo.admin.roles.edit(':roleId')} exact>
          <EditRole />
        </Route>
      )}
    </Switch>
  )
}
