import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import {IconButton, TextField} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {Theme} from '../../../../../theme'
import {
  CashCounterAction,
  CashCounterActionType,
  CashCounterRow,
  CashCounterRowType
} from '../types'

const useStyles = makeStyles<Theme>(() => ({
  countInput: {
    maxWidth: 128
  }
}))

interface IGetClickHandlerArg {
  id: number
  dispatch: React.Dispatch<CashCounterAction>
  rowType: CashCounterRowType
}

const getOnIncrementButtonClickHandler =
  ({id, dispatch, rowType}: IGetClickHandlerArg) =>
  () => {
    dispatch({
      type: CashCounterActionType.Increment,
      payload: {
        id,
        row: rowType
      }
    })
  }
const getOnDecrementButtonClickHandler =
  ({id, dispatch, rowType}: IGetClickHandlerArg) =>
  () => {
    dispatch({
      type: CashCounterActionType.Decrement,
      payload: {
        id,
        row: rowType
      }
    })
  }

const getOnChangeHandler =
  ({id, dispatch, rowType}: IGetClickHandlerArg) =>
  (e: any) => {
    dispatch({
      type: CashCounterActionType.Change,
      payload: {
        id,
        inputValue: parseInt(e.target.value, 10),
        row: rowType
      }
    })
  }

interface ICountCellProps {
  row: CashCounterRow
  className: string
  dispatch: React.Dispatch<CashCounterAction>
  rowType: CashCounterRowType
}

export const CountCell: React.FC<ICountCellProps> = ({
  row,
  className,
  dispatch,
  rowType
}: ICountCellProps) => {
  const classes = useStyles()
  const handleClick = useCallback((e) => {
    if (e.target) {
      e.target.select()
    }
  }, [])
  const getRemoveButtonClickHandler = useCallback(
    (id: number) => () => {
      dispatch({
        type: CashCounterActionType.RemoveVoucherRow,
        payload: id
      })
    },
    [dispatch]
  )
  return (
    <div className={className}>
      {rowType === CashCounterRowType.VouchersRow && row.count < 1 ? (
        <IconButton
          onClick={getRemoveButtonClickHandler(row.id)}
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      ) : (
        <IconButton
          onClick={getOnDecrementButtonClickHandler({
            dispatch,
            id: row.id,
            rowType
          })}
          color="primary"
          disabled={row.count < 1}
        >
          <RemoveCircleOutlineOutlinedIcon />
        </IconButton>
      )}

      <TextField
        variant="outlined"
        type="number"
        onClick={handleClick}
        className={classes.countInput}
        value={row.count}
        onChange={getOnChangeHandler({
          dispatch,
          id: row.id,
          rowType
        })}
        fullWidth
      />
      <IconButton
        onClick={getOnIncrementButtonClickHandler({
          dispatch,
          id: row.id,
          rowType
        })}
        color="primary"
      >
        <AddCircleOutlineOutlinedIcon />
      </IconButton>
    </div>
  )
}
