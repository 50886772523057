import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import {Box, Drawer, IconButton, Typography, useMediaQuery} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {RouteComponentProps, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  VenuePropertiesFragment,
  VenuesQuery
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {CreateFab, SaveButton, useFabClasses} from '../../../common/Buttons'
import {DataGridTable} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {AddVenue} from './AddVenue'
import {getVenueAddressString} from './common'
import {useGetVenues, useUpdateVenuesOrder} from './graphql'
import {IVenuesSearchFilter, VenuesSearch} from './VenuesSearch'

const normalize = (text: string) =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const getFilteredVenues = (
  venues: VenuesQuery['venues'],
  filter: IVenuesSearchFilter
) =>
  filter && filter.hasText
    ? venues.filter(
        ({name, address}) =>
          normalize(name)
            .toLowerCase()
            .indexOf(normalize(filter.hasText!).toLowerCase()) > -1 ||
          normalize(address.town)
            .toLowerCase()
            .indexOf(normalize(filter.hasText!).toLowerCase()) > -1
      )
    : venues

const NameRenderer = ({name, address}: {name: string; address: string}) => (
  <Box>
    <Typography variant="subtitle2">{name}</Typography>
    <Typography variant="caption" color="textSecondary">
      {address}
    </Typography>
  </Box>
)

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.venues.detail(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IVenuesProps {
  venues: Array<VenuePropertiesFragment>
  refetch: () => Promise<object>
}

const Venues: React.FC<IVenuesProps> = ({venues, refetch}: IVenuesProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateVenuesOrder = useUpdateVenuesOrder()
  const {addInfoNotification, setShowBackdrop, customErrorHandler} =
    useMutationAssistanceHooks()
  const isLargeDesktopMinus = useMediaQuery(MediaSizes.LargeDesktopMinus)
  const handleRowOrderChange = useCallback(
    async (params) => {
      const rowsClone = [...venues]
      const row = rowsClone.splice(params.oldIndex, 1)[0]
      rowsClone.splice(params.targetIndex, 0, row)
      try {
        setShowBackdrop(true)
        await updateVenuesOrder(rowsClone.map(({id}) => id))
        await refetch()
        addInfoNotification(t('Venues have been reordered'))
      } catch (error) {
        customErrorHandler(error, {
          title: t('Operation has failed'),
          contentText: t(
            "We're sorry, but there was a problem updating venues order. Please try again."
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      customErrorHandler,
      refetch,
      setShowBackdrop,
      t,
      updateVenuesOrder,
      venues
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Venue'),
        field: 'name',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <NameRenderer
              name={params.value}
              address={getVenueAddressString(params.row.address)}
            />
          )
        },
        flex: 1
      },
      {
        headerName: '',
        field: 'auditoriumsCount',
        valueGetter: (params) => params.row.auditoriums.length,
        valueFormatter: (params) =>
          t('{{count}} auditorium', {
            count: params.value
          }),
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t]
  )
  return (
    <Box sx={{pb: isLargeDesktopMinus ? 8 : 0}}>
      <DataGridTable
        columns={columns}
        rows={venues}
        pagination={false}
        autoHeight
        hideFooter
        rowHeight={64}
        disableRowSelectionOnClick
        columnVisibilityModel={{
          id: P([PermissionCode.ReadVenue])
        }}
        initialState={{
          pinnedColumns: {
            left: ['__reorder__'],
            right: ['auditoriumsCount', 'id']
          }
        }}
        localeText={{noRowsLabel: t('No venues to show')}}
        rowReordering={P([PermissionCode.UpdateVenuesOrder])}
        slots={{rowReorderIcon: DragHandleIcon}}
        onRowOrderChange={handleRowOrderChange}
      />
    </Box>
  )
}

export const VenueList: React.FC<RouteComponentProps> = ({
  match
}: RouteComponentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, error, loading, refetch} = useGetVenues()
  const [searchFilter, setSearchFilter] = useState<IVenuesSearchFilter>({})
  const history = useHistory()
  const drawerOpen = match.url === routeTo.admin.venues.add()

  const handleAddButtonClick = useCallback(() => {
    history.push(routeTo.admin.venues.add())
  }, [history])

  const fabClasses = useFabClasses()

  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Venues')}
          search={<VenuesSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData
          loading={loading}
          error={error}
          data={data}
          errorMessage={t<string>('Could not load venues')}
          dataCondition={(data: VenuesQuery) => Array.isArray(data?.venues)}
        >
          {({venues}: VenuesQuery) => (
            <CenteredLayout>
              {venues.length ? (
                <CenteredLayoutListWrapper>
                  <Venues
                    venues={getFilteredVenues(venues, searchFilter)}
                    refetch={refetch}
                  />
                  {P([PermissionCode.CreateVenue]) && (
                    <CreateFab
                      onClick={handleAddButtonClick}
                      classes={fabClasses}
                      cypress-id="venue-list-create-button"
                    />
                  )}
                </CenteredLayoutListWrapper>
              ) : (
                <Blank
                  title={t('There are no venues created yet.')}
                  actions={
                    P([PermissionCode.CreateVenue]) && (
                      <SaveButton
                        startIcon={<AddIcon />}
                        onClick={handleAddButtonClick}
                      >
                        {t('Create venue')}
                      </SaveButton>
                    )
                  }
                />
              )}
            </CenteredLayout>
          )}
        </RenderOnData>
        <Drawer anchor="right" open={drawerOpen} onClose={history.goBack}>
          <AddVenue />
        </Drawer>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
