import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DiscountApplication,
  DiscountsFilterInput,
  DiscountType,
  GetLightweightDivisionsQuery,
  SellingChannel
} from '../../../../__generated__/schema'
import {
  useTranslateDiscountApplication,
  useTranslateDiscountType
} from '../../../../hooks/discounts'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useGetLightweightDivisions} from '../graphql'

export const DEFAULT_DISCOUNTS_FILTER_INPUT: DiscountsFilterInput = {
  hasText: undefined
}

const mapHasTextFilter = (
  filter: DiscountsFilterInput,
  hasText?: string
): DiscountsFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapSellingChannelToFilter = (
  filter: DiscountsFilterInput,
  sellingChannel?: SellingChannel
): DiscountsFilterInput => ({
  ...filter,
  sellingChannels: sellingChannel ? [sellingChannel] : undefined
})

const mapApplicationToFilter = (
  filter: DiscountsFilterInput,
  application?: DiscountApplication
): DiscountsFilterInput => ({
  ...filter,
  applications: application ? [application] : undefined
})

const mapTypeToFilter = (
  filter: DiscountsFilterInput,
  type?: DiscountType
): DiscountsFilterInput => ({
  ...filter,
  types: type ? [type] : undefined
})

const mapDiscountCodeNameToFilter = (
  filter: DiscountsFilterInput,
  discountCodeName?: string
): DiscountsFilterInput => ({
  ...filter,
  discountCodeName: discountCodeName || undefined
})

const mapDivisionIdToFilter = (
  filter: DiscountsFilterInput,
  divisionId: number | undefined
): DiscountsFilterInput => ({
  ...filter,
  divisionIds: divisionId ? [divisionId] : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateDiscountApplication = useTranslateDiscountApplication()
  const translateDiscountType = useTranslateDiscountType()
  const getHasTextFromSearchObject = (filter: DiscountsFilterInput) =>
    filter.hasText || undefined
  const getSellingChannelFromSearchObject = (filter: DiscountsFilterInput) =>
    filter.sellingChannels
      ? t('Channel: {{sellingChannel}}', {
          sellingChannel: translateSellingChannel(filter.sellingChannels[0])
        })
      : undefined
  const getApplicationFromSearchObject = (filter: DiscountsFilterInput) =>
    filter.applications
      ? t('Application: {{application}}', {
          application: translateDiscountApplication(filter.applications[0])
        })
      : undefined
  const getTypeFromSearchObject = (filter: DiscountsFilterInput) =>
    filter.types
      ? t('Type: {{type}}', {
          type: translateDiscountType(filter.types[0])
        })
      : undefined
  const getDiscountCodeNameFromSearchObject = (filter: DiscountsFilterInput) =>
    filter.discountCodeName
      ? t('Discount code: {{code}}', {code: filter.discountCodeName})
      : undefined
  const getDivisionFromSearchObject = (
    filter: DiscountsFilterInput,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionIds && filter.divisionIds.length) {
      const filterDivisionId = filter.divisionIds[0]
      const division = divisions.find(
        (division) => division.id === filterDivisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filterDivisionId})
    }
    return undefined
  }
  return {
    getHasTextFromSearchObject,
    getSellingChannelFromSearchObject,
    getApplicationFromSearchObject,
    getTypeFromSearchObject,
    getDiscountCodeNameFromSearchObject,
    getDivisionFromSearchObject
  }
}

interface IDiscountsSearchProps {
  onFilterChange: (filter: DiscountsFilterInput) => void
}

export const DiscountsSearch: React.FC<IDiscountsSearchProps> = ({
  onFilterChange
}: IDiscountsSearchProps) => {
  const {t} = useTranslation()
  const {divisions} = useGetLightweightDivisions()
  const {
    getHasTextFromSearchObject,
    getSellingChannelFromSearchObject,
    getApplicationFromSearchObject,
    getTypeFromSearchObject,
    getDiscountCodeNameFromSearchObject,
    getDivisionFromSearchObject
  } = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject,
    getSellingChannelFromSearchObject,
    getApplicationFromSearchObject,
    getTypeFromSearchObject,
    getDiscountCodeNameFromSearchObject,
    (filter) => getDivisionFromSearchObject(filter, divisions)
  )
  const translateSellingChannel = useTranslateSellingChannel()
  const translateDiscountApplication = useTranslateDiscountApplication()
  const translateDiscountType = useTranslateDiscountType()
  const sellingChannelSelectOptions: ISelectOption<SellingChannel>[] = [
    SellingChannel.Retail,
    SellingChannel.ECommerce
  ].map((channel) => ({
    id: channel,
    label: translateSellingChannel(channel)
  }))
  const applicationSelectOptions: ISelectOption<DiscountApplication>[] = [
    DiscountApplication.Selectable,
    DiscountApplication.Code,
    DiscountApplication.Customer
  ].map((application) => ({
    id: application,
    label: translateDiscountApplication(application)
  }))
  const typesSelectOptions: ISelectOption<DiscountType>[] = [
    DiscountType.Percentage,
    DiscountType.FixedAmount
  ].map((type) => ({
    id: type,
    label: translateDiscountType(type)
  }))
  const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
    ({id, name: label}) => ({
      id,
      label
    })
  )
  return (
    <Search<DiscountsFilterInput>
      storageKey="DISCOUNTS"
      placeholder={t('Search for discount')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_DISCOUNTS_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<DiscountsFilterInput, SellingChannel>
            label={t('Channel')}
            value={
              advancedSearchObject.sellingChannels?.length
                ? advancedSearchObject.sellingChannels[0]
                : undefined
            }
            options={sellingChannelSelectOptions}
            mapSelectValueToSearchObject={mapSellingChannelToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<DiscountsFilterInput, DiscountApplication>
            label={t('Application')}
            value={
              advancedSearchObject.applications?.length
                ? advancedSearchObject.applications[0]
                : undefined
            }
            options={applicationSelectOptions}
            mapSelectValueToSearchObject={mapApplicationToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<DiscountsFilterInput, DiscountType>
            label={t('Type')}
            value={
              advancedSearchObject.types?.length
                ? advancedSearchObject.types[0]
                : undefined
            }
            options={typesSelectOptions}
            mapSelectValueToSearchObject={mapTypeToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow<DiscountsFilterInput>
            label={t('Contains')}
            placeholder={t('Enter discount name or description')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow<DiscountsFilterInput>
            label={t('Discount code')}
            placeholder={t('Enter discount code')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapDiscountCodeNameToFilter}
            value={advancedSearchObject.discountCodeName || undefined}
          />
          <AdvancedSearchSelectRow<DiscountsFilterInput, number>
            label={t('Division')}
            value={
              advancedSearchObject.divisionIds
                ? advancedSearchObject.divisionIds[0]
                : undefined
            }
            options={divisionSelectOptions}
            mapSelectValueToSearchObject={mapDivisionIdToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
