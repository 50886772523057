import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  EventCategoryFormField,
  EventCategoryState,
  IEventCategoryForm
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface IEventCategoryFormProps {
  formId: string
  onSubmit: (data: IEventCategoryForm) => void
  defaultValues?: Partial<IEventCategoryForm>
  className?: string
}

export const EventCategoryForm: React.FC<IEventCategoryFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  className
}: IEventCategoryFormProps) => {
  const {t} = useTranslation()
  const {handleSubmit, errors, setValue, watch, register, triggerValidation} =
    useForm<IEventCategoryForm>({defaultValues})
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IEventCategoryForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Name')}
            name={EventCategoryFormField.Name}
            key={EventCategoryFormField.Name}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IEventCategoryForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Label')}
            helperNote={t('Ideal for custom internal code')}
            name={EventCategoryFormField.Label}
            key={EventCategoryFormField.Label}
            validationOptions={{validate: isStringWithMaxLength}}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IEventCategoryForm>
            selectOptions={Object.values(EventCategoryState).reduce(
              (acc, state) => ({
                ...acc,
                [state]:
                  state === EventCategoryState.Active
                    ? t('Active')
                    : t('Inactive')
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            label={t('State')}
            name={EventCategoryFormField.IsActive}
            key={EventCategoryFormField.IsActive}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
    </form>
  )
}
