import GetAppIcon from '@mui/icons-material/GetApp'
import {IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '../../../../common'
import {SubHeaderToolbar} from '../../../../common/SubHeaderToolbar'
import {ReloadButton} from '../../components/ReloadButton'

interface IToolbarProps {
  title: string
  onReloadButtonClick: () => void
  onDownloadButtonClick: () => void
}

export const Toolbar: React.FC<IToolbarProps> = ({
  title,
  onDownloadButtonClick,
  onReloadButtonClick
}: IToolbarProps) => {
  const {t} = useTranslation()
  return (
    <SubHeaderToolbar
      title={title}
      rightActions={[
        <ReloadButton key="reload" onReloadButtonClick={onReloadButtonClick} />,
        <Tooltip key="download" title={t('Download CSV')}>
          <IconButton onClick={onDownloadButtonClick} color="primary">
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      ]}
    />
  )
}
