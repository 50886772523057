import {useTranslation} from 'react-i18next'

import {LanguageCode} from '../__generated__/schema'

export const useTranslateLanguageCode = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<LanguageCode, string> = {
    [LanguageCode.Aar]: t<string>('LanguageCode->Aar'),
    [LanguageCode.Abk]: t<string>('LanguageCode->Abk'),
    [LanguageCode.Ace]: t<string>('LanguageCode->Ace'),
    [LanguageCode.Ach]: t<string>('LanguageCode->Ach'),
    [LanguageCode.Ada]: t<string>('LanguageCode->Ada'),
    [LanguageCode.Ady]: t<string>('LanguageCode->Ady'),
    [LanguageCode.Afa]: t<string>('LanguageCode->Afa'),
    [LanguageCode.Afh]: t<string>('LanguageCode->Afh'),
    [LanguageCode.Afr]: t<string>('LanguageCode->Afr'),
    [LanguageCode.Ain]: t<string>('LanguageCode->Ain'),
    [LanguageCode.Aka]: t<string>('LanguageCode->Aka'),
    [LanguageCode.Akk]: t<string>('LanguageCode->Akk'),
    [LanguageCode.Ale]: t<string>('LanguageCode->Ale'),
    [LanguageCode.Alg]: t<string>('LanguageCode->Alg'),
    [LanguageCode.Alt]: t<string>('LanguageCode->Alt'),
    [LanguageCode.Amh]: t<string>('LanguageCode->Amh'),
    [LanguageCode.Ang]: t<string>('LanguageCode->Ang'),
    [LanguageCode.Anp]: t<string>('LanguageCode->Anp'),
    [LanguageCode.Apa]: t<string>('LanguageCode->Apa'),
    [LanguageCode.Ara]: t<string>('LanguageCode->Ara'),
    [LanguageCode.Arc]: t<string>('LanguageCode->Arc'),
    [LanguageCode.Arg]: t<string>('LanguageCode->Arg'),
    [LanguageCode.Arn]: t<string>('LanguageCode->Arn'),
    [LanguageCode.Arp]: t<string>('LanguageCode->Arp'),
    [LanguageCode.Art]: t<string>('LanguageCode->Art'),
    [LanguageCode.Arw]: t<string>('LanguageCode->Arw'),
    [LanguageCode.Asm]: t<string>('LanguageCode->Asm'),
    [LanguageCode.Ast]: t<string>('LanguageCode->Ast'),
    [LanguageCode.Ath]: t<string>('LanguageCode->Ath'),
    [LanguageCode.Aus]: t<string>('LanguageCode->Aus'),
    [LanguageCode.Ava]: t<string>('LanguageCode->Ava'),
    [LanguageCode.Ave]: t<string>('LanguageCode->Ave'),
    [LanguageCode.Awa]: t<string>('LanguageCode->Awa'),
    [LanguageCode.Aym]: t<string>('LanguageCode->Aym'),
    [LanguageCode.Aze]: t<string>('LanguageCode->Aze'),
    [LanguageCode.Bad]: t<string>('LanguageCode->Bad'),
    [LanguageCode.Bai]: t<string>('LanguageCode->Bai'),
    [LanguageCode.Bak]: t<string>('LanguageCode->Bak'),
    [LanguageCode.Bal]: t<string>('LanguageCode->Bal'),
    [LanguageCode.Bam]: t<string>('LanguageCode->Bam'),
    [LanguageCode.Ban]: t<string>('LanguageCode->Ban'),
    [LanguageCode.Bas]: t<string>('LanguageCode->Bas'),
    [LanguageCode.Bat]: t<string>('LanguageCode->Bat'),
    [LanguageCode.Bej]: t<string>('LanguageCode->Bej'),
    [LanguageCode.Bel]: t<string>('LanguageCode->Bel'),
    [LanguageCode.Bem]: t<string>('LanguageCode->Bem'),
    [LanguageCode.Ben]: t<string>('LanguageCode->Ben'),
    [LanguageCode.Ber]: t<string>('LanguageCode->Ber'),
    [LanguageCode.Bho]: t<string>('LanguageCode->Bho'),
    [LanguageCode.Bih]: t<string>('LanguageCode->Bih'),
    [LanguageCode.Bik]: t<string>('LanguageCode->Bik'),
    [LanguageCode.Bin]: t<string>('LanguageCode->Bin'),
    [LanguageCode.Bis]: t<string>('LanguageCode->Bis'),
    [LanguageCode.Bla]: t<string>('LanguageCode->Bla'),
    [LanguageCode.Bnt]: t<string>('LanguageCode->Bnt'),
    [LanguageCode.Bod]: t<string>('LanguageCode->Bod'),
    [LanguageCode.Bos]: t<string>('LanguageCode->Bos'),
    [LanguageCode.Bra]: t<string>('LanguageCode->Bra'),
    [LanguageCode.Bre]: t<string>('LanguageCode->Bre'),
    [LanguageCode.Btk]: t<string>('LanguageCode->Btk'),
    [LanguageCode.Bua]: t<string>('LanguageCode->Bua'),
    [LanguageCode.Bug]: t<string>('LanguageCode->Bug'),
    [LanguageCode.Bul]: t<string>('LanguageCode->Bul'),
    [LanguageCode.Byn]: t<string>('LanguageCode->Byn'),
    [LanguageCode.Cad]: t<string>('LanguageCode->Cad'),
    [LanguageCode.Cai]: t<string>('LanguageCode->Cai'),
    [LanguageCode.Car]: t<string>('LanguageCode->Car'),
    [LanguageCode.Cat]: t<string>('LanguageCode->Cat'),
    [LanguageCode.Cau]: t<string>('LanguageCode->Cau'),
    [LanguageCode.Ceb]: t<string>('LanguageCode->Ceb'),
    [LanguageCode.Cel]: t<string>('LanguageCode->Cel'),
    [LanguageCode.Ces]: t<string>('LanguageCode->Ces'),
    [LanguageCode.Cha]: t<string>('LanguageCode->Cha'),
    [LanguageCode.Chb]: t<string>('LanguageCode->Chb'),
    [LanguageCode.Che]: t<string>('LanguageCode->Che'),
    [LanguageCode.Chk]: t<string>('LanguageCode->Chk'),
    [LanguageCode.Chm]: t<string>('LanguageCode->Chm'),
    [LanguageCode.Chn]: t<string>('LanguageCode->Chn'),
    [LanguageCode.Cho]: t<string>('LanguageCode->Cho'),
    [LanguageCode.Chp]: t<string>('LanguageCode->Chp'),
    [LanguageCode.Chr]: t<string>('LanguageCode->Chr'),
    [LanguageCode.Chu]: t<string>('LanguageCode->Chu'),
    [LanguageCode.Chv]: t<string>('LanguageCode->Chv'),
    [LanguageCode.Chy]: t<string>('LanguageCode->Chy'),
    [LanguageCode.Cmc]: t<string>('LanguageCode->Cmc'),
    [LanguageCode.Cnr]: t<string>('LanguageCode->Cnr'),
    [LanguageCode.Cop]: t<string>('LanguageCode->Cop'),
    [LanguageCode.Cor]: t<string>('LanguageCode->Cor'),
    [LanguageCode.Cos]: t<string>('LanguageCode->Cos'),
    [LanguageCode.Cre]: t<string>('LanguageCode->Cre'),
    [LanguageCode.Crh]: t<string>('LanguageCode->Crh'),
    [LanguageCode.Crp]: t<string>('LanguageCode->Crp'),
    [LanguageCode.Csb]: t<string>('LanguageCode->Csb'),
    [LanguageCode.Cus]: t<string>('LanguageCode->Cus'),
    [LanguageCode.Cym]: t<string>('LanguageCode->Cym'),
    [LanguageCode.Dak]: t<string>('LanguageCode->Dak'),
    [LanguageCode.Dan]: t<string>('LanguageCode->Dan'),
    [LanguageCode.Dar]: t<string>('LanguageCode->Dar'),
    [LanguageCode.Day]: t<string>('LanguageCode->Day'),
    [LanguageCode.Del]: t<string>('LanguageCode->Del'),
    [LanguageCode.Den]: t<string>('LanguageCode->Den'),
    [LanguageCode.Deu]: t<string>('LanguageCode->Deu'),
    [LanguageCode.Dgr]: t<string>('LanguageCode->Dgr'),
    [LanguageCode.Din]: t<string>('LanguageCode->Din'),
    [LanguageCode.Div]: t<string>('LanguageCode->Div'),
    [LanguageCode.Doi]: t<string>('LanguageCode->Doi'),
    [LanguageCode.Dra]: t<string>('LanguageCode->Dra'),
    [LanguageCode.Dsb]: t<string>('LanguageCode->Dsb'),
    [LanguageCode.Dua]: t<string>('LanguageCode->Dua'),
    [LanguageCode.Dum]: t<string>('LanguageCode->Dum'),
    [LanguageCode.Dyu]: t<string>('LanguageCode->Dyu'),
    [LanguageCode.Dzo]: t<string>('LanguageCode->Dzo'),
    [LanguageCode.Efi]: t<string>('LanguageCode->Efi'),
    [LanguageCode.Egy]: t<string>('LanguageCode->Egy'),
    [LanguageCode.Eka]: t<string>('LanguageCode->Eka'),
    [LanguageCode.Ell]: t<string>('LanguageCode->Ell'),
    [LanguageCode.Elx]: t<string>('LanguageCode->Elx'),
    [LanguageCode.Eng]: t<string>('LanguageCode->Eng'),
    [LanguageCode.Enm]: t<string>('LanguageCode->Enm'),
    [LanguageCode.Epo]: t<string>('LanguageCode->Epo'),
    [LanguageCode.Est]: t<string>('LanguageCode->Est'),
    [LanguageCode.Eus]: t<string>('LanguageCode->Eus'),
    [LanguageCode.Ewe]: t<string>('LanguageCode->Ewe'),
    [LanguageCode.Ewo]: t<string>('LanguageCode->Ewo'),
    [LanguageCode.Fan]: t<string>('LanguageCode->Fan'),
    [LanguageCode.Fao]: t<string>('LanguageCode->Fao'),
    [LanguageCode.Fas]: t<string>('LanguageCode->Fas'),
    [LanguageCode.Fat]: t<string>('LanguageCode->Fat'),
    [LanguageCode.Fij]: t<string>('LanguageCode->Fij'),
    [LanguageCode.Fil]: t<string>('LanguageCode->Fil'),
    [LanguageCode.Fin]: t<string>('LanguageCode->Fin'),
    [LanguageCode.Fiu]: t<string>('LanguageCode->Fiu'),
    [LanguageCode.Fon]: t<string>('LanguageCode->Fon'),
    [LanguageCode.Fra]: t<string>('LanguageCode->Fra'),
    [LanguageCode.Frm]: t<string>('LanguageCode->Frm'),
    [LanguageCode.Fro]: t<string>('LanguageCode->Fro'),
    [LanguageCode.Frr]: t<string>('LanguageCode->Frr'),
    [LanguageCode.Frs]: t<string>('LanguageCode->Frs'),
    [LanguageCode.Fry]: t<string>('LanguageCode->Fry'),
    [LanguageCode.Ful]: t<string>('LanguageCode->Ful'),
    [LanguageCode.Fur]: t<string>('LanguageCode->Fur'),
    [LanguageCode.Gaa]: t<string>('LanguageCode->Gaa'),
    [LanguageCode.Gay]: t<string>('LanguageCode->Gay'),
    [LanguageCode.Gba]: t<string>('LanguageCode->Gba'),
    [LanguageCode.Gem]: t<string>('LanguageCode->Gem'),
    [LanguageCode.Gez]: t<string>('LanguageCode->Gez'),
    [LanguageCode.Gil]: t<string>('LanguageCode->Gil'),
    [LanguageCode.Gla]: t<string>('LanguageCode->Gla'),
    [LanguageCode.Gle]: t<string>('LanguageCode->Gle'),
    [LanguageCode.Glg]: t<string>('LanguageCode->Glg'),
    [LanguageCode.Glv]: t<string>('LanguageCode->Glv'),
    [LanguageCode.Gmh]: t<string>('LanguageCode->Gmh'),
    [LanguageCode.Goh]: t<string>('LanguageCode->Goh'),
    [LanguageCode.Gon]: t<string>('LanguageCode->Gon'),
    [LanguageCode.Gor]: t<string>('LanguageCode->Gor'),
    [LanguageCode.Got]: t<string>('LanguageCode->Got'),
    [LanguageCode.Grb]: t<string>('LanguageCode->Grb'),
    [LanguageCode.Grc]: t<string>('LanguageCode->Grc'),
    [LanguageCode.Grn]: t<string>('LanguageCode->Grn'),
    [LanguageCode.Gsw]: t<string>('LanguageCode->Gsw'),
    [LanguageCode.Guj]: t<string>('LanguageCode->Guj'),
    [LanguageCode.Gwi]: t<string>('LanguageCode->Gwi'),
    [LanguageCode.Hai]: t<string>('LanguageCode->Hai'),
    [LanguageCode.Hat]: t<string>('LanguageCode->Hat'),
    [LanguageCode.Hau]: t<string>('LanguageCode->Hau'),
    [LanguageCode.Haw]: t<string>('LanguageCode->Haw'),
    [LanguageCode.Heb]: t<string>('LanguageCode->Heb'),
    [LanguageCode.Her]: t<string>('LanguageCode->Her'),
    [LanguageCode.Hil]: t<string>('LanguageCode->Hil'),
    [LanguageCode.Him]: t<string>('LanguageCode->Him'),
    [LanguageCode.Hin]: t<string>('LanguageCode->Hin'),
    [LanguageCode.Hit]: t<string>('LanguageCode->Hit'),
    [LanguageCode.Hmn]: t<string>('LanguageCode->Hmn'),
    [LanguageCode.Hmo]: t<string>('LanguageCode->Hmo'),
    [LanguageCode.Hrv]: t<string>('LanguageCode->Hrv'),
    [LanguageCode.Hsb]: t<string>('LanguageCode->Hsb'),
    [LanguageCode.Hun]: t<string>('LanguageCode->Hun'),
    [LanguageCode.Hup]: t<string>('LanguageCode->Hup'),
    [LanguageCode.Hye]: t<string>('LanguageCode->Hye'),
    [LanguageCode.Iba]: t<string>('LanguageCode->Iba'),
    [LanguageCode.Ibo]: t<string>('LanguageCode->Ibo'),
    [LanguageCode.Ido]: t<string>('LanguageCode->Ido'),
    [LanguageCode.Iii]: t<string>('LanguageCode->Iii'),
    [LanguageCode.Ijo]: t<string>('LanguageCode->Ijo'),
    [LanguageCode.Iku]: t<string>('LanguageCode->Iku'),
    [LanguageCode.Ile]: t<string>('LanguageCode->Ile'),
    [LanguageCode.Ilo]: t<string>('LanguageCode->Ilo'),
    [LanguageCode.Ina]: t<string>('LanguageCode->Ina'),
    [LanguageCode.Inc]: t<string>('LanguageCode->Inc'),
    [LanguageCode.Ind]: t<string>('LanguageCode->Ind'),
    [LanguageCode.Ine]: t<string>('LanguageCode->Ine'),
    [LanguageCode.Inh]: t<string>('LanguageCode->Inh'),
    [LanguageCode.Ipk]: t<string>('LanguageCode->Ipk'),
    [LanguageCode.Ira]: t<string>('LanguageCode->Ira'),
    [LanguageCode.Iro]: t<string>('LanguageCode->Iro'),
    [LanguageCode.Isl]: t<string>('LanguageCode->Isl'),
    [LanguageCode.Ita]: t<string>('LanguageCode->Ita'),
    [LanguageCode.Jav]: t<string>('LanguageCode->Jav'),
    [LanguageCode.Jbo]: t<string>('LanguageCode->Jbo'),
    [LanguageCode.Jpn]: t<string>('LanguageCode->Jpn'),
    [LanguageCode.Jpr]: t<string>('LanguageCode->Jpr'),
    [LanguageCode.Jrb]: t<string>('LanguageCode->Jrb'),
    [LanguageCode.Kaa]: t<string>('LanguageCode->Kaa'),
    [LanguageCode.Kab]: t<string>('LanguageCode->Kab'),
    [LanguageCode.Kac]: t<string>('LanguageCode->Kac'),
    [LanguageCode.Kal]: t<string>('LanguageCode->Kal'),
    [LanguageCode.Kam]: t<string>('LanguageCode->Kam'),
    [LanguageCode.Kan]: t<string>('LanguageCode->Kan'),
    [LanguageCode.Kar]: t<string>('LanguageCode->Kar'),
    [LanguageCode.Kas]: t<string>('LanguageCode->Kas'),
    [LanguageCode.Kat]: t<string>('LanguageCode->Kat'),
    [LanguageCode.Kau]: t<string>('LanguageCode->Kau'),
    [LanguageCode.Kaw]: t<string>('LanguageCode->Kaw'),
    [LanguageCode.Kaz]: t<string>('LanguageCode->Kaz'),
    [LanguageCode.Kbd]: t<string>('LanguageCode->Kbd'),
    [LanguageCode.Kha]: t<string>('LanguageCode->Kha'),
    [LanguageCode.Khi]: t<string>('LanguageCode->Khi'),
    [LanguageCode.Khm]: t<string>('LanguageCode->Khm'),
    [LanguageCode.Kho]: t<string>('LanguageCode->Kho'),
    [LanguageCode.Kik]: t<string>('LanguageCode->Kik'),
    [LanguageCode.Kin]: t<string>('LanguageCode->Kin'),
    [LanguageCode.Kir]: t<string>('LanguageCode->Kir'),
    [LanguageCode.Kmb]: t<string>('LanguageCode->Kmb'),
    [LanguageCode.Kok]: t<string>('LanguageCode->Kok'),
    [LanguageCode.Kom]: t<string>('LanguageCode->Kom'),
    [LanguageCode.Kon]: t<string>('LanguageCode->Kon'),
    [LanguageCode.Kor]: t<string>('LanguageCode->Kor'),
    [LanguageCode.Kos]: t<string>('LanguageCode->Kos'),
    [LanguageCode.Kpe]: t<string>('LanguageCode->Kpe'),
    [LanguageCode.Krc]: t<string>('LanguageCode->Krc'),
    [LanguageCode.Krl]: t<string>('LanguageCode->Krl'),
    [LanguageCode.Kro]: t<string>('LanguageCode->Kro'),
    [LanguageCode.Kru]: t<string>('LanguageCode->Kru'),
    [LanguageCode.Kua]: t<string>('LanguageCode->Kua'),
    [LanguageCode.Kum]: t<string>('LanguageCode->Kum'),
    [LanguageCode.Kur]: t<string>('LanguageCode->Kur'),
    [LanguageCode.Kut]: t<string>('LanguageCode->Kut'),
    [LanguageCode.Lad]: t<string>('LanguageCode->Lad'),
    [LanguageCode.Lah]: t<string>('LanguageCode->Lah'),
    [LanguageCode.Lam]: t<string>('LanguageCode->Lam'),
    [LanguageCode.Lao]: t<string>('LanguageCode->Lao'),
    [LanguageCode.Lat]: t<string>('LanguageCode->Lat'),
    [LanguageCode.Lav]: t<string>('LanguageCode->Lav'),
    [LanguageCode.Lez]: t<string>('LanguageCode->Lez'),
    [LanguageCode.Lim]: t<string>('LanguageCode->Lim'),
    [LanguageCode.Lin]: t<string>('LanguageCode->Lin'),
    [LanguageCode.Lit]: t<string>('LanguageCode->Lit'),
    [LanguageCode.Lol]: t<string>('LanguageCode->Lol'),
    [LanguageCode.Loz]: t<string>('LanguageCode->Loz'),
    [LanguageCode.Ltz]: t<string>('LanguageCode->Ltz'),
    [LanguageCode.Lua]: t<string>('LanguageCode->Lua'),
    [LanguageCode.Lub]: t<string>('LanguageCode->Lub'),
    [LanguageCode.Lug]: t<string>('LanguageCode->Lug'),
    [LanguageCode.Lui]: t<string>('LanguageCode->Lui'),
    [LanguageCode.Lun]: t<string>('LanguageCode->Lun'),
    [LanguageCode.Luo]: t<string>('LanguageCode->Luo'),
    [LanguageCode.Lus]: t<string>('LanguageCode->Lus'),
    [LanguageCode.Mad]: t<string>('LanguageCode->Mad'),
    [LanguageCode.Mag]: t<string>('LanguageCode->Mag'),
    [LanguageCode.Mah]: t<string>('LanguageCode->Mah'),
    [LanguageCode.Mai]: t<string>('LanguageCode->Mai'),
    [LanguageCode.Mak]: t<string>('LanguageCode->Mak'),
    [LanguageCode.Mal]: t<string>('LanguageCode->Mal'),
    [LanguageCode.Man]: t<string>('LanguageCode->Man'),
    [LanguageCode.Map]: t<string>('LanguageCode->Map'),
    [LanguageCode.Mar]: t<string>('LanguageCode->Mar'),
    [LanguageCode.Mas]: t<string>('LanguageCode->Mas'),
    [LanguageCode.Mdf]: t<string>('LanguageCode->Mdf'),
    [LanguageCode.Mdr]: t<string>('LanguageCode->Mdr'),
    [LanguageCode.Men]: t<string>('LanguageCode->Men'),
    [LanguageCode.Mga]: t<string>('LanguageCode->Mga'),
    [LanguageCode.Mic]: t<string>('LanguageCode->Mic'),
    [LanguageCode.Min]: t<string>('LanguageCode->Min'),
    [LanguageCode.Mis]: t<string>('LanguageCode->Mis'),
    [LanguageCode.Mkd]: t<string>('LanguageCode->Mkd'),
    [LanguageCode.Mkh]: t<string>('LanguageCode->Mkh'),
    [LanguageCode.Mlg]: t<string>('LanguageCode->Mlg'),
    [LanguageCode.Mlt]: t<string>('LanguageCode->Mlt'),
    [LanguageCode.Mnc]: t<string>('LanguageCode->Mnc'),
    [LanguageCode.Mni]: t<string>('LanguageCode->Mni'),
    [LanguageCode.Mno]: t<string>('LanguageCode->Mno'),
    [LanguageCode.Moh]: t<string>('LanguageCode->Moh'),
    [LanguageCode.Mon]: t<string>('LanguageCode->Mon'),
    [LanguageCode.Mos]: t<string>('LanguageCode->Mos'),
    [LanguageCode.Mri]: t<string>('LanguageCode->Mri'),
    [LanguageCode.Msa]: t<string>('LanguageCode->Msa'),
    [LanguageCode.Mul]: t<string>('LanguageCode->Mul'),
    [LanguageCode.Mun]: t<string>('LanguageCode->Mun'),
    [LanguageCode.Mus]: t<string>('LanguageCode->Mus'),
    [LanguageCode.Mwl]: t<string>('LanguageCode->Mwl'),
    [LanguageCode.Mwr]: t<string>('LanguageCode->Mwr'),
    [LanguageCode.Mya]: t<string>('LanguageCode->Mya'),
    [LanguageCode.Myn]: t<string>('LanguageCode->Myn'),
    [LanguageCode.Myv]: t<string>('LanguageCode->Myv'),
    [LanguageCode.Nah]: t<string>('LanguageCode->Nah'),
    [LanguageCode.Nai]: t<string>('LanguageCode->Nai'),
    [LanguageCode.Nap]: t<string>('LanguageCode->Nap'),
    [LanguageCode.Nau]: t<string>('LanguageCode->Nau'),
    [LanguageCode.Nav]: t<string>('LanguageCode->Nav'),
    [LanguageCode.Nbl]: t<string>('LanguageCode->Nbl'),
    [LanguageCode.Nde]: t<string>('LanguageCode->Nde'),
    [LanguageCode.Ndo]: t<string>('LanguageCode->Ndo'),
    [LanguageCode.Nds]: t<string>('LanguageCode->Nds'),
    [LanguageCode.Nep]: t<string>('LanguageCode->Nep'),
    [LanguageCode.Nia]: t<string>('LanguageCode->Nia'),
    [LanguageCode.Nic]: t<string>('LanguageCode->Nic'),
    [LanguageCode.Niu]: t<string>('LanguageCode->Niu'),
    [LanguageCode.Nld]: t<string>('LanguageCode->Nld'),
    [LanguageCode.Nno]: t<string>('LanguageCode->Nno'),
    [LanguageCode.Nob]: t<string>('LanguageCode->Nob'),
    [LanguageCode.Nog]: t<string>('LanguageCode->Nog'),
    [LanguageCode.Non]: t<string>('LanguageCode->Non'),
    [LanguageCode.Nor]: t<string>('LanguageCode->Nor'),
    [LanguageCode.Nqo]: t<string>('LanguageCode->Nqo'),
    [LanguageCode.Nso]: t<string>('LanguageCode->Nso'),
    [LanguageCode.Nub]: t<string>('LanguageCode->Nub'),
    [LanguageCode.Nwc]: t<string>('LanguageCode->Nwc'),
    [LanguageCode.Nya]: t<string>('LanguageCode->Nya'),
    [LanguageCode.Nym]: t<string>('LanguageCode->Nym'),
    [LanguageCode.Nyn]: t<string>('LanguageCode->Nyn'),
    [LanguageCode.Nyo]: t<string>('LanguageCode->Nyo'),
    [LanguageCode.Nzi]: t<string>('LanguageCode->Nzi'),
    [LanguageCode.Oci]: t<string>('LanguageCode->Oci'),
    [LanguageCode.Oji]: t<string>('LanguageCode->Oji'),
    [LanguageCode.Ori]: t<string>('LanguageCode->Ori'),
    [LanguageCode.Orm]: t<string>('LanguageCode->Orm'),
    [LanguageCode.Osa]: t<string>('LanguageCode->Osa'),
    [LanguageCode.Oss]: t<string>('LanguageCode->Oss'),
    [LanguageCode.Ota]: t<string>('LanguageCode->Ota'),
    [LanguageCode.Oto]: t<string>('LanguageCode->Oto'),
    [LanguageCode.Paa]: t<string>('LanguageCode->Paa'),
    [LanguageCode.Pag]: t<string>('LanguageCode->Pag'),
    [LanguageCode.Pal]: t<string>('LanguageCode->Pal'),
    [LanguageCode.Pam]: t<string>('LanguageCode->Pam'),
    [LanguageCode.Pan]: t<string>('LanguageCode->Pan'),
    [LanguageCode.Pap]: t<string>('LanguageCode->Pap'),
    [LanguageCode.Pau]: t<string>('LanguageCode->Pau'),
    [LanguageCode.Peo]: t<string>('LanguageCode->Peo'),
    [LanguageCode.Phi]: t<string>('LanguageCode->Phi'),
    [LanguageCode.Phn]: t<string>('LanguageCode->Phn'),
    [LanguageCode.Pli]: t<string>('LanguageCode->Pli'),
    [LanguageCode.Pol]: t<string>('LanguageCode->Pol'),
    [LanguageCode.Pon]: t<string>('LanguageCode->Pon'),
    [LanguageCode.Por]: t<string>('LanguageCode->Por'),
    [LanguageCode.Pra]: t<string>('LanguageCode->Pra'),
    [LanguageCode.Pro]: t<string>('LanguageCode->Pro'),
    [LanguageCode.Pus]: t<string>('LanguageCode->Pus'),
    [LanguageCode.Que]: t<string>('LanguageCode->Que'),
    [LanguageCode.Raj]: t<string>('LanguageCode->Raj'),
    [LanguageCode.Rap]: t<string>('LanguageCode->Rap'),
    [LanguageCode.Rar]: t<string>('LanguageCode->Rar'),
    [LanguageCode.Roa]: t<string>('LanguageCode->Roa'),
    [LanguageCode.Roh]: t<string>('LanguageCode->Roh'),
    [LanguageCode.Rom]: t<string>('LanguageCode->Rom'),
    [LanguageCode.Ron]: t<string>('LanguageCode->Ron'),
    [LanguageCode.Run]: t<string>('LanguageCode->Run'),
    [LanguageCode.Rup]: t<string>('LanguageCode->Rup'),
    [LanguageCode.Rus]: t<string>('LanguageCode->Rus'),
    [LanguageCode.Sad]: t<string>('LanguageCode->Sad'),
    [LanguageCode.Sag]: t<string>('LanguageCode->Sag'),
    [LanguageCode.Sah]: t<string>('LanguageCode->Sah'),
    [LanguageCode.Sai]: t<string>('LanguageCode->Sai'),
    [LanguageCode.Sam]: t<string>('LanguageCode->Sam'),
    [LanguageCode.San]: t<string>('LanguageCode->San'),
    [LanguageCode.Sas]: t<string>('LanguageCode->Sas'),
    [LanguageCode.Sat]: t<string>('LanguageCode->Sat'),
    [LanguageCode.Scn]: t<string>('LanguageCode->Scn'),
    [LanguageCode.Sco]: t<string>('LanguageCode->Sco'),
    [LanguageCode.Sel]: t<string>('LanguageCode->Sel'),
    [LanguageCode.Sem]: t<string>('LanguageCode->Sem'),
    [LanguageCode.Sga]: t<string>('LanguageCode->Sga'),
    [LanguageCode.Sgn]: t<string>('LanguageCode->Sgn'),
    [LanguageCode.Shn]: t<string>('LanguageCode->Shn'),
    [LanguageCode.Sid]: t<string>('LanguageCode->Sid'),
    [LanguageCode.Sin]: t<string>('LanguageCode->Sin'),
    [LanguageCode.Sio]: t<string>('LanguageCode->Sio'),
    [LanguageCode.Sit]: t<string>('LanguageCode->Sit'),
    [LanguageCode.Sla]: t<string>('LanguageCode->Sla'),
    [LanguageCode.Slk]: t<string>('LanguageCode->Slk'),
    [LanguageCode.Slv]: t<string>('LanguageCode->Slv'),
    [LanguageCode.Sma]: t<string>('LanguageCode->Sma'),
    [LanguageCode.Sme]: t<string>('LanguageCode->Sme'),
    [LanguageCode.Smi]: t<string>('LanguageCode->Smi'),
    [LanguageCode.Smj]: t<string>('LanguageCode->Smj'),
    [LanguageCode.Smn]: t<string>('LanguageCode->Smn'),
    [LanguageCode.Smo]: t<string>('LanguageCode->Smo'),
    [LanguageCode.Sms]: t<string>('LanguageCode->Sms'),
    [LanguageCode.Sna]: t<string>('LanguageCode->Sna'),
    [LanguageCode.Snd]: t<string>('LanguageCode->Snd'),
    [LanguageCode.Snk]: t<string>('LanguageCode->Snk'),
    [LanguageCode.Sog]: t<string>('LanguageCode->Sog'),
    [LanguageCode.Som]: t<string>('LanguageCode->Som'),
    [LanguageCode.Son]: t<string>('LanguageCode->Son'),
    [LanguageCode.Sot]: t<string>('LanguageCode->Sot'),
    [LanguageCode.Spa]: t<string>('LanguageCode->Spa'),
    [LanguageCode.Sqi]: t<string>('LanguageCode->Sqi'),
    [LanguageCode.Srd]: t<string>('LanguageCode->Srd'),
    [LanguageCode.Srn]: t<string>('LanguageCode->Srn'),
    [LanguageCode.Srp]: t<string>('LanguageCode->Srp'),
    [LanguageCode.Srr]: t<string>('LanguageCode->Srr'),
    [LanguageCode.Ssa]: t<string>('LanguageCode->Ssa'),
    [LanguageCode.Ssw]: t<string>('LanguageCode->Ssw'),
    [LanguageCode.Suk]: t<string>('LanguageCode->Suk'),
    [LanguageCode.Sun]: t<string>('LanguageCode->Sun'),
    [LanguageCode.Sus]: t<string>('LanguageCode->Sus'),
    [LanguageCode.Sux]: t<string>('LanguageCode->Sux'),
    [LanguageCode.Swa]: t<string>('LanguageCode->Swa'),
    [LanguageCode.Swe]: t<string>('LanguageCode->Swe'),
    [LanguageCode.Syc]: t<string>('LanguageCode->Syc'),
    [LanguageCode.Syr]: t<string>('LanguageCode->Syr'),
    [LanguageCode.Tah]: t<string>('LanguageCode->Tah'),
    [LanguageCode.Tai]: t<string>('LanguageCode->Tai'),
    [LanguageCode.Tam]: t<string>('LanguageCode->Tam'),
    [LanguageCode.Tat]: t<string>('LanguageCode->Tat'),
    [LanguageCode.Tel]: t<string>('LanguageCode->Tel'),
    [LanguageCode.Tem]: t<string>('LanguageCode->Tem'),
    [LanguageCode.Ter]: t<string>('LanguageCode->Ter'),
    [LanguageCode.Tet]: t<string>('LanguageCode->Tet'),
    [LanguageCode.Tgk]: t<string>('LanguageCode->Tgk'),
    [LanguageCode.Tgl]: t<string>('LanguageCode->Tgl'),
    [LanguageCode.Tha]: t<string>('LanguageCode->Tha'),
    [LanguageCode.Tig]: t<string>('LanguageCode->Tig'),
    [LanguageCode.Tir]: t<string>('LanguageCode->Tir'),
    [LanguageCode.Tiv]: t<string>('LanguageCode->Tiv'),
    [LanguageCode.Tkl]: t<string>('LanguageCode->Tkl'),
    [LanguageCode.Tlh]: t<string>('LanguageCode->Tlh'),
    [LanguageCode.Tli]: t<string>('LanguageCode->Tli'),
    [LanguageCode.Tmh]: t<string>('LanguageCode->Tmh'),
    [LanguageCode.Tog]: t<string>('LanguageCode->Tog'),
    [LanguageCode.Ton]: t<string>('LanguageCode->Ton'),
    [LanguageCode.Tpi]: t<string>('LanguageCode->Tpi'),
    [LanguageCode.Tsi]: t<string>('LanguageCode->Tsi'),
    [LanguageCode.Tsn]: t<string>('LanguageCode->Tsn'),
    [LanguageCode.Tso]: t<string>('LanguageCode->Tso'),
    [LanguageCode.Tuk]: t<string>('LanguageCode->Tuk'),
    [LanguageCode.Tum]: t<string>('LanguageCode->Tum'),
    [LanguageCode.Tup]: t<string>('LanguageCode->Tup'),
    [LanguageCode.Tur]: t<string>('LanguageCode->Tur'),
    [LanguageCode.Tut]: t<string>('LanguageCode->Tut'),
    [LanguageCode.Tvl]: t<string>('LanguageCode->Tvl'),
    [LanguageCode.Twi]: t<string>('LanguageCode->Twi'),
    [LanguageCode.Tyv]: t<string>('LanguageCode->Tyv'),
    [LanguageCode.Udm]: t<string>('LanguageCode->Udm'),
    [LanguageCode.Uga]: t<string>('LanguageCode->Uga'),
    [LanguageCode.Uig]: t<string>('LanguageCode->Uig'),
    [LanguageCode.Ukr]: t<string>('LanguageCode->Ukr'),
    [LanguageCode.Umb]: t<string>('LanguageCode->Umb'),
    [LanguageCode.Und]: t<string>('LanguageCode->Und'),
    [LanguageCode.Urd]: t<string>('LanguageCode->Urd'),
    [LanguageCode.Uzb]: t<string>('LanguageCode->Uzb'),
    [LanguageCode.Vai]: t<string>('LanguageCode->Vai'),
    [LanguageCode.Ven]: t<string>('LanguageCode->Ven'),
    [LanguageCode.Vie]: t<string>('LanguageCode->Vie'),
    [LanguageCode.Vol]: t<string>('LanguageCode->Vol'),
    [LanguageCode.Vot]: t<string>('LanguageCode->Vot'),
    [LanguageCode.Wak]: t<string>('LanguageCode->Wak'),
    [LanguageCode.Wal]: t<string>('LanguageCode->Wal'),
    [LanguageCode.War]: t<string>('LanguageCode->War'),
    [LanguageCode.Was]: t<string>('LanguageCode->Was'),
    [LanguageCode.Wen]: t<string>('LanguageCode->Wen'),
    [LanguageCode.Wln]: t<string>('LanguageCode->Wln'),
    [LanguageCode.Wol]: t<string>('LanguageCode->Wol'),
    [LanguageCode.Xal]: t<string>('LanguageCode->Xal'),
    [LanguageCode.Xho]: t<string>('LanguageCode->Xho'),
    [LanguageCode.Yao]: t<string>('LanguageCode->Yao'),
    [LanguageCode.Yap]: t<string>('LanguageCode->Yap'),
    [LanguageCode.Yid]: t<string>('LanguageCode->Yid'),
    [LanguageCode.Yor]: t<string>('LanguageCode->Yor'),
    [LanguageCode.Ypk]: t<string>('LanguageCode->Ypk'),
    [LanguageCode.Zap]: t<string>('LanguageCode->Zap'),
    [LanguageCode.Zbl]: t<string>('LanguageCode->Zbl'),
    [LanguageCode.Zen]: t<string>('LanguageCode->Zen'),
    [LanguageCode.Zgh]: t<string>('LanguageCode->Zgh'),
    [LanguageCode.Zha]: t<string>('LanguageCode->Zha'),
    [LanguageCode.Zho]: t<string>('LanguageCode->Zho'),
    [LanguageCode.Znd]: t<string>('LanguageCode->Znd'),
    [LanguageCode.Zul]: t<string>('LanguageCode->Zul'),
    [LanguageCode.Zun]: t<string>('LanguageCode->Zun'),
    [LanguageCode.Zxx]: t<string>('LanguageCode->Zxx'),
    [LanguageCode.Zza]: t<string>('LanguageCode->Zza')
  }
  return (code: LanguageCode) => translatedCodes[code]
}
