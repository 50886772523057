import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  AnonymizeUserMutation,
  AnonymizeUserMutationVariables,
  AvailableRolesQuery,
  AvailableRolesQueryVariables,
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
  CreateUserMutation,
  DisableUserMutation,
  DisableUserMutationVariables,
  EnableUserMutation,
  EnableUserMutationVariables,
  MutationCreateUserArgs,
  PermissionRelationsInput,
  ResendInvitationMutation,
  ResendInvitationMutationVariables,
  RevokeInvitationMutation,
  RevokeInvitationMutationVariables,
  UpdateMeMutation,
  UpdateMeMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserInput,
  UserPropertiesFragment,
  UserQuery,
  UserQueryVariables,
  UsersQuery,
  UsersQueryVariables
} from '../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../utils/apollo'
import {
  ROLE_PROPERTIES_FRAGMENT,
  USER_PROPERTIES_FRAGMENT
} from '../../../../utils/auth'

export const GET_USERS = gql`
  query Users {
    users {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

export const GET_USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

const CREATE_USER = gql`
  mutation CreateUser(
    $data: UserInput!
    $password: String!
    $roleIds: [Int!]!
    $relations: PermissionRelationsInput!
  ) {
    createUser(
      data: $data
      password: $password
      roleIds: $roleIds
      relations: $relations
    ) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $data: UserInput!
    $roleIds: [Int!]
    $relations: PermissionRelationsInput
  ) {
    updateUser(id: $id, data: $data, roleIds: $roleIds, relations: $relations) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

const UPDATE_ME = gql`
  mutation UpdateMe($data: UserInput!) {
    updateMe(data: $data) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

const CHANGE_PASSWORD = gql`
  mutation changePassword($id: Int!, $password: String!, $sendEmail: Boolean!) {
    changePassword(id: $id, password: $password, sendEmail: $sendEmail)
  }
`

const DISABLE_USER = gql`
  mutation DisableUser($id: Int!) {
    disableUser(id: $id) {
      id
      state
    }
  }
`

const ENABLE_USER = gql`
  mutation EnableUser($id: Int!) {
    enableUser(id: $id) {
      id
      state
    }
  }
`

const ANONYMIZE_USER = gql`
  mutation AnonymizeUser($id: Int!) {
    anonymizeUser(id: $id) {
      ...UserProperties
    }
  }
  ${USER_PROPERTIES_FRAGMENT}
`

const REVOKE_INVITATION = gql`
  mutation RevokeInvitation($id: Int!) {
    revokeInvitation(id: $id) {
      id
    }
  }
`

export const GET_AVAILABLE_ROLES = gql`
  query AvailableRoles {
    availableRoles {
      ...RoleProperties
    }
  }
  ${ROLE_PROPERTIES_FRAGMENT}
`

const RESEND_INVITATION = gql`
  mutation ResendInvitation($id: Int!) {
    resendInvitation(id: $id)
  }
`

interface UsersCacheResult {
  users: Array<UserPropertiesFragment>
}

export const useCreateUser = () => {
  const [createUser] = useMutation<CreateUserMutation, MutationCreateUserArgs>(
    CREATE_USER,
    {
      update(cache, {data}) {
        const cachedData = cache.readQuery<UsersCacheResult>({query: GET_USERS})
        const users = cachedData ? cachedData.users : []
        data &&
          cache.writeQuery({
            query: GET_USERS,
            data: {
              users: [...users, data.createUser]
            }
          })
      }
    }
  )
  return useCallback(
    (
      data: UserInput,
      password: string,
      roleIds: Array<number>,
      relations: PermissionRelationsInput
    ) => createUser({variables: {data, password, roleIds, relations}}),
    [createUser]
  )
}

export const useUpdateUser = () => {
  const [updateUser] =
    useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER)
  return useCallback(
    (
      id: number,
      data: UserInput,
      roleIds?: Array<number>,
      relations?: PermissionRelationsInput
    ) => updateUser({variables: {id, data, roleIds, relations}}),
    [updateUser]
  )
}

export const useUpdateMe = () => {
  const [updateMe] =
    useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UPDATE_ME)
  return useCallback(
    (data: UserInput) => updateMe({variables: {data}}),
    [updateMe]
  )
}

export const useChangePassword = () => {
  const [changePassword] =
    useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
      CHANGE_PASSWORD
    )
  return useCallback(
    (id: number, password: string, sendEmail: boolean) =>
      changePassword({variables: {id, password, sendEmail}}),
    [changePassword]
  )
}

export const useDisableUser = () => {
  const [disableUser] =
    useMutation<DisableUserMutation, DisableUserMutationVariables>(DISABLE_USER)
  return useCallback(
    (id: number) => disableUser({variables: {id}}),
    [disableUser]
  )
}

export const useAnonymizeUser = () => {
  const [anonymizeUser] =
    useMutation<AnonymizeUserMutation, AnonymizeUserMutationVariables>(
      ANONYMIZE_USER
    )
  return useCallback(
    (id: number) => anonymizeUser({variables: {id}}),
    [anonymizeUser]
  )
}

export const useEnableUser = () => {
  const [enableUser] =
    useMutation<EnableUserMutation, EnableUserMutationVariables>(ENABLE_USER)
  return useCallback(
    (id: number) => enableUser({variables: {id}}),
    [enableUser]
  )
}

export const useRevokeInvitation = (id: number) => {
  const [revokeInvitation] = useMutation<
    RevokeInvitationMutation,
    RevokeInvitationMutationVariables
  >(REVOKE_INVITATION, {
    refetchQueries: () => {
      return [{query: GET_USERS}]
    },
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, data.revokeInvitation)
      }
    }
  })
  return useCallback(
    () => revokeInvitation({variables: {id}}),
    [id, revokeInvitation]
  )
}

export const useGetUsers = () => {
  return useQuery<UsersQuery, UsersQueryVariables>(GET_USERS)
}

export const useGetUser = (id: number) => {
  return useQuery<UserQuery, UserQueryVariables>(GET_USER, {variables: {id}})
}

export const useGetAvailableRoles = () => {
  return useQuery<AvailableRolesQuery, AvailableRolesQueryVariables>(
    GET_AVAILABLE_ROLES
  )
}

export const useResendInvitation = () => {
  const [resendInvitation] =
    useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
      RESEND_INVITATION
    )
  return useCallback(
    (id: number) => resendInvitation({variables: {id}}),
    [resendInvitation]
  )
}
