import {
  TourTimeSlotInput,
  TourTimeSlotState
} from '../../../__generated__/schema'
import {ITourTimeSlotForm, TourTimeSlotFormField} from './types'

export const transformTourTimeSlotFormToInput = (
  formData: ITourTimeSlotForm,
  state?: TourTimeSlotState
): TourTimeSlotInput => ({
  ...formData,
  state,
  admissionRateId: parseInt(
    formData[TourTimeSlotFormField.AdmissionRateId],
    10
  ),
  guideId: formData.guideId ? parseInt(formData.guideId, 10) : undefined,
  venueId: parseInt(formData[TourTimeSlotFormField.VenueId], 10),
  retailAttendeesLimit: formData.retailAttendeesLimit
    ? parseInt(formData.retailAttendeesLimit, 10)
    : undefined,
  eCommerceAttendeesLimit: formData.eCommerceAttendeesLimit
    ? parseInt(formData.eCommerceAttendeesLimit, 10)
    : undefined,
  eCommerceOrderAttendeesLimit: parseInt(
    formData[TourTimeSlotFormField.ECommerceOrderAttendeesLimit],
    10
  ),
  costCenterId: formData.costCenterId
    ? parseInt(formData.costCenterId, 10)
    : undefined,
  marketingLabelId: formData.marketingLabelId
    ? parseInt(formData.marketingLabelId, 10)
    : undefined,
  eventCategoryId: formData.eventCategoryId
    ? parseInt(formData.eventCategoryId, 10)
    : undefined,
  ageClassificationCode:
    formData[TourTimeSlotFormField.AgeClassificationCode] || undefined,
  ticketNote: formData[TourTimeSlotFormField.TicketNote] || undefined,
  versionCode: formData[TourTimeSlotFormField.VersionCode] || undefined
})
