import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {DetailClientShowPropertiesFragment} from '../../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../../hooks/getLocales'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {
  getShowTranslation,
  useGetShowTitle
} from '../../../../../utils/translations'
import {ShowSection} from '../../../../constants'
import {useBackdropState} from '../../../../context/backdrop'
import {useNotifications} from '../../../../context/notifications'
import {useUpdateClientShow} from '../graphql'
import {ShowCenteredLayoutWithSingleNavigation} from '../ShowCenteredLayoutWithNavigation'
import {ShowForm, useShowFormStyles} from '../ShowForm'
import {IShowForm, ShowFormLocation} from '../types'
import {useShowDetailsFormAnchors} from '../useShowDetailsFormAnchors'
import {
  getShowFormDefaultValues,
  transformIShowFormToShowTranslationInput,
  transformIShowFormToUpdateClientShowInput
} from '../utils'
import {EditClientShowPageDetailTemplate} from './EditClientShowPageDetailTemplate'

const EDIT_CLIENT_SHOW_DATA_FORM_ID = 'editClientShowDataFormId'

interface IEditClientShowDataProps {
  clientShow: DetailClientShowPropertiesFragment
}

export const EditClientShowData: React.FC<IEditClientShowDataProps> = ({
  clientShow
}: IEditClientShowDataProps) => {
  const history = useHistory()
  const handleClose = useCallback(
    () => history.replace(routeTo.admin.shows.home()),
    [history]
  )
  const {t} = useTranslation()

  const updateClientShow = useUpdateClientShow()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()
  const navigationItems = useShowDetailsFormAnchors()

  const handleSubmit = useCallback(
    async (showForm: IShowForm) => {
      setShowBackdrop(true)
      try {
        await updateClientShow({
          id: clientShow.id,
          data: transformIShowFormToUpdateClientShowInput(showForm),
          translation: transformIShowFormToShowTranslationInput(showForm)
        })
        addInfoNotification(t('Client show updated'))
      } catch (e) {
        defaultErrorHandler(e, t('Error while updating client show'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      clientShow.id,
      setShowBackdrop,
      t,
      updateClientShow
    ]
  )

  const showFormClasses = useShowFormStyles()

  const localeCode = useClientLocaleCode()

  const translation = getShowTranslation({
    translations: clientShow.translations,
    localeCode
  })
  const defaultValues: IShowForm = getShowFormDefaultValues({
    localeCode,
    translation,
    show: clientShow
  })
  const getShowTitle = useGetShowTitle()
  const showTitle = getShowTitle({
    translations: clientShow.translations,
    originalTitle: clientShow.originalTitle,
    fallbackTitle: t('Show')
  })
  return (
    <EditClientShowPageDetailTemplate
      showName={showTitle}
      formId={EDIT_CLIENT_SHOW_DATA_FORM_ID}
      onClose={handleClose}
      currentTab={ShowSection.Data}
    >
      <ShowCenteredLayoutWithSingleNavigation items={navigationItems}>
        <ShowForm
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          formId={EDIT_CLIENT_SHOW_DATA_FORM_ID}
          className={showFormClasses.form}
          location={ShowFormLocation.ClientUpdate}
        />
      </ShowCenteredLayoutWithSingleNavigation>
    </EditClientShowPageDetailTemplate>
  )
}
