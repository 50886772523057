import React from 'react'
import {useTranslation} from 'react-i18next'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'

interface IOccupiedSeatsLimitReachedErrorDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const OccupiedSeatsLimitReachedErrorDialog: React.FC<IOccupiedSeatsLimitReachedErrorDialogProps> =
  ({isOpen, onClose}: IOccupiedSeatsLimitReachedErrorDialogProps) => {
    const {t} = useTranslation()
    return (
      <ConfirmationDialog
        title={t('Unable to add more items to cart')}
        contentText={t(
          'We are sorry, but you are not allowed to add more items to cart, due to exceeding the maximum number of occupied seats for this event. If you wish to change this limit, you can find it in the event settings.'
        )}
        confirmButtonLabel={t('Got it')}
        onConfirm={onClose}
        isOpen={isOpen}
      />
    )
  }
