import {useMediaQuery} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientShowPropertiesFragment,
  DetailLibraryShowPropertiesFragment,
  LibraryShowPropertiesFragment
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {routeTo} from '../../../../../utils/routes'
import {DrawerWithDebouncedId} from '../../../../common'
import {
  MediaSizes,
  SHOW_IMAGE_HEIGHT,
  SHOW_IMAGE_WIDTH
} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {ClientShowDetailProvider} from '../ClientShowDetailProvider'
import {LibraryShowDetailProvider} from '../LibraryShowDetailProvider'
import {ShowCard} from '../ShowCard'
import {ShowDetailContent} from '../ShowDetailContent'

const useStyles = makeStyles<Theme>((theme) => ({
  pickMessage: {
    margin: theme.spacing(1, 0, 3, 0)
  },
  showsList: {
    width: '100%'
  },
  showBox: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
    width: SHOW_IMAGE_WIDTH + theme.spacing(1.5),
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 4px 16px ${theme.palette.primary.light}`,
      borderRadius: theme.spacing(1)
    }
  },
  selectedShowBox: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0px 4px 16px ${theme.palette.primary.light}`,
    borderRadius: theme.spacing(1)
  },
  showImage: {
    width: SHOW_IMAGE_WIDTH,
    height: SHOW_IMAGE_HEIGHT,
    borderRadius: 5
  },
  showTitle: {
    margin: theme.spacing(2, 0.5),
    width: SHOW_IMAGE_WIDTH - 8
  },
  detailRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    [`@media ${MediaSizes.Phablet}`]: {
      gridTemplateColumns: 'auto'
    },
    gap: theme.spacing(2),
    width: '100%'
  }
}))

interface IMixedShowsGridProps {
  shows: (LibraryShowPropertiesFragment | ClientShowPropertiesFragment)[]
  onUseLibraryShowClicked: (show: DetailLibraryShowPropertiesFragment) => void
}

export const MixedShowsGrid: React.FC<IMixedShowsGridProps> = ({
  shows,
  onUseLibraryShowClicked
}: IMixedShowsGridProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const [selectedShow, setSelectedShow] =
    useState<
      LibraryShowPropertiesFragment | ClientShowPropertiesFragment | null
    >(null)
  const handleClose = useCallback(() => {
    setSelectedShow(null)
  }, [])
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const maxWidth = isPhablet ? '100%' : undefined
  return shows.length ? (
    <>
      <div className={classes.grid}>
        {shows.map((show) => (
          <ShowCard
            key={show.id}
            show={show}
            onClick={() => setSelectedShow(show)}
            maxWidth={maxWidth}
          />
        ))}
      </div>
      {selectedShow?.__typename === 'LibraryShow' && (
        <DrawerWithDebouncedId id={selectedShow.id} onClose={handleClose}>
          {({debouncedId, closeDrawer}) => (
            <div className={classes.detailRoot}>
              <LibraryShowDetailProvider
                showId={debouncedId}
                onClose={closeDrawer}
              >
                {(libraryShow) => (
                  <ShowDetailContent
                    onPrimaryButtonClick={() => {
                      onUseLibraryShowClicked(libraryShow)
                    }}
                    primaryButtonLabel={t('Create')}
                    onClose={closeDrawer}
                    show={libraryShow}
                    title={t('Show details')}
                  />
                )}
              </LibraryShowDetailProvider>
            </div>
          )}
        </DrawerWithDebouncedId>
      )}
      {selectedShow?.__typename === 'ClientShow' && (
        <DrawerWithDebouncedId id={selectedShow.id} onClose={handleClose}>
          {({debouncedId, closeDrawer}) => (
            <div className={classes.detailRoot}>
              <ClientShowDetailProvider
                showId={debouncedId}
                onClose={closeDrawer}
              >
                {(clientShow) => (
                  <ShowDetailContent
                    onPrimaryButtonClick={(id) => {
                      history.push(routeTo.admin.shows.edit(id))
                    }}
                    onClose={closeDrawer}
                    show={clientShow}
                  />
                )}
              </ClientShowDetailProvider>
            </div>
          )}
        </DrawerWithDebouncedId>
      )}
    </>
  ) : (
    <Blank
      title={t('No shows have been found')}
      description={t(
        'Check typed text for mistakes and typos or try to search different text. If you are not able to find any show, that fits your search, you can create new show from the beginning.'
      )}
    />
  )
}
