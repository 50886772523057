import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  EventState,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../../hooks/state'
import {Theme} from '../../../../../../theme'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {useEventsPathnameParams} from '../../../../../../utils/pathname'
import {PricingStatus} from '../../../../../../utils/setPricingsStatus'

import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData,
  StaticSideNavigationList
} from '../../../../../common'
import {
  useCommonFormStyles,
  useDrawerHeaderTitle,
  useGetBackToGeneralInfoLink
} from '../common'
import {EventPricingData, useGetEventPricingData} from './graphql'
import {PricingCard} from './PricingCard'
import {
  CreatePricingDialog,
  DeletePricingDialog,
  EditPricingDialog
} from './PricingDialogs'
import {useEventPricingOptionsAnchors} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  createButton: {
    gridArea: 'createButton',
    alignSelf: 'center',
    justifySelf: 'flex-end',
    marginBottom: theme.spacing(1)
  },
  drawerTemplate: {
    width: '100%'
  },
  drawerGrid: {
    display: 'grid',
    minHeight: '100%',
    gridTemplateColumns: '250px auto auto',
    gridTemplateRows: 'auto 1fr',
    padding: theme.spacing(3, 1, 3, 0),
    gridTemplateAreas: `
      "sideMenu title createButton"
      "sideMenu list  list"
    `
  },
  title: {
    gridArea: 'title'
  },
  list: {
    gridArea: 'list'
  },
  sideMenu: {
    gridArea: 'sideMenu',
    paddingTop: theme.spacing(5),
    position: 'sticky',
    height: 'fit-content',
    top: 0
  },
  pricesBox: {
    paddingTop: theme.spacing(2),
    '&:first-child': {
      paddingTop: 0
    }
  }
}))

interface IPricingsDetailContentProps {
  data: EventPricingData
}

const PricingsDetailContent: React.FC<IPricingsDetailContentProps> = ({
  data
}: IPricingsDetailContentProps) => {
  const classes = useStyles()
  const commonClasses = useCommonFormStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const backLink = useGetBackToGeneralInfoLink()

  const onClose = useCallback(
    () => history.replace(backLink),
    [backLink, history]
  )
  const drawerHeaderTitle = useDrawerHeaderTitle()

  const {
    state: isCreateDialogOpen,
    setTrue: openCreateDialog,
    setFalse: closeCreateDialog
  } = useBooleanState(false)

  const [editPricingId, setEditPricingId] = useState<number | null>(null)
  const openEditDialog = useCallback((id: number) => setEditPricingId(id), [])
  const closeEditDialog = useCallback(() => setEditPricingId(null), [])

  const [deletePricingId, setDeletePricingId] = useState<number | null>(null)
  const openDeleteDialog = useCallback(
    (id: number) => setDeletePricingId(id),
    []
  )
  const closeDeleteDialog = useCallback(() => setDeletePricingId(null), [])

  const sideMenuItems = useEventPricingOptionsAnchors(data.pricings)

  const eventCancelled =
    data.state !== EventState.Draft && data.state !== EventState.Published

  return (
    <DrawerTemplate
      className={classes.drawerTemplate}
      header={
        <DrawerTemplateHeader
          title={drawerHeaderTitle}
          onLeftActionClick={onClose}
          LeftActionIcon={ArrowBackIcon}
        />
      }
    >
      <div className={cn(classes.drawerGrid, commonClasses.drawerFormWrapper)}>
        <StaticSideNavigationList
          items={sideMenuItems}
          className={classes.sideMenu}
        />
        <Typography variant="h6" color="textPrimary" className={classes.title}>
          {t('Pricing')}
        </Typography>
        {P([PermissionCode.CreateEventPricing]) && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.createButton}
            onClick={openCreateDialog}
            disabled={eventCancelled}
          >
            {t('Create new pricing')}
          </Button>
        )}
        <div className={classes.list}>
          {data.pricings.map((pricing) => (
            <div
              className={classes.pricesBox}
              key={pricing.id}
              id={pricing.id.toString()}
            >
              <PricingCard
                {...pricing}
                isLastAvailablePricing={
                  data.pricings.filter(
                    (pricing) => pricing.status !== PricingStatus.Future
                  ).length === 1
                }
                eventState={data.state}
                openEditDialog={openEditDialog}
                openDeleteDialog={openDeleteDialog}
                clientVatRegistered={data.division.client.VATRegistered}
              />
            </div>
          ))}
        </div>
      </div>
      <CreatePricingDialog
        eventId={data.id}
        close={closeCreateDialog}
        pricing={data.pricings[0]}
        isOpen={isCreateDialogOpen}
        clientInfo={data.division.client}
      />
      <EditPricingDialog
        editPricingId={editPricingId}
        close={closeEditDialog}
        pricing={data.pricings.find(({id}) => id === editPricingId)}
        eventId={data.id}
        clientInfo={data.division.client}
      />
      <DeletePricingDialog
        deletePricingId={deletePricingId}
        close={closeDeleteDialog}
        eventId={data.id}
      />
    </DrawerTemplate>
  )
}

export const PricingsDetail: React.FC = () => {
  const {t} = useTranslation()
  const {eventId} = useEventsPathnameParams()
  const {error, loading, data} = useGetEventPricingData(eventId)

  return (
    <RenderOnData
      {...{data, loading, error}}
      errorMessage={t<string>('Could not load event data.')}
    >
      {(data: EventPricingData) => <PricingsDetailContent data={data} />}
    </RenderOnData>
  )
}
