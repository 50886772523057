import {Tab, Tabs, useMediaQuery} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Division} from '../../../../__generated__/schema'
import {MediaSizes} from '../../../constants'
import {Error, Loading} from '../../../visual'

const useStyles = makeStyles<Theme, {isDesktopMinus: boolean}>((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'grid',
    gridTemplateRows: '1fr',
    borderBottom: `solid ${theme.palette.divider} 1px`,
    justifyItems: 'center'
  },
  tabs: {
    maxWidth: ({isDesktopMinus}) => (isDesktopMinus ? 560 : 960),
    padding: ({isDesktopMinus}) =>
      isDesktopMinus ? theme.spacing(0) : theme.spacing(0, 4, 0, 11)
  }
}))

interface IDivision extends Pick<Division, 'id' | 'name'> {}

interface IEnabledDivisionsTabsProps {
  enabledDivisions: IDivision[]
  selectedDivisionId: number | null
  onTabChange: (e: React.ChangeEvent<{}>, divisionId: number) => void
  loading?: boolean
  error?: ApolloError
}

export const EnabledDivisionsTabs: React.FC<IEnabledDivisionsTabsProps> = ({
  enabledDivisions,
  selectedDivisionId,
  onTabChange,
  loading,
  error
}: IEnabledDivisionsTabsProps) => {
  const {t} = useTranslation()
  const isDesktopMinus = useMediaQuery(MediaSizes.DesktopMinus)
  const classes = useStyles({isDesktopMinus})
  if (error) {
    return <Error error={error} message={t('Could not load divisions')} />
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Loading />
      ) : (
        <Tabs
          value={selectedDivisionId}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{root: classes.tabs}}
          variant="scrollable"
        >
          {enabledDivisions.map((division) => (
            <Tab key={division.id} label={division.name} value={division.id} />
          ))}
        </Tabs>
      )}
    </div>
  )
}
