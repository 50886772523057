import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DeviceServices,
  useTranslateDeviceServiceDescription,
  useTranslateDeviceServiceTitle
} from '../../../../../hooks/deviceServices'
import {Theme} from '../../../../../theme'
import {DeviceServiceIcon} from '../../../../common/DeviceServiceIcon'
import {ItemsList} from './ItemsList'

const useStyles = makeStyles<Theme>((theme) => ({
  listTitle: {
    paddingBottom: theme.spacing(1.75)
  },
  listItem: {
    padding: theme.spacing(1.5, 3),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5)
  }
}))

interface IServicesListProps {
  title: string
  services: DeviceServices[]
  className?: string
}

export const ServicesList: React.FC<IServicesListProps> = ({
  services,
  title,
  className
}: IServicesListProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateDeviceServiceTitle = useTranslateDeviceServiceTitle()
  const translateDeviceServiceDescription =
    useTranslateDeviceServiceDescription()
  const history = useHistory()
  const handleItemClick = useCallback(
    (link: string) => () => history.push(link),
    [history]
  )
  return services.length ? (
    <div className={className}>
      <Typography variant="subtitle1" className={classes.listTitle}>
        {title}
      </Typography>
      <ItemsList
        disablePadding
        items={services.map(({link, isAvailable}, index) => ({
          icon: <DeviceServiceIcon link={link} />,
          disabled: !isAvailable,
          onClick: isAvailable ? handleItemClick(link) : undefined,
          primaryText: translateDeviceServiceTitle(link),
          secondaryText: [
            translateDeviceServiceDescription(link),
            isAvailable &&
              index === 0 &&
              t(
                'Is the default service for return after successful checkout of the cart.'
              )
          ]
            .filter(Boolean)
            .join(' ')
        }))}
      />
    </div>
  ) : null
}
