import {Box, useMediaQuery} from '@mui/material'
import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CashDeskEventFragment} from '../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {MediaSizes} from '../../../constants'
import {CashdeskEventItem} from '../components/CashdeskEventItem'
import {GroupedEventsItem} from '../components/GroupedEventsItem'
import {MobileCashdeskEventItem} from '../components/MobileCashdeskEventItem'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../Layout'
import {groupEventsByStartAtDate} from '../utils'
import {Header} from './Header'

interface IEventsViewProps {
  events: CashDeskEventFragment[]
}

export const EventsView: React.FC<IEventsViewProps> = ({
  events
}: IEventsViewProps) => {
  const {t} = useTranslation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  return (
    <PageWithHeaderTemplate header={<Header title={t('Events')} />}>
      <FullScreenCenteredLayout>
        <CenteredLayoutListWrapper>
          {groupEventsByStartAtDate(
            events.map((e) =>
              omit(e, [
                'availableSeatsCount',
                'reservedSeatsCount',
                'soldSeatsCount'
              ])
            ),
            getUserLocaleTranslation
          ).map((groupedEvents) => (
            <Box
              key={groupedEvents.date}
              sx={{
                pb: 1,
                '&:last-child': {
                  paddingBottom: 0
                }
              }}
            >
              <GroupedEventsItem
                item={groupedEvents}
                EventItemComp={
                  isPhablet ? MobileCashdeskEventItem : CashdeskEventItem
                }
                isPhablet={isPhablet}
              />
            </Box>
          ))}
        </CenteredLayoutListWrapper>
      </FullScreenCenteredLayout>
    </PageWithHeaderTemplate>
  )
}
