import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetCheckEventQuery,
  GetCheckTourQuery,
  GetCheckTourTimeSlotQuery,
  GetCheckVenueQuery,
  PassCodeCheckInputType
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {
  CapacityIndicator,
  capacityIndicatorSxProps
} from '../../../../common/CapacityIndicator'
import {useCheckMode} from '../CheckModeContext'
import {CheckMode} from '../types'
import {isGetCheckEventQuery, isGetCheckTourTimeSlotQuery} from '../utils'
import {CheckEventCircularProgress} from './CheckEventCircularProgress'
import {CodeEntry} from './CodeEntry'
import {Header} from './Header'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3, 0, 1.5, 0),
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '50% 50%'
  },
  paper: {
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto auto',
    gridTemplateColumns: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    border: `solid 1px ${theme.palette.divider}`
  },
  eventHeader: {padding: theme.spacing(1, 2)},
  camera: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    border: `solid 1px ${theme.palette.divider}`,
    borderRight: 'none'
  },
  info: {
    padding: theme.spacing(5)
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

interface IDesktopContentProps {
  data:
    | GetCheckEventQuery
    | GetCheckTourTimeSlotQuery
    | GetCheckTourQuery
    | GetCheckVenueQuery
  onReload: () => void
  inputType: PassCodeCheckInputType
}

export const DesktopContent: React.FC<IDesktopContentProps> = ({
  data,
  onReload,
  inputType
}: IDesktopContentProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {checkMode} = useCheckMode()
  const reservedCount = isGetCheckEventQuery(data)
    ? data.event.reservedSeatsCount
    : isGetCheckTourTimeSlotQuery(data)
    ? data.tourTimeSlot.tourItemsStateCounts.reserved
    : undefined
  return (
    <div className={classes.root}>
      <CodeEntry
        className={classes.camera}
        inputType={inputType}
        entityData={data}
      />
      <div className={classes.paper}>
        <Header data={data} className={classes.eventHeader} />
        <Divider />
        <div className={classes.info}>
          {(isGetCheckEventQuery(data) ||
            isGetCheckTourTimeSlotQuery(data)) && (
            <CheckEventCircularProgress
              activity={data}
              onReload={onReload}
              isInCheckInMode={
                checkMode === CheckMode.CheckIn || checkMode === CheckMode.Info
              }
            />
          )}
        </div>
        {Boolean(reservedCount) && (
          <>
            <Divider />
            <div className={classes.footer}>
              <Typography variant="body2">
                {t('Active reservations')}
              </Typography>
              <CapacityIndicator
                tooltipTitle={t('Reserved')}
                colorSx={capacityIndicatorSxProps.reservedSpaces}
                countLabel={String(reservedCount)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
