import {Box, Drawer, drawerClasses, List, Typography} from '@mui/material'
import {orderBy} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TicketItemPropertiesFragment} from '../../../../../__generated__/schema'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {useGetBasicTicketListItemProps} from '../../components/BasicTicketListItem'
import {TicketListItemWithCheckbox} from '../TicketListItem'

interface ISplitReservationDrawerProps {
  isOpen: boolean
  onClose: () => void
  ticketItems: TicketItemPropertiesFragment[]
  addReservationToCart: () => void
  splitReservation: (itemIds: number[]) => void
}

export const SplitReservationDrawer: React.FC<ISplitReservationDrawerProps> = ({
  isOpen,
  onClose,
  ticketItems,
  addReservationToCart,
  splitReservation
}: ISplitReservationDrawerProps) => {
  const {t} = useTranslation()
  const [search, setSearch] = useState<string>('')
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const getBasicTicketListItemProps = useGetBasicTicketListItemProps()
  const createCheckboxClickHandler = useCallback(
    (itemId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedIds((prevIds) =>
        prevIds.includes(itemId)
          ? prevIds.filter((id) => id !== itemId)
          : [...prevIds, itemId]
      )
    },
    []
  )
  const filteredTicketItems = orderBy(ticketItems, 'id', 'asc').filter(
    ({eventSeat, eventPricingToTicketType}) =>
      eventSeat.label.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      (eventSeat.row &&
        eventSeat.row.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      (eventSeat.section &&
        eventSeat.section.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      (eventSeat.floor &&
        eventSeat.floor.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
      eventPricingToTicketType.name
        .toLowerCase()
        .indexOf(search.toLowerCase()) > -1
  )
  const handleExited = useCallback(() => {
    if (!isOpen) {
      setSelectedIds([])
      setSearch('')
    }
  }, [isOpen])
  const handleAddButtonClick = useCallback(() => {
    if (selectedIds.length === ticketItems.length) {
      addReservationToCart()
      onClose()
    } else {
      splitReservation(selectedIds)
    }
  }, [
    addReservationToCart,
    ticketItems.length,
    onClose,
    selectedIds,
    splitReservation
  ])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      SlideProps={{onExited: handleExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 480, width: '100%'}}}
    >
      <DrawerTemplate
        isFooterFullWidth
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Split reservation')}
          />
        }
        footer={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="body2">
              <strong>{t('Selected:')}&nbsp;</strong>
              {selectedIds.length === ticketItems.length
                ? t('all')
                : t('{{subCount}} of {{total}}', {
                    subCount: selectedIds.length,
                    total: ticketItems.length
                  })}
            </Typography>
            <SaveButton
              onClick={handleAddButtonClick}
              disabled={selectedIds.length === 0}
            >
              {t('Add to cart')}
            </SaveButton>
          </Box>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <Box
          sx={{
            px: 3,
            pt: 3,
            display: 'flex',
            gap: 1,
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'common.white',
              zIndex: 1,
              gap: 1
            }}
          >
            <Typography variant="overline" color="textSecondary">
              {t('Select tickets')}
            </Typography>
            <OutlinedInputWithCancelAdornment
              onCancelClick={() => setSearch('')}
              inputProps={{
                value: search,
                onChange: (e) => setSearch(e.target.value),
                placeholder: t('Search for ticket')
              }}
            />
          </Box>
          {filteredTicketItems.length > 0 ? (
            <List sx={{overflowY: 'auto', zIndex: 0}}>
              {filteredTicketItems.map((ticketItem) => (
                <TicketListItemWithCheckbox
                  key={ticketItem.id}
                  {...getBasicTicketListItemProps(ticketItem)}
                  checkBoxProps={{
                    onClick: createCheckboxClickHandler(ticketItem.id),
                    checked: selectedIds.includes(ticketItem.id)
                  }}
                />
              ))}
            </List>
          ) : (
            <Typography variant="body2">{t('No tickets found')}</Typography>
          )}
        </Box>
      </DrawerTemplate>
    </Drawer>
  )
}
