import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PrintIcon from '@mui/icons-material/Print'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import SendIcon from '@mui/icons-material/Send'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode, SaleFilterInput} from '../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {useTranslatedEffectiveClientCurrencySign} from '../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../utils/auth'
import {useEventsPathnameParams} from '../../../utils/pathname'
import {routeTo} from '../../../utils/routes'
import {TentativeCurrentCartFab} from '../../pages/admin/cashDesk/CurrentCartFab'
import {ChildrenOnEffectiveClientSelected} from '../../pages/admin/ChildrenOnEffectiveClientSelected'
import {CartPreviewDrawer} from '../../pages/admin/components/cartPreviewDrawer'
import {TicketPrintingGroupButton} from '../../pages/admin/components/TicketPrintingGroupButton'
import {useSendSaleConfirmationEmail} from '../../pages/admin/graphql'
import {SecondaryHeader} from '../../pages/admin/Header'
import {Error} from '../../visual'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useDecimalFormatter,
  useSellingChannelFormatter
} from '../DataGridTable'
import {EmailDialog, EmailDialogField, IEmailDialogForm} from '../EmailDialog'
import {PageWithHeaderTemplate} from '../PageWithHeaderTemplate'
import {useSales} from './graphql'
import {DEFAULT_SALES_FILTER_INPUT, SalesPageSearch} from './SalesPageSearch'

const DEFAULT_EMAIL_DIALOG_STATE = {
  isOpen: false,
  email: undefined,
  uuid: undefined,
  hash: undefined,
  saleId: undefined
}

const ActionsRenderer = ({
  cartId,
  onEmailIconClick
}: {
  cartId: number
  onEmailIconClick: () => void
}) => {
  const {P} = useEnsurePermissions()
  const {eventId} = useEventsPathnameParams()
  const history = useHistory()
  const handleInfoClick = useCallback(
    () =>
      history.push(
        routeTo.admin.cashDesk.eventSalesCartDetail(eventId, cartId)
      ),
    [cartId, eventId, history]
  )
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {P([PermissionCode.SendSaleConfirmationEmail]) && (
        <IconButton onClick={onEmailIconClick} color="primary">
          <SendIcon />
        </IconButton>
      )}
      {P([PermissionCode.ReadTemplates]) && (
        <TicketPrintingGroupButton
          variant="text"
          icon={<PrintOutlinedIcon />}
          cartId={cartId}
          label={<PrintIcon />}
          disablePortal={false}
        />
      )}
      {P([PermissionCode.ReadCart]) && (
        <IconButton onClick={handleInfoClick}>
          <ChevronRightIcon />
        </IconButton>
      )}
    </Box>
  )
}

export const SalesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {eventId} = useEventsPathnameParams()
  const [searchFilter, setSearchFilter] = useState<SaleFilterInput>(
    DEFAULT_SALES_FILTER_INPUT
  )
  const [emailDialogState, setEmailDialogState] = useState<{
    isOpen: boolean
    email?: string
    uuid?: string
    hash?: string
    saleId?: number
  }>(DEFAULT_EMAIL_DIALOG_STATE)
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error} = useSales(
    {
      filter: {eventId, ...searchFilter},
      paginationInput
    },
    isNaN(eventId)
  )
  const sendSaleConfirmationEmail = useSendSaleConfirmationEmail()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const dateTimeFormatter = useDateTimeFormatter()
  const sellingChannelFormatter = useSellingChannelFormatter()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const decimalFormatter = useDecimalFormatter()
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.cashDesk.eventSales(eventId)),
    [eventId, history]
  )
  const handleSendSaleConfirmationSubmit = useCallback(
    async (form: IEmailDialogForm) => {
      if (
        emailDialogState.uuid &&
        emailDialogState.hash &&
        emailDialogState.saleId
      ) {
        try {
          setShowBackdrop(true)
          await sendSaleConfirmationEmail({
            cartUUID: emailDialogState.uuid,
            cartHash: emailDialogState.hash,
            saleInputs: [{id: emailDialogState.saleId}],
            deliverTo: [form[EmailDialogField.Email]]
          })
          addInfoNotification(
            t('Email has been sent to {{email}}', {
              email: form[EmailDialogField.Email]
            })
          )
          setEmailDialogState(DEFAULT_EMAIL_DIALOG_STATE)
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while sending sale confirmation email')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      emailDialogState.hash,
      emailDialogState.saleId,
      emailDialogState.uuid,
      sendSaleConfirmationEmail,
      setShowBackdrop,
      t
    ]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Sale ID'),
        field: 'id',
        sortable: false,
        minWidth: 100
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Selling channel'),
        field: 'sellingChannel',
        valueFormatter: sellingChannelFormatter,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Value ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'price',
        valueFormatter: decimalFormatter,
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('LeadField->Name'),
        field: 'leadName',
        valueGetter: (params) => params.row.lead?.data.name,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: t('LeadField->Phone'),
        field: 'leadPhone',
        valueGetter: (params) => params.row.lead?.data.phone,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->Email'),
        field: 'leadEmail',
        valueGetter: (params) => params.row.lead?.data.email,
        minWidth: 300,
        sortable: false
      },
      {
        headerName: '',
        field: 'actions',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <ActionsRenderer
              cartId={params.row.cart.id}
              onEmailIconClick={() =>
                setEmailDialogState({
                  isOpen: true,
                  email: params.row.lead?.data.email,
                  uuid: params.row.cart.uuid,
                  hash: params.row.cart.hash,
                  saleId: params.row.id
                })
              }
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 200
      }
    ],
    [
      dateTimeFormatter,
      decimalFormatter,
      t,
      sellingChannelFormatter,
      translatedEffectiveClientCurrencySign
    ]
  )
  if (error) {
    return <Error error={error} message={t('Error while loading sales')} />
  }
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={t('Sales')}
          hasArrowBackIcon
          onLeftActionClick={() =>
            history.push(routeTo.admin.cashDesk.eventAuditoriumPreview(eventId))
          }
          search={<SalesPageSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
          <DataGridTable
            columns={columns}
            rows={data?.sales.items || []}
            loading={loading}
            disableColumnMenu
            disableRowSelectionOnClick
            experimentalFeatures={{columnGrouping: true}}
            columnHeaderHeight={32}
            initialState={{pinnedColumns: {left: ['id'], right: ['actions']}}}
            columnVisibilityModel={{
              actions:
                P([PermissionCode.SendSaleConfirmationEmail]) ||
                P([PermissionCode.ReadTemplates]) ||
                P([PermissionCode.ReadCart])
            }}
            columnGroupingModel={[
              {
                groupId: t('Sale'),
                children: [
                  {field: 'createdAt'},
                  {field: 'sellingChannel'},
                  {field: 'price'}
                ]
              },
              {
                groupId: t('Customer'),
                children: [
                  {field: 'leadName'},
                  {field: 'leadPhone'},
                  {field: 'leadEmail'}
                ]
              }
            ]}
            localeText={{noRowsLabel: t('No sales to show')}}
            {...getDataGridPaginationProps(data?.sales.pagination)}
          />
        </Box>
        <TentativeCurrentCartFab />
        {emailDialogState.isOpen && (
          <EmailDialog
            isOpen
            title={t('Send sale confirmation')}
            description={t(
              'Confirmation e-mail will contain only selected tickets.'
            )}
            submitButtonLabel={t('Send')}
            submitButtonStartIcon={<SendIcon />}
            inputPlaceholder={t('Type email here')}
            inputLabel={t('Email address')}
            onSubmit={handleSendSaleConfirmationSubmit}
            onCancel={() => setEmailDialogState(DEFAULT_EMAIL_DIALOG_STATE)}
            defaultValues={{
              [EmailDialogField.Email]: emailDialogState.email
            }}
          />
        )}
        <Route
          path={routeTo.admin.cashDesk.eventSalesCartDetail(
            ':eventId',
            ':cartId'
          )}
          exact
        >
          <CartPreviewDrawer onExited={handleExited} />
        </Route>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
