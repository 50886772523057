import {useQuery} from '@apollo/react-hooks'
import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import dayjs from 'dayjs'
import {isEmpty} from 'lodash'
import onScan, {ScanEvent} from 'onscan.js'
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  CustomersForDiscountQuery,
  CustomersForDiscountQueryVariables,
  CustomerState
} from '../../../../__generated__/schema'
import {useDebounce} from '../../../../hooks/debounce'
import {InputRow} from '../../../common'
import {DialogTitleWithCloseButton} from '../../../common/DialogTitleWithCloseButton'
import {FormAutocomplete} from '../../../form/FormAutocomplete'
import {keyCodeMapper} from '../types'
import {CUSTOMERS_FOR_DISCOUNT} from './graphql'
import {ISelectCustomerForm, SelectCustomerFormField} from './types'

const hasExpiredBenefits = (expirationDate?: string) =>
  expirationDate ? dayjs().isAfter(dayjs(expirationDate)) : false

const SELECT_CUSTOMER_FORM_ID = 'selectCustomerForm'

interface ISelectCustomerDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: ISelectCustomerForm) => void
  onScanComplete?: (code: string) => void
}

export const SelectCustomerDialog: React.FC<ISelectCustomerDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onScanComplete
}: ISelectCustomerDialogProps) => {
  const {t} = useTranslation()
  const [search, setSearch] = useState<string | null>(null)
  const {errors, register, watch, setValue, handleSubmit} =
    useForm<ISelectCustomerForm>()
  const {data, loading} = useQuery<
    CustomersForDiscountQuery,
    CustomersForDiscountQueryVariables
  >(CUSTOMERS_FOR_DISCOUNT, {
    variables: {
      paginationInput: {
        offset: 0,
        limit: 5
      },
      filter: {
        hasText: search,
        states: [CustomerState.Active]
      }
    },
    fetchPolicy: 'network-only'
  })
  const options = (data?.customers.items || []).map((item) => ({
    value: item.loyaltyId,
    name: [
      item.loyaltyId,
      item.lead.data.name,
      hasExpiredBenefits(item.loyaltyLevelExpirationDate) && t('Expired')
    ]
      .filter(Boolean)
      .join(' • '),
    additionalValue: item.loyaltyLevelExpirationDate
  }))
  const debouncedSetSearch = useDebounce(setSearch, 500)
  const handleClose = useCallback(() => {
    setSearch(null)
    onClose()
  }, [onClose])
  const _onSubmit = useCallback(
    (formData: ISelectCustomerForm) => {
      onSubmit(formData)
      setSearch(null)
    },
    [onSubmit]
  )
  const handleScanSuccess = useCallback(
    (scan: ScanEvent) => {
      if (isOpen && onScanComplete) {
        onScanComplete(scan.detail.scanCode.toUpperCase())
      }
    },
    [isOpen, onScanComplete]
  )
  useEffect(() => {
    onScan.attachTo(document, {
      keyCodeMapper
    })
    return () => {
      onScan.detachFrom(document)
    }
  }, [])
  useEffect(() => {
    window.document.addEventListener('scan', handleScanSuccess)
    return () => {
      window.document.removeEventListener('scan', handleScanSuccess)
    }
  }, [handleScanSuccess])
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={handleClose}>
        {t('Select customer')}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        <form id={SELECT_CUSTOMER_FORM_ID} onSubmit={handleSubmit(_onSubmit)}>
          <InputRow
            nodes={[
              <FormAutocomplete<ISelectCustomerForm>
                noOptionsText={t('No customer found')}
                loading={loading}
                fullWidth
                errors={errors}
                label={t('Loyalty ID or customer')}
                placeholder={t('Type customer name or loyalty ID')}
                name={SelectCustomerFormField.LoyaltyId}
                key={SelectCustomerFormField.LoyaltyId}
                getOptionDisabled={({additionalValue}) =>
                  hasExpiredBenefits(additionalValue)
                }
                register={register}
                setValue={setValue}
                watch={watch}
                autocompleteOptions={options}
                required
                validationOptions={{required: true}}
                disableClearable={false}
                onInputChange={(e, newValue, reason) => {
                  if (reason === 'input' && newValue) {
                    debouncedSetSearch(newValue)
                  }
                }}
              />
            ]}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={SELECT_CUSTOMER_FORM_ID}
          disabled={isEmpty(watch(SelectCustomerFormField.LoyaltyId))}
        >
          {t('Apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
