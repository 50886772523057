import React from 'react'
import {useTranslation} from 'react-i18next'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {ProductGroupsList} from './ProductGroupsList'

export const ProductGroupsPage: React.FC = () => {
  const {t} = useTranslation()
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Product groups')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <ProductGroupsList />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
