import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypesStatisticsFilterInput,
  GetLightweightCostCentersQuery,
  GetLightweightDivisionsQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightMarketingLabelsQuery,
  LightweightAccessZonesQuery,
  LightweightAccessZonesQueryVariables,
  LightweightToursQuery,
  LightweightToursQueryVariables,
  LightweightVenuesQuery,
  LightweightVenuesQueryVariables,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {
  LIGHTWEIGHT_TOURS,
  LIGHTWEIGHT_VENUES,
  useGetLightweightCostCenters,
  useGetLightweightDivisions,
  useGetLightweightEventCategories,
  useGetLightweightMarketingLabels
} from '../graphql'
import {LIGHTWEIGHT_ACCESS_ZONES} from '../tours/graphql'

const mapNameToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  name: string | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  name: name || undefined
})

const mapTourIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  tourId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  tourId
})

const mapDivisionIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  divisionId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  divisionIds: divisionId ? [divisionId] : undefined
})

const mapVenueIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  venueId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  venueIds: venueId ? [venueId] : undefined
})

const mapAccessZoneIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  tourAccessZoneId?: number
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  tourAccessZoneIds: tourAccessZoneId ? [tourAccessZoneId] : undefined
})

const mapEventCategoryIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  eventCategoryId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  eventCategoryId
})

const mapCostCenterIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  costCenterId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  costCenterId
})

const mapMarketingLabelIdToFilter = (
  filter: AdmissionTypesStatisticsFilterInput,
  marketingLabelId: number | undefined
): AdmissionTypesStatisticsFilterInput => ({
  ...filter,
  marketingLabelId
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getNameFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput
  ) => filter.name || undefined
  const getTourFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    tours: LightweightToursQuery['tours']['items']
  ) => {
    if (filter.tourId) {
      const tour = tours.find((tour) => tour.id === filter.tourId)
      return tour
        ? t('Tour: {{name}}', {name: tour.name})
        : t('Tour ID: {{id}}', {id: filter.tourId})
    }
    return undefined
  }
  const getDivisionFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionIds && filter.divisionIds.length) {
      const filterDivisionId = filter.divisionIds[0]
      const division = divisions.find(
        (division) => division.id === filterDivisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filterDivisionId})
    }
    return undefined
  }
  const getAccessZoneFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    accessZones: {id: number; name: string}[]
  ) => {
    if (filter.tourAccessZoneIds && filter.tourAccessZoneIds.length) {
      const filterAccessZoneId = filter.tourAccessZoneIds[0]
      const accessZone = accessZones.find(({id}) => id === filterAccessZoneId)
      return accessZone
        ? t('Access zone: {{name}}', {name: accessZone.name})
        : t('Access zone ID: {{id}}', {id: filterAccessZoneId})
    }
    return undefined
  }
  const getVenueFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    venues: LightweightVenuesQuery['venues']
  ) => {
    if (filter.venueIds && filter.venueIds.length) {
      const filterVenueId = filter.venueIds[0]
      const venue = venues.find((venue) => venue.id === filterVenueId)
      return venue
        ? t('Venue: {{name}}', {name: venue.name})
        : t('Venue ID: {{id}}', {id: filterVenueId})
    }
    return undefined
  }
  const getEventCategoryFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    eventCategories: GetLightweightEventCategoriesQuery['eventCategories']
  ) => {
    if (filter.eventCategoryId) {
      const eventCategory = eventCategories.find(
        ({id}) => id === filter.eventCategoryId
      )
      return eventCategory
        ? t('Event category: {{name}}', {name: eventCategory.name})
        : t('Event category ID: {{id}}', {id: filter.eventCategoryId})
    }
    return undefined
  }
  const getCostCenterFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    costCenters: GetLightweightCostCentersQuery['costCenters']
  ) => {
    if (filter.costCenterId) {
      const costCenter = costCenters.find(
        (costCenter) => costCenter.id === filter.costCenterId
      )
      return costCenter
        ? t('Cost center: {{name}}', {name: costCenter.name})
        : t('Cost center ID: {{id}}', {id: filter.costCenterId})
    }
    return undefined
  }
  const getMarketingLabelFromSearchObject = (
    filter: AdmissionTypesStatisticsFilterInput,
    marketingLabels: GetLightweightMarketingLabelsQuery['marketingLabels']
  ) => {
    if (filter.marketingLabelId) {
      const marketingLabel = marketingLabels.find(
        ({id}) => id === filter.marketingLabelId
      )
      return marketingLabel
        ? t('Marketing label: {{name}}', {name: marketingLabel.name})
        : t('Marketing label ID: {{id}}', {id: filter.marketingLabelId})
    }
    return undefined
  }
  return {
    getNameFromSearchObject,
    getTourFromSearchObject,
    getDivisionFromSearchObject,
    getAccessZoneFromSearchObject,
    getVenueFromSearchObject,
    getEventCategoryFromSearchObject,
    getCostCenterFromSearchObject,
    getMarketingLabelFromSearchObject
  }
}

interface IAdmissionTypesStatisticsSearchProps {
  onFilterChange: (filter: AdmissionTypesStatisticsFilterInput) => void
}

export const AdmissionTypesStatisticsSearch: React.FC<IAdmissionTypesStatisticsSearchProps> =
  ({onFilterChange}: IAdmissionTypesStatisticsSearchProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {data: toursData} = useQuery<
      LightweightToursQuery,
      LightweightToursQueryVariables
    >(LIGHTWEIGHT_TOURS, {
      fetchPolicy: 'network-only',
      variables: {
        paginationInput: {offset: 0, limit: 300}
      }
    })
    const {divisions} = useGetLightweightDivisions()
    const {data: venuesData} = useQuery<
      LightweightVenuesQuery,
      LightweightVenuesQueryVariables
    >(LIGHTWEIGHT_VENUES, {
      fetchPolicy: 'network-only'
    })
    const {eventCategories} = useGetLightweightEventCategories()
    const {costCenters} = useGetLightweightCostCenters()
    const {marketingLabels} = useGetLightweightMarketingLabels()
    const {data: accessZonesData} = useQuery<
      LightweightAccessZonesQuery,
      LightweightAccessZonesQueryVariables
    >(LIGHTWEIGHT_ACCESS_ZONES, {
      variables: {paginationInput: {offset: 0, limit: 300}},
      fetchPolicy: 'network-only'
    })
    const tourSelectOptions: ISelectOption<number>[] = (
      toursData?.tours.items || []
    ).map(({id, name: label}) => ({id, label}))
    const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
      ({id, name: label}) => ({
        id,
        label
      })
    )
    const venueSelectOptions: ISelectOption<number>[] = (
      venuesData?.venues || []
    ).map(({id, name: label}) => ({id, label}))
    const accessZoneSelectOptions: ISelectOption<number>[] = (
      accessZonesData?.accessZones.items || []
    ).map(({id, name}) => ({id, label: name}))
    const eventCategorySelectOptions: ISelectOption<number>[] =
      eventCategories.map(({id, name}) => ({
        id,
        label: name
      }))
    const costCenterSelectOptions: ISelectOption<number>[] = costCenters.map(
      ({id, name: label}) => ({id, label})
    )
    const marketingLabelSelectOptions: ISelectOption<number>[] =
      marketingLabels.map(({id, name}) => ({
        id,
        label: name
      }))
    const {
      getNameFromSearchObject,
      getTourFromSearchObject,
      getDivisionFromSearchObject,
      getVenueFromSearchObject,
      getAccessZoneFromSearchObject,
      getEventCategoryFromSearchObject,
      getCostCenterFromSearchObject,
      getMarketingLabelFromSearchObject
    } = useGetFieldFromSearchObject()
    const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
      getNameFromSearchObject,
      (filter) => getTourFromSearchObject(filter, toursData?.tours.items || []),
      (filter) => getDivisionFromSearchObject(filter, divisions),
      (filter) => getVenueFromSearchObject(filter, venuesData?.venues || []),
      (filter) =>
        getAccessZoneFromSearchObject(
          filter,
          accessZonesData?.accessZones.items || []
        ),
      (filter) => getEventCategoryFromSearchObject(filter, eventCategories),
      (filter) => getCostCenterFromSearchObject(filter, costCenters),
      (filter) => getMarketingLabelFromSearchObject(filter, marketingLabels)
    )
    return (
      <Search<AdmissionTypesStatisticsFilterInput>
        storageKey="ADMISSIONS_STATISTICS"
        placeholder={t('Search for admission type')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapNameToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={{}}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            {P([PermissionCode.ReadTours]) && (
              <AdvancedSearchSelectRow<
                AdmissionTypesStatisticsFilterInput,
                number
              >
                label={t('Tour')}
                value={advancedSearchObject.tourId || undefined}
                options={tourSelectOptions}
                mapSelectValueToSearchObject={mapTourIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            <AdvancedSearchTextRow<AdmissionTypesStatisticsFilterInput>
              label={t('Admission type')}
              placeholder={t('Enter admission type name')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapNameToFilter}
              value={advancedSearchObject.name || undefined}
            />
            <AdvancedSearchSelectRow<
              AdmissionTypesStatisticsFilterInput,
              number
            >
              label={t('Division')}
              value={
                advancedSearchObject.divisionIds
                  ? advancedSearchObject.divisionIds[0]
                  : undefined
              }
              options={divisionSelectOptions}
              mapSelectValueToSearchObject={mapDivisionIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            {P([PermissionCode.ReadVenues]) && (
              <AdvancedSearchSelectRow<
                AdmissionTypesStatisticsFilterInput,
                number
              >
                label={t('Venue')}
                value={
                  advancedSearchObject.venueIds
                    ? advancedSearchObject.venueIds[0]
                    : undefined
                }
                options={venueSelectOptions}
                mapSelectValueToSearchObject={mapVenueIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadAccessZones]) && (
              <AdvancedSearchSelectRow<
                AdmissionTypesStatisticsFilterInput,
                number
              >
                label={t('Access zone')}
                value={
                  advancedSearchObject.tourAccessZoneIds
                    ? advancedSearchObject.tourAccessZoneIds[0]
                    : undefined
                }
                options={accessZoneSelectOptions}
                mapSelectValueToSearchObject={mapAccessZoneIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadEventCategories]) && (
              <AdvancedSearchSelectRow<
                AdmissionTypesStatisticsFilterInput,
                number
              >
                label={t('Event category')}
                value={advancedSearchObject.eventCategoryId || undefined}
                options={eventCategorySelectOptions}
                mapSelectValueToSearchObject={mapEventCategoryIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadCostCenters]) && (
              <AdvancedSearchSelectRow<
                AdmissionTypesStatisticsFilterInput,
                number
              >
                label={t('Cost center')}
                value={advancedSearchObject.costCenterId || undefined}
                options={costCenterSelectOptions}
                mapSelectValueToSearchObject={mapCostCenterIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
            {P([PermissionCode.ReadMarketingLabels]) && (
              <AdvancedSearchSelectRow<
                AdmissionTypesStatisticsFilterInput,
                number
              >
                label={t('Marketing label')}
                value={advancedSearchObject.marketingLabelId || undefined}
                options={marketingLabelSelectOptions}
                mapSelectValueToSearchObject={mapMarketingLabelIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
          </AdvancedSearchBase>
        )}
      />
    )
  }
