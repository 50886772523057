export type ObjectId = string

export interface ISelectionState {
  ids: Array<ObjectId>
  doubleClick: boolean
}

const initialState: ISelectionState = {
  ids: [],
  doubleClick: false
}

export enum SelectionActionType {
  SELECT_SINGLE = 'select single',
  ADD_OBJECTS_TO_SELECTION = 'add object to selection',
  UNSELECT_SINGLE = 'unselect single',
  UNSELECT_ALL = 'unselect all',
  SELECT_MULTIPLE = 'select multiple',
  SET_DOUBLE_CLICK = 'set double click'
}

interface ISelectAction {
  type: typeof SelectionActionType.ADD_OBJECTS_TO_SELECTION
  payload: Array<ObjectId>
}

interface IUnselectAction {
  type: typeof SelectionActionType.UNSELECT_SINGLE
  payload: ObjectId
}

interface ISelectSingleAction {
  type: typeof SelectionActionType.SELECT_SINGLE
  payload: ObjectId
}

interface IUnselectAllAction {
  type: typeof SelectionActionType.UNSELECT_ALL
}

interface ISelectMultipleAction {
  type: typeof SelectionActionType.SELECT_MULTIPLE
  payload: Array<ObjectId>
}

interface ISetDoubleClickAction {
  type: typeof SelectionActionType.SET_DOUBLE_CLICK
  payload: boolean
}

type SelectionAction =
  | ISelectAction
  | IUnselectAction
  | ISelectSingleAction
  | IUnselectAllAction
  | ISelectMultipleAction
  | ISetDoubleClickAction

export const selectionReducer = (
  state = initialState,
  action: SelectionAction
): ISelectionState => {
  switch (action.type) {
    case SelectionActionType.ADD_OBJECTS_TO_SELECTION: {
      const notSelectedYet = action.payload.filter(
        (id) => !state.ids.includes(id)
      )
      return {...state, ids: [...state.ids, ...notSelectedYet]}
    }
    case SelectionActionType.UNSELECT_SINGLE:
      return {
        ...state,
        ids: state.ids.filter((id: ObjectId) => id !== action.payload),
        doubleClick: false
      }
    case SelectionActionType.SELECT_SINGLE:
      return {
        ...state,
        ids: [action.payload]
      }
    case SelectionActionType.UNSELECT_ALL:
      return initialState
    case SelectionActionType.SELECT_MULTIPLE:
      return {...state, ids: action.payload, doubleClick: false}
    case SelectionActionType.SET_DOUBLE_CLICK:
      return {...state, doubleClick: action.payload}
    default:
      return state
  }
}
