import {useTranslation} from 'react-i18next'
import {PassCodeCheckDirection} from '../__generated__/schema'

export const useTranslatePassCodeCheckDirection = () => {
  const {t} = useTranslation()
  const translated: Record<PassCodeCheckDirection, string> = {
    [PassCodeCheckDirection.Out]: t<string>('PassCodeCheckDirection->Out'),
    [PassCodeCheckDirection.In]: t<string>('PassCodeCheckDirection->In')
  }
  return (direction: PassCodeCheckDirection) => translated[direction]
}
