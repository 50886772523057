import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DiscountPropertiesFragment,
  DiscountState
} from '../../../../../__generated__/schema'
import {
  useTranslateDiscountApplication,
  useTranslateDiscountState
} from '../../../../../hooks/discounts'
import {useFormatDiscountValue} from '../../../../../hooks/formatDiscountValue'
import {Theme} from '../../../../../theme'
import {EntityStateChip} from '../../../../common'

import {GeneralBlock} from '../../../../common/GeneralBlock'
import {discountStateColors} from '../../../../constants'

interface IInfoProps {
  label: string
  info?: string | null
  className?: string
}

const Info = ({label, info, className}: IInfoProps) => (
  <div className={className}>
    <Typography variant="caption" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="subtitle2">{info || <span>&nbsp;</span>}</Typography>
  </div>
)

const useStyles = makeStyles<Theme>((theme) => ({
  grid: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(3)
  },
  stateGrid: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    paddingBottom: theme.spacing(2)
  },
  internalDescriptionInfo: {
    paddingBottom: theme.spacing(2)
  }
}))

interface IGeneralDiscountBlockProps {
  label: string
  blockId: string
  discount: DiscountPropertiesFragment
}

export const GeneralDiscountBlock: React.FC<IGeneralDiscountBlockProps> = ({
  label,
  discount,
  blockId
}: IGeneralDiscountBlockProps) => {
  const formatDiscountValue = useFormatDiscountValue()
  const translateDiscountApplication = useTranslateDiscountApplication()
  const translateDiscountState = useTranslateDiscountState()
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <GeneralBlock label={label} blockId={blockId}>
      <div>
        <div className={classes.stateGrid}>
          <EntityStateChip
            label={translateDiscountState(discount.state)}
            colorConf={discountStateColors[discount.state]}
          />
          <Typography variant="caption" color="textSecondary">
            {discount.state === DiscountState.Active
              ? t('Discount is currently applicable')
              : t('Discount is currently not applicable')}
          </Typography>
        </div>
      </div>
      {discount.internalDescription && (
        <Info
          label={t('Internal description')}
          info={discount.internalDescription}
          className={classes.internalDescriptionInfo}
        />
      )}
      <div>
        <div className={classes.grid}>
          <Info
            label={t('Application')}
            info={translateDiscountApplication(discount.application)}
          />
          <Divider orientation="vertical" />
          <Info label={t('Value')} info={formatDiscountValue(discount)} />
        </div>
      </div>
    </GeneralBlock>
  )
}
