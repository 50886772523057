import {useMutation} from '@apollo/react-hooks'
import {Box, Button, RadioGroup, SxProps, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApiSeatState,
  ChangeEventSeatsAvailabilityMutation,
  ChangeEventSeatsAvailabilityMutationVariables
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {Footer} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {RadioGroupItem} from '../../../../common/RadioGroupItem'
import {CHANGE_EVENT_SEATS_AVAILABILITY} from '../graphql'

interface IEditSeatStatesContentProps {
  selectedUuids: string[]
  eventId: number
  onCancelButtonClick: () => void
  sx?: SxProps<Theme>
}

export const EditSeatStatesContent: React.FC<IEditSeatStatesContentProps> = ({
  selectedUuids,
  eventId,
  onCancelButtonClick,
  sx
}: IEditSeatStatesContentProps) => {
  const {t} = useTranslation()
  const [changeEventSeatsAvailability] = useMutation<
    ChangeEventSeatsAvailabilityMutation,
    ChangeEventSeatsAvailabilityMutationVariables
  >(CHANGE_EVENT_SEATS_AVAILABILITY)
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const handleChange = useCallback(
    (e, value) => {
      const state = value as ApiSeatState
      setShowBackdrop(true)
      changeEventSeatsAvailability({
        variables: {
          eventId,
          uuids: selectedUuids,
          state
        }
      })
        .catch((e) => {
          defaultErrorHandler(e, t('Changing event seat availability failed'))
        })
        .finally(() => {
          setShowBackdrop(false)
        })
    },
    [
      changeEventSeatsAvailability,
      defaultErrorHandler,
      eventId,
      selectedUuids,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Box
      sx={{
        ...sx,
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr auto',
        height: '100%'
      }}
    >
      <Typography variant="subtitle1" sx={{pt: 3, px: 3}}>
        {t('Seat states')}
      </Typography>
      <RadioGroup onChange={handleChange} sx={{p: 3}}>
        <RadioGroupItem
          label={t('Available')}
          subLabel={t('It is possible to click on seat in online and POS')}
          value={ApiSeatState.Available}
        />
        <RadioGroupItem
          label={t('Disabled')}
          subLabel={t(
            "Seats can't be added to cart and are visible for cashiers and customers."
          )}
          value={ApiSeatState.Disabled}
        />
        <RadioGroupItem
          label={t('Hidden')}
          subLabel={t(
            "Seats can't be added to cart and are visible for cashiers but hidden for customers."
          )}
          value={ApiSeatState.Hidden}
        />
      </RadioGroup>
      <Footer sx={{height: 64}}>
        <Button color="primary" onClick={onCancelButtonClick}>
          {t('Cancel')}
        </Button>
      </Footer>
    </Box>
  )
}
