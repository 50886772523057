import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  LightweightECommercePaymentMethodsQuery,
  LightweightRetailPaymentMethodsQuery
} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {
  useIsNonNegativeDecimal,
  useIsPositiveInteger,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormSwitchInput} from '../../../form/FormSwitchInput'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  IVoucherCampaignForm,
  VoucherCampaignFormField,
  VoucherCampaignFormLocation
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  sectionTitle: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  switch: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

interface IVoucherCampaignFormProps {
  location: VoucherCampaignFormLocation
  formId: string
  onSubmit: (data: IVoucherCampaignForm) => void
  defaultValues?: Partial<IVoucherCampaignForm>
  retailPaymentMethods: LightweightRetailPaymentMethodsQuery['retailPaymentMethods']
  eCommercePaymentMethods: LightweightECommercePaymentMethodsQuery['eCommercePaymentMethods']
}

export const VoucherCampaignForm: React.FC<IVoucherCampaignFormProps> = ({
  location,
  formId,
  onSubmit,
  defaultValues,
  retailPaymentMethods,
  eCommercePaymentMethods
}: IVoucherCampaignFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    watch,
    control,
    setValue,
    register,
    triggerValidation,
    handleSubmit
  } = useForm<IVoucherCampaignForm>({defaultValues})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isNonNegativeDecimal = useIsNonNegativeDecimal()
  const isPositiveInteger = useIsPositiveInteger()
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVoucherCampaignForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VoucherCampaignFormField.Name}
            key={VoucherCampaignFormField.Name}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength255
            }}
            required
            fullWidth
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVoucherCampaignForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VoucherCampaignFormField.InitialBalance}
            key={VoucherCampaignFormField.InitialBalance}
            label={t('Initial balance')}
            helperNote={t(
              'If is not set each voucher in campaign can have custom initial balance.'
            )}
            validationOptions={{
              validate: isNonNegativeDecimal
            }}
            fullWidth
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVoucherCampaignForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VoucherCampaignFormField.InitialValidity}
            key={VoucherCampaignFormField.InitialValidity}
            label={t('Initial validity')}
            helperNote={t(
              'Set number of days or leave empty and vouchers will never expires.'
            )}
            validationOptions={{
              validate: isPositiveInteger
            }}
            fullWidth
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVoucherCampaignForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VoucherCampaignFormField.RedemptionLimit}
            key={VoucherCampaignFormField.RedemptionLimit}
            label={t('Redemption limit')}
            validationOptions={{
              validate: isPositiveInteger
            }}
            fullWidth
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVoucherCampaignForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VoucherCampaignFormField.RechargeLimit}
            key={VoucherCampaignFormField.RechargeLimit}
            label={t('Recharge limit')}
            validationOptions={{
              validate: isPositiveInteger
            }}
            fullWidth
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IVoucherCampaignForm>
            selectOptions={retailPaymentMethods.reduce(
              (acc, pm) => ({
                ...acc,
                [pm.id]: pm.name
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={VoucherCampaignFormField.RetailPaymentMethodId}
            key={VoucherCampaignFormField.RetailPaymentMethodId}
            label={t('Retail payment method')}
            helperNote={t(
              'If not filled, voucher won’t be redeemed on retail.'
            )}
            fullWidth
            hasNoneSelectOption
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IVoucherCampaignForm>
            selectOptions={eCommercePaymentMethods.reduce(
              (acc, pm) => ({
                ...acc,
                [pm.id]: pm.name
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={VoucherCampaignFormField.EcommercePaymentMethodId}
            key={VoucherCampaignFormField.EcommercePaymentMethodId}
            label={t('Ecommerce payment method')}
            helperNote={t(
              'If not filled, voucher won’t be redeemed on ecommerce.'
            )}
            fullWidth
            hasNoneSelectOption
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVoucherCampaignForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VoucherCampaignFormField.Description}
            key={VoucherCampaignFormField.Description}
            validationOptions={{
              validate: isStringWithMaxLength1000
            }}
            label={t('Description')}
            multiline
            rows={4}
            fullWidth
            disabled={location === VoucherCampaignFormLocation.Edit}
          />
        ]}
      />
      {location === VoucherCampaignFormLocation.Edit && (
        <div className={classes.switch}>
          <Typography variant="body1" key="title">
            {t('Is allowed to emit new vouchers?')}
          </Typography>
          <FormSwitchInput
            name={VoucherCampaignFormField.CanCreateNewVouchers}
            key={VoucherCampaignFormField.CanCreateNewVouchers}
            control={control}
            watch={watch}
            defaultValue={defaultValues?.canCreateNewVouchers || false}
            enabledText={t('Yes')}
            disabledText={t('No')}
            disabled
          />
        </div>
      )}
    </form>
  )
}
