import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme, {noOffset: boolean}>((theme) => ({
  error: {
    marginTop: ({noOffset}) => (noOffset ? 0 : theme.spacing(1)),
    color: theme.palette.error.main
  }
}))

interface IValidationErrorProps {
  children: React.ReactNode
  noOffset?: boolean
}

export const ValidationError: React.FC<IValidationErrorProps> = ({
  children,
  noOffset = false
}: IValidationErrorProps) => {
  const classes = useStyles({noOffset})
  return (
    <Typography className={classes.error} variant="caption">
      {children}
    </Typography>
  )
}
