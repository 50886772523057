import {TextField} from '@mui/material'
import React from 'react'
import {SearchRowWithLabel} from './SearchRowWithLabel'

export const getTextWithPrefixFromSearchObject =
  <T extends object>(
    prefix: string,
    getTextFromSearchObject: (o: T) => string | undefined
  ): ((o: T) => undefined | string) =>
  (o: T) =>
    getTextFromSearchObject(o) && `${prefix}: ${getTextFromSearchObject(o)}`

interface IAdvancedSearchTextRowProps<T> {
  label: string
  placeholder: string
  setAdvancedSearchObject: (o: T) => void
  mapTextToSearchObject: (o: T, value: string) => T
  advancedSearchObject: T
  value?: string
}

export const AdvancedSearchTextRow = <T extends object>({
  label,
  placeholder,
  setAdvancedSearchObject,
  advancedSearchObject,
  mapTextToSearchObject,
  value
}: IAdvancedSearchTextRowProps<T>) => {
  return (
    <SearchRowWithLabel label={label} labelColor="textSecondary">
      <TextField
        fullWidth
        margin="dense"
        onChange={(e) => {
          setAdvancedSearchObject(
            mapTextToSearchObject(advancedSearchObject, e.target.value)
          )
        }}
        value={value}
        placeholder={placeholder}
      />
    </SearchRowWithLabel>
  )
}
