import React from 'react'
import {useTranslation} from 'react-i18next'

import {EntityStateChip} from '../../../../common'
import {
  AuditoriumLayoutType,
  AuditoriumLayoutState
} from '../../../../../__generated__/schema'
import {
  auditoriumLayoutStateColors,
  useAuditoriumLayoutStateTranslations
} from '../../../../constants'

export const useAuditoriumLayoutTypeToString = () => {
  const {t} = useTranslation()
  return (type: AuditoriumLayoutType) =>
    ({
      [AuditoriumLayoutType.SeatingFloorPlan]: t('Seating plan'),
      [AuditoriumLayoutType.WithoutSeats]: t('Without seats'),
      [AuditoriumLayoutType.ZoneFloor]: t('Zone plan')
    }[type])
}

export const useAuditoriumLayoutStateTextTranslations = () => {
  const {t} = useTranslation()

  return {
    [AuditoriumLayoutState.Active]: t('ActiveAuditoriumLayoutStateText'),
    [AuditoriumLayoutState.Archived]: t('ArchivedAuditoriumLayoutStateText'),
    [AuditoriumLayoutState.Draft]: t('DraftAuditoriumLayoutStateText')
  }
}

export const StateRenderer = ({status}: {status: AuditoriumLayoutState}) => {
  const auditoriumLayoutStateTranslations =
    useAuditoriumLayoutStateTranslations()

  return (
    <EntityStateChip
      label={auditoriumLayoutStateTranslations[status]}
      colorConf={auditoriumLayoutStateColors[status]}
    />
  )
}
