import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  ClaimsFilterInput,
  ClaimState,
  ItemType,
  NarrowClaimPropertiesFragment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateClaimState} from '../../../../hooks/claimState'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {claimStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {CartPreviewDrawer} from '../components/cartPreviewDrawer'
import {ClaimDetailDrawer} from '../components/claimDetailDrawer'
import {ReloadButton} from '../components/ReloadButton'
import {PrimaryHeader} from '../Header'
import {WideCenteredLayout} from '../Layout'
import {ClaimsSearch, DEFAULT_CLAIMS_FILTER_INPUT} from './ClaimsSearch'
import {ClaimStateChip, ClaimStateOption} from './ClaimStateChip'
import {useClaims} from './graphql'

const IconRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.claims.detail(id)),
    [history, id]
  )
  return (
    <IconButton size="small" onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const getClaimStates = (filter: ClaimStateOption) => {
  switch (filter) {
    case ClaimStateOption.Draft:
      return [ClaimState.Draft]
    case ClaimStateOption.Approved:
      return [ClaimState.Approved]
    case ClaimStateOption.Pending:
      return [ClaimState.Pending]
    case ClaimStateOption.Failed:
      return [ClaimState.Failed]
    case ClaimStateOption.Refunded:
      return [ClaimState.Refunded]
    case ClaimStateOption.All:
    default:
      return [
        ClaimState.Draft,
        ClaimState.Approved,
        ClaimState.Pending,
        ClaimState.Failed,
        ClaimState.Refunded
      ]
  }
}

const ClaimStateCellRenderer = ({
  value
}: GridCellParams<{state: ClaimState}, ClaimState>) => {
  const translateClaimState = useTranslateClaimState()
  return value ? (
    <EntityStateChip
      isDotHidden
      colorConf={claimStateColors[value]}
      label={translateClaimState(value)}
    />
  ) : null
}

const NoteCellRenderer = ({
  value,
  row: {event, tourTimeSlot}
}: GridCellParams<NarrowClaimPropertiesFragment, ItemType>) => {
  const {t} = useTranslation()
  const {formatDateTime} = useDateTimeFormatters()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  return value
    ? value === ItemType.Ticket
      ? event?.names && event.startsAt
        ? t('Event: {{eventName}}, {{startsAt}}', {
            eventName: getUserLocaleTranslation(event.names),
            startsAt: formatDateTime(new Date(event.startsAt))
          })
        : t('Event')
      : value === ItemType.Tour
      ? tourTimeSlot
        ? t('Tour: {{tourName}}, {{startsAt}}', {
            tourName: tourTimeSlot.tour.name,
            startsAt: formatDateTime(new Date(tourTimeSlot.startsAt))
          })
        : t('Tour')
      : t('Products')
    : null
}

interface IClaimsListProps {
  searchFilter: ClaimsFilterInput
}

const ClaimsList: React.FC<IClaimsListProps> = ({
  searchFilter
}: IClaimsListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {resetPaginationModel, paginationInput, getDataGridPaginationProps} =
    useDataGridPagination()
  useEffect(() => {
    resetPaginationModel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPaginationModel, JSON.stringify(searchFilter)])
  const [claimState, setClaimState] = useState<ClaimStateOption>(
    ClaimStateOption.Draft
  )
  const {data, loading, error, refetch} = useClaims({
    filter: {
      ...searchFilter,
      states: getClaimStates(claimState)
    },
    paginationInput
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Created at'),
        field: 'createdAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Created by'),
        field: 'createdByName',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: ClaimStateCellRenderer,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Total'),
        field: 'price',
        type: 'number',
        minWidth: 100,
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false
      },
      {
        headerName: t('Items'),
        field: 'itemsCount',
        type: 'number',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Claim'),
        field: 'id',
        type: 'number',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Updated by'),
        field: 'updatedByName',
        minWidth: 156
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        minWidth: 250,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Reason'),
        field: 'reason',
        minWidth: 156,
        sortable: false,
        flex: 1
      },
      {
        headerName: t('Note'),
        field: 'itemType',
        minWidth: 450,
        sortable: false,
        flex: 1,
        renderCell: NoteCellRenderer
      },
      {
        headerName: '',
        field: 'icon',
        editable: false,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconRenderer id={params.value} />
        },
        disableColumnMenu: true,
        width: 32,
        sortable: false,
        valueGetter: ({id}) => id,
        hideable: P([PermissionCode.ReadClaim])
      }
    ],
    [P, dateTimeFormatter, effectiveClientPriceFormatter, t]
  )
  if (error) {
    return <Error error={error} message={t('Loading claims failed')} />
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ClaimStateChip
            key="claim-state-chip"
            state={claimState}
            onChange={setClaimState}
          />
        ]}
        rightActions={[
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout
        sx={{
          width: '100%',
          p: 3
        }}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.claims.items || []}
          localeText={{noRowsLabel: t('No claims to show')}}
          disableColumnFilter
          initialState={{
            pinnedColumns: {
              left: ['createdAt'],
              right: ['icon']
            },
            columns: {
              columnVisibilityModel: {icon: P([PermissionCode.ReadClaim])}
            }
          }}
          {...getDataGridPaginationProps(data?.claims.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}

export const ClaimsPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] = useState<ClaimsFilterInput>(
    DEFAULT_CLAIMS_FILTER_INPUT
  )
  const history = useHistory()
  const {P} = useEnsurePermissions()
  const handleExited = useCallback(() => {
    history.replace(routeTo.admin.claims.index())
  }, [history])
  return (
    <>
      <PageWithHeaderTemplate
        header={
          <PrimaryHeader
            title={t('Claims')}
            search={<ClaimsSearch onFilterChange={setSearchFilter} />}
          />
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <ClaimsList searchFilter={searchFilter} />
        </ChildrenOnEffectiveClientSelected>
      </PageWithHeaderTemplate>
      {P([PermissionCode.ReadClaim]) && (
        <Route path={routeTo.admin.claims.detail(':claimId')} exact>
          <ClaimDetailDrawer onExited={handleExited} />
        </Route>
      )}
      {P([PermissionCode.ReadCart]) && (
        <Route path={routeTo.admin.claims.cartInfo(':cartId')} exact>
          <CartPreviewDrawer onExited={handleExited} />
        </Route>
      )}
    </>
  )
}
