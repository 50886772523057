import React from 'react'
import {useTranslation} from 'react-i18next'
import {LeadData} from '../../../../__generated__/schema'
import {useTranslateCountry} from '../../../../hooks/countries'
import {SectionGridCouple, SectionGridSubtitle} from '../../../common'

interface ILeadSectionGridContentProps {
  leadData?: LeadData
  title?: string
  isPrivacyPolicyHidden?: boolean
}

export const LeadSectionGridContent: React.FC<ILeadSectionGridContentProps> = ({
  leadData = {},
  title,
  isPrivacyPolicyHidden
}: ILeadSectionGridContentProps) => {
  const {t} = useTranslation()
  const translateCountry = useTranslateCountry()
  return (
    <>
      {(leadData.name || leadData.phone || leadData.email) && (
        <>
          <SectionGridSubtitle subtitle={title || t('Customer')} />
          <SectionGridCouple label={t('Name')}>
            {leadData.name}
          </SectionGridCouple>
          <SectionGridCouple label={t('Phone')}>
            {leadData.phone}
          </SectionGridCouple>
          <SectionGridCouple label={t('Email')}>
            {leadData.email}
          </SectionGridCouple>
          {!isPrivacyPolicyHidden && (
            <SectionGridCouple label={t('Privacy policy')}>
              {leadData.isPrivacyPolicyConsentGranted
                ? t('Consent granted')
                : t('Consent not granted')}
            </SectionGridCouple>
          )}
        </>
      )}
      {(leadData.companyName ||
        leadData.companyIdNumber ||
        leadData.VATId ||
        leadData.TAXId) && (
        <>
          <SectionGridSubtitle subtitle={t('Company')} />
          <SectionGridCouple label={t('Company')}>
            {leadData.companyName}
          </SectionGridCouple>
          <SectionGridCouple label={t('Company ID')}>
            {leadData.companyIdNumber}
          </SectionGridCouple>
          <SectionGridCouple label={t('TAX ID')}>
            {leadData.TAXId}
          </SectionGridCouple>
          <SectionGridCouple label={t('VAT ID')}>
            {leadData.VATId}
          </SectionGridCouple>
        </>
      )}
      {(leadData.billingAddressCountry ||
        leadData.billingAddressStreet ||
        leadData.billingAddressTown ||
        leadData.billingPostalCode) && (
        <>
          <SectionGridSubtitle subtitle={t('Billing address')} />
          <SectionGridCouple label={t('Street')}>
            {leadData.billingAddressStreet}
          </SectionGridCouple>
          <SectionGridCouple label={t('Postal code')}>
            {leadData.billingPostalCode}
          </SectionGridCouple>
          <SectionGridCouple label={t('Town')}>
            {leadData.billingAddressTown}
          </SectionGridCouple>
          <SectionGridCouple label={t('Country')}>
            {leadData.billingAddressCountry &&
              translateCountry(leadData.billingAddressCountry)}
          </SectionGridCouple>
        </>
      )}

      {(leadData.deliveryAddressCountry ||
        leadData.deliveryAddressee ||
        leadData.deliveryAddressStreet ||
        leadData.deliveryAddressTown ||
        leadData.deliveryPostalCode) && (
        <>
          <SectionGridSubtitle subtitle={t('Delivery address')} />
          <SectionGridCouple label={t('Addressee')}>
            {leadData.deliveryAddressee}
          </SectionGridCouple>
          <SectionGridCouple label={t('Street')}>
            {leadData.deliveryAddressStreet}
          </SectionGridCouple>
          <SectionGridCouple label={t('Postal code')}>
            {leadData.deliveryPostalCode}
          </SectionGridCouple>
          <SectionGridCouple label={t('Town')}>
            {leadData.deliveryAddressTown}
          </SectionGridCouple>
          <SectionGridCouple label={t('Country')}>
            {leadData.deliveryAddressCountry &&
              translateCountry(leadData.deliveryAddressCountry)}
          </SectionGridCouple>
        </>
      )}

      {(leadData.internalNote || leadData.note) && (
        <>
          <SectionGridSubtitle subtitle={t('Notes')} />
          <SectionGridCouple label={t('Customer note')}>
            {leadData.note}
          </SectionGridCouple>
          <SectionGridCouple label={t('Internal note')}>
            {leadData.internalNote}
          </SectionGridCouple>
        </>
      )}
    </>
  )
}
