import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../../../theme'

interface IListItemWrapperProps {
  children: React.ReactNode
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  }
}))

export const ListItemWrapper: React.FC<IListItemWrapperProps> = ({
  children
}: IListItemWrapperProps) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}
