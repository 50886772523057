import React from 'react'
import {useTranslation} from 'react-i18next'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'

export interface IVenuesSearchFilter {
  hasText?: string
}

const mapHasTextToFiler = (
  filter: IVenuesSearchFilter,
  hasText: string | undefined
): IVenuesSearchFilter => ({...filter, hasText})

const getHasTextFormSearchObject = (filter: IVenuesSearchFilter) =>
  filter.hasText || undefined

interface IVenuesSearchProps {
  onFilterChange: (filter: IVenuesSearchFilter) => void
}

export const VenuesSearch: React.FC<IVenuesSearchProps> = ({
  onFilterChange
}: IVenuesSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFormSearchObject
  )
  return (
    <Search<IVenuesSearchFilter>
      storageKey="VENUES"
      placeholder={t('Search for venue')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFiler}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
    />
  )
}
