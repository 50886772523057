import GetAppIcon from '@mui/icons-material/GetApp'
import {IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  TourTimeSlotState
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Tooltip} from '../../../common'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {PaymentDateChip} from '../components/PaymentDateChip'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {StateChip} from './StateChip'

interface IToolbarProps {
  title: string
  selectedDate: IDataPickerData | undefined
  setSelectedDate: (date: IDataPickerData | undefined) => void
  selectedState: TourTimeSlotState | undefined
  setSelectedState: (state: TourTimeSlotState | undefined) => void
  setUserId: (id: number | null) => void
  selectedPaymentDate: IDataPickerData | undefined
  setSelectedPaymentDate: (date: IDataPickerData | undefined) => void
  onDownloadButtonClick?: () => void
  refetch: () => void
}

export const Toolbar: React.FC<IToolbarProps> = ({
  title,
  selectedDate,
  setSelectedDate,
  selectedState,
  setSelectedState,
  setUserId,
  selectedPaymentDate,
  setSelectedPaymentDate,
  onDownloadButtonClick,
  refetch
}: IToolbarProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  return (
    <SubHeaderToolbar
      title={title}
      leftActions={[
        <StateChip
          key="state-chip"
          state={selectedState}
          onChange={setSelectedState}
        />,
        P([PermissionCode.ReadUsers]) ? (
          <UserChip key="user-chip" onChange={setUserId} />
        ) : (
          []
        ),
        <PaymentDateChip
          key="payment-date-chip"
          selectedDate={selectedPaymentDate}
          setSelectedDate={setSelectedPaymentDate}
          popperSx={(theme) => ({zIndex: theme.zIndex.drawer - 1})}
        />
      ].filter(Boolean)}
      rightActions={[
        <DatePickerButton
          key="admissions-statistics-date-picker"
          onDateSelect={setSelectedDate}
          selectedValues={selectedDate}
          defaultValues={{
            group: TabGroup.Now,
            value: TabNow.Today
          }}
          tooltip={t('Time slot date')}
        />,
        <ReloadButton
          key="reload-button"
          onReloadButtonClick={() => refetch()}
        />,
        <Tooltip
          key="download"
          title={onDownloadButtonClick ? t('Download CSV') : undefined}
        >
          <IconButton
            disabled={!onDownloadButtonClick}
            onClick={onDownloadButtonClick}
            color="primary"
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      ]}
    />
  )
}
