import React from 'react'
import {Route, RouteComponentProps, Switch} from 'react-router-dom'
import {
  DetailLibraryShowPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {SHOWS_PARAMS as SP} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {ShowSection} from '../../../../constants'

import {LibraryShowDetailProvider} from '../LibraryShowDetailProvider'
import {EditLibraryShowCrew} from './EditLibraryShowCrew'
import {EditLibraryShowData} from './EditLibraryShowData'
import {EditLibraryShowMedia} from './EditLibraryShowMedia'
import {EditLibraryShowTranslations} from './EditLibraryShowTranslations'

interface IEditLibraryShowProps
  extends RouteComponentProps<{
    showId: string
  }> {}

export const EditLibraryShow: React.FC<IEditLibraryShowProps> = ({
  match,
  history
}: IEditLibraryShowProps) => {
  const {P} = useEnsurePermissions()
  const showId = parseInt(match.params.showId, 10)
  return (
    <LibraryShowDetailProvider showId={showId} onClose={() => history.goBack()}>
      {(libraryShow: DetailLibraryShowPropertiesFragment) => (
        <Switch>
          {P([PermissionCode.ReadLibraryShow]) && (
            <Route
              path={routeTo.admin.library.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                ShowSection.Crew
              )}
            >
              <EditLibraryShowCrew libraryShow={libraryShow} />
            </Route>
          )}
          {P([PermissionCode.ReadLibraryShow]) && (
            <Route
              path={routeTo.admin.library.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                ShowSection.Translations
              )}
            >
              <EditLibraryShowTranslations libraryShow={libraryShow} />
            </Route>
          )}
          {P([PermissionCode.ReadLibraryShow]) && (
            <Route
              path={routeTo.admin.library.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                ShowSection.Media
              )}
            >
              <EditLibraryShowMedia libraryShow={libraryShow} />
            </Route>
          )}
          {P([PermissionCode.ReadLibraryShow]) && (
            <Route
              path={[
                routeTo.admin.library.edit(toPlaceholderParam(SP.SHOW_ID)),
                routeTo.admin.library.editWithEditSection(
                  toPlaceholderParam(SP.SHOW_ID),
                  ShowSection.Data
                )
              ]}
            >
              <EditLibraryShowData libraryShow={libraryShow} />
            </Route>
          )}
        </Switch>
      )}
    </LibraryShowDetailProvider>
  )
}
