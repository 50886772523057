import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import {Box, Button, SxProps, Typography} from '@mui/material'
import Decimal from 'decimal.js'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useTranslateEffectiveClientPrice} from '../../../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../../common/ListOfItemsSeparatedByDividers'
import {Blank, BlankContentPosition} from '../../../../../visual/Blank'
import {isTourItemPropertiesFragment} from '../../../types'
import {useCurrentCart} from '../../CurrentCartContext'
import {useRemoveTourItemFromCart} from '../graphql'
import {TourListItem} from './TourListItem'

interface ISubCartProps {
  tourTimeSlotId: number
  sx?: SxProps
  onRemoveButtonClick: () => Promise<void>
  onDiscountsButtonClick: () => void
}

export const SubCart: React.FC<ISubCartProps> = ({
  tourTimeSlotId,
  sx,
  onRemoveButtonClick,
  onDiscountsButtonClick
}: ISubCartProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {currentCart, updateCurrentCart, currentCartId} = useCurrentCart()
  const removeTourItemFromCart = useRemoveTourItemFromCart()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const currentCartTourItems = (currentCart?.items || [])
    .filter(isTourItemPropertiesFragment)
    .filter((item) => item.tourTimeSlotId === tourTimeSlotId)
    .filter((item) => item.reservation === null)
    .reverse()
  const handleRemoveButtonClick = useCallback(
    (itemId: number) => async () => {
      if (currentCartId) {
        try {
          setShowBackdrop(true)
          const {data: removeTourItemData} = await removeTourItemFromCart({
            cartId: currentCartId,
            itemId
          })
          if (removeTourItemData) {
            updateCurrentCart()
          }
        } catch (error) {
          defaultErrorHandler(error, t('Error while removing tour item'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      defaultErrorHandler,
      removeTourItemFromCart,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr 64px',
        width: '100%',
        background: (theme) => theme.palette.background.paper,
        borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
        ...sx
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          p: 1,
          gap: 1
        }}
      >
        {P([PermissionCode.RemoveAllTourItemsFromCartForTimeSlot]) && (
          <ButtonWithConfirmationDialog
            onConfirmButtonClick={onRemoveButtonClick}
            buttonProps={{
              children: t('Remove'),
              startIcon: <RemoveShoppingCartIcon />,
              disabled: !currentCartTourItems.length,
              variant: 'text',
              color: 'primary'
            }}
            dialogProps={{
              title: t('Remove items from cart?'),
              contentText: t(
                'Do you really want to remove all items for this tour time slot from cart?'
              ),
              confirmButtonLabel: t('Remove')
            }}
          />
        )}
        <Button
          variant="text"
          color="primary"
          disabled={!currentCartTourItems.length}
          onClick={onDiscountsButtonClick}
        >
          {t('Discounts')}
        </Button>
      </Box>
      {currentCartTourItems.length > 0 ? (
        <ListOfItemsSeparatedByDividers DividerProps={{sx: {my: 0, mx: 2}}}>
          {currentCartTourItems
            .sort((itemA, itemB) => itemA.id - itemB.id)
            .map((item) => (
              <TourListItem
                key={item.id}
                name={item.admissionType.name}
                price={translateEffectiveClientPrice(item.price)}
                onRemoveButtonClick={handleRemoveButtonClick(item.id)}
                priceBeforeDiscountLabel={
                  item.price !== item.priceBeforeDiscount
                    ? translateEffectiveClientPrice(item.priceBeforeDiscount)
                    : undefined
                }
                appliedDiscounts={
                  item.appliedDiscounts.length > 0
                    ? item.appliedDiscounts
                        .map((appliedDiscount) => appliedDiscount.discount.name)
                        .join(' • ')
                    : undefined
                }
              />
            ))}
        </ListOfItemsSeparatedByDividers>
      ) : (
        <Blank
          title={t('Add first ticket for tour')}
          contentPosition={BlankContentPosition.Center}
        />
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `
      "subTotalLabel count"
      "subTotalLabel price"
    `,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          alignItems: 'center',
          py: 0,
          px: 2
        }}
      >
        <Typography sx={{gridArea: 'subTotalLabel'}} variant="subtitle1">
          {t('Subtotal')}
        </Typography>
        <Typography
          sx={{alignSelf: 'flex-end'}}
          variant="caption"
          color="textSecondary"
          align="right"
        >
          {t('{{count}} ticket', {
            count: currentCartTourItems.length
          })}
        </Typography>
        <Typography
          sx={{alignSelf: 'flex-start'}}
          variant="subtitle1"
          align="right"
        >
          {translateEffectiveClientPrice(
            currentCartTourItems
              .reduce((acc, item) => acc.add(item.price), new Decimal(0))
              .toNumber()
          )}
        </Typography>
      </Box>
    </Box>
  )
}
