import {IIcon} from '@attendio/shared-components'
import {CanvasObjectType} from '../../types'

export enum IconActionType {
  ADD_ICON = 'add icon'
}

export interface IAddIconAction {
  type: typeof IconActionType.ADD_ICON
  payload: {
    type: typeof CanvasObjectType.Icon
    config: IIcon
  }
}

export type IconAction = IAddIconAction
