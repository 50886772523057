import SettingsIcon from '@mui/icons-material/Settings'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {Blank, BlankContentPosition} from '../../../visual/Blank'

export const CustomerDisplayDisabledView: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {P} = useEnsurePermissions()

  return (
    <Blank
      contentPosition={BlankContentPosition.Center}
      IconComp={SettingsIcon}
      title={t('Customer display disabled')}
      description={t('Enable customer display in device settings first.')}
      actions={
        P([PermissionCode.ChangeDeviceSettings]) ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(
                `${routeTo.admin.cashDesk.settings()}#customerDisplay`
              )
            }}
          >
            {t('Device settings')}
          </Button>
        ) : null
      }
    />
  )
}
