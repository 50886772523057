import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  PassCodeChecksQuery,
  PassCodeChecksQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {TRANSLATED_LOCALES_FRAGMENT, USER_FIELDS} from '../graphql'

const PASS_CODE_CHECKS = gql`
  ${PAGINATION_FRAGMENT}
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${USER_FIELDS}
  query PassCodeChecks(
    $paginationInput: PaginationInput!
    $filter: PassCodeChecksFilter
  ) {
    passCodeChecks(paginationInput: $paginationInput, filter: $filter) {
      items {
        id
        itemId
        createdAt
        passCode
        state
        additionalInformation
        direction
        discountAuthorizationMode
        event {
          id
          startsAt
          names {
            ...TranslatedLocales
          }
        }
        tourTimeSlot {
          id
          startsAt
          names {
            ...TranslatedLocales
          }
        }
        createdBy {
          ...UserFields
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const usePassCodeChecks = (variables: PassCodeChecksQueryVariables) =>
  useQuery<PassCodeChecksQuery, PassCodeChecksQueryVariables>(
    PASS_CODE_CHECKS,
    {variables, fetchPolicy: 'network-only'}
  )
