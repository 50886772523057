import {useLazyQuery} from '@apollo/react-hooks'
import {Button} from '@mui/material'
import {ApolloError} from 'apollo-client'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CheckStatnaPokladnicaConfigInputValidQuery,
  CheckStatnaPokladnicaConfigInputValidQueryVariables,
  ErrorMessages,
  PaymentGatewayEnvironment
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {CHECK_STATNA_POKLADNICA_CREDENTIALS} from './graphql'

interface ICheckStatnaPokladnicaCredentialsProps {
  merchantId: string
  privateKey: string
  environment: PaymentGatewayEnvironment
  isButtonDisabled?: boolean
}

export const CheckStatnaPokladnicaCredentials: React.FC<ICheckStatnaPokladnicaCredentialsProps> =
  ({
    environment,
    isButtonDisabled,
    merchantId,
    privateKey
  }: ICheckStatnaPokladnicaCredentialsProps) => {
    const {t} = useTranslation()
    const {defaultErrorHandler} = useMutationAssistanceHooks()
    const {
      state: isDialogOpen,
      setTrue: openDialog,
      setFalse: closeDialog
    } = useBooleanState(false)
    const [checkCredentialsMessage, setCheckCredentialsMessage] =
      useState<string | null>(null)
    const handleOnCheckCredentialsCompleted = useCallback(
      (data: CheckStatnaPokladnicaConfigInputValidQuery) => {
        if (data.isStatnaPokladnicaConfigInputValid) {
          setCheckCredentialsMessage(
            t<string>('Successful. Credentials are ready to use.')
          )
        } else {
          setCheckCredentialsMessage(t<string>('Credentials are not valid.'))
        }
      },
      [t]
    )
    const handleOnCheckCredentialsError = useCallback(
      (error: ApolloError) => {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidStatnaPokladnicaCredentials
          )
        ) {
          setCheckCredentialsMessage(t<string>('Credentials are not valid.'))
        } else {
          closeDialog()
          setCheckCredentialsMessage(null)
          defaultErrorHandler(
            error,
            t('Error while checking Statna pokladnica credentials')
          )
        }
      },
      [closeDialog, defaultErrorHandler, t]
    )
    const [checkStatnaPokladnicaCredentials, {loading}] = useLazyQuery<
      CheckStatnaPokladnicaConfigInputValidQuery,
      CheckStatnaPokladnicaConfigInputValidQueryVariables
    >(CHECK_STATNA_POKLADNICA_CREDENTIALS, {
      fetchPolicy: 'network-only',
      onCompleted: handleOnCheckCredentialsCompleted,
      onError: handleOnCheckCredentialsError
    })
    const handleOnCheckCredentialsButtonClick = useCallback(() => {
      checkStatnaPokladnicaCredentials({
        variables: {
          input: {
            merchantId,
            privateKey,
            environment
          }
        }
      })
      openDialog()
    }, [
      privateKey,
      checkStatnaPokladnicaCredentials,
      environment,
      merchantId,
      openDialog
    ])
    useEffect(() => {
      if (loading) {
        setCheckCredentialsMessage(
          t<string>('Connecting to Slovak state treasury ...')
        )
      }
    }, [loading, t])
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOnCheckCredentialsButtonClick}
          disabled={isButtonDisabled}
        >
          {t('Check credentials')}
        </Button>
        <ConfirmationDialog
          isOpen={isDialogOpen}
          title={t('Checking credentials')}
          contentText={checkCredentialsMessage}
          onConfirm={closeDialog}
          confirmButtonLabel={t('Close')}
          dialogProps={{maxWidth: 'sm', fullWidth: true}}
        />
      </>
    )
  }
