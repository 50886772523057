import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import {Box, Button, SxProps, Typography} from '@mui/material'
import React from 'react'
import {DropzoneOptions, useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'

interface IDropZoneProps {
  onDrop: (files: File[]) => void
  sx?: SxProps
  options?: Omit<DropzoneOptions, 'onDrop'>
}

export const DropZone: React.FC<IDropZoneProps> = ({
  onDrop,
  sx,
  options
}: IDropZoneProps) => {
  const {t} = useTranslation()
  const {getRootProps, getInputProps, open} = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/bmp': []
    },
    onDrop,
    multiple: false,
    ...options
  })
  return (
    <Box
      sx={{
        width: 600,
        height: 400,
        backgroundColor: 'background.paper',
        border: (theme) => `2px dashed ${theme.palette.divider}`,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        ...sx
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          maxWidth: 360
        }}
      >
        <CloudUploadOutlinedIcon sx={{width: 64, height: 64}} />
        <Typography variant="h6">{t('Upload image here')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t('Drop image here or click to button to upload.')}
        </Typography>
        <Button variant="contained" color="primary" onClick={open}>
          {t('Browse images')}
        </Button>
      </Box>
    </Box>
  )
}
