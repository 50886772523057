import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {FieldErrors, FieldValues, FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {FormSwitchWithLabels} from '../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormFieldName} from '../../../form/types'
import {IPaymentMethodForm, PaymentMethodField} from './types'

interface ICommonEcommercePaymentMethodFormPartsProps<
  FormValues extends FieldValues = FieldValues
> {
  children?: React.ReactNode
  formId: string
  className?: string
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
  setValue: FormContextValues<FormValues>['setValue']
  triggerValidation: FormContextValues<FormValues>['triggerValidation']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  control: FormContextValues<FormValues>['control']
  errors: FieldErrors<FormValues>
  defaultValues: Pick<
    IPaymentMethodForm,
    PaymentMethodField.CURRENCY | PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS
  >
}

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    gap: theme.spacing(1)
  },
  typography: {
    paddingBottom: theme.spacing(1.5)
  },
  descriptionFormPart: {
    paddingBottom: theme.spacing(1)
  }
}))

export const CommonEcommercePaymentMethodFormParts = <
  FormValues extends FieldValues = FieldValues
>({
  formId,
  children,
  className,
  onSubmit,
  register,
  errors,
  setValue,
  triggerValidation,
  watch,
  control,
  defaultValues
}: ICommonEcommercePaymentMethodFormPartsProps<FormValues>) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  return (
    <form
      id={formId}
      onSubmit={onSubmit}
      className={cn(classes.form, className)}
      autoComplete="off"
    >
      <Typography variant="subtitle1" className={classes.typography}>
        {t('Payment method details')}
      </Typography>
      <input
        name={PaymentMethodField.CURRENCY}
        type="hidden"
        ref={register()}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<FormValues>
            register={register}
            name={PaymentMethodField.NAME as FormFieldName<FormValues>}
            key={PaymentMethodField.NAME}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            label={t('Name')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
      <div className={classes.descriptionFormPart}>
        <div className={classes.typography}>
          <Typography variant="subtitle2">
            {t('Payment method description')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('Description is visible for customers on ecommerce.')}
          </Typography>
        </div>
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<FormValues>
              register={register}
              name={
                `${PaymentMethodField.DESCRIPTION}[sk]` as FormFieldName<FormValues>
              }
              key="sk"
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Description in slovak')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<FormValues>
              register={register}
              name={
                `${PaymentMethodField.DESCRIPTION}[cs]` as FormFieldName<FormValues>
              }
              key="cs"
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Description in czech')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<FormValues>
              register={register}
              name={
                `${PaymentMethodField.DESCRIPTION}[en]` as FormFieldName<FormValues>
              }
              key="en"
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Description in english')}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<FormValues>
              register={register}
              name={
                `${PaymentMethodField.DESCRIPTION}[hu]` as FormFieldName<FormValues>
              }
              key="hu"
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              label={t('Description in hungarian')}
              fullWidth
            />
          ]}
        />
      </div>
      <InputRow
        spacing={0}
        nodes={[
          <FormSwitchWithLabels<FormValues>
            key={PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS}
            primaryLabel={t('Available for refunds?')}
            secondaryLabel={t(
              'Allows payment method to be used also for returning funds to the customer.'
            )}
            name={
              PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS as FormFieldName<FormValues>
            }
            control={control}
            watch={watch}
            enabledText={t('Yes')}
            disabledText={t('No')}
            defaultValue={
              defaultValues[PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]
            }
          />
        ]}
      />
      {children}
    </form>
  )
}
