import {
  CashDrawerController,
  CashDrawerOpenLocation,
  CheckoutPaymentMethodsViewMode,
  RegistrationOfSales,
  ShopViewMode,
  TourTimeSlotViewMode,
  ZonePlanView
} from '../../../../types'
import {getAssignedTemplatesByType} from './selectors'
import {
  DeviceSettingsAction,
  DeviceSettingsActionTypes,
  DeviceSettingsState
} from './types'

export const defaultDeviceSettingsState: DeviceSettingsState = {
  assignDialogOpenedForType: null,
  availableTemplates: [],
  assignedTemplatesByType: {},
  zonePlanView: ZonePlanView.ListView,
  shopViewMode: ShopViewMode.ListView,
  tourTimeSlotViewMode: TourTimeSlotViewMode.GridView,
  checkoutPaymentMethodsViewMode:
    CheckoutPaymentMethodsViewMode.ExpandedFirstGroup,
  deviceServices: [],
  registrationOfSales: RegistrationOfSales.None,
  divisionId: null,
  enabledDivisions: [],
  cashDrawer: {
    controller: CashDrawerController.None,
    openLocation: CashDrawerOpenLocation.DontOpen,
    ipAddress: null
  },
  posTerminal: {
    enabled: false,
    port: null,
    paymentMethodId: null
  },
  warehouseId: null,
  customerDisplay: {
    enabled: false,
    windowManagementEnabled: false,
    screen: null
  },
  directTicketPrint: {
    enabled: false,
    printer: null
  }
}

export const deviceSettingsStateReducer = (
  state: DeviceSettingsState = defaultDeviceSettingsState,
  action?: DeviceSettingsAction
) => {
  switch (action?.type) {
    case DeviceSettingsActionTypes.OpenAssignDialog:
      return {
        ...state,
        assignDialogOpenedForType: action.payload.templateType,
        availableTemplates: action.payload.availableTemplates
      }
    case DeviceSettingsActionTypes.CloseAssignDialog:
      return {
        ...state,
        assignDialogOpenedForType: null,
        availableTemplates: []
      }
    case DeviceSettingsActionTypes.AssignTemplate:
      if (!state.assignDialogOpenedForType) {
        return state
      }
      return {
        ...state,
        availableTemplates: state.availableTemplates.filter(
          (t) => t.id !== action.payload.id
        ),
        assignedTemplatesByType: {
          ...state.assignedTemplatesByType,
          [state.assignDialogOpenedForType]: [
            ...getAssignedTemplatesByType(
              state,
              state.assignDialogOpenedForType
            ),
            action.payload
          ]
        }
      }
    case DeviceSettingsActionTypes.RemoveTemplate:
      return {
        ...state,
        assignedTemplatesByType: {
          ...state.assignedTemplatesByType,
          [action.payload.type]: getAssignedTemplatesByType(
            state,
            action.payload.type
          ).filter((t) => t.id !== action.payload.id)
        }
      }
    case DeviceSettingsActionTypes.ReorderTemplates:
      return {
        ...state,
        assignedTemplatesByType: {
          ...state.assignedTemplatesByType,
          [action.payload.type]: action.payload.templates
        }
      }
    case DeviceSettingsActionTypes.ChangeZonePlanView:
      return {
        ...state,
        zonePlanView: action.payload
      }
    case DeviceSettingsActionTypes.ChangeShopViewMode:
      return {
        ...state,
        shopViewMode: action.payload
      }
    case DeviceSettingsActionTypes.ChangeTourTimeSlotViewMode:
      return {
        ...state,
        tourTimeSlotViewMode: action.payload
      }
    case DeviceSettingsActionTypes.ChangeCheckoutPaymentMethodsViewMode:
      return {
        ...state,
        checkoutPaymentMethodsViewMode: action.payload
      }
    case DeviceSettingsActionTypes.ChangeRegistrationOfSales:
      return {
        ...state,
        registrationOfSales: action.payload
      }
    case DeviceSettingsActionTypes.ChangeDivision:
      return {
        ...state,
        divisionId: action.payload
      }
    case DeviceSettingsActionTypes.ChangeDeviceServices:
      return {
        ...state,
        deviceServices: action.payload
      }
    case DeviceSettingsActionTypes.AddEnabledDivisions:
      return {
        ...state,
        enabledDivisions: [...state.enabledDivisions, ...action.payload]
      }
    case DeviceSettingsActionTypes.RemoveEnabledDivision:
      return {
        ...state,
        enabledDivisions: [
          ...state.enabledDivisions.filter((d) => d.id !== action.payload.id)
        ]
      }
    case DeviceSettingsActionTypes.ResetEnabledDivisions:
      return {
        ...state,
        enabledDivisions: action.payload
      }
    case DeviceSettingsActionTypes.ChangeCashDrawerSettings:
      return {
        ...state,
        cashDrawer: action.payload
      }
    case DeviceSettingsActionTypes.ChangePosTerminalSettings:
      return {
        ...state,
        posTerminal: action.payload
      }
    case DeviceSettingsActionTypes.ChangeWarehouseId:
      return {
        ...state,
        warehouseId: action.payload
      }
    case DeviceSettingsActionTypes.ChangeCustomerDisplaySettings:
      return {
        ...state,
        customerDisplay: action.payload
      }
    case DeviceSettingsActionTypes.ChangeDirectTicketPrintSettings:
      return {
        ...state,
        directTicketPrint: action.payload
      }
    default:
      return state
  }
}
