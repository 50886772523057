import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Chip} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'
import {TimeSlotStateChip} from './types'

const useTranslateState = () => {
  const {t} = useTranslation()
  const translated: Record<TimeSlotStateChip, TimeSlotStateChip> = {
    [TimeSlotStateChip.Published]: t('Published time slots'),
    [TimeSlotStateChip.Canceled]: t('Canceled time slots'),
    [TimeSlotStateChip.All]: t('All time slots')
  }
  return (state: TimeSlotStateChip) => translated[state]
}

interface IStateChipProps {
  state: TimeSlotStateChip
  onChange: (state: TimeSlotStateChip) => void
}

export const StateChip: React.FC<IStateChipProps> = ({
  state,
  onChange
}: IStateChipProps) => {
  const translateState = useTranslateState()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleMenuItemClick = useCallback(
    (newState: TimeSlotStateChip) => () => {
      onChange(newState)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        label={translateState(state)}
        deleteIcon={<ArrowDropDownIcon />}
        onClick={openMenu}
        onDelete={openMenu}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {Object.values(TimeSlotStateChip).map((stateChip) => (
          <MenuItem
            key={stateChip}
            label={translateState(stateChip)}
            icon={state === stateChip ? <CheckIcon /> : undefined}
            isSelected={state === stateChip}
            onClick={handleMenuItemClick(stateChip)}
            sx={{flexFlow: 'row-reverse'}}
            iconSx={{justifyContent: 'center'}}
          />
        ))}
      </Menu>
    </>
  )
}
