import React from 'react'
import {useTranslation} from 'react-i18next'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {SecondaryHeader} from '../../Header'

interface IApplyDiscountsPageProps {
  children: React.ReactNode
  onBackArrowClick: () => void
}

export const ApplyDiscountsPageTemplate: React.FC<IApplyDiscountsPageProps> = ({
  children,
  onBackArrowClick
}: IApplyDiscountsPageProps) => {
  const {t} = useTranslation()
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={t('Discounts')}
          hasArrowBackIcon
          onLeftActionClick={onBackArrowClick}
          isDatetimeEnabled
        />
      }
    >
      {children}
    </PageWithHeaderTemplate>
  )
}
