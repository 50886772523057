import {useQuery} from '@apollo/react-hooks'
import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightProductGroupsQuery,
  LightweightProductGroupsQueryVariables,
  PermissionCode,
  WarehouseProductsFilterInput,
  WebsitesFilter
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {
  LIGHTWEIGHT_PRODUCT_GROUPS,
  useGetLightweightProductTypes
} from '../graphql'

export const DEFAULT_WAREHOUSE_PRODUCTS_FILTER_INPUT: WarehouseProductsFilterInput =
  {
    hasText: undefined
  }

type ExtendedWarehouseProductsFilterInput = WarehouseProductsFilterInput & {
  _createdAtDaterangeId?: DATERANGE_IDS
}

const stripCreatedAtDateFromFilter = (
  filter: ExtendedWarehouseProductsFilterInput
): ExtendedWarehouseProductsFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripHelperKeysFromFilter = (
  filter: ExtendedWarehouseProductsFilterInput
): WebsitesFilter => omit(filter, ['_createdAtDaterangeId'])

const createdAtDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_30_DAYS,
  DATERANGE_IDS.LAST_MONTH
]

const mapHasTextToFilter = (
  filter: WarehouseProductsFilterInput,
  hasText?: string
): WarehouseProductsFilterInput => ({
  ...filter,
  hasText: hasText || undefined
})

const mapNegativeStockToFilter = (
  filter: WarehouseProductsFilterInput,
  isNegativeStockEnabled?: number
): WarehouseProductsFilterInput => ({
  ...filter,
  isNegativeStockEnabled:
    typeof isNegativeStockEnabled === 'number'
      ? isNegativeStockEnabled === 1
      : undefined
})

const mapProductTypeIdToFilter = (
  filter: WarehouseProductsFilterInput,
  productTypeId?: number
): WarehouseProductsFilterInput => ({
  ...filter,
  productTypeIds: productTypeId ? [productTypeId] : undefined
})

const mapProductGroupIdToFilter = (
  filter: WarehouseProductsFilterInput,
  productGroupId?: number
): WarehouseProductsFilterInput => ({
  ...filter,
  productGroupIds: productGroupId ? [productGroupId] : undefined
})

const mapInternalCodeToFilter = (
  filter: WarehouseProductsFilterInput,
  productInternalCode?: string
): WarehouseProductsFilterInput => ({
  ...filter,
  productInternalCode: productInternalCode || undefined
})

const mapBarcodeToFilter = (
  filter: WarehouseProductsFilterInput,
  productBarcode?: string
): WarehouseProductsFilterInput => ({
  ...filter,
  productBarcode: productBarcode || undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getHasTextFromSearchObject = (filter: WarehouseProductsFilterInput) =>
    filter.hasText || undefined
  const getNegativeStockFromSearchObject = (
    filter: WarehouseProductsFilterInput
  ) =>
    typeof filter.isNegativeStockEnabled === 'boolean'
      ? filter.isNegativeStockEnabled
        ? t('Negative stock: Enabled')
        : t('Negative stock: Disabled')
      : undefined
  const getProductTypeFromSearchObject = (
    filter: WarehouseProductsFilterInput,
    productTypes: {id: number; name: string}[]
  ) => {
    if (filter.productTypeIds && filter.productTypeIds.length) {
      const productTypeId = filter.productTypeIds[0]
      const productType = productTypes.find(({id}) => id === productTypeId)
      return productType
        ? t('Product type: {{productType}}', {productType: productType.name})
        : t('Product type ID: {{productTypeId}}', {
            productTypeId
          })
    }
    return undefined
  }
  const getProductGroupFromSearchObject = (
    filter: WarehouseProductsFilterInput,
    productGroups: {id: number; name: string}[]
  ) => {
    if (filter.productGroupIds && filter.productGroupIds.length) {
      const productGroupId = filter.productGroupIds[0]
      const productGroup = productGroups.find(({id}) => id === productGroupId)
      return productGroup
        ? t('Product group: {{productGroup}}', {
            productGroup: productGroup.name
          })
        : t('Product group ID: {{productGroupId}}', {
            productGroupId
          })
    }
    return undefined
  }
  const getProductInternalCodeFromSearchObject = (
    filter: WarehouseProductsFilterInput
  ) =>
    filter.productInternalCode
      ? t('Internal code: {{code}}', {code: filter.productInternalCode})
      : undefined
  return {
    getHasTextFromSearchObject,
    getNegativeStockFromSearchObject,
    getProductTypeFromSearchObject,
    getProductGroupFromSearchObject,
    getProductInternalCodeFromSearchObject
  }
}

interface IWarehouseStocksSearchProps {
  onFilterChange: (filter: WarehouseProductsFilterInput) => void
}

export const WarehouseStocksSearch: React.FC<IWarehouseStocksSearchProps> = ({
  onFilterChange
}: IWarehouseStocksSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data: productTypesData} = useGetLightweightProductTypes()
  const {data: productGroupsData} = useQuery<
    LightweightProductGroupsQuery,
    LightweightProductGroupsQueryVariables
  >(LIGHTWEIGHT_PRODUCT_GROUPS, {
    fetchPolicy: 'network-only',
    skip: !P([PermissionCode.ReadProductGroups])
  })
  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedWarehouseProductsFilterInput>({
    usedDateranges: createdAtDateRanges,
    dateRangeInputPrefix: t('Created at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const {
    getHasTextFromSearchObject,
    getNegativeStockFromSearchObject,
    getProductTypeFromSearchObject,
    getProductGroupFromSearchObject,
    getProductInternalCodeFromSearchObject
  } = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject,
    getNegativeStockFromSearchObject,
    (filter) =>
      getProductTypeFromSearchObject(
        filter,
        productTypesData?.productTypes || []
      ),
    (filter) =>
      getProductGroupFromSearchObject(
        filter,
        productGroupsData?.productGroups || []
      ),
    getProductInternalCodeFromSearchObject,
    getStringifiedCreatedAtDateDateRangeFromSearchObject
  )
  const productTypeSelectOptions: ISelectOption<number>[] = (
    productTypesData?.productTypes || []
  ).map(({id, name}) => ({
    id,
    label: name
  }))
  const productGroupSelectOptions: ISelectOption<number>[] = (
    productGroupsData?.productGroups || []
  ).map(({id, name}) => ({
    id,
    label: name
  }))
  return (
    <Search<WarehouseProductsFilterInput, ExtendedWarehouseProductsFilterInput>
      storageKey="WAREHOUSE_STOCKS"
      placeholder={t('Search for warehouse product')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_WAREHOUSE_PRODUCTS_FILTER_INPUT}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<WarehouseProductsFilterInput, number>
            value={
              typeof advancedSearchObject.isNegativeStockEnabled === 'boolean'
                ? advancedSearchObject.isNegativeStockEnabled
                  ? 1
                  : 0
                : undefined
            }
            label={t('Negative stock')}
            options={[
              {id: 1, label: t('Enabled')},
              {id: 0, label: t('Disabled')}
            ]}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapNegativeStockToFilter}
          />
          <AdvancedSearchTextRow
            label={t('Contains')}
            placeholder={t('Enter words or codes found in product')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          {P([PermissionCode.ReadProductTypes]) && (
            <AdvancedSearchSelectRow<WarehouseProductsFilterInput, number>
              label={t('Product type')}
              value={
                advancedSearchObject.productTypeIds
                  ? advancedSearchObject.productTypeIds[0]
                  : undefined
              }
              options={productTypeSelectOptions}
              mapSelectValueToSearchObject={mapProductTypeIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadProductGroups]) && (
            <AdvancedSearchSelectRow<WarehouseProductsFilterInput, number>
              label={t('Product group')}
              value={
                advancedSearchObject.productGroupIds
                  ? advancedSearchObject.productGroupIds[0]
                  : undefined
              }
              options={productGroupSelectOptions}
              mapSelectValueToSearchObject={mapProductGroupIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          <AdvancedSearchTextRow
            label={t('Internal code')}
            placeholder={t("Enter product's internal code")}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapInternalCodeToFilter}
            value={advancedSearchObject.productInternalCode || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Barcode')}
            placeholder={t("Enter product's barcode")}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapBarcodeToFilter}
            value={advancedSearchObject.productBarcode || undefined}
          />
          <AdvancedSearchDaterangeRow<WebsitesFilter>
            label={t('Created at')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for warehouse product created at'
            )}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
