import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DivisionOnlineReservationEnd} from '../../../../../__generated__/schema'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {DivisionFormControlLabel} from './DivisionFormControlLabel'
import {
  DivisionFormSelect,
  FormControlDurationLabel,
  FormControlTextLabel,
  FormControlTimeLabel
} from './DivisionFormSelect'
import {
  DivisionFormField,
  IDivisionFormData,
  IDivisionSectionProps
} from './types'

export const OnlineReservationEnd: React.FC<IDivisionSectionProps> = ({
  defaultValues
}: IDivisionSectionProps) => {
  const {t} = useTranslation()
  const reservationsEnds = Object.values(DivisionOnlineReservationEnd)
  const methods = useFormContext<IDivisionFormData>()
  const {
    watch,
    triggerValidation,
    errors,
    setValue,
    register,
    control,
    unregister,
    clearError,
    setError
  } = methods
  const reservationEndWebType = watch(
    DivisionFormField.RESERVATION_END_WEB_TYPE
  )
  useEffect(() => {
    if (reservationEndWebType) {
      triggerValidation(
        Object.values(DivisionOnlineReservationEnd)
          .filter((reservation) => reservation !== reservationEndWebType)
          .map(
            (reservation) =>
              `${DivisionFormField.RESERVATION_END_WEB_VALUE}.${reservation}`
          )
      )
    }
  }, [reservationEndWebType, triggerValidation])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <DivisionFormSelect<IDivisionFormData>
      options={reservationsEnds}
      name={DivisionFormField.RESERVATION_END_WEB_TYPE}
      subfieldsName={DivisionFormField.RESERVATION_END_WEB_VALUE}
      defaultValue={
        defaultValues[DivisionFormField.RESERVATION_END_WEB_TYPE] ||
        DivisionOnlineReservationEnd.EventStart
      }
      validationOptions={{
        required: true
      }}
      required
      label={t('Online reservation expiration')}
      errors={errors}
      setValue={setValue}
      register={register}
    >
      <DivisionFormControlLabel
        value={DivisionOnlineReservationEnd.EventStart}
        label={t('Same as event start time')}
      />
      <DivisionFormControlLabel
        value={DivisionOnlineReservationEnd.AfterStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.RESERVATION_END_WEB_VALUE}.${DivisionOnlineReservationEnd.AfterStart}`}
            validationOptions={{
              required:
                reservationEndWebType ===
                DivisionOnlineReservationEnd.AfterStart,
              validate: isNonNegativeInteger
            }}
            required={
              reservationEndWebType === DivisionOnlineReservationEnd.AfterStart
            }
            inputMode="numeric"
            note={t('minutes after event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionOnlineReservationEnd.BeforeStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.RESERVATION_END_WEB_VALUE}.${DivisionOnlineReservationEnd.BeforeStart}`}
            validationOptions={{
              required:
                reservationEndWebType ===
                DivisionOnlineReservationEnd.BeforeStart,
              validate: isNonNegativeInteger
            }}
            required={
              reservationEndWebType === DivisionOnlineReservationEnd.BeforeStart
            }
            inputMode="numeric"
            note={t('minutes before event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionOnlineReservationEnd.DayBeforeStart}
        label={
          <FormControlTimeLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.RESERVATION_END_WEB_VALUE}.${DivisionOnlineReservationEnd.DayBeforeStart}`}
            validationOptions={{
              required:
                reservationEndWebType ===
                DivisionOnlineReservationEnd.DayBeforeStart
            }}
            note={t('the day before the event start')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            unregister={unregister}
            clearError={clearError}
            setError={setError}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionOnlineReservationEnd.Duration}
        label={
          <FormControlDurationLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.RESERVATION_END_WEB_VALUE}.${DivisionOnlineReservationEnd.Duration}`}
            validationOptions={{
              required:
                reservationEndWebType === DivisionOnlineReservationEnd.Duration
            }}
            required={
              reservationEndWebType === DivisionOnlineReservationEnd.Duration
            }
            note={t('Duration')}
            watch={watch}
            errors={errors}
            setValue={setValue}
            register={register}
          />
        }
      />
    </DivisionFormSelect>
  )
}
