import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {isSomeObjectSelectedSelector} from '../redux/objects/selectors'
import {useSelectionActions} from '../redux/selection/actions'
import {SelectionMode} from '../types'
import {
  CrossingSelectionIcon,
  FenceSelectionIcon,
  SelectAllIcon,
  SelectOffIcon
} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'
import {PanelSplitButton} from './PanelSplitButton'

const SelectionSplitButton: React.FC = () => {
  const {setSelectMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode} = useSelector(editorModeSelector)

  const onCrossingSelectionClick = useCallback(() => {
    setSelectMode({mode: SelectionMode.CROSSING})
  }, [setSelectMode])

  const onFenceSelectionClick = useCallback(() => {
    setSelectMode({mode: SelectionMode.FENCE})
  }, [setSelectMode])

  const options = useMemo(
    () => [
      {
        icon: <CrossingSelectionIcon />,
        onClick: onCrossingSelectionClick,
        label: t('Crossing selection')
      },
      {
        icon: <FenceSelectionIcon />,
        onClick: onFenceSelectionClick,
        label: t('Fence selection')
      }
    ],
    [onCrossingSelectionClick, onFenceSelectionClick, t]
  )

  return (
    <PanelSplitButton
      options={options}
      selected={selectedMode === EditorMode.SELECT}
    />
  )
}

interface ISelectionPanelProps {
  disableSelectAll?: boolean
}

export const SelectionPanel: React.FC<ISelectionPanelProps> = ({
  disableSelectAll = false
}: ISelectionPanelProps) => {
  const {t} = useTranslation()
  const {setSelectMode} = useEditorModeActions()
  const {selectAll, unselectAll} = useSelectionActions()
  const isSomeObjectSelected = useSelector(isSomeObjectSelectedSelector)

  const onSelectAll = useCallback(() => {
    selectAll()
    setSelectMode()
  }, [selectAll, setSelectMode])

  return (
    <Panel>
      {!disableSelectAll && (
        <PanelButton
          cypressId="select-all-button"
          tooltip={t('Select all')}
          onClick={onSelectAll}
        >
          <SelectAllIcon />
        </PanelButton>
      )}
      <PanelButton
        tooltip={t('Deselect all')}
        disabled={!isSomeObjectSelected}
        onClick={unselectAll}
      >
        <SelectOffIcon />
      </PanelButton>
      <SelectionSplitButton />
    </Panel>
  )
}
