export enum CostCenterState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum CostCenterFormField {
  Name = 'name',
  Label = 'label',
  IsActive = 'isActive'
}

export interface ICostCenterForm {
  [CostCenterFormField.Name]: string
  [CostCenterFormField.Label]?: string
  [CostCenterFormField.IsActive]: CostCenterState
}
