import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {IconButton, Tooltip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClaimState,
  PermissionCode,
  ProductItemPropertiesFragment,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useGetEventSeatLocation} from '../../../../../hooks/getEventSeatLocation'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useTranslateUnit} from '../../../../../hooks/translateUnit'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  isProductItemPropertiesFragment,
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(0, 1),
    gridTemplateAreas: `
      "removeIcon itemId       priceBox"
      "removeIcon description  priceBox"
      "removeIcon name         priceBox"
    `,
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    padding: theme.spacing(1, 2, 1, 1)
  },
  description: {
    gridArea: 'description'
  },
  removeIcon: {
    gridArea: 'removeIcon'
  },
  priceBox: {
    gridArea: 'priceBox',
    textAlign: 'right'
  },
  priceBeforeDiscount: {
    textDecorationLine: 'line-through'
  },
  itemId: {
    gridArea: 'itemId'
  },
  name: {
    gridArea: 'name'
  }
}))

interface IItemProps {
  item:
    | TicketItemPropertiesFragment
    | ProductItemPropertiesFragment
    | TourItemPropertiesFragment
  claimState: ClaimState
  claimId: number
  onRemoveIconClick?: (claimId: number, itemId: number) => Promise<void>
}

export const Item: React.FC<IItemProps> = ({
  item,
  claimState,
  claimId,
  onRemoveIconClick
}: IItemProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const translateUnit = useTranslateUnit()

  const getRemoveIconClickHandler = useCallback(
    (claimId, itemId: number) => async () => {
      if (onRemoveIconClick) {
        await onRemoveIconClick(claimId, itemId)
      }
    },
    [onRemoveIconClick]
  )
  const getEventSeatLocation = useGetEventSeatLocation()
  return (
    <div className={classes.root}>
      {claimState === ClaimState.Draft &&
        P([PermissionCode.RemoveItemsFromClaim]) && (
          <div className={classes.removeIcon}>
            <Tooltip title={t<string>('Remove item')}>
              <IconButton
                color="primary"
                onClick={getRemoveIconClickHandler(claimId, item.id)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      <Typography variant="subtitle2" className={classes.itemId}>
        {isTicketItemPropertiesFragment(item) ||
        isTourItemPropertiesFragment(item)
          ? item.passCode
          : item.name}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.description}
      >
        {isTicketItemPropertiesFragment(item)
          ? getEventSeatLocation(item.eventSeat)
          : isProductItemPropertiesFragment(item)
          ? [
              `${item.quantity} ${translateUnit(item.unit)}`,
              translateEffectiveClientPrice(item.unitPrice)
            ].join(' • ')
          : t('Type: {{type}}', {type: item.name})}
      </Typography>
      <div className={classes.priceBox}>
        <Typography variant="body2">
          {translateEffectiveClientPrice(item.price)}
        </Typography>
        {(isTicketItemPropertiesFragment(item) ||
          isTourItemPropertiesFragment(item)) &&
          item.priceBeforeDiscount !== item.price && (
            <Typography
              variant="caption"
              className={classes.priceBeforeDiscount}
              color="textSecondary"
            >
              {translateEffectiveClientPrice(item.priceBeforeDiscount)}
            </Typography>
          )}
      </div>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.name}
      >
        {isTicketItemPropertiesFragment(item) &&
          [
            item.eventPricingToTicketType.name,
            ...item.appliedDiscounts.map(
              (appliedDiscount) => appliedDiscount.discount.name
            )
          ].join(' • ')}
        {isTourItemPropertiesFragment(item) &&
          [
            t('{{count}} attendees', {count: item.attendeesCount}),
            item.appliedDiscounts[0]?.discount.name
          ]
            .filter(Boolean)
            .join(' • ')}
      </Typography>
    </div>
  )
}
