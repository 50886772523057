import UncheckedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckedIcon from '@mui/icons-material/CheckCircleRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {HeaderActionType, IconPosition} from '../../../../common/header/types'
import {MediaPreviewHeader} from '../../Header'
import {GalleryImage2} from '../utils'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: '100vw'
  },
  button: {
    color: 'white'
  },
  carouselDeleteItemButton: {
    color: 'white'
  },
  formControlLabel: {
    color: 'white',
    '& .MuiCheckbox-root, .Mui-checked': {
      color: 'white'
    }
  }
}))

interface ICenterTopControlsProps {
  images: GalleryImage2[]
  startIndex: number
  onPrivateButtonClick?: (id: number) => void
  onDeleteButtonClick?: (id: number) => void
  onBackButtonClick: () => void
  currentImage: number
}

export const CenterTopControls: React.FC<ICenterTopControlsProps> = ({
  images,
  startIndex,
  currentImage,
  onPrivateButtonClick,
  onBackButtonClick,
  onDeleteButtonClick
}: ICenterTopControlsProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const isPrivate = images[startIndex].isPrivate

  return (
    <div className={classes.root}>
      <MediaPreviewHeader
        title={t('Preview')}
        hasArrowBackIcon
        onLeftActionClick={onBackButtonClick}
        rightActions={
          onPrivateButtonClick && onDeleteButtonClick
            ? [
                {
                  type: HeaderActionType.Button,
                  label: isPrivate ? t('Private') : t('Make private'),
                  onClick: () => onPrivateButtonClick(currentImage),
                  Icon: isPrivate ? CheckedIcon : UncheckedIcon,
                  iconPosition: IconPosition.End
                },
                {
                  type: HeaderActionType.Button,
                  label: t('Delete'),
                  onClick: () => onDeleteButtonClick(currentImage),
                  Icon: DeleteIcon
                }
              ]
            : onPrivateButtonClick
            ? [
                {
                  type: HeaderActionType.Button,
                  label: isPrivate ? t('Private') : t('Make private'),
                  onClick: () => onPrivateButtonClick(currentImage),
                  Icon: isPrivate ? CheckedIcon : UncheckedIcon,
                  iconPosition: IconPosition.End
                }
              ]
            : onDeleteButtonClick
            ? [
                {
                  type: HeaderActionType.Button,
                  label: t('Delete'),
                  onClick: () => onDeleteButtonClick(currentImage),
                  Icon: DeleteIcon
                }
              ]
            : []
        }
      />
    </div>
  )
}
