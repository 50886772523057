import {useTranslation} from 'react-i18next'

export enum FormType {
  Create = 'create',
  Edit = 'edit'
}

export const useRoleFormAnchors = (formType: FormType) => {
  const {t} = useTranslation()
  return {
    ...(formType === FormType.Create
      ? {
          firstItem: {
            id: 'settings',
            label: t('Settings')
          }
        }
      : {
          firstItem: {
            id: 'overview',
            label: t('Overview')
          }
        }),
    english: {
      id: 'english',
      label: t('English')
    },
    slovak: {
      id: 'slovak',
      label: t('Slovak')
    },
    czech: {
      id: 'czech',
      label: t('Czech')
    },
    hungarian: {
      id: 'hungarian',
      label: t('Hungarian')
    },
    permissions: {
      id: 'permissions',
      label: t('Permissions')
    }
  }
}
