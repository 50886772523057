import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import {Box, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClaimPropertiesFragment,
  ItemType
} from '../../../../__generated__/schema'
import {useGetDistributionLabelAbbreviation} from '../../../../hooks/getDistributionLabel'
import {useTranslateAgeClassificationAbbreviation} from '../../../../hooks/translateAgeClassification'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {useTranslateShowVersionAbbreviation} from '../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../theme'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {safeSum} from '../../../../utils/money'
import {TotalPrice} from '../components/TotalPrice'
import {isTourItemPropertiesFragment} from '../types'
import {ClaimSummaryTicketItem} from './ClaimSummaryTicketItem'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.common.white,
    overflow: 'hidden'
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2)
  },
  footer: {
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    borderTop: `1px solid ${theme.palette.divider}`
  }
}))

interface IClaimSummaryProps {
  claim: ClaimPropertiesFragment
}

export const ClaimSummary: React.FC<IClaimSummaryProps> = ({
  claim
}: IClaimSummaryProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDate, formatTime, formatDateNumericShort} =
    useDateTimeFormatters()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const getDistributionLabelAbbreviation = useGetDistributionLabelAbbreviation()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const {sale, event, lead, tourTimeSlot} = claim
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle1">{t('Claims')}</Typography>
      </div>
      <div>
        <ClaimSummaryTicketItem
          title={[t('#{{claimId}}', {claimId: claim.id}), lead?.data.name]
            .filter(Boolean)
            .join(' • ')}
          description={
            sale.itemType === ItemType.Ticket && event ? (
              [
                [
                  getUserLocaleTranslation(event.names),
                  formatDate(new Date(event.startsAt)),
                  formatTime(new Date(event.startsAt))
                ].join(' • '),
                getDistributionLabelAbbreviation({
                  formatCode: event.formatCode,
                  ageClassificationCode: event.ageClassificationCode,
                  soundMixCode: event.soundMixCode,
                  versionCode: event.versionCode
                })
              ]
                .filter(Boolean)
                .join(' | ')
            ) : sale.itemType === ItemType.Tour ? (
              <Box
                sx={{
                  display: 'flex',
                  color: 'text.secondary',
                  alignItems: 'flex-start',
                  gap: 0.5
                }}
              >
                <ConfirmationNumberOutlinedIcon fontSize="small" />
                <Typography variant="caption">
                  {safeSum(
                    (claim.items || [])
                      .filter(isTourItemPropertiesFragment)
                      .map(({attendeesCount}) => attendeesCount)
                  )}
                </Typography>
                {' • '}
                {tourTimeSlot &&
                  [
                    tourTimeSlot.tour.name,
                    formatDateNumericShort(new Date(tourTimeSlot.startsAt)),
                    formatTime(new Date(tourTimeSlot.startsAt))
                  ].join(' • ')}
                {(tourTimeSlot?.versionCode ||
                  tourTimeSlot?.ageClassificationCode) &&
                  ` | ${[
                    tourTimeSlot?.versionCode &&
                      translateShowVersionAbbreviation(
                        tourTimeSlot.versionCode
                      ),
                    tourTimeSlot?.ageClassificationCode &&
                      translateAgeClassificationAbbreviation(
                        tourTimeSlot.ageClassificationCode
                      )
                  ]
                    .filter(Boolean)
                    .join(' • ')}`}
              </Box>
            ) : (
              t('productCount', {count: sale.items?.length || 0})
            )
          }
          count={sale.items?.length || 0}
          price={translateEffectiveClientPrice(claim.price)}
        />
      </div>
      <TotalPrice total={claim.price} />
    </div>
  )
}
