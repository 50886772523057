import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import _ from 'lodash'
import queryString from 'query-string'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {Theme} from '../../../theme'
import {useDefaultErrorHandler} from '../../../utils/errors'

import {routeTo} from '../../../utils/routes'
import {useNotifications} from '../../context/notifications'
import {
  NewPasswordForm,
  NewPasswordFormData,
  RESET_PASSWORD_FORM_ID
} from '../admin/users/resetPassword/common'

import {useRecoverPassword, useVerifyToken} from './graphql'
import {InvalidToken} from './InvalidToken'

const useStyles = makeStyles<Theme>((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2)
  }
}))

const ConfirmButton = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      form={RESET_PASSWORD_FORM_ID}
      className={classes.submitButton}
    >
      {t('Reset password')}
    </Button>
  )
}

export const ResetForgottenPasswordScreen: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const [invalidToken, setInvalidToken] = useState(false)
  const recoverPassword = useRecoverPassword()
  const verifyToken = useVerifyToken()

  const token = queryString.parse(location.search).token as string

  useEffect(() => {
    // Note: you can not use async directly in useEffect
    const _verifyToken = async () => {
      if (token) {
        try {
          const res = await verifyToken(token)
          setInvalidToken(_.get(res, ['data', 'verifyEmailToken']) === null)
        } catch (err) {
          setInvalidToken(true)
        }
      }
    }

    _verifyToken()
    // Note: we want this to run only once
    }, []) // eslint-disable-line

  const onSubmit = useCallback(
    async (data: NewPasswordFormData) => {
      if (token) {
        try {
          await recoverPassword(token, data.password)
          history.replace(routeTo.admin.dashboard())
          addInfoNotification(t('Password changed'))
        } catch (err) {
          defaultErrorHandler(err, t('Error while recovering password'))
        }
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      history,
      recoverPassword,
      t,
      token
    ]
  )

  if (!token || invalidToken) {
    return (
      <InvalidToken
        description={t(
          'Token you try to use has expired, was used already or doesn’t exist at all. Please, visit login page and try to reset password again.'
        )}
      />
    )
  }

  return (
    <NewPasswordForm onSubmit={onSubmit} confirmButton={<ConfirmButton />} />
  )
}
