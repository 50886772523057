import {CanvasObjectType} from '@attendio/shared-components'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from '../../../../editor/redux'
import {
  iconsSelector,
  seatsSelector,
  selectedObjectsSelector,
  shapesSelector,
  textsSelector,
  zonesSelector
} from '../../../../editor/redux/objects/selectors'
import {Theme} from '../../../../theme'
import {useGetCapacity} from './editorUtils'

export const useStyles = makeStyles<Theme>((theme) => ({
  count: {
    marginRight: theme.spacing(0.5)
  },
  bold: {
    fontWeight: 500
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  }
}))

const getMapItemsCount = (map: {}) => Object.keys(map).length

interface IFloorPlanStatusItemProps {
  count: number
  label: string
  cypressId?: string
}

const FloorPlanStatusItem: React.FC<IFloorPlanStatusItemProps> = ({
  label,
  count,
  cypressId
}: IFloorPlanStatusItemProps) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Typography
        cypress-id={cypressId}
        className={cn(classes.count, classes.bold)}
      >
        {count}
      </Typography>
      <Typography>{label}</Typography>
    </Grid>
  )
}

interface IFloorPlanStatusProps {
  disableCapacity?: boolean
  disableShapes?: boolean
  disableIcons?: boolean
  disableTexts?: boolean
}

export const FloorPlanStatus: React.FC<IFloorPlanStatusProps> = ({
  disableCapacity = false,
  disableShapes = false,
  disableIcons = false,
  disableTexts = false
}: IFloorPlanStatusProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const zones = useSelector(zonesSelector)
  const seats = useSelector(seatsSelector)
  const shapes = useSelector(shapesSelector)
  const icons = useSelector(iconsSelector)
  const texts = useSelector(textsSelector)

  const seatCount = getMapItemsCount(seats)
  const zoneCount = getMapItemsCount(zones)
  const shapeCount = getMapItemsCount(shapes)
  const iconCount = getMapItemsCount(icons)
  const textCount = getMapItemsCount(texts)

  const capacity = useGetCapacity()

  return (
    <>
      <Typography className={cn(classes.header, classes.bold)}>
        {t('Floor plan status')}
      </Typography>

      <FloorPlanStatusItem
        count={seatCount}
        label={t('seat', {
          count: seatCount
        })}
        cypressId="seats-count"
      />
      <FloorPlanStatusItem
        count={zoneCount}
        label={t('zone', {
          count: zoneCount
        })}
        cypressId="zones-count"
      />

      {!disableShapes && getMapItemsCount(shapes) > 0 && (
        <FloorPlanStatusItem
          count={getMapItemsCount(shapes)}
          label={t('shape', {
            count: shapeCount
          })}
          cypressId="shapes-count"
        />
      )}
      {!disableIcons && getMapItemsCount(icons) > 0 && (
        <FloorPlanStatusItem
          count={getMapItemsCount(icons)}
          label={t('icon', {
            count: iconCount
          })}
          cypressId="icons-count"
        />
      )}
      {!disableTexts && getMapItemsCount(texts) > 0 && (
        <FloorPlanStatusItem
          count={getMapItemsCount(texts)}
          label={t('text', {
            count: textCount
          })}
          cypressId="texts-count"
        />
      )}
      {!disableCapacity && (
        <FloorPlanStatusItem
          count={capacity}
          label={t('total capacity')}
          cypressId="total-capacity-count"
        />
      )}
    </>
  )
}

const getSelectedObjectsLabel = (
  items: Array<{count: number; label: string}>
) =>
  items
    .filter(({count}) => count > 0)
    .map(({label, count}) => `${count} ${label}`)
    .join(', ')

export const SelectedEditorItemsStatus: React.FC = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  const selectedObjects = useSelector(selectedObjectsSelector)

  const selectedObjectsCounts = Object.values(selectedObjects).reduce(
    (res, object) => {
      return {
        ...res,
        [object.type]: res[object.type] ? res[object.type] + 1 : 1
      }
    },
    {
      [CanvasObjectType.Seat]: 0,
      [CanvasObjectType.Zone]: 0,
      [CanvasObjectType.Icon]: 0,
      [CanvasObjectType.Shape]: 0,
      [CanvasObjectType.Text]: 0
    }
  )

  const isSomethingSelected = Object.values(selectedObjectsCounts).some(
    (v) => v > 0
  )

  if (!isSomethingSelected) return <div />

  const seatCount = selectedObjectsCounts[CanvasObjectType.Seat]
  const zoneCount = selectedObjectsCounts[CanvasObjectType.Zone]
  const shapeCount = selectedObjectsCounts[CanvasObjectType.Shape]
  const iconCount = selectedObjectsCounts[CanvasObjectType.Icon]
  const textCount = selectedObjectsCounts[CanvasObjectType.Text]

  return (
    <Grid item>
      <Grid container alignItems="center">
        <Typography className={cn(classes.rightMargin, classes.bold)}>
          {t('Selected:')}
        </Typography>
        <Typography>
          {getSelectedObjectsLabel([
            {
              count: seatCount,
              label: t('seat', {
                count: seatCount
              })
            },
            {
              count: zoneCount,
              label: t('zone', {
                count: zoneCount
              })
            },
            {
              count: shapeCount,
              label: t('shape', {
                count: shapeCount
              })
            },
            {
              count: iconCount,
              label: t('icon', {
                count: iconCount
              })
            },
            {
              count: textCount,
              label: t('text', {
                count: textCount
              })
            }
          ])}
        </Typography>
      </Grid>
    </Grid>
  )
}
