import {
  CountryCode,
  LanguageCode,
  LocaleCode,
  ShowAgeClassificationCode,
  ShowContentDescriptorCode,
  ShowFormatCode,
  ShowGenreCode,
  ShowSoundMixCode,
  ShowTypeCode,
  ShowVersionCode
} from '../../../../__generated__/schema'

export enum ShowFormField {
  TITLE = 'title',
  DESCRIPTION = 'description',
  TAGLINE = 'tagline',
  LOCALE_CODE = 'localeCode',
  ORIGINAL_TITLE = 'originalTitle',
  PRODUCTION_YEAR = 'productionYear',
  DURATION = 'duration',
  GLOBAL_RELEASE_DATE = 'globalReleaseDate',
  TYPE_CODE = 'typeCode',
  AGE_CLASSIFICATION_CODES = 'ageClassificationCodes',
  GENRE_CODES = 'genreCodes',
  COUNTRY_CODES = 'countryCodes',
  LANGUAGE_CODES = 'languageCodes',
  DISTRIBUTIONS = 'distributions',
  CONTENT_DESCRIPTOR_CODES = 'contentDescriptorCodes',
  BUSINESS_PARTNER_ID = 'businessPartnerId'
}

export enum TranslationFormField {
  TITLE = 'title',
  DESCRIPTION = 'description',
  TAGLINE = 'tagline',
  LOCALE_CODE = 'localeCode',
  TRANSLATIONS = 'translations'
}

export enum DistributionFormField {
  FORMAT = 'formatCode',
  VERSION = 'versionCode',
  SOUND_MIX = 'soundMixCode'
}

export interface IDistributionFormProps {
  [DistributionFormField.FORMAT]: ShowFormatCode | ''
  [DistributionFormField.VERSION]: ShowVersionCode | ''
  [DistributionFormField.SOUND_MIX]: ShowSoundMixCode | ''
}

export interface IShowForm {
  [ShowFormField.TITLE]: string
  [ShowFormField.TAGLINE]: string
  [ShowFormField.DESCRIPTION]: string
  [ShowFormField.LOCALE_CODE]: LocaleCode
  [ShowFormField.TYPE_CODE]: ShowTypeCode
  [ShowFormField.ORIGINAL_TITLE]: string
  [ShowFormField.DURATION]: string
  [ShowFormField.AGE_CLASSIFICATION_CODES]: ShowAgeClassificationCode[]
  [ShowFormField.PRODUCTION_YEAR]: string
  [ShowFormField.GLOBAL_RELEASE_DATE]: string
  [ShowFormField.GENRE_CODES]: ShowGenreCode[]
  [ShowFormField.COUNTRY_CODES]: CountryCode[]
  [ShowFormField.LANGUAGE_CODES]: LanguageCode[]
  [ShowFormField.DISTRIBUTIONS]: {[key: string]: IDistributionFormProps | null}
  [ShowFormField.CONTENT_DESCRIPTOR_CODES]?: ShowContentDescriptorCode[]
  [ShowFormField.BUSINESS_PARTNER_ID]?: number
}

export interface ITranslationProps {
  [TranslationFormField.TITLE]: string
  [TranslationFormField.TAGLINE]?: string
  [TranslationFormField.DESCRIPTION]?: string
  [TranslationFormField.LOCALE_CODE]: LocaleCode
}
export interface ITranslationsForm {
  [TranslationFormField.TRANSLATIONS]: ITranslationProps[]
}

export const showCrewForm = 'showCrewForm'

export enum ShowCrewFormField {
  CrewRoleCode = 'crewRoleCode',
  PersonName = 'personName',
  Description = 'description'
}

export interface IShowCrewForm {
  [ShowCrewFormField.CrewRoleCode]: string
  [ShowCrewFormField.PersonName]: string
  [ShowCrewFormField.Description]?: string
}

export interface IShowCrewFieldsProps {
  id: string
  name: string
  description?: string
  crewRoleCode: string
}

export enum ShowFormLocation {
  Library = 'library',
  ClientCreate = 'clientCreate',
  ClientUpdate = 'clientUpdate'
}
