import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {BusinessPartnerState} from '../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../hooks/state'
import {useBusinessPartnerParams} from '../../../utils/pathname'
import {
  useBusinessPartner,
  useUpdateBusinessPartner
} from '../../pages/admin/businessPartners/graphql'
import {SplitButton} from '../../visual'
import {SaveButton} from '../Buttons'
import {DrawerTemplateWithSideNavigation} from '../DrawerTemplateWithSideNavigation'
import {DrawerTemplateHeader} from '../DrawerUtils'
import {BusinessPartnerForm} from './BusinessPartnerForm'
import {IBusinessPartnerForm} from './types'
import {
  transformBusinessPartnerFormDataToUpdateBusinessPartnerInput,
  transformBusinessPartnerToBusinessPartnerForm,
  useNavigationItems
} from './utils'

const UPDATE_BUSINESS_PARTNER_FORM_ID = 'updateBusinessPartnerForm'

interface IUpdateBusinessPartnerDrawerProps {
  onExited: () => void
}

export const UpdateBusinessPartnerDrawer: React.FC<IUpdateBusinessPartnerDrawerProps> =
  ({onExited}: IUpdateBusinessPartnerDrawerProps) => {
    const {t} = useTranslation()
    const {businessPartnerId} = useBusinessPartnerParams()
    const {data, loading, error} = useBusinessPartner(businessPartnerId)
    const updateBusinessPartner = useUpdateBusinessPartner()
    const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const nextBusinessPartnerState =
      useRef<BusinessPartnerState | undefined>(undefined)
    const navigationItems = useNavigationItems()
    const updateNextBusinessPartnerState = useCallback(
      (state) => () => (nextBusinessPartnerState.current = state),
      []
    )
    useEffect(() => {
      if (!isNaN(businessPartnerId)) {
        openDrawer()
      }
    }, [businessPartnerId, openDrawer])
    const handleSubmit = useCallback(
      async (formData: IBusinessPartnerForm) => {
        try {
          setShowBackdrop(true)
          await updateBusinessPartner({
            id: businessPartnerId,
            input: transformBusinessPartnerFormDataToUpdateBusinessPartnerInput(
              formData,
              nextBusinessPartnerState.current
            )
          })
          addInfoNotification(t('Business partner has been updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating business partner'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        businessPartnerId,
        closeDrawer,
        defaultErrorHandler,
        setShowBackdrop,
        t,
        updateBusinessPartner
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 960, width: '100%'}}}
      >
        <DrawerTemplateWithSideNavigation
          DrawerTemplateProps={{
            header: (
              <DrawerTemplateHeader
                title={t('Update business partner')}
                onLeftActionClick={closeDrawer}
                LeftActionIcon={CloseIcon}
              />
            ),
            footer: (
              <>
                {data?.businessPartner.state !==
                  BusinessPartnerState.Deleted && (
                  <Button
                    color="primary"
                    startIcon={<DeleteIcon />}
                    type="submit"
                    form={UPDATE_BUSINESS_PARTNER_FORM_ID}
                    onClick={updateNextBusinessPartnerState(
                      BusinessPartnerState.Deleted
                    )}
                  >
                    {t('Delete')}
                  </Button>
                )}
                <SplitButton
                  Options={[
                    <MenuItem
                      key="save-as"
                      type="submit"
                      form={UPDATE_BUSINESS_PARTNER_FORM_ID}
                      component="button"
                      onClick={
                        data?.businessPartner.state ===
                        BusinessPartnerState.Active
                          ? updateNextBusinessPartnerState(
                              BusinessPartnerState.Inactive
                            )
                          : updateNextBusinessPartnerState(
                              BusinessPartnerState.Active
                            )
                      }
                    >
                      {data?.businessPartner.state ===
                      BusinessPartnerState.Active
                        ? t('Save as inactive')
                        : t('Save as active')}
                    </MenuItem>
                  ]}
                >
                  <SaveButton
                    type="submit"
                    form={UPDATE_BUSINESS_PARTNER_FORM_ID}
                  >
                    {t('Save')}
                  </SaveButton>
                </SplitButton>
              </>
            ),
            isLoading: loading,
            errorMessage: error && t('Error while loading business partner')
          }}
          navigationItems={navigationItems}
        >
          {data && (
            <BusinessPartnerForm
              formId={UPDATE_BUSINESS_PARTNER_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={transformBusinessPartnerToBusinessPartnerForm(
                data.businessPartner
              )}
              navigationItems={navigationItems}
            />
          )}
        </DrawerTemplateWithSideNavigation>
      </Drawer>
    )
  }
