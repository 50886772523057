import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CancelButton, SaveButton} from '../Buttons'
import {DialogTitleWithCloseButton} from '../DialogTitleWithCloseButton'
import {
  CreateSectionDialogStep,
  GeneralFilterActions,
  GeneralFilterActionTypes,
  GeneralFilterState
} from './reducer'
import {IFilterKeyOption} from './types'

interface IAddSectionDialogProps<T extends object> {
  generalFilter: GeneralFilterState<T>
  dispatch: React.Dispatch<GeneralFilterActions<T>>
  filterKeyOptions: IFilterKeyOption<T>[]
  secondStepChildren: React.ReactNode
}

export const AddSectionDialog = <T extends object>({
  generalFilter,
  dispatch,
  secondStepChildren,
  filterKeyOptions
}: IAddSectionDialogProps<T>) => {
  const {addSectionDialog, visibleSections} = generalFilter
  const {t} = useTranslation()
  return (
    <Dialog open={addSectionDialog.isOpen} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton
        onCloseClick={() =>
          dispatch({
            type: GeneralFilterActionTypes.CloseCreateSectionDialog
          })
        }
      >
        {t('Add filter option')}
      </DialogTitleWithCloseButton>
      <DialogContent
        dividers
        sx={
          addSectionDialog.step === CreateSectionDialogStep.First ? {} : {pt: 0}
        }
      >
        {addSectionDialog.step === CreateSectionDialogStep.First ? (
          <>
            <Typography variant="caption" color="textSecondary" sx={{pb: 1}}>
              {t('Select filter key')}
            </Typography>
            <RadioGroup
              value={addSectionDialog.key}
              onChange={(e, key) => {
                dispatch({
                  type: GeneralFilterActionTypes.SelectSectionDialogKey,
                  payload: key as keyof T
                })
              }}
            >
              {filterKeyOptions.map((option) => (
                <FormControlLabel
                  key={String(option.key)}
                  value={option.key}
                  disabled={visibleSections.includes(option.key)}
                  control={<Radio color="primary" />}
                  sx={{
                    ...(visibleSections.includes(option.key)
                      ? {}
                      : {
                          '&:hover': {
                            backgroundColor: 'grey.300'
                          }
                        }),
                    m: 0
                  }}
                  label={option.name}
                />
              ))}
            </RadioGroup>
          </>
        ) : (
          secondStepChildren
        )}
      </DialogContent>
      <DialogActions>
        {addSectionDialog.step === CreateSectionDialogStep.First ? (
          <SaveButton
            onClick={() =>
              dispatch({
                type: GeneralFilterActionTypes.SetCreateSectionDialogStep,
                payload: CreateSectionDialogStep.Second
              })
            }
            disabled={!addSectionDialog.key}
          >
            {t('Next')}
          </SaveButton>
        ) : (
          <>
            <CancelButton
              onClick={() =>
                dispatch({
                  type: GeneralFilterActionTypes.SetCreateSectionDialogStep,
                  payload: CreateSectionDialogStep.First
                })
              }
            >
              {t('Back')}
            </CancelButton>
            <SaveButton
              disabled={
                addSectionDialog.key === null ||
                !addSectionDialog.dialogFilter[addSectionDialog.key]
              }
              onClick={() => {
                if (addSectionDialog.key) {
                  dispatch({
                    type: GeneralFilterActionTypes.MoveDataFromAddDialogToFilter,
                    payload: addSectionDialog.key
                  })
                }
              }}
            >
              {t('Done')}
            </SaveButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
