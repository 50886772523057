import {Draggable} from '@fullcalendar/interaction'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Chip,
  Grid,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {
  ClientShowPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useGetCountryThreeLetterCode} from '../../../../../hooks/countries'
import {useClientLocaleCode} from '../../../../../hooks/getLocales'
import {
  getGenreBorderColor,
  getGenreTextColor,
  useTranslateGenre
} from '../../../../../hooks/showGenres'
import {useTranslateShowType} from '../../../../../hooks/showTypes'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../../../utils/formatting'
import {getShowTranslation} from '../../../../../utils/translations'
import {Tooltip} from '../../../../common'
import {DescriptorsRow} from '../../../../common/DescriptorsRow'
import {borderStyle} from '../../../../constants'
import {getDistributionString} from '../common'

const DRAGGABLE_WRAPPER_ID = 'draggable-wrapper'

const usePanelStyles = makeStyles<Theme, {color: string}>((theme) => ({
  cardOuterWrapper: {
    display: 'flex',
    alignItems: 'stretch'
  },
  cardInnerWrapper: {
    flex: 1
  },
  cardLine: {
    width: 4,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    background: ({color}) => color
  },
  accordion: {
    border: borderStyle,
    boxShadow: 'none',
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important'
  },
  accordionExpanded: {
    borderColor: ({color}) => color,
    borderLeftColor: 'transparent'
  },
  accordionSummary: {
    alignItems: 'flex-start'
  },
  expandIconWrapper: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  accordionSummaryExpanded: {
    [`&.${accordionSummaryClasses.expandIconWrapper}`]: {
      transform: `rotate(180deg) translateY(-${theme.spacing(1)})`
    }
  }
}))

const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    cursor: 'pointer'
  },
  showTitle: {
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

interface IDistributionListProps {
  show: ClientShowPropertiesFragment
}

interface IDistributionItemProps extends IDistributionListProps {
  distribution: ClientShowPropertiesFragment['distributions'][number] | null
  show: ClientShowPropertiesFragment
}

const DistributionItem: React.FC<IDistributionItemProps> = ({
  show,
  distribution
}: IDistributionItemProps) => {
  const clientLocaleCode = useClientLocaleCode()
  const {t} = useTranslation()
  const classes = useStyles()

  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()

  const label = distribution
    ? getDistributionString({
        format: distribution.formatCode
          ? translateShowFormatAbbreviation(distribution.formatCode)
          : '',
        version: distribution.versionCode
          ? translateShowVersionAbbreviation(distribution.versionCode)
          : '',
        soundMix: distribution.soundMixCode
          ? translateShowSoundMixAbbreviation(distribution.soundMixCode)
          : ''
      })
    : t('Drag to calendar')
  const translation = getShowTranslation({
    translations: show.translations,
    localeCode: clientLocaleCode
  })
  return (
    // @ts-ignore TS complains about 'data' prop
    <Grid
      item
      className="draggable-show"
      title={
        translation
          ? translation.title
          : show.originalTitle || t('Title not available')
      }
      data={JSON.stringify({
        id: uuidv4(),
        price: '',
        textColor: getGenreTextColor(show.genreCodes[0]),
        borderColor: getGenreBorderColor(show.genreCodes[0]),
        distribution: label,
        duration: show.duration || 30, // 30min is default
        versionCode: distribution && distribution.versionCode,
        formatCode: distribution && distribution.formatCode,
        soundMixCode: distribution && distribution.soundMixCode,
        ageClassificationCode:
          show.ageClassificationCodes.length > 0
            ? show.ageClassificationCodes[0]
            : null,
        showId: show.id
      })}
    >
      <Tooltip title={t<string>('Drag to calendar')}>
        <Chip
          icon={<DragIndicatorIcon />}
          className={classes.chip}
          label={label}
        />
      </Tooltip>
    </Grid>
  )
}

const DistributionList: React.FC<IDistributionListProps> = ({
  show
}: IDistributionListProps) => {
  useEffect(() => {
    const draggableEl = document.getElementById(DRAGGABLE_WRAPPER_ID + show.id)

    if (!draggableEl) return

    // This is magic that makes components draggable
    // eslint-disable-next-line no-new
    new Draggable(draggableEl, {
      itemSelector: '.draggable-show',
      eventData(eventEl) {
        const data = JSON.parse(eventEl.getAttribute('data')!)
        return {
          title: eventEl.getAttribute('title'),
          // fullcalendar duration is in miliseconds, but we work with minutes
          duration: data.duration * 1000 * 60,
          extendedProps: data
        }
      }
    })
    // the [] is important otherwise it add more events
  }, []) // eslint-disable-line

  return (
    <Grid id={DRAGGABLE_WRAPPER_ID + show.id} container spacing={2}>
      {show.distributions.length > 0 ? (
        show.distributions.map((distribution, index) => {
          return (
            <DistributionItem
              key={index}
              show={show}
              distribution={distribution}
            />
          )
        })
      ) : (
        <DistributionItem show={show} distribution={null} />
      )}
    </Grid>
  )
}

interface IShowCardSummaryProps {
  show: ClientShowPropertiesFragment
}

const ShowCardSummary: React.FC<IShowCardSummaryProps> = ({
  show
}: IShowCardSummaryProps) => {
  const clientLocaleCode = useClientLocaleCode()
  const classes = useStyles()
  const {t} = useTranslation()
  const {formatDate} = useDateTimeFormatters()
  const {
    durationToHoursMinutesShortFormat,
    durationToMinutesWithAbbreviatedUnitFormat
  } = useShowDurationFormatters()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateShowType = useTranslateShowType()

  const getCountryThreeLetterCode = useGetCountryThreeLetterCode()

  const translation = getShowTranslation({
    translations: show.translations,
    localeCode: clientLocaleCode
  })
  const translateGenre = useTranslateGenre()

  return (
    <Grid container direction="column">
      <Typography className={classes.showTitle}>
        {translation
          ? translation.title
          : show.originalTitle || t('Title not available')}
      </Typography>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Typography variant="caption" color="textSecondary">
                {show.duration && (
                  <Tooltip title={t<string>('Duration')}>
                    <span>
                      {durationToHoursMinutesShortFormat(show.duration)}
                      &nbsp;(
                      {durationToMinutesWithAbbreviatedUnitFormat(
                        show.duration
                      )}
                      )
                    </span>
                  </Tooltip>
                )}
                {show.duration && show.ageClassificationCodes.length > 0 && (
                  <span>&nbsp;•&nbsp;</span>
                )}
                {show.ageClassificationCodes.length > 0 && (
                  <Tooltip title={t<string>('Age restrictions')}>
                    <span>
                      {show.ageClassificationCodes
                        .map((code) =>
                          translateAgeClassificationAbbreviation(code)
                        )
                        .join(', ')}
                    </span>
                  </Tooltip>
                )}
                {show.contentDescriptorCodes &&
                  show.contentDescriptorCodes.length > 0 && (
                    <>
                      &nbsp;•&nbsp;
                      <DescriptorsRow
                        descriptorCodes={show.contentDescriptorCodes}
                        showAsAbbreviation
                        component="span"
                      />
                    </>
                  )}
              </Typography>
            </Grid>
          </Grid>
          {show.globalReleaseDate && (
            <Tooltip title={t<string>('Premiere')}>
              <Typography variant="caption" color="textSecondary">
                {formatDate(new Date(show.globalReleaseDate))}
              </Typography>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Typography variant="caption" color="textSecondary">
            {[
              translateShowType(show.typeCode),
              show.genreCodes.map((code) => translateGenre(code)).join(', ')
            ]
              .filter(Boolean)
              .join(' • ')}
          </Typography>
          <Tooltip title={t<string>('Countries of origin')}>
            <Typography variant="caption" color="textSecondary">
              {show.countryCodes
                .map((countryCode) => getCountryThreeLetterCode(countryCode))
                .join(', ')}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
      {show.businessPartner && (
        <Typography variant="caption" color="textSecondary">
          {show.businessPartner.companyName}
        </Typography>
      )}
    </Grid>
  )
}

interface IShowCardProps {
  show: ClientShowPropertiesFragment
  expanded: boolean
  onCollapse: Function
  onExpand: Function
}

export const ShowCard: React.FC<IShowCardProps> = ({
  show,
  expanded,
  onCollapse,
  onExpand
}: IShowCardProps) => {
  const color = getGenreBorderColor(show.genreCodes[0])
  const classes = usePanelStyles({color})
  const {P} = useEnsurePermissions()
  const {t} = useTranslation()

  return (
    <div className={classes.cardOuterWrapper}>
      <div className={classes.cardLine} />
      <div className={classes.cardInnerWrapper}>
        <Accordion
          expanded={expanded}
          onChange={(e, isExpanded) => {
            isExpanded ? onExpand() : onCollapse()
          }}
          classes={{
            root: classes.accordion,
            expanded: classes.accordionExpanded
          }}
        >
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIconWrapper,
              expanded: classes.accordionSummaryExpanded
            }}
            expandIcon={
              <Tooltip
                title={
                  expanded ? t<string>('Show less') : t<string>('Show more')
                }
              >
                <ExpandMoreIcon />
              </Tooltip>
            }
            className={classes.accordionSummary}
          >
            <ShowCardSummary show={show} />
          </AccordionSummary>
          <AccordionDetails>
            {P([PermissionCode.CreateEvent]) && (
              <DistributionList show={show} />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}
