import {ICreateSeat, ISeat} from '@attendio/shared-components'
import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {getCompressedId} from '../../../utils/compressedIdGenerator'
import {CanvasObjectType} from '../../types'
import {SeatActionType} from './types'

export const useSeatsActions = () => {
  const dispatch = useDispatch()

  const addSeat = useCallback(
    (seat: ICreateSeat) => {
      const _seat: ISeat = {
        ...seat,
        id: getCompressedId()
      }
      dispatch({
        type: SeatActionType.ADD_SEAT,
        payload: {type: CanvasObjectType.Seat, config: _seat}
      })
    },
    [dispatch]
  )

  const addSeats = useCallback(
    (seats: Array<ICreateSeat>) => {
      const _seats: Array<ISeat> = seats.map((seat) => ({
        ...seat,
        id: getCompressedId()
      }))
      dispatch({
        type: SeatActionType.ADD_SEATS,
        payload: {
          type: CanvasObjectType.Seat,
          configs: _seats
        }
      })
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      addSeat,
      addSeats
    }),
    [addSeat, addSeats]
  )

  return actions
}
