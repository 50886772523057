import {useTranslation} from 'react-i18next'
import {PassCodeCheckAdditionalInformation} from '../__generated__/schema'

export const useTranslatePassCodeCheckAdditionalInformation = () => {
  const {t} = useTranslation()
  const translatedAdditionalInformation: Record<
    PassCodeCheckAdditionalInformation,
    string
  > = {
    [PassCodeCheckAdditionalInformation.GateIsNotOpen]:
      t<string>('Gate is not open'),
    [PassCodeCheckAdditionalInformation.TicketClaimed]:
      t<string>('Ticket claimed'),
    [PassCodeCheckAdditionalInformation.AlreadyCheckedIn]:
      t<string>('Already checked in'),
    [PassCodeCheckAdditionalInformation.AlreadyCheckedOut]: t<string>(
      'Already checked out'
    ),
    [PassCodeCheckAdditionalInformation.CheckedOut]: t<string>('Checked out'),
    [PassCodeCheckAdditionalInformation.CheckedIn]: t<string>('Checked in'),
    [PassCodeCheckAdditionalInformation.DiscountAuthorizationPending]:
      t<string>('Discount authorization pending'),
    [PassCodeCheckAdditionalInformation.UnauthorizedDiscount]: t<string>(
      'Unauthorized discount'
    ),
    [PassCodeCheckAdditionalInformation.AuthorizedDiscount]: t<string>(
      'Authorized discount'
    ),
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterVenueIds]: t<string>(
      'Not found due to filter venue ids'
    ),
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterTourIds]: t<string>(
      'Not found due to filter tour ids'
    ),
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterTourTimeSlotIds]:
      t<string>('Not found due to filter tour time slot ids'),
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterEventIds]: t<string>(
      'Not found due to event ids'
    ),
    [PassCodeCheckAdditionalInformation.NotFoundDueToFilterArguments]:
      t<string>('Not found due to filter arguments'),
    [PassCodeCheckAdditionalInformation.EventNotPublished]: t<string>(
      'Event not published'
    ),
    [PassCodeCheckAdditionalInformation.TourTimeSlotNotPublished]: t<string>(
      'Tour time slot not published'
    )
  }
  return (additionalInformation: PassCodeCheckAdditionalInformation) =>
    translatedAdditionalInformation[additionalInformation]
}
