import {Button, Drawer, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import uniqBy from 'lodash/uniqBy'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Gallery from 'react-photo-gallery'
import {ShowImageType} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {Theme} from '../../../../theme'
import {useShowParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {CenteredLayout} from '../Layout'
import {Dropzone} from './Dropzone'
import {ImagePreview} from './imagePreview/ImagePreview'
import {PrivateCheckbox} from './PrivateCheckbox'
import {RenderedImage} from './RenderedImage'
import {
  GALLERY_MARGIN,
  GALLERY_TARGET_ROW_HEIGHT,
  GalleryImage2,
  getImageInfo
} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  paper: {
    width: '100%',
    height: '100%'
  },
  dropzone: {
    width: '100%'
  },
  gallery: {
    padding: 0,
    width: '100%'
  },
  drawerFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imagesInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5)
  },
  bold: {
    fontWeight: 700
  }
}))

interface IUploadMediaProps {
  onExited: () => void
  onUpload: (images: GalleryImage2[]) => Promise<void>
  showPrivateCheckbox?: boolean
  title?: string
}

export const UploadMedia: React.FC<IUploadMediaProps> = ({
  onExited,
  onUpload,
  showPrivateCheckbox,
  title
}: IUploadMediaProps) => {
  const {t} = useTranslation()
  const {showId, activeContentType} = useShowParams()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {
    state: isImagePreviewOpen,
    setTrue: openImagePreview,
    setFalse: closeImagePreview
  } = useBooleanState(false)
  const {
    state: isUnsavedChangesDialogOpen,
    setTrue: openUnsavedChangesDialog,
    setFalse: closeUnsavedChangesDialog
  } = useBooleanState(false)
  useEffect(() => {
    if (
      !isNaN(showId) &&
      Object.values(ShowImageType).includes(activeContentType as ShowImageType)
    ) {
      openDrawer()
    }
  }, [activeContentType, openDrawer, showId])
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const [selectedImages, setSelectedImages] = useState<GalleryImage2[]>([])
  const [openedImage, setOpenedImage] = useState<number | null>(null)
  const openedImageIndex = selectedImages.findIndex(
    (image) => image.id === openedImage
  )
  const handleClose = useCallback(() => {
    closeDrawer()
    setSelectedImages([])
  }, [closeDrawer])
  const handleDrop = useCallback(
    async (acceptedImages: File[]) => {
      try {
        setShowBackdrop(true)
        const droppedImages = await getImageInfo(
          acceptedImages,
          showId,
          activeContentType as ShowImageType
        )
        setSelectedImages((prevState) =>
          uniqBy([...prevState, ...droppedImages], 'imageName')
        )
      } catch (err) {
        defaultErrorHandler(err, t('Error while loading images'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [activeContentType, defaultErrorHandler, setShowBackdrop, showId, t]
  )
  const handleDeleteButtonClick = useCallback(
    (imageId: number) =>
      setSelectedImages((selectedImages) =>
        selectedImages.filter((image) => image.id !== imageId)
      ),
    []
  )
  const handleImagePreviewDeleteButtonClick = useCallback(
    (imageId: number) => {
      if (openedImageIndex === 0 && selectedImages.length === 1) {
        setOpenedImage(null)
        closeImagePreview()
      } else if (openedImageIndex === 0 && selectedImages.length > 0) {
        setOpenedImage(selectedImages[openedImageIndex + 1].id)
      } else {
        setOpenedImage(selectedImages[openedImageIndex - 1].id)
      }
      setSelectedImages((selectedImages) =>
        selectedImages.filter((image) => image.id !== imageId)
      )
      addInfoNotification(t('Image has been deleted.'))
    },
    [
      addInfoNotification,
      closeImagePreview,
      openedImageIndex,
      selectedImages,
      t
    ]
  )
  const handleZoomButtonClick = useCallback(
    (imageId: number) => {
      setOpenedImage(imageId)
      openImagePreview()
    },
    [openImagePreview]
  )
  const handleBackButtonClick = useCallback(() => {
    setOpenedImage(null)
    closeImagePreview()
  }, [closeImagePreview])
  const handlePrivateCheckboxClick = useCallback((imageId: number) => {
    setSelectedImages((selectedImages) =>
      selectedImages.map((image) =>
        image.id === imageId
          ? {
              ...image,
              isPrivate: !image.isPrivate
            }
          : image
      )
    )
  }, [])
  const handleUpload = useCallback(async () => {
    await onUpload(selectedImages)
    setSelectedImages([])
  }, [onUpload, selectedImages])
  const classes = useStyles()
  return (
    <Drawer
      anchor="bottom"
      classes={{paper: classes.paper}}
      onClose={
        selectedImages.length > 0 ? openUnsavedChangesDialog : handleClose
      }
      SlideProps={{
        exit: true,
        onExited
      }}
      open={isDrawerOpen}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            title={title || t('Upload from computer')}
            onLeftActionClick={
              selectedImages.length > 0 ? openUnsavedChangesDialog : handleClose
            }
          />
        }
        footer={
          <div className={classes.drawerFooter}>
            <div className={classes.imagesInfo}>
              {selectedImages.length > 0 && (
                <>
                  <Typography variant="body2" className={classes.bold}>
                    {t('Prepared to upload:')}
                  </Typography>
                  <Typography variant="body2">
                    {t('{{count}} images', {count: selectedImages.length})}
                  </Typography>
                </>
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedImages.length === 0}
              onClick={handleUpload}
            >
              {t('Upload')}
            </Button>
          </div>
        }
        isFooterFullWidth
      >
        <CenteredLayout>
          <div className={classes.root}>
            <Dropzone className={classes.dropzone} onDrop={handleDrop} />
            {selectedImages.length > 0 && (
              <Typography variant="subtitle1">
                {t('Selected images')}
              </Typography>
            )}
            <div>
              <Gallery
                photos={selectedImages}
                margin={GALLERY_MARGIN}
                targetRowHeight={GALLERY_TARGET_ROW_HEIGHT}
                renderImage={({photo, margin, index}) => (
                  <RenderedImage
                    photo={photo}
                    margin={margin}
                    imageId={selectedImages[index].id}
                    key={photo.src}
                    onDeleteButtonClick={handleDeleteButtonClick}
                    onZoomButtonClick={handleZoomButtonClick}
                    onContentClick={handleZoomButtonClick}
                    customAction={
                      showPrivateCheckbox ? (
                        <PrivateCheckbox
                          isChecked={selectedImages[index].isPrivate || false}
                          onClick={() =>
                            handlePrivateCheckboxClick(selectedImages[index].id)
                          }
                        />
                      ) : undefined
                    }
                  />
                )}
              />
              {openedImage && (
                <ImagePreview
                  images={selectedImages}
                  isOpen={isImagePreviewOpen}
                  onClose={closeImagePreview}
                  startIndex={openedImageIndex || 0}
                  currentImage={openedImage}
                  setCurrentImage={setOpenedImage}
                  onBackButtonClick={handleBackButtonClick}
                  onDeleteButtonClick={handleImagePreviewDeleteButtonClick}
                  onPrivateButtonClick={
                    showPrivateCheckbox ? handlePrivateCheckboxClick : undefined
                  }
                />
              )}
            </div>
          </div>
        </CenteredLayout>
      </DrawerTemplate>
      <ConfirmationDialog
        title={t('Unsaved changes')}
        contentText={t(
          'There are some images prepared for upload. Would you like to discard those changes without uploading or you would like to keep editing?'
        )}
        onConfirm={closeUnsavedChangesDialog}
        onCancel={handleClose}
        confirmButtonLabel={t('Keep editing')}
        cancelButtonLabel={t('Discard changes')}
        isOpen={isUnsavedChangesDialogOpen}
      />
    </Drawer>
  )
}
