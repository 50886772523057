import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useRef} from 'react'
import {Provider} from 'react-redux'
import {Editor} from '../../../../editor'
import {SIDE_PANEL_WIDTH} from '../../../../editor/config'
import {createTmpStore, useSelector} from '../../../../editor/redux'

import {DisplayMode} from '../../../../editor/redux/displayMode/reducer'
import {
  seatsSelector,
  zonesSelector
} from '../../../../editor/redux/objects/selectors'
import {
  ISeatStateValue,
  IZoneStateValue
} from '../../../../editor/redux/objects/types'
import {IDimensions, useElementDimensions} from '../../../../hooks/dimensions'

import {Theme} from '../../../../theme'
import {BOTTOM_ACTION_BAR_HEIGHT} from '../../../common/BottomActionsBar'

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    flexGrow: 1,
    height: '100%'
  },
  sideBarWrapper: {
    width: SIDE_PANEL_WIDTH,
    padding: theme.spacing(2, 3)
  }
}))

interface IAuditoriumEditorLayoutProps {
  children: (dimensions: IDimensions) => React.ReactNode
}

export const AuditoriumEditorLayout: React.FC<IAuditoriumEditorLayoutProps> = ({
  children
}: IAuditoriumEditorLayoutProps) => {
  const classes = useStyles()
  const [ref, dimensions] = useElementDimensions()

  // We only create store in the initial render and store it in 'ref', thus it is not
  // recreated on other re-renders which could cause lost of state and various errors
  const store = useRef(createTmpStore()).current

  return (
    <Provider store={store}>
      <Grid item ref={ref} className={classes.wrapper}>
        {children(dimensions)}
      </Grid>
    </Provider>
  )
}

interface IFullWidthEditorProps extends IDimensions {
  children: React.ReactElement
  displayMode?: DisplayMode
  fullHeight?: boolean
}

export const FullWidthEditor: React.FC<IFullWidthEditorProps> = ({
  width,
  height,
  children,
  displayMode,
  fullHeight = false
}: IFullWidthEditorProps) => {
  return height > 0 ? (
    <Editor
      height={fullHeight ? height : height - BOTTOM_ACTION_BAR_HEIGHT}
      DefaultPanel={children}
      {...{displayMode, width}}
    />
  ) : null
}

export const EditorSideBar = ({children}: {children: React.ReactNode}) => {
  const classes = useStyles()
  return <div className={classes.sideBarWrapper}>{children}</div>
}

export const useEditorFormStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  formItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

export const useGetCapacity = () => {
  const zones = useSelector(zonesSelector) as Array<IZoneStateValue>
  const seats = useSelector(seatsSelector) as Array<ISeatStateValue>

  const zonesCapacity = zones.reduce((res, z) => res + z.config.capacity, 0)
  const seatsCapacity = seats.length
  return zonesCapacity + seatsCapacity
}
