// @flow

import {useCallback, useState} from 'react'

// The useRef doesn’t notify you when its content changes.
// Mutating the .current property doesn’t cause a re-render.ref
// See:
// https://reactjs.org/docs/hooks-reference.html#useref
// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node

export const useCallbackRef = <T = HTMLElement | null>() => {
  const [htmlNode, setHtmlNode] = useState<T | null>(null)

  const callbackRef = useCallback((node: T) => {
    if (node !== null) {
      setHtmlNode(node)
    }
  }, [])

  return {callbackRef, htmlNode}
}
