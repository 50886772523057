import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  CartsSupportUserFilterInput,
  SellingChannel
} from '../../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {
  Search,
  useCombineStringifySearchObjectFunctions
} from '../../../../common'
import {AdvancedSearchBase} from '../../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../../common/search/daterangeSearch'
import {QuickSearchDaterangeRow} from '../../../../common/search/QuickSearchDaterangeRow'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../../common/search/types'
import {useGetLightweightClients} from '../../graphql'
import {
  CartSearchLocation,
  CartsFilter,
  DEFAULT_CARTS_FILTER,
  ExtendedCartsFilter
} from './types'
import {getStorageKey} from './utils'

const stripCreatedAtDateFromFilter = (
  filter: ExtendedCartsFilter
): CartsFilter =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripUpdatedAtDateFromFilter = (
  filter: ExtendedCartsFilter
): CartsFilter =>
  omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAtDaterangeId'])

const stripStartsAtDateFromFilter = (
  filter: ExtendedCartsFilter
): ExtendedCartsFilter =>
  omit(filter, ['startsAtTo', 'startsAtFrom', '_startsAtDaterangeId'])

const stripHelperKeysFromFilter = (filter: ExtendedCartsFilter): CartsFilter =>
  omit(filter, [
    '_createdAtDaterangeId',
    '_updatedAtDaterangeId',
    '_startsAtDaterangeId'
  ])

const mapHasTextToFilter = (
  filter: CartsFilter,
  hasText: string
): CartsFilter => ({
  ...filter,
  hasText
})

const mapSellingChannelToFilter = (
  filter: CartsFilter,
  sellingChannel: SellingChannel | undefined
): CartsFilter => ({
  ...filter,
  sellingChannel
})

const mapClientIdToFilter = (
  filter: CartsSupportUserFilterInput,
  clientId: number | undefined
): CartsSupportUserFilterInput => ({...filter, clientId})

const mapPassCodeToFilter = (
  filter: CartsFilter,
  passCode: string | undefined
): CartsFilter => ({...filter, passCode})

const mapThirdPartyPaymentIdToFilter = (
  filter: CartsFilter,
  thirdPartyPaymentId: string | undefined
): CartsFilter => ({...filter, thirdPartyPaymentId})

const mapCustomerNameToFilter = (
  filter: CartsFilter,
  leadName: string | undefined
): CartsFilter => ({...filter, leadName})

const mapCustomerPhoneToFilter = (
  filter: CartsFilter,
  leadPhone: string | undefined
): CartsFilter => ({...filter, leadPhone})

const mapCustomerEmailToFilter = (
  filter: CartsSupportUserFilterInput,
  leadEmail: string | undefined
): CartsFilter => ({...filter, leadEmail})

const mapCustomerNoteToFilter = (
  filter: CartsSupportUserFilterInput,
  leadNote: string | undefined
): CartsFilter => ({...filter, leadNote})

const mapEventNameToFilter = (
  filter: CartsFilter,
  title: string | undefined
): CartsFilter => ({...filter, title})

const mapCartIdToFilter = (
  filter: CartsFilter,
  cartId: string | undefined
): CartsFilter => ({
  ...filter,
  cartId: cartId ? parseInt(cartId, 10) : undefined
})

const mapReservationIdToFilter = (
  filter: CartsFilter,
  reservationId: string | undefined
): CartsFilter => ({
  ...filter,
  reservationId: reservationId ? parseInt(reservationId, 10) : undefined
})

const mapSaleIdToFilter = (
  filter: CartsFilter,
  saleId: string | undefined
): CartsFilter => ({
  ...filter,
  saleId: saleId ? parseInt(saleId, 10) : undefined
})

const cartDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_30_DAYS
]

const eventStartDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.TOMORROW,
  DATERANGE_IDS.NEXT_7_DAYS,
  DATERANGE_IDS.NEXT_MONTH
]

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateSellingChannel = useTranslateSellingChannel()
  const getSellingChannelFromSearchObject = (filter: CartsFilter) =>
    filter.sellingChannel
      ? t('Selling channel: {{channel}}', {
          channel: translateSellingChannel(filter.sellingChannel)
        })
      : undefined
  const getClientFromSearchObject = (
    filter: CartsSupportUserFilterInput,
    clients: {id: number; name: string}[]
  ) => {
    if (filter.clientId) {
      const client = clients.find(({id}) => id === filter.clientId)
      return client
        ? t('Client: {{name}}', {name: client.name})
        : t('Client ID: {{id}}', {id: filter.clientId})
    }
    return undefined
  }
  const getPassCodeFromSearchObject = (filter: CartsFilter) =>
    filter.passCode
      ? t('Ticket: {{passCode}}', {passCode: filter.passCode})
      : undefined
  const getThirdPartyPaymentIdFromSearchObject = (filter: CartsFilter) =>
    filter.thirdPartyPaymentId
      ? t('3rd party payment ID: {{id}}', {
          id: filter.thirdPartyPaymentId
        })
      : undefined
  const getLeadNameFromSearchObject = (filter: CartsFilter) =>
    filter.leadName
      ? t('Customer name: {{name}}', {
          name: filter.leadName
        })
      : undefined
  const getLeadPhoneFromSearchObject = (filter: CartsFilter) =>
    filter.leadPhone
      ? t('Customer phone: {{phone}}', {
          phone: filter.leadPhone
        })
      : undefined
  const getLeadEmailFromSearchObject = (filter: CartsFilter) =>
    filter.leadEmail
      ? t('Customer e-mail: {{email}}', {
          email: filter.leadEmail
        })
      : undefined
  const getLeadNoteFromSearchObject = (filter: CartsFilter) =>
    filter.leadNote
      ? t('Note: {{note}}', {
          note: filter.leadNote
        })
      : undefined
  const getEventNameFromSearchObject = (filter: CartsFilter) =>
    filter.title
      ? t('Event title: {{name}}', {
          name: filter.title
        })
      : undefined
  const getCartIdFromSearchObject = (filter: CartsFilter) =>
    filter.cartId
      ? t('Cart ID: {{id}}', {
          id: filter.cartId
        })
      : undefined
  const getReservationIdFromSearchObject = (filter: CartsFilter) =>
    filter.reservationId
      ? t('Reservation ID: {{id}}', {
          id: filter.reservationId
        })
      : undefined
  const getSaleIdFromSearchObject = (filter: CartsFilter) =>
    filter.saleId
      ? t('Sale ID: {{id}}', {
          id: filter.saleId
        })
      : undefined
  const getHasTextFromSearchObject = (filter: CartsFilter) =>
    filter.hasText || undefined
  return {
    getSellingChannelFromSearchObject,
    getClientFromSearchObject,
    getPassCodeFromSearchObject,
    getThirdPartyPaymentIdFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadPhoneFromSearchObject,
    getLeadEmailFromSearchObject,
    getLeadNoteFromSearchObject,
    getEventNameFromSearchObject,
    getCartIdFromSearchObject,
    getReservationIdFromSearchObject,
    getSaleIdFromSearchObject,
    getHasTextFromSearchObject
  }
}

interface ICartsSearchProps {
  onFilterChange: (filter: CartsFilter) => void
  location: CartSearchLocation
}

export const CartsSearch: React.FC<ICartsSearchProps> = ({
  onFilterChange,
  location
}: ICartsSearchProps) => {
  const {t} = useTranslation()
  const {clients} = useGetLightweightClients()
  const {
    getSellingChannelFromSearchObject,
    getClientFromSearchObject,
    getPassCodeFromSearchObject,
    getThirdPartyPaymentIdFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadPhoneFromSearchObject,
    getLeadEmailFromSearchObject,
    getLeadNoteFromSearchObject,
    getEventNameFromSearchObject,
    getCartIdFromSearchObject,
    getReservationIdFromSearchObject,
    getSaleIdFromSearchObject,
    getHasTextFromSearchObject
  } = useGetFieldFromSearchObject()
  const translateSellingChannel = useTranslateSellingChannel()
  const sellingChannelOptions: ISelectOption<SellingChannel>[] = [
    SellingChannel.Retail,
    SellingChannel.ECommerce
  ].map((channel) => ({
    id: channel,
    label: translateSellingChannel(channel)
  }))
  const clientOptions: ISelectOption<number>[] = clients.map(({id, name}) => ({
    id,
    label: name
  }))
  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCartsFilter>({
    usedDateranges: cartDateRanges,
    dateRangeInputPrefix: t('Created'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: updatedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedUpdatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCartsFilter>({
    usedDateranges: cartDateRanges,
    dateRangeInputPrefix: t('Updated'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.updatedAtFrom || undefined,
      endDateISOString: o.updatedAtTo || undefined,
      id: o._updatedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      updatedAtFrom: input.startDate,
      updatedAtTo: input.endDate,
      _updatedAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: startsAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomStartsAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapStartsAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedStartsAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsStartsAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCartsFilter>({
    usedDateranges: eventStartDateDateRanges,
    dateRangeInputPrefix: t('Event starts'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.startsAtFrom || undefined,
      endDateISOString: o.startsAtTo || undefined,
      id: o._startsAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      startsAtFrom: input.startDate,
      startsAtTo: input.endDate,
      _startsAtDaterangeId: input.id
    })
  })
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getSellingChannelFromSearchObject,
    getStringifiedUpdatedAtDateDateRangeFromSearchObject,
    (filter) => getClientFromSearchObject(filter, clients),
    getPassCodeFromSearchObject,
    getThirdPartyPaymentIdFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadPhoneFromSearchObject,
    getLeadEmailFromSearchObject,
    getLeadNoteFromSearchObject,
    getEventNameFromSearchObject,
    getCartIdFromSearchObject,
    getReservationIdFromSearchObject,
    getSaleIdFromSearchObject,
    getStringifiedStartsAtDateDateRangeFromSearchObject,
    getHasTextFromSearchObject
  )
  return (
    <Search<CartsFilter, ExtendedCartsFilter>
      storageKey={getStorageKey(location)}
      placeholder={t('Search for carts')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_CARTS_FILTER}
      renderQuickOptions={(setSearchObject) => (
        <QuickSearchDaterangeRow<CartsFilter>
          label={t('Created')}
          daterangeOptions={createdAtDateDaterangeOptions}
          mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
          mapCustomDaterangeToSearchObject={mapCustomCreatedAtDateRangeToFilter}
          searchObject={DEFAULT_CARTS_FILTER}
          setSearchObject={setSearchObject}
          customDaterangeDialogTitle={t('Select date range')}
          customDaterangeDialogDescription={t(
            'Select date range for cart created at'
          )}
        />
      )}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          {location === CartSearchLocation.Carts && (
            <AdvancedSearchSelectRow<CartsFilter, SellingChannel>
              value={advancedSearchObject.sellingChannel || undefined}
              label={t('Channel')}
              options={sellingChannelOptions}
              searchObject={advancedSearchObject}
              setSearchObject={setAdvancedSearchObject}
              mapSelectValueToSearchObject={mapSellingChannelToFilter}
            />
          )}
          <AdvancedSearchDaterangeRow<CartsFilter>
            label={t('Created')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart created at'
            )}
          />
          <AdvancedSearchDaterangeRow<CartsFilter>
            label={t('Updated')}
            daterangeOptions={updatedAtDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomUpdatedAtDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripUpdatedAtDateFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart updated at'
            )}
          />
          {location === CartSearchLocation.CartsSupport && (
            <AdvancedSearchSelectRow<CartsSupportUserFilterInput, number>
              label={t('Client')}
              value={
                (advancedSearchObject as CartsSupportUserFilterInput)
                  .clientId || undefined
              }
              options={clientOptions}
              mapSelectValueToSearchObject={mapClientIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Ticket')}
            placeholder={t('Enter ticket number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapPassCodeToFilter}
            value={advancedSearchObject.passCode || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('3rd party payment ID')}
            placeholder={t('Enter payment number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapThirdPartyPaymentIdToFilter}
            value={advancedSearchObject.thirdPartyPaymentId || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Customer name')}
            placeholder={t('Enter customer name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapCustomerNameToFilter}
            value={advancedSearchObject.leadName || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Customer phone')}
            placeholder={t('Enter customer phone')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapCustomerPhoneToFilter}
            value={advancedSearchObject.leadPhone || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Customer e-mail')}
            placeholder={t('Enter customer e-mail')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapCustomerEmailToFilter}
            value={advancedSearchObject.leadEmail || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Note')}
            placeholder={t("Enter customer's note or internal note")}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapCustomerNoteToFilter}
            value={advancedSearchObject.leadNote || undefined}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Event title')}
            placeholder={t('Enter event name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapEventNameToFilter}
            value={advancedSearchObject.title || undefined}
          />
          <AdvancedSearchDaterangeRow<CartsFilter>
            label={t('Event start')}
            daterangeOptions={startsAtDateDaterangeOptions}
            mapDaterangeToSearchObject={mapStartsAtDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomStartsAtDateRangeToFilter
            }
            stripDaterangeFromSearchObject={stripStartsAtDateFromFilter}
            getIsDaterangeOptionActive={getIsStartsAtDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Cart ID')}
            placeholder={t('Enter cart ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapCartIdToFilter}
            value={
              advancedSearchObject.cartId
                ? String(advancedSearchObject.cartId)
                : ''
            }
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Reservation ID')}
            placeholder={t('Enter reservation ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapReservationIdToFilter}
            value={
              advancedSearchObject.reservationId
                ? String(advancedSearchObject.reservationId)
                : ''
            }
          />
          <AdvancedSearchTextRow<CartsFilter>
            label={t('Sale ID')}
            placeholder={t('Enter sale ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapSaleIdToFilter}
            value={
              advancedSearchObject.saleId
                ? String(advancedSearchObject.saleId)
                : ''
            }
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
