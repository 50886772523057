import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Divider, Grid, Typography} from '@mui/material'
import dayjs from 'dayjs'
import {isNil} from 'lodash'
import React, {useCallback} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DetailEventPropertiesFragment,
  ErrorMessages,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useHandleInvalidDateTimes} from '../../../../../../hooks/pickerErrors'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../../utils/errors'
import {useEventsPathnameParams} from '../../../../../../utils/pathname'
import {routeTo} from '../../../../../../utils/routes'

import {
  ButtonWithConfirmationDialog,
  CustomDrawerActionsBar,
  DrawerForm,
  RelativeSideNavigation
} from '../../../../../common'
import {HEIGHT as DRAWER_HEADER_HEIGHT} from '../../../../../common/DrawerUtils'
import {SplitButton} from '../../../../../visual'

import {
  LoadEventData,
  useCommonFormStyles,
  useDrawerHeaderTitle
} from '../common'
import {useDeleteEvent} from '../graphql'
import {Analytics} from './Analytics'
import {
  FormField,
  IMainEventPropertiesFormData,
  NO_BUSINESS_PARTNER_SELECTED,
  NO_COST_CENTER_SELECTED,
  NO_EVENT_CATEGORY_SELECTED,
  NO_MARKETING_LABEL_SELECTED
} from './common'
import {Fees} from './Fees'
import {FinancialFields} from './FinancialFields'
import {GeneralEventInfoCard} from './general/GeneralEventInfoCard'
import {NotesFields} from './NotesFields'
import {PricingAndAvailability} from './PricingAndAvailability'
import {useGetUpdateOptions, useSubmitActions} from './saveUtils'
import {TicketCheckingFields} from './TicketCheckingFields'
import {WebsiteAndApi} from './WebsiteAndApi'

const EVENT_MAIN_PROPERTIES_FORM_ID = 'event main properties form'

interface IMainEventPropertiesFormProps {
  width: number
  elementToScrollOn: Element | null
}

const useEventMainFormAnchors = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    financial: {
      id: 'financial',
      label: t('Financial')
    },
    websiteAndApi: {
      id: 'websiteAndApi',
      label: t('Website & API')
    },
    pricingAndAvailability: {
      id: 'pricingAndAvailability',
      label: t('Pricing and availability')
    },
    analytics: {
      id: 'analytics',
      label: t('Analytics')
    },
    fees: {
      id: 'fees',
      label: t('Fees')
    },
    ticketChecking: {
      id: 'ticketChecking',
      label: t('Ticket checking')
    },
    notes: {
      id: 'notes',
      label: t('Notes and messages')
    }
  }
}

interface IMainEventPropertiesFormContextProps
  extends IMainEventPropertiesFormProps {
  eventData: DetailEventPropertiesFragment
}

const MainEventPropertiesFormContent: React.FC<IMainEventPropertiesFormContextProps> =
  ({
    width,
    elementToScrollOn,
    eventData
  }: IMainEventPropertiesFormContextProps) => {
    const classes = useCommonFormStyles()
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const history = useHistory()
    const {
      setShowBackdrop,
      defaultErrorHandler,
      addInfoNotification,
      customErrorHandler
    } = useMutationAssistanceHooks()
    const deleteEvent = useDeleteEvent()

    const {eventId} = useEventsPathnameParams()

    const onClose = () => history.replace(routeTo.admin.events.home())
    const handleEventDelete = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await deleteEvent(eventId)
        history.replace(routeTo.admin.events.home())
        addInfoNotification(t('Event was deleted.'))
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CantDeleteEventWithItems
          )
        ) {
          customErrorHandler(error, {
            title: t('Unable to delete event'),
            contentText: t(
              'There were created some items for this event, e.g. sold tickets, refunded tickets, active reservation or there is ongoing cart with some items from this event. You are not able to delete this event.'
            ),
            confirmButtonLabel: t('Got it')
          })
        } else {
          defaultErrorHandler(error, t('Error while deleting event.'))
        }
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      customErrorHandler,
      defaultErrorHandler,
      deleteEvent,
      eventId,
      history,
      setShowBackdrop,
      t
    ])

    const sideMenuItems = useEventMainFormAnchors()

    const drawerHeaderTitle = useDrawerHeaderTitle()

    const formProps = useForm<IMainEventPropertiesFormData>({
      defaultValues: {
        [FormField.DIVISION_ID]: `${eventData.division.id}`,
        [FormField.COST_CENTER_ID]: eventData.costCenterId
          ? `${eventData.costCenterId}`
          : NO_COST_CENTER_SELECTED,
        [FormField.SHOW_ON_WEBSITE_API]: eventData.showOnWebsiteAndApi,
        [FormField.GATE_OPENS_AT]: dayjs(eventData.gateOpensAt),
        [FormField.GATE_CLOSES_AT]: dayjs(eventData.gateClosedAt),
        [FormField.CHECKING_OPTION]: eventData.checkingOption,
        [FormField.ORGANIZER]: eventData.organizerNote,
        [FormField.TICKET_NOTE]: eventData.ticketNote,
        [FormField.EVENT_CATEGORY_ID]: eventData.eventCategoryId
          ? String(eventData.eventCategoryId)
          : NO_EVENT_CATEGORY_SELECTED,
        [FormField.MARKETING_LABEL_ID]: eventData.marketingLabelId
          ? String(eventData.marketingLabelId)
          : NO_MARKETING_LABEL_SELECTED,
        [FormField.BUSINESS_PARTNER_ID]: eventData.businessPartnerId
          ? String(eventData.businessPartnerId)
          : NO_BUSINESS_PARTNER_SELECTED,
        [FormField.FEE_PERCENTAGE_OF_SOLD_TICKETS]: !isNil(
          eventData.feePercentageOfSoldTickets
        )
          ? String(eventData.feePercentageOfSoldTickets)
          : undefined,
        [FormField.FEE_FIXED_AMOUNT_PER_EVENT]: !isNil(
          eventData.feeFixedAmountPerEvent
        )
          ? String(eventData.feeFixedAmountPerEvent)
          : undefined,
        [FormField.FEE_FIXED_AMOUNT_PER_SOLD_TICKET]: !isNil(
          eventData.feeFixedAmountPerSoldTicket
        )
          ? String(eventData.feeFixedAmountPerSoldTicket)
          : undefined,
        [FormField.FEE_MINIMUM_GUARANTEE]: !isNil(eventData.feeMinimumGuarantee)
          ? String(eventData.feeMinimumGuarantee)
          : undefined,
        [FormField.THIRD_PARTY_PURCHASE_URL]:
          eventData.thirdPartyPurchaseURL || undefined
      }
    })
    const {handleSubmit, setError} = formProps
    const {onSubmit, onPublish, onCancel} = useSubmitActions(eventData)
    const UpdateOptions = useGetUpdateOptions({
      handleSubmit,
      onPublish,
      onCancel,
      state: eventData.state
    })
    const handleInvalidDateTimes =
      useHandleInvalidDateTimes<IMainEventPropertiesFormData>(setError)
    return (
      <FormContext {...formProps}>
        <DrawerForm
          title={drawerHeaderTitle}
          wrapperClassName={classes.drawerFormWrapper}
          bodyClassName={classes.drawerForm}
          {...{width, onClose}}
          noWrap
          ActionBar={
            <CustomDrawerActionsBar width={width}>
              {P([PermissionCode.DeleteEvent]) && (
                <>
                  <ButtonWithConfirmationDialog
                    onConfirmButtonClick={handleEventDelete}
                    dialogProps={{
                      title: t('Do you really want to delete the event?'),
                      contentText: t(
                        'Are you sure you want to delete the event?'
                      ),
                      confirmButtonLabel: t('Delete')
                    }}
                    buttonProps={{
                      variant: 'text',
                      color: 'primary',
                      startIcon: <DeleteIcon />,
                      children: t('Delete')
                    }}
                  />
                  <Divider orientation="vertical" />
                </>
              )}
              <Button color="primary" onClick={onClose}>
                {t('Cancel')}
              </Button>
              {P([PermissionCode.UpdateExtendedEventData]) && (
                <SplitButton Options={UpdateOptions}>
                  <Button
                    form={EVENT_MAIN_PROPERTIES_FORM_ID}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {t('Save')}
                  </Button>
                </SplitButton>
              )}
            </CustomDrawerActionsBar>
          }
        >
          <Grid container>
            <div className={classes.stickyNavigationWrapper}>
              <RelativeSideNavigation
                items={sideMenuItems}
                scrollOffset={DRAWER_HEADER_HEIGHT}
                elementToScrollOn={elementToScrollOn}
              />
            </div>
            <form
              className={classes.formWrapper}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleInvalidDateTimes(onSubmit))}
              id={EVENT_MAIN_PROPERTIES_FORM_ID}
            >
              <div id={sideMenuItems.general.id}>
                <GeneralEventInfoCard data={eventData} />
              </div>

              <Typography
                variant="h6"
                id={sideMenuItems.financial.id}
                className={classes.title}
              >
                {sideMenuItems.financial.label}
              </Typography>
              <FinancialFields event={eventData} />

              <Typography
                variant="h6"
                id={sideMenuItems.websiteAndApi.id}
                className={classes.title}
              >
                {sideMenuItems.websiteAndApi.label}
              </Typography>
              <WebsiteAndApi event={eventData} />

              <Typography
                variant="h6"
                id={sideMenuItems.pricingAndAvailability.id}
                className={classes.title}
              >
                {sideMenuItems.pricingAndAvailability.label}
              </Typography>
              <PricingAndAvailability event={eventData} />

              <Typography
                variant="h6"
                id={sideMenuItems.analytics.id}
                className={classes.title}
              >
                {sideMenuItems.analytics.label}
              </Typography>
              <Analytics event={eventData} />

              <Typography
                variant="h6"
                id={sideMenuItems.fees.id}
                className={classes.title}
              >
                {sideMenuItems.fees.label}
              </Typography>
              <Fees />

              <Typography
                variant="h6"
                id={sideMenuItems.ticketChecking.id}
                className={classes.title}
              >
                {sideMenuItems.ticketChecking.label}
              </Typography>
              <TicketCheckingFields event={eventData} hideCheckingOptions />

              <Typography
                variant="h6"
                id={sideMenuItems.notes.id}
                className={classes.title}
              >
                {sideMenuItems.notes.label}
              </Typography>
              <NotesFields />
            </form>
          </Grid>
        </DrawerForm>
      </FormContext>
    )
  }

export const MainEventPropertiesForm: React.FC<IMainEventPropertiesFormProps> =
  ({width, elementToScrollOn}: IMainEventPropertiesFormProps) => {
    return (
      <LoadEventData>
        {(eventData) => (
          <MainEventPropertiesFormContent
            {...{width, elementToScrollOn, eventData}}
          />
        )}
      </LoadEventData>
    )
  }
