import {List} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  AuditoriumLayoutPricingState,
  PermissionCode,
  TicketTypePricingPropertiesFragment
} from '../../../../../../../__generated__/schema'
import {
  useEnsurePermissions,
  useUserInfo
} from '../../../../../../../utils/auth'
import {
  getUnavailableColors,
  TicketColor,
  TicketTypeMenuHeader
} from '../common'
import {useTicketTypeDrawersState} from '../drawerContext'

import {Ticket} from '../Ticket'
import {useGetTicketTypeCounts} from './common'
import {EditTicketTypeDrawer} from './EditDrawer'

interface ITicketTypeListProps {
  ticketTypes: Array<TicketTypePricingPropertiesFragment>
  layoutPricingState: AuditoriumLayoutPricingState
}

export const TicketTypeList: React.FC<ITicketTypeListProps> = ({
  ticketTypes,
  layoutPricingState
}: ITicketTypeListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {effectiveClient} = useUserInfo()

  const ticketTypeCounts = useGetTicketTypeCounts()

  const {
    selectedTicketId,
    openSelectedTicketTypeDrawer,
    closeSelectedTicketTypeDrawer,
    openCreateTicketTypeDrawer
  } = useTicketTypeDrawersState()

  if (!effectiveClient) return null

  const unavailableColors = getUnavailableColors(ticketTypes)

  return (
    <>
      <TicketTypeMenuHeader
        label={t('Ticket type list')}
        onCreateNewTicket={openCreateTicketTypeDrawer}
        hideCreate={layoutPricingState !== AuditoriumLayoutPricingState.Draft}
      />
      <List component="nav">
        {ticketTypes.map(({id, color, ...rest}) => (
          <Ticket
            key={id}
            {...rest}
            color={color as TicketColor}
            count={ticketTypeCounts[id] || 0}
            onClick={() =>
              P([PermissionCode.UpdateTicketType]) ||
              P([PermissionCode.DeleteTicketType])
                ? openSelectedTicketTypeDrawer(id)
                : undefined
            }
          />
        ))}
      </List>
      <EditTicketTypeDrawer
        layoutPricingState={layoutPricingState}
        open={selectedTicketId != null}
        onClose={closeSelectedTicketTypeDrawer}
        unavailableColors={unavailableColors}
        ticketType={
          selectedTicketId
            ? ticketTypes.find(({id}) => id === selectedTicketId)
            : undefined
        }
      />
    </>
  )
}
