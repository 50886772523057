import React, {ReactNode, useContext} from 'react'

import {useCallbackRef} from '../../hooks/callbackRef'

type SideBarRefContext = {
  callbackRef: (node: HTMLElement) => void
  htmlNode: HTMLElement | null
}

export const Context = React.createContext({} as SideBarRefContext)

type Props = {
  children: ReactNode
}

export const SideBarRefProvider: React.FC<Props> = ({children}: Props) => {
  const callbackRefState = useCallbackRef()

  return (
    <Context.Provider value={callbackRefState}>{children}</Context.Provider>
  )
}

export const useSideBarRef = () => useContext(Context)
