import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useExpirationToNowLabel, useGetExpirationToNowColor} from '../utils'

const useExpirationToNowCellStyles = makeStyles(() => ({
  text: {
    display: 'inline-block',
    '&:first-letter': {
      textTransform: 'capitalize'
    },
    lineHeight: '100%'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '100%'
  },
  icon: {
    lineHeight: '100%',
    marginLeft: -1
  }
}))

interface IExpirationToNowCellProps {
  expireAt: string
  daysCountBetweenTodayAndExpirationDate: number
}

export const ExpirationToNowCell: React.FC<IExpirationToNowCellProps> = ({
  expireAt,
  daysCountBetweenTodayAndExpirationDate
}: IExpirationToNowCellProps) => {
  const classes = useExpirationToNowCellStyles()
  const getExpirationToNowColor = useGetExpirationToNowColor({
    isWithBackground: false
  })
  const expirationToNowLabel = useExpirationToNowLabel({
    expireAt,
    daysCountBetweenTodayAndExpirationDate
  })
  return (
    <div
      className={cn(
        classes.root,
        getExpirationToNowColor(daysCountBetweenTodayAndExpirationDate)
      )}
    >
      <Typography variant="caption" className={classes.icon} color="inherit">
        <FiberManualRecordIcon fontSize="inherit" />
      </Typography>
      &nbsp;
      <Typography variant="caption" color="inherit" className={classes.text}>
        {expirationToNowLabel}
      </Typography>
    </div>
  )
}

const useExpirationCellStyles = makeStyles(() => ({
  text: {
    textTransform: 'uppercase'
  }
}))

interface IExpirationCellProps {
  expireAt: string
  daysCountBetweenTodayAndExpirationDate: number
}

export const ExpirationCell: React.FC<IExpirationCellProps> = ({
  expireAt,
  daysCountBetweenTodayAndExpirationDate
}: IExpirationCellProps) => {
  const classes = useExpirationCellStyles()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const expireAtDate = new Date(expireAt)
  return (
    <Typography
      variant="caption"
      className={classes.text}
      color="textSecondary"
    >
      {daysCountBetweenTodayAndExpirationDate < 7
        ? formatTime(expireAtDate)
        : formatDateNumeric(expireAtDate)}
    </Typography>
  )
}
