import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  TourTimeSlotsStatisticsFilterInput
} from '../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../hooks/formatUserName'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../utils/auth'
import {safeSum} from '../../../../utils/money'
import {routeTo} from '../../../../utils/routes'
import {
  DataGridTable,
  useAgeClassificationFormatter,
  useDataGridPagination,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useGetTranslatedValueFormatter,
  useVersionCodeFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {PaymentDateChip} from '../components/PaymentDateChip'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {useGetFilterDateRange} from '../utils'
import {useTourTimeSlotsStatistics} from './graphql'
import {StateChip} from './StateChip'
import {TimeSlotStateChip} from './types'
import {getTourTimeSlotsStates} from './utils'

const IconCellRenderer = ({id, isPinned}: {id: number; isPinned: boolean}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(
        routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsRevenues(
          id
        )
      ),
    [history, id]
  )
  return isPinned ? null : (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IRevenuesProps {
  searchFilter: TourTimeSlotsStatisticsFilterInput
}

export const Revenues: React.FC<IRevenuesProps> = ({
  searchFilter
}: IRevenuesProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [selectedTourTimeSlotState, setSelectedTourTimeSlotState] =
    useState<TimeSlotStateChip>(TimeSlotStateChip.Published)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.Today
    }
  )
  const [userId, setUserId] = useState<number | null>(null)
  const [selectedPaymentDate, setSelectedPaymentDate] =
    useState<IDataPickerData | undefined>(undefined)
  const getFilterDateRange = useGetFilterDateRange()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useTourTimeSlotsStatistics({
    filter: {
      tourTimeSlotStates: getTourTimeSlotsStates(selectedTourTimeSlotState),
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'startsAtFrom',
        filterNameTo: 'startsAtTo'
      }),
      ...getFilterDateRange({
        date: selectedPaymentDate,
        filterNameFrom: 'paymentDateTimeFrom',
        filterNameTo: 'paymentDateTimeTo'
      }),
      paymentCreatedByIds: userId ? [userId] : undefined,
      ...searchFilter
    },
    paginationInput
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const versionCodeFormatter = useVersionCodeFormatter()
  const ageClassificationFormatter = useAgeClassificationFormatter()
  const formatShortGuideName = useFormatShortGuideName(true)
  const getTranslatedValueFormatter = useGetTranslatedValueFormatter()
  const handleStateChange = useCallback(
    (state: TimeSlotStateChip) => {
      setSelectedTourTimeSlotState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handlePaymentDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedPaymentDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Starts at'),
        field: 'startsAt',
        sortable: false,
        minWidth: 200,
        valueFormatter: dateTimeFormatter
      },
      {
        headerName: t('Name'),
        field: 'names',
        sortable: false,
        minWidth: 350,
        valueFormatter: getTranslatedValueFormatter
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'revenue',
        sortable: false,
        minWidth: 100,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Quantity'),
        field: 'revenueTicketCount',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Attendees'),
        field: 'revenueAttendeesCount',
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'saleSum',
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? safeSum([
                params.row.saleSumOnRetailChannel,
                params.row.saleSumOnECommerceChannel
              ])
            : safeSum([
                data?.tourTimeSlotsStatistics.totals.saleSumOnRetailChannel,
                data?.tourTimeSlotsStatistics.totals.saleSumOnECommerceChannel
              ]),
        sortable: false,
        minWidth: 100,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Quantity'),
        field: 'saleTicketCount',
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? safeSum([
                params.row.saleTicketCountOnRetailChannel,
                params.row.saleTicketCountOnECommerceChannel
              ])
            : safeSum([
                data?.tourTimeSlotsStatistics.totals
                  .saleTicketCountOnRetailChannel,
                data?.tourTimeSlotsStatistics.totals
                  .saleTicketCountOnECommerceChannel
              ]),
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Attendees'),
        field: 'saleAttendeesCount',
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? safeSum([
                params.row.saleAttendeesCountOnRetailChannel,
                params.row.saleAttendeesCountOnECommerceChannel
              ])
            : safeSum([
                data?.tourTimeSlotsStatistics.totals
                  .saleAttendeesCountOnRetailChannel,
                data?.tourTimeSlotsStatistics.totals
                  .saleAttendeesCountOnECommerceChannel
              ]),
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'refundSum',
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? safeSum([
                params.row.refundSumOnRetailChannel,
                params.row.refundSumOnECommerceChannel
              ])
            : safeSum([
                data?.tourTimeSlotsStatistics.totals.refundSumOnRetailChannel,
                data?.tourTimeSlotsStatistics.totals.refundSumOnECommerceChannel
              ]),
        sortable: false,
        minWidth: 100,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Quantity'),
        field: 'refundTicketCount',
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? safeSum([
                params.row.refundTicketCountOnRetailChannel,
                params.row.refundTicketCountOnECommerceChannel
              ])
            : safeSum([
                data?.tourTimeSlotsStatistics.totals
                  .refundTicketCountOnRetailChannel,
                data?.tourTimeSlotsStatistics.totals
                  .refundTicketCountOnECommerceChannel
              ]),
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Attendees'),
        field: 'refundAttendeesCount',
        valueGetter: (params) =>
          params.rowNode.type !== 'pinnedRow'
            ? safeSum([
                params.row.refundAttendeesCountOnRetailChannel,
                params.row.refundAttendeesCountOnECommerceChannel
              ])
            : safeSum([
                data?.tourTimeSlotsStatistics.totals
                  .refundAttendeesCountOnRetailChannel,
                data?.tourTimeSlotsStatistics.totals
                  .refundAttendeesCountOnECommerceChannel
              ]),
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right'
      },
      {
        headerName: t('Venue'),
        field: 'venue',
        minWidth: 250,
        valueGetter: (params) => params.row.venue,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Division'),
        field: 'division',
        minWidth: 250,
        valueGetter: (params) => params.row.division,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Version'),
        field: 'versionCode',
        minWidth: 250,
        valueFormatter: versionCodeFormatter,
        sortable: false
      },
      {
        headerName: t('Age restriction'),
        field: 'ageClassificationCode',
        minWidth: 250,
        valueFormatter: ageClassificationFormatter,
        sortable: false
      },
      {
        headerName: t('Guide'),
        field: 'guide',
        minWidth: 200,
        valueGetter: (params) => params.row.guide,
        valueFormatter: (params) =>
          params.value ? formatShortGuideName(params.value) : '',
        sortable: false
      },
      {
        headerName: t('Cost center'),
        field: 'costCenter',
        minWidth: 250,
        valueGetter: (params) => params.row.costCenter,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Event category'),
        field: 'eventCategory',
        minWidth: 250,
        valueGetter: (params) => params.row.eventCategory,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Marketing label'),
        field: 'marketingLabel',
        minWidth: 250,
        valueGetter: (params) => params.row.marketingLabel,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Tour'),
        field: 'tourName',
        minWidth: 250,
        valueGetter: (params) => params.row.tour,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.tourTimeSlotId,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              id={params.value}
              isPinned={params.rowNode.type === 'pinnedRow'}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      ageClassificationFormatter,
      data?.tourTimeSlotsStatistics.totals
        .refundAttendeesCountOnECommerceChannel,
      data?.tourTimeSlotsStatistics.totals.refundAttendeesCountOnRetailChannel,
      data?.tourTimeSlotsStatistics.totals.refundSumOnECommerceChannel,
      data?.tourTimeSlotsStatistics.totals.refundSumOnRetailChannel,
      data?.tourTimeSlotsStatistics.totals.refundTicketCountOnECommerceChannel,
      data?.tourTimeSlotsStatistics.totals.refundTicketCountOnRetailChannel,
      data?.tourTimeSlotsStatistics.totals.saleAttendeesCountOnECommerceChannel,
      data?.tourTimeSlotsStatistics.totals.saleAttendeesCountOnRetailChannel,
      data?.tourTimeSlotsStatistics.totals.saleSumOnECommerceChannel,
      data?.tourTimeSlotsStatistics.totals.saleSumOnRetailChannel,
      data?.tourTimeSlotsStatistics.totals.saleTicketCountOnECommerceChannel,
      data?.tourTimeSlotsStatistics.totals.saleTicketCountOnRetailChannel,
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      formatShortGuideName,
      getTranslatedValueFormatter,
      t,
      translatedEffectiveClientCurrencySign,
      versionCodeFormatter
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading tour time slots statistics')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <SubHeaderToolbar
        title={t('Revenues')}
        leftActions={[
          <StateChip
            key="state-chip"
            state={selectedTourTimeSlotState}
            onChange={handleStateChange}
          />,
          P([PermissionCode.ReadUsers]) ? (
            <UserChip
              key="user-chip"
              onChange={setUserId}
              nameFormatter={(firstName, lastName) =>
                `${lastName} ${firstName.charAt(0)}.`
              }
            />
          ) : (
            []
          ),
          <PaymentDateChip
            key="payment-date-chip"
            selectedDate={selectedPaymentDate}
            setSelectedDate={handlePaymentDateSelect}
            popperSx={(theme) => ({zIndex: theme.zIndex.drawer - 1})}
          />
        ].filter(Boolean)}
        rightActions={[
          <DatePickerButton
            key="tour-time-slot-date-picker"
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.Today
            }}
            tooltip={t('Tour time slot date')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            }
          }}
          columns={columns}
          loading={loading}
          rows={data?.tourTimeSlotsStatistics.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          getRowId={(row) => row.tourTimeSlotId}
          initialState={{
            pinnedColumns: {left: ['startsAt'], right: ['arrow']}
          }}
          columnVisibilityModel={{
            arrow: P([PermissionCode.ReadAdmissionTypesStatistics])
          }}
          columnGroupingModel={[
            {
              groupId: t('Time slot'),
              children: [{field: 'names'}]
            },
            {
              groupId: t('Summary'),
              children: [
                {field: 'revenue'},
                {field: 'revenueTicketCount'},
                {field: 'revenueAttendeesCount'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Sales'),
              children: [
                {
                  field: 'saleSum'
                },
                {field: 'saleTicketCount'},
                {field: 'saleAttendeesCount'}
              ]
            },
            {
              groupId: t('Refunds'),
              children: [
                {
                  field: 'refundSum'
                },
                {field: 'refundTicketCount'},
                {field: 'refundAttendeesCount'}
              ]
            },
            {
              groupId: t('Time slot details'),
              children: [
                {field: 'venue'},
                {field: 'division'},
                {field: 'versionCode'},
                {field: 'ageClassificationCode'},
                {field: 'guide'},
                {field: 'costCenter'},
                {field: 'eventCategory'},
                {field: 'marketingLabel'},
                {field: 'tourName'}
              ],
              headerAlign: 'left'
            }
          ]}
          pinnedRows={{
            bottom: [
              {
                tourTimeSlotId: 0,
                revenue: data?.tourTimeSlotsStatistics.totals.revenue,
                revenueTicketCount:
                  data?.tourTimeSlotsStatistics.totals.revenueTicketCount,
                revenueAttendeesCount:
                  data?.tourTimeSlotsStatistics.totals.revenueAttendeesCount
              }
            ]
          }}
          {...getDataGridPaginationProps(
            data?.tourTimeSlotsStatistics.pagination
          )}
        />
      </Box>
    </Box>
  )
}
