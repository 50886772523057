import {InputAdornment, OutlinedInputProps} from '@mui/material'
import React from 'react'
import {CurrencySignPosition} from '../types'

import {useRequireEffectiveClient} from './requireEffectiveClient'
import {
  useGetCurrencySignPosition,
  useTranslatedEffectiveClientCurrencySign
} from './translateCurrencies'

export const useEffectiveClientCurrencySignInputProps = (): Pick<
  OutlinedInputProps,
  'startAdornment' | 'endAdornment'
> => {
  const effectiveClient = useRequireEffectiveClient()
  const currencySignPosition = useGetCurrencySignPosition(
    effectiveClient.currency
  )
  const translatedCurrencySign = useTranslatedEffectiveClientCurrencySign()
  return {
    [currencySignPosition === CurrencySignPosition.START
      ? 'startAdornment'
      : 'endAdornment']: (
      <InputAdornment position={currencySignPosition}>
        {translatedCurrencySign}
      </InputAdornment>
    )
  }
}
