import CancelIcon from '@mui/icons-material/Cancel'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ShowVideoSource} from '../../../__generated__/schema'
import {useBooleanState} from '../../../hooks/state'
import {useTranslateShowVideoSource} from '../../../hooks/translateShowVideoSource'
import {useIsValidURL} from '../../../utils/formsValidations'
import {
  getVideoSource,
  vimeoIdLength,
  youtubeIdLength
} from '../../../utils/getVideoSource'
import {UncontrolledFormTextInput} from '../../form/FormTextInput'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../form/UncontrolledFormRadioGroup'
import {DialogTitleWithCloseButton} from '../DialogTitleWithCloseButton'
import {InputRow} from '../FormHelpers'
import {AddVideoFormField, IAddVideoForm, IAddVideoFormResult} from './types'
import {useHandleValidationError} from './utils'

const ADD_VIDEO_FORM_ID = 'addVideoForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(1.5)
}))

const VideoExampleList: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Box>
      <Typography variant="subtitle1">{t('Youtube video examples')}</Typography>
      <Typography component="div" variant="subtitle2">
        <ul>
          <li>
            {t('Full URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://www.youtube.com/watch?v=abc123xyz
            </Typography>
          </li>
          <li>
            {t('Video identifier')}&nbsp;
            <Typography variant="body2" component="span">
              {t("part after 'v=' in a YouTube URL like “abc123xyz”")}
            </Typography>
          </li>
          <li>
            {t('Shortened URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://youtu.be/abc123xyz
            </Typography>
          </li>
          <li>
            {t('Embedded URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://www.youtube.com/embed/abc123xyz
            </Typography>
          </li>
          <li>
            {t('Mobile app URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://m.youtube.com/watch?v=abc123xyz
            </Typography>
          </li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">{t('Vimeo video examples')}</Typography>
      <Typography component="div" variant="subtitle2">
        <ul>
          <li>
            {t('Full URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://vimeo.com/123456789
            </Typography>
          </li>
          <li>
            {t('Video identifier')}&nbsp;
            <Typography variant="body2" component="span">
              {t('unique number at the end of a Vimeo URL like “123456789”')}
            </Typography>
          </li>
          <li>
            {t('Embedded URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://player.vimeo.com/video/123456789
            </Typography>
          </li>
          <li>
            {t('Mobile app URL')}&nbsp;
            <Typography variant="body2" component="span">
              https://vimeo.com/m/123456789
            </Typography>
          </li>
        </ul>
      </Typography>
    </Box>
  )
}

interface IAddVideoDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (result: IAddVideoFormResult) => void
  defaultValues?: Partial<IAddVideoForm>
}

export const AddVideoDialog: React.FC<IAddVideoDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  defaultValues
}: IAddVideoDialogProps) => {
  const {t} = useTranslation()
  const {
    state: isVideoProviderRadioGroupVisible,
    setTrue: setVideoProviderRadioGroupVisible,
    setFalse: setVideoProviderRadioGroupHidden
  } = useBooleanState(false)
  const {
    register,
    errors,
    setValue,
    triggerValidation,
    watch,
    control,
    handleSubmit,
    clearError,
    setError
  } = useForm<IAddVideoForm>({defaultValues, reValidateMode: 'onBlur'})
  const isValidUrl = useIsValidURL(false, false)
  const handleValidationError = useHandleValidationError()
  const translateShowVideoSource = useTranslateShowVideoSource()
  const watchedUrl = watch(AddVideoFormField.Url)
  const hasUrlOnlyIdentifier =
    watchedUrl &&
    (watchedUrl.length === youtubeIdLength ||
      watchedUrl.length === vimeoIdLength)
  useEffect(() => {
    if (
      watchedUrl &&
      (watchedUrl.length === youtubeIdLength ||
        watchedUrl.length === vimeoIdLength)
    ) {
      setVideoProviderRadioGroupVisible()
      if (watchedUrl.length === youtubeIdLength) {
        setValue(AddVideoFormField.Provider, ShowVideoSource.Youtube)
      } else {
        setValue(AddVideoFormField.Provider, ShowVideoSource.Vimeo)
      }
      clearError(AddVideoFormField.Provider)
    }
  }, [clearError, setValue, setVideoProviderRadioGroupVisible, watchedUrl])
  useEffect(() => {
    if (
      isVideoProviderRadioGroupVisible &&
      watchedUrl &&
      !(
        watchedUrl.length === youtubeIdLength ||
        watchedUrl.length === vimeoIdLength
      )
    ) {
      setVideoProviderRadioGroupHidden()
      setValue(AddVideoFormField.Provider, undefined)
    }
  }, [
    isVideoProviderRadioGroupVisible,
    setValue,
    setVideoProviderRadioGroupHidden,
    watchedUrl
  ])
  const _handleSubmit = useCallback(
    (formData: IAddVideoForm) => {
      if (
        formData.provider &&
        (formData.url.length === youtubeIdLength ||
          formData.url.length === vimeoIdLength)
      ) {
        onSubmit({
          identifier: formData.url,
          source: formData.provider
        })
      } else {
        try {
          const {source, identifier} = getVideoSource(formData.url)
          onSubmit({
            source,
            identifier
          })
        } catch (error) {
          handleValidationError({error, setError})
        }
      }
    },
    [handleValidationError, onSubmit, setError]
  )
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 600}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add video')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <StyledForm
          id={ADD_VIDEO_FORM_ID}
          onSubmit={handleSubmit(_handleSubmit)}
        >
          <Typography variant="body1">
            {t(
              "You can easily add a video from YouTube or Vimeo. Once you provide the link or identifier, we'll process it accordingly. Please paste your video link or identifier below."
            )}
          </Typography>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IAddVideoForm>
                fullWidth
                label={t('Video URL or unique identifier')}
                name={AddVideoFormField.Url}
                key={AddVideoFormField.Url}
                register={register}
                errors={errors}
                setValue={setValue}
                triggerValidation={triggerValidation}
                watch={watch}
                required
                validationOptions={{
                  required: true,
                  validate: hasUrlOnlyIdentifier
                    ? {}
                    : {
                        isValidUrl
                      }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() => setValue(AddVideoFormField.Url, '')}
                    >
                      <CancelIcon />
                    </IconButton>
                  )
                }}
              />
            ]}
          />
          <Box
            sx={{
              display: isVideoProviderRadioGroupVisible ? 'none' : 'initial'
            }}
          >
            <VideoExampleList />
          </Box>
          <Box
            sx={{
              display: isVideoProviderRadioGroupVisible ? 'initial' : 'none'
            }}
          >
            <InputRow
              nodes={[
                <UncontrolledFormRadioGroup<IAddVideoForm, ShowVideoSource>
                  label={t('Select video provider')}
                  name={AddVideoFormField.Provider}
                  key={AddVideoFormField.Provider}
                  errors={errors}
                  validationOptions={{
                    required: isVideoProviderRadioGroupVisible
                  }}
                  fullWidth
                  control={control}
                  options={[
                    {
                      value: ShowVideoSource.Youtube,
                      label: (
                        <BasicRadioLabel
                          primaryText={translateShowVideoSource(
                            ShowVideoSource.Youtube
                          )}
                        />
                      )
                    },
                    {
                      value: ShowVideoSource.Vimeo,
                      label: (
                        <BasicRadioLabel
                          primaryText={translateShowVideoSource(
                            ShowVideoSource.Vimeo
                          )}
                        />
                      )
                    }
                  ]}
                />
              ]}
            />
          </Box>
        </StyledForm>
      </DialogContent>
      <DialogActions sx={{px: 3, py: 1.5}}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={ADD_VIDEO_FORM_ID}
        >
          {t('Next')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
