import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {Box, IconButton, Typography} from '@mui/material'
import React from 'react'
import {ListItemID} from './types'

export interface IIdListItem<T extends ListItemID = number> {
  id: T
  firstRow: string
  secondRow?: string
  thirdRow?: string
}

interface IIdListItemProps<T extends ListItemID> extends IIdListItem<T> {
  onDelete: (id: T) => void
}

export const IdListItem = <T extends ListItemID>({
  id,
  firstRow,
  secondRow,
  thirdRow,
  onDelete
}: IIdListItemProps<T>) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: `
          "firstRow deleteButton"
          ${secondRow ? '"secondRow deleteButton"' : ''}
          ${thirdRow ? '"thirdRow deleteButton"' : ''}
        `,
        gridTemplateColumns: '1fr auto',
        alignItems: 'center'
      }}
    >
      <Typography>{firstRow}</Typography>
      {secondRow && (
        <Typography variant="caption" color="textSecondary">
          {secondRow}
        </Typography>
      )}
      {thirdRow && (
        <Typography variant="caption" color="textSecondary">
          {thirdRow}
        </Typography>
      )}
      <IconButton
        onClick={() => onDelete(id)}
        sx={{gridArea: 'deleteButton'}}
        color="primary"
        edge="end"
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Box>
  )
}
