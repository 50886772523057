import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InputRow} from '../../../../common'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {ISelectFooterForm, SelectFooterFormField} from '../types'
import {useWebsitePageContext} from './WebsitePageContext'

const SELECT_FOOTER_FORM_ID = 'selectFooterForm'

interface ISelectFooterDialogProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (data: ISelectFooterForm) => void
}

export const SelectFooterDialog: React.FC<ISelectFooterDialogProps> = ({
  isOpen,
  onClose,
  onSubmit
}: ISelectFooterDialogProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, handleSubmit} =
    useForm<ISelectFooterForm>()
  const {websiteFooters, selectedWebsitePage} = useWebsitePageContext()
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 560}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add footer')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <form id={SELECT_FOOTER_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <InputRow
            nodes={[
              <UncontrolledFormSelect<ISelectFooterForm>
                errors={errors}
                key={SelectFooterFormField.Id}
                name={SelectFooterFormField.Id}
                fullWidth
                selectOptions={websiteFooters
                  .filter(
                    (footer) =>
                      footer.localeCode === selectedWebsitePage?.localeCode
                  )
                  .reduce(
                    (acc, footer) => ({
                      ...acc,
                      [footer.ID]: footer.ID
                    }),
                    {}
                  )}
                label={t('Footer ID')}
                validationOptions={{
                  required: true
                }}
                required
                register={register}
                watch={watch}
                setValue={setValue}
              />
            ]}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={SELECT_FOOTER_FORM_ID}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
