import Button from '@mui/material/Button'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  DetailLibraryShowPropertiesFragment,
  LibraryShowQuery
} from '../../../../__generated__/schema'

import {Theme} from '../../../../theme'
import {RenderOnData} from '../../../common'
import {useGetLibraryShow} from './graphql'

const useStyles = makeStyles<Theme>((theme) => ({
  errorButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
}))
interface ILibraryShowDetailProviderProps {
  onClose(): void
  showId: number
  children(show: DetailLibraryShowPropertiesFragment): React.ReactElement
}

export const LibraryShowDetailProvider: React.FC<ILibraryShowDetailProviderProps> =
  ({onClose, showId, children}: ILibraryShowDetailProviderProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {loading, error, data} = useGetLibraryShow(showId)
    return (
      <>
        <RenderOnData
          data={data}
          loading={loading}
          error={error}
          dataCondition={(data?: LibraryShowQuery) =>
            Boolean(data?.libraryShow)
          }
          errorMessage={t<string>('Could not load library show detail.')}
        >
          {(data: LibraryShowQuery) => children(data.libraryShow)}
        </RenderOnData>
        {error && (
          <div className={classes.errorButtonBox}>
            <Button autoFocus onClick={onClose} color="primary">
              {t('Close')}
            </Button>
          </div>
        )}
      </>
    )
  }
