import {ApolloError} from 'apollo-client'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {
  getParsedItemFromStorage,
  LocalStorageKey
} from '../../../../../hooks/storage'
import {IPosTerminalSettings} from '../../../../../types'

export const getPosTerminalAppSettings = () =>
  getParsedItemFromStorage<IPosTerminalSettings>(
    localStorage,
    LocalStorageKey.PosTerminal
  )

export const getPosTerminalAppPort = (): number | null | undefined =>
  getPosTerminalAppSettings()?.port

export const useShowPosTerminalAppSettingsError = () => {
  const {t} = useTranslation()
  const {customErrorHandler} = useMutationAssistanceHooks()
  return useCallback(
    (
      error = new ApolloError({
        errorMessage: "Can't connect to the POS terminal app"
      })
    ) => {
      customErrorHandler(error, {
        title: t("Can't connect to the POS terminal app"),
        contentText: t(
          'We were unable to establish connection to the POS terminal app. Please check your device settings'
        ),
        confirmButtonLabel: t('Got it')
      })
    },
    [customErrorHandler, t]
  )
}
