import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {Button, IconButton} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ToursFilter,
  ToursQuery,
  TourState
} from '../../../../__generated__/schema'
import {useTranslateTourState} from '../../../../hooks/tourState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination
} from '../../../common/DataGridTable'
import {tourStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {WideCenteredLayout} from '../Layout'
import {useTours} from './graphql'

const StateCellRenderer = ({value}: {value?: TourState}) => {
  const translateTourState = useTranslateTourState()
  return (
    <>
      {value && (
        <EntityStateChip
          label={translateTourState(value)}
          colorConf={tourStateColors[value]}
          isDotHidden
        />
      )}
    </>
  )
}

const EditCellRenderer = ({value}: {value?: number}) => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleIconClick = useCallback(
    (id: number) => () => history.push(routeTo.admin.tours.detail(id)),
    [history]
  )
  return (
    <>
      {value && P([PermissionCode.ReadTour]) && (
        <IconButton size="small" onClick={handleIconClick(value)}>
          <KeyboardArrowRightIcon />
        </IconButton>
      )}
    </>
  )
}

interface IToursListProps {
  onCreateButtonClick: () => void
  searchFilter: ToursFilter
}

export const ToursList: React.FC<IToursListProps> = ({
  onCreateButtonClick,
  searchFilter
}: IToursListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error} = useTours({
    filter: {...searchFilter},
    paginationInput
  })
  const hasPermissionToCreateTour = P([
    PermissionCode.CreateTour,
    PermissionCode.ReadClientShows
  ])
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        sortable: false,
        flex: 1
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: StateCellRenderer,
        sortable: false,
        flex: 0.5
      },
      {
        headerName: t('Division'),
        field: 'division',
        valueGetter: (params) => params.row.division?.name,
        sortable: false,
        flex: 1
      },
      {
        headerName: t('Internal note'),
        field: 'internalNote',
        sortable: false,
        flex: 1
      },
      {
        headerName: '',
        field: 'id',
        renderCell: EditCellRenderer,
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 32
      }
    ],
    [t]
  )
  return (
    <WideCenteredLayout
      sx={{
        height: `calc(100% - 64px)`,
        width: '100%',
        p: 3
      }}
    >
      <RenderOnData<ToursQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading tours')}
        ignoreLoadingIfData
      >
        {({tours}) =>
          tours.items.length > 0 ? (
            <DataGridTable
              columns={columns}
              loading={loading}
              rows={tours.items}
              disableColumnMenu
              initialState={{pinnedColumns: {left: ['name'], right: ['id']}}}
              columnVisibilityModel={{
                id: P([PermissionCode.ReadTour])
              }}
              {...getDataGridPaginationProps(tours.pagination)}
            />
          ) : (
            <Blank
              title={t('No tours found')}
              actions={
                hasPermissionToCreateTour && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={onCreateButtonClick}
                  >
                    {t('Create')}
                  </Button>
                )
              }
            />
          )
        }
      </RenderOnData>
      {hasPermissionToCreateTour &&
        (data?.tours.items || []).length > 0 &&
        !loading && (
          <CreateFab classes={fabClasses} onClick={onCreateButtonClick} />
        )}
    </WideCenteredLayout>
  )
}
