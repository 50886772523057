import {Drawer, RadioGroup} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EcommercePaymentMethodInput,
  EcommercePaymentMethodType,
  PaymentServiceProvider,
  RetailPaymentMethodInput,
  RetailPaymentMethodType,
  SellingChannel
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {
  useTranslateRetailPaymentMethodType,
  useTranslateRetailPaymentMethodTypeSubLabel
} from '../../../../hooks/translatePaymentMethodType'
import {Theme} from '../../../../theme'
import {useUserInfo} from '../../../../utils/auth'

import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {CancelButton, SaveButton} from '../../../common/Buttons'
import {RadioGroupItem} from '../../../common/RadioGroupItem'
import {useShowFormStyles} from '../shows/ShowForm'
import {
  useCreateECommercePaymentMethod,
  useCreateRetailPaymentMethod
} from './graphql'
import {PaymentMethodForm, PaymentMethodFormLocation} from './PaymentMethodForm'
import {
  IPaymentMethodForm,
  PaymentMethodField,
  transformPaymentMethodFormIntoInput
} from './types'

const CREATE_PAYMENT_METHOD_FORM_ID = 'createPaymentMethodFormId'

enum DrawerState {
  SELECT_PAYMENT_METHOD_TYPE,
  FILL_CREATE_PAYMENT_METHOD_FORM
}

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  radioGroup: {
    padding: theme.spacing(3)
  }
}))

const isEcommercePaymentMethodInput = (
  input: RetailPaymentMethodInput | EcommercePaymentMethodInput,
  channel: SellingChannel
): input is EcommercePaymentMethodInput => channel === SellingChannel.ECommerce

interface ICreatePaymentMethodDrawerProps {
  isOpen: boolean
  closeDrawer: () => void
  channel: SellingChannel
}

export const CreatePaymentMethodDrawer: React.FC<ICreatePaymentMethodDrawerProps> =
  ({isOpen, closeDrawer, channel}: ICreatePaymentMethodDrawerProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const [drawerState, setDrawerState] = useState<DrawerState>(
      DrawerState.SELECT_PAYMENT_METHOD_TYPE
    )
    const [selectedPaymentMethodType, setSelectedPaymentMethodType] =
      useState<RetailPaymentMethodType | EcommercePaymentMethodType | null>(
        null
      )
    const onRadioGroupChange = useCallback((_event, value) => {
      setSelectedPaymentMethodType(value)
    }, [])

    const handleExited = useCallback(() => {
      setDrawerState(DrawerState.SELECT_PAYMENT_METHOD_TYPE)
      setSelectedPaymentMethodType(null)
    }, [])

    const translateRetailPaymentMethodType =
      useTranslateRetailPaymentMethodType()
    const translateRetailPaymentMethodTypeSubLabel =
      useTranslateRetailPaymentMethodTypeSubLabel()

    const options = Object.values(RetailPaymentMethodType).map((type) => ({
      label: translateRetailPaymentMethodType(type),
      subLabel: translateRetailPaymentMethodTypeSubLabel(type),
      value: type
    }))
    const showFormClasses = useShowFormStyles()
    const {effectiveClient} = useUserInfo()
    const createRetailPaymentMethod = useCreateRetailPaymentMethod()
    const createECommercePaymentMethod = useCreateECommercePaymentMethod()
    const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
      useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (form: IPaymentMethodForm) => {
        setShowBackdrop(true)
        try {
          const input = transformPaymentMethodFormIntoInput(form)
          if (isEcommercePaymentMethodInput(input, channel)) {
            await createECommercePaymentMethod({input})
          } else {
            await createRetailPaymentMethod(
              form[PaymentMethodField.IS_IN_HOUSE_VOUCHER]
                ? {
                    input,
                    paymentServiceProviderConfigInput: {
                      type: PaymentServiceProvider.InHouseVoucher
                    }
                  }
                : {input}
            )
          }
          addInfoNotification(t('Payment method created'))
          closeDrawer()
        } catch (e) {
          defaultErrorHandler(e, t('Error while creating payment method'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        channel,
        closeDrawer,
        createECommercePaymentMethod,
        createRetailPaymentMethod,
        defaultErrorHandler,
        setShowBackdrop,
        t
      ]
    )
    if (!effectiveClient) {
      return null
    }
    return (
      <Drawer
        onClose={closeDrawer}
        open={isOpen}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
        SlideProps={{
          onExited: handleExited
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Create payment method')}
            />
          }
          footer={
            drawerState === DrawerState.SELECT_PAYMENT_METHOD_TYPE ? (
              <>
                <CancelButton onClick={closeDrawer} />
                <SaveButton
                  form={CREATE_PAYMENT_METHOD_FORM_ID}
                  disabled={!selectedPaymentMethodType}
                  onClick={() => {
                    setDrawerState(DrawerState.FILL_CREATE_PAYMENT_METHOD_FORM)
                  }}
                >
                  {t('Next')}
                </SaveButton>
              </>
            ) : (
              <>
                <CancelButton
                  onClick={() => {
                    setDrawerState(DrawerState.SELECT_PAYMENT_METHOD_TYPE)
                  }}
                >
                  {t('Back')}
                </CancelButton>
                <SaveButton type="submit" form={CREATE_PAYMENT_METHOD_FORM_ID}>
                  {t('Create')}
                </SaveButton>
              </>
            )
          }
        >
          {drawerState === DrawerState.SELECT_PAYMENT_METHOD_TYPE ? (
            <RadioGroup
              onChange={onRadioGroupChange}
              classes={{root: classes.radioGroup}}
              value={selectedPaymentMethodType}
            >
              {options.map((option) => (
                <RadioGroupItem
                  key={option.value}
                  {...option}
                  selectedValue={selectedPaymentMethodType}
                />
              ))}
            </RadioGroup>
          ) : (
            <PaymentMethodForm
              location={PaymentMethodFormLocation.CreatePayment}
              className={showFormClasses.form}
              formId={CREATE_PAYMENT_METHOD_FORM_ID}
              defaultValues={{
                [PaymentMethodField.TYPE]: selectedPaymentMethodType!,
                [PaymentMethodField.CURRENCY]: effectiveClient.currency,
                [PaymentMethodField.HAS_DENOMINATION]: false,
                [PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT]: false,
                [PaymentMethodField.REDUCES_BILL_TOTAL]: false,
                [PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]: false,
                [PaymentMethodField.IS_IN_HOUSE_VOUCHER]: false
              }}
              onSubmit={handleSubmit}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
