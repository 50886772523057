import {AxiosRequestConfig} from 'axios'

export function getTerminalConfig<D = any>({
  port,
  ...config
}: AxiosRequestConfig<D> & {
  port: number
}): AxiosRequestConfig<D> {
  return {
    baseURL: `http://127.0.0.1:${port}/`,
    timeout: 30000,
    timeoutErrorMessage: 'Request timed out',
    ...config
  }
}
