import {v4 as uuid} from 'uuid'
import {
  AuditoriumPreviewFieldsFragment,
  CartPropertiesFragment,
  CashDeskEventFragment,
  CashDeskTourTimeSlotQuery,
  Scalars
} from '../../../../__generated__/schema'
import {
  CustomerDisplayMessage,
  CustomerDisplayMessageType
} from '../../../../customerDisplayBroadcastChannel/types'

export enum CustomerDisplayView {
  BaseView = 'basicView',
  EventAuditoriumView = 'eventAuditoriumView',
  TourTimeSlotView = 'tourTimeSlotView',
  CashdeskEventsView = 'cashdeskEventsView'
}

export interface ICustomerDisplayPageState {
  cart: CartPropertiesFragment | null
  currentView: CustomerDisplayView
  event: AuditoriumPreviewFieldsFragment | null
  tourTimeSlot: CashDeskTourTimeSlotQuery['tourTimeSlot'] | null
  cashdeskEvents: CashDeskEventFragment[] | null
  eventSeats: Scalars['JSON'] | null
  pageUuid: string
}

export const getDefaultCustomerDisplayPageState = () => ({
  pageUuid: uuid(),
  cart: null,
  event: null,
  tourTimeSlot: null,
  cashdeskEvents: null,
  eventSeats: null,
  currentView: CustomerDisplayView.BaseView
})

export const customerDisplayPageReducer = (
  state: ICustomerDisplayPageState = getDefaultCustomerDisplayPageState(),
  action?: CustomerDisplayMessage
) => {
  switch (action?.type) {
    case CustomerDisplayMessageType.CurrentCartChange:
      return {
        ...state,
        cart: action.payload
      }
    case CustomerDisplayMessageType.DisplayEventAuditoriumView:
      return {
        ...state,
        ...action.payload,
        currentView: CustomerDisplayView.EventAuditoriumView
      }
    case CustomerDisplayMessageType.DisplayBaseView:
      return {
        ...state,
        currentView: CustomerDisplayView.BaseView
      }
    case CustomerDisplayMessageType.DisplayCashdeskEventsView:
      return {
        ...state,
        cashdeskEvents: action.payload,
        currentView: CustomerDisplayView.CashdeskEventsView
      }
    case CustomerDisplayMessageType.UserLoggedOut:
      return {
        ...getDefaultCustomerDisplayPageState(),
        pageUuid: state.pageUuid
      }
    case CustomerDisplayMessageType.DisplayTourTimeSlotView:
      return {
        ...state,
        tourTimeSlot: action.payload,
        currentView: CustomerDisplayView.TourTimeSlotView
      }
    default:
      return state
  }
}
