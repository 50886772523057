import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../theme'

import {
  INotification,
  NotificationType,
  useNotifications
} from '../context/notifications'
import {ConfirmationDialog} from './ConfirmationDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  messageWrapper: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2)
  }
}))

interface INotificationProps {
  notification: INotification
  onClose: () => void
}

const DialogNotification: React.FC<INotificationProps> = ({
  notification,
  onClose
}: INotificationProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <Dialog open>
      <DialogTitle>
        <Grid container alignItems="center">
          {t('Oops something went wrong ...')}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" className={classes.messageWrapper}>
          <Typography>{notification.message}</Typography>
        </Grid>
        <DialogContentText>
          {notification.subText ||
            t(
              'Please try to reload the page and try again. If the error persists, please contact our support.'
            )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} autoFocus>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useSnackbarStyles = makeStyles<Theme>((theme) => ({
  close: {
    padding: theme.spacing(0.5)
  }
}))

export const SnackbarNotification: React.FC<INotificationProps> = ({
  notification,
  onClose
}: INotificationProps) => {
  const classes = useSnackbarStyles()

  return (
    <Snackbar
      key={notification.id}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open
      autoHideDuration={6000}
      onClose={onClose}
      message={<Typography>{notification.message}</Typography>}
      action={[
        <IconButton
          key="close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
      cypress-id="snack-bar"
    />
  )
}

export const Notifications: React.FC = () => {
  const {notifications, removeNotification} = useNotifications()

  const getOnCloseHandler = useCallback(
    (id: string) => () => removeNotification(id),
    [removeNotification]
  )

  return (
    <>
      {notifications.map((notification) => {
        switch (notification.type) {
          case NotificationType.ERROR:
            return (
              <DialogNotification
                notification={notification}
                key={notification.id}
                onClose={getOnCloseHandler(notification.id)}
              />
            )
          case NotificationType.INFO:
            return (
              <SnackbarNotification
                notification={notification}
                key={notification.id}
                onClose={getOnCloseHandler(notification.id)}
              />
            )
          case NotificationType.ErrorWithCustomDialog:
            return (
              <ConfirmationDialog
                isOpen
                key={notification.id}
                {...notification.dialogProps}
                onCancel={
                  notification.dialogProps.onCancel
                    ? () => {
                        notification.dialogProps.onCancel?.()
                        getOnCloseHandler(notification.id)()
                      }
                    : undefined
                }
                onConfirm={() => {
                  notification.dialogProps.onConfirm?.()
                  getOnCloseHandler(notification.id)()
                }}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}
