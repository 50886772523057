import {Box, Divider, Grid} from '@mui/material'
import {ApolloError} from 'apollo-client'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {TicketTypeInput} from '../../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../../hooks/effectiveClientCurrencySignInputProps'
import {convertValueToFloat} from '../../../../../../utils/conversions'
import {useIsTicketPriceValid} from '../../../../../../utils/formsValidations'
import {InputRow} from '../../../../../common'
import {FormSwitchWithLabels} from '../../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {FormInput, ValidationError} from '../../../../../visual'
import {VenuesDrawerForm} from '../../common'
import {ColorPicker} from './ColorPicker'
import {TicketColor} from './common'

export const TICKET_FORM_ID = 'ticket form'

export enum TicketFormField {
  PRICE = 'price',
  AreDiscountsEnabled = 'areDiscountsEnabled'
}

export interface ITicketFormData {
  name: string
  description?: string | undefined | null
  price?: string | undefined | null
  color: TicketColor
  [TicketFormField.AreDiscountsEnabled]: boolean
}

export const ticketTypeFromTicketTypeForm = (
  data: ITicketFormData
): TicketTypeInput => {
  return {
    ...data,
    color: data.color as TicketColor,
    price: convertValueToFloat(data.price)
  }
}

interface ITicketFormProps {
  title: string
  onSubmit: (data: ITicketFormData) => Promise<void>
  onError: (err: ApolloError) => any
  defaultValues?: ITicketFormData
  ActionBar: React.ReactNode
  onClose: () => void
  unavailableColors: Array<TicketColor>
}

const Error = ({children}: {children: React.ReactNode}) => {
  return (
    <Box marginLeft="16px" marginBottom="8px">
      <ValidationError>{children}</ValidationError>
    </Box>
  )
}

export const TicketForm: React.FC<ITicketFormProps> = ({
  title,
  onSubmit,
  onError,
  defaultValues,
  ActionBar,
  onClose,
  unavailableColors
}: ITicketFormProps) => {
  const {t} = useTranslation()
  const isValidTicketPrice = useIsTicketPriceValid()
  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  const [currentColor, setCurrentColor] = useState<TicketColor | undefined>(
    defaultValues?.color
  )
  const [currentColorError, setCurrentColorError] = useState<boolean>(false)

  const config = defaultValues ? {defaultValues} : {}
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    triggerValidation,
    control,
    watch
  } = useForm<ITicketFormData>(config)

  const _onSubmit = async (data: ITicketFormData) => {
    if (currentColor) {
      const _data = {...data, color: currentColor}
      try {
        await onSubmit(_data)
      } catch (err) {
        onError(err)
      }
    } else {
      setCurrentColorError(true)
    }
  }

  const onColorChange = (value: TicketColor) => {
    if (currentColorError) {
      setCurrentColorError(false)
    }
    setCurrentColor(value)
  }

  return (
    <VenuesDrawerForm {...{title, ActionBar, onClose}}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(_onSubmit)}
        id={TICKET_FORM_ID}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <FormInput
              label={t('label')}
              placeholder={t('label')}
              name="name"
              type="text"
              showRequiredLabel
              inputRef={register({required: true})}
            />
          </Grid>
          <Grid item>
            <FormInput
              label={t('Description')}
              placeholder={t('Description')}
              name="description"
              type="text"
              multiline
              rows={4}
              inputRef={register({required: false})}
            />
          </Grid>
          <Grid item>
            <UncontrolledFormTextInput<ITicketFormData>
              InputProps={currencySignInputProps}
              key={TicketFormField.PRICE}
              label={t('Default price')}
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              validationOptions={{validate: isValidTicketPrice}}
              triggerValidation={triggerValidation}
              name={TicketFormField.PRICE}
              fullWidth
            />
          </Grid>
          <Grid item>
            <InputRow
              nodes={[
                <FormSwitchWithLabels<ITicketFormData>
                  name={TicketFormField.AreDiscountsEnabled}
                  key={TicketFormField.AreDiscountsEnabled}
                  primaryLabel={t('Enabled discounts')}
                  control={control}
                  watch={watch}
                  defaultValue={defaultValues?.areDiscountsEnabled || true}
                  secondaryLabel=""
                  enabledText=""
                  disabledText=""
                />
              ]}
            />
          </Grid>
        </Grid>
        <Box marginTop="16px" />
        <Divider />
        <ColorPicker
          unavailableColors={unavailableColors}
          onChange={onColorChange}
          defaultValue={defaultValues && defaultValues.color}
        />
        {currentColorError && <Error>{t('Color is required!')}</Error>}
        <Divider />
      </form>
    </VenuesDrawerForm>
  )
}
