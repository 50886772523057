import {WarehouseDocumentSource} from '../../../../__generated__/schema'

export enum DocumentTypeOption {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  StockWriteOff = 'stockWriteOff'
}

export enum WarehouseDocumentFormField {
  WarehouseId = 'warehouseId',
  Type = 'type',
  Source = 'source',
  ThirdPartyDocumentId = 'thirdPartyDocumentId',
  Note = 'note',
  DeliveryDate = 'deliveryDate',
  BusinessPartnerId = 'businessPartnerId'
}

export interface IWarehouseDocumentForm {
  [WarehouseDocumentFormField.WarehouseId]: number
  [WarehouseDocumentFormField.Type]: DocumentTypeOption
  [WarehouseDocumentFormField.Source]: WarehouseDocumentSource
  [WarehouseDocumentFormField.ThirdPartyDocumentId]?: string
  [WarehouseDocumentFormField.Note]?: string
  [WarehouseDocumentFormField.DeliveryDate]: string
  [WarehouseDocumentFormField.BusinessPartnerId]?: number
}

export enum WarehouseDocumentFormType {
  Create = 'create',
  Update = 'update'
}
