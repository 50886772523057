import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import Decimal from 'decimal.js'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {ButtonWithConfirmationDialog} from '../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {Blank, BlankContentPosition} from '../../../../visual/Blank'
import {isProductItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {useRemoveAllProductItemsFromCart} from './graphql'
import {ProductListItemWithRemoveButton} from './ProductListItemWithRemoveButton'

interface ISubCartProps {
  className?: string
  onProductItemQuantityDecrement: (
    productItemId: number,
    decrement: number
  ) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr 64px',
    width: '100%',
    background: theme.palette.background.paper,
    borderRight: `solid ${theme.palette.divider} 1px`
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  },
  subTotal: {
    display: 'grid',
    gridTemplateAreas: `
      "subTotalLabel count"
      "subTotalLabel price"
    `,
    borderTop: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    padding: theme.spacing(0, 2)
  },
  subTotalLabel: {
    gridArea: 'subTotalLabel'
  },
  subTotalCount: {
    alignSelf: 'flex-end'
  },
  subTotalPrice: {
    alignSelf: 'flex-start'
  },
  list: {
    overflow: 'auto'
  },
  divider: {
    margin: theme.spacing(0, 1.5)
  }
}))

export const SubCart: React.FC<ISubCartProps> = ({
  className,
  onProductItemQuantityDecrement
}: ISubCartProps) => {
  const classes = useStyles()
  const {currentCart, updateCurrentCart, initializeCurrentCart} =
    useCurrentCart()
  const removeAllProductItemsFromCart = useRemoveAllProductItemsFromCart()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const currentCartProductItems = (currentCart?.items || [])
    .filter(isProductItemPropertiesFragment)
    .reverse()

  const getRemoveButtonClickHandler = useCallback(
    (productItemId: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onProductItemQuantityDecrement(productItemId, 1)
    },
    [onProductItemQuantityDecrement]
  )
  const handleRemoveAllProductItemsClick = useCallback(async () => {
    if (currentCart?.id) {
      try {
        setShowBackdrop(true)
        const {data} = await removeAllProductItemsFromCart({
          cartId: currentCart.id
        })
        addInfoNotification(t('Products removed'))
        if (data?.removeAllProductItemsFromCart) {
          updateCurrentCart()
        } else {
          initializeCurrentCart(null)
        }
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while removeing all product items from cart')
        )
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    addInfoNotification,
    currentCart?.id,
    defaultErrorHandler,
    initializeCurrentCart,
    removeAllProductItemsFromCart,
    setShowBackdrop,
    t,
    updateCurrentCart
  ])
  const history = useHistory()
  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.header}>
        {P([PermissionCode.RemoveAllProductItemsFromCart]) && (
          <ButtonWithConfirmationDialog
            buttonProps={{
              children: t('Remove'),
              disabled: currentCartProductItems.length === 0,
              startIcon: <RemoveShoppingCartIcon />
            }}
            onConfirmButtonClick={handleRemoveAllProductItemsClick}
            dialogProps={{
              title: t('Remove products?'),
              contentText: t(
                'Do you really want to remove all products from cart?'
              ),
              confirmButtonLabel: t('Remove')
            }}
          />
        )}
      </div>
      {currentCartProductItems.length > 0 ? (
        <ListOfItemsSeparatedByDividers
          className={classes.list}
          DividerProps={{
            className: classes.divider
          }}
        >
          {currentCartProductItems
            .sort((itemA, itemB) => itemA.id - itemB.id)
            .map((item) => (
              <ProductListItemWithRemoveButton
                key={item.id}
                quantity={item.quantity}
                price={translateEffectiveClientPrice(item.price)}
                description={translateEffectiveClientPrice(item.unitPrice)}
                title={item.name}
                onRemoveButtonClick={getRemoveButtonClickHandler(item.id)}
                onClick={() => {
                  history.replace(
                    routeTo.admin.cashDesk.shopWithOpenedQuantityDialog(
                      item.productId
                    )
                  )
                }}
              />
            ))}
        </ListOfItemsSeparatedByDividers>
      ) : (
        <Blank
          title={t('Add  first product to cart')}
          contentPosition={BlankContentPosition.Center}
        />
      )}
      <div className={classes.subTotal}>
        <Typography className={classes.subTotalLabel} variant="subtitle1">
          {t('Subtotal')}
        </Typography>
        <Typography
          className={classes.subTotalCount}
          variant="caption"
          color="textSecondary"
          align="right"
        >
          {t('{{count}} product', {
            count: currentCartProductItems.length
          })}
        </Typography>
        <Typography
          className={classes.subTotalPrice}
          variant="subtitle1"
          align="right"
        >
          {translateEffectiveClientPrice(
            currentCartProductItems
              .reduce((acc, item) => acc.add(item.price), new Decimal(0))
              .toNumber()
          )}
        </Typography>
      </div>
    </div>
  )
}
