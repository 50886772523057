import CancelIcon from '@mui/icons-material/Cancel'
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  adornedEnd: {
    paddingRight: theme.spacing(0.5)
  }
}))

type OutlinedInputWithCancelAdornmentProps = (
  | {
      label: string
      inputId: string
    }
  | {
      label?: never
      inputId?: never
    }
) & {
  onCancelClick?: () => void
  inputProps?: TextFieldProps
  errorMessage?: string | null
  /**
   * @deprecated use sx prop
   */
  className?: string
  sx?: SxProps
  errorSx?: SxProps
}

export const OutlinedInputWithCancelAdornment: React.FC<OutlinedInputWithCancelAdornmentProps> =
  ({
    label,
    inputProps = {},
    onCancelClick,
    inputId,
    errorMessage,
    sx,
    errorSx,
    className
  }: OutlinedInputWithCancelAdornmentProps) => {
    const classes = useStyles()
    return (
      <>
        <TextField
          className={className}
          sx={sx}
          error={!!errorMessage}
          id={inputId}
          label={label}
          InputProps={{
            classes: {
              adornedEnd: classes.adornedEnd
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onCancelClick}>
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          {...inputProps}
        />
        {errorMessage && (
          <FormHelperText sx={errorSx} error>
            {errorMessage}
          </FormHelperText>
        )}
      </>
    )
  }
