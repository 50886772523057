import SettingsIcon from '@mui/icons-material/Settings'
import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {Blank} from '../../../../visual/Blank'
import {usePostDisplayBaseViewMessageOnInit} from '../../customerDisplay/postDisplayBaseViewMessageOnInit'
import {Content} from './Content'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingLeft: theme.spacing(9),
    height: '100%'
  }
}))

export const Shop: React.FC = () => {
  const {t} = useTranslation()
  usePostDisplayBaseViewMessageOnInit()
  const classes = useStyles()
  const [divisionId] = useLocalStorageState<null | number>(
    LocalStorageKey.CashDeskShopDivisionId,
    null
  )
  const history = useHistory()
  const {P} = useEnsurePermissions()

  return (
    <div className={classes.root}>
      {divisionId ? (
        <Content divisionId={divisionId} />
      ) : (
        <Blank
          IconComp={SettingsIcon}
          title={t('Division is not set')}
          description={t(
            'Set division in device settings first to display products.'
          )}
          actions={
            P([PermissionCode.ChangeDeviceSettings]) ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(
                    `${routeTo.admin.cashDesk.settings()}#shopSettings`
                  )
                }}
              >
                {t('Device settings')}
              </Button>
            ) : null
          }
        />
      )}
    </div>
  )
}
