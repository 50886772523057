import gql from 'graphql-tag'
import {useMutation} from '@apollo/react-hooks'

import {
  UpdateSalesEventDataMutation,
  UpdateSalesEventDataMutationVariables
} from '../../../../../../__generated__/schema'

import {DETAIL_EVENT_PROPERTIES_FRAGMENT} from '../../graphql'

const UPDATE_SALES_EVENT_DATA = gql`
  mutation UpdateSalesEventData(
    $id: Int!
    $data: EventSaleAndReservationInput!
  ) {
    updateSalesEventData(id: $id, data: $data) {
      ...DetailEventProperties
    }
  }
  ${DETAIL_EVENT_PROPERTIES_FRAGMENT}
`

export const useUpdateSalesEventData = () => {
  const [updateEvent] = useMutation<
    UpdateSalesEventDataMutation,
    UpdateSalesEventDataMutationVariables
  >(UPDATE_SALES_EVENT_DATA)

  return ({id, data}: UpdateSalesEventDataMutationVariables) =>
    updateEvent({variables: {id, data}})
}
