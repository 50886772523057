import Konva from 'konva'

export type StageRef = Konva.Stage | null

export interface IRefsState {
  stageRef: StageRef
}

const initialState: IRefsState = {
  stageRef: null
}

export enum RefsActionType {
  SET_STAGE_REF = 'set stage ref'
}

interface ISetStageRefAction {
  type: typeof RefsActionType.SET_STAGE_REF
  payload: StageRef
}

type RefsAction = ISetStageRefAction

export const refsReducer = (
  state = initialState,
  action: RefsAction
): IRefsState => {
  switch (action.type) {
    case RefsActionType.SET_STAGE_REF: {
      return {...state, stageRef: action.payload}
    }
    default:
      return state
  }
}
