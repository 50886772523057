import {LeadDataInput} from '../../../../../__generated__/schema'

export enum CustomerFormField {
  Username = 'username',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  LoyaltyId = 'loyaltyId',
  IsPrivacyPolicyConsentGranted = 'isPrivacyPolicyConsentGranted',
  LeadData = 'leadData',
  LoyaltyLevelExpirationDate = 'loyaltyLevelExpirationDate'
}

export interface ICustomerForm {
  [CustomerFormField.Username]: string
  [CustomerFormField.Password]: string
  [CustomerFormField.ConfirmPassword]: string
  [CustomerFormField.LoyaltyId]?: string
  [CustomerFormField.IsPrivacyPolicyConsentGranted]: boolean
  [CustomerFormField.LeadData]: LeadDataInput
  [CustomerFormField.LoyaltyLevelExpirationDate]?: string
}

export enum CustomerFormLocation {
  Create = 'create',
  Edit = 'edit'
}
