import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {omit} from 'lodash'
import React, {useCallback, useEffect} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  LocaleCode,
  ProductQuery,
  ProductState
} from '../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../hooks/getLocales'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {useProductParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useGetProduct, useUpdateProduct} from './graphql'
import {ProductForm} from './ProductForm'
import {IProductForm, ProductFormField, ProductFormLocation} from './types'

const UPDATE_PRODUCT_FORM_ID = 'updateProductForm'

const getEcommerceFieldsDefaultValues = (
  clientLocales: LocaleCode[],
  product?: ProductQuery['product']
) =>
  clientLocales.reduce(
    (acc, locale) => ({
      ...acc,
      [`${ProductFormField.ECommerceNames}[${locale}]`]: product?.eCommerceNames
        ? product.eCommerceNames[locale]
        : undefined,
      [`${ProductFormField.ECommerceDescriptions}[${locale}]`]:
        product?.eCommerceDescriptions
          ? product.eCommerceDescriptions[locale]
          : undefined
    }),
    {}
  )

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface IUpdateProductDrawerProps {
  onExited: () => void
}

export const UpdateProductDrawer: React.FC<IUpdateProductDrawerProps> = ({
  onExited
}: IUpdateProductDrawerProps) => {
  const {t} = useTranslation()
  const {productId} = useProductParams()
  const {data, loading, error} = useGetProduct(productId)
  const updateProduct = useUpdateProduct()
  const {
    addInfoNotification,
    setShowBackdrop,
    defaultErrorHandler,
    customErrorHandler
  } = useMutationAssistanceHooks()
  const clientLocales = useGetClientLocales()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    if (productId) {
      openDrawer()
    }
  }, [productId, openDrawer])
  const methods = useForm<IProductForm>()
  const {reset, handleSubmit, setError} = methods
  useEffect(() => {
    if (data) {
      reset({
        [ProductFormField.Name]: data.product.name,
        [ProductFormField.Unit]: data.product.unit,
        [ProductFormField.ProductTypeId]: String(data.product.productType.id),
        [ProductFormField.PriceLookupCode]:
          data.product.priceLookupCode || undefined,
        [ProductFormField.InternalDescription]:
          data.product.internalDescription || undefined,
        [ProductFormField.ReceiptName]: data.product.receiptName || undefined,
        [ProductFormField.InternalCode]: data.product.internalCode || undefined,
        [ProductFormField.Mode]: data.product.mode,
        ...getEcommerceFieldsDefaultValues(clientLocales, data.product)
      })
    }
  }, [clientLocales, data, reset])
  const classes = useStyles()

  const handleProductUpdateError = useCallback(
    (error) => {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.CanNotChangeModeOfProductWithStock
        )
      ) {
        customErrorHandler(error, {
          title: t('Product mode change is not allowed'),
          contentText: t(
            'The operation could not be completed because the product currently has stock or has negative stock in at least one warehouse. To change the mode, please ensure that the stock levels are zero. This requirement helps maintain inventory accuracy.'
          ),
          confirmButtonLabel: t('Got it')
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidProductTypeId
        )
      ) {
        setError(
          ProductFormField.ProductTypeId,
          t('Select product type in active state')
        )
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.PriceLookupCodeAlreadyExists
        )
      ) {
        setError(
          ProductFormField.PriceLookupCode,
          t('This PLU code is already used in another product.')
        )
      } else {
        defaultErrorHandler(error, t('Error while updating product'))
      }
    },
    [customErrorHandler, defaultErrorHandler, setError, t]
  )

  const handleProductUpdate = useCallback(
    async (data: IProductForm) => {
      try {
        setShowBackdrop(true)
        await updateProduct({
          id: productId,
          input: {
            ...omit(data, ProductFormField.Unit),
            internalCode: data[ProductFormField.InternalCode] || null,
            internalDescription:
              data[ProductFormField.InternalDescription] || null,
            receiptName: data[ProductFormField.ReceiptName] || null,
            priceLookupCode: data[ProductFormField.PriceLookupCode] || null,
            productTypeId: parseInt(data[ProductFormField.ProductTypeId], 10)
          }
        })
        addInfoNotification(t('Product updated'))
        onExited()
      } catch (error) {
        handleProductUpdateError(error)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      handleProductUpdateError,
      onExited,
      productId,
      setShowBackdrop,
      t,
      updateProduct
    ]
  )
  const saveAs = useCallback(
    (state: ProductState) => async (data: IProductForm) => {
      try {
        setShowBackdrop(true)
        await updateProduct({
          id: productId,
          input: {
            ...omit(data, ProductFormField.Unit),
            internalCode: data[ProductFormField.InternalCode] || null,
            internalDescription:
              data[ProductFormField.InternalDescription] || null,
            receiptName: data[ProductFormField.ReceiptName] || null,
            priceLookupCode: data[ProductFormField.PriceLookupCode] || null,
            productTypeId: parseInt(data[ProductFormField.ProductTypeId], 10),
            state
          }
        })
        addInfoNotification(t('Product updated'))
        onExited()
      } catch (error) {
        handleProductUpdateError(error)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      handleProductUpdateError,
      onExited,
      productId,
      setShowBackdrop,
      t,
      updateProduct
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t<string>('Error while loading product')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Update product')}
          />
        }
        footer={
          <SplitButton
            Options={[
              (data?.product.state === ProductState.Active ||
                data?.product.state === ProductState.Disabled) && (
                <MenuItem
                  key={1}
                  label={t('Save as inactive')}
                  onClick={handleSubmit(saveAs(ProductState.Inactive))}
                />
              ),
              (data?.product.state === ProductState.Active ||
                data?.product.state === ProductState.Inactive) && (
                <MenuItem
                  key={2}
                  label={t('Save as disabled')}
                  onClick={handleSubmit(saveAs(ProductState.Disabled))}
                />
              ),
              (data?.product.state === ProductState.Disabled ||
                data?.product.state === ProductState.Inactive) && (
                <MenuItem
                  key={3}
                  label={t('Save as active')}
                  onClick={handleSubmit(saveAs(ProductState.Active))}
                />
              )
            ].filter(Boolean)}
          >
            <SaveButton type="submit" form={UPDATE_PRODUCT_FORM_ID}>
              {t('Save')}
            </SaveButton>
          </SplitButton>
        }
        childrenClassName={classes.drawerChildren}
      >
        {data && (
          <FormContext {...methods}>
            <ProductForm
              location={ProductFormLocation.EditProduct}
              formId={UPDATE_PRODUCT_FORM_ID}
              onSubmit={handleProductUpdate}
              product={data.product}
              isAdditionalSettingsExpanded
              isEcommerceSettingsExpanded
            />
          </FormContext>
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
