import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  VoucherCampaignQuery
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useVoucherParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {VerticalDivider} from '../../../../common/VerticalDivider'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useGetVoucherCampaign} from '../graphql'
import {EditVoucherCampaignDrawer} from './EditVoucherCampaignDrawer'
import {GeneralSection} from './GeneralSection'
import {PaymentMethodsSection} from './PaymentMethodsSection'

const useItems = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    paymentMethods: {
      id: 'paymentMethods',
      label: t('Payment methods')
    }
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(3)
  }
}))

export const VoucherCampaignDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {voucherCampaignId} = useVoucherParams()
  const {data, loading, error} = useGetVoucherCampaign(voucherCampaignId)
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.voucherCampaigns.index()),
    [history]
  )
  const handleExited = useCallback(
    () =>
      history.push(routeTo.admin.voucherCampaigns.detail(voucherCampaignId)),
    [history, voucherCampaignId]
  )
  const handleEditButtonClick = useCallback(
    () => history.push(routeTo.admin.voucherCampaigns.edit(voucherCampaignId)),
    [history, voucherCampaignId]
  )
  const items = useItems()
  const classes = useStyles()
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={t('Voucher campaign')}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        <Footer>
          <Button variant="text" color="primary" startIcon={<DeleteIcon />}>
            {t('Delete')}
          </Button>
          {P([PermissionCode.ChangeVoucherCampaignState]) && (
            <>
              <VerticalDivider />
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditButtonClick}
              >
                {t('Edit')}
              </Button>
            </>
          )}
        </Footer>
      }
    >
      <RenderOnData<VoucherCampaignQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading voucher campaign')}
        dataCondition={(data) => Boolean(data.voucherCampaign)}
        ignoreLoadingIfData
      >
        {({voucherCampaign}) => (
          <CenteredLayout>
            <SingleSideNavigationList items={items} />
            <div className={classes.sections}>
              <GeneralSection
                id={items.general.id}
                voucherCampaign={voucherCampaign}
              />
              <PaymentMethodsSection
                id={items.paymentMethods.id}
                voucherCampaign={voucherCampaign}
              />
            </div>
          </CenteredLayout>
        )}
      </RenderOnData>
      {P([PermissionCode.ReadVoucherCampaign]) && (
        <Route
          path={routeTo.admin.voucherCampaigns.edit(':voucherCampaignId')}
          exact
        >
          <EditVoucherCampaignDrawer onExited={handleExited} />
        </Route>
      )}
    </PageWithHeaderAndFooterTemplate>
  )
}
