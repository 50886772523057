import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'
import {
  Box,
  Divider,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import {last} from 'lodash'
import React, {useCallback, useState} from 'react'
import DraggableList from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {FooterItemAlignment} from '../../../../../../__generated__/schema'
import {Menu, MenuItem, useMenu} from '../../../../../common/Menu'
import {
  AddMenuItem,
  FooterItemWithId,
  IItemForm,
  ItemFormField,
  ItemGroupWithId
} from '../../types'
import {getAddMenuItemType} from '../../utils'
import {AddItemDialog} from '../AddItemDialog'
import {EditItemDialog} from '../EditItemDialog'
import {DraggableItem, IItemCommonProps} from './Item'

interface IItemGroupProps {
  itemGroup: ItemGroupWithId
  dragHandle: React.ReactElement
  setItemGroups: React.Dispatch<React.SetStateAction<ItemGroupWithId[]>>
}

export const ItemGroup: React.FC<IItemGroupProps> = ({
  itemGroup,
  dragHandle,
  setItemGroups
}: IItemGroupProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const [selectedAddItem, setSelectedAddItem] =
    useState<AddMenuItem | undefined>(undefined)
  const [selectedItemIdToEdit, setSelectedItemIdToEdit] =
    useState<string | null>(null)
  const handleMenuItemClick = useCallback(
    (item: AddMenuItem) => () => {
      setSelectedAddItem(item)
      closeMenu()
    },
    [closeMenu]
  )
  const selectedItemToEdit = itemGroup.items.find(
    ({id}) => id === selectedItemIdToEdit
  )
  const handleAddItem = useCallback(
    (formData: IItemForm) => {
      const href = formData[ItemFormField.Page]
        ? formData[ItemFormField.Type] === AddMenuItem.Page
          ? formData[ItemFormField.Page] === 'homepage'
            ? '/'
            : `/${formData[ItemFormField.Page]}`
          : formData[ItemFormField.Page]
        : null
      setItemGroups((prevItemGroups) =>
        prevItemGroups.map((prevItemGroup) =>
          itemGroup.id === prevItemGroup.id
            ? {
                ...prevItemGroup,
                items: [
                  ...prevItemGroup.items,
                  {
                    id: uuidv4(),
                    label: formData[ItemFormField.Label],
                    typographyVariant:
                      formData[ItemFormField.Variant] || undefined,
                    link: href,
                    linkTarget: formData[ItemFormField.OpenInNewTab]
                      ? '_blank'
                      : undefined,
                    icon: formData[ItemFormField.Icon] || undefined
                  }
                ]
              }
            : prevItemGroup
        )
      )
    },
    [itemGroup.id, setItemGroups]
  )
  const handleEditItem = useCallback(
    (formData: IItemForm) => {
      const href = formData[ItemFormField.Page]
        ? formData[ItemFormField.Type] === AddMenuItem.Page
          ? formData[ItemFormField.Page] === 'homepage'
            ? '/'
            : `/${formData[ItemFormField.Page]}`
          : formData[ItemFormField.Page]
        : null
      setItemGroups((prevItemGroups) =>
        prevItemGroups.map((prevItemGroup) =>
          itemGroup.id === prevItemGroup.id
            ? {
                ...prevItemGroup,
                items: prevItemGroup.items.map((item) =>
                  item.id === selectedItemIdToEdit
                    ? {
                        id: uuidv4(),
                        label: formData[ItemFormField.Label],
                        typographyVariant:
                          formData[ItemFormField.Variant] || undefined,
                        link: href,
                        linkTarget: formData[ItemFormField.OpenInNewTab]
                          ? '_blank'
                          : undefined,
                        icon: formData[ItemFormField.Icon] || undefined
                      }
                    : item
                )
              }
            : prevItemGroup
        )
      )
    },
    [itemGroup.id, selectedItemIdToEdit, setItemGroups]
  )
  const handleDeleteItemGroup = useCallback(
    () =>
      setItemGroups((prevItemGroups) =>
        prevItemGroups.filter(
          (prevItemGroup) => prevItemGroup.id !== itemGroup.id
        )
      ),
    [itemGroup.id, setItemGroups]
  )
  const handleAlignment = useCallback(
    (_, newAlignment: FooterItemAlignment) => {
      if (newAlignment) {
        setItemGroups((prevItemGroups) =>
          prevItemGroups.map((prevItemGroup) =>
            prevItemGroup.id === itemGroup.id
              ? {
                  ...prevItemGroup,
                  alignment: newAlignment
                }
              : prevItemGroup
          )
        )
      }
    },
    [itemGroup.id, setItemGroups]
  )
  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 2,
          minHeight: 256
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1
          }}
        >
          {dragHandle}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            <ToggleButtonGroup
              value={itemGroup.alignment}
              exclusive
              onChange={handleAlignment}
              size="small"
              color="primary"
              sx={{pr: 1}}
            >
              <ToggleButton value={FooterItemAlignment.Left}>
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton value={FooterItemAlignment.Center}>
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton value={FooterItemAlignment.Right}>
                <FormatAlignRightIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton color="primary" onClick={handleDeleteItemGroup}>
              <DeleteIcon />
            </IconButton>
            <IconButton color="primary" onClick={openMenu}>
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <DraggableList<FooterItemWithId, IItemCommonProps, DraggableItem>
          list={itemGroup.items}
          itemKey="id"
          template={DraggableItem}
          padding={4}
          constrainDrag
          onMoveEnd={(items: readonly FooterItemWithId[]) =>
            setItemGroups((prevItemGroups) =>
              prevItemGroups.map((prevItemGroup) =>
                prevItemGroup.id === itemGroup.id
                  ? {
                      ...prevItemGroup,
                      items: [...items]
                    }
                  : prevItemGroup
              )
            )
          }
          commonProps={{
            getDeleteClickHandler: (id: string) => () =>
              setItemGroups((prevItemGroups) =>
                prevItemGroups.map((prevItemGroup) =>
                  prevItemGroup.id === itemGroup.id
                    ? {
                        ...prevItemGroup,
                        items: prevItemGroup.items.filter(
                          (item) => item.id !== id
                        )
                      }
                    : prevItemGroup
                )
              ),
            getEditClickHandler: (id: string) => () =>
              setSelectedItemIdToEdit(id),
            isLastItem: (id: string) => last(itemGroup.items)?.id === id,
            alignment: itemGroup.alignment
          }}
        />
      </Paper>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          label={t('Page')}
          sx={{flexFlow: 'row-reverse'}}
          iconSx={{justifyContent: 'center'}}
          onClick={handleMenuItemClick(AddMenuItem.Page)}
        />
        <MenuItem
          label={t('External link')}
          sx={{flexFlow: 'row-reverse'}}
          iconSx={{justifyContent: 'center'}}
          onClick={handleMenuItemClick(AddMenuItem.ExternalLink)}
        />
        <MenuItem
          label={t('Label')}
          sx={{flexFlow: 'row-reverse'}}
          iconSx={{justifyContent: 'center'}}
          onClick={handleMenuItemClick(AddMenuItem.Label)}
        />
      </Menu>
      <AddItemDialog
        type={selectedAddItem}
        onSubmit={handleAddItem}
        onClose={() => setSelectedAddItem(undefined)}
        showVariant
        showIcon
      />
      {selectedItemToEdit && (
        <EditItemDialog
          isOpen={Boolean(selectedItemIdToEdit)}
          type={getAddMenuItemType(selectedItemToEdit.link)}
          onSubmit={handleEditItem}
          onClose={() => setSelectedItemIdToEdit(null)}
          defaultValues={{
            [ItemFormField.Label]: selectedItemToEdit.label,
            [ItemFormField.Page]:
              selectedItemToEdit.link === '/'
                ? 'homepage'
                : typeof selectedItemToEdit.link === 'string' &&
                  selectedItemToEdit.link.startsWith('/')
                ? selectedItemToEdit.link.substring(1)
                : selectedItemToEdit.link,
            [ItemFormField.OpenInNewTab]:
              selectedItemToEdit.linkTarget === '_blank',
            [ItemFormField.Variant]:
              selectedItemToEdit.typographyVariant || undefined,
            [ItemFormField.Icon]: selectedItemToEdit.icon || undefined
          }}
          showVariant
          showIcon
        />
      )}
    </>
  )
}
