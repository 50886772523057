import EditIcon from '@mui/icons-material/Edit'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  CostCentersQuery,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {DataGridTable} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {COLOR_CONF} from '../../../constants'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateCostCenterDrawer} from './CreateCostCenterDrawer'
import {useGetCostCenters} from './graphql'
import {UpdateCostCenterDrawer} from './UpdateCostCenterDrawer'

const StateRenderer = ({isActive}: {isActive: boolean}) => {
  const {t} = useTranslation()
  return (
    <EntityStateChip
      colorConf={isActive ? COLOR_CONF.GREEN : COLOR_CONF.RED}
      label={isActive ? t('Active') : t('Inactive')}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.costCenters.edit(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick} color="primary">
      <EditIcon />
    </IconButton>
  )
}

const CostCentersList: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useGetCostCenters()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 200
      },
      {
        headerName: t('Label'),
        field: 'label',
        minWidth: 150
      },
      {
        headerName: t('State'),
        field: 'isActive',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <StateRenderer isActive={params.value} />
        },
        minWidth: 150
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t]
  )
  return (
    <RenderOnData
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading cost centers')}
      ignoreLoadingIfData
      dataCondition={(data: CostCentersQuery) =>
        Array.isArray(data.costCenters)
      }
    >
      {({costCenters}: CostCentersQuery) => (
        <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
          <DataGridTable
            columns={columns}
            rows={costCenters}
            pageSizeOptions={[10, 30, 50]}
            initialState={{
              pagination: {paginationModel: {pageSize: 30}},
              pinnedColumns: {left: ['name'], right: ['id']}
            }}
            columnVisibilityModel={{id: P([PermissionCode.UpdateCostCenter])}}
            localeText={{noRowsLabel: t('No cost centers to show')}}
            disableRowSelectionOnClick
          />
        </Box>
      )}
    </RenderOnData>
  )
}

export const CostCentersPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateCostCenterDrawerOpen,
    setTrue: openCreateCostCenterDrawer,
    setFalse: closeCreateCostCenterDrawer
  } = useBooleanState(false)
  const history = useHistory()
  const handleExited = useCallback(
    () => history.replace(routeTo.admin.costCenters.index()),
    [history]
  )
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Cost centers')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <CostCentersList />
        {P([PermissionCode.CreateCostCenter]) && (
          <CreateFab
            onClick={openCreateCostCenterDrawer}
            classes={fabClasses}
          />
        )}
        <CreateCostCenterDrawer
          isOpen={isCreateCostCenterDrawerOpen}
          onClose={closeCreateCostCenterDrawer}
        />
        {P([PermissionCode.UpdateCostCenter]) && (
          <Route path={routeTo.admin.costCenters.edit(':costCenterId')} exact>
            <UpdateCostCenterDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
