import {once, sortBy, uniqBy} from 'lodash'
import React, {createContext, useCallback, useContext} from 'react'
import {
  SessionStorageKey,
  useSessionStorageState
} from '../../../../hooks/storage'
import {PinnedEventPick} from '../types'

interface IPinnedEventsContext<T extends PinnedEventPick> {
  pinnedEvents: T[]
  pinEvent: (event: T) => void
  unpinEvent: (eventId: number) => void
  isEventPinned: (eventId: number) => boolean
}

const createPinnedEventsContext = once(<T extends PinnedEventPick>() =>
  createContext<IPinnedEventsContext<T>>({} as IPinnedEventsContext<T>)
)

interface IPinnedEventsContextProviderProps {
  children: React.ReactNode
}

export const PinnedEventsContextProvider = <T extends PinnedEventPick>({
  children
}: IPinnedEventsContextProviderProps) => {
  const PinnedEventsContext = createPinnedEventsContext<T>()
  const [pinnedEvents, setPinnedEvents] = useSessionStorageState<T[]>(
    SessionStorageKey.PinnedEvents,
    []
  )
  const pinEvent = useCallback(
    (event: T) => {
      setPinnedEvents(
        uniqBy(sortBy<T>([...pinnedEvents, event], 'startsAt'), 'id')
      )
    },
    [pinnedEvents, setPinnedEvents]
  )
  const unpinEvent = useCallback(
    (eventId: number) => {
      setPinnedEvents(pinnedEvents.filter((event) => event.id !== eventId))
    },
    [pinnedEvents, setPinnedEvents]
  )
  const isEventPinned = useCallback(
    (eventId: number) =>
      Boolean(
        pinnedEvents.find((pinnedEvent: T) => pinnedEvent.id === eventId)
      ),
    [pinnedEvents]
  )
  return (
    <PinnedEventsContext.Provider
      value={{
        pinnedEvents,
        pinEvent,
        unpinEvent,
        isEventPinned
      }}
    >
      {children}
    </PinnedEventsContext.Provider>
  )
}

export const usePinnedEvents = <T extends PinnedEventPick>() =>
  useContext(createPinnedEventsContext<T>())
