import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {DisplayMode, SET_DISPLAY_MODE} from './reducer'

export const useDisplayModeActions = () => {
  const dispatch = useDispatch()

  const setDisplayMode = useCallback(
    (displayMode: DisplayMode) => {
      dispatch({type: SET_DISPLAY_MODE, payload: displayMode})
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      setDisplayMode
    }),
    [setDisplayMode]
  )

  return actions
}
