import CancelIcon from '@mui/icons-material/Cancel'
import {IconButton, InputAdornment, SxProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import cn from 'classnames'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDebounce} from '../../../../../hooks/debounce'
import {Theme} from '../../../../../theme'

import {useIsFormMultiSelectRequired} from '../../../../../utils/formsValidations'
import {HideOnLoadingOrError} from '../../../../common'
import {ControlledFormCheckboxGroup} from '../../../../form/ControlledFormCheckboxGroup'
import {TextField} from '../../../../form/FormTextInput'
import {Blank} from '../../../../visual/Blank'
import {
  ConditionFormField,
  IConditionForm,
  IConditionFormInputProps
} from './types'

const parseIdFromCheckboxValue = (id: string) => parseInt(id, 10)

const useStyles = makeStyles<Theme>((theme) => ({
  searchIcon: {
    color: theme.palette.text.secondary
  },
  renderBox: {
    minHeight: 128,
    paddingTop: theme.spacing(2)
  },
  displayNone: {
    display: 'none'
  },
  adornedEnd: {
    paddingRight: 0
  }
}))

interface IIdConditionResourceFormInputProps {
  conditionFormInputProps: IConditionFormInputProps
  textFieldLabel: string
  errorMessage: string
  blankMessage: string
  options: {name: string; id: number; description?: string}[]
  error: ApolloError | undefined
  loading: boolean
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  sx?: SxProps<Theme>
}

export const IdConditionResourceFormInput = ({
  blankMessage,
  errorMessage,
  textFieldLabel,
  loading,
  error,
  setSearchText,
  options,
  conditionFormInputProps,
  sx
}: IIdConditionResourceFormInputProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
  const debouncedSetSearchText = useDebounce(setSearchText, 400)
  const [value, setValue] = useState('')
  const handleChange = useCallback(
    (e) => {
      debouncedSetSearchText(e.target.value)
      setValue(e.target.value)
    },
    [debouncedSetSearchText]
  )

  const handleCancelButtonClick = useCallback(() => {
    setValue('')
    setSearchText('')
  }, [setSearchText])
  return (
    <div>
      <TextField
        fullWidth
        value={value}
        label={textFieldLabel}
        onChange={handleChange}
        InputProps={{
          classes: {
            adornedEnd: classes.adornedEnd
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCancelButtonClick}>
                <CancelIcon className={classes.searchIcon} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <div className={classes.renderBox}>
        <HideOnLoadingOrError
          loading={loading}
          errorMessage={errorMessage}
          error={error}
        >
          <div
            className={cn({
              [classes.displayNone]: options.length === 0
            })}
          >
            <ControlledFormCheckboxGroup<IConditionForm, number>
              {...conditionFormInputProps}
              options={options}
              name={ConditionFormField.Value}
              required
              fullWidth
              parseCheckboxValue={parseIdFromCheckboxValue}
              helperNote={t('Select at least one option')}
              validationOptions={{validate: isFormMultiSelectRequired}}
              sx={sx}
              checkboxSx={{
                '&:hover': {
                  backgroundColor: 'grey.300'
                },
                m: 0
              }}
            />
          </div>
          <div
            className={cn({
              [classes.displayNone]: options.length !== 0
            })}
          >
            <Blank title={blankMessage} />
          </div>
        </HideOnLoadingOrError>
      </div>
    </div>
  )
}
