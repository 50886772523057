import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DiscountsStatisticsFilterInput} from '../../../../__generated__/schema'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {DiscountsStatisticsList} from './DiscountsStatisticsList'
import {
  DEFAULT_DISCOUNTS_STATISTICS_FILTER_INPUT,
  DiscountsStatisticsSearch
} from './DiscountsStatisticsSearch'

export const DiscountsStatisticsPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] =
    useState<DiscountsStatisticsFilterInput>(
      DEFAULT_DISCOUNTS_STATISTICS_FILTER_INPUT
    )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Discounts statistics')}
          search={
            <DiscountsStatisticsSearch onFilterChange={setSearchFilter} />
          }
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <DiscountsStatisticsList searchFilter={searchFilter} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
