import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {EVENTS_PARAMS, RESERVATION_PARAMS} from '../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../utils/routes'
import {SalesPage} from '../../../common/salesPage'
import {ActivityReservations} from './ActivityReservations'
import {ApplyDiscountsForEvent} from './applyDiscounts/ApplyDiscountsForEvent'
import {ApplyDiscountsForReservation} from './applyDiscounts/ApplyDiscountsForReservation'
import {Cart} from './cart'
import {CartExpirationController} from './CartExpirationController'
import {CashDeskPageWithNavigationRail} from './CashDeskPageWithNavigationRail'
import {CurrentCartContextProvider} from './CurrentCartContext'
import {TentativeCurrentCartFab} from './CurrentCartFab'
import {EventAuditoriumPreview} from './eventAuditoriumPreview'
import {FailedCartController} from './FailedCartController'
import {PendingCartController} from './PendingCartController'
import {PinnedEventsContextProvider} from './PinnedEventsContext'
import {ReservationPreview} from './reservationPreview'
import {TourTimeSlot} from './tours/tourTimeSlot'
import {ApplyTourDiscounts} from './tours/tourTimeSlot/applyTourDiscounts'

export const CashDesk: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <CurrentCartContextProvider>
      <CartExpirationController>
        <FailedCartController />
        <PendingCartController
          excludeRoutes={[routeTo.admin.cashDesk.cartDetail()]}
        >
          <PinnedEventsContextProvider>
            <Switch>
              <Route
                path={routeTo.admin.cashDesk.tourTimeSlotApplyDiscounts(
                  ':tourTimeSlotId'
                )}
                component={ApplyTourDiscounts}
              />
              {P([PermissionCode.ReadReservations]) && (
                <Route
                  path={routeTo.admin.cashDesk.tourTimeSlotReservations(
                    ':tourTimeSlotId'
                  )}
                  component={ActivityReservations}
                />
              )}
              {P([
                PermissionCode.IncrementTourItemQuantity,
                PermissionCode.ReadTourTimeSlot,
                PermissionCode.ReadCart
              ]) && (
                <Route
                  path={routeTo.admin.cashDesk.tourTimeSlot(':tourTimeSlotId')}
                  component={TourTimeSlot}
                />
              )}
              <Route
                path={routeTo.admin.cashDesk.eventApplyDiscounts(
                  toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
                )}
                component={ApplyDiscountsForEvent}
              />
              <Route
                path={routeTo.admin.cashDesk.reservationApplyDiscounts(
                  toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
                )}
                component={ApplyDiscountsForReservation}
              />

              {P([PermissionCode.ReadCart]) && (
                <Route
                  path={routeTo.admin.cashDesk.eventAuditoriumPreview(
                    toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
                  )}
                  component={EventAuditoriumPreview}
                />
              )}
              {P([PermissionCode.ReadReservations]) && (
                <Route
                  path={routeTo.admin.cashDesk.eventReservations(
                    toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
                  )}
                  component={ActivityReservations}
                />
              )}
              {P([PermissionCode.ReadSales]) && (
                <Route
                  path={routeTo.admin.cashDesk.eventSales(
                    toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
                  )}
                  component={SalesPage}
                />
              )}
              {P([
                PermissionCode.ReadReservation,
                PermissionCode.ReadEventSeats
              ]) && (
                <Route
                  path={routeTo.admin.cashDesk.reservationPreview(
                    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
                  )}
                  component={ReservationPreview}
                />
              )}

              {P([PermissionCode.ReadCart]) && (
                <Route
                  path={routeTo.admin.cashDesk.cartDetail()}
                  component={Cart}
                />
              )}
              <Route
                path={routeTo.admin.cashDesk.index()}
                component={CashDeskPageWithNavigationRail}
              />
            </Switch>
          </PinnedEventsContextProvider>
        </PendingCartController>
        <Switch>
          {P([PermissionCode.ReadReservations]) && (
            <Route
              path={[routeTo.admin.cashDesk.reservations()]}
              component={TentativeCurrentCartFab}
            />
          )}
          {P([PermissionCode.ReadCart]) && (
            <Route
              path={[routeTo.admin.cashDesk.events()]}
              component={TentativeCurrentCartFab}
            />
          )}
          <Route
            path={[routeTo.admin.cashDesk.shop()]}
            component={TentativeCurrentCartFab}
          />
          <Route
            path={[routeTo.admin.cashDesk.tours()]}
            component={TentativeCurrentCartFab}
          />
          <Route
            path={[routeTo.admin.cashDesk.tourTimeSlot(':tourTimeSlotId')]}
            component={TentativeCurrentCartFab}
          />
        </Switch>
      </CartExpirationController>
    </CurrentCartContextProvider>
  )
}
