import {IVoucherActivationOptionsForm} from '../components/types'

export const RETAIL_REFUND_FORM_ID = 'retailRefundFormId'
export const ECOMMERCE_REFUND_FORM_ID = 'ecommerceRefundFormId'
export const VOUCHER_REFUND_FORM_ID = 'voucherRefundFormId'

export enum RetailRefundMethodField {
  Type = 'type',
  Value = 'value'
}

export interface IRetailRefundMethodForm {
  [RetailRefundMethodField.Type]: string
  [RetailRefundMethodField.Value]: number
}

export enum EcommerceRefundMethodField {
  Type = 'type',
  Value = 'value'
}

export interface IEcommerceRefundMethodForm {
  [EcommerceRefundMethodField.Type]: string
  [EcommerceRefundMethodField.Value]: number
}

export enum VoucherRefundMethodField {
  CampaignId = 'campaignId',
  Balance = 'balance',
  Type = 'type'
}

export interface IVoucherRefundMethodForm
  extends IVoucherActivationOptionsForm {
  [VoucherRefundMethodField.CampaignId]: number
  [VoucherRefundMethodField.Balance]: number
  [VoucherRefundMethodField.Type]: string
}

export enum ClaimTab {
  Retail = 'retail',
  Ecommerce = 'ecommerce',
  Voucher = 'voucher'
}
