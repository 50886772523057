import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  EnabledDiscountsForTourTimeSlotQuery,
  TourItemPropertiesFragment
} from '../../../../../../../__generated__/schema'
import {Blank} from '../../../../../../visual/Blank'

interface IChildrenOnValidDataProps {
  tourItems: TourItemPropertiesFragment[]
  tourTimeSlot: EnabledDiscountsForTourTimeSlotQuery['tourTimeSlot']
  children: React.ReactElement
}

export const ChildrenOnValidData: React.FC<IChildrenOnValidDataProps> = ({
  tourItems,
  children
}: IChildrenOnValidDataProps) => {
  const {t} = useTranslation()
  if (tourItems.length === 0) {
    return (
      <Blank
        title={t('No items to be discounted')}
        description={t('Add first ticket for tour')}
      />
    )
  }
  return children
}
