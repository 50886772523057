import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  BusinessPartnersFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {UpdateBusinessPartnerDrawer} from '../../../common/businessPartnerDrawer/UpdateBusinessPartnerDrawer'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {BusinessPartnersList} from './BusinessPartnersList'
import {
  BusinessPartnersSearch,
  DEFAULT_BUSINESS_PARTNERS_FILTER_INPUT
} from './BusinessPartnersSearch'

export const BusinessPartnersPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<BusinessPartnersFilterInput>(
    DEFAULT_BUSINESS_PARTNERS_FILTER_INPUT
  )
  const history = useHistory()
  const handleExited = useCallback(
    () => history.replace(routeTo.admin.businessPartners.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Business partners')}
          search={<BusinessPartnersSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <BusinessPartnersList searchFilter={searchFilter} />
        {P([
          PermissionCode.ReadBusinessPartner,
          PermissionCode.UpdateBusinessPartner
        ]) && (
          <Route
            path={routeTo.admin.businessPartners.edit(':businessPartnerId')}
            exact
          >
            <UpdateBusinessPartnerDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
