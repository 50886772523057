import {createSelector} from 'reselect'

import {IAppState} from '../index'

export const canvasSelector = (state: IAppState) => state.canvas

export const canvasScaleSelector = createSelector(
  canvasSelector,
  (canvas) => canvas.scale
)

export const canvasOriginSelector = createSelector(
  canvasSelector,
  (canvas) => canvas.origin
)
