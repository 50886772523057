import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {MessageQuery} from '../../../../__generated__/schema'
import {ReadOnlyRow} from '../../../common/ReadOnly'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'column'
  },
  stateRow: {
    display: 'grid',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr'
  }
}))

interface IMessageProps {
  message: MessageQuery['message']
}

export const Message: React.FC<IMessageProps> = ({message}: IMessageProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ReadOnlyRow label={t('Subject')}>{message.subject}</ReadOnlyRow>
      <ReadOnlyRow label={t('Message body')}>{message.body}</ReadOnlyRow>
    </div>
  )
}
