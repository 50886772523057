import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useBooleanState} from '../../../hooks/state'
import {Theme} from '../../../theme'

import {useForgotPassword} from './graphql'

const useStyles = makeStyles<Theme>((theme) => ({
  forgotPasswordText: {
    cursor: 'pointer',
    textAlign: 'center',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

interface IForgotPasswordConfirmationModalProps {
  isOpen: boolean
  onClose: () => any
}

const ForgotPasswordConfirmationModal: React.FC<IForgotPasswordConfirmationModalProps> =
  ({isOpen, onClose}: IForgotPasswordConfirmationModalProps) => {
    const {t} = useTranslation()
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>{t('Forgot password')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Recovery intructions were sent to your email.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

interface IForgotPasswordModal {
  isOpen: boolean
  onClose: () => any
  onSubmit: (data: any) => any
}

const ForgotPasswordModal: React.FC<IForgotPasswordModal> = ({
  isOpen,
  onClose,
  onSubmit
}: IForgotPasswordModal) => {
  const {t} = useTranslation()
  const {register, handleSubmit} = useForm()

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('Forgot password')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(
              'Type your username to the form below, to send a recovery email.'
            )}
          </DialogContentText>
          <TextField
            inputRef={register({required: true})}
            name="username"
            autoFocus
            margin="dense"
            label={t('Username')}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button type="submit" color="primary">
            {t('Confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const ForgotPassword: React.FC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const forgotPassword = useForgotPassword()

  // TODO: better naming?
  const {
    state: isOpenConfirmation,
    setTrue: setOpenedConfirmation,
    setFalse: setClosedConfirmation
  } = useBooleanState(false)

  // TODO: better naming?
  const {
    state: isOpenMain,
    setTrue: setOpenedMain,
    setFalse: setClosedMain
  } = useBooleanState(false)

  const onSubmit = useCallback(
    async (data) => {
      try {
        await forgotPassword(data.username)
        setClosedMain()
        setOpenedConfirmation()
      } catch (err) {
        // TODO: handle in ui
        console.log('Error calling forgotPassword handler', err) // eslint-disable-line
      }
    },
    [forgotPassword, setClosedMain, setOpenedConfirmation]
  )

  return (
    <div>
      <Typography
        className={classes.forgotPasswordText}
        onClick={setOpenedMain}
      >
        {t('Forgot password')}
      </Typography>
      <ForgotPasswordModal
        isOpen={isOpenMain}
        onClose={setClosedMain}
        onSubmit={onSubmit}
      />
      <ForgotPasswordConfirmationModal
        isOpen={isOpenConfirmation}
        onClose={setClosedConfirmation}
      />
    </div>
  )
}
