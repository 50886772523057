import {List, ListItem, ListItemText, Portal, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import isUndefined from 'lodash/isUndefined'
import React, {SyntheticEvent, useEffect, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {Theme} from '../../theme'
import {MediaSizes, TOP_BAR_HEIGHT} from '../constants'
import {useSideBarRef} from '../context/sideBarRef'

const RECT_WIDTH = 2 // px

const useSideNavigationStyles = makeStyles<Theme>((theme) => ({
  listTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: RECT_WIDTH + theme.spacing(2),
    fontWeight: 500
  },
  listWrapper: {
    height: 'fit-content'
  },
  fixed: {
    // Note: 'sticky' sometimes causes strange behavior
    position: 'fixed',
    top: 30 + TOP_BAR_HEIGHT
  },
  rect: {
    width: RECT_WIDTH,
    height: '100%',
    marginRight: theme.spacing(2)
  },
  rectActive: {
    background: theme.palette.primary.dark
  },
  rectInactive: {
    background: 'transparent'
  },
  link: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    paddingLeft: 0,
    height: 40,
    cursor: 'pointer'
  },
  linkSelected: {
    color: theme.palette.primary.dark
  },
  linkUnselected: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }
}))

interface ISideNavigationProps {
  items: {[key: string]: {id: string; label: string}}
  scrollOffset?: number
  title?: string
}

interface ISecondLevelSideNavigationProps extends ISideNavigationProps {
  fixed: boolean
  elementToScrollOn?: Element | null
}

const SideNavigationList: React.FC<ISecondLevelSideNavigationProps> = ({
  items,
  fixed,
  elementToScrollOn,
  scrollOffset = 0,
  title
}: ISecondLevelSideNavigationProps) => {
  const classes = useSideNavigationStyles()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const {hash} = location
    const element = document.getElementById(hash.replace('#', ''))
    const valueToScrollAbove = 20

    setTimeout(() => {
      if (elementToScrollOn) {
        elementToScrollOn.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top:
            (element
              ? element.getBoundingClientRect().top +
                elementToScrollOn.scrollTop
              : 0) -
            scrollOffset -
            valueToScrollAbove
        })
      } else {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          // https://stackoverflow.com/questions/1350581/how-to-get-an-elements-top-position-relative-to-the-browsers-viewport
          top:
            (element
              ? element.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top
              : 0) -
            TOP_BAR_HEIGHT -
            scrollOffset -
            valueToScrollAbove
        })
      }
    }, 100)
  }, [elementToScrollOn, location, scrollOffset])

  const selectedLink = useMemo(() => {
    return (
      Object.values(items).find(
        (conf) => conf.id === location.hash.replace('#', '')
      ) || Object.values(items)[0]
    )
  }, [items, location.hash])

  // To avoid `history.push` on every link click
  const createOnClick = (link: string) => () => {
    history.replace(`${location.pathname}#${link}`)
  }

  return (
    <div className={cn(fixed && classes.fixed)}>
      {title && (
        <Typography variant="h6" className={classes.listTitle}>
          {title}
        </Typography>
      )}
      <List className={classes.listWrapper}>
        {Object.values(items).map((conf) => (
          <ListItem
            className={cn(
              classes.link,
              selectedLink.id === conf.id
                ? classes.linkSelected
                : classes.linkUnselected
            )}
            component="a"
            key={conf.id}
            onClick={createOnClick(conf.id)}
          >
            <>
              <div
                className={cn(
                  classes.rect,
                  selectedLink.id === conf.id
                    ? classes.rectActive
                    : classes.rectInactive
                )}
              />
              <ListItemText>{conf.label}</ListItemText>
            </>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

interface ITwoLevelSideNavigationItems {
  [key: string]: {
    label: string
    secondLevelItems: {[key: string]: {id: string; label: string}}
  }
}

const useStaticSideNavigationStyles = makeStyles<Theme>((theme) => ({
  link: {
    padding: theme.spacing(1, 0),
    cursor: 'pointer'
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    margin: 0,
    paddingLeft: theme.spacing(2)
  }
}))

interface IStaticSideNavigationListProps {
  items: {[key: string]: {id: string; label: string}}
  className?: string
}

export const StaticSideNavigationList: React.FC<IStaticSideNavigationListProps> =
  ({items, className}: IStaticSideNavigationListProps) => {
    const classes = useStaticSideNavigationStyles()
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
      const {hash} = location
      const element = document.getElementById(hash.replace('#', ''))

      setTimeout(() => {
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }, 100)
    }, [location])

    return (
      <List className={className}>
        {Object.values(items).map((item) => (
          <ListItem
            className={classes.link}
            component="a"
            key={item.id}
            onClick={() => {
              history.replace(
                `${location.pathname}${location.search}#${item.id}`
              )
            }}
          >
            <ListItemText className={classes.listItemText}>
              {item.label}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    )
  }

interface IAbsoluteTwoLevelSideNavigationProps {
  items: ITwoLevelSideNavigationItems
  activeLevel: string
  onSectionItemClick: (item: any) => void
}

const useAbsoluteTwoLevelSideNavigationStyles = makeStyles<Theme>((theme) => ({
  list: {
    position: 'absolute',
    top: 30 + TOP_BAR_HEIGHT,
    left: 0,
    width: 200,
    display: 'none',
    gap: theme.spacing(1),
    [`@media ${MediaSizes.LargeDesktop}`]: {
      display: 'grid'
    }
  },
  link: {
    display: 'grid',
    marginLeft: theme.spacing(3)
  },
  linkSelected: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  linkUnselected: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}))

export const AbsoluteTwoLevelSideNavigation: React.FC<IAbsoluteTwoLevelSideNavigationProps> =
  ({
    items,
    activeLevel,
    onSectionItemClick
  }: IAbsoluteTwoLevelSideNavigationProps) => {
    const classes = useAbsoluteTwoLevelSideNavigationStyles()
    const {htmlNode} = useSideBarRef()

    return (
      <Portal container={htmlNode}>
        <div className={classes.list}>
          {Object.keys(items).map((item: string) => {
            const isActive = activeLevel === item
            return (
              <div
                key={item}
                className={classes.link}
                onClick={() => onSectionItemClick(item)}
              >
                <Typography
                  variant="subtitle1"
                  className={
                    isActive ? classes.linkSelected : classes.linkUnselected
                  }
                >
                  {items[item].label}
                </Typography>
                <div
                  onClick={(e: SyntheticEvent) => {
                    // We do not want to propagate click events from inner level, as they change
                    // url as also the top level does, which causes unwanted race-condition errors
                    e.stopPropagation()
                  }}
                >
                  {isActive && (
                    <StaticSideNavigationList
                      items={items[item].secondLevelItems}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </Portal>
    )
  }

interface IRelativeSideNavigationProps extends ISideNavigationProps {
  elementToScrollOn: Element | null
}

/**
 * @deprecated please use `SingleSideNavigationList`, where possible
 */
export const RelativeSideNavigation: React.FC<IRelativeSideNavigationProps> = ({
  items,
  scrollOffset = 0,
  elementToScrollOn,
  title
}: IRelativeSideNavigationProps) => {
  return (
    <SideNavigationList
      fixed={false}
      {...{items, scrollOffset, title, elementToScrollOn}}
    />
  )
}

const useSingleSideNavigationListStyles = makeStyles<
  Theme,
  {
    top?: number
  }
>(() => ({
  root: {
    position: 'absolute',
    top: ({top}) => (isUndefined(top) ? 30 + TOP_BAR_HEIGHT : top),
    left: 0,
    width: 200,
    display: 'none',
    [`@media ${MediaSizes.LargeDesktop}`]: {
      display: 'block'
    }
  }
}))

export interface ISingleSideNavigationListProps {
  items: {[key: string]: {id: string; label: string}}
  top?: number
}

/**
 * Compatible with CenteredLayout
 */
export const SingleSideNavigationList: React.FC<ISingleSideNavigationListProps> =
  ({items, top}: ISingleSideNavigationListProps) => {
    const {htmlNode} = useSideBarRef()
    const classes = useSingleSideNavigationListStyles({top})
    return (
      <Portal container={htmlNode}>
        <div className={classes.root}>
          <StaticSideNavigationList items={items} />
        </div>
      </Portal>
    )
  }
