import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {CartsSupportUserFilterInput} from '../../../../__generated__/schema'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnNoEffectiveClientSelected} from '../ChildrenOnNoEffectiveClientSelected'
import {CartSupportDetailDrawer} from '../components/cartPreviewDrawer/CartSupportDetailDrawer'
import {CartsSearch} from '../components/cartsSearch'
import {
  CartSearchLocation,
  DEFAULT_CARTS_FILTER
} from '../components/cartsSearch/types'
import {PrimaryHeader} from '../Header'
import {CartsSupportList} from './CartsSupportList'

export const CartsSupportPage: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const [searchFilter, setSearchFilter] =
    useState<CartsSupportUserFilterInput>(DEFAULT_CARTS_FILTER)
  const handleExited = useCallback(
    () => history.push(routeTo.admin.cartsSupport.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Carts for support')}
          search={
            <CartsSearch
              onFilterChange={setSearchFilter}
              location={CartSearchLocation.CartsSupport}
            />
          }
        />
      }
    >
      <ChildrenOnNoEffectiveClientSelected showBlank>
        <CartsSupportList searchFilter={searchFilter} />
        <Route path={routeTo.admin.cartsSupport.detail(':cartId')}>
          <CartSupportDetailDrawer onExited={handleExited} />
        </Route>
      </ChildrenOnNoEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
