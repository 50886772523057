import {Chip, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {PRIMARY_100_COLOR} from '../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: `
      "title        price"
      "description  price"
    `,
    gridTemplateColumns: `1fr auto`,
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5)
  },
  title: {
    gridArea: 'title',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(1),
    gap: theme.spacing(0.5),
    gridAutoFlow: 'column'
  },
  price: {
    gridArea: 'price'
  },
  description: {
    gridArea: 'description',
    display: 'flex',
    alignItems: 'flex-start'
  },
  chip: {
    paddingRight: theme.spacing(0.5)
  },
  chipRoot: {
    background: PRIMARY_100_COLOR,
    color: theme.palette.primary.main,
    height: 'auto'
  },
  chipLabelSmall: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  }
}))

interface IClaimSummaryTicketItemProps {
  title: string
  description: React.ReactNode
  count: number
  price: string
}

export const ClaimSummaryTicketItem: React.FC<IClaimSummaryTicketItemProps> = ({
  title,
  description,
  count,
  price
}: IClaimSummaryTicketItemProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2" className={classes.price}>
        {price}
      </Typography>
      <div className={classes.description}>
        <span className={classes.chip}>
          <Chip
            color="primary"
            label={`${count}x`}
            size="small"
            classes={{
              root: classes.chipRoot,
              labelSmall: classes.chipLabelSmall
            }}
          />
        </span>
        <Typography variant="caption">{description}</Typography>
      </div>
    </div>
  )
}
