import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {noop} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'
import {PermissionCode} from '../../../__generated__/schema'
import {useBooleanState} from '../../../hooks/state'
import {useGetUserLocaleTranslation} from '../../../hooks/useGetUserLocaleTranslation'
import {useEnsurePermissions} from '../../../utils/auth'
import {useDateTimeFormatters} from '../../../utils/formatting'
import {useEventsPathnameParams} from '../../../utils/pathname'
import {routeTo} from '../../../utils/routes'
import {CartPreviewDrawer} from '../../pages/admin/components/cartPreviewDrawer'
import {TicketInfoDrawer} from '../../pages/admin/components/ticketInfoDrawer/TicketInfoDrawer'
import {SecondaryHeader} from '../../pages/admin/Header'
import {Error, Loading} from '../../visual'
import {MenuItem} from '../Menu'
import {PersistentDrawerWithContent} from '../PersistentDrawerWithContent'
import {EventSalesReportDrawerContent} from './EventSalesReportDrawerContent'
import {useGetLightweightEventStatistics} from './graphql'
import {Occupancy} from './Occupancy'
import {ReservationsContactsDrawerContent} from './ReservationsContactsDrawerContent'
import {SalesContactsDrawerContent} from './SalesContactsDrawerContent'
import {TicketsDrawerContent} from './TicketsDrawerContent'

const occupancyPermissions = [PermissionCode.ReadEventSeats]

interface INavigationItem {
  id: string
  label: string
  route: string
}
const useNavigationItems = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {eventId} = useEventsPathnameParams()
  return useMemo(
    (): {
      eventSalesReport: INavigationItem
      occupancy?: INavigationItem
      tickets?: INavigationItem
      salesContacts?: INavigationItem
      reservationsContacts?: INavigationItem
    } => ({
      eventSalesReport: {
        id: 'eventSalesReport',
        label: t('Event sales report'),
        route: routeTo.admin.eventsStatistics.eventInfoEventSalesReport(eventId)
      },
      ...(P(occupancyPermissions)
        ? {
            occupancy: {
              id: 'occupancy',
              label: t('Occupancy'),
              route: routeTo.admin.eventsStatistics.eventOccupancy(eventId)
            }
          }
        : {}),
      ...(P([PermissionCode.ReadSales])
        ? {
            tickets: {
              id: 'tickets',
              label: t('Tickets'),
              route: routeTo.admin.eventsStatistics.eventInfoTickets(eventId)
            }
          }
        : {}),
      ...(P([PermissionCode.ReadSales])
        ? {
            salesContacts: {
              id: 'salesContacts',
              label: t('Contacts from sales'),
              route:
                routeTo.admin.eventsStatistics.eventInfoSalesContacts(eventId)
            }
          }
        : {}),
      ...(P([PermissionCode.ReadReservations])
        ? {
            reservationsContacts: {
              id: 'reservationsContacts',
              label: t('Contacts from reservations'),
              route:
                routeTo.admin.eventsStatistics.eventInfoReservationsContacts(
                  eventId
                )
            }
          }
        : {})
    }),
    [P, eventId, t]
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '64px 1fr',
    gridTemplateAreas: `
        "header"
        "persistentDrawerWithContent"
      `,
    height: '100%'
  },
  header: {
    gridArea: 'header'
  },
  menu: {
    padding: theme.spacing(1, 0)
  }
}))

interface IEventSalesPersistentDrawerProps {
  onExited: () => void
}

export const EventSalesPersistentDrawer: React.FC<IEventSalesPersistentDrawerProps> =
  ({onExited}: IEventSalesPersistentDrawerProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {
      state: isDrawerOpen,
      setFalse: closeDrawer,
      setTrue: openDrawer
    } = useBooleanState(false)
    useEffect(() => openDrawer(), [openDrawer])
    const {eventId} = useEventsPathnameParams()
    const [headerTitle, setHeaderTitle] = useState<string>(
      t<string>('Event id: {{eventId}}', {eventId})
    )
    const {data, loading, error} = useGetLightweightEventStatistics(eventId)
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const navigationItems = useNavigationItems()
    const getUserLocaleTranslation = useGetUserLocaleTranslation()
    const {formatDate, formatTime} = useDateTimeFormatters()
    const handleTicketInfoExited = useCallback(
      () =>
        history.push(routeTo.admin.eventsStatistics.eventInfoTickets(eventId)),
      [eventId, history]
    )
    const handleCartDetailExited = useCallback(
      () =>
        history.push(
          routeTo.admin.eventsStatistics.eventInfoSalesContacts(eventId)
        ),
      [eventId, history]
    )
    useEffect(() => {
      if (data) {
        const {aggregatedEventStatisticsByItemPrice: event} = data
        setHeaderTitle(
          [
            formatDate(new Date(event.startsAt)),
            formatTime(new Date(event.startsAt)),
            getUserLocaleTranslation(event.names)
          ].join(' • ')
        )
      }
    }, [data, formatDate, formatTime, getUserLocaleTranslation])
    if (loading) {
      return <Loading />
    }
    if (error) {
      return <Error error={error} message={t('Loading event data failed')} />
    }
    return (
      <>
        <div className={classes.root}>
          <div className={classes.header}>
            <SecondaryHeader
              title={headerTitle}
              hasArrowBackIcon
              onLeftActionClick={onExited}
            />
          </div>
          <PersistentDrawerWithContent
            content={
              <Switch>
                <Route
                  path={routeTo.admin.eventsStatistics.eventInfo(':eventId')}
                  exact
                >
                  <Redirect to={navigationItems.eventSalesReport.route} />
                </Route>
                {P([
                  PermissionCode.ReadAggregatedEventStatisticsByItemPrice
                ]) && (
                  <Route
                    path={routeTo.admin.eventsStatistics.eventInfoEventSalesReport(
                      ':eventId'
                    )}
                  >
                    <EventSalesReportDrawerContent headerTitle={headerTitle} />
                  </Route>
                )}
                {P(occupancyPermissions) && (
                  <Route
                    path={routeTo.admin.eventsStatistics.eventOccupancy(
                      ':eventId'
                    )}
                    component={Occupancy}
                  />
                )}
                {P([PermissionCode.ReadSales]) && (
                  <Route
                    path={routeTo.admin.eventsStatistics.eventInfoTickets(
                      ':eventId'
                    )}
                    component={TicketsDrawerContent}
                  />
                )}
                <Route
                  path={routeTo.admin.eventsStatistics.eventInfoSalesContacts(
                    ':eventId'
                  )}
                >
                  {P([PermissionCode.ReadSales]) && (
                    <Route
                      path={routeTo.admin.eventsStatistics.eventInfoSalesContacts(
                        ':eventId'
                      )}
                      component={SalesContactsDrawerContent}
                    />
                  )}
                </Route>
                {P([PermissionCode.ReadReservations]) && (
                  <Route
                    path={routeTo.admin.eventsStatistics.eventInfoReservationsContacts(
                      ':eventId'
                    )}
                    component={ReservationsContactsDrawerContent}
                  />
                )}
              </Switch>
            }
            drawerContent={
              <div className={classes.menu}>
                {Object.values(navigationItems).map(
                  (item) =>
                    item && (
                      <MenuItem
                        key={item.id}
                        label={item.label}
                        onClick={() => history.push(item.route)}
                        isSelected={
                          !!matchPath(location.pathname, {path: item.route})
                        }
                      />
                    )
                )}
              </div>
            }
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            onOpen={openDrawer}
          />
        </div>
        <Route
          path={routeTo.admin.eventsStatistics.eventInfoTicketInfo(
            ':eventId',
            ':itemId'
          )}
          exact
        >
          <TicketInfoDrawer
            onExited={handleTicketInfoExited}
            onEntered={noop}
          />
        </Route>
        <Route
          path={routeTo.admin.eventsStatistics.eventInfoCartDetail(
            ':eventId',
            ':cartId'
          )}
          exact
        >
          <CartPreviewDrawer onExited={handleCartDetailExited} />
        </Route>
      </>
    )
  }
