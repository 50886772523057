import LinkIcon from '@mui/icons-material/Link'
import {IconButton, Switch} from '@mui/material'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  DetailEventPropertiesFragment,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {useIsValidURL} from '../../../../../../utils/formsValidations'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'

import {StackableCardDivider, StackableCardWrapper} from '../../../../../visual'

import {
  EventBasePropertyCard,
  EventBasePropertyHeader,
  FormField,
  IMainEventPropertiesFormData
} from './common'

interface IWebsiteAndApiProps {
  event: DetailEventPropertiesFragment
}

export const WebsiteAndApi: React.FC<IWebsiteAndApiProps> = ({
  // TODO: we will read those properties from backend once we have them stored there
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  event
}: IWebsiteAndApiProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {control, errors, setValue, watch, triggerValidation, register} =
    useFormContext<IMainEventPropertiesFormData>()
  const isValidURL = useIsValidURL()
  const mockedSwitchDefault = true
  return (
    <StackableCardWrapper>
      {/* TODO: from where will be online sales URL supplied? */}
      <EventBasePropertyCard
        left={
          <EventBasePropertyHeader
            mainLabel={t('E-commerce event URL')}
            secondaryLabel={event.ecommerceEventURL}
          />
        }
        right={
          <IconButton
            onClick={() => {
              window.open(event.ecommerceEventURL, '_blank')
            }}
          >
            <LinkIcon />
          </IconButton>
        }
      />
      {P([PermissionCode.ManageThirdPartyPurchaseUrl]) && (
        <>
          <StackableCardDivider />
          <EventBasePropertyCard
            left={
              <EventBasePropertyHeader
                mainLabel={t('Third party purchase URL')}
                secondaryLabel={t('Optional')}
              />
            }
            right={
              <UncontrolledFormTextInput<IMainEventPropertiesFormData>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={FormField.THIRD_PARTY_PURCHASE_URL}
                placeholder={t('Enter URL')}
                validationOptions={{
                  validate: isValidURL
                }}
                fullWidth
              />
            }
          />
        </>
      )}
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventBasePropertyHeader
            mainLabel={t('Show event on website and API')}
            secondaryLabel={
              event.showOnWebsiteAndApi
                ? t(
                    'Event can be listed on aggregated views, partner apps and portals.'
                  )
                : t(
                    'Event is not listed on any aggregated views, partner apps and portals.'
                  )
            }
          />
        }
        right={
          <Controller
            as={<Switch color="primary" />}
            control={control}
            name={FormField.SHOW_ON_WEBSITE_API}
            defaultValue={mockedSwitchDefault}
          />
        }
      />
    </StackableCardWrapper>
  )
}
