import {useTranslation} from 'react-i18next'

import {DivisionCheckingOption} from '../__generated__/schema'

const useTranslateDivisionCheckingOption = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<DivisionCheckingOption, string> = {
    [DivisionCheckingOption.Entrance]: t<string>(
      'DivisionCheckingOption->Entrance'
    ),
    [DivisionCheckingOption.EntranceAndExit]: t<string>(
      'DivisionCheckingOption->EntranceAndExit'
    )
  }
  return (code: DivisionCheckingOption) => translatedCodes[code]
}

export const useDivisionCheckingOptionSelectData = () => {
  const checkingOptions = Object.values(DivisionCheckingOption)
  const translateDivisionCheckingOption = useTranslateDivisionCheckingOption()
  return checkingOptions.reduce(
    (acc, divisionInvoiceFrequency) => ({
      ...acc,
      [divisionInvoiceFrequency]: translateDivisionCheckingOption(
        divisionInvoiceFrequency
      )
    }),
    {}
  ) as Record<DivisionCheckingOption, string>
}
