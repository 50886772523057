import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {PosTerminalApiConfigSchemaResponse} from '../../../../types'
import {
  useIsIntegerWithMinMaxBoundary,
  useIsPositiveInteger,
  useIsStringWithMaxLength,
  useIsValidIpAddress
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {IPosSettingsForm, PosSettingsField} from './types'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IPosSettingsFormProps {
  formId: string
  onSubmit: (data: IPosSettingsForm) => void
  defaultValues?: Partial<IPosSettingsForm>
  terminalSettings: PosTerminalApiConfigSchemaResponse
}

export const PosSettingsForm: React.FC<IPosSettingsFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  terminalSettings
}: IPosSettingsFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useForm<IPosSettingsForm>({defaultValues})
  const isStringWithMaxLength64 = useIsStringWithMaxLength(64)
  const isValidIpAddress = useIsValidIpAddress()
  const isIntegerWithMinMaxBoundary = useIsIntegerWithMinMaxBoundary(0, 65535)
  const isPositiveInteger = useIsPositiveInteger()
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IPosSettingsForm>
            errors={errors}
            watch={watch}
            register={register}
            label={t('Terminal protocol type')}
            setValue={setValue}
            name={PosSettingsField.ProtocolType}
            key={PosSettingsField.ProtocolType}
            fullWidth
            hasNoneSelectOption
            selectOptions={terminalSettings.properties.terminal.properties.message_protocol.format.reduce(
              (acc, protocol) => ({...acc, [protocol]: protocol}),
              {}
            )}
            required
            validationOptions={{required: true}}
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IPosSettingsForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Terminal ID')}
            name={PosSettingsField.TerminalId}
            key={PosSettingsField.TerminalId}
            validationOptions={{
              validate: isStringWithMaxLength64
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IPosSettingsForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('POS terminal IP address')}
            name={PosSettingsField.IpAddress}
            key={PosSettingsField.IpAddress}
            validationOptions={{
              required: true,
              validate: isValidIpAddress
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IPosSettingsForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('POS terminal port')}
            name={PosSettingsField.Port}
            key={PosSettingsField.Port}
            validationOptions={{
              required: true,
              validate: isIntegerWithMinMaxBoundary
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IPosSettingsForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Initial connection timeout')}
            name={PosSettingsField.Timeout}
            key={PosSettingsField.Timeout}
            validationOptions={{
              validate: isPositiveInteger,
              required: true
            }}
            helperNote={t('Set in milliseconds. Default is 60 000 ms.')}
            required
            fullWidth
          />
        ]}
      />
    </StyledForm>
  )
}
