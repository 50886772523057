import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {TypographyVariant} from '../../../../../../__generated__/schema'
import {InputRow} from '../../../../../common'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {ILabelBlockForm, LabelBlockFormField} from './types'

export const LabelBlockContent: React.FC = () => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation} =
    useFormContext<ILabelBlockForm>()
  return (
    <>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ILabelBlockForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={LabelBlockFormField.Text}
            key={LabelBlockFormField.Text}
            label={t('Text')}
            validationOptions={{
              required: true
            }}
            fullWidth
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<ILabelBlockForm>
            selectOptions={Object.values(TypographyVariant).reduce(
              (acc, variant) => ({
                ...acc,
                [variant]: variant
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={LabelBlockFormField.Variant}
            key={LabelBlockFormField.Variant}
            label={t('Variant')}
            fullWidth
            hasNoneSelectOption
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<ILabelBlockForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={LabelBlockFormField.ClassName}
            key={LabelBlockFormField.ClassName}
            label={t('Class name')}
            fullWidth
          />
        ]}
      />
    </>
  )
}
