import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {TourDetail} from './tourDetail'
import {ToursPage} from './ToursPage'

export const Tours: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadTour]) && (
        <Route
          path={routeTo.admin.tours.detail(':tourId')}
          component={TourDetail}
        />
      )}
      <Route path={routeTo.admin.tours.index()} component={ToursPage} exact />
    </Switch>
  )
}
