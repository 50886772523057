import {useTranslation} from 'react-i18next'
import {
  BaseWebsiteCommonSettingsFieldsFragment,
  UpdateWebsiteCommonSettingsInput
} from '../../../../../../__generated__/schema'
import {
  ButtonTarget,
  EcommerceSettingsFormField,
  IEcommerceSettingsForm
} from './types'

export const useTranslateButtonTarget = () => {
  const {t} = useTranslation()
  const translated: Record<ButtonTarget, string> = {
    [ButtonTarget.Top]: t('ButtonTarget->Top'),
    [ButtonTarget.Self]: t('ButtonTarget->Self'),
    [ButtonTarget.Blank]: t('ButtonTarget->Blank')
  }
  return (target: ButtonTarget) => translated[target]
}

export const transformBaseWebsiteCommonSettingsFieldsToEcommerceSettingsForm = (
  data: BaseWebsiteCommonSettingsFieldsFragment
): IEcommerceSettingsForm => ({
  [EcommerceSettingsFormField.Theme]:
    data.eCommerceQueryParams?.theme || undefined,
  [EcommerceSettingsFormField.ShowCartButton]:
    data.eCommerceQueryParams?.showCartButton || false,
  [EcommerceSettingsFormField.BackgroundColor]:
    data.eCommerceQueryParams?.backgroundColor || undefined,
  [EcommerceSettingsFormField.GatewayButtonTarget]:
    data.eCommerceQueryParams?.gatewayButtonTarget || undefined,
  [EcommerceSettingsFormField.EventOverviewEnabled]:
    data.eCommerceQueryParams?.eventOverviewEnabled || false,
  [EcommerceSettingsFormField.EventButtonTarget]:
    data.eCommerceQueryParams?.eventButtonTarget || undefined,
  [EcommerceSettingsFormField.CartUrl]: data.client.eCommerceCartUrl
})

export const transformECommerceSettingsFormToUpdateWebsiteCommonSettingsInput =
  (data: IEcommerceSettingsForm): UpdateWebsiteCommonSettingsInput => ({
    eCommerceQueryParams: {
      theme: data[EcommerceSettingsFormField.Theme] || null,
      showCartButton: data[EcommerceSettingsFormField.ShowCartButton],
      backgroundColor: data[EcommerceSettingsFormField.BackgroundColor] || null,
      gatewayButtonTarget:
        data[EcommerceSettingsFormField.GatewayButtonTarget] || null,
      eventOverviewEnabled:
        data[EcommerceSettingsFormField.EventOverviewEnabled],
      eventButtonTarget:
        data[EcommerceSettingsFormField.EventButtonTarget] || null
    },
    client: {
      eCommerceCartUrl: data[EcommerceSettingsFormField.CartUrl]
    }
  })
