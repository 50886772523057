export enum WarehouseFormField {
  Name = 'name',
  Code = 'code',
  DivisionId = 'divisionId'
}

export interface IWarehouseForm {
  [WarehouseFormField.Name]: string
  [WarehouseFormField.Code]?: string
  [WarehouseFormField.DivisionId]: string
}
