import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  TemplateFileType,
  TemplatesFilterInput,
  TemplateState,
  TemplateType
} from '../../../../__generated__/schema'
import {
  useTranslateTemplateFileType,
  useTranslateTemplateState,
  useTranslateTemplateType
} from '../../../../hooks/translateTemplates'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption,
  useSearchSelect
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'

export const DEFAULT_TEMPLATES_FILTER_INPUT: TemplatesFilterInput = {
  name: ''
}

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getNameFromSearchObject = (filter: TemplatesFilterInput) =>
    filter.name || undefined
  const getTemplateStateFromSearchObject = (filter: TemplatesFilterInput) =>
    filter.state || undefined
  const getDescriptionFromSearchObject = (filter: TemplatesFilterInput) =>
    filter.description
      ? t('Description: {{description}}', {description: filter.description})
      : undefined
  const getClientNameFromSearchObject = (filter: TemplatesFilterInput) =>
    filter.clientName
      ? t('Client: {{clientName}}', {clientName: filter.clientName})
      : undefined
  const getTemplateTypeFromSearchObject = (filter: TemplatesFilterInput) =>
    filter.type || undefined
  const getTemplateFileTypeFromSearchObject = (filter: TemplatesFilterInput) =>
    filter.fileType || undefined
  const getTemplateVersionNameFromSearchObject = (
    filter: TemplatesFilterInput
  ) =>
    filter.versionName
      ? t('Version name: {{versionName}}', {versionName: filter.versionName})
      : undefined
  return {
    getNameFromSearchObject,
    getTemplateStateFromSearchObject,
    getDescriptionFromSearchObject,
    getClientNameFromSearchObject,
    getTemplateTypeFromSearchObject,
    getTemplateFileTypeFromSearchObject,
    getTemplateVersionNameFromSearchObject
  }
}

const mapNameToFilter = (
  filter: TemplatesFilterInput,
  name: string
): TemplatesFilterInput => ({
  ...filter,
  name
})

const mapTemplateStateToFilter = (
  filter: TemplatesFilterInput,
  state: TemplateState | undefined
): TemplatesFilterInput => ({
  ...filter,
  state
})

const mapDescriptionToFilter = (
  filter: TemplatesFilterInput,
  description: string
): TemplatesFilterInput => ({
  ...filter,
  description
})

const mapClientNameToFilter = (
  filter: TemplatesFilterInput,
  clientName: string
): TemplatesFilterInput => ({
  ...filter,
  clientName
})

const mapTemplateTypeToFilter = (
  filter: TemplatesFilterInput,
  type: TemplateType | undefined
): TemplatesFilterInput => ({
  ...filter,
  type
})

const mapTemplateFileTypeToFilter = (
  filter: TemplatesFilterInput,
  fileType: TemplateFileType | undefined
): TemplatesFilterInput => ({
  ...filter,
  fileType
})

const mapTemplateVersionNameToFilter = (
  filter: TemplatesFilterInput,
  versionName: string | undefined
): TemplatesFilterInput => ({
  ...filter,
  versionName
})

interface ITemplatesSearchProps {
  onFilterChange: (filter: TemplatesFilterInput) => void
}

export const TemplatesSearch: React.FC<ITemplatesSearchProps> = ({
  onFilterChange
}: ITemplatesSearchProps) => {
  const {t} = useTranslation()
  const {
    getNameFromSearchObject,
    getTemplateStateFromSearchObject,
    getDescriptionFromSearchObject,
    getClientNameFromSearchObject,
    getTemplateTypeFromSearchObject,
    getTemplateFileTypeFromSearchObject,
    getTemplateVersionNameFromSearchObject
  } = useGetFieldFromSearchObject()
  const translateTemplateState = useTranslateTemplateState()
  const translateTemplateType = useTranslateTemplateType()
  const translateTemplateFileType = useTranslateTemplateFileType()
  const templateStateOptions: ISelectOption<TemplateState>[] = Object.values(
    TemplateState
  ).map((state) => ({
    id: state,
    label: translateTemplateState(state)
  }))
  const templateTypeOptions: ISelectOption<TemplateType>[] = Object.values(
    TemplateType
  ).map((type) => ({
    id: type,
    label: translateTemplateType(type)
  }))
  const templateFileTypeOptions: ISelectOption<TemplateFileType>[] =
    Object.values(TemplateFileType).map((fileType) => ({
      id: fileType,
      label: translateTemplateFileType(fileType)
    }))
  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedTemplateStateFromSearchObject
  } = useSearchSelect<TemplatesFilterInput, TemplateState>({
    selectInputPrefix: t('State'),
    selectOptions: templateStateOptions,
    getSelectValueFromSearchObject: getTemplateStateFromSearchObject
  })
  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedTemplateTypeFromSearchObject
  } = useSearchSelect<TemplatesFilterInput, TemplateType>({
    selectInputPrefix: t('Template type'),
    selectOptions: templateTypeOptions,
    getSelectValueFromSearchObject: getTemplateTypeFromSearchObject
  })
  const {
    getStringifiedSelectValueFromSearchObject:
      getStringifiedTemplateFileTypeFromSearchObject
  } = useSearchSelect<TemplatesFilterInput, TemplateFileType>({
    selectInputPrefix: t('File type'),
    selectOptions: templateFileTypeOptions,
    getSelectValueFromSearchObject: getTemplateFileTypeFromSearchObject
  })
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<TemplatesFilterInput>(
      getNameFromSearchObject,
      getStringifiedTemplateStateFromSearchObject,
      getDescriptionFromSearchObject,
      getClientNameFromSearchObject,
      getStringifiedTemplateTypeFromSearchObject,
      getStringifiedTemplateFileTypeFromSearchObject,
      getTemplateVersionNameFromSearchObject
    )
  return (
    <Search
      storageKey="TEMPLATES_LIST"
      placeholder={t('Search templates')}
      defaultSearchObject={DEFAULT_TEMPLATES_FILTER_INPUT}
      mapInputTextToSearchObject={mapNameToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      onChange={onFilterChange}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<TemplatesFilterInput, TemplateState>
            label={t('State')}
            value={getTemplateStateFromSearchObject(advancedSearchObject)}
            options={templateStateOptions}
            mapSelectValueToSearchObject={mapTemplateStateToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow
            label={t('Name')}
            placeholder={t('Enter template name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.name || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Description')}
            placeholder={t('Enter template description')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapDescriptionToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.description || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Client')}
            placeholder={t('Enter client name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapClientNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.clientName || undefined}
          />
          <AdvancedSearchSelectRow<TemplatesFilterInput, TemplateType>
            label={t('Template type')}
            value={getTemplateTypeFromSearchObject(advancedSearchObject)}
            options={templateTypeOptions}
            mapSelectValueToSearchObject={mapTemplateTypeToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<TemplatesFilterInput, TemplateFileType>
            label={t('File type')}
            value={getTemplateFileTypeFromSearchObject(advancedSearchObject)}
            options={templateFileTypeOptions}
            mapSelectValueToSearchObject={mapTemplateFileTypeToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow
            label={t('Version name')}
            placeholder={t('Enter template version name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapTemplateVersionNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.versionName || undefined}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
