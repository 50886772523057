import {useTranslation} from 'react-i18next'
import {WebsiteState} from '../__generated__/schema'

export const useTranslateWebsiteState = () => {
  const {t} = useTranslation()
  const translated: Record<WebsiteState, string> = {
    [WebsiteState.Published]: t<string>('WebsiteState->Published'),
    [WebsiteState.Draft]: t<string>('WebsiteState->Draft'),
    [WebsiteState.Deleted]: t<string>('WebsiteState->Deleted')
  }
  return (state: WebsiteState) => translated[state]
}
