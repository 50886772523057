import noop from 'lodash/noop'
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react'

import {
  ShowAgeClassificationCode,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowVersionCode
} from '../../../../../__generated__/schema'

export type AddEventProperties = {
  start: string
  title: string
  showId: number
  auditoriumId: number
  versionCode: ShowVersionCode | null
  formatCode: ShowFormatCode | null
  soundMixCode: ShowSoundMixCode | null
  ageClassificationCode: ShowAgeClassificationCode | null
  calendarEventId: string
  duration: number
}

type AddEventPropertiesContext = [
  AddEventProperties | null,
  Dispatch<SetStateAction<AddEventProperties | null>>
]

const Context = createContext([null, noop] as AddEventPropertiesContext)

type Props = {
  children: ReactNode
}

export const AddEventPropertiesProvider: React.FC<Props> = ({
  children
}: Props) => {
  const addEventPropertiesTuple = useState<AddEventProperties | null>(null)

  return (
    <Context.Provider value={addEventPropertiesTuple}>
      {children}
    </Context.Provider>
  )
}

export const useAddEventProperties = () => useContext(Context)
