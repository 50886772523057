import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypesFilter,
  AdmissionTypeState
} from '../../../../__generated__/schema'
import {useTranslateAdmissionTypeState} from '../../../../hooks/admissionTypeState'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'

export const DEFAULT_ADMISSION_TYPES_FILTER: AdmissionTypesFilter = {}

const mapHasTextToFiler = (
  filter: AdmissionTypesFilter,
  hasText: string | undefined
): AdmissionTypesFilter => ({...filter, hasText})

const mapAdmissionTypeStateToFilter = (
  filter: AdmissionTypesFilter,
  states: AdmissionTypeState | undefined
): AdmissionTypesFilter => ({...filter, states: states ? [states] : undefined})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateAdmissionTypeState = useTranslateAdmissionTypeState()
  const getHasTextFormSearchObject = (filter: AdmissionTypesFilter) =>
    filter.hasText || undefined
  const getAdmissionTypeStateFromSearchObject = (
    filter: AdmissionTypesFilter
  ) =>
    filter.states
      ? t('State: {{admissionTypeState}}', {
          admissionTypeState: translateAdmissionTypeState(filter.states[0])
        })
      : undefined
  return {getHasTextFormSearchObject, getAdmissionTypeStateFromSearchObject}
}

interface IAdmissionTypeSearchProps {
  onFilterChange: (filter: AdmissionTypesFilter) => void
}

export const AdmissionTypesSearch: React.FC<IAdmissionTypeSearchProps> = ({
  onFilterChange
}: IAdmissionTypeSearchProps) => {
  const {t} = useTranslation()
  const {getHasTextFormSearchObject, getAdmissionTypeStateFromSearchObject} =
    useGetFieldFromSearchObject()
  const translateAdmissionTypeState = useTranslateAdmissionTypeState()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFormSearchObject,
    getAdmissionTypeStateFromSearchObject
  )
  const admissionTypeStateSelectOptions: ISelectOption<AdmissionTypeState>[] =
    Object.values(AdmissionTypeState).map((state) => ({
      id: state,
      label: translateAdmissionTypeState(state)
    }))
  return (
    <Search<AdmissionTypesFilter>
      storageKey="ADMISSION_TYPES"
      placeholder={t('Search for admission type')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFiler}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_ADMISSION_TYPES_FILTER}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<AdmissionTypesFilter, AdmissionTypeState>
            label={t('State')}
            value={
              advancedSearchObject.states
                ? advancedSearchObject.states[0]
                : undefined
            }
            options={admissionTypeStateSelectOptions}
            mapSelectValueToSearchObject={mapAdmissionTypeStateToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow<AdmissionTypesFilter>
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFiler}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
