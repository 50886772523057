import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {AdmissionRateQuery} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {useUpdateAdmissionRate} from '../../graphql'
import {AdmissionRateFormField, IAdmissionRateForm} from '../../types'
import {transformAdmissionRateUpdateFormToInput} from '../../utils'
import {AdmissionRateForm} from '../AdmissionRateForm'

const UPDATE_ADMISSION_RATE_FORM_ID = 'updateAdmissionRateForm'

interface IUpdateAdmissionRateDrawerProps {
  onExited: () => void
  admissionRate: AdmissionRateQuery['admissionRate']
}

export const UpdateAdmissionRateDrawer: React.FC<IUpdateAdmissionRateDrawerProps> =
  ({onExited, admissionRate}: IUpdateAdmissionRateDrawerProps) => {
    const {t} = useTranslation()
    const updateAdmissionRate = useUpdateAdmissionRate()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => openDrawer(), [openDrawer])
    const handleSubmit = useCallback(
      async (formData: IAdmissionRateForm) => {
        try {
          setShowBackdrop(true)
          await updateAdmissionRate({
            id: admissionRate.id,
            input: transformAdmissionRateUpdateFormToInput(
              formData,
              admissionRate.state
            )
          })
          addInfoNotification(t('Admission rate updated'))
          onExited()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating admission rate'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        admissionRate.id,
        admissionRate.state,
        defaultErrorHandler,
        onExited,
        setShowBackdrop,
        t,
        updateAdmissionRate
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Edit admission rate')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_ADMISSION_RATE_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <AdmissionRateForm
            formId={UPDATE_ADMISSION_RATE_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{
              [AdmissionRateFormField.Name]: admissionRate.name,
              [AdmissionRateFormField.Abbreviation]: admissionRate.abbreviation,
              [AdmissionRateFormField.Color]: admissionRate.color,
              [AdmissionRateFormField.Description]:
                admissionRate.description || undefined
            }}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
