import {makeStyles} from '@mui/styles'
import React from 'react'

interface IPageWithHeaderTemplateProps {
  children: React.ReactNode
  header: React.ReactNode
}

const useStyles = makeStyles(() => ({
  grid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100vh'
  },
  children: {
    overflow: 'hidden'
  }
}))

export const PageWithHeaderTemplate: React.FC<IPageWithHeaderTemplateProps> = ({
  children,
  header
}: IPageWithHeaderTemplateProps) => {
  const classes = useStyles()
  return (
    <div className={classes.grid}>
      {header}
      <div className={classes.children}>{children}</div>
    </div>
  )
}
