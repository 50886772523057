import AddIcon from '@mui/icons-material/Add'
import {Button} from '@mui/material'
import {last} from 'lodash'
import React from 'react'
import DraggableList from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {
  TemplatePropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {InputBlockWithoutSpacings} from '../../../common'
import {
  DraggableAssignedTemplateItem,
  IAssignedTemplateItemCommonProps
} from './AssignedTemplateItem'
import {InputBlockHeadingRow} from './InputBlockHeadingRow'
import {
  DeviceSettingsAction,
  DeviceSettingsActionTypes,
  DeviceSettingsState
} from './types'

interface ITemplateTypeBlockProps {
  templateType: TemplateType
  clientTemplates: TemplatePropertiesFragment[]
  anchorLabel: string
  anchorId: string
  dispatch: React.Dispatch<DeviceSettingsAction>
  assignedTemplatesByType: DeviceSettingsState['assignedTemplatesByType']
  areDeviceTemplatesAlreadyAssigned: boolean
}

const getAssignedTemplates = (
  assignedTemplatesByType: DeviceSettingsState['assignedTemplatesByType'],
  type: TemplateType | string
): TemplatePropertiesFragment[] => assignedTemplatesByType[type] || []

export const TemplateTypeBlock: React.FC<ITemplateTypeBlockProps> = ({
  templateType,
  clientTemplates,
  anchorLabel,
  anchorId,
  dispatch,
  assignedTemplatesByType,
  areDeviceTemplatesAlreadyAssigned
}: ITemplateTypeBlockProps) => {
  const {t} = useTranslation()
  return (
    <InputBlockWithoutSpacings
      key={templateType}
      header={anchorLabel}
      blockId={anchorId}
      headerRightAction={
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            dispatch({
              type: DeviceSettingsActionTypes.OpenAssignDialog,
              payload: {
                templateType,
                availableTemplates: clientTemplates.filter(
                  (t) =>
                    !getAssignedTemplates(assignedTemplatesByType, templateType)
                      .map((at) => at.id)
                      .includes(t.id)
                )
              }
            })
          }}
        >
          {t('Add template')}
        </Button>
      }
    >
      <InputBlockHeadingRow
        label={t('Templates assigned to device')}
        subLabel={
          areDeviceTemplatesAlreadyAssigned
            ? t(
                'The higher the template is listed, the higher priority template gets.'
              )
            : t(
                'Device is using default templates assigned to company. If you want to set specific templates for this device or custom template sorting add some templates to device first.'
              )
        }
      />
      <DraggableList<
        TemplatePropertiesFragment,
        IAssignedTemplateItemCommonProps,
        DraggableAssignedTemplateItem
      >
        list={getAssignedTemplates(assignedTemplatesByType, templateType)}
        itemKey={'id'}
        template={DraggableAssignedTemplateItem}
        padding={0}
        constrainDrag
        onMoveEnd={(templates: readonly TemplatePropertiesFragment[]) => {
          dispatch({
            type: DeviceSettingsActionTypes.ReorderTemplates,
            payload: {
              type: templateType,
              templates: [...templates]
            }
          })
        }}
        commonProps={{
          isLastItem: (id: number) =>
            last(getAssignedTemplates(assignedTemplatesByType, templateType))
              ?.id === id,
          getRemoveButtonClickHandler: (id: number) => () => {
            dispatch({
              type: DeviceSettingsActionTypes.RemoveTemplate,
              payload: {
                id,
                type: templateType
              }
            })
          }
        }}
      />
    </InputBlockWithoutSpacings>
  )
}
