import {Box, SxProps} from '@mui/material'
import React, {useCallback, useLayoutEffect, useRef} from 'react'
import {Theme} from '../../theme'
import {LoadingMoreProgress} from './LoadingMoreProgress'

interface IScrollableContainerProps {
  onScrollNearEndOfTheContainer: () => void
  sx?: SxProps<Theme>
  children: React.ReactNode
  hasMore: boolean
  isLoadingMore: boolean
}

export const ScrollableContainer: React.FC<IScrollableContainerProps> = ({
  onScrollNearEndOfTheContainer,
  sx,
  children,
  hasMore,
  isLoadingMore
}: IScrollableContainerProps) => {
  const targetRef = useRef<HTMLDivElement | null>(null)
  const interceptorObserverRef = useRef<IntersectionObserver | null>(null)

  const intersectionObserverCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].intersectionRatio === 1) {
        onScrollNearEndOfTheContainer()
      }
    },
    [onScrollNearEndOfTheContainer]
  )

  useLayoutEffect(() => {
    interceptorObserverRef.current = new IntersectionObserver(
      intersectionObserverCallback,
      {
        root: null,
        rootMargin: '0px 0px 200px 0px',
        threshold: 1
      }
    )
    if (interceptorObserverRef.current && targetRef.current) {
      interceptorObserverRef.current.observe(targetRef.current)
    }
    return () => {
      interceptorObserverRef.current?.disconnect()
    }
  }, [intersectionObserverCallback])

  return (
    <Box sx={sx}>
      {children}
      {hasMore && (
        <Box sx={{minHeight: 1}} ref={targetRef} component="div">
          {isLoadingMore && <LoadingMoreProgress />}
        </Box>
      )}
    </Box>
  )
}
