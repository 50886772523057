import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback, useEffect, useState} from 'react'
import {
  EventSeatsChangeSubscription,
  EventSeatsChangeSubscriptionVariables,
  EventSeatsQuery,
  EventSeatsQueryVariables
} from '../__generated__/schema'

const EVENT_SEATS_CHANGE_SUBSCRIPTION = gql`
  subscription eventSeatsChange(
    $eventId: Int!
    $myCartId: Int
    $myReservationIds: [Int!]
    $shouldCleanSelectedSeatsOnUnsubscribe: Boolean
  ) {
    eventSeatsChange(
      eventId: $eventId
      myCartId: $myCartId
      myReservationIds: $myReservationIds
      shouldCleanSelectedSeatsOnUnsubscribe: $shouldCleanSelectedSeatsOnUnsubscribe
    ) {
      eventSeats
    }
  }
`

const GET_EVENT_SEATS = gql`
  query eventSeats($eventId: Int!, $myCartId: Int, $myReservationIds: [Int!]) {
    eventSeats(
      eventId: $eventId
      myCartId: $myCartId
      myReservationIds: $myReservationIds
    )
  }
`

export const useEventSeats = (
  variables: EventSeatsChangeSubscriptionVariables
) => {
  const {
    eventId,
    myCartId,
    myReservationIds,
    shouldCleanSelectedSeatsOnUnsubscribe
  } = variables

  const [subscriptionCartId, setSubscriptionCartId] = useState<
    number | null | 'unsubscribed'
  >(myCartId || null)

  const interruptSubscription = useCallback(() => {
    setSubscriptionCartId('unsubscribed')
  }, [])
  const resetSubscription = useCallback((myCartId: null | number) => {
    setSubscriptionCartId(myCartId)
  }, [])

  const {subscribeToMore, ...queryResult} = useQuery<
    EventSeatsQuery,
    EventSeatsQueryVariables
  >(GET_EVENT_SEATS, {
    variables,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    let unsubscribe: () => void
    if (subscriptionCartId !== 'unsubscribed') {
      unsubscribe = subscribeToMore<
        EventSeatsChangeSubscription,
        EventSeatsChangeSubscriptionVariables
      >({
        document: EVENT_SEATS_CHANGE_SUBSCRIPTION,
        variables: {
          eventId,
          myCartId: subscriptionCartId,
          myReservationIds,
          shouldCleanSelectedSeatsOnUnsubscribe
        },
        updateQuery: (prev, {subscriptionData}) =>
          subscriptionData?.data?.eventSeatsChange?.eventSeats
            ? {
                eventSeats: {
                  seats: {
                    ...prev.eventSeats.seats,
                    ...subscriptionData.data.eventSeatsChange.eventSeats.seats
                  },
                  zones: {
                    ...prev.eventSeats.zones,
                    ...subscriptionData.data.eventSeatsChange.eventSeats.zones
                  }
                }
              }
            : prev
      })
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscribeToMore,
    subscriptionCartId,
    eventId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(myReservationIds)
  ])

  return {
    ...queryResult,
    interruptSubscription,
    resetSubscription
  }
}
