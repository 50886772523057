import {Drawer, drawerClasses} from '@mui/material'
import {styled} from '@mui/system'
import {isEmpty} from 'lodash'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  VoucherTransactionIntentReason
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateVoucherIntentReason} from '../../../../../hooks/translateVoucherTransactionIntent'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {
  useIsPositiveDecimal,
  useIsStringWithMaxLength
} from '../../../../../utils/formsValidations'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  InputRow
} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../../form/UncontrolledFormRadioGroup'
import {replaceCommaWithDot} from '../../tours/tourDetail/admissionRateDetail/utils'
import {useCreditVoucher} from '../graphql'
import {CreditVoucherFormField, ICreditVoucherForm} from '../types'

const CREDIT_VOUCHER_FORM_ID = 'creditVoucherForm'

const useReasonOptions = () => {
  const translateVoucherIntentReason = useTranslateVoucherIntentReason()
  return [
    VoucherTransactionIntentReason.TopUp,
    VoucherTransactionIntentReason.Reward,
    VoucherTransactionIntentReason.Import,
    VoucherTransactionIntentReason.Other
  ].map((reason) => ({
    value: reason,
    label: (
      <BasicRadioLabel primaryText={translateVoucherIntentReason(reason)} />
    )
  }))
}

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface ICreditVoucherDrawerProps {
  isOpen: boolean
  onClose: () => void
  voucherId: number
  refetch: () => void
}

export const CreditVoucherDrawer: React.FC<ICreditVoucherDrawerProps> = ({
  isOpen,
  onClose,
  voucherId,
  refetch
}: ICreditVoucherDrawerProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<ICreditVoucherForm>()
  const creditVoucher = useCreditVoucher()
  const {
    addInfoNotification,
    setShowBackdrop,
    customErrorHandler,
    defaultErrorHandler
  } = useMutationAssistanceHooks()
  const isPositiveDecimal = useIsPositiveDecimal('1,2')
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isSubmitDisabled =
    isEmpty(watch(CreditVoucherFormField.Amount)) ||
    isEmpty(watch(CreditVoucherFormField.Reason))
  const onSubmit = useCallback(
    async (formData: ICreditVoucherForm) => {
      try {
        setShowBackdrop(true)
        await creditVoucher({
          id: voucherId,
          input: {
            amount: parseFloat(
              replaceCommaWithDot(formData[CreditVoucherFormField.Amount])
            ),
            reason: formData[CreditVoucherFormField.Reason],
            note: formData[CreditVoucherFormField.Note] || undefined
          }
        })
        addInfoNotification(t('Credit successfully added'))
        refetch()
        onClose()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.VoucherMaxTopUpsCountReached
          )
        ) {
          customErrorHandler(error, {
            title: t('Credit limit exceeded'),
            contentText: t(
              'Unable to add credit for this voucher. The voucher has already reached its credit limit based on the voucher campaign settings.'
            ),
            confirmButtonLabel: t('Got it')
          })
        } else {
          defaultErrorHandler(error, t('Error while crediting voucher'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      creditVoucher,
      customErrorHandler,
      defaultErrorHandler,
      onClose,
      refetch,
      setShowBackdrop,
      t,
      voucherId
    ]
  )
  const reasonOptions = useReasonOptions()
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          maxWidth: 480,
          width: '100%'
        }
      }}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Credit voucher')}
          />
        }
        footer={
          <SaveButton
            type="submit"
            form={CREDIT_VOUCHER_FORM_ID}
            disabled={isSubmitDisabled}
          >
            {t('Save')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <StyledForm
          id={CREDIT_VOUCHER_FORM_ID}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ICreditVoucherForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CreditVoucherFormField.Amount}
                key={CreditVoucherFormField.Amount}
                label={t('Amount')}
                fullWidth
                validationOptions={{
                  validate: isPositiveDecimal,
                  required: true
                }}
                required
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ICreditVoucherForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={CreditVoucherFormField.Note}
                key={CreditVoucherFormField.Note}
                label={t('Note')}
                fullWidth
                validationOptions={{
                  validate: isStringWithMaxLength
                }}
              />
            ]}
          />
          <InputRow
            nodes={[
              <UncontrolledFormRadioGroup<
                ICreditVoucherForm,
                VoucherTransactionIntentReason
              >
                label={t('Reason')}
                name={CreditVoucherFormField.Reason}
                key={CreditVoucherFormField.Reason}
                errors={errors}
                validationOptions={{
                  required: true
                }}
                fullWidth
                control={control}
                options={reasonOptions}
              />
            ]}
          />
        </StyledForm>
      </DrawerTemplate>
    </Drawer>
  )
}
