import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SettingsIcon from '@mui/icons-material/Settings'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CheckTicketsToursQuery,
  TourState
} from '../../../../__generated__/schema'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {HeaderActionType} from '../../../common/header/types'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {Blank} from '../../../visual/Blank'
import {ListItemWrapper} from '../cashDesk/ListItemWrapper'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {EnabledDivisionsTabs} from '../components/EnabledDivisionsTabs'
import {PrimaryHeader} from '../Header'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../Layout'
import {useGetCheckTicketsTours} from './graphql'
import {ResponsiveTourItem} from './ResponsiveTourItem'
import {CheckTicketsPageType} from './types'
import {useEnabledDivisionsTabs} from './utils'

export const Tours: React.FC = () => {
  const {t} = useTranslation()
  const {
    areDivisionTabsShown,
    enabledDivisions,
    selectedDivisionId,
    handleTabChange,
    divisionsLoading,
    divisionsError
  } = useEnabledDivisionsTabs()
  const {data, error, loading, fetchMore, isLoadingMore} =
    useGetCheckTicketsTours({
      filter: {
        states: [TourState.Active],
        divisionId: selectedDivisionId
      },
      paginationInput: {limit: 50, offset: 0}
    })
  const history = useHistory()
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.tours.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.tours.pagination.hasMore, fetchMore, isLoadingMore])
  const handleOnSettingsIconClick = useCallback(() => {
    history.push(routeTo.admin.checkTickets.advancedSettings())
  }, [history])
  const getTourClickHandler = useCallback(
    (tourId: number) => () =>
      history.push(
        routeTo.admin.checkTickets.checkTicketCode(
          CheckTicketsPageType.Tours,
          tourId
        )
      ),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Check tours tickets')}
          rightActions={[
            {
              Icon: SettingsIcon,
              onClick: handleOnSettingsIconClick,
              label: t('Settings'),
              type: HeaderActionType.IconButton,
              cypressId: 'check-tickets-settings-icon'
            }
          ]}
          isDatetimeEnabled
        />
      }
    >
      <FullScreenCenteredLayout
        onScrolledNearTheEndOfTheLayout={
          data?.tours.pagination.hasMore
            ? handleScrolledNearTheEndOfTheLayout
            : undefined
        }
        subTopbar={
          areDivisionTabsShown && (
            <EnabledDivisionsTabs
              enabledDivisions={enabledDivisions}
              selectedDivisionId={selectedDivisionId}
              onTabChange={handleTabChange}
              loading={divisionsLoading}
              error={divisionsError}
            />
          )
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData<CheckTicketsToursQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading tours')}
            dataCondition={(data) => Array.isArray(data.tours.items)}
          >
            {({tours}) =>
              tours.items.length > 0 ? (
                <CenteredLayoutListWrapper>
                  {tours.items.map((tour) => (
                    <ListItemWrapper key={tour.id}>
                      <ResponsiveTourItem
                        tour={tour}
                        onTourClick={getTourClickHandler}
                      />
                    </ListItemWrapper>
                  ))}
                </CenteredLayoutListWrapper>
              ) : (
                <Blank
                  title={t('No tours found')}
                  IconComp={CalendarTodayIcon}
                />
              )
            }
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </FullScreenCenteredLayout>
    </PageWithHeaderTemplate>
  )
}
