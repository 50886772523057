import {Box, Button, Paper, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AdmissionRateQuery,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {
  useTranslateAdmissionRateState,
  useTranslateAdmissionRateStateDescription
} from '../../../../../../hooks/admissionRateState'
import {useFormatUserName} from '../../../../../../hooks/formatUserName'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../../utils/formatting'
import {useTourParams} from '../../../../../../utils/pathname'
import {routeTo} from '../../../../../../utils/routes'
import {EntityStateChip} from '../../../../../common'
import {ColorBox} from '../../../../../common/ColorBox'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../../common/DividerSeparatedInfoRow'
import {admissionRateStateColors} from '../../../../../constants'

interface IGeneralProps {
  admissionRate: AdmissionRateQuery['admissionRate']
}

export const General: React.FC<IGeneralProps> = ({
  admissionRate
}: IGeneralProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {tourId} = useTourParams()
  const translateAdmissionRateState = useTranslateAdmissionRateState()
  const translateAdmissionRateStateDescription =
    useTranslateAdmissionRateStateDescription()
  const formatUserName = useFormatUserName()
  const {formatDateTime} = useDateTimeFormatters()
  const history = useHistory()
  const handleEditButtonClick = useCallback(
    () =>
      history.push(
        routeTo.admin.tours.editAdmissionRate(tourId, admissionRate.id)
      ),
    [admissionRate.id, history, tourId]
  )
  return (
    <Paper variant="outlined" sx={{p: 3}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6">
            {[admissionRate.abbreviation, admissionRate.name]
              .filter(Boolean)
              .join(' • ')}
          </Typography>
          {P([PermissionCode.UpdateAdmissionRate]) && (
            <Button
              variant="text"
              color="primary"
              onClick={handleEditButtonClick}
            >
              {t('Edit')}
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            alignItems: 'baseline',
            gap: 1,
            gridAutoFlow: 'column',
            gridTemplateColumns: 'auto 1fr'
          }}
        >
          <EntityStateChip
            label={translateAdmissionRateState(admissionRate.state)}
            colorConf={admissionRateStateColors[admissionRate.state]}
          />
          <Typography variant="caption" color="textSecondary">
            {translateAdmissionRateStateDescription(admissionRate.state)}
          </Typography>
        </Box>
        {admissionRate.description && (
          <SingleInfoColumn
            caption={t('Description')}
            value={admissionRate.description}
            valueTypographyVariant="body2"
          />
        )}
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Color'),
              value: (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    textTransform: 'uppercase'
                  }}
                >
                  <ColorBox hexColor={admissionRate.color} />
                  {admissionRate.color.substring(1)}
                </Box>
              )
            },
            {
              caption: t('Created at'),
              value: formatDateTime(new Date(admissionRate.createdAt))
            },
            {
              caption: t('Created by'),
              value: formatUserName(admissionRate.createdBy)
            },
            {
              caption: t('Updated at'),
              value: formatDateTime(new Date(admissionRate.updatedAt))
            },
            {
              caption: t('Updated by'),
              value: formatUserName(admissionRate.updatedBy)
            }
          ]}
        />
      </Box>
    </Paper>
  )
}
