import DeleteIcon from '@mui/icons-material/Delete'
import {Button, ButtonProps} from '@mui/material'
import React, {SyntheticEvent, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useBooleanState} from '../../hooks/state'
import {CancelButton} from './Buttons'
import {
  ConfirmationDialog,
  IConfirmationDialogProps
} from './ConfirmationDialog'

import {IOpenModalButtonProps, StateChangeModal} from './StateChangeModal'
import {Tooltip} from './Tooltip'

export interface IExtraActionOuterProps {
  onSubmit: (e: SyntheticEvent) => Promise<any>
  // Used to make sure that a modal is not openned sooner that the form is being valid
  ensureCanOpenModal: any
  disabled?: boolean
}

export interface IDeleteButtonProps {
  onSubmit: (e: SyntheticEvent) => Promise<any>
  header: string
  message: string
  disabled?: boolean
  noIcon?: boolean
}

/**
 * @deprecated - please use ButtonWithConfirmationDialog instead of this one
 */
export const DeleteButton: React.FC<IDeleteButtonProps> = ({
  onSubmit,
  header,
  message,
  disabled = false,
  noIcon = false
}: IDeleteButtonProps) => {
  const {t} = useTranslation()

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button
        color="primary"
        startIcon={noIcon ? undefined : <DeleteIcon />}
        {...{onClick, disabled}}
      >
        {t('Delete')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      submitButtonText={t('Delete')}
      OpenModalButton={OpenModalButton}
      {...{header, message}}
    />
  )
}

export interface IButtonWithConfirmationDialogProps {
  onConfirmButtonClick: () => void
  buttonProps: ButtonProps
  dialogProps: Pick<
    IConfirmationDialogProps,
    'title' | 'contentText' | 'confirmButtonLabel'
  >
  tooltip?: string
}

export const ButtonWithConfirmationDialog: React.FC<IButtonWithConfirmationDialogProps> =
  ({
    onConfirmButtonClick,
    buttonProps,
    dialogProps,
    tooltip
  }: IButtonWithConfirmationDialogProps) => {
    const {
      state: isOpen,
      setTrue: setOpened,
      setFalse: setClosed
    } = useBooleanState(false)

    const handleConfirmButtonClick = useCallback(() => {
      setClosed()
      onConfirmButtonClick()
    }, [onConfirmButtonClick, setClosed])
    return (
      <>
        <Tooltip title={tooltip}>
          <span>
            <CancelButton {...buttonProps} onClick={setOpened} />
          </span>
        </Tooltip>
        <ConfirmationDialog
          {...dialogProps}
          isOpen={isOpen}
          onCancel={setClosed}
          onConfirm={handleConfirmButtonClick}
        />
      </>
    )
  }
