import Button from '@mui/material/Button'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventDetailDrawerDataQuery,
  SellingChannel
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useUserInfo} from '../../../../../utils/auth'

import {RenderOnData} from '../../../../common'
import {useEventDetailDrawerData} from '../graphql'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  errorButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
}))

interface IEventDetailProviderProps {
  onClose(): void

  eventId: number

  children(data: EventDetailDrawerDataQuery): React.ReactElement
}

export const EventDetailProvider: React.FC<IEventDetailProviderProps> = ({
  onClose,
  eventId,
  children
}: IEventDetailProviderProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {loading: userInfoLoading, error: userInfoError} = useUserInfo()
  const {data, loading, error} = useEventDetailDrawerData({
    id: eventId,
    discountsFilter: {channel: SellingChannel.Retail}
  })
  return (
    <div className={classes.root}>
      <RenderOnData<EventDetailDrawerDataQuery>
        data={data}
        loading={loading || userInfoLoading}
        error={error || userInfoError}
        dataCondition={(data) => Boolean(data && data.event)}
        errorMessage={t<string>('Could not load event data')}
      >
        {(data) => children(data)}
      </RenderOnData>
      {error && (
        <div className={classes.errorButtonBox}>
          <Button autoFocus onClick={onClose} color="primary">
            {t('Close')}
          </Button>
        </div>
      )}
    </div>
  )
}
