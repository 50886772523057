import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useState} from 'react'

import {Theme} from '../../theme'
import {ValidationError} from './ValidationError'

export const useAccordionStyles = makeStyles<Theme>(() => ({
  label: {
    fontWeight: 500
  },
  info: {
    color: 'rgba(0, 0, 0, 0.5)'
  },
  details: {
    width: '100%'
  },
  detailsWrapper: {
    // When set to 'hidden' (default), focus on possible collapsed element(s),
    // in case of possible validation errors, does not scroll page view into this element.
    visibility: 'visible'
  },
  noDividers: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  }
}))

export interface IAccordionProps {
  children: React.ReactNode
  description?: string
  renderInfo?: (label: string) => React.ReactNode
  renderHeader?: (label: string) => React.ReactNode
  ExpandNode?: React.ReactNode
  label: string
  onCollapse?: () => void
  validationError?: string | React.ReactElement
  value?: string
  disableDivider?: boolean
  summaryClassName?: string
  CustomIcon?: React.ReactNode
}

/**
 * @deprecated - missing added value of the component, low quality of code
 */
export const Accordion: React.FC<IAccordionProps> = ({
  children,
  description,
  renderInfo,
  ExpandNode,
  label,
  renderHeader,
  onCollapse,
  validationError,
  value = '',
  disableDivider = false,
  summaryClassName,
  CustomIcon
}: IAccordionProps) => {
  const classes = useAccordionStyles()
  const [isExpanded, setExpanded] = useState<boolean>(false)

  return (
    <MuiAccordion
      className={cn(disableDivider && classes.noDividers)}
      onChange={(_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded)

        if (!isExpanded && onCollapse) {
          onCollapse()
        }
      }}
    >
      <MuiAccordionSummary
        className={cn(summaryClassName)}
        expandIcon={
          !isExpanded && ExpandNode
            ? ExpandNode
            : CustomIcon || <ExpandMoreIcon />
        }
      >
        <div>
          {renderHeader ? (
            renderHeader(label)
          ) : (
            <Typography className={classes.label}>{label}</Typography>
          )}
          {isExpanded && !value && description && (
            <>
              {renderInfo ? (
                renderInfo(description)
              ) : (
                <Typography className={classes.info}>{description}</Typography>
              )}
            </>
          )}
          {!isExpanded && (value || description) && (
            <>
              {renderInfo ? (
                renderInfo(value || description || '')
              ) : (
                <Typography className={classes.info}>
                  {value || description}
                </Typography>
              )}
            </>
          )}
          {!!validationError && (
            <ValidationError>{validationError}</ValidationError>
          )}
        </div>
      </MuiAccordionSummary>
      <MuiAccordionDetails className={classes.detailsWrapper}>
        <div className={classes.details}>{children}</div>
      </MuiAccordionDetails>
    </MuiAccordion>
  )
}
