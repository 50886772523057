import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import TodayIcon from '@mui/icons-material/Today'
import {Box, Stack, SxProps, Typography} from '@mui/material'
import {TypographyProps} from '@mui/material/Typography/Typography'
import {TFunction} from 'i18next'
import {groupBy} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  SaleByItemPassCodeQuery,
  SaleByItemPassCodeTicketItemFieldsFragment,
  SaleByItemPassCodeTourItemFieldsFragment
} from '../../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../../theme'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../../../../utils/formatting'
import {CheckTicketsPageType} from '../../types'
import {
  isSaleByItemPassCodeTicketItemFieldsFragment,
  uncheckedTicketsFilter
} from '../../utils'

const getInvalidEntityTitle = (
  checkType: CheckTicketsPageType,
  t: TFunction
) => {
  switch (checkType) {
    case CheckTicketsPageType.Tours:
      return t<string>('Different tour')
    case CheckTicketsPageType.Events:
      return t<string>('Different event')
    case CheckTicketsPageType.TourTimeSlots:
      return t<string>('Different time slot')
    case CheckTicketsPageType.Venues:
      return t<string>('Different venue')
    default:
      return ''
  }
}

interface IInfoRowProps {
  icon?: React.ReactElement
  children: React.ReactNode
  typographyVariant?: TypographyProps['variant']
  typographyColor?: TypographyProps['color']
  indent?: boolean
}

const InfoRow: React.FC<IInfoRowProps> = ({
  icon,
  children,
  typographyVariant,
  typographyColor,
  indent
}: IInfoRowProps) => (
  <Stack alignItems="center" direction="row" gap={1} pl={indent ? 4 : 0}>
    {icon}
    <Typography
      variant={typographyVariant}
      color={typographyColor}
      component="div"
    >
      {children}
    </Typography>
  </Stack>
)

interface IInfoPaperProps {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

const InfoPaper: React.FC<IInfoPaperProps> = ({
  children,
  sx
}: IInfoPaperProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
      ':last-child': {
        borderBottom: 'none'
      },
      py: 2,
      px: 3,
      ...sx
    }}
  >
    {children}
  </Box>
)

interface IOverviewProps {
  sale: SaleByItemPassCodeQuery['saleByItemPassCode']
  filteredTickets: (
    | SaleByItemPassCodeTicketItemFieldsFragment
    | SaleByItemPassCodeTourItemFieldsFragment
  )[]
  isEntityValid: boolean
  checkType: CheckTicketsPageType
}

export const Overview: React.FC<IOverviewProps> = ({
  sale,
  filteredTickets,
  isEntityValid,
  checkType
}: IOverviewProps) => {
  const {t} = useTranslation()
  const {startsAt, endsAt, duration} = sale.event ?? sale.tourTimeSlot ?? {}
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const {durationToMinutesWithAbbreviatedUnitFormat} =
    useShowDurationFormatters()
  const uncheckedTickets = filteredTickets.filter(uncheckedTicketsFilter) ?? []
  const groupedUncheckedTickets = groupBy(uncheckedTickets, (ticket) =>
    isSaleByItemPassCodeTicketItemFieldsFragment(ticket)
      ? ticket.eventPricingToTicketType.ticketTypeId
      : ticket.admissionType.id
  )
  const filteredUncheckedTicketsWithDiscount = uncheckedTickets.filter(
    (item) => item.appliedDiscounts.length > 0
  )
  const groupedFilteredUncheckedTicketsWithDiscount = groupBy(
    filteredUncheckedTicketsWithDiscount,
    (ticket) =>
      ticket.appliedDiscounts[0] && ticket.appliedDiscounts[0].discount.id
  )
  return (
    <Box>
      <InfoPaper>
        {!isEntityValid && (
          <InfoRow
            typographyVariant="subtitle1"
            typographyColor="error.main"
            icon={<ReportProblemIcon color="error" />}
          >
            {getInvalidEntityTitle(checkType, t)}
          </InfoRow>
        )}
        <InfoRow typographyVariant="subtitle1" icon={<TodayIcon />}>
          {sale.event
            ? getUserLocaleTranslation(sale.event.names)
            : sale.tourTimeSlot?.tour.name}
        </InfoRow>
        <InfoRow
          typographyVariant="body1"
          typographyColor="textSecondary"
          icon={<AccessTimeIcon sx={{color: 'text.secondary'}} />}
        >
          {startsAt &&
            endsAt &&
            duration &&
            [
              formatDateNumeric(new Date(startsAt)),
              `${formatTime(new Date(startsAt))}-${formatTime(
                new Date(endsAt)
              )}`,
              durationToMinutesWithAbbreviatedUnitFormat(duration)
            ].join(' • ')}
        </InfoRow>
        <InfoRow
          typographyVariant="body1"
          typographyColor="textSecondary"
          icon={<PlaceOutlinedIcon sx={{color: 'text.secondary'}} />}
        >
          {sale.event
            ? [sale.event.venue.name, sale.event.auditorium.name].join(' • ')
            : sale.tourTimeSlot?.venue.name}
        </InfoRow>
      </InfoPaper>
      <InfoPaper>
        <InfoRow typographyVariant="subtitle1" icon={<PersonOutlinedIcon />}>
          {t('{{count}}x unchecked tickets of {{totalCount}}', {
            count: uncheckedTickets.length,
            totalCount: filteredTickets.length
          })}
        </InfoRow>
        {Object.entries(groupedUncheckedTickets).map(([id, tickets]) => {
          const name = isSaleByItemPassCodeTicketItemFieldsFragment(tickets[0])
            ? tickets[0].eventPricingToTicketType.name
            : tickets[0].admissionType.name
          return (
            <InfoRow
              key={id}
              typographyVariant="body1"
              typographyColor="textSecondary"
              indent
            >
              {t('{{count}}x {{name}}', {
                count: tickets.length,
                name
              })}
            </InfoRow>
          )
        })}
      </InfoPaper>
      {filteredUncheckedTicketsWithDiscount.length > 0 && (
        <InfoPaper>
          <InfoRow typographyVariant="subtitle1" icon={<PersonOutlinedIcon />}>
            {t('{{count}}x discounted unchecked tickets', {
              count: filteredUncheckedTicketsWithDiscount.length
            })}
          </InfoRow>
          {Object.entries(groupedFilteredUncheckedTicketsWithDiscount).map(
            ([id, tickets]) =>
              tickets[0].appliedDiscounts[0] && (
                <InfoRow
                  key={id}
                  typographyVariant="body1"
                  typographyColor="textSecondary"
                  indent
                >
                  {t('{{count}}x {{discountName}}', {
                    count: tickets.length,
                    discountName: tickets[0].appliedDiscounts[0].discount.name
                  })}
                </InfoRow>
              )
          )}
        </InfoPaper>
      )}
    </Box>
  )
}
