import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box, Button, Paper, Typography} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode, ProductQuery} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../common'
import {
  DataGridTable,
  useDateTimeFormatter,
  useUserNameFormatter
} from '../../../../common/DataGridTable'
import {useDeleteProductBarcode} from '../graphql'
import {CreateBarcodeDrawer} from './CreateBarcodeDrawer'

const IconCellRenderer = ({id, refetch}: {id: number; refetch: () => void}) => {
  const {t} = useTranslation()
  const deleteProductBarcode = useDeleteProductBarcode()
  const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
    useMutationAssistanceHooks()
  const deleteBarcode = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteProductBarcode({id})
      addInfoNotification(t('Barcode deleted'))
      refetch()
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting product barcode'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteProductBarcode,
    id,
    refetch,
    setShowBackdrop,
    t
  ])
  return (
    <>
      <ButtonWithConfirmationDialog
        onConfirmButtonClick={deleteBarcode}
        dialogProps={{
          title: t('Delete product barcode?'),
          contentText: t(
            'After deleting barcode you will not be able to add this product to cart by scanning barcode placed on product. You can add this barcode to any product later.'
          ),
          confirmButtonLabel: t('Delete')
        }}
        buttonProps={{
          children: t('Delete'),
          variant: 'text',
          color: 'primary',
          startIcon: <DeleteIcon />
        }}
      />
    </>
  )
}

interface IBarcodesSectionProps {
  id: string
  product: ProductQuery['product']
  onRefetch: () => void
}

export const BarcodesSection: React.FC<IBarcodesSectionProps> = ({
  id,
  product,
  onRefetch
}: IBarcodesSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateBarcodeDrawerOpen,
    setTrue: openCreateBarcode,
    setFalse: closeCreateBarcode
  } = useBooleanState(false)
  const dateTimeFormatter = useDateTimeFormatter()
  const nameFormatter = useUserNameFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Barcode'),
        field: 'code',
        minWidth: 200
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 250
      },
      {
        headerName: t('Created'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: nameFormatter,
        minWidth: 250
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} refetch={onRefetch} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 112
      }
    ],
    [dateTimeFormatter, nameFormatter, onRefetch, t]
  )
  return (
    <>
      <Box id={id}>
        <Box
          sx={{
            pb: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="subtitle1">{t('Barcodes')}</Typography>
          {P([PermissionCode.CreateProductBarcode]) && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openCreateBarcode}
            >
              {t('Create')}
            </Button>
          )}
        </Box>
        <Paper variant="outlined" sx={{py: 1.5}}>
          <Box sx={{px: 3, pb: 2}}>
            <Typography variant="subtitle2">{t('Product barcodes')}</Typography>
            <Typography variant="caption" color="textSecondary">
              {t(
                'Cashier can add product to cart by scanning or typing the barcode placed at product. One barcode can be included only in one product.'
              )}
            </Typography>
          </Box>
          {product.productBarcodes.length > 0 && (
            <Box sx={{height: '100%', width: '100%'}}>
              <DataGridTable
                columns={columns}
                rows={product.productBarcodes}
                disableColumnMenu
                autoHeight
                disableRowSelectionOnClick
                pagination={false}
                hideFooter
                initialState={{
                  pinnedColumns: {left: ['code'], right: ['id']}
                }}
                columnVisibilityModel={{
                  id: P([PermissionCode.DeleteProductBarcode])
                }}
                sx={{border: 'none', borderRadius: 0}}
              />
            </Box>
          )}
        </Paper>
      </Box>
      <CreateBarcodeDrawer
        isOpen={isCreateBarcodeDrawerOpen}
        onClose={closeCreateBarcode}
        productId={product.id}
      />
    </>
  )
}
