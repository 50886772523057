import {Box} from '@mui/material'
import {useGridApiRef} from '@mui/x-data-grid-pro'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypesStatisticsFilterInput,
  AdmissionTypesStatisticsQuery,
  TourTimeSlotState
} from '../../../../__generated__/schema'
import {RenderOnData} from '../../../common'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {RevenuesTable} from '../components/admissionsStatisticsTables/RevenuesTable'
import {useAdmissionTypesStatistics} from '../graphql'
import {useGetFilterDateRange} from '../utils'
import {Toolbar} from './Toolbar'

interface IRevenuesProps {
  searchFilter: AdmissionTypesStatisticsFilterInput
}

export const Revenues: React.FC<IRevenuesProps> = ({
  searchFilter
}: IRevenuesProps) => {
  const {t} = useTranslation()
  const dataGridApiRef = useGridApiRef()
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.Today
    }
  )
  const [selectedState, setSelectedState] = useState<
    TourTimeSlotState | undefined
  >(TourTimeSlotState.Published)
  const [userId, setUserId] = useState<number | null>(null)
  const [selectedPaymentDate, setSelectedPaymentDate] =
    useState<IDataPickerData | undefined>(undefined)
  const getFilterDateRange = useGetFilterDateRange()
  const {data, loading, error, refetch} = useAdmissionTypesStatistics({
    filter: {
      tourTimeSlotStates: selectedState ? [selectedState] : undefined,
      paymentCreatedByIds: userId ? [userId] : undefined,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'timeSlotStartsAtFrom',
        filterNameTo: 'timeSlotStartsAtTo'
      }),
      ...getFilterDateRange({
        date: selectedPaymentDate,
        filterNameFrom: 'paymentDateTimeFrom',
        filterNameTo: 'paymentDateTimeTo'
      }),
      ...searchFilter
    },
    paginationInput: {
      offset: 0,
      limit: 300
    }
  })
  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <Toolbar
        title={t('Revenues')}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        setUserId={setUserId}
        selectedPaymentDate={selectedPaymentDate}
        setSelectedPaymentDate={setSelectedPaymentDate}
        refetch={refetch}
        onDownloadButtonClick={
          (data?.admissionTypesStatistics.items || []).length > 0
            ? () =>
                dataGridApiRef.current.exportDataAsCsv({
                  fileName: t<string>('admission-types-statistics-revenues')
                })
            : undefined
        }
      />

      <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
        <RenderOnData<AdmissionTypesStatisticsQuery>
          loading={loading}
          data={data}
          error={error}
          errorMessage={t<string>(
            'Error while loading admission types statistics'
          )}
          dataCondition={(data) =>
            Array.isArray(data.admissionTypesStatistics.items)
          }
          ignoreLoadingIfData
        >
          {(data) => (
            <RevenuesTable
              apiRef={dataGridApiRef}
              data={data}
              dataGridProps={{
                disableRowSelectionOnClick: true,
                pagination: false,
                hideFooter: true
              }}
            />
          )}
        </RenderOnData>
      </Box>
    </Box>
  )
}
