import {Box, Divider, DividerProps, SxProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    overflowY: 'auto'
  },
  fullWidth: {
    width: '100%'
  }
}))

interface IListOfItemsSeparatedByDividersProps {
  sx?: SxProps<Theme>
  /**
   * @deprecated use sx prop
   */
  className?: string
  children: React.ReactNodeArray
  DividerProps?: DividerProps
  isFullWidth?: boolean
}

export const ListOfItemsSeparatedByDividers: React.FC<IListOfItemsSeparatedByDividersProps> =
  ({
    children,
    sx,
    className,
    DividerProps,
    isFullWidth
  }: IListOfItemsSeparatedByDividersProps) => {
    const classes = useStyles()
    const lastItemIndex = React.Children.count(children) - 1
    return (
      <Box
        className={cn(
          classes.root,
          {
            [classes.fullWidth]: isFullWidth
          },
          className
        )}
        sx={sx}
      >
        {React.Children.map(children, (child, index) => (
          <>
            {child}
            {index !== lastItemIndex && (
              <Divider {...DividerProps} key={`divider-${index}`} />
            )}
          </>
        ))}
      </Box>
    )
  }
