import React, {ReactNode} from 'react'

import {useUserInfo} from '../../../utils/auth'
import {
  INoEffectiveClientSelectedProps,
  NoEffectiveClientSelected
} from './NoEffectiveClientSelected'

interface IChildrenOnEffectiveClientSelectedProps
  extends INoEffectiveClientSelectedProps {
  children: ReactNode
}

export const ChildrenOnEffectiveClientSelected: React.FC<IChildrenOnEffectiveClientSelectedProps> =
  ({children, text}: IChildrenOnEffectiveClientSelectedProps) => {
    const {effectiveClient} = useUserInfo()
    return effectiveClient ? (
      <>{children}</>
    ) : (
      <NoEffectiveClientSelected text={text} />
    )
  }
