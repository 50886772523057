import {Box} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AdmissionTypesStatisticsFilterInput} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PersistentDrawerMenu} from '../../../common/PersistentDrawerMenu'
import {PersistentDrawerWithContent} from '../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {AdmissionTypesStatisticsSearch} from './AdmissionTypesStatisticsSearch'
import {Revenues} from './Revenues'
import {Turnovers} from './Turnovers'

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      revenues: {id: string; label: string; route: string}
      turnovers: {id: string; label: string; route: string}
    } => ({
      revenues: {
        id: 'revenues',
        label: t('Revenues'),
        route: routeTo.admin.admissionTypesStatistics.revenues()
      },
      turnovers: {
        id: 'turnovers',
        label: t('Turnovers'),
        route: routeTo.admin.admissionTypesStatistics.turnovers()
      }
    }),
    [t]
  )
}

interface IDrawerContentProps {
  searchFilter: AdmissionTypesStatisticsFilterInput
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  searchFilter
}: IDrawerContentProps) => (
  <Switch>
    <Route path={routeTo.admin.admissionTypesStatistics.revenues()}>
      <Revenues searchFilter={searchFilter} />
    </Route>
    <Route path={routeTo.admin.admissionTypesStatistics.turnovers()}>
      <Turnovers searchFilter={searchFilter} />
    </Route>
  </Switch>
)

export const AdmissionTypesStatistics: React.FC = () => {
  const {t} = useTranslation()
  const navigationItems = useNavigationItems()
  const [searchFilter, setSearchFilter] =
    useState<AdmissionTypesStatisticsFilterInput>({})
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(true)
  return (
    <Switch>
      <Route path={routeTo.admin.admissionTypesStatistics.index()} exact>
        <Redirect to={routeTo.admin.admissionTypesStatistics.revenues()} />
      </Route>
      <Route
        path={[
          routeTo.admin.admissionTypesStatistics.revenues(),
          routeTo.admin.admissionTypesStatistics.turnovers()
        ]}
      >
        <PageWithHeaderTemplate
          header={
            <PrimaryHeader
              title={t('Admissions statistics')}
              search={
                <AdmissionTypesStatisticsSearch
                  onFilterChange={setSearchFilter}
                />
              }
            />
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <Box sx={{display: 'grid', height: '100%'}}>
              <PersistentDrawerWithContent
                content={<DrawerContent searchFilter={searchFilter} />}
                drawerContent={<PersistentDrawerMenu items={navigationItems} />}
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
              />
            </Box>
          </ChildrenOnEffectiveClientSelected>
        </PageWithHeaderTemplate>
      </Route>
    </Switch>
  )
}
