import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'

import {useUserInfo} from '../../../../../utils/auth'
import {FormSwitch} from '../../../../visual'
import {PasswordGenerator} from './PasswordGenerator'

const useStyles = makeStyles<Theme>((theme) => ({
  error: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main
  },
  input: {
    marginTop: theme.spacing(2)
  },
  sendEmailWrapper: {
    marginTop: theme.spacing(3),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

export interface NewPasswordFormData {
  password: string
  confirmPassword: string
  sendEmail: boolean
}

interface INewPasswordFormProps {
  onSubmit: (data: NewPasswordFormData) => void
  confirmButton?: React.ReactNode
  generatePassword?: boolean
  userId?: number
}

enum ERROR_CODES {
  DO_NOT_MATCH = 'do not match'
}

export const RESET_PASSWORD_FORM_ID = 'resetPassword'

export const NewPasswordForm: React.FC<INewPasswordFormProps> = ({
  onSubmit,
  confirmButton,
  generatePassword = false,
  userId
}: INewPasswordFormProps) => {
  const classes = useStyles()
  const {register, handleSubmit, setError, errors, control} =
    useForm<NewPasswordFormData>()
  const {t} = useTranslation()
  const {user} = useUserInfo()
  const [fieldVisibility, setFieldVisibility] = useState({
    password: false,
    confirmPassword: false
  })

  const toggleFieldVisibility = useCallback(
    (fieldName: 'password' | 'confirmPassword') => {
      setFieldVisibility({
        ...fieldVisibility,
        [fieldName]: !fieldVisibility[fieldName]
      })
    },
    [fieldVisibility]
  )

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
    },
    []
  )

  const _onSubmit = (data: NewPasswordFormData) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', ERROR_CODES.DO_NOT_MATCH)
    } else {
      onSubmit(data)
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{height: '100%'}}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(_onSubmit)}
        id={RESET_PASSWORD_FORM_ID}
      >
        <Grid container direction="column" alignItems="center">
          <TextField
            label={t('New password')}
            name="password"
            type={fieldVisibility.password ? 'text' : 'password'}
            className={classes.input}
            variant="outlined"
            inputRef={register({required: true})}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => toggleFieldVisibility('password')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {fieldVisibility.password ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            label={t('Confirm password')}
            name="confirmPassword"
            type={fieldVisibility.confirmPassword ? 'text' : 'password'}
            inputRef={register({required: true})}
            className={classes.input}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => toggleFieldVisibility('confirmPassword')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {fieldVisibility.confirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {generatePassword && <PasswordGenerator />}
          {errors.confirmPassword &&
            errors.confirmPassword.type === ERROR_CODES.DO_NOT_MATCH && (
              <Typography className={classes.error}>
                {t('Passwords do not match!')}
              </Typography>
            )}
          {user && user.id !== userId && (
            <Grid item className={classes.sendEmailWrapper}>
              <FormSwitch
                name="sendEmail"
                control={control}
                label={t('Send notification email')}
                checked
              />
            </Grid>
          )}
          {confirmButton || null}
        </Grid>
      </form>
    </Grid>
  )
}
