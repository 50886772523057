import {Box, Typography} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentReport,
  PaymentReportDataAggregatedByVatRate
} from '../../../__generated__/schema'
import {
  DataGridTable,
  useEffectiveClientPriceFormatter,
  usePercentageFormatter
} from '../DataGridTable'
import {commonTableSxProps, getCellClassName} from './utils'

interface IVatSummaryProps {
  id?: string
  label: string
  paymentReport: Pick<
    PaymentReport,
    'revenueVat' | 'revenueVatIncluded' | 'revenueVatExcluded'
  > & {
    dataAggregatedByVatRate: Pick<
      PaymentReportDataAggregatedByVatRate,
      'vatRate' | 'revenueVatExcluded' | 'revenueVat' | 'revenueVatIncluded'
    >[]
  }
}

export const VatSummary: React.FC<IVatSummaryProps> = ({
  id,
  label,
  paymentReport
}: IVatSummaryProps) => {
  const {t} = useTranslation()
  const percentageFormatter = usePercentageFormatter(0)
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('VAT rate'),
        field: 'vatRate',
        valueFormatter: percentageFormatter,
        flex: 1,
        sortable: false
      },
      {
        headerName: t('VAT base'),
        field: 'revenueVatExcluded',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName,
        flex: 1,
        sortable: false
      },
      {
        headerName: t('VAT'),
        field: 'revenueVat',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName,
        flex: 1,
        sortable: false
      },
      {
        headerName: t('Revenue'),
        field: 'revenueVatIncluded',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName,
        flex: 1,
        sortable: false
      }
    ],
    [t, effectiveClientPriceFormatter, percentageFormatter]
  )
  const summarizationRow = [
    {
      vatRate: t('Total'),
      revenueVatExcluded: paymentReport.revenueVatExcluded,
      revenueVat: paymentReport.revenueVat,
      revenueVatIncluded: paymentReport.revenueVatIncluded
    }
  ]
  return (
    <Box id={id} sx={commonTableSxProps}>
      <Typography variant="subtitle1" sx={{pb: 2}}>
        {label}
      </Typography>
      <DataGridTable
        columns={columns}
        rows={[...paymentReport.dataAggregatedByVatRate, ...summarizationRow]}
        hideFooter
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        getRowId={(params) => params.vatRate}
      />
    </Box>
  )
}
