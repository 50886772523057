import {useTranslation} from 'react-i18next'
import {ProductTypeState} from '../__generated__/schema'

export const useTranslateProductTypeState = () => {
  const {t} = useTranslation()
  const translatedTypes: Record<ProductTypeState, string> = {
    [ProductTypeState.Active]: t('ProductTypeState->Active'),
    [ProductTypeState.Inactive]: t('ProductTypeState->Inactive'),
    [ProductTypeState.Deleted]: t('ProductTypeState->Deleted')
  }
  return (type: ProductTypeState) => translatedTypes[type]
}
