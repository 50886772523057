import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DivisionCreateOnlineReservationEnd} from '../../../../../__generated__/schema'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {DivisionFormControlLabel} from './DivisionFormControlLabel'
import {
  DivisionFormSelect,
  FormControlTextLabel,
  FormControlTimeLabel
} from './DivisionFormSelect'
import {
  DivisionFormField,
  IDivisionFormData,
  IDivisionSectionProps
} from './types'

export const OnlineCreateReservationEnd: React.FC<IDivisionSectionProps> = ({
  defaultValues
}: IDivisionSectionProps) => {
  const {t} = useTranslation()
  const createReservationEnds = Object.values(
    DivisionCreateOnlineReservationEnd
  )
  const methods = useFormContext<IDivisionFormData>()
  const {
    watch,
    triggerValidation,
    errors,
    setValue,
    register,
    control,
    unregister,
    clearError,
    setError
  } = methods
  const reservationEndWebType = watch(
    DivisionFormField.ONLINE_CREATE_RESERVATION_END_TYPE
  )
  useEffect(() => {
    if (reservationEndWebType) {
      triggerValidation(
        Object.values(DivisionCreateOnlineReservationEnd)
          .filter((reservation) => reservation !== reservationEndWebType)
          .map(
            (reservation) =>
              `${DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE}.${reservation}`
          )
      )
    }
  }, [reservationEndWebType, triggerValidation])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <DivisionFormSelect<IDivisionFormData>
      options={createReservationEnds}
      name={DivisionFormField.ONLINE_CREATE_RESERVATION_END_TYPE}
      subfieldsName={DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE}
      defaultValue={
        defaultValues[DivisionFormField.ONLINE_CREATE_RESERVATION_END_TYPE] ||
        DivisionCreateOnlineReservationEnd.AfterStart
      }
      validationOptions={{
        required: true
      }}
      required
      label={t('Creating new online reservations')}
      errors={errors}
      setValue={setValue}
      register={register}
    >
      <DivisionFormControlLabel
        value={DivisionCreateOnlineReservationEnd.AfterStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE}.${DivisionCreateOnlineReservationEnd.AfterStart}`}
            validationOptions={{
              required:
                reservationEndWebType ===
                DivisionCreateOnlineReservationEnd.AfterStart,
              validate: isNonNegativeInteger
            }}
            required={
              reservationEndWebType ===
              DivisionCreateOnlineReservationEnd.AfterStart
            }
            inputMode="numeric"
            note={t('minutes after event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionCreateOnlineReservationEnd.BeforeStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE}.${DivisionCreateOnlineReservationEnd.BeforeStart}`}
            validationOptions={{
              required:
                reservationEndWebType ===
                DivisionCreateOnlineReservationEnd.BeforeStart,
              validate: isNonNegativeInteger
            }}
            required={
              reservationEndWebType ===
              DivisionCreateOnlineReservationEnd.BeforeStart
            }
            inputMode="numeric"
            note={t('minutes before event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionCreateOnlineReservationEnd.DayBeforeStart}
        label={
          <FormControlTimeLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.ONLINE_CREATE_RESERVATION_END_VALUE}.${DivisionCreateOnlineReservationEnd.DayBeforeStart}`}
            validationOptions={{
              required:
                reservationEndWebType ===
                DivisionCreateOnlineReservationEnd.DayBeforeStart
            }}
            note={t('the day before the event start')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            unregister={unregister}
            clearError={clearError}
            setError={setError}
          />
        }
      />
    </DivisionFormSelect>
  )
}
