import {
  Autocomplete,
  Chip,
  FormControl,
  FormControlProps,
  Paper,
  PaperProps,
  TextField,
  Typography
} from '@mui/material'
import {isObject} from 'lodash'
import React, {useRef} from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {FormSubText} from './FormSubText'
import {FormFieldName} from './types'
import {getFormError} from './utils'

const PaperComponent = ({children}: PaperProps) => (
  <Paper elevation={8}>{children}</Paper>
)

type AutocompleteOptions<FormValues extends FieldValues = FieldValues> = {
  value: FormValues[FormFieldName<FormValues>]
  name: string
}[]

interface IFormAutocompleteMultipleProps<
  FormValues extends FieldValues = FieldValues
> extends Pick<
    FormControlProps<typeof TextField>,
    'inputMode' | 'label' | 'fullWidth' | 'sx' | 'disabled' | 'size'
  > {
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  validationOptions?: ValidationOptions
  helperText?: string
  noOptionsText?: string
  name: FormFieldName<FormValues>
  autocompleteOptions: AutocompleteOptions<FormValues>
  limitTags?: number
  loading?: boolean
}

export const FormAutocompleteMultiple = <
  FormValues extends FieldValues = FieldValues
>({
  errors,
  control,
  validationOptions,
  helperText,
  name,
  disabled,
  inputMode,
  label,
  fullWidth,
  autocompleteOptions,
  noOptionsText,
  sx,
  limitTags,
  size = 'small',
  loading
}: IFormAutocompleteMultipleProps<FormValues>) => {
  const {t} = useTranslation()
  const error = getFormError(errors, name)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const isRequired = Boolean(validationOptions?.required)
  return (
    <FormControl
      sx={sx}
      fullWidth={fullWidth}
      disabled={disabled}
      error={Boolean(error)}
      variant="outlined"
      size={size}
      inputMode={inputMode}
      required={isRequired}
    >
      <Controller
        as={(props) => (
          <Autocomplete
            key="autocomplete-multiple"
            multiple
            limitTags={limitTags}
            size={size}
            options={autocompleteOptions}
            noOptionsText={noOptionsText}
            loading={loading}
            loadingText={t('Loading...')}
            onChange={async (e, autocompleteValues) => {
              const values = autocompleteValues.map(
                (
                  val:
                    | FormValues[FormFieldName<FormValues>]
                    | AutocompleteOptions<FormValues>[number]
                ) => {
                  return isObject(val) ? val.value : val
                }
              )
              if (Array.isArray(values) && values.length) {
                props.onChange(values, true)
              } else {
                props.onChange(undefined, true)
              }
              await control.triggerValidation(name)
            }}
            PaperComponent={PaperComponent}
            autoComplete={false}
            value={props.value || []}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((tag, index) => {
                const {key, ...tagProps} = getTagProps({index})
                return (
                  <Chip
                    key={key}
                    {...tagProps}
                    variant="outlined"
                    size="small"
                    label={
                      autocompleteOptions.find(({value}) => value === tag)
                        ?.name || tag
                    }
                  />
                )
              })
            }
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.value === value}
            renderOption={(p, option) => (
              <Typography {...p} component="li" variant="body2">
                {option.name}
              </Typography>
            )}
            getLimitTagsText={(more) => t('+ {{count}} more', {count: more})}
            renderInput={(params) => (
              <TextField
                error={Boolean(error)}
                {...params}
                label={label}
                required={isRequired}
                variant="outlined"
                color="primary"
                autoComplete="off"
                inputRef={inputRef}
                inputProps={{
                  ...params.inputProps,
                  form: {
                    autocomplete: 'off'
                  }
                }}
              />
            )}
          />
        )}
        onFocus={() => {
          inputRef.current?.focus()
        }}
        control={control}
        rules={validationOptions}
        name={name}
      />
      <FormSubText
        error={error}
        helperText={helperText}
        validationOptions={validationOptions}
      />
    </FormControl>
  )
}
