import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {Button, IconButton, InputAdornment, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ErrorMessages} from '../../../__generated__/schema'
import {usePostCustomerDisplayMessage} from '../../../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../../../customerDisplayBroadcastChannel/types'
import {useBooleanState} from '../../../hooks/state'
import {NEUTRAL_900_COLOR, Theme} from '../../../theme'
import {useLogin} from '../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../utils/errors'
import {InputRow} from '../../common'
import {COLOR_CONF} from '../../constants'
import {UncontrolledFormTextInput} from '../../form/FormTextInput'

const useErrorStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    // height: '100%',
    alignItems: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    border: `1px solid ${COLOR_CONF.RED.color}`,
    backgroundColor: COLOR_CONF.RED.background,
    borderRadius: theme.spacing(1),
    gap: theme.spacing(2),
    padding: theme.spacing(2)
  }
}))

interface ILoginErrorProps {
  error: ErrorMessages.InvalidCredentials | 'unknown'
}

const LoginError: React.FC<ILoginErrorProps> = ({error}: ILoginErrorProps) => {
  const classes = useErrorStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.root}>
      <Typography variant="h6">
        {error === ErrorMessages.InvalidCredentials
          ? t('Login failed')
          : t('Something went wrong')}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {error === ErrorMessages.InvalidCredentials
          ? t(
              'The username or password you entered is incorrect. Please try again or contact support if you are unable to access your account.'
            )
          : t('Unexpected error, please contact support if error persists.')}
      </Typography>
    </div>
  )
}

const useLoginFormStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  icon: {
    color: NEUTRAL_900_COLOR
  },
  inputRowUsername: {
    paddingBottom: 0
  }
}))

enum LoginFormField {
  USERNAME = 'username',
  PASSWORD = 'password'
}

interface ILoginForm {
  [LoginFormField.USERNAME]: string
  [LoginFormField.PASSWORD]: string
}

export const LoginForm: React.FC = () => {
  const login = useLogin()
  const classes = useLoginFormStyles()
  const [loginError, setLoginError] =
    useState<ErrorMessages.InvalidCredentials | 'unknown' | null>(null)
  const {t} = useTranslation()

  const {register, setValue, errors, watch, triggerValidation, handleSubmit} =
    useForm<ILoginForm>()

  const {state: isPasswordVisible, toggle: togglePasswordVisibility} =
    useBooleanState(false)

  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()

  const _onSubmit = useCallback(
    async (data: ILoginForm) => {
      try {
        await login(data)
        postCustomerDisplayMessage({
          type: CustomerDisplayMessageType.UserLoggedIn
        })
      } catch (err) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            err,
            ErrorMessages.InvalidCredentials
          )
        ) {
          setLoginError(ErrorMessages.InvalidCredentials)
        } else {
          setLoginError('unknown')
        }
      }
    },
    [login, postCustomerDisplayMessage]
  )

  return (
    <>
      {loginError && <LoginError error={loginError} />}
      <form
        className={classes.root}
        noValidate
        onSubmit={handleSubmit(_onSubmit)}
        id="login"
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ILoginForm>
              label={t('Username')}
              key={LoginFormField.USERNAME}
              name={LoginFormField.USERNAME}
              register={register}
              validationOptions={{
                required: true
              }}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
              inputProps={{autoComplete: 'username'}}
              value={undefined}
            />
          ]}
          className={classes.inputRowUsername}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ILoginForm>
              label={t('Password')}
              key={LoginFormField.PASSWORD}
              name={LoginFormField.PASSWORD}
              register={register}
              validationOptions={{required: true}}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              type={isPasswordVisible ? 'text' : 'password'}
              fullWidth
              InputProps={{
                autoComplete: 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {isPasswordVisible ? (
                        <VisibilityOffIcon className={classes.icon} />
                      ) : (
                        <VisibilityIcon className={classes.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={undefined}
            />
          ]}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          cypress-id="login-submit-button"
        >
          {t('Login')}
        </Button>
      </form>
    </>
  )
}
