import {gridClasses, GridColDef} from '@mui/x-data-grid-pro'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {LeadPropertiesFragment} from '../../../../__generated__/schema'
import {
  DataGridTable,
  useTranslateCountryFormatter
} from '../../../common/DataGridTable'

interface IRecipientsProps {
  leads: LeadPropertiesFragment[]
}

export const Recipients: React.FC<IRecipientsProps> = ({
  leads
}: IRecipientsProps) => {
  const {t} = useTranslation()
  const translateCountryFormatter = useTranslateCountryFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('LeadField->Name'),
        field: 'name',
        valueGetter: (params) => params.row.data.name,
        minWidth: 300
      },
      {
        headerName: t('LeadField->Phone'),
        field: 'phone',
        valueGetter: (params) => params.row.data.phone,
        minWidth: 200
      },
      {
        headerName: t('LeadField->Email'),
        field: 'email',
        valueGetter: (params) => params.row.data.email,
        minWidth: 200
      },
      {
        headerName: t('LeadField->Note'),
        field: 'note',
        valueGetter: (params) => params.row.data.note,
        minWidth: 200
      },
      {
        headerName: t('LeadField->InternalNote'),
        field: 'internalNote',
        valueGetter: (params) => params.row.data.internalNote,
        minWidth: 200
      },
      {
        headerName: t('LeadField->CompanyName'),
        field: 'data.companyName',
        valueGetter: (params) => params.row.data.companyName,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->CompanyIdNumber'),
        field: 'data.companyIdNumber',
        valueGetter: (params) => params.row.lead?.data.companyIdNumber,
        minWidth: 200
      },
      {
        headerName: t('LeadField->TaxId'),
        field: 'data.TAXId',
        valueGetter: (params) => params.row.data.TAXid,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->VatId'),
        field: 'VATId',
        valueGetter: (params) => params.row.data.VATid,
        minWidth: 200
      },
      {
        headerName: t('LeadField->BillingAddressStreet'),
        field: 'billingAddressStreet',
        valueGetter: (params) => params.row.data.billingAddressStreet,
        minWidth: 300
      },
      {
        headerName: t('LeadField->BillingAddressTown'),
        field: 'billingAddressTown',
        valueGetter: (params) => params.row.data.billingAddressTown,
        minWidth: 200
      },
      {
        headerName: t('LeadField->BillingPostalCode'),
        field: 'billingPostalCode',
        valueGetter: (params) => params.row.data.billingPostalCode,
        minWidth: 200
      },
      {
        headerName: t('LeadField->BillingAddressCountry'),
        field: 'billingAddressCountry',
        valueGetter: (params) => params.row.data.billingAddressCountry,
        valueFormatter: translateCountryFormatter,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressee'),
        field: 'deliveryAddressee',
        valueGetter: (params) => params.row.data.deliveryAddressee,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressStreet'),
        field: 'deliveryAddressStreet',
        valueGetter: (params) => params.row.data.deliveryAddressStreet,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressTown'),
        field: 'deliveryAddressTown',
        valueGetter: (params) => params.row.data.deliveryAddressTown,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryPostalCode'),
        field: 'deliveryPostalCode',
        valueGetter: (params) => params.row.lead?.data.deliveryPostalCode,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressCountry'),
        field: 'deliveryAddressCountry',
        valueGetter: (params) => params.row.data.deliveryAddressCountry,
        valueFormatter: translateCountryFormatter,
        minWidth: 200
      }
    ],
    [t, translateCountryFormatter]
  )
  return (
    <DataGridTable
      sx={{
        [`& .${gridClasses.withBorderColor}`]: {
          borderColor: 'divider'
        },
        [`& .${gridClasses.columnSeparator}`]: {
          color: 'divider'
        },
        maxWidth: 647,
        [`@media (min-width: 720px)`]: {
          maxWidth: 708
        }
      }}
      columns={columns}
      pagination={false}
      hideFooter
      rows={leads}
      autoHeight
      disableColumnMenu
      disableRowSelectionOnClick
      experimentalFeatures={{columnGrouping: true}}
      columnHeaderHeight={32}
      initialState={{pinnedColumns: {left: ['name']}}}
      columnGroupingModel={[
        {
          groupId: t('Customer'),
          children: [{field: 'phone'}, {field: 'email'}]
        },
        {
          groupId: t('Notes'),
          children: [{field: 'note'}, {field: 'internalNote'}],
          headerAlign: 'left'
        },
        {
          groupId: t('Company'),
          children: [
            {field: 'companyName'},
            {field: 'companyIdNumber'},
            {field: 'TAXId'},
            {field: 'VATId'}
          ],
          headerAlign: 'left'
        },
        {
          groupId: t('Billing address'),
          children: [
            {field: 'billingAddressStreet'},
            {field: 'billingAddressTown'},
            {field: 'billingPostalCode'},
            {field: 'billingAddressCountry'}
          ],
          headerAlign: 'left'
        },
        {
          groupId: t('Delivery address'),
          children: [
            {field: 'deliveryAddressee'},
            {field: 'deliveryAddressStreet'},
            {field: 'deliveryAddressTown'},
            {field: 'deliveryPostalCode'},
            {field: 'deliveryAddressCountry'}
          ],
          headerAlign: 'left'
        }
      ]}
    />
  )
}
