import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  CheckingOption,
  DetailEventPropertiesFragment
} from '../../../../../../__generated__/schema'
import {FormDatetimeInput} from '../../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {StackableCardDivider, StackableCardWrapper} from '../../../../../visual'
import {
  EventBasePropertyCard,
  EventBasePropertyHeader,
  FormField,
  IMainEventPropertiesFormData
} from './common'

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  },
  checkingOptionField: {
    flex: 1
  }
}))

const useGetSelectItems = () => {
  const {t} = useTranslation()
  return {
    checkingOptionItems: {
      [CheckingOption.Entrance]: t('Entrance'),
      [CheckingOption.EntranceExit]: t('Entrance and exit')
    }
  }
}

interface ITicketCheckingFieldsProps {
  event: DetailEventPropertiesFragment
  hideCheckingOptions?: boolean
}

export const TicketCheckingFields: React.FC<ITicketCheckingFieldsProps> = ({
  event,
  hideCheckingOptions = false
}: ITicketCheckingFieldsProps) => {
  const {t} = useTranslation()
  const {
    setError,
    register,
    setValue,
    unregister,
    errors,
    clearError,
    control,
    watch
  } = useFormContext<IMainEventPropertiesFormData>()
  const {checkingOptionItems} = useGetSelectItems()
  const classes = useStyles()

  return (
    <StackableCardWrapper>
      <EventBasePropertyCard
        left={
          <EventBasePropertyHeader
            mainLabel={t('Gate opens at')}
            secondaryLabel={t('Time, when ticket checking will start.')}
          />
        }
        right={
          <FormDatetimeInput
            dataTimePickerProps={{
              minDateTime: dayjs(event.createdAt)
            }}
            name={FormField.GATE_OPENS_AT}
            setValue={setValue}
            errors={errors}
            clearError={clearError}
            control={control}
            register={register}
            unregister={unregister}
            watch={watch}
            setError={setError}
            validationOptions={{
              required: true
            }}
          />
        }
      />
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventBasePropertyHeader
            mainLabel={t('Gate closed at')}
            secondaryLabel={t('Time, when ticket checking ends.')}
          />
        }
        right={
          <FormDatetimeInput
            dataTimePickerProps={{
              minDateTime: dayjs(event.createdAt)
            }}
            name={FormField.GATE_CLOSES_AT}
            setValue={setValue}
            errors={errors}
            clearError={clearError}
            control={control}
            register={register}
            unregister={unregister}
            watch={watch}
            setError={setError}
            validationOptions={{
              required: true
            }}
          />
        }
      />
      <div className={hideCheckingOptions ? classes.hidden : undefined}>
        <StackableCardDivider />
        <EventBasePropertyCard
          left={
            <EventBasePropertyHeader
              mainLabel={t('Checking option')}
              secondaryLabel={t('TODO ...')}
            />
          }
          right={
            <UncontrolledFormSelect<IMainEventPropertiesFormData>
              className={classes.checkingOptionField}
              fullWidth
              register={register}
              validationOptions={{
                required: true
              }}
              name={FormField.CHECKING_OPTION}
              selectOptions={checkingOptionItems}
              setValue={setValue}
              errors={errors}
              watch={watch}
            />
          }
        />
      </div>
    </StackableCardWrapper>
  )
}
