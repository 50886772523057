import {Button, ButtonGroupProps} from '@mui/material'
import {noop} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {TemplatesQuery} from '../../../../__generated__/schema'
import {performCompiledTicketPrintingTemplateAction} from '../../../../utils/compiledTemplates'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useGetReceiptPrintingTemplates} from '../hooks/getReceiptPrintingTemplates'

interface IReceiptPrintingButtonGroupProps {
  selectedItemIds?: number[]
  disabled?: boolean
  cartId: number
  variant?: ButtonGroupProps['variant']
  icon?: React.ReactNode
}

export const ReceiptPrintingButtonGroup: React.FC<IReceiptPrintingButtonGroupProps> =
  ({
    selectedItemIds,
    disabled,
    cartId,
    variant = 'contained',
    icon
  }: IReceiptPrintingButtonGroupProps) => {
    const {t} = useTranslation()
    const templates = useGetReceiptPrintingTemplates()
    const getTemplateButtonClickHandler = useCallback(
      (template: TemplatesQuery['templates'][number]) => () =>
        performCompiledTicketPrintingTemplateAction({
          templateFileType: template.fileType,
          templateId: template.id,
          cartId,
          itemIds: selectedItemIds
        }),
      [cartId, selectedItemIds]
    )
    return (
      <SplitButton
        variant={variant}
        disabled={disabled}
        disableElevation
        Options={
          templates.length
            ? templates.map((template) => (
                <MenuItem
                  key={template.id}
                  label={template.name}
                  icon={icon}
                  onClick={getTemplateButtonClickHandler(template)}
                />
              ))
            : [
                <MenuItem
                  key="no-templates"
                  label={t('No templates have been found')}
                  isDisabled
                />
              ]
        }
      >
        <Button
          variant={variant}
          color="primary"
          onClick={
            templates[0] ? getTemplateButtonClickHandler(templates[0]) : noop
          }
          disabled={!templates.length}
        >
          {t('Print')}
        </Button>
      </SplitButton>
    )
  }
