import {useTranslation} from 'react-i18next'
import {ShopViewMode} from '../types'

export const useTranslateShopViewMode = () => {
  const {t} = useTranslation()
  return (shopViewMode: ShopViewMode) => {
    switch (shopViewMode) {
      case ShopViewMode.ListView:
        return t(`ShopViewMode->ListView`)
      case ShopViewMode.GridView:
        return t(`ShopViewMode->GridView`)
      case ShopViewMode.PriceLookupCodeInput:
        return t(`ShopViewMode->PriceLookupCodeInput`)
      default:
        return 'unknown'
    }
  }
}
