import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PaymentReportsFilterInput,
  PaymentReportType,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination,
  useDateNumericFormatter,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {WideCenteredLayout} from '../Layout'
import {CreateDailyCashierReportDrawer} from './CreateDailyCashierReportDrawer'
import {usePaymentReports} from './graphql'

const EditCell: React.FC<{
  params: GridRenderCellParams<{id: number}>
}> = ({params: {id}}: {params: GridRenderCellParams<{id: number}>}) => {
  const history = useHistory()
  return (
    <IconButton
      size="small"
      onClick={() => {
        history.push(routeTo.admin.paymentReports.detail(id), {
          from: routeTo.admin.paymentReports.dailyCashierReports()
        })
      }}
    >
      <ArrowRightIcon />
    </IconButton>
  )
}

const editCellRenderer = (params: GridRenderCellParams<{id: number}>) => (
  <EditCell params={params} />
)

interface IDailyCashierReportsProps {
  searchFilter: PaymentReportsFilterInput
}

export const DailyCashierReports: React.FC<IDailyCashierReportsProps> = ({
  searchFilter
}: IDailyCashierReportsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [userId, setUserId] = useState<number | null>(null)
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const dateTimeFormatter = useDateTimeFormatter()
  const dateNumericFormatter = useDateNumericFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const userNameFormatter = useUserNameFormatter()
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error, refetch} = usePaymentReports({
    filter: {
      ...searchFilter,
      type: PaymentReportType.DailyCashierReport,
      cashierUserIds: userId ? [userId] : undefined
    },
    paginationInput
  })
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('ID'),
        field: 'id',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Reported date'),
        field: 'reportFrom',
        valueFormatter: dateNumericFormatter,
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Revenue'),
        field: 'revenueVatIncluded',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 100
      },
      {
        headerName: t('Amount'),
        field: 'paymentsAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 100
      },
      {
        headerName: t('Payments count'),
        field: 'paymentsCount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Cashier'),
        field: 'cashier',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: ({id}) => id,
        renderCell: editCellRenderer,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [
      dateNumericFormatter,
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      t,
      userNameFormatter
    ]
  )
  if (error) {
    return (
      <Error error={error} message={t('Error while loading payment reports')} />
    )
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Daily cashier reports')}
        leftActions={[
          P([PermissionCode.ReadUsers]) ? (
            <UserChip key="user-chip" onChange={setUserId} />
          ) : (
            []
          )
        ]}
        rightActions={[
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout
        sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.paymentReports.items || []}
          localeText={{noRowsLabel: t('No daily cashier reports to show')}}
          disableColumnFilter
          disableRowSelectionOnClick
          initialState={{
            pinnedColumns: {left: ['id', 'reportFrom'], right: ['arrow']}
          }}
          columnVisibilityModel={{
            arrow: P([
              PermissionCode.ReadPaymentReport,
              PermissionCode.ReadDailyCashierReport
            ])
          }}
          {...getDataGridPaginationProps(data?.paymentReports.pagination)}
        />
      </WideCenteredLayout>
      {P([
        PermissionCode.CreateDailyCashierPaymentReport,
        PermissionCode.ReadAvailableInputDataForDailyCashierReports
      ]) && (
        <>
          <CreateDailyCashierReportDrawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
          />
          <CreateFab classes={fabClasses} onClick={openDrawer} />
        </>
      )}
    </Box>
  )
}
