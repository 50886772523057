import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  WarehouseStocksProductsQuery,
  WarehouseStocksProductsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

const WAREHOUSE_STOCKS_PRODUCTS = gql`
  ${PAGINATION_FRAGMENT}
  query WarehouseStocksProducts(
    $filter: WarehouseProductsFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouseProducts(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        stock
        isNegativeStockEnabled
        minStockLevel
        optimalStockLevel
        product {
          id
          name
          internalCode
          unit
        }
        warehouse {
          id
          name
        }
        division {
          id
          name
        }
        updatedAt
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useWarehouseStocksProducts = (
  variables?: WarehouseStocksProductsQueryVariables
) =>
  useQuery<WarehouseStocksProductsQuery, WarehouseStocksProductsQueryVariables>(
    WAREHOUSE_STOCKS_PRODUCTS,
    {variables, fetchPolicy: 'network-only'}
  )
