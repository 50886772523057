import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {DetailLibraryShowPropertiesFragment} from '../../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../../hooks/getLocales'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {
  getShowTranslation,
  useGetShowTitle
} from '../../../../../utils/translations'

import {ShowSection} from '../../../../constants'
import {useBackdropState} from '../../../../context/backdrop'
import {useNotifications} from '../../../../context/notifications'
import {useUpdateLibraryShow} from '../graphql'
import {ShowCenteredLayoutWithSingleNavigation} from '../ShowCenteredLayoutWithNavigation'
import {ShowForm, useShowFormStyles} from '../ShowForm'
import {IShowForm, ShowFormLocation} from '../types'
import {useShowDetailsFormAnchors} from '../useShowDetailsFormAnchors'
import {
  getShowFormDefaultValues,
  transformIShowFormToShowInput,
  transformIShowFormToShowTranslationInput
} from '../utils'
import {EditLibraryPageDetailTemplate} from './EditLibraryPageDetailTemplate'

const EDIT_LIBRARY_SHOW_FORM_ID = 'editLibraryShowForm'

export interface IEditLibraryShowDataProps {
  libraryShow: DetailLibraryShowPropertiesFragment
}

export const EditLibraryShowData: React.FC<IEditLibraryShowDataProps> = ({
  libraryShow
}: IEditLibraryShowDataProps) => {
  const showFormClasses = useShowFormStyles()
  const history = useHistory()
  const {t} = useTranslation()
  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const updateLibraryShow = useUpdateLibraryShow()

  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()

  const handleSubmit = useCallback(
    async (showForm: IShowForm) => {
      setShowBackdrop(true)
      try {
        await updateLibraryShow({
          id: libraryShow.id,
          data: transformIShowFormToShowInput(showForm),
          translation: transformIShowFormToShowTranslationInput(showForm)
        })
        addInfoNotification(t('Library show updated'))
      } catch (e) {
        defaultErrorHandler(e, t('Error while updating library show'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      libraryShow.id,
      setShowBackdrop,
      t,
      updateLibraryShow
    ]
  )

  const localeCode = useClientLocaleCode()

  const translation = getShowTranslation({
    translations: libraryShow.translations,
    localeCode
  })

  const defaultValues: IShowForm = getShowFormDefaultValues({
    localeCode,
    translation,
    show: libraryShow
  })
  const getShowTitle = useGetShowTitle()
  const showTitle = getShowTitle({
    translations: libraryShow.translations,
    originalTitle: libraryShow.originalTitle,
    fallbackTitle: t('Library show')
  })
  const showDetailsFormAnchors = useShowDetailsFormAnchors()
  return (
    <EditLibraryPageDetailTemplate
      formId={EDIT_LIBRARY_SHOW_FORM_ID}
      showName={showTitle}
      onClose={handleClose}
      currentTab={ShowSection.Data}
      isFooterVisible
    >
      <ShowCenteredLayoutWithSingleNavigation items={showDetailsFormAnchors}>
        <ShowForm
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          formId={EDIT_LIBRARY_SHOW_FORM_ID}
          className={showFormClasses.form}
          location={ShowFormLocation.Library}
        />
      </ShowCenteredLayoutWithSingleNavigation>
    </EditLibraryPageDetailTemplate>
  )
}
