import {Box, Divider, List, Typography} from '@mui/material'
import _ from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  ApiSeatState,
  EventWithSeatsPropertiesFragment
} from '../../../../../../__generated__/schema'
import {useGetUserLocaleTranslation} from '../../../../../../hooks/useGetUserLocaleTranslation'
import {useDateTimeFormatters} from '../../../../../../utils/formatting'
import {BoldCaption, BoldRegular, ColoredListItem} from '../../../../../common'

import {EditorSideBar} from '../../../venues/editorUtils'
import {FloorPlanStatus} from '../../../venues/FloorPlanStatus'

import {EventAddress, StateRenderer} from '../common'
import {STATES_WITH_INFO, useAllSeatStateTranslations} from './common'
import {ISeatsState, useSeatsState} from './seatsStateContext'

interface ISeatStatesInfoProps {}

const zoneAggregator = (objValue?: number, srcValue?: number) => {
  return (objValue || 0) + (srcValue || 0)
}

const getSeatsCounts = (seatsState: ISeatsState | null) => {
  if (!seatsState) return null

  const initialState = Object.values(ApiSeatState).reduce(
    (res, v) => ({...res, [v]: 0}),
    {}
  )
  const aggregatedSeats = Object.values(seatsState.seats).reduce(
    (res, {state}) => {
      return {...res, [state]: res[state] + 1}
    },
    initialState as {[key in ApiSeatState]: number}
  )

  const aggregatedZones = Object.values(seatsState.zones).reduce(
    (res, zone) => {
      return _.mergeWith(res, zone.states, zoneAggregator)
    },
    initialState as {[key in ApiSeatState]: number}
  )

  return _.mergeWith(
    aggregatedSeats,
    aggregatedZones,
    (objValue, srcValue) => objValue + srcValue
  )
}

const SeatStatesInfo: React.FC<ISeatStatesInfoProps> = () => {
  const seatStateTranslations = useAllSeatStateTranslations()
  const {seatsState} = useSeatsState()
  const {t} = useTranslation()

  const aggregatedSeatsState = useMemo(
    () => getSeatsCounts(seatsState),
    [seatsState]
  )

  if (!aggregatedSeatsState) return null

  const choosenStatesSeatCount = _.pick(aggregatedSeatsState, STATES_WITH_INFO)
  const otherStatesSeatCount = _.sum(
    Object.values(_.omit(aggregatedSeatsState, STATES_WITH_INFO))
  )

  return (
    <List component="nav">
      {STATES_WITH_INFO.map((seatState) => {
        const trObject = seatStateTranslations[seatState]!
        const count = choosenStatesSeatCount[seatState]
        return (
          <ColoredListItem
            description={trObject.desc}
            extra={
              <BoldCaption>
                {count}{' '}
                {t('seat', {
                  postProcess: 'interval',
                  count
                })}
              </BoldCaption>
            }
            title={trObject.state}
            key={trObject.state}
          />
        )
      })}
      <ColoredListItem
        description={t(
          'Can not be edited. Seats in occupied, reserved or purchased states.'
        )}
        extra={
          <BoldCaption>
            {otherStatesSeatCount}{' '}
            {t('seat', {
              postProcess: 'interval',
              count: otherStatesSeatCount
            })}
          </BoldCaption>
        }
        title={t('Other')}
      />
    </List>
  )
}

interface IInfoSidebarProps {
  data: EventWithSeatsPropertiesFragment
}

export const InfoSidebar: React.FC<IInfoSidebarProps> = ({
  data
}: IInfoSidebarProps) => {
  const {t} = useTranslation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDateTime} = useDateTimeFormatters()

  return (
    <>
      <EditorSideBar>
        <BoldRegular>{t('Event')}</BoldRegular>
        <StateRenderer state={data.state} />
        <Typography variant="caption">
          {formatDateTime(new Date(data.startsAt))}
        </Typography>
        <BoldCaption>{getUserLocaleTranslation(data.names)}</BoldCaption>
        <Box marginTop={2}>
          <EventAddress venue={data.venue} auditorium={data.auditorium} />
        </Box>
      </EditorSideBar>
      <Divider />
      <EditorSideBar>
        <FloorPlanStatus
          disableCapacity
          disableIcons
          disableShapes
          disableTexts
        />
      </EditorSideBar>
      <Divider />
      <EditorSideBar>
        <Box marginBottom={-1}>
          <BoldRegular>{t('Seat states')}</BoldRegular>
        </Box>
      </EditorSideBar>
      <SeatStatesInfo />
    </>
  )
}
