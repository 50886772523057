import {DateValidationError} from '@mui/x-date-pickers/internals'
import {DateTimeValidationError} from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation'
import {TimeValidationError} from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation'
import dayjs, {Dayjs} from 'dayjs'
import {useState} from 'react'
import {
  FieldValues,
  FormContextValues,
  IsFlatObject,
  ManualFieldError
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

export const useTranslateDateValidationError = () => {
  const {t} = useTranslation()
  return (reason: DateValidationError) => {
    switch (reason) {
      case 'invalidDate':
        return t('DateValidationError->invalidDate')
      case 'shouldDisableDate':
        return t('DateValidationError->shouldDisableDate')
      case 'maxDate':
        return t('DateValidationError->maxDate')
      case 'minDate':
        return t('DateValidationError->minDate')
      case 'disableFuture':
        return t('DateValidationError->disableFuture')
      case 'disablePast':
        return t('DateValidationError->disablePast')
      default:
        return ''
    }
  }
}

export const useTranslateTimeValidationError = () => {
  const {t} = useTranslation()
  return (reason: TimeValidationError) => {
    switch (reason) {
      case 'invalidDate':
        return t('TimeValidationError->invalidDate')
      case 'minutesStep':
        return t('TimeValidationError->minutesStep')
      case 'minTime':
        return t('TimeValidationError->minTime')
      case 'maxTime':
        return t('TimeValidationError->maxTime')
      case 'shouldDisableTime-hours':
        return t('TimeValidationError->shouldDisableTime-hours')
      case 'shouldDisableTime-minutes':
        return t('TimeValidationError->shouldDisableTime-minutes')
      case 'shouldDisableTime-seconds':
        return t('TimeValidationError->shouldDisableTime-seconds')
      default:
        return ''
    }
  }
}

const isDateValidationError = (
  reason: DateTimeValidationError
): reason is DateValidationError => {
  return [
    'invalidDate',
    'shouldDisableDate',
    'maxDate',
    'minDate',
    'disableFuture',
    'disablePast'
  ].includes(String(reason))
}

export const INVALID_DATETIME_FORMAT_MISSING_TIME =
  'invalidDatetimeFormatMissingTime'

export const useTranslateDateTimeValidationError = () => {
  const translateDateValidationError = useTranslateDateValidationError()
  const translateTimeValidationError = useTranslateTimeValidationError()
  const {t} = useTranslation()
  return (
    reason:
      | DateTimeValidationError
      | typeof INVALID_DATETIME_FORMAT_MISSING_TIME
  ) =>
    reason === INVALID_DATETIME_FORMAT_MISSING_TIME
      ? t('Invalid format, missing time part')
      : isDateValidationError(reason)
      ? translateDateValidationError(reason)
      : translateTimeValidationError(reason)
}

const reduceFormPart = <FormValues extends FieldValues>(
  formPart: object,
  keyPrefix: string = '',
  initialAcc: Extract<keyof FormValues, string>[] = []
): Extract<keyof FormValues, string>[] =>
  Object.entries(formPart).reduce((acc, [key, value]) => {
    if (dayjs.isDayjs(value)) {
      if (value?.isValid() === false) {
        return [
          ...acc,
          `${keyPrefix}${key}` as Extract<keyof FormValues, string>
        ]
      }
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      return reduceFormPart<FormValues>(value, `${key}.`, acc)
    }

    return acc
  }, initialAcc)
export const useHandleInvalidDateTimes = <FormValues extends FieldValues>(
  setError: FormContextValues<FormValues>['setError']
) => {
  const translateDateTimeValidationError = useTranslateDateTimeValidationError()

  return (callback: (f: FormValues) => void | Promise<void>) =>
    (form: FormValues) => {
      const keysWithInvalidDayjsValues = reduceFormPart<FormValues>(form)

      const manualFieldErrors: null | ManualFieldError<FormValues>[] =
        keysWithInvalidDayjsValues.length
          ? keysWithInvalidDayjsValues.map((key) => ({
              name: key as IsFlatObject<FormValues> extends true
                ? Extract<keyof FormValues, string>
                : never,
              type: 'error',
              message: translateDateTimeValidationError('invalidDate')
            }))
          : null
      if (manualFieldErrors === null) {
        callback(form)
      } else {
        setError(manualFieldErrors)
      }
    }
}

export const useDatePickerError = () => {
  const [reason, setReason] = useState<DateValidationError>(null)
  const translateDateValidationError = useTranslateDateValidationError()
  return {
    reason,
    errorMessage: translateDateValidationError(reason),
    onError: (reason: DateValidationError, value: Dayjs | null) => {
      if (reason === null) {
        setReason(null)
      } else {
        if (value) {
          setReason(reason)
        }
      }
    }
  }
}
