import {OutlinedTextFieldProps, TextField} from '@mui/material'
import {debounce} from 'lodash'
import React, {ChangeEvent, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useIsRequired} from '../../utils/formsValidations'
import {SIDE_PANEL_WAIT_TIME_FOR_AUTOSAVE} from '../config'

export interface ITextFieldWithValidationsProps extends OutlinedTextFieldProps {
  validationOptions?: {
    isRequired?: boolean
    validators?: Record<string, (value: string) => string | undefined>
  }
}

export const TextFieldWithValidations: React.FC<ITextFieldWithValidationsProps> =
  ({
    validationOptions: {isRequired = false, validators = {}} = {},
    helperText,
    onChange,
    ...props
  }: ITextFieldWithValidationsProps) => {
    const {t} = useTranslation()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const isRequiredValidator = useIsRequired()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        const failingValidator =
          (isRequired &&
            isRequiredValidator(e.target.value) &&
            isRequiredValidator) ||
          Object.values(validators).find((validator) =>
            validator(e.target.value)
          )
        if (failingValidator) {
          setErrorMessage(failingValidator(e.target.value))
        } else {
          if (onChange) {
            onChange(e)
          }
          setErrorMessage(undefined)
        }
      }, SIDE_PANEL_WAIT_TIME_FOR_AUTOSAVE),
      [isRequired, isRequiredValidator, onChange, validators]
    )
    return (
      <TextField
        onChange={handleChange}
        error={Boolean(errorMessage)}
        helperText={
          errorMessage ||
          (isRequired
            ? t('*Required. {{helperText}}', {helperText})
            : helperText)
        }
        {...props}
      />
    )
  }
