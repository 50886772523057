import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, Button, IconButton} from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ProductMode,
  ProductsFilterInput,
  ProductsQuery,
  ProductState
} from '../../../../__generated__/schema'
import {useTranslateProductMode} from '../../../../hooks/translateProductMode'
import {useTranslateProductState} from '../../../../hooks/translateProductState'
import {useTranslateUnit} from '../../../../hooks/translateUnit'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useDataGridPagination
} from '../../../common/DataGridTable'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {productStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {useGetProducts} from './graphql'

const StateRenderer = ({state}: {state: ProductState}) => {
  const translateProductState = useTranslateProductState()
  return (
    <EntityStateChip
      colorConf={productStateColors[state]}
      label={translateProductState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.products.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const useUnitFormatter = () => {
  const translateUnit = useTranslateUnit()
  return useCallback(
    (params: GridValueFormatterParams) =>
      params.value ? translateUnit(params.value) : '',
    [translateUnit]
  )
}

interface IProductsListProps {
  searchFilter: ProductsFilterInput
  onCreateProductButtonClick: () => void
}

export const ProductsList: React.FC<IProductsListProps> = ({
  onCreateProductButtonClick,
  searchFilter
}: IProductsListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [state, setState] = useState<ProductState | undefined>(undefined)
  const [mode, setMode] = useState<ProductMode | undefined>(undefined)
  const {resetPaginationModel, paginationInput, getDataGridPaginationProps} =
    useDataGridPagination()
  const {data, loading, error} = useGetProducts({
    filter: {...searchFilter, state, mode},
    paginationInput
  })
  const unitFormatter = useUnitFormatter()
  const translateProductMode = useTranslateProductMode()
  const translateProductState = useTranslateProductState()
  const fabClasses = useFabClasses()
  const handleStateChange = useCallback(
    (state?: ProductState) => {
      setState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleModeChange = useCallback(
    (mode?: ProductMode) => {
      setMode(mode)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 350,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ProductState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Measurement unit'),
        field: 'unit',
        valueFormatter: unitFormatter,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Product type'),
        field: 'productType.name',
        valueGetter: (params) => params.row.productType.name,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Product mode'),
        field: 'mode',
        valueFormatter: ({value}) => translateProductMode(value),
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('PLU'),
        field: 'priceLookupCode',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Product number'),
        field: 'internalCode',
        minWidth: 200,
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [t, translateProductMode, unitFormatter]
  )
  return (
    <RenderOnData<ProductsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading products')}
      dataCondition={(data) => Array.isArray(data.products.items)}
      ignoreLoadingIfData
    >
      {({products}) => (
        <>
          <Box
            sx={{
              height: '100%'
            }}
          >
            <SubHeaderToolbar
              title={t('Overview')}
              leftActions={[
                <ChipWithOptions<ProductState>
                  key="state-chip"
                  selectedItem={state}
                  setSelectedItem={handleStateChange}
                  options={[
                    ProductState.Active,
                    ProductState.Inactive,
                    ProductState.Disabled
                  ].map((state) => ({
                    label: translateProductState(state),
                    option: state
                  }))}
                  allText={t('All products')}
                  size="small"
                />,
                <ChipWithOptions<ProductMode>
                  key="mode-chip"
                  selectedItem={mode}
                  setSelectedItem={handleModeChange}
                  options={[
                    ProductMode.Product,
                    ProductMode.WarehouseProduct,
                    ProductMode.WarehouseRecipe
                  ].map((mode) => ({
                    label: translateProductMode(mode),
                    option: mode
                  }))}
                  allText={t('All modes')}
                  size="small"
                />
              ]}
            />
            <Box sx={{height: 'calc(100% - 112px)', width: '100%', p: 3}}>
              {products.items.length === 0 && !state && !loading ? (
                <Blank
                  title={t('No products found')}
                  description={t(
                    'Create products or additional services that you are offering and sell more. Get sale processes and all data under one roof.'
                  )}
                  actions={
                    P([PermissionCode.CreateProduct]) && (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onCreateProductButtonClick}
                      >
                        {t('Create product')}
                      </Button>
                    )
                  }
                />
              ) : (
                <DataGridTable
                  columns={columns}
                  loading={loading}
                  rows={products.items}
                  disableColumnMenu
                  disableRowSelectionOnClick
                  initialState={{
                    pinnedColumns: {left: ['name'], right: ['id']}
                  }}
                  columnVisibilityModel={{
                    id: P([PermissionCode.ReadProduct])
                  }}
                  localeText={{noRowsLabel: t('No products to show')}}
                  {...getDataGridPaginationProps(products.pagination)}
                />
              )}
            </Box>
          </Box>
          {P([PermissionCode.CreateProduct]) && products.items.length > 0 && (
            <CreateFab
              onClick={onCreateProductButtonClick}
              classes={fabClasses}
            />
          )}
        </>
      )}
    </RenderOnData>
  )
}
