import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../theme'

const useDetailItemStyles = makeStyles<Theme, {isClickable: boolean}>(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
      minHeight: theme.spacing(6),
      cursor: ({isClickable}) => (isClickable ? 'pointer' : 'normal'),
      borderBottom: `solid ${theme.palette.divider} 1px`,
      '&:last-child': {
        borderBottom: 0
      }
    }
  })
)

interface IAccordionDetailsItemWrapperProps {
  onClick?(): void
  children: React.ReactNode
}
export const AccordionDetailsItemWrapper: React.FC<IAccordionDetailsItemWrapperProps> =
  ({onClick, children}: IAccordionDetailsItemWrapperProps) => {
    const styles = useDetailItemStyles({isClickable: !!onClick})
    return (
      <div className={styles.root} onClick={onClick}>
        {children}
      </div>
    )
  }
