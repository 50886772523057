import {ApolloError} from 'apollo-client'
import {orderBy} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import {
  ErrorMessages,
  ReservationOnApplyDiscountsPageQuery,
  SellingChannel
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'
import {DiscountCodeErrorDialog} from '../DiscountCodeErrorDialog'
import {
  useAddDiscountToReservationTicketItems,
  useApplyDiscountForReservation,
  useDecrementDiscountQuantityFromReservationTicketItems,
  useIncrementDiscountQuantityForReservationTicketItems,
  useRemoveDiscountFromReservationTicketItem
} from '../graphql'
import {useDiscountErrorHandler} from '../utils'
import {ApplyDiscountsPageTemplate} from './ApplyDiscountsPageTemplate'
import {ChildrenOnValidEventData} from './ChildrenOnValidEventData'
import {ChildrenOnValidTourTimeSlotData} from './ChildrenOnValidTourTimeSlotData'
import {Content} from './Content'
import {TourTimeSlotContent} from './TourTimeSlotContent'

export const ApplyDiscountsForReservation = () => {
  const params = useParams<{reservationId: string}>()
  const reservationId = parseInt(params.reservationId, 10)
  const history = useHistory()
  const handleBackArrowClick = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.reservationPreview(reservationId))
  }, [reservationId, history])
  const {data, loading, error} = useApplyDiscountForReservation(reservationId, {
    channel: SellingChannel.Retail
  })
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const discountErrorHandler = useDiscountErrorHandler()
  const {t} = useTranslation()

  const addDiscountToReservationTicketItems =
    useAddDiscountToReservationTicketItems()
  const removeDiscountFromReservationTicketItem =
    useRemoveDiscountFromReservationTicketItem()
  const incrementDiscountQuantityForReservationTicketItems =
    useIncrementDiscountQuantityForReservationTicketItems()
  const decrementDiscountQuantityFromReservationTicketItems =
    useDecrementDiscountQuantityFromReservationTicketItems()

  const handleAddButtonClick = useCallback(
    async ({
      discountId,
      selectedItemIds,
      discountCodeId,
      customerLoyaltyId,
      onSuccessCallback
    }: {
      discountId: number
      selectedItemIds: number[]
      discountCodeId?: number
      customerLoyaltyId?: string
      onSuccessCallback?: () => void
    }) => {
      try {
        setShowBackdrop(true)
        await addDiscountToReservationTicketItems({
          reservationId,
          itemIds: selectedItemIds,
          discountId,
          discountCodeId,
          customerLoyaltyId
        })
        onSuccessCallback && onSuccessCallback()
      } catch (e) {
        discountErrorHandler(e, t('Adding discount to ticket items failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addDiscountToReservationTicketItems,
      discountErrorHandler,
      reservationId,
      setShowBackdrop,
      t
    ]
  )

  const getChipDeleteButtonClickHandler = useCallback(
    (itemId: number, discountId: number) => async () => {
      try {
        setShowBackdrop(true)
        await removeDiscountFromReservationTicketItem({
          discountId,
          reservationId,
          itemId
        })
      } catch (e) {
        defaultErrorHandler(e, t('Removing discount from ticket item failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      defaultErrorHandler,
      removeDiscountFromReservationTicketItem,
      reservationId,
      setShowBackdrop,
      t
    ]
  )

  const [exceededUsageLimitError, setExceededUsageLimitError] =
    useState<ApolloError | null>(null)
  const incrementDiscountQuantity = useCallback(
    async ({
      discountId,
      discountCodeId,
      increment,
      notificationOnSuccess,
      customerLoyaltyId,
      onSuccessCallback
    }: {
      discountId: number
      discountCodeId?: number
      increment?: number
      notificationOnSuccess?: string
      customerLoyaltyId?: string
      onSuccessCallback?: () => void
    }) => {
      try {
        setShowBackdrop(true)
        await incrementDiscountQuantityForReservationTicketItems({
          reservationId,
          discountId,
          discountCodeId,
          increment,
          customerLoyaltyId
        })
        notificationOnSuccess && addInfoNotification(notificationOnSuccess)
        onSuccessCallback && onSuccessCallback()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.DiscountCodeHasExceededUsageLimit
          )
        ) {
          setExceededUsageLimitError(error)
        } else {
          discountErrorHandler(
            error,
            t('Adding discount to ticket items failed')
          )
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      discountErrorHandler,
      incrementDiscountQuantityForReservationTicketItems,
      reservationId,
      setShowBackdrop,
      t
    ]
  )

  const decrementDiscountQuantity = useCallback(
    async ({
      discountId,
      discountCodeId,
      decrement,
      notificationOnSuccess
    }: {
      discountId: number
      discountCodeId?: number
      decrement?: number
      notificationOnSuccess?: string
    }) => {
      try {
        setShowBackdrop(true)
        await decrementDiscountQuantityFromReservationTicketItems({
          reservationId,
          discountCodeId,
          discountId,
          decrement
        })
        notificationOnSuccess && addInfoNotification(notificationOnSuccess)
      } catch (e) {
        defaultErrorHandler(e, t('Removing discount from ticket items failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      decrementDiscountQuantityFromReservationTicketItems,
      defaultErrorHandler,
      reservationId,
      setShowBackdrop,
      t
    ]
  )

  return (
    <ApplyDiscountsPageTemplate onBackArrowClick={handleBackArrowClick}>
      <RenderOnData
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Could not load reservation detail data')}
        dataCondition={(data) => Boolean(data.reservation)}
      >
        {(data: ReservationOnApplyDiscountsPageQuery) => {
          const ticketItems = (
            orderBy(data.reservation.items, 'id', 'asc') || []
          ).filter(isTicketItemPropertiesFragment)
          const tourItems = (
            orderBy(data.reservation.items, 'id', 'asc') || []
          ).filter(isTourItemPropertiesFragment)
          return (
            <>
              {data.reservation.event ? (
                <ChildrenOnValidEventData
                  ticketItems={ticketItems}
                  event={data.reservation.event}
                >
                  <>
                    {exceededUsageLimitError && (
                      <DiscountCodeErrorDialog
                        error={exceededUsageLimitError}
                        onConfirm={() => setExceededUsageLimitError(null)}
                      />
                    )}
                    <Content
                      ticketItems={ticketItems}
                      discounts={data.reservation.event.enabledDiscounts}
                      eventId={data.reservation.event.id}
                      onAddButtonClick={handleAddButtonClick}
                      incrementDiscountQuantity={incrementDiscountQuantity}
                      decrementDiscountQuantity={decrementDiscountQuantity}
                      getChipDeleteButtonClickHandler={
                        getChipDeleteButtonClickHandler
                      }
                    />
                    )
                  </>
                </ChildrenOnValidEventData>
              ) : (
                data.reservation.tourTimeSlot && (
                  <ChildrenOnValidTourTimeSlotData tourItems={tourItems}>
                    <TourTimeSlotContent tourItems={tourItems} />
                  </ChildrenOnValidTourTimeSlotData>
                )
              )}
            </>
          )
        }}
      </RenderOnData>
    </ApplyDiscountsPageTemplate>
  )
}
