import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  TourGeneralPageQuery,
  TourGeneralPageQueryVariables
} from '../../../../../../__generated__/schema'
import {RenderOnData} from '../../../../../common'
import {GET_TOUR_GENERAL_PAGE} from './graphql'
import {UpdateTourForm} from './UpdateTourForm'

interface IGeneralProps {
  tourId: number
}

export const General: React.FC<IGeneralProps> = ({tourId}: IGeneralProps) => {
  const {data, loading, error} = useQuery<
    TourGeneralPageQuery,
    TourGeneralPageQueryVariables
  >(GET_TOUR_GENERAL_PAGE, {
    variables: {id: tourId}
  })
  const {t} = useTranslation()
  return (
    <RenderOnData<TourGeneralPageQuery>
      data={data}
      error={error}
      loading={loading}
      errorMessage={t<string>('Failed to load tour data')}
    >
      {(data: TourGeneralPageQuery) => <UpdateTourForm data={data} />}
    </RenderOnData>
  )
}
