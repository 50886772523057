import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {
  isResizeEnabledSelector,
  isSomeObjectSelectedSelector
} from '../redux/objects/selectors'
import {ResizeIcon, RotationIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const TransformPanel: React.FC = () => {
  const {setTransformMode} = useEditorModeActions()
  const {t} = useTranslation()
  const {selectedMode, modeConfigs} = useSelector(editorModeSelector)
  const isResizeEnabled = useSelector(isResizeEnabledSelector)
  const isSomeObjectSelected = useSelector(isSomeObjectSelectedSelector)

  const onResizingClick = () => {
    setTransformMode({resizing: true, rotation: false})
  }

  const onRotationClick = () => {
    setTransformMode({resizing: false, rotation: true})
  }

  return (
    <Panel>
      <PanelButton
        disabled={!isResizeEnabled}
        onClick={onResizingClick}
        cypressId="resize-button"
        tooltip={t('Resize')}
        selected={
          selectedMode === EditorMode.TRANSFORM &&
          modeConfigs[EditorMode.TRANSFORM].resizing
        }
      >
        <ResizeIcon />
      </PanelButton>
      <PanelButton
        disabled={!isSomeObjectSelected}
        onClick={onRotationClick}
        cypressId="rotate-button"
        tooltip={t('Rotate')}
        selected={
          selectedMode === EditorMode.TRANSFORM &&
          modeConfigs[EditorMode.TRANSFORM].rotation
        }
      >
        <RotationIcon />
      </PanelButton>
    </Panel>
  )
}
