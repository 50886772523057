import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InputRow} from '../../../../../common'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {
  ButtonGroupBlockSettingsFormField,
  IButtonGroupBlockSettingsForm
} from '../editBlockDrawer/types'

const UPDATE_SETTINGS_FORM_ID = 'updateSettingsForm'

interface ISettingsDialogProps {
  isOpen: boolean
  onClose: () => void
  defaultValues: IButtonGroupBlockSettingsForm
  onSubmit: (data: IButtonGroupBlockSettingsForm) => void
}

export const SettingsDialog: React.FC<ISettingsDialogProps> = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit
}: ISettingsDialogProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    handleSubmit,
    reset
  } = useForm<IButtonGroupBlockSettingsForm>({defaultValues, mode: 'onChange'})
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit settings')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <form onSubmit={handleSubmit(onSubmit)} id={UPDATE_SETTINGS_FORM_ID}>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IButtonGroupBlockSettingsForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={ButtonGroupBlockSettingsFormField.ClassName}
                key={ButtonGroupBlockSettingsFormField.ClassName}
                label={t('Class name')}
                fullWidth
              />
            ]}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={UPDATE_SETTINGS_FORM_ID}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
