import {
  IconShape,
  ICoords,
  IIcon,
  ISeat,
  IZone,
  SeatShape,
  ShapeVariant
} from '@attendio/shared-components'
import Konva from 'konva'
import {
  ICON_CORNER_RADIUS,
  SEAT_BORDER_RADIUS,
  SEAT_SIZE,
  TEXT_SIZE
} from '../config'

import {ObjectsStateValue} from '../redux/objects/types'
import {CanvasObjectType} from '../redux/types'
import {IRectangle, IShape, IText} from '../types'
import {degreesToRadians, rotatePointAroundAnother} from './math'

interface IRectangleCoords {
  topLeft: ICoords
  topRight: ICoords
  bottomLeft: ICoords
  bottomRight: ICoords
}

const rectangleToRectangleCoords = (
  rectangle: IRectangle
): IRectangleCoords => {
  const {x, y, width, height} = rectangle

  return {
    topLeft: {x, y},
    topRight: {x: x + width, y},
    bottomLeft: {x, y: y + height},
    bottomRight: {x: x + width, y: y + height}
  }
}

export const mergeBoundingRects = (
  boundingRects: Array<IRectangle>
): IRectangle => {
  const xMin = Math.min(...boundingRects.map((rect) => rect.x))
  const yMin = Math.min(...boundingRects.map((rect) => rect.y))
  const xMax = Math.max(...boundingRects.map((rect) => rect.x + rect.width))
  const yMax = Math.max(...boundingRects.map((rect) => rect.y + rect.height))

  return {
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin
  }
}

const pointBoundingRect = (point: ICoords): IRectangle => ({
  ...point,
  width: 0,
  height: 0
})

interface ICircle {
  center: ICoords
  radius: number
}

const circleBoundingRect = ({center, radius}: ICircle): IRectangle => ({
  x: center.x - radius,
  y: center.y - radius,
  width: radius * 2,
  height: radius * 2
})

interface IRoundedRectangle {
  rectangle: IRectangle
  rotation: number
  cornerRadius: {
    topLeft?: number
    topRight?: number
    bottomLeft?: number
    bottomRight?: number
  }
}

/**
 * 1. Get coordinates of rectangle corners (without roundings).
 * 2. Get "center" points of rectangle corners:
 *   - for the square corner, it's the same as in the previous step,
 *   - for the rounded corner, it's the center of the inscribed circle.
 * 3. Get the center of the rectangle.
 * 4. Rotate the points from step 2 around the point from step 3.
 * 5. Get bounding rectangles for corners:
 *   - for the square corner, it's a rectangle with zero dimensions,
 *   - for the rounded corner, it's a bounding rectangle of the inscribed circle.
 * 6. Merge bounding rectangles from the previous step into one.
 */
const roundedRectangleBoundingRect = ({
  rectangle,
  rotation,
  cornerRadius
}: IRoundedRectangle): IRectangle => {
  // 1
  const {topLeft, topRight, bottomLeft, bottomRight} =
    rectangleToRectangleCoords(rectangle)

  // 2
  const topLeftCenter = cornerRadius.topLeft
    ? {x: topLeft.x + cornerRadius.topLeft, y: topLeft.y + cornerRadius.topLeft}
    : topLeft

  const topRightCenter = cornerRadius.topRight
    ? {
        x: topRight.x - cornerRadius.topRight,
        y: topRight.y + cornerRadius.topRight
      }
    : topRight

  const bottomLeftCenter = cornerRadius.bottomLeft
    ? {
        x: bottomLeft.x + cornerRadius.bottomLeft,
        y: bottomLeft.y - cornerRadius.bottomLeft
      }
    : bottomLeft

  const bottomRightCenter = cornerRadius.bottomRight
    ? {
        x: bottomRight.x - cornerRadius.bottomRight,
        y: bottomRight.y - cornerRadius.bottomRight
      }
    : bottomRight

  // 3
  const {x, y, width, height} = rectangle
  const rectangleCenter = {x: x + width / 2, y: y + height / 2}

  // 4
  const topLeftCenterRotated = rotatePointAroundAnother({
    point: topLeftCenter,
    angle: rotation,
    center: rectangleCenter
  })

  const topRightCenterRotated = rotatePointAroundAnother({
    point: topRightCenter,
    angle: rotation,
    center: rectangleCenter
  })

  const bottomLeftCenterRotated = rotatePointAroundAnother({
    point: bottomLeftCenter,
    angle: rotation,
    center: rectangleCenter
  })

  const bottomRightCenterRotated = rotatePointAroundAnother({
    point: bottomRightCenter,
    angle: rotation,
    center: rectangleCenter
  })

  // 5
  const topLeftBoundingRect = cornerRadius.topLeft
    ? circleBoundingRect({
        center: topLeftCenterRotated,
        radius: cornerRadius.topLeft
      })
    : pointBoundingRect(topLeftCenterRotated)

  const topRightBoundingRect = cornerRadius.topRight
    ? circleBoundingRect({
        center: topRightCenterRotated,
        radius: cornerRadius.topRight
      })
    : pointBoundingRect(topRightCenterRotated)

  const bottomLeftBoundingRect = cornerRadius.bottomLeft
    ? circleBoundingRect({
        center: bottomLeftCenterRotated,
        radius: cornerRadius.bottomLeft
      })
    : pointBoundingRect(bottomLeftCenterRotated)

  const bottomRightBoundingRect = cornerRadius.bottomRight
    ? circleBoundingRect({
        center: bottomRightCenterRotated,
        radius: cornerRadius.bottomRight
      })
    : pointBoundingRect(bottomRightCenterRotated)

  // 6
  return mergeBoundingRects([
    topLeftBoundingRect,
    topRightBoundingRect,
    bottomLeftBoundingRect,
    bottomRightBoundingRect
  ])
}

const rectangleBoundingRect = ({
  rectangle,
  rotation
}: Omit<IRoundedRectangle, 'cornerRadius'>): IRectangle =>
  roundedRectangleBoundingRect({rectangle, rotation, cornerRadius: {}})

interface IEllipseBoundingRectProps {
  center: ICoords
  radiusX: number
  radiusY: number
  rotation: number // degrees
}

// See: https://jsfiddle.net/Kolosovsky/sLc7ynd1/
const ellipseBoundingRect = ({
  center,
  radiusX,
  radiusY,
  rotation
}: IEllipseBoundingRectProps): IRectangle => {
  // ellipse axes angles
  const radians = degreesToRadians(rotation)
  const radians90 = radians + Math.PI / 2

  // ellipse axes endpoints (one of each)
  const ux = radiusX * Math.cos(radians)
  const uy = radiusX * Math.sin(radians)
  const vx = radiusY * Math.cos(radians90)
  const vy = radiusY * Math.sin(radians90)

  // bounding rectangle
  const width = Math.sqrt(ux * ux + vx * vx) * 2
  const height = Math.sqrt(uy * uy + vy * vy) * 2
  const x = center.x - width / 2
  const y = center.y - height / 2

  return {x, y, width, height}
}

interface ILineBoundingRect {
  startPoint: ICoords
  endPoint: ICoords
  rotation: number // degrees
}

const lineBoundingRect = ({
  startPoint,
  endPoint,
  rotation
}: ILineBoundingRect): IRectangle => {
  const centerPoint = {
    x: (startPoint.x + endPoint.x) / 2,
    y: (startPoint.y + endPoint.y) / 2
  }

  const rotatedStartPoint = rotatePointAroundAnother({
    point: startPoint,
    angle: rotation,
    center: centerPoint
  })

  const rotatedEndPoint = rotatePointAroundAnother({
    point: endPoint,
    angle: rotation,
    center: centerPoint
  })

  const minX = Math.min(rotatedStartPoint.x, rotatedEndPoint.x)
  const maxX = Math.max(rotatedStartPoint.x, rotatedEndPoint.x)
  const minY = Math.min(rotatedStartPoint.y, rotatedEndPoint.y)
  const maxY = Math.max(rotatedStartPoint.y, rotatedEndPoint.y)

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY
  }
}

export const canvasObjectBoundingRect = ({
  config: objectConfig,
  type: objectType
}: ObjectsStateValue): IRectangle | null => {
  if (!objectConfig) {
    return null
  }

  if (objectType === CanvasObjectType.Icon) {
    const iconConfig = objectConfig as IIcon
    const {
      coords: {x, y},
      dimensions: {width, height},
      iconShape,
      rotation
    } = iconConfig

    if (iconShape === IconShape.Ellipse) {
      return ellipseBoundingRect({
        center: {
          x: x + width / 2,
          y: y + height / 2
        },
        radiusX: width / 2,
        radiusY: height / 2,
        rotation
      })
    }

    if (iconShape === IconShape.Rectangle) {
      return roundedRectangleBoundingRect({
        rectangle: {x, y, width, height},
        rotation,
        cornerRadius: {
          topLeft: ICON_CORNER_RADIUS,
          topRight: ICON_CORNER_RADIUS,
          bottomLeft: ICON_CORNER_RADIUS,
          bottomRight: ICON_CORNER_RADIUS
        }
      })
    }
  }

  if (objectType === CanvasObjectType.Seat) {
    const seatConfig = objectConfig as ISeat
    const {
      coords: {x, y},
      rotation,
      seatShape
    } = seatConfig

    if (seatShape === SeatShape.Classic) {
      return roundedRectangleBoundingRect({
        rectangle: {x, y, width: SEAT_SIZE, height: SEAT_SIZE},
        rotation,
        cornerRadius: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: SEAT_BORDER_RADIUS,
          bottomRight: SEAT_BORDER_RADIUS
        }
      })
    }

    if (seatShape === SeatShape.Left) {
      return roundedRectangleBoundingRect({
        rectangle: {x, y, width: SEAT_SIZE, height: SEAT_SIZE},
        rotation,
        cornerRadius: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: SEAT_BORDER_RADIUS,
          bottomRight: 0
        }
      })
    }

    if (seatShape === SeatShape.Middle || seatShape === SeatShape.Invalid) {
      return rectangleBoundingRect({
        rectangle: {x, y, width: SEAT_SIZE, height: SEAT_SIZE},
        rotation
      })
    }

    if (seatShape === SeatShape.Right) {
      return roundedRectangleBoundingRect({
        rectangle: {x, y, width: SEAT_SIZE, height: SEAT_SIZE},
        rotation,
        cornerRadius: {
          topLeft: 0,
          topRight: 0,
          bottomLeft: 0,
          bottomRight: SEAT_BORDER_RADIUS
        }
      })
    }

    if (seatShape === SeatShape.Round) {
      return circleBoundingRect({
        center: {x: x + SEAT_SIZE / 2, y: y + SEAT_SIZE / 2},
        radius: SEAT_SIZE / 2
      })
    }
  }

  if (objectType === CanvasObjectType.Shape) {
    const shapeConfig = objectConfig as IShape
    const {
      coords: {x, y},
      dimensions: {width, height},
      rotation,
      shapeVariant
    } = shapeConfig

    if (shapeVariant === ShapeVariant.Ellipse) {
      return ellipseBoundingRect({
        center: {
          x: x + width / 2,
          y: y + height / 2
        },
        radiusX: width / 2,
        radiusY: height / 2,
        rotation
      })
    }

    if (shapeVariant === ShapeVariant.Line) {
      return lineBoundingRect({
        startPoint: {x, y},
        endPoint: {
          x: x + width,
          y: y + height
        },
        rotation
      })
    }

    if (shapeVariant === ShapeVariant.Rectangle) {
      return rectangleBoundingRect({
        rectangle: {x, y, width, height},
        rotation
      })
    }
  }

  if (objectType === CanvasObjectType.Text) {
    const textConfig = objectConfig as IText
    const {
      coords: {x, y},
      rotation,
      label
    } = textConfig

    const textComponent = new Konva.Text({
      fontSize: TEXT_SIZE,
      text: label
    })

    return rectangleBoundingRect({
      rectangle: {
        x,
        y,
        width: textComponent.width(),
        height: textComponent.height()
      },
      rotation
    })
  }

  if (objectType === CanvasObjectType.Zone) {
    const zoneConfig = objectConfig as IZone
    const {
      coords: {x, y},
      dimensions: {width, height},
      rotation
    } = zoneConfig

    return rectangleBoundingRect({rectangle: {x, y, width, height}, rotation})
  }

  return null
}
