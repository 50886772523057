import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Discount,
  DiscountApplication,
  EventPricing,
  EventTicketType
} from '../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../hooks/formatDiscountValue'
import {useTranslateEffectiveClientPrice} from '../../../hooks/translateCurrencies'
import {Theme} from '../../../theme'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple
} from '../DrawerUtils'

const useStyles = makeStyles<Theme>((theme) => ({
  recordingIcon: {
    marginRight: theme.spacing(2)
  },
  ticketItem: {display: 'flex', alignItems: 'center'},
  discountItem: {display: 'flex', alignItems: 'center'}
}))

type EventPick = {
  activePricing: Pick<EventPricing, 'id'> & {
    ticketTypes: Pick<
      EventTicketType,
      'id' | 'color' | 'description' | 'price' | 'name'
    >[]
  }
  enabledDiscounts: Pick<
    Discount,
    'id' | 'application' | 'name' | 'type' | 'value'
  >[]
}

interface IPricesAndDiscountsDrawerSectionProps<T extends EventPick> {
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
  event: T
}

export const PricesAndDiscountsDrawerSection = <T extends EventPick>({
  drawerSectionProps,
  event
}: IPricesAndDiscountsDrawerSectionProps<T>) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  const formatDiscountValue = useFormatDiscountValue()
  return (
    <DrawerSection {...drawerSectionProps}>
      <SectionGrid>
        <SectionGridCouple label={t('Ticket types')}>
          <Grid container direction="column" spacing={2}>
            {event.activePricing.ticketTypes.map((ticketType) => (
              <Grid item key={ticketType.id}>
                <Typography variant="body2" className={classes.ticketItem}>
                  {ticketType.color && (
                    <FiberManualRecordIcon
                      className={classes.recordingIcon}
                      htmlColor={ticketType.color}
                      fontSize="inherit"
                    />
                  )}
                  {ticketType.description || ticketType.name}&nbsp;(
                  {translatePrice(ticketType.price || 0)})
                </Typography>
              </Grid>
            ))}
          </Grid>
        </SectionGridCouple>
        <SectionGridCouple label={t('Discounts')}>
          <Grid container direction="column" spacing={2}>
            {event.enabledDiscounts.map((discount) => (
              <Grid item key={discount.id}>
                <Typography variant="body2" className={classes.discountItem}>
                  {discount.application === DiscountApplication.Code
                    ? t(`{{name}} (- {{value}}, Discount code)`, {
                        name: discount.name,
                        value: formatDiscountValue(discount)
                      })
                    : discount.application === DiscountApplication.Customer
                    ? t(`{{name}} (- {{value}}, Registered customer)`, {
                        name: discount.name,
                        value: formatDiscountValue(discount)
                      })
                    : t(`{{name}} (- {{value}})`, {
                        name: discount.name,
                        value: formatDiscountValue(discount)
                      })}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </SectionGridCouple>
      </SectionGrid>
    </DrawerSection>
  )
}
