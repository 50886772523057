import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {CashDeskAdmissionRateFieldsFragment} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {SaveButton} from '../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../common/DialogTitleWithCloseButton'
import {OutlinedInputWithCancelAdornment} from '../../../common/OutlinedInputWithCancelAdornment'
import {TourTimeSlotQuantityDescription} from '../components/TourTimeSlotQuantityDescription'

interface IEditTourTicketQuantityDialogProps {
  onClose: () => void
  admissionTypesAssignment: CashDeskAdmissionRateFieldsFragment['admissionTypesAssignments'][number]
  itemsInCart: number
  onSaveClick: (args: {
    admissionTypeAssignmentId: number
    increment?: number
    decrement?: number
    admissionTypeName: string
  }) => void
}

export const EditTourTicketQuantityDialog: React.FC<IEditTourTicketQuantityDialogProps> =
  ({
    onClose,
    admissionTypesAssignment,
    itemsInCart,
    onSaveClick
  }: IEditTourTicketQuantityDialogProps) => {
    const {t} = useTranslation()
    const [quantity, setQuantity] = useState<number>(itemsInCart)
    const [error, setError] = useState<boolean>(false)
    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
    const handleQuantityChange = useCallback((e) => {
      const parsedQuantity = parseInt(e.target.value, 10)
      if (!isNaN(parsedQuantity) && parsedQuantity > -1) {
        setQuantity(parsedQuantity)
      } else {
        setQuantity(0)
      }
    }, [])
    const handleCancelButtonClick = useCallback(() => setQuantity(0), [])
    const handleDecrementClick = useCallback(
      () =>
        setQuantity((prevQuantity) =>
          prevQuantity > 0
            ? prevQuantity <=
              admissionTypesAssignment.admissionType.startingQuantity
              ? 0
              : prevQuantity - 1
            : prevQuantity
        ),
      [admissionTypesAssignment.admissionType.startingQuantity]
    )
    const handleIncrementClick = useCallback(
      () =>
        setQuantity((prevQuantity) =>
          prevQuantity < admissionTypesAssignment.admissionType.startingQuantity
            ? admissionTypesAssignment.admissionType.startingQuantity
            : prevQuantity + 1
        ),
      [admissionTypesAssignment.admissionType.startingQuantity]
    )
    const handleSaveClick = useCallback(() => {
      const change = quantity - itemsInCart
      onSaveClick({
        admissionTypeAssignmentId: admissionTypesAssignment.id,
        decrement: change < 0 ? -change : undefined,
        increment: change > 0 ? change : undefined,
        admissionTypeName: admissionTypesAssignment.admissionType.name
      })
    }, [
      admissionTypesAssignment.admissionType.name,
      admissionTypesAssignment.id,
      itemsInCart,
      onSaveClick,
      quantity
    ])
    useEffect(() => {
      if (
        quantity < admissionTypesAssignment.admissionType.startingQuantity &&
        quantity !== 0
      ) {
        setError(true)
      } else {
        setError(false)
      }
    }, [admissionTypesAssignment.admissionType.startingQuantity, quantity])
    return (
      <Dialog open onClose={onClose}>
        <DialogTitleWithCloseButton onCloseClick={onClose}>
          {t('Edit ticket quantity')}
        </DialogTitleWithCloseButton>
        <DialogContent
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
          dividers
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateAreas: `
            "title        price"
            "description  description"
            `,
              gridTemplateColumns: '1fr auto'
            }}
          >
            <Typography variant="subtitle2" sx={{gridArea: 'title'}}>
              {admissionTypesAssignment.admissionType.name}
            </Typography>
            <Typography variant="body2" sx={{gridArea: 'price'}}>
              {translateEffectiveClientPrice(
                admissionTypesAssignment.retailPrice
              )}
            </Typography>
            <Box
              sx={{
                gridArea: 'description',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TourTimeSlotQuantityDescription
                capacityDecreaseCount={
                  admissionTypesAssignment.admissionType.capacityDecreaseCount
                }
                startingQuantity={
                  admissionTypesAssignment.admissionType.startingQuantity
                }
              />
              <Typography variant="caption" color="textSecondary">
                {admissionTypesAssignment.admissionType.internalDescription}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              justifyContent: 'space-between',
              alignItems: 'start'
            }}
          >
            <IconButton
              disabled={quantity < 1}
              color="primary"
              onClick={handleDecrementClick}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <div>
              <OutlinedInputWithCancelAdornment
                inputId="quantity-input"
                label={t('Quantity')}
                inputProps={{value: quantity, onChange: handleQuantityChange}}
                onCancelClick={handleCancelButtonClick}
                errorMessage={
                  error
                    ? t('Minimum is {{min}}.', {
                        min: admissionTypesAssignment.admissionType
                          .startingQuantity
                      })
                    : quantity > 200
                    ? t('Maximum allowed value of 200 exceeded')
                    : null
                }
                sx={{width: 216, mx: 1.5}}
                errorSx={{pl: 2}}
              />
            </div>
            <IconButton onClick={handleIncrementClick} color="primary">
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions sx={{px: 3, py: 1.5}}>
          <SaveButton
            disabled={error || quantity > 200}
            onClick={quantity === itemsInCart ? onClose : handleSaveClick}
          />
        </DialogActions>
      </Dialog>
    )
  }
