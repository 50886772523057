import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {debounce} from 'lodash'
import React, {useCallback, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DetailLibraryShowPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useGetClientLocales} from '../../../../../hooks/getLocales'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {routeTo} from '../../../../../utils/routes'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {ShowSection} from '../../../../constants'
import {useBackdropState} from '../../../../context/backdrop'
import {useNotifications} from '../../../../context/notifications'
import {SecondaryHeader} from '../../Header'
import {BottomBarActionsWrapper, CenteredLayout} from '../../Layout'
import {useCreateClientShow, useCreateLibraryShow} from '../graphql'
import {IShowForm} from '../types'
import {
  transformIShowFormToShowInput,
  transformIShowFormToShowTranslationInput
} from '../utils'
import {DatabaseStep} from './DatabaseStep'
import {DETAILS_STEP_FORM_ID, DetailsStep} from './DetailsStep'

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    paddingTop: theme.spacing(8)
  },
  iconColor: {
    color: theme.palette.text.secondary
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

enum AddShowStep {
  Database,
  Details
}

const useTranslateAddShowStep = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<AddShowStep, string> = {
    [AddShowStep.Database]: t<string>('AddShowStep->Database'),
    [AddShowStep.Details]: t<string>('AddShowStep->Details')
  }
  return (code: AddShowStep) => translatedCodes[code]
}

const addShowSteps = [AddShowStep.Database, AddShowStep.Details]

export const AddShow: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(AddShowStep.Database)
  const classes = useStyles()
  const translateAddShowStep = useTranslateAddShowStep()
  const clientLocales = useGetClientLocales()

  const handleBackClick = useCallback(() => {
    const indexOfActiveStep = addShowSteps.findIndex(
      (step) => step === activeStep
    )
    setActiveStep(addShowSteps[indexOfActiveStep - 1])
  }, [activeStep])
  const moveToNextStep = useCallback(() => {
    const indexOfActiveStep = addShowSteps.findIndex(
      (step) => step === activeStep
    )
    setActiveStep(addShowSteps[indexOfActiveStep + 1])
  }, [activeStep])
  const [selectedLibraryShow, setSelectedLibraryShow] =
    useState<DetailLibraryShowPropertiesFragment | null>(null)
  const [defaultTitle, setDefaultTitle] =
    useState<string | undefined>(undefined)
  const handleUseLibraryShowClicked = useCallback(
    (show: DetailLibraryShowPropertiesFragment) => {
      setSelectedLibraryShow(show)
      moveToNextStep()
      setDefaultTitle(undefined)
    },
    [moveToNextStep]
  )
  const createClientShow = useCreateClientShow()
  const createLibraryShow = useCreateLibraryShow()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()

  const handleCreateClientShowSubmit = useCallback(
    async (showForm: IShowForm) => {
      setShowBackdrop(true)
      try {
        let libraryShow: DetailLibraryShowPropertiesFragment
        if (!selectedLibraryShow) {
          const result = await createLibraryShow({
            data: transformIShowFormToShowInput(showForm),
            translation: transformIShowFormToShowTranslationInput(showForm)
          })
          if (!result.data) {
            return
          }
          libraryShow = result.data.createLibraryShow
        } else {
          libraryShow = selectedLibraryShow
        }

        const {data} = await createClientShow({
          showsDbId: libraryShow.id,
          data: {
            ...transformIShowFormToShowInput(showForm),
            videos: libraryShow.videos.map(({source, sourceVideoId}) => ({
              source,
              sourceVideoId
            }))
          },
          translation: transformIShowFormToShowTranslationInput(showForm)
        })
        if (data) {
          addInfoNotification(t('Client show created'))
          history.replace(
            routeTo.admin.shows.editWithEditSection(
              data.createClientShow.id,
              clientLocales.length > 1
                ? ShowSection.Translations
                : ShowSection.Media
            )
          )
        }
      } catch (e) {
        defaultErrorHandler(e, t('Error while creating client show'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      clientLocales.length,
      createClientShow,
      createLibraryShow,
      defaultErrorHandler,
      history,
      selectedLibraryShow,
      setShowBackdrop,
      t
    ]
  )
  const [searchText, setSearchText] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const handleSearchInputChange = debounce(
    () => setSearchText(inputRef.current?.value || ''),
    400
  )
  const handleClearIconClick = useCallback(() => {
    setSearchText('')
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [])
  const handleCreateNewShowButtonClick = useCallback(() => {
    setSelectedLibraryShow(null)
    setDefaultTitle(searchText)
    setActiveStep(AddShowStep.Details)
  }, [searchText])
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={t('Add show')}
          search={
            activeStep === AddShowStep.Database ? (
              <TextField
                InputProps={{
                  startAdornment: <SearchIcon className={classes.iconColor} />,
                  endAdornment: searchText ? (
                    <IconButton onClick={handleClearIconClick}>
                      <CloseIcon className={classes.iconColor} />
                    </IconButton>
                  ) : undefined
                }}
                onChange={handleSearchInputChange}
                defaultValue={searchText || undefined}
                inputRef={inputRef}
                variant="outlined"
                placeholder={t('Type show name here')}
                fullWidth
              />
            ) : undefined
          }
        />
      }
      footer={
        <>
          {activeStep === AddShowStep.Database &&
            searchText &&
            P([PermissionCode.CreateClientShow]) && (
              <Footer className={classes.footer}>
                <Typography variant="body2">
                  {t('Didn’t find what were you looking for?')}
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleCreateNewShowButtonClick}
                >
                  {t('Create new show')}
                </Button>
              </Footer>
            )}
          {AddShowStep.Database !== activeStep && (
            <BottomBarActionsWrapper>
              <Button onClick={handleBackClick}>{t('Back')}</Button>
              {activeStep === AddShowStep.Details &&
                P([PermissionCode.CreateLibraryShow]) && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    form={DETAILS_STEP_FORM_ID}
                  >
                    {t('Create')}
                  </Button>
                )}
            </BottomBarActionsWrapper>
          )}
        </>
      }
    >
      <CenteredLayout>
        <Stepper
          activeStep={activeStep}
          classes={{
            root: classes.stepper
          }}
          alternativeLabel
        >
          {addShowSteps.map((step) => (
            <Step key={step}>
              <StepLabel>{translateAddShowStep(step)}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === AddShowStep.Database &&
          P([PermissionCode.ReadMixedShows]) && (
            <DatabaseStep
              searchText={searchText}
              onUseLibraryShowClicked={handleUseLibraryShowClicked}
            />
          )}
        {activeStep === AddShowStep.Details && (
          <DetailsStep
            libraryShow={selectedLibraryShow}
            onSubmit={handleCreateClientShowSubmit}
            defaultTitle={defaultTitle}
          />
        )}
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
