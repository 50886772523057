import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {CartsQuery, CartsQueryVariables} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'

export const NARROW_CART_PROPERTIES_FRAGMENT = gql`
  fragment NarrowCartProperties on NarrowCart {
    id
    price
    state
    sellingChannel
    createdAt
    updatedAt
    createdByName
    itemsCount
    lead {
      id
      data {
        name
        companyName
        email
      }
    }
  }
`

const GET_CARTS = gql`
  ${PAGINATION_FRAGMENT}
  ${NARROW_CART_PROPERTIES_FRAGMENT}
  query carts($filter: CartsFilterInput, $paginationInput: PaginationInput!) {
    carts(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowCartProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useCarts = (variables: CartsQueryVariables) =>
  useQuery<CartsQuery, CartsQueryVariables>(GET_CARTS, {
    variables,
    fetchPolicy: 'network-only'
  })
