import {
  CreateVoucherCampaignInput,
  VoucherCampaignPeriodType
} from '../../../../__generated__/schema'
import {convertValueToFloat} from '../../../../utils/conversions'

export enum VoucherCampaignFormLocation {
  Create = 'create',
  Edit = 'edit'
}

export enum VoucherCampaignFormField {
  Name = 'name',
  Description = 'description',
  RedemptionLimit = 'redemptionLimit',
  RechargeLimit = 'rechargeLimit',
  InitialValidity = 'initialValidity',
  InitialBalance = 'initialBalance',
  RetailPaymentMethodId = 'retailPaymentMethodId',
  EcommercePaymentMethodId = 'ecommercePaymentMethodId',
  CanCreateNewVouchers = 'canCreateNewVouchers'
}

export interface IVoucherCampaignForm {
  [VoucherCampaignFormField.Name]: string
  [VoucherCampaignFormField.Description]?: string
  [VoucherCampaignFormField.RedemptionLimit]?: number
  [VoucherCampaignFormField.RechargeLimit]?: number
  [VoucherCampaignFormField.InitialValidity]?: number
  [VoucherCampaignFormField.InitialBalance]?: number
  [VoucherCampaignFormField.RetailPaymentMethodId]?: number
  [VoucherCampaignFormField.EcommercePaymentMethodId]?: number
  [VoucherCampaignFormField.CanCreateNewVouchers]: boolean
}

const getInitialValidity = (value?: number) =>
  value && {
    initialValidity: {
      value,
      period: VoucherCampaignPeriodType.Day
    }
  }

export const transformDataToInput = (
  data: IVoucherCampaignForm
): CreateVoucherCampaignInput => ({
  name: data[VoucherCampaignFormField.Name],
  description: data[VoucherCampaignFormField.Description],
  redemptionLimit: data[VoucherCampaignFormField.RedemptionLimit] || undefined,
  rechargeLimit: data[VoucherCampaignFormField.RedemptionLimit] || undefined,
  initialBalance: data[VoucherCampaignFormField.InitialBalance]
    ? convertValueToFloat(data[VoucherCampaignFormField.InitialBalance])
    : undefined,
  retailPaymentMethodId:
    data[VoucherCampaignFormField.RetailPaymentMethodId] || undefined,
  ecommercePaymentMethodId:
    data[VoucherCampaignFormField.EcommercePaymentMethodId] || undefined,
  ...getInitialValidity(data[VoucherCampaignFormField.InitialValidity])
})
