import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import TodayIcon from '@mui/icons-material/Today'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  NarrowSalePropertiesFragment,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {useEventsPathnameParams} from '../../../../utils/pathname'
import {routeTo} from '../../../../utils/routes'
import {
  BaseSeatDialog,
  ListItemRow,
  ListWrapper,
  TopSummary
} from './BaseSeatDialog'

interface ISoldSeatDialogProps {
  sale: NarrowSalePropertiesFragment
  cartId: number
  onClose: () => void
}

export const SoldSeatDialog: React.FC<ISoldSeatDialogProps> = ({
  sale,
  cartId,
  onClose
}: ISoldSeatDialogProps) => {
  const {eventId} = useEventsPathnameParams()
  const {t} = useTranslation()
  const history = useHistory()
  const handleOnConfirmationButtonClick = useCallback(() => {
    history.replace(
      routeTo.admin.cashDesk.eventAuditoriumPreviewWithCartInfo(eventId, cartId)
    )
  }, [cartId, eventId, history])
  const {formatDateTime} = useDateTimeFormatters()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <BaseSeatDialog
      isOpen
      title={t('Sale #{{saleNumber}}', {
        saleNumber: sale.id
      })}
      confirmationButtonLabel={t('Cart details')}
      onConfirmationButtonClick={handleOnConfirmationButtonClick}
      onClose={onClose}
      sx={(theme) => ({zIndex: theme.zIndex.drawer})}
    >
      <ListWrapper>
        {sale.items && (
          <TopSummary
            Icon={AccountBalanceWalletOutlinedIcon}
            texts={[
              t('{{count}} ticket', {
                count: sale.items.length
              }),
              translateEffectiveClientPrice(sale.price),
              translateSellingChannel(sale.sellingChannel)
            ]}
            tooltip={t('Sale details')}
          />
        )}
        <ListItemRow
          Icon={TodayIcon}
          texts={[
            formatDateTime(new Date(sale.createdAt)),
            sale.sellingChannel === SellingChannel.Retail
              ? sale.createdByName
              : ''
          ].filter(Boolean)}
          tooltip={t('Created at')}
          hasDivider={
            !!sale.lead?.data.name ||
            !!sale.lead?.data.phone ||
            !!sale.lead?.data.email ||
            !!sale.lead?.data.note ||
            !!sale.lead?.data.internalNote
          }
        />
        {sale.lead?.data.name && (
          <ListItemRow
            Icon={PersonOutlinedIcon}
            texts={[sale.lead.data.name]}
            tooltip={t('Customer name')}
          />
        )}
        {sale.lead?.data.phone && (
          <ListItemRow
            Icon={PhoneOutlinedIcon}
            texts={[sale.lead.data.phone]}
            tooltip={t('Customer phone')}
          />
        )}
        {sale.lead?.data.email && (
          <ListItemRow
            Icon={EmailOutlinedIcon}
            texts={[sale.lead.data.email]}
            tooltip={t('Customer e-mail')}
          />
        )}
        {sale.lead?.data.note && (
          <ListItemRow
            Icon={NotesOutlinedIcon}
            texts={[sale.lead.data.note]}
            tooltip={t('Notes')}
          />
        )}
        {sale.lead?.data.internalNote && (
          <ListItemRow
            Icon={!sale.lead?.data.note ? NotesOutlinedIcon : undefined}
            texts={[sale.lead.data.internalNote]}
            tooltip={!sale.lead?.data.note ? t('Notes') : undefined}
          />
        )}
      </ListWrapper>
    </BaseSeatDialog>
  )
}
