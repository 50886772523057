import {Box, Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Scalars} from '../../../../../../__generated__/schema'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {BlockType} from '../../types'
import {isBlockType, useTranslateBlockType} from '../../utils'
import {ButtonGroupBlockContent} from '../buttonGroupBlockContent'
import {CardsRowBlockContent} from '../cardsRowBlockContent'
import {EventsListBlockContent} from './EventsListBlockContent'
import {FeaturedEventPostersBlockContent} from './FeaturedEventPostersBlockContent'
import {FeaturedShowPhotosBlockContent} from './FeaturedShowPhotosBlockContent'
import {LabelBlockContent} from './LabelBlockContent'
import {MarkdownBlockContent} from './MarkdownBlockContent'
import {EditBlockProps, ILabelBlockForm, LabelBlockFormField} from './types'

interface IEditBlockDrawerProps {
  block: Scalars['JSON'] | null
  onClose: () => void
  onApplyButtonClick: (props: EditBlockProps) => void
}

export const EditBlockDrawer: React.FC<IEditBlockDrawerProps> = ({
  block,
  onClose,
  onApplyButtonClick
}: IEditBlockDrawerProps) => {
  const {t} = useTranslation()
  const translateBlockType = useTranslateBlockType()
  const [markdownContent, setMarkdownContent] = useState<string | null>(null)
  const [editBlockProps, setEditBlockProps] =
    useState<EditBlockProps | null>(null)
  const labelBlockFormMethods = useForm<ILabelBlockForm>()
  const {reset: resetLabelBlockForm} = labelBlockFormMethods
  const handleApplyButtonClick = useCallback(async () => {
    if (block?.type === BlockType.Markdown && markdownContent) {
      onApplyButtonClick({content: markdownContent})
      onClose()
    } else if (block?.type === BlockType.Label) {
      const isFormValid = await labelBlockFormMethods.triggerValidation()
      if (isFormValid) {
        onApplyButtonClick(labelBlockFormMethods.getValues())
        onClose()
      }
    } else if (editBlockProps) {
      onApplyButtonClick(editBlockProps)
    }
    onClose()
  }, [
    block?.type,
    markdownContent,
    editBlockProps,
    onClose,
    onApplyButtonClick,
    labelBlockFormMethods
  ])
  useEffect(() => {
    if (block?.type === BlockType.Label) {
      resetLabelBlockForm({
        [LabelBlockFormField.Text]: block?.props?.text ?? '',
        [LabelBlockFormField.ClassName]: block?.props?.className ?? undefined,
        [LabelBlockFormField.Variant]: block?.props?.variant ?? undefined
      })
    }
  }, [block, resetLabelBlockForm])
  const handleBlockPropsChange = useCallback(
    <T extends EditBlockProps>(props: T) => {
      setEditBlockProps(props)
    },
    []
  )
  return (
    <Drawer
      anchor="right"
      open={Boolean(block)}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 960, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={
              isBlockType(block?.type) ? translateBlockType(block.type) : ''
            }
          />
        }
        footer={
          <SaveButton onClick={handleApplyButtonClick}>{t('Apply')}</SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <Box sx={{py: 2, px: 3}}>
          {block?.type === BlockType.Markdown && (
            <MarkdownBlockContent
              setContent={setMarkdownContent}
              content={block?.props?.content ?? ''}
            />
          )}
          {block?.type === BlockType.Label && (
            <FormContext {...labelBlockFormMethods}>
              <LabelBlockContent />
            </FormContext>
          )}
          {[
            BlockType.FeaturedShowPhotos,
            BlockType.FeaturedShowPosters,
            BlockType.FeaturedShowCovers
          ].includes(block?.type) && (
            <FeaturedShowPhotosBlockContent
              blockProps={block.props ?? {}}
              onBlockPropsChange={handleBlockPropsChange}
            />
          )}
          {block?.type === BlockType.FeaturedEventPosters && (
            <FeaturedEventPostersBlockContent
              blockProps={block.props ?? {}}
              onBlockPropsChange={handleBlockPropsChange}
            />
          )}
          {block?.type === BlockType.EventsList && (
            <EventsListBlockContent
              blockProps={block.props ?? {}}
              onBlockPropsChange={handleBlockPropsChange}
            />
          )}
          {block?.type === BlockType.CardsRow && (
            <CardsRowBlockContent
              blockProps={block.props}
              onBlockPropsChange={handleBlockPropsChange}
            />
          )}
          {block?.type === BlockType.ButtonGroup && (
            <ButtonGroupBlockContent
              blockProps={block.props}
              onBlockPropsChange={handleBlockPropsChange}
            />
          )}
        </Box>
      </DrawerTemplate>
    </Drawer>
  )
}
