import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Button,
  Chip,
  chipClasses,
  Collapse,
  Drawer,
  drawerClasses,
  IconButton,
  styled,
  Typography
} from '@mui/material'
import React, {SyntheticEvent, useCallback, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  BaseWarehouseProductFieldsFragment,
  Unit,
  WarehouseDocumentType,
  WarehouseProductsFilterInput
} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateUnit} from '../../../../../hooks/translateUnit'
import {useUserInfo} from '../../../../../utils/auth'
import {
  useIsNonNegativeDecimal,
  useIsPositiveDecimal,
  useIsStringWithMaxLength
} from '../../../../../utils/formsValidations'
import {safeSum} from '../../../../../utils/money'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {LazyLoadingList} from '../../../../common/LazyLoadingList'
import {COLOR_CONF} from '../../../../constants'
import {useLocale} from '../../../../context/locale'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {useCountryVatRate} from '../../graphql'
import {
  AddWarehouseProductFormField,
  IAddWarehouseProductForm
} from '../../warehouseDocuments/detail/types'
import {useWarehouseProducts} from './graphql'
import {WarehouseProductSearch} from './WarehouseProductSearch'

const StyledForm = styled('form')(({theme}) => ({
  paddingTop: theme.spacing(3)
}))

interface IProductCardProps {
  item: BaseWarehouseProductFieldsFragment
  translateUnit: (unit: Unit) => string
  stockFormatter: (stock: number) => string
  vatRates: number[]
  defaultValues?: Partial<IAddWarehouseProductForm>
  onSubmit: (data: IAddWarehouseProductForm) => void
  addedQuantity?: number
  onDelete?: () => void
  warehouseDocumentType?: WarehouseDocumentType
}

const ProductCard: React.FC<IProductCardProps> = ({
  item,
  translateUnit,
  stockFormatter,
  vatRates,
  defaultValues,
  onSubmit,
  addedQuantity,
  onDelete,
  warehouseDocumentType
}: IProductCardProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    reset,
    triggerValidation,
    handleSubmit
  } = useForm<IAddWarehouseProductForm>({defaultValues})
  const {
    state: isCardExpanded,
    toggle: toggleCard,
    setFalse: shrinkCard
  } = useBooleanState(false)
  const isPositiveDecimal6 = useIsPositiveDecimal('1,6')
  const isNonNegativeDecimal = useIsNonNegativeDecimal('1,8')
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const handleArrowClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleCard()
    },
    [toggleCard]
  )
  const formId = useMemo(() => `ADD-ITEM-FORM-${item.id}`, [item.id])
  const _onSubmit = useCallback(
    (formData: IAddWarehouseProductForm) => {
      onSubmit(formData)
      reset(defaultValues)
      shrinkCard()
    },
    [defaultValues, onSubmit, reset, shrinkCard]
  )
  return (
    <Box
      sx={{
        py: 1.5,
        cursor: 'pointer',
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
        ':last-child': {
          borderBottom: 'none'
        }
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          onClick={handleArrowClick}
        >
          <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <Typography variant="subtitle2">{item.product.name}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <Typography variant="caption" color="textSecondary">
                  {[
                    stockFormatter(item.stock),
                    translateUnit(item.product.unit)
                  ].join(' ')}
                </Typography>
                {typeof addedQuantity === 'number' && (
                  <Chip
                    size="small"
                    sx={
                      warehouseDocumentType === WarehouseDocumentType.Outgoing
                        ? {
                            [`&.${chipClasses.root}`]: {
                              ...COLOR_CONF.RED
                            },
                            [`& .${chipClasses.deleteIcon}`]: {
                              color: 'error.main',
                              '&:hover': {
                                color: 'error.dark'
                              }
                            }
                          }
                        : {
                            [`&.${chipClasses.root}`]: {
                              ...COLOR_CONF.GREEN
                            },
                            [`& .${chipClasses.deleteIcon}`]: {
                              color: 'success.main',
                              '&:hover': {
                                color: 'success.dark'
                              }
                            }
                          }
                    }
                    onDelete={onDelete}
                    label={
                      warehouseDocumentType === WarehouseDocumentType.Outgoing
                        ? `- ${addedQuantity}`
                        : `+ ${addedQuantity}`
                    }
                  />
                )}
              </Box>
              <Typography variant="caption" color="textSecondary">
                {item.product.internalCode}
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={handleArrowClick} size="small">
            {isCardExpanded ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </Box>
        <Collapse in={isCardExpanded}>
          <StyledForm id={formId} onSubmit={handleSubmit(_onSubmit)}>
            <input
              type="hidden"
              name={AddWarehouseProductFormField.ProductId}
              ref={register()}
              defaultValue={item.id}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
              <Box
                sx={{
                  gap: 1,
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns: '172px 172px auto'
                }}
              >
                <UncontrolledFormTextInput<IAddWarehouseProductForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={AddWarehouseProductFormField.Quantity}
                  key={AddWarehouseProductFormField.Quantity}
                  label={t('Quantity')}
                  validationOptions={{
                    required: true,
                    validate: isPositiveDecimal6
                  }}
                  margin="dense"
                  fullWidth
                  hideErrorMessage
                />
                <UncontrolledFormTextInput<IAddWarehouseProductForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={AddWarehouseProductFormField.Price}
                  key={AddWarehouseProductFormField.Price}
                  label={t('Unit price VAT exc.')}
                  validationOptions={{
                    required: true,
                    validate: isNonNegativeDecimal
                  }}
                  margin="dense"
                  fullWidth
                  hideErrorMessage
                />
                <UncontrolledFormSelect<IAddWarehouseProductForm>
                  name={AddWarehouseProductFormField.Vat}
                  validationOptions={{required: true}}
                  selectOptions={vatRates.reduce(
                    (acc, vatRate) => ({
                      ...acc,
                      [String(vatRate)]: t('{{vatRate}} %', {vatRate})
                    }),
                    {}
                  )}
                  label={t('VAT')}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  watch={watch}
                  fullWidth
                  margin="dense"
                />
              </Box>
              <Box
                sx={{
                  gap: 1,
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns: '1fr auto'
                }}
              >
                <UncontrolledFormTextInput<IAddWarehouseProductForm>
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  triggerValidation={triggerValidation}
                  name={AddWarehouseProductFormField.Note}
                  key={AddWarehouseProductFormField.Note}
                  label={t('Note')}
                  validationOptions={{
                    validate: isStringWithMaxLength
                  }}
                  margin="dense"
                  fullWidth
                  hideErrorMessage
                  sx={{p: 0, m: 0}}
                />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  form={formId}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Box>
          </StyledForm>
        </Collapse>
      </Box>
    </Box>
  )
}

interface IAddWarehouseProductDrawerProps {
  isOpen: boolean
  onClose: () => void
  warehouseId: number
  onSubmit: (data: IAddWarehouseProductForm) => void
  addedProducts: {id: number; quantity: number; warehouseProductId: number}[]
  onDelete?: (ids: number[]) => void
  warehouseDocumentType?: WarehouseDocumentType
}

export const AddWarehouseProductDrawer: React.FC<IAddWarehouseProductDrawerProps> =
  ({
    isOpen,
    onClose,
    warehouseId,
    onSubmit,
    addedProducts,
    onDelete,
    warehouseDocumentType
  }: IAddWarehouseProductDrawerProps) => {
    const {t} = useTranslation()
    const {localeCode} = useLocale()
    const {effectiveClient} = useUserInfo()
    const defaultSearchObject = useMemo(
      () => ({warehouseIds: [warehouseId]}),
      [warehouseId]
    )
    const [searchFilter, setSearchFilter] =
      useState<WarehouseProductsFilterInput>(defaultSearchObject)
    const {data, loading, error, isLoadingMore, fetchMore} =
      useWarehouseProducts(searchFilter, !isOpen)
    const {data: vatRatesData} = useCountryVatRate(effectiveClient!.countryCode)
    const translateUnit = useTranslateUnit()
    const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
      if (
        !isLoadingMore &&
        data &&
        data?.warehouseProducts.pagination.hasMore
      ) {
        fetchMore()
      }
    }, [data, fetchMore, isLoadingMore])
    const handleDelete = useCallback(
      (ids: number[]) => () => {
        if (onDelete) {
          onDelete(ids)
        }
      },
      [onDelete]
    )
    const formatStock = useCallback(
      (stock: number) => stock.toLocaleString(localeCode),
      [localeCode]
    )
    const handleClose = useCallback(() => {
      setSearchFilter(defaultSearchObject)
      onClose()
    }, [defaultSearchObject, onClose])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          errorMessage={
            error && t<string>('Error while loading warehouse products')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={handleClose}
              title={t('Add products')}
            />
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <LazyLoadingList
            isLoadingMore={isLoadingMore || loading}
            onScrolledNearTheEndOfTheList={handleScrolledNearTheEndOfTheLayout}
          >
            <Box
              sx={(theme) => ({
                px: 2,
                py: 1,
                position: 'sticky',
                top: 0,
                backgroundColor: 'background.paper',
                borderBottom: `1px solid ${theme.palette.divider}`,
                zIndex: 1
              })}
            >
              <WarehouseProductSearch
                onFilterChange={setSearchFilter}
                defaultSearchObject={defaultSearchObject}
              />
            </Box>
            <Box sx={{px: 2, display: 'flex', flexDirection: 'column'}}>
              {data?.warehouseProducts.items.map((item) => {
                const filteredAddedProducts = addedProducts.filter(
                  (product) => product.warehouseProductId === item.id
                )
                return (
                  <ProductCard
                    key={item.id}
                    item={item}
                    translateUnit={translateUnit}
                    stockFormatter={formatStock}
                    vatRates={vatRatesData?.countryVatRates ?? []}
                    defaultValues={{
                      [AddWarehouseProductFormField.Vat]: String(
                        Math.max(...(vatRatesData?.countryVatRates ?? [20]))
                      )
                    }}
                    onSubmit={onSubmit}
                    addedQuantity={
                      filteredAddedProducts.length > 0
                        ? safeSum(
                            filteredAddedProducts.map(({quantity}) => quantity)
                          )
                        : undefined
                    }
                    onDelete={handleDelete(
                      filteredAddedProducts.map(({id}) => id)
                    )}
                    warehouseDocumentType={warehouseDocumentType}
                  />
                )
              })}
            </Box>
          </LazyLoadingList>
        </DrawerTemplate>
      </Drawer>
    )
  }
