import {Popover} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {ChromePicker, ColorResult, CompactPicker} from 'react-color'
import {FieldValues} from 'react-hook-form'
import {isValidHexColor, useIsValidHexColor} from '../../utils/formsValidations'
import {ColorBox} from '../common/ColorBox'
import {
  IUncontrolledFormTextInputProps,
  UncontrolledFormTextInput
} from './FormTextInput'
import {FormFieldName} from './types'

interface IUncontrolledFormColorInputProps<
  FormValues extends FieldValues = FieldValues
> extends IUncontrolledFormTextInputProps<FormValues> {
  colorPickerType?: 'chrome' | 'compact'
}

export const UncontrolledFormColorInput = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  setValue,
  validationOptions,
  colorPickerType = 'chrome',
  ...rest
}: IUncontrolledFormColorInputProps<FormValues>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClickAway = useCallback(() => setAnchorEl(null), [])
  const [color, setColor] = useState<ColorResult['hex'] | undefined>(undefined)
  const isValidHexColorValidator = useIsValidHexColor()
  const handleColorChangeComplete = useCallback(
    (color: ColorResult) => {
      setColor(color.hex)
      setValue(name, color.hex as FormValues[FormFieldName<FormValues>], true)
    },
    [name, setValue]
  )
  useEffect(() => {
    const fieldValue = rest.watch(name)
    if (isValidHexColor(fieldValue as string)) {
      setColor(fieldValue as ColorResult['hex'])
    }
  }, [name, rest])
  return (
    <>
      <UncontrolledFormTextInput<FormValues>
        setValue={setValue}
        name={name}
        InputProps={{
          endAdornment: (
            <ColorBox
              onClick={(e) => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget)
              }}
              hexColor={color}
            />
          ),
          inputProps: {style: {textTransform: 'uppercase'}}
        }}
        validationOptions={{
          ...validationOptions,
          validate: isValidHexColorValidator
        }}
        {...rest}
      />
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickAway}
      >
        {colorPickerType === 'chrome' && (
          <ChromePicker
            disableAlpha
            color={color}
            onChangeComplete={handleColorChangeComplete}
          />
        )}
        {colorPickerType === 'compact' && (
          <CompactPicker
            color={color}
            onChangeComplete={handleColorChangeComplete}
          />
        )}
      </Popover>
    </>
  )
}
