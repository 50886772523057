import _ from 'lodash'
import {useEffect} from 'react'
import {EventWithSeatsPropertiesFragment} from '../../../../../../__generated__/schema'
import {useSelector} from '../../../../../../editor/redux'

import {useObjectsActions} from '../../../../../../editor/redux/objects/actions'
import {
  presentObjectsSelector,
  selectedObjectsSelector
} from '../../../../../../editor/redux/objects/selectors'
import {IObjectsState} from '../../../../../../editor/redux/objects/types'
import {usePricingActions} from '../../../../../../editor/redux/pricing/actions'
import {IPricingState} from '../../../../../../editor/redux/pricing/types'
import {CanvasObjectType} from '../../../../../../editor/redux/types'
import {backendLayoutPricingToEditorLayoutPricing} from '../../../venues/layoutPricings/edit/utils'

import {useSeatsState} from './seatsStateContext'

export const useInitEditor = (
  event: EventWithSeatsPropertiesFragment | null
) => {
  const {setObjectsState} = useObjectsActions()

  useEffect(() => {
    if (event) {
      const {auditoriumLayout} = event
      setObjectsState(auditoriumLayout.layout as IObjectsState)
    }
  }, [event, setObjectsState])
}

export const useUpdateEditorSeatsStates = (
  event: EventWithSeatsPropertiesFragment
) => {
  const {seatsState} = useSeatsState()
  const {setPricing} = usePricingActions()

  const {
    auditoriumLayoutPricing: {pricing, ticketTypes}
  } = event

  useEffect(() => {
    if (!seatsState) return

    const editorLayoutPricing = backendLayoutPricingToEditorLayoutPricing({
      pricing,
      ticketTypes
    })

    const editorLayoutPricingWithMockedSeat = _.mapValues(
      editorLayoutPricing,
      (v, uuid) => {
        if (seatsState.seats[uuid]) {
          return {
            ...v,
            apiSeatState: seatsState.seats[uuid].state
          }
        } else if (seatsState.zones[uuid]) {
          return {
            ...v,
            zoneStatesWithCounts: seatsState.zones[uuid].states
          }
        }
        throw new Error('Could not find "event seat" for given uuid!')
      }
    )

    setPricing(editorLayoutPricingWithMockedSeat as IPricingState)
  }, [pricing, seatsState, setPricing, ticketTypes])
}

export const useOnSelectionChange = () => {
  const {
    onSeatsSelected,
    onZoneSelected,
    ignoreNextDeselectAll,
    setIgnoreNextDeselectAll
  } = useSeatsState()
  const selectedObjects = useSelector(selectedObjectsSelector)
  const editorInitialized = !_.isEmpty(useSelector(presentObjectsSelector))

  useEffect(() => {
    if (!editorInitialized) {
      return
    }
    if (ignoreNextDeselectAll) {
      setIgnoreNextDeselectAll(false)
      return
    }
    if (selectedObjects.every((o) => o.type === CanvasObjectType.Seat)) {
      onSeatsSelected(selectedObjects.map((o) => o.config.id))
    } else if (
      selectedObjects.length === 1 &&
      selectedObjects[0].type === CanvasObjectType.Zone
    ) {
      onZoneSelected(selectedObjects[0].config.id)
    }
    // Only re-run on `selectedObjects` change
  }, [selectedObjects]) // eslint-disable-line
}
