import {Drawer, drawerClasses} from '@mui/material'
import Joi from 'joi'
import {isEmpty} from 'lodash'
import queryString from 'query-string'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'
import {
  ClientVatRegistered,
  ErrorMessages,
  PaymentReportType,
  SellingChannel
} from '../../../__generated__/schema'
import {useBooleanState} from '../../../hooks/state'
import {useUserInfo} from '../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../utils/errors'
import {WideCenteredLayout} from '../../pages/admin/Layout'
import {Blank} from '../../visual/Blank'
import {DrawerTemplateWithSideNavigation} from '../DrawerTemplateWithSideNavigation'
import {DrawerTemplateHeader} from '../DrawerUtils'
import {DivisionsSummary} from './DivisionsSummary'
import {General} from './General'
import {usePaymentReportView} from './graphql'
import {PaymentsCount} from './PaymentsCount'
import {PaymentsSummary} from './PaymentsSummary'
import {TransactionTypes} from './TransactionTypes'
import {VatSummary} from './VatSummary'

const useNavigationItems = ({
  isVatSectionVisible,
  isDivisionsSummarySectionVisible
}: {
  isVatSectionVisible: boolean
  isDivisionsSummarySectionVisible: boolean
}) => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      general: {
        id: 'general',
        label: t('General')
      },
      paymentsSummary: {
        id: 'paymentsSummary',
        label: t('Payments summary')
      },
      paymentsCount: {
        id: 'paymentsCount',
        label: t('Payments count')
      },
      transactionTypes: {
        id: 'transactionTypes',
        label: t('Transaction types')
      },
      ...(isVatSectionVisible
        ? {
            vatSummary: {
              id: 'vatSummary',
              label: t('VAT summary')
            }
          }
        : {}),
      ...(isDivisionsSummarySectionVisible
        ? {
            divisionsSummary: {
              id: 'divisionsSummary',
              label: t('Divisions summary')
            }
          }
        : {})
    }),
    [t, isVatSectionVisible, isDivisionsSummarySectionVisible]
  )
}

interface IPaymentReportParamsSchema {
  dateFrom: string
  dateTo: string
  channel?: SellingChannel
  userIds?: number[]
  note?: string
}

const paymentReportParamsSchema = Joi.object<IPaymentReportParamsSchema>({
  dateFrom: Joi.string().required(),
  dateTo: Joi.string().required(),
  channel: Joi.string()
    .allow(...Object.values(SellingChannel))
    .optional(),
  userIds: Joi.array()
    .items(Joi.number().integer().positive().required())
    .single(),
  note: Joi.string().optional()
}).options({stripUnknown: true})

interface IPaymentReportDrawerProps {
  onExited: () => void
}

export const PaymentReportDrawer: React.FC<IPaymentReportDrawerProps> = ({
  onExited
}: IPaymentReportDrawerProps) => {
  const {t} = useTranslation()
  const location = useLocation()
  const {value, error: schemaError} = useMemo(
    () =>
      paymentReportParamsSchema.validate(queryString.parse(location.search)),
    [location.search]
  )
  const {data, loading, error} = usePaymentReportView(
    {
      reportDateTimeFrom: value!.dateFrom,
      reportDateTimeTo: value!.dateTo,
      channel: value?.channel,
      cashierUserIds: value?.userIds
    },
    isEmpty(value) || Boolean(schemaError)
  )
  const {effectiveClient} = useUserInfo()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const isVatSectionVisible =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None &&
    (data?.paymentReportView.dataAggregatedByVatRate || []).length > 0
  const isDivisionsSummarySectionVisible =
    (data?.paymentReportView.dataAggregatedByDivision?.items || []).length > 0
  const navigationItems = useNavigationItems({
    isVatSectionVisible,
    isDivisionsSummarySectionVisible
  })
  useEffect(() => {
    if (!isEmpty(value) && !schemaError) {
      openDrawer()
    }
  }, [openDrawer, schemaError, value])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {width: '100%'}}}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          header: (
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Payment report view')}
            />
          ),
          isLoading: loading
        }}
        navigationItems={navigationItems}
      >
        <>
          {error ? (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.NoPaymentsFoundToBeIncludedInReport
            ) ? (
              <Blank title={t('No payments found to be included in report')} />
            ) : (
              <Blank
                title={t('Error while loading payment report view')}
                description={error.message}
              />
            )
          ) : (
            <WideCenteredLayout
              sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 5}}
            >
              {data && (
                <>
                  <General
                    paymentReport={{
                      ...data.paymentReportView,
                      note: value?.note,
                      type: PaymentReportType.MonthlyReport
                    }}
                    id={navigationItems.general.id}
                  />
                  <PaymentsSummary
                    dataAggregatedByPaymentMethodAndTransactionType={
                      data.paymentReportView
                        .dataAggregatedByPaymentMethodAndTransactionType
                    }
                    label={navigationItems.paymentsSummary.label}
                    id={navigationItems.paymentsSummary.id}
                  />
                  <PaymentsCount
                    dataAggregatedByPaymentMethodAndTransactionType={
                      data.paymentReportView
                        .dataAggregatedByPaymentMethodAndTransactionType
                    }
                    label={navigationItems.paymentsCount.label}
                    id={navigationItems.paymentsCount.id}
                  />
                  <TransactionTypes
                    paymentReport={data.paymentReportView}
                    label={navigationItems.transactionTypes.label}
                    id={navigationItems.transactionTypes.id}
                  />
                  {navigationItems.vatSummary && (
                    <VatSummary
                      paymentReport={data.paymentReportView}
                      label={navigationItems.vatSummary.label}
                      id={navigationItems.vatSummary.id}
                    />
                  )}
                  {navigationItems.divisionsSummary && (
                    <DivisionsSummary
                      dataAggregatedByDivision={
                        data.paymentReportView.dataAggregatedByDivision
                      }
                      label={navigationItems.divisionsSummary.label}
                      id={navigationItems.divisionsSummary.id}
                    />
                  )}
                </>
              )}
            </WideCenteredLayout>
          )}
        </>
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
