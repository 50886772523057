import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {TFunction} from 'i18next'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ApplicationRulePropertiesFragment,
  ApplicationRuleType,
  PermissionCode
} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../common'
import {ConditionView} from './ConditionView'

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    gridTemplateColumns: '1fr auto auto'
  },
  body: {
    paddingTop: theme.spacing(1)
  },
  conditionViewWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    '&:first-child': {
      paddingTop: 0
    }
  },
  labelClassName: {
    display: 'inline-flex',
    paddingRight: '0.5em'
  },
  chipsClassName: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    marginTop: -theme.spacing(0.5)
  }
}))

const getRuleTitle = ({
  t,
  ruleType,
  index
}: {
  t: TFunction
  ruleType: ApplicationRuleType
  index: number
}) => {
  switch (ruleType) {
    case ApplicationRuleType.Event:
      return t('{{index}}. Rule for events', {
        index
      })
    case ApplicationRuleType.Tour:
      return t('{{index}}. Rule for tours', {
        index
      })
    case ApplicationRuleType.Product:
      return t('{{index}}. Rule for products', {
        index
      })
    case ApplicationRuleType.Cart:
      return t('{{index}}. Rule for carts', {
        index
      })
    default:
      return ''
  }
}

const getEmptyConditionsTitle = ({
  t,
  ruleType
}: {
  t: TFunction
  ruleType: ApplicationRuleType
}) => {
  switch (ruleType) {
    case ApplicationRuleType.Event:
      return t(
        'No conditions in this rule. The discount will be applicable for all events.'
      )
    case ApplicationRuleType.Tour:
      return t(
        'No conditions in this rule. The discount will be applicable for all tours.'
      )
    case ApplicationRuleType.Product:
      return t(
        'No conditions in this rule. The discount will be applicable for all products.'
      )
    case ApplicationRuleType.Cart:
      return t(
        'No conditions in this rule. The discount will be applicable for all carts.'
      )
    default:
      return ''
  }
}

interface IApplicationRuleProps {
  applicationRule: ApplicationRulePropertiesFragment
  index: number
  className?: string

  onEditButtonClick(): void

  onDeleteApplicationRuleConfirm(applicationRuleId: number): void
}

export const ApplicationRule: React.FC<IApplicationRuleProps> = ({
  index,
  applicationRule,
  className,
  onEditButtonClick,
  onDeleteApplicationRuleConfirm
}: IApplicationRuleProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  const handleDeleteButtonClick = useCallback(() => {
    onDeleteApplicationRuleConfirm(applicationRule.id)
  }, [applicationRule.id, onDeleteApplicationRuleConfirm])
  return (
    <div className={className}>
      <div className={classes.header}>
        <Typography variant="subtitle2">
          {getRuleTitle({t, index, ruleType: applicationRule.type})}
        </Typography>
        {P([PermissionCode.DeleteApplicationRule]) && (
          <ButtonWithConfirmationDialog
            buttonProps={{
              color: 'primary',
              startIcon: <DeleteIcon />,
              children: t('Delete')
            }}
            onConfirmButtonClick={handleDeleteButtonClick}
            dialogProps={{
              title: t('Delete rule'),
              contentText: t(
                "Are you sure you want to delete rule? This can't be undone."
              ),
              confirmButtonLabel: t('Delete')
            }}
          />
        )}
        {(P([PermissionCode.UpdateCondition]) ||
          P([PermissionCode.DeleteCondition]) ||
          P([PermissionCode.CreateCondition])) && (
          <Button
            color="primary"
            startIcon={<EditIcon />}
            onClick={onEditButtonClick}
          >
            {t('Edit')}
          </Button>
        )}
      </div>
      <div className={classes.body}>
        {applicationRule.conditions.length ? (
          applicationRule.conditions.map((condition, conditionIndex) => (
            <div className={classes.conditionViewWrapper} key={condition.id}>
              <ConditionView
                condition={condition}
                isFirst={conditionIndex === 0}
                labelClassName={classes.labelClassName}
                chipsClassName={classes.chipsClassName}
              />
            </div>
          ))
        ) : (
          <Typography variant="caption">
            {getEmptyConditionsTitle({t, ruleType: applicationRule.type})}
          </Typography>
        )}
      </div>
    </div>
  )
}
