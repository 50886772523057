import {useQuery} from '@apollo/react-hooks'
import {Box} from '@mui/material'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'
import {
  TourTimeSlotNarrowDetailQuery,
  TourTimeSlotNarrowDetailQueryVariables
} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useTourParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {PersistentDrawerMenu} from '../../../../common/PersistentDrawerMenu'
import {PersistentDrawerWithContent} from '../../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../Header'
import {TOUR_TIME_SLOT_NARROW_DETAIL} from '../graphql'
import {Revenues} from './Revenues'
import {Turnovers} from './Turnovers'

const useNavigationItems = () => {
  const {t} = useTranslation()
  const {tourTimeSlotId} = useTourParams()
  return useMemo(
    (): {
      revenues: {id: string; label: string; route: string}
      turnovers: {id: string; label: string; route: string}
    } => ({
      revenues: {
        id: 'revenues',
        label: t('Revenues'),
        route:
          routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsRevenues(
            tourTimeSlotId
          )
      },
      turnovers: {
        id: 'turnovers',
        label: t('Turnovers'),
        route:
          routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsTurnovers(
            tourTimeSlotId
          )
      }
    }),
    [t, tourTimeSlotId]
  )
}

interface IDrawerContentProps {
  tourTimeSlotId: number
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  tourTimeSlotId
}: IDrawerContentProps) => (
  <Switch>
    <Route
      path={routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsRevenues(
        ':tourTimeSlotId'
      )}
    >
      <Revenues tourTimeSlotId={tourTimeSlotId} />
    </Route>
    <Route
      path={routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsTurnovers(
        ':tourTimeSlotId'
      )}
    >
      <Turnovers tourTimeSlotId={tourTimeSlotId} />
    </Route>
  </Switch>
)

interface IAdmissionTypesStatisticsProps {
  onExited: () => void
}

export const AdmissionTypesStatistics: React.FC<IAdmissionTypesStatisticsProps> =
  ({onExited}: IAdmissionTypesStatisticsProps) => {
    const {t} = useTranslation()
    const navigationItems = useNavigationItems()
    const {tourTimeSlotId} = useTourParams()
    const {
      state: isDrawerOpen,
      setFalse: closeDrawer,
      setTrue: openDrawer
    } = useBooleanState(true)
    const {formatDateNumeric, formatTime} = useDateTimeFormatters()
    const {data} = useQuery<
      TourTimeSlotNarrowDetailQuery,
      TourTimeSlotNarrowDetailQueryVariables
    >(TOUR_TIME_SLOT_NARROW_DETAIL, {
      variables: {id: tourTimeSlotId},
      fetchPolicy: 'network-only'
    })
    const [title, setTitle] = useState<string>(t<string>('Loading...'))
    useEffect(() => {
      if (data) {
        const startsAtDate = new Date(data.tourTimeSlot.startsAt)
        setTitle(
          [
            formatDateNumeric(startsAtDate),
            formatTime(startsAtDate),
            data.tourTimeSlot.tour.name
          ].join(' • ')
        )
      }
    }, [data, formatDateNumeric, formatTime])
    return (
      <Switch>
        <Route
          path={routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatistics(
            ':tourTimeSlotId'
          )}
          exact
        >
          <Redirect
            to={routeTo.admin.tourTimeSlotsStatistics.admissionTypeStatisticsRevenues(
              tourTimeSlotId
            )}
          />
        </Route>
        <PageWithHeaderTemplate
          header={
            <SecondaryHeader
              title={title}
              hasArrowBackIcon
              onLeftActionClick={onExited}
            />
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <Box sx={{display: 'grid', height: '100%'}}>
              <PersistentDrawerWithContent
                content={<DrawerContent tourTimeSlotId={tourTimeSlotId} />}
                drawerContent={<PersistentDrawerMenu items={navigationItems} />}
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
              />
            </Box>
          </ChildrenOnEffectiveClientSelected>
        </PageWithHeaderTemplate>
      </Switch>
    )
  }
