import {ApolloClient} from 'apollo-client'
import {ApolloQueryResult} from 'apollo-client/core/types'
import {QueryOptions} from 'apollo-client/core/watchQueryOptions'
import {useCallback} from 'react'
import {
  NarrowEventCategoriesQuery,
  NarrowEventCategoriesQueryVariables,
  NarrowEventCategoryFragment,
  Pagination,
  PaginationInput
} from '../../../../../../__generated__/schema'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {
  GET_NARROW_EVENT_CATEGORIES,
  NARROW_EVENT_CATEGORY_FRAGMENT
} from './graphql'
import {isNotNull, useIdListItems} from './idListItems'

const useMapNarrowEventCategoryToIdListItem = () =>
  useCallback(
    ({id, name}: NarrowEventCategoryFragment): IIdListItem => ({
      firstRow: name,
      id
    }),
    []
  )

const getDataFromDb = ({
  client,
  options
}: {
  options: Omit<QueryOptions<NarrowEventCategoriesQueryVariables>, 'query'>
  client: ApolloClient<object>
}): Promise<ApolloQueryResult<NarrowEventCategoriesQuery>> =>
  client.query<NarrowEventCategoriesQuery, NarrowEventCategoriesQueryVariables>(
    {
      query: GET_NARROW_EVENT_CATEGORIES,
      ...options
    }
  )

const getPagination = (data: NarrowEventCategoriesQuery): Pagination => ({
  offset: 0,
  hasMore: false,
  totalRowsCount: data.eventCategories.length,
  limit: data.eventCategories.length || 1
})

const useGetIdListItemsFromCache = (
  mapTItemToIdListItem: (tItem: NarrowEventCategoryFragment) => IIdListItem
) =>
  useCallback(
    (client: ApolloClient<object>, ids: number[]) =>
      ids
        .map((id) =>
          client.readFragment<NarrowEventCategoryFragment>({
            id: `EventCategory:${id}`,
            fragment: NARROW_EVENT_CATEGORY_FRAGMENT,
            fragmentName: 'NarrowEventCategory'
          })
        )
        .filter(isNotNull)
        .map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const useMapIdListItemsFromData = (
  mapTItemToIdListItem: (tItem: NarrowEventCategoryFragment) => IIdListItem
) =>
  useCallback(
    (data: NarrowEventCategoriesQuery): IIdListItem[] =>
      data.eventCategories.map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const mapVariables = ({
  hasText,
  ids
}: {
  paginationInput: PaginationInput
  hasText?: string
  ids?: number[]
}): NarrowEventCategoriesQueryVariables => ({
  filter: {
    hasText,
    eventCategoriesIds: ids
  }
})

export const useEventCategoryListItems = () => {
  const mapTItemToIdListItem = useMapNarrowEventCategoryToIdListItem()
  const getIdListItemsFromCache =
    useGetIdListItemsFromCache(mapTItemToIdListItem)
  const mapIdListItemsFromData = useMapIdListItemsFromData(mapTItemToIdListItem)
  const {
    getIdListItems: getEventCategoryListItems,
    getIdListItemsByIds: getEventCategoryListItemsByIds
  } = useIdListItems<
    NarrowEventCategoriesQuery,
    NarrowEventCategoriesQueryVariables
  >({
    getDataFromDb,
    getPagination,
    getIdListItemsFromCache,
    mapIdListItemsFromData,
    mapVariables
  })
  return {
    getEventCategoryListItems,
    getEventCategoryListItemsByIds
  }
}
