import {Timeline} from '@mui/lab'
import {Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  NarrowTicketItemPropertiesFragment,
  NarrowTourItemPropertiesFragment,
  PassCodeCheckState
} from '../../../../../__generated__/schema'
import {useTranslatePassCodeCheckAdditionalInformation} from '../../../../../hooks/translatePassCodeCheckAdditionalInformation'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {DrawerSection, IDrawerSectionProps} from '../../../../common'
import {
  ActivityLogTimelineItem,
  DotColor
} from '../../../../common/ActivityLogTimelineItem'

const getDotColor = (state: PassCodeCheckState) => {
  switch (state) {
    case PassCodeCheckState.Allowed:
      return DotColor.Success
    case PassCodeCheckState.Denied:
    default:
      return DotColor.Error
    case PassCodeCheckState.Pending:
      return DotColor.Warning
  }
}

const getDescription = (
  updatedAt: string,
  createdByName: string,
  formatDate: (date: Date) => string,
  formatTime: (date: Date) => string,
  t: TFunction
) => {
  if (dayjs(updatedAt).isToday()) {
    return t('Today {{time}} • {{name}}', {
      time: formatTime(new Date(updatedAt)),
      name: createdByName
    })
  } else if (dayjs(updatedAt).isYesterday()) {
    return t('Yesterday {{time}} • {{name}}', {
      time: formatTime(new Date(updatedAt)),
      name: createdByName
    })
  } else {
    return `${formatDate(new Date(updatedAt))} • ${createdByName}`
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  timeline: {
    '& .MuiTimelineItem-root:before': {
      flex: 0
    },
    paddingLeft: 0
  },
  noData: {
    padding: theme.spacing(2),
    color: theme.palette.text.disabled
  }
}))

interface IActivityLogTicketInfoSectionProps {
  activityLog:
    | NarrowTicketItemPropertiesFragment['passCodeCheckActivityLog']
    | NarrowTourItemPropertiesFragment['passCodeCheckActivityLog']
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const ActivityLogTicketInfoSection: React.FC<IActivityLogTicketInfoSectionProps> =
  ({activityLog, drawerSectionProps}: IActivityLogTicketInfoSectionProps) => {
    const {t} = useTranslation()
    const translatePassCodeCheckAdditionalInformation =
      useTranslatePassCodeCheckAdditionalInformation()
    const {formatTimeWithMilliseconds, formatDateTimeWithMilliseconds} =
      useDateTimeFormatters()
    const classes = useStyles()
    return (
      <DrawerSection {...drawerSectionProps}>
        {(activityLog?.items.length || []) > 0 ? (
          <Timeline className={classes.timeline}>
            {activityLog?.items.filter(Boolean).map((log, index, array) => (
              <ActivityLogTimelineItem
                key={`activityLogTimeline-${index}`}
                title={translatePassCodeCheckAdditionalInformation(
                  log.additionalInformation
                )}
                description={getDescription(
                  log.updatedAt,
                  log.createdByName,
                  formatDateTimeWithMilliseconds,
                  formatTimeWithMilliseconds,
                  t
                )}
                dotColor={getDotColor(log.state)}
                hasConnector={index !== array.length - 1}
              />
            ))}
            {activityLog.pagination.totalRowsCount >= 50 && (
              <Typography variant="caption" color="textSecondary" sx={{px: 2}}>
                {t('Displayed 50 of total {{count}} checks', {
                  count: activityLog.pagination.totalRowsCount
                })}
              </Typography>
            )}
          </Timeline>
        ) : (
          <Typography variant="subtitle2" className={classes.noData}>
            {t('No additional information found')}
          </Typography>
        )}
      </DrawerSection>
    )
  }
