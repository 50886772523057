import {Box, Paper, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ApiGrantQuery} from '../../../../../__generated__/schema'
import {useFormatUserName} from '../../../../../hooks/formatUserName'
import {
  useTranslateApiGrantState,
  useTranslateApiGrantStateDescription
} from '../../../../../hooks/translateApiGrantState'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {apiGrantStateColors} from '../../../../constants'

interface IGeneralSectionProps {
  id: string
  apiGrant: ApiGrantQuery['apiGrant']
}

export const GeneralSection: React.FC<IGeneralSectionProps> = ({
  id,
  apiGrant
}: IGeneralSectionProps) => {
  const {t} = useTranslation()
  const translateApiGrantState = useTranslateApiGrantState()
  const translateApiGrantStateDescription =
    useTranslateApiGrantStateDescription()
  const formatUserName = useFormatUserName()
  const {formatDateTime} = useDateTimeFormatters()
  return (
    <Paper
      variant="outlined"
      id={id}
      sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}
    >
      <Typography variant="h6">{apiGrant.name}</Typography>
      <Box sx={{display: 'flex', alignItems: 'baseline', gap: 1}}>
        <EntityStateChip
          label={translateApiGrantState(apiGrant.state)}
          colorConf={apiGrantStateColors[apiGrant.state]}
        />
        <Typography variant="caption" color="textSecondary">
          {translateApiGrantStateDescription(apiGrant.state)}
        </Typography>
      </Box>
      <SingleInfoColumn
        caption={t('Description')}
        value={apiGrant.description}
        valueTypographyVariant="body2"
      />
      <DividerSeparatedInfoRow
        information={[
          {
            caption: t('Per minute'),
            value: apiGrant.maxRequestsPerMinute
          },
          {
            caption: t('Per hour'),
            value: apiGrant.maxRequestsPerHour
          },
          {
            caption: t('Expires at'),
            value: formatDateTime(new Date(apiGrant.expiresAt))
          },
          {
            caption: t('Created at'),
            value: formatDateTime(new Date(apiGrant.createdAt))
          },
          {
            caption: t('Created by'),
            value: formatUserName(apiGrant.createdBy)
          },
          {
            caption: t('Updated at'),
            value: formatDateTime(new Date(apiGrant.updatedAt))
          },
          {
            caption: t('Updated by'),
            value: formatUserName(apiGrant.updatedBy)
          }
        ]}
      />
    </Paper>
  )
}
