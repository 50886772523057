import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'
import {useSelector} from '../redux'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'

import {PanelType} from '../redux/sidePanel'
import {ObjectsPanel} from './ObjectsPanel'

const useStyles = makeStyles<Theme>(() => ({
  scrollable: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}))

interface ISidePanelProps {
  DefaultPanel?: React.ReactElement
}

export const SidePanel: React.FC<ISidePanelProps> = ({
  DefaultPanel
}: ISidePanelProps) => {
  const classes = useStyles()
  const activePanelType = useSelector(
    (state) => state.sidePanel.activePanelType
  )
  const displayMode = useSelector(displayModeSelector)

  const displayObjectsPanel = !(
    displayMode === DisplayMode.PRICING ||
    displayMode === DisplayMode.CASH ||
    activePanelType === PanelType.DEFAULT
  )

  return (
    <div className={classes.scrollable}>
      {/* Note: DefaultPanel can not unmount otherwise we would lost "registration" for form fields,
          we would be not able to get them when submitting the form.
          This is a quick workaround, investigate if we can not instead pass DefaultPanel as Component instead
          of already rendered node. It could solve the problem without this fragile hack.
      */}
      <div style={{display: displayObjectsPanel ? 'none' : 'initial'}}>
        {DefaultPanel}
      </div>
      {displayObjectsPanel && <ObjectsPanel />}
    </div>
  )
}
