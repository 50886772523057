import {Chip, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {useCallback} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {PaymentMethodType} from '../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../hooks/effectiveClientCurrencySignInputProps'
import {useRequireEffectiveClient} from '../../../../../hooks/requireEffectiveClient'
import {useTranslateEffectiveClientShortenedPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {SetValue} from '../../../../../utils/forms'
import {useIsValidMoneyAmount} from '../../../../../utils/formsValidations'
import {safeSum} from '../../../../../utils/money'
import {getClosestNominalValues} from '../../../../../utils/nominals'
import {UncontrolledFormTextInputWithCancelButton} from '../../../../form/UncontrolledFormTextInputWithCancelButton'
import {useCurrentCart} from '../CurrentCartContext'

import {
  getPaymentIntentFieldName,
  IPaymentIntentsForm,
  PaymentIntentField
} from '../types'
import {IIntentFrame} from './types'

const useQuickChoiceStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto auto auto auto 1fr',
    gridTemplateAreas: `
      "title title title title"
      ".     .     .     ."  
    `,
    paddingTop: theme.spacing(1),
    gap: theme.spacing(1)
  },
  title: {
    gridArea: 'title'
  }
}))

interface IQuickChoiceProps {
  value: number
  title: string
  showMoreChips: boolean
  fieldName: string
  setValue: SetValue
}

const QuickChoice: React.FC<IQuickChoiceProps> = ({
  value,
  title,
  showMoreChips,
  fieldName,
  setValue
}: IQuickChoiceProps) => {
  const effectiveClient = useRequireEffectiveClient()
  const closestNominalValues = showMoreChips
    ? getClosestNominalValues({currency: effectiveClient.currency, value})
    : []
  const translateEffectiveClientShortenedPrice =
    useTranslateEffectiveClientShortenedPrice()
  const classes = useQuickChoiceStyles()

  const getOnClickHandler = useCallback(
    (value: number) => () => {
      setValue(fieldName, value)
    },
    [fieldName, setValue]
  )

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant={'caption'}>
        {title}
      </Typography>
      <Chip
        label={translateEffectiveClientShortenedPrice(value)}
        size={'small'}
        onClick={getOnClickHandler(value)}
      />
      {closestNominalValues.map((nominalValue) => (
        <Chip
          label={translateEffectiveClientShortenedPrice(nominalValue)}
          key={nominalValue}
          size={'small'}
          onClick={getOnClickHandler(nominalValue)}
        />
      ))}
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  uncontrolledInput: {
    marginLeft: -theme.spacing(2)
  }
}))

interface IIntentTextFieldWithChipsFrameProps {
  intentFrame: IIntentFrame
  className?: string
}

export const IntentTextFieldWithChipsFrame: React.FC<IIntentTextFieldWithChipsFrameProps> =
  ({intentFrame, className}: IIntentTextFieldWithChipsFrameProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {register, setValue, triggerValidation, watch, getValues, errors} =
      useFormContext<IPaymentIntentsForm>()
    const isValidMoneyAmount = useIsValidMoneyAmount()
    const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
    const {currentCart} = useCurrentCart()
    const amountFieldName = getPaymentIntentFieldName(
      intentFrame.key,
      PaymentIntentField.Amount
    )
    const intentsSum = safeSum(
      Object.entries(getValues({nest: true}))
        .filter(([id]) => id !== intentFrame.key)
        .map(([, intentFrame]) => intentFrame[PaymentIntentField.Amount])
    )
    const quickChoiceValue = new Decimal(currentCart!.price)
      .sub(intentsSum)
      .toNumber()
    return (
      <div className={className}>
        <UncontrolledFormTextInputWithCancelButton<IPaymentIntentsForm>
          margin="dense"
          fullWidth
          className={classes.uncontrolledInput}
          label={intentFrame.name}
          name={amountFieldName}
          register={register}
          errors={errors}
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
          InputProps={currencySignInputProps}
          validationOptions={{
            validate: isValidMoneyAmount
          }}
        />
        <input
          type="hidden"
          ref={register()}
          defaultValue={intentFrame.paymentMethodId}
          name={getPaymentIntentFieldName(
            intentFrame.key,
            PaymentIntentField.PaymentMethodId
          )}
        />
        {quickChoiceValue > 0 && (
          <QuickChoice
            value={quickChoiceValue}
            title={t('{{name}} • Quick choice', {name: intentFrame.name})}
            showMoreChips={intentFrame.type === PaymentMethodType.Cash}
            fieldName={amountFieldName}
            setValue={setValue}
          />
        )}
      </div>
    )
  }
