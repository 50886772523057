import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {uniqBy} from 'lodash'
import {
  CheckTicketsPaginatedEventsQuery,
  CheckTicketsPaginatedEventsQueryVariables,
  CheckTicketsToursQuery,
  CheckTicketsToursQueryVariables,
  CheckTicketsTourTimeSlotsQuery,
  CheckTicketsTourTimeSlotsQueryVariables,
  GetCheckEventQuery,
  GetCheckEventQueryVariables,
  GetCheckTicketsEventDrawerDataQuery,
  GetCheckTicketsEventDrawerDataQueryVariables,
  GetCheckTourQuery,
  GetCheckTourQueryVariables,
  GetCheckTourTimeSlotQuery,
  GetCheckTourTimeSlotQueryVariables,
  GetCheckVenueQuery,
  GetCheckVenueQueryVariables,
  GetItemQuery,
  GetItemQueryVariables
} from '../../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../utils/pagination'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {
  LEAD_PROPERTIES_FRAGMENT,
  NARROW_DIVISION_PROPERTIES_FRAGMENT,
  TRANSLATED_LOCALES_FRAGMENT,
  USER_FIELDS
} from '../graphql'

const CHECK_TICKETS_EVENT_FRAGMENT = gql`
  fragment CheckTicketsEvent on Event {
    id
    reservedSeatsCount
    soldSeatsCount
    uniqueCheckedInCount
    gateOpensAt
    gateClosedAt
    names {
      ...TranslatedLocales
    }
    startsAt
    formatCode
    auditorium {
      id
      name
    }
    soundMixCode
    versionCode
    ageClassificationCode
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const GET_CHECK_TICKETS_PAGINATED_EVENTS = gql`
  query checkTicketsPaginatedEvents(
    $filter: EventsFilterInput!
    $paginationInput: PaginationInput!
  ) {
    paginatedEvents(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...CheckTicketsEvent
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${CHECK_TICKETS_EVENT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const useGetCheckTicketsPaginatedEvents = (
  variables: CheckTicketsPaginatedEventsQueryVariables
) =>
  useQueryWithPagination<
    CheckTicketsPaginatedEventsQuery,
    CheckTicketsPaginatedEventsQueryVariables
  >(
    GET_CHECK_TICKETS_PAGINATED_EVENTS,
    {
      fetchPolicy: 'network-only',
      variables
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.paginatedEvents.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        paginatedEvents: {
          ...fetchMoreResult.paginatedEvents,
          items: uniqBy(
            [
              ...prevData.paginatedEvents.items,
              ...fetchMoreResult.paginatedEvents?.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

export const CHECK_EVENT_FRAGMENT = gql`
  fragment CheckEventProperties on Event {
    id
    gateOpensAt
    gateClosedAt
    names {
      ...TranslatedLocales
    }
    auditorium {
      id
      name
    }
    startsAt
    state
    formatCode
    soundMixCode
    versionCode
    ageClassificationCode
    uniqueCheckedInCount
    soldSeatsCount
    reservedSeatsCount
    passCodesCheckedInCount
    passCodesCheckedOutCount
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`

const CHECK_TOUR_TIME_SLOT_FRAGMENT = gql`
  fragment CheckTourTimeSlotProperties on TourTimeSlot {
    id
    startsAt
    state
    tourId
    names {
      ...TranslatedLocales
    }
    venue {
      id
      name
    }
    guide {
      ...UserFields
    }
    gateOpensAt
    gateClosesAt
    versionCode
    ageClassificationCode
    tourItemsStateCounts {
      reserved
      sold
    }
    passCodesCheckedInCount
    passCodesCheckedOutCount
    uniqueCheckedInCount
    attendeesCheckedInCount
    attendeesCheckedOutCount
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
  ${USER_FIELDS}
`

const CHECK_TOUR_FRAGMENT = gql`
  fragment CheckTourProperties on Tour {
    id
    name
    division {
      id
      name
    }
  }
`

const CHECK_VENUE_FRAGMENT = gql`
  fragment CheckVenueProperties on Venue {
    id
    name
    address {
      street
      town
    }
  }
`

const GET_CHECK_EVENT = gql`
  query GetCheckEvent($id: Int!) {
    event(id: $id) {
      ...CheckEventProperties
    }
  }
  ${CHECK_EVENT_FRAGMENT}
`

const GET_CHECK_TOUR_TIME_SLOT = gql`
  query GetCheckTourTimeSlot($id: PositiveInt!) {
    tourTimeSlot(id: $id) {
      ...CheckTourTimeSlotProperties
    }
  }
  ${CHECK_TOUR_TIME_SLOT_FRAGMENT}
`

const GET_CHECK_TOUR = gql`
  query GetCheckTour($id: PositiveInt!) {
    tour(id: $id) {
      ...CheckTourProperties
    }
  }
  ${CHECK_TOUR_FRAGMENT}
`

const GET_CHECK_VENUE = gql`
  query GetCheckVenue($id: Int!) {
    venue(id: $id) {
      ...CheckVenueProperties
    }
  }
  ${CHECK_VENUE_FRAGMENT}
`

export const useGetCheckEventData = (id: number) =>
  useQuery<GetCheckEventQuery, GetCheckEventQueryVariables>(GET_CHECK_EVENT, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

export const useGetCheckTourTimeSlotData = (id: number) =>
  useQuery<GetCheckTourTimeSlotQuery, GetCheckTourTimeSlotQueryVariables>(
    GET_CHECK_TOUR_TIME_SLOT,
    {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    }
  )

export const useGetCheckTourData = (id: number) =>
  useQuery<GetCheckTourQuery, GetCheckTourQueryVariables>(GET_CHECK_TOUR, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

export const useGetCheckVenueData = (id: number) =>
  useQuery<GetCheckVenueQuery, GetCheckVenueQueryVariables>(GET_CHECK_VENUE, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

const GET_CHECK_TICKETS_EVENT_DRAWER_DATA = gql`
  query GetCheckTicketsEventDrawerData($id: Int!) {
    event(id: $id) {
      ...CheckEventProperties
      duration
      endsAt
      activePricing {
        id
        ticketTypes {
          id
          color
          description
          price
          name
        }
      }
      enabledDiscounts {
        id
        application
        name
        type
        value
      }
      division {
        id
        name
      }
      venue {
        id
        name
        address {
          complex
          street
          town
          postalCode
          country
        }
      }
      show {
        id
        genreCodes
        globalReleaseDate
        productionYear
        translations {
          localeCode
          title
          description
        }
        crew {
          crewRoleCode
          persons {
            name
            description
          }
        }
        contentDescriptorCodes
      }
    }
  }
  ${CHECK_EVENT_FRAGMENT}
`

export const useGetCheckTicketEventDrawerData = (id: number) =>
  useQuery<
    GetCheckTicketsEventDrawerDataQuery,
    GetCheckTicketsEventDrawerDataQueryVariables
  >(GET_CHECK_TICKETS_EVENT_DRAWER_DATA, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  })

const NARROW_TICKET_ITEM_PROPERTIES_FRAGMENT = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment NarrowTicketItemProperties on TicketItem {
    passCode
    ticketItemCheckStatus: checkStatus
    price
    totalPassCodeChecksCount
    checkedInCount
    checkedOutCount
    passCodeCheckActivityLog(paginationInput: $paginationInput) {
      items {
        additionalInformation
        state
        createdByName
        createdAt
        updatedAt
      }
      pagination {
        totalRowsCount
      }
    }
    eventSeat {
      label
      row
      section
      floor
      type
      event {
        id
        startsAt
        names {
          ...TranslatedLocales
        }
        venue {
          id
          name
          address {
            town
          }
        }
        auditorium {
          id
          name
        }
      }
    }
    eventPricingToTicketType {
      price
      name
    }
    appliedDiscounts {
      discount {
        name
        type
        value
      }
    }
    sale {
      cart {
        lead {
          ...LeadProperties
        }
      }
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
`

const NARROW_TOUR_ITEM_PROPERTIES_FRAGMENT = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment NarrowTourItemProperties on TourItem {
    id
    passCode
    price
    priceBeforeDiscount
    tourItemCheckStatus: checkStatus
    tourTimeSlot {
      id
      startsAt
      names {
        ...TranslatedLocales
      }
      venue {
        id
        name
        address {
          town
        }
      }
    }
    admissionType {
      id
      name
      internalDescription
    }
    passCodeCheckActivityLog(paginationInput: $paginationInput) {
      items {
        additionalInformation
        state
        createdByName
        createdAt
        updatedAt
      }
      pagination {
        totalRowsCount
      }
    }
    appliedDiscounts {
      discount {
        name
        type
        value
      }
    }
    totalPassCodeChecksCount
    checkedInCount
    checkedOutCount
    attendeesCount
    sale {
      cart {
        lead {
          ...LeadProperties
        }
      }
    }
  }
  ${LEAD_PROPERTIES_FRAGMENT}
`

const NARROW_PRODUCT_ITEM_PROPERTIES_FRAGMENT = gql`
  fragment NarrowProductItemProperties on ProductItem {
    id
  }
`

const GET_ITEM = gql`
  query GetItem($id: Int!, $paginationInput: PaginationInput) {
    item(id: $id) {
      ...NarrowTicketItemProperties
      ...NarrowTourItemProperties
      ...NarrowProductItemProperties
    }
  }
  ${NARROW_TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${NARROW_TOUR_ITEM_PROPERTIES_FRAGMENT}
  ${NARROW_PRODUCT_ITEM_PROPERTIES_FRAGMENT}
`

export const useGetItem = (variables: GetItemQueryVariables) =>
  useQuery<GetItemQuery, GetItemQueryVariables>(GET_ITEM, {
    variables,
    fetchPolicy: 'network-only'
  })

export const ADVANCED_SETTINGS_DIVISIONS = gql`
  ${NARROW_DIVISION_PROPERTIES_FRAGMENT}
  query AdvancedSettingsDivisions {
    divisions {
      ...NarrowDivisionProperties
    }
  }
`

const GET_CHECK_TICKETS_TOUR_TIME_SLOTS = gql`
  query checkTicketsTourTimeSlots(
    $filter: TourTimeSlotsFilter
    $paginationInput: PaginationInput!
  ) {
    tourTimeSlots(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...CheckTourTimeSlotProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${CHECK_TOUR_TIME_SLOT_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const useGetCheckTicketsTourTimeSlots = (
  variables: CheckTicketsTourTimeSlotsQueryVariables
) =>
  useQueryWithPagination<
    CheckTicketsTourTimeSlotsQuery,
    CheckTicketsTourTimeSlotsQueryVariables
  >(
    GET_CHECK_TICKETS_TOUR_TIME_SLOTS,
    {fetchPolicy: 'network-only', variables},
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.tourTimeSlots.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        tourTimeSlots: {
          ...fetchMoreResult.tourTimeSlots,
          items: uniqBy(
            [
              ...prevData.tourTimeSlots.items,
              ...fetchMoreResult.tourTimeSlots.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

const GET_CHECK_TICKETS_TOURS = gql`
  query checkTicketsTours(
    $filter: ToursFilter
    $paginationInput: PaginationInput!
  ) {
    tours(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...CheckTourProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${CHECK_TOUR_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const useGetCheckTicketsTours = (
  variables: CheckTicketsToursQueryVariables
) =>
  useQueryWithPagination<
    CheckTicketsToursQuery,
    CheckTicketsToursQueryVariables
  >(
    GET_CHECK_TICKETS_TOURS,
    {fetchPolicy: 'network-only', variables},
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.tours.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        tours: {
          ...fetchMoreResult.tours,
          items: uniqBy(
            [...prevData.tours.items, ...fetchMoreResult.tours.items],
            ({id}) => id
          )
        }
      })
    }
  )
