import {CanvasObjectType} from '@attendio/shared-components'
import {Box, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Scalars} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'

interface IFloorPlanStatusProps {
  layout: Scalars['JSONObject']
  sx?: SxProps<Theme>
}

const getSeatsCountByType = (
  objects: {
    [uuid: string]: {
      type: CanvasObjectType
    }
  },
  type: CanvasObjectType
): number =>
  Object.values(objects).filter((object) => object.type === type).length

export const FloorPlanStatus: React.FC<IFloorPlanStatusProps> = (
  props: IFloorPlanStatusProps
) => {
  const {t} = useTranslation()
  const {sx} = props
  const layout = props.layout as unknown as {
    [uuid: string]: {
      type: CanvasObjectType
    }
  }
  const seatsCount = getSeatsCountByType(layout, CanvasObjectType.Seat)
  const zonesCount = getSeatsCountByType(layout, CanvasObjectType.Zone)
  return (
    <Box sx={sx}>
      <Typography sx={{pb: 1}} variant="subtitle1">
        {t('Floor plan status')}
      </Typography>
      <Box sx={{display: 'flex', gap: 0.5}}>
        <Typography fontWeight="bold">{seatsCount}</Typography>
        <Typography>{t('seat', {count: seatsCount})}</Typography>
      </Box>
      <Box sx={{display: 'flex', gap: 0.5}}>
        <Typography fontWeight="bold">{zonesCount}</Typography>
        <Typography>{t('zone', {count: zonesCount})}</Typography>
      </Box>
    </Box>
  )
}
