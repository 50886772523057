import {Autocomplete, TextField} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {OptionChip, useChipSpacingStyles} from './OptionChip'

import {SearchRowWithLabel} from './SearchRowWithLabel'

export interface ISelectOption<U> {
  id: U
  label: string
}

interface IAdvancedSearchSelectRowProps<T, U> {
  label: string
  value: U | undefined
  options: ISelectOption<U>[]
  mapSelectValueToSearchObject: (searchObject: T, value: U | undefined) => T
  setSearchObject: (searchObject: T) => void
  searchObject: T
}

interface IUseSearchSelectProps<T, U> {
  selectOptions: ISelectOption<U>[]
  selectInputPrefix: string
  getSelectValueFromSearchObject: (searchObject: T) => U | undefined
}

interface IUseSearchSelect<T> {
  getStringifiedSelectValueFromSearchObject: (
    searchObject: T
  ) => string | undefined
}

const ANY_VALUE_ID = ''

export const useSearchSelect = <
  T extends object,
  U extends string | number = string
>({
  selectOptions,
  selectInputPrefix,
  getSelectValueFromSearchObject
}: IUseSearchSelectProps<T, U>): IUseSearchSelect<T> => {
  const getStringifiedSelectValueFromSearchObject = useCallback(
    (searchObject: T) => {
      const id = getSelectValueFromSearchObject(searchObject)
      const option = selectOptions.find((item) => item.id === id)
      return option && `${selectInputPrefix}: ${option.label}`
    },
    [selectOptions, getSelectValueFromSearchObject, selectInputPrefix]
  )

  return {
    getStringifiedSelectValueFromSearchObject
  }
}

export const AdvancedSearchSelectRow = <
  T extends object,
  U extends string | number = string
>({
  label,
  value,
  options,
  mapSelectValueToSearchObject,
  searchObject,
  setSearchObject
}: IAdvancedSearchSelectRowProps<T, U>) => {
  const {t} = useTranslation()
  const onChange = useCallback(
    (e, newValue) => {
      setSearchObject(
        mapSelectValueToSearchObject(
          searchObject,
          newValue && newValue.id
            ? newValue.id === ANY_VALUE_ID
              ? undefined
              : newValue.id
            : undefined
        )
      )
    },
    [mapSelectValueToSearchObject, searchObject, setSearchObject]
  )
  const onChipClick = useCallback(
    (id) => {
      setSearchObject(
        mapSelectValueToSearchObject(
          searchObject,
          value === id ? undefined : id
        )
      )
    },
    [mapSelectValueToSearchObject, searchObject, setSearchObject, value]
  )

  const chipSpacingClasses = useChipSpacingStyles()
  const isSelect = options.length > 6
  if (options.length === 0) {
    return null
  }
  return (
    <SearchRowWithLabel
      label={label}
      labelColor="textSecondary"
      alignLabel={isSelect ? 'center' : 'top'}
    >
      {isSelect ? (
        <Autocomplete
          renderInput={(params) => (
            <TextField {...params} margin="dense" placeholder={t('Any')} />
          )}
          options={[{id: ANY_VALUE_ID, label: t('Any')}, ...options].map(
            (option) => option
          )}
          getOptionLabel={(option) => option.label}
          value={options.find((option) => option.id === value) || null}
          onChange={onChange}
          noOptionsText={t('No options')}
          fullWidth
        />
      ) : (
        <div className={chipSpacingClasses.chipBox}>
          {options.map((option) => (
            <OptionChip
              className={chipSpacingClasses.chip}
              key={option.id}
              id={option.id}
              isActive={value === option.id}
              label={option.label}
              onClick={onChipClick}
            />
          ))}
        </div>
      )}
    </SearchRowWithLabel>
  )
}
