import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import _ from 'lodash'
import {useCallback} from 'react'

import {
  AdmissionTypesStatisticsQuery,
  AdmissionTypesStatisticsQueryVariables,
  AuditoriumsFilterInput,
  AuditoriumState,
  ClientShowsQuery,
  ClientShowsQueryVariables,
  ClientState,
  CostCentersFilterInput,
  CountryVatRatesQuery,
  CountryVatRatesQueryVariables,
  DivisionState,
  EventCategoriesFilterInput,
  ExistingCountryCode,
  GetLightweightClientShowsQuery,
  GetLightweightClientShowsQueryVariables,
  GetLightweightClientsQuery,
  GetLightweightClientsQueryVariables,
  GetLightweightCostCentersQuery,
  GetLightweightCostCentersQueryVariables,
  GetLightweightDivisionsQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightEventCategoriesQueryVariables,
  GetLightweightMarketingLabelsQuery,
  GetLightweightMarketingLabelsQueryVariables,
  GetLightweightProductTypesQuery,
  GetLightweightProductTypesQueryVariables,
  LightweightAuditoriumsQuery,
  LightweightAuditoriumsQueryVariables,
  LightweightCustomerGroupsQuery,
  LightweightCustomerGroupsQueryVariables,
  LightweightUsersQuery,
  LightweightUsersQueryVariables,
  LightweightVenuesQuery,
  LightweightVenuesQueryVariables,
  MarketingLabelsFilterInput,
  PaymentMethodState,
  ProductTypeState,
  RetailPaymentMethodsQuery,
  RetailPaymentMethodsQueryVariables,
  SendReservationConfirmationEmailMutation,
  SendReservationConfirmationEmailMutationVariables,
  SendSaleConfirmationEmailMutation,
  SendSaleConfirmationEmailMutationVariables,
  ShowAgeClassificationCodesQuery,
  ShowAgeClassificationCodesQueryVariables,
  ShowsFilterInput,
  ShowTypesQuery,
  ShowTypesQueryVariables,
  UserState,
  VenuesFilterInput
} from '../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../utils/pagination'
import {defaultPaginationInput, PAGINATION_FRAGMENT} from '../graphql'

export const TRANSLATED_LOCALES_FRAGMENT = gql`
  fragment TranslatedLocales on Translated {
    en
    sk
    cs
    hu
  }
`

export const ECOMMERCE_TRANSLATED_FIELDS = gql`
  fragment ECommerceTranslatedFields on ECommerceTranslated {
    en
    sk
    cs
    hu
    de
    pl
  }
`

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    username
    firstName
    lastName
  }
`

export const TOUR_ITEMS_STATE_COUNTS_FIELDS = gql`
  fragment TourItemsStateCountsFields on TourItemsStateCounts {
    inCart
    reserved
    sold
  }
`

export const CLIENT_SHOW_PROPERTIES_FRAGMENT = gql`
  fragment ClientShowProperties on ClientShow {
    id
    showsDbId
    originalTitle
    translations {
      localeCode
      title
      description
      tagline
    }
    countryCodes
    genreCodes
    typeCode
    businessPartner {
      id
      companyName
    }
    ageClassificationCodes
    crew {
      crewRoleCode
    }
    duration
    globalReleaseDate
    distributions {
      formatCode
      soundMixCode
      versionCode
    }
    images {
      id
      url
      thumbnails {
        id
        key
        size
        width
        height
        url
      }
    }
    primaryImage(type: poster) {
      id
      url
      thumbnails(size: [small]) {
        id
        url
        type
      }
    }
    contentDescriptorCodes
  }
`

export const GET_CLIENT_SHOWS = gql`
  query ClientShows(
    $filter: ShowsFilterInput
    $paginationInput: PaginationInput!
  ) {
    clientShows(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...ClientShowProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${CLIENT_SHOW_PROPERTIES_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const useGetClientShows = (filterInput: ShowsFilterInput) =>
  useQueryWithPagination<ClientShowsQuery, ClientShowsQueryVariables>(
    GET_CLIENT_SHOWS,
    {
      variables: {
        filter: filterInput,
        paginationInput: defaultPaginationInput
      },
      fetchPolicy: 'network-only'
    },
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.clientShows.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        clientShows: {
          ...fetchMoreResult.clientShows,
          items: _.uniqBy(
            [
              ...prevData.clientShows.items,
              ...fetchMoreResult.clientShows.items
            ],
            ({id}) => id
          )
        }
      })
    }
  )

const EVENT_VENUE_PROPERTIES_FRAGMENT = gql`
  fragment EventVenueProperties on Venue {
    id
    name
    secondaryName
    order
    auditoriums {
      id
      name
      state
      order
      auditoriumLayouts {
        id
        name
        status
        auditoriumLayoutPricings {
          id
          name
          status
          ticketTypes {
            id
            price
            name
            description
            areDiscountsEnabled
          }
        }
      }
    }
  }
`

export const GET_EVENT_VENUES = gql`
  query EventVenues {
    venues {
      ...EventVenueProperties
    }
  }
  ${EVENT_VENUE_PROPERTIES_FRAGMENT}
`

export const eventVenuesQuery = {
  query: GET_EVENT_VENUES
}

export const NARROW_DIVISION_PROPERTIES_FRAGMENT = gql`
  fragment NarrowDivisionProperties on Division {
    id
    state
    name
  }
`

export const GET_DIVISIONS = gql`
  ${NARROW_DIVISION_PROPERTIES_FRAGMENT}
  query getLightweightDivisions {
    divisions {
      ...NarrowDivisionProperties
    }
  }
`

export const useGetLightweightDivisions = (skip?: boolean) => {
  const {data, error, loading} = useQuery<GetLightweightDivisionsQuery>(
    GET_DIVISIONS,
    {skip}
  )
  return {
    divisions:
      data?.divisions
        .filter((d) => d.state === DivisionState.Active)
        .sort((divisionA, divisionB) =>
          divisionA.name.localeCompare(divisionB.name)
        ) || [],
    error,
    loading
  }
}

export const GET_COST_CENTERS = gql`
  query getLightweightCostCenters($filter: CostCentersFilterInput) {
    costCenters(filter: $filter) {
      id
      name
      isActive
    }
  }
`

export const useGetLightweightCostCenters = (
  filter?: CostCentersFilterInput
) => {
  const {data, error, loading} = useQuery<
    GetLightweightCostCentersQuery,
    GetLightweightCostCentersQueryVariables
  >(GET_COST_CENTERS, {variables: {filter}})
  return {
    costCenters:
      data?.costCenters.sort((costCenterA, costCenterB) =>
        costCenterA.name.localeCompare(costCenterB.name)
      ) || [],
    error,
    loading
  }
}

export const GET_EVENT_CATEGORIES = gql`
  query getLightweightEventCategories($filter: EventCategoriesFilterInput) {
    eventCategories(filter: $filter) {
      id
      name
      isActive
    }
  }
`

export const useGetLightweightEventCategories = (
  filter?: EventCategoriesFilterInput
) => {
  const {data, error, loading} = useQuery<
    GetLightweightEventCategoriesQuery,
    GetLightweightEventCategoriesQueryVariables
  >(GET_EVENT_CATEGORIES, {variables: {filter}})
  return {
    eventCategories:
      data?.eventCategories.sort((eventCategoryA, eventCategoryB) =>
        eventCategoryA.name.localeCompare(eventCategoryB.name)
      ) || [],
    error,
    loading
  }
}

export const GET_MARKETING_LABELS = gql`
  query getLightweightMarketingLabels($filter: MarketingLabelsFilterInput) {
    marketingLabels(filter: $filter) {
      id
      name
      isActive
    }
  }
`

export const useGetLightweightMarketingLabels = (
  filter?: MarketingLabelsFilterInput
) => {
  const {data, error, loading} = useQuery<
    GetLightweightMarketingLabelsQuery,
    GetLightweightMarketingLabelsQueryVariables
  >(GET_MARKETING_LABELS, {variables: {filter}})
  return {
    marketingLabels:
      data?.marketingLabels.sort((marketingLabelA, marketingLabelB) =>
        marketingLabelA.name.localeCompare(marketingLabelB.name)
      ) || [],
    error,
    loading
  }
}

export const GET_LIGHTWEIGHT_CLIENTS = gql`
  query getLightweightClients {
    clients {
      id
      name
      state
    }
  }
`

export const useGetLightweightClients = (skip?: boolean) => {
  const {data, loading, error} = useQuery<
    GetLightweightClientsQuery,
    GetLightweightClientsQueryVariables
  >(GET_LIGHTWEIGHT_CLIENTS, {fetchPolicy: 'network-only', skip})
  return {
    clients: (data?.clients || [])
      .filter(({state}) => state === ClientState.Active)
      .sort((clientA, clientB) => clientA.name.localeCompare(clientB.name)),
    error,
    loading
  }
}

const GET_LIGHTWEIGHT_CLIENT_SHOWS = gql`
  query getLightweightClientShows(
    $filter: ShowsFilterInput
    $paginationInput: PaginationInput!
  ) {
    clientShows(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        duration
        originalTitle
        translations {
          localeCode
          title
        }
      }
    }
  }
`

export const useGetLightweightClientShows = (
  variables: GetLightweightClientShowsQueryVariables,
  skip?: boolean
) => {
  const {data, error, loading} = useQuery<
    GetLightweightClientShowsQuery,
    GetLightweightClientShowsQueryVariables
  >(GET_LIGHTWEIGHT_CLIENT_SHOWS, {
    variables,
    fetchPolicy: 'network-only',
    skip
  })
  return {
    clientShows: data?.clientShows.items || [],
    error,
    loading
  }
}

export const ENABLED_DISCOUNT_PROPERTIES_FRAGMENT = gql`
  fragment EnabledDiscountProperties on Discount {
    id
    name
    application
    type
    value
    sellingChannels
    internalDescription
    maxUsageLimitPerOrder
  }
`

export const AVAILABLE_DISCOUNT_PROPERTIES_FRAGMENT = gql`
  fragment AvailableDiscountProperties on Discount {
    id
    name
    application
    value
    type
    sellingChannels
  }
`

export const TICKET_ITEM_PROPERTIES_FRAGMENT = gql`
  fragment TicketItemProperties on TicketItem {
    id
    passCode
    eventPricingToTicketType {
      id
      name
      promotions
    }
    reservation {
      id
    }
    price
    priceBeforeDiscount
    eventSeat {
      id
      state
      label
      row
      section
      type
      floor
      uuid
      event {
        id
        names {
          ...TranslatedLocales
        }
        startsAt
        formatCode
        soundMixCode
        versionCode
        division {
          id
          name
          address {
            town
          }
        }
        venue {
          id
          name
          address {
            town
          }
        }
      }
    }
    appliedDiscounts {
      discount {
        id
        name
      }
      discountCode {
        id
      }
    }
    claim {
      id
      state
    }
    sale {
      id
    }
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`
export const PRODUCT_ITEM_PROPERTIES_FRAGMENT = gql`
  fragment ProductItemProperties on ProductItem {
    id
    name
    price
    unit
    unitPrice
    quantity
    productId
    claim {
      id
      state
    }
    division {
      id
      name
    }
    sale {
      id
    }
  }
`

export const TOUR_ITEM_PROPERTIES_FRAGMENT = gql`
  ${USER_FIELDS}
  fragment TourItemProperties on TourItem {
    id
    name
    price
    passCode
    priceBeforeDiscount
    attendeesCount
    appliedDiscounts {
      discount {
        id
        name
      }
      discountCode {
        id
        name
      }
    }
    claim {
      id
      state
    }
    sale {
      id
    }
    admissionTypeAssignmentId
    admissionType {
      id
      name
    }
    attendeesCount
    tourTimeSlot {
      id
      venue {
        id
        name
        address {
          town
        }
      }
      startsAt
      ageClassificationCode
      versionCode
      guide {
        ...UserFields
      }
      tour {
        id
        name
        division {
          id
          name
          address {
            town
          }
        }
      }
    }
    tourTimeSlotId
    admissionTypeAssignment {
      id
      hasEnabledDiscountsOnRetail
    }
    tourTimeSlot {
      id
      startsAt
      versionCode
      ageClassificationCode
      tour {
        id
        name
      }
    }
    reservation {
      id
    }
  }
`

export const LEAD_PROPERTIES_FRAGMENT = gql`
  fragment LeadProperties on Lead {
    id
    type
    data {
      name
      phone
      email
      note
      internalNote
      companyName
      companyIdNumber
      VATId
      TAXId
      billingAddressStreet
      billingAddressTown
      billingPostalCode
      billingAddressCountry
      deliveryAddressee
      deliveryAddressStreet
      deliveryAddressTown
      deliveryPostalCode
      deliveryAddressCountry
      isPrivacyPolicyConsentGranted
    }
  }
`

export const CART_PROPERTIES_FRAGMENT = gql`
  fragment CartProperties on Cart {
    id
    uuid
    hash
    price
    state
    clientId
    sellingChannel
    checkoutOptions
    eCommercePaymentRedirectUrl
    items {
      ...TicketItemProperties
      ...ProductItemProperties
      ...TourItemProperties
    }
    lead {
      ...LeadProperties
    }
    expiresAt
    payment {
      id
      amount
      surplus
    }
    paymentIntents {
      id
      state
      amount
      paymentMethodId
      retryAttempts
    }
    reservations {
      id
    }
    sales {
      id
      itemType
      items {
        ... on TicketItem {
          id
        }
        ... on TourItem {
          id
        }
      }
    }
  }
  ${TICKET_ITEM_PROPERTIES_FRAGMENT}
  ${PRODUCT_ITEM_PROPERTIES_FRAGMENT}
  ${TOUR_ITEM_PROPERTIES_FRAGMENT}
  ${LEAD_PROPERTIES_FRAGMENT}
`

export const ERROR_PROPERTIES_FRAGMENT = gql`
  fragment ErrorResultProperties on ErrorResult {
    errorCode
  }
`

export const PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT = gql`
  fragment PaymentMethodGroupProperties on PaymentMethodGroup {
    id
    name
  }
`

export const PAYMENT_METHOD_PROPERTIES_FRAGMENT = gql`
  fragment PaymentMethodProperties on PaymentMethod {
    id
    name
    value
    currency
    type
    state
    channel
    group {
      ...PaymentMethodGroupProperties
    }
    serviceProvider
    paymentServiceProviderConfig {
      ... on GoPayPaymentServiceProviderConfig {
        environment
        defaultPaymentInstrument
      }
      ... on StatnaPokladnicaPaymentServiceProviderConfig {
        environment
      }
    }
    reducesBillTotal
    hasDenomination
    possibleCashdeskDisbursement
    isAvailableForRefunds
    createdAt
    createdByName
    updatedAt
    updatedByName
  }
  ${PAYMENT_METHOD_GROUP_PROPERTIES_FRAGMENT}
`

export const RETAIL_PAYMENT_METHODS = gql`
  query retailPaymentMethods($state: PaymentMethodState) {
    retailPaymentMethods(state: $state) {
      ...PaymentMethodProperties
    }
  }
  ${PAYMENT_METHOD_PROPERTIES_FRAGMENT}
`

export const useRetailPaymentMethods = () =>
  useQuery<RetailPaymentMethodsQuery, RetailPaymentMethodsQueryVariables>(
    RETAIL_PAYMENT_METHODS,
    {
      variables: {
        state: PaymentMethodState.Active
      },
      fetchPolicy: 'network-only'
    }
  )

const SEND_RESERVATION_CONFIRMATION_EMAIL = gql`
  mutation sendReservationConfirmationEmail(
    $cartUUID: String!
    $cartHash: String!
    $reservationIds: [Int!]!
    $deliverTo: [String!]!
    $customEmailTemplateData: CustomEmailTemplateDataInput
  ) {
    sendReservationConfirmationEmail(
      cartUUID: $cartUUID
      cartHash: $cartHash
      reservationIds: $reservationIds
      deliverTo: $deliverTo
      customEmailTemplateData: $customEmailTemplateData
    )
  }
`

export const useSendReservationConfirmationEmail = () => {
  const [sendReservationConfirmationEmail] = useMutation<
    SendReservationConfirmationEmailMutation,
    SendReservationConfirmationEmailMutationVariables
  >(SEND_RESERVATION_CONFIRMATION_EMAIL)
  return useCallback(
    (variables: SendReservationConfirmationEmailMutationVariables) =>
      sendReservationConfirmationEmail({variables}),
    [sendReservationConfirmationEmail]
  )
}

const SEND_SALE_CONFIRMATION_EMAIL = gql`
  mutation sendSaleConfirmationEmail(
    $cartUUID: String!
    $cartHash: String!
    $saleInputs: [SaleInput!]!
    $deliverTo: [String!]!
    $customEmailTemplateData: CustomEmailTemplateDataInput
  ) {
    sendSaleConfirmationEmail(
      cartUUID: $cartUUID
      cartHash: $cartHash
      saleInputs: $saleInputs
      deliverTo: $deliverTo
      customEmailTemplateData: $customEmailTemplateData
    )
  }
`

export const useSendSaleConfirmationEmail = () => {
  const [sendSaleConfirmationEmail] = useMutation<
    SendSaleConfirmationEmailMutation,
    SendSaleConfirmationEmailMutationVariables
  >(SEND_SALE_CONFIRMATION_EMAIL)
  return useCallback(
    (variables: SendSaleConfirmationEmailMutationVariables) =>
      sendSaleConfirmationEmail({variables}),
    [sendSaleConfirmationEmail]
  )
}

export const COUNTRY_VAT_RATE = gql`
  query countryVatRates($countryCode: ExistingCountryCode!) {
    countryVatRates(countryCode: $countryCode)
  }
`

export const useCountryVatRate = (countryCode: ExistingCountryCode) =>
  useQuery<CountryVatRatesQuery, CountryVatRatesQueryVariables>(
    COUNTRY_VAT_RATE,
    {
      variables: {
        countryCode
      }
    }
  )

export const GET_LIGHTWEIGHT_USERS = gql`
  ${USER_FIELDS}
  query lightweightUsers {
    users {
      ...UserFields
      state
    }
  }
`

export const useLightweightUsers = (skip?: boolean) => {
  const {data, error, loading} = useQuery<
    LightweightUsersQuery,
    LightweightUsersQueryVariables
  >(GET_LIGHTWEIGHT_USERS, {skip})
  return {
    users:
      data?.users
        .filter((user) => user.state !== UserState.Invited)
        .sort(
          (nameA, nameB) =>
            nameA.lastName.localeCompare(nameB.lastName) ||
            nameA.firstName.localeCompare(nameB.firstName)
        ) || [],
    error,
    loading
  }
}

export const GET_LIGHTWEIGHT_PRODUCT_TYPES = gql`
  query getLightweightProductTypes($state: ProductTypeState) {
    productTypes(state: $state) {
      id
      name
      state
    }
  }
`

export const useGetLightweightProductTypes = (state?: ProductTypeState) =>
  useQuery<
    GetLightweightProductTypesQuery,
    GetLightweightProductTypesQueryVariables
  >(GET_LIGHTWEIGHT_PRODUCT_TYPES, {
    fetchPolicy: 'network-only',
    variables: {state}
  })

const NARROW_ADMISSION_RATE_FIELDS = gql`
  fragment NarrowAdmissionRateFields on AdmissionRate {
    id
    name
    description
    color
  }
`

export const LIGHTWEIGHT_ADMISSION_RATES = gql`
  ${NARROW_ADMISSION_RATE_FIELDS}
  ${PAGINATION_FRAGMENT}
  query LightweightAdmissionRates(
    $filter: AdmissionRatesFilter
    $paginationInput: PaginationInput!
  ) {
    admissionRates(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...NarrowAdmissionRateFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const GET_TOUR_SHOW_TYPE_CODE = gql`
  query GetTourShowTypeCode($id: PositiveInt!) {
    tour(id: $id) {
      id
      show {
        id
        typeCode
      }
    }
  }
`

export const LIGHTWEIGHT_VENUES = gql`
  query LightweightVenues($filter: VenuesFilterInput) {
    venues(filter: $filter) {
      id
      name
      address {
        town
      }
    }
  }
`

export const useLightweightVenues = (filter?: VenuesFilterInput) => {
  const {data, loading, error} = useQuery<
    LightweightVenuesQuery,
    LightweightVenuesQueryVariables
  >(LIGHTWEIGHT_VENUES, {variables: {filter}})
  return {
    venues:
      data?.venues.sort((venueA, venueB) =>
        venueA.name.localeCompare(venueB.name)
      ) || [],
    loading,
    error
  }
}

export const LIGHTWEIGHT_TOUR = gql`
  query LightweightTour($id: PositiveInt!) {
    tour(id: $id) {
      id
      venueId
      retailAttendeesLimit
      eCommerceAttendeesLimit
      eCommerceOrderAttendeesLimit
      isECommerceReservationActive
      isECommerceSaleActive
      isRetailReservationActive
      isRetailSaleActive
    }
  }
`

export const NARROW_RETAIL_PAYMENT_METHODS = gql`
  query NarrowRetailPaymentMethods($state: PaymentMethodState) {
    retailPaymentMethods(state: $state) {
      id
      name
      type
    }
  }
`

export const LIGHTWEIGHT_TOURS = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightTours(
    $filter: ToursFilter
    $paginationInput: PaginationInput!
  ) {
    tours(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

const ADMISSION_TYPES_STATISTICS = gql`
  ${PAGINATION_FRAGMENT}
  query AdmissionTypesStatistics(
    $paginationInput: PaginationInput!
    $filter: AdmissionTypesStatisticsFilterInput
  ) {
    admissionTypesStatistics(
      paginationInput: $paginationInput
      filter: $filter
    ) {
      items {
        admissionTypeId
        name
        capacityDecreaseCount
        startingQuantity
        revenue
        revenueTicketCount
        revenueAttendeesCount
        saleSum
        saleTicketCount
        saleAttendeesCount
        saleSumOnRetailChannel
        saleTicketCountOnRetailChannel
        saleAttendeesCountOnRetailChannel
        saleSumOnECommerceChannel
        saleTicketCountOnECommerceChannel
        saleAttendeesCountOnECommerceChannel
        refundSum
        refundTicketCount
        refundAttendeesCount
        refundSumOnRetailChannel
        refundTicketCountOnRetailChannel
        refundAttendeesCountOnRetailChannel
        refundSumOnECommerceChannel
        refundTicketCountOnECommerceChannel
        refundAttendeesCountOnECommerceChannel
      }
      totals {
        revenue
        revenueTicketCount
        revenueAttendeesCount
        saleSum
        saleTicketCount
        saleAttendeesCount
        saleSumOnRetailChannel
        saleTicketCountOnRetailChannel
        saleAttendeesCountOnRetailChannel
        saleSumOnECommerceChannel
        saleTicketCountOnECommerceChannel
        saleAttendeesCountOnECommerceChannel
        refundSum
        refundTicketCount
        refundAttendeesCount
        refundSumOnRetailChannel
        refundTicketCountOnRetailChannel
        refundAttendeesCountOnRetailChannel
        refundSumOnECommerceChannel
        refundTicketCountOnECommerceChannel
        refundAttendeesCountOnECommerceChannel
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useAdmissionTypesStatistics = (
  variables: AdmissionTypesStatisticsQueryVariables
) =>
  useQuery<
    AdmissionTypesStatisticsQuery,
    AdmissionTypesStatisticsQueryVariables
  >(ADMISSION_TYPES_STATISTICS, {variables, fetchPolicy: 'network-only'})

export const LIGHTWEIGHT_PRODUCT_GROUPS = gql`
  query LightweightProductGroups($filter: ProductGroupsFilterInput) {
    productGroups(filter: $filter) {
      id
      name
      state
      internalDescription
      icon
      color
    }
  }
`

export const LIGHTWEIGHT_PRODUCTS = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightProducts(
    $filter: ProductsFilterInput
    $paginationInput: PaginationInput!
  ) {
    products(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        priceLookupCode
        internalCode
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

const SHOW_AGE_CLASSIFICATION_CODES = gql`
  query ShowAgeClassificationCodes {
    showAgeClassificationCodes
  }
`

export const useShowAgeClassificationCodes = () =>
  useQuery<
    ShowAgeClassificationCodesQuery,
    ShowAgeClassificationCodesQueryVariables
  >(SHOW_AGE_CLASSIFICATION_CODES)

const LIGHTWEIGHT_AUDITORIUMS = gql`
  query LightweightAuditoriums($filter: AuditoriumsFilterInput) {
    auditoriums(filter: $filter) {
      id
      name
      state
      venue {
        id
        name
        address {
          town
        }
      }
    }
  }
`

export const useLightweightAuditoriums = (filter?: AuditoriumsFilterInput) => {
  const {data, loading, error} = useQuery<
    LightweightAuditoriumsQuery,
    LightweightAuditoriumsQueryVariables
  >(LIGHTWEIGHT_AUDITORIUMS, {variables: {filter}})
  return {
    auditoriums:
      data?.auditoriums
        .filter(({state}) => state === AuditoriumState.Active)
        .sort((auditoriumA, auditoriumB) =>
          auditoriumA.name.localeCompare(auditoriumB.name)
        ) || [],
    loading,
    error
  }
}

export const LIGHTWEIGHT_CUSTOMER_GROUPS = gql`
  query LightweightCustomerGroups {
    customerGroups {
      id
      name
      description
    }
  }
`

export const useLightweightCustomerGroups = (skip?: boolean) =>
  useQuery<
    LightweightCustomerGroupsQuery,
    LightweightCustomerGroupsQueryVariables
  >(LIGHTWEIGHT_CUSTOMER_GROUPS, {skip, fetchPolicy: 'network-only'})

export const LIGHTWEIGHT_WAREHOUSES = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightWarehouses(
    $paginationInput: PaginationInput
    $filter: WarehousesFilterInput
  ) {
    warehouses(paginationInput: $paginationInput, filter: $filter) {
      items {
        id
        name
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const LIGHTWEIGHT_BUSINESS_PARTNERS = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightBusinessPartners(
    $paginationInput: PaginationInput
    $filter: BusinessPartnersFilterInput
  ) {
    businessPartners(paginationInput: $paginationInput, filter: $filter) {
      items {
        id
        companyName
        state
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const LIGHTWEIGHT_WAREHOUSE_PRODUCTS = gql`
  ${PAGINATION_FRAGMENT}
  query LightweightWarehouseProducts(
    $filter: WarehouseProductsFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouseProducts(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        product {
          id
          name
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

const SHOW_TYPES = gql`
  query ShowTypes {
    showTypes {
      code
      ageClassificationCodes
      crewRoleCodes
      formatCodes
      genreCodes
      soundMixCodes
      versionCodes
    }
  }
`

export const useShowTypes = () =>
  useQuery<ShowTypesQuery, ShowTypesQueryVariables>(SHOW_TYPES)
