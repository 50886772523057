import {Box, Drawer, drawerClasses, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ScreenDetailed} from '../../../../windowManagement/types'
import {useTranslateScreenOrientationType} from '../../../../windowManagement/utils'
import {DrawerTemplate, DrawerTemplateHeader, InputRow} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {UncontrolledFormRadioGroup} from '../../../form/UncontrolledFormRadioGroup'
import {ISelectMonitorForm, SelectMonitorFormField} from './types'

const SELECT_MONITOR_FORM_ID = 'selectMonitorForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface IRadioLabelProps {
  primaryText: string
  secondaryText: string
  description?: string
}

const RadioLabel: React.FC<IRadioLabelProps> = ({
  primaryText,
  secondaryText,
  description
}: IRadioLabelProps) => (
  <Box
    sx={{
      width: '100%',
      pl: 0.5,
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Typography variant="subtitle2">{primaryText}</Typography>
    <Typography variant="caption" color="textSecondary">
      {secondaryText}
    </Typography>
    {description && (
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
    )}
  </Box>
)

interface ISelectMonitorDrawerProps {
  isOpen: boolean
  onClose: () => void
  defaultValues?: Partial<ISelectMonitorForm>
  onSubmit: (data: ISelectMonitorForm) => void
  monitors: ScreenDetailed[]
  refetchMonitors: () => void
}

export const SelectMonitorDrawer: React.FC<ISelectMonitorDrawerProps> = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
  monitors,
  refetchMonitors
}: ISelectMonitorDrawerProps) => {
  const {t} = useTranslation()
  const {errors, control, watch, handleSubmit} = useForm<ISelectMonitorForm>({
    defaultValues
  })
  const translateScreenOrientationType = useTranslateScreenOrientationType()
  useEffect(() => {
    if (isOpen) {
      refetchMonitors()
    }
  }, [isOpen, refetchMonitors])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Select monitor')}
          />
        }
        footer={
          <SaveButton
            type="submit"
            form={SELECT_MONITOR_FORM_ID}
            disabled={!watch(SelectMonitorFormField.Monitor)}
          >
            {t('Apply')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          id={SELECT_MONITOR_FORM_ID}
        >
          <Typography variant="caption" color="textSecondary">
            {t(
              'Please choose one of the monitors listed below to serve as customer display for this device only.'
            )}
          </Typography>
          <InputRow
            nodes={[
              <UncontrolledFormRadioGroup<ISelectMonitorForm, string>
                fullWidth
                name={SelectMonitorFormField.Monitor}
                key={SelectMonitorFormField.Monitor}
                control={control}
                errors={errors}
                options={monitors.map(
                  ({
                    label: name,
                    top,
                    left,
                    isPrimary,
                    isInternal,
                    width,
                    height,
                    orientation
                  }) => ({
                    value: JSON.stringify({name, top, left}),
                    label: (
                      <RadioLabel
                        primaryText={[
                          name,
                          isPrimary && t('Primary'),
                          isInternal && t('Internal')
                        ]
                          .filter(Boolean)
                          .join(' • ')}
                        secondaryText={[
                          t('Resolution: {{width}} x {{height}}', {
                            width,
                            height
                          }),
                          t('Orientation: {{orientation}}', {
                            orientation: translateScreenOrientationType(
                              orientation.type
                            )
                          })
                        ].join(' • ')}
                        description={
                          isPrimary
                            ? t(
                                'Primary monitor is not recommended to use as customer display.'
                              )
                            : undefined
                        }
                      />
                    )
                  })
                )}
                validationOptions={{
                  required: true
                }}
              />
            ]}
          />
        </StyledForm>
      </DrawerTemplate>
    </Drawer>
  )
}
