import {useTranslation} from 'react-i18next'

import {DivisionType} from '../__generated__/schema'

const useTranslateDivisionType = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<DivisionType, string> = {
    [DivisionType.Agency]: t<string>('DivisionType->Agency'),
    [DivisionType.Amphitheatre]: t<string>('DivisionType->Amphitheatre'),
    [DivisionType.AmusementPark]: t<string>('DivisionType->AmusementPark'),
    [DivisionType.Cinema]: t<string>('DivisionType->Cinema'),
    [DivisionType.Festival]: t<string>('DivisionType->Festival'),
    [DivisionType.Gallery]: t<string>('DivisionType->Gallery'),
    [DivisionType.HouseOfCulture]: t<string>('DivisionType->HouseOfCulture'),
    [DivisionType.Multiplex]: t<string>('DivisionType->Multiplex'),
    [DivisionType.Museum]: t<string>('DivisionType->Museum'),
    [DivisionType.Other]: t<string>('DivisionType->Other'),
    [DivisionType.SportsGrounds]: t<string>('DivisionType->SportsGrounds'),
    [DivisionType.Theatre]: t<string>('DivisionType->Theatre')
  }
  return (code: DivisionType) => translatedCodes[code]
}

export const useDivisionTypeSelectData = () => {
  const divisionTypes = Object.values(DivisionType)
  const translateDivisionType = useTranslateDivisionType()
  return divisionTypes.reduce(
    (acc, divisionType) => ({
      ...acc,
      [divisionType]: translateDivisionType(divisionType)
    }),
    {}
  ) as Record<DivisionType, string>
}
