import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  TemplateQuery,
  TemplateState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../utils/auth'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useUpdateTemplate} from './graphql'
import {TemplateSettingsFormContent} from './TemplateSettingsFormContent'
import {ITemplateSettingsForm, TemplateFormField} from './types'

const TEMPLATE_SETTINGS_FORM_ID = 'editTemplateSettings'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  drawerContent: {
    backgroundColor: theme.palette.background.paper
  },
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

const getDefaultFormValues = (
  template: TemplateQuery['template']
): ITemplateSettingsForm => ({
  [TemplateFormField.Name]: template.name,
  [TemplateFormField.Description]: template.description || undefined,
  [TemplateFormField.Types]: template.types,
  [TemplateFormField.LocaleCode]: template.localeCode,
  [TemplateFormField.State]: template.state,
  [TemplateFormField.AdditionalSearchParams]:
    template.additionalSearchParams ?? undefined
})

interface ITemplateSettingsDrawerProps {
  isOpen: boolean
  onClose: () => void
  template: TemplateQuery['template']
}

export const TemplateSettingsDrawer: React.FC<ITemplateSettingsDrawerProps> = ({
  isOpen,
  onClose,
  template
}: ITemplateSettingsDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateTemplate = useUpdateTemplate()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const classes = useStyles()
  const onSubmit = useCallback(
    async (data: ITemplateSettingsForm) => {
      try {
        setShowBackdrop(true)
        await updateTemplate({id: template.id, input: data})
        onClose()
        addInfoNotification(t('Template has been updated'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating template'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      onClose,
      setShowBackdrop,
      t,
      template.id,
      updateTemplate
    ]
  )
  const handleSetAsSubmit = useCallback(
    async (data: ITemplateSettingsForm) => {
      try {
        setShowBackdrop(true)
        await updateTemplate({
          id: template.id,
          input: {
            ...data,
            state:
              template.state === TemplateState.Active
                ? TemplateState.Inactive
                : TemplateState.Active
          }
        })
        onClose()
        addInfoNotification(t('Template has been updated'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating template'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      onClose,
      setShowBackdrop,
      t,
      template.id,
      template.state,
      updateTemplate
    ]
  )
  const methods = useForm<ITemplateSettingsForm>({
    defaultValues: getDefaultFormValues(template)
  })
  const {handleSubmit, reset} = methods
  useEffect(() => {
    if (isOpen) {
      reset(getDefaultFormValues(template))
    }
  }, [isOpen, reset, template])

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      classes={{paper: classes.drawerPaper}}
    >
      <DrawerTemplate
        childrenClassName={classes.drawerContent}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Template settings')}
          />
        }
        footer={
          P([PermissionCode.UpdateTemplate]) && (
            <>
              <Button variant="text" color="primary" startIcon={<DeleteIcon />}>
                {t('Delete')}
              </Button>
              <SplitButton
                Options={[
                  <MenuItem
                    key={1}
                    label={
                      template.state === TemplateState.Active
                        ? t('Set as inactive')
                        : t('Set as active')
                    }
                    onClick={handleSubmit(handleSetAsSubmit)}
                  />
                ]}
              >
                <SaveButton type="submit" form={TEMPLATE_SETTINGS_FORM_ID}>
                  {t('Save')}
                </SaveButton>
              </SplitButton>
            </>
          )
        }
      >
        <FormContext {...methods}>
          <form
            id={TEMPLATE_SETTINGS_FORM_ID}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
          >
            <TemplateSettingsFormContent template={template} />
          </form>
        </FormContext>
      </DrawerTemplate>
    </Drawer>
  )
}
