import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import {Chip, ListItemIcon, ListItemText, MenuItem} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ShowVersionCode} from '../../../../../../__generated__/schema'
import {useTranslateShowVersion} from '../../../../../../hooks/translateDistributions'
import {Tooltip} from '../../../../../common'
import {Menu, useMenu} from '../../../../../common/Menu'

interface IVersionsChipProps {
  onChange: (version: ShowVersionCode | null) => void
}

export const VersionsChip: React.FC<IVersionsChipProps> = ({
  onChange
}: IVersionsChipProps) => {
  const {t} = useTranslation()
  const [selectedVersion, setSelectedVersion] =
    useState<ShowVersionCode | null>(null)
  const translateShowVersion = useTranslateShowVersion()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleChipClick = useCallback((e) => openMenu(e), [openMenu])
  const handleMenuItemClick = useCallback(
    (version: ShowVersionCode) => () => {
      setSelectedVersion(version)
      onChange(version)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  const handleDeleteChipClick = useCallback(() => {
    setSelectedVersion(null)
    onChange(null)
  }, [onChange])
  return (
    <>
      <Tooltip title={t('Select version')}>
        <Chip
          size="small"
          variant="outlined"
          icon={<LanguageOutlinedIcon />}
          deleteIcon={selectedVersion ? <CancelIcon /> : <ArrowDropDownIcon />}
          label={
            selectedVersion ? translateShowVersion(selectedVersion) : undefined
          }
          onClick={handleChipClick}
          onDelete={selectedVersion ? handleDeleteChipClick : handleChipClick}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        {Object.values(ShowVersionCode).map((code) => (
          <MenuItem
            key={code}
            selected={code === selectedVersion}
            onClick={handleMenuItemClick(code)}
            sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
          >
            {code === selectedVersion && (
              <ListItemIcon
                color="primary"
                sx={{minWidth: 48, justifyContent: 'center'}}
              >
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText primary={translateShowVersion(code)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
