import {
  DivisionState,
  AuditoriumLayoutPricingState
} from '../../../../../__generated__/schema'
import {useGetDivisions} from '../../divisions/graphql'
import {useGetEventsScreenData as useGetVenuesData} from '../graphql'
import {CreateEventAuditoriumLayout} from './types'

interface IChipItem {
  label: string
  value: number
}

export const getChipItems = (
  array: Array<{name: string; id: number}>
): IChipItem[] => {
  return array.map((item) => ({
    label: item.name,
    value: item.id
  }))
}

export const useActiveDivisions = () => {
  const {data: divisionsData} = useGetDivisions()
  const activeDivisions = divisionsData
    ? divisionsData.divisions.filter((d) => d.state === DivisionState.Active)
    : []
  return activeDivisions || []
}

export const useSelectableAuditoriumLayouts = ({
  venueId,
  auditoriumId
}: {
  venueId: number
  auditoriumId: number
}): CreateEventAuditoriumLayout[] => {
  const {
    data: {venues: venuesData}
  } = useGetVenuesData()
  const auditorium = venuesData![venueId].auditoriums.find(
    (a) => a.id === auditoriumId
  )
  return (auditorium?.auditoriumLayouts || [])
    .map((al) => ({
      ...al,
      auditoriumLayoutPricings: al.auditoriumLayoutPricings.filter(
        (alp) => alp.status === AuditoriumLayoutPricingState.Active
      )
    }))
    .filter((al) => al.auditoriumLayoutPricings.length > 0)
}

export const getAuditoriumLayoutPricings = ({
  auditoriumLayoutId,
  auditoriumLayouts
}: {
  auditoriumLayoutId?: number
  auditoriumLayouts: CreateEventAuditoriumLayout[]
}) =>
  auditoriumLayouts.find((al) => al.id === auditoriumLayoutId)
    ?.auditoriumLayoutPricings
