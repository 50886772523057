import {useTranslation} from 'react-i18next'
import {ECommerceThemeBackgroundColor} from '../__generated__/schema'

export const useTranslateECommerceThemeBackgroundColor = () => {
  const {t} = useTranslation()
  const translated: Record<ECommerceThemeBackgroundColor, string> = {
    [ECommerceThemeBackgroundColor.Grey]: t(
      'ECommerceThemeBackgroundColor->Grey'
    ),
    [ECommerceThemeBackgroundColor.Transparent]: t(
      'ECommerceThemeBackgroundColor->Transparent'
    )
  }
  return (color: ECommerceThemeBackgroundColor) => translated[color]
}
