import {Button, ButtonGroupProps} from '@mui/material'
import {noop} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  TemplatesQuery,
  WarehouseDocumentType
} from '../../../../__generated__/schema'
import {performCompiledWarehouseDocumentAction} from '../../../../utils/compiledTemplates'
import {MenuItem} from '../../../common/Menu'
import {SplitButton} from '../../../visual'
import {useGetIncomingWarehouseDocumentTemplates} from '../hooks/getIncomingWarehouseDocumentTemplates'
import {useGetOutgoingWarehouseDocumentTemplates} from '../hooks/getOutgoingWarehouseDocumentTemplates'

interface IWarehouseDocumentPrintingButtonGroupProps {
  warehouseDocumentId: number
  variant?: ButtonGroupProps['variant']
  disabled?: boolean
  icon?: React.ReactNode
  type: WarehouseDocumentType
}

export const WarehouseDocumentPrintingButtonGroup: React.FC<IWarehouseDocumentPrintingButtonGroupProps> =
  ({
    warehouseDocumentId,
    variant = 'contained',
    disabled,
    icon,
    type
  }: IWarehouseDocumentPrintingButtonGroupProps) => {
    const {t} = useTranslation()
    const incomingTemplates = useGetIncomingWarehouseDocumentTemplates(
      type === WarehouseDocumentType.Outgoing
    )
    const outgoingTemplates = useGetOutgoingWarehouseDocumentTemplates(
      type === WarehouseDocumentType.Incoming
    )
    const getTemplateButtonClickHandler = useCallback(
      (template: TemplatesQuery['templates'][number]) => () => {
        performCompiledWarehouseDocumentAction({
          templateFileType: template.fileType,
          templateId: template.id,
          warehouseDocumentId
        })
      },
      [warehouseDocumentId]
    )
    return (
      <SplitButton
        variant={variant}
        disabled={disabled}
        disableElevation
        Options={
          type === WarehouseDocumentType.Incoming
            ? incomingTemplates.length
              ? incomingTemplates.map((template) => (
                  <MenuItem
                    key={template.id}
                    label={template.name}
                    icon={icon}
                    onClick={getTemplateButtonClickHandler(template)}
                  />
                ))
              : [
                  <MenuItem
                    key="no-templates"
                    label={t('No templates have been found')}
                    isDisabled
                  />
                ]
            : outgoingTemplates.length
            ? outgoingTemplates.map((template) => (
                <MenuItem
                  key={template.id}
                  label={template.name}
                  icon={icon}
                  onClick={getTemplateButtonClickHandler(template)}
                />
              ))
            : [
                <MenuItem
                  key="no-templates"
                  label={t('No templates have been found')}
                  isDisabled
                />
              ]
        }
      >
        <Button
          variant={variant}
          color="primary"
          onClick={
            type === WarehouseDocumentType.Incoming
              ? incomingTemplates[0]
                ? getTemplateButtonClickHandler(incomingTemplates[0])
                : noop
              : outgoingTemplates[0]
              ? getTemplateButtonClickHandler(outgoingTemplates[0])
              : noop
          }
        >
          {t('Print')}
        </Button>
      </SplitButton>
    )
  }
