import {Box, SxProps} from '@mui/material'
import React from 'react'
import {matchPath, useHistory} from 'react-router-dom'
import {MenuItem} from './Menu'

interface IPersistentDrawerMenuProps {
  items: Record<string, {id: string; label: string; route: string}>
  sx?: SxProps
}

export const PersistentDrawerMenu: React.FC<IPersistentDrawerMenuProps> = ({
  items,
  sx
}: IPersistentDrawerMenuProps) => {
  const history = useHistory()
  return (
    <Box sx={{py: 1, ...sx}}>
      {Object.values(items).map((item) => (
        <MenuItem
          key={item.id}
          label={item.label}
          onClick={() => history.push(item.route)}
          isSelected={!!matchPath(location.pathname, {path: item.route})}
        />
      ))}
    </Box>
  )
}
