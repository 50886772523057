import {Box, Paper, Tab, Tabs} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'

import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {EcommercePaymentMethodsTable} from './EcommercePaymentMethodsTable'
import {EditPaymentMethodDrawer} from './EditPaymentMethodDrawer'
import {Groups} from './Groups'
import {RetailPaymentMethodsTable} from './RetailPaymentMethodsTable'

export const PaymentMethods: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const {P} = useEnsurePermissions()
  const hasManagePaymentMethodsPermission = P([
    PermissionCode.ManagePaymentMethods
  ])
  const hasReadRetailPaymentMethodsPermission = P([
    PermissionCode.ReadRetailPaymentMethods
  ])
  const hasReadPaymentMethodGroupsPermission = P([
    PermissionCode.ReadPaymentMethodGroups
  ])
  const hasReadECommercePaymentMethodsPermission = P([
    PermissionCode.ReadECommercePaymentMethods
  ])
  const handleEditPaymentMethodDrawerExited = useCallback(() => {
    history.replace(routeTo.admin.paymentMethods.retail())
  }, [history])

  return (
    <>
      <PageWithHeaderTemplate
        header={<PrimaryHeader title={t('Payment methods')} />}
      >
        <ChildrenOnEffectiveClientSelected
          text={t('Please select a client, to see/manage his payment methods.')}
        >
          <Box
            sx={{
              height: '100%'
            }}
          >
            <Paper variant="outlined" square>
              <Tabs
                value={
                  [
                    hasReadRetailPaymentMethodsPermission &&
                      routeTo.admin.paymentMethods.retail(),
                    hasReadPaymentMethodGroupsPermission &&
                      routeTo.admin.paymentMethods.groups(),
                    hasReadECommercePaymentMethodsPermission &&
                      routeTo.admin.paymentMethods.ecommerce()
                  ]
                    .filter(Boolean)
                    .includes(location.pathname)
                    ? location.pathname
                    : hasReadRetailPaymentMethodsPermission
                    ? routeTo.admin.paymentMethods.retail()
                    : hasReadECommercePaymentMethodsPermission
                    ? routeTo.admin.paymentMethods.ecommerce()
                    : hasReadPaymentMethodGroupsPermission
                    ? routeTo.admin.paymentMethods.groups()
                    : undefined
                }
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {hasReadRetailPaymentMethodsPermission && (
                  <Tab
                    component={Link}
                    label={t('RETAIL')}
                    to={routeTo.admin.paymentMethods.retail()}
                    value={routeTo.admin.paymentMethods.retail()}
                  />
                )}
                {hasReadPaymentMethodGroupsPermission && (
                  <Tab
                    component={Link}
                    label={t('GROUPS')}
                    to={routeTo.admin.paymentMethods.groups()}
                    value={routeTo.admin.paymentMethods.groups()}
                  />
                )}
                {hasReadECommercePaymentMethodsPermission && (
                  <Tab
                    component={Link}
                    label={t('ECOMMERCE')}
                    to={routeTo.admin.paymentMethods.ecommerce()}
                    value={routeTo.admin.paymentMethods.ecommerce()}
                  />
                )}
              </Tabs>
            </Paper>
            <Box sx={{height: 'calc(100% - 112px)', width: '100%', p: 3}}>
              <Switch>
                {hasReadPaymentMethodGroupsPermission && (
                  <Route
                    path={routeTo.admin.paymentMethods.groups()}
                    component={Groups}
                  />
                )}
                {hasReadECommercePaymentMethodsPermission && (
                  <Route
                    path={routeTo.admin.paymentMethods.ecommerce()}
                    component={EcommercePaymentMethodsTable}
                  />
                )}
                {hasReadRetailPaymentMethodsPermission && (
                  <Route
                    path={[routeTo.admin.paymentMethods.retail()]}
                    component={RetailPaymentMethodsTable}
                  />
                )}
                {hasManagePaymentMethodsPermission && (
                  <Route
                    path={[routeTo.admin.paymentMethods.home()]}
                    component={
                      hasReadRetailPaymentMethodsPermission
                        ? RetailPaymentMethodsTable
                        : hasReadECommercePaymentMethodsPermission
                        ? EcommercePaymentMethodsTable
                        : hasReadPaymentMethodGroupsPermission
                        ? Groups
                        : undefined
                    }
                  />
                )}
              </Switch>
            </Box>
          </Box>
        </ChildrenOnEffectiveClientSelected>
      </PageWithHeaderTemplate>
      {P([PermissionCode.UpdatePaymentMethod]) && (
        <Route
          path={routeTo.admin.paymentMethods.editRetail(':paymentMethodId')}
        >
          <EditPaymentMethodDrawer
            onExited={handleEditPaymentMethodDrawerExited}
          />
        </Route>
      )}
    </>
  )
}
