import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  RadioGroup,
  SelectProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {
  FieldErrors,
  FieldValue,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {
  IUncontrolledFormTextInputProps,
  UncontrolledFormTextInput
} from '../../../../form/FormTextInput'
import {FormTimeInput, IFormTimeInputProps} from '../../../../form/pickers'
import {FormFieldName} from '../../../../form/types'
import {
  IUncontrolledFormSelectProps,
  UncontrolledFormSelect
} from '../../../../form/UncontrolledFormSelect'
import {useHasError} from '../../../../form/utils'

import {MainLabel, ValidationError} from '../../../../visual'
import {DurationField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  hidden: {
    display: 'none'
  },
  accordionRoot: {
    padding: theme.spacing(1),
    '&$accordionExpanded': {
      margin: 0
    },
    '&:before': {
      backgroundColor: 'transparent'
    }
  },
  labelBox: {
    height: 20
  },
  accordionSummaryRoot: {
    '&$accordionExpanded': {
      minHeight: theme.spacing(6)
    }
  },
  accordionSummaryContent: {
    margin: 0,
    '&$accordionExpanded': {
      margin: 0
    }
  },
  accordionDetailsRoot: {
    paddingBottom: 0
  },
  accordionExpanded: {}
}))

interface IDivisionFormSelectProps<FormValues extends FieldValues = FieldValues>
  extends SelectProps {
  label: string
  errors: FieldErrors<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  register: FormContextValues<FormValues>['register']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
  helperNote?: string
  defaultValue: FieldValue<FormValues>[FormFieldName<FormValues>]
  children: React.ReactNodeArray
  options: FormValues[FormFieldName<FormValues>][]
  subfieldsName: FormFieldName<FormValues>
}

export const DivisionFormSelect = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  subfieldsName,
  validationOptions,
  setValue,
  register,
  errors,
  defaultValue,
  label,
  children,
  options
}: IDivisionFormSelectProps<FormValues>) => {
  const onRadioGroupChange = useCallback(
    (_event, value) => {
      setValue(name, value)
    },
    [name, setValue]
  )
  const hasError = useHasError<FormValues>(errors, subfieldsName)
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <FormControl variant="outlined" fullWidth>
      <select
        name={name}
        defaultValue={defaultValue}
        ref={validationOptions ? register(validationOptions) : register()}
        className={classes.hidden}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <Accordion
        elevation={0}
        square
        classes={{
          expanded: classes.accordionExpanded,
          root: classes.accordionRoot
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
            expanded: classes.accordionExpanded
          }}
        >
          <div className={classes.labelBox}>
            <MainLabel label={label} />
            {hasError && (
              <ValidationError>
                {t('Please fix errors in this section')}
              </ValidationError>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.accordionDetailsRoot
          }}
        >
          <RadioGroup onChange={onRadioGroupChange} defaultValue={defaultValue}>
            {children}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  )
}

const useFormControlLabelStyles = makeStyles<Theme>((theme) => ({
  formControlRow: {
    display: 'grid',
    gridAutoFlow: 'column',
    height: theme.spacing(6),
    '&>div:first-child': {
      width: 195
    }
  },
  note: {
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2)
  },
  leftNote: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2)
  },
  durationGrid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(2),
    '&>div': {
      width: 120
    }
  }
}))

interface IFormControlTextLabelProps<
  FormValues extends FieldValues = FieldValues
> extends IUncontrolledFormTextInputProps<FormValues> {
  note: string
}

export const FormControlTextLabel = <
  FormValues extends FieldValues = FieldValues
>({
  note,
  ...rest
}: IFormControlTextLabelProps<FormValues>) => {
  const classes = useFormControlLabelStyles()
  return (
    <div className={classes.formControlRow}>
      <div>
        <UncontrolledFormTextInput<FormValues> {...rest} />
      </div>
      <Typography className={classes.note}>{note}</Typography>
    </div>
  )
}

interface IFormControlTimeLabelProps<
  FormValues extends FieldValues = FieldValues
> extends Omit<IFormTimeInputProps<FormValues>, 'timePickerProps'> {
  note: string
}

export const FormControlTimeLabel = <
  FormValues extends FieldValues = FieldValues
>({
  note,
  ...rest
}: IFormControlTimeLabelProps<FormValues>) => {
  const classes = useFormControlLabelStyles()
  return (
    <div className={classes.formControlRow}>
      <div>
        <FormTimeInput<FormValues> timePickerProps={{label: ''}} {...rest} />
      </div>
      <Typography className={classes.note}>{note}</Typography>
    </div>
  )
}

interface IFormControlDurationLabelProps<
  FormValues extends FieldValues = FieldValues
> extends Omit<
    IUncontrolledFormSelectProps<FormValues>,
    'label' | 'selectOptions'
  > {
  note: string
}

const getDurationOption = (n: number) => {
  return [...Array.from(Array(n).keys())].reduce(
    (acc, key) => ({
      ...acc,
      [key]: String(key)
    }),
    {}
  )
}

export const FormControlDurationLabel = <
  FormValues extends FieldValues = FieldValues
>({
  note,
  required,
  name,
  ...rest
}: IFormControlDurationLabelProps<FormValues>) => {
  const classes = useFormControlLabelStyles()
  const {t} = useTranslation()
  return (
    <div className={classes.formControlRow}>
      <Typography className={cn(classes.note, classes.leftNote)}>
        {note}
      </Typography>
      <div className={classes.durationGrid}>
        <UncontrolledFormSelect<FormValues>
          {...rest}
          required={required}
          name={`${name}.${DurationField.DAYS}` as FormFieldName<FormValues>}
          label={t('Days')}
          selectOptions={getDurationOption(7)}
        />
        <UncontrolledFormSelect<FormValues>
          {...rest}
          label={t('Hours')}
          name={`${name}.${DurationField.HOURS}` as FormFieldName<FormValues>}
          selectOptions={getDurationOption(24)}
        />
        <UncontrolledFormSelect<FormValues>
          {...rest}
          label={t('Minutes')}
          name={`${name}.${DurationField.MINUTES}` as FormFieldName<FormValues>}
          selectOptions={getDurationOption(60)}
        />
      </div>
    </div>
  )
}
