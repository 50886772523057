import {Dialog, DialogActions, DialogContent} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  ConditionPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {useUpdateCondition} from '../graphql'
import {
  transformConditionFormValueToInputValue,
  transformConditionIntoConditionForm
} from '../types'
import {SelectValueAndOperatorStep} from './SelectValueAndOperatorStep'
import {ConditionFormField, IConditionForm} from './types'

const EDIT_CONDITION_FORM = 'editConditionOperatorForm'

interface IEditConditionDialogProps {
  closeDialog(): void
  condition: ConditionPropertiesFragment | null
  onConditionUpdate: (condition: ConditionPropertiesFragment) => void
  onConditionDelete: (conditionId: number) => Promise<void>
}

export const EditConditionDialog: React.FC<IEditConditionDialogProps> = ({
  condition,
  onConditionUpdate,
  closeDialog,
  onConditionDelete
}: IEditConditionDialogProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const params = useParams<{discountId: string}>()
  const discountId = parseInt(params.discountId, 10)
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const updateCondition = useUpdateCondition(discountId)
  const handleSubmit = useCallback(
    async (form: IConditionForm) => {
      if (!condition) {
        return
      }
      setShowBackdrop(true)
      try {
        const updatedCondition = await updateCondition({
          id: condition.id,
          value: transformConditionFormValueToInputValue(form),
          operator: form[ConditionFormField.Operator]
        })
        addInfoNotification(t('Condition updated'))
        onConditionUpdate(updatedCondition)
        closeDialog()
      } catch (e) {
        defaultErrorHandler(e, t('Updating of condition failed.'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDialog,
      defaultErrorHandler,
      condition,
      onConditionUpdate,
      setShowBackdrop,
      t,
      updateCondition
    ]
  )
  const handleDelete = useCallback(() => {
    if (condition) {
      onConditionDelete(condition.id)
      closeDialog()
    }
  }, [closeDialog, condition, onConditionDelete])
  return (
    <Dialog open={Boolean(condition)} maxWidth="xs" fullWidth>
      <DialogTitleWithCloseButton onCloseClick={closeDialog}>
        {t('Edit condition')}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        {condition && (
          <SelectValueAndOperatorStep
            formId={EDIT_CONDITION_FORM}
            defaultValues={transformConditionIntoConditionForm(condition)}
            onSubmit={handleSubmit}
          />
        )}
      </DialogContent>
      <DialogActions>
        {P([PermissionCode.DeleteCondition]) && (
          <ButtonWithConfirmationDialog
            onConfirmButtonClick={handleDelete}
            buttonProps={{
              color: 'primary',
              variant: 'text',
              children: t('Delete')
            }}
            dialogProps={{
              title: t('Delete condition'),
              contentText: t(
                "Are you sure you want to delete condition? This can't be undone."
              ),
              confirmButtonLabel: t('Delete')
            }}
          />
        )}
        <SaveButton type="submit" form={EDIT_CONDITION_FORM}>
          {t('Done')}
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}
