import {useTranslation} from 'react-i18next'
import {TabFuture, TabGroup, TabNow, TabPast} from './types'

export const useTranslateDatePickerFutureValues = () => {
  const {t} = useTranslation()
  const translatedValues: Record<TabFuture, string> = {
    [TabFuture.Tomorrow]: t('DatePickerFuture->Tomorrow'),
    [TabFuture.Next7Days]: t('DatePickerFuture->Next7Days'),
    [TabFuture.Next14Days]: t('DatePickerFuture->Next14Days'),
    [TabFuture.Next28Days]: t('DatePickerFuture->Next28Days'),
    [TabFuture.NextWeek]: t('DatePickerFuture->NextWeek'),
    [TabFuture.NextMonth]: t('DatePickerFuture->NextMonth'),
    [TabFuture.NextQuarter]: t('DatePickerFuture->NextQuarter'),
    [TabFuture.NextYear]: t('DatePickerFuture->NextYear')
  }
  return (value: TabFuture) => translatedValues[value]
}

export const useTranslateDatePickerNowValues = () => {
  const {t} = useTranslation()
  const translatedValues: Record<TabNow, string> = {
    [TabNow.FromNow]: t('DatePickerNow->FromNow'),
    [TabNow.FromToday]: t('DatePickerNow->FromToday'),
    [TabNow.Today]: t('DatePickerNow->Today'),
    [TabNow.UntilToday]: t('DatePickerNow->UntilToday'),
    [TabNow.ThisWeek]: t('DatePickerNow->ThisWeek'),
    [TabNow.ThisMonth]: t('DatePickerNow->ThisMonth'),
    [TabNow.ThisQuarter]: t('DatePickerNow->ThisQuarter'),
    [TabNow.ThisYear]: t('DatePickerNow->ThisYear')
  }
  return (value: TabNow) => translatedValues[value]
}

export const useTranslateDatePickerPastValues = () => {
  const {t} = useTranslation()
  const translatedValues: Record<TabPast, string> = {
    [TabPast.Yesterday]: t('DatePickerPast->Yesterday'),
    [TabPast.Last7Days]: t('DatePickerPast->Last7Days'),
    [TabPast.Last14Days]: t('DatePickerPast->Last14Days'),
    [TabPast.Last28Days]: t('DatePickerPast->Last28Days'),
    [TabPast.LastWeek]: t('DatePickerPast->LastWeek'),
    [TabPast.LastMonth]: t('DatePickerPast->LastMonth'),
    [TabPast.LastQuarter]: t('DatePickerPast->LastQuarter'),
    [TabPast.LastYear]: t('DatePickerPast->LastYear')
  }
  return (value: TabPast) => translatedValues[value]
}

export const useTranslateDatePickerGroupValues = () => {
  const {t} = useTranslation()
  const translatedValues: Record<TabGroup, string> = {
    [TabGroup.Future]: t('DatePickerGroup->Future'),
    [TabGroup.Now]: t('DatePickerGroup->Now'),
    [TabGroup.Past]: t('DatePickerGroup->Past'),
    [TabGroup.Custom]: t('DatePickerGroup->Custom')
  }
  return (value: TabGroup) => translatedValues[value]
}
