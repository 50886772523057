import DeleteIcon from '@mui/icons-material/Delete'
import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages, PermissionCode} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {useCustomerParams} from '../../../../utils/pathname'
import {
  ButtonWithConfirmationDialog,
  DrawerTemplate,
  DrawerTemplateHeader
} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CustomerGroupForm} from './CustomerGroupForm'
import {
  useCustomerGroup,
  useDeleteCustomerGroup,
  useUpdateCustomerGroup
} from './graphql'
import {CustomerGroupFormField, ICustomerGroupForm} from './types'

const UPDATE_CUSTOMER_GROUP_FORM_ID = 'updateCustomerGroupForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface IUpdateCustomerGroupDrawerProps {
  onExited: () => void
}

export const UpdateCustomerGroupDrawer: React.FC<IUpdateCustomerGroupDrawerProps> =
  ({onExited}: IUpdateCustomerGroupDrawerProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {customerGroupId} = useCustomerParams()
    const {data, loading, error} = useCustomerGroup(customerGroupId)
    const updateCustomerGroup = useUpdateCustomerGroup()
    const deleteCustomerGroup = useDeleteCustomerGroup()
    const {
      addInfoNotification,
      defaultErrorHandler,
      setShowBackdrop,
      customErrorHandler
    } = useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (customerGroupId) {
        openDrawer()
      }
    }, [customerGroupId, openDrawer])
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: ICustomerGroupForm) => {
        try {
          setShowBackdrop(true)
          await updateCustomerGroup({id: customerGroupId, input: data})
          addInfoNotification(t('Customer group updated'))
          onExited()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating customer group'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        customerGroupId,
        defaultErrorHandler,
        onExited,
        setShowBackdrop,
        t,
        updateCustomerGroup
      ]
    )
    const handleDeleteConfirmation = useCallback(async () => {
      try {
        setShowBackdrop(true)
        await deleteCustomerGroup(customerGroupId)
        addInfoNotification(t('Customer group deleted'))
        onExited()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.CantDeleteCustomerGroupWithCustomers
          )
        ) {
          customErrorHandler(error, {
            title: t('Unable to delete customer group'),
            contentText: t(
              'This group is assigned to one or more customers. Remove group from all users and try to delete it again.'
            ),
            confirmButtonLabel: t('Got it')
          })
        } else {
          defaultErrorHandler(error, t('Error while deleting customer group'))
        }
      } finally {
        setShowBackdrop(false)
      }
    }, [
      addInfoNotification,
      customErrorHandler,
      customerGroupId,
      defaultErrorHandler,
      deleteCustomerGroup,
      onExited,
      setShowBackdrop,
      t
    ])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={error && t('Error while loading customer group')}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update customer group')}
            />
          }
          footer={
            <>
              {P([PermissionCode.DeleteCustomerGroup]) && (
                <ButtonWithConfirmationDialog
                  onConfirmButtonClick={handleDeleteConfirmation}
                  dialogProps={{
                    confirmButtonLabel: t('Delete'),
                    title: t('Delete customer group?'),
                    contentText: t(
                      'Are you sure you want to delete this customer group? This can’t be undone.'
                    )
                  }}
                  buttonProps={{
                    startIcon: <DeleteIcon />,
                    children: t('Delete'),
                    color: 'primary'
                  }}
                />
              )}
              <SaveButton type="submit" form={UPDATE_CUSTOMER_GROUP_FORM_ID}>
                {t('Save')}
              </SaveButton>
            </>
          }
        >
          {data && (
            <CustomerGroupForm
              formId={UPDATE_CUSTOMER_GROUP_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={{
                [CustomerGroupFormField.Name]: data.customerGroup.name,
                [CustomerGroupFormField.Description]:
                  data.customerGroup.description || ''
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
