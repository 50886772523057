import {useTranslation} from 'react-i18next'
import {WarehouseDocumentSource} from '../__generated__/schema'

export const useTranslateWarehouseDocumentSource = () => {
  const {t} = useTranslation()
  const translated: Record<WarehouseDocumentSource, string> = {
    [WarehouseDocumentSource.InventoryCheck]: t(
      'WarehouseDocumentSource->InventoryCheck'
    ),
    [WarehouseDocumentSource.Shop]: t('WarehouseDocumentSource->Shop'),
    [WarehouseDocumentSource.Stock]: t('WarehouseDocumentSource->Stock'),
    [WarehouseDocumentSource.Transfer]: t('WarehouseDocumentSource->Transfer'),
    [WarehouseDocumentSource.StockWriteOff]: t(
      'WarehouseDocumentSource->StockWriteOff'
    )
  }
  return (source: WarehouseDocumentSource) => translated[source]
}
