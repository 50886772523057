import {useLazyQuery} from '@apollo/react-hooks'
import {ApolloError} from 'apollo-client'
import {noop} from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {v4 as uuidv4} from 'uuid'
import {
  BaseWebsiteFooterFieldsFragment,
  BaseWebsiteHeaderFieldsFragment,
  BaseWebsitePageFieldsFragment,
  Scalars,
  WebsiteFootersQuery,
  WebsiteFootersQueryVariables,
  WebsiteHeadersQuery,
  WebsiteHeadersQueryVariables,
  WebsitePagesQuery,
  WebsitePagesQueryVariables
} from '../../../../../__generated__/schema'
import {useWebsiteParams} from '../../../../../utils/pathname'
import {WEBSITE_FOOTERS, WEBSITE_HEADERS, WEBSITE_PAGES} from '../graphql'
import {WebsiteEditorContent, WebsitePageMode} from '../types'

interface IWebsitePageContextValue {
  websitePages: BaseWebsitePageFieldsFragment[]
  selectedWebsitePage: BaseWebsitePageFieldsFragment | null
  setSelectedWebsitePage: (
    websitePage: BaseWebsitePageFieldsFragment | null
  ) => void
  websitePagesLoading: boolean
  websitePagesError?: ApolloError
  websiteId: number
  selectedWebsiteEditorContent?: WebsiteEditorContent
  setSelectedWebsiteEditorContent: (content?: WebsiteEditorContent) => void
  refetchWebsitePages: () => void
  selectedWebsitePageMode: WebsitePageMode
  setSelectedWebsitePageMode: (mode: WebsitePageMode) => void
  blocksWithId: Scalars['JSON']
  setBlocksWithId: (blocks: Scalars['JSON']) => void
  websiteHeaders: BaseWebsiteHeaderFieldsFragment[]
  refetchWebsiteHeaders: () => void
  websiteHeadersLoading: boolean
  websiteHeadersError?: ApolloError
  websiteFooters: BaseWebsiteFooterFieldsFragment[]
  refetchWebsiteFooters: () => void
  websiteFootersLoading: boolean
  websiteFootersError?: ApolloError
}

const WebsitePageContext = createContext<IWebsitePageContextValue>({
  websitePages: [],
  selectedWebsitePage: null,
  setSelectedWebsitePage: noop,
  websitePagesLoading: false,
  websiteId: 0,
  setSelectedWebsiteEditorContent: noop,
  refetchWebsitePages: noop,
  selectedWebsitePageMode: WebsitePageMode.Editing,
  setSelectedWebsitePageMode: noop,
  blocksWithId: [],
  setBlocksWithId: noop,
  websiteHeaders: [],
  refetchWebsiteHeaders: noop,
  websiteHeadersLoading: false,
  websiteFooters: [],
  refetchWebsiteFooters: noop,
  websiteFootersLoading: false
})

interface IWebsitePageContextProviderProps {
  children: React.ReactElement
}

export const WebsitePageContextProvider: React.FC<IWebsitePageContextProviderProps> =
  ({children}: IWebsitePageContextProviderProps) => {
    const {websiteId} = useWebsiteParams()
    const [websitePages, setWebsitePages] = useState<
      BaseWebsitePageFieldsFragment[]
    >([])
    const [websiteHeaders, setWebsiteHeaders] = useState<
      BaseWebsiteHeaderFieldsFragment[]
    >([])
    const [websiteFooters, setWebsiteFooters] = useState<
      BaseWebsiteFooterFieldsFragment[]
    >([])
    const [selectedWebsitePage, setSelectedWebsitePage] =
      useState<BaseWebsitePageFieldsFragment | null>(null)
    const [selectedWebsiteEditorContent, setSelectedWebsiteEditorContent] =
      useState<WebsiteEditorContent | undefined>(undefined)
    const [selectedWebsitePageMode, setSelectedWebsitePageMode] =
      useState<WebsitePageMode>(WebsitePageMode.Editing)
    const [blocksWithId, setBlocksWithId] = useState<Scalars['JSON']>([])
    const [
      queryWebsiteHeaders,
      {loading: websiteHeadersLoading, error: websiteHeadersError}
    ] = useLazyQuery<WebsiteHeadersQuery, WebsiteHeadersQueryVariables>(
      WEBSITE_HEADERS,
      {
        variables: {websiteId},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          setWebsiteHeaders(data.websiteHeaders)
        }
      }
    )
    const [
      queryWebsiteFooters,
      {loading: websiteFootersLoading, error: websiteFootersError}
    ] = useLazyQuery<WebsiteFootersQuery, WebsiteFootersQueryVariables>(
      WEBSITE_FOOTERS,
      {
        variables: {websiteId},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          setWebsiteFooters(data.websiteFooters)
        }
      }
    )
    const [
      queryWebsitePages,
      {loading: websitePagesLoading, error: websitePagesError}
    ] = useLazyQuery<WebsitePagesQuery, WebsitePagesQueryVariables>(
      WEBSITE_PAGES,
      {
        variables: {websiteId},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          setWebsitePages(data.websitePages)
          if (
            (data.websitePages.length > 0 &&
              selectedWebsitePage &&
              data.websitePages.filter(({ID}) => ID === selectedWebsitePage.ID)
                .length === 0) ||
            (!selectedWebsitePage && data.websitePages.length > 0)
          ) {
            setSelectedWebsitePage(data.websitePages[0])
          }
          if (selectedWebsitePage) {
            const currentPage = data.websitePages.find(
              (websitePage) => websitePage.ID === selectedWebsitePage.ID
            )
            if (currentPage) {
              setSelectedWebsitePage(currentPage)
            }
          }
        }
      }
    )
    const refetchWebsitePages = useCallback(() => {
      queryWebsitePages()
    }, [queryWebsitePages])
    const refetchWebsiteHeaders = useCallback(() => {
      queryWebsiteHeaders()
    }, [queryWebsiteHeaders])
    const refetchWebsiteFooters = useCallback(() => {
      queryWebsiteFooters()
    }, [queryWebsiteFooters])
    useEffect(() => {
      if (!isNaN(websiteId)) {
        queryWebsitePages()
        queryWebsiteHeaders()
        queryWebsiteFooters()
      }
      return () => {
        setWebsitePages([])
        setWebsiteHeaders([])
        setWebsiteFooters([])
      }
    }, [queryWebsiteFooters, queryWebsiteHeaders, queryWebsitePages, websiteId])
    useEffect(() => {
      if (selectedWebsitePage) {
        setBlocksWithId(
          selectedWebsitePage.blocks.map((block: Scalars['JSON']) => ({
            ...block,
            id: uuidv4()
          }))
        )
      }
    }, [selectedWebsitePage])
    return (
      <WebsitePageContext.Provider
        value={{
          websitePages,
          setSelectedWebsitePage,
          selectedWebsitePage,
          websitePagesLoading,
          websitePagesError,
          websiteId,
          selectedWebsiteEditorContent,
          setSelectedWebsiteEditorContent,
          refetchWebsitePages,
          selectedWebsitePageMode,
          setSelectedWebsitePageMode,
          blocksWithId,
          setBlocksWithId,
          websiteHeaders,
          refetchWebsiteHeaders,
          websiteHeadersLoading,
          websiteHeadersError,
          websiteFooters,
          refetchWebsiteFooters,
          websiteFootersLoading,
          websiteFootersError
        }}
      >
        {children}
      </WebsitePageContext.Provider>
    )
  }

export const useWebsitePageContext = () => useContext(WebsitePageContext)
