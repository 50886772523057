import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientState,
  DivisionPropertiesFragment,
  DivisionState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {DataGridTable} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {
  divisionStateColors,
  useDivisionStateTranslations
} from '../../../constants'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'

import {useGetDivisions} from './graphql'

interface IDivisionsTableProps {
  divisions: DivisionPropertiesFragment[]
}

const StateRenderer = ({state}: {state: DivisionState}) => {
  const divisionStateTranslations = useDivisionStateTranslations()
  return (
    <EntityStateChip
      label={divisionStateTranslations[state]}
      colorConf={divisionStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.divisions.edit(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const DivisionsTable: React.FC<IDivisionsTableProps> = ({
  divisions
}: IDivisionsTableProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('ID'),
        field: 'id',
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Code'),
        field: 'code',
        minWidth: 150
      },
      {
        headerName: t('Town'),
        field: 'town',
        valueGetter: (params) => params.row.address?.town,
        minWidth: 150
      },
      {
        headerName: t('Short name'),
        field: 'shortName',
        minWidth: 150
      },
      {
        headerName: t('Name', {context: 'object'}),
        field: 'name',
        minWidth: 150
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ClientState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 100
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t]
  )
  return (
    <Box sx={{height: '100%', width: '100%'}}>
      <DataGridTable
        columns={columns}
        rows={divisions}
        pagination={false}
        hideFooter
        disableRowSelectionOnClick
        initialState={{pinnedColumns: {left: ['id'], right: ['arrow']}}}
        columnVisibilityModel={{arrow: P([PermissionCode.UpdateDivision])}}
        localeText={{noRowsLabel: t('No divisions to show')}}
      />
    </Box>
  )
}

export const DivisionList: React.FC = () => {
  const {error, loading, data} = useGetDivisions()
  const {P} = useEnsurePermissions()
  const {t} = useTranslation()
  const history = useHistory()
  const handleAddClick = useCallback(
    () => history.push(routeTo.admin.divisions.add()),
    [history]
  )
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate header={<PrimaryHeader title={t('Divisions')} />}>
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage his divisions.')}
      >
        <RenderOnData
          loading={loading}
          error={error}
          dataCondition={(data) => Boolean(data?.divisions)}
          errorMessage={t<string>('Could not load divisions')}
          data={data}
        >
          {({divisions}) => (
            <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
              <DivisionsTable divisions={divisions} />
              {P([PermissionCode.CreateDivision]) && (
                <CreateFab classes={fabClasses} onClick={handleAddClick} />
              )}
            </Box>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
