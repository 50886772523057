import React from 'react'
import {Route, Switch} from 'react-router'
import {RouteComponentProps} from 'react-router-dom'
import {
  DetailClientShowPropertiesFragment,
  PermissionCode,
  ShowImageType
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {SHOWS_PARAMS as SP} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {ShowSection} from '../../../../constants'
import {AssignMediaFromLibrary} from '../assignMediaFromLibrary/AssignMediaFromLibrary'
import {ClientShowDetailProvider} from '../ClientShowDetailProvider'
import {EditClientShowCrew} from './EditClientShowCrew'
import {EditClientShowData} from './EditClientShowData'
import {EditClientShowMedia} from './EditClientShowMedia'
import {EditClientShowTranslations} from './EditClientShowTranslations'

interface IEditClientShowProps
  extends RouteComponentProps<{
    showId: string
  }> {}

export const EditClientShow: React.FC<IEditClientShowProps> = ({
  match,
  history
}: IEditClientShowProps) => {
  const showId = parseInt(match.params.showId, 10)
  const {P} = useEnsurePermissions()
  return (
    <ClientShowDetailProvider
      showId={showId}
      onClose={() => history.replace(routeTo.admin.shows.home())}
    >
      {(clientShow: DetailClientShowPropertiesFragment) => (
        <Switch>
          <Route
            path={routeTo.admin.shows.editWithEditSection(
              toPlaceholderParam(SP.SHOW_ID),
              ShowSection.Translations
            )}
          >
            <EditClientShowTranslations clientShow={clientShow} />
          </Route>
          {P([PermissionCode.ReadMixedShowImages]) && (
            <Route
              path={routeTo.admin.shows.assignMediaFromLibrary(
                toPlaceholderParam(SP.SHOW_ID),
                ShowSection.Media,
                `:${SP.SHOW_IMAGE_TYPE}(${Object.values(ShowImageType).join(
                  '|'
                )})`
              )}
              exact
              component={AssignMediaFromLibrary}
            />
          )}
          <Route
            path={routeTo.admin.shows.editWithEditSection(
              toPlaceholderParam(SP.SHOW_ID),
              ShowSection.Media
            )}
          >
            <EditClientShowMedia clientShow={clientShow} />
          </Route>
          {P([PermissionCode.UpsertClientShowCrew]) && (
            <Route
              path={routeTo.admin.shows.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                ShowSection.Crew
              )}
            >
              <EditClientShowCrew clientShow={clientShow} />
            </Route>
          )}
          <Route
            path={[
              routeTo.admin.shows.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                ShowSection.Data
              ),
              routeTo.admin.shows.edit(toPlaceholderParam(SP.SHOW_ID))
            ]}
          >
            <EditClientShowData clientShow={clientShow} />
          </Route>
        </Switch>
      )}
    </ClientShowDetailProvider>
  )
}
