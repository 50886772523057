import {useEffect, useCallback} from 'react'

import {useSelector} from '../redux'
import {useEditorModeActions} from '../redux/editorMode/actions'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {isSomeObjectSelectedSelector} from '../redux/objects/selectors'
import {useSelectionActions} from '../redux/selection/actions'
import {escapeHandler} from './keyboard'

export const useMouseListener = () => {
  const {selectedMode} = useSelector(editorModeSelector)
  const isSomeObjectSelected = useSelector(isSomeObjectSelectedSelector)

  const {setSelectMode} = useEditorModeActions()
  const {unselectAll} = useSelectionActions()

  const onContextMenu = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      escapeHandler(
        selectedMode,
        isSomeObjectSelected,
        unselectAll,
        setSelectMode
      )
    },
    [isSomeObjectSelected, selectedMode, setSelectMode, unselectAll]
  )

  useEffect(() => {
    document.addEventListener('contextmenu', onContextMenu)

    return () => {
      document.removeEventListener('contextmenu', onContextMenu)
    }
  }, [onContextMenu])
}
