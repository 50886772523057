import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AdmissionTypeInput,
  AdmissionTypeQuery,
  AdmissionTypeQueryVariables,
  AdmissionTypesFilter,
  AdmissionTypesQuery,
  AdmissionTypesQueryVariables,
  CreateAdmissionTypeMutation,
  CreateAdmissionTypeMutationVariables,
  DeleteDraftAdmissionTypeMutation,
  DeleteDraftAdmissionTypeMutationVariables,
  UpdateAdmissionTypeMutation,
  UpdateAdmissionTypeMutationVariables,
  UpdateDraftAdmissionTypeMutation,
  UpdateDraftAdmissionTypeMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {ECOMMERCE_TRANSLATED_FIELDS} from '../graphql'

const ADMISSION_TYPE_FIELDS = gql`
  ${ECOMMERCE_TRANSLATED_FIELDS}
  fragment AdmissionTypeFields on AdmissionType {
    id
    color
    icon
    name
    abbreviation
    capacityDecreaseCount
    startingQuantity
    state
    internalDescription
    eCommerceNames {
      ...ECommerceTranslatedFields
    }
    eCommerceDescriptions {
      ...ECommerceTranslatedFields
    }
  }
`

const ADMISSION_TYPES = gql`
  ${PAGINATION_FRAGMENT}
  query AdmissionTypes(
    $filter: AdmissionTypesFilter
    $paginationInput: PaginationInput!
  ) {
    admissionTypes(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        color
        icon
        name
        abbreviation
        capacityDecreaseCount
        startingQuantity
        state
        internalDescription
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useAdmissionTypes = (filter?: AdmissionTypesFilter) =>
  useQuery<AdmissionTypesQuery, AdmissionTypesQueryVariables>(ADMISSION_TYPES, {
    variables: {filter, paginationInput: {offset: 0, limit: 100}},
    fetchPolicy: 'network-only'
  })

const CREATE_ADMISSION_TYPE = gql`
  mutation CreateAdmissionType($input: AdmissionTypeInput!) {
    createAdmissionType(input: $input) {
      id
    }
  }
`

export const useCreateAdmissionType = () => {
  const [createAdmissionType] = useMutation<
    CreateAdmissionTypeMutation,
    CreateAdmissionTypeMutationVariables
  >(CREATE_ADMISSION_TYPE)
  return useCallback(
    (input: AdmissionTypeInput, appliedFilter?: AdmissionTypesFilter) =>
      createAdmissionType({
        variables: {input},
        refetchQueries: [
          {
            query: ADMISSION_TYPES,
            variables: {
              filter: appliedFilter,
              paginationInput: {
                offset: 0,
                limit: 100
              }
            }
          }
        ]
      }),
    [createAdmissionType]
  )
}

const ADMISSION_TYPE = gql`
  ${ADMISSION_TYPE_FIELDS}
  query AdmissionType($id: PositiveInt!) {
    admissionType(id: $id) {
      ...AdmissionTypeFields
    }
  }
`

export const useAdmissionType = (id: number) =>
  useQuery<AdmissionTypeQuery, AdmissionTypeQueryVariables>(ADMISSION_TYPE, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const DELETE_DRAFT_ADMISSION_TYPE = gql`
  mutation DeleteDraftAdmissionType($id: PositiveInt!) {
    deleteDraftAdmissionType(id: $id)
  }
`

export const useDeleteDraftAdmissionType = () => {
  const [deleteDraftAdmissionType] = useMutation<
    DeleteDraftAdmissionTypeMutation,
    DeleteDraftAdmissionTypeMutationVariables
  >(DELETE_DRAFT_ADMISSION_TYPE)
  return useCallback(
    (id: number, appliedFilter?: AdmissionTypesFilter) =>
      deleteDraftAdmissionType({
        variables: {id},
        refetchQueries: [
          {
            query: ADMISSION_TYPES,
            variables: {
              filter: appliedFilter,
              paginationInput: {
                offset: 0,
                limit: 100
              }
            }
          }
        ]
      }),
    [deleteDraftAdmissionType]
  )
}

const UPDATE_DRAFT_ADMISSION_TYPE = gql`
  ${ADMISSION_TYPE_FIELDS}
  mutation UpdateDraftAdmissionType(
    $id: PositiveInt!
    $input: UpdateDraftAdmissionTypeInput!
  ) {
    updateDraftAdmissionType(id: $id, input: $input) {
      ...AdmissionTypeFields
    }
  }
`

export const useUpdateDraftAdmissionType = () => {
  const [updateDraftAdmissionType] = useMutation<
    UpdateDraftAdmissionTypeMutation,
    UpdateDraftAdmissionTypeMutationVariables
  >(UPDATE_DRAFT_ADMISSION_TYPE)
  return useCallback(
    (variables: UpdateDraftAdmissionTypeMutationVariables) =>
      updateDraftAdmissionType({variables}),
    [updateDraftAdmissionType]
  )
}

const UPDATE_ADMISSION_TYPE = gql`
  ${ADMISSION_TYPE_FIELDS}
  mutation UpdateAdmissionType(
    $id: PositiveInt!
    $input: UpdateAdmissionTypeInput!
  ) {
    updateAdmissionType(id: $id, input: $input) {
      ...AdmissionTypeFields
    }
  }
`

export const useUpdateAdmissionType = () => {
  const [updateAdmissionType] = useMutation<
    UpdateAdmissionTypeMutation,
    UpdateAdmissionTypeMutationVariables
  >(UPDATE_ADMISSION_TYPE)
  return useCallback(
    (variables: UpdateAdmissionTypeMutationVariables) =>
      updateAdmissionType({variables}),
    [updateAdmissionType]
  )
}
