import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'

import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../common'
import {CancelButton, SaveButton} from '../../../common/Buttons'
import {useBackdropState} from '../../../context/backdrop'
import {useNotifications} from '../../../context/notifications'
import {useGetLightweightDivisions} from '../graphql'
import {useShowFormStyles} from '../shows/ShowForm'
import {DiscountForm} from './DiscountForm'
import {useCreateDiscount} from './graphql'
import {IDiscountForm, transformDiscountFormIntoInput} from './types'

const CREATE_DISCOUNT_DRAWER_FORM_ID = 'createDiscountDrawerFormId'

interface ICreateDiscountDrawerProps {
  closeDrawer(): void
}

export const CreateDiscountDrawer: React.FC<ICreateDiscountDrawerProps> = ({
  closeDrawer
}: ICreateDiscountDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const showFormClasses = useShowFormStyles()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()
  const createDiscount = useCreateDiscount()
  const history = useHistory()
  const handleSubmit = useCallback(
    async (data: IDiscountForm) => {
      setShowBackdrop(true)
      try {
        const result = await createDiscount(
          transformDiscountFormIntoInput(data)
        )
        addInfoNotification(t('Discount created'))
        closeDrawer()
        history.push(
          routeTo.admin.discounts.detail(result.data!.createDiscount.id)
        )
      } catch (e) {
        defaultErrorHandler(e, t('Error while creating a discount'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDrawer,
      createDiscount,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t
    ]
  )
  const {divisions, error, loading} = useGetLightweightDivisions()
  return (
    <RenderOnData
      data={divisions}
      error={error}
      loading={loading}
      errorMessage={t<string>('Loading divisions failed!')}
    >
      {(divisions) => (
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              title={t('Create discount')}
              onLeftActionClick={closeDrawer}
            />
          }
          footer={
            <>
              <CancelButton onClick={closeDrawer} />
              {P([PermissionCode.CreateDiscount]) && (
                <SaveButton type="submit" form={CREATE_DISCOUNT_DRAWER_FORM_ID}>
                  {t('Create')}
                </SaveButton>
              )}
            </>
          }
        >
          <DiscountForm
            formId={CREATE_DISCOUNT_DRAWER_FORM_ID}
            onSubmit={handleSubmit}
            className={showFormClasses.form}
            divisions={divisions}
          />
        </DrawerTemplate>
      )}
    </RenderOnData>
  )
}
