import {OperationVariables, QueryResult} from '@apollo/react-common'
import {QueryHookOptions, useQuery} from '@apollo/react-hooks'
import {DocumentNode} from 'graphql'
import omit from 'lodash/omit'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Pagination, PaginationInput} from '../__generated__/schema'
import {useNotifications} from '../components/context/notifications'

export const extractPaginationInput = (
  pagination: Pagination
): PaginationInput => omit(pagination, ['hasMore', 'totalRowsCount'])

interface IResult<TData, TVariables>
  extends Omit<QueryResult<TData, TVariables>, 'fetchMore'> {
  fetchMore(): void

  isLoadingMore: boolean
}

interface IPaginationOptions<TData> {
  /**
   * Used to update data graph after successfull fetchMore call
   * @param prevData
   * @param fetchMoreData
   */
  updateData: (prevData: TData, fetchMoreData: TData) => TData
  /**
   * Used to get paginationInput from data for fetchMore call
   * @param data
   */
  mapPaginationInput: (data: TData) => PaginationInput
}

export const useQueryWithPagination = <
  TData extends object | undefined = object,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables>,
  paginationOptions: IPaginationOptions<TData>
): IResult<TData, TVariables> => {
  const {variables} = options
  const {data, fetchMore, ...rest} = useQuery<TData, TVariables>(query, options)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const {mapPaginationInput, updateData} = paginationOptions

  const {addErrorNotification} = useNotifications()
  const {t} = useTranslation()

  return {
    data,
    fetchMore: () => {
      setIsLoadingMore(true)
      fetchMore({
        variables: {
          ...variables,
          paginationInput: mapPaginationInput(data!)
        },
        updateQuery: (prevData: TData, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prevData

          return updateData(prevData, fetchMoreResult)
        }
      })
        .catch(() => {
          addErrorNotification(t('Failed to fetch more data'))
        })
        .finally(() => {
          setIsLoadingMore(false)
        })
    },
    isLoadingMore,
    ...rest
  }
}
