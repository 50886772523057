import {SxProps, Typography, TypographyVariant} from '@mui/material'
import React from 'react'
import {ShowContentDescriptorCode} from '../../__generated__/schema'
import {
  useTranslateShowContentDescriptorCode,
  useTranslateShowContentDescriptorCodeAbbreviation
} from '../../hooks/translateShowContentDescriptorCode'
import {Theme} from '../../theme'
import {Tooltip} from './Tooltip'

interface IDescriptorsRowProps {
  descriptorCodes: ShowContentDescriptorCode[]
  showAsAbbreviation?: boolean
  prefix?: string
  sx?: SxProps<Theme>
  component?: React.ElementType
  typographyVariant?: TypographyVariant
}

export const DescriptorsRow: React.FC<IDescriptorsRowProps> = ({
  descriptorCodes,
  showAsAbbreviation,
  prefix,
  sx,
  component = 'div',
  typographyVariant
}: IDescriptorsRowProps) => {
  const translateShowContentDescriptorCode =
    useTranslateShowContentDescriptorCode()
  const translateShowContentDescriptorCodeAbbreviation =
    useTranslateShowContentDescriptorCodeAbbreviation()
  return (
    <Typography
      component={component}
      variant={typographyVariant || 'inherit'}
      sx={sx}
    >
      {prefix && `${prefix}: `}
      {descriptorCodes.reduce(
        (acc: React.ReactNode, code) => [
          acc,
          acc !== null && ', ',
          showAsAbbreviation ? (
            <Tooltip
              key={code}
              title={translateShowContentDescriptorCode(code)}
            >
              <span>
                {translateShowContentDescriptorCodeAbbreviation(code)}
              </span>
            </Tooltip>
          ) : (
            translateShowContentDescriptorCode(code)
          )
        ],
        null
      )}
    </Typography>
  )
}
