import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import {Button} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../__generated__/schema'

import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {ButtonWithConfirmationDialog} from '../../../../common'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 2)
  }
}))

interface ISubCartHeaderProps {
  areActionsDisabled?: boolean
  onRemoveAllEventItemsClick: () => void
  onDiscountsButtonClick: () => void
}

export const SubCartHeader: React.FC<ISubCartHeaderProps> = ({
  areActionsDisabled,
  onRemoveAllEventItemsClick,
  onDiscountsButtonClick
}: ISubCartHeaderProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  return (
    <div className={classes.root}>
      {P([PermissionCode.RemoveAllTicketItemsFromCartForEvent]) && (
        <ButtonWithConfirmationDialog
          buttonProps={{
            children: t('Remove'),
            disabled: areActionsDisabled,
            startIcon: <RemoveShoppingCartIcon />
          }}
          onConfirmButtonClick={onRemoveAllEventItemsClick}
          dialogProps={{
            title: t('Remove items from cart?'),
            contentText: t(
              'Do you really want to remove those items from cart?'
            ),
            confirmButtonLabel: t('Remove')
          }}
        />
      )}
      <Button
        color="primary"
        disabled={areActionsDisabled}
        onClick={onDiscountsButtonClick}
      >
        {t('Discounts')}
      </Button>
    </div>
  )
}
