import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {ButtonWithConfirmationDialog} from '../../../common'
import {useDiscardCart} from './cart/graphql'
import {useCurrentCart} from './CurrentCartContext'

interface IDiscardCartButtonProps {
  cart: CartPropertiesFragment | null
}

export const DiscardCartButton: React.FC<IDiscardCartButtonProps> = ({
  cart
}: IDiscardCartButtonProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const discardCart = useDiscardCart()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const history = useHistory()
  const {resetCurrentCart} = useCurrentCart()

  const handleDiscardCartConfirm = useCallback(async () => {
    try {
      if (cart?.id) {
        setShowBackdrop(true)
        await discardCart(cart.id)
        resetCurrentCart()
        history.replace(routeTo.admin.cashDesk.index())
        addInfoNotification(t('Cart discarded'))
      }
    } catch (e) {
      defaultErrorHandler(e, t('Discarding cart failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    cart?.id,
    defaultErrorHandler,
    discardCart,
    history,
    resetCurrentCart,
    setShowBackdrop,
    t
  ])
  return cart?.state === CartState.Draft && P([PermissionCode.DiscardCart]) ? (
    <ButtonWithConfirmationDialog
      onConfirmButtonClick={handleDiscardCartConfirm}
      buttonProps={{
        children: t('Discard cart'),
        disabled: !cart || cart.items?.length === 0,
        startIcon: <RemoveShoppingCartIcon />
      }}
      dialogProps={{
        title: t('Discard cart?'),
        contentText: t(
          "Are you sure you want to discard this cart? This can't be undone."
        ),
        confirmButtonLabel: t('Discard')
      }}
    />
  ) : null
}
