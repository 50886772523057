import React from 'react'
import {useTranslation} from 'react-i18next'
import {Blank, BlankContentPosition} from '../visual/Blank'

export const NoCamerasFoundBlank: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Blank
      title={t('No cameras found')}
      contentPosition={BlankContentPosition.Center}
    />
  )
}
