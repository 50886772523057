import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  VoucherCampaignState
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useHandleInvalidDateTimes} from '../../../../hooks/pickerErrors'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {
  useIsIntegerWithMinMaxBoundary,
  useIsValidMoneyAmount
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  VoucherActivationOption,
  VoucherActivationOptionsField
} from '../components/types'
import {VoucherActivationOptions} from '../components/VoucherActivationOptions'
import {useGetLightweightVoucherCampaigns} from './graphql'
import {IVouchersForm, VouchersFormField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  },
  sectionTitle: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2)
  }
}))

interface IVouchersFormProps {
  formId: string
  onSubmit: (data: IVouchersForm) => void
  defaultValues?: Partial<IVouchersForm>
}

export const VouchersForm: React.FC<IVouchersFormProps> = ({
  formId,
  onSubmit,
  defaultValues
}: IVouchersFormProps) => {
  const {t} = useTranslation()
  const {data: voucherCampaignsData} = useGetLightweightVoucherCampaigns({
    canCreateNewVouchers: true,
    state: [VoucherCampaignState.Active, VoucherCampaignState.Inactive]
  })
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const [isBalanceFieldDisabled, setIsBalanceFieldDisabled] =
    useState<boolean>(false)
  const vouchersFormProps = useForm<IVouchersForm>({defaultValues})
  const {
    errors,
    setError,
    setValue,
    watch,
    register,
    triggerValidation,
    handleSubmit
  } = vouchersFormProps
  const isIntegerWithMinMaxBoundary = useIsIntegerWithMinMaxBoundary(1, 500)
  const isValidMoneyAmount = useIsValidMoneyAmount()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const campaignIdField = watch(VouchersFormField.CampaignId)
  useEffect(() => {
    if (voucherCampaignsData) {
      const campaign = voucherCampaignsData.voucherCampaigns.items.find(
        (vc) => vc.id === Number(campaignIdField)
      )
      if (campaign) {
        setValue(
          VouchersFormField.Balance,
          campaign.initialBalance || undefined
        )
        setIsBalanceFieldDisabled(!!campaign.initialBalance)
      }
    }
  }, [
    campaignIdField,
    setValue,
    voucherCampaignsData,
    voucherCampaignsData?.voucherCampaigns.items
  ])
  const _onSubmit = useCallback(
    async (data: IVouchersForm) => {
      try {
        await onSubmit(data)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidVoucherActivationDate
          )
        ) {
          setError(
            VoucherActivationOptionsField.ActivationDatetime,
            t('Can’t be in past.')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidVoucherExpirationDate
          )
        ) {
          setError(
            VoucherActivationOptionsField.ExpirationDatetime,
            t('Can’t be before activation date.')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.ForbiddenToCreateVouchersForCampaign
          )
        ) {
          setError(
            VouchersFormField.CampaignId,
            t(
              'Forbidden to set custom initial balance for vouchers in selected campaign.'
            )
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.UnexpectedVoucherInitialBalance
          )
        ) {
          setError(
            VouchersFormField.Balance,
            t(
              'Forbidden to create vouchers in selected campaign. Select another campaign.'
            )
          )
        } else {
          defaultErrorHandler(error, t('Error while creating vouchers'))
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  const classes = useStyles()
  const handleInvalidDateTimes =
    useHandleInvalidDateTimes<IVouchersForm>(setError)
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(handleInvalidDateTimes(_onSubmit))}
      className={cn(classes.form)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVouchersForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VouchersFormField.Count}
            key={VouchersFormField.Count}
            label={t('Voucher count')}
            validationOptions={{
              required: true,
              validate: isIntegerWithMinMaxBoundary
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IVouchersForm>
            selectOptions={(
              voucherCampaignsData?.voucherCampaigns.items || []
            ).reduce(
              (acc, vc) => ({
                ...acc,
                [vc.id]: vc.name
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            name={VouchersFormField.CampaignId}
            key={VouchersFormField.CampaignId}
            label={t('Campaign')}
            validationOptions={{required: true}}
            fullWidth
            hasNoneSelectOption
            required
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IVouchersForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={VouchersFormField.Balance}
            key={VouchersFormField.Balance}
            label={t('Initial balance ({{currencySign}})', {
              currencySign: translatedEffectiveClientCurrencySign
            })}
            helperNote={t(
              'If is not set each voucher in campaign can have custom initial balance. '
            )}
            validationOptions={{
              validate: isValidMoneyAmount
            }}
            disabled={isBalanceFieldDisabled}
            fullWidth
          />
        ]}
      />
      <FormContext {...vouchersFormProps}>
        <VoucherActivationOptions<IVouchersForm>
          availableOptions={[
            VoucherActivationOption.Later,
            VoucherActivationOption.Now,
            VoucherActivationOption.Date
          ]}
        />
      </FormContext>
    </form>
  )
}
