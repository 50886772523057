import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ClaimsFilterInput} from '../../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'

type ClaimsSearchObject = ClaimsFilterInput & {
  _eventStartDaterangeId?: DATERANGE_IDS
  _createdAtDaterangeId?: DATERANGE_IDS
  _updatedAtDaterangeId?: DATERANGE_IDS
}

const claimDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_30_DAYS
]

const eventStartDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.TOMORROW,
  DATERANGE_IDS.NEXT_7_DAYS,
  DATERANGE_IDS.NEXT_MONTH
]

export const DEFAULT_CLAIMS_FILTER_INPUT: ClaimsFilterInput = {
  hasText: ''
}

const mapHasTextToFilter = (
  filter: ClaimsFilterInput,
  hasText: string
): ClaimsFilterInput => ({
  ...filter,
  hasText
})

const mapLeadNameToFilter = (
  filter: ClaimsFilterInput,
  leadName: string
): ClaimsFilterInput => ({
  ...filter,
  leadName
})

const mapLeadEmailToFilter = (
  filter: ClaimsFilterInput,
  leadEmail: string
): ClaimsFilterInput => ({
  ...filter,
  leadEmail
})

const mapReasonToFilter = (
  filter: ClaimsFilterInput,
  reason: string
): ClaimsFilterInput => ({
  ...filter,
  reason
})

const mapEventNameToFilter = (
  filter: ClaimsFilterInput,
  eventName: string
): ClaimsFilterInput => ({
  ...filter,
  eventName
})

const mapClaimIdToFilter = (
  filter: ClaimsFilterInput,
  claimId: string
): ClaimsFilterInput => ({
  ...filter,
  claimId: claimId ? parseInt(claimId, 10) : undefined
})

const mapSaleIdToFilter = (
  filter: ClaimsFilterInput,
  saleId: string
): ClaimsFilterInput => ({
  ...filter,
  saleId: saleId ? parseInt(saleId, 10) : undefined
})

const mapCreatedByNameToFilter = (
  filter: ClaimsFilterInput,
  createdByName: string
): ClaimsFilterInput => ({
  ...filter,
  createdByName
})

const mapApprovedByNameToFilter = (
  filter: ClaimsFilterInput,
  approvedByName: string
): ClaimsFilterInput => ({
  ...filter,
  approvedByName
})

const mapRefundedByNameToFilter = (
  filter: ClaimsFilterInput,
  refundedByName: string
): ClaimsFilterInput => ({
  ...filter,
  refundedByName
})

const mapPassCodeToFilter = (
  filter: ClaimsFilterInput,
  passCode: string
): ClaimsFilterInput => ({
  ...filter,
  passCode
})

const stripCreatedAtDateFromSearchObject = (
  filter: ClaimsSearchObject
): ClaimsFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripUpdatedAtDateFromSearchObject = (
  filter: ClaimsSearchObject
): ClaimsFilterInput =>
  omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAtDaterangeId'])

const stripEventStartDateFromSearchObject = (
  filter: ClaimsSearchObject
): ClaimsFilterInput =>
  omit(filter, [
    'eventStartsAtFrom',
    'eventStartsAtTo',
    '_eventStartDaterangeId'
  ])

const stripHelperKeysFromSearchObject = (
  filter: ClaimsSearchObject
): ClaimsFilterInput =>
  omit(filter, [
    '_eventStartDaterangeId',
    '_createdAtDaterangeId',
    '_updatedAtDaterangeId'
  ])

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getHasTextFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.hasText || undefined
  const getLeadNameFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.leadName
      ? t('Customer name: {{name}}', {name: filter.leadName})
      : undefined
  const getLeadEmailFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.leadEmail
      ? t('Customer e-mail: {{email}}', {email: filter.leadEmail})
      : undefined
  const getReasonFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.reason ? t('Reason: {{reason}}', {reason: filter.reason}) : undefined
  const getEventNameFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.eventName
      ? t('Event name: {{eventName}}', {eventName: filter.eventName})
      : undefined
  const getClaimIdFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.claimId
      ? t('Claim ID: {{claimId}}', {claimId: filter.claimId})
      : undefined
  const getSaleIdFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.saleId
      ? t('Sale ID: {{saleId}}', {saleId: filter.saleId})
      : undefined
  const getCreatedByFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.createdByName
      ? t('Created by: {{name}}', {name: filter.createdByName})
      : undefined
  const getApprovedByFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.approvedByName
      ? t('Approved by: {{name}}', {name: filter.approvedByName})
      : undefined
  const getRefundedByFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.refundedByName
      ? t('Refunded by: {{name}}', {name: filter.refundedByName})
      : undefined
  const getPassCodeFromSearchObject = (filter: ClaimsFilterInput) =>
    filter.passCode
      ? t('Ticket number: {{passCode}}', {passCode: filter.passCode})
      : undefined

  return {
    getHasTextFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadEmailFromSearchObject,
    getReasonFromSearchObject,
    getEventNameFromSearchObject,
    getClaimIdFromSearchObject,
    getSaleIdFromSearchObject,
    getCreatedByFromSearchObject,
    getApprovedByFromSearchObject,
    getRefundedByFromSearchObject,
    getPassCodeFromSearchObject
  }
}

interface IClaimsSearchProps {
  onFilterChange: (filter: ClaimsFilterInput) => void
}

export const ClaimsSearch: React.FC<IClaimsSearchProps> = ({
  onFilterChange
}: IClaimsSearchProps) => {
  const {t} = useTranslation()

  const {
    daterangeOptions: createdAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ClaimsSearchObject>({
    usedDateranges: claimDateRanges,
    dateRangeInputPrefix: t('Created'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: updatedAtDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDateRangeToFilter,
    mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedUpdatedAtDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
  } = useDateRangeSearch<ClaimsSearchObject>({
    usedDateranges: claimDateRanges,
    dateRangeInputPrefix: t('Updated'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.updatedAtFrom || undefined,
      endDateISOString: o.updatedAtTo || undefined,
      id: o._updatedAtDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      updatedAtFrom: input.startDate,
      updatedAtTo: input.endDate,
      _updatedAtDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: eventStartDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomEventStartDateRangeToFilter,
    mapDaterangeToSearchObject: mapEventStartDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedEventStartDateDateRangeFromSearchObject,
    getIsDaterangeOptionActive: getIsEventStartDaterangeOptionActive
  } = useDateRangeSearch<ClaimsSearchObject>({
    usedDateranges: eventStartDateDateRanges,
    dateRangeInputPrefix: t('Event starts'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.eventStartsAtFrom || undefined,
      endDateISOString: o.eventStartsAtTo || undefined,
      id: o._eventStartDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      eventStartsAtFrom: input.startDate,
      eventStartsAtTo: input.endDate,
      _eventStartDaterangeId: input.id
    })
  })

  const {
    getHasTextFromSearchObject,
    getLeadNameFromSearchObject,
    getLeadEmailFromSearchObject,
    getReasonFromSearchObject,
    getEventNameFromSearchObject,
    getClaimIdFromSearchObject,
    getSaleIdFromSearchObject,
    getCreatedByFromSearchObject,
    getApprovedByFromSearchObject,
    getRefundedByFromSearchObject,
    getPassCodeFromSearchObject
  } = useGetFieldFromSearchObject()

  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<ClaimsFilterInput>(
      getHasTextFromSearchObject,
      getStringifiedCreatedAtDateDateRangeFromSearchObject,
      getStringifiedUpdatedAtDateDateRangeFromSearchObject,
      getStringifiedEventStartDateDateRangeFromSearchObject,
      getLeadNameFromSearchObject,
      getLeadEmailFromSearchObject,
      getReasonFromSearchObject,
      getEventNameFromSearchObject,
      getClaimIdFromSearchObject,
      getSaleIdFromSearchObject,
      getCreatedByFromSearchObject,
      getApprovedByFromSearchObject,
      getRefundedByFromSearchObject,
      getPassCodeFromSearchObject
    )
  return (
    <Search<ClaimsFilterInput, ClaimsSearchObject>
      storageKey="CLAIMS_LIST"
      placeholder={t('Search for claims')}
      defaultSearchObject={DEFAULT_CLAIMS_FILTER_INPUT}
      mapInputTextToSearchObject={mapHasTextToFilter}
      stripExtendedSearchObject={stripHelperKeysFromSearchObject}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      onChange={onFilterChange}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchDaterangeRow
            label={t('Created')}
            daterangeOptions={createdAtDateDaterangeOptions}
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDateRangeToFilter
            }
            stripDaterangeFromSearchObject={stripCreatedAtDateFromSearchObject}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart created at'
            )}
          />
          <AdvancedSearchDaterangeRow
            label={t('Updated')}
            daterangeOptions={updatedAtDateDaterangeOptions}
            mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomUpdatedAtDateRangeToFilter
            }
            stripDaterangeFromSearchObject={stripUpdatedAtDateFromSearchObject}
            getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range for cart updated at'
            )}
          />
          <AdvancedSearchTextRow
            label={t('Contains')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Ticket number')}
            placeholder={t('Enter ticket number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapPassCodeToFilter}
            value={advancedSearchObject.passCode || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Customer name')}
            placeholder={t('Enter customer name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapLeadNameToFilter}
            value={advancedSearchObject.leadName || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Customer e-mail')}
            placeholder={t('Enter customer e-mail')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapLeadEmailToFilter}
            value={advancedSearchObject.leadEmail || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Claim reason')}
            placeholder={t('Enter claim reason')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapReasonToFilter}
            value={advancedSearchObject.reason || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Event title')}
            placeholder={t('Enter event title')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapEventNameToFilter}
            value={advancedSearchObject.eventName || undefined}
          />
          <AdvancedSearchDaterangeRow
            label={t('Event starts')}
            daterangeOptions={eventStartDateDaterangeOptions}
            mapDaterangeToSearchObject={mapEventStartDaterangeToFilter}
            mapCustomDaterangeToSearchObject={
              mapCustomEventStartDateRangeToFilter
            }
            stripDaterangeFromSearchObject={stripEventStartDateFromSearchObject}
            getIsDaterangeOptionActive={getIsEventStartDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          <AdvancedSearchTextRow
            label={t('Claim ID')}
            placeholder={t('Enter claim number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapClaimIdToFilter}
            value={
              advancedSearchObject.claimId
                ? String(advancedSearchObject.claimId)
                : ''
            }
          />
          <AdvancedSearchTextRow
            label={t('Sale ID')}
            placeholder={t('Enter sale number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapSaleIdToFilter}
            value={
              advancedSearchObject.saleId
                ? String(advancedSearchObject.saleId)
                : ''
            }
          />
          <AdvancedSearchTextRow
            label={t('Created by')}
            placeholder={t('Enter name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapCreatedByNameToFilter}
            value={advancedSearchObject.createdByName || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Approved by')}
            placeholder={t('Enter name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapApprovedByNameToFilter}
            value={advancedSearchObject.approvedByName || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Refunded by')}
            placeholder={t('Enter name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapRefundedByNameToFilter}
            value={advancedSearchObject.refundedByName || undefined}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
