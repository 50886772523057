import {useCallback, useEffect, useState} from 'react'

import {ModifierKey} from '../editor/redux/keyboardKeys/reducer'

export const useModifierKeys = (keys: ModifierKey[]) => {
  const [isPressed, setPressed] = useState(false)
  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (keys.includes(e.key as ModifierKey)) {
        setPressed(false)
      }
    },
    [keys]
  )
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (keys.includes(e.key as ModifierKey)) {
        setPressed(true)
      }
    },
    [keys]
  )
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [onKeyDown, onKeyUp])
  return isPressed
}
