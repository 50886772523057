import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useRef} from 'react'
import {
  Controller,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {Theme} from '../../theme'
import {FormValidationError} from '../common'
import {FormFieldHelper} from '../visual/common'
import {FormFieldName} from './types'
import {useHasError} from './utils'

export interface IUncontrolledFormCheckboxProps<
  FormValues extends FieldValues = FieldValues
> extends Pick<FormControlProps<typeof Checkbox>, 'className' | 'disabled'> {
  errors: FieldErrors<FormValues>
  control: FormContextValues<FormValues>['control']
  validationOptions?: ValidationOptions
  checkboxDescription?: React.ReactNode
  helperText?: string
  name: FormFieldName<FormValues>
  label: FormControlLabelProps['label']
}

const useStyles = makeStyles<Theme>((theme) => ({
  formControlLabel: {alignItems: 'center'},
  label: {pt: '11px'},
  checkbox: {
    color: theme.palette.error.main
  },
  formFieldHelper: {
    marginLeft: 0
  }
}))
export const UncontrolledFormCheckbox = <
  FormValues extends FieldValues = FieldValues
>({
  errors,
  control,
  validationOptions,
  helperText,
  checkboxDescription,
  name,
  disabled,
  label,
  className
}: IUncontrolledFormCheckboxProps<FormValues>) => {
  const hasError = useHasError<FormValues>(errors, name)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const classes = useStyles()
  const isRequired = Boolean(validationOptions?.required)
  return (
    <FormControl
      className={className}
      disabled={disabled}
      error={hasError}
      size="small"
      required={isRequired}
    >
      <FormControlLabel
        className={classes.formControlLabel}
        label={
          <>
            <Typography
              variant="subtitle2"
              className={classes.label}
              color={hasError ? 'error' : 'textPrimary'}
            >
              {label}
            </Typography>
            {checkboxDescription}
            {hasError ? (
              <FormValidationError<FormValues>
                errors={errors}
                fieldName={name}
              />
            ) : (
              <FormFieldHelper
                required={isRequired}
                note={helperText}
                className={classes.formFieldHelper}
              />
            )}
          </>
        }
        control={
          <Controller
            as={({onChange, checked}) => (
              <Checkbox
                onChange={(e) => onChange(e.target.checked)}
                className={hasError ? classes.checkbox : undefined}
                color="primary"
                inputRef={inputRef}
                checked={checked ?? false}
              />
            )}
            onFocus={() => {
              inputRef.current?.focus()
            }}
            control={control}
            rules={validationOptions}
            name={name}
          />
        }
      />
    </FormControl>
  )
}
