import gql from 'graphql-tag'
import {useCallback} from 'react'
import {useMutation} from '@apollo/react-hooks'

import {
  InviteUsersMutation,
  InviteUsersMutationVariables
} from '../../../../../__generated__/schema'

import {GET_USERS} from '../graphql'

const INVITE_USERS = gql`
  mutation InviteUsers($emails: [String!]!, $roleIds: [Int!]!) {
    inviteUsers(emails: $emails, roleIds: $roleIds) {
      email
      user {
        id
      }
    }
  }
`

export const useInviteUsers = () => {
  const [inviteUsers] = useMutation<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >(INVITE_USERS, {
    refetchQueries: () => {
      // TODO: we could consider returning invited users data
      // from backend as a better solution
      return [{query: GET_USERS}]
    }
  })
  return useCallback(
    (emails: Array<string>, roleIds: Array<number>) =>
      inviteUsers({variables: {emails, roleIds}}),
    [inviteUsers]
  )
}
