import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import {Chip, ListItemIcon, ListItemText, MenuItem} from '@mui/material'
import React, {useCallback} from 'react'
import {Menu, useMenu} from './Menu'
import {Tooltip} from './Tooltip'

interface IOptionsChipWithDeleteProps<T> {
  tooltip?: string
  icon?: React.ReactElement
  options: {
    id: T
    label: string
  }[]
  selectedOption: T | undefined
  onChange: (newOption: T | undefined) => void
}

export const OptionsChipWithDelete = <T extends unknown>({
  icon,
  tooltip,
  options,
  selectedOption,
  onChange
}: IOptionsChipWithDeleteProps<T>) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleChipClick = useCallback((e) => openMenu(e), [openMenu])
  const handleDeleteChipClick = useCallback(
    () => onChange(undefined),
    [onChange]
  )
  const handleMenuItemClick = useCallback(
    (option: T) => () => {
      onChange(option)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  return (
    <>
      <Tooltip title={tooltip}>
        <Chip
          size="small"
          variant="outlined"
          icon={icon}
          deleteIcon={selectedOption ? <CancelIcon /> : <ArrowDropDownIcon />}
          label={
            selectedOption
              ? options.find((option) => option.id === selectedOption)?.label
              : undefined
          }
          onClick={handleChipClick}
          onDelete={selectedOption ? handleDeleteChipClick : handleChipClick}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        {options.map((option) => (
          <MenuItem
            key={String(option.id)}
            selected={option.id === selectedOption}
            onClick={handleMenuItemClick(option.id)}
            sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
          >
            {selectedOption === option.id && (
              <ListItemIcon
                color="primary"
                sx={{minWidth: 48, justifyContent: 'center'}}
              >
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
