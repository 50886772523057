import _ from 'lodash'

const getIntegerSelectItems = (from: number, to: number) =>
  _.fromPairs(_.range(from, to).map((v) => [v, v]))

export const daysItems = getIntegerSelectItems(0, 7)
export const hoursItems = getIntegerSelectItems(0, 24)
export const minutesItems = getIntegerSelectItems(0, 60)

export interface IDurationObject {
  days: string
  hours: string
  minutes: string
}

export const minutesToDurationObject = (lastFor: number): IDurationObject => {
  let base = lastFor

  const days = Math.floor(base / (60 * 24))
  base = base % (60 * 24)

  const hours = Math.floor(base / 60)
  const minutes = base % 60
  return {
    minutes: minutes.toString(),
    hours: hours.toString(),
    days: days.toString()
  }
}

export const durationObjectToMinutes = (durationObject: IDurationObject) => {
  const {days, hours, minutes} = durationObject
  return (
    parseInt(days, 10) * 60 * 24 +
    parseInt(hours, 10) * 60 +
    parseInt(minutes, 10)
  )
}
