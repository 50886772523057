import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, Chip, Paper, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetCustomerQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useUpdateCustomer} from '../graphql'
import {AssignCustomerGroupDrawer} from './AssignCustomerGroupDrawer'

interface ICustomerGroupsSectionProps {
  id: string
  title: string
  customer: GetCustomerQuery['customer']
}

export const CustomerGroupsSection: React.FC<ICustomerGroupsSectionProps> = ({
  id,
  title,
  customer
}: ICustomerGroupsSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const updateCustomer = useUpdateCustomer()
  const {setShowBackdrop, customErrorHandler} = useMutationAssistanceHooks()
  const {
    state: isDrawerOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const handleSaveButtonClick = useCallback(
    async (customerGroupIds: number[]) => {
      try {
        setShowBackdrop(true)
        await updateCustomer({
          id: customer.id,
          input: {
            customerGroupIds
          }
        })
        closeDrawer()
      } catch (error) {
        customErrorHandler(error, {
          title: t('Something went wrong'),
          contentText: t('Error while updating customer'),
          confirmButtonLabel: t('Got it'),
          onConfirm: () => window.location.reload()
        })
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      closeDrawer,
      customer.id,
      customErrorHandler,
      setShowBackdrop,
      t,
      updateCustomer
    ]
  )
  return (
    <>
      <Box id={id}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            pb: 2,
            pl: 2
          }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          {P([
            PermissionCode.UpdateCustomer,
            PermissionCode.ReadCustomerGroups
          ]) && (
            <Button
              variant="text"
              color="primary"
              startIcon={<EditIcon />}
              onClick={openDrawer}
            >
              {t('Edit')}
            </Button>
          )}
        </Box>
        <Paper variant="outlined">
          <Box
            sx={{
              p: 2,
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            {customer.customerGroups.length > 0 ? (
              customer.customerGroups.map(({id, name}) => (
                <Chip key={id} label={name} />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                {t('No customer groups set yet.')}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
      <AssignCustomerGroupDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        assignedCustomerGroupIds={customer.customerGroups.map(({id}) => id)}
        onSaveButtonClick={handleSaveButtonClick}
      />
    </>
  )
}
