import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DivisionState,
  GetLightweightClientsQueryVariables,
  GetLightweightDivisionsQuery
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {GET_DIVISIONS} from '../graphql'
import {useCreateWarehouse} from './graphql'
import {IWarehouseForm} from './types'
import {transformFormDataToCreateWarehouseInput} from './utils'
import {WarehouseForm} from './WarehouseForm'

const CREATE_WAREHOUSE_FORM_ID = 'createWarehouseForm'

interface ICreateWarehouseDrawerProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const CreateWarehouseDrawer: React.FC<ICreateWarehouseDrawerProps> = ({
  isOpen,
  onClose,
  refetch
}: ICreateWarehouseDrawerProps) => {
  const {t} = useTranslation()
  const [skip, setSkip] = useState(!isOpen)
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  const {data, loading, error} = useQuery<
    GetLightweightDivisionsQuery,
    GetLightweightClientsQueryVariables
  >(GET_DIVISIONS, {skip})
  const createWarehouse = useCreateWarehouse()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (formData: IWarehouseForm) => {
      try {
        setShowBackdrop(true)
        await createWarehouse(transformFormDataToCreateWarehouseInput(formData))
        addInfoNotification(t('Warehouses created'))
        onClose()
        refetch()
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating warehouse'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createWarehouse,
      defaultErrorHandler,
      onClose,
      refetch,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t('Could not load divisions')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create warehouse')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_WAREHOUSE_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        {data && (
          <WarehouseForm
            formId={CREATE_WAREHOUSE_FORM_ID}
            onSubmit={handleSubmit}
            divisions={
              data.divisions
                .filter(({state}) => state === DivisionState.Active)
                .sort((divisionA, divisionB) =>
                  divisionA.name.localeCompare(divisionB.name)
                ) || []
            }
          />
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
