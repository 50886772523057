import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import RedoIcon from '@mui/icons-material/Redo'
import SettingsIcon from '@mui/icons-material/Settings'
import UndoIcon from '@mui/icons-material/Undo'
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import {uniqueId} from 'lodash'
import omit from 'lodash/omit'
import React, {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../../hooks/state'
import {useUndoRedo} from '../../../../../../hooks/undoableState'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {DividerSeparatedInfoRow} from '../../../../../common/DividerSeparatedInfoRow'
import {Blank} from '../../../../../visual/Blank'
import {
  CardRowFormField,
  CardsRowBlockSettingFormField,
  CardWithId,
  ICardRowForm,
  ICardsRowBlockForm,
  ICardsRowBlockProps
} from '../editBlockDrawer/types'
import {AddCardRowDialog} from './AddCardRowDialog'
import {EditCardRowDialog} from './EditCardRowDialog'
import {SettingsDialog} from './SettingsDialog'

const StyledImg = styled('img')(() => ({
  width: 96,
  height: '100%',
  objectFit: 'cover'
}))

interface ICardRowProps extends CardWithId {
  onEditClick?: () => void
  onDeleteClick: () => void
}

const CardRow: React.FC<ICardRowProps> = ({
  id,
  title,
  description,
  buttonLabel,
  openInNewTab,
  buttonUrl,
  coverImageUrl,
  onEditClick,
  onDeleteClick
}: ICardRowProps) => {
  const {t} = useTranslation()
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id})
  const {state: isCardExpanded, toggle: toggleCard} = useBooleanState(false)
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  const handleArrowClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleCard()
    },
    [toggleCard]
  )
  return (
    <Paper
      ref={setNodeRef}
      style={style}
      variant="outlined"
      sx={{
        py: 1,
        px: 1
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: onEditClick
            ? '48px 1fr 48px 48px 48px'
            : '48px 1fr 48px 48px',
          alignItems: 'center'
        }}
      >
        <IconButton {...attributes} {...listeners}>
          <DragHandleIcon />
        </IconButton>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
          variant="caption"
        >
          {title || description
            ? [title, description].filter(Boolean).join(' • ')
            : t('Title and description are not set')}
        </Typography>
        {onEditClick && (
          <IconButton color="primary" onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        )}
        <IconButton color="primary" onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
        <IconButton sx={{pointerEvents: 'auto'}} onClick={handleArrowClick}>
          {isCardExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>
      <Collapse in={isCardExpanded}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            px: 1,
            pt: 1
          }}
        >
          <Divider />
          <DividerSeparatedInfoRow
            information={[
              {
                caption: t('Title'),
                value: title
              },
              {
                caption: t('Description'),
                value: description
              },
              {
                caption: t('Button'),
                value: buttonLabel ? (
                  <Typography
                    onClick={
                      buttonUrl ? () => window.open(buttonUrl) : undefined
                    }
                    component="div"
                    variant="subtitle2"
                    sx={(theme) =>
                      buttonUrl
                        ? {
                            textDecoration: 'underline',
                            textDecorationColor: theme.palette.primary.main,
                            cursor: 'pointer'
                          }
                        : {}
                    }
                  >
                    {buttonLabel}
                    {openInNewTab && (
                      <OpenInNewIcon
                        color="primary"
                        fontSize="small"
                        sx={{ml: 1}}
                      />
                    )}
                  </Typography>
                ) : undefined
              },
              {
                caption: t('Image'),
                value: <StyledImg src={coverImageUrl} />
              }
            ]}
          />
        </Box>
      </Collapse>
    </Paper>
  )
}

interface ICardsRowBlockContentProps {
  blockProps: ICardsRowBlockProps
  onBlockPropsChange: (props: ICardsRowBlockProps) => void
}

export const CardsRowBlockContent: React.FC<ICardsRowBlockContentProps> = ({
  blockProps,
  onBlockPropsChange
}: ICardsRowBlockContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: cards,
    setNewState: setCards,
    canUndo,
    canRedo,
    undo,
    redo
  } = useUndoRedo<CardWithId[]>(
    blockProps.cards.map((card) => ({
      id: uniqueId(),
      ...card
    }))
  )
  const [blockSettings, setBlockSettings] = useState<
    Omit<ICardsRowBlockProps, 'cards'>
  >(omit(blockProps, ['cards']))
  const [cardForEdit, setCardForEdit] = useState<CardWithId | null>(null)
  const {
    state: isCreateDialogOpen,
    setTrue: openCreateDialog,
    setFalse: closeCreateDialog
  } = useBooleanState(false)
  const {
    state: isSettingsDialogOpen,
    setTrue: openSettingsDialog,
    setFalse: closeSettingsDialog
  } = useBooleanState(false)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    }),
    useSensor(TouchSensor)
  )
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const {active, over} = event
      if (active.id !== over?.id) {
        setCards((prevCards) => {
          const oldIndex = prevCards.findIndex(({id}) => id === active.id)
          const newIndex = prevCards.findIndex(({id}) => id === over?.id)
          return arrayMove(prevCards, oldIndex, newIndex)
        })
      }
    },
    [setCards]
  )
  const handleAddCardRow = useCallback(
    (data: ICardRowForm) => {
      setCards((prevCards) => [
        ...prevCards,
        {
          id: uniqueId(),
          coverImageUrl: data[CardRowFormField.CoverImageUrl],
          title: data[CardRowFormField.Title] || undefined,
          description: data[CardRowFormField.Description] || undefined,
          buttonLabel: data[CardRowFormField.ButtonLabel] || undefined,
          buttonUrl: data[CardRowFormField.ButtonUrl] || undefined,
          openInNewTab: data[CardRowFormField.OpenInNewTab]
        }
      ])
      closeCreateDialog()
    },
    [closeCreateDialog, setCards]
  )
  const handleSaveCardRow = useCallback(
    (data: ICardRowForm) => {
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === cardForEdit?.id
            ? {
                ...card,
                coverImageUrl: data[CardRowFormField.CoverImageUrl],
                title: data[CardRowFormField.Title] || undefined,
                description: data[CardRowFormField.Description] || undefined,
                buttonLabel: data[CardRowFormField.ButtonLabel] || undefined,
                buttonUrl: data[CardRowFormField.ButtonUrl] || undefined,
                openInNewTab: data[CardRowFormField.OpenInNewTab]
              }
            : card
        )
      )
      setCardForEdit(null)
    },
    [cardForEdit?.id, setCards]
  )
  const handleDeleteCardRow = useCallback(
    (card: CardWithId) => () =>
      setCards((prevCards) => prevCards.filter(({id}) => id !== card.id)),
    [setCards]
  )
  const handleSettingsChange = useCallback(
    (settings: ICardsRowBlockForm) => {
      setBlockSettings({
        className:
          settings[CardsRowBlockSettingFormField.ClassName] || undefined,
        autoplay: settings[CardsRowBlockSettingFormField.Autoplay],
        spaceBetweenSlides: settings.spaceBetweenSlides
          ? parseInt(settings.spaceBetweenSlides, 10)
          : undefined,
        size: settings[CardsRowBlockSettingFormField.Size] || undefined
      })
      closeSettingsDialog()
    },
    [closeSettingsDialog]
  )
  useEffect(() => {
    onBlockPropsChange({
      ...blockSettings,
      cards: cards.map((card) => omit(card, 'id')) || []
    })
  }, [blockSettings, cards, onBlockPropsChange])
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'background.paper',
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <IconButton color="primary" disabled={!canUndo} onClick={undo}>
            <UndoIcon />
          </IconButton>
          <IconButton color="primary" disabled={!canRedo} onClick={redo}>
            <RedoIcon />
          </IconButton>
        </Box>
        <Box
          sx={{display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap'}}
        >
          <Button
            variant="text"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={openSettingsDialog}
          >
            {t('Settings')}
          </Button>
          {P([PermissionCode.ReadClientFiles]) && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openCreateDialog}
            >
              {t('Add')}
            </Button>
          )}
        </Box>
      </Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={cards} strategy={verticalListSortingStrategy}>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
            {cards.length > 0 ? (
              cards.map((card) => (
                <CardRow
                  key={card.id}
                  onEditClick={
                    P([PermissionCode.ReadClientFiles])
                      ? () => setCardForEdit(card)
                      : undefined
                  }
                  onDeleteClick={handleDeleteCardRow(card)}
                  {...card}
                />
              ))
            ) : (
              <Blank title={t('No card created yet.')} />
            )}
          </Box>
        </SortableContext>
      </DndContext>
      <AddCardRowDialog
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialog}
        onAdd={handleAddCardRow}
      />
      <EditCardRowDialog
        card={cardForEdit}
        onClose={() => setCardForEdit(null)}
        onSave={handleSaveCardRow}
      />
      <SettingsDialog
        defaultValues={{
          [CardsRowBlockSettingFormField.ClassName]: blockSettings.className,
          [CardsRowBlockSettingFormField.Autoplay]: Boolean(
            blockSettings.autoplay
          ),
          [CardsRowBlockSettingFormField.SpaceBetweenSlides]:
            typeof blockSettings.spaceBetweenSlides === 'number'
              ? String(blockSettings.spaceBetweenSlides)
              : undefined,
          [CardsRowBlockSettingFormField.Size]: blockSettings.size
        }}
        isOpen={isSettingsDialogOpen}
        onClose={closeSettingsDialog}
        onSubmit={handleSettingsChange}
      />
    </Box>
  )
}
