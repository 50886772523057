import {useLazyQuery} from '@apollo/react-hooks'
import {Autocomplete, Button, TextField} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AuditoriumLayoutSupportUserPropertiesFragment,
  ClientPropertiesFragment,
  ClientsQuery,
  VenueSupportUserPropertiesFragment
} from '../../../../__generated__/schema'
import {InputBlock, InputRow, RenderOnData} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {ChildrenOnNoEffectiveClientSelected} from '../ChildrenOnNoEffectiveClientSelected'
import {useGetClients} from '../clients/graphql'
import {PrimaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {DuplicateDialog} from './DuplicateDialog'
import {GET_VENUES_SUPPORT_USER} from './graphql'

enum DuplicateLayoutField {
  SOURCE_CLIENT = 'source_client',
  SOURCE_VENUE = 'source_venue',
  SOURCE_AUDITORIUM = 'source_auditorium',
  SOURCE_AUDITORIUM_LAYOUT = 'source_auditorium_layout',
  DESTINATION_CLIENT = 'destination_client',
  DESTINATION_VENUE = 'destination_venue',
  DESTINATION_AUDITORIUM = 'destination_auditorium'
}

const initialSource = {
  venue: null,
  auditoriumLayout: null,
  auditorium: null
}
const initialDestination = {
  venue: null,
  auditorium: null
}

export const DuplicateAuditoriumLayout: React.FC = () => {
  const {t} = useTranslation()
  const {data, loading, error} = useGetClients()

  const [source, setSource] =
    useState<{
      venue: null | VenueSupportUserPropertiesFragment
      auditorium:
        | null
        | VenueSupportUserPropertiesFragment['auditoriums'][number]
      auditoriumLayout: null | AuditoriumLayoutSupportUserPropertiesFragment
    }>(initialSource)

  const [destination, setDestination] =
    useState<{
      venue: null | VenueSupportUserPropertiesFragment
      auditorium:
        | null
        | VenueSupportUserPropertiesFragment['auditoriums'][number]
    }>(initialDestination)

  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] =
    useState<boolean>(false)

  const [getSourceVenues, sourceVenuesResult] = useLazyQuery(
    GET_VENUES_SUPPORT_USER
  )
  const [getDestinationVenues, destinationVenuesResult] = useLazyQuery(
    GET_VENUES_SUPPORT_USER
  )

  const handleSourceClientChange = useCallback(
    (_event, client: null | ClientPropertiesFragment) => {
      if (client) {
        getSourceVenues({variables: {clientId: client.id}})
      }
      setSource(initialSource)
    },
    [getSourceVenues, setSource]
  )
  const handleDestinationClientChange = useCallback(
    (_event, client: null | ClientPropertiesFragment) => {
      if (client) {
        getDestinationVenues({variables: {clientId: client.id}})
      }
      setDestination(initialDestination)
    },
    [getDestinationVenues, setDestination]
  )

  const openDuplicateDialog = useCallback(() => {
    setIsDuplicateDialogOpen(true)
  }, [])

  const closeDuplicateDialog = useCallback(() => {
    setIsDuplicateDialogOpen(false)
  }, [])

  const resetForm = useCallback(() => {
    setSource(initialSource)
    setDestination(initialDestination)
  }, [])

  return (
    <ChildrenOnNoEffectiveClientSelected>
      <PageWithHeaderAndFooterTemplate
        header={<PrimaryHeader title={t('Duplicate auditorium layout')} />}
        footer={
          <Footer>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={openDuplicateDialog}
              disabled={
                !(
                  source.auditoriumLayout != null &&
                  destination.auditorium != null
                )
              }
            >
              {t('Duplicate')}
            </Button>
          </Footer>
        }
      >
        <RenderOnData
          {...{loading, error, data}}
          errorMessage={t<string>('Could not load clients')}
        >
          {(data: ClientsQuery) => (
            <CenteredLayout>
              <CenteredLayoutListWrapper>
                <InputBlock header={t('Source')}>
                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.SOURCE_CLIENT}
                        options={data.clients}
                        noOptionsText={t('Client not found')}
                        disableClearable
                        getOptionLabel={(option) =>
                          `${option.name} ( ${option.state}, ${option.legalAddress.town} )`
                        }
                        onChange={handleSourceClientChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Client')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />

                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.SOURCE_VENUE}
                        disabled={!sourceVenuesResult.data?.venuesSupportUser}
                        noOptionsText={t('Venue not found')}
                        onChange={(_, venue) =>
                          setSource({
                            ...source,
                            venue,
                            auditorium: null,
                            auditoriumLayout: null
                          })
                        }
                        options={
                          sourceVenuesResult.data?.venuesSupportUser || []
                        }
                        getOptionLabel={(option) =>
                          !option
                            ? ''
                            : `${option.name} (${option.address.town})`
                        }
                        value={source.venue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Venue')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />

                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.SOURCE_AUDITORIUM}
                        noOptionsText={t('Auditorium not found')}
                        disabled={source.venue == null}
                        onChange={(_, auditorium) =>
                          setSource({
                            ...source,
                            auditorium,
                            auditoriumLayout: null
                          })
                        }
                        options={source.venue?.auditoriums || []}
                        getOptionLabel={(option: {name: string}) =>
                          !option ? '' : option.name
                        }
                        value={source.auditorium}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Auditorium')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />
                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.SOURCE_AUDITORIUM_LAYOUT}
                        noOptionsText={t('Auditorium layout not found')}
                        disabled={source.auditorium == null}
                        options={source.auditorium?.auditoriumLayouts || []}
                        onChange={(_, auditoriumLayout) =>
                          setSource({
                            ...source,
                            auditoriumLayout
                          })
                        }
                        getOptionLabel={(option: {name: string}) =>
                          !option ? '' : option.name
                        }
                        value={source.auditoriumLayout}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('AuditoriumLayout')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />
                </InputBlock>

                <InputBlock header={t('Destination')}>
                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.DESTINATION_CLIENT}
                        options={data.clients}
                        noOptionsText={t('Client not found')}
                        disableClearable
                        getOptionLabel={(option) =>
                          `${option.name} ( ${option.state}, ${option.legalAddress.town} )`
                        }
                        onChange={handleDestinationClientChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Client')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />
                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.DESTINATION_VENUE}
                        disabled={
                          !destinationVenuesResult.data?.venuesSupportUser
                        }
                        noOptionsText={t('Venue not found')}
                        onChange={(_, venue) =>
                          setDestination({
                            venue,
                            auditorium: null
                          })
                        }
                        options={
                          destinationVenuesResult.data?.venuesSupportUser || []
                        }
                        getOptionLabel={(option) =>
                          !option
                            ? ''
                            : `${option.name} (${option.address.town})`
                        }
                        value={destination.venue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Venue')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />
                  <InputRow
                    nodes={[
                      <Autocomplete
                        key={DuplicateLayoutField.DESTINATION_AUDITORIUM}
                        noOptionsText={t('Auditorium not found')}
                        disabled={destination.venue == null}
                        onChange={(_, auditorium) =>
                          setDestination({
                            ...destination,
                            auditorium
                          })
                        }
                        options={destination.venue?.auditoriums || []}
                        getOptionLabel={(option: {name: string}) =>
                          !option ? '' : option.name
                        }
                        value={destination.auditorium}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('Auditorium')}
                            variant={'outlined'}
                          />
                        )}
                      />
                    ]}
                  />
                </InputBlock>

                {isDuplicateDialogOpen &&
                  source.auditoriumLayout &&
                  destination.auditorium && (
                    <DuplicateDialog
                      isOpen={isDuplicateDialogOpen}
                      source={source.auditoriumLayout}
                      destination={destination.auditorium}
                      closeDialog={closeDuplicateDialog}
                      resetForm={resetForm}
                    />
                  )}
              </CenteredLayoutListWrapper>
            </CenteredLayout>
          )}
        </RenderOnData>
      </PageWithHeaderAndFooterTemplate>
    </ChildrenOnNoEffectiveClientSelected>
  )
}
