import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useForceRerender} from '../../../../../../hooks/forceRerender'

import {Theme} from '../../../../../../theme'

import {
  Accordion,
  FormInput,
  StackableCardDivider,
  StackableCardWrapper
} from '../../../../../visual'

import {FormField, ITEM_HEIGHT, MainLabel, SecondaryLabel} from './common'

const useStyles = makeStyles<Theme>((theme) => ({
  accordion: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  item: {
    height: ITEM_HEIGHT
  }
}))

const Icon = (
  <Typography color="textPrimary">
    <ExpandMoreIcon />
  </Typography>
)

const renderInfo = (label: string) => <SecondaryLabel label={label} />

const renderHeader = (label: string) => <MainLabel label={label} />

export const NotesFields = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {errors, register, getValues} = useFormContext()

  const {forceRerender} = useForceRerender()
  const values = getValues()

  const errorMessage = t('This field is required!')

  return (
    <StackableCardWrapper>
      <Accordion
        disableDivider
        {...{renderInfo, renderHeader}}
        summaryClassName={cn(classes.accordion, classes.item)}
        description={t('Organizer printed on tickets and e-mails')}
        label={t('Organizer')}
        validationError={errors[FormField.ORGANIZER] && errorMessage}
        value={values[FormField.ORGANIZER]}
        onCollapse={forceRerender}
        CustomIcon={Icon}
      >
        <FormInput
          label={t('Organizer')}
          placeholder={t('Organizer')}
          type="text"
          showRequiredLabel
          name={FormField.ORGANIZER}
          inputRef={register({required: true})}
          error={!!errors[FormField.ORGANIZER]}
          maxLength={160}
        />
      </Accordion>
      <StackableCardDivider />
      <Accordion
        disableDivider
        {...{renderInfo, renderHeader}}
        summaryClassName={cn(classes.accordion, classes.item)}
        description={t('Note printed on tickets and e-mails')}
        label={t('Ticket note')}
        value={values[FormField.TICKET_NOTE]}
        onCollapse={forceRerender}
        CustomIcon={Icon}
      >
        <FormInput
          label={t('Ticket note')}
          placeholder={t('Ticket note')}
          type="text"
          name={FormField.TICKET_NOTE}
          inputRef={register({required: false})}
          maxLength={160}
        />
      </Accordion>
    </StackableCardWrapper>
  )
}
