import {makeStyles} from '@mui/styles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch} from 'react-router'
import {PermissionCode} from '../../../../__generated__/schema'

import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {EVENTS_PARAMS as EP} from '../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {useGetDivisions} from '../divisions/graphql'
import {PrimaryHeader} from '../Header'
import {EventsCalendar} from './calendar/EventsCalendar'
import {SIDE_MENU_WIDTH} from './common'
import {AddEventPropertiesProvider} from './context/addEventProperties'
import {DayHeaderContentStateProvider} from './context/dayHeaderContentState'
import {EventCalendarDatePropertiesProvider} from './context/eventCalendarDateProperties'
import {EventCalendarSettingsProvider} from './context/eventCalendarSettingsProvider'
import {EventCalendarViewProvider} from './context/eventCalendarView'
import {ResourceLabelContentStateProvider} from './context/resourceLabelContentState'
import {EditEventSection} from './edit'
import {CalendarShowsMenu} from './showsList'

const useStyles = makeStyles<Theme, {hasDivisions: boolean}>(() => ({
  grid: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: ({hasDivisions}) =>
      hasDivisions ? `1fr ${SIDE_MENU_WIDTH}px` : '1fr'
  },
  gridItem: {
    height: '100%',
    overflowY: 'auto'
  }
}))

const EventsContent: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()

  const [hasDivisions, setHasDivisions] = useState<boolean>(true)
  const classes = useStyles({hasDivisions})

  // we want to have divisions cached for smoother UI when adding new event
  useGetDivisions()

  return (
    <PageWithHeaderTemplate header={<PrimaryHeader title={t('Events')} />}>
      <ChildrenOnEffectiveClientSelected
        text={t('Please select some client, to see/manage events.')}
      >
        <div className={classes.grid}>
          <div className={classes.gridItem}>
            <EventsCalendar setHasDivisions={setHasDivisions} />
          </div>
          <div className={classes.gridItem}>
            <CalendarShowsMenu />
          </div>
        </div>
        <Switch>
          {P([PermissionCode.ManageEvents]) && (
            <Route
              path={
                // Also include "home" to have smooth Drawer transition
                routeTo.admin.events.home()
              }
              component={EditEventSection}
              // We need 'exact' here to access `eventId` in `match`.
              // Otherwise, react-router would match only `/events` and we would not get it
              exact
            />
          )}
          {P([PermissionCode.ReadEvent]) && (
            <Route
              path={routeTo.admin.events.edit(toPlaceholderParam(EP.EVENT_ID))}
              component={EditEventSection}
            />
          )}
        </Switch>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}

export const Events: React.FC = () => {
  return (
    <AddEventPropertiesProvider>
      <EventCalendarDatePropertiesProvider>
        <EventCalendarSettingsProvider>
          <EventCalendarViewProvider>
            <DayHeaderContentStateProvider>
              <ResourceLabelContentStateProvider>
                <EventsContent />
              </ResourceLabelContentStateProvider>
            </DayHeaderContentStateProvider>
          </EventCalendarViewProvider>
        </EventCalendarSettingsProvider>
      </EventCalendarDatePropertiesProvider>
    </AddEventPropertiesProvider>
  )
}
