import {useTranslation} from 'react-i18next'

import {IntentState} from '../__generated__/schema'

export const useTranslateIntentState = () => {
  const {t} = useTranslation()
  const translatedIntentStates: Record<IntentState, string> = {
    [IntentState.Pending]: t<string>('IntentState->Pending'),
    [IntentState.Failed]: t<string>('IntentState->Failed'),
    [IntentState.Cancelled]: t<string>('IntentState->Cancelled'),
    [IntentState.Expired]: t<string>('IntentState->Expired'),
    [IntentState.Succeeded]: t<string>('IntentState->Succeeded')
  }
  return (type: IntentState) => translatedIntentStates[type]
}
