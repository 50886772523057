import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DiscountPropertiesFragment,
  GetLightweightDivisionsQuery,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'

import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../common'
import {CancelButton, SaveButton} from '../../../common/Buttons'
import {useBackdropState} from '../../../context/backdrop'
import {useGetLightweightDivisions} from '../graphql'
import {useShowFormStyles} from '../shows/ShowForm'
import {DiscountForm} from './DiscountForm'
import {useCopyDiscount} from './graphql'
import {
  IDiscountForm,
  transformDiscountFormIntoInput,
  transformDiscountIntoForm
} from './types'

const COPY_DISCOUNT_DRAWER_FORM_ID = 'copyDiscountDrawerFormId'

interface ICopyDiscountDrawerProps {
  originalDiscount?: DiscountPropertiesFragment

  closeDrawer(): void
}

export const CopyDiscountDrawer: React.FC<ICopyDiscountDrawerProps> = ({
  closeDrawer,
  originalDiscount
}: ICopyDiscountDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const showFormClasses = useShowFormStyles()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {setShowBackdrop} = useBackdropState()
  const copyDiscount = useCopyDiscount()
  const history = useHistory()
  const handleSubmit = useCallback(
    async (data: IDiscountForm) => {
      if (!originalDiscount) {
        return
      }
      setShowBackdrop(true)
      try {
        const result = await copyDiscount({
          id: originalDiscount.id,
          input: transformDiscountFormIntoInput(data)
        })
        closeDrawer()
        history.push(
          routeTo.admin.discounts.detail(result.data!.copyDiscount.id)
        )
      } catch (e) {
        defaultErrorHandler(e, t('Error while copying a discount'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      closeDrawer,
      copyDiscount,
      defaultErrorHandler,
      history,
      originalDiscount,
      setShowBackdrop,
      t
    ]
  )
  const {divisions, error, loading} = useGetLightweightDivisions()
  return (
    <RenderOnData
      data={divisions}
      error={error}
      loading={loading}
      errorMessage={t<string>('Loading divisions failed!')}
    >
      {(divisions: GetLightweightDivisionsQuery['divisions']) => (
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              title={t('Copy discount')}
              onLeftActionClick={closeDrawer}
            />
          }
          footer={
            <>
              <CancelButton onClick={closeDrawer} />
              {P([PermissionCode.CopyDiscount]) && (
                <SaveButton type="submit" form={COPY_DISCOUNT_DRAWER_FORM_ID}>
                  {t('Create')}
                </SaveButton>
              )}
            </>
          }
        >
          <DiscountForm
            formId={COPY_DISCOUNT_DRAWER_FORM_ID}
            onSubmit={handleSubmit}
            className={showFormClasses.form}
            divisions={divisions}
            defaultValues={
              originalDiscount
                ? transformDiscountIntoForm(originalDiscount, divisions)
                : {}
            }
          />
        </DrawerTemplate>
      )}
    </RenderOnData>
  )
}
