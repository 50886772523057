import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CompleteInventoryCheckMutation,
  CompleteInventoryCheckMutationVariables,
  CreateInventoryCheckInput,
  CreateInventoryCheckMutation,
  CreateInventoryCheckMutationVariables,
  DeleteInventoryCheckMutation,
  DeleteInventoryCheckMutationVariables,
  InventoryCheckQuery,
  InventoryCheckQueryVariables,
  InventoryChecksQuery,
  InventoryChecksQueryVariables,
  UpdateInventoryCheckMutation,
  UpdateInventoryCheckMutationVariables,
  UpdateInventoryCheckProductInput,
  UpdateInventoryCheckProductMutation,
  UpdateInventoryCheckProductMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const INVENTORY_CHECKS = gql`
  ${USER_FIELDS}
  ${PAGINATION_FRAGMENT}
  query InventoryChecks(
    $filter: InventoryChecksFilterInput
    $paginationInput: PaginationInput
  ) {
    inventoryChecks(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        type
        state
        inventoryCheckedTill
        inventoryVarianceVatExcluded
        inventoryVarianceVatIncluded
        warehouse {
          id
          name
        }
        division {
          id
          name
        }
        note
        completedAt
        completedBy {
          ...UserFields
        }
        createdAt
        createdBy {
          ...UserFields
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useInventoryChecks = (variables?: InventoryChecksQueryVariables) =>
  useQuery<InventoryChecksQuery, InventoryChecksQueryVariables>(
    INVENTORY_CHECKS,
    {variables, fetchPolicy: 'network-only'}
  )

const CREATE_INVENTORY_CHECK = gql`
  mutation CreateInventoryCheck($input: CreateInventoryCheckInput!) {
    createInventoryCheck(input: $input) {
      id
    }
  }
`

export const useCreateInventoryCheck = () => {
  const [createInventoryCheck] = useMutation<
    CreateInventoryCheckMutation,
    CreateInventoryCheckMutationVariables
  >(CREATE_INVENTORY_CHECK)
  return useCallback(
    (input: CreateInventoryCheckInput) =>
      createInventoryCheck({variables: {input}}),
    [createInventoryCheck]
  )
}

const BASE_INVENTORY_CHECK_PRODUCT_FIELDS = gql`
  ${USER_FIELDS}
  fragment BaseInventoryCheckProductFields on InventoryCheckProduct {
    id
    averagePriceVatExcluded
    averagePriceVatIncluded
    expectedStock
    realStock
    stockVariance
    note
    updatedAt
    updatedBy {
      ...UserFields
    }
    warehouseProduct {
      id
      product {
        id
        name
        internalCode
        unit
      }
    }
  }
`

const BASE_INVENTORY_CHECK_FIELDS = gql`
  ${USER_FIELDS}
  ${BASE_INVENTORY_CHECK_PRODUCT_FIELDS}
  fragment BaseInventoryCheckFields on InventoryCheck {
    id
    type
    state
    note
    warehouse {
      id
      name
    }
    division {
      id
      name
    }
    createdAt
    createdBy {
      ...UserFields
    }
    updatedAt
    updatedBy {
      ...UserFields
    }
    completedAt
    completedBy {
      ...UserFields
    }
    inventoryCheckedTill
    inventoryVarianceVatIncluded
    inventoryVarianceVatExcluded
    incomingWarehouseDocument {
      id
    }
    outgoingWarehouseDocument {
      id
    }
    inventoryCheckProducts {
      ...BaseInventoryCheckProductFields
    }
  }
`

const INVENTORY_CHECK = gql`
  ${BASE_INVENTORY_CHECK_FIELDS}
  query InventoryCheck($id: PositiveInt!) {
    inventoryCheck(id: $id) {
      ...BaseInventoryCheckFields
    }
  }
`

export const useInventoryCheck = (id: number, skip?: boolean) =>
  useQuery<InventoryCheckQuery, InventoryCheckQueryVariables>(INVENTORY_CHECK, {
    variables: {id},
    fetchPolicy: 'network-only',
    skip
  })

const UPDATE_INVENTORY_CHECK_PRODUCT = gql`
  ${USER_FIELDS}
  ${BASE_INVENTORY_CHECK_PRODUCT_FIELDS}
  mutation UpdateInventoryCheckProduct(
    $input: UpdateInventoryCheckProductInput!
  ) {
    updateInventoryCheckProduct(input: $input) {
      id
      updatedAt
      updatedBy {
        ...UserFields
      }
      inventoryCheckProducts {
        ...BaseInventoryCheckProductFields
      }
    }
  }
`

export const useUpdateInventoryCheckProduct = () => {
  const [updateInventoryCheckProduct] = useMutation<
    UpdateInventoryCheckProductMutation,
    UpdateInventoryCheckProductMutationVariables
  >(UPDATE_INVENTORY_CHECK_PRODUCT)
  return useCallback(
    (input: UpdateInventoryCheckProductInput) =>
      updateInventoryCheckProduct({variables: {input}}),
    [updateInventoryCheckProduct]
  )
}

const UPDATE_INVENTORY_CHECK = gql`
  ${USER_FIELDS}
  mutation UpdateInventoryCheck(
    $id: PositiveInt!
    $input: UpdateInventoryCheckInput!
  ) {
    updateInventoryCheck(id: $id, input: $input) {
      id
      type
      note
      updatedAt
      updatedBy {
        ...UserFields
      }
    }
  }
`

export const useUpdateInventoryCheck = () => {
  const [updateInventoryCheck] = useMutation<
    UpdateInventoryCheckMutation,
    UpdateInventoryCheckMutationVariables
  >(UPDATE_INVENTORY_CHECK)
  return useCallback(
    (variables: UpdateInventoryCheckMutationVariables) =>
      updateInventoryCheck({variables}),
    [updateInventoryCheck]
  )
}

const DELETE_INVENTORY_CHECK = gql`
  mutation DeleteInventoryCheck($id: PositiveInt!) {
    deleteInventoryCheck(id: $id)
  }
`

export const useDeleteInventoryCheck = () => {
  const [deleteInventoryCheck] = useMutation<
    DeleteInventoryCheckMutation,
    DeleteInventoryCheckMutationVariables
  >(DELETE_INVENTORY_CHECK)
  return useCallback(
    (id: number) => deleteInventoryCheck({variables: {id}}),
    [deleteInventoryCheck]
  )
}

const COMPLETE_INVENTORY_CHECK = gql`
  ${BASE_INVENTORY_CHECK_FIELDS}
  mutation CompleteInventoryCheck($id: PositiveInt!) {
    completeInventoryCheck(id: $id) {
      ...BaseInventoryCheckFields
    }
  }
`

export const useCompleteInventoryCheck = () => {
  const [completeInventoryCheck] = useMutation<
    CompleteInventoryCheckMutation,
    CompleteInventoryCheckMutationVariables
  >(COMPLETE_INVENTORY_CHECK)
  return useCallback(
    (id: number) => completeInventoryCheck({variables: {id}}),
    [completeInventoryCheck]
  )
}
