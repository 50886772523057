import {IShape} from '../../../types'
import {CanvasObjectType} from '../../types'

export enum ShapeActionType {
  ADD_SHAPE = 'add shape'
}

export interface IAddShapeAction {
  type: typeof ShapeActionType.ADD_SHAPE
  payload: {
    type: typeof CanvasObjectType.Shape
    config: IShape
  }
}

export type ShapeAction = IAddShapeAction
