import {Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    padding: theme.spacing(3)
  },
  label: {
    paddingBottom: theme.spacing(2)
  }
}))

interface IGeneralBlockProps {
  label: string
  blockId?: string
  children: React.ReactNode
}

export const GeneralBlock: React.FC<IGeneralBlockProps> = ({
  label,
  children,
  blockId
}: IGeneralBlockProps) => {
  const classes = useStyles()
  return (
    <Paper id={blockId} variant="outlined" className={classes.paper}>
      <Typography variant="h6" className={classes.label}>
        {label}
      </Typography>
      <div>{children}</div>
    </Paper>
  )
}
