import {
  InventoryCheckStockLevel,
  InventoryCheckType
} from '../../../../__generated__/schema'

export enum InventoryCheckFormField {
  Type = 'type',
  StockLevels = 'stockLevels',
  WarehouseId = 'warehouseId',
  Note = 'note',
  ProductIds = 'productIds',
  ProductGroupIds = 'productGroupIds',
  ProductTypeIds = 'productTypeIds'
}

export interface IInventoryCheckForm {
  [InventoryCheckFormField.Type]: InventoryCheckType
  [InventoryCheckFormField.StockLevels]: InventoryCheckStockLevel[]
  [InventoryCheckFormField.WarehouseId]: number
  [InventoryCheckFormField.Note]?: string
  [InventoryCheckFormField.ProductIds]?: number[]
  [InventoryCheckFormField.ProductGroupIds]?: number[]
  [InventoryCheckFormField.ProductTypeIds]?: number[]
}

export enum InventoryCheckFormLocation {
  Create = 'create',
  Update = 'update'
}
