import omit from 'lodash/omit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {WarehouseDocumentsFilterInput} from '../../../../../__generated__/schema'
import {
  Search,
  useCombineStringifySearchObjectFunctions
} from '../../../../common'

const mapHasTextToFilter = (
  filter: WarehouseDocumentsFilterInput,
  hasText: string
): WarehouseDocumentsFilterInput =>
  hasText ? {...filter, hasText} : omit(filter, 'hasText')

const useGetFieldFromSearchObject = () => {
  const getHasTextFromSearchObject = (filter: WarehouseDocumentsFilterInput) =>
    filter.hasText || undefined
  return {
    getHasTextFromSearchObject
  }
}

interface ISearchProps {
  onFilterChange: (filter: WarehouseDocumentsFilterInput) => void
  defaultSearchObject: WarehouseDocumentsFilterInput
}

export const WarehouseProductSearch: React.FC<ISearchProps> = ({
  onFilterChange,
  defaultSearchObject
}: ISearchProps) => {
  const {t} = useTranslation()
  const {getHasTextFromSearchObject} = useGetFieldFromSearchObject()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFromSearchObject
  )
  return (
    <Search<WarehouseDocumentsFilterInput>
      storageKey="WAREHOUSE_PRODUCT_SEARCH"
      placeholder={t('Search for products')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      defaultSearchObject={defaultSearchObject}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
    />
  )
}
