import {Grid} from '@mui/material'
import React from 'react'
import {ShowCrewByRole} from '../../../__generated__/schema'
import {useTranslateShowCrewRole} from '../../../hooks/translateCrewRole'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple
} from '../DrawerUtils'

type EventPick = {
  show: {
    crew: Pick<ShowCrewByRole, 'crewRoleCode' | 'persons'>[]
  }
}

interface ICrewDrawerSectionProps<T extends EventPick> {
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
  event: T
}

export const CrewDrawerSection = <T extends EventPick>({
  drawerSectionProps,
  event
}: ICrewDrawerSectionProps<T>) => {
  const translateShowCrewRole = useTranslateShowCrewRole()
  return (
    <DrawerSection {...drawerSectionProps}>
      <SectionGrid>
        {event.show.crew.map(({crewRoleCode, persons}) => (
          <SectionGridCouple
            key={crewRoleCode}
            label={translateShowCrewRole(crewRoleCode)}
          >
            <Grid container direction="column">
              {persons.map((person, index) => (
                <Grid item key={index}>
                  {person.name}
                  {person.description && ` (${person.description})`}
                </Grid>
              ))}
            </Grid>
          </SectionGridCouple>
        ))}
      </SectionGrid>
    </DrawerSection>
  )
}
