// Note: Keep same values and keys in the following two enums.
// It allows // to easily check if we should react to some "event" by checking
// "event.key in CommonKey" or "event.key in ModifierKey" without additional facade.
export enum ModifierKey {
  Control = 'Control',
  Meta = 'Meta', // for Mac
  Shift = 'Shift'
}

export enum CommonKey {
  ' ' = ' ', // Space
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Backspace = 'Backspace',
  Delete = 'Delete',
  Escape = 'Escape',
  y = 'y',
  Y = 'Y',
  z = 'z',
  Z = 'Z'
}

export type ModifierKeysState = {
  [key in ModifierKey]: boolean
}

const initialState: ModifierKeysState = {
  [ModifierKey.Control]: false,
  [ModifierKey.Meta]: false,
  [ModifierKey.Shift]: false
}

export enum ModifierKeysActions {
  ADD_MODIFIER_KEY = 'add modifier key',
  REMOVE_MODIFIER_KEY = 'remove modifier key'
}

interface IAddModifierKeyAction {
  type: typeof ModifierKeysActions.ADD_MODIFIER_KEY
  payload: ModifierKey
}

interface IRemoveModifierKeyAction {
  type: typeof ModifierKeysActions.REMOVE_MODIFIER_KEY
  payload: ModifierKey
}

type ModifierKeyAction = IAddModifierKeyAction | IRemoveModifierKeyAction

export const modifierKeysReducer = (
  state = initialState,
  action: ModifierKeyAction
): ModifierKeysState => {
  switch (action.type) {
    case ModifierKeysActions.ADD_MODIFIER_KEY:
      return {...state, [action.payload]: true}
    case ModifierKeysActions.REMOVE_MODIFIER_KEY:
      return {...state, [action.payload]: false}
    default:
      return state
  }
}
