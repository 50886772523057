// enums can/must be re-exported, otherwise TS complains
export {CanvasObjectType} from '@attendio/shared-components'

export enum Align {
  ALIGN_LEFT = 'align left',
  ALIGN_CENTER = 'align center',
  ALIGN_RIGHT = 'align right',
  ALIGN_TOP = 'align top',
  ALIGN_MIDDLE = 'align middle',
  ALIGN_BOTTOM = 'align bottom'
}

export enum Distribute {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export enum Flip {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}
