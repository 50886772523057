import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'

import {
  AuditoriumQuery,
  AuditoriumState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useTranslateAuditoriumState} from '../../../../../hooks/auditoriumState'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useVenuesPathnameParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../../common'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {
  IActionButton,
  IOpenModalButtonProps,
  StateChangeModal
} from '../../../../common/StateChangeModal'
import {auditoriumStateColors} from '../../../../constants'
import {useNotifications} from '../../../../context/notifications'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {AuditoriumLayouts} from '../auditoriumLayouts/List'
import {EntityDetailCardLayout} from '../common'
import {VenuesBreadcrumbs} from '../VenuesBreadcrumbs'
import {EditAuditoriumDrawer} from './EditAuditoriumDrawer'
import {useDeleteAuditorium, useGetAuditorium} from './graphql'

interface IDeleteAuditoriumButtonProps extends IActionButton {
  venueId: number
  disabled: boolean
}

const DeleteAuditoriumButton: React.FC<IDeleteAuditoriumButtonProps> = ({
  id,
  venueId,
  disabled
}: IDeleteAuditoriumButtonProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const deleteAuditorium = useDeleteAuditorium()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()

  const onSubmit = useCallback(async () => {
    try {
      await deleteAuditorium({id})
      addInfoNotification(t('Auditorium was deleted.'))
      history.replace(routeTo.admin.venues.detail(venueId))
    } catch (err) {
      defaultErrorHandler(err, t('Error while deleting the auditorium.'))
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteAuditorium,
    history,
    id,
    t,
    venueId
  ])

  const OpenModalButton: React.FC<IOpenModalButtonProps> = ({
    onClick
  }: IOpenModalButtonProps) => {
    return (
      <Button
        cypress-id="delete-auditorium-button"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        startIcon={<DeleteIcon />}
      >
        {t('Delete')}
      </Button>
    )
  }

  return (
    <StateChangeModal
      onSubmit={onSubmit}
      header={t('Do you really want to delete the auditorium?')}
      message={t('Are you sure you want to delete the auditorium?')}
      submitButtonText={t('Delete')}
      OpenModalButton={OpenModalButton}
      confirmationButtonCypressId="delete-auditorium-confirmation-button"
    />
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(1)
  }
}))

interface IStateInfoProps {
  state: AuditoriumState
}

const StateInfo: React.FC<IStateInfoProps> = ({state}: IStateInfoProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateAuditoriumState = useTranslateAuditoriumState()

  return state === AuditoriumState.Archived ? (
    <div className={classes.root}>
      <EntityStateChip
        label={translateAuditoriumState(state)}
        colorConf={auditoriumStateColors[state]}
      />
      <Typography variant="caption" color="textSecondary">
        {t(
          "The auditorium doesn't show in event planner and you are not able to use it for new events. You can change this option at any time."
        )}
      </Typography>
    </div>
  ) : null
}

export const AuditoriumDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()

  const {venueId, auditoriumId} = useVenuesPathnameParams()
  const {data, loading, error} = useGetAuditorium(auditoriumId)
  const handleExited = useCallback(
    () =>
      history.push(
        routeTo.admin.venues.detailAuditorium(venueId, auditoriumId)
      ),
    [auditoriumId, history, venueId]
  )

  return (
    <PageWithHeaderTemplate header={<PrimaryHeader title={t('Venues')} />}>
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData
          loading={loading}
          error={error}
          data={data}
          errorMessage={t<string>('Could not load auditorium')}
          dataCondition={(data: AuditoriumQuery) => Boolean(data?.auditorium)}
        >
          {({auditorium}: AuditoriumQuery) => (
            <CenteredLayout>
              <CenteredLayoutListWrapper>
                <VenuesBreadcrumbs
                  links={[
                    {
                      label: t('Venues'),
                      onClick: () =>
                        history.replace(routeTo.admin.venues.home())
                    },
                    {
                      label: auditorium.venue.name,
                      onClick: () =>
                        history.replace(routeTo.admin.venues.detail(venueId))
                    },
                    {label: auditorium.name}
                  ]}
                />
                <EntityDetailCardLayout
                  header={auditorium.name}
                  Buttons={[
                    P([PermissionCode.DeleteAuditorium]) && (
                      <DeleteAuditoriumButton
                        id={auditoriumId}
                        venueId={venueId}
                        key={1}
                        disabled={!!auditorium.auditoriumLayouts.length}
                      />
                    ),
                    P([PermissionCode.UpdateAuditorium]) && (
                      <Button
                        color="primary"
                        key={2}
                        onClick={() =>
                          history.push(
                            routeTo.admin.venues.editAuditorium(
                              venueId,
                              auditoriumId
                            )
                          )
                        }
                        startIcon={<EditIcon />}
                      >
                        {t('Edit')}
                      </Button>
                    )
                  ].filter(Boolean)}
                  supplementalInformation={[
                    {
                      caption: t('Shortcut'),
                      value: auditorium.shortcut
                    },
                    {
                      caption: t('Default division'),
                      value: auditorium.defaultDivision?.name
                    }
                  ]}
                >
                  <StateInfo state={auditorium.state} />
                </EntityDetailCardLayout>
                <AuditoriumLayouts {...{venueId, auditoriumId}} />
              </CenteredLayoutListWrapper>
            </CenteredLayout>
          )}
        </RenderOnData>
        {data?.auditorium && (
          <Route
            path={routeTo.admin.venues.editAuditorium(
              ':venueId',
              ':auditoriumId'
            )}
          >
            <EditAuditoriumDrawer
              venueId={venueId}
              auditorium={data.auditorium}
              onExited={handleExited}
            />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
