import {Divider} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import {Theme} from '../../theme'

interface IDividedElementsProps {
  arrayOfElementArrays: (React.ReactElement | null)[][]
  className?: string
}

export const getUniqueAndTrulyItems = <T extends object>(
  array: T[],
  iterateeShorthand: string
): T[] => uniqBy(filter(array, iterateeShorthand), iterateeShorthand)

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(1)
  }
}))

export const DividedElements: React.FC<IDividedElementsProps> = ({
  arrayOfElementArrays,
  className
}: IDividedElementsProps) => {
  const classes = useStyles()
  const flatArray = arrayOfElementArrays
    .filter((array) => Boolean(array.length))
    .reduce<React.ReactNode[]>(
      (elementsAcc, elementsArray, index, originalArrays) => [
        ...elementsAcc,
        ...elementsArray,
        index < originalArrays.length - 1 ? (
          <Divider key={`divider-${index}`} orientation="vertical" />
        ) : null
      ],
      []
    )
    .flat()
  return flatArray.length ? (
    <div className={cn(classes.root, className)}>{flatArray}</div>
  ) : null
}
