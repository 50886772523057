import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {FilledInput, IconButton, InputAdornment} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import _ from 'lodash'
import React, {useRef, useState} from 'react'
import {Theme} from '../../theme'

import {borderStyle} from '../constants'

const useStyles = makeStyles<Theme>((theme) => ({
  searchInput: {
    width: '100%',
    border: borderStyle,
    borderRadius: 30
  },
  iconButton: {
    padding: theme.spacing(0.75),
    color: theme.palette.text.primary,
    marginRight: -6
  },
  input: {
    padding: theme.spacing(1.3, 2)
  },
  root: {
    background: '#f5f5f5'
  }
}))

interface ISearchFieldProps {
  onTypingEnd: (value: string) => any
  placeholder: string
  defaultValue?: string
  className?: string
}

// Note: this component resp. its `Input` is not controlled and
// instead "ref" is used so that there is no lagging when typing fast
export const SearchField: React.FC<ISearchFieldProps> = ({
  onTypingEnd,
  placeholder,
  defaultValue,
  className
}: ISearchFieldProps) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isEmpty, setIsEmpty] = useState(!defaultValue)

  const onReset = () => {
    if (!inputRef || !inputRef.current) return
    inputRef.current.value = ''
    onTypingEnd('')
    setIsEmpty(true)
  }

  const onKeyUp = _.debounce(() => {
    if (!inputRef || !inputRef.current) return

    const value = inputRef.current.value
    onTypingEnd(inputRef.current.value)
    setIsEmpty(!value)
  }, 300)

  return (
    <FilledInput
      {...{inputRef, onKeyUp, placeholder, defaultValue}}
      className={cn(classes.searchInput, className)}
      disableUnderline
      classes={{input: classes.input, root: classes.root}}
      endAdornment={
        <InputAdornment position="end">
          {isEmpty ? (
            <SearchIcon />
          ) : (
            <IconButton
              onClick={onReset}
              className={classes.iconButton}
              edge="end"
            >
              <ClearIcon />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  )
}
