import {Box} from '@mui/material'
import React from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {ReactComponent as Logo} from '../../../assets/logo.svg'
import {getValuesBasedOnInfrastructure} from '../../../hooks/getValuesBasedOnInfrastructure'
import {AuthStatus, useUserInfo} from '../../../utils/auth'
import {routeTo} from '../../../utils/routes'
import {LocaleSwitcherButton} from '../../common/LocaleSwitcherButton'
import {ForgotPassword} from './ForgotPassword'
import {LoginForm} from './LoginForm'

export const LoginPage: React.FC = () => {
  const {loginStatus} = useUserInfo()
  const {promoWebsite} = getValuesBasedOnInfrastructure()
  const {
    location: {state: prevUrl}
  } = useHistory()
  if (loginStatus === AuthStatus.LOGGED_IN) {
    return (
      <Redirect
        to={{pathname: (prevUrl || routeTo.admin.dashboard()) as string}}
      />
    )
  }
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <Box sx={{px: 3, py: 1.5, alignSelf: 'flex-end'}}>
        <LocaleSwitcherButton />
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: 2,
            px: 3,
            backgroundColor: 'primary.contrastText',
            border: (theme) => `solid 1px ${theme.palette.divider}`,
            borderRadius: 1,
            width: 360,
            gap: 3
          }}
        >
          <Logo
            style={{marginTop: 8, alignSelf: 'center', cursor: 'pointer'}}
            height={30.45}
            width={133}
            onClick={() => window.open(promoWebsite, '_blank')}
          />
          <LoginForm />
          <ForgotPassword />
        </Box>
      </Box>
    </Box>
  )
}
