import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ReservationsFilterInput,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption,
  useSearchSelect
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {QuickSearchDaterangeRow} from '../../../common/search/QuickSearchDaterangeRow'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {useGetLightweightDivisions} from '../graphql'
import {useGetFilterEventVenues} from './graphql'

export enum ReservationSearchLocation {
  ReservationsList = 'reservationsList',
  ActivityReservations = 'activityReservations'
}

type ExtendedReservationsFilter = ReservationsFilterInput & {
  _eventStartDaterangeId?: DATERANGE_IDS
  _expireDDaterangeId?: DATERANGE_IDS
}

const stripEventStartDateFromFilter = (
  filter: ExtendedReservationsFilter
): ReservationsFilterInput =>
  omit(filter, ['eventFrom', 'eventTo', '_eventStartDaterangeId'])

const stripHelperKeysFromFilter = (
  filter: ExtendedReservationsFilter
): ReservationsFilterInput =>
  omit(filter, ['_eventStartDaterangeId', '_expireDDaterangeId'])

const stripExpireDateFromFilter = (
  filter: ExtendedReservationsFilter
): ReservationsFilterInput =>
  omit(filter, ['expireFrom', 'expireTo', '_expireDDaterangeId'])

export const DEFAULT_RESERVATIONS_FILTER_INPUT: ReservationsFilterInput = {
  hasText: ''
}

const getVenueIdFromFilter = (filter: ReservationsFilterInput) =>
  filter.venueId || undefined

const getDivisionIdFromFilter = (filter: ReservationsFilterInput) =>
  filter.divisionId || undefined

const getHasTextFromFilter = (filter: ReservationsFilterInput) =>
  filter.hasText || undefined

const getReservationIdFromFilter = (filter: ReservationsFilterInput) =>
  filter.id ? String(filter.id) : undefined

const getTitleFromFilter = (filter: ReservationsFilterInput) =>
  filter.title || undefined

const getLeadNameFromFilter = (filter: ReservationsFilterInput) =>
  filter.leadName || undefined

const getLeadEmailFromFilter = (filter: ReservationsFilterInput) =>
  filter.leadEmail || undefined

const getLeadPhoneFromFilter = (filter: ReservationsFilterInput) =>
  filter.leadPhone || undefined

const getNoteFromFilter = (filter: ReservationsFilterInput) =>
  filter.note || undefined

const getChannelFromFilter = (filter: ReservationsFilterInput) =>
  filter.channels ? filter.channels[0] : undefined

const mapDivisionIdToFilter = (
  filter: ReservationsFilterInput,
  divisionId: number | undefined
): ReservationsFilterInput => ({
  ...filter,
  divisionId
})

const mapVenueIdToFilter = (
  filter: ReservationsFilterInput,
  venueId: number | undefined
): ReservationsFilterInput => ({
  ...filter,
  venueId
})

const mapHasTextToFilter = (
  filter: ReservationsFilterInput,
  hasText: string
): ReservationsFilterInput => ({
  ...filter,
  hasText
})

const mapReservationIdToFilter = (
  filter: ReservationsFilterInput,
  id?: string
): ReservationsFilterInput => ({
  ...filter,
  id: id ? parseInt(id, 10) : undefined
})

const mapTitleToFilter = (
  filter: ReservationsFilterInput,
  title: string
): ReservationsFilterInput => ({
  ...filter,
  title
})

const mapLeadNameToFilter = (
  filter: ReservationsFilterInput,
  leadName: string
): ReservationsFilterInput => ({
  ...filter,
  leadName
})

const mapLeadEmailToFilter = (
  filter: ReservationsFilterInput,
  leadEmail: string
): ReservationsFilterInput => ({
  ...filter,
  leadEmail
})

const mapLeadPhoneToFilter = (
  filter: ReservationsFilterInput,
  leadPhone: string
): ReservationsFilterInput => ({
  ...filter,
  leadPhone
})

const mapNoteToFilter = (
  filter: ReservationsFilterInput,
  note: string
): ReservationsFilterInput => ({
  ...filter,
  note
})

const mapChannelToFilter = (
  filter: ReservationsFilterInput,
  channel?: SellingChannel
): ReservationsFilterInput => ({
  ...filter,
  channels: channel ? [channel] : undefined
})

const eventStartDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.TOMORROW,
  DATERANGE_IDS.THIS_WEEKEND,
  DATERANGE_IDS.NEXT_WEEK,
  DATERANGE_IDS.NEXT_WEEKEND,
  DATERANGE_IDS.NEXT_MONTH
]

const expirationDateDateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.TOMORROW,
  DATERANGE_IDS.THIS_WEEKEND,
  DATERANGE_IDS.NEXT_7_DAYS,
  DATERANGE_IDS.NEXT_WEEK,
  DATERANGE_IDS.NEXT_WEEKEND
]

interface IReservationsSearchProps {
  onFilterChange: (filter: ReservationsFilterInput) => void
  location: ReservationSearchLocation
}

export const ReservationsSearch: React.FC<IReservationsSearchProps> = ({
  onFilterChange,
  location
}: IReservationsSearchProps) => {
  const {t} = useTranslation()
  const [enabledDivisions] = useLocalStorageState<number[]>(
    LocalStorageKey.EnabledDivisions,
    []
  )
  const translateSellingChannel = useTranslateSellingChannel()
  const sellingChannelOptions = [
    SellingChannel.Retail,
    SellingChannel.ECommerce
  ].map((channel) => ({
    id: channel,
    label: translateSellingChannel(channel)
  }))

  const {
    daterangeOptions: eventStartDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomEventStartDateRangeToFilter,
    mapDaterangeToSearchObject: mapEventStartDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedEventStartDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsEventStartDaterangeOptionActive
  } = useDateRangeSearch<ExtendedReservationsFilter>({
    usedDateranges: eventStartDateDateRanges,
    dateRangeInputPrefix: t('Event start'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.startsAtFrom || undefined,
      endDateISOString: o.startsAtTo || undefined,
      id: o._eventStartDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      startsAtFrom: input.startDate,
      startsAtTo: input.endDate,
      _eventStartDaterangeId: input.id
    })
  })

  const {
    daterangeOptions: expireDateDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomExpireDateRangeToFilter,
    mapDaterangeToSearchObject: mapExpireDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedExpireDateDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsExpireDaterangeOptionActive
  } = useDateRangeSearch<ExtendedReservationsFilter>({
    usedDateranges: expirationDateDateRanges,
    dateRangeInputPrefix: t('Expiration date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.expireFrom || undefined,
      endDateISOString: o.expireTo || undefined,
      id: o._expireDDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      expireFrom: input.startDate,
      expireTo: input.endDate,
      _expireDDaterangeId: input.id
    })
  })

  const {venues} = useGetFilterEventVenues()

  const venueSelectOptions: ISelectOption<number>[] = venues.map((venue) => ({
    id: venue.id,
    label: venue.name
  }))

  const {
    getStringifiedSelectValueFromSearchObject: getVenueIdFromSearchObject
  } = useSearchSelect<ReservationsFilterInput, number>({
    selectInputPrefix: t('Venue'),
    selectOptions: venueSelectOptions,
    getSelectValueFromSearchObject: getVenueIdFromFilter
  })

  const {
    getStringifiedSelectValueFromSearchObject: getChannelFromSearchObject
  } = useSearchSelect<ReservationsFilterInput, SellingChannel>({
    selectInputPrefix: t('Channel'),
    selectOptions: sellingChannelOptions,
    getSelectValueFromSearchObject: getChannelFromFilter
  })

  const {divisions} = useGetLightweightDivisions()

  const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
    (division) => ({
      id: division.id,
      label: division.name
    })
  )

  const {
    getStringifiedSelectValueFromSearchObject: getDivisionIdFromSearchObject
  } = useSearchSelect<ReservationsFilterInput, number>({
    selectInputPrefix: t('Division'),
    selectOptions: divisionSelectOptions,
    getSelectValueFromSearchObject: getDivisionIdFromFilter
  })

  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<ReservationsFilterInput>(
      getHasTextFromFilter,
      getReservationIdFromFilter,
      getTitleFromFilter,
      getLeadNameFromFilter,
      getLeadEmailFromFilter,
      getLeadPhoneFromFilter,
      getNoteFromFilter,
      getVenueIdFromSearchObject,
      getDivisionIdFromSearchObject,
      getStringifiedExpireDateDateRangeFromFilter,
      getStringifiedEventStartDateDateRangeFromFilter,
      getChannelFromSearchObject
    )

  return (
    <Search<ReservationsFilterInput, ExtendedReservationsFilter>
      storageKey={
        location === ReservationSearchLocation.ReservationsList
          ? 'RESERVATIONS_LIST'
          : 'ACTIVITY_RESERVATIONS'
      }
      placeholder={t('Search for reservations')}
      defaultSearchObject={DEFAULT_RESERVATIONS_FILTER_INPUT}
      mapInputTextToSearchObject={mapHasTextToFilter}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      onChange={onFilterChange}
      renderQuickOptions={(setSearchObject) => (
        <>
          {location === ReservationSearchLocation.ReservationsList && (
            <QuickSearchDaterangeRow<ReservationsFilterInput>
              label={t('Event start')}
              daterangeOptions={eventStartDateDaterangeOptions}
              mapDaterangeToSearchObject={mapEventStartDaterangeToFilter}
              mapCustomDaterangeToSearchObject={
                mapCustomEventStartDateRangeToFilter
              }
              searchObject={DEFAULT_RESERVATIONS_FILTER_INPUT}
              setSearchObject={setSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range of event start'
              )}
            />
          )}
          <QuickSearchDaterangeRow<ReservationsFilterInput>
            label={t('Expiration date')}
            daterangeOptions={expireDateDaterangeOptions}
            mapDaterangeToSearchObject={mapExpireDaterangeToFilter}
            mapCustomDaterangeToSearchObject={mapCustomExpireDateRangeToFilter}
            searchObject={DEFAULT_RESERVATIONS_FILTER_INPUT}
            setSearchObject={setSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of expire date'
            )}
          />
        </>
      )}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          {location === ReservationSearchLocation.ReservationsList && (
            <AdvancedSearchDaterangeRow
              label={t('Event start')}
              daterangeOptions={eventStartDateDaterangeOptions}
              mapDaterangeToSearchObject={mapEventStartDaterangeToFilter}
              mapCustomDaterangeToSearchObject={
                mapCustomEventStartDateRangeToFilter
              }
              stripDaterangeFromSearchObject={stripEventStartDateFromFilter}
              getIsDaterangeOptionActive={getIsEventStartDaterangeOptionActive}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range of event start'
              )}
            />
          )}
          <AdvancedSearchSelectRow<ReservationsFilterInput, SellingChannel>
            label={t('Channel')}
            value={getChannelFromFilter(advancedSearchObject)}
            options={sellingChannelOptions}
            searchObject={advancedSearchObject}
            setSearchObject={setAdvancedSearchObject}
            mapSelectValueToSearchObject={mapChannelToFilter}
          />
          <AdvancedSearchDaterangeRow
            label={t('Expiration date')}
            daterangeOptions={expireDateDaterangeOptions}
            mapDaterangeToSearchObject={mapExpireDaterangeToFilter}
            mapCustomDaterangeToSearchObject={mapCustomExpireDateRangeToFilter}
            stripDaterangeFromSearchObject={stripExpireDateFromFilter}
            getIsDaterangeOptionActive={getIsExpireDaterangeOptionActive}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of expire date'
            )}
          />
          {location === ReservationSearchLocation.ReservationsList &&
            enabledDivisions.length === 0 && (
              <AdvancedSearchSelectRow<ReservationsFilterInput, number>
                label={t('Division')}
                value={getDivisionIdFromFilter(advancedSearchObject)}
                options={divisionSelectOptions}
                searchObject={advancedSearchObject}
                setSearchObject={setAdvancedSearchObject}
                mapSelectValueToSearchObject={mapDivisionIdToFilter}
              />
            )}
          <AdvancedSearchTextRow
            label={t('Has text')}
            placeholder={t('Enter text')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            value={advancedSearchObject.hasText || undefined}
          />
          {location === ReservationSearchLocation.ReservationsList && (
            <AdvancedSearchTextRow
              label={t('Event title')}
              placeholder={t('Enter event title')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapTitleToFilter}
              value={advancedSearchObject.title || undefined}
            />
          )}
          <AdvancedSearchTextRow
            label={t('Reservation number')}
            placeholder={t('Enter reservation number')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapReservationIdToFilter}
            value={
              advancedSearchObject.id
                ? String(advancedSearchObject.id)
                : undefined
            }
          />
          <AdvancedSearchTextRow
            label={t('Customer name')}
            placeholder={t('Enter customer name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapLeadNameToFilter}
            value={advancedSearchObject.leadName || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Customer phone')}
            placeholder={t('Enter customer phone')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapLeadPhoneToFilter}
            value={advancedSearchObject.leadPhone || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Customer e-mail')}
            placeholder={t('Enter customer e-mail')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapLeadEmailToFilter}
            value={advancedSearchObject.leadEmail || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Order note')}
            placeholder={t('Enter order note')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapNoteToFilter}
            value={advancedSearchObject.note || undefined}
          />
          {location === ReservationSearchLocation.ReservationsList && (
            <AdvancedSearchSelectRow<ReservationsFilterInput, number>
              label={t('Venue')}
              value={getVenueIdFromFilter(advancedSearchObject)}
              options={venueSelectOptions}
              searchObject={advancedSearchObject}
              setSearchObject={setAdvancedSearchObject}
              mapSelectValueToSearchObject={mapVenueIdToFilter}
            />
          )}
        </AdvancedSearchBase>
      )}
    />
  )
}
