import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import dayjs from 'dayjs'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentMethodType,
  PaymentServiceProvider,
  PermissionCode,
  VoucherCampaignState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useVoucherParams} from '../../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {MenuItem} from '../../../../common/Menu'
import {SplitButton} from '../../../../visual'
import {
  useChangeVoucherCampaignState,
  useGetLightweightECommercePaymentMethods,
  useGetLightweightRetailPaymentMethods,
  useGetVoucherCampaign
} from '../graphql'
import {
  IVoucherCampaignForm,
  VoucherCampaignFormField,
  VoucherCampaignFormLocation
} from '../types'
import {VoucherCampaignForm} from '../VoucherCampaignForm'

const UPDATE_VOUCHER_CAMPAIGN_FORM_ID = 'updateVoucherCampaignForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface IEditVoucherCampaignDrawerProps {
  onExited: () => void
}

export const EditVoucherCampaignDrawer: React.FC<IEditVoucherCampaignDrawerProps> =
  ({onExited}: IEditVoucherCampaignDrawerProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {voucherCampaignId} = useVoucherParams()
    const {data, loading, error} = useGetVoucherCampaign(voucherCampaignId)
    const changeVoucherCampaignState = useChangeVoucherCampaignState()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {data: retailPaymentMethodsData} =
      useGetLightweightRetailPaymentMethods()
    const {data: eCommercePaymentMethodsData} =
      useGetLightweightECommercePaymentMethods()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (voucherCampaignId) {
        openDrawer()
      }
    }, [voucherCampaignId, openDrawer])
    const classes = useStyles()
    const handleSubmit = useCallback(
      (data: IVoucherCampaignForm) => {
        // eslint-disable-next-line no-console
        console.log(data)
        closeDrawer()
      },
      [closeDrawer]
    )
    const setAs = useCallback(
      (state: VoucherCampaignState) => async () => {
        try {
          setShowBackdrop(true)
          await changeVoucherCampaignState({id: voucherCampaignId, state})
          addInfoNotification(t('Voucher campaign state updated'))
          onExited()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while changing voucher campaign state')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        changeVoucherCampaignState,
        defaultErrorHandler,
        onExited,
        setShowBackdrop,
        t,
        voucherCampaignId
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error && t<string>('Error while loading voucher campaign')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Edit voucher campaign')}
            />
          }
          footer={
            P([PermissionCode.ChangeVoucherCampaignState]) ? (
              <SplitButton
                Options={[
                  data?.voucherCampaign.state ===
                    VoucherCampaignState.Active && (
                    <MenuItem
                      key={1}
                      label={t('Set sa invalid')}
                      onClick={setAs(VoucherCampaignState.Invalid)}
                    />
                  ),
                  (data?.voucherCampaign.state ===
                    VoucherCampaignState.Inactive ||
                    data?.voucherCampaign.state ===
                      VoucherCampaignState.Invalid) && (
                    <MenuItem
                      key={2}
                      label={t('Set sa active')}
                      onClick={setAs(VoucherCampaignState.Active)}
                    />
                  )
                ]}
              >
                <SaveButton
                  type="submit"
                  form={UPDATE_VOUCHER_CAMPAIGN_FORM_ID}
                >
                  {t('Save')}
                </SaveButton>
              </SplitButton>
            ) : (
              <SaveButton type="submit" form={UPDATE_VOUCHER_CAMPAIGN_FORM_ID}>
                {t('Save')}
              </SaveButton>
            )
          }
          childrenClassName={classes.drawerChildren}
        >
          {data && retailPaymentMethodsData && eCommercePaymentMethodsData && (
            <VoucherCampaignForm
              location={VoucherCampaignFormLocation.Edit}
              formId={UPDATE_VOUCHER_CAMPAIGN_FORM_ID}
              onSubmit={handleSubmit}
              retailPaymentMethods={retailPaymentMethodsData.retailPaymentMethods.filter(
                ({serviceProvider, type}) =>
                  serviceProvider === PaymentServiceProvider.InHouseVoucher &&
                  type === PaymentMethodType.Voucher
              )}
              eCommercePaymentMethods={eCommercePaymentMethodsData.eCommercePaymentMethods.filter(
                ({serviceProvider, type}) =>
                  serviceProvider === PaymentServiceProvider.InHouseVoucher &&
                  type === PaymentMethodType.Voucher
              )}
              defaultValues={{
                [VoucherCampaignFormField.Name]: data.voucherCampaign.name,
                [VoucherCampaignFormField.InitialBalance]:
                  data.voucherCampaign.initialBalance || undefined,
                [VoucherCampaignFormField.InitialValidity]: data.voucherCampaign
                  .initialValidity
                  ? dayjs
                      .duration(data.voucherCampaign.initialValidity)
                      .asDays()
                  : undefined,
                [VoucherCampaignFormField.RechargeLimit]:
                  data.voucherCampaign.rechargeLimit || undefined,
                [VoucherCampaignFormField.RetailPaymentMethodId]:
                  data.voucherCampaign.retailPaymentMethod?.id,
                [VoucherCampaignFormField.EcommercePaymentMethodId]:
                  data.voucherCampaign.ecommercePaymentMethod?.id,
                [VoucherCampaignFormField.Description]:
                  data.voucherCampaign.description || undefined,
                [VoucherCampaignFormField.CanCreateNewVouchers]:
                  data.voucherCampaign.canCreateNewVouchers
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
