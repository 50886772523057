import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useObjectsActions} from '../redux/objects/actions'
import {isSomeObjectSelectedSelector} from '../redux/objects/selectors'
import {CopyIcon, DeleteOutlineIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const CopyDeletePanel: React.FC = () => {
  const {t} = useTranslation()
  const {duplicateSelectedObjects, removeSelectedObjects} = useObjectsActions()

  const isSomeObjectSelected = useSelector(isSomeObjectSelectedSelector)

  return (
    <Panel>
      <PanelButton
        disabled={!isSomeObjectSelected}
        onClick={duplicateSelectedObjects}
        tooltip={t('Copy')}
        cypressId="copy-button"
      >
        <CopyIcon />
      </PanelButton>
      <PanelButton
        disabled={!isSomeObjectSelected}
        onClick={removeSelectedObjects}
        tooltip={t('Delete', {context: 'editor'})}
        cypressId="delete-button"
      >
        <DeleteOutlineIcon />
      </PanelButton>
    </Panel>
  )
}
