export enum ProductGroupFormField {
  Name = 'name',
  Color = 'color',
  Icon = 'icon',
  Description = 'description',
  InternalDescription = 'internalDescription',
  CoverImageUrl = 'coverImageUrl'
}

export interface IProductGroupForm {
  [ProductGroupFormField.Name]: string
  [ProductGroupFormField.Color]: string
  [ProductGroupFormField.Icon]?: string
  [ProductGroupFormField.Description]?: string
  [ProductGroupFormField.InternalDescription]?: string
  [ProductGroupFormField.CoverImageUrl]?: string
}

export enum ProductGroupAvailabilityFormField {
  DivisionId = 'divisionId',
  IsAvailableOnRetailChannel = 'isAvailableOnRetailChannel',
  IsAvailableOnECommerceChannel = 'isAvailableOnECommerceChannel'
}

export interface IProductGroupAvailabilityForm {
  [ProductGroupAvailabilityFormField.DivisionId]: number
  [ProductGroupAvailabilityFormField.IsAvailableOnRetailChannel]: boolean
  [ProductGroupAvailabilityFormField.IsAvailableOnECommerceChannel]: boolean
}

export interface IMergedProductGrupAvailability {
  divisionId: number
  divisionName: string
  isAvailableOnRetailChannel: boolean
  isAvailableOnECommerceChannel: boolean
}
