import {useEffect} from 'react'
import {
  isCustomerDisplayEnabled,
  usePostCustomerDisplayMessage
} from '../../../../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../../../../customerDisplayBroadcastChannel/types'

export const usePostDisplayBaseViewMessageOnInit = () => {
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()

  useEffect(() => {
    if (isCustomerDisplayEnabled()) {
      postCustomerDisplayMessage({
        type: CustomerDisplayMessageType.DisplayBaseView
      })
    }
  }, [postCustomerDisplayMessage])
}
