import {Button} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'

import {AbsoluteTwoLevelSideNavigation} from '../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {ShowSection, useShowSectionTranslations} from '../../../constants'
import {useBackdropState} from '../../../context/backdrop'
import {useNotifications} from '../../../context/notifications'
import {SecondaryHeader} from '../Header'
import {CenteredLayout} from '../Layout'
import {useCreateLibraryShow} from './graphql'
import {ShowForm, useShowFormStyles} from './ShowForm'
import {IShowForm, ShowFormLocation} from './types'
import {useShowDetailsFormAnchors} from './useShowDetailsFormAnchors'
import {
  getShowFormDefaultValues,
  transformIShowFormToShowInput,
  transformIShowFormToShowTranslationInput
} from './utils'

const ADD_LIBRARY_SHOW_FORM_ID = 'addLibraryShowForm'

const useAddLibraryShowNavigationItems = () => {
  const showDetailsFormAnchors = useShowDetailsFormAnchors()
  const showSectionTranslations = useShowSectionTranslations()
  return {
    [ShowSection.Data]: {
      label: showSectionTranslations[ShowSection.Data],
      secondLevelItems: showDetailsFormAnchors
    }
  }
}

export const AddLibraryShow: React.FC = () => {
  const showFormClasses = useShowFormStyles()
  const history = useHistory()
  const {t} = useTranslation()
  const navigationItems = useAddLibraryShowNavigationItems()
  const handleClose = useCallback(() => {
    history.goBack()
  }, [history])

  const [showSection, setShowSection] = useState<ShowSection>(ShowSection.Data)

  const createLibraryShow = useCreateLibraryShow()
  const defaultErrorHandler = useDefaultErrorHandler()
  const {addInfoNotification} = useNotifications()
  const {setShowBackdrop} = useBackdropState()

  const handleSubmit = useCallback(
    async (showForm: IShowForm) => {
      setShowBackdrop(true)
      try {
        const {data} = await createLibraryShow({
          data: transformIShowFormToShowInput(showForm),
          translation: transformIShowFormToShowTranslationInput(showForm)
        })
        if (data) {
          addInfoNotification(t('Library show created'))
          history.replace(
            routeTo.admin.library.editWithEditSection(
              data.createLibraryShow.id,
              ShowSection.Translations
            )
          )
        }
      } catch (e) {
        defaultErrorHandler(e, t('Error while adding library show'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createLibraryShow,
      defaultErrorHandler,
      history,
      setShowBackdrop,
      t
    ]
  )

  const localeCode = useClientLocaleCode()

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Create a library show')} />}
      footer={
        <Footer>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            form={ADD_LIBRARY_SHOW_FORM_ID}
          >
            {t('Create')}
          </Button>
        </Footer>
      }
    >
      <AbsoluteTwoLevelSideNavigation
        items={navigationItems}
        activeLevel={showSection}
        onSectionItemClick={setShowSection}
      />
      <CenteredLayout>
        <ShowForm
          defaultValues={getShowFormDefaultValues({
            show: null,
            localeCode
          })}
          onSubmit={handleSubmit}
          formId={ADD_LIBRARY_SHOW_FORM_ID}
          className={showFormClasses.form}
          location={ShowFormLocation.Library}
        />
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
