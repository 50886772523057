import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {CartState} from '../../../../../__generated__/schema'

export const useNavigationItems = (cartState?: CartState) => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      general: {id: string; label: string}
      payment: {id: string; label: string}
      items?: {id: string; label: string}
      reservations?: {id: string; label: string}
      lead: {id: string; label: string}
    } => ({
      general: {
        id: 'general',
        label: t('General')
      },
      payment: {
        id: 'payment',
        label: t('Payment')
      },
      ...(cartState === CartState.Reserved
        ? {
            reservations: {
              id: 'reservations',
              label: t('Reservations')
            }
          }
        : {
            items: {
              id: 'items',
              label: t('Items')
            }
          }),
      lead: {
        id: 'lead',
        label: t('Lead')
      }
    }),
    [cartState, t]
  )
}
