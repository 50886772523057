import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, Button, Divider, IconButton, Typography} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CashDeskToursQuery,
  PermissionCode,
  TourTimeSlotState
} from '../../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../../hooks/formatUserName'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {useTranslateShowVersionAbbreviation} from '../../../../../hooks/translateDistributions'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {CreateTourTimeSlotDrawer} from '../../../../common/createTourTimeSlotDrawer'
import {TimeSlotCapacityIndicators} from './TimeSlotCapacityIndicators'

interface ISlotItemProps {
  slot: CashDeskToursQuery['tours']['items'][number]['timeSlots']['items'][number]
}

const SlotItem: React.FC<ISlotItemProps> = ({slot}: ISlotItemProps) => {
  const {formatTime, formatDateNumericShort} = useDateTimeFormatters()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()

  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const formatShortGuideName = useFormatShortGuideName()
  const history = useHistory()
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      history.push(routeTo.admin.cashDesk.tourTimeSlot(slot.id))
    },
    [history, slot.id]
  )
  return (
    <Box
      sx={{
        width: 224,
        height: 72,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        backgroundColor: 'background.paper',
        borderRadius: 1,
        p: 1.5,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle2">
          {formatTime(new Date(slot.startsAt))}
        </Typography>
        <TimeSlotCapacityIndicators
          retailAttendeesLimit={slot.retailAttendeesLimit}
          stateCounts={slot.tourItemsStateCounts}
          hideCapacity
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          {!dayjs(slot.startsAt).isToday() && (
            <Typography variant="subtitle2">
              {formatDateNumericShort(new Date(slot.startsAt))}
            </Typography>
          )}
        </Box>
        <Typography variant="overline" color="textSecondary">
          {[
            slot.guide && formatShortGuideName(slot.guide),
            slot.versionCode &&
              translateShowVersionAbbreviation(slot.versionCode),
            slot.ageClassificationCode &&
              translateAgeClassificationAbbreviation(slot.ageClassificationCode)
          ]
            .filter(Boolean)
            .join(' • ')}
        </Typography>
      </Box>
    </Box>
  )
}

interface ITourItemProps {
  tour: CashDeskToursQuery['tours']['items'][number]
  refetch: () => void
  isPhablet: boolean
}

export const TourItem: React.FC<ITourItemProps> = ({
  tour,
  refetch,
  isPhablet
}: ITourItemProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const {
    state: isCreateDrawerOpen,
    setTrue: openCreateDrawer,
    setFalse: closeCreateDrawer
  } = useBooleanState(false)
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.cashDesk.tourTimeSlotsDrawer(tour.id)),
    [history, tour.id]
  )
  return (
    <>
      <Box
        sx={{
          maxWidth: 960,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          border: (theme) => `solid 1px ${theme.palette.divider}`
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="subtitle1">{tour.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {[tour.division.name, tour.internalNote]
                .filter(Boolean)
                .join(' • ')}
            </Typography>
          </Box>
          <Box>
            {P([
              PermissionCode.CreateTourTimeSlotInCashDesk,
              PermissionCode.CreateTourTimeSlot
            ]) && (
              <Button
                variant="text"
                color="primary"
                startIcon={<AddIcon />}
                onClick={openCreateDrawer}
              >
                {t('Add')}
              </Button>
            )}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            px: 2,
            py: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: isPhablet ? 1 : 2,
              flexWrap: 'wrap'
            }}
          >
            {tour.timeSlots.items.length ? (
              tour.timeSlots.items.map((slot) => (
                <SlotItem key={slot.id} slot={slot} />
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                {t(
                  'There are no available time slots for this tour on the selected date.'
                )}
              </Typography>
            )}
          </Box>
          <Box>
            {P([
              PermissionCode.ReadTourTimeSlots,
              PermissionCode.ReadTourTimeSlot
            ]) && (
              <IconButton onClick={handleIconClick}>
                <ChevronRightIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <CreateTourTimeSlotDrawer
        isOpen={isCreateDrawerOpen}
        onClose={closeCreateDrawer}
        tourId={tour.id}
        showTypeCode={tour.show.typeCode}
        refetch={refetch}
        state={TourTimeSlotState.Published}
      />
    </>
  )
}
