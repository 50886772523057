import {Box, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'

interface ITotalPriceProps {
  sx?: SxProps<Theme>
  total: number
}

export const TotalPrice: React.FC<ITotalPriceProps> = ({
  sx,
  total
}: ITotalPriceProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  return (
    <Box
      sx={{
        height: 64,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 2,
        borderTop: (theme) => `solid ${theme.palette.divider} 1px`,
        ...sx
      }}
    >
      <Typography variant="subtitle1">{t('Total')}</Typography>
      <Typography variant="subtitle1">{translatePrice(total)}</Typography>
    </Box>
  )
}
