import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {matchPath, Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {
  EventsStatisticsFilterInput,
  PermissionCode
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EventSalesPersistentDrawer} from '../../../common/eventSalesPersistentDrawer/EventSalesPersistentDrawer'
import {MenuItem} from '../../../common/Menu'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PersistentDrawerWithContent} from '../../../common/PersistentDrawerWithContent'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {AvailabilityPage} from './AvailabilityPage'
import {EventsStatisticsPage} from './EventsStatisticsPage'
import {
  DEFAULT_EVENT_STATISTICS_FILTER_INPUT,
  EventsStatisticsSearch
} from './EventsStatisticsSearch'
import {OverviewPage} from './OverviewPage'
import {RevenuesPage} from './RevenuesPage'
import {TicketsCheckingPage} from './TicketsCheckingPage'

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      overview: {id: string; label: string; route: string}
      revenues: {id: string; label: string; route: string}
      turnovers: {id: string; label: string; route: string}
      availability: {id: string; label: string; route: string}
      ticketsChecking: {id: string; label: string; route: string}
    } => ({
      overview: {
        id: 'overview',
        label: t('Overview'),
        route: routeTo.admin.eventsStatistics.overview()
      },
      revenues: {
        id: 'revenues',
        label: t('Revenues'),
        route: routeTo.admin.eventsStatistics.revenues()
      },
      turnovers: {
        id: 'turnovers',
        label: t('Turnovers'),
        route: routeTo.admin.eventsStatistics.turnovers()
      },
      availability: {
        id: 'availability',
        label: t('Availability'),
        route: routeTo.admin.eventsStatistics.availability()
      },
      ticketsChecking: {
        id: 'ticketsChecking',
        label: t('Tickets checking'),
        route: routeTo.admin.eventsStatistics.ticketsChecking()
      }
    }),
    [t]
  )
}

const useDrawerMenuStyles = makeStyles<Theme>((theme) => ({
  menu: {
    padding: theme.spacing(1, 0)
  }
}))

const DrawerMenu: React.FC = () => {
  const navigationItems = useNavigationItems()
  const history = useHistory()
  const classes = useDrawerMenuStyles()
  return (
    <div className={classes.menu}>
      {Object.values(navigationItems).map(
        (item) =>
          item && (
            <MenuItem
              key={item.id}
              label={item.label}
              onClick={() => history.push(item.route)}
              isSelected={!!matchPath(location.pathname, {path: item.route})}
            />
          )
      )}
    </div>
  )
}

interface IDrawerContentProps {
  searchFilter: EventsStatisticsFilterInput
  setReturnUrl: (url: string) => void
}

const DrawerContent: React.FC<IDrawerContentProps> = ({
  searchFilter,
  setReturnUrl
}: IDrawerContentProps) => {
  const currentLocation = location.pathname
  useEffect(() => {
    if (matchPath(currentLocation, routeTo.admin.eventsStatistics.revenues())) {
      setReturnUrl(routeTo.admin.eventsStatistics.revenues())
    } else if (
      matchPath(currentLocation, routeTo.admin.eventsStatistics.turnovers())
    ) {
      setReturnUrl(routeTo.admin.eventsStatistics.turnovers())
    } else if (
      matchPath(currentLocation, routeTo.admin.eventsStatistics.overview())
    ) {
      setReturnUrl(routeTo.admin.eventsStatistics.overview())
    } else if (
      matchPath(
        currentLocation,
        routeTo.admin.eventsStatistics.ticketsChecking()
      )
    ) {
      setReturnUrl(routeTo.admin.eventsStatistics.ticketsChecking())
    } else {
      setReturnUrl(routeTo.admin.eventsStatistics.availability())
    }
  }, [currentLocation, setReturnUrl])
  return (
    <Switch>
      <Route path={routeTo.admin.eventsStatistics.overview()}>
        <OverviewPage searchFilter={searchFilter} />
      </Route>
      <Route path={routeTo.admin.eventsStatistics.revenues()}>
        <RevenuesPage searchFilter={searchFilter} />
      </Route>
      <Route path={routeTo.admin.eventsStatistics.turnovers()}>
        <EventsStatisticsPage searchFilter={searchFilter} />
      </Route>
      <Route path={routeTo.admin.eventsStatistics.availability()}>
        <AvailabilityPage searchFilter={searchFilter} />
      </Route>
      <Route path={routeTo.admin.eventsStatistics.ticketsChecking()}>
        <TicketsCheckingPage searchFilter={searchFilter} />
      </Route>
    </Switch>
  )
}

const useEventsStatisticsStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    height: '100%'
  }
}))

export const EventsStatistics: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const navigationItems = useNavigationItems()
  const [searchFilter, setSearchFilter] = useState<EventsStatisticsFilterInput>(
    DEFAULT_EVENT_STATISTICS_FILTER_INPUT
  )
  const [returnUrl, setReturnUrl] = useState<string>(
    routeTo.admin.eventsStatistics.index()
  )
  const {
    state: isDrawerOpen,
    setFalse: closeDrawer,
    setTrue: openDrawer
  } = useBooleanState(true)
  const hasPermissionToAccessDrawer =
    (P([PermissionCode.ReadSales]) || P([PermissionCode.ReadReservations])) &&
    P([PermissionCode.ReadAggregatedEventStatisticsByItemPrice])
  const handleExited = useCallback(
    () => history.push(returnUrl),
    [history, returnUrl]
  )
  const classes = useEventsStatisticsStyles()
  return (
    <Switch>
      <Route path={routeTo.admin.eventsStatistics.index()} exact>
        <Redirect to={navigationItems.overview.route} />
      </Route>
      <Route
        path={[
          routeTo.admin.eventsStatistics.overview(),
          routeTo.admin.eventsStatistics.turnovers(),
          routeTo.admin.eventsStatistics.revenues(),
          routeTo.admin.eventsStatistics.availability(),
          routeTo.admin.eventsStatistics.ticketsChecking()
        ]}
      >
        <PageWithHeaderTemplate
          header={
            <PrimaryHeader
              title={t('Events statistics')}
              search={
                <EventsStatisticsSearch onFilterChange={setSearchFilter} />
              }
            />
          }
        >
          <ChildrenOnEffectiveClientSelected>
            <div className={classes.root}>
              <PersistentDrawerWithContent
                content={
                  <DrawerContent
                    searchFilter={searchFilter}
                    setReturnUrl={setReturnUrl}
                  />
                }
                drawerContent={<DrawerMenu />}
                isOpen={isDrawerOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
              />
            </div>
          </ChildrenOnEffectiveClientSelected>
        </PageWithHeaderTemplate>
      </Route>
      {hasPermissionToAccessDrawer && (
        <Route path={routeTo.admin.eventsStatistics.eventInfo(':eventId')}>
          <EventSalesPersistentDrawer onExited={handleExited} />
        </Route>
      )}
    </Switch>
  )
}
