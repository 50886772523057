import {useTranslation} from 'react-i18next'
import {CheckoutPaymentMethodsViewMode} from '../types'

export const useTranslateCheckoutPaymentMethodsViewMode = () => {
  const {t} = useTranslation()
  const translated: Record<CheckoutPaymentMethodsViewMode, string> = {
    [CheckoutPaymentMethodsViewMode.ExpandedFirstGroup]: t(
      'CheckoutPaymentMethodsViewMode->ExpandedFirstGroup'
    ),
    [CheckoutPaymentMethodsViewMode.DoNotExpandAnyGroup]: t(
      'CheckoutPaymentMethodsViewMode->DoNotExpandAnyGroup'
    )
  }
  return (mode: CheckoutPaymentMethodsViewMode) => translated[mode]
}
