import ReplayIcon from '@mui/icons-material/Replay'
import {Box, Button, Divider, MenuItem} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connectToQz, useCheckQzTray, useGetPrinters} from '../../../../qz'
import {InputBlockWithoutSpacings, RenderOnData} from '../../../common'
import {RowWithSelect} from './RowWithSelect'
import {RowWithSwitch} from './RowWithSwitch'
import {
  DeviceSettingsAction,
  DeviceSettingsActionTypes,
  DeviceSettingsState
} from './types'

interface IDirectTicketPrintProps {
  blockId: string
  blockLabel: string
  settings: DeviceSettingsState
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const DirectTicketPrint: React.FC<IDirectTicketPrintProps> = ({
  blockId,
  blockLabel,
  settings,
  dispatch
}: IDirectTicketPrintProps) => {
  const {t} = useTranslation()
  const {directTicketPrint: directTicketPrintSettings} = settings
  const {enabled, printer} = directTicketPrintSettings
  const {isActive, reCheck} = useCheckQzTray()
  const {printers, loading, error} = useGetPrinters(!isActive)
  return (
    <InputBlockWithoutSpacings
      blockId={blockId}
      header={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          {blockLabel}
          {!isActive && (
            <Button
              variant="text"
              color="primary"
              onClick={async () => {
                await connectToQz()
                reCheck()
              }}
              startIcon={<ReplayIcon />}
            >
              {t('Reconnect')}
            </Button>
          )}
        </Box>
      }
      headerSx={{width: '100%'}}
    >
      <RowWithSwitch
        primaryLabel={t('Enable direct ticket print on this device?')}
        secondaryLabel={!isActive ? t('QZ tray is not running') : undefined}
        disabled={!isActive}
        secondaryLabelColor={!isActive ? 'error' : undefined}
        switchProps={{
          checked: enabled,
          onChange: (event) =>
            dispatch({
              type: DeviceSettingsActionTypes.ChangeDirectTicketPrintSettings,
              payload: {
                ...directTicketPrintSettings,
                ...(event.target.checked
                  ? {}
                  : {
                      enabled: false,
                      printer: null
                    }),
                enabled: event.target.checked
              }
            })
        }}
      />
      {enabled && isActive && (
        <RenderOnData<string[]>
          data={printers}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading printers')}
        >
          {(printers) => (
            <>
              <Divider />
              <RowWithSelect
                primaryLabel={t('Select printer')}
                secondaryLabel={t('* Required.')}
                selectProps={{
                  value: printer ?? '',
                  onChange: (event) =>
                    dispatch({
                      type: DeviceSettingsActionTypes.ChangeDirectTicketPrintSettings,
                      payload: {
                        ...directTicketPrintSettings,
                        printer: event.target.value as string
                      }
                    }),
                  children: (printers || []).map((printer) => (
                    <MenuItem key={printer} value={printer}>
                      {printer}
                    </MenuItem>
                  ))
                }}
              />
            </>
          )}
        </RenderOnData>
      )}
    </InputBlockWithoutSpacings>
  )
}
