import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, RouteComponentProps, useHistory} from 'react-router-dom'
import {
  ReservationDetailQuery,
  ReservationState,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {
  RESERVATION_PARAMS,
  useReservationPathnameParams
} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {PageWithHeaderTemplate} from '../../../../common/PageWithHeaderTemplate'
import {ReservationDetailDrawer} from '../../components/reservationDetailDrawer'
import {SecondaryHeader} from '../../Header'
import {
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {useReservation} from '../graphql'
import {ChildrenOnValidData} from './ChildrenOnValidData'
import {Content} from './Content'
import {RenderOnEventSeats} from './RenderOnEventSeats'
import {TourTimeSlotContent} from './TourTimeSlotContent'

const reservationDrawerPaths = {
  initialPath: routeTo.admin.cashDesk.reservationPreview(
    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
  ),
  detailPath: routeTo.admin.cashDesk.reservationPreviewWithReservationInfo(
    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
  ),
  editPath: routeTo.admin.cashDesk.reservationPreviewWithReservationInfoEdit(
    toPlaceholderParam(RESERVATION_PARAMS.RESERVATION_ID)
  )
}

export const ReservationPreview: React.FC<RouteComponentProps> = () => {
  const {reservationId} = useReservationPathnameParams()
  const {t} = useTranslation()
  const {data, loading, error, refetch} = useReservation(reservationId)
  const [title, setTitle] = useState<string>(
    t<string>('Reservation with id {{reservationId}}', {
      reservationId
    })
  )
  useEffect(() => {
    if (
      data &&
      data.reservation.lead?.data &&
      (data.reservation.lead?.data.name || data.reservation.lead?.data.phone)
    ) {
      setTitle(
        [
          data.reservation.id,
          data.reservation.lead?.data.name,
          data.reservation.lead?.data.phone
        ]
          .filter(Boolean)
          .join(' • ')
      )
    }
  }, [data])
  const history = useHistory()
  const handleReservationDelete = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.reservations())
  }, [history])

  const {currentCart} = useCurrentCart()

  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader title={title} hasArrowBackIcon isDatetimeEnabled />
      }
    >
      <RenderOnData
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Could not load reservation detail data')}
        dataCondition={(data) => Boolean(data.reservation)}
      >
        {({reservation}: ReservationDetailQuery) => (
          <>
            {reservation.event ? (
              <RenderOnEventSeats
                eventId={reservation.event.id}
                reservationId={reservationId}
              >
                {(eventSeatsData) => (
                  <ChildrenOnValidData
                    reservationState={reservation.state}
                    isReservationInCurrentCart={
                      reservation.state === ReservationState.InCart &&
                      !!currentCart?.items
                        ?.filter<TicketItemPropertiesFragment>(
                          isTicketItemPropertiesFragment
                        )
                        .find(
                          (item) =>
                            item.reservation?.id === data?.reservation.id
                        )
                    }
                  >
                    <Content
                      reservation={{...reservation, event: reservation.event!}}
                      eventSeats={eventSeatsData.eventSeats}
                      refetch={refetch}
                    />
                  </ChildrenOnValidData>
                )}
              </RenderOnEventSeats>
            ) : reservation.tourTimeSlot ? (
              <ChildrenOnValidData
                reservationState={reservation.state}
                isReservationInCurrentCart={
                  reservation.state === ReservationState.InCart &&
                  !!currentCart?.items
                    ?.filter(isTourItemPropertiesFragment)
                    .find(
                      (item) => item.reservation?.id === data?.reservation.id
                    )
                }
              >
                <TourTimeSlotContent
                  reservation={reservation}
                  tourTimeSlot={reservation.tourTimeSlot}
                  refetch={refetch}
                />
              </ChildrenOnValidData>
            ) : null}
            <Route path={Object.values(reservationDrawerPaths)} exact>
              <ReservationDetailDrawer
                {...reservationDrawerPaths}
                onReservationDelete={handleReservationDelete}
              />
            </Route>
          </>
        )}
      </RenderOnData>
    </PageWithHeaderTemplate>
  )
}
