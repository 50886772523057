import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {useCreateAdmissionRate} from '../graphql'
import {AdmissionRateFormField, IAdmissionRateForm} from '../types'
import {transformAdmissionRateFormToInput} from '../utils'
import {AdmissionRateForm} from './AdmissionRateForm'

const CREATE_ADMISSION_RATE_FORM_ID = 'createAdmissionRateForm'

interface ICreateAdmissionRateDrawerProps {
  isOpen: boolean
  onClose: () => void
  tourId: number
}

export const CreateAdmissionRateDrawer: React.FC<ICreateAdmissionRateDrawerProps> =
  ({isOpen, onClose, tourId}: ICreateAdmissionRateDrawerProps) => {
    const {t} = useTranslation()
    const createAdmissionRate = useCreateAdmissionRate()
    const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
      useMutationAssistanceHooks()
    const history = useHistory()
    const handleSubmit = useCallback(
      async (formData: IAdmissionRateForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await createAdmissionRate(
            transformAdmissionRateFormToInput(formData, tourId)
          )
          addInfoNotification(t('Admission rate created'))
          if (data) {
            history.push(
              routeTo.admin.tours.admissionRateDetail(
                tourId,
                data.createAdmissionRate.id
              )
            )
          }
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating admission rate'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        history,
        addInfoNotification,
        createAdmissionRate,
        defaultErrorHandler,
        setShowBackdrop,
        t,
        tourId
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create admission rate')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_ADMISSION_RATE_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          <AdmissionRateForm
            formId={CREATE_ADMISSION_RATE_FORM_ID}
            onSubmit={handleSubmit}
            defaultValues={{[AdmissionRateFormField.Color]: '#999999'}}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
