import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {CarouselSlideRenderControlProps} from 'nuka-carousel'
import React from 'react'

const HEADER_HEIGHT = 64

const useStyles = makeStyles(() => ({
  root: {
    height: `calc(100vh - ${HEADER_HEIGHT * 2}px)`,
    width: '30vw',
    display: 'grid',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover>$controlArrow': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  },
  controlArrow: {
    width: 48,
    height: 48,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  },
  controlArrowRight: {
    justifySelf: 'flex-end'
  }
}))

interface ICenterLeftControlsProps
  extends Pick<
    CarouselSlideRenderControlProps,
    'previousSlide' | 'currentSlide'
  > {}

export const CenterLeftControls: React.FC<ICenterLeftControlsProps> = ({
  previousSlide,
  currentSlide
}: ICenterLeftControlsProps) => {
  const classes = useStyles()
  return currentSlide !== 0 ? (
    <div className={classes.root} onClick={previousSlide}>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className={classes.controlArrow}
        onClick={previousSlide}
      >
        <NavigateBeforeIcon />
      </IconButton>
    </div>
  ) : null
}

interface ICenterRightControlsProps
  extends Pick<
    CarouselSlideRenderControlProps,
    'nextSlide' | 'currentSlide' | 'slideCount'
  > {}

export const CenterRightControls: React.FC<ICenterRightControlsProps> = ({
  nextSlide: onNextButtonClick,
  currentSlide,
  slideCount
}: ICenterRightControlsProps) => {
  const classes = useStyles()
  return currentSlide < slideCount - 1 ? (
    <div className={classes.root} onClick={onNextButtonClick}>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className={cn(classes.controlArrow, classes.controlArrowRight)}
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  ) : null
}
