import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  Button,
  IconButton,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import {gridClasses, GridRowModes} from '@mui/x-data-grid'
import {
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModesModel,
  GridValueSetterParams
} from '@mui/x-data-grid-pro'
import isEqual from 'lodash/isEqual'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BaseWarehouseDocumentFieldsFragment,
  ErrorMessages,
  ExistingCountryCode,
  PermissionCode,
  WarehouseDocumentState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../../hooks/translateCurrencies'
import {useTranslateUnit} from '../../../../../hooks/translateUnit'
import {useEnsurePermissions, useUserInfo} from '../../../../../utils/auth'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../utils/errors'
import {
  useIsNonNegativeDecimal,
  useIsPositiveDecimal
} from '../../../../../utils/formsValidations'
import {Tooltip} from '../../../../common'
import {
  DataGridTable,
  useDecimalFormatter,
  usePercentageFormatter
} from '../../../../common/DataGridTable'
import {COLOR_CONF} from '../../../../constants'
import {AddWarehouseProductDrawer} from '../../components/addWarehouseProductDrawer'
import {getCellClassName} from '../../eventsStatistics/Miscellaneous'
import {useCountryVatRate} from '../../graphql'
import {replaceCommaWithDot} from '../../tours/tourDetail/admissionRateDetail/utils'
import {
  useAddItemToWarehouseDocument,
  useRemoveItemFromWarehouseDocument,
  useUpdateDraftWarehouseDocument
} from '../graphql'
import {
  transformAddWarehouseProductFormToAddItemToWarehouseDocumentInput,
  transformRowUpdateToUpdateDraftWarehouseDocumentItems
} from '../utils'
import {IAddWarehouseProductForm} from './types'

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
  <MuiTooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}))

const DeleteCellRenderer = ({
  itemId,
  warehouseDocumentId,
  isRowPinned
}: {
  itemId: number
  warehouseDocumentId: number
  isRowPinned: boolean
}) => {
  const {t} = useTranslation()
  const removeItemFromWarehouseDocument = useRemoveItemFromWarehouseDocument()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const handleButtonClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await removeItemFromWarehouseDocument({
        itemId,
        documentId: warehouseDocumentId
      })
    } catch (error) {
      defaultErrorHandler(
        error,
        t('Error while removing item from warehouse document ')
      )
    } finally {
      setShowBackdrop(false)
    }
  }, [
    defaultErrorHandler,
    itemId,
    removeItemFromWarehouseDocument,
    setShowBackdrop,
    t,
    warehouseDocumentId
  ])
  return !isRowPinned ? (
    <Tooltip title={t('Remove')}>
      <IconButton size="small" color="primary" onClick={handleButtonClick}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  ) : null
}

interface IItemsProps {
  warehouseDocument: BaseWarehouseDocumentFieldsFragment
  refetch: () => void
}

export const Items: React.FC<IItemsProps> = ({
  warehouseDocument,
  refetch
}: IItemsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {effectiveClient} = useUserInfo()
  const {
    data: countryVatRateData,
    error: countryVatRateError,
    loading: isCountryVatRateLoading
  } = useCountryVatRate(effectiveClient?.countryCode || ExistingCountryCode.Sk)
  const addItemToWarehouseDocument = useAddItemToWarehouseDocument()
  const removeItemFromWarehouseDocument = useRemoveItemFromWarehouseDocument()
  const updateDraftWarehouseDocument = useUpdateDraftWarehouseDocument()
  const {
    addInfoNotification,
    setShowBackdrop,
    defaultErrorHandler,
    customErrorHandler
  } = useMutationAssistanceHooks()
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const translateUnit = useTranslateUnit()
  const percentageFormatter = usePercentageFormatter(0)
  const isNonNegativeDecimal = useIsNonNegativeDecimal('1,8')
  const isPositiveDecimal6 = useIsPositiveDecimal('1,6')
  const decimalFormatter = useDecimalFormatter(8)
  const decimalFormatter6 = useDecimalFormatter(6)
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const {
    state: isAddWarehouseProductDrawerOpen,
    setTrue: openAddWarehouseProductDrawer,
    setFalse: closeAddWarehouseProductDrawer
  } = useBooleanState(false)
  const {id: warehouseDocumentId} = warehouseDocument
  const handleAddWarehouseProduct = useCallback(
    async (product: IAddWarehouseProductForm) => {
      try {
        setShowBackdrop(true)
        await addItemToWarehouseDocument({
          input:
            transformAddWarehouseProductFormToAddItemToWarehouseDocumentInput(
              product,
              warehouseDocumentId
            )
        })
        addInfoNotification(t('Product has been added'))
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.BelowWarehouseProductStockLevel
          )
        ) {
          customErrorHandler(error, {
            title: t('Invalid product quantity'),
            contentText: t(
              'You attempted to dispatch a higher quantity of product than are currently available in stock. Please adjust the quantity or check back later when more stock is available.'
            ),
            confirmButtonLabel: t('Got it')
          })
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.InvalidProductMode
          )
        ) {
          customErrorHandler(error, {
            title: t('Invalid product mode'),
            contentText: t(
              "You are attempting to perform an action that is not allowed with the selected item(s). Items must be in mode 'Inventory item' to be added to warehouse document. Please verify the product mode of your items and make the necessary adjustments. If you need further assistance, contact your system administrator or refer to the help documentation."
            ),
            confirmButtonLabel: t('Got it'),
            onConfirm: () => window.location.reload()
          })
        } else {
          defaultErrorHandler(
            error,
            t('Error while adding item to warehouse document')
          )
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      addItemToWarehouseDocument,
      customErrorHandler,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      warehouseDocumentId
    ]
  )

  const handleDeleteFromDrawer = useCallback(
    async (ids: number[]) => {
      try {
        setShowBackdrop(true)
        await Promise.allSettled(
          ids.map((id) =>
            removeItemFromWarehouseDocument({
              itemId: id,
              documentId: warehouseDocumentId
            })
          )
        )
        refetch()
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while removing item from warehouse document ')
        )
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      defaultErrorHandler,
      refetch,
      removeItemFromWarehouseDocument,
      setShowBackdrop,
      t,
      warehouseDocumentId
    ]
  )
  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevState) => ({
        ...prevState,
        [id]: {mode: GridRowModes.Edit}
      }))
    },
    []
  )
  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel((prevState) => ({
        ...prevState,
        [id]: {mode: GridRowModes.View}
      }))
    },
    []
  )
  const handleRowEditStop = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }, [])
  const processRowUpdate = useCallback(
    async (newRow) => {
      let updatedData: BaseWarehouseDocumentFieldsFragment['items'] = []
      const transformedRow = {
        ...newRow,
        id: newRow.id,
        note: newRow.note || null,
        vatRate: parseInt(newRow.vatRate, 10),
        quantity: convertValueToFloat(newRow.quantity) || 0,
        unitPriceVatExcluded:
          convertValueToFloat(newRow.unitPriceVatExcluded) || 0
      }
      const updatedRows = warehouseDocument.items.map((item) =>
        item.id === newRow.id ? transformedRow : item
      )
      const shouldUpdate = !isEqual(
        warehouseDocument.items.find(({id}) => id === newRow.id),
        transformedRow
      )
      if (shouldUpdate) {
        try {
          setShowBackdrop(true)
          const {data} = await updateDraftWarehouseDocument({
            id: warehouseDocumentId,
            input: {
              items:
                transformRowUpdateToUpdateDraftWarehouseDocumentItems(
                  updatedRows
                )
            }
          })
          addInfoNotification(t('Item has been updated'))
          if (data) {
            updatedData = data.updateDraftWarehouseDocument.items
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while updating draft warehouse document')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
      return updatedData.find(({id}) => id === newRow.id) || newRow
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateDraftWarehouseDocument,
      warehouseDocument.items,
      warehouseDocumentId
    ]
  )
  const isColumnEditable =
    warehouseDocument.state === WarehouseDocumentState.Draft &&
    P([PermissionCode.UpdateDraftWarehouseDocument])
  useEffect(
    () =>
      isCountryVatRateLoading ? setShowBackdrop(true) : setShowBackdrop(false),
    [isCountryVatRateLoading, setShowBackdrop]
  )
  useEffect(() => {
    if (countryVatRateError) {
      defaultErrorHandler(
        countryVatRateError,
        t('Error while loading country VAT rates')
      )
    }
  }, [countryVatRateError, defaultErrorHandler, t])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Code'),
        field: 'internalCode',
        valueGetter: (params) =>
          params.rowNode.type === 'pinnedRow'
            ? params.value
            : params.row.warehouseProduct.product.internalCode,
        minWidth: 100,
        cellClassName: getCellClassName,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Name'),
        field: 'name',
        valueGetter: (params) =>
          params.rowNode.type === 'pinnedRow'
            ? params.value
            : params.row.warehouseProduct.product.name,
        minWidth: 200,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Quantity'),
        field: 'quantity',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false,
        editable: isColumnEditable,
        valueGetter: decimalFormatter6,
        valueSetter: function setter(params: GridValueSetterParams) {
          return {
            ...params.row,
            quantity: parseFloat(
              replaceCommaWithDot(String(params.value))
            ).toFixed(6)
          }
        },
        preProcessEditCellProps: function processor(
          params: GridPreProcessEditCellProps
        ) {
          const error = params.props.value
            ? isPositiveDecimal6(params.props.value)
            : null
          return {...params.props, errormessage: error, error: !!error}
        },
        renderEditCell: function renderer(params: GridRenderEditCellParams) {
          return (
            <StyledTooltip open={params.error} title={params.errormessage}>
              <span>
                <GridEditInputCell
                  {...params}
                  sx={{'& .MuiInputBase-input': {textAlign: 'right'}}}
                />
              </span>
            </StyledTooltip>
          )
        }
      },
      {
        headerName: t('Unit'),
        field: 'unit',
        valueGetter: (params) =>
          params.rowNode.type === 'pinnedRow'
            ? params.value
            : params.row.warehouseProduct.product.unit,
        valueFormatter: (params) =>
          params.value ? translateUnit(params.value) : '',
        minWidth: 50,
        sortable: false,
        editable: false
      },
      {
        headerName: t('VAT rate'),
        field: 'vatRate',
        valueFormatter: percentageFormatter,
        type: 'singleSelect',
        align: 'right',
        headerAlign: 'right',
        valueOptions: countryVatRateData?.countryVatRates.map((vat) => vat),
        minWidth: 100,
        editable: isColumnEditable,
        sortable: false
      },
      {
        headerName: t('VAT exc. ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'unitPriceVatExcluded',
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        editable: isColumnEditable,
        sortable: false,
        valueGetter: decimalFormatter,
        valueSetter: function setter(params: GridValueSetterParams) {
          return {
            ...params.row,
            unitPriceVatExcluded: parseFloat(
              replaceCommaWithDot(String(params.value))
            ).toFixed(8)
          }
        },
        preProcessEditCellProps: function processor(
          params: GridPreProcessEditCellProps
        ) {
          const error = isNonNegativeDecimal(params.props.value)
          return {...params.props, errormessage: error, error: !!error}
        },
        renderEditCell: function renderer(params: GridRenderEditCellParams) {
          return (
            <StyledTooltip open={params.error} title={params.errormessage}>
              <span>
                <GridEditInputCell
                  {...params}
                  sx={{
                    '& .MuiInputBase-input': {
                      textAlign: 'right'
                    }
                  }}
                />
              </span>
            </StyledTooltip>
          )
        }
      },
      {
        headerName: t('Vat inc. ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'unitPriceVatIncluded',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatter,
        minWidth: 150,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Vat exc. ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'priceVatExcluded',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatter,
        cellClassName: getCellClassName,
        minWidth: 150,
        sortable: false,
        editable: false
      },
      {
        headerName: t('VAT ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'vat',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatter,
        cellClassName: getCellClassName,
        minWidth: 150,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Vat inc. ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'priceVatIncluded',
        align: 'right',
        headerAlign: 'right',
        valueFormatter: decimalFormatter,
        cellClassName: getCellClassName,
        minWidth: 150,
        sortable: false,
        editable: false
      },
      {
        headerName: t('Note'),
        field: 'note',
        minWidth: 250,
        editable: isColumnEditable,
        sortable: false
      },
      {
        headerName: '',
        field: 'action',
        editable: false,
        renderCell: function renderer(params: GridRenderCellParams) {
          const isInEditMode =
            rowModesModel[params.id]?.mode === GridRowModes.Edit
          return params.rowNode.type !== 'pinnedRow' ? (
            isInEditMode ? (
              <IconButton color="primary" onClick={handleSaveClick(params.id)}>
                <CheckCircleIcon />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={handleEditClick(params.id)}>
                <EditIcon />
              </IconButton>
            )
          ) : null
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      },
      {
        headerName: '',
        field: 'id',
        editable: false,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <DeleteCellRenderer
              itemId={params.value}
              warehouseDocumentId={warehouseDocumentId}
              isRowPinned={params.rowNode.type === 'pinnedRow'}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [
      isColumnEditable,
      countryVatRateData?.countryVatRates,
      decimalFormatter,
      decimalFormatter6,
      handleEditClick,
      handleSaveClick,
      isNonNegativeDecimal,
      isPositiveDecimal6,
      percentageFormatter,
      rowModesModel,
      t,
      translateUnit,
      translatedEffectiveClientCurrencySign,
      warehouseDocumentId
    ]
  )
  return (
    <Box>
      <Box
        sx={{
          pb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">{t('Items')}</Typography>
        {P([PermissionCode.AddItemToWarehouseDocument]) &&
          warehouseDocument.state === WarehouseDocumentState.Draft && (
            <Button
              startIcon={<AddIcon />}
              variant="text"
              color="primary"
              onClick={openAddWarehouseProductDrawer}
            >
              {t('Add')}
            </Button>
          )}
      </Box>
      <DataGridTable
        columns={columns}
        rows={warehouseDocument.items}
        pagination={false}
        disableColumnMenu
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: ['internalCode', 'name'],
            right: ['action', 'id']
          }
        }}
        experimentalFeatures={{columnGrouping: true}}
        columnGroupingModel={[
          {
            groupId: 'product',
            headerName: t('Product'),
            children: [
              {field: 'internalCode'},
              {field: 'name'},
              {field: 'quantity'},
              {field: 'unit'},
              {field: 'vatRate'}
            ]
          },
          {
            groupId: 'unitPrice',
            headerName: t('Unit price'),
            children: [
              {field: 'unitPriceVatExcluded'},
              {field: 'unitPriceVatIncluded'}
            ]
          },
          {
            groupId: 'totalPrice',
            headerName: t('Total price'),
            children: [
              {field: 'priceVatExcluded'},
              {field: 'vat'},
              {field: 'priceVatIncluded'}
            ]
          }
        ]}
        isCellEditable={(params) => params.rowNode.type !== 'pinnedRow'}
        hideFooter
        sx={{
          '& .bold': {fontWeight: 'bold'},
          [`.${gridClasses.main}`]: {
            overflow: 'unset'
          },
          [`&& .${gridClasses['cell--editing']}`]: {
            backgroundColor: COLOR_CONF.BLUE.background
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'background.paper',
            zIndex: 1
          },
          [`& .${gridClasses.withBorderColor}`]: {
            borderColor: 'divider'
          },
          [`& .${gridClasses.columnSeparator}`]: {
            color: 'divider'
          }
        }}
        columnHeaderHeight={32}
        autoHeight
        localeText={{noRowsLabel: t('No items to show')}}
        columnVisibilityModel={{
          action:
            warehouseDocument.state === WarehouseDocumentState.Draft &&
            P([PermissionCode.UpdateDraftWarehouseDocument]),
          id: warehouseDocument.state === WarehouseDocumentState.Draft
        }}
        pinnedRows={{
          bottom: [
            {
              id: 0,
              internalCode: t('{{count}} items', {
                count: warehouseDocument.items.length
              }),
              priceVatExcluded: warehouseDocument.sumPriceVatExcluded,
              vat: warehouseDocument.sumVat,
              priceVatIncluded: warehouseDocument.sumPriceVatIncluded
            }
          ]
        }}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={setRowModesModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
      <AddWarehouseProductDrawer
        isOpen={isAddWarehouseProductDrawerOpen}
        onClose={closeAddWarehouseProductDrawer}
        warehouseId={warehouseDocument.warehouse.id}
        onSubmit={handleAddWarehouseProduct}
        onDelete={handleDeleteFromDrawer}
        warehouseDocumentType={warehouseDocument.type}
        addedProducts={warehouseDocument.items.map((item) => ({
          id: item.id,
          warehouseProductId: item.warehouseProduct.id,
          quantity: item.quantity
        }))}
      />
    </Box>
  )
}
