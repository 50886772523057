import {noop} from 'lodash'
import React, {createContext, useContext} from 'react'
import {
  DiscountAuthorizationMode,
  PassCodeCheckInputType
} from '../../../../__generated__/schema'
import {useLocalStorageState} from '../../../../hooks/storage'
import {
  CheckingMethod,
  DeviceEffect,
  IAdvancedCheckingSettings,
  PauseAfterScan
} from './types'

interface IAdvancedSettingsContext {
  settings: IAdvancedCheckingSettings
  setSettings: (settings: IAdvancedCheckingSettings) => void
}

const defaultSettings: IAdvancedCheckingSettings = {
  discountAuthorization: DiscountAuthorizationMode.ECommerceOnly,
  defaultInputType: PassCodeCheckInputType.Scanned,
  pauseAfterScan: PauseAfterScan.For1000ms,
  allowedDeviceEffect: DeviceEffect.Sound,
  deniedDeviceEffect: DeviceEffect.Sound,
  actionRequiredDeviceEffect: DeviceEffect.Sound,
  enabledDivisions: [],
  checkingMethod: CheckingMethod.SingleTicket
}

const AdvancedSettingsContext = createContext<IAdvancedSettingsContext>({
  settings: defaultSettings,
  setSettings: noop
})

interface IAdvancedSettingsContextProviderProps {
  children: React.ReactNode
}

export const AdvancedSettingsContextProvider: React.FC<IAdvancedSettingsContextProviderProps> =
  ({children}: IAdvancedSettingsContextProviderProps) => {
    const [settings, setSettings] =
      useLocalStorageState<IAdvancedCheckingSettings>(
        'advancedCheckingSettings',
        defaultSettings
      )
    return (
      <AdvancedSettingsContext.Provider
        value={{
          settings,
          setSettings
        }}
      >
        {children}
      </AdvancedSettingsContext.Provider>
    )
  }

export const useAdvancedSettings = () => useContext(AdvancedSettingsContext)
