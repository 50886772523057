import {FeSeatState} from '@attendio/shared-components'
import {ApiSeatState} from '../__generated__/schema'

export const getFeSeatStateInEventSeatOption = (
  apiSeatState?: ApiSeatState
): FeSeatState => {
  switch (apiSeatState) {
    case ApiSeatState.Available:
      return FeSeatState.Available
    case ApiSeatState.SelectedByOthers:
    case ApiSeatState.AddedToOtherCart:
    case ApiSeatState.AddedToMyCart:
      return FeSeatState.Occupied
    case ApiSeatState.PreSold:
      return FeSeatState.PreSold
    case ApiSeatState.Sold:
      return FeSeatState.Sold
    case ApiSeatState.ReservedInMyCart:
    case ApiSeatState.InMyReservation:
    case ApiSeatState.InOtherReservation:
      return FeSeatState.Reserved
    case ApiSeatState.SelectedByMe:
      return FeSeatState.Selected
    case ApiSeatState.Hidden:
      return FeSeatState.Hidden
    case ApiSeatState.Disabled:
      return FeSeatState.Disabled
    default:
      return FeSeatState.Plain
  }
}

export const getFeSeatStateInEventAuditoriumPreview = (
  apiSeatState?: ApiSeatState
): FeSeatState => {
  switch (apiSeatState) {
    case ApiSeatState.Available:
      return FeSeatState.Available
    case ApiSeatState.AddedToMyCart:
      return FeSeatState.Selected
    case ApiSeatState.Disabled:
      return FeSeatState.Disabled
    case ApiSeatState.Hidden:
      return FeSeatState.Hidden
    case ApiSeatState.SelectedByOthers:
    case ApiSeatState.AddedToOtherCart:
    case ApiSeatState.SelectedByMe:
      return FeSeatState.Occupied
    case ApiSeatState.PreSold:
      return FeSeatState.PreSold
    case ApiSeatState.Sold:
      return FeSeatState.Sold
    case ApiSeatState.ReservedInMyCart:
    case ApiSeatState.InMyReservation:
    case ApiSeatState.InOtherReservation:
      return FeSeatState.Reserved
    default:
      return FeSeatState.Plain
  }
}

export const getFeSeatStateInReservationAuditoriumPreview = (
  apiSeatState?: ApiSeatState
): FeSeatState => {
  switch (apiSeatState) {
    case ApiSeatState.Available:
      return FeSeatState.Available
    case ApiSeatState.InMyReservation:
      return FeSeatState.Reserved
    case ApiSeatState.ReservedInMyCart:
      return FeSeatState.Selected
    case ApiSeatState.Disabled:
      return FeSeatState.Disabled
    case ApiSeatState.Hidden:
      return FeSeatState.Hidden
    case ApiSeatState.SelectedByOthers:
    case ApiSeatState.AddedToOtherCart:
    case ApiSeatState.SelectedByMe:
    case ApiSeatState.InOtherReservation:
    case ApiSeatState.AddedToMyCart:
    case ApiSeatState.Sold:
    case ApiSeatState.PreSold:
      return FeSeatState.UnAvailable
    default:
      return FeSeatState.Plain
  }
}
