import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {PaymentMethodType} from '../../../../../__generated__/schema'
import {Theme} from '../../../../../theme'
import {AddIntentFrame} from './AddIntentFrame'
import {IntentCountFormController} from './IntentCountFormController'
import {IntentTextFieldWithChipsFrame} from './IntentTextFieldWithChipsFrame'

import {IAddIntentFrame, IIntentFrame, IntentFrameComponentType} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  intentCountFormController: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2)
  }
}))

interface IIntentFrameProps {
  intentFrame: IIntentFrame
  structureKey: number | PaymentMethodType
  className?: string
}

export const IntentFrame: React.FC<IIntentFrameProps> = ({
  intentFrame,
  structureKey,
  className
}: IIntentFrameProps) => {
  const classes = useStyles()
  switch (intentFrame.componentType) {
    case IntentFrameComponentType.IntentTextFieldWithChipsFrame:
      return (
        <IntentTextFieldWithChipsFrame
          intentFrame={intentFrame}
          className={className}
        />
      )
    case IntentFrameComponentType.IntentCountFrame:
      return (
        <IntentCountFormController
          intentFrame={intentFrame}
          className={cn(classes.intentCountFormController, className)}
        />
      )
    case IntentFrameComponentType.AddIntentFrame:
      return (
        <AddIntentFrame
          addIntentFrame={intentFrame as IAddIntentFrame}
          structureKey={structureKey}
          className={className}
        />
      )
    case IntentFrameComponentType.None:
    default:
      return (
        <div>
          None - should not happen
          <div>{JSON.stringify(intentFrame)}</div>
        </div>
      )
  }
}
