import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {AuditoriumLayoutType} from '../../../../../__generated__/schema'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {FormInput} from '../../../../visual'
import {useEditorFormStyles} from '../editorUtils'

import {useAuditoriumLayoutTypeToString} from './common'

export const AUDITORIUM_LAYOUT_FORM_ID = 'auditorium layout editor form'

enum FormField {
  NAME = 'name',
  TYPE = 'type'
}

export interface IEditorFormData {
  name: string
  type: AuditoriumLayoutType
}

interface IEditorFormProps {
  onSubmit: (data: IEditorFormData) => Promise<void>
}

export const EditorForm: React.FC<IEditorFormProps> = ({
  onSubmit
}: IEditorFormProps) => {
  const {t} = useTranslation()
  const classes = useEditorFormStyles()
  const auditoriumLayoutTypeToString = useAuditoriumLayoutTypeToString()
  const {handleSubmit, watch, register, setValue, errors} =
    useFormContext<IEditorFormData>()

  return (
    <form
      noValidate
      autoComplete="off"
      className={classes.wrapper}
      onSubmit={handleSubmit(onSubmit)}
      id={AUDITORIUM_LAYOUT_FORM_ID}
    >
      <div className={classes.formItem}>
        <FormInput
          label={t('Name', {context: 'object'})}
          placeholder={t('Name', {context: 'object'})}
          name={FormField.NAME}
          type="text"
          showRequiredLabel
          inputRef={register({required: true})}
        />
      </div>
      <div className={classes.formItem}>
        <UncontrolledFormSelect
          fullWidth
          name={FormField.TYPE}
          label={t('Type')}
          selectOptions={{
            [AuditoriumLayoutType.SeatingFloorPlan]:
              auditoriumLayoutTypeToString(
                AuditoriumLayoutType.SeatingFloorPlan
              ),
            [AuditoriumLayoutType.ZoneFloor]: auditoriumLayoutTypeToString(
              AuditoriumLayoutType.ZoneFloor
            )
          }}
          disabled
          setValue={setValue}
          errors={errors}
          register={register}
          watch={watch}
        />
      </div>
    </form>
  )
}
