import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {uniqBy} from 'lodash'
import {useCallback} from 'react'
import {
  ClientFilesQuery,
  ClientFilesQueryVariables,
  CreateWebsiteMutation,
  CreateWebsiteMutationVariables,
  CreateWebsitePageMutation,
  CreateWebsitePageMutationVariables,
  DeleteClientFileMutation,
  DeleteClientFileMutationVariables,
  DeleteWebsitePageMutation,
  DeleteWebsitePageMutationVariables,
  UpdateWebsiteCommonSettingsMutation,
  UpdateWebsiteCommonSettingsMutationVariables,
  UpdateWebsiteFooterMutation,
  UpdateWebsiteFooterMutationVariables,
  UpdateWebsiteHeaderMutation,
  UpdateWebsiteHeaderMutationVariables,
  UpdateWebsiteMutation,
  UpdateWebsiteMutationVariables,
  UpdateWebsitePageBlocksMutation,
  UpdateWebsitePageBlocksMutationVariables,
  UpdateWebsitePageMutation,
  UpdateWebsitePageMutationVariables,
  UploadClientFileMutation,
  UploadClientFileMutationVariables,
  WebsiteCommonSettingsQuery,
  WebsiteCommonSettingsQueryVariables,
  WebsiteForEditQuery,
  WebsiteForEditQueryVariables,
  WebsiteQuery,
  WebsiteQueryVariables,
  WebsitesQuery,
  WebsitesQueryVariables
} from '../../../../__generated__/schema'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../utils/pagination'
import {PAGINATION_FRAGMENT} from '../../graphql'

const WEBSITES = gql`
  ${PAGINATION_FRAGMENT}
  query websites($filter: WebsitesFilter, $paginationInput: PaginationInput!) {
    websites(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
        state
        primaryDomain
        productionBranch
        projectFullPath
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useWebsites = (variables: WebsitesQueryVariables) =>
  useQueryWithPagination<WebsitesQuery, WebsitesQueryVariables>(
    WEBSITES,
    {fetchPolicy: 'network-only', variables},
    {
      mapPaginationInput: (data) =>
        extractPaginationInput(data.websites.pagination),
      updateData: (prevData, fetchMoreResult) => ({
        ...fetchMoreResult,
        websites: {
          ...fetchMoreResult.websites,
          items: uniqBy(
            [...prevData.websites.items, ...fetchMoreResult.websites.items],
            ({id}) => id
          )
        }
      })
    }
  )

const CREATE_WEBSITE = gql`
  mutation createWebsite($input: WebsiteInput!) {
    createWebsite(input: $input) {
      id
    }
  }
`

export const useCreateWebsite = () => {
  const [createWebsite] =
    useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(
      CREATE_WEBSITE
    )
  return useCallback(
    (variables: CreateWebsiteMutationVariables) => createWebsite({variables}),
    [createWebsite]
  )
}

const WEBSITE = gql`
  query website($id: PositiveInt!) {
    website(id: $id) {
      id
      name
      primaryDomain
      enabledLocaleCodes
    }
  }
`

export const useWebsite = (id: number, skip?: boolean) =>
  useQuery<WebsiteQuery, WebsiteQueryVariables>(WEBSITE, {
    variables: {id},
    fetchPolicy: 'network-only',
    skip
  })

const NARROW_WEBSITE_FIELDS = gql`
  fragment NarrowWebsiteFields on Website {
    id
    name
    primaryDomain
    enabledLocaleCodes
    projectFullPath
    productionBranch
    previewUrl
    state
    client {
      id
      name
      state
      localeCodes
    }
  }
`

const WEBSITE_FOR_EDIT = gql`
  ${NARROW_WEBSITE_FIELDS}
  query websiteForEdit($id: PositiveInt!) {
    website(id: $id) {
      ...NarrowWebsiteFields
    }
  }
`

export const useWebsiteForEdit = (id: number, skip?: boolean) =>
  useQuery<WebsiteForEditQuery, WebsiteForEditQueryVariables>(
    WEBSITE_FOR_EDIT,
    {
      variables: {id},
      fetchPolicy: 'network-only',
      skip
    }
  )

export const LIGHTWEIGHT_CLIENTS_WITH_LOCALE_CODES = gql`
  query lightweightClientsWithLocaleCodes {
    clients {
      id
      name
      state
      localeCodes
    }
  }
`

const BASE_WEBSITE_PAGE_FIELDS = gql`
  fragment BaseWebsitePageFields on WebsitePage {
    ID
    title
    state
    slug
    blocks
    localeCode
    description
    type
  }
`

export const WEBSITE_PAGES = gql`
  ${BASE_WEBSITE_PAGE_FIELDS}
  query websitePages($websiteId: PositiveInt!, $filter: WebsitesPageFilter) {
    websitePages(websiteId: $websiteId, filter: $filter) {
      ...BaseWebsitePageFields
    }
  }
`

const DELETE_WEBSITE_PAGE = gql`
  mutation deleteWebsitePage(
    $pageID: NonEmptyString!
    $websiteId: PositiveInt!
  ) {
    deleteWebsitePage(pageID: $pageID, websiteId: $websiteId)
  }
`

export const useDeleteWebsitePage = () => {
  const [deleteWebsitePage] =
    useMutation<DeleteWebsitePageMutation, DeleteWebsitePageMutationVariables>(
      DELETE_WEBSITE_PAGE
    )
  return useCallback(
    (variables: DeleteWebsitePageMutationVariables) =>
      deleteWebsitePage({variables}),
    [deleteWebsitePage]
  )
}

const CREATE_WEBSITE_PAGE = gql`
  ${BASE_WEBSITE_PAGE_FIELDS}
  mutation createWebsitePage(
    $input: WebsitePageInput!
    $localeCode: LocaleCode!
    $websiteId: PositiveInt!
  ) {
    createWebsitePage(
      input: $input
      localeCode: $localeCode
      websiteId: $websiteId
    ) {
      ...BaseWebsitePageFields
    }
  }
`

export const useCreateWebsitePage = () => {
  const [createWebsitePage] =
    useMutation<CreateWebsitePageMutation, CreateWebsitePageMutationVariables>(
      CREATE_WEBSITE_PAGE
    )
  return useCallback(
    (variables: CreateWebsitePageMutationVariables) =>
      createWebsitePage({variables}),
    [createWebsitePage]
  )
}

const UPDATE_WEBSITE_PAGE = gql`
  ${BASE_WEBSITE_PAGE_FIELDS}
  mutation updateWebsitePage(
    $pageID: NonEmptyString!
    $websiteId: PositiveInt!
    $input: UpdateWebsitePageInput!
  ) {
    updateWebsitePage(pageID: $pageID, websiteId: $websiteId, input: $input) {
      ...BaseWebsitePageFields
    }
  }
`

export const useUpdateWebsitePage = () => {
  const [updateWebsitePage] =
    useMutation<UpdateWebsitePageMutation, UpdateWebsitePageMutationVariables>(
      UPDATE_WEBSITE_PAGE
    )
  return useCallback(
    (variables: UpdateWebsitePageMutationVariables) =>
      updateWebsitePage({variables}),
    [updateWebsitePage]
  )
}

const UPDATE_WEBSITE_PAGE_BLOCKS = gql`
  ${BASE_WEBSITE_PAGE_FIELDS}
  mutation updateWebsitePageBlocks(
    $pageID: NonEmptyString!
    $websiteId: PositiveInt!
    $blocks: JSON!
  ) {
    updateWebsitePageBlocks(
      pageID: $pageID
      websiteId: $websiteId
      blocks: $blocks
    ) {
      ...BaseWebsitePageFields
    }
  }
`

export const useUpdateWebsitPageBlocks = () => {
  const [updateWebsitePageBlocks] = useMutation<
    UpdateWebsitePageBlocksMutation,
    UpdateWebsitePageBlocksMutationVariables
  >(UPDATE_WEBSITE_PAGE_BLOCKS)
  return useCallback(
    (variables: UpdateWebsitePageBlocksMutationVariables) =>
      updateWebsitePageBlocks({variables}),
    [updateWebsitePageBlocks]
  )
}

const HEADER_LINK_ITEM_FIELDS = gql`
  fragment HeaderLinkItemField on HeaderLinkItem {
    label
    href
    target
  }
`

const HEADER_ITEMS_GROUP_FIELDS = gql`
  ${HEADER_LINK_ITEM_FIELDS}
  fragment HeaderItemsGroupFields on HeaderItemsGroup {
    label
    items {
      ... on HeaderLinkItem {
        ...HeaderLinkItemField
      }
      ... on HeaderItemsGroup {
        label
        items {
          ... on HeaderLinkItem {
            ...HeaderLinkItemField
          }
          ... on HeaderItemsGroup {
            label
            items {
              ... on HeaderLinkItem {
                ...HeaderLinkItemField
              }
              ... on HeaderItemsGroup {
                label
                items {
                  ... on HeaderLinkItem {
                    ...HeaderLinkItemField
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const BASE_WEBSITE_HEADER_FIELDS = gql`
  ${HEADER_LINK_ITEM_FIELDS}
  ${HEADER_ITEMS_GROUP_FIELDS}
  fragment BaseWebsiteHeaderFields on WebsiteHeader {
    ID
    localeCode
    items {
      ... on HeaderLinkItem {
        ...HeaderLinkItemField
      }
      ... on HeaderItemsGroup {
        ...HeaderItemsGroupFields
      }
    }
    logo {
      src
      alt
      width
      height
      phabletUp {
        width
        height
      }
    }
  }
`

export const WEBSITE_HEADERS = gql`
  ${BASE_WEBSITE_HEADER_FIELDS}
  query WebsiteHeaders(
    $websiteId: PositiveInt!
    $filter: WebsiteHeadersFilterInput
  ) {
    websiteHeaders(websiteId: $websiteId, filter: $filter) {
      ...BaseWebsiteHeaderFields
    }
  }
`

const UPDATE_WEBSITE_HEADER = gql`
  ${BASE_WEBSITE_HEADER_FIELDS}
  mutation UpdateWebsiteHeader(
    $headerID: NonEmptyString!
    $websiteId: PositiveInt!
    $input: UpdateWebsiteHeaderInput!
  ) {
    updateWebsiteHeader(
      headerID: $headerID
      websiteId: $websiteId
      input: $input
    ) {
      ...BaseWebsiteHeaderFields
    }
  }
`

export const useUpdateWebsiteHeader = () => {
  const [updateWebsiteHeader] = useMutation<
    UpdateWebsiteHeaderMutation,
    UpdateWebsiteHeaderMutationVariables
  >(UPDATE_WEBSITE_HEADER)
  return useCallback(
    (variables: UpdateWebsiteHeaderMutationVariables) =>
      updateWebsiteHeader({variables}),
    [updateWebsiteHeader]
  )
}

const BASE_WEBSITE_FOOTER_FIELDS = gql`
  fragment BaseWebsiteFooterFields on WebsiteFooter {
    ID
    localeCode
    itemGroups {
      items {
        label
        typographyVariant
        link
        linkTarget
        icon
      }
      alignment
    }
  }
`

export const WEBSITE_FOOTERS = gql`
  ${BASE_WEBSITE_FOOTER_FIELDS}
  query WebsiteFooters(
    $websiteId: PositiveInt!
    $filter: WebsiteFootersFilterInput
  ) {
    websiteFooters(websiteId: $websiteId, filter: $filter) {
      ...BaseWebsiteFooterFields
    }
  }
`

const UPDATE_WEBSITE_FOOTER = gql`
  ${BASE_WEBSITE_FOOTER_FIELDS}
  mutation UpdateWebsiteFooter(
    $footerID: NonEmptyString!
    $websiteId: PositiveInt!
    $input: UpdateWebsiteFooterInput!
  ) {
    updateWebsiteFooter(
      footerID: $footerID
      websiteId: $websiteId
      input: $input
    ) {
      ...BaseWebsiteFooterFields
    }
  }
`

export const useUpdateWebsiteFooter = () => {
  const [updateWebsiteFooter] = useMutation<
    UpdateWebsiteFooterMutation,
    UpdateWebsiteFooterMutationVariables
  >(UPDATE_WEBSITE_FOOTER)
  return useCallback(
    (variables: UpdateWebsiteFooterMutationVariables) =>
      updateWebsiteFooter({variables}),
    [updateWebsiteFooter]
  )
}

const CLIENT_FILES = gql`
  query ClientFiles {
    clientFiles {
      url
      fileName
    }
  }
`

export const useClientFiles = (skip?: boolean) =>
  useQuery<ClientFilesQuery, ClientFilesQueryVariables>(CLIENT_FILES, {
    fetchPolicy: 'network-only',
    skip
  })

const UPLOAD_CLIENT_FILE = gql`
  mutation UploadClientFile($filePath: NonEmptyString!, $file: Upload!) {
    uploadClientFile(filepath: $filePath, file: $file) {
      url
      fileName
    }
  }
`

export const useUploadClientFile = () => {
  const [uploadClientFile] =
    useMutation<UploadClientFileMutation, UploadClientFileMutationVariables>(
      UPLOAD_CLIENT_FILE
    )
  return useCallback(
    (variables: UploadClientFileMutationVariables) =>
      uploadClientFile({
        variables,
        refetchQueries: [
          {
            query: CLIENT_FILES
          }
        ]
      }),
    [uploadClientFile]
  )
}

const DELETE_CLIENT_FILE = gql`
  mutation DeleteClientFile($filepath: NonEmptyString!) {
    deleteClientFile(filepath: $filepath)
  }
`

export const useDeleteClientFile = () => {
  const [deleteClientFile] =
    useMutation<DeleteClientFileMutation, DeleteClientFileMutationVariables>(
      DELETE_CLIENT_FILE
    )
  return useCallback(
    (filepath: string) => deleteClientFile({variables: {filepath}}),
    [deleteClientFile]
  )
}

const UPDATE_WEBSITE = gql`
  ${NARROW_WEBSITE_FIELDS}
  mutation UpdateWebsite($id: PositiveInt!, $input: UpdateWebsiteInput!) {
    updateWebsite(id: $id, input: $input) {
      ...NarrowWebsiteFields
    }
  }
`

export const useUpdateWebsite = () => {
  const [updateWebsite] =
    useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(
      UPDATE_WEBSITE
    )
  return useCallback(
    (variables: UpdateWebsiteMutationVariables) => updateWebsite({variables}),
    [updateWebsite]
  )
}

const BASE_WEBSITE_COMMON_SETTINGS_FIELDS = gql`
  fragment BaseWebsiteCommonSettingsFields on WebsiteCommonSettings {
    eCommerceQueryParams {
      theme
      showCartButton
      backgroundColor
      gatewayButtonTarget
      eventOverviewEnabled
      eventButtonTarget
    }
    client {
      eCommerceCartUrl
    }
    metadata {
      metadataBase
      title
      description
      openGraph {
        title
        description
        images
      }
      robots {
        index
        follow
      }
    }
    scripts {
      id
      content
      location
      src
    }
  }
`

const WEBSITE_COMMON_SETTINGS = gql`
  ${BASE_WEBSITE_COMMON_SETTINGS_FIELDS}
  query WebsiteCommonSettings($websiteId: PositiveInt!) {
    websiteCommonSettings(websiteId: $websiteId) {
      ...BaseWebsiteCommonSettingsFields
    }
  }
`

export const useWebsiteCommonSettings = (websiteId: number, skip?: boolean) =>
  useQuery<WebsiteCommonSettingsQuery, WebsiteCommonSettingsQueryVariables>(
    WEBSITE_COMMON_SETTINGS,
    {variables: {websiteId}, fetchPolicy: 'network-only', skip}
  )

const UPDATE_WEBSITE_COMMON_SETTINGS = gql`
  ${BASE_WEBSITE_COMMON_SETTINGS_FIELDS}
  mutation UpdateWebsiteCommonSettings(
    $websiteId: PositiveInt!
    $input: UpdateWebsiteCommonSettingsInput!
  ) {
    updateWebsiteCommonSettings(websiteId: $websiteId, input: $input) {
      ...BaseWebsiteCommonSettingsFields
    }
  }
`

export const useUpdateWebsiteCommonSettings = () => {
  const [updateWebsiteSettings] = useMutation<
    UpdateWebsiteCommonSettingsMutation,
    UpdateWebsiteCommonSettingsMutationVariables
  >(UPDATE_WEBSITE_COMMON_SETTINGS)
  return useCallback(
    (variables: UpdateWebsiteCommonSettingsMutationVariables) =>
      updateWebsiteSettings({variables}),
    [updateWebsiteSettings]
  )
}
