import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {AddMenuItem, IItemForm} from '../types'
import {ItemForm} from './ItemForm'

const EDIT_ITEM_FORM_ID = 'editItemForm'

interface IEditItemDialogProps {
  isOpen: boolean
  type: AddMenuItem
  onClose: () => void
  onSubmit: (formData: IItemForm) => void
  defaultValues?: Partial<IItemForm>
  showVariant?: boolean
  showIcon?: boolean
}

export const EditItemDialog: React.FC<IEditItemDialogProps> = ({
  isOpen,
  type,
  onClose,
  onSubmit,
  defaultValues,
  showVariant,
  showIcon
}: IEditItemDialogProps) => {
  const {t} = useTranslation()
  const handleSubmit = useCallback(
    (formData: IItemForm) => {
      onSubmit(formData)
      onClose()
    },
    [onClose, onSubmit]
  )
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Edit item')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <ItemForm
          formId={EDIT_ITEM_FORM_ID}
          onSubmit={handleSubmit}
          type={type}
          showVariant={showVariant}
          showIcon={showIcon}
          defaultValues={defaultValues}
        />
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={EDIT_ITEM_FORM_ID}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
