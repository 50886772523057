import {Grid, Paper, Typography} from '@mui/material'
import {SvgIconProps} from '@mui/material/SvgIcon'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'
import {borderStyle} from '../constants'

const useCardStyles = makeStyles<Theme>((theme) => ({
  card: {
    padding: theme.spacing(3),
    boxShadow: 'none',
    border: borderStyle
  }
}))

interface IEntityCard {
  header: string
  Icon: React.FC<SvgIconProps>
}

export const EntityCard: React.FC<IEntityCard> = ({
  header,
  Icon
}: IEntityCard) => {
  const classes = useCardStyles()
  return (
    <Paper className={classes.card}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Grid container alignItems="center">
            <Icon color="primary" fontSize="large" />
          </Grid>
        </Grid>
        <Grid item>
          <Typography color="primary" variant="h6">
            {header}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
