import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  CustomersFilterInput,
  LoyaltyLevelStatus
} from '../../../../__generated__/schema'
import {useTranslateLoyaltyLevelStatus} from '../../../../hooks/translateLoyaltyLevelStatus'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'

export const DEFAULT_CUSTOMERS_FILTER_INPUT: CustomersFilterInput = {}

enum PrivacyPolicy {
  Granted = 'granted',
  NotGranted = 'notGranted'
}

type ExtendedCustomersFilter = CustomersFilterInput & {
  _privacyPolicyConsentGrantedDaterangeId?: DATERANGE_IDS
  _loyaltyScoreUpdatedDaterangeId?: DATERANGE_IDS
  _loyaltyLevelExpirationDate?: DATERANGE_IDS
  _updatedAt?: DATERANGE_IDS
  _createdAt?: DATERANGE_IDS
}

const stripPrivacyPolicyConsentGrantedDateFromFilter = (
  filter: ExtendedCustomersFilter
): CustomersFilterInput =>
  omit(filter, [
    'privacyPolicyConsentGrantedFrom',
    'privacyPolicyConsentGrantedTo',
    '_privacyPolicyConsentGrantedDaterangeId'
  ])

const stripLoyaltyScoreUpdatedFromFilter = (
  filter: ExtendedCustomersFilter
): CustomersFilterInput =>
  omit(filter, [
    'loyaltyScoreUpdatedAtFrom',
    'loyaltyScoreUpdatedAtTo',
    '_loyaltyScoreUpdatedDaterangeId'
  ])

const stripLoyaltyLevelExpirationDateFromFilter = (
  filter: ExtendedCustomersFilter
): CustomersFilterInput =>
  omit(filter, [
    'loyaltyLevelExpirationDateFrom',
    'loyaltyLevelExpirationDateTo',
    '_loyaltyLevelExpirationDate'
  ])

const stripUpdatedAtFromFilter = (
  filter: ExtendedCustomersFilter
): CustomersFilterInput =>
  omit(filter, ['updatedAtFrom', 'updatedAtTo', '_updatedAt'])

const stripCreatedAtFromFilter = (
  filter: ExtendedCustomersFilter
): CustomersFilterInput =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAt'])

const stripHelperKeysFromFilter = (
  filter: ExtendedCustomersFilter
): CustomersFilterInput =>
  omit(filter, [
    '_privacyPolicyConsentGrantedDaterangeId',
    '_loyaltyScoreUpdatedDaterangeId',
    '_loyaltyLevelExpirationDate',
    '_updatedAt',
    '_createdAt'
  ])

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateLoyaltyLevelStatus = useTranslateLoyaltyLevelStatus()
  const getHasTextFromSearchObject = (filter: CustomersFilterInput) =>
    filter.hasText || undefined
  const getPrivacyPolicyFormSearchObject = (filter: CustomersFilterInput) => {
    if (typeof filter.privacyPolicyConsentGranted === 'boolean') {
      return filter.privacyPolicyConsentGranted
        ? t('Privacy policy: Granted')
        : t('Privacy policy: Not granted')
    }
    return undefined
  }
  const getNameFromSearchObject = (filter: CustomersFilterInput) =>
    filter.name ? t('Name: {{name}}', {name: filter.name}) : undefined
  const getLoyaltyIdFromSearchObject = (filter: CustomersFilterInput) =>
    filter.loyaltyId
      ? t('Loyalty ID: {{id}}', {id: filter.loyaltyId})
      : undefined
  const getUsernameFromSearchObject = (filter: CustomersFilterInput) =>
    filter.username
      ? t('Username: {{name}}', {name: filter.username})
      : undefined
  const getPhoneFromSearchObject = (filter: CustomersFilterInput) =>
    filter.phone ? t('Phone: {{phone}}', {phone: filter.phone}) : undefined
  const getEmailFromSearchObject = (filter: CustomersFilterInput) =>
    filter.email ? t('Email: {{email}}', {email: filter.email}) : undefined
  const getCompanyNameFromSearchObject = (filter: CustomersFilterInput) =>
    filter.companyName
      ? t('Company name: {{companyName}}', {companyName: filter.companyName})
      : undefined
  const getCompanyIdFromSearchObject = (filter: CustomersFilterInput) =>
    filter.companyIdNumber
      ? t('Company ID: {{companyId}}', {companyId: filter.companyIdNumber})
      : undefined
  const getLoyaltyLevelStatusFromSearchObject = (
    filter: CustomersFilterInput
  ) =>
    filter.loyaltyLevelStatus
      ? t('Loyalty level status: {{status}}', {
          status: translateLoyaltyLevelStatus(filter.loyaltyLevelStatus)
        })
      : undefined
  return {
    getHasTextFromSearchObject,
    getPrivacyPolicyFormSearchObject,
    getLoyaltyIdFromSearchObject,
    getUsernameFromSearchObject,
    getNameFromSearchObject,
    getPhoneFromSearchObject,
    getEmailFromSearchObject,
    getCompanyNameFromSearchObject,
    getCompanyIdFromSearchObject,
    getLoyaltyLevelStatusFromSearchObject
  }
}

const mapHasTextToFilter = (
  filter: CustomersFilterInput,
  hasText: string | undefined
): CustomersFilterInput => ({
  ...filter,
  hasText
})

const mapPrivacyPolicyToFilter = (
  filter: CustomersFilterInput,
  privacyPolicyConsentGranted: string | undefined
): CustomersFilterInput => ({
  ...filter,
  privacyPolicyConsentGranted:
    typeof privacyPolicyConsentGranted === 'string'
      ? privacyPolicyConsentGranted === PrivacyPolicy.Granted
      : undefined
})

const mapNameToFilter = (
  filter: CustomersFilterInput,
  name: string | undefined
): CustomersFilterInput => ({
  ...filter,
  name
})

const mapLoyaltyIdToFilter = (
  filter: CustomersFilterInput,
  loyaltyId: string | undefined
): CustomersFilterInput => ({
  ...filter,
  loyaltyId: loyaltyId || undefined
})

const mapUsernameToFilter = (
  filter: CustomersFilterInput,
  username: string | undefined
): CustomersFilterInput => ({
  ...filter,
  username: username || undefined
})

const mapPhoneToFilter = (
  filter: CustomersFilterInput,
  phone: string | undefined
): CustomersFilterInput => ({
  ...filter,
  phone
})

const mapEmailToFilter = (
  filter: CustomersFilterInput,
  email: string | undefined
): CustomersFilterInput => ({
  ...filter,
  email
})

const mapCompanyNameToFilter = (
  filter: CustomersFilterInput,
  companyName: string | undefined
): CustomersFilterInput => ({
  ...filter,
  companyName
})

const mapCompanyIdToFilter = (
  filter: CustomersFilterInput,
  companyIdNumber: string | undefined
): CustomersFilterInput => ({
  ...filter,
  companyIdNumber
})

const mapLoyaltyLevelStatusToFilter = (
  filter: CustomersFilterInput,
  loyaltyLevelStatus?: LoyaltyLevelStatus
): CustomersFilterInput => ({
  ...filter,
  loyaltyLevelStatus
})

const dateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.LAST_7_DAYS,
  DATERANGE_IDS.LAST_30_DAYS
]

interface ICustomersSearchProps {
  onFilterChange: (filter: CustomersFilterInput) => void
}

export const CustomersSearch: React.FC<ICustomersSearchProps> = ({
  onFilterChange
}: ICustomersSearchProps) => {
  const {t} = useTranslation()
  const {
    getHasTextFromSearchObject,
    getPrivacyPolicyFormSearchObject,
    getLoyaltyIdFromSearchObject,
    getNameFromSearchObject,
    getUsernameFromSearchObject,
    getPhoneFromSearchObject,
    getEmailFromSearchObject,
    getCompanyNameFromSearchObject,
    getCompanyIdFromSearchObject,
    getLoyaltyLevelStatusFromSearchObject
  } = useGetFieldFromSearchObject()
  const {
    daterangeOptions: privacyPolicyConsentGrantedDateDaterangeOptions,
    mapCustomDaterangeToSearchObject:
      mapCustomPrivacyPolicyConsentGrantedDateDateRangeToFilter,
    mapDaterangeToSearchObject:
      mapPrivacyPolicyConsentGrantedDateDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedPrivacyPolicyConsentGrantedDateDateRangeFromFilter,
    getIsDaterangeOptionActive:
      getIsPrivacyPolicyConsentGrantedDateDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCustomersFilter>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Granted at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.privacyPolicyConsentGrantedFrom || undefined,
      endDateISOString: o.privacyPolicyConsentGrantedTo || undefined,
      id: o._privacyPolicyConsentGrantedDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      privacyPolicyConsentGrantedFrom: input.startDate,
      privacyPolicyConsentGrantedTo: input.endDate,
      _privacyPolicyConsentGrantedDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: loyaltyScoreUpdatedDaterangeOptions,
    mapCustomDaterangeToSearchObject:
      mapCustomLoyaltyScoreUpdatedDateRangeToFilter,
    mapDaterangeToSearchObject: mapLoyaltyScoreUpdatedDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedLoyaltyScoreUpdatedDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsLoyaltyScoreUpdatedDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCustomersFilter>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Loyalty updated'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.loyaltyScoreUpdatedAtFrom || undefined,
      endDateISOString: o.loyaltyScoreUpdatedAtTo || undefined,
      id: o._loyaltyScoreUpdatedDaterangeId
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      loyaltyScoreUpdatedAtFrom: input.startDate,
      loyaltyScoreUpdatedAtTo: input.endDate,
      _loyaltyScoreUpdatedDaterangeId: input.id
    })
  })
  const {
    daterangeOptions: loyaltyLevelExpirationDateDaterangeOptions,
    mapCustomDaterangeToSearchObject:
      mapCustomLoyaltyLevelExpirationDateDateRangeToFilter,
    mapDaterangeToSearchObject: mapLoyaltyLevelExpirationDateDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedLoyaltyLevelExpirationDateDateRangeFromFilter,
    getIsDaterangeOptionActive:
      getIsLoyaltyLevelExpirationDateDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCustomersFilter>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Loyalty level expiration date'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.loyaltyLevelExpirationDateFrom || undefined,
      endDateISOString: o.loyaltyLevelExpirationDateTo || undefined,
      id: o._loyaltyLevelExpirationDate
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      loyaltyLevelExpirationDateFrom: input.startDate,
      loyaltyLevelExpirationDateTo: input.endDate,
      _loyaltyLevelExpirationDate: input.id
    })
  })
  const {
    daterangeOptions: updatedAtDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomUpdatedAtDaterangeToFilter,
    mapDaterangeToSearchObject: mapUpdatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedUpdatedAtDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsUpdatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCustomersFilter>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Updated at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.updatedAtFrom || undefined,
      endDateISOString: o.updatedAtTo || undefined,
      id: o._updatedAt
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      updatedAtFrom: input.startDate,
      updatedAtTo: input.endDate,
      _updatedAt: input.id
    })
  })
  const {
    daterangeOptions: createdAtDaterangeOptions,
    mapCustomDaterangeToSearchObject: mapCustomCreatedAtDaterangeToFilter,
    mapDaterangeToSearchObject: mapCreatedAtDaterangeToFilter,
    getStringifiedDaterangeFromSearchObject:
      getStringifiedCreatedAtDateRangeFromFilter,
    getIsDaterangeOptionActive: getIsCreatedAtDaterangeOptionActive
  } = useDateRangeSearch<ExtendedCustomersFilter>({
    usedDateranges: dateRanges,
    dateRangeInputPrefix: t('Created at'),
    getDateRangeFromSearchObject: (o) => ({
      startDateISOString: o.createdAtFrom || undefined,
      endDateISOString: o.createdAtTo || undefined,
      id: o._createdAt
    }),
    mapDaterangeValuesToSearchObject: (o, input) => ({
      ...o,
      createdAtFrom: input.startDate,
      createdAtTo: input.endDate,
      _createdAt: input.id
    })
  })
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<CustomersFilterInput>(
      getHasTextFromSearchObject,
      getPrivacyPolicyFormSearchObject,
      getStringifiedPrivacyPolicyConsentGrantedDateDateRangeFromFilter,
      getLoyaltyIdFromSearchObject,
      getNameFromSearchObject,
      getUsernameFromSearchObject,
      getPhoneFromSearchObject,
      getEmailFromSearchObject,
      getCompanyNameFromSearchObject,
      getCompanyIdFromSearchObject,
      getStringifiedLoyaltyScoreUpdatedDateRangeFromFilter,
      getStringifiedLoyaltyLevelExpirationDateDateRangeFromFilter,
      getStringifiedUpdatedAtDateRangeFromFilter,
      getStringifiedCreatedAtDateRangeFromFilter,
      getLoyaltyLevelStatusFromSearchObject
    )
  const translateLoyaltyLevelStatus = useTranslateLoyaltyLevelStatus()
  const privacyPolicyOptions: ISelectOption<string>[] = Object.values(
    PrivacyPolicy
  ).map((pp) => ({
    id: pp,
    label: pp === PrivacyPolicy.Granted ? t('Granted') : t('Not granted')
  }))
  const loyaltyLevelStatusOptions: ISelectOption<LoyaltyLevelStatus>[] =
    Object.values(LoyaltyLevelStatus).map((status) => ({
      id: status,
      label: translateLoyaltyLevelStatus(status)
    }))
  return (
    <Search<CustomersFilterInput>
      storageKey="CUSTOMERS_LIST"
      placeholder={t('Search for customers')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_CUSTOMERS_FILTER_INPUT}
      stripExtendedSearchObject={stripHelperKeysFromFilter}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchTextRow<CustomersFilterInput>
            label={t('Has text')}
            placeholder={t('Enter name, email, or company name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapHasTextToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.hasText || undefined}
          />
          <AdvancedSearchDaterangeRow
            label={t('Loyalty updated')}
            daterangeOptions={loyaltyScoreUpdatedDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomLoyaltyScoreUpdatedDateRangeToFilter
            }
            mapDaterangeToSearchObject={mapLoyaltyScoreUpdatedDaterangeToFilter}
            getIsDaterangeOptionActive={
              getIsLoyaltyScoreUpdatedDaterangeOptionActive
            }
            stripDaterangeFromSearchObject={stripLoyaltyScoreUpdatedFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of loyalty update'
            )}
          />
          <AdvancedSearchDaterangeRow
            label={t('Loyalty level expiration date')}
            daterangeOptions={loyaltyLevelExpirationDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomLoyaltyLevelExpirationDateDateRangeToFilter
            }
            mapDaterangeToSearchObject={
              mapLoyaltyLevelExpirationDateDaterangeToFilter
            }
            getIsDaterangeOptionActive={
              getIsLoyaltyLevelExpirationDateDaterangeOptionActive
            }
            stripDaterangeFromSearchObject={
              stripLoyaltyLevelExpirationDateFromFilter
            }
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of loyalty level expiration'
            )}
          />
          <AdvancedSearchSelectRow<CustomersFilterInput, LoyaltyLevelStatus>
            label={t('Loyalty level status')}
            value={advancedSearchObject.loyaltyLevelStatus || undefined}
            options={loyaltyLevelStatusOptions}
            mapSelectValueToSearchObject={mapLoyaltyLevelStatusToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<CustomersFilterInput, string>
            label={t('Privacy policy')}
            value={
              typeof advancedSearchObject.privacyPolicyConsentGranted ===
              'boolean'
                ? advancedSearchObject.privacyPolicyConsentGranted
                  ? PrivacyPolicy.Granted
                  : PrivacyPolicy.NotGranted
                : undefined
            }
            options={privacyPolicyOptions}
            mapSelectValueToSearchObject={mapPrivacyPolicyToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchDaterangeRow
            label={t('Granted at')}
            daterangeOptions={privacyPolicyConsentGrantedDateDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomPrivacyPolicyConsentGrantedDateDateRangeToFilter
            }
            mapDaterangeToSearchObject={
              mapPrivacyPolicyConsentGrantedDateDaterangeToFilter
            }
            getIsDaterangeOptionActive={
              getIsPrivacyPolicyConsentGrantedDateDaterangeOptionActive
            }
            stripDaterangeFromSearchObject={
              stripPrivacyPolicyConsentGrantedDateFromFilter
            }
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of event start'
            )}
          />
          <AdvancedSearchTextRow
            label={t('Loyalty ID')}
            placeholder={t('Enter customer loyalty ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapLoyaltyIdToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.loyaltyId || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Username')}
            placeholder={t('Enter customer username')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapUsernameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.username || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Name')}
            placeholder={t('Enter customer name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.name || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Phone')}
            placeholder={t('Enter customer phone')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapPhoneToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.phone || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Email')}
            placeholder={t('Enter customer email')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapEmailToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.email || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Company name')}
            placeholder={t('Enter company name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapCompanyNameToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.companyName || undefined}
          />
          <AdvancedSearchTextRow
            label={t('Company ID')}
            placeholder={t('Enter company ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            mapTextToSearchObject={mapCompanyIdToFilter}
            advancedSearchObject={advancedSearchObject}
            value={advancedSearchObject.companyIdNumber || undefined}
          />
          <AdvancedSearchDaterangeRow
            label={t('Updated at')}
            daterangeOptions={updatedAtDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomUpdatedAtDaterangeToFilter
            }
            mapDaterangeToSearchObject={mapUpdatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsUpdatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripUpdatedAtFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of updated at'
            )}
          />
          <AdvancedSearchDaterangeRow
            label={t('Created at')}
            daterangeOptions={createdAtDaterangeOptions}
            mapCustomDaterangeToSearchObject={
              mapCustomCreatedAtDaterangeToFilter
            }
            mapDaterangeToSearchObject={mapCreatedAtDaterangeToFilter}
            getIsDaterangeOptionActive={getIsCreatedAtDaterangeOptionActive}
            stripDaterangeFromSearchObject={stripCreatedAtFromFilter}
            advancedSearchObject={advancedSearchObject}
            setAdvancedSearchObject={setAdvancedSearchObject}
            customDaterangeDialogTitle={t('Select date range')}
            customDaterangeDialogDescription={t(
              'Select date range of created at'
            )}
          />
        </AdvancedSearchBase>
      )}
    />
  )
}
