import {useTranslation} from 'react-i18next'
import {
  ApplicationRuleType,
  ConditionOperator,
  ConditionResource,
  DiscountApplication,
  DiscountState,
  DiscountType
} from '../__generated__/schema'

export const useTranslateDiscountType = () => {
  const {t} = useTranslation()
  const translatedTypes: Record<DiscountType, string> = {
    [DiscountType.Percentage]: t<string>('DiscountType->Percentage'),
    [DiscountType.FixedAmount]: t<string>('DiscountType->FixedAmount')
  }
  return (type: DiscountType) => translatedTypes[type]
}

export const useTranslateDiscountApplication = () => {
  const {t} = useTranslation()
  const translatedApplications: Record<DiscountApplication, string> = {
    [DiscountApplication.Selectable]: t<string>(
      'DiscountApplication->Selectable'
    ),
    [DiscountApplication.Code]: t<string>('DiscountApplication->Code'),
    [DiscountApplication.Automatic]: t<string>(
      'DiscountApplication->Automatic'
    ),
    [DiscountApplication.Customer]: t<string>('DiscountApplication->Customer')
  }
  return (application: DiscountApplication) =>
    translatedApplications[application]
}

export const useTranslateDiscountState = () => {
  const {t} = useTranslation()
  const translatedState: Record<DiscountState, string> = {
    [DiscountState.Inactive]: t<string>('DiscountState->Inactive'),
    [DiscountState.Active]: t<string>('DiscountState->Active'),
    [DiscountState.Deleted]: t<string>('DiscountState->Deleted')
  }
  return (state: DiscountState) => translatedState[state]
}

export const useTranslateApplicationRuleType = () => {
  const {t} = useTranslation()
  const translatedType: Record<ApplicationRuleType, string> = {
    [ApplicationRuleType.Tour]: t<string>('ApplicationRuleType->Tour'),
    [ApplicationRuleType.Cart]: t<string>('ApplicationRuleType->Cart'),
    [ApplicationRuleType.Event]: t<string>('ApplicationRuleType->Event'),
    [ApplicationRuleType.Product]: t<string>('ApplicationRuleType->Product')
  }
  return (type: ApplicationRuleType) => translatedType[type]
}

export const useTranslateConditionResource = () => {
  const {t} = useTranslation()
  const translatedResources: Record<ConditionResource, string> = {
    [ConditionResource.ShowTypeCode]: t<string>(
      'ConditionResource->ShowTypeCode'
    ),
    [ConditionResource.FormatCode]: t<string>('ConditionResource->FormatCode'),
    [ConditionResource.VersionCode]: t<string>(
      'ConditionResource->VersionCode'
    ),
    [ConditionResource.SoundMixCode]: t<string>(
      'ConditionResource->SoundMixCode'
    ),
    [ConditionResource.ShowAgeClassificationCode]: t<string>(
      'ConditionResource->ShowAgeClassificationCode'
    ),
    [ConditionResource.VenueId]: t<string>('ConditionResource->VenueId'),
    [ConditionResource.AuditoriumId]: t<string>(
      'ConditionResource->AuditoriumId'
    ),
    [ConditionResource.ShowId]: t<string>('ConditionResource->ShowId'),
    [ConditionResource.EventId]: t<string>('ConditionResource->EventId'),
    [ConditionResource.StartWeekDay]: t<string>(
      'ConditionResource->StartWeekDay'
    ),
    [ConditionResource.StartTime]: t<string>('ConditionResource->StartTime'),
    [ConditionResource.StartDate]: t<string>('ConditionResource->StartDate'),
    [ConditionResource.MarketingLabelId]: t<string>(
      'ConditionResource->MarketingLabelId'
    ),
    [ConditionResource.CostCenterId]: t<string>(
      'ConditionResource->CostCenterId'
    ),
    [ConditionResource.EventCategoryId]: t<string>(
      'ConditionResource->EventCategoryId'
    ),
    [ConditionResource.ApplicableByUserId]: t<string>(
      'ConditionResource->ApplicableByUserId'
    ),
    [ConditionResource.DiscountApplicationTime]: t<string>(
      'ConditionResource->DiscountApplicationTime'
    ),
    [ConditionResource.DiscountApplicationDate]: t<string>(
      'ConditionResource->DiscountApplicationDate'
    ),
    [ConditionResource.TourId]: t<string>('ConditionResource->TourId'),
    [ConditionResource.TourTimeSlotId]: t<string>(
      'ConditionResource->TourTimeSlotId'
    ),
    [ConditionResource.AdmissionTypeId]: t<string>(
      'ConditionResource->AdmissionTypeId'
    )
  }
  return (resource: ConditionResource) => translatedResources[resource]
}

export const useTranslateConditionOperator = () => {
  const {t} = useTranslation()
  const translatedOperators: Record<ConditionOperator, string> = {
    [ConditionOperator.IsOneOf]: t<string>('ConditionOperator->IsOneOf'),
    [ConditionOperator.IsNotOneOf]: t<string>('ConditionOperator->IsNotOneOf'),
    [ConditionOperator.IsGreaterOrEqual]: t<string>(
      'ConditionOperator->IsGreaterOrEqual'
    ),
    [ConditionOperator.IsLowerOrEqual]: t<string>(
      'ConditionOperator->IsLowerOrEqual'
    ),
    [ConditionOperator.IsEqual]: t<string>('ConditionOperator->IsEqual'),
    [ConditionOperator.IsNotEqual]: t<string>('ConditionOperator->IsNotEqual')
  }
  return (operator: ConditionOperator) => translatedOperators[operator]
}
