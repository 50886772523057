import {ECommerceTranslatedInput} from '../../../../__generated__/schema'

export enum AdmissionTypeFormField {
  Name = 'name',
  Abbreviation = 'abbreviation',
  CapacityDecreaseCount = 'capacityDecreaseCount',
  StartingQuantity = 'startingQuantity',
  Color = 'color',
  InternalDescription = 'internalDescription',
  Icon = 'icon',
  ECommerceNames = 'eCommerceNames',
  ECommerceDescriptions = 'eCommerceDescriptions'
}

export interface IAdmissionTypeForm {
  [AdmissionTypeFormField.Name]: string
  [AdmissionTypeFormField.Abbreviation]: string
  [AdmissionTypeFormField.CapacityDecreaseCount]: number
  [AdmissionTypeFormField.StartingQuantity]: number
  [AdmissionTypeFormField.Color]: string | null
  [AdmissionTypeFormField.InternalDescription]: string | null
  [AdmissionTypeFormField.Icon]: string | null
  [AdmissionTypeFormField.ECommerceNames]?: ECommerceTranslatedInput
  [AdmissionTypeFormField.ECommerceDescriptions]?: ECommerceTranslatedInput
}
