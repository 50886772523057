import {ApolloError} from 'apollo-client'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages, Maybe} from '../../../../__generated__/schema'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'

export enum ReservationErrorTriggers {
  Update = 'update',
  Split = 'split',
  Delete = 'delete',
  Add = 'add'
}

type ReservationErrorMessage =
  | ErrorMessages.ReservationDeleted
  | ErrorMessages.ReservationIsBeingProcessed
  | ErrorMessages.ReservationHasBeenAlreadyPaid
  | ErrorMessages.ReservationExpired

const getTranslatedReservationErrorMessage = (
  errorMessage: ReservationErrorMessage,
  trigger: ReservationErrorTriggers,
  t: TFunction
) => {
  const reservationErrorMessages = {
    [ReservationErrorTriggers.Delete]: {
      [ErrorMessages.ReservationHasBeenAlreadyPaid]: t(
        'Unable to delete reservation. Reservation was already paid.'
      ),
      [ErrorMessages.ReservationIsBeingProcessed]: t(
        'Unable to delete reservation. Reservation is being processed by another user.'
      ),
      [ErrorMessages.ReservationDeleted]: t(
        'Unable to delete reservation. Reservation was deleted.'
      ),
      [ErrorMessages.ReservationExpired]: t(
        'Unable to delete reservation, because reservation has expired.'
      )
    },
    [ReservationErrorTriggers.Split]: {
      [ErrorMessages.ReservationHasBeenAlreadyPaid]: t(
        'Unable to split reservation. Reservation was already paid.'
      ),
      [ErrorMessages.ReservationIsBeingProcessed]: t(
        'Unable to split reservation. Reservation is being processed by another user.'
      ),
      [ErrorMessages.ReservationDeleted]: t(
        'Unable to split reservation. Reservation was deleted.'
      ),
      [ErrorMessages.ReservationExpired]: t(
        'Unable to split reservation, because reservation has expired.'
      )
    },
    [ReservationErrorTriggers.Update]: {
      [ErrorMessages.ReservationHasBeenAlreadyPaid]: t(
        'Unable to update reservation. Reservation was already paid.'
      ),
      [ErrorMessages.ReservationIsBeingProcessed]: t(
        'Unable to update reservation. Reservation is being processed by another user.'
      ),
      [ErrorMessages.ReservationDeleted]: t(
        'Unable to update reservation. Reservation was deleted.'
      ),
      [ErrorMessages.ReservationExpired]: t(
        'Unable to update reservation, because reservation has expired.'
      )
    },
    [ReservationErrorTriggers.Add]: {
      [ErrorMessages.ReservationHasBeenAlreadyPaid]: t(
        'Unable to add reservation to cart. Reservation was already paid.'
      ),
      [ErrorMessages.ReservationIsBeingProcessed]: t(
        'Unable to add reservation to cart. Reservation is being processed by another user.'
      ),
      [ErrorMessages.ReservationDeleted]: t(
        'Unable to add reservation to cart. Reservation was deleted.'
      ),
      [ErrorMessages.ReservationExpired]: t(
        'Unable to add reservation to cart, because reservation has expired.'
      )
    }
  }

  return reservationErrorMessages[trigger][errorMessage]
}

const isSpecificReservationErrorMessage = (errorMessage?: ErrorMessages) =>
  !!errorMessage &&
  [
    ErrorMessages.ReservationDeleted,
    ErrorMessages.ReservationExpired,
    ErrorMessages.ReservationHasBeenAlreadyPaid,
    ErrorMessages.ReservationIsBeingProcessed
  ].includes(errorMessage)

const getTranslatedReservationErrorTitle = (
  trigger: ReservationErrorTriggers,
  t: TFunction
) => {
  switch (trigger) {
    case ReservationErrorTriggers.Update:
      return t('Reservation update has failed')
    case ReservationErrorTriggers.Split:
      return t('Reservation split has failed')
    case ReservationErrorTriggers.Delete:
      return t('Failed to delete reservation')
    case ReservationErrorTriggers.Add:
      return t('Failed to add reservation to cart')
    default:
      return t('Error')
  }
}

interface IReservationErrorDialogProps {
  error: Maybe<ApolloError>
  onConfirm: () => void
  trigger: ReservationErrorTriggers
}

export const ReservationErrorDialog: React.FC<IReservationErrorDialogProps> = ({
  error,
  onConfirm,
  trigger
}: IReservationErrorDialogProps) => {
  const {t} = useTranslation()
  const errorMessage = error?.graphQLErrors[0].message
  return (
    <ConfirmationDialog
      title={getTranslatedReservationErrorTitle(trigger, t)}
      onConfirm={onConfirm}
      contentText={
        isSpecificReservationErrorMessage(errorMessage as ErrorMessages)
          ? getTranslatedReservationErrorMessage(
              errorMessage as ReservationErrorMessage,
              trigger,
              t
            )
          : getTranslatedReservationErrorTitle(trigger, t)
      }
      isOpen={error !== null}
      confirmButtonLabel={t('Got it')}
    />
  )
}
