import {Divider, Grid, GridProps} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {Fragment} from 'react'
import {Theme} from '../../theme'
import {TOP_PANEL_HEIGHT, TOP_PANEL_ICONS_SIZE} from '../config'
import {useSelector} from '../redux'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'
import {AlignPanel} from './AlignPanel'
import {CopyDeletePanel} from './CopyDeletePanel'
import {DownloadPanel} from './DownloadPanel'
import {DrawPanel} from './DrawPanel'
import {FlipPanel} from './FlipPanel'
import {HistoryPanel} from './HistoryPanel'
import {Panel} from './Panel'
import {PanPanel} from './PanPanel'
import {PlacePanel} from './PlacePanel'
import {SelectionPanel} from './SelectionPanel'
import {TransformPanel} from './TransformPanel'
import {ZoomPanel} from './ZoomPanel'

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    height: TOP_PANEL_HEIGHT,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: '0px 4px 4px -4px rgba(0, 0, 0, 0.25)'
  },
  divider: {
    height: TOP_PANEL_ICONS_SIZE
  }
}))

const TopPanelDivider: React.FC = () => {
  const classes = useStyles()

  return <Divider orientation="vertical" className={classes.divider} />
}

type SubpanelGroup = Array<{node: React.ReactNode; visible: boolean}>

interface ISubpanelProps {
  items: Array<SubpanelGroup>
  justify?: GridProps['justifyContent']
}

const Subpanel: React.FC<ISubpanelProps> = ({
  items,
  justify
}: ISubpanelProps) => {
  const subpanelGroups = items.map((subpanelGroup: SubpanelGroup) => {
    return subpanelGroup.filter((groupItem) => groupItem.visible)
  })

  const nonEmptySubpanelGroups = subpanelGroups.filter(
    (subpanelGroup) => subpanelGroup.length
  )

  return (
    <Grid item>
      <Grid container justifyContent={justify} alignItems="center">
        {nonEmptySubpanelGroups.map((subpanelGroup, index) => (
          <Fragment key={index}>
            <Grid item>
              {subpanelGroup.length === 1 ? (
                subpanelGroup[0].node
              ) : (
                <Panel>
                  {subpanelGroup.map((groupItem) => groupItem.node)}
                </Panel>
              )}
            </Grid>
            {index !== nonEmptySubpanelGroups.length - 1 && (
              <Grid item>
                <TopPanelDivider />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export const TopPanel: React.FC = () => {
  const classes = useStyles()
  const displayMode = useSelector(displayModeSelector)

  const panels = {
    left: [
      [
        {
          node: <DownloadPanel key="download-panel" />,
          visible: true
        }
      ],
      [
        {
          node: <HistoryPanel key="history-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.RESTRICTED
        }
      ],
      [
        {
          node: (
            <PlacePanel
              key="place-panel"
              seatsDisabled={displayMode === DisplayMode.FULL_WITHOUT_SEATS}
            />
          ),
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS
        }
      ],
      [
        {
          node: <DrawPanel key="draw-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS
        }
      ],
      [
        {
          node: <FlipPanel key="flip-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.RESTRICTED
        },
        {
          node: <AlignPanel key="align-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.RESTRICTED
        }
      ],
      [
        {
          node: (
            <SelectionPanel
              key="selection-panel"
              disableSelectAll={displayMode === DisplayMode.CASH}
            />
          ),
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.PRICING ||
            displayMode === DisplayMode.CASH ||
            displayMode === DisplayMode.RESTRICTED
        },
        {
          node: <PanPanel key="pan-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.PRICING ||
            displayMode === DisplayMode.CASH ||
            displayMode === DisplayMode.RESTRICTED
        }
      ],
      [
        {
          node: <TransformPanel key="transform-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.RESTRICTED
        }
      ],
      [
        {
          node: <CopyDeletePanel key="copy-delete-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS
        }
      ]
    ],
    right: [
      [
        {
          node: <ZoomPanel key="zoom-panel" />,
          visible:
            displayMode === DisplayMode.FULL ||
            displayMode === DisplayMode.FULL_WITHOUT_SEATS ||
            displayMode === DisplayMode.PRICING ||
            displayMode === DisplayMode.CASH ||
            displayMode === DisplayMode.RESTRICTED
        }
      ]
    ]
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.wrapper}
    >
      <Subpanel items={panels.left} />
      <Subpanel items={panels.right} justify="flex-end" />
    </Grid>
  )
}
