import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import {SvgIconProps} from '@mui/material'
import React from 'react'
import {PaymentMethodType} from '../../../../../__generated__/schema'
import {useTranslatePaymentMethodType} from '../../../../../hooks/translatePaymentMethodType'
import {IntentStructureAccordion} from '../../../../common/IntentStructureAccordion'
import {IntentFrames} from './IntentFrames'
import {IntentStructureAccordionSecondaryChildren} from './IntentStructureAccordionSecondaryChildren'
import {ITypeIntentStructure} from './types'

const typeFrameIcons: Record<PaymentMethodType, React.FC<SvgIconProps>> = {
  [PaymentMethodType.Cash]: AccountBalanceWalletOutlinedIcon,
  [PaymentMethodType.Card]: CreditCardOutlinedIcon,
  [PaymentMethodType.WireTransfer]: AccountBalanceIcon,
  [PaymentMethodType.Voucher]: ConfirmationNumberOutlinedIcon,
  [PaymentMethodType.PaymentGateway]: AccountBalanceWalletOutlinedIcon
}

interface ITypeIntentStructureProps {
  structure: ITypeIntentStructure
  isExpanded: boolean
  onStructureExpand: () => void
}

export const TypeIntentStructure: React.FC<ITypeIntentStructureProps> = ({
  structure,
  isExpanded,
  onStructureExpand
}: ITypeIntentStructureProps) => {
  const {type} = structure
  const translatePaymentMethodType = useTranslatePaymentMethodType()
  return (
    <IntentStructureAccordion
      isExpanded={isExpanded}
      onSummaryClick={onStructureExpand}
      name={translatePaymentMethodType(type)}
      Icon={typeFrameIcons[type]}
      secondaryChildren={
        <IntentStructureAccordionSecondaryChildren
          intentFrames={structure.intentFrames}
          isExpanded={isExpanded}
        />
      }
    >
      <IntentFrames structure={structure} />
    </IntentStructureAccordion>
  )
}
