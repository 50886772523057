import {LanguageCode} from '../__generated__/schema'
import {useTranslateLanguageCode} from './translateLanguages'

export const useLanguageSelectData = () => {
  const languages = Object.values(LanguageCode)
  const translateLanguage = useTranslateLanguageCode()
  return languages.reduce(
    (acc, languageCode) => ({
      ...acc,
      [languageCode]: translateLanguage(languageCode)
    }),
    {}
  ) as Record<LanguageCode, string>
}
