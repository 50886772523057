import DragHandleIcon from '@mui/icons-material/DragHandle'
import {IconButton, Switch, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {TemplateProps} from 'react-draggable-list'

import {
  DeviceServices,
  useTranslateDeviceServiceDescription,
  useTranslateDeviceServiceTitle
} from '../../../../hooks/deviceServices'
import {Theme} from '../../../../theme'
import {DeviceServiceIcon} from '../../../common/DeviceServiceIcon'

const useStyles = makeStyles<Theme, {displayDivider: boolean}>((theme) => ({
  root: {
    display: 'grid',
    padding: theme.spacing(1, 1.5),
    gridTemplateAreas: `
      "dragHandle iconBox title       switch"
      "dragHandle iconBox description switch"
    `,
    gridTemplateColumns: 'auto auto 1fr auto',
    alignItems: 'center',
    borderBottom: ({displayDivider}) =>
      displayDivider ? `solid 1px ${theme.palette.divider}` : 'none',
    background: theme.palette.background.paper
  },
  dragHandle: {
    gridArea: 'dragHandle'
  },
  switch: {
    gridArea: 'switch'
  },
  iconBox: {
    gridArea: 'iconBox',
    padding: theme.spacing(0, 1),
    color: theme.palette.text.secondary
  },
  title: {
    gridArea: 'title',
    alignSelf: 'bottom'
  },
  description: {
    gridArea: 'description',
    alignSelf: 'top'
  }
}))

export interface IDeviceServiceItemCommonProps {
  onAvailabilityChanged: (link: string) => void
  isLastItem: (link: string) => boolean
}

interface IDeviceServiceItemProps
  extends TemplateProps<DeviceServices, IDeviceServiceItemCommonProps> {}

export const DeviceServiceItem: React.FC<IDeviceServiceItemProps> = ({
  item: {link, isAvailable},
  dragHandleProps,
  commonProps: {onAvailabilityChanged, isLastItem},
  itemSelected
}: IDeviceServiceItemProps) => {
  const classes = useStyles({
    displayDivider: !isLastItem(link) || itemSelected !== 0
  })
  const handleSwitchChanged = useCallback(() => {
    onAvailabilityChanged(link)
  }, [link, onAvailabilityChanged])
  const translateDeviceServiceTitle = useTranslateDeviceServiceTitle()
  const translateDeviceServiceDescription =
    useTranslateDeviceServiceDescription()
  return (
    <div className={classes.root}>
      <IconButton {...dragHandleProps} className={classes.dragHandle}>
        <DragHandleIcon />
      </IconButton>
      <div className={classes.iconBox}>
        <DeviceServiceIcon link={link} />
      </div>
      <Typography variant="subtitle2" className={classes.title}>
        {translateDeviceServiceTitle(link)}
      </Typography>
      <Switch
        className={classes.switch}
        checked={isAvailable}
        color="primary"
        onChange={handleSwitchChanged}
      />
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.description}
      >
        {translateDeviceServiceDescription(link)}
      </Typography>
    </div>
  )
}

export class DraggableDeviceServiceItem extends React.Component<IDeviceServiceItemProps> {
  render() {
    return <DeviceServiceItem {...this.props} item={this.props.item} />
  }
}
