import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Divider,
  IconButton,
  SvgIconProps,
  SxProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../theme'
import {Tooltip} from './Tooltip'

interface IPersistentDrawerWithContentProps {
  content: React.ReactNode
  drawerContent: React.ReactNode
  className?: string
  controlButtonTooltip?: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  isAnchoredToRight?: boolean
  drawerWidth?: number
  OpenIconComp?: React.FC<SvgIconProps>
  CloseIconComp?: React.FC<SvgIconProps>
}

const useStyles = makeStyles<Theme, {drawerWidth: number}>((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden'
  },
  drawer: {
    width: ({drawerWidth}) => drawerWidth,
    height: '100%',
    position: 'absolute',
    top: 0,
    background: theme.palette.background.paper,
    display: 'grid',
    gridTemplateRows: '1fr 1px 56px',
    '.leftAnchor &': {
      borderRight: `solid ${theme.palette.divider} 1px`,
      left: 0,
      marginLeft: ({drawerWidth}) => -drawerWidth,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    '.leftAnchor.isOpen &': {
      marginLeft: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    '.rightAnchor &': {
      right: 0,
      borderLeft: `solid ${theme.palette.divider} 1px`,
      marginRight: ({drawerWidth}) => -drawerWidth,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    '.rightAnchor.isOpen &': {
      marginRight: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  drawerContent: {
    overflow: 'auto'
  },
  controlButtonWrapper: {
    position: 'absolute',
    backgroundColor: 'white',
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    bottom: theme.spacing(0.5),
    '.leftAnchor &': {
      left: '100%'
    },
    '.leftAnchor.isOpen &': {
      left: 'unset',
      right: theme.spacing(0.5)
    },
    '.rightAnchor &': {
      right: '100%'
    },
    '.rightAnchor.isOpen &': {
      right: 'unset',
      left: theme.spacing(0.5)
    }
  },
  content: {
    overflow: 'auto',
    height: '100%',
    '.leftAnchor &': {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: 0
    },
    '.leftAnchor.isOpen &': {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: ({drawerWidth}) => drawerWidth
    },
    '.rightAnchor &': {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: 0
    },
    '.rightAnchor.isOpen &': {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: ({drawerWidth}) => drawerWidth
    }
  },
  closedIconButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0
  }
}))

export const PersistentDrawerWithContent: React.FC<IPersistentDrawerWithContentProps> =
  ({
    content,
    drawerContent,
    className,
    controlButtonTooltip,
    isOpen,
    onOpen,
    onClose,
    isAnchoredToRight,
    drawerWidth = 256,
    OpenIconComp = ChevronLeftIcon,
    CloseIconComp = ChevronRightIcon
  }: IPersistentDrawerWithContentProps) => {
    const classes = useStyles({drawerWidth})
    const {t} = useTranslation()
    return (
      <div
        className={cn(
          classes.root,
          {isOpen},
          isAnchoredToRight ? 'rightAnchor' : 'leftAnchor',
          className
        )}
      >
        <div className={classes.content}>{content}</div>
        <div className={classes.drawer}>
          <div className={classes.drawerContent}>{drawerContent}</div>
          <Divider />
          <div />
          <Box
            className={classes.controlButtonWrapper}
            boxShadow={isOpen ? 0 : 4}
          >
            <Tooltip
              title={
                controlButtonTooltip || isOpen
                  ? t('Hide')
                  : t('Show more options')
              }
            >
              <IconButton
                onClick={isOpen ? onClose : onOpen}
                edge={isAnchoredToRight ? 'end' : 'start'}
                className={isOpen ? undefined : classes.closedIconButton}
              >
                {isOpen ? <OpenIconComp /> : <CloseIconComp />}
              </IconButton>
            </Tooltip>
          </Box>
        </div>
      </div>
    )
  }

interface IPeristentDrawerHeaderProps {
  title: string
  onClose: () => void
}

export const PeristentDrawerHeader: React.FC<IPeristentDrawerHeaderProps> = ({
  title,
  onClose
}: IPeristentDrawerHeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      p: 2,
      alignItems: 'center',
      borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
    }}
  >
    <Typography variant="subtitle1">{title}</Typography>
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Box>
)

interface IPersistentDrawerWithCustomContentProps
  extends Pick<
    IPersistentDrawerWithContentProps,
    | 'content'
    | 'drawerContent'
    | 'className'
    | 'isOpen'
    | 'isAnchoredToRight'
    | 'drawerWidth'
  > {
  header: React.ReactNode
  footer?: React.ReactNode
  childrenSx?: SxProps<Theme>
}

export const PersistentDrawerWithHeaderAndFooter: React.FC<IPersistentDrawerWithCustomContentProps> =
  ({
    content,
    drawerContent,
    className,
    isOpen,
    isAnchoredToRight,
    drawerWidth = 256,
    header,
    footer,
    childrenSx
  }: IPersistentDrawerWithCustomContentProps) => {
    const classes = useStyles({drawerWidth})
    return (
      <div
        className={cn(
          classes.root,
          {isOpen},
          isAnchoredToRight ? 'rightAnchor' : 'leftAnchor',
          className
        )}
      >
        <div className={classes.content}>{content}</div>
        <Box
          className={classes.drawer}
          sx={{
            '&&': {
              gridTemplateRows: footer ? `56px 1fr 64px` : `56px 1fr`
            }
          }}
        >
          {header}
          <Box sx={{overflow: 'auto', ...childrenSx}}>{drawerContent}</Box>
          {footer && (
            <Box
              sx={{
                backgroundColor: 'background.paper',
                borderTop: (theme) => `solid ${theme.palette.divider} 1px`,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                px: 3,
                py: 1.75
              }}
            >
              {footer}
            </Box>
          )}
        </Box>
      </div>
    )
  }
