import GetAppIcon from '@mui/icons-material/GetApp'
import LaunchIcon from '@mui/icons-material/Launch'
import {Box, Button, IconButton} from '@mui/material'
import {gridClasses} from '@mui/x-data-grid'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import {orderBy} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  GetReservationsContactsQuery,
  PermissionCode
} from '../../../__generated__/schema'
import {useEnsurePermissions} from '../../../utils/auth'
import {useEventsPathnameParams} from '../../../utils/pathname'
import {routeTo} from '../../../utils/routes'
import {CartPreviewDrawer} from '../../pages/admin/components/cartPreviewDrawer'
import {
  DataGridTable,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useSellingChannelFormatter,
  useTranslateCountryFormatter
} from '../DataGridTable'
import {RenderOnData} from '../RenderOnData'
import {SubHeaderToolbar} from '../SubHeaderToolbar'
import {useGetReservationsContacts} from './graphql'
import {isLeadDataPropertiesFragmentEmpty} from './utils'

const transformRowData = (
  reservations: GetReservationsContactsQuery['reservations']['items']
) =>
  orderBy(reservations, 'createdAt', 'desc')
    .filter(
      (reservation) =>
        !isLeadDataPropertiesFragmentEmpty(reservation.lead?.data)
    )
    .map((reservation) => reservation)

const IconCellRenderer = ({
  cartId,
  eventId
}: {
  cartId: number
  eventId: number
}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () =>
      history.push(
        routeTo.admin.eventsStatistics.eventInfoReservationsContactsCartDetail(
          eventId,
          cartId
        )
      ),
    [history, cartId, eventId]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <LaunchIcon />
    </IconButton>
  )
}

export const ReservationsContactsDrawerContent: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {eventId} = useEventsPathnameParams()
  const {data, loading, error} = useGetReservationsContacts({
    filter: {eventId},
    eventId,
    paginationInput: {offset: 0, limit: 200}
  })
  const dataGridApiRef = useGridApiRef()
  const dateTimeFormatter = useDateTimeFormatter()
  const sellingChannelFormatter = useSellingChannelFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const translateCountryFormatter = useTranslateCountryFormatter()
  const history = useHistory()
  const handleExited = useCallback(() => {
    history.replace(
      routeTo.admin.eventsStatistics.eventInfoReservationsContacts(eventId)
    )
  }, [eventId, history])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Channel'),
        field: 'sellingChannel',
        valueFormatter: sellingChannelFormatter,
        minWidth: 150
      },
      {
        headerName: t('Price'),
        field: 'price',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 150
      },
      {
        headerName: t('LeadField->Name'),
        field: 'name',
        valueGetter: (params) => params.row.lead?.data.name,
        minWidth: 300
      },
      {
        headerName: t('LeadField->Phone'),
        field: 'phone',
        valueGetter: (params) => params.row.lead?.data.phone,
        minWidth: 200
      },
      {
        headerName: t('LeadField->Email'),
        field: 'email',
        valueGetter: (params) => params.row.lead?.data.email,
        minWidth: 200
      },
      {
        headerName: t('LeadField->Note'),
        field: 'note',
        valueGetter: (params) => params.row.lead?.data.note,
        minWidth: 200
      },
      {
        headerName: t('LeadField->InternalNote'),
        field: 'internalNote',
        valueGetter: (params) => params.row.lead?.data.internalNote,
        minWidth: 200
      },
      {
        headerName: t('LeadField->CompanyName'),
        field: 'companyName',
        valueGetter: (params) => params.row.lead?.data.companyName,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->CompanyIdNumber'),
        field: 'companyIdNumber',
        valueGetter: (params) => params.row.lead?.data.companyIdNumber,
        minWidth: 200
      },
      {
        headerName: t('LeadField->TaxId'),
        field: 'TAXId',
        valueGetter: (params) => params.row.lead?.data.TAXId,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('LeadField->VatId'),
        field: 'VATId',
        valueGetter: (params) => params.row.lead?.data.VATId,
        minWidth: 200
      },
      {
        headerName: t('LeadField->BillingAddressStreet'),
        field: 'billingAddressStreet',
        valueGetter: (params) => params.row.lead?.data.billingAddressStreet,
        minWidth: 300
      },
      {
        headerName: t('LeadField->BillingAddressTown'),
        field: 'billingAddressTown',
        valueGetter: (params) => params.row.lead?.data.billingAddressTown,
        minWidth: 200
      },
      {
        headerName: t('LeadField->BillingPostalCode'),
        field: 'billingPostalCode',
        valueGetter: (params) => params.row.lead?.data.billingPostalCode,
        minWidth: 200
      },
      {
        headerName: t('LeadField->BillingAddressCountry'),
        field: 'billingAddressCountry',
        valueGetter: (params) => params.row.lead?.data.billingAddressCountry,
        valueFormatter: translateCountryFormatter,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressee'),
        field: 'deliveryAddressee',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressee,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressStreet'),
        field: 'deliveryAddressStreet',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressStreet,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressTown'),
        field: 'deliveryAddressTown',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressTown,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryPostalCode'),
        field: 'deliveryPostalCode',
        valueGetter: (params) => params.row.lead?.data.deliveryPostalCode,
        minWidth: 200
      },
      {
        headerName: t('LeadField->DeliveryAddressCountry'),
        field: 'deliveryAddressCountry',
        valueGetter: (params) => params.row.lead?.data.deliveryAddressCountry,
        valueFormatter: translateCountryFormatter,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'icon',
        valueGetter: (params) => params.row.cart.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer cartId={params.value} eventId={eventId} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      eventId,
      sellingChannelFormatter,
      t,
      translateCountryFormatter
    ]
  )
  return (
    <RenderOnData<GetReservationsContactsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading reservations contacts')}
      dataCondition={(data) => Array.isArray(data.reservations.items)}
      ignoreLoadingIfData
    >
      {({reservations}) => (
        <>
          <Box sx={{height: '100%'}}>
            <SubHeaderToolbar
              title={t('Contacts from reservations')}
              rightActions={[
                <Button
                  key="download"
                  variant="text"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() =>
                    dataGridApiRef.current.exportDataAsCsv({
                      fileName: `reservations_contacts_${dayjs(
                        data?.event.startsAt
                      ).format('YYYY_MM_DD_hhmm')}_event_${eventId}`
                    })
                  }
                >
                  {t('Download')}
                </Button>
              ]}
            />
            <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
              <DataGridTable
                sx={{
                  [`& .${gridClasses.withBorderColor}`]: {
                    borderColor: 'divider'
                  },
                  [`& .${gridClasses.columnSeparator}`]: {
                    color: 'divider'
                  }
                }}
                apiRef={dataGridApiRef}
                columns={columns}
                rows={transformRowData(reservations.items)}
                pageSizeOptions={[10, 30, 50]}
                initialState={{
                  pagination: {paginationModel: {pageSize: 30}},
                  pinnedColumns: {left: ['createdAt'], right: ['icon']}
                }}
                columnVisibilityModel={{
                  id: P([PermissionCode.ReadCart])
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                columnHeaderHeight={32}
                experimentalFeatures={{columnGrouping: true}}
                columnGroupingModel={[
                  {
                    groupId: t('Reservation'),
                    children: [{field: 'sellingChannel'}, {field: 'price'}]
                  },
                  {
                    groupId: t('Customer'),
                    children: [
                      {field: 'name'},
                      {field: 'phone'},
                      {field: 'email'}
                    ]
                  },
                  {
                    groupId: t('Notes'),
                    children: [{field: 'note'}, {field: 'internalNote'}],
                    headerAlign: 'left'
                  },
                  {
                    groupId: t('Company'),
                    children: [
                      {field: 'companyName'},
                      {field: 'companyIdNumber'},
                      {field: 'TAXId'},
                      {field: 'VATId'}
                    ],
                    headerAlign: 'left'
                  },
                  {
                    groupId: t('Billing address'),
                    children: [
                      {field: 'billingAddressStreet'},
                      {field: 'billingAddressTown'},
                      {field: 'billingPostalCode'},
                      {field: 'billingAddressCountry'}
                    ],
                    headerAlign: 'left'
                  },
                  {
                    groupId: t('Delivery address'),
                    children: [
                      {field: 'deliveryAddressee'},
                      {field: 'deliveryAddressStreet'},
                      {field: 'deliveryAddressTown'},
                      {field: 'deliveryPostalCode'},
                      {field: 'deliveryAddressCountry'}
                    ],
                    headerAlign: 'left'
                  }
                ]}
                localeText={{
                  noRowsLabel: t('No reservations contacts to show')
                }}
              />
            </Box>
          </Box>
          {P([PermissionCode.ReadCart]) && (
            <Route
              path={routeTo.admin.eventsStatistics.eventInfoReservationsContactsCartDetail(
                ':eventId',
                ':cartId'
              )}
              exact
            >
              <CartPreviewDrawer onExited={handleExited} />
            </Route>
          )}
        </>
      )}
    </RenderOnData>
  )
}
