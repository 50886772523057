import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import {Chip, ClickAwayListener, Grow, Paper, SxProps} from '@mui/material'
import Popper from '@mui/material/Popper'
import dayjs from 'dayjs'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {Tooltip} from '../../../common'
import {DatePicker} from '../../../common/datePicker/DatePicker'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {useTransformDatePickerValueToDate} from '../../../common/datePicker/utils'

const useGetChipLabel = () => {
  const {t} = useTranslation()
  const transformDatePickerValueToDate = useTransformDatePickerValueToDate()
  const {formatDateNumeric} = useDateTimeFormatters()
  return (date?: IDataPickerData) => {
    const transformedDateRange = transformDatePickerValueToDate(date?.value)
    if (date?.group === TabGroup.Custom) {
      return `${t('Paid')} ${[
        date?.dateRange?.dateFrom &&
          formatDateNumeric(date.dateRange.dateFrom.toDate()),
        date?.dateRange?.dateTo &&
          formatDateNumeric(date.dateRange.dateTo.toDate())
      ]
        .filter(Boolean)
        .join(' - ')}`
    } else {
      return `${t('Paid')} ${[
        transformedDateRange?.dateFrom &&
          formatDateNumeric(transformedDateRange.dateFrom.toDate()),
        transformedDateRange?.dateTo &&
          formatDateNumeric(transformedDateRange.dateTo.toDate())
      ]
        .filter(Boolean)
        .join(' - ')}`
    }
  }
}

interface IPaymentDateChipProps {
  selectedDate?: IDataPickerData
  setSelectedDate: (date?: IDataPickerData) => void
  popperSx?: SxProps<Theme>
}

export const PaymentDateChip: React.FC<IPaymentDateChipProps> = ({
  selectedDate,
  setSelectedDate,
  popperSx
}: IPaymentDateChipProps) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const getChipLabel = useGetChipLabel()
  const handleChipClick = useCallback(
    (event) => {
      event.stopPropagation()
      setAnchorEl(anchorEl ? null : event.currentTarget)
    },
    [anchorEl]
  )
  const handleClickAway = useCallback(() => setAnchorEl(null), [])
  const handleApplyButtonClick = useCallback(
    (date: IDataPickerData) => {
      setSelectedDate(date)
      setAnchorEl(null)
    },
    [setSelectedDate]
  )
  const handleDeleteChipClick = useCallback(
    () => setSelectedDate(undefined),
    [setSelectedDate]
  )
  const isDateSelected =
    selectedDate && Object.values(selectedDate).some((v) => v !== undefined)
  return (
    <>
      <Tooltip title={t('Payment date')}>
        <Chip
          size="small"
          variant="outlined"
          icon={<AccountBalanceWalletOutlinedIcon />}
          deleteIcon={isDateSelected ? <CancelIcon /> : <ArrowDropDownIcon />}
          onDelete={isDateSelected ? handleDeleteChipClick : handleChipClick}
          onClick={handleChipClick}
          label={isDateSelected ? getChipLabel(selectedDate) : undefined}
        />
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={popperSx}
      >
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left top'
              }}
            >
              <Paper elevation={8}>
                <DatePicker
                  onApplyButtonClick={handleApplyButtonClick}
                  onCloseButtonClick={handleClickAway}
                  selectedValues={selectedDate}
                  groups={[TabGroup.Now, TabGroup.Past]}
                  excludedValues={[TabNow.UntilToday, TabNow.FromToday]}
                  minDateFrom={
                    selectedDate?.dateRange?.dateTo
                      ? dayjs(selectedDate?.dateRange?.dateTo)
                          .subtract(1, 'year')
                          .startOf('d')
                      : dayjs().subtract(1, 'year').startOf('d')
                  }
                  maxDateFrom={
                    selectedDate?.dateRange?.dateTo || dayjs().endOf('d')
                  }
                  maxDateTo={dayjs().endOf('d')}
                />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
