import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {ErrorMessages} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useCreateProduct} from './graphql'
import {ProductForm} from './ProductForm'
import {IProductForm, ProductFormField, ProductFormLocation} from './types'

const CREATE_PRODUCT_FORM_ID = 'createProductForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface ICreateProductDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateProductDrawer: React.FC<ICreateProductDrawerProps> = ({
  isOpen,
  onClose
}: ICreateProductDrawerProps) => {
  const {t} = useTranslation()
  const createProduct = useCreateProduct()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const methods = useForm<IProductForm>()
  const {setError} = methods
  const history = useHistory()
  const classes = useStyles()
  const handleSubmit = useCallback(
    async (data: IProductForm) => {
      try {
        setShowBackdrop(true)
        const product = await createProduct({
          input: {
            ...data,
            internalCode: data[ProductFormField.InternalCode] || undefined,
            internalDescription:
              data[ProductFormField.InternalDescription] || undefined,
            receiptName: data[ProductFormField.ReceiptName] || undefined,
            priceLookupCode:
              data[ProductFormField.PriceLookupCode] || undefined,
            productTypeId: parseInt(data[ProductFormField.ProductTypeId], 10)
          }
        })
        if (product.data) {
          history.push(
            routeTo.admin.products.detail(product.data.createProduct.id)
          )
          addInfoNotification(t('Product created'))
        }
        onClose()
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.PriceLookupCodeAlreadyExists
          )
        ) {
          setError(
            ProductFormField.PriceLookupCode,
            t('This PLU code is already used in another product.')
          )
        } else {
          defaultErrorHandler(error, t('Error while creating product'))
        }
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      createProduct,
      defaultErrorHandler,
      history,
      onClose,
      setError,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      classes={{paper: classes.drawerPaper}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create product')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_PRODUCT_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenClassName={classes.drawerChildren}
      >
        <FormContext {...methods}>
          <ProductForm
            location={ProductFormLocation.CreateProduct}
            formId={CREATE_PRODUCT_FORM_ID}
            onSubmit={handleSubmit}
          />
        </FormContext>
      </DrawerTemplate>
    </Drawer>
  )
}
