import {Radio} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import {FormControlLabelProps} from '@mui/material/FormControlLabel/FormControlLabel'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  formControlLabel: {
    paddingBottom: theme.spacing(3)
  }
}))

interface IDivisionFormControlLabelProps
  extends Omit<FormControlLabelProps, 'control'> {
  control?: FormControlLabelProps['control']
}

export const DivisionFormControlLabel: React.FC<IDivisionFormControlLabelProps> =
  (props: IDivisionFormControlLabelProps) => {
    const classes = useStyles()
    const {
      control = <Radio color="primary" />,
      className = classes.formControlLabel,
      ...rest
    } = props
    return (
      <FormControlLabel {...rest} control={control} className={className} />
    )
  }
