import {CodeFormulaErrorMessage} from './types'

const MAX_INTEGER_VALUE = 2147483647

export const validateCodeFormula = (code: string) => {
  if (
    code.toLowerCase().charAt(0) === '0' &&
    (code.toLowerCase().includes('*') || code.toLowerCase().includes('x'))
  ) {
    return {isValid: false, message: CodeFormulaErrorMessage.ZeroQuantity}
  }
  if (code.includes('*')) {
    const split = code.split('*')
    if (!split[1]) {
      return {isValid: false, message: CodeFormulaErrorMessage.MissingPlu}
    }
    if (parseInt(split[1], 10) > MAX_INTEGER_VALUE) {
      return {isValid: false, message: CodeFormulaErrorMessage.MaxPluLength}
    }
    if (parseInt(split[1], 10) === 0) {
      return {
        isValid: false,
        message: CodeFormulaErrorMessage.PluCodeCannotBeZero
      }
    }
  } else if (code.includes('x')) {
    const split = code.split('x')
    if (!split[1]) {
      return {isValid: false, message: CodeFormulaErrorMessage.MissingPlu}
    }
    if (parseInt(split[1], 10) > MAX_INTEGER_VALUE) {
      return {isValid: false, message: CodeFormulaErrorMessage.MaxPluLength}
    }
    if (parseInt(split[1], 10) === 0) {
      return {
        isValid: false,
        message: CodeFormulaErrorMessage.PluCodeCannotBeZero
      }
    }
  } else if (code.includes('X')) {
    const split = code.split('X')
    if (!split[1]) {
      return {isValid: false, message: CodeFormulaErrorMessage.MissingPlu}
    }
    if (parseInt(split[1], 10) > MAX_INTEGER_VALUE) {
      return {isValid: false, message: CodeFormulaErrorMessage.MaxPluLength}
    }
    if (parseInt(split[1], 10) === 0) {
      return {
        isValid: false,
        message: CodeFormulaErrorMessage.PluCodeCannotBeZero
      }
    }
  } else {
    if (parseInt(code, 10) > MAX_INTEGER_VALUE) {
      return {isValid: false, message: CodeFormulaErrorMessage.MaxPluLength}
    }
    if (parseInt(code, 10) === 0) {
      return {
        isValid: false,
        message: CodeFormulaErrorMessage.PluCodeCannotBeZero
      }
    }
  }
  return /^\d{0,}\s*([*xX])?\s*\d+$/g.test(code)
    ? {isValid: true}
    : {isValid: false, message: CodeFormulaErrorMessage.InvalidInput}
}
