import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MessagesFilterInput} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {CreateMessageDrawer} from '../components/messageDrawer/CreateMessageDrawer'
import {PrimaryHeader} from '../Header'
import {MessagesList} from './MessagesList'
import {DEFAULT_MESSAGES_FILTER_INPUT, MessagesSearch} from './MessagesSearch'

export const MessagesPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] = useState<MessagesFilterInput>(
    DEFAULT_MESSAGES_FILTER_INPUT
  )
  const {
    state: isCreateMessageDrawerOpen,
    setTrue: openCreateMessageDrawer,
    setFalse: closeCreateMessageDrawer
  } = useBooleanState(false)
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Messages')}
          search={<MessagesSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <MessagesList
          onCreateButtonClick={openCreateMessageDrawer}
          searchFilter={searchFilter}
        />
        <CreateMessageDrawer
          isOpen={isCreateMessageDrawerOpen}
          onClose={closeCreateMessageDrawer}
          leads={[]}
        />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
