import UploadIcon from '@mui/icons-material/CloudUpload'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'

import {Theme} from '../../../../theme'

import {InputDropzoneButton} from './InputDropzoneButton'
import {DROPZONE_WIDTH} from './utils'

const useStyles = makeStyles<Theme>((theme) => ({
  dropZone: {
    width: `${DROPZONE_WIDTH}vw`,
    height: 160,
    background: theme.palette.background.default,
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    flexShrink: 0
  }
}))

interface IDropzoneProps {
  onDrop: (acceptedFiles: Array<File>) => void
  className?: string
}

export const Dropzone: React.FC<IDropzoneProps> = ({
  onDrop,
  className
}: IDropzoneProps) => {
  const {getRootProps, getInputProps, open} = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxSize: 10485760,
    onDrop
  })
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Grid
      container
      className={cn(classes.dropZone, className)}
      direction="column"
      alignItems="center"
      justifyContent="center"
      {...getRootProps()}
    >
      <Grid item>
        <UploadIcon color="primary" />
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography>{t('Drop images here or')}</Typography>
        </Grid>
        <Grid item>
          <input {...getInputProps()} />
          <InputDropzoneButton onClick={open}>
            {t('click here to upload.')}
          </InputDropzoneButton>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>{t('Image files up to 10MB.')}</Typography>
      </Grid>
    </Grid>
  )
}
