import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  VoucherTransactionIntentsQuery,
  VoucherTransactionIntentsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const VOUCHER_TRANSACTION_INTENTS = gql`
  ${USER_FIELDS}
  ${PAGINATION_FRAGMENT}
  query VoucherTransactionIntents(
    $paginationInput: PaginationInput!
    $filter: VoucherTransactionIntentsFilterInput
  ) {
    voucherTransactionIntents(
      paginationInput: $paginationInput
      filter: $filter
    ) {
      items {
        id
        createdAt
        voucher {
          id
          code
        }
        amount
        type
        state
        reason
        note
        createdBy {
          ...UserFields
        }
        paymentIntent {
          id
          cart {
            id
          }
        }
        refundIntent {
          id
          claim {
            id
          }
        }
      }
      totals {
        amount
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useVoucherTransactionIntents = (
  variables: VoucherTransactionIntentsQueryVariables
) =>
  useQuery<
    VoucherTransactionIntentsQuery,
    VoucherTransactionIntentsQueryVariables
  >(VOUCHER_TRANSACTION_INTENTS, {variables, fetchPolicy: 'network-only'})

export const SEARCH_LIGHTWEIGHT_VOUCHER_CAMPAINGS = gql`
  query SearchLightweightVoucherCampaings(
    $filter: VoucherCampaignsFilterInput
    $paginationInput: PaginationInput!
  ) {
    voucherCampaigns(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        name
      }
    }
  }
`
