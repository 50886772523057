import {useTranslation} from 'react-i18next'
import {
  DataSetType,
  TemplateFileType,
  TemplateState,
  TemplateType,
  TemplateVersionState
} from '../__generated__/schema'

export const useTranslateTemplateState = () => {
  const {t} = useTranslation()
  const translatedStates: Record<TemplateState, string> = {
    [TemplateState.Active]: t('TemplateState->Active'),
    [TemplateState.Inactive]: t('TemplateState->Inactive')
  }
  return (state: TemplateState) => translatedStates[state]
}

export const useTranslateTemplateVersionState = () => {
  const {t} = useTranslation()
  const translatedStates: Record<TemplateVersionState, string> = {
    [TemplateVersionState.Active]: t('TemplateVersionState->Active'),
    [TemplateVersionState.Inactive]: t('TemplateVersionState->Inactive')
  }
  return (state: TemplateVersionState) => translatedStates[state]
}

export const useTranslateTemplateFileType = () => {
  const {t} = useTranslation()
  const translatedFileTypes: Record<TemplateFileType, string> = {
    [TemplateFileType.Zpl]: t('TemplateFileType->Zpl'),
    [TemplateFileType.Pdf]: t('TemplateState->Pdf')
  }
  return (fileType: TemplateFileType) => translatedFileTypes[fileType]
}

export const useTranslateTemplateDatasetType = () => {
  const {t} = useTranslation()
  const translatedDatasetTypes: Record<DataSetType, string> = {
    [DataSetType.SalesPrinting]: t('DataSetType->SalesPrinting'),
    [DataSetType.Claim]: t('DataSetType->Claim'),
    [DataSetType.PaymentReport]: t('DataSetType->PaymentReport'),
    [DataSetType.WarehouseDocument]: t('DataSetType->WarehouseDocument'),
    [DataSetType.InventoryCheck]: t('DataSetType->InventoryCheck')
  }
  return (dataSet: DataSetType) => translatedDatasetTypes[dataSet]
}

export const useTranslateTemplateType = () => {
  const {t} = useTranslation()
  const translatedTemplateTypes: Record<TemplateType, string> = {
    [TemplateType.TicketPrinting]: t('TemplateType->TicketPrinting'),
    [TemplateType.ClaimPrinting]: t('TemplateType->ClaimPrinting'),
    [TemplateType.Receipt]: t('TemplateType->Receipt'),
    [TemplateType.DailyCashierReport]: t('TemplateType->DailyCashierReport'),
    [TemplateType.MonthlyECommerceReport]: t(
      'TemplateType->MonthlyECommerceReport'
    ),
    [TemplateType.MonthlyReport]: t('TemplateType->MonthlyReport'),
    [TemplateType.IncomingWarehouseDocument]: t(
      'TemplateType->IncomingWarehouseDocument'
    ),
    [TemplateType.OutgoingWarehouseDocument]: t(
      'TemplateType->OutgoingWarehouseDocument'
    ),
    [TemplateType.InventoryCheck]: t('TemplateType->InventoryCheck')
  }
  return (templateType: TemplateType) => translatedTemplateTypes[templateType]
}
