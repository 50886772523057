import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useStore} from 'react-redux'
import {presentObjectsSelector} from '../../../../../../editor/redux/objects/selectors'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useVenuesPathnameParams} from '../../../../../../utils/pathname'
import {IEditorFormData} from '../EditorForm'
import {
  useActivateAuditoriumLayout,
  useArchiveAuditoriumLayout,
  useUpdateAuditoriumLayout
} from '../graphql'

export const useSubmitActions = () => {
  const {t} = useTranslation()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {auditoriumLayoutId} = useVenuesPathnameParams()
  const archiveAuditoriumLayout = useArchiveAuditoriumLayout()
  const activateAuditoriumLayout = useActivateAuditoriumLayout()
  const updateAuditoriumLayout = useUpdateAuditoriumLayout()
  const store = useStore()

  const _onSubmit = useCallback(
    async (formData: IEditorFormData) => {
      const state = store.getState()
      await updateAuditoriumLayout({
        id: auditoriumLayoutId,
        data: {
          name: formData.name,
          layout: presentObjectsSelector(state)
        }
      })
    },
    [auditoriumLayoutId, store, updateAuditoriumLayout]
  )

  const errorMsg = t('Error while saving layout.')

  const onArchive = useCallback(
    async (formData: IEditorFormData) => {
      try {
        setShowBackdrop(true)
        await _onSubmit(formData)
        await archiveAuditoriumLayout({id: auditoriumLayoutId})
        addInfoNotification(t('Auditorium layout was updated.'))
      } catch (err) {
        defaultErrorHandler(err, errorMsg)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _onSubmit,
      addInfoNotification,
      archiveAuditoriumLayout,
      auditoriumLayoutId,
      defaultErrorHandler,
      errorMsg,
      setShowBackdrop,
      t
    ]
  )

  const onActivate = useCallback(
    async (formData: IEditorFormData) => {
      try {
        setShowBackdrop(true)
        await _onSubmit(formData)
        await activateAuditoriumLayout({id: auditoriumLayoutId})
        addInfoNotification(t('Auditorium layout was updated.'))
      } catch (err) {
        defaultErrorHandler(err, errorMsg)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _onSubmit,
      activateAuditoriumLayout,
      addInfoNotification,
      auditoriumLayoutId,
      defaultErrorHandler,
      errorMsg,
      setShowBackdrop,
      t
    ]
  )

  const onSubmit = useCallback(
    async (formData: IEditorFormData) => {
      try {
        setShowBackdrop(true)
        await _onSubmit(formData)
        addInfoNotification(t('All changes were saved'))
      } catch (err) {
        defaultErrorHandler(err, errorMsg)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _onSubmit,
      addInfoNotification,
      defaultErrorHandler,
      errorMsg,
      setShowBackdrop,
      t
    ]
  )

  return {onArchive, onSubmit, onActivate}
}
