import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

export const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      general: {id: string; label: string}
      message: {id: string; label: string}
    } => ({
      general: {
        id: 'general',
        label: t('General')
      },
      message: {
        id: 'message',
        label: t('Message')
      }
    }),
    [t]
  )
}

export enum MessageFormField {
  LeadIds = 'leadIds',
  Body = 'body',
  InternalNote = 'internalNote',
  Subject = 'subject',
  SenderName = 'senderName',
  ReplyTo = 'replyTo'
}

export interface IMessageForm {
  [MessageFormField.LeadIds]: number[]
  [MessageFormField.Body]: string
  [MessageFormField.InternalNote]: string
  [MessageFormField.Subject]: string
  [MessageFormField.SenderName]: string
  [MessageFormField.ReplyTo]?: string
}
