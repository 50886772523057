import CheckIcon from '@mui/icons-material/Check'
import {Button} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ErrorMessages,
  PassCodeCheckAdditionalInformation,
  PassCodeCheckPropertiesFragment,
  PassCodeCheckState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useGetEventSeatLocation} from '../../../../../hooks/getEventSeatLocation'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useCheckPassCodeParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {LoginIcon} from '../../../../common/icons/LoginIcon'
import {LogoutIcon} from '../../../../common/icons/LogoutIcon'
import {QuestionMarkIcon} from '../../../../common/icons/QuestionMarkIcon'
import {StopIcon} from '../../../../common/icons/StopIcon'
import {InfoModal, InfoModalColor} from '../../../../common/InfoModal'
import {CheckMode} from '../types'
import {useGetTourTimeSlotDescription} from '../utils'

interface IInfoModalControllerProps {
  className?: string
  passCode: string | null
  passCodeCheck: PassCodeCheckPropertiesFragment | null
  error: any | null
  createUpdatePassCodeCheckHandler: (args: {
    authorizeDiscount: boolean
    passCode: string
    id: number
  }) => () => Promise<void>
  checkMode: CheckMode | null
  checkPassCodeIn: (passCode: string) => Promise<void>
  checkPassCodeOut: (passCode: string) => Promise<void>
}

export const InfoModalController: React.FC<IInfoModalControllerProps> = ({
  passCodeCheck,
  passCode,
  className,
  error,
  createUpdatePassCodeCheckHandler,
  checkMode,
  checkPassCodeIn,
  checkPassCodeOut
}: IInfoModalControllerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {checkType, entityId} = useCheckPassCodeParams()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const getEventSeatLocation = useGetEventSeatLocation()
  const getTourTimeSlotDescription = useGetTourTimeSlotDescription()
  const history = useHistory()
  const handleSuccessInfoClick = useCallback(
    (itemId: number) => () => {
      history.push(
        routeTo.admin.checkTickets.ticketInfo(checkType, entityId, itemId)
      )
    },
    [checkType, entityId, history]
  )
  if (passCodeCheck === null && error === null && passCode === null) {
    return null
  }
  const commonErrorProps = {
    className,
    title: t('{{passCode}} • Denied', {passCode}),
    color: InfoModalColor.Red,
    Icon: StopIcon
  }
  if (error) {
    switch (error.message) {
      case ErrorMessages.InvalidTicketItemCheckStatus:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={t('Discount validation has failed')}
            secondaryDescription={t('The ticket has been already checked.')}
          />
        )
      case ErrorMessages.ItemNotFoundDueToFilterEventIds:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={t('Different event')}
          />
        )
      case ErrorMessages.ItemNotFoundDueToFilterTourTimeSlotIds:
      case ErrorMessages.ItemNotFoundDueToFilterTourIds:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={t('Code not found for this tour or time slot')}
          />
        )
      case ErrorMessages.PassCodeNotFound:
      case ErrorMessages.ItemNotFound:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={t('Code was not found')}
          />
        )
      case ErrorMessages.EventNotPublished:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={t(
              "Event settings doesn't allow to check tickets. Please contact your supervisor."
            )}
          />
        )
      case ErrorMessages.RequestMadeUnauthorizedUser:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={ErrorMessages.RequestMadeUnauthorizedUser}
          />
        )
      case ErrorMessages.InvalidPassCodeCheckState:
        return (
          <InfoModal
            {...commonErrorProps}
            primaryDescription={ErrorMessages.InvalidPassCodeCheckState}
          />
        )
      default:
        return null
    }
  }
  if (checkMode === null && passCode) {
    return (
      <InfoModal
        className={className}
        title={t('{{passCode}} • Choose direction', {passCode})}
        color={InfoModalColor.Blue}
        Icon={QuestionMarkIcon}
        actions={
          <>
            <Button color="primary" onClick={() => checkPassCodeOut(passCode)}>
              {t('Check out')}
            </Button>
            <Button color="primary" onClick={() => checkPassCodeIn(passCode)}>
              {t('Check in')}
            </Button>
          </>
        }
      />
    )
  }
  if (passCodeCheck) {
    const {id: itemId} =
      passCodeCheck.ticketItem ?? passCodeCheck.tourItem ?? {}
    if (passCodeCheck.state === PassCodeCheckState.Allowed) {
      const allowedCommonProps = {
        className,
        title: t('{{passCode}} • Allowed', {passCode}),
        color: InfoModalColor.Green,
        onInfoClick:
          P([PermissionCode.ReadItem]) && itemId
            ? handleSuccessInfoClick(itemId)
            : undefined
      }
      if (
        passCodeCheck.additionalInformation ===
        PassCodeCheckAdditionalInformation.CheckedOut
      ) {
        return (
          <InfoModal
            {...allowedCommonProps}
            Icon={LogoutIcon}
            primaryDescription={t('Checked out')}
          />
        )
      } else if (
        [
          PassCodeCheckAdditionalInformation.CheckedIn,
          PassCodeCheckAdditionalInformation.AuthorizedDiscount
        ].includes(passCodeCheck.additionalInformation)
      ) {
        return (
          <InfoModal
            {...allowedCommonProps}
            Icon={LoginIcon}
            primaryDescription={
              passCodeCheck.tourItem
                ? passCodeCheck.tourItem.admissionType.name
                : undefined
            }
            secondaryDescription={
              passCodeCheck.ticketItem
                ? getEventSeatLocation(passCodeCheck.ticketItem.eventSeat)
                : passCodeCheck.tourItem
                ? getTourTimeSlotDescription(
                    passCodeCheck.tourItem.tourTimeSlot
                  )
                : undefined
            }
          />
        )
      }
    }
    if (
      passCodeCheck.state === PassCodeCheckState.Pending &&
      passCodeCheck.ticketItem &&
      passCodeCheck.ticketItem.appliedDiscounts.length &&
      passCode
    ) {
      return (
        <InfoModal
          className={className}
          title={t('{{passCode}} • Verify discount', {passCode})}
          color={InfoModalColor.Blue}
          Icon={QuestionMarkIcon}
          primaryDescription={
            passCodeCheck.ticketItem.appliedDiscounts[0].discount.name
          }
          secondaryDescription={
            passCodeCheck.ticketItem.appliedDiscounts[0].discount
              .internalDescription ?? ''
          }
          actions={
            <>
              <Button
                color="primary"
                startIcon={<StopIcon />}
                onClick={createUpdatePassCodeCheckHandler({
                  authorizeDiscount: false,
                  id: passCodeCheck.id,
                  passCode
                })}
              >
                {t('Deny')}
              </Button>
              <Button
                color="primary"
                startIcon={<CheckIcon />}
                onClick={createUpdatePassCodeCheckHandler({
                  authorizeDiscount: true,
                  id: passCodeCheck.id,
                  passCode
                })}
              >
                {t('Allow')}
              </Button>
            </>
          }
        />
      )
    }
    if (passCodeCheck?.state === PassCodeCheckState.Denied) {
      if (
        passCodeCheck.additionalInformation ===
        PassCodeCheckAdditionalInformation.UnauthorizedDiscount
      ) {
        return (
          <InfoModal
            {...commonErrorProps}
            onInfoClick={
              P([PermissionCode.ReadItem]) && itemId
                ? handleSuccessInfoClick(itemId)
                : undefined
            }
            primaryDescription={t('Unauthorized discount')}
          />
        )
      } else if (
        passCodeCheck.additionalInformation ===
        PassCodeCheckAdditionalInformation.AlreadyCheckedIn
      ) {
        return (
          <InfoModal
            {...commonErrorProps}
            onInfoClick={
              P([PermissionCode.ReadItem]) && itemId
                ? handleSuccessInfoClick(itemId)
                : undefined
            }
            primaryDescription={t('Already checked in')}
          />
        )
      } else if (
        passCodeCheck.additionalInformation ===
        PassCodeCheckAdditionalInformation.AlreadyCheckedOut
      ) {
        return (
          <InfoModal
            {...commonErrorProps}
            onInfoClick={
              P([PermissionCode.ReadItem]) && itemId
                ? handleSuccessInfoClick(itemId)
                : undefined
            }
            primaryDescription={t('Already checked out')}
          />
        )
      } else if (
        passCodeCheck.additionalInformation ===
        PassCodeCheckAdditionalInformation.TicketClaimed
      ) {
        return (
          <InfoModal
            {...commonErrorProps}
            onInfoClick={
              P([PermissionCode.ReadItem]) && itemId
                ? handleSuccessInfoClick(itemId)
                : undefined
            }
            primaryDescription={t('Ticket was claimed')}
          />
        )
      } else if (
        passCodeCheck.additionalInformation ===
        PassCodeCheckAdditionalInformation.GateIsNotOpen
      ) {
        const gateOpensAt = passCodeCheck.tourItem
          ? new Date(passCodeCheck.tourItem.tourTimeSlot.gateOpensAt)
          : passCodeCheck.ticketItem
          ? new Date(passCodeCheck.ticketItem.eventSeat.event.gateOpensAt)
          : undefined
        const gateClosesAt = passCodeCheck.tourItem
          ? new Date(passCodeCheck.tourItem.tourTimeSlot.gateClosesAt)
          : passCodeCheck.ticketItem
          ? new Date(passCodeCheck.ticketItem.eventSeat.event.gateClosedAt)
          : undefined
        const entityName = passCodeCheck.tourItem
          ? passCodeCheck.tourItem.tourTimeSlot.names
          : passCodeCheck.ticketItem
          ? passCodeCheck.ticketItem.eventSeat.event.names
          : undefined
        return (
          <InfoModal
            {...commonErrorProps}
            onInfoClick={
              P([PermissionCode.ReadItem]) && itemId
                ? handleSuccessInfoClick(itemId)
                : undefined
            }
            primaryDescription={
              gateOpensAt
                ? dayjs().isBefore(gateOpensAt)
                  ? t('Checking not started yet')
                  : t('Checking has ended')
                : undefined
            }
            secondaryDescription={
              gateOpensAt && gateClosesAt && entityName
                ? `${formatDateNumeric(gateOpensAt)} • ${formatTime(
                    gateOpensAt
                  )} • ${formatDateNumeric(gateClosesAt)} - ${formatTime(
                    gateClosesAt
                  )} • ${getUserLocaleTranslation(entityName)}`
                : undefined
            }
          />
        )
      }
    }
  }
  return null
}
