import {
  TemplatePropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {DeviceSettingsState} from './types'

export const getAssignedTemplatesByType = (
  state: DeviceSettingsState,
  type: TemplateType | string
): TemplatePropertiesFragment[] => state.assignedTemplatesByType[type] || []
