import React from 'react'
import {useTranslation} from 'react-i18next'
import {FieldErrors, FieldValues, FormContextValues} from 'react-hook-form'

import {InputRow} from '../common'
import {UncontrolledFormTextInput} from '../form/FormTextInput'
import {FormFieldName} from '../form/types'
import {useIsStringWithMaxLength} from '../../utils/formsValidations'
// import {AddressInput} from '../../__generated__/schema'

interface IUncontrolledFormAddressFieldsProps<
  FormValues extends FieldValues = FieldValues
> {
  namePrefix: FormFieldName<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  triggerValidation: FormContextValues<FormValues>['triggerValidation']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  errors: FieldErrors<FormValues>
}

export const UncontrolledFormAddressFields = <
  FormValues extends FieldValues = FieldValues
>({
  register,
  namePrefix,
  setValue,
  triggerValidation,
  watch,
  errors
}: IUncontrolledFormAddressFieldsProps<FormValues>) => {
  const {t} = useTranslation()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)

  return (
    <>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<FormValues>
            label={t('Complex')}
            key="complex"
            name={`${namePrefix}[complex]` as FormFieldName<FormValues>}
            register={register}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            fullWidth
          />,
          <UncontrolledFormTextInput<FormValues>
            label={t('Street')}
            key="street"
            name={`${namePrefix}[street]` as FormFieldName<FormValues>}
            register={register}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<FormValues>
            label={t('Town')}
            key="town"
            name={`${namePrefix}[town]` as FormFieldName<FormValues>}
            register={register}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            required
            fullWidth
          />,
          <UncontrolledFormTextInput<FormValues>
            label={t('Postal code')}
            key="postalCode"
            name={`${namePrefix}[postalCode]` as FormFieldName<FormValues>}
            register={register}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<FormValues>
            label={t('Country')}
            key="country"
            name={`${namePrefix}[country]` as FormFieldName<FormValues>}
            register={register}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            errors={errors}
            setValue={setValue}
            triggerValidation={triggerValidation}
            watch={watch}
            required
            fullWidth
          />
        ]}
        xs={6}
      />
    </>
  )
}
