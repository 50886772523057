import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'

import {PermissionCode} from '../../../__generated__/schema'
import {useGetClientCountryCodes} from '../../../hooks/clientCountryCodes'
import {
  EnsurePermissions,
  useEnsurePermissions,
  useUserInfo
} from '../../../utils/auth'
import {
  DISCOUNTS_PARAMS as DP,
  EVENTS_PARAMS,
  SHOWS_PARAMS as SP,
  VENUES_PARAMS as VP
} from '../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../utils/routes'

import {useLocale} from '../../context/locale'
import {AccessZones} from './accessZones'
import {ActivityListener} from './ActivityListener'
import {AdmissionTypes} from './admissionTypes'
import {AdmissionTypesStatistics} from './admissionTypesStatistics'
import {ApiGrants} from './apiGrants'
import {BusinessPartners} from './businessPartners'
import {Carts} from './carts'
import {CartsSupport} from './cartsSupport'
import {CashCounter} from './cashCounter'
import {CashDesk} from './cashDesk'
import {CheckTickets} from './checkTickets'
import {Claims} from './claims'
import {Clients} from './clients'
import {Company} from './company'
import {CostCenters} from './costCenters'
import {CustomerDisplay} from './customerDisplay'
import {CustomerGroups} from './customerGroups'
import {Customers} from './customers'
import {Dashboard} from './dashboard'
import {DeviceSettingsPage} from './deviceSettings'
import {Discount} from './discounts/Discount'
import {Discounts} from './discounts/Discounts'
import {DiscountsStatistics} from './discountsStatistics'
import {AddDivision} from './divisions/AddDivision'
import {DivisionList} from './divisions/DivisionList'
import {EditDivision} from './divisions/EditDivision'
import {DuplicateAuditoriumLayout} from './duplicateAuditoriumLayout'
import {EffectiveClientIndicator} from './EffectiveClientIndicator'
import {EventCategories} from './eventCategories'
import {Events} from './events'
import {EventsStatistics} from './eventsStatistics'
import {InventoryChecks} from './inventoryChecks'
import {InventoryTransactions} from './inventoryTransactions'
import {MarketingLabels} from './marketingLabels'
import {Messages} from './messages'

import {PageNotFound} from './PageNotFound'
import {PassCodeCheckHistory} from './passCodeCheckHistory'
import {PaymentMethods} from './paymentMethods'
import {PaymentReports} from './paymentReports'
import {PaymentsOverview} from './paymentsOverview'
import {PaymentsSupport} from './paymentsSupport'
import {ProductGroups} from './productGroups'
import {Products} from './products'
import {ProductsStatistics} from './productsStatistics'
import {ProductTypes} from './productTypes'
import {Roles} from './roles'
import {SeatOptions} from './seatOptions'
import {AddLibraryShow} from './shows/AddLibraryShow'
import {AddShow} from './shows/addShow/AddShow'
import {ClientShowList} from './shows/ClientShowList'
import {EditClientShow} from './shows/editClientShow/EditClientShow'
import {EditLibraryShow} from './shows/editLibraryShow/EditLibraryShow'
import {useFetchShowSelectFields} from './shows/graphql'
import {LibraryShowList} from './shows/LibraryShowList'
import {ShowsStatistics} from './showsStatistics'
import {Templates} from './templates'
import {Tours} from './tours'
import {TourTimeSlotsStatistics} from './tourTimeSlotsStatistics'
import {AddUser} from './users/AddUser'
import {InviteUsers} from './users/inviteUsers/InviteUsers'
import {EditUser} from './users/userInfo/EditUser'
import {Profile} from './users/userInfo/Profile'
import {UserList} from './users/UserList'
import {Venues} from './venues'
import {EditAuditoriumLayout} from './venues/auditoriumLayouts/edit'
import {EditLayoutPricing} from './venues/layoutPricings/edit'
import {VenueList} from './venues/VenueList'
import {VoucherCampaigns} from './voucherCampaigns'
import {Vouchers} from './vouchers'
import {VoucherTransactions} from './voucherTransactions'
import {WarehouseDocuments} from './warehouseDocuments'
import {Warehouses} from './warehouses'
import {WarehouseStocks} from './warehouseStocks'
import {Websites} from './websites'

const useDetectLocaleChange = () => {
  const {user} = useUserInfo()
  const {localeCode, setLocale} = useLocale()

  useEffect(() => {
    if (user && user.localeCode !== localeCode) {
      setLocale(user.localeCode)
    }
  }, [localeCode, setLocale, user])
}

export const AdminPage: React.FC = () => {
  const {P} = useEnsurePermissions()

  useDetectLocaleChange()

  // load data into cache here, to have smoother UX later on
  useGetClientCountryCodes()
  useFetchShowSelectFields()
  const {effectiveClient} = useUserInfo()

  return (
    <>
      <ActivityListener />
      <Switch>
        {P([PermissionCode.ReadDiscounts]) && (
          <Route
            path={routeTo.admin.discounts.home()}
            component={Discounts}
            exact
          />
        )}
        {P([PermissionCode.ReadDiscount]) && (
          <Route
            path={routeTo.admin.discounts.detail(
              toPlaceholderParam(DP.DISCOUNT_ID)
            )}
            component={Discount}
          />
        )}
        {P([PermissionCode.ManageVenues]) && (
          <Route
            path={[routeTo.admin.venues.home()]}
            component={VenueList}
            exact
          />
        )}
        {P([PermissionCode.CreateVenue]) && (
          <Route
            path={[routeTo.admin.venues.add()]}
            component={VenueList}
            exact
          />
        )}
        <Route path={routeTo.admin.dashboard()} component={Dashboard} exact />
        {P([PermissionCode.ManageClientShows]) && (
          <Route
            path={routeTo.admin.shows.home()}
            component={ClientShowList}
            exact
          />
        )}
        {P([PermissionCode.ReadLibraryShows]) && (
          <Route
            path={routeTo.admin.library.home()}
            component={LibraryShowList}
            exact
          />
        )}
        {P([PermissionCode.ChangeDeviceSettings]) && (
          <Route
            path={routeTo.admin.cashDesk.settings()}
            component={DeviceSettingsPage}
          />
        )}
        {P([PermissionCode.CreateClientShow]) && (
          <Route path={routeTo.admin.shows.add()} exact component={AddShow} />
        )}
        <Route path={routeTo.admin.cashDesk.index()} component={CashDesk} />
        {P([PermissionCode.ReadCarts]) && (
          <Route path={routeTo.admin.carts.index()} component={Carts} />
        )}
        <Route path={routeTo.admin.cashCounter()}>
          <CashCounter effectiveClientCurrency={effectiveClient?.currency} />
        </Route>
        <Route path={routeTo.admin.claims.index()} component={Claims} />
        <Route
          path={routeTo.admin.costCenters.index()}
          component={CostCenters}
        />
        {P([
          PermissionCode.ManageWarehouses,
          PermissionCode.ReadWarehouses
        ]) && (
          <Route
            path={routeTo.admin.warehouses.index()}
            component={Warehouses}
          />
        )}
        {P([
          PermissionCode.ManageProductGroups,
          PermissionCode.ReadProductGroups
        ]) && (
          <Route
            path={routeTo.admin.productGroups.index()}
            component={ProductGroups}
          />
        )}
        {P([
          PermissionCode.ReadProductTypes,
          PermissionCode.ManageProductTypes
        ]) && (
          <Route
            path={routeTo.admin.productTypes.index()}
            component={ProductTypes}
          />
        )}
        {P([PermissionCode.ReadVoucherTransactionIntents]) && (
          <Route
            path={routeTo.admin.voucherTransactions.index()}
            component={VoucherTransactions}
          />
        )}
        {P([PermissionCode.ManageProducts, PermissionCode.ReadProducts]) && (
          <Route path={routeTo.admin.products.index()} component={Products} />
        )}
        {P([
          PermissionCode.ManageWarehouseDocuments,
          PermissionCode.ReadWarehouseDocuments
        ]) && (
          <Route
            path={routeTo.admin.warehouseDocuments.index()}
            component={WarehouseDocuments}
          />
        )}
        {P([PermissionCode.ReadWarehouseDocumentItems]) && (
          <Route
            path={routeTo.admin.inventoryTransactions.index()}
            component={InventoryTransactions}
          />
        )}
        {P([
          PermissionCode.ReadInventoryChecks,
          PermissionCode.ManageInventoryChecks
        ]) && (
          <Route
            path={routeTo.admin.inventoryChecks.index()}
            component={InventoryChecks}
          />
        )}
        {P([PermissionCode.ReadWarehouseProducts]) && (
          <Route
            path={routeTo.admin.warehouseStocks.index()}
            component={WarehouseStocks}
          />
        )}
        {P([PermissionCode.ManageMessages, PermissionCode.ReadMessages]) && (
          <Route path={routeTo.admin.messages.index()} component={Messages} />
        )}
        {P([
          PermissionCode.ManageVoucherCampaigns,
          PermissionCode.ReadVoucherCampaigns
        ]) && (
          <Route
            path={routeTo.admin.voucherCampaigns.index()}
            component={VoucherCampaigns}
          />
        )}
        {P([PermissionCode.ManageVouchers, PermissionCode.ReadVouchers]) && (
          <Route path={routeTo.admin.vouchers.index()} component={Vouchers} />
        )}
        {P([PermissionCode.ManageCustomers, PermissionCode.ReadCustomers]) && (
          <Route path={routeTo.admin.customers.index()} component={Customers} />
        )}
        {P([
          PermissionCode.ManageCustomerGroups,
          PermissionCode.ReadCustomerGroups
        ]) && (
          <Route
            path={routeTo.admin.customerGroups.index()}
            component={CustomerGroups}
          />
        )}
        {P([
          PermissionCode.ManageAdmissionTypes,
          PermissionCode.ReadAdmissionTypes
        ]) && (
          <Route
            path={routeTo.admin.admissionTypes.index()}
            component={AdmissionTypes}
          />
        )}
        {P([
          PermissionCode.ManageAccessZones,
          PermissionCode.ReadAccessZones
        ]) && (
          <Route
            path={routeTo.admin.accessZones.index()}
            component={AccessZones}
          />
        )}
        {P([PermissionCode.ManageTours, PermissionCode.ReadTours]) && (
          <Route path={routeTo.admin.tours.index()} component={Tours} />
        )}
        {P([
          PermissionCode.ManageBusinessPartners,
          PermissionCode.ReadBusinessPartners
        ]) && (
          <Route
            path={routeTo.admin.businessPartners.index()}
            component={BusinessPartners}
          />
        )}
        {P([PermissionCode.ManageWebsites, PermissionCode.ReadWebsites]) && (
          <Route path={routeTo.admin.websites.index()} component={Websites} />
        )}
        {P([PermissionCode.ReadCartsSupportUser]) && (
          <Route
            path={routeTo.admin.cartsSupport.index()}
            component={CartsSupport}
          />
        )}
        {P([
          PermissionCode.ReadPayments,
          PermissionCode.ReadPaymentsForSupport
        ]) && (
          <Route
            path={routeTo.admin.paymentsSupport.index()}
            component={PaymentsSupport}
          />
        )}
        {P([
          PermissionCode.ManageEventCategories,
          PermissionCode.ReadEventCategories
        ]) && (
          <Route
            path={routeTo.admin.eventCategories.index()}
            component={EventCategories}
          />
        )}
        {P([
          PermissionCode.ManageMarketingLabels,
          PermissionCode.ReadMarketingLabels
        ]) && (
          <Route
            path={routeTo.admin.marketingLabels.index()}
            component={MarketingLabels}
          />
        )}
        {P([PermissionCode.ManageRoles]) && (
          <Route path={routeTo.admin.roles.index()} component={Roles} />
        )}
        {P([PermissionCode.ManageTemplates]) && (
          <Route path={routeTo.admin.templates.index()} component={Templates} />
        )}
        {P([PermissionCode.ReadApiGrants]) && (
          <Route path={routeTo.admin.apiGrants.index()} component={ApiGrants} />
        )}
        {(P([PermissionCode.CheckPassCodeIn]) ||
          P([PermissionCode.CheckPassCodeOut]) ||
          P([PermissionCode.ReadItemByPassCode])) && (
          <Route
            path={routeTo.admin.checkTickets.index()}
            component={CheckTickets}
          />
        )}
        {P([PermissionCode.ReadPassCodeChecks]) && (
          <Route
            path={routeTo.admin.passCodeCheckHistory.index()}
            component={PassCodeCheckHistory}
          />
        )}
        {P([PermissionCode.ReadPaymentReports]) && (
          <Route
            path={routeTo.admin.paymentReports.index()}
            component={PaymentReports}
          />
        )}
        {P([PermissionCode.ReadEventsStatistics]) && (
          <Route
            path={routeTo.admin.eventsStatistics.index()}
            component={EventsStatistics}
          />
        )}
        {P([PermissionCode.ReadTourTimeSlotsStatistics]) && (
          <Route
            path={routeTo.admin.tourTimeSlotsStatistics.index()}
            component={TourTimeSlotsStatistics}
          />
        )}

        {P([PermissionCode.ReadShowsStatistics]) && (
          <Route
            path={routeTo.admin.showsStatistics.index()}
            component={ShowsStatistics}
          />
        )}
        {P([PermissionCode.ReadAdmissionTypesStatistics]) && (
          <Route
            path={routeTo.admin.admissionTypesStatistics.index()}
            component={AdmissionTypesStatistics}
          />
        )}
        {P([PermissionCode.ReadDiscountsStatistics]) && (
          <Route
            path={routeTo.admin.discountsStatistics.index()}
            component={DiscountsStatistics}
          />
        )}
        {P([PermissionCode.ReadProductsStatistics]) && (
          <Route
            path={routeTo.admin.productsStatistics.index()}
            component={ProductsStatistics}
          />
        )}
        {P([PermissionCode.ReadPayments]) && (
          <Route
            path={routeTo.admin.paymentsOverview.index()}
            component={PaymentsOverview}
          />
        )}
        <Route path={routeTo.admin.profile()} component={Profile} />
        {effectiveClient && P([PermissionCode.UpdateCompany]) && (
          <Route path={routeTo.admin.company()} component={Company} />
        )}
        {P([PermissionCode.ManageUsers]) && (
          <Route path={routeTo.admin.users.home()} component={UserList} exact />
        )}
        {P([PermissionCode.CreateUser]) && (
          <Route path={routeTo.admin.users.add()} component={AddUser} exact />
        )}
        {P([PermissionCode.InviteUsers]) && (
          <Route
            path={routeTo.admin.users.invite()}
            component={InviteUsers}
            exact
          />
        )}
        {P([PermissionCode.ReadUser, PermissionCode.UpdateUser]) && (
          <Route
            path={routeTo.admin.users.edit(':id')}
            component={EditUser}
            exact
          />
        )}
        {P([PermissionCode.ReadClients]) && (
          <Route path={routeTo.admin.clients.home()} component={Clients} />
        )}
        {P([PermissionCode.ManageDivisions]) && (
          <Route
            path={routeTo.admin.divisions.home()}
            component={DivisionList}
            exact
          />
        )}
        {P([PermissionCode.CreateDivision]) && (
          <Route
            path={routeTo.admin.divisions.add()}
            component={AddDivision}
            exact
          />
        )}
        <Route
          path={routeTo.admin.divisions.edit(':id')}
          component={EditDivision}
          exact
        />
        {P([PermissionCode.DuplicateAuditoriumLayoutSupportUser]) && (
          <Route
            path={routeTo.admin.support.duplicateAuditoriumLayout()}
            component={DuplicateAuditoriumLayout}
            exact
          />
        )}
        {P([PermissionCode.ManagePaymentMethods]) && (
          <Route
            path={routeTo.admin.paymentMethods.home()}
            component={PaymentMethods}
          />
        )}
        {P([PermissionCode.UpdateAuditoriumLayoutPricing]) && (
          <Route
            path={[
              routeTo.admin.venues.editLayoutPricing(
                toPlaceholderParam(VP.VENUE_ID),
                toPlaceholderParam(VP.AUDITORIUM_ID),
                toPlaceholderParam(VP.AUDITORIUM_LAYOUT_ID),
                toPlaceholderParam(VP.LAYOUT_PRICING_ID)
              )
            ]}
            component={EditLayoutPricing}
            exact
          />
        )}
        {P([PermissionCode.UpdateAuditoriumLayout]) && (
          <Route
            path={routeTo.admin.venues.editAuditoriumLayout(
              toPlaceholderParam(VP.VENUE_ID),
              toPlaceholderParam(VP.AUDITORIUM_ID),
              toPlaceholderParam(VP.AUDITORIUM_LAYOUT_ID)
            )}
            component={EditAuditoriumLayout}
            exact
          />
        )}
        {P([PermissionCode.CreateLibraryShow]) && (
          <Route
            path={routeTo.admin.library.add()}
            component={AddLibraryShow}
          />
        )}
        {P([PermissionCode.UpdateLibraryShow]) && (
          <Route
            path={[
              routeTo.admin.library.edit(toPlaceholderParam(SP.SHOW_ID)),
              routeTo.admin.library.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                toPlaceholderParam(SP.SECTION)
              ),
              routeTo.admin.library.uploadMedia(
                toPlaceholderParam(SP.SHOW_ID),
                toPlaceholderParam(SP.SECTION),
                toPlaceholderParam(SP.ACTIVE_CONTENT_TYPE)
              )
            ]}
            component={EditLibraryShow}
            exact
          />
        )}
        {P([PermissionCode.UpdateClientShow]) && (
          <Route
            path={[
              routeTo.admin.shows.edit(toPlaceholderParam(SP.SHOW_ID)),
              routeTo.admin.shows.editWithEditSection(
                toPlaceholderParam(SP.SHOW_ID),
                toPlaceholderParam(SP.SECTION)
              ),
              routeTo.admin.shows.assignMediaFromLibrary(
                toPlaceholderParam(SP.SHOW_ID),
                toPlaceholderParam(SP.SECTION),
                toPlaceholderParam(SP.SHOW_IMAGE_TYPE)
              ),
              routeTo.admin.shows.uploadMedia(
                toPlaceholderParam(SP.SHOW_ID),
                toPlaceholderParam(SP.SECTION),
                toPlaceholderParam(SP.ACTIVE_CONTENT_TYPE)
              ),
              routeTo.admin.shows.libraryCrew(':showId')
            ]}
            component={EditClientShow}
            exact
          />
        )}
        {P([PermissionCode.ManageEvents]) && (
          <Route path={routeTo.admin.events.home()} component={Events} />
        )}
        {P([PermissionCode.ManageVenues]) && (
          <Route path={routeTo.admin.venues.home()} component={Venues} />
        )}
        {P([PermissionCode.CustomerDisplay]) && (
          <Route
            path={routeTo.admin.customerDisplay.home()}
            component={CustomerDisplay}
          />
        )}
        {P([
          PermissionCode.ChangeEventSeatsAvailability,
          PermissionCode.ChangeEventZoneSeatsAvailability
        ]) && (
          <Route
            path={routeTo.admin.seatOptions(
              toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
            )}
            component={SeatOptions}
          />
        )}
        <Route
          path={[routeTo.admin.notFound(), '*']}
          component={PageNotFound}
        />
      </Switch>
      <EnsurePermissions permissions={[PermissionCode.SwitchClient]}>
        <EffectiveClientIndicator />
      </EnsurePermissions>
    </>
  )
}
