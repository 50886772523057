import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  EnabledDiscountsForEventQuery,
  PermissionCode,
  SellingChannel,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {Blank} from '../../../../visual/Blank'

interface IChildrenOnValidEventDataProps {
  children: React.ReactElement
  ticketItems: TicketItemPropertiesFragment[]
  event: EnabledDiscountsForEventQuery['event']
}

export const ChildrenOnValidEventData: React.FC<IChildrenOnValidEventDataProps> =
  ({
    children,
    ticketItems,
    event: {
      allowedDiscountsSellingChannels,
      id: eventId,
      enabledDiscounts: discounts
    }
  }: IChildrenOnValidEventDataProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const history = useHistory()
    const handleEventSettingsButtonClick = useCallback(() => {
      history.replace(routeTo.admin.events.eventDiscountDetails(eventId))
    }, [eventId, history])
    const handleDiscountsButtonClick = useCallback(() => {
      history.replace(routeTo.admin.discounts.home())
    }, [history])
    if (ticketItems.length === 0) {
      return (
        <Blank
          title={t('No items to be discounted')}
          description={t('Add first ticket for event')}
        />
      )
    }
    if (!allowedDiscountsSellingChannels.includes(SellingChannel.Retail)) {
      return (
        <Blank
          title={t('Discounts are disabled')}
          description={t(
            'Discounts for this event are disabled. It can be changed in event settings.'
          )}
          actions={
            P([
              PermissionCode.UpdateAllowedDiscountsSellingChannelsForEvent
            ]) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleEventSettingsButtonClick}
              >
                {t('Settings')}
              </Button>
            )
          }
        />
      )
    }
    if (discounts.length === 0) {
      return (
        <Blank
          title={t('No discounts found')}
          description={t(
            'There are no discounts for this event at this moment. This can be changed in discounts settings.'
          )}
          actions={
            P([PermissionCode.ReadDiscounts]) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleDiscountsButtonClick}
              >
                {t('Discounts')}
              </Button>
            )
          }
        />
      )
    }
    return children
  }
