import {Box, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {ReactNode} from 'react'

const useReadOnlyRowStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '96px 1fr',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  typography: {
    lineHeight: '24px',
    whiteSpace: 'pre-wrap'
  }
}))

interface IReadOnlyRowProps {
  label: string
  children?: ReactNode
}

export const ReadOnlyRow: React.FC<IReadOnlyRowProps> = ({
  label,
  children
}: IReadOnlyRowProps) => {
  const classes = useReadOnlyRowStyles()
  return (
    <Box className={classes.root}>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.typography}
      >
        {label}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        className={classes.typography}
      >
        {children}
      </Typography>
    </Box>
  )
}
