import Button from '@mui/material/Button'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientShowQuery,
  DetailClientShowPropertiesFragment
} from '../../../../__generated__/schema'

import {Theme} from '../../../../theme'
import {RenderOnData} from '../../../common'
import {useGetClientShow} from './graphql'

const useStyles = makeStyles<Theme>((theme) => ({
  errorButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  }
}))
interface IClientShowDetailProviderProps {
  onClose(): void
  showId: number
  children(show: DetailClientShowPropertiesFragment): React.ReactElement
}

export const ClientShowDetailProvider: React.FC<IClientShowDetailProviderProps> =
  ({onClose, showId, children}: IClientShowDetailProviderProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const {loading, error, data} = useGetClientShow(showId)
    return (
      <>
        <RenderOnData
          data={data}
          loading={loading}
          error={error}
          dataCondition={(data?: ClientShowQuery) => Boolean(data?.clientShow)}
          errorMessage={t<string>('Could not load show detail.')}
        >
          {(data: ClientShowQuery) => children(data.clientShow)}
        </RenderOnData>
        {error && (
          <div className={classes.errorButtonBox}>
            <Button autoFocus onClick={onClose} color="primary">
              {t('Close')}
            </Button>
          </div>
        )}
      </>
    )
  }
