import AddIcon from '@mui/icons-material/Add'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import {
  Backdrop,
  Box,
  Button,
  CloseReason,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography
} from '@mui/material'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro'
import {isNumber} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ShowTypeCode,
  ShowVersionCode,
  TourTimeSlotsQuery,
  TourTimeSlotState
} from '../../../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../../../hooks/formatUserName'
import {useBooleanState} from '../../../../../../hooks/state'
import {useTranslateTourTimeSlotState} from '../../../../../../hooks/translateTourTimeSlotState'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {routeTo} from '../../../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../../../common'
import {ColorBox} from '../../../../../common/ColorBox'
import {CreateTourTimeSlotDrawer} from '../../../../../common/createTourTimeSlotDrawer'
import {
  BooleanStateRenderer,
  DataGridTable,
  useAgeClassificationFormatter,
  useDataGridPagination,
  useDateTimeFormatter,
  useUserNameFormatter,
  useVersionCodeFormatter
} from '../../../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../../../common/datePicker/types'
import {tourTimeSlotStateColors} from '../../../../../constants'
import {Blank} from '../../../../../visual/Blank'
import {WideCenteredLayout} from '../../../Layout'
import {useGetFilterDateRange} from '../../../utils'
import {useTourTimeSlots} from '../../graphql'
import {CopySlotsDrawer} from './CopySlotsDrawer'
import {Toolbar} from './Toolbar'
import {UpdateSlotsDrawer} from './UpdateSlotsDrawer'

const StateRenderer = ({state}: {state: TourTimeSlotState}) => {
  const translateTourTimeSlotState = useTranslateTourTimeSlotState()
  return (
    <EntityStateChip
      label={translateTourTimeSlotState(state)}
      colorConf={tourTimeSlotStateColors[state]}
    />
  )
}

const AdmissionRateRenderer = ({
  admissionRate
}: {
  admissionRate: TourTimeSlotsQuery['tourTimeSlots']['items'][number]['admissionRate']
}) => (
  <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
    <ColorBox hexColor={admissionRate.color} />
    <Typography variant="body2">{admissionRate.name}</Typography>
  </Box>
)

interface ITimeSlotsProps {
  tourId: number
  showTypeCode: ShowTypeCode
}

export const TimeSlots: React.FC<ITimeSlotsProps> = ({
  tourId,
  showTypeCode
}: ITimeSlotsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const formatShortGuideName = useFormatShortGuideName(true)
  const [tourTimeSlotState, setTourTimeSlotState] =
    useState<TourTimeSlotState | null>(null)
  const [admissionRateId, setAdmissionRateId] = useState<number | null>(null)
  const [versionCode, setVersionCode] = useState<ShowVersionCode | null>(null)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.FromToday
    }
  )
  const getFilterDateRange = useGetFilterDateRange()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const {data, loading, error, refetch} = useTourTimeSlots({
    filter: {
      tourId,
      state: tourTimeSlotState,
      admissionRateId,
      versionCode,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'startsAtFrom',
        filterNameTo: 'startsAtTo'
      })
    },
    paginationInput
  })
  const {
    state: isCreateDrawerOpen,
    setTrue: openCreateDrawer,
    setFalse: closeCreateDrawer
  } = useBooleanState(false)
  const {
    state: isCopySlotsDrawerOpen,
    setTrue: openCopySlotsDrawer,
    setFalse: closeCopySlotsDrawer
  } = useBooleanState(false)
  const {
    state: isSpeedDialOpen,
    setTrue: openSpeedDial,
    setFalse: closeSpeedDial
  } = useBooleanState(false)
  const dateTimeFormatter = useDateTimeFormatter()
  const versionCodeFormatter = useVersionCodeFormatter()
  const ageClassificationFormatter = useAgeClassificationFormatter()
  const userNameFormatter = useUserNameFormatter()
  const hasPermissionToCreateTimeSlot = P([PermissionCode.CreateTourTimeSlot])
  const handleAdmissionRateChange = useCallback(
    (admissionRateId: number | null) => {
      setAdmissionRateId(admissionRateId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleVersionChange = useCallback(
    (version: ShowVersionCode | null) => {
      setVersionCode(version)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleStateChange = useCallback(
    (state: TourTimeSlotState | null) => {
      setTourTimeSlotState(state)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF
      },
      {
        headerName: t('Date'),
        field: 'startsAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 250,
        sortable: false
      },
      {
        headerName: t('Duration'),
        field: 'duration',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        minWidth: 150,
        renderCell: function renderer(params: GridCellParams) {
          return <StateRenderer state={params.value as TourTimeSlotState} />
        },
        sortable: false
      },
      {
        headerName: t('Retail'),
        field: 'retailAttendeesLimit',
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Ecommerce'),
        field: 'eCommerceAttendeesLimit',
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Ecommerce order'),
        field: 'eCommerceOrderAttendeesLimit',
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        sortable: false
      },
      {
        headerName: t('Admission rate'),
        field: 'admissionRate',
        minWidth: 250,
        renderCell: function renderer(params: GridCellParams) {
          return (
            <AdmissionRateRenderer
              admissionRate={
                params.value as TourTimeSlotsQuery['tourTimeSlots']['items'][number]['admissionRate']
              }
            />
          )
        },
        sortable: false
      },
      {
        headerName: t('Version'),
        field: 'versionCode',
        minWidth: 200,
        valueFormatter: versionCodeFormatter,
        sortable: false
      },
      {
        headerName: t('Guide'),
        field: 'guide',
        minWidth: 200,
        valueGetter: (params) => params.row.guide,
        valueFormatter: (params) =>
          params.value && formatShortGuideName(params.value),
        sortable: false
      },
      {
        headerName: t('Retail sale'),
        field: 'isRetailSaleActive',
        minWidth: 150,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <BooleanStateRenderer value={params.value} />
        },
        sortable: false
      },
      {
        headerName: t('Retail sale starts at'),
        field: 'retailSaleStartsAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Retail sale ends at'),
        field: 'retailSaleEndsAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Retail reservation'),
        field: 'isRetailReservationActive',
        minWidth: 150,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <BooleanStateRenderer value={params.value} />
        },
        sortable: false
      },
      {
        headerName: t('Retail reservation starts at'),
        field: 'retailReservationStartsAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Retail reservation ends at'),
        field: 'retailReservationEndsAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Ecommerce sale'),
        field: 'isECommerceSaleActive',
        minWidth: 150,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <BooleanStateRenderer value={params.value} />
        }
      },
      {
        headerName: t('Ecommerce sale starts at'),
        field: 'eCommerceSaleStartsAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Ecommerce sale ends at'),
        field: 'eCommerceSaleEndsAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Ecommerce reservation'),
        field: 'isECommerceReservationActive',
        minWidth: 200,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <BooleanStateRenderer value={params.value} />
        }
      },
      {
        headerName: t('Ecommerce reservation starts at'),
        field: 'eCommerceReservationStartsAt',
        minWidth: 220,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Ecommerce reservation ends at'),
        field: 'eCommerceReservationEndsAt',
        minWidth: 220,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Show on website'),
        field: 'showOnWebsiteAndApi',
        minWidth: 200,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <BooleanStateRenderer value={params.value} />
        },
        sortable: false
      },
      {
        headerName: t('Venue'),
        field: 'venue',
        minWidth: 250,
        valueGetter: (params) => params.row.venue,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Age restriction'),
        field: 'ageClassificationCode',
        minWidth: 250,
        valueFormatter: ageClassificationFormatter,
        sortable: false
      },
      {
        headerName: t('Cost center'),
        field: 'costCenter',
        minWidth: 250,
        valueGetter: (params) => params.row.costCenter,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Marketing label'),
        field: 'marketingLabel',
        minWidth: 250,
        valueGetter: (params) => params.row.marketingLabel,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Event category'),
        field: 'eventCategory',
        minWidth: 250,
        valueGetter: (params) => params.row.eventCategory,
        valueFormatter: (params) => params.value?.name,
        sortable: false
      },
      {
        headerName: t('Ticket note'),
        field: 'ticketNote',
        minWidth: 250,
        sortable: false
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        minWidth: 250,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Updated by'),
        field: 'updatedBy',
        minWidth: 250,
        valueFormatter: userNameFormatter,
        sortable: false
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        minWidth: 250,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        minWidth: 250,
        valueFormatter: userNameFormatter,
        sortable: false
      }
    ],
    [
      ageClassificationFormatter,
      dateTimeFormatter,
      formatShortGuideName,
      t,
      userNameFormatter,
      versionCodeFormatter
    ]
  )
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const hasUpdateTourTimeSlotsPermission = P([
    PermissionCode.UpdateTourTimeSlots
  ])
  const hasDeleteTourTimeSlotsPermission = P([
    PermissionCode.DeleteTourTimeSlots
  ])
  const handleSpeedDialClose = useCallback(
    (e: React.SyntheticEvent<{}>, reason: CloseReason) => {
      if (reason !== 'mouseLeave') {
        closeSpeedDial()
      }
      e.stopPropagation()
    },
    [closeSpeedDial]
  )
  const handleCreateOneSlotClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      closeSpeedDial()
      openCreateDrawer()
    },
    [closeSpeedDial, openCreateDrawer]
  )
  const handleCopySlotsClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      closeSpeedDial()
      openCopySlotsDrawer()
    },
    [closeSpeedDial, openCopySlotsDrawer]
  )
  const handleExited = useCallback(
    () => history.push(routeTo.admin.tours.timeSlots(tourId)),
    [history, tourId]
  )
  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr'
        }}
      >
        <Toolbar
          tourId={tourId}
          onTimeSlotStateChange={handleStateChange}
          onAdmissionRateChange={handleAdmissionRateChange}
          onVersionCodeChange={handleVersionChange}
          selectedItemIds={rowSelectionModel.filter(isNumber)}
          selectedDate={selectedDate}
          onDateSelect={setSelectedDate}
          hasUpdateTourTimeSlotsPermission={hasUpdateTourTimeSlotsPermission}
          hasDeleteTourTimeSlotsPermission={hasDeleteTourTimeSlotsPermission}
          refetchTourTimeSlots={refetch}
        />
        <WideCenteredLayout
          sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}
        >
          <RenderOnData<TourTimeSlotsQuery>
            loading={loading}
            data={data}
            error={error}
            ignoreLoadingIfData
            errorMessage={t<string>('Error while loading tour time slots')}
          >
            {({tourTimeSlots}) =>
              tourTimeSlots.items.length > 0 ? (
                <>
                  <DataGridTable
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel)
                    }}
                    rowSelectionModel={rowSelectionModel}
                    columns={columns}
                    loading={loading}
                    rows={tourTimeSlots.items}
                    disableColumnMenu
                    initialState={{
                      pinnedColumns: {
                        left: ['__check__', 'startsAt']
                      },
                      columns: {
                        columnVisibilityModel: {
                          id: P([PermissionCode.CreateTourTimeSlot])
                        }
                      }
                    }}
                    checkboxSelection={
                      hasUpdateTourTimeSlotsPermission ||
                      hasDeleteTourTimeSlotsPermission
                    }
                    disableRowSelectionOnClick
                    {...getDataGridPaginationProps(
                      data?.tourTimeSlots.pagination
                    )}
                  />
                  {hasPermissionToCreateTimeSlot && (
                    <SpeedDial
                      open={isSpeedDialOpen}
                      onClick={openSpeedDial}
                      onClose={handleSpeedDialClose}
                      FabProps={{
                        size: 'medium'
                      }}
                      icon={<SpeedDialIcon />}
                      ariaLabel="createTourTimeSlotDial"
                      direction="up"
                      sx={(theme) => ({
                        position: 'fixed',
                        bottom: theme.spacing(3),
                        right: theme.spacing(3)
                      })}
                    >
                      <SpeedDialAction
                        icon={<AddOutlinedIcon />}
                        tooltipTitle={t('Create one slot')}
                        tooltipOpen
                        sx={{whiteSpace: 'nowrap'}}
                        onClick={handleCreateOneSlotClick}
                      />
                      <SpeedDialAction
                        icon={<ContentCopyOutlinedIcon />}
                        tooltipTitle={t('Copy multiple slots')}
                        tooltipOpen
                        sx={{whiteSpace: 'nowrap'}}
                        onClick={handleCopySlotsClick}
                      />
                    </SpeedDial>
                  )}
                </>
              ) : (
                <Blank
                  title={t('No time slots found')}
                  description={t(
                    'Create tour time slots that visitors can attend. You can set up slots in various languages, offer audio guides or unguided options, limit the number of attendees per slot, and much more.'
                  )}
                  actions={
                    hasPermissionToCreateTimeSlot && (
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={openCreateDrawer}
                      >
                        {t('Create')}
                      </Button>
                    )
                  }
                />
              )
            }
          </RenderOnData>
        </WideCenteredLayout>
      </Box>
      <CreateTourTimeSlotDrawer
        isOpen={isCreateDrawerOpen}
        onClose={closeCreateDrawer}
        tourId={tourId}
        showTypeCode={showTypeCode}
        refetch={refetch}
      />
      <CopySlotsDrawer
        isOpen={isCopySlotsDrawerOpen}
        onClose={closeCopySlotsDrawer}
        tourId={tourId}
        refetch={refetch}
      />
      {hasUpdateTourTimeSlotsPermission && (
        <Route path={routeTo.admin.tours.editTimeSlots(':tourId')} exact>
          <UpdateSlotsDrawer onExited={handleExited} refetch={refetch} />
        </Route>
      )}
      <Backdrop
        open={isSpeedDialOpen}
        transitionDuration={{enter: 0, exit: 1000}}
        sx={{zIndex: (theme) => theme.zIndex.speedDial - 1}}
      />
    </>
  )
}
