import dayjs from 'dayjs'
import {isEmpty, omitBy} from 'lodash'
import {
  CreateCustomerInput,
  NarrowCustomerQuery,
  UpdateCustomerInput
} from '../../../../../__generated__/schema'
import {CustomerFormField, ICustomerForm} from './types'

export const transformFormDataToCreateCustomerInput = (
  formData: ICustomerForm
): CreateCustomerInput => ({
  username: formData[CustomerFormField.Username],
  password: formData[CustomerFormField.Password],
  loyaltyId: formData[CustomerFormField.LoyaltyId] || undefined,
  isPrivacyPolicyConsentGranted:
    formData[CustomerFormField.IsPrivacyPolicyConsentGranted] || false,
  loyaltyLevelExpirationDate: formData[
    CustomerFormField.LoyaltyLevelExpirationDate
  ]
    ? dayjs(formData[CustomerFormField.LoyaltyLevelExpirationDate]).format(
        'YYYY-MM-DD'
      )
    : undefined,
  leadData: omitBy(formData[CustomerFormField.LeadData], isEmpty)
})

export const transformNarrowCustomerDataToFormData = (
  customer: NarrowCustomerQuery['customer']
): Partial<ICustomerForm> => ({
  [CustomerFormField.Username]: customer.username,
  [CustomerFormField.LoyaltyId]: customer.loyaltyId,
  [CustomerFormField.IsPrivacyPolicyConsentGranted]: Boolean(
    customer.privacyPolicyConsentGrantedAt
  ),
  [CustomerFormField.LeadData]: customer.lead.data,
  [CustomerFormField.LoyaltyLevelExpirationDate]:
    customer.loyaltyLevelExpirationDate
})

export const trasnformFormDataToUpdateCustomerInput = (
  formData: ICustomerForm,
  defaultLoyaltyLevelExpirationDate?: string
): UpdateCustomerInput => ({
  username: formData[CustomerFormField.Username],
  isPrivacyPolicyConsentGranted:
    formData[CustomerFormField.IsPrivacyPolicyConsentGranted],
  leadData: omitBy(formData[CustomerFormField.LeadData], isEmpty),
  loyaltyLevelExpirationDate:
    formData[CustomerFormField.LoyaltyLevelExpirationDate] ===
    defaultLoyaltyLevelExpirationDate
      ? undefined
      : formData[CustomerFormField.LoyaltyLevelExpirationDate]
      ? dayjs(formData[CustomerFormField.LoyaltyLevelExpirationDate]).format(
          'YYYY-MM-DD'
        )
      : null
})
