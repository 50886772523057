import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'
import React from 'react'

export const QrcodeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#qrcode-icon-path)">
      <path
        d="M4 4h6v6H4V4Zm16 0v6h-6V4h6Zm-6 11h2v-2h-2v-2h2v2h2v-2h2v2h-2v2h2v3h-2v2h-2v-2h-3v2h-2v-4h3v-1Zm2 0v3h2v-3h-2ZM4 20v-6h6v6H4ZM6 6v2h2V6H6Zm10 0v2h2V6h-2ZM6 16v2h2v-2H6Zm-2-5h2v2H4v-2Zm5 0h4v4h-2v-2H9v-2Zm2-5h2v4h-2V6ZM2 2v4H0V2a2 2 0 0 1 2-2h4v2H2Zm20-2a2 2 0 0 1 2 2v4h-2V2h-4V0h4ZM2 18v4h4v2H2a2 2 0 0 1-2-2v-4h2Zm20 4v-4h2v4a2 2 0 0 1-2 2h-4v-2h4Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="qrcode-icon-path">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)
