import {QueryHookOptions, useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  ActivateClientMutation,
  ActivateClientMutationVariables,
  Client,
  ClientDeactivateReason,
  ClientInput,
  ClientQuery,
  ClientQueryVariables,
  ClientsQuery,
  ClientsQueryVariables,
  CreateClientMutation,
  DeactivateClientMutation,
  DeactivateClientMutationVariables,
  GetClientTemplatesQuery,
  GetClientTemplatesQueryVariables,
  MutationCreateClientArgs,
  MutationSwitchClientArgs,
  MutationUpdateClientArgs,
  RemoveTemplateFromClientMutation,
  RemoveTemplateFromClientMutationVariables,
  SuperadminsQuery,
  SuperadminsQueryVariables,
  SwitchClientMutation,
  UpdateClientMutation
} from '../../../../__generated__/schema'
import {useBroadcastClientSwitched} from '../../../../hooks/broadcastChannel'

import {
  DETAIL_CLIENT_PROPERTIES_FRAGMENT,
  GET_CURRENT_USER
} from '../../../../utils/auth'

export const CLIENT_PROPERTIES_FRAGMENT = gql`
  fragment ClientProperties on Client {
    id
    name
    state
    legalAddress {
      town
    }
    createdAt
    updatedAt
  }
`

const CREATE_CLIENT = gql`
  mutation CreateClient($data: ClientInput!) {
    createClient(data: $data) {
      ...DetailClientProperties
    }
  }
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: Int!, $data: ClientInput!) {
    updateClient(id: $id, data: $data) {
      ...DetailClientProperties
    }
  }
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

const GET_CLIENTS = gql`
  query Clients {
    clients {
      ...ClientProperties
    }
  }
  ${CLIENT_PROPERTIES_FRAGMENT}
`

const GET_CLIENT = gql`
  query Client($id: Int!) {
    client(id: $id) {
      ...DetailClientProperties
    }
  }
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

const ACTIVATE_CLIENT = gql`
  mutation ActivateClient($id: Int!) {
    activateClient(id: $id) {
      ...DetailClientProperties
    }
  }
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

const DEACTIVATE_CLIENT = gql`
  mutation DeactivateClient($id: Int!, $reason: ClientDeactivateReason!) {
    deactivateClient(id: $id, reason: $reason) {
      ...DetailClientProperties
    }
  }
  ${DETAIL_CLIENT_PROPERTIES_FRAGMENT}
`

const SWITCH_CLIENT = gql`
  mutation SwitchClient($id: Int) {
    switchClient(id: $id) {
      id
    }
  }
`

const GET_SUPERADMINS = gql`
  query Superadmins {
    role(code: "SUPER_ADMIN") {
      users {
        id
        username
      }
    }
  }
`

export const TEMPLATE_PROPERTIES_FRAGMENT = gql`
  fragment TemplateProperties on Template {
    id
    name
    types
    fileType
    localeCode
    createdAt
    createdByName
    description
  }
`

export const TEMPLATE_ASSIGNMENT_PROPERTIES_FRAGMENT = gql`
  fragment TemplateAssignmentProperties on TemplateAssignment {
    id
    createdAt
    createdByName
    template {
      ...TemplateProperties
    }
  }
  ${TEMPLATE_PROPERTIES_FRAGMENT}
`

export const GET_CLIENT_TEMPLATES = gql`
  ${TEMPLATE_ASSIGNMENT_PROPERTIES_FRAGMENT}
  query getClientTemplates($id: Int!) {
    client(id: $id) {
      id
      templateAssignments {
        ...TemplateAssignmentProperties
      }
    }
  }
`

export const useGetClientTemplates = (
  options?: QueryHookOptions<
    GetClientTemplatesQuery,
    GetClientTemplatesQueryVariables
  >
) => {
  return useQuery<GetClientTemplatesQuery, GetClientTemplatesQueryVariables>(
    GET_CLIENT_TEMPLATES,
    options
  )
}

const REMOVE_TEMPLATE_FROM_CLIENT = gql`
  mutation removeTemplateFromClient(
    $templateId: PositiveInt!
    $clientId: PositiveInt!
  ) {
    removeTemplateFromClient(templateId: $templateId, clientId: $clientId) {
      id
      templateAssignments {
        id
        template {
          id
        }
      }
    }
  }
`

export const useRemoveTemplateFromClient = () => {
  const [removeRemoveTemplateFromClient] = useMutation<
    RemoveTemplateFromClientMutation,
    RemoveTemplateFromClientMutationVariables
  >(REMOVE_TEMPLATE_FROM_CLIENT)
  return useCallback(
    (variables: RemoveTemplateFromClientMutationVariables) =>
      removeRemoveTemplateFromClient({variables}),
    [removeRemoveTemplateFromClient]
  )
}

interface ClientsCacheResult {
  clients: Array<Client>
}

export const useCreateClient = () => {
  const [createClient] = useMutation<
    CreateClientMutation,
    MutationCreateClientArgs
  >(CREATE_CLIENT, {
    update(cache, {data}) {
      const cachedData = cache.readQuery<ClientsCacheResult>({
        query: GET_CLIENTS
      })
      const clients = cachedData ? cachedData.clients : []
      data &&
        cache.writeQuery({
          query: GET_CLIENTS,
          data: {
            clients: [...clients, data.createClient]
          }
        })
    }
  })
  return useCallback(
    (clientData: MutationCreateClientArgs) =>
      createClient({variables: clientData}),
    [createClient]
  )
}

export const useUpdateClient = () => {
  const [updateClient] =
    useMutation<UpdateClientMutation, MutationUpdateClientArgs>(UPDATE_CLIENT)
  return useCallback(
    (id: number, data: ClientInput) => updateClient({variables: {id, data}}),
    [updateClient]
  )
}

export const useGetClients = () => {
  return useQuery<ClientsQuery, ClientsQueryVariables>(GET_CLIENTS)
}

export const useGetClient = (id: number) => {
  return useQuery<ClientQuery, ClientQueryVariables>(GET_CLIENT, {
    variables: {id}
  })
}

export const useActivateClient = () => {
  const [activateClient] =
    useMutation<ActivateClientMutation, ActivateClientMutationVariables>(
      ACTIVATE_CLIENT
    )
  return useCallback(
    (id: number) => activateClient({variables: {id}}),
    [activateClient]
  )
}

export const useDeactivateClient = () => {
  const [deactivateClient] =
    useMutation<DeactivateClientMutation, DeactivateClientMutationVariables>(
      DEACTIVATE_CLIENT
    )
  return useCallback(
    (id: number, reason: ClientDeactivateReason) =>
      deactivateClient({variables: {id, reason}}),
    [deactivateClient]
  )
}

export const useSwitchClient = () => {
  const broadcastClientSwitched = useBroadcastClientSwitched()
  const [switchClient] = useMutation<
    SwitchClientMutation,
    MutationSwitchClientArgs
  >(SWITCH_CLIENT, {
    refetchQueries: () => {
      return [{query: GET_CURRENT_USER}]
    }
  })
  return useCallback(
    (id?: number) =>
      switchClient({variables: {id}}).then(() => {
        // Note: reload is simpler compared to make sure that the graphql cache
        // is updated correctly to reflect new effective client ID
        broadcastClientSwitched()
        localStorage.removeItem('currentCartId')
        window.location.reload()
      }),
    [broadcastClientSwitched, switchClient]
  )
}

export const useGetSuperadmins = () => {
  return useQuery<SuperadminsQuery, SuperadminsQueryVariables>(GET_SUPERADMINS)
}
