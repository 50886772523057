import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import StorefrontIcon from '@mui/icons-material/Storefront'
import TourOutlinedIcon from '@mui/icons-material/TourOutlined'
import {SvgIconProps} from '@mui/material'
import React from 'react'

import {routeTo} from '../../utils/routes'

interface IDeviceServiceIconProps extends SvgIconProps {
  link: string
}

export const DeviceServiceIcon: React.FC<IDeviceServiceIconProps> = ({
  link,
  ...otherProps
}: IDeviceServiceIconProps) => {
  switch (link) {
    case routeTo.admin.cashDesk.settings():
      return <SettingsIcon {...otherProps} />
    case routeTo.admin.cashDesk.reservations():
      return <BookmarkBorderIcon {...otherProps} />
    case routeTo.admin.cashDesk.shop():
      return <StorefrontIcon {...otherProps} />
    case routeTo.admin.cashDesk.deviceInformation():
      return <InfoIcon {...otherProps} />
    case routeTo.admin.cashDesk.tours():
      return <TourOutlinedIcon {...otherProps} />
    case routeTo.admin.cashDesk.events():
    default:
      return <CalendarTodayIcon {...otherProps} />
  }
}
