import React from 'react'
import {useTranslation} from 'react-i18next'
import {TourTimeSlotState} from '../../../../__generated__/schema'
import {ChipWithOptions} from '../components/ChipWithOptions'

const useTranslateTourTimeSlotState = () => {
  const {t} = useTranslation()
  const translated: Record<TourTimeSlotState, string> = {
    [TourTimeSlotState.Draft]: t<string>('Draft time slots'),
    [TourTimeSlotState.Published]: t<string>('Published time slots'),
    [TourTimeSlotState.Cancelled]: t<string>('Cancelled time slots')
  }
  return (state: TourTimeSlotState) => translated[state]
}

interface IStateChipProps {
  state: TourTimeSlotState | undefined
  onChange: (state: TourTimeSlotState | undefined) => void
}

export const StateChip: React.FC<IStateChipProps> = ({
  state,
  onChange
}: IStateChipProps) => {
  const {t} = useTranslation()
  const translateTourTimeSlotState = useTranslateTourTimeSlotState()
  return (
    <ChipWithOptions<TourTimeSlotState>
      selectedItem={state}
      setSelectedItem={onChange}
      size="small"
      options={[TourTimeSlotState.Published, TourTimeSlotState.Cancelled].map(
        (state) => ({
          label: translateTourTimeSlotState(state),
          option: state
        })
      )}
      allText={t('All time slots')}
    />
  )
}
