import {omit} from 'lodash'
import {
  Currency,
  EcommercePaymentMethodInput,
  EcommercePaymentMethodType,
  GoPayPaymentInstrument,
  PaymentGatewayEnvironment,
  PaymentServiceProvider,
  RetailPaymentMethodInput,
  RetailPaymentMethodType,
  TranslatedInput
} from '../../../../__generated__/schema'

export enum PaymentMethodGroupField {
  NAME = 'name'
}

export interface IPaymentMethodGroupForm {
  [PaymentMethodGroupField.NAME]: string
}

export enum PaymentMethodField {
  NAME = 'name',
  VALUE = 'value',
  CURRENCY = 'currency',
  TYPE = 'type',
  CHANNEL = 'channel',
  GROUP_ID = 'groupId',
  REDUCES_BILL_TOTAL = 'reducesBillTotal',
  HAS_DENOMINATION = 'hasDenomination',
  POSSIBLE_CASHDESK_DISBURSEMENT = 'possibleCashdeskDisbursement',
  IS_AVAILABLE_FOR_REFUNDS = 'isAvailableForRefunds',
  DESCRIPTION = 'description',
  IS_IN_HOUSE_VOUCHER = 'isInHouseVoucher'
}

export interface IPaymentMethodForm {
  [PaymentMethodField.NAME]: string
  [PaymentMethodField.VALUE]: string
  [PaymentMethodField.CURRENCY]: Currency
  [PaymentMethodField.TYPE]:
    | RetailPaymentMethodType
    | EcommercePaymentMethodType
  [PaymentMethodField.GROUP_ID]: string
  [PaymentMethodField.REDUCES_BILL_TOTAL]: boolean
  [PaymentMethodField.HAS_DENOMINATION]: boolean
  [PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT]: boolean
  [PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]: boolean
  [PaymentMethodField.DESCRIPTION]: TranslatedInput
  [PaymentMethodField.IS_IN_HOUSE_VOUCHER]: boolean
}

export enum GoPayField {
  GoId = 'goId',
  ClientId = 'clientId',
  ClientSecret = 'clientSecret',
  DefaultPaymentInstrument = 'defaultPaymentInstrument',
  Environment = 'environment'
}

export enum StatnaPokladnicaField {
  MerchantId = 'merchantId',
  PrivateKey = 'privateKey',
  Environment = 'environment'
}

export enum BesteronField {
  BesteronId = 'besteronId',
  PaymentKey = 'paymentKey',
  ApiKey = 'apiKey',
  OnBehalfOf = 'onBehalfOf',
  Enviroment = 'enviroment',
  PaymentInstruments = 'paymentInstruments'
}

export enum BesteronPaymentMethod {
  AggregatedView = 'aggregatedView',
  PaymentCard = 'paymentCard',
  QuickBankTransfer = 'quickBankTransfer'
}

export type GoPayPaymentInstrumentOrNone = GoPayPaymentInstrument | 'none'

export const isGoPayInstrument = (
  instrument: GoPayPaymentInstrumentOrNone
): instrument is GoPayPaymentInstrument =>
  Boolean(instrument) && instrument !== 'none'

export interface IGoPayForm extends IPaymentMethodForm {
  [GoPayField.GoId]: string
  [GoPayField.ClientId]: string
  [GoPayField.ClientSecret]: string
  [GoPayField.DefaultPaymentInstrument]: GoPayPaymentInstrumentOrNone
  [GoPayField.Environment]: PaymentGatewayEnvironment
}

export interface IStatnaPokladnicaForm extends IPaymentMethodForm {
  [StatnaPokladnicaField.MerchantId]: string
  [StatnaPokladnicaField.PrivateKey]: string
  [StatnaPokladnicaField.Environment]: PaymentGatewayEnvironment
}

export interface IBesteronForm extends IPaymentMethodForm {
  [BesteronField.BesteronId]: string
  [BesteronField.PaymentKey]: string
  [BesteronField.ApiKey]: string
  [BesteronField.OnBehalfOf]?: number
  [BesteronField.Enviroment]: PaymentGatewayEnvironment
  [BesteronField.PaymentInstruments]: BesteronPaymentMethod
}

export const transformPaymentMethodFormIntoInput = ({
  ...form
}: IPaymentMethodForm):
  | RetailPaymentMethodInput
  | EcommercePaymentMethodInput => {
  return {
    ...omit(form, 'isInHouseVoucher'),
    value: form[PaymentMethodField.VALUE]
      ? parseFloat(form[PaymentMethodField.VALUE])
      : 1,
    groupId: parseInt(form[PaymentMethodField.GROUP_ID], 10),
    hasDenomination: form[PaymentMethodField.HAS_DENOMINATION] || false
  }
}

export const transformEcommercePaymentMethodFormIntoInput = (
  form: IPaymentMethodForm,
  paymentServiceProvider: PaymentServiceProvider
): EcommercePaymentMethodInput => {
  return {
    currency: form[PaymentMethodField.CURRENCY],
    isAvailableForRefunds: form[PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS],
    name: form[PaymentMethodField.NAME],
    type:
      paymentServiceProvider === PaymentServiceProvider.InHouseVoucher
        ? EcommercePaymentMethodType.Voucher
        : EcommercePaymentMethodType.PaymentGateway,
    value: 1,
    hasDenomination: false,
    possibleCashdeskDisbursement: false,
    reducesBillTotal: false,
    description: form[PaymentMethodField.DESCRIPTION]
  }
}
