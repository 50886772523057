import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  Search,
  useCombineStringifySearchObjectFunctions
} from '../../../../common'

export interface IPagesSearchFilter {
  hasText?: string
}

const mapHasTextToFiler = (
  filter: IPagesSearchFilter,
  hasText?: string
): IPagesSearchFilter => ({...filter, hasText: hasText || undefined})

const getHasTextFormSearchObject = (filter: IPagesSearchFilter) =>
  filter.hasText || undefined

interface IPagesSearchProps {
  onFilterChange: (filter: IPagesSearchFilter) => void
}

export const PagesSearch: React.FC<IPagesSearchProps> = ({
  onFilterChange
}: IPagesSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFormSearchObject
  )
  return (
    <Search<IPagesSearchFilter>
      storageKey="WEBSITE_PAGES"
      placeholder={t('Search for website page')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFiler}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
    />
  )
}
