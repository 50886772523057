import {ICoords, IDimensions} from '@attendio/shared-components'
import _ from 'lodash'
import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {useSelector} from '..'
import {
  canvasObjectBoundingRect,
  mergeBoundingRects
} from '../../utils/boundingBox'
import {presentObjectsSelector} from '../objects/selectors'
import {CanvasActionType} from './reducer'

export const useCanvasActions = () => {
  const dispatch = useDispatch()
  const presentObjects = useSelector(presentObjectsSelector)

  const changeDimensions = useCallback(
    (dimensions: IDimensions) => {
      dispatch({type: CanvasActionType.SET_DIMENSIONS, payload: dimensions})
    },
    [dispatch]
  )

  const changeOrigin = useCallback(
    (origin: ICoords) => {
      dispatch({type: CanvasActionType.SET_ORIGIN, payload: origin})
    },
    [dispatch]
  )

  const fitToScreen = useCallback(() => {
    const canvasObjects = Object.values(presentObjects)
    if (!canvasObjects.length) return

    const boundingRects = canvasObjects.map((canvasObject) =>
      canvasObjectBoundingRect(canvasObject)
    )
    const commonBoundingRect = mergeBoundingRects(_.compact(boundingRects))

    dispatch({
      type: CanvasActionType.FIT_TO_SCREEN,
      payload: commonBoundingRect
    })
  }, [dispatch, presentObjects])

  const zoomIn = useCallback(
    (anchor?: ICoords) => {
      dispatch({type: CanvasActionType.ZOOM_IN, payload: anchor})
    },
    [dispatch]
  )

  const zoomOut = useCallback(
    (anchor?: ICoords) => {
      dispatch({type: CanvasActionType.ZOOM_OUT, payload: anchor})
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      changeDimensions,
      changeOrigin,
      fitToScreen,
      zoomIn,
      zoomOut
    }),
    [changeDimensions, changeOrigin, fitToScreen, zoomIn, zoomOut]
  )

  return actions
}
