import {chain, sortBy} from 'lodash'
import {TemplatesQuery, TemplateType} from '../../../../__generated__/schema'
import {useRequireEffectiveClient} from '../../../../hooks/requireEffectiveClient'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {useGetTemplates} from '../templates/graphql'

export const useGetTicketPrintingTemplates =
  (): TemplatesQuery['templates'] => {
    const effectiveClient = useRequireEffectiveClient()
    const [deviceTemplateIds] = useLocalStorageState<{
      [templateType: string]: number[]
    }>(LocalStorageKey.TemplateIdsAssignedToDeviceByType, {})
    const {data, loading} = useGetTemplates(
      {
        clientId: effectiveClient.id,
        type: TemplateType.TicketPrinting
      },
      !effectiveClient.id
    )
    if (!data || loading) {
      return []
    }
    const deviceTemplates = chain(data.templates)
      .keyBy('id')
      .at(deviceTemplateIds.ticketPrinting)
      .compact()
      .value()
    return deviceTemplates.length
      ? deviceTemplates
      : sortBy(data?.templates, 'id')
  }
