import {useTranslation} from 'react-i18next'

import {CountryCode, ExistingCountryCode} from '../__generated__/schema'

export const useTranslateCountry = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<CountryCode, string> = {
    [CountryCode.Aw]: t<string>('CountryCode->Aw'),
    [CountryCode.Af]: t<string>('CountryCode->Af'),
    [CountryCode.Ao]: t<string>('CountryCode->Ao'),
    [CountryCode.Ai]: t<string>('CountryCode->Ai'),
    [CountryCode.Al]: t<string>('CountryCode->Al'),
    [CountryCode.Ad]: t<string>('CountryCode->Ad'),
    [CountryCode.An]: t<string>('CountryCode->An'),
    [CountryCode.Ae]: t<string>('CountryCode->Ae'),
    [CountryCode.Ar]: t<string>('CountryCode->Ar'),
    [CountryCode.Am]: t<string>('CountryCode->Am'),
    [CountryCode.As]: t<string>('CountryCode->As'),
    [CountryCode.Ag]: t<string>('CountryCode->Ag'),
    [CountryCode.Au]: t<string>('CountryCode->Au'),
    [CountryCode.At]: t<string>('CountryCode->At'),
    [CountryCode.Az]: t<string>('CountryCode->Az'),
    [CountryCode.Bi]: t<string>('CountryCode->Bi'),
    [CountryCode.Be]: t<string>('CountryCode->Be'),
    [CountryCode.Bj]: t<string>('CountryCode->Bj'),
    [CountryCode.Bf]: t<string>('CountryCode->Bf'),
    [CountryCode.Bd]: t<string>('CountryCode->Bd'),
    [CountryCode.Bg]: t<string>('CountryCode->Bg'),
    [CountryCode.Bh]: t<string>('CountryCode->Bh'),
    [CountryCode.Bs]: t<string>('CountryCode->Bs'),
    [CountryCode.Ba]: t<string>('CountryCode->Ba'),
    [CountryCode.By]: t<string>('CountryCode->By'),
    [CountryCode.Bz]: t<string>('CountryCode->Bz'),
    [CountryCode.Bm]: t<string>('CountryCode->Bm'),
    [CountryCode.Bo]: t<string>('CountryCode->Bo'),
    [CountryCode.Br]: t<string>('CountryCode->Br'),
    [CountryCode.Bb]: t<string>('CountryCode->Bb'),
    [CountryCode.Bn]: t<string>('CountryCode->Bn'),
    [CountryCode.Bt]: t<string>('CountryCode->Bt'),
    [CountryCode.Bw]: t<string>('CountryCode->Bw'),
    [CountryCode.Cf]: t<string>('CountryCode->Cf'),
    [CountryCode.Ca]: t<string>('CountryCode->Ca'),
    [CountryCode.Ci]: t<string>('CountryCode->Ci'),
    [CountryCode.Cm]: t<string>('CountryCode->Cm'),
    [CountryCode.Cd]: t<string>('CountryCode->Cd'),
    [CountryCode.Cg]: t<string>('CountryCode->Cg'),
    [CountryCode.Ck]: t<string>('CountryCode->Ck'),
    [CountryCode.Co]: t<string>('CountryCode->Co'),
    [CountryCode.Km]: t<string>('CountryCode->Km'),
    [CountryCode.Cv]: t<string>('CountryCode->Cv'),
    [CountryCode.Cr]: t<string>('CountryCode->Cr'),
    [CountryCode.Cs]: t<string>('CountryCode->Cs'),
    [CountryCode.Cu]: t<string>('CountryCode->Cu'),
    [CountryCode.Ky]: t<string>('CountryCode->Ky'),
    [CountryCode.Cy]: t<string>('CountryCode->Cy'),
    [CountryCode.Cz]: t<string>('CountryCode->Cz'),
    [CountryCode.Dd]: t<string>('CountryCode->Dd'),
    [CountryCode.De]: t<string>('CountryCode->De'),
    [CountryCode.Dj]: t<string>('CountryCode->Dj'),
    [CountryCode.Dm]: t<string>('CountryCode->Dm'),
    [CountryCode.Dk]: t<string>('CountryCode->Dk'),
    [CountryCode.Do]: t<string>('CountryCode->Do'),
    [CountryCode.Dz]: t<string>('CountryCode->Dz'),
    [CountryCode.Ec]: t<string>('CountryCode->Ec'),
    [CountryCode.Eg]: t<string>('CountryCode->Eg'),
    [CountryCode.Er]: t<string>('CountryCode->Er'),
    [CountryCode.Eh]: t<string>('CountryCode->Eh'),
    [CountryCode.Es]: t<string>('CountryCode->Es'),
    [CountryCode.Ee]: t<string>('CountryCode->Ee'),
    [CountryCode.Et]: t<string>('CountryCode->Et'),
    [CountryCode.Fi]: t<string>('CountryCode->Fi'),
    [CountryCode.Fj]: t<string>('CountryCode->Fj'),
    [CountryCode.Fk]: t<string>('CountryCode->Fk'),
    [CountryCode.Fr]: t<string>('CountryCode->Fr'),
    [CountryCode.Fo]: t<string>('CountryCode->Fo'),
    [CountryCode.Fm]: t<string>('CountryCode->Fm'),
    [CountryCode.Ga]: t<string>('CountryCode->Ga'),
    [CountryCode.Gb]: t<string>('CountryCode->Gb'),
    [CountryCode.Ge]: t<string>('CountryCode->Ge'),
    [CountryCode.Gh]: t<string>('CountryCode->Gh'),
    [CountryCode.Gi]: t<string>('CountryCode->Gi'),
    [CountryCode.Gn]: t<string>('CountryCode->Gn'),
    [CountryCode.Gp]: t<string>('CountryCode->Gp'),
    [CountryCode.Gm]: t<string>('CountryCode->Gm'),
    [CountryCode.Gw]: t<string>('CountryCode->Gw'),
    [CountryCode.Gq]: t<string>('CountryCode->Gq'),
    [CountryCode.Gr]: t<string>('CountryCode->Gr'),
    [CountryCode.Gd]: t<string>('CountryCode->Gd'),
    [CountryCode.Gl]: t<string>('CountryCode->Gl'),
    [CountryCode.Gt]: t<string>('CountryCode->Gt'),
    [CountryCode.Gf]: t<string>('CountryCode->Gf'),
    [CountryCode.Gu]: t<string>('CountryCode->Gu'),
    [CountryCode.Gy]: t<string>('CountryCode->Gy'),
    [CountryCode.Hk]: t<string>('CountryCode->Hk'),
    [CountryCode.Hn]: t<string>('CountryCode->Hn'),
    [CountryCode.Hr]: t<string>('CountryCode->Hr'),
    [CountryCode.Ht]: t<string>('CountryCode->Ht'),
    [CountryCode.Hu]: t<string>('CountryCode->Hu'),
    [CountryCode.Ch]: t<string>('CountryCode->Ch'),
    [CountryCode.Cl]: t<string>('CountryCode->Cl'),
    [CountryCode.Cn]: t<string>('CountryCode->Cn'),
    [CountryCode.Id]: t<string>('CountryCode->Id'),
    [CountryCode.In]: t<string>('CountryCode->In'),
    [CountryCode.Ie]: t<string>('CountryCode->Ie'),
    [CountryCode.Ir]: t<string>('CountryCode->Ir'),
    [CountryCode.Iq]: t<string>('CountryCode->Iq'),
    [CountryCode.Is]: t<string>('CountryCode->Is'),
    [CountryCode.Il]: t<string>('CountryCode->Il'),
    [CountryCode.It]: t<string>('CountryCode->It'),
    [CountryCode.Jm]: t<string>('CountryCode->Jm'),
    [CountryCode.Jo]: t<string>('CountryCode->Jo'),
    [CountryCode.Jp]: t<string>('CountryCode->Jp'),
    [CountryCode.Kz]: t<string>('CountryCode->Kz'),
    [CountryCode.Ke]: t<string>('CountryCode->Ke'),
    [CountryCode.Kg]: t<string>('CountryCode->Kg'),
    [CountryCode.Kh]: t<string>('CountryCode->Kh'),
    [CountryCode.Ki]: t<string>('CountryCode->Ki'),
    [CountryCode.Kn]: t<string>('CountryCode->Kn'),
    [CountryCode.Kr]: t<string>('CountryCode->Kr'),
    [CountryCode.Kw]: t<string>('CountryCode->Kw'),
    [CountryCode.La]: t<string>('CountryCode->La'),
    [CountryCode.Lb]: t<string>('CountryCode->Lb'),
    [CountryCode.Lr]: t<string>('CountryCode->Lr'),
    [CountryCode.Ly]: t<string>('CountryCode->Ly'),
    [CountryCode.Lc]: t<string>('CountryCode->Lc'),
    [CountryCode.Li]: t<string>('CountryCode->Li'),
    [CountryCode.Lk]: t<string>('CountryCode->Lk'),
    [CountryCode.Ls]: t<string>('CountryCode->Ls'),
    [CountryCode.Lt]: t<string>('CountryCode->Lt'),
    [CountryCode.Lu]: t<string>('CountryCode->Lu'),
    [CountryCode.Lv]: t<string>('CountryCode->Lv'),
    [CountryCode.Mo]: t<string>('CountryCode->Mo'),
    [CountryCode.Ma]: t<string>('CountryCode->Ma'),
    [CountryCode.Mc]: t<string>('CountryCode->Mc'),
    [CountryCode.Md]: t<string>('CountryCode->Md'),
    [CountryCode.Mg]: t<string>('CountryCode->Mg'),
    [CountryCode.Mv]: t<string>('CountryCode->Mv'),
    [CountryCode.Mx]: t<string>('CountryCode->Mx'),
    [CountryCode.Mh]: t<string>('CountryCode->Mh'),
    [CountryCode.Mk]: t<string>('CountryCode->Mk'),
    [CountryCode.Ml]: t<string>('CountryCode->Ml'),
    [CountryCode.Mt]: t<string>('CountryCode->Mt'),
    [CountryCode.Mm]: t<string>('CountryCode->Mm'),
    [CountryCode.Me]: t<string>('CountryCode->Me'),
    [CountryCode.Mn]: t<string>('CountryCode->Mn'),
    [CountryCode.Mp]: t<string>('CountryCode->Mp'),
    [CountryCode.Mz]: t<string>('CountryCode->Mz'),
    [CountryCode.Mr]: t<string>('CountryCode->Mr'),
    [CountryCode.Ms]: t<string>('CountryCode->Ms'),
    [CountryCode.Mq]: t<string>('CountryCode->Mq'),
    [CountryCode.Mu]: t<string>('CountryCode->Mu'),
    [CountryCode.Mw]: t<string>('CountryCode->Mw'),
    [CountryCode.My]: t<string>('CountryCode->My'),
    [CountryCode.Na]: t<string>('CountryCode->Na'),
    [CountryCode.Nc]: t<string>('CountryCode->Nc'),
    [CountryCode.Ne]: t<string>('CountryCode->Ne'),
    [CountryCode.Nf]: t<string>('CountryCode->Nf'),
    [CountryCode.Ng]: t<string>('CountryCode->Ng'),
    [CountryCode.Ni]: t<string>('CountryCode->Ni'),
    [CountryCode.Nu]: t<string>('CountryCode->Nu'),
    [CountryCode.Nl]: t<string>('CountryCode->Nl'),
    [CountryCode.No]: t<string>('CountryCode->No'),
    [CountryCode.Np]: t<string>('CountryCode->Np'),
    [CountryCode.Nr]: t<string>('CountryCode->Nr'),
    [CountryCode.Nz]: t<string>('CountryCode->Nz'),
    [CountryCode.Om]: t<string>('CountryCode->Om'),
    [CountryCode.Pk]: t<string>('CountryCode->Pk'),
    [CountryCode.Pa]: t<string>('CountryCode->Pa'),
    [CountryCode.Pn]: t<string>('CountryCode->Pn'),
    [CountryCode.Pe]: t<string>('CountryCode->Pe'),
    [CountryCode.Ph]: t<string>('CountryCode->Ph'),
    [CountryCode.Pw]: t<string>('CountryCode->Pw'),
    [CountryCode.Ps]: t<string>('CountryCode->Ps'),
    [CountryCode.Pg]: t<string>('CountryCode->Pg'),
    [CountryCode.Pl]: t<string>('CountryCode->Pl'),
    [CountryCode.Pr]: t<string>('CountryCode->Pr'),
    [CountryCode.Kp]: t<string>('CountryCode->Kp'),
    [CountryCode.Pt]: t<string>('CountryCode->Pt'),
    [CountryCode.Py]: t<string>('CountryCode->Py'),
    [CountryCode.Pf]: t<string>('CountryCode->Pf'),
    [CountryCode.Qa]: t<string>('CountryCode->Qa'),
    [CountryCode.Re]: t<string>('CountryCode->Re'),
    [CountryCode.Ro]: t<string>('CountryCode->Ro'),
    [CountryCode.Ru]: t<string>('CountryCode->Ru'),
    [CountryCode.Rw]: t<string>('CountryCode->Rw'),
    [CountryCode.Sa]: t<string>('CountryCode->Sa'),
    [CountryCode.Sd]: t<string>('CountryCode->Sd'),
    [CountryCode.Sn]: t<string>('CountryCode->Sn'),
    [CountryCode.Sg]: t<string>('CountryCode->Sg'),
    [CountryCode.Sh]: t<string>('CountryCode->Sh'),
    [CountryCode.Sj]: t<string>('CountryCode->Sj'),
    [CountryCode.Sb]: t<string>('CountryCode->Sb'),
    [CountryCode.Sl]: t<string>('CountryCode->Sl'),
    [CountryCode.Sv]: t<string>('CountryCode->Sv'),
    [CountryCode.Sm]: t<string>('CountryCode->Sm'),
    [CountryCode.So]: t<string>('CountryCode->So'),
    [CountryCode.Pm]: t<string>('CountryCode->Pm'),
    [CountryCode.Rs]: t<string>('CountryCode->Rs'),
    [CountryCode.Ss]: t<string>('CountryCode->Ss'),
    [CountryCode.St]: t<string>('CountryCode->St'),
    [CountryCode.Su]: t<string>('CountryCode->Su'),
    [CountryCode.Sr]: t<string>('CountryCode->Sr'),
    [CountryCode.Sk]: t<string>('CountryCode->Sk'),
    [CountryCode.Si]: t<string>('CountryCode->Si'),
    [CountryCode.Se]: t<string>('CountryCode->Se'),
    [CountryCode.Sz]: t<string>('CountryCode->Sz'),
    [CountryCode.Sc]: t<string>('CountryCode->Sc'),
    [CountryCode.Sy]: t<string>('CountryCode->Sy'),
    [CountryCode.Tc]: t<string>('CountryCode->Tc'),
    [CountryCode.Td]: t<string>('CountryCode->Td'),
    [CountryCode.Tg]: t<string>('CountryCode->Tg'),
    [CountryCode.Th]: t<string>('CountryCode->Th'),
    [CountryCode.Tj]: t<string>('CountryCode->Tj'),
    [CountryCode.Tk]: t<string>('CountryCode->Tk'),
    [CountryCode.Tm]: t<string>('CountryCode->Tm'),
    [CountryCode.To]: t<string>('CountryCode->To'),
    [CountryCode.Tt]: t<string>('CountryCode->Tt'),
    [CountryCode.Tn]: t<string>('CountryCode->Tn'),
    [CountryCode.Tr]: t<string>('CountryCode->Tr'),
    [CountryCode.Tv]: t<string>('CountryCode->Tv'),
    [CountryCode.Tw]: t<string>('CountryCode->Tw'),
    [CountryCode.Tz]: t<string>('CountryCode->Tz'),
    [CountryCode.Ug]: t<string>('CountryCode->Ug'),
    [CountryCode.Ua]: t<string>('CountryCode->Ua'),
    [CountryCode.Uy]: t<string>('CountryCode->Uy'),
    [CountryCode.Us]: t<string>('CountryCode->Us'),
    [CountryCode.Uz]: t<string>('CountryCode->Uz'),
    [CountryCode.Va]: t<string>('CountryCode->Va'),
    [CountryCode.Vc]: t<string>('CountryCode->Vc'),
    [CountryCode.Ve]: t<string>('CountryCode->Ve'),
    [CountryCode.Vg]: t<string>('CountryCode->Vg'),
    [CountryCode.Vi]: t<string>('CountryCode->Vi'),
    [CountryCode.Vn]: t<string>('CountryCode->Vn'),
    [CountryCode.Vu]: t<string>('CountryCode->Vu'),
    [CountryCode.Wf]: t<string>('CountryCode->Wf'),
    [CountryCode.Ws]: t<string>('CountryCode->Ws'),
    [CountryCode.Ye]: t<string>('CountryCode->Ye'),
    [CountryCode.Yu]: t<string>('CountryCode->Yu'),
    [CountryCode.Za]: t<string>('CountryCode->Za'),
    [CountryCode.Zm]: t<string>('CountryCode->Zm'),
    [CountryCode.Zw]: t<string>('CountryCode->Zw')
  }
  return (code: CountryCode | ExistingCountryCode) => translatedCodes[code]
}

export const useGetCountryThreeLetterCode = () => {
  const translatedCodes: Record<CountryCode, string> = {
    [CountryCode.Cz]: 'CZK',
    [CountryCode.Sk]: 'SVK',
    [CountryCode.Us]: 'USA',
    [CountryCode.Aw]: 'ABW',
    [CountryCode.Af]: 'AFG',
    [CountryCode.Ao]: 'AGO',
    [CountryCode.Ai]: 'AIA',
    [CountryCode.Al]: 'ALB',
    [CountryCode.Ad]: 'AND',
    [CountryCode.An]: 'ANT',
    [CountryCode.Ae]: 'ARE',
    [CountryCode.Ar]: 'ARG',
    [CountryCode.Am]: 'ARM',
    [CountryCode.As]: 'ASM',
    [CountryCode.Ag]: 'ATG',
    [CountryCode.Au]: 'AUS',
    [CountryCode.At]: 'AUT',
    [CountryCode.Az]: 'AZE',
    [CountryCode.Bi]: 'BDI',
    [CountryCode.Be]: 'BEL',
    [CountryCode.Bj]: 'BEN',
    [CountryCode.Bf]: 'BFA',
    [CountryCode.Bd]: 'BGD',
    [CountryCode.Bg]: 'BGR',
    [CountryCode.Bh]: 'BHR',
    [CountryCode.Bs]: 'BHS',
    [CountryCode.Ba]: 'BIH',
    [CountryCode.By]: 'BLR',
    [CountryCode.Bz]: 'BLZ',
    [CountryCode.Bm]: 'BMU',
    [CountryCode.Bo]: 'BOL',
    [CountryCode.Br]: 'BRA',
    [CountryCode.Bb]: 'BRB',
    [CountryCode.Bn]: 'BRN',
    [CountryCode.Bt]: 'BTN',
    [CountryCode.Bw]: 'BWA',
    [CountryCode.Cf]: 'CAF',
    [CountryCode.Ca]: 'CAN',
    [CountryCode.Ci]: 'CIV',
    [CountryCode.Cm]: 'CMR',
    [CountryCode.Cd]: 'COD',
    [CountryCode.Cg]: 'COG',
    [CountryCode.Ck]: 'COK',
    [CountryCode.Co]: 'COL',
    [CountryCode.Km]: 'COM',
    [CountryCode.Cv]: 'CPV',
    [CountryCode.Cr]: 'CRI',
    [CountryCode.Cs]: 'CSR',
    [CountryCode.Cu]: 'CUB',
    [CountryCode.Ky]: 'CYM',
    [CountryCode.Cy]: 'CYP',
    [CountryCode.Cz]: 'CZE',
    [CountryCode.Dd]: 'DDR',
    [CountryCode.De]: 'DEU',
    [CountryCode.Dj]: 'DJI',
    [CountryCode.Dm]: 'DMA',
    [CountryCode.Dk]: 'DNK',
    [CountryCode.Do]: 'DOM',
    [CountryCode.Dz]: 'DZA',
    [CountryCode.Ec]: 'ECU',
    [CountryCode.Eg]: 'EGY',
    [CountryCode.Er]: 'ERI',
    [CountryCode.Eh]: 'ESH',
    [CountryCode.Es]: 'ESP',
    [CountryCode.Ee]: 'EST',
    [CountryCode.Et]: 'ETH',
    [CountryCode.Fi]: 'FIN',
    [CountryCode.Fj]: 'FJI',
    [CountryCode.Fk]: 'FLK',
    [CountryCode.Fr]: 'FRA',
    [CountryCode.Fo]: 'FRO',
    [CountryCode.Fm]: 'FSM',
    [CountryCode.Ga]: 'GAB',
    [CountryCode.Gb]: 'GBR',
    [CountryCode.Ge]: 'GEO',
    [CountryCode.Gh]: 'GHA',
    [CountryCode.Gi]: 'GIB',
    [CountryCode.Gn]: 'GIN',
    [CountryCode.Gp]: 'GLP',
    [CountryCode.Gm]: 'GMB',
    [CountryCode.Gw]: 'GNB',
    [CountryCode.Gq]: 'GNQ',
    [CountryCode.Gr]: 'GRC',
    [CountryCode.Gd]: 'GRD',
    [CountryCode.Gl]: 'GRL',
    [CountryCode.Gt]: 'GTM',
    [CountryCode.Gf]: 'GUF',
    [CountryCode.Gu]: 'GUM',
    [CountryCode.Gy]: 'GUY',
    [CountryCode.Hk]: 'HKG',
    [CountryCode.Hn]: 'HND',
    [CountryCode.Hr]: 'HRV',
    [CountryCode.Ht]: 'HTI',
    [CountryCode.Hu]: 'HUN',
    [CountryCode.Ch]: 'CHE',
    [CountryCode.Cl]: 'CHL',
    [CountryCode.Cn]: 'CHN',
    [CountryCode.Id]: 'IDN',
    [CountryCode.In]: 'IND',
    [CountryCode.Ie]: 'IRL',
    [CountryCode.Ir]: 'IRN',
    [CountryCode.Iq]: 'IRQ',
    [CountryCode.Is]: 'ISL',
    [CountryCode.Il]: 'ISR',
    [CountryCode.It]: 'ITA',
    [CountryCode.Jm]: 'JAM',
    [CountryCode.Jo]: 'JOR',
    [CountryCode.Jp]: 'JPN',
    [CountryCode.Kz]: 'KAZ',
    [CountryCode.Ke]: 'KEN',
    [CountryCode.Kg]: 'KGZ',
    [CountryCode.Kh]: 'KHM',
    [CountryCode.Ki]: 'KIR',
    [CountryCode.Kn]: 'KNA',
    [CountryCode.Kr]: 'KOR',
    [CountryCode.Kw]: 'KWT',
    [CountryCode.La]: 'LAO',
    [CountryCode.Lb]: 'LBN',
    [CountryCode.Lr]: 'LBR',
    [CountryCode.Ly]: 'LBY',
    [CountryCode.Lc]: 'LCA',
    [CountryCode.Li]: 'LIE',
    [CountryCode.Lk]: 'LKA',
    [CountryCode.Ls]: 'LSO',
    [CountryCode.Lt]: 'LTU',
    [CountryCode.Lu]: 'LUX',
    [CountryCode.Lv]: 'LVA',
    [CountryCode.Mo]: 'MAC',
    [CountryCode.Ma]: 'MAR',
    [CountryCode.Mc]: 'MCO',
    [CountryCode.Md]: 'MDA',
    [CountryCode.Mg]: 'MDG',
    [CountryCode.Mv]: 'MDV',
    [CountryCode.Mx]: 'MEX',
    [CountryCode.Mh]: 'MHL',
    [CountryCode.Mk]: 'MKD',
    [CountryCode.Ml]: 'MLI',
    [CountryCode.Mt]: 'MLT',
    [CountryCode.Mm]: 'MMR',
    [CountryCode.Me]: 'MNE',
    [CountryCode.Mn]: 'MNG',
    [CountryCode.Mp]: 'MNP',
    [CountryCode.Mz]: 'MOZ',
    [CountryCode.Mr]: 'MRT',
    [CountryCode.Ms]: 'MSR',
    [CountryCode.Mq]: 'MTQ',
    [CountryCode.Mu]: 'MUS',
    [CountryCode.Mw]: 'MWI',
    [CountryCode.My]: 'MYS',
    [CountryCode.Na]: 'NAM',
    [CountryCode.Nc]: 'NCL',
    [CountryCode.Ne]: 'NER',
    [CountryCode.Nf]: 'NFK',
    [CountryCode.Ng]: 'NGA',
    [CountryCode.Ni]: 'NIC',
    [CountryCode.Nu]: 'NIU',
    [CountryCode.Nl]: 'NLD',
    [CountryCode.No]: 'NOR',
    [CountryCode.Np]: 'NPL',
    [CountryCode.Nr]: 'NRU',
    [CountryCode.Nz]: 'NZL',
    [CountryCode.Om]: 'OMN',
    [CountryCode.Pk]: 'PAK',
    [CountryCode.Pa]: 'PAN',
    [CountryCode.Pn]: 'PCN',
    [CountryCode.Pe]: 'PER',
    [CountryCode.Ph]: 'PHL',
    [CountryCode.Pw]: 'PLW',
    [CountryCode.Ps]: 'PNA',
    [CountryCode.Pg]: 'PNG',
    [CountryCode.Pl]: 'POL',
    [CountryCode.Pr]: 'PRI',
    [CountryCode.Kp]: 'PRK',
    [CountryCode.Pt]: 'PRT',
    [CountryCode.Py]: 'PRY',
    [CountryCode.Pf]: 'PYF',
    [CountryCode.Qa]: 'QAT',
    [CountryCode.Re]: 'REU',
    [CountryCode.Ro]: 'ROM',
    [CountryCode.Ru]: 'RUS',
    [CountryCode.Rw]: 'RWA',
    [CountryCode.Sa]: 'SAU',
    [CountryCode.Sd]: 'SDN',
    [CountryCode.Sn]: 'SEN',
    [CountryCode.Sg]: 'SGP',
    [CountryCode.Sh]: 'SHN',
    [CountryCode.Sj]: 'SJM',
    [CountryCode.Sb]: 'SLB',
    [CountryCode.Sl]: 'SLE',
    [CountryCode.Sv]: 'SLV',
    [CountryCode.Sm]: 'SMR',
    [CountryCode.So]: 'SOM',
    [CountryCode.Pm]: 'SPM',
    [CountryCode.Rs]: 'SRB',
    [CountryCode.Ss]: 'SSD',
    [CountryCode.St]: 'STP',
    [CountryCode.Su]: 'SUN',
    [CountryCode.Sr]: 'SUR',
    [CountryCode.Sk]: 'SVK',
    [CountryCode.Si]: 'SVN',
    [CountryCode.Se]: 'SWE',
    [CountryCode.Sz]: 'SWZ',
    [CountryCode.Sc]: 'SYC',
    [CountryCode.Sy]: 'SYR',
    [CountryCode.Tc]: 'TCA',
    [CountryCode.Td]: 'TCD',
    [CountryCode.Tg]: 'TGO',
    [CountryCode.Th]: 'THA',
    [CountryCode.Tj]: 'TJK',
    [CountryCode.Tk]: 'TKL',
    [CountryCode.Tm]: 'TKM',
    [CountryCode.To]: 'TON',
    [CountryCode.Tt]: 'TTO',
    [CountryCode.Tn]: 'TUN',
    [CountryCode.Tr]: 'TUR',
    [CountryCode.Tv]: 'TUV',
    [CountryCode.Tw]: 'TWN',
    [CountryCode.Tz]: 'TZA',
    [CountryCode.Ug]: 'UGA',
    [CountryCode.Ua]: 'UKR',
    [CountryCode.Uy]: 'URY',
    [CountryCode.Us]: 'USA',
    [CountryCode.Uz]: 'UZB',
    [CountryCode.Va]: 'VAT',
    [CountryCode.Vc]: 'VCT',
    [CountryCode.Ve]: 'VEN',
    [CountryCode.Vg]: 'VGB',
    [CountryCode.Vi]: 'VIR',
    [CountryCode.Vn]: 'VNM',
    [CountryCode.Vu]: 'VUT',
    [CountryCode.Wf]: 'WLF',
    [CountryCode.Ws]: 'WSM',
    [CountryCode.Ye]: 'YEM',
    [CountryCode.Yu]: 'YUG',
    [CountryCode.Za]: 'ZAF',
    [CountryCode.Zm]: 'ZMB',
    [CountryCode.Zw]: 'ZWE'
  }
  return (code: CountryCode | ExistingCountryCode) => translatedCodes[code]
}

export const useGetShowCountries = (): CountryCode[] => {
  return Object.values(CountryCode)
}

export const useGetShowCountrySelectData = () => {
  const countryCodes = useGetShowCountries()
  const translateCountryCode = useTranslateCountry()
  return countryCodes.reduce(
    (acc, countryCode) => ({
      ...acc,
      [countryCode]: translateCountryCode(countryCode)
    }),
    {}
  )
}
