import {Dayjs} from 'dayjs'

import {EventPricingInput} from '../../../../../../__generated__/schema'
import {convertValueToFloat} from '../../../../../../utils/conversions'

export enum PricingFormField {
  DATETIME = 'datetime',
  TICKET_TYPES = 'ticketTypes',
  SCHEDULE_APPROACH = 'scheduleApproach'
}

export enum EventTicketTypeFormField {
  PROMOTIONS = 'promotions',
  PRICE = 'price',
  NAME = 'name',
  DESCRIPTION = 'description',
  TICKET_TYPE_ID = 'ticketTypeId',
  VAT_RATE = 'vatRate'
}

export enum ScheduleApproach {
  Now = 'now',
  Schedule = 'schedule'
}

export interface IPricingForm {
  [PricingFormField.DATETIME]: Dayjs | null
  [PricingFormField.SCHEDULE_APPROACH]?: ScheduleApproach
  [PricingFormField.TICKET_TYPES]: Array<{
    [EventTicketTypeFormField.TICKET_TYPE_ID]: number
    [EventTicketTypeFormField.PRICE]: number | null
    [EventTicketTypeFormField.VAT_RATE]: number
    [EventTicketTypeFormField.PROMOTIONS]: boolean
    [EventTicketTypeFormField.DESCRIPTION]?: string | null
    [EventTicketTypeFormField.NAME]: string
  }>
}

export const getEventPricingInput = (
  data: IPricingForm
): EventPricingInput => ({
  startsAt: data[PricingFormField.DATETIME]?.toISOString(),
  isStartsAtNow:
    data[PricingFormField.SCHEDULE_APPROACH] === ScheduleApproach.Now,
  ticketTypes: data[PricingFormField.TICKET_TYPES].map((t) => ({
    ticketTypeId: parseInt(String(t.ticketTypeId), 10),
    price: convertValueToFloat(t.price) || 0,
    promotions: t.promotions,
    vatRate: t[EventTicketTypeFormField.VAT_RATE]
  }))
})
