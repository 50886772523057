import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'

import {Theme} from '../../../../theme'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {CancelButton, SaveButton} from '../../../common/Buttons'
import {useShowFormStyles} from '../shows/ShowForm'
import {useCreatePaymentMethodGroup} from './graphql'
import {PaymentMethodGroupForm} from './PaymentMethodGroupForm'
import {IPaymentMethodGroupForm} from './types'

const CREATE_PAYMENT_METHOD_GROUP_FORM_ID = 'createPaymentMethodGroupFormId'

const useStyles = makeStyles<Theme>(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface ICreatePaymentMethodGroupDrawerProps {
  isOpen: boolean
  closeDrawer: () => void
}

export const CreatePaymentMethodGroupDrawer: React.FC<ICreatePaymentMethodGroupDrawerProps> =
  ({isOpen, closeDrawer}: ICreatePaymentMethodGroupDrawerProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const createPaymentMethodGroup = useCreatePaymentMethodGroup()
    const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
    const handleSubmit = useCallback(
      async (form: IPaymentMethodGroupForm) => {
        try {
          setShowBackdrop(true)
          await createPaymentMethodGroup(form)
          closeDrawer()
        } catch (e) {
          defaultErrorHandler(e, t('Error while creating payment method group'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        createPaymentMethodGroup,
        defaultErrorHandler,
        closeDrawer,
        setShowBackdrop,
        t
      ]
    )
    const showFormClasses = useShowFormStyles()
    return (
      <Drawer
        onClose={closeDrawer}
        open={isOpen}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Create payment method group')}
            />
          }
          footer={
            <>
              <CancelButton onClick={closeDrawer} />
              <SaveButton
                type="submit"
                form={CREATE_PAYMENT_METHOD_GROUP_FORM_ID}
              >
                {t('Create')}
              </SaveButton>
            </>
          }
        >
          <PaymentMethodGroupForm
            formId={CREATE_PAYMENT_METHOD_GROUP_FORM_ID}
            onSubmit={handleSubmit}
            className={showFormClasses.form}
          />
        </DrawerTemplate>
      </Drawer>
    )
  }
