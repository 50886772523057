import React from 'react'
import {RouteComponentProps} from 'react-router-dom'
import {PageNotFound} from '../PageNotFound'
import {SeatOptionsPage} from './SeatOptionsPage'

export const SeatOptions = ({
  match
}: RouteComponentProps<{eventId: string}>) => {
  const eventId = parseInt(match.params.eventId, 10)
  if (isNaN(eventId)) {
    return <PageNotFound />
  }
  return <SeatOptionsPage eventId={eventId} />
}
