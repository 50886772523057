import {Box} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {AvailableInputDataForDailyCashierReportsQuery} from '../../../../__generated__/schema'
import {useFormatUserName} from '../../../../hooks/formatUserName'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {InputRow} from '../../../common'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../form/UncontrolledFormRadioGroup'
import {Blank} from '../../../visual/Blank'
import {
  CreateDailyCashierPaymentReportStep,
  DailyCashierPaymentReportFormField,
  IDailyCashierReportForm
} from './types'

const StyledForm = styled('form')(({theme}) => ({
  padding: theme.spacing(2, 3)
}))

interface ICreateDailyCashierReportFormProps {
  formId: string
  onSubmit: (data: IDailyCashierReportForm) => void
  step: CreateDailyCashierPaymentReportStep
  data: AvailableInputDataForDailyCashierReportsQuery['availableInputDataForDailyCashierReports']
}

export const CreateDailyCashierReportForm: React.FC<ICreateDailyCashierReportFormProps> =
  ({formId, onSubmit, step, data}: ICreateDailyCashierReportFormProps) => {
    const {t} = useTranslation()
    const {errors, control, handleSubmit, watch} =
      useFormContext<IDailyCashierReportForm>()
    const formatUserName = useFormatUserName(true)
    const {formatDateNumeric} = useDateTimeFormatters()
    const selectedUserId = watch(
      DailyCashierPaymentReportFormField.CashierUserId
    )
    const selectedUser =
      selectedUserId &&
      data.find((d) => String(d.user.id) === selectedUserId)?.user
    const dateOptions = data
      .find((d) => String(d.user.id) === selectedUserId)
      ?.paymentDatesWithoutReport.map((d) => ({
        value: d,
        label: <BasicRadioLabel primaryText={formatDateNumeric(new Date(d))} />
      }))
    return (
      <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
        {data.length ? (
          <>
            <Box
              sx={{
                display:
                  step === CreateDailyCashierPaymentReportStep.SelectCashier
                    ? 'block'
                    : 'none'
              }}
            >
              <InputRow
                nodes={[
                  <UncontrolledFormRadioGroup<IDailyCashierReportForm>
                    label={t('Select cashier')}
                    name={DailyCashierPaymentReportFormField.CashierUserId}
                    key={DailyCashierPaymentReportFormField.CashierUserId}
                    errors={errors}
                    validationOptions={{
                      required: true
                    }}
                    fullWidth
                    control={control}
                    options={data.map((d) => ({
                      value: String(d.user.id),
                      label: (
                        <BasicRadioLabel primaryText={formatUserName(d.user)} />
                      )
                    }))}
                  />
                ]}
              />
            </Box>
            <Box
              sx={{
                display:
                  step === CreateDailyCashierPaymentReportStep.SelectDate
                    ? 'block'
                    : 'none'
              }}
            >
              <InputRow
                nodes={[
                  <UncontrolledFormRadioGroup<IDailyCashierReportForm>
                    label={selectedUser ? formatUserName(selectedUser) : ''}
                    name={DailyCashierPaymentReportFormField.ReportDate}
                    key={DailyCashierPaymentReportFormField.ReportDate}
                    errors={errors}
                    validationOptions={{
                      required: true
                    }}
                    fullWidth
                    disabled={dateOptions ? dateOptions.length === 0 : false}
                    control={control}
                    options={
                      dateOptions || [
                        {
                          value: 'none',
                          label: <BasicRadioLabel primaryText={t('None')} />
                        }
                      ]
                    }
                  />
                ]}
              />
            </Box>
          </>
        ) : (
          <Blank
            title={t('All payments have been processed.')}
            description={t(
              'No payments were found that should be included in the daily cashier’s report.'
            )}
          />
        )}
      </StyledForm>
    )
  }
