import {Box, Typography} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentMethodDataAggregatedByTransactionType,
  PaymentReportDataAggregatedByPaymentMethodAndTransactionType,
  TransactionType
} from '../../../__generated__/schema'
import {useTranslateTransactionType} from '../../../hooks/translateTransactionType'
import {DataGridTable} from '../DataGridTable'
import {commonTableSxProps, getCellClassName} from './utils'

type DataAggregatedByPaymentMethodAndTransactionType = Pick<
  PaymentReportDataAggregatedByPaymentMethodAndTransactionType,
  'paymentMethodId' | 'paymentMethodName' | 'intentsCount'
> & {
  transactionTypes: Pick<
    PaymentMethodDataAggregatedByTransactionType,
    'intentsCount' | 'transactionType'
  >[]
}

const formatData = (data: DataAggregatedByPaymentMethodAndTransactionType[]) =>
  data.map((item) => ({
    id: item.paymentMethodId,
    paymentMethodName: item.paymentMethodName,
    chargeCount: item.transactionTypes.find(
      (v) => v.transactionType === TransactionType.Charge
    )?.intentsCount,
    refundCount: item.transactionTypes.find(
      (v) => v.transactionType === TransactionType.Refund
    )?.intentsCount,
    depositCount: item.transactionTypes.find(
      (v) => v.transactionType === TransactionType.Deposit
    )?.intentsCount,
    withdrawalCount: item.transactionTypes.find(
      (v) => v.transactionType === TransactionType.Withdrawal
    )?.intentsCount,
    totalCount: item.intentsCount
  }))

interface IPaymentsCountProps {
  id?: string
  label: string
  dataAggregatedByPaymentMethodAndTransactionType: DataAggregatedByPaymentMethodAndTransactionType[]
}

export const PaymentsCount: React.FC<IPaymentsCountProps> = ({
  id,
  label,
  dataAggregatedByPaymentMethodAndTransactionType
}: IPaymentsCountProps) => {
  const {t} = useTranslation()
  const translateTransactionType = useTranslateTransactionType()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Payment method'),
        field: 'paymentMethodName',
        flex: 1,
        sortable: false
      },
      {
        headerName: translateTransactionType(TransactionType.Charge),
        field: 'chargeCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false
      },
      {
        headerName: translateTransactionType(TransactionType.Refund),
        field: 'refundCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false,
        cellClassName: () => 'amount negative'
      },
      {
        headerName: translateTransactionType(TransactionType.Deposit),
        field: 'depositCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false
      },
      {
        headerName: translateTransactionType(TransactionType.Withdrawal),
        field: 'withdrawalCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false,
        cellClassName: () => 'amount negative'
      },
      {
        headerName: t('Total'),
        field: 'totalCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false,
        cellClassName: getCellClassName
      }
    ],
    [t, translateTransactionType]
  )
  return (
    <Box id={id} sx={commonTableSxProps}>
      <Typography variant="subtitle1" sx={{pb: 2}}>
        {label}
      </Typography>
      <DataGridTable
        autoHeight
        columns={columns}
        rows={formatData(dataAggregatedByPaymentMethodAndTransactionType)}
        hideFooter
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </Box>
  )
}
