import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ErrorMessages,
  GetLightweightProductTypesQuery,
  GetLightweightProductTypesQueryVariables,
  LightweightProductGroupsQuery,
  LightweightProductGroupsQueryVariables,
  LightweightWarehousesQuery,
  LightweightWarehousesQueryVariables
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {
  GET_LIGHTWEIGHT_PRODUCT_TYPES,
  LIGHTWEIGHT_PRODUCT_GROUPS,
  LIGHTWEIGHT_WAREHOUSES
} from '../graphql'
import {useCreateInventoryCheck} from './graphql'
import {InventoryCheckForm} from './InventoryCheckForm'
import {IInventoryCheckForm, InventoryCheckFormLocation} from './types'
import {transformInventoryCheckFormToCreateInventoryCheckInput} from './utils'

const CREATE_INVENTORY_CHECK_FORM_ID = 'createInventoryCheckForm'

interface ICreateInventoryCheckDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateInventoryCheckDrawer: React.FC<ICreateInventoryCheckDrawerProps> =
  ({isOpen, onClose}: ICreateInventoryCheckDrawerProps) => {
    const {t} = useTranslation()
    const [skip, setSkip] = useState(!isOpen)
    const createInventoryCheck = useCreateInventoryCheck()
    const {
      addInfoNotification,
      defaultErrorHandler,
      setShowBackdrop,
      customErrorHandler
    } = useMutationAssistanceHooks()
    const history = useHistory()
    const {
      data: warehousesData,
      loading: warehousesLoading,
      error: warehousesError
    } = useQuery<
      LightweightWarehousesQuery,
      LightweightWarehousesQueryVariables
    >(LIGHTWEIGHT_WAREHOUSES, {
      variables: {paginationInput: {offset: 0, limit: 300}},
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: productGroupsData,
      loading: productGroupsLoading,
      error: productGroupsError
    } = useQuery<
      LightweightProductGroupsQuery,
      LightweightProductGroupsQueryVariables
    >(LIGHTWEIGHT_PRODUCT_GROUPS, {
      fetchPolicy: 'network-only',
      skip
    })
    const {
      data: productTypesData,
      loading: productTypesLoading,
      error: productTypesError
    } = useQuery<
      GetLightweightProductTypesQuery,
      GetLightweightProductTypesQueryVariables
    >(GET_LIGHTWEIGHT_PRODUCT_TYPES, {
      fetchPolicy: 'network-only',
      skip
    })
    const handleSubmit = useCallback(
      async (formData: IInventoryCheckForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await createInventoryCheck(
            transformInventoryCheckFormToCreateInventoryCheckInput(formData)
          )
          addInfoNotification(t('Inventory check has been created'))
          if (data) {
            history.push(
              routeTo.admin.inventoryChecks.detail(data.createInventoryCheck.id)
            )
          }
          onClose()
        } catch (error) {
          if (
            getGraphQLErrorRelatedToErrorMessage(
              error,
              ErrorMessages.ProductNotFound
            )
          ) {
            customErrorHandler(error, {
              title: t('No warehouse products found'),
              contentText: t(
                'No warehouse products were found for the selected filter. Please adjust your filter criteria and try again.'
              ),
              confirmButtonLabel: t('Got it')
            })
          } else {
            defaultErrorHandler(
              error,
              t('Error while creating inventory check')
            )
          }
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createInventoryCheck,
        customErrorHandler,
        defaultErrorHandler,
        history,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
      return () => setSkip(true)
    }, [isOpen])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={
            warehousesLoading || productGroupsLoading || productTypesLoading
          }
          errorMessage={
            (warehousesError && t('Error while loading warehouses')) ||
            (productGroupsError && t('Error while loading product groups')) ||
            (productTypesError && t('Error while loading product types'))
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create inventory check')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_INVENTORY_CHECK_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {warehousesData && productGroupsData && productTypesData && (
            <InventoryCheckForm
              formId={CREATE_INVENTORY_CHECK_FORM_ID}
              onSubmit={handleSubmit}
              warehouses={warehousesData.warehouses.items}
              productGroups={productGroupsData.productGroups}
              productTypes={productTypesData.productTypes}
              location={InventoryCheckFormLocation.Create}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
