import {useApolloClient, useLazyQuery} from '@apollo/react-hooks'
import {noop} from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  CartPropertiesFragment,
  CurrentCartQuery,
  CurrentCartQueryVariables
} from '../../../../__generated__/schema'
import {
  isCustomerDisplayEnabled,
  usePostCustomerDisplayMessage
} from '../../../../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../../../../customerDisplayBroadcastChannel/types'
import {useLocalStorageState} from '../../../../hooks/storage'
import {CART_PROPERTIES_FRAGMENT} from '../graphql'
import {CURRENT_CART_QUERY} from './graphql'

interface ICurrentCartContext {
  currentCart: CartPropertiesFragment | null
  currentCartId: number | null
  initializeCurrentCart(cart: CartPropertiesFragment | null): void
  updateCurrentCart: () => void
  resetCurrentCart: () => void
  refetchCurrentCart: () => void
}

const CurrentCartContext = createContext<ICurrentCartContext>({
  currentCart: null,
  currentCartId: null,
  initializeCurrentCart: noop,
  updateCurrentCart: noop,
  resetCurrentCart: noop,
  refetchCurrentCart: noop
})

interface ICurrentCartContextProviderProps {
  children: React.ReactNode
}

export const CurrentCartContextProvider: React.FC<ICurrentCartContextProviderProps> =
  ({children}: ICurrentCartContextProviderProps) => {
    const [currentCartId, setCurrentCartId] = useLocalStorageState<
      number | null
    >('currentCartId', null)
    const client = useApolloClient()
    const [currentCart, setCurrentCart] =
      useState<CartPropertiesFragment | null>(null)

    const [queryCurrentCart] = useLazyQuery<
      CurrentCartQuery,
      CurrentCartQueryVariables
    >(CURRENT_CART_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setCurrentCart(data.cart)
      }
    })
    useEffect(() => {
      if (currentCartId !== null && currentCart === null) {
        queryCurrentCart({variables: {cartId: currentCartId}})
        // todo: handle case, cart is expired
      }
    }, [currentCart, currentCartId, queryCurrentCart])

    const refetchCurrentCart = useCallback(() => {
      if (currentCartId) {
        queryCurrentCart({
          variables: {cartId: currentCartId}
        })
      }
    }, [currentCartId, queryCurrentCart])
    const postCustomerDisplayMessage = usePostCustomerDisplayMessage()

    const updateCurrentCart = useCallback(() => {
      if (currentCartId) {
        const fragment = client.readFragment({
          id: `Cart:${currentCartId}`,
          fragment: CART_PROPERTIES_FRAGMENT,
          fragmentName: 'CartProperties'
        })
        setCurrentCart(fragment)
        if (isCustomerDisplayEnabled()) {
          postCustomerDisplayMessage({
            type: CustomerDisplayMessageType.CurrentCartChange,
            payload: fragment
          })
        }
      }
    }, [client, currentCartId, postCustomerDisplayMessage])

    const initializeCurrentCart = useCallback(
      (cart: CartPropertiesFragment) => {
        setCurrentCart((c) => c || cart)
        setCurrentCartId(cart.id)
        if (isCustomerDisplayEnabled()) {
          postCustomerDisplayMessage({
            type: CustomerDisplayMessageType.CurrentCartChange,
            payload: cart
          })
        }
      },
      [postCustomerDisplayMessage, setCurrentCartId]
    )
    const resetCurrentCart = useCallback(() => {
      setCurrentCartId(null)
      setCurrentCart(null)
      if (isCustomerDisplayEnabled()) {
        postCustomerDisplayMessage({
          type: CustomerDisplayMessageType.CurrentCartChange,
          payload: null
        })
      }
    }, [postCustomerDisplayMessage, setCurrentCartId])
    return (
      <CurrentCartContext.Provider
        value={{
          currentCart,
          currentCartId,
          updateCurrentCart,
          initializeCurrentCart,
          resetCurrentCart,
          refetchCurrentCart
        }}
      >
        {children}
      </CurrentCartContext.Provider>
    )
  }

export const useCurrentCart = () => useContext(CurrentCartContext)
