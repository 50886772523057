import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../../../theme'
import {IntentStructureAccordion} from '../../../../common/IntentStructureAccordion'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {InHouseVoucherIntentFrame} from './InHouseVoucherIntentFrame'
import {IntentStructureAccordionSecondaryChildren} from './IntentStructureAccordionSecondaryChildren'
import {IInHouseVoucherIntentStructure} from './types'

interface IInHouseVoucherIntentStructureProps {
  isExpanded: boolean
  structure: IInHouseVoucherIntentStructure
  onStructureExpand: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}))

export const InHouseVoucherIntentStructure: React.FC<IInHouseVoucherIntentStructureProps> =
  ({
    structure,
    onStructureExpand,
    isExpanded
  }: IInHouseVoucherIntentStructureProps) => {
    const classes = useStyles()
    return (
      <IntentStructureAccordion
        isExpanded={isExpanded}
        name={structure.campaign.name}
        onSummaryClick={onStructureExpand}
        Icon={CardGiftcardOutlinedIcon}
        secondaryChildren={
          <IntentStructureAccordionSecondaryChildren
            intentFrames={structure.voucherFrames}
            isExpanded={isExpanded}
          />
        }
      >
        <ListOfItemsSeparatedByDividers
          className={classes.root}
          DividerProps={{className: classes.divider}}
        >
          {structure.voucherFrames.map((vf) => (
            <InHouseVoucherIntentFrame key={vf.key} frame={vf} />
          ))}
        </ListOfItemsSeparatedByDividers>
      </IntentStructureAccordion>
    )
  }
