import {useLazyQuery} from '@apollo/react-hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import {Box, Button, Chip, Typography} from '@mui/material'
import {ApolloError} from 'apollo-client'
import {difference, isNil} from 'lodash'
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BroadDiscountCodePropertiesFragment,
  CheckDiscountCodeForTourItemsQuery,
  CheckDiscountCodeForTourItemsQueryVariables,
  DiscountApplication,
  DiscountCodeQuery,
  DiscountCodeQueryVariables,
  DiscountCodeState,
  EnabledDiscountPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../../../../../hooks/formatDiscountValue'
import {useMutationAssistanceHooks} from '../../../../../../../hooks/mutationAssistanceHooks'
import {useTranslateEffectiveClientPrice} from '../../../../../../../hooks/translateCurrencies'
import {Tooltip} from '../../../../../../common'
import {ListOfItemsSeparatedByDividers} from '../../../../../../common/ListOfItemsSeparatedByDividers'
import {
  MassCheckbox,
  MassCheckboxState
} from '../../../../../../common/MassCheckbox'
import {Blank} from '../../../../../../visual/Blank'
import {CheckDiscountCodeItem} from '../../../CheckDiscountCodeItem'
import {useCurrentCart} from '../../../CurrentCartContext'
import {DiscountCodeErrorDialog} from '../../../DiscountCodeErrorDialog'
import {DiscountListItem} from '../../../DiscountListItem'
import {
  CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS,
  DISCOUNT_CODE
} from '../../../graphql'
import {getRemainingDiscountUsageLimitPerOrder} from '../../../utils'
import {Item} from './Item'
import {Subtotal} from './Subtotal'

interface IContentProps {
  tourItems: TourItemPropertiesFragment[]
  discounts: EnabledDiscountPropertiesFragment[]
  onAddButtonClick: (
    itemIds: number[],
    discountId: number,
    discountCodeId?: number
  ) => Promise<void>
  getChipDeleteButtonClickHandler: (
    itemId: number,
    discountId: number
  ) => () => Promise<void>
  tourTimeSlotId: number
}

export const Content: React.FC<IContentProps> = ({
  tourItems,
  discounts,
  onAddButtonClick,
  getChipDeleteButtonClickHandler,
  tourTimeSlotId
}: IContentProps) => {
  const {t} = useTranslation()
  const {currentCartId} = useCurrentCart()
  const [code, setCode] = useState<string>('')
  const [validCodes, setValidCodes] = useState<
    BroadDiscountCodePropertiesFragment[]
  >([])
  const [discountCodeError, setDiscountCodeError] =
    useState<ApolloError | null>(null)
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const formatDiscountValue = useFormatDiscountValue(true)
  const tourItemsWithAllowedDiscounts = tourItems.filter(
    (item) => item.admissionTypeAssignment.hasEnabledDiscountsOnRetail
  )
  const idsOfTourItemsWithAllowedDiscounts = tourItemsWithAllowedDiscounts.map(
    ({id}) => id
  )
  const selectableTicketItemsIds = tourItemsWithAllowedDiscounts
    .filter((item) => item.appliedDiscounts.length === 0)
    .map(({id}) => id)
  const [selectedItemIds, setSelectedItemIds] = useState<number[]>([])
  const checkboxesControllerState =
    selectedItemIds.length === 0
      ? MassCheckboxState.Unselected
      : difference(selectableTicketItemsIds, selectedItemIds).length === 0
      ? MassCheckboxState.Selected
      : MassCheckboxState.Indeterminate
  const createItemCheckboxClickHandler = useCallback(
    (tourItemId: number) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedItemIds((ids) =>
        ids.includes(tourItemId)
          ? ids.filter((id) => id !== tourItemId)
          : [...ids, tourItemId]
      )
    },
    []
  )
  const [
    checkDiscountCodeForTourItems,
    {loading: isLoadingCheckDiscountCodeForTourItems}
  ] = useLazyQuery<
    CheckDiscountCodeForTourItemsQuery,
    CheckDiscountCodeForTourItemsQueryVariables
  >(CHECK_DISCOUNT_CODE_FOR_TOUR_ITEMS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setValidCodes((validCodes) => [
        data.checkDiscountCodeForTourItems,
        ...validCodes
      ])
      setCode('')
    },
    onError: setDiscountCodeError
  })
  const [getDiscountCode, {loading: isLoadingGetDiscountCode}] = useLazyQuery<
    DiscountCodeQuery,
    DiscountCodeQueryVariables
  >(DISCOUNT_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: ({discountCode}) =>
      setValidCodes((validCodes: BroadDiscountCodePropertiesFragment[]) =>
        validCodes.reduce<BroadDiscountCodePropertiesFragment[]>(
          (acc, vc) => [...acc, vc.id === discountCode.id ? discountCode : vc],
          []
        )
      ),
    onError: setDiscountCodeError
  })
  const updateValidCode = useCallback(
    (discountCodeId: number) => {
      const codeInList = validCodes.find((vc) => vc.id === discountCodeId)
      if (codeInList) {
        getDiscountCode({variables: {id: codeInList.id}})
      }
    },
    [getDiscountCode, validCodes]
  )
  const discountCodes = discounts.filter(
    (discount) => discount.application === DiscountApplication.Code
  )
  const handleCodeInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCode(e.target.value.toUpperCase())
    },
    []
  )
  const handleCancelButtonClick = useCallback(() => {
    setCode('')
  }, [])
  const getAddButtonClickHandler = useCallback(
    (discountId: number, discountCodeId?: number) => async () => {
      await onAddButtonClick(selectedItemIds, discountId, discountCodeId)
      setSelectedItemIds([])
      if (discountCodeId) {
        updateValidCode(discountCodeId)
      }
    },
    [onAddButtonClick, selectedItemIds, updateValidCode]
  )
  const handleCheckDiscountCode = useCallback(() => {
    if (currentCartId) {
      checkDiscountCodeForTourItems({
        variables: {
          cartId: currentCartId,
          tourTimeSlotId,
          discountCodeName: code
        }
      })
    }
  }, [checkDiscountCodeForTourItems, code, currentCartId, tourTimeSlotId])
  const handleConfirmDiscountCodeErrorButtonClick = useCallback(() => {
    setDiscountCodeError(null)
    setCode('')
  }, [])
  useEffect(() => {
    if (isLoadingCheckDiscountCodeForTourItems || isLoadingGetDiscountCode) {
      setShowBackdrop(true)
    } else {
      setShowBackdrop(false)
    }
  }, [
    setShowBackdrop,
    isLoadingCheckDiscountCodeForTourItems,
    isLoadingGetDiscountCode
  ])
  return (
    <Box
      sx={{display: 'grid', height: '100%', gridTemplateColumns: '360px 1fr'}}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          overflow: 'hidden'
        }}
      >
        <MassCheckbox
          state={checkboxesControllerState}
          sx={{
            pl: 2,
            minHeight: 56,
            borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
          }}
          onClick={() =>
            checkboxesControllerState === MassCheckboxState.Unselected
              ? setSelectedItemIds(selectableTicketItemsIds)
              : setSelectedItemIds([])
          }
        />
        <ListOfItemsSeparatedByDividers
          DividerProps={{
            sx: {my: 0, mx: 2}
          }}
        >
          {tourItems.map((item) => {
            const areDiscountsAllowed =
              idsOfTourItemsWithAllowedDiscounts.includes(item.id)
            const hasAppliedDiscount = item.appliedDiscounts.length > 0
            return (
              <Item
                key={item.id}
                checkboxProps={{
                  checked: selectedItemIds.includes(item.id),
                  disabled: hasAppliedDiscount,
                  sx: !areDiscountsAllowed ? {visibility: 'hidden'} : {}
                }}
                disabled={!areDiscountsAllowed}
                onClick={
                  areDiscountsAllowed && !hasAppliedDiscount
                    ? createItemCheckboxClickHandler(item.id)
                    : undefined
                }
                label={item.name}
                price={translateEffectiveClientPrice(item.price)}
                priceBeforeDiscount={
                  item.priceBeforeDiscount !== item.price
                    ? translateEffectiveClientPrice(item.priceBeforeDiscount)
                    : undefined
                }
                sx={{py: 1, px: 2}}
              >
                {!areDiscountsAllowed && (
                  <Typography variant="caption">
                    {t("Can't be discounted")}
                  </Typography>
                )}
                {hasAppliedDiscount &&
                  item.appliedDiscounts.map((appliedDiscount) => (
                    <Chip
                      sx={{
                        maxWidth: 184,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      key={appliedDiscount.discount.id}
                      onDelete={getChipDeleteButtonClickHandler(
                        item.id,
                        appliedDiscount.discount.id
                      )}
                      label={
                        <Tooltip title={appliedDiscount.discount.name}>
                          <span>{appliedDiscount.discount.name}</span>
                        </Tooltip>
                      }
                      deleteIcon={
                        <Tooltip title={t<string>('Remove discount')}>
                          <CancelIcon />
                        </Tooltip>
                      }
                    />
                  ))}
              </Item>
            )
          })}
        </ListOfItemsSeparatedByDividers>
        <Subtotal
          tourItems={tourItems}
          description={t('Discounted {{discountedCount}} of {{count}} ticket', {
            count: tourItemsWithAllowedDiscounts.length,
            discountedCount: tourItemsWithAllowedDiscounts.filter(
              (item) => item.appliedDiscounts.length
            ).length
          })}
        />
      </Box>
      {idsOfTourItemsWithAllowedDiscounts.length > 0 ? (
        <Box
          sx={{
            px: 3,
            pt: 2,
            pb: 11,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            overflow: 'auto'
          }}
        >
          {discountCodes.length > 0 && (
            <CheckDiscountCodeItem
              code={code}
              onChange={handleCodeInputChange}
              onCheckButtonClick={handleCheckDiscountCode}
              onCancel={handleCancelButtonClick}
              errorMessage={
                validCodes.map((c) => c.name).includes(code)
                  ? t('Code has been already verified.')
                  : null
              }
            />
          )}
          {validCodes.filter(Boolean).map((code) => {
            const {remainingDiscountUsageLimitPerOrder, canDiscountBeUsed} =
              getRemainingDiscountUsageLimitPerOrder({
                discount: code.discount,
                items: tourItems
              })
            return (
              <DiscountListItem
                key={code.id}
                disabled={code.state === DiscountCodeState.Invalid}
                discount={code.discount}
                description={[
                  formatDiscountValue(code.discount),
                  !isNil(code.discount.maxUsageLimitPerOrder) &&
                    t('Can be redeemed {{count}} times per order.', {
                      count: remainingDiscountUsageLimitPerOrder
                    }),
                  code.usageLimit
                    ? t('{{codeName}} can be redeemed {{count}} time.', {
                        codeName: code.name,
                        count: code.usageLimit - code.usageCount
                      })
                    : t('{{codeName}} can be redeemed infinity times.', {
                        codeName: code.name
                      }),
                  code.discount.internalDescription
                ]
                  .filter(Boolean)
                  .join(' • ')}
              >
                <Button
                  color="primary"
                  onClick={getAddButtonClickHandler(code.discount.id, code.id)}
                  disabled={
                    (!!code.usageLimit &&
                      code.usageLimit - code.usageCount === 0) ||
                    selectedItemIds.length === 0 ||
                    (!!remainingDiscountUsageLimitPerOrder &&
                      selectedItemIds.length >
                        remainingDiscountUsageLimitPerOrder) ||
                    !canDiscountBeUsed
                  }
                >
                  {t('Add')}
                </Button>
              </DiscountListItem>
            )
          })}
          {discounts
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(
              (discount) => discount.application !== DiscountApplication.Code
            )
            .map((discount) => {
              const {remainingDiscountUsageLimitPerOrder, canDiscountBeUsed} =
                getRemainingDiscountUsageLimitPerOrder({
                  discount,
                  items: tourItems
                })
              return (
                <DiscountListItem
                  key={discount.id}
                  discount={discount}
                  description={[
                    formatDiscountValue(discount),
                    [
                      !isNil(discount.maxUsageLimitPerOrder) &&
                        t('Can be redeemed {{count}} times per order.', {
                          count: remainingDiscountUsageLimitPerOrder
                        }),
                      discount.internalDescription
                    ]
                      .filter(Boolean)
                      .join(' ')
                  ]
                    .filter(Boolean)
                    .join(' • ')}
                >
                  <Button
                    color="primary"
                    disabled={
                      selectedItemIds.length === 0 ||
                      (!!remainingDiscountUsageLimitPerOrder &&
                        selectedItemIds.length >
                          remainingDiscountUsageLimitPerOrder) ||
                      !canDiscountBeUsed
                    }
                    onClick={getAddButtonClickHandler(discount.id)}
                  >
                    {t('Add')}
                  </Button>
                </DiscountListItem>
              )
            })}
        </Box>
      ) : (
        <Blank
          title={t('No items to be discounted')}
          description={t('It is forbidden to add discounts for this items.')}
        />
      )}
      {discountCodeError && (
        <DiscountCodeErrorDialog
          error={discountCodeError}
          onConfirm={handleConfirmDiscountCodeErrorButtonClick}
        />
      )}
    </Box>
  )
}
