import DeleteIcon from '@mui/icons-material/Delete'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  DiscountApplication,
  GetDiscountQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  ButtonWithConfirmationDialog,
  SingleSideNavigationList
} from '../../../../common'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {VerticalDivider} from '../../../../common/VerticalDivider'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {useDeleteDiscount} from '../graphql'
import {ApplicationRulesBlock} from './ApplicationRulesBlock'
import {CodesBlock} from './CodesBlock'
import {EditDiscountDrawer} from './EditDiscountDrawer'
import {GeneralDiscountBlock} from './GeneralDiscountBlock'
import {RestrictionsBlock} from './RestrictionsBlock'

const useDiscountAnchors = (hasCodes: boolean) => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    restrictions: {
      id: 'restrictions',
      label: t('Restrictions')
    },
    rules: {
      id: 'rules',
      label: t('Rules')
    },
    ...(hasCodes
      ? {
          codes: {
            id: 'codes',
            label: t('Codes')
          }
        }
      : {})
  }
}

interface IDiscountDetailProps {
  discount: GetDiscountQuery['discount']
}

export const DiscountDetail: React.FC<IDiscountDetailProps> = ({
  discount
}: IDiscountDetailProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const goBack = useCallback(() => {
    history.goBack()
  }, [history])
  const deleteDiscount = useDeleteDiscount()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const handleDeleteButtonClick = useCallback(async () => {
    setShowBackdrop(true)
    try {
      await deleteDiscount(discount.id)
      goBack()
      addInfoNotification(t('Discount deleted.'))
    } catch (error) {
      defaultErrorHandler(error, t('Error while deleting the discount'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteDiscount,
    discount.id,
    goBack,
    setShowBackdrop,
    t
  ])
  const hasCodes = discount.application === DiscountApplication.Code
  const discountAnchors = useDiscountAnchors(hasCodes)
  const {
    state: isEditDrawerOpened,
    setTrue: openEditDrawer,
    setFalse: closeEditDrawer
  } = useBooleanState(false)

  return (
    <PageWithHeaderAndFooterTemplate
      header={<SecondaryHeader title={t('Discount')} hasArrowBackIcon />}
      footer={
        <Footer>
          {P([PermissionCode.DeleteDiscount]) && (
            <ButtonWithConfirmationDialog
              onConfirmButtonClick={handleDeleteButtonClick}
              dialogProps={{
                confirmButtonLabel: t('Delete'),
                title: t('Delete discount {{name}}?', {name: discount.name}),
                contentText: (
                  <>
                    {t(
                      "Deleting this discount will not affect created orders or reservations, but new discount usage won't be allowed."
                    )}
                    <br />
                    <br />
                    {t(
                      "Are you sure you want to delete this discount? This can't be undone."
                    )}
                  </>
                )
              }}
              buttonProps={{
                children: t('Delete'),
                startIcon: <DeleteIcon />
              }}
            />
          )}
          <VerticalDivider />
          <CancelButton onClick={goBack} />
          {P([PermissionCode.UpdateDiscount]) && (
            <SaveButton onClick={openEditDrawer}>{t('Edit')}</SaveButton>
          )}
        </Footer>
      }
    >
      <SingleSideNavigationList items={discountAnchors} />
      <CenteredLayout>
        <CenteredLayoutListWrapper>
          <GeneralDiscountBlock
            label={discount.name}
            blockId={discountAnchors.general.id}
            discount={discount}
          />
          <RestrictionsBlock
            label={discountAnchors.restrictions.label}
            blockId={discountAnchors.restrictions.id}
            discount={discount}
          />

          <ApplicationRulesBlock
            label={discountAnchors.rules.label}
            blockId={discountAnchors.rules.id}
            applicationRules={discount.applicationRules}
            discountId={discount.id}
          />

          {hasCodes && P([PermissionCode.ReadDiscountCodes]) && (
            <CodesBlock
              discountId={discount.id}
              label={discountAnchors.codes!.label}
              blockId={discountAnchors.codes!.id}
            />
          )}
        </CenteredLayoutListWrapper>
      </CenteredLayout>
      <EditDiscountDrawer
        isOpen={isEditDrawerOpened}
        discount={discount}
        closeDrawer={closeEditDrawer}
      />
    </PageWithHeaderAndFooterTemplate>
  )
}
