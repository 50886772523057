import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Switch,
  Typography
} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  AvailableDiscountPropertiesFragment,
  DiscountType,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useTranslateDiscountApplication} from '../../../../../../hooks/discounts'
import {useFormatDiscountValue} from '../../../../../../hooks/formatDiscountValue'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {routeTo} from '../../../../../../utils/routes'
import {DataGridTable} from '../../../../../common/DataGridTable'

const ValueCellRenderer = ({
  value,
  type
}: {
  value: number
  type: DiscountType
}) => {
  const formatDiscountValue = useFormatDiscountValue()
  return <>{formatDiscountValue({value, type})}</>
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.discounts.detail(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

interface ITableBoxProps {
  discounts: AvailableDiscountPropertiesFragment[]
  title: string
  labelId: string
  className?: string
  isToggleOn: boolean
  onToggleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const TableBox: React.FC<ITableBoxProps> = ({
  discounts,
  title,
  labelId,
  className,
  isToggleOn,
  onToggleChange
}: ITableBoxProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateDiscountApplication = useTranslateDiscountApplication()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 200
      },
      {
        headerName: t('Value'),
        field: 'value',
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <ValueCellRenderer value={params.value} type={params.row.type} />
          )
        },
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('Application'),
        field: 'application',
        valueFormatter: (params) => translateDiscountApplication(params.value),
        minWidth: 200
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t, translateDiscountApplication]
  )
  return (
    <Paper variant="outlined" className={className}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          p: 2
        }}
      >
        <Typography id={labelId} variant="subtitle1">
          {title}
        </Typography>
        {P([PermissionCode.UpdateAllowedDiscountsSellingChannelsForEvent]) && (
          <Switch
            checked={isToggleOn}
            onChange={onToggleChange}
            color="primary"
            name={labelId}
          />
        )}
      </Box>
      <Collapse in={isToggleOn}>
        <DataGridTable
          sx={{border: 'none', borderRadius: 0}}
          columns={columns}
          rows={discounts}
          localeText={{noRowsLabel: t('No discounts to show')}}
          pagination={false}
          hideFooter
          autoHeight
          disableRowSelectionOnClick
          initialState={{pinnedColumns: {left: ['name'], right: ['id']}}}
          columnVisibilityModel={{id: P([PermissionCode.ReadDiscount])}}
        />
      </Collapse>
    </Paper>
  )
}
