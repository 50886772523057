import {Box, Paper, Typography} from '@mui/material'
import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CartPropertiesFragment,
  CashDeskTourTimeSlotQuery
} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {ListOfItemsSeparatedByDividers} from '../../../common/ListOfItemsSeparatedByDividers'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {BasicTicketListItem} from '../components/BasicTicketListItem'
import {TicketsSubtotal} from '../components/TicketsSubtotal'
import {TourTimeSlotQuantityDescription} from '../components/TourTimeSlotQuantityDescription'
import {CenteredLayout} from '../Layout'
import {isTourItemPropertiesFragment} from '../types'
import {Header} from './Header'

interface ITourTimeSlotViewProps {
  tourTimeSlot: CashDeskTourTimeSlotQuery['tourTimeSlot']
  cart: CartPropertiesFragment | null
}

export const TourTimeSlotView: React.FC<ITourTimeSlotViewProps> = ({
  tourTimeSlot,
  cart
}: ITourTimeSlotViewProps) => {
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const startsAt = new Date(tourTimeSlot.startsAt)
  const {t} = useTranslation()
  const currentCartTourItems = (cart?.items || [])
    .filter(isTourItemPropertiesFragment)
    .filter((item) => item.tourTimeSlotId === tourTimeSlot.id)
    .filter((item) => item.reservation === null)
    .sort((itemA, itemB) => itemA.id - itemB.id)
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const lastElementRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [currentCartTourItems])
  return (
    <PageWithHeaderTemplate
      header={
        <Header
          title={[
            formatDateNumeric(startsAt),
            formatTime(startsAt),
            tourTimeSlot.tour.name
          ].join(' • ')}
        />
      }
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `
            "subCart viewBox"
          `,
          gridTemplateColumns: '360px 1fr',
          height: '100%'
        }}
      >
        <Box
          sx={{
            gridArea: 'subCart',
            backgroundColor: `background.paper`,
            borderRight: (theme) => `solid ${theme.palette.divider} 1px`,
            display: 'grid',
            gridTemplateRows: '1fr auto',
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <ListOfItemsSeparatedByDividers sx={{px: 2}}>
            {currentCartTourItems.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  display: 'grid',
                  minHeight: 56,
                  gridTemplateColumns: 'auto 1fr auto',
                  alignItems: 'center'
                }}
                ref={
                  index === currentCartTourItems.length - 1
                    ? lastElementRef
                    : null
                }
              >
                <BasicTicketListItem
                  key={item.id}
                  primaryLabel={item.admissionType.name}
                  priceLabel={translateEffectiveClientPrice(item.price)}
                  priceBeforeDiscountLabel={
                    item.price !== item.priceBeforeDiscount
                      ? translateEffectiveClientPrice(item.priceBeforeDiscount)
                      : undefined
                  }
                  secondaryLabel={item.appliedDiscounts
                    .map((appliedDiscount) => appliedDiscount.discount.name)
                    .join(' • ')}
                />
              </Box>
            ))}
          </ListOfItemsSeparatedByDividers>
          <TicketsSubtotal
            items={currentCartTourItems}
            description={t('{{count}} ticket', {
              count: currentCartTourItems.length
            })}
          />
        </Box>
        <Box sx={{gridArea: 'viewBox'}}>
          <CenteredLayout>
            <Box
              sx={{
                pt: 3,
                pb: 11,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {tourTimeSlot.admissionRate.admissionTypesAssignments.map(
                (assignment) => (
                  <Paper
                    key={assignment.id}
                    variant="outlined"
                    square
                    sx={{
                      minWidth: 328,
                      minHeight: 48,
                      display: 'grid',
                      gridAutoFlow: 'column',
                      gridTemplateColumns: '1fr auto auto',
                      gridTemplateAreas: `"name description priceLabel"`,
                      gap: 1,
                      px: 2,
                      alignItems: 'center',
                      '&:first-child': {
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4
                      },
                      '&:last-child': {
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4
                      }
                    }}
                  >
                    <Typography variant="subtitle1" sx={{gridArea: 'name'}}>
                      {assignment.admissionType.name}
                    </Typography>
                    {(assignment.admissionType.capacityDecreaseCount !== 1 ||
                      assignment.admissionType.startingQuantity >= 2) && (
                      <TourTimeSlotQuantityDescription
                        startingQuantity={
                          assignment.admissionType.startingQuantity
                        }
                        capacityDecreaseCount={
                          assignment.admissionType.capacityDecreaseCount
                        }
                      />
                    )}
                    <Typography sx={{gridArea: 'priceLabel'}}>
                      {translateEffectiveClientPrice(assignment.retailPrice)}
                    </Typography>
                  </Paper>
                )
              )}
            </Box>
          </CenteredLayout>
        </Box>
      </Box>
    </PageWithHeaderTemplate>
  )
}
