import StorefrontIcon from '@mui/icons-material/StorefrontOutlined'
import {makeStyles} from '@mui/styles'
import {isNumber} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  GetAvailableProductsQuery,
  ProductMode,
  ProductPropertiesFragment,
  ProductsFilterInput
} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData} from '../../../../common'
import {LoadingMoreProgress} from '../../../../common/LoadingMoreProgress'
import {Blank} from '../../../../visual/Blank'
import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {isProductItemPropertiesFragment} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {useGetAvailableProducts} from './graphql'
import {ListItemWithQuantityController} from './ListItemWithQuantityController'

const useStyles = makeStyles<Theme, {currentCartItems: number}>((theme) => ({
  list: {
    '&:last-child': {
      paddingBottom: ({currentCartItems}) =>
        currentCartItems > 0 ? theme.spacing(11) : theme.spacing(2)
    }
  },
  loadingMoreProgress: {
    paddingTop: theme.spacing(2)
  }
}))

interface IListViewProps {
  onProductItemQuantityIncrement: (productId: number, increment: number) => void
  onProductItemQuantityDecrement: (
    productItemId: number,
    decrement: number
  ) => void
  getProductUnitPrice: (
    product: ProductPropertiesFragment
  ) => number | undefined
  divisionId: number
  searchFilter: ProductsFilterInput
  warehouseId: number | null
}

export const ListView: React.FC<IListViewProps> = ({
  onProductItemQuantityIncrement,
  onProductItemQuantityDecrement,
  getProductUnitPrice,
  divisionId,
  searchFilter,
  warehouseId
}: IListViewProps) => {
  const {t} = useTranslation()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const {data, loading, error, fetchMore, isLoadingMore} =
    useGetAvailableProducts({
      divisionId,
      filter: searchFilter,
      warehouseIds: warehouseId ? [warehouseId] : undefined
    })
  const {currentCart} = useCurrentCart()
  const currentCartProductItems = (currentCart?.items || []).filter(
    isProductItemPropertiesFragment
  )
  const getIncrementButtonClickHandler = useCallback(
    (productId: number) => () => {
      onProductItemQuantityIncrement(productId, 1)
    },
    [onProductItemQuantityIncrement]
  )
  const getDecrementButtonClickHandler = useCallback(
    (productItemId: number) => () => {
      onProductItemQuantityDecrement(productItemId, 1)
    },
    [onProductItemQuantityDecrement]
  )
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!loading && data?.products.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.products.pagination.hasMore, fetchMore, loading])
  const history = useHistory()
  const classes = useStyles({currentCartItems: currentCart?.items?.length || 0})
  return (
    <RenderOnData<GetAvailableProductsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Failed to load available products')}
    >
      {({products}) =>
        products.items.length ? (
          <CenteredLayout
            onScrolledNearTheEndOfTheLayout={
              handleScrolledNearTheEndOfTheLayout
            }
          >
            <CenteredLayoutListWrapper className={classes.list}>
              {products.items.map((product) => {
                const unitPrice = getProductUnitPrice(product)
                const productItem = currentCartProductItems.find(
                  (i) => i.productId === product.id
                )
                const isProductOutOfStock =
                  product.mode === ProductMode.WarehouseProduct &&
                  product.warehouseProducts.length > 0 &&
                  !product.warehouseProducts[0].isNegativeStockEnabled &&
                  product.warehouseProducts[0].stock <= 0
                const isIncrementDisabled =
                  product.mode === ProductMode.WarehouseProduct &&
                  product.warehouseProducts.length > 0 &&
                  !product.warehouseProducts[0].isNegativeStockEnabled &&
                  productItem &&
                  productItem.quantity >= product.warehouseProducts[0].stock
                const isProductDisabled =
                  product.mode === ProductMode.WarehouseProduct &&
                  (!warehouseId ||
                    product.warehouseProducts.length === 0 ||
                    isProductOutOfStock)
                return (
                  <ListItemWithQuantityController
                    disabled={isProductDisabled}
                    key={product.id}
                    primaryLabel={product.name}
                    secondaryLabel={
                      isProductOutOfStock || isIncrementDisabled
                        ? t('Out of stock')
                        : isNumber(unitPrice)
                        ? translateEffectiveClientPrice(unitPrice)
                        : t('Undefined price')
                    }
                    quantityControllerProps={{
                      onClick: isNumber(unitPrice)
                        ? () => {
                            history.replace(
                              routeTo.admin.cashDesk.shopWithOpenedQuantityDialog(
                                product.id
                              )
                            )
                          }
                        : undefined,
                      quantity: productItem?.quantity ?? 0,
                      IncrementButtonProps: {
                        onClick: isNumber(unitPrice)
                          ? getIncrementButtonClickHandler(product.id)
                          : undefined,
                        disabled: isIncrementDisabled
                      },
                      DecrementButtonProps: {
                        onClick: productItem
                          ? getDecrementButtonClickHandler(productItem.id)
                          : undefined
                      }
                    }}
                  />
                )
              })}
              {isLoadingMore && (
                <LoadingMoreProgress className={classes.loadingMoreProgress} />
              )}
            </CenteredLayoutListWrapper>
          </CenteredLayout>
        ) : (
          <Blank title={t('No products found')} IconComp={StorefrontIcon} />
        )
      }
    </RenderOnData>
  )
}
