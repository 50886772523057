import {
  Icon as CanvasIcon,
  IconShape,
  ICoords,
  IDimensions
} from '@attendio/shared-components'
import Konva from 'konva'
import _ from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'

import {ROTATION} from '../config'
import {useSelector} from '../redux'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {useIconActions} from '../redux/objects/icons/actions'
import {AuditoriumIcon, DrawTool} from '../types'
import {getMouseCoordsOnCanvas} from '../utils/common'
import {EventLayer} from './EventLayer'

const defaultDimensions = {width: 0, height: 0}

const TemporaryIconFn: React.FC = () => {
  const [startCoords, setStartCoords] = useState<ICoords | null>(null)
  const [endCoords, setEndCoords] = useState<ICoords | null>(null)
  const {modeConfigs} = useSelector(editorModeSelector)
  const {addIcon} = useIconActions()

  const icon = useMemo(() => {
    const drawConfig = modeConfigs[EditorMode.DRAW]
    return drawConfig.type === DrawTool.ICON
      ? drawConfig.icon
      : AuditoriumIcon.Toilets
  }, [modeConfigs])

  const dimensions: IDimensions = useMemo(() => {
    if (!startCoords || !endCoords) {
      return defaultDimensions
    }

    const newDimensions = {
      width: endCoords.x - startCoords.x,
      height: endCoords.y - startCoords.y
    }

    if (_.isEqual(newDimensions, defaultDimensions)) {
      return defaultDimensions
    }

    return newDimensions
  }, [endCoords, startCoords])

  const onMouseDown = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const newCoords = getMouseCoordsOnCanvas(e)
    setStartCoords(newCoords)
    setEndCoords(newCoords)
  }, [])

  const onMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (!startCoords) {
        return
      }
      setEndCoords(getMouseCoordsOnCanvas(e))
    },
    [startCoords]
  )

  const onMouseUp = useCallback(() => {
    if (startCoords && endCoords) {
      addIcon({
        iconShape: IconShape.Ellipse,
        icon,
        coords: startCoords,
        dimensions,
        rotation: ROTATION
      })
    }

    setStartCoords(null)
    setEndCoords(null)
  }, [addIcon, dimensions, endCoords, icon, startCoords])

  return (
    <EventLayer {...{onMouseUp, onMouseMove, onMouseDown}}>
      {startCoords && endCoords && (
        <CanvasIcon
          id=""
          coords={startCoords}
          dimensions={dimensions}
          iconShape={IconShape.Ellipse}
          icon={icon}
          rotation={ROTATION}
        />
      )}
    </EventLayer>
  )
}

export const TemporaryIcon = React.memo(TemporaryIconFn)
