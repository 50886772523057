import {Box, Checkbox, CheckboxProps, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    minHeight: 56,
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center'
  },
  labels: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  priceColumn: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  mainColumn: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  listItem: {
    padding: theme.spacing(0, 2)
  },
  priceBeforeDiscountLabel: {
    textDecoration: 'line-through'
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  clickable: {
    cursor: 'pointer'
  }
}))

interface IItemProps {
  primaryLabel: string
  priceLabel: string
  priceBeforeDiscountLabel: string | undefined
  checkboxProps?: CheckboxProps
  children?: React.ReactNode
  isDisabled?: boolean
  /**
   * @deprecated use sx prop
   */
  className?: string
  sx?: SxProps<Theme>
  onClick?: React.MouseEventHandler
}

export const Item: React.FC<IItemProps> = ({
  primaryLabel,
  checkboxProps,
  priceLabel,
  priceBeforeDiscountLabel,
  children,
  className,
  onClick,
  isDisabled,
  sx
}: IItemProps) => {
  const classes = useStyles()
  return (
    <Box
      className={cn(classes.root, className, {
        [classes.disabled]: isDisabled,
        [classes.clickable]: Boolean(onClick)
      })}
      sx={sx}
      onClick={onClick}
    >
      <Checkbox
        color="primary"
        edge="start"
        disabled={isDisabled}
        onClick={onClick}
        {...checkboxProps}
      />
      <div className={classes.mainColumn}>
        <Typography variant="subtitle2" color="inherit">
          {primaryLabel}
        </Typography>
        <div>{children}</div>
      </div>
      <div className={classes.priceColumn}>
        <Typography variant="body2" color="inherit" align="right">
          {priceLabel}
        </Typography>
        {priceBeforeDiscountLabel && (
          <Typography
            variant="caption"
            color="textSecondary"
            align="right"
            className={classes.priceBeforeDiscountLabel}
          >
            {priceBeforeDiscountLabel}
          </Typography>
        )}
      </div>
    </Box>
  )
}
