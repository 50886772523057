import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, IconButton, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CashDeskTourTimeSlotQuery,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../../../hooks/formatUserName'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../../hooks/translateAgeClassification'
import {useTranslateShowVersionAbbreviation} from '../../../../../../hooks/translateDistributions'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {routeTo} from '../../../../../../utils/routes'
import {Tooltip} from '../../../../../common'
import {TimeSlotCapacityIndicators} from '../TimeSlotCapacityIndicators'

interface ISubHeaderProps {
  tourTimeSlot: CashDeskTourTimeSlotQuery['tourTimeSlot']
}

export const SubHeader: React.FC<ISubHeaderProps> = ({
  tourTimeSlot
}: ISubHeaderProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const formatShortGuideName = useFormatShortGuideName()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  return (
    <Box
      sx={{
        height: 48,
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3,
        py: 1,
        backgroundColor: 'common.white',
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
      }}
    >
      <Box>
        <Typography variant="body2">
          {[
            tourTimeSlot.versionCode &&
              translateShowVersionAbbreviation(tourTimeSlot.versionCode),
            tourTimeSlot.guide && formatShortGuideName(tourTimeSlot.guide),
            tourTimeSlot.ageClassificationCode &&
              translateAgeClassificationAbbreviation(
                tourTimeSlot.ageClassificationCode
              )
          ]
            .filter(Boolean)
            .join(' • ')}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TimeSlotCapacityIndicators
          retailAttendeesLimit={tourTimeSlot.retailAttendeesLimit}
          stateCounts={tourTimeSlot.tourItemsStateCounts}
          sx={{gap: 1}}
          hideSoldOut
        />
        {tourTimeSlot.tourItemsStateCounts.reserved > 0 &&
          P([PermissionCode.ReadReservations]) && (
            <Tooltip title={t<string>('Show time slot reservations')}>
              <IconButton
                onClick={() =>
                  history.push(
                    routeTo.admin.cashDesk.tourTimeSlotReservations(
                      tourTimeSlot.id
                    )
                  )
                }
              >
                <BookmarkBorderIcon />
              </IconButton>
            </Tooltip>
          )}
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
