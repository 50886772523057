import {ApolloClient} from 'apollo-client'
import {ApolloLink, split} from 'apollo-link'
import {
  InMemoryCache,
  defaultDataIdFromObject,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import {createUploadLink} from 'apollo-upload-client'
import {WebSocketLink} from 'apollo-link-ws'
import {onError} from 'apollo-link-error'
import {getMainDefinition} from 'apollo-utilities'
import {getSentry} from './sentry'

import introspectionQueryResultData from '../__generated__/fragmentTypes.json'

import {config} from '../config'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({fragmentMatcher})

// https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const {file, ...rest} = operation.variables
    const restWithoutTypename = JSON.parse(JSON.stringify(rest), (key, value) =>
      key === '__typename' ? undefined : value
    )
    operation.variables = {...restWithoutTypename, file}
  }

  return forward(operation)
})

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  const Sentry = getSentry()
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      const {message, locations, path} = graphQLError
      Sentry.withScope((scope) => {
        scope.setExtras({
          locations,
          path,
          operationName: operation.operationName,
          variables: operation.variables
        })
        Sentry.captureException(new Error(message))
      })
    })
  }

  if (networkError) {
    Sentry.captureException(networkError)
  }
})

const uploadAndHttpLink = createUploadLink({
  uri: config.graphqlServerUrl,
  headers: {
    'keep-alive': 'true'
  },
  // Note: in dev mode we run dev-server for frontend which have different port (origin)
  credentials: config.isProduction ? 'same-origin' : 'include'
})

let wsReconnect = false

export const setWebSocketReconnect = (reconnect: boolean) => {
  wsReconnect = reconnect
}

const wsLink = new WebSocketLink({
  uri: `${config.isProduction ? 'wss://' : 'ws://'}${window.location.host}${
    config.graphqlServerSubscriptionsPath
  }`,
  options: {
    reconnect: wsReconnect
  }
})

const splitLink = split(
  // split based on operation type
  ({query}) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  errorLink.concat(uploadAndHttpLink)
)

const link = ApolloLink.from([omitTypenameLink, splitLink])

export const apolloClient = new ApolloClient({
  cache,
  link,
  connectToDevTools: !config.isProduction
})

export const removeObjectFromCache = (cache: any, object: {}) => {
  // TODO: could not find any way how to remove key from apollo client cache.
  // This works, however it seems fragile, as it is not in official docs.
  // Pay extra attention when updating apollo later on.
  cache.data.delete(defaultDataIdFromObject(object))
}
