import {useLazyQuery} from '@apollo/react-hooks'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ClientVatRegistered,
  CountryVatRatesQuery,
  CountryVatRatesQueryVariables,
  DetailClientPropertiesFragment,
  ExistingCountryCode
} from '../../../../__generated__/schema'
import {useGetClientCountrySelectData} from '../../../../hooks/clientCountryCodes'

import {useClientFormAnchors} from '../../../../hooks/clientFormAnchors'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {
  useIsStringWithMaxLength,
  useIsValidEmailList,
  useIsValidURL
} from '../../../../utils/formsValidations'
import {InputBlock, InputRow} from '../../../common'
import {UncontrolledFormAddressFields} from '../../../common/UncontrolledFormAddressFields'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {FormMultiSelect} from '../../../visual'
import {ClientFormField} from '../clients/types'
import {COUNTRY_VAT_RATE} from '../graphql'
import {DummySelect} from './DummySelect'
import {
  CompanyFormField,
  getDefaultValuesForCompanyForm,
  ICompanyForm
} from './types'

interface ICompanyFormProps {
  formId: string
  company: DetailClientPropertiesFragment
  onSubmit: (companyForm: ICompanyForm) => void
}

export const CompanyForm: React.FC<ICompanyFormProps> = ({
  formId,
  company,
  onSubmit
}: ICompanyFormProps) => {
  const {t} = useTranslation()
  const clientFormAnchors = useClientFormAnchors()
  const defaultValues = getDefaultValuesForCompanyForm(company)
  const {
    register,
    setValue,
    errors,
    watch,
    unregister,
    triggerValidation,
    handleSubmit
  } = useForm<ICompanyForm>({
    defaultValues
  })
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const countrySelectData = useGetClientCountrySelectData()
  const isValidEmailList = useIsValidEmailList()
  const isValidURL = useIsValidURL()
  const isValidHttpsUrl = useIsValidURL(true)
  const selectedCountryCode = watch(CompanyFormField.COUNTRY_CODE)
  const isVatRegistered = company.VATRegistered !== ClientVatRegistered.None
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const [countryVatRates, setCountryVatRates] = useState<number[]>([])
  const [getCountryVatRates, {loading: isCountryVatRatesLoading}] =
    useLazyQuery<CountryVatRatesQuery, CountryVatRatesQueryVariables>(
      COUNTRY_VAT_RATE,
      {
        onCompleted: (data) => {
          setCountryVatRates(data.countryVatRates)
          if (defaultValues?.ticketDefaultVatRate) {
            if (
              !data.countryVatRates.includes(defaultValues.ticketDefaultVatRate)
            ) {
              setValue(CompanyFormField.TICKET_DEFAULT_VAT_RATE, 0)
            }
          }
        },
        onError: (error) =>
          defaultErrorHandler(error, t('Error while loading country VAT rates'))
      }
    )
  useEffect(
    () =>
      isCountryVatRatesLoading ? setShowBackdrop(true) : setShowBackdrop(false),
    [isCountryVatRatesLoading, setShowBackdrop]
  )
  useEffect(() => {
    if (selectedCountryCode) {
      getCountryVatRates({
        variables: {countryCode: selectedCountryCode as ExistingCountryCode}
      })
    }
  }, [getCountryVatRates, selectedCountryCode])
  return (
    <form
      noValidate
      autoComplete="off"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputBlock
        header={clientFormAnchors.general.label}
        id={clientFormAnchors.general.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              label={t('Name')}
              key={CompanyFormField.NAME}
              name={CompanyFormField.NAME}
              register={register}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormSelect<ICompanyForm>
              label={t('Country')}
              key={CompanyFormField.COUNTRY_CODE}
              name={CompanyFormField.COUNTRY_CODE}
              selectOptions={countrySelectData}
              setValue={setValue}
              errors={errors}
              register={register}
              watch={watch}
              fullWidth
            />,
            <DummySelect
              key="currency"
              label={t('Currency')}
              defaultValue={company.currency}
              items={{[company.currency]: company.currency}}
              isDisabled
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormMultiSelect
              name="localeCodes"
              register={register}
              selectedKeys={company.localeCodes}
              unregister={unregister}
              errors={errors}
              setValue={setValue}
              key="locales"
              label={t('Locales')}
              selectOptions={company.localeCodes.reduce(
                (acc, localeCode) => ({
                  ...acc,
                  [localeCode]: localeCode
                }),
                {}
              )}
              disabled
            />
          ]}
        />
      </InputBlock>
      <InputBlock
        header={clientFormAnchors.legal.label}
        id={clientFormAnchors.legal.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              label={t('Company ID number')}
              key={CompanyFormField.COMPANY_ID_NUMBER}
              name={CompanyFormField.COMPANY_ID_NUMBER}
              register={register}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              required
              fullWidth
            />,
            <DummySelect
              key="VATRegistered"
              label={t('VAT Registered')}
              defaultValue={company.VATRegistered}
              items={{
                [ClientVatRegistered.Full]: t('Full'),
                [ClientVatRegistered.None]: t('Non VAT payer'),
                [ClientVatRegistered.Part]: t('Part')
              }}
              isDisabled
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              label={t('TAX ID')}
              key={CompanyFormField.TAX_ID}
              name={CompanyFormField.TAX_ID}
              register={register}
              validationOptions={{
                validate: isStringWithMaxLength
              }}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />
          ]}
          xs={6}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              label={t('VAT ID')}
              key={CompanyFormField.VAT_ID}
              name={CompanyFormField.VAT_ID}
              register={register}
              validationOptions={{
                validate: isStringWithMaxLength
              }}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />,
            ...(isVatRegistered && countryVatRates.length > 0
              ? [
                  <UncontrolledFormSelect<ICompanyForm>
                    label={t('Default VAT rate for tickets')}
                    placeholder={t('Default VAT rate for tickets')}
                    key={CompanyFormField.TICKET_DEFAULT_VAT_RATE}
                    name={CompanyFormField.TICKET_DEFAULT_VAT_RATE}
                    selectOptions={countryVatRates.reduce(
                      (acc, vatRate) => ({
                        ...acc,
                        [String(vatRate)]: t('{{vatRate}} %', {vatRate})
                      }),
                      {}
                    )}
                    setValue={setValue}
                    errors={errors}
                    register={register}
                    watch={watch}
                    fullWidth
                  />
                ]
              : [])
          ].filter(Boolean)}
          xs={isVatRegistered ? undefined : 6}
        />
        <UncontrolledFormAddressFields<ICompanyForm>
          register={register}
          namePrefix={CompanyFormField.LEGAL_ADDRESS}
          errors={errors}
          setValue={setValue}
          triggerValidation={triggerValidation}
          watch={watch}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              key={CompanyFormField.TERMS_OF_SERVICE_URL}
              label={t('Terms of service')}
              placeholder={t('Type link here')}
              helperNote={t(
                'Recommended. Customer will be able to visit this link.'
              )}
              type="text"
              name={CompanyFormField.TERMS_OF_SERVICE_URL}
              validationOptions={{validate: isValidURL}}
              register={register}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              key={CompanyFormField.MARKETING_INFORMATION_URL}
              label={t('Marketing and GDPR information for customers')}
              placeholder={t('Type link here')}
              helperNote={t(
                'Recommended. Customer will be able to visit this link.'
              )}
              type="text"
              name={CompanyFormField.MARKETING_INFORMATION_URL}
              validationOptions={{validate: isValidURL}}
              register={register}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              key={ClientFormField.E_COMMERCE_URL}
              label={t('Ecommerce URL')}
              placeholder={t('Type link here')}
              type="text"
              name={ClientFormField.E_COMMERCE_URL}
              validationOptions={{validate: isValidHttpsUrl}}
              register={register}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
              disabled
            />
          ]}
        />
      </InputBlock>
      <InputBlock
        header={clientFormAnchors.contact.label}
        id={clientFormAnchors.contact.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              key={CompanyFormField.MAILING_ADDRESS}
              label={t('Mailing address')}
              name={CompanyFormField.MAILING_ADDRESS}
              register={register}
              validationOptions={{validate: isValidEmailList}}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
              helperNote={t('Please use "," or ";" to separate emails')}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ICompanyForm>
              key={CompanyFormField.INVOICE_ADDRESS}
              label={t('Invoice address')}
              name={CompanyFormField.INVOICE_ADDRESS}
              register={register}
              validationOptions={{validate: isValidEmailList}}
              errors={errors}
              setValue={setValue}
              triggerValidation={triggerValidation}
              watch={watch}
              fullWidth
              helperNote={t('Please use "," or ";" to separate emails')}
            />
          ]}
        />
      </InputBlock>
    </form>
  )
}
