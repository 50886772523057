import {useMutation} from '@apollo/react-hooks'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button, ButtonGroup, IconButton} from '@mui/material'
import {ApolloQueryResult} from 'apollo-client'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DeleteTourTimeSlotsMutation,
  DeleteTourTimeSlotsMutationVariables,
  ShowVersionCode,
  TourTimeSlotsQuery,
  TourTimeSlotState,
  UpdateTourTimeSlotsMutation,
  UpdateTourTimeSlotsMutationVariables
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../../hooks/state'
import {routeTo} from '../../../../../../utils/routes'
import {Tooltip} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {ConfirmationDialog} from '../../../../../common/ConfirmationDialog'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../../../common/datePicker/types'
import {DatePickerButton} from '../../../../../common/DatePickerButton'
import {Menu, MenuItem, useMenu} from '../../../../../common/Menu'
import {SubHeaderToolbar} from '../../../../../common/SubHeaderToolbar'
import {VerticalDivider} from '../../../../../common/VerticalDivider'
import {AdmissionRatesChip} from '../AdmissionRatesChip'
import {
  DELETE_TOUR_TIME_SLOTS_MUTATION,
  UPDATE_TOUR_TIME_SLOTS_MUTATION
} from './graphql'
import {StatesChip} from './StatesChip'
import {VersionsChip} from './VersionsChip'

interface IToolbarProps {
  tourId: number
  onTimeSlotStateChange: (state: TourTimeSlotState | null) => void
  onAdmissionRateChange: (rateId: number | null) => void
  onVersionCodeChange: (version: ShowVersionCode | null) => void
  selectedItemIds: number[]
  selectedDate?: IDataPickerData
  onDateSelect: (date?: IDataPickerData) => void
  hasUpdateTourTimeSlotsPermission: boolean
  hasDeleteTourTimeSlotsPermission: boolean
  refetchTourTimeSlots: () => Promise<ApolloQueryResult<TourTimeSlotsQuery>>
}

export const Toolbar: React.FC<IToolbarProps> = ({
  tourId,
  onTimeSlotStateChange,
  onAdmissionRateChange,
  onVersionCodeChange,
  selectedItemIds,
  selectedDate,
  onDateSelect,
  hasUpdateTourTimeSlotsPermission,
  hasDeleteTourTimeSlotsPermission,
  refetchTourTimeSlots
}: IToolbarProps) => {
  const {t} = useTranslation()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const history = useHistory()

  const {
    setFalse: closeCancelDialog,
    setTrue: openCancelDialog,
    state: isCancelDialogOpened
  } = useBooleanState(false)
  const {
    setFalse: closePublishDialog,
    setTrue: openPublishDialog,
    state: isPublishDialogOpened
  } = useBooleanState(false)
  const {
    setFalse: closeDeleteDialog,
    setTrue: openDeleteDialog,
    state: isDeleteDialogOpened
  } = useBooleanState(false)

  const [updateTourTimeSlots] = useMutation<
    UpdateTourTimeSlotsMutation,
    UpdateTourTimeSlotsMutationVariables
  >(UPDATE_TOUR_TIME_SLOTS_MUTATION)

  const [deleteTourTimeSlots] = useMutation<
    DeleteTourTimeSlotsMutation,
    DeleteTourTimeSlotsMutationVariables
  >(DELETE_TOUR_TIME_SLOTS_MUTATION)

  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()

  const handleDeleteConfirmation = useCallback(async () => {
    try {
      setShowBackdrop(true)
      const {data} = await deleteTourTimeSlots({
        variables: {
          ids: selectedItemIds
        }
      })
      if (data) {
        addInfoNotification(
          t('{{updatedCount}} of {{count}} slots have been deleted.', {
            updatedCount: data.deleteTourTimeSlots.length,
            count: selectedItemIds.length
          })
        )
        closeDeleteDialog()
      }
    } catch (e) {
      defaultErrorHandler(e, t('Deletion of tour time slots failed'))
    }
    try {
      await refetchTourTimeSlots()
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    closeDeleteDialog,
    defaultErrorHandler,
    deleteTourTimeSlots,
    refetchTourTimeSlots,
    selectedItemIds,
    setShowBackdrop,
    t
  ])
  const getConfirmHandler = useCallback(
    (desiredState: TourTimeSlotState) => async () => {
      setShowBackdrop(true)
      try {
        const {data} = await updateTourTimeSlots({
          variables: {
            ids: selectedItemIds,
            input: {
              state: desiredState
            }
          }
        })
        if (data) {
          if (desiredState === TourTimeSlotState.Cancelled) {
            closeCancelDialog()
          } else {
            closePublishDialog()
          }
          addInfoNotification(
            t('{{updatedCount}} of {{count}} slots have been updated.', {
              updatedCount: data.updateTourTimeSlots.length,
              count: selectedItemIds.length
            })
          )
        }
      } catch (e) {
        defaultErrorHandler(e, t('Tour time slots update failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeCancelDialog,
      closePublishDialog,
      defaultErrorHandler,
      selectedItemIds,
      setShowBackdrop,
      t,
      updateTourTimeSlots
    ]
  )
  const handleEditButtonClick = useCallback(() => {
    history.push({
      pathname: routeTo.admin.tours.editTimeSlots(tourId),
      search: `?itemIds=${selectedItemIds.join()}`
    })
  }, [history, selectedItemIds, tourId])
  return (
    <>
      <SubHeaderToolbar
        title={t('Time slots')}
        leftActions={[
          <StatesChip key="state-chip" onChange={onTimeSlotStateChange} />,
          <AdmissionRatesChip
            key="admission-rate-chip"
            onChange={onAdmissionRateChange}
            tourId={tourId}
          />,
          <VersionsChip key="version-chip" onChange={onVersionCodeChange} />,
          ...(selectedItemIds.length && hasDeleteTourTimeSlotsPermission
            ? [
                <VerticalDivider key="vertical-divider-before-delete-button" />,
                <Tooltip
                  title={t('Delete draft tour time slots')}
                  key="delete-button-with-tooltip"
                >
                  <IconButton
                    edge="start"
                    color="primary"
                    onClick={openDeleteDialog}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ]
            : []),
          ...(selectedItemIds.length && hasUpdateTourTimeSlotsPermission
            ? [
                <Tooltip key="edit-button-tooltip" title={t('Edit')}>
                  <IconButton
                    color="primary"
                    edge="start"
                    onClick={handleEditButtonClick}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ]
            : []),
          ...(selectedItemIds.length && hasUpdateTourTimeSlotsPermission
            ? [
                <ButtonGroup
                  key="split-button"
                  variant="contained"
                  color="primary"
                >
                  <SaveButton onClick={openPublishDialog}>
                    {t('Publish')}
                  </SaveButton>
                  <Button color="primary" size="small" onClick={openMenu}>
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
              ]
            : [])
        ]}
        rightActions={[
          <DatePickerButton
            key="event-date-picker"
            onDateSelect={onDateSelect}
            selectedValues={selectedDate}
            defaultValues={{
              group: TabGroup.Now,
              value: TabNow.FromToday
            }}
            tooltip={t('Time slot date')}
          />
        ]}
      />
      <Menu
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          label={t('Cancel selected tour time slots')}
          onClick={() => {
            openCancelDialog()
            closeMenu()
          }}
        />
      </Menu>
      <ConfirmationDialog
        title={t('Set selected tour time slots as cancelled?')}
        contentText={t(
          'You are about to cancel multiple tour time slots. These time slots will no longer be available for customers on the ecommerce or for cashiers at the cash desk. Please confirm that this is what you intend to do before proceeding.'
        )}
        onConfirm={getConfirmHandler(TourTimeSlotState.Cancelled)}
        onCancel={closeCancelDialog}
        isOpen={isCancelDialogOpened}
      />
      <ConfirmationDialog
        title={t('Publish selected tour time slots?')}
        contentText={t(
          'You are about to publish multiple tour time slots. After performing this action, you will not be able to delete these tour time slots later. Please confirm that this is what you intend to do before proceeding.'
        )}
        confirmButtonLabel={t('Publish')}
        onConfirm={getConfirmHandler(TourTimeSlotState.Published)}
        onCancel={closePublishDialog}
        isOpen={isPublishDialogOpened}
      />
      <ConfirmationDialog
        title={t('Delete selected draft tour time slots?')}
        contentText={t(
          'You are about to delete multiple tour time slots. This action is irreversible and will permanently erase all associated information. Please confirm that this is what you intend to do before proceeding.'
        )}
        confirmButtonLabel={t('Delete')}
        onConfirm={handleDeleteConfirmation}
        onCancel={closeDeleteDialog}
        isOpen={isDeleteDialogOpened}
      />
    </>
  )
}
