import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useProductTypeParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useGetProductType, useUpdateProductType} from './graphql'
import {ProductTypeForm} from './ProductTypeForm'
import {
  IProductTypeForm,
  ProductTypeFormField,
  ProductTypeFormLocation
} from './types'

const UPDATE_PRODUCT_TYPE_FORM_ID = 'updateProductTypeForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface IUpdateProductTypeDrawerProps {
  onExited: () => void
}

export const UpdateProductTypeDrawer: React.FC<IUpdateProductTypeDrawerProps> =
  ({onExited}: IUpdateProductTypeDrawerProps) => {
    const {t} = useTranslation()
    const {productTypeId} = useProductTypeParams()
    const {data, loading, error} = useGetProductType({id: productTypeId})
    const updateProductType = useUpdateProductType()
    const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (productTypeId) {
        openDrawer()
      }
    }, [productTypeId, openDrawer])
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: IProductTypeForm) => {
        try {
          setShowBackdrop(true)
          await updateProductType({id: productTypeId, input: data})
          addInfoNotification(t('Product type updated'))
          onExited()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating product type'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        defaultErrorHandler,
        onExited,
        productTypeId,
        setShowBackdrop,
        t,
        updateProductType
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={error && t<string>('Error while loading product type')}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update product type')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_PRODUCT_TYPE_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
        >
          {data && (
            <ProductTypeForm
              formId={UPDATE_PRODUCT_TYPE_FORM_ID}
              location={ProductTypeFormLocation.Edit}
              onSubmit={handleSubmit}
              defaultValues={{
                [ProductTypeFormField.Name]: data.productType.name,
                [ProductTypeFormField.Abbreviation]:
                  data.productType.abbreviation,
                [ProductTypeFormField.State]: data.productType.state
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
