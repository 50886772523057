import {
  Button,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {DialogTitleWithCloseButton} from '../../../common/DialogTitleWithCloseButton'
import {OutlinedInputWithCancelAdornment} from '../../../common/OutlinedInputWithCancelAdornment'

const usePermissionRowStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 40,
    padding: theme.spacing(0),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 0
    }
  },
  typography: {
    overflowWrap: 'anywhere'
  }
}))

interface IPermissionRowListItemProps {
  permission: string
  isCheckBoxHidden?: boolean
  checkBoxProps?: CheckboxProps
}

const PermissionRowListItem: React.FC<IPermissionRowListItemProps> = ({
  permission,
  isCheckBoxHidden,
  checkBoxProps
}: IPermissionRowListItemProps) => {
  const classes = usePermissionRowStyles()
  return (
    <ListItem className={cn(classes.root)} disabled={isCheckBoxHidden}>
      {!isCheckBoxHidden && (
        <ListItemIcon>
          <Checkbox {...checkBoxProps} color="primary" />
        </ListItemIcon>
      )}
      <ListItemText
        className={classes.typography}
        inset={isCheckBoxHidden}
        primary={permission}
        primaryTypographyProps={{variant: 'body1'}}
      />
    </ListItem>
  )
}

const useAddPermissionDialogStyles = makeStyles<Theme>((theme) => ({
  paper: {
    height: '92vh'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: 0
  },
  topBar: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    gap: theme.spacing(1)
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 2),
    overflowY: 'auto'
  },
  search: {
    padding: 0
  },
  noItems: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'center',
    alignItems: 'center'
  }
}))

interface IAddPermissionDialogProps {
  isOpen: boolean
  onClose: () => void
  addedPermissions: string[]
  onAddButtonClick: (selectedPermissions: string[]) => void
}

export const AddPermissionDialog: React.FC<IAddPermissionDialogProps> = ({
  isOpen,
  onClose,
  addedPermissions,
  onAddButtonClick
}: IAddPermissionDialogProps) => {
  const {t} = useTranslation()
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  const selectablePermissions: string[] = Object.values(PermissionCode).filter(
    (permission) => !addedPermissions.includes(permission)
  )
  const filteredPermissions = Object.values(PermissionCode).filter(
    (p) => p.indexOf(search.toUpperCase()) > -1
  )
  const createPermissionCheckboxClickHandler = useCallback(
    (permission: string) => (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedPermissions((permissions) =>
        permissions.includes(permission)
          ? permissions.filter((p) => p !== permission)
          : [...permissions, permission]
      )
    },
    []
  )
  const handleAddButtonClick = useCallback(() => {
    onAddButtonClick([...addedPermissions, ...selectedPermissions])
    setSelectedPermissions([])
  }, [addedPermissions, onAddButtonClick, selectedPermissions])
  const classes = useAddPermissionDialogStyles()
  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      maxWidth="xs"
      fullWidth
      classes={{paper: classes.paper}}
    >
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add permissions')}
      </DialogTitleWithCloseButton>
      <DialogContent className={classes.dialogContent} dividers>
        <div className={classes.topBar}>
          <OutlinedInputWithCancelAdornment
            onCancelClick={() => setSearch('')}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
              placeholder: t('Search for permission')
            }}
            className={classes.search}
          />
          <Typography variant="overline" color="textSecondary">
            {t('Select permissions')}
          </Typography>
        </div>
        {filteredPermissions.length > 0 ? (
          <List className={classes.list}>
            {filteredPermissions.map((permission) => (
              <PermissionRowListItem
                key={permission}
                permission={permission}
                checkBoxProps={{
                  onClick: createPermissionCheckboxClickHandler(permission),
                  checked:
                    !!selectedPermissions.find((sp) => sp === permission) ||
                    false
                }}
                isCheckBoxHidden={
                  !!addedPermissions.find((ap) => ap === permission) || false
                }
              />
            ))}
          </List>
        ) : (
          <Typography variant="body2" className={classes.noItems}>
            {t('No permissions found')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Typography variant="subtitle1" color="textPrimary">
          {t('Selected: {{selected}} of {{available}}', {
            selected: selectedPermissions.length,
            available: selectablePermissions.length
          })}
        </Typography>
        <Button
          onClick={handleAddButtonClick}
          color="primary"
          variant="contained"
          disabled={selectedPermissions.length === 0}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
