import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {SaveButton} from '../../../common/Buttons'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: 64,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0, 3),
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  balanceGrid: {
    display: 'grid',
    textAlign: 'end',
    gridTemplateAreas: `
      ". divider"
      ". divider"
    `,
    columnGap: theme.spacing(1)
  },
  balanceDivider: {
    gridArea: 'divider'
  },
  positive: {
    color: theme.palette.success.main
  },
  negative: {
    color: theme.palette.error.main
  }
}))

interface IRefundClaimFooterProps {
  surplus: number
  formId: string
}

export const RefundClaimFooter: React.FC<IRefundClaimFooterProps> = ({
  surplus,
  formId
}: IRefundClaimFooterProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslateEffectiveClientPrice()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.balanceGrid}>
        <Typography variant="caption">
          {surplus < 0 ? t('Outstanding payment') : t('Over refunded')}
        </Typography>
        <Divider orientation="vertical" className={classes.balanceDivider} />
        <Typography
          variant="subtitle1"
          className={surplus < 0 ? classes.negative : classes.positive}
        >
          {translatePrice(surplus)}
        </Typography>
      </div>
      <SaveButton type="submit" form={formId} disabled={surplus < 0}>
        {t('Refund')}
      </SaveButton>
    </div>
  )
}
