import {omit} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightDivisionsQuery,
  LightweightUsersQuery,
  PassCodeCheckAdditionalInformation,
  PassCodeCheckDirection,
  PassCodeChecksFilter,
  PassCodeCheckState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useFormatUserName} from '../../../../hooks/formatUserName'
import {useTranslatePassCodeCheckAdditionalInformation} from '../../../../hooks/translatePassCodeCheckAdditionalInformation'
import {useTranslatePassCodeCheckDirection} from '../../../../hooks/translatePassCodeCheckDirection'
import {useTranslatePassCodeCheckState} from '../../../../hooks/translatePassCodeCheckState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {AdvancedSearchDaterangeRow} from '../../../common/search/AdvancedSearchDaterangeRow'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useDateRangeSearch} from '../../../common/search/daterangeSearch'
import {
  DATERANGE_IDS,
  PREDICTABLE_DATERANGE_IDS
} from '../../../common/search/types'
import {useGetLightweightDivisions} from '../graphql'
import {useGetLightweightUsers} from '../paymentsOverview/graphql'

type ExtendedPassCodeChecksFilter = PassCodeChecksFilter & {
  _createdAtDaterangeId?: DATERANGE_IDS
}

const stripCreatedAtDateFromFilter = (
  filter: ExtendedPassCodeChecksFilter
): PassCodeChecksFilter =>
  omit(filter, ['createdAtFrom', 'createdAtTo', '_createdAtDaterangeId'])

const stripHelperKeysFromFilter = (
  filter: ExtendedPassCodeChecksFilter
): PassCodeChecksFilter => omit(filter, ['_createdAtDaterangeId'])

const dateRanges: PREDICTABLE_DATERANGE_IDS[] = [
  DATERANGE_IDS.TODAY,
  DATERANGE_IDS.YESTERDAY,
  DATERANGE_IDS.TWO_DAYS_AGO,
  DATERANGE_IDS.LAST_7_DAYS
]

const mapPassCodeToFilter = (
  filter: PassCodeChecksFilter,
  passCode: string | undefined
): PassCodeChecksFilter => ({...filter, passCode})

const mapDirectionToFilter = (
  filter: PassCodeChecksFilter,
  direction: PassCodeCheckDirection | undefined
): PassCodeChecksFilter => ({
  ...filter,
  direction
})

const mapStateToFilter = (
  filter: PassCodeChecksFilter,
  states: PassCodeCheckState | undefined
): PassCodeChecksFilter => ({
  ...filter,
  states: states ? [states] : undefined
})

const mapInformationToFilter = (
  filter: PassCodeChecksFilter,
  additionalInformation: PassCodeCheckAdditionalInformation | undefined
): PassCodeChecksFilter => ({
  ...filter,
  additionalInformation: additionalInformation
    ? [additionalInformation]
    : undefined
})

const mapDivisionIdToFilter = (
  filter: PassCodeChecksFilter,
  divisionId: number | undefined
): PassCodeChecksFilter => ({
  ...filter,
  divisionId
})

const mapCreatedByIdToFilter = (
  filter: PassCodeChecksFilter,
  createdById: number | undefined
): PassCodeChecksFilter => ({
  ...filter,
  createdById
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translatePassCodeCheckDirection = useTranslatePassCodeCheckDirection()
  const translatePassCodeCheckState = useTranslatePassCodeCheckState()
  const translatePassCodeCheckAdditionalInformation =
    useTranslatePassCodeCheckAdditionalInformation()
  const formatUserName = useFormatUserName(true)
  const getPassCodeFromSearchObject = (filter: PassCodeChecksFilter) =>
    filter.passCode || undefined
  const getDirectionFromSearchObject = (filter: PassCodeChecksFilter) =>
    filter.direction
      ? t('Direction: {{direction}}', {
          direction: translatePassCodeCheckDirection(filter.direction)
        })
      : undefined
  const getStateFromSearchObject = (filter: PassCodeChecksFilter) =>
    filter.states
      ? t('State: {{state}}', {
          state: translatePassCodeCheckState(filter.states[0])
        })
      : undefined
  const getInformationFromSearchObject = (filter: PassCodeChecksFilter) =>
    filter.additionalInformation
      ? t('Information: {{information}}', {
          information: translatePassCodeCheckAdditionalInformation(
            filter.additionalInformation[0]
          )
        })
      : undefined
  const getUserFromSearchObject = (
    filter: PassCodeChecksFilter,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.createdById) {
      const user = users.find((user) => user.id === filter.createdById)
      return user
        ? t('Created by: {{userName}}', {
            userName: formatUserName(user)
          })
        : t('Created by ID: {{userId}}', {userId: filter.createdById})
    }
    return undefined
  }
  const getDivisionFromSearchObject = (
    filter: PassCodeChecksFilter,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionId) {
      const division = divisions.find(
        (division) => division.id === filter.divisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filter.divisionId})
    }
    return undefined
  }
  return {
    getPassCodeFromSearchObject,
    getDirectionFromSearchObject,
    getStateFromSearchObject,
    getInformationFromSearchObject,
    getUserFromSearchObject,
    getDivisionFromSearchObject
  }
}

interface IPassCodeCheckHistorySearchProps {
  onFilterChange: (filter: PassCodeChecksFilter) => void
}

export const PassCodeCheckHistorySearch: React.FC<IPassCodeCheckHistorySearchProps> =
  ({onFilterChange}: IPassCodeCheckHistorySearchProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const {users} = useGetLightweightUsers()
    const {divisions} = useGetLightweightDivisions()
    const translatePassCodeCheckDirection = useTranslatePassCodeCheckDirection()
    const translatePassCodeCheckState = useTranslatePassCodeCheckState()
    const translatePassCodeCheckAdditionalInformation =
      useTranslatePassCodeCheckAdditionalInformation()
    const formatUserName = useFormatUserName(true)
    const {
      getPassCodeFromSearchObject,
      getDirectionFromSearchObject,
      getStateFromSearchObject,
      getInformationFromSearchObject,
      getUserFromSearchObject,
      getDivisionFromSearchObject
    } = useGetFieldFromSearchObject()
    const {
      daterangeOptions,
      mapDaterangeToSearchObject,
      mapCustomDaterangeToSearchObject,
      getStringifiedDaterangeFromSearchObject,
      getIsDaterangeOptionActive
    } = useDateRangeSearch<ExtendedPassCodeChecksFilter>({
      usedDateranges: dateRanges,
      dateRangeInputPrefix: t('Timestamp'),
      getDateRangeFromSearchObject: (o) => ({
        startDateISOString: o.createdAtFrom || undefined,
        endDateISOString: o.createdAtTo || undefined,
        id: o._createdAtDaterangeId
      }),
      mapDaterangeValuesToSearchObject: (o, input) => ({
        ...o,
        createdAtFrom: input.startDate,
        createdAtTo: input.endDate,
        _createdAtDaterangeId: input.id
      })
    })
    const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
      getPassCodeFromSearchObject,
      getStringifiedDaterangeFromSearchObject,
      getDirectionFromSearchObject,
      getStateFromSearchObject,
      getInformationFromSearchObject,
      (filter) => getUserFromSearchObject(filter, users),
      (filter) => getDivisionFromSearchObject(filter, divisions)
    )
    const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
      (division) => ({
        id: division.id,
        label: division.name
      })
    )
    const userSelectOptions: ISelectOption<number>[] = users.map((user) => ({
      id: user.id,
      label: formatUserName(user)
    }))
    return (
      <Search<PassCodeChecksFilter, ExtendedPassCodeChecksFilter>
        storageKey="PASS_CODE_CHECK_HISTORY"
        placeholder={t('Search for ticket...')}
        onChange={onFilterChange}
        mapInputTextToSearchObject={mapPassCodeToFilter}
        mapSearchObjectToInputText={mapSearchObjectToInputText}
        defaultSearchObject={{}}
        stripExtendedSearchObject={stripHelperKeysFromFilter}
        renderAdvancedSearch={({
          isAdvancedSubmitDisabled,
          onAdvancedSearchSubmit,
          advancedSearchObject,
          setAdvancedSearchObject
        }) => (
          <AdvancedSearchBase
            isSubmitDisabled={isAdvancedSubmitDisabled}
            onSubmit={onAdvancedSearchSubmit}
          >
            <AdvancedSearchDaterangeRow
              label={t('Timestamp')}
              daterangeOptions={daterangeOptions}
              mapCustomDaterangeToSearchObject={
                mapCustomDaterangeToSearchObject
              }
              mapDaterangeToSearchObject={mapDaterangeToSearchObject}
              getIsDaterangeOptionActive={getIsDaterangeOptionActive}
              stripDaterangeFromSearchObject={stripCreatedAtDateFromFilter}
              advancedSearchObject={advancedSearchObject}
              setAdvancedSearchObject={setAdvancedSearchObject}
              customDaterangeDialogTitle={t('Select date range')}
              customDaterangeDialogDescription={t(
                'Select date range of timestamp'
              )}
            />
            <AdvancedSearchTextRow<PassCodeChecksFilter>
              label={t('Ticket number')}
              placeholder={t('Enter ticket number')}
              setAdvancedSearchObject={setAdvancedSearchObject}
              advancedSearchObject={advancedSearchObject}
              mapTextToSearchObject={mapPassCodeToFilter}
              value={advancedSearchObject.passCode || undefined}
            />
            <AdvancedSearchSelectRow<
              PassCodeChecksFilter,
              PassCodeCheckDirection
            >
              label={t('Direction')}
              value={advancedSearchObject.direction || undefined}
              options={[
                PassCodeCheckDirection.In,
                PassCodeCheckDirection.Out
              ].map((direction) => ({
                id: direction,
                label: translatePassCodeCheckDirection(direction)
              }))}
              mapSelectValueToSearchObject={mapDirectionToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchSelectRow<PassCodeChecksFilter, PassCodeCheckState>
              label={t('State')}
              value={
                advancedSearchObject.states
                  ? advancedSearchObject.states[0]
                  : undefined
              }
              options={[
                PassCodeCheckState.Allowed,
                PassCodeCheckState.Pending,
                PassCodeCheckState.Denied
              ].map((state) => ({
                id: state,
                label: translatePassCodeCheckState(state)
              }))}
              mapSelectValueToSearchObject={mapStateToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchSelectRow<
              PassCodeChecksFilter,
              PassCodeCheckAdditionalInformation
            >
              label={t('Information')}
              value={
                advancedSearchObject.additionalInformation
                  ? advancedSearchObject.additionalInformation[0]
                  : undefined
              }
              options={Object.values(PassCodeCheckAdditionalInformation).map(
                (information) => ({
                  id: information,
                  label:
                    translatePassCodeCheckAdditionalInformation(information)
                })
              )}
              mapSelectValueToSearchObject={mapInformationToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            <AdvancedSearchSelectRow<PassCodeChecksFilter, number>
              label={t('Division')}
              value={advancedSearchObject.divisionId || undefined}
              options={divisionSelectOptions}
              mapSelectValueToSearchObject={mapDivisionIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
            {P([PermissionCode.ReadUsers]) && (
              <AdvancedSearchSelectRow<PassCodeChecksFilter, number>
                label={t('Created by')}
                value={advancedSearchObject.createdById || undefined}
                options={userSelectOptions}
                mapSelectValueToSearchObject={mapCreatedByIdToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
          </AdvancedSearchBase>
        )}
      />
    )
  }
