import {useTranslation} from 'react-i18next'
import {BusinessPartnerState} from '../__generated__/schema'

export const useTranslateBusinessPartnerState = () => {
  const {t} = useTranslation()
  const translated: Record<BusinessPartnerState, string> = {
    [BusinessPartnerState.Active]: t<string>('BusinessPartnerState->Active'),
    [BusinessPartnerState.Inactive]: t<string>(
      'BusinessPartnerState->Inactive'
    ),
    [BusinessPartnerState.Deleted]: t<string>('BusinessPartnerState->Deleted')
  }
  return (state: BusinessPartnerState) => translated[state]
}
