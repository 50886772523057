import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DivisionGateOpens} from '../../../../../__generated__/schema'
import {useIsNonNegativeInteger} from '../../../../../utils/formsValidations'
import {DivisionFormControlLabel} from './DivisionFormControlLabel'
import {
  DivisionFormSelect,
  FormControlTextLabel,
  FormControlTimeLabel
} from './DivisionFormSelect'
import {
  DivisionFormField,
  IDivisionFormData,
  IDivisionSectionProps
} from './types'

export const GateOpens: React.FC<IDivisionSectionProps> = ({
  defaultValues
}: IDivisionSectionProps) => {
  const {t} = useTranslation()
  const gateOpensOptions = Object.values(DivisionGateOpens)
  const methods = useFormContext<IDivisionFormData>()
  const {
    watch,
    triggerValidation,
    errors,
    setValue,
    register,
    control,
    unregister,
    clearError,
    setError
  } = methods
  const gateOpens = watch(DivisionFormField.GATE_OPENS_TYPE)
  useEffect(() => {
    if (gateOpens) {
      triggerValidation(
        Object.values(DivisionGateOpens)
          .filter((option) => option !== gateOpens)
          .map((option) => `${DivisionFormField.GATE_OPENS_VALUE}.${option}`)
      )
    }
  }, [gateOpens, triggerValidation])
  const isNonNegativeInteger = useIsNonNegativeInteger()
  return (
    <DivisionFormSelect<IDivisionFormData>
      options={gateOpensOptions}
      name={DivisionFormField.GATE_OPENS_TYPE}
      subfieldsName={DivisionFormField.GATE_OPENS_VALUE}
      defaultValue={
        defaultValues[DivisionFormField.GATE_OPENS_TYPE] ||
        DivisionGateOpens.BeforeStart
      }
      validationOptions={{
        required: true
      }}
      required
      label={t('Gate opens')}
      errors={errors}
      setValue={setValue}
      register={register}
    >
      <DivisionFormControlLabel
        value={DivisionGateOpens.BeforeStart}
        label={
          <FormControlTextLabel<IDivisionFormData>
            name={`${DivisionFormField.GATE_OPENS_VALUE}.${DivisionGateOpens.BeforeStart}`}
            validationOptions={{
              required: gateOpens === DivisionGateOpens.BeforeStart,
              validate: isNonNegativeInteger
            }}
            required={gateOpens === DivisionGateOpens.BeforeStart}
            inputMode="numeric"
            note={t('minutes before event starts')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
          />
        }
      />
      <DivisionFormControlLabel
        value={DivisionGateOpens.DayBeforeStart}
        label={
          <FormControlTimeLabel<IDivisionFormData>
            fullWidth
            name={`${DivisionFormField.GATE_OPENS_VALUE}.${DivisionGateOpens.DayBeforeStart}`}
            validationOptions={{
              required: gateOpens === DivisionGateOpens.DayBeforeStart
            }}
            note={t('the day before the event start')}
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            control={control}
            unregister={unregister}
            clearError={clearError}
            setError={setError}
          />
        }
      />
    </DivisionFormSelect>
  )
}
