import dayjs from 'dayjs'

import {
  ConditionPropertiesFragment,
  ConditionResource,
  DiscountApplication,
  DiscountInput,
  DiscountPropertiesFragment,
  DiscountType,
  GetLightweightDivisionsQuery,
  Scalars,
  SellingChannel
} from '../../../../__generated__/schema'
import {convertValueToFloat} from '../../../../utils/conversions'
import {ConditionFormField, IConditionForm} from './DiscountDetail/types'

export enum DiscountFormField {
  NAME = 'name',
  INTERNAL_DESCRIPTION = 'internalDescription',
  APPLICATION = 'application',
  TYPE = 'type',
  FIXED_TYPE_VALUE = 'fixedTypeValue',
  PERCENTAGE_TYPE_VALUE = 'percentageTypeValue',
  SELLING_CHANNELS = 'sellingChannels',
  DIVISION_IDS = 'divisionIds',
  MAX_USAGE_LIMIT_PER_ORDER = 'maxUsageLimitPerOrder'
}

export interface IDiscountForm {
  [DiscountFormField.NAME]: string
  [DiscountFormField.INTERNAL_DESCRIPTION]?: string
  [DiscountFormField.APPLICATION]: DiscountApplication
  [DiscountFormField.TYPE]: DiscountType
  [DiscountFormField.FIXED_TYPE_VALUE]?: number
  [DiscountFormField.PERCENTAGE_TYPE_VALUE]?: number
  [DiscountFormField.SELLING_CHANNELS]: SellingChannel[]
  [DiscountFormField.DIVISION_IDS]: number[]
  [DiscountFormField.MAX_USAGE_LIMIT_PER_ORDER]?: number
}

export const transformDiscountFormIntoInput = (
  data: IDiscountForm
): DiscountInput => {
  return {
    name: data[DiscountFormField.NAME],
    internalDescription: data[DiscountFormField.INTERNAL_DESCRIPTION],
    application: data[DiscountFormField.APPLICATION],
    type: data[DiscountFormField.TYPE],
    value:
      data[DiscountFormField.TYPE] === DiscountType.FixedAmount
        ? convertValueToFloat(data[DiscountFormField.FIXED_TYPE_VALUE]) || 0
        : convertValueToFloat(data[DiscountFormField.PERCENTAGE_TYPE_VALUE]) ||
          0,
    sellingChannels: data[DiscountFormField.SELLING_CHANNELS],
    divisionIds: data[DiscountFormField.DIVISION_IDS],
    maxUsageLimitPerOrder:
      data[DiscountFormField.MAX_USAGE_LIMIT_PER_ORDER] || undefined
  }
}

const getIsDivisionActiveFilter =
  (activeDivisions: GetLightweightDivisionsQuery['divisions']) =>
  (division: DiscountPropertiesFragment['divisions'][number]) =>
    activeDivisions.find((activeDivision) => activeDivision.id === division.id)

export const transformDiscountIntoForm = (
  discount: DiscountPropertiesFragment,
  divisions: GetLightweightDivisionsQuery['divisions']
) => ({
  [DiscountFormField.NAME]: discount.name,
  [DiscountFormField.INTERNAL_DESCRIPTION]:
    discount.internalDescription || undefined,
  [DiscountFormField.APPLICATION]: discount.application,
  [DiscountFormField.TYPE]: discount.type,
  [DiscountFormField.FIXED_TYPE_VALUE]:
    discount.type === DiscountType.FixedAmount ? discount.value : undefined,
  [DiscountFormField.PERCENTAGE_TYPE_VALUE]:
    discount.type === DiscountType.Percentage ? discount.value : undefined,
  [DiscountFormField.SELLING_CHANNELS]: discount.sellingChannels,
  [DiscountFormField.DIVISION_IDS]: discount.divisions
    .filter(getIsDivisionActiveFilter(divisions))
    .map((division) => division.id),
  [DiscountFormField.MAX_USAGE_LIMIT_PER_ORDER]:
    discount.maxUsageLimitPerOrder || undefined
})

export const transformConditionFormValueToInputValue = ({
  value,
  resource
}: {
  value: Scalars['JSON']
  resource: ConditionResource
}) => {
  if (
    resource === ConditionResource.StartDate ||
    resource === ConditionResource.DiscountApplicationDate
  ) {
    return dayjs(value).format('YYYY-MM-DD')
  }
  if (
    resource === ConditionResource.StartTime ||
    resource === ConditionResource.DiscountApplicationTime
  ) {
    return dayjs(value).format('HH:mm')
  }
  return value
}

export const transformConditionIntoConditionForm = (
  condition: ConditionPropertiesFragment
): IConditionForm => ({
  [ConditionFormField.Resource]: condition.resource,
  [ConditionFormField.Value]:
    condition.resource === ConditionResource.StartTime ||
    condition.resource === ConditionResource.DiscountApplicationTime
      ? dayjs(condition.conditionData.value, 'HH:mm').toDate()
      : condition.conditionData.value,
  [ConditionFormField.Operator]: condition.conditionData.operator
})
