import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {ClickInfoActivity} from './types'
import {useBackdropState} from '../../../../context/backdrop'
import {useDefaultErrorHandler} from '../../../../../utils/errors'
import {useCancelEvents, useDeleteEvents, usePublishEvents} from '../graphql'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'

interface IDropdownsConfirmationDialogsProps {
  activity?: ClickInfoActivity
  closeDialogAndPopup: () => void
  draftEventIds: number[]
  draftAndCanceledEventIds: number[]
  publishedEventIds: number[]
}

export const DropdownsConfirmationDialogs: React.FC<IDropdownsConfirmationDialogsProps> =
  ({
    activity,
    closeDialogAndPopup,
    draftEventIds,
    draftAndCanceledEventIds,
    publishedEventIds
  }: IDropdownsConfirmationDialogsProps) => {
    const {t} = useTranslation()
    const {setShowBackdrop} = useBackdropState()
    const defaultErrorHandler = useDefaultErrorHandler()
    const deleteEvents = useDeleteEvents()

    const performDeleteEvents = useCallback(async () => {
      setShowBackdrop(true)
      closeDialogAndPopup()
      try {
        await deleteEvents(draftEventIds)
      } catch (e) {
        defaultErrorHandler(e, t('Error while deleting events.'))
      } finally {
        setShowBackdrop(false)
      }
    }, [
      closeDialogAndPopup,
      defaultErrorHandler,
      deleteEvents,
      draftEventIds,
      setShowBackdrop,
      t
    ])

    const publishEvents = usePublishEvents()

    const performPublishEvents = useCallback(async () => {
      closeDialogAndPopup()
      setShowBackdrop(true)
      try {
        await publishEvents(draftAndCanceledEventIds)
      } catch (e) {
        defaultErrorHandler(e, t('Error while publishing events.'))
      } finally {
        setShowBackdrop(false)
      }
    }, [
      closeDialogAndPopup,
      defaultErrorHandler,
      draftAndCanceledEventIds,
      publishEvents,
      setShowBackdrop,
      t
    ])

    const cancelEvents = useCancelEvents()

    const performCancelEvents = useCallback(async () => {
      setShowBackdrop(true)
      closeDialogAndPopup()
      try {
        await cancelEvents(publishedEventIds)
      } catch (e) {
        defaultErrorHandler(e, t('Error while canceling events.'))
      } finally {
        setShowBackdrop(false)
      }
    }, [
      setShowBackdrop,
      cancelEvents,
      publishedEventIds,
      defaultErrorHandler,
      t,
      closeDialogAndPopup
    ])
    return (
      <>
        <ConfirmationDialog
          contentText={t(
            'Do you really want to publish all draft events and canceled events?'
          )}
          title={t('Publish events')}
          onCancel={closeDialogAndPopup}
          onConfirm={performPublishEvents}
          isOpen={activity === ClickInfoActivity.PUBLISH}
        />
        <ConfirmationDialog
          contentText={t('Do you really want to cancel all published events?')}
          title={t('Cancel events')}
          onCancel={closeDialogAndPopup}
          onConfirm={performCancelEvents}
          isOpen={activity === ClickInfoActivity.CANCEL}
        />
        <ConfirmationDialog
          contentText={t('Do you really want to delete all draft events?')}
          title={t('Delete events')}
          onCancel={closeDialogAndPopup}
          onConfirm={performDeleteEvents}
          isOpen={activity === ClickInfoActivity.DELETE}
        />
      </>
    )
  }
