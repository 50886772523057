import {Box, SxProps} from '@mui/material'
import React from 'react'
import {isValidHexColor} from '../../utils/formsValidations'

const isLight = (hexColor: string, contrastRatioLimit: number = 0.5) => {
  const hexCode = hexColor.charAt(0) === '#' ? hexColor.substr(1, 6) : hexColor
  const hexR = parseInt(hexCode.substr(0, 2), 16)
  const hexG = parseInt(hexCode.substr(2, 2), 16)
  const hexB = parseInt(hexCode.substr(4, 2), 16)
  const contrastRatio = (hexR + hexG + hexB) / (255 * 3)
  return contrastRatio >= contrastRatioLimit
}

interface IColorBoxProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  hexColor?: string
  sx?: SxProps
}

export const ColorBox: React.FC<IColorBoxProps> = ({
  onClick,
  hexColor = '#ffffff',
  sx
}: IColorBoxProps) => {
  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: 1,
        cursor: onClick ? 'pointer' : 'inherit',
        backgroundColor: isValidHexColor(hexColor) ? hexColor : 'white',
        border: (theme) =>
          isLight(hexColor, 0.6)
            ? `1px solid ${theme.palette.divider}`
            : 'none',
        ...sx
      }}
      onClick={onClick}
    />
  )
}
