import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import {Fab} from '@mui/material'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {CartPropertiesFragment} from '../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'

import {routeTo} from '../../../../utils/routes'
import {useFabClasses} from '../../../common/Buttons'
import {useCurrentCart} from './CurrentCartContext'

interface ICurrentCartFabProps {
  currentCart: CartPropertiesFragment
}

const CurrentCartFab: React.FC<ICurrentCartFabProps> = ({
  currentCart
}: ICurrentCartFabProps) => {
  const fabClasses = useFabClasses()
  const history = useHistory()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <Fab
      variant="extended"
      color="secondary"
      classes={fabClasses}
      onClick={() => {
        history.replace(routeTo.admin.cashDesk.cartDetail())
      }}
    >
      <>
        <ShoppingCartIcon />
        <span>{translateEffectiveClientPrice(currentCart.price)}</span>
      </>
    </Fab>
  )
}

export const TentativeCurrentCartFab: React.FC = () => {
  const {currentCart} = useCurrentCart()
  return currentCart && currentCart.items?.length ? (
    <CurrentCartFab currentCart={currentCart} />
  ) : null
}
