import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {DefaultErrorBoundary} from './components/common/DefaultErrorBoundary'
import {Notifications} from './components/common/Notifications'
import {PrivateRoute} from './components/common/PrivateRoute'
import {BackdropProvider, useBackdropState} from './components/context/backdrop'
import {LocaleProvider} from './components/context/locale'
import {NotificationsProvider} from './components/context/notifications'
import {SideBarRefProvider} from './components/context/sideBarRef'
import {AdminPage} from './components/pages/admin'
import {CustomerDisplayRoute} from './components/pages/customerDisplayRoute'
import {InvitedUserPage} from './components/pages/invitedUser'
import {LoginPage} from './components/pages/login'
import {ResetForgottenPasswordScreen} from './components/pages/login/ResetForgottenPassword'
import {Backdrop} from './components/visual'
import {
  CustomDisplayBroadcastChannelContext,
  customerDisplayBroadcastChannel
} from './customerDisplayBroadcastChannel'
import {initializeDayjs} from './dayjs'
import {useSubscribeToBroadcastChannel} from './hooks/broadcastChannel'
import {useInitializeQz} from './qz'
import {theme} from './theme'
import {routeTo} from './utils/routes'

const BackdropWrapper = () => {
  // Not placed directly into AppLayout to prevent unnecessary re-render of whole app
  const {showBackdrop} = useBackdropState()
  return <Backdrop open={showBackdrop} />
}

const AppLayout: React.FC = () => {
  useSubscribeToBroadcastChannel()
  initializeDayjs()
  useInitializeQz()

  return (
    <CustomDisplayBroadcastChannelContext.Provider
      value={customerDisplayBroadcastChannel}
    >
      <CssBaseline />
      <Notifications />
      <BackdropWrapper />
      <Router>
        <Switch>
          <Route path={routeTo.login()} component={LoginPage} />
          <Route path={routeTo.invitedUser()} component={InvitedUserPage} />
          <Route
            path={routeTo.recoverPassword()}
            component={ResetForgottenPasswordScreen}
          />
          <CustomerDisplayRoute
            path={routeTo.admin.customerDisplay.home()}
            component={AdminPage}
          />
          <PrivateRoute path="*" component={AdminPage} />
        </Switch>
      </Router>
    </CustomDisplayBroadcastChannelContext.Provider>
  )
}

export const App: React.FC = () => {
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <DefaultErrorBoundary>
            <LocaleProvider>
              <NotificationsProvider>
                <SideBarRefProvider>
                  <BackdropProvider>
                    <AppLayout />
                  </BackdropProvider>
                </SideBarRefProvider>
              </NotificationsProvider>
            </LocaleProvider>
          </DefaultErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  )
}
