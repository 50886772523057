import {Divider, Typography, TypographyVariant} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'

import {Theme} from '../../theme'

interface ISingleInfoColumnProps {
  caption: string
  value: React.ReactNode
  valueTypographyVariant?: TypographyVariant
  subValue?: string | number
}

export const SingleInfoColumn: React.FC<ISingleInfoColumnProps> = ({
  caption,
  value,
  valueTypographyVariant,
  subValue
}: ISingleInfoColumnProps) => (
  <div>
    <Typography variant="caption" color="textSecondary">
      {caption}
    </Typography>
    <Typography variant={valueTypographyVariant || 'subtitle2'}>
      {value}
    </Typography>
    {subValue && <Typography variant="subtitle2">{subValue}</Typography>}
  </div>
)

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoFlow: 'column',
    alignItems: 'start',
    justifyContent: 'start'
  }
}))

interface IInformation {
  caption: string
  value?: React.ReactNode | null
  subValue?: string | number | null
}

interface IDividerSeparatedInfoRowProps {
  information: IInformation[]
  className?: string
}

export const DividerSeparatedInfoRow: React.FC<IDividerSeparatedInfoRowProps> =
  ({information, className}: IDividerSeparatedInfoRowProps) => {
    const classes = useStyles()
    return (
      <div className={cn(classes.root, className)}>
        {(
          information.filter(
            (information) =>
              information.value !== '' &&
              information.value !== null &&
              information.value !== undefined
          ) as ISingleInfoColumnProps[]
        ).reduce(
          (prev: React.ReactNode, curr, index) => [
            prev,
            prev !== null && (
              <Divider
                key={`${index}-divider`}
                orientation="vertical"
                flexItem
              />
            ),
            <SingleInfoColumn
              key={`${index}-box`}
              caption={curr.caption}
              value={curr.value}
              subValue={curr.subValue}
            />
          ],
          null
        )}
      </div>
    )
  }
