import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Box,
  IconButton,
  IconButtonProps,
  SxProps,
  Typography
} from '@mui/material'
import {isNil} from 'lodash'
import React from 'react'
import {Theme} from '../../theme'

export interface IQuantityControlsProps {
  quantity: number
  minQuantity?: number
  maxQuantity?: number
  DecrementButtonProps: IconButtonProps
  IncrementButtonProps: IconButtonProps
  onClick?: (e: React.MouseEvent) => void
  typographySx?: SxProps
  sx?: SxProps<Theme>
}

export const QuantityControls: React.FC<IQuantityControlsProps> = ({
  quantity,
  minQuantity = 0,
  maxQuantity,
  DecrementButtonProps,
  IncrementButtonProps,
  onClick,
  typographySx,
  sx
}: IQuantityControlsProps) => (
  <Box sx={{display: 'inline-grid', gridAutoFlow: 'column', ...sx}}>
    <IconButton
      disabled={quantity <= minQuantity}
      color="primary"
      {...DecrementButtonProps}
      sx={{width: 38, height: 38}}
    >
      <RemoveCircleOutlineIcon />
    </IconButton>
    <Typography
      variant="body1"
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        p: 1,
        cursor: onClick ? 'pointer' : 'auto',
        ...typographySx
      }}
      onClick={onClick}
    >
      {quantity}
    </Typography>
    <IconButton
      disabled={!isNil(maxQuantity) ? quantity >= maxQuantity : undefined}
      color="primary"
      {...IncrementButtonProps}
      sx={{width: 38, height: 38}}
    >
      <AddCircleOutlineIcon />
    </IconButton>
  </Box>
)
