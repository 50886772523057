import {ApolloError} from 'apollo-client'
import {useCallback, useEffect, useRef, useState} from 'react'
import {ICustomerDisplayScreen} from '../types'
import {IScreenDetails, PermissionState} from './types'

export const isWindowManagementAvailable = () =>
  Boolean('getScreenDetails' in window)

interface IFetchScreens {
  isLoading: boolean
  error?: ApolloError
  data: IScreenDetails | null
}

export const useFetchScreens = () => {
  const [state, setState] = useState<IFetchScreens>({
    isLoading: false,
    data: null
  })
  const fetchData = useCallback(async () => {
    try {
      setState((prevState) => ({...prevState, isLoading: false}))
      // @ts-ignore
      const data = await window.getScreenDetails()
      setState((prevState) => ({...prevState, data}))
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: new ApolloError({errorMessage: error.message}),
        data: null
      }))
    } finally {
      setState((prevState) => ({...prevState, isLoading: false}))
    }
  }, [])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return {state, refetch: fetchData}
}

export const useGetWindowManagementPermissionState = () => {
  const [permissionState, setPermissionState] =
    useState<PermissionState>(undefined)
  const {refetch} = useFetchScreens()
  const getState = useCallback(async () => {
    try {
      const {state} = await navigator.permissions.query({
        // @ts-ignore
        name: 'window-management'
      })
      setPermissionState(state)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`${error.name}: ${error.message}`)
    }
  }, [])
  useEffect(() => {
    getState()
  }, [getState])

  return {
    permissionState,
    recheckPermissionState: getState,
    promptPermission: refetch
  }
}

export const useListenPermissionStateChange = (
  callback: (permissionState: PermissionState) => void
) => {
  const permissionStatus = useRef<PermissionStatus>()
  const addListener = useCallback(async () => {
    try {
      permissionStatus.current = await navigator.permissions.query({
        // @ts-ignore
        name: 'window-management'
      })
      permissionStatus.current.addEventListener('change', () =>
        callback(permissionStatus.current?.state)
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`${error.name}: ${error.message}`)
    }
  }, [callback])
  const removeListener = useCallback(() => {
    permissionStatus.current?.removeEventListener('change', () =>
      callback(permissionStatus.current?.state)
    )
  }, [callback])
  return {addListener, removeListener}
}

export const useFindScreen = () => {
  const {
    state: {data}
  } = useFetchScreens()
  return useCallback(
    (screen: ICustomerDisplayScreen) =>
      (data?.screens || []).find(
        ({label, top, left}) =>
          screen.name === label && screen.top === top && screen.left === left
      ),
    [data?.screens]
  )
}

export const useOpenWindow = () => {
  const windowRef = useRef<Window | null>(null)
  const prevUrl = useRef<string | null>(null)
  const openWindow = useCallback(
    ({
      url,
      target,
      features
    }: {
      url: string
      target?: string
      features?: string
    }) => {
      if (windowRef.current === null || windowRef.current.closed) {
        windowRef.current = window.open(url, target, features)
      } else if (prevUrl.current !== url) {
        windowRef.current?.focus()
      } else {
        windowRef.current?.focus()
      }
      prevUrl.current = url
    },
    []
  )
  const closeWindow = useCallback(() => {
    windowRef.current?.close()
  }, [])
  return {openWindow, closeWindow}
}
