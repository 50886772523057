import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  AccessZoneQuery,
  AccessZoneQueryVariables,
  AccessZonesQuery,
  AccessZonesQueryVariables,
  CreateAccessZoneMutation,
  CreateAccessZoneMutationVariables,
  UpdateAccessZoneMutation,
  UpdateAccessZoneMutationVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const BASE_ACCESS_ZONE_FIELDS = gql`
  ${USER_FIELDS}
  fragment BaseAccessZoneFields on AccessZone {
    id
    name
    description
    state
    createdAt
    createdBy {
      ...UserFields
    }
    updatedAt
    updatedBy {
      ...UserFields
    }
  }
`

const ACCESS_ZONES = gql`
  ${BASE_ACCESS_ZONE_FIELDS}
  ${PAGINATION_FRAGMENT}
  query AccessZones(
    $filter: AccessZonesFilter
    $paginationInput: PaginationInput!
  ) {
    accessZones(filter: $filter, paginationInput: $paginationInput) {
      items {
        ...BaseAccessZoneFields
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useAccessZones = (variables: AccessZonesQueryVariables) =>
  useQuery<AccessZonesQuery, AccessZonesQueryVariables>(ACCESS_ZONES, {
    variables,
    fetchPolicy: 'network-only'
  })

const CREATE_ACCESS_ZONE = gql`
  mutation CreateAccessZone($input: AccessZoneInput!) {
    createAccessZone(input: $input) {
      id
    }
  }
`

export const useCreateAccessZone = () => {
  const [createAccessZone] =
    useMutation<CreateAccessZoneMutation, CreateAccessZoneMutationVariables>(
      CREATE_ACCESS_ZONE
    )
  return useCallback(
    (variables: CreateAccessZoneMutationVariables) =>
      createAccessZone({variables}),
    [createAccessZone]
  )
}

const UPDATE_ACCESS_ZONE = gql`
  ${BASE_ACCESS_ZONE_FIELDS}
  mutation UpdateAccessZone($id: PositiveInt!, $input: UpdateAccessZoneInput!) {
    updateAccessZone(id: $id, input: $input) {
      ...BaseAccessZoneFields
    }
  }
`

export const useUpdateAccessZone = () => {
  const [updateAccessZone] =
    useMutation<UpdateAccessZoneMutation, UpdateAccessZoneMutationVariables>(
      UPDATE_ACCESS_ZONE
    )
  return useCallback(
    (variables: UpdateAccessZoneMutationVariables) =>
      updateAccessZone({variables}),
    [updateAccessZone]
  )
}

const ACCESS_ZONE = gql`
  query AccessZone($id: PositiveInt!) {
    accessZone(id: $id) {
      id
      name
      description
      state
    }
  }
`

export const useAccessZone = (id: number, skip?: boolean) =>
  useQuery<AccessZoneQuery, AccessZoneQueryVariables>(ACCESS_ZONE, {
    variables: {id},
    fetchPolicy: 'network-only',
    skip
  })
