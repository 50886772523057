import AddIcon from '@mui/icons-material/Add'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Button, Grid, Paper} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  LibraryShowsQuery,
  PermissionCode,
  ShowsFilterInput
} from '../../../../__generated__/schema'

import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {DrawerWithDebouncedId, RenderOnData} from '../../../common'
import {CreateFab, SaveButton, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {
  DEFAULT_SHOWS_FILTER_INPUT,
  ShowsSearch,
  ShowsSearchLocation
} from '../../../common/ShowsSearch'
import {Loading} from '../../../visual'
import {Blank} from '../../../visual/Blank'
import {ChildrenOnNoEffectiveClientSelected} from '../ChildrenOnNoEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {useGetLibraryShows} from './graphql'
import {LibraryShowDetailProvider} from './LibraryShowDetailProvider'
import {ShowDetailContent} from './ShowDetailContent'
import {ShowListItem} from './ShowListItem'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  showMore: {
    paddingTop: theme.spacing(1)
  },
  showMoreButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  downArrow: {
    marginRight: theme.spacing(1)
  }
}))

export const LibraryShowList: React.FC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [filter, setFilter] = useState<ShowsFilterInput>(
    DEFAULT_SHOWS_FILTER_INPUT
  )
  const {data, fetchMore, loading, isLoadingMore, error} =
    useGetLibraryShows(filter)
  const [previewedShowId, setPreviewedShowId] = useState(0)

  const resetPreviewedShowId = useCallback(() => {
    setPreviewedShowId(0)
  }, [])
  const history = useHistory()

  const handleAddClicked = useCallback(
    () => history.push(routeTo.admin.library.add()),
    [history]
  )

  const fabClasses = useFabClasses()

  return (
    <ChildrenOnNoEffectiveClientSelected>
      <PageWithHeaderTemplate
        header={
          <PrimaryHeader
            title={t('Library')}
            search={
              <ShowsSearch
                onFilterChange={setFilter}
                location={ShowsSearchLocation.Library}
              />
            }
          />
        }
      >
        <CenteredLayout>
          <RenderOnData
            {...{loading, error, data}}
            ignoreLoadingIfData
            dataCondition={(data: LibraryShowsQuery | undefined) =>
              Array.isArray(data?.libraryShows.items)
            }
            errorMessage={t<string>('Could not load library shows.')}
          >
            {(data: LibraryShowsQuery) => {
              const {items} = data.libraryShows
              return items.length > 0 ? (
                <CenteredLayoutListWrapper>
                  <Paper variant="outlined">
                    {items.map((show) => (
                      <ShowListItem
                        getEditRoute={routeTo.admin.library.edit}
                        key={show.id}
                        show={show}
                        previewShow={setPreviewedShowId}
                      />
                    ))}
                  </Paper>
                  {P([PermissionCode.CreateLibraryShow]) && (
                    <CreateFab
                      classes={fabClasses}
                      onClick={handleAddClicked}
                    />
                  )}
                  <Grid
                    container
                    justifyContent="center"
                    className={classes.showMore}
                  >
                    {(loading || isLoadingMore) && <Loading />}
                    {!loading &&
                      !isLoadingMore &&
                      data.libraryShows.pagination.hasMore && (
                        <Button
                          color="primary"
                          className={classes.showMoreButton}
                          onClick={fetchMore}
                        >
                          <ExpandMoreIcon className={classes.downArrow} />
                          {t('Show more')}
                        </Button>
                      )}
                  </Grid>
                </CenteredLayoutListWrapper>
              ) : (
                <Blank
                  title={t('No library shows to show.')}
                  actions={
                    P([PermissionCode.CreateLibraryShow]) && (
                      <SaveButton
                        startIcon={<AddIcon />}
                        onClick={handleAddClicked}
                      >
                        {t('Create')}
                      </SaveButton>
                    )
                  }
                />
              )
            }}
          </RenderOnData>
        </CenteredLayout>

        <DrawerWithDebouncedId
          id={previewedShowId}
          onClose={resetPreviewedShowId}
        >
          {({debouncedId, closeDrawer}) => (
            <div className={classes.root}>
              <LibraryShowDetailProvider
                showId={debouncedId}
                onClose={closeDrawer}
              >
                {(libraryShow) => (
                  <ShowDetailContent
                    onPrimaryButtonClick={(id) => {
                      history.push(routeTo.admin.library.edit(id))
                    }}
                    onClose={closeDrawer}
                    show={libraryShow}
                    title={t('Library show details')}
                  />
                )}
              </LibraryShowDetailProvider>
            </div>
          )}
        </DrawerWithDebouncedId>
      </PageWithHeaderTemplate>
    </ChildrenOnNoEffectiveClientSelected>
  )
}
