import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {VouchersFilterInput} from '../../../../__generated__/schema'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {VouchersList} from './VouchersList'
import {DEFAULT_VOUCHERS_FILTER_INPUT, VouchersSearch} from './VouchersSearch'

export const VouchersPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] = useState<VouchersFilterInput>(
    DEFAULT_VOUCHERS_FILTER_INPUT
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Vouchers')}
          search={<VouchersSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <VouchersList searchFilter={searchFilter} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
