import {TextField} from '@mui/material'
import {OutlinedTextFieldProps} from '@mui/material/TextField'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../theme'

import {FormFieldHelper} from '../common'

const useStyles = makeStyles<Theme>(() => ({
  input: {
    width: '100%'
  }
}))

export interface IFormInputProps
  extends Omit<OutlinedTextFieldProps, 'variant'> {
  inputRef: React.Ref<any>
  label?: string
  name: string
  type?: string
  disabled?: boolean
  placeholder?: string
  showRequiredLabel?: boolean
  multiline?: boolean
  rows?: number
  note?: string
  maxLength?: number
  hasError?: boolean
  cypressId?: string
}

/**
 * @deprecated: Prefer UncontrolledFormTextInput
 */
export const FormInput: React.FC<IFormInputProps> = ({
  inputRef,
  label,
  name,
  note,
  showRequiredLabel,
  placeholder,
  type = 'text',
  maxLength,
  hasError,
  cypressId,
  ...rest
}: IFormInputProps) => {
  const classes = useStyles()

  return (
    <>
      <TextField
        {...{inputRef, label, name, type, placeholder, ...rest}}
        error={hasError}
        className={classes.input}
        variant="outlined"
        InputLabelProps={{
          ...rest.InputLabelProps,
          // ensures that label is fixed
          // prevents "react-hook-form" + "TextField" bug
          // https://github.com/react-hook-form/react-hook-form/issues/220
          shrink: true
        }}
        inputProps={{
          maxLength,
          'cypress-id': cypressId
        }}
      />
      <FormFieldHelper required={showRequiredLabel} note={note} />
    </>
  )
}
