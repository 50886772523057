import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {Button} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ProductMode,
  ProductQuery
} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useProductParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {VerticalDivider} from '../../../../common/VerticalDivider'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {useGetProduct} from '../graphql'
import {UpdateProductDrawer} from '../UpdateProductDrawer'
import {BarcodesSection} from './BarcodesSection'
import {EcommerceSection} from './EcommerceSection'
import {GeneralSection} from './GeneralSection'
import {PricesSection} from './PricesSection'
import {PricingDrawer} from './PricingDrawer'
import {ProductAvailabilityDrawer} from './ProductAvailabilityDrawer'
import {ProductGroupsSection} from './ProductGroupsSection'
import {UpdateWarehouseProductDrawer} from './UpdateWarehouseProductDrawer'
import {WarehousesSection} from './WarehousesSection'

const useItems = (isWarehousesSectionAvailable: boolean) => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    prices: {
      id: 'prices',
      label: t('Prices')
    },
    ecommerce: {
      id: 'ecommerce',
      label: t('Ecommerce')
    },
    ...(isWarehousesSectionAvailable
      ? {
          warehouses: {
            id: 'warehouses',
            label: t('Warehouses')
          }
        }
      : {}),
    productGroups: {
      id: 'productGroups',
      label: t('Product groups')
    },
    barcodes: {
      id: 'barcodes',
      label: t('Barcodes')
    }
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  sections: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

export const ProductDetail: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {productId} = useProductParams()
  const {data, loading, error, refetch} = useGetProduct(productId)
  const history = useHistory()
  const [productName, setProductName] = useState<string>(t<string>('Product'))
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.products.index()),
    [history]
  )
  useEffect(() => {
    if (data?.product) {
      setProductName(data.product.name)
    }
  }, [data?.product])
  const items = useItems(
    data?.product.mode === ProductMode.WarehouseProduct &&
      P([PermissionCode.ReadWarehouses])
  )
  const classes = useStyles()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.products.detail(productId)),
    [history, productId]
  )
  const handlePricingDrawerExited = useCallback(() => {
    history.push(routeTo.admin.products.detail(productId))
    refetch()
  }, [history, productId, refetch])
  const handleEditButtonClick = useCallback(
    () => history.push(routeTo.admin.products.edit(productId)),
    [history, productId]
  )
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={productName}
          hasArrowBackIcon
          onLeftActionClick={handleArrowBackClick}
        />
      }
      footer={
        <Footer>
          <Button variant="text" color="primary" startIcon={<DeleteIcon />}>
            {t('Delete')}
          </Button>
          {P([PermissionCode.UpdateProduct]) && (
            <>
              <VerticalDivider />
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditButtonClick}
              >
                {t('Edit')}
              </Button>
            </>
          )}
        </Footer>
      }
    >
      <RenderOnData<ProductQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading product')}
        dataCondition={(data) => Boolean(data.product)}
        ignoreLoadingIfData
      >
        {({product}) => (
          <CenteredLayout>
            <SingleSideNavigationList items={items} />
            <div className={classes.sections}>
              <GeneralSection id={items.general.id} product={product} />
              <PricesSection id={items.prices.id} product={product} />
              <EcommerceSection id={items.ecommerce.id} product={product} />
              {items.warehouses && (
                <WarehousesSection
                  id={items.warehouses.id}
                  title={items.warehouses.label}
                  product={product}
                />
              )}
              <ProductGroupsSection
                id={items.productGroups.id}
                label={items.productGroups.label}
                product={product}
                refetchProduct={refetch}
              />
              <BarcodesSection
                id={items.barcodes.id}
                product={product}
                onRefetch={refetch}
              />
            </div>
          </CenteredLayout>
        )}
      </RenderOnData>
      {P([PermissionCode.UpsertProductAvailability]) && (
        <Route path={routeTo.admin.products.availability(':productId')} exact>
          <ProductAvailabilityDrawer onExited={handleExited} />
        </Route>
      )}
      {P([PermissionCode.UpdateProduct, PermissionCode.ReadProduct]) && (
        <Route path={routeTo.admin.products.edit(':productId')} exact>
          <UpdateProductDrawer onExited={handleExited} />
        </Route>
      )}
      {P([
        PermissionCode.UpdateWarehouseProduct,
        PermissionCode.ReadWarehouseProduct
      ]) && (
        <Route
          path={routeTo.admin.products.editWarehouseProduct(
            ':productId',
            ':warehouseProductId'
          )}
          exact
        >
          <UpdateWarehouseProductDrawer onExited={handleExited} />
        </Route>
      )}
      <Route
        path={routeTo.admin.products.pricing(':productId', ':divisionId')}
        exact
      >
        <PricingDrawer onExited={handlePricingDrawerExited} />
      </Route>
    </PageWithHeaderAndFooterTemplate>
  )
}
