import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Currency} from '../__generated__/schema'

import {useLocale} from '../components/context/locale'
import {CurrencySignPosition} from '../types'
import {formatShortenedPrice} from '../utils/formatting'
import {useRequireEffectiveClient} from './requireEffectiveClient'

export type TranslatedCurrency =
  | Currency.Eur
  | Currency.Czk
  | Currency.Usd
  | Currency.Huf
  | Currency.Pln
  | Currency.Gbp

export const useTranslatePrice = (defaultCurrency: Currency) => {
  const {t} = useTranslation()

  return (value: number, currency?: Currency) =>
    t('formattedPrice', {
      price: {currency: currency || defaultCurrency, value}
    })
}

export const useTranslateEffectiveClientPrice = () => {
  const {t} = useTranslation()
  const effectiveClient = useRequireEffectiveClient()

  return useCallback(
    (value: number) =>
      t<string>('formattedPrice', {
        price: {currency: effectiveClient.currency, value}
      }),
    [effectiveClient.currency, t]
  )
}

export const useTranslateCurrencyName = () => {
  const {t} = useTranslation()
  const translatedCurrencies: Record<TranslatedCurrency, string> = {
    [Currency.Eur]: t<string>('Currency->Eur'),
    [Currency.Czk]: t<string>('Currency->Czk'),
    [Currency.Usd]: t<string>('Currency->Usd'),
    [Currency.Huf]: t<string>('Currency->Huf'),
    [Currency.Pln]: t<string>('Currency->Pln'),
    [Currency.Gbp]: t<string>('Currency->Gbp')
  }
  return (currency: TranslatedCurrency) => translatedCurrencies[currency]
}

export const useTranslateCurrencySign = () => {
  const {t} = useTranslation()
  return (currency: Currency) => t('currencySign', {currencySign: currency})
}

export const useTranslatedEffectiveClientCurrencySign = () => {
  const translateCurrencySign = useTranslateCurrencySign()
  const effectiveClient = useRequireEffectiveClient()

  return translateCurrencySign(effectiveClient.currency)
}

export const useGetCurrencySignPosition = (currency: Currency) => {
  const {localeCode} = useLocale()

  const formattedPriceParts = Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency
  }).formatToParts(0)
  const currencySignIndex = formattedPriceParts.findIndex(
    (part) => part.type === 'currency'
  )

  return currencySignIndex === 0
    ? CurrencySignPosition.START
    : CurrencySignPosition.END
}

export const useTranslateEffectiveClientShortenedPrice = () => {
  const effectiveClient = useRequireEffectiveClient()
  const currencySignPosition = useGetCurrencySignPosition(
    effectiveClient.currency
  )
  const translateCurrencySign = useTranslateCurrencySign()
  return (value: number) =>
    formatShortenedPrice(
      value,
      translateCurrencySign(effectiveClient.currency),
      currencySignPosition
    )
}
