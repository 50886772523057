import {Box, Divider, Grid, Typography} from '@mui/material'
import cn from 'classnames'
import React from 'react'

export const InfoLabel = ({
  children
}: {
  children: React.ReactNode | Array<React.ReactNode>
}) => {
  return (
    <Typography variant="caption" color="textSecondary">
      {children}
    </Typography>
  )
}

export const HighlightedInfoLabel = ({
  children
}: {
  children: React.ReactNode | Array<React.ReactNode>
}) => {
  return (
    <Typography variant="caption" color="textSecondary" component="div">
      <Box fontWeight="fontWeightBold">{children}</Box>
    </Typography>
  )
}

interface ISelectInputRowProps {
  children: React.ReactNode
  label: string
  message: string
  className?: string
}

export const SelectInputRow: React.FC<ISelectInputRowProps> = ({
  children,
  label,
  message,
  className
}: ISelectInputRowProps) => {
  return (
    <>
      <Grid container alignItems="center" className={cn(className)}>
        <Grid item xs={6}>
          <Grid container direction="column" justifyContent="center">
            <Typography variant="caption" component="div">
              <Box fontWeight="fontWeightBold">{label}</Box>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {children}
        </Grid>
      </Grid>
      <Divider />
    </>
  )
}
