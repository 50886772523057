import DeleteIcon from '@mui/icons-material/Delete'
import {IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ECommercePaymentMethodsQuery,
  PaymentGatewayEnvironment,
  PermissionCode
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {
  useTranslatePaymentMethodType,
  useTranslatePaymentServiceProvider
} from '../../../../hooks/translatePaymentMethodType'
import {useEnsurePermissions} from '../../../../utils/auth'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useBooleanFormatter,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {COLOR_CONF} from '../../../constants'
import {CreateEcommercePaymentMethodDrawer} from './CreateEcommercePaymentMethodDrawer'
import {useTranslateGoPayPaymentInstrument} from './defaultGopayPaymentInstroment'
import {DeletePaymentMethodConfirmationDialog} from './DeletePaymentMethodConfirmationDialog'
import {
  useDeleteECommercePaymentMethod,
  useEcommercePaymentMethods
} from './graphql'
import {PaymentMethodsTableBlank} from './PaymentMethodsTableBlank'
import {useTranslatePaymentGatewayEnvironment} from './translatePaymentGatewayEnvironment'

const EnvironmentRenderer = ({
  environment
}: {
  environment: PaymentGatewayEnvironment | undefined
}) => {
  const translatePaymentGatewayEnvironment =
    useTranslatePaymentGatewayEnvironment()
  return environment ? (
    <EntityStateChip
      isDotHidden
      label={translatePaymentGatewayEnvironment(environment)}
      colorConf={
        environment === PaymentGatewayEnvironment.Production
          ? COLOR_CONF.GREEN
          : COLOR_CONF.YELLOW
      }
    />
  ) : null
}

const IconCellRenderer = ({id}: {id: number}) => {
  const {t} = useTranslation()
  const {
    state: isOpen,
    setTrue: setOpened,
    setFalse: setClosed
  } = useBooleanState(false)
  const deleteECommercePaymentMethod = useDeleteECommercePaymentMethod()
  const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
    useMutationAssistanceHooks()
  const handleDeleteClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteECommercePaymentMethod({id})
      addInfoNotification(t('Payment method deleted successfully.'))
      setClosed()
    } catch (e) {
      defaultErrorHandler(e, t('Deletion of payment method failed.'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteECommercePaymentMethod,
    id,
    setClosed,
    setShowBackdrop,
    t
  ])
  return (
    <>
      <IconButton sx={{width: 48, height: 48}} onClick={setOpened}>
        <DeleteIcon />
      </IconButton>
      <DeletePaymentMethodConfirmationDialog
        isOpen={isOpen}
        onCancel={setClosed}
        onConfirm={handleDeleteClick}
      />
    </>
  )
}

export const EcommercePaymentMethodsTable: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    setTrue: openDrawer,
    setFalse: closeDrawer,
    state: isDrawerOpened
  } = useBooleanState(false)
  const {data, loading, error} = useEcommercePaymentMethods()
  const translatePaymentMethodType = useTranslatePaymentMethodType()
  const translatePaymentServiceProvider = useTranslatePaymentServiceProvider()
  const translateInstrument = useTranslateGoPayPaymentInstrument()
  const booleanFormatter = useBooleanFormatter()
  const dateTimeFormatter = useDateTimeFormatter()
  const fabClasses = useFabClasses()
  const hasCreatePaymentMethodPermission = P([
    PermissionCode.CreateECommercePaymentMethod
  ])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 250
      },
      {
        headerName: t('Value'),
        field: 'value',
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Currency'),
        field: 'currency',
        minWidth: 100
      },
      {
        headerName: t('Type'),
        field: 'type',
        valueFormatter: (params) => translatePaymentMethodType(params.value),
        minWidth: 150
      },
      {
        headerName: t('Service provider'),
        field: 'serviceProvider',
        valueFormatter: (params) =>
          translatePaymentServiceProvider(params.value),
        minWidth: 150
      },
      {
        headerName: t('Environment'),
        field: 'environment',
        valueGetter: (params) =>
          params.row.paymentServiceProviderConfig?.environment,
        minWidth: 150,
        renderCell: function renderer(
          params: GridRenderCellParams<{
            value: PaymentGatewayEnvironment | undefined
          }>
        ) {
          return <EnvironmentRenderer environment={params.value} />
        }
      },
      {
        headerName: t('Group'),
        field: 'groupName',
        valueGetter: (params) => params.row.group?.name,
        minWidth: 150
      },
      {
        headerName: t('Default instrument'),
        field: 'defaultPaymentInstrument',
        valueGetter: (params) =>
          params.row.paymentServiceProviderConfig?.defaultPaymentInstrument,
        valueFormatter: (params) =>
          params.value ? translateInstrument(params.value) : '',
        minWidth: 150
      },
      {
        headerName: t('Cashback'),
        field: 'possibleCashdeskDisbursement',
        valueFormatter: booleanFormatter,
        minWidth: 100
      },
      {
        headerName: t('Reduces bill total'),
        field: 'reducesBillTotal',
        valueFormatter: booleanFormatter,
        minWidth: 150
      },
      {
        headerName: t('Has denomination'),
        field: 'hasDenomination',
        valueFormatter: booleanFormatter,
        minWidth: 150
      },
      {
        headerName: t('Available for refunds'),
        field: 'isAvailableForRefunds',
        valueFormatter: booleanFormatter,
        minWidth: 150
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdByName',
        minWidth: 250
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Updated by'),
        field: 'updatedByName',
        minWidth: 250
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [
      booleanFormatter,
      dateTimeFormatter,
      t,
      translateInstrument,
      translatePaymentMethodType,
      translatePaymentServiceProvider
    ]
  )
  return (
    <RenderOnData<ECommercePaymentMethodsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading ecommerce payment methods')}
      dataCondition={(data) => Array.isArray(data.eCommercePaymentMethods)}
      ignoreLoadingIfData
    >
      {({eCommercePaymentMethods}) => (
        <>
          {eCommercePaymentMethods.length > 0 ? (
            <>
              <DataGridTable
                columns={columns}
                rows={eCommercePaymentMethods}
                hideFooter
                pagination={false}
                disableRowSelectionOnClick
                initialState={{
                  pinnedColumns: {left: ['name'], right: ['id']}
                }}
                columnVisibilityModel={{
                  id: P([PermissionCode.DeleteECommercePaymentMethod])
                }}
              />
              {hasCreatePaymentMethodPermission && (
                <CreateFab onClick={openDrawer} classes={fabClasses} />
              )}
            </>
          ) : (
            <PaymentMethodsTableBlank
              hasCreatePaymentMethodPermission={
                hasCreatePaymentMethodPermission
              }
              handleCreateClick={openDrawer}
            />
          )}
          <CreateEcommercePaymentMethodDrawer
            isOpen={isDrawerOpened}
            closeDrawer={closeDrawer}
          />
        </>
      )}
    </RenderOnData>
  )
}
