import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClaimPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useFormatShortGuideName} from '../../../../../hooks/formatUserName'
import {useTranslateAgeClassificationAbbreviation} from '../../../../../hooks/translateAgeClassification'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../hooks/useGetUserLocaleTranslation'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {
  isProductItemPropertiesFragment,
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../types'

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'grid',
    gap: theme.spacing(0, 1),
    padding: theme.spacing(2),
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto auto'
  },
  info: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center'
  },
  price: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    textAlign: 'right'
  }
}))

interface IItemsHeaderProps {
  claim: ClaimPropertiesFragment
}

export const ItemsHeader: React.FC<IItemsHeaderProps> = ({
  claim
}: IItemsHeaderProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDate, formatTime, formatDateNumeric} = useDateTimeFormatters()
  const translateShowFormat = useTranslateShowFormatAbbreviation()
  const translateSoundMixCode = useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateAgeClassificationCode =
    useTranslateAgeClassificationAbbreviation()
  const translateVersionCode = useTranslateShowVersionAbbreviation()
  const formatShortGuideName = useFormatShortGuideName()
  const history = useHistory()
  const classes = useStyles()
  const handleButtonClick = useCallback(() => {
    history.replace(routeTo.admin.claims.cartInfo(claim.sale.cart.id))
  }, [claim.sale.cart.id, history])
  const {event, sale, tourTimeSlot} = claim
  const ticketItems = (sale.items || []).filter(isTicketItemPropertiesFragment)
  const productItems = (sale.items || []).filter(
    isProductItemPropertiesFragment
  )
  const tourItems = (sale.items || []).filter(isTourItemPropertiesFragment)
  const isTicketItem = ticketItems.length > 0
  const isTourItem = tourItems.length > 0
  return (
    <div className={classes.content}>
      <div className={classes.info}>
        <Typography variant="subtitle2">
          {isTicketItem && event
            ? t('Cart #{{id}} • {{eventName}}', {
                id: sale.cart.id,
                eventName: getUserLocaleTranslation(event.names)
              })
            : isTourItem && tourTimeSlot
            ? t('Cart #{{id}} • {{tourName}}', {
                id: sale.cart.id,
                tourName: tourTimeSlot.tour.name
              })
            : t('Cart #{{id}} • Products', {id: sale.cart.id})}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {isTicketItem && event
            ? [
                formatDate(new Date(event.startsAt)),
                formatTime(new Date(event.startsAt)),
                event.formatCode && translateShowFormat(event.formatCode),
                event.versionCode && translateVersionCode(event.versionCode),
                event.soundMixCode && translateSoundMixCode(event.soundMixCode),
                event.ageClassificationCode &&
                  translateAgeClassificationCode(event.ageClassificationCode)
              ]
                .filter(Boolean)
                .join(' • ')
            : isTourItem && tourTimeSlot
            ? [
                formatDateNumeric(new Date(tourTimeSlot.startsAt)),
                formatTime(new Date(tourTimeSlot.startsAt)),
                tourTimeSlot.versionCode &&
                  translateShowVersionAbbreviation(tourTimeSlot.versionCode),
                tourTimeSlot.ageClassificationCode &&
                  translateAgeClassificationAbbreviation(
                    tourTimeSlot.ageClassificationCode
                  ),
                tourTimeSlot.guide && formatShortGuideName(tourTimeSlot.guide)
              ]
                .filter(Boolean)
                .join(' • ')
            : productItems[0]?.division.name}
        </Typography>
      </div>
      <div className={classes.price}>
        <Typography variant="body2">
          {translateEffectiveClientPrice(claim.price)}
        </Typography>
        <Typography variant="overline" color="textSecondary">
          {isTicketItem || isTourItem
            ? t('{{count}} ticket', {count: claim.itemsCount})
            : t('{{count}} product', {count: claim.itemsCount})}
        </Typography>
      </div>
      {P([PermissionCode.ReadCart]) && (
        <IconButton className={classes.iconButton} onClick={handleButtonClick}>
          <OpenInNewIcon color="primary" />
        </IconButton>
      )}
    </div>
  )
}
