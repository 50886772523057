import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {isNil} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {EnabledDiscountPropertiesFragment} from '../../../../__generated__/schema'
import {useFormatDiscountValue} from '../../../../hooks/formatDiscountValue'
import {Theme} from '../../../../theme'
import {SaveButton} from '../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../common/DialogTitleWithCloseButton'
import {OutlinedInputWithCancelAdornment} from '../../../common/OutlinedInputWithCancelAdornment'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogActionsRoot: {
    padding: theme.spacing(1, 3)
  },
  about: {
    display: 'grid',
    gridTemplateAreas: `
      "title        price"
      "quantities  quantities"
      "description  description"
    `,
    paddingTop: theme.spacing(2)
  },
  aboutDescription: {
    gridArea: 'description'
  },
  operationsBox: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  capacityIndicator: {
    paddingRight: theme.spacing(1)
  },
  quantities: {
    gridArea: 'quantities',
    display: 'flex'
  },
  quantityInput: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    minWidth: 216
  }
}))

interface IEditDiscountQuantityDialogProps {
  onClose: () => void
  maxQuantity: number
  initialQuantity: number
  discount: EnabledDiscountPropertiesFragment
  discountCodeId?: number
  incrementDiscountQuantity?: ({
    discountId,
    discountCodeId,
    increment,
    notificationOnSuccess
  }: {
    discountId: number
    discountCodeId?: number
    increment?: number
    notificationOnSuccess?: string
  }) => Promise<void>
  decrementDiscountQuantity?: ({
    discountId,
    discountCodeId,
    decrement,
    notificationOnSuccess
  }: {
    discountId: number
    discountCodeId?: number
    decrement?: number
    notificationOnSuccess?: string
  }) => Promise<void>
  onDiscountWithCodeQuantityChange?: (discountCodeId: number) => void
  remainingDiscountUsageLimitPerOrder?: number
}

export const EditDiscountQuantityDialog: React.FC<IEditDiscountQuantityDialogProps> =
  ({
    onClose,
    maxQuantity,
    initialQuantity,
    discount,
    discountCodeId,
    incrementDiscountQuantity,
    decrementDiscountQuantity,
    onDiscountWithCodeQuantityChange,
    remainingDiscountUsageLimitPerOrder
  }: IEditDiscountQuantityDialogProps) => {
    const {t} = useTranslation()
    const classes = useStyles()
    const [quantity, setQuantity] = useState<number>(initialQuantity)
    const handleCancelButtonClick = useCallback(() => {
      setQuantity(0)
    }, [])
    const handleQuantityChange = useCallback(
      (e) => {
        const parsedQuantity = parseInt(e.target.value, 10)
        if (!isNaN(parsedQuantity) && parsedQuantity > -1) {
          setQuantity(
            parsedQuantity <= maxQuantity ? parsedQuantity : maxQuantity
          )
        } else {
          setQuantity(0)
        }
      },
      [maxQuantity]
    )
    const handleDecrementClick = useCallback(() => {
      setQuantity((prevQuantity) =>
        prevQuantity > 0 ? prevQuantity - 1 : prevQuantity
      )
    }, [])

    const handleIncrementClick = useCallback(() => {
      setQuantity((prevQuantity) => prevQuantity + 1)
    }, [])

    const handleSaveClick = useCallback(async () => {
      if (incrementDiscountQuantity && initialQuantity < quantity) {
        await incrementDiscountQuantity({
          discountId: discount.id,
          discountCodeId,
          increment: quantity - initialQuantity,
          notificationOnSuccess: t('{{count}} item was discounted', {
            count: quantity - initialQuantity
          })
        })
        onClose()
        if (onDiscountWithCodeQuantityChange && discountCodeId) {
          await onDiscountWithCodeQuantityChange(discountCodeId)
        }
      }
      if (decrementDiscountQuantity && initialQuantity > quantity) {
        await decrementDiscountQuantity({
          discountId: discount.id,
          discountCodeId,
          decrement: initialQuantity - quantity
        })
        onClose()
      }
    }, [
      decrementDiscountQuantity,
      discount.id,
      discountCodeId,
      incrementDiscountQuantity,
      initialQuantity,
      onClose,
      quantity,
      t,
      onDiscountWithCodeQuantityChange
    ])

    const formatDiscountValue = useFormatDiscountValue(true)

    return (
      <Dialog open onClose={onClose}>
        <DialogTitleWithCloseButton onCloseClick={onClose}>
          {t('Edit discount quantity')}
        </DialogTitleWithCloseButton>
        <Divider />
        <DialogContent>
          <div className={classes.about}>
            <Typography variant="subtitle2">{discount.name}</Typography>
            <Typography variant="body2" align="right">
              {formatDiscountValue(discount)}
            </Typography>
            {(discount.internalDescription ||
              !isNil(discount.maxUsageLimitPerOrder)) && (
              <Typography
                variant="caption"
                className={classes.aboutDescription}
                color="textSecondary"
              >
                {[
                  !isNil(discount.maxUsageLimitPerOrder) &&
                    t('Can be redeemed {{count}} times per order.', {
                      count: remainingDiscountUsageLimitPerOrder
                    }),
                  discount.internalDescription
                ]
                  .filter(Boolean)
                  .join(' ')}
              </Typography>
            )}
          </div>
          <div className={classes.operationsBox}>
            <IconButton
              disabled={quantity < 1}
              color="primary"
              onClick={handleDecrementClick}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
            <OutlinedInputWithCancelAdornment
              inputId="quantity-input"
              label={t('Quantity')}
              inputProps={{value: quantity, onChange: handleQuantityChange}}
              onCancelClick={handleCancelButtonClick}
              className={classes.quantityInput}
            />
            <IconButton
              onClick={handleIncrementClick}
              disabled={quantity >= maxQuantity}
              color="primary"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions
          classes={{
            root: classes.dialogActionsRoot
          }}
        >
          <SaveButton
            onClick={initialQuantity === quantity ? onClose : handleSaveClick}
          />
        </DialogActions>
      </Dialog>
    )
  }
