import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'

import {useSelector} from '..'
import {editorModeSelector} from '../editorMode/selectors'
import {useSelectionActions} from '../selection/actions'
import {
  SET_MODE,
  EditorMode,
  DrawConfig,
  PanConfig,
  ReservationConfig,
  SelectConfig,
  TransformConfig
} from './reducer'

export const useEditorModeActions = () => {
  const dispatch = useDispatch()
  const {unselectAll} = useSelectionActions()
  const {modeConfigs} = useSelector(editorModeSelector)

  const setDrawMode = useCallback(
    (config: DrawConfig) => {
      unselectAll()
      dispatch({type: SET_MODE, payload: {mode: EditorMode.DRAW, config}})
    },
    [dispatch, unselectAll]
  )

  const setPanMode = useCallback(
    (config: PanConfig) => {
      dispatch({type: SET_MODE, payload: {mode: EditorMode.PAN, config}})
    },
    [dispatch]
  )

  const setReservationMode = useCallback(
    (config: ReservationConfig) => {
      dispatch({
        type: SET_MODE,
        payload: {mode: EditorMode.RESERVATION, config}
      })
    },
    [dispatch]
  )

  const setSelectMode = useCallback(
    (config?: SelectConfig) => {
      dispatch({
        type: SET_MODE,
        payload: {
          mode: EditorMode.SELECT,
          config: {mode: modeConfigs.select.mode, ...config}
        }
      })
    },
    [dispatch, modeConfigs.select.mode]
  )

  const setTransformMode = useCallback(
    (config: TransformConfig) => {
      dispatch({type: SET_MODE, payload: {mode: EditorMode.TRANSFORM, config}})
    },
    [dispatch]
  )

  const actions = useMemo(
    () => ({
      setDrawMode,
      setPanMode,
      setReservationMode,
      setSelectMode,
      setTransformMode
    }),
    [
      setDrawMode,
      setPanMode,
      setReservationMode,
      setSelectMode,
      setTransformMode
    ]
  )

  return actions
}
