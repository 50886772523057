import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../theme'

import {Error, Loading} from '../visual'

const useStyles = makeStyles<Theme>(() => ({
  displayNone: {
    display: 'none'
  }
}))

interface IHideOnLoadingOrErrorProps {
  errorMessage: string
  error: any
  loading: boolean
  children: React.ReactNode
}

export const HideOnLoadingOrError: React.FC<IHideOnLoadingOrErrorProps> = ({
  errorMessage,
  error,
  loading,
  children
}: IHideOnLoadingOrErrorProps) => {
  const classes = useStyles()
  return (
    <>
      {loading && <Loading />}
      {!loading && error && <Error error={error} message={errorMessage} />}
      <div
        className={cn({
          [classes.displayNone]: loading || error
        })}
      >
        {children}
      </div>
    </>
  )
}
