import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import {Grid, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {PhotoProps} from 'react-photo-gallery'
import {PRIMARY_100_COLOR, Theme} from '../../../../theme'

const useStyles = makeStyles<
  Theme,
  {
    margin?: string
    height: number
    width: number
    isClickable: boolean
    isSelected: boolean
  }
>((theme) => ({
  root: {
    margin: ({margin}) => margin,
    height: ({height}) => height,
    width: ({width}) => width,
    position: 'relative',
    cursor: ({isClickable}) => (isClickable ? 'pointer' : 'auto'),
    backgroundColor: PRIMARY_100_COLOR
  },
  renderedImage: {
    height: ({height}) => height,
    width: ({width}) => width,
    transform: ({isSelected, width, height}) =>
      isSelected
        ? `translateZ(0px) scale3d(${(100 - (45 / width) * 100) / 100}, ${
            (100 - (45 / height) * 100) / 100
          }, 1)`
        : 'none',
    transition:
      'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
  },
  icon: {
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)'
    }
  },
  contentIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  topActionsWrapper: {
    position: 'absolute',
    padding: theme.spacing(1),
    top: 0,
    left: 0,
    width: '100%'
  },
  bottomActionsWrapper: {
    position: 'absolute',
    padding: theme.spacing(1),
    bottom: 0,
    left: 0,
    width: '100%'
  }
}))

type RenderedImageProps = (
  | {
      isSelected: boolean
      onIsSelectedChange: (src: string) => void
    }
  | {
      isSelected?: never
      onIsSelectedChange?: never
    }
) & {
  photo: PhotoProps
  onDeleteButtonClick?: (id: number) => void
  onZoomButtonClick?: (id: number) => void
  onContentClick?: (id: number) => void
  contentIcon?: React.ReactNode
  customAction?: React.ReactNode
  margin?: string
  imageId: number
}

export const RenderedImage: React.FC<RenderedImageProps> = ({
  photo,
  margin,
  onDeleteButtonClick,
  onZoomButtonClick,
  onContentClick,
  contentIcon,
  customAction,
  imageId,
  isSelected,
  onIsSelectedChange
}: RenderedImageProps) => {
  const classes = useStyles({
    margin,
    width: photo.width,
    height: photo.height,
    isClickable: !!onContentClick,
    isSelected: isSelected || false
  })
  return (
    <div
      className={classes.root}
      onClick={onContentClick ? () => onContentClick(imageId) : undefined}
    >
      <img className={classes.renderedImage} src={photo.src} alt={photo.alt} />
      {onDeleteButtonClick && (
        <Grid
          container
          className={classes.topActionsWrapper}
          alignItems="center"
          justifyContent={onIsSelectedChange ? 'space-between' : 'flex-end'}
        >
          {onIsSelectedChange && (
            <IconButton
              onClick={() => onIsSelectedChange(photo.src)}
              className={classes.icon}
            >
              {isSelected ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
            </IconButton>
          )}
          <IconButton
            className={classes.icon}
            onClick={(e) => {
              e.stopPropagation()
              onDeleteButtonClick(imageId)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
      {contentIcon && (
        <div className={classes.contentIcon}>
          <IconButton className={classes.icon}>{contentIcon}</IconButton>
        </div>
      )}
      {(onZoomButtonClick || customAction) && (
        <Grid
          container
          className={classes.bottomActionsWrapper}
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>{customAction && customAction}</Grid>
          {onZoomButtonClick && (
            <Grid item>
              <IconButton
                onClick={() => onZoomButtonClick(imageId)}
                className={classes.icon}
              >
                <ZoomInIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  )
}
