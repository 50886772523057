import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {Theme} from '../../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  listItem: {
    padding: theme.spacing(1.5, 3),
    borderBottom: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5)
  }
}))

interface IItem extends Pick<ListItemProps, 'disabled' | 'className'> {
  icon?: React.ReactNode
  primaryText: React.ReactNode
  secondaryText?: React.ReactNode
  onClick?: () => void
}

interface IItemsListProps {
  disablePadding?: boolean
  className?: string
  items: IItem[]
}

export const ItemsList: React.FC<IItemsListProps> = ({
  disablePadding,
  className,
  items
}: IItemsListProps) => {
  const classes = useStyles()
  return (
    <List
      disablePadding={disablePadding}
      className={cn(classes.list, className)}
    >
      {items.map((item, index) => (
        <ListItem
          key={`item-${index}`}
          disabled={item.disabled}
          button={!!item.onClick as any}
          onClick={item.onClick}
          className={cn(classes.listItem, item.className)}
        >
          {item.icon && (
            <ListItemAvatar>
              <>{item.icon}</>
            </ListItemAvatar>
          )}
          <ListItemText
            primary={item.primaryText}
            primaryTypographyProps={{variant: 'subtitle2'}}
            secondary={item.secondaryText}
            secondaryTypographyProps={{
              variant: 'caption',
              color: 'textSecondary'
            }}
          />
        </ListItem>
      ))}
    </List>
  )
}
