import {Box, Typography} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentReport,
  PaymentReportDataAggregatedByTransactionType
} from '../../../__generated__/schema'
import {DataGridTable, useEffectiveClientPriceFormatter} from '../DataGridTable'
import {
  commonTableSxProps,
  getCellClassName,
  useTransactionTypeFormatter
} from './utils'

interface ITransactionTypesProps {
  id?: string
  label: string
  paymentReport: Pick<PaymentReport, 'paymentsCount' | 'paymentsAmount'> & {
    dataAggregatedByTransactionType: Pick<
      PaymentReportDataAggregatedByTransactionType,
      'transactionType' | 'paymentsCount' | 'paymentsAmount'
    >[]
  }
}

export const TransactionTypes: React.FC<ITransactionTypesProps> = ({
  id,
  label,
  paymentReport
}: ITransactionTypesProps) => {
  const {t} = useTranslation()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter(false)
  const transactionTypeFormatter = useTransactionTypeFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Transaction type'),
        field: 'transactionType',
        valueFormatter: transactionTypeFormatter,
        flex: 1,
        sortable: false
      },
      {
        headerName: t('Payments count'),
        field: 'paymentsCount',
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false
      },
      {
        headerName: t('Amount'),
        field: 'paymentsAmount',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        flex: 0.5,
        sortable: false,
        cellClassName: getCellClassName
      }
    ],
    [t, transactionTypeFormatter, effectiveClientPriceFormatter]
  )
  const summarizationRow = [
    {
      transactionType: t('Total'),
      paymentsCount: paymentReport.paymentsCount,
      paymentsAmount: paymentReport.paymentsAmount
    }
  ]
  return (
    <Box id={id} sx={commonTableSxProps}>
      <Typography variant="subtitle1" sx={{pb: 2}}>
        {label}
      </Typography>
      <DataGridTable
        columns={columns}
        rows={[
          ...paymentReport.dataAggregatedByTransactionType,
          ...summarizationRow
        ]}
        hideFooter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
        getRowId={(params) => params.transactionType}
      />
    </Box>
  )
}
