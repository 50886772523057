import {ApolloError} from 'apollo-client'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages, Maybe} from '../../../../__generated__/schema'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'

const getDiscountCodeErrorTitle = (error: Maybe<ApolloError>, t: TFunction) => {
  const isOffline = error?.networkError?.message
  const errorMessage = !isOffline ? error?.graphQLErrors[0].message : null
  switch (errorMessage) {
    case ErrorMessages.DiscountCodeIsInactive:
      return t('Discount code inactive')
    case ErrorMessages.DiscountCodeNotFound:
      return t('Discount code not found')
    case ErrorMessages.DiscountCodeIsInvalid:
      return t('Discount code was invalidated')
    case ErrorMessages.DiscountCodeHasExceededUsageLimit:
      return t('Discount code has exceeded usage limit')
    case ErrorMessages.DiscountCodeUsageLimitWasReached:
      return t('Discount code limit was reached')
    case ErrorMessages.DiscountCodeExpired:
      return t('Discount code expired')
    case ErrorMessages.EventDoesntComplyWithDiscountRules:
      return t('Discount not available for this event')
    default:
      return t('Error')
  }
}

const getDiscountCodeErrorMessage = (
  error: Maybe<ApolloError>,
  t: TFunction,
  formatDateTime: (date: Date) => string
) => {
  const isOffline = error?.networkError?.message
  const errorMessage = !isOffline ? error?.graphQLErrors[0].message : null
  const firstGraphqlErrorExtensions = error?.graphQLErrors[0].extensions
  switch (errorMessage) {
    case ErrorMessages.DiscountCodeIsInactive:
      return firstGraphqlErrorExtensions?.activationDate
        ? t(
            'Discount code has not been activated yet. Code will be active from {{date}}.',
            {
              date: formatDateTime(
                new Date(firstGraphqlErrorExtensions.activationDate)
              )
            }
          )
        : t('Discount code has not been activated yet.')
    case ErrorMessages.DiscountCodeNotFound:
      return t('Discount code doesn’t exist or is misspelled.')
    case ErrorMessages.DiscountCodeIsInvalid:
      return firstGraphqlErrorExtensions?.invalidatedAt
        ? t('Discount code was valid until {{date}}.', {
            date: formatDateTime(
              new Date(firstGraphqlErrorExtensions.invalidatedAt)
            )
          })
        : t('Discount code is invalid.')
    case ErrorMessages.DiscountCodeHasExceededUsageLimit:
      return firstGraphqlErrorExtensions?.canBeUsed
        ? t('Discount code can be redeemed only {{count}} time.', {
            count: firstGraphqlErrorExtensions.canBeUsed
          })
        : t('Too many discount code redeems.')
    case ErrorMessages.DiscountCodeUsageLimitWasReached:
      return firstGraphqlErrorExtensions?.invalidatedAt
        ? t(
            'You can’t use this code again. Usage limit reached {{invalidatedAt}}.',
            {
              invalidatedAt: formatDateTime(
                new Date(firstGraphqlErrorExtensions.invalidatedAt)
              )
            }
          )
        : t('You can’t use this code again. Usage limit was reached.')
    case ErrorMessages.DiscountCodeExpired:
      return firstGraphqlErrorExtensions?.expirationDate
        ? t('Discount code was valid until {{date}}.', {
            date: formatDateTime(
              new Date(firstGraphqlErrorExtensions.expirationDate)
            )
          })
        : t('Discount code is invalid.')
    case ErrorMessages.EventDoesntComplyWithDiscountRules:
      return t(
        'Discount code is correct, but discount settings don’t allow to use it for this event.'
      )
    default:
      return t('Error while checking discount code.')
  }
}

interface IDiscountCodeErrorDialogProps {
  error: Maybe<ApolloError>
  onConfirm: () => void
}

export const DiscountCodeErrorDialog: React.FC<IDiscountCodeErrorDialogProps> =
  ({error, onConfirm}: IDiscountCodeErrorDialogProps) => {
    const {t} = useTranslation()
    const {formatDateTime} = useDateTimeFormatters()
    return (
      <ConfirmationDialog
        title={getDiscountCodeErrorTitle(error, t)}
        onConfirm={onConfirm}
        contentText={getDiscountCodeErrorMessage(error, t, formatDateTime)}
        isOpen={error !== null}
        confirmButtonLabel={t('Got it')}
      />
    )
  }
