import {AuditoriumIcon} from '@attendio/shared-components'
import {MenuItem, TextField, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {useTranslateAuditoriumIcon} from '../../hooks/translateAuditoriumIcon'
import {IIconStateValue} from '../../shared/editor/stateTypes'
import {Theme} from '../../theme'
import {useObjectsActions} from '../redux/objects/actions'
import {
  BarIcon,
  CloakroomIcon,
  CoffeeIcon,
  DrinksIcon,
  EmergencyExitIcon,
  EntranceIcon,
  ExitIcon,
  FoodCourtIcon,
  ScreenIcon,
  StageIcon,
  ToiletsIcon
} from '../TopPanel/Icons'
import {usePlaceholderAndValue} from './placeholderAndValue'

const items = [
  {
    value: AuditoriumIcon.Toilets,
    icon: <ToiletsIcon />
  },
  {
    value: AuditoriumIcon.Stage,
    icon: <StageIcon />
  },
  {
    value: AuditoriumIcon.Screen,
    icon: <ScreenIcon />
  },
  {
    value: AuditoriumIcon.Entrance,
    icon: <EntranceIcon />
  },
  {
    value: AuditoriumIcon.Exit,
    icon: <ExitIcon />
  },
  {
    value: AuditoriumIcon.EmergencyExit,
    icon: <EmergencyExitIcon />
  },
  {
    value: AuditoriumIcon.Cloakroom,
    icon: <CloakroomIcon />
  },
  {
    value: AuditoriumIcon.Drinks,
    icon: <DrinksIcon />
  },
  {
    value: AuditoriumIcon.Bar,
    icon: <BarIcon />
  },
  {
    value: AuditoriumIcon.Coffee,
    icon: <CoffeeIcon />
  },
  {
    value: AuditoriumIcon.FoodCourt,
    icon: <FoodCourtIcon />
  }
]

const useStyles = makeStyles<Theme, {placeholder?: string}>((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  selectRoot: ({placeholder}) =>
    placeholder
      ? {
          '& span::after': {
            content: `"${placeholder}"`,
            opacity: 0.42
          }
        }
      : {}
}))

interface IIconSelectProps {
  icons: IIconStateValue[]
}

export const IconSelect: React.FC<IIconSelectProps> = ({
  icons
}: IIconSelectProps) => {
  const {t} = useTranslation()
  const translateAuditoriumIcon = useTranslateAuditoriumIcon()
  const {placeholder, value} = usePlaceholderAndValue({
    selectedObjects: icons,
    iteratee: 'config.icon'
  })
  const {updateObjects} = useObjectsActions()
  const classes = useStyles({placeholder})
  return (
    <TextField
      select
      label={t('Icon')}
      variant="outlined"
      fullWidth
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        updateObjects(
          icons.map(
            <T extends IIconStateValue>(object: T): T => ({
              ...object,
              config: {
                ...object.config,
                icon: e.target.value
              }
            })
          )
        )
      }}
      value={value || ''}
      SelectProps={{
        classes: {
          select: classes.selectRoot
        }
      }}
    >
      {items.map(({value, icon}) => (
        <MenuItem key={value} value={value}>
          <div className={classes.menuItem}>
            {icon}
            <Typography>{translateAuditoriumIcon(value)}</Typography>
          </div>
        </MenuItem>
      ))}
    </TextField>
  )
}
