import SettingsIcon from '@mui/icons-material/Settings'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {routeTo} from '../../../../utils/routes'
import {HeaderActionType} from '../../../common/header/types'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PrimaryHeader} from '../Header'

export const Venues: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const handleOnSettingsIconClick = useCallback(() => {
    history.push(routeTo.admin.checkTickets.advancedSettings())
  }, [history])
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Check venues tickets')}
          rightActions={[
            {
              Icon: SettingsIcon,
              onClick: handleOnSettingsIconClick,
              label: t('Settings'),
              type: HeaderActionType.IconButton,
              cypressId: 'check-tickets-settings-icon'
            }
          ]}
          isDatetimeEnabled
        />
      }
    >
      venues
    </PageWithHeaderTemplate>
  )
}
