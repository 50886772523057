import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ClearIcon from '@mui/icons-material/Clear'
import {Box} from '@mui/material'
import {
  csCZ,
  DataGridPro,
  DataGridProProps,
  enUS,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridPaginationModel,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  huHU,
  skSK
} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import {isNil} from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CartState,
  DiscountType,
  LocaleCode,
  Pagination,
  VoucherTransactionIntentType
} from '../../__generated__/schema'
import {useTranslateCartState} from '../../hooks/cartState'
import {useTranslateCountry} from '../../hooks/countries'
import {useFormatDiscountValue} from '../../hooks/formatDiscountValue'
import {useFormatUserName} from '../../hooks/formatUserName'
import {useTranslateSellingChannel} from '../../hooks/sellingChannel'
import {
  useTranslateAgeClassification,
  useTranslateAgeClassificationAbbreviation
} from '../../hooks/translateAgeClassification'
import {useTranslateBoolean} from '../../hooks/translateBoolean'
import {useTranslateEffectiveClientPrice} from '../../hooks/translateCurrencies'
import {
  useTranslateShowFormat,
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMix,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersion,
  useTranslateShowVersionAbbreviation
} from '../../hooks/translateDistributions'
import {useTranslateTransactionType} from '../../hooks/translateTransactionType'
import {useTranslateUnit} from '../../hooks/translateUnit'
import {isUserFieldsFragment} from '../../utils/common'
import {useDateTimeFormatters} from '../../utils/formatting'
import {cartStateColors, COLOR_CONF} from '../constants'
import {useLocale} from '../context/locale'
import {useFormatDecimalToLocaleString} from '../pages/admin/utils'
import {EntityStateChip} from './EntityState'
import {Tooltip} from './Tooltip'

const useDataGridLocaleText = () => {
  const {localeCode} = useLocale()
  switch (localeCode) {
    case LocaleCode.Sk:
      return skSK.components.MuiDataGrid.defaultProps.localeText
    case LocaleCode.Cs:
      return csCZ.components.MuiDataGrid.defaultProps.localeText
    case LocaleCode.Hu:
      return huHU.components.MuiDataGrid.defaultProps.localeText
    case LocaleCode.En:
    default:
      return enUS.components.MuiDataGrid.defaultProps.localeText
  }
}

export const CartStateCellRenderer = ({value}: {value?: CartState}) => {
  const translateCartState = useTranslateCartState()
  return (
    <>
      {value && (
        <EntityStateChip
          isDotHidden
          colorConf={cartStateColors[value]}
          label={translateCartState(value)}
        />
      )}
    </>
  )
}

export const getIsNegativeNumberOrPinnedRow = (
  params: GridCellParams<any, number>
) => {
  if (!isNil(params.value) && params.rowNode.type === 'pinnedRow') {
    return clsx({bold: true, amount: {negative: params.value < 0}})
  }
  if (!isNil(params.value)) {
    return clsx('amount', {negative: params.value < 0})
  }
  if (params.rowNode.type === 'pinnedRow') {
    return clsx({bold: true})
  }
  return ''
}

export const PriceCellRenderer = ({
  params,
  formatNull = true
}: {
  params: GridRenderCellParams
  formatNull?: boolean
}) => {
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <>
      {formatNull
        ? translateEffectiveClientPrice(params.value)
        : typeof params.value === 'number'
        ? translateEffectiveClientPrice(params.value)
        : translateEffectiveClientPrice(params.value)}
    </>
  )
}

export const VoucherTransactionAmountCellRenderer = ({
  amount,
  type
}: {
  amount: number
  type: VoucherTransactionIntentType
}) => {
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (
    <Box
      sx={{
        color:
          type === VoucherTransactionIntentType.Debit ? 'error.main' : 'inherit'
      }}
    >
      {type === VoucherTransactionIntentType.Debit
        ? translateEffectiveClientPrice(-amount)
        : translateEffectiveClientPrice(amount)}
    </Box>
  )
}

export const useDiscountValueFormatter = (startsWithMinus: boolean = false) => {
  const formatDiscountValue = useFormatDiscountValue(startsWithMinus)
  return (params: GridValueFormatterParams, discountType?: DiscountType) =>
    params.value && discountType
      ? formatDiscountValue({value: params.value, type: discountType})
      : ''
}

export const BooleanStateRenderer = ({value}: {value: boolean}) => {
  const {t} = useTranslation()
  return (
    <EntityStateChip
      label={value ? t('Enabled') : t('Disabled')}
      colorConf={value ? COLOR_CONF.GREEN : COLOR_CONF.GRAY}
      isDotHidden
    />
  )
}

export const BooleanIconCellRenderer = ({value}: {value: boolean}) => {
  const {t} = useTranslation()
  return value ? (
    <Tooltip title={t('Enabled')}>
      <CheckCircleIcon sx={{color: COLOR_CONF.GREEN.color}} />
    </Tooltip>
  ) : (
    <Tooltip title={t('Disabled')}>
      <ClearIcon sx={{color: COLOR_CONF.GRAY.color}} />
    </Tooltip>
  )
}

export const useDateTimeFormatter = () => {
  const {formatDateTime} = useDateTimeFormatters()
  return (params: GridValueFormatterParams) =>
    params.value ? formatDateTime(new Date(params.value)) : ''
}

export const useDateNumericFormatter = () => {
  const {formatDateNumeric} = useDateTimeFormatters()
  return (params: GridValueFormatterParams) =>
    params.value ? formatDateNumeric(new Date(params.value)) : ''
}

export const useDateTimeNumericWithSecondsFormatter = () => {
  const {formatDateTimeWithSeconds} = useDateTimeFormatters()
  return (params: GridValueFormatterParams) =>
    params.value ? formatDateTimeWithSeconds(new Date(params.value)) : ''
}

export const useDateTimeNumericWithMilliSecondsFormatter = () => {
  const {formatDateTimeWithMilliseconds} = useDateTimeFormatters()
  return (params: GridValueFormatterParams) =>
    params.value ? formatDateTimeWithMilliseconds(new Date(params.value)) : ''
}

export const useSellingChannelFormatter = () => {
  const translateSellingChannel = useTranslateSellingChannel()
  return (params: GridValueFormatterParams) =>
    params.value ? translateSellingChannel(params.value) : ''
}

export const useEffectiveClientPriceFormatter = (
  formatNull: boolean = true
) => {
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  return (params: GridValueFormatterParams) =>
    formatNull
      ? translateEffectiveClientPrice(params.value)
      : typeof params.value === 'number'
      ? translateEffectiveClientPrice(params.value)
      : ''
}

export const useUnitFormatter = () => {
  const translateUnit = useTranslateUnit()
  return (params: GridValueFormatterParams) =>
    params.value && translateUnit(params.value)
}

export const usePercentageFormatter = (fractionDigits: number = 2) => {
  const {localeCode} = useLocale()
  return (params: GridValueFormatterParams) =>
    typeof params.value === 'number'
      ? `${params.value.toLocaleString(localeCode, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        })} %`
      : params.value
}

export const useUserNameFormatter = (swap?: boolean, hideLead?: boolean) => {
  const formatUserName = useFormatUserName(swap, hideLead)
  return (params: GridValueFormatterParams) =>
    isUserFieldsFragment(params.value) ? formatUserName(params.value) : ''
}

export const useMadeByNameFormatter =
  (hideLead?: boolean) => (params: GridValueFormatterParams) =>
    params.value === 'Attendio'
      ? 'Entradio'
      : params.value === 'Lead' && hideLead
      ? ''
      : params.value

export const useVersionCodeFormatter = () => {
  const translateShowVersion = useTranslateShowVersion()
  return (params: GridValueFormatterParams) =>
    params.value ? translateShowVersion(params.value) : ''
}

export const useVersionCodeAbbreviationFormatter = () => {
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  return (params: GridValueFormatterParams) =>
    params.value ? translateShowVersionAbbreviation(params.value) : ''
}

export const useAgeClassificationFormatter = () => {
  const translateAgeClassification = useTranslateAgeClassification()
  return (params: GridValueFormatterParams) =>
    params.value ? translateAgeClassification(params.value) : ''
}

export const useAgeClassificationAbbreviationFormatter = () => {
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  return (params: GridValueFormatterParams) =>
    params.value ? translateAgeClassificationAbbreviation(params.value) : ''
}

export const useShowFormatFormatter = () => {
  const translateShowFormat = useTranslateShowFormat()
  return (params: GridValueFormatterParams) =>
    params.value ? translateShowFormat(params.value) : ''
}

export const useShowFormatAbbreviationFormatter = () => {
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  return (params: GridValueFormatterParams) =>
    params.value ? translateShowFormatAbbreviation(params.value) : ''
}

export const useSoundMixFormatter = () => {
  const translateShowSoundMix = useTranslateShowSoundMix()
  return (params: GridValueFormatterParams) =>
    params.value ? translateShowSoundMix(params.value) : ''
}

export const useSoundMixAbbreviationFormatter = () => {
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  return (params: GridValueFormatterParams) =>
    params.value ? translateShowSoundMixAbbreviation(params.value) : ''
}

export const useGetTranslatedValueFormatter = () => {
  const {localeCode} = useLocale()
  return (params: GridValueFormatterParams) =>
    params.value
      ? params.value[localeCode] ||
        params.value[LocaleCode.En] ||
        Object.values(params.value).filter(Boolean)[0] ||
        ''
      : ''
}

export const useTranslatedValueGetter = () => {
  const {localeCode} = useLocale()
  return (params: GridValueGetterParams) =>
    params.value
      ? params.value[localeCode] ||
        params.value[LocaleCode.En] ||
        Object.values(params.value).filter(Boolean)[0] ||
        ''
      : ''
}

export const useBooleanFormatter = () => {
  const translateBoolean = useTranslateBoolean()
  return useCallback(
    (params: GridValueFormatterParams) =>
      translateBoolean(
        typeof params.value === 'string'
          ? params.value === 'true'
          : params.value
      ),
    [translateBoolean]
  )
}

export const useTranslateCountryFormatter = () => {
  const translateCountry = useTranslateCountry()
  return (params: GridValueFormatterParams) =>
    params.value ? translateCountry(params.value) : ''
}

export const useDecimalFormatter = (fractionDigits: number = 2) => {
  const {localeCode} = useLocale()
  return (params: GridValueFormatterParams | GridValueGetterParams) =>
    !isNil(params.value)
      ? parseFloat(params.value).toLocaleString(localeCode, {
          minimumFractionDigits: fractionDigits
        })
      : ''
}

export const useDateMonthYearFormatter = () => {
  const {formatMonthYear} = useDateTimeFormatters()
  return (params: GridValueFormatterParams) =>
    params.value ? formatMonthYear(new Date(params.value)) : ''
}

export const replaceDotWithCommaFormatter = (
  params: GridValueFormatterParams
) =>
  typeof params.value === 'number' ? String(params.value).replace('.', ',') : ''

export const DecimalCellRenderer = ({
  params,
  fractionDigits
}: {
  params: GridRenderCellParams
  fractionDigits?: number
}) => {
  const formatDecimalToLocaleString = useFormatDecimalToLocaleString({
    fractionDigits
  })
  return (
    <>
      {typeof params.value === 'number'
        ? formatDecimalToLocaleString(params.value)
        : ''}
    </>
  )
}

export const useTransactionTypeFormatter = () => {
  const translateTransactionType = useTranslateTransactionType()
  return (params: GridValueFormatterParams) =>
    params.value ? translateTransactionType(params.value) : ''
}

const INITIAL_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 30
}

export const useDataGridPagination = (
  initialPaginationModel: GridPaginationModel = INITIAL_PAGINATION_MODEL
) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    initialPaginationModel
  )
  const resetPaginationModel = useCallback(() => {
    setPaginationModel(initialPaginationModel)
  }, [initialPaginationModel])

  const getDataGridPaginationProps: (
    pagination?: Pick<Pagination, 'totalRowsCount'>
  ) => Pick<
    DataGridProProps,
    | 'rowCount'
    | 'paginationModel'
    | 'paginationMode'
    | 'onPaginationModelChange'
    | 'pageSizeOptions'
  > = useCallback(
    (pagination) => ({
      rowCount: pagination?.totalRowsCount || 0,
      paginationModel,
      paginationMode: 'server',
      pageSizeOptions: [initialPaginationModel.pageSize],
      onPaginationModelChange: setPaginationModel
    }),
    [initialPaginationModel.pageSize, paginationModel]
  )
  return {
    paginationInput: {
      offset: paginationModel.pageSize * paginationModel.page,
      limit: paginationModel.pageSize
    },
    resetPaginationModel,
    getDataGridPaginationProps
  }
}

export const useSingleClickEditing = () => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
  const onCellClick = useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return
      }
      if (!event.currentTarget.contains(event.target as Element)) {
        return
      }
      setCellModesModel((prevModel) => {
        return {
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: {mode: GridCellModes.View}
                }),
                {}
              )
            }),
            {}
          ),
          [params.id]: {
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({...acc, [field]: {mode: GridCellModes.View}}),
              {}
            ),
            [params.field]: {mode: GridCellModes.Edit}
          }
        }
      })
    },
    []
  )
  const onCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => setCellModesModel(newModel),
    []
  )

  return {cellModesModel, onCellModesModelChange, onCellClick}
}

export const DataGridTable: React.FC<DataGridProProps> = (
  props: DataGridProProps
) => {
  const localeText = useDataGridLocaleText()
  return (
    <DataGridPro
      pagination
      disableAutosize
      autosizeOnMount={false}
      {...props}
      localeText={{...localeText, ...props.localeText}}
    />
  )
}
