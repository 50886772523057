import {GridColDef} from '@mui/x-data-grid-pro'
import {
  DataGridTableColumnAlign,
  DataGridTableColumnType,
  DataGridTableData
} from '../../../../__generated__/schema'

const columnsWithSummary = [
  'amount',
  'surplus',
  'paymentMethodTypeAmounts.cash',
  'paymentMethodTypeAmounts.card',
  'paymentMethodTypeAmounts.wireTransfer',
  'paymentMethodTypeAmounts.voucher',
  'paymentMethodTypeAmounts.paymentGateway'
]

const isDataGridTableColumnAlign = (
  align: any
): align is DataGridTableColumnAlign =>
  Object.values(DataGridTableColumnAlign).includes(align)

const isDataGridTableColumnType = (
  type: any
): type is DataGridTableColumnType =>
  Object.values(DataGridTableColumnType).includes(type)

export const transformGridColDefToDataGridTableData = (
  columns: GridColDef[],
  extended: Boolean = false
): DataGridTableData['columns'] =>
  columns
    .filter(({field}) => field !== 'arrow')
    .map((column) => ({
      align: isDataGridTableColumnAlign(column.align)
        ? column.align
        : undefined,
      headerName: column.headerName,
      field: column.field,
      headerAlign: isDataGridTableColumnAlign(column.headerAlign)
        ? column.headerAlign
        : undefined,
      type: isDataGridTableColumnType(column.type)
        ? column.type
        : DataGridTableColumnType.String,
      hasSummary: columnsWithSummary.includes(column.field),
      merge: !!extended
    }))

export const downloadXlsx = (base64: string, extended: boolean = false) => {
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `payments${extended ? '_extended' : ''}.xlsx`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
