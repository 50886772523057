import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {CartState} from '../../../../__generated__/schema'
import {routeTo} from '../../../../utils/routes'
import {useNotifications} from '../../../context/notifications'
import {useCurrentCart} from './CurrentCartContext'

export const FailedCartController: React.FC = () => {
  const {currentCart, resetCurrentCart} = useCurrentCart()
  const {addErrorWithCustomDialogNotification} = useNotifications()
  const {t} = useTranslation()
  const history = useHistory()
  useEffect(() => {
    if (currentCart?.state === CartState.Failed) {
      addErrorWithCustomDialogNotification({
        title: t('Cart failed due to payment issue'),
        contentText: t(
          'Payment processing issues on the POS terminal resulted in a failed cart. If the customer wishes to retain the selected items, the whole process has to be repeated.'
        ),
        confirmButtonLabel: t('Got it')
      })
      resetCurrentCart()
      history.replace(routeTo.admin.cashDesk.index())
    }
  }, [
    addErrorWithCustomDialogNotification,
    currentCart?.state,
    history,
    resetCurrentCart,
    t
  ])
  return null
}
