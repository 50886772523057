import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {ListItem, ListItemIcon, ListItemText} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../../theme'

import {IMenuCategory} from './menuCategories'

const useStyles = makeStyles<Theme, {isOpened: boolean}>((theme) => ({
  categoryItem: {
    height: 64,
    textTransform: 'uppercase'
  },
  listItem: {
    height: 56
  },
  iconRoot: {
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1)',
    transform: ({isOpened}) => (isOpened ? 'rotate(0deg)' : 'rotate(180deg)')
  },
  listItemIconRoot: {
    minWidth: theme.spacing(6),
    width: theme.spacing(6),
    paddingLeft: theme.spacing(1)
  }
}))

interface ICategoryItemProps {
  category: IMenuCategory
  isOpened: boolean
  onCategoryItemClick(): void
}

export const CategoryItem: React.FC<ICategoryItemProps> = ({
  category,
  isOpened,
  onCategoryItemClick
}: ICategoryItemProps) => {
  const classes = useStyles({isOpened})

  return (
    <ListItem
      classes={{
        root: classes.categoryItem
      }}
      button
      onClick={onCategoryItemClick}
    >
      <ListItemIcon
        classes={{
          root: classes.listItemIconRoot
        }}
      >
        <KeyboardArrowUpIcon
          classes={{
            root: classes.iconRoot
          }}
        />
      </ListItemIcon>
      <ListItemText primary={category.label} />
    </ListItem>
  )
}
