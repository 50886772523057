import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  IconButton,
  TextField
} from '@mui/material'
import Typography from '@mui/material/Typography'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  GetVoucherDetailQuery,
  Lead,
  LeadData,
  PermissionCode,
  SellingChannel
} from '../../../../../__generated__/schema'
import {CopyIcon} from '../../../../../editor/TopPanel/Icons'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {useClaimDetailParams} from '../../../../../utils/pathname'
import {routeTo} from '../../../../../utils/routes'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {CreateMessageDrawer} from '../messageDrawer/CreateMessageDrawer'
import {MessageFormField} from '../messageDrawer/types'

interface IVoucherDialogProps {
  isOpen: boolean
  onClose: () => void
  voucher?: GetVoucherDetailQuery['voucherByCodeOnRetailChannel']
  lead?: (Pick<Lead, 'id'> & {data: Pick<LeadData, 'email'>}) | null
}

export const VoucherDialog: React.FC<IVoucherDialogProps> = ({
  isOpen,
  onClose,
  voucher,
  lead
}: IVoucherDialogProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateMessageDrawerOpen,
    setTrue: openCreateMessageDrawer,
    setFalse: closeCreateMessageDrawer
  } = useBooleanState(false)
  const history = useHistory()
  const {addInfoNotification} = useMutationAssistanceHooks()
  const {formatDateNumeric} = useDateTimeFormatters()
  const {claimId} = useClaimDetailParams()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const handleDetailButtonClick = useCallback(
    (voucherId: number) => () => {
      history.push(routeTo.admin.vouchers.detail(voucherId))
    },
    [history]
  )
  const handleSendButtonClick = useCallback(() => {
    openCreateMessageDrawer()
    onClose()
  }, [onClose, openCreateMessageDrawer])
  return (
    <>
      {voucher && (
        <Dialog
          open={isOpen}
          scroll="paper"
          maxWidth="xs"
          fullWidth
          sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}
        >
          <DialogTitleWithCloseButton onCloseClick={onClose}>
            {t('Voucher created')}
          </DialogTitleWithCloseButton>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 3
            }}
            dividers
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {t('Activation')}
                </Typography>
                <Typography variant="subtitle1">
                  {voucher.activatedAt
                    ? formatDateNumeric(new Date(voucher.activatedAt))
                    : t('Not activated yet')}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {t('Balance')}
                </Typography>
                <Typography variant="subtitle1">
                  {translateEffectiveClientPrice(voucher.balance)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="caption" color="textSecondary">
                {t('Valid until')}
              </Typography>
              <Typography variant="subtitle1">
                {voucher.expirationDate
                  ? formatDateNumeric(new Date(voucher.expirationDate))
                  : t('Undefined')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="textSecondary">
                {t('Campaing')}
              </Typography>
              <Typography variant="subtitle1">
                {voucher.campaign.name}
              </Typography>
            </Box>
            {(voucher.campaign.retailPaymentMethodId ||
              voucher.campaign.ecommercePaymentMethodId) && (
              <Box>
                <Typography variant="caption" color="textSecondary">
                  {t('Redeem options')}
                </Typography>
                <Typography variant="subtitle1">
                  {[
                    voucher.campaign.retailPaymentMethodId &&
                      translateSellingChannel(SellingChannel.Retail),
                    voucher.campaign.ecommercePaymentMethodId &&
                      translateSellingChannel(SellingChannel.ECommerce)
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </Typography>
              </Box>
            )}
            <TextField
              defaultValue={voucher.code}
              onFocus={(e) => e.target.select()}
              label={t('Voucher code')}
              fullWidth
              disabled
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton
                    onClick={async () => {
                      await navigator.clipboard.writeText(voucher.code)
                      addInfoNotification(t('Voucher code copied to clipboard'))
                    }}
                    color="primary"
                  >
                    <CopyIcon />
                  </IconButton>
                )
              }}
            />
            {P([PermissionCode.ReadVoucherPinCode]) && (
              <TextField
                defaultValue={voucher.pinCode}
                onFocus={(e) => e.target.select()}
                label={t('Ecommerce PIN')}
                fullWidth
                disabled
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <IconButton
                      onClick={async () => {
                        await navigator.clipboard.writeText(voucher.pinCode)
                        addInfoNotification(
                          t('Voucher PIN code copied to clipboard')
                        )
                      }}
                      color="primary"
                    >
                      <CopyIcon />
                    </IconButton>
                  )
                }}
              />
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: 3,
              py: 2,
              gap: 1
            }}
          >
            {lead &&
              lead.data.email &&
              P([
                PermissionCode.SendMessage,
                PermissionCode.ReadVoucherPinCode
              ]) && (
                <Button
                  color="primary"
                  variant="text"
                  startIcon={<SendOutlinedIcon />}
                  onClick={handleSendButtonClick}
                >
                  {t('Send')}
                </Button>
              )}
            <Button
              variant="text"
              color="primary"
              onClick={handleDetailButtonClick(voucher.id)}
            >
              {t('Detail')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {lead && lead.data.email && voucher && (
        <CreateMessageDrawer
          isOpen={isCreateMessageDrawerOpen}
          onClose={closeCreateMessageDrawer}
          leads={[{id: lead.id, contact: lead.data.email}]}
          defaultValues={{
            [MessageFormField.InternalNote]: t(
              'Claim #{{claimId}} notification - voucher code',
              {claimId}
            ),
            [MessageFormField.Subject]: t('Your voucher code is {{code}}', {
              code: voucher.code
            }),
            [MessageFormField.SenderName]: voucher.client.name,
            [MessageFormField.LeadIds]: [lead.id],
            [MessageFormField.Body]: t(
              "Dear customer,\nWe're excited to share that we've created a special voucher exclusively for you! Please keep this message saved for your future redemption.\nVoucher Details:\n- Voucher Code: {{code}}\n- PIN for E-commerce: {{pinCode}}\n- Valid until: {{expirationDate}}\n\nTo redeem this voucher, simply enter the above code and PIN at checkout on our website.\nFor any queries or assistance, feel free to contact us.\n\nBest regards,\n{{clientName}}",
              {
                code: voucher.code,
                pinCode: voucher.pinCode,
                expirationDate: voucher.expirationDate
                  ? formatDateNumeric(new Date(voucher.expirationDate))
                  : t('Not set'),
                clientName: voucher.client.name,
                interpolation: {
                  escapeValue: false
                }
              }
            )
          }}
        />
      )}
    </>
  )
}
