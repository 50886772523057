import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'
import {CheckTourPropertiesFragment} from '../../../../__generated__/schema'

interface IResponsiveTourItemProps {
  tour: CheckTourPropertiesFragment
  onTourClick: (tourId: number) => () => void
}

export const ResponsiveTourItem: React.FC<IResponsiveTourItemProps> = ({
  tour,
  onTourClick
}: IResponsiveTourItemProps) => (
  <Paper
    variant="outlined"
    sx={{
      height: 64,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      p: 1.5
    }}
    onClick={onTourClick(tour.id)}
  >
    <Typography variant="subtitle2">{tour.name}</Typography>
    <Typography variant="overline" color="textSecondary">
      {tour.division.name}
    </Typography>
  </Paper>
)
