import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  CostCenterQuery,
  CostCenterQueryVariables,
  CostCentersQuery,
  CostCentersQueryVariables,
  CreateCostCenterMutation,
  CreateCostCenterMutationVariables,
  UpdateCostCenterMutation,
  UpdateCostCenterMutationVariables
} from '../../../../__generated__/schema'

const GET_COST_CENTERS = gql`
  query costCenters {
    costCenters {
      id
      name
      label
      isActive
    }
  }
`

const GET_COST_CENTER = gql`
  query costCenter($id: Int!) {
    costCenter(id: $id) {
      id
      name
      label
      isActive
    }
  }
`

const CREATE_COST_CENTER = gql`
  mutation createCostCenter($data: CostCenterInput!) {
    createCostCenter(data: $data) {
      id
    }
  }
`

const UPDATE_COST_CENTER = gql`
  mutation updateCostCenter($id: Int!, $data: CostCenterInput!) {
    updateCostCenter(id: $id, data: $data) {
      id
    }
  }
`

export const useGetCostCenters = () =>
  useQuery<CostCentersQuery, CostCentersQueryVariables>(GET_COST_CENTERS, {
    fetchPolicy: 'network-only'
  })

export const useGetCostCenter = (id: number) =>
  useQuery<CostCenterQuery, CostCenterQueryVariables>(GET_COST_CENTER, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

export const useCreateCostCenter = () => {
  const [createCostCenter] =
    useMutation<CreateCostCenterMutation, CreateCostCenterMutationVariables>(
      CREATE_COST_CENTER
    )
  return (variables: CreateCostCenterMutationVariables) =>
    createCostCenter({variables, refetchQueries: [{query: GET_COST_CENTERS}]})
}

export const useUpdateCostCenter = () => {
  const [updateCostCenter] =
    useMutation<UpdateCostCenterMutation, UpdateCostCenterMutationVariables>(
      UPDATE_COST_CENTER
    )
  return (variables: UpdateCostCenterMutationVariables) =>
    updateCostCenter({variables, refetchQueries: [{query: GET_COST_CENTERS}]})
}
