import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentMethodType,
  PaymentServiceProvider,
  RetailPaymentMethodType
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {Theme} from '../../../../theme'
import {usePaymentMethodParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useGetRetailPaymentMethod, useUpdatePaymentMethod} from './graphql'
import {PaymentMethodForm, PaymentMethodFormLocation} from './PaymentMethodForm'
import {IPaymentMethodForm, PaymentMethodField} from './types'

const isRetailPaymentMethodType = (
  type: PaymentMethodType | RetailPaymentMethodType
): type is RetailPaymentMethodType =>
  Object.values(RetailPaymentMethodType).includes(
    type as RetailPaymentMethodType
  )

const useStyles = makeStyles<Theme>({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
})

interface IEditPaymentMethodDrawerProps {
  onExited: () => void
}

export const EditPaymentMethodDrawer: React.FC<IEditPaymentMethodDrawerProps> =
  ({onExited}: IEditPaymentMethodDrawerProps) => {
    const {t} = useTranslation()
    const {paymentMethodId} = usePaymentMethodParams()
    const {data, loading, error} = useGetRetailPaymentMethod(paymentMethodId)
    const updatePaymentMethod = useUpdatePaymentMethod()
    const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      openDrawer()
    }, [openDrawer])
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (form: IPaymentMethodForm) => {
        try {
          setShowBackdrop(true)
          await updatePaymentMethod({
            id: paymentMethodId,
            input: {
              groupId: parseInt(form[PaymentMethodField.GROUP_ID], 10),
              isAvailableForRefunds:
                form[PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]
            }
          })
          addInfoNotification(t('Payment method updated'))
          onExited()
        } catch (e) {
          defaultErrorHandler(e, t('Error while updating payment method'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        defaultErrorHandler,
        onExited,
        paymentMethodId,
        setShowBackdrop,
        t,
        updatePaymentMethod
      ]
    )
    return (
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper
        }}
        SlideProps={{
          onExited
        }}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={error && t('Error while loading retail payment method')}
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update payment method')}
            />
          }
          footer={
            <SaveButton type="submit" form="editPaymentMethodFormId">
              {t('Save')}
            </SaveButton>
          }
        >
          {data?.retailPaymentMethod && (
            <PaymentMethodForm
              location={PaymentMethodFormLocation.EditPayment}
              formId="editPaymentMethodFormId"
              defaultValues={{
                [PaymentMethodField.NAME]: data.retailPaymentMethod.name,
                [PaymentMethodField.TYPE]: isRetailPaymentMethodType(
                  data.retailPaymentMethod.type
                )
                  ? data.retailPaymentMethod.type
                  : RetailPaymentMethodType.Cash,
                [PaymentMethodField.CURRENCY]:
                  data.retailPaymentMethod.currency,
                [PaymentMethodField.HAS_DENOMINATION]:
                  data.retailPaymentMethod.hasDenomination,
                [PaymentMethodField.VALUE]:
                  data.retailPaymentMethod.value.toString(),
                [PaymentMethodField.POSSIBLE_CASHDESK_DISBURSEMENT]:
                  data.retailPaymentMethod.possibleCashdeskDisbursement,
                [PaymentMethodField.REDUCES_BILL_TOTAL]:
                  data.retailPaymentMethod.reducesBillTotal,
                [PaymentMethodField.IS_AVAILABLE_FOR_REFUNDS]:
                  data.retailPaymentMethod.isAvailableForRefunds,
                [PaymentMethodField.GROUP_ID]:
                  data.retailPaymentMethod.group?.id.toString(),
                [PaymentMethodField.IS_IN_HOUSE_VOUCHER]:
                  data.retailPaymentMethod.serviceProvider ===
                  PaymentServiceProvider.InHouseVoucher
              }}
              onSubmit={handleSubmit}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
