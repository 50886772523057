import {MenuItem, TextField} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  TranslatedCurrency,
  useTranslateCurrencyName
} from '../../../../hooks/translateCurrencies'
import {Theme} from '../../../../theme'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {GeneralBlock} from '../../../common/GeneralBlock'

import {supportedCurrencies} from './reducer'
import {CashCounterAction, CashCounterActionType} from './types'

const useStyles = makeStyles<Theme>(() => ({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

interface ICurrencyBlockProps {
  label: string
  blockId: string
  currency: TranslatedCurrency
  dispatch: React.Dispatch<CashCounterAction>
}

export const CurrencyBlock: React.FC<ICurrencyBlockProps> = ({
  label,
  blockId,
  currency,
  dispatch
}: ICurrencyBlockProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const translateCurrencyName = useTranslateCurrencyName()

  const [selectedCurrency, setSelectedCurrency] =
    useState<TranslatedCurrency | null>(null)

  const handleCancelClick = useCallback(() => {
    setSelectedCurrency(null)
  }, [])

  const handleCurrencyChange = useCallback((e: any) => {
    setSelectedCurrency(e.target.value as TranslatedCurrency)
  }, [])

  const handleConfirmButtonClick = useCallback(() => {
    dispatch({
      type: CashCounterActionType.CurrencyChange,
      payload: selectedCurrency!
    })
    setSelectedCurrency(null)
  }, [dispatch, selectedCurrency])
  return (
    <>
      <GeneralBlock label={label} blockId={blockId}>
        <TextField
          fullWidth
          label={t('Currency')}
          select
          value={currency}
          onChange={handleCurrencyChange}
          SelectProps={{
            renderValue: (value) =>
              translateCurrencyName(value as TranslatedCurrency)
          }}
        >
          {supportedCurrencies.map((currency) => (
            <MenuItem
              key={currency}
              value={currency}
              className={classes.menuItem}
            >
              <span>{translateCurrencyName(currency)}</span>
              <span>{currency}</span>
            </MenuItem>
          ))}
        </TextField>
      </GeneralBlock>
      <ConfirmationDialog
        title={t('Change currency?')}
        contentText={t(
          'By changing currency will clear all data filled in form. Would you like to change currency anyway?'
        )}
        confirmButtonLabel={t('Change')}
        isOpen={Boolean(selectedCurrency)}
        onCancel={handleCancelClick}
        onConfirm={handleConfirmButtonClick}
      />
    </>
  )
}
