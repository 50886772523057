import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  WarehouseDocumentItemsQuery,
  WarehouseDocumentItemsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {USER_FIELDS} from '../graphql'

const WAREHOUSE_DOCUMENT_ITEMS = gql`
  ${USER_FIELDS}
  ${PAGINATION_FRAGMENT}
  query WarehouseDocumentItems(
    $filter: WarehouseDocumentItemsFilterInput
    $paginationInput: PaginationInput
  ) {
    warehouseDocumentItems(filter: $filter, paginationInput: $paginationInput) {
      items {
        id
        quantity
        unitPriceVatExcluded
        unitPriceVatIncluded
        priceVatExcluded
        vatRate
        vat
        priceVatIncluded
        note
        warehouseDocument {
          id
          issuedAt
          issuedBy {
            ...UserFields
          }
          type
          source
          division {
            id
            name
          }
          warehouse {
            id
            name
          }
        }
        warehouseProduct {
          id
          product {
            id
            name
            unit
            internalCode
          }
        }
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
`

export const useWarehouseDocumentItems = (
  variables?: WarehouseDocumentItemsQueryVariables
) =>
  useQuery<WarehouseDocumentItemsQuery, WarehouseDocumentItemsQueryVariables>(
    WAREHOUSE_DOCUMENT_ITEMS,
    {variables, fetchPolicy: 'network-only'}
  )
