import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDatePickerError} from '../../hooks/pickerErrors'
import {LocalizationProvider} from '../form/pickers'
import {ValidationError} from '../visual'

interface IDaterangePickerProps {
  dialogTitle: string
  dialogDescription?: string
  startDateLabel?: string
  endDateLabel?: string
  children(openDialog: () => void): void
  onSubmit(startDate: Date, endDate: Date): void
}

export const DaterangePicker: React.FC<IDaterangePickerProps> = ({
  children,
  onSubmit,
  dialogTitle,
  dialogDescription,
  startDateLabel,
  endDateLabel
}: IDaterangePickerProps) => {
  const {t} = useTranslation()
  const [startDate, setStartDate] = useState<null | Dayjs>(null)
  const [endDate, setEndDate] = useState<null | Dayjs>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const closeDialog = useCallback(() => {
    setIsDialogOpen(false)
  }, [])
  const openDialog = useCallback(() => {
    setIsDialogOpen(true)
  }, [])
  const {
    reason: startReason,
    errorMessage: startErrorMessage,
    onError: onStartDateError
  } = useDatePickerError()
  const {
    reason: endReason,
    errorMessage: endErrorMessage,
    onError: onEndDateError
  } = useDatePickerError()
  const submit = useCallback(() => {
    if (startDate && endDate) {
      onSubmit(startDate.startOf('day').toDate(), endDate.endOf('day').toDate())
    }
    closeDialog()
  }, [closeDialog, endDate, onSubmit, startDate])
  return (
    <>
      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth="xl">
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <LocalizationProvider>
            {dialogDescription && (
              <Box paddingBottom={2}>
                <Typography variant="body2">{dialogDescription}</Typography>
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item>
                <DatePicker<Dayjs>
                  label={startDateLabel || t('Start date')}
                  renderInput={(props) => (
                    <>
                      <TextField {...props} />
                      <div>
                        <ValidationError>{startErrorMessage}</ValidationError>
                      </div>
                    </>
                  )}
                  onError={onStartDateError}
                  value={startDate}
                  maxDate={endDate || undefined}
                  onChange={setStartDate}
                />
              </Grid>
              <Grid item>
                <DatePicker<Dayjs>
                  label={endDateLabel || t('End date')}
                  renderInput={(props) => (
                    <>
                      <TextField {...props} />
                      <div>
                        <ValidationError>{endErrorMessage}</ValidationError>
                      </div>
                    </>
                  )}
                  onError={onEndDateError}
                  value={endDate}
                  minDate={startDate || undefined}
                  onChange={setEndDate}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            {t('Close')}
          </Button>
          <Button
            disabled={
              Boolean(startReason || endReason) || !startDate || !endDate
            }
            variant="contained"
            onClick={submit}
            color="primary"
          >
            {t('Apply')}
          </Button>
        </DialogActions>
      </Dialog>
      {children(openDialog)}
    </>
  )
}
