import {useTranslation} from 'react-i18next'
import {ShowVideoSource} from '../__generated__/schema'

export const useTranslateShowVideoSource = () => {
  const {t} = useTranslation()
  const translated: Record<ShowVideoSource, string> = {
    [ShowVideoSource.Youtube]: t('ShowVideoSource->Youtube'),
    [ShowVideoSource.Vimeo]: t('ShowVideoSource->Vimeo')
  }
  return (videoSource: ShowVideoSource) => translated[videoSource]
}
