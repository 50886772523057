import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentMethodPropertiesFragment,
  RetailPaymentMethodsQuery
} from '../../../../../__generated__/schema'

import {RenderOnData} from '../../../../common'
import {useRetailPaymentMethods} from '../../graphql'

interface IDraftCartCoreDataProviderProps {
  children: (
    paymentMethods: PaymentMethodPropertiesFragment[]
  ) => React.ReactElement
}

export const DraftCartCoreDataProvider: React.FC<IDraftCartCoreDataProviderProps> =
  ({children}: IDraftCartCoreDataProviderProps) => {
    const {t} = useTranslation()
    const {data, loading, error} = useRetailPaymentMethods()
    return (
      <RenderOnData<RetailPaymentMethodsQuery>
        data={data}
        loading={loading}
        error={error}
        dataCondition={(data) => Boolean(data.retailPaymentMethods)}
        errorMessage={t<string>('Could not load retail payment methods')}
      >
        {(data: RetailPaymentMethodsQuery) =>
          children(data.retailPaymentMethods)
        }
      </RenderOnData>
    )
  }
