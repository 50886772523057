import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Chip} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {VoucherTransactionIntentState} from '../../../../__generated__/schema'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

export enum StateChipOption {
  AllTransactions = 'allTransactions',
  Succeeded = 'succeeded',
  Pending = 'pending',
  Unsuccessful = 'unsuccessful'
}

export const transformStateChipOptionToStatesFilter = (
  option: StateChipOption
): VoucherTransactionIntentState[] => {
  switch (option) {
    case StateChipOption.Succeeded:
      return [VoucherTransactionIntentState.Succeeded]
    case StateChipOption.Pending:
      return [VoucherTransactionIntentState.Pending]
    case StateChipOption.Unsuccessful:
      return [
        VoucherTransactionIntentState.Failed,
        VoucherTransactionIntentState.Expired,
        VoucherTransactionIntentState.Canceled
      ]
    case StateChipOption.AllTransactions:
    default:
      return Object.values(VoucherTransactionIntentState)
  }
}

const useTranslateStateChipOption = () => {
  const {t} = useTranslation()
  const translated: Record<StateChipOption, string> = {
    [StateChipOption.AllTransactions]: t('All transactions'),
    [StateChipOption.Succeeded]: t('Succeeded'),
    [StateChipOption.Pending]: t('Pending'),
    [StateChipOption.Unsuccessful]: t('Unsuccessful')
  }
  return (state: StateChipOption) => translated[state]
}

interface IStateChipProps {
  state: StateChipOption
  setState: (state: StateChipOption) => void
}

export const StateChip: React.FC<IStateChipProps> = ({
  state,
  setState
}: IStateChipProps) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const translateStateChipOption = useTranslateStateChipOption()
  const handleMenuItemClick = useCallback(
    (option: StateChipOption) => () => {
      setState(option)
      closeMenu()
    },
    [closeMenu, setState]
  )
  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        deleteIcon={<ArrowDropDownIcon />}
        onClick={openMenu}
        onDelete={openMenu}
        label={translateStateChipOption(state)}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {Object.values(StateChipOption).map((option, index) => (
          <MenuItem
            key={`${index}-${option}`}
            label={translateStateChipOption(option)}
            icon={state === option ? <CheckIcon /> : undefined}
            isSelected={state === option}
            sx={{flexFlow: 'row-reverse'}}
            iconSx={{justifyContent: 'center'}}
            onClick={handleMenuItemClick(option)}
          />
        ))}
      </Menu>
    </>
  )
}
