import MapIcon from '@mui/icons-material/Map'
import {ToggleButtonGroup} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {get} from 'lodash'
import React from 'react'
import {
  ApiSeatState,
  AuditoriumPreviewFieldsFragment,
  Scalars,
  TicketItemPropertiesFragment
} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useTranslateZonePlanView} from '../../../../../hooks/translateZonePlanView'
import {Theme} from '../../../../../theme'
import {ZonePlanView} from '../../../../../types'
import {ZoomButton} from '../../../../common/auditoriumLayoutPreview/VerticalZoomPanel'
import {isTicketItem} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {useTicketTypesByUuid} from '../utils'
import {ZoneListItem} from './ZoneListItem'

interface IZoneListViewProps {
  getZoneViewChangeHandler: (zonePlan: ZonePlanView) => () => void
  className?: string
  zones: Scalars['JSON']
  event: AuditoriumPreviewFieldsFragment
  getZoneClickHandler: (uuid: string) => (() => void) | undefined
  onZoneTicketQuantityChange: (args: {
    zoneUuid: string
    decrement?: number
    increment?: number
  }) => void
}
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    height: '100%'
  },
  listWrapper: {
    maxWidth: 800,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  toggleButtonGroupRoot: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    filter:
      'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));'
  }
}))

const getZoneLabelByUuid = (
  event: AuditoriumPreviewFieldsFragment,
  zoneUuid: string
) => get(event, `auditoriumLayout.layout[${zoneUuid}].config.label`)

const getTicketItemsByEventIdFilter =
  (eventId: number) => (ticketItem: TicketItemPropertiesFragment) =>
    ticketItem.eventSeat.event.id === eventId

export const ZoneListView: React.FC<IZoneListViewProps> = ({
  getZoneViewChangeHandler,
  className,
  zones,
  event,
  getZoneClickHandler,
  onZoneTicketQuantityChange
}: IZoneListViewProps) => {
  const translateZonePlanView = useTranslateZonePlanView()
  const classes = useStyles()
  const ticketTypesByUuid = useTicketTypesByUuid({
    auditoriumLayoutPricing: event.auditoriumLayoutPricing.pricing,
    ticketTypes: event.activePricing.ticketTypes
  })
  const translatePrice = useTranslateEffectiveClientPrice()
  const {currentCart} = useCurrentCart()
  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.listWrapper}>
        {Object.entries(zones)
          .sort(([uuidA], [uuidB]) =>
            getZoneLabelByUuid(event, uuidA).localeCompare(
              getZoneLabelByUuid(event, uuidB)
            )
          )
          .map(([uuid, zone]) => {
            const ticketType = ticketTypesByUuid[uuid]!
            const availableCount =
              get(zone, ['states', ApiSeatState.Available]) || 0
            const addedToMyCartCount =
              get(zone, ['states', ApiSeatState.AddedToMyCart]) || 0
            return (
              <ZoneListItem
                key={uuid}
                primaryLabel={getZoneLabelByUuid(event, uuid)}
                secondaryLabel={[
                  ticketType.price
                    ? translatePrice(ticketType.price)
                    : translatePrice(0),
                  ticketType.name,
                  ticketType.description
                ]
                  .filter(Boolean)
                  .join(' • ')}
                isAddButtonDisabled={availableCount === 0}
                isSoldOut={availableCount + addedToMyCartCount === 0}
                onQuantityControllerClick={getZoneClickHandler(uuid)}
                ticketsInCartCount={
                  (currentCart?.items || [])
                    .filter(isTicketItem)
                    .filter(getTicketItemsByEventIdFilter(event.id))
                    .filter((i) => i.eventSeat.uuid === uuid).length
                }
                onDecrementButtonClick={() => {
                  onZoneTicketQuantityChange({
                    zoneUuid: uuid,
                    decrement: 1
                  })
                }}
                onIncrementButtonClick={() => {
                  onZoneTicketQuantityChange({
                    zoneUuid: uuid,
                    increment: 1
                  })
                }}
              />
            )
          })}
      </div>
      <ToggleButtonGroup
        size="small"
        exclusive
        orientation="vertical"
        className={classes.toggleButtonGroupRoot}
      >
        <ZoomButton
          tooltipTitle={translateZonePlanView(ZonePlanView.SeatingPlan)}
          onClick={getZoneViewChangeHandler(ZonePlanView.SeatingPlan)}
          value={1}
        >
          <MapIcon />
        </ZoomButton>
      </ToggleButtonGroup>
    </div>
  )
}
