import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GetAppIcon from '@mui/icons-material/GetApp'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import SendIcon from '@mui/icons-material/Send'
import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Decimal from 'decimal.js'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState,
  PermissionCode,
  TemplateFileType
} from '../../../../../__generated__/schema'
import {useDownloadSalesPdf} from '../../../../../hooks/downloadSalesPdf'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {printToPrinter} from '../../../../../qz'
import {Theme} from '../../../../../theme'
import {IDirectTicketPrintSettings} from '../../../../../types'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  getPublicPdfTicketPrintingTemplateUrl,
  performCompiledTicketPrintingTemplateAction
} from '../../../../../utils/compiledTemplates'
import {routeTo} from '../../../../../utils/routes'

import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {
  EmailDialog,
  EmailDialogField,
  IEmailDialogForm
} from '../../../../common/EmailDialog'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {TicketPrintingGroupButton} from '../../components/TicketPrintingGroupButton'
import {
  useSendReservationConfirmationEmail,
  useSendSaleConfirmationEmail
} from '../../graphql'
import {isTicketItem} from '../../types'
import {useCurrentCart} from '../CurrentCartContext'
import {CoreFooter} from './CoreFooter'

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    gridRow: '1/3',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  doneIconBox: {
    fontSize: 64,
    height: 64,
    marginBottom: theme.spacing(2)
  },
  label: {
    paddingBottom: theme.spacing(2)
  },
  paymentInfoGrid: {
    paddingBottom: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: 'auto auto',
    gap: theme.spacing(0, 1)
  },
  paymentInfoDivider: {
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
    width: 1,
    height: 'auto',
    marginBottom: 4,
    backgroundColor: theme.palette.divider
  },
  positive: {
    color: theme.palette.success.main
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  }
}))

interface IFulFilledCartCoreProps {
  cart: CartPropertiesFragment
  className?: string
}

export const FulFilledCartCore: React.FC<IFulFilledCartCoreProps> = ({
  cart,
  className
}: IFulFilledCartCoreProps) => {
  const {t} = useTranslation()
  const isReservation = cart.state === CartState.Reserved
  const classes = useStyles()
  const translatePrice = useTranslateEffectiveClientPrice()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const history = useHistory()
  const {resetCurrentCart} = useCurrentCart()
  const {
    setTrue: openSendReservationConfirmationDialog,
    setFalse: closeSendReservationConfirmationDialog,
    state: isSendReservationConfirmationDialogOpen
  } = useBooleanState(false)
  const {
    setTrue: openSendSaleConfirmationDialog,
    setFalse: closeSendSaleConfirmationDialog,
    state: isSendSaleConfirmationDialogOpen
  } = useBooleanState(false)
  const sendReservationConfirmationEmail = useSendReservationConfirmationEmail()
  const sendSaleConfirmationEmail = useSendSaleConfirmationEmail()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {P} = useEnsurePermissions()
  const {uuid, hash, reservations, sales} = cart
  const clearCurrentCart = useCallback(
    (e?: BeforeUnloadEvent) => {
      e?.preventDefault()
      resetCurrentCart()
    },
    [resetCurrentCart]
  )
  const handleNextCustomerClick = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.index())
    clearCurrentCart()
  }, [clearCurrentCart, history])
  useEffect(() => {
    window.addEventListener('beforeunload', clearCurrentCart)
    return () => {
      window.removeEventListener('beforeunload', clearCurrentCart)
      clearCurrentCart()
    }
  }, [clearCurrentCart])

  const handleSendReservationConfirmationSubmit = useCallback(
    async (form: IEmailDialogForm) => {
      if (uuid && hash && reservations?.length) {
        try {
          setShowBackdrop(true)
          await sendReservationConfirmationEmail({
            cartUUID: uuid,
            cartHash: hash,
            reservationIds: reservations.map(({id}) => id),
            deliverTo: [form[EmailDialogField.Email]]
          })
          addInfoNotification(
            t('{{count}} reservations were sent to {{email}}', {
              email: form[EmailDialogField.Email],
              count: reservations.length
            })
          )
        } catch (e) {
          defaultErrorHandler(
            e,
            t('Failed to send email', {
              count: reservations.length
            })
          )
        } finally {
          setShowBackdrop(false)
          closeSendReservationConfirmationDialog()
        }
      }
    },
    [
      addInfoNotification,
      setShowBackdrop,
      closeSendReservationConfirmationDialog,
      defaultErrorHandler,
      t,
      sendReservationConfirmationEmail,
      uuid,
      hash,
      reservations
    ]
  )

  const handleSendSaleConfirmationSubmit = useCallback(
    async (form: IEmailDialogForm) => {
      if (uuid && hash && sales?.length) {
        try {
          setShowBackdrop(true)
          await sendSaleConfirmationEmail({
            cartUUID: uuid,
            cartHash: hash,
            saleInputs: sales.map((sale) => ({
              id: sale.id,
              itemIds: sale.items?.filter(isTicketItem).map((item) => item.id)
            })),
            deliverTo: [form[EmailDialogField.Email]]
          })
          addInfoNotification(
            t('{{count}} messages were sent to {{email}}', {
              email: form[EmailDialogField.Email],
              count: sales.length
            })
          )
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while sending sale confirmation email')
          )
        } finally {
          setShowBackdrop(false)
          closeSendSaleConfirmationDialog()
        }
      }
    },
    [
      addInfoNotification,
      closeSendSaleConfirmationDialog,
      defaultErrorHandler,
      hash,
      sales,
      sendSaleConfirmationEmail,
      setShowBackdrop,
      t,
      uuid
    ]
  )

  const handleSendByEmailItemClick = useCallback(
    () => openSendReservationConfirmationDialog(),
    [openSendReservationConfirmationDialog]
  )

  const handleSendSaleConfirmationMenuItemClick = useCallback(() => {
    closeMenu()
    openSendSaleConfirmationDialog()
  }, [closeMenu, openSendSaleConfirmationDialog])

  const downloadSalesPdf = useDownloadSalesPdf()
  const handleDownloadPDFButtonClick = useCallback(async () => {
    if (cart.sales) {
      setShowBackdrop(true)
      await downloadSalesPdf(cart.sales)
      setShowBackdrop(false)
      closeMenu()
    }
  }, [cart.sales, closeMenu, downloadSalesPdf, setShowBackdrop])

  const location = useLocation()
  const [directTicketPrint] = useLocalStorageState<IDirectTicketPrintSettings>(
    LocalStorageKey.DirectTicketPrintSettings,
    {
      enabled: false,
      printer: null
    }
  )
  useEffect(() => {
    if (location.hash) {
      const params = new URLSearchParams(location.hash.slice(1))
      const templateId = new Decimal(params.get('templateId') || '').toNumber()
      const templateFileType = params.get(
        'templateFileType'
      ) as TemplateFileType
      const uuid = params.get('uuid')
      const hash = params.get('hash')
      if (templateId && templateFileType) {
        if (
          directTicketPrint.enabled &&
          directTicketPrint.printer &&
          uuid &&
          hash &&
          templateFileType === TemplateFileType.Pdf
        ) {
          const publicUrl = getPublicPdfTicketPrintingTemplateUrl({
            templateId,
            uuid,
            hash
          })
          printToPrinter(directTicketPrint.printer, publicUrl)
        } else {
          performCompiledTicketPrintingTemplateAction({
            templateFileType,
            templateId,
            cartId: cart.id
          })
        }
      }
      history.replace({
        hash: ''
      })
    }
  }, [
    cart.id,
    directTicketPrint.enabled,
    directTicketPrint.printer,
    history,
    location
  ])

  return (
    <div className={className}>
      <div className={classes.content}>
        <div className={classes.doneIconBox}>
          <DoneIcon fontSize="inherit" />
        </div>
        <Typography variant="h6" className={classes.label}>
          {isReservation
            ? t('Reservation successful')
            : t('Payment successful')}
        </Typography>
        {!isReservation && (
          <div className={classes.paymentInfoGrid}>
            <Typography variant="caption" align="right">
              {t('Paid')}
            </Typography>

            <div className={classes.paymentInfoDivider} />
            <Typography variant="caption">{t('Overpaid')}</Typography>
            <Typography variant="subtitle1" align="right">
              {translatePrice(cart.payment?.amount || 0)}
            </Typography>
            <Typography variant="subtitle1" className={classes.positive}>
              {translatePrice(cart.payment?.surplus || 0)}
            </Typography>
          </div>
        )}
        <div className={classes.actionButtons}>
          {isReservation ? (
            P([PermissionCode.SendReservationConfirmationEmail]) && (
              <CancelButton
                startIcon={<SendIcon />}
                onClick={handleSendByEmailItemClick}
              >
                {t('Send by e-mail')}
              </CancelButton>
            )
          ) : (
            <CancelButton startIcon={<ExpandMoreIcon />} onClick={openMenu}>
              {t('More')}
            </CancelButton>
          )}
          {!isReservation && P([PermissionCode.ReadTemplates]) && (
            <TicketPrintingGroupButton
              variant="outlined"
              icon={<PrintOutlinedIcon />}
              cartId={cart.id}
              uuid={cart.uuid}
              hash={cart.hash}
            />
          )}
        </div>
      </div>
      <CoreFooter>
        <SaveButton onClick={handleNextCustomerClick}>
          {t('Next customer')}
        </SaveButton>
      </CoreFooter>
      <Menu onClose={closeMenu} anchorEl={anchorEl}>
        {isReservation
          ? []
          : [
              ...(P([PermissionCode.SendSaleConfirmationEmail])
                ? [
                    <MenuItem
                      key={4}
                      label={t('Send by e-mail')}
                      icon={<SendIcon />}
                      onClick={handleSendSaleConfirmationMenuItemClick}
                    />,
                    <Divider key={5} />
                  ]
                : []),
              <MenuItem
                key={6}
                label={t('Download PDF')}
                icon={<GetAppIcon />}
                onClick={handleDownloadPDFButtonClick}
              />
            ]}
      </Menu>
      <EmailDialog
        isOpen={
          isReservation
            ? isSendReservationConfirmationDialogOpen
            : isSendSaleConfirmationDialogOpen
        }
        title={isReservation ? t('Send email') : t('Send sale confirmation')}
        description={
          isReservation
            ? t(
                'All reservations will be send to specified email address. Please, have in mind, recipient will receive 1 email for each reservation created in cart.'
              )
            : t(
                'All messages will be send to specified email address. Please, have in mind, recipient will receive 1 email for each sale created in cart.'
              )
        }
        submitButtonLabel={t('Send')}
        submitButtonStartIcon={<SendIcon />}
        inputPlaceholder={t('Type email here')}
        inputLabel={t('Email address')}
        onCancel={
          isReservation
            ? closeSendReservationConfirmationDialog
            : closeSendSaleConfirmationDialog
        }
        onSubmit={
          isReservation
            ? handleSendReservationConfirmationSubmit
            : handleSendSaleConfirmationSubmit
        }
        defaultValues={{
          [EmailDialogField.Email]: cart.lead?.data.email || undefined
        }}
      />
    </div>
  )
}
