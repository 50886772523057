import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ClientFilesQuery} from '../../../../../../__generated__/schema'
import {RenderOnData} from '../../../../../common'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {ChildrenOnEffectiveClientSelected} from '../../../ChildrenOnEffectiveClientSelected'
import {isFileImage} from '../../../components/utils'
import {useClientFiles} from '../../graphql'
import {ICardRowForm} from '../editBlockDrawer/types'
import {CardRowForm} from './CardRowForm'

const ADD_CARD_ROW_FORM_ID = 'addCardRowForm'

interface IAddCardRowDialogProps {
  isOpen: boolean
  onClose: () => void
  onAdd: (data: ICardRowForm) => void
}

export const AddCardRowDialog: React.FC<IAddCardRowDialogProps> = ({
  isOpen,
  onClose,
  onAdd
}: IAddCardRowDialogProps) => {
  const {t} = useTranslation()
  const [skip, setSkip] = useState<boolean>(!isOpen)
  const {data, loading, error} = useClientFiles(skip)
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  return (
    <Dialog open={isOpen} sx={{[`& .${dialogClasses.paper}`]: {width: 360}}}>
      <DialogTitleWithCloseButton onCloseClick={onClose}>
        {t('Add card row')}
      </DialogTitleWithCloseButton>
      <DialogContent sx={{p: 3}} dividers>
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData<ClientFilesQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading client files')}
          >
            {({clientFiles}) => (
              <CardRowForm
                images={clientFiles.filter(({fileName}) =>
                  isFileImage(fileName)
                )}
                formId={ADD_CARD_ROW_FORM_ID}
                onSubmit={onAdd}
              />
            )}
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </DialogContent>
      <DialogActions sx={{px: 3}}>
        <Button
          variant="text"
          color="primary"
          type="submit"
          form={ADD_CARD_ROW_FORM_ID}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
