import {
  InfoOutlined as InfoOutlinedIcon,
  PersonOutline as PersonOutlineIcon
} from '@mui/icons-material/'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  CashDeskEventFragment,
  Currency,
  LocaleCode
} from '../../../../__generated__/schema'
import {ReactComponent as PinOffIcon} from '../../../../assets/pinOff.svg'
import {useTranslateAgeClassificationAbbreviation} from '../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../hooks/translateDistributions'
import {Theme} from '../../../../theme'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {
  CapacityIndicator,
  capacityIndicatorSxProps
} from '../../../common/CapacityIndicator'
import {PinnedEventPick} from '../types'
import {getIsAlmostSoldOut, isCashDeskEventFragment} from './utils'

const useBaseItemStyles = makeStyles<Theme, {isSoldOut: boolean}>((theme) => ({
  root: {
    height: 64,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    cursor: 'pointer'
  },
  timeBox: {
    paddingRight: theme.spacing(3)
  },
  time: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    color: ({isSoldOut}) =>
      isSoldOut ? theme.palette.text.secondary : theme.palette.text.primary
  },
  detailIconBox: {
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5)
  },
  detailIcon: {
    color: theme.palette.text.secondary,
    width: '1em',
    height: '1em',
    fontSize: '1.5rem'
  },
  eventMainBox: {
    minWidth: 0
  },
  eventMainBoxLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    color: ({isSoldOut}) =>
      isSoldOut ? theme.palette.text.secondary : theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  auditorium: {
    color: ({isSoldOut}) =>
      isSoldOut ? theme.palette.text.secondary : theme.palette.text.primary,
    flexShrink: 0,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(1)
  },
  almostSoldOut: {
    color: theme.palette.warning.main,
    backgroundColor: '#FCF3D7'
  },
  soldOut: {
    color: theme.palette.error.main,
    backgroundColor: '#FEE4E2'
  },
  chipBox: {
    flexShrink: 0,
    paddingLeft: theme.spacing(1)
  },
  info: {
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: `solid ${theme.palette.divider} 1px`,
    '&:last-child': {
      borderRight: 0
    }
  },
  chip: {
    ...theme.typography.caption,
    height: 20
  },
  capacityPriceAndChipBox: {
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    columnGap: theme.spacing(2)
  },
  capacityBox: {
    display: 'grid',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min-content, 1fr))'
  },
  startingPrice: {
    lineHeight: '100%'
  }
}))

export interface ICashdeskEventItemProps {
  event: CashDeskEventFragment | PinnedEventPick
  onEventClick?: (eventId: number) => void
  onEventDetailClick?: (eventId: number) => void
  currency: Currency
  clientLocaleCode: LocaleCode
}

export interface IBaseItemProps extends ICashdeskEventItemProps {
  capacityBoxChildren?: React.ReactElement
  DetailIcon: React.FC<{className?: string}>
}

const BaseItem: React.FC<IBaseItemProps> = ({
  event,
  onEventClick,
  onEventDetailClick,
  capacityBoxChildren,
  //currency,
  clientLocaleCode,
  DetailIcon
}: IBaseItemProps) => {
  const isSoldOut = isCashDeskEventFragment(event)
    ? event.availableSeatsCount === 0
    : false
  const classes = useBaseItemStyles({isSoldOut})
  const {formatTime} = useDateTimeFormatters()
  const {t} = useTranslation()
  const isAlmostSoldOut = isCashDeskEventFragment(event)
    ? getIsAlmostSoldOut(event)
    : false

  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  //const translatePrice = useTranslatePrice(currency)
  const eventNameInClientLocale =
    event.names[clientLocaleCode] || Object.values(event.names)[0]
  return (
    <div
      className={classes.root}
      onClick={() => {
        onEventClick?.(event.id)
      }}
    >
      <div className={classes.timeBox}>
        <Typography variant="subtitle2" className={classes.time}>
          {formatTime(new Date(event.startsAt))}
        </Typography>
      </div>
      <div className={classes.eventMainBox}>
        <div className={classes.eventMainBoxLine}>
          <Typography variant="subtitle2" className={classes.title}>
            {eventNameInClientLocale}
          </Typography>
          <Typography variant="caption" className={classes.auditorium}>
            {event.auditorium.name}
          </Typography>
        </div>
        <div className={classes.eventMainBoxLine}>
          <div>
            {event.formatCode && (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.info}
              >
                {translateShowFormatAbbreviation(event.formatCode)}
              </Typography>
            )}
            {event.soundMixCode && (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.info}
              >
                {translateShowSoundMixAbbreviation(event.soundMixCode)}
              </Typography>
            )}
            {event.versionCode && (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.info}
              >
                {translateShowVersionAbbreviation(event.versionCode)}
              </Typography>
            )}
            {event.ageClassificationCode && (
              <Typography
                variant="caption"
                color="textSecondary"
                className={classes.info}
              >
                {translateAgeClassificationAbbreviation(
                  event.ageClassificationCode
                )}
              </Typography>
            )}
            {isCashDeskEventFragment(event) && event.marketingLabel && (
              <Chip size="small" label={event.marketingLabel.name} />
            )}
          </div>
          <div className={classes.capacityPriceAndChipBox}>
            {isSoldOut && (
              <span className={classes.chipBox}>
                <Chip
                  className={cn(classes.chip, classes.soldOut)}
                  size="small"
                  label={t('Sold out')}
                />
              </span>
            )}
            {isAlmostSoldOut && (
              <span className={classes.chipBox}>
                <Chip
                  className={cn(classes.chip, classes.almostSoldOut)}
                  size="small"
                  label={t('Almost sold out')}
                />
              </span>
            )}
            <div className={classes.capacityBox}>{capacityBoxChildren}</div>
            {/*            {!isSoldOut && (
              <Typography variant="overline" className={classes.startingPrice}>
                {t('From {{startingPrice}}', {
                  startingPrice: translatePrice(event.activePricing.minPrice)
                })}
              </Typography>
            )}*/}
          </div>
        </div>
      </div>
      <div className={classes.detailIconBox}>
        {onEventDetailClick ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              onEventDetailClick(event.id)
            }}
          >
            <DetailIcon className={classes.detailIcon} />
          </IconButton>
        ) : null}
      </div>
    </div>
  )
}

export const CashdeskEventItem: React.FC<ICashdeskEventItemProps> = (
  props: ICashdeskEventItemProps
) => {
  const {t} = useTranslation()
  return (
    <BaseItem
      DetailIcon={InfoOutlinedIcon}
      capacityBoxChildren={
        isCashDeskEventFragment(props.event) ? (
          <>
            {props.event.availableSeatsCount > 0 && (
              <CapacityIndicator
                tooltipTitle={t('Available')}
                countLabel={props.event.availableSeatsCount.toString()}
                customIcon={<PersonOutlineIcon fontSize="inherit" />}
              />
            )}
            <CapacityIndicator
              tooltipTitle={t('Reserved')}
              sx={capacityIndicatorSxProps.reservedSpaces}
              countLabel={props.event.reservedSeatsCount.toString()}
            />
            <CapacityIndicator
              tooltipTitle={t('Sold')}
              sx={capacityIndicatorSxProps.soldSpaces}
              countLabel={props.event.soldSeatsCount.toString()}
            />
          </>
        ) : undefined
      }
      {...props}
    />
  )
}

export const PinnedEventItem: React.FC<ICashdeskEventItemProps> = (
  props: ICashdeskEventItemProps
) => <BaseItem {...props} DetailIcon={PinOffIcon} />
