import {Box, Button, Drawer, drawerClasses, Typography} from '@mui/material'
import {uniqBy} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AccessZoneState,
  LightweightAccessZonesQuery,
  LightweightAccessZonesQueryVariables
} from '../../../../../../__generated__/schema'
import {useDebounce} from '../../../../../../hooks/debounce'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../../../utils/pagination'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../../../common'
import {LazyLoadingList} from '../../../../../common/LazyLoadingList'
import {ListItemWithAddCustomButton} from '../../../../../common/ListItemWithCustomButton'
import {OutlinedInputWithCancelAdornment} from '../../../../../common/OutlinedInputWithCancelAdornment'
import {
  LIGHTWEIGHT_ACCESS_ZONES,
  useAssignAccessZoneToAdmissionRateType,
  useRemoveAccessZoneFromAdmissionRateType
} from '../../graphql'

interface IAssignAccessZoneDrawerProps {
  isOpen: boolean
  onClose: () => void
  assignedAccessZoneIds: number[]
  admissionTypeToRateId?: number
  refetch: () => void
}

export const AssignAccessZoneDrawer: React.FC<IAssignAccessZoneDrawerProps> = ({
  isOpen,
  onClose,
  assignedAccessZoneIds,
  admissionTypeToRateId,
  refetch
}: IAssignAccessZoneDrawerProps) => {
  const {t} = useTranslation()
  const assignAccessZoneToAdmissionRateType =
    useAssignAccessZoneToAdmissionRateType()
  const removeAccessZoneFromAdmissionRateType =
    useRemoveAccessZoneFromAdmissionRateType()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const [skip, setSkip] = useState(!isOpen)
  const [search, setSearch] = useState<string>('')
  const [hasText, setHasText] = useState<string>('')
  const {data, loading, error, isLoadingMore, fetchMore} =
    useQueryWithPagination<
      LightweightAccessZonesQuery,
      LightweightAccessZonesQueryVariables
    >(
      LIGHTWEIGHT_ACCESS_ZONES,
      {
        variables: {
          filter: {
            state: AccessZoneState.Active,
            hasText: hasText || undefined
          },
          paginationInput: {offset: 0, limit: 30}
        },
        fetchPolicy: 'network-only',
        skip
      },
      {
        mapPaginationInput: (data) =>
          extractPaginationInput(data.accessZones.pagination),
        updateData: (prevData, fetchMoreData) => ({
          ...fetchMoreData,
          accessZones: {
            ...fetchMoreData.accessZones,
            items: uniqBy(
              [
                ...prevData.accessZones.items,
                ...fetchMoreData.accessZones.items
              ],
              ({id}) => id
            )
          }
        })
      }
    )
  const debouncedSetHasText = useDebounce(setHasText, 400)
  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value.length !== 1) {
        debouncedSetHasText(e.target.value)
      }
      setSearch(e.target.value)
    },
    [debouncedSetHasText]
  )
  const handleCancelClick = useCallback(() => {
    setSearch('')
    setHasText('')
  }, [])
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.accessZones.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.accessZones.pagination.hasMore, fetchMore, isLoadingMore])
  const handleAssignClick = useCallback(
    (id: number) => async () => {
      if (admissionTypeToRateId) {
        try {
          setShowBackdrop(true)
          await assignAccessZoneToAdmissionRateType({
            admissionTypeToRateId,
            accessZoneIds: [id]
          })
          refetch()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while assigning access zone to admission rate type')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      admissionTypeToRateId,
      assignAccessZoneToAdmissionRateType,
      defaultErrorHandler,
      refetch,
      setShowBackdrop,
      t
    ]
  )
  const handleRemoveClick = useCallback(
    (id: number) => async () => {
      if (admissionTypeToRateId) {
        try {
          setShowBackdrop(true)
          await removeAccessZoneFromAdmissionRateType({
            admissionTypeToRateId,
            accessZoneIds: [id]
          })
          refetch()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while removing access zone from admission rate type')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      admissionTypeToRateId,
      defaultErrorHandler,
      refetch,
      removeAccessZoneFromAdmissionRateType,
      setShowBackdrop,
      t
    ]
  )
  useEffect(() => {
    if (isOpen) {
      setSkip(false)
    }
    return () => setSkip(true)
  }, [isOpen])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Access zones')}
          />
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <LazyLoadingList
          isLoadingMore={isLoadingMore}
          onScrolledNearTheEndOfTheList={handleScrolledNearTheEndOfTheLayout}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'common.white',
              py: 2,
              px: 3,
              zIndex: 1,
              borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
            }}
          >
            <OutlinedInputWithCancelAdornment
              onCancelClick={handleCancelClick}
              inputProps={{
                value: search,
                onChange: handleInputChange,
                placeholder: t('Search for access zone'),
                fullWidth: true
              }}
            />
          </Box>
          <RenderOnData<LightweightAccessZonesQuery>
            data={data}
            loading={loading}
            error={error}
            errorMessage={t<string>('Error while loading access zones')}
            dataCondition={(data) => Array.isArray(data.accessZones.items)}
          >
            {({accessZones}) => (
              <Box
                sx={{
                  px: 3,
                  pb: 2,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {accessZones.items.length > 0 ? (
                  accessZones.items.map((item) => (
                    <ListItemWithAddCustomButton
                      key={item.id}
                      button={
                        assignedAccessZoneIds.includes(item.id) ? (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleRemoveClick(item.id)}
                          >
                            {t('Remove')}
                          </Button>
                        ) : (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={handleAssignClick(item.id)}
                          >
                            {t('Assign')}
                          </Button>
                        )
                      }
                      primaryText={item.name}
                      secondaryText={item.description}
                    />
                  ))
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      pt: 2
                    }}
                  >
                    <Typography variant="body2">
                      {t('No access zones found')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </RenderOnData>
        </LazyLoadingList>
      </DrawerTemplate>
    </Drawer>
  )
}
