import {Box, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  TypifiedIconModel,
  TypographyVariant
} from '../../../../../__generated__/schema'
import {
  useIsStringWithMaxLength,
  useIsValidURLWithMailtoAndTel
} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {FormAutocomplete} from '../../../../form/FormAutocomplete'
import {FormSwitchWithLabels} from '../../../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {AddMenuItem, IItemForm, ItemFormField} from '../types'
import {TypifiedIcon} from './TypifiedIcon'
import {useWebsitePageContext} from './WebsitePageContext'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(1.5)
}))

interface IItemFormProps {
  formId: string
  onSubmit: (data: IItemForm) => void
  defaultValues?: Partial<IItemForm>
  type: AddMenuItem
  showVariant?: boolean
  showIcon?: boolean
}

export const ItemForm: React.FC<IItemFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  type,
  showVariant,
  showIcon
}: IItemFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    watch,
    register,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IItemForm>({defaultValues, mode: 'onChange'})
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isValidURLWithMailtoAndTel = useIsValidURLWithMailtoAndTel()
  const {websitePages, selectedWebsitePage} = useWebsitePageContext()
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <input
        type="hidden"
        name={ItemFormField.Type}
        ref={register()}
        defaultValue={type}
      />
      <Typography variant="caption" color="textSecondary">
        {type === AddMenuItem.Page
          ? t(
              "Select a page from the list of pages you've already created. For the label, type a concise and clear name that will be displayed in the navigation menu for visitors. Choose label that accurately reflect the page's purpose or content to ensure an intuitive browsing experience."
            )
          : type === AddMenuItem.ExternalLink
          ? t(
              'When adding a menu item that links to an external website, enter the URL and a descriptive, concise label indicating an external destination. Ensure the label reflects the linked content, aiding seamless navigation.'
            )
          : type === AddMenuItem.Folder
          ? t(
              "When creating a 'folder' in the menu to organize multiple items, ensure you choose a clear and descriptive name that accurately reflects the common theme or purpose of the links it contains. This menu item serves as a container rather than a navigable page, designed solely to expand and reveal a list of included items for streamlined navigation."
            )
          : t(
              "Enter a clear and concise text description for the menu. This non-clickable label helps users understand the menu's structure and organization."
            )}
      </Typography>
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IItemForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ItemFormField.Label}
            key={ItemFormField.Label}
            label={t('Label')}
            validationOptions={{
              validate: isStringWithMaxLength255,
              required: true
            }}
            helperNote={t('Label displayed in menu')}
            fullWidth
            required
          />
        ]}
      />
      {type === AddMenuItem.Page && (
        <InputRow
          nodes={[
            <FormAutocomplete<IItemForm>
              noOptionsText=""
              fullWidth
              disableClearable
              errors={errors}
              label={t('Page')}
              name={ItemFormField.Page}
              key={ItemFormField.Page}
              register={register}
              setValue={setValue}
              watch={watch}
              required
              validationOptions={{required: true}}
              helperNote={t('Select page from list')}
              autocompleteOptions={websitePages
                .filter(
                  (page) => page.localeCode === selectedWebsitePage?.localeCode
                )
                .map((page) => ({
                  value: page.slug === '' ? 'homepage' : page.slug,
                  name: page.title
                }))}
            />
          ]}
        />
      )}
      {type === AddMenuItem.ExternalLink && (
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IItemForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={ItemFormField.Page}
              key={ItemFormField.Page}
              label={t('External URL')}
              validationOptions={{
                validate: isValidURLWithMailtoAndTel,
                required: true
              }}
              helperNote={t('Expected valid URL from external web.')}
              fullWidth
              required
            />
          ]}
        />
      )}
      {showIcon && (
        <InputRow
          nodes={[
            <UncontrolledFormSelect<IItemForm>
              errors={errors}
              watch={watch}
              register={register}
              label={t('Icon')}
              setValue={setValue}
              name={ItemFormField.Icon}
              key={ItemFormField.Icon}
              fullWidth
              hasNoneSelectOption
              selectOptions={Object.values(TypifiedIconModel).reduce(
                (acc, icon) => ({
                  ...acc,
                  [icon]: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5
                      }}
                    >
                      <TypifiedIcon model={icon} />
                      {icon}
                    </Box>
                  )
                }),
                {}
              )}
            />
          ]}
        />
      )}
      {showVariant && (
        <InputRow
          nodes={[
            <FormAutocomplete<IItemForm>
              noOptionsText=""
              fullWidth
              disableClearable
              errors={errors}
              label={t('Variant')}
              name={ItemFormField.Variant}
              key={ItemFormField.Variant}
              register={register}
              setValue={setValue}
              watch={watch}
              helperNote={t('Select variant from list')}
              autocompleteOptions={Object.values(TypographyVariant).map(
                (variant) => ({value: variant, name: variant})
              )}
            />
          ]}
        />
      )}
      {type !== AddMenuItem.Folder && type !== AddMenuItem.Label && (
        <InputRow
          nodes={[
            <FormSwitchWithLabels<IItemForm>
              name={ItemFormField.OpenInNewTab}
              key={ItemFormField.OpenInNewTab}
              primaryLabel={t('Open in new tab?')}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.openInNewTab || false}
              secondaryLabel=""
              enabledText=""
              disabledText=""
            />
          ]}
        />
      )}
    </StyledForm>
  )
}
