import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {Box, Button, Divider, Paper, Typography} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  BaseInventoryCheckFieldsFragment,
  InventoryCheckState,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useFormatUserName} from '../../../../../hooks/formatUserName'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {
  useTranslateInventoryCheckState,
  useTranslateInventoryCheckStateDescription
} from '../../../../../hooks/translateInventoryCheckState'
import {useTranslateInventoryCheckType} from '../../../../../hooks/translateInventoryCheckType'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip} from '../../../../common'
import {
  DividerSeparatedInfoRow,
  SingleInfoColumn
} from '../../../../common/DividerSeparatedInfoRow'
import {inventoryCheckStateColors} from '../../../../constants'

const WarehouseDocumentLink: React.FC<{id: number}> = ({id}: {id: number}) => {
  const handleClick = useCallback(
    () => window.open(routeTo.admin.warehouseDocuments.detail(id), '_blank'),
    [id]
  )
  return (
    <Typography
      variant="subtitle2"
      component="div"
      color="primary"
      onClick={handleClick}
      sx={{cursor: 'pointer', display: 'flex', gap: 0.5, alignItems: 'center'}}
    >
      <OpenInNewIcon />
      {id}
    </Typography>
  )
}

interface IGeneralProps {
  inventoryCheck: BaseInventoryCheckFieldsFragment
  isVatRegistered: boolean
}

export const General: React.FC<IGeneralProps> = ({
  inventoryCheck,
  isVatRegistered
}: IGeneralProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateInventoryCheckState = useTranslateInventoryCheckState()
  const translateInventoryCheckStateDescription =
    useTranslateInventoryCheckStateDescription()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const formatUserName = useFormatUserName()
  const translateInventoryCheckType = useTranslateInventoryCheckType()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const history = useHistory()
  const handleEditClick = useCallback(
    () => history.push(routeTo.admin.inventoryChecks.edit(inventoryCheck.id)),
    [history, inventoryCheck.id]
  )
  const formatDateTimeNumeric = useCallback(
    (date: string) =>
      [formatDateNumeric(new Date(date)), formatTime(new Date(date))].join(
        ', '
      ),
    [formatDateNumeric, formatTime]
  )
  return (
    <Paper variant="outlined">
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6">
            {[t('Inventory check'), inventoryCheck.id].join(' • ')}
          </Typography>
          {P([PermissionCode.UpdateInventoryCheck]) &&
            inventoryCheck.state === InventoryCheckState.Draft && (
              <Button
                variant="text"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEditClick}
              >
                {t('Edit')}
              </Button>
            )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            alignItems: 'baseline',
            gap: 1,
            gridAutoFlow: 'column',
            gridTemplateColumns: 'auto 1fr'
          }}
        >
          <EntityStateChip
            label={translateInventoryCheckState(inventoryCheck.state)}
            colorConf={inventoryCheckStateColors[inventoryCheck.state]}
          />
          <Typography variant="caption" color="textSecondary">
            {translateInventoryCheckStateDescription(inventoryCheck.state)}
          </Typography>
        </Box>
        {inventoryCheck.note && (
          <SingleInfoColumn
            caption={t('Note')}
            value={inventoryCheck.note}
            valueTypographyVariant="body2"
          />
        )}
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Warehouse'),
              value: inventoryCheck.warehouse.name
            },
            {
              caption: t('Division'),
              value: inventoryCheck.division.name
            },
            {
              caption: t('Created at'),
              value: formatDateTimeNumeric(inventoryCheck.createdAt)
            },
            {
              caption: t('Created by'),
              value: formatUserName(inventoryCheck.createdBy)
            },
            ...(inventoryCheck.state === InventoryCheckState.Completed &&
            inventoryCheck.completedAt &&
            inventoryCheck.completedBy
              ? [
                  {
                    caption: t('Completed at'),
                    value: formatDateTimeNumeric(inventoryCheck.completedAt)
                  },
                  {
                    caption: t('Completed by'),
                    value: formatUserName(inventoryCheck.completedBy)
                  }
                ]
              : [
                  {
                    caption: t('Updated at'),
                    value: formatDateTimeNumeric(inventoryCheck.updatedAt)
                  },
                  {
                    caption: t('Updated by'),
                    value: formatUserName(inventoryCheck.updatedBy)
                  }
                ])
          ]}
        />
      </Box>
      <Divider />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, p: 3}}>
        <DividerSeparatedInfoRow
          information={[
            {
              caption: t('Stock on hand as of'),
              value: formatDateNumeric(
                new Date(inventoryCheck.inventoryCheckedTill)
              )
            },
            {
              caption: t('Type'),
              value: translateInventoryCheckType(inventoryCheck.type)
            },
            ...(inventoryCheck.state === InventoryCheckState.Completed
              ? [
                  {
                    caption: t('Inventory variance'),
                    value: isVatRegistered
                      ? translateEffectiveClientPrice(
                          inventoryCheck.inventoryVarianceVatExcluded
                        )
                      : translateEffectiveClientPrice(
                          inventoryCheck.inventoryVarianceVatIncluded
                        )
                  }
                ]
              : []),
            ...(inventoryCheck.incomingWarehouseDocument
              ? [
                  {
                    caption: t('Incoming document'),
                    value: (
                      <WarehouseDocumentLink
                        id={inventoryCheck.incomingWarehouseDocument.id}
                      />
                    )
                  }
                ]
              : []),
            ...(inventoryCheck.outgoingWarehouseDocument
              ? [
                  {
                    caption: t('Outgoing document'),
                    value: (
                      <WarehouseDocumentLink
                        id={inventoryCheck.outgoingWarehouseDocument.id}
                      />
                    )
                  }
                ]
              : [])
          ].filter(Boolean)}
        />
      </Box>
    </Paper>
  )
}
