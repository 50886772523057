import {Button, Divider, MenuItem} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {newAbortSignal} from '../../../../hooks/openCashDrawer'
import {
  useTranslateCashDrawerController,
  useTranslateCashDrawerOpenLocation
} from '../../../../hooks/translateCashDrawer'
import {
  CashDrawerController,
  CashDrawerOpenLocation,
  ICashDrawerSettings
} from '../../../../types'
import {InputBlockWithoutSpacings} from '../../../common'
import {RowWithSelect} from './RowWithSelect'
import {RowWithTextField} from './RowWithTextField'
import {DeviceSettingsAction, DeviceSettingsActionTypes} from './types'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  }
}))

interface ICashDrawerProps {
  blockId: string
  blockLabel: string
  settings: ICashDrawerSettings
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const CashDrawer: React.FC<ICashDrawerProps> = ({
  blockId,
  blockLabel,
  settings,
  dispatch
}: ICashDrawerProps) => {
  const {t} = useTranslation()
  const translateCashDrawerController = useTranslateCashDrawerController()
  const translateCashDrawerOpenLocation = useTranslateCashDrawerOpenLocation()
  const {openLocation, controller, ipAddress} = settings
  const classes = useStyles()
  const isOpenDrawerEnabled =
    openLocation !== CashDrawerOpenLocation.DontOpen &&
    controller === CashDrawerController.VirtuosNetworkController &&
    ipAddress
  const handleOpenDrawerButtonClick = useCallback(() => {
    if (ipAddress) {
      fetch(`https://${ipAddress}`, {signal: newAbortSignal(100)}).catch(
        (e) => e
      )
    }
  }, [ipAddress])
  return (
    <>
      <InputBlockWithoutSpacings
        blockId={blockId}
        header={
          <div className={classes.header}>
            {blockLabel}
            <Button
              variant="text"
              color="primary"
              disabled={!isOpenDrawerEnabled}
              onClick={handleOpenDrawerButtonClick}
            >
              {t('Open drawer')}
            </Button>
          </div>
        }
        headerClassName={classes.fullWidth}
      >
        <RowWithSelect
          primaryLabel={t('Open cash drawer by app')}
          selectProps={{
            value: openLocation,
            onChange: (event) =>
              dispatch({
                type: DeviceSettingsActionTypes.ChangeCashDrawerSettings,
                payload: {
                  ...settings,
                  openLocation: event.target.value as CashDrawerOpenLocation,
                  controller:
                    (event.target.value as CashDrawerOpenLocation) ===
                    CashDrawerOpenLocation.DontOpen
                      ? CashDrawerController.None
                      : settings.controller,
                  ipAddress:
                    (event.target.value as CashDrawerOpenLocation) ===
                    CashDrawerOpenLocation.DontOpen
                      ? null
                      : settings.ipAddress
                }
              }),
            children: Object.values(CashDrawerOpenLocation).map((location) => (
              <MenuItem key={location} value={location}>
                {translateCashDrawerOpenLocation(location)}
              </MenuItem>
            ))
          }}
        />
        <Divider />
        <RowWithSelect
          primaryLabel={t('Connected cash drawer controller')}
          secondaryLabel={t(
            '* Required. Choose supported controller connected to device.'
          )}
          selectProps={{
            value: controller,
            onChange: (event) =>
              dispatch({
                type: DeviceSettingsActionTypes.ChangeCashDrawerSettings,
                payload: {
                  ...settings,
                  controller: event.target.value as CashDrawerController
                }
              }),
            children: Object.values(CashDrawerController).map((controller) => (
              <MenuItem key={controller} value={controller}>
                {translateCashDrawerController(controller)}
              </MenuItem>
            )),
            disabled: openLocation === CashDrawerOpenLocation.DontOpen
          }}
        />
        {controller === CashDrawerController.VirtuosNetworkController && (
          <>
            <Divider />
            <RowWithTextField
              primaryLabel={t('Controller IP address')}
              textFieldProps={{
                fullWidth: true,
                required: true,
                inputProps: {
                  defaultValue: ipAddress || undefined,
                  onChange: (event: ChangeEvent<HTMLInputElement>) =>
                    dispatch({
                      type: DeviceSettingsActionTypes.ChangeCashDrawerSettings,
                      payload: {
                        ...settings,
                        ipAddress: event.target.value || null
                      }
                    })
                }
              }}
            />
          </>
        )}
      </InputBlockWithoutSpacings>
    </>
  )
}
