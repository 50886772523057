import {Box, TextField, TextFieldProps, Typography} from '@mui/material'
import React from 'react'

interface IRowWithTextFieldProps {
  primaryLabel: string
  secondaryLabel?: string
  textFieldProps: TextFieldProps
}

export const RowWithTextField: React.FC<IRowWithTextFieldProps> = ({
  primaryLabel,
  secondaryLabel,
  textFieldProps
}: IRowWithTextFieldProps) => (
  <Box
    sx={{
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: {sx: undefined, md: '1fr 316px'},
      columnGap: 3,
      py: 1,
      px: 3
    }}
  >
    <Box>
      <Typography variant="subtitle2">{primaryLabel}</Typography>
      {secondaryLabel && (
        <Typography variant="caption" color="textSecondary">
          {secondaryLabel}
        </Typography>
      )}
    </Box>
    <TextField fullWidth variant="outlined" {...textFieldProps} />
  </Box>
)
