import {TextField, Typography} from '@mui/material'
import React, {ChangeEvent, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useObjectsActions} from '../redux/objects/actions'
import {ISeatStateValue, IZoneStateValue} from '../redux/objects/types'
import {usePlaceholderAndValue} from './placeholderAndValue'

interface IAttendeeNavigationProps {
  selectedSeatsAndZones: (ISeatStateValue | IZoneStateValue)[]
  className?: string
}

export const AttendeeNavigation: React.FC<IAttendeeNavigationProps> = ({
  className,
  selectedSeatsAndZones
}: IAttendeeNavigationProps) => {
  const {t} = useTranslation()
  const {updateObjects} = useObjectsActions()
  const {placeholder: rowPlaceholder, value: commonRow} =
    usePlaceholderAndValue({
      selectedObjects: selectedSeatsAndZones,
      iteratee: 'config.row'
    })
  const {placeholder: sectionPlaceholder, value: commonSection} =
    usePlaceholderAndValue({
      selectedObjects: selectedSeatsAndZones,
      iteratee: 'config.section'
    })
  const {placeholder: floorPlaceholder, value: commonFloor} =
    usePlaceholderAndValue({
      selectedObjects: selectedSeatsAndZones,
      iteratee: 'config.floor'
    })
  const getSuccessfulChangeHandler = useCallback(
    (field: 'row' | 'section' | 'floor') =>
      (e: ChangeEvent<HTMLInputElement>) => {
        updateObjects(
          selectedSeatsAndZones.map(
            <T extends ISeatStateValue | IZoneStateValue>(object: T): T => ({
              ...object,
              config: {
                ...object.config,
                [field]: e.target.value
              }
            })
          )
        )
      },
    [selectedSeatsAndZones, updateObjects]
  )
  return (
    <div className={className}>
      <Typography variant="subtitle2">{t('Attendee navigation')}</Typography>
      <TextField
        label={t('Row')}
        defaultValue={commonRow}
        placeholder={rowPlaceholder}
        variant="outlined"
        onChange={getSuccessfulChangeHandler('row')}
        fullWidth
      />
      <TextField
        label={t('Section')}
        defaultValue={commonSection}
        placeholder={sectionPlaceholder}
        variant="outlined"
        onChange={getSuccessfulChangeHandler('section')}
        fullWidth
      />
      <TextField
        label={t('Floor')}
        defaultValue={commonFloor}
        placeholder={floorPlaceholder}
        variant="outlined"
        onChange={getSuccessfulChangeHandler('floor')}
        fullWidth
      />
    </div>
  )
}
