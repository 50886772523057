import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '../../../common'

interface IReloadButtonProps {
  onReloadButtonClick: () => void
}

export const ReloadButton: React.FC<IReloadButtonProps> = ({
  onReloadButtonClick
}: IReloadButtonProps) => {
  const {t} = useTranslation()
  return (
    <Tooltip title={t('Reload')}>
      <IconButton onClick={onReloadButtonClick}>
        <RefreshOutlinedIcon color="primary" />
      </IconButton>
    </Tooltip>
  )
}
