import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  RetailPaymentMethodsQuery,
  SellingChannel
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslatePaymentMethodType} from '../../../../hooks/translatePaymentMethodType'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useBooleanFormatter,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {useRetailPaymentMethods} from '../graphql'
import {CreatePaymentMethodDrawer} from './CreatePaymentMethodDrawer'
import {DeletePaymentMethodConfirmationDialog} from './DeletePaymentMethodConfirmationDialog'
import {useDeleteRetailPaymentMethod} from './graphql'
import {PaymentMethodsTableBlank} from './PaymentMethodsTableBlank'

const EditCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.paymentMethods.editRetail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

const DeleteCellRenderer = ({id}: {id: number}) => {
  const {t} = useTranslation()
  const {
    state: isOpen,
    setTrue: setOpened,
    setFalse: setClosed
  } = useBooleanState(false)
  const deleteRetailPaymentMethod = useDeleteRetailPaymentMethod()
  const {addInfoNotification, defaultErrorHandler, setShowBackdrop} =
    useMutationAssistanceHooks()
  const handleDeleteClick = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteRetailPaymentMethod({id})
      addInfoNotification(t('Payment method deleted successfully.'))
      setClosed()
    } catch (error) {
      defaultErrorHandler(error, t('Deletion of payment method failed.'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    defaultErrorHandler,
    deleteRetailPaymentMethod,
    id,
    setClosed,
    setShowBackdrop,
    t
  ])
  return (
    <>
      <IconButton sx={{width: 48, height: 48}} onClick={setOpened}>
        <DeleteIcon />
      </IconButton>
      <DeletePaymentMethodConfirmationDialog
        isOpen={isOpen}
        onCancel={setClosed}
        onConfirm={handleDeleteClick}
      />
    </>
  )
}

export const RetailPaymentMethodsTable: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    setTrue: openDrawer,
    setFalse: closeDrawer,
    state: isDrawerOpened
  } = useBooleanState(false)
  const {data, loading, error} = useRetailPaymentMethods()
  const booleanFormatter = useBooleanFormatter()
  const dateTimeFormatter = useDateTimeFormatter()
  const translatePaymentMethodType = useTranslatePaymentMethodType()
  const fabClasses = useFabClasses()
  const hasCreatePaymentMethodPermission = P([
    PermissionCode.CreateRetailPaymentMethod
  ])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 250
      },
      {
        headerName: t('Value'),
        field: 'value',
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Currency'),
        field: 'currency',
        minWidth: 100
      },
      {
        headerName: t('Type'),
        field: 'type',
        valueFormatter: (params) => translatePaymentMethodType(params.value),
        minWidth: 150
      },
      {
        headerName: t('Group'),
        field: 'groupName',
        valueGetter: (params) => params.row.group?.name,
        minWidth: 150
      },
      {
        headerName: t('Cashback'),
        field: 'possibleCashdeskDisbursement',
        valueFormatter: booleanFormatter,
        minWidth: 100
      },
      {
        headerName: t('Reduces bill total'),
        field: 'reducesBillTotal',
        valueFormatter: booleanFormatter,
        minWidth: 150
      },
      {
        headerName: t('Has denomination'),
        field: 'hasDenomination',
        valueFormatter: booleanFormatter,
        minWidth: 150
      },
      {
        headerName: t('Available for refunds'),
        field: 'isAvailableForRefunds',
        valueFormatter: booleanFormatter,
        minWidth: 150
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdByName',
        minWidth: 250
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Updated by'),
        field: 'updatedByName',
        minWidth: 250
      },
      {
        headerName: '',
        field: 'edit',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <EditCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      },
      {
        headerName: '',
        field: 'delete',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DeleteCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [booleanFormatter, dateTimeFormatter, t, translatePaymentMethodType]
  )
  return (
    <RenderOnData<RetailPaymentMethodsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading retail payment methods')}
      dataCondition={(data) => Array.isArray(data.retailPaymentMethods)}
      ignoreLoadingIfData
    >
      {({retailPaymentMethods}) => (
        <>
          {retailPaymentMethods.length > 0 ? (
            <>
              <DataGridTable
                columns={columns}
                rows={retailPaymentMethods}
                hideFooter
                pagination={false}
                disableRowSelectionOnClick
                initialState={{
                  pinnedColumns: {left: ['name'], right: ['edit', 'delete']}
                }}
                columnVisibilityModel={{
                  edit: P([
                    PermissionCode.ReadRetailPaymentMethod,
                    PermissionCode.UpdatePaymentMethod
                  ]),
                  delete: P([PermissionCode.DeleteRetailPaymentMethod])
                }}
              />
              {hasCreatePaymentMethodPermission && (
                <CreateFab onClick={openDrawer} classes={fabClasses} />
              )}
            </>
          ) : (
            <PaymentMethodsTableBlank
              hasCreatePaymentMethodPermission={
                hasCreatePaymentMethodPermission
              }
              handleCreateClick={openDrawer}
            />
          )}
          <CreatePaymentMethodDrawer
            channel={SellingChannel.Retail}
            closeDrawer={closeDrawer}
            isOpen={isDrawerOpened}
          />
        </>
      )}
    </RenderOnData>
  )
}
