import dayjs, {Dayjs} from 'dayjs'
import {
  DivisionPropertiesFragment,
  EventVenuePropertiesFragment
} from '../../../../../__generated__/schema'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {AddEventProperties} from '../context/addEventProperties'

export enum TicketTypeFormField {
  PROMOTIONS = 'promotions',
  PRICE = 'price',
  TICKET_TYPE_ID = 'ticketTypeId',
  VAT_RATE = 'vatRate'
}

export enum CreateEventFormField {
  DIVISION_ID = 'divisionId',
  AUDITORIUM_LAYOUT_ID = 'auditoriumLayoutId',
  AUDITORIUM_LAYOUT_PRICING_ID = 'auditoriumLayoutPricingId',
  NAME = 'name',
  DROPPED_STARTS_AT = 'droppedStartsAt',
  STARTS_AT = 'startsAt',
  TICKET_TYPES = 'ticketTypes',
  SERVICE_TIME = 'serviceTime'
}

interface ITicketTypes {
  [TicketTypeFormField.PROMOTIONS]: boolean
  [TicketTypeFormField.PRICE]: string
  [TicketTypeFormField.TICKET_TYPE_ID]: string
  [TicketTypeFormField.VAT_RATE]: number
}

export interface ICreateEventForm {
  [CreateEventFormField.DIVISION_ID]: number
  [CreateEventFormField.AUDITORIUM_LAYOUT_ID]: number
  [CreateEventFormField.AUDITORIUM_LAYOUT_PRICING_ID]?: number
  [CreateEventFormField.NAME]: string
  [CreateEventFormField.DROPPED_STARTS_AT]: string
  [CreateEventFormField.STARTS_AT]: Dayjs
  [CreateEventFormField.TICKET_TYPES]: {
    [key: string]: ITicketTypes
  }
  [CreateEventFormField.SERVICE_TIME]: number
}

const getDefaultAuditoriumLayoutId = (
  auditoriumLayouts: CreateEventAuditoriumLayout[]
) => (auditoriumLayouts.length === 1 ? auditoriumLayouts[0].id : undefined)

const getDefaultAuditoriumLayoutPricingId = (
  auditoriumLayouts: CreateEventAuditoriumLayout[]
) =>
  getDefaultAuditoriumLayoutId(auditoriumLayouts) &&
  auditoriumLayouts[0].auditoriumLayoutPricings.length === 1
    ? auditoriumLayouts[0].auditoriumLayoutPricings[0].id
    : undefined

export const createEventFormTicketTypesField = (
  ticketTypes: CreateEventAuditoriumLayout['auditoriumLayoutPricings'][number]['ticketTypes'],
  ticketDefaultVatRate: number
): {
  [ticketTypeId: string]: ITicketTypes
} =>
  ticketTypes.reduce<{
    [ticketTypeId: string]: ITicketTypes
  }>(
    (acc, ticketType) => ({
      ...acc,
      [ticketType.id]: {
        [TicketTypeFormField.TICKET_TYPE_ID]: String(ticketType.id),
        [TicketTypeFormField.PRICE]: ticketType.price
          ? String(ticketType.price)
          : '',
        [TicketTypeFormField.VAT_RATE]: ticketDefaultVatRate,
        [TicketTypeFormField.PROMOTIONS]: false
      }
    }),
    {}
  )

export const useGetCreateEventFormDefaults = ({
  addEventProperties,
  divisions,
  auditoriumLayouts,
  ticketDefaultVatRate,
  defaultDivisionId
}: {
  addEventProperties: AddEventProperties | null
  divisions: DivisionPropertiesFragment[]
  auditoriumLayouts: CreateEventAuditoriumLayout[]
  ticketDefaultVatRate: number
  defaultDivisionId?: number
}): Partial<ICreateEventForm> => {
  const {formatDate} = useDateTimeFormatters()
  return addEventProperties
    ? {
        [CreateEventFormField.NAME]: addEventProperties.title,
        [CreateEventFormField.DROPPED_STARTS_AT]: formatDate(
          new Date(addEventProperties.start)
        ),
        [CreateEventFormField.STARTS_AT]: dayjs(addEventProperties.start),
        [CreateEventFormField.DIVISION_ID]:
          divisions.length === 1 ? divisions[0].id : undefined,
        [CreateEventFormField.AUDITORIUM_LAYOUT_ID]:
          getDefaultAuditoriumLayoutId(auditoriumLayouts),
        [CreateEventFormField.AUDITORIUM_LAYOUT_PRICING_ID]:
          getDefaultAuditoriumLayoutPricingId(auditoriumLayouts),
        [CreateEventFormField.TICKET_TYPES]:
          getDefaultAuditoriumLayoutPricingId(auditoriumLayouts)
            ? createEventFormTicketTypesField(
                auditoriumLayouts[0].auditoriumLayoutPricings[0].ticketTypes,
                ticketDefaultVatRate
              )
            : {},
        [CreateEventFormField.DIVISION_ID]: defaultDivisionId
      }
    : {}
}

export type CreateEventAuditoriumLayout =
  EventVenuePropertiesFragment['auditoriums'][number]['auditoriumLayouts'][number]
