import React, {
  useState,
  createContext,
  ReactNode,
  useContext,
  Dispatch,
  SetStateAction
} from 'react'
import _ from 'lodash'

import {ClickInfoActivity} from '../calendar/types'

export interface DayHeaderClickInfo {
  x: number
  y: number
  date: string
  auditoriumId: number
  activity?: ClickInfoActivity
}

export interface CopyInfo {
  auditoriumId: number
  date: string
  ids: Array<number>
}

interface IDayHeaderContentState {
  clickInfo: DayHeaderClickInfo | null
  copyInfo: CopyInfo | null
}

export type SetDayHeaderContentState = Dispatch<
  SetStateAction<IDayHeaderContentState>
>

const Context = createContext([{}, _.noop] as [
  IDayHeaderContentState,
  SetDayHeaderContentState
])

type Props = {
  children: ReactNode
}

export const DayHeaderContentStateProvider: React.FC<Props> = ({
  children
}: Props) => {
  const dayHeaderContentStateTuple = useState<IDayHeaderContentState>({
    clickInfo: null,
    copyInfo: null
  })

  return (
    <Context.Provider value={dayHeaderContentStateTuple}>
      {children}
    </Context.Provider>
  )
}

export const useDayHeaderContentState = () => useContext(Context)
