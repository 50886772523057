import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ApiGrantsQuery,
  ApiGrantState,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateApiGrantState} from '../../../../hooks/translateApiGrantState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {
  DataGridTable,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {apiGrantStateColors} from '../../../constants'
import {useApiGrants} from './graphql'

const StateRenderer = ({state}: {state: ApiGrantState}) => {
  const translateApiGrantState = useTranslateApiGrantState()
  return (
    <EntityStateChip
      label={translateApiGrantState(state)}
      colorConf={apiGrantStateColors[state]}
      isDotHidden
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.apiGrants.detail(id)),
    [history, id]
  )
  return (
    <IconButton onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

export const ApiGrantsList: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useApiGrants({
    paginationInput: {offset: 0, limit: 300}
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 300
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ApiGrantState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 100
      },
      {
        headerName: t('Expires at'),
        field: 'expiresAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Per minute'),
        field: 'maxRequestsPerMinute',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('Per hour'),
        field: 'maxRequestsPerHour',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 300
      },
      {
        headerName: t('Clients'),
        field: 'clientsCount',
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => params.row.clientsOwningResources.length,
        minWidth: 100
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [dateTimeFormatter, t]
  )
  return (
    <RenderOnData<ApiGrantsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading API grants')}
      dataCondition={(data) => Array.isArray(data.apiGrants.items)}
      ignoreLoadingIfData
    >
      {({apiGrants}) => (
        <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
          <DataGridTable
            columns={columns}
            rows={apiGrants.items}
            pageSizeOptions={[10, 30, 50]}
            initialState={{
              pagination: {paginationModel: {pageSize: 30}},
              pinnedColumns: {left: ['name'], right: ['id']}
            }}
            columnVisibilityModel={{
              id: P([PermissionCode.ReadApiGrant])
            }}
            disableRowSelectionOnClick
            localeText={{noRowsLabel: t('No API grants to show')}}
          />
        </Box>
      )}
    </RenderOnData>
  )
}
