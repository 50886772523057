import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckIcon from '@mui/icons-material/Check'
import {Chip} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {EventState} from '../../../../__generated__/schema'
import {useTranslateEventState} from '../../../../hooks/translateEventState'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'

const useStyles = makeStyles(() => ({
  menuItem: {
    flexFlow: 'row-reverse'
  },
  menuIcon: {
    justifyContent: 'center'
  }
}))

interface IEventStateProps {
  options?: EventState[]
  selectedOption?: EventState
  onChange: (option: EventState) => void
}

export const EventStateChip: React.FC<IEventStateProps> = ({
  options = [EventState.Published, EventState.Canceled],
  selectedOption = EventState.Published,
  onChange
}: IEventStateProps) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const translateEventState = useTranslateEventState()
  const classes = useStyles()
  const handleMenuItemClick = useCallback(
    (option: EventState) => () => {
      onChange(option)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={openMenu}
        onClick={openMenu}
        label={translateEventState(selectedOption)}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            label={translateEventState(option)}
            icon={option === selectedOption ? <CheckIcon /> : undefined}
            isSelected={option === selectedOption}
            className={classes.menuItem}
            iconClassName={classes.menuIcon}
            onClick={handleMenuItemClick(option)}
          />
        ))}
      </Menu>
    </>
  )
}
