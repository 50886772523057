import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {
  CartPropertiesFragment,
  CartState
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {routeTo} from '../../../../utils/routes'
import {useCurrentCart} from './CurrentCartContext'

interface IPendingCartDialogProps {
  currentCart: CartPropertiesFragment
}

const PendingCartDialog: React.FC<IPendingCartDialogProps> = ({
  currentCart
}: IPendingCartDialogProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {
    state: isDialogOpen,
    setTrue: openDialog,
    setFalse: closeDialog
  } = useBooleanState(false)
  const handleButtonClick = useCallback(() => {
    closeDialog()
    history.push(routeTo.admin.cashDesk.cartDetail())
  }, [closeDialog, history])
  useEffect(() => {
    if (currentCart.state === CartState.Pending) {
      openDialog()
    }
  }, [currentCart.state, openDialog])
  return (
    <Dialog open={isDialogOpen} fullWidth maxWidth="sm">
      <DialogTitle>{t('Payment in progress')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'Payment on the POS terminal is still in progress. Please be patient and wait for the transaction to complete. Avoid interrupting the process or restarting the terminal. If the status remains unchanged for an extended period, please seek assistance or contact support.'
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleButtonClick}>
          {t('Got it')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IPendingCartControllerProps {
  children: React.ReactNode
  excludeRoutes?: string[]
}

export const PendingCartController: React.FC<IPendingCartControllerProps> = ({
  children,
  excludeRoutes
}: IPendingCartControllerProps) => {
  const {currentCart} = useCurrentCart()
  const location = useLocation()
  const isOnExlucedRoute = excludeRoutes?.includes(location.pathname)
  return (
    <>
      {children}
      {currentCart && !isOnExlucedRoute && (
        <PendingCartDialog currentCart={currentCart} />
      )}
    </>
  )
}
