import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {routeTo} from '../../../utils/routes'
import {Blank} from '../../visual/Blank'

interface IInvalidTokenProps {
  description: string
}

export const InvalidToken: React.FC<IInvalidTokenProps> = ({
  description
}: IInvalidTokenProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const handleLoginPageButtonClick = useCallback(
    () => history.push(routeTo.login()),
    [history]
  )
  return (
    <Blank
      title={t('Token unavailable')}
      description={description}
      IconComp={ReportProblemOutlinedIcon}
      actions={
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoginPageButtonClick}
        >
          {t('Login page')}
        </Button>
      }
    />
  )
}
