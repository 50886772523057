import {Box, SxProps, Typography} from '@mui/material'
import {styled} from '@mui/system'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  GetLightweightCostCentersQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightMarketingLabelsQuery,
  LightweightUsersQuery,
  LightweightVenuesQuery,
  NarrowAdmissionRateFieldsFragment,
  ShowTypeCode,
  ShowVersionCode,
  UserState
} from '../../../__generated__/schema'
import {useTranslateShowVersion} from '../../../hooks/translateDistributions'
import {
  useIsNonNegativeInteger,
  useIsStringWithMaxLength
} from '../../../utils/formsValidations'
import {FormAutocomplete} from '../../form/FormAutocomplete'
import {FormSwitchWithLabels} from '../../form/FormSwitchWithLabels'
import {UncontrolledFormTextInput} from '../../form/FormTextInput'
import {FormDatetimeInput} from '../../form/pickers'
import {UncontrolledFormRadioGroup} from '../../form/UncontrolledFormRadioGroup'
import {useAgeClassificationSelectFields} from '../../pages/admin/shows/getSelectFields'
import {CollapseSection} from '../CollapseSection'
import {ColorBox} from '../ColorBox'
import {InputRow} from '../FormHelpers'
import {ITourTimeSlotForm, TourTimeSlotFormField} from './types'

interface IRadioLabelProps {
  title: React.ReactNode
  description?: React.ReactNode
  icon: React.ReactNode
}

const RadioLabel: React.FC<IRadioLabelProps> = ({
  title,
  description,
  icon
}: IRadioLabelProps) => (
  <Box
    sx={{
      display: 'grid',
      width: '100%',
      pl: 0.5,
      gridTemplateAreas: `
        "title       icon"
        "description icon"
      `
    }}
  >
    <Typography variant="subtitle2">{title}</Typography>
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{gridArea: 'description'}}
    >
      {description}
    </Typography>
    <Box
      sx={{
        gridArea: 'icon',
        alignSelf: 'center',
        justifySelf: 'flex-end'
      }}
    >
      {icon}
    </Box>
  </Box>
)

interface ISectionProps {
  title: React.ReactNode
  children: React.ReactNode
  sx?: SxProps
}

const Section: React.FC<ISectionProps> = ({
  title,
  children,
  sx
}: ISectionProps) => (
  <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, ...sx}}>
    <Typography variant="subtitle1">{title}</Typography>
    <Box>{children}</Box>
  </Box>
)

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(1.5)
}))

interface ITourTimeSlotFormProps {
  formId: string
  onSubmit: (data: ITourTimeSlotForm) => void
  defaultValues?: Partial<ITourTimeSlotForm>
  admissionRates: NarrowAdmissionRateFieldsFragment[]
  users: LightweightUsersQuery['users']
  costCenters: GetLightweightCostCentersQuery['costCenters']
  showTypeCode: ShowTypeCode
  eventCategories: GetLightweightEventCategoriesQuery['eventCategories']
  marketingLabels: GetLightweightMarketingLabelsQuery['marketingLabels']
  venues: LightweightVenuesQuery['venues']
}

export const TourTimeSlotForm: React.FC<ITourTimeSlotFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  admissionRates,
  users,
  showTypeCode,
  costCenters,
  eventCategories,
  marketingLabels,
  venues
}: ITourTimeSlotFormProps) => {
  const {t} = useTranslation()
  const {
    errors,
    setValue,
    control,
    setError,
    watch,
    clearError,
    register,
    unregister,
    triggerValidation,
    handleSubmit
  } = useForm<ITourTimeSlotForm>({defaultValues})
  const translateShowVersion = useTranslateShowVersion()
  const isNonNegativeInteger = useIsNonNegativeInteger()
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const ageClassificationSelectFields =
    useAgeClassificationSelectFields(showTypeCode)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id={formId}>
      <InputRow
        nodes={[
          <FormDatetimeInput<ITourTimeSlotForm>
            dataTimePickerProps={{
              label: t<string>('Starts at'),
              disablePast: true
            }}
            control={control}
            register={register}
            unregister={unregister}
            watch={watch}
            errors={errors}
            clearError={clearError}
            setValue={setValue}
            key={TourTimeSlotFormField.StartsAt}
            name={TourTimeSlotFormField.StartsAt}
            setError={setError}
            validationOptions={{
              required: true
            }}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormRadioGroup<ITourTimeSlotForm>
            label={t('Admission rate')}
            name={TourTimeSlotFormField.AdmissionRateId}
            key={TourTimeSlotFormField.AdmissionRateId}
            errors={errors}
            validationOptions={{required: true}}
            fullWidth
            control={control}
            options={admissionRates.map(({id, name, description, color}) => ({
              value: String(id),
              label: (
                <RadioLabel
                  title={name}
                  description={description}
                  icon={<ColorBox hexColor={color} />}
                />
              )
            }))}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<ITourTimeSlotForm>
            noOptionsText={t('No venue found')}
            fullWidth
            errors={errors}
            label={t('Venue')}
            name={TourTimeSlotFormField.VenueId}
            key={TourTimeSlotFormField.VenueId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={venues
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({id, name}) => ({
                value: String(id),
                name
              }))}
            required
            validationOptions={{required: true}}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<ITourTimeSlotForm>
            noOptionsText={t('No guide found')}
            fullWidth
            errors={errors}
            label={t('Guide')}
            name={TourTimeSlotFormField.GuideId}
            key={TourTimeSlotFormField.GuideId}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={users
              .filter(({state}) => state === UserState.Active)
              .sort(
                (nameA, nameB) =>
                  nameA.lastName.localeCompare(nameB.lastName) ||
                  nameA.firstName.localeCompare(nameB.firstName)
              )
              .map(({id, firstName, lastName}) => ({
                value: String(id),
                name: [lastName, firstName].join(' ')
              }))}
          />
        ]}
      />
      <InputRow
        nodes={[
          <FormAutocomplete<ITourTimeSlotForm>
            noOptionsText={t('No version found')}
            fullWidth
            errors={errors}
            label={t('Version')}
            name={TourTimeSlotFormField.VersionCode}
            key={TourTimeSlotFormField.VersionCode}
            register={register}
            setValue={setValue}
            watch={watch}
            autocompleteOptions={Object.values(ShowVersionCode).map((code) => ({
              value: code,
              name: translateShowVersion(code)
            }))}
          />
        ]}
      />
      <Section title={t('Retail settings')} sx={{pt: 5}}>
        <InputRow
          nodes={[
            <FormSwitchWithLabels<ITourTimeSlotForm>
              name={TourTimeSlotFormField.IsRetailSaleActive}
              key={TourTimeSlotFormField.IsRetailSaleActive}
              primaryLabel={t('Enabled sales on retail')}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.isRetailSaleActive || false}
              secondaryLabel=""
              enabledText=""
              disabledText=""
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormSwitchWithLabels<ITourTimeSlotForm>
              name={TourTimeSlotFormField.IsRetailReservationActive}
              key={TourTimeSlotFormField.IsRetailReservationActive}
              primaryLabel={t('Enabled reservations on retail')}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.isRetailReservationActive || false}
              secondaryLabel=""
              enabledText=""
              disabledText=""
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ITourTimeSlotForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              label={t('Retail maximum attendees')}
              name={TourTimeSlotFormField.RetailAttendeesLimit}
              key={TourTimeSlotFormField.RetailAttendeesLimit}
              validationOptions={{
                validate: isNonNegativeInteger
              }}
              fullWidth
              helperNote={t(
                'After reaching this limit, cashier won’t be able to sell more tickets.'
              )}
            />
          ]}
        />
      </Section>
      <Section title={t('Ecommerce settings')} sx={{pt: 2}}>
        <InputRow
          nodes={[
            <FormSwitchWithLabels<ITourTimeSlotForm>
              name={TourTimeSlotFormField.ShowOnWebsiteAndApi}
              key={TourTimeSlotFormField.ShowOnWebsiteAndApi}
              primaryLabel={t('Display on website and API ?')}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.showOnWebsiteAndApi || true}
              secondaryLabel=""
              enabledText=""
              disabledText=""
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormSwitchWithLabels<ITourTimeSlotForm>
              name={TourTimeSlotFormField.IsECommerceSaleActive}
              key={TourTimeSlotFormField.IsECommerceSaleActive}
              primaryLabel={t('Enable sales on ecommerce')}
              control={control}
              watch={watch}
              defaultValue={defaultValues?.isECommerceSaleActive || false}
              secondaryLabel=""
              enabledText=""
              disabledText=""
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormSwitchWithLabels<ITourTimeSlotForm>
              name={TourTimeSlotFormField.IsECommerceReservationActive}
              key={TourTimeSlotFormField.IsECommerceReservationActive}
              primaryLabel={t('Enable reservations on ecommerce')}
              control={control}
              watch={watch}
              defaultValue={
                defaultValues?.isECommerceReservationActive || false
              }
              secondaryLabel=""
              enabledText=""
              disabledText=""
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ITourTimeSlotForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              label={t('Ecommerce maximum attendees')}
              name={TourTimeSlotFormField.ECommerceAttendeesLimit}
              key={TourTimeSlotFormField.ECommerceAttendeesLimit}
              validationOptions={{
                validate: isNonNegativeInteger
              }}
              fullWidth
              helperNote={t(
                'After reaching this limit, customer won’t be able to buy more tickets.'
              )}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ITourTimeSlotForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              label={t('Max attendees per ecommerce order')}
              name={TourTimeSlotFormField.ECommerceOrderAttendeesLimit}
              key={TourTimeSlotFormField.ECommerceOrderAttendeesLimit}
              validationOptions={{
                required: true,
                validate: isNonNegativeInteger
              }}
              fullWidth
              helperNote={t(
                'After reaching this limit, customer won’t be able to buy more tickets.'
              )}
              required
            />
          ]}
        />
      </Section>
      <CollapseSection title={t('Additional settings')} expanded>
        <InputRow
          nodes={[
            <FormAutocomplete<ITourTimeSlotForm>
              noOptionsText={t('No age restriction found')}
              fullWidth
              errors={errors}
              label={t('Age restriction')}
              name={TourTimeSlotFormField.AgeClassificationCode}
              key={TourTimeSlotFormField.AgeClassificationCode}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={Object.entries(
                ageClassificationSelectFields
              ).map(([value, name]) => ({value, name: name as string}))}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<ITourTimeSlotForm>
              noOptionsText={t('No cost center found')}
              fullWidth
              errors={errors}
              label={t('Cost center')}
              name={TourTimeSlotFormField.CostCenterId}
              key={TourTimeSlotFormField.CostCenterId}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={costCenters
                .filter(({isActive}) => isActive)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<ITourTimeSlotForm>
              noOptionsText={t('No event category found')}
              fullWidth
              errors={errors}
              label={t('Event category')}
              name={TourTimeSlotFormField.EventCategoryId}
              key={TourTimeSlotFormField.EventCategoryId}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={eventCategories
                .filter(({isActive}) => isActive)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))}
            />
          ]}
        />
        <InputRow
          nodes={[
            <FormAutocomplete<ITourTimeSlotForm>
              noOptionsText={t('No marketing label found')}
              fullWidth
              errors={errors}
              label={t('Marketing label')}
              name={TourTimeSlotFormField.MarketingLabelId}
              key={TourTimeSlotFormField.MarketingLabelId}
              register={register}
              setValue={setValue}
              watch={watch}
              autocompleteOptions={marketingLabels
                .filter(({isActive}) => isActive)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({id, name}) => ({
                  value: String(id),
                  name
                }))}
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ITourTimeSlotForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              label={t('Ticket note')}
              name={TourTimeSlotFormField.TicketNote}
              key={TourTimeSlotFormField.TicketNote}
              validationOptions={{
                validate: isStringWithMaxLength1000
              }}
              fullWidth
              rows={3}
              multiline
            />
          ]}
        />
      </CollapseSection>
    </StyledForm>
  )
}
