import {makeStyles} from '@mui/styles'
import {ApolloError} from 'apollo-client'
import dayjs from 'dayjs'
import {TFunction} from 'i18next'
import {isNil} from 'lodash'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BroadDiscountCodePropertiesFragment,
  CashDeskCustomerFieldsFragment,
  CashDeskEventDetailPropertiesFragment,
  Discount,
  EnabledDiscountPropertiesFragment,
  ErrorMessages,
  LeadDataInput,
  LeadInputStatus,
  LeadOptionPropertiesFragment,
  Scalars,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {COLOR_CONF} from '../../../constants'
import {DAY_FORMAT} from '../utils'
import {ILeadForm} from './types'

export const parseDateObjectFromDate = (date: string) => {
  return new Date(dayjs(date, DAY_FORMAT).format())
}

interface IUseGetExpirationToNowColor {
  isWithBackground?: boolean
}

const getBackgroundColor =
  (color: string) =>
  ({isWithBackground}: IUseGetExpirationToNowColor) =>
    isWithBackground ? color : 'transparent'

export const useGetExpirationToNowColor = ({
  isWithBackground
}: IUseGetExpirationToNowColor) => {
  const useExpirationColorStyles = makeStyles<
    Theme,
    IUseGetExpirationToNowColor
  >(() => ({
    red: {
      color: COLOR_CONF.RED.color,
      backgroundColor: getBackgroundColor(COLOR_CONF.RED.background)
    },
    yellow: {
      color: COLOR_CONF.YELLOW.color,
      backgroundColor: getBackgroundColor(COLOR_CONF.YELLOW.background)
    },
    green: {
      color: COLOR_CONF.GREEN.color,
      backgroundColor: getBackgroundColor(COLOR_CONF.GREEN.background)
    }
  }))
  const classes = useExpirationColorStyles({isWithBackground})
  return (daysCountBetweenTodayAndExpirationDate: number): string =>
    daysCountBetweenTodayAndExpirationDate < 2
      ? classes.red
      : daysCountBetweenTodayAndExpirationDate < 7
      ? classes.yellow
      : classes.green
}

export const useDaysCountBetweenTodayAndExpirationDate = (expireAt: string) =>
  dayjs(expireAt).startOf('d').diff(dayjs().startOf('d'), 'day')

export const useExpirationToNowLabel = ({
  expireAt,
  daysCountBetweenTodayAndExpirationDate
}: {
  expireAt: string
  daysCountBetweenTodayAndExpirationDate: number
}) => {
  const {daysToNow, formatLongWeekDay} = useDateTimeFormatters()
  return daysCountBetweenTodayAndExpirationDate < 2 ||
    daysCountBetweenTodayAndExpirationDate > 6
    ? daysToNow(daysCountBetweenTodayAndExpirationDate)
    : formatLongWeekDay(new Date(expireAt))
}

export const getHelperNote = (
  t: TFunction,
  leadOption?: LeadOptionPropertiesFragment
): string | undefined =>
  leadOption?.helperText
    ? leadOption?.inputStatus === LeadInputStatus.Required
      ? `*${leadOption.helperText}`
      : leadOption.helperText
    : (leadOption?.inputStatus === LeadInputStatus.Required &&
        t('*Required')) ||
      (leadOption?.inputStatus === LeadInputStatus.Recommended &&
        t('*Recommended')) ||
      undefined

export const useTicketTypesByUuid = ({
  auditoriumLayoutPricing,
  ticketTypes
}: {
  auditoriumLayoutPricing: Scalars['JSONObject']
  ticketTypes: CashDeskEventDetailPropertiesFragment['activePricing']['ticketTypes']
}) =>
  Object.entries(auditoriumLayoutPricing).reduce<{
    [uuid: string]:
      | null
      | CashDeskEventDetailPropertiesFragment['activePricing']['ticketTypes'][number]
  }>(
    (acc, [uuid, {id: ticketTypeId}]) => ({
      ...acc,
      [uuid]:
        ticketTypes?.find(
          (ticketType) => ticketType.ticketTypeId === ticketTypeId
        ) || null
    }),
    {}
  )

export const useGetTourItemQuantity = (
  tourItems: TourItemPropertiesFragment[]
) => {
  const getIncrementTourItemQuantity = useCallback(
    (startingQuantity: number, assigmentId: number, tourTimeSlotId: number) => {
      const itemsLength = tourItems.filter(
        (i) =>
          i.tourTimeSlotId === tourTimeSlotId &&
          i.admissionTypeAssignmentId === assigmentId
      ).length
      return itemsLength === 0 ? startingQuantity : 1
    },
    [tourItems]
  )
  const getDecrementTourItemQuantity = useCallback(
    (startingQuantity: number, assigmentId: number, tourTimeSlotId: number) => {
      const itemsLength = tourItems.filter(
        (i) =>
          i.tourTimeSlotId === tourTimeSlotId &&
          i.admissionTypeAssignmentId === assigmentId
      ).length
      return itemsLength === startingQuantity ? startingQuantity : 1
    },
    [tourItems]
  )
  return {getIncrementTourItemQuantity, getDecrementTourItemQuantity}
}

export const useIncrementErrorHandler = () => {
  const {t} = useTranslation()
  // eslint-disable-next-line no-undef
  const {customErrorHandler, defaultErrorHandler} = useMutationAssistanceHooks()
  const handleConfirm = useCallback(() => window.location.reload(), [])
  return useCallback(
    (error: ApolloError, admissionTypeName: string) => {
      if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.RetailAttendeesLimitPerTourTimeSlotExceeded
        )
      ) {
        customErrorHandler(error, {
          title: t('Time slot is sold out on retail'),
          contentText: t(
            "We're sorry, but this time slot is sold out on retail. Please try it again later or pick another one."
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: handleConfirm
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.AdmissionTypeAssignmentLimitPerTimeSlotExceeded
        )
      ) {
        customErrorHandler(error, {
          title: t('Item {{admissionTypeName}} is sold out', {
            admissionTypeName
          }),
          contentText: t(
            'Item {{admissionTypeName}} is sold out for this time slot. Please try it again later or pick another item or time slot.',
            {
              admissionTypeName
            }
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: handleConfirm
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidTourTimeSlotState
        )
      ) {
        customErrorHandler(error, {
          title: t('Time slot is unavailable'),
          contentText: t(
            "We're sorry, but this time slot is no longer available. Please try to pick another one."
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: handleConfirm
        })
      } else if (
        getGraphQLErrorRelatedToErrorMessage(
          error,
          ErrorMessages.InvalidCartState
        )
      ) {
        customErrorHandler(error, {
          title: t('Invalid cart state'),
          contentText: t(
            "We're sorry, but your cart is currently in an invalid state. Please reload your page and try again. If the issue persists, contact our customer support."
          ),
          confirmButtonLabel: t('Got it'),
          onConfirm: handleConfirm
        })
      } else {
        defaultErrorHandler(
          error,
          t('Error while incrementing tour item quantity')
        )
      }
    },
    [customErrorHandler, defaultErrorHandler, handleConfirm, t]
  )
}

export const getQuantityOfItemsWithAppliedDiscount = (
  itemsWithAllowedDiscounts: (
    | TicketItemPropertiesFragment
    | TourItemPropertiesFragment
  )[],
  discount: EnabledDiscountPropertiesFragment
): number =>
  itemsWithAllowedDiscounts.filter(
    (item: TicketItemPropertiesFragment | TourItemPropertiesFragment) =>
      item.appliedDiscounts.find(
        (appliedDiscount) => appliedDiscount.discount.id === discount.id
      )
  ).length

export const getMaxQuantityOfItemsForDiscount = (
  itemsWithAllowedDiscounts: (
    | TicketItemPropertiesFragment
    | TourItemPropertiesFragment
  )[],
  discount: EnabledDiscountPropertiesFragment
): number => {
  if (discount.maxUsageLimitPerOrder) {
    return discount.maxUsageLimitPerOrder
  }

  return itemsWithAllowedDiscounts.filter(
    (item: TicketItemPropertiesFragment | TourItemPropertiesFragment) =>
      item.appliedDiscounts.length === 0 ||
      item.appliedDiscounts.find(
        (appliedDiscount) => appliedDiscount.discount.id === discount.id
      )
  ).length
}

export const getRemainingDiscountCodeUsageLimitPerOrder = ({
  discountCode,
  items
}: {
  discountCode: BroadDiscountCodePropertiesFragment
  items: (TicketItemPropertiesFragment | TourItemPropertiesFragment)[]
}) => {
  const itemsWithDiscount = items.filter((item) =>
    item.appliedDiscounts.find((d) => d.discountCode?.id === discountCode.id)
  )
  const remainingDiscountCodeUsageLimitPerOrder = isNil(
    discountCode.usageLimitPerOrder
  )
    ? undefined
    : discountCode.usageLimitPerOrder - itemsWithDiscount.length
  return {
    remainingDiscountCodeUsageLimitPerOrder,
    canDiscountCodeBeUsed: isNil(remainingDiscountCodeUsageLimitPerOrder)
      ? true
      : remainingDiscountCodeUsageLimitPerOrder > 0
  }
}

export const getRemainingDiscountUsageLimitPerOrder = ({
  discount,
  items
}: {
  discount: Pick<Discount, 'id' | 'maxUsageLimitPerOrder'>
  items: (TicketItemPropertiesFragment | TourItemPropertiesFragment)[]
}) => {
  const itemsWithDiscount = items.filter((item) =>
    item.appliedDiscounts.find(({discount: {id}}) => id === discount.id)
  )
  const remainingDiscountUsageLimitPerOrder = isNil(
    discount.maxUsageLimitPerOrder
  )
    ? undefined
    : discount.maxUsageLimitPerOrder - itemsWithDiscount.length
  return {
    remainingDiscountUsageLimitPerOrder,
    canDiscountBeUsed: isNil(remainingDiscountUsageLimitPerOrder)
      ? true
      : remainingDiscountUsageLimitPerOrder > 0
  }
}

export const getRemainingDiscountUsageLimitPerOrderForDiscountDialog = ({
  discount,
  items
}: {
  discount: Pick<Discount, 'id' | 'maxUsageLimitPerOrder'>
  items: (TicketItemPropertiesFragment | TourItemPropertiesFragment)[]
}) => {
  const itemsWithDiscount = items.filter((item) =>
    item.appliedDiscounts.find(({discount: {id}}) => id === discount.id)
  )
  return isNil(discount.maxUsageLimitPerOrder)
    ? undefined
    : discount.maxUsageLimitPerOrder - itemsWithDiscount.length
}

export const getMaxQuantityOfItemsForDiscountCode = (
  itemsWithAllowedDiscounts: (
    | TicketItemPropertiesFragment
    | TourItemPropertiesFragment
  )[],
  discountCode: BroadDiscountCodePropertiesFragment
): number => {
  if (
    discountCode.usageLimit &&
    discountCode.usageLimit - discountCode.usageCount === 0
  ) {
    return 0
  }

  if (discountCode.discount.maxUsageLimitPerOrder) {
    return discountCode.discount.maxUsageLimitPerOrder
  }

  if (discountCode.usageLimitPerOrder) {
    return discountCode.usageLimitPerOrder
  }

  return itemsWithAllowedDiscounts.filter(
    (item: TicketItemPropertiesFragment | TourItemPropertiesFragment) =>
      item.appliedDiscounts.length === 0 ||
      item.appliedDiscounts.find(
        (appliedDiscount) =>
          appliedDiscount.discountCode?.id === discountCode.id
      )
  ).length
}

export const getQuantityOfItemsWithAppliedDiscountCode = (
  itemsWithAllowedDiscounts: (
    | TicketItemPropertiesFragment
    | TourItemPropertiesFragment
  )[],
  discountCode: BroadDiscountCodePropertiesFragment
): number =>
  itemsWithAllowedDiscounts.filter(
    (item: TicketItemPropertiesFragment | TourItemPropertiesFragment) =>
      item.appliedDiscounts.find(
        (appliedDiscount) =>
          appliedDiscount.discountCode?.id === discountCode.id
      )
  ).length

export const getIsIncrementButtonDisabled = (
  itemsWithAllowedDiscounts: (
    | TicketItemPropertiesFragment
    | TourItemPropertiesFragment
  )[],
  maxQuantity: number,
  code: BroadDiscountCodePropertiesFragment
) => {
  if (code.usageLimit && code.usageLimit - code.usageCount === 0) {
    return true
  }
  return (
    getQuantityOfItemsWithAppliedDiscountCode(
      itemsWithAllowedDiscounts,
      code
    ) >= maxQuantity
  )
}

export const useDiscountErrorHandler = () => {
  const {t} = useTranslation()
  const {customErrorHandler} = useMutationAssistanceHooks()
  return (error: ApolloError, defaultErrorMessage: string) => {
    if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountCodeUsageLimitWasReached
      )
    ) {
      customErrorHandler(error, {
        title: t('Discount code is invalid'),
        contentText: t(
          "We're sorry, but this discount code has reached its usage limit and cannot be applied to selected items."
        ),
        confirmButtonLabel: t('Got it')
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountCodeHasExceededUsageLimit
      )
    ) {
      customErrorHandler(error, {
        title: t('Discount code redemption limit exceeded'),
        contentText: t(
          "The discount code you're trying to apply is for more items than allowed. Each code can only be applied to a maximum number of items, and it seems you're attempting to exceed this limit. Please adjust the number of items you're redeeming with this code to meet its terms, or explore other available discount options."
        ),
        confirmButtonLabel: t('Got it')
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.CustomerNotFound
      )
    ) {
      customErrorHandler(error, {
        title: t('Customer not found'),
        contentText: t(
          "We're sorry, but we were unable to find any customer information associated with the scanned code. The code might have been entered incorrectly or is invalid. Please, try it again. If the problem persists, you might consider manually searching for the customer's name of loyalty id instead."
        ),
        confirmButtonLabel: t('Got it')
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.CustomerIsNotActive
      )
    ) {
      customErrorHandler(error, {
        title: t('Customer is not active'),
        contentText: t(
          "The customer account you're attempting to access is currently inactive, invited or anonymised. Consider choosing a different customer or activating this account. Inform the customer about their account status if necessary."
        ),
        confirmButtonLabel: t('Got it')
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountCodeUsageLimitPerOrderExceeded
      )
    ) {
      customErrorHandler(error, {
        title: t('Discount code redemption limit exceeded'),
        contentText: t(
          'We’re sorry, but the discount code you are trying to apply has reached its usage limit for this order. Each order can only use this discount code a certain number of times, and you have already applied the maximum allowed usage. Please review your order to ensure the discount code is applied correctly or reduce the number of times you’re using the code.'
        ),
        confirmButtonLabel: t('Got it'),
        onConfirm: () => window.location.reload()
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.ItemIsAlreadyDiscounted
      )
    ) {
      customErrorHandler(error, {
        title: t('Adding discount failed'),
        contentText: t(
          'The item you are trying to discount has already been discounted. Please, reload page and review your order details to see the applied discounts.'
        ),
        confirmButtonLabel: t('Got it'),
        onConfirm: () => window.location.reload()
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.CustomerLoyaltyLevelExpired
      )
    ) {
      customErrorHandler(error, {
        title: t('Loyalty benefits has expired'),
        contentText: t(
          "The loyalty benefits associated with this customer's loyalty ID have expired. To regain access to these benefits, please renew the loyalty membership."
        ),
        confirmButtonLabel: t('Got it'),
        onConfirm: () => window.location.reload()
      })
    } else if (
      getGraphQLErrorRelatedToErrorMessage(
        error,
        ErrorMessages.DiscountUsageLimitPerOrderExceeded
      )
    ) {
      customErrorHandler(error, {
        title: t('Discount redemption limit exceeded'),
        contentText: t(
          'We’re sorry, but the discount you are trying to apply has reached its usage limit for this order. Each order can only use this discount a certain number of times, and you have already applied the maximum allowed usage. Please review your order to ensure the discount is applied correctly or reduce the number of times you’re using the discount.'
        ),
        confirmButtonLabel: t('Got it'),
        onConfirm: () => window.location.reload()
      })
    } else {
      customErrorHandler(error, {
        title: defaultErrorMessage,
        contentText: error.graphQLErrors[0].message,
        confirmButtonLabel: t('Got it')
      })
    }
  }
}

export const transformLeadFormToLeadDataInput = (
  form: Partial<ILeadForm>
): LeadDataInput =>
  Object.entries(form).reduce(
    (acc, [key, value]) =>
      value ? {...acc, [key]: value} : {...acc, [key]: null},
    {}
  )

export const getCustomerParamsForMutation = ({
  selectedCustomer,
  leadData
}: {
  selectedCustomer?: CashDeskCustomerFieldsFragment | null
  leadData: ILeadForm
}) =>
  selectedCustomer
    ? {customerId: selectedCustomer.id}
    : {
        input: transformLeadFormToLeadDataInput(leadData)
      }
