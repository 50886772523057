import {
  Ellipse,
  ICoords,
  IDimensions,
  Line,
  Rectangle,
  ShapeVariant
} from '@attendio/shared-components'
import Konva from 'konva'
import _ from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {LINE_MIN_LENGTH, ROTATION} from '../config'
import {useSelector} from '../redux'
import {canvasSelector} from '../redux/canvas/selectors'
import {EditorMode} from '../redux/editorMode/reducer'
import {editorModeSelector} from '../redux/editorMode/selectors'
import {useShapeActions} from '../redux/objects/shapes/actions'
import {DrawTool, ICreateShape} from '../types'
import {getMouseCoordsOnCanvas} from '../utils/common'
import {radiansToDegrees, vectorLength, vectorToRadians} from '../utils/math'
import {EventLayer} from './EventLayer'

const defaultDimensions = {width: 0, height: 0}

export const lineToVerticalWithRotation = (
  coords: ICoords,
  dimensions: IDimensions
): ICreateShape => {
  const center = {
    x: coords.x + dimensions.width / 2,
    y: coords.y + dimensions.height / 2
  }

  const length = vectorLength(dimensions.width, dimensions.height)

  const rotationInRadians = vectorToRadians(dimensions.width, dimensions.height)

  return {
    coords: {
      x: center.x - length / 2,
      y: center.y
    },
    dimensions: {
      width: length,
      height: 0
    },
    rotation: radiansToDegrees(rotationInRadians)
  }
}

const TemporaryShapeFn: React.FC = () => {
  const [startCoords, setStartCoords] = useState<ICoords | null>(null)
  const [endCoords, setEndCoords] = useState<ICoords | null>(null)
  const {modeConfigs} = useSelector(editorModeSelector)
  const {scale} = useSelector(canvasSelector)
  const {addShape} = useShapeActions()

  const shapeVariant = useMemo(() => {
    const drawConfig = modeConfigs[EditorMode.DRAW]
    return drawConfig.type === DrawTool.SHAPE
      ? drawConfig.variant
      : ShapeVariant.Rectangle
  }, [modeConfigs])

  const dimensions: IDimensions = useMemo(() => {
    if (!startCoords || !endCoords) {
      return defaultDimensions
    }

    let newDimensions = {
      width: endCoords.x - startCoords.x,
      height: endCoords.y - startCoords.y
    }

    if (shapeVariant === ShapeVariant.Line) {
      const lineLength = vectorLength(newDimensions.width, newDimensions.height)
      if (lineLength === 0) {
        newDimensions = {
          width: LINE_MIN_LENGTH,
          height: 0
        }
      } else if (lineLength < LINE_MIN_LENGTH) {
        const ratio = LINE_MIN_LENGTH / lineLength
        newDimensions = {
          width: newDimensions.width * ratio,
          height: newDimensions.height * ratio
        }
      }
    }

    if (_.isEqual(newDimensions, defaultDimensions)) {
      return defaultDimensions
    }

    return newDimensions
  }, [endCoords, shapeVariant, startCoords])

  const onMouseDown = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const newCoords = getMouseCoordsOnCanvas(e)
    setStartCoords(newCoords)
    setEndCoords(newCoords)
  }, [])

  const onMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (startCoords) {
        setEndCoords(getMouseCoordsOnCanvas(e))
      }
    },
    [startCoords]
  )

  const onMouseUp = useCallback(() => {
    if (startCoords && endCoords) {
      if (shapeVariant === ShapeVariant.Line) {
        const line = lineToVerticalWithRotation(startCoords, dimensions)
        addShape(line, shapeVariant)
      } else {
        addShape(
          {
            coords: startCoords,
            dimensions,
            rotation: ROTATION
          },
          shapeVariant
        )
      }
    }

    setStartCoords(null)
    setEndCoords(null)
  }, [addShape, dimensions, endCoords, shapeVariant, startCoords])

  return (
    <EventLayer {...{onMouseUp, onMouseMove, onMouseDown}}>
      {startCoords && endCoords && shapeVariant === ShapeVariant.Ellipse && (
        <Ellipse
          id=""
          coords={startCoords}
          dimensions={dimensions}
          rotation={ROTATION}
        />
      )}
      {startCoords && endCoords && shapeVariant === ShapeVariant.Line && (
        <Line
          id=""
          scale={scale}
          coords={startCoords}
          dimensions={dimensions}
          rotation={ROTATION}
        />
      )}
      {startCoords && endCoords && shapeVariant === ShapeVariant.Rectangle && (
        <Rectangle
          id=""
          coords={startCoords}
          dimensions={dimensions}
          rotation={ROTATION}
        />
      )}
    </EventLayer>
  )
}

export const TemporaryShape = React.memo(TemporaryShapeFn)
