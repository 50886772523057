import {Button} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'

import {Blank, BlankContentPosition} from '../../../../visual/Blank'

interface IChildrenOnValidPaymentMethodProps {
  hasValidPaymentMethod: boolean
  children: React.ReactNode
}

export const ChildrenOnValidPaymentMethod: React.FC<IChildrenOnValidPaymentMethodProps> =
  ({hasValidPaymentMethod, children}: IChildrenOnValidPaymentMethodProps) => {
    const {t} = useTranslation()
    const history = useHistory()
    const handleNavigateButtonClick = useCallback(() => {
      history.push(routeTo.admin.paymentMethods.retail())
    }, [history])
    const {P} = useEnsurePermissions()
    return hasValidPaymentMethod ? (
      <>{children}</>
    ) : (
      <Blank
        contentPosition={BlankContentPosition.Center}
        title={t('No payment methods found')}
        description={t(
          'Payment can’t be provided, because no payment methods ware found. Create payment method first.'
        )}
        actions={
          P([PermissionCode.ReadRetailPaymentMethods]) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateButtonClick}
            >
              {t('Payment methods')}
            </Button>
          )
        }
      />
    )
  }
