import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  SvgIconProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  summaryContent: {
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gridAutoFlow: 'column',
    gridTemplateAreas: `"icon name secondaryChildren"`,
    gridTemplateColumns: '24px auto 1fr',
    gap: theme.spacing(1)
  },
  secondaryChildren: {
    justifySelf: 'flex-end',
    gridArea: 'secondaryChildren'
  },
  name: {
    gridArea: 'name'
  },
  accordionDetailsRoot: {
    flexDirection: 'column'
  }
}))

export interface IIntentStructureAccordionProps {
  name: string
  isExpanded: boolean
  onSummaryClick: AccordionSummaryProps['onClick']
  Icon?: React.FC<SvgIconProps>
  children: React.ReactNode
  secondaryChildren?: React.ReactNode
}

export const IntentStructureAccordion: React.FC<IIntentStructureAccordionProps> =
  ({
    name,
    isExpanded,
    children,
    secondaryChildren,
    onSummaryClick,
    Icon
  }: IIntentStructureAccordionProps) => {
    const classes = useStyles()
    return (
      <Accordion expanded={isExpanded} variant="outlined">
        <AccordionSummary
          classes={{
            content: classes.summaryContent
          }}
          onClick={onSummaryClick}
        >
          {Icon && <Icon />}
          <Typography variant="subtitle1" className={classes.name}>
            {name}
          </Typography>
          {secondaryChildren && (
            <div className={classes.secondaryChildren}>{secondaryChildren}</div>
          )}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsRoot}>
          {children}
        </AccordionDetails>
      </Accordion>
    )
  }
