import {Box, Typography} from '@mui/material'
import React from 'react'

interface IInputBlockHeadingRowProps {
  label: string
  subLabel: string
}

export const InputBlockHeadingRow = ({
  label,
  subLabel
}: IInputBlockHeadingRowProps) => (
  <Box paddingY={1} paddingX={3}>
    <Typography variant="subtitle2">{label}</Typography>
    <Typography variant="caption" color="textSecondary">
      {subLabel}
    </Typography>
  </Box>
)
