import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import {makeStyles} from '@mui/styles'
import {compact} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  EventsFilterInput,
  PaginatedEventsQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {usePostCustomerDisplayMessage} from '../../../../../customerDisplayBroadcastChannel'
import {CustomerDisplayMessageType} from '../../../../../customerDisplayBroadcastChannel/types'
import {
  LocalStorageKey,
  useLocalStorageState
} from '../../../../../hooks/storage'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {EVENTS_PARAMS} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {RenderOnData, SubTopbarControls} from '../../../../common'
import {LoadingMoreProgress} from '../../../../common/LoadingMoreProgress'
import {Blank, BlankSearch} from '../../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {EnabledDivisionsTabs} from '../../components/EnabledDivisionsTabs'
import {useGetLightweightDivisions} from '../../graphql'
import {CenteredLayoutListWrapper, FullScreenCenteredLayout} from '../../Layout'
import {EventDetailDrawer} from '../eventDetailDrawer'
import {useGetPaginatedEvents} from '../graphql'
import {EventsContent} from './EventsContent'
import {DEFAULT_EVENTS_FILTER_INPUT, EventsSearch} from './EventsSearch'

const useStyles = makeStyles<Theme>((theme) => ({
  loadingMoreProgress: {
    paddingTop: theme.spacing(2)
  }
}))

export const Events: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {divisions} = useGetLightweightDivisions()
  const [filter, setFilter] = useState<EventsFilterInput>(
    DEFAULT_EVENTS_FILTER_INPUT
  )
  const [enabledDivisionsIds] = useLocalStorageState<number[]>(
    LocalStorageKey.EnabledDivisions,
    []
  )
  const history = useHistory()
  const enabledDivisions = compact(
    enabledDivisionsIds.map((ed) => divisions.find(({id}) => id === ed))
  )
  const areDivisionTabsShown = enabledDivisions.length > 1
  const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(
    enabledDivisionsIds ? enabledDivisionsIds[0] : null
  )
  const {data, error, loading, fetchMore, isLoadingMore} =
    useGetPaginatedEvents({
      ...filter,
      divisionId: selectedDivisionId || filter.divisionId
    })
  const displayAsSearchResults = useMemo(
    () =>
      JSON.stringify(DEFAULT_EVENTS_FILTER_INPUT) !== JSON.stringify(filter),
    [filter]
  )
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.paginatedEvents.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.paginatedEvents.pagination.hasMore, fetchMore, isLoadingMore])
  const classes = useStyles()

  const handleTabChange = useCallback(
    (e: React.ChangeEvent<{}>, divisionId: number) => {
      setSelectedDivisionId(divisionId)
    },
    []
  )
  const handleExited = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.events())
  }, [history])
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()
  useEffect(() => {
    postCustomerDisplayMessage({
      type: CustomerDisplayMessageType.DisplayCashdeskEventsView,
      payload: data?.paginatedEvents.items || []
    })
  }, [data?.paginatedEvents.items, postCustomerDisplayMessage])
  return (
    <>
      <FullScreenCenteredLayout
        onScrolledNearTheEndOfTheLayout={
          data?.paginatedEvents.pagination.hasMore
            ? handleScrolledNearTheEndOfTheLayout
            : undefined
        }
        subTopbar={
          <>
            <SubTopbarControls
              leftChildren={<EventsSearch onFilterChange={setFilter} />}
            />
            {areDivisionTabsShown && (
              <EnabledDivisionsTabs
                enabledDivisions={enabledDivisions}
                selectedDivisionId={selectedDivisionId}
                onTabChange={handleTabChange}
              />
            )}
          </>
        }
      >
        <ChildrenOnEffectiveClientSelected>
          <RenderOnData
            data={data}
            error={error}
            loading={loading}
            errorMessage={t<string>('Unable to load events')}
          >
            {({paginatedEvents}: PaginatedEventsQuery) => (
              <>
                {paginatedEvents.items.length > 0 ? (
                  <CenteredLayoutListWrapper>
                    <EventsContent
                      events={paginatedEvents.items}
                      displayAsSearchResults={displayAsSearchResults}
                    />
                    {isLoadingMore && (
                      <LoadingMoreProgress
                        className={classes.loadingMoreProgress}
                      />
                    )}
                  </CenteredLayoutListWrapper>
                ) : displayAsSearchResults ? (
                  <BlankSearch />
                ) : (
                  <Blank
                    title={t('No events found')}
                    IconComp={CalendarTodayIcon}
                  />
                )}
              </>
            )}
          </RenderOnData>
        </ChildrenOnEffectiveClientSelected>
      </FullScreenCenteredLayout>
      {P([PermissionCode.ReadPaginatedEvents]) && (
        <Route path={[routeTo.admin.cashDesk.events()]} exact>
          <EventDetailDrawer onExited={handleExited} showOpenPreviewButton />
        </Route>
      )}
      {P([PermissionCode.ReadEvent]) && (
        <Route
          path={[
            routeTo.admin.cashDesk.eventDetail(
              toPlaceholderParam(EVENTS_PARAMS.EVENT_ID)
            )
          ]}
          exact
        >
          <EventDetailDrawer onExited={handleExited} showOpenPreviewButton />
        </Route>
      )}
    </>
  )
}
