import {
  Box,
  Dialog,
  dialogClasses,
  DialogContent,
  Typography
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AssignTemplateDialogQuery,
  TemplateState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useTemplateParams} from '../../../../../utils/pathname'
import {RenderOnData} from '../../../../common'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {
  useAssignTemplateDialogQuery,
  useAssignTemplateToClient
} from './graphql'
import {TemplateItem} from './TemplateItem'

const normalize = (text: string) =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

interface IAssignTemplateDialogProps {
  clientId: number
  isOpen: boolean
  onClose: () => void
}

export const AssignTemplateDialog: React.FC<IAssignTemplateDialogProps> = ({
  clientId,
  isOpen,
  onClose
}: IAssignTemplateDialogProps) => {
  const {t} = useTranslation()
  const {templateType} = useTemplateParams()
  const {data, loading, error} = useAssignTemplateDialogQuery(
    {
      filter: {
        state: TemplateState.Active,
        type: templateType
      },
      clientId
    },
    !isOpen
  )
  const [search, setSearch] = useState<string>('')
  const assignTemplateToClient = useAssignTemplateToClient()
  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()
  const getAssignButtonClickHandler = useCallback(
    (templateId: number) => async () => {
      try {
        setShowBackdrop(true)
        await assignTemplateToClient({templateId, clientId})
        addInfoNotification(t('Template assignment successful'))
      } catch (e) {
        defaultErrorHandler(e, t('Template assignment failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      assignTemplateToClient,
      clientId,
      defaultErrorHandler,
      setShowBackdrop,
      t
    ]
  )
  const handleClose = useCallback(() => {
    onClose()
    setSearch('')
  }, [onClose])
  return (
    <Dialog
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      sx={{[`& .${dialogClasses.paper}`]: {height: '92vh'}}}
      open={isOpen}
    >
      <DialogTitleWithCloseButton onCloseClick={handleClose}>
        {t('Add template')}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={{p: 0, display: 'flex', flexDirection: 'column', gap: 2}}
        dividers
      >
        <RenderOnData<AssignTemplateDialogQuery>
          loading={loading}
          error={error}
          errorMessage={t<string>('Could not load templates.')}
          data={data}
        >
          {({templates, client}) => {
            const unassignedTemplates = templates.filter(
              (t) =>
                !client.templateAssignments
                  .map((ta) => ta.template.id)
                  .includes(t.id)
            )
            const filteredUnassignedTemplates = unassignedTemplates.filter(
              ({name, description}) =>
                normalize(name).indexOf(normalize(search)) > -1 ||
                (description &&
                  normalize(description).indexOf(normalize(search)) > -1)
            )
            return unassignedTemplates.length ? (
              <>
                <Box
                  sx={{
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'common.white',
                    px: 2,
                    pt: 2
                  }}
                >
                  <OutlinedInputWithCancelAdornment
                    onCancelClick={() => setSearch('')}
                    inputProps={{
                      value: search,
                      onChange: (e) => setSearch(e.target.value),
                      placeholder: t('Search for template')
                    }}
                    sx={{p: 0}}
                  />
                </Box>
                {filteredUnassignedTemplates.length > 0 ? (
                  <ListOfItemsSeparatedByDividers
                    sx={{px: 2, pb: 2, overflowY: 'auto'}}
                  >
                    {filteredUnassignedTemplates.map((template) => (
                      <TemplateItem
                        template={template}
                        key={template.id}
                        onAssignButtonClick={getAssignButtonClickHandler(
                          template.id
                        )}
                      />
                    ))}
                  </ListOfItemsSeparatedByDividers>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 2
                    }}
                  >
                    <Typography variant="body2">
                      {t('No templates found')}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2
                }}
              >
                <Typography variant="body2">
                  {t('No available templates found')}
                </Typography>
              </Box>
            )
          }}
        </RenderOnData>
      </DialogContent>
    </Dialog>
  )
}
