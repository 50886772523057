import React from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {
  DeviceServices,
  useDeviceServices
} from '../../../../hooks/deviceServices'
import {useEnsurePermissions} from '../../../../utils/auth'

import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {DeviceInformation} from './deviceInformation'
import {Events} from './events'
import {NavigationRail} from './NavigationRail'
import {Reservations} from './reservations'
import {Shop} from './shop'
import {Tours} from './tours'

const getRouteIfAvailable = (
  link: string,
  availableSettings: DeviceServices[],
  Comp: React.FC
) =>
  Boolean(availableSettings.find((setting) => setting.link === link)) && (
    <Route path={link}>
      <ChildrenOnEffectiveClientSelected>
        <Comp />
      </ChildrenOnEffectiveClientSelected>
    </Route>
  )

export const CashDeskPageWithNavigationRail: React.FC = () => {
  const [deviceServices] = useDeviceServices()
  const availableDeviceServices: DeviceServices[] = deviceServices.filter(
    ({isAvailable}) => isAvailable
  )
  const {P} = useEnsurePermissions()
  const {t} = useTranslation()
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Cash desk')} isDatetimeEnabled />}
    >
      <NavigationRail />
      <Switch>
        <Redirect
          to={
            availableDeviceServices[0]
              ? availableDeviceServices[0].link
              : routeTo.admin.cashDesk.deviceInformation()
          }
          path={routeTo.admin.cashDesk.index()}
          exact
        />
        {P([PermissionCode.ReadPaginatedEvents]) &&
          getRouteIfAvailable(
            routeTo.admin.cashDesk.events(),
            availableDeviceServices,
            Events
          )}
        {P([PermissionCode.IncrementTourItemQuantity]) &&
          getRouteIfAvailable(
            routeTo.admin.cashDesk.tours(),
            availableDeviceServices,
            Tours
          )}
        {P([PermissionCode.ReadReservations]) &&
          getRouteIfAvailable(
            routeTo.admin.cashDesk.reservations(),
            availableDeviceServices,
            Reservations
          )}
        {P([PermissionCode.IncrementProductItemQuantity]) &&
          getRouteIfAvailable(
            routeTo.admin.cashDesk.shop(),
            availableDeviceServices,
            Shop
          )}
        <Route
          path={routeTo.admin.cashDesk.deviceInformation()}
          component={DeviceInformation}
        />
      </Switch>
    </PageWithHeaderTemplate>
  )
}
