import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {PermissionCode, WebsitesFilter} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {PrimaryHeader} from '../Header'
import {WebsitesList} from './WebsitesList'
import {DEFAULT_WEBSITES_FILTER, WebsitesSearch} from './WebsitesSearch'

export const WebsitesPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<WebsitesFilter>(
    DEFAULT_WEBSITES_FILTER
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Websites')}
          search={
            P([PermissionCode.WebsiteInfoForSupportUser]) && (
              <WebsitesSearch onFilterChange={setSearchFilter} />
            )
          }
        />
      }
    >
      <WebsitesList searchFilter={searchFilter} />
    </PageWithHeaderTemplate>
  )
}
