import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateMarketingLabelDrawer} from './CreateMarketingLabelDrawer'
import {MarketingLabelsList} from './MarketingLabelsList'
import {UpdateMarketingLabelDrawer} from './UpdateMarketingLabelDrawer'

export const MarketingLabelsPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateMarketingLabelDrawerOpen,
    setTrue: openCreateMarketingLabelDrawer,
    setFalse: closeCreateMarketingLabelDrawer
  } = useBooleanState(false)
  const history = useHistory()
  const handleExited = useCallback(
    () => history.replace(routeTo.admin.marketingLabels.index()),
    [history]
  )
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate
      header={<PrimaryHeader title={t('Marketing labels')} />}
    >
      <ChildrenOnEffectiveClientSelected>
        <MarketingLabelsList />
        <CreateMarketingLabelDrawer
          isOpen={isCreateMarketingLabelDrawerOpen}
          onClose={closeCreateMarketingLabelDrawer}
        />
        {P([PermissionCode.CreateMarketingLabel]) && (
          <CreateFab
            classes={fabClasses}
            onClick={openCreateMarketingLabelDrawer}
          />
        )}
        {P([PermissionCode.UpdateMarketingLabel]) && (
          <Route
            path={routeTo.admin.marketingLabels.edit(':marketingLabelId')}
            exact
          >
            <UpdateMarketingLabelDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
