import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  SxProps
} from '@mui/material'
import {SvgIconProps} from '@mui/material/SvgIcon'
import {makeStyles} from '@mui/styles'
import React from 'react'
import Draggable from 'react-draggable'
import {theme, Theme} from '../../../../theme'
import {Tooltip} from '../../../common'

interface IListWrapperProps {
  children: React.ReactNode | React.ReactNodeArray
}

export const ListWrapper: React.FC<IListWrapperProps> = ({
  children
}: IListWrapperProps) => <List disablePadding>{children}</List>

interface ITopSummaryProps {
  texts: string[]
  Icon: React.FC<SvgIconProps>
  tooltip: string
}

export const TopSummary: React.FC<ITopSummaryProps> = ({
  texts,
  Icon,
  tooltip
}: ITopSummaryProps) => {
  return (
    <ListItem disableGutters>
      <ListItemIcon>
        <Tooltip title={tooltip}>
          <Icon />
        </Tooltip>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          variant: 'overline',
          color: 'textSecondary'
        }}
        primary={texts.join(' • ')}
      />
    </ListItem>
  )
}

const useInfoRowStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  icon: {
    color: theme.palette.grey[600]
  },
  noIcon: {
    paddingLeft: theme.spacing(5)
  },
  uppercase: {
    textTransform: 'uppercase'
  }
}))

interface IListItemRowProps {
  texts: string[]
  tooltip?: string
  Icon?: React.FC<SvgIconProps>
  hasDivider?: boolean
}

export const ListItemRow: React.FC<IListItemRowProps> = ({
  texts,
  tooltip,
  Icon,
  hasDivider
}: IListItemRowProps) => {
  const classes = useInfoRowStyles()
  return (
    <ListItem disableGutters divider={hasDivider}>
      {Icon && (
        <ListItemIcon>
          {tooltip ? (
            <Tooltip title={tooltip}>
              <Icon className={classes.icon} />
            </Tooltip>
          ) : (
            <Icon className={classes.icon} />
          )}
        </ListItemIcon>
      )}
      <ListItemText inset={!tooltip} primary={texts.join(' • ')} />
    </ListItem>
  )
}

const PaperComponent = (props: PaperProps) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
)

const useDialogStyles = makeStyles<Theme>(() => ({
  dialogTitle: {
    cursor: 'move'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  dialogActions: {
    paddingRight: theme.spacing(2)
  }
}))

interface IBaseSeatDialogProps {
  title: string
  confirmationButtonLabel?: string
  onConfirmationButtonClick?: () => void
  onClose: () => void
  children: React.ReactNode
  isOpen: boolean
  sx?: SxProps<Theme>
}

export const BaseSeatDialog: React.FC<IBaseSeatDialogProps> = ({
  title,
  confirmationButtonLabel,
  onConfirmationButtonClick,
  onClose,
  children,
  isOpen,
  sx
}: IBaseSeatDialogProps) => {
  const classes = useDialogStyles()
  return (
    <Dialog
      open={isOpen}
      PaperComponent={PaperComponent}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      hideBackdrop
      sx={sx}
    >
      <DialogTitle className={classes.dialogTitle} id="draggable-dialog-title">
        {title}
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        {children}
      </DialogContent>
      {confirmationButtonLabel && (
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" onClick={onConfirmationButtonClick}>
            {confirmationButtonLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
