import {IZone} from '@attendio/shared-components'
import {Box, Button, List} from '@mui/material'
import {makeStyles} from '@mui/styles'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {ApiSeatState} from '../../../../../../__generated__/schema'
import {useSelectionActions} from '../../../../../../editor/redux/selection/actions'
import {Theme} from '../../../../../../theme'
import {BoldRegular, ColoredListItem} from '../../../../../common'

import {FormInput, Loading, ValidationError} from '../../../../../visual'
import {EditorSideBar} from '../../../venues/editorUtils'
import {isNonNegativeInteger} from '.././../../../../../utils/formsValidations'

import {STATES_WITH_INFO, useAllSeatStateTranslations} from './common'
import {useSeatsState, ZoneState} from './seatsStateContext'

const useStyles = makeStyles<Theme>((theme) => ({
  input: {
    width: 120,
    marginRight: theme.spacing(2),
    display: 'flex',
    alignSelf: 'center'
  }
}))

const UPDATABLE_STATES = STATES_WITH_INFO.filter(
  (v) => ![ApiSeatState.Available, ApiSeatState.Hidden].includes(v)
)

export const ZONE_SIDEBAR_FORM = 'zone sidebar form'

// Note: it is prefered by typescript to assign error to existing field in form
const TOTAL_COUNT_ERROR = ApiSeatState.Available
type FormData = {[key in ApiSeatState]: string}

interface IZoneSidebarProps {
  selectedZone: IZone
}

const getDefaultAvailable = (zone: ZoneState) => {
  return [ApiSeatState.Available, ...UPDATABLE_STATES].reduce((res, state) => {
    return res + (zone.states[state] || 0)
  }, 0)
}

const useInitForm = (selectedZoneId: string) => {
  const formProps = useForm<FormData>()
  const {errors, setError, watch, clearError} = formProps
  const {seatsState, zoneBeforeSelection} = useSeatsState()
  const [isDisabled, setIsDisabled] = useState(false)

  const values = watch()

  useEffect(() => {
    if (!seatsState || !zoneBeforeSelection) return
    const zone = zoneBeforeSelection[selectedZoneId]
    if (!zone) return

    const defaultAvailable = getDefaultAvailable(zone)
    setIsDisabled(defaultAvailable === 0)
  }, [seatsState, zoneBeforeSelection, selectedZoneId, isDisabled])

  useEffect(() => {
    if (_.isEmpty(values)) return
    if (!seatsState || !zoneBeforeSelection) return

    const zone = zoneBeforeSelection[selectedZoneId]
    if (!zone) return

    const updatableStatesTotalCount = _(values)
      .pick(UPDATABLE_STATES)
      .values()
      .map((v) => parseInt(v, 10))
      .sum()

    const defaultAvailable = getDefaultAvailable(zone)

    if (updatableStatesTotalCount > defaultAvailable) {
      if (!errors[TOTAL_COUNT_ERROR]) {
        setError(TOTAL_COUNT_ERROR, TOTAL_COUNT_ERROR)
      }
    } else {
      if (errors[TOTAL_COUNT_ERROR]) {
        clearError(TOTAL_COUNT_ERROR)
      }
    }
  }, [
    values,
    seatsState,
    zoneBeforeSelection,
    selectedZoneId,
    errors,
    setError,
    clearError
  ])

  return {formProps, isDisabled}
}

const useOnUnmount = () => {
  const {resetZoneBeforeSelection} = useSeatsState()

  useEffect(() => {
    return () => resetZoneBeforeSelection()
    // We want to run this only on unmount
  }, []) // eslint-disable-line
}

export const ZoneSidebar: React.FC<IZoneSidebarProps> = ({
  selectedZone
}: IZoneSidebarProps) => {
  const {t} = useTranslation()
  const {formProps, isDisabled} = useInitForm(selectedZone.id)
  const {register, errors, handleSubmit} = formProps
  const classes = useStyles()
  const seatStateTranslations = useAllSeatStateTranslations()
  const {
    seatsState,
    zoneBeforeSelection,
    updateZoneState,
    setIgnoreNextDeselectAll
  } = useSeatsState()
  const {unselectAll} = useSelectionActions()

  useOnUnmount()

  if (!seatsState || !zoneBeforeSelection) return null

  const zone = zoneBeforeSelection[selectedZone.id]

  if (!zone) return <Loading />

  const otherStatesCount = _(zone.states).omit(STATES_WITH_INFO).values().sum()

  return (
    <>
      <EditorSideBar>
        <BoldRegular>{t('Zone ticket states')}</BoldRegular>
      </EditorSideBar>
      <form
        id={ZONE_SIDEBAR_FORM}
        onSubmit={handleSubmit((data) => {
          if (_.isEmpty(errors)) {
            updateZoneState(
              selectedZone.id,
              _(data)
                .pick(UPDATABLE_STATES)
                .mapValues((v) => parseInt(v, 10))
                .value()
            )

            // Prevent unnecessary socket call cased by deselection in the next step,
            // TODO: can we do better, using more straightforward approach?
            setIgnoreNextDeselectAll(true)
            unselectAll()
          }
        })}
      >
        <List component="nav">
          <ColoredListItem
            title={t('Other')}
            description={t(
              'Can not be edited. Seats in occupied, reserved or purchased states.'
            )}
            input={
              <div className={classes.input}>
                <FormInput
                  defaultValue={otherStatesCount}
                  type="number"
                  disabled
                  name="other"
                  inputRef={register({
                    required: true
                  })}
                />
              </div>
            }
          />
          <ColoredListItem
            title={seatStateTranslations.available.state}
            description={seatStateTranslations.available.desc}
            input={
              <div className={classes.input}>
                <FormInput
                  defaultValue={getDefaultAvailable(zone)}
                  type="number"
                  disabled
                  name={ApiSeatState.Available}
                  inputRef={register({
                    required: true
                  })}
                />
              </div>
            }
          />
          {UPDATABLE_STATES.map((seatState) => {
            const trObject = seatStateTranslations[seatState]
            const errorMessage = _.get(errors, [seatState, 'message'])
            return (
              <ColoredListItem
                key={seatState}
                title={trObject.state}
                description={
                  errorMessage ? (
                    <ValidationError>{errorMessage}</ValidationError>
                  ) : (
                    trObject.desc
                  )
                }
                input={
                  <div className={classes.input}>
                    <FormInput
                      defaultValue={zone.states[seatState] || 0}
                      type="number"
                      name={seatState}
                      disabled={isDisabled}
                      hasError={
                        !!errors[seatState] || !!errors[TOTAL_COUNT_ERROR]
                      }
                      inputRef={register({
                        required: true,
                        validate: (v) =>
                          isNonNegativeInteger(v) ||
                          (t('Value must be a non negative integer') as string)
                      })}
                    />
                  </div>
                }
              />
            )
          })}
        </List>
      </form>
      <Box marginLeft={1.5} height={'30px'}>
        {!!errors[TOTAL_COUNT_ERROR] && (
          <ValidationError>
            {t('Can not set more seats than available!')}
          </ValidationError>
        )}
      </Box>
      <Box marginLeft={1.5}>
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          form={ZONE_SIDEBAR_FORM}
          disabled={isDisabled}
        >
          {t('Apply')}
        </Button>
      </Box>
    </>
  )
}
