import {useTranslation} from 'react-i18next'
import {CustomerState} from '../__generated__/schema'

export const useTranslateCustomerState = () => {
  const {t} = useTranslation()
  const translatedState: Record<CustomerState, string> = {
    [CustomerState.Active]: t<string>('CustomerState->Active'),
    [CustomerState.Disabled]: t<string>('CustomerState->Disabled'),
    [CustomerState.Invited]: t<string>('CustomerState->Invited'),
    [CustomerState.Anonymized]: t<string>('CustomerState->Anonymized')
  }
  return (state: CustomerState) => translatedState[state]
}

export const useTranslateCustomerStateInFilterChip = () => {
  const {t} = useTranslation()
  const translatedState: Record<CustomerState, string> = {
    [CustomerState.Active]: t<string>('Active customers'),
    [CustomerState.Disabled]: t<string>('Disabled customers'),
    [CustomerState.Invited]: t<string>('Invited customers'),
    [CustomerState.Anonymized]: t<string>('Anonymized customers')
  }
  return (state: CustomerState) => translatedState[state]
}
