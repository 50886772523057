import React from 'react'
import {useTranslation} from 'react-i18next'
import {TourItemPropertiesFragment} from '../../../../../__generated__/schema'
import {Blank} from '../../../../visual/Blank'

interface IChildrenOnValidTourTimeSlotDataProps {
  children: React.ReactElement
  tourItems: TourItemPropertiesFragment[]
}

export const ChildrenOnValidTourTimeSlotData: React.FC<IChildrenOnValidTourTimeSlotDataProps> =
  ({children, tourItems}: IChildrenOnValidTourTimeSlotDataProps) => {
    const {t} = useTranslation()
    if (tourItems.length === 0) {
      return (
        <Blank
          title={t('No items to be discounted')}
          description={t('Add first ticket for tour')}
        />
      )
    }
    return children
  }
