import {AutocompleteInputChangeReason} from '@mui/base/useAutocomplete/useAutocomplete'
import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  FormControl,
  Paper,
  TextField
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {FormValidationError} from '../common'
import {FormFieldHelper} from '../visual/common'

import {FormFieldName} from './types'
import {getValueInUncontrolledFormComponent, useHasError} from './utils'

const filter = createFilterOptions()

const PaperComponent = ({children}: {children?: React.ReactNode}) => (
  <Paper elevation={8}>{children}</Paper>
)

type AutocompleteOptions<FormValues extends FieldValues = FieldValues> = {
  value: FormValues[FormFieldName<FormValues>]
  name: string
  additionalValue?: string
}[]

interface IFormAutocompleteProps<FormValues extends FieldValues = FieldValues> {
  label: string
  autocompleteOptions: AutocompleteOptions<FormValues>
  errors: FieldErrors<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  register: FormContextValues<FormValues>['register']
  watch: FormContextValues<FormValues>['watch']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
  cypressId?: string
  helperNote?: string
  required?: boolean
  /**
   * noOptionsText is displayed instead of options, when no option suits text in autocomplete
   */
  noOptionsText?: string
  disableClearable?: boolean
  fullWidth?: boolean
  defaultValue?: string
  hideErrorMessage?: boolean
  placeholder?: string
  disabled?: boolean
  onInputChange?: (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void
  loading?: boolean
  onCreate?: (name: string) => void
  getOptionDisabled?: (
    option: AutocompleteOptions<FormValues>[number]
  ) => boolean
}

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  }
}))

export const FormAutocomplete = <FormValues extends FieldValues = FieldValues>({
  label,
  autocompleteOptions,
  errors,
  setValue,
  register,
  watch,
  validationOptions,
  name,
  cypressId,
  helperNote,
  required,
  noOptionsText,
  disableClearable,
  fullWidth,
  defaultValue,
  hideErrorMessage,
  placeholder,
  disabled,
  loading,
  onInputChange,
  onCreate,
  getOptionDisabled
}: IFormAutocompleteProps<FormValues>) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const value = getValueInUncontrolledFormComponent({name, defaultValue, watch})
  const hasError = useHasError<FormValues>(errors, name)
  const handleChange = useCallback(
    (_event, changeValue: AutocompleteOptions<FormValues>[number] | null) => {
      if (onCreate && changeValue?.value === 'non-existing-value') {
        onCreate(changeValue.additionalValue || changeValue.name)
      } else {
        setValue(
          name,
          changeValue?.value as FormValues[FormFieldName<FormValues>],
          true
        )
      }
    },
    [name, onCreate, setValue]
  )

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" error={hasError}>
      <select
        className={classes.hidden}
        ref={validationOptions ? register(validationOptions) : register()}
        name={name}
        cypress-id={cypressId}
        defaultValue={defaultValue as string}
      >
        {/*// Keep this empty value, so you can prevent autofill on form submit*/}
        <option />
        {autocompleteOptions.map(({value: optionValue, name}) => (
          <option key={optionValue} value={optionValue}>
            {name}
          </option>
        ))}
      </select>
      <Autocomplete
        size="small"
        getOptionDisabled={getOptionDisabled}
        loading={loading}
        disabled={disabled}
        noOptionsText={noOptionsText}
        disableClearable={disableClearable}
        clearOnBlur={false}
        fullWidth={fullWidth}
        PaperComponent={PaperComponent}
        value={
          // eslint-disable-next-line eqeqeq
          autocompleteOptions.find((option) => option.value == value) || null
        }
        options={autocompleteOptions}
        onInputChange={onInputChange}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        filterOptions={
          onCreate
            ? (
                options: AutocompleteOptions<FormValues>,
                params: FilterOptionsState<any>
              ) => {
                const filtered = filter(options, params)
                const isExisting = options.some(
                  (option) =>
                    params.inputValue.toLowerCase() ===
                    option.name.toLowerCase()
                )
                if (params.inputValue !== '' && !isExisting) {
                  filtered.push({
                    value: 'non-existing-value',
                    name: t('Create "{{name}}"', {name: params.inputValue}),
                    additionalValue: params.inputValue
                  })
                }
                return filtered as AutocompleteOptions<FormValues>
              }
            : undefined
        }
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            color="primary"
            required={required}
            placeholder={placeholder}
            autoComplete="off"
            error={hasError}
            inputProps={{
              ...params.inputProps,
              form: {
                autocomplete: 'off'
              }
            }}
          />
        )}
      />
      {hasError && !hideErrorMessage ? (
        <FormValidationError<FormValues> errors={errors} fieldName={name} />
      ) : (
        <FormFieldHelper required={required} note={helperNote} />
      )}
    </FormControl>
  )
}
