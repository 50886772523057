export enum EventCategoryState {
  Active = 'active',
  Inactive = 'inactive'
}

export enum EventCategoryFormField {
  Name = 'name',
  Label = 'label',
  IsActive = 'isActive'
}

export interface IEventCategoryForm {
  [EventCategoryFormField.Name]: string
  [EventCategoryFormField.Label]?: string
  [EventCategoryFormField.IsActive]: EventCategoryState
}
