import {useTranslation} from 'react-i18next'
import {PermissionCode} from '../../../../__generated__/schema'

import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'

interface IItem {
  to: string
  label: string
  permissions?: Array<PermissionCode>
}

export interface IMenuCategory {
  id: string
  items: Array<IItem>
  label: string
  permissions?: Array<PermissionCode>
}

export const useMenuCategories: () => Array<IMenuCategory> = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {effectiveClient} = useUserInfo()
  return [
    {
      id: 'shop',
      label: t('Shop'),
      items: [
        {
          to: routeTo.admin.cashDesk.deviceInformation(),
          label: t('Cash desk')
        },
        {
          to: routeTo.admin.carts.index(),
          label: t('Carts'),
          permissions: [PermissionCode.ReadCarts]
        },
        {
          to: routeTo.admin.claims.index(),
          label: t('Claims'),
          permissions: [PermissionCode.ReadClaims]
        },
        {
          to: routeTo.admin.cashCounter(),
          label: t('Cash counter'),
          permissions: [PermissionCode.AccessCashCounter]
        },
        {
          to: '',
          label: t('Balances')
        }
      ]
    },
    {
      label: t('Check'),
      id: 'check',
      items: [
        {
          to: routeTo.admin.checkTickets.index(),
          label: t('Check tickets'),
          permissions: [PermissionCode.CheckPassCodeIn]
        },
        {
          to: routeTo.admin.passCodeCheckHistory.index(),
          label: t('Tickets check history'),
          permissions: [PermissionCode.ReadPassCodeChecks]
        }
      ]
    },
    {
      label: t('Statistics'),
      id: 'statistics',
      items: [
        {
          to: routeTo.admin.paymentReports.index(),
          label: t('Payment reports'),
          permissions: [PermissionCode.ReadPaymentReports]
        },
        {
          to: routeTo.admin.eventsStatistics.index(),
          label: t('Events statistics'),
          permissions: [PermissionCode.ReadEventsStatistics]
        },
        {
          to: routeTo.admin.showsStatistics.index(),
          label: t('Shows statistics'),
          permissions: [PermissionCode.ReadShowsStatistics]
        },
        {
          to: routeTo.admin.tourTimeSlotsStatistics.index(),
          label: t('Tour time slots statistics'),
          permissions: [PermissionCode.ReadTourTimeSlotsStatistics]
        },
        {
          to: routeTo.admin.admissionTypesStatistics.index(),
          label: t('Admission types statistics'),
          permissions: [PermissionCode.ReadAdmissionTypesStatistics]
        },
        {
          to: routeTo.admin.discountsStatistics.index(),
          label: t('Discounts statistics'),
          permissions: [PermissionCode.ReadDiscountsStatistics]
        },
        {
          to: routeTo.admin.productsStatistics.index(),
          label: t('Products statistics'),
          permissions: [PermissionCode.ReadProductsStatistics]
        },
        {
          to: routeTo.admin.warehouseStocks.index(),
          label: t('Warehouse stocks'),
          permissions: [PermissionCode.ReadWarehouseProducts]
        },
        {
          to: routeTo.admin.inventoryTransactions.index(),
          label: t('Inventory transactions'),
          permissions: [PermissionCode.ReadWarehouseDocumentItems]
        },
        {
          to: routeTo.admin.paymentsOverview.index(),
          label: t('Payments overview'),
          permissions: [PermissionCode.ReadPayments]
        },
        {
          to: routeTo.admin.voucherTransactions.index(),
          label: t('Voucher transactions'),
          permissions: [PermissionCode.ReadVoucherTransactionIntents]
        }
      ]
    },
    {
      label: t('Manage'),
      id: 'manage',
      items: [
        {
          to: routeTo.admin.shows.home(),
          label: t('Shows'),
          permissions: [PermissionCode.ManageClientShows]
        },
        {
          to: routeTo.admin.events.home(),
          label: t('Events'),
          permissions: [PermissionCode.ManageEvents]
        },
        {
          to: routeTo.admin.tours.index(),
          label: t('Tours'),
          permissions: [PermissionCode.ManageTours, PermissionCode.ReadTours]
        },
        {
          to: routeTo.admin.websites.index(),
          label: t('Websites'),
          permissions: [
            PermissionCode.ManageWebsites,
            PermissionCode.ReadWebsites
          ]
        },
        {
          to: routeTo.admin.venues.home(),
          label: t('Venues'),
          permissions: [PermissionCode.ManageVenues]
        },
        {
          to: routeTo.admin.discounts.home(),
          permissions: [PermissionCode.ReadDiscounts],
          label: t('Discounts')
        },
        {
          to: routeTo.admin.products.index(),
          permissions: [
            PermissionCode.ManageProducts,
            PermissionCode.ReadProducts
          ],
          label: t('Products')
        },
        {
          to: routeTo.admin.warehouseDocuments.index(),
          permissions: [
            PermissionCode.ManageWarehouseDocuments,
            PermissionCode.ReadWarehouseDocuments
          ],
          label: t('Warehouse documents')
        },
        {
          to: routeTo.admin.productGroups.index(),
          permissions: [
            PermissionCode.ManageProductGroups,
            PermissionCode.ReadProductGroups
          ],
          label: t('Product groups')
        },
        {
          to: routeTo.admin.messages.index(),
          permissions: [
            PermissionCode.ManageMessages,
            PermissionCode.ReadMessages
          ],
          label: t('Messages')
        },
        {
          to: routeTo.admin.vouchers.index(),
          permissions: [
            PermissionCode.ManageVouchers,
            PermissionCode.ReadVouchers
          ],
          label: t('Vouchers')
        },
        {
          to: routeTo.admin.customers.index(),
          permissions: [
            PermissionCode.ManageCustomers,
            PermissionCode.ReadCustomers
          ],
          label: t('Customers')
        },
        {
          to: routeTo.admin.businessPartners.index(),
          permissions: [
            PermissionCode.ManageBusinessPartners,
            PermissionCode.ReadBusinessPartners
          ],
          label: t('Business partners')
        },
        {
          to: '',
          label: t('Loyalty')
        },
        {
          to: '',
          label: t('Newsletters')
        },
        {
          to: '',
          label: t('Customers')
        },
        {
          to: '',
          label: t('Selling points')
        }
      ]
    },
    {
      label: t('Account'),
      id: 'account',
      items: [
        {
          to: routeTo.admin.profile(),
          label: t('My profile')
        },
        {
          to: effectiveClient ? routeTo.admin.company() : '',
          label: t('Company'),
          permissions: [PermissionCode.UpdateCompany]
        },
        {
          to: routeTo.admin.divisions.home(),
          label: t('Divisions'),
          permissions: [PermissionCode.ManageDivisions]
        },
        {
          to: routeTo.admin.costCenters.index(),
          label: t('Cost centers'),
          permissions: [PermissionCode.ManageCostCenters]
        },
        {
          to: routeTo.admin.eventCategories.index(),
          label: t('Event categories'),
          permissions: [
            PermissionCode.ManageEventCategories,
            PermissionCode.ReadEventCategories
          ]
        },
        {
          to: routeTo.admin.marketingLabels.index(),
          label: t('Marketing labels'),
          permissions: [
            PermissionCode.ManageMarketingLabels,
            PermissionCode.ReadMarketingLabels
          ]
        },
        {
          to: routeTo.admin.users.home(),
          label: t('Users'),
          permissions: [PermissionCode.ManageUsers]
        },
        {
          to: routeTo.admin.paymentMethods.home(),
          permissions: [PermissionCode.ManagePaymentMethods],
          label: t('Payment methods')
        },
        {
          to: routeTo.admin.productTypes.index(),
          permissions: [
            PermissionCode.ReadProductTypes,
            PermissionCode.ManageProductTypes
          ],
          label: t('Product types')
        },
        {
          to: routeTo.admin.warehouses.index(),
          permissions: [
            PermissionCode.ManageWarehouses,
            PermissionCode.ReadWarehouses
          ],
          label: t('Warehouses')
        },
        {
          to: routeTo.admin.voucherCampaigns.index(),
          permissions: [
            PermissionCode.ManageVoucherCampaigns,
            PermissionCode.ReadVoucherCampaigns
          ],
          label: t('Voucher campaigns')
        },
        {
          to: routeTo.admin.customerGroups.index(),
          permissions: [
            PermissionCode.ManageCustomerGroups,
            PermissionCode.ReadCustomerGroups
          ],
          label: t('Customer groups')
        },
        {
          to: routeTo.admin.admissionTypes.index(),
          permissions: [
            PermissionCode.ManageAdmissionTypes,
            PermissionCode.ReadAdmissionTypes
          ],
          label: t('Admission types')
        },
        {
          to: routeTo.admin.accessZones.index(),
          permissions: [
            PermissionCode.ManageAccessZones,
            PermissionCode.ReadAccessZones
          ],
          label: t('Access zones')
        },
        {
          to: '',
          label: t('Billing')
        }
      ]
    },
    {
      label: t('Settings'),
      id: 'settings',
      items: [
        {
          to: '',
          label: t('Picklists')
        },
        {
          to: '',
          label: t('Security')
        },
        {
          to: '',
          label: t('Tags')
        },
        {
          to: '',
          label: t('Numbering sequences')
        },
        {
          to: '',
          label: t('API keys')
        }
      ]
    },
    {
      label: t('Explore'),
      id: 'explore',
      items: []
    },
    {
      label: t('Support'),
      id: 'support',
      items: [
        {
          to: routeTo.admin.cartsSupport.index(),
          label: t('Carts for support'),
          permissions: [PermissionCode.ReadCartsSupportUser]
        },
        {
          to: routeTo.admin.paymentsSupport.index(),
          label: t('Payments for support'),
          permissions: [
            PermissionCode.ReadPayments,
            PermissionCode.ReadPaymentsForSupport
          ]
        },
        {
          to: routeTo.admin.clients.home(),
          label: t('Clients'),
          permissions: [PermissionCode.ReadClients]
        },
        {
          to: routeTo.admin.roles.index(),
          label: t('Roles'),
          permissions: [PermissionCode.ManageRoles]
        },
        {
          to: '',
          label: t('All divisions')
        },
        {
          to: '',
          label: t('All users')
        },
        {
          to: routeTo.admin.library.home(),
          label: t('Library'),
          permissions: [PermissionCode.ReadLibraryShows]
        },
        {
          to: routeTo.admin.templates.index(),
          label: t('Templates'),
          permissions: [PermissionCode.ManageTemplates]
        },
        {
          to: routeTo.admin.apiGrants.index(),
          label: t('API grants'),
          permissions: [PermissionCode.ReadApiGrants]
        },
        {
          to: routeTo.admin.support.duplicateAuditoriumLayout(),
          label: t('Duplicate auditorium layout'),
          permissions: [PermissionCode.DuplicateAuditoriumLayoutSupportUser]
        }
      ]
    }
  ]
    .filter((category: IMenuCategory) => P(category.permissions || []))
    .map((category: IMenuCategory) => ({
      ...category,
      items: category.items.filter(
        (item) => Boolean(item.to) && P(item.permissions || [])
      )
    }))
    .filter((category: IMenuCategory) => category.items.length)
}
