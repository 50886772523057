import {InputBaseComponentProps, OutlinedTextFieldProps} from '@mui/material'
import get from 'lodash/get'
import {useCallback, useEffect} from 'react'
import {
  FieldError,
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'

import {FormFieldName} from './types'

export const useHasError = <FormValues extends FieldValues = FieldValues>(
  errors: FieldErrors<FormValues>,
  name: FormFieldName<FormValues>
): boolean => !!get(errors, name)

export const getFormError = <FormValues extends FieldValues = FieldValues>(
  errors: FieldErrors<FormValues>,
  name: FormFieldName<FormValues>
): FieldError | undefined => get(errors, name) as FieldError | undefined

export const useHandleChange = <FormValues extends FieldValues = FieldValues>({
  name,
  setValue,
  hasError
}: {
  name: FormFieldName<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  hasError: boolean
}) =>
  useCallback(
    (e) => {
      setValue(name, e.target.value, hasError)
    },
    [hasError, name, setValue]
  )

export const getValueInUncontrolledFormComponent = <
  FormValues extends FieldValues = FieldValues
>({
  name,
  defaultValue,
  watch
}: {
  name: FormFieldName<FormValues>
  defaultValue?: unknown
  watch: FormContextValues<FormValues>['watch']
}) => {
  const watchedValue = watch(name)
  return watchedValue ?? defaultValue ?? ''
}

export const useHandleBlur = <FormValues extends FieldValues = FieldValues>({
  triggerValidation,
  name
}: {
  triggerValidation?: FormContextValues<FormValues>['triggerValidation']
  name: FormFieldName<FormValues>
}) =>
  useCallback(() => {
    if (triggerValidation) {
      triggerValidation(name)
    }
  }, [name, triggerValidation])

export const useCustomReactHookFormRegistration = <
  FormValues extends FieldValues = FieldValues
>({
  register,
  unregister,
  validationOptions,
  name
}: {
  register: FormContextValues<FormValues>['register']
  unregister: FormContextValues<FormValues>['unregister']
  validationOptions?: ValidationOptions
  name: FormFieldName<FormValues>
}) =>
  useEffect(() => {
    register(
      {
        name
      },
      validationOptions
    )
    return () => {
      unregister(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, register, JSON.stringify(validationOptions)])

export const cloneInputModeInInputProps = (
  InputProps: OutlinedTextFieldProps['InputProps'],
  inputMode?: InputBaseComponentProps['inputMode']
) =>
  inputMode
    ? {
        ...InputProps,
        inputProps: {
          ...InputProps?.inputProps,
          inputMode
        }
      }
    : InputProps
