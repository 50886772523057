import {Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AuditoriumPropertiesFragment,
  AuditoriumState
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {ConfirmationDialog} from '../../../../common/ConfirmationDialog'
import {SplitButton} from '../../../../visual'

import {
  AuditoriumForm,
  AuditoriumFormField,
  IAuditoriumFormData
} from './AuditoriumForm'
import {useUpdateAuditorium} from './graphql'

const UPDATE_AUDITORIUM_FORM_ID = 'updateAuditoriumForm'

interface IEditAuditoriumDrawerProps {
  auditorium: AuditoriumPropertiesFragment
  venueId: number
  onExited: () => void
}

export const EditAuditoriumDrawer: React.FC<IEditAuditoriumDrawerProps> = ({
  auditorium,
  venueId,
  onExited
}: IEditAuditoriumDrawerProps) => {
  const {t} = useTranslation()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const updateAuditorium = useUpdateAuditorium()
  const auditoriumState = useRef<AuditoriumState>(auditorium.state)
  const {
    state: isConfirmationDialogOpen,
    setTrue: openConfirmationDialog,
    setFalse: closeConfirmationDialog
  } = useBooleanState(false)
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const changeAuditoriumState = useCallback(
    (state: AuditoriumState) => () => (auditoriumState.current = state),
    []
  )
  const onSubmit = useCallback(
    async (data: IAuditoriumFormData) => {
      try {
        setShowBackdrop(true)
        await updateAuditorium({
          id: auditorium.id,
          data: {
            venueId,
            name: data[AuditoriumFormField.Name],
            shortcut: data[AuditoriumFormField.Shortcut],
            state: auditoriumState.current,
            defaultDivisionId:
              data[AuditoriumFormField.DefaultDivisionId] || null
          }
        })
        addInfoNotification(t('Auditorium edited'))
        closeConfirmationDialog()
        closeDrawer()
      } catch (e) {
        defaultErrorHandler(e, t('Error while editing auditorium'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      setShowBackdrop,
      updateAuditorium,
      auditorium.id,
      venueId,
      addInfoNotification,
      t,
      closeConfirmationDialog,
      closeDrawer,
      defaultErrorHandler
    ]
  )

  useEffect(() => {
    openDrawer()
  }, [openDrawer])

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      SlideProps={{onExited}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Edit auditorium')}
          />
        }
        footer={
          <SplitButton
            Options={[
              <MenuItem
                key="save-as"
                component="button"
                onClick={openConfirmationDialog}
              >
                {auditorium.state === AuditoriumState.Active
                  ? t('Save as archived')
                  : t('Save as active')}
              </MenuItem>
            ]}
          >
            <SaveButton type="submit" form={UPDATE_AUDITORIUM_FORM_ID}>
              {t('Save')}
            </SaveButton>
          </SplitButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <AuditoriumForm
          onSubmit={onSubmit}
          formId={UPDATE_AUDITORIUM_FORM_ID}
          defaultValues={{
            [AuditoriumFormField.Name]: auditorium.name,
            [AuditoriumFormField.Shortcut]: auditorium.shortcut,
            [AuditoriumFormField.DefaultDivisionId]:
              auditorium.defaultDivision?.id
          }}
        />
        <ConfirmationDialog
          title={
            auditorium.state === AuditoriumState.Active
              ? t('Are you sure you want to save and archive auditorium?')
              : t('Are you sure you want to save and activate auditorium?')
          }
          contentText={
            auditorium.state === AuditoriumState.Active
              ? t('Are you sure you want to save and archive auditorium?')
              : t('Are you sure you want to save and activate auditorium?')
          }
          onConfirm={
            auditorium.state === AuditoriumState.Active
              ? changeAuditoriumState(AuditoriumState.Archived)
              : changeAuditoriumState(AuditoriumState.Active)
          }
          confirmButtonProps={{
            type: 'submit',
            form: UPDATE_AUDITORIUM_FORM_ID
          }}
          isOpen={isConfirmationDialogOpen}
          onCancel={closeConfirmationDialog}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
