import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {
  CheckoutMutation,
  CheckoutMutationVariables,
  DiscardCartMutation,
  DiscardCartMutationVariables,
  PostponeCartExpirationMutation,
  PostponeCartExpirationMutationVariables,
  ReserveMutation,
  ReserveMutationVariables
} from '../../../../../__generated__/schema'
import {CART_PROPERTIES_FRAGMENT} from '../../graphql'

const DISCARD_CART = gql`
  mutation DiscardCart($cartId: Int!) {
    discardCart(cartId: $cartId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useDiscardCart = () => {
  const [discardCart] =
    useMutation<DiscardCartMutation, DiscardCartMutationVariables>(DISCARD_CART)
  return (cartId: number) => discardCart({variables: {cartId}})
}

const RESERVE = gql`
  mutation reserve(
    $cartId: Int!
    $input: LeadDataInput
    $sendEmail: Boolean = false
    $customerId: PositiveInt
  ) {
    reserve(
      cartId: $cartId
      input: $input
      sendEmail: $sendEmail
      customerId: $customerId
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useReserve = () => {
  const [reserve] =
    useMutation<ReserveMutation, ReserveMutationVariables>(RESERVE)
  return (variables: ReserveMutationVariables) => reserve({variables})
}
const CHECKOUT = gql`
  mutation checkout(
    $input: LeadDataInput
    $paymentIntentInputs: [PaymentIntentInput!]!
    $cartId: Int!
    $sendEmail: Boolean
    $customerId: PositiveInt
  ) {
    checkout(
      input: $input
      cartId: $cartId
      paymentIntentInputs: $paymentIntentInputs
      sendEmail: $sendEmail
      customerId: $customerId
    ) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const useCheckout = () => {
  const [checkout] =
    useMutation<CheckoutMutation, CheckoutMutationVariables>(CHECKOUT)
  return (variables: CheckoutMutationVariables) => checkout({variables})
}

const POSTPONE_CART_EXPIRATION = gql`
  mutation postponeCartExpiration($cartId: Int!) {
    postponeCartExpiration(cartId: $cartId) {
      ...CartProperties
    }
  }
  ${CART_PROPERTIES_FRAGMENT}
`

export const usePostponeCartExpiration = () => {
  const [postponeCartExpiration] = useMutation<
    PostponeCartExpirationMutation,
    PostponeCartExpirationMutationVariables
  >(POSTPONE_CART_EXPIRATION)
  return (cartId: number) => postponeCartExpiration({variables: {cartId}})
}

export const VOUCHER_PROPERTIES_FRAGMENT = gql`
  fragment VoucherProperties on Voucher {
    id
    balance
    state
    activatedAt
    code
    expirationDate
    campaign {
      id
      name
      state
      retailPaymentMethod {
        id
        state
      }
    }
  }
`

export const GET_VOUCHER_BY_CODE = gql`
  ${VOUCHER_PROPERTIES_FRAGMENT}
  query GetVoucherByCode($code: NonEmptyString!) {
    voucherByCodeOnRetailChannel(code: $code) {
      ...VoucherProperties
    }
  }
`
