import {useLazyQuery} from '@apollo/react-hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material'
import {styled} from '@mui/system'
import React, {useCallback, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AdmissionRatesQuery,
  LightweightToursQuery,
  LightweightToursQueryVariables
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useIsStringWithMaxLength} from '../../../../../utils/formsValidations'
import {routeTo} from '../../../../../utils/routes'
import {InputRow} from '../../../../common'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {
  BasicRadioLabel,
  UncontrolledFormRadioGroup
} from '../../../../form/UncontrolledFormRadioGroup'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {LIGHTWEIGHT_TOURS} from '../../graphql'
import {useCopyAdmissionRate} from '../graphql'
import {
  CopyAdmissionRateDestinationOption,
  CopyAdmissionRateFormField,
  ICopyAdmissionRateForm
} from '../types'

const COPY_ADMISSION_RATE_FORM_ID = 'copyAdmissionRateForm'

const StyledForm = styled('form')(() => ({
  display: 'grid',
  gridAutoFlow: 'row'
}))

interface ICopyAdmissionRateDialogProps {
  onClose: () => void
  admissionRate: AdmissionRatesQuery['admissionRates']['items'][number]
}

export const CopyAdmissionRateDialog: React.FC<ICopyAdmissionRateDialogProps> =
  ({onClose, admissionRate}: ICopyAdmissionRateDialogProps) => {
    const {t} = useTranslation()
    const [tours, setTours] =
      useState<LightweightToursQuery['tours']['items'] | null>(null)
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const copyAdmissionRate = useCopyAdmissionRate()
    const [getTours, {loading: toursLoading}] = useLazyQuery<
      LightweightToursQuery,
      LightweightToursQueryVariables
    >(LIGHTWEIGHT_TOURS, {
      fetchPolicy: 'network-only',
      variables: {paginationInput: {offset: 0, limit: 300}},
      onCompleted: (data) => setTours(data.tours.items),
      onError: (error) =>
        defaultErrorHandler(error, t('Error while loading tours'))
    })
    const {
      register,
      errors,
      setValue,
      triggerValidation,
      watch,
      control,
      handleSubmit
    } = useForm<ICopyAdmissionRateForm>({
      defaultValues: {
        [CopyAdmissionRateFormField.AdmissionRateName]: t('Copy - {{name}}', {
          name: admissionRate.name
        }),
        [CopyAdmissionRateFormField.AdmissionRateId]: String(admissionRate.id)
      }
    })
    const history = useHistory()
    const isStringWithMaxLength = useIsStringWithMaxLength(255)
    const selectedDestination = watch(CopyAdmissionRateFormField.Destination)
    const selectedAnotherTourId = watch(
      CopyAdmissionRateFormField.AnotherTourId
    )
    const _handleSubmit = useCallback(
      async (formData: ICopyAdmissionRateForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await copyAdmissionRate({
            input: {
              admissionRateId: parseInt(
                formData[CopyAdmissionRateFormField.AdmissionRateId],
                10
              ),
              admissionRateName:
                formData[CopyAdmissionRateFormField.AdmissionRateName],
              tourId: parseInt(formData[CopyAdmissionRateFormField.TourId], 10)
            }
          })
          if (data) {
            history.push(
              routeTo.admin.tours.admissionRateDetail(
                data.copyAdmissionRate.tourId,
                data.copyAdmissionRate.id
              )
            )
          }
          addInfoNotification(t('Admission rate has been copied'))
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while copying admission rate'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        copyAdmissionRate,
        defaultErrorHandler,
        history,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    useEffect(() => {
      if (
        selectedDestination === CopyAdmissionRateDestinationOption.AnotherTour
      ) {
        getTours()
      }
    }, [getTours, selectedDestination])
    useEffect(() => {
      if (selectedDestination === CopyAdmissionRateDestinationOption.ThisTour) {
        setValue(
          CopyAdmissionRateFormField.TourId,
          String(admissionRate.tour.id)
        )
      }
      if (
        selectedDestination ===
          CopyAdmissionRateDestinationOption.AnotherTour &&
        selectedAnotherTourId
      ) {
        setValue(CopyAdmissionRateFormField.TourId, selectedAnotherTourId)
      }
    }, [
      admissionRate.tour.id,
      selectedAnotherTourId,
      selectedDestination,
      setValue
    ])
    return (
      <Dialog open sx={{[`& .${dialogClasses.paper}`]: {width: 600}}}>
        <DialogTitle>
          <Typography component="div" variant="h6">
            {t('Copy admission rate')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <StyledForm
            id={COPY_ADMISSION_RATE_FORM_ID}
            onSubmit={handleSubmit(_handleSubmit)}
          >
            <input
              type="hidden"
              ref={register()}
              name={CopyAdmissionRateFormField.TourId}
            />
            <input
              type="hidden"
              ref={register()}
              name={CopyAdmissionRateFormField.AdmissionRateId}
            />
            <InputRow
              nodes={[
                <UncontrolledFormTextInput<ICopyAdmissionRateForm>
                  fullWidth
                  label={t('Admission rate name')}
                  name={CopyAdmissionRateFormField.AdmissionRateName}
                  key={CopyAdmissionRateFormField.AdmissionRateName}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                  watch={watch}
                  required
                  validationOptions={{
                    required: true,
                    validate: {
                      isStringWithMaxLength
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() =>
                          setValue(
                            CopyAdmissionRateFormField.AdmissionRateName,
                            ''
                          )
                        }
                      >
                        <CancelIcon />
                      </IconButton>
                    )
                  }}
                />
              ]}
            />
            <InputRow
              nodes={[
                <UncontrolledFormRadioGroup<
                  ICopyAdmissionRateForm,
                  CopyAdmissionRateDestinationOption
                >
                  label={t('Select destination for new copy')}
                  name={CopyAdmissionRateFormField.Destination}
                  key={CopyAdmissionRateFormField.Destination}
                  errors={errors}
                  validationOptions={{required: true}}
                  fullWidth
                  control={control}
                  options={[
                    {
                      value: CopyAdmissionRateDestinationOption.ThisTour,
                      label: (
                        <BasicRadioLabel
                          primaryText={admissionRate.tour.name}
                          secondaryText={t(
                            'Create copy of {{admissionRateName}} to tour {{tourName}}.',
                            {
                              admissionRateName: admissionRate.name,
                              tourName: admissionRate.tour.name
                            }
                          )}
                        />
                      )
                    },
                    {
                      value: CopyAdmissionRateDestinationOption.AnotherTour,
                      label: (
                        <BasicRadioLabel
                          primaryText={t('Another tour')}
                          secondaryText={t(
                            'Create copy of {{admissionRateName}} to another tour.',
                            {
                              admissionRateName: admissionRate.name
                            }
                          )}
                        />
                      )
                    }
                  ]}
                />
              ]}
            />
            {selectedDestination ===
              CopyAdmissionRateDestinationOption.AnotherTour && (
              <InputRow
                nodes={[
                  <UncontrolledFormSelect<ICopyAdmissionRateForm>
                    errors={errors}
                    watch={watch}
                    register={register}
                    label={
                      toursLoading ? t('Loading...') : t('Destination tour')
                    }
                    setValue={setValue}
                    name={CopyAdmissionRateFormField.AnotherTourId}
                    key={CopyAdmissionRateFormField.AnotherTourId}
                    fullWidth
                    hasNoneSelectOption
                    selectOptions={(tours || [])
                      .filter(({id}) => id !== admissionRate.tour.id)
                      .reduce(
                        (acc, tour) => ({
                          ...acc,
                          [String(tour.id)]: tour.name
                        }),
                        {}
                      )}
                    required
                    validationOptions={{required: true}}
                    disabled={toursLoading}
                  />
                ]}
              />
            )}
          </StyledForm>
        </DialogContent>
        <DialogActions sx={{px: 3, display: 'flex', gap: 2}}>
          <Button variant="text" color="primary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            variant="text"
            color="primary"
            startIcon={<ContentCopyIcon />}
            type="submit"
            form={COPY_ADMISSION_RATE_FORM_ID}
          >
            {t('Copy')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
