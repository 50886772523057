import {FormControlLabel, Radio, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {PRIMARY_50_COLOR, Theme} from '../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  formControlLabelRoot: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 0.5),
    borderRadius: 4,
    '&:last-child': {
      margin: 0
    }
  },
  formControlLabelRootSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: PRIMARY_50_COLOR
  },
  labelBox: {
    paddingLeft: theme.spacing(0.5)
  },
  radioGroup: {
    padding: theme.spacing(3)
  }
}))

interface IRadioGroupItemProps {
  label: string
  subLabel: string
  value?: unknown
  selectedValue?: unknown
}

export const RadioGroupItem: React.FC<IRadioGroupItemProps> = ({
  value,
  selectedValue,
  label,
  subLabel
}: IRadioGroupItemProps) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      classes={{
        root: cn(classes.formControlLabelRoot, {
          [classes.formControlLabelRootSelected]: selectedValue === value
        })
      }}
      control={<Radio color="primary" />}
      label={
        <div className={classes.labelBox}>
          <Typography variant="subtitle2">{label}</Typography>
          <Typography variant="caption" color="textSecondary">
            {subLabel}
          </Typography>
        </div>
      }
      value={value}
    />
  )
}
