import {SxProps} from '@mui/material'
import Decimal from 'decimal.js'
import React from 'react'
import {
  CartPropertiesFragment,
  CartState,
  LocaleCode,
  ProductItemPropertiesFragment,
  TicketItemPropertiesFragment,
  TourItemPropertiesFragment
} from '../../../__generated__/schema'
import {Theme} from '../../../theme'
import {CartSummaryItem} from '../../pages/admin/cashDesk/cart/types'
import {
  isProductItemPropertiesFragment,
  isTicketItemPropertiesFragment,
  isTourItemPropertiesFragment
} from '../../pages/admin/types'
import {ListOfItemsSeparatedByDividers} from '../ListOfItemsSeparatedByDividers'
import {CartSummaryProductItem} from './CartSummaryProductItem'
import {CartSummaryTicketItem} from './CartSummaryTicketItem'
import {CartSummaryTourItem} from './CartSummaryTourItem'

interface ICartSummaryProps {
  cart: CartPropertiesFragment
  sx?: SxProps<Theme>
  translatePrice: (price: number) => string
  getTicketItemClickHandler?: (eventId: number) => () => void
  getReservedTicketItemClickHandler?: (reservationId: number) => () => void
  getProductItemClickHandler?: (productId: number) => () => void
  getTourItemClickHandler?: (tourTimeSlotId: number) => () => void
  getReservedTourItemClickHandler?: (reservationId: number) => () => void
  clientLocaleCode: LocaleCode
}

export const CartSummary: React.FC<ICartSummaryProps> = ({
  sx,
  cart,
  translatePrice,
  getTicketItemClickHandler,
  getReservedTicketItemClickHandler,
  getProductItemClickHandler,
  clientLocaleCode,
  getTourItemClickHandler,
  getReservedTourItemClickHandler
}: ICartSummaryProps) => {
  const sortedItems = (cart.items || []).sort((itemA, itemB) => {
    if (
      isTicketItemPropertiesFragment(itemA) &&
      isTicketItemPropertiesFragment(itemB)
    ) {
      return itemA.eventSeat.event.startsAt.localeCompare(
        itemB.eventSeat.event.startsAt
      )
    }
    if (
      isProductItemPropertiesFragment(itemA) &&
      isProductItemPropertiesFragment(itemB)
    ) {
      return itemA.id - itemB.id
    }
    if (
      isTourItemPropertiesFragment(itemA) &&
      isTourItemPropertiesFragment(itemB)
    ) {
      return itemA.tourTimeSlot.startsAt.localeCompare(
        itemB.tourTimeSlot.startsAt
      )
    }
    return isProductItemPropertiesFragment(itemA) ? 1 : -1
  })

  return (
    <ListOfItemsSeparatedByDividers
      sx={sx}
      DividerProps={{
        sx: {
          mx: 1.5
        }
      }}
    >
      {sortedItems
        .reduce<CartSummaryItem[]>(
          (
            acc: CartSummaryItem[],
            item:
              | TicketItemPropertiesFragment
              | ProductItemPropertiesFragment
              | TourItemPropertiesFragment
          ) => {
            if (isProductItemPropertiesFragment(item)) {
              return [...acc, {item, count: item.quantity, total: item.price}]
            }
            if (isTicketItemPropertiesFragment(item)) {
              const aggregationItemIndex = acc.findIndex(
                (aggregatedItem) =>
                  isTicketItemPropertiesFragment(aggregatedItem.item) &&
                  aggregatedItem.item.reservation?.id ===
                    item.reservation?.id &&
                  aggregatedItem.item.eventSeat.event.id ===
                    item.eventSeat.event.id
              )
              return aggregationItemIndex !== -1
                ? acc.map((accItem, index) =>
                    aggregationItemIndex === index
                      ? {
                          ...accItem,
                          count: accItem.count + 1,
                          total: new Decimal(accItem.total)
                            .add(item.price)
                            .toNumber()
                        }
                      : accItem
                  )
                : [
                    ...acc,
                    {
                      item,
                      count: 1,
                      total: item.price
                    }
                  ]
            }
            if (isTourItemPropertiesFragment(item)) {
              const aggregationItemIndex = acc.findIndex(
                (aggregatedItem) =>
                  isTourItemPropertiesFragment(aggregatedItem.item) &&
                  aggregatedItem.item.tourTimeSlotId === item.tourTimeSlotId &&
                  aggregatedItem.item.reservation?.id === item.reservation?.id
              )
              return aggregationItemIndex !== -1
                ? acc.map((accItem, index) =>
                    aggregationItemIndex === index
                      ? {
                          ...accItem,
                          count: accItem.count + 1,
                          total: new Decimal(accItem.total)
                            .add(item.price)
                            .toNumber(),
                          attendeesCount: accItem.attendeesCount
                            ? accItem.attendeesCount + item.attendeesCount
                            : item.attendeesCount
                        }
                      : accItem
                  )
                : [
                    ...acc,
                    {
                      item,
                      count: 1,
                      total: item.price,
                      attendeesCount: item.attendeesCount
                    }
                  ]
            }
            return acc
          },
          []
        )
        .map((cartSummaryItem: CartSummaryItem) => {
          const item = cartSummaryItem.item
          if (isTicketItemPropertiesFragment(item)) {
            return (
              <CartSummaryTicketItem
                key={item.id}
                {...cartSummaryItem}
                item={item}
                translatePrice={translatePrice}
                onClick={
                  cart.state === CartState.Reserved || item.reservation
                    ? getReservedTicketItemClickHandler?.(item.reservation?.id!)
                    : getTicketItemClickHandler?.(item.eventSeat.event.id)
                }
                title={
                  item.eventSeat.event.names[clientLocaleCode] ||
                  Object.values(item.eventSeat.event.names)[0] ||
                  ''
                }
              />
            )
          }
          if (isProductItemPropertiesFragment(item)) {
            return (
              <CartSummaryProductItem
                onClick={getProductItemClickHandler?.(item.productId)}
                cartItem={item}
                key={item.id}
                translatePrice={translatePrice}
              />
            )
          }
          if (isTourItemPropertiesFragment(item)) {
            return (
              <CartSummaryTourItem
                key={item.id}
                name={item.tourTimeSlot.tour.name}
                price={translatePrice(cartSummaryItem.total)}
                attendeesCount={
                  cartSummaryItem?.attendeesCount || item.attendeesCount
                }
                reservationId={item.reservation?.id}
                totalCount={cartSummaryItem.count}
                startsAt={new Date(item.tourTimeSlot.startsAt)}
                versionCode={item.tourTimeSlot.versionCode}
                ageClassificationCode={item.tourTimeSlot.ageClassificationCode}
                onClick={
                  cart.state === CartState.Reserved || item.reservation
                    ? getReservedTourItemClickHandler?.(item.reservation?.id!)
                    : getTourItemClickHandler?.(item.tourTimeSlot.id)
                }
              />
            )
          }
          return null
        })}
    </ListOfItemsSeparatedByDividers>
  )
}
