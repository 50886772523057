import {useLazyQuery} from '@apollo/react-hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined'
import {
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionRateState,
  LightweightAdmissionRatesQuery,
  LightweightAdmissionRatesQueryVariables
} from '../../../../../__generated__/schema'
import {Tooltip} from '../../../../common'
import {Menu, useMenu} from '../../../../common/Menu'
import {LIGHTWEIGHT_ADMISSION_RATES} from '../../graphql'

const LoadingSkeleton = React.forwardRef<HTMLDivElement>(
  function LoadingSkeletonWithRef(props, ref) {
    return (
      <div ref={ref}>
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
      </div>
    )
  }
)

interface IAdmissionRatesChipProps {
  onChange: (admissionRateId: number | null) => void
  tourId: number
}

export const AdmissionRatesChip: React.FC<IAdmissionRatesChipProps> = ({
  onChange,
  tourId
}: IAdmissionRatesChipProps) => {
  const {t} = useTranslation()
  const [admissionRates, setAdmissionRates] = useState<
    LightweightAdmissionRatesQuery['admissionRates']['items']
  >([])
  const [selectedAdmissionRate, setSelectedAdmissionRate] =
    useState<
      LightweightAdmissionRatesQuery['admissionRates']['items'][number] | null
    >(null)
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const [getAdmissionRates, {loading}] = useLazyQuery<
    LightweightAdmissionRatesQuery,
    LightweightAdmissionRatesQueryVariables
  >(LIGHTWEIGHT_ADMISSION_RATES, {
    variables: {
      paginationInput: {offset: 0, limit: 300},
      filter: {tourId, states: [AdmissionRateState.Active]}
    },
    onCompleted: (data) =>
      setAdmissionRates(
        data.admissionRates.items.sort((a, b) => a.name.localeCompare(b.name))
      ),
    fetchPolicy: 'network-only'
  })
  const handleChipClick = useCallback(
    (e) => {
      getAdmissionRates()
      openMenu(e)
    },
    [getAdmissionRates, openMenu]
  )
  const handleMenuItemClick = useCallback(
    (
        admissionRate: LightweightAdmissionRatesQuery['admissionRates']['items'][number]
      ) =>
      () => {
        setSelectedAdmissionRate(admissionRate)
        onChange(admissionRate.id)
        closeMenu()
      },
    [closeMenu, onChange]
  )
  const handleDeleteChipClick = useCallback(() => {
    setSelectedAdmissionRate(null)
    onChange(null)
  }, [onChange])
  return (
    <>
      <Tooltip title={t('Select admission rate')}>
        <Chip
          size="small"
          variant="outlined"
          icon={<DiscountOutlinedIcon />}
          deleteIcon={
            selectedAdmissionRate ? <CancelIcon /> : <ArrowDropDownIcon />
          }
          label={selectedAdmissionRate?.name}
          onClick={handleChipClick}
          onDelete={
            selectedAdmissionRate ? handleDeleteChipClick : handleChipClick
          }
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{maxHeight: 398}}
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          admissionRates?.map((admissionRate) => (
            <MenuItem
              key={admissionRate.id}
              selected={admissionRate.id === selectedAdmissionRate?.id}
              onClick={handleMenuItemClick(admissionRate)}
              sx={{px: 3, py: 1.5, flexFlow: 'row-reverse'}}
            >
              {admissionRate.id === selectedAdmissionRate?.id && (
                <ListItemIcon
                  color="primary"
                  sx={{minWidth: 48, justifyContent: 'center'}}
                >
                  <CheckIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={admissionRate.name} />
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  )
}
