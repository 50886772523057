import {noop} from 'lodash'
import React, {createContext, useContext} from 'react'
import {useLocalStorageState} from '../../../../hooks/storage'
import {CheckMode} from './types'

interface ICheckModeContext {
  checkMode: CheckMode
  setCheckMode: (mode: CheckMode) => void
}

const CheckModeContext = createContext<ICheckModeContext>({
  checkMode: CheckMode.CheckIn,
  setCheckMode: noop
})

interface ICheckModeContextProviderProps {
  children: React.ReactNode
}

export const CheckModeContextProvider: React.FC<ICheckModeContextProviderProps> =
  ({children}: ICheckModeContextProviderProps) => {
    const [checkMode, setCheckMode] = useLocalStorageState<CheckMode>(
      'checkMode',
      CheckMode.CheckIn
    )
    return (
      <CheckModeContext.Provider
        value={{
          checkMode,
          setCheckMode
        }}
      >
        {children}
      </CheckModeContext.Provider>
    )
  }

export const useCheckMode = () => useContext(CheckModeContext)
