import {Dayjs} from 'dayjs'
import {
  FieldErrors,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {
  ConditionOperator,
  ConditionResource,
  CreateDiscountCodeMutationVariables,
  CreateDiscountCodesMutationVariables,
  DiscountCodeInput
} from '../../../../../__generated__/schema'

export enum ConditionFormField {
  Value = 'value',
  Operator = 'operator',
  Resource = 'resource'
}

export interface IConditionForm {
  [ConditionFormField.Operator]: ConditionOperator
  [ConditionFormField.Value]: any
  [ConditionFormField.Resource]: ConditionResource
}

export interface IConditionFormInputProps {
  errors: FieldErrors<IConditionForm>
  setValue: FormContextValues<IConditionForm>['setValue']
  triggerValidation: FormContextValues<IConditionForm>['triggerValidation']
  watch: FormContextValues<IConditionForm>['watch']
  register: FormContextValues<IConditionForm>['register']
  unregister: FormContextValues<IConditionForm>['unregister']
  clearError: FormContextValues<IConditionForm>['clearError']
  setError: FormContextValues<IConditionForm>['setError']
  control: FormContextValues<IConditionForm>['control']
  defaultValue: IConditionForm[ConditionFormField.Value]
}

export enum DiscountCodeNamingApproach {
  GENERATE_RANDOM = 'generateRandom',
  DEFINED_BY_USER = 'definedByUser'
}

export enum DiscountCodeFormField {
  NAME = 'name',
  COUNT = 'count',
  DESCRIPTION = 'description',
  USAGE_LIMIT = 'usageLimit',
  ACTIVATION_DATE = 'activationDate',
  ACTIVATION_TIME = 'activationTime',
  EXPIRATION_DATE = 'expirationDate',
  EXPIRATION_TIME = 'expirationTime',
  NAMING_APPROACH = 'namingApproach',
  USAGE_PER_LIMIT_ORDER = 'usagePerLimitOrder'
}

export interface IDiscountCodeForm {
  [DiscountCodeFormField.NAME]?: string
  [DiscountCodeFormField.COUNT]?: string
  [DiscountCodeFormField.DESCRIPTION]?: string
  [DiscountCodeFormField.USAGE_LIMIT]?: string
  [DiscountCodeFormField.ACTIVATION_DATE]?: Dayjs
  [DiscountCodeFormField.ACTIVATION_TIME]?: string
  [DiscountCodeFormField.EXPIRATION_DATE]?: Dayjs
  [DiscountCodeFormField.EXPIRATION_TIME]?: string
  [DiscountCodeFormField.NAMING_APPROACH]: DiscountCodeNamingApproach
  [DiscountCodeFormField.USAGE_PER_LIMIT_ORDER]?: string
}

export interface ICreateDiscountCodeSubmitOptions {
  hasUsageLimit?: boolean
  hasActivationDate?: boolean
  hasExpirationDate?: boolean
  hasUsageLimitPerOrder?: boolean
}

const getDiscountCodeInput = (
  form: IDiscountCodeForm,
  options: ICreateDiscountCodeSubmitOptions,
  discountId: number
): DiscountCodeInput => ({
  discountId,
  description: form[DiscountCodeFormField.DESCRIPTION],
  usageLimit:
    options.hasUsageLimit && form[DiscountCodeFormField.USAGE_LIMIT]
      ? parseInt(String(form[DiscountCodeFormField.USAGE_LIMIT]), 10)
      : null,
  activationDate:
    options.hasActivationDate &&
    form[DiscountCodeFormField.ACTIVATION_DATE] &&
    form[DiscountCodeFormField.ACTIVATION_TIME]
      ? form[DiscountCodeFormField.ACTIVATION_DATE]
          ?.hour(parseInt(form[DiscountCodeFormField.ACTIVATION_TIME]!, 10))
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString()
      : null,
  expirationDate:
    options.hasExpirationDate &&
    form[DiscountCodeFormField.EXPIRATION_DATE] &&
    form[DiscountCodeFormField.EXPIRATION_TIME]
      ? form[DiscountCodeFormField.EXPIRATION_DATE]
          ?.hour(parseInt(form[DiscountCodeFormField.EXPIRATION_TIME]!, 10))
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString()
      : null,
  usageLimitPerOrder:
    options.hasUsageLimitPerOrder &&
    form[DiscountCodeFormField.USAGE_PER_LIMIT_ORDER]
      ? parseInt(form[DiscountCodeFormField.USAGE_PER_LIMIT_ORDER]!, 10)
      : undefined
})

export const getCreateDiscountCodeVariables = (
  form: IDiscountCodeForm,
  options: ICreateDiscountCodeSubmitOptions,
  discountId: number
): CreateDiscountCodeMutationVariables => ({
  input: getDiscountCodeInput(form, options, discountId),
  name:
    form[DiscountCodeFormField.NAMING_APPROACH] ===
    DiscountCodeNamingApproach.DEFINED_BY_USER
      ? form[DiscountCodeFormField.NAME]
      : undefined
})

export const getCreateDiscountCodesVariables = (
  form: IDiscountCodeForm,
  options: ICreateDiscountCodeSubmitOptions,
  discountId: number
): CreateDiscountCodesMutationVariables => ({
  input: getDiscountCodeInput(form, options, discountId),
  count:
    typeof form[DiscountCodeFormField.COUNT] === 'string'
      ? parseInt(<string>form[DiscountCodeFormField.COUNT], 10)
      : 0
})

export interface IControlledFormCheckboxGroupCommonProps
  extends Omit<IConditionFormInputProps, 'triggerValidation'> {
  required: boolean
  name: ConditionFormField
  validationOptions: ValidationOptions
  parseCheckboxValue: (value: string) => string
  helperNote: string
}
