export enum MetadataFormField {
  Index = 'index',
  MetadataBase = 'metadataBase',
  Title = 'title',
  Description = 'description',
  OpenGraphTitle = 'openGraphTitle',
  OpenGraphDescrpition = 'openGraphDescription',
  OpenGraphImageUrl = 'openGraphImageUrl'
}

export interface IMetadataForm {
  [MetadataFormField.Index]: boolean
  [MetadataFormField.MetadataBase]: string
  [MetadataFormField.Title]?: string
  [MetadataFormField.Description]?: string
  [MetadataFormField.OpenGraphTitle]?: string
  [MetadataFormField.OpenGraphDescrpition]?: string
  [MetadataFormField.OpenGraphImageUrl]?: string
}
