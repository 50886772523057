import {
  AuditoriumPreviewFieldsFragment,
  CartPropertiesFragment,
  CashDeskEventFragment,
  CashDeskTourTimeSlotQuery,
  Scalars
} from '../__generated__/schema'

export enum CustomerDisplayMessageType {
  CurrentCartChange = 'currentCartChange',
  NextCustomer = 'nextCustomer',
  DisplayEventAuditoriumView = 'displayEventAuditoriumView',
  DisplayTourTimeSlotView = 'displayTourTimeSlotView',
  DisplayCashdeskEventsView = 'displayCashdeskEventsView',
  DisplayBaseView = 'displayBaseView',
  PingAllCustomerDisplayPages = 'pingAllCustomerDisplayPages',
  UserLoggedIn = 'userLoggedIn',
  UserLoggedOut = 'userLoggedOut',
  ReplyToPing = 'replyToPing',
  CustomerDisplayPageClosed = 'customerDisplayPageClosed',
  CustomerDisplayPageOpened = 'customerDisplayPageOpened'
}

export type CurrentCartChangeMessage = {
  type: CustomerDisplayMessageType.CurrentCartChange
  payload: CartPropertiesFragment | null
}

export type NextCustomerMessage = {
  type: CustomerDisplayMessageType.NextCustomer
}

export type DisplayEventAuditoriumViewMessage = {
  type: CustomerDisplayMessageType.DisplayEventAuditoriumView
  payload: {
    event: AuditoriumPreviewFieldsFragment
    eventSeats: Scalars['JSON']
  }
}
export type DisplayTourTimeSlotViewMessage = {
  type: CustomerDisplayMessageType.DisplayTourTimeSlotView
  payload: CashDeskTourTimeSlotQuery['tourTimeSlot']
}
export type DisplayEventsViewMessage = {
  type: CustomerDisplayMessageType.DisplayCashdeskEventsView
  payload: CashDeskEventFragment[]
}
export type DisplayBaseViewMessage = {
  type: CustomerDisplayMessageType.DisplayBaseView
}
export type PingAllCustomerDisplayPagesMessage = {
  type: CustomerDisplayMessageType.PingAllCustomerDisplayPages
}
export type ReplyToPingMessage = {
  type: CustomerDisplayMessageType.ReplyToPing
  payload: string
}
export type UserLoggedInMessage = {
  type: CustomerDisplayMessageType.UserLoggedIn
}
export type UserLoggedOutMessage = {
  type: CustomerDisplayMessageType.UserLoggedOut
}
export type CustomerDisplayPageClosedMessage = {
  type: CustomerDisplayMessageType.CustomerDisplayPageClosed
}
export type CustomerDisplayPageOpenedMessage = {
  type: CustomerDisplayMessageType.CustomerDisplayPageOpened
}

export type CustomerDisplayMessage =
  | CurrentCartChangeMessage
  | NextCustomerMessage
  | DisplayEventAuditoriumViewMessage
  | DisplayTourTimeSlotViewMessage
  | DisplayEventsViewMessage
  | DisplayBaseViewMessage
  | PingAllCustomerDisplayPagesMessage
  | ReplyToPingMessage
  | UserLoggedInMessage
  | UserLoggedOutMessage
  | CustomerDisplayPageClosedMessage
  | CustomerDisplayPageOpenedMessage
