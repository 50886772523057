import React from 'react'
import {useTranslation} from 'react-i18next'
import {EventDiscountsQuery} from '../../../../../../__generated__/schema'
import {RenderOnData} from '../../../../../common'
import {useGetEventDiscounts} from '../../graphql'

import {EventDiscountsContent} from './EventDiscountsContent'

interface IEventDiscountsProps {
  eventId: number
}

export const EventDiscounts: React.FC<IEventDiscountsProps> = ({
  eventId
}: IEventDiscountsProps) => {
  const {t} = useTranslation()
  const {error, loading, data} = useGetEventDiscounts(eventId)
  return (
    <RenderOnData
      {...{error, loading, data}}
      errorMessage={t<string>('Could not load event discounts')}
    >
      {({event}: EventDiscountsQuery) => (
        <EventDiscountsContent
          allowedDiscountsSellingChannels={
            event.allowedDiscountsSellingChannels
          }
          eventId={eventId}
        />
      )}
    </RenderOnData>
  )
}
