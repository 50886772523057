import {useQuery} from '@apollo/react-hooks'
import {Drawer, drawerClasses} from '@mui/material'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BaseWarehouseDocumentFieldsFragment,
  BusinessPartnerState,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {LIGHTWEIGHT_BUSINESS_PARTNERS} from '../../graphql'
import {useUpdateDraftWarehouseDocument} from '../graphql'
import {
  IWarehouseDocumentForm,
  WarehouseDocumentFormField,
  WarehouseDocumentFormType
} from '../types'
import {transformWarehouseFormDataToUpdateDraftWarehouseDocumentInput} from '../utils'
import {WarehouseDocumentForm} from '../WarehouseDocumentForm'

const UPDATE_WAREHOUSE_DOCUMENT_FORM_ID = 'updateWarehouseDocumentFormId'

interface IUpdateWarehouseDocumentDrawerProps {
  onExited: () => void
  warehouseDocument: BaseWarehouseDocumentFieldsFragment
}

export const UpdateWarehouseDocumentDrawer: React.FC<IUpdateWarehouseDocumentDrawerProps> =
  ({onExited, warehouseDocument}: IUpdateWarehouseDocumentDrawerProps) => {
    const {t} = useTranslation()
    const updateDraftWarehouseDocument = useUpdateDraftWarehouseDocument()
    const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    const {
      data: businessPartnersData,
      loading: businessPartnersLoading,
      error: businessPartnersError
    } = useQuery<
      LightweightBusinessPartnersQuery,
      LightweightBusinessPartnersQueryVariables
    >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
      variables: {
        filter: {states: [BusinessPartnerState.Active]},
        paginationInput: {offset: 0, limit: 300}
      },
      fetchPolicy: 'network-only',
      skip: !isOpen
    })
    const handleSubmit = useCallback(
      async (formData: IWarehouseDocumentForm) => {
        try {
          setShowBackdrop(true)
          await updateDraftWarehouseDocument({
            id: warehouseDocument.id,
            input:
              transformWarehouseFormDataToUpdateDraftWarehouseDocumentInput(
                formData
              )
          })
          addInfoNotification(t('Warehouse document has been updated'))
          closeDrawer()
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while updating draft warehouse document')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        closeDrawer,
        defaultErrorHandler,
        setShowBackdrop,
        t,
        updateDraftWarehouseDocument,
        warehouseDocument.id
      ]
    )
    useEffect(() => {
      openDrawer()
    }, [openDrawer])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update warehouse document')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_WAREHOUSE_DOCUMENT_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
          isLoading={businessPartnersLoading}
          errorMessage={
            businessPartnersError && t('Error while loading business partners')
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {businessPartnersData && (
            <WarehouseDocumentForm
              formId={UPDATE_WAREHOUSE_DOCUMENT_FORM_ID}
              onSubmit={handleSubmit}
              warehouses={[warehouseDocument.warehouse]}
              businessPartners={businessPartnersData.businessPartners.items}
              formType={WarehouseDocumentFormType.Update}
              defaultValues={{
                [WarehouseDocumentFormField.WarehouseId]:
                  warehouseDocument.warehouse.id,
                [WarehouseDocumentFormField.DeliveryDate]:
                  warehouseDocument.deliveryDate,
                [WarehouseDocumentFormField.BusinessPartnerId]:
                  warehouseDocument.businessPartner?.id,
                [WarehouseDocumentFormField.ThirdPartyDocumentId]:
                  warehouseDocument.thirdPartyDocumentId,
                [WarehouseDocumentFormField.Note]: warehouseDocument.note
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
