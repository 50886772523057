import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, Button, IconButton} from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  SellingChannel,
  VoucherCampaignsQuery,
  VoucherCampaignState
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useBooleanState} from '../../../../hooks/state'
import {useTranslateVoucherCampaignState} from '../../../../hooks/translateVoucherCampaignState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {
  DataGridTable,
  useBooleanFormatter,
  useDataGridPagination,
  useEffectiveClientPriceFormatter
} from '../../../common/DataGridTable'
import {voucherCampaignStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {CreateVoucherCampaignDrawer} from './CreateVoucherCampaignDrawer'
import {useGetVoucherCampaigns} from './graphql'

const useDurationFormatter = () => {
  const {t} = useTranslation()
  return (params: GridValueFormatterParams) =>
    params.value
      ? t('{{count}} day', {count: dayjs.duration(params.value).asDays()})
      : ''
}

const StateRenderer = ({state}: {state: VoucherCampaignState}) => {
  const translateVoucherCampaignState = useTranslateVoucherCampaignState()
  return (
    <EntityStateChip
      colorConf={voucherCampaignStateColors[state]}
      label={translateVoucherCampaignState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.voucherCampaigns.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

export const VoucherCampaignsList: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    state: isCreateVoucherCampaignDrawerOpen,
    setTrue: openCreateVoucherCampaignDrawer,
    setFalse: closeCreateVoucherCampaignDrawer
  } = useBooleanState(false)
  const {paginationInput, getDataGridPaginationProps} = useDataGridPagination()
  const {data, loading, error, refetch} = useGetVoucherCampaigns({
    paginationInput
  })
  const booleanFormatter = useBooleanFormatter()
  const durationFormatter = useDurationFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter(false)
  const translateSellingChannel = useTranslateSellingChannel()
  const fabClasses = useFabClasses()
  const hasPermissionToCreateVoucherCampaign = P([
    PermissionCode.CreateVoucherCampaign,
    PermissionCode.ReadRetailPaymentMethods,
    PermissionCode.ReadECommercePaymentMethods
  ])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 250,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: VoucherCampaignState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 50,
        sortable: false
      },
      {
        headerName: t('Create new vouchers'),
        field: 'canCreateNewVouchers',
        valueFormatter: booleanFormatter,
        minWidth: 170,
        sortable: false
      },
      {
        headerName: translateSellingChannel(SellingChannel.Retail),
        field: 'retailPaymentMethod.name',
        valueGetter: (params) => params.row.retailPaymentMethod?.name,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: translateSellingChannel(SellingChannel.ECommerce),
        field: 'ecommercePaymentMethod.name',
        valueGetter: (params) => params.row.ecommercePaymentMethod?.name,
        minWidth: 200,
        sortable: false
      },
      {
        headerName: t('Initial balance'),
        field: 'initialBalance',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Initial validity'),
        field: 'initialValidity',
        valueFormatter: durationFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Redemption limit'),
        field: 'redemptionLimit',
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Recharge limit'),
        field: 'rechargeLimit',
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        sortable: false
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [
      booleanFormatter,
      effectiveClientPriceFormatter,
      durationFormatter,
      t,
      translateSellingChannel
    ]
  )
  return (
    <RenderOnData<VoucherCampaignsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading voucher campaigns')}
      dataCondition={(data) => Array.isArray(data.voucherCampaigns.items)}
      ignoreLoadingIfData
    >
      {({voucherCampaigns}) => (
        <>
          <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
            {voucherCampaigns.items.length > 0 ? (
              <DataGridTable
                columns={columns}
                loading={loading}
                rows={voucherCampaigns.items}
                disableColumnMenu
                disableRowSelectionOnClick
                initialState={{pinnedColumns: {left: ['name'], right: ['id']}}}
                columnVisibilityModel={{
                  id: P([PermissionCode.ReadVoucherCampaign])
                }}
                {...getDataGridPaginationProps(voucherCampaigns.pagination)}
              />
            ) : (
              <Blank
                title={t('No voucher campaigns')}
                description={t(
                  'Voucher campaigns defines basic behaviour of all vouchers that belongs to campaign. You can set campaigns for loyal customers, partners, VIP’s, gift cards, gift vouchers, etc.'
                )}
                actions={
                  hasPermissionToCreateVoucherCampaign && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={openCreateVoucherCampaignDrawer}
                    >
                      {t('Create')}
                    </Button>
                  )
                }
              />
            )}
          </Box>
          {hasPermissionToCreateVoucherCampaign &&
            voucherCampaigns.items.length > 0 && (
              <CreateFab
                classes={fabClasses}
                onClick={openCreateVoucherCampaignDrawer}
              />
            )}
          <CreateVoucherCampaignDrawer
            isOpen={isCreateVoucherCampaignDrawerOpen}
            onClose={closeCreateVoucherCampaignDrawer}
            refetch={refetch}
          />
        </>
      )}
    </RenderOnData>
  )
}
