import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateEventsStatisticsCsvLinkMutation,
  CreateEventsStatisticsCsvLinkMutationVariables,
  EventsStatisticsQuery,
  EventsStatisticsQueryVariables
} from '../../../../__generated__/schema'
import {PAGINATION_FRAGMENT} from '../../graphql'
import {TRANSLATED_LOCALES_FRAGMENT} from '../graphql'

const EVENT_STATISTICS_PROPERTIES_FRAGMENT = gql`
  fragment EventStatisticsProperties on EventStatistics {
    names {
      ...TranslatedLocales
    }
    startsAt
    venue {
      id
      name
    }
    auditorium {
      id
      name
    }
    division {
      id
      name
    }
    formatCode
    versionCode
    soundMixCode
    ageClassificationCode
    profit
    profitTicketCount
    saleSumOnRetailChannel
    saleSumOnEcommerceChannel
    saleTicketCountOnRetailChannel
    saleTicketCountOnEcommerceChannel
    refundSumOnRetailChannel
    refundSumOnEcommerceChannel
    refundTicketCountOnRetailChannel
    refundTicketCountOnEcommerceChannel
    eventId
    costCenter {
      id
      name
    }
    marketingLabel {
      id
      name
    }
    eventCategory {
      id
      name
    }
    auditoriumLayout {
      id
      capacity
    }
    availableSeatsCount
    soldSeatsCount
    reservedSeatsCount
    inCartSeatsCount
    disabledSeatsCount
    hiddenSeatsCount
    totalDiscountedSoldTicketCount
    totalFreeSoldTicketCount
    uniqueCheckedInCount
    passCodesCheckedInCount
    passCodesCheckedOutCount
  }
  ${TRANSLATED_LOCALES_FRAGMENT}
`
const GET_EVENTS_STATISTICS = gql`
  query eventsStatistics(
    $paginationInput: PaginationInput!
    $filter: EventsStatisticsFilterInput
  ) {
    eventsStatistics(paginationInput: $paginationInput, filter: $filter) {
      items {
        ...EventStatisticsProperties
      }
      pagination {
        ...PaginationProperties
      }
    }
  }
  ${EVENT_STATISTICS_PROPERTIES_FRAGMENT}
  ${PAGINATION_FRAGMENT}
`

export const useGetEventsStatistics = (
  variables: EventsStatisticsQueryVariables
) =>
  useQuery<EventsStatisticsQuery, EventsStatisticsQueryVariables>(
    GET_EVENTS_STATISTICS,
    {
      fetchPolicy: 'network-only',
      variables
    }
  )

const CREATE_EVENTS_STATISTICS_CSV_LINK = gql`
  mutation CreateEventsStatisticsCsvLink(
    $filter: EventsStatisticsFilterInput
    $localeCode: LocaleCode
  ) {
    createEventsStatisticsCsvLink(filter: $filter, localeCode: $localeCode)
  }
`

export const useCreateEventsStatisticsCsvLink = () => {
  const [createEventsStatisticsCsvLink] = useMutation<
    CreateEventsStatisticsCsvLinkMutation,
    CreateEventsStatisticsCsvLinkMutationVariables
  >(CREATE_EVENTS_STATISTICS_CSV_LINK)
  return useCallback(
    (variables?: CreateEventsStatisticsCsvLinkMutationVariables) =>
      createEventsStatisticsCsvLink({variables}),
    [createEventsStatisticsCsvLink]
  )
}
