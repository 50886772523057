import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Blank, BlankContentPosition} from '../visual/Blank'

interface ISetCameraPermissionBlankProps {
  refetchCameras: () => void
}

export const MissingCameraPermissionBlank: React.FC<ISetCameraPermissionBlankProps> =
  ({refetchCameras}: ISetCameraPermissionBlankProps) => {
    const {t} = useTranslation()
    return (
      <Blank
        title={t('Set camera permission')}
        description={t(
          'Application requires access to using your camera for scanning ticket codes. If you can’t allow this permission now, open your settings on device operating system. For more information, check the instructions for your operating system in our help centre.'
        )}
        contentPosition={BlankContentPosition.Center}
        actions={
          <Button variant="contained" color="primary" onClick={refetchCameras}>
            {t('Retry')}
          </Button>
        }
      />
    )
  }
