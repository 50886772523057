import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {getParsedItemFromStorage, LocalStorageKey} from '../hooks/storage'
import {ICustomerDisplaySettings} from '../types'
import {routeTo} from '../utils/routes'
import {useOpenWindow} from '../windowManagement'
import {ScreenDetailed} from '../windowManagement/types'
import {CustomerDisplayMessage, CustomerDisplayMessageType} from './types'

const CUSTOMER_DISPLAY_BROADCAST_CHANNEL_ID =
  'CUSTOMER_DISPLAY_BROADCAST_CHANNEL_ID'

export const isCustomerDisplayEnabled = (): boolean =>
  Boolean(
    getParsedItemFromStorage<ICustomerDisplaySettings>(
      localStorage,
      LocalStorageKey.CustomerDisplaySettings
    )?.enabled
  )

export const CustomDisplayBroadcastChannelContext =
  createContext<null | BroadcastChannel>(null)

export const useCustomDisplayBroadcastChannel = () =>
  useContext(CustomDisplayBroadcastChannelContext)

export const customerDisplayBroadcastChannel =
  typeof BroadcastChannel === 'function'
    ? new BroadcastChannel(CUSTOMER_DISPLAY_BROADCAST_CHANNEL_ID)
    : null

export const useSubscribeToCustomerDisplayChannel = (
  handler: (
    this: BroadcastChannel,
    ev: MessageEvent<CustomerDisplayMessage>
  ) => any
) => {
  const broadcastChannel = useCustomDisplayBroadcastChannel()
  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', handler)
    } else {
      // eslint-disable-next-line no-console
      console.error(
        "Subscribe to CustomerDisplayBroadcastChannel failed. Channel doesn't exist."
      )
    }
    return () => {
      if (broadcastChannel) {
        broadcastChannel.removeEventListener('message', handler)
      }
    }
  }, [broadcastChannel, handler])
}

export const usePostCustomerDisplayMessage = () => {
  const broadcastChannel = useCustomDisplayBroadcastChannel()
  return useCallback(
    (message: CustomerDisplayMessage) => {
      if (broadcastChannel && isCustomerDisplayEnabled()) {
        broadcastChannel.postMessage(message)
      } else {
        // eslint-disable-next-line no-console
        console.error(
          "Post to CustomerDisplayBroadcastChannel failed. Channel doesn't exist."
        )
      }
    },
    [broadcastChannel]
  )
}

export const usePingCustomerDisplayPages = () => {
  const [detectedPages, setDetectedPages] = useState<string[]>([])
  const tempDetectedTempPagesRef = useRef<string[]>([])
  const postCustomerDisplayMessage = usePostCustomerDisplayMessage()
  const broadcastChannel = useCustomDisplayBroadcastChannel()

  const handlePongResponse = useCallback(function (
    this: BroadcastChannel,
    {data: message}: MessageEvent<CustomerDisplayMessage>
  ) {
    if (message.type === CustomerDisplayMessageType.ReplyToPing) {
      tempDetectedTempPagesRef.current = [
        ...tempDetectedTempPagesRef.current,
        message.payload
      ]
    }
  },
  [])
  const pingRef = useRef({timeout: 0})

  useEffect(() => {
    return () => {
      if (pingRef.current.timeout) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(pingRef.current.timeout)
      }
    }
  }, [])

  const removeListener = useCallback(() => {
    setDetectedPages(tempDetectedTempPagesRef.current)
    if (broadcastChannel) {
      broadcastChannel.removeEventListener('message', handlePongResponse)
    }
  }, [broadcastChannel, handlePongResponse])

  const pingPages = useCallback(() => {
    clearTimeout(pingRef.current.timeout)
    tempDetectedTempPagesRef.current = []
    if (broadcastChannel) {
      pingRef.current.timeout = window.setTimeout(removeListener, 300)
      broadcastChannel.addEventListener('message', handlePongResponse)
      postCustomerDisplayMessage({
        type: CustomerDisplayMessageType.PingAllCustomerDisplayPages
      })
    }
  }, [
    broadcastChannel,
    handlePongResponse,
    postCustomerDisplayMessage,
    removeListener
  ])
  return {
    pingPages,
    detectedPages
  }
}

export const useOpenCustomerDisplay = () => {
  const {openWindow} = useOpenWindow()
  return useCallback(
    (externalScreen?: ScreenDetailed) => {
      const features = externalScreen
        ? `left=${externalScreen.availLeft},top=${externalScreen.availTop},width=${externalScreen.availWidth},height=${externalScreen.availHeight}`
        : `fullscreen,popup`
      openWindow({
        url: routeTo.admin.customerDisplay.home(),
        target: 'customerDisplayWindow',
        features
      })
    },
    [openWindow]
  )
}
