import {Box, Checkbox, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../theme'

export enum MassCheckboxState {
  Unselected = 'unselected',
  Indeterminate = 'indeterminate',
  Selected = 'selected'
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))

interface IMassCheckboxProps {
  state: MassCheckboxState
  /**
   * @deprecated use sx prop
   */
  className?: string
  onClick?: () => void
  disabled?: boolean
  sx?: SxProps<Theme>
}

export const MassCheckbox: React.FC<IMassCheckboxProps> = ({
  state,
  className,
  onClick,
  disabled,
  sx
}: IMassCheckboxProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Box className={cn(classes.root, className)} sx={sx}>
      <Checkbox
        checked={state === MassCheckboxState.Selected}
        onClick={onClick}
        color="primary"
        edge="start"
        indeterminate={state === MassCheckboxState.Indeterminate}
        disabled={disabled}
      />
      <Typography variant="body2">
        {state === MassCheckboxState.Unselected
          ? t('Select all')
          : t('Deselect all', {context: 'massCheckbox'})}
      </Typography>
    </Box>
  )
}
