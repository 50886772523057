import {chain} from 'lodash'
import {TemplatesQuery, TemplateType} from '../../../../__generated__/schema'
import {useRequireEffectiveClient} from '../../../../hooks/requireEffectiveClient'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {useGetTemplates} from '../templates/graphql'

export const useGetClaimPrintingTemplates = (): TemplatesQuery['templates'] => {
  const effectiveClient = useRequireEffectiveClient()
  const [deviceTemplateIds] = useLocalStorageState<{
    [templateType: string]: number[]
  }>(LocalStorageKey.TemplateIdsAssignedToDeviceByType, {})
  const {data, loading} = useGetTemplates(
    {
      clientId: effectiveClient.id,
      type: TemplateType.ClaimPrinting
    },
    !effectiveClient.id
  )
  if (!data || loading) {
    return []
  }
  const deviceTemplates = chain(data.templates)
    .keyBy('id')
    .at(deviceTemplateIds.claimPrinting)
    .compact()
    .value()
  return deviceTemplates.length ? deviceTemplates : data?.templates
}
