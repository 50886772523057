///<reference types="webpack-env" />

import {ApolloProvider} from '@apollo/react-hooks'
import {LicenseInfo} from '@mui/x-data-grid-pro'
import React from 'react'
import {render} from 'react-dom'

import {App} from './App'
import {apolloClient} from './utils/apollo'
import {initSentry} from './utils/sentry'
import {initI18next} from './utils/i18next'

initSentry()
initI18next()
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_LICENSE_KEY || '')

const rootEl = document.getElementById('root')

render(
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>,
  rootEl
)
