import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  DetailClientPropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {useClientFormAnchors} from '../../../../hooks/clientFormAnchors'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {
  BottomBackButton,
  EntityStateChip,
  SingleSideNavigationList
} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {ClientTemplates} from '../../../common/ClientTemplates'
import {LeadSettings} from '../../../common/leadSettings'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../common/PageWithHeaderAndFooterTemplate'
import {StatusBarLayout} from '../../../common/StatusBarLayout'
import {clientStateColors, useClientStateTranslations} from '../../../constants'
import {PrimaryHeader} from '../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../Layout'
import {CompanyForm} from './CompanyForm'
import {useUpdateCompany} from './graphql'
import {CompanyFormField, ICompanyForm} from './types'

const COMPANY_FORM_ID = 'COMPANY_FORM_ID'

interface IEditCompanyProps {
  company: DetailClientPropertiesFragment
}

export const EditCompany: React.FC<IEditCompanyProps> = ({
  company
}: IEditCompanyProps) => {
  const {t} = useTranslation()
  const clientFormAnchors = useClientFormAnchors(true)
  const clientStateTranslations = useClientStateTranslations()
  const updateCompany = useUpdateCompany()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const history = useHistory()
  const rowClickHandler = useCallback(
    (templateType: TemplateType) => () =>
      history.push(routeTo.admin.companyAssignedTemplates(templateType)),
    [history]
  )

  const handleSubmit = useCallback(
    async (companyForm: ICompanyForm) => {
      setShowBackdrop(true)
      try {
        await updateCompany({
          input: {
            ...companyForm,
            eCommerceUrl: companyForm[CompanyFormField.E_COMMERCE_URL] || null
          }
        })
      } catch (e) {
        defaultErrorHandler(e, t('Error while updating company'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [defaultErrorHandler, setShowBackdrop, t, updateCompany]
  )
  return (
    <PageWithHeaderAndFooterTemplate
      header={<PrimaryHeader title={t('Company')} />}
      footer={
        <Footer>
          <BottomBackButton />
          <SaveButton type="submit" form={COMPANY_FORM_ID} />
        </Footer>
      }
    >
      <CenteredLayout>
        <SingleSideNavigationList items={clientFormAnchors} />
        <CenteredLayoutListWrapper>
          <StatusBarLayout
            title={company.name}
            iconType="client"
            rightSideContent={null}
            bottomContent={
              <EntityStateChip
                label={clientStateTranslations[company.state]}
                colorConf={clientStateColors[company.state]}
              />
            }
          />
          <CompanyForm
            formId={COMPANY_FORM_ID}
            company={company}
            onSubmit={handleSubmit}
          />
          <LeadSettings client={company} />
          <ClientTemplates
            templates={company.templateAssignments}
            onRowClick={rowClickHandler}
          />
        </CenteredLayoutListWrapper>
      </CenteredLayout>
    </PageWithHeaderAndFooterTemplate>
  )
}
