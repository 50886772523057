import {useQuery} from '@apollo/react-hooks'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Typography,
  useMediaQuery
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {CameraDevice} from 'html5-qrcode/esm/core'
import {compact, last, omit} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import DraggableList from 'react-draggable-list'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  AdvancedSettingsDivisionsQuery,
  AdvancedSettingsDivisionsQueryVariables,
  DiscountAuthorizationMode,
  DivisionState,
  NarrowDivisionPropertiesFragment,
  PassCodeCheckInputType,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  InputBlockWithoutSpacings,
  RenderOnData,
  SingleSideNavigationList
} from '../../../../common'
import {AddDivisionDialog} from '../../../../common/enabledDivisions/AddDivisionDialog'
import {
  DraggableDivisionItem,
  IDivisionItemCommonProps
} from '../../../../common/enabledDivisions/DraggableDivisionItem'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {MediaSizes} from '../../../../constants'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'
import {useFetchCameras} from '../../../../html5qrcode/fetchCameras'
import {MissingCameraPermissionBlank} from '../../../../html5qrcode/MissingCameraPermissionBlank'
import {NoCamerasFoundBlank} from '../../../../html5qrcode/NoCamerasFoundBlank'
import {Error, Loading} from '../../../../visual'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout, CenteredLayoutListWrapper} from '../../Layout'
import {useAdvancedSettings} from '../AdvancedSettingsContext'
import {
  usePerformActionRequiredEffect,
  usePerformFailedEffect,
  usePerformSuccessEffect
} from '../deviceEffects'
import {ADVANCED_SETTINGS_DIVISIONS} from '../graphql'
import {
  AdvancedSettingField,
  CheckingMethod,
  DeviceEffect,
  IAdvancedSettingsForm,
  PauseAfterScan
} from '../types'
import {useTranslatePauseAfterScan} from './pauseAfterScanUtils'
import {SettingBox} from './SettingBox'

const useItems = () => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General')
    },
    effects: {
      id: 'effects',
      label: t('Effects')
    },
    deviceSettings: {
      id: 'deviceSettings',
      label: t('Device settings')
    },
    enabledDivisions: {
      id: 'enabledDivisions',
      label: t('Enabled divisions')
    }
  }
}

export const PlayButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {t} = useTranslation()
  return (
    <Button color="primary" {...props}>
      {t('Play')}
    </Button>
  )
}

const useStyles = makeStyles<Theme, {isPhablet: boolean}>((theme) => ({
  select: {
    width: ({isPhablet}) => (isPhablet ? '100%' : 296),
    paddingTop: theme.spacing(1)
  },
  settingsBoxChildren: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: ({isPhablet}) =>
      isPhablet ? 'flex-start' : 'space-between',
    alignItems: ({isPhablet}) => (isPhablet ? 'flex-start' : 'center'),
    flexDirection: ({isPhablet}) => (isPhablet ? 'column' : 'row')
  }
}))

const ADVANCED_SETTINGS_FORM_ID = 'ADVANCED_SETTINGS_FORM_ID'

export const AdvancedSettingsPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleCancelClick = useCallback(() => {
    history.goBack()
  }, [history])
  const items = useItems()
  const {
    settings: {
      defaultInputType,
      discountAuthorization,
      pauseAfterScan = PauseAfterScan.For1000ms,
      cameraId,
      allowedDeviceEffect = DeviceEffect.Sound,
      deniedDeviceEffect = DeviceEffect.Sound,
      actionRequiredDeviceEffect = DeviceEffect.Sound,
      enabledDivisions = [],
      checkingMethod = CheckingMethod.SingleTicket
    },
    setSettings
  } = useAdvancedSettings()
  const [selectedEnabledDivisions, setSelectedEnabledDivisions] = useState<
    NarrowDivisionPropertiesFragment[]
  >([])
  const {
    data: divisionsData,
    error: divisionsError,
    loading: divisionsLoading
  } = useQuery<
    AdvancedSettingsDivisionsQuery,
    AdvancedSettingsDivisionsQueryVariables
  >(ADVANCED_SETTINGS_DIVISIONS, {
    onCompleted: (data) =>
      setSelectedEnabledDivisions(
        compact(
          enabledDivisions.map((ed) => data.divisions.find(({id}) => id === ed))
        )
      )
  })
  const {setValue, register, errors, watch, handleSubmit} =
    useForm<IAdvancedSettingsForm>({
      defaultValues: {
        [AdvancedSettingField.DiscountAuthorization]: discountAuthorization,
        [AdvancedSettingField.DefaultInputType]: defaultInputType,
        [AdvancedSettingField.PauseAfterScan]: pauseAfterScan,
        [AdvancedSettingField.CameraId]: cameraId,
        [AdvancedSettingField.AllowedDeviceEffect]: allowedDeviceEffect,
        [AdvancedSettingField.DeniedDeviceEffect]: deniedDeviceEffect,
        [AdvancedSettingField.ActionRequiredDeviceEffect]:
          actionRequiredDeviceEffect,
        [AdvancedSettingField.CheckingMethod]: checkingMethod
      }
    })
  const {
    state: isAddDivisionDialogOpen,
    setTrue: openAddDivisionDialog,
    setFalse: closeAddDivisionDialog
  } = useBooleanState(false)
  const filteredDivisions = (divisionsData?.divisions || [])
    .filter((d) => d.state === DivisionState.Active)
    .filter((division) => !selectedEnabledDivisions.includes(division))
    .sort((divisionA, divisionB) =>
      divisionA.name.localeCompare(divisionB.name)
    )
  const handleAddButtonClick = useCallback(
    (divisions: NarrowDivisionPropertiesFragment[]) => {
      setSelectedEnabledDivisions((selectedEnabledDivisions) => [
        ...selectedEnabledDivisions,
        ...divisions
      ])
    },
    []
  )
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const classes = useStyles({isPhablet})
  const handleFormSubmit = useCallback(
    (form: IAdvancedSettingsForm) => {
      const splittedEnabledDivisions = form[
        AdvancedSettingField.EnabledDivisions
      ]
        .split(',')
        .filter(Boolean)
      setSettings({
        ...omit(form, AdvancedSettingField.EnabledDivisions),
        enabledDivisions: splittedEnabledDivisions.length
          ? splittedEnabledDivisions.map((id) => parseInt(id, 10))
          : []
      })
      handleCancelClick()
    },
    [handleCancelClick, setSettings]
  )
  const translatePauseAfterScan = useTranslatePauseAfterScan()
  const {
    state: {
      isLoading: isLoadingCameras,
      error: fetchingCamerasError,
      data: fetchedCameras
    },
    refetchCameras
  } = useFetchCameras()
  const effectsSelectOptions = {
    [DeviceEffect.NoEffect]: t('No effects'),
    [DeviceEffect.Vibration]: t('Vibration'),
    [DeviceEffect.Sound]: t('Sound'),
    [DeviceEffect.VibrationAndSound]: t('Vibration and sound')
  }
  const handleSuccessEffect = usePerformSuccessEffect(
    watch(AdvancedSettingField.AllowedDeviceEffect)
  )
  const handleFailedEffect = usePerformFailedEffect(
    watch(AdvancedSettingField.DeniedDeviceEffect)
  )
  const handleActionRequiredEffect = usePerformActionRequiredEffect(
    watch(AdvancedSettingField.ActionRequiredDeviceEffect)
  )
  const watchedCheckingMethod = watch(AdvancedSettingField.CheckingMethod)
  useEffect(() => {
    if (watchedCheckingMethod === CheckingMethod.WholeSaleAtOnce) {
      setValue(
        AdvancedSettingField.DiscountAuthorization,
        DiscountAuthorizationMode.DoNotAuthorize
      )
    }
  }, [setValue, watchedCheckingMethod])
  useEffect(() => {
    setValue(
      AdvancedSettingField.EnabledDivisions,
      selectedEnabledDivisions.map((d) => d.id).join()
    )
  }, [selectedEnabledDivisions, setValue])
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader title={t('Checking preferences')} isDatetimeEnabled />
      }
      footer={
        <Footer>
          <>
            <Button color="primary" onClick={handleCancelClick}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form={ADVANCED_SETTINGS_FORM_ID}
            >
              {t('Save')}
            </Button>
          </>
        </Footer>
      }
    >
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
        autoComplete="off"
        id={ADVANCED_SETTINGS_FORM_ID}
      >
        <CenteredLayout>
          <SingleSideNavigationList items={items} />
          <CenteredLayoutListWrapper>
            <InputBlockWithoutSpacings
              header={items.general.label}
              blockId={items.general.id}
            >
              <SettingBox
                label={t('Discount authorization')}
                description={t(
                  'Select which tickets will require additional discount authorization after check. You will be promped to authorize all discounted tickets for selected selling channel.'
                )}
              >
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={{
                    [DiscountAuthorizationMode.DoNotAuthorize]: t(
                      'Do not authorize discounts'
                    ),
                    [DiscountAuthorizationMode.RetailOnly]: t('Sold on retail'),
                    [DiscountAuthorizationMode.ECommerceOnly]:
                      t('Sold on ecommerce'),
                    [DiscountAuthorizationMode.AuthorizeAll]: t('All tickets')
                  }}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.DiscountAuthorization}
                  errors={errors}
                  disabled={
                    watchedCheckingMethod === CheckingMethod.WholeSaleAtOnce
                  }
                  fullWidth
                />
              </SettingBox>
              <Divider />
              <SettingBox label={t('Default entry mode')}>
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={{
                    [PassCodeCheckInputType.Scanned]: t('Camera'),
                    [PassCodeCheckInputType.Typed]: t('Typed')
                  }}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.DefaultInputType}
                  errors={errors}
                  fullWidth
                />
              </SettingBox>
              <Divider />
              <SettingBox
                label={t('Default checking mode')}
                description={t(
                  "Choose preferred checking method below: 'Single Ticket' for individual ticket verification, or 'Whole Sale' to verify all tickets in purchase in one go."
                )}
              >
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={{
                    [CheckingMethod.SingleTicket]: t('Single ticket'),
                    ...(P([PermissionCode.ReadSaleByItemPassCode])
                      ? {
                          [CheckingMethod.WholeSaleAtOnce]:
                            t('Whole sale at once')
                        }
                      : {})
                  }}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.CheckingMethod}
                  errors={errors}
                  fullWidth
                />
              </SettingBox>
            </InputBlockWithoutSpacings>
            <InputBlockWithoutSpacings
              header={items.effects.label}
              blockId={items.effects.id}
            >
              <SettingBox
                label={t('Effect after code check')}
                description={t(
                  'Set up effects for each type of response after ticket verification. This setting will be applied only for this specific device and it is not shared among all devices that you use. We are sorry, but vibrations are not supported by iPhones.'
                )}
              >
                <div />
              </SettingBox>
              <SettingBox
                label={t('Allowed')}
                description={t(
                  'Attendee successfully checked in or checked out'
                )}
                childrenClassName={classes.settingsBoxChildren}
              >
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={effectsSelectOptions}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.AllowedDeviceEffect}
                  errors={errors}
                  fullWidth
                />
                <PlayButton onClick={handleSuccessEffect} />
              </SettingBox>
              <Divider />
              <SettingBox
                label={t('Denied')}
                description={t(
                  'Code not found, access denied, different event, ticket was claimed, etc.'
                )}
                childrenClassName={classes.settingsBoxChildren}
              >
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={effectsSelectOptions}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.DeniedDeviceEffect}
                  errors={errors}
                  fullWidth
                />
                <PlayButton onClick={handleFailedEffect} />
              </SettingBox>
              <Divider />
              <SettingBox
                label={t('Additional action required')}
                description={t('Discount verification and bidirectional mode')}
                childrenClassName={classes.settingsBoxChildren}
              >
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={effectsSelectOptions}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.ActionRequiredDeviceEffect}
                  errors={errors}
                  fullWidth
                />
                <PlayButton onClick={handleActionRequiredEffect} />
              </SettingBox>
            </InputBlockWithoutSpacings>
            <InputBlockWithoutSpacings
              header={items.deviceSettings.label}
              blockId={items.deviceSettings.id}
            >
              <SettingBox
                label={t('Pause after scan')}
                description={t(
                  "How long will app pause to scan another code, when using device's camera."
                )}
              >
                <UncontrolledFormSelect<IAdvancedSettingsForm>
                  label=""
                  className={classes.select}
                  selectOptions={Object.values(PauseAfterScan).reduce(
                    (acc, key) => ({
                      ...acc,
                      [key]: translatePauseAfterScan(key)
                    }),
                    {}
                  )}
                  setValue={setValue}
                  register={register}
                  watch={watch}
                  name={AdvancedSettingField.PauseAfterScan}
                  errors={errors}
                  fullWidth
                />
              </SettingBox>
              <Divider />
              <SettingBox
                label={t('Default device camera')}
                description={t(
                  'Will be applied, if device support this feature'
                )}
              >
                <RenderOnData<CameraDevice[]>
                  loading={isLoadingCameras}
                  error={fetchingCamerasError}
                  data={fetchedCameras}
                  errorChildren={() => (
                    <MissingCameraPermissionBlank
                      refetchCameras={refetchCameras}
                    />
                  )}
                >
                  {(fetchedCameras) =>
                    fetchedCameras.length === 0 ? (
                      <NoCamerasFoundBlank />
                    ) : (
                      <UncontrolledFormSelect<IAdvancedSettingsForm>
                        label=""
                        className={classes.select}
                        selectOptions={fetchedCameras.reduce(
                          (acc, {id, label}) => ({
                            ...acc,
                            [id]: label
                          }),
                          {}
                        )}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                        validationOptions={{required: true}}
                        name={AdvancedSettingField.CameraId}
                        errors={errors}
                        fullWidth
                      />
                    )
                  }
                </RenderOnData>
              </SettingBox>
            </InputBlockWithoutSpacings>
            <InputBlockWithoutSpacings
              header={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  {items.enabledDivisions.label}
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={openAddDivisionDialog}
                    disabled={filteredDivisions.length === 0}
                  >
                    {t('Add division')}
                  </Button>
                </Box>
              }
              headerSx={{width: '100%'}}
              blockId={items.enabledDivisions.id}
            >
              <Box>
                <Box sx={{p: 2}}>
                  <Typography variant="subtitle2">
                    {t('Divisions enabled on device')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t(
                      'Leave empty, if all divisions should be enabled on device for checking tickets.'
                    )}
                  </Typography>
                </Box>
                {divisionsLoading && <Loading />}
                {divisionsError && (
                  <Error
                    error={divisionsError}
                    message={t('Could not load divisions')}
                  />
                )}
                {selectedEnabledDivisions.length > 0 && <Divider />}
                <input
                  type="hidden"
                  ref={register()}
                  name={AdvancedSettingField.EnabledDivisions}
                />
                <DraggableList<
                  NarrowDivisionPropertiesFragment,
                  IDivisionItemCommonProps,
                  DraggableDivisionItem
                >
                  list={selectedEnabledDivisions}
                  itemKey="id"
                  template={DraggableDivisionItem}
                  padding={0}
                  constrainDrag
                  onMoveEnd={(
                    divisions: readonly NarrowDivisionPropertiesFragment[]
                  ) => {
                    setSelectedEnabledDivisions([...divisions])
                  }}
                  commonProps={{
                    isLastItem: (divisionId: number) =>
                      last(selectedEnabledDivisions)?.id === divisionId,
                    getRemoveButtonClickHandler:
                      (division: NarrowDivisionPropertiesFragment) => () =>
                        setSelectedEnabledDivisions(
                          (selectedEnabledDivisions) =>
                            selectedEnabledDivisions.filter(
                              ({id}) => id !== division.id
                            )
                        )
                  }}
                />
                <AddDivisionDialog
                  isOpen={isAddDivisionDialogOpen}
                  onClose={closeAddDivisionDialog}
                  divisions={filteredDivisions}
                  onAddButtonClick={handleAddButtonClick}
                />
              </Box>
            </InputBlockWithoutSpacings>
          </CenteredLayoutListWrapper>
        </CenteredLayout>
      </form>
    </PageWithHeaderAndFooterTemplate>
  )
}
