import {GridCellParams} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import dayjs from 'dayjs'
import {groupBy, isNil} from 'lodash'
import {useCallback} from 'react'
import {
  Event,
  LocaleCode,
  TourTimeSlot,
  Translated
} from '../../../__generated__/schema'
import {IDataPickerData} from '../../common/datePicker/types'
import {useTransformDatePickerValueToDate} from '../../common/datePicker/utils'
import {useLocale} from '../../context/locale'
import {GroupedEntitiesType, GroupedEventsType} from './types'

export const DAY_FORMAT = 'DD-MM-YYYY'
const DURATION_REGEX =
  /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/

export const parseDateFromDateTime = (datetime: string) => {
  return dayjs(datetime).format(DAY_FORMAT)
}

const getCompareFunction =
  <T extends Pick<Event, 'startsAt' | 'names' | 'id'>>(
    clientTranslation: (field: Translated) => string
  ) =>
  (eventA: T, eventB: T) => {
    if (eventA.startsAt > eventB.startsAt) {
      return 1
    } else if (eventA.startsAt < eventB.startsAt) {
      return -1
    } else {
      const clientAName = clientTranslation(eventA.names)
      const clientBName = clientTranslation(eventB.names)
      if (clientAName > clientBName) {
        return 1
      } else if (clientAName < clientBName) {
        return -1
      }
    }
    return eventA.id > eventB.id ? 1 : -1
  }

export const groupEventsByStartAtDate = <
  T extends Pick<Event, 'startsAt' | 'names' | 'id'>
>(
  eventsData: T[],
  clientTranslation: (field: Translated) => string
): GroupedEventsType<T>[] => {
  const ungrouped = eventsData.map((event: T) => ({
    date: parseDateFromDateTime(event.startsAt),
    event
  }))
  const grouped = groupBy(ungrouped, 'date')
  return Object.entries(grouped).map(([date, dayItems]) => ({
    date,
    events: dayItems
      .map((dayItem) => dayItem.event)
      .sort(getCompareFunction(clientTranslation))
  }))
}

export const groupEntitiesByStartsAtDate = <
  T extends
    | Pick<TourTimeSlot, 'startsAt' | 'names' | 'id'>
    | Pick<Event, 'startsAt' | 'names' | 'id'>
>(
  tourTimeSlotsData: T[],
  clientTranslation: (field: Translated) => string
): GroupedEntitiesType<T>[] => {
  const ungrouped = tourTimeSlotsData.map((tourTimeSlot) => ({
    date: parseDateFromDateTime(tourTimeSlot.startsAt),
    tourTimeSlot
  }))
  const grouped = groupBy(ungrouped, 'date')
  return Object.entries(grouped).map(([date, dayItems]) => ({
    date,
    entities: dayItems
      .map((dayItem) => dayItem.tourTimeSlot)
      .sort(getCompareFunction(clientTranslation))
  }))
}

export const calculateCeil = (a: number, b: number) => Math.ceil(a / b)

export const useGetFilterDateRange = (onlyDate?: boolean) => {
  const transformDatePickerValueToDate = useTransformDatePickerValueToDate()
  return useCallback(
    ({
      date,
      filterNameFrom,
      filterNameTo
    }: {
      date?: IDataPickerData
      filterNameFrom: string
      filterNameTo: string
    }) => {
      const dateFormat = 'YYYY-MM-DD'
      const transformedDate = transformDatePickerValueToDate(date?.value)
      if (date?.dateRange?.dateFrom && !date.dateRange.dateTo) {
        return {
          [filterNameFrom]: onlyDate
            ? dayjs(date.dateRange.dateFrom).format(dateFormat)
            : dayjs(date.dateRange.dateFrom).toISOString()
        }
      }
      if (date?.dateRange?.dateTo && !date.dateRange.dateFrom) {
        return {
          [filterNameTo]: onlyDate
            ? dayjs(date.dateRange.dateTo).format(dateFormat)
            : dayjs(date.dateRange.dateTo).toISOString()
        }
      }
      if (date?.dateRange?.dateFrom && date?.dateRange?.dateTo) {
        return {
          [filterNameFrom]: onlyDate
            ? dayjs(date.dateRange.dateFrom).format(dateFormat)
            : dayjs(date.dateRange.dateFrom).startOf('d').toISOString(),
          [filterNameTo]: onlyDate
            ? dayjs(date.dateRange.dateTo).format(dateFormat)
            : dayjs(date.dateRange.dateTo).endOf('d').toISOString()
        }
      }
      return {
        [filterNameFrom]: transformedDate?.dateFrom
          ? onlyDate
            ? dayjs(transformedDate.dateFrom).format(dateFormat)
            : dayjs(transformedDate.dateFrom).toISOString()
          : undefined,
        [filterNameTo]: transformedDate?.dateTo
          ? onlyDate
            ? dayjs(transformedDate.dateTo).format(dateFormat)
            : dayjs(transformedDate.dateTo).toISOString()
          : undefined
      }
    },
    [onlyDate, transformDatePickerValueToDate]
  )
}

export const durationToJSON = (duration: string) => {
  const [, years, months, weeks, days, hours, minutes, seconds] =
    duration.match(DURATION_REGEX) || []
  return {
    years: years ? parseInt(years, 10) : 0,
    months: months ? parseInt(months, 10) : 0,
    weeks: weeks ? parseInt(weeks, 10) : 0,
    days: days ? parseInt(days, 10) : 0,
    hours: hours ? parseInt(hours, 10) : 0,
    minutes: minutes ? parseInt(minutes, 10) : 0,
    seconds: seconds ? parseInt(seconds, 10) : 0
  }
}

export const slugify = (str: string) =>
  str
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')

export const getBoldCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) && params.rowNode.type === 'pinnedRow'
    ? clsx('bold')
    : ''

export const useFormatDecimalToLocaleString = ({
  fractionDigits,
  defaultLocale
}: {
  fractionDigits?: number
  defaultLocale?: LocaleCode
}) => {
  const {localeCode} = useLocale()
  return (value: number) =>
    value.toLocaleString(defaultLocale || localeCode, {
      minimumFractionDigits: !isNil(fractionDigits) ? fractionDigits : 2
    })
}

export const normalize = (text: string) =>
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
