import AddIcon from '@mui/icons-material/Add'
import {Button, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {PaymentMethodType} from '../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../hooks/effectiveClientCurrencySignInputProps'
import {usePiecesInputProps} from '../../../../../hooks/piecesInputProps'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {convertValueToFloat} from '../../../../../utils/conversions'
import {
  IIntentFrameDialogForm,
  IntentFrameDialog,
  IntentFrameDialogField
} from '../../../../common/IntentFrameDialog'
import {
  IntentsStructureDispatchContext,
  IntentStructureActionType
} from './intentsStructure'

import {IAddIntentFrame} from './types'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

interface IAddIntentFrameProps {
  addIntentFrame: IAddIntentFrame
  structureKey: number | PaymentMethodType
  className?: string
}

export const AddIntentFrame: React.FC<IAddIntentFrameProps> = ({
  addIntentFrame,
  structureKey,
  className
}: IAddIntentFrameProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const intentStructureDispatch = useContext(IntentsStructureDispatchContext)
  const {
    state: isDialogOpened,
    setFalse: closeDialog,
    setTrue: openDialog
  } = useBooleanState(false)
  const handleDialogSubmit = useCallback(
    (form: IIntentFrameDialogForm) => {
      intentStructureDispatch({
        type: IntentStructureActionType.InsertCountIntentFrameBeforeAddIntentFrame,
        payload: {
          addIntentFrame: {
            ...addIntentFrame,
            initialCount: parseInt(form[IntentFrameDialogField.Quantity], 10),
            initialDenomination:
              convertValueToFloat(form[IntentFrameDialogField.Denomination]) ||
              0
          },
          structureKey
        }
      })
      closeDialog()
    },
    [addIntentFrame, closeDialog, intentStructureDispatch, structureKey]
  )
  const translatePrice = useTranslateEffectiveClientPrice()
  const effectiveClientCurrencySignInputProps =
    useEffectiveClientCurrencySignInputProps()
  const piecesInputProps = usePiecesInputProps()
  return (
    <div className={cn(classes.root, className)}>
      <Typography variant="subtitle2">{addIntentFrame.name}</Typography>
      <Button
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={openDialog}
      >
        {t('Add')}
      </Button>
      <IntentFrameDialog
        isOpen={isDialogOpened}
        onClose={closeDialog}
        intentFrameDialogFormId={`intent-frame-${addIntentFrame.key}-dialog-form`}
        defaultValues={{
          [IntentFrameDialogField.Denomination]: '',
          [IntentFrameDialogField.Quantity]: '1'
        }}
        onSubmit={handleDialogSubmit}
        title={addIntentFrame.name}
        translatePrice={translatePrice}
        DenominationInputProps={effectiveClientCurrencySignInputProps}
        QuantityInputProps={piecesInputProps}
        fieldWithAutoFocus={IntentFrameDialogField.Denomination}
      />
    </div>
  )
}
