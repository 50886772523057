import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography
} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {WebsitesQuery} from '../../../../__generated__/schema'
import {useTranslateWebsiteState} from '../../../../hooks/translateWebsiteState'
import {EntityStateChip} from '../../../common'
import {websiteStateColors} from '../../../constants'

const extractRepositoryName = (value: string) => {
  const last = value.lastIndexOf('/')
  return value.substring(last + 1)
}

interface IWebsiteCardProps {
  website: WebsitesQuery['websites']['items'][number]
  onDetailClick?: (id: number) => () => void
  hasSupportUserPermission?: boolean
}

export const WebsiteCard: React.FC<IWebsiteCardProps> = ({
  website,
  onDetailClick,
  hasSupportUserPermission
}: IWebsiteCardProps) => {
  const {t} = useTranslation()
  const translateWebsiteState = useTranslateWebsiteState()
  const handleDomainClick = useCallback(() => {
    if (website.primaryDomain) {
      window.open(website.primaryDomain, '_blank')
    }
  }, [website.primaryDomain])
  return (
    <Card variant="outlined" sx={{width: '100%'}}>
      <CardContent
        sx={{
          p: 2,
          '&:last-child': {
            pb: 2
          },
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="subtitle2">{website.name}</Typography>
          {onDetailClick && (
            <IconButton color="primary" onClick={onDetailClick(website.id)}>
              <ChevronRightIcon />
            </IconButton>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            minHeight: 34,
            flexWrap: 'wrap'
          }}
        >
          <EntityStateChip
            label={translateWebsiteState(website.state)}
            colorConf={websiteStateColors[website.state]}
          />
          <Typography component="div" variant="caption" color="textSecondary">
            {website.primaryDomain || t('Domain is not set')}
            {website.primaryDomain && (
              <IconButton size="small" onClick={handleDomainClick}>
                <OpenInNewIcon />
              </IconButton>
            )}
          </Typography>
        </Box>
        {hasSupportUserPermission && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap'
            }}
          >
            <Chip size="small" label={website.productionBranch} />
            <Chip
              size="small"
              label={extractRepositoryName(website.projectFullPath)}
              icon={<FolderOpenIcon />}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
