import {Drawer} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PaymentMethodType,
  PaymentServiceProvider
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {
  useCreateVoucherCampaign,
  useGetLightweightECommercePaymentMethods,
  useGetLightweightRetailPaymentMethods
} from './graphql'
import {
  IVoucherCampaignForm,
  transformDataToInput,
  VoucherCampaignFormLocation
} from './types'
import {VoucherCampaignForm} from './VoucherCampaignForm'

const CREATE_VOUCHER_CAMPAIGN_FORM_ID = 'createVoucherCampaignForm'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  },
  drawerChildren: {
    backgroundColor: theme.palette.background.paper
  }
}))

interface ICreateVoucherCampaignDrawerProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

export const CreateVoucherCampaignDrawer: React.FC<ICreateVoucherCampaignDrawerProps> =
  ({isOpen, onClose, refetch}: ICreateVoucherCampaignDrawerProps) => {
    const {t} = useTranslation()
    const createVoucherCampaign = useCreateVoucherCampaign()
    const {data: retailPaymentMethodsData} =
      useGetLightweightRetailPaymentMethods()
    const {data: eCommercePaymentMethodsData} =
      useGetLightweightECommercePaymentMethods()
    const {setShowBackdrop, addInfoNotification, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: IVoucherCampaignForm) => {
        try {
          setShowBackdrop(true)
          await createVoucherCampaign(transformDataToInput(data))
          addInfoNotification(t('Voucher campaign created'))
          refetch()
          onClose()
        } catch (error) {
          defaultErrorHandler(error, t('Error while creating voucher campaign'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createVoucherCampaign,
        defaultErrorHandler,
        onClose,
        refetch,
        setShowBackdrop,
        t
      ]
    )
    return (
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        classes={{paper: classes.drawerPaper}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create voucher campaign')}
            />
          }
          footer={
            <SaveButton type="submit" form={CREATE_VOUCHER_CAMPAIGN_FORM_ID}>
              {t('Create')}
            </SaveButton>
          }
          childrenClassName={classes.drawerChildren}
        >
          {retailPaymentMethodsData && eCommercePaymentMethodsData && (
            <VoucherCampaignForm
              location={VoucherCampaignFormLocation.Create}
              formId={CREATE_VOUCHER_CAMPAIGN_FORM_ID}
              onSubmit={handleSubmit}
              retailPaymentMethods={retailPaymentMethodsData.retailPaymentMethods.filter(
                ({serviceProvider, type}) =>
                  serviceProvider === PaymentServiceProvider.InHouseVoucher &&
                  type === PaymentMethodType.Voucher
              )}
              eCommercePaymentMethods={eCommercePaymentMethodsData.eCommercePaymentMethods.filter(
                ({serviceProvider, type}) =>
                  serviceProvider === PaymentServiceProvider.InHouseVoucher &&
                  type === PaymentMethodType.Voucher
              )}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
