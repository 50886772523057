import FlipIcon from '@mui/icons-material/Flip'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw'
import Rotate90DegreesCwOutlinedIcon from '@mui/icons-material/Rotate90DegreesCwOutlined'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import {IconButton} from '@mui/material'
import Paper from '@mui/material/Paper'
import React, {useCallback} from 'react'
import {ReactCropperElement} from 'react-cropper'
import {useTranslation} from 'react-i18next'
import {Tooltip} from '../Tooltip'

const CROPPER_ZOOM = 0.1
const ROTATE = 45

interface IToolbarProps {
  cropperRef: React.MutableRefObject<ReactCropperElement | null>
}

export const Toolbar: React.FC<IToolbarProps> = ({
  cropperRef
}: IToolbarProps) => {
  const {t} = useTranslation()
  const handleZoomInIconClick = useCallback(
    () => cropperRef.current?.cropper.zoom(CROPPER_ZOOM),
    [cropperRef]
  )
  const handleZoomOutIconClick = useCallback(
    () => cropperRef.current?.cropper.zoom(-CROPPER_ZOOM),
    [cropperRef]
  )
  const handleVerticalFlip = useCallback(
    () =>
      cropperRef.current?.cropper.scaleY(
        -cropperRef.current?.cropper.getData().scaleY || -1
      ),
    [cropperRef]
  )
  const handleHorizontalFlip = useCallback(
    () =>
      cropperRef.current?.cropper.scaleX(
        -cropperRef.current?.cropper.getData().scaleX || -1
      ),
    [cropperRef]
  )
  const handleRotateLeft = useCallback(
    () => cropperRef.current?.cropper.rotate(-ROTATE),
    [cropperRef]
  )
  const handleRotateRight = useCallback(
    () => cropperRef.current?.cropper.rotate(ROTATE),
    [cropperRef]
  )
  const handleRestartIconClick = useCallback(
    () => cropperRef.current?.cropper.reset(),
    [cropperRef]
  )
  return (
    <Paper
      sx={{
        position: 'absolute',
        width: 360,
        height: 48,
        left: 0,
        right: 0,
        bottom: (theme) => theme.spacing(2),
        margin: '0 auto',
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Tooltip title={t('Zoom in')}>
        <IconButton onClick={handleZoomInIconClick}>
          <ZoomInIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Zoom out')}>
        <IconButton onClick={handleZoomOutIconClick}>
          <ZoomOutIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Rotate {{degree}}° left', {degree: ROTATE})}>
        <IconButton onClick={handleRotateLeft}>
          <Rotate90DegreesCcwIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Rotate {{degree}}° right', {degree: ROTATE})}>
        <IconButton onClick={handleRotateRight}>
          <Rotate90DegreesCwOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Flip horizontal')}>
        <IconButton onClick={handleHorizontalFlip}>
          <FlipIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Flip vertical')}>
        <IconButton onClick={handleVerticalFlip}>
          <FlipIcon sx={{transform: 'rotate(90deg)'}} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Reset')}>
        <IconButton onClick={handleRestartIconClick}>
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  )
}
