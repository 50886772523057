import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  AuditoriumLayoutSupportUserPropertiesFragment,
  VenueSupportUserPropertiesFragment
} from '../../../../__generated__/schema'
import {useDefaultErrorHandler} from '../../../../utils/errors'
import {useBackdropState} from '../../../context/backdrop'
import {useNotifications} from '../../../context/notifications'
import {FormTextInput} from '../../../form/FormTextInput'
import {useDuplicateAuditoriumLayoutSupportUser} from './graphql'

enum FieldName {
  LAYOUT_NAME = 'layoutName'
}

interface IDuplicateLayoutFormData {
  [FieldName.LAYOUT_NAME]: string
}

interface IDuplicateDialogProps {
  isOpen: boolean
  source: AuditoriumLayoutSupportUserPropertiesFragment
  destination: VenueSupportUserPropertiesFragment['auditoriums'][number]
  closeDialog: () => void
  resetForm: () => void
}

const DUPLICATE_DIALOG_FORM_ID = 'duplicate_dialog_form'

export const DuplicateDialog: React.FC<IDuplicateDialogProps> = ({
  isOpen,
  source,
  destination,
  closeDialog,
  resetForm
}: IDuplicateDialogProps) => {
  const {t} = useTranslation()
  const {
    register,
    unregister,
    setValue,
    watch,
    errors,
    handleSubmit,
    triggerValidation
  } = useForm<IDuplicateLayoutFormData>({
    defaultValues: {
      [FieldName.LAYOUT_NAME]: t('Copy of {{layoutName}}', {
        layoutName: source.name
      })
    }
  })
  const {setShowBackdrop} = useBackdropState()
  const {addInfoNotification} = useNotifications()
  const defaultErrorHandler = useDefaultErrorHandler()
  const duplicateAuditoriumLayout = useDuplicateAuditoriumLayoutSupportUser()
  const submit = async (data: IDuplicateLayoutFormData) => {
    try {
      setShowBackdrop(true)
      await duplicateAuditoriumLayout({
        auditoriumId: destination.id,
        id: source.id,
        name: data[FieldName.LAYOUT_NAME]
      })
      addInfoNotification(t('Auditorium layout duplicated.'))
      closeDialog()
      resetForm()
    } catch (err) {
      defaultErrorHandler(err, t('Error while duplicate auditorium layout.'))
    } finally {
      setShowBackdrop(false)
    }
  }
  const handleCloseDialog = () => {
    closeDialog()
  }

  return (
    <Dialog open={isOpen} onClose={() => closeDialog}>
      <DialogTitle>{t('Duplicate auditorium layout?')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'Are you sure you want to duplicate auditorium layout {{from}} to auditorium {{to}}? Default state of the duplicate will be Draft. Layout pricings will not be duplicated.  Edit name of the duplicate below.',
            {
              from: source.name,
              to: destination.name
            }
          )}
        </DialogContentText>
        <form
          autoComplete="off"
          id={DUPLICATE_DIALOG_FORM_ID}
          onSubmit={handleSubmit(submit)}
        >
          <FormTextInput<IDuplicateLayoutFormData>
            label={t('Name')}
            name={FieldName.LAYOUT_NAME}
            fullWidth
            register={register}
            unregister={unregister}
            setValue={setValue}
            watch={watch}
            errors={errors}
            autoFocus
            triggerValidation={triggerValidation}
            validationOptions={{
              required: true
            }}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          {t('Cancel')}
        </Button>
        <Button type={'submit'} form={DUPLICATE_DIALOG_FORM_ID} color="primary">
          {t('Duplicate')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
