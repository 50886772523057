import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  LocaleCode,
  TemplateFileType,
  TemplateQuery,
  TemplateType
} from '../../../../__generated__/schema'
import {useTranslateLocaleCode} from '../../../../hooks/translateLocales'
import {
  useTranslateTemplateDatasetType,
  useTranslateTemplateFileType,
  useTranslateTemplateType
} from '../../../../hooks/translateTemplates'
import {Theme} from '../../../../theme'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {
  useIsFormMultiSelectRequired,
  useIsStringWithMaxLength
} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {FormMultiSelect} from '../../../visual'
import {ITemplateSettingsForm, TemplateFormField} from './types'

const useTemplateDetailInfoStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    paddingBottom: theme.spacing(1)
  }
}))

interface ITemplateDetailInfoProps {
  title: string
  value: string
}

const TemplateDetailInfo: React.FC<ITemplateDetailInfoProps> = ({
  title,
  value
}: ITemplateDetailInfoProps) => {
  const classes = useTemplateDetailInfoStyles()
  return (
    <div className={classes.root}>
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  )
}

const useTemplateSettingsFormContentStyles = makeStyles<Theme>((theme) => ({
  title: {
    padding: theme.spacing(2, 0)
  }
}))

interface ITemplateSettingsFormContentProps {
  template: TemplateQuery['template']
}

export const TemplateSettingsFormContent: React.FC<ITemplateSettingsFormContentProps> =
  ({template}: ITemplateSettingsFormContentProps) => {
    const {t} = useTranslation()
    const classes = useTemplateSettingsFormContentStyles()
    const {errors, setValue, watch, register, triggerValidation, unregister} =
      useFormContext<ITemplateSettingsForm>()
    const isStringWithMaxLength30 = useIsStringWithMaxLength(30)
    const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
    const isStringWithMaxLength256 = useIsStringWithMaxLength(256)
    const isFormMultiSelectRequired = useIsFormMultiSelectRequired()
    const translateLocaleCode = useTranslateLocaleCode()
    const translateTemplateType = useTranslateTemplateType()
    const translateTemplateFileType = useTranslateTemplateFileType()
    const translateTemplateDatasetType = useTranslateTemplateDatasetType()
    const {formatDateTimeWithSeconds} = useDateTimeFormatters()
    return (
      <>
        <input type="hidden" name={TemplateFormField.State} ref={register()} />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ITemplateSettingsForm>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              name={TemplateFormField.Name}
              key={TemplateFormField.Name}
              label={t('Name')}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength30
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<ITemplateSettingsForm>
              multiline
              rows={4}
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={TemplateFormField.Description}
              name={TemplateFormField.Description}
              label={t('Description')}
              validationOptions={{
                validate: isStringWithMaxLength1000
              }}
              fullWidth
            />
          ]}
        />
        <Typography variant="subtitle1" className={classes.title}>
          {t('Settings')}
        </Typography>
        <InputRow
          nodes={[
            <FormMultiSelect
              selectOptions={Object.values(TemplateType).reduce(
                (acc, type) => ({
                  ...acc,
                  [type]: translateTemplateType(type)
                }),
                {}
              )}
              errors={errors}
              setValue={setValue}
              selectedKeys={watch(TemplateFormField.Types)}
              register={register}
              unregister={unregister}
              name={TemplateFormField.Types}
              label={t('Type')}
              key={TemplateFormField.Types}
              validationOptions={{
                validate: {
                  isFormMultiSelectRequired
                }
              }}
              showRequiredLabel
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormSelect<ITemplateSettingsForm>
              selectOptions={Object.values(LocaleCode).reduce(
                (acc, localeCode) => ({
                  ...acc,
                  [localeCode]: translateLocaleCode(localeCode)
                }),
                {}
              )}
              errors={errors}
              setValue={setValue}
              register={register}
              watch={watch}
              name={TemplateFormField.LocaleCode}
              label={t('Locale')}
              key={TemplateFormField.LocaleCode}
              validationOptions={{
                required: true
              }}
              required
              fullWidth
            />
          ]}
        />
        {template.fileType === TemplateFileType.Pdf && (
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<ITemplateSettingsForm>
                multiline
                rows={4}
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                key={TemplateFormField.AdditionalSearchParams}
                name={TemplateFormField.AdditionalSearchParams}
                label={t('Additional arguments')}
                helperNote={t(
                  'Those arguments are used for filling puppeteer pdf options.'
                )}
                validationOptions={{
                  validate: isStringWithMaxLength256
                }}
                fullWidth
              />
            ]}
          />
        )}
        <Typography variant="subtitle1" className={classes.title}>
          {t('Template details')}
        </Typography>
        <TemplateDetailInfo
          title={t('File type')}
          value={translateTemplateFileType(template.fileType)}
        />
        <TemplateDetailInfo
          title={t('Dataset type')}
          value={translateTemplateDatasetType(template.dataSetType)}
        />
        <TemplateDetailInfo
          title={t('Active version')}
          value={[
            t('#{{versionId}}', {versionId: template.activeVersion.id}),
            template.activeVersion.name
          ].join(' • ')}
        />
        <TemplateDetailInfo
          title={t('Created')}
          value={[
            formatDateTimeWithSeconds(new Date(template.createdAt)),
            template.createdByName
          ].join(' • ')}
        />
        <TemplateDetailInfo
          title={t('Updated')}
          value={[
            formatDateTimeWithSeconds(new Date(template.updatedAt)),
            template.updatedByName
          ].join(' • ')}
        />
        <TemplateDetailInfo
          title={t('Assigned clients')}
          value={String(template.clientsCount)}
        />
      </>
    )
  }
