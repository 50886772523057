import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SxProps,
  Typography
} from '@mui/material'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuid} from 'uuid'
import {useMutationAssistanceHooks} from '../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../theme'
import {Loading} from '../../visual'
import {OutlinedInputWithCancelAdornment} from '../OutlinedInputWithCancelAdornment'
import {ScrollableContainer} from '../ScrollableContainer'
import {IIdListItem} from './IdListItem'
import {ListItemID} from './types'

interface IAddIdItemsCheckGroupProps<T extends ListItemID = number> {
  selectedIds: T[]
  checkedIds: T[]
  onCheck: (id: T) => void
  onUnCheck: (id: T) => void
  getOptions: (hasText?: string) => Promise<IIdListItem<T>[]>
  getMoreOptions?: (hasText?: string) => Promise<IIdListItem<T>[]>
  sx?: SxProps<Theme>
  isFilterTextInputHidden?: boolean
}

export const AddIdItemsCheckGroup = <T extends ListItemID = number>({
  getOptions,
  getMoreOptions,
  selectedIds,
  checkedIds,
  onCheck,
  onUnCheck,
  sx,
  isFilterTextInputHidden
}: IAddIdItemsCheckGroupProps<T>) => {
  const {t} = useTranslation()
  const [options, setOptions] = useState<IIdListItem<T>[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasText, setHasText] = useState<undefined | string>()
  const {customErrorHandler} = useMutationAssistanceHooks()
  const [scrollUuid, setScrollUuid] = useState<null | string>(null)

  const handleTextFieldChange = useRef(
    debounce((e) => {
      setHasText(e.target.value ?? undefined)
    }, 400)
  )

  const inputRef = useRef<null | HTMLInputElement>(null)

  useEffect(() => {
    let isActive = true
    setIsLoading(true)
    getOptions(hasText)
      .then((data) => {
        if (isActive) {
          setOptions(data)
        }
      })
      .catch((e) => {
        if (isActive) {
          customErrorHandler(e, {
            title: t('Loading data for dialog failed'),
            contentText: '',
            onConfirm: () => {
              window.location.reload()
            },
            confirmButtonLabel: t('Reload')
          })
        }
      })
      .finally(() => {
        if (isActive) {
          setIsLoading(false)
        }
      })
    return () => {
      isActive = false
    }
  }, [hasText, getOptions, customErrorHandler, t])

  useEffect(() => {
    let isActive = true
    if (scrollUuid) {
      setIsLoadingMore(true)
      getMoreOptions?.(hasText)
        .then((data) => {
          if (isActive) {
            setOptions(data)
          }
        })
        .catch((e) => {
          if (isActive) {
            customErrorHandler(e, {
              title: t('Loading more data for dialog failed'),
              contentText: '',
              onConfirm: () => {
                window.location.reload()
              },
              confirmButtonLabel: t('Reload')
            })
          }
        })
        .finally(() => {
          if (isActive) {
            setIsLoadingMore(false)
          }
        })
    }
    return () => {
      isActive = false
    }
  }, [getMoreOptions, hasText, customErrorHandler, t, scrollUuid])

  const handleScrollNearEndOfContainer = useCallback(() => {
    setScrollUuid(uuid())
  }, [])

  return (
    <Box sx={sx}>
      {!isFilterTextInputHidden && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            pt: 2
          }}
        >
          <OutlinedInputWithCancelAdornment
            label={t<string>('Filter items')}
            inputId=""
            inputProps={{
              fullWidth: true,
              onChange: handleTextFieldChange.current,
              inputRef
            }}
            sx={{
              pb: 1
            }}
            onCancelClick={() => {
              setHasText(undefined)
              if (inputRef.current) {
                inputRef.current.value = ''
                inputRef.current.focus()
              }
            }}
          />
        </Box>
      )}
      {isLoading ? (
        <Loading />
      ) : options.length === 0 ? (
        <Typography>{t('No items available')}</Typography>
      ) : (
        <ScrollableContainer
          isLoadingMore={isLoadingMore}
          hasMore={Boolean(getMoreOptions)}
          onScrollNearEndOfTheContainer={handleScrollNearEndOfContainer}
        >
          <FormGroup>
            {options.map(({id, firstRow, secondRow, thirdRow}) => (
              <FormControlLabel
                key={id}
                sx={{
                  ...(selectedIds.includes(id)
                    ? {}
                    : {
                        '&:hover': {
                          backgroundColor: 'grey.300'
                        }
                      }),
                  m: 0
                }}
                control={
                  <Checkbox
                    disabled={selectedIds.includes(id)}
                    onChange={(e, checked) => {
                      checked ? onCheck(id) : onUnCheck(id)
                    }}
                    value={id}
                    checked={checkedIds.includes(id)}
                    color="primary"
                  />
                }
                label={
                  <div>
                    <Typography>{firstRow}</Typography>
                    {secondRow && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="div"
                      >
                        {secondRow}
                      </Typography>
                    )}
                    {thirdRow && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="div"
                      >
                        {thirdRow}
                      </Typography>
                    )}
                  </div>
                }
              />
            ))}
          </FormGroup>
        </ScrollableContainer>
      )}
    </Box>
  )
}
