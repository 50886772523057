import {isEmpty, isUndefined, keyBy, omitBy} from 'lodash'
import React, {useEffect, useReducer} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ShowGenreCode,
  ShowTypeCode
} from '../../../../../../__generated__/schema'
import {GeneralFilter} from '../../../../../common/generalFilter'
import {ChildrenOnVisibleSection} from '../../../../../common/generalFilter/ChildrenOnVisibleSection'
import {IdsListSecondStep} from '../../../../../common/generalFilter/IdsListSecondStep'
import {IdsListSection} from '../../../../../common/generalFilter/IdsListSection'
import {
  GeneralFilterActions,
  generalFilterReducer,
  GeneralFilterState,
  getDefaultGeneralFilterState
} from '../../../../../common/generalFilter/reducer'
import {IFilterKeyOption} from '../../../../../common/generalFilter/types'
import {isObjectEmpty} from '../../utils'
import {useCostCenterListItems} from './costCenterListItems'
import {useDivisionListItems} from './divisionListItems'
import {useEventCategoryListItems} from './eventCategoryListItems'
import {useEventListItems} from './eventListItems'
import {useMarketingLabelListItems} from './marketingLabelListItems'
import {useShowGenreCodes} from './showGenreCodes'
import {useShowTypeCodes} from './showTypeCodes'
import {IEventsListBlockContentFilter, IEventsListBlockProps} from './types'
import {useVenueListItems} from './venueListItems'

interface IEventsListBlockContentProps {
  blockProps: IEventsListBlockProps
  onBlockPropsChange: (props: IEventsListBlockProps) => void
}

const useFilterKeyOptions =
  (): IFilterKeyOption<IEventsListBlockContentFilter>[] => {
    const {t} = useTranslation()
    return [
      {key: 'eventIds', name: t<string>('Events')},
      {key: 'divisionIds', name: t<string>('Divisions')},
      {key: 'venueIds', name: t<string>('Venues')},
      {key: 'eventCategoryIds', name: t<string>('Event categories')},
      {key: 'marketingLabelIds', name: t<string>('Marketing labels')},
      {key: 'costCenterIds', name: t<string>('Cost centers')},
      {key: 'showTypeCodes', name: t<string>('Show types')},
      {key: 'showGenreCodes', name: t<string>('Show genres')}
    ]
  }

const mapBlockPropsToFilter = (
  blockProps: IEventsListBlockProps
): IEventsListBlockContentFilter =>
  omitBy(
    {
      eventIds: blockProps.filter?.baseListFilter?.ids,
      divisionIds: blockProps.filter?.divisionIds,
      venueIds: blockProps.filter?.venueIds,
      eventCategoryIds: blockProps.filter?.eventCategoryIds,
      marketingLabelIds: blockProps.filter?.marketingLabelIds,
      costCenterIds: blockProps.filter?.costCenterIds,
      showTypeCodes: blockProps.filter?.showTypeCodes,
      showGenreCodes: blockProps.filter?.showGenreCodes
    },
    isUndefined
  )

const mapFilterToBlockProps = (
  filter: IEventsListBlockContentFilter
): IEventsListBlockProps => ({
  filter: {
    venueIds: filter?.venueIds ?? undefined,
    divisionIds: filter?.divisionIds ?? undefined,
    eventCategoryIds: filter?.eventCategoryIds ?? undefined,
    marketingLabelIds: filter?.marketingLabelIds ?? undefined,
    costCenterIds: filter?.costCenterIds ?? undefined,
    showTypeCodes: filter?.showTypeCodes ?? undefined,
    showGenreCodes: filter?.showGenreCodes ?? undefined,
    baseListFilter: isEmpty(filter?.eventIds)
      ? undefined
      : {
          ids: filter?.eventIds ?? undefined
        }
  }
})

export const EventsListBlockContent: React.FC<IEventsListBlockContentProps> = ({
  blockProps,
  onBlockPropsChange
}: IEventsListBlockContentProps) => {
  const filterKeyOptions = useFilterKeyOptions()
  const filterKeyOptionsByKey = keyBy(filterKeyOptions, 'key')
  const [generalFilter, dispatch] = useReducer<
    React.Reducer<
      GeneralFilterState<IEventsListBlockContentFilter>,
      GeneralFilterActions<IEventsListBlockContentFilter>
    >
  >(
    generalFilterReducer,
    getDefaultGeneralFilterState(mapBlockPropsToFilter(blockProps), [
      'eventIds',
      'venueIds',
      'divisionIds',
      'eventCategoryIds',
      'marketingLabelIds',
      'costCenterIds',
      'showTypeCodes',
      'showGenreCodes'
    ])
  )
  const {getEventListItems, getMoreEventListItems, getEventListItemsByIds} =
    useEventListItems()

  const {getDivisionListItems, getDivisionListItemsByIds} =
    useDivisionListItems()

  const {getVenueListItemsByIds, getVenueListItems} = useVenueListItems()
  const {getEventCategoryListItemsByIds, getEventCategoryListItems} =
    useEventCategoryListItems()
  const {getMarketingLabelListItemsByIds, getMarketingLabelListItems} =
    useMarketingLabelListItems()
  const {getCostCenterListItemsByIds, getCostCenterListItems} =
    useCostCenterListItems()
  const {getShowTypeCodeItems, getShowTypeCodeItemsByIds} = useShowTypeCodes()
  const {getShowGenreCodeItemsByIds, getShowGenreCodeItems} =
    useShowGenreCodes()

  useEffect(() => {
    const {filter} = mapFilterToBlockProps(generalFilter.filter)
    onBlockPropsChange({
      ...omitBy(blockProps, 'filter'),
      filter: isObjectEmpty(filter) ? undefined : filter
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalFilter.filter, onBlockPropsChange])
  return (
    <>
      <GeneralFilter<IEventsListBlockContentFilter>
        dispatch={dispatch}
        generalFilter={generalFilter}
        filterKeyOptions={filterKeyOptions}
        secondStepChildren={
          <>
            <IdsListSecondStep<IEventsListBlockContentFilter>
              filterKey="eventIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getEventListItems}
              getMoreOptions={getMoreEventListItems}
            />
            <IdsListSecondStep<IEventsListBlockContentFilter>
              filterKey="divisionIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getDivisionListItems}
              isFilterTextInputHidden
            />
            <IdsListSecondStep<IEventsListBlockContentFilter>
              filterKey="venueIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getVenueListItems}
            />
            <IdsListSecondStep<IEventsListBlockContentFilter>
              filterKey="eventCategoryIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getEventCategoryListItems}
            />
            <IdsListSecondStep<IEventsListBlockContentFilter>
              filterKey="marketingLabelIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getMarketingLabelListItems}
            />
            <IdsListSecondStep<IEventsListBlockContentFilter>
              filterKey="costCenterIds"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getCostCenterListItems}
            />
            <IdsListSecondStep<IEventsListBlockContentFilter, ShowTypeCode>
              filterKey="showTypeCodes"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getShowTypeCodeItems}
            />
            <IdsListSecondStep<IEventsListBlockContentFilter, ShowGenreCode>
              filterKey="showGenreCodes"
              generalFilter={generalFilter}
              dispatch={dispatch}
              getOptions={getShowGenreCodeItems}
            />
          </>
        }
      >
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="eventIds"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter>
            label={filterKeyOptionsByKey.eventIds.name}
            filterKey="eventIds"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getEventListItems}
            getMoreOptions={getMoreEventListItems}
            getOptionsByIds={getEventListItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="divisionIds"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter>
            label={filterKeyOptionsByKey.divisionIds.name}
            filterKey="divisionIds"
            isFilterTextInputHidden
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getDivisionListItems}
            getOptionsByIds={getDivisionListItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="venueIds"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter>
            label={filterKeyOptionsByKey.venueIds.name}
            filterKey="venueIds"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getVenueListItems}
            getOptionsByIds={getVenueListItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="eventCategoryIds"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter>
            label={filterKeyOptionsByKey.eventCategoryIds.name}
            filterKey="eventCategoryIds"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getEventCategoryListItems}
            getOptionsByIds={getEventCategoryListItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="marketingLabelIds"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter>
            label={filterKeyOptionsByKey.marketingLabelIds.name}
            filterKey="marketingLabelIds"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getMarketingLabelListItems}
            getOptionsByIds={getMarketingLabelListItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="costCenterIds"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter>
            label={filterKeyOptionsByKey.costCenterIds.name}
            filterKey="costCenterIds"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getCostCenterListItems}
            getOptionsByIds={getCostCenterListItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="showTypeCodes"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter, ShowTypeCode>
            label={filterKeyOptionsByKey.showTypeCodes.name}
            filterKey="showTypeCodes"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getShowTypeCodeItems}
            getOptionsByIds={getShowTypeCodeItemsByIds}
          />
        </ChildrenOnVisibleSection>
        <ChildrenOnVisibleSection<IEventsListBlockContentFilter>
          filterKey="showGenreCodes"
          visibleSections={generalFilter.visibleSections}
        >
          <IdsListSection<IEventsListBlockContentFilter, ShowGenreCode>
            label={filterKeyOptionsByKey.showGenreCodes.name}
            filterKey="showGenreCodes"
            generalFilter={generalFilter}
            dispatch={dispatch}
            getOptions={getShowGenreCodeItems}
            getOptionsByIds={getShowGenreCodeItemsByIds}
          />
        </ChildrenOnVisibleSection>
      </GeneralFilter>
    </>
  )
}
