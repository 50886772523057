import GetAppIcon from '@mui/icons-material/GetApp'
import {Box, Button} from '@mui/material'
import {TFunction} from 'i18next'
import {noop} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientVatRegistered,
  PaymentReportQuery,
  PaymentReportType,
  PermissionCode,
  TemplatesQuery
} from '../../../../../__generated__/schema'
import {useEnsurePermissions, useUserInfo} from '../../../../../utils/auth'
import {performCompiledPaymentReportAction} from '../../../../../utils/compiledTemplates'
import {usePaymentReportParams} from '../../../../../utils/pathname'
import {RenderOnData, SingleSideNavigationList} from '../../../../common'
import {MenuItem} from '../../../../common/Menu'
import {
  Footer,
  PageWithHeaderAndFooterTemplate
} from '../../../../common/PageWithHeaderAndFooterTemplate'
import {DivisionsSummary} from '../../../../common/paymentReportDrawer/DivisionsSummary'
import {General} from '../../../../common/paymentReportDrawer/General'
import {PaymentsCount} from '../../../../common/paymentReportDrawer/PaymentsCount'
import {PaymentsSummary} from '../../../../common/paymentReportDrawer/PaymentsSummary'
import {TransactionTypes} from '../../../../common/paymentReportDrawer/TransactionTypes'
import {VatSummary} from '../../../../common/paymentReportDrawer/VatSummary'
import {SplitButton} from '../../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {SecondaryHeader} from '../../Header'
import {CenteredLayout} from '../../Layout'
import {usePaymentReport} from '../graphql'
import {useGetPaymentReportTemplates} from '../utils'

const useNavigationItems = ({
  isVatSectionVisible,
  isDivisionsSummarySectionVisible
}: {
  isVatSectionVisible: boolean
  isDivisionsSummarySectionVisible: boolean
}) => {
  const {t} = useTranslation()
  return useMemo(
    () => ({
      general: {
        id: 'general',
        label: t('General')
      },
      paymentsSummary: {
        id: 'paymentsSummary',
        label: t('Payments summary')
      },
      paymentsCount: {
        id: 'paymentsCount',
        label: t('Payments count')
      },
      transactionTypes: {
        id: 'transactionTypes',
        label: t('Transaction types')
      },
      ...(isVatSectionVisible
        ? {
            vatSummary: {
              id: 'vatSummary',
              label: t('VAT summary')
            }
          }
        : {}),
      ...(isDivisionsSummarySectionVisible
        ? {
            divisionsSummary: {
              id: 'divisionsSummary',
              label: t('Divisions summary')
            }
          }
        : {})
    }),
    [t, isVatSectionVisible, isDivisionsSummarySectionVisible]
  )
}

const getTitle = ({
  type,
  id,
  t
}: {
  type: PaymentReportType
  id: number
  t: TFunction
}) => {
  switch (type) {
    case PaymentReportType.DailyCashierReport:
      return t('Daily cashier report #{{id}}', {id})
    case PaymentReportType.MonthlyECommerceReport:
      return t('Monthly ecommerce report #{{id}}', {id})
    case PaymentReportType.MonthlyReport:
      return t('Monthly report #{{id}}', {id})
    default:
      return ''
  }
}

interface IPaymentReportDetailProps {
  onExited: () => void
}

export const PaymentReportDetail: React.FC<IPaymentReportDetailProps> = ({
  onExited
}: IPaymentReportDetailProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {paymentReportId} = usePaymentReportParams()
  const {effectiveClient} = useUserInfo()
  const {data, loading, error} = usePaymentReport(paymentReportId)
  const templates = useGetPaymentReportTemplates(data?.paymentReport.type)
  const [title, setTitle] = useState<string>(
    t('Payment report #{{id}}', {id: paymentReportId})
  )
  useEffect(() => {
    if (data) {
      setTitle(
        getTitle({type: data.paymentReport.type, id: data.paymentReport.id, t})
      )
    }
  }, [data, t])
  const isVatSectionVisible =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None &&
    (data?.paymentReport.dataAggregatedByVatRate || []).length > 0
  const isDivisionsSummarySectionVisible =
    (data?.paymentReport.dataAggregatedByDivision?.items || []).length > 0
  const navigationItems = useNavigationItems({
    isVatSectionVisible,
    isDivisionsSummarySectionVisible
  })
  const getTemplateButtonClickHandler = useCallback(
    (template: TemplatesQuery['templates'][number]) => () =>
      data &&
      performCompiledPaymentReportAction({
        templateFileType: template.fileType,
        templateId: template.id,
        paymentReportId: data.paymentReport.id
      }),
    [data]
  )
  return (
    <PageWithHeaderAndFooterTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={onExited}
        />
      }
      footer={
        <Footer>
          {data?.paymentReport.externalFileUrl && (
            <Button
              variant="text"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() =>
                window.open(data.paymentReport.externalFileUrl, '_blank')
              }
            >
              {t('Download')}
            </Button>
          )}
          {P([PermissionCode.ReadTemplates]) && (
            <SplitButton
              Options={
                templates.length
                  ? templates.map((template) => (
                      <MenuItem
                        key={template.id}
                        label={template.name}
                        onClick={getTemplateButtonClickHandler(template)}
                      />
                    ))
                  : [
                      <MenuItem
                        key="no-templates"
                        label={t('No templates have been found')}
                        isDisabled
                      />
                    ]
              }
            >
              <Button
                variant="contained"
                color="primary"
                onClick={
                  templates[0]
                    ? getTemplateButtonClickHandler(templates[0])
                    : noop
                }
              >
                {t('Print')}
              </Button>
            </SplitButton>
          )}
        </Footer>
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <RenderOnData<PaymentReportQuery>
          data={data}
          loading={loading}
          error={error}
          errorMessage={t<string>('Error while loading payment report')}
          ignoreLoadingIfData
          dataCondition={(data) => Boolean(data.paymentReport)}
        >
          {({paymentReport}) => (
            <CenteredLayout>
              <SingleSideNavigationList items={navigationItems} />
              <Box
                sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 5}}
              >
                <General
                  id={navigationItems.general.id}
                  paymentReport={paymentReport}
                />
                <PaymentsSummary
                  dataAggregatedByPaymentMethodAndTransactionType={
                    paymentReport.dataAggregatedByPaymentMethodAndTransactionType
                  }
                  id={navigationItems.paymentsSummary.id}
                  label={navigationItems.paymentsSummary.label}
                />
                <PaymentsCount
                  dataAggregatedByPaymentMethodAndTransactionType={
                    paymentReport.dataAggregatedByPaymentMethodAndTransactionType
                  }
                  id={navigationItems.paymentsCount.id}
                  label={navigationItems.paymentsCount.label}
                />
                <TransactionTypes
                  paymentReport={paymentReport}
                  id={navigationItems.transactionTypes.id}
                  label={navigationItems.transactionTypes.label}
                />
                {navigationItems.vatSummary && (
                  <VatSummary
                    paymentReport={paymentReport}
                    id={navigationItems.vatSummary.id}
                    label={navigationItems.vatSummary.label}
                  />
                )}
                {navigationItems.divisionsSummary && (
                  <DivisionsSummary
                    id={navigationItems.divisionsSummary.id}
                    label={navigationItems.divisionsSummary.label}
                    dataAggregatedByDivision={
                      paymentReport.dataAggregatedByDivision
                    }
                  />
                )}
              </Box>
            </CenteredLayout>
          )}
        </RenderOnData>
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderAndFooterTemplate>
  )
}
