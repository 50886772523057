import {Box, Button, Divider, Grid, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  DetailEventPropertiesFragment,
  PermissionCode
} from '../../../../../../../__generated__/schema'
import {useTranslateAgeClassification} from '../../../../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormat,
  useTranslateShowSoundMix,
  useTranslateShowVersion
} from '../../../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../../../hooks/useGetUserLocaleTranslation'

import {Theme} from '../../../../../../../theme'
import {useEnsurePermissions} from '../../../../../../../utils/auth'
import {
  useDateTimeFormatters,
  useShowDurationFormatters
} from '../../../../../../../utils/formatting'
import {useEventsPathnameParams} from '../../../../../../../utils/pathname'
import {routeTo} from '../../../../../../../utils/routes'

import {EventAddress, StateRenderer} from '../../common'

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    padding: theme.spacing(2)
  },
  leftPanelContentWrapper: {
    paddingRight: theme.spacing(2),
    height: '100%'
  },
  leftRightPanelWrapper: {
    paddingTop: theme.spacing(1)
  }
}))

const ValueLabel = ({children}: {children: React.ReactNode}) => {
  return <Typography variant="caption">{children}</Typography>
}

const KeyLabel = ({children}: {children: React.ReactNode}) => {
  return (
    <Typography variant="caption">
      <Box fontWeight="fontWeightBold">
        {children}
        &nbsp;
      </Box>
    </Typography>
  )
}

interface IGeneralEventInfoCardProps {
  data: DetailEventPropertiesFragment
}

const RightSideInfo: React.FC<IGeneralEventInfoCardProps> = ({
  data
}: IGeneralEventInfoCardProps) => {
  const {venue, auditorium} = data

  return <EventAddress {...{venue, auditorium}} />
}

const LeftSideInfo: React.FC<IGeneralEventInfoCardProps> = ({
  data
}: IGeneralEventInfoCardProps) => {
  const {t} = useTranslation()
  const {formatDateTime} = useDateTimeFormatters()
  const {durationToHoursMinutesLongFormat, durationToMinutesFormat} =
    useShowDurationFormatters()

  const {startsAt, duration, serviceTime, endsAt} = data
  return (
    <Grid item>
      <Grid container>
        <KeyLabel>{t('Starts:')}</KeyLabel>
        <ValueLabel>{formatDateTime(new Date(startsAt))}</ValueLabel>
      </Grid>
      <Grid container>
        <KeyLabel>{t('Ends:')}</KeyLabel>
        <ValueLabel>{formatDateTime(new Date(endsAt))}</ValueLabel>
      </Grid>
      <Grid container>
        <KeyLabel>{t('Duration:')}</KeyLabel>
        <ValueLabel>
          {`${durationToMinutesFormat(
            duration
          )} (${durationToHoursMinutesLongFormat(duration)})`}
        </ValueLabel>{' '}
      </Grid>
      <Grid container>
        <KeyLabel>{t('Service time:')}</KeyLabel>
        <ValueLabel>{durationToMinutesFormat(serviceTime)}</ValueLabel>{' '}
      </Grid>
    </Grid>
  )
}

const useGetOnEdit = () => {
  const history = useHistory()
  const {eventId} = useEventsPathnameParams()
  return () => history.push(routeTo.admin.events.editGeneralOptions(eventId))
}

export const GeneralEventInfoCard: React.FC<IGeneralEventInfoCardProps> = ({
  data
}: IGeneralEventInfoCardProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {P} = useEnsurePermissions()
  const onEdit = useGetOnEdit()
  const {
    names,
    state,
    formatCode,
    versionCode,
    soundMixCode,
    ageClassificationCode
  } = data

  const translateShowFormat = useTranslateShowFormat()
  const translateShowVersion = useTranslateShowVersion()
  const translateShowSoundMix = useTranslateShowSoundMix()
  const translateAgeClassification = useTranslateAgeClassification()

  return (
    <Paper className={classes.wrapper}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography component="div">
          <Box fontWeight="fontWeightBold">
            {getUserLocaleTranslation(names)}
          </Box>
        </Typography>
        {P([PermissionCode.UpdateGeneralEventData]) && (
          <Button color="primary" onClick={onEdit}>
            {t('Edit')}
          </Button>
        )}
      </Grid>

      <StateRenderer state={state} />

      <ValueLabel>
        {[
          formatCode && translateShowFormat(formatCode),
          versionCode && translateShowVersion(versionCode),
          soundMixCode && translateShowSoundMix(soundMixCode),
          ageClassificationCode &&
            translateAgeClassification(ageClassificationCode)
        ]
          .filter(Boolean)
          .join(' | ')}
      </ValueLabel>

      <Grid container className={classes.leftRightPanelWrapper}>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.leftPanelContentWrapper}
          >
            <LeftSideInfo data={data} />
            <Divider orientation="vertical" />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <RightSideInfo data={data} />
        </Grid>
      </Grid>
    </Paper>
  )
}
