import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {AuditoriumLayoutPricingPropertiesFragment} from '../../../../../../__generated__/schema'

import {useSelector} from '../../../../../../editor/redux'
import {pricingSelector} from '../../../../../../editor/redux/pricing/selectors'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useVenuesPathnameParams} from '../../../../../../utils/pathname'
import {routeTo} from '../../../../../../utils/routes'
import {IEditorFormData} from '../EditorForm'
import {
  useActivateLayoutPricing,
  useArchiveLayoutPricing,
  useDeleteLayoutPricing,
  useUpdateLayoutPricing
} from '../graphql'
import {editorLayoutPricingToBackendLayoutPricing} from './utils'

export const useOnSubmitActions = (
  pricing: AuditoriumLayoutPricingPropertiesFragment
) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {venueId, auditoriumId, auditoriumLayoutId, layoutPricingId} =
    useVenuesPathnameParams()
  const pricingLayout = useSelector(pricingSelector)

  const updateLayoutPricing = useUpdateLayoutPricing()
  const deleteLayoutPricing = useDeleteLayoutPricing(auditoriumLayoutId)
  const activateLayoutPricing = useActivateLayoutPricing()
  const archiveLayoutPricing = useArchiveLayoutPricing()

  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()

  const errorMsg = t('Error while saving layout pricing')

  const _onSubmit = useCallback(
    async (data: IEditorFormData) => {
      await updateLayoutPricing(pricing.id, {
        name: data.name,
        pricing: editorLayoutPricingToBackendLayoutPricing(pricingLayout),
        maxTicketsPerOrder: parseInt(data.maxTicketsPerOrder, 10),
        maxNumberOfOccupiedSeatsOnRetail: data.maxNumberOfOccupiedSeatsOnRetail,
        maxNumberOfOccupiedSeatsOnEcommerce:
          data.maxNumberOfOccupiedSeatsOnEcommerce
      })
    },
    [pricing.id, pricingLayout, updateLayoutPricing]
  )

  const onSubmit = useCallback(
    async (data: IEditorFormData) => {
      try {
        setShowBackdrop(true)
        await _onSubmit(data)
        addInfoNotification(t('All changes were saved'))
      } catch (err) {
        defaultErrorHandler(err, errorMsg)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _onSubmit,
      addInfoNotification,
      defaultErrorHandler,
      errorMsg,
      setShowBackdrop,
      t
    ]
  )

  const onDelete = useCallback(async () => {
    try {
      await deleteLayoutPricing(pricing.id)
      addInfoNotification(t('Layout pricing was deleted'))
      history.push(
        routeTo.admin.venues.detailAuditoriumLayout(
          venueId,
          auditoriumId,
          auditoriumLayoutId
        )
      )
    } catch (err) {
      defaultErrorHandler(err, t('Error while deleting layout pricing'))
    }
  }, [
    addInfoNotification,
    auditoriumId,
    auditoriumLayoutId,
    defaultErrorHandler,
    deleteLayoutPricing,
    history,
    pricing.id,
    t,
    venueId
  ])

  const onActivate = useCallback(
    async (data: IEditorFormData) => {
      try {
        setShowBackdrop(true)
        await _onSubmit(data)
        await activateLayoutPricing(layoutPricingId)
        addInfoNotification(t('Layout pricing was saved.'))
      } catch (err) {
        defaultErrorHandler(err, errorMsg)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _onSubmit,
      activateLayoutPricing,
      addInfoNotification,
      defaultErrorHandler,
      errorMsg,
      layoutPricingId,
      setShowBackdrop,
      t
    ]
  )

  const onArchive = useCallback(
    async (data: IEditorFormData) => {
      try {
        setShowBackdrop(true)
        await _onSubmit(data)
        await archiveLayoutPricing(layoutPricingId)
        addInfoNotification(t('Layout pricing was saved.'))
      } catch (err) {
        defaultErrorHandler(err, errorMsg)
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      _onSubmit,
      addInfoNotification,
      archiveLayoutPricing,
      defaultErrorHandler,
      errorMsg,
      layoutPricingId,
      setShowBackdrop,
      t
    ]
  )

  return {onSubmit, onDelete, onActivate, onArchive}
}
