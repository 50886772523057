import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import {Button, ButtonBase, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {CodeFormulaErrorMessage} from './types'
import {useTranslateCodeFormulaErrorMessages, validatePluCode} from './utils'

const useDialButtonStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: 48,
    height: 48,
    backgroundColor: theme.palette.divider,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

interface IDialButtonProps {
  children: React.ReactNode
  onClick: () => void
}

const DialButton: React.FC<IDialButtonProps> = ({
  children,
  onClick
}: IDialButtonProps) => {
  const classes = useDialButtonStyles()
  return (
    <ButtonBase component="div" onClick={onClick} className={classes.root}>
      {children}
    </ButtonBase>
  )
}

const useStyles = makeStyles<Theme, {hasError: boolean}>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },
  paper: {
    width: 256,
    height: ({hasError}) => (hasError ? 424 : 376),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  dial: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3)
  },
  button: {
    borderRadius: theme.spacing(3),
    height: 48
  }
}))

interface IInputViewProps {
  pluCode: string
  setPluCode: (code: string) => void
  pluCodeError: CodeFormulaErrorMessage | null
  setPluCodeError: (error: CodeFormulaErrorMessage | null) => void
  onProductItemQuantityIncrementWithPluCode: () => void
}

export const InputView: React.FC<IInputViewProps> = ({
  pluCode,
  setPluCode,
  pluCodeError,
  setPluCodeError,
  onProductItemQuantityIncrementWithPluCode
}: IInputViewProps) => {
  const translateCodeFormulaErrorMessages =
    useTranslateCodeFormulaErrorMessages()
  const handleDialButtonClick = useCallback(
    (value: string) => () => {
      const newPluCode = pluCode + value
      validatePluCode(newPluCode, setPluCodeError)
      setPluCode(newPluCode)
    },
    [pluCode, setPluCode, setPluCodeError]
  )
  const handleRemoveLastCharacter = useCallback(() => {
    const newPluCode = pluCode.substring(0, pluCode.length - 1)
    validatePluCode(newPluCode, setPluCodeError)
    setPluCode(newPluCode)
  }, [pluCode, setPluCode, setPluCodeError])
  const handleSubmitButtonClick = useCallback(() => {
    onProductItemQuantityIncrementWithPluCode()
  }, [onProductItemQuantityIncrementWithPluCode])
  const classes = useStyles({hasError: !!pluCodeError})
  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <div className={classes.dial}>
          <DialButton onClick={handleDialButtonClick('7')}>
            <Typography variant="subtitle1">7</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('8')}>
            <Typography variant="subtitle1">8</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('9')}>
            <Typography variant="subtitle1">9</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('4')}>
            <Typography variant="subtitle1">4</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('5')}>
            <Typography variant="subtitle1">5</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('6')}>
            <Typography variant="subtitle1">6</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('1')}>
            <Typography variant="subtitle1">1</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('2')}>
            <Typography variant="subtitle1">2</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('3')}>
            <Typography variant="subtitle1">3</Typography>
          </DialButton>
          <DialButton onClick={handleRemoveLastCharacter}>
            <BackspaceOutlinedIcon />
          </DialButton>
          <DialButton onClick={handleDialButtonClick('0')}>
            <Typography variant="subtitle1">0</Typography>
          </DialButton>
          <DialButton onClick={handleDialButtonClick('*')}>
            <Typography variant="subtitle1">*</Typography>
          </DialButton>
        </div>
        {pluCodeError && (
          <Typography variant="caption" color="error" align="center">
            {translateCodeFormulaErrorMessages(pluCodeError)}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={!!pluCodeError || !pluCode}
          className={classes.button}
          onClick={handleSubmitButtonClick}
        >
          <DoneOutlinedIcon />
        </Button>
      </Paper>
    </div>
  )
}
