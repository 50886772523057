import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
  EditTemplateVersionCodeMutation,
  EditTemplateVersionCodeMutationVariables,
  TemplateQuery,
  TemplateQueryVariables,
  TemplatesFilterInput,
  TemplatesQuery,
  TemplatesQueryVariables,
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
} from '../../../../__generated__/schema'

const GET_TEMPLATES = gql`
  query templates($filter: TemplatesFilterInput) {
    templates(filter: $filter) {
      id
      name
      state
      fileType
      versionsCount
      clientsCount
      localeCode
      dataSetType
      createdAt
      updatedAt
      description
    }
  }
`

const GET_TEMPLATE = gql`
  query template($id: PositiveInt!) {
    template(id: $id) {
      id
      name
      description
      types
      localeCode
      fileType
      dataSetType
      createdAt
      createdByName
      updatedAt
      updatedByName
      clientsCount
      additionalSearchParams
      state
      activeVersion {
        id
        name
        state
        code
      }
    }
  }
`

const EDIT_TEMPLATE_VERSION_CODE = gql`
  mutation editTemplateVersionCode($id: PositiveInt!, $code: String!) {
    editTemplateVersionCode(id: $id, code: $code) {
      id
      code
    }
  }
`

const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      id
    }
  }
`

const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($id: PositiveInt!, $input: UpdateTemplateInput!) {
    updateTemplate(id: $id, input: $input) {
      id
    }
  }
`

export const useGetTemplates = (
  filter?: TemplatesFilterInput,
  skip?: boolean
) =>
  useQuery<TemplatesQuery, TemplatesQueryVariables>(GET_TEMPLATES, {
    variables: {filter},
    fetchPolicy: 'network-only',
    skip
  })

export const useGetTemplate = (id: number) =>
  useQuery<TemplateQuery, TemplateQueryVariables>(GET_TEMPLATE, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

export const useEditTemplateVersionCode = () => {
  const [editTemplateVersionCode] = useMutation<
    EditTemplateVersionCodeMutation,
    EditTemplateVersionCodeMutationVariables
  >(EDIT_TEMPLATE_VERSION_CODE)
  return (variables: EditTemplateVersionCodeMutationVariables) =>
    editTemplateVersionCode({
      variables
    })
}

export const useCreateTemplate = () => {
  const [createTemplate] =
    useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(
      CREATE_TEMPLATE
    )
  return (variables: CreateTemplateMutationVariables) =>
    createTemplate({
      variables
    })
}

export const useUpdateTemplate = () => {
  const [updateTemplate] =
    useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(
      UPDATE_TEMPLATE
    )
  return (variables: UpdateTemplateMutationVariables) =>
    updateTemplate({
      variables,
      refetchQueries: [{query: GET_TEMPLATE, variables: {id: variables.id}}]
    })
}
