import {
  NarrowDivisionPropertiesFragment,
  TemplatePropertiesFragment,
  TemplateType
} from '../../../../__generated__/schema'
import {DeviceServices} from '../../../../hooks/deviceServices'
import {
  CheckoutPaymentMethodsViewMode,
  ICashDrawerSettings,
  ICustomerDisplaySettings,
  IDirectTicketPrintSettings,
  IPosTerminalSettings,
  RegistrationOfSales,
  ShopViewMode,
  TourTimeSlotViewMode,
  ZonePlanView
} from '../../../../types'

export type DeviceSettingsState = {
  assignDialogOpenedForType: TemplateType | null
  availableTemplates: TemplatePropertiesFragment[]
  assignedTemplatesByType: {
    [type: string]: TemplatePropertiesFragment[]
  }
  zonePlanView: ZonePlanView
  shopViewMode: ShopViewMode
  tourTimeSlotViewMode: TourTimeSlotViewMode
  checkoutPaymentMethodsViewMode: CheckoutPaymentMethodsViewMode
  registrationOfSales: RegistrationOfSales
  deviceServices: DeviceServices[]
  divisionId: number | null
  enabledDivisions: NarrowDivisionPropertiesFragment[]
  cashDrawer: ICashDrawerSettings
  posTerminal: IPosTerminalSettings
  warehouseId: number | null
  customerDisplay: ICustomerDisplaySettings
  directTicketPrint: IDirectTicketPrintSettings
}

export enum DeviceSettingsActionTypes {
  OpenAssignDialog = 'openAssignDialog',
  CloseAssignDialog = 'closeAssignDialog',
  AssignTemplate = 'assignTemplate',
  RemoveTemplate = 'removeTemplate',
  ReorderTemplates = 'reorderTemplates',
  ChangeZonePlanView = 'changeZonePlanView',
  ChangeShopViewMode = 'changeShopViewMode',
  ChangeTourTimeSlotViewMode = 'changeTourTimeSlotViewMode',
  ChangeCheckoutPaymentMethodsViewMode = 'changeCheckoutPaymentMethodsViewMode',
  ChangeRegistrationOfSales = 'changeRegistrationOfSales',
  ChangeDeviceServices = 'changeDeviceServices',
  ChangeDivision = 'changeDivision',
  AddEnabledDivisions = 'addEnabledDivisions',
  RemoveEnabledDivision = 'removeEnabledDivision',
  ResetEnabledDivisions = 'resetEnabledDivisions',
  ChangeCashDrawerSettings = 'changeCashDrawer',
  ChangePosTerminalSettings = 'changePosTerminalSettings',
  ChangeWarehouseId = 'changeWarehouseId',
  ChangeCustomerDisplaySettings = 'changeCustomerDisplaySettings',
  ChangeDirectTicketPrintSettings = 'changeDirectTicketPrintSettings'
}

export type OpenAssignDialogAction = {
  type: DeviceSettingsActionTypes.OpenAssignDialog
  payload: {
    templateType: TemplateType
    availableTemplates: TemplatePropertiesFragment[]
  }
}
export type CloseAssignDialogAction = {
  type: DeviceSettingsActionTypes.CloseAssignDialog
}

export type AssignTemplateAction = {
  type: DeviceSettingsActionTypes.AssignTemplate
  payload: TemplatePropertiesFragment
}
export type RemoveTemplateAction = {
  type: DeviceSettingsActionTypes.RemoveTemplate
  payload: {
    id: number
    type: TemplateType
  }
}

export type ReorderTemplatesAction = {
  type: DeviceSettingsActionTypes.ReorderTemplates
  payload: {
    type: TemplateType
    templates: TemplatePropertiesFragment[]
  }
}

export type ChangeZonePlanViewAction = {
  type: DeviceSettingsActionTypes.ChangeZonePlanView
  payload: ZonePlanView
}

export type ChangeShopViewModeAction = {
  type: DeviceSettingsActionTypes.ChangeShopViewMode
  payload: ShopViewMode
}

export type ChangeTourTimeSlotViewModeAction = {
  type: DeviceSettingsActionTypes.ChangeTourTimeSlotViewMode
  payload: TourTimeSlotViewMode
}

export type ChangeCheckoutPaymentMethodsViewModeAction = {
  type: DeviceSettingsActionTypes.ChangeCheckoutPaymentMethodsViewMode
  payload: CheckoutPaymentMethodsViewMode
}

export type ChangeRegistrationOfSalesAction = {
  type: DeviceSettingsActionTypes.ChangeRegistrationOfSales
  payload: RegistrationOfSales
}
export type ChangeDeviceServicesAction = {
  type: DeviceSettingsActionTypes.ChangeDeviceServices
  payload: DeviceServices[]
}
export type ChangeDivision = {
  type: DeviceSettingsActionTypes.ChangeDivision
  payload: number | null
}

export type AddEnabledDivisions = {
  type: DeviceSettingsActionTypes.AddEnabledDivisions
  payload: NarrowDivisionPropertiesFragment[]
}

export type RemovedEnabledDivision = {
  type: DeviceSettingsActionTypes.RemoveEnabledDivision
  payload: NarrowDivisionPropertiesFragment
}

export type ResetEnabledDivisions = {
  type: DeviceSettingsActionTypes.ResetEnabledDivisions
  payload: NarrowDivisionPropertiesFragment[]
}

export type ChangeCashDrawerSettings = {
  type: DeviceSettingsActionTypes.ChangeCashDrawerSettings
  payload: ICashDrawerSettings
}

export type ChangePosTerminalSettings = {
  type: DeviceSettingsActionTypes.ChangePosTerminalSettings
  payload: IPosTerminalSettings
}

export type ChangeWarehouseId = {
  type: DeviceSettingsActionTypes.ChangeWarehouseId
  payload: number | null
}

export type ChangeCustomerDisplaySettings = {
  type: DeviceSettingsActionTypes.ChangeCustomerDisplaySettings
  payload: ICustomerDisplaySettings
}

export type ChangeDirectTicketPrintSettings = {
  type: DeviceSettingsActionTypes.ChangeDirectTicketPrintSettings
  payload: IDirectTicketPrintSettings
}

export type DeviceSettingsAction =
  | OpenAssignDialogAction
  | CloseAssignDialogAction
  | AssignTemplateAction
  | RemoveTemplateAction
  | ReorderTemplatesAction
  | ChangeZonePlanViewAction
  | ChangeShopViewModeAction
  | ChangeTourTimeSlotViewModeAction
  | ChangeCheckoutPaymentMethodsViewModeAction
  | ChangeDeviceServicesAction
  | ChangeRegistrationOfSalesAction
  | ChangeDivision
  | AddEnabledDivisions
  | RemovedEnabledDivision
  | ResetEnabledDivisions
  | ChangeCashDrawerSettings
  | ChangePosTerminalSettings
  | ChangeWarehouseId
  | ChangeCustomerDisplaySettings
  | ChangeDirectTicketPrintSettings

export enum PosSettingsField {
  ProtocolType = 'protocolType',
  TerminalId = 'terminalId',
  IpAddress = 'ipAddress',
  Port = 'port',
  Timeout = 'timeout'
}

export interface IPosSettingsForm {
  [PosSettingsField.ProtocolType]: string
  [PosSettingsField.TerminalId]?: string
  [PosSettingsField.IpAddress]: string
  [PosSettingsField.Port]: string
  [PosSettingsField.Timeout]: string
}

export enum SelectMonitorFormField {
  Monitor = 'monitor'
}

export interface ISelectMonitorForm {
  [SelectMonitorFormField.Monitor]: string
}
