import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../theme'

export enum DotColor {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

const useStyles = makeStyles<Theme, {dotColor: DotColor}>((theme) => ({
  dotColor: {
    backgroundColor: ({dotColor}) => {
      switch (dotColor) {
        case DotColor.Success:
          return theme.palette.success.main
        case DotColor.Error:
          return theme.palette.error.main
        case DotColor.Warning:
          return theme.palette.warning.main
        default:
          return theme.palette.grey.A700
      }
    }
  }
}))

interface IActivityLogTimelineItemProps {
  title: string
  dotColor: DotColor
  description?: string
  hasConnector?: boolean
}

export const ActivityLogTimelineItem: React.FC<IActivityLogTimelineItemProps> =
  ({
    title,
    description,
    dotColor,
    hasConnector = true
  }: IActivityLogTimelineItemProps) => {
    const classes = useStyles({dotColor})
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot className={classes.dotColor} />
          {hasConnector && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="body2">{title}</Typography>
          {description && (
            <Typography variant="caption" color="textSecondary">
              {description}
            </Typography>
          )}
        </TimelineContent>
      </TimelineItem>
    )
  }
