import {FormControl, FormLabel, RadioGroup} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {FieldErrors, FormContextValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {DiscountApplication} from '../../../../__generated__/schema'
import {Theme} from '../../../../theme'
import {FormValidationError} from '../../../common'
import {RadioGroupItem} from '../../../common/RadioGroupItem'
import {useHasError} from '../../../form/utils'

import {DiscountFormField, IDiscountForm} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  hidden: {
    display: 'none'
  },
  radioGroup: {
    paddingTop: theme.spacing(1)
  },
  formControl: {
    width: '100%'
  }
}))

interface IApplicationRadioGroupProps {
  register: FormContextValues<IDiscountForm>['register']
  setValue: FormContextValues<IDiscountForm>['setValue']
  watch: FormContextValues<IDiscountForm>['watch']
  errors: FieldErrors<IDiscountForm>
  defaultValue?: DiscountApplication
}

export const ApplicationRadioGroup: React.FC<IApplicationRadioGroupProps> = ({
  register,
  setValue,
  defaultValue,
  errors,
  watch
}: IApplicationRadioGroupProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const hasError = useHasError<IDiscountForm>(
    errors,
    DiscountFormField.APPLICATION
  )
  const onRadioGroupChange = useCallback(
    (_event, value) => {
      setValue(DiscountFormField.APPLICATION, value, hasError)
    },
    [hasError, setValue]
  )
  const options = [
    {
      label: t('Selectable discount'),
      subLabel: t(
        'Cashier or Customer has to manually choose discount from list.'
      ),
      value: DiscountApplication.Selectable
    },
    {
      label: t('Discount code'),
      subLabel: t('Cashier or Customer has to enter discount code.'),
      value: DiscountApplication.Code
    },
    {
      label: t('Registered customer'),
      subLabel: t('Discount will be available only for registered customers.'),
      value: DiscountApplication.Customer
    }
  ]
  const value = watch(DiscountFormField.APPLICATION)
  return (
    <FormControl error={hasError} className={classes.formControl}>
      <FormLabel>{t('Application')}</FormLabel>
      <select
        name={DiscountFormField.APPLICATION}
        defaultValue={defaultValue}
        ref={register({required: true})}
        className={classes.hidden}
      >
        <option />
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FormValidationError<IDiscountForm>
        errors={errors}
        fieldName={DiscountFormField.APPLICATION}
      />
      <RadioGroup
        onChange={onRadioGroupChange}
        defaultValue={defaultValue}
        classes={{root: classes.radioGroup}}
      >
        {options.map((option) => (
          <RadioGroupItem
            key={option.value}
            {...option}
            selectedValue={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
