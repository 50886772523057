import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {Box, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  ClientVatRegistered,
  PermissionCode,
  WarehouseDocumentsFilterInput,
  WarehouseDocumentState,
  WarehouseDocumentType
} from '../../../../__generated__/schema'
import {useTranslateWarehouseDocumentSource} from '../../../../hooks/translateWarehouseDocumentSource'
import {useTranslateWarehouseDocumentState} from '../../../../hooks/translateWarehouseDocumentState'
import {useTranslateWarehouseDocumentType} from '../../../../hooks/translateWarehouseDocumentType'
import {useEnsurePermissions, useUserInfo} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip} from '../../../common'
import {
  DataGridTable,
  useDataGridPagination,
  useDateNumericFormatter,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useUserNameFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {OptionsChipWithDelete} from '../../../common/OptionsChipWithDelete'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {warehouseDocumentStateColors} from '../../../constants'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {DivisionChip} from '../components/DivisionChip'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {WarehousesChip} from '../components/WarehousesChip'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {useWarehouseDocuments} from './graphql'

const StateRenderer = ({state}: {state: WarehouseDocumentState}) => {
  const translateWarehouseDocumentState = useTranslateWarehouseDocumentState()
  return (
    <EntityStateChip
      colorConf={warehouseDocumentStateColors[state]}
      label={translateWarehouseDocumentState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.warehouseDocuments.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IWarehouseDocumentsListProps {
  searchFilter: WarehouseDocumentsFilterInput
}

export const WarehouseDocumentsList: React.FC<IWarehouseDocumentsListProps> = ({
  searchFilter
}: IWarehouseDocumentsListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [state, setState] =
    useState<WarehouseDocumentState | undefined>(undefined)
  const [type, setType] = useState<WarehouseDocumentType | undefined>(undefined)
  const [divisionId, setDivisionId] = useState<number | null>(null)
  const [warehouseId, setWarehouseId] = useState<number | undefined>(undefined)
  const [selectedDate, setSelectedDate] =
    useState<IDataPickerData | undefined>(undefined)
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination()
  const getFilterDateRange = useGetFilterDateRange(true)
  const {data, loading, error, refetch} = useWarehouseDocuments({
    paginationInput,
    filter: {
      ...searchFilter,
      state,
      type,
      divisionIds: divisionId ? [divisionId] : undefined,
      warehouseIds: warehouseId ? [warehouseId] : undefined,
      issuedByIds: selectedUserId ? [selectedUserId] : undefined,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'deliveryDateFrom',
        filterNameTo: 'deliveryDateTo'
      })
    }
  })
  const {effectiveClient} = useUserInfo()
  const dateTimeFormatter = useDateTimeFormatter()
  const dateNumericFormatter = useDateNumericFormatter()
  const translateWarehouseDocumentType = useTranslateWarehouseDocumentType()
  const translateWarehouseDocumentSource = useTranslateWarehouseDocumentSource()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const userNameFormatter = useUserNameFormatter()
  const translateWarehouseDocumentState = useTranslateWarehouseDocumentState()
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleUserChange = useCallback(
    (userId: number | null) => {
      setSelectedUserId(userId)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const isVatRegistered =
    effectiveClient?.VATRegistered !== ClientVatRegistered.None
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Delivery date'),
        field: 'deliveryDate',
        valueFormatter: dateNumericFormatter,
        minWidth: 150,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('ID'),
        field: 'id',
        minWidth: 100,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('Type'),
        field: 'type',
        minWidth: 150,
        valueFormatter: (params) =>
          translateWarehouseDocumentType(params.value),
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('Source'),
        field: 'source',
        minWidth: 200,
        valueFormatter: (params) =>
          translateWarehouseDocumentSource(params.value),
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: WarehouseDocumentState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Note'),
        field: 'note',
        minWidth: 250,
        disableColumnMenu: true,
        sortable: false
      },
      {
        headerName: t('Price VAT excluded'),
        field: 'sumPriceVatExcluded',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Price VAT included'),
        field: 'sumPriceVatIncluded',
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Warehouse'),
        field: 'warehouse',
        valueGetter: (params) => params.row.warehouse?.name,
        disableColumnMenu: true,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Business partner'),
        field: 'businessPartner',
        valueGetter: (params) => params.row.businessPartner?.companyName,
        disableColumnMenu: true,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Third party document ID'),
        field: 'thirdPartyDocumentId',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Updated by'),
        field: 'updatedBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: t('Created by'),
        field: 'createdBy',
        valueFormatter: userNameFormatter,
        sortable: false,
        minWidth: 250
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'detail',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [
      dateNumericFormatter,
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      t,
      translateWarehouseDocumentSource,
      translateWarehouseDocumentType,
      userNameFormatter
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading warehouse documents')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ChipWithOptions<WarehouseDocumentState>
            key="state-chip"
            selectedItem={state}
            setSelectedItem={setState}
            options={[
              WarehouseDocumentState.Draft,
              WarehouseDocumentState.Issued
            ].map((state) => ({
              label: translateWarehouseDocumentState(state),
              option: state
            }))}
            allText={t('All states')}
            size="small"
          />,
          <OptionsChipWithDelete<WarehouseDocumentType>
            key="type-chip"
            tooltip={t('Select document type')}
            icon={<DescriptionOutlinedIcon />}
            options={[
              {
                id: WarehouseDocumentType.Incoming,
                label: t('Incoming documents')
              },
              {
                id: WarehouseDocumentType.Outgoing,
                label: t('Outgoing documents')
              }
            ]}
            selectedOption={type}
            onChange={setType}
          />,
          <DivisionChip key="division-chip" onChange={setDivisionId} />,
          <WarehousesChip key="warehouse-chip" onChange={setWarehouseId} />,
          P([PermissionCode.ReadUsers]) && (
            <UserChip key="user-chip" onChange={handleUserChange} />
          )
        ]}
        rightActions={[
          <DatePickerButton
            key="delivery-date-picker"
            tooltip={t('Delivery date')}
            label={t('Delivery date')}
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            groups={[TabGroup.Now, TabGroup.Past]}
            excludedValues={[TabNow.UntilToday, TabNow.FromToday]}
            minDateFrom={
              selectedDate?.dateRange?.dateTo
                ? dayjs(selectedDate?.dateRange?.dateTo)
                    .subtract(1, 'year')
                    .startOf('d')
                : dayjs().subtract(1, 'year').startOf('d')
            }
            maxDateFrom={selectedDate?.dateRange?.dateTo || dayjs().endOf('d')}
            maxDateTo={dayjs().endOf('d')}
          />,
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout
        sx={{
          height: `calc(100% - 64px)`,
          width: '100%',
          p: 3
        }}
      >
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.warehouseDocuments.items || []}
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={{noRowsLabel: t('No warehouse documents found')}}
          initialState={{
            pinnedColumns: {left: ['deliveryDate'], right: ['detail']}
          }}
          columnVisibilityModel={{
            detail: P([PermissionCode.ReadWarehouseDocument]),
            sumPriceVatExcluded: isVatRegistered
          }}
          {...getDataGridPaginationProps(data?.warehouseDocuments.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}
