import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  List,
  Typography
} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  AdmissionTypeState,
  NarrowAdmissionTypeFieldsFragment
} from '../../../../../../__generated__/schema'
import {DialogTitleWithCloseButton} from '../../../../../common/DialogTitleWithCloseButton'
import {OutlinedInputWithCancelAdornment} from '../../../../../common/OutlinedInputWithCancelAdornment'
import {Error} from '../../../../../visual'
import {useLightweightAdmissionTypes} from '../../graphql'
import {AdmissionRowListItem} from './AdmissionRowListItem'

interface IAddAdmissionTypeDialogProps {
  onClose: () => void
  assignedAdmissionTypes: NarrowAdmissionTypeFieldsFragment[]
  onAddButtonClick: (
    selectedAdmissionTypes: NarrowAdmissionTypeFieldsFragment[]
  ) => void
}

export const AddAdmissionTypeDialog: React.FC<IAddAdmissionTypeDialogProps> = ({
  onClose,
  assignedAdmissionTypes,
  onAddButtonClick
}: IAddAdmissionTypeDialogProps) => {
  const {t} = useTranslation()
  const {data, loading, error} = useLightweightAdmissionTypes({
    variables: {
      filter: {states: [AdmissionTypeState.Active]},
      paginationInput: {offset: 0, limit: 300}
    },
    fetchPolicy: 'network-only'
  })
  const [search, setSearch] = useState<string>('')
  const [selectedAdmissionTypes, setSelectedAdmissionTypes] = useState<
    NarrowAdmissionTypeFieldsFragment[]
  >([])
  const filteredAdmissionTypes = (data?.admissionTypes.items || []).filter(
    (at) => at.name.toUpperCase().indexOf(search.toUpperCase()) > -1
  )
  const selectableAdmissionTypes = (data?.admissionTypes.items || []).filter(
    (admissionType) =>
      !assignedAdmissionTypes.find((aat) => aat.id === admissionType.id)
  )
  const createAdmissionTypeCheckboxClickHandler = useCallback(
    (admissionType: NarrowAdmissionTypeFieldsFragment) =>
      (e: React.MouseEvent) => {
        e.stopPropagation()
        setSelectedAdmissionTypes((admissionTypes) =>
          admissionTypes.find((at) => at.id === admissionType.id)
            ? admissionTypes.filter((at) => at.id !== admissionType.id)
            : [...admissionTypes, admissionType]
        )
      },
    []
  )
  const handleAddButtonClick = useCallback(() => {
    onAddButtonClick([...assignedAdmissionTypes, ...selectedAdmissionTypes])
    onClose()
    setSelectedAdmissionTypes([])
    setSearch('')
  }, [
    assignedAdmissionTypes,
    onAddButtonClick,
    onClose,
    selectedAdmissionTypes
  ])
  const handleClose = useCallback(() => {
    onClose()
    setSelectedAdmissionTypes([])
    setSearch('')
  }, [onClose])
  return (
    <Dialog
      open
      scroll="paper"
      maxWidth="xs"
      fullWidth
      sx={{[`& .${dialogClasses.paper}`]: {height: '92vh'}}}
    >
      <DialogTitleWithCloseButton onCloseClick={handleClose}>
        {t('Add admission type')}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 0
        }}
        dividers
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'common.white',
            px: 2,
            pt: 2,
            gap: 1
          }}
        >
          <OutlinedInputWithCancelAdornment
            onCancelClick={() => setSearch('')}
            inputProps={{
              value: search,
              onChange: (e) => setSearch(e.target.value),
              placeholder: t('Search for admission type')
            }}
            sx={{p: 0}}
          />
          <Typography variant="overline" color="textSecondary">
            {t('Select admission types available in price list')}
          </Typography>
        </Box>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}
        {error && (
          <Error
            error={error}
            message={t('Error while loading admission types')}
          />
        )}
        {data && !loading && (
          <>
            {filteredAdmissionTypes.length > 0 ? (
              <List sx={{px: 2, py: 1, overflowY: 'auto'}}>
                {filteredAdmissionTypes.map((item) => (
                  <AdmissionRowListItem
                    key={item.id}
                    name={item.name}
                    color={item.color}
                    icon={item.icon}
                    capacity={item.capacityDecreaseCount}
                    minimalQuantity={item.startingQuantity}
                    checkBoxProps={{
                      onClick: createAdmissionTypeCheckboxClickHandler(item),
                      checked:
                        !!selectedAdmissionTypes.find((at) => at === item) ||
                        false
                    }}
                    isCheckBoxHidden={
                      !!assignedAdmissionTypes.find(
                        (at) => at.id === item.id
                      ) || false
                    }
                  />
                ))}
              </List>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2
                }}
              >
                <Typography variant="body2">
                  {t('No admission types found')}
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          p: 2
        }}
      >
        <Typography variant="subtitle1" color="textPrimary">
          {t('Selected: {{selected}} of {{available}}', {
            selected: selectedAdmissionTypes.length,
            available: selectableAdmissionTypes.length
          })}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddButtonClick}
          disabled={selectedAdmissionTypes.length === 0}
        >
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
