import {Typography} from '@mui/material'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {safeSum} from '../../../../../utils/money'
import {IPaymentIntentsForm, PaymentIntentField} from '../types'

interface IIntentStructureAccordionSecondaryChildrenProps {
  isExpanded: boolean
  intentFrames: {key: string}[]
}

export const IntentStructureAccordionSecondaryChildren: React.FC<IIntentStructureAccordionSecondaryChildrenProps> =
  ({
    intentFrames,
    isExpanded
  }: IIntentStructureAccordionSecondaryChildrenProps) => {
    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
    const {getValues} = useFormContext<IPaymentIntentsForm>()
    if (isExpanded) {
      return null
    }
    const intents = getValues({nest: true})
    const sum = safeSum(
      intentFrames.map(
        ({key}) => intents[key] && intents[key][PaymentIntentField.Amount]
      )
    )
    return sum ? (
      <Typography variant="body1">
        {translateEffectiveClientPrice(sum)}
      </Typography>
    ) : null
  }
