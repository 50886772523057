import {Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {MessageQuery} from '../../../../__generated__/schema'
import {
  useTranslateMessageState,
  useTranslateMessageStateDescription
} from '../../../../hooks/translateMessageState'
import {useTranslateMessageType} from '../../../../hooks/translateMessageType'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {EntityStateChip} from '../../../common'
import {ReadOnlyRow} from '../../../common/ReadOnly'
import {messageStateColors} from '../../../constants'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  stateRow: {
    display: 'grid',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr'
  }
}))

interface IGeneralProps {
  message: MessageQuery['message']
}

export const General: React.FC<IGeneralProps> = ({message}: IGeneralProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const translateMessageState = useTranslateMessageState()
  const translateMessageStateDescription = useTranslateMessageStateDescription(
    message.sendAt
  )
  const translateMessageType = useTranslateMessageType()
  const {formatDateNumeric, formatTimeWithSeconds} = useDateTimeFormatters()
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">{message.internalNote}</Typography>
      <div className={classes.stateRow}>
        <EntityStateChip
          label={translateMessageState(message.state)}
          colorConf={messageStateColors[message.state]}
        />
        <Typography variant="caption" color="textSecondary">
          {translateMessageStateDescription(message.state)}
        </Typography>
      </div>
      <div>
        <ReadOnlyRow label={t('Sender name')}>{message.senderName}</ReadOnlyRow>
        {message.replyTo && (
          <ReadOnlyRow label={t('Reply to')}>{message.replyTo}</ReadOnlyRow>
        )}
      </div>
      <div>
        <ReadOnlyRow label={t('Type')}>
          {translateMessageType(message.type)}
        </ReadOnlyRow>
        <ReadOnlyRow label={t('Created at')}>
          {[
            formatDateNumeric(new Date(message.createdAt)),
            formatTimeWithSeconds(new Date(message.createdAt))
          ].join(', ')}
        </ReadOnlyRow>
        <ReadOnlyRow label={t('Created by')}>
          {[message.createdBy.firstName, message.createdBy.lastName].join(' ')}
        </ReadOnlyRow>
      </div>
    </div>
  )
}
