import GetAppIcon from '@mui/icons-material/GetApp'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {Box, IconButton} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventState,
  PermissionCode,
  ShowsStatisticsFilterInput
} from '../../../../__generated__/schema'
import {useClientLocaleCode} from '../../../../hooks/getLocales'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useTranslateEventState} from '../../../../hooks/translateEventState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Tooltip} from '../../../common'
import {
  DataGridTable,
  DecimalCellRenderer,
  replaceDotWithCommaFormatter,
  useDataGridPagination,
  useDateTimeFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {MoreMenu, SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {PaymentDateChip} from '../components/PaymentDateChip'
import {UserChip} from '../components/UserChip'
import {getBoldCellClassName, useGetFilterDateRange} from '../utils'
import {useOverviewShowsStatistics} from './graphql'

const useShowTitleFormatter = () => {
  const clientLocaleCode = useClientLocaleCode()
  return useCallback(
    (params: GridValueFormatterParams) =>
      Array.isArray(params.value)
        ? params.value.find(({localeCode}) => localeCode === clientLocaleCode)
            ?.title
        : params.value,
    [clientLocaleCode]
  )
}

interface IOverviewProps {
  searchFilter: ShowsStatisticsFilterInput
}

export const Overview: React.FC<IOverviewProps> = ({
  searchFilter
}: IOverviewProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
    useDataGridPagination({
      page: 0,
      pageSize: 50
    })
  const dataGridApiRef = useGridApiRef()
  const [selectedPaymentDate, setSelectedPaymentDate] =
    useState<IDataPickerData | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.FromToday
    }
  )
  const [selectedEventState, setSelectedEventState] = useState<
    EventState | undefined
  >(EventState.Published)
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const getFilterDateRange = useGetFilterDateRange()
  const {data, loading, error, refetch} = useOverviewShowsStatistics({
    paginationInput,
    filter: {
      ...searchFilter,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'eventStartsAtFrom',
        filterNameTo: 'eventStartsAtTo'
      }),
      ...getFilterDateRange({
        date: selectedPaymentDate,
        filterNameFrom: 'paymentDateTimeFrom',
        filterNameTo: 'paymentDateTimeTo'
      }),
      eventStates: selectedEventState
        ? [selectedEventState]
        : [EventState.Published, EventState.Canceled],
      paymentCreatedByIds: selectedUserId ? [selectedUserId] : undefined
    }
  })
  const dateTimeFormatter = useDateTimeFormatter()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const showTitleFormatter = useShowTitleFormatter()
  const translateEventState = useTranslateEventState()
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Title'),
        field: 'showTitles',
        valueFormatter: showTitleFormatter,
        sortable: false,
        minWidth: 300,
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('First in range'),
        field: 'firstEventStartsAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 250,
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('Last in range'),
        field: 'lastEventStartsAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 250,
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('Count'),
        field: 'eventsCount',
        align: 'right',
        headerAlign: 'right',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 150,
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'profit',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('Total'),
        field: 'profitTicketCount',
        align: 'right',
        headerAlign: 'right',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('Discounted'),
        field: 'totalDiscountedSoldTicketCount',
        align: 'right',
        headerAlign: 'right',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        cellClassName: getBoldCellClassName
      },
      {
        headerName: t('Free'),
        field: 'totalFreeSoldTicketCount',
        align: 'right',
        headerAlign: 'right',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <DecimalCellRenderer params={params} fractionDigits={0} />
        },
        valueFormatter: replaceDotWithCommaFormatter,
        sortable: false,
        minWidth: 100,
        cellClassName: getBoldCellClassName
      }
    ],
    [
      dateTimeFormatter,
      showTitleFormatter,
      t,
      translatedEffectiveClientCurrencySign
    ]
  )
  if (error) {
    return (
      <Error
        error={error}
        message={t('Error while loading shows statistics')}
      />
    )
  }
  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ChipWithOptions<EventState>
            key="state-chip"
            options={[EventState.Published, EventState.Canceled].map(
              (eventState) => ({
                option: eventState,
                label: translateEventState(eventState)
              })
            )}
            selectedItem={selectedEventState}
            setSelectedItem={setSelectedEventState}
            size="small"
            allText={t('All events')}
          />,
          P([PermissionCode.ReadUsers]) ? (
            <UserChip key="user-chip" onChange={setSelectedUserId} />
          ) : (
            []
          ),
          <PaymentDateChip
            key="payment-date-chip"
            selectedDate={selectedPaymentDate}
            setSelectedDate={setSelectedPaymentDate}
            popperSx={(theme) => ({zIndex: theme.zIndex.drawer - 1})}
          />
        ].filter(Boolean)}
        rightActions={[
          <DatePickerButton
            key="event-date-picker"
            onDateSelect={handleDateSelect}
            selectedValues={selectedDate}
            tooltip={t('Event date')}
          />,
          <Tooltip key="refetch-button" title={t('Reload')}>
            <IconButton onClick={handleRefetchButtonClick}>
              <RefreshOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>,
          <MoreMenu
            key="more-menu"
            items={[
              {
                label: t('Download CSV'),
                icon: <GetAppIcon />,
                onClick: () =>
                  dataGridApiRef.current.exportDataAsCsv({
                    fileName: t<string>('shows-statistics')
                  })
              }
            ]}
          />
        ]}
        rightActionsSx={{py: 0}}
      />
      <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            },
            '& .bold': {fontWeight: 'bold'}
          }}
          apiRef={dataGridApiRef}
          columns={columns}
          loading={loading}
          rows={data?.showsStatistics.items || []}
          getRowId={(row) => row.showId}
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{
            pinnedColumns: {left: ['showTitles']}
          }}
          columnGroupingModel={[
            {
              groupId: t('Show'),
              children: [{field: 'showTitles'}]
            },
            {
              groupId: t('Event'),
              children: [
                {field: 'firstEventStartsAt'},
                {field: 'lastEventStartsAt'},
                {field: 'eventsCount'}
              ]
            },
            {
              groupId: t('Tickets'),
              children: [
                {field: 'profitTicketCount'},
                {field: 'totalDiscountedSoldTicketCount'},
                {field: 'totalFreeSoldTicketCount'}
              ]
            }
          ]}
          pinnedRows={{
            bottom: [
              {
                showId: 0,
                showTitles: t('Total'),
                eventsCount: data?.showsStatistics.totals.eventsCount,
                profit: data?.showsStatistics.totals.profit,
                profitTicketCount:
                  data?.showsStatistics.totals.profitTicketCount,
                totalDiscountedSoldTicketCount:
                  data?.showsStatistics.totals.totalDiscountedSoldTicketCount,
                totalFreeSoldTicketCount:
                  data?.showsStatistics.totals.totalFreeSoldTicketCount
              }
            ]
          }}
          localeText={{noRowsLabel: t('No shows statistics to show')}}
          {...getDataGridPaginationProps(data?.showsStatistics.pagination)}
        />
      </Box>
    </Box>
  )
}
