import {useTranslation} from 'react-i18next'
import {Month} from '../__generated__/schema'

export const useTranslateMonth = () => {
  const {t} = useTranslation()
  const translated: Record<Month, string> = {
    [Month.January]: t<string>('Month->January'),
    [Month.February]: t<string>('Month->February'),
    [Month.March]: t<string>('Month->March'),
    [Month.April]: t<string>('Month->April'),
    [Month.May]: t<string>('Month->May'),
    [Month.June]: t<string>('Month->June'),
    [Month.July]: t<string>('Month->July'),
    [Month.August]: t<string>('Month->August'),
    [Month.September]: t<string>('Month->September'),
    [Month.October]: t<string>('Month->October'),
    [Month.November]: t<string>('Month->November'),
    [Month.December]: t<string>('Month->December')
  }
  return (month: Month) => translated[month]
}
