export enum DATERANGE_IDS {
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  NEXT_30_DAYS = 'NEXT_30_DAYS',
  THIS_WEEKEND = 'THIS_WEEKEND',
  NEXT_WEEKEND = 'NEXT_WEEKEND',
  THIS_MONTH = 'THIS_MONTH',
  NEXT_MONTH = 'NEXT_MONTH',
  CUSTOM_DATERANGE = 'CUSTOM_DATERANGE',
  TWO_DAYS_AGO = 'TWO_DAYS_AGO',
  THIS_QUARTER = 'THIS_QUARTER',
  LAST_QUARTER = 'LAST_QUARTER',
  LAST_MONTH = 'LAST_MONTH'
}

export type PREDICTABLE_DATERANGE_IDS = Exclude<
  DATERANGE_IDS,
  DATERANGE_IDS.CUSTOM_DATERANGE
>
