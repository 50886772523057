import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Box,
  Divider,
  IconButton,
  SxProps,
  Typography,
  useMediaQuery
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {MediaSizes} from '../constants'
import {IMenuItemProps, Menu, MenuItem, useMenu} from './Menu'

const useStyles = makeStyles<Theme, {isSmallMobile: boolean}>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    height: 56,
    width: '100%',
    borderBottom: `solid ${theme.palette.divider} 1px`,
    backgroundColor: theme.palette.background.paper,
    alignContent: 'center'
  },
  leftSide: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(2, 0, 2, 3),
    justifyContent: 'flex-start'
  },
  rightSide: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(2, 3, 2, 0),
    justifyContent: 'flex-end'
  },
  title: {
    paddingRight: theme.spacing(2)
  },
  divider: {
    marginRight: theme.spacing(1),
    height: 24
  }
}))

interface ISubHeaderToolbarProps {
  title: string
  /**
   * @deprecated use sx prop
   */
  className?: string
  sx?: SxProps
  leftActions?: React.ReactNode[]
  /**
   * @deprecated use leftActionsSx prop
   */
  leftActionsClassName?: string
  leftActionsSx?: SxProps<Theme>
  rightActions?: React.ReactNode[]
  /**
   * @deprecated use rightActionsSx prop
   */
  rightActionsClassName?: string
  rightActionsSx?: SxProps<Theme>
}

export const SubHeaderToolbar: React.FC<ISubHeaderToolbarProps> = ({
  title,
  className,
  sx,
  leftActions,
  leftActionsClassName,
  leftActionsSx,
  rightActions,
  rightActionsClassName,
  rightActionsSx
}: ISubHeaderToolbarProps) => {
  const isSmallMobile = useMediaQuery(MediaSizes.SmallMobile)
  const classes = useStyles({isSmallMobile})
  return (
    <Box className={cn(classes.root, className)} sx={sx}>
      <Box
        className={cn(classes.leftSide, leftActionsClassName)}
        sx={leftActionsSx}
      >
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        {leftActions}
      </Box>
      {rightActions && (
        <Box
          className={cn(classes.rightSide, rightActionsClassName)}
          sx={rightActionsSx}
        >
          <Divider orientation="vertical" className={classes.divider} />
          {rightActions}
        </Box>
      )}
    </Box>
  )
}

interface IMoreMenuProps {
  items: IMenuItemProps[]
}

export const MoreMenu: React.FC<IMoreMenuProps> = ({items}: IMoreMenuProps) => {
  const {anchorEl, openMenu, closeMenu} = useMenu()
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            {...item}
            onClick={(e) => {
              if (item.onClick) {
                item.onClick(e)
              }
              closeMenu()
            }}
          />
        ))}
      </Menu>
    </>
  )
}
