import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {WarehouseDocumentItemsFilterInput} from '../../../../__generated__/schema'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {InventoryTransactionsList} from './InventoryTransactionsList'
import {
  DEFAULT_WAREHOUSE_DOCUMENT_ITEMS_FILTER_INPUT,
  InventoryTransactionsSearch
} from './InventoryTransactionsSearch'

export const InventoryTransactionsPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] =
    useState<WarehouseDocumentItemsFilterInput>(
      DEFAULT_WAREHOUSE_DOCUMENT_ITEMS_FILTER_INPUT
    )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Inventory transactions')}
          search={
            <InventoryTransactionsSearch onFilterChange={setSearchFilter} />
          }
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <InventoryTransactionsList searchFilter={searchFilter} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
