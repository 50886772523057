import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {SortableTreeItem} from './SortableTreeItem'
import type {FlattenedItem, SensorContext, TreeItems} from './types'

import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty
} from './utils'

const adjustTranslate: Modifier = ({transform}) => {
  return {
    ...transform,
    y: transform.y - 25
  }
}

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always
  }
}

const dropAnimationConfig: DropAnimation = {
  keyframes({transform}) {
    return [
      {opacity: 1, transform: CSS.Transform.toString(transform.initial)},
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5
        })
      }
    ]
  },
  easing: 'ease-out',
  sideEffects({active}) {
    active.node.animate([{opacity: 0}, {opacity: 1}], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing
    })
  }
}

interface ISortableTreeProps {
  collapsible?: boolean
  treeItems: TreeItems
  indentationWidth?: number
  indicator?: boolean
  removable?: boolean
  onEdit?: (id: UniqueIdentifier) => void
  onChange: (newItems: TreeItems) => void
}

export const SortableTree: React.FC<ISortableTreeProps> = ({
  treeItems,
  indicator,
  indentationWidth = 48,
  removable,
  collapsible,
  onEdit,
  onChange
}: ISortableTreeProps) => {
  const [items, setItems] = useState<TreeItems>(treeItems)
  useEffect(() => {
    setItems(treeItems)
  }, [treeItems])
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items)
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, {children, collapsed, id}) =>
        collapsed && children.length ? [...acc, String(id)] : acc,
      []
    )
    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    )
  }, [activeId, items])
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  })
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor))
  const sortedIds = useMemo(
    () => flattenedItems.map(({id}) => id),
    [flattenedItems]
  )
  const activeItem = activeId
    ? flattenedItems.find(({id}) => id === activeId)
    : null
  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    }
  }, [flattenedItems, offsetLeft])
  const handleDragStart = useCallback(
    ({active: {id: activeId}}: DragStartEvent) => {
      setActiveId(activeId)
      setOverId(activeId)
      document.body.style.setProperty('cursor', 'grabbing')
    },
    []
  )
  const handleDragMove = useCallback(
    ({delta}: DragMoveEvent) => setOffsetLeft(delta.x),
    []
  )
  const handleDragOver = useCallback(
    ({over}: DragOverEvent) => setOverId(over?.id ?? null),
    []
  )
  const resetState = useCallback(() => {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    document.body.style.setProperty('cursor', '')
  }, [])
  const handleDragEnd = useCallback(
    ({active, over}: DragEndEvent) => {
      resetState()

      if (projected && over) {
        const {depth, parentId} = projected
        const clonedItems: FlattenedItem[] = JSON.parse(
          JSON.stringify(flattenTree(items))
        )
        const overIndex = clonedItems.findIndex(({id}) => id === over.id)
        const activeIndex = clonedItems.findIndex(({id}) => id === active.id)
        const activeTreeItem = clonedItems[activeIndex]

        clonedItems[activeIndex] = {...activeTreeItem, depth, parentId}

        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
        const newItems = buildTree(sortedItems)

        setItems(newItems)
        onChange(newItems)
      }
    },
    [items, onChange, projected, resetState]
  )
  const handleDragCancel = useCallback(() => resetState(), [resetState])
  const handleRemove = useCallback(
    (id: UniqueIdentifier) =>
      setItems((items) => {
        const removedItems = removeItem(items, id)
        onChange(removedItems)
        return removedItems
      }),
    [onChange]
  )
  const handleCollapse = useCallback(
    (id: UniqueIdentifier) =>
      setItems((items) => {
        const updated = setProperty(items, id, 'collapsed', (value) => {
          return !value
        })
        onChange(updated)
        return updated
      }),
    [onChange]
  )
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(
          ({id, children, collapsed, depth, label, description}) => (
            <SortableTreeItem
              key={id}
              id={id}
              value={label}
              description={description}
              depth={id === activeId && projected ? projected.depth : depth}
              indentationWidth={indentationWidth}
              indicator={indicator}
              collapsed={Boolean(collapsed && children.length)}
              onCollapse={
                collapsible && children.length
                  ? () => handleCollapse(id)
                  : undefined
              }
              onRemove={removable ? () => handleRemove(id) : undefined}
              onEdit={onEdit ? () => onEdit(id) : undefined}
            />
          )
        )}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(items, activeId) + 1}
                value={
                  flattenedItems.find(({id}) => id === activeId)?.label ||
                  String(activeId)
                }
                description={
                  flattenedItems.find(({id}) => id === activeId)?.description
                }
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  )
}
