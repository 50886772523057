import {SellingChannel} from '../../../../__generated__/schema'

export enum DailyCashierPaymentReportFormField {
  Note = 'note',
  CashierUserId = 'cashierUserId',
  ReportDate = 'reportDate'
}

export interface IDailyCashierReportForm {
  [DailyCashierPaymentReportFormField.Note]: string | null
  [DailyCashierPaymentReportFormField.CashierUserId]: string
  [DailyCashierPaymentReportFormField.ReportDate]: string
}

export enum CreateDailyCashierPaymentReportStep {
  SelectCashier = 'selectCashier',
  SelectDate = 'selectDate'
}

export enum MonthlyPaymentReportField {
  Note = 'note',
  YearMonth = 'yearMonth'
}

export interface IMonthlyPaymentReportForm {
  [MonthlyPaymentReportField.Note]: string | null
  [MonthlyPaymentReportField.YearMonth]: string
}

export enum PaymentReportViewFormField {
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  Channel = 'channel',
  UserIds = 'userIds',
  Note = 'note'
}

export interface IPaymentReportViewForm {
  [PaymentReportViewFormField.DateFrom]: string
  [PaymentReportViewFormField.DateTo]: string
  [PaymentReportViewFormField.Channel]?: SellingChannel
  [PaymentReportViewFormField.UserIds]?: number[]
  [PaymentReportViewFormField.Note]?: string
}
