import {useLazyQuery} from '@apollo/react-hooks'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightUsersQuery,
  LightweightUsersQueryVariables,
  UserState
} from '../../../../__generated__/schema'
import {Tooltip} from '../../../common'
import {Menu, MenuItem, useMenu} from '../../../common/Menu'
import {GET_LIGHTWEIGHT_USERS} from '../graphql'

const LoadingSkeleton = React.forwardRef<HTMLDivElement>(
  function LoadingSkeletonWithRef(props, ref) {
    return (
      <div ref={ref}>
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
        <Skeleton height={48} />
      </div>
    )
  }
)

interface IUser {
  id: number
  firstName: string
  lastName: string
}

const useStyles = makeStyles(() => ({
  menuItem: {
    flexFlow: 'row-reverse'
  },
  menuIcon: {
    justifyContent: 'center'
  },
  menu: {}
}))

interface IUserChipProps {
  onChange: (userId: number | null) => void
  search?: boolean
  nameFormatter?: (firstName: string, lastName: string) => string
}

export const UserChip: React.FC<IUserChipProps> = ({
  onChange,
  search,
  nameFormatter
}: IUserChipProps) => {
  const {t} = useTranslation()
  const [users, setUsers] = useState<IUser[] | null>(null)
  const [userSearch, setUserSearch] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleGetUsersCompleted = useCallback((data: LightweightUsersQuery) => {
    setUsers(
      data.users
        .filter((user) => user.state !== UserState.Invited)
        .sort(
          (userA, userB) =>
            userA.lastName.localeCompare(userB.lastName) ||
            userA.firstName.localeCompare(userB.firstName)
        )
        .map((user) => ({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        }))
    )
  }, [])
  const filteredUsers = users?.filter(({firstName, lastName}) =>
    [lastName, firstName].join(' ').toLowerCase().includes(userSearch)
  )
  const handleMenuItemClick = useCallback(
    (user: IUser) => () => {
      setSelectedUser(user)
      onChange(user.id)
      closeMenu()
    },
    [closeMenu, onChange]
  )
  const [getUsers, {loading}] = useLazyQuery<
    LightweightUsersQuery,
    LightweightUsersQueryVariables
  >(GET_LIGHTWEIGHT_USERS, {
    onCompleted: handleGetUsersCompleted,
    fetchPolicy: 'network-only'
  })
  const handleChipClick = useCallback(
    (event) => {
      getUsers()
      openMenu(event)
    },
    [getUsers, openMenu]
  )
  const handleDeleteChipClick = useCallback(() => {
    setSelectedUser(null)
    onChange(null)
  }, [onChange])
  const handleMenuClose = useCallback(() => {
    closeMenu()
    setUserSearch('')
  }, [closeMenu])
  const classes = useStyles()
  return (
    <>
      <Tooltip title={t('Select user')}>
        <Chip
          size="small"
          onClick={handleChipClick}
          variant="outlined"
          icon={<PersonOutlineIcon />}
          deleteIcon={selectedUser ? <CancelIcon /> : <ArrowDropDownIcon />}
          onDelete={selectedUser ? handleDeleteChipClick : handleChipClick}
          label={
            selectedUser
              ? nameFormatter
                ? nameFormatter(selectedUser.firstName, selectedUser.lastName)
                : [selectedUser.lastName, selectedUser.firstName].join(' ')
              : undefined
          }
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        className={classes.menu}
      >
        {search && (
          <Box sx={{p: 1}}>
            <TextField
              variant="standard"
              size="small"
              placeholder={t('Search for user')}
              fullWidth
              autoComplete="off"
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => setUserSearch('')}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Box>
        )}
        {loading ? (
          <LoadingSkeleton />
        ) : (
          filteredUsers?.map((user) => (
            <MenuItem
              key={user.id}
              label={
                nameFormatter
                  ? nameFormatter(user.firstName, user.lastName)
                  : [user.lastName, user.firstName].join(' ')
              }
              icon={user.id === selectedUser?.id ? <CheckIcon /> : undefined}
              isSelected={user.id === selectedUser?.id}
              className={classes.menuItem}
              iconClassName={classes.menuIcon}
              onClick={handleMenuItemClick(user)}
            />
          ))
        )}
      </Menu>
    </>
  )
}
