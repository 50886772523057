import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSelector} from '../redux'
import {useObjectsActions} from '../redux/objects/actions'
import {isSomeObjectSelectedSelector} from '../redux/objects/selectors'
import {FlipHorizontalIcon, FlipVerticalIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const FlipPanel: React.FC = () => {
  const {t} = useTranslation()
  const {flipHorizontal, flipVertical} = useObjectsActions()
  const isSomeObjectSelected = useSelector(isSomeObjectSelectedSelector)

  return (
    <Panel>
      <PanelButton
        tooltip={t('Flip vertical')}
        disabled={!isSomeObjectSelected}
        onClick={flipVertical}
        cypressId="flip-vertical-button"
      >
        <FlipVerticalIcon />
      </PanelButton>
      <PanelButton
        tooltip={t('Flip horizontal')}
        disabled={!isSomeObjectSelected}
        onClick={flipHorizontal}
        cypressId="flip-horizontal-button"
      >
        <FlipHorizontalIcon />
      </PanelButton>
    </Panel>
  )
}
