import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  ClientShowImagePropertiesFragment,
  GetLibraryShowImagesQuery,
  LibraryShowImagePropertiesFragment,
  ShowImageType,
  ShowVideo
} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'

import {SHOWS_PARAMS as SP} from '../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../utils/routes'
import {
  ShowContentType,
  ShowSection,
  useMediaContentTypeTranslations
} from '../../../constants'
import {LibraryShowImageTypeAccordion} from './editLibraryShow/LibraryShowImageTypeAccordion'
import {useUploadClientShowImage} from './graphql'
import {ShowImageTypeAccordion} from './ShowImageTypeAccordion'
import {UploadMedia} from './UploadMedia'
import {GalleryImage2} from './utils'
import {VideosSectionAccordion} from './VideosSectionAccordion'

export const useShowContentTypeAnchors = () => {
  const contentLabels = useMediaContentTypeTranslations()
  return {
    posters: {
      id: 'posters',
      label: contentLabels[ShowContentType.Posters]
    },
    photos: {
      id: 'photos',
      label: contentLabels[ShowContentType.Photos]
    },
    videos: {
      id: 'videos',
      label: contentLabels[ShowContentType.Videos]
    },
    covers: {
      id: 'covers',
      label: contentLabels[ShowContentType.Covers]
    },
    crew: {
      id: 'crew',
      label: contentLabels[ShowContentType.Crew]
    },
    banners: {
      id: 'banners',
      label: contentLabels[ShowContentType.Banners]
    },
    backdrops: {
      id: 'backdrops',
      label: contentLabels[ShowContentType.Backdrops]
    }
  }
}

const useLibraryShowUploadContentStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingTop: theme.spacing(4)
  }
}))

interface ILibraryShowUploadContentProps {
  libraryShowImages: GetLibraryShowImagesQuery['libraryShow']['images']
  showId: number
  refetchLibraryShowImages: () => void
  videos: Pick<ShowVideo, 'source' | 'url' | 'sourceVideoId'>[]
}

export const LibraryShowUploadContent: React.FC<ILibraryShowUploadContentProps> =
  ({
    libraryShowImages,
    showId,
    refetchLibraryShowImages,
    videos
  }: ILibraryShowUploadContentProps) => {
    const classes = useLibraryShowUploadContentStyles()
    return (
      <div className={classes.root}>
        {Object.values(ShowImageType).map((type) => (
          <LibraryShowImageTypeAccordion
            key={type}
            libraryShowImages={libraryShowImages.filter(
              (image) => image.type === type
            )}
            type={type}
            showId={showId}
            refetchLibraryShowImages={refetchLibraryShowImages}
          />
        ))}
        <VideosSectionAccordion
          showId={showId}
          videos={videos}
          isLibraryVideo
        />
      </div>
    )
  }

const useClientShowUploadContentStyles = makeStyles<Theme>((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3)
  }
}))

interface IClientShowUploadContentProps {
  showId: number
  clientShowImages: ClientShowImagePropertiesFragment[]
  libraryShowImages: LibraryShowImagePropertiesFragment[]
  refetch?: () => void
  videos: Pick<ShowVideo, 'source' | 'url' | 'sourceVideoId'>[]
  showTitle: string
}

export const ClientShowUploadContent: React.FC<IClientShowUploadContentProps> =
  ({
    showId,
    clientShowImages,
    libraryShowImages,
    refetch,
    videos,
    showTitle
  }: IClientShowUploadContentProps) => {
    const {t} = useTranslation()
    const classes = useClientShowUploadContentStyles()
    const {setShowBackdrop, addInfoNotification} = useMutationAssistanceHooks()
    const history = useHistory()
    const handleExited = useCallback(
      () =>
        history.replace(
          routeTo.admin.shows.editWithEditSection(showId, ShowSection.Media)
        ),
      [showId, history]
    )
    const uploadClientShowImage = useUploadClientShowImage()
    const handleUpload = useCallback(
      async (images: GalleryImage2[]) => {
        setShowBackdrop(true)
        const promises = await Promise.allSettled(
          images.map((image) =>
            uploadClientShowImage({
              isPrivate: image.isPrivate || false,
              data: {showId, type: image.imageType},
              file: image.image
            })
          )
        )
        if (refetch) {
          refetch()
        }
        addInfoNotification(
          t('{{uploadedCount}} of {{count}} images uploaded', {
            uploadedCount: promises.filter(
              (promise) => promise.status === 'fulfilled'
            ).length,
            count: images.length
          })
        )
        setShowBackdrop(false)
        history.push(
          routeTo.admin.shows.editWithEditSection(showId, ShowSection.Media)
        )
      },
      [
        addInfoNotification,
        history,
        refetch,
        setShowBackdrop,
        showId,
        t,
        uploadClientShowImage
      ]
    )
    return (
      <>
        <div className={classes.root}>
          {Object.values(ShowImageType).map((type) => (
            <ShowImageTypeAccordion
              key={type}
              clientShowImages={clientShowImages
                .filter((image) => image.type === type)
                .sort((imageA, imageB) => imageA.order - imageB.order)}
              libraryShowImages={libraryShowImages.filter(
                (image) => image.type === type
              )}
              type={type}
              showId={showId}
              refetch={refetch}
            />
          ))}
          <VideosSectionAccordion showId={showId} videos={videos} />
        </div>
        <Route
          path={routeTo.admin.shows.uploadMedia(
            toPlaceholderParam(SP.SHOW_ID),
            toPlaceholderParam(SP.SECTION),
            toPlaceholderParam(SP.ACTIVE_CONTENT_TYPE)
          )}
          exact
        >
          <UploadMedia
            onExited={handleExited}
            onUpload={handleUpload}
            showPrivateCheckbox
            title={showTitle}
          />
        </Route>
      </>
    )
  }
