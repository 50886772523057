import {Switch, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {
  Control,
  Controller,
  FieldValues,
  FormContextValues
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {FormFieldName} from './types'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export interface IFormSwitchInputProps<
  FormValues extends FieldValues = FieldValues
> {
  name: FormFieldName<FormValues>
  control: Control<FormValues>
  watch: FormContextValues<FormValues>['watch']
  className?: string
  enabledText?: string
  disabledText?: string
  defaultValue: boolean
  disabled?: boolean
}

export const FormSwitchInput = <FormValues extends FieldValues = FieldValues>({
  name,
  control,
  watch,
  className,
  defaultValue,
  disabled,
  ...rest
}: IFormSwitchInputProps<FormValues>) => {
  const value = watch(name)
  const {t} = useTranslation()
  const {enabledText = t('Enabled'), disabledText = t('Disabled')} = rest
  const classes = useStyles()
  return (
    <div className={cn(classes.root, className)}>
      <Typography variant="body2" align="right">
        {value ? enabledText : disabledText}
      </Typography>
      <Controller
        as={<Switch color="primary" disabled={disabled} />}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
    </div>
  )
}
