import {useMutation} from '@apollo/react-hooks'
import DeleteIcon from '@mui/icons-material/Delete'
import {Box} from '@mui/material'
import {styled} from '@mui/system'
import {isNil} from 'lodash'
import React, {useCallback} from 'react'
import {FormContextValues, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  Maybe,
  PermissionCode,
  TourGeneralPageQuery,
  TourState
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {
  useIsNonNegativeInteger,
  useIsPositiveInteger,
  useIsStringWithMaxLength
} from '../../../../../../utils/formsValidations'
import {routeTo} from '../../../../../../utils/routes'
import {
  ButtonWithConfirmationDialog,
  InputBlock,
  InputRow
} from '../../../../../common'
import {SaveButton} from '../../../../../common/Buttons'
import {MenuItem} from '../../../../../common/Menu'
import {FormAutocomplete} from '../../../../../form/FormAutocomplete'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {SplitButton} from '../../../../../visual'
import {BottomBarActionsWrapper, CenteredLayout} from '../../../Layout'
import {TourEntranceFormPart} from '../../TourEntranceFormPart'
import {TourPurchaseFormPart} from '../../TourPurchaseFormPart'
import {TourReservationFormPart} from '../../TourReservationFormPart'
import {ITourDivisionSettingsForm} from '../../types'
import {GeneralSection} from './GeneralSection'
import {DELETE_TOUR_MUTATION, UPDATE_TOUR_MUTATION} from './graphql'
import {
  IUpdateTourForm,
  TourField,
  transformDivisionSettingsToUpdateForm,
  transformUpdateTourFormIntoInput
} from './types'

const Form = styled('form')`
  height: 100%;
`

const UPDATE_TOUR_FORM_ID = 'update_tour_form_id'

interface IUpdateTourFormProps {
  data: TourGeneralPageQuery
}

const getDefaultValueForNonRequiredInt = (num?: Maybe<number>) =>
  isNil(num) ? undefined : String(num)

export const UpdateTourForm: React.FC<IUpdateTourFormProps> = ({
  data: {tour, venues}
}: IUpdateTourFormProps) => {
  const {t} = useTranslation()
  const divisionSettingsDefaultValues =
    transformDivisionSettingsToUpdateForm(tour)
  const {
    register,
    errors,
    setValue,
    handleSubmit,
    triggerValidation,
    watch,
    control,
    unregister,
    clearError,
    setError
  } = useForm<IUpdateTourForm>({
    defaultValues: {
      [TourField.Name]: tour.name,
      [TourField.InternalNote]: tour.internalNote,
      [TourField.Duration]: String(tour.duration),
      [TourField.RetailAttendeesLimit]: getDefaultValueForNonRequiredInt(
        tour.retailAttendeesLimit
      ),
      [TourField.ECommerceAttendeesLimit]: getDefaultValueForNonRequiredInt(
        tour.eCommerceAttendeesLimit
      ),
      [TourField.ECommerceOrderAttendeesLimit]:
        getDefaultValueForNonRequiredInt(tour.eCommerceOrderAttendeesLimit),
      [TourField.VenueId]: tour.venueId ? String(tour.venueId) : null,
      ...divisionSettingsDefaultValues
    }
  })
  const isStringWithMaxLength255 = useIsStringWithMaxLength(255)
  const isStringWithMaxLength1000 = useIsStringWithMaxLength(1000)
  const isPositiveInteger = useIsPositiveInteger()
  const isNonNegativeInteger = useIsNonNegativeInteger()

  const [deleteTour] = useMutation(DELETE_TOUR_MUTATION)

  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()

  const history = useHistory()

  const handleDeleteConfirm = useCallback(async () => {
    try {
      setShowBackdrop(true)
      await deleteTour({variables: {id: tour.id}})
      addInfoNotification(t('Tour deleted'))
      history.replace(routeTo.admin.tours.index())
    } catch (e) {
      defaultErrorHandler(e, t('Deleting tour failed'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    tour.id,
    defaultErrorHandler,
    deleteTour,
    history,
    setShowBackdrop,
    t
  ])

  const [updateTour] = useMutation(UPDATE_TOUR_MUTATION)

  const getUpdateTourHandler = useCallback(
    ({id, state}: {id: number; state: TourState}) =>
      async (form: IUpdateTourForm) => {
        try {
          setShowBackdrop(true)
          await updateTour({
            variables: {
              id,
              input: transformUpdateTourFormIntoInput(form, state)
            }
          })
          addInfoNotification(t('Tour has been updated'))
        } catch (e) {
          defaultErrorHandler(e, t('Update of tour failed'))
        } finally {
          setShowBackdrop(false)
        }
      },
    [addInfoNotification, defaultErrorHandler, setShowBackdrop, t, updateTour]
  )

  const {P} = useEnsurePermissions()
  const hasUpdatePermission = P([PermissionCode.UpdateTour])
  const canDelete =
    P([PermissionCode.DeleteTour]) && tour.state === TourState.Draft

  return (
    <Form
      id={UPDATE_TOUR_FORM_ID}
      onSubmit={handleSubmit(getUpdateTourHandler(tour))}
    >
      <CenteredLayout
        bottomBar={
          (hasUpdatePermission || canDelete) && (
            <BottomBarActionsWrapper
              sx={{height: 57, backgroundColor: 'background.paper'}}
            >
              {canDelete && (
                <ButtonWithConfirmationDialog
                  onConfirmButtonClick={handleDeleteConfirm}
                  buttonProps={{
                    children: t('Delete'),
                    variant: 'text',
                    startIcon: <DeleteIcon />
                  }}
                  dialogProps={{
                    title: t('Delete tour?'),
                    contentText: t(
                      'You are about to delete this tour. This action is irreversible and will permanently erase all associated information. Please confirm that this is what you intend to do before proceeding.'
                    ),
                    confirmButtonLabel: t('Delete')
                  }}
                />
              )}
              {hasUpdatePermission && (
                <SplitButton
                  Options={[
                    ...(tour.state !== TourState.Active
                      ? [
                          <MenuItem
                            key="save-as-active"
                            label={t('Save as active')}
                            onClick={handleSubmit(
                              getUpdateTourHandler({
                                id: tour.id,
                                state: TourState.Active
                              })
                            )}
                          />
                        ]
                      : []),
                    ...(tour.state !== TourState.Inactive
                      ? [
                          <MenuItem
                            key="save-as-inactive"
                            label={t('Save as inactive')}
                            onClick={handleSubmit(
                              getUpdateTourHandler({
                                id: tour.id,
                                state: TourState.Inactive
                              })
                            )}
                          />
                        ]
                      : [])
                  ]}
                >
                  <SaveButton form={UPDATE_TOUR_FORM_ID} type="submit" />
                </SplitButton>
              )}
            </BottomBarActionsWrapper>
          )
        }
      >
        <Box sx={{py: 3}}>
          <GeneralSection tour={tour} />
          {hasUpdatePermission && (
            <>
              <InputBlock header={t('Internal descriptions')}>
                <InputRow
                  nodes={[
                    <UncontrolledFormTextInput<IUpdateTourForm>
                      key={TourField.Name}
                      label={t('Name')}
                      name={TourField.Name}
                      validationOptions={{
                        required: true,
                        validate: {
                          isStringWithMaxLength255
                        }
                      }}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      triggerValidation={triggerValidation}
                      watch={watch}
                      required
                      fullWidth
                    />
                  ]}
                />
                <InputRow
                  nodes={[
                    <UncontrolledFormTextInput<IUpdateTourForm>
                      key={TourField.InternalNote}
                      label={t('Internal note')}
                      name={TourField.InternalNote}
                      validationOptions={{
                        validate: {
                          isStringWithMaxLength1000
                        }
                      }}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      triggerValidation={triggerValidation}
                      watch={watch}
                      fullWidth
                      multiline
                      rows={3}
                    />
                  ]}
                />
              </InputBlock>
              <InputBlock header={t('Default values')}>
                <InputRow
                  nodes={[
                    <UncontrolledFormTextInput<IUpdateTourForm>
                      key={TourField.Duration}
                      label={t('Duration')}
                      name={TourField.Duration}
                      validationOptions={{
                        required: true,
                        validate: {
                          isPositiveInteger
                        }
                      }}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      inputMode="numeric"
                      triggerValidation={triggerValidation}
                      watch={watch}
                      fullWidth
                      required
                    />,
                    <UncontrolledFormTextInput<IUpdateTourForm>
                      key={TourField.RetailAttendeesLimit}
                      label={t('Retail limit')}
                      name={TourField.RetailAttendeesLimit}
                      validationOptions={{
                        validate: {
                          isNonNegativeInteger
                        }
                      }}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      inputMode="numeric"
                      triggerValidation={triggerValidation}
                      watch={watch}
                      fullWidth
                    />
                  ]}
                />
                <InputRow
                  nodes={[
                    <UncontrolledFormTextInput<IUpdateTourForm>
                      key={TourField.ECommerceAttendeesLimit}
                      label={t('Ecommerce limit')}
                      name={TourField.ECommerceAttendeesLimit}
                      validationOptions={{
                        validate: {
                          isNonNegativeInteger
                        }
                      }}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      inputMode="numeric"
                      triggerValidation={triggerValidation}
                      watch={watch}
                      fullWidth
                    />,
                    <UncontrolledFormTextInput<IUpdateTourForm>
                      key={TourField.ECommerceOrderAttendeesLimit}
                      label={t('Limit per ecommerce order')}
                      name={TourField.ECommerceOrderAttendeesLimit}
                      validationOptions={{
                        validate: {
                          isNonNegativeInteger
                        }
                      }}
                      register={register}
                      errors={errors}
                      setValue={setValue}
                      inputMode="numeric"
                      triggerValidation={triggerValidation}
                      watch={watch}
                      fullWidth
                    />
                  ]}
                />
                <InputRow
                  nodes={[
                    <FormAutocomplete<IUpdateTourForm>
                      noOptionsText={t('No venue found')}
                      fullWidth
                      errors={errors}
                      label={t('Venue')}
                      name={TourField.VenueId}
                      key={TourField.VenueId}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      autocompleteOptions={venues
                        .map((v) => ({
                          ...v,
                          name: [v.name, v.address.town].join(', ')
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(({id, name}) => ({
                          value: String(id),
                          name
                        }))}
                    />
                  ]}
                />
              </InputBlock>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pt: 5,
                  gap: 5
                }}
              >
                <TourPurchaseFormPart
                  control={control}
                  watch={watch}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                  unregister={unregister}
                  clearError={clearError}
                  setError={
                    setError as unknown as FormContextValues<ITourDivisionSettingsForm>['setError']
                  }
                  defaultValues={divisionSettingsDefaultValues}
                />
                <TourReservationFormPart
                  control={control}
                  watch={watch}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                  unregister={unregister}
                  clearError={clearError}
                  setError={
                    setError as unknown as FormContextValues<ITourDivisionSettingsForm>['setError']
                  }
                  defaultValues={divisionSettingsDefaultValues}
                />
                <TourEntranceFormPart
                  control={control}
                  watch={watch}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  triggerValidation={triggerValidation}
                  unregister={unregister}
                  clearError={clearError}
                  setError={
                    setError as unknown as FormContextValues<ITourDivisionSettingsForm>['setError']
                  }
                />
              </Box>
            </>
          )}
        </Box>
      </CenteredLayout>
    </Form>
  )
}
