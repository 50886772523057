import {ApolloError} from 'apollo-client'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorMessages, Maybe} from '../__generated__/schema'
import {
  INotificationWithCustomErrorDialog,
  useNotifications
} from '../components/context/notifications'
import {reportErrorToSentry} from './sentry'

export const getGraphQLErrorRelatedToErrorMessage = (
  error: Maybe<ApolloError>,
  errorMessage: ErrorMessages
) => error?.graphQLErrors?.find((e) => e.message === errorMessage)

export const isNoInternetConnectionError = (error: ApolloError) =>
  Boolean(
    error.networkError &&
      // @ts-ignore
      navigator.connection?.rtt === 0
  )

export const isWithoutConnectivityToServer = (error: ApolloError) =>
  Boolean(
    error.networkError &&
      // @ts-ignore
      navigator.connection?.rtt > 0
  )

export const isNotAuthenticatedError = (error: ApolloError): boolean =>
  Boolean(
    getGraphQLErrorRelatedToErrorMessage(error, ErrorMessages.NotAuthenticated)
  )

export const isWithoutPermissionError = (error: ApolloError): boolean =>
  Boolean(
    getGraphQLErrorRelatedToErrorMessage(error, ErrorMessages.MissingPermission)
  )

export const useDefaultErrorHandler = () => {
  const {addErrorNotification, addErrorWithCustomDialogNotification} =
    useNotifications()
  const {t} = useTranslation()

  return useCallback(
    (error: ApolloError, message: string, subText?: string) => {
      if (isNoInternetConnectionError(error)) {
        addErrorWithCustomDialogNotification({
          title: t('No internet connection'),
          contentText: t(
            'Try to check your network cables, modem and routers or reconnect to your wireless network. Please, have in mind, that page reload may cause loosing data, that you have changed in this process.'
          ),
          confirmButtonLabel: t('Reload'),
          onConfirm: () => {
            window.location.reload()
          }
        })
      } else if (isWithoutConnectivityToServer(error)) {
        addErrorWithCustomDialogNotification({
          title: t('Something went wrong'),
          contentText: t(
            'The application has encountered an unknown error. Our technical staff have been automatically notified and will be looking into this with the utmost urgency.'
          ),
          confirmButtonLabel: t('Reload'),
          onConfirm: () => {
            window.location.reload()
          }
        })
      } else if (isNotAuthenticatedError(error)) {
        addErrorWithCustomDialogNotification({
          title: t('You are not logged in'),
          contentText: t(
            'You are not logged in or because of long inactivity you were automatically logged out by system. Please, try to log in again.'
          ),
          confirmButtonLabel: t('Log in'),
          onConfirm: () => {
            window.location.reload()
          }
        })
      } else if (isWithoutPermissionError(error)) {
        addErrorWithCustomDialogNotification({
          title: t('You are not permitted'),
          contentText: t(
            'Your account permissions does’t allow you to perform this action or access to such an information.'
          ),
          confirmButtonLabel: t('Got it')
        })
      } else {
        addErrorNotification(message, subText)
      }
      console.error(message, error) // eslint-disable-line no-console
      reportErrorToSentry(error, message)
    },
    [addErrorNotification, addErrorWithCustomDialogNotification, t]
  )
}

export const useCustomErrorHandler = () => {
  const {addErrorWithCustomDialogNotification} = useNotifications()
  return useCallback(
    (
      error: ApolloError,
      dialogProps: INotificationWithCustomErrorDialog['dialogProps']
    ) => {
      addErrorWithCustomDialogNotification(dialogProps)
      console.error(dialogProps, error) // eslint-disable-line no-console
      reportErrorToSentry(error, dialogProps.title)
    },
    [addErrorWithCustomDialogNotification]
  )
}
