import {useCallback, useMemo} from 'react'
import {ShowGenreCode} from '../../../../../../__generated__/schema'
import {useTranslateGenre} from '../../../../../../hooks/showGenres'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {getFilteredItems} from '../../../../../common/generalFilter/utils'

export const useShowGenreCodes = () => {
  const translateShowGenre = useTranslateGenre()
  const allShowGenreCodeItems: IIdListItem<ShowGenreCode>[] = useMemo(
    () =>
      Object.values(ShowGenreCode).map((c) => ({
        id: c,
        firstRow: translateShowGenre(c)
      })),
    [translateShowGenre]
  )
  const getShowGenreCodeItems = useCallback(
    (hasText?: string) => {
      return Promise.resolve(getFilteredItems(allShowGenreCodeItems, hasText))
    },
    [allShowGenreCodeItems]
  )
  const getShowGenreCodeItemsByIds = useCallback(
    (codes: ShowGenreCode[]) => {
      return Promise.resolve(
        allShowGenreCodeItems.filter((c) => codes.includes(c.id))
      )
    },
    [allShowGenreCodeItems]
  )
  return {
    getShowGenreCodeItems,
    getShowGenreCodeItemsByIds
  }
}
