import _ from 'lodash'
import React, {ReactNode, useMemo} from 'react'
import {Layer, Rect} from 'react-konva'

import {useSelector} from '../redux'
import {canvasSelector} from '../redux/canvas/selectors'
import {rectangleToCanvasCoords} from '../utils/common'

interface IEventLayerProps {
  children: ReactNode
  [prop: string]: any
}

const EventLayerFn: React.FC<IEventLayerProps> = ({
  children,
  ...otherProps
}: IEventLayerProps) => {
  const {dimensions, origin, scale} = useSelector(canvasSelector)

  const auxiliaryRectangle = useMemo(() => {
    return rectangleToCanvasCoords({...dimensions, x: 0, y: 0}, origin, scale)
  }, [dimensions, origin, scale])

  return (
    <Layer {...otherProps}>
      {/* All events are started from Shapes. So if you click on an empty space within a canvas,
      a click event will not trigger on Layer but it will trigger on the Stage object instead.
      Therefore we need the following shape - auxiliary rectangle in order to catch events and
      propagate them to layer. */}
      <Rect {...auxiliaryRectangle} />
      {children}
    </Layer>
  )
}

export const EventLayer = React.memo(EventLayerFn, _.isEqual)
