import {SeatShape} from '@attendio/shared-components'
import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ISeatStateValue} from '../../shared/editor/stateTypes'
import {PRIMARY_50_COLOR, Theme} from '../../theme'
import {useObjectsActions} from '../redux/objects/actions'
import {
  ClassicSeatIcon,
  InvalidSeatIcon,
  LeftSeatIcon,
  MiddleSeatIcon,
  RightSeatIcon,
  RoundSeatIcon
} from './Icons'
import {usePlaceholderAndValue} from './placeholderAndValue'

const items = [
  {
    value: SeatShape.Classic,
    node: <ClassicSeatIcon />,
    cypressId: 'classic-seat-icon'
  },
  {
    value: SeatShape.Left,
    node: <LeftSeatIcon />,
    cypressId: 'left-seat-icon'
  },
  {
    value: SeatShape.Middle,
    node: <MiddleSeatIcon />,
    cypressId: 'middle-seat-icon'
  },
  {
    value: SeatShape.Right,
    node: <RightSeatIcon />,
    cypressId: 'right-seat-icon'
  },
  {
    value: SeatShape.Invalid,
    node: <InvalidSeatIcon />,
    cypressId: 'invalid-seat-icon'
  },
  {
    value: SeatShape.Round,
    node: <RoundSeatIcon />,
    cypressId: 'round-seat-icon'
  }
]

const useStyles = makeStyles((theme: Theme) => ({
  groupRoot: {
    paddingTop: theme.spacing(1),
    display: 'inline-grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'column'
  },
  groupGrouped: {
    border: 'none'
  },
  buttonRoot: {
    height: 32,
    width: 32,
    border: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& path': {
      fill: 'white',
      stroke: theme.palette.grey[500]
    },
    '& .svgicons-svg-inside': {
      fill: theme.palette.grey[500],
      strokeWidth: 0
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& path': {
        fill: PRIMARY_50_COLOR,
        stroke: theme.palette.primary.main
      },
      '& .svgicons-svg-inside': {
        fill: theme.palette.primary.main
      },
      '& .svgicons-svg-border': {
        strokeWidth: 2
      }
    }
  }
}))

interface ISeatShapeToggleButtonGroupProps {
  seatStateObjects: ISeatStateValue[]
}

export const SeatShapeToggleButtonGroup: React.FC<ISeatShapeToggleButtonGroupProps> =
  ({seatStateObjects}: ISeatShapeToggleButtonGroupProps) => {
    const {t} = useTranslation()
    const {updateObjects} = useObjectsActions()
    const {value} = usePlaceholderAndValue({
      selectedObjects: seatStateObjects,
      iteratee: 'config.seatShape'
    })
    const handleChange = useCallback(
      (e, seatShape?: SeatShape) => {
        if (seatShape) {
          updateObjects(
            seatStateObjects.map(
              <T extends ISeatStateValue>(object: T): T => ({
                ...object,
                config: {
                  ...object.config,
                  seatShape
                }
              })
            )
          )
        }
      },
      [seatStateObjects, updateObjects]
    )
    const classes = useStyles()
    return (
      <div>
        <Typography variant="body2">{t('Seat shape')}</Typography>
        <ToggleButtonGroup
          exclusive
          onChange={handleChange}
          value={value}
          classes={{grouped: classes.groupGrouped, root: classes.groupRoot}}
        >
          {items.map((item) => (
            <ToggleButton
              key={item.value}
              value={item.value}
              classes={{root: classes.buttonRoot}}
              cypress-id={item.cypressId}
            >
              {item.node}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    )
  }
