import AddIcon from '@mui/icons-material/Add'
import {Box, Button, SxProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../theme'
import {AddSectionDialog} from './AddSectionDialog'
import {
  GeneralFilterActions,
  GeneralFilterActionTypes,
  GeneralFilterState
} from './reducer'
import {SpecialCases} from './SpecialCases'
import {IFilterKeyOption} from './types'

interface IGeneralFilterProps<T extends object> {
  sx?: SxProps<Theme>
  children: React.ReactNode
  secondStepChildren: React.ReactNode
  generalFilter: GeneralFilterState<T>
  dispatch: React.Dispatch<GeneralFilterActions<T>>
  filterKeyOptions: IFilterKeyOption<T>[]
}

export const GeneralFilter = <T extends object>({
  sx,
  children,
  secondStepChildren,
  generalFilter,
  dispatch,
  filterKeyOptions
}: IGeneralFilterProps<T>) => {
  const {t} = useTranslation()
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, ...sx}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1">{t('Filter options')}</Typography>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={() =>
            dispatch({
              type: GeneralFilterActionTypes.OpenCreateSectionDialog
            })
          }
        >
          {t('Add')}
        </Button>
      </Box>
      {children}
      <SpecialCases<T>
        filter={generalFilter.filter}
        supportedKeys={filterKeyOptions.map(({key}) => key)}
      />
      <AddSectionDialog<T>
        generalFilter={generalFilter}
        filterKeyOptions={filterKeyOptions}
        secondStepChildren={secondStepChildren}
        dispatch={dispatch}
      />
    </Box>
  )
}
