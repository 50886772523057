import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Divider} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ClaimState, PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'

interface IActionButtonProps {
  label: string
  onButtonClick: () => void
  icon?: React.ReactNode
  hasPermission: boolean
  isDisabled?: boolean
}

const ActionButton: React.FC<IActionButtonProps> = ({
  label,
  onButtonClick,
  icon,
  hasPermission,
  isDisabled
}: IActionButtonProps) =>
  hasPermission ? (
    <Button
      onClick={onButtonClick}
      startIcon={icon}
      color="primary"
      disabled={isDisabled}
    >
      {label}
    </Button>
  ) : null

interface IFooterProps {
  claimState: ClaimState
  onDeleteButtonClick: () => void
  onApproveButtonClick: () => void
  onRefundButtonClick: () => void
  claimItemsLength: number
}

export const Footer: React.FC<IFooterProps> = ({
  claimState,
  onDeleteButtonClick,
  onApproveButtonClick,
  onRefundButtonClick,
  claimItemsLength
}: IFooterProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const hasDeleteClaimPermission = P([PermissionCode.DeleteClaim])
  const hasApproveClaimPermission = P([PermissionCode.ApproveClaim])
  const hasRefundClaimPermission =
    P([PermissionCode.RefundClaimOnRetailChannel]) ||
    P([PermissionCode.RefundClaimOnECommerceChannel])
  if (claimState === ClaimState.Draft) {
    return (
      <>
        <ActionButton
          label={t('Delete')}
          icon={<DeleteIcon />}
          onButtonClick={onDeleteButtonClick}
          hasPermission={hasDeleteClaimPermission}
        />
        {hasDeleteClaimPermission && hasApproveClaimPermission && (
          <Divider orientation="vertical" />
        )}
        <ActionButton
          label={t('Approve')}
          onButtonClick={onApproveButtonClick}
          hasPermission={hasApproveClaimPermission}
          isDisabled={claimItemsLength === 0}
        />
        <ActionButton
          label={t('Refund')}
          onButtonClick={onRefundButtonClick}
          hasPermission={hasRefundClaimPermission}
          isDisabled
        />
      </>
    )
  }
  if (claimState === ClaimState.Approved) {
    return (
      <>
        <ActionButton
          label={t('Delete')}
          icon={<DeleteIcon />}
          onButtonClick={onDeleteButtonClick}
          hasPermission={hasDeleteClaimPermission}
        />
        {hasDeleteClaimPermission && hasRefundClaimPermission && (
          <Divider orientation="vertical" />
        )}
        <ActionButton
          label={t('Refund')}
          onButtonClick={onRefundButtonClick}
          hasPermission={hasRefundClaimPermission}
          isDisabled={claimItemsLength === 0}
        />
      </>
    )
  }
  if (claimState === ClaimState.Pending) {
    return (
      <>
        <ActionButton
          label={t('Delete')}
          icon={<DeleteIcon />}
          onButtonClick={onDeleteButtonClick}
          hasPermission={hasDeleteClaimPermission}
          isDisabled
        />
        {hasDeleteClaimPermission && hasRefundClaimPermission && (
          <Divider orientation="vertical" />
        )}
        <ActionButton
          label={t('Refund')}
          onButtonClick={onRefundButtonClick}
          hasPermission={hasRefundClaimPermission}
          isDisabled
        />
      </>
    )
  }
  if (claimState === ClaimState.Failed) {
    return (
      <ActionButton
        label={t('Refund')}
        onButtonClick={onRefundButtonClick}
        hasPermission={hasRefundClaimPermission}
      />
    )
  }
  return null
}
