import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  TranslatedCurrency,
  useTranslatePrice
} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

interface ISummaryRowProps {
  count: number
  total: number
  className?: string
  currency: TranslatedCurrency
}

export const SummaryRow: React.FC<ISummaryRowProps> = ({
  count,
  total,
  currency,
  className
}: ISummaryRowProps) => {
  const {t} = useTranslation()
  const translatePrice = useTranslatePrice(currency)
  const classes = useStyles()
  return (
    <div className={cn(classes.root, className)}>
      <Typography color="textSecondary" variant="subtitle1">
        {t('Subtotal')}
      </Typography>
      <Typography align="right" color="textSecondary" variant="subtitle1">
        {`${t('pc', {count})}  •  ${translatePrice(total)}`}
      </Typography>
    </div>
  )
}
