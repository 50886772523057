// Inspired by: https://material-ui.com/components/buttons/#split-button

import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Tooltip
} from '@mui/material'
import Popper, {PopperPlacementType} from '@mui/material/Popper'
import {makeStyles} from '@mui/styles'
import React, {ReactNode, useCallback, useMemo, useRef, useState} from 'react'

import {Theme} from '../../theme'

const growStyle = (placement: PopperPlacementType) => {
  return {
    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
  }
}

const useStyles = makeStyles<Theme>((theme) => ({
  groupedText: {
    '&:not(:last-child)': {
      borderRight: 0
    }
  },
  grouped: {
    minWidth: 'auto',
    '&:not(:last-child)': {
      borderRadius: 4
    }
  },
  itemText: {
    fontSize: 12
  },
  arrowRoot: {
    paddingLeft: 0,
    marginLeft: -6,
    '&:hover': {
      background: 'none'
    }
  },
  root: {
    color: theme.palette.common.black,
    marginLeft: 2
  },
  selected: {
    color: theme.palette.primary.main,
    background: '#E1BEE7',
    '&:hover': {
      background: '#E1BEE7'
    }
  },
  popper: {
    // So that popper is also visible in drawer
    zIndex: theme.zIndex.modal + 1
  }
}))

type ItemBaseProps = Omit<ItemProps, 'tooltip'>

const ItemBase: React.FC<ItemBaseProps> = ({icon, label}: ItemBaseProps) => {
  const classes = useStyles()

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Box display="flex">{icon}</Box>
      </Grid>
      {label && (
        <Grid item className={classes.itemText}>
          {label}
        </Grid>
      )}
    </Grid>
  )
}

interface IItemOptions {
  icon: ReactNode
  label?: string
  tooltip?: string
  disabled?: boolean
  onClick?: () => void
  cypressId?: string
}

type ItemProps = Pick<IItemOptions, 'icon' | 'label' | 'tooltip'>

const Item: React.FC<ItemProps> = ({tooltip, ...rest}: ItemProps) =>
  tooltip ? (
    <Tooltip title={tooltip}>
      <Box>
        <ItemBase {...rest} />
      </Box>
    </Tooltip>
  ) : (
    <ItemBase {...rest} />
  )

interface IPanelSplitButtonProps {
  options: Array<IItemOptions | null>
  defaultSelectedIndex?: number
  disabled?: boolean
  showButtonLabel?: boolean
  selected?: boolean
  toggleButtonCypressId?: string
}

export const PanelSplitButton: React.FC<IPanelSplitButtonProps> = ({
  options,
  defaultSelectedIndex = 0,
  disabled = false,
  showButtonLabel = false,
  selected,
  toggleButtonCypressId
}: IPanelSplitButtonProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex)
  const classes = useStyles()

  const selectedOption = useMemo(() => {
    return options[selectedIndex]
  }, [options, selectedIndex])

  const onClick = useCallback(
    (selectedIndex: number) => {
      if (options) {
        const selectedOption = options[selectedIndex]

        if (selectedOption && selectedOption.onClick) {
          setOpen(false)
          selectedOption.onClick()
        }
      }
    },
    [options]
  )

  const onMenuItemClick = useCallback(
    (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedIndex(index)
      onClick(index)
    },
    [onClick]
  )

  const onToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const onClose = useCallback((event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }, [])

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          disabled={disabled}
          variant="text"
          ref={anchorRef}
          aria-label="split button"
          classes={{grouped: classes.grouped, groupedText: classes.groupedText}}
        >
          <Button
            size="small"
            onClick={() => {
              onClick(selectedIndex)
            }}
            classes={{
              root: selected
                ? `${classes.root} ${classes.selected}`
                : classes.root
            }}
          >
            <Item
              icon={selectedOption && selectedOption.icon}
              label={
                showButtonLabel && selectedOption
                  ? selectedOption.label
                  : undefined
              }
              tooltip={
                (selectedOption && selectedOption.tooltip) ||
                (!showButtonLabel && selectedOption
                  ? selectedOption.label
                  : undefined)
              }
            />
          </Button>
          <Button
            cypress-id={toggleButtonCypressId}
            size="small"
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={onToggle}
            classes={{
              root: selected
                ? `${classes.arrowRoot} ${classes.selected}`
                : classes.arrowRoot
            }}
          >
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          className={classes.popper}
        >
          {({TransitionProps, placement}) => (
            <Grow {...TransitionProps} style={growStyle(placement)}>
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={onClose}>
                  <MenuList>
                    {options.map((option, index) =>
                      option ? (
                        <MenuItem
                          key={index}
                          cypress-id={option.cypressId}
                          selected={index === selectedIndex}
                          onClick={(event) => onMenuItemClick(event, index)}
                          disabled={!!option.disabled}
                        >
                          <Item
                            icon={option.icon}
                            label={option.label}
                            tooltip={option.tooltip}
                          />
                        </MenuItem>
                      ) : (
                        <Divider />
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}
