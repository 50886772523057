import {has} from 'lodash'
import {CashDeskEventFragment} from '../../../../__generated__/schema'
import {PinnedEventPick} from '../types'

export const getIsAlmostSoldOut = (event: CashDeskEventFragment) => {
  return (
    event.availableSeatsCount !== 0 &&
    event.auditoriumLayout.capacity &&
    event.availableSeatsCount / event.auditoriumLayout.capacity <= 0.1
  )
}

export const isCashDeskEventFragment = (
  item: CashDeskEventFragment | PinnedEventPick
): item is CashDeskEventFragment => has(item, 'availableSeatsCount')

export const getFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.')
  return fileName.substring(lastDotIndex)
}

export const shortenFileName = (fileName: string, maxLength: number = 6) => {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return fileName
  }
  const name = fileName.substring(0, lastDotIndex)
  const extension = fileName.substring(lastDotIndex)
  if (name.length <= maxLength) {
    return fileName
  }
  const shortenedName = `${name.substring(0, 3)}...${name.substring(
    name.length - 1
  )}`
  return shortenedName + extension
}

export const isFileImage = (fileName: string) =>
  ['.png', '.jpeg', '.gif', '.svg', '.jpg'].includes(getFileExtension(fileName))

export const getFileNameFromUrl = (url: string) => {
  try {
    const urlObj = new URL(url)
    const path = urlObj.pathname
    return decodeURIComponent(path.substring(path.lastIndexOf('/') + 1))
  } catch (e) {
    return ''
  }
}
