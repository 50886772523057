import {LocaleCode} from './__generated__/schema'

export enum CurrencySignPosition {
  START = 'start',
  END = 'end'
}

export enum ZonePlanView {
  ListView = 'listView',
  SeatingPlan = 'seatingPlan'
}

export enum RegistrationOfSales {
  None = 'none',
  PortosEkasa = 'portosEkasa'
}

export enum ShopViewMode {
  ListView = 'listView',
  GridView = 'gridView',
  PriceLookupCodeInput = 'priceLookupCodeInput'
}

export enum TourTimeSlotViewMode {
  GridView = 'gridView',
  ListView = 'listView'
}

export enum CheckoutPaymentMethodsViewMode {
  ExpandedFirstGroup = 'expandedFirstGroup',
  DoNotExpandAnyGroup = 'doNotExpandAnyGroup'
}

export enum CashDrawerController {
  None = 'none',
  VirtuosNetworkController = 'virtuosNetworkController'
}

export enum CashDrawerOpenLocation {
  DontOpen = 'dontOpen',
  BeforeCheckout = 'beforeCheckout',
  AfterCheckout = 'afterCheckout'
}

export interface ICashDrawerSettings {
  openLocation: CashDrawerOpenLocation
  controller: CashDrawerController
  ipAddress: string | null
}

export interface ICustomerDisplayScreen {
  name: string
  top: number
  left: number
}

export interface ICustomerDisplaySettings {
  enabled: boolean
  windowManagementEnabled: boolean
  screen: ICustomerDisplayScreen | null
}

export interface IDirectTicketPrintSettings {
  enabled: boolean
  printer: string | null
}

export interface IPosTerminalSettings {
  enabled: boolean
  port: number | null
  paymentMethodId: number | null
}

export interface PosTerminalApiConfigResponse {
  // eslint-disable-next-line camelcase
  api_port: number
  terminal: {
    // eslint-disable-next-line camelcase
    message_protocol: string
    host: string
    port: number
    id?: string
    // eslint-disable-next-line camelcase
    initial_timeout: number
  }
}

export interface PosTerminalApiConfigSchemaResponse {
  properties: {
    terminal: {
      properties: {
        // eslint-disable-next-line camelcase
        message_protocol: {
          format: string[]
        }
      }
    }
  }
}

export enum PosTerminalCurrency {
  CZK = 203,
  EUR = 978,
  USD = 840,
  GBP = 826,
  RUB = 643
}
export interface PosTerminalApiTerminalSaleRequest {
  returnUrl?: string
  data: {
    amount: number
    language?: LocaleCode
    externalId?: string
    additionalNumericId?: number
    cashbackAmount?: number
    clientId?: number
    currency?: PosTerminalCurrency
  }
}

export interface IPosTerminalApiTerminalRepeatLastMessageRequest {
  returnUrl?: string
}

export enum PosTerminalApiSaleResponseState {
  succeeded = 'succeeded',
  failed = 'failed',
  cancelled = 'cancelled',
  error = 'error'
}
export interface PosTerminalApiTerminalSaleResponse {
  state?: PosTerminalApiSaleResponseState
  authorizationCode: string
  terminalId?: string
  rawPosResponse: {
    authorizationCode?: string
    cardNumber?: string
    responseCode?: string
    transaction?: string
    uniqTransId?: string
    transactionId?: string
    applicationId?: string
  }
}
