import {createSelector} from 'reselect'
import {IAppState} from '../index'

import {selectedObjectsIdsSelector} from '../selection/selectors'
import {CanvasObjectType} from '../types'
import {ObjectsStateValue} from './types'

export const presentObjectsSelector = (state: IAppState) =>
  state.undoable.present.objects

export const iconsSelector = createSelector(presentObjectsSelector, (objects) =>
  Object.values<ObjectsStateValue>(objects).filter(
    (object) => object.type === CanvasObjectType.Icon
  )
)

export const seatsSelector = createSelector(presentObjectsSelector, (objects) =>
  Object.values<ObjectsStateValue>(objects).filter(
    (object) => object.type === CanvasObjectType.Seat
  )
)

export const seatsIdsSelector = createSelector(seatsSelector, (seats) =>
  Object.values<ObjectsStateValue>(seats).map(({config}) => config.id)
)

export const shapesSelector = createSelector(
  presentObjectsSelector,
  (objects) =>
    Object.values<ObjectsStateValue>(objects).filter(
      (object) => object.type === CanvasObjectType.Shape
    )
)

export const textsSelector = createSelector(presentObjectsSelector, (objects) =>
  Object.values<ObjectsStateValue>(objects).filter(
    (object) => object.type === CanvasObjectType.Text
  )
)

export const zonesSelector = createSelector(presentObjectsSelector, (objects) =>
  Object.values<ObjectsStateValue>(objects).filter(
    (object) => object.type === CanvasObjectType.Zone
  )
)

const zonesIdsSelector = createSelector(zonesSelector, (zones) =>
  Object.values<ObjectsStateValue>(zones).map(({config}) => config.id)
)

export const priceAssignableObjectsIdsSelector = createSelector(
  seatsIdsSelector,
  zonesIdsSelector,
  (seatsIds, zonesIds) => {
    return [...zonesIds, ...seatsIds]
  }
)

// Note: The order matters due to z-index like positioning.
export const objectsIdsSelector = createSelector(
  iconsSelector,
  seatsIdsSelector,
  shapesSelector,
  textsSelector,
  zonesIdsSelector,
  (icons, seatsIds, shapes, texts, zonesIds) => {
    const iconsIds = Object.values<ObjectsStateValue>(icons).map(
      ({config}) => config.id
    )
    const shapesIds = Object.values<ObjectsStateValue>(shapes).map(
      ({config}) => config.id
    )
    const textsIds = Object.values<ObjectsStateValue>(texts).map(
      ({config}) => config.id
    )

    return [...shapesIds, ...zonesIds, ...iconsIds, ...textsIds, ...seatsIds]
  }
)

export const selectedObjectsSelector = createSelector(
  presentObjectsSelector,
  selectedObjectsIdsSelector,
  (objects, selectedObjectIds) => {
    return selectedObjectIds.map((id) => objects[id])
  }
)

export const unselectedObjectsIdsSelector = createSelector(
  objectsIdsSelector,
  selectedObjectsIdsSelector,
  (objectsIds, selectedObjectIds) => {
    return objectsIds.filter(
      (objectId) => !selectedObjectIds.includes(objectId)
    )
  }
)

export const isSomeObjectSelectedSelector = createSelector(
  selectedObjectsIdsSelector,
  (selectedObjectIds) => !!selectedObjectIds.length
)

export const isResizeEnabledSelector = createSelector(
  selectedObjectsSelector,
  (selectedObjects) => {
    if (selectedObjects.length !== 1) return false

    const {type} = selectedObjects[0]
    return type !== CanvasObjectType.Seat && type !== CanvasObjectType.Text
  }
)
