import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import {v4 as uuidv4} from 'uuid'
import {IConfirmationDialogProps} from '../common/ConfirmationDialog'

export enum NotificationType {
  ERROR = 'error',
  INFO = 'info',
  ErrorWithCustomDialog = 'ErrorWithCustomDialog'
}

export interface INotification {
  type: NotificationType.ERROR | NotificationType.INFO
  message: string
  subText?: string
  id: string
}

export interface INotificationWithCustomErrorDialog {
  type: NotificationType.ErrorWithCustomDialog
  id: string
  dialogProps: Pick<
    IConfirmationDialogProps,
    | 'title'
    | 'contentText'
    | 'confirmButtonLabel'
    | 'cancelButtonLabel'
    | 'onCancel'
  > & {
    onConfirm?: () => void
  }
}

interface INotificationContext {
  notifications: Array<INotification | INotificationWithCustomErrorDialog>
  addErrorNotification: (message: string, subText?: string) => void
  addInfoNotification: (message: string) => void
  addErrorWithCustomDialogNotification: (
    dialogProps: INotificationWithCustomErrorDialog['dialogProps']
  ) => void
  removeNotification: (id: string) => void
}

export const Context = React.createContext({} as INotificationContext)

interface IProps {
  children: ReactNode
}

export const NotificationsProvider: React.FC<IProps> = ({children}: IProps) => {
  const [notifications, setNotifications] = useState<
    Array<INotification | INotificationWithCustomErrorDialog>
  >([])

  const _addNotification = useCallback(
    (
      type: NotificationType.INFO | NotificationType.ERROR,
      message: string,
      subText?: string
    ) => {
      setNotifications((notifications) => [
        ...notifications,
        {id: uuidv4(), type, message, subText}
      ])
    },
    []
  )

  const addErrorWithCustomDialogNotification = useCallback(
    (dialogProps: INotificationWithCustomErrorDialog['dialogProps']) => {
      setNotifications((notifications) => [
        ...notifications,
        {
          id: uuidv4(),
          type: NotificationType.ErrorWithCustomDialog,
          dialogProps: {
            ...dialogProps,
            dialogProps: {
              maxWidth: 'sm',
              fullWidth: true
            }
          }
        }
      ])
    },
    []
  )

  const addErrorNotification = useCallback(
    (message: string, subText?: string) => {
      _addNotification(NotificationType.ERROR, message, subText)
    },
    [_addNotification]
  )

  const addInfoNotification = useCallback(
    (message: string) => {
      _addNotification(NotificationType.INFO, message)
    },
    [_addNotification]
  )

  const removeNotification = useCallback((id: string) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.id !== id)
    )
  }, [])

  const context = useMemo(
    () => ({
      addErrorWithCustomDialogNotification,
      notifications,
      addErrorNotification,
      addInfoNotification,
      removeNotification
    }),
    [
      addErrorWithCustomDialogNotification,
      addErrorNotification,
      addInfoNotification,
      notifications,
      removeNotification
    ]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useNotifications = () => useContext(Context)
