import {Dialog, DialogActions, DialogContent} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import {
  ApplicationRulePropertiesFragment,
  ApplicationRuleType,
  ConditionResource
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {DialogTitleWithCloseButton} from '../../../../common/DialogTitleWithCloseButton'
import {
  useCreateApplicationRuleWithCondition,
  useCreateCondition
} from '../graphql'
import {transformConditionFormValueToInputValue} from '../types'
import {SelectResourceStep} from './SelectResourceStep'
import {
  getAvailableOperatorsByResource,
  SelectValueAndOperatorStep
} from './SelectValueAndOperatorStep'
import {ConditionFormField, IConditionForm} from './types'

const CREATE_CONDITION_FORM = 'createConditionOperatorForm'

export enum ConditionDialogStep {
  SelectResource = 'selectResource',
  SelectValueAndOperator = 'selectValueAndOperator'
}

interface IConditionDialogProps {
  isOpen: boolean
  closeDialog(): void
  openApplicationRuleDetail(
    applicationRule: ApplicationRulePropertiesFragment
  ): void
  existingApplicationRule?: ApplicationRulePropertiesFragment
  type?: ApplicationRuleType
}

export const CreateConditionDialog: React.FC<IConditionDialogProps> = ({
  isOpen,
  closeDialog,
  openApplicationRuleDetail,
  existingApplicationRule,
  type
}: IConditionDialogProps) => {
  const params = useParams<{discountId: string}>()
  const discountId = parseInt(params.discountId, 10)
  const {t} = useTranslation()
  const [step, setStep] = useState<ConditionDialogStep>(
    ConditionDialogStep.SelectResource
  )
  const onNextButtonClick = useCallback(() => {
    setStep(ConditionDialogStep.SelectValueAndOperator)
  }, [])
  const onBackButtonClick = useCallback(() => {
    setStep(ConditionDialogStep.SelectResource)
  }, [])
  const [selectedResource, setSelectedResource] =
    useState<null | ConditionResource>(null)
  const handleResourceChange = useCallback((e) => {
    setSelectedResource(e.target.value as ConditionResource)
  }, [])
  const handleExited = useCallback(() => {
    setSelectedResource(null)
    setStep(ConditionDialogStep.SelectResource)
  }, [])

  const createApplicationRuleWithCondition =
    useCreateApplicationRuleWithCondition(discountId)

  const createCondition = useCreateCondition(discountId)

  const {defaultErrorHandler, setShowBackdrop, addInfoNotification} =
    useMutationAssistanceHooks()

  const handleSubmit = useCallback(
    async (form: IConditionForm) => {
      setShowBackdrop(true)
      try {
        if (!existingApplicationRule) {
          if (type) {
            const applicationRule = await createApplicationRuleWithCondition(
              {
                ...form,
                value: transformConditionFormValueToInputValue(form)
              },
              type
            )
            addInfoNotification(t('New rule with condition created'))
            openApplicationRuleDetail(applicationRule)
          }
        } else {
          const condition = await createCondition({
            ...form,
            value: transformConditionFormValueToInputValue(form),
            applicationRuleId: existingApplicationRule.id
          })
          addInfoNotification(t('Condition created'))
          openApplicationRuleDetail({
            ...existingApplicationRule,
            conditions: [...existingApplicationRule.conditions, condition]
          })
        }
        closeDialog()
      } catch (e) {
        defaultErrorHandler(e, t('Creating rule with condition failed'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeDialog,
      createApplicationRuleWithCondition,
      createCondition,
      defaultErrorHandler,
      existingApplicationRule,
      openApplicationRuleDetail,
      setShowBackdrop,
      t,
      type
    ]
  )

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      fullWidth
      TransitionProps={{
        onExited: handleExited
      }}
    >
      <DialogTitleWithCloseButton onCloseClick={closeDialog}>
        {t('New condition')}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        {step === ConditionDialogStep.SelectResource && (
          <SelectResourceStep
            selectedResource={selectedResource}
            onChange={handleResourceChange}
            type={type}
          />
        )}
        {step === ConditionDialogStep.SelectValueAndOperator &&
          selectedResource && (
            <SelectValueAndOperatorStep
              formId={CREATE_CONDITION_FORM}
              defaultValues={{
                [ConditionFormField.Resource]: selectedResource,
                [ConditionFormField.Operator]:
                  getAvailableOperatorsByResource(selectedResource)[0]
              }}
              onSubmit={handleSubmit}
            />
          )}
      </DialogContent>
      <DialogActions>
        {step === ConditionDialogStep.SelectResource ? (
          <SaveButton onClick={onNextButtonClick} disabled={!selectedResource}>
            {t('Next')}
          </SaveButton>
        ) : (
          <>
            <CancelButton onClick={onBackButtonClick}>{t('Back')}</CancelButton>
            <SaveButton type="submit" form={CREATE_CONDITION_FORM}>
              {t('Done')}
            </SaveButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
