import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../../../../theme'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {IntentFrame} from './IntentFrame'

import {IGroupIntentStructure, ITypeIntentStructure} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    overflow: 'visible'
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}))

interface IIntentFramesProps {
  structure: ITypeIntentStructure | IGroupIntentStructure
}

export const IntentFrames: React.FC<IIntentFramesProps> = ({
  structure
}: IIntentFramesProps) => {
  const classes = useStyles()
  return (
    <ListOfItemsSeparatedByDividers
      className={classes.root}
      DividerProps={{className: classes.divider}}
    >
      {structure.intentFrames.map((intentFrame) => (
        <IntentFrame
          key={intentFrame.key}
          intentFrame={intentFrame}
          structureKey={
            'type' in structure ? structure.type : structure.group.id
          }
        />
      ))}
    </ListOfItemsSeparatedByDividers>
  )
}
