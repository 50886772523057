import {InputAdornment} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {CurrencySignPosition} from '../types'

import {useRequireEffectiveClient} from './requireEffectiveClient'
import {useGetCurrencySignPosition} from './translateCurrencies'

export const usePiecesInputProps = () => {
  const effectiveClient = useRequireEffectiveClient()
  const {t} = useTranslation()
  const currencySignPosition = useGetCurrencySignPosition(
    effectiveClient.currency
  )
  return {
    [currencySignPosition === CurrencySignPosition.START
      ? 'startAdornment'
      : 'endAdornment']: (
      <InputAdornment position={currencySignPosition}>
        {t('Pcs')}
      </InputAdornment>
    )
  }
}
