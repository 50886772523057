import {Drawer} from '@mui/material'
import {isNumber} from 'lodash'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useBooleanState} from '../../../../../hooks/state'
import {useMessageParams} from '../../../../../utils/pathname'
import {
  DrawerSection,
  DrawerTemplateHeader,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {General} from '../../messages/General'
import {useGetMessage} from '../../messages/graphql'
import {Message} from '../../messages/Message'
import {Recipients} from '../../messages/Recipients'

const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      general: {id: string; label: string}
      message: {id: string; label: string}
      recipients: {id: string; label: string}
    } => ({
      general: {
        id: 'general',
        label: t('General')
      },
      message: {
        id: 'message',
        label: t('Message')
      },
      recipients: {
        id: 'recipients',
        label: t('Recipients')
      }
    }),
    [t]
  )
}

interface IMessageDetailDrawerProps {
  onExited: () => void
}

export const MessageDetailDrawer: React.FC<IMessageDetailDrawerProps> = ({
  onExited
}: IMessageDetailDrawerProps) => {
  const {t} = useTranslation()
  const {messageId} = useMessageParams()
  const {data, loading, error} = useGetMessage(messageId)
  const navigationItems = useNavigationItems()
  const drawerClasses = useDrawerWithSideNavigationStyles()
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    if (isNumber(messageId)) {
      openDrawer()
    }
  }, [messageId, openDrawer])
  return (
    <Drawer
      open={isOpen}
      onClose={closeDrawer}
      anchor="right"
      SlideProps={{
        onExited
      }}
      classes={drawerClasses}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          isLoading: loading,
          errorMessage: error && t('Loading message failed'),
          header: (
            <DrawerTemplateHeader
              title={t('Message #{{messageId}}', {messageId})}
              onLeftActionClick={closeDrawer}
            />
          )
        }}
        navigationItems={navigationItems}
      >
        {data?.message && (
          <>
            <DrawerSection id={navigationItems.general.id}>
              <General message={data.message} />
            </DrawerSection>
            <DrawerSection
              id={navigationItems.message.id}
              label={navigationItems.message.label}
            >
              <Message message={data.message} />
            </DrawerSection>
          </>
        )}
        {data?.message.leads && (
          <DrawerSection
            id={navigationItems.recipients.id}
            label={navigationItems.recipients.label}
            isWithoutPaper
          >
            <Recipients leads={data.message.leads} />
          </DrawerSection>
        )}
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
