import CancelIcon from '@mui/icons-material/Cancel'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../theme'
import {useIsValidEmail} from '../../utils/formsValidations'
import {UncontrolledFormTextInput} from '../form/FormTextInput'
import {CancelButton} from './Buttons'

export enum EmailDialogField {
  Email = 'email'
}

export interface IEmailDialogForm {
  [EmailDialogField.Email]: string
}

const EMAIL_DIALOG_FORM_ID = 'emailDialogFormId'

export interface IEmailDialogProps {
  isOpen: boolean
  title: string
  description?: string
  defaultValues?: Partial<IEmailDialogForm>
  inputLabel?: string
  inputPlaceholder?: string
  submitButtonStartIcon?: React.ReactElement
  submitButtonLabel: string
  onSubmit: (data: IEmailDialogForm) => void
  onCancel: () => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  dialogActions: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

export const EmailDialog: React.FC<IEmailDialogProps> = ({
  isOpen,
  title,
  description,
  defaultValues,
  inputLabel,
  inputPlaceholder,
  submitButtonLabel,
  submitButtonStartIcon,
  onSubmit,
  onCancel
}: IEmailDialogProps) => {
  const {t} = useTranslation()
  const isValidEmail = useIsValidEmail()
  const classes = useStyles()
  const {errors, watch, setValue, register, triggerValidation, handleSubmit} =
    useForm<IEmailDialogForm>({
      defaultValues
    })
  const value = watch(EmailDialogField.Email)
  const handleIconButtonClick = useCallback(() => {
    setValue(EmailDialogField.Email, '')
  }, [setValue])

  return (
    <form id={EMAIL_DIALOG_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={isOpen} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {description && (
            <DialogContentText sx={{pb: 2}}>{description}</DialogContentText>
          )}
          <Box sx={{pt: 1}}>
            <UncontrolledFormTextInput<IEmailDialogForm>
              autoFocus
              focused
              required
              fullWidth
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              inputMode="email"
              label={inputLabel}
              placeholder={inputPlaceholder}
              validationOptions={{required: true, validate: isValidEmail}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!value}
                      onClick={handleIconButtonClick}
                      edge="end"
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name={EmailDialogField.Email}
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CancelButton onClick={onCancel}>{t('Cancel')}</CancelButton>
          <Button
            color="primary"
            type="submit"
            form={EMAIL_DIALOG_FORM_ID}
            disabled={!value || !!isValidEmail(value)}
            {...(submitButtonStartIcon && {
              startIcon: submitButtonStartIcon
            })}
          >
            {submitButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
