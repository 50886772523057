import React, {useMemo} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  BusinessPartnerState,
  DetailEventPropertiesFragment,
  PermissionCode
} from '../../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../../utils/auth'
import {FormAutocomplete} from '../../../../../form/FormAutocomplete'
import {StackableCardDivider, StackableCardWrapper} from '../../../../../visual'
import {useGetEvent} from '../../graphql'
import {
  EventBasePropertyCard,
  EventFormFieldHeader,
  FormField,
  IMainEventPropertiesFormData,
  NO_BUSINESS_PARTNER_SELECTED,
  NO_EVENT_CATEGORY_SELECTED,
  NO_MARKETING_LABEL_SELECTED
} from './common'

interface IAnalyticsProps {
  event: DetailEventPropertiesFragment
}

export const Analytics: React.FC<IAnalyticsProps> = ({
  event
}: IAnalyticsProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {register, setValue, errors, watch} =
    useFormContext<IMainEventPropertiesFormData>()
  const {data} = useGetEvent(event.id, {
    fetchPolicy: 'cache-only'
  })
  const marketingLabelAutocompleteOptions = useMemo(
    () => [
      {
        value: NO_MARKETING_LABEL_SELECTED,
        name: t('None')
      },
      ...(data?.marketingLabels || [])
        .filter(
          (marketingLabel) =>
            marketingLabel.isActive ||
            marketingLabel.id === event.marketingLabelId
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((marketingLabel) => ({
          value: String(marketingLabel.id),
          name: marketingLabel.name
        }))
    ],
    [t, data?.marketingLabels, event.marketingLabelId]
  )
  const eventCategoryAutocompleteOptions = useMemo(
    () => [
      {
        value: NO_EVENT_CATEGORY_SELECTED,
        name: t('None')
      },
      ...(data?.eventCategories || [])
        .filter(
          (eventCategory) =>
            eventCategory.isActive || eventCategory.id === event.eventCategoryId
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((eventCategory) => ({
          value: String(eventCategory.id),
          name: eventCategory.name
        }))
    ],
    [t, data?.eventCategories, event.eventCategoryId]
  )
  const businessPartnerAutocompleteOptions = useMemo(
    () => [
      {
        value: NO_BUSINESS_PARTNER_SELECTED,
        name: t('None')
      },
      ...(data?.businessPartners.items || [])
        .filter(
          ({state, id}) =>
            state === BusinessPartnerState.Active ||
            id === event.businessPartnerId
        )
        .sort((a, b) => a.companyName.localeCompare(b.companyName))
        .map(({id, companyName}) => ({
          value: String(id),
          name: companyName
        }))
    ],
    [data?.businessPartners.items, event.businessPartnerId, t]
  )
  return (
    <StackableCardWrapper>
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader required={false} label={t('Marketing label')} />
        }
        right={
          <FormAutocomplete<IMainEventPropertiesFormData>
            label=""
            register={register}
            setValue={setValue}
            errors={errors}
            name={FormField.MARKETING_LABEL_ID}
            autocompleteOptions={marketingLabelAutocompleteOptions}
            noOptionsText={t('No marketing labels found')}
            disableClearable={false}
            watch={watch}
            fullWidth
          />
        }
      />
      <StackableCardDivider />
      <EventBasePropertyCard
        left={
          <EventFormFieldHeader required={false} label={t('Event category')} />
        }
        right={
          <FormAutocomplete<IMainEventPropertiesFormData>
            label=""
            register={register}
            setValue={setValue}
            errors={errors}
            name={FormField.EVENT_CATEGORY_ID}
            autocompleteOptions={eventCategoryAutocompleteOptions}
            noOptionsText={t('No event categories found')}
            disableClearable={false}
            watch={watch}
            fullWidth
          />
        }
      />
      {P([PermissionCode.ReadBusinessPartners]) && (
        <>
          <StackableCardDivider />
          <EventBasePropertyCard
            left={
              <EventFormFieldHeader
                required={false}
                label={t('Business partner')}
              />
            }
            right={
              <FormAutocomplete<IMainEventPropertiesFormData>
                label=""
                register={register}
                setValue={setValue}
                errors={errors}
                name={FormField.BUSINESS_PARTNER_ID}
                autocompleteOptions={businessPartnerAutocompleteOptions}
                noOptionsText={t('No business partners to show')}
                disableClearable={false}
                watch={watch}
                fullWidth
              />
            }
          />
        </>
      )}
    </StackableCardWrapper>
  )
}
