import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  PermissionCode,
  VoucherTransactionIntentsFilterInput
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {CartPreviewDrawer} from '../components/cartPreviewDrawer'
import {ClaimDetailDrawer} from '../components/claimDetailDrawer'
import {PrimaryHeader} from '../Header'
import {VoucherTransactionsList} from './VoucherTransactionsList'
import {VoucherTransactionsSearch} from './VoucherTransactionsSearch'

export const VoucherTransactionsPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] =
    useState<VoucherTransactionIntentsFilterInput>({})
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.voucherTransactions.index()),
    [history]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Voucher transactions')}
          search={
            <VoucherTransactionsSearch onFilterChange={setSearchFilter} />
          }
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <VoucherTransactionsList searchFilter={searchFilter} />
        {P([PermissionCode.ReadCart]) && (
          <Route
            path={routeTo.admin.voucherTransactions.cartDetail(':cartId')}
            exact
          >
            <CartPreviewDrawer onExited={handleExited} />
          </Route>
        )}
        {P([PermissionCode.ReadClaim]) && (
          <Route
            path={routeTo.admin.voucherTransactions.claimDetail(':claimId')}
            exact
          >
            <ClaimDetailDrawer onExited={handleExited} />
          </Route>
        )}
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
