import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetTicketTypesQuery,
  PermissionCode,
  TicketTypePricingPropertiesFragment
} from '../../../../../../__generated__/schema'

import {useEnsurePermissions, useUserInfo} from '../../../../../../utils/auth'
import {useVenuesPathnameParams} from '../../../../../../utils/pathname'
import {RenderOnData} from '../../../../../common'

import {getUnavailableColors} from './common'
import {
  TicketTypeDrawersStateProvider,
  useTicketTypeDrawersState
} from './drawerContext'
import {useGetTicketTypes} from './graphql'
import {CreateTicketTypeDrawer} from './list/CreateDrawer'

interface ITicketTypeSectionWrapperProps {
  children: (
    ticketTypes: Array<TicketTypePricingPropertiesFragment>
  ) => React.ReactNode | Array<React.ReactNode>
}

const TicketTypeSectionWrapperContent: React.FC<ITicketTypeSectionWrapperProps> =
  ({children}: ITicketTypeSectionWrapperProps) => {
    const {effectiveClient} = useUserInfo()
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()

    const {layoutPricingId} = useVenuesPathnameParams()
    const {data, loading, error} = useGetTicketTypes(layoutPricingId)

    const {isOpenCreateTicketTypeDrawer, closeCreateTicketTypeDrawer} =
      useTicketTypeDrawersState()

    if (!effectiveClient) return null

    return P([PermissionCode.ReadTicketTypes]) ? (
      <RenderOnData
        {...{loading, error, data}}
        errorMessage={t<string>('Could not load ticket types.')}
      >
        {(data: GetTicketTypesQuery) => {
          const {ticketTypes} = data
          const unavailableColors = getUnavailableColors(ticketTypes)
          return (
            <>
              {children(ticketTypes)}
              <CreateTicketTypeDrawer
                unavailableColors={unavailableColors}
                open={isOpenCreateTicketTypeDrawer}
                onClose={closeCreateTicketTypeDrawer}
              />
            </>
          )
        }}
      </RenderOnData>
    ) : null
  }

export const TicketTypeSectionWrapper = (
  props: ITicketTypeSectionWrapperProps
) => {
  return (
    <TicketTypeDrawersStateProvider>
      <TicketTypeSectionWrapperContent {...props} />
    </TicketTypeDrawersStateProvider>
  )
}
