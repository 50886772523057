import React, {useCallback} from 'react'
import {Route, useHistory} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {TourTimeSlotsDrawer} from '../../../../common/tourTimeSlotsDrawer'
import {usePostDisplayBaseViewMessageOnInit} from '../../customerDisplay/postDisplayBaseViewMessageOnInit'
import {Content} from './Content'

export const Tours: React.FC = () => {
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const handleExited = useCallback(
    () => history.push(routeTo.admin.cashDesk.tours()),
    [history]
  )
  usePostDisplayBaseViewMessageOnInit()
  return (
    <>
      <Content />
      {P([
        PermissionCode.ReadTourTimeSlots,
        PermissionCode.ReadTourTimeSlot
      ]) && (
        <Route path={routeTo.admin.cashDesk.tourTimeSlotsDrawer(':tourId')}>
          <TourTimeSlotsDrawer onExited={handleExited} />
        </Route>
      )}
    </>
  )
}
