import type {UniqueIdentifier} from '@dnd-kit/core'
import {AnimateLayoutChanges, useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import React, {CSSProperties} from 'react'

import {TreeItem} from './TreeItem'
import {ITreeItemProps} from './types'
import {iOS} from './utils'

interface ISortableTreeItemProps extends ITreeItemProps {
  id: UniqueIdentifier
}

const animateLayoutChanges: AnimateLayoutChanges = ({isSorting, wasDragging}) =>
  !(isSorting || wasDragging)

export const SortableTreeItem: React.FC<ISortableTreeItemProps> = ({
  id,
  depth,
  ...props
}: ISortableTreeItemProps) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition
  } = useSortable({
    id,
    animateLayoutChanges
  })
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition
  }

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners
      }}
      {...props}
    />
  )
}
