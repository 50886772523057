import AccessibleIcon from '@mui/icons-material/Accessible'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import BadgeIcon from '@mui/icons-material/Badge'
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby'
import BikeScooterIcon from '@mui/icons-material/BikeScooter'
import BlindIcon from '@mui/icons-material/Blind'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import ChildCareIcon from '@mui/icons-material/ChildCare'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import ElderlyIcon from '@mui/icons-material/Elderly'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FlagIcon from '@mui/icons-material/Flag'
import GroupsIcon from '@mui/icons-material/Groups'
import HandshakeIcon from '@mui/icons-material/Handshake'
import HeadsetIcon from '@mui/icons-material/Headset'
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import LuggageIcon from '@mui/icons-material/Luggage'
import ManIcon from '@mui/icons-material/Man'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import PedalBikeIcon from '@mui/icons-material/PedalBike'
import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import PetsIcon from '@mui/icons-material/Pets'
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import SchoolIcon from '@mui/icons-material/School'
import SellIcon from '@mui/icons-material/Sell'
import StarIcon from '@mui/icons-material/Star'
import StarsIcon from '@mui/icons-material/Stars'
import StrollerIcon from '@mui/icons-material/Stroller'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import TranslateIcon from '@mui/icons-material/Translate'
import VideocamIcon from '@mui/icons-material/Videocam'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'
import WcIcon from '@mui/icons-material/Wc'
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup'
import WomanIcon from '@mui/icons-material/Woman'
import {Box, SvgIconProps} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export enum AdmissionTypeIcon {
  ConfirmationNumber = 'confirmationNumber',
  Sell = 'sell',
  Person = 'person',
  ChildFriendly = 'childFriendly',
  Stroller = 'stroller',
  BedroomBaby = 'bedroomBaby',
  ChildCare = 'childCare',
  School = 'school',
  Elderly = 'elderly',
  AutoStories = 'autoStories',
  MenuBook = 'menuBook',
  ImportContacts = 'importContacts',
  CoPresent = 'coPresent',
  People = 'people',
  Groups = 'groups',
  Diversity3 = 'diversity3',
  Wc = 'wc',
  Man = 'man',
  Woman = 'woman',
  PregnantWoman = 'pregnantWoman',
  FamilyRestroom = 'familyRestroom',
  EscalatorWarning = 'escalatorWarning',
  SupervisorAccount = 'supervisorAccount',
  Handshake = 'handshake',
  VolunteerActivism = 'volunteerActivism',
  Favorite = 'favorite',
  LocalActivity = 'localActivity',
  Badge = 'badge',
  Star = 'star',
  Stars = 'stars',
  Accessible = 'accessible',
  Blind = 'blind',
  HearingDisabled = 'hearingDisabled',
  WheelchairPickup = 'wheelchairPickup',
  Pets = 'pets',
  CameraAlt = 'cameraAlt',
  Videocam = 'videocam',
  Flag = 'flag',
  Headset = 'headset',
  RecordVoiceOver = 'recordVoiceOver',
  Translate = 'translate',
  Luggage = 'luggage',
  PedalBike = 'pedalBike',
  BikeScooter = 'bikeScooter'
}

export const useTranslateAdmissionTypeIcon = () => {
  const {t} = useTranslation()
  return (icon: AdmissionTypeIcon) => {
    switch (icon) {
      case AdmissionTypeIcon.ConfirmationNumber:
        return t<string>('AdmissionTypeIcon->ConfirmationNumber')
      case AdmissionTypeIcon.Sell:
        return t<string>('AdmissionTypeIcon->Sell')
      case AdmissionTypeIcon.Person:
        return t<string>('AdmissionTypeIcon->Person')
      case AdmissionTypeIcon.ChildFriendly:
        return t<string>('AdmissionTypeIcon->ChildFriendly')
      case AdmissionTypeIcon.Stroller:
        return t<string>('AdmissionTypeIcon->Stroller')
      case AdmissionTypeIcon.BedroomBaby:
        return t<string>('AdmissionTypeIcon->BedroomBaby')
      case AdmissionTypeIcon.ChildCare:
        return t<string>('AdmissionTypeIcon->ChildCare')
      case AdmissionTypeIcon.School:
        return t<string>('AdmissionTypeIcon->School')
      case AdmissionTypeIcon.Elderly:
        return t<string>('AdmissionTypeIcon->Elderly')
      case AdmissionTypeIcon.AutoStories:
        return t<string>('AdmissionTypeIcon->AutoStories')
      case AdmissionTypeIcon.MenuBook:
        return t<string>('AdmissionTypeIcon->MenuBook')
      case AdmissionTypeIcon.ImportContacts:
        return t<string>('AdmissionTypeIcon->ImportContacts')
      case AdmissionTypeIcon.CoPresent:
        return t<string>('AdmissionTypeIcon->CoPresent')
      case AdmissionTypeIcon.People:
        return t<string>('AdmissionTypeIcon->People')
      case AdmissionTypeIcon.Groups:
        return t<string>('AdmissionTypeIcon->Groups')
      case AdmissionTypeIcon.Diversity3:
        return t<string>('AdmissionTypeIcon->Diversity3')
      case AdmissionTypeIcon.Wc:
        return t<string>('AdmissionTypeIcon->Wc')
      case AdmissionTypeIcon.Man:
        return t<string>('AdmissionTypeIcon->Man')
      case AdmissionTypeIcon.Woman:
        return t<string>('AdmissionTypeIcon->Woman')
      case AdmissionTypeIcon.PregnantWoman:
        return t<string>('AdmissionTypeIcon->PregnantWoman')
      case AdmissionTypeIcon.FamilyRestroom:
        return t<string>('AdmissionTypeIcon->FamilyRestroom')
      case AdmissionTypeIcon.EscalatorWarning:
        return t<string>('AdmissionTypeIcon->EscalatorWarning')
      case AdmissionTypeIcon.SupervisorAccount:
        return t<string>('AdmissionTypeIcon->SupervisorAccount')
      case AdmissionTypeIcon.Handshake:
        return t<string>('AdmissionTypeIcon->Handshake')
      case AdmissionTypeIcon.VolunteerActivism:
        return t<string>('AdmissionTypeIcon->VolunteerActivism')
      case AdmissionTypeIcon.Favorite:
        return t<string>('AdmissionTypeIcon->Favorite')
      case AdmissionTypeIcon.LocalActivity:
        return t<string>('AdmissionTypeIcon->LocalActivity')
      case AdmissionTypeIcon.Badge:
        return t<string>('AdmissionTypeIcon->Badge')
      case AdmissionTypeIcon.Star:
        return t<string>('AdmissionTypeIcon->Star')
      case AdmissionTypeIcon.Stars:
        return t<string>('AdmissionTypeIcon->Stars')
      case AdmissionTypeIcon.Accessible:
        return t<string>('AdmissionTypeIcon->Accessible')
      case AdmissionTypeIcon.Blind:
        return t<string>('AdmissionTypeIcon->Blind')
      case AdmissionTypeIcon.HearingDisabled:
        return t<string>('AdmissionTypeIcon->HearingDisabled')
      case AdmissionTypeIcon.WheelchairPickup:
        return t<string>('AdmissionTypeIcon->WheelchairPickup')
      case AdmissionTypeIcon.Pets:
        return t<string>('AdmissionTypeIcon->Pets')
      case AdmissionTypeIcon.CameraAlt:
        return t<string>('AdmissionTypeIcon->CameraAlt')
      case AdmissionTypeIcon.Videocam:
        return t<string>('AdmissionTypeIcon->Videocam')
      case AdmissionTypeIcon.Flag:
        return t<string>('AdmissionTypeIcon->Flag')
      case AdmissionTypeIcon.Headset:
        return t<string>('AdmissionTypeIcon->Headset')
      case AdmissionTypeIcon.RecordVoiceOver:
        return t<string>('AdmissionTypeIcon->RecordVoiceOver')
      case AdmissionTypeIcon.Translate:
        return t<string>('AdmissionTypeIcon->Translate')
      case AdmissionTypeIcon.Luggage:
        return t<string>('AdmissionTypeIcon->Luggage')
      case AdmissionTypeIcon.PedalBike:
        return t<string>('AdmissionTypeIcon->PedalBike')
      case AdmissionTypeIcon.BikeScooter:
        return t<string>('AdmissionTypeIcon->BikeScooter')
      default:
        return `Unknown icon ${icon}`
    }
  }
}

interface IAdmissionTypeSvgIconProps extends SvgIconProps {
  icon: AdmissionTypeIcon
}

export const getIcon = (icon: AdmissionTypeIcon) => {
  switch (icon) {
    case AdmissionTypeIcon.ConfirmationNumber:
      return ConfirmationNumberIcon
    case AdmissionTypeIcon.Sell:
      return SellIcon
    case AdmissionTypeIcon.Person:
      return PersonIcon
    case AdmissionTypeIcon.ChildFriendly:
      return ChildFriendlyIcon
    case AdmissionTypeIcon.Stroller:
      return StrollerIcon
    case AdmissionTypeIcon.BedroomBaby:
      return BedroomBabyIcon
    case AdmissionTypeIcon.ChildCare:
      return ChildCareIcon
    case AdmissionTypeIcon.School:
      return SchoolIcon
    case AdmissionTypeIcon.Elderly:
      return ElderlyIcon
    case AdmissionTypeIcon.AutoStories:
      return AutoStoriesIcon
    case AdmissionTypeIcon.MenuBook:
      return MenuBookIcon
    case AdmissionTypeIcon.ImportContacts:
      return ImportContactsIcon
    case AdmissionTypeIcon.CoPresent:
      return CoPresentIcon
    case AdmissionTypeIcon.People:
      return PeopleIcon
    case AdmissionTypeIcon.Groups:
      return GroupsIcon
    case AdmissionTypeIcon.Diversity3:
      return Diversity3Icon
    case AdmissionTypeIcon.Wc:
      return WcIcon
    case AdmissionTypeIcon.Man:
      return ManIcon
    case AdmissionTypeIcon.Woman:
      return WomanIcon
    case AdmissionTypeIcon.PregnantWoman:
      return PregnantWomanIcon
    case AdmissionTypeIcon.FamilyRestroom:
      return FamilyRestroomIcon
    case AdmissionTypeIcon.EscalatorWarning:
      return EscalatorWarningIcon
    case AdmissionTypeIcon.SupervisorAccount:
      return SupervisorAccountIcon
    case AdmissionTypeIcon.Handshake:
      return HandshakeIcon
    case AdmissionTypeIcon.VolunteerActivism:
      return VolunteerActivismIcon
    case AdmissionTypeIcon.Favorite:
      return FavoriteIcon
    case AdmissionTypeIcon.LocalActivity:
      return LocalActivityIcon
    case AdmissionTypeIcon.Badge:
      return BadgeIcon
    case AdmissionTypeIcon.Star:
      return StarIcon
    case AdmissionTypeIcon.Stars:
      return StarsIcon
    case AdmissionTypeIcon.Accessible:
      return AccessibleIcon
    case AdmissionTypeIcon.Blind:
      return BlindIcon
    case AdmissionTypeIcon.HearingDisabled:
      return HearingDisabledIcon
    case AdmissionTypeIcon.WheelchairPickup:
      return WheelchairPickupIcon
    case AdmissionTypeIcon.Pets:
      return PetsIcon
    case AdmissionTypeIcon.CameraAlt:
      return CameraAltIcon
    case AdmissionTypeIcon.Videocam:
      return VideocamIcon
    case AdmissionTypeIcon.Flag:
      return FlagIcon
    case AdmissionTypeIcon.Headset:
      return HeadsetIcon
    case AdmissionTypeIcon.RecordVoiceOver:
      return RecordVoiceOverIcon
    case AdmissionTypeIcon.Translate:
      return TranslateIcon
    case AdmissionTypeIcon.Luggage:
      return LuggageIcon
    case AdmissionTypeIcon.PedalBike:
      return PedalBikeIcon
    case AdmissionTypeIcon.BikeScooter:
      return BikeScooterIcon
    default:
      return QuestionMarkIcon
  }
}
export const AdmissionTypeSvgIcon: React.FC<IAdmissionTypeSvgIconProps> = ({
  icon,
  ...props
}: IAdmissionTypeSvgIconProps) => {
  const IconComp = getIcon(icon)
  return <IconComp {...props} />
}

export const useAdmissionTypeIconSelectOptions = () => {
  const translateAdmissionTypeIcon = useTranslateAdmissionTypeIcon()
  return Object.values(AdmissionTypeIcon).reduce(
    (acc, icon) => ({
      ...acc,
      [icon]: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5
          }}
        >
          <AdmissionTypeSvgIcon icon={icon} />
          {translateAdmissionTypeIcon(icon)}
        </Box>
      )
    }),
    {}
  )
}
