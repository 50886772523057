import CheckIcon from '@mui/icons-material/Check'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback} from 'react'
import {
  useTranslateDatePickerFutureValues,
  useTranslateDatePickerNowValues,
  useTranslateDatePickerPastValues
} from './translations'
import {
  AvailableDatePickerValues,
  TabFuture,
  TabGroup,
  TabNow,
  TabPast
} from './types'

const useStyles = makeStyles(() => ({
  listItemSecondary: {
    display: 'flex'
  }
}))

interface ITabContentProps {
  selectedValue?: AvailableDatePickerValues
  group: Exclude<TabGroup, TabGroup.Custom>
  setItem: (value: AvailableDatePickerValues | undefined) => void
  setTab: (value: TabGroup) => void
  excludedValues?: AvailableDatePickerValues[]
}

export const TabContent: React.FC<ITabContentProps> = ({
  selectedValue,
  group,
  setItem,
  setTab,
  excludedValues
}: ITabContentProps) => {
  const translateDatePickerFutureValues = useTranslateDatePickerFutureValues()
  const translateDatePickerNowValues = useTranslateDatePickerNowValues()
  const translateDatePickerPastValues = useTranslateDatePickerPastValues()
  const handleClick = useCallback(
    (group: TabGroup, tab: AvailableDatePickerValues) => () => {
      setTab(group)
      setItem(tab)
    },
    [setItem, setTab]
  )
  const classes = useStyles()
  switch (group) {
    case TabGroup.Past:
      return (
        <List>
          {Object.values(TabPast)
            .filter((value) => !excludedValues?.includes(value))
            .map((tab) => (
              <ListItem
                selected={selectedValue === tab}
                key={tab}
                onClick={handleClick(group, tab)}
                button
              >
                <ListItemText primary={translateDatePickerPastValues(tab)} />
                {selectedValue && selectedValue === tab && (
                  <ListItemSecondaryAction
                    className={classes.listItemSecondary}
                  >
                    <CheckIcon />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
        </List>
      )
    case TabGroup.Now:
      return (
        <List>
          {Object.values(TabNow)
            .filter((value) => !excludedValues?.includes(value))
            .map((tab) => (
              <ListItem
                selected={selectedValue === tab}
                key={tab}
                onClick={handleClick(group, tab)}
                button
              >
                <ListItemText primary={translateDatePickerNowValues(tab)} />
                {selectedValue && selectedValue === tab && (
                  <ListItemSecondaryAction
                    className={classes.listItemSecondary}
                  >
                    <CheckIcon />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
        </List>
      )
    case TabGroup.Future:
      return (
        <List>
          {Object.values(TabFuture)
            .filter((value) => !excludedValues?.includes(value))
            .map((tab) => (
              <ListItem
                selected={selectedValue === tab}
                key={tab}
                onClick={handleClick(group, tab)}
                button
              >
                <ListItemText primary={translateDatePickerFutureValues(tab)} />
                {selectedValue && selectedValue === tab && (
                  <ListItemSecondaryAction
                    className={classes.listItemSecondary}
                  >
                    <CheckIcon />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
        </List>
      )
    default:
      return null
  }
}
