import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import GetAppIcon from '@mui/icons-material/GetApp'
import {Box} from '@mui/material'
import {gridClasses} from '@mui/x-data-grid'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CombinedEventAndTourTimeSlotState,
  DiscountsStatisticsFilterInput,
  DiscountType,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslateDiscountApplication} from '../../../../hooks/discounts'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../utils/auth'
import {
  DataGridTable,
  DecimalCellRenderer,
  replaceDotWithCommaFormatter,
  useDataGridPagination
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {MoreMenu, SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ChipWithOptions} from '../components/ChipWithOptions'
import {DatePickerChip} from '../components/DatePickerChip'
import {ReloadButton} from '../components/ReloadButton'
import {UserChip} from '../components/UserChip'
import {getCellClassName} from '../eventsStatistics/Miscellaneous'
import {WideCenteredLayout} from '../Layout'
import {useGetFilterDateRange} from '../utils'
import {useDiscountsStatistics} from './graphql'

const useTypeFormatter = () => {
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  return useCallback(
    (params: GridValueFormatterParams) =>
      params.value
        ? params.value === DiscountType.Percentage
          ? '%'
          : translatedEffectiveClientCurrencySign
        : '',
    [translatedEffectiveClientCurrencySign]
  )
}

interface IDiscountsStatisticsListProps {
  searchFilter: DiscountsStatisticsFilterInput
}

export const DiscountsStatisticsList: React.FC<IDiscountsStatisticsListProps> =
  ({searchFilter}: IDiscountsStatisticsListProps) => {
    const {t} = useTranslation()
    const {P} = useEnsurePermissions()
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
    const [selectedDate, setSelectedDate] = useState<
      IDataPickerData | undefined
    >({group: TabGroup.Now, value: TabNow.Today})
    const [selectedState, setSelectedState] =
      useState<CombinedEventAndTourTimeSlotState | undefined>(undefined)
    const [selectedEventDate, setSelectedEventDate] =
      useState<IDataPickerData | undefined>(undefined)
    const getFilterDateRange = useGetFilterDateRange()
    const {paginationInput, getDataGridPaginationProps, resetPaginationModel} =
      useDataGridPagination()
    const {data, loading, error, refetch} = useDiscountsStatistics({
      paginationInput,
      filter: {
        ...searchFilter,
        paymentCreatedByIds: selectedUserId ? [selectedUserId] : undefined,
        states: selectedState
          ? [selectedState]
          : [
              CombinedEventAndTourTimeSlotState.Published,
              CombinedEventAndTourTimeSlotState.Canceled
            ],
        ...getFilterDateRange({
          date: selectedDate,
          filterNameFrom: 'paymentDateTimeFrom',
          filterNameTo: 'paymentDateTimeTo'
        }),
        ...getFilterDateRange({
          date: selectedEventDate,
          filterNameFrom: 'startsAtFrom',
          filterNameTo: 'startsAtTo'
        })
      }
    })
    const dataGridApiRef = useGridApiRef()
    const typeFormatter = useTypeFormatter()
    const translateDiscountApplication = useTranslateDiscountApplication()
    const handleUserChange = useCallback(
      (userId: number | null) => {
        setSelectedUserId(userId)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleDateSelect = useCallback(
      (date?: IDataPickerData) => {
        setSelectedDate(date)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleStateChange = useCallback(
      (state?: CombinedEventAndTourTimeSlotState) => {
        setSelectedState(state)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const handleEventDateChange = useCallback(
      (date?: IDataPickerData) => {
        setSelectedEventDate(date)
        resetPaginationModel()
      },
      [resetPaginationModel]
    )
    const columns: GridColDef[] = useMemo(
      () => [
        {
          headerName: t('Name'),
          field: 'name',
          minWidth: 250,
          sortable: false,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Value'),
          field: 'value',
          align: 'right',
          headerAlign: 'right',
          renderCell: function renderer(params: GridRenderCellParams) {
            return <DecimalCellRenderer params={params} />
          },
          valueFormatter: replaceDotWithCommaFormatter,
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('Type'),
          field: 'type',
          valueFormatter: typeFormatter,
          minWidth: 150,
          sortable: false
        },
        {
          headerName: t('Application'),
          field: 'application',
          valueFormatter: (params: GridValueFormatterParams) =>
            params.value ? translateDiscountApplication(params.value) : '',
          minWidth: 200,
          sortable: false
        },
        {
          headerName: t('Count'),
          field: 'discountApplicationCountTotal',
          renderCell: function renderer(params: GridRenderCellParams) {
            return <DecimalCellRenderer params={params} fractionDigits={0} />
          },
          valueFormatter: replaceDotWithCommaFormatter,
          align: 'right',
          headerAlign: 'right',
          minWidth: 150,
          sortable: false,
          cellClassName: getCellClassName
        },
        {
          headerName: t('Value'),
          field: 'discountedPriceTotal',
          align: 'right',
          headerAlign: 'right',
          renderCell: function renderer(params: GridRenderCellParams) {
            return <DecimalCellRenderer params={params} />
          },
          valueFormatter: replaceDotWithCommaFormatter,
          minWidth: 200,
          sortable: false,
          cellClassName: getCellClassName
        }
      ],
      [t, translateDiscountApplication, typeFormatter]
    )
    if (error) {
      return (
        <Error
          error={error}
          message={t('Error while loading discounts statistics')}
        />
      )
    }
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateRows: 'auto 1fr'
        }}
      >
        <SubHeaderToolbar
          title={t('Overview')}
          leftActions={[
            <ChipWithOptions<CombinedEventAndTourTimeSlotState>
              key="state-chip"
              selectedItem={selectedState}
              setSelectedItem={handleStateChange}
              options={[
                {
                  label: t('Published events and time slots'),
                  option: CombinedEventAndTourTimeSlotState.Published
                },
                {
                  label: t('Canceled events and time slots'),
                  option: CombinedEventAndTourTimeSlotState.Canceled
                }
              ]}
              allText={t('All events and time slots')}
            />,
            P([PermissionCode.ReadUsers]) && (
              <UserChip key="user-chip" onChange={handleUserChange} />
            ),
            <DatePickerChip
              key="event-date"
              tooltip={t('Select event date')}
              chipLabel={t('Event date')}
              selectedDate={selectedEventDate}
              setSelectedDate={handleEventDateChange}
              popperSx={(theme) => ({zIndex: theme.zIndex.drawer - 1})}
              icon={<CalendarTodayIcon />}
            />
          ].filter(Boolean)}
          rightActions={[
            <DatePickerButton
              key="payment-date-picker"
              tooltip={t('Payment date')}
              label={t('Payment date')}
              onDateSelect={handleDateSelect}
              selectedValues={selectedDate}
              groups={[TabGroup.Now, TabGroup.Past]}
              excludedValues={[TabNow.FromNow, TabNow.FromToday]}
              minDateFrom={
                selectedDate?.dateRange?.dateTo
                  ? dayjs(selectedDate?.dateRange?.dateTo)
                      .subtract(1, 'year')
                      .startOf('d')
                  : dayjs().subtract(1, 'year').startOf('d')
              }
              maxDateFrom={
                selectedDate?.dateRange?.dateTo || dayjs().endOf('d')
              }
              maxDateTo={dayjs().endOf('d')}
            />,
            <ReloadButton
              key="reload-button"
              onReloadButtonClick={() => refetch()}
            />,
            <MoreMenu
              key="more-menu"
              items={[
                {
                  label: t('Download CSV'),
                  icon: <GetAppIcon />,
                  onClick: () =>
                    dataGridApiRef.current.exportDataAsCsv({
                      fileName: t<string>('discounts-statistics')
                    })
                }
              ]}
            />
          ]}
        />
        <WideCenteredLayout
          sx={{
            height: '100%',
            width: '100%',
            p: 3
          }}
        >
          <DataGridTable
            sx={{
              [`& .${gridClasses.withBorderColor}`]: {
                borderColor: 'divider'
              },
              [`& .${gridClasses.columnSeparator}`]: {
                color: 'divider'
              },
              '& .bold': {fontWeight: 'bold'}
            }}
            apiRef={dataGridApiRef}
            columns={columns}
            loading={loading}
            rows={data?.discountsStatistics.items || []}
            disableColumnMenu
            disableRowSelectionOnClick
            localeText={{
              noRowsLabel: t('No discounts statistics found')
            }}
            initialState={{
              pinnedColumns: {left: ['name']}
            }}
            experimentalFeatures={{columnGrouping: true}}
            columnGroupingModel={[
              {
                groupId: 'discount',
                headerName: t('Discount'),
                children: [
                  {field: 'name'},
                  {field: 'value'},
                  {field: 'type'},
                  {field: 'application'}
                ]
              },
              {
                groupId: 'discountRedemption',
                headerName: t('Discount redemption'),
                children: [
                  {field: 'discountApplicationCountTotal'},
                  {field: 'discountedPriceTotal'}
                ]
              }
            ]}
            columnHeaderHeight={32}
            pinnedRows={{
              bottom: [
                {
                  id: 0,
                  name: t('Total'),
                  discountApplicationCountTotal:
                    data?.discountsStatistics.totals
                      .discountApplicationCountTotal,
                  discountedPriceTotal:
                    data?.discountsStatistics.totals.discountedPriceTotal
                }
              ]
            }}
            {...getDataGridPaginationProps(
              data?.discountsStatistics.pagination
            )}
          />
        </WideCenteredLayout>
      </Box>
    )
  }
