import {Box} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridCsvGetRowsToExportParams,
  gridFilteredSortedRowIdsSelector,
  GridRenderCellParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import {sortBy, sum} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventsStatisticsFilterInput,
  EventsStatisticsQuery,
  EventState,
  LocaleCode,
  PermissionCode
} from '../../../../__generated__/schema'
import {useTranslatedEffectiveClientCurrencySign} from '../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {safeSum} from '../../../../utils/money'
import {RenderOnData} from '../../../common'
import {
  DataGridTable,
  useAgeClassificationAbbreviationFormatter,
  useDecimalFormatter,
  useShowFormatAbbreviationFormatter,
  useSoundMixAbbreviationFormatter,
  useTranslatedValueGetter,
  useVersionCodeAbbreviationFormatter
} from '../../../common/DataGridTable'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {useGetFilterDateRange} from '../utils'
import {useGetEventsStatistics} from './graphql'
import {getCellClassName, IconCellRenderer} from './Miscellaneous'
import {Toolbar} from './Toolbar'

const formatData = (data: EventsStatisticsQuery['eventsStatistics']['items']) =>
  sortBy(data, 'startsAt').map((item) => ({
    ...item,
    salesQuantity: safeSum([
      item.saleTicketCountOnRetailChannel,
      item.saleTicketCountOnEcommerceChannel
    ]),
    salesAmount: safeSum([
      item.saleSumOnRetailChannel,
      item.saleSumOnEcommerceChannel
    ]),
    refundsQuantity: safeSum([
      item.refundTicketCountOnRetailChannel,
      item.refundTicketCountOnEcommerceChannel
    ]),
    refundsAmount: safeSum([
      item.refundSumOnRetailChannel,
      item.refundSumOnEcommerceChannel
    ])
  }))

interface IRevenuesPageProps {
  searchFilter: EventsStatisticsFilterInput
}

export const RevenuesPage: React.FC<IRevenuesPageProps> = ({
  searchFilter
}: IRevenuesPageProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const dataGridApiRef = useGridApiRef()
  const getFilterDateRange = useGetFilterDateRange()
  const translatedValueGetter = useTranslatedValueGetter()
  const {formatDateTime} = useDateTimeFormatters()
  const decimalFormatter = useDecimalFormatter()
  const translatedEffectiveClientCurrencySign =
    useTranslatedEffectiveClientCurrencySign()
  const showFormatAbbreviationFormatter = useShowFormatAbbreviationFormatter()
  const versionCodeAbbreviationFormatter = useVersionCodeAbbreviationFormatter()
  const soundMixAbbreviationFormatter = useSoundMixAbbreviationFormatter()
  const ageClassificationAbbreviationFormatter =
    useAgeClassificationAbbreviationFormatter()
  const [eventStateFilter, setEventStateFilter] = useState<EventState>(
    EventState.Published
  )
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    {
      group: TabGroup.Now,
      value: TabNow.FromToday
    }
  )
  const hasPermissionToAccessDrawer =
    (P([PermissionCode.ReadSales]) || P([PermissionCode.ReadReservations])) &&
    P([PermissionCode.ReadAggregatedEventStatisticsByItemPrice])
  const [selectedPaymentDate, setSelectedPaymentDate] =
    useState<IDataPickerData | undefined>(undefined)
  const {data, loading, error, refetch} = useGetEventsStatistics({
    filter: {
      ...searchFilter,
      ...getFilterDateRange({
        date: selectedDate,
        filterNameFrom: 'eventStartsAtFrom',
        filterNameTo: 'eventStartsAtTo'
      }),
      ...getFilterDateRange({
        date: selectedPaymentDate,
        filterNameFrom: 'paymentDateTimeFrom',
        filterNameTo: 'paymentDateTimeTo'
      }),
      eventState: eventStateFilter,
      paymentCreatedById: selectedUserId
    },
    paginationInput: {limit: 500, offset: 0}
  })
  const handleRefetchButtonClick = useCallback(() => refetch(), [refetch])
  const handleDateSelect = useCallback(
    (date?: IDataPickerData) => setSelectedDate(date),
    []
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Starts at'),
        field: 'startsAt',
        valueFormatter: (params) =>
          params.id === 0
            ? params.value
            : params.value
            ? formatDateTime(new Date(params.value))
            : '',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Name'),
        field: 'names',
        valueGetter: translatedValueGetter,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'profit',
        valueFormatter: decimalFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Quantity'),
        field: 'profitTicketCount',
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'salesAmount',
        valueFormatter: decimalFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Quantity'),
        field: 'salesQuantity',
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Amount ({{currencySign}})', {
          currencySign: translatedEffectiveClientCurrencySign
        }),
        field: 'refundsAmount',
        valueFormatter: decimalFormatter,
        align: 'right',
        headerAlign: 'right',
        cellClassName: getCellClassName
      },
      {
        headerName: t('Quantity'),
        field: 'refundsQuantity',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Venue'),
        field: 'venueName',
        valueGetter: (params) => params.row.venue?.name,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Auditorium'),
        field: 'auditoriumName',
        valueGetter: (params) => params.row.auditorium?.name,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Division'),
        field: 'divisionName',
        valueGetter: (params) => params.row.division?.name,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Format'),
        field: 'formatCode',
        valueFormatter: showFormatAbbreviationFormatter,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Version'),
        field: 'versionCode',
        valueFormatter: versionCodeAbbreviationFormatter,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Sound mix'),
        field: 'soundMixCode',
        valueFormatter: soundMixAbbreviationFormatter,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Age restrictions'),
        field: 'ageClassificationCode',
        valueFormatter: ageClassificationAbbreviationFormatter,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Cost center'),
        field: 'costCenterName',
        valueGetter: (params) => params.row.costCenter?.name,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Event category'),
        field: 'eventCategoryName',
        valueGetter: (params) => params.row.eventCategory?.name,
        cellClassName: getCellClassName
      },
      {
        headerName: t('Marketing label'),
        field: 'marketingLabelName',
        valueGetter: (params) => params.row.marketingLabel?.name,
        cellClassName: getCellClassName
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.eventId,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              id={params.value}
              isRowPinned={params.rowNode.type === 'pinnedRow'}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        width: 48
      }
    ],
    [
      ageClassificationAbbreviationFormatter,
      decimalFormatter,
      formatDateTime,
      showFormatAbbreviationFormatter,
      soundMixAbbreviationFormatter,
      t,
      translatedEffectiveClientCurrencySign,
      translatedValueGetter,
      versionCodeAbbreviationFormatter
    ]
  )
  return (
    <RenderOnData<EventsStatisticsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading events statistics')}
      ignoreLoadingIfData
      dataCondition={(data) => Array.isArray(data.eventsStatistics.items)}
    >
      {({eventsStatistics}) => (
        <Box sx={{height: '100%'}}>
          <Toolbar
            title={t('Revenues')}
            eventStateFilter={eventStateFilter}
            setEventStateFilter={setEventStateFilter}
            setSelectedUserId={setSelectedUserId}
            selectedPaymentDate={selectedPaymentDate}
            setSelectedPaymentDate={setSelectedPaymentDate}
            onDateSelect={handleDateSelect}
            selectedDate={selectedDate}
            onRefetchButtonClick={handleRefetchButtonClick}
            onEventsListCsvButtonClick={() =>
              dataGridApiRef.current.exportDataAsCsv({
                fileName: 'eventsStatistics',
                getRowsToExport: (params: GridCsvGetRowsToExportParams) =>
                  gridFilteredSortedRowIdsSelector(params.apiRef).filter(
                    (rowId) => rowId !== 0
                  )
              })
            }
          />
          <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
            <DataGridTable
              sx={{
                [`& .${gridClasses.withBorderColor}`]: {
                  borderColor: 'divider'
                },
                [`& .${gridClasses.columnSeparator}`]: {
                  color: 'divider'
                },
                '& .bold': {fontWeight: 'bold'}
              }}
              getRowId={(row) => row.eventId}
              apiRef={dataGridApiRef}
              columns={columns}
              rows={formatData(eventsStatistics.items)}
              disableColumnMenu
              disableRowSelectionOnClick
              experimentalFeatures={{columnGrouping: true}}
              columnHeaderHeight={32}
              initialState={{
                pagination: {paginationModel: {pageSize: 30}},
                pinnedColumns: {left: ['startsAt'], right: ['arrow']}
              }}
              columnVisibilityModel={{
                arrow: hasPermissionToAccessDrawer
              }}
              pageSizeOptions={[10, 30, 50]}
              columnGroupingModel={[
                {
                  groupId: t('Event'),
                  children: [{field: 'names'}]
                },
                {
                  groupId: t('Summary'),
                  children: [{field: 'profit'}, {field: 'profitTicketCount'}]
                },
                {
                  groupId: t('Sales'),
                  children: [{field: 'salesAmount'}, {field: 'salesQuantity'}]
                },
                {
                  groupId: t('Refunds'),
                  children: [
                    {field: 'refundsAmount'},
                    {field: 'refundsQuantity'}
                  ]
                },
                {
                  groupId: t('Event details'),
                  children: [
                    {field: 'formatCode'},
                    {field: 'versionCode'},
                    {field: 'soundMixCode'},
                    {field: 'ageClassificationCode'},
                    {field: 'costCenterName'},
                    {field: 'eventCategoryName'},
                    {field: 'marketingLabelName'}
                  ]
                }
              ]}
              pinnedRows={{
                bottom: [
                  {
                    eventId: 0,
                    startsAt: t('Summary'),
                    names: {
                      [LocaleCode.En]: '',
                      [LocaleCode.Cs]: '',
                      [LocaleCode.Sk]: '',
                      [LocaleCode.Hu]: ''
                    },
                    profit: sum(
                      eventsStatistics.items.map((item) => item.profit)
                    ),
                    profitTicketCount: sum(
                      data?.eventsStatistics.items.map(
                        (item) => item.profitTicketCount
                      )
                    ),
                    salesAmount: safeSum([
                      ...eventsStatistics.items.map(
                        (item) => item.saleSumOnRetailChannel
                      ),
                      ...eventsStatistics.items.map(
                        (item) => item.saleSumOnEcommerceChannel
                      )
                    ]),
                    salesQuantity: safeSum([
                      ...eventsStatistics.items.map(
                        (item) => item.saleTicketCountOnRetailChannel
                      ),
                      ...eventsStatistics.items.map(
                        (item) => item.saleTicketCountOnEcommerceChannel
                      )
                    ]),
                    refundsAmount: safeSum([
                      ...eventsStatistics.items.map(
                        (item) => item.refundSumOnRetailChannel
                      ),
                      ...eventsStatistics.items.map(
                        (item) => item.refundSumOnEcommerceChannel
                      )
                    ]),
                    refundsQuantity: safeSum([
                      ...eventsStatistics.items.map(
                        (item) => item.refundTicketCountOnRetailChannel
                      ),
                      ...eventsStatistics.items.map(
                        (item) => item.refundTicketCountOnEcommerceChannel
                      )
                    ])
                  }
                ]
              }}
              localeText={{noRowsLabel: t('No events statistics to show')}}
              disableAutosize={false}
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: true,
                expand: true
              }}
            />
          </Box>
        </Box>
      )}
    </RenderOnData>
  )
}
