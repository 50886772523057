import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'

import {
  ActivateLayoutPricingMutation,
  ActivateLayoutPricingMutationVariables,
  ArchiveLayoutPricingMutation,
  ArchiveLayoutPricingMutationVariables,
  AuditoriumLayoutPricingInput,
  AuditoriumLayoutPricingPropertiesFragment,
  AuditoriumLayoutPropertiesFragment,
  CreateLayoutPricingMutation,
  CreateLayoutPricingMutationVariables,
  DeleteLayoutPricingMutation,
  DeleteLayoutPricingMutationVariables,
  GetLayoutPricingQuery,
  GetLayoutPricingQueryVariables,
  GetLayoutPricingsQuery,
  GetLayoutPricingsQueryVariables,
  UpdateLayoutPricingMutation,
  UpdateLayoutPricingMutationVariables
} from '../../../../../__generated__/schema'
import {removeObjectFromCache} from '../../../../../utils/apollo'
import {useVenuesPathnameParams} from '../../../../../utils/pathname'
import {eventVenuesQuery} from '../../graphql'
import {useGetAuditoriumLayout} from '../auditoriumLayouts/graphql'

const AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT = gql`
  fragment AuditoriumLayoutPricingProperties on AuditoriumLayoutPricing {
    id
    name
    maxTicketsPerOrder
    maxNumberOfOccupiedSeatsOnRetail
    maxNumberOfOccupiedSeatsOnEcommerce
    status
    pricing
    ticketTypes {
      id
      color
    }
  }
`

export const GET_LAYOUT_PRICINGS = gql`
  query GetLayoutPricings($auditoriumLayoutId: Int!) {
    auditoriumLayout(id: $auditoriumLayoutId) {
      id
      status
      capacity
    }
    auditoriumLayoutPricings(auditoriumLayoutId: $auditoriumLayoutId) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

const GET_LAYOUT_PRICING = gql`
  query GetLayoutPricing($id: Int!) {
    auditoriumLayoutPricing(id: $id) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

const CREATE_LAYOUT_PRICING = gql`
  mutation CreateLayoutPricing(
    $auditoriumLayoutId: Int!
    $data: AuditoriumLayoutPricingInput!
  ) {
    createAuditoriumLayoutPricing(
      auditoriumLayoutId: $auditoriumLayoutId
      data: $data
    ) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

const UPDATE_LAYOUT_PRICING = gql`
  mutation UpdateLayoutPricing(
    $id: Int!
    $data: AuditoriumLayoutPricingInput!
  ) {
    updateAuditoriumLayoutPricing(id: $id, data: $data) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

const DELETE_LAYOUT_PRICING = gql`
  mutation DeleteLayoutPricing($id: Int!) {
    deleteAuditoriumLayoutPricing(id: $id) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

const ACTIVATE_LAYOUT_PRICING = gql`
  mutation ActivateLayoutPricing($id: Int!) {
    activateAuditoriumLayoutPricing(id: $id) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

const ARCHIVE_LAYOUT_PRICING = gql`
  mutation ArchiveLayoutPricing($id: Int!) {
    archiveAuditoriumLayoutPricing(id: $id) {
      ...AuditoriumLayoutPricingProperties
    }
  }
  ${AUDITORIUM_LAYOUT_PRICING_PROPERTIES_FRAGMENT}
`

export const useCreateLayoutPricing = (auditoriumLayoutId: number) => {
  const [createLayoutPricing] = useMutation<
    CreateLayoutPricingMutation,
    CreateLayoutPricingMutationVariables
  >(CREATE_LAYOUT_PRICING, {
    refetchQueries: () => {
      return [{query: GET_LAYOUT_PRICINGS, variables: {auditoriumLayoutId}}]
    }
  })
  return useCallback(
    (
      auditoriumLayoutId: number,
      name: string,
      maxTicketsPerOrder: number,
      maxNumberOfOccupiedSeatsOnRetail: number,
      maxNumberOfOccupiedSeatsOnEcommerce: number
    ) =>
      createLayoutPricing({
        variables: {
          auditoriumLayoutId,
          data: {
            name,
            maxTicketsPerOrder,
            maxNumberOfOccupiedSeatsOnRetail,
            maxNumberOfOccupiedSeatsOnEcommerce,
            pricing: {}
          }
        }
      }),
    [createLayoutPricing]
  )
}

export const useUpdateLayoutPricing = () => {
  const [updateLayoutPricing] = useMutation<
    UpdateLayoutPricingMutation,
    UpdateLayoutPricingMutationVariables
  >(UPDATE_LAYOUT_PRICING)
  return useCallback(
    (id: number, data: AuditoriumLayoutPricingInput) =>
      updateLayoutPricing({
        variables: {id, data}
      }),
    [updateLayoutPricing]
  )
}

export const useDeleteLayoutPricing = (auditoriumLayoutId: number) => {
  const [deleteLayoutPricing] = useMutation<
    DeleteLayoutPricingMutation,
    DeleteLayoutPricingMutationVariables
  >(DELETE_LAYOUT_PRICING, {
    refetchQueries: () => {
      return [
        {query: GET_LAYOUT_PRICINGS, variables: {auditoriumLayoutId}},
        eventVenuesQuery
      ]
    },
    update(cache, {data}) {
      if (data) {
        removeObjectFromCache(cache, data.deleteAuditoriumLayoutPricing)
      }
    }
  })
  return useCallback(
    (id: number) =>
      deleteLayoutPricing({
        variables: {id}
      }),
    [deleteLayoutPricing]
  )
}

export const useActivateLayoutPricing = () => {
  const [activateLayoutPricing] = useMutation<
    ActivateLayoutPricingMutation,
    ActivateLayoutPricingMutationVariables
  >(ACTIVATE_LAYOUT_PRICING, {
    refetchQueries: () => [eventVenuesQuery]
  })
  return useCallback(
    (id: number) =>
      activateLayoutPricing({
        variables: {id}
      }),
    [activateLayoutPricing]
  )
}

export const useArchiveLayoutPricing = () => {
  const [archiveLayoutPricing] = useMutation<
    ArchiveLayoutPricingMutation,
    ArchiveLayoutPricingMutationVariables
  >(ARCHIVE_LAYOUT_PRICING, {
    refetchQueries: () => [eventVenuesQuery]
  })
  return useCallback(
    (id: number) =>
      archiveLayoutPricing({
        variables: {id}
      }),
    [archiveLayoutPricing]
  )
}

export const useGetLayoutPricings = (auditoriumLayoutId: number) => {
  return useQuery<GetLayoutPricingsQuery, GetLayoutPricingsQueryVariables>(
    GET_LAYOUT_PRICINGS,
    {variables: {auditoriumLayoutId}}
  )
}

export const useGetLayoutPricing = (id: number) => {
  return useQuery<GetLayoutPricingQuery, GetLayoutPricingQueryVariables>(
    GET_LAYOUT_PRICING,
    {variables: {id}}
  )
}

export interface ITransformedPricingScreenData {
  layoutData: AuditoriumLayoutPropertiesFragment
  pricingData: AuditoriumLayoutPricingPropertiesFragment
}

export const useGetPricingScreenData = () => {
  // TODO: make this on one request once backend is ready on it
  const {auditoriumLayoutId, layoutPricingId} = useVenuesPathnameParams()

  const {
    data: layoutData,
    loading: loadingLayout,
    error: errorLayout
  } = useGetAuditoriumLayout(auditoriumLayoutId)
  const {
    data: pricingData,
    loading: loadingPricing,
    error: errorPricing
  } = useGetLayoutPricing(layoutPricingId)

  if (layoutData && pricingData) {
    return {
      data: {
        layoutData: layoutData.auditoriumLayout,
        pricingData: pricingData.auditoriumLayoutPricing
      },
      loading: false,
      error: errorLayout || errorPricing
    }
  }
  return {
    data: {layoutData: null, pricingData: null},
    loading: loadingLayout || loadingPricing,
    error: errorLayout || errorPricing
  }
}
