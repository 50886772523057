import AddIcon from '@mui/icons-material/Add'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Blank} from '../../../visual/Blank'

interface IPaymentMethodsTableBlankProps {
  hasCreatePaymentMethodPermission: boolean
  handleCreateClick: () => void
}

export const PaymentMethodsTableBlank: React.FC<IPaymentMethodsTableBlankProps> =
  ({
    hasCreatePaymentMethodPermission,
    handleCreateClick
  }: IPaymentMethodsTableBlankProps) => {
    const {t} = useTranslation()
    return (
      <Blank
        title={t('No payment methods found')}
        description={
          hasCreatePaymentMethodPermission
            ? t(
                'Create payment methods and let your customers pay by options you have'
              )
            : undefined
        }
        actions={
          hasCreatePaymentMethodPermission ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCreateClick}
            >
              {t('Create')}
            </Button>
          ) : undefined
        }
      />
    )
  }
