import ViewListIcon from '@mui/icons-material/ViewList'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import {Box, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClientFilesQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {RenderOnData} from '../../../../common'
import {OutlinedInputWithCancelAdornment} from '../../../../common/OutlinedInputWithCancelAdornment'
import {Blank} from '../../../../visual/Blank'
import {ChildrenOnEffectiveClientSelected} from '../../ChildrenOnEffectiveClientSelected'
import {FilesList} from '../../components/FilesList'
import {useClientFiles, useDeleteClientFile} from '../graphql'

export const Assets: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const deleteClientFile = useDeleteClientFile()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {data, loading, error, refetch} = useClientFiles()
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid')
  const [search, setSearch] = useState<string>('')
  const handleDeleteClientFile = useCallback(
    async (filepath: string) => {
      try {
        setShowBackdrop(true)
        await deleteClientFile(filepath)
        await refetch()
        addInfoNotification(t('File has been deleted'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while deleting client file'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      refetch,
      addInfoNotification,
      defaultErrorHandler,
      deleteClientFile,
      setShowBackdrop,
      t
    ]
  )
  const handleInputChange = useCallback((e) => setSearch(e.target.value), [])
  const handleCancelClick = useCallback(() => setSearch(''), [])
  const filteredFiles = (data?.clientFiles || []).filter(
    ({fileName}) => fileName.toUpperCase().indexOf(search.toUpperCase()) > -1
  )
  const handleViewModeChange = useCallback((e, newViewMode) => {
    if (newViewMode) {
      setViewMode(newViewMode)
    }
  }, [])
  return (
    <ChildrenOnEffectiveClientSelected>
      <RenderOnData<ClientFilesQuery>
        data={data}
        loading={loading}
        error={error}
        errorMessage={t<string>('Error while loading client files')}
      >
        {({clientFiles}) =>
          clientFiles.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                pb: 1
              }}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'common.white',
                  px: 2,
                  py: 1,
                  zIndex: 1,
                  gap: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
                }}
              >
                <OutlinedInputWithCancelAdornment
                  onCancelClick={handleCancelClick}
                  inputProps={{
                    value: search,
                    onChange: handleInputChange,
                    placeholder: t('Search for assets'),
                    size: 'small',
                    fullWidth: true
                  }}
                  sx={{p: 0}}
                />
                <ToggleButtonGroup
                  size="small"
                  value={viewMode}
                  exclusive
                  onChange={handleViewModeChange}
                >
                  <ToggleButton value="grid">
                    <ViewModuleIcon />
                  </ToggleButton>
                  <ToggleButton value="list">
                    <ViewListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {filteredFiles.length > 0 ? (
                <FilesList
                  files={filteredFiles}
                  onDelete={
                    P([PermissionCode.DeleteClientFile])
                      ? handleDeleteClientFile
                      : undefined
                  }
                  type={viewMode}
                />
              ) : (
                <Blank
                  title={t(
                    'No matching assets found. Please refine your search.'
                  )}
                  contentSx={{'&&': {width: '100%'}}}
                />
              )}
            </Box>
          ) : (
            <Blank
              title={t('No assets found')}
              description={t(
                'It looks like there are no assets available at the moment. You can upload your assets to get started.'
              )}
              contentSx={{'&&': {width: '100%'}}}
            />
          )
        }
      </RenderOnData>
    </ChildrenOnEffectiveClientSelected>
  )
}
