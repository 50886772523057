import React from 'react'
import {useTranslation} from 'react-i18next'
import {MessagesFilterInput} from '../../../../__generated__/schema'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'

export const DEFAULT_MESSAGES_FILTER_INPUT: MessagesFilterInput = {
  hasText: ''
}

const mapHasTextToFilter = (
  filter: MessagesFilterInput,
  hasText?: string
): MessagesFilterInput => ({
  ...filter,
  hasText
})

const getHasTextFromSearchObject = (filter: MessagesFilterInput) =>
  filter.hasText || undefined

interface IMessagesSearchProps {
  onFilterChange: (filter: MessagesFilterInput) => void
}

export const MessagesSearch: React.FC<IMessagesSearchProps> = ({
  onFilterChange
}: IMessagesSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<MessagesFilterInput>(
      getHasTextFromSearchObject
    )
  return (
    <Search<MessagesFilterInput>
      storageKey="MESSAGES"
      placeholder={t('Search for message')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_MESSAGES_FILTER_INPUT}
    />
  )
}
