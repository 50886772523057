export enum CashRegisterOperation {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal'
}

export enum CashRegisterOperationFormField {
  Amount = 'amount',
  Note = 'note'
}

export interface ICashRegisterOperationForm {
  [CashRegisterOperationFormField.Amount]: string[]
  [CashRegisterOperationFormField.Note]?: string
}
