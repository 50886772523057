import AddIcon from '@mui/icons-material/Add'
import {Button, Divider} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import {last} from 'lodash'
import React, {useCallback} from 'react'
import DraggableList from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {NarrowDivisionPropertiesFragment} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {InputBlockWithoutSpacings} from '../../../common'
import {AddDivisionDialog} from '../../../common/enabledDivisions/AddDivisionDialog'
import {
  DraggableDivisionItem,
  IDivisionItemCommonProps
} from '../../../common/enabledDivisions/DraggableDivisionItem'
import {InputBlockHeadingRow} from './InputBlockHeadingRow'
import {
  DeviceSettingsAction,
  DeviceSettingsActionTypes,
  DeviceSettingsState
} from './types'

const useStyles = makeStyles<Theme>(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  }
}))

interface IEnabledDivisionsProps {
  blockId: string
  blockLabel: string
  divisions: NarrowDivisionPropertiesFragment[]
  enabledDivisions: DeviceSettingsState['enabledDivisions']
  dispatch: React.Dispatch<DeviceSettingsAction>
}

export const EnabledDivisions: React.FC<IEnabledDivisionsProps> = ({
  blockId,
  blockLabel,
  divisions,
  enabledDivisions,
  dispatch
}: IEnabledDivisionsProps) => {
  const {t} = useTranslation()
  const {
    state: isAddDivisionDialogOpen,
    setTrue: openAddDivisionDialog,
    setFalse: closeAddDivisionDialog
  } = useBooleanState(false)
  const filteredDivisions = divisions.filter(
    (division) => !enabledDivisions.includes(division)
  )
  const classes = useStyles()
  const handleAddButtonClick = useCallback(
    (divisions: NarrowDivisionPropertiesFragment[]) =>
      dispatch({
        type: DeviceSettingsActionTypes.AddEnabledDivisions,
        payload: divisions
      }),
    [dispatch]
  )
  return (
    <>
      <InputBlockWithoutSpacings
        blockId={blockId}
        header={
          <div className={classes.header}>
            {blockLabel}
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openAddDivisionDialog}
              disabled={filteredDivisions.length === 0}
            >
              {t('Add division')}
            </Button>
          </div>
        }
        headerClassName={classes.fullWidth}
      >
        <InputBlockHeadingRow
          label={t('Divisions enabled on device')}
          subLabel={t(
            'Leave empty, if all divisions should be enabled on device for events and reservations.'
          )}
        />
        {enabledDivisions.length > 0 && <Divider />}
        <DraggableList<
          NarrowDivisionPropertiesFragment,
          IDivisionItemCommonProps,
          DraggableDivisionItem
        >
          list={enabledDivisions}
          itemKey="id"
          template={DraggableDivisionItem}
          padding={0}
          constrainDrag
          onMoveEnd={(
            divisions: readonly NarrowDivisionPropertiesFragment[]
          ) => {
            dispatch({
              type: DeviceSettingsActionTypes.ResetEnabledDivisions,
              payload: [...divisions]
            })
          }}
          commonProps={{
            isLastItem: (divisionId: number) =>
              last(enabledDivisions)?.id === divisionId,
            getRemoveButtonClickHandler:
              (division: NarrowDivisionPropertiesFragment) => () =>
                dispatch({
                  type: DeviceSettingsActionTypes.RemoveEnabledDivision,
                  payload: division
                })
          }}
        />
      </InputBlockWithoutSpacings>
      <AddDivisionDialog
        isOpen={isAddDivisionDialogOpen}
        onClose={closeAddDivisionDialog}
        divisions={filteredDivisions}
        onAddButtonClick={handleAddButtonClick}
      />
    </>
  )
}
