import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Button,
  Divider,
  ListItemIcon,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode, TemplateQuery} from '../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {
  useTranslateTemplateState,
  useTranslateTemplateVersionState
} from '../../../../hooks/translateTemplates'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useTemplateParams} from '../../../../utils/pathname'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {ConfirmationDialog} from '../../../common/ConfirmationDialog'
import {HeaderActionType} from '../../../common/header/types'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {templateStateColors} from '../../../constants'
import {SplitButton} from '../../../visual'
import {SecondaryHeader} from '../Header'
import {useEditTemplateVersionCode, useGetTemplate} from './graphql'
import {TemplateSettingsDrawer} from './TemplateSettingsDrawer'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflow: 'hidden'
  },
  code: {
    padding: theme.spacing(1),
    flexBasis: '66.66%',
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'auto'
  },
  dataset: {
    padding: theme.spacing(1),
    flexBasis: '33.33%',
    background: theme.palette.background.paper,
    overflow: 'auto'
  },
  footerWrapper: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  footerLeft: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    gap: theme.spacing(1),
    height: '100%',
    flexBasis: '66.66%',
    borderRight: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between'
  },
  footerRight: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    gap: theme.spacing(1),
    height: '100%',
    flexBasis: '33.33%',
    justifyContent: 'flex-end'
  },
  templateVersionInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3)
  },
  templateVersionActions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  textField: {
    width: '100%'
  }
}))

export const EditTemplate: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {templateId} = useTemplateParams()
  const translateTemplateVersionState = useTranslateTemplateVersionState()
  const translateTemplateState = useTranslateTemplateState()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {data, loading, error} = useGetTemplate(templateId)
  const editTemplateVersionCode = useEditTemplateVersionCode()
  const [templateName, setTemplateName] = useState<string>(String(templateId))
  const [code, setCode] = useState<string>('')
  const [dataset, setDataset] = useState<string>('')
  const {
    state: isEditTemplateDrawerOpen,
    setTrue: openEditTemplateDrawer,
    setFalse: closeEditTemplateDrawer
  } = useBooleanState(false)
  const {
    state: isDialogOpen,
    setTrue: openDialog,
    setFalse: closeDialog
  } = useBooleanState(false)
  useEffect(() => {
    if (data) {
      setTemplateName(
        [translateTemplateState(data.template.state), data.template.name].join(
          ' • '
        )
      )
    }
  }, [data, translateTemplateState])
  useEffect(() => {
    if (data?.template.activeVersion.code) {
      setCode(data.template.activeVersion.code)
    }
  }, [data])
  const history = useHistory()
  const handleArrowBackClick = useCallback(
    () => history.push(routeTo.admin.templates.index()),
    [history]
  )
  const handleCodeChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => setCode(e.target.value),
    []
  )
  const handleDatasetChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => setDataset(e.target.value),
    []
  )
  const handleSaveCodeButtonClick = useCallback(async () => {
    if (data) {
      try {
        setShowBackdrop(true)
        await editTemplateVersionCode({
          id: data.template.activeVersion.id,
          code
        })
        addInfoNotification(t('Version changes saved'))
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while saving template version code')
        )
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    addInfoNotification,
    code,
    data,
    defaultErrorHandler,
    editTemplateVersionCode,
    setShowBackdrop,
    t
  ])
  const classes = useStyles()
  return (
    <RenderOnData
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading template')}
      dataCondition={(data: TemplateQuery) => Boolean(data.template)}
    >
      {({template}: TemplateQuery) => (
        <PageWithHeaderTemplate
          header={
            <SecondaryHeader
              title={templateName}
              onLeftActionClick={
                (template.activeVersion.code ?? '') === code
                  ? handleArrowBackClick
                  : openDialog
              }
              hasArrowBackIcon
              rightActions={[
                {
                  label: t('Clients'),
                  type: HeaderActionType.Button
                },
                {
                  label: t('Preview'),
                  type: HeaderActionType.Button,
                  Icon: VisibilityIcon
                },
                {
                  label: t('Settings'),
                  type: HeaderActionType.IconButton,
                  Icon: SettingsIcon,
                  onClick: openEditTemplateDrawer
                }
              ]}
            />
          }
        >
          <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.code}>
                <TextField
                  multiline
                  value={code}
                  onChange={handleCodeChange}
                  variant="outlined"
                  className={classes.textField}
                />
              </div>
              <div className={classes.dataset}>
                <TextField
                  multiline
                  value={dataset}
                  onChange={handleDatasetChange}
                  variant="outlined"
                  className={classes.textField}
                />
              </div>
            </div>
            <div className={classes.footerWrapper}>
              <div className={classes.footerLeft}>
                <div className={classes.templateVersionInfo}>
                  <Typography variant="body2">
                    {t('#{{templateId}} • {{templateName}}', {
                      templateId: template.activeVersion.id,
                      templateName: template.activeVersion.name
                    })}
                  </Typography>
                  <EntityStateChip
                    label={translateTemplateVersionState(
                      template.activeVersion.state
                    )}
                    colorConf={
                      templateStateColors[template.activeVersion.state]
                    }
                    isDotHidden
                  />
                </div>
                <div className={classes.templateVersionActions}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    disabled
                  >
                    {t('Delete')}
                  </Button>
                  <Divider orientation="vertical" flexItem />
                  <Button variant="text" color="primary" disabled>
                    {t('Load')}
                  </Button>
                  <SplitButton
                    Options={[
                      <MenuItem
                        disabled
                        key="edit-version-info"
                        component="button"
                      >
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        {t('Edit version info')}
                      </MenuItem>
                    ]}
                  >
                    {P([PermissionCode.EditTemplateVersionCode]) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveCodeButtonClick}
                      >
                        {t('Save')}
                      </Button>
                    )}
                  </SplitButton>
                </div>
              </div>
              <div className={classes.footerRight}>
                <Button disabled variant="text" color="primary">
                  {t('Load')}
                </Button>
                <SplitButton
                  Options={[
                    <MenuItem
                      disabled
                      key="create-dataset-example"
                      component="button"
                    >
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      {t('Create dataset example')}
                    </MenuItem>
                  ]}
                >
                  <Button disabled variant="contained" color="primary">
                    {t('Save')}
                  </Button>
                </SplitButton>
              </div>
            </div>
          </div>
          <ConfirmationDialog
            title={t('Unsaved changes')}
            contentText={t(
              'There are unsaved changes. If you would like to save changes, you can keep editing. Discarding changes is permanent action and can’t be undone.'
            )}
            confirmButtonLabel={t('Keep editing')}
            onConfirm={closeDialog}
            cancelButtonLabel={t('Discard changes')}
            onCancel={handleArrowBackClick}
            isOpen={isDialogOpen}
          />
          <TemplateSettingsDrawer
            isOpen={isEditTemplateDrawerOpen}
            onClose={closeEditTemplateDrawer}
            template={template}
          />
        </PageWithHeaderTemplate>
      )}
    </RenderOnData>
  )
}
