import {useTranslation} from 'react-i18next'
import {WarehouseDocumentState} from '../__generated__/schema'

export const useTranslateWarehouseDocumentState = () => {
  const {t} = useTranslation()
  const translated: Record<WarehouseDocumentState, string> = {
    [WarehouseDocumentState.Draft]: t('WarehouseDocumentState->Draft'),
    [WarehouseDocumentState.Issued]: t('WarehouseDocumentState->Issued')
  }
  return (state: WarehouseDocumentState) => translated[state]
}

export const useTranslateWarehouseDocumentStateDescription = () => {
  const {t} = useTranslation()
  const translated: Record<WarehouseDocumentState, string> = {
    [WarehouseDocumentState.Draft]: t(
      'WarehouseDocumentStateDescription->Draft'
    ),
    [WarehouseDocumentState.Issued]: t('WarehouseDocumentDescription->Issued')
  }
  return (state: WarehouseDocumentState) => translated[state]
}
