import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RestoreIcon from '@mui/icons-material/Restore'
import SearchIcon from '@mui/icons-material/Search'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ClickAwayListener,
  colors,
  IconButton,
  InputBase,
  Tooltip,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDebounce} from '../../../hooks/debounce'

import {Theme} from '../../../theme'
import {AccordionDetailsItemWrapper} from './AccordionDetailsItemWrapper'
import {useSearchHistory} from './searchHistory'

/**
 * The `useCombineStringifySearchObjectFunctions` hook is used to create a function, that is able to combine multiple functions, that are responsible for mapping particular parts of search object to input text
 * @param stringifySearchObjectFunctions
 */

export const useCombineStringifySearchObjectFunctions = <T extends object>(
  ...stringifySearchObjectFunctions: ((func: T) => string | undefined)[]
): ((searchObject: T) => string) => {
  const combinedFunctionRef = useRef((searchObject: T) => {
    return stringifySearchObjectFunctions
      .map((func) => func(searchObject))
      .filter(Boolean)
      .join(' ')
  })
  useEffect(() => {
    combinedFunctionRef.current = (searchObject: T) => {
      return stringifySearchObjectFunctions
        .map((func) => func(searchObject))
        .filter(Boolean)
        .join(' ')
    }
  }, [stringifySearchObjectFunctions])
  return combinedFunctionRef.current
}

interface ISearchProps<T, ET> {
  storageKey: string
  placeholder: string
  onChange(searchObject: T): void
  mapInputTextToSearchObject(searchObject: ET, inputText: string): ET
  mapSearchObjectToInputText(searchObject: ET): string
  defaultSearchObject: ET
  renderQuickOptions?(
    setSearchObject: (updatedSearchObject: ET) => void
  ): React.ReactNode | React.ReactNodeArray
  renderAdvancedSearch?: (props: {
    isAdvancedSubmitDisabled: boolean
    onAdvancedSearchSubmit: () => void
    advancedSearchObject: ET
    setAdvancedSearchObject: (o: ET) => void
  }) => React.ReactNode | React.ReactNodeArray
  stripExtendedSearchObject?(extendedSearchObject: ET): T
}

const useStyles = makeStyles<
  Theme,
  {showClose: boolean; showAdvancedSearch: boolean; isContentHidden: boolean}
>((theme) => ({
  root: {
    width: '100%',
    border: 'none'
  },
  wrapper: {
    height: 48,
    position: 'relative',
    zIndex: theme.zIndex.mobileStepper - 1
  },
  iconColor: {
    color: theme.palette.text.secondary
  },
  searchIcon: {
    width: 'auto',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },
  closeIconButton: {
    visibility: ({showClose}) => (showClose ? 'inherit' : 'hidden')
  },
  endAdornmentInputButton: {
    padding: theme.spacing(1)
  },
  expandButton: {
    transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1)',
    transform: ({showAdvancedSearch}) =>
      showAdvancedSearch ? 'rotate(180deg)' : 'rotate(360deg)'
  },
  accordionSummaryRoot: {
    backgroundColor: colors.grey['100'],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: ({isContentHidden}) => (isContentHidden ? 4 : 0),
    borderBottomRightRadius: ({isContentHidden}) => (isContentHidden ? 4 : 0),
    '&.MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `solid ${theme.palette.divider} 1px`,
      minHeight: 'auto'
    }
  },
  accordionSummaryContent: {
    minHeight: theme.spacing(6),
    margin: 0
  },
  accordionSummaryExpanded: {
    '&.MuiAccordionSummary-content.Mui-expanded': {
      margin: 0
    }
  },
  accordionSummaryFocused: {
    '&.MuiAccordionSummary-root.Mui-focused': {
      backgroundColor: theme.palette.background.paper
    }
  },
  accordionDetailRoot: {
    flexDirection: 'column',
    border: 'none',
    padding: 0
  }
}))

const useHistoryStyles = makeStyles<Theme>((theme) => ({
  icon: {
    color: theme.palette.text.secondary
  },
  iconWrapper: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  }
}))

interface IHistoryItemProps {
  label: string
  onClick(): void
}

const HistoryItem: React.FC<IHistoryItemProps> = ({
  label,
  onClick
}: IHistoryItemProps) => {
  const styles = useHistoryStyles()
  return (
    <AccordionDetailsItemWrapper onClick={onClick}>
      <div className={styles.iconWrapper}>
        <RestoreIcon className={styles.icon} />
      </div>
      <Typography variant="body2" noWrap>
        {label}
      </Typography>
    </AccordionDetailsItemWrapper>
  )
}

enum SearchContentEnum {
  HIDDEN = 'HIDDEN',
  QUICK = 'QUICK',
  ADVANCED = 'ADVANCED'
}

export const Search = <T extends object, ET extends T = T>({
  storageKey,
  placeholder,
  mapInputTextToSearchObject,
  mapSearchObjectToInputText,
  renderQuickOptions,
  defaultSearchObject,
  renderAdvancedSearch,
  stripExtendedSearchObject,
  onChange: unStrippedOnChange
}: ISearchProps<T, ET>) => {
  const onChange = useCallback(
    (o) => {
      unStrippedOnChange(
        stripExtendedSearchObject ? stripExtendedSearchObject(o) : o
      )
    },
    [stripExtendedSearchObject, unStrippedOnChange]
  )
  const {t} = useTranslation()
  const [searchObject, setSearchObject] = useState<ET>(defaultSearchObject)
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(false)
  const setSearchObjectAndFireOnChange = useCallback(
    (updatedSearchObject: ET) => {
      setSearchObject(updatedSearchObject)
      onChange(updatedSearchObject)
    },
    [onChange]
  )
  const {items: historyItems, addToHistory} = useSearchHistory(storageKey)
  const [currentContent, setCurrentContent] = useState<SearchContentEnum>(
    SearchContentEnum.HIDDEN
  )
  const baseInputRef = React.useRef<HTMLInputElement | null>(null)
  const onQuickSearchClick = useCallback((e, expanded) => {
    e.stopPropagation()
    if (expanded) {
      setCurrentContent(SearchContentEnum.QUICK)
    }
  }, [])

  const [advancedSearchObject, setAdvancedSearchObject] =
    useState<ET>(defaultSearchObject)

  const onAdvancedSearchClick = useCallback(
    (e) => {
      e.stopPropagation()
      setCurrentContent(
        currentContent === SearchContentEnum.ADVANCED
          ? SearchContentEnum.HIDDEN
          : SearchContentEnum.ADVANCED
      )
      setAdvancedSearchObject(searchObject)
    },
    [currentContent, searchObject]
  )

  const debouncedOnChange = useDebounce(onChange, 400)

  const onBaseInputChange = useCallback(
    (e) => {
      const updatedSearchObject = mapInputTextToSearchObject(
        searchObject,
        e.target.value
      )
      if (e.target.value.length >= 2) {
        setCurrentContent(SearchContentEnum.HIDDEN)
      }
      setSearchObject(updatedSearchObject)
      if (e.target.value.length !== 1) {
        debouncedOnChange(updatedSearchObject)
      }
    },
    [debouncedOnChange, mapInputTextToSearchObject, searchObject]
  )
  const onCloseClick = useCallback(
    (e) => {
      e.stopPropagation()
      setSearchObjectAndFireOnChange(defaultSearchObject)
      setIsSearchDisabled(false)
      if (
        currentContent !== SearchContentEnum.HIDDEN &&
        baseInputRef &&
        baseInputRef.current
      ) {
        baseInputRef.current.focus()
      }
      setAdvancedSearchObject(defaultSearchObject)
    },
    [currentContent, defaultSearchObject, setSearchObjectAndFireOnChange]
  )
  const onBaseInputBlur = useCallback(() => {
    const inputText = mapSearchObjectToInputText(searchObject).trim()
    if (inputText.length > 1) {
      addToHistory(inputText)
    }
  }, [addToHistory, mapSearchObjectToInputText, searchObject])
  const styles = useStyles({
    showClose: !isEqual(defaultSearchObject, searchObject),
    showAdvancedSearch: currentContent === SearchContentEnum.ADVANCED,
    isContentHidden: currentContent === SearchContentEnum.HIDDEN
  })

  const onOptionClick = useCallback(
    (searchObject: ET) => {
      setSearchObjectAndFireOnChange(searchObject)
      setIsSearchDisabled(true)
      setCurrentContent(SearchContentEnum.HIDDEN)
    },
    [setSearchObjectAndFireOnChange]
  )
  const handleClickAway = useCallback(() => {
    setCurrentContent(SearchContentEnum.HIDDEN)
  }, [])

  const onAdvancedSearchSubmit = useCallback(() => {
    onOptionClick(advancedSearchObject)
  }, [advancedSearchObject, onOptionClick])
  const isAdvancedSubmitDisabled = useMemo(
    () => isEqual(advancedSearchObject, searchObject),
    [advancedSearchObject, searchObject]
  )
  return (
    <div className={styles.wrapper}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Accordion
          expanded={currentContent !== SearchContentEnum.HIDDEN}
          variant="outlined"
          onChange={onQuickSearchClick}
          classes={{
            root: styles.root
          }}
          TransitionProps={{
            style: {
              boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4
            }
          }}
        >
          <AccordionSummary
            classes={{
              root: styles.accordionSummaryRoot,
              content: styles.accordionSummaryContent,
              expanded: styles.accordionSummaryExpanded,
              focusVisible: styles.accordionSummaryFocused
            }}
          >
            <InputBase
              inputRef={baseInputRef}
              placeholder={placeholder}
              value={mapSearchObjectToInputText(searchObject)}
              onChange={onBaseInputChange}
              disabled={
                isSearchDisabled ||
                currentContent === SearchContentEnum.ADVANCED
              }
              color="primary"
              startAdornment={
                <SearchIcon
                  className={cn(styles.iconColor, styles.searchIcon)}
                />
              }
              onBlur={onBaseInputBlur}
              endAdornment={
                <>
                  <Tooltip title={t<string>('Delete input text')}>
                    <IconButton
                      onClick={onCloseClick}
                      className={cn(
                        styles.closeIconButton,
                        styles.endAdornmentInputButton
                      )}
                    >
                      <CloseIcon className={styles.iconColor} />
                    </IconButton>
                  </Tooltip>
                  {renderAdvancedSearch && (
                    <Tooltip
                      title={
                        currentContent === SearchContentEnum.ADVANCED
                          ? t<string>('Close advanced search')
                          : t<string>('Open advanced search')
                      }
                    >
                      <IconButton
                        className={cn(
                          styles.endAdornmentInputButton,
                          styles.expandButton
                        )}
                        onClick={onAdvancedSearchClick}
                      >
                        <ExpandMoreIcon className={styles.iconColor} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              }
              fullWidth
            />
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: styles.accordionDetailRoot
            }}
          >
            {currentContent === SearchContentEnum.QUICK && (
              <>
                {historyItems.map((item) => (
                  <HistoryItem
                    key={item}
                    label={item}
                    onClick={() => {
                      setSearchObjectAndFireOnChange(
                        mapInputTextToSearchObject(defaultSearchObject, item)
                      )
                      setIsSearchDisabled(false)
                      setCurrentContent(SearchContentEnum.HIDDEN)
                    }}
                  />
                ))}
                {renderQuickOptions && renderQuickOptions(onOptionClick)}
                {renderAdvancedSearch && (
                  <AccordionDetailsItemWrapper>
                    <Button color="primary" onClick={onAdvancedSearchClick}>
                      {t('Advanced Search')}
                    </Button>
                  </AccordionDetailsItemWrapper>
                )}
              </>
            )}
            {currentContent === SearchContentEnum.ADVANCED &&
              renderAdvancedSearch &&
              renderAdvancedSearch({
                isAdvancedSubmitDisabled,
                onAdvancedSearchSubmit,
                advancedSearchObject,
                setAdvancedSearchObject
              })}
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
    </div>
  )
}
