import {ApolloClient} from 'apollo-client'
import {ApolloQueryResult} from 'apollo-client/core/types'
import {QueryOptions} from 'apollo-client/core/watchQueryOptions'
import dayjs from 'dayjs'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {
  EventState,
  NarrowEventFragment,
  NarrowEventsQuery,
  NarrowEventsQueryVariables,
  Pagination,
  PaginationInput
} from '../../../../../../__generated__/schema'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../../../hooks/translateDistributions'
import {useGetUserLocaleTranslation} from '../../../../../../hooks/useGetUserLocaleTranslation'
import {useDateTimeFormatters} from '../../../../../../utils/formatting'
import {IIdListItem} from '../../../../../common/generalFilter/IdListItem'
import {GET_NARROW_EVENTS, NARROW_EVENT_FRAGMENT} from './graphql'
import {isNotNull, useIdListItems} from './idListItems'

const useMapNarrowEventToIdListItem = () => {
  const {t} = useTranslation()
  const getUserLocaleTranslation = useGetUserLocaleTranslation()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  return useCallback(
    ({
      id,
      names,
      startsAt,
      formatCode,
      soundMixCode,
      versionCode,
      venue,
      auditorium,
      marketingLabel
    }: NarrowEventFragment): IIdListItem => ({
      firstRow: getUserLocaleTranslation(names),
      secondRow: [
        formatDateNumeric(new Date(startsAt)),
        formatTime(new Date(startsAt)),
        formatCode && translateShowFormatAbbreviation(formatCode),
        soundMixCode && translateShowSoundMixAbbreviation(soundMixCode),
        versionCode && translateShowVersionAbbreviation(versionCode)
      ]
        .filter(Boolean)
        .join(' • '),
      thirdRow: [
        [t('Venue'), venue.name].join(': '),
        auditorium.name,
        marketingLabel && [t('Marketing label'), marketingLabel.name].join(': ')
      ]
        .filter(Boolean)
        .join(', '),
      id
    }),
    [
      formatDateNumeric,
      formatTime,
      getUserLocaleTranslation,
      t,
      translateShowFormatAbbreviation,
      translateShowSoundMixAbbreviation,
      translateShowVersionAbbreviation
    ]
  )
}

const getDataFromDb = ({
  client,
  options
}: {
  options: Omit<QueryOptions<NarrowEventsQueryVariables>, 'query'>
  client: ApolloClient<object>
}): Promise<ApolloQueryResult<NarrowEventsQuery>> =>
  client.query<NarrowEventsQuery, NarrowEventsQueryVariables>({
    query: GET_NARROW_EVENTS,
    ...options
  })

const getPagination = (data: NarrowEventsQuery): Pagination =>
  data.paginatedEvents.pagination

const useGetIdListItemsFromCache = (
  mapTItemToIdListItem: (tItem: NarrowEventFragment) => IIdListItem
) =>
  useCallback(
    (client: ApolloClient<object>, ids: number[]) =>
      ids
        .map((id) =>
          client.readFragment<NarrowEventFragment>({
            id: `Event:${id}`,
            fragment: NARROW_EVENT_FRAGMENT,
            fragmentName: 'NarrowEvent'
          })
        )
        .filter(isNotNull)
        .map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const useMapIdListItemsFromData = (
  mapTItemToIdListItem: (tItem: NarrowEventFragment) => IIdListItem
) =>
  useCallback(
    (data: NarrowEventsQuery): IIdListItem[] =>
      data.paginatedEvents.items.map(mapTItemToIdListItem),
    [mapTItemToIdListItem]
  )

const mapVariables = ({
  paginationInput,
  hasText,
  ids
}: {
  paginationInput: PaginationInput
  hasText?: string
  ids?: number[]
}): NarrowEventsQueryVariables => ({
  paginationInput,
  filter: {
    hasText,
    eventIds: ids,
    state: EventState.Published,
    showOnWebsiteAndApi: true,
    from: dayjs().startOf('d').toISOString()
  }
})

export const useEventListItems = () => {
  const mapTItemToIdListItem = useMapNarrowEventToIdListItem()
  const getIdListItemsFromCache =
    useGetIdListItemsFromCache(mapTItemToIdListItem)
  const mapIdListItemsFromData = useMapIdListItemsFromData(mapTItemToIdListItem)
  const {
    getIdListItems: getEventListItems,
    getMoreIdListItems: getMoreEventListItems,
    getIdListItemsByIds: getEventListItemsByIds
  } = useIdListItems<NarrowEventsQuery, NarrowEventsQueryVariables>({
    getDataFromDb,
    getPagination,
    getIdListItemsFromCache,
    mapIdListItemsFromData,
    mapVariables
  })
  return {
    getEventListItems,
    getMoreEventListItems,
    getEventListItemsByIds
  }
}
