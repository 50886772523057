import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
  GetVoucherDetailQuery,
  GetVoucherDetailQueryVariables
} from '../../../../../__generated__/schema'

const GET_VOUCHER_DETAIL = gql`
  query GetVoucherDetail($code: NonEmptyString!, $canReadPinCode: Boolean!) {
    voucherByCodeOnRetailChannel(code: $code) {
      id
      activatedAt
      balance
      expirationDate
      code
      pinCode @include(if: $canReadPinCode)
      client {
        id
        name
      }
      campaign {
        id
        name
        retailPaymentMethodId
        ecommercePaymentMethodId
      }
    }
  }
`

export const useGetVoucherDetail = (
  variables: GetVoucherDetailQueryVariables,
  skip?: boolean
) =>
  useQuery<GetVoucherDetailQuery, GetVoucherDetailQueryVariables>(
    GET_VOUCHER_DETAIL,
    {variables, fetchPolicy: 'network-only', skip}
  )
