import {ListItem, ListItemIcon, ListItemText} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Theme} from '../../../../theme'

const useStyles = makeStyles<Theme>((theme) => ({
  listItemIconRoot: {
    minWidth: theme.spacing(6),
    width: theme.spacing(6),
    paddingLeft: theme.spacing(1)
  }
}))

interface IFooterItemProps {
  label: string
  icon: React.ReactElement
  onClick?: () => void
  className?: string
}

export const FooterItem: React.FC<IFooterItemProps> = ({
  label,
  icon,
  onClick,
  className
}: IFooterItemProps) => {
  const classes = useStyles()
  return (
    <ListItem button onClick={onClick} className={className}>
      <ListItemIcon
        classes={{
          root: classes.listItemIconRoot
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  )
}
