import {Box, Button, Drawer, drawerClasses} from '@mui/material'
import {uniqBy} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CashDeskCustomerFieldsFragment,
  CashDeskCustomersQuery,
  CashDeskCustomersQueryVariables,
  CustomersFilterInput,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  extractPaginationInput,
  useQueryWithPagination
} from '../../../../../utils/pagination'
import {
  DrawerTemplate,
  DrawerTemplateHeader,
  RenderOnData
} from '../../../../common'
import {LazyLoadingList} from '../../../../common/LazyLoadingList'
import {CreateCustomerDrawer} from '../../components/customerDrawer/CreateCustomerDrawer'
import {ICustomerForm} from '../../components/customerDrawer/types'
import {transformFormDataToCreateCustomerInput} from '../../components/customerDrawer/utils'
import {
  CustomersSearch,
  DEFAULT_CUSTOMERS_FILTER_INPUT
} from '../../components/CustomersSearch'
import {CASH_DESK_CUSTOMERS, useCreateCashDeskCustomer} from '../graphql'
import {CustomerCard} from './CustomerCard'

interface ICustomersDrawerProps {
  onExited: () => void
  onCustomerSelect: (customer: CashDeskCustomerFieldsFragment) => void
}

export const CustomersDrawer: React.FC<ICustomersDrawerProps> = ({
  onExited,
  onCustomerSelect
}: ICustomersDrawerProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] = useState<CustomersFilterInput>(
    DEFAULT_CUSTOMERS_FILTER_INPUT
  )
  const createCashDeskCustomer = useCreateCashDeskCustomer()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const {data, loading, error, isLoadingMore, fetchMore} =
    useQueryWithPagination<
      CashDeskCustomersQuery,
      CashDeskCustomersQueryVariables
    >(
      CASH_DESK_CUSTOMERS,
      {
        variables: {
          filter: searchFilter,
          paginationInput: {offset: 0, limit: 30}
        },
        fetchPolicy: 'network-only'
      },
      {
        mapPaginationInput: (data) =>
          extractPaginationInput(data.customers.pagination),
        updateData: (prevData, fetchMoreData) => ({
          ...fetchMoreData,
          customers: {
            ...fetchMoreData.customers,
            items: uniqBy(
              [...prevData.customers.items, ...fetchMoreData.customers.items],
              ({id}) => id
            )
          }
        })
      }
    )
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const {
    state: isCreateCustomerDrawerOpen,
    setTrue: openCreateCustomerDrawer,
    setFalse: closeCreateCustomerDrawer
  } = useBooleanState(false)
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data?.customers.pagination.hasMore) {
      fetchMore()
    }
  }, [data?.customers.pagination.hasMore, fetchMore, isLoadingMore])
  const handleCardClick = useCallback(
    (customer: CashDeskCustomerFieldsFragment) => () => {
      onCustomerSelect(customer)
      closeDrawer()
    },
    [closeDrawer, onCustomerSelect]
  )
  const handleCreateCustomer = useCallback(
    async (formData: ICustomerForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await createCashDeskCustomer({
          input: transformFormDataToCreateCustomerInput(formData)
        })
        if (data) {
          onCustomerSelect(data.createCustomer)
        }
        closeCreateCustomerDrawer()
        closeDrawer()
        addInfoNotification(t('Customer has been created'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while creating customer'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      closeCreateCustomerDrawer,
      closeDrawer,
      createCashDeskCustomer,
      defaultErrorHandler,
      onCustomerSelect,
      setShowBackdrop,
      t
    ]
  )
  useEffect(() => {
    openDrawer()
  }, [openDrawer])
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        anchor="right"
        sx={{
          [`& .${drawerClasses.paper}`]: {
            maxWidth: 560,
            width: '100%'
          }
        }}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Customers')}
            />
          }
          footer={
            P([PermissionCode.CreateCustomer]) && (
              <Button
                variant="text"
                color="primary"
                onClick={openCreateCustomerDrawer}
              >
                {t('Create')}
              </Button>
            )
          }
        >
          <LazyLoadingList
            isLoadingMore={isLoadingMore}
            onScrolledNearTheEndOfTheList={handleScrolledNearTheEndOfTheLayout}
          >
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'background.paper',
                py: 1,
                px: 2,
                borderBottom: (theme) => `solid ${theme.palette.divider} 1px`
              }}
            >
              <CustomersSearch onFilterChange={setSearchFilter} />
            </Box>
            <RenderOnData<CashDeskCustomersQuery>
              data={data}
              loading={loading}
              error={error}
              errorMessage={t<string>('Error while loading customers')}
              dataCondition={(data) => Array.isArray(data.customers.items)}
            >
              {({customers}) => (
                <Box
                  sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                  }}
                >
                  {customers.items.map((customer) => (
                    <CustomerCard
                      key={customer.id}
                      customer={customer}
                      onCardClick={handleCardClick(customer)}
                    />
                  ))}
                </Box>
              )}
            </RenderOnData>
          </LazyLoadingList>
        </DrawerTemplate>
      </Drawer>
      <CreateCustomerDrawer
        isOpen={isCreateCustomerDrawerOpen}
        onClose={closeCreateCustomerDrawer}
        onSubmit={handleCreateCustomer}
      />
    </>
  )
}
