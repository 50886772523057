import React, {ReactNode, useContext, useState} from 'react'

interface IBackdropContext {
  showBackdrop: boolean
  setShowBackdrop: (isLoading: boolean) => void
}

export const Context = React.createContext({} as IBackdropContext)

type Props = {
  children: ReactNode
}

export const BackdropProvider: React.FC<Props> = ({children}: Props) => {
  const [showBackdrop, setShowBackdrop] = useState(false)

  return (
    <Context.Provider value={{showBackdrop, setShowBackdrop}}>
      {children}
    </Context.Provider>
  )
}

export const useBackdropState = () => useContext(Context)
