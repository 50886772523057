import {useTranslation} from 'react-i18next'

import {LocaleCode} from '../__generated__/schema'

export const useTranslateLocaleCode = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<LocaleCode, string> = {
    [LocaleCode.Sk]: t<string>('LocaleCode->Sk'),
    [LocaleCode.En]: t<string>('LocaleCode->En'),
    [LocaleCode.Cs]: t<string>('LocaleCode->Cs'),
    [LocaleCode.Hu]: t<string>('LocaleCode->Hu')
  }
  return (code: LocaleCode) => translatedCodes[code]
}

export const useTranslateLocaleCodeName = () => {
  const {t} = useTranslation()
  return (templateFileType: LocaleCode) => {
    switch (templateFileType) {
      case LocaleCode.En:
        return t('Locale english')
      case LocaleCode.Sk:
        return t('Locale slovak')
      case LocaleCode.Hu:
        return t('Locale hungarian')
      case LocaleCode.Cs:
        return t('Locale czech')
      default:
        return ''
    }
  }
}
