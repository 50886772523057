import DisabledIcon from '@mui/icons-material/Clear'
import SelectedIcon from '@mui/icons-material/Done'
import {Grid, lighten, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {SyntheticEvent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../../theme'
import {TicketColor} from './common'

const useComputedStyles = makeStyles(() => ({
  activeDot: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: ({color}: {color: TicketColor}) => lighten(color, 0.8)
    }
  }
}))

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    padding: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(1),
    fontWeight: 500
  },
  dot: {
    width: 40,
    height: 40,
    borderRadius: 40,
    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: 'white'
  },
  disabledDot: {
    cursor: 'not-allowed'
  },
  selectedIcon: {
    color: 'white'
  },
  disabledIcon: {
    color: 'white'
  }
}))

interface IDotProps {
  children?: React.ReactNode
  color: TicketColor
  className: string
  onClick?: (e: SyntheticEvent) => any
}

const Dot: React.FC<IDotProps> = ({
  children,
  color,
  className,
  onClick
}: IDotProps) => {
  const classes = useStyles()
  return (
    <Grid item>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={cn(classes.dot, className)}
        style={{background: color}}
        onClick={onClick}
      >
        {children}
      </Grid>
    </Grid>
  )
}

interface IColorProps {
  color: TicketColor
}

const DisabledColor: React.FC<IColorProps> = ({color}: IColorProps) => {
  const classes = useStyles()

  return (
    <Dot className={classes.disabledDot} color={color}>
      <DisabledIcon className={classes.disabledIcon} />
    </Dot>
  )
}

interface IActiveColorProps {
  color: TicketColor
  onClick: (e: SyntheticEvent) => any
}

const SelectedColor: React.FC<IActiveColorProps> = ({
  color,
  onClick
}: IActiveColorProps) => {
  const classes = useStyles()
  const computedClasses = useComputedStyles({color})

  return (
    <Dot {...{onClick, color}} className={computedClasses.activeDot}>
      <SelectedIcon className={classes.selectedIcon} />
    </Dot>
  )
}

const AvailableColor: React.FC<IActiveColorProps> = ({
  color,
  onClick
}: IActiveColorProps) => {
  const computedClasses = useComputedStyles({color})

  return <Dot {...{onClick, color}} className={computedClasses.activeDot} />
}

interface IColorPickerProps {
  unavailableColors: Array<TicketColor>
  onChange: (color: TicketColor) => any
  defaultValue?: TicketColor
}

export const ColorPicker: React.FC<IColorPickerProps> = ({
  unavailableColors,
  onChange,
  defaultValue
}: IColorPickerProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [selectedColor, setSelectedColor] = useState<TicketColor | null>(null)

  useEffect(() => {
    defaultValue && setSelectedColor(defaultValue)
  }, [defaultValue])

  return (
    <div className={classes.wrapper}>
      <Typography component="div" className={classes.label} variant="caption">
        {t('Color')}
      </Typography>
      <Grid container>
        {Object.values(TicketColor).map((color) => {
          if (
            unavailableColors
              .filter((color) => color !== defaultValue)
              .includes(color)
          ) {
            return <DisabledColor key={color} color={color} />
          }

          const onClick = () => {
            setSelectedColor(color)
            onChange(color)
          }
          if (selectedColor === color) {
            return <SelectedColor key={color} {...{color, onClick}} />
          }
          return <AvailableColor key={color} {...{color, onClick}} />
        })}
      </Grid>
    </div>
  )
}
