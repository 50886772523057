import {Drawer} from '@mui/material'
import {ApolloError} from 'apollo-client'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {CartDetailPropertiesFragment} from '../../../../../__generated__/schema'
import {useBooleanState} from '../../../../../hooks/state'
import {
  DrawerTemplateHeader,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'

interface ICartDetailDrawerTemplateProps {
  children: React.ReactNode
  cart: CartDetailPropertiesFragment
  onExited: () => void
  loading: boolean
  error: ApolloError | undefined
  navigationItems: Record<string, {id: string; label: string}>
}

export const CartDetailDrawerTemplate: React.FC<ICartDetailDrawerTemplateProps> =
  ({
    children,
    cart,
    onExited,
    loading,
    error,
    navigationItems
  }: ICartDetailDrawerTemplateProps) => {
    const {t} = useTranslation()
    const drawerClasses = useDrawerWithSideNavigationStyles()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      openDrawer()
    }, [openDrawer])
    return (
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        anchor="right"
        SlideProps={{
          onExited
        }}
        classes={drawerClasses}
      >
        <DrawerTemplateWithSideNavigation
          DrawerTemplateProps={{
            isLoading: loading,
            errorMessage: error && t<string>('Loading cart failed'),
            header: (
              <DrawerTemplateHeader
                title={t('Cart {{cartId}}', {cartId: cart.id})}
                onLeftActionClick={closeDrawer}
              />
            )
          }}
          navigationItems={navigationItems}
        >
          {children}
        </DrawerTemplateWithSideNavigation>
      </Drawer>
    )
  }
