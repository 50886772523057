import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  LightweightUsersQuery,
  PaymentsFilterInput,
  PermissionCode,
  SellingChannel,
  TransactionType
} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {useTranslateTransactionType} from '../../../../hooks/translateTransactionType'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useGetLightweightClients} from '../graphql'
import {useGetLightweightUsers} from '../paymentsOverview/graphql'

export const DEFAULT_PAYMENTS_OVERVIEW_FILTER_INPUT: PaymentsFilterInput = {}

const mapPaymentIdToFilter = (
  filter: PaymentsFilterInput,
  id: string | undefined
): PaymentsFilterInput => ({
  ...filter,
  id: id ? parseInt(id, 10) : undefined
})

const mapSellingChannelToFilter = (
  filter: PaymentsFilterInput,
  channel: SellingChannel | undefined
): PaymentsFilterInput => ({
  ...filter,
  channel
})

const mapTransactionTypeToFilter = (
  filter: PaymentsFilterInput,
  transactionType: TransactionType | undefined
): PaymentsFilterInput => ({
  ...filter,
  transactionType
})

const mapUserIdTypeToFilter = (
  filter: PaymentsFilterInput,
  userId: number | undefined
): PaymentsFilterInput => ({
  ...filter,
  userId
})

const mapClientIdTypeToFilter = (
  filter: PaymentsFilterInput,
  clientId: number | undefined
): PaymentsFilterInput => ({
  ...filter,
  clientId
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateTransactionType = useTranslateTransactionType()
  const getPaymentIdFromSearchObject = (filter: PaymentsFilterInput) =>
    filter.id ? String(filter.id) : undefined
  const getSellingChannelFromSearchObject = (filter: PaymentsFilterInput) =>
    filter.channel
      ? t('Channel: {{sellingChannel}}', {
          sellingChannel: translateSellingChannel(filter.channel)
        })
      : undefined
  const getTransactionTypeFromSearchObject = (filter: PaymentsFilterInput) =>
    filter.transactionType
      ? t('Transaction type: {{transactionType}}', {
          transactionType: translateTransactionType(filter.transactionType)
        })
      : undefined
  const getUserFromSearchObject = (
    filter: PaymentsFilterInput,
    users: LightweightUsersQuery['users']
  ) => {
    if (filter.userId) {
      const user = users.find((user) => user.id === filter.userId)
      return user
        ? t('User: {{userName}}', {
            userName: [user.lastName, user.firstName].join(' ')
          })
        : t('User ID: {{userId}}', {userId: filter.userId})
    }
    return undefined
  }
  const getClientFromSearchObject = (
    filter: PaymentsFilterInput,
    clients: {id: number; name: string}[]
  ) => {
    if (filter.clientId) {
      const client = clients.find(({id}) => id === filter.clientId)
      return client
        ? t('Client: {{name}}', {name: client.name})
        : t('Client ID: {{id}}', {id: filter.clientId})
    }
    return undefined
  }
  return {
    getPaymentIdFromSearchObject,
    getSellingChannelFromSearchObject,
    getTransactionTypeFromSearchObject,
    getUserFromSearchObject,
    getClientFromSearchObject
  }
}

interface IPaymentsSearchProps {
  onFilterChange: (filter: PaymentsFilterInput) => void
  location: 'payments' | 'paymentsSupport'
}

export const PaymentsSearch: React.FC<IPaymentsSearchProps> = ({
  onFilterChange,
  location
}: IPaymentsSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {users} = useGetLightweightUsers(location === 'paymentsSupport')
  const {clients} = useGetLightweightClients(location === 'payments')
  const {
    getPaymentIdFromSearchObject,
    getSellingChannelFromSearchObject,
    getTransactionTypeFromSearchObject,
    getUserFromSearchObject,
    getClientFromSearchObject
  } = useGetFieldFromSearchObject()
  const translateSellingChannel = useTranslateSellingChannel()
  const translateTransactionType = useTranslateTransactionType()

  const sellingChannelSelectOptions: ISelectOption<SellingChannel>[] = [
    SellingChannel.Retail,
    SellingChannel.ECommerce
  ].map((channel) => ({
    id: channel,
    label: translateSellingChannel(channel)
  }))

  const transactionTypeSelectOptions: ISelectOption<TransactionType>[] =
    Object.values(TransactionType).map((type) => ({
      id: type,
      label: translateTransactionType(type)
    }))

  const userSelectOptions: ISelectOption<number>[] = users.map((user) => ({
    id: user.id,
    label: [user.lastName, user.firstName].join(' ')
  }))

  const clientSelectOptions: ISelectOption<number>[] = clients.map(
    (client) => ({
      id: client.id,
      label: client.name
    })
  )

  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<PaymentsFilterInput>(
      getPaymentIdFromSearchObject,
      getSellingChannelFromSearchObject,
      getTransactionTypeFromSearchObject,
      (filter) => getUserFromSearchObject(filter, users),
      (filter) => getClientFromSearchObject(filter, clients)
    )
  return (
    <Search
      storageKey="PAYMENTS_OVERVIEW"
      placeholder={t('Search for payment')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapPaymentIdToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_PAYMENTS_OVERVIEW_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchSelectRow<PaymentsFilterInput, SellingChannel>
            label={t('Channel')}
            value={advancedSearchObject.channel || undefined}
            options={sellingChannelSelectOptions}
            mapSelectValueToSearchObject={mapSellingChannelToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<PaymentsFilterInput, TransactionType>
            label={t('Transaction type')}
            value={advancedSearchObject.transactionType || undefined}
            options={transactionTypeSelectOptions}
            mapSelectValueToSearchObject={mapTransactionTypeToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchTextRow<PaymentsFilterInput>
            label={t('Payment ID')}
            placeholder={t('Enter payment ID')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapPaymentIdToFilter}
            value={
              advancedSearchObject.id
                ? String(advancedSearchObject.id)
                : undefined
            }
          />
          {P([PermissionCode.ReadUsers]) && location === 'payments' && (
            <AdvancedSearchSelectRow<PaymentsFilterInput, number>
              label={t('User')}
              value={advancedSearchObject.userId || undefined}
              options={userSelectOptions}
              mapSelectValueToSearchObject={mapUserIdTypeToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadClients]) &&
            location === 'paymentsSupport' && (
              <AdvancedSearchSelectRow<PaymentsFilterInput, number>
                label={t('Client')}
                value={advancedSearchObject.clientId || undefined}
                options={clientSelectOptions}
                mapSelectValueToSearchObject={mapClientIdTypeToFilter}
                setSearchObject={setAdvancedSearchObject}
                searchObject={advancedSearchObject}
              />
            )}
        </AdvancedSearchBase>
      )}
    />
  )
}
