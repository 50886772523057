import AddIcon from '@mui/icons-material/Add'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Button, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  CustomerGroupsQuery,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {DataGridTable} from '../../../common/DataGridTable'
import {Blank} from '../../../visual/Blank'
import {WideCenteredLayout} from '../Layout'
import {useCustomerGroups} from './graphql'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.customerGroups.edit(id)),
    [id, history]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface ICustomerGroupsListProps {
  onCreateButtonClick: () => void
}

export const CustomerGroupsList: React.FC<ICustomerGroupsListProps> = ({
  onCreateButtonClick
}: ICustomerGroupsListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useCustomerGroups()
  const fabClasses = useFabClasses()
  const hasPermissionToCreateCustomerGroup = P([
    PermissionCode.CreateCustomerGroup
  ])
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 200
      },
      {
        headerName: t('Description'),
        field: 'description',
        minWidth: 500
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [t]
  )
  return (
    <RenderOnData<CustomerGroupsQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading customer groups')}
      dataCondition={(data) => Array.isArray(data.customerGroups)}
      ignoreLoadingIfData
    >
      {({customerGroups}) => (
        <>
          <WideCenteredLayout
            sx={{
              height: `calc(100% - 64px)`,
              width: '100%',
              p: 3
            }}
          >
            {customerGroups.length > 0 ? (
              <DataGridTable
                columns={columns}
                rows={customerGroups}
                initialState={{
                  pagination: {paginationModel: {pageSize: 30}},
                  pinnedColumns: {left: ['name'], right: ['id']}
                }}
                columnVisibilityModel={{
                  id: P([
                    PermissionCode.UpdateCustomerGroup,
                    PermissionCode.ReadCustomerGroup
                  ])
                }}
                pageSizeOptions={[10, 30, 50]}
                disableRowSelectionOnClick
              />
            ) : (
              <Blank
                title={t('No customer group')}
                actions={
                  hasPermissionToCreateCustomerGroup && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={onCreateButtonClick}
                    >
                      {t('Create')}
                    </Button>
                  )
                }
              />
            )}
          </WideCenteredLayout>
          {hasPermissionToCreateCustomerGroup && customerGroups.length > 0 && (
            <CreateFab classes={fabClasses} onClick={onCreateButtonClick} />
          )}
        </>
      )}
    </RenderOnData>
  )
}
