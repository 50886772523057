import React, {ReactNode, useContext} from 'react'
import _ from 'lodash'
import io from 'socket.io-client'

import {ApiSeatState} from '../../../../../../__generated__/schema'

type UUID = string

export type SeatState = {
  state: ApiSeatState
  id: number
  updatedAt: string
}
export type ZoneState = {
  states: {[key in ApiSeatState]: number}
  updatedAt: string
}

export interface ISeatsState {
  zones: {[uuid: string]: ZoneState}
  seats: {[uuid: string]: SeatState}
}

interface ISeatsStateContext {
  seatsState: ISeatsState | null
  zoneBeforeSelection: {[uuid: string]: ZoneState} | null // TODO: this does not have to be map
  resetZoneBeforeSelection: () => void
  ignoreNextDeselectAll: boolean
  setIgnoreNextDeselectAll: (ignoreNextDeselectAll: boolean) => void
  onSeatsSelected: (uuids: Array<string>) => void
  onZoneSelected: (uuid: UUID) => void
  updateSeatsState: (uuids: Array<UUID>, state: ApiSeatState) => void
  updateZoneState: (uuid: UUID, states: {[key in ApiSeatState]: number}) => void
}

export const Context = React.createContext({} as ISeatsStateContext)

type UpdatedSeatsData = {
  seats?: {[key: string]: Array<{uuid: UUID; updatedAt: string}>}
  zones?: {
    updated?: {
      [key: string]: {
        states: {[key in ApiSeatState]: number}
        updatedAt: string
      }
    }
    zoneBeforeSelection?: {
      [key: string]: {
        states: {[key in ApiSeatState]: number}
        updatedAt: string
      }
    }
  }
}

type AllSeatsData = {
  seats: {[uuid: string]: SeatState}
  zones: {[uuid: string]: ZoneState}
}

type Props = {
  children: ReactNode
  eventId: number
}

type State = {
  seatsState: ISeatsState | null
  zoneBeforeSelection: {[uuid: string]: ZoneState} | null
  ignoreNextDeselectAll: boolean
}

// Handle transformations for data that come via socket, and only
// apply changes in case that the data are newer.
// Note: this just transforms the data, but do not save them.
// Therefore it should be easy to test this object if we want to.
const DataTransformer = {
  __processUpdatedSeatsData: (
    seatsState: ISeatsState,
    data: Array<{uuid: UUID; updatedAt: string}>,
    state: ApiSeatState
  ) => {
    return data.reduce((res: {[uuid: string]: SeatState}, d) => {
      const {uuid, updatedAt} = d

      const current = seatsState.seats[uuid]
      return current.updatedAt < updatedAt
        ? {
            ...res,
            [uuid]: {
              ...seatsState.seats[uuid],
              updatedAt,
              state
            }
          }
        : res
    }, {})
  },
  transformUpdatedSeats: (
    seatsState: ISeatsState,
    seats: UpdatedSeatsData['seats']
  ): ISeatsState['seats'] => {
    return seats
      ? Object.entries(seats).reduce((result, [_state, updatedSeats]) => {
          const state = _state as ApiSeatState
          return {
            ...result,
            ...DataTransformer.__processUpdatedSeatsData(
              seatsState,
              updatedSeats,
              state
            )
          }
        }, {})
      : {}
  },
  transformUpdatedZones: (
    seatsState: ISeatsState,
    zones: UpdatedSeatsData['zones']
  ): ISeatsState['zones'] => {
    return zones && zones.updated
      ? _.mapValues(zones.updated, (d: ZoneState, uuid: string) => {
          const current = seatsState.zones[uuid]
          return current.updatedAt < d.updatedAt ? d : current
        })
      : {}
  },
  transformAllSeatData: (
    seatsState: ISeatsState | null,
    data: AllSeatsData
  ): ISeatsState => {
    if (!seatsState) return data

    const seats = _.mapValues(data.seats, (v, uuid) => {
      const current = seatsState.seats[uuid]
      return !current || current.updatedAt < v.updatedAt ? v : current
    })
    const zones = _.mapValues(data.zones, (v, uuid) => {
      const current = seatsState.zones[uuid]
      return !current || current.updatedAt < v.updatedAt ? v : current
    })
    return {seats, zones}
  }
}

// Note: I could not make this work with `useEffect` hook, was receiving strage socket erros,
// when rewritten to class based component, it seam to work
export class SeatsStateProvider extends React.Component<Props, State> {
  // eslint-disable-next-line no-undef
  socket: SocketIOClient.Socket
  pendingChanges: Array<UpdatedSeatsData>

  constructor(props: Props) {
    super(props)
    this.socket = io()
    this.pendingChanges = []
    this.state = {
      seatsState: null,
      ignoreNextDeselectAll: true,
      zoneBeforeSelection: null
    }
  }

  setIgnoreNextDeselectAll = (ignoreNextDeselectAll: boolean) =>
    this.setState({ignoreNextDeselectAll})

  onUpdatedSeatsData = (data: UpdatedSeatsData) => {
    const {seatsState} = this.state
    if (!seatsState) {
      return
    }
    const {seats, zones} = data

    const updatedSeats = DataTransformer.transformUpdatedSeats(
      seatsState,
      seats
    )
    const updatedZones = DataTransformer.transformUpdatedZones(
      seatsState,
      zones
    )
    const newState = {
      zones: {
        ...seatsState.zones,
        ...updatedZones
      },
      seats: {
        ...seatsState.seats,
        ...updatedSeats
      }
    }

    const zoneBeforeSelection =
      zones && zones.zoneBeforeSelection ? zones.zoneBeforeSelection : null

    this.setState({
      seatsState: newState,
      zoneBeforeSelection: zoneBeforeSelection || this.state.zoneBeforeSelection
    })
  }

  componentDidMount() {
    this.socket.on('custom_error', (err: any) => {
      // eslint-disable-next-line no-console
      console.error('Unhandled socket error', err)
    })

    this.socket.on('connect', () => {
      this.socket.emit('revolt_registerRoom', this.props.eventId)
    })

    this.socket.on('allSeatsData', (data: AllSeatsData) => {
      this.setState({
        seatsState: DataTransformer.transformAllSeatData(
          this.state.seatsState,
          data
        )
      })

      // Apply pending changes if any, so that no updates are lost
      // TODO: (Martin Petro), prepare some testing mechanism for that
      // as this case is hard to test
      this.pendingChanges.forEach((data) => {
        this.onUpdatedSeatsData(data)
      })
      this.pendingChanges = []
    })

    this.socket.on('updatedSeatsData', (data: UpdatedSeatsData) => {
      const {seatsState} = this.state

      if (!seatsState) {
        // If the "initialData" did not arive yet, but we received some changes
        // sooner, we store them in "pendingChanges", and apply them layer on.
        this.pendingChanges.push(data)
        return
      }
      this.onUpdatedSeatsData(data)
    })
  }

  componentWillUnmount() {
    this.socket.emit('leaveRoom', this.props.eventId)
  }

  onSeatsSelected = (uuids: Array<UUID>) => {
    // Do not emit before the "room" was initiated
    if (!this.state.seatsState) {
      return
    }

    this.socket.emit('revolt_seatsSelection', {
      uuids,
      eventId: this.props.eventId
    })
  }

  onZoneSelected = (uuid: UUID) => {
    // Do not emit before the "room" was initiated
    if (!this.state.seatsState) {
      return
    }

    this.socket.emit('revolt_zoneSelection', {
      uuid,
      eventId: this.props.eventId
    })
  }

  updateSeatsState = (uuids: Array<UUID>, state: ApiSeatState) => {
    this.socket.emit('revolt_changeSeatsState', {
      uuids,
      state,
      eventId: this.props.eventId
    })
  }

  updateZoneState = (uuid: UUID, states: {[key in ApiSeatState]: number}) => {
    this.socket.emit('revolt_changeZoneState', {
      uuid,
      states,
      eventId: this.props.eventId
    })
  }

  resetZoneBeforeSelection = () => {
    this.setState({zoneBeforeSelection: null})
  }

  render() {
    const context = {
      seatsState: this.state.seatsState,
      zoneBeforeSelection: this.state.zoneBeforeSelection,
      resetZoneBeforeSelection: this.resetZoneBeforeSelection,
      ignoreNextDeselectAll: this.state.ignoreNextDeselectAll,
      setIgnoreNextDeselectAll: this.setIgnoreNextDeselectAll,
      onSeatsSelected: this.onSeatsSelected,
      onZoneSelected: this.onZoneSelected,
      updateSeatsState: this.updateSeatsState,
      updateZoneState: this.updateZoneState
    }

    return (
      <Context.Provider value={context}>{this.props.children}</Context.Provider>
    )
  }
}

export const useSeatsState = () => useContext(Context)
