import {Button, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {SyntheticEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {
  PermissionCode,
  TicketTypePricingPropertiesFragment
} from '../../../../../../__generated__/schema'

import {TicketColor} from '../../../../../../shared'
import {Theme} from '../../../../../../theme'
import {useEnsurePermissions} from '../../../../../../utils/auth'

export {TicketColor} from '../../../../../../shared'

export const getUnavailableColors = (
  ticketTypes: Array<TicketTypePricingPropertiesFragment>
) => {
  return ticketTypes.reduce((res: Array<TicketColor>, item) => {
    return res.includes(item.color as TicketColor)
      ? res
      : [...res, item.color as TicketColor]
  }, [])
}

const useTicketMenuHeaderStyles = makeStyles<Theme>((theme) => ({
  listLabel: {
    fontWeight: 500
  },
  wrapper: {
    padding: theme.spacing(2)
  }
}))

interface ITicketTypeMenuHeaderProps {
  label: string
  onCreateNewTicket: (e: SyntheticEvent) => any
  hideCreate: boolean
}

export const TicketTypeMenuHeader: React.FC<ITicketTypeMenuHeaderProps> = ({
  label,
  onCreateNewTicket,
  hideCreate
}: ITicketTypeMenuHeaderProps) => {
  const classes = useTicketMenuHeaderStyles()
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.wrapper}
    >
      <Typography className={classes.listLabel}>{label}</Typography>
      {!hideCreate && P([PermissionCode.CreateTicketType]) && (
        <Button color="primary" onClick={onCreateNewTicket}>
          {t('Create new')}
        </Button>
      )}
    </Grid>
  )
}
