export enum ObjectsPanelType {
  SingleSeat = 'singleSeat',
  MultipleSeats = 'multipleSeats',
  SingleZone = 'singleZone',
  MultipleZones = 'multipleZones',
  SingleIcon = 'singleIcon',
  MultipleIcons = 'multipleIcons',
  SingleRectangle = 'singleRectangle',
  MultipleRectangles = 'multipleRectangles',
  SingleLine = 'singleLine',
  MultipleLines = 'multipleLines',
  SingleEllipse = 'singleEllipse',
  MultipleEllipses = 'multipleEllipses',
  MultipleShapes = 'multipleShapes',
  SingleText = 'singleText',
  MultipleTexts = 'multipleTexts',
  MixWithAttendeeNavigation = 'mixWithAttendeeNavigation',
  MinimalMix = 'minimalMix',
  MixZonesAndTexts = 'mixZonesAndTexts',
  MixWithLabel = 'mixWithLabel',
  MixWithDimensions = 'mixWithDimensions'
}
