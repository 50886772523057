import {Box, Paper, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {ApiContact} from '../../../../../__generated__/schema'
import {ReadOnlyRow} from '../../../../common/ReadOnly'

interface IContactSectionProps {
  id: string
  label: string
  contact: ApiContact
}

export const ContactSection: React.FC<IContactSectionProps> = ({
  id,
  label,
  contact
}: IContactSectionProps) => {
  const {t} = useTranslation()
  return (
    <Box id={id}>
      <Typography variant="subtitle1" sx={{pb: 1}}>
        {label}
      </Typography>
      <Paper variant="outlined" sx={{p: 2}}>
        <ReadOnlyRow label={t('Name')}>{contact.name}</ReadOnlyRow>
        {contact.role && (
          <ReadOnlyRow label={t('Role')}>{contact.role}</ReadOnlyRow>
        )}
        <ReadOnlyRow label={t('Email')}>{contact.email}</ReadOnlyRow>
        {contact.phone && (
          <ReadOnlyRow label={t('Phone')}>{contact.phone}</ReadOnlyRow>
        )}
      </Paper>
    </Box>
  )
}
