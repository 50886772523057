import {
  ShowAgeClassificationCode,
  ShowFormatCode,
  ShowSoundMixCode,
  ShowVersionCode
} from '../__generated__/schema'
import {useTranslateAgeClassificationAbbreviation} from './translateAgeClassification'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from './translateDistributions'

export const useGetDistributionLabelAbbreviation = () => {
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  return (args: {
    formatCode?: ShowFormatCode | null
    soundMixCode?: ShowSoundMixCode | null
    versionCode?: ShowVersionCode | null
    ageClassificationCode?: ShowAgeClassificationCode | null
  }) =>
    [
      args.formatCode && translateShowFormatAbbreviation(args.formatCode),
      args.soundMixCode && translateShowSoundMixAbbreviation(args.soundMixCode),
      args.versionCode && translateShowVersionAbbreviation(args.versionCode),
      args.ageClassificationCode &&
        translateAgeClassificationAbbreviation(args.ageClassificationCode)
    ]
      .filter(Boolean)
      .join(' • ')
}
