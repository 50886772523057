import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {WebsiteDetail} from './detail'
import {WebsitePageContextProvider} from './detail/WebsitePageContext'
import {WebsitesPage} from './WebsitesPage'

export const Websites: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadWebsite]) && (
        <Route path={routeTo.admin.websites.detail(':websiteId')}>
          <WebsitePageContextProvider>
            <WebsiteDetail />
          </WebsitePageContextProvider>
        </Route>
      )}
      <Route
        path={routeTo.admin.websites.index()}
        component={WebsitesPage}
        exact
      />
    </Switch>
  )
}
