import {Box, Paper, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  GetCustomerQuery,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {
  SectionGrid,
  SectionGridCouple,
  SectionGridSubtitle
} from '../../../../common'

interface ILoyaltyScoreSectionProps {
  id: string
  title: string
  loyaltyScore: GetCustomerQuery['customer']['loyaltyScore']
}

export const LoyaltyScoreSection: React.FC<ILoyaltyScoreSectionProps> = ({
  id,
  title,
  loyaltyScore
}: ILoyaltyScoreSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
  const {formatDateNumeric, formatTime} = useDateTimeFormatters()
  const updatedAt = new Date(loyaltyScore.updatedAt)
  return (
    <Box id={id}>
      <Typography variant="subtitle1" sx={{pb: 2, pl: 2}}>
        {title}
      </Typography>
      <Paper variant="outlined">
        <SectionGrid>
          <SectionGridSubtitle subtitle={t('Purchases')} />
          <SectionGridCouple label={t('Amount')}>
            {translateEffectiveClientPrice(loyaltyScore.totalPurchaseAmount)}
          </SectionGridCouple>
          <SectionGridCouple label={t('Sold carts')}>
            {t('{{count}} cart', {count: loyaltyScore.soldCartsCount})}
          </SectionGridCouple>
          <SectionGridCouple label={t('Sales')}>
            {t('{{count}} sale', {count: loyaltyScore.salesCount})}
          </SectionGridCouple>
          <SectionGridCouple label={t('Updated')}>
            {[formatDateNumeric(updatedAt), formatTime(updatedAt)].join(' • ')}
          </SectionGridCouple>
        </SectionGrid>
        <SectionGrid>
          <SectionGridSubtitle subtitle={t('Tickets')} />
          <SectionGridCouple label={t('Total tickets')}>
            {t('{{count}} ticket', {count: loyaltyScore.totalTicketsCount})}
          </SectionGridCouple>
          <SectionGridCouple label={t('Paid tickets')}>
            {t('{{count}} ticket', {count: loyaltyScore.paidTicketsCount})}
          </SectionGridCouple>
          <SectionGridCouple label={t('Free tickets')}>
            {t('{{count}} ticket', {count: loyaltyScore.freeTicketsCount})}
          </SectionGridCouple>
        </SectionGrid>
        {P([PermissionCode.FeatureFlagEntradio_3099]) && (
          <SectionGrid>
            <SectionGridSubtitle subtitle={t('Reservations')} />
            <SectionGridCouple label={t('Reserved cart')}>
              {t('{{count}} cart', {count: loyaltyScore.reservedCartsCount})}
            </SectionGridCouple>
            <SectionGridCouple label={t('Reservations')}>
              {t('{{count}} reservation', {
                count: loyaltyScore.reservationsCount
              })}
            </SectionGridCouple>
          </SectionGrid>
        )}
      </Paper>
    </Box>
  )
}
