import {Dayjs} from 'dayjs'
import {ProductPricingInput} from '../../../../../__generated__/schema'
import {convertValueToFloat} from '../../../../../utils/conversions'

export enum ProductAvailabilityFormField {
  DivisionId = 'divisionId',
  IsAvailableOnRetailChannel = 'isAvailableOnRetailChannel',
  IsAvailableOnECommerceChannel = 'isAvailableOnECommerceChannel'
}

export interface IProductAvailabilityForm {
  [ProductAvailabilityFormField.DivisionId]: number
  [ProductAvailabilityFormField.IsAvailableOnRetailChannel]: boolean
  [ProductAvailabilityFormField.IsAvailableOnECommerceChannel]: boolean
}

export enum ProductBarcodeFormField {
  Code = 'code',
  Description = 'description'
}

export interface IProductBarcodeForm {
  [ProductBarcodeFormField.Code]: string
  [ProductBarcodeFormField.Description]?: string
}

export enum PricingEnabled {
  Now = 'now',
  Schedule = 'schedule'
}

export enum ProductPricingFormField {
  DivisionId = 'divisionId',
  PricingEnabled = 'pricingEnabled',
  Datetime = 'datetime',
  RetailPrice = 'retailPrice',
  RetailVatRate = 'retailVatRate',
  ECommercePrice = 'eCommercePrice',
  ECommerceVatRate = 'eCommerceVatRate'
}

export interface IProductPricingForm {
  [ProductPricingFormField.DivisionId]: number
  [ProductPricingFormField.PricingEnabled]: PricingEnabled
  [ProductPricingFormField.Datetime]?: Dayjs | null
  [ProductPricingFormField.RetailPrice]: number
  [ProductPricingFormField.RetailVatRate]: string
  [ProductPricingFormField.ECommercePrice]: number
  [ProductPricingFormField.ECommerceVatRate]: string
}

export const getProductPricingInput = (
  data: IProductPricingForm
): ProductPricingInput => {
  const startsAt = data[ProductPricingFormField.Datetime]?.toISOString()

  return {
    divisionId: data[ProductPricingFormField.DivisionId],
    startsAt:
      data[ProductPricingFormField.PricingEnabled] === PricingEnabled.Schedule
        ? startsAt
        : undefined,
    retailPrice:
      convertValueToFloat(data[ProductPricingFormField.RetailPrice]) || 0,
    retailVatRate: parseInt(data[ProductPricingFormField.RetailVatRate], 10),
    eCommercePrice:
      convertValueToFloat(data[ProductPricingFormField.RetailPrice]) || 0,
    eCommerceVatRate: parseInt(data[ProductPricingFormField.RetailVatRate], 10)
  }
}

export enum WarehouseProductFormField {
  IsNegativeStockEnabled = 'isNegativeStockEnabled',
  MinStockLevel = 'minStockLevel',
  OptimalStockLevel = 'optimalStockLevel'
}

export interface IWarehouseProductForm {
  [WarehouseProductFormField.IsNegativeStockEnabled]: boolean
  [WarehouseProductFormField.MinStockLevel]?: number
  [WarehouseProductFormField.OptimalStockLevel]?: number
}
