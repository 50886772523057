import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SettingsIcon from '@mui/icons-material/Settings'
import {Button, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import dayjs, {Dayjs} from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {Tooltip} from '../../../../common'
import {PopoverDatePicker} from '../../../../common/PopoverDatePicker'
import {ViewType} from './types'
import {ViewOptions} from './ViewOptions'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap'
  },
  title: {
    flexGrow: 1
  },
  icon: {
    color: theme.palette.common.black
  }
}))

interface ICalendarHeaderProps {
  onToday: (e: any) => any
  onPrev: (e: any) => any
  onNext: (e: any) => any
  selectedDate: Date
  changeDate: (date: Date) => void
  title: string
  viewType: ViewType
  changeView: (viewType: ViewType) => void
  openSettingsDialog: () => void
  prevTooltipTitle: string
  nextTooltipTitle: string
}

export const CalendarHeader: React.FC<ICalendarHeaderProps> = ({
  onToday,
  onPrev,
  onNext,
  selectedDate,
  changeDate,
  title,
  viewType,
  changeView,
  prevTooltipTitle,
  nextTooltipTitle,
  openSettingsDialog
}: ICalendarHeaderProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const onChange = useCallback(
    (date: Dayjs | null) => {
      if (date) {
        changeDate(date.toDate())
      }
    },
    [changeDate]
  )
  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary" onClick={onToday}>
        {t('Today')}
      </Button>
      <Tooltip title={prevTooltipTitle}>
        <IconButton onClick={onPrev}>
          <ChevronLeftIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <PopoverDatePicker
        value={dayjs(selectedDate)}
        onChange={onChange}
        calendarIconClassName={classes.icon}
        tooltipTitle={t('Choose date')}
      />
      <Tooltip title={nextTooltipTitle}>
        <IconButton onClick={onNext}>
          <ChevronRightIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Tooltip title={t<string>('Settings')}>
        <IconButton onClick={openSettingsDialog}>
          <SettingsIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <ViewOptions value={viewType} changeView={changeView} />
    </div>
  )
}
