import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {sortBy} from 'lodash'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ClaimPropertiesFragment} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../../theme'
import {ListOfItemsSeparatedByDividers} from '../../../../common/ListOfItemsSeparatedByDividers'
import {useRemoveItemsFromClaim} from '../../claims/graphql'
import {Item} from './Item'
import {ItemsHeader} from './ItemsHeader'

const useStyles = makeStyles<Theme>((theme) => ({
  noItemsRoot: {
    display: 'flex',
    gap: theme.spacing(0, 1),
    padding: theme.spacing(2),
    alignItems: 'center'
  }
}))

interface IItemsSectionProps {
  claim: ClaimPropertiesFragment
}

export const ItemsSection: React.FC<IItemsSectionProps> = ({
  claim
}: IItemsSectionProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const items = sortBy(claim.items || [], 'id')
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const removeItemsFromClaim = useRemoveItemsFromClaim()

  const onRemoveIconClick = useCallback(
    async (claimId: number, itemId: number) => {
      try {
        setShowBackdrop(true)
        await removeItemsFromClaim({claimId, itemIds: [itemId]})
      } catch (e) {
        defaultErrorHandler(e, 'Error while removing items from claim.')
      } finally {
        setShowBackdrop(false)
      }
    },
    [defaultErrorHandler, removeItemsFromClaim, setShowBackdrop]
  )

  return (
    <>
      <ItemsHeader claim={claim} />
      <Divider />
      {items.length ? (
        <ListOfItemsSeparatedByDividers isFullWidth>
          {items.map((item) => (
            <Item
              key={item.id}
              item={item}
              claimState={claim.state}
              claimId={claim.id}
              onRemoveIconClick={onRemoveIconClick}
            />
          ))}
        </ListOfItemsSeparatedByDividers>
      ) : (
        <div className={classes.noItemsRoot}>
          <Typography variant="body2" color="textSecondary">
            {t('No items found')}
          </Typography>
        </div>
      )}
    </>
  )
}
