import CloseIcon from '@mui/icons-material/Close'
import {Button, Drawer, drawerClasses} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {DrawerTemplateWithSideNavigation} from '../DrawerTemplateWithSideNavigation'
import {DrawerTemplateHeader} from '../DrawerUtils'
import {
  BusinessPartnerForm,
  IBusinessPartnerFormProps
} from './BusinessPartnerForm'
import {useNavigationItems} from './utils'

const CREATE_BUSINESS_PARTNER_FORM_ID = 'createBusinessPartnerFormId'

interface ICreateBusinessPartnerDrawerProps
  extends Pick<IBusinessPartnerFormProps, 'onSubmit' | 'defaultValues'> {
  isOpen: boolean
  onClose: () => void
}

export const CreateBusinessPartnerDrawer: React.FC<ICreateBusinessPartnerDrawerProps> =
  ({
    isOpen,
    onClose,
    defaultValues,
    onSubmit
  }: ICreateBusinessPartnerDrawerProps) => {
    const {t} = useTranslation()
    const navigationItems = useNavigationItems()
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 960, width: '100%'}}}
      >
        <DrawerTemplateWithSideNavigation
          DrawerTemplateProps={{
            header: (
              <DrawerTemplateHeader
                title={t('Create business partner')}
                onLeftActionClick={onClose}
                LeftActionIcon={CloseIcon}
              />
            ),
            footer: (
              <Button
                color="primary"
                variant="contained"
                type="submit"
                form={CREATE_BUSINESS_PARTNER_FORM_ID}
              >
                {t('Create')}
              </Button>
            )
          }}
          navigationItems={navigationItems}
        >
          <BusinessPartnerForm
            formId={CREATE_BUSINESS_PARTNER_FORM_ID}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            navigationItems={navigationItems}
          />
        </DrawerTemplateWithSideNavigation>
      </Drawer>
    )
  }
