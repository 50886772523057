import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import {Box} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CashDeskTourTimeSlotQuery,
  ErrorMessages
} from '../../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../../utils/errors'
import {useDateTimeFormatters} from '../../../../../../utils/formatting'
import {useTourParams} from '../../../../../../utils/pathname'
import {routeTo} from '../../../../../../utils/routes'
import {RenderOnData} from '../../../../../common'
import {PageWithHeaderTemplate} from '../../../../../common/PageWithHeaderTemplate'
import {Blank} from '../../../../../visual/Blank'
import {SecondaryHeader} from '../../../Header'
import {useCurrentCart} from '../../CurrentCartContext'
import {useIncrementErrorHandler} from '../../utils'
import {
  useCashDeskTourTimeSlot,
  useDecrementTourItemQuantity,
  useIncrementTourItemQuantity,
  useRemoveAllTourItemsFromCartForTimeSlot
} from '../graphql'
import {ChildrenOnValidTourTimeSlot} from './ChildrenOnValidTourTimeSlot'
import {Content} from './Content'
import {SubCart} from './SubCart'
import {SubHeader} from './SubHeader'

export const TourTimeSlot: React.FC = () => {
  const {t} = useTranslation()
  const {tourTimeSlotId} = useTourParams()
  const {data, loading, error} = useCashDeskTourTimeSlot(tourTimeSlotId)
  const incrementTourItemQuantity = useIncrementTourItemQuantity()
  const decrementTourItemQuantity = useDecrementTourItemQuantity()
  const removeAllTourItemsFromCartForTimeSlot =
    useRemoveAllTourItemsFromCartForTimeSlot()
  const {defaultErrorHandler, setShowBackdrop} = useMutationAssistanceHooks()
  const incrementErrorHandler = useIncrementErrorHandler()
  const {currentCartId, initializeCurrentCart, updateCurrentCart} =
    useCurrentCart()
  const [title, setTitle] = useState<string>(
    t<string>('Tour time slot {{id}}', {id: tourTimeSlotId})
  )
  const history = useHistory()
  const {formatDate, formatTime} = useDateTimeFormatters()
  const handleLeftActionClick = useCallback(
    () => history.push(routeTo.admin.cashDesk.tours()),
    [history]
  )
  useEffect(() => {
    if (data) {
      const startsAtDate = new Date(data.tourTimeSlot.startsAt)
      setTitle(
        [
          formatDate(startsAtDate),
          formatTime(startsAtDate),
          data.tourTimeSlot.tour.name
        ].join(' • ')
      )
    }
  }, [data, formatDate, formatTime])
  const handleIncrementButtonClick = useCallback(
    async (
      admissionTypeAssignmentId: number,
      increment: number,
      admissionTypeName: string
    ) => {
      if (data) {
        try {
          setShowBackdrop(true)
          const {data: incrementTourItemData} = await incrementTourItemQuantity(
            {
              cartId: currentCartId,
              tourTimeSlotId: data.tourTimeSlot.id,
              admissionTypeAssignmentId,
              increment
            }
          )
          if (incrementTourItemData?.incrementTourItemQuantity) {
            if (!currentCartId) {
              initializeCurrentCart(
                incrementTourItemData.incrementTourItemQuantity
              )
            } else {
              updateCurrentCart()
            }
          }
        } catch (error) {
          incrementErrorHandler(error, admissionTypeName)
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      data,
      incrementErrorHandler,
      incrementTourItemQuantity,
      initializeCurrentCart,
      setShowBackdrop,
      updateCurrentCart
    ]
  )
  const handleDecrementButtonClick = useCallback(
    async (admissionTypeAssignmentId: number, decrement: number) => {
      if (data && currentCartId) {
        try {
          setShowBackdrop(true)
          const {data: decrementData} = await decrementTourItemQuantity({
            cartId: currentCartId,
            tourTimeSlotId: data.tourTimeSlot.id,
            admissionTypeAssignmentId,
            decrement
          })
          if (decrementData?.decrementTourItemQuantity) {
            updateCurrentCart()
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while decrementing tour item quantity')
          )
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      currentCartId,
      data,
      decrementTourItemQuantity,
      defaultErrorHandler,
      setShowBackdrop,
      t,
      updateCurrentCart
    ]
  )
  const handleRemoveButtonClick = useCallback(async () => {
    if (currentCartId && data) {
      try {
        setShowBackdrop(true)
        const {data: removeData} = await removeAllTourItemsFromCartForTimeSlot({
          cartId: currentCartId,
          tourTimeSlotId: data.tourTimeSlot.id
        })
        if (removeData?.removeAllTourItemsFromCartForTimeSlot) {
          updateCurrentCart()
        }
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while removing tour items from cart')
        )
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    currentCartId,
    data,
    defaultErrorHandler,
    removeAllTourItemsFromCartForTimeSlot,
    setShowBackdrop,
    t,
    updateCurrentCart
  ])
  const handleDiscountsButtonClick = useCallback(
    () =>
      history.push(
        routeTo.admin.cashDesk.tourTimeSlotApplyDiscounts(tourTimeSlotId)
      ),
    [history, tourTimeSlotId]
  )
  return (
    <PageWithHeaderTemplate
      header={
        <SecondaryHeader
          title={title}
          hasArrowBackIcon
          onLeftActionClick={handleLeftActionClick}
        />
      }
    >
      <RenderOnData<CashDeskTourTimeSlotQuery>
        data={data}
        loading={loading}
        error={error}
        ignoreLoadingIfData
        dataCondition={(data) => Boolean(data.tourTimeSlot)}
        errorChildren={(error) =>
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.TourTimeSlotNotFound
          ) ? (
            <Blank
              title={t('Tour time slot not found')}
              IconComp={ReportProblemOutlinedIcon}
            />
          ) : (
            <Blank
              title={t('Error while loading tour time slot')}
              IconComp={ReportProblemOutlinedIcon}
            />
          )
        }
      >
        {({tourTimeSlot}) => (
          <ChildrenOnValidTourTimeSlot tourTimeSlot={tourTimeSlot}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateAreas: `
                "subCart subHeader" 
                "subCart viewBox"
              `,
                gridTemplateRows: 'auto 1fr',
                gridTemplateColumns: '360px 1fr',
                height: '100%'
              }}
            >
              <Box sx={{gridArea: 'subHeader'}}>
                <SubHeader tourTimeSlot={tourTimeSlot} />
              </Box>
              <SubCart
                tourTimeSlotId={tourTimeSlot.id}
                sx={{gridArea: 'subCart'}}
                onRemoveButtonClick={handleRemoveButtonClick}
                onDiscountsButtonClick={handleDiscountsButtonClick}
              />
              <Box sx={{gridArea: 'viewBox', overflowY: 'auto'}}>
                <Content
                  tourTimeSlot={tourTimeSlot}
                  onIncrementButtonClick={handleIncrementButtonClick}
                  onDecrementButtonClick={handleDecrementButtonClick}
                />
              </Box>
            </Box>
          </ChildrenOnValidTourTimeSlot>
        )}
      </RenderOnData>
    </PageWithHeaderTemplate>
  )
}
