import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {ProductGroupDetail} from './productGroupDetail'
import {ProductGroupsPage} from './ProductGroupsPage'

export const ProductGroups: React.FC = () => {
  const {P} = useEnsurePermissions()
  return (
    <Switch>
      {P([PermissionCode.ReadProductGroup]) && (
        <Route
          path={routeTo.admin.productGroups.detail(':productGroupId')}
          component={ProductGroupDetail}
        />
      )}
      <Route
        path={routeTo.admin.productGroups.index()}
        component={ProductGroupsPage}
        exact
      />
    </Switch>
  )
}
