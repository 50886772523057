import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  Client,
  ClientVatRegistered,
  ErrorMessages
} from '../../../../../../__generated__/schema'
import {useEffectiveClientCurrencySignInputProps} from '../../../../../../hooks/effectiveClientCurrencySignInputProps'
import {useMutationAssistanceHooks} from '../../../../../../hooks/mutationAssistanceHooks'
import {useHandleInvalidDateTimes} from '../../../../../../hooks/pickerErrors'
import {Theme} from '../../../../../../theme'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../../../utils/errors'
import {useIsTicketPriceValid} from '../../../../../../utils/formsValidations'
import {FormSwitchInput} from '../../../../../form/FormSwitchInput'
import {UncontrolledFormTextInput} from '../../../../../form/FormTextInput'
import {FormDatetimeInput} from '../../../../../form/pickers'
import {UncontrolledFormSelect} from '../../../../../form/UncontrolledFormSelect'
import {FormChipsGroupSelect} from '../../../../../visual'
import {useCountryVatRate} from '../../../graphql'
import {
  EventTicketTypeFormField,
  IPricingForm,
  PricingFormField,
  ScheduleApproach
} from './types'

const getTicketTypeFieldName = (
  index: number,
  ticketTypeFormField: EventTicketTypeFormField
) => `${PricingFormField.TICKET_TYPES}[${index}].${ticketTypeFormField}`

const useStyles = makeStyles<
  Theme,
  {scheduleApproach?: ScheduleApproach; showVatRate: boolean}
>((theme) => ({
  formGrid: {
    display: 'grid',
    gridTemplateColumns: ({showVatRate}) =>
      showVatRate ? 'auto 1fr 1fr auto' : 'auto 1fr 1fr',
    gap: theme.spacing(0, 3),
    gridTemplateAreas: ({showVatRate}) =>
      showVatRate
        ? `
      "chips enabledFrom enabledFrom enabledFrom"
      "labelsGrid labelsGrid labelsGrid labelsGrid"
      "ticketTypesGrid ticketTypesGrid ticketTypesGrid ticketTypesGrid"
    `
        : `
      "chips enabledFrom enabledFrom"
      "labelsGrid labelsGrid labelsGrid"
      "ticketTypesGrid ticketTypesGrid ticketTypesGrid"`
  },
  requiredLabel: {
    padding: theme.spacing(1, 0, 2)
  },
  labelsGrid: {
    gridArea: 'labelsGrid',
    display: 'grid',
    gridTemplateColumns: ({showVatRate}) =>
      showVatRate ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr',
    gap: theme.spacing(0, 2),
    paddingTop: theme.spacing(6)
  },
  promotionColumnLabel: {
    justifySelf: 'flex-end',
    paddingRight: theme.spacing(1.5)
  },
  dateTimeFormField: {
    position: 'relative',
    top: theme.spacing(3.5),
    maxWidth: 300,
    alignSelf: 'flex-end',
    gridArea: 'enabledFrom',
    visibility: ({scheduleApproach}) =>
      scheduleApproach !== ScheduleApproach.Now ? 'visible' : 'hidden'
  },
  ticketLabel: {
    paddingLeft: theme.spacing(2)
  },
  ticketTypesGrid: {
    display: 'grid',
    gridArea: 'ticketTypesGrid',
    gridAutoFlow: 'row',
    gridTemplateColumns: ({showVatRate}) =>
      showVatRate ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr',
    gap: theme.spacing(4, 2),
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  priceAndVatColumn: {
    alignSelf: 'flex-start',
    height: 48
  },
  promotionColumn: {
    justifySelf: 'flex-end'
  }
}))

interface IPricingFormProps {
  onSubmit: (data: IPricingForm) => void
  submitErrorMessage: string

  formId: string
  defaultValues: Partial<IPricingForm>
  clientInfo: Pick<Client, 'VATRegistered' | 'countryCode'>
}

export const PricingForm: React.FC<IPricingFormProps> = ({
  onSubmit,
  submitErrorMessage,
  formId,
  defaultValues,
  clientInfo
}: IPricingFormProps) => {
  const {t} = useTranslation()

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearError,
    errors,
    unregister,
    watch,
    triggerValidation,
    control
  } = useForm<IPricingForm>({
    defaultValues
  })
  const isTicketPriceValid = useIsTicketPriceValid()
  const {setShowBackdrop, defaultErrorHandler} = useMutationAssistanceHooks()
  const {
    data: countryVatRateData,
    error: countryVatRateError,
    loading: isCountryVatRateLoading
  } = useCountryVatRate(clientInfo.countryCode)

  useEffect(
    () =>
      isCountryVatRateLoading ? setShowBackdrop(true) : setShowBackdrop(false),
    [isCountryVatRateLoading, setShowBackdrop]
  )
  useEffect(() => {
    if (countryVatRateError) {
      defaultErrorHandler(
        countryVatRateError,
        t('Error while loading country VAT rates')
      )
    }
  }, [countryVatRateError, defaultErrorHandler, t])

  const {ticketTypes} = defaultValues
  const currencySignInputProps = useEffectiveClientCurrencySignInputProps()
  const handleInvalidDateTimes =
    useHandleInvalidDateTimes<IPricingForm>(setError)
  const _handleSubmit = useCallback(
    async (data: IPricingForm) => {
      try {
        await onSubmit(data)
      } catch (err) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            err,
            ErrorMessages.NotUniqueEventPricingStartsAt
          )
        ) {
          setError(
            PricingFormField.DATETIME,
            t('Chosen time overlaps existing pricing.')
          )
        } else if (
          getGraphQLErrorRelatedToErrorMessage(
            err,
            ErrorMessages.EventPricingStartsAtInThePast
          )
        ) {
          setError(
            PricingFormField.DATETIME,
            t("Pricing can't start in the past.")
          )
        } else {
          defaultErrorHandler(err, submitErrorMessage)
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, submitErrorMessage, t]
  )

  const showVatRate = clientInfo.VATRegistered !== ClientVatRegistered.None
  const scheduleApproach = watch(PricingFormField.SCHEDULE_APPROACH)
  const classes = useStyles({scheduleApproach, showVatRate})

  return (
    <form
      onSubmit={handleSubmit(handleInvalidDateTimes(_handleSubmit))}
      id={formId}
      className={classes.formGrid}
    >
      <div>
        <Typography
          className={classes.requiredLabel}
          component="div"
          variant="caption"
          color="textSecondary"
        >
          {t('All fields are required')}
        </Typography>
        <FormChipsGroupSelect<IPricingForm, ScheduleApproach>
          validationOptions={{
            required: true
          }}
          errors={errors}
          register={register}
          setValue={setValue}
          unregister={unregister}
          watch={watch}
          label={t('Pricing enabled')}
          name={PricingFormField.SCHEDULE_APPROACH}
          items={[
            {
              label: t('Now'),
              value: ScheduleApproach.Now
            },
            {
              label: t('Schedule'),
              value: ScheduleApproach.Schedule
            }
          ]}
        />
      </div>

      <div className={classes.dateTimeFormField}>
        {scheduleApproach === ScheduleApproach.Schedule && (
          <FormDatetimeInput<IPricingForm>
            dataTimePickerProps={{
              label: t('Enabled from'),
              disablePast: true
            }}
            validationOptions={{
              required: true
            }}
            fullWidth
            name={PricingFormField.DATETIME}
            unregister={unregister}
            setError={setError}
            clearError={clearError}
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
          />
        )}
      </div>
      <div className={classes.labelsGrid}>
        <Typography variant="caption" color="textSecondary">
          {t('Ticket type')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('Price')}
        </Typography>
        {showVatRate && (
          <Typography variant="caption" color="textSecondary">
            {t('VAT rate')}
          </Typography>
        )}
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.promotionColumnLabel}
        >
          {t('Discounts')}
        </Typography>
      </div>
      <div className={classes.ticketTypesGrid}>
        {ticketTypes &&
          ticketTypes.map((ticketType, index) =>
            [
              <input
                type="hidden"
                name={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.TICKET_TYPE_ID
                )}
                key={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.TICKET_TYPE_ID
                )}
                ref={register()}
              />,
              <input
                type="hidden"
                name={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.NAME
                )}
                key={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.NAME
                )}
                ref={register()}
              />,
              <input
                type="hidden"
                name={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.DESCRIPTION
                )}
                key={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.DESCRIPTION
                )}
                ref={register()}
              />,
              <div key={`name-and-description-of-item-${index}`}>
                <Typography variant="body2">
                  {ticketType[EventTicketTypeFormField.NAME]}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {ticketType[EventTicketTypeFormField.DESCRIPTION]}
                </Typography>
              </div>,
              <div
                className={classes.priceAndVatColumn}
                key={`priceColumn-${index}`}
              >
                <UncontrolledFormTextInput<IPricingForm>
                  InputProps={currencySignInputProps}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                  name={getTicketTypeFieldName(
                    index,
                    EventTicketTypeFormField.PRICE
                  )}
                  validationOptions={{
                    required: true,
                    validate: {
                      isTicketPriceValid
                    }
                  }}
                  triggerValidation={triggerValidation}
                  fullWidth
                />
              </div>,
              ...(showVatRate && countryVatRateData
                ? [
                    <div
                      className={classes.priceAndVatColumn}
                      key={`vatColumn-${index}`}
                    >
                      <UncontrolledFormSelect<IPricingForm>
                        selectOptions={countryVatRateData.countryVatRates.reduce(
                          (acc, vatRate) => ({
                            ...acc,
                            [vatRate]: t('{{vatRate}} %', {
                              vatRate
                            })
                          }),
                          {}
                        )}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                        key={getTicketTypeFieldName(
                          index,
                          EventTicketTypeFormField.VAT_RATE
                        )}
                        name={getTicketTypeFieldName(
                          index,
                          EventTicketTypeFormField.VAT_RATE
                        )}
                        validationOptions={{
                          required: true
                        }}
                        fullWidth
                      />
                    </div>
                  ]
                : []),
              <FormSwitchInput<IPricingForm>
                watch={watch}
                control={control}
                name={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.PROMOTIONS
                )}
                key={getTicketTypeFieldName(
                  index,
                  EventTicketTypeFormField.PROMOTIONS
                )}
                className={classes.promotionColumn}
                defaultValue
              />
            ].filter(Boolean)
          )}
      </div>
    </form>
  )
}
