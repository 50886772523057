import {
  AddressInput,
  DetailClientPropertiesFragment,
  ExistingCountryCode
} from '../../../../__generated__/schema'

export enum CompanyFormField {
  NAME = 'name',
  COUNTRY_CODE = 'countryCode',
  COMPANY_ID_NUMBER = 'companyIdNumber',
  TAX_ID = 'TAXId',
  VAT_ID = 'VATId',
  TICKET_DEFAULT_VAT_RATE = 'ticketDefaultVatRate',
  LEGAL_ADDRESS = 'legalAddress',
  MAILING_ADDRESS = 'mailingAddress',
  INVOICE_ADDRESS = 'invoiceAddress',
  TERMS_OF_SERVICE_URL = 'termsOfServiceUrl',
  MARKETING_INFORMATION_URL = 'marketingInformationUrl',
  E_COMMERCE_URL = 'eCommerceUrl'
}

export interface ICompanyForm {
  [CompanyFormField.NAME]: string
  [CompanyFormField.COUNTRY_CODE]: ExistingCountryCode
  [CompanyFormField.COMPANY_ID_NUMBER]: string
  [CompanyFormField.TAX_ID]?: string
  [CompanyFormField.VAT_ID]?: string
  [CompanyFormField.TICKET_DEFAULT_VAT_RATE]?: number
  [CompanyFormField.LEGAL_ADDRESS]: AddressInput
  [CompanyFormField.MAILING_ADDRESS]?: string
  [CompanyFormField.INVOICE_ADDRESS]?: string
  [CompanyFormField.TERMS_OF_SERVICE_URL]?: string
  [CompanyFormField.MARKETING_INFORMATION_URL]?: string
  [CompanyFormField.E_COMMERCE_URL]?: string
}

export const getDefaultValuesForCompanyForm = (
  company: DetailClientPropertiesFragment
): ICompanyForm => ({
  [CompanyFormField.NAME]: company.name,
  [CompanyFormField.COUNTRY_CODE]: company.countryCode,
  [CompanyFormField.COMPANY_ID_NUMBER]: company.companyIdNumber,
  [CompanyFormField.TAX_ID]: company.TAXId || undefined,
  [CompanyFormField.VAT_ID]: company.VATId || undefined,
  [CompanyFormField.TICKET_DEFAULT_VAT_RATE]:
    company.ticketDefaultVatRate || undefined,
  [CompanyFormField.LEGAL_ADDRESS]: company.legalAddress,
  [CompanyFormField.MAILING_ADDRESS]: company.mailingAddress || undefined,
  [CompanyFormField.INVOICE_ADDRESS]: company.invoiceAddress || undefined,
  [CompanyFormField.TERMS_OF_SERVICE_URL]:
    company.termsOfServiceUrl || undefined,
  [CompanyFormField.MARKETING_INFORMATION_URL]:
    company.marketingInformationUrl || undefined,
  [CompanyFormField.E_COMMERCE_URL]: company.eCommerceUrl || undefined
})
