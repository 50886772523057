import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const isValidDateWithMissingTimePart = (text?: string) => {
  if (typeof text !== 'string') {
    return false
  }
  return (
    dayjs(text.trim(), 'DD.MM.YYYY', true).isValid() ||
    dayjs(text.trim(), 'MM/DD/YYYY', true).isValid()
  )
}
