import {SxProps} from '@mui/material'
import {GridCellParams, GridValueFormatterParams} from '@mui/x-data-grid-pro'
import {clsx} from 'clsx'
import {isNil} from 'lodash'
import {useTranslateTransactionType} from '../../../hooks/translateTransactionType'
import {Theme} from '../../../theme'

export const getCellClassName = (params: GridCellParams<any, number>) =>
  !isNil(params.value) ? clsx('amount', {negative: params.value < 0}) : ''

export const commonTableSxProps: SxProps<Theme> = {
  '& .amount.negative': {color: 'error.main'}
}

export const useTransactionTypeFormatter = () => {
  const translateTransactionType = useTranslateTransactionType()
  return (params: GridValueFormatterParams) =>
    params.value && translateTransactionType(params.value)
}
