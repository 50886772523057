import {Box, Button, Chip, Paper, Typography} from '@mui/material'
import {styled} from '@mui/system'
import dayjs from 'dayjs'
import {identity, pickBy} from 'lodash'
import queryString from 'query-string'
import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode, SellingChannel} from '../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../hooks/sellingChannel'
import {PRIMARY_100_COLOR} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {routeTo} from '../../../../utils/routes'
import {InputRow} from '../../../common'
import {
  useTranslateDatePickerNowValues,
  useTranslateDatePickerPastValues
} from '../../../common/datePicker/translations'
import {TabNow, TabPast} from '../../../common/datePicker/types'
import {
  isNowValue,
  isPastValue,
  useTransformDatePickerValueToDate
} from '../../../common/datePicker/utils'
import {FormAutocompleteMultiple} from '../../../form/FormAutocompleteMultiple'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {FormDateInput} from '../../../form/pickers'
import {useLightweightUsers} from '../graphql'
import {IPaymentReportViewForm, PaymentReportViewFormField} from './types'

const PAYMENT_REPORT_VIEW_FORM_ID = 'paymentReportForm'

const StyledForm = styled('form')(({theme}) => ({
  display: 'grid',
  gridAutoFlow: 'row',
  gap: theme.spacing(3)
}))

export const PaymentReportView: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    errors,
    setValue,
    watch,
    register,
    unregister,
    setError,
    clearError,
    triggerValidation,
    control,
    handleSubmit
  } = useForm<IPaymentReportViewForm>()
  const {users} = useLightweightUsers(!P([PermissionCode.ReadUsers]))
  const history = useHistory()
  const isStringWithMaxLength = useIsStringWithMaxLength(1000)
  const translateDatePickerPastValues = useTranslateDatePickerPastValues()
  const translateDatePickerNowValues = useTranslateDatePickerNowValues()
  const transformDatePickerValueToDate = useTransformDatePickerValueToDate()
  const translateSellingChannel = useTranslateSellingChannel()
  const selectedSellingChannel = watch(PaymentReportViewFormField.Channel)
  const selectedDateFrom = watch(PaymentReportViewFormField.DateFrom)
  const handleQuickChipClick = useCallback(
    (option: TabPast | TabNow) => () => {
      const transformedDateRange = transformDatePickerValueToDate(option)
      if (transformedDateRange) {
        const dateFrom = dayjs(transformedDateRange.dateFrom)
          .startOf('day')
          .toISOString()
        const dateTo = dayjs(transformedDateRange.dateTo)
          .endOf('day')
          .toISOString()
        setValue(PaymentReportViewFormField.DateFrom, dateFrom)
        setValue(PaymentReportViewFormField.DateTo, dateTo)
      }
    },
    [setValue, transformDatePickerValueToDate]
  )
  const handleSellingChannelChipClick = useCallback(
    (channel: SellingChannel) => () => {
      if (channel === selectedSellingChannel) {
        setValue(PaymentReportViewFormField.Channel, undefined)
      } else {
        setValue(PaymentReportViewFormField.Channel, channel)
      }
    },
    [setValue, selectedSellingChannel]
  )
  const onSubmit = useCallback(
    (data: IPaymentReportViewForm) => {
      const formattedData: IPaymentReportViewForm = {
        ...data,
        [PaymentReportViewFormField.DateFrom]: dayjs(
          data[PaymentReportViewFormField.DateFrom]
        )
          .startOf('day')
          .toISOString(),
        [PaymentReportViewFormField.DateTo]: dayjs(
          data[PaymentReportViewFormField.DateTo]
        )
          .endOf('day')
          .toISOString()
      }
      history.push({
        pathname: routeTo.admin.paymentReports.paymentReportView(),
        search: queryString.stringify(pickBy(formattedData, identity))
      })
    },
    [history]
  )
  return (
    <Box sx={{p: 3}}>
      <Paper variant="outlined" sx={{p: 3}}>
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          id={PAYMENT_REPORT_VIEW_FORM_ID}
        >
          <input
            type="hidden"
            name={PaymentReportViewFormField.Channel}
            ref={register()}
          />
          <Box>
            <Typography variant="subtitle2">{t('Payment date')}</Typography>
            <InputRow
              nodes={[
                <FormDateInput<IPaymentReportViewForm>
                  datePickerProps={{
                    label: t<string>('Date from'),
                    disableFuture: true
                  }}
                  control={control}
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  errors={errors}
                  clearError={clearError}
                  setValue={setValue}
                  key={PaymentReportViewFormField.DateFrom}
                  name={PaymentReportViewFormField.DateFrom}
                  setError={setError}
                  validationOptions={{required: true}}
                  fullWidth
                />,
                <FormDateInput<IPaymentReportViewForm>
                  datePickerProps={{
                    label: t<string>('Date to'),
                    minDate: selectedDateFrom
                      ? dayjs(selectedDateFrom)
                      : undefined
                  }}
                  control={control}
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  errors={errors}
                  clearError={clearError}
                  setValue={setValue}
                  key={PaymentReportViewFormField.DateTo}
                  name={PaymentReportViewFormField.DateTo}
                  setError={setError}
                  validationOptions={{required: true}}
                  fullWidth
                />
              ]}
            />
            <Typography variant="overline" color="textSecondary">
              {t('Quick choice')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                flexWrap: 'wrap',
                pt: 1
              }}
            >
              {[
                TabNow.Today,
                TabNow.ThisWeek,
                TabNow.ThisMonth,
                TabNow.ThisQuarter,
                TabPast.Yesterday,
                TabPast.LastWeek,
                TabPast.LastMonth,
                TabPast.LastQuarter
              ].map((option) => (
                <Chip
                  key={option}
                  label={
                    isPastValue(option)
                      ? translateDatePickerPastValues(option)
                      : isNowValue(option)
                      ? translateDatePickerNowValues(option)
                      : undefined
                  }
                  onClick={handleQuickChipClick(option)}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle2">{t('Selling channel')}</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                pt: 1,
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {[SellingChannel.ECommerce, SellingChannel.Retail].map(
                (channel) => (
                  <Chip
                    key={channel}
                    label={translateSellingChannel(channel)}
                    onClick={handleSellingChannelChipClick(channel)}
                    color={
                      selectedSellingChannel === channel ? 'primary' : undefined
                    }
                    variant={
                      selectedSellingChannel === channel
                        ? 'outlined'
                        : undefined
                    }
                    sx={
                      selectedSellingChannel === channel
                        ? {
                            backgroundColor: PRIMARY_100_COLOR,
                            '&&:hover': {
                              backgroundColor: PRIMARY_100_COLOR
                            }
                          }
                        : {}
                    }
                  />
                )
              )}
              {selectedSellingChannel === SellingChannel.Retail &&
                P([PermissionCode.ReadUsers]) && (
                  <InputRow
                    nodes={[
                      <FormAutocompleteMultiple<IPaymentReportViewForm>
                        errors={errors}
                        control={control}
                        name={PaymentReportViewFormField.UserIds}
                        key={PaymentReportViewFormField.UserIds}
                        label={t('Users')}
                        autocompleteOptions={users.map((user) => ({
                          value: user.id,
                          name: [user.firstName, user.lastName].join(' ')
                        }))}
                        fullWidth
                      />
                    ]}
                  />
                )}
            </Box>
          </Box>
          <InputRow
            nodes={[
              <UncontrolledFormTextInput<IPaymentReportViewForm>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                name={PaymentReportViewFormField.Note}
                key={PaymentReportViewFormField.Note}
                label={t('Note')}
                validationOptions={{
                  validate: isStringWithMaxLength
                }}
                fullWidth
                multiline
                rows={3}
              />
            ]}
          />
          <Button
            variant="contained"
            color="primary"
            form={PAYMENT_REPORT_VIEW_FORM_ID}
            type="submit"
            sx={{justifySelf: 'flex-start'}}
          >
            {t('View')}
          </Button>
        </StyledForm>
      </Paper>
    </Box>
  )
}
