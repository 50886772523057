import {useTranslation} from 'react-i18next'
import {ZonePlanView} from '../types'

export const useTranslateZonePlanView = () => {
  const {t} = useTranslation()
  return (zonePlanView: ZonePlanView) => {
    switch (zonePlanView) {
      case ZonePlanView.ListView:
        return t(`ZonePlanView->ListView`)
      case ZonePlanView.SeatingPlan:
        return t(`ZonePlanView->SeatingPlan`)
      default:
        return 'unknown'
    }
  }
}
