import React from 'react'
import {useTranslation} from 'react-i18next'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'

export interface IRolesSearchFilter {
  hasText?: string
}

const mapHasTextToFiler = (
  filter: IRolesSearchFilter,
  hasText: string | undefined
): IRolesSearchFilter => ({...filter, hasText})

const getHasTextFormSearchObject = (filter: IRolesSearchFilter) =>
  filter.hasText || undefined

interface IRolesSearchProps {
  onFilterChange: (filter: IRolesSearchFilter) => void
}

export const RolesSearch: React.FC<IRolesSearchProps> = ({
  onFilterChange
}: IRolesSearchProps) => {
  const {t} = useTranslation()
  const mapSearchObjectToInputText = useCombineStringifySearchObjectFunctions(
    getHasTextFormSearchObject
  )
  return (
    <Search<IRolesSearchFilter>
      storageKey="ROLES"
      placeholder={t('Search for role')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapHasTextToFiler}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={{}}
    />
  )
}
