import {Drawer, Paper, Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {ShowCrewByRole} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateShowCrewRole} from '../../../../../hooks/translateCrewRole'
import {useShowParams} from '../../../../../utils/pathname'
import {
  ButtonWithConfirmationDialog,
  DrawerTemplate,
  DrawerTemplateHeader
} from '../../../../common'
import {ReadOnlyRow} from '../../../../common/ReadOnly'
import {useGetLibraryShowCrew, useUpsertClientShowCrew} from '../graphql'
import {IShowCrewFieldsProps} from '../types'
import {mapShowCrewRoleInputToFormValues} from '../utils'

const useStyles = makeStyles<Theme>((theme) => ({
  drawerPaper: {
    maxWidth: 480,
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2, 2)
  },
  root: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  }
}))

interface ILibraryCrewDrawerProps {
  onExited: () => void
  showCrew: Pick<ShowCrewByRole, 'crewRoleCode' | 'persons'>[]
  libraryShowId: number
  setCrewFormFields: (crew: IShowCrewFieldsProps[]) => void
}

export const LibraryCrewDrawer: React.FC<ILibraryCrewDrawerProps> = ({
  onExited,
  showCrew,
  libraryShowId,
  setCrewFormFields
}: ILibraryCrewDrawerProps) => {
  const {t} = useTranslation()
  const {showId} = useShowParams()
  const {data, loading, error} = useGetLibraryShowCrew(libraryShowId)
  const upsertClientShowCrew = useUpsertClientShowCrew()
  const {setShowBackdrop, defaultErrorHandler, addInfoNotification} =
    useMutationAssistanceHooks()
  const translateShowCrewRole = useTranslateShowCrewRole()
  const isActionButtonDisabled = data?.libraryShow.crew.length === 0
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  useEffect(() => {
    if (showId) {
      openDrawer()
    }
  }, [showId, openDrawer])
  const classes = useStyles()
  const handleReplaceButtonClick = useCallback(async () => {
    if (data) {
      try {
        setShowBackdrop(true)
        const {data: updatedShowCrew} = await upsertClientShowCrew({
          id: showId,
          data: data.libraryShow.crew
        })
        if (updatedShowCrew) {
          setCrewFormFields(
            mapShowCrewRoleInputToFormValues(
              updatedShowCrew.upsertClientShowCrew
            )
          )
        }
        addInfoNotification(t('Crew has been updated successfully'))
        onExited()
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating show crew'))
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    addInfoNotification,
    data,
    defaultErrorHandler,
    onExited,
    setCrewFormFields,
    setShowBackdrop,
    showId,
    t,
    upsertClientShowCrew
  ])
  const handleAddButtonClick = useCallback(async () => {
    if (data) {
      try {
        setShowBackdrop(true)
        const {data: updatedShowCrew} = await upsertClientShowCrew({
          id: showId,
          data: [...showCrew, ...data.libraryShow.crew]
        })
        if (updatedShowCrew) {
          setCrewFormFields(
            mapShowCrewRoleInputToFormValues(
              updatedShowCrew.upsertClientShowCrew
            )
          )
        }
        addInfoNotification(t('Crew has been updated successfully'))
        onExited()
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating show crew'))
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    addInfoNotification,
    data,
    defaultErrorHandler,
    onExited,
    setCrewFormFields,
    setShowBackdrop,
    showCrew,
    showId,
    t,
    upsertClientShowCrew
  ])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <DrawerTemplate
        isLoading={loading}
        errorMessage={error && t<string>('Error while loading library show')}
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('Library')}
          />
        }
        footer={
          <>
            <ButtonWithConfirmationDialog
              buttonProps={{
                color: 'primary',
                variant: 'text',
                children: t('Replace'),
                disabled: isActionButtonDisabled
              }}
              onConfirmButtonClick={handleReplaceButtonClick}
              dialogProps={{
                title: t('Replace all crew members?'),
                contentText: t(
                  'All crew members you have saved in show will be replaced by library crew members. You will be able to edit, add or delete new data in show.'
                ),
                confirmButtonLabel: t('Replace')
              }}
            />
            <ButtonWithConfirmationDialog
              buttonProps={{
                color: 'primary',
                variant: 'contained',
                children: t('Add'),
                disabled: isActionButtonDisabled
              }}
              onConfirmButtonClick={handleAddButtonClick}
              dialogProps={{
                title: t('Add crew from library?'),
                contentText: t(
                  'Crew members from library will be added at the end of list with crew members already saved in show. Former crew will not be changed.'
                ),
                confirmButtonLabel: t('Add')
              }}
            />
          </>
        }
      >
        {data && (
          <div className={classes.root}>
            <Typography variant="subtitle1">{t('Crew')}</Typography>
            <Paper variant="outlined" className={classes.paper}>
              {data.libraryShow.crew.length === 0 && (
                <Typography variant="subtitle2" color="textSecondary">
                  {t('No items found')}
                </Typography>
              )}
              {data.libraryShow.crew.map(({crewRoleCode, persons}) => (
                <ReadOnlyRow
                  key={crewRoleCode}
                  label={translateShowCrewRole(crewRoleCode)}
                >
                  {persons.map((person, index) => (
                    <Typography key={`person-${index}`} variant="body2">
                      {person.name}
                      {person.description && ` (${person.description})`}
                    </Typography>
                  ))}
                </ReadOnlyRow>
              ))}
            </Paper>
          </div>
        )}
      </DrawerTemplate>
    </Drawer>
  )
}
