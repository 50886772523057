import {useTranslation} from 'react-i18next'

import {ReservationState} from '../__generated__/schema'

export const useTranslateReservationState = () => {
  const {t} = useTranslation()
  const translatedCodes: Record<ReservationState, string> = {
    [ReservationState.Active]: t<string>('ReservationState->Active'),
    [ReservationState.Expired]: t<string>('ReservationState->Expired'),
    [ReservationState.InCart]: t<string>('ReservationState->InCart'),
    [ReservationState.Canceled]: t<string>('ReservationState->Canceled'),
    [ReservationState.Fulfilled]: t<string>('ReservationState->Fulfilled')
  }
  return (code: ReservationState) => translatedCodes[code]
}
