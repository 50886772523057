import {Box, Drawer, drawerClasses, Typography} from '@mui/material'
import dayjs from 'dayjs'
import {groupBy} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {TourTimeSlotState} from '../../../__generated__/schema'
import {useBooleanState} from '../../../hooks/state'
import {useTourParams} from '../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../DrawerUtils'
import {LazyLoadingList} from '../LazyLoadingList'
import {useLightweightTourTimeSlots, useTourName} from './graphql'
import {TimeSlotsList} from './TimeSlotsList'

interface ITourTimeSlotsDrawerProps {
  onExited: () => void
}

export const TourTimeSlotsDrawer: React.FC<ITourTimeSlotsDrawerProps> = ({
  onExited
}: ITourTimeSlotsDrawerProps) => {
  const {t} = useTranslation()
  const {tourId} = useTourParams()
  const {
    data: tourNameData,
    loading: tourNameLoading,
    error: tourNameError
  } = useTourName(tourId)
  const {data, loading, error, isLoadingMore, fetchMore} =
    useLightweightTourTimeSlots({
      tourId,
      state: TourTimeSlotState.Published,
      isAvailableOnRetail: true
    })
  const {
    state: isOpen,
    setTrue: openDrawer,
    setFalse: closeDrawer
  } = useBooleanState(false)
  const groupedTimeSlots = groupBy(
    (data?.tourTimeSlots.items || []).map((item) => ({
      ...item,
      startsAtDate: dayjs.utc(item.startsAt).local().startOf('day')
    })),
    'startsAtDate'
  )
  const [title, setTitle] = useState<string>(t('Tour'))
  const handleScrolledNearTheEndOfTheLayout = useCallback(() => {
    if (!isLoadingMore && data && data?.tourTimeSlots.pagination.hasMore) {
      fetchMore()
    }
  }, [data, fetchMore, isLoadingMore])
  useEffect(() => {
    if (tourId) {
      openDrawer()
    }
  }, [tourId, openDrawer])
  useEffect(() => {
    if (tourNameData) {
      setTitle(tourNameData.tour.name)
    }
  }, [tourNameData])
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      SlideProps={{onExited}}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 360, width: '100%'}}}
      id="drawer"
    >
      <DrawerTemplate
        isLoading={loading || tourNameLoading}
        errorMessage={
          (error || tourNameError) &&
          t<string>('Error while loading tour time slots')
        }
        header={
          <DrawerTemplateHeader
            onLeftActionClick={closeDrawer}
            title={t('More dates')}
          />
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <LazyLoadingList
          isLoadingMore={isLoadingMore}
          onScrolledNearTheEndOfTheList={handleScrolledNearTheEndOfTheLayout}
        >
          <Box
            sx={{
              py: 2,
              px: 3,
              gap: 2,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant="subtitle2">{title}</Typography>
            {Object.entries(groupedTimeSlots).map(([date, timeSlots]) => (
              <TimeSlotsList key={date} date={date} timeSlots={timeSlots} />
            ))}
          </Box>
        </LazyLoadingList>
      </DrawerTemplate>
    </Drawer>
  )
}
