import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useContext, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {
  getPaymentIntentFieldName,
  IPaymentIntentsForm,
  PaymentIntentField
} from '../types'
import {
  IntentsStructureDispatchContext,
  IntentStructureActionType
} from './intentsStructure'
import {IInHouseVoucherFrame} from './types'

interface IInHouseVoucherIntentFrameProps {
  frame: IInHouseVoucherFrame
}

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center'
  },
  hiddenInput: {
    display: 'none'
  }
}))
export const InHouseVoucherIntentFrame: React.FC<IInHouseVoucherIntentFrameProps> =
  ({frame}: IInHouseVoucherIntentFrameProps) => {
    const classes = useStyles()
    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
    const intentStructureDispatch = useContext(IntentsStructureDispatchContext)
    const handleClick = useCallback(() => {
      intentStructureDispatch({
        type: IntentStructureActionType.RemoveInHouseVoucherFrame,
        payload: frame.voucherId
      })
    }, [frame.voucherId, intentStructureDispatch])
    const {register, triggerValidation} = useFormContext<IPaymentIntentsForm>()
    useEffect(() => {
      // triggerValidation to force recalculation of cart balance on adding/removing intent frame
      triggerValidation()
      return () => {
        triggerValidation()
      }
    }, [triggerValidation])
    return (
      <div className={classes.root}>
        <IconButton color="primary" edge="start" onClick={handleClick}>
          <RemoveCircleOutlineIcon />
        </IconButton>
        <Typography variant="subtitle1">{frame.code}</Typography>
        <Typography>{translateEffectiveClientPrice(frame.credit)}</Typography>
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(frame.key, PaymentIntentField.Amount)}
          defaultValue={frame.credit}
        />
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(
            frame.key,
            PaymentIntentField.VoucherId
          )}
          defaultValue={frame.voucherId}
        />
        <input
          className={classes.hiddenInput}
          ref={register()}
          name={getPaymentIntentFieldName(
            frame.key,
            PaymentIntentField.PaymentMethodId
          )}
          defaultValue={frame.paymentMethodId}
        />
      </div>
    )
  }
