import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {stageRefSelector} from '../redux/refs/selectors'

import {exportToPng} from '../utils/common'
import {ExportIcon} from './Icons'
import {Panel} from './Panel'
import {PanelButton} from './PanelButton'

export const DownloadPanel: React.FC = () => {
  const stageRef = useSelector(stageRefSelector)

  const {t} = useTranslation()

  const onExportClick = () => {
    if (stageRef) {
      exportToPng(stageRef.getStage())
    }
  }

  return (
    <Panel>
      <PanelButton onClick={onExportClick} tooltip={t('Export as PNG')}>
        <ExportIcon />
      </PanelButton>
    </Panel>
  )
}
