import CalendarIcon from '@mui/icons-material/CalendarToday'
import {IconButton, Popover, TextField} from '@mui/material'
import {StaticDatePicker} from '@mui/x-date-pickers'
import {Dayjs} from 'dayjs'
import React, {useState} from 'react'
import {LocalizationProvider} from '../form/pickers'
import {Tooltip} from './Tooltip'

interface IPopoverDatePickerProps {
  value: Dayjs
  onChange: (date: Dayjs | null) => void
  calendarIconClassName?: string
  tooltipTitle: string
}

const usePopoverDatePickerHandlers = () => {
  const [anchorEl, setAnchorEl] =
    useState<(EventTarget & HTMLButtonElement) | null>(null)

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const open = !!anchorEl

  return {open, onClick, onClose, anchorEl}
}

export const PopoverDatePicker: React.FC<IPopoverDatePickerProps> = ({
  value,
  onChange,
  calendarIconClassName,
  tooltipTitle
}: IPopoverDatePickerProps) => {
  const {open, onClick, onClose, anchorEl} = usePopoverDatePickerHandlers()
  return (
    <>
      <Tooltip title={tooltipTitle}>
        <IconButton onClick={onClick}>
          <CalendarIcon className={calendarIconClassName} />
        </IconButton>
      </Tooltip>
      <Popover
        {...{open, onClose, anchorEl}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <LocalizationProvider>
          <StaticDatePicker
            views={['day']}
            showToolbar={false}
            renderInput={(props) => <TextField {...props} helperText={null} />}
            value={value}
            onChange={onChange}
            components={{
              ActionBar: () => null
            }}
          />
        </LocalizationProvider>
      </Popover>
    </>
  )
}
