import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  CartsFilterInput,
  CartState,
  PermissionCode,
  SellingChannel
} from '../../../../__generated__/schema'
import {useTranslateCartState} from '../../../../hooks/cartState'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {
  CartStateCellRenderer,
  DataGridTable,
  useDataGridPagination,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  useSellingChannelFormatter
} from '../../../common/DataGridTable'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {Error} from '../../../visual'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {CartsSearch} from '../components/cartsSearch'
import {
  CartSearchLocation,
  DEFAULT_CARTS_FILTER
} from '../components/cartsSearch/types'
import {ChipWithMultiselect} from '../components/ChipWithMultiselect'
import {ReloadButton} from '../components/ReloadButton'
import {PrimaryHeader} from '../Header'
import {WideCenteredLayout} from '../Layout'
import {useCarts} from './graphql'

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.carts.detail(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

const useCashierValueFormatter = () => {
  return (params: GridValueFormatterParams, sellingChannel?: SellingChannel) =>
    sellingChannel && sellingChannel === SellingChannel.Retail
      ? params.value
      : ''
}

const useStyles = makeStyles<Theme>((theme) => ({
  tableWrapper: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(3)
  }
}))

interface ICartsListProps {
  searchFilter: CartsFilterInput
}

export const CartsList: React.FC<ICartsListProps> = ({
  searchFilter
}: ICartsListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {resetPaginationModel, paginationInput, getDataGridPaginationProps} =
    useDataGridPagination()
  const [selectedCartStates, setSelectedCartStates] = useState<CartState[]>([])
  const {data, loading, error, refetch} = useCarts({
    filter: {
      ...searchFilter,
      cartStates: selectedCartStates.length > 0 ? selectedCartStates : undefined
    },
    paginationInput
  })
  const classes = useStyles()
  const translateCartState = useTranslateCartState()
  const dateTimeFormatter = useDateTimeFormatter()
  const sellingChannelFormatter = useSellingChannelFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const cashierValueFormatter = useCashierValueFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Created at'),
        field: 'createdAt',
        minWidth: 200,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: t('Channel'),
        field: 'sellingChannel',
        minWidth: 150,
        valueFormatter: sellingChannelFormatter,
        sortable: false
      },
      {
        headerName: t('Total'),
        field: 'price',
        type: 'number',
        minWidth: 100,
        valueFormatter: effectiveClientPriceFormatter,
        sortable: false
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: CartStateCellRenderer,
        minWidth: 150,
        sortable: false
      },
      {
        headerName: t('Customer'),
        field: 'lead.data.name',
        minWidth: 250,
        valueGetter: (params) =>
          params.row.lead?.data?.name ||
          params.row.lead?.data?.companyName ||
          params.row.lead?.data?.email,
        sortable: false
      },
      {
        headerName: t('Items'),
        field: 'itemsCount',
        type: 'number',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Cart'),
        field: 'id',
        align: 'right',
        headerAlign: 'right',
        minWidth: 100,
        sortable: false
      },
      {
        headerName: t('Cashier'),
        field: 'createdByName',
        minWidth: 250,
        valueFormatter: (params) =>
          cashierValueFormatter(
            params,
            data?.carts.items.find((item) => item.id === params.id)
              ?.sellingChannel
          ),
        sortable: false
      },
      {
        headerName: t('Updated at'),
        field: 'updatedAt',
        minWidth: 250,
        valueFormatter: dateTimeFormatter,
        sortable: false
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.id,
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        disableColumnMenu: true,
        width: 64
      }
    ],
    [
      cashierValueFormatter,
      data?.carts.items,
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      sellingChannelFormatter,
      t
    ]
  )
  const handleCartStateChange = useCallback(
    (states: CartState[]) => {
      setSelectedCartStates(states)
      resetPaginationModel()
    },
    [resetPaginationModel]
  )
  if (error) {
    return <Error error={error} message={t('Error while loading carts')} />
  }
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr'
      }}
    >
      <SubHeaderToolbar
        title={t('Overview')}
        leftActions={[
          <ChipWithMultiselect<CartState>
            key="cart-state-chip"
            selectedItems={selectedCartStates}
            onApplyButtonClick={handleCartStateChange}
            options={[
              CartState.Draft,
              CartState.Pending,
              CartState.Sold,
              CartState.Reserved,
              CartState.Expired,
              CartState.Failed,
              CartState.Discarded
            ].map((state) => ({
              option: state,
              label: translateCartState(state)
            }))}
            allText={t('All carts')}
          />
        ]}
        rightActions={[
          <ReloadButton
            key="reload-button"
            onReloadButtonClick={() => refetch()}
          />
        ]}
      />
      <WideCenteredLayout className={classes.tableWrapper}>
        <DataGridTable
          columns={columns}
          loading={loading}
          rows={data?.carts.items || []}
          localeText={{noRowsLabel: t('No carts to show')}}
          disableColumnFilter
          initialState={{
            pinnedColumns: {
              left: ['createdAt'],
              right: ['arrow']
            }
          }}
          columnVisibilityModel={{
            id: P([PermissionCode.ReadCart])
          }}
          disableRowSelectionOnClick
          {...getDataGridPaginationProps(data?.carts.pagination)}
        />
      </WideCenteredLayout>
    </Box>
  )
}

export const CartsPage: React.FC = () => {
  const {t} = useTranslation()
  const [searchFilter, setSearchFilter] =
    useState<CartsFilterInput>(DEFAULT_CARTS_FILTER)
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Carts')}
          search={
            <CartsSearch
              onFilterChange={setSearchFilter}
              location={CartSearchLocation.Carts}
            />
          }
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <CartsList searchFilter={searchFilter} />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
