import {Box, SvgIconProps, SxProps, useTheme} from '@mui/material'
import React from 'react'
import {Theme} from '../../theme'
import {convertHexToRGBA} from '../../utils/colors'
import {isValidHexColor} from '../../utils/formsValidations'

export interface IIconBoxProps {
  Icon: React.FC<SvgIconProps>
  hexColor?: string
  sx?: SxProps
  iconSx?: SxProps
  hideBackground?: boolean
}

export const IconBox: React.FC<IIconBoxProps> = ({
  Icon,
  hexColor,
  sx,
  iconSx,
  hideBackground
}: IIconBoxProps) => {
  const theme = useTheme<Theme>()
  const iconColor =
    hexColor && isValidHexColor(hexColor)
      ? hexColor
      : theme.palette.primary.main
  const boxColor = convertHexToRGBA(iconColor, 0.2)
  return (
    <Box
      sx={{
        width: 56,
        height: 72,
        borderRadius: 0.5,
        backgroundColor: hideBackground ? 'transparent' : boxColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx
      }}
    >
      {<Icon sx={{color: iconColor, width: 32, height: 32, ...iconSx}} />}
    </Box>
  )
}
