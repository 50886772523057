import {Box, Typography} from '@mui/material'
import {BoxProps} from '@mui/material/Box/Box'
import React from 'react'

interface ISettingBoxProps {
  label: string
  description?: string
  children: React.ReactNode
  childrenClassName?: string
  boxProps?: BoxProps
}

export const SettingBox: React.FC<ISettingBoxProps> = ({
  label,
  description,
  children,
  childrenClassName,
  boxProps
}: ISettingBoxProps) => (
  <Box p={2} {...boxProps}>
    <Typography variant="subtitle2">{label}</Typography>
    {description && (
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
    )}
    <div className={childrenClassName}>{children}</div>
  </Box>
)
