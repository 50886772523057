import {Card, CardProps, Grid, SxProps, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import get from 'lodash/get'
import React from 'react'
import {FieldError, FieldErrors, FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../theme'
import {borderStyle} from '../constants'
import {FormFieldName} from '../form/types'
// todo: reorganize components in /frontend/src/components/visual and in /frontend/src/components/common
// noinspection ES6PreferShortImport - prevent dependency cycle
import {ValidationError} from '../visual/ValidationError'

const useStyles = makeStyles<Theme>((theme) => ({
  inputRow: {
    padding: theme.spacing(1, 0)
  },
  node: {
    height: '100%'
  },
  blockHeader: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    fontWeight: 500
  },
  outerCardBlockHeader: {
    fontWeight: 500
  },
  smallerOuterCardBlockMargin: {
    marginBottom: theme.spacing(1)
  },
  outerCardBlockMargin: {
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    boxShadow: 'none',
    border: borderStyle,
    overflow: 'visible' // For dropdown menus in text editor
  },
  sidesPadding: {
    padding: theme.spacing(3)
  },
  noSidesPadding: {
    padding: theme.spacing(3, 0)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
}))

type Xs = 1 | 2 | 3 | 4 | 6 | 12

interface IInputRowProps {
  nodes: Array<React.ReactNode>
  spacing?: 0 | 1 | 2 | 3 | 4 | undefined
  xs?: Xs
  nodeClassName?: string
  className?: string
}

export const InputRow: React.FC<IInputRowProps> = ({
  nodes,
  xs,
  nodeClassName,
  spacing = 3,
  className
}: IInputRowProps) => {
  const classes = useStyles()

  if (!nodes.length) return null

  const xsValue =
    xs ||
    (nodes.length <= 4 || nodes.length === 6 ? ((12 / nodes.length) as Xs) : 1)

  return (
    <Grid
      container
      spacing={spacing}
      className={cn(classes.inputRow, className)}
      alignItems="flex-start"
    >
      {nodes.map((node, index) => (
        <Grid
          item
          xs={xsValue}
          key={index}
          className={cn(nodeClassName, classes.node)}
        >
          {node}
        </Grid>
      ))}
    </Grid>
  )
}

interface IInputBlockProps {
  children: React.ReactNode
  header: string
  /**
   * @deprecated, please use 'blockId' (scrolls to top of block, not to top of title)
   */
  id?: string
  blockId?: string
  sidesPadding?: boolean
  className?: string
  headerClassName?: string
  cardClassName?: string
  headerRightAction?: React.ReactNode
}

export const InputBlock: React.FC<IInputBlockProps> = ({
  children,
  header,
  headerRightAction,
  id,
  blockId,
  className,
  headerClassName,
  cardClassName,
  sidesPadding = true
}: IInputBlockProps) => {
  const classes = useStyles()
  return (
    <div className={className} id={blockId}>
      <div className={classes.header}>
        <Typography
          align="left"
          className={cn(classes.blockHeader, headerClassName)}
          id={id || undefined}
        >
          {header}
        </Typography>
        {headerRightAction}
      </div>
      <Card
        className={cn(
          classes.wrapper,
          sidesPadding ? classes.sidesPadding : classes.noSidesPadding,
          cardClassName
        )}
      >
        {children}
      </Card>
    </div>
  )
}

const useInputBlockWithDividerStyles = makeStyles<Theme>((theme) => ({
  blockHeader: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    fontWeight: 500
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }
}))

interface IInputBlockWithDividerProps {
  children: CardProps['children']
  header: React.ReactNode
  blockId?: string
  className?: string
  headerSx?: SxProps<Theme>
  /**
   * @deprecated use headerSx prop
   */
  headerClassName?: string
  headerRightAction?: React.ReactNode
}

export const InputBlockWithoutSpacings: React.FC<IInputBlockWithDividerProps> =
  ({
    children,
    header,
    blockId,
    className,
    headerSx,
    headerClassName,
    headerRightAction
  }: IInputBlockWithDividerProps) => {
    const classes = useInputBlockWithDividerStyles()
    return (
      <div className={className} id={blockId}>
        <div className={classes.header}>
          <Typography
            align="left"
            className={cn(classes.blockHeader, headerClassName)}
            sx={headerSx}
            component="div"
          >
            {header}
          </Typography>
          {headerRightAction}
        </div>
        <Card variant="outlined">{children}</Card>
      </div>
    )
  }

interface IInputOuterCardBlockProps {
  children: React.ReactNode
  header: string
  id?: string
  sidesPadding?: boolean
  className?: string
  headerClassName?: string
  message?: string
}

export const InputOuterCardBlock: React.FC<IInputOuterCardBlockProps> = ({
  children,
  header,
  id,
  sidesPadding = true,
  message,
  className,
  headerClassName
}: IInputOuterCardBlockProps) => {
  const classes = useStyles()
  return (
    <Card
      className={cn(
        classes.wrapper,
        sidesPadding ? classes.sidesPadding : classes.noSidesPadding,
        className
      )}
    >
      <Typography
        align="left"
        variant="h6"
        className={cn(
          classes.outerCardBlockHeader,
          message
            ? classes.smallerOuterCardBlockMargin
            : classes.outerCardBlockMargin,
          headerClassName
        )}
        component="div"
        id={id || undefined}
      >
        {header}
      </Typography>

      {message && (
        <Typography
          variant="caption"
          component="div"
          className={classes.outerCardBlockMargin}
        >
          {message}
        </Typography>
      )}

      {children}
    </Card>
  )
}

interface IFormValidationErrorProps<
  FormValues extends FieldValues = FieldValues
> {
  errors: FieldErrors<FormValues>
  fieldName: FormFieldName<FormValues>
}

export const FormValidationError = <
  FormValues extends FieldValues = FieldValues
>({
  errors,
  fieldName
}: IFormValidationErrorProps<FormValues>) => {
  const {t} = useTranslation()
  const error = get(errors, fieldName) as FieldError | undefined
  return error ? (
    <ValidationError>
      {error.message ||
        (error.type === 'required' ? t('This field is required!') : error.type)}
    </ValidationError>
  ) : null
}
