import {useTranslation} from 'react-i18next'

import {
  AccessZoneState,
  AdmissionRateState,
  AdmissionTypeState,
  ApiGrantState,
  AuditoriumLayoutPricingState,
  AuditoriumLayoutState,
  AuditoriumState,
  BusinessPartnerState,
  CartState,
  ClaimState,
  ClientState,
  CustomerState,
  DiscountCodeState,
  DiscountState,
  DivisionState,
  EventState,
  InventoryCheckState,
  MessageState,
  PassCodeCheckState,
  ProductGroupState,
  ProductState,
  ProductTypeState,
  ShowImageType,
  TemplateState,
  TourState,
  TourTimeSlotState,
  UserState,
  VoucherCampaignState,
  VoucherState,
  VoucherTransactionIntentState,
  WarehouseDocumentState,
  WarehouseState,
  WebsitePageState,
  WebsiteState
} from '../__generated__/schema'
import {theme} from '../theme'
import {PricingStatus} from '../utils/setPricingsStatus'

export const TOP_BAR_HEIGHT = 64
export const BOTTOM_BAR_HEIGHT = 64

export const SHOW_IMAGE_WIDTH = 100
export const SHOW_IMAGE_HEIGHT = 140

export const COLOR_CONF = {
  RED: {
    color: '#EA3D2F',
    background: '#FEE4E2'
  },
  YELLOW: {
    color: '#F3AA18',
    background: '#FCF3D7'
  },
  GREEN: {
    color: '#2FA84F',
    background: '#DCF7E3'
  },
  BLUE: {
    color: '#367BF5',
    background: '#E5EFFF'
  },
  GRAY: {
    color: '#7F7F7F',
    background: '#EEEEEE'
  },
  PURPLE: {
    color: theme.palette.primary.main,
    background: '#EEEEEE'
  }
}

export const borderStyle = '1px solid rgba(0, 0, 0, 0.12)'

// Note: not in `theme` object as we would need to alter its type which is pain
export const userStateColors = {
  [UserState.Active]: COLOR_CONF.GREEN,
  [UserState.Invited]: COLOR_CONF.YELLOW,
  [UserState.Disabled]: COLOR_CONF.RED,
  [UserState.Anonymized]: COLOR_CONF.RED
}

export const useUserStateTranslations = () => {
  const {t} = useTranslation()

  return {
    [UserState.Active]: t('Active'),
    [UserState.Disabled]: t('Cancelled'),
    [UserState.Invited]: t('Invited'),
    [UserState.Anonymized]: t('Anonymized')
  }
}

export const clientStateColors = {
  [ClientState.Active]: COLOR_CONF.GREEN,
  [ClientState.Potential]: COLOR_CONF.YELLOW,
  [ClientState.Inactive]: COLOR_CONF.RED
}

export const useClientStateTranslations = () => {
  const {t} = useTranslation()

  return {
    [ClientState.Active]: t('Active'),
    [ClientState.Inactive]: t('Inactive'),
    [ClientState.Potential]: t('Potential')
  }
}

export const divisionStateColors = {
  [DivisionState.Active]: COLOR_CONF.GREEN,
  [DivisionState.Draft]: COLOR_CONF.YELLOW,
  [DivisionState.Inactive]: COLOR_CONF.RED
}

export const discountStateColors = {
  [DiscountState.Inactive]: COLOR_CONF.RED,
  [DiscountState.Active]: COLOR_CONF.GREEN,
  [DiscountState.Deleted]: COLOR_CONF.RED
}

export const useDivisionStateTranslations = () => {
  const {t} = useTranslation()

  return {
    [DivisionState.Active]: t('Active'),
    [DivisionState.Inactive]: t('Inactive'),
    [DivisionState.Draft]: t('Draft')
  }
}

export const auditoriumLayoutStateColors = {
  [AuditoriumLayoutState.Active]: COLOR_CONF.GREEN,
  [AuditoriumLayoutState.Draft]: COLOR_CONF.YELLOW,
  [AuditoriumLayoutState.Archived]: COLOR_CONF.RED
}

export const auditoriumStateColors = {
  [AuditoriumState.Active]: COLOR_CONF.GREEN,
  [AuditoriumState.Archived]: COLOR_CONF.RED
}

export const useAuditoriumLayoutStateTranslations = () => {
  const {t} = useTranslation()

  return {
    [AuditoriumLayoutState.Active]: t('Active'),
    [AuditoriumLayoutState.Archived]: t('Archived'),
    [AuditoriumLayoutState.Draft]: t('Draft')
  }
}

export const layoutPricingStateColors = {
  [AuditoriumLayoutPricingState.Active]: COLOR_CONF.GREEN,
  [AuditoriumLayoutPricingState.Draft]: COLOR_CONF.YELLOW,
  [AuditoriumLayoutPricingState.Archived]: COLOR_CONF.RED
}

export const useLayoutPricingStateTranslations = () => {
  const {t} = useTranslation()

  return {
    [AuditoriumLayoutPricingState.Active]: t('Active'),
    [AuditoriumLayoutPricingState.Archived]: t('Archived'),
    [AuditoriumLayoutPricingState.Draft]: t('Draft')
  }
}

export enum ShowContentType {
  Posters = 'posters',
  Photos = 'photos',
  Videos = 'videos',
  Covers = 'covers',
  Crew = 'crew',
  Banners = 'banners',
  Backdrops = 'backdrops',
  Files = 'files',
  Links = 'links'
}

export const useMediaContentTypeTranslations = () => {
  const {t} = useTranslation()
  return {
    [ShowContentType.Posters]: t('Posters'),
    [ShowContentType.Photos]: t('Photos'),
    [ShowContentType.Videos]: t('Videos'),
    [ShowContentType.Covers]: t('Covers'),
    [ShowContentType.Crew]: t('Crew'),
    [ShowContentType.Banners]: t('Banners'),
    [ShowContentType.Backdrops]: t('Backdrops'),
    [ShowContentType.Files]: t('Files'),
    [ShowContentType.Links]: t('Links')
  }
}

export const useTranslateShowImageType = () => {
  const {t} = useTranslation()
  const translatedShowImageType: Record<ShowImageType, string> = {
    [ShowImageType.Poster]: t('Posters'),
    [ShowImageType.Photo]: t('Photos'),
    [ShowImageType.Cover]: t('Covers'),
    [ShowImageType.Banner]: t('Banners'),
    [ShowImageType.Backdrop]: t('Backdrops')
  }
  return (showImageType: ShowImageType) =>
    translatedShowImageType[showImageType]
}

export enum ShowSection {
  Data = 'data',
  Translations = 'translations',
  Media = 'media',
  Crew = 'crew'
}

export const useShowSectionTranslations = () => {
  const {t} = useTranslation()
  return {
    [ShowSection.Data]: t('Data'),
    [ShowSection.Translations]: t('Translations'),
    [ShowSection.Media]: t('Media')
  }
}

export const eventStateColors = {
  [EventState.Draft]: COLOR_CONF.YELLOW,
  [EventState.Published]: COLOR_CONF.GREEN,
  [EventState.Canceled]: COLOR_CONF.RED,
  [EventState.Deleted]: COLOR_CONF.RED
}

export const discountCodeStateColors = {
  [DiscountCodeState.Invalid]: COLOR_CONF.RED,
  [DiscountCodeState.Inactive]: COLOR_CONF.YELLOW,
  [DiscountCodeState.Active]: COLOR_CONF.GREEN
}

export const cartStateColors = {
  [CartState.Draft]: COLOR_CONF.YELLOW,
  [CartState.Sold]: COLOR_CONF.GREEN,
  [CartState.Discarded]: COLOR_CONF.GRAY,
  [CartState.Expired]: COLOR_CONF.GRAY,
  [CartState.Failed]: COLOR_CONF.GRAY,
  [CartState.Reserved]: COLOR_CONF.BLUE,
  [CartState.Pending]: COLOR_CONF.YELLOW
}

export const claimStateColors = {
  [ClaimState.Draft]: COLOR_CONF.YELLOW,
  [ClaimState.Approved]: COLOR_CONF.YELLOW,
  [ClaimState.Deleted]: COLOR_CONF.GRAY,
  [ClaimState.Pending]: COLOR_CONF.YELLOW,
  [ClaimState.Refunded]: COLOR_CONF.RED,
  [ClaimState.Failed]: COLOR_CONF.GRAY
}

export const templateStateColors = {
  [TemplateState.Active]: COLOR_CONF.GREEN,
  [TemplateState.Inactive]: COLOR_CONF.RED
}

export const productTypeStateColors = {
  [ProductTypeState.Active]: COLOR_CONF.GREEN,
  [ProductTypeState.Inactive]: COLOR_CONF.RED,
  [ProductTypeState.Deleted]: COLOR_CONF.GRAY
}

export const productStateColors = {
  [ProductState.Active]: COLOR_CONF.GREEN,
  [ProductState.Disabled]: COLOR_CONF.RED,
  [ProductState.Deleted]: COLOR_CONF.GRAY,
  [ProductState.Inactive]: COLOR_CONF.YELLOW
}

export const messageStateColors = {
  [MessageState.Sent]: COLOR_CONF.GREEN,
  [MessageState.Draft]: COLOR_CONF.YELLOW,
  [MessageState.Failed]: COLOR_CONF.RED,
  [MessageState.Scheduled]: COLOR_CONF.BLUE,
  [MessageState.Pending]: COLOR_CONF.YELLOW
}

export const voucherCampaignStateColors = {
  [VoucherCampaignState.Active]: COLOR_CONF.GREEN,
  [VoucherCampaignState.Inactive]: COLOR_CONF.YELLOW,
  [VoucherCampaignState.Invalid]: COLOR_CONF.RED
}

export const voucherStateColors = {
  [VoucherState.Active]: COLOR_CONF.GREEN,
  [VoucherState.Inactive]: COLOR_CONF.YELLOW,
  [VoucherState.Invalid]: COLOR_CONF.RED
}

export const customerStateColors = {
  [CustomerState.Active]: COLOR_CONF.GREEN,
  [CustomerState.Disabled]: COLOR_CONF.RED,
  [CustomerState.Invited]: COLOR_CONF.YELLOW,
  [CustomerState.Anonymized]: COLOR_CONF.GRAY
}

export const voucherTransactionIntentStateColors = {
  [VoucherTransactionIntentState.Pending]: COLOR_CONF.YELLOW,
  [VoucherTransactionIntentState.Failed]: COLOR_CONF.GRAY,
  [VoucherTransactionIntentState.Expired]: COLOR_CONF.GRAY,
  [VoucherTransactionIntentState.Canceled]: COLOR_CONF.GRAY,
  [VoucherTransactionIntentState.Succeeded]: COLOR_CONF.GREEN
}

export const admissionTypeStateColors = {
  [AdmissionTypeState.Active]: COLOR_CONF.GREEN,
  [AdmissionTypeState.Inactive]: COLOR_CONF.RED,
  [AdmissionTypeState.Draft]: COLOR_CONF.YELLOW
}

export const tourStateColors = {
  [TourState.Active]: COLOR_CONF.GREEN,
  [TourState.Inactive]: COLOR_CONF.RED,
  [TourState.Draft]: COLOR_CONF.YELLOW
}

export const admissionRateStateColors = {
  [AdmissionRateState.Active]: COLOR_CONF.GREEN,
  [AdmissionRateState.Inactive]: COLOR_CONF.RED,
  [AdmissionRateState.Draft]: COLOR_CONF.YELLOW
}

export const tourTimeSlotStateColors = {
  [TourTimeSlotState.Published]: COLOR_CONF.GREEN,
  [TourTimeSlotState.Cancelled]: COLOR_CONF.RED,
  [TourTimeSlotState.Draft]: COLOR_CONF.YELLOW
}

export const apiGrantStateColors = {
  [ApiGrantState.Active]: COLOR_CONF.GREEN,
  [ApiGrantState.Inactive]: COLOR_CONF.RED,
  [ApiGrantState.Expired]: COLOR_CONF.RED
}

export const passCodeCheckStateColors = {
  [PassCodeCheckState.Allowed]: COLOR_CONF.GREEN,
  [PassCodeCheckState.Denied]: COLOR_CONF.RED,
  [PassCodeCheckState.Pending]: COLOR_CONF.YELLOW
}

export const warehouseStateColors = {
  [WarehouseState.Active]: COLOR_CONF.GREEN,
  [WarehouseState.Inactive]: COLOR_CONF.RED,
  [WarehouseState.Deleted]: COLOR_CONF.RED
}

export const productGroupStateColors = {
  [ProductGroupState.Active]: COLOR_CONF.GREEN,
  [ProductGroupState.Inactive]: COLOR_CONF.RED
}

export const businessPartnerStateColors = {
  [BusinessPartnerState.Active]: COLOR_CONF.GREEN,
  [BusinessPartnerState.Inactive]: COLOR_CONF.RED,
  [BusinessPartnerState.Deleted]: COLOR_CONF.GRAY
}

export const websiteStateColors = {
  [WebsiteState.Published]: COLOR_CONF.GREEN,
  [WebsiteState.Draft]: COLOR_CONF.YELLOW,
  [WebsiteState.Deleted]: COLOR_CONF.RED
}

export const websitePageStateColors = {
  [WebsitePageState.Published]: COLOR_CONF.GREEN,
  [WebsitePageState.Draft]: COLOR_CONF.YELLOW,
  [WebsitePageState.Archived]: COLOR_CONF.RED
}

export const accessZoneStateColors = {
  [AccessZoneState.Active]: COLOR_CONF.GREEN,
  [AccessZoneState.Inactive]: COLOR_CONF.RED
}

export const warehouseDocumentStateColors = {
  [WarehouseDocumentState.Draft]: COLOR_CONF.YELLOW,
  [WarehouseDocumentState.Issued]: COLOR_CONF.GREEN
}

export const inventoryCheckStateColors = {
  [InventoryCheckState.Draft]: COLOR_CONF.YELLOW,
  [InventoryCheckState.Completed]: COLOR_CONF.GREEN
}

export const useEventStateTranslations = () => {
  const {t} = useTranslation()

  return {
    [EventState.Draft]: t('Draft'),
    [EventState.Published]: t('Published'),
    [EventState.Canceled]: t('Cancelled'),
    [EventState.Deleted]: t('Deleted')
  }
}

export const eventPricingStatusColors = {
  [PricingStatus.Past]: COLOR_CONF.RED,
  [PricingStatus.Current]: COLOR_CONF.GREEN,
  [PricingStatus.Future]: COLOR_CONF.YELLOW
}

export const CENTERED_LAYOUT_MAX_WIDTH_ON_LARGE_DESKTOP = 960

export const MediaSizes = {
  SmallMobile: '(max-width: 575px)',
  LargeMobilePlus: '(min-width: 576px)',
  TabletPlus: '(min-width: 768px)',
  Phablet: '(max-width: 767px)',
  DesktopMinus: '(max-width: 959px)',
  DesktopPlus: '(min-width: 960px)',
  LargeDesktopMinus: '(max-width: 1364px)',
  LargeDesktop: '(min-width: 1365px)',
  BetweenTabletAndDesktop: '(min-width: 768px) and (max-width: 959px)',
  BetweenLargeMobileAndTablet: '(min-width: 576px) and (max-width: 767px)'
}
