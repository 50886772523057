import {GridColDef} from '@mui/x-data-grid-pro'
import {chain} from 'lodash'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  CreateMonthlyECommercePaymentReportInput,
  CreateMonthlyPaymentReportInput,
  Month,
  PaymentReportType,
  TemplateType
} from '../../../../__generated__/schema'
import {useRequireEffectiveClient} from '../../../../hooks/requireEffectiveClient'
import {LocalStorageKey, useLocalStorageState} from '../../../../hooks/storage'
import {
  useDateMonthYearFormatter,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter
} from '../../../common/DataGridTable'
import {useGetTemplates} from '../templates/graphql'
import {IMonthlyPaymentReportForm, MonthlyPaymentReportField} from './types'

export const transformMonthlyPaymentReportFormToInput = (
  formData: IMonthlyPaymentReportForm
):
  | CreateMonthlyECommercePaymentReportInput
  | CreateMonthlyPaymentReportInput => {
  const splittedYearMonth =
    formData[MonthlyPaymentReportField.YearMonth].split('-')
  const year = parseInt(splittedYearMonth[0], 10)
  const month = splittedYearMonth[1] as Month
  return {
    note: formData[MonthlyPaymentReportField.Note],
    year,
    month
  }
}

const getTemplateTypeFromPaymentReportType = (
  type: PaymentReportType | undefined
): TemplateType | undefined => {
  switch (type) {
    case PaymentReportType.DailyCashierReport:
      return TemplateType.DailyCashierReport
    case PaymentReportType.MonthlyECommerceReport:
      return TemplateType.MonthlyECommerceReport
    case PaymentReportType.MonthlyReport:
      return TemplateType.MonthlyReport
    default:
      return undefined
  }
}

export const useGetPaymentReportTemplates = (
  type: PaymentReportType | undefined
) => {
  const effectiveClient = useRequireEffectiveClient()
  const [deviceTemplateIds] = useLocalStorageState<{
    [templateType: string]: number[]
  }>(LocalStorageKey.TemplateIdsAssignedToDeviceByType, {})
  const {data, loading} = useGetTemplates(
    {
      clientId: effectiveClient.id,
      type: getTemplateTypeFromPaymentReportType(type)
    },
    !effectiveClient.id || !type
  )
  if (!data || loading) {
    return []
  }
  const deviceTemplates = chain(data.templates)
    .keyBy('id')
    .at(type ? deviceTemplateIds[type] : [])
    .compact()
    .value()
  return deviceTemplates.length ? deviceTemplates : data?.templates
}

export const useColDefForMonthlyReports = (customCols: GridColDef[]) => {
  const {t} = useTranslation()
  const dateMonthYearFormatter = useDateMonthYearFormatter()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter()
  const dateTimeFormatter = useDateTimeFormatter()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('ID'),
        field: 'id',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Reported month'),
        field: 'reportFrom',
        valueFormatter: dateMonthYearFormatter,
        sortable: false,
        minWidth: 150
      },
      {
        headerName: t('Revenue'),
        field: 'revenueVatIncluded',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 150
      },
      {
        headerName: t('Amount'),
        field: 'paymentsAmount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        valueFormatter: effectiveClientPriceFormatter,
        minWidth: 150
      },
      {
        headerName: t('Payments'),
        field: 'paymentsCount',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        minWidth: 50
      },
      {
        headerName: t('Created at'),
        field: 'createdAt',
        valueFormatter: dateTimeFormatter,
        sortable: false,
        minWidth: 200
      },
      ...customCols
    ],
    [
      customCols,
      dateMonthYearFormatter,
      dateTimeFormatter,
      effectiveClientPriceFormatter,
      t
    ]
  )
  return columns
}
