import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {useIsStringWithMaxLength} from '../../../../../utils/formsValidations'
import {InputRow} from '../../../../common'
import {UncontrolledFormTextInput} from '../../../../form/FormTextInput'
import {IProductBarcodeForm, ProductBarcodeFormField} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface IBarcodeFormProps {
  formId: string
  onSubmit: (data: IProductBarcodeForm) => void
  className?: string
}

export const BarcodeForm: React.FC<IBarcodeFormProps> = ({
  formId,
  onSubmit,
  className
}: IBarcodeFormProps) => {
  const {t} = useTranslation()
  const {errors, setValue, watch, register, triggerValidation, handleSubmit} =
    useFormContext<IProductBarcodeForm>()
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IProductBarcodeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductBarcodeFormField.Code}
            key={ProductBarcodeFormField.Code}
            label={t('Code')}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IProductBarcodeForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            name={ProductBarcodeFormField.Description}
            key={ProductBarcodeFormField.Description}
            label={t('Description')}
            validationOptions={{
              validate: isStringWithMaxLength
            }}
            fullWidth
            multiline
            rows={4}
          />
        ]}
      />
    </form>
  )
}
