import {Drawer, drawerClasses} from '@mui/material'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {ApiGrantForm} from './ApiGrantForm'
import {useCreateApiGrant} from './graphql'
import {ApiGrantField, IApiGrantForm} from './types'
import {transformFormDataToApiGrantInput} from './utils'

const CREATE_API_GRANT_FORM_ID = 'createApiGrantForm'

interface ICreateApiGrantDrawerProps {
  isOpen: boolean
  onClose: () => void
  setCreatedApiKey: (data: {id: number; key: string}) => void
}

export const CreateApiGrantDrawer: React.FC<ICreateApiGrantDrawerProps> = ({
  isOpen,
  onClose,
  setCreatedApiKey
}: ICreateApiGrantDrawerProps) => {
  const {t} = useTranslation()
  const createApiGrant = useCreateApiGrant()
  const {setShowBackdrop} = useMutationAssistanceHooks()
  const handleSubmit = useCallback(
    async (formData: IApiGrantForm) => {
      try {
        setShowBackdrop(true)
        const {data} = await createApiGrant(
          transformFormDataToApiGrantInput(formData)
        )
        if (data) {
          setCreatedApiKey({
            id: data.createApiGrant.apiGrant.id,
            key: data.createApiGrant.bearerToken
          })
        }
        onClose()
      } finally {
        setShowBackdrop(false)
      }
    },
    [createApiGrant, onClose, setCreatedApiKey, setShowBackdrop]
  )
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
    >
      <DrawerTemplate
        header={
          <DrawerTemplateHeader
            onLeftActionClick={onClose}
            title={t('Create API grant')}
          />
        }
        footer={
          <SaveButton type="submit" form={CREATE_API_GRANT_FORM_ID}>
            {t('Create')}
          </SaveButton>
        }
        childrenSx={{backgroundColor: 'background.paper'}}
      >
        <ApiGrantForm
          formId={CREATE_API_GRANT_FORM_ID}
          onSubmit={handleSubmit}
          defaultValues={{
            [ApiGrantField.ExpiresAt]: dayjs().add(365, 'days').toISOString(),
            [ApiGrantField.MaxRequestsPerMinute]: '50',
            [ApiGrantField.MaxRequestsPerHour]: '200'
          }}
        />
      </DrawerTemplate>
    </Drawer>
  )
}
