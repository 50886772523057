import {
  ListItem,
  ListItemIcon,
  listItemSecondaryActionClasses,
  ListItemText
} from '@mui/material'
import React from 'react'

interface IListItemWithAddCustomButtonProps {
  disabled?: boolean
  icon?: React.ReactNode
  primaryText: string
  secondaryText?: string
  button: React.ReactElement
}

export const ListItemWithAddCustomButton: React.FC<IListItemWithAddCustomButtonProps> =
  ({
    disabled,
    button,
    icon,
    primaryText,
    secondaryText
  }: IListItemWithAddCustomButtonProps) => (
    <ListItem
      sx={{
        minHeight: 40,
        p: 0,
        borderBottom: (theme) => `solid ${theme.palette.divider} 1px`,
        '&:last-child': {
          borderBottom: 0
        },
        gap: 2,
        opacity: (theme) =>
          disabled ? theme.palette.action.disabledOpacity : undefined,
        cursor: disabled ? 'pointer' : 'normal',
        pointerEvents: disabled ? 'none' : 'auto',
        [`& .${listItemSecondaryActionClasses.root}`]: {
          right: 0
        }
      }}
      secondaryAction={button}
    >
      {icon && <ListItemIcon sx={{minWidth: 'unset'}}>{icon}</ListItemIcon>}
      <ListItemText
        sx={{overflowWrap: 'anywhere', maxWidth: '80%'}}
        primary={primaryText}
        primaryTypographyProps={{variant: 'body1'}}
        secondary={secondaryText}
        secondaryTypographyProps={{variant: 'caption', color: 'textSecondary'}}
      />
    </ListItem>
  )
