import {Button} from '@mui/material'
import {get} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Route, useHistory} from 'react-router-dom'
import {
  DetailClientShowPropertiesFragment,
  PermissionCode
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {useGetShowTitle} from '../../../../../utils/translations'
import {ShowSection} from '../../../../constants'
import {CenteredLayout} from '../../Layout'
import {useUpsertClientShowCrew} from '../graphql'
import {ShowCrewForm} from '../ShowCrewForm'
import {
  IShowCrewFieldsProps,
  IShowCrewForm,
  showCrewForm,
  ShowCrewFormField
} from '../types'
import {
  mapFormValuesToShowCrewRoleInput,
  mapShowCrewRoleInputToFormValues
} from '../utils'
import {EditClientShowPageDetailTemplate} from './EditClientShowPageDetailTemplate'
import {LibraryCrewDrawer} from './LibraryCrewDrawer'

interface IEditClientShowCrewProps {
  clientShow: DetailClientShowPropertiesFragment
}

export const EditClientShowCrew: React.FC<IEditClientShowCrewProps> = ({
  clientShow
}: IEditClientShowCrewProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const history = useHistory()
  const getShowTitle = useGetShowTitle()
  const upsertClientShowCrew = useUpsertClientShowCrew()
  const {defaultErrorHandler, addInfoNotification, setShowBackdrop} =
    useMutationAssistanceHooks()
  const methods = useForm<IShowCrewForm>()
  const {getValues} = methods
  const showTitle = getShowTitle({
    translations: clientShow.translations,
    originalTitle: clientShow.originalTitle,
    fallbackTitle: t('Show')
  })
  const [crewFields, setCrewFields] = useState<IShowCrewFieldsProps[]>([])
  const crewFormFields = mapFormValuesToShowCrewRoleInput(
    crewFields
      .filter((f) => f.crewRoleCode)
      .map((f) => ({
        crewRoleCode: get(
          getValues(),
          `${ShowCrewFormField.CrewRoleCode}[${f.id}]`
        ),
        name: get(getValues(), `${ShowCrewFormField.PersonName}[${f.id}]`),
        description: get(
          getValues(),
          `${ShowCrewFormField.Description}[${f.id}]`
        )
      }))
  )
  useEffect(() => {
    if (clientShow.crew.length > 0) {
      setCrewFields(mapShowCrewRoleInputToFormValues(clientShow.crew))
    }
  }, [clientShow.crew])
  const handleClose = useCallback(
    () => history.replace(routeTo.admin.shows.home()),
    [history]
  )
  const handleSubmit = useCallback(async () => {
    const data = crewFields.map((f) => ({
      crewRoleCode: get(
        getValues(),
        `${ShowCrewFormField.CrewRoleCode}[${f.id}]`
      ),
      name: get(getValues(), `${ShowCrewFormField.PersonName}[${f.id}]`),
      description: get(getValues(), `${ShowCrewFormField.Description}[${f.id}]`)
    }))
    try {
      setShowBackdrop(true)
      await upsertClientShowCrew({
        id: clientShow.id,
        data: mapFormValuesToShowCrewRoleInput(data)
      })
      addInfoNotification(t('Crew has been updated successfully'))
    } catch (error) {
      defaultErrorHandler(error, t('Error while updating show crew'))
    } finally {
      setShowBackdrop(false)
    }
  }, [
    addInfoNotification,
    clientShow.id,
    crewFields,
    defaultErrorHandler,
    getValues,
    setShowBackdrop,
    t,
    upsertClientShowCrew
  ])
  const handleLibraryButtonClick = useCallback(
    () => history.push(routeTo.admin.shows.libraryCrew(clientShow.id)),
    [clientShow.id, history]
  )
  const handleExited = useCallback(
    () =>
      history.push(
        routeTo.admin.shows.editWithEditSection(clientShow.id, ShowSection.Crew)
      ),
    [clientShow.id, history]
  )
  return (
    <EditClientShowPageDetailTemplate
      onClose={handleClose}
      showName={showTitle}
      formId={showCrewForm}
      currentTab={ShowSection.Crew}
    >
      <>
        <CenteredLayout>
          <FormContext {...methods}>
            <ShowCrewForm
              onSubmit={handleSubmit}
              crewFields={crewFields}
              setCrewFields={setCrewFields}
              showType={clientShow.typeCode}
              headerAction={
                P([PermissionCode.ReadLibraryShow]) && (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleLibraryButtonClick}
                  >
                    {t('Library')}
                  </Button>
                )
              }
            />
          </FormContext>
        </CenteredLayout>
        {P([PermissionCode.ReadLibraryShow]) && (
          <Route path={routeTo.admin.shows.libraryCrew(':showId')} exact>
            <LibraryCrewDrawer
              showCrew={crewFormFields}
              libraryShowId={clientShow.showsDbId}
              onExited={handleExited}
              setCrewFormFields={setCrewFields}
            />
          </Route>
        )}
      </>
    </EditClientShowPageDetailTemplate>
  )
}
