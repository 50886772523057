import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {Route, useHistory} from 'react-router-dom'
import {
  CashDeskCustomerFieldsFragment,
  LeadInputStatus,
  LeadOptionPropertiesFragment
} from '../../../../../__generated__/schema'
import {useLeadOptionsObject} from '../../../../../hooks/leadSection'
import {Theme} from '../../../../../theme'
import {routeTo} from '../../../../../utils/routes'
import {ILeadForm} from '../types'
import {BriefLeadForm} from './BriefLeadForm'
import {LeadDetailLayout} from './LeadDetailLayout'
import {LeadDrawer} from './LeadDrawer'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    paddingTop: theme.spacing(2)
  }
}))

interface ILeadDetailProps {
  leadOptions: LeadOptionPropertiesFragment[]
  onSubmit: (leadForm: ILeadForm) => void
  selectedCustomer?: CashDeskCustomerFieldsFragment | null
}

export const LeadDetail: React.FC<ILeadDetailProps> = ({
  leadOptions,
  onSubmit,
  selectedCustomer
}: ILeadDetailProps) => {
  const classes = useStyles()
  const history = useHistory()
  const handleMoreButtonClick = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.cartDetailWithLead())
  }, [history])
  const handleLeadDrawerExited = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.cartDetail())
  }, [history])
  const handlePersonButtonClick = useCallback(() => {
    history.replace(routeTo.admin.cashDesk.cartDetailWithCustomers())
  }, [history])
  const {watch, reset} = useFormContext<ILeadForm>()
  const leadDrawerDefaultValues = watch()
  const onLeadDrawerSubmit = useCallback(
    (form: ILeadForm) => {
      reset(form)
    },
    [reset]
  )
  useEffect(() => {
    if (selectedCustomer) {
      reset(selectedCustomer.lead.data)
    }
  }, [reset, selectedCustomer])
  const leadOptionsObject = useLeadOptionsObject(leadOptions)
  return (
    <LeadDetailLayout
      onMoreButtonClick={handleMoreButtonClick}
      onPersonButtonClick={handlePersonButtonClick}
      sx={selectedCustomer ? {display: 'none'} : {}}
    >
      <BriefLeadForm
        leadOptionsObject={leadOptionsObject}
        onSubmit={onSubmit}
        className={
          leadOptions.every((option) =>
            [LeadInputStatus.Optional, LeadInputStatus.Unavailable].includes(
              option.inputStatus
            )
          )
            ? undefined
            : classes.form
        }
      />
      <Route path={routeTo.admin.cashDesk.cartDetailWithLead()}>
        <LeadDrawer
          defaultValues={leadDrawerDefaultValues}
          onExited={handleLeadDrawerExited}
          onSubmit={onLeadDrawerSubmit}
          leadOptionsObject={leadOptionsObject}
        />
      </Route>
    </LeadDetailLayout>
  )
}
