import {Drawer} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../hooks/state'
import {useMarketingLabelParams} from '../../../../utils/pathname'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {useGetMarketingLabel, useUpdateMarketingLabel} from './graphql'
import {MarketingLabelForm} from './MarketingLabelForm'
import {
  IMarketingLabelForm,
  MarketingLabelFormField,
  MarketingLabelState
} from './types'

const UPDATE_MARKETING_LABEL_FORM_ID = 'updateMarketingLabelForm'

const useStyles = makeStyles(() => ({
  drawerPaper: {
    maxWidth: 560,
    width: '100%'
  }
}))

interface IUpdateMarketingLabelDrawerProps {
  onExited: () => void
}

export const UpdateMarketingLabelDrawer: React.FC<IUpdateMarketingLabelDrawerProps> =
  ({onExited}: IUpdateMarketingLabelDrawerProps) => {
    const {t} = useTranslation()
    const {marketingLabelId} = useMarketingLabelParams()
    const {data, loading, error} = useGetMarketingLabel(marketingLabelId)
    const updateMarketingLabel = useUpdateMarketingLabel()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const {
      state: isOpen,
      setTrue: openDrawer,
      setFalse: closeDrawer
    } = useBooleanState(false)
    useEffect(() => {
      if (marketingLabelId) {
        openDrawer()
      }
    }, [marketingLabelId, openDrawer])
    const classes = useStyles()
    const handleSubmit = useCallback(
      async (data: IMarketingLabelForm) => {
        try {
          setShowBackdrop(true)
          await updateMarketingLabel({
            id: marketingLabelId,
            data: {
              ...data,
              isActive:
                data[MarketingLabelFormField.IsActive] ===
                MarketingLabelState.Active
            }
          })
          addInfoNotification(t('Marketing label updated'))
          onExited()
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating marketing label'))
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        defaultErrorHandler,
        marketingLabelId,
        onExited,
        setShowBackdrop,
        t,
        updateMarketingLabel
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeDrawer}
        SlideProps={{onExited}}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error && t<string>('Error while loading marketing label')
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={closeDrawer}
              title={t('Update marketing label')}
            />
          }
          footer={
            <SaveButton type="submit" form={UPDATE_MARKETING_LABEL_FORM_ID}>
              {t('Save')}
            </SaveButton>
          }
        >
          {data && (
            <MarketingLabelForm
              formId={UPDATE_MARKETING_LABEL_FORM_ID}
              onSubmit={handleSubmit}
              defaultValues={{
                [MarketingLabelFormField.Name]: data.marketingLabel.name,
                [MarketingLabelFormField.Label]:
                  data.marketingLabel.label || undefined,
                [MarketingLabelFormField.IsActive]: data.marketingLabel.isActive
                  ? MarketingLabelState.Active
                  : MarketingLabelState.Inactive
              }}
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
