import {Drawer} from '@mui/material'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {MessageType} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {
  DrawerTemplateHeader,
  useDrawerWithSideNavigationStyles
} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {DrawerTemplateWithSideNavigation} from '../../../../common/DrawerTemplateWithSideNavigation'
import {useSendMessage} from './graphql'
import {MessageForm} from './MessageForm'
import {IMessageForm, MessageFormField, useNavigationItems} from './types'

const CREATE_MESSAGE_FORM_ID = 'createMessageForm'

interface ICreateMessageDrawerProps {
  isOpen: boolean
  onClose: () => void
  defaultValues?: Partial<IMessageForm>
  leads: {id: number; contact: string}[]
}

export const CreateMessageDrawer: React.FC<ICreateMessageDrawerProps> = ({
  isOpen,
  onClose,
  defaultValues,
  leads
}: ICreateMessageDrawerProps) => {
  const {t} = useTranslation()
  const sendMessage = useSendMessage()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const navigationItems = useNavigationItems()
  const drawerClasses = useDrawerWithSideNavigationStyles()

  const handleSubmit = useCallback(
    async (data: IMessageForm) => {
      try {
        setShowBackdrop(true)
        await sendMessage({
          ...data,
          type: MessageType.Email,
          leadIds: data[MessageFormField.LeadIds]
            .toString()
            .split(',')
            .map(Number)
        })
        addInfoNotification(t('Message was sent'))
        onClose()
      } catch (error) {
        defaultErrorHandler(error, t('Error while sending message'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      onClose,
      sendMessage,
      setShowBackdrop,
      t
    ]
  )
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      classes={drawerClasses}
    >
      <DrawerTemplateWithSideNavigation
        DrawerTemplateProps={{
          header: (
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Send message')}
            />
          ),
          footer: (
            <SaveButton type="submit" form={CREATE_MESSAGE_FORM_ID}>
              {t('Send')}
            </SaveButton>
          )
        }}
        navigationItems={navigationItems}
      >
        <MessageForm
          formId={CREATE_MESSAGE_FORM_ID}
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          leads={leads}
        />
      </DrawerTemplateWithSideNavigation>
    </Drawer>
  )
}
