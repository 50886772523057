import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import {Box, Button, Chip, IconButton} from '@mui/material'
import omit from 'lodash/omit'
import React, {useCallback, useState} from 'react'
import DraggableList from 'react-draggable-list'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  Scalars,
  WebsitePageType,
  WebsiteQuery
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {useBooleanState} from '../../../../../hooks/state'
import {useTranslateWebsitePageState} from '../../../../../hooks/translateWebsitePageState'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  ButtonWithConfirmationDialog,
  EntityStateChip,
  Tooltip
} from '../../../../common'
import {TreeItems} from '../../../../common/sortableTree/types'
import {SubHeaderToolbar} from '../../../../common/SubHeaderToolbar'
import {websitePageStateColors} from '../../../../constants'
import {Blank} from '../../../../visual/Blank'
import {
  useDeleteWebsitePage,
  useUpdateWebsiteFooter,
  useUpdateWebsiteHeader,
  useUpdateWebsitPageBlocks
} from '../graphql'
import {BlockType, ItemGroupWithId} from '../types'
import {useTranslateWebsitePageType} from '../utils'
import {DraggableBlockItem, IBlockItemCommonProps} from './BlockItem'
import {CopyWebsitePageDrawer} from './CopyWebsitePageDrawer'
import {EditBlockDrawer} from './editBlockDrawer'
import {EditBlockProps} from './editBlockDrawer/types'
import {EditFooterDrawer} from './editFooterDrawer'
import {EditHeaderDrawer} from './editHeaderDrawer'
import {
  extractHeaderDataFromTreeItems,
  transformItemGroupWithIdsToUpdateWebsiteFooterInput
} from './editHeaderDrawer/utils'
import {UpdateWebsitePageDrawer} from './UpdateWebsitePageDrawer'
import {useWebsitePageContext} from './WebsitePageContext'
import {WebsitePageModeSwitcherButton} from './WebsitePageModeSwitcherButton'

interface IPageContentProps {
  website: WebsiteQuery['website']
}

export const PageContent: React.FC<IPageContentProps> = ({
  website
}: IPageContentProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    selectedWebsitePage,
    websiteId,
    refetchWebsitePages,
    blocksWithId,
    setBlocksWithId,
    refetchWebsiteHeaders,
    websiteFooters,
    refetchWebsiteFooters
  } = useWebsitePageContext()
  const deleteWebsitePage = useDeleteWebsitePage()
  const updateWebsitPageBlocks = useUpdateWebsitPageBlocks()
  const updateWebsiteHeader = useUpdateWebsiteHeader()
  const updateWebsiteFooter = useUpdateWebsiteFooter()
  const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
    useMutationAssistanceHooks()
  const {
    state: isCopyWebsitePageDrawerOpen,
    setTrue: openCopyWebsitePageDrawer,
    setFalse: closeCopyWebsitePageDrawer
  } = useBooleanState(false)
  const {
    state: isUpdateWebsitePageDrawerOpen,
    setTrue: openUpdateWebsitePageDrawer,
    setFalse: closeUpdateWebsitePageDrawer
  } = useBooleanState(false)
  const [selectedBlockForEdit, setSelectedBlockForEdit] =
    useState<Scalars['JSON'] | null>(null)
  const [selectedHeaderIdForEdit, setSelectedHeaderIdForEdit] =
    useState<string | null>(null)
  const [selectedFooterIdForEdit, setSelectedFooterIdForEdit] =
    useState<string | null>(null)
  const selectedFooterForEdit = websiteFooters.find(
    ({ID}) => ID === selectedFooterIdForEdit
  )
  const translateWebsitePageState = useTranslateWebsitePageState()
  const translateWebsitePageType = useTranslateWebsitePageType()
  const handleDeleteButtonClick = useCallback(async () => {
    if (selectedWebsitePage) {
      try {
        setShowBackdrop(true)
        await deleteWebsitePage({websiteId, pageID: selectedWebsitePage.ID})
        refetchWebsitePages()
        addInfoNotification(t('Website page has been deleted'))
      } catch (error) {
        defaultErrorHandler(error, t('Error while deleting website page'))
      } finally {
        setShowBackdrop(false)
      }
    }
  }, [
    refetchWebsitePages,
    addInfoNotification,
    defaultErrorHandler,
    deleteWebsitePage,
    selectedWebsitePage,
    setShowBackdrop,
    t,
    websiteId
  ])
  const handleSaveButtonClick = useCallback(async () => {
    if (selectedWebsitePage) {
      try {
        setShowBackdrop(true)
        await updateWebsitPageBlocks({
          websiteId,
          pageID: selectedWebsitePage.ID,
          blocks: Object.values(blocksWithId).map((block: Scalars['JSON']) =>
            omit(block, 'id')
          )
        })
        addInfoNotification(t('Blocks have been reordered'))
      } catch (error) {
        defaultErrorHandler(
          error,
          t('Error while updating website page blocks')
        )
      } finally {
        setShowBackdrop(false)
        refetchWebsitePages()
      }
    }
  }, [
    addInfoNotification,
    blocksWithId,
    defaultErrorHandler,
    refetchWebsitePages,
    selectedWebsitePage,
    setShowBackdrop,
    t,
    updateWebsitPageBlocks,
    websiteId
  ])
  const handleEditBlockApplyButtonClick = useCallback(
    (blockProps: EditBlockProps) => {
      setBlocksWithId((prevBlocks: Scalars['JSON']) =>
        prevBlocks.map((prevBlock: Scalars['JSON']) =>
          prevBlock.id === selectedBlockForEdit?.id
            ? {...prevBlock, props: blockProps}
            : prevBlock
        )
      )
    },
    [setBlocksWithId, selectedBlockForEdit?.id]
  )
  const handleEditBlockButtonClick = useCallback((block) => {
    if (block.type === BlockType.Header) {
      setSelectedHeaderIdForEdit(block.props.headerId)
    } else if (block.type === BlockType.Footer) {
      setSelectedFooterIdForEdit(block.props.footerId)
    } else {
      setSelectedBlockForEdit(block)
    }
  }, [])
  const handleSaveWebsiteHeader = useCallback(
    async (newItems: TreeItems) => {
      if (selectedHeaderIdForEdit) {
        try {
          setShowBackdrop(true)
          await updateWebsiteHeader({
            websiteId,
            headerID: selectedHeaderIdForEdit,
            input: {
              items: extractHeaderDataFromTreeItems(newItems)
            }
          })
          refetchWebsiteHeaders()
          addInfoNotification(t('Header updated'))
          setSelectedHeaderIdForEdit(null)
        } catch (error) {
          defaultErrorHandler(error, t('Error while updating website header'))
        } finally {
          setShowBackdrop(false)
        }
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      refetchWebsiteHeaders,
      selectedHeaderIdForEdit,
      setShowBackdrop,
      t,
      updateWebsiteHeader,
      websiteId
    ]
  )
  const handleSaveWebsiteFooter = useCallback(
    async (footerID: string, itemGroups: ItemGroupWithId[]) => {
      try {
        setShowBackdrop(true)
        await updateWebsiteFooter({
          footerID,
          websiteId,
          input: transformItemGroupWithIdsToUpdateWebsiteFooterInput(itemGroups)
        })
        refetchWebsiteFooters()
        addInfoNotification(t('Footer has been updated'))
        setSelectedFooterIdForEdit(null)
      } catch (error) {
        defaultErrorHandler(error, t('Error while updating website footer'))
      } finally {
        setShowBackdrop(false)
      }
    },
    [
      addInfoNotification,
      defaultErrorHandler,
      refetchWebsiteFooters,
      setShowBackdrop,
      t,
      updateWebsiteFooter,
      websiteId
    ]
  )
  return selectedWebsitePage ? (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto'
        }}
      >
        <SubHeaderToolbar
          sx={{position: 'sticky', top: 0}}
          title={selectedWebsitePage.title}
          leftActions={[
            <Chip
              key="locale-code"
              label={selectedWebsitePage.localeCode.toUpperCase()}
              sx={{height: 24}}
            />,
            <EntityStateChip
              key="state"
              colorConf={websitePageStateColors[selectedWebsitePage.state]}
              label={translateWebsitePageState(selectedWebsitePage.state)}
            />
          ]}
          rightActions={[
            <WebsitePageModeSwitcherButton key="mode-switcher-button" />,
            P([PermissionCode.CreateWebsitePage]) && (
              <Tooltip title={t('Copy page')} key="copy-page">
                <IconButton onClick={openCopyWebsitePageDrawer}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            ),
            P([PermissionCode.UpdateWebsitePage]) &&
              selectedWebsitePage.type !== WebsitePageType.Homepage && (
                <Tooltip title={t('Page settings')} key="page-settings">
                  <IconButton onClick={openUpdateWebsitePageDrawer}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )
          ].filter(Boolean)}
        />
        <Box
          sx={{
            overflow: 'auto',
            p: 2,
            gap: 2
          }}
        >
          <DraggableList<
            Scalars['JSON'],
            IBlockItemCommonProps,
            DraggableBlockItem
          >
            list={blocksWithId}
            itemKey="id"
            template={DraggableBlockItem}
            padding={8}
            constrainDrag
            onMoveEnd={(blocks: Scalars['JSON']) => setBlocksWithId(blocks)}
            commonProps={{
              getDeleteClickHandler: (id: string) => () =>
                setBlocksWithId((prevBlocksWithId: Scalars['JSON']) =>
                  prevBlocksWithId.filter(
                    (block: Scalars['JSON']) => block.id !== id
                  )
                ),
              editableBlocks: [
                BlockType.Markdown,
                BlockType.Header,
                BlockType.Label,
                BlockType.FeaturedShowPhotos,
                BlockType.FeaturedShowPosters,
                BlockType.FeaturedShowCovers,
                BlockType.FeaturedEventPosters,
                BlockType.EventsList,
                BlockType.Footer,
                BlockType.CardsRow,
                BlockType.ButtonGroup
              ],
              onEditBlockButtonClick: handleEditBlockButtonClick
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: 64,
            width: '100%',
            borderTop: (theme) => `solid ${theme.palette.divider} 1px`,
            backgroundColor: 'background.paper',
            alignContent: 'center',
            justifyContent: 'flex-end',
            alignItems: 'center',
            py: 1.85,
            px: 3,
            gap: 2
          }}
        >
          <ButtonWithConfirmationDialog
            onConfirmButtonClick={handleDeleteButtonClick}
            dialogProps={{
              confirmButtonLabel: t('Delete'),
              title: t('Delete website page?'),
              contentText: t(
                'Are you sure you want to delete this website page? This can’t be undone.'
              )
            }}
            buttonProps={{
              startIcon: <DeleteIcon />,
              children: t('Delete'),
              color: 'primary',
              disabled: !!selectedWebsitePage.type
            }}
            tooltip={
              selectedWebsitePage.type
                ? t("{{type}} can't be deleted", {
                    type: translateWebsitePageType(selectedWebsitePage.type)
                  })
                : undefined
            }
          />
          {P([PermissionCode.UpdateWebsitePageBlocks]) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveButtonClick}
            >
              {t('Save')}
            </Button>
          )}
        </Box>
      </Box>
      <CopyWebsitePageDrawer
        isOpen={isCopyWebsitePageDrawerOpen}
        onClose={closeCopyWebsitePageDrawer}
        website={website}
      />
      <UpdateWebsitePageDrawer
        isOpen={isUpdateWebsitePageDrawerOpen}
        onClose={closeUpdateWebsitePageDrawer}
        website={website}
      />
      <EditBlockDrawer
        block={selectedBlockForEdit}
        onClose={() => setSelectedBlockForEdit(null)}
        onApplyButtonClick={handleEditBlockApplyButtonClick}
      />
      <EditHeaderDrawer
        headerId={selectedHeaderIdForEdit}
        onClose={() => setSelectedHeaderIdForEdit(null)}
        onSaveButtonClick={handleSaveWebsiteHeader}
      />
      {selectedFooterForEdit && selectedFooterIdForEdit && (
        <EditFooterDrawer
          footer={selectedFooterForEdit}
          onExited={() => setSelectedFooterIdForEdit(null)}
          onSaveButtonClick={handleSaveWebsiteFooter}
        />
      )}
    </>
  ) : (
    <Blank title={t('No page selected')} />
  )
}
