import {Drawer, Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useCallbackRef} from '../../../../../hooks/callbackRef'
import {Theme} from '../../../../../theme'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {
  EVENTS_PARAMS as EP,
  useEventsPathnameParams
} from '../../../../../utils/pathname'
import {routeTo, toPlaceholderParam} from '../../../../../utils/routes'
import {SCROLLABLE_CONTENT_ID} from '../../../../common'
import {EventDiscounts} from './eventDiscounts'

import {MainEventPropertiesForm} from './mainPropertiesForm'
import {GeneralEventInfoForm} from './mainPropertiesForm/general/GeneralEventInfoForm'
import {PricingsDetail} from './pricingsDetail'
import {SalesOptionsForm} from './salesForm'
import {EventSeats} from './seats'

const WIDTH = 940

const useStyles = makeStyles<Theme>(() => ({
  wrapper: {
    minWidth: WIDTH,
    minHeight: '100%',
    display: 'flex'
  }
}))

export const EditEventSection: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const {eventId} = useEventsPathnameParams()
  const {P} = useEnsurePermissions()

  // Can we do better? We can not wrap `Drawer` into `Route` as we would lost transitions
  const open = location.pathname !== routeTo.admin.events.home()

  const onClose = () => {
    // We allow to directly close drawer only for "first-level"
    if (location.pathname === routeTo.admin.events.edit(eventId)) {
      history.push(routeTo.admin.events.home())
    }
  }

  const {htmlNode: drawerNode, callbackRef: setDrawerNode} = useCallbackRef()

  // Note: we can not scroll on `window` as the scrollable content is within drawer
  const elementToScrollOn =
    drawerNode && drawerNode.querySelector(`#${SCROLLABLE_CONTENT_ID}`)

  return (
    <Drawer ref={setDrawerNode} open={open} onClose={onClose} anchor="right">
      <Grid className={classes.wrapper}>
        <Switch>
          {P([PermissionCode.ReadEvent]) && (
            <Route
              path={routeTo.admin.events.edit(toPlaceholderParam(EP.EVENT_ID))}
              exact
            >
              <MainEventPropertiesForm
                width={WIDTH}
                elementToScrollOn={elementToScrollOn}
              />
            </Route>
          )}
          {P([PermissionCode.UpdateGeneralEventData]) && (
            <Route
              path={routeTo.admin.events.editGeneralOptions(
                toPlaceholderParam(EP.EVENT_ID)
              )}
              exact
            >
              <GeneralEventInfoForm
                width={WIDTH}
                elementToScrollOn={elementToScrollOn}
              />
            </Route>
          )}

          <Route
            path={routeTo.admin.events.editSaleOptions(
              toPlaceholderParam(EP.EVENT_ID)
            )}
            exact
          >
            <SalesOptionsForm
              width={WIDTH}
              elementToScrollOn={elementToScrollOn}
            />
          </Route>

          <Route
            path={routeTo.admin.events.editPricingOptions(
              toPlaceholderParam(EP.EVENT_ID)
            )}
            exact
          >
            <PricingsDetail />
          </Route>

          <Route
            path={routeTo.admin.events.eventDiscountDetails(
              toPlaceholderParam(EP.EVENT_ID)
            )}
            exact
          >
            <EventDiscounts eventId={eventId} />
          </Route>

          <Route
            path={routeTo.admin.events.editSeatsOptions(
              toPlaceholderParam(EP.EVENT_ID)
            )}
            exact
          >
            <EventSeats />
          </Route>
        </Switch>
      </Grid>
    </Drawer>
  )
}
