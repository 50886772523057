import GeneratePasswordIcon from '@mui/icons-material/Cached'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {Button, Grid, IconButton, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {useNotifications} from '../../../../context/notifications'

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.primary.main,
    textTransform: 'none'
  },
  text: {
    marginLeft: theme.spacing(1)
  },
  copyIcon: {
    marginTop: theme.spacing(1)
  }
}))

const UPPER_CHARS = _.range(65, 91)
  .map((i: number) => String.fromCharCode(i))
  .join('')

const LOWER_CHARS = UPPER_CHARS.toLowerCase()

const NUMBERS = _.range(0, 10).join('')

const FORBIDDEN_CHARS = ['0', '1', 'i', 'I', 'l', 'L', 'o', 'O', 'Y', 'Z']

const ALLOWED_CHARS = `${UPPER_CHARS}${LOWER_CHARS}${NUMBERS}`
  .split('')
  .filter((c) => !FORBIDDEN_CHARS.includes(c))
  .join('')

const getGeneratedPassword = () =>
  _.range(0, 8)
    .map(() => _.sample(ALLOWED_CHARS))
    .join('')

export const PasswordGenerator: React.FC = () => {
  const classes = useStyles()
  const {t} = useTranslation()
  const {addInfoNotification} = useNotifications()
  const [generatedPassword, setGeneratedPassword] = useState('')

  const generatePassword = useCallback(
    () => setGeneratedPassword(getGeneratedPassword()),
    [setGeneratedPassword]
  )

  const _copyToClipboard = useCallback(() => {
    copy(generatedPassword)
    addInfoNotification(t('Copied to clipboard!'))
  }, [addInfoNotification, generatedPassword, t])

  return (
    <Grid container direction="row" justifyContent="center">
      <Button onClick={generatePassword} className={classes.button}>
        <GeneratePasswordIcon />
        <Typography className={classes.text}>
          {generatedPassword !== '' ? generatedPassword : t('GENERATE NEW')}
        </Typography>
      </Button>
      {generatedPassword !== '' && (
        <IconButton onClick={_copyToClipboard} className={classes.copyIcon}>
          <FileCopyIcon />
        </IconButton>
      )}
    </Grid>
  )
}
