import {isEmpty, omitBy} from 'lodash'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {
  BusinessPartnerFieldsFragment,
  BusinessPartnerState,
  UpdateBusinessPartnerInput
} from '../../../__generated__/schema'
import {BusinessPartnerFormField, IBusinessPartnerForm} from './types'

export const useNavigationItems = () => {
  const {t} = useTranslation()
  return useMemo(
    (): {
      company: {id: string; label: string}
      billingAddress: {id: string; label: string}
      contact: {id: string; label: string}
      deliveryAddress: {id: string; label: string}
      notes: {id: string; label: string}
    } => ({
      company: {
        id: 'company',
        label: t('Company')
      },
      billingAddress: {
        id: 'billingAddress',
        label: t('Billing address')
      },
      contact: {
        id: 'Contact',
        label: t('Contact')
      },
      deliveryAddress: {
        id: 'deliveryAddress',
        label: t('Delivery address')
      },
      notes: {
        id: 'notes',
        label: t('Notes')
      }
    }),
    [t]
  )
}

export const transformBusinessPartnerToBusinessPartnerForm = (
  businessPartner: BusinessPartnerFieldsFragment
): Partial<IBusinessPartnerForm> => ({
  [BusinessPartnerFormField.CompanyName]: businessPartner.companyName,
  [BusinessPartnerFormField.Types]: businessPartner.types,
  [BusinessPartnerFormField.Categories]: businessPartner.categories || [],
  [BusinessPartnerFormField.CompanyIdNumber]: businessPartner.companyIdNumber,
  [BusinessPartnerFormField.TaxId]: businessPartner.taxId,
  [BusinessPartnerFormField.VatId]: businessPartner.vatId,
  [BusinessPartnerFormField.VATRegistered]:
    businessPartner.VATRegistered || undefined,
  [BusinessPartnerFormField.CustomId]: businessPartner.customId,
  [BusinessPartnerFormField.Phone]: businessPartner.phone,
  [BusinessPartnerFormField.Email]: businessPartner.email,
  [BusinessPartnerFormField.Website]: businessPartner.website,
  [BusinessPartnerFormField.Note]: businessPartner.note,
  [BusinessPartnerFormField.InternalNote]: businessPartner.internalNote,
  [BusinessPartnerFormField.BillingAddress]: businessPartner.billingAddress,
  [BusinessPartnerFormField.DeliveryAddress]: businessPartner.deliveryAddress
})

export const transformBusinessPartnerFormDataToUpdateBusinessPartnerInput = (
  formData: IBusinessPartnerForm,
  state?: BusinessPartnerState
): UpdateBusinessPartnerInput => ({
  companyName: formData[BusinessPartnerFormField.CompanyName],
  types: formData[BusinessPartnerFormField.Types],
  categories: formData[BusinessPartnerFormField.Categories] || null,
  companyIdNumber: formData[BusinessPartnerFormField.CompanyIdNumber] || null,
  taxId: formData[BusinessPartnerFormField.TaxId] || null,
  vatId: formData[BusinessPartnerFormField.VatId] || null,
  VATRegistered: formData[BusinessPartnerFormField.VATRegistered] || null,
  customId: formData[BusinessPartnerFormField.CustomId] || null,
  phone: formData[BusinessPartnerFormField.Phone] || null,
  email: formData[BusinessPartnerFormField.Email] || null,
  website: formData[BusinessPartnerFormField.Website] || null,
  note: formData[BusinessPartnerFormField.Note] || null,
  internalNote: formData[BusinessPartnerFormField.InternalNote] || null,
  billingAddress: omitBy(
    formData[BusinessPartnerFormField.BillingAddress],
    isEmpty
  ),
  deliveryAddress: omitBy(
    formData[BusinessPartnerFormField.DeliveryAddress],
    isEmpty
  ),
  state
})
