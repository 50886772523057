import {difference} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Blank} from '../../visual/Blank'

interface ISpecialCasesProps<T extends object> {
  supportedKeys: (keyof T)[]
  filter: T
}

export const SpecialCases = <T extends object>({
  supportedKeys,
  filter
}: ISpecialCasesProps<T>) => {
  const {t} = useTranslation()
  if (Object.keys(filter).length === 0) {
    return (
      <Blank
        title={t('No filter')}
        description={t('No filter has been applied yet.')}
      />
    )
  } else if (
    difference(Object.keys(filter), supportedKeys.map(String)).length > 0
  ) {
    return (
      <Blank
        title={t('Default filter applied')}
        description={t(
          'In this block, the default filter is applied. If you would like to redefine this filter, add some filter criteria, or keep it unchanged if you are uncertain about this step.'
        )}
      />
    )
  }
  return null
}
