import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  SxProps,
  Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useMemo} from 'react'
import {
  FieldErrors,
  FieldValues,
  FormContextValues,
  ValidationOptions
} from 'react-hook-form'
import {Theme} from '../../theme'
import {FormValidationError} from '../common'
import {FormFieldHelper} from '../visual/common'
import {FormFieldName} from './types'
import {useCustomReactHookFormRegistration, useHasError} from './utils'

const useStyles = makeStyles<Theme>(() => ({
  formFieldHelper: {
    marginLeft: 0
  }
}))

interface IControlledFormCheckboxGroup<
  FormValues extends FieldValues = FieldValues,
  T extends string | number = number
> {
  errors: FieldErrors<FormValues>
  setValue: FormContextValues<FormValues>['setValue']
  watch: FormContextValues<FormValues>['watch']
  register: FormContextValues<FormValues>['register']
  unregister: FormContextValues<FormValues>['unregister']
  name: FormFieldName<FormValues>
  validationOptions?: ValidationOptions
  required?: boolean
  parseCheckboxValue: (v: string) => T
  options: {
    name: string
    description?: string
    id: T
  }[]
  helperNote?: string
  label?: string
  fullWidth?: boolean
  checkboxSx?: SxProps<Theme>
  sx?: SxProps<Theme>
}

export const ControlledFormCheckboxGroup = <
  FormValues extends FieldValues = FieldValues,
  T extends string | number = string
>({
  options,
  setValue,
  watch,
  register,
  unregister,
  name,
  errors,
  validationOptions,
  required,
  helperNote,
  parseCheckboxValue,
  label,
  fullWidth,
  sx,
  checkboxSx
}: IControlledFormCheckboxGroup<FormValues, T>) => {
  const classes = useStyles()
  const value: T[] = useMemo(() => watch(name) || [], [name, watch])
  const hasError = useHasError<FormValues>(errors, name)
  const handleChange = useCallback(
    (e) => {
      const currentTargetValue = parseCheckboxValue(e.target.value)
      setValue(
        name,
        (e.target.checked
          ? [...value, currentTargetValue]
          : value.filter(
              (item) => item !== currentTargetValue
            )) as FormFieldName extends keyof FormValues
          ? FormValues[keyof FormValues & FormFieldName]
          : unknown,
        hasError
      )
    },
    [hasError, name, parseCheckboxValue, setValue, value]
  )
  useCustomReactHookFormRegistration<FormValues>({
    register,
    unregister,
    validationOptions,
    name
  })
  return (
    <FormControl error={hasError} fullWidth={fullWidth}>
      {label && <FormLabel>{label}</FormLabel>}
      {!hasError && (
        <FormFieldHelper
          required={required}
          note={helperNote}
          className={classes.formFieldHelper}
        />
      )}
      <FormValidationError<FormValues> errors={errors} fieldName={name} />
      <FormGroup sx={sx}>
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            sx={checkboxSx}
            control={
              <Checkbox
                onChange={handleChange}
                value={option.id}
                checked={value.includes(option.id)}
                color="primary"
              />
            }
            label={
              <div>
                <Typography variant="body1" component="div">
                  {option.name}
                </Typography>
                {option.description && (
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    {option.description}
                  </Typography>
                )}
              </div>
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
