import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateRoleMutation,
  CreateRoleMutationVariables,
  DeleteRoleMutation,
  DeleteRoleMutationVariables,
  RoleInput,
  RoleQuery,
  RoleQueryVariables,
  RolesQuery,
  RolesQueryVariables,
  RoleType,
  UpdateRoleMutation,
  UpdateRoleMutationVariables
} from '../../../../__generated__/schema'
import {TRANSLATED_LOCALES_FRAGMENT} from '../graphql'
import {GET_AVAILABLE_ROLES} from '../users/graphql'

const BASIC_ROLE_FIELDS = gql`
  ${TRANSLATED_LOCALES_FRAGMENT}
  fragment BasicRoleFields on Role {
    id
    type
    code
    clientId
    names {
      ...TranslatedLocales
    }
    descriptions {
      ...TranslatedLocales
    }
    createdAt
    updatedAt
    updatedByName
    permissionCodes
    users {
      id
    }
    isDeprecated
    client {
      name
    }
  }
`

const GET_ROLES = gql`
  ${BASIC_ROLE_FIELDS}
  query Roles {
    roles {
      ...BasicRoleFields
    }
  }
`

const GET_ROLE = gql`
  ${BASIC_ROLE_FIELDS}
  query Role($id: Int, $code: String) {
    role(id: $id, code: $code) {
      ...BasicRoleFields
    }
  }
`

const CREATE_ROLE = gql`
  ${BASIC_ROLE_FIELDS}
  mutation CreateRole($type: RoleType, $data: RoleInput!) {
    createRole(type: $type, data: $data) {
      ...BasicRoleFields
    }
  }
`

const UPDATE_ROLE = gql`
  ${BASIC_ROLE_FIELDS}
  mutation UpdateRole($id: Int!, $data: RoleInput!, $isDeprecated: Boolean) {
    updateRole(id: $id, data: $data, isDeprecated: $isDeprecated) {
      ...BasicRoleFields
    }
  }
`

const DELETE_ROLE = gql`
  ${BASIC_ROLE_FIELDS}
  mutation DeleteRole($id: Int!) {
    deleteRole(id: $id) {
      ...BasicRoleFields
    }
  }
`

export const useGetRoles = () =>
  useQuery<RolesQuery, RolesQueryVariables>(GET_ROLES, {
    fetchPolicy: 'network-only'
  })

export const useGetRole = (id: number, code?: string) =>
  useQuery<RoleQuery, RoleQueryVariables>(GET_ROLE, {
    variables: {
      id,
      code
    },
    fetchPolicy: 'network-only'
  })

export const useCreateRole = () => {
  const [createRole] = useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(CREATE_ROLE, {
    refetchQueries: () => {
      return [{query: GET_AVAILABLE_ROLES}]
    }
  })
  return useCallback(
    (data: RoleInput, type?: RoleType) => createRole({variables: {data, type}}),
    [createRole]
  )
}

export const useUpdateRole = () => {
  const [updateRole] = useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(UPDATE_ROLE, {
    refetchQueries: () => {
      return [{query: GET_AVAILABLE_ROLES}]
    }
  })
  return useCallback(
    (id: number, data: RoleInput, isDeprecated?: boolean) =>
      updateRole({variables: {id, data, isDeprecated}}),
    [updateRole]
  )
}

export const useDeleteRole = () => {
  const [deleteRole] = useMutation<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >(DELETE_ROLE, {
    refetchQueries: () => {
      return [{query: GET_AVAILABLE_ROLES}]
    }
  })
  return useCallback(
    (id: number) => deleteRole({variables: {id}}),
    [deleteRole]
  )
}
