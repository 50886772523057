import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {useIsStringWithMaxLength} from '../../../../utils/formsValidations'
import {InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {
  IMarketingLabelForm,
  MarketingLabelFormField,
  MarketingLabelState
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'grid',
    gridAutoFlow: 'row',
    padding: theme.spacing(3)
  }
}))

interface IMarketingLabelFormProps {
  formId: string
  onSubmit: (data: IMarketingLabelForm) => void
  defaultValues?: Partial<IMarketingLabelForm>
  className?: string
}

export const MarketingLabelForm: React.FC<IMarketingLabelFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  className
}: IMarketingLabelFormProps) => {
  const {t} = useTranslation()
  const {handleSubmit, errors, setValue, watch, register, triggerValidation} =
    useForm<IMarketingLabelForm>({defaultValues})
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const classes = useStyles()
  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={cn(classes.form, className)}
    >
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMarketingLabelForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Name')}
            name={MarketingLabelFormField.Name}
            key={MarketingLabelFormField.Name}
            validationOptions={{
              required: true,
              validate: isStringWithMaxLength
            }}
            required
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormTextInput<IMarketingLabelForm>
            errors={errors}
            setValue={setValue}
            watch={watch}
            register={register}
            triggerValidation={triggerValidation}
            label={t('Label')}
            helperNote={t('Ideal for custom internal code')}
            name={MarketingLabelFormField.Label}
            key={MarketingLabelFormField.Label}
            validationOptions={{validate: isStringWithMaxLength}}
            fullWidth
          />
        ]}
      />
      <InputRow
        nodes={[
          <UncontrolledFormSelect<IMarketingLabelForm>
            selectOptions={Object.values(MarketingLabelState).reduce(
              (acc, state) => ({
                ...acc,
                [state]:
                  state === MarketingLabelState.Active
                    ? t('Active')
                    : t('Inactive')
              }),
              {}
            )}
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            label={t('State')}
            name={MarketingLabelFormField.IsActive}
            key={MarketingLabelFormField.IsActive}
            validationOptions={{
              required: true
            }}
            required
            fullWidth
          />
        ]}
      />
    </form>
  )
}
