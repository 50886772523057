import {Box, Divider, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DetailEventPropertiesFragment} from '../../../../../../../__generated__/schema'
import {Theme} from '../../../../../../../theme'

import {HighlightedInfoLabel, InfoLabel} from './common'

interface IAddressInfoProps {
  data: DetailEventPropertiesFragment
}

const useStyles = makeStyles<Theme>((theme) => ({
  leftPanelContentWrapper: {
    paddingRight: theme.spacing(2),
    height: '100%'
  }
}))

const LeftSideInfo: React.FC<IAddressInfoProps> = ({
  data
}: IAddressInfoProps) => {
  const {t} = useTranslation()
  return (
    <Grid container direction="column">
      <InfoLabel>{t('Venue')}</InfoLabel>
      <HighlightedInfoLabel>{data.venue.name}</HighlightedInfoLabel>
      <InfoLabel>{data.venue.address.complex}</InfoLabel>
      <InfoLabel>{data.venue.address.street}</InfoLabel>
      <Grid container>
        <InfoLabel>
          {data.venue.address.postalCode} {data.venue.address.town}
        </InfoLabel>
      </Grid>
      <InfoLabel>{data.venue.address.country}</InfoLabel>
    </Grid>
  )
}

const RightSideInfo: React.FC<IAddressInfoProps> = ({
  data
}: IAddressInfoProps) => {
  const {t} = useTranslation()
  return (
    <Grid container direction="column">
      <InfoLabel>{t('Auditorium')}</InfoLabel>
      <HighlightedInfoLabel>{data.auditorium.name}</HighlightedInfoLabel>
      <Box marginTop={1} />
      <InfoLabel>{t('Auditorium layout')}</InfoLabel>
      <InfoLabel>{data.auditoriumLayout.name}</InfoLabel>
      <Box marginTop={1} />
      <InfoLabel>{t('Layout pricing')}</InfoLabel>
      <InfoLabel>{data.auditoriumLayoutPricing.name}</InfoLabel>
    </Grid>
  )
}

export const AddressInfo: React.FC<IAddressInfoProps> = ({
  data
}: IAddressInfoProps) => {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <Box>
      <Typography variant="caption">{t('Unable to change values')}</Typography>
      <Box marginTop={1} />
      <Grid container>
        <Grid item xs={6}>
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            className={classes.leftPanelContentWrapper}
          >
            <LeftSideInfo data={data} />
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <RightSideInfo data={data} />
        </Grid>
      </Grid>
    </Box>
  )
}
