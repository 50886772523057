import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'

const useStyles = makeStyles<Theme>((theme) => ({
  accordionSummaryRoot: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  accordionDetailsRoot: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    flexDirection: 'column'
  },
  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

export interface IImageAccordionProps {
  children: React.ReactNode
  isExpanded: boolean
  onAccordionChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void
  summaryTitle: string
  summaryDescription: string
  isDisabled?: boolean
  accordionDetailsSx?: SxProps<Theme>
}

export const ImageAccordion: React.FC<IImageAccordionProps> = ({
  children,
  isExpanded,
  summaryTitle,
  summaryDescription,
  onAccordionChange,
  isDisabled,
  accordionDetailsSx
}: IImageAccordionProps) => {
  const classes = useStyles()
  return (
    <Accordion
      variant="outlined"
      expanded={isExpanded}
      onChange={onAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummaryRoot}
        classes={{content: classes.accordionSummaryContent}}
        disabled={isDisabled}
      >
        <Typography variant="subtitle1">{summaryTitle}</Typography>
        <Typography variant="body2" color="textSecondary">
          {summaryDescription}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetailsRoot}
        sx={accordionDetailsSx}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
