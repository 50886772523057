import React from 'react'
import '../../../../../common/textEditor/textEditor.css'
import {MarkdownEditor} from '../../../../../common/textEditor/MarkdownEditor'

import {IMarkdownBlockProps} from './types'

interface IMarkdownBlockContentProps extends IMarkdownBlockProps {
  setContent: (content: string) => void
}

export const MarkdownBlockContent: React.FC<IMarkdownBlockContentProps> = ({
  content,
  setContent
}: IMarkdownBlockContentProps) => {
  return <MarkdownEditor defaultValue={content} onChange={setContent} />
}
