import {ISeat} from '@attendio/shared-components'
import {CanvasObjectType} from '../../types'

export enum SeatActionType {
  ADD_SEAT = 'add seat',
  ADD_SEATS = 'add seats'
}

export interface IAddSeatAction {
  type: typeof SeatActionType.ADD_SEAT
  payload: {
    type: typeof CanvasObjectType.Seat
    config: ISeat
  }
}

export interface IAddSeatsAction {
  type: typeof SeatActionType.ADD_SEATS
  payload: {
    type: typeof CanvasObjectType.Seat
    configs: Array<ISeat>
  }
}

export type SeatsAction = IAddSeatAction | IAddSeatsAction
