import {useTranslation} from 'react-i18next'
import {PauseAfterScan} from '../types'

export const getPauseAfterScanValue = (pauseAfterScan?: PauseAfterScan) => {
  switch (pauseAfterScan) {
    case PauseAfterScan.DoNotPause:
      return 0
    case PauseAfterScan.For250ms:
      return 250
    case PauseAfterScan.For500ms:
      return 500
    case PauseAfterScan.For750ms:
      return 750
    case PauseAfterScan.For1500ms:
      return 1500
    case PauseAfterScan.For2000ms:
      return 2000
    case PauseAfterScan.For2500ms:
      return 2500
    case PauseAfterScan.For3000ms:
      return 3000
    case PauseAfterScan.For1000ms:
    default:
      return 1000
  }
}

export const useTranslatePauseAfterScan = () => {
  const {t} = useTranslation()
  return (pauseAfterScan: PauseAfterScan) => {
    switch (pauseAfterScan) {
      case PauseAfterScan.DoNotPause:
        return t('Do not pause')
      case PauseAfterScan.For250ms:
        return t('250 ms')
      case PauseAfterScan.For500ms:
        return t('500 ms')
      case PauseAfterScan.For750ms:
        return t('750 ms')
      case PauseAfterScan.For1500ms:
        return t('1500 ms')
      case PauseAfterScan.For2000ms:
        return t('2000 ms')
      case PauseAfterScan.For2500ms:
        return t('2500 ms')
      case PauseAfterScan.For3000ms:
        return t('3000 ms')
      case PauseAfterScan.For1000ms:
      default:
        return t('1000 ms')
    }
  }
}
