import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {EventState, PermissionCode} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Tooltip} from '../../../common'
import {
  IDataPickerData,
  TabGroup,
  TabNow
} from '../../../common/datePicker/types'
import {DatePickerButton} from '../../../common/DatePickerButton'
import {SubHeaderToolbar} from '../../../common/SubHeaderToolbar'
import {PaymentDateChip} from '../components/PaymentDateChip'
import {UserChip} from '../components/UserChip'
import {EventStateChip} from './EventStateChip'
import {MoreMenu} from './MoreMenu'

const useStyles = makeStyles(() => ({
  rightActions: {
    paddingTop: 0,
    paddingBottom: 0
  }
}))

interface IToolbarProps {
  title: string
  eventStateFilter: EventState
  setEventStateFilter: (filter: EventState) => void
  setSelectedUserId: (id: number | null) => void
  selectedPaymentDate: IDataPickerData | undefined
  setSelectedPaymentDate: (date: IDataPickerData | undefined) => void
  onDateSelect: (date: IDataPickerData | undefined) => void
  selectedDate: IDataPickerData | undefined
  onRefetchButtonClick: () => void
  onEventsListCsvButtonClick: () => void
}

export const Toolbar: React.FC<IToolbarProps> = ({
  title,
  eventStateFilter,
  setEventStateFilter,
  setSelectedUserId,
  selectedPaymentDate,
  setSelectedPaymentDate,
  onDateSelect,
  selectedDate,
  onRefetchButtonClick,
  onEventsListCsvButtonClick
}: IToolbarProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const classes = useStyles()
  return (
    <SubHeaderToolbar
      title={title}
      leftActions={[
        <EventStateChip
          key="event-state-chip"
          selectedOption={eventStateFilter}
          onChange={setEventStateFilter}
        />,
        P([PermissionCode.ReadUsers]) ? (
          <UserChip key="user-chip" onChange={setSelectedUserId} />
        ) : (
          []
        ),
        <PaymentDateChip
          key="payment-date-chip"
          selectedDate={selectedPaymentDate}
          setSelectedDate={setSelectedPaymentDate}
        />
      ].filter(Boolean)}
      rightActions={[
        <DatePickerButton
          key="event-date-picker"
          onDateSelect={onDateSelect}
          selectedValues={selectedDate}
          defaultValues={{
            group: TabGroup.Now,
            value: TabNow.FromToday
          }}
          tooltip={t('Event date')}
        />,
        <Tooltip key="refetch-button" title={t('Reload')}>
          <IconButton onClick={onRefetchButtonClick}>
            <RefreshOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>,
        <MoreMenu
          key="more-menu"
          onEventsListCsvButtonClick={onEventsListCsvButtonClick}
        />
      ]}
      rightActionsClassName={classes.rightActions}
    />
  )
}
