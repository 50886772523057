import {useTranslation} from 'react-i18next'
import {ApiResource} from '../__generated__/schema'

export const useTranslateApiResource = () => {
  const {t} = useTranslation()
  const translated: Record<ApiResource, string> = {
    [ApiResource.Shows]: t<string>('ApiResource->Shows'),
    [ApiResource.Events]: t<string>('ApiResource->Events'),
    [ApiResource.Tours]: t<string>('ApiResource->Tours'),
    [ApiResource.TourTimeSlots]: t<string>('ApiResource->TourTimeSlots'),
    [ApiResource.Venues]: t<string>('ApiResource->Venues'),
    [ApiResource.CheckPassCode]: t<string>('ApiResource->CheckPassCode')
  }
  return (resource: ApiResource) => translated[resource]
}
