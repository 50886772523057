import {makeStyles} from '@mui/styles'
import _ from 'lodash'
import React, {useCallback, useRef} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {
  ErrorMessages,
  LocaleCode,
  PermissionCode,
  Timezone,
  Title,
  UserPropertiesFragment
} from '../../../../__generated__/schema'
import {useGetServerIntlSelectData} from '../../../../hooks/getServerIntlSelectData'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {Theme} from '../../../../theme'
import {useEnsurePermissions} from '../../../../utils/auth'
import {getGraphQLErrorRelatedToErrorMessage} from '../../../../utils/errors'
import {enumToFormSelectItems} from '../../../../utils/forms'
import {
  useIsStringWithMaxLength,
  useIsValidEmail,
  useIsValidPhone
} from '../../../../utils/formsValidations'
import {InputBlock, InputRow} from '../../../common'
import {UncontrolledFormTextInput} from '../../../form/FormTextInput'
import {UncontrolledFormSelect} from '../../../form/UncontrolledFormSelect'
import {RoleInputs} from './RoleInputs'

export const useUserFormAnchors = (hideRoles?: boolean) => {
  const {t} = useTranslation()
  return {
    general: {
      id: 'general',
      label: t('General information')
    },
    contact: {
      id: 'contact',
      label: t('Contact information')
    },
    ...(hideRoles
      ? {}
      : {
          roles: {
            id: 'roles',
            label: t('Roles')
          }
        })
  }
}

export enum FormField {
  USERNAME = 'username',
  PASSWORD = 'password',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  WORK_EMAIL = 'workEmail',
  PERSONAL_EMAIL = 'personalEmail',
  PHONE_NUMBER = 'phoneNumber',
  MOBIL_PHONE_NUMBER = 'mobilPhoneNumber',
  TITLE = 'title',
  DEGREE = 'degree',
  LOCALE_CODE = 'localeCode',
  TIMEZONE = 'timezone',
  ROLE_IDS = 'roleIds'
}

/*
API will never return a user's password.
We will use an empty string as default value.
*/

export const userToUserForm = ({
  ...user
}: UserPropertiesFragment): IUserFormData => ({...user, password: ''})

export const userFromUserForm = (userData: IUserFormData) => {
  const _userData = _.omit(userData, [FormField.PASSWORD, FormField.ROLE_IDS])
  return {
    ..._userData,
    localeCode: userData.localeCode as LocaleCode,
    title: _userData.title ? (_userData.title as Title) : null
  }
}

export interface IUserFormData {
  [FormField.USERNAME]: string
  [FormField.PASSWORD]: string
  [FormField.FIRST_NAME]: string
  [FormField.LAST_NAME]: string
  [FormField.WORK_EMAIL]?: string | null
  [FormField.PERSONAL_EMAIL]?: string | null
  [FormField.PHONE_NUMBER]?: string | null
  [FormField.MOBIL_PHONE_NUMBER]?: string | null
  [FormField.TITLE]?: string | null
  [FormField.DEGREE]?: string | null
  [FormField.LOCALE_CODE]: string
  [FormField.TIMEZONE]: Timezone
  [FormField.ROLE_IDS]: Array<number>
}

const useStyles = makeStyles<Theme>({
  card: {
    padding: 0
  }
})

interface IUserFormProps {
  formId: string
  onSubmit: (data: IUserFormData) => Promise<void>
  defaultValues?: IUserFormData
  showPasswordField?: boolean
  hideRoles?: boolean
}

export const UserForm: React.FC<IUserFormProps> = ({
  formId,
  onSubmit,
  defaultValues,
  showPasswordField,
  hideRoles
}: IUserFormProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const usernameDivRef = useRef<HTMLDivElement | null>(null)
  const methods = useForm<IUserFormData>({
    defaultValues
  })
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    triggerValidation
  } = methods
  const isStringWithMaxLength = useIsStringWithMaxLength(255)
  const isValidPhone = useIsValidPhone()
  const isValidEmail = useIsValidEmail()
  const {defaultErrorHandler} = useMutationAssistanceHooks()
  const userFormAnchors = useUserFormAnchors(hideRoles)
  const {localeSelectData} = useGetServerIntlSelectData()
  const classes = useStyles()
  const _onSubmit = useCallback(
    async (formData: IUserFormData) => {
      try {
        await onSubmit(formData)
      } catch (error) {
        if (
          getGraphQLErrorRelatedToErrorMessage(
            error,
            ErrorMessages.UsernameAlreadyTaken
          )
        ) {
          setError(FormField.USERNAME, t('Username already exists'))
          usernameDivRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        } else {
          defaultErrorHandler(error, t('Error while creating/updating user'))
        }
      }
    },
    [defaultErrorHandler, onSubmit, setError, t]
  )
  return (
    <form onSubmit={handleSubmit(_onSubmit)} id={formId}>
      <InputBlock
        header={t('General information')}
        id={userFormAnchors.general.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.DEGREE}
              label={t('Degree')}
              name={FormField.DEGREE}
              validationOptions={{validate: isStringWithMaxLength}}
              fullWidth
            />,
            <UncontrolledFormSelect<IUserFormData>
              key={FormField.TITLE}
              label={t('Title')}
              validationOptions={{
                required: true
              }}
              required
              name={FormField.TITLE}
              selectOptions={{
                [Title.Mrs]: t('Mrs'),
                [Title.Mr]: t('Mr')
              }}
              fullWidth
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          ]}
        />
        <InputRow
          nodes={[
            <div ref={usernameDivRef} key={FormField.USERNAME}>
              <UncontrolledFormTextInput<IUserFormData>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                label={t('Username')}
                name={FormField.USERNAME}
                validationOptions={{
                  required: true,
                  validate: isStringWithMaxLength
                }}
                required
                fullWidth
              />
            </div>,
            showPasswordField && (
              <UncontrolledFormTextInput<IUserFormData>
                errors={errors}
                setValue={setValue}
                watch={watch}
                register={register}
                triggerValidation={triggerValidation}
                label={t('Password')}
                key={FormField.PASSWORD}
                name={FormField.PASSWORD}
                type="password"
                autoComplete="new-password"
                validationOptions={{required: true}}
                required
                fullWidth
              />
            )
          ]}
          xs={6}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.FIRST_NAME}
              label={t('First name')}
              name={FormField.FIRST_NAME}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
              required
              fullWidth
            />,
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.LAST_NAME}
              label={t('Last name')}
              name={FormField.LAST_NAME}
              validationOptions={{
                required: true,
                validate: isStringWithMaxLength
              }}
              required
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormSelect<IUserFormData>
              key={FormField.TIMEZONE}
              label={t('Timezone')}
              name={FormField.TIMEZONE}
              validationOptions={{
                required: true
              }}
              required
              selectOptions={enumToFormSelectItems(Timezone)}
              fullWidth
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />,
            <UncontrolledFormSelect<IUserFormData>
              key={FormField.LOCALE_CODE}
              label={t('Locale')}
              name={FormField.LOCALE_CODE}
              validationOptions={{
                required: true
              }}
              required
              selectOptions={localeSelectData}
              fullWidth
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          ]}
        />
      </InputBlock>
      <InputBlock
        header={t('Contact information')}
        id={userFormAnchors.contact.id}
      >
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.WORK_EMAIL}
              label={t('Work email')}
              name={FormField.WORK_EMAIL}
              validationOptions={{validate: isValidEmail}}
              fullWidth
            />,
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.PERSONAL_EMAIL}
              label={t('Personal email')}
              name={FormField.PERSONAL_EMAIL}
              validationOptions={{validate: isValidEmail}}
              fullWidth
            />
          ]}
        />
        <InputRow
          nodes={[
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.PHONE_NUMBER}
              label={t('Phone number')}
              name={FormField.PHONE_NUMBER}
              validationOptions={{validate: isValidPhone}}
              fullWidth
            />,
            <UncontrolledFormTextInput<IUserFormData>
              errors={errors}
              setValue={setValue}
              watch={watch}
              register={register}
              triggerValidation={triggerValidation}
              key={FormField.MOBIL_PHONE_NUMBER}
              label={t('Mobile phone number')}
              name={FormField.MOBIL_PHONE_NUMBER}
              validationOptions={{validate: isValidPhone}}
              fullWidth
            />
          ]}
        />
      </InputBlock>
      {P([PermissionCode.ReadAvailableRoles]) && !hideRoles && (
        <InputBlock
          sidesPadding={false}
          header={t('Roles')}
          cardClassName={classes.card}
          id={userFormAnchors.roles!.id}
        >
          <FormContext {...methods}>
            <RoleInputs
              roleIds={defaultValues ? defaultValues.roleIds : []}
              name={FormField.ROLE_IDS}
            />
          </FormContext>
        </InputBlock>
      )}
    </form>
  )
}
