import {Dayjs} from 'dayjs'
import {EventExpirationType} from '../../../../../../__generated__/schema'

export enum FormField {
  SALE_ACTIVE = 'saleActive',
  SALE_START = 'saleStart',
  SALE_END = 'saleEnd',
  RESERVATION_ACTIVE = 'reservationActive',
  RESERVATION_START = 'reservationStart',
  RESERVATION_END = 'reservationEnd',
  RESERVATION_EXPIRATION_TYPE = 'reservationExpirationType',
  RESERVATION_EXPIRATION_EXPIRES_AT = 'reservationExpirationExpiresAt',
  RESERVATION_EXPIRATION_LAST_FOR = 'reservationExpirationLastFor',
  MAX_NUMBER_OF_OCCUPIED_SEATS = 'maxNumberOfOccupiedSeats'
}

export enum SectionPrefix {
  POINT_OF_SALE = 'pointOfSale',
  ONLINE = 'online'
}

export interface IReservationLastFor {
  days: string
  hours: string
  minutes: string
}

interface ISalesSubData {
  [FormField.SALE_ACTIVE]: boolean
  [FormField.SALE_START]: Dayjs
  [FormField.SALE_END]: Dayjs
  [FormField.RESERVATION_ACTIVE]: boolean
  [FormField.RESERVATION_START]: Dayjs
  [FormField.RESERVATION_END]: Dayjs
  [FormField.RESERVATION_EXPIRATION_TYPE]: EventExpirationType
  [FormField.RESERVATION_EXPIRATION_EXPIRES_AT]: Dayjs
  [FormField.RESERVATION_EXPIRATION_LAST_FOR]: IReservationLastFor
  [FormField.MAX_NUMBER_OF_OCCUPIED_SEATS]: number
}

export interface ISalesFormData {
  [SectionPrefix.POINT_OF_SALE]: ISalesSubData
  [SectionPrefix.ONLINE]: ISalesSubData
}
