import {FormHelperText} from '@mui/material'
import React from 'react'
import {FieldError, ValidationOptions} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

interface IFormSubTextProps {
  error?: FieldError
  validationOptions?: ValidationOptions
  helperText?: string
  className?: string
}

export const FormSubText: React.FC<IFormSubTextProps> = ({
  error,
  validationOptions,
  helperText,
  className
}: IFormSubTextProps) => {
  const {t} = useTranslation()
  return (
    <FormHelperText className={className}>
      {error
        ? error.type === 'required'
          ? error.message || t('This field is required!')
          : error.message
        : (validationOptions?.required || helperText) &&
          (validationOptions?.required
            ? helperText
              ? t('*Required. {{helperText}}', {helperText})
              : t('*Required')
            : helperText)}
    </FormHelperText>
  )
}
