import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  PermissionCode,
  WarehouseDocumentsFilterInput
} from '../../../../__generated__/schema'
import {useBooleanState} from '../../../../hooks/state'
import {useEnsurePermissions} from '../../../../utils/auth'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {PageWithHeaderTemplate} from '../../../common/PageWithHeaderTemplate'
import {ChildrenOnEffectiveClientSelected} from '../ChildrenOnEffectiveClientSelected'
import {PrimaryHeader} from '../Header'
import {CreateWarehouseDocumentDrawer} from './CreateWarehouseDocumentDrawer'
import {WarehouseDocumentsList} from './WarehouseDocumentsList'
import {
  DEFAULT_WAREHOUSE_DOCUMENTS_FILTER_INPUT,
  WarehouseDocumentsSearch
} from './WarehouseDocumentsSearch'

export const WarehouseDocumentsPage: React.FC = () => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const [searchFilter, setSearchFilter] =
    useState<WarehouseDocumentsFilterInput>(
      DEFAULT_WAREHOUSE_DOCUMENTS_FILTER_INPUT
    )
  const {
    state: isCreateWarehouseDocumentDrawerOpen,
    setTrue: openCreateWarehouseDocumentDrawer,
    setFalse: closeCreateWarehouseDocumentDrawer
  } = useBooleanState(false)
  const fabClasses = useFabClasses()
  return (
    <PageWithHeaderTemplate
      header={
        <PrimaryHeader
          title={t('Warehouse documents')}
          search={<WarehouseDocumentsSearch onFilterChange={setSearchFilter} />}
        />
      }
    >
      <ChildrenOnEffectiveClientSelected>
        <WarehouseDocumentsList searchFilter={searchFilter} />
        {P([
          PermissionCode.CreateWarehouseDocument,
          PermissionCode.ReadBusinessPartners,
          PermissionCode.ReadWarehouses
        ]) && (
          <CreateFab
            classes={fabClasses}
            onClick={openCreateWarehouseDocumentDrawer}
          />
        )}
        <CreateWarehouseDocumentDrawer
          isOpen={isCreateWarehouseDocumentDrawerOpen}
          onClose={closeCreateWarehouseDocumentDrawer}
        />
      </ChildrenOnEffectiveClientSelected>
    </PageWithHeaderTemplate>
  )
}
