import {
  AccessZoneInput,
  AccessZoneQuery,
  AccessZoneState,
  UpdateAccessZoneInput
} from '../../../../__generated__/schema'
import {AccessZoneFormField, IAccessZoneForm} from './types'

export const transformFormDataToAccessZoneInput = (
  formData: IAccessZoneForm
): AccessZoneInput => ({
  name: formData[AccessZoneFormField.Name],
  description: formData[AccessZoneFormField.Description] || undefined
})

export const transformUpdateAccessZoneQueryDataToFormData = (
  data: AccessZoneQuery['accessZone']
): Partial<IAccessZoneForm> => ({
  [AccessZoneFormField.Name]: data.name,
  [AccessZoneFormField.Description]: data.description
})

export const transformFormDataToUpdateAccessZoneInput = (
  formData: IAccessZoneForm,
  state?: AccessZoneState
): UpdateAccessZoneInput => ({
  name: formData[AccessZoneFormField.Name],
  description: formData[AccessZoneFormField.Description] || null,
  state
})
