import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  DetailClientShowPropertiesFragment,
  MixedShowImagesQuery,
  ShowImageType
} from '../../../../../__generated__/schema'
import {routeTo} from '../../../../../utils/routes'
import {useGetShowTitle} from '../../../../../utils/translations'
import {RenderOnData} from '../../../../common'
import {ShowSection} from '../../../../constants'
import {CenteredLayout} from '../../Layout'
import {
  isClientShowImagePropertiesFragment,
  isLibraryShowImagePropertiesFragment
} from '../../types'
import {useGetMixedShowImages} from '../graphql'
import {ClientShowUploadContent} from '../UploadContent'
import {EditClientShowPageDetailTemplate} from './EditClientShowPageDetailTemplate'

interface IEditClientShowMediaProps {
  clientShow: DetailClientShowPropertiesFragment
}

export const EditClientShowMedia: React.FC<IEditClientShowMediaProps> = ({
  clientShow
}: IEditClientShowMediaProps) => {
  const history = useHistory()
  const {t} = useTranslation()
  const handleClose = useCallback(
    () => history.replace(routeTo.admin.shows.home()),
    [history]
  )
  const {data, loading, error, refetch} = useGetMixedShowImages(clientShow.id, {
    type: [
      ShowImageType.Poster,
      ShowImageType.Cover,
      ShowImageType.Photo,
      ShowImageType.Banner,
      ShowImageType.Backdrop
    ]
  })

  const getShowTitle = useGetShowTitle()
  const showTitle = getShowTitle({
    translations: clientShow.translations,
    originalTitle: clientShow.originalTitle,
    fallbackTitle: t('Show')
  })
  return (
    <RenderOnData
      loading={loading}
      error={error}
      data={data}
      dataCondition={(data: MixedShowImagesQuery) =>
        Boolean(data.mixedShowImages)
      }
      errorMessage={t<string>('Error while loading show images')}
    >
      {({mixedShowImages}) => (
        <EditClientShowPageDetailTemplate
          showName={showTitle!}
          onClose={handleClose}
          currentTab={ShowSection.Media}
          hideFooter
        >
          <CenteredLayout>
            <ClientShowUploadContent
              clientShowImages={
                mixedShowImages?.filter(isClientShowImagePropertiesFragment) ||
                []
              }
              libraryShowImages={
                mixedShowImages?.filter(isLibraryShowImagePropertiesFragment) ||
                []
              }
              showTitle={showTitle}
              refetch={refetch}
              showId={clientShow.id}
              videos={clientShow.videos}
            />
          </CenteredLayout>
        </EditClientShowPageDetailTemplate>
      )}
    </RenderOnData>
  )
}
