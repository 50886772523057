import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material'
import Popper from '@mui/material/Popper'
import {Theme} from '@mui/material/styles'
import {SvgIconProps} from '@mui/material/SvgIcon'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import {Dayjs} from 'dayjs'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDateTimeFormatters} from '../../utils/formatting'
import {MediaSizes} from '../constants'
import {DatePicker} from './datePicker/DatePicker'
import {
  useTranslateDatePickerFutureValues,
  useTranslateDatePickerNowValues,
  useTranslateDatePickerPastValues
} from './datePicker/translations'
import {
  AvailableDatePickerValues,
  IDataPickerData,
  TabFuture,
  TabGroup,
  TabNow,
  TabPast
} from './datePicker/types'
import {useTransformDatePickerValueToDate} from './datePicker/utils'
import {Tooltip} from './Tooltip'

const useGetSelectedDateLabel = (defaultLabel?: string) => {
  const {t} = useTranslation()
  const transformDatePickerValueToDate = useTransformDatePickerValueToDate()
  const {formatDateNumeric} = useDateTimeFormatters()
  return (selectedDate: IDataPickerData | undefined, isPhablet: boolean) => {
    const transformedDateRange = transformDatePickerValueToDate(
      selectedDate?.value
    )
    if (
      selectedDate &&
      Object.values(selectedDate).some((v) => v !== undefined)
    ) {
      if (selectedDate?.group === TabGroup.Custom) {
        return [
          selectedDate?.dateRange?.dateFrom &&
            formatDateNumeric(selectedDate.dateRange.dateFrom.toDate()),
          selectedDate?.dateRange?.dateTo &&
            formatDateNumeric(selectedDate.dateRange.dateTo.toDate())
        ]
          .filter(Boolean)
          .join(isPhablet ? '\r\n' : ' - ')
      } else {
        return [
          transformedDateRange?.dateFrom &&
            formatDateNumeric(transformedDateRange.dateFrom.toDate()),
          transformedDateRange?.dateTo &&
            formatDateNumeric(transformedDateRange.dateTo.toDate())
        ]
          .filter(Boolean)
          .join(isPhablet ? '\r\n' : ' - ')
      }
    }
    return defaultLabel || t('Event starts at')
  }
}

const useGetSelectedDateSubLabel = () => {
  const {t} = useTranslation()
  const translateDatePickerPastValues = useTranslateDatePickerPastValues()
  const translateDatePickerNowValues = useTranslateDatePickerNowValues()
  const translateDatePickerFutureValues = useTranslateDatePickerFutureValues()
  return (selectedDate: IDataPickerData | undefined) => {
    switch (selectedDate?.group) {
      case TabGroup.Past:
        return (
          selectedDate?.value &&
          translateDatePickerPastValues(selectedDate.value as TabPast)
        )
      case TabGroup.Now:
        return (
          selectedDate?.value &&
          translateDatePickerNowValues(selectedDate.value as TabNow)
        )
      case TabGroup.Future:
        return (
          selectedDate?.value &&
          translateDatePickerFutureValues(selectedDate.value as TabFuture)
        )
      case TabGroup.Custom:
        if (selectedDate.dateRange?.dateFrom && selectedDate.dateRange.dateTo) {
          return t('Custom range')
        } else {
          return selectedDate.dateRange?.dateFrom
            ? t('From date')
            : t('Until date')
        }
      default:
        return t('Set date range')
    }
  }
}

const useStyles = makeStyles<
  Theme,
  {isDisabled: boolean; isFocused: boolean; isPhablet: boolean}
>((theme) => ({
  root: {
    width: ({isPhablet}) => (isPhablet ? 128 : 244),
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    padding: ({isPhablet}) =>
      isPhablet ? theme.spacing(1) : theme.spacing(1, 2),
    cursor: ({isDisabled}) => (isDisabled ? 'auto' : 'pointer'),
    background: ({isFocused}) =>
      isFocused ? theme.palette.action.focus : 'none',
    '&:hover': {
      background: ({isDisabled, isFocused}) =>
        isDisabled
          ? 'none'
          : isFocused
          ? theme.palette.action.focus
          : theme.palette.action.hover,
      cursor: ({isDisabled}) => (isDisabled ? 'auto' : 'pointer')
    }
  },
  leftSide: {
    display: 'flex',
    flexFlow: 'column'
  },
  icon: {
    color: ({isDisabled}) =>
      isDisabled ? theme.palette.action.disabled : theme.palette.text.primary,
    fontSize: 24
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  iconButton: {
    width: 40,
    height: 40
  }
}))

interface IIconProps {
  ExpandIcon: React.FC<SvgIconProps>
  ShrinkIcon: React.FC<SvgIconProps>
}

interface IDatePickerButtonProps {
  onDateSelect: (date: IDataPickerData | undefined) => void
  defaultValues?: IDataPickerData
  selectedValues?: IDataPickerData
  isDisabled?: boolean
  hideIcon?: boolean
  tooltip?: string
  className?: string
  IconProps?: IIconProps
  groups?: Exclude<TabGroup, TabGroup.Custom>[]
  excludedValues?: AvailableDatePickerValues[]
  minDateTo?: Dayjs
  maxDateTo?: Dayjs
  minDateFrom?: Dayjs
  maxDateFrom?: Dayjs
  label?: string
}

export const DatePickerButton: React.FC<IDatePickerButtonProps> = ({
  onDateSelect,
  defaultValues,
  selectedValues,
  isDisabled = false,
  hideIcon = false,
  tooltip = '',
  IconProps = {
    ShrinkIcon: ArrowDropUpIcon,
    ExpandIcon: ArrowDropDownIcon
  },
  className,
  groups = [TabGroup.Future, TabGroup.Now, TabGroup.Past],
  excludedValues,
  minDateFrom,
  maxDateFrom,
  minDateTo,
  maxDateTo,
  label
}: IDatePickerButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedDate, setSelectedDate] = useState<IDataPickerData | undefined>(
    selectedValues || undefined
  )
  const getSelectedDateLabel = useGetSelectedDateLabel(label)
  const getSelectedDateSubLabel = useGetSelectedDateSubLabel()
  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      setAnchorEl(anchorEl ? null : event.currentTarget),
    [anchorEl]
  )
  const handleDatePickerApplyButtonClick = useCallback(
    (date?: IDataPickerData) => {
      setSelectedDate(date)
      onDateSelect(date)
    },
    [onDateSelect]
  )
  const handleClickAway = useCallback(() => setAnchorEl(null), [])
  const isPhablet = useMediaQuery(MediaSizes.Phablet)
  const classes = useStyles({
    isDisabled,
    isFocused: Boolean(anchorEl),
    isPhablet
  })
  return (
    <>
      <Tooltip title={tooltip}>
        <div
          className={cn(classes.root, className)}
          onClick={isDisabled ? undefined : handleButtonClick}
        >
          <div className={classes.leftSide}>
            <Typography
              variant={isPhablet ? 'caption' : 'subtitle2'}
              className={isDisabled ? classes.disabled : undefined}
            >
              {getSelectedDateLabel(selectedDate, isPhablet)}
            </Typography>
            {!isPhablet && (
              <Typography
                variant="overline"
                color="textSecondary"
                className={isDisabled ? classes.disabled : undefined}
              >
                {getSelectedDateSubLabel(selectedDate)}
              </Typography>
            )}
          </div>
          {!hideIcon && (
            <IconButton className={classes.iconButton} disabled={isDisabled}>
              {anchorEl ? (
                <IconProps.ShrinkIcon className={classes.icon} />
              ) : (
                <IconProps.ExpandIcon className={classes.icon} />
              )}
            </IconButton>
          )}
        </div>
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={(theme) => ({zIndex: theme.zIndex.drawer - 1})}
      >
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={8}>
                <DatePicker
                  onApplyButtonClick={handleDatePickerApplyButtonClick}
                  groups={groups}
                  onCloseButtonClick={handleClickAway}
                  selectedValues={selectedDate}
                  defaultValues={defaultValues}
                  excludedValues={excludedValues}
                  minDateFrom={minDateFrom}
                  maxDateFrom={maxDateFrom}
                  minDateTo={minDateTo}
                  maxDateTo={maxDateTo}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}
