import React, {
  ReactNode,
  useContext,
  useMemo,
  useState,
  createContext
} from 'react'

interface IEventCalendarDatePropertiesContext {
  selectedDate: Date
  setSelectedDate: (d: Date) => void
}

const Context = createContext({} as IEventCalendarDatePropertiesContext)

type Props = {
  children: ReactNode
}

export const EventCalendarDatePropertiesProvider: React.FC<Props> = ({
  children
}: Props) => {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const context = useMemo(
    () => ({
      selectedDate,
      setSelectedDate
    }),
    [selectedDate]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useEventCalendarDateProperties = () => useContext(Context)
