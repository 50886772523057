import ZoomInIcon from '@mui/icons-material/ZoomIn'
import {IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {MouseEvent} from 'react'
import {PhotoProps} from 'react-photo-gallery'
import {Theme} from '../../theme'

export const useBaseGalleryImageButtonStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'absolute',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)'
    }
  },
  bottomRight: {
    bottom: theme.spacing(0.5),
    right: theme.spacing(0.5)
  },
  topLeft: {
    top: theme.spacing(0.5),
    left: theme.spacing(0.5)
  },
  topRight: {
    top: theme.spacing(0.5),
    right: theme.spacing(0.5)
  }
}))

const useStyles = makeStyles<
  Theme,
  {
    margin?: number
    height: number
    width: number
  }
>((theme) => ({
  root: {
    margin: ({margin}) => margin,
    height: ({height}) => height,
    width: ({width}) => width,
    position: 'relative',
    cursor: 'pointer',
    transition: 'box-shadow .3s',
    '&:hover': {
      boxShadow: '0 0 16px rgba(33,33,33,.4)'
    }
  },
  image: {
    height: ({height}) => height,
    width: ({width}) => width
  },
  zoomInButton: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(0.5)
  }
}))

export interface IGalleryImageProps {
  photo: PhotoProps
  openPreview: (e: MouseEvent) => void
  margin: number
}

export interface IBaseGalleryImageProps extends IGalleryImageProps {
  children?: React.ReactNode
  imageClassName?: string
}

export const BaseGalleryImage: React.FC<IBaseGalleryImageProps> = ({
  photo,
  openPreview,
  children,
  margin,
  imageClassName
}: IBaseGalleryImageProps) => {
  const classes = useStyles({
    width: photo.width,
    height: photo.height,
    margin
  })
  const buttonClasses = useBaseGalleryImageButtonStyles()
  return (
    <div onClick={openPreview} className={classes.root}>
      <img
        src={photo.src}
        alt={photo.alt}
        className={cn(classes.image, imageClassName)}
      />
      <IconButton
        size="small"
        onClick={openPreview}
        className={cn(buttonClasses.button, buttonClasses.bottomRight)}
      >
        <ZoomInIcon />
      </IconButton>
      {children}
    </div>
  )
}
