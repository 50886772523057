import {Divider, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {Theme} from '../../theme'
import {useSelector} from '../redux'
import {selectedObjectsSelector} from '../redux/objects/selectors'
import {ObjectsStateValue} from '../redux/objects/types'
import {AttendeeNavigation} from './AttendeeNavigation'
import {CapacityTextField} from './CapacityTextField'
import {DimensionTextFields} from './DimensionTextFields'
import {FontFieldsToggleButtonGroups} from './FontFieldsToggleButtonGroups'
import {useGetObjectsPanelTitle} from './getObjectsPanelTitle'
import {IconSelect} from './IconSelect'
import {IconShapeToggleButtonGroup} from './IconShapeToggleButtonGroup'
import {LabelTextField} from './LabelTextField'
import {RotationTextField} from './RotationTextField'
import {SeatShapeToggleButtonGroup} from './SeatShapeToggleButtonGroup'
import {ObjectsPanelType} from './types'
import {
  getObjectsPanelType,
  isIconStateValue,
  isSeatOrZoneStateObject,
  isSeatStateObject,
  isSeatZoneOrTextStateObject,
  isTextStateValue,
  isZoneIconOrShapeStateObject,
  isZoneStateValue
} from './utils'
import {ZoneTypeSelect} from './ZoneTypeSelect'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: '100%'
  },
  gridLayout: {
    display: 'grid',
    padding: theme.spacing(3),
    gap: theme.spacing(3, 0)
  }
}))

const useGetKey = (objects: ObjectsStateValue[]) =>
  useCallback(
    (prefix: string) =>
      `${prefix}-${objects.map((o) => o.config.id).join('-')}`,
    [objects]
  )

export const ObjectsPanel: React.FC = () => {
  const classes = useStyles()
  const selectedObjects = useSelector(selectedObjectsSelector)
  const objectsPanelType = getObjectsPanelType(selectedObjects)
  const getObjectsPanelTitle = useGetObjectsPanelTitle()
  const getKey = useGetKey(selectedObjects)
  return (
    <div className={classes.root}>
      <div className={cn(classes.gridLayout)}>
        <Typography variant="subtitle2">
          {getObjectsPanelTitle(objectsPanelType)}
        </Typography>
        {[
          ObjectsPanelType.SingleSeat,
          ObjectsPanelType.MultipleSeats,
          ObjectsPanelType.SingleZone,
          ObjectsPanelType.MultipleZones,
          ObjectsPanelType.SingleText,
          ObjectsPanelType.MultipleTexts,
          ObjectsPanelType.MixWithAttendeeNavigation,
          ObjectsPanelType.MixZonesAndTexts,
          ObjectsPanelType.MixWithLabel
        ].includes(objectsPanelType) && (
          <LabelTextField
            objectsWithLabel={selectedObjects.filter(
              isSeatZoneOrTextStateObject
            )}
            key={getKey('label')}
            objectsPanelType={objectsPanelType}
          />
        )}
        <RotationTextField
          selectedObjects={selectedObjects}
          key={getKey('rotation')}
        />
        {[ObjectsPanelType.SingleZone, ObjectsPanelType.MultipleZones].includes(
          objectsPanelType
        ) && (
          <ZoneTypeSelect
            key={getKey('zoneType')}
            selectedZones={selectedObjects.filter(isZoneStateValue)}
          />
        )}
        {[ObjectsPanelType.SingleZone, ObjectsPanelType.MultipleZones].includes(
          objectsPanelType
        ) && (
          <CapacityTextField
            key={getKey('capacity')}
            selectedZones={selectedObjects.filter(isZoneStateValue)}
          />
        )}
        {[ObjectsPanelType.SingleIcon, ObjectsPanelType.MultipleIcons].includes(
          objectsPanelType
        ) && (
          <IconSelect
            key={getKey('icon')}
            icons={selectedObjects.filter(isIconStateValue)}
          />
        )}
        {[ObjectsPanelType.SingleIcon, ObjectsPanelType.MultipleIcons].includes(
          objectsPanelType
        ) && (
          <IconShapeToggleButtonGroup
            key={getKey('icon-shape')}
            icons={selectedObjects.filter(isIconStateValue)}
          />
        )}
        {[ObjectsPanelType.SingleText, ObjectsPanelType.MultipleTexts].includes(
          objectsPanelType
        ) && (
          <FontFieldsToggleButtonGroups
            key={getKey('font')}
            texts={selectedObjects.filter(isTextStateValue)}
          />
        )}
        {[
          ObjectsPanelType.SingleZone,
          ObjectsPanelType.MultipleZones,
          ObjectsPanelType.SingleIcon,
          ObjectsPanelType.MultipleIcons,
          ObjectsPanelType.SingleRectangle,
          ObjectsPanelType.MultipleRectangles,
          ObjectsPanelType.SingleLine,
          ObjectsPanelType.MultipleLines,
          ObjectsPanelType.SingleEllipse,
          ObjectsPanelType.MultipleEllipses,
          ObjectsPanelType.MultipleShapes,
          ObjectsPanelType.MixWithDimensions
        ].includes(objectsPanelType) && (
          <DimensionTextFields
            key={getKey('dimensions')}
            selectedObjects={selectedObjects.filter(
              isZoneIconOrShapeStateObject
            )}
          />
        )}
        {[ObjectsPanelType.SingleSeat, ObjectsPanelType.MultipleSeats].includes(
          objectsPanelType
        ) && (
          <SeatShapeToggleButtonGroup
            key={getKey('seat-shape')}
            seatStateObjects={selectedObjects.filter(isSeatStateObject)}
          />
        )}
      </div>
      {[
        ObjectsPanelType.SingleSeat,
        ObjectsPanelType.MultipleSeats,
        ObjectsPanelType.SingleZone,
        ObjectsPanelType.MultipleZones,
        ObjectsPanelType.MixWithAttendeeNavigation
      ].includes(objectsPanelType) && (
        <>
          <Divider />
          <AttendeeNavigation
            className={classes.gridLayout}
            key={getKey('attendee-navigation')}
            selectedSeatsAndZones={selectedObjects.filter(
              isSeatOrZoneStateObject
            )}
          />
        </>
      )}
    </div>
  )
}
