import {Drawer, drawerClasses} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import {FormContext, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useMutationAssistanceHooks} from '../../../../hooks/mutationAssistanceHooks'
import {routeTo} from '../../../../utils/routes'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../common'
import {SaveButton} from '../../../common/Buttons'
import {CreateMonthlyReportForm} from './CreateMonthlyReportForm'
import {
  useAvailableInputDataForMonthlyECommerceReports,
  useCreateMonthlyECommercePaymentReport
} from './graphql'
import {IMonthlyPaymentReportForm, MonthlyPaymentReportField} from './types'
import {transformMonthlyPaymentReportFormToInput} from './utils'

const CREATE_MONTHLY_ECOMMERCE_REPORT_FORM_ID =
  'createMonthlyECommerceReportForm'

interface ICreateMonthlyECommerceReportDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateMonthlyECommerceReportDrawer: React.FC<ICreateMonthlyECommerceReportDrawerProps> =
  ({isOpen, onClose}: ICreateMonthlyECommerceReportDrawerProps) => {
    const {t} = useTranslation()
    const [skip, setSkip] = useState(!isOpen)
    const {data, loading, error} =
      useAvailableInputDataForMonthlyECommerceReports({
        fetchPolicy: 'network-only',
        skip
      })
    const createMonthlyECommercePaymentReport =
      useCreateMonthlyECommercePaymentReport()
    const {addInfoNotification, setShowBackdrop, defaultErrorHandler} =
      useMutationAssistanceHooks()
    const methods = useForm<IMonthlyPaymentReportForm>()
    const history = useHistory()
    const handleSubmit = useCallback(
      async (formData: IMonthlyPaymentReportForm) => {
        try {
          setShowBackdrop(true)
          const {data} = await createMonthlyECommercePaymentReport(
            transformMonthlyPaymentReportFormToInput(formData)
          )
          addInfoNotification(t('Monthly ecommerce payment report created'))
          onClose()
          if (data) {
            history.replace(
              routeTo.admin.paymentReports.detail(
                data.createMonthlyECommercePaymentReport.id
              ),
              {
                from: routeTo.admin.paymentReports.monthlyEcommerceReports()
              }
            )
          }
        } catch (error) {
          defaultErrorHandler(
            error,
            t('Error while creating monthly ecommerce payment report')
          )
        } finally {
          setShowBackdrop(false)
        }
      },
      [
        addInfoNotification,
        createMonthlyECommercePaymentReport,
        defaultErrorHandler,
        history,
        onClose,
        setShowBackdrop,
        t
      ]
    )
    const selectedMonth = methods.watch(MonthlyPaymentReportField.YearMonth)
    const isSubmitButtonDisabled = isEmpty(selectedMonth)
    useEffect(() => {
      if (isOpen) {
        setSkip(false)
      }
    }, [isOpen])
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        SlideProps={{
          onExited: () => {
            setSkip(true)
          }
        }}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 480, width: '100%'}}}
      >
        <DrawerTemplate
          isLoading={loading}
          errorMessage={
            error &&
            t<string>(
              'Error while loading input data for monthly ecommerce reports'
            )
          }
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Create monthly ecommerce report')}
            />
          }
          footer={
            <SaveButton
              type="submit"
              form={CREATE_MONTHLY_ECOMMERCE_REPORT_FORM_ID}
              disabled={isSubmitButtonDisabled}
            >
              {t('Create')}
            </SaveButton>
          }
        >
          {data && (
            <FormContext {...methods}>
              <CreateMonthlyReportForm
                formId={CREATE_MONTHLY_ECOMMERCE_REPORT_FORM_ID}
                onSubmit={handleSubmit}
                data={data.availableInputDataForMonthlyECommerceReports}
              />
            </FormContext>
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
