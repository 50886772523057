import {Typography} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {GetCheckTicketsEventDrawerDataQuery} from '../../../../__generated__/schema'
import {
  DrawerSection,
  IDrawerSectionProps,
  SectionGrid,
  SectionGridCouple,
  SectionGridSubtitle
} from '../../../common'

const useStyles = makeStyles<Theme>((theme) => ({
  totalChecks: {
    paddingTop: theme.spacing(6)
  },
  info: {
    padding: theme.spacing(0, 2, 2, 2)
  }
}))

interface IStatisticsDrawerSectionProps {
  event: GetCheckTicketsEventDrawerDataQuery['event']
  drawerSectionProps: Omit<IDrawerSectionProps, 'children'>
}

export const StatisticsDrawerSection: React.FC<IStatisticsDrawerSectionProps> =
  ({event, drawerSectionProps}: IStatisticsDrawerSectionProps) => {
    const {t} = useTranslation()
    const classes = useStyles()
    return (
      <DrawerSection {...drawerSectionProps}>
        <SectionGrid>
          <SectionGridCouple label={t('Unchecked')}>
            <Typography variant="body2">
              {event.soldSeatsCount - event.uniqueCheckedInCount}
            </Typography>
          </SectionGridCouple>
          <SectionGridCouple label={t('Sold tickets')}>
            <Typography variant="body2">{event.soldSeatsCount}</Typography>
          </SectionGridCouple>
          <SectionGridCouple label={t('Reserved')}>
            <Typography variant="body2">{event.reservedSeatsCount}</Typography>
          </SectionGridCouple>
        </SectionGrid>
        <div className={classes.totalChecks}>
          <SectionGrid>
            <SectionGridSubtitle subtitle={t('Total checks')} />
            <SectionGridCouple label={t('Checked in')}>
              <Typography variant="body2">
                {event.passCodesCheckedInCount}
              </Typography>
            </SectionGridCouple>
            <SectionGridCouple label={t('Checked out')}>
              <Typography variant="body2">
                {event.passCodesCheckedOutCount}
              </Typography>
            </SectionGridCouple>
            <SectionGridCouple label={t('On event right now')}>
              <Typography variant="body2">
                {event.passCodesCheckedInCount - event.passCodesCheckedOutCount}
              </Typography>
            </SectionGridCouple>
          </SectionGrid>
        </div>
        <div className={classes.info}>
          <Typography variant="caption" color="textSecondary">
            {t(
              'Currently inside attendees count depends on quality of checking tickets at all access points (entrances and exits as well). Count may be negative, if some tickets were not checked at the entrance, but were checked at the exit.'
            )}
          </Typography>
        </div>
      </DrawerSection>
    )
  }
