import {noop, sortBy} from 'lodash'
import {createContext, Dispatch} from 'react'
import {v4 as uuidv4} from 'uuid'
import {
  PaymentMethodPropertiesFragment,
  PaymentMethodType,
  VoucherPropertiesFragment
} from '../../../../../__generated__/schema'
import {CheckoutPaymentMethodsViewMode} from '../../../../../types'
import {
  IAddIntentFrame,
  IGroupIntentStructure,
  IInHouseVoucherFrame,
  IInHouseVoucherIntentStructure,
  IIntentFrame,
  IntentFrameComponentType,
  IntentsStructureReducer,
  ITypeIntentStructure
} from './types'

export const sortIntentFrames = (
  intentFrames: IIntentFrame[]
): IIntentFrame[] => sortBy(intentFrames, ['name', 'paymentMethodId'])

const getDefaultIntentComponentType = (
  paymentMethod: PaymentMethodPropertiesFragment
) => {
  if (
    !paymentMethod.hasDenomination &&
    [
      PaymentMethodType.Cash,
      PaymentMethodType.Card,
      PaymentMethodType.WireTransfer
    ].includes(paymentMethod.type)
  ) {
    return IntentFrameComponentType.IntentTextFieldWithChipsFrame
  }
  if (paymentMethod.type === PaymentMethodType.Voucher) {
    return paymentMethod.hasDenomination
      ? IntentFrameComponentType.IntentCountFrame
      : IntentFrameComponentType.AddIntentFrame
  }
  return IntentFrameComponentType.None
}

export const createIntentFrame = (
  paymentMethod: PaymentMethodPropertiesFragment,
  component?: IntentFrameComponentType
): IIntentFrame => ({
  paymentMethodId: paymentMethod.id,
  key: uuidv4(),
  initialDenomination: paymentMethod.value,
  initialCount: 0,
  name: paymentMethod.name,
  hasFixedDenomination: paymentMethod.hasDenomination,
  type: paymentMethod.type,
  componentType: component || getDefaultIntentComponentType(paymentMethod)
})

export const getTypeIntentStructuresDefaultState = (
  paymentMethods: PaymentMethodPropertiesFragment[]
): ITypeIntentStructure[] =>
  paymentMethods
    .filter(
      (paymentMethod) => !paymentMethod.group && !paymentMethod.serviceProvider
    )
    .reduce<ITypeIntentStructure[]>(
      (acc, paymentMethod: PaymentMethodPropertiesFragment) =>
        acc.find((structure) => structure.type === paymentMethod.type)
          ? acc.reduce(
              (
                innerAcc: ITypeIntentStructure[],
                innerIntentStructure: ITypeIntentStructure
              ) => [
                ...innerAcc,
                innerIntentStructure.type === paymentMethod.type
                  ? {
                      ...innerIntentStructure,
                      intentFrames: [
                        ...innerIntentStructure.intentFrames,
                        createIntentFrame(paymentMethod)
                      ]
                    }
                  : innerIntentStructure
              ],
              []
            )
          : [
              ...acc,
              {
                key: uuidv4(),
                type: paymentMethod.type,
                intentFrames: [createIntentFrame(paymentMethod)]
              }
            ],
      [
        {
          type: PaymentMethodType.Cash,
          key: uuidv4(),
          intentFrames: []
        },
        {
          type: PaymentMethodType.Card,
          key: uuidv4(),
          intentFrames: []
        },
        {
          type: PaymentMethodType.Voucher,
          key: uuidv4(),
          intentFrames: []
        },
        {
          type: PaymentMethodType.WireTransfer,
          key: uuidv4(),
          intentFrames: []
        }
      ]
    )
    .filter((intentStructure) => intentStructure.intentFrames.length > 0)
    .map((intentStructure) => ({
      ...intentStructure,
      intentFrames: sortIntentFrames(intentStructure.intentFrames)
    }))

export const getGroupIntentStructuresDefaultState = (
  paymentMethods: PaymentMethodPropertiesFragment[]
): IGroupIntentStructure[] =>
  paymentMethods
    .filter(
      (paymentMethod) =>
        Boolean(paymentMethod.group) && !paymentMethod.serviceProvider
    )
    .reduce<IGroupIntentStructure[]>(
      (
        acc: IGroupIntentStructure[],
        paymentMethod: PaymentMethodPropertiesFragment
      ) =>
        acc.find(
          (structure: IGroupIntentStructure) =>
            structure.group.id === paymentMethod.group!.id
        )
          ? acc.reduce(
              (
                innerAcc: IGroupIntentStructure[],
                innerStructure: IGroupIntentStructure
              ) => [
                ...innerAcc,
                innerStructure.group.id === paymentMethod.group!.id
                  ? {
                      ...innerStructure,
                      intentFrames: [
                        ...innerStructure.intentFrames,
                        createIntentFrame(paymentMethod)
                      ]
                    }
                  : innerStructure
              ],
              []
            )
          : [
              ...acc,
              {
                key: uuidv4(),
                group: paymentMethod.group!,
                intentFrames: [createIntentFrame(paymentMethod)]
              }
            ],
      []
    )
    .sort((collectionA, collectionB) =>
      collectionA.group.name > collectionB.group.name ? 1 : -1
    )
    .map((collection) => ({
      ...collection,
      intentFrames: sortIntentFrames(collection.intentFrames)
    }))

export const getIntentsStructureDefaultState = (
  paymentMethods: PaymentMethodPropertiesFragment[],
  isReadVoucherByCodePermitted: boolean = false,
  defaultCheckoutPaymentMethodsViewMode: CheckoutPaymentMethodsViewMode
): IntentsStructureReducer => {
  const typeIntentStructures =
    getTypeIntentStructuresDefaultState(paymentMethods)
  const groupIntentStructures =
    getGroupIntentStructuresDefaultState(paymentMethods)
  return {
    typeIntentStructures,
    groupIntentStructures,
    inHouseVoucherIntentStructure: [],
    inHouseVoucherRowStructure: isReadVoucherByCodePermitted
      ? {key: uuidv4()}
      : null,
    expandedStructureKey:
      (defaultCheckoutPaymentMethodsViewMode ===
        CheckoutPaymentMethodsViewMode.ExpandedFirstGroup &&
        (typeIntentStructures[0]?.key || groupIntentStructures[0]?.key)) ||
      null
  }
}

export enum IntentStructureActionType {
  InsertCountIntentFrameBeforeAddIntentFrame = 'insertCountIntentFrameBeforeAddIntentFrame',
  RemoveIntentFrame = 'removeIntentFrame',
  InsertInHouseVoucherFrame = 'InsertInHouseVoucherFrame',
  RemoveInHouseVoucherFrame = 'RemoveInHouseVoucherFrame',
  ExtendStructure = 'extendStructure'
}

export type InsertCountIntentFrameBeforeAddIntentFrameAction = {
  type: IntentStructureActionType.InsertCountIntentFrameBeforeAddIntentFrame
  payload: {
    addIntentFrame: IAddIntentFrame
    structureKey: PaymentMethodType | number
  }
}
export type RemoveIntentFrameAction = {
  type: IntentStructureActionType.RemoveIntentFrame
  payload: string
}

export type InsertInHouseVoucherFrameAction = {
  type: IntentStructureActionType.InsertInHouseVoucherFrame
  payload: {
    campaign: VoucherPropertiesFragment['campaign']
    voucherFrame: IInHouseVoucherFrame
  }
}
export type RemoveInHouseVoucherFrameAction = {
  type: IntentStructureActionType.RemoveInHouseVoucherFrame
  payload: number
}

export type ExtendStructureAction = {
  type: IntentStructureActionType.ExtendStructure
  payload: string
}

export type IntentStructureAction =
  | InsertCountIntentFrameBeforeAddIntentFrameAction
  | RemoveIntentFrameAction
  | InsertInHouseVoucherFrameAction
  | RemoveInHouseVoucherFrameAction
  | ExtendStructureAction

const insertCountIntentFrameBeforeAddIntentFrame = (
  intentFrames: IIntentFrame[],
  addIntentFrame: IAddIntentFrame
): IIntentFrame[] =>
  intentFrames.reduce<IIntentFrame[]>(
    (acc, intentFrame) => [
      ...acc,
      ...(intentFrame.key === addIntentFrame.key
        ? [
            {
              ...addIntentFrame,
              key: uuidv4(),
              componentType: IntentFrameComponentType.IntentCountFrame
            },
            intentFrame
          ]
        : [intentFrame])
    ],
    []
  )

const stripIntentFrameFromStructuresByKey = <
  T extends Pick<IGroupIntentStructure, 'intentFrames'>
>(
  structures: T[],
  intentFrameKey: string
): T[] =>
  structures.reduce<T[]>(
    (acc, structure) => [
      ...acc,
      {
        ...structure,
        intentFrames: structure.intentFrames.filter(
          (frame) => frame.key !== intentFrameKey
        )
      }
    ],
    []
  )

const insertVoucherFrameIntoVoucherFrames = (
  voucherFrames: IInHouseVoucherFrame[],
  voucherFrame: IInHouseVoucherFrame
): IInHouseVoucherFrame[] =>
  voucherFrames.find((vf) => vf.voucherId === voucherFrame.voucherId)
    ? voucherFrames.map((v) =>
        v.voucherId === voucherFrame.voucherId ? voucherFrame : v
      )
    : [...voucherFrames, voucherFrame]

const doesVoucherIdBelongToLastFrameInStructure = (
  structure: IInHouseVoucherIntentStructure,
  voucherId: number
) =>
  structure.voucherFrames.length === 1 &&
  structure.voucherFrames[0].voucherId === voucherId

const removeVoucherFrameFromVoucherFrames = (
  frames: IInHouseVoucherFrame[],
  voucherId: number
) => frames.filter((f) => f.voucherId !== voucherId)

export const removeFrameFromInHouseVoucherIntentStructures = (
  prevStructures: IInHouseVoucherIntentStructure[],
  voucherId: number
): IInHouseVoucherIntentStructure[] => {
  return prevStructures.reduce<IInHouseVoucherIntentStructure[]>(
    (acc, structure) =>
      doesVoucherIdBelongToLastFrameInStructure(structure, voucherId)
        ? acc
        : [
            ...acc,
            {
              ...structure,
              voucherFrames: removeVoucherFrameFromVoucherFrames(
                structure.voucherFrames,
                voucherId
              )
            }
          ],
    []
  )
}

export const insertInHouseVoucherFrameReducer = (
  state: IntentsStructureReducer,
  payload: InsertInHouseVoucherFrameAction['payload']
) => {
  const prevStructures = state.inHouseVoucherIntentStructure
  const existingStructure = prevStructures.find(
    (s) => s.campaign.id === payload.campaign.id
  )
  if (existingStructure) {
    return {
      ...state,
      inHouseVoucherIntentStructure: prevStructures.map((structure) =>
        structure.campaign.id === payload.campaign.id
          ? {
              ...structure,
              voucherFrames: insertVoucherFrameIntoVoucherFrames(
                structure.voucherFrames,
                payload.voucherFrame
              )
            }
          : structure
      ),
      expandedStructureKey: existingStructure.key
    }
  }
  const newlyAddedStructure = {
    key: uuidv4(),
    campaign: payload.campaign,
    voucherFrames: [payload.voucherFrame]
  }
  return {
    ...state,
    inHouseVoucherIntentStructure: [...prevStructures, newlyAddedStructure],
    expandedStructureKey: newlyAddedStructure.key
  }
}
export const intentsStructureReducer = (
  state: IntentsStructureReducer,
  action?: IntentStructureAction
): IntentsStructureReducer => {
  switch (action?.type) {
    case IntentStructureActionType.InsertCountIntentFrameBeforeAddIntentFrame:
      return {
        ...state,
        ...(typeof action.payload.structureKey === 'number'
          ? {
              groupIntentStructures: state.groupIntentStructures.reduce<
                IGroupIntentStructure[]
              >(
                (acc, structure) => [
                  ...acc,
                  structure.group.id === action.payload.structureKey
                    ? {
                        ...structure,
                        intentFrames:
                          insertCountIntentFrameBeforeAddIntentFrame(
                            structure.intentFrames,
                            action.payload.addIntentFrame
                          )
                      }
                    : structure
                ],
                []
              )
            }
          : {
              typeIntentStructures: state.typeIntentStructures.reduce<
                ITypeIntentStructure[]
              >(
                (acc, structure) => [
                  ...acc,
                  structure.type === action.payload.structureKey
                    ? {
                        ...structure,
                        intentFrames:
                          insertCountIntentFrameBeforeAddIntentFrame(
                            structure.intentFrames,
                            action.payload.addIntentFrame
                          )
                      }
                    : structure
                ],
                []
              )
            })
      }
    case IntentStructureActionType.RemoveIntentFrame:
      return {
        ...state,
        typeIntentStructures: stripIntentFrameFromStructuresByKey(
          state.typeIntentStructures,
          action.payload
        ),
        groupIntentStructures: stripIntentFrameFromStructuresByKey(
          state.groupIntentStructures,
          action.payload
        )
      }
    case IntentStructureActionType.InsertInHouseVoucherFrame:
      return insertInHouseVoucherFrameReducer(state, action.payload)
    case IntentStructureActionType.RemoveInHouseVoucherFrame:
      return {
        ...state,
        inHouseVoucherIntentStructure:
          removeFrameFromInHouseVoucherIntentStructures(
            state.inHouseVoucherIntentStructure,
            action.payload
          )
      }
    case IntentStructureActionType.ExtendStructure:
      return {...state, expandedStructureKey: action.payload}
    default:
      return state
  }
}

export const IntentsStructureDispatchContext =
  createContext<Dispatch<IntentStructureAction>>(noop)
