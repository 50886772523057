import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {AccordionSummary, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ClaimPropertiesFragment,
  ClaimState,
  IntentState
} from '../../../../../__generated__/schema'
import {useTranslateSellingChannel} from '../../../../../hooks/sellingChannel'
import {useTranslateEffectiveClientPrice} from '../../../../../hooks/translateCurrencies'
import {Theme} from '../../../../../theme'
import {useDateTimeFormatters} from '../../../../../utils/formatting'
import {EntityStateChip} from '../../../../common'
import {COLOR_CONF} from '../../../../constants'

interface IPaymentChipProps {
  claimState: ClaimState
}

const PaymentChip: React.FC<IPaymentChipProps> = ({
  claimState
}: IPaymentChipProps) => {
  const {t} = useTranslation()
  if (claimState === ClaimState.Pending) {
    return (
      <EntityStateChip
        isDotHidden
        colorConf={COLOR_CONF.YELLOW}
        label={t('Pending')}
      />
    )
  }
  if (claimState === ClaimState.Refunded) {
    return (
      <EntityStateChip
        isDotHidden
        colorConf={COLOR_CONF.RED}
        label={t('Refunded')}
      />
    )
  }
  return null
}

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr auto'
  },
  leftWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: theme.spacing(0, 1),
    gridTemplateAreas: `
      "title   chip"
      "infoRow infoRow"
    `
  },
  infoRow: {
    gridArea: 'infoRow'
  },
  rightWrapper: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    textAlign: 'right'
  },
  intentsCount: {
    lineHeight: '100%'
  }
}))

interface IPaymentAccordionSummaryProps {
  claim: ClaimPropertiesFragment
}

export const PaymentAccordionSummary: React.FC<IPaymentAccordionSummaryProps> =
  ({claim}: IPaymentAccordionSummaryProps) => {
    const {t} = useTranslation()
    const classes = useStyles()
    const {formatDateNumeric, formatTime} = useDateTimeFormatters()
    const translateEffectiveClientPrice = useTranslateEffectiveClientPrice()
    const translateSellingChannel = useTranslateSellingChannel()
    const isRefunded = claim.state === ClaimState.Refunded
    const title =
      claim.payment && isRefunded
        ? claim.payment.id
        : t('No successful payment found')
    return (
      <AccordionSummary
        expandIcon={
          claim.refundIntents.length > 0 ? <ExpandMoreIcon /> : undefined
        }
        classes={{content: classes.content}}
      >
        <div className={classes.leftWrapper}>
          <Typography variant="subtitle2">{title}</Typography>
          <PaymentChip claimState={claim.state} />
          {claim.payment && (
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.infoRow}
            >
              {[
                formatDateNumeric(new Date(claim.payment.createdAt)),
                formatTime(new Date(claim.payment.createdAt)),
                translateSellingChannel(claim.payment.channel)
              ].join(' • ')}
            </Typography>
          )}
        </div>
        <div className={classes.rightWrapper}>
          {claim.payment && (
            <Typography variant="body2" component="span">
              {translateEffectiveClientPrice(claim.payment.amount * -1)}
            </Typography>
          )}
          {isRefunded ? (
            <Typography variant="overline">
              {t('{{count}} methods', {
                count: claim.refundIntents.filter(
                  ({state}) => state === IntentState.Succeeded
                ).length
              })}
            </Typography>
          ) : (
            <Typography variant="overline" className={classes.intentsCount}>
              {t('{{count}} intents', {
                count: claim.refundIntents.length
              })}
            </Typography>
          )}
        </div>
      </AccordionSummary>
    )
  }
