import {every, values} from 'lodash'
import isUndefined from 'lodash/isUndefined'
import omit from 'lodash/omit'
import {useTranslation} from 'react-i18next'
import {
  BaseWebsitePageFieldsFragment,
  UpdateWebsiteInput,
  UpdateWebsitePageInput,
  WebsiteInput,
  WebsitePageInput,
  WebsitePageState,
  WebsitePageType,
  WebsiteState
} from '../../../../__generated__/schema'
import {
  ButtonIconPosition,
  MuiButtonSize,
  MuiButtonVariant
} from './detail/editBlockDrawer/types'
import {
  AddMenuItem,
  BlockType,
  IWebsiteForm,
  IWebsitePageForm,
  WebsiteEditorContent,
  WebsiteFormField,
  WebsitePageFormField,
  WebsitePageMode
} from './types'

export const transformWebsiteFormDataToWebsiteInput = (
  formData: IWebsiteForm
): WebsiteInput => ({
  ...formData,
  previewUrl: formData[WebsiteFormField.PreviewUrl] || undefined,
  primaryDomain: formData[WebsiteFormField.PrimaryDomain] || undefined,
  clientId: parseInt(formData[WebsiteFormField.ClientId], 10)
})

export const transformWebsiteFormDataToUpdateWebsiteInput = (
  formData: IWebsiteForm,
  state?: WebsiteState
): UpdateWebsiteInput => ({
  projectFullPath: formData[WebsiteFormField.ProjectFullPath],
  productionBranch: formData[WebsiteFormField.ProductionBranch],
  name: formData[WebsiteFormField.Name],
  previewUrl: formData[WebsiteFormField.PreviewUrl] || null,
  primaryDomain: formData[WebsiteFormField.PrimaryDomain] || null,
  enabledLocaleCodes: formData[WebsiteFormField.EnabledLocaleCodes],
  state
})

export const useTranslateWebsiteEditorContent = () => {
  const {t} = useTranslation()
  const translated: Record<WebsiteEditorContent, string> = {
    [WebsiteEditorContent.Pages]: t('WebsiteEditorContent->Pages'),
    [WebsiteEditorContent.Blocks]: t('WebsiteEditorContent->Blocks'),
    [WebsiteEditorContent.Assets]: t('WebsiteEditorContent->Assets')
  }
  return (item: WebsiteEditorContent) => translated[item]
}

export const transformWebsitePageDataToWebsitePageInput = (
  formData: IWebsitePageForm
): WebsitePageInput => ({
  ...omit(formData, WebsitePageFormField.LocaleCode),
  description: formData[WebsitePageFormField.Description] || undefined,
  state: WebsitePageState.Draft
})

export const getWebsitePageInputForCopyPage = (
  formData: IWebsitePageForm,
  originPage: BaseWebsitePageFieldsFragment
): WebsitePageInput => ({
  ...omit(formData, WebsitePageFormField.LocaleCode),
  description: formData[WebsitePageFormField.Description] || undefined,
  state: WebsitePageState.Draft,
  blocks: originPage.blocks
})

export const transformWebsitePageDataToUpdateWebsitePageInput = (
  formData: IWebsitePageForm,
  prevSlug: string,
  state?: WebsitePageState
): UpdateWebsitePageInput => ({
  ...omit(formData, WebsitePageFormField.LocaleCode),
  description: formData[WebsitePageFormField.Description] || null,
  slug:
    formData[WebsitePageFormField.Slug] === prevSlug
      ? undefined
      : formData[WebsitePageFormField.Slug],
  state
})

export const useTranslateBlockType = () => {
  const {t} = useTranslation()
  const translated: Record<BlockType, string> = {
    [BlockType.Header]: t('BlockType->Header'),
    [BlockType.EventsTable]: t('BlockType->EventsTable'),
    [BlockType.Label]: t('BlockType->Label'),
    [BlockType.FeaturedEventPosters]: t('BlockType->FeaturedEventPosters'),
    [BlockType.FeaturedShowPosters]: t('BlockType->FeaturedShowPosters'),
    [BlockType.ShowDetail]: t('BlockType->ShowDetail'),
    [BlockType.Footer]: t('BlockType->Footer'),
    [BlockType.Markdown]: t('BlockType->Markdown'),
    [BlockType.RawHtml]: t('BlockType->RawHtml'),
    [BlockType.FeaturedShowPhotos]: t('BlockType->FeaturedShowPhotos'),
    [BlockType.FeaturedShowCovers]: t('BlockType->FeaturedShowCovers'),
    [BlockType.ButtonGroup]: t('BlockType->ButtonGroup'),
    [BlockType.EventsList]: t('BlockType->EventsList'),
    [BlockType.TimeSlotsTable]: t('BlockType->TimeSlotsTable'),
    [BlockType.CardsRow]: t('BlockType->CardsRow')
  }
  return (blockType: BlockType) => translated[blockType]
}

export const useTranslateWebsitePageType = () => {
  const {t} = useTranslation()
  const translated: Record<WebsitePageType, string> = {
    [WebsitePageType.Homepage]: t('WebsitePageType->Homepage'),
    [WebsitePageType.ShowDetail]: t('WebsitePageType->ShowDetail'),
    [WebsitePageType.EventDetail]: t('WebsitePageType->EventDetail'),
    [WebsitePageType.ArticleDetail]: t('WebsitePageType->ArticleDetail'),
    [WebsitePageType.SignIn]: t('WebsitePageType->SignIn'),
    [WebsitePageType.SignUp]: t('WebsitePageType->SignUp'),
    [WebsitePageType.Profile]: t('WebsitePageType->Profile'),
    [WebsitePageType.Cart]: t('WebsitePageType->Cart'),
    [WebsitePageType.PrivacyPolicy]: t('WebsitePageType->PrivacyPolicy'),
    [WebsitePageType.TermsAndConditions]: t(
      'WebsitePageType->TermsAndConditions'
    ),
    [WebsitePageType.Sitemap]: t('WebsitePageType->Sitemap'),
    [WebsitePageType.SearchResults]: t('WebsitePageType->SearchResults'),
    [WebsitePageType.PageNotFound]: t('WebsitePageType->PageNotFound')
  }
  return (type: WebsitePageType) => translated[type]
}

export const isBlockType = (item: any): item is BlockType =>
  Object.values(BlockType).includes(item)

export const useTranslateWebsitePageMode = () => {
  const {t} = useTranslation()
  const translated: Record<WebsitePageMode, string> = {
    [WebsitePageMode.Editing]: t('WebsitePageMode->Editing'),
    [WebsitePageMode.Designing]: t('WebsitePageMode->Designing')
  }
  return (mode: WebsitePageMode) => translated[mode]
}

export const useTranslateWebsitePageModeDescription = () => {
  const {t} = useTranslation()
  const translated: Record<WebsitePageMode, string> = {
    [WebsitePageMode.Editing]: t('Edit static and dynamic content'),
    [WebsitePageMode.Designing]: t('Manage content and layouts')
  }
  return (mode: WebsitePageMode) => translated[mode]
}

export const getAddMenuItemType = (link?: string): AddMenuItem => {
  if (link) {
    if (link.startsWith('/')) {
      return AddMenuItem.Page
    } else {
      return AddMenuItem.ExternalLink
    }
  }
  return AddMenuItem.Label
}

export const isObjectEmpty = (object?: {}) => every(values(object), isUndefined)

export const useTranslateMuiButtonSize = () => {
  const {t} = useTranslation()
  const translated: Record<MuiButtonSize, string> = {
    [MuiButtonSize.Small]: t('MuiButtonSize->Small'),
    [MuiButtonSize.Medium]: t('MuiButtonSize->Medium'),
    [MuiButtonSize.Large]: t('MuiButtonSize->Large')
  }
  return (size: MuiButtonSize) => translated[size]
}

export const useTranslateMuiButtonVariant = () => {
  const {t} = useTranslation()
  const translated: Record<MuiButtonVariant, string> = {
    [MuiButtonVariant.Text]: t('MuiButtonVariant->Text'),
    [MuiButtonVariant.Outlined]: t('MuiButtonVariant->Outlined'),
    [MuiButtonVariant.Contained]: t('MuiButtonVariant->Contained')
  }
  return (variant: MuiButtonVariant) => translated[variant]
}

export const useTranslateButtonIconPosition = () => {
  const {t} = useTranslation()
  const translated: Record<ButtonIconPosition, string> = {
    [ButtonIconPosition.Start]: t('ButtonIconPosition->Start'),
    [ButtonIconPosition.End]: t('ButtonIconPosition->End'),
    [ButtonIconPosition.Top]: t('ButtonIconPosition->Top')
  }
  return (position: ButtonIconPosition) => translated[position]
}
