import {Drawer, drawerClasses, MenuItem} from '@mui/material'
import React, {useCallback, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {
  WebsitePageState,
  WebsiteQuery
} from '../../../../../__generated__/schema'
import {useMutationAssistanceHooks} from '../../../../../hooks/mutationAssistanceHooks'
import {DrawerTemplate, DrawerTemplateHeader} from '../../../../common'
import {SaveButton} from '../../../../common/Buttons'
import {SplitButton} from '../../../../visual'
import {useUpdateWebsitePage} from '../graphql'
import {IWebsitePageForm, WebsitePageFormField} from '../types'
import {transformWebsitePageDataToUpdateWebsitePageInput} from '../utils'
import {useWebsitePageContext} from './WebsitePageContext'
import {WebsitePageForm} from './WebsitePageForm'

const UPDATE_PAGE_FORM_ID = 'updatePageForm'

interface IUpdateWebsitePageDrawerProps {
  isOpen: boolean
  onClose: () => void
  website: WebsiteQuery['website']
}

export const UpdateWebsitePageDrawer: React.FC<IUpdateWebsitePageDrawerProps> =
  ({isOpen, onClose, website}: IUpdateWebsitePageDrawerProps) => {
    const {t} = useTranslation()
    const {selectedWebsitePage, refetchWebsitePages, setSelectedWebsitePage} =
      useWebsitePageContext()
    const updateWebsitePage = useUpdateWebsitePage()
    const {addInfoNotification, setShowBackdrop} = useMutationAssistanceHooks()
    const nextWebsitePageState = useRef<WebsitePageState | undefined>(undefined)
    const updateNextWebsitePageState = useCallback(
      (state) => () => (nextWebsitePageState.current = state),
      []
    )
    const handleSubmit = useCallback(
      async (formData: IWebsitePageForm) => {
        if (selectedWebsitePage) {
          try {
            setShowBackdrop(true)
            const {data} = await updateWebsitePage({
              websiteId: website.id,
              pageID: selectedWebsitePage.ID,
              input: transformWebsitePageDataToUpdateWebsitePageInput(
                formData,
                selectedWebsitePage.slug,
                nextWebsitePageState.current
              )
            })
            refetchWebsitePages()
            if (data) {
              setSelectedWebsitePage(data.updateWebsitePage)
            }
            addInfoNotification(t('Website page has been updated'))
            onClose()
          } finally {
            setShowBackdrop(false)
          }
        }
      },
      [
        addInfoNotification,
        onClose,
        refetchWebsitePages,
        selectedWebsitePage,
        setSelectedWebsitePage,
        setShowBackdrop,
        t,
        updateWebsitePage,
        website.id
      ]
    )
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{[`& .${drawerClasses.paper}`]: {maxWidth: 560, width: '100%'}}}
      >
        <DrawerTemplate
          header={
            <DrawerTemplateHeader
              onLeftActionClick={onClose}
              title={t('Update page')}
            />
          }
          footer={
            <SplitButton
              Options={[
                <MenuItem
                  key="save-as"
                  type="submit"
                  form={UPDATE_PAGE_FORM_ID}
                  component="button"
                  onClick={
                    selectedWebsitePage?.state === WebsitePageState.Published
                      ? updateNextWebsitePageState(WebsitePageState.Archived)
                      : updateNextWebsitePageState(WebsitePageState.Published)
                  }
                >
                  {selectedWebsitePage?.state === WebsitePageState.Published
                    ? t('Save and archive')
                    : t('Save and publish')}
                </MenuItem>
              ]}
            >
              <SaveButton type="submit" form={UPDATE_PAGE_FORM_ID}>
                {t('Save')}
              </SaveButton>
            </SplitButton>
          }
          childrenSx={{backgroundColor: 'background.paper'}}
        >
          {selectedWebsitePage && (
            <WebsitePageForm
              formId={UPDATE_PAGE_FORM_ID}
              onSubmit={handleSubmit}
              primaryDomain={website.primaryDomain}
              enabledLocaleCodes={website.enabledLocaleCodes}
              defaultValues={{
                [WebsitePageFormField.LocaleCode]:
                  selectedWebsitePage.localeCode,
                [WebsitePageFormField.Title]: selectedWebsitePage.title,
                [WebsitePageFormField.Slug]: selectedWebsitePage.slug,
                [WebsitePageFormField.Description]:
                  selectedWebsitePage.description
              }}
              isLocaleCodeDisabled
            />
          )}
        </DrawerTemplate>
      </Drawer>
    )
  }
