import {useMutation, useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {useCallback} from 'react'
import {
  CreateCustomerGroupInput,
  CreateCustomerGroupMutation,
  CreateCustomerGroupMutationVariables,
  CustomerGroupQuery,
  CustomerGroupQueryVariables,
  CustomerGroupsQuery,
  CustomerGroupsQueryVariables,
  DeleteCustomerGroupMutation,
  DeleteCustomerGroupMutationVariables,
  UpdateCustomerGroupMutation,
  UpdateCustomerGroupMutationVariables
} from '../../../../__generated__/schema'

const CUSTOMER_GROUP_FIELDS = gql`
  fragment CustomerGroupFields on CustomerGroup {
    id
    name
    description
  }
`

const CUSTOMER_GROUPS = gql`
  ${CUSTOMER_GROUP_FIELDS}
  query CustomerGroups {
    customerGroups {
      ...CustomerGroupFields
    }
  }
`

export const useCustomerGroups = () =>
  useQuery<CustomerGroupsQuery, CustomerGroupsQueryVariables>(CUSTOMER_GROUPS, {
    fetchPolicy: 'network-only'
  })

const CREATE_CUSTOMER_GROUP = gql`
  mutation CreateCustomerGroup($input: CreateCustomerGroupInput!) {
    createCustomerGroup(input: $input) {
      id
    }
  }
`

export const useCreateCustomerGroup = () => {
  const [createCustomerGroup] = useMutation<
    CreateCustomerGroupMutation,
    CreateCustomerGroupMutationVariables
  >(CREATE_CUSTOMER_GROUP)
  return useCallback(
    (input: CreateCustomerGroupInput) =>
      createCustomerGroup({
        variables: {input},
        refetchQueries: [{query: CUSTOMER_GROUPS}]
      }),
    [createCustomerGroup]
  )
}

const CUSTOMER_GROUP = gql`
  ${CUSTOMER_GROUP_FIELDS}
  query CustomerGroup($id: PositiveInt!) {
    customerGroup(id: $id) {
      ...CustomerGroupFields
    }
  }
`

export const useCustomerGroup = (id: number) =>
  useQuery<CustomerGroupQuery, CustomerGroupQueryVariables>(CUSTOMER_GROUP, {
    variables: {id},
    fetchPolicy: 'network-only'
  })

const UPDATE_CUSTOMER_GROUP = gql`
  ${CUSTOMER_GROUP_FIELDS}
  mutation UpdateCustomerGroup(
    $id: PositiveInt!
    $input: UpdateCustomerGroupInput!
  ) {
    updateCustomerGroup(id: $id, input: $input) {
      ...CustomerGroupFields
    }
  }
`

export const useUpdateCustomerGroup = () => {
  const [updateCustomerGroup] = useMutation<
    UpdateCustomerGroupMutation,
    UpdateCustomerGroupMutationVariables
  >(UPDATE_CUSTOMER_GROUP)
  return useCallback(
    (variables: UpdateCustomerGroupMutationVariables) =>
      updateCustomerGroup({
        variables
      }),
    [updateCustomerGroup]
  )
}

const DELETE_CUSTOMER_GROUP = gql`
  mutation DeleteCustomerGroup($id: PositiveInt!) {
    deleteCustomerGroup(id: $id) {
      id
    }
  }
`

export const useDeleteCustomerGroup = () => {
  const [deleteCustomerGroup] = useMutation<
    DeleteCustomerGroupMutation,
    DeleteCustomerGroupMutationVariables
  >(DELETE_CUSTOMER_GROUP)
  return useCallback(
    (id: number) =>
      deleteCustomerGroup({
        variables: {id},
        refetchQueries: [{query: CUSTOMER_GROUPS}]
      }),
    [deleteCustomerGroup]
  )
}
