import {useQuery} from '@apollo/react-hooks'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  BusinessPartnerCategory,
  BusinessPartnerState,
  EventsStatisticsFilterInput,
  GetFilterEventVenuesQuery,
  GetLightweightCostCentersQuery,
  GetLightweightDivisionsQuery,
  GetLightweightEventCategoriesQuery,
  GetLightweightMarketingLabelsQuery,
  LightweightBusinessPartnersQuery,
  LightweightBusinessPartnersQueryVariables,
  PermissionCode
} from '../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../utils/auth'
import {Search, useCombineStringifySearchObjectFunctions} from '../../../common'
import {AdvancedSearchBase} from '../../../common/search/AdvancedSearchBase'
import {
  AdvancedSearchSelectRow,
  ISelectOption
} from '../../../common/search/AdvancedSearchSelectRow'
import {AdvancedSearchTextRow} from '../../../common/search/AdvancedSearchTextRow'
import {useLocale} from '../../../context/locale'
import {useGetFilterEventVenues} from '../cashDesk/graphql'
import {
  LIGHTWEIGHT_BUSINESS_PARTNERS,
  useGetLightweightClientShows,
  useGetLightweightCostCenters,
  useGetLightweightDivisions,
  useGetLightweightEventCategories,
  useGetLightweightMarketingLabels
} from '../graphql'

export const DEFAULT_EVENT_STATISTICS_FILTER_INPUT: EventsStatisticsFilterInput =
  {}

const mapEventNameToFilter = (
  filter: EventsStatisticsFilterInput,
  eventName: string | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  eventName
})

const mapDivisionIdToFilter = (
  filter: EventsStatisticsFilterInput,
  divisionId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  divisionId
})

const mapVenueIdToFilter = (
  filter: EventsStatisticsFilterInput,
  venueId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  venueId
})

const mapCostCenterIdToFilter = (
  filter: EventsStatisticsFilterInput,
  costCenterId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  costCenterId
})

const mapShowIdToFilter = (
  filter: EventsStatisticsFilterInput,
  showId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  showId
})

const mapEventCategoryIdToFilter = (
  filter: EventsStatisticsFilterInput,
  eventCategoryId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  eventCategoryId
})

const mapMarketingLabelIdToFilter = (
  filter: EventsStatisticsFilterInput,
  marketingLabelId: number | undefined
): EventsStatisticsFilterInput => ({
  ...filter,
  marketingLabelId
})

const mapBusinessPartnerIdToFilter = (
  filter: EventsStatisticsFilterInput,
  businessPartnerId?: number
): EventsStatisticsFilterInput => ({
  ...filter,
  businessPartnerIds: businessPartnerId ? [businessPartnerId] : undefined
})

const useGetFieldFromSearchObject = () => {
  const {t} = useTranslation()
  const getEventNameFromSearchObject = (filter: EventsStatisticsFilterInput) =>
    filter.eventName || undefined
  const getDivisionFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    divisions: GetLightweightDivisionsQuery['divisions']
  ) => {
    if (filter.divisionId) {
      const division = divisions.find(
        (division) => division.id === filter.divisionId
      )
      return division
        ? t('Division: {{name}}', {name: division.name})
        : t('Division ID: {{id}}', {id: filter.divisionId})
    }
    return undefined
  }
  const getVenueFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    venues: GetFilterEventVenuesQuery['venues']
  ) => {
    if (filter.venueId) {
      const venue = venues.find((venue) => venue.id === filter.venueId)
      return venue
        ? t('Venue: {{name}}', {name: venue.name})
        : t('Venue ID: {{id}}', {id: filter.venueId})
    }
    return undefined
  }
  const getCostCenterFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    costCenters: GetLightweightCostCentersQuery['costCenters']
  ) => {
    if (filter.costCenterId) {
      const costCenter = costCenters.find(
        (costCenter) => costCenter.id === filter.costCenterId
      )
      return costCenter
        ? t('Cost center: {{name}}', {name: costCenter.name})
        : t('Cost center ID: {{id}}', {id: filter.costCenterId})
    }
    return undefined
  }
  const getShowFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    shows: {id: number; title: string}[]
  ) => {
    if (filter.showId) {
      const clientShow = shows.find((show) => show.id === filter.showId)
      return clientShow
        ? t('Show: {{name}}', {name: clientShow.title})
        : t('Show ID: {{id}}', {id: filter.showId})
    }
    return undefined
  }
  const getEventCategoryFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    eventCategories: GetLightweightEventCategoriesQuery['eventCategories']
  ) => {
    if (filter.eventCategoryId) {
      const eventCategory = eventCategories.find(
        ({id}) => id === filter.eventCategoryId
      )
      return eventCategory
        ? t('Event category: {{name}}', {name: eventCategory.name})
        : t('Event category ID: {{id}}', {id: filter.eventCategoryId})
    }
    return undefined
  }
  const getMarketingLabelFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    marketingLabels: GetLightweightMarketingLabelsQuery['marketingLabels']
  ) => {
    if (filter.marketingLabelId) {
      const marketingLabel = marketingLabels.find(
        ({id}) => id === filter.marketingLabelId
      )
      return marketingLabel
        ? t('Marketing label: {{name}}', {name: marketingLabel.name})
        : t('Marketing label ID: {{id}}', {id: filter.marketingLabelId})
    }
    return undefined
  }
  const getBusinessPartnerIdFromSearchObject = (
    filter: EventsStatisticsFilterInput,
    businessPartners: {id: number; companyName: string}[]
  ) => {
    if (filter.businessPartnerIds && filter.businessPartnerIds.length) {
      const businessPartnerId = filter.businessPartnerIds[0]
      const businessPartner = businessPartners.find(
        ({id}) => id === businessPartnerId
      )
      return businessPartner
        ? t('Business partner: {{companyName}}', {
            companyName: businessPartner.companyName
          })
        : t('Business partner ID: {{id}}', {id: businessPartnerId})
    }
    return undefined
  }
  return {
    getEventNameFromSearchObject,
    getDivisionFromSearchObject,
    getVenueFromSearchObject,
    getCostCenterFromSearchObject,
    getShowFromSearchObject,
    getEventCategoryFromSearchObject,
    getMarketingLabelFromSearchObject,
    getBusinessPartnerIdFromSearchObject
  }
}

interface IEventsStatisticsSearchProps {
  onFilterChange: (filter: EventsStatisticsFilterInput) => void
}

export const EventsStatisticsSearch: React.FC<IEventsStatisticsSearchProps> = ({
  onFilterChange
}: IEventsStatisticsSearchProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {
    getEventNameFromSearchObject,
    getDivisionFromSearchObject,
    getVenueFromSearchObject,
    getCostCenterFromSearchObject,
    getShowFromSearchObject,
    getEventCategoryFromSearchObject,
    getMarketingLabelFromSearchObject,
    getBusinessPartnerIdFromSearchObject
  } = useGetFieldFromSearchObject()
  const {divisions} = useGetLightweightDivisions()
  const {costCenters} = useGetLightweightCostCenters()
  const {clientShows} = useGetLightweightClientShows({
    paginationInput: {offset: 0, limit: 200}
  })
  const {eventCategories} = useGetLightweightEventCategories()
  const {marketingLabels} = useGetLightweightMarketingLabels()
  const {data: businessPartnersData} = useQuery<
    LightweightBusinessPartnersQuery,
    LightweightBusinessPartnersQueryVariables
  >(LIGHTWEIGHT_BUSINESS_PARTNERS, {
    variables: {
      paginationInput: {offset: 0, limit: 100},
      filter: {
        categories: [
          BusinessPartnerCategory.Licensing,
          BusinessPartnerCategory.MovieDistributor,
          BusinessPartnerCategory.Agency
        ],
        states: [BusinessPartnerState.Active, BusinessPartnerState.Inactive]
      }
    },
    fetchPolicy: 'network-only',
    skip: !P([PermissionCode.ReadBusinessPartners])
  })
  const {localeCode} = useLocale()
  const translatedShowsTitle = clientShows.map((show) => ({
    id: show.id,
    title:
      show.translations.find(
        (translation) => translation.localeCode === localeCode
      )?.title ||
      show?.originalTitle ||
      show.translations[0]?.title ||
      t<string>('Title not available')
  }))
  const {venues} = useGetFilterEventVenues()

  const divisionSelectOptions: ISelectOption<number>[] = divisions.map(
    (division) => ({
      id: division.id,
      label: division.name
    })
  )
  const venueSelectOptions: ISelectOption<number>[] = venues.map((venue) => ({
    id: venue.id,
    label: venue.name
  }))

  const costCentersSelectOptions: ISelectOption<number>[] = costCenters.map(
    (costCenter) => ({
      id: costCenter.id,
      label: costCenter.name
    })
  )

  const showsSelectOptions: ISelectOption<number>[] = translatedShowsTitle.map(
    (show) => ({
      id: show.id,
      label: show.title
    })
  )

  const eventCategorySelectOptions: ISelectOption<number>[] =
    eventCategories.map(({id, name}) => ({
      id,
      label: name
    }))

  const marketingLabelSelectOptions: ISelectOption<number>[] =
    marketingLabels.map(({id, name}) => ({
      id,
      label: name
    }))

  const businessPartnerSelectOptions: ISelectOption<number>[] = (
    businessPartnersData?.businessPartners.items || []
  ).map(({id, companyName}) => ({id, label: companyName}))

  const mapSearchObjectToInputText =
    useCombineStringifySearchObjectFunctions<EventsStatisticsFilterInput>(
      getEventNameFromSearchObject,
      (filter) => getDivisionFromSearchObject(filter, divisions),
      (filter) => getVenueFromSearchObject(filter, venues),
      (filter) => getCostCenterFromSearchObject(filter, costCenters),
      (filter) => getShowFromSearchObject(filter, translatedShowsTitle),
      (filter) => getEventCategoryFromSearchObject(filter, eventCategories),
      (filter) => getMarketingLabelFromSearchObject(filter, marketingLabels),
      (filter) =>
        getBusinessPartnerIdFromSearchObject(
          filter,
          businessPartnersData?.businessPartners.items || []
        )
    )
  return (
    <Search
      storageKey="EVENTS_STATISTICS"
      placeholder={t('Search for event')}
      onChange={onFilterChange}
      mapInputTextToSearchObject={mapEventNameToFilter}
      mapSearchObjectToInputText={mapSearchObjectToInputText}
      defaultSearchObject={DEFAULT_EVENT_STATISTICS_FILTER_INPUT}
      renderAdvancedSearch={({
        isAdvancedSubmitDisabled,
        onAdvancedSearchSubmit,
        advancedSearchObject,
        setAdvancedSearchObject
      }) => (
        <AdvancedSearchBase
          isSubmitDisabled={isAdvancedSubmitDisabled}
          onSubmit={onAdvancedSearchSubmit}
        >
          <AdvancedSearchTextRow
            label={t('Event name')}
            placeholder={t('Enter event name')}
            setAdvancedSearchObject={setAdvancedSearchObject}
            advancedSearchObject={advancedSearchObject}
            mapTextToSearchObject={mapEventNameToFilter}
            value={advancedSearchObject.eventName || undefined}
          />
          <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
            label={t('Division')}
            value={advancedSearchObject.divisionId || undefined}
            options={divisionSelectOptions}
            mapSelectValueToSearchObject={mapDivisionIdToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
            label={t('Venue')}
            value={advancedSearchObject.venueId || undefined}
            options={venueSelectOptions}
            mapSelectValueToSearchObject={mapVenueIdToFilter}
            setSearchObject={setAdvancedSearchObject}
            searchObject={advancedSearchObject}
          />
          {P([PermissionCode.ReadCostCenters]) && (
            <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
              label={t('Cost center')}
              value={advancedSearchObject.costCenterId || undefined}
              options={costCentersSelectOptions}
              mapSelectValueToSearchObject={mapCostCenterIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadClientShows]) && (
            <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
              label={t('Show')}
              value={advancedSearchObject.showId || undefined}
              options={showsSelectOptions}
              mapSelectValueToSearchObject={mapShowIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadEventCategories]) && (
            <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
              label={t('Event category')}
              value={advancedSearchObject.eventCategoryId || undefined}
              options={eventCategorySelectOptions}
              mapSelectValueToSearchObject={mapEventCategoryIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadMarketingLabels]) && (
            <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
              label={t('Marketing label')}
              value={advancedSearchObject.marketingLabelId || undefined}
              options={marketingLabelSelectOptions}
              mapSelectValueToSearchObject={mapMarketingLabelIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
          {P([PermissionCode.ReadBusinessPartners]) && (
            <AdvancedSearchSelectRow<EventsStatisticsFilterInput, number>
              label={t('Business partner')}
              value={
                advancedSearchObject.businessPartnerIds
                  ? advancedSearchObject.businessPartnerIds[0]
                  : undefined
              }
              options={businessPartnerSelectOptions}
              mapSelectValueToSearchObject={mapBusinessPartnerIdToFilter}
              setSearchObject={setAdvancedSearchObject}
              searchObject={advancedSearchObject}
            />
          )}
        </AdvancedSearchBase>
      )}
    />
  )
}
