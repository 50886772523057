import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Box, Button, IconButton, Typography} from '@mui/material'
import {
  gridClasses,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {PermissionCode, ProductQuery} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'
import {routeTo} from '../../../../../utils/routes'
import {EntityStateChip} from '../../../../common'
import {
  DataGridTable,
  useDateTimeFormatter,
  useEffectiveClientPriceFormatter,
  usePercentageFormatter
} from '../../../../common/DataGridTable'
import {COLOR_CONF} from '../../../../constants'
import {useGetLightweightDivisions} from '../../graphql'

const AvailabilityRenderer = ({state}: {state: boolean}) => {
  const {t} = useTranslation()
  return (
    <EntityStateChip
      colorConf={state ? COLOR_CONF.GREEN : COLOR_CONF.RED}
      label={state ? t('Enabled') : t('Disabled')}
    />
  )
}

const IconCellRenderer = ({
  productId,
  divisionId
}: {
  productId: number
  divisionId: number
}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.products.pricing(productId, divisionId)),
    [divisionId, history, productId]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <ChevronRightIcon />
    </IconButton>
  )
}

interface IPricesSectionProps {
  id: string
  product: ProductQuery['product']
}

export const PricesSection: React.FC<IPricesSectionProps> = ({
  id,
  product
}: IPricesSectionProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {divisions} = useGetLightweightDivisions()
  const effectiveClientPriceFormatter = useEffectiveClientPriceFormatter(false)
  const percentageFormatter = usePercentageFormatter(0)
  const dateTimeFormatter = useDateTimeFormatter()
  const tableData = divisions.map((division) => {
    const productAvailability = product.productAvailability.find(
      (pa) => pa.division.id === division.id
    )
    const pricings = product.pricings.find((p) => p.division.id === division.id)
    return {
      divisionId: division.id,
      divisionName: division.name,
      productId: product.id,
      availability: {
        retail: productAvailability?.isAvailableOnRetailChannel || false,
        eCommerce: productAvailability?.isAvailableOnECommerceChannel || false
      },
      currentPrice: {
        retailPrice: pricings?.activePricing?.retailPrice,
        retailVatRate: pricings?.activePricing?.retailVatRate,
        eCommercePrice: pricings?.activePricing?.eCommercePrice,
        eCommerceVatRate: pricings?.activePricing?.eCommerceVatRate,
        validFrom: pricings?.activePricing?.startsAt
      },
      followingPrice: {
        retailPrice: pricings?.futurePricing?.retailPrice,
        retailVatRate: pricings?.futurePricing?.retailVatRate,
        eCommercePrice: pricings?.futurePricing?.eCommercePrice,
        eCommerceVatRate: pricings?.futurePricing?.eCommerceVatRate,
        validFrom: pricings?.futurePricing?.startsAt
      }
    }
  })
  const history = useHistory()
  const handleChangeAvailabilityButtonClick = useCallback(
    () => history.push(routeTo.admin.products.availability(product.id)),
    [history, product.id]
  )
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Division'),
        field: 'divisionName',
        minWidth: 150
      },
      {
        headerName: t('Retail'),
        field: 'availability.retail',
        valueGetter: (params) => params.row.availability.retail,
        renderCell: function renderer(
          params: GridRenderCellParams<{value: boolean}>
        ) {
          return <AvailabilityRenderer state={params.value} />
        },
        minWidth: 120
      },
      {
        headerName: t('Ecommerce'),
        field: 'availability.eCommerce',
        valueGetter: (params) => params.row.availability.eCommerce,
        renderCell: function renderer(
          params: GridRenderCellParams<{value: boolean}>
        ) {
          return <AvailabilityRenderer state={params.value} />
        },
        minWidth: 120
      },
      {
        headerName: t('Retail price'),
        field: 'currentPrice.retailPrice',
        valueGetter: (params) => params.row.currentPrice.retailPrice,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Retail VAT rate'),
        field: 'currentPrice.retailVatRate',
        valueGetter: (params) => params.row.currentPrice.retailVatRate,
        valueFormatter: percentageFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Ecommerce price'),
        field: 'currentPrice.eCommercePrice',
        valueGetter: (params) => params.row.currentPrice.eCommercePrice,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Ecommerce VAT rate'),
        field: 'currentPrice.eCommerceVatRate',
        valueGetter: (params) => params.row.currentPrice.eCommerceVatRate,
        valueFormatter: percentageFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 170
      },
      {
        headerName: t('Price valid from'),
        field: 'currentPrice.validFrom',
        valueGetter: (params) => params.row.currentPrice.validFrom,
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: t('Retail price'),
        field: 'followingPrice.retailPrice',
        valueGetter: (params) => params.row.followingPrice.retailPrice,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Retail VAT rate'),
        field: 'followingPrice.retailVatRate',
        valueGetter: (params) => params.row.followingPrice.retailVatRate,
        valueFormatter: percentageFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Ecommerce price'),
        field: 'followingPrice.eCommercePrice',
        valueGetter: (params) => params.row.followingPrice.eCommercePrice,
        valueFormatter: effectiveClientPriceFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150
      },
      {
        headerName: t('Ecommerce VAT rate'),
        field: 'followingPrice.eCommerceVatRate',
        valueGetter: (params) => params.row.followingPrice.eCommerceVatRate,
        valueFormatter: percentageFormatter,
        align: 'right',
        headerAlign: 'right',
        minWidth: 170
      },
      {
        headerName: t('Price valid from'),
        field: 'followingPrice.validFrom',
        valueGetter: (params) => params.row.followingPrice.validFrom,
        valueFormatter: dateTimeFormatter,
        minWidth: 200
      },
      {
        headerName: '',
        field: 'arrow',
        valueGetter: (params) => params.row.productId,
        renderCell: function renderer(params: GridRenderCellParams) {
          return (
            <IconCellRenderer
              productId={params.value}
              divisionId={params.row.divisionId}
            />
          )
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        width: 48
      }
    ],
    [dateTimeFormatter, effectiveClientPriceFormatter, percentageFormatter, t]
  )
  return (
    <Box id={id}>
      <Box
        sx={{
          pb: 1,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="subtitle1">{t('Prices')}</Typography>
        {P([PermissionCode.UpsertProductAvailability]) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleChangeAvailabilityButtonClick}
          >
            {t('Change availability')}
          </Button>
        )}
      </Box>
      <Box sx={{height: '100%', width: '100%'}}>
        <DataGridTable
          sx={{
            [`& .${gridClasses.withBorderColor}`]: {
              borderColor: 'divider'
            },
            [`& .${gridClasses.columnSeparator}`]: {
              color: 'divider'
            }
          }}
          getRowId={(row) => row.divisionId}
          autoHeight
          columns={columns}
          rows={tableData}
          disableColumnMenu
          disableRowSelectionOnClick
          experimentalFeatures={{columnGrouping: true}}
          columnHeaderHeight={32}
          pagination={false}
          hideFooter
          initialState={{
            pinnedColumns: {left: ['divisionName'], right: ['arrow']}
          }}
          columnGroupingModel={[
            {
              groupId: t('Availability'),
              children: [
                {field: 'availability.retail'},
                {field: 'availability.eCommerce'}
              ]
            },
            {
              groupId: t('Current price'),
              children: [
                {field: 'currentPrice.retailPrice'},
                {field: 'currentPrice.retailVatRate'},
                {field: 'currentPrice.eCommercePrice'},
                {field: 'currentPrice.eCommerceVatRate'},
                {field: 'currentPrice.validFrom'}
              ],
              headerAlign: 'left'
            },
            {
              groupId: t('Following price'),
              children: [
                {field: 'followingPrice.retailPrice'},
                {field: 'followingPrice.retailVatRate'},
                {field: 'followingPrice.eCommercePrice'},
                {field: 'followingPrice.eCommerceVatRate'},
                {field: 'followingPrice.validFrom'}
              ],
              headerAlign: 'left'
            }
          ]}
          localeText={{noRowsLabel: t('No data to show')}}
        />
      </Box>
    </Box>
  )
}
