import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Currency,
  DetailEventPropertiesFragment,
  EventPricing
} from '../../../../__generated__/schema'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {useTranslateEffectiveClientPrice} from '../../../../hooks/translateCurrencies'

export const useGetPriceString = () => {
  const translatePrice = useTranslateEffectiveClientPrice()
  return useCallback(
    (pricing: Pick<EventPricing, 'minPrice' | 'maxPrice'>) =>
      pricing.minPrice === pricing.maxPrice
        ? translatePrice(pricing.minPrice)
        : `${translatePrice(pricing.minPrice)} - ${translatePrice(
            pricing.maxPrice
          )}`,
    [translatePrice]
  )
}

export const useGetPriceSecondaryText = () => {
  const {t} = useTranslation()
  const {formatTime, formatDateNumeric} = useDateTimeFormatters()
  const getPriceString = useGetPriceString()
  return ({
    pricings,
    activePricing
  }: {
    pricings: DetailEventPropertiesFragment['pricings']
    activePricing: DetailEventPropertiesFragment['activePricing']
    currency?: Currency
  }) => {
    const indexOfActivePricing = pricings.findIndex(
      (pricing) => pricing.id === activePricing.id
    )
    if (indexOfActivePricing === pricings.length - 1) {
      return t('Current {{currentPrice}}', {
        currentPrice: getPriceString(activePricing)
      })
    }
    const followingPricing = pricings[indexOfActivePricing + 1]
    const followingDatetime = new Date(followingPricing.startsAt)
    return t(
      'Current {{currentPrice}} {{pipe}} Following {{followingPrice}} ({{followingDate}}, {{followingTime}})',
      {
        currentPrice: getPriceString(activePricing),
        followingPrice: getPriceString(followingPricing),
        followingDate: formatDateNumeric(followingDatetime),
        followingTime: formatTime(followingDatetime),
        pipe: '|',
        interpolation: {escapeValue: false}
      }
    )
  }
}
