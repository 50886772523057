import {Badge, badgeClasses, Box, Typography} from '@mui/material'
import Paper from '@mui/material/Paper'
import {styled} from '@mui/system'
import React from 'react'
import {isValidHexColor} from '../../utils/formsValidations'

type TileSize = 'small' | 'medium' | 'large' | number

const getWidth = (size: TileSize) => {
  if (typeof size === 'number') {
    return size
  }
  switch (size) {
    case 'large':
      return 344
    case 'medium':
      return 256
    case 'small':
    default:
      return 208
  }
}

const StyledImg = styled('img')(() => ({
  width: 96,
  height: '100%',
  objectFit: 'cover',
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  flexShrink: 0
}))

interface IProductTileProps {
  size?: TileSize
  imageUrl?: string
  name: string
  formattedPrice: string
  badgeContent?: number
  badgeColor?: string
  onClick?: () => void
  onDoubleClick?: () => void
  disabled?: boolean
}

export const ProductTile: React.FC<IProductTileProps> = ({
  size = 'small',
  imageUrl,
  name,
  formattedPrice,
  badgeContent,
  badgeColor,
  onClick,
  onDoubleClick,
  disabled
}: IProductTileProps) => {
  return (
    <Badge
      sx={{
        [`& .${badgeClasses.badge}`]: {
          backgroundColor: isValidHexColor(badgeColor)
            ? badgeColor
            : 'primary.main',
          color: 'background.paper'
        }
      }}
      badgeContent={badgeContent}
    >
      <Paper
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        variant="outlined"
        sx={{
          height: 72,
          width: getWidth(size),
          display: 'flex',
          cursor: onClick || onDoubleClick ? 'pointer' : 'normal',
          opacity: (theme) =>
            disabled ? theme.palette.action.disabledOpacity : undefined,
          pointerEvents: disabled ? 'none' : 'auto',
          '&:hover': {
            backgroundColor: 'grey.300'
          }
        }}
      >
        {imageUrl && <StyledImg alt={name} src={imageUrl} />}
        <Box
          sx={{
            p: 0.75,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography
            variant={imageUrl ? 'caption' : 'body2'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: imageUrl ? '3' : '2',
              WebkitBoxOrient: 'vertical',
              lineHeight: imageUrl ? 1.15 : undefined
            }}
          >
            {name}
          </Typography>
          <Typography variant="overline" sx={{alignSelf: 'flex-end'}}>
            {formattedPrice}
          </Typography>
        </Box>
      </Paper>
    </Badge>
  )
}
