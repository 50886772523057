import {useTranslation} from 'react-i18next'
import {durationToJSON} from '../components/pages/admin/utils'

export const useTranslateDuration = () => {
  const {t} = useTranslation()
  return (duration: string) => {
    const _duration = durationToJSON(duration)
    if (_duration.hours) {
      return t('{{count}} hours', {count: _duration.hours})
    }
    if (_duration.days) {
      return t('{{count}} days', {count: _duration.days})
    }
    return ''
  }
}
