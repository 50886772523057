import {useTranslation} from 'react-i18next'
import {TicketItemCheckStatus} from '../__generated__/schema'

export const useTranslateTicketItemCheckStatus = () => {
  const {t} = useTranslation()
  const translatedCheckStatus: Record<TicketItemCheckStatus, string> = {
    [TicketItemCheckStatus.CheckedIn]: t<string>('Checked in'),
    [TicketItemCheckStatus.CheckedOut]: t<string>('Checked out'),
    [TicketItemCheckStatus.Unchecked]: t<string>('Unchecked')
  }
  return (status: TicketItemCheckStatus) => translatedCheckStatus[status]
}
