import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import SendIcon from '@mui/icons-material/Send'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import validator from 'validator'
import {PermissionCode} from '../../../../../__generated__/schema'
import {useEnsurePermissions} from '../../../../../utils/auth'

import {CancelButton, SaveButton} from '../../../../common/Buttons'
import {Menu, MenuItem, useMenu} from '../../../../common/Menu'
import {BRIEF_LEAD_FORM_ID} from './BriefLeadForm'
import {CoreFooter} from './CoreFooter'

interface IReservationFooterProps {
  onCreateAndSendByEmailButtonClick: () => Promise<void>
  isLeadReservationEmailEnabled: boolean
  leadEmail?: string | null
}

export const ReservationFooter: React.FC<IReservationFooterProps> = ({
  onCreateAndSendByEmailButtonClick,
  isLeadReservationEmailEnabled,
  leadEmail
}: IReservationFooterProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {anchorEl, openMenu, closeMenu} = useMenu()
  const handleCreateAndSendByEmailButtonClick = useCallback(async () => {
    await onCreateAndSendByEmailButtonClick()
    closeMenu()
  }, [closeMenu, onCreateAndSendByEmailButtonClick])
  return (
    <CoreFooter>
      <Menu onClose={closeMenu} anchorEl={anchorEl}>
        {P([PermissionCode.SendReservationConfirmationEmail]) &&
          isLeadReservationEmailEnabled && (
            <MenuItem
              label={t('Create and send by email')}
              description={
                leadEmail
                  ? !validator.isEmail(leadEmail)
                    ? t('Invalid email address')
                    : leadEmail
                  : t('Type email first')
              }
              icon={<SendIcon />}
              onClick={handleCreateAndSendByEmailButtonClick}
              isDisabled={!leadEmail || !validator.isEmail(leadEmail)}
            />
          )}
      </Menu>
      <CancelButton startIcon={<ExpandLessIcon />} onClick={openMenu}>
        {t('More')}
      </CancelButton>
      <SaveButton type="submit" form={BRIEF_LEAD_FORM_ID}>
        {t('Create')}
      </SaveButton>
    </CoreFooter>
  )
}
