import {Button, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../theme'
import {OutlinedInputWithCancelAdornment} from '../../../common/OutlinedInputWithCancelAdornment'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    maxWidth: 800,
    minHeight: 68,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rightActions: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    minWidth: 216
  }
}))

interface ICheckDiscountCodeItemProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onCheckButtonClick: () => void
  onCancel: () => void
  code: string
  errorMessage?: string | null
}

export const CheckDiscountCodeItem: React.FC<ICheckDiscountCodeItemProps> = ({
  onChange,
  onCheckButtonClick,
  onCancel,
  code,
  errorMessage
}: ICheckDiscountCodeItemProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Paper
      variant="outlined"
      classes={{
        root: classes.root
      }}
    >
      <Typography variant="body2">{t('Discount code')}</Typography>
      <div className={classes.rightActions}>
        <OutlinedInputWithCancelAdornment
          onCancelClick={onCancel}
          errorMessage={errorMessage}
          inputProps={{
            value: code,
            onChange,
            placeholder: t('Enter code')
          }}
          className={classes.input}
        />
        <Button
          color="primary"
          onClick={onCheckButtonClick}
          disabled={!code || !!errorMessage}
        >
          {t('Verify')}
        </Button>
      </div>
    </Paper>
  )
}
