import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {Box, IconButton, Typography} from '@mui/material'
import Chip from '@mui/material/Chip'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  CashDeskEventFragment,
  Currency,
  LocaleCode
} from '../../../../__generated__/schema'
import {ReactComponent as PinOffIcon} from '../../../../assets/pinOff.svg'
import {useTranslateAgeClassificationAbbreviation} from '../../../../hooks/translateAgeClassification'
import {
  useTranslateShowFormatAbbreviation,
  useTranslateShowSoundMixAbbreviation,
  useTranslateShowVersionAbbreviation
} from '../../../../hooks/translateDistributions'
import {useDateTimeFormatters} from '../../../../utils/formatting'
import {Tooltip} from '../../../common'
import {PinnedEventPick} from '../types'
import {getIsAlmostSoldOut, isCashDeskEventFragment} from './utils'

interface IMobileCashdeskEventItemProps {
  event: CashDeskEventFragment | PinnedEventPick
  onEventClick?: (eventId: number) => void
  onEventDetailClick?: (eventId: number) => void
  currency: Currency
  clientLocaleCode: LocaleCode
}

interface IBaseItemProps extends IMobileCashdeskEventItemProps {
  unPinButton?: boolean
}

const BaseItem: React.FC<IBaseItemProps> = ({
  event,
  onEventClick,
  clientLocaleCode,
  unPinButton,
  onEventDetailClick
}: IBaseItemProps) => {
  const {t} = useTranslation()
  const {formatTime} = useDateTimeFormatters()
  const eventNameInClientLocale =
    event.names[clientLocaleCode] || Object.values(event.names)[0]
  const translateAgeClassificationAbbreviation =
    useTranslateAgeClassificationAbbreviation()
  const translateShowFormatAbbreviation = useTranslateShowFormatAbbreviation()
  const translateShowVersionAbbreviation = useTranslateShowVersionAbbreviation()
  const translateShowSoundMixAbbreviation =
    useTranslateShowSoundMixAbbreviation()
  const isAlmostSoldOut = isCashDeskEventFragment(event)
    ? getIsAlmostSoldOut(event)
    : undefined
  const isSoldOut = isCashDeskEventFragment(event)
    ? event.availableSeatsCount === 0
    : undefined
  return (
    <Box
      sx={{
        px: 2,
        py: 1.5,
        display: 'flex',
        alignItems: unPinButton ? 'center' : 'flex-end',
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
      onClick={() => onEventClick?.(event.id)}
    >
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
        <Typography
          variant="subtitle2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {[formatTime(new Date(event.startsAt)), eventNameInClientLocale].join(
            ' • '
          )}
        </Typography>
        <Typography variant="overline" color="textSecondary">
          {event.auditorium.name}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="overline" color="textSecondary">
            {[
              event.formatCode &&
                translateShowFormatAbbreviation(event.formatCode),
              event.soundMixCode &&
                translateShowSoundMixAbbreviation(event.soundMixCode),
              event.versionCode &&
                translateShowVersionAbbreviation(event.versionCode),
              event.ageClassificationCode &&
                translateAgeClassificationAbbreviation(
                  event.ageClassificationCode
                )
            ]
              .filter(Boolean)
              .join(' • ')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
        {unPinButton ? (
          <Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                if (onEventDetailClick) {
                  onEventDetailClick(event.id)
                }
              }}
            >
              <PinOffIcon
                style={{width: '1em', height: '1em', fontSize: '1.5rem'}}
              />
            </IconButton>
          </Box>
        ) : (
          <>
            {isAlmostSoldOut && (
              <Chip
                sx={{color: 'warning.main', backgroundColor: '#FCF3D7'}}
                size="small"
                label={t('Almost sold out')}
              />
            )}
            {isSoldOut && (
              <Chip
                sx={{color: 'error.main', backgroundColor: '#FEE4E2'}}
                size="small"
                label={t('Sold out')}
              />
            )}
            {!isSoldOut && isCashDeskEventFragment(event) && (
              <Tooltip title={t('Available')}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                  <PersonOutlineIcon fontSize="small" />
                  <Typography variant="overline">
                    {event.availableSeatsCount}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export const MobileCashdeskEventItem: React.FC<IMobileCashdeskEventItemProps> =
  (props: IMobileCashdeskEventItemProps) => <BaseItem {...props} />

export const MobileCashdeskPinnedEventItem: React.FC<IMobileCashdeskEventItemProps> =
  (props: IMobileCashdeskEventItemProps) => <BaseItem {...props} unPinButton />
