import {Checkbox, FormControlLabel} from '@mui/material'
import React, {useCallback, useState} from 'react'

interface ICheckboxWithLabelProps {
  label: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const useCheckboxWithLabel = (
  defaultChecked = false
): [boolean, ICheckboxWithLabelProps['onChange']] => {
  const [value, setValue] = useState(defaultChecked)
  const handleChange = useCallback((e) => {
    setValue(e.target.checked)
  }, [])
  return [value, handleChange]
}

export const CheckboxWithLabel: React.FC<ICheckboxWithLabelProps> = ({
  label,
  onChange,
  checked
}: ICheckboxWithLabelProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox color="primary" onChange={onChange} checked={checked} />
      }
      label={label}
    />
  )
}
