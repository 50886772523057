import {makeStyles} from '@mui/styles'
import React from 'react'
import {Theme} from '../../theme'
import {DrawerTemplate, IDrawerTemplateProps} from './DrawerUtils'

import {StaticSideNavigationList} from './SideNavigation'

const mediaMinWidthToDisplaySideNavigation = `(min-width: 720px)`

const useStyles = makeStyles<Theme>((theme) => ({
  drawerGrid: {
    display: 'grid',
    minHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    padding: theme.spacing(0, 3, 3, 3),
    [`@media ${mediaMinWidthToDisplaySideNavigation}`]: {
      gridTemplateColumns: '226px 1fr',
      padding: theme.spacing(0, 3, 3, 0)
    }
  },
  sideNavigationBox: {
    display: 'none',
    [`@media ${mediaMinWidthToDisplaySideNavigation}`]: {
      display: 'block'
    }
  },
  staticSideNavigationList: {
    top: 0,
    position: 'sticky'
  }
}))
interface IDrawerTemplateWithSideNavigationProps {
  DrawerTemplateProps: Omit<IDrawerTemplateProps, 'children'>
  navigationItems: Record<string, {id: string; label: string}>
  children: React.ReactNode
}

export const DrawerTemplateWithSideNavigation: React.FC<IDrawerTemplateWithSideNavigationProps> =
  ({
    DrawerTemplateProps,
    navigationItems,
    children
  }: IDrawerTemplateWithSideNavigationProps) => {
    const classes = useStyles()
    return (
      <DrawerTemplate {...DrawerTemplateProps}>
        <div className={classes.drawerGrid}>
          <div className={classes.sideNavigationBox}>
            <StaticSideNavigationList
              className={classes.staticSideNavigationList}
              items={navigationItems}
            />
          </div>
          <div>{children}</div>
        </div>
      </DrawerTemplate>
    )
  }
