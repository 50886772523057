import {
  Checkbox,
  CheckboxProps,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import React from 'react'

interface ICustomerGroupRowListItemProps {
  isCheckBoxHidden?: boolean
  checkBoxProps?: CheckboxProps
  name: string
  description?: string | null
}

export const CustomerGroupRowListItem: React.FC<ICustomerGroupRowListItemProps> =
  ({
    isCheckBoxHidden,
    checkBoxProps,
    name,
    description
  }: ICustomerGroupRowListItemProps) => (
    <ListItem
      sx={(theme) => ({
        minHeight: 40,
        p: 0,
        borderBottom: `solid ${theme.palette.divider} 1px`,
        '&:last-child': {
          borderBottom: 0
        },
        opacity: isCheckBoxHidden
          ? theme.palette.action.disabledOpacity
          : undefined,
        pointerEvents: isCheckBoxHidden ? 'none' : 'auto'
      })}
    >
      {!isCheckBoxHidden && (
        <ListItemIcon>
          <Checkbox {...checkBoxProps} color="primary" />
        </ListItemIcon>
      )}
      <ListItemText
        sx={{overflowWrap: 'anywhere'}}
        inset={isCheckBoxHidden}
        primary={name}
        primaryTypographyProps={{variant: 'subtitle2'}}
        secondary={description}
        secondaryTypographyProps={{variant: 'caption'}}
      />
    </ListItem>
  )
