import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useCallback, useMemo} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Theme} from '../../../../../theme'
import {UncontrolledFormSelect} from '../../../../form/UncontrolledFormSelect'

import {FormChipsGroupSelect} from '../../../../visual'
import {useEventCalendarSettings} from '../context/eventCalendarSettingsProvider'
import {
  CalendarSettingsFieldName,
  DayOfWeek,
  ICalendarSettingsForm,
  ScrollTime,
  SlotDuration
} from './types'

const useStyles = makeStyles<Theme>((theme) => ({
  dialogContentRoot: {
    paddingTop: 0
  },
  label: {
    paddingBottom: theme.spacing(2)
  },
  venueNames: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  densitySettings: {
    paddingBottom: theme.spacing(4)
  }
}))

const SETTINGS_DIALOG_ID = 'settingsDialog'

interface ICalendarSettingsDialogProps {
  onClose: () => void
}

export const CalendarSettingsDialog: React.FC<ICalendarSettingsDialogProps> = ({
  onClose
}: ICalendarSettingsDialogProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const [calendarSettings, setCalendarSettings] = useEventCalendarSettings()

  const config = {
    defaultValues: calendarSettings
  }
  const {register, watch, unregister, errors, setValue, handleSubmit} =
    useForm<ICalendarSettingsForm>(config)

  const _handleSubmit = useCallback(
    (data: ICalendarSettingsForm) => {
      setCalendarSettings(data)
      onClose()
    },
    [onClose, setCalendarSettings]
  )

  const firstDayOfWeekItems = useMemo(
    () => [
      {value: DayOfWeek.Mon, label: t('Mon')},
      {value: DayOfWeek.Tue, label: t('Tue')},
      {value: DayOfWeek.Wed, label: t('Wed')},
      {value: DayOfWeek.Thu, label: t('Thu')},
      {value: DayOfWeek.Fri, label: t('Fri')},
      {value: DayOfWeek.Sat, label: t('Sat')},
      {value: DayOfWeek.Sun, label: t('Sun')}
    ],
    [t]
  )

  const venueNameItems = useMemo(
    () => [
      {value: true, label: t('Shorten')},
      {value: false, label: t('Full names')}
    ],
    [t]
  )

  const densitySettingItems = useMemo(
    () => [
      {value: SlotDuration.Compact, label: t('Compact')},
      {value: SlotDuration.Standard, label: t('Standard')},
      {value: SlotDuration.Wide, label: t('Wide')},
      {value: SlotDuration.UltraWide, label: t('Ultra wide')}
    ],
    [t]
  )
  return (
    <Dialog open onClose={onClose}>
      <form id={SETTINGS_DIALOG_ID} onSubmit={handleSubmit(_handleSubmit)}>
        <DialogTitle>{t('Calendar settings')}</DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContentRoot
          }}
        >
          <FormChipsGroupSelect<ICalendarSettingsForm>
            items={firstDayOfWeekItems}
            register={register}
            unregister={unregister}
            errors={errors}
            setValue={setValue}
            name={CalendarSettingsFieldName.FIRST_DAY_OF_WEEK}
            isRequired
            label={t('Choose first day of week')}
            watch={watch}
          />
          <div className={classes.venueNames}>
            <FormChipsGroupSelect<ICalendarSettingsForm, boolean>
              items={venueNameItems}
              register={register}
              unregister={unregister}
              errors={errors}
              setValue={setValue}
              name={CalendarSettingsFieldName.SHORTEN_VENUE_NAMES}
              isRequired
              label={t('Venues names')}
              watch={watch}
            />
          </div>
          <div className={classes.densitySettings}>
            <FormChipsGroupSelect<ICalendarSettingsForm, string>
              items={densitySettingItems}
              register={register}
              unregister={unregister}
              errors={errors}
              setValue={setValue}
              name={CalendarSettingsFieldName.DENSITY_SETTINGS}
              isRequired
              label={t('Density settings')}
              watch={watch}
            />
          </div>
          <UncontrolledFormSelect<ICalendarSettingsForm>
            register={register}
            errors={errors}
            setValue={setValue}
            label={t('Scroll time')}
            watch={watch}
            name={CalendarSettingsFieldName.SCROLL_TIME}
            selectOptions={ScrollTime}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            form={SETTINGS_DIALOG_ID}
            color="primary"
            variant="contained"
          >
            {t('Apply')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
