import {ICoords} from '@attendio/shared-components'
import Konva from 'konva'
import _ from 'lodash'
import React, {useCallback, useState} from 'react'
import {Rect} from 'react-konva'
import {
  DOUBLE_CLICK_INTERVAL,
  SELECTION_FILL_COLOR,
  SELECTION_STROKE_COLOR,
  SELECTION_STROKE_DASH,
  SELECTION_STROKE_WIDTH
} from '../config'
import {cursorMap} from '../cursorMap'
import {useSelector} from '../redux'
import {
  canvasOriginSelector,
  canvasScaleSelector
} from '../redux/canvas/selectors'
import {DisplayMode} from '../redux/displayMode/reducer'
import {displayModeSelector} from '../redux/displayMode/selectors'
import {EditorMode} from '../redux/editorMode/reducer'
import {
  editorModeSelector,
  selectionModeSelector
} from '../redux/editorMode/selectors'
import {isCtrlActiveSelector} from '../redux/keyboardKeys/selectors'
import {
  presentObjectsSelector,
  priceAssignableObjectsIdsSelector,
  seatsIdsSelector
} from '../redux/objects/selectors'
import {IObjectsState} from '../redux/objects/types'
import {stageRefSelector} from '../redux/refs/selectors'
import {useSelectionActions} from '../redux/selection/actions'
import {IRectangle, SelectionMode} from '../types'
import {canvasObjectBoundingRect} from '../utils/boundingBox'
import {
  getChangeCursor,
  getMouseCoordsOnScreen,
  toCanvasCoords
} from '../utils/common'
import {EventLayer} from './EventLayer'

interface IRectangleSelectionProps {
  unselectedLayerRef: React.RefObject<Konva.Layer | undefined>
  selectedLayerRef: React.RefObject<Konva.Layer | undefined>
  selectedGroupRef: React.RefObject<Konva.Group | undefined>
}

const objectWithinFenceSelection = (
  selectionCoords: IRectangle,
  objectCoords: IRectangle
) => {
  return (
    selectionCoords.x <= objectCoords.x &&
    selectionCoords.x + selectionCoords.width >=
      objectCoords.x + objectCoords.width &&
    selectionCoords.y <= objectCoords.y &&
    selectionCoords.y + selectionCoords.height >=
      objectCoords.y + objectCoords.height
  )
}

const getBoundingRectCoords = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): IRectangle => ({
  x: Math.min(x1, x2),
  y: Math.min(y1, y2),
  width: Math.max(x1, x2) - Math.min(x1, x2),
  height: Math.max(y1, y2) - Math.min(y1, y2)
})

const findIdParam = (object: Konva.Node | null): string | null => {
  if (!object) return null
  const id = object.getAttr('id')
  return id || findIdParam(object.parent)
}

const getObjectIdsOnCoords = (
  coords: ICoords,
  selectedLayer: Konva.Layer,
  unselectedLayer: Konva.Layer
) => {
  const selectedObject = selectedLayer.getIntersection(coords)
  const unselectedObject = unselectedLayer.getIntersection(coords)
  return [findIdParam(selectedObject), findIdParam(unselectedObject)].filter(
    (id) => !!id
  )
}

const getObjectIdsOnPerimeter = (
  startCoordsOnScreen: ICoords,
  endCoordsOnScreen: ICoords,
  selectedLayer: Konva.Layer,
  unselectedLayer: Konva.Layer
) => {
  const objectsIdsOnPerimeter: Set<string> = new Set()

  const minX = Math.min(startCoordsOnScreen.x, endCoordsOnScreen.x)
  const maxX = Math.max(startCoordsOnScreen.x, endCoordsOnScreen.x)
  const minY = Math.min(startCoordsOnScreen.y, endCoordsOnScreen.y)
  const maxY = Math.max(startCoordsOnScreen.y, endCoordsOnScreen.y)

  for (let x = minX; x <= maxX; x++) {
    ;[
      ..._.compact(
        getObjectIdsOnCoords(
          {x, y: startCoordsOnScreen.y},
          selectedLayer,
          unselectedLayer
        )
      ),
      ..._.compact(
        getObjectIdsOnCoords(
          {x, y: endCoordsOnScreen.y},
          selectedLayer,
          unselectedLayer
        )
      )
    ].forEach((id) => objectsIdsOnPerimeter.add(id))
  }

  for (let y = minY; y <= maxY; y++) {
    ;[
      ..._.compact(
        getObjectIdsOnCoords(
          {x: startCoordsOnScreen.x, y},
          selectedLayer,
          unselectedLayer
        )
      ),
      ..._.compact(
        getObjectIdsOnCoords(
          {x: endCoordsOnScreen.x, y},
          selectedLayer,
          unselectedLayer
        )
      )
    ].forEach((id) => objectsIdsOnPerimeter.add(id))
  }

  return Array.from(objectsIdsOnPerimeter)
}

const getObjectIdsWithinSelection = (
  objects: IObjectsState,
  startCoordsOnScreen: ICoords,
  endCoordsOnScreen: ICoords,
  origin: ICoords,
  scale: number,
  selectionType: SelectionMode,
  unselectedLayerRef: React.RefObject<Konva.Layer | undefined>,
  selectedLayerRef: React.RefObject<Konva.Layer | undefined>
): Array<string> => {
  const selectedLayer = selectedLayerRef.current
  const unselectedLayer = unselectedLayerRef.current

  const startCoordsOnCanvas = toCanvasCoords({
    coords: startCoordsOnScreen,
    origin,
    scale
  })

  const endCoordsOnCanvas = toCanvasCoords({
    coords: endCoordsOnScreen,
    origin,
    scale
  })

  const selectionBoudingRectangle = getBoundingRectCoords(
    startCoordsOnCanvas.x,
    startCoordsOnCanvas.y,
    endCoordsOnCanvas.x,
    endCoordsOnCanvas.y
  )

  const objectIdsWithinFenceSelection = Object.values(objects)
    .filter((object) => {
      const objectBoundingBox = canvasObjectBoundingRect(object)

      if (objectBoundingBox) {
        return objectWithinFenceSelection(
          selectionBoudingRectangle,
          objectBoundingBox
        )
      } else {
        return false
      }
    })
    .map((object) => object.config.id)

  if (
    selectionType === SelectionMode.FENCE ||
    !selectedLayer ||
    !unselectedLayer
  ) {
    return objectIdsWithinFenceSelection
  }

  // TODO: this sometimes return duplicates (hard to debug), so as tmp workaround
  // it is wrapped inside uniq
  return _.uniq([
    ...getObjectIdsOnPerimeter(
      startCoordsOnScreen,
      endCoordsOnScreen,
      selectedLayer,
      unselectedLayer
    ),
    ...objectIdsWithinFenceSelection
  ])
}

let timeout: number | null = null

// TODO: can we divide this somehow nice?
const useManageRectangleSelection = (
  unselectedLayerRef: React.RefObject<Konva.Layer | undefined>,
  selectedGroupRef: React.RefObject<Konva.Group | undefined>,
  selectedLayerRef: React.RefObject<Konva.Layer | undefined>
) => {
  const [endCoordsOnScreen, setEndCoordsOnScreen] =
    useState<ICoords | null>(null)
  const [startCoordsOnScreen, setStartCoordsOnScreen] =
    useState<ICoords | null>(null)
  const [dragOnObject, setDragOnObject] = useState<any>(null)
  const [dragStarted, setDragStarted] = useState<boolean>(false)
  const [dragPerformed, setDragPerformed] = useState<boolean>(false)
  const {selectMultiple, setDoubleClick, unselectAll} = useSelectionActions()
  const origin = useSelector(canvasOriginSelector)
  const scale = useSelector(canvasScaleSelector)
  const {modeConfigs} = useSelector(editorModeSelector)
  const displayMode = useSelector(displayModeSelector)
  const isCtrlPressed = useSelector(isCtrlActiveSelector)
  const objects = useSelector(presentObjectsSelector)
  const priceAssignableObjectsIds = useSelector(
    priceAssignableObjectsIdsSelector
  )
  const seatIds = useSelector(seatsIdsSelector)
  const stageRef = useSelector(stageRefSelector)

  const changeCursor = getChangeCursor(stageRef ? stageRef.getStage() : null)

  const useGetClickHandlerHook = (onClick: Function) => {
    // Note: We need to use 'useState' for the following variables because
    // there are a couple of re-renders before handling 'click' event
    // (e.g. there are state changes on 'mousedown', 'mouseup').
    const [clickTime, setClickTime] = useState<number>(Date.now())
    const [clickTimeout, setClickTimeout] =
      useState<number | undefined>(undefined)

    const onSingleClick = (e: Konva.KonvaEventObject<MouseEvent>) =>
      onClick(e, false)

    const onDoubleClick = (e: Konva.KonvaEventObject<MouseEvent>) =>
      onClick(e, true)

    return (e: Konva.KonvaEventObject<MouseEvent>) => {
      // Note: we need to clone the event as during the "DOUBLE_CLICK_INTERVAL" the coords
      // may change (e.g. fast cursor move after click), which can result in wrong seats
      // being selected
      const _e = _.cloneDeep(e)
      if (Date.now() - clickTime < DOUBLE_CLICK_INTERVAL) {
        window.clearInterval(clickTimeout)
        onDoubleClick(_e)
      } else {
        setClickTimeout(
          window.setTimeout(() => onSingleClick(_e), DOUBLE_CLICK_INTERVAL)
        )
      }
      setClickTime(Date.now())
    }
  }

  const onClick = useCallback(
    // "doubleClick: boolean" is returned from our custom "useGetClickHandlerHook" function
    (e: Konva.KonvaEventObject<MouseEvent>, doubleClick: boolean) => {
      // Note: This extra check is here because Konva sometimes recognizes drag as a click.
      if (dragPerformed) return

      const coords = getMouseCoordsOnScreen(e)

      if (coords) {
        if (selectedLayerRef.current) {
          const object = selectedLayerRef.current.getIntersection(coords)

          changeCursor(
            object && isCtrlPressed
              ? cursorMap[EditorMode.SELECT].default
              : cursorMap[EditorMode.SELECT].overSelectedObject
          )

          if (object) {
            setDoubleClick(doubleClick)
            object.fire('click', e, true)
            return
          }
        }

        if (unselectedLayerRef.current) {
          const object = unselectedLayerRef.current.getIntersection(coords)

          changeCursor(
            object
              ? cursorMap[EditorMode.SELECT].overSelectedObject
              : cursorMap[EditorMode.SELECT].default
          )

          if (object) {
            setDoubleClick(doubleClick)
            object.fire('click', e, true)
            return
          }
        }

        unselectAll()
      }
    },
    [
      changeCursor,
      dragPerformed,
      isCtrlPressed,
      selectedLayerRef,
      setDoubleClick,
      unselectAll,
      unselectedLayerRef
    ]
  )

  const onMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const newCoords = getMouseCoordsOnScreen(e)
      setStartCoordsOnScreen(newCoords)
      setEndCoordsOnScreen(newCoords)

      if (newCoords && selectedLayerRef.current) {
        setDragOnObject(selectedLayerRef.current.getIntersection(newCoords))
      }
      setDragStarted(false)
    },
    [selectedLayerRef]
  )

  const onMouseUp = useCallback(() => {
    // NOTE: This should be run before onClick handler.
    setDragPerformed(
      !!startCoordsOnScreen &&
        !!endCoordsOnScreen &&
        (startCoordsOnScreen.x !== endCoordsOnScreen.x ||
          startCoordsOnScreen.y !== endCoordsOnScreen.y)
    )
    setStartCoordsOnScreen(null)
    setEndCoordsOnScreen(null)
  }, [endCoordsOnScreen, startCoordsOnScreen])

  const selectObjectsWithinSelection = useCallback(() => {
    if (
      selectedLayerRef.current &&
      unselectedLayerRef.current &&
      startCoordsOnScreen &&
      endCoordsOnScreen
    ) {
      let objectsToSelect = getObjectIdsWithinSelection(
        objects,
        startCoordsOnScreen,
        endCoordsOnScreen,
        origin,
        scale,
        modeConfigs.select.mode,
        unselectedLayerRef,
        selectedLayerRef
      )

      if (displayMode === DisplayMode.CASH) {
        objectsToSelect = objectsToSelect.filter((id) => seatIds.includes(id))
      }
      if (displayMode === DisplayMode.PRICING) {
        objectsToSelect = objectsToSelect.filter((id) =>
          priceAssignableObjectsIds.includes(id)
        )
      }

      selectMultiple(objectsToSelect)
    }
  }, [
    displayMode,
    endCoordsOnScreen,
    modeConfigs.select.mode,
    objects,
    origin,
    priceAssignableObjectsIds,
    scale,
    seatIds,
    selectMultiple,
    selectedLayerRef,
    startCoordsOnScreen,
    unselectedLayerRef
  ])

  const onSelection = useCallback(() => {
    timeout && window.clearTimeout(timeout)
    timeout = window.setTimeout(selectObjectsWithinSelection, 300)
  }, [selectObjectsWithinSelection])

  const handleCursorForSelectedObject = useCallback(
    (coords: ICoords | null) => {
      if (!coords) return

      const selectedLayer = selectedLayerRef.current
      if (!selectedLayer) return

      const object = selectedLayer.getIntersection(coords)

      changeCursor(
        object
          ? cursorMap[EditorMode.SELECT].overSelectedObject
          : cursorMap[EditorMode.SELECT].default
      )
    },
    [changeCursor, selectedLayerRef]
  )

  const onMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (startCoordsOnScreen) {
        if (dragOnObject) {
          if (
            displayMode !== DisplayMode.PRICING &&
            displayMode !== DisplayMode.CASH &&
            !dragStarted &&
            selectedGroupRef.current
          ) {
            selectedGroupRef.current.startDrag()
            setDragStarted(true)
          }
        } else {
          setEndCoordsOnScreen(getMouseCoordsOnScreen(e))
          onSelection()
        }
      } else {
        handleCursorForSelectedObject(getMouseCoordsOnScreen(e))
      }
    },
    [
      displayMode,
      dragOnObject,
      dragStarted,
      handleCursorForSelectedObject,
      onSelection,
      selectedGroupRef,
      startCoordsOnScreen
    ]
  )

  return {
    startCoordsOnCanvas: startCoordsOnScreen
      ? toCanvasCoords({coords: startCoordsOnScreen, origin, scale})
      : null,
    endCoordsOnCanvas: endCoordsOnScreen
      ? toCanvasCoords({coords: endCoordsOnScreen, origin, scale})
      : null,
    onClick: useGetClickHandlerHook(onClick),
    onMouseDown,
    onMouseUp,
    onMouseMove
  }
}

const RectangleSelectionFn: React.FC<IRectangleSelectionProps> = ({
  unselectedLayerRef,
  selectedGroupRef,
  selectedLayerRef
}: IRectangleSelectionProps) => {
  const {
    startCoordsOnCanvas,
    endCoordsOnCanvas,
    onClick,
    onMouseDown,
    onMouseMove,
    onMouseUp
  } = useManageRectangleSelection(
    unselectedLayerRef,
    selectedGroupRef,
    selectedLayerRef
  )

  const selectionMode = useSelector(selectionModeSelector)

  return (
    <EventLayer {...{onClick, onMouseUp, onMouseMove, onMouseDown}}>
      {startCoordsOnCanvas && endCoordsOnCanvas && (
        <Rect
          x={startCoordsOnCanvas.x}
          y={startCoordsOnCanvas.y}
          width={endCoordsOnCanvas.x - startCoordsOnCanvas.x}
          height={endCoordsOnCanvas.y - startCoordsOnCanvas.y}
          fill={SELECTION_FILL_COLOR}
          stroke={SELECTION_STROKE_COLOR}
          strokeWidth={SELECTION_STROKE_WIDTH}
          dash={
            selectionMode === SelectionMode.CROSSING
              ? SELECTION_STROKE_DASH
              : undefined
          }
        />
      )}
    </EventLayer>
  )
}

export const RectangleSelection = React.memo(RectangleSelectionFn, _.isEqual)
