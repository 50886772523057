import {useMutation} from '@apollo/react-hooks'
import gql from 'graphql-tag'

import {LEAD_OPTION_FRAGMENT} from '../../../utils/auth'
import {
  UpdateLeadOptionsMutation,
  UpdateLeadOptionsMutationVariables
} from '../../../__generated__/schema'

const UPDATE_LEAD_OPTIONS = gql`
  mutation updateLeadOptions($options: [LeadOptionInput!]!) {
    updateLeadOptions(options: $options) {
      ...LeadOptionProperties
    }
  }
  ${LEAD_OPTION_FRAGMENT}
`

export const useUpdateLeadOptions = () => {
  const [updateLeadOptions] =
    useMutation<UpdateLeadOptionsMutation, UpdateLeadOptionsMutationVariables>(
      UPDATE_LEAD_OPTIONS
    )
  return (variables: UpdateLeadOptionsMutationVariables) =>
    updateLeadOptions({variables})
}
