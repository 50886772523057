import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {Box, Button, IconButton} from '@mui/material'
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {
  PermissionCode,
  ProductTypesQuery,
  ProductTypeState
} from '../../../../__generated__/schema'
import {useTranslateProductTypeState} from '../../../../hooks/productTypeState'
import {useEnsurePermissions} from '../../../../utils/auth'
import {routeTo} from '../../../../utils/routes'
import {EntityStateChip, RenderOnData} from '../../../common'
import {CreateFab, useFabClasses} from '../../../common/Buttons'
import {DataGridTable} from '../../../common/DataGridTable'
import {productTypeStateColors} from '../../../constants'
import {Blank} from '../../../visual/Blank'
import {useGetProductTypes} from './graphql'

const StateRenderer = ({state}: {state: ProductTypeState}) => {
  const translateProductTypeState = useTranslateProductTypeState()
  return (
    <EntityStateChip
      colorConf={productTypeStateColors[state]}
      label={translateProductTypeState(state)}
    />
  )
}

const IconCellRenderer = ({id}: {id: number}) => {
  const history = useHistory()
  const handleIconClick = useCallback(
    () => history.push(routeTo.admin.productTypes.edit(id)),
    [history, id]
  )
  return (
    <IconButton sx={{width: 48, height: 48}} onClick={handleIconClick}>
      <EditIcon />
    </IconButton>
  )
}

interface IProductTypesListProps {
  onCreateProductTypeButtonClick: () => void
}

export const ProductTypesList: React.FC<IProductTypesListProps> = ({
  onCreateProductTypeButtonClick
}: IProductTypesListProps) => {
  const {t} = useTranslation()
  const {P} = useEnsurePermissions()
  const {data, loading, error} = useGetProductTypes()
  const fabClasses = useFabClasses()
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: t('Name'),
        field: 'name',
        minWidth: 200
      },
      {
        headerName: t('Abbreviation'),
        field: 'abbreviation',
        minWidth: 150
      },
      {
        headerName: t('State'),
        field: 'state',
        renderCell: function renderer(
          params: GridRenderCellParams<{value: ProductTypeState}>
        ) {
          return <StateRenderer state={params.value} />
        },
        minWidth: 150
      },
      {
        headerName: '',
        field: 'id',
        renderCell: function renderer(params: GridRenderCellParams) {
          return <IconCellRenderer id={params.value} />
        },
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        width: 48
      }
    ],
    [t]
  )
  return (
    <RenderOnData<ProductTypesQuery>
      data={data}
      loading={loading}
      error={error}
      errorMessage={t<string>('Error while loading product types list')}
      dataCondition={(data) => Array.isArray(data.productTypes)}
      ignoreLoadingIfData
    >
      {({productTypes}) => (
        <>
          <Box sx={{height: 'calc(100% - 64px)', width: '100%', p: 3}}>
            {productTypes.length > 0 ? (
              <DataGridTable
                columns={columns}
                rows={productTypes}
                pageSizeOptions={[10, 30, 50]}
                initialState={{
                  pagination: {paginationModel: {pageSize: 30}},
                  pinnedColumns: {left: ['name'], right: ['id']}
                }}
                columnVisibilityModel={{
                  id: P([
                    PermissionCode.ReadProductType,
                    PermissionCode.UpdateProductType
                  ])
                }}
                localeText={{noRowsLabel: t('No product types found')}}
                disableRowSelectionOnClick
              />
            ) : (
              <Blank
                title={t('No product types found')}
                description={t(
                  'Create product types for better statistics views. You can try basic types like Products and Services, or very sophisticated list with many types like Food, Cold beverages, Hot beverages, Merchandise, etc.'
                )}
                actions={
                  P([PermissionCode.CreateProductType]) && (
                    <Button
                      onClick={onCreateProductTypeButtonClick}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      {t('Create')}
                    </Button>
                  )
                }
              />
            )}
          </Box>
          {P([PermissionCode.CreateProductType]) && productTypes.length > 0 && (
            <CreateFab
              onClick={onCreateProductTypeButtonClick}
              classes={fabClasses}
            />
          )}
        </>
      )}
    </RenderOnData>
  )
}
